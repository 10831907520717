import { Instance, SnapshotOut, types } from 'mobx-state-tree';

/**
 * A UserStore model.
 */
// prettier-ignore
const UserModelDefinition = types
  .model('User')
  .props({
    id: types.identifier,
    firebaseUid: types.string,
    twoFactorAuthEnabled: types.maybeNull(types.boolean),
    provider: types.maybeNull(types.model('Provider').props({
        id: types.identifier,
        firstName: types.maybeNull(types.string),
        lastName: types.maybeNull(types.string),
      })
    ),
    userWorkspaces: types.optional(
      types.array(
        types.model('UserWorkspace').props({
          id: types.identifier,
          defaultLocation: types.maybeNull(
            types.model('DefaultLocation').props({
              id: types.identifier,
              name: types.maybeNull(types.string),
              timezone: types.maybeNull(types.string),
            })
          ),
          lockTimezoneToDefaultLocation: types.boolean,
          lockedToLocation: types.maybeNull(
            types.model('LockedToLocation').props({
              id: types.identifier,
              name: types.maybeNull(types.string),
            })
          ),
          isManager: types.optional(types.boolean, false),
          isListMobileView: types.boolean,
          role: types.maybeNull(types.string),
          userId: types.maybeNull(types.string),
          workspaceId: types.maybeNull(types.string),
        })
      ),
      []
    ),
    workspaceId: types.maybeNull(types.string),
    providerId: types.maybeNull(types.string),
    knockUserToken: types.maybeNull(types.string),
    firstName: types.maybeNull(types.string),
    lastName: types.maybeNull(types.string),
    email: types.maybeNull(types.string),
    phoneNumber: types.maybeNull(types.string),
    title: types.maybeNull(types.string),
    providerLocationIds: types.maybeNull(types.array(types.string)), // This is used to track if the provider that the user is associated with has locationIds
    nylasGrant: types.maybeNull(types.string),
    googleCalendarId: types.maybeNull(types.string),
  })
  .actions((self) => ({}))
  .views((self) => ({
    get role() {
      if (self.userWorkspaces.length > 0) {
        return self.userWorkspaces[0].role;
      }
      return null;
    },
    get lockedToLocation() {
      if (self.userWorkspaces.length > 0) {
        return self.userWorkspaces[0].lockedToLocation;
      }
      return null;
    },
    get userWorkspaceId() {
      if (self.userWorkspaces.length > 0) {
        return self.userWorkspaces[0].id;
      }
      return null;
    },
  }))
  .views((self) => ({
    get isManager() {
      if (self.userWorkspaces.length > 0) {
        return self.userWorkspaces[0].isManager || self.role === 'owner';
      }
      return false;
    },
  }));

/**
 * The User instance.
 */
export type User = Instance<typeof UserModel>;

export const UserModel = types.snapshotProcessor(UserModelDefinition, {
  preProcessor(sn: any) {
    const user = { ...sn };
    if (user?.providers) {
      const providerIds: string[] = [];
      user.providers.forEach((provider: any) => {
        provider.locationProviders.forEach((locationProvider: any) => {
          providerIds.push(locationProvider.location.id);
        });
      });
      user.providerLocationIds = providerIds;
      user.providerId = user.providers?.[0]?.id;
      user.provider = user.providers?.[0];
    }
    return user;
  },
});

/**
 * The data of a User.
 */
export type UserSnapshot = SnapshotOut<typeof UserModel>;
