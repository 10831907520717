import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type InsertTaskCommentMutationVariables = Types.Exact<{
  comment: Types.Scalars['String'];
  taskId: Types.Scalars['uuid'];
}>;


export type InsertTaskCommentMutation = { __typename?: 'mutation_root', insert_taskComment_one?: { __typename?: 'taskComment', comment: string, createdAt: any, id: any, updatedAt: any, author: { __typename?: 'user', firstName: string, id: any, lastName: string, title?: string | null | undefined } } | null | undefined };


export const InsertTaskCommentDocument = gql`
    mutation InsertTaskComment($comment: String!, $taskId: uuid!) {
  insert_taskComment_one(object: {comment: $comment, taskId: $taskId}) {
    author {
      firstName
      id
      lastName
      title
    }
    comment
    createdAt
    id
    updatedAt
  }
}
    `;
export type InsertTaskCommentMutationFn = Apollo.MutationFunction<InsertTaskCommentMutation, InsertTaskCommentMutationVariables>;

/**
 * __useInsertTaskCommentMutation__
 *
 * To run a mutation, you first call `useInsertTaskCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertTaskCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertTaskCommentMutation, { data, loading, error }] = useInsertTaskCommentMutation({
 *   variables: {
 *      comment: // value for 'comment'
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useInsertTaskCommentMutation(baseOptions?: Apollo.MutationHookOptions<InsertTaskCommentMutation, InsertTaskCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertTaskCommentMutation, InsertTaskCommentMutationVariables>(InsertTaskCommentDocument, options);
      }
export type InsertTaskCommentMutationHookResult = ReturnType<typeof useInsertTaskCommentMutation>;
export type InsertTaskCommentMutationResult = Apollo.MutationResult<InsertTaskCommentMutation>;
export type InsertTaskCommentMutationOptions = Apollo.BaseMutationOptions<InsertTaskCommentMutation, InsertTaskCommentMutationVariables>;