import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { stringToColor } from '@webapp/webapp/util-display';
/**
 * A CalendarEventStore model.
 */
// prettier-ignore
export const model = types
  .model('CalendarEvent')
  .props({
    appointmentType: types.maybeNull(types.string),
    color: types.maybeNull(types.string),
    intakeStatus: types.optional(types.string, ''),
    consumablesRecorded: types.optional(types.boolean, false),
    editable: types.boolean,
    end: types.string,
    externalId: types.maybeNull(types.string),
    id: types.string,
    internalAppointmentProviders: types.optional(types.array(types.string), []),
    isFirstAppointmentWithProvider: types.maybeNull(types.boolean),
    isMembershipActive: types.maybeNull(types.boolean),
    isMembershipTypeSubscription: types.maybeNull(types.boolean),
    isNewPatient: types.maybeNull(types.boolean),
    note: types.maybeNull(types.string),
    notesSigned: types.optional(types.boolean, false),
    patientId: types.maybeNull(types.string),
    patientName: types.maybeNull(types.string),
    phoneNumber: types.maybeNull(types.string),
    hasPhotos: types.optional(types.boolean, false),
    providerName: types.maybeNull(types.string),
    recurring: types.maybeNull(types.string),
    recurringException: types.optional(types.array(types.string), []),
    resource: types.optional(types.array(types.string), []),
    room: types.maybeNull(types.string),
    start: types.string,
    status: types.maybeNull(types.string),
    services: types.optional(types.array(types.string), []),
    title: types.maybeNull(types.string),
    type: types.maybeNull(types.string),
  })
  .actions((self) => ({}));
/**
 * The CalendarEvent instance.
 */
export const CalendarEventModel = types.snapshotProcessor(model, {
  preProcessor(sn: any) {
    const calendarEvent = { ...sn };
    if (!sn.color) {
      calendarEvent.color = stringToColor(sn.appointmentType || 'non-service');
    }
    calendarEvent.editable = sn.externalId ? false : true;
    return calendarEvent;
  },
});

export type CalendarEvent = Instance<typeof CalendarEventModel>;

/**
 * The data of a CalendarEvent.
 */
export type CalendarEventSnapshot = SnapshotOut<typeof CalendarEventModel>;
