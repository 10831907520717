import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type SearchInvoiceTemplatesQueryVariables = Types.Exact<{
  searchValue: Types.Scalars['String'];
  limit?: Types.Maybe<Types.Scalars['Int']>;
}>;


export type SearchInvoiceTemplatesQuery = { __typename?: 'query_root', invoiceTemplate: Array<{ __typename?: 'invoiceTemplate', id: any, title: string }> };


export const SearchInvoiceTemplatesDocument = gql`
    query searchInvoiceTemplates($searchValue: String!, $limit: Int) {
  invoiceTemplate(where: {title: {_ilike: $searchValue}}) {
    id
    title
  }
}
    `;

/**
 * __useSearchInvoiceTemplatesQuery__
 *
 * To run a query within a React component, call `useSearchInvoiceTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchInvoiceTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchInvoiceTemplatesQuery({
 *   variables: {
 *      searchValue: // value for 'searchValue'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useSearchInvoiceTemplatesQuery(baseOptions: Apollo.QueryHookOptions<SearchInvoiceTemplatesQuery, SearchInvoiceTemplatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchInvoiceTemplatesQuery, SearchInvoiceTemplatesQueryVariables>(SearchInvoiceTemplatesDocument, options);
      }
export function useSearchInvoiceTemplatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchInvoiceTemplatesQuery, SearchInvoiceTemplatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchInvoiceTemplatesQuery, SearchInvoiceTemplatesQueryVariables>(SearchInvoiceTemplatesDocument, options);
        }
export type SearchInvoiceTemplatesQueryHookResult = ReturnType<typeof useSearchInvoiceTemplatesQuery>;
export type SearchInvoiceTemplatesLazyQueryHookResult = ReturnType<typeof useSearchInvoiceTemplatesLazyQuery>;
export type SearchInvoiceTemplatesQueryResult = Apollo.QueryResult<SearchInvoiceTemplatesQuery, SearchInvoiceTemplatesQueryVariables>;