import './generic-object-select.module.scss';
import Async from 'react-select/async';
import Select, { ActionMeta, MultiValue, SingleValue } from 'react-select';

import { Box, HTMLChakraProps } from '@chakra-ui/react';
import { ObjectOption } from '@webapp/types';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import { ReactNode } from 'react';
import { FormatOptionLabelMeta } from 'react-select/src/Select';

export type GenericSelectOnChangeFunction<T> = (
  newValue: SingleValue<ObjectOption<T>> | MultiValue<ObjectOption<T>>,
  actionMeta: ActionMeta<ObjectOption<T>>
) => void;

/* eslint-disable-next-line */
export interface GenericObjectSelectProps<T> {
  useLoadOptions?: () => {
    search: (searchParam: string) => Promise<ObjectOption<T>[]>;
  };
  isAsync?: boolean;
  isClearable?: boolean;
  defaultOptions?: boolean;
  isMulti?: boolean;
  isSearchable?: boolean;
  onChange?: GenericSelectOnChangeFunction<T>;
  selectOptions?: ObjectOption<T>[];
  value?: ObjectOption<T> | ObjectOption<T>[] | undefined | null;
  containerStyle?: HTMLChakraProps<'div'>;
  placeholder?: string;
  inputId?: string;
  defaultValue?: ObjectOption<T> | ObjectOption<T>[] | undefined | null;
  formatOptionLabel?: (
    option: ObjectOption<T>,
    meta: FormatOptionLabelMeta<ObjectOption<T>, any>
  ) => ReactNode;
}

export function GenericObjectSelect<T>({
  isAsync = false,
  defaultOptions = true,
  isClearable = true,
  isMulti = false,
  isSearchable = true,
  placeholder,
  defaultValue = null,
  selectOptions = [],
  value,
  onChange,
  useLoadOptions = () => ({
    search: async () => [],
  }),
  containerStyle = {},
  formatOptionLabel,
  inputId,
}: GenericObjectSelectProps<T>) {
  const { search } = useLoadOptions();

  const debouncedSearch = AwesomeDebouncePromise(search, 300);

  async function loadOptions(
    inputValue: string,
    callback: (results: ObjectOption<T>[]) => void
  ) {
    const options = await debouncedSearch(inputValue);
    callback(options);
    return options;
  }

  function handleChange(
    newValue: SingleValue<ObjectOption<T>> | MultiValue<ObjectOption<T>>,
    actionMeta: ActionMeta<ObjectOption<T>>
  ) {
    if (onChange) onChange(newValue, actionMeta);

    switch (actionMeta.action) {
      case 'remove-value': {
        break;
      }

      case 'select-option': {
        break;
      }

      case 'clear': {
        if (onChange) onChange(null, actionMeta);
        break;
      }

      case 'create-option':
      case 'deselect-option':
      case 'pop-value':
      default: {
        break;
      }
    }
  }
  return (
    <Box {...containerStyle}>
      {!isAsync && (
        <Select
          {...(inputId && { inputId })}
          menuPortalTarget={document.body}
          onChange={handleChange}
          options={selectOptions}
          placeholder={placeholder}
          styles={{
            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
          }}
          {...(defaultValue && { defaultValue })}
          {...(formatOptionLabel && { formatOptionLabel })}
        />
      )}

      {isAsync && (
        <Async
          menuPortalTarget={document.body}
          isClearable={isClearable}
          styles={{
            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
          }}
          isMulti={isMulti}
          placeholder={placeholder}
          noOptionsMessage={() => 'Type to search'}
          loadOptions={loadOptions}
          onChange={handleChange}
          defaultOptions={defaultOptions}
          defaultValue={defaultValue}
          {...(inputId && { inputId })}
          {...(value && { value })}
          {...(formatOptionLabel && { formatOptionLabel })}
        />
      )}
    </Box>
  );
}

export default GenericObjectSelect;
