import { Box, Button, HStack } from '@chakra-ui/react';
import { Location } from '@webapp/state-models';
import { HookForm, FormInput } from '@webapp/ui';
import { observer } from 'mobx-react-lite';
import AvailabilityTemplate from '../AvailabilityTemplate/AvailabilityTemplate';
import './location-availability.module.scss';

/* eslint-disable-next-line */
export interface LocationAvailabilityProps {
  location: Location;
}

export const LocationAvailability = observer(
  ({ location }: LocationAvailabilityProps) => (
    <Box>
      <Box maxW="md">
        <HookForm
          onSubmit={location.updateWorkTimes}
          defaultValues={{
            workTime: {
              start: location.workStartTime,
              end: location.workEndTime,
            },
          }}
        >
          <HStack alignItems={'center'}>
            <FormInput
              name="workTime"
              label="What timerange should your schedule show?"
              type="timerange-picker"
              datePickerProps={{
                rangeStartName: 'workStartTime',
                rangeEndName: 'workEndTime',
              }}
            ></FormInput>
            <Button
              colorScheme={'teal'}
              type="submit"
              alignSelf="flex-end"
              mb={2}
            >
              Save
            </Button>
          </HStack>
        </HookForm>
      </Box>

      {/* {location.effectiveSchedule() ? (
        <AvailabilityTemplate
          onChange={(schedule) =>
            location.updateAvailability(
              location.effectiveSchedule()?.id as string,
              schedule
            )
          }
          schedule={JSON.parse(
            JSON.stringify(location.effectiveSchedule()?.schedule)
          )}
        />
      ) : (
        <Button onClick={() => location.createAvailability()}>
          Add availability
        </Button>
      )} */}
    </Box>
  )
);

export default LocationAvailability;
