import { Center, Spinner } from '@chakra-ui/react';
import './loading-full-screen.module.css';
import { VH_MINUS_HEADER } from '@webapp/webapp/util-display';
/* eslint-disable-next-line */
export interface LoadingFullScreenProps {}

export function LoadingFullScreen(props: LoadingFullScreenProps) {
  return (
    <Center w="full" h={VH_MINUS_HEADER}>
      <Spinner size="xl" colorScheme={'teal'} />
    </Center>
  );
}

export default LoadingFullScreen;
