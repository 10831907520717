import { SimpleGrid, Skeleton } from '@chakra-ui/react';
import PhotoGridByDate from './photo-grid-by-date';
import './photo-grid.module.scss';
import DefaultPhotoGrid from './default-photo-grid';
import PhotoGridInSquares from './photo-grid-in-squares';
import PhotoGridProps from './photo-grid-props';

export function PhotoGrid({
  columnGap,
  customStyles,
  isLoading,
  nextPage,
  onPhotoClick,
  photos,
  rowGap,
  selectedPhoto,
  shouldDisplayTags = true,
  spacing,
  variant = 'default',
  onSelect,
  selectedPhotos,
}: PhotoGridProps) {
  if (!photos.length && isLoading) {
    return (
      <SimpleGrid spacing={spacing ?? 15} columns={4} w={'full'} h={'full'}>
        {Array.from(Array(20)).map((_, index) => (
          <Skeleton
            endColor="gray.400"
            height="188px"
            key={`loading-cube-${index}`}
            speed={1.1}
            startColor="gray.200"
            width="100%"
          />
        ))}
      </SimpleGrid>
    );
  }

  const sharedProps = {
    columnGap,
    customStyles,
    isLoading,
    nextPage,
    onPhotoClick,
    photos,
    rowGap,
    selectedPhoto,
    spacing,
    shouldDisplayTags,
    onSelect,
    selectedPhotos,
  };

  switch (variant) {
    case 'squares': {
      return <PhotoGridInSquares {...sharedProps} />;
    }
    case 'date':
    case 'date-and-uploader': {
      return <PhotoGridByDate variant={variant} {...sharedProps} />;
    }

    default: {
      return <DefaultPhotoGrid {...sharedProps} />;
    }
  }
}

export default PhotoGrid;
