import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetOnePayrixTransactionQueryVariables = Types.Exact<{
  transactionId: Types.Scalars['String'];
}>;


export type GetOnePayrixTransactionQuery = { __typename?: 'query_root', getPayrixTransaction: { __typename?: 'PayrixTransaction', type: number, status: number, id: string } };


export const GetOnePayrixTransactionDocument = gql`
    query GetOnePayrixTransaction($transactionId: String!) {
  getPayrixTransaction(transactionId: $transactionId) {
    type
    status
    id
  }
}
    `;

/**
 * __useGetOnePayrixTransactionQuery__
 *
 * To run a query within a React component, call `useGetOnePayrixTransactionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOnePayrixTransactionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOnePayrixTransactionQuery({
 *   variables: {
 *      transactionId: // value for 'transactionId'
 *   },
 * });
 */
export function useGetOnePayrixTransactionQuery(baseOptions: Apollo.QueryHookOptions<GetOnePayrixTransactionQuery, GetOnePayrixTransactionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOnePayrixTransactionQuery, GetOnePayrixTransactionQueryVariables>(GetOnePayrixTransactionDocument, options);
      }
export function useGetOnePayrixTransactionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOnePayrixTransactionQuery, GetOnePayrixTransactionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOnePayrixTransactionQuery, GetOnePayrixTransactionQueryVariables>(GetOnePayrixTransactionDocument, options);
        }
export type GetOnePayrixTransactionQueryHookResult = ReturnType<typeof useGetOnePayrixTransactionQuery>;
export type GetOnePayrixTransactionLazyQueryHookResult = ReturnType<typeof useGetOnePayrixTransactionLazyQuery>;
export type GetOnePayrixTransactionQueryResult = Apollo.QueryResult<GetOnePayrixTransactionQuery, GetOnePayrixTransactionQueryVariables>;