import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { MembershipFieldsFragmentDoc } from '../../fragments/membershipFields.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type InsertMembershipMutationVariables = Types.Exact<{
  membership: Types.Membership_Insert_Input;
}>;


export type InsertMembershipMutation = { __typename?: 'mutation_root', insert_membership?: { __typename?: 'membership_mutation_response', returning: Array<{ __typename?: 'membership', id: any, name: string, type: Types.MembershipType_Enum, price: number, taxRate: any, description?: string | null | undefined, image?: string | null | undefined, availableInStore?: boolean | null | undefined, discount: number, billingPeriodInDays: number, maxBillingCycles?: number | null | undefined, billingDayOfMonth?: number | null | undefined, createdAt: any, updatedAt: any, locationId?: any | null | undefined, workspaceId: any, color: string, hasCoupons: boolean, hasCredits: boolean, credits: number, archived: boolean, location?: { __typename?: 'location', id: any, name: string } | null | undefined, membershipCoupons: Array<{ __typename?: 'membershipCoupon', count: number, coupon: { __typename?: 'coupon', id: any, name: string } }> }> } | null | undefined };


export const InsertMembershipDocument = gql`
    mutation insertMembership($membership: membership_insert_input!) {
  insert_membership(objects: [$membership]) {
    returning {
      ...MembershipFields
    }
  }
}
    ${MembershipFieldsFragmentDoc}`;
export type InsertMembershipMutationFn = Apollo.MutationFunction<InsertMembershipMutation, InsertMembershipMutationVariables>;

/**
 * __useInsertMembershipMutation__
 *
 * To run a mutation, you first call `useInsertMembershipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertMembershipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertMembershipMutation, { data, loading, error }] = useInsertMembershipMutation({
 *   variables: {
 *      membership: // value for 'membership'
 *   },
 * });
 */
export function useInsertMembershipMutation(baseOptions?: Apollo.MutationHookOptions<InsertMembershipMutation, InsertMembershipMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertMembershipMutation, InsertMembershipMutationVariables>(InsertMembershipDocument, options);
      }
export type InsertMembershipMutationHookResult = ReturnType<typeof useInsertMembershipMutation>;
export type InsertMembershipMutationResult = Apollo.MutationResult<InsertMembershipMutation>;
export type InsertMembershipMutationOptions = Apollo.BaseMutationOptions<InsertMembershipMutation, InsertMembershipMutationVariables>;