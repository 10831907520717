import * as Types from '../../generated/graphql';

import { gql } from '@apollo/client';
export type PatientSearchFieldsFragment = { __typename?: 'patient', dob?: string | null | undefined, id: any, firstName: string, gender?: string | null | undefined, language: string, lastName: string, email?: string | null | undefined, status: string, phoneNumber?: string | null | undefined, profilePicture?: { __typename?: 'photo', hasThumbnail?: boolean | null | undefined, file?: { __typename?: 'FilePayload', filePath: string, url: string, thumbnailUrl?: string | null | undefined } | null | undefined } | null | undefined, attributes: { __typename?: 'PatientAttributes', attributes?: any | null | undefined, dob?: string | null | undefined, email?: string | null | undefined, firstName?: string | null | undefined, gender?: string | null | undefined, id?: string | null | undefined, lastName?: string | null | undefined, middleName?: string | null | undefined, phoneNumber?: string | null | undefined, profileNote?: string | null | undefined, status?: string | null | undefined, partialMediaConsent?: boolean | null | undefined, fullMediaConsent?: boolean | null | undefined, language?: string | null | undefined, emailMarketingEnabled?: boolean | null | undefined, smsMarketingEnabled?: boolean | null | undefined, phoneMarketingEnabled?: boolean | null | undefined, emailConfirmed?: boolean | null | undefined, phoneConfirmed?: boolean | null | undefined, profilePicture?: { __typename?: 'FilePayload', filePath: string, url: string, thumbnailUrl?: string | null | undefined } | null | undefined } };

export const PatientSearchFieldsFragmentDoc = gql`
    fragment PatientSearchFields on patient {
  dob
  id
  firstName
  gender
  language
  lastName
  email
  language
  status
  phoneNumber
  profilePicture {
    hasThumbnail
    file {
      filePath
      url
      thumbnailUrl
    }
  }
  attributes {
    attributes
    dob
    email
    firstName
    gender
    id
    lastName
    middleName
    phoneNumber
    profileNote
    status
    partialMediaConsent
    fullMediaConsent
    language
    emailMarketingEnabled
    smsMarketingEnabled
    phoneMarketingEnabled
    emailConfirmed
    phoneConfirmed
    profilePicture {
      filePath
      url
      thumbnailUrl
    }
  }
}
    `;