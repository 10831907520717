import toast from 'react-hot-toast';
import { GenerateCUDHookParams, GenerateCUDHookParamsOutput } from './types';

export const generateCUDHookParams = <T>({
  item,
  operation,
  callback,
  customErrorMessage,
  refetchQueries,
}: GenerateCUDHookParams<T>): GenerateCUDHookParamsOutput<T> => ({
  onCompleted: (response) => {
    toast.success(`${item} ${operation}`);
    if (!callback) return;
    callback(response);
  },
  onError: (error) => {
    toast.error(customErrorMessage ?? (error as Error).message);
  },
  refetchQueries,
});

export default generateCUDHookParams;
