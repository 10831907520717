import { useApolloClient } from '@apollo/client';
import {
  GetConsentsDocument,
  GetConsentsQuery,
  GetConsentsQueryVariables,
} from '@webapp/graphql';
import { useStores } from '@webapp/state-models';
import { SelectOption } from '@webapp/types';

interface UseLoadConsentsOutput {
  search: (searchValue: string) => Promise<SelectOption[]>;
}

export function useLoadConsents(): UseLoadConsentsOutput {
  const client = useApolloClient();

  const { workspace } = useStores();

  return {
    search: async (searchValue: string) => {
      const { data } = await client.query<
        GetConsentsQuery,
        GetConsentsQueryVariables
      >({
        query: GetConsentsDocument,
        variables: {
          where: {
            ...(searchValue && {
              title: {
                _ilike: `%${searchValue}%`,
              },
            }),
            workspaceId: {
              _eq: workspace?.id,
            },
          },
        },
      });

      const consents = data?.consent ?? [];

      return consents.map(({ id, title }) => ({
        label: title,
        value: id,
      }));
    },
  };
}

export default useLoadConsents;
