import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type SendInvoiceToPatientMutationVariables = Types.Exact<{
  invoiceId: Types.Scalars['uuid'];
  method?: Types.Maybe<Types.Scalars['String']>;
}>;


export type SendInvoiceToPatientMutation = { __typename?: 'mutation_root', sendInvoiceToPatient?: { __typename?: 'SuccessAnswer', success: boolean } | null | undefined };


export const SendInvoiceToPatientDocument = gql`
    mutation SendInvoiceToPatient($invoiceId: uuid!, $method: String) {
  sendInvoiceToPatient(invoiceId: $invoiceId, method: $method) {
    success
  }
}
    `;
export type SendInvoiceToPatientMutationFn = Apollo.MutationFunction<SendInvoiceToPatientMutation, SendInvoiceToPatientMutationVariables>;

/**
 * __useSendInvoiceToPatientMutation__
 *
 * To run a mutation, you first call `useSendInvoiceToPatientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendInvoiceToPatientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendInvoiceToPatientMutation, { data, loading, error }] = useSendInvoiceToPatientMutation({
 *   variables: {
 *      invoiceId: // value for 'invoiceId'
 *      method: // value for 'method'
 *   },
 * });
 */
export function useSendInvoiceToPatientMutation(baseOptions?: Apollo.MutationHookOptions<SendInvoiceToPatientMutation, SendInvoiceToPatientMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendInvoiceToPatientMutation, SendInvoiceToPatientMutationVariables>(SendInvoiceToPatientDocument, options);
      }
export type SendInvoiceToPatientMutationHookResult = ReturnType<typeof useSendInvoiceToPatientMutation>;
export type SendInvoiceToPatientMutationResult = Apollo.MutationResult<SendInvoiceToPatientMutation>;
export type SendInvoiceToPatientMutationOptions = Apollo.BaseMutationOptions<SendInvoiceToPatientMutation, SendInvoiceToPatientMutationVariables>;