import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type CountAudienceQueryVariables = Types.Exact<{
  audienceId?: Types.Maybe<Types.Scalars['uuid']>;
  ast?: Types.Maybe<Types.Scalars['jsonb']>;
}>;


export type CountAudienceQuery = { __typename?: 'query_root', audienceCount?: { __typename?: 'AudienceCountResult', count?: number | null | undefined } | null | undefined };


export const CountAudienceDocument = gql`
    query countAudience($audienceId: uuid, $ast: jsonb) {
  audienceCount(audienceId: $audienceId, ast: $ast) {
    count
  }
}
    `;

/**
 * __useCountAudienceQuery__
 *
 * To run a query within a React component, call `useCountAudienceQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountAudienceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountAudienceQuery({
 *   variables: {
 *      audienceId: // value for 'audienceId'
 *      ast: // value for 'ast'
 *   },
 * });
 */
export function useCountAudienceQuery(baseOptions?: Apollo.QueryHookOptions<CountAudienceQuery, CountAudienceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CountAudienceQuery, CountAudienceQueryVariables>(CountAudienceDocument, options);
      }
export function useCountAudienceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CountAudienceQuery, CountAudienceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CountAudienceQuery, CountAudienceQueryVariables>(CountAudienceDocument, options);
        }
export type CountAudienceQueryHookResult = ReturnType<typeof useCountAudienceQuery>;
export type CountAudienceLazyQueryHookResult = ReturnType<typeof useCountAudienceLazyQuery>;
export type CountAudienceQueryResult = Apollo.QueryResult<CountAudienceQuery, CountAudienceQueryVariables>;