import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { SmartNoteFieldsFragmentDoc } from '../../fragments/smartNoteFields.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type UpdateSmartNoteMutationVariables = Types.Exact<{
  set?: Types.Maybe<Types.SmartNote_Set_Input>;
  id: Types.Scalars['uuid'];
}>;


export type UpdateSmartNoteMutation = { __typename?: 'mutation_root', update_smartNote_by_pk?: { __typename?: 'smartNote', id: any, title: string, callPhrase: string, content: string, createdAt: any, updatedAt: any, workspaceId: any } | null | undefined };


export const UpdateSmartNoteDocument = gql`
    mutation UpdateSmartNote($set: smartNote_set_input, $id: uuid!) {
  update_smartNote_by_pk(_set: $set, pk_columns: {id: $id}) {
    ...SmartNoteFields
  }
}
    ${SmartNoteFieldsFragmentDoc}`;
export type UpdateSmartNoteMutationFn = Apollo.MutationFunction<UpdateSmartNoteMutation, UpdateSmartNoteMutationVariables>;

/**
 * __useUpdateSmartNoteMutation__
 *
 * To run a mutation, you first call `useUpdateSmartNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSmartNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSmartNoteMutation, { data, loading, error }] = useUpdateSmartNoteMutation({
 *   variables: {
 *      set: // value for 'set'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateSmartNoteMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSmartNoteMutation, UpdateSmartNoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSmartNoteMutation, UpdateSmartNoteMutationVariables>(UpdateSmartNoteDocument, options);
      }
export type UpdateSmartNoteMutationHookResult = ReturnType<typeof useUpdateSmartNoteMutation>;
export type UpdateSmartNoteMutationResult = Apollo.MutationResult<UpdateSmartNoteMutation>;
export type UpdateSmartNoteMutationOptions = Apollo.BaseMutationOptions<UpdateSmartNoteMutation, UpdateSmartNoteMutationVariables>;