import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type ReplyToGoogleReviewMutationVariables = Types.Exact<{
  comment: Types.Scalars['String'];
  externalId: Types.Scalars['String'];
}>;


export type ReplyToGoogleReviewMutation = { __typename?: 'mutation_root', replyToGoogleReview: { __typename?: 'ReplyToGoogleReviewOutput', externalReview?: { __typename?: 'externalReview', id: any, comment: string, response?: string | null | undefined } | null | undefined } };


export const ReplyToGoogleReviewDocument = gql`
    mutation ReplyToGoogleReview($comment: String!, $externalId: String!) {
  replyToGoogleReview(comment: $comment, externalId: $externalId) {
    externalReview {
      id
      comment
      response
    }
  }
}
    `;
export type ReplyToGoogleReviewMutationFn = Apollo.MutationFunction<ReplyToGoogleReviewMutation, ReplyToGoogleReviewMutationVariables>;

/**
 * __useReplyToGoogleReviewMutation__
 *
 * To run a mutation, you first call `useReplyToGoogleReviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReplyToGoogleReviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [replyToGoogleReviewMutation, { data, loading, error }] = useReplyToGoogleReviewMutation({
 *   variables: {
 *      comment: // value for 'comment'
 *      externalId: // value for 'externalId'
 *   },
 * });
 */
export function useReplyToGoogleReviewMutation(baseOptions?: Apollo.MutationHookOptions<ReplyToGoogleReviewMutation, ReplyToGoogleReviewMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReplyToGoogleReviewMutation, ReplyToGoogleReviewMutationVariables>(ReplyToGoogleReviewDocument, options);
      }
export type ReplyToGoogleReviewMutationHookResult = ReturnType<typeof useReplyToGoogleReviewMutation>;
export type ReplyToGoogleReviewMutationResult = Apollo.MutationResult<ReplyToGoogleReviewMutation>;
export type ReplyToGoogleReviewMutationOptions = Apollo.BaseMutationOptions<ReplyToGoogleReviewMutation, ReplyToGoogleReviewMutationVariables>;