import * as Types from '../../generated/graphql';

import { gql } from '@apollo/client';
export type PatientConversationFieldsFragment = { __typename?: 'patientConversation', id: any, category?: string | null | undefined, patientId: any, patientWorkspaceId: any, lastPatientMessageReceived?: any | null | undefined, lastUserMessageSent?: any | null | undefined, hasUnreadMessages?: boolean | null | undefined, twilioConversationSid: string, participantIdentityOverride?: string | null | undefined, patientWorkspace: { __typename?: 'patientWorkspace', id: any, firstName?: string | null | undefined, lastName?: string | null | undefined, phoneNumber?: string | null | undefined } };

export const PatientConversationFieldsFragmentDoc = gql`
    fragment PatientConversationFields on patientConversation {
  id
  patientWorkspace {
    id
    firstName
    lastName
    phoneNumber
  }
  category
  patientId
  patientWorkspaceId
  lastPatientMessageReceived
  lastUserMessageSent
  hasUnreadMessages
  twilioConversationSid
  participantIdentityOverride
}
    `;