import { useState, useRef } from 'react';

import '@knocklabs/react/dist/index.css';
import {
  KnockProvider,
  KnockFeedProvider,
  NotificationIconButton,
  NotificationFeedPopover,
} from '@knocklabs/react';

import { useStores } from '@webapp/state-models';
import { observer } from 'mobx-react-lite';
import { Box } from '@chakra-ui/react';
/* eslint-disable-next-line */
export interface NotificationFeedProps {}

export function NotificationFeed(props: NotificationFeedProps) {
  const [isVisible, setIsVisible] = useState(false);
  const notifButtonRef = useRef(null);
  const { user } = useStores();

  if (!user) return null;

  return (
    <KnockProvider
      apiKey={import.meta.env.VITE_KNOCK_PUBLIC_KEY ?? ''}
      userId={user.id}
      userToken={user.knockUserToken}
    >
      <KnockFeedProvider feedId={import.meta.env.VITE_KNOCK_FEED_CHANNEL_ID}>
        <Box color="black">
          <NotificationIconButton
            ref={notifButtonRef}
            onClick={(e) => setIsVisible(!isVisible)}
          />
          <NotificationFeedPopover
            buttonRef={notifButtonRef}
            isVisible={isVisible}
            onClose={() => setIsVisible(false)}
          />
        </Box>
      </KnockFeedProvider>
    </KnockProvider>
  );
}

export default observer(NotificationFeed);
