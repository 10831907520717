import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type UpdateSmsMarketingTagsMutationVariables = Types.Exact<{
  smsTemplateId: Types.Scalars['uuid'];
  smsMarketingTags: Array<Types.MarketingTagSms_Insert_Input> | Types.MarketingTagSms_Insert_Input;
}>;


export type UpdateSmsMarketingTagsMutation = { __typename?: 'mutation_root', delete_marketingTagSms?: { __typename?: 'marketingTagSms_mutation_response', returning: Array<{ __typename?: 'marketingTagSms', id: any }> } | null | undefined, insert_marketingTagSms?: { __typename?: 'marketingTagSms_mutation_response', affected_rows: number } | null | undefined };


export const UpdateSmsMarketingTagsDocument = gql`
    mutation UpdateSmsMarketingTags($smsTemplateId: uuid!, $smsMarketingTags: [marketingTagSms_insert_input!]!) {
  delete_marketingTagSms(where: {smsTemplateId: {_eq: $smsTemplateId}}) {
    returning {
      id
    }
  }
  insert_marketingTagSms(objects: $smsMarketingTags) {
    affected_rows
  }
}
    `;
export type UpdateSmsMarketingTagsMutationFn = Apollo.MutationFunction<UpdateSmsMarketingTagsMutation, UpdateSmsMarketingTagsMutationVariables>;

/**
 * __useUpdateSmsMarketingTagsMutation__
 *
 * To run a mutation, you first call `useUpdateSmsMarketingTagsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSmsMarketingTagsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSmsMarketingTagsMutation, { data, loading, error }] = useUpdateSmsMarketingTagsMutation({
 *   variables: {
 *      smsTemplateId: // value for 'smsTemplateId'
 *      smsMarketingTags: // value for 'smsMarketingTags'
 *   },
 * });
 */
export function useUpdateSmsMarketingTagsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSmsMarketingTagsMutation, UpdateSmsMarketingTagsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSmsMarketingTagsMutation, UpdateSmsMarketingTagsMutationVariables>(UpdateSmsMarketingTagsDocument, options);
      }
export type UpdateSmsMarketingTagsMutationHookResult = ReturnType<typeof useUpdateSmsMarketingTagsMutation>;
export type UpdateSmsMarketingTagsMutationResult = Apollo.MutationResult<UpdateSmsMarketingTagsMutation>;
export type UpdateSmsMarketingTagsMutationOptions = Apollo.BaseMutationOptions<UpdateSmsMarketingTagsMutation, UpdateSmsMarketingTagsMutationVariables>;