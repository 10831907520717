import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type RoomAggregateQueryVariables = Types.Exact<{
  where?: Types.Maybe<Types.Room_Bool_Exp>;
}>;


export type RoomAggregateQuery = { __typename?: 'query_root', room_aggregate: { __typename?: 'room_aggregate', aggregate?: { __typename?: 'room_aggregate_fields', count: number } | null | undefined } };


export const RoomAggregateDocument = gql`
    query roomAggregate($where: room_bool_exp) {
  room_aggregate(where: $where) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useRoomAggregateQuery__
 *
 * To run a query within a React component, call `useRoomAggregateQuery` and pass it any options that fit your needs.
 * When your component renders, `useRoomAggregateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoomAggregateQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useRoomAggregateQuery(baseOptions?: Apollo.QueryHookOptions<RoomAggregateQuery, RoomAggregateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RoomAggregateQuery, RoomAggregateQueryVariables>(RoomAggregateDocument, options);
      }
export function useRoomAggregateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RoomAggregateQuery, RoomAggregateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RoomAggregateQuery, RoomAggregateQueryVariables>(RoomAggregateDocument, options);
        }
export type RoomAggregateQueryHookResult = ReturnType<typeof useRoomAggregateQuery>;
export type RoomAggregateLazyQueryHookResult = ReturnType<typeof useRoomAggregateLazyQuery>;
export type RoomAggregateQueryResult = Apollo.QueryResult<RoomAggregateQuery, RoomAggregateQueryVariables>;