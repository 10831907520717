import {
  HStack,
  Text,
  Button,
  Stack,
  Collapse,
  Box,
  Wrap,
  WrapItem,
  Spinner,
  Heading,
} from '@chakra-ui/react';
import { AvailabilityRange } from '@webapp/types';
import { observer } from 'mobx-react-lite';
import { useEffect, useState, useRef } from 'react';
import { FaCaretDown, FaCaretUp } from 'react-icons/fa';
import { configuredDayjs as dayjs } from '@webapp/util-time';
import { useStores } from '@webapp/state-models';
import { useGetAppointmentAvailabilityLazyQuery } from '@webapp/graphql';
import generateSuggestedTimes from './generateSuggestedTimes';
import { FormInputV2 } from '@webapp/ui';

const AppointmentManualDate = observer(() => {
  const [show, setShow] = useState(false);
  const {
    calendarCurrentEventDate,
    calendarTimeZone,
    draftAppointment,
    workspace,
  } = useStores();
  const scrollRef = useRef<HTMLHeadingElement | null>(null);
  const [suggestedTimeGroups, setSuggestedTimeGroups] = useState<any>({});
  dayjs.tz.setDefault(calendarTimeZone);

  const [
    getAppointmentAvailability,
    { data: appointmentAvailability, loading: appointmentAvailabilityLoading },
  ] = useGetAppointmentAvailabilityLazyQuery({});

  useEffect(() => {
    if (!appointmentAvailability) return;
    setSuggestedTimeGroups(
      generateSuggestedTimes(
        appointmentAvailability?.getProviderAvailabilityForLocation
          ?.availability as AvailabilityRange[],
        draftAppointment?.totalDuration || 30,
        workspace?.workspaceConfiguration?.schedulingIntervalInMinutes,
        calendarTimeZone
      )
    );

    scrollRef?.current?.scrollIntoView({ behavior: 'smooth' });
  }, [appointmentAvailability]);

  useEffect(() => {
    if (
      draftAppointment?.services &&
      draftAppointment?.location &&
      (draftAppointment?.provider ||
        draftAppointment?.services?.[0]?.providerId)
    ) {
      getAppointmentAvailability({
        variables: {
          providerId: (draftAppointment?.provider?.id ||
            draftAppointment?.services?.[0]?.providerId) as string,
          locationId: draftAppointment?.location?.id,
          serviceIds: draftAppointment?.services?.map((s) => s.serviceId),
          day: dayjs(draftAppointment?.startTime)
            .tz(calendarTimeZone)
            .format('YYYY-MM-DD'),
        },
      });
    }
  }, [
    draftAppointment?.location,
    draftAppointment?.provider?.id,
    draftAppointment?.services,
    draftAppointment?.services.length,
    draftAppointment?.services?.[0]?.providerId,
    draftAppointment?.startTime,
    draftAppointment?.totalDuration,
  ]);

  useEffect(() => {
    if (show) {
      scrollRef?.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [show]);

  return (
    <Stack>
      <HStack justifyContent={'space-between'}>
        <Text
          color="text.100"
          fontSize="md"
          fontWeight="bold"
          id="appointment-manual-date-text"
        >
          {dayjs(draftAppointment?.startTime)
            .tz(calendarTimeZone)
            .format('ddd, D MMMM')}{' '}
          @{' '}
          {dayjs(draftAppointment?.startTime)
            .tz(calendarTimeZone)
            .format('hh:mm A z')}
        </Text>
        <Button
          color="teal.500"
          fontSize="14px"
          id="appointment-manual-date-change-date"
          variant={'ghost'}
          onClick={() => setShow(!show)}
          leftIcon={
            show ? (
              <FaCaretUp color={'teal.500'}></FaCaretUp>
            ) : (
              <FaCaretDown color={'teal.500'}></FaCaretDown>
            )
          }
        >
          Change date
        </Button>
      </HStack>
      <Collapse startingHeight={0} in={show}>
        <HStack w="full" justifyContent={'space-between'}>
          <FormInputV2
            data-testid={`appointment-date-input`}
            label="Appointment Start Time"
            name="appointmentStartTime"
            onChange={(newDate: string) => {
              draftAppointment?.setStartTime(newDate);
            }}
            timezone={calendarTimeZone}
            type="datetime"
            uncontrolled
            value={dayjs(draftAppointment?.startTime).toISOString()}
          />
        </HStack>
        <Box>
          {appointmentAvailabilityLoading ? (
            <Spinner />
          ) : Object.keys(suggestedTimeGroups).length > 0 ? (
            <Box>
              <Heading size="md" color={'brand.primary'} my={2} ref={scrollRef}>
                Suggested available times & dates
              </Heading>
              {Object.keys(suggestedTimeGroups).map((timeGroup, outerIndex) => (
                <Stack>
                  <Text size={'sm'} my={2}>
                    {timeGroup}
                  </Text>
                  <Wrap>
                    {suggestedTimeGroups[timeGroup].map(
                      (time: AvailabilityRange, innerIndex: number) => (
                        <WrapItem
                          id={`suggested-time-${outerIndex}-${innerIndex}`}
                          cursor={'pointer'}
                          bg={
                            time.dateTime.isSame(
                              draftAppointment?.startTime,
                              'date'
                            ) &&
                            time.start ===
                              dayjs(draftAppointment?.startTime)
                                .tz(calendarTimeZone)
                                .format('hh:mm A z')
                              ? 'brand.primary'
                              : 'white'
                          }
                          color={
                            time.dateTime.isSame(
                              draftAppointment?.startTime,
                              'date'
                            ) &&
                            time.start ===
                              dayjs(draftAppointment?.startTime)
                                .tz(calendarTimeZone)
                                .format('hh:mm A z')
                              ? 'white'
                              : 'brand.primary'
                          }
                          _hover={{
                            bg: 'brand.primary',
                            color: 'white',
                          }}
                          rounded="md"
                          borderWidth={2}
                          borderColor={'brand.primary'}
                          p={2}
                          onClick={() => {
                            draftAppointment?.setStartTime(
                              dayjs.tz(time.dateTime).utc().toISOString()
                            );
                            setShow(false);
                          }}
                        >
                          <Text fontSize="sm">{time.start}</Text>
                        </WrapItem>
                      )
                    )}
                  </Wrap>
                </Stack>
              ))}
            </Box>
          ) : (
            <Stack pt={2}>
              <Text fontSize="sm">
                This provider doesn't have any availability over the next 7
                days, please try a later date.
              </Text>
            </Stack>
          )}
        </Box>
      </Collapse>
    </Stack>
  );
});

export default AppointmentManualDate;
