import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type ListFlowsheetParametersQueryVariables = Types.Exact<{
  limit?: Types.Maybe<Types.Scalars['Int']>;
  offset?: Types.Maybe<Types.Scalars['Int']>;
  orderBy?: Types.Maybe<Array<Types.FlowsheetParameter_Order_By> | Types.FlowsheetParameter_Order_By>;
  where?: Types.Maybe<Types.FlowsheetParameter_Bool_Exp>;
}>;


export type ListFlowsheetParametersQuery = { __typename?: 'query_root', flowsheetParameter_aggregate: { __typename?: 'flowsheetParameter_aggregate', aggregate?: { __typename?: 'flowsheetParameter_aggregate_fields', count: number } | null | undefined }, flowsheetParameter: Array<{ __typename?: 'flowsheetParameter', apiKey: string, id: any, isSystemParameter: boolean, title: string, type: string, unit: string }> };


export const ListFlowsheetParametersDocument = gql`
    query ListFlowsheetParameters($limit: Int, $offset: Int = 0, $orderBy: [flowsheetParameter_order_by!] = [{title: asc}], $where: flowsheetParameter_bool_exp) {
  flowsheetParameter_aggregate(where: $where) {
    aggregate {
      count
    }
  }
  flowsheetParameter(
    limit: $limit
    offset: $offset
    order_by: $orderBy
    where: $where
  ) {
    apiKey
    id
    isSystemParameter
    title
    type
    unit
  }
}
    `;

/**
 * __useListFlowsheetParametersQuery__
 *
 * To run a query within a React component, call `useListFlowsheetParametersQuery` and pass it any options that fit your needs.
 * When your component renders, `useListFlowsheetParametersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListFlowsheetParametersQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      orderBy: // value for 'orderBy'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useListFlowsheetParametersQuery(baseOptions?: Apollo.QueryHookOptions<ListFlowsheetParametersQuery, ListFlowsheetParametersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListFlowsheetParametersQuery, ListFlowsheetParametersQueryVariables>(ListFlowsheetParametersDocument, options);
      }
export function useListFlowsheetParametersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListFlowsheetParametersQuery, ListFlowsheetParametersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListFlowsheetParametersQuery, ListFlowsheetParametersQueryVariables>(ListFlowsheetParametersDocument, options);
        }
export type ListFlowsheetParametersQueryHookResult = ReturnType<typeof useListFlowsheetParametersQuery>;
export type ListFlowsheetParametersLazyQueryHookResult = ReturnType<typeof useListFlowsheetParametersLazyQuery>;
export type ListFlowsheetParametersQueryResult = Apollo.QueryResult<ListFlowsheetParametersQuery, ListFlowsheetParametersQueryVariables>;