import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { SocialMediaPostFieldsFragmentDoc } from '../../fragments/socialMediaPostFields.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type UpdateSocialMediaPostByPkMutationVariables = Types.Exact<{
  set?: Types.Maybe<Types.SocialMediaPost_Set_Input>;
  id: Types.Scalars['uuid'];
}>;


export type UpdateSocialMediaPostByPkMutation = { __typename?: 'mutation_root', update_socialMediaPost_by_pk?: { __typename?: 'socialMediaPost', id: any, title: string, caption?: string | null | undefined, isScheduled: boolean, intendedPostAt: any, privatePhoto?: { __typename?: 'photo', id: any, workspaceId: any, patientId?: any | null | undefined, mediaDate?: any | null | undefined, mediaType?: string | null | undefined, mimeType?: string | null | undefined, hasThumbnail?: boolean | null | undefined, isSharedWithPatient: boolean, partialMediaConsent?: boolean | null | undefined, fullMediaConsent?: boolean | null | undefined, patient?: { __typename?: 'patient', firstName: string, id: any, lastName: string } | null | undefined, file?: { __typename?: 'FilePayload', filePath: string, url: string, thumbnailUrl?: string | null | undefined } | null | undefined, photoMediaTags: Array<{ __typename?: 'photoMediaTag', id: any, mediaTag: { __typename?: 'mediaTag', id: any, title: string } }>, uploader?: { __typename?: 'user', firstName: string, id: any, lastName: string } | null | undefined } | null | undefined, socialMediaAccountPosts: Array<{ __typename?: 'socialMediaAccountPost', status: Types.PostStatus_Enum, likeCount?: number | null | undefined, commentCount?: number | null | undefined, socialMediaAccount: { __typename?: 'socialMediaAccount', id: any, name: string, profilePicture?: string | null | undefined, platform: Types.SocialMediaPlatform_Enum } }> } | null | undefined };


export const UpdateSocialMediaPostByPkDocument = gql`
    mutation UpdateSocialMediaPostByPk($set: socialMediaPost_set_input, $id: uuid!) {
  update_socialMediaPost_by_pk(_set: $set, pk_columns: {id: $id}) {
    ...SocialMediaPostFields
  }
}
    ${SocialMediaPostFieldsFragmentDoc}`;
export type UpdateSocialMediaPostByPkMutationFn = Apollo.MutationFunction<UpdateSocialMediaPostByPkMutation, UpdateSocialMediaPostByPkMutationVariables>;

/**
 * __useUpdateSocialMediaPostByPkMutation__
 *
 * To run a mutation, you first call `useUpdateSocialMediaPostByPkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSocialMediaPostByPkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSocialMediaPostByPkMutation, { data, loading, error }] = useUpdateSocialMediaPostByPkMutation({
 *   variables: {
 *      set: // value for 'set'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateSocialMediaPostByPkMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSocialMediaPostByPkMutation, UpdateSocialMediaPostByPkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSocialMediaPostByPkMutation, UpdateSocialMediaPostByPkMutationVariables>(UpdateSocialMediaPostByPkDocument, options);
      }
export type UpdateSocialMediaPostByPkMutationHookResult = ReturnType<typeof useUpdateSocialMediaPostByPkMutation>;
export type UpdateSocialMediaPostByPkMutationResult = Apollo.MutationResult<UpdateSocialMediaPostByPkMutation>;
export type UpdateSocialMediaPostByPkMutationOptions = Apollo.BaseMutationOptions<UpdateSocialMediaPostByPkMutation, UpdateSocialMediaPostByPkMutationVariables>;