import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type UpdateLocationProvidersMutationVariables = Types.Exact<{
  where: Types.LocationProvider_Bool_Exp;
  objects: Array<Types.LocationProvider_Insert_Input> | Types.LocationProvider_Insert_Input;
}>;


export type UpdateLocationProvidersMutation = { __typename?: 'mutation_root', delete_locationProvider?: { __typename?: 'locationProvider_mutation_response', affected_rows: number } | null | undefined, insert_locationProvider?: { __typename?: 'locationProvider_mutation_response', affected_rows: number } | null | undefined };


export const UpdateLocationProvidersDocument = gql`
    mutation updateLocationProviders($where: locationProvider_bool_exp!, $objects: [locationProvider_insert_input!]!) {
  delete_locationProvider(where: $where) {
    affected_rows
  }
  insert_locationProvider(objects: $objects) {
    affected_rows
  }
}
    `;
export type UpdateLocationProvidersMutationFn = Apollo.MutationFunction<UpdateLocationProvidersMutation, UpdateLocationProvidersMutationVariables>;

/**
 * __useUpdateLocationProvidersMutation__
 *
 * To run a mutation, you first call `useUpdateLocationProvidersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLocationProvidersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLocationProvidersMutation, { data, loading, error }] = useUpdateLocationProvidersMutation({
 *   variables: {
 *      where: // value for 'where'
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useUpdateLocationProvidersMutation(baseOptions?: Apollo.MutationHookOptions<UpdateLocationProvidersMutation, UpdateLocationProvidersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateLocationProvidersMutation, UpdateLocationProvidersMutationVariables>(UpdateLocationProvidersDocument, options);
      }
export type UpdateLocationProvidersMutationHookResult = ReturnType<typeof useUpdateLocationProvidersMutation>;
export type UpdateLocationProvidersMutationResult = Apollo.MutationResult<UpdateLocationProvidersMutation>;
export type UpdateLocationProvidersMutationOptions = Apollo.BaseMutationOptions<UpdateLocationProvidersMutation, UpdateLocationProvidersMutationVariables>;