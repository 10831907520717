import React, { useState, useCallback } from 'react';
import { Photo, useInsertPhotoMutation } from '@webapp/graphql';
import { useDropzone } from 'react-dropzone';
import {
  Center,
  useColorModeValue,
  SimpleGrid,
  Box,
  Stack,
  Heading,
  useDisclosure,
  Progress,
  Spinner,
} from '@chakra-ui/react';
import { AiFillCamera, AiFillFileAdd, AiFillTablet } from 'react-icons/ai';

import { useStores, PhotoModel } from '@webapp/state-models';
import dayjs, { Dayjs } from 'dayjs';
import loadImage from 'blueimp-load-image';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import Queue from 'promise-queue';
import { TakePhoto } from '@webapp/webapp/ui-composites';
import { useFlagsmith } from 'flagsmith-react';
import { uploadToS3 } from './uploadToS3';
import GhostIcon from './ghost-icon';

export * from './uploadToS3';

dayjs.extend(customParseFormat);

interface UploadPhotoProps {
  appointmentId?: string;
  onComplete?: (photo: Photo) => void;
  patientId?: string;
}

function extractDateFromMetadata(metadata: any): Dayjs {
  try {
    if (metadata?.exif) {
      const exifDate = metadata?.exif?.['306'];
      if (exifDate) return dayjs(exifDate, 'YYYY:MM:DD');
      return dayjs();
    }
  } catch (err) {
    return dayjs();
  }

  return dayjs();
}

export function UploadPhoto({
  appointmentId,
  onComplete,
  patientId,
}: UploadPhotoProps) {
  const { hasFeature } = useFlagsmith();
  const {
    workspace,
    clearDraftPhotos,
    appendToDraftPhotos,
    setBulkFilesUploading,
  } = useStores();
  const [files, setFiles] = useState([]);
  const [insertPhoto] = useInsertPhotoMutation({
    refetchQueries: ['listPhotos'],
  });
  const {
    isOpen: isTakePhotoModalOpen,
    onOpen: openTakePhotoModal,
    onClose: closeTakePhotoModal,
  } = useDisclosure();
  const [shouldSelectOverlayPhoto, setShouldSelectOverlayPhoto] =
    useState(false);

  const [progress, setProgress] = useState(0);

  async function uploadImage(file: File): Promise<Photo | null> {
    const metadata = await new Promise((resolve) =>
      // eslint-disable-next-line no-promise-executor-return
      loadImage.parseMetaData(
        file,
        (data) => {
          resolve(data);
        },
        {
          maxMetaDataSize: 262144,
        }
      )
    );

    const filePath = await uploadToS3({
      fileType: file.type || '',
      fileContents: file || '',
      onProgress: setProgress,
    });

    const mediaDate = extractDateFromMetadata(metadata);

    if (workspace && filePath && mediaDate) {
      const { data } = await insertPhoto({
        variables: {
          photo: {
            ...(appointmentId && { appointmentId }),
            ...(patientId && { patientId }),
            workspaceId: workspace.id,
            filePath,
            mediaDate: mediaDate.toISOString(),
            mimeType: file.type,
          },
        },
      });

      return data?.insert_photo?.returning[0] as Photo;
    }
    return null;
  }

  const onDrop = useCallback(async (acceptedFiles: any) => {
    clearDraftPhotos();
    if (acceptedFiles && acceptedFiles.length > 0) {
      const queue = new Queue(1);
      setBulkFilesUploading(true);
      acceptedFiles.forEach((file: File) =>
        queue.add(async () => {
          const photo = await uploadImage(file);

          if (photo && onComplete) {
            onComplete(photo);
          }

          if (photo) {
            appendToDraftPhotos(PhotoModel.create(photo));
          }
        })
      );
      queue.add(async () => {
        setBulkFilesUploading(false);
      });
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: ['image/*', 'video/*'],
    maxFiles: 50,
    multiple: true,
  });

  const dropText = isDragActive
    ? 'Drop the files here ...'
    : "Drag 'n' drop file here, or click to select files";

  const activeBg = useColorModeValue('gray.100', 'gray.600');
  const borderColor = useColorModeValue(
    isDragActive ? 'teal.300' : 'gray.300',
    isDragActive ? 'teal.500' : 'gray.500'
  );

  function openProspyrProCamera() {
    const url = new URL('prospyr://camera');
    if (patientId) {
      url.searchParams.append('patientId', patientId);
    }
    if (appointmentId) {
      url.searchParams.append('appointmentId', appointmentId);
    }
    if (workspace?.id) {
      url.searchParams.append('workspaceId', workspace.id);
    }

    window.open(url.toString());
  }

  return (
    <>
      <SimpleGrid columns={2} spacing={10}>
        <Box borderRadius={4} border="3px dashed" borderColor={borderColor}>
          {progress === 0 && (
            <Center
              py={20}
              w={'full'}
              h={'full'}
              cursor="pointer"
              bg={isDragActive ? activeBg : 'transparent'}
              _hover={{ bg: activeBg }}
              transition="background-color 0.2s ease"
              {...getRootProps()}
            >
              <input {...getInputProps()} />

              <Stack spacing={4} alignItems="center">
                <AiFillFileAdd size={'60px'} />
                <Heading size="xs" textAlign={'center'}>
                  {dropText}
                </Heading>
              </Stack>
            </Center>
          )}

          {progress > 0 && (
            <Center p={10}>
              <Spinner size="lg" />
              <Progress value={progress} h={6} />
            </Center>
          )}
        </Box>
        <Center
          onClick={() => {
            setShouldSelectOverlayPhoto(true);
            openTakePhotoModal();
          }}
          cursor="pointer"
          borderRadius={4}
          border="3px solid"
          borderColor={'gray.300'}
          py={20}
        >
          <Stack spacing={4} alignItems="center">
            <GhostIcon />
            <Heading size="xs">Take a ghost photo</Heading>
          </Stack>
        </Center>
        <Center
          onClick={() => {
            setShouldSelectOverlayPhoto(false);
            openTakePhotoModal();
          }}
          cursor="pointer"
          borderRadius={4}
          border="3px solid"
          borderColor={'gray.300'}
          py={20}
        >
          <Stack spacing={4} alignItems="center">
            <AiFillCamera size="60px" />
            <Heading size="xs">Take multiple photos</Heading>
          </Stack>
        </Center>
        {hasFeature('camera:mobile-app') && (
          <Center
            onClick={() => {
              openProspyrProCamera();
            }}
            cursor="pointer"
            borderRadius={4}
            border="3px solid"
            borderColor={'gray.300'}
            py={20}
          >
            <Stack spacing={4} alignItems="center">
              <AiFillTablet size="60px" />
              <Heading size="xs">Take photos in Prospyr Pro Camera</Heading>
            </Stack>
          </Center>
        )}
      </SimpleGrid>
      {isTakePhotoModalOpen && (
        <TakePhoto
          isOpen={isTakePhotoModalOpen}
          onClose={closeTakePhotoModal}
          shouldSelectOverlayPhoto={shouldSelectOverlayPhoto}
          patientId={patientId}
          appointmentId={appointmentId}
        />
      )}
    </>
  );
}

export default UploadPhoto;
