import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type VerifyManagerPinQueryVariables = Types.Exact<{
  pin: Types.Scalars['String'];
  workspaceId: Types.Scalars['uuid'];
}>;


export type VerifyManagerPinQuery = { __typename?: 'query_root', verifyManagerPin?: { __typename?: 'SuccessAnswer', success: boolean } | null | undefined };


export const VerifyManagerPinDocument = gql`
    query VerifyManagerPin($pin: String!, $workspaceId: uuid!) {
  verifyManagerPin(value: $pin, workspaceId: $workspaceId) {
    success
  }
}
    `;

/**
 * __useVerifyManagerPinQuery__
 *
 * To run a query within a React component, call `useVerifyManagerPinQuery` and pass it any options that fit your needs.
 * When your component renders, `useVerifyManagerPinQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVerifyManagerPinQuery({
 *   variables: {
 *      pin: // value for 'pin'
 *      workspaceId: // value for 'workspaceId'
 *   },
 * });
 */
export function useVerifyManagerPinQuery(baseOptions: Apollo.QueryHookOptions<VerifyManagerPinQuery, VerifyManagerPinQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VerifyManagerPinQuery, VerifyManagerPinQueryVariables>(VerifyManagerPinDocument, options);
      }
export function useVerifyManagerPinLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VerifyManagerPinQuery, VerifyManagerPinQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VerifyManagerPinQuery, VerifyManagerPinQueryVariables>(VerifyManagerPinDocument, options);
        }
export type VerifyManagerPinQueryHookResult = ReturnType<typeof useVerifyManagerPinQuery>;
export type VerifyManagerPinLazyQueryHookResult = ReturnType<typeof useVerifyManagerPinLazyQuery>;
export type VerifyManagerPinQueryResult = Apollo.QueryResult<VerifyManagerPinQuery, VerifyManagerPinQueryVariables>;