import * as Types from '../../generated/graphql';

import { gql } from '@apollo/client';
export type CustomQuestionFieldsFragment = { __typename?: 'customQuestion', id: any, label: string, body?: string | null | undefined, photoId?: any | null | undefined, categoryValueAndDescription?: { __typename?: 'customFormFieldCategory', value: string, description: string } | null | undefined, typeValueAndDescription: { __typename?: 'customFormFieldType', value: string, description: string }, options: Array<{ __typename?: 'customQuestionOption', value: string }>, photo?: { __typename?: 'photo', file?: { __typename?: 'FilePayload', url: string } | null | undefined } | null | undefined };

export const CustomQuestionFieldsFragmentDoc = gql`
    fragment CustomQuestionFields on customQuestion {
  id
  label
  body
  categoryValueAndDescription {
    value
    description
  }
  typeValueAndDescription {
    value
    description
  }
  options {
    value
  }
  photoId
  photo {
    file {
      url
    }
  }
}
    `;