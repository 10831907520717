import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { AddressFieldsFragmentDoc } from '../../fragments/addressFields.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type InsertAddressMutationVariables = Types.Exact<{
  address: Types.Address_Insert_Input;
}>;


export type InsertAddressMutation = { __typename?: 'mutation_root', insert_address?: { __typename?: 'address_mutation_response', returning: Array<{ __typename?: 'address', addressLine1: string, addressLine2?: string | null | undefined, city: string, id: any, state: string, zipCode: string }> } | null | undefined };


export const InsertAddressDocument = gql`
    mutation insertAddress($address: address_insert_input!) {
  insert_address(objects: [$address]) {
    returning {
      ...AddressFields
    }
  }
}
    ${AddressFieldsFragmentDoc}`;
export type InsertAddressMutationFn = Apollo.MutationFunction<InsertAddressMutation, InsertAddressMutationVariables>;

/**
 * __useInsertAddressMutation__
 *
 * To run a mutation, you first call `useInsertAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertAddressMutation, { data, loading, error }] = useInsertAddressMutation({
 *   variables: {
 *      address: // value for 'address'
 *   },
 * });
 */
export function useInsertAddressMutation(baseOptions?: Apollo.MutationHookOptions<InsertAddressMutation, InsertAddressMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertAddressMutation, InsertAddressMutationVariables>(InsertAddressDocument, options);
      }
export type InsertAddressMutationHookResult = ReturnType<typeof useInsertAddressMutation>;
export type InsertAddressMutationResult = Apollo.MutationResult<InsertAddressMutation>;
export type InsertAddressMutationOptions = Apollo.BaseMutationOptions<InsertAddressMutation, InsertAddressMutationVariables>;