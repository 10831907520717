import * as Types from '../../generated/graphql';

import { gql } from '@apollo/client';
export type ResolvedAppointmentConsumablesDataFieldsFragment = { __typename?: 'GetAppointmentConsumablesDataOutput', hasToResolve: boolean, hasCanUpdate: boolean, hasMarkedUnused: boolean, markedUnused: Array<{ __typename?: 'AppointmentServiceConsumableTypeMarkedUnused', text: string, unusedAppointmentServiceConsumableTypeId: any }>, toResolve: Array<{ __typename?: 'AppointmentServiceConsumableTypeToResolve', appointmentServiceId: any, text: string, quantity: number, service: { __typename?: 'MinimalResolvedService', id: any, name: string }, type: { __typename?: 'MinimalResolvedConsumableType', id: any, name: string } }>, canUpdate: Array<{ __typename?: 'AppointmentServiceConsumableCanUpdate', appointmentServiceId: any, text: string, quantity: number, service: { __typename?: 'MinimalResolvedService', id: any, name: string }, consumable: { __typename?: 'MinimalResolvedConsumable', id: any, metadata: any, brand: { __typename?: 'MinimalResolvedConsumableBrand', id: any, name: string }, type: { __typename?: 'MinimalResolvedConsumableType', id: any, name: string } } }> };

export const ResolvedAppointmentConsumablesDataFieldsFragmentDoc = gql`
    fragment ResolvedAppointmentConsumablesDataFields on GetAppointmentConsumablesDataOutput {
  hasToResolve
  hasCanUpdate
  hasMarkedUnused
  markedUnused {
    text
    unusedAppointmentServiceConsumableTypeId
  }
  toResolve {
    appointmentServiceId
    text
    quantity
    service {
      id
      name
    }
    type {
      id
      name
    }
  }
  canUpdate {
    appointmentServiceId
    text
    quantity
    service {
      id
      name
    }
    consumable {
      id
      metadata
      brand {
        id
        name
      }
      type {
        id
        name
      }
    }
  }
}
    `;