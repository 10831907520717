import { Text } from '@chakra-ui/react';
import { FormInput } from '@webapp/ui';
import { toLocalePrice } from '@webapp/util-formatting';
import { useWatch } from 'react-hook-form';

interface PriceCellProps {
  index: number;
}

function PriceCell({ index }: PriceCellProps) {
  const name = `services[${index}][object][pricePerUnit]`;

  const services = useWatch({ name: 'services' });

  const { pricePerUnit, pricingModel } = services[index].object;

  if (pricingModel === 'custom') {
    return (
      <FormInput
        changeMode="onBlur"
        defaultValue={pricePerUnit / 100}
        key={`service-${index}-${services[index].value}`}
        min={0}
        name={name}
        type="currency"
      />
    );
  }

  return <Text>{toLocalePrice(pricePerUnit / 100)}</Text>;
}

export default PriceCell;
