import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { ServiceUnitFieldsFragmentDoc } from '../../fragments/serviceUnitFields.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type InsertServiceUnitMutationVariables = Types.Exact<{
  serviceUnit: Types.ServiceUnit_Insert_Input;
}>;


export type InsertServiceUnitMutation = { __typename?: 'mutation_root', insert_serviceUnit?: { __typename?: 'serviceUnit_mutation_response', returning: Array<{ __typename?: 'serviceUnit', id: any, name: string }> } | null | undefined };


export const InsertServiceUnitDocument = gql`
    mutation insertServiceUnit($serviceUnit: serviceUnit_insert_input!) {
  insert_serviceUnit(objects: [$serviceUnit]) {
    returning {
      ...ServiceUnitFields
    }
  }
}
    ${ServiceUnitFieldsFragmentDoc}`;
export type InsertServiceUnitMutationFn = Apollo.MutationFunction<InsertServiceUnitMutation, InsertServiceUnitMutationVariables>;

/**
 * __useInsertServiceUnitMutation__
 *
 * To run a mutation, you first call `useInsertServiceUnitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertServiceUnitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertServiceUnitMutation, { data, loading, error }] = useInsertServiceUnitMutation({
 *   variables: {
 *      serviceUnit: // value for 'serviceUnit'
 *   },
 * });
 */
export function useInsertServiceUnitMutation(baseOptions?: Apollo.MutationHookOptions<InsertServiceUnitMutation, InsertServiceUnitMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertServiceUnitMutation, InsertServiceUnitMutationVariables>(InsertServiceUnitDocument, options);
      }
export type InsertServiceUnitMutationHookResult = ReturnType<typeof useInsertServiceUnitMutation>;
export type InsertServiceUnitMutationResult = Apollo.MutationResult<InsertServiceUnitMutation>;
export type InsertServiceUnitMutationOptions = Apollo.BaseMutationOptions<InsertServiceUnitMutation, InsertServiceUnitMutationVariables>;