import * as Types from '../../generated/graphql';

import { gql } from '@apollo/client';
export type PatientTagsFieldsFragment = { __typename?: 'patientTags', id: any, patientTag: { __typename?: 'patientTag', id: any, title: string, color?: string | null | undefined } };

export const PatientTagsFieldsFragmentDoc = gql`
    fragment PatientTagsFields on patientTags {
  id
  patientTag {
    id
    title
    color
  }
}
    `;