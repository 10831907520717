/* eslint-disable import/no-cycle */
import {
  PatientFlowsheetsQuery,
  useGetMinimalPatientQuery,
  usePatientFlowsheetsLazyQuery,
} from '@webapp/graphql';
import { ColDef } from 'ag-grid-community';
import { CellEditor, CompoundControlledFormInputProps } from '@webapp/ui';
import { useEffect, useState } from 'react';
import { FieldValues } from 'react-hook-form';
import { configuredDayjs } from '@webapp/util-time';
import { transformColDefsToFormInputs } from '@webapp/util-transforms';
import usePatientRowMutations from '../usePatientRowMutations/usePatientRowMutations';

export type FlowsheetData = {
  formInputs: Omit<CompoundControlledFormInputProps, 'control'>[];
  id: string;
  gridData: { colDefs: ColDef<any>[]; data: FieldValues[] };
  rawData: any;
  title: string;
};

export function usePatientFlowsheets({ patientId }: { patientId: string }) {
  const [flowsheets, selectFlowsheets] = useState<FlowsheetData[] | null>(null);

  const [selectedFlowsheet, selectFlowsheet] = useState<FlowsheetData | null>(
    null
  );

  const { data: patientData, loading: patientLoading } =
    useGetMinimalPatientQuery({
      variables: {
        id: patientId,
      },
      skip: !patientId,
    });

  const patientWorkspaceId = patientData?.patient_by_pk?.attributes.id;

  const {
    deleteData,
    deleteRow,
    deleteRows,
    insertRow,
    insertRowData,
    updateData,
    insertRowLoading,
  } = usePatientRowMutations({
    patientWorkspaceId,
    flowsheet: selectedFlowsheet,
  });

  const [getFlowsheets, { loading }] = usePatientFlowsheetsLazyQuery({
    onCompleted: (data) => {
      const transformedFlowsheets = data?.flowsheet.map((flowsheet) => {
        const parameters = flowsheet?.parameters || [];

        const colDefs: ColDef<any>[] = parameters.reduce(
          (acc, parameter) => [
            ...acc,
            {
              cellEditor: CellEditor,
              cellEditorPopup: true,
              cellEditorParams: {
                onDelete: deleteData,
                inputProps: {
                  ...(['currency', 'fraction', 'number'].includes(
                    parameter.parameter.type
                  ) && {
                    hasIncrement: false,
                  }),
                  name: parameter.parameter.id,
                  precision: 2,
                  type: parameter.parameter.type,
                  unit: parameter.parameter.unit,
                },
                isPopup: true,
              },
              editable: true,
              field: parameter.parameter.id,
              headerName: parameter.parameter.title,
              minWidth: 200,
              sortable: true,
              valueFormatter: (params) => {
                const value = params.data[parameter.parameter.id];

                if (value) {
                  return `${value} ${parameter.parameter.unit}`;
                }

                return '';
              },
            },
          ],
          [
            {
              field: 'flowsheetId',
              headerName: 'Flowsheet ID',
              hide: true,
            },
            {
              field: 'row',
              headerName: 'Row ID',
              valueFormatter: (params) => params.data.row.id,
              hide: true,
            },
            {
              cellEditor: CellEditor,
              cellEditorPopup: true,
              cellEditorParams: {
                inputProps: {
                  name: 'recordedAt',
                  type: 'datetime',
                },
                isPopup: true,
              },
              editable: true,
              field: 'recordedAt',
              minWidth: 250,
              sortable: true,
              valueFormatter: (params) => {
                if (params.data.recordedAt) {
                  return configuredDayjs(
                    new Date(params.data.recordedAt)
                  ).format('MM/DD/YYYY h:mm A');
                }

                return '';
              },
            },
          ] as ColDef<any>[]
        );

        const rowData = flowsheet.patientRows.map((row) => {
          const dataMap = row.data.reduce(
            (acc, datum) => ({
              ...acc,
              [datum.parameter.id]: datum.value,
            }),
            {
              recordedAt: row.recordedAt,
            } as Record<string, string>
          );

          const newDatum = colDefs.reduce(
            (acc, col) => {
              if (col.field && dataMap[col.field]) {
                return {
                  ...acc,
                  [col.field]: dataMap[col.field],
                };
              }

              return acc;
            },
            {
              flowsheetId: flowsheet.id,
              row,
            } as Record<keyof typeof colDefs, string> & {
              flowsheetId: string;
              recordedAt: string | null;
              row: PatientFlowsheetsQuery['flowsheet'][number]['patientRows'][number];
            }
          );

          return newDatum;
        });

        return {
          formInputs: transformColDefsToFormInputs(colDefs),
          id: flowsheet.id,
          gridData: {
            colDefs,
            data: rowData,
          },
          rawData: flowsheet,
          title: flowsheet.title,
        };
      });

      selectFlowsheets(transformedFlowsheets);
    },
    variables: {
      patientWorkspaceId,
    },
  });

  useEffect(() => {
    if (patientWorkspaceId) {
      getFlowsheets();
    }
  }, [getFlowsheets, patientWorkspaceId]);

  useEffect(() => {
    const [firstFlowsheet] = flowsheets || [];

    if (firstFlowsheet && !selectedFlowsheet) {
      selectFlowsheet(firstFlowsheet);
    } else if (flowsheets && selectedFlowsheet) {
      const updatedFlowsheet = flowsheets.find(
        (flowsheet) => flowsheet.id === selectedFlowsheet.id
      );

      if (updatedFlowsheet) {
        selectFlowsheet(updatedFlowsheet);
      }
    }
  }, [selectedFlowsheet, flowsheets]);

  return {
    deleteRow,
    deleteRows,
    flowsheets,
    insertRow,
    insertRowData,
    isInitialized: Boolean(patientWorkspaceId) && !patientLoading,
    isLoading: loading,
    patientWorkspaceId,
    selectFlowsheet,
    selectedFlowsheet,
    updateData,
    insertRowLoading,
    getFlowsheets,
  };
}

export default usePatientFlowsheets;
