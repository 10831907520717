import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { PatientAddressFieldsFragmentDoc } from '../../fragments/patientAddressFields.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type SetBillingPatientAddressMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
}>;


export type SetBillingPatientAddressMutation = { __typename?: 'mutation_root', setBillingPatientAddress?: Array<{ __typename?: 'SetBillingPatientAddressOutput', patientAddress?: { __typename?: 'patientAddress', id: any, isDefault: boolean, type: string, status: string, address: { __typename?: 'address', addressLine1: string, addressLine2?: string | null | undefined, city: string, id: any, state: string, zipCode: string } } | null | undefined }> | null | undefined };


export const SetBillingPatientAddressDocument = gql`
    mutation SetBillingPatientAddress($id: uuid!) {
  setBillingPatientAddress(patientAddressId: $id) {
    patientAddress {
      ...PatientAddressFields
    }
  }
}
    ${PatientAddressFieldsFragmentDoc}`;
export type SetBillingPatientAddressMutationFn = Apollo.MutationFunction<SetBillingPatientAddressMutation, SetBillingPatientAddressMutationVariables>;

/**
 * __useSetBillingPatientAddressMutation__
 *
 * To run a mutation, you first call `useSetBillingPatientAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetBillingPatientAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setBillingPatientAddressMutation, { data, loading, error }] = useSetBillingPatientAddressMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSetBillingPatientAddressMutation(baseOptions?: Apollo.MutationHookOptions<SetBillingPatientAddressMutation, SetBillingPatientAddressMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetBillingPatientAddressMutation, SetBillingPatientAddressMutationVariables>(SetBillingPatientAddressDocument, options);
      }
export type SetBillingPatientAddressMutationHookResult = ReturnType<typeof useSetBillingPatientAddressMutation>;
export type SetBillingPatientAddressMutationResult = Apollo.MutationResult<SetBillingPatientAddressMutation>;
export type SetBillingPatientAddressMutationOptions = Apollo.BaseMutationOptions<SetBillingPatientAddressMutation, SetBillingPatientAddressMutationVariables>;