import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { InventoryFieldsFragmentDoc } from '../../fragments/inventoryFields.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetInventoryQueryVariables = Types.Exact<{
  input: Types.GetInventoryInput;
}>;


export type GetInventoryQuery = { __typename?: 'query_root', getInventory: { __typename?: 'GetInventoryOutput', count: number, inventory: Array<{ __typename?: 'InventoryItem', account: any, quantity: number, createdAt: string, updatedAt: string, consumable: { __typename?: 'FullConsumable', id: any, originalQuantity: number, quantity: number, metadata: any, cost: number, isExpirable: boolean, expirationDate?: string | null | undefined, daysBeforeNotification?: number | null | undefined, shouldWarnQuantity: boolean, warningQuantity?: number | null | undefined, archived: boolean, createdAt: string, updatedAt: string, type: { __typename?: 'IdNameRequiredFields', id: any, name: string, requiredFields: any, unitStep: number, unitName: string, defaultNumberOfUnits: number, defaultPricePerUnit: number }, brand: { __typename?: 'IdName', id: any, name: string }, purchaseOrder?: { __typename?: 'IdOrderNumber', id: any, orderNumber: string } | null | undefined }, metadata: { __typename?: 'InventoryItemMetadata', stockAlert: boolean, expiration: { __typename?: 'InventoryMetadataExpiration', status: string, daysUntilExpiration: number } } }> } };


export const GetInventoryDocument = gql`
    query GetInventory($input: GetInventoryInput!) {
  getInventory(input: $input) {
    inventory {
      ...InventoryFields
    }
    count
  }
}
    ${InventoryFieldsFragmentDoc}`;

/**
 * __useGetInventoryQuery__
 *
 * To run a query within a React component, call `useGetInventoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInventoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInventoryQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetInventoryQuery(baseOptions: Apollo.QueryHookOptions<GetInventoryQuery, GetInventoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInventoryQuery, GetInventoryQueryVariables>(GetInventoryDocument, options);
      }
export function useGetInventoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInventoryQuery, GetInventoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInventoryQuery, GetInventoryQueryVariables>(GetInventoryDocument, options);
        }
export type GetInventoryQueryHookResult = ReturnType<typeof useGetInventoryQuery>;
export type GetInventoryLazyQueryHookResult = ReturnType<typeof useGetInventoryLazyQuery>;
export type GetInventoryQueryResult = Apollo.QueryResult<GetInventoryQuery, GetInventoryQueryVariables>;