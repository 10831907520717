import { Switch } from '@chakra-ui/react';
import NumberInput from '../NumberInput';
import TextInput from '../TextInput';
import { CompoundControlledFormInputProps } from '../types';
import CurrencyInput from '../CurrencyInput';
import SelectInput from '../SelectInput';
import CheckboxInput from '../CheckboxInput';
import MultiSelectInput from '../MultiSelectFormInput';
import AsyncSelectInput from '../AsyncSelectInput';
import RadioInput from '../RadioInput';
import getInputValue from './getInputValue';
import AsyncMultiSelectInput from '../AsyncMultiSelectInput';
import DateInput from '../DateInput';
import renderReadOnlyText from './renderReadOnlyText';
import PercentInput from '../PercentInput';
import FractionInput from '../FractionInput';
import DateTimeInput from '../DateTimeInput';
import TextAreaInput from '../TextAreaInput';
import AddressInput from '../AddressInput';
import PlacesInput from '../PlacesInput';
import TextEditorInput from '../TextEditorInput';

function renderControlledInput(
  { isEditable = true, ...props }: CompoundControlledFormInputProps,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  field: { onChange: any; value: any }
) {
  if (!isEditable) {
    const value = getInputValue({ ...props, value: field.value });
    return renderReadOnlyText({ type: props.type, value });
  }

  if (props.type === 'address') {
    const { control, ...inputProps } = props;

    return (
      <AddressInput
        onChange={field.onChange}
        value={
          field.value || {
            addressLine1: '',
            addressLine2: '',
            city: '',
            formattedAddress: '',
            state: '',
            country: '',
            zipCode: '',
          }
        }
        {...inputProps}
      />
    );
  }

  if (props.type === 'places') {
    const { control, ...inputProps } = props;

    return (
      <PlacesInput
        onChange={field.onChange}
        showFullAddress
        value={
          field.value || {
            addressLine1: '',
            addressLine2: '',
            city: '',
            formattedAddress: '',
            state: '',
            country: '',
            zipCode: '',
          }
        }
        {...inputProps}
      />
    );
  }

  if (props.type === 'date') {
    const { control, ...inputProps } = props;
    return (
      <DateInput
        onChange={field.onChange}
        value={field.value ?? new Date().toUTCString()}
        {...inputProps}
      />
    );
  }

  if (props.type === 'datetime') {
    const { control, ...inputProps } = props;
    return (
      <DateTimeInput
        onChange={field.onChange}
        value={field.value ?? new Date().toUTCString()}
        {...inputProps}
      />
    );
  }

  if (props.type === 'checkbox') {
    const { control, ...inputProps } = props;
    return (
      <CheckboxInput
        onChange={field.onChange}
        value={field.value ?? false}
        {...inputProps}
      />
    );
  }

  if (props.type === 'number') {
    const { control, ...inputProps } = props;
    return (
      <NumberInput
        onChange={(_, numVal) => {
          if (!Number.isNaN(numVal)) {
            field.onChange(numVal);
          } else {
            field.onChange(0);
          }
        }}
        value={field.value ?? 0}
        {...inputProps}
      />
    );
  }

  if (props.type === 'fraction') {
    const { control, ...inputProps } = props;
    return (
      <FractionInput
        onChange={field.onChange}
        value={field.value ?? 0}
        {...inputProps}
      />
    );
  }

  if (props.type === 'currency') {
    const { control, ...inputProps } = props;

    return (
      <CurrencyInput
        onChange={(_, numVal) => {
          if (!Number.isNaN(numVal)) {
            field.onChange(numVal);
          } else {
            field.onChange(0);
          }
        }}
        value={field.value ?? 0}
        {...inputProps}
      />
    );
  }

  if (props.type === 'percent') {
    const { control, ...inputProps } = props;

    return (
      <PercentInput
        onChange={(_, numVal) => {
          if (!Number.isNaN(numVal)) {
            field.onChange(numVal);
          } else {
            field.onChange(0);
          }
        }}
        value={field.value ?? 0}
        {...inputProps}
      />
    );
  }

  if (props.type === 'async-select') {
    const { control, ...inputProps } = props;
    return (
      <AsyncSelectInput
        onChange={field.onChange}
        value={field.value}
        {...inputProps}
      />
    );
  }

  if (props.type === 'async-multiselect') {
    const { control, ...inputProps } = props;
    return (
      <AsyncMultiSelectInput
        onChange={field.onChange}
        value={field.value ?? []}
        {...inputProps}
      />
    );
  }

  if (props.type === 'multiselect') {
    const { options, control, ...inputProps } = props;
    return (
      <MultiSelectInput
        options={options}
        onChange={field.onChange}
        value={field.value ?? []}
        {...inputProps}
      />
    );
  }

  if (props.type === 'select') {
    const { options, control, ...inputProps } = props;
    return (
      <SelectInput
        options={options}
        onChange={field.onChange}
        value={field.value}
        {...inputProps}
      />
    );
  }

  if (props.type === 'switch') {
    const { control, ...inputProps } = props;

    return (
      <Switch
        defaultChecked={field.value || false}
        isChecked={field.value || false}
        onChange={field.onChange}
        value={field.value}
        {...inputProps}
      />
    );
  }

  if (props.type === 'text') {
    const { control, ...inputProps } = props;

    return (
      <TextInput
        onChange={field.onChange}
        value={field.value ?? ''}
        {...inputProps}
      />
    );
  }

  if (props.type === 'radio') {
    const { control, type, ...inputProps } = props;

    return (
      <RadioInput
        onChange={field.onChange}
        value={field.value}
        {...inputProps}
      />
    );
  }

  if (props.type === 'textarea') {
    const { control, ...inputProps } = props;

    return (
      <TextAreaInput
        onChange={field.onChange}
        value={field.value ?? ''}
        {...inputProps}
      />
    );
  }

  if (props.type === 'texteditor') {
    return <TextEditorInput {...field} {...props} />;
  }

  // eslint-disable-next-line no-console
  console.error('Unsupported input type');

  return <div />;
}

export default renderControlledInput;
