import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type DeleteNoteByPkMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
}>;


export type DeleteNoteByPkMutation = { __typename?: 'mutation_root', delete_note_by_pk?: { __typename?: 'note', id: any } | null | undefined };


export const DeleteNoteByPkDocument = gql`
    mutation DeleteNoteByPk($id: uuid!) {
  delete_note_by_pk(id: $id) {
    id
  }
}
    `;
export type DeleteNoteByPkMutationFn = Apollo.MutationFunction<DeleteNoteByPkMutation, DeleteNoteByPkMutationVariables>;

/**
 * __useDeleteNoteByPkMutation__
 *
 * To run a mutation, you first call `useDeleteNoteByPkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteNoteByPkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteNoteByPkMutation, { data, loading, error }] = useDeleteNoteByPkMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteNoteByPkMutation(baseOptions?: Apollo.MutationHookOptions<DeleteNoteByPkMutation, DeleteNoteByPkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteNoteByPkMutation, DeleteNoteByPkMutationVariables>(DeleteNoteByPkDocument, options);
      }
export type DeleteNoteByPkMutationHookResult = ReturnType<typeof useDeleteNoteByPkMutation>;
export type DeleteNoteByPkMutationResult = Apollo.MutationResult<DeleteNoteByPkMutation>;
export type DeleteNoteByPkMutationOptions = Apollo.BaseMutationOptions<DeleteNoteByPkMutation, DeleteNoteByPkMutationVariables>;