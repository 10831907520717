import { Box, chakra, Circle, Grid, Heading } from '@chakra-ui/react';
import React, { PropsWithChildren } from 'react';
import './steps.module.scss';

/* eslint-disable-next-line */
export interface StepsProps {
  activeStep?: number;
  rowGap?: string;
}

export const StepConnector = (props: any) => (
  <Box
    borderStartWidth="1px"
    borderStartColor={'inherit'}
    height="100%"
    margin="0 0 0 6px"
    {...props}
  />
);

function StepHeading({ children, ...props }: PropsWithChildren<unknown>) {
  return (
    <Heading
      color="#919197"
      letterSpacing="0.09em"
      margin="0 0 0 28px"
      size="14px"
      textTransform="uppercase"
      {...props}
    >
      {children}
    </Heading>
  );
}

interface StepProps {
  circleColor?: string;
}

function Step({
  children,
  circleColor = '#919197',
}: PropsWithChildren<StepProps>) {
  return (
    <chakra.div
      display="grid"
      alignItems="center"
      gridAutoFlow="row"
      gridColumnGap="15px"
      gridRowGap="15px"
      gridTemplateColumns="max-content 1fr"
      margin="0 0 0 -0.5px"
      width="100%"
    >
      <Circle
        background={circleColor}
        border={`4px solid #fff`}
        margin={'0 0 0 -4.5px'}
        size={'20px'}
        fontWeight="bold"
      ></Circle>
      <chakra.div
        display="grid"
        alignItems="center"
        gridAutoFlow="column"
        gridColumnGap="12px"
      >
        {children}
      </chakra.div>
    </chakra.div>
  );
}

export function Steps({
  children,
  rowGap = '30px',
}: PropsWithChildren<StepsProps>) {
  const LastConnector = chakra(StepConnector);
  const childArray = React.Children.toArray(children);

  return (
    <chakra.div
      display="grid"
      gridAutoFlow="row"
      gridTemplateRows={`repeat(${childArray.length}, max-content) 1fr`}
      gridTemplateColumns="1px 1fr"
      height="100%"
      width="100%"
      maxW={'100vw'}
    >
      <StepConnector />
      <chakra.div
        display="grid"
        className="step-content"
        gridAutoColumns="1fr"
        gridRowGap={rowGap}
        width="100%"
      >
        {React.Children.toArray(children).map((step, i, arr) => (
          <React.Fragment key={`steps-step-${i}}`}>
            {arr.length !== 1 && i === 0 && <StepConnector />}
            {step}
            {arr.length !== i + 1 && <StepConnector />}
          </React.Fragment>
        ))}
      </chakra.div>
      <LastConnector background="red" className="last-connector" />
    </chakra.div>
  );
}

Steps.StepHeading = StepHeading;
Steps.Step = Step;

export default Steps;
