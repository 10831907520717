/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable no-plusplus */
/* eslint-disable no-promise-executor-return */
type ErrorWithMessage = {
  message: string;
};

export async function getIpAddress(): Promise<string> {
  try {
    const response = await fetch(
      'https://ipapi.co/json?key=pszvLHgOywi5Ay7KaRB5STI3vcgfuddhtw8vAYoqX3OWKw4mQv'
    );
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await response.json();

    return data.ip;
  } catch (error) {
    console.error('Failed to fetch IP address:', error);
    throw new Error('Unable to fetch IP address');
  }
}

function isErrorWithMessage(error: unknown): error is ErrorWithMessage {
  return (
    typeof error === 'object' &&
    error !== null &&
    'message' in error &&
    // @ts-ignore
    typeof (error as Record<string, unknown>).message === 'string'
  );
}

function toErrorWithMessage(maybeError: unknown): ErrorWithMessage {
  if (isErrorWithMessage(maybeError)) return maybeError;

  try {
    return new Error(JSON.stringify(maybeError));
  } catch {
    // fallback in case there's an error stringifying the maybeError
    // like with circular references for example.
    return new Error(String(maybeError));
  }
}

export function getErrorMessage(error: unknown) {
  return toErrorWithMessage(error).message;
}

type BmiCategory =
  | 'Underweight'
  | 'Normal weight'
  | 'Overweight'
  | 'Obesity Class 1'
  | 'Obesity Class 2'
  | 'Obesity Class 3';

interface BmiResult {
  bmi: number;
  category: BmiCategory;
}

export function calculateBmi(
  heightInMeters: number,
  weightInKilograms: number
): BmiResult {
  const bmi = weightInKilograms / (heightInMeters * heightInMeters);
  let category: BmiCategory;

  if (bmi < 18.5) {
    category = 'Underweight';
  } else if (bmi >= 18.5 && bmi < 24.9) {
    category = 'Normal weight';
  } else if (bmi >= 24.9 && bmi < 29.9) {
    category = 'Overweight';
  } else if (bmi >= 29.9 && bmi < 34.9) {
    category = 'Obesity Class 1';
  } else if (bmi >= 34.9 && bmi < 39.9) {
    category = 'Obesity Class 2';
  } else {
    category = 'Obesity Class 3';
  }

  return {
    bmi: parseFloat(bmi.toFixed(2)),
    category,
  };
}

export function heightToMeters(height: number, unit: string): number {
  if (unit === 'cm') {
    return height / 100;
  }
  if (unit === 'in') {
    return height * 0.0254;
  }
  throw new Error(`Unsupported unit: ${unit}`);
}

export function lbsToKilograms(lbs: number): number {
  return lbs * 0.453592;
}

export default getErrorMessage;

export function sleep(ms: number) {
  // add ms millisecond timeout before promise resolution
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function dataURLtoFile(dataurl: string, filename: string) {
  const arr = dataurl.split(',');
  const mime = arr[0].match(/:(.*?);/)?.[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  const blob = new Blob([u8arr], { type: mime });
  return new File([blob], filename, { type: mime });
}

export function validatePhoneNumber(phoneNumber: string): boolean {
  const phoneRegex =
    /^(1\s?)?((\([0-9]{3}\))|[0-9]{3})[\s\-]?[\0-9]{3}[\s\-]?[0-9]{4}$/g;
  return phoneRegex.test(phoneNumber);
}

export function getProxyPhotoUrl(
  photoUrl: string | undefined | null,
  type: 'thumbnail' | 'full' = 'thumbnail'
) {
  if (!photoUrl) return undefined;

  if (!photoUrl.includes('heic')) {
    return photoUrl;
  }

  if (type === 'thumbnail') {
    return `https://d2je7um5zglace.cloudfront.net/unsafe/resize:fit:300:300:0/gravity:sm/plain/${encodeURIComponent(
      photoUrl
    )}@jpg`;
  }
  // @ts-ignore
  return `https://d2je7um5zglace.cloudfront.net/unsafe/plain/${encodeURIComponent(
    photoUrl
  )}@jpg`;
}
