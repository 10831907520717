/* eslint-disable camelcase */
import {
  Stack,
  Box,
  Button,
  chakra,
  Flex,
  Text,
  Heading,
} from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import { Document, Page, pdfjs } from 'react-pdf';
import { useUploadToS3 } from '@webapp/hooks';
import './SignConsent.css';
import {
  ConsentFieldsFragment,
  GraphqlClient,
  InsertSignatureDocument,
  PatientConsentFieldsFragment,
  PatientConsent_Insert_Input,
  StorageType,
  useGetPatientConsentsQuery,
  useGetPinAggregateQuery,
  useInsertPatientConsentMutation,
  useUpdatePatientConsentMutation,
} from '@webapp/graphql';
import { useForm, FormProvider, SubmitHandler } from 'react-hook-form';
import { FormInput } from '@webapp/ui';
import toast from 'react-hot-toast';
import { isConsentFormFinished } from '@webapp/util-validation';
import {
  loadExistingSignature,
  saveSignatureAndConvertToFile,
} from '@webapp/utils';
import { useApolloClient } from '@apollo/client';
import { useStores } from '@webapp/state-models';
import VerifyPinModal from '../VerifyPinModal/VerifyPinModal';
// import './sign-consent.css';
import SignatureGroup from './signatureGroup';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

export type PatientConsentFromQuery = {
  __typename?: 'patientConsent' | undefined;
  id: any;
  name: string;
  date: string;
  consent: {
    __typename?: 'consent' | undefined;
    id: any;
    document: {
      __typename?: 'document' | undefined;
      id: any;
      filePath: string;
      file?:
        | {
            url: string;
          }
        | null
        | undefined;
    };
  };
  signature: {
    __typename?: 'signature' | undefined;
    id: any;
    filePath: string;
    file?:
      | {
          url: string;
        }
      | null
      | undefined;
  };
};

interface SignConsentProps {
  appointmentId: string;
  consent: ConsentFieldsFragment;
  isPinDisabled?: boolean;
  onCompleted?: () => void;
  onVerify?: () => void;
  patientConsent?: PatientConsentFieldsFragment;
  patientId: string;
  refetchQueries?: string[];
  workspaceId: string;
}

type FormInput = {
  name?: string;
  date?: string;
  providerName?: string;
  providerDate?: string;
  witnessName?: string;
  witnessDate?: string;
};

export const SignConsent = ({
  appointmentId,
  consent,
  isPinDisabled = false,
  onCompleted = () => undefined,
  onVerify = () => undefined,
  patientId,
  refetchQueries = [],
  workspaceId,
}: SignConsentProps) => {
  const { data } = useGetPatientConsentsQuery({
    variables: {
      where: {
        patientId: { _eq: patientId },
        consentId: { _eq: consent.id },
        appointmentId: { _eq: appointmentId },
      },
    },
  });

  const { ui } = useStores();

  const client = useApolloClient();

  const [loading, setLoading] = useState(false);

  const patientConsent = data?.patientConsent[0];

  const methods = useForm({
    mode: 'onChange',
    defaultValues: {
      name: patientConsent?.name || '',
      date: patientConsent?.date || '',
      ...(patientConsent?.providerName &&
        patientConsent?.providerDate && {
          providerName: patientConsent?.providerName || '',
          providerDate: patientConsent?.providerDate || '',
        }),
      ...(patientConsent?.witnessName &&
        patientConsent?.witnessDate && {
          witnessName: patientConsent?.witnessName || '',
          witnessDate: patientConsent?.witnessDate || new Date(),
        }),
    },
    reValidateMode: 'onChange',
    criteriaMode: 'firstError',
    shouldFocusError: true,
  });

  const { handleSubmit } = methods;
  const patientSignatureRef = useRef<SignatureCanvas>(null);
  const providerSignatureRef = useRef<SignatureCanvas>(null);
  const witnessSignatureRef = useRef<SignatureCanvas>(null);

  const [numPages, setNumPages] = useState<number | null>(null);
  const [progress, setProgress] = useState(0);
  const [verifyPinModalIsOpen, setVerifyPinModalIsOpen] = useState(false);

  const { data: pinAggregateData } = useGetPinAggregateQuery();

  useEffect(() => {
    document.body.addEventListener(
      'touchstart',
      (e) => {
        if (
          e.target === patientSignatureRef.current ||
          e.target === providerSignatureRef.current ||
          e.target === witnessSignatureRef.current
        ) {
          e.preventDefault();
        }
      },
      false
    );
    document.body.addEventListener(
      'touchend',
      (e) => {
        if (
          e.target === patientSignatureRef.current ||
          e.target === providerSignatureRef.current ||
          e.target === witnessSignatureRef.current
        ) {
          e.preventDefault();
        }
      },
      false
    );
    document.body.addEventListener(
      'touchmove',
      (e) => {
        if (
          e.target === patientSignatureRef.current ||
          e.target === providerSignatureRef.current ||
          e.target === witnessSignatureRef.current
        ) {
          e.preventDefault();
        }
      },
      false
    );
  }, []);

  useEffect(() => {
    if (!patientConsent) return;

    patientSignatureRef.current?.off();

    const signatureURL = patientConsent?.signature?.file?.url;
    const witnessSignatureUrl = patientConsent?.witnessSignature?.file?.url;
    const providerSignatureUrl = patientConsent?.providerSignature?.file?.url;

    if (signatureURL) {
      loadExistingSignature(patientSignatureRef.current!, signatureURL);
    }

    if (witnessSignatureUrl) {
      witnessSignatureRef?.current?.off();
      loadExistingSignature(witnessSignatureRef.current!, witnessSignatureUrl);
    }

    if (providerSignatureUrl) {
      providerSignatureRef?.current?.off();
      loadExistingSignature(
        providerSignatureRef.current!,
        providerSignatureUrl
      );
    }
  }, [patientConsent]);

  const [insertPatientConsent] = useInsertPatientConsentMutation();
  const [updatePatientConsent] = useUpdatePatientConsentMutation();

  const { uploadToS3 } = useUploadToS3();

  const getAFileName = (): string => {
    const fileName = `${patientId}-${consent.id}-${new Date().getTime()}.png`;

    return fileName;
  };

  function onDocumentLoadSuccess({
    numPages: newNumPages,
  }: {
    numPages: number;
  }) {
    setNumPages(newNumPages);
  }

  const onClose = (): void => {
    ui?.setGlobalPinLock?.(false);
  };

  const onOpen = (): void => {
    ui?.setGlobalPinLock?.(true);
  };

  const verifyOrGoNext = (): void => {
    if (!isPinDisabled && pinAggregateData?.pin_aggregate.aggregate?.count) {
      onOpen();
    } else {
      onVerify();
    }
  };

  const onSubmit: SubmitHandler<FormInput> = async ({
    name,
    date,
    witnessName,
    witnessDate,
    providerName,
    providerDate,
  }) => {
    try {
      let witnessSignatureFilepath;
      let providerSignatureFilePath;

      if (patientSignatureRef?.current?.isEmpty()) {
        toast.error('Please sign the document');
        setLoading(false);
        return;
      }

      setLoading(true);
      const patientFileName = getAFileName();
      const file = await saveSignatureAndConvertToFile(
        patientSignatureRef.current!,
        patientFileName
      );

      const filePath = await uploadToS3({
        fileType: file.type,
        fileContents: file,
        filePath: file.name,
        storageType: StorageType.Document,
        onProgress: setProgress,
      });

      if (
        consent?.requireWitnessSignature &&
        !witnessSignatureRef.current?.isEmpty()
      ) {
        const witnessFileName = getAFileName();

        const witnessFile = await saveSignatureAndConvertToFile(
          witnessSignatureRef.current!,
          witnessFileName
        );

        witnessSignatureFilepath = await uploadToS3({
          fileType: witnessFile.type,
          fileContents: witnessFile,
          filePath: witnessFile.name,
          storageType: StorageType.Document,
          onProgress: setProgress,
        });
      }
      if (
        consent?.requireProviderSignature &&
        !providerSignatureRef.current?.isEmpty()
      ) {
        const providerFileName = getAFileName();

        const providerFile = await saveSignatureAndConvertToFile(
          providerSignatureRef.current!,
          providerFileName
        );

        providerSignatureFilePath = await uploadToS3({
          fileType: providerFile.type,
          fileContents: providerFile,
          filePath: providerFile.name,
          storageType: StorageType.Document,
          onProgress: setProgress,
        });
      }

      if (!patientConsent) {
        await insertPatientConsent({
          variables: {
            patientConsent: {
              name,
              date,
              consentId: consent.id,
              patientId,
              appointmentId,
              signature: {
                data: {
                  filePath,
                  patientId,
                },
              },
              ...(providerSignatureFilePath
                ? {
                    providerName,
                    providerDate,
                    providerSignature: {
                      data: {
                        filePath: providerSignatureFilePath,
                        patientId,
                      },
                    },
                  }
                : {}),
              ...(witnessSignatureFilepath
                ? {
                    witnessName,
                    witnessDate,
                    witnessSignature: {
                      data: {
                        filePath: witnessSignatureFilepath,
                        patientId,
                      },
                    },
                  }
                : {}),
            },
          },
          refetchQueries,
        });
      } else {
        const updateFields: PatientConsent_Insert_Input = {
          witnessName,
          witnessDate,
          providerName,
          providerDate,
        };
        if (providerSignatureFilePath) {
          const { data: providerSignatureData } = await client.mutate({
            mutation: InsertSignatureDocument,
            variables: {
              signature: {
                filePath: providerSignatureFilePath,
              },
            },
          });
          updateFields.providerSignatureId =
            providerSignatureData?.insert_signature?.returning[0].id;
        }

        if (witnessSignatureFilepath) {
          const { data: witnessSignatureData } = await client.mutate({
            mutation: InsertSignatureDocument,
            variables: {
              signature: {
                filePath: witnessSignatureFilepath,
              },
            },
          });
          updateFields.witnessSignatureId =
            witnessSignatureData?.insert_signature?.returning[0].id;
        }

        await updatePatientConsent({
          variables: {
            id: patientConsent?.id,
            set: updateFields,
          },
        });
      }
      setLoading(false);
      toast.success('Consent submitted successfully');

      onCompleted();
    } catch (e) {
      toast.error((e as Error).message);
    }
  };

  return (
    <Stack
      bg="gray.400"
      justifyContent="center"
      alignItems={'center'}
      zIndex={1350}
      maxW={`${window.innerWidth}px`}
    >
      <Box
        zIndex={2}
        bg="white"
        borderBottomWidth={1}
        borderColor="grey.200"
        shadow={'md'}
        w={'full'}
        p={4}
        position="sticky"
        top={0}
        left={0}
      >
        <Heading size="md">Pease read document & sign below 👇</Heading>
      </Box>
      <FormProvider {...methods}>
        <chakra.form onSubmit={handleSubmit(onSubmit)} m={0} p={0}>
          <Stack
            id="document-stack"
            bg="white"
            shadow="md"
            w="full"
            h="full"
            justifyContent={'center'}
            alignItems="center"
          >
            <Box
              id="consent-form-container"
              bg="white"
              zIndex={1}
              overflowY={'auto'}
              width={`${window.innerWidth * 0.8}px`}
            >
              {/* @ts-ignore-error React 18 */}
              <Document
                file={consent.document?.file?.url}
                onLoadSuccess={onDocumentLoadSuccess}
                options={{
                  width: `${window.innerWidth * 0.8}px`,
                }}
              >
                {Array.from(new Array(numPages), (el, index) => (
                  // @ts-ignore-error React 18
                  <Page
                    key={`page_${index + 1}`}
                    pageNumber={index + 1}
                    width={window.innerWidth * 0.78}
                  />
                ))}
              </Document>
              <Text px={2} pb={4} textAlign="center">
                By signing your name electronically on this document, you are
                agreeing that your electronic signature is the legal equivalent
                of your manual signature. You will receive a copy of this form
                after it has been signed.
              </Text>
              <Stack spacing={4} zIndex={99999}>
                <SignatureGroup
                  heading="Patient Signature"
                  formInputName="name"
                  formInputDateName="date"
                  consent={patientConsent}
                  signatureRef={patientSignatureRef}
                />
                {consent.requireWitnessSignature && (
                  <SignatureGroup
                    required={false}
                    heading="Witness Signature"
                    formInputName="witnessName"
                    formInputDateName="witnessDate"
                    consent={patientConsent}
                    signatureRef={witnessSignatureRef}
                  />
                )}
                {consent.requireProviderSignature && (
                  <SignatureGroup
                    required={false}
                    heading="Provider Signature"
                    formInputName="providerName"
                    formInputDateName="providerDate"
                    consent={patientConsent}
                    signatureRef={providerSignatureRef}
                  />
                )}
              </Stack>
            </Box>
          </Stack>

          <Flex
            justifyContent="space-between"
            p={4}
            w={'full'}
            alignItems="center"
          >
            <Button onClick={verifyOrGoNext}>Back</Button>
            {(!patientConsent || !isConsentFormFinished(patientConsent)) && (
              <Button colorScheme="teal" type="submit" isLoading={loading}>
                Submit
              </Button>
            )}
          </Flex>
        </chakra.form>
      </FormProvider>
      {!isPinDisabled && (
        <VerifyPinModal
          isOpen={verifyPinModalIsOpen}
          onClose={onClose}
          onVerify={onVerify}
        />
      )}
    </Stack>
  );
};

export default SignConsent;
