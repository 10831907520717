import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { FlowsheetFieldsMinimalFragmentDoc } from '../../fragments/flowsheet/FlowsheetFieldsMinimal.fragment.generated';
import { FlowsheetParameterFieldsMinimalFragmentDoc } from '../../fragments/flowsheet/FlowsheetParameterFieldsMinimal.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type PatientFlowsheetsQueryVariables = Types.Exact<{
  patientWorkspaceId: Types.Scalars['uuid'];
  limit?: Types.Maybe<Types.Scalars['Int']>;
  offset?: Types.Maybe<Types.Scalars['Int']>;
  orderBy?: Types.Maybe<Array<Types.Flowsheet_Order_By> | Types.Flowsheet_Order_By>;
  where?: Types.Maybe<Types.Flowsheet_Bool_Exp>;
}>;


export type PatientFlowsheetsQuery = { __typename?: 'query_root', flowsheet: Array<{ __typename?: 'flowsheet', id: any, title: string, parameters: Array<{ __typename?: 'flowsheetParameterFlowsheet', id: any, parameter: { __typename?: 'flowsheetParameter', apiKey: string, id: any, isSystemParameter: boolean, title: string, type: string, unit: string } }>, patientRows: Array<{ __typename?: 'patientFlowsheetRow', id: any, recordedAt: any, data: Array<{ __typename?: 'patientFlowsheetData', appointmentId?: any | null | undefined, flowsheetId: any, id: any, value: string, parameter: { __typename?: 'flowsheetParameter', apiKey: string, id: any, isSystemParameter: boolean, title: string, type: string, unit: string } }> }> }> };


export const PatientFlowsheetsDocument = gql`
    query PatientFlowsheets($patientWorkspaceId: uuid!, $limit: Int, $offset: Int, $orderBy: [flowsheet_order_by!], $where: flowsheet_bool_exp) {
  flowsheet(order_by: [{title: asc}], where: $where) {
    ...FlowsheetFieldsMinimal
    parameters {
      id
      parameter {
        ...FlowsheetParameterFieldsMinimal
      }
    }
    patientRows(
      order_by: [{recordedAt: desc}]
      where: {patientWorkspaceId: {_eq: $patientWorkspaceId}}
    ) {
      id
      recordedAt
      data {
        appointmentId
        flowsheetId
        id
        value
        parameter {
          ...FlowsheetParameterFieldsMinimal
        }
      }
    }
  }
}
    ${FlowsheetFieldsMinimalFragmentDoc}
${FlowsheetParameterFieldsMinimalFragmentDoc}`;

/**
 * __usePatientFlowsheetsQuery__
 *
 * To run a query within a React component, call `usePatientFlowsheetsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePatientFlowsheetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePatientFlowsheetsQuery({
 *   variables: {
 *      patientWorkspaceId: // value for 'patientWorkspaceId'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      orderBy: // value for 'orderBy'
 *      where: // value for 'where'
 *   },
 * });
 */
export function usePatientFlowsheetsQuery(baseOptions: Apollo.QueryHookOptions<PatientFlowsheetsQuery, PatientFlowsheetsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PatientFlowsheetsQuery, PatientFlowsheetsQueryVariables>(PatientFlowsheetsDocument, options);
      }
export function usePatientFlowsheetsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PatientFlowsheetsQuery, PatientFlowsheetsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PatientFlowsheetsQuery, PatientFlowsheetsQueryVariables>(PatientFlowsheetsDocument, options);
        }
export type PatientFlowsheetsQueryHookResult = ReturnType<typeof usePatientFlowsheetsQuery>;
export type PatientFlowsheetsLazyQueryHookResult = ReturnType<typeof usePatientFlowsheetsLazyQuery>;
export type PatientFlowsheetsQueryResult = Apollo.QueryResult<PatientFlowsheetsQuery, PatientFlowsheetsQueryVariables>;