import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type SearchNoteTitleQueryVariables = Types.Exact<{
  search: Types.Scalars['String'];
  workspaceId: Types.Scalars['uuid'];
}>;


export type SearchNoteTitleQuery = { __typename?: 'query_root', search_note_title: Array<{ __typename?: 'note', title?: string | null | undefined }> };


export const SearchNoteTitleDocument = gql`
    query SearchNoteTitle($search: String!, $workspaceId: uuid!) {
  search_note_title(args: {search: $search, workspace_id: $workspaceId}) {
    title
  }
}
    `;

/**
 * __useSearchNoteTitleQuery__
 *
 * To run a query within a React component, call `useSearchNoteTitleQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchNoteTitleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchNoteTitleQuery({
 *   variables: {
 *      search: // value for 'search'
 *      workspaceId: // value for 'workspaceId'
 *   },
 * });
 */
export function useSearchNoteTitleQuery(baseOptions: Apollo.QueryHookOptions<SearchNoteTitleQuery, SearchNoteTitleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchNoteTitleQuery, SearchNoteTitleQueryVariables>(SearchNoteTitleDocument, options);
      }
export function useSearchNoteTitleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchNoteTitleQuery, SearchNoteTitleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchNoteTitleQuery, SearchNoteTitleQueryVariables>(SearchNoteTitleDocument, options);
        }
export type SearchNoteTitleQueryHookResult = ReturnType<typeof useSearchNoteTitleQuery>;
export type SearchNoteTitleLazyQueryHookResult = ReturnType<typeof useSearchNoteTitleLazyQuery>;
export type SearchNoteTitleQueryResult = Apollo.QueryResult<SearchNoteTitleQuery, SearchNoteTitleQueryVariables>;