import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Flex,
  Button,
  HStack,
  PinInput,
  PinInputField,
  Text,
  Stack,
} from '@chakra-ui/react';
import { useVerifyPinMutation } from '@webapp/graphql';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';

interface VerifyPinModalProps {
  isOpen: boolean;
  onClose: () => void;
  onVerify: () => void;
}

export const VerifyPinModal = ({
  isOpen,
  onClose,
  onVerify,
}: VerifyPinModalProps): JSX.Element => {
  const [pin, setPin] = useState('');

  useEffect(() => {
    if (isOpen) {
      window.history.pushState(null, 'null', window.location.href);
      window.onpopstate = () => {
        window.history.go(1);
      };
    }
  }, [isOpen]);
  const [verify] = useVerifyPinMutation({
    onCompleted: () => {
      onClose();
      onVerify();
    },
    onError: (error) => {
      toast.error(error.message);
      onClose();
    },
  });

  const handleVerify = async (): Promise<void> => {
    await verify({ variables: { value: pin } });
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Enter Pin</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack>
            <Text>Please hand tablet back to office staff</Text>
            <HStack>
              <PinInput onComplete={setPin}>
                <PinInputField />
                <PinInputField />
                <PinInputField />
                <PinInputField />
                <PinInputField />
              </PinInput>
            </HStack>
          </Stack>
        </ModalBody>
        <ModalFooter>
          <Flex justifyContent="space-between">
            <Button onClick={onClose} marginRight="5">
              Cancel
            </Button>
            <Button variant="solid" colorScheme="teal" onClick={handleVerify}>
              Submit
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default VerifyPinModal;
