import { VStack } from '@chakra-ui/react';
import { FormInputV2 } from '@webapp/ui';
import { useFormContext } from 'react-hook-form';

function CardHolderInputs() {
  const form = useFormContext();

  return (
    <VStack>
      <FormInputV2
        control={form.control}
        styles={{
          label: {
            color:
              'var(--sjs-font-questiontitle-color, var(--sjs-general-forecolor, var(--foreground, gray.600)))',
          },
          input: {
            backgroundColor: 'transparent',
          },
          inputContainer: {
            backgroundColor: 'transparent',
          },
        }}
        label="Card Holder Name"
        name="name"
        type="text"
      />
      <FormInputV2
        styles={{
          label: {
            color:
              'var(--sjs-font-questiontitle-color, var(--sjs-general-forecolor, var(--foreground, gray.600)))',
          },
        }}
        control={form.control}
        label="Email"
        name="email"
        type="text"
      />
      <FormInputV2
        styles={{
          label: {
            color:
              'var(--sjs-font-questiontitle-color, var(--sjs-general-forecolor, var(--foreground, gray.600)))',
          },
        }}
        control={form.control}
        label="Phone #"
        name="phoneNumber"
        type="text"
      />
    </VStack>
  );
}

export default CardHolderInputs;
