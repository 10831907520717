import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetValidatedCouponsForInvoiceQueryVariables = Types.Exact<{
  locationId: Types.Scalars['uuid'];
  patientId?: Types.Maybe<Types.Scalars['uuid']>;
  serviceIds: Array<Types.Scalars['uuid']> | Types.Scalars['uuid'];
}>;


export type GetValidatedCouponsForInvoiceQuery = { __typename?: 'query_root', getValidatedCouponsForInvoice: { __typename?: 'GetValidatedCouponsForInvoiceOutput', invoiceCoupons: Array<{ __typename?: 'ValidatedCoupon', amount: number, couponId: any, invalidReason?: string | null | undefined, isMembershipCoupon: boolean, name: string, type: string, validity: string }>, serviceCoupons: Array<{ __typename?: 'ValidatedCoupon', amount: number, couponId: any, invalidReason?: string | null | undefined, isMembershipCoupon: boolean, name: string, type: string, validity: string }>, walletCoupons: Array<{ __typename?: 'ValidatedWalletCoupon', amount: number, couponId: any, invalidReason?: string | null | undefined, isMembershipCoupon: boolean, name: string, packageId?: any | null | undefined, quantity: number, type: string, validity: string }> } };


export const GetValidatedCouponsForInvoiceDocument = gql`
    query GetValidatedCouponsForInvoice($locationId: uuid!, $patientId: uuid, $serviceIds: [uuid!]!) {
  getValidatedCouponsForInvoice(
    locationId: $locationId
    patientId: $patientId
    serviceIds: $serviceIds
  ) {
    invoiceCoupons {
      amount
      couponId
      invalidReason
      isMembershipCoupon
      name
      type
      validity
    }
    serviceCoupons {
      amount
      couponId
      invalidReason
      isMembershipCoupon
      name
      type
      validity
    }
    walletCoupons {
      amount
      couponId
      invalidReason
      isMembershipCoupon
      name
      packageId
      quantity
      type
      validity
    }
  }
}
    `;

/**
 * __useGetValidatedCouponsForInvoiceQuery__
 *
 * To run a query within a React component, call `useGetValidatedCouponsForInvoiceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetValidatedCouponsForInvoiceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetValidatedCouponsForInvoiceQuery({
 *   variables: {
 *      locationId: // value for 'locationId'
 *      patientId: // value for 'patientId'
 *      serviceIds: // value for 'serviceIds'
 *   },
 * });
 */
export function useGetValidatedCouponsForInvoiceQuery(baseOptions: Apollo.QueryHookOptions<GetValidatedCouponsForInvoiceQuery, GetValidatedCouponsForInvoiceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetValidatedCouponsForInvoiceQuery, GetValidatedCouponsForInvoiceQueryVariables>(GetValidatedCouponsForInvoiceDocument, options);
      }
export function useGetValidatedCouponsForInvoiceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetValidatedCouponsForInvoiceQuery, GetValidatedCouponsForInvoiceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetValidatedCouponsForInvoiceQuery, GetValidatedCouponsForInvoiceQueryVariables>(GetValidatedCouponsForInvoiceDocument, options);
        }
export type GetValidatedCouponsForInvoiceQueryHookResult = ReturnType<typeof useGetValidatedCouponsForInvoiceQuery>;
export type GetValidatedCouponsForInvoiceLazyQueryHookResult = ReturnType<typeof useGetValidatedCouponsForInvoiceLazyQuery>;
export type GetValidatedCouponsForInvoiceQueryResult = Apollo.QueryResult<GetValidatedCouponsForInvoiceQuery, GetValidatedCouponsForInvoiceQueryVariables>;