interface Options {
  shouldDisplayCurrency?: boolean;
  locales?: string | string[];
  maximumFractionDigits?: number;
  minimumFractionDigits?: number;
}

export function toCents(price: number) {
  return Math.round(price / 100);
}

export function toLocalePrice(
  price: number,
  options: Options = {
    locales: undefined,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    shouldDisplayCurrency: true,
  }
): string {
  const {
    locales = undefined,
    maximumFractionDigits = 2,
    minimumFractionDigits = 2,
    shouldDisplayCurrency = true,
  } = options;

  const effectiveMinimum =
    maximumFractionDigits < minimumFractionDigits
      ? maximumFractionDigits
      : minimumFractionDigits;

  return `${shouldDisplayCurrency ? '$' : ''}${price.toLocaleString(locales, {
    maximumFractionDigits,
    minimumFractionDigits: effectiveMinimum,
  })}`;
}

export function toLocalePriceInCents(cents: number, options?: Options): string {
  return toLocalePrice(cents / 100, options);
}

export default toLocalePrice;
