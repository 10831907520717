import { Stack, Image, Heading, Text, Center } from '@chakra-ui/react';

export default function SurveyJSCompletePage({
  surveyJson,
  completedHtml,
}: {
  surveyJson: any;
  completedHtml?: string;
}) {
  return (
    <Center h="100vh">
      <Stack
        spacing={4}
        bg="white"
        p={8}
        rounded="md"
        shadow="lg"
        alignItems={'center'}
      >
        <a href={surveyJson?.websiteHomepage}>
          <Image src={surveyJson?.logo} maxHeight="200px" />
        </a>
        <Heading>Thank you!</Heading>
        {surveyJson.completedHtml || completedHtml ? (
          <Text
            maxW="md"
            dangerouslySetInnerHTML={{
              __html: completedHtml ?? surveyJson?.completedHtml,
            }}
          />
        ) : (
          <Text>We'll be in touch soon!</Text>
        )}
      </Stack>
    </Center>
  );
}
