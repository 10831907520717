import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type ListMarketingTagsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ListMarketingTagsQuery = { __typename?: 'query_root', marketingTag: Array<{ __typename?: 'marketingTag', id: any, title: string }> };


export const ListMarketingTagsDocument = gql`
    query ListMarketingTags {
  marketingTag {
    id
    title
  }
}
    `;

/**
 * __useListMarketingTagsQuery__
 *
 * To run a query within a React component, call `useListMarketingTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListMarketingTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListMarketingTagsQuery({
 *   variables: {
 *   },
 * });
 */
export function useListMarketingTagsQuery(baseOptions?: Apollo.QueryHookOptions<ListMarketingTagsQuery, ListMarketingTagsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListMarketingTagsQuery, ListMarketingTagsQueryVariables>(ListMarketingTagsDocument, options);
      }
export function useListMarketingTagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListMarketingTagsQuery, ListMarketingTagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListMarketingTagsQuery, ListMarketingTagsQueryVariables>(ListMarketingTagsDocument, options);
        }
export type ListMarketingTagsQueryHookResult = ReturnType<typeof useListMarketingTagsQuery>;
export type ListMarketingTagsLazyQueryHookResult = ReturnType<typeof useListMarketingTagsLazyQuery>;
export type ListMarketingTagsQueryResult = Apollo.QueryResult<ListMarketingTagsQuery, ListMarketingTagsQueryVariables>;