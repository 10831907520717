import {
  getRoot,
  IMSTArray,
  Instance,
  ISimpleType,
  SnapshotOut,
  types,
} from 'mobx-state-tree';
import { calendarFormat } from 'moment-timezone';
import { CalendarConfiguration } from 'libs/webapp/pages/src/lib/workspace-configuration-page/calendarConfiguration';
import { LocationModel } from './location';
import { RootStore } from '../root-store';
import { AnyJsonValue } from '../custom-types/any-json-value';
import { OrganizationModel } from './organization';

export const WorkspaceCalendarStatusConfigurationModel = types
  .model('WorkspaceCalendarStatusConfigurationModel')
  .props({
    BOOKED: types.maybeNull(types.string),
    NO_SHOW: types.maybeNull(types.string),
    CANCELED: types.maybeNull(types.string),
    CONFLICT: types.maybeNull(types.string),
    COMPLETED: types.maybeNull(types.string),
    CONFIRMED: types.maybeNull(types.string),
    CHECKED_IN: types.maybeNull(types.string),
    CHECKED_OUT: types.maybeNull(types.string),
  });

export const WorkspaceCalendarConfigurationModel = types
  .model('WorkspaceCalendarConfiguration')
  .props({
    defaultView: types.optional(types.string, 'day'),
    timeCellStep: types.optional(types.string, '30'),
    showWeekends: types.optional(types.boolean, true),
    showEventPopover: types.optional(types.boolean, true),
    showCancelledAppointments: types.optional(types.boolean, true),
    status: types.maybeNull(WorkspaceCalendarStatusConfigurationModel),
  });

export const WorkspaceOpportunityConfigurationModel = types
  .model('WorkspaceOpportunityConfiguration')
  .props({
    showNextAppointmentCard: types.optional(types.boolean, true),
    showLastAppointmentCard: types.optional(types.boolean, true),
    showOpportunityHoverPreview: types.optional(types.boolean, true),
    showLeadFormSource: types.optional(types.boolean, true),
    showTags: types.optional(types.boolean, true),
  });

export const WorkspaceConfigurationModel = types
  .model('WorkspaceConfiguration')
  .props({
    autoCategorizeConversations: types.optional(types.boolean, false),
    calendar: types.maybeNull(WorkspaceCalendarConfigurationModel),
    clinicalNotesExpandedDefault: types.maybeNull(types.boolean),
    cloudflareCustomHostId: types.maybeNull(types.string),
    completeAppointmentCopy: types.optional(AnyJsonValue, {}),
    customPermissions: types.optional(AnyJsonValue, {}),
    defaultToPatientWallet: types.optional(types.boolean, false),
    id: types.identifier,
    invoiceMessage: types.maybeNull(types.string),
    leadStatusOptions: types.optional(types.array(types.string), []),
    logo: types.maybeNull(types.string),
    noShowActive: types.maybeNull(types.boolean),
    noShowFee: types.maybeNull(types.number),
    opportunities: types.maybeNull(WorkspaceOpportunityConfigurationModel),
    patientReferralOptions: types.optional(types.array(types.string), []),
    patientPortalInviteCopy: types.optional(AnyJsonValue, {}),
    patientPortalCanBookAppointments: types.boolean,
    patientPortalBookAppointmentBufferAmount: types.number,
    patientPortalBookAppointmentBufferUnit: types.string,
    patientPortalCanCancelAppointments: types.boolean,
    patientPortalCancelAppointmentBufferAmount: types.number,
    patientPortalCancelAppointmentBufferUnit: types.string,
    patientPortalBookableServices: AnyJsonValue,
    paymentMethods: types.optional(types.array(types.string), []),
    providersOnlySeeOwnSchedule: types.optional(types.boolean, false),
    schedulingIntervalInMinutes: types.number,
    smsMarketingCharacterLimit: types.maybeNull(types.number),
    slug: types.maybeNull(types.string),
    timezone: types.maybeNull(types.string),
  })
  .actions((self) => ({
    afterCreate() {
      (getRoot(self) as RootStore).ui.setHideCancelledAppointments(
        !self.calendar?.showCancelledAppointments
      );
    },
    setLeadStatusOptions(options: string[]) {
      self.leadStatusOptions = options as IMSTArray<ISimpleType<string>>;
    },
  }));

/**
 * A WorkspaceStore model.
 */
export const WorkspaceModel = types
  .model('Workspace')
  .props({
    logo: types.maybeNull(types.string),
    id: types.identifier,
    name: types.string,
    payrixMerchantId: types.maybeNull(types.string),
    finixMerchantId: types.maybeNull(types.string),
    flags: types.optional(types.array(types.string), []),
    workspaceConfiguration: types.maybeNull(WorkspaceConfigurationModel),
    locations: types.optional(types.array(LocationModel), []),
    organization: types.maybeNull(OrganizationModel),
  })
  .actions((self) => ({
    setCalendarConfiguration(calendarConfiguration: CalendarConfiguration) {
      self.workspaceConfiguration = {
        ...self.workspaceConfiguration,
        calendar: {
          ...calendarConfiguration,
          status: {
            ...(calendarConfiguration.status
              ? calendarConfiguration.status
              : {}),
          },
        },
      };
    },
    setWorkspaceConfiguration(workspaceConfiguration: WorkspaceConfiguration) {
      self.workspaceConfiguration = workspaceConfiguration;
    },
  }));
/**
 * The Workspace instance.
 */
export type Workspace = Instance<typeof WorkspaceModel>;

/**
 * The data of a Workspace.
 */
export type WorkspaceSnapshot = SnapshotOut<typeof WorkspaceModel>;
