import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type AggregateDocumentsQueryVariables = Types.Exact<{
  where?: Types.Maybe<Types.Document_Bool_Exp>;
}>;


export type AggregateDocumentsQuery = { __typename?: 'query_root', document_aggregate: { __typename?: 'document_aggregate', aggregate?: { __typename?: 'document_aggregate_fields', count: number } | null | undefined } };


export const AggregateDocumentsDocument = gql`
    query AggregateDocuments($where: document_bool_exp) {
  document_aggregate(where: $where) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useAggregateDocumentsQuery__
 *
 * To run a query within a React component, call `useAggregateDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAggregateDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAggregateDocumentsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useAggregateDocumentsQuery(baseOptions?: Apollo.QueryHookOptions<AggregateDocumentsQuery, AggregateDocumentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AggregateDocumentsQuery, AggregateDocumentsQueryVariables>(AggregateDocumentsDocument, options);
      }
export function useAggregateDocumentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AggregateDocumentsQuery, AggregateDocumentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AggregateDocumentsQuery, AggregateDocumentsQueryVariables>(AggregateDocumentsDocument, options);
        }
export type AggregateDocumentsQueryHookResult = ReturnType<typeof useAggregateDocumentsQuery>;
export type AggregateDocumentsLazyQueryHookResult = ReturnType<typeof useAggregateDocumentsLazyQuery>;
export type AggregateDocumentsQueryResult = Apollo.QueryResult<AggregateDocumentsQuery, AggregateDocumentsQueryVariables>;