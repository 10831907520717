import './delete-dialog.module.css';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useDisclosure,
  Button,
} from '@chakra-ui/react';
import { cloneElement, useRef } from 'react';

/* eslint-disable-next-line */
export interface DeleteDialogProps {
  children?: React.ReactElement;
  objectToDelete?: string;
  handleConfirm: () => void;
  buttonText?: string;
  headerText?: string;
  confirmButtonText?: string;
  bodyText?: string;
}

export function DeleteDialog({
  children,
  objectToDelete,
  handleConfirm,
  buttonText,
  headerText,
  confirmButtonText,
  bodyText,
}: DeleteDialogProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef<HTMLButtonElement>(null);

  function okayToDelete() {
    handleConfirm();
    onClose();
  }

  return (
    <>
      {children ? (
        cloneElement(children, { onClick: onOpen })
      ) : (
        <Button colorScheme="red" onClick={onOpen}>
          {buttonText || `Delete ${objectToDelete}`}
        </Button>
      )}

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {headerText || `Delete ${objectToDelete}`}
            </AlertDialogHeader>

            <AlertDialogBody>
              {bodyText ||
                "Are you sure? You can't undo this action afterwards."}
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button colorScheme="red" onClick={okayToDelete} ml={3}>
                {confirmButtonText || 'Delete'}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
}

export default DeleteDialog;
