import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type CustomInsertConsumableMutationVariables = Types.Exact<{
  consumable: Types.Scalars['jsonb'];
}>;


export type CustomInsertConsumableMutation = { __typename?: 'mutation_root', customInsertConsumable: any };


export const CustomInsertConsumableDocument = gql`
    mutation CustomInsertConsumable($consumable: jsonb!) {
  customInsertConsumable(consumable: $consumable)
}
    `;
export type CustomInsertConsumableMutationFn = Apollo.MutationFunction<CustomInsertConsumableMutation, CustomInsertConsumableMutationVariables>;

/**
 * __useCustomInsertConsumableMutation__
 *
 * To run a mutation, you first call `useCustomInsertConsumableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCustomInsertConsumableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [customInsertConsumableMutation, { data, loading, error }] = useCustomInsertConsumableMutation({
 *   variables: {
 *      consumable: // value for 'consumable'
 *   },
 * });
 */
export function useCustomInsertConsumableMutation(baseOptions?: Apollo.MutationHookOptions<CustomInsertConsumableMutation, CustomInsertConsumableMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CustomInsertConsumableMutation, CustomInsertConsumableMutationVariables>(CustomInsertConsumableDocument, options);
      }
export type CustomInsertConsumableMutationHookResult = ReturnType<typeof useCustomInsertConsumableMutation>;
export type CustomInsertConsumableMutationResult = Apollo.MutationResult<CustomInsertConsumableMutation>;
export type CustomInsertConsumableMutationOptions = Apollo.BaseMutationOptions<CustomInsertConsumableMutation, CustomInsertConsumableMutationVariables>;