import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetAudienceByPkQueryVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
}>;


export type GetAudienceByPkQuery = { __typename?: 'query_root', audience?: { __typename?: 'audience', id: any, name?: string | null | undefined, type: string, definition: any, snapshot: any } | null | undefined };


export const GetAudienceByPkDocument = gql`
    query getAudienceByPk($id: uuid!) {
  audience: audience_by_pk(id: $id) {
    id
    name
    type
    definition
    snapshot
  }
}
    `;

/**
 * __useGetAudienceByPkQuery__
 *
 * To run a query within a React component, call `useGetAudienceByPkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAudienceByPkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAudienceByPkQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAudienceByPkQuery(baseOptions: Apollo.QueryHookOptions<GetAudienceByPkQuery, GetAudienceByPkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAudienceByPkQuery, GetAudienceByPkQueryVariables>(GetAudienceByPkDocument, options);
      }
export function useGetAudienceByPkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAudienceByPkQuery, GetAudienceByPkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAudienceByPkQuery, GetAudienceByPkQueryVariables>(GetAudienceByPkDocument, options);
        }
export type GetAudienceByPkQueryHookResult = ReturnType<typeof useGetAudienceByPkQuery>;
export type GetAudienceByPkLazyQueryHookResult = ReturnType<typeof useGetAudienceByPkLazyQuery>;
export type GetAudienceByPkQueryResult = Apollo.QueryResult<GetAudienceByPkQuery, GetAudienceByPkQueryVariables>;