import {
  Box,
  Button,
  Collapse,
  Flex,
  Icon,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { ErrorMessage } from '@hookform/error-message';
import { DataTable, FormInput } from '@webapp/ui';
import { toLocalePrice } from '@webapp/util-formatting';
import { useLoadMemberships } from '@webapp/webapp/hooks';
import { useEffect, useState } from 'react';
import { useFormContext, useFieldArray, useWatch } from 'react-hook-form';
import { HiTrash } from 'react-icons/hi';
import { RiAddBoxLine } from 'react-icons/ri';
import { Cell } from 'react-table';
import { InvoiceFormValues } from './types';

export const MembershipForm = (): JSX.Element => {
  const {
    control,
    formState: { errors },
    getValues,
    watch,
  } = useFormContext<InvoiceFormValues>();

  const [toggleDisabled, setToggleDisabled] = useState<boolean>(
    Boolean(getValues('memberships')?.length)
  );

  const { isOpen, onToggle } = useDisclosure();

  const { append, fields, remove } = useFieldArray<
    InvoiceFormValues,
    'memberships'
  >({
    control,
    name: 'memberships',
  });

  const getValue = (id: string) => {
    const index = fields.findIndex((field) => field.id === id);
    const name = `memberships.${index}`;

    return getValues(name as any);
  };

  useEffect(() => {
    const memberships = getValues('memberships');

    if (!isOpen && memberships) {
      onToggle();
    }
  }, []);

  useEffect(() => {
    const sub = watch((data, options) => {
      if (options.name === 'memberships' && data.memberships?.length) {
        setToggleDisabled(true);
      } else if (options.name === 'memberships') {
        setToggleDisabled(false);
      }
    });

    return () => sub.unsubscribe();
  }, [watch]);

  return (
    <Box my={4}>
      <Button isDisabled={toggleDisabled} onClick={onToggle}>
        Add Membership
      </Button>
      <Collapse in={isOpen} animateOpacity>
        <Box width="100%" mt={4}>
          <DataTable
            columns={[
              {
                accessor: 'object',
                disableSortBy: true,
                defaultCanSort: false,
                Header: 'Membership',
                id: 'memberships.name',
                Cell: (originalRow: Cell<Record<'id', string>>) => {
                  const index = fields.findIndex(
                    (field) => field.id === originalRow.row.original.id
                  );

                  const name = `memberships.${index}`;

                  const currentValue = getValue(originalRow.row.original.id);

                  return (
                    <FormInput
                      name={name}
                      placeholder="Add Membership"
                      style={{
                        minWidth: '200px',
                      }}
                      type="async-select"
                      selectProps={{
                        defaultOption: currentValue,
                        loadOptions: useLoadMemberships,
                      }}
                    />
                  );
                },
              },
              {
                accessor: 'label',
                Header: 'Price',
                Cell: (originalRow: Cell<Record<'id', string>>) => {
                  const index = fields.findIndex(
                    (field) => field.id === originalRow.row.original.id
                  );

                  const memberships = useWatch({ name: 'memberships' });

                  const { price } = memberships[index].object;

                  return <Text>{toLocalePrice(price / 100)}</Text>;
                },
              },
              {
                accessor: 'id',
                Cell: (originalRow: Cell<Record<'id', string>>) => {
                  const index = fields.findIndex(
                    (el) => el.id === originalRow.row.original.id
                  );

                  return (
                    <Flex justifyContent="end" width="100%">
                      <Button
                        colorScheme="red"
                        onClick={() => {
                          remove(index);
                        }}
                        variant="ghost"
                      >
                        <Icon as={HiTrash} />
                      </Button>
                    </Flex>
                  );
                },
                disableSortBy: true,
                defaultCanSort: false,
                Header: '',
                id: 'services',
                width: 15,
              },
            ]}
            customStyles={{
              borderColor: '#CFEBFF',
              cell: {
                color: '#6C6C72',
              },
              table: {
                borderRadius: '8px',
                minHeight: 'unset',
              },
              tableHeader: {
                background: '#EAFCFF',
                color: '#525257',
                textTransform: 'uppercase',
              },
            }}
            data={fields}
            emptyStateText="Add Memberships"
            shouldDisplayPagination={false}
          />
          {errors.services && (
            <ErrorMessage
              errors={errors}
              name={'memberships'}
              render={(error) => (
                <Text
                  className="input-error"
                  color="red.500"
                  margin="15px 0 0"
                  _before={{
                    display: 'inline',
                    content: '"⚠ "',
                  }}
                >
                  {error.message ?? 'Incomplete Memberships'}
                </Text>
              )}
            />
          )}
          <Box>
            <Button
              color="teal.500"
              justifyContent="start"
              leftIcon={<Icon as={RiAddBoxLine} color="teal.500" />}
              margin="15px 0 0"
              onClick={() =>
                append({
                  label: '',
                  value: '',
                  object: {
                    name: '',
                    price: 0,
                  },
                })
              }
              textAlign="left"
              variant="ghost"
              width="max-content"
            >
              Add New Membership
            </Button>
          </Box>
        </Box>
      </Collapse>
    </Box>
  );
};

export default MembershipForm;
