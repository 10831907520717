import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type UpdateSubscriptionPaymentMethodMutationVariables = Types.Exact<{
  patientMembershipId: Types.Scalars['uuid'];
  paymentMethodId: Types.Scalars['uuid'];
}>;


export type UpdateSubscriptionPaymentMethodMutation = { __typename?: 'mutation_root', updateSubscriptionPaymentMethod: { __typename?: 'SuccessAnswer', success: boolean } };


export const UpdateSubscriptionPaymentMethodDocument = gql`
    mutation UpdateSubscriptionPaymentMethod($patientMembershipId: uuid!, $paymentMethodId: uuid!) {
  updateSubscriptionPaymentMethod(
    patientMembershipId: $patientMembershipId
    paymentMethodId: $paymentMethodId
  ) {
    success
  }
}
    `;
export type UpdateSubscriptionPaymentMethodMutationFn = Apollo.MutationFunction<UpdateSubscriptionPaymentMethodMutation, UpdateSubscriptionPaymentMethodMutationVariables>;

/**
 * __useUpdateSubscriptionPaymentMethodMutation__
 *
 * To run a mutation, you first call `useUpdateSubscriptionPaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSubscriptionPaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSubscriptionPaymentMethodMutation, { data, loading, error }] = useUpdateSubscriptionPaymentMethodMutation({
 *   variables: {
 *      patientMembershipId: // value for 'patientMembershipId'
 *      paymentMethodId: // value for 'paymentMethodId'
 *   },
 * });
 */
export function useUpdateSubscriptionPaymentMethodMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSubscriptionPaymentMethodMutation, UpdateSubscriptionPaymentMethodMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSubscriptionPaymentMethodMutation, UpdateSubscriptionPaymentMethodMutationVariables>(UpdateSubscriptionPaymentMethodDocument, options);
      }
export type UpdateSubscriptionPaymentMethodMutationHookResult = ReturnType<typeof useUpdateSubscriptionPaymentMethodMutation>;
export type UpdateSubscriptionPaymentMethodMutationResult = Apollo.MutationResult<UpdateSubscriptionPaymentMethodMutation>;
export type UpdateSubscriptionPaymentMethodMutationOptions = Apollo.BaseMutationOptions<UpdateSubscriptionPaymentMethodMutation, UpdateSubscriptionPaymentMethodMutationVariables>;