/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable camelcase */
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Flex,
  Button,
  HStack,
  Input,
  Select,
  chakra,
  FormControl,
  Stack,
  IconButton,
  Text,
  Icon,
  Image,
} from '@chakra-ui/react';
import {
  CustomFormFieldType_Enum,
  CustomQuestion_Insert_Input,
  Photo,
  StorageType,
  useDeleteCustomQuestionOptionMutation,
  useInsertCustomQuestionMutation,
  useInsertCustomQuestionOptionMutation,
  useInsertPhotoMutation,
  useUpdateCustomQuestionByPkMutation,
} from '@webapp/graphql';
import { useCallback, useEffect, useRef, useState } from 'react';
import { SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form';
import { BiPlus } from 'react-icons/bi';
import { FiTrash } from 'react-icons/fi';
import toast from 'react-hot-toast';
import { useStores } from '@webapp/state-models';
import { DefaultQuestion } from '@webapp/types';
import {
  DropAndUpload,
  SmartTextEditor,
  SmartTextEditorSize,
} from '@webapp/ui';
import { useUploadToS3 } from '@webapp/hooks';

interface CreateQuestionModalProps {
  isOpen: boolean;
  question?: DefaultQuestion;
  onClose: () => void;
  refetchQuestions: any;
}

type CreateQuestionInput = Pick<CustomQuestion_Insert_Input, 'label' | 'type'>;

export const CreateQuestionModal = ({
  isOpen,
  onClose,
  question,
  refetchQuestions,
}: CreateQuestionModalProps): JSX.Element => {
  const [progress, setProgress] = useState(0);
  const { uploadToS3 } = useUploadToS3();
  const [options, setOptions] = useState<string[]>(['']);
  const methods = useForm<CreateQuestionInput>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    criteriaMode: 'firstError',
    shouldFocusError: true,
    defaultValues: {
      label: question?.label || '',
      type: question?.type || CustomFormFieldType_Enum.SingleSelect,
    },
  });

  const [hasOptions, setHasOptions] = useState<boolean>(false);
  const { register, handleSubmit, reset, watch } = methods;
  const [showEditor, setShowEditor] = useState(false);
  const [showDropAndUpload, setShowDropAndUpload] = useState(false);
  const [photo, setPhoto] = useState<Partial<Photo> | undefined>();

  useEffect(() => {
    setOptions(
      question?.options?.length
        ? question.options.map(({ value }) => value)
        : ['']
    );
  }, [question?.options]);

  useEffect(() => {
    if (!question) reset();
  }, [question, reset]);

  useEffect(() => {
    if (question) {
      methods.setValue('label', question.label);
      methods.setValue('type', question.type);
      setPhoto(question.photo as any);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [question]);

  const textEditorRef = useRef<any>(null);

  const [insertPhoto] = useInsertPhotoMutation({
    onCompleted: (data) => {
      const insertedPhoto = data.insert_photo?.returning[0];

      if (insertedPhoto) {
        setPhoto(insertedPhoto as Photo);
      }
    },
  });

  const onDrop = async (acceptedFiles: File[]): Promise<void> => {
    try {
      const file = acceptedFiles[0];

      const filePath = await uploadToS3({
        fileType: file.type,
        fileContents: file,
        filePath: file.name,
        storageType: StorageType.Private,
        onProgress: setProgress,
        randomizeFileName: true,
      });

      await insertPhoto({
        variables: {
          photo: {
            filePath,
            workspaceId: workspace?.id,
          },
        },
      });
    } catch (error) {
      toast.error((error as Error).message);
    } finally {
      setProgress(0);
    }
  };

  const questionType = watch('type');

  useEffect(() => {
    if (!questionType) return;

    setHasOptions(
      [
        CustomFormFieldType_Enum.MultipleSelect,
        CustomFormFieldType_Enum.SingleSelect,
        CustomFormFieldType_Enum.CreatableSelect,
        CustomFormFieldType_Enum.CreatableSingleSelect,
        CustomFormFieldType_Enum.SingleSelectWithImage,
        CustomFormFieldType_Enum.MultipleSelectWithImage,
      ].includes(questionType)
    );

    setShowEditor(questionType === CustomFormFieldType_Enum.FormattedText);
    setShowDropAndUpload(
      questionType === CustomFormFieldType_Enum.SingleSelectWithImage ||
        questionType === CustomFormFieldType_Enum.MultipleSelectWithImage
    );
  }, [questionType]);

  const { workspace, user } = useStores();

  const [createQuestion] = useInsertCustomQuestionMutation();
  const [updateQuestion] = useUpdateCustomQuestionByPkMutation();
  const [insertQuestionOption] = useInsertCustomQuestionOptionMutation();
  const [deleteQuestionOption] = useDeleteCustomQuestionOptionMutation();

  const refetchResetAndClose = useCallback((): void => {
    toast.success(
      `Question ${question?.id ? 'updated' : 'created'} successfully`
    );
    refetchQuestions();
    reset();
    setOptions(['']);
    onClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [question?.id]);

  const onSubmit: SubmitHandler<CreateQuestionInput> = async ({
    label,
    type,
  }): Promise<void> => {
    const mapOptions = (
      customQuestionId?: string
    ): { value: string; customQuestionId?: string }[] => {
      const populated = options.filter((option) => option !== '');

      return populated.map((item) => ({
        value: item,
        customQuestionId,
      }));
    };

    const questionId = question?.id;

    const body = textEditorRef?.current?.saveContent();

    const photoId = photo?.id;

    try {
      if (questionId) {
        if (hasOptions) {
          await deleteQuestionOption({
            variables: {
              where: {
                question: {
                  id: {
                    _eq: questionId,
                  },
                },
              },
            },
          });

          await insertQuestionOption({
            variables: {
              rows: mapOptions(questionId),
            },
          });
        }

        await updateQuestion({
          variables: {
            set: {
              label,
              type,
              ...(body && { body }),
              ...(photoId && { photoId }),
            },
            id: questionId,
          },
          onCompleted: () => {
            refetchResetAndClose();
          },
        });
      } else {
        await createQuestion({
          variables: {
            customQuestion: {
              workspaceId: workspace?.id,
              userId: user?.id,
              label,
              ...(body && { body }),
              type,
              options: {
                data: mapOptions(),
              },
              ...(photoId && { photoId }),
            },
          },
          onCompleted: () => {
            refetchResetAndClose();
          },
        });
      }
    } catch (error) {
      toast.error((error as Error).message);
    }
  };

  const insertOption = (option: string, index: number): void => {
    const newOptions = [...options];
    newOptions[index] = option;
    setOptions(newOptions);
  };

  const onError: SubmitErrorHandler<CreateQuestionInput> = ({
    label,
    type,
  }): void => {
    if (label?.message) {
      toast.error(label.message);
    } else if (type?.message) {
      toast.error(type.message);
    }
  };

  const removeFromList = (index: number): void => {
    const newOptions = [...options];
    newOptions.splice(index, 1);
    setOptions(newOptions);
  };

  const addToList = (): void => {
    setOptions([...options, '']);
  };

  const clearImage = (): void => {
    setPhoto(undefined);
  };

  const optionCustomize = question?.optionCustomizable;

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent width="700px" maxWidth="unset">
        <chakra.form onSubmit={handleSubmit(onSubmit, onError)}>
          <ModalHeader mb={2}>{optionCustomize && question?.label}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <HStack spacing={5} display={optionCustomize ? 'none' : 'flex'}>
              <FormControl>
                <Input
                  defaultValue={question?.label}
                  {...register('label', {
                    required: 'Label is required',
                  })}
                />
              </FormControl>
              <Select
                id="type"
                {...register('type', {
                  required: 'Type is required',
                })}
              >
                <option value={CustomFormFieldType_Enum.TextInput}>
                  Text Input
                </option>
                <option value={CustomFormFieldType_Enum.MultipleSelect}>
                  All That Apply
                </option>
                <option value={CustomFormFieldType_Enum.SingleSelect}>
                  Multiple Choice
                </option>
                <option value={CustomFormFieldType_Enum.Address}>
                  Address
                </option>
                <option value={CustomFormFieldType_Enum.Date}>Date</option>
                <option value={CustomFormFieldType_Enum.YesNo}>Y/N</option>
                <option value={CustomFormFieldType_Enum.FormattedText}>
                  Formatted Text
                </option>
                <option value={CustomFormFieldType_Enum.SingleSelectWithImage}>
                  Multiple Choice With Image
                </option>
                <option
                  value={CustomFormFieldType_Enum.MultipleSelectWithImage}
                >
                  All That Apply With Image
                </option>
              </Select>
            </HStack>
            {showEditor && (
              <SmartTextEditor
                ref={textEditorRef}
                content={question?.body}
                hideSave
                size={SmartTextEditorSize.SMALL}
              />
            )}
            {showDropAndUpload && (
              <>
                {photo ? (
                  <Flex w="full" justifyContent="center">
                    <Stack w="fit-content">
                      <Image maxH={60} src={photo.file?.url} />
                      <Button onClick={clearImage}>Clear Image</Button>
                    </Stack>
                  </Flex>
                ) : (
                  <DropAndUpload
                    onDrop={onDrop}
                    progress={progress}
                    accept=".png,.jpg,.jpeg"
                  />
                )}
              </>
            )}
            {hasOptions && (
              <Stack>
                <HStack
                  width="100%"
                  padding={2}
                  cursor="pointer"
                  onClick={addToList}
                >
                  <Icon as={BiPlus} color="teal" />
                  <Text color="teal" fontWeight="bold">
                    Add option
                  </Text>
                </HStack>
                {options.map((option, index) => (
                  <HStack key={index}>
                    <Input
                      defaultValue={option}
                      onChange={(event) =>
                        insertOption(event.target.value, index)
                      }
                    />
                    <IconButton
                      aria-label="delete-option"
                      variant="outline"
                      size={'sm'}
                      colorScheme="red"
                      icon={<FiTrash />}
                      onClick={() => removeFromList(index)}
                    />
                  </HStack>
                ))}
              </Stack>
            )}
          </ModalBody>
          <ModalFooter>
            <Flex justifyContent="end">
              <Button onClick={onClose} marginRight="5">
                Cancel
              </Button>
              <Button variant="solid" colorScheme="teal" type="submit">
                Save
              </Button>
            </Flex>
          </ModalFooter>
        </chakra.form>
      </ModalContent>
    </Modal>
  );
};

export default CreateQuestionModal;
