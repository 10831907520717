/* eslint-disable camelcase */
import {
  Badge,
  Flex,
  Tooltip,
  useBreakpointValue,
  useDisclosure,
} from '@chakra-ui/react';
import { MultiValue } from 'react-select';
import {
  Eventcalendar,
  MbscEventcalendarView,
  MbscEventClickEvent,
  MbscEventCreatedEvent,
  MbscEventUpdateEvent,
  MbscResource,
  MbscCalendarColor,
  MbscCalendarEvent,
} from '@mobiscroll/react';
import { DateType } from '@mobiscroll/react/dist/src/core/util/datetime';
import {
  Appointment_Insert_Input,
  useSendAppointmentRescheduleConfirmationMutation,
  useUpdateAppointmentServicesMutation,
  useGetInverseAvailabilityMutation,
  ProviderFieldsFragment,
  LocationFieldsFragment,
  AppointmentService_Insert_Input,
  useUpdateAppointmentMutation,
} from '@webapp/graphql';
import { ObjectOption } from '@webapp/types';
import { useStores } from '@webapp/state-models';
import { configuredDayjs as dayjs } from '@webapp/util-time';
import { observer } from 'mobx-react-lite';
import { useRef, useEffect, useState, useCallback } from 'react';
import toast from 'react-hot-toast';
import BaseCalendar from './BaseCalendar';
import { MdMoreVert } from 'react-icons/md';

interface DayCalendarProps {
  events: MbscCalendarEvent[];
  filterDate: DateType;
  filterProviders: MultiValue<ObjectOption<ProviderFieldsFragment>>;
  filterLocations: MultiValue<ObjectOption<LocationFieldsFragment>>;
  isListMobileView: boolean;
  onEventClick: (event: MbscEventClickEvent, instance: Eventcalendar) => void;
  providers: MbscResource[];
  selectedView: string;
  setCalendarDate: (date: string) => void;
  view: MbscEventcalendarView;
}

export const DayCalendar = observer(
  ({
    events,
    filterDate,
    filterLocations,
    filterProviders,
    isListMobileView,
    onEventClick,
    providers,
    selectedView,
    setCalendarDate,
    view,
  }: DayCalendarProps) => {
    const isMobile = useBreakpointValue({ base: true, md: false });
    const lastUpdatedAppointment = useRef<string | number | null | undefined>(
      null
    );

    const [colors, setColors] = useState<MbscCalendarColor[]>([]);

    const {
      isOpen: isAppointmentUpdateConfirmationOpen,
      onClose: onAppointmentUpdateConfirmationClose,
      onOpen: onAppointmentUpdateConfirmationOpen,
    } = useDisclosure();

    const [
      sendAppointmentRescheduleConfirmation,
      { loading: confirmationLoading },
    ] = useSendAppointmentRescheduleConfirmationMutation({
      onCompleted: () => {
        toast.success('Confirmation SMS sent successfully');
        onAppointmentUpdateConfirmationClose();
        lastUpdatedAppointment.current = null;
      },
    });

    const {
      calendarTimeZone,
      setDraftAppointment,
      ui: { setShowCreateAppointmentSidebar },
      workspace,
    } = useStores();

    const [updateAppointmentServices] = useUpdateAppointmentServicesMutation({
      onCompleted: () => {
        toast.success('Updated appointment');
      },
    });

    const [updateAppointment] = useUpdateAppointmentMutation({
      onCompleted: () => {
        toast.success('Updated appointment');
      },
    });

    const [getInverseAvailability, { data: inverseData }] =
      useGetInverseAvailabilityMutation();

    // This fetches 4 times on render
    useEffect(() => {
      const locationsFilter = filterLocations
        .map((location) => `'${location.value}'`)
        .join(',');
      const providersFilter = filterProviders
        .map((provider) => `'${provider.value}'`)
        .join(',');
      getInverseAvailability({
        variables: {
          startDate: dayjs(filterDate.toString()).format('YYYY-MM-DD'),
          endDate: dayjs(filterDate.toString()).format('YYYY-MM-DD'),
          locationsFilter,
          providersFilter,
        },
      });
    }, [selectedView, filterDate, filterLocations, filterProviders]);

    useEffect(() => {
      if (providers) {
        const colors =
          inverseData?.getInverseAvailability?.inverseAvailability?.map(
            (inverse) => {
              const providerData = providers.find(
                (provider) => provider.id === inverse?.resource
              );
              if (!providerData) {
                return {
                  resource: inverse?.resource,
                  start: filterDate,
                  end: dayjs(filterDate.toString())
                    .add(23, 'hour')
                    .add(59, 'minute')
                    .format(),
                  background: '#f5f5f5',
                };
              }
              return { ...inverse, background: '#f5f5f5' };
            }
          ) as MbscCalendarColor[];
        setColors(colors);
      }
    }, [inverseData, providers]);

    const onEventCreated = async ({ event }: MbscEventCreatedEvent) => {
      setShowCreateAppointmentSidebar(false);
      setDraftAppointment({
        id: 'DRAFT',
        workspaceId: workspace?.id,
        type: 'patient_appointment',
        startTime: dayjs(event.start as string).toISOString(),
        endTime: dayjs(event.end as string).toISOString(),
        providerId: event.resource,
      });
      setShowCreateAppointmentSidebar(true);
    };

    const renderResource = (resource: MbscResource) => {
      if (resource.name) {
        const firstTwoLocations =
          resource.locations.length > 0 ? resource.locations.slice(0, 2) : [];
        const remainingLocations =
          resource.locations.length > 2 ? resource.locations.slice(2) : [];
        return (
          <Flex direction="column" alignItems="center">
            <div className="resource-template-content">
              <div className="resource-name">{resource.name}</div>
              {!isMobile && (
                <Flex>
                  {firstTwoLocations.map((location: string, index: number) => (
                    <Badge
                      key={index}
                      p={1}
                      mr={2}
                      fontSize="xs"
                      fontWeight="normal"
                      whiteSpace="normal"
                    >
                      {location}
                    </Badge>
                  ))}
                  {remainingLocations.length > 0 && (
                    <Tooltip
                      label={remainingLocations.join(', ')}
                      aria-label="Remaining Locations"
                    >
                      <Flex alignItems="center">
                        <MdMoreVert
                          style={{
                            fontSize: '1.5rem',
                            verticalAlign: 'middle',
                          }}
                        />
                      </Flex>
                    </Tooltip>
                  )}
                </Flex>
              )}
            </div>
          </Flex>
        );
      }
    };

    const onEventUpdate = async ({ event }: MbscEventUpdateEvent) => {
      const { start, end, resource } = event;

      const set: AppointmentService_Insert_Input | Appointment_Insert_Input = {
        id: event.id,
        timerange: JSON.stringify([start, end]),
      };

      if ((resource as Array<string>).length > 0) {
        const [providerId] = resource as Array<string>;
        set.providerId = providerId === 'No Provider' ? null : providerId;
      }

      if (event.type === 'other') {
        await updateAppointment({
          variables: {
            id: event.id,
            set,
          },
        });
        lastUpdatedAppointment.current = event.id;
      } else {
        await updateAppointmentServices({
          variables: {
            object: {
              appointmentId: event.appointmentId,
              services: [set],
              shouldUpdateAppointmentTimeRange: true,
            },
          },
        });
        lastUpdatedAppointment.current = event.appointmentId;
      }

      onAppointmentUpdateConfirmationOpen();
    };
    return (
      <BaseCalendar
        isLoading={confirmationLoading}
        calendarDate={filterDate}
        colors={colors}
        events={events}
        isOpen={isAppointmentUpdateConfirmationOpen}
        isListMobileView={isListMobileView}
        onClose={onAppointmentUpdateConfirmationClose}
        onConfirm={() => {
          sendAppointmentRescheduleConfirmation({
            variables: {
              appointmentId: lastUpdatedAppointment.current,
            },
          });
        }}
        onEventClick={onEventClick}
        onEventCreated={onEventCreated}
        onEventUpdate={onEventUpdate}
        renderResource={renderResource}
        resources={providers}
        selectedView={selectedView}
        setCalendarDate={setCalendarDate}
        view={view}
      />
    );
  }
);

export default DayCalendar;
