import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { PatientAddressFieldsFragmentDoc } from '../../fragments/patientAddressFields.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type SetDefaultPatientAddressMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
}>;


export type SetDefaultPatientAddressMutation = { __typename?: 'mutation_root', setDefaultPatientAddress?: Array<{ __typename?: 'SetDefaultPatientAddressOutput', patientAddress?: { __typename?: 'patientAddress', id: any, isDefault: boolean, type: string, status: string, address: { __typename?: 'address', addressLine1: string, addressLine2?: string | null | undefined, city: string, id: any, state: string, zipCode: string } } | null | undefined }> | null | undefined };


export const SetDefaultPatientAddressDocument = gql`
    mutation SetDefaultPatientAddress($id: uuid!) {
  setDefaultPatientAddress(patientAddressId: $id) {
    patientAddress {
      ...PatientAddressFields
    }
  }
}
    ${PatientAddressFieldsFragmentDoc}`;
export type SetDefaultPatientAddressMutationFn = Apollo.MutationFunction<SetDefaultPatientAddressMutation, SetDefaultPatientAddressMutationVariables>;

/**
 * __useSetDefaultPatientAddressMutation__
 *
 * To run a mutation, you first call `useSetDefaultPatientAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetDefaultPatientAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setDefaultPatientAddressMutation, { data, loading, error }] = useSetDefaultPatientAddressMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSetDefaultPatientAddressMutation(baseOptions?: Apollo.MutationHookOptions<SetDefaultPatientAddressMutation, SetDefaultPatientAddressMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetDefaultPatientAddressMutation, SetDefaultPatientAddressMutationVariables>(SetDefaultPatientAddressDocument, options);
      }
export type SetDefaultPatientAddressMutationHookResult = ReturnType<typeof useSetDefaultPatientAddressMutation>;
export type SetDefaultPatientAddressMutationResult = Apollo.MutationResult<SetDefaultPatientAddressMutation>;
export type SetDefaultPatientAddressMutationOptions = Apollo.BaseMutationOptions<SetDefaultPatientAddressMutation, SetDefaultPatientAddressMutationVariables>;