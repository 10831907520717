import { FormInputProps } from '@webapp/types';
import { useState, useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import {
  Box,
  FormControl,
  FormLabel,
  Grid,
  NumberInput,
  NumberInputField,
  Radio,
  RadioGroup,
  Stack,
  Text,
} from '@chakra-ui/react';
import { ErrorMessage } from '@hookform/error-message';
import { DEFAULT_LABEL_STYLE } from './constants';

export const convertFeetToInches = (feet: number, inches: number) =>
  feet * 12 + inches;

export const convertInchesToFeet = (inches: number) => {
  if (Number.isNaN(inches)) {
    return { feet: 0, inches: 0 };
  }
  const feet = Math.floor(inches / 12);
  const remainingInches = inches % 12;

  return { feet, inches: remainingInches };
};

export const convertInchesToCm = (inches: number) => inches * 2.54;

const convertCmToInches = (cm: number) => cm / 2.54;

function HeightInput({
  id,
  label,
  labelAlign,
  labelStyle = DEFAULT_LABEL_STYLE,
  name,
  disabled,
  isEditable,
}: FormInputProps) {
  const {
    formState: { errors },
    getValues,
    register,
    setValue,
    watch,
    control,
  } = useFormContext();

  const [feet, setFeet] = useState<number>();
  const [inches, setInches] = useState<number>();

  const height = watch('height');

  useEffect(() => {
    const { heightUnit } = getValues();

    if (heightUnit === 'in') {
      const { feet: cFeet, inches: cInches } = convertInchesToFeet(height);

      setFeet(cFeet);

      setInches(cInches ?? 0);
    } else {
      setValue('height', height);
    }
  }, [height]);

  useEffect(() => {
    setValue('feet', feet);
  }, [feet]);

  useEffect(() => {
    setValue('inches', inches ?? 0);
  }, [inches]);

  const heightUnit = watch('heightUnit') || 'in';

  const inputId = id ?? name;

  useEffect(() => {
    register(name);
  });

  return (
    <Grid
      key={`${height}-${heightUnit}-${feet}-${inches}`}
      alignItems={labelAlign}
      className="input-outer"
      gridAutoRows="max-content"
      gridRowGap="6px"
      gridTemplateColumns="1fr"
    >
      {Boolean(label) && (
        <FormLabel
          className="input-label"
          htmlFor={inputId}
          margin="0 0 0 0"
          {...labelStyle}
        >
          {label}
        </FormLabel>
      )}
      <Grid className="input-inner" gridRowGap="8px">
        {isEditable && (
          <FormControl>
            <FormLabel {...labelStyle}>Height Unit</FormLabel>
            <Controller
              name="heightUnit"
              control={control}
              defaultValue={heightUnit}
              render={({ field }) => (
                <RadioGroup
                  {...field}
                  onChange={(value) => setValue('heightUnit', value)}
                >
                  <Stack direction="row">
                    <Radio value="cm">Centimeters</Radio>
                    <Radio value="in">Feet & Inches</Radio>
                  </Stack>
                </RadioGroup>
              )}
            />
          </FormControl>
        )}

        {heightUnit === 'cm' ? (
          <FormControl>
            <FormLabel {...labelStyle}>Height (cm)</FormLabel>
            {isEditable ? (
              <Controller
                name="centimeters"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <NumberInput
                    min={0}
                    value={height}
                    onChange={(_, value) => setValue(name, value)}
                  >
                    <NumberInputField {...field} placeholder="Height in cm" />
                  </NumberInput>
                )}
              />
            ) : (
              <Text data-value-for="height">{height}</Text>
            )}
          </FormControl>
        ) : (
          <Box key={`${height}-${heightUnit}-${feet}-${inches}`}>
            <FormControl key={`${feet}-${inches}-${height}`}>
              <FormLabel {...labelStyle}>Height (ft)</FormLabel>
              {isEditable ? (
                <Controller
                  name="feet"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <NumberInput
                      key={`${height}-${heightUnit}-${feet}-${inches}`}
                      min={0}
                      value={feet}
                      onChange={(_, value) =>
                        setValue('height', convertFeetToInches(value, inches))
                      }
                    >
                      <NumberInputField {...field} placeholder="Height in ft" />
                    </NumberInput>
                  )}
                />
              ) : (
                <Text
                  data-value-for="feet"
                  key={`${height}-${heightUnit}-${feet}-${inches}`}
                >
                  {feet}
                </Text>
              )}
            </FormControl>

            <FormControl key={inches}>
              <FormLabel {...labelStyle}>Height (in)</FormLabel>
              {isEditable ? (
                <Controller
                  name="inches"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <NumberInput
                      min={0}
                      value={inches}
                      onChange={(_, value) =>
                        setValue('height', convertFeetToInches(feet, value))
                      }
                    >
                      <NumberInputField {...field} placeholder="Height in in" />
                    </NumberInput>
                  )}
                />
              ) : (
                <Text data-value-for="inches">{inches}</Text>
              )}
            </FormControl>
          </Box>
        )}

        <ErrorMessage
          errors={errors}
          name={name}
          render={({ message }) => (
            <Text
              className="input-error"
              color="red.500"
              _before={{
                display: 'inline',
                content: '"⚠ "',
              }}
            >
              {message}
            </Text>
          )}
        />
      </Grid>
    </Grid>
  );
}

export default HeightInput;
