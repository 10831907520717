import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { NoteFieldsFragmentDoc } from '../../fragments/noteFields.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type UpdateNoteByPkMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
  set?: Types.Maybe<Types.Note_Set_Input>;
}>;


export type UpdateNoteByPkMutation = { __typename?: 'mutation_root', update_note_by_pk?: { __typename?: 'note', isLocked?: boolean | null | undefined, createdAt: any, title?: string | null | undefined, isGoodFaithExam?: boolean | null | undefined, id: any, isInternal: boolean, message: string, appointmentId?: any | null | undefined, updatedAt: any, author: { __typename?: 'user', firstName: string, id: any, lastName: string, title?: string | null | undefined, profilePicture?: string | null | undefined }, editedBy?: { __typename?: 'user', firstName: string, id: any, lastName: string, title?: string | null | undefined } | null | undefined, noteSignatures: Array<{ __typename?: 'noteSignature', signature?: { __typename?: 'signature', id: any, filePath: string, provider?: { __typename?: 'provider', firstName: string, id: any, lastName: string, title?: string | null | undefined } | null | undefined, file?: { __typename?: 'FileOnlyURL', url: string } | null | undefined } | null | undefined }>, assignedToProvider?: { __typename?: 'provider', id: any, firstName: string, lastName: string, title?: string | null | undefined } | null | undefined, patient: { __typename?: 'patient', firstName: string, id: any, lastName: string } } | null | undefined };


export const UpdateNoteByPkDocument = gql`
    mutation UpdateNoteByPk($id: uuid!, $set: note_set_input) {
  update_note_by_pk(pk_columns: {id: $id}, _set: $set) {
    ...NoteFields
  }
}
    ${NoteFieldsFragmentDoc}`;
export type UpdateNoteByPkMutationFn = Apollo.MutationFunction<UpdateNoteByPkMutation, UpdateNoteByPkMutationVariables>;

/**
 * __useUpdateNoteByPkMutation__
 *
 * To run a mutation, you first call `useUpdateNoteByPkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNoteByPkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNoteByPkMutation, { data, loading, error }] = useUpdateNoteByPkMutation({
 *   variables: {
 *      id: // value for 'id'
 *      set: // value for 'set'
 *   },
 * });
 */
export function useUpdateNoteByPkMutation(baseOptions?: Apollo.MutationHookOptions<UpdateNoteByPkMutation, UpdateNoteByPkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateNoteByPkMutation, UpdateNoteByPkMutationVariables>(UpdateNoteByPkDocument, options);
      }
export type UpdateNoteByPkMutationHookResult = ReturnType<typeof useUpdateNoteByPkMutation>;
export type UpdateNoteByPkMutationResult = Apollo.MutationResult<UpdateNoteByPkMutation>;
export type UpdateNoteByPkMutationOptions = Apollo.BaseMutationOptions<UpdateNoteByPkMutation, UpdateNoteByPkMutationVariables>;