import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { ServiceFieldsFragmentDoc } from '../../fragments/serviceFields.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetServicesQueryVariables = Types.Exact<{
  where?: Types.Maybe<Types.Service_Bool_Exp>;
  limit?: Types.Maybe<Types.Scalars['Int']>;
  offset?: Types.Maybe<Types.Scalars['Int']>;
  orderBy?: Types.Maybe<Array<Types.Service_Order_By> | Types.Service_Order_By>;
}>;


export type GetServicesQuery = { __typename?: 'query_root', service: Array<{ __typename?: 'service', id: any, taxRate?: any | null | undefined, deposit?: number | null | undefined, requiresDeposit?: boolean | null | undefined, name: string, active?: boolean | null | undefined, description?: string | null | undefined, image?: string | null | undefined, availableInStore?: boolean | null | undefined, canBeBookedOnline?: boolean | null | undefined, minutesToPerform: number, newPatientMinutesToPerform?: number | null | undefined, deviceBufferTime?: number | null | undefined, price: number, isProduct?: boolean | null | undefined, onlineBookingRules?: any | null | undefined, color?: string | null | undefined, serviceUnitId?: any | null | undefined, serviceCategoryId?: any | null | undefined, pricingModel: string, serviceUnit?: { __typename?: 'serviceUnit', id: any, name: string } | null | undefined, locationServices: Array<{ __typename?: 'locationService', id: any, locationId: any, price?: any | null | undefined, taxRate?: any | null | undefined }>, serviceCategory?: { __typename?: 'serviceCategory', title: string } | null | undefined, appointmentType?: { __typename?: 'appointmentType', name: string, color?: string | null | undefined } | null | undefined, serviceDeviceTypes: Array<{ __typename?: 'serviceDeviceType', deviceType: { __typename?: 'deviceType', value: string } }>, serviceConsumableTypes: Array<{ __typename?: 'serviceConsumableType', numberOfUnits: any, pricePerUnit: number, consumableType: { __typename?: 'consumableType', id: any, name: string, requiredFields: any, unitStep: any } }>, precareDocument?: { __typename?: 'document', title?: string | null | undefined, file?: { __typename?: 'FileOnlyURL', url: string } | null | undefined } | null | undefined, postcareDocument?: { __typename?: 'document', title?: string | null | undefined, file?: { __typename?: 'FileOnlyURL', url: string } | null | undefined } | null | undefined }> };


export const GetServicesDocument = gql`
    query GetServices($where: service_bool_exp, $limit: Int, $offset: Int, $orderBy: [service_order_by!]) {
  service(where: $where, limit: $limit, offset: $offset, order_by: $orderBy) {
    ...ServiceFields
  }
}
    ${ServiceFieldsFragmentDoc}`;

/**
 * __useGetServicesQuery__
 *
 * To run a query within a React component, call `useGetServicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetServicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetServicesQuery({
 *   variables: {
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetServicesQuery(baseOptions?: Apollo.QueryHookOptions<GetServicesQuery, GetServicesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetServicesQuery, GetServicesQueryVariables>(GetServicesDocument, options);
      }
export function useGetServicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetServicesQuery, GetServicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetServicesQuery, GetServicesQueryVariables>(GetServicesDocument, options);
        }
export type GetServicesQueryHookResult = ReturnType<typeof useGetServicesQuery>;
export type GetServicesLazyQueryHookResult = ReturnType<typeof useGetServicesLazyQuery>;
export type GetServicesQueryResult = Apollo.QueryResult<GetServicesQuery, GetServicesQueryVariables>;