import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type CreateConsumableAndAddToLocationMutationVariables = Types.Exact<{
  locationId: Types.Scalars['uuid'];
  consumable: Types.Scalars['jsonb'];
}>;


export type CreateConsumableAndAddToLocationMutation = { __typename?: 'mutation_root', createConsumableAndAddToLocation: { __typename?: 'CreateConsumableAndAddToLocationOutput', locationId: any, consumableId: any, creditId: any, debitId: any, quantity: number } };


export const CreateConsumableAndAddToLocationDocument = gql`
    mutation CreateConsumableAndAddToLocation($locationId: uuid!, $consumable: jsonb!) {
  createConsumableAndAddToLocation(
    locationId: $locationId
    consumable: $consumable
  ) {
    locationId
    consumableId
    creditId
    debitId
    quantity
  }
}
    `;
export type CreateConsumableAndAddToLocationMutationFn = Apollo.MutationFunction<CreateConsumableAndAddToLocationMutation, CreateConsumableAndAddToLocationMutationVariables>;

/**
 * __useCreateConsumableAndAddToLocationMutation__
 *
 * To run a mutation, you first call `useCreateConsumableAndAddToLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateConsumableAndAddToLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createConsumableAndAddToLocationMutation, { data, loading, error }] = useCreateConsumableAndAddToLocationMutation({
 *   variables: {
 *      locationId: // value for 'locationId'
 *      consumable: // value for 'consumable'
 *   },
 * });
 */
export function useCreateConsumableAndAddToLocationMutation(baseOptions?: Apollo.MutationHookOptions<CreateConsumableAndAddToLocationMutation, CreateConsumableAndAddToLocationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateConsumableAndAddToLocationMutation, CreateConsumableAndAddToLocationMutationVariables>(CreateConsumableAndAddToLocationDocument, options);
      }
export type CreateConsumableAndAddToLocationMutationHookResult = ReturnType<typeof useCreateConsumableAndAddToLocationMutation>;
export type CreateConsumableAndAddToLocationMutationResult = Apollo.MutationResult<CreateConsumableAndAddToLocationMutation>;
export type CreateConsumableAndAddToLocationMutationOptions = Apollo.BaseMutationOptions<CreateConsumableAndAddToLocationMutation, CreateConsumableAndAddToLocationMutationVariables>;