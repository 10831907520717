import { forwardRef } from 'react';
import {
  Button,
  HStack,
  IconButton,
  Input,
  InputGroup,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput as ChakraNumberInput,
  NumberInputField,
  NumberInputStepper,
  StyleProps,
  Text,
  useBreakpointValue,
  useNumberInput,
} from '@chakra-ui/react';
import { FormInputProps } from '@webapp/types';
import { AiOutlineMenu, AiOutlineMinus, AiOutlinePlus } from 'react-icons/ai';
import InputWrapper from '../InputWrapper/InputWrapper';
import FormInputWrapper from '../FormInput/components/FormInputWrapper';

export type NumberInputProps = {
  customStyle?: {
    input?: StyleProps;
  };
  defaultValue?: number;
  disabled?: boolean;
  errors?: any;
  hasIncrement?: boolean;
  incrementStep?: number;
  max?: number;
  min?: number;
  onChange?: (value: number, valueString: string) => void;
  onMount?: () => void;
  precision?: number;
  register?: any;
  value?: number | string;
  isReadOnly?: boolean;
} & Pick<
  FormInputProps,
  | 'id'
  | 'isEditable'
  | 'label'
  | 'labelAlign'
  | 'labelStyle'
  | 'labelSubText'
  | 'name'
  | 'style'
>;

export function NumberInput(
  {
    customStyle = {},
    disabled = false,
    defaultValue = 0,
    hasIncrement = true,
    incrementStep = 1,
    max,
    min,
    onChange,
    onMount,
    precision = 0,
    register,
    isDisabled,
    isReadOnly,
    ...formInputProps
  }: NumberInputProps,
  ref?: any
) {
  const { name = '', value, isEditable } = formInputProps;
  const isDesktop = useBreakpointValue({ md: true });
  const isMobile = !isDesktop;
  function handleChange(valueAsString: string, valueAsNumber: number) {
    if (onChange) {
      onChange(valueAsNumber, valueAsString);
    }
  }
  const { getInputProps, getIncrementButtonProps, getDecrementButtonProps } =
    useNumberInput({
      step: incrementStep,
      defaultValue,
      min,
      max,
      name,
      precision,
      isDisabled: disabled,
      onChange: (valueAsString, valueAsNumber) =>
        onChange && onChange(valueAsNumber, valueAsString),
      value,
    });

  const inc = getIncrementButtonProps();
  const dec = getDecrementButtonProps();
  const input = getInputProps();

  if (isMobile) {
    return (
      <FormInputWrapper {...formInputProps}>
        <HStack width={'100%'}>
          <Input {...input} readOnly={isReadOnly} />
          <Button
            width={'40px'}
            height={'40px'}
            colorScheme={'teal'}
            variant={'outline'}
            {...dec}
          >
            <IconButton
              aria-label="Subtract one"
              icon={<AiOutlineMinus />}
              size={'sm'}
              width={'24px'}
              height={'24px'}
              colorScheme={'teal'}
              variant={'outline'}
              border={'0px'}
            />
          </Button>
          <Button
            width={'40px'}
            height={'40px'}
            colorScheme={'teal'}
            variant={'outline'}
            {...inc}
          >
            <IconButton
              aria-label="Add one"
              icon={<AiOutlinePlus />}
              size={'sm'}
              width={'24px'}
              height={'24px'}
              colorScheme={'teal'}
              variant={'outline'}
              border={'0px'}
            />
          </Button>
        </HStack>
      </FormInputWrapper>
    );
  }

  return (
    <InputWrapper
      data-value-for={name}
      {...formInputProps}
      displayValue={value}
    >
      <InputGroup>
        <ChakraNumberInput
          defaultValue={defaultValue}
          name={name}
          data-value-for={name}
          maxHeight="38px"
          maxWidth="200px"
          min={min}
          max={max}
          onChange={handleChange}
          precision={precision}
          step={incrementStep}
          value={value}
          isDisabled={isDisabled}
          {...customStyle.input}
        >
          <NumberInputField
            data-value-for={name}
            disabled={disabled}
            readOnly={isReadOnly}
          />
          {hasIncrement && (
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          )}
        </ChakraNumberInput>
      </InputGroup>
    </InputWrapper>
  );
}

export default forwardRef(NumberInput);
