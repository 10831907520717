import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
export type SimpleAppointmentFieldsFragment = { __typename?: 'appointment', id: any, isDraft: boolean, startTime?: any | null | undefined, note?: string | null | undefined, status?: string | null | undefined, timerange?: any | null | undefined, type: string, location: { __typename?: 'location', id: any, name: string, timezone?: string | null | undefined }, patient?: { __typename?: 'patient', id: any, attributes: { __typename?: 'PatientAttributes', firstName?: string | null | undefined, id?: string | null | undefined, lastName?: string | null | undefined } } | null | undefined, provider?: { __typename?: 'provider', firstName: string, id: any, lastName: string, title?: string | null | undefined } | null | undefined, room?: { __typename?: 'room', id: any, name: string } | null | undefined };

export const SimpleAppointmentFieldsFragmentDoc = gql`
    fragment SimpleAppointmentFields on appointment {
  id
  isDraft
  location {
    id
    name
    timezone
  }
  startTime
  note
  patient {
    attributes {
      firstName
      id
      lastName
    }
    id
  }
  provider {
    firstName
    id
    lastName
    title
  }
  room {
    id
    name
  }
  status
  timerange
  type
}
    `;