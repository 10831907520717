import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type ServiceCategoryAggregateQueryVariables = Types.Exact<{
  where?: Types.Maybe<Types.ServiceCategory_Bool_Exp>;
}>;


export type ServiceCategoryAggregateQuery = { __typename?: 'query_root', serviceCategory_aggregate: { __typename?: 'serviceCategory_aggregate', aggregate?: { __typename?: 'serviceCategory_aggregate_fields', count: number } | null | undefined } };


export const ServiceCategoryAggregateDocument = gql`
    query serviceCategoryAggregate($where: serviceCategory_bool_exp) {
  serviceCategory_aggregate(where: $where) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useServiceCategoryAggregateQuery__
 *
 * To run a query within a React component, call `useServiceCategoryAggregateQuery` and pass it any options that fit your needs.
 * When your component renders, `useServiceCategoryAggregateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useServiceCategoryAggregateQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useServiceCategoryAggregateQuery(baseOptions?: Apollo.QueryHookOptions<ServiceCategoryAggregateQuery, ServiceCategoryAggregateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ServiceCategoryAggregateQuery, ServiceCategoryAggregateQueryVariables>(ServiceCategoryAggregateDocument, options);
      }
export function useServiceCategoryAggregateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ServiceCategoryAggregateQuery, ServiceCategoryAggregateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ServiceCategoryAggregateQuery, ServiceCategoryAggregateQueryVariables>(ServiceCategoryAggregateDocument, options);
        }
export type ServiceCategoryAggregateQueryHookResult = ReturnType<typeof useServiceCategoryAggregateQuery>;
export type ServiceCategoryAggregateLazyQueryHookResult = ReturnType<typeof useServiceCategoryAggregateLazyQuery>;
export type ServiceCategoryAggregateQueryResult = Apollo.QueryResult<ServiceCategoryAggregateQuery, ServiceCategoryAggregateQueryVariables>;