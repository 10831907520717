import {
  Box,
  Heading,
  HStack,
  Select,
  Grid,
  GridItem,
  Stack,
  RadioGroup,
  Radio,
  Spinner,
  Switch,
} from '@chakra-ui/react';
import dayjs from 'dayjs';
// import { DateTimePicker } from '@atlaskit/datetime-picker';
import { useStores } from '@webapp/state-models';
import { useGetAudienceQuery } from '@webapp/graphql';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import DateInput from '../../../../../ui/src/lib/FormInputV2/DateInput';

export const EmailScheduler = observer(
  ({
    emailTemplate,
    previewHtml,
  }: {
    emailTemplate: any;
    previewHtml: any;
  }) => {
    const { workspace, loadAudience } = useStores();
    const [audienceType, setAudienceType] = useState(
      emailTemplate?.audienceType || 'active'
    );
    const { data, loading } = useGetAudienceQuery({
      variables: {
        where: {
          workspaceId: {
            _eq: workspace?.id,
          },
        },
      },
    });
    useEffect(() => {
      if (
        data &&
        data?.audience.length > 0 &&
        !emailTemplate.targetAudienceId
      ) {
        const defaultAudience = data?.audience[0];
        emailTemplate.setTargetAudienceId(defaultAudience.id);

        if (defaultAudience.id) loadAudience(defaultAudience.id);
      } else if (emailTemplate.targetAudienceId) {
        loadAudience(emailTemplate.targetAudienceId);
      }
    }, [data]);

    const dtToIsoString = (d: string) => dayjs(d).toISOString();

    const changeAudienceType = (audienceType: string) => {
      setAudienceType(audienceType);
      emailTemplate.setAudienceType(audienceType);
      // On selecting a different audience type, automatically select the first available audience
      // of that type as well, otherwise stale audience will be saved.
      const selectedAudiences = data?.audience.filter(
        (a) => a.type === audienceType
      );
      if (selectedAudiences && selectedAudiences.length > 0) {
        emailTemplate.setTargetAudienceId(selectedAudiences[0].id);
        loadAudience(selectedAudiences[0].id);
      }
    };

    return loading ? (
      <Spinner />
    ) : (
      <Grid gridTemplateColumns="1fr 2fr" width="100%" px={8}>
        <GridItem pr="10">
          <Stack>
            <Heading size="sm" pb="5">
              Recipients
            </Heading>
            <Box>
              <Heading size="xs">Audience Type</Heading>
            </Box>
            <Box py="3">
              <Select
                size="sm"
                defaultValue={audienceType}
                onChange={(e) => changeAudienceType(e.target.value)}
              >
                <option value="active">Active</option>
                <option value="static">Static</option>
              </Select>
            </Box>
            <Box>
              <Heading size="xs">Send To</Heading>
            </Box>
            <Box py="3">
              <Select
                size="sm"
                defaultValue={emailTemplate.targetAudienceId}
                onChange={(e) => {
                  emailTemplate.setTargetAudienceId(e.target.value);
                  loadAudience(e.target.value);
                }}
              >
                {data?.audience
                  .filter((a) => a.type === audienceType)
                  .map((audience, idx) => (
                    <option key={idx} value={audience.id}>
                      {audience.name}
                    </option>
                  ))}
              </Select>
            </Box>
            <Box>
              <Heading size="xs">Don't Send To</Heading>
            </Box>
            <Box py="3">
              <Select
                size="sm"
                defaultValue={emailTemplate.omitAudienceId}
                onChange={(e) =>
                  emailTemplate.setOmitAudienceId(
                    e.target.value ? e.target.value : null
                  )
                }
              >
                <option key="null_omit_audience" value={''}></option>
                {data?.audience
                  .filter((a) => a.type === emailTemplate.audienceType)
                  .map((audience, idx) => (
                    <option key={idx} value={audience.id}>
                      {audience.name}
                    </option>
                  ))}
              </Select>
            </Box>
            <Box py="3">
              <HStack>
                <Heading size="sm" pr="5">
                  Promotion Included
                </Heading>
                <Switch
                  colorScheme="teal"
                  onChange={(e) =>
                    emailTemplate.setIncludesPromotion(e.target.checked)
                  }
                />
              </HStack>
            </Box>
            <Box>
              <HStack>
                <Heading size="xs" w="100px">
                  Off/On
                </Heading>
                <Switch
                  px="2"
                  defaultChecked={emailTemplate.isActive}
                  onChange={(e) => emailTemplate.setIsActive(e.target.checked)}
                />
              </HStack>
            </Box>
            <Box py="3">
              <Heading size="sm" pr="5">
                Sending Options
              </Heading>
            </Box>
            <Box>
              <Stack>
                <RadioGroup
                  value={emailTemplate.sendType}
                  onChange={(val: string) => {
                    emailTemplate.setSendType(val);
                  }}
                >
                  <Radio
                    colorScheme="teal"
                    size="sm"
                    name="sendNow"
                    value="now"
                    pr="5"
                    isChecked={
                      emailTemplate.sendType === 'now' ||
                      emailTemplate.sendType === '' ||
                      emailTemplate.sendType === null
                    }
                  >
                    <Heading size="xs">Send Now</Heading>
                  </Radio>
                  <Radio
                    colorScheme="teal"
                    size="sm"
                    name="sendNow"
                    value="later"
                    isChecked={emailTemplate.sendType === 'later'}
                  >
                    <Heading size="xs">Send Later</Heading>
                  </Radio>
                </RadioGroup>
                {emailTemplate.sendType === 'later' && (
                  //   <DateTimePicker
                  //     onChange={(d) =>
                  //       d ? emailTemplate.setSendDateTime(dtToIsoString(d)) : null
                  //     }
                  //     value={emailTemplate.sendDateTime || dayjs().toISOString()}
                  //   />
                  <DateInput
                    controls={['calendar', 'timegrid']}
                    displayFormat="MM/DD/YYYY h:mm A"
                    onChange={(d) =>
                      d ? emailTemplate.setSendDateTime(dtToIsoString(d)) : null
                    }
                    value={emailTemplate.sendDateTime || dayjs().toISOString()}
                  />
                )}
              </Stack>
            </Box>
          </Stack>
        </GridItem>
        <GridItem>
          <iframe srcDoc={previewHtml} width="100%" height="100%" />
        </GridItem>
      </Grid>
    );
  }
);

export default EmailScheduler;
