// lib/apolloClient.js

import { ApolloClient, HttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';

const hasuraGraphqlApi = import.meta.env.VITE_GRAPHQL_ENDPOINT;

export const GraphqlClient = (extraHeaders = {}) => {
  const authMiddleware = setContext(async (req, { headers }) => {
    const token = localStorage.getItem(
      `prospyr_token_${import.meta.env.VITE_ENV}`
    );
    const currentWorkspace = localStorage.getItem(
      `prspr_wid_${import.meta.env.VITE_ENV}`
    );
    return {
      headers: {
        ...headers,
        ...extraHeaders,
        authorization: `Bearer ${token}`,
        currentWorkspace,
      },
    };
  });

  const httpLink = new HttpLink({
    uri: hasuraGraphqlApi,
  });

  const errorLink = onError(({ graphQLErrors, networkError }: any) => {
    if (graphQLErrors)
      graphQLErrors.map(({ message, extensions }: any) => {
        console.error(
          `[GraphQL error]: Message: ${message},  Extension: ${JSON.stringify(
            extensions,
            null,
            2
          )}`
        );
      });
    if (networkError) console.error(`[Network error]: ${networkError}`);
  });

  const apolloClient = new ApolloClient({
    link: errorLink.concat(authMiddleware.concat(httpLink)),
    cache: new InMemoryCache({
      resultCaching: false,
      typePolicies: {
        FilePayload: {
          keyFields: ['filePath'],
        },
      },
    }),
  });

  return apolloClient;
};

export default GraphqlClient;
