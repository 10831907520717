import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type ScheduleSmsSendMutationVariables = Types.Exact<{
  smsTemplateId: Types.Scalars['String'];
}>;


export type ScheduleSmsSendMutation = { __typename?: 'mutation_root', scheduleSmsSend?: { __typename?: 'SuccessAnswer', success: boolean } | null | undefined };


export const ScheduleSmsSendDocument = gql`
    mutation scheduleSmsSend($smsTemplateId: String!) {
  scheduleSmsSend(smsTemplateId: $smsTemplateId) {
    success
  }
}
    `;
export type ScheduleSmsSendMutationFn = Apollo.MutationFunction<ScheduleSmsSendMutation, ScheduleSmsSendMutationVariables>;

/**
 * __useScheduleSmsSendMutation__
 *
 * To run a mutation, you first call `useScheduleSmsSendMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useScheduleSmsSendMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [scheduleSmsSendMutation, { data, loading, error }] = useScheduleSmsSendMutation({
 *   variables: {
 *      smsTemplateId: // value for 'smsTemplateId'
 *   },
 * });
 */
export function useScheduleSmsSendMutation(baseOptions?: Apollo.MutationHookOptions<ScheduleSmsSendMutation, ScheduleSmsSendMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ScheduleSmsSendMutation, ScheduleSmsSendMutationVariables>(ScheduleSmsSendDocument, options);
      }
export type ScheduleSmsSendMutationHookResult = ReturnType<typeof useScheduleSmsSendMutation>;
export type ScheduleSmsSendMutationResult = Apollo.MutationResult<ScheduleSmsSendMutation>;
export type ScheduleSmsSendMutationOptions = Apollo.BaseMutationOptions<ScheduleSmsSendMutation, ScheduleSmsSendMutationVariables>;