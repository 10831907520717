import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { RoomFieldsFragmentDoc } from '../../fragments/roomFields.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type InsertRoomMutationVariables = Types.Exact<{
  room: Types.Room_Insert_Input;
}>;


export type InsertRoomMutation = { __typename?: 'mutation_root', insert_room?: { __typename?: 'room_mutation_response', returning: Array<{ __typename?: 'room', id: any, name: string }> } | null | undefined };


export const InsertRoomDocument = gql`
    mutation insertRoom($room: room_insert_input!) {
  insert_room(objects: [$room]) {
    returning {
      ...RoomFields
    }
  }
}
    ${RoomFieldsFragmentDoc}`;
export type InsertRoomMutationFn = Apollo.MutationFunction<InsertRoomMutation, InsertRoomMutationVariables>;

/**
 * __useInsertRoomMutation__
 *
 * To run a mutation, you first call `useInsertRoomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertRoomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertRoomMutation, { data, loading, error }] = useInsertRoomMutation({
 *   variables: {
 *      room: // value for 'room'
 *   },
 * });
 */
export function useInsertRoomMutation(baseOptions?: Apollo.MutationHookOptions<InsertRoomMutation, InsertRoomMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertRoomMutation, InsertRoomMutationVariables>(InsertRoomDocument, options);
      }
export type InsertRoomMutationHookResult = ReturnType<typeof useInsertRoomMutation>;
export type InsertRoomMutationResult = Apollo.MutationResult<InsertRoomMutation>;
export type InsertRoomMutationOptions = Apollo.BaseMutationOptions<InsertRoomMutation, InsertRoomMutationVariables>;