import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { SimpleAppointmentFieldsFragmentDoc } from '../../fragments/appointment/simpleAppointmentFields.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetPatientAppointmentQueryVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
}>;


export type GetPatientAppointmentQuery = { __typename?: 'query_root', appointment_by_pk?: { __typename?: 'appointment', id: any, isDraft: boolean, startTime?: any | null | undefined, note?: string | null | undefined, status?: string | null | undefined, timerange?: any | null | undefined, type: string, location: { __typename?: 'location', address?: string | null | undefined, id: any, name: string, timezone?: string | null | undefined }, services: Array<{ __typename?: 'appointmentService', id: any, type?: string | null | undefined, serviceId: any, service: { __typename?: 'service', id: any, name: string } }>, patient?: { __typename?: 'patient', id: any, attributes: { __typename?: 'PatientAttributes', firstName?: string | null | undefined, id?: string | null | undefined, lastName?: string | null | undefined } } | null | undefined, provider?: { __typename?: 'provider', firstName: string, id: any, lastName: string, title?: string | null | undefined } | null | undefined, room?: { __typename?: 'room', id: any, name: string } | null | undefined } | null | undefined };


export const GetPatientAppointmentDocument = gql`
    query GetPatientAppointment($id: uuid!) {
  appointment_by_pk(id: $id) {
    ...SimpleAppointmentFields
    location {
      address
    }
    services: appointmentServices {
      id
      type
      serviceId
      service {
        id
        name
      }
    }
  }
}
    ${SimpleAppointmentFieldsFragmentDoc}`;

/**
 * __useGetPatientAppointmentQuery__
 *
 * To run a query within a React component, call `useGetPatientAppointmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPatientAppointmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPatientAppointmentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPatientAppointmentQuery(baseOptions: Apollo.QueryHookOptions<GetPatientAppointmentQuery, GetPatientAppointmentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPatientAppointmentQuery, GetPatientAppointmentQueryVariables>(GetPatientAppointmentDocument, options);
      }
export function useGetPatientAppointmentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPatientAppointmentQuery, GetPatientAppointmentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPatientAppointmentQuery, GetPatientAppointmentQueryVariables>(GetPatientAppointmentDocument, options);
        }
export type GetPatientAppointmentQueryHookResult = ReturnType<typeof useGetPatientAppointmentQuery>;
export type GetPatientAppointmentLazyQueryHookResult = ReturnType<typeof useGetPatientAppointmentLazyQuery>;
export type GetPatientAppointmentQueryResult = Apollo.QueryResult<GetPatientAppointmentQuery, GetPatientAppointmentQueryVariables>;