import {
  chakra,
  Button,
  FormControl,
  FormLabel,
  HStack,
  Text,
  InputGroup,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  StyleProps,
  ButtonProps,
} from '@chakra-ui/react';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { toLocalePriceInCents } from '@webapp/util-formatting';
import {
  PaymentMethod,
  useInsertPaymentMethodMutation,
  useUpdatePatientWorkspaceMutation,
} from '@webapp/graphql';
import {
  PayrixToken,
  PayrixTokenResponse,
  PayrixTxnTokenResponse,
} from '@webapp/types';
import { omit } from 'lodash';
import { Amex, Visa, Mastercard, Discover } from '../CreditCardIcons';
import { RadioCard, RadioCardGroup } from '../RadioCardGroup/RadioCardGroup';
import useConfiguredPayFields from './useConfiguredPayFields/useConfiguredPayfields';
import { PayrixFieldsValues } from './useConfiguredPayFields/types';
import extractPayrixTokenInformation from './extractTokenInformation';
import isTxnToken from './isTxnToken';

export interface PayrixPayfieldsProps<Mode extends 'txnToken' | 'token'> {
  amount: number;
  authenticated: boolean;
  defaultValues?: PayrixFieldsValues;
  isEditable?: boolean;
  isLoading?: boolean;
  mode?: Mode;
  onChange?: (value: number) => void;
  onComplete?: () => void;
  onPayWithExistingMethod?: (
    args: {
      amount: number;
      paymentMethodId: string;
    },
    billingInfo?: PayrixFieldsValues
  ) => void;
  onPayWithNewMethod: (response: {
    transaction?: Omit<PayrixTxnTokenResponse['data'][number], 'token'>;
    token: PayrixToken;
  }) => void;
  patientId?: string;
  paymentMethods?: Pick<PaymentMethod, 'id' | 'last4'>[];
  payrixMerchantId: string;
  refetchQueries?: string[];
  showPaymentAmount?: boolean;
  showSavedCards?: boolean;
  styleProps?: {
    addressContainer?: StyleProps;
    amountContainer?: StyleProps;
    amountLabel?: StyleProps;
    amountText?: StyleProps;
    container?: StyleProps;
    tab?: StyleProps;
    payButton?: ButtonProps;
    payFieldsContainer?: StyleProps;
  };
  walletAmount?: number;
  workspaceId: string;
}

export function PayrixPayFields<Mode extends 'txnToken' | 'token'>({
  amount,
  authenticated = false,
  defaultValues,
  isEditable = false,
  isLoading: isLoadingFromProps = false,
  mode,
  onChange,
  onPayWithExistingMethod = () => undefined,
  onPayWithNewMethod,
  patientId,
  styleProps = {},
  showPaymentAmount = true,
  showSavedCards = false,
  paymentMethods = [],
  payrixMerchantId,
  workspaceId,
}: PayrixPayfieldsProps<Mode>) {
  const {
    container: containerStyle = {},
    tab: tabStyle = {},
    payButton: payButtonStyle = {},
    payFieldsContainer: payFieldsContainerStyle = {},
  } = styleProps;

  const [isPayrixLoading, setIsPayrixLoading] = useState<boolean>(false);
  const [updatePatientWorkspace] = useUpdatePatientWorkspaceMutation();
  const [insertPaymentMethod] = useInsertPaymentMethodMutation();

  const onSuccess = async (
    response: PayrixTokenResponse | PayrixTxnTokenResponse
  ) => {
    const [token] = extractPayrixTokenInformation(response);

    if (patientId && authenticated) {
      await updatePatientWorkspace({
        variables: {
          where: {
            workspaceId: { _eq: workspaceId },
            patientId: { _eq: patientId },
          },
          _set: {
            payrixCustomerId: token.customer.id as string,
          },
        },
      });

      await insertPaymentMethod({
        variables: {
          paymentMethod: {
            patientId,
            token: token.token,
            last4: token.payment.number,
            payrixCustomerId: token.customer.id as string,
            workspaceId,
          },
        },
      });
    }

    onPayWithNewMethod({
      transaction: isTxnToken(response)
        ? omit(response.data[0], 'token')
        : undefined,
      token,
    });
  };

  const PayFields = useConfiguredPayFields({
    amount,
    defaultValues,
    mode,
    onFailure: () => setIsPayrixLoading(false),
    onFinish: () => setIsPayrixLoading(false),
    onSuccess,
    payrixMerchantId,
    workspaceId,
  });

  const [tabIndex, setTabIndex] = useState<number>(
    paymentMethods.length && showSavedCards ? 1 : 0
  );
  const [paymentMethod, setPaymentMethod] = useState<string | undefined>(
    paymentMethods[0]?.id ?? undefined
  );

  async function pay() {
    if (amount <= 0) {
      toast.error(`Payment amount must be greater than 0.`);
      return;
    }

    if (tabIndex === 1 && paymentMethod) {
      onPayWithExistingMethod(
        {
          amount,
          paymentMethodId: paymentMethod,
        },
        defaultValues
      );
    } else {
      setIsPayrixLoading(true);
      PayFields.submit();
    }
  }

  const isLoading = isPayrixLoading;

  return (
    <Stack {...containerStyle}>
      <Tabs
        index={tabIndex}
        onChange={(index: number) => setTabIndex(index)}
        width={'full'}
      >
        <TabList>
          <Tab>New Card</Tab>
          {showSavedCards && (
            <Tab isDisabled={!paymentMethods.length}>Saved Card</Tab>
          )}
        </TabList>

        <TabPanels>
          <TabPanel {...tabStyle}>
            <chakra.div id="address" display="none" />
            <chakra.div id="name" display="none" />
            <chakra.div
              display="grid"
              gridTemplateAreas={{
                base: `
                    "number number"
                    "exp cvv"
                `,
                sm: `
                    "number number"
                    "exp cvv"
                `,
              }}
              {...payFieldsContainerStyle}
            >
              <FormControl gridArea="number">
                <FormLabel>Card number</FormLabel>
                <InputGroup h={'40px'} mb={2}>
                  <chakra.div id="number" width="100%" />
                </InputGroup>
              </FormControl>
              <FormControl gridArea="exp">
                <FormLabel>Expiration</FormLabel>
                <InputGroup h={'40px'}>
                  <div id="expiration" />
                </InputGroup>
              </FormControl>
              <FormControl gridArea="cvv">
                <FormLabel>CVV</FormLabel>
                <InputGroup h={'40px'}>
                  <div id="cvv" />
                </InputGroup>
              </FormControl>
              {/* <HStack></HStack> */}
            </chakra.div>
          </TabPanel>
          {showSavedCards && (
            <TabPanel {...tabStyle}>
              <RadioCardGroup
                w={'full'}
                spacing="3"
                value={paymentMethod}
                onChange={setPaymentMethod}
              >
                {paymentMethods.map((pm) => (
                  <RadioCard key={pm.id} value={pm.id}>
                    <Text color="muted" fontWeight="medium" fontSize="sm">
                      Card ending in
                    </Text>
                    <Text color="emphasized" fontSize="sm">
                      {pm.last4}
                    </Text>
                  </RadioCard>
                ))}
              </RadioCardGroup>
            </TabPanel>
          )}
        </TabPanels>
      </Tabs>

      <Button
        colorScheme="teal"
        isDisabled={isLoading || isLoadingFromProps}
        onClick={pay}
        w="full"
        data-test-id="payrix-pay-button"
        isLoading={isLoading || isLoadingFromProps}
        {...payButtonStyle}
      >
        {showPaymentAmount ? `Pay ${toLocalePriceInCents(amount)}` : 'Submit'}
      </Button>
      <HStack justifyContent={'center'} spacing={1} py={4}>
        <Amex height={'25'} width={'60'} />
        <Visa height={'25'} width={'60'} />
        <Mastercard height={'25'} width={'60'} />
        <Discover height={'25'} width={'60'} />
      </HStack>
    </Stack>
  );
}

export default PayrixPayFields;
