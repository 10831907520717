import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type UpdateIncompatibleServicesMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
  objects: Array<Types.IncompatibleServices_Insert_Input> | Types.IncompatibleServices_Insert_Input;
}>;


export type UpdateIncompatibleServicesMutation = { __typename?: 'mutation_root', delete_incompatibleServices?: { __typename?: 'incompatibleServices_mutation_response', affected_rows: number } | null | undefined, insert_incompatibleServices?: { __typename?: 'incompatibleServices_mutation_response', affected_rows: number } | null | undefined };


export const UpdateIncompatibleServicesDocument = gql`
    mutation updateIncompatibleServices($id: uuid!, $objects: [incompatibleServices_insert_input!]!) {
  delete_incompatibleServices(where: {serviceAId: {_eq: $id}}) {
    affected_rows
  }
  insert_incompatibleServices(objects: $objects) {
    affected_rows
  }
}
    `;
export type UpdateIncompatibleServicesMutationFn = Apollo.MutationFunction<UpdateIncompatibleServicesMutation, UpdateIncompatibleServicesMutationVariables>;

/**
 * __useUpdateIncompatibleServicesMutation__
 *
 * To run a mutation, you first call `useUpdateIncompatibleServicesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateIncompatibleServicesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateIncompatibleServicesMutation, { data, loading, error }] = useUpdateIncompatibleServicesMutation({
 *   variables: {
 *      id: // value for 'id'
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useUpdateIncompatibleServicesMutation(baseOptions?: Apollo.MutationHookOptions<UpdateIncompatibleServicesMutation, UpdateIncompatibleServicesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateIncompatibleServicesMutation, UpdateIncompatibleServicesMutationVariables>(UpdateIncompatibleServicesDocument, options);
      }
export type UpdateIncompatibleServicesMutationHookResult = ReturnType<typeof useUpdateIncompatibleServicesMutation>;
export type UpdateIncompatibleServicesMutationResult = Apollo.MutationResult<UpdateIncompatibleServicesMutation>;
export type UpdateIncompatibleServicesMutationOptions = Apollo.BaseMutationOptions<UpdateIncompatibleServicesMutation, UpdateIncompatibleServicesMutationVariables>;