import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type LocationAggregateQueryVariables = Types.Exact<{
  where?: Types.Maybe<Types.Location_Bool_Exp>;
}>;


export type LocationAggregateQuery = { __typename?: 'query_root', location_aggregate: { __typename?: 'location_aggregate', aggregate?: { __typename?: 'location_aggregate_fields', count: number } | null | undefined } };


export const LocationAggregateDocument = gql`
    query locationAggregate($where: location_bool_exp) {
  location_aggregate(where: $where) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useLocationAggregateQuery__
 *
 * To run a query within a React component, call `useLocationAggregateQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocationAggregateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocationAggregateQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useLocationAggregateQuery(baseOptions?: Apollo.QueryHookOptions<LocationAggregateQuery, LocationAggregateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LocationAggregateQuery, LocationAggregateQueryVariables>(LocationAggregateDocument, options);
      }
export function useLocationAggregateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LocationAggregateQuery, LocationAggregateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LocationAggregateQuery, LocationAggregateQueryVariables>(LocationAggregateDocument, options);
        }
export type LocationAggregateQueryHookResult = ReturnType<typeof useLocationAggregateQuery>;
export type LocationAggregateLazyQueryHookResult = ReturnType<typeof useLocationAggregateLazyQuery>;
export type LocationAggregateQueryResult = Apollo.QueryResult<LocationAggregateQuery, LocationAggregateQueryVariables>;