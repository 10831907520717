import { types } from 'mobx-state-tree';
import dayjs from 'dayjs';

const DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss';
export const DateTime = types.custom({
  name: 'DateTime',
  fromSnapshot: (string) => dayjs(string).toDate(),
  toSnapshot: (date: Date) => dayjs(date).format('MM/DD/YYYY'),
  isTargetType: (maybeDate) => dayjs.isDayjs(maybeDate),
  getValidationMessage: (snapshot) => {
    if (snapshot === undefined || snapshot === null) return '';
    const date = dayjs(snapshot);
    if (!date.isValid()) {
      const message = `"${snapshot}" is not in valid date format`;
      console.error(message);
      return message;
    }
    return '';
  },
});
