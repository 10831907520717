import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { CustomFormFieldsFragmentDoc } from '../../fragments/customFormFields.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type InsertCustomFormMutationVariables = Types.Exact<{
  customForm: Types.CustomForm_Insert_Input;
}>;


export type InsertCustomFormMutation = { __typename?: 'mutation_root', insert_customForm?: { __typename?: 'customForm_mutation_response', returning: Array<{ __typename?: 'customForm', id: any, title: string, isRequired: boolean, allFieldsRequired: boolean, createdAt: any, recurrence?: string | null | undefined, language?: string | null | undefined, surveyJSJSON?: any | null | undefined, workspaceId: any, rows: Array<{ __typename?: 'customFormRow', id: any, index: number, replicable?: boolean | null | undefined, fields: Array<{ __typename?: 'customFormField', id: any, index: number, label: string, body?: string | null | undefined, category?: Types.CustomFormFieldCategory_Enum | null | undefined, type: Types.CustomFormFieldType_Enum, table?: string | null | undefined, attribute?: string | null | undefined, validation?: any | null | undefined, photoId?: any | null | undefined, row: { __typename?: 'customFormRow', id: any, index: number }, options: Array<{ __typename?: 'customFormOption', id: any, value: string }>, photo?: { __typename?: 'photo', file?: { __typename?: 'FilePayload', url: string } | null | undefined } | null | undefined }> }>, serviceCustomForms: Array<{ __typename?: 'serviceCustomForm', id: any, service: { __typename?: 'service', id: any, name: string } }> }> } | null | undefined };


export const InsertCustomFormDocument = gql`
    mutation insertCustomForm($customForm: customForm_insert_input!) {
  insert_customForm(objects: [$customForm]) {
    returning {
      ...CustomFormFields
    }
  }
}
    ${CustomFormFieldsFragmentDoc}`;
export type InsertCustomFormMutationFn = Apollo.MutationFunction<InsertCustomFormMutation, InsertCustomFormMutationVariables>;

/**
 * __useInsertCustomFormMutation__
 *
 * To run a mutation, you first call `useInsertCustomFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertCustomFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertCustomFormMutation, { data, loading, error }] = useInsertCustomFormMutation({
 *   variables: {
 *      customForm: // value for 'customForm'
 *   },
 * });
 */
export function useInsertCustomFormMutation(baseOptions?: Apollo.MutationHookOptions<InsertCustomFormMutation, InsertCustomFormMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertCustomFormMutation, InsertCustomFormMutationVariables>(InsertCustomFormDocument, options);
      }
export type InsertCustomFormMutationHookResult = ReturnType<typeof useInsertCustomFormMutation>;
export type InsertCustomFormMutationResult = Apollo.MutationResult<InsertCustomFormMutation>;
export type InsertCustomFormMutationOptions = Apollo.BaseMutationOptions<InsertCustomFormMutation, InsertCustomFormMutationVariables>;