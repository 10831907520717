import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type ServiceUnitAggregateQueryVariables = Types.Exact<{
  where?: Types.Maybe<Types.ServiceUnit_Bool_Exp>;
}>;


export type ServiceUnitAggregateQuery = { __typename?: 'query_root', serviceUnit_aggregate: { __typename?: 'serviceUnit_aggregate', aggregate?: { __typename?: 'serviceUnit_aggregate_fields', count: number } | null | undefined } };


export const ServiceUnitAggregateDocument = gql`
    query serviceUnitAggregate($where: serviceUnit_bool_exp) {
  serviceUnit_aggregate(where: $where) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useServiceUnitAggregateQuery__
 *
 * To run a query within a React component, call `useServiceUnitAggregateQuery` and pass it any options that fit your needs.
 * When your component renders, `useServiceUnitAggregateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useServiceUnitAggregateQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useServiceUnitAggregateQuery(baseOptions?: Apollo.QueryHookOptions<ServiceUnitAggregateQuery, ServiceUnitAggregateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ServiceUnitAggregateQuery, ServiceUnitAggregateQueryVariables>(ServiceUnitAggregateDocument, options);
      }
export function useServiceUnitAggregateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ServiceUnitAggregateQuery, ServiceUnitAggregateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ServiceUnitAggregateQuery, ServiceUnitAggregateQueryVariables>(ServiceUnitAggregateDocument, options);
        }
export type ServiceUnitAggregateQueryHookResult = ReturnType<typeof useServiceUnitAggregateQuery>;
export type ServiceUnitAggregateLazyQueryHookResult = ReturnType<typeof useServiceUnitAggregateLazyQuery>;
export type ServiceUnitAggregateQueryResult = Apollo.QueryResult<ServiceUnitAggregateQuery, ServiceUnitAggregateQueryVariables>;