import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Flex,
  Button,
} from '@chakra-ui/react';
import { titleCase } from 'title-case';

interface DeleteModalProps {
  confirmButtonText?: string;
  itemName: string;
  isOpen: boolean;
  onClose: () => void;
  handleDelete: () => void;
}

export const DeleteModal = ({
  confirmButtonText = 'Delete',
  itemName,
  isOpen,
  onClose,
  handleDelete,
}: DeleteModalProps): JSX.Element => {
  const handleDeleteAndClose = async (): Promise<void> => {
    handleDelete();
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{`Delete ${titleCase(itemName ?? '')}`}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>{`Are you sure you want to delete this ${itemName}? This action cannot be undone.`}</ModalBody>
        <ModalFooter>
          <Flex justifyContent="end">
            <Button onClick={onClose} marginRight="5">
              Cancel
            </Button>
            <Button
              variant="solid"
              colorScheme="red"
              onClick={handleDeleteAndClose}
            >
              {confirmButtonText}
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default DeleteModal;
