/* eslint-disable max-classes-per-file */
import React, { createElement } from 'react';
import {
  PropertyGridEditorCollection,
  localization,
} from 'survey-creator-react';
import { ElementFactory, Question, Serializer, SvgRegistry } from 'survey-core';
import {
  SurveyQuestionElementBase,
  ReactQuestionFactory,
} from 'survey-react-ui';
import { FormControl, FormLabel, Box } from '@chakra-ui/react';
import { GenericObjectSelect } from '@webapp/ui';
import { SimpleImageUpload } from '@webapp/webapp/ui-composites';
import { StorageType } from '@webapp/graphql';
import Icon from './icon.svg?raw';

const CUSTOM_TYPE = 'private-image-upload';

// A model for the new question type
export class ImageUploadModel extends Question {
  getType() {
    return CUSTOM_TYPE;
  }
}

// Register `ImageUploadModel` as a model for the `book-appointment` type
export function registerImageUpload() {
  ElementFactory.Instance.registerElement(
    CUSTOM_TYPE,
    (name) => new ImageUploadModel(name)
  );
}

// Specify display names for the question type and its properties
const locale = localization.getLocale('');
locale.qt[CUSTOM_TYPE] = 'Private Image Upload';

// Register an SVG icon for the question type
SvgRegistry.registerIconFromSvg(CUSTOM_TYPE, Icon);

// Add question type metadata for further serialization into JSON
Serializer.addClass(
  CUSTOM_TYPE,
  [],
  () => new ImageUploadModel('Private Image Upload'),
  'question'
);

// A class that renders questions of the new type in the UI
export class SurveyQuestionImageUpload extends SurveyQuestionElementBase {
  constructor(props) {
    super(props);
    this.state = { value: this.question.value };
  }

  get question() {
    return this.questionBase;
  }

  get value() {
    return this.question.value;
  }

  // Support the read-only and design modes
  get style() {
    return this.question.getPropertyValue('readOnly') ||
      this.question.isDesignMode
      ? { pointerEvents: 'none' }
      : undefined;
  }

  get patientId() {
    return this.question.data.getVariable('patientId');
  }

  get workspaceId() {
    return (
      this.question.data.getVariable('workspaceId') ??
      this.question.leadFormWorkspaceId
    );
  }

  get imageUploadSelectionScope(): React.InputHTMLAttributes<HTMLInputElement>['capture'] {
    return this.question.data.getVariable('imageUploadSelectionScope');
  }

  renderElement() {
    return (
      <div style={this.style}>
        <SimpleImageUpload
          name="image"
          storageType={StorageType.Private}
          uploadingAnonymously={!this.patientId}
          defaultValue={this.question.value}
          onChange={(v) => {
            this.question.value = v;
          }}
          patientId={this.patientId}
          workspaceId={this.workspaceId}
          selectionScope={this.imageUploadSelectionScope}
        />
      </div>
    );
  }
}

// Register `SurveyQuestionImageUpload` as a class that renders `book-appointment` questions
ReactQuestionFactory.Instance.registerQuestion(CUSTOM_TYPE, (props) =>
  createElement(SurveyQuestionImageUpload, props)
);
