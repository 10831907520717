import { Controller } from 'react-hook-form';
import { Skeleton } from '@chakra-ui/react';
import {
  CompoundControlledFormInputProps,
  CompoundUncontrolledFormInputProps,
  FormInputProps,
} from './types';
import FormInputWrapper from './FormInputWrapper';
import renderControlledInput from './util/renderControlledInput';
import renderUncontrolledInput from './util/renderUncontrolledInput';

function isControlled(
  props: CompoundControlledFormInputProps | CompoundUncontrolledFormInputProps
): props is CompoundControlledFormInputProps {
  return Boolean((props as CompoundControlledFormInputProps).control);
}

export function FormInputV2(props: FormInputProps) {
  if (isControlled(props)) {
    const { isLoading, ...inputProps } = props;

    return (
      <Controller
        control={props.control}
        name={props.name}
        render={({ field, formState: { errors } }) => (
          <FormInputWrapper error={errors[props.name]?.message} {...props}>
            <Skeleton isLoaded={!isLoading}>
              {renderControlledInput(
                { ...inputProps, error: errors[props.name] as any },
                field
              )}
            </Skeleton>
          </FormInputWrapper>
        )}
      />
    );
  }

  const { isLoading, ...inputProps } = props;

  return (
    <FormInputWrapper {...props}>
      <Skeleton isLoaded={!isLoading}>
        {renderUncontrolledInput(inputProps)}
      </Skeleton>
    </FormInputWrapper>
  );
}

export default FormInputV2;
