import { useApolloClient } from '@apollo/client';
import {
  SearchProviderQueryResult,
  SearchProviderQueryVariables,
  SearchProviderDocument,
  ProviderFieldsFragment,
} from '@webapp/graphql';
import { ObjectOption } from '@webapp/types';

export interface UseLoadProviders {
  search: (
    searchValue: string
  ) => Promise<ObjectOption<ProviderFieldsFragment>[]>;
}

export function useLoadProviders(): UseLoadProviders {
  const client = useApolloClient();

  return {
    search: async (searchValue: string) => {
      const { data } = await client.query<
        SearchProviderQueryResult['data'],
        SearchProviderQueryVariables
      >({
        query: SearchProviderDocument,
        variables: {
          searchValue: `%${searchValue}%`,
        },
      });

      const providers = data?.provider ?? [];

      return providers.map((provider) => ({
        label: `${provider.firstName} ${provider.lastName}`,
        value: provider.id,
        object: provider as ProviderFieldsFragment,
      }));
    },
  };
}

export default useLoadProviders;
