import { Stack } from '@chakra-ui/react';
import {
  ConsentFieldsFragment,
  useUpdateConsentMutation,
} from '@webapp/graphql';
import { useStores } from '@webapp/state-models';

import {
  registerRichTextEditor,
  registerRichTextReadOnly,
  registerCustomMediaConsent,
  registerCustomConsentText,
  SurveyJSCreator,
} from '@webapp/webapp/ui-composites';

import { setLicenseKey } from 'survey-core';
import type { SurveyCreator } from 'survey-creator-react';
import configureSurveyJSConsentComponents from './configureSurveyJSConsentComponents';

setLicenseKey(
  'MTMxYTY2ODMtNTQxMC00NWIzLTliMGMtZGMyN2RiMGUzMzgyOzE9MjAyNS0wMi0wOCwyPTIwMjUtMDItMDgsND0yMDI1LTAyLTA4'
);

/* eslint-disable-next-line */
export interface CreateSurveyJSConsentFormProps {
  consent: ConsentFieldsFragment;
}

function creatorConfigFunction(creator: SurveyCreator) {
  creator.toolbox.getItemByName('html').iconName = 'icon-document-text';
  creator.toolbox.getItemByName('html').title = 'Consent Text';
}

function bootstrapFunction() {
  configureSurveyJSConsentComponents();
}

const questionTypes = [
  'text',
  'signaturepad',
  'initials',
  'panel',
  'html',
  'name-signature-date',
  'patient-name',
  'media-consent',
  'custom-consent-text',
  'custom-media-consent',
  'text',
  'radiogroup',
  'checkbox',
  'expression',
  'dropdown',
  'boolean',
  'panel',
  'dynamic-panel',
];

interface Signature {
  requireProviderSignature: boolean;
  requireWitnessSignature: boolean;
}

interface Page {
  name: string;
  elements: Array<any>;
  title?: string;
}

interface JSONData {
  title: string;
  description: string;
  logo: string;
  logoWidth: string;
  pages: Array<Page>;
  showQuestionNumbers: string;
  widthMode: string;
}

function findSignatureTypes(data: JSONData): Signature {
  let requireProviderSignature = false;
  let requireWitnessSignature = false;

  data.pages.forEach((page) => {
    page.elements.forEach((element) => {
      if (element.type === 'panel') {
        element.elements.forEach((panelElement: any) => {
          if (panelElement.signatureType === 'Provider') {
            requireProviderSignature = true;
          }
          if (panelElement.signatureType === 'Witness') {
            requireWitnessSignature = true;
          }
        });
      }
      if (element.signatureType === 'Provider') {
        requireProviderSignature = true;
      }
      if (element.signatureType === 'Witness') {
        requireWitnessSignature = true;
      }
    });
  });

  return {
    requireProviderSignature,
    requireWitnessSignature,
  };
}

export function CreateSurveyJSConsentForm({
  consent,
}: CreateSurveyJSConsentFormProps) {
  const { workspace } = useStores();

  const [updateConsentForm] = useUpdateConsentMutation();

  registerRichTextReadOnly();
  registerRichTextEditor();
  registerCustomMediaConsent();
  registerCustomConsentText();
  
  return (
    <Stack overflowY={'hidden'} w={'full'} h={'84vh'}>
      <SurveyJSCreator
        surveyJson={consent?.surveyJSJson}
        workspace={workspace}
        onChange={(newJson: any) => {
          if (newJson) {
            updateConsentForm({
              variables: {
                id: consent.id,
                set: {
                  surveyJSJson: {
                    ...((workspace?.logo ||
                      workspace?.workspaceConfiguration?.logo) && {
                      logo:
                        workspace.logo ||
                        workspace?.workspaceConfiguration?.logo,
                      logoWidth: '100px',
                    }),
                    ...newJson,
                  },
                  ...findSignatureTypes(newJson),
                },
              },
            });
          }
        }}
        bootstrapFunction={bootstrapFunction}
        creatorConfigFunction={creatorConfigFunction}
        questionTypes={questionTypes}
      />
    </Stack>
  );
}

export default CreateSurveyJSConsentForm;
