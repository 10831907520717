import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { RoomFieldsFragmentDoc } from '../../fragments/roomFields.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type UpdateRoomMutationVariables = Types.Exact<{
  set?: Types.Maybe<Types.Room_Set_Input>;
  id: Types.Scalars['uuid'];
}>;


export type UpdateRoomMutation = { __typename?: 'mutation_root', update_room_by_pk?: { __typename?: 'room', id: any, name: string } | null | undefined };


export const UpdateRoomDocument = gql`
    mutation UpdateRoom($set: room_set_input, $id: uuid!) {
  update_room_by_pk(_set: $set, pk_columns: {id: $id}) {
    ...RoomFields
  }
}
    ${RoomFieldsFragmentDoc}`;
export type UpdateRoomMutationFn = Apollo.MutationFunction<UpdateRoomMutation, UpdateRoomMutationVariables>;

/**
 * __useUpdateRoomMutation__
 *
 * To run a mutation, you first call `useUpdateRoomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRoomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRoomMutation, { data, loading, error }] = useUpdateRoomMutation({
 *   variables: {
 *      set: // value for 'set'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateRoomMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRoomMutation, UpdateRoomMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateRoomMutation, UpdateRoomMutationVariables>(UpdateRoomDocument, options);
      }
export type UpdateRoomMutationHookResult = ReturnType<typeof useUpdateRoomMutation>;
export type UpdateRoomMutationResult = Apollo.MutationResult<UpdateRoomMutation>;
export type UpdateRoomMutationOptions = Apollo.BaseMutationOptions<UpdateRoomMutation, UpdateRoomMutationVariables>;