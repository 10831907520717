import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { PhotoFieldsFragmentDoc } from '../../fragments/photoFields.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type UpdatePhotoWithMediaTagsMutationVariables = Types.Exact<{
  isSharedWithPatient?: Types.Maybe<Types.Scalars['Boolean']>;
  photoId: Types.Scalars['uuid'];
  mediaType?: Types.Maybe<Types.Scalars['String']>;
  mediaTags: Array<Types.PhotoMediaTag_Insert_Input> | Types.PhotoMediaTag_Insert_Input;
}>;


export type UpdatePhotoWithMediaTagsMutation = { __typename?: 'mutation_root', update_photo_by_pk?: { __typename?: 'photo', id: any, workspaceId: any, patientId?: any | null | undefined, mediaDate?: any | null | undefined, mediaType?: string | null | undefined, mimeType?: string | null | undefined, hasThumbnail?: boolean | null | undefined, isSharedWithPatient: boolean, partialMediaConsent?: boolean | null | undefined, fullMediaConsent?: boolean | null | undefined, patient?: { __typename?: 'patient', firstName: string, id: any, lastName: string } | null | undefined, file?: { __typename?: 'FilePayload', filePath: string, url: string, thumbnailUrl?: string | null | undefined } | null | undefined, photoMediaTags: Array<{ __typename?: 'photoMediaTag', id: any, mediaTag: { __typename?: 'mediaTag', id: any, title: string } }>, uploader?: { __typename?: 'user', firstName: string, id: any, lastName: string } | null | undefined } | null | undefined, delete_photoMediaTag?: { __typename?: 'photoMediaTag_mutation_response', affected_rows: number } | null | undefined, insert_photoMediaTag?: { __typename?: 'photoMediaTag_mutation_response', returning: Array<{ __typename?: 'photoMediaTag', id: any, mediaTag: { __typename?: 'mediaTag', id: any, title: string } }> } | null | undefined };


export const UpdatePhotoWithMediaTagsDocument = gql`
    mutation UpdatePhotoWithMediaTags($isSharedWithPatient: Boolean, $photoId: uuid!, $mediaType: String, $mediaTags: [photoMediaTag_insert_input!]!) {
  update_photo_by_pk(
    _set: {isSharedWithPatient: $isSharedWithPatient, mediaType: $mediaType}
    pk_columns: {id: $photoId}
  ) {
    ...PhotoFields
  }
  delete_photoMediaTag(where: {photoId: {_eq: $photoId}}) {
    affected_rows
  }
  insert_photoMediaTag(
    objects: $mediaTags
    on_conflict: {constraint: photoMediaTag_photoId_mediaTagId_key, update_columns: [updatedAt]}
  ) {
    returning {
      id
      mediaTag {
        id
        title
      }
    }
  }
}
    ${PhotoFieldsFragmentDoc}`;
export type UpdatePhotoWithMediaTagsMutationFn = Apollo.MutationFunction<UpdatePhotoWithMediaTagsMutation, UpdatePhotoWithMediaTagsMutationVariables>;

/**
 * __useUpdatePhotoWithMediaTagsMutation__
 *
 * To run a mutation, you first call `useUpdatePhotoWithMediaTagsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePhotoWithMediaTagsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePhotoWithMediaTagsMutation, { data, loading, error }] = useUpdatePhotoWithMediaTagsMutation({
 *   variables: {
 *      isSharedWithPatient: // value for 'isSharedWithPatient'
 *      photoId: // value for 'photoId'
 *      mediaType: // value for 'mediaType'
 *      mediaTags: // value for 'mediaTags'
 *   },
 * });
 */
export function useUpdatePhotoWithMediaTagsMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePhotoWithMediaTagsMutation, UpdatePhotoWithMediaTagsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePhotoWithMediaTagsMutation, UpdatePhotoWithMediaTagsMutationVariables>(UpdatePhotoWithMediaTagsDocument, options);
      }
export type UpdatePhotoWithMediaTagsMutationHookResult = ReturnType<typeof useUpdatePhotoWithMediaTagsMutation>;
export type UpdatePhotoWithMediaTagsMutationResult = Apollo.MutationResult<UpdatePhotoWithMediaTagsMutation>;
export type UpdatePhotoWithMediaTagsMutationOptions = Apollo.BaseMutationOptions<UpdatePhotoWithMediaTagsMutation, UpdatePhotoWithMediaTagsMutationVariables>;