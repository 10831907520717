import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type SendInvoiceReceiptToPatientMutationVariables = Types.Exact<{
  invoiceId: Types.Scalars['uuid'];
}>;


export type SendInvoiceReceiptToPatientMutation = { __typename?: 'mutation_root', sendInvoiceReceiptToPatient: { __typename?: 'SuccessAnswer', success: boolean } };


export const SendInvoiceReceiptToPatientDocument = gql`
    mutation sendInvoiceReceiptToPatient($invoiceId: uuid!) {
  sendInvoiceReceiptToPatient(invoiceId: $invoiceId) {
    success
  }
}
    `;
export type SendInvoiceReceiptToPatientMutationFn = Apollo.MutationFunction<SendInvoiceReceiptToPatientMutation, SendInvoiceReceiptToPatientMutationVariables>;

/**
 * __useSendInvoiceReceiptToPatientMutation__
 *
 * To run a mutation, you first call `useSendInvoiceReceiptToPatientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendInvoiceReceiptToPatientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendInvoiceReceiptToPatientMutation, { data, loading, error }] = useSendInvoiceReceiptToPatientMutation({
 *   variables: {
 *      invoiceId: // value for 'invoiceId'
 *   },
 * });
 */
export function useSendInvoiceReceiptToPatientMutation(baseOptions?: Apollo.MutationHookOptions<SendInvoiceReceiptToPatientMutation, SendInvoiceReceiptToPatientMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendInvoiceReceiptToPatientMutation, SendInvoiceReceiptToPatientMutationVariables>(SendInvoiceReceiptToPatientDocument, options);
      }
export type SendInvoiceReceiptToPatientMutationHookResult = ReturnType<typeof useSendInvoiceReceiptToPatientMutation>;
export type SendInvoiceReceiptToPatientMutationResult = Apollo.MutationResult<SendInvoiceReceiptToPatientMutation>;
export type SendInvoiceReceiptToPatientMutationOptions = Apollo.BaseMutationOptions<SendInvoiceReceiptToPatientMutation, SendInvoiceReceiptToPatientMutationVariables>;