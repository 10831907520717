import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type UpdateDeviceTypesForServiceMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
  objects: Array<Types.ServiceDeviceType_Insert_Input> | Types.ServiceDeviceType_Insert_Input;
}>;


export type UpdateDeviceTypesForServiceMutation = { __typename?: 'mutation_root', delete_serviceDeviceType?: { __typename?: 'serviceDeviceType_mutation_response', affected_rows: number } | null | undefined, insert_serviceDeviceType?: { __typename?: 'serviceDeviceType_mutation_response', affected_rows: number } | null | undefined };


export const UpdateDeviceTypesForServiceDocument = gql`
    mutation updateDeviceTypesForService($id: uuid!, $objects: [serviceDeviceType_insert_input!]!) {
  delete_serviceDeviceType(where: {serviceId: {_eq: $id}}) {
    affected_rows
  }
  insert_serviceDeviceType(
    objects: $objects
    on_conflict: {constraint: serviceDeviceType_serviceId_deviceTypeId_key, update_columns: updatedAt}
  ) {
    affected_rows
  }
}
    `;
export type UpdateDeviceTypesForServiceMutationFn = Apollo.MutationFunction<UpdateDeviceTypesForServiceMutation, UpdateDeviceTypesForServiceMutationVariables>;

/**
 * __useUpdateDeviceTypesForServiceMutation__
 *
 * To run a mutation, you first call `useUpdateDeviceTypesForServiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDeviceTypesForServiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDeviceTypesForServiceMutation, { data, loading, error }] = useUpdateDeviceTypesForServiceMutation({
 *   variables: {
 *      id: // value for 'id'
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useUpdateDeviceTypesForServiceMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDeviceTypesForServiceMutation, UpdateDeviceTypesForServiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDeviceTypesForServiceMutation, UpdateDeviceTypesForServiceMutationVariables>(UpdateDeviceTypesForServiceDocument, options);
      }
export type UpdateDeviceTypesForServiceMutationHookResult = ReturnType<typeof useUpdateDeviceTypesForServiceMutation>;
export type UpdateDeviceTypesForServiceMutationResult = Apollo.MutationResult<UpdateDeviceTypesForServiceMutation>;
export type UpdateDeviceTypesForServiceMutationOptions = Apollo.BaseMutationOptions<UpdateDeviceTypesForServiceMutation, UpdateDeviceTypesForServiceMutationVariables>;