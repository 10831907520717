import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import NorthAmericaTimeZones from './constants/NorthAmerica.js';
import PacificTimeZones from './constants/Pacific.jsx';
import FormInputV2 from '../FormInputV2/FormInputV2.js';

dayjs.extend(timezone);
dayjs.extend(advancedFormat);
dayjs.extend(utc);

interface TimeZoneSelectProps {
  browserTimeZone?: boolean;
  canClearSelection?: boolean;
  defaultValue?: string | null;
  handleChange?: (value: string | undefined) => void;
  label?: string;
  onBlur?: () => void;
  timezones?: { [key: string]: string };
}

const TimeZones = {
  ...NorthAmericaTimeZones,
  ...PacificTimeZones,
};

export function TimeZoneSelect({
  browserTimeZone = true,
  canClearSelection = false,
  defaultValue,
  handleChange,
  label,
  onBlur,
  timezones = TimeZones,
  ...props
}: TimeZoneSelectProps) {
  const tzOptions: any = [];
  Object.keys(timezones).forEach(function (key) {
    tzOptions.push({
      value: key,
      label: timezones[key],
    });
  });

  const currentTimeZone = dayjs.tz.guess();
  const browserLocationOption = {
    value: currentTimeZone,
    label: 'Current location',
  };

  const clearSelectionOption = {
    value: '',
    label: 'Choose time zone...',
  };

  const options = [
    browserTimeZone ? browserLocationOption : null,
    canClearSelection ? clearSelectionOption : null,
    ...tzOptions,
  ].filter(Boolean);

  const defaultOption =
    options.find((option) => option.value === defaultValue) || null;

  function onChange(
    selectedOption: { label: string; value: string | number } | null
  ) {
    if (handleChange) handleChange(selectedOption?.value.toString());
  }

  return (
    <FormInputV2
      label={label ? label : ''}
      uncontrolled
      name="timezone"
      onBlur={onBlur}
      onChange={onChange}
      placeholder={'Choose time zone...'}
      options={options}
      type="select"
      id="timezone-select"
      value={defaultOption || (browserTimeZone ? browserLocationOption : null)}
    />
  );
}

export default TimeZoneSelect;
