import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type LocationServicesQueryVariables = Types.Exact<{
  where?: Types.Maybe<Types.LocationService_Bool_Exp>;
}>;


export type LocationServicesQuery = { __typename?: 'query_root', locationService: Array<{ __typename?: 'locationService', id: any, price?: any | null | undefined, taxRate?: any | null | undefined, service: { __typename?: 'service', allowGratuity: boolean, id: any, taxRate?: any | null | undefined, name: string, active?: boolean | null | undefined, price: number, isProduct?: boolean | null | undefined, productBarcodeId?: string | null | undefined, onlineBookingRules?: any | null | undefined, color?: string | null | undefined, pricingModel: string, serviceCategory?: { __typename?: 'serviceCategory', title: string } | null | undefined } }> };


export const LocationServicesDocument = gql`
    query LocationServices($where: locationService_bool_exp) {
  locationService(where: $where) {
    id
    price
    taxRate
    service {
      allowGratuity
      id
      taxRate
      name
      active
      price
      isProduct
      productBarcodeId
      onlineBookingRules
      color
      pricingModel
      serviceCategory {
        title
      }
    }
  }
}
    `;

/**
 * __useLocationServicesQuery__
 *
 * To run a query within a React component, call `useLocationServicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocationServicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocationServicesQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useLocationServicesQuery(baseOptions?: Apollo.QueryHookOptions<LocationServicesQuery, LocationServicesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LocationServicesQuery, LocationServicesQueryVariables>(LocationServicesDocument, options);
      }
export function useLocationServicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LocationServicesQuery, LocationServicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LocationServicesQuery, LocationServicesQueryVariables>(LocationServicesDocument, options);
        }
export type LocationServicesQueryHookResult = ReturnType<typeof useLocationServicesQuery>;
export type LocationServicesLazyQueryHookResult = ReturnType<typeof useLocationServicesLazyQuery>;
export type LocationServicesQueryResult = Apollo.QueryResult<LocationServicesQuery, LocationServicesQueryVariables>;