import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type PopulateStaticAudienceMutationVariables = Types.Exact<{
  audienceId: Types.Scalars['uuid'];
}>;


export type PopulateStaticAudienceMutation = { __typename?: 'mutation_root', populateStaticAudience?: { __typename?: 'SuccessAnswer', success: boolean } | null | undefined };


export const PopulateStaticAudienceDocument = gql`
    mutation populateStaticAudience($audienceId: uuid!) {
  populateStaticAudience(audienceId: $audienceId) {
    success
  }
}
    `;
export type PopulateStaticAudienceMutationFn = Apollo.MutationFunction<PopulateStaticAudienceMutation, PopulateStaticAudienceMutationVariables>;

/**
 * __usePopulateStaticAudienceMutation__
 *
 * To run a mutation, you first call `usePopulateStaticAudienceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePopulateStaticAudienceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [populateStaticAudienceMutation, { data, loading, error }] = usePopulateStaticAudienceMutation({
 *   variables: {
 *      audienceId: // value for 'audienceId'
 *   },
 * });
 */
export function usePopulateStaticAudienceMutation(baseOptions?: Apollo.MutationHookOptions<PopulateStaticAudienceMutation, PopulateStaticAudienceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PopulateStaticAudienceMutation, PopulateStaticAudienceMutationVariables>(PopulateStaticAudienceDocument, options);
      }
export type PopulateStaticAudienceMutationHookResult = ReturnType<typeof usePopulateStaticAudienceMutation>;
export type PopulateStaticAudienceMutationResult = Apollo.MutationResult<PopulateStaticAudienceMutation>;
export type PopulateStaticAudienceMutationOptions = Apollo.BaseMutationOptions<PopulateStaticAudienceMutation, PopulateStaticAudienceMutationVariables>;