import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetProviderAcrossReviewPlatformsQueryVariables = Types.Exact<{
  providerName: Types.Scalars['String'];
  providerLocation: Types.Scalars['String'];
  categories?: Types.Maybe<Types.Scalars['String']>;
  coordinates?: Types.Maybe<Types.Coordinates>;
}>;


export type GetProviderAcrossReviewPlatformsQuery = { __typename?: 'query_root', fetchProviderAcrossReviewPlatforms?: Array<{ __typename?: 'ProviderOnReviewPlatform', id?: string | null | undefined, name?: string | null | undefined, location?: string | null | undefined, reviewCount?: number | null | undefined, platform?: string | null | undefined } | null | undefined> | null | undefined };


export const GetProviderAcrossReviewPlatformsDocument = gql`
    query GetProviderAcrossReviewPlatforms($providerName: String!, $providerLocation: String!, $categories: String, $coordinates: Coordinates) {
  fetchProviderAcrossReviewPlatforms(
    providerName: $providerName
    providerLocation: $providerLocation
    categories: $categories
    coordinates: $coordinates
  ) {
    id
    name
    location
    reviewCount
    platform
  }
}
    `;

/**
 * __useGetProviderAcrossReviewPlatformsQuery__
 *
 * To run a query within a React component, call `useGetProviderAcrossReviewPlatformsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProviderAcrossReviewPlatformsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProviderAcrossReviewPlatformsQuery({
 *   variables: {
 *      providerName: // value for 'providerName'
 *      providerLocation: // value for 'providerLocation'
 *      categories: // value for 'categories'
 *      coordinates: // value for 'coordinates'
 *   },
 * });
 */
export function useGetProviderAcrossReviewPlatformsQuery(baseOptions: Apollo.QueryHookOptions<GetProviderAcrossReviewPlatformsQuery, GetProviderAcrossReviewPlatformsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProviderAcrossReviewPlatformsQuery, GetProviderAcrossReviewPlatformsQueryVariables>(GetProviderAcrossReviewPlatformsDocument, options);
      }
export function useGetProviderAcrossReviewPlatformsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProviderAcrossReviewPlatformsQuery, GetProviderAcrossReviewPlatformsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProviderAcrossReviewPlatformsQuery, GetProviderAcrossReviewPlatformsQueryVariables>(GetProviderAcrossReviewPlatformsDocument, options);
        }
export type GetProviderAcrossReviewPlatformsQueryHookResult = ReturnType<typeof useGetProviderAcrossReviewPlatformsQuery>;
export type GetProviderAcrossReviewPlatformsLazyQueryHookResult = ReturnType<typeof useGetProviderAcrossReviewPlatformsLazyQuery>;
export type GetProviderAcrossReviewPlatformsQueryResult = Apollo.QueryResult<GetProviderAcrossReviewPlatformsQuery, GetProviderAcrossReviewPlatformsQueryVariables>;