import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type UpdateTasksManyMutationVariables = Types.Exact<{
  _in?: Types.Maybe<Array<Types.Scalars['uuid']> | Types.Scalars['uuid']>;
  status: Types.Scalars['String'];
}>;


export type UpdateTasksManyMutation = { __typename?: 'mutation_root', update_task?: { __typename?: 'task_mutation_response', returning: Array<{ __typename?: 'task', description?: string | null | undefined, dueDate: any, id: any, status?: string | null | undefined, comments: Array<{ __typename?: 'taskComment', createdAt: any, comment: string, id: any, updatedAt: any, author: { __typename?: 'user', firstName: string, id: any, lastName: string, title?: string | null | undefined } }>, provider?: { __typename?: 'provider', firstName: string, id: any, lastName: string, profilePicture?: string | null | undefined, title?: string | null | undefined } | null | undefined, patient?: { __typename?: 'patient', firstName: string, id: any, lastName: string, profilePicture?: { __typename?: 'photo', file?: { __typename?: 'FilePayload', thumbnailUrl?: string | null | undefined } | null | undefined } | null | undefined } | null | undefined, user?: { __typename?: 'user', firstName: string, id: any, lastName: string, profilePicture?: string | null | undefined, title?: string | null | undefined } | null | undefined }> } | null | undefined };


export const UpdateTasksManyDocument = gql`
    mutation UpdateTasksMany($_in: [uuid!], $status: String!) {
  update_task(where: {id: {_in: $_in}}, _set: {status: $status}) {
    returning {
      comments {
        author {
          firstName
          id
          lastName
          title
        }
        createdAt
        comment
        id
        updatedAt
      }
      description
      dueDate
      id
      provider {
        firstName
        id
        lastName
        profilePicture
        title
      }
      patient {
        firstName
        id
        lastName
        profilePicture {
          file {
            thumbnailUrl
          }
        }
      }
      status
      user {
        firstName
        id
        lastName
        profilePicture
        title
      }
    }
  }
}
    `;
export type UpdateTasksManyMutationFn = Apollo.MutationFunction<UpdateTasksManyMutation, UpdateTasksManyMutationVariables>;

/**
 * __useUpdateTasksManyMutation__
 *
 * To run a mutation, you first call `useUpdateTasksManyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTasksManyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTasksManyMutation, { data, loading, error }] = useUpdateTasksManyMutation({
 *   variables: {
 *      _in: // value for '_in'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useUpdateTasksManyMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTasksManyMutation, UpdateTasksManyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTasksManyMutation, UpdateTasksManyMutationVariables>(UpdateTasksManyDocument, options);
      }
export type UpdateTasksManyMutationHookResult = ReturnType<typeof useUpdateTasksManyMutation>;
export type UpdateTasksManyMutationResult = Apollo.MutationResult<UpdateTasksManyMutation>;
export type UpdateTasksManyMutationOptions = Apollo.BaseMutationOptions<UpdateTasksManyMutation, UpdateTasksManyMutationVariables>;