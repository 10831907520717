import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type DeleteClinicalMessageMutationVariables = Types.Exact<{
  where: Types.ClinicalMessage_Bool_Exp;
}>;


export type DeleteClinicalMessageMutation = { __typename?: 'mutation_root', delete_clinicalMessage?: { __typename?: 'clinicalMessage_mutation_response', returning: Array<{ __typename?: 'clinicalMessage', id: any }> } | null | undefined };


export const DeleteClinicalMessageDocument = gql`
    mutation deleteClinicalMessage($where: clinicalMessage_bool_exp!) {
  delete_clinicalMessage(where: $where) {
    returning {
      id
    }
  }
}
    `;
export type DeleteClinicalMessageMutationFn = Apollo.MutationFunction<DeleteClinicalMessageMutation, DeleteClinicalMessageMutationVariables>;

/**
 * __useDeleteClinicalMessageMutation__
 *
 * To run a mutation, you first call `useDeleteClinicalMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteClinicalMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteClinicalMessageMutation, { data, loading, error }] = useDeleteClinicalMessageMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDeleteClinicalMessageMutation(baseOptions?: Apollo.MutationHookOptions<DeleteClinicalMessageMutation, DeleteClinicalMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteClinicalMessageMutation, DeleteClinicalMessageMutationVariables>(DeleteClinicalMessageDocument, options);
      }
export type DeleteClinicalMessageMutationHookResult = ReturnType<typeof useDeleteClinicalMessageMutation>;
export type DeleteClinicalMessageMutationResult = Apollo.MutationResult<DeleteClinicalMessageMutation>;
export type DeleteClinicalMessageMutationOptions = Apollo.BaseMutationOptions<DeleteClinicalMessageMutation, DeleteClinicalMessageMutationVariables>;