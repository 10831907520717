import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type SubmitSurveyJsFormMutationVariables = Types.Exact<{
  input: Types.SubmitSurveyJsInput;
}>;


export type SubmitSurveyJsFormMutation = { __typename?: 'mutation_root', submitSurveyJSForm?: { __typename?: 'SubmitSurveyJSOutput', success: boolean, patientId?: any | null | undefined, patientStatus?: string | null | undefined, previousServiceIds?: Array<any | null | undefined> | null | undefined } | null | undefined };


export const SubmitSurveyJsFormDocument = gql`
    mutation SubmitSurveyJSForm($input: SubmitSurveyJSInput!) {
  submitSurveyJSForm(input: $input) {
    success
    patientId
    patientStatus
    previousServiceIds
  }
}
    `;
export type SubmitSurveyJsFormMutationFn = Apollo.MutationFunction<SubmitSurveyJsFormMutation, SubmitSurveyJsFormMutationVariables>;

/**
 * __useSubmitSurveyJsFormMutation__
 *
 * To run a mutation, you first call `useSubmitSurveyJsFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitSurveyJsFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitSurveyJsFormMutation, { data, loading, error }] = useSubmitSurveyJsFormMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubmitSurveyJsFormMutation(baseOptions?: Apollo.MutationHookOptions<SubmitSurveyJsFormMutation, SubmitSurveyJsFormMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SubmitSurveyJsFormMutation, SubmitSurveyJsFormMutationVariables>(SubmitSurveyJsFormDocument, options);
      }
export type SubmitSurveyJsFormMutationHookResult = ReturnType<typeof useSubmitSurveyJsFormMutation>;
export type SubmitSurveyJsFormMutationResult = Apollo.MutationResult<SubmitSurveyJsFormMutation>;
export type SubmitSurveyJsFormMutationOptions = Apollo.BaseMutationOptions<SubmitSurveyJsFormMutation, SubmitSurveyJsFormMutationVariables>;