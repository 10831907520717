import * as Types from '../../generated/graphql';

import { gql } from '@apollo/client';
export type ConsumableBrandFieldsFullFragment = { __typename?: 'consumableBrand', id: any, name: string, contactName?: string | null | undefined, contactEmail?: string | null | undefined, contactPhoneNumber?: string | null | undefined, note?: string | null | undefined, createdAt: any, updatedAt: any, consumables: Array<{ __typename?: 'consumable', id: any, metadata: any, type: { __typename?: 'consumableType', name: string }, brand?: { __typename?: 'consumableBrand', name: string } | null | undefined }> };

export const ConsumableBrandFieldsFullFragmentDoc = gql`
    fragment ConsumableBrandFieldsFull on consumableBrand {
  id
  name
  contactName
  contactEmail
  contactPhoneNumber
  note
  createdAt
  updatedAt
  consumables(limit: 50, order_by: {createdAt: desc}) {
    id
    type {
      name
    }
    brand {
      name
    }
    metadata
  }
}
    `;