import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type UpdateServiceConsentsMutationVariables = Types.Exact<{
  where: Types.ServiceConsent_Bool_Exp;
  objects: Array<Types.ServiceConsent_Insert_Input> | Types.ServiceConsent_Insert_Input;
}>;


export type UpdateServiceConsentsMutation = { __typename?: 'mutation_root', delete_serviceConsent?: { __typename?: 'serviceConsent_mutation_response', affected_rows: number } | null | undefined, insert_serviceConsent?: { __typename?: 'serviceConsent_mutation_response', affected_rows: number } | null | undefined };


export const UpdateServiceConsentsDocument = gql`
    mutation updateServiceConsents($where: serviceConsent_bool_exp!, $objects: [serviceConsent_insert_input!]!) {
  delete_serviceConsent(where: $where) {
    affected_rows
  }
  insert_serviceConsent(objects: $objects) {
    affected_rows
  }
}
    `;
export type UpdateServiceConsentsMutationFn = Apollo.MutationFunction<UpdateServiceConsentsMutation, UpdateServiceConsentsMutationVariables>;

/**
 * __useUpdateServiceConsentsMutation__
 *
 * To run a mutation, you first call `useUpdateServiceConsentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateServiceConsentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateServiceConsentsMutation, { data, loading, error }] = useUpdateServiceConsentsMutation({
 *   variables: {
 *      where: // value for 'where'
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useUpdateServiceConsentsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateServiceConsentsMutation, UpdateServiceConsentsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateServiceConsentsMutation, UpdateServiceConsentsMutationVariables>(UpdateServiceConsentsDocument, options);
      }
export type UpdateServiceConsentsMutationHookResult = ReturnType<typeof useUpdateServiceConsentsMutation>;
export type UpdateServiceConsentsMutationResult = Apollo.MutationResult<UpdateServiceConsentsMutation>;
export type UpdateServiceConsentsMutationOptions = Apollo.BaseMutationOptions<UpdateServiceConsentsMutation, UpdateServiceConsentsMutationVariables>;