import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { PatientMembershipFieldsFragmentDoc } from '../../fragments/patientMembershipFields.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type UpdatePatientMembershipMutationVariables = Types.Exact<{
  set?: Types.Maybe<Types.PatientMembership_Set_Input>;
  id: Types.Scalars['uuid'];
}>;


export type UpdatePatientMembershipMutation = { __typename?: 'mutation_root', update_patientMembership_by_pk?: { __typename?: 'patientMembership', id: any, status: string, createdAt: any, updatedAt: any, nextPaymentDate?: any | null | undefined, subscriptionEndDate?: any | null | undefined, membership: { __typename?: 'membership', id: any, name: string, type: Types.MembershipType_Enum, price: number, taxRate: any, description?: string | null | undefined, image?: string | null | undefined, availableInStore?: boolean | null | undefined, discount: number, billingPeriodInDays: number, maxBillingCycles?: number | null | undefined, billingDayOfMonth?: number | null | undefined, createdAt: any, updatedAt: any, locationId?: any | null | undefined, workspaceId: any, color: string, hasCoupons: boolean, hasCredits: boolean, credits: number, archived: boolean, location?: { __typename?: 'location', id: any, name: string } | null | undefined, membershipCoupons: Array<{ __typename?: 'membershipCoupon', count: number, coupon: { __typename?: 'coupon', id: any, name: string } }> }, payments: Array<{ __typename?: 'payment', createdAt: any, id: any, workspaceId: any, paymentMethodId?: any | null | undefined, paymentMethodType: string, payrixType?: string | null | undefined, amount: number, invoiceId?: any | null | undefined, status: string, type?: string | null | undefined }> } | null | undefined };


export const UpdatePatientMembershipDocument = gql`
    mutation UpdatePatientMembership($set: patientMembership_set_input, $id: uuid!) {
  update_patientMembership_by_pk(_set: $set, pk_columns: {id: $id}) {
    ...PatientMembershipFields
  }
}
    ${PatientMembershipFieldsFragmentDoc}`;
export type UpdatePatientMembershipMutationFn = Apollo.MutationFunction<UpdatePatientMembershipMutation, UpdatePatientMembershipMutationVariables>;

/**
 * __useUpdatePatientMembershipMutation__
 *
 * To run a mutation, you first call `useUpdatePatientMembershipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePatientMembershipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePatientMembershipMutation, { data, loading, error }] = useUpdatePatientMembershipMutation({
 *   variables: {
 *      set: // value for 'set'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdatePatientMembershipMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePatientMembershipMutation, UpdatePatientMembershipMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePatientMembershipMutation, UpdatePatientMembershipMutationVariables>(UpdatePatientMembershipDocument, options);
      }
export type UpdatePatientMembershipMutationHookResult = ReturnType<typeof useUpdatePatientMembershipMutation>;
export type UpdatePatientMembershipMutationResult = Apollo.MutationResult<UpdatePatientMembershipMutation>;
export type UpdatePatientMembershipMutationOptions = Apollo.BaseMutationOptions<UpdatePatientMembershipMutation, UpdatePatientMembershipMutationVariables>;