import React from 'react';
import {
  InputGroup,
  Radio,
  RadioGroup,
  Text,
  Tooltip,
  Icon,
  HStack,
  Box,
} from '@chakra-ui/react';
import { InfoIcon } from '@chakra-ui/icons';
import DEFAULT_CONTROL_STYLE from './styles/DefaultControlStyle';
import DEFAULT_INPUT_CONTAINER_STYLE from './styles/DefaultInputContainerStyle';
import { RadioInputProps } from './types';

function RadioInput({
  direction = 'row',
  options,
  radioSize = 'md',
  styles = {},
  ...props
}: Omit<RadioInputProps, 'type'>) {
  const inputStyles = { ...DEFAULT_CONTROL_STYLE, ...styles.control };

  const containerStyles = {
    ...DEFAULT_INPUT_CONTAINER_STYLE,
    ...styles.inputContainer,
  };

  return (
    <InputGroup {...containerStyles}>
      <RadioGroup
        display="grid"
        gridAutoColumns="max-content"
        gridAutoFlow={direction === 'column' ? 'row' : 'column'}
        gridColumnGap="20px"
        gridRowGap="20px"
        {...inputStyles}
        {...props}
      >
        {options.map((option) => (
          <Box key={`${props.name}-option-${option.value}`}>
            <Radio
              colorScheme={props.colorScheme}
              size={radioSize}
              value={option.value}
              {...inputStyles}
            >
              <HStack spacing={2} align="center">
                <Text
                  color={inputStyles.color}
                  fontSize={inputStyles.fontSize}
                  fontWeight={inputStyles.fontWeight}
                >
                  {option.label}
                </Text>
                {option.description && (
                  <Tooltip
                    label={option.description}
                    placement="top"
                    hasArrow
                    openDelay={300}
                  >
                    <Icon
                      as={InfoIcon}
                      boxSize={3}
                      color="gray.500"
                      cursor="help"
                    />
                  </Tooltip>
                )}
              </HStack>
            </Radio>
          </Box>
        ))}
      </RadioGroup>
    </InputGroup>
  );
}

export default RadioInput;
