import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetPatientLabOrderUrlMutationVariables = Types.Exact<{
  patientId?: Types.Maybe<Types.Scalars['uuid']>;
}>;


export type GetPatientLabOrderUrlMutation = { __typename?: 'mutation_root', getPatientLabOrderUrl: { __typename?: 'LabOrderUrlOutput', iframeUrl: string } };


export const GetPatientLabOrderUrlDocument = gql`
    mutation getPatientLabOrderUrl($patientId: uuid) {
  getPatientLabOrderUrl(patientId: $patientId) {
    iframeUrl
  }
}
    `;
export type GetPatientLabOrderUrlMutationFn = Apollo.MutationFunction<GetPatientLabOrderUrlMutation, GetPatientLabOrderUrlMutationVariables>;

/**
 * __useGetPatientLabOrderUrlMutation__
 *
 * To run a mutation, you first call `useGetPatientLabOrderUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetPatientLabOrderUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getPatientLabOrderUrlMutation, { data, loading, error }] = useGetPatientLabOrderUrlMutation({
 *   variables: {
 *      patientId: // value for 'patientId'
 *   },
 * });
 */
export function useGetPatientLabOrderUrlMutation(baseOptions?: Apollo.MutationHookOptions<GetPatientLabOrderUrlMutation, GetPatientLabOrderUrlMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GetPatientLabOrderUrlMutation, GetPatientLabOrderUrlMutationVariables>(GetPatientLabOrderUrlDocument, options);
      }
export type GetPatientLabOrderUrlMutationHookResult = ReturnType<typeof useGetPatientLabOrderUrlMutation>;
export type GetPatientLabOrderUrlMutationResult = Apollo.MutationResult<GetPatientLabOrderUrlMutation>;
export type GetPatientLabOrderUrlMutationOptions = Apollo.BaseMutationOptions<GetPatientLabOrderUrlMutation, GetPatientLabOrderUrlMutationVariables>;