import * as Types from '../../generated/graphql';

import { gql } from '@apollo/client';
export type PatientTableFieldsFragment = { __typename?: 'patient', email?: string | null | undefined, id: any, firstName: string, lastName: string, status: string, attributes: { __typename?: 'PatientAttributes', email?: string | null | undefined, firstName?: string | null | undefined, middleName?: string | null | undefined, clinicId?: number | null | undefined, dosespotPatientId?: number | null | undefined, lastName?: string | null | undefined, status?: string | null | undefined, dob?: string | null | undefined, phoneNumber?: string | null | undefined, profilePicture?: { __typename?: 'FilePayload', filePath: string, url: string, thumbnailUrl?: string | null | undefined } | null | undefined }, patientWorkspaces: Array<{ __typename?: 'patientWorkspace', id: any, status?: string | null | undefined, patientReferenceNumber: number, profileNote?: string | null | undefined, workspaceId: any, primaryProvider?: { __typename?: 'provider', id: any, firstName: string, lastName: string } | null | undefined }>, lastAppointment: Array<{ __typename?: 'appointment', id: any, timerange?: any | null | undefined, appointmentServices: Array<{ __typename?: 'appointmentService', service: { __typename?: 'service', name: string } }> }>, patientMemberships: Array<{ __typename?: 'patientMembership', id: any, status: string, createdAt: any, nextPaymentDate?: any | null | undefined, membership: { __typename?: 'membership', name: string, type: Types.MembershipType_Enum, color: string } }>, patientWallets: Array<{ __typename?: 'patientWallet', id: any, patientId: any, balance: number, patientWalletCoupons: Array<{ __typename?: 'patientWalletCoupon', coupon: { __typename?: 'coupon', id: any, name: string, rule: string } }> }> };

export const PatientTableFieldsFragmentDoc = gql`
    fragment PatientTableFields on patient {
  attributes {
    email
    firstName
    middleName
    clinicId
    dosespotPatientId
    lastName
    status
    dob
    phoneNumber
    profilePicture {
      filePath
      url
      thumbnailUrl
    }
  }
  email
  id
  firstName
  lastName
  patientWorkspaces {
    id
    status
    patientReferenceNumber
    primaryProvider {
      id
      firstName
      lastName
    }
    profileNote
    workspaceId
  }
  status
  lastAppointment: appointments(limit: 1, order_by: {timerange: desc}) {
    id
    timerange
    appointmentServices {
      service {
        name
      }
    }
  }
  patientMemberships: patientMemberships(
    where: {status: {_in: ["ACTIVE", "MISSED_PAYMENT"]}}
  ) {
    id
    status
    createdAt
    nextPaymentDate
    membership {
      name
      type
      color
    }
  }
  patientWallets {
    id
    patientId
    balance
    patientWalletCoupons: patientWalletCoupons(
      where: {used: {_eq: false}, archived: {_eq: false}}
    ) {
      coupon {
        id
        name
        rule
      }
    }
  }
}
    `;