import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { DocumentFieldsFragmentDoc } from '../../fragments/documentFields.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type InsertDocumentMutationVariables = Types.Exact<{
  document: Types.Document_Insert_Input;
}>;


export type InsertDocumentMutation = { __typename?: 'mutation_root', insert_document?: { __typename?: 'document_mutation_response', returning: Array<{ __typename?: 'document', id: any, title?: string | null | undefined, filePath: string, documentType: string, documentTags: Array<{ __typename?: 'documentTag', id: any, tag: { __typename?: 'tag', color?: string | null | undefined, id: any, title: string } }>, file?: { __typename?: 'FileOnlyURL', url: string } | null | undefined }> } | null | undefined };


export const InsertDocumentDocument = gql`
    mutation insertDocument($document: document_insert_input!) {
  insert_document(
    objects: [$document]
    on_conflict: {constraint: document_filePath_key, update_columns: updatedAt}
  ) {
    returning {
      ...DocumentFields
    }
  }
}
    ${DocumentFieldsFragmentDoc}`;
export type InsertDocumentMutationFn = Apollo.MutationFunction<InsertDocumentMutation, InsertDocumentMutationVariables>;

/**
 * __useInsertDocumentMutation__
 *
 * To run a mutation, you first call `useInsertDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertDocumentMutation, { data, loading, error }] = useInsertDocumentMutation({
 *   variables: {
 *      document: // value for 'document'
 *   },
 * });
 */
export function useInsertDocumentMutation(baseOptions?: Apollo.MutationHookOptions<InsertDocumentMutation, InsertDocumentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertDocumentMutation, InsertDocumentMutationVariables>(InsertDocumentDocument, options);
      }
export type InsertDocumentMutationHookResult = ReturnType<typeof useInsertDocumentMutation>;
export type InsertDocumentMutationResult = Apollo.MutationResult<InsertDocumentMutation>;
export type InsertDocumentMutationOptions = Apollo.BaseMutationOptions<InsertDocumentMutation, InsertDocumentMutationVariables>;