import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetPatientTagQueryVariables = Types.Exact<{
  where?: Types.Maybe<Types.PatientTag_Bool_Exp>;
  limit?: Types.Maybe<Types.Scalars['Int']>;
  offset?: Types.Maybe<Types.Scalars['Int']>;
  orderBy?: Types.Maybe<Array<Types.PatientTag_Order_By> | Types.PatientTag_Order_By>;
}>;


export type GetPatientTagQuery = { __typename?: 'query_root', patientTag: Array<{ __typename?: 'patientTag', id: any, title: string, color?: string | null | undefined, patientTags_aggregate: { __typename?: 'patientTags_aggregate', aggregate?: { __typename?: 'patientTags_aggregate_fields', count: number } | null | undefined } }> };


export const GetPatientTagDocument = gql`
    query GetPatientTag($where: patientTag_bool_exp, $limit: Int, $offset: Int, $orderBy: [patientTag_order_by!]) {
  patientTag(where: $where, limit: $limit, offset: $offset, order_by: $orderBy) {
    id
    title
    color
    patientTags_aggregate {
      aggregate {
        count(columns: patientId)
      }
    }
  }
}
    `;

/**
 * __useGetPatientTagQuery__
 *
 * To run a query within a React component, call `useGetPatientTagQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPatientTagQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPatientTagQuery({
 *   variables: {
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetPatientTagQuery(baseOptions?: Apollo.QueryHookOptions<GetPatientTagQuery, GetPatientTagQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPatientTagQuery, GetPatientTagQueryVariables>(GetPatientTagDocument, options);
      }
export function useGetPatientTagLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPatientTagQuery, GetPatientTagQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPatientTagQuery, GetPatientTagQueryVariables>(GetPatientTagDocument, options);
        }
export type GetPatientTagQueryHookResult = ReturnType<typeof useGetPatientTagQuery>;
export type GetPatientTagLazyQueryHookResult = ReturnType<typeof useGetPatientTagLazyQuery>;
export type GetPatientTagQueryResult = Apollo.QueryResult<GetPatientTagQuery, GetPatientTagQueryVariables>;