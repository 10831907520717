import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type DeleteCustomQuestionOptionMutationVariables = Types.Exact<{
  where: Types.CustomQuestionOption_Bool_Exp;
}>;


export type DeleteCustomQuestionOptionMutation = { __typename?: 'mutation_root', delete_customQuestionOption?: { __typename?: 'customQuestionOption_mutation_response', returning: Array<{ __typename?: 'customQuestionOption', id: any }> } | null | undefined };


export const DeleteCustomQuestionOptionDocument = gql`
    mutation deleteCustomQuestionOption($where: customQuestionOption_bool_exp!) {
  delete_customQuestionOption(where: $where) {
    returning {
      id
    }
  }
}
    `;
export type DeleteCustomQuestionOptionMutationFn = Apollo.MutationFunction<DeleteCustomQuestionOptionMutation, DeleteCustomQuestionOptionMutationVariables>;

/**
 * __useDeleteCustomQuestionOptionMutation__
 *
 * To run a mutation, you first call `useDeleteCustomQuestionOptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCustomQuestionOptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCustomQuestionOptionMutation, { data, loading, error }] = useDeleteCustomQuestionOptionMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDeleteCustomQuestionOptionMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCustomQuestionOptionMutation, DeleteCustomQuestionOptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCustomQuestionOptionMutation, DeleteCustomQuestionOptionMutationVariables>(DeleteCustomQuestionOptionDocument, options);
      }
export type DeleteCustomQuestionOptionMutationHookResult = ReturnType<typeof useDeleteCustomQuestionOptionMutation>;
export type DeleteCustomQuestionOptionMutationResult = Apollo.MutationResult<DeleteCustomQuestionOptionMutation>;
export type DeleteCustomQuestionOptionMutationOptions = Apollo.BaseMutationOptions<DeleteCustomQuestionOptionMutation, DeleteCustomQuestionOptionMutationVariables>;