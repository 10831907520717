import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { ClinicalMessageFieldsFragmentDoc } from '../../fragments/clinicalMessageFields.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type UpdateClinicalMessageByPkMutationVariables = Types.Exact<{
  set?: Types.Maybe<Types.ClinicalMessage_Set_Input>;
  id: Types.Scalars['uuid'];
}>;


export type UpdateClinicalMessageByPkMutation = { __typename?: 'mutation_root', update_clinicalMessage_by_pk?: { __typename?: 'clinicalMessage', id: any, messageText: string, messageSubject: string, messageType: string, eventSendTime: number, fromAddress?: string | null | undefined, attachmentDocument?: { __typename?: 'document', id: any, title?: string | null | undefined } | null | undefined } | null | undefined };


export const UpdateClinicalMessageByPkDocument = gql`
    mutation UpdateClinicalMessageByPk($set: clinicalMessage_set_input, $id: uuid!) {
  update_clinicalMessage_by_pk(_set: $set, pk_columns: {id: $id}) {
    ...ClinicalMessageFields
  }
}
    ${ClinicalMessageFieldsFragmentDoc}`;
export type UpdateClinicalMessageByPkMutationFn = Apollo.MutationFunction<UpdateClinicalMessageByPkMutation, UpdateClinicalMessageByPkMutationVariables>;

/**
 * __useUpdateClinicalMessageByPkMutation__
 *
 * To run a mutation, you first call `useUpdateClinicalMessageByPkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClinicalMessageByPkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClinicalMessageByPkMutation, { data, loading, error }] = useUpdateClinicalMessageByPkMutation({
 *   variables: {
 *      set: // value for 'set'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateClinicalMessageByPkMutation(baseOptions?: Apollo.MutationHookOptions<UpdateClinicalMessageByPkMutation, UpdateClinicalMessageByPkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateClinicalMessageByPkMutation, UpdateClinicalMessageByPkMutationVariables>(UpdateClinicalMessageByPkDocument, options);
      }
export type UpdateClinicalMessageByPkMutationHookResult = ReturnType<typeof useUpdateClinicalMessageByPkMutation>;
export type UpdateClinicalMessageByPkMutationResult = Apollo.MutationResult<UpdateClinicalMessageByPkMutation>;
export type UpdateClinicalMessageByPkMutationOptions = Apollo.BaseMutationOptions<UpdateClinicalMessageByPkMutation, UpdateClinicalMessageByPkMutationVariables>;