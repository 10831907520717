import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { SmartNoteFieldsFragmentDoc } from '../../fragments/smartNoteFields.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetSmartNoteQueryVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
}>;


export type GetSmartNoteQuery = { __typename?: 'query_root', smartNote_by_pk?: { __typename?: 'smartNote', id: any, title: string, callPhrase: string, content: string, createdAt: any, updatedAt: any, workspaceId: any } | null | undefined };


export const GetSmartNoteDocument = gql`
    query GetSmartNote($id: uuid!) {
  smartNote_by_pk(id: $id) {
    ...SmartNoteFields
  }
}
    ${SmartNoteFieldsFragmentDoc}`;

/**
 * __useGetSmartNoteQuery__
 *
 * To run a query within a React component, call `useGetSmartNoteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSmartNoteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSmartNoteQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSmartNoteQuery(baseOptions: Apollo.QueryHookOptions<GetSmartNoteQuery, GetSmartNoteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSmartNoteQuery, GetSmartNoteQueryVariables>(GetSmartNoteDocument, options);
      }
export function useGetSmartNoteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSmartNoteQuery, GetSmartNoteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSmartNoteQuery, GetSmartNoteQueryVariables>(GetSmartNoteDocument, options);
        }
export type GetSmartNoteQueryHookResult = ReturnType<typeof useGetSmartNoteQuery>;
export type GetSmartNoteLazyQueryHookResult = ReturnType<typeof useGetSmartNoteLazyQuery>;
export type GetSmartNoteQueryResult = Apollo.QueryResult<GetSmartNoteQuery, GetSmartNoteQueryVariables>;