import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { ConsumableFieldsFragmentDoc } from '../../fragments/consumableFields.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetConsumablesQueryVariables = Types.Exact<{
  where: Types.Consumable_Bool_Exp;
  limit: Types.Scalars['Int'];
  offset?: Types.Maybe<Types.Scalars['Int']>;
  orderBy?: Types.Maybe<Array<Types.Consumable_Order_By> | Types.Consumable_Order_By>;
}>;


export type GetConsumablesQuery = { __typename?: 'query_root', consumable: Array<{ __typename?: 'consumable', id: any, cost: number, metadata: any, originalQuantity: any, quantity: any, isExpirable: boolean, expirationDate?: any | null | undefined, daysBeforeNotification?: number | null | undefined, shouldWarnQuantity: boolean, warningQuantity?: number | null | undefined, createdAt: any, updatedAt: any, archived: boolean, type: { __typename?: 'consumableType', id: any, name: string, requiredFields: any, unitStep: any, unitName: string, defaultNumberOfUnits: any, defaultPricePerUnit: number }, brand?: { __typename?: 'consumableBrand', id: any, name: string } | null | undefined, purchaseOrder?: { __typename?: 'purchaseOrder', id: any, orderNumber: string } | null | undefined }> };


export const GetConsumablesDocument = gql`
    query GetConsumables($where: consumable_bool_exp!, $limit: Int!, $offset: Int, $orderBy: [consumable_order_by!]) {
  consumable(where: $where, limit: $limit, offset: $offset, order_by: $orderBy) {
    ...ConsumableFields
  }
}
    ${ConsumableFieldsFragmentDoc}`;

/**
 * __useGetConsumablesQuery__
 *
 * To run a query within a React component, call `useGetConsumablesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetConsumablesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetConsumablesQuery({
 *   variables: {
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetConsumablesQuery(baseOptions: Apollo.QueryHookOptions<GetConsumablesQuery, GetConsumablesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetConsumablesQuery, GetConsumablesQueryVariables>(GetConsumablesDocument, options);
      }
export function useGetConsumablesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetConsumablesQuery, GetConsumablesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetConsumablesQuery, GetConsumablesQueryVariables>(GetConsumablesDocument, options);
        }
export type GetConsumablesQueryHookResult = ReturnType<typeof useGetConsumablesQuery>;
export type GetConsumablesLazyQueryHookResult = ReturnType<typeof useGetConsumablesLazyQuery>;
export type GetConsumablesQueryResult = Apollo.QueryResult<GetConsumablesQuery, GetConsumablesQueryVariables>;