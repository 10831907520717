import {
  Button,
  Divider,
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  FormControl,
  FormLabel,
  ModalHeader,
  ModalOverlay,
  HStack,
  Heading,
  Stack,
  Wrap,
  WrapItem,
  Select,
} from '@chakra-ui/react';
import {
  createMarkupEditorToolStyle,
  createMarkupEditorToolStyles,
  createDefaultColorOptions,
  createDefaultStrokeScaleOptions,
} from '@pqina/pintura';
import { useMemo, useRef, useState } from 'react';
import { Photo, PhotoModel, useStores } from '@webapp/state-models';
import { Configuration, Tool } from 'photoeditorsdk';
import { FaEraser, FaSave } from 'react-icons/fa';

import { PinturaImageEditor } from '@webapp/ui-composites';
import { useFlagsmith } from 'flagsmith-react';
import { MdOutlineDraw } from 'react-icons/md';
import { FiType } from 'react-icons/fi';

import { AiOutlineReload } from 'react-icons/ai';
import SelectPhotoOrTemplate from '../SelectPhotoOrTemplate/SelectPhotoOrTemplate';

interface MarkupModalProps {
  isOpen: boolean;
  onClose: () => void;
  onMarkupCompleted: (filePath: string, imageState?: any, file?: File) => void;
  fullscreen?: boolean;
  patientId?: string;
  photoEditorConfiguration?: Partial<Configuration>;
}

export const MarkupModal = ({
  isOpen,
  onClose,
  onMarkupCompleted,
  fullscreen,
  photoEditorConfiguration,
  patientId,
}: MarkupModalProps): JSX.Element => {
  const { workspace } = useStores();
  const { hasFeature } = useFlagsmith();
  const imageRef = useRef<any>();

  const [annotateActiveTool, setAnnotateActiveTool] = useState('sharpie');
  const [currentColor, setCurrentColor] = useState('red');
  const [strokeWidth, setStrokeWidth] = useState('0.50%');

  const [selectedTemplate, setSelectedTemplate] = useState<Photo | null>(null);

  const [mode, setMode] = useState(0);

  const reset = (): void => {
    setMode(0);
    setSelectedTemplate(null);
  };

  const resetAndClose = (): void => {
    reset();
    onClose();
  };

  const setModeEditor = (): void => {
    setMode(1);
  };

  const setModeSelectTemplate = (): void => {
    setMode(0);
  };

  const doneEditing = async (): Promise<void> => {
    const { filePath, file } = await imageRef?.current?.saveCurrentImage({
      randomizeFileName: true,
    });

    onMarkupCompleted(filePath, imageRef?.current?.imageState(), file);
    onClose();
    reset();
  };

  const modalContentMaxWidth = useMemo(() => {
    if (fullscreen) {
      return undefined;
    }
    if (mode === 1) {
      return '850px';
    }

    return '700px';
  }, [mode, fullscreen]);

  const allowedColors = ['black', 'blue', 'green', 'red', 'white'];
  const colorOptions = useMemo(() => createDefaultColorOptions(), []);
  const strokeOptions = useMemo(() => createDefaultStrokeScaleOptions(), []);

  const innerComponent =
    mode === 0 ? (
      <ModalBody display="flex" justifyContent="center" alignItems="center">
        <SelectPhotoOrTemplate
          patientId={patientId}
          removeAssetPrefix={true}
          setSelectedTemplate={(selectedTemplate: PhotoModel) => {
            setSelectedTemplate(selectedTemplate);
            setModeEditor();
          }}
        />
      </ModalBody>
    ) : (
      <ModalBody>
        {selectedTemplate && (
          <HStack w="95vw" h="full">
            <Box w={'90vw'} h={'96vh'}>
              <PinturaImageEditor
                ref={imageRef}
                photo={selectedTemplate}
                editorConfigOverrides={{
                  enableToolbar: false,
                  enableUtils: false,
                  markupEditorToolbar: [],
                  annotateActiveTool,
                  markupEditorToolStyles: createMarkupEditorToolStyles({
                    // create the text tool style and override fontSize property
                    sharpie: createMarkupEditorToolStyle('path', {
                      strokeWidth,
                      strokeColor: colorOptions[currentColor],
                      disableSelect: true,
                      disableManipulate: true,
                      bitmap: true,
                    }),

                    text: createMarkupEditorToolStyle('text', {
                      color: colorOptions[currentColor],
                    }),
                  }),
                }}
              />
            </Box>
            <Stack maxW={'200px'}>
              <Button
                size="sm"
                leftIcon={<MdOutlineDraw />}
                variant={annotateActiveTool === 'sharpie' ? 'outline' : 'ghost'}
                onClick={() => {
                  setAnnotateActiveTool('sharpie');
                }}
                onPointerDown={() => {
                  setAnnotateActiveTool('sharpie');
                }}
              >
                Draw
              </Button>

              <Button
                size="sm"
                leftIcon={<FiType />}
                variant={annotateActiveTool === 'text' ? 'outline' : 'ghost'}
                onClick={() => {
                  setAnnotateActiveTool('text');
                }}
                onPointerDown={() => {
                  setAnnotateActiveTool('text');
                }}
              >
                Type
              </Button>

              <Button
                size="sm"
                leftIcon={<FaEraser />}
                variant={annotateActiveTool === 'eraser' ? 'outline' : 'ghost'}
                onClick={() => {
                  setAnnotateActiveTool('eraser');
                }}
                onPointerDown={() => {
                  setAnnotateActiveTool('eraser');
                }}
              >
                Erase
              </Button>
              <Divider />
              <Stack
                rounded={'lg'}
                borderColor="gray.100"
                borderWidth={'1px'}
                spacing={1}
                p={1}
                w={'full'}
              >
                <FormControl size="sm">
                  <FormLabel fontSize={'sm'} m={0}>
                    Color
                  </FormLabel>
                  <Wrap w={'full'} spacing={1} justify="center">
                    {allowedColors.map((color) => (
                      <WrapItem key={color}>
                        <Box
                          opacity={currentColor === color ? 1 : 0.2}
                          onClick={() => setCurrentColor(color)}
                          onPointerDown={() => setCurrentColor(color)}
                          rounded="md"
                          borderColor="gray.100"
                          borderWidth={'1px'}
                          w={'30px'}
                          h={'30px'}
                          bg={`rgb(${colorOptions[color]
                            .map((c) => Math.ceil(c * 255))
                            .join(',')})`}
                        />
                      </WrapItem>
                    ))}
                  </Wrap>
                </FormControl>
                <FormControl size="sm">
                  <FormLabel fontSize={'sm'} m={0}>
                    Line Width
                  </FormLabel>
                  <Select
                    size="sm"
                    placeholder="Stroke Width"
                    onChange={(v) => setStrokeWidth(v.target.value)}
                    value={strokeWidth}
                    onPointerUp={(e) => e.currentTarget.focus()}
                  >
                    {Object.keys(strokeOptions).map((s) => (
                      <option key={s} value={strokeOptions[s]}>
                        {s}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </Stack>
              <Divider />
              <Button
                size="sm"
                leftIcon={<AiOutlineReload />}
                onClick={setModeSelectTemplate}
              >
                Select Different Template
              </Button>
              <Button
                size="sm"
                colorScheme={'teal'}
                onClick={doneEditing}
                leftIcon={<FaSave />}
              >
                Done
              </Button>
            </Stack>
          </HStack>
        )}
      </ModalBody>
    );

  return (
    <Modal
      isOpen={isOpen}
      onClose={resetAndClose}
      size={fullscreen ? 'full' : undefined}
    >
      <ModalOverlay />
      <ModalContent
        maxW={modalContentMaxWidth}
        minH={fullscreen ? undefined : '500px'}
      >
        {mode === 0 && (
          <ModalHeader>
            <HStack w={'full'} justifyContent="space-between">
              <Heading size="sm">Select Markup Template</Heading>
              {mode === 1 && (
                <HStack spacing={2} pr={8}>
                  <Button
                    leftIcon={<AiOutlineReload />}
                    onClick={setModeSelectTemplate}
                  >
                    Select Different Template
                  </Button>
                  <Button
                    colorScheme={'teal'}
                    onClick={doneEditing}
                    leftIcon={<FaSave />}
                  >
                    Done
                  </Button>
                </HStack>
              )}
            </HStack>
          </ModalHeader>
        )}
        <ModalCloseButton />
        {innerComponent}
      </ModalContent>
    </Modal>
  );
};

export default MarkupModal;
