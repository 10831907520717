import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetMinimalPatientQueryVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
}>;


export type GetMinimalPatientQuery = { __typename?: 'query_root', patient_by_pk?: { __typename?: 'patient', id: any, attributes: { __typename?: 'PatientAttributes', firstName?: string | null | undefined, id?: string | null | undefined, lastName?: string | null | undefined, phoneNumber?: string | null | undefined, profilePicture?: { __typename?: 'FilePayload', url: string, thumbnailUrl?: string | null | undefined } | null | undefined } } | null | undefined };


export const GetMinimalPatientDocument = gql`
    query GetMinimalPatient($id: uuid!) {
  patient_by_pk(id: $id) {
    id
    attributes {
      firstName
      id
      lastName
      phoneNumber
      profilePicture {
        url
        thumbnailUrl
      }
    }
  }
}
    `;

/**
 * __useGetMinimalPatientQuery__
 *
 * To run a query within a React component, call `useGetMinimalPatientQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMinimalPatientQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMinimalPatientQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetMinimalPatientQuery(baseOptions: Apollo.QueryHookOptions<GetMinimalPatientQuery, GetMinimalPatientQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMinimalPatientQuery, GetMinimalPatientQueryVariables>(GetMinimalPatientDocument, options);
      }
export function useGetMinimalPatientLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMinimalPatientQuery, GetMinimalPatientQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMinimalPatientQuery, GetMinimalPatientQueryVariables>(GetMinimalPatientDocument, options);
        }
export type GetMinimalPatientQueryHookResult = ReturnType<typeof useGetMinimalPatientQuery>;
export type GetMinimalPatientLazyQueryHookResult = ReturnType<typeof useGetMinimalPatientLazyQuery>;
export type GetMinimalPatientQueryResult = Apollo.QueryResult<GetMinimalPatientQuery, GetMinimalPatientQueryVariables>;