import * as Types from '../../generated/graphql';

import { gql } from '@apollo/client';
export type LocationScheduleFieldsFragment = { __typename?: 'locationSchedule', id: any, schedule: any, effectiveFrom?: any | null | undefined, createdAt: any, updatedAt: any, locationId: any };

export const LocationScheduleFieldsFragmentDoc = gql`
    fragment LocationScheduleFields on locationSchedule {
  id
  schedule
  effectiveFrom
  createdAt
  updatedAt
  locationId
}
    `;