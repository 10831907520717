/* eslint-disable camelcase */
/* eslint-disable guard-for-in */
/* eslint-disable no-continue */
/* eslint-disable no-restricted-syntax */
import { DRAGGABLE_QUESTIONS } from '@webapp/constants';
import {
  CustomFormFieldType_Enum,
  PatientAttributes,
  PatientFieldsFragment,
  useUpdatePatientWorkspaceMutation,
} from '@webapp/graphql';
import { useStores } from '@webapp/state-models';
import { FormInputProps, DefaultQuestion } from '@webapp/types';
import { camelCase } from 'lodash';
import { useState, useCallback, useEffect } from 'react';
import { useFlagsmith } from 'flagsmith-react';
export interface EntityRoot {
  rootKey: string;
  entity: Entity;
}

export interface Entity {
  apiFieldKey: string;
  value: string;
  type?: string;
  label?: string;
  options?: { value: string }[];
}

export interface DiscreteAttributes {
  firstName?: string;
  lastName?: string;
  gender?: string;
  sex?: string;
  email?: string;
  phoneNumber?: string;
  dob?: string;
  language?: string;
  partialMediaConsent?: boolean;
  fullMediaConsent?: boolean;
  emailMarketingEnabled?: boolean;
  smsMarketingEnabled?: boolean;
  phoneMarketingEnabled?: boolean;
}

export interface UsePatientAttributes {
  getValue: (key: string) => string | undefined;
  getEntity: (key: string) => EntityRoot | undefined;
  setValue: (apiFieldKey: string, value: string) => any;
  saveAttributes: (
    newAttributes: {
      [key: string]: string | undefined;
    },
    discreteAttributes: DiscreteAttributes
  ) => void;
  formInputProps: (apiFieldKey: string) => FormInputProps;
  updateAndSaveAttributes: (
    newAttributes: {
      [key: string]: string | undefined;
    },
    discreteAttributes?: DiscreteAttributes
  ) => void;
}

export interface UsePatientAttributesProps {
  patient: Pick<PatientFieldsFragment, 'id' | 'attributes'>;
}

const POSSIBLE_QUESTIONS = DRAGGABLE_QUESTIONS.map((q) => ({
  ...q,
  apiFieldKey: camelCase(q.label),
}));

export function usePatientAttributes({
  patient,
}: UsePatientAttributesProps): UsePatientAttributes {
  const { workspace } = useStores();
  const { hasFeature } = useFlagsmith();

  const [patientAttributes, setPatientAttributes] = useState<PatientAttributes>(
    patient.attributes.attributes ?? {}
  );

  useEffect(() => {
    setPatientAttributes(patient.attributes.attributes ?? {});
  }, [patient?.id]);

  const processEntities = useCallback(
    (localAttributes?: PatientAttributes) => {
      const allEntities = [];
      for (const [key, value] of Object.entries(
        localAttributes || patientAttributes
      )) {
        if (!value) continue;
        const { entities: localEntities } = value;

        if (!localEntities) continue;

        for (const entity of localEntities) {
          const itemToPush = { rootKey: key, entity };

          allEntities.push(itemToPush);
        }
      }

      return allEntities;
    },
    [patientAttributes, patient?.id]
  );

  const [entities, setEntities] = useState<EntityRoot[]>(processEntities());

  useEffect(() => {
    setEntities(processEntities());
  }, [patientAttributes, patient?.id]);

  const [updatePatientWorkspace] = useUpdatePatientWorkspaceMutation({
    refetchQueries: ['GetOnePatient'],
  });

  // Get entity by apiFieldKey
  function getEntity(
    key: string,
    attributeOverride?: any
  ): EntityRoot | undefined {
    return (
      attributeOverride ? processEntities(attributeOverride) : entities
    ).find(({ entity: { apiFieldKey } }) => apiFieldKey === key);
  }

  // Get value by apiFieldKey
  function getValue(key: string): string | undefined {
    const entityValue = entities.find(
      ({ entity: { apiFieldKey } }) => apiFieldKey === key
    )?.entity?.value;

    if (entityValue) {
      return entityValue;
    }

    const simpleValue = patientAttributes[key as keyof PatientAttributes];

    switch (typeof simpleValue) {
      case 'string':
        return simpleValue;
      case 'object':
        return '';
      case 'boolean':
        return simpleValue;
      default:
        return simpleValue;
    }
  }

  function setValue(
    apiFieldKey: string,
    value: string,
    overrideAttributes?: PatientAttributes,
    expandPossibleQuestions: DefaultQuestion[] = []
  ): PatientAttributes {
    const localAttributes = overrideAttributes || { ...patientAttributes };
    if (hasFeature('custom-forms-surveyjs')) {
      return { ...localAttributes, [apiFieldKey]: value };
    }
    let entityRoot = getEntity(apiFieldKey, overrideAttributes);

    if (!entityRoot) {
      const possibleQuestion = [
        ...POSSIBLE_QUESTIONS,
        ...expandPossibleQuestions,
      ].find((pq) => pq.apiFieldKey === apiFieldKey) as DefaultQuestion;

      entityRoot = {
        rootKey: apiFieldKey,
        entity: {
          apiFieldKey,
          value,
          ...(possibleQuestion && {
            type: possibleQuestion.type ?? CustomFormFieldType_Enum.TextInput,
            label: possibleQuestion.label,
            options: possibleQuestion.options,
            category: possibleQuestion.category,
            description: possibleQuestion.description,
          }),
        },
      };
    }
    entityRoot.entity = { ...entityRoot.entity, value };
    const { entity, rootKey } = entityRoot;

    const relevantItem: {
      rootKey: string;
      entities: Entity[];
    } = localAttributes[rootKey as keyof PatientAttributes];

    if (relevantItem) {
      const relevantEntityIndex = relevantItem?.entities?.findIndex(
        (e: Entity) => e.apiFieldKey === apiFieldKey
      );

      if (relevantEntityIndex === -1 || relevantEntityIndex === undefined)
        return localAttributes;

      const relevantEntity = relevantItem.entities[relevantEntityIndex];

      if (relevantEntity?.value === entity.value) return localAttributes;

      const copyEntities = [...relevantItem.entities];

      copyEntities.splice(relevantEntityIndex, 1, entity);

      localAttributes[rootKey as keyof PatientAttributes] = {
        entities: copyEntities,
      };
    } else {
      localAttributes[rootKey as keyof PatientAttributes] = {
        entities: [entity],
      };
    }

    setPatientAttributes(localAttributes);
    return localAttributes;
  }

  function formInputProps(apiFieldKey: string): FormInputProps {
    const entityRoot = getEntity(apiFieldKey);
    const formProps: FormInputProps = {
      name: apiFieldKey,
    };

    if (!entityRoot) return formProps;

    const { entity } = entityRoot;

    switch (entity.type) {
      case CustomFormFieldType_Enum.MultipleSelect:
        formProps.type = 'multiselect';
        formProps.multiSelectProps = {
          async: false,
          staticOptions: entity.options?.map(({ value }) => ({
            label: value,
            value,
          })),
          defaultOptions: entity.value
            .split(',')
            .map((value) => ({ label: value, value })),
        };
        break;
      default:
        break;
    }

    return formProps;
  }

  const saveAttributes = useCallback(
    (
      localAttributes?: PatientAttributes,
      discreteAttributes?: DiscreteAttributes
    ) => {
      if (!localAttributes && !discreteAttributes) return;

      updatePatientWorkspace({
        variables: {
          where: {
            patientId: {
              _eq: patient.id,
            },
            workspaceId: {
              _eq: workspace?.id,
            },
          },
          _set: {
            ...(localAttributes && { attributes: localAttributes }),
            ...(discreteAttributes && discreteAttributes),
          },
        },
      });
    },
    [patient, workspace]
  );

  function updateAndSaveAttributes(
    newAttributes: {
      [key: string]: string | undefined;
    },
    discreteAttributes?: DiscreteAttributes
  ) {
    let localAttributes = { ...patientAttributes };

    for (const key in newAttributes) {
      const value = newAttributes[key];

      if (value !== undefined) {
        localAttributes = setValue(key, value, localAttributes);
      }
    }

    saveAttributes(localAttributes, discreteAttributes);
  }

  return {
    getValue,
    setValue,
    saveAttributes,
    getEntity,
    updateAndSaveAttributes,
    formInputProps,
  };
}

export default usePatientAttributes;
