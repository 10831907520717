import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { ConsumableTypeFieldsFragmentDoc } from '../../fragments/consumableTypeFields.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type UpdateConsumableTypeAndSubtypesMutationVariables = Types.Exact<{
  set?: Types.Maybe<Types.ConsumableType_Set_Input>;
  id: Types.Scalars['uuid'];
  objects: Array<Types.ConsumableTypeConsumableSubtype_Insert_Input> | Types.ConsumableTypeConsumableSubtype_Insert_Input;
}>;


export type UpdateConsumableTypeAndSubtypesMutation = { __typename?: 'mutation_root', update_consumableType_by_pk?: { __typename?: 'consumableType', id: any, name: string, requiredFields: any, defaultNumberOfUnits: any, defaultPricePerUnit: number, unitStep: any, unitName: string, createdAt: any, updatedAt: any, archived: boolean, productBarcodeId?: string | null | undefined, consumableTypeConsumableSubtypes: Array<{ __typename?: 'consumableTypeConsumableSubtype', id: any, consumableSubtype: { __typename?: 'consumableSubtype', id: any, name: string } }> } | null | undefined, delete_consumableTypeConsumableSubtype?: { __typename?: 'consumableTypeConsumableSubtype_mutation_response', affected_rows: number } | null | undefined, insert_consumableTypeConsumableSubtype?: { __typename?: 'consumableTypeConsumableSubtype_mutation_response', affected_rows: number } | null | undefined };


export const UpdateConsumableTypeAndSubtypesDocument = gql`
    mutation UpdateConsumableTypeAndSubtypes($set: consumableType_set_input, $id: uuid!, $objects: [consumableTypeConsumableSubtype_insert_input!]!) {
  update_consumableType_by_pk(_set: $set, pk_columns: {id: $id}) {
    ...ConsumableTypeFields
  }
  delete_consumableTypeConsumableSubtype(where: {consumableTypeId: {_eq: $id}}) {
    affected_rows
  }
  insert_consumableTypeConsumableSubtype(objects: $objects) {
    affected_rows
  }
}
    ${ConsumableTypeFieldsFragmentDoc}`;
export type UpdateConsumableTypeAndSubtypesMutationFn = Apollo.MutationFunction<UpdateConsumableTypeAndSubtypesMutation, UpdateConsumableTypeAndSubtypesMutationVariables>;

/**
 * __useUpdateConsumableTypeAndSubtypesMutation__
 *
 * To run a mutation, you first call `useUpdateConsumableTypeAndSubtypesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateConsumableTypeAndSubtypesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateConsumableTypeAndSubtypesMutation, { data, loading, error }] = useUpdateConsumableTypeAndSubtypesMutation({
 *   variables: {
 *      set: // value for 'set'
 *      id: // value for 'id'
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useUpdateConsumableTypeAndSubtypesMutation(baseOptions?: Apollo.MutationHookOptions<UpdateConsumableTypeAndSubtypesMutation, UpdateConsumableTypeAndSubtypesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateConsumableTypeAndSubtypesMutation, UpdateConsumableTypeAndSubtypesMutationVariables>(UpdateConsumableTypeAndSubtypesDocument, options);
      }
export type UpdateConsumableTypeAndSubtypesMutationHookResult = ReturnType<typeof useUpdateConsumableTypeAndSubtypesMutation>;
export type UpdateConsumableTypeAndSubtypesMutationResult = Apollo.MutationResult<UpdateConsumableTypeAndSubtypesMutation>;
export type UpdateConsumableTypeAndSubtypesMutationOptions = Apollo.BaseMutationOptions<UpdateConsumableTypeAndSubtypesMutation, UpdateConsumableTypeAndSubtypesMutationVariables>;