/* eslint-disable camelcase */
import { useApolloClient } from '@apollo/client';
import {
  LocationFieldsFragment,
  Location_Bool_Exp,
  SearchLocationsDocument,
} from '@webapp/graphql';
import { ObjectOption } from '@webapp/types';

export interface UseLoadLocations {
  search: (
    searchValue: string
  ) => Promise<ObjectOption<LocationFieldsFragment>[]>;
}

interface UseLoadLocationsProps {
  nameAsValue?: boolean;
  where?: Location_Bool_Exp;
}

export function useLoadLocations({
  nameAsValue = false,
  where,
}: UseLoadLocationsProps = {}): UseLoadLocations {
  const client = useApolloClient();
  return {
    search: async (searchValue: string) => {
      const result = await client.query({
        query: SearchLocationsDocument,
        variables: {
          searchValue: `%${searchValue}%`,
          limit: 10,
          where: {
            isSystemManaged: {
              _eq: false,
            },
            isActive: {
              _eq: true,
            },
            name: {
              _ilike: `%${searchValue}%`,
            },

            ...(where ?? {}),
          },
        },
      });

      const locations = result.data?.location ?? [];

      return locations.map(
        (
          location: LocationFieldsFragment
        ): ObjectOption<LocationFieldsFragment> => ({
          label: location.name,
          value: nameAsValue ? location.name : location.id,
          object: location as LocationFieldsFragment,
        })
      );
    },
  };
}

export default useLoadLocations;
