import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { DocumentFieldsFragmentDoc } from '../../fragments/documentFields.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type ListDocumentsQueryVariables = Types.Exact<{
  limit?: Types.Maybe<Types.Scalars['Int']>;
  offset?: Types.Maybe<Types.Scalars['Int']>;
  orderBy?: Types.Maybe<Array<Types.Document_Order_By> | Types.Document_Order_By>;
  where?: Types.Maybe<Types.Document_Bool_Exp>;
}>;


export type ListDocumentsQuery = { __typename?: 'query_root', document: Array<{ __typename?: 'document', id: any, title?: string | null | undefined, filePath: string, documentType: string, documentTags: Array<{ __typename?: 'documentTag', id: any, tag: { __typename?: 'tag', color?: string | null | undefined, id: any, title: string } }>, file?: { __typename?: 'FileOnlyURL', url: string } | null | undefined }> };


export const ListDocumentsDocument = gql`
    query ListDocuments($limit: Int = 10, $offset: Int = 0, $orderBy: [document_order_by!], $where: document_bool_exp) {
  document(offset: $offset, order_by: $orderBy, limit: $limit, where: $where) {
    ...DocumentFields
  }
}
    ${DocumentFieldsFragmentDoc}`;

/**
 * __useListDocumentsQuery__
 *
 * To run a query within a React component, call `useListDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListDocumentsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      orderBy: // value for 'orderBy'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useListDocumentsQuery(baseOptions?: Apollo.QueryHookOptions<ListDocumentsQuery, ListDocumentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListDocumentsQuery, ListDocumentsQueryVariables>(ListDocumentsDocument, options);
      }
export function useListDocumentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListDocumentsQuery, ListDocumentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListDocumentsQuery, ListDocumentsQueryVariables>(ListDocumentsDocument, options);
        }
export type ListDocumentsQueryHookResult = ReturnType<typeof useListDocumentsQuery>;
export type ListDocumentsLazyQueryHookResult = ReturnType<typeof useListDocumentsLazyQuery>;
export type ListDocumentsQueryResult = Apollo.QueryResult<ListDocumentsQuery, ListDocumentsQueryVariables>;