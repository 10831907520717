import { AgGridReact, AgGridReactProps } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { forwardRef, useCallback, useEffect, useRef, useState } from 'react';
import { ColDef } from 'ag-grid-community';
import './data-grid.css';
import { Box, BoxProps, Button } from '@chakra-ui/react';

interface DataGridProps<T> {
  rows: T[];
  columns: ColDef<T>[];
  onAddNew?: (n: T) => void;
  onUpdate?: (n: T) => void;
  containerStyle?: BoxProps;
  agProps?: AgGridReactProps<T>;
}

const ADD_NEW = '+ Add New';

const PAGE_SIZE = 5;

function DataGridInner<T>(
  {
    rows,
    columns,
    onAddNew,
    onUpdate,
    containerStyle,
    agProps,
  }: DataGridProps<T>,
  ref: React.MutableRefObject<AgGridReact<T>>
) {
  const internalGridRef = useRef<AgGridReact>(null);
  const [rowData, setRowData] = useState<T[]>(rows);
  const [columnDefs] = useState<ColDef<T>[]>(columns);
  const [pageSize, setPageSize] = useState<number>(PAGE_SIZE);
  const gridRef = ref || internalGridRef;

  useEffect(() => {
    if (!gridRef?.current?.api) return;

    setPageSize(gridRef.current.api.paginationGetPageSize());
  }, [gridRef]);

  useEffect(() => {
    setRowData(rows);
  }, [rows]);

  const defaultColDef: ColDef = {
    width: 200,
    editable: true,
    cellEditorPopup: false,
  };

  const cellClickedListener = useCallback(
    (event: any) => {
      if (event.value === ADD_NEW && onAddNew) {
        onAddNew(rowData[0]);
      }
    },
    [rowData, onAddNew]
  );

  const onCellValueChanged = useCallback(
    (event: any) => {
      if (event.node.rowIndex === 0) return;

      if (!onUpdate) return;

      onUpdate(event.data);
    },
    [onUpdate]
  );

  const seeMore = useCallback(() => {
    if (!gridRef?.current?.api) return;

    const newPageSize = pageSize + PAGE_SIZE;

    gridRef.current.api.paginationSetPageSize(newPageSize);

    setPageSize(newPageSize);
  }, [gridRef, pageSize]);

  return (
    <Box className="ag-theme-alpine ag-theme-data-grid" h="400px" pb={6} {...containerStyle}>
      <AgGridReact
        ref={gridRef}
        rowData={rowData}
        columnDefs={columnDefs}
        animateRows
        pagination
        paginationPageSize={PAGE_SIZE}
        suppressPaginationPanel
        defaultColDef={defaultColDef}
        onCellClicked={cellClickedListener}
        onCellValueChanged={onCellValueChanged}
        {...agProps}
      />
      {pageSize && rows.length > pageSize && (
        <Button variant="link" colorScheme="teal" onClick={seeMore}>
          See more
        </Button>
      )}
    </Box>
  );
}
// TODO: Figure out best way to type this
// This implementation follows this: https://fettblog.eu/typescript-react-generic-forward-refs/#option-1%3A-type-assertion
// But still has a small type error (possibly im implementing it incorrectly)
export const DataGrid = forwardRef(DataGridInner) as <T>(
  props: DataGridProps<T> & { ref?: React.ForwardedRef<AgGridReact<T>> }
) => ReturnType<typeof DataGridInner>;

export default DataGrid;
