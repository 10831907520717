import {
  Circle,
  Collapse,
  Box,
  HStack,
  Stack,
  Heading,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import React, { PropsWithChildren } from 'react';
import { HiChevronDown, HiChevronUp } from 'react-icons/hi';
import './step.module.scss';

export interface StepProps {
  circleColor?: string;
  defaultIsOpen?: boolean;
  headingContent: string | React.ComponentType<{ onClick: () => void }>;
  onStepOpen?: () => void;
  withTime?: string;
}

export function Step({
  circleColor = '#919197',
  children,
  defaultIsOpen = false,
  headingContent: HeadingContent,
  onStepOpen,
  withTime,
}: PropsWithChildren<StepProps>) {
  const { isOpen, onToggle } = useDisclosure({
    defaultIsOpen,
  });

  function handleClick() {
    if (onStepOpen && !isOpen) {
      onStepOpen();
    }

    onToggle();
  }

  return (
    <Stack width="100%">
      <HStack>
        <Circle
          background={circleColor}
          border={`4px solid #fff`}
          margin={'0 0 0 -4.5px'}
          size={'20px'}
          fontWeight="bold"
        ></Circle>
        <HStack alignItems="center" onClick={handleClick} w={'full'}>
          {typeof HeadingContent === 'string' ? (
            <Heading
              color="#525257"
              cursor="pointer"
              fontSize="16px"
              fontWeight="700"
              letterSpacing="0.01em"
              userSelect="none"
            >
              {HeadingContent}
            </Heading>
          ) : (
            <HeadingContent onClick={handleClick} />
          )}
          {Boolean(children) && isOpen && <HiChevronUp />}
          {Boolean(children) && !isOpen && <HiChevronDown />}
          {withTime && (
            <Text
              color="#919197"
              fontSize="14px"
              justifySelf="end"
              letterSpacing="0.03em"
            >
              {withTime}
            </Text>
          )}
        </HStack>
      </HStack>
      {Boolean(children) && (
        <Box pl={4} width="100%">
          <Collapse in={isOpen}>{children}</Collapse>
        </Box>
      )}
    </Stack>
  );
}

export default Step;
