import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { ServiceUnitFieldsFragmentDoc } from '../../fragments/serviceUnitFields.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type UpdateServiceUnitMutationVariables = Types.Exact<{
  set?: Types.Maybe<Types.ServiceUnit_Set_Input>;
  id: Types.Scalars['uuid'];
}>;


export type UpdateServiceUnitMutation = { __typename?: 'mutation_root', update_serviceUnit_by_pk?: { __typename?: 'serviceUnit', id: any, name: string } | null | undefined };


export const UpdateServiceUnitDocument = gql`
    mutation UpdateServiceUnit($set: serviceUnit_set_input, $id: uuid!) {
  update_serviceUnit_by_pk(_set: $set, pk_columns: {id: $id}) {
    ...ServiceUnitFields
  }
}
    ${ServiceUnitFieldsFragmentDoc}`;
export type UpdateServiceUnitMutationFn = Apollo.MutationFunction<UpdateServiceUnitMutation, UpdateServiceUnitMutationVariables>;

/**
 * __useUpdateServiceUnitMutation__
 *
 * To run a mutation, you first call `useUpdateServiceUnitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateServiceUnitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateServiceUnitMutation, { data, loading, error }] = useUpdateServiceUnitMutation({
 *   variables: {
 *      set: // value for 'set'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateServiceUnitMutation(baseOptions?: Apollo.MutationHookOptions<UpdateServiceUnitMutation, UpdateServiceUnitMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateServiceUnitMutation, UpdateServiceUnitMutationVariables>(UpdateServiceUnitDocument, options);
      }
export type UpdateServiceUnitMutationHookResult = ReturnType<typeof useUpdateServiceUnitMutation>;
export type UpdateServiceUnitMutationResult = Apollo.MutationResult<UpdateServiceUnitMutation>;
export type UpdateServiceUnitMutationOptions = Apollo.BaseMutationOptions<UpdateServiceUnitMutation, UpdateServiceUnitMutationVariables>;