import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetProviderSignatureQueryVariables = Types.Exact<{
  providerId: Types.Scalars['uuid'];
}>;


export type GetProviderSignatureQuery = { __typename?: 'query_root', signature: Array<{ __typename?: 'signature', id: any, providerId?: any | null | undefined, createdAt: any, filePath: string, updatedAt: any, workspaceId: any, file?: { __typename?: 'FileOnlyURL', url: string } | null | undefined }> };


export const GetProviderSignatureDocument = gql`
    query getProviderSignature($providerId: uuid!) {
  signature(where: {providerId: {_eq: $providerId}}, order_by: {createdAt: desc}) {
    id
    providerId
    createdAt
    filePath
    updatedAt
    workspaceId
    file {
      url
    }
  }
}
    `;

/**
 * __useGetProviderSignatureQuery__
 *
 * To run a query within a React component, call `useGetProviderSignatureQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProviderSignatureQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProviderSignatureQuery({
 *   variables: {
 *      providerId: // value for 'providerId'
 *   },
 * });
 */
export function useGetProviderSignatureQuery(baseOptions: Apollo.QueryHookOptions<GetProviderSignatureQuery, GetProviderSignatureQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProviderSignatureQuery, GetProviderSignatureQueryVariables>(GetProviderSignatureDocument, options);
      }
export function useGetProviderSignatureLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProviderSignatureQuery, GetProviderSignatureQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProviderSignatureQuery, GetProviderSignatureQueryVariables>(GetProviderSignatureDocument, options);
        }
export type GetProviderSignatureQueryHookResult = ReturnType<typeof useGetProviderSignatureQuery>;
export type GetProviderSignatureLazyQueryHookResult = ReturnType<typeof useGetProviderSignatureLazyQuery>;
export type GetProviderSignatureQueryResult = Apollo.QueryResult<GetProviderSignatureQuery, GetProviderSignatureQueryVariables>;