import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type InsertPhotoMediaTagMutationVariables = Types.Exact<{
  photoMediaTag: Types.PhotoMediaTag_Insert_Input;
}>;


export type InsertPhotoMediaTagMutation = { __typename?: 'mutation_root', insert_photoMediaTag?: { __typename?: 'photoMediaTag_mutation_response', returning: Array<{ __typename?: 'photoMediaTag', id: any, mediaTag: { __typename?: 'mediaTag', id: any, title: string } }> } | null | undefined };


export const InsertPhotoMediaTagDocument = gql`
    mutation insertPhotoMediaTag($photoMediaTag: photoMediaTag_insert_input!) {
  insert_photoMediaTag(
    objects: [$photoMediaTag]
    on_conflict: {constraint: photoMediaTag_photoId_mediaTagId_key, update_columns: updatedAt}
  ) {
    returning {
      id
      mediaTag {
        id
        title
      }
    }
  }
}
    `;
export type InsertPhotoMediaTagMutationFn = Apollo.MutationFunction<InsertPhotoMediaTagMutation, InsertPhotoMediaTagMutationVariables>;

/**
 * __useInsertPhotoMediaTagMutation__
 *
 * To run a mutation, you first call `useInsertPhotoMediaTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertPhotoMediaTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertPhotoMediaTagMutation, { data, loading, error }] = useInsertPhotoMediaTagMutation({
 *   variables: {
 *      photoMediaTag: // value for 'photoMediaTag'
 *   },
 * });
 */
export function useInsertPhotoMediaTagMutation(baseOptions?: Apollo.MutationHookOptions<InsertPhotoMediaTagMutation, InsertPhotoMediaTagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertPhotoMediaTagMutation, InsertPhotoMediaTagMutationVariables>(InsertPhotoMediaTagDocument, options);
      }
export type InsertPhotoMediaTagMutationHookResult = ReturnType<typeof useInsertPhotoMediaTagMutation>;
export type InsertPhotoMediaTagMutationResult = Apollo.MutationResult<InsertPhotoMediaTagMutation>;
export type InsertPhotoMediaTagMutationOptions = Apollo.BaseMutationOptions<InsertPhotoMediaTagMutation, InsertPhotoMediaTagMutationVariables>;