import * as Types from '../../generated/graphql';

import { gql } from '@apollo/client';
export type ConsumableSubtypeFieldsFragment = { __typename?: 'consumableSubtype', id: any, name: string, createdAt: any, updatedAt: any };

export const ConsumableSubtypeFieldsFragmentDoc = gql`
    fragment ConsumableSubtypeFields on consumableSubtype {
  id
  name
  createdAt
  updatedAt
}
    `;