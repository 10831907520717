import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type UpdateLeadFormEntriesMutationVariables = Types.Exact<{
  set?: Types.Maybe<Types.LeadFormEntry_Set_Input>;
  where: Types.LeadFormEntry_Bool_Exp;
}>;


export type UpdateLeadFormEntriesMutation = { __typename?: 'mutation_root', update_leadFormEntry?: { __typename?: 'leadFormEntry_mutation_response', affected_rows: number } | null | undefined };


export const UpdateLeadFormEntriesDocument = gql`
    mutation UpdateLeadFormEntries($set: leadFormEntry_set_input, $where: leadFormEntry_bool_exp!) {
  update_leadFormEntry(_set: $set, where: $where) {
    affected_rows
  }
}
    `;
export type UpdateLeadFormEntriesMutationFn = Apollo.MutationFunction<UpdateLeadFormEntriesMutation, UpdateLeadFormEntriesMutationVariables>;

/**
 * __useUpdateLeadFormEntriesMutation__
 *
 * To run a mutation, you first call `useUpdateLeadFormEntriesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLeadFormEntriesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLeadFormEntriesMutation, { data, loading, error }] = useUpdateLeadFormEntriesMutation({
 *   variables: {
 *      set: // value for 'set'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateLeadFormEntriesMutation(baseOptions?: Apollo.MutationHookOptions<UpdateLeadFormEntriesMutation, UpdateLeadFormEntriesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateLeadFormEntriesMutation, UpdateLeadFormEntriesMutationVariables>(UpdateLeadFormEntriesDocument, options);
      }
export type UpdateLeadFormEntriesMutationHookResult = ReturnType<typeof useUpdateLeadFormEntriesMutation>;
export type UpdateLeadFormEntriesMutationResult = Apollo.MutationResult<UpdateLeadFormEntriesMutation>;
export type UpdateLeadFormEntriesMutationOptions = Apollo.BaseMutationOptions<UpdateLeadFormEntriesMutation, UpdateLeadFormEntriesMutationVariables>;