import { Box, Button, Flex, Icon, Text, useDisclosure } from '@chakra-ui/react';
import { ObjectOption } from '@webapp/types';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { DataTable, FormInput } from '@webapp/ui';
import { useLoadServices } from '@webapp/webapp/hooks';
import { Cell } from 'react-table';
import { HiTrash } from 'react-icons/hi';
import { RiAddBoxLine } from 'react-icons/ri';
import { ErrorMessage } from '@hookform/error-message';
import { Service } from '@webapp/graphql';
import { useEffect, useState } from 'react';
import { InvoiceFormValues } from './types';
import PriceCell from './PriceCell';
import PromptModal from '../PromptModal/PromptModal';

export function ItemForm() {
  const {
    isOpen: isCouponAlertOpen,
    onClose: onCouponAlertClose,
    onOpen: onCouponAlertOpen,
  } = useDisclosure();

  const {
    control,
    formState: { errors },
    getValues,
    setValue,
    watch,
  } = useFormContext<InvoiceFormValues>();

  const [providerId, setProviderId] = useState<string>(
    (getValues('provider.value') as string) || ''
  );

  useEffect(() => {
    const sub = watch((data, options) => {
      if (options.name === 'provider') {
        setProviderId(data.provider?.value as string);
      }
    });

    return () => sub.unsubscribe();
  }, [watch]);

  const location = watch('location');

  const { append, fields, remove } = useFieldArray<
    InvoiceFormValues,
    'services'
  >({
    control,
    name: 'services',
  });

  function getValue(id: string) {
    const index = fields.findIndex((field) => field.id === id);
    const name = `services.${index}`;
    return getValues(name as any);
  }

  if (!location) {
    return (
      <Box
        w={'full'}
        bg="red.50"
        borderColor="red.500"
        borderWidth={1}
        rounded="md"
        p={4}
        textColor="red.500"
        fontWeight={'bold'}
      >
        Please select a Location before adding Services.
      </Box>
    );
  }

  return (
    <Box width="100%">
      <DataTable
        columns={[
          {
            accessor: 'object',
            Cell: (originalRow: Cell<Record<'id', string>>) => {
              const index = fields.findIndex(
                (field) => field.id === originalRow.row.original.id
              );

              const name = `services.${index}`;

              const currentValue = getValue(originalRow.row.original.id);

              return (
                <FormInput
                  name={name}
                  placeholder="Add Service"
                  style={{
                    minWidth: '200px',
                  }}
                  selectProps={{
                    defaultOption: currentValue,
                    loadOptions: () =>
                      useLoadServices({
                        groupByCategory: true,
                        showProducts: true,
                        locationId: getValues('location')?.value,
                      }),
                    transform: (
                      option: ObjectOption<
                        Pick<
                          Service,
                          | 'allowGratuity'
                          | 'id'
                          | 'name'
                          | 'price'
                          | 'pricingModel'
                          | 'taxRate'
                          | 'serviceCategory'
                        >
                      >
                    ) => ({
                      id: originalRow.row.original.id,
                      label: option.object.name,
                      object: {
                        allowGratuity: option.object.allowGratuity,
                        name: option.object.name,
                        pricePerUnit: option.object.price,
                        pricingModel: option.object.pricingModel,
                        providerId,
                        quantity: 1,
                        serviceId: option.object.id,
                        taxRate: option.object.taxRate,
                      },
                      value: option.object.id,
                    }),
                  }}
                  shouldDisplayError={false}
                  type="async-select"
                />
              );
            },
            disableSortBy: true,
            defaultCanSort: false,
            Header: 'Products And Services',
            id: 'services.name',
          },
          {
            accessor: (originalRow: Record<'id', string>) => 'quantity',
            Cell: (originalRow: Cell<Record<'id', string>>) => {
              const index = fields.findIndex(
                (field) => field.id === originalRow.row.original.id
              );

              return (
                <FormInput
                  min={1}
                  name={`services[${index}][object][quantity]`}
                  type="number"
                />
              );
            },
            disableSortBy: true,
            defaultCanSort: false,
            Header: 'Quantity',
            id: 'services.quantity',
          },
          {
            accessor: (originalRow: Record<'id', string>) => 'pricePerUnit',
            Cell: (originalRow: Cell<Record<'id', string>>) => {
              const index = fields.findIndex(
                (field) => field.id === originalRow.row.original.id
              );

              return <PriceCell index={index} />;
            },
            disableSortBy: true,
            defaultCanSort: false,
            Header: 'Unit Price',
            id: 'services.price',
          },
          {
            accessor: 'id',
            Cell: (originalRow: Cell<Record<'id', string>>) => {
              const index = fields.findIndex(
                (el) => el.id === originalRow.row.original.id
              );

              return (
                <Flex justifyContent="end" width="100%">
                  <Controller
                    control={control}
                    name="appliedCoupons"
                    render={({ field: { value: couponValue } }) => (
                      <Button
                        colorScheme="red"
                        onClick={() => {
                          if (couponValue?.length) {
                            setValue('appliedCoupons', []);
                            onCouponAlertOpen();
                          }

                          remove(index);
                        }}
                        variant="ghost"
                      >
                        <Icon as={HiTrash} />
                      </Button>
                    )}
                  />
                </Flex>
              );
            },
            disableSortBy: true,
            defaultCanSort: false,
            Header: '',
            id: 'services',
            width: 15,
          },
        ]}
        customStyles={{
          borderColor: '#CFEBFF',
          cell: {
            color: '#6C6C72',
          },
          table: {
            borderRadius: '8px',
            minHeight: 'unset',
          },
          tableHeader: {
            background: '#EAFCFF',
            color: '#525257',
            textTransform: 'uppercase',
          },
        }}
        data={fields}
        emptyStateText="Please Add Products or Services"
        shouldDisplayPagination={false}
      />
      {errors.services && (
        <ErrorMessage
          errors={errors}
          name={'services'}
          render={(error) => (
            <Text
              className="input-error"
              color="red.500"
              margin="15px 0 0"
              _before={{
                display: 'inline',
                content: '"⚠ "',
              }}
            >
              {error.message ?? 'Incomplete Services'}
            </Text>
          )}
        />
      )}
      <Box>
        <PromptModal
          bodyText="Coupons have been reset since services have been modified. Please re-apply coupons."
          colorScheme="red"
          confirmText={'Confirm'}
          isOpen={isCouponAlertOpen}
          onClose={onCouponAlertClose}
          onConfirm={onCouponAlertClose}
          withCloseButton={false}
          withCancelButton={false}
        />
        <Controller
          control={control}
          name="appliedCoupons"
          render={({ field: { value: couponValue } }) => (
            <Button
              color="teal.500"
              justifyContent="start"
              leftIcon={<Icon as={RiAddBoxLine} color="teal.500" />}
              margin="15px 0 0"
              onClick={() => {
                if (couponValue?.length) {
                  setValue('appliedCoupons', []);
                  onCouponAlertOpen();
                }

                append({
                  label: '',
                  value: '',
                  object: {
                    name: '',
                    pricePerUnit: 0,
                    pricingModel: 'fixed-price',
                    serviceId: '',
                    quantity: 1,
                    taxRate: 0,
                  },
                });
              }}
              textAlign="left"
              variant="ghost"
              width="max-content"
            >
              Add New Service
            </Button>
          )}
        />
      </Box>
    </Box>
  );
}

export default ItemForm;
