import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './TextEditorInput.css';
import { useFormContext } from 'react-hook-form';
import { useEffect } from 'react';
import { debounce } from 'lodash';

interface TextEditorInputProps {
  name: string;
}

export function TextEditorInput({ name }: TextEditorInputProps) {
  const { getValues, register, setValue } = useFormContext();

  const initialValue = getValues(name);

  useEffect(() => {
    register(name);
  });

  const handleChange = debounce((content: string) => {
    setValue(name, content);
  }, 500);

  return (
    <div className="form-input__text_editor">
      <ReactQuill
        theme="snow"
        value={initialValue ?? ''}
        onChange={handleChange}
      />
    </div>
  );
}

export default TextEditorInput;
