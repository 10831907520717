import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetInvoiceGratuityForProviderQueryVariables = Types.Exact<{
  userId: Types.Scalars['uuid'];
  start: Types.Scalars['timestamptz'];
  end: Types.Scalars['timestamptz'];
}>;


export type GetInvoiceGratuityForProviderQuery = { __typename?: 'query_root', invoice_aggregate: { __typename?: 'invoice_aggregate', aggregate?: { __typename?: 'invoice_aggregate_fields', sum?: { __typename?: 'invoice_sum_fields', gratuity?: number | null | undefined } | null | undefined } | null | undefined }, invoice: Array<{ __typename?: 'invoice', id: any, gratuity: number, paidOn?: any | null | undefined, patient: { __typename?: 'patient', firstName: string, lastName: string }, provider?: { __typename?: 'provider', firstName: string, lastName: string } | null | undefined }> };


export const GetInvoiceGratuityForProviderDocument = gql`
    query GetInvoiceGratuityForProvider($userId: uuid!, $start: timestamptz!, $end: timestamptz!) {
  invoice_aggregate(
    where: {provider: {userId: {_eq: $userId}}, paidOn: {_gte: $start, _lte: $end}}
  ) {
    aggregate {
      sum {
        gratuity
      }
    }
  }
  invoice(
    where: {provider: {userId: {_eq: $userId}}, paidOn: {_gte: $start, _lte: $end}, gratuity: {_gt: 0}}
  ) {
    id
    gratuity
    patient {
      firstName
      lastName
    }
    provider {
      firstName
      lastName
    }
    paidOn
  }
}
    `;

/**
 * __useGetInvoiceGratuityForProviderQuery__
 *
 * To run a query within a React component, call `useGetInvoiceGratuityForProviderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvoiceGratuityForProviderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvoiceGratuityForProviderQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      start: // value for 'start'
 *      end: // value for 'end'
 *   },
 * });
 */
export function useGetInvoiceGratuityForProviderQuery(baseOptions: Apollo.QueryHookOptions<GetInvoiceGratuityForProviderQuery, GetInvoiceGratuityForProviderQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInvoiceGratuityForProviderQuery, GetInvoiceGratuityForProviderQueryVariables>(GetInvoiceGratuityForProviderDocument, options);
      }
export function useGetInvoiceGratuityForProviderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInvoiceGratuityForProviderQuery, GetInvoiceGratuityForProviderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInvoiceGratuityForProviderQuery, GetInvoiceGratuityForProviderQueryVariables>(GetInvoiceGratuityForProviderDocument, options);
        }
export type GetInvoiceGratuityForProviderQueryHookResult = ReturnType<typeof useGetInvoiceGratuityForProviderQuery>;
export type GetInvoiceGratuityForProviderLazyQueryHookResult = ReturnType<typeof useGetInvoiceGratuityForProviderLazyQuery>;
export type GetInvoiceGratuityForProviderQueryResult = Apollo.QueryResult<GetInvoiceGratuityForProviderQuery, GetInvoiceGratuityForProviderQueryVariables>;