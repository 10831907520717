import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type SearchServiceUnitsQueryVariables = Types.Exact<{
  searchValue: Types.Scalars['String'];
  limit?: Types.Maybe<Types.Scalars['Int']>;
}>;


export type SearchServiceUnitsQuery = { __typename?: 'query_root', serviceUnit: Array<{ __typename?: 'serviceUnit', id: any, name: string }> };


export const SearchServiceUnitsDocument = gql`
    query searchServiceUnits($searchValue: String!, $limit: Int) {
  serviceUnit(where: {name: {_ilike: $searchValue}}, order_by: {name: asc}) {
    id
    name
  }
}
    `;

/**
 * __useSearchServiceUnitsQuery__
 *
 * To run a query within a React component, call `useSearchServiceUnitsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchServiceUnitsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchServiceUnitsQuery({
 *   variables: {
 *      searchValue: // value for 'searchValue'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useSearchServiceUnitsQuery(baseOptions: Apollo.QueryHookOptions<SearchServiceUnitsQuery, SearchServiceUnitsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchServiceUnitsQuery, SearchServiceUnitsQueryVariables>(SearchServiceUnitsDocument, options);
      }
export function useSearchServiceUnitsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchServiceUnitsQuery, SearchServiceUnitsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchServiceUnitsQuery, SearchServiceUnitsQueryVariables>(SearchServiceUnitsDocument, options);
        }
export type SearchServiceUnitsQueryHookResult = ReturnType<typeof useSearchServiceUnitsQuery>;
export type SearchServiceUnitsLazyQueryHookResult = ReturnType<typeof useSearchServiceUnitsLazyQuery>;
export type SearchServiceUnitsQueryResult = Apollo.QueryResult<SearchServiceUnitsQuery, SearchServiceUnitsQueryVariables>;