import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type DeleteManyTasksMutationVariables = Types.Exact<{
  _in?: Types.Maybe<Array<Types.Scalars['uuid']> | Types.Scalars['uuid']>;
}>;


export type DeleteManyTasksMutation = { __typename?: 'mutation_root', delete_task?: { __typename?: 'task_mutation_response', returning: Array<{ __typename?: 'task', id: any }> } | null | undefined };


export const DeleteManyTasksDocument = gql`
    mutation deleteManyTasks($_in: [uuid!] = "") {
  delete_task(where: {id: {_in: $_in}}) {
    returning {
      id
    }
  }
}
    `;
export type DeleteManyTasksMutationFn = Apollo.MutationFunction<DeleteManyTasksMutation, DeleteManyTasksMutationVariables>;

/**
 * __useDeleteManyTasksMutation__
 *
 * To run a mutation, you first call `useDeleteManyTasksMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteManyTasksMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteManyTasksMutation, { data, loading, error }] = useDeleteManyTasksMutation({
 *   variables: {
 *      _in: // value for '_in'
 *   },
 * });
 */
export function useDeleteManyTasksMutation(baseOptions?: Apollo.MutationHookOptions<DeleteManyTasksMutation, DeleteManyTasksMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteManyTasksMutation, DeleteManyTasksMutationVariables>(DeleteManyTasksDocument, options);
      }
export type DeleteManyTasksMutationHookResult = ReturnType<typeof useDeleteManyTasksMutation>;
export type DeleteManyTasksMutationResult = Apollo.MutationResult<DeleteManyTasksMutation>;
export type DeleteManyTasksMutationOptions = Apollo.BaseMutationOptions<DeleteManyTasksMutation, DeleteManyTasksMutationVariables>;