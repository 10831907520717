import './take-photo.module.css';
import {
  Stack,
  HStack,
  Heading,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Flex,
  Image,
  IconButton,
  Box,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  useBreakpointValue,
  Center,
  Select,
} from '@chakra-ui/react';
import { useRef, useCallback, useState, useEffect } from 'react';
import { Photo, PhotoModel, useStores } from '@webapp/state-models';
import { CloseIcon } from '@chakra-ui/icons';
import { useUploadToS3 } from '@webapp/hooks';
import {
  StorageType,
  useGetMinimalPatientQuery,
  useInsertPhotoMutation,
} from '@webapp/graphql';
import { nanoid } from 'nanoid';
import dayjs from 'dayjs';
import toast from 'react-hot-toast';
import { Camera } from 'react-camera-pro';
import { FaCamera, FaSave, FaTrash } from 'react-icons/fa';
import { RiCameraSwitchFill } from 'react-icons/ri';
import {
  SelectPhotoOrTemplate,
  TakePhotoV2,
} from '@webapp/webapp/ui-composites';
import { getProxyPhotoUrl } from '@webapp/utils';

/* eslint-disable-next-line */
export interface TakePhotoProps {
  isOpen: boolean;
  onClose: () => void;
  beforePhoto?: Photo | null;
  shouldSelectOverlayPhoto: boolean;
  patientId?: string;
  appointmentId?: string;
}

export function TakePhoto({
  isOpen,
  onClose,
  beforePhoto,
  patientId,
  shouldSelectOverlayPhoto = false,
  appointmentId,
}: TakePhotoProps) {
  const aspectRatio = 3 / 2;

  const [numberOfCameras, setNumberOfCameras] = useState(0);
  const [devices, setDevices] = useState<MediaDeviceInfo[]>([]);
  const [activeDeviceId, setActiveDeviceId] = useState<string | undefined>(
    undefined
  );
  const isMobile = useBreakpointValue({ base: true, lg: false });
  const height = isMobile
    ? window.document.body.clientHeight - 200
    : window.document.body.clientHeight - 72;
  const width = height * aspectRatio;
  const camera = useRef(null);
  const [cameraFacingMode, setCameraFacingMode] = useState<
    'user' | 'environment'
  >('environment');

  const [capturedImages, setCapturedImages] = useState<string[]>([]);
  const [videoConstraints, setVideoConstraints] = useState({
    facingMode: 'environment',
    width: { min: width },
    height: { min: height },
    aspectRatio,
  });
  const [insertPhoto] = useInsertPhotoMutation({
    refetchQueries: ['listPhotos'],
  });
  const { data: patientData, loading: patientLoading } =
    useGetMinimalPatientQuery({
      variables: {
        id: patientId,
      },
    });

  const [loading, setLoading] = useState(false);
  const { workspace, appendToDraftPhotos } = useStores();
  const [previewImage, setPreviewImage] = useState<string | null>();
  const [overlayImage, setOverlayImage] = useState<string | null>(
    beforePhoto?.file?.url ?? null
  );

  const [opacity, setOpacity] = useState<number>(20);
  const webcamRef = useRef<any>(null);
  const { uploadToS3 } = useUploadToS3();

  async function saveImage(imageToSave: string) {
    try {
      const filePath = await uploadToS3({
        fileType: 'image/jpeg',
        fileContents: new File(
          [await (await fetch(imageToSave as string)).blob()],
          nanoid()
        ),
        filePath: nanoid(),
        onProgress: () => undefined,
        storageType: StorageType.Private,
      });
      const { data } = await insertPhoto({
        variables: {
          photo: {
            ...(beforePhoto?.patientId && {
              patientId: beforePhoto?.patientId,
            }),
            ...(patientId && { patientId }),
            ...(appointmentId && { appointmentId }),
            workspaceId: workspace?.id,
            filePath,
            mediaDate: dayjs().toISOString(),
          },
        },
      });
      toast.success('Photo saved');
      return data;
    } catch (err) {
      toast.error('Error saving photo');
      return null;
    }
  }

  async function saveImages() {
    setLoading(true);
    const promises = capturedImages.map((image) => saveImage(image));
    const savedPhotos = await Promise.all(promises);
    savedPhotos
      .filter((f) => f !== null)
      .forEach((photo) => {
        appendToDraftPhotos(
          PhotoModel.create(photo?.insert_photo?.returning[0])
        );
      });

    setLoading(false);
    onClose();
  }

  const capture = useCallback(() => {
    const imageSrc = camera.current.takePhoto();
    setCapturedImages([...capturedImages, imageSrc]);
  }, [webcamRef, capturedImages, capturedImages.length]);

  function flipCamera() {
    camera.current.switchCamera();
    setCameraFacingMode(
      cameraFacingMode === 'environment' ? 'user' : 'environment'
    );
  }

  function removePhotoFromCaptures(index: number) {
    setCapturedImages(capturedImages.filter((_, i) => i !== index));
  }

  useEffect(() => {
    (async () => {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const videoDevices = devices.filter((i) => i.kind == 'videoinput');
      setDevices(videoDevices);
    })();
  });

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={'full'}>
      <ModalOverlay />
      <ModalContent>
        <TakePhotoV2
          patientId={patientId ?? beforePhoto?.patientId}
          overlayImageUrl={beforePhoto?.file?.url}
          onImageCapture={saveImage}
          goBack={onClose}
          beginWithTemplateSelection={shouldSelectOverlayPhoto}
        />
      </ModalContent>
    </Modal>
  );
}

export default TakePhoto;
