import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { CustomQuestionFieldsFragmentDoc } from '../../fragments/customQuestionFields.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetCustomQuestionsQueryVariables = Types.Exact<{
  where?: Types.Maybe<Types.CustomQuestion_Bool_Exp>;
  limit?: Types.Maybe<Types.Scalars['Int']>;
  offset?: Types.Maybe<Types.Scalars['Int']>;
  orderBy?: Types.Maybe<Array<Types.CustomQuestion_Order_By> | Types.CustomQuestion_Order_By>;
}>;


export type GetCustomQuestionsQuery = { __typename?: 'query_root', customQuestion: Array<{ __typename?: 'customQuestion', id: any, label: string, body?: string | null | undefined, photoId?: any | null | undefined, categoryValueAndDescription?: { __typename?: 'customFormFieldCategory', value: string, description: string } | null | undefined, typeValueAndDescription: { __typename?: 'customFormFieldType', value: string, description: string }, options: Array<{ __typename?: 'customQuestionOption', value: string }>, photo?: { __typename?: 'photo', file?: { __typename?: 'FilePayload', url: string } | null | undefined } | null | undefined }> };


export const GetCustomQuestionsDocument = gql`
    query GetCustomQuestions($where: customQuestion_bool_exp, $limit: Int, $offset: Int, $orderBy: [customQuestion_order_by!]) {
  customQuestion(
    where: $where
    limit: $limit
    offset: $offset
    order_by: $orderBy
  ) {
    ...CustomQuestionFields
  }
}
    ${CustomQuestionFieldsFragmentDoc}`;

/**
 * __useGetCustomQuestionsQuery__
 *
 * To run a query within a React component, call `useGetCustomQuestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomQuestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomQuestionsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetCustomQuestionsQuery(baseOptions?: Apollo.QueryHookOptions<GetCustomQuestionsQuery, GetCustomQuestionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCustomQuestionsQuery, GetCustomQuestionsQueryVariables>(GetCustomQuestionsDocument, options);
      }
export function useGetCustomQuestionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCustomQuestionsQuery, GetCustomQuestionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCustomQuestionsQuery, GetCustomQuestionsQueryVariables>(GetCustomQuestionsDocument, options);
        }
export type GetCustomQuestionsQueryHookResult = ReturnType<typeof useGetCustomQuestionsQuery>;
export type GetCustomQuestionsLazyQueryHookResult = ReturnType<typeof useGetCustomQuestionsLazyQuery>;
export type GetCustomQuestionsQueryResult = Apollo.QueryResult<GetCustomQuestionsQuery, GetCustomQuestionsQueryVariables>;