import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetEmailTemplateTableQueryVariables = Types.Exact<{
  where: Types.EmailTemplate_Bool_Exp;
}>;


export type GetEmailTemplateTableQuery = { __typename?: 'query_root', emailTemplate: Array<{ __typename?: 'emailTemplate', id: any, emailName?: string | null | undefined, subjectLine?: string | null | undefined, lastSentDate?: any | null | undefined, isActive: boolean, deliveries: number, opens: number, clicks: number, targetAudience?: { __typename?: 'audience', type: string } | null | undefined, metrics?: { __typename?: 'emailCampaignMetrics', deliveries?: any | null | undefined, opens?: any | null | undefined, clicks?: any | null | undefined } | null | undefined, marketingTags: Array<{ __typename?: 'marketingTagEmail', marketingTag: { __typename?: 'marketingTag', id: any, title: string } }> }> };


export const GetEmailTemplateTableDocument = gql`
    query getEmailTemplateTable($where: emailTemplate_bool_exp!) {
  emailTemplate(where: $where) {
    id
    emailName
    subjectLine
    lastSentDate
    targetAudience {
      type
    }
    metrics {
      deliveries
      opens
      clicks
    }
    isActive
    marketingTags {
      marketingTag {
        id
        title
      }
    }
    deliveries
    opens
    clicks
  }
}
    `;

/**
 * __useGetEmailTemplateTableQuery__
 *
 * To run a query within a React component, call `useGetEmailTemplateTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmailTemplateTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmailTemplateTableQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetEmailTemplateTableQuery(baseOptions: Apollo.QueryHookOptions<GetEmailTemplateTableQuery, GetEmailTemplateTableQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEmailTemplateTableQuery, GetEmailTemplateTableQueryVariables>(GetEmailTemplateTableDocument, options);
      }
export function useGetEmailTemplateTableLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEmailTemplateTableQuery, GetEmailTemplateTableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEmailTemplateTableQuery, GetEmailTemplateTableQueryVariables>(GetEmailTemplateTableDocument, options);
        }
export type GetEmailTemplateTableQueryHookResult = ReturnType<typeof useGetEmailTemplateTableQuery>;
export type GetEmailTemplateTableLazyQueryHookResult = ReturnType<typeof useGetEmailTemplateTableLazyQuery>;
export type GetEmailTemplateTableQueryResult = Apollo.QueryResult<GetEmailTemplateTableQuery, GetEmailTemplateTableQueryVariables>;