import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type InsertCompensationStrategyMutationVariables = Types.Exact<{
  object: Types.CompensationStrategy_Insert_Input;
}>;


export type InsertCompensationStrategyMutation = { __typename?: 'mutation_root', insert_compensationStrategy_one?: { __typename?: 'compensationStrategy', id: any } | null | undefined };


export const InsertCompensationStrategyDocument = gql`
    mutation InsertCompensationStrategy($object: compensationStrategy_insert_input!) {
  insert_compensationStrategy_one(object: $object) {
    id
  }
}
    `;
export type InsertCompensationStrategyMutationFn = Apollo.MutationFunction<InsertCompensationStrategyMutation, InsertCompensationStrategyMutationVariables>;

/**
 * __useInsertCompensationStrategyMutation__
 *
 * To run a mutation, you first call `useInsertCompensationStrategyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertCompensationStrategyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertCompensationStrategyMutation, { data, loading, error }] = useInsertCompensationStrategyMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertCompensationStrategyMutation(baseOptions?: Apollo.MutationHookOptions<InsertCompensationStrategyMutation, InsertCompensationStrategyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertCompensationStrategyMutation, InsertCompensationStrategyMutationVariables>(InsertCompensationStrategyDocument, options);
      }
export type InsertCompensationStrategyMutationHookResult = ReturnType<typeof useInsertCompensationStrategyMutation>;
export type InsertCompensationStrategyMutationResult = Apollo.MutationResult<InsertCompensationStrategyMutation>;
export type InsertCompensationStrategyMutationOptions = Apollo.BaseMutationOptions<InsertCompensationStrategyMutation, InsertCompensationStrategyMutationVariables>;