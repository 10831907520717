import { FormLabel, Grid, Text } from '@chakra-ui/react';

import {
  SelectOption,
  FormInputProps,
  SelectFormInputProps,
} from '@webapp/types';

import Select, { ActionMeta, SingleValue } from 'react-select';
import { Controller, useFormContext } from 'react-hook-form';
import { useEffect, useMemo, useState } from 'react';
import { ErrorMessage } from '@hookform/error-message';

import { DEFAULT_LABEL_STYLE } from './constants';

export default function SearchableSelectInput({
  containerGridTemplate = '100px 1fr',
  isEditable = true,
  disabled = false,
  id,
  label,
  labelAlign,
  labelPosition,
  labelStyle = DEFAULT_LABEL_STYLE,
  name,
  defaultOption = null,
  placeholder,
  withOptions,
  shouldDisplayError = true,
}: Omit<FormInputProps, 'selectProps'> & SelectFormInputProps) {
  const [selectedOption, setSelectedOption] = useState<SelectOption | null>(
    defaultOption ?? null
  );
  const {
    control,
    formState: { errors },
    register,
    setValue,
  } = useFormContext();

  useEffect(() => {
    register(name);
  });

  const containerColumns = useMemo(() => {
    if (label) {
      if (labelPosition === 'left') {
        return containerGridTemplate;
      }
    }

    return '1fr';
  }, [label, labelPosition]);

  const handleChange = async (
    option: SingleValue<SelectOption>,
    actionMeta: ActionMeta<SelectOption>
  ) => {
    switch (actionMeta.action) {
      case 'pop-value':
      case 'remove-value': {
        if (actionMeta.removedValue?.value) {
          setValue(name, null);
          setSelectedOption(null);
        }

        break;
      }

      case 'clear': {
        setValue(name, null);
        setSelectedOption(null);

        break;
      }

      default: {
        setSelectedOption(option as SelectOption);
        setValue(name, option?.value);

        break;
      }
    }
  };

  return (
    <Grid
      alignItems={labelAlign}
      className="input-outer"
      gridAutoRows="max-content"
      gridRowGap="6px"
      gridTemplateColumns={containerColumns}
      minWidth="200px"
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
      }}
    >
      <Grid gridTemplateColumns="1fr max-content">
        <FormLabel
          className="input-label"
          htmlFor={id ?? name}
          margin="0 0 0 0"
          {...labelStyle}
        >
          {label}
        </FormLabel>
      </Grid>

      <Grid className="input-inner" gridRowGap="8px">
        <Controller
          control={control}
          name={name}
          render={({ field: { onBlur, ref, value } }) => {
            if (!isEditable) {
              return <Text>{value?.label}</Text>;
            }

            return (
              <Select
                defaultValue={defaultOption}
                isDisabled={disabled}
                isSearchable={true}
                name={name}
                onBlur={onBlur}
                onChange={handleChange}
                placeholder={placeholder}
                ref={ref}
                value={selectedOption || value}
                options={withOptions}
              />
            );
          }}
        />
        {shouldDisplayError && (
          <ErrorMessage
            errors={errors}
            name={name}
            render={({ message }) => (
              <Text
                className="input-error"
                color="red.500"
                _before={{
                  display: 'inline',
                  content: '"⚠ "',
                }}
              >
                {/* TODO: Errors component does not work well with complex fields */}
                {message ?? 'Invalid'}
              </Text>
            )}
          />
        )}
      </Grid>
    </Grid>
  );
}
