import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type WarmTranscriberMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type WarmTranscriberMutation = { __typename?: 'mutation_root', warmTranscriber?: { __typename?: 'TranscribeAudioOutput', success?: boolean | null | undefined, data?: string | null | undefined } | null | undefined };


export const WarmTranscriberDocument = gql`
    mutation WarmTranscriber {
  warmTranscriber {
    success
    data
  }
}
    `;
export type WarmTranscriberMutationFn = Apollo.MutationFunction<WarmTranscriberMutation, WarmTranscriberMutationVariables>;

/**
 * __useWarmTranscriberMutation__
 *
 * To run a mutation, you first call `useWarmTranscriberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWarmTranscriberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [warmTranscriberMutation, { data, loading, error }] = useWarmTranscriberMutation({
 *   variables: {
 *   },
 * });
 */
export function useWarmTranscriberMutation(baseOptions?: Apollo.MutationHookOptions<WarmTranscriberMutation, WarmTranscriberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<WarmTranscriberMutation, WarmTranscriberMutationVariables>(WarmTranscriberDocument, options);
      }
export type WarmTranscriberMutationHookResult = ReturnType<typeof useWarmTranscriberMutation>;
export type WarmTranscriberMutationResult = Apollo.MutationResult<WarmTranscriberMutation>;
export type WarmTranscriberMutationOptions = Apollo.BaseMutationOptions<WarmTranscriberMutation, WarmTranscriberMutationVariables>;