import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type PatientAggregateQueryVariables = Types.Exact<{
  where?: Types.Maybe<Types.Patient_Bool_Exp>;
}>;


export type PatientAggregateQuery = { __typename?: 'query_root', patient_aggregate: { __typename?: 'patient_aggregate', aggregate?: { __typename?: 'patient_aggregate_fields', count: number } | null | undefined } };


export const PatientAggregateDocument = gql`
    query PatientAggregate($where: patient_bool_exp) {
  patient_aggregate(where: $where) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __usePatientAggregateQuery__
 *
 * To run a query within a React component, call `usePatientAggregateQuery` and pass it any options that fit your needs.
 * When your component renders, `usePatientAggregateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePatientAggregateQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function usePatientAggregateQuery(baseOptions?: Apollo.QueryHookOptions<PatientAggregateQuery, PatientAggregateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PatientAggregateQuery, PatientAggregateQueryVariables>(PatientAggregateDocument, options);
      }
export function usePatientAggregateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PatientAggregateQuery, PatientAggregateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PatientAggregateQuery, PatientAggregateQueryVariables>(PatientAggregateDocument, options);
        }
export type PatientAggregateQueryHookResult = ReturnType<typeof usePatientAggregateQuery>;
export type PatientAggregateLazyQueryHookResult = ReturnType<typeof usePatientAggregateLazyQuery>;
export type PatientAggregateQueryResult = Apollo.QueryResult<PatientAggregateQuery, PatientAggregateQueryVariables>;