import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Stack, Text } from '@chakra-ui/react';

export const MergeTagList = forwardRef((props, ref) => {
  const propsAny = props as any;
  const { editor, mergeTagContext, creatingTemplate } = propsAny;
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [hasKeyed, setHasKeyed] = useState(false);

  const selectItem = (index: any) => {
    const item = propsAny.items[index];

    if (item) {
      editor.commands.deleteRange(propsAny.range);
      if (!creatingTemplate) {
        editor.commands.insertContent(mergeTagContext?.[item] ?? 'No value');
      } else {
        editor.commands.insertContent(`{{${item}}}`);
      }
    }
  };

  const upHandler = () => {
    setSelectedIndex(
      (selectedIndex + propsAny.items.length - 1) % propsAny.items.length
    );
    setHasKeyed(true);
  };

  const downHandler = () => {
    setSelectedIndex((selectedIndex + 1) % propsAny.items.length);
    setHasKeyed(true);
  };

  const enterHandler = () => {
    selectItem(selectedIndex);
  };

  useEffect(() => setSelectedIndex(0), [propsAny.items]);

  useImperativeHandle(ref, () => ({
    onKeyDown: ({ event }: { event: any }) => {
      if (event.key === 'ArrowUp') {
        upHandler();
        return true;
      }

      if (event.key === 'ArrowDown') {
        downHandler();
        return true;
      }

      if (event.key === 'Enter') {
        enterHandler();
        return true;
      }

      return false;
    },
  }));

  return (
    <Stack shadow="lg" rounded="md" bg="white" p={2} maxH={300} overflow="auto">
      {propsAny.items.length ? (
        <Stack>
          {propsAny.items.map((item: any, index: number) => (
            <Stack
              key={index}
              onClick={() => selectItem(index)}
              backgroundColor={
                selectedIndex === index && hasKeyed ? 'teal.50' : 'gray.50'
              }
              rounded={'md'}
              p={2}
              justifyContent="space-evenly"
              width="300px"
              borderColor={
                selectedIndex === index && hasKeyed ? 'teal.500' : 'gray.50'
              }
              borderWidth={1}
              cursor="pointer"
              _hover={{
                backgroundColor: 'teal.50',
                border: '1px solid teal',
              }}
            >
              <Text color="teal.500" fontSize="md" fontWeight="bold">
                {item}
              </Text>
              <Text color="gray.600" fontSize="sm">
                {item}
              </Text>
            </Stack>
          ))}
        </Stack>
      ) : (
        <div className="item">No result</div>
      )}
    </Stack>
  );
});

export default MergeTagList;
