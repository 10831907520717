import { extendTheme } from '@chakra-ui/react';
import { StyleConfig } from '@webapp/types';

const RadioConfig: Record<string, StyleConfig> = {
  Radio: {
    parts: ['label', 'control'],
    sizes: {
      lg: {
        control: {
          _checked: {
            background: 'teal.500',
          },
        },
        label: {
          color: 'text.100',
          fontSize: '16px',
          letterSpacing: '0.01em',
        },
      },
    },
  },
};

const RadioTheme = extendTheme({
  components: RadioConfig,
});

export default RadioTheme;
