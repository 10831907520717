import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { PatientAddressFieldsFragmentDoc } from '../../fragments/patientAddressFields.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type ListPatientAddressQueryVariables = Types.Exact<{
  where?: Types.Maybe<Types.PatientAddress_Bool_Exp>;
}>;


export type ListPatientAddressQuery = { __typename?: 'query_root', patientAddress: Array<{ __typename?: 'patientAddress', id: any, isDefault: boolean, type: string, status: string, address: { __typename?: 'address', addressLine1: string, addressLine2?: string | null | undefined, city: string, id: any, state: string, zipCode: string } }> };


export const ListPatientAddressDocument = gql`
    query ListPatientAddress($where: patientAddress_bool_exp) {
  patientAddress(where: $where) {
    ...PatientAddressFields
  }
}
    ${PatientAddressFieldsFragmentDoc}`;

/**
 * __useListPatientAddressQuery__
 *
 * To run a query within a React component, call `useListPatientAddressQuery` and pass it any options that fit your needs.
 * When your component renders, `useListPatientAddressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListPatientAddressQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useListPatientAddressQuery(baseOptions?: Apollo.QueryHookOptions<ListPatientAddressQuery, ListPatientAddressQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListPatientAddressQuery, ListPatientAddressQueryVariables>(ListPatientAddressDocument, options);
      }
export function useListPatientAddressLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListPatientAddressQuery, ListPatientAddressQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListPatientAddressQuery, ListPatientAddressQueryVariables>(ListPatientAddressDocument, options);
        }
export type ListPatientAddressQueryHookResult = ReturnType<typeof useListPatientAddressQuery>;
export type ListPatientAddressLazyQueryHookResult = ReturnType<typeof useListPatientAddressLazyQuery>;
export type ListPatientAddressQueryResult = Apollo.QueryResult<ListPatientAddressQuery, ListPatientAddressQueryVariables>;