import * as Types from '../../generated/graphql';

import { gql } from '@apollo/client';
export type TaskFieldsFragment = { __typename?: 'task', description?: string | null | undefined, dueDate: any, id: any, status?: string | null | undefined, comments: Array<{ __typename?: 'taskComment', createdAt: any, comment: string, id: any, updatedAt: any, author: { __typename?: 'user', firstName: string, id: any, lastName: string, title?: string | null | undefined } }>, provider?: { __typename?: 'provider', firstName: string, id: any, lastName: string, profilePicture?: string | null | undefined, title?: string | null | undefined } | null | undefined, patient?: { __typename?: 'patient', firstName: string, id: any, lastName: string, profilePicture?: { __typename?: 'photo', file?: { __typename?: 'FilePayload', thumbnailUrl?: string | null | undefined } | null | undefined } | null | undefined } | null | undefined, user?: { __typename?: 'user', firstName: string, id: any, lastName: string, profilePicture?: string | null | undefined, title?: string | null | undefined } | null | undefined };

export const TaskFieldsFragmentDoc = gql`
    fragment TaskFields on task {
  comments {
    author {
      firstName
      id
      lastName
      title
    }
    createdAt
    comment
    id
    updatedAt
  }
  description
  dueDate
  id
  provider {
    firstName
    id
    lastName
    profilePicture
    title
  }
  patient {
    firstName
    id
    lastName
    profilePicture {
      file {
        thumbnailUrl
      }
    }
  }
  status
  user {
    firstName
    id
    lastName
    profilePicture
    title
  }
}
    `;