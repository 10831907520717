import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { SocialMediaAccountPostFieldsFragmentDoc } from '../../fragments/socialMediaAccountPostFields.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type InsertSocialMediaAccountPostMutationVariables = Types.Exact<{
  objects: Array<Types.SocialMediaAccountPost_Insert_Input> | Types.SocialMediaAccountPost_Insert_Input;
}>;


export type InsertSocialMediaAccountPostMutation = { __typename?: 'mutation_root', insert_socialMediaAccountPost?: { __typename?: 'socialMediaAccountPost_mutation_response', returning: Array<{ __typename?: 'socialMediaAccountPost', id: any, likeCount?: number | null | undefined, commentCount?: number | null | undefined, socialMediaAccount: { __typename?: 'socialMediaAccount', id: any, name: string, platform: Types.SocialMediaPlatform_Enum, profilePicture?: string | null | undefined }, socialMediaPost: { __typename?: 'socialMediaPost', id: any, title: string, caption?: string | null | undefined, isScheduled: boolean, intendedPostAt: any, privatePhoto?: { __typename?: 'photo', id: any, workspaceId: any, patientId?: any | null | undefined, mediaDate?: any | null | undefined, mediaType?: string | null | undefined, mimeType?: string | null | undefined, hasThumbnail?: boolean | null | undefined, isSharedWithPatient: boolean, partialMediaConsent?: boolean | null | undefined, fullMediaConsent?: boolean | null | undefined, patient?: { __typename?: 'patient', firstName: string, id: any, lastName: string } | null | undefined, file?: { __typename?: 'FilePayload', filePath: string, url: string, thumbnailUrl?: string | null | undefined } | null | undefined, photoMediaTags: Array<{ __typename?: 'photoMediaTag', id: any, mediaTag: { __typename?: 'mediaTag', id: any, title: string } }>, uploader?: { __typename?: 'user', firstName: string, id: any, lastName: string } | null | undefined } | null | undefined, socialMediaAccountPosts: Array<{ __typename?: 'socialMediaAccountPost', status: Types.PostStatus_Enum, likeCount?: number | null | undefined, commentCount?: number | null | undefined, socialMediaAccount: { __typename?: 'socialMediaAccount', id: any, name: string, profilePicture?: string | null | undefined, platform: Types.SocialMediaPlatform_Enum } }> } }> } | null | undefined };


export const InsertSocialMediaAccountPostDocument = gql`
    mutation insertSocialMediaAccountPost($objects: [socialMediaAccountPost_insert_input!]!) {
  insert_socialMediaAccountPost(
    on_conflict: {constraint: socialMediaPostPlatform_socialMediaPostId_socialMediaAccountId_, update_columns: [updated_at]}
    objects: $objects
  ) {
    returning {
      ...SocialMediaAccountPostFields
    }
  }
}
    ${SocialMediaAccountPostFieldsFragmentDoc}`;
export type InsertSocialMediaAccountPostMutationFn = Apollo.MutationFunction<InsertSocialMediaAccountPostMutation, InsertSocialMediaAccountPostMutationVariables>;

/**
 * __useInsertSocialMediaAccountPostMutation__
 *
 * To run a mutation, you first call `useInsertSocialMediaAccountPostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertSocialMediaAccountPostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertSocialMediaAccountPostMutation, { data, loading, error }] = useInsertSocialMediaAccountPostMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useInsertSocialMediaAccountPostMutation(baseOptions?: Apollo.MutationHookOptions<InsertSocialMediaAccountPostMutation, InsertSocialMediaAccountPostMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertSocialMediaAccountPostMutation, InsertSocialMediaAccountPostMutationVariables>(InsertSocialMediaAccountPostDocument, options);
      }
export type InsertSocialMediaAccountPostMutationHookResult = ReturnType<typeof useInsertSocialMediaAccountPostMutation>;
export type InsertSocialMediaAccountPostMutationResult = Apollo.MutationResult<InsertSocialMediaAccountPostMutation>;
export type InsertSocialMediaAccountPostMutationOptions = Apollo.BaseMutationOptions<InsertSocialMediaAccountPostMutation, InsertSocialMediaAccountPostMutationVariables>;