/* eslint-disable camelcase */
import {
  Order_By,
  useListPhotosQuery,
  useSubscribeNewPhotosSubscription,
} from '@webapp/graphql';
import { PhotoGridPhoto } from '@webapp/types';
import { useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { useLocation, useParams } from 'react-router-dom';

export interface UsePaginatedPhotosProps {
  patientId?: string;
  pageSize?: number;
}

export function usePaginatedPhotos({
  patientId: patientIdFromProps,
  pageSize: defaultPageSize = 20,
}: UsePaginatedPhotosProps = {}) {
  const [photoCount, setPhotoCount] = useState<number | undefined>(undefined);
  const [selectedPhoto, selectPhoto] = useState<PhotoGridPhoto | null>(null);

  const params = useParams();
  const location = useLocation();

  const patientId = patientIdFromProps ?? params.patientId;

  const searchParams = new URLSearchParams(location.search);

  const page = parseInt(searchParams.get('page') ?? '1', 10);

  const pageSizeFromQuery = searchParams.get('pageSize');

  const pageSize = pageSizeFromQuery
    ? parseInt(pageSizeFromQuery, 10)
    : defaultPageSize;

  const search = searchParams.get('search');
  const order = searchParams.get('order');

  const timelineOrder = order === 'createdAt:asc' ? 'Asc' : 'Desc';

  const whereQuery = useMemo(
    () => ({
      ...(search && {
        photoMediaTags: {
          mediaTag: {
            title: {
              _ilike: `%${search}%`,
            },
          },
        },
      }),
      patientId: {
        _eq: patientId,
      },
    }),
    [patientId, search]
  );

  // This acts as a time cursor that will let us know if new photos come in
  const createdAtMarker = useMemo(() => new Date().toISOString(), []);

  const { data: newPhotoData } = useSubscribeNewPhotosSubscription({
    variables: {
      createdAt: createdAtMarker,
      where: {
        patientId: {
          _eq: patientId,
        },
      },
    },
  });

  const { data, loading, refetch } = useListPhotosQuery({
    fetchPolicy: 'network-only',
    onCompleted: ({ photo_aggregate: aggregate }) => {
      setPhotoCount(aggregate?.aggregate?.count ?? 0);
    },
    onError: (e) => {
      toast.error(e.message);
    },
    variables: {
      ...(Boolean(timelineOrder) && {
        limit: pageSize,
        offset: (page - 1) * pageSize,
        orderBy: [
          {
            createdAt: Order_By[timelineOrder],
          },
        ],
      }),
      where: whereQuery,
    },
  });

  useEffect(() => {
    if (newPhotoData?.photo_stream && newPhotoData?.photo_stream?.length > 0) {
      refetch();
    }
  }, [newPhotoData]);

  const photos = data?.photo || [];

  return {
    count: photoCount,
    loading,
    photos,
    refetch,
    selectPhoto,
    selectedPhoto,
  };
}

export default usePaginatedPhotos;
