import * as Types from '../../generated/graphql';

import { gql } from '@apollo/client';
import { AddressFieldsFragmentDoc } from './addressFields.fragment.generated';
export type SubscribablePatientFieldsFragment = { __typename?: 'patient', dob?: string | null | undefined, id: any, firstName: string, gender?: string | null | undefined, language: string, lastName: string, email?: string | null | undefined, phoneNumber?: string | null | undefined, status: string, patientAddresses: Array<{ __typename?: 'patientAddress', id: any, isDefault: boolean, type: string, status: string, address: { __typename?: 'address', addressLine1: string, addressLine2?: string | null | undefined, city: string, id: any, state: string, zipCode: string } }>, lastAppointment: Array<{ __typename?: 'appointment', id: any, timerange?: any | null | undefined, appointmentServices: Array<{ __typename?: 'appointmentService', service: { __typename?: 'service', name: string } }> }>, patientWorkspaces: Array<{ __typename?: 'patientWorkspace', status?: string | null | undefined, createdAt: any, updatedAt: any, firstName?: string | null | undefined, lastName?: string | null | undefined, email?: string | null | undefined, phoneNumber?: string | null | undefined, referringPatient?: { __typename?: 'patient', id: any, firstName: string, lastName: string } | null | undefined, primaryProvider?: { __typename?: 'provider', id: any, firstName: string, lastName: string } | null | undefined, primaryLocation?: { __typename?: 'location', id: any, name: string } | null | undefined }> };

export const SubscribablePatientFieldsFragmentDoc = gql`
    fragment SubscribablePatientFields on patient {
  dob
  id
  firstName
  gender
  language
  lastName
  email
  patientAddresses {
    address {
      ...AddressFields
    }
    id
    isDefault
    type
    status
  }
  phoneNumber
  status
  lastAppointment: appointments(limit: 1, order_by: {timerange: desc}) {
    id
    timerange
    appointmentServices {
      service {
        name
      }
    }
  }
  patientWorkspaces {
    status
    createdAt
    updatedAt
    firstName
    lastName
    email
    phoneNumber
    referringPatient {
      id
      firstName
      lastName
    }
    primaryProvider {
      id
      firstName
      lastName
    }
    primaryLocation {
      id
      name
    }
  }
}
    ${AddressFieldsFragmentDoc}`;