import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type DeletePatientAddressByPkMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
}>;


export type DeletePatientAddressByPkMutation = { __typename?: 'mutation_root', delete_patientAddress_by_pk?: { __typename?: 'patientAddress', id: any } | null | undefined };


export const DeletePatientAddressByPkDocument = gql`
    mutation DeletePatientAddressByPk($id: uuid!) {
  delete_patientAddress_by_pk(id: $id) {
    id
  }
}
    `;
export type DeletePatientAddressByPkMutationFn = Apollo.MutationFunction<DeletePatientAddressByPkMutation, DeletePatientAddressByPkMutationVariables>;

/**
 * __useDeletePatientAddressByPkMutation__
 *
 * To run a mutation, you first call `useDeletePatientAddressByPkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePatientAddressByPkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePatientAddressByPkMutation, { data, loading, error }] = useDeletePatientAddressByPkMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePatientAddressByPkMutation(baseOptions?: Apollo.MutationHookOptions<DeletePatientAddressByPkMutation, DeletePatientAddressByPkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePatientAddressByPkMutation, DeletePatientAddressByPkMutationVariables>(DeletePatientAddressByPkDocument, options);
      }
export type DeletePatientAddressByPkMutationHookResult = ReturnType<typeof useDeletePatientAddressByPkMutation>;
export type DeletePatientAddressByPkMutationResult = Apollo.MutationResult<DeletePatientAddressByPkMutation>;
export type DeletePatientAddressByPkMutationOptions = Apollo.BaseMutationOptions<DeletePatientAddressByPkMutation, DeletePatientAddressByPkMutationVariables>;