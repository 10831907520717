import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type SendInvoicePaymentLinkMutationVariables = Types.Exact<{
  invoiceId: Types.Scalars['uuid'];
  channel?: Types.Maybe<Types.Scalars['String']>;
}>;


export type SendInvoicePaymentLinkMutation = { __typename?: 'mutation_root', sendInvoicePaymentLink?: { __typename?: 'SendPaymentInvoiceLinkOutput', success: boolean, paymentLink: string } | null | undefined };


export const SendInvoicePaymentLinkDocument = gql`
    mutation SendInvoicePaymentLink($invoiceId: uuid!, $channel: String) {
  sendInvoicePaymentLink(invoiceId: $invoiceId, channel: $channel) {
    success
    paymentLink
  }
}
    `;
export type SendInvoicePaymentLinkMutationFn = Apollo.MutationFunction<SendInvoicePaymentLinkMutation, SendInvoicePaymentLinkMutationVariables>;

/**
 * __useSendInvoicePaymentLinkMutation__
 *
 * To run a mutation, you first call `useSendInvoicePaymentLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendInvoicePaymentLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendInvoicePaymentLinkMutation, { data, loading, error }] = useSendInvoicePaymentLinkMutation({
 *   variables: {
 *      invoiceId: // value for 'invoiceId'
 *      channel: // value for 'channel'
 *   },
 * });
 */
export function useSendInvoicePaymentLinkMutation(baseOptions?: Apollo.MutationHookOptions<SendInvoicePaymentLinkMutation, SendInvoicePaymentLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendInvoicePaymentLinkMutation, SendInvoicePaymentLinkMutationVariables>(SendInvoicePaymentLinkDocument, options);
      }
export type SendInvoicePaymentLinkMutationHookResult = ReturnType<typeof useSendInvoicePaymentLinkMutation>;
export type SendInvoicePaymentLinkMutationResult = Apollo.MutationResult<SendInvoicePaymentLinkMutation>;
export type SendInvoicePaymentLinkMutationOptions = Apollo.BaseMutationOptions<SendInvoicePaymentLinkMutation, SendInvoicePaymentLinkMutationVariables>;