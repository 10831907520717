import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetNylasAuthUrlMutationVariables = Types.Exact<{
  syncOption: Types.Scalars['String'];
  userWorkspaceId: Types.Scalars['String'];
}>;


export type GetNylasAuthUrlMutation = { __typename?: 'mutation_root', getNylasAuthUrl: { __typename?: 'NylasAuthUrl', url: string } };


export const GetNylasAuthUrlDocument = gql`
    mutation GetNylasAuthUrl($syncOption: String!, $userWorkspaceId: String!) {
  getNylasAuthUrl(syncOption: $syncOption, userWorkspaceId: $userWorkspaceId) {
    url
  }
}
    `;
export type GetNylasAuthUrlMutationFn = Apollo.MutationFunction<GetNylasAuthUrlMutation, GetNylasAuthUrlMutationVariables>;

/**
 * __useGetNylasAuthUrlMutation__
 *
 * To run a mutation, you first call `useGetNylasAuthUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetNylasAuthUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getNylasAuthUrlMutation, { data, loading, error }] = useGetNylasAuthUrlMutation({
 *   variables: {
 *      syncOption: // value for 'syncOption'
 *      userWorkspaceId: // value for 'userWorkspaceId'
 *   },
 * });
 */
export function useGetNylasAuthUrlMutation(baseOptions?: Apollo.MutationHookOptions<GetNylasAuthUrlMutation, GetNylasAuthUrlMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GetNylasAuthUrlMutation, GetNylasAuthUrlMutationVariables>(GetNylasAuthUrlDocument, options);
      }
export type GetNylasAuthUrlMutationHookResult = ReturnType<typeof useGetNylasAuthUrlMutation>;
export type GetNylasAuthUrlMutationResult = Apollo.MutationResult<GetNylasAuthUrlMutation>;
export type GetNylasAuthUrlMutationOptions = Apollo.BaseMutationOptions<GetNylasAuthUrlMutation, GetNylasAuthUrlMutationVariables>;