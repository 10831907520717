import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type DeleteCustomFormFieldResponseMutationVariables = Types.Exact<{
  where: Types.CustomFormFieldResponse_Bool_Exp;
}>;


export type DeleteCustomFormFieldResponseMutation = { __typename?: 'mutation_root', delete_customFormFieldResponse?: { __typename?: 'customFormFieldResponse_mutation_response', returning: Array<{ __typename?: 'customFormFieldResponse', id: any }> } | null | undefined };


export const DeleteCustomFormFieldResponseDocument = gql`
    mutation deleteCustomFormFieldResponse($where: customFormFieldResponse_bool_exp!) {
  delete_customFormFieldResponse(where: $where) {
    returning {
      id
    }
  }
}
    `;
export type DeleteCustomFormFieldResponseMutationFn = Apollo.MutationFunction<DeleteCustomFormFieldResponseMutation, DeleteCustomFormFieldResponseMutationVariables>;

/**
 * __useDeleteCustomFormFieldResponseMutation__
 *
 * To run a mutation, you first call `useDeleteCustomFormFieldResponseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCustomFormFieldResponseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCustomFormFieldResponseMutation, { data, loading, error }] = useDeleteCustomFormFieldResponseMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDeleteCustomFormFieldResponseMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCustomFormFieldResponseMutation, DeleteCustomFormFieldResponseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCustomFormFieldResponseMutation, DeleteCustomFormFieldResponseMutationVariables>(DeleteCustomFormFieldResponseDocument, options);
      }
export type DeleteCustomFormFieldResponseMutationHookResult = ReturnType<typeof useDeleteCustomFormFieldResponseMutation>;
export type DeleteCustomFormFieldResponseMutationResult = Apollo.MutationResult<DeleteCustomFormFieldResponseMutation>;
export type DeleteCustomFormFieldResponseMutationOptions = Apollo.BaseMutationOptions<DeleteCustomFormFieldResponseMutation, DeleteCustomFormFieldResponseMutationVariables>;