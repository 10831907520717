import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type SearchProviderScheduleQueryVariables = Types.Exact<{
  locationIds?: Types.Maybe<Types.Scalars['_uuid']>;
  providerIds?: Types.Maybe<Types.Scalars['_uuid']>;
  workspaceId: Types.Scalars['uuid'];
  workday: Types.Scalars['String'];
}>;


export type SearchProviderScheduleQuery = { __typename?: 'query_root', searchProviderSchedule: Array<{ __typename?: 'providerSchedule', default: boolean, schedule: any, effectiveFrom: any, effectiveUntil?: any | null | undefined, locationId: any, providerId: any, location: { __typename?: 'location', id: any, name: string, timezone?: string | null | undefined }, provider: { __typename?: 'provider', firstName: string, lastName: string } }> };


export const SearchProviderScheduleDocument = gql`
    query SearchProviderSchedule($locationIds: _uuid, $providerIds: _uuid, $workspaceId: uuid!, $workday: String!) {
  searchProviderSchedule(
    args: {locationids: $locationIds, workspaceid: $workspaceId, workday: $workday, providerids: $providerIds}
  ) {
    default
    schedule
    effectiveFrom
    effectiveUntil
    locationId
    location {
      id
      name
      timezone
    }
    providerId
    provider {
      firstName
      lastName
    }
  }
}
    `;

/**
 * __useSearchProviderScheduleQuery__
 *
 * To run a query within a React component, call `useSearchProviderScheduleQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchProviderScheduleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchProviderScheduleQuery({
 *   variables: {
 *      locationIds: // value for 'locationIds'
 *      providerIds: // value for 'providerIds'
 *      workspaceId: // value for 'workspaceId'
 *      workday: // value for 'workday'
 *   },
 * });
 */
export function useSearchProviderScheduleQuery(baseOptions: Apollo.QueryHookOptions<SearchProviderScheduleQuery, SearchProviderScheduleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchProviderScheduleQuery, SearchProviderScheduleQueryVariables>(SearchProviderScheduleDocument, options);
      }
export function useSearchProviderScheduleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchProviderScheduleQuery, SearchProviderScheduleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchProviderScheduleQuery, SearchProviderScheduleQueryVariables>(SearchProviderScheduleDocument, options);
        }
export type SearchProviderScheduleQueryHookResult = ReturnType<typeof useSearchProviderScheduleQuery>;
export type SearchProviderScheduleLazyQueryHookResult = ReturnType<typeof useSearchProviderScheduleLazyQuery>;
export type SearchProviderScheduleQueryResult = Apollo.QueryResult<SearchProviderScheduleQuery, SearchProviderScheduleQueryVariables>;