import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type LeadTrackedEventAggregateQueryVariables = Types.Exact<{
  where?: Types.Maybe<Types.LeadTrackedEvent_Bool_Exp>;
}>;


export type LeadTrackedEventAggregateQuery = { __typename?: 'query_root', leadTrackedEvent_aggregate: { __typename?: 'leadTrackedEvent_aggregate', aggregate?: { __typename?: 'leadTrackedEvent_aggregate_fields', count: number } | null | undefined } };


export const LeadTrackedEventAggregateDocument = gql`
    query leadTrackedEventAggregate($where: leadTrackedEvent_bool_exp) {
  leadTrackedEvent_aggregate(where: $where) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useLeadTrackedEventAggregateQuery__
 *
 * To run a query within a React component, call `useLeadTrackedEventAggregateQuery` and pass it any options that fit your needs.
 * When your component renders, `useLeadTrackedEventAggregateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLeadTrackedEventAggregateQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useLeadTrackedEventAggregateQuery(baseOptions?: Apollo.QueryHookOptions<LeadTrackedEventAggregateQuery, LeadTrackedEventAggregateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LeadTrackedEventAggregateQuery, LeadTrackedEventAggregateQueryVariables>(LeadTrackedEventAggregateDocument, options);
      }
export function useLeadTrackedEventAggregateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LeadTrackedEventAggregateQuery, LeadTrackedEventAggregateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LeadTrackedEventAggregateQuery, LeadTrackedEventAggregateQueryVariables>(LeadTrackedEventAggregateDocument, options);
        }
export type LeadTrackedEventAggregateQueryHookResult = ReturnType<typeof useLeadTrackedEventAggregateQuery>;
export type LeadTrackedEventAggregateLazyQueryHookResult = ReturnType<typeof useLeadTrackedEventAggregateLazyQuery>;
export type LeadTrackedEventAggregateQueryResult = Apollo.QueryResult<LeadTrackedEventAggregateQuery, LeadTrackedEventAggregateQueryVariables>;