import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type CustomUpdateConsumableMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
  _set: Types.Scalars['jsonb'];
}>;


export type CustomUpdateConsumableMutation = { __typename?: 'mutation_root', customUpdateConsumable: any };


export const CustomUpdateConsumableDocument = gql`
    mutation CustomUpdateConsumable($id: uuid!, $_set: jsonb!) {
  customUpdateConsumable(id: $id, _set: $_set)
}
    `;
export type CustomUpdateConsumableMutationFn = Apollo.MutationFunction<CustomUpdateConsumableMutation, CustomUpdateConsumableMutationVariables>;

/**
 * __useCustomUpdateConsumableMutation__
 *
 * To run a mutation, you first call `useCustomUpdateConsumableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCustomUpdateConsumableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [customUpdateConsumableMutation, { data, loading, error }] = useCustomUpdateConsumableMutation({
 *   variables: {
 *      id: // value for 'id'
 *      _set: // value for '_set'
 *   },
 * });
 */
export function useCustomUpdateConsumableMutation(baseOptions?: Apollo.MutationHookOptions<CustomUpdateConsumableMutation, CustomUpdateConsumableMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CustomUpdateConsumableMutation, CustomUpdateConsumableMutationVariables>(CustomUpdateConsumableDocument, options);
      }
export type CustomUpdateConsumableMutationHookResult = ReturnType<typeof useCustomUpdateConsumableMutation>;
export type CustomUpdateConsumableMutationResult = Apollo.MutationResult<CustomUpdateConsumableMutation>;
export type CustomUpdateConsumableMutationOptions = Apollo.BaseMutationOptions<CustomUpdateConsumableMutation, CustomUpdateConsumableMutationVariables>;