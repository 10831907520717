import { Stack, Heading } from '@chakra-ui/react';
import { useGetActivityLogsQuery } from '@webapp/graphql';
import ReactDiffViewer from 'react-diff-viewer';
import { configuredDayjs as dayjs } from '@webapp/util-time';
import { SmartTextEditor } from '@webapp/ui';
/* eslint-disable-next-line */
export interface NoteAuditLogProps {
  noteId: string;
}

const NoteAudit = ({ activityLog }: { activityLog: any }) => (
  <Stack p={2} shadow="sm">
    <Heading size="sm">
      {activityLog?.metadata?.header} by {activityLog?.actor.firstName}{' '}
      {activityLog?.actor.lastName} on{' '}
      {dayjs(activityLog?.createdAt).format('LL')}
    </Heading>
    {activityLog?.metadata?.noteMessageBefore && (
      <ReactDiffViewer
        hideLineNumbers={true}
        oldValue={activityLog?.metadata?.noteMessageBefore}
        newValue={activityLog?.metadata?.noteMessageAfter}
        splitView={true}
        renderContent={(block) => (
          <SmartTextEditor
            content={block}
            hideSave
            editable={false}
            height={'100%'}
          />
        )}
      />
    )}
  </Stack>
);

export function NoteAuditLog({ noteId }: NoteAuditLogProps) {
  const { data: activityLogData } = useGetActivityLogsQuery({
    variables: {
      where: {
        objectType: {
          _eq: 'note',
        },
        relevantObjectId: {
          _eq: noteId,
        },
      },
    },
    skip: !noteId,
  });
  return (
    <Stack spacing={4} maxH="65vh" overflowY={'auto'}>
      {activityLogData?.activityLog?.map((activityLog) => (
        <NoteAudit activityLog={activityLog} />
      ))}
    </Stack>
  );
}

export default NoteAuditLog;
