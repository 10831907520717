import { createContext, useContext } from 'react';
import { UseFieldArrayAppend, UseFieldArrayRemove } from 'react-hook-form';
import { InvoiceFormValues } from '../types';

interface InvoiceItemContextType {
  append: UseFieldArrayAppend<InvoiceFormValues, 'lineItems'>;
  remove: UseFieldArrayRemove;
}

export const InvoiceItemContext = createContext<
  InvoiceItemContextType | undefined
>(undefined);

export const useInvoiceItemContext = () => {
  const context = useContext(InvoiceItemContext);

  if (context === undefined) {
    throw new Error(
      'useInvoiceItemContext must be used within a InvoiceItemProvider'
    );
  }
  return context;
};

export default useInvoiceItemContext;
