import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type SendTransactionToTerminalMutationVariables = Types.Exact<{
  payrixTerminalId: Types.Scalars['uuid'];
  amount: Types.Scalars['Int'];
  invoiceId: Types.Scalars['uuid'];
}>;


export type SendTransactionToTerminalMutation = { __typename?: 'mutation_root', sendTransactionToTerminal?: { __typename?: 'TerminalTransactionReceiptResponse', terminalTransactionId: string, payrixTerminalTransactionId: string } | null | undefined };


export const SendTransactionToTerminalDocument = gql`
    mutation sendTransactionToTerminal($payrixTerminalId: uuid!, $amount: Int!, $invoiceId: uuid!) {
  sendTransactionToTerminal(
    payrixTerminalId: $payrixTerminalId
    amount: $amount
    invoiceId: $invoiceId
  ) {
    terminalTransactionId
    payrixTerminalTransactionId
  }
}
    `;
export type SendTransactionToTerminalMutationFn = Apollo.MutationFunction<SendTransactionToTerminalMutation, SendTransactionToTerminalMutationVariables>;

/**
 * __useSendTransactionToTerminalMutation__
 *
 * To run a mutation, you first call `useSendTransactionToTerminalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendTransactionToTerminalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendTransactionToTerminalMutation, { data, loading, error }] = useSendTransactionToTerminalMutation({
 *   variables: {
 *      payrixTerminalId: // value for 'payrixTerminalId'
 *      amount: // value for 'amount'
 *      invoiceId: // value for 'invoiceId'
 *   },
 * });
 */
export function useSendTransactionToTerminalMutation(baseOptions?: Apollo.MutationHookOptions<SendTransactionToTerminalMutation, SendTransactionToTerminalMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendTransactionToTerminalMutation, SendTransactionToTerminalMutationVariables>(SendTransactionToTerminalDocument, options);
      }
export type SendTransactionToTerminalMutationHookResult = ReturnType<typeof useSendTransactionToTerminalMutation>;
export type SendTransactionToTerminalMutationResult = Apollo.MutationResult<SendTransactionToTerminalMutation>;
export type SendTransactionToTerminalMutationOptions = Apollo.BaseMutationOptions<SendTransactionToTerminalMutation, SendTransactionToTerminalMutationVariables>;