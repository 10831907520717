/* eslint-disable no-restricted-syntax */
import './wrappedExternalBooking.module.css';
import { useForm, FormProvider } from 'react-hook-form';
import { useCallback, useEffect, useState } from 'react';
import { Button, Stack, Heading, Center, Text } from '@chakra-ui/react';
import {
  BookExternalAppointmentInput,
  useBookExternalAppointmentMutation,
  useGetLeadFormLinkExternalLazyQuery,
  usePayWithNewMethodMutation,
} from '@webapp/graphql';
import { datadogRum } from '@datadog/browser-rum';
import { PayForm, PayrixPayfieldsProps } from '@webapp/ui-composites';
import toast from 'react-hot-toast';
import { ExternalBooking } from '../../../external-booking/external-booking';

interface WrappedExternalBookingProps {
  workspaceId: string;
  payrixMerchantId?: string;
  patientStatus?: string;
  previousServiceIds?: string[];
  finixMerchantId?: string;
  lockedToLocations?: any[];
  lockedToServices?: any[];
  serviceOrderRanking?: any[];
  leadFormLinkId?: string;
  onCompleted: (values: any) => void;
  data: any;
  parentSurvey: any;
  allowProviderSelection?: boolean;
}

export default function WrappedExternalBooking({
  workspaceId,
  onCompleted,
  payrixMerchantId,
  finixMerchantId,
  data,
  lockedToLocations,
  lockedToServices,
  parentSurvey,
  leadFormLinkId,
  allowProviderSelection,
  patientStatus,
  previousServiceIds,
  serviceOrderRanking,
}: WrappedExternalBookingProps) {
  const [locationPayrixMerchantId, setLocationPayrixMerchantId] =
    useState<string>();
  const [locationFinixMerchantId, setLocationFinixMerchantId] =
    useState<string>();
  const [showPaymentStep, setShowPaymentStep] = useState(false);
  const [paymentReason, setPaymentReason] = useState<string>();
  const [bookExternalAppointment] = useBookExternalAppointmentMutation({
    onError: (err) => {
      console.error(err, 'bookExternalAppointmentError');
    },
  });
  const [bookingFormValidationError, setBookingFormValidationError] = useState<
    string | null
  >(null);

  const [getLeadFormLink, { data: leadFormLinkData }] =
    useGetLeadFormLinkExternalLazyQuery();
  const [payWithNewMethod, { loading: payingWithNewMethod }] =
    usePayWithNewMethodMutation({
      onCompleted: () => {
        closePaymentAndOnCompleted();
      },
      onError: (err) => {
        console.error(err);
        toast.error('There was an error processing your payment');
      },
    });

  useEffect(() => {
    if (leadFormLinkId) {
      getLeadFormLink({ variables: { id: leadFormLinkId } });
    }
  }, [getLeadFormLink, leadFormLinkId]);

  const methods = useForm<any>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: {
      ...data,
    },
    criteriaMode: 'firstError',
    shouldFocusError: true,
    shouldUnregister: false,
    shouldUseNativeValidation: false,
    delayError: undefined,
  });

  const { prepayment, noShowFee } = methods.watch();

  const {
    formState: { errors },
    getValues,
  } = methods;

  const generatePatientValues = useCallback(() => {
    const values = { ...parentSurvey.data, ...getValues() };
    let patient = {
      ...values,
    };

    if (!patient?.id) {
      patient.id = values?.patientId;
    }

    Object.keys(parentSurvey.data).forEach((key) => {
      if (typeof patient[key] === 'object') {
        patient = { ...patient, ...patient[key] };
      } else {
        patient[key] = parentSurvey.data[key];
      }
    });

    if (typeof patient.email === 'object' && patient.email) {
      patient.email = patient?.email?.email;
    }
    if (typeof patient.phoneNumber === 'object' && patient.phoneNumber) {
      patient.phoneNumber = patient.phoneNumber.phoneNumber;
    }
    return patient;
  }, [getValues, parentSurvey.data]);

  useEffect(() => {
    if (leadFormLinkData) {
      methods.setValue(
        'patientId',
        leadFormLinkData?.leadFormLink_by_pk?.patientId
      );
      methods.setValue(
        'location',
        leadFormLinkData?.leadFormLink_by_pk?.formData?.locationId
      );
      methods.setValue(
        'providerId',
        leadFormLinkData?.leadFormLink_by_pk?.formData?.providerId
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leadFormLinkData]);

  const submitForm = useCallback(async () => {
    setBookingFormValidationError(null);

    let values = { ...getValues() };

    for (const key of Object.keys(data)) {
      if (typeof data[key] === 'object') {
        values = { ...values, ...data[key] };
      }
    }

    if (!values.dateTime) {
      setBookingFormValidationError(
        'Please select a date and time for your appointment to continue.'
      );
      return;
    }

    if (values.prepayment || values.noShowFee) {
      if (values.prepayment) {
        setPaymentReason(
          'One of the services you selected requires a deposit before it can be booked.'
        );
      } else {
        setPaymentReason(
          'We require a credit card to hold your appointment, this will show up as a hold on your statement. You will not be charged unless you do not show up for your appointment.'
        );
      }
      setShowPaymentStep(true);
      return;
    }
    const patientValues = generatePatientValues();

    await bookExternalAppointment({
      variables: {
        input: {
          email: patientValues.email,
          firstName: patientValues.firstName,
          lastName: patientValues.lastName,
          patientId: values.patientId,
          phoneNumber: patientValues.phoneNumber,
          locationId: values.location,
          serviceIds: values.serviceIds,
          providerId: values.providerId,
          time: values.dateTime,
          workspaceId,
          ...(values.availableProviders
            ? { availableProviders: values.availableProviders }
            : {}),
        },
      },
    });

    onCompleted(generatePatientValues());
    try {
      datadogRum.addAction('bookExternalAppointment', {
        email: patientValues.email,
        firstName: patientValues.firstName,
        lastName: patientValues.lastName,
        patientId: values.patientId,
        phoneNumber: patientValues.phoneNumber,
        locationId: values.location,
        serviceIds: values.serviceIds,
        providerId: values.providerId,
        time: values.dateTime,
        workspaceId,
      });
    } catch (err) {
      // shh
    }
  }, [
    bookExternalAppointment,
    data,
    generatePatientValues,
    getValues,
    onCompleted,
    workspaceId,
  ]);

  useEffect(() => {
    window.document.querySelector('#sv-nav-complete')?.remove();
  }, []);

  type BookingInputPartial = Omit<
    BookExternalAppointmentInput,
    | 'billingAddress'
    | 'paymentId'
    | 'noShowFeePaymentMethodId'
    | 'approvedNoShowFeeAmount'
  >;

  const getBaseValues = useCallback((): {
    patientValues: any;
    bookingValues: BookingInputPartial;
  } => {
    const values = generatePatientValues();

    try {
      datadogRum.setUser({
        name: `${values.firstName} ${values.lastName}`,
        email: values.email as string,
      });
    } catch (err) {
      // shh
    }

    return {
      patientValues: values,
      bookingValues: {
        email: values.email,
        firstName: values.firstName,
        lastName: values.lastName,
        phoneNumber: values.phoneNumber,
        locationId: values.location,
        serviceIds: values.serviceIds,
        providerId: values.providerId,
        time: values.dateTime,
        workspaceId,
        ...(values.availableProviders
          ? { availableProviders: values.availableProviders }
          : {}),
      },
    };
  }, [generatePatientValues, workspaceId]);

  const closePaymentAndOnCompleted = useCallback(() => {
    setShowPaymentStep(false);
    onCompleted(generatePatientValues());
  }, [generatePatientValues, onCompleted]);

  const payAndBookAppointment: PayrixPayfieldsProps<'token'>['onPayWithNewMethod'] =
    useCallback(
      ({ token }) => {
        const { patientValues, bookingValues } = getBaseValues();

        payWithNewMethod({
          variables: {
            amount: patientValues.prepayment ?? patientValues.noShowFee,
            caseName: patientValues.prepayment
              ? 'EXTERNAL_DEPOSIT'
              : 'EXTERNAL_NO_SHOW_FEE',
            caseContext: {
              ...bookingValues,
              billingAddress: token.customer.address1,
            },
            paymentInfo: token,

            payrixMerchantId: locationPayrixMerchantId ?? payrixMerchantId,
            workspaceId,
          },
        });
      },
      [
        getBaseValues,
        locationPayrixMerchantId,
        payWithNewMethod,
        payrixMerchantId,
        workspaceId,
      ]
    );

  const payFinixAndBookAppointment = useCallback(
    ({
      token,
      cardOwnerDetails,
      finixFraudSessionId,
    }: {
      token: string;
      cardOwnerDetails: any;
      finixFraudSessionId: string;
    }) => {
      const { patientValues, bookingValues } = getBaseValues();

      payWithNewMethod({
        variables: {
          amount: patientValues.prepayment ?? patientValues.noShowFee,
          caseName: patientValues.prepayment
            ? 'EXTERNAL_DEPOSIT'
            : 'EXTERNAL_NO_SHOW_FEE',
          caseContext: {
            ...bookingValues,
            billingAddress: cardOwnerDetails.address.addressLine1,
          },
          paymentInfo: {
            token,
            cardOwnerDetails,
            finixFraudSessionId,
          },
          finixMerchantId: locationFinixMerchantId ?? finixMerchantId,
          workspaceId,
        },
      });
    },
    [
      getBaseValues,
      locationPayrixMerchantId,
      payWithNewMethod,
      payrixMerchantId,
      workspaceId,
    ]
  );

  const amount = prepayment ?? noShowFee;

  const showPaymentAmount = Boolean(prepayment);

  return (
    <FormProvider {...methods}>
      <Stack
        id="wrapped-external-booking"
        w="full"
        h="full"
        spacing={8}
        minH={'400px'}
      >
        {!showPaymentStep && (
          <ExternalBooking
            patientStatus={patientStatus}
            previousServiceIds={previousServiceIds}
            workspaceId={workspaceId}
            hasLeadFormLink={!!leadFormLinkId}
            presetServiceIds={
              leadFormLinkData?.leadFormLink_by_pk?.formData?.serviceIds
            }
            lockedToLocationIds={lockedToLocations?.map((l) => l.value)}
            lockedToServiceIds={lockedToServices?.map((s) => s.value)}
            serviceOrderRanking={serviceOrderRanking}
            onSelectedLocation={(location) => {
              if (location?.payrixMerchantId) {
                setLocationPayrixMerchantId(location?.payrixMerchantId);
              }
            }}
            allowProviderSelection={allowProviderSelection}
          />
        )}

        {showPaymentStep && (
          <Stack>
            <Heading
              size="sm"
              color="var(
    --sjs-font-questiontitle-color,
    var(--sjs-general-forecolor, var(--foreground, #161616))
  )"
            >
              {paymentReason}
            </Heading>
            <Center>
              <PayForm
                styleProps={{
                  amountContainer: {
                    color:
                      'var(--sjs-font-questiontitle-color, var(--sjs-general-forecolor, var(--foreground, gray.600)))',
                  },
                  amountText: {
                    color:
                      'var(--sjs-font-questiontitle-color, var(--sjs-general-forecolor, var(--foreground, gray.600)))',
                  },
                }}
                amount={amount}
                authenticated={false}
                mode="token"
                type={finixMerchantId ? 'cardTokenForm' : 'payrix'}
                isLoading={payingWithNewMethod}
                onNewCardToken={payFinixAndBookAppointment}
                onPayWithNewMethod={payAndBookAppointment}
                payrixMerchantId={
                  locationPayrixMerchantId ?? payrixMerchantId ?? ''
                }
                finixMerchantId={finixMerchantId}
                showPaymentAmount={showPaymentAmount}
                workspaceId={workspaceId}
              />
            </Center>
          </Stack>
        )}
        {bookingFormValidationError && (
          <Text color={'red'}>{bookingFormValidationError}</Text>
        )}
        {!showPaymentStep && (
          <Button
            id="lead-form-next-button"
            type="button"
            rounded="md"
            border="1px solid white"
            onClick={submitForm}
          >
            Next
          </Button>
        )}
      </Stack>
    </FormProvider>
  );
}
