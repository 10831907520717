import '../ui.module.scss';

import {
  Box,
  Button,
  Heading,
  SimpleGrid,
  useColorModeValue,
  VisuallyHidden,
} from '@chakra-ui/react';

import { FaFacebook, FaGithub, FaGoogle } from 'react-icons/fa';
import { Card } from '../card/card';
import { DividerWithText } from '../divider-with-text/divider-with-text';
import { SignupForm } from '../signup-form/signup-form';
import { Logo } from '../logo/logo';

/* eslint-disable-next-line */
export interface SignupPageProps {}

export function SignupPage(props: SignupPageProps) {
  return (
    <Box
      bg={useColorModeValue('gray.50', 'inherit')}
      minH="100vh"
      py="12"
      px={{ base: '4', lg: '8' }}
    >
      <Box maxW="md" mx="auto">
        <Logo mx="auto" h="14" mb={{ base: '10', md: '12' }} />
        <Heading
          textAlign="center"
          mb={{ base: '10' }}
          size="xl"
          fontWeight="extrabold"
        >
          Create your account
        </Heading>

        <Card>
          <SignupForm />
          <DividerWithText mt="6">or continue with</DividerWithText>
          <SimpleGrid mt="6" columns={3} spacing="3">
            <Button color="currentColor" variant="outline">
              <VisuallyHidden>Register with Facebook</VisuallyHidden>
              <FaFacebook />
            </Button>
            <Button color="currentColor" variant="outline">
              <VisuallyHidden>Register with Google</VisuallyHidden>
              <FaGoogle />
            </Button>
            <Button color="currentColor" variant="outline">
              <VisuallyHidden>Register with Github</VisuallyHidden>
              <FaGithub />
            </Button>
          </SimpleGrid>
        </Card>
      </Box>
    </Box>
  );
}

export default SignupPage;
