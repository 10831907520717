import { DEFAULT_INPUT_STYLE } from '@webapp/constants';
import CardTokenFormOptions from './CardTokenFormOptions';
import CardTokenFormProps from './CardTokenFormProps';

function useCardTokenFormOptions({
  onComplete,
  ...options
}: Omit<CardTokenFormProps, 'onSubmit'>): CardTokenFormOptions {
  const hideFields = options.hideFields || ['name'];

  const styles = {
    ...options.styles,
    default: {
      ...DEFAULT_INPUT_STYLE,
      ...options.styles?.default,
    },
    error: {
      borderColor: 'red',
      color: 'red',
      ...options.styles?.error,
    },
  };

  return {
    ...options,
    hideFields,
    styles,
  };
}

export default useCardTokenFormOptions;
