import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type DeletePhotoMediaTagByPhotoIdTagIdMutationVariables = Types.Exact<{
  photoId: Types.Scalars['uuid'];
  mediaTagId: Types.Scalars['uuid'];
}>;


export type DeletePhotoMediaTagByPhotoIdTagIdMutation = { __typename?: 'mutation_root', delete_photoMediaTag?: { __typename?: 'photoMediaTag_mutation_response', affected_rows: number } | null | undefined };


export const DeletePhotoMediaTagByPhotoIdTagIdDocument = gql`
    mutation DeletePhotoMediaTagByPhotoIdTagId($photoId: uuid!, $mediaTagId: uuid!) {
  delete_photoMediaTag(
    where: {photoId: {_eq: $photoId}, mediaTagId: {_eq: $mediaTagId}}
  ) {
    affected_rows
  }
}
    `;
export type DeletePhotoMediaTagByPhotoIdTagIdMutationFn = Apollo.MutationFunction<DeletePhotoMediaTagByPhotoIdTagIdMutation, DeletePhotoMediaTagByPhotoIdTagIdMutationVariables>;

/**
 * __useDeletePhotoMediaTagByPhotoIdTagIdMutation__
 *
 * To run a mutation, you first call `useDeletePhotoMediaTagByPhotoIdTagIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePhotoMediaTagByPhotoIdTagIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePhotoMediaTagByPhotoIdTagIdMutation, { data, loading, error }] = useDeletePhotoMediaTagByPhotoIdTagIdMutation({
 *   variables: {
 *      photoId: // value for 'photoId'
 *      mediaTagId: // value for 'mediaTagId'
 *   },
 * });
 */
export function useDeletePhotoMediaTagByPhotoIdTagIdMutation(baseOptions?: Apollo.MutationHookOptions<DeletePhotoMediaTagByPhotoIdTagIdMutation, DeletePhotoMediaTagByPhotoIdTagIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePhotoMediaTagByPhotoIdTagIdMutation, DeletePhotoMediaTagByPhotoIdTagIdMutationVariables>(DeletePhotoMediaTagByPhotoIdTagIdDocument, options);
      }
export type DeletePhotoMediaTagByPhotoIdTagIdMutationHookResult = ReturnType<typeof useDeletePhotoMediaTagByPhotoIdTagIdMutation>;
export type DeletePhotoMediaTagByPhotoIdTagIdMutationResult = Apollo.MutationResult<DeletePhotoMediaTagByPhotoIdTagIdMutation>;
export type DeletePhotoMediaTagByPhotoIdTagIdMutationOptions = Apollo.BaseMutationOptions<DeletePhotoMediaTagByPhotoIdTagIdMutation, DeletePhotoMediaTagByPhotoIdTagIdMutationVariables>;