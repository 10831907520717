import * as Types from '../../generated/graphql';

import { gql } from '@apollo/client';
export type ProviderFieldsMinimalFragment = { __typename?: 'provider', id: any, firstName: string, lastName: string, profilePicture?: string | null | undefined, email: string, active?: boolean | null | undefined, phoneNumber?: string | null | undefined, address?: string | null | undefined, title?: string | null | undefined, stateLicense?: string | null | undefined, upin?: string | null | undefined, hourlyRate?: any | null | undefined, canBeBookedOnline?: boolean | null | undefined, canPerformGFE?: boolean | null | undefined, dea?: string | null | undefined, npi?: string | null | undefined, degree?: Types.DegreeDesignation_Enum | null | undefined, dob?: any | null | undefined, addressId?: any | null | undefined, scheduleBuffer: number, user?: { __typename?: 'user', id: any, firstName: string, lastName: string } | null | undefined, degreeDesignation?: { __typename?: 'degreeDesignation', value: string, description: string } | null | undefined };

export const ProviderFieldsMinimalFragmentDoc = gql`
    fragment ProviderFieldsMinimal on provider {
  id
  firstName
  lastName
  profilePicture
  email
  active
  phoneNumber
  address
  title
  stateLicense
  upin
  hourlyRate
  canBeBookedOnline
  canPerformGFE
  dea
  npi
  degree
  dob
  addressId
  scheduleBuffer
  user {
    id
    firstName
    lastName
  }
  degreeDesignation {
    value
    description
  }
}
    `;