import { AccordionButton, chakra } from '@chakra-ui/react';

export const PayformAccordionButton = chakra(AccordionButton, {
  baseStyle: {
    boxShadow: '0',
    background: 'gray.100',
    color: 'gray.500',
    fontWeight: 'bold',
    outline: '0',
  },
});

export default {};
