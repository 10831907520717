import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { VitalsFieldsFragmentDoc } from '../../fragments/vitalsFields.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetVitalsQueryVariables = Types.Exact<{
  where?: Types.Maybe<Types.Vitals_Bool_Exp>;
  limit?: Types.Maybe<Types.Scalars['Int']>;
  offset?: Types.Maybe<Types.Scalars['Int']>;
  orderBy?: Types.Maybe<Array<Types.Vitals_Order_By> | Types.Vitals_Order_By>;
}>;


export type GetVitalsQuery = { __typename?: 'query_root', vitals: Array<{ __typename?: 'vitals', id: any, temperature: any, pulse: number, bloodPressure?: any | null | undefined, patientWorkspaceId: any, createdAt: any, spo2?: any | null | undefined, updatedAt: any, time?: any | null | undefined }> };


export const GetVitalsDocument = gql`
    query GetVitals($where: vitals_bool_exp, $limit: Int, $offset: Int, $orderBy: [vitals_order_by!]) {
  vitals(where: $where, limit: $limit, offset: $offset, order_by: $orderBy) {
    ...VitalsFields
  }
}
    ${VitalsFieldsFragmentDoc}`;

/**
 * __useGetVitalsQuery__
 *
 * To run a query within a React component, call `useGetVitalsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVitalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVitalsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetVitalsQuery(baseOptions?: Apollo.QueryHookOptions<GetVitalsQuery, GetVitalsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetVitalsQuery, GetVitalsQueryVariables>(GetVitalsDocument, options);
      }
export function useGetVitalsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetVitalsQuery, GetVitalsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetVitalsQuery, GetVitalsQueryVariables>(GetVitalsDocument, options);
        }
export type GetVitalsQueryHookResult = ReturnType<typeof useGetVitalsQuery>;
export type GetVitalsLazyQueryHookResult = ReturnType<typeof useGetVitalsLazyQuery>;
export type GetVitalsQueryResult = Apollo.QueryResult<GetVitalsQuery, GetVitalsQueryVariables>;