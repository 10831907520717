import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type RetractInvoiceMembershipMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
}>;


export type RetractInvoiceMembershipMutation = { __typename?: 'mutation_root', retractInvoiceMembership: { __typename?: 'SuccessAnswer', success: boolean } };


export const RetractInvoiceMembershipDocument = gql`
    mutation RetractInvoiceMembership($id: uuid!) {
  retractInvoiceMembership(id: $id) {
    success
  }
}
    `;
export type RetractInvoiceMembershipMutationFn = Apollo.MutationFunction<RetractInvoiceMembershipMutation, RetractInvoiceMembershipMutationVariables>;

/**
 * __useRetractInvoiceMembershipMutation__
 *
 * To run a mutation, you first call `useRetractInvoiceMembershipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRetractInvoiceMembershipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [retractInvoiceMembershipMutation, { data, loading, error }] = useRetractInvoiceMembershipMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRetractInvoiceMembershipMutation(baseOptions?: Apollo.MutationHookOptions<RetractInvoiceMembershipMutation, RetractInvoiceMembershipMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RetractInvoiceMembershipMutation, RetractInvoiceMembershipMutationVariables>(RetractInvoiceMembershipDocument, options);
      }
export type RetractInvoiceMembershipMutationHookResult = ReturnType<typeof useRetractInvoiceMembershipMutation>;
export type RetractInvoiceMembershipMutationResult = Apollo.MutationResult<RetractInvoiceMembershipMutation>;
export type RetractInvoiceMembershipMutationOptions = Apollo.BaseMutationOptions<RetractInvoiceMembershipMutation, RetractInvoiceMembershipMutationVariables>;