import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { ServiceFieldsFragmentDoc } from '../../fragments/serviceFields.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type UpdateServiceMutationVariables = Types.Exact<{
  set?: Types.Maybe<Types.Service_Set_Input>;
  id: Types.Scalars['uuid'];
}>;


export type UpdateServiceMutation = { __typename?: 'mutation_root', update_service_by_pk?: { __typename?: 'service', id: any, taxRate?: any | null | undefined, deposit?: number | null | undefined, requiresDeposit?: boolean | null | undefined, name: string, active?: boolean | null | undefined, description?: string | null | undefined, image?: string | null | undefined, availableInStore?: boolean | null | undefined, canBeBookedOnline?: boolean | null | undefined, minutesToPerform: number, newPatientMinutesToPerform?: number | null | undefined, deviceBufferTime?: number | null | undefined, price: number, isProduct?: boolean | null | undefined, onlineBookingRules?: any | null | undefined, color?: string | null | undefined, serviceUnitId?: any | null | undefined, serviceCategoryId?: any | null | undefined, pricingModel: string, serviceUnit?: { __typename?: 'serviceUnit', id: any, name: string } | null | undefined, locationServices: Array<{ __typename?: 'locationService', id: any, locationId: any, price?: any | null | undefined, taxRate?: any | null | undefined }>, serviceCategory?: { __typename?: 'serviceCategory', title: string } | null | undefined, appointmentType?: { __typename?: 'appointmentType', name: string, color?: string | null | undefined } | null | undefined, serviceDeviceTypes: Array<{ __typename?: 'serviceDeviceType', deviceType: { __typename?: 'deviceType', value: string } }>, serviceConsumableTypes: Array<{ __typename?: 'serviceConsumableType', numberOfUnits: any, pricePerUnit: number, consumableType: { __typename?: 'consumableType', id: any, name: string, requiredFields: any, unitStep: any } }>, precareDocument?: { __typename?: 'document', title?: string | null | undefined, file?: { __typename?: 'FileOnlyURL', url: string } | null | undefined } | null | undefined, postcareDocument?: { __typename?: 'document', title?: string | null | undefined, file?: { __typename?: 'FileOnlyURL', url: string } | null | undefined } | null | undefined } | null | undefined };


export const UpdateServiceDocument = gql`
    mutation UpdateService($set: service_set_input, $id: uuid!) {
  update_service_by_pk(_set: $set, pk_columns: {id: $id}) {
    ...ServiceFields
  }
}
    ${ServiceFieldsFragmentDoc}`;
export type UpdateServiceMutationFn = Apollo.MutationFunction<UpdateServiceMutation, UpdateServiceMutationVariables>;

/**
 * __useUpdateServiceMutation__
 *
 * To run a mutation, you first call `useUpdateServiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateServiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateServiceMutation, { data, loading, error }] = useUpdateServiceMutation({
 *   variables: {
 *      set: // value for 'set'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateServiceMutation(baseOptions?: Apollo.MutationHookOptions<UpdateServiceMutation, UpdateServiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateServiceMutation, UpdateServiceMutationVariables>(UpdateServiceDocument, options);
      }
export type UpdateServiceMutationHookResult = ReturnType<typeof useUpdateServiceMutation>;
export type UpdateServiceMutationResult = Apollo.MutationResult<UpdateServiceMutation>;
export type UpdateServiceMutationOptions = Apollo.BaseMutationOptions<UpdateServiceMutation, UpdateServiceMutationVariables>;