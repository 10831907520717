// import { Player } from 'video-react';
// import 'video-react/dist/video-react.css';

import { AspectRatio } from '@chakra-ui/react';

/* eslint-disable-next-line */
export interface SimpleVideoProps {
  src: string;
  maxH?: string;
}

export function SimpleVideo({ src, maxH = '400px' }: SimpleVideoProps) {
  return (
    <AspectRatio maxH={maxH}>
      <video controls style={{ objectFit: 'contain' }}>
        <source src={src} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </AspectRatio>
  );
}

export default SimpleVideo;
