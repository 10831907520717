import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { PurchaseOrderFieldsFragmentDoc } from '../../fragments/purchaseOrderFields.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetPurchaseOrdersQueryVariables = Types.Exact<{
  where: Types.PurchaseOrder_Bool_Exp;
  limit: Types.Scalars['Int'];
  offset?: Types.Maybe<Types.Scalars['Int']>;
  orderBy?: Types.Maybe<Array<Types.PurchaseOrder_Order_By> | Types.PurchaseOrder_Order_By>;
}>;


export type GetPurchaseOrdersQuery = { __typename?: 'query_root', purchaseOrder: Array<{ __typename?: 'purchaseOrder', id: any, orderNumber: string, vendorOrderNumber?: string | null | undefined, note?: string | null | undefined, status: string, cost: number, createdAt: any, updatedAt: any, archived: boolean, consumableType: { __typename?: 'consumableType', id: any, name: string }, consumableBrand: { __typename?: 'consumableBrand', id: any, name: string }, consumables: Array<{ __typename?: 'consumable', id: any, cost: number, quantity: any, originalQuantity: any, metadata: any, type: { __typename?: 'consumableType', id: any, name: string }, brand?: { __typename?: 'consumableBrand', id: any, name: string } | null | undefined }> }> };


export const GetPurchaseOrdersDocument = gql`
    query GetPurchaseOrders($where: purchaseOrder_bool_exp!, $limit: Int!, $offset: Int, $orderBy: [purchaseOrder_order_by!]) {
  purchaseOrder(where: $where, limit: $limit, offset: $offset, order_by: $orderBy) {
    ...PurchaseOrderFields
  }
}
    ${PurchaseOrderFieldsFragmentDoc}`;

/**
 * __useGetPurchaseOrdersQuery__
 *
 * To run a query within a React component, call `useGetPurchaseOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPurchaseOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPurchaseOrdersQuery({
 *   variables: {
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetPurchaseOrdersQuery(baseOptions: Apollo.QueryHookOptions<GetPurchaseOrdersQuery, GetPurchaseOrdersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPurchaseOrdersQuery, GetPurchaseOrdersQueryVariables>(GetPurchaseOrdersDocument, options);
      }
export function useGetPurchaseOrdersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPurchaseOrdersQuery, GetPurchaseOrdersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPurchaseOrdersQuery, GetPurchaseOrdersQueryVariables>(GetPurchaseOrdersDocument, options);
        }
export type GetPurchaseOrdersQueryHookResult = ReturnType<typeof useGetPurchaseOrdersQuery>;
export type GetPurchaseOrdersLazyQueryHookResult = ReturnType<typeof useGetPurchaseOrdersLazyQuery>;
export type GetPurchaseOrdersQueryResult = Apollo.QueryResult<GetPurchaseOrdersQuery, GetPurchaseOrdersQueryVariables>;