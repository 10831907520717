import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetRewardProgramByWorkspaceIdQueryVariables = Types.Exact<{
  workspaceId: Types.Scalars['uuid'];
}>;


export type GetRewardProgramByWorkspaceIdQuery = { __typename?: 'query_root', workspace_by_pk?: { __typename?: 'workspace', rewardProgram?: { __typename?: 'rewardProgram', id: any, termsAndConditions: string } | null | undefined } | null | undefined };


export const GetRewardProgramByWorkspaceIdDocument = gql`
    query getRewardProgramByWorkspaceId($workspaceId: uuid!) {
  workspace_by_pk(id: $workspaceId) {
    rewardProgram {
      id
      termsAndConditions
    }
  }
}
    `;

/**
 * __useGetRewardProgramByWorkspaceIdQuery__
 *
 * To run a query within a React component, call `useGetRewardProgramByWorkspaceIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRewardProgramByWorkspaceIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRewardProgramByWorkspaceIdQuery({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *   },
 * });
 */
export function useGetRewardProgramByWorkspaceIdQuery(baseOptions: Apollo.QueryHookOptions<GetRewardProgramByWorkspaceIdQuery, GetRewardProgramByWorkspaceIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRewardProgramByWorkspaceIdQuery, GetRewardProgramByWorkspaceIdQueryVariables>(GetRewardProgramByWorkspaceIdDocument, options);
      }
export function useGetRewardProgramByWorkspaceIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRewardProgramByWorkspaceIdQuery, GetRewardProgramByWorkspaceIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRewardProgramByWorkspaceIdQuery, GetRewardProgramByWorkspaceIdQueryVariables>(GetRewardProgramByWorkspaceIdDocument, options);
        }
export type GetRewardProgramByWorkspaceIdQueryHookResult = ReturnType<typeof useGetRewardProgramByWorkspaceIdQuery>;
export type GetRewardProgramByWorkspaceIdLazyQueryHookResult = ReturnType<typeof useGetRewardProgramByWorkspaceIdLazyQuery>;
export type GetRewardProgramByWorkspaceIdQueryResult = Apollo.QueryResult<GetRewardProgramByWorkspaceIdQuery, GetRewardProgramByWorkspaceIdQueryVariables>;