import { mergeAttributes, Node } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';

import Component from './Component';

export default Node.create({
  name: 'DynamicDropdown',

  group: 'block',

  atom: true,

  addOptions() {
    return {
      ...this.parent?.(),
      creatingTemplate: false,
    };
  },

  addAttributes() {
    return {
      name: {
        default: 'Dropdown Name',
      },
      label: {
        default: '',
      },
      showLabel: {
        default: false,
      },
      value: {
        default: 0,
      },
      options: {
        default: ['Option 1', 'Option 2', 'Option 3'],
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'dynamic-dropdown',
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['dynamic-dropdown', mergeAttributes(HTMLAttributes)];
  },

  addNodeView() {
    return ReactNodeViewRenderer(Component);
  },
});
