import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type ConvertFileToSurveyJsConsentMutationVariables = Types.Exact<{
  filePath: Types.Scalars['String'];
  consentId: Types.Scalars['uuid'];
}>;


export type ConvertFileToSurveyJsConsentMutation = { __typename?: 'mutation_root', convertFileToSurveyJSConsent?: { __typename?: 'SuccessAnswer', success: boolean } | null | undefined };


export const ConvertFileToSurveyJsConsentDocument = gql`
    mutation ConvertFileToSurveyJSConsent($filePath: String!, $consentId: uuid!) {
  convertFileToSurveyJSConsent(filePath: $filePath, consentId: $consentId) {
    success
  }
}
    `;
export type ConvertFileToSurveyJsConsentMutationFn = Apollo.MutationFunction<ConvertFileToSurveyJsConsentMutation, ConvertFileToSurveyJsConsentMutationVariables>;

/**
 * __useConvertFileToSurveyJsConsentMutation__
 *
 * To run a mutation, you first call `useConvertFileToSurveyJsConsentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConvertFileToSurveyJsConsentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [convertFileToSurveyJsConsentMutation, { data, loading, error }] = useConvertFileToSurveyJsConsentMutation({
 *   variables: {
 *      filePath: // value for 'filePath'
 *      consentId: // value for 'consentId'
 *   },
 * });
 */
export function useConvertFileToSurveyJsConsentMutation(baseOptions?: Apollo.MutationHookOptions<ConvertFileToSurveyJsConsentMutation, ConvertFileToSurveyJsConsentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ConvertFileToSurveyJsConsentMutation, ConvertFileToSurveyJsConsentMutationVariables>(ConvertFileToSurveyJsConsentDocument, options);
      }
export type ConvertFileToSurveyJsConsentMutationHookResult = ReturnType<typeof useConvertFileToSurveyJsConsentMutation>;
export type ConvertFileToSurveyJsConsentMutationResult = Apollo.MutationResult<ConvertFileToSurveyJsConsentMutation>;
export type ConvertFileToSurveyJsConsentMutationOptions = Apollo.BaseMutationOptions<ConvertFileToSurveyJsConsentMutation, ConvertFileToSurveyJsConsentMutationVariables>;