export function createInvoiceLedgerItemsDeprecated(invoice?: {
  balanceDue?: number;
  creditsApplied?: number;
  depositPaid?: number;
  discount?: number;
  gratuity?: number | null;
  preTaxTotal?: number;
  postTaxTotal?: number;
  status?: string;
  taxTotal?: number;
}) {
  const balanceDue = invoice?.balanceDue ?? 0;
  const creditsApplied = invoice?.creditsApplied ?? 0;
  const depositPaid = invoice?.depositPaid ?? 0;
  const gratuity = invoice?.gratuity ?? 0;
  const preTaxTotal = invoice?.preTaxTotal ?? 0;
  const postTaxTotal = invoice?.postTaxTotal ?? 0;
  const taxTotal = invoice?.taxTotal ?? 0;
  const discount = invoice?.discount ?? 0;
  const status = invoice?.status || '';

  const isDraftOrVoidInvoice = ['VOID', 'DRAFT', 'QUOTE'].includes(status);

  function shouldHideDeposit() {
    if (invoice?.status === 'REQUIRES_DEPOSIT') {
      return false;
    }

    if (invoice?.status === 'PAID_IN_FULL') {
      return true;
    }

    if (depositPaid > 0) {
      return false;
    }

    return true;
  }

  const ledgerItems = [
    {
      label: 'Discount',
      value: discount,
    },
    {
      label: 'Subtotal',
      value: preTaxTotal,
    },
    {
      label: 'Tax',
      value: taxTotal,
    },
    {
      label: 'Tip',
      shouldHide: gratuity === 0,
      value: gratuity,
    },
    {
      label: 'Total',
      value: postTaxTotal,
    },
    {
      label: 'Deposit Paid',
      shouldHide: shouldHideDeposit(),
      value: depositPaid,
    },
    {
      label: 'Credits Applied',
      shouldHide: isDraftOrVoidInvoice,
      value: creditsApplied,
    },
    {
      label: 'Amount Paid',
      shouldHide: isDraftOrVoidInvoice,
      value: postTaxTotal - balanceDue,
    },
    {
      label: 'Balance Due',
      shouldHide: isDraftOrVoidInvoice,
      value: balanceDue,
    },
  ];

  return ledgerItems.filter((ledgerItem) => !ledgerItem.shouldHide);
}

export default createInvoiceLedgerItemsDeprecated;
