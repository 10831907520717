import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type InsertDocumentTagMutationVariables = Types.Exact<{
  object: Types.DocumentTag_Insert_Input;
}>;


export type InsertDocumentTagMutation = { __typename?: 'mutation_root', insert_documentTag_one?: { __typename?: 'documentTag', id: any, tag: { __typename?: 'tag', color?: string | null | undefined, id: any, title: string } } | null | undefined };


export const InsertDocumentTagDocument = gql`
    mutation InsertDocumentTag($object: documentTag_insert_input!) {
  insert_documentTag_one(
    object: $object
    on_conflict: {constraint: documentTag_documentId_tagId_key, update_columns: []}
  ) {
    id
    tag {
      color
      id
      title
    }
  }
}
    `;
export type InsertDocumentTagMutationFn = Apollo.MutationFunction<InsertDocumentTagMutation, InsertDocumentTagMutationVariables>;

/**
 * __useInsertDocumentTagMutation__
 *
 * To run a mutation, you first call `useInsertDocumentTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertDocumentTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertDocumentTagMutation, { data, loading, error }] = useInsertDocumentTagMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertDocumentTagMutation(baseOptions?: Apollo.MutationHookOptions<InsertDocumentTagMutation, InsertDocumentTagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertDocumentTagMutation, InsertDocumentTagMutationVariables>(InsertDocumentTagDocument, options);
      }
export type InsertDocumentTagMutationHookResult = ReturnType<typeof useInsertDocumentTagMutation>;
export type InsertDocumentTagMutationResult = Apollo.MutationResult<InsertDocumentTagMutation>;
export type InsertDocumentTagMutationOptions = Apollo.BaseMutationOptions<InsertDocumentTagMutation, InsertDocumentTagMutationVariables>;