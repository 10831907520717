import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type InsertLeadTagMutationVariables = Types.Exact<{
  leadTag: Types.LeadTag_Insert_Input;
}>;


export type InsertLeadTagMutation = { __typename?: 'mutation_root', insert_leadTag?: { __typename?: 'leadTag_mutation_response', returning: Array<{ __typename?: 'leadTag', id: any, title: string }> } | null | undefined };


export const InsertLeadTagDocument = gql`
    mutation insertLeadTag($leadTag: leadTag_insert_input!) {
  insert_leadTag(
    on_conflict: {constraint: leadTag_workspaceId_title_key, update_columns: [updatedAt]}
    objects: [$leadTag]
  ) {
    returning {
      id
      title
    }
  }
}
    `;
export type InsertLeadTagMutationFn = Apollo.MutationFunction<InsertLeadTagMutation, InsertLeadTagMutationVariables>;

/**
 * __useInsertLeadTagMutation__
 *
 * To run a mutation, you first call `useInsertLeadTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertLeadTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertLeadTagMutation, { data, loading, error }] = useInsertLeadTagMutation({
 *   variables: {
 *      leadTag: // value for 'leadTag'
 *   },
 * });
 */
export function useInsertLeadTagMutation(baseOptions?: Apollo.MutationHookOptions<InsertLeadTagMutation, InsertLeadTagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertLeadTagMutation, InsertLeadTagMutationVariables>(InsertLeadTagDocument, options);
      }
export type InsertLeadTagMutationHookResult = ReturnType<typeof useInsertLeadTagMutation>;
export type InsertLeadTagMutationResult = Apollo.MutationResult<InsertLeadTagMutation>;
export type InsertLeadTagMutationOptions = Apollo.BaseMutationOptions<InsertLeadTagMutation, InsertLeadTagMutationVariables>;