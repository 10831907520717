/* eslint-disable camelcase */
import type { ICreatorOptions } from 'survey-creator-core';
import { Serializer, setLicenseKey } from 'survey-core';
import { SurveyCreator, SurveyCreatorComponent } from 'survey-creator-react';
import { useEffect, useState } from 'react';
import { RecurrenceType_Enum } from '@webapp/graphql';
import { useLoadServices } from '@webapp/webapp/hooks';
import { elementsAsComponentConfiguration } from '@webapp/constants';
import { Stack } from '@chakra-ui/react';
import configureComponents from './configureCustomFormBuilderComponents';

setLicenseKey(
  'MTMxYTY2ODMtNTQxMC00NWIzLTliMGMtZGMyN2RiMGUzMzgyOzE9MjAyNS0wMi0wOCwyPTIwMjUtMDItMDgsND0yMDI1LTAyLTA4'
);

interface CustomFormBuilderSurveyJSProps {
  schemaJSON?: Record<string, unknown>;
  onSave: (data: Record<string, unknown>) => Promise<unknown>;
}

const recurrences = [
  { text: 'Every Appointment', value: RecurrenceType_Enum.Always },
  { text: 'First Appointment Only', value: RecurrenceType_Enum.Never },
  { text: 'Annually', value: RecurrenceType_Enum.Annually },
  { text: 'First Service', value: RecurrenceType_Enum.FirstService },
];

const questionTypes = [
  // built-ins
  'text',
  'checkbox',
  'radiogroup',
  'dropdown',
  'comment',
  'rating',
  'matrix',
  'matricdropdown',
  'matricdynamic',
  'panel',
  'paneldynamic',
  'boolean',
  'image',
  'html',
  'expression',
  //   Simplify for users, let's hide these for now:
  //   'multipletext',
  // Fully custom components:
  'private-image-upload',
  'signaturepad',
  ...elementsAsComponentConfiguration.map(({ name }) => name),
];

// eslint-disable-next-line import/prefer-default-export
export function CustomFormBuilderSurveyJS({
  schemaJSON,
  onSave,
}: CustomFormBuilderSurveyJSProps) {
  const { search: searchServices } = useLoadServices();

  async function saveHandler(
    saveNo: number,
    callback: (saveNo: number, success: boolean) => void
  ) {
    try {
      if (onSave) await onSave(creator.JSON);

      callback(saveNo, true);
    } catch (error) {
      callback(saveNo, false);
    }
  }

  function configureSerializer() {
    Serializer.addProperty('survey', {
      name: 'formAlwaysRequired',
      displayName: 'Required regardless of services',
      category: 'general',
      type: 'boolean',
      default: false,
    });

    Serializer.addProperty('survey', {
      name: 'requiredServices',
      displayName: 'Required Services',
      category: 'general',
      type: 'multiplevalues',
      choices: (
        obj: unknown,
        callback: (choices: { text?: string; value: string | number }[]) => void
      ) => {
        if (!callback) return;

        searchServices('').then((services) => {
          const formattedServices = services.map(({ label, value }) => ({
            text: label,
            value,
          }));

          callback(formattedServices);
        });
      },
      visibleIf: (obj) => obj.formAlwaysRequired === false,
    });

    Serializer.addProperty('survey', {
      name: 'recurrence',
      displayName: 'Recurrence',
      category: 'general',
      choices: recurrences,
      default: RecurrenceType_Enum.Always,
    });
  }

  useEffect(() => {
    configureSerializer();

    return () => {
      Serializer.removeProperty('survey', 'formAlwaysRequired');
      Serializer.removeProperty('survey', 'requiredServices');
      Serializer.removeProperty('survey', 'recurrence');
    };
  }, []);

  const [creator] = useState(() => {
    configureComponents();

    const creatorOptions: ICreatorOptions = {
      showLogicTab: true,
      //   isAutoSave: true,
      haveCommercialLicense: true,
      //   showJSONEditorTab: false,
      questionTypes,
      JSON: schemaJSON,
    };

    const surveyCreator = new SurveyCreator(creatorOptions);

    surveyCreator.saveSurveyFunc = saveHandler;

    const dateFormatter = new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
    });

    // Set a default survey title so we can autosave easily

    surveyCreator.survey.title = `New Form (${dateFormatter.format(
      new Date()
    )})`;

    // When adding a question, set the title to the custom question title if it exists
    surveyCreator.onQuestionAdded.add((_, options) => {
      if (options.question.customQuestion?.json.name) {
        // eslint-disable-next-line no-param-reassign
        options.question.name = options.question.customQuestion?.json.name;
      }

      if (!options.question.customQuestion?.json?.title) return;

      // eslint-disable-next-line no-param-reassign
      options.question.title = options.question.customQuestion.json.title;
    });

    surveyCreator.onElementAllowOperations.add((_, options) => {
      // eslint-disable-next-line no-param-reassign
      options.allowChangeType = false;
    });

    return surveyCreator;
  });

  useEffect(() => {
    if (schemaJSON) creator.JSON = schemaJSON;
  }, [schemaJSON, creator]);

  return (
    <Stack w={'full'} h={'85vh'}>
      <SurveyCreatorComponent creator={creator} />
    </Stack>
  );
}
