import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type ListLeadFormEntriesSubscriptionVariables = Types.Exact<{
  where?: Types.Maybe<Types.LeadFormEntry_Bool_Exp>;
  orderBy?: Types.Maybe<Array<Types.LeadFormEntry_Order_By> | Types.LeadFormEntry_Order_By>;
}>;


export type ListLeadFormEntriesSubscription = { __typename?: 'subscription_root', leadFormEntry: Array<{ __typename?: 'leadFormEntry', id: any, createdAt: any, surveyJSData?: any | null | undefined, leadForm?: { __typename?: 'leadForm', id: any, name: string } | null | undefined, patient: { __typename?: 'patient', id: any, firstName: string, lastName: string, lastAppointment: Array<{ __typename?: 'appointment', id: any, startTime?: any | null | undefined, status?: string | null | undefined, appointmentServices: Array<{ __typename?: 'appointmentService', service: { __typename?: 'service', name: string } }> }>, nextAppointment: Array<{ __typename?: 'appointment', id: any, startTime?: any | null | undefined, status?: string | null | undefined, appointmentServices: Array<{ __typename?: 'appointmentService', service: { __typename?: 'service', name: string } }> }>, patientWorkspaces: Array<{ __typename?: 'patientWorkspace', firstName?: string | null | undefined, lastName?: string | null | undefined, leadStatus?: string | null | undefined, email?: string | null | undefined, phoneNumber?: string | null | undefined, source?: string | null | undefined }> }, leadFormFieldValues: Array<{ __typename?: 'leadFormFieldValue', value: string, leadFormField: { __typename?: 'leadFormField', label: string } }>, leadFormEntryTags: Array<{ __typename?: 'leadFormEntryTag', id: any, leadTag: { __typename?: 'leadTag', id: any, title: string } }> }> };


export const ListLeadFormEntriesDocument = gql`
    subscription ListLeadFormEntries($where: leadFormEntry_bool_exp, $orderBy: [leadFormEntry_order_by!]) {
  leadFormEntry(where: $where, order_by: $orderBy) {
    id
    createdAt
    leadForm {
      id
      name
    }
    surveyJSData
    patient {
      id
      firstName
      lastName
      lastAppointment: appointments(
        where: {startTime: {_lt: "now()"}}
        order_by: {startTime: desc}
        limit: 1
      ) {
        id
        startTime
        status
        appointmentServices {
          service {
            name
          }
        }
      }
      nextAppointment: appointments(
        where: {startTime: {_gte: "now()"}}
        order_by: {startTime: asc}
        limit: 1
      ) {
        id
        startTime
        status
        appointmentServices {
          service {
            name
          }
        }
      }
      patientWorkspaces {
        firstName
        lastName
        leadStatus
        email
        phoneNumber
        source
      }
    }
    leadFormFieldValues {
      value
      leadFormField {
        label
      }
    }
    leadFormEntryTags {
      id
      leadTag {
        id
        title
      }
    }
  }
}
    `;

/**
 * __useListLeadFormEntriesSubscription__
 *
 * To run a query within a React component, call `useListLeadFormEntriesSubscription` and pass it any options that fit your needs.
 * When your component renders, `useListLeadFormEntriesSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListLeadFormEntriesSubscription({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useListLeadFormEntriesSubscription(baseOptions?: Apollo.SubscriptionHookOptions<ListLeadFormEntriesSubscription, ListLeadFormEntriesSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<ListLeadFormEntriesSubscription, ListLeadFormEntriesSubscriptionVariables>(ListLeadFormEntriesDocument, options);
      }
export type ListLeadFormEntriesSubscriptionHookResult = ReturnType<typeof useListLeadFormEntriesSubscription>;
export type ListLeadFormEntriesSubscriptionResult = Apollo.SubscriptionResult<ListLeadFormEntriesSubscription>;