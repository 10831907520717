import './init';

import { useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import {
  BrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import { FlagsmithProvider } from 'flagsmith-react';
import * as Sentry from '@sentry/react';
import CustomErrorBoundary from './CustomErrorBoundary';
import { ApolloProviderWrapper } from './app/apollo-provider-wrapper/apollo-provider-wrapper';
import App from './app/app';
import ignoreErrorList from './ignoreErrorList';

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  ignoreErrors: ignoreErrorList,
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
      tracePropagationTargets: ['localhost', '*.prospyrmed.com', /^\//],
    }),
  ],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});

const root = createRoot(document.getElementById('root') as HTMLElement);

const params = new URL(document.location.href).searchParams;
const msq = params.get('msq');
if (!msq) {
  try {
    localStorage.removeItem(`prspr_msq_${import.meta.env.VITE_ENV}`);
  } catch (err) {
    console.error(err);
  }
}

root.render(
  <FlagsmithProvider
    api="https://edge.api.flagsmith.com/api/v1/"
    environmentId={import.meta.env.VITE_FLAGSMITH_ENVIRONMENT_ID as string}
  >
    <ApolloProviderWrapper {...(msq && { msq })}>
      <BrowserRouter>
        {import.meta.env.VITE_ENV === 'development' ? (
          <App />
        ) : (
          <CustomErrorBoundary
            Sentry={Sentry}
            fallback={<p>An error has occurred - please refresh the page.</p>}
          >
            <App />
          </CustomErrorBoundary>
        )}
      </BrowserRouter>
    </ApolloProviderWrapper>
  </FlagsmithProvider>
);
