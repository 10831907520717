import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type UpdateProvidersForServiceMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
  objects: Array<Types.ServiceProvider_Insert_Input> | Types.ServiceProvider_Insert_Input;
}>;


export type UpdateProvidersForServiceMutation = { __typename?: 'mutation_root', delete_serviceProvider?: { __typename?: 'serviceProvider_mutation_response', affected_rows: number } | null | undefined, insert_serviceProvider?: { __typename?: 'serviceProvider_mutation_response', affected_rows: number } | null | undefined };


export const UpdateProvidersForServiceDocument = gql`
    mutation updateProvidersForService($id: uuid!, $objects: [serviceProvider_insert_input!]!) {
  delete_serviceProvider(where: {serviceId: {_eq: $id}}) {
    affected_rows
  }
  insert_serviceProvider(objects: $objects) {
    affected_rows
  }
}
    `;
export type UpdateProvidersForServiceMutationFn = Apollo.MutationFunction<UpdateProvidersForServiceMutation, UpdateProvidersForServiceMutationVariables>;

/**
 * __useUpdateProvidersForServiceMutation__
 *
 * To run a mutation, you first call `useUpdateProvidersForServiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProvidersForServiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProvidersForServiceMutation, { data, loading, error }] = useUpdateProvidersForServiceMutation({
 *   variables: {
 *      id: // value for 'id'
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useUpdateProvidersForServiceMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProvidersForServiceMutation, UpdateProvidersForServiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProvidersForServiceMutation, UpdateProvidersForServiceMutationVariables>(UpdateProvidersForServiceDocument, options);
      }
export type UpdateProvidersForServiceMutationHookResult = ReturnType<typeof useUpdateProvidersForServiceMutation>;
export type UpdateProvidersForServiceMutationResult = Apollo.MutationResult<UpdateProvidersForServiceMutation>;
export type UpdateProvidersForServiceMutationOptions = Apollo.BaseMutationOptions<UpdateProvidersForServiceMutation, UpdateProvidersForServiceMutationVariables>;