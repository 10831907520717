import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { SimplifiedInventoryTransactionFieldsFragmentDoc } from '../../fragments/simplifiedInventoryTransactionFields.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetSimplifiedInventoryTransactionsQueryVariables = Types.Exact<{
  input: Types.GetSimplifiedInventoryTransactionsInput;
}>;


export type GetSimplifiedInventoryTransactionsQuery = { __typename?: 'query_root', getSimplifiedInventoryTransactions: { __typename?: 'GetSimplifiedInventoryTransactionsOutput', count: number, inventoryTransactions: Array<{ __typename?: 'SimplfiedInventoryTransaction', from: any, to: any, createdAt: string, quantity: number, note?: string | null | undefined, user: any, consumable: { __typename?: 'FullConsumable', id: any, originalQuantity: number, quantity: number, metadata: any, cost: number, isExpirable: boolean, expirationDate?: string | null | undefined, daysBeforeNotification?: number | null | undefined, shouldWarnQuantity: boolean, warningQuantity?: number | null | undefined, archived: boolean, createdAt: string, updatedAt: string, type: { __typename?: 'IdNameRequiredFields', id: any, name: string, requiredFields: any, unitStep: number, unitName: string, defaultNumberOfUnits: number, defaultPricePerUnit: number }, brand: { __typename?: 'IdName', id: any, name: string }, purchaseOrder?: { __typename?: 'IdOrderNumber', id: any, orderNumber: string } | null | undefined } }> } };


export const GetSimplifiedInventoryTransactionsDocument = gql`
    query GetSimplifiedInventoryTransactions($input: GetSimplifiedInventoryTransactionsInput!) {
  getSimplifiedInventoryTransactions(input: $input) {
    inventoryTransactions {
      ...SimplifiedInventoryTransactionFields
    }
    count
  }
}
    ${SimplifiedInventoryTransactionFieldsFragmentDoc}`;

/**
 * __useGetSimplifiedInventoryTransactionsQuery__
 *
 * To run a query within a React component, call `useGetSimplifiedInventoryTransactionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSimplifiedInventoryTransactionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSimplifiedInventoryTransactionsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetSimplifiedInventoryTransactionsQuery(baseOptions: Apollo.QueryHookOptions<GetSimplifiedInventoryTransactionsQuery, GetSimplifiedInventoryTransactionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSimplifiedInventoryTransactionsQuery, GetSimplifiedInventoryTransactionsQueryVariables>(GetSimplifiedInventoryTransactionsDocument, options);
      }
export function useGetSimplifiedInventoryTransactionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSimplifiedInventoryTransactionsQuery, GetSimplifiedInventoryTransactionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSimplifiedInventoryTransactionsQuery, GetSimplifiedInventoryTransactionsQueryVariables>(GetSimplifiedInventoryTransactionsDocument, options);
        }
export type GetSimplifiedInventoryTransactionsQueryHookResult = ReturnType<typeof useGetSimplifiedInventoryTransactionsQuery>;
export type GetSimplifiedInventoryTransactionsLazyQueryHookResult = ReturnType<typeof useGetSimplifiedInventoryTransactionsLazyQuery>;
export type GetSimplifiedInventoryTransactionsQueryResult = Apollo.QueryResult<GetSimplifiedInventoryTransactionsQuery, GetSimplifiedInventoryTransactionsQueryVariables>;