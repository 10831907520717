/* eslint-disable prefer-arrow-callback */
import { CheckCircleIcon } from '@chakra-ui/icons';
import { Heading, Flex } from '@chakra-ui/react';
import { LeadFormStep } from '@webapp/state-models';
/* eslint-disable-next-line */
export interface FormCompleteProps {
  step: LeadFormStep;
}

export const FormComplete = ({ step }: FormCompleteProps) => {
  return (
    <Flex justifyContent={'center'} alignItems={'center'} direction={'column'}>
      <CheckCircleIcon color="green.500" boxSize={20} />
      <Heading size="md" py={2} textAlign={'center'}>
        {step.subtitle || "We'll be in touch shortly!"}
      </Heading>
    </Flex>
  );
};

export default FormComplete;
