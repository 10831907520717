import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetWorkspaceQueryVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
}>;


export type GetWorkspaceQuery = { __typename?: 'query_root', workspace_by_pk?: { __typename?: 'workspace', finixMerchantId?: string | null | undefined, id: any, logo?: string | null | undefined, name: string, slug?: string | null | undefined, domain?: string | null | undefined, payrixMerchantId?: string | null | undefined, workspaceConfiguration?: { __typename?: 'workspaceConfiguration', id: any, giftCardOptions?: any | null | undefined, ipWhitelist?: any | null | undefined, ipWhitelistEnabled?: boolean | null | undefined, storefrontConfiguration?: any | null | undefined, theme?: any | null | undefined, cloudflareCustomHostId?: string | null | undefined } | null | undefined } | null | undefined };


export const GetWorkspaceDocument = gql`
    query GetWorkspace($id: uuid!) {
  workspace_by_pk(id: $id) {
    finixMerchantId
    id
    logo
    name
    slug
    domain
    payrixMerchantId
    workspaceConfiguration {
      id
      giftCardOptions
      ipWhitelist
      ipWhitelistEnabled
      storefrontConfiguration
      theme
      cloudflareCustomHostId
    }
  }
}
    `;

/**
 * __useGetWorkspaceQuery__
 *
 * To run a query within a React component, call `useGetWorkspaceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkspaceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkspaceQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetWorkspaceQuery(baseOptions: Apollo.QueryHookOptions<GetWorkspaceQuery, GetWorkspaceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWorkspaceQuery, GetWorkspaceQueryVariables>(GetWorkspaceDocument, options);
      }
export function useGetWorkspaceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWorkspaceQuery, GetWorkspaceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWorkspaceQuery, GetWorkspaceQueryVariables>(GetWorkspaceDocument, options);
        }
export type GetWorkspaceQueryHookResult = ReturnType<typeof useGetWorkspaceQuery>;
export type GetWorkspaceLazyQueryHookResult = ReturnType<typeof useGetWorkspaceLazyQuery>;
export type GetWorkspaceQueryResult = Apollo.QueryResult<GetWorkspaceQuery, GetWorkspaceQueryVariables>;