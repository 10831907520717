import * as Types from '../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type ListLeadFormsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ListLeadFormsQuery = { __typename?: 'query_root', leadForm: Array<{ __typename?: 'leadForm', id: any, name: string, steps: Array<{ __typename?: 'leadFormStep', id: any, fields: Array<{ __typename?: 'leadFormField', id: any }> }> }> };


export const ListLeadFormsDocument = gql`
    query listLeadForms {
  leadForm(where: {isArchived: {_eq: false}}) {
    id
    name
    steps: leadFormSteps {
      id
      fields: leadFormFields {
        id
      }
    }
  }
}
    `;

/**
 * __useListLeadFormsQuery__
 *
 * To run a query within a React component, call `useListLeadFormsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListLeadFormsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListLeadFormsQuery({
 *   variables: {
 *   },
 * });
 */
export function useListLeadFormsQuery(baseOptions?: Apollo.QueryHookOptions<ListLeadFormsQuery, ListLeadFormsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListLeadFormsQuery, ListLeadFormsQueryVariables>(ListLeadFormsDocument, options);
      }
export function useListLeadFormsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListLeadFormsQuery, ListLeadFormsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListLeadFormsQuery, ListLeadFormsQueryVariables>(ListLeadFormsDocument, options);
        }
export type ListLeadFormsQueryHookResult = ReturnType<typeof useListLeadFormsQuery>;
export type ListLeadFormsLazyQueryHookResult = ReturnType<typeof useListLeadFormsLazyQuery>;
export type ListLeadFormsQueryResult = Apollo.QueryResult<ListLeadFormsQuery, ListLeadFormsQueryVariables>;