import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { ConsumableTypeFieldsFragmentDoc } from '../../fragments/consumableTypeFields.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type SearchConsumableTypesQueryVariables = Types.Exact<{
  search: Types.Scalars['String'];
  workspaceId: Types.Scalars['uuid'];
}>;


export type SearchConsumableTypesQuery = { __typename?: 'query_root', searchConsumableTypes: Array<{ __typename?: 'consumableType', id: any, name: string, requiredFields: any, defaultNumberOfUnits: any, defaultPricePerUnit: number, unitStep: any, unitName: string, createdAt: any, updatedAt: any, archived: boolean, productBarcodeId?: string | null | undefined, consumableTypeConsumableSubtypes: Array<{ __typename?: 'consumableTypeConsumableSubtype', id: any, consumableSubtype: { __typename?: 'consumableSubtype', id: any, name: string } }> }> };


export const SearchConsumableTypesDocument = gql`
    query SearchConsumableTypes($search: String!, $workspaceId: uuid!) {
  searchConsumableTypes(args: {search: $search, workspace_id: $workspaceId}) {
    ...ConsumableTypeFields
  }
}
    ${ConsumableTypeFieldsFragmentDoc}`;

/**
 * __useSearchConsumableTypesQuery__
 *
 * To run a query within a React component, call `useSearchConsumableTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchConsumableTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchConsumableTypesQuery({
 *   variables: {
 *      search: // value for 'search'
 *      workspaceId: // value for 'workspaceId'
 *   },
 * });
 */
export function useSearchConsumableTypesQuery(baseOptions: Apollo.QueryHookOptions<SearchConsumableTypesQuery, SearchConsumableTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchConsumableTypesQuery, SearchConsumableTypesQueryVariables>(SearchConsumableTypesDocument, options);
      }
export function useSearchConsumableTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchConsumableTypesQuery, SearchConsumableTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchConsumableTypesQuery, SearchConsumableTypesQueryVariables>(SearchConsumableTypesDocument, options);
        }
export type SearchConsumableTypesQueryHookResult = ReturnType<typeof useSearchConsumableTypesQuery>;
export type SearchConsumableTypesLazyQueryHookResult = ReturnType<typeof useSearchConsumableTypesLazyQuery>;
export type SearchConsumableTypesQueryResult = Apollo.QueryResult<SearchConsumableTypesQuery, SearchConsumableTypesQueryVariables>;