import * as Types from '../../generated/graphql';

import { gql } from '@apollo/client';
export type ConsumableBrandFieldsFragment = { __typename?: 'consumableBrand', id: any, name: string, contactName?: string | null | undefined, contactEmail?: string | null | undefined, contactPhoneNumber?: string | null | undefined, note?: string | null | undefined, createdAt: any, updatedAt: any, archived: boolean };

export const ConsumableBrandFieldsFragmentDoc = gql`
    fragment ConsumableBrandFields on consumableBrand {
  id
  name
  contactName
  contactEmail
  contactPhoneNumber
  note
  createdAt
  updatedAt
  archived
}
    `;