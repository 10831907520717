import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { PaymentFieldsFragmentDoc } from '../../fragments/paymentFields.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type InsertPaymentMutationVariables = Types.Exact<{
  payment: Types.Payment_Insert_Input;
}>;


export type InsertPaymentMutation = { __typename?: 'mutation_root', insert_payment?: { __typename?: 'payment_mutation_response', returning: Array<{ __typename?: 'payment', createdAt: any, id: any, workspaceId: any, paymentMethodId?: any | null | undefined, paymentMethodType: string, payrixType?: string | null | undefined, amount: number, invoiceId?: any | null | undefined, status: string, type?: string | null | undefined }> } | null | undefined };


export const InsertPaymentDocument = gql`
    mutation insertPayment($payment: payment_insert_input!) {
  insert_payment(objects: [$payment]) {
    returning {
      ...PaymentFields
    }
  }
}
    ${PaymentFieldsFragmentDoc}`;
export type InsertPaymentMutationFn = Apollo.MutationFunction<InsertPaymentMutation, InsertPaymentMutationVariables>;

/**
 * __useInsertPaymentMutation__
 *
 * To run a mutation, you first call `useInsertPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertPaymentMutation, { data, loading, error }] = useInsertPaymentMutation({
 *   variables: {
 *      payment: // value for 'payment'
 *   },
 * });
 */
export function useInsertPaymentMutation(baseOptions?: Apollo.MutationHookOptions<InsertPaymentMutation, InsertPaymentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertPaymentMutation, InsertPaymentMutationVariables>(InsertPaymentDocument, options);
      }
export type InsertPaymentMutationHookResult = ReturnType<typeof useInsertPaymentMutation>;
export type InsertPaymentMutationResult = Apollo.MutationResult<InsertPaymentMutation>;
export type InsertPaymentMutationOptions = Apollo.BaseMutationOptions<InsertPaymentMutation, InsertPaymentMutationVariables>;