import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type RetractInvoicePackageMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
}>;


export type RetractInvoicePackageMutation = { __typename?: 'mutation_root', retractInvoicePackage: { __typename?: 'SuccessAnswer', success: boolean } };


export const RetractInvoicePackageDocument = gql`
    mutation RetractInvoicePackage($id: uuid!) {
  retractInvoicePackage(id: $id) {
    success
  }
}
    `;
export type RetractInvoicePackageMutationFn = Apollo.MutationFunction<RetractInvoicePackageMutation, RetractInvoicePackageMutationVariables>;

/**
 * __useRetractInvoicePackageMutation__
 *
 * To run a mutation, you first call `useRetractInvoicePackageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRetractInvoicePackageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [retractInvoicePackageMutation, { data, loading, error }] = useRetractInvoicePackageMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRetractInvoicePackageMutation(baseOptions?: Apollo.MutationHookOptions<RetractInvoicePackageMutation, RetractInvoicePackageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RetractInvoicePackageMutation, RetractInvoicePackageMutationVariables>(RetractInvoicePackageDocument, options);
      }
export type RetractInvoicePackageMutationHookResult = ReturnType<typeof useRetractInvoicePackageMutation>;
export type RetractInvoicePackageMutationResult = Apollo.MutationResult<RetractInvoicePackageMutation>;
export type RetractInvoicePackageMutationOptions = Apollo.BaseMutationOptions<RetractInvoicePackageMutation, RetractInvoicePackageMutationVariables>;