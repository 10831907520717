import * as Types from '../../generated/graphql';

import { gql } from '@apollo/client';
export type PaymentMethodFieldsFragment = { __typename?: 'paymentMethod', id: any, patientId?: any | null | undefined, workspaceId: any, token: string, payrixCustomerId?: string | null | undefined, last4: string, createdAt: any, default: boolean };

export const PaymentMethodFieldsFragmentDoc = gql`
    fragment PaymentMethodFields on paymentMethod {
  id
  patientId
  workspaceId
  token
  payrixCustomerId
  last4
  createdAt
  default
}
    `;