import { useInsertTagMutation, useTagsLazyQuery } from '@webapp/graphql';
import { stringToColor } from '@webapp/webapp/util-display';

export function useLoadDocumentTags() {
  const [insertTag] = useInsertTagMutation();
  const [getDocumentTags] = useTagsLazyQuery();

  return {
    onCreateOption: async (value: string) => {
      const { data } = await insertTag({
        variables: {
          object: {
            title: value,
            type: 'DOCUMENT',
          },
        },
      });

      const tag = data?.insert_tag_one;

      if (tag) {
        return {
          label: tag.title,
          object: tag,
          value: tag.id,
        };
      }

      return undefined;
    },
    search: async (searchQuery: string) => {
      const { data } = await getDocumentTags({
        variables: {
          where: {
            title: {
              _ilike: `%${searchQuery}%`,
            },
            type: {
              _eq: 'DOCUMENT',
            },
          },
        },
      });

      const tagOptions = (data?.tag || []).map((tag) => ({
        label: tag.title,
        value: tag.id,
      }));

      return tagOptions;
    },
  };
}

export default useLoadDocumentTags;
