import * as Types from '../../generated/graphql';

import { gql } from '@apollo/client';
export type FullConsumableFieldsFragment = { __typename?: 'FullConsumable', id: any, originalQuantity: number, quantity: number, metadata: any, cost: number, isExpirable: boolean, expirationDate?: string | null | undefined, daysBeforeNotification?: number | null | undefined, shouldWarnQuantity: boolean, warningQuantity?: number | null | undefined, archived: boolean, createdAt: string, updatedAt: string, type: { __typename?: 'IdNameRequiredFields', id: any, name: string, requiredFields: any, unitStep: number, unitName: string, defaultNumberOfUnits: number, defaultPricePerUnit: number }, brand: { __typename?: 'IdName', id: any, name: string }, purchaseOrder?: { __typename?: 'IdOrderNumber', id: any, orderNumber: string } | null | undefined };

export const FullConsumableFieldsFragmentDoc = gql`
    fragment FullConsumableFields on FullConsumable {
  id
  originalQuantity
  quantity
  type {
    id
    name
    requiredFields
    unitStep
    unitName
    defaultNumberOfUnits
    defaultPricePerUnit
  }
  brand {
    id
    name
  }
  metadata
  cost
  isExpirable
  expirationDate
  daysBeforeNotification
  shouldWarnQuantity
  warningQuantity
  purchaseOrder {
    id
    orderNumber
  }
  archived
  createdAt
  updatedAt
}
    `;