import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type DownloadImageQueryVariables = Types.Exact<{
  photoId: Types.Scalars['uuid'];
  top: Types.Scalars['Float'];
  left: Types.Scalars['Float'];
  rotation: Types.Scalars['Float'];
  height: Types.Scalars['Float'];
  width: Types.Scalars['Float'];
}>;


export type DownloadImageQuery = { __typename?: 'query_root', downloadImage?: { __typename?: 'DownloadImageOutput', image?: string | null | undefined } | null | undefined };


export const DownloadImageDocument = gql`
    query downloadImage($photoId: uuid!, $top: Float!, $left: Float!, $rotation: Float!, $height: Float!, $width: Float!) {
  downloadImage(
    input: {photoId: $photoId, top: $top, left: $left, rotation: $rotation, height: $height, width: $width}
  ) {
    image
  }
}
    `;

/**
 * __useDownloadImageQuery__
 *
 * To run a query within a React component, call `useDownloadImageQuery` and pass it any options that fit your needs.
 * When your component renders, `useDownloadImageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDownloadImageQuery({
 *   variables: {
 *      photoId: // value for 'photoId'
 *      top: // value for 'top'
 *      left: // value for 'left'
 *      rotation: // value for 'rotation'
 *      height: // value for 'height'
 *      width: // value for 'width'
 *   },
 * });
 */
export function useDownloadImageQuery(baseOptions: Apollo.QueryHookOptions<DownloadImageQuery, DownloadImageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DownloadImageQuery, DownloadImageQueryVariables>(DownloadImageDocument, options);
      }
export function useDownloadImageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DownloadImageQuery, DownloadImageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DownloadImageQuery, DownloadImageQueryVariables>(DownloadImageDocument, options);
        }
export type DownloadImageQueryHookResult = ReturnType<typeof useDownloadImageQuery>;
export type DownloadImageLazyQueryHookResult = ReturnType<typeof useDownloadImageLazyQuery>;
export type DownloadImageQueryResult = Apollo.QueryResult<DownloadImageQuery, DownloadImageQueryVariables>;