import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type InsertMarketingTagMutationVariables = Types.Exact<{
  marketingTag: Types.MarketingTag_Insert_Input;
}>;


export type InsertMarketingTagMutation = { __typename?: 'mutation_root', insert_marketingTag?: { __typename?: 'marketingTag_mutation_response', returning: Array<{ __typename?: 'marketingTag', id: any }> } | null | undefined };


export const InsertMarketingTagDocument = gql`
    mutation insertMarketingTag($marketingTag: marketingTag_insert_input!) {
  insert_marketingTag(objects: [$marketingTag]) {
    returning {
      id
    }
  }
}
    `;
export type InsertMarketingTagMutationFn = Apollo.MutationFunction<InsertMarketingTagMutation, InsertMarketingTagMutationVariables>;

/**
 * __useInsertMarketingTagMutation__
 *
 * To run a mutation, you first call `useInsertMarketingTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertMarketingTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertMarketingTagMutation, { data, loading, error }] = useInsertMarketingTagMutation({
 *   variables: {
 *      marketingTag: // value for 'marketingTag'
 *   },
 * });
 */
export function useInsertMarketingTagMutation(baseOptions?: Apollo.MutationHookOptions<InsertMarketingTagMutation, InsertMarketingTagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertMarketingTagMutation, InsertMarketingTagMutationVariables>(InsertMarketingTagDocument, options);
      }
export type InsertMarketingTagMutationHookResult = ReturnType<typeof useInsertMarketingTagMutation>;
export type InsertMarketingTagMutationResult = Apollo.MutationResult<InsertMarketingTagMutation>;
export type InsertMarketingTagMutationOptions = Apollo.BaseMutationOptions<InsertMarketingTagMutation, InsertMarketingTagMutationVariables>;