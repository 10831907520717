export default function GhostIcon() {
  return (
    <svg
      width="62"
      height="62"
      viewBox="0 0 62 62"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M52.45 0H23.55C21.018 0.00264716 18.5905 1.00966 16.8001 2.80005C15.0097 4.59045 14.0026 7.01799 14 9.55V14H9.55C7.01799 14.0026 4.59045 15.0097 2.80005 16.8001C1.00966 18.5905 0.00264716 21.018 0 23.55V52.45C0.00264716 54.982 1.00966 57.4095 2.80005 59.1999C4.59045 60.9903 7.01799 61.9974 9.55 62H38.45C40.982 61.9974 43.4095 60.9903 45.1999 59.1999C46.9903 57.4095 47.9974 54.982 48 52.45V48H52.45C54.982 47.9974 57.4095 46.9903 59.1999 45.1999C60.9903 43.4095 61.9974 40.982 62 38.45V9.55C61.9974 7.01799 60.9903 4.59045 59.1999 2.80005C57.4095 1.00966 54.982 0.00264716 52.45 0ZM42 52.45C41.9974 53.3907 41.6225 54.2921 40.9573 54.9573C40.2921 55.6225 39.3907 55.9974 38.45 56H9.55C8.60929 55.9974 7.70787 55.6225 7.04269 54.9573C6.3775 54.2921 6.00264 53.3907 6 52.45V23.55C6.00264 22.6093 6.3775 21.7079 7.04269 21.0427C7.70787 20.3775 8.60929 20.0026 9.55 20H14V38.45C14.0026 40.982 15.0097 43.4095 16.8001 45.1999C18.5905 46.9903 21.018 47.9974 23.55 48H42V52.45ZM42 42H33.24L42 33.24V42ZM42 24.76L24.76 42H23.55C22.6093 41.9974 21.7079 41.6225 21.0427 40.9573C20.3775 40.2921 20.0026 39.3907 20 38.45V38.24L38.24 20H38.45C39.3907 20.0026 40.2921 20.3775 40.9573 21.0427C41.6225 21.7079 41.9974 22.6093 42 23.55V24.76ZM20 29.76V20H29.76L20 29.76ZM56 38.45C55.9974 39.3907 55.6225 40.2921 54.9573 40.9573C54.2921 41.6225 53.3907 41.9974 52.45 42H48V23.55C47.9974 21.018 46.9903 18.5905 45.1999 16.8001C43.4095 15.0097 40.982 14.0026 38.45 14H20V9.55C20.0026 8.60929 20.3775 7.70787 21.0427 7.04269C21.7079 6.3775 22.6093 6.00264 23.55 6H52.45C53.3907 6.00264 54.2921 6.3775 54.9573 7.04269C55.6225 7.70787 55.9974 8.60929 56 9.55V38.45Z"
        fill="black"
      />
    </svg>
  );
}
