import './editable-input.module.scss';
import {
  EditablePreview,
  useColorModeValue,
  IconButton,
  Input,
  useEditableControls,
  ButtonGroup,
  Editable,
  Tooltip,
  EditableInput,
  HStack,
} from '@chakra-ui/react';
import { CheckIcon, CloseIcon } from '@chakra-ui/icons';
import { useState } from 'react';

/* eslint-disable-next-line */
export interface CustomEditableInputProps {
  defaultValue: string | undefined;
  onChange?: (e: string | undefined) => void;
  type: 'text' | 'number' | 'date' | 'email' | 'tel';
}

export function CustomEditableInput({
  defaultValue,
  onChange,
  type = 'text',
}: CustomEditableInputProps) {
  const [value, setValue] = useState(defaultValue);

  /* Here's a custom control */
  function EditableControls() {
    const { isEditing, getSubmitButtonProps, getCancelButtonProps } =
      useEditableControls();

    return isEditing ? (
      <ButtonGroup justifyContent="start" size="sm" w="full" spacing={2} mt={2}>
        {/* @ts-expect-error some chakra prop mismatch */}
        <IconButton
          icon={<CheckIcon />}
          {...getSubmitButtonProps()}
          onClick={() => (onChange ? onChange(value) : null)}
        />
        {/* @ts-expect-error some chakra prop mismatch */}
        <IconButton
          icon={<CloseIcon boxSize={3} />}
          {...getCancelButtonProps()}
        />
      </ButtonGroup>
    ) : null;
  }

  return (
    <Editable
      value={value}
      isPreviewFocusable={true}
      selectAllOnFocus={false}
      onChange={setValue}
      onSubmit={() => (onChange ? onChange(value) : null)}
    >
      <Tooltip label="Click to edit">
        <EditablePreview
          py={2}
          px={4}
          _hover={{
            background: useColorModeValue('gray.100', 'gray.700'),
          }}
        />
      </Tooltip>
      <HStack>
        <Input py={2} px={4} as={EditableInput} type={type} />
        <EditableControls />
      </HStack>
    </Editable>
  );
}

export default CustomEditableInput;
