import {
  Button,
  ButtonGroup,
  Flex,
  Icon,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import {
  HiChevronDoubleLeft,
  HiChevronDoubleRight,
  HiChevronLeft,
  HiChevronRight,
} from 'react-icons/hi';
import { useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Select, {
  ActionMeta,
  GroupBase,
  MultiValue,
  SingleValue,
  StylesConfig,
} from 'react-select';
import { SelectOption } from '@webapp/types';
import PaginationUtil from './pagination-util';
import isMultiValue from '../../util/isMultiValue';
import './pagination.module.scss';

export interface PaginationProps {
  count?: number;
  pageNeighbors?: number;
  perPageLimit?: number;
  shouldDisplayPages?: boolean;
}

const LEFT_PAGE = 'LEFT_PAGE';
const RIGHT_PAGE = 'RIGHT_PAGE';

const PAGE_SIZE_DROPDOWN_STYLE: StylesConfig<
  SelectOption,
  false,
  GroupBase<SelectOption>
> = {
  control: () => ({
    border: 0,
    display: 'flex',
    flexDirection: 'row',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  singleValue: (provided) => ({
    ...provided,
    color: '#718096',
    fontSize: '16px',
    fontWeight: 'bold',
  }),
  valueContainer: (provided) => ({
    ...provided,
    paddingRight: 0,
  }),
};

export function Pagination({
  count = 0,
  pageNeighbors = 2,
  perPageLimit = 10,
  shouldDisplayPages = false,
}: PaginationProps) {
  const location = useLocation();
  const navigate = useNavigate();
  const currentParams = new URLSearchParams(location.search);

  const currentPageSize: string =
    currentParams.get('pageSize') ?? perPageLimit.toString();

  const currentPageSizeInt: number = parseInt(currentPageSize ?? 0, 10);

  const handleClick = (page: number) => () => {
    const lastPage = Math.ceil(count / currentPageSizeInt);

    if (page > lastPage || page < 1) {
      return;
    }

    currentParams.delete('page');
    currentParams.append('page', page.toString());

    navigate({
      search: currentParams.toString(),
    });
  };

  const currentPage = parseInt(currentParams.get('page') ?? '1', 10);

  const pagination = useMemo(
    () =>
      new PaginationUtil({
        count,
        currentPage,
        pageNeighbors,
        perPageLimit: currentPageSizeInt,
      }),
    [count, currentPage, currentPageSizeInt, pageNeighbors]
  );

  const pages = pagination.genPages();

  function handlePageChange(
    newValue: SingleValue<SelectOption> | MultiValue<SelectOption>,
    actionMeta: ActionMeta<SelectOption>
  ) {
    if (isMultiValue(newValue)) {
      return;
    }

    switch (actionMeta.action) {
      case 'select-option': {
        currentParams.set('pageSize', newValue?.value ?? '');

        const newValueInt = parseInt(newValue?.value ?? '0', 10);

        if (count / newValueInt < 1 || newValueInt * currentPage > count) {
          currentParams.set('page', '1');
        }

        navigate({
          search: currentParams.toString(),
        });

        break;
      }

      default: {
        break;
      }
    }
  }

  return (
    <Flex
      align="center"
      background="#FBFAFA"
      borderRadius="8px"
      padding="5px"
      justifyContent="end"
      width="100%"
    >
      <Select
        isSearchable={false}
        styles={PAGE_SIZE_DROPDOWN_STYLE}
        defaultValue={{
          label: currentPageSize,
          value: currentPageSize,
        }}
        onChange={handlePageChange}
        options={[
          {
            label: '10',
            value: '10',
          },
          {
            label: '25',
            value: '25',
          },
          {
            label: '50',
            value: '50',
          },
          {
            label: '100',
            value: '100',
          },
        ]}
      />
      {Boolean(pages.length) && shouldDisplayPages && (
        <ButtonGroup size="sm" variant="outline">
          {pages.map((page) => {
            if (typeof page === 'number') {
              return (
                <Button
                  {...(currentPage === page && {
                    colorScheme: 'blue',
                    variant: 'solid',
                  })}
                  onClick={handleClick(page)}
                  key={`page-${page}`}
                >
                  {page}
                </Button>
              );
            }

            if (page === LEFT_PAGE) {
              return (
                <Button
                  key={`page-${page}`}
                  onClick={handleClick(currentPage - 1)}
                >
                  <Icon as={HiChevronDoubleLeft} color="black" />
                </Button>
              );
            }

            if (page === RIGHT_PAGE) {
              return (
                <Button
                  key={`page-${page}`}
                  onClick={handleClick(currentPage + 1)}
                >
                  <Icon as={HiChevronDoubleRight} color="black" />
                </Button>
              );
            }

            return undefined;
          })}
        </ButtonGroup>
      )}
      <Text
        color={useColorModeValue('gray.600', 'gray.400')}
        data-testid="pagination-count"
        fontSize="16px"
        marginLeft="20px"
      >
        {count > 0 &&
          `${Math.min(currentPage * currentPageSizeInt, count)} of ${count}`}
        {count <= 0 && 'No Results'}
      </Text>
      {!shouldDisplayPages && (
        <ButtonGroup marginLeft="15px" size="sm" variant="unstyled">
          <Button
            data-testid="prev-button"
            disabled={currentPage === 1}
            display="flex"
            justifyContent="center"
            onClick={handleClick(currentPage - 1)}
            rel="prev"
          >
            <HiChevronLeft color="black" size="20px" />
          </Button>
          <Button
            data-testid="next-button"
            disabled={currentPage * currentPageSizeInt >= count}
            justifyContent="center"
            display="flex"
            onClick={handleClick(currentPage + 1)}
            rel="next"
          >
            <HiChevronRight color="black" size="20px" />
          </Button>
        </ButtonGroup>
      )}
    </Flex>
  );
}

export default Pagination;
