import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { PatientAddressFieldsFragmentDoc } from '../../fragments/patientAddressFields.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type InsertPatientAddressMutationVariables = Types.Exact<{
  patientAddress: Types.PatientAddress_Insert_Input;
}>;


export type InsertPatientAddressMutation = { __typename?: 'mutation_root', insert_patientAddress?: { __typename?: 'patientAddress_mutation_response', returning: Array<{ __typename?: 'patientAddress', id: any, isDefault: boolean, type: string, status: string, address: { __typename?: 'address', addressLine1: string, addressLine2?: string | null | undefined, city: string, id: any, state: string, zipCode: string } }> } | null | undefined };


export const InsertPatientAddressDocument = gql`
    mutation InsertPatientAddress($patientAddress: patientAddress_insert_input!) {
  insert_patientAddress(objects: [$patientAddress]) {
    returning {
      ...PatientAddressFields
    }
  }
}
    ${PatientAddressFieldsFragmentDoc}`;
export type InsertPatientAddressMutationFn = Apollo.MutationFunction<InsertPatientAddressMutation, InsertPatientAddressMutationVariables>;

/**
 * __useInsertPatientAddressMutation__
 *
 * To run a mutation, you first call `useInsertPatientAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertPatientAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertPatientAddressMutation, { data, loading, error }] = useInsertPatientAddressMutation({
 *   variables: {
 *      patientAddress: // value for 'patientAddress'
 *   },
 * });
 */
export function useInsertPatientAddressMutation(baseOptions?: Apollo.MutationHookOptions<InsertPatientAddressMutation, InsertPatientAddressMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertPatientAddressMutation, InsertPatientAddressMutationVariables>(InsertPatientAddressDocument, options);
      }
export type InsertPatientAddressMutationHookResult = ReturnType<typeof useInsertPatientAddressMutation>;
export type InsertPatientAddressMutationResult = Apollo.MutationResult<InsertPatientAddressMutation>;
export type InsertPatientAddressMutationOptions = Apollo.BaseMutationOptions<InsertPatientAddressMutation, InsertPatientAddressMutationVariables>;