import { HStack, Input, Text } from '@chakra-ui/react';
import dayjs from 'dayjs';
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import VitalsCellIconButton from '../VitalsCellIconButton/VitalsCellIconButton';

interface TimePickerCellEditorProps {
  value: string;
  unit?: boolean;
  isFloatEditor?: boolean;
  stopEditing?: () => void;
}
export const TimePickerCellEditor = forwardRef(
  (
    { unit, value: incomingValue, stopEditing }: TimePickerCellEditorProps,
    ref
  ) => {
    const [value, setValue] = useState(() => {
      // TODO: Determine cleaner way to handle this
      // Collects Hours and Minutes without needing to account for offset
      if (incomingValue?.includes('T')) {
        const [_, time] = incomingValue.split('T');
        const [h, m] = time.slice(0, 5).split(':');
        return dayjs()
          .set('hour', parseFloat(h))
          .set('minute', parseFloat(m))
          .format('HH:mm');
      }
      return dayjs().format('HH:mm');
    });

    const refInput = useRef<any>(null);

    useEffect(() => {
      refInput?.current?.focus();
    }, []);
    useImperativeHandle(ref, () => ({
      getValue() {
        return value;
      },
      isCancelBeforeStart() {
        return false;
      },
    }));
    function handleChange(e) {
      const { value } = e.target;
      setValue(value);
    }
    return (
      <HStack spacing={2} p={1}>
        <Input
          ref={refInput}
          id="appt-time"
          type="time"
          name="appt-time"
          maxHeight="38px"
          maxWidth="200px"
          onChange={handleChange}
          value={value}
        />
        {unit && <Text>{unit}</Text>}
        {stopEditing && <VitalsCellIconButton clickHandler={stopEditing} />}
      </HStack>
    );
  }
);

export default TimePickerCellEditor;
