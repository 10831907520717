import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { LeadTrackedEventFieldsFragmentDoc } from '../../fragments/leadPhoneCallFields.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type InsertLeadTrackedEventMutationVariables = Types.Exact<{
  leadTrackedEvent: Types.LeadTrackedEvent_Insert_Input;
}>;


export type InsertLeadTrackedEventMutation = { __typename?: 'mutation_root', insert_leadTrackedEvent?: { __typename?: 'leadTrackedEvent_mutation_response', returning: Array<{ __typename?: 'leadTrackedEvent', id: any, outcome: string, type?: string | null | undefined, createdAt: any, updatedAt: any, user?: { __typename?: 'user', firstName: string, lastName: string } | null | undefined }> } | null | undefined };


export const InsertLeadTrackedEventDocument = gql`
    mutation insertLeadTrackedEvent($leadTrackedEvent: leadTrackedEvent_insert_input!) {
  insert_leadTrackedEvent(objects: [$leadTrackedEvent]) {
    returning {
      ...LeadTrackedEventFields
    }
  }
}
    ${LeadTrackedEventFieldsFragmentDoc}`;
export type InsertLeadTrackedEventMutationFn = Apollo.MutationFunction<InsertLeadTrackedEventMutation, InsertLeadTrackedEventMutationVariables>;

/**
 * __useInsertLeadTrackedEventMutation__
 *
 * To run a mutation, you first call `useInsertLeadTrackedEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertLeadTrackedEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertLeadTrackedEventMutation, { data, loading, error }] = useInsertLeadTrackedEventMutation({
 *   variables: {
 *      leadTrackedEvent: // value for 'leadTrackedEvent'
 *   },
 * });
 */
export function useInsertLeadTrackedEventMutation(baseOptions?: Apollo.MutationHookOptions<InsertLeadTrackedEventMutation, InsertLeadTrackedEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertLeadTrackedEventMutation, InsertLeadTrackedEventMutationVariables>(InsertLeadTrackedEventDocument, options);
      }
export type InsertLeadTrackedEventMutationHookResult = ReturnType<typeof useInsertLeadTrackedEventMutation>;
export type InsertLeadTrackedEventMutationResult = Apollo.MutationResult<InsertLeadTrackedEventMutation>;
export type InsertLeadTrackedEventMutationOptions = Apollo.BaseMutationOptions<InsertLeadTrackedEventMutation, InsertLeadTrackedEventMutationVariables>;