import * as Types from '../../generated/graphql';

import { gql } from '@apollo/client';
export type PackageFieldsFragment = { __typename?: 'package', id: any, name: string, price: number, taxRate: any, quantity: number, isActive: boolean, createdAt: any, description?: string | null | undefined, image?: string | null | undefined, availableInStore?: boolean | null | undefined, updatedAt: any, service: { __typename?: 'service', id: any, name: string, isProduct?: boolean | null | undefined } };

export const PackageFieldsFragmentDoc = gql`
    fragment PackageFields on package {
  id
  name
  price
  taxRate
  quantity
  isActive
  createdAt
  description
  image
  availableInStore
  updatedAt
  service {
    id
    name
    isProduct
  }
}
    `;