import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { SocialMediaAccountFieldsFragmentDoc } from '../../fragments/socialMediaAccountFields.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetSocialMediaAccountsQueryVariables = Types.Exact<{
  where: Types.SocialMediaAccount_Bool_Exp;
  limit?: Types.Maybe<Types.Scalars['Int']>;
  offset?: Types.Maybe<Types.Scalars['Int']>;
  orderBy?: Types.Maybe<Array<Types.SocialMediaAccount_Order_By> | Types.SocialMediaAccount_Order_By>;
}>;


export type GetSocialMediaAccountsQuery = { __typename?: 'query_root', socialMediaAccount: Array<{ __typename?: 'socialMediaAccount', id: any, name: string, platform: Types.SocialMediaPlatform_Enum, profilePicture?: string | null | undefined }> };


export const GetSocialMediaAccountsDocument = gql`
    query GetSocialMediaAccounts($where: socialMediaAccount_bool_exp!, $limit: Int, $offset: Int, $orderBy: [socialMediaAccount_order_by!]) {
  socialMediaAccount(
    where: $where
    limit: $limit
    offset: $offset
    order_by: $orderBy
  ) {
    ...SocialMediaAccountFields
  }
}
    ${SocialMediaAccountFieldsFragmentDoc}`;

/**
 * __useGetSocialMediaAccountsQuery__
 *
 * To run a query within a React component, call `useGetSocialMediaAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSocialMediaAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSocialMediaAccountsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetSocialMediaAccountsQuery(baseOptions: Apollo.QueryHookOptions<GetSocialMediaAccountsQuery, GetSocialMediaAccountsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSocialMediaAccountsQuery, GetSocialMediaAccountsQueryVariables>(GetSocialMediaAccountsDocument, options);
      }
export function useGetSocialMediaAccountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSocialMediaAccountsQuery, GetSocialMediaAccountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSocialMediaAccountsQuery, GetSocialMediaAccountsQueryVariables>(GetSocialMediaAccountsDocument, options);
        }
export type GetSocialMediaAccountsQueryHookResult = ReturnType<typeof useGetSocialMediaAccountsQuery>;
export type GetSocialMediaAccountsLazyQueryHookResult = ReturnType<typeof useGetSocialMediaAccountsLazyQuery>;
export type GetSocialMediaAccountsQueryResult = Apollo.QueryResult<GetSocialMediaAccountsQuery, GetSocialMediaAccountsQueryVariables>;