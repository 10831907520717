import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Flex,
  Button,
  HStack,
} from '@chakra-ui/react';
import { ADMIN, OWNER, PROVIDER } from '@webapp/constants';
import {
  useCollectAppointmentNoShowFeeMutation,
  useOverrideAppointmentNoShowFeeMutation,
} from '@webapp/graphql';
import { useStores } from '@webapp/state-models';
import { observer } from 'mobx-react-lite';
import toast from 'react-hot-toast';

interface NoShowFeeModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSuccess?: () => void;
  appointmentId: string;
}

export const NoShowFeeModal = observer(
  ({
    isOpen,
    onClose,
    onSuccess,
    appointmentId,
  }: NoShowFeeModalProps): JSX.Element => {
    const { user } = useStores();

    const onSuccessAndClose = (): void => {
      if (onSuccess) onSuccess();
      onClose();
    };

    const [collectFee, { loading: collectFeeLoading }] =
      useCollectAppointmentNoShowFeeMutation({
        onCompleted: () => {
          toast.success('Collect no show fee successful');
          onSuccessAndClose();
        },
        onError: () => {
          toast.error('Collect no show fee unsuccessful');
          onSuccessAndClose();
        },
      });

    const [overrideFee, { loading: overrideFeeLoading }] =
      useOverrideAppointmentNoShowFeeMutation({
        onCompleted: () => {
          toast.success('Override no show fee successful');
          onSuccessAndClose();
        },
        onError: () => {
          toast.error('Override no show fee unsuccessful');
          onSuccessAndClose();
        },
      });

    const role = user?.role;

    const isAppropriateUser = [ADMIN, OWNER, PROVIDER].includes(role as string);

    const collect = (): void => {
      collectFee({
        variables: {
          appointmentId,
        },
      });
    };

    const override = (): void => {
      overrideFee({
        variables: {
          appointmentId,
        },
      });
    };

    return (
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>No show fee</ModalHeader>
          <ModalCloseButton />
          <ModalBody>Collect no show fee?</ModalBody>
          <ModalFooter>
            <Flex w="full" justifyContent="flex-end">
              <HStack spacing={2}>
                <Button
                  colorScheme="teal"
                  onClick={collect}
                  disabled={collectFeeLoading}
                  isLoading={collectFeeLoading}
                >
                  Collect
                </Button>
                {isAppropriateUser && (
                  <Button
                    colorScheme="red"
                    onClick={override}
                    disabled={overrideFeeLoading}
                    isLoading={overrideFeeLoading}
                  >
                    Override
                  </Button>
                )}
              </HStack>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  }
);

export default NoShowFeeModal;
