import {
  CustomFormFieldType_Enum as CustomFormFieldTypeEnum,
  CustomFormFieldCategory_Enum as CustomFormFieldCategoryEnum,
} from '@webapp/graphql';
import { PossibleQuestions } from '@webapp/types';

// prettier-ignore
const numericRegexAsString = '^(0|[1-9][0-9]*)$';

const phoneValidation = JSON.stringify({
  phone: true,
});

const emailValidation = JSON.stringify({
  email: true,
});

const yesNoDescription = 'Y/N';

const textInputDescription = 'Text input';

const dateInputDescription = 'Date input';

const signatureInputDescription = 'Signature';

const multipleChoiceDescription = 'Multiple choice';

const multipleSelectDescription = 'Multiple select';

const multipleChoiceCustomizableDescription = 'Multiple choice (customizable)';

export const emergencyContactQuestions = [
  {
    label: 'Emergency Contact Name',
    type: CustomFormFieldTypeEnum.TextInput,
    category: CustomFormFieldCategoryEnum.EmergencyContact,
    description: textInputDescription,
  },
  {
    label: 'Emergency Contact Phone',
    type: CustomFormFieldTypeEnum.TextInput,
    category: CustomFormFieldCategoryEnum.EmergencyContact,
    description: textInputDescription,
    validation: phoneValidation,
  },
  {
    label: 'Emergency Contact Email',
    type: CustomFormFieldTypeEnum.TextInput,
    category: CustomFormFieldCategoryEnum.EmergencyContact,
    description: textInputDescription,
    validation: emailValidation,
  },
];

export const allergyQuestions = [
  {
    label: 'Allergies - Latex',
    type: CustomFormFieldTypeEnum.YesNo,
    category: CustomFormFieldCategoryEnum.Allergies,
    description: yesNoDescription,
  },
  {
    label: 'Allergies - Adhesive Tape',
    type: CustomFormFieldTypeEnum.YesNo,
    category: CustomFormFieldCategoryEnum.Allergies,
    description: yesNoDescription,
  },
  {
    label: 'Allergies - Iodine',
    type: CustomFormFieldTypeEnum.YesNo,
    category: CustomFormFieldCategoryEnum.Allergies,
    description: yesNoDescription,
  },
  {
    label: 'Allergies - Bee Stings',
    type: CustomFormFieldTypeEnum.YesNo,
    category: CustomFormFieldCategoryEnum.Allergies,
    description: yesNoDescription,
  },
  {
    label: 'Allergies - Lidocane',
    type: CustomFormFieldTypeEnum.YesNo,
    category: CustomFormFieldCategoryEnum.Allergies,
    description: yesNoDescription,
  },
  {
    label: 'Allergies - Medicine',
    type: CustomFormFieldTypeEnum.CreatableSelect,
    category: CustomFormFieldCategoryEnum.Allergies,
    description: multipleSelectDescription,
    optionCustomizable: true,
    options: [
      { value: 'Aspirin' },
      { value: 'Ibuprofen' },
      { value: 'Paracetamol' },
      { value: 'Penicillin' },
      { value: 'Sulfacid' },
    ],
  },
  {
    label: 'Previous Complications with Anesthesia',
    type: CustomFormFieldTypeEnum.YesNo,
    description: yesNoDescription,
    category: CustomFormFieldCategoryEnum.Allergies,
  },
  {
    label: 'Problematic Anesthesia',
    type: CustomFormFieldTypeEnum.TextInput,
    description: textInputDescription,
    category: CustomFormFieldCategoryEnum.Allergies,
  },
];

export const pregnancyQuestions = [
  {
    label: 'Pregnant?',
    category: CustomFormFieldCategoryEnum.Pregnancy,
    type: CustomFormFieldTypeEnum.YesNo,
    description: yesNoDescription,
  },
  {
    label: 'Nursing?',
    category: CustomFormFieldCategoryEnum.Pregnancy,
    type: CustomFormFieldTypeEnum.YesNo,
    description: yesNoDescription,
  },
  {
    label: 'Are you planning on becoming pregnant?',
    category: CustomFormFieldCategoryEnum.Pregnancy,
    type: CustomFormFieldTypeEnum.YesNo,
    description: yesNoDescription,
  },
  {
    label: 'Are you taking accutane or have you taken it in the last 6 months?',
    category: CustomFormFieldCategoryEnum.Pregnancy,
    type: CustomFormFieldTypeEnum.YesNo,
    description: yesNoDescription,
  },
];

export const symptomsQuestions = [
  {
    label: 'Symptoms - Do you have or have you had',
    category: CustomFormFieldCategoryEnum.Symptoms,
    type: CustomFormFieldTypeEnum.MultipleSelect,
    description: multipleChoiceDescription,
    optionCustomizable: true,
    options: [
      { value: 'Fever' },
      { value: 'Cough' },
      { value: 'Shortness of breath' },
      { value: 'Headache' },
      { value: 'Chills' },
      { value: 'Sore throat' },
      { value: 'Nausea' },
      { value: 'Vomiting' },
      { value: 'Diarrhea' },
      { value: 'Constipation' },
      { value: 'Abdominal pain' },
    ],
  },
  {
    label: 'Do you have a history of cold sores?',
    type: CustomFormFieldTypeEnum.YesNo,
    category: CustomFormFieldCategoryEnum.Symptoms,
    description: yesNoDescription,
  },
];

export const historyQuestions = [
  {
    label: 'Medical History',
    category: CustomFormFieldCategoryEnum.History,
    type: CustomFormFieldTypeEnum.MultipleSelect,
    description: multipleSelectDescription,
    optionCustomizable: true,
    options: [
      { value: 'Heart Disease' },
      { value: 'High Blood Pressure' },
      { value: 'Diabetes' },
      { value: 'Asthma' },
      { value: 'Cancer' },
      { value: 'Hepatitis' },
      { value: 'HIV/AIDS' },
    ],
  },
  {
    label: 'Family History',
    category: CustomFormFieldCategoryEnum.History,
    type: CustomFormFieldTypeEnum.MultipleSelect,
    description: multipleSelectDescription,
    optionCustomizable: true,
    options: [
      { value: 'Heart Disease' },
      { value: 'High Blood Pressure' },
      { value: 'Diabetes' },
      { value: 'Asthma' },
      { value: 'Cancer' },
    ],
  },
];

export const patientProfileQuestions = [
  {
    label: 'Height',
    type: CustomFormFieldTypeEnum.DropdownSelect,
    category: CustomFormFieldCategoryEnum.PatientProfile,
    description: 'Dropdown select',
    options: [
      { value: '6\'11"' },
      { value: '6\'10"' },
      { value: '6\'9"' },
      { value: '6\'8"' },
      { value: '6\'7"' },
      { value: '6\'6"' },
      { value: '6\'5"' },
      { value: '6\'4"' },
      { value: '6\'3"' },
      { value: '6\'2"' },
      { value: '6\'1"' },
      { value: '6\'"' },
      { value: '5\'11"' },
      { value: '5\'10"' },
      { value: '5\'9"' },
      { value: '5\'8"' },
      { value: '5\'7"' },
      { value: '5\'6"' },
      { value: '5\'5"' },
      { value: '5\'4"' },
      { value: '5\'3"' },
      { value: '5\'2"' },
      { value: '5\'1"' },
      { value: '5\'"' },
      { value: '4\'11"' },
      { value: '4\'10"' },
      { value: '4\'9"' },
      { value: '4\'8"' },
      { value: '4\'7"' },
      { value: '4\'6"' },
      { value: '4\'5"' },
      { value: '4\'4"' },
      { value: '4\'3"' },
      { value: '4\'2"' },
      { value: '4\'1"' },
      { value: '4\'"' },
      { value: '3\'11"' },
      { value: '3\'10"' },
      { value: '3\'9"' },
      { value: '3\'8"' },
      { value: '3\'7"' },
      { value: '3\'6"' },
      { value: '3\'5"' },
      { value: '3\'4"' },
      { value: '3\'3"' },
      { value: '3\'2"' },
      { value: '3\'1"' },
      { value: '3\'"' },
    ],
  },
  {
    label: 'Weight',
    type: CustomFormFieldTypeEnum.TextInput,
    category: CustomFormFieldCategoryEnum.PatientProfile,
    description: textInputDescription,
    validation: JSON.stringify({
      pattern: {
        value: numericRegexAsString,
        message: 'Weight must be a number',
      },
    }),
  },
  {
    label: 'Preferred Pharmacy',
    type: CustomFormFieldTypeEnum.TextInput,
    description: textInputDescription,
    category: CustomFormFieldCategoryEnum.PatientProfile,
  },
  {
    label: 'Race',
    type: CustomFormFieldTypeEnum.SingleSelect,
    description: multipleChoiceDescription,
    options: [
      {
        value: 'White',
      },
      {
        value: 'Hispanic',
      },
      {
        value: 'Black',
      },
      {
        value: 'Native American',
      },
      {
        value: 'Asian',
      },
      {
        value: 'Pacific Islander',
      },
      {
        value: 'Mixed race',
      },
    ],
    category: CustomFormFieldCategoryEnum.PatientProfile,
  },

  {
    label: 'Sex',
    type: CustomFormFieldTypeEnum.SingleSelect,
    description: multipleChoiceDescription,
    options: [{ value: 'Male' }, { value: 'Female' }],
    category: CustomFormFieldCategoryEnum.PatientProfile,
  },
  {
    label: 'Gender',
    type: CustomFormFieldTypeEnum.SingleSelect,
    description: multipleChoiceDescription,
    optionCustomizable: true,
    options: [{ value: 'Man' }, { value: 'Woman' }, { value: 'Other' }],
    category: CustomFormFieldCategoryEnum.PatientProfile,
  },
];

export const socialHistoryQuestions = [
  {
    label: 'What is your cigarette smoking status?',
    category: CustomFormFieldCategoryEnum.SocialHistory,
    type: CustomFormFieldTypeEnum.MultipleSelect,
    description: multipleSelectDescription,
    options: [
      { value: 'Former smoker' },
      { value: 'Current smoker' },
      { value: 'Non-smoker' },
    ],
  },
  {
    label:
      'Have you ever been a cigar, pipe, e-cig, or hookah smoker, or used a tobacco vaporizer or other nicotine products?',
    category: CustomFormFieldCategoryEnum.SocialHistory,
    type: CustomFormFieldTypeEnum.MultipleSelect,
    description: multipleSelectDescription,
    options: [
      {
        value: 'Cigar',
      },
      {
        value: 'Pipe',
      },
      {
        value: 'E-cig',
      },
      {
        value: 'Hookah',
      },
      {
        value: 'Tobacco vaporizer',
      },
      {
        value: 'None of the above',
      },
    ],
  },
  {
    label: 'Do you drink beer, wine, or liquor?',
    category: CustomFormFieldCategoryEnum.SocialHistory,
    type: CustomFormFieldTypeEnum.MultipleSelect,
    description: multipleSelectDescription,
    options: [
      {
        value: 'Beer',
      },
      {
        value: 'Wine',
      },
      {
        value: 'Liquor',
      },
      {
        value: 'None of the above',
      },
    ],
  },
  {
    label: 'Do you have a history of using recreational street drugs?',
    category: CustomFormFieldCategoryEnum.SocialHistory,
    type: CustomFormFieldTypeEnum.MultipleSelect,
    description: multipleSelectDescription,
    options: [
      {
        value: 'Marijuana',
      },
      {
        value: 'Cocaine',
      },
      {
        value: 'Heroin',
      },
      {
        value: 'Methamphetamine',
      },
      {
        value: 'None of the above',
      },
    ],
  },
];

export const medicationQuestions = [
  {
    label: 'Medication',
    description: textInputDescription,
    type: CustomFormFieldTypeEnum.TextInput,
    category: CustomFormFieldCategoryEnum.Medication,
  },
  {
    label: 'Dosage',
    description: textInputDescription,
    type: CustomFormFieldTypeEnum.TextInput,
    category: CustomFormFieldCategoryEnum.Medication,
  },
  {
    label: 'Frequency',
    description: textInputDescription,
    type: CustomFormFieldTypeEnum.TextInput,
    category: CustomFormFieldCategoryEnum.Medication,
  },
  {
    label: 'Last Taken',
    description: dateInputDescription,
    type: CustomFormFieldTypeEnum.Date,
    category: CustomFormFieldCategoryEnum.Medication,
  },
];

export const proceduresQuestions = [
  {
    label: 'Past Procedure',
    description: textInputDescription,
    type: CustomFormFieldTypeEnum.TextInput,
    category: CustomFormFieldCategoryEnum.Procedures,
  },
  {
    label: 'Procedure Date',
    description: dateInputDescription,
    type: CustomFormFieldTypeEnum.Date,
    category: CustomFormFieldCategoryEnum.Procedures,
  },
];

export const freeResponseQuestions = [
  {
    label: 'Any other medication allergies',
    description: textInputDescription,
    type: CustomFormFieldTypeEnum.TextInput,
    category: CustomFormFieldCategoryEnum.FreeResponse,
  },
  {
    label: 'Any other symptoms - Do you have or have you had',
    description: textInputDescription,
    type: CustomFormFieldTypeEnum.TextInput,
    category: CustomFormFieldCategoryEnum.FreeResponse,
  },
  {
    label: 'Any other medical history',
    description: textInputDescription,
    type: CustomFormFieldTypeEnum.TextInput,
    category: CustomFormFieldCategoryEnum.FreeResponse,
  },
  {
    label: 'Any other family history',
    description: textInputDescription,
    type: CustomFormFieldTypeEnum.TextInput,
    category: CustomFormFieldCategoryEnum.FreeResponse,
  },
  {
    label: 'Any other tobacco, drugs, alcohol',
    description: textInputDescription,
    type: CustomFormFieldTypeEnum.TextInput,
    category: CustomFormFieldCategoryEnum.FreeResponse,
  },
];

const emergencyContactGroup = {
  label: 'All Emergency Contact Questions',
  description: 'Emergency Contact Information',
  questions: emergencyContactQuestions,
};

const allergyGroup = {
  label: 'All Allergy Questions',
  description: 'Allergy Information',
  questions: allergyQuestions,
};

const pregnancyGroup = {
  label: 'All Pregnancy Questions',
  description: 'Pregnancy Information',
  questions: pregnancyQuestions,
};

export const medicineDosageRow = {
  label: 'Medication and Dosage',
  description: 'Medications',
  row: medicationQuestions,
  replicable: true,
};

export const procedureDateRow = {
  label: 'Past Procedure and Date',
  description: 'Past Procedure',
  row: proceduresQuestions,
  replicable: true,
};

export const surgeryQuestions = [
  {
    label: 'Past Surgery',
    description: textInputDescription,
    type: CustomFormFieldTypeEnum.TextInput,
    category: CustomFormFieldCategoryEnum.Procedures,
  },
  {
    label: 'Surgery Date',
    description: dateInputDescription,
    type: CustomFormFieldTypeEnum.Date,
    category: CustomFormFieldCategoryEnum.Procedures,
  },
];

export const surgeryDateRow = {
  label: 'Past Surgery and Date',
  description: 'Past Surgery',
  row: surgeryQuestions,
  replicable: true,
};

export const aestheticTreatmentQuestions = [
  {
    label: 'Past Aesthetic Treatment',
    description: textInputDescription,
    type: CustomFormFieldTypeEnum.TextInput,
    category: CustomFormFieldCategoryEnum.Procedures,
  },
  {
    label: 'Treatment Date',
    description: dateInputDescription,
    type: CustomFormFieldTypeEnum.Date,
    category: CustomFormFieldCategoryEnum.Procedures,
  },
];

export const aestheticTreatmentDateRow = {
  label: 'Past Aesthetic Treatment and Date',
  description: 'Past Aesthetic Treatment',
  row: aestheticTreatmentQuestions,
  replicable: true,
};

export const DRAGGABLE_QUESTIONS: PossibleQuestions[] = [
  emergencyContactGroup,
  allergyGroup,
  pregnancyGroup,
  medicineDosageRow,
  procedureDateRow,
  surgeryDateRow,
  aestheticTreatmentDateRow,
  ...surgeryQuestions,
  ...aestheticTreatmentQuestions,
  ...medicationQuestions,
  {
    label: 'Have you taken aspirin or any blood thinners in the past 7 days?',
    description: yesNoDescription,
    type: CustomFormFieldTypeEnum.YesNo,
    category: CustomFormFieldCategoryEnum.Medication,
  },
  ...proceduresQuestions,
  {
    label: 'First Name',
    type: CustomFormFieldTypeEnum.TextInput,
    description: textInputDescription,
    validation: JSON.stringify({
      required: {
        value: true,
        message: 'First Name is required',
      },
    }),
  },
  {
    label: 'Last Name',
    type: CustomFormFieldTypeEnum.TextInput,
    description: textInputDescription,
    validation: JSON.stringify({
      required: {
        value: true,
        message: 'Last Name is required',
      },
    }),
  },
  {
    label: 'Email Address',
    type: CustomFormFieldTypeEnum.TextInput,
    description: textInputDescription,
    validation: emailValidation,
  },
  {
    label: 'Phone Number',
    type: CustomFormFieldTypeEnum.TextInput,
    description: textInputDescription,
    validation: phoneValidation,
  },
  {
    label: 'Preferred Contact Method',
    type: CustomFormFieldTypeEnum.SingleSelect,
    description: multipleChoiceDescription,
    options: [
      { value: 'Email' },
      { value: 'Phone call' },
      {
        value: 'Text message',
      },
    ],
  },
  {
    label: 'Preferred Language',
    type: CustomFormFieldTypeEnum.CreatableSingleSelect,
    description: multipleChoiceCustomizableDescription,
    optionCustomizable: true,
    options: [
      { value: 'English' },
      { value: 'Spanish' },
      { value: 'French' },
      { value: 'Chinese' },
    ],
  },
  {
    label: 'Address',
    type: CustomFormFieldTypeEnum.Address,
    description: textInputDescription,
  },
  {
    label: 'Signature',
    type: CustomFormFieldTypeEnum.Signature,
    description: signatureInputDescription,
  },
  {
    label: 'Can we leave a voicemail?',
    type: CustomFormFieldTypeEnum.YesNo,
    description: yesNoDescription,
  },
  {
    label: 'Open to Clinical Trials?',
    type: CustomFormFieldTypeEnum.YesNo,
    description: yesNoDescription,
  },
  {
    label: 'Date of birth',
    type: CustomFormFieldTypeEnum.Date,
    description: 'Date',
  },
  {
    label: 'How did you find us?',
    type: CustomFormFieldTypeEnum.SingleSelect,
    description: multipleChoiceCustomizableDescription,
    optionCustomizable: true,
    options: [
      { value: 'Google' },
      { value: 'Facebook' },
      { value: 'Twitter' },
      { value: 'Instagram' },
    ],
  },
  {
    label: "Driver's License (State & #)",
    type: CustomFormFieldTypeEnum.TextInput,
    description: textInputDescription,
  },
  {
    label: 'Marital Status',
    type: CustomFormFieldTypeEnum.SingleSelect,
    description: multipleChoiceDescription,
    optionCustomizable: true,
    options: [
      { value: 'Single' },
      { value: 'Married' },
      { value: 'Divorced' },
      { value: 'Widowed' },
    ],
  },
  {
    label: 'Spouse Name',
    type: CustomFormFieldTypeEnum.TextInput,
    description: textInputDescription,
  },
  {
    label: 'Open to Clinical Trials',
    type: CustomFormFieldTypeEnum.YesNo,
    description: yesNoDescription,
  },
  {
    label: 'Alle Loyalty Number',
    type: CustomFormFieldTypeEnum.TextInput,
    description: textInputDescription,
  },
  {
    label: 'Merz Loyalty',
    type: CustomFormFieldTypeEnum.TextInput,
    description: textInputDescription,
  },
  {
    label: 'Galderma Loyalty',
    type: CustomFormFieldTypeEnum.TextInput,
    description: textInputDescription,
  },
  ...emergencyContactQuestions,
  ...allergyQuestions,
  ...pregnancyQuestions,
  ...symptomsQuestions,
  ...historyQuestions,
  ...patientProfileQuestions,
  ...socialHistoryQuestions,
  ...freeResponseQuestions,
  {
    label: 'Services of Interest',
    type: CustomFormFieldTypeEnum.MultipleSelect,
    description: multipleSelectDescription,
    category: CustomFormFieldCategoryEnum.SocialHistory,
    table: 'service',
    attribute: 'name',
  },
  {
    label: 'Place/Type of Employment',
    type: CustomFormFieldTypeEnum.TextInput,
    description: textInputDescription,
  },
  {
    label: 'Witness Signature (Office Use Only)',
    type: CustomFormFieldTypeEnum.Signature,
    description: signatureInputDescription,
  },
];

export const leftListId = 'droppableLeft';

export const rightListId = 'droppableRight';
