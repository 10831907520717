import * as Types from '../../generated/graphql';

import { gql } from '@apollo/client';
export type ProviderScheduleFieldsFragment = { __typename?: 'providerSchedule', id: any, default: boolean, schedule: any, effectiveFrom: any, effectiveUntil?: any | null | undefined, createdAt: any, updatedAt: any, locationId: any, location: { __typename?: 'location', id: any, name: string, timezone?: string | null | undefined } };

export const ProviderScheduleFieldsFragmentDoc = gql`
    fragment ProviderScheduleFields on providerSchedule {
  id
  default
  schedule
  effectiveFrom
  effectiveUntil
  createdAt
  updatedAt
  locationId
  location {
    id
    name
    timezone
  }
}
    `;