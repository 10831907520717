import * as Joi from 'joi';

export function addMessages(
  schema: Joi.Schema,
  name: string,
  options: { compareFieldName?: string } = {}
) {
  const messages = generateMessages(name, options);
  return schema.messages(messages);
}

export function generateMessages(
  name: string,
  options: { compareFieldName?: string }
) {
  const REQUIRED_MESSAGE = `${name} is required`;
  const INVALID_MESSAGE = `Invalid ${name}`;
  const ARRAY_MIN_MESSAGE = `${name} should have a minimum length of {#limit}`;
  const NUMBER_MIN_MESSAGE = `${name} must be greater than {#limit - 1}`;
  const NUMBER_MAX_MESSAGE = `${name} must be less than or equal to {#limit}`;
  const NUMBER_GREATER_MESSAGE = `${name} must be greater than ${
    options.compareFieldName ?? 'compared field'
  }`;

  return {
    'array.min': ARRAY_MIN_MESSAGE,
    'any.required': REQUIRED_MESSAGE,
    'date.base': REQUIRED_MESSAGE,
    'date.empty': INVALID_MESSAGE,
    'number.base': INVALID_MESSAGE,
    'number.greater': NUMBER_GREATER_MESSAGE,
    'number.min': NUMBER_MIN_MESSAGE,
    'number.max': NUMBER_MAX_MESSAGE,
    'object.base': REQUIRED_MESSAGE,
    'string.base': REQUIRED_MESSAGE,
    'string.email': INVALID_MESSAGE,
    'string.empty': REQUIRED_MESSAGE,
  };
}

export function addStringMessages(schema: Joi.StringSchema, name: string) {
  return schema.messages(generateStringMessages(name));
}

export function addDateMessages(schema: Joi.DateSchema, name: string) {
  return schema.messages(generateDateMessages(name));
}

export function generateDateMessages(name: string) {
  const REQUIRED_MESSAGE = `${name} is required`;
  const INVALID_MESSAGE = `Invalid ${name}`;

  return {
    'date.base': REQUIRED_MESSAGE,
    'date.empty': INVALID_MESSAGE,
    'any.required': REQUIRED_MESSAGE,
  };
}

export function generateStringMessages(name: string) {
  const REQUIRED_MESSAGE = `${name} is required`;
  const INVALID_MESSAGE = `Invalid ${name}`;

  return {
    'string.base': REQUIRED_MESSAGE,
    'string.empty': INVALID_MESSAGE,
    'any.required': REQUIRED_MESSAGE,
  };
}

export default generateStringMessages;
