import { HStack, StackProps, Text, TextProps } from '@chakra-ui/react';
import { InfoOutlineIcon, CheckIcon } from '@chakra-ui/icons';

/* eslint-disable-next-line */

export enum NotificationType {
  SUCCESS = 'SUCCESS',
  WARNING = 'WARNING',
  DEFAULT = 'DEFAULT',
}

export interface NotificationWithIconProps {
  text: string;
  type?: NotificationType;
  containerStyle?: StackProps;
  textStyle?: TextProps;
}

const NTypeResource = {
  [NotificationType.SUCCESS]: {
    color: 'green.800',
    background: 'green.50',
    icon: <CheckIcon color="green.800" />,
  },
  [NotificationType.WARNING]: {
    color: 'red.600',
    background: 'red.50',
    icon: <InfoOutlineIcon color="red.600" />,
  },

  [NotificationType.DEFAULT]: {
    color: 'gray.600',
    background: 'gray.200',
    icon: <InfoOutlineIcon color="gray.600" />,
  },
};

export function NotificationWithIcon({
  text,
  type = NotificationType.DEFAULT,
  containerStyle,
  textStyle,
}: NotificationWithIconProps) {
  const { color, background, icon } = NTypeResource[type];

  return (
    <HStack
      p={4}
      rounded="md"
      justifyContent="flex-start"
      alignItems={'center'}
      spacing={2}
      bg={background}
      {...containerStyle}
    >
      {icon}
      <Text fontSize={14} fontWeight="bold" color={color} {...textStyle}>
        {text}
      </Text>
    </HStack>
  );
}

export default NotificationWithIcon;
