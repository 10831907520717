/* eslint-disable camelcase */
import {
  PatientFlowsheetData_Insert_Input,
  useInsertManyPatientFlowsheetDataMutation,
} from '@webapp/graphql';
import { FieldValues } from 'react-hook-form';
import toast from 'react-hot-toast';

function useInsertFlowsheetRowData({
  patientWorkspaceId,
}: {
  patientWorkspaceId: string;
}): [(values: FieldValues) => void, { isLoading: boolean }] {
  const [insertPatientFlowsheetData, { loading }] =
    useInsertManyPatientFlowsheetDataMutation({
      onCompleted: () => {
        toast.success('Row updated successfully');
      },
      onError: (e) => {
        toast.error(e.message);
      },
      refetchQueries: ['PatientFlowsheets'],
    });

  const insertFlowsheetRowData = ({
    flowsheetId,
    recordedAt,
    row,
    ...values
  }: FieldValues) => {
    const flowsheetParameterIds = Object.keys(values);

    const insertInputs: PatientFlowsheetData_Insert_Input[] =
      flowsheetParameterIds.reduce((acc, flowsheetParameterId) => {
        if (values[flowsheetParameterId] !== undefined) {
          const newValue = values[flowsheetParameterId];

          return [
            ...acc,
            {
              flowsheetId,
              flowsheetParameterId,
              patientFlowsheetRowId: row.id,
              patientWorkspaceId,
              recordedAt,
              value:
                typeof newValue === 'string'
                  ? newValue
                  : JSON.stringify(newValue),
            } as PatientFlowsheetData_Insert_Input,
          ];
        }

        return acc;
      }, [] as PatientFlowsheetData_Insert_Input[]);

    insertPatientFlowsheetData({
      variables: {
        objects: insertInputs,
      },
    });
  };

  return [insertFlowsheetRowData, { isLoading: loading }];
}

export default useInsertFlowsheetRowData;
