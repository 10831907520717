import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type SubscribePatientWalletBalanceSubscriptionVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
}>;


export type SubscribePatientWalletBalanceSubscription = { __typename?: 'subscription_root', patientWallet_by_pk?: { __typename?: 'patientWallet', balance: number } | null | undefined };


export const SubscribePatientWalletBalanceDocument = gql`
    subscription SubscribePatientWalletBalance($id: uuid!) {
  patientWallet_by_pk(id: $id) {
    balance
  }
}
    `;

/**
 * __useSubscribePatientWalletBalanceSubscription__
 *
 * To run a query within a React component, call `useSubscribePatientWalletBalanceSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribePatientWalletBalanceSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribePatientWalletBalanceSubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSubscribePatientWalletBalanceSubscription(baseOptions: Apollo.SubscriptionHookOptions<SubscribePatientWalletBalanceSubscription, SubscribePatientWalletBalanceSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<SubscribePatientWalletBalanceSubscription, SubscribePatientWalletBalanceSubscriptionVariables>(SubscribePatientWalletBalanceDocument, options);
      }
export type SubscribePatientWalletBalanceSubscriptionHookResult = ReturnType<typeof useSubscribePatientWalletBalanceSubscription>;
export type SubscribePatientWalletBalanceSubscriptionResult = Apollo.SubscriptionResult<SubscribePatientWalletBalanceSubscription>;