import {
  chakra,
  Button,
  Image,
  Skeleton,
  Stack,
  Wrap,
  WrapItem,
} from '@chakra-ui/react';
import { SimpleVideo } from '@webapp/ui';
import { getProxyPhotoUrl } from '@webapp/utils';
import { HiCheckCircle } from 'react-icons/hi';
import PhotoGridProps from './photo-grid-props';

function PhotoGridInSquares({
  customStyles = {},
  isLoading = false,
  nextPage,
  onPhotoClick = () => undefined,
  photos,
  selectedPhoto,
  spacing = 0,
}: PhotoGridProps) {
  const cardStyle = customStyles.card ?? {};
  const containerStyle = customStyles.container ?? {};
  const imageStyle = customStyles.image ?? {};

  return (
    <Stack {...containerStyle}>
      <Wrap spacing={spacing}>
        {photos.map((photo) => (
          <WrapItem
            cursor={'pointer'}
            display="grid"
            key={photo.id}
            maxHeight="188px"
            onClick={() => {
              if (!(selectedPhoto?.id === photo.id)) {
                onPhotoClick(photo);
              }
            }}
            overflow="hidden"
            position="relative"
            width="100%"
            {...cardStyle}
          >
            <chakra.div
              display={selectedPhoto?.id === photo.id ? 'grid' : 'none'}
              position="absolute"
              top="15px"
              right="15px"
              zIndex={2}
            >
              <HiCheckCircle color="#00758A" size="25px" />
            </chakra.div>
            <chakra.div
              background="rgba(0, 0, 0, .4)"
              display={selectedPhoto?.id === photo.id ? 'grid' : 'none'}
              position="absolute"
              top="0"
              right="0"
              bottom="0"
              left="0"
            />
            {photo?.mimeType && photo?.mimeType.includes('video') ? (
              <SimpleVideo src={photo.file?.url} />
            ) : (
              <Image
                className="ph-no-capture fs-exclude"
                fallback={<Skeleton w={'full'} h={'60'} />}
                minHeight="100%"
                objectFit="cover"
                src={getProxyPhotoUrl(
                  photo.file?.thumbnailUrl || photo.file?.url,
                  'thumbnail'
                )}
                width="full"
                {...imageStyle}
                {...(!(selectedPhoto?.id === photo.id) && {
                  _hover: {
                    shadow: 'lg',
                    opacity: 0.75,
                  },
                })}
              />
            )}
          </WrapItem>
        ))}
      </Wrap>
      {nextPage && (
        <Button
          isLoading={isLoading}
          justifySelf="center"
          margin="50px 0 0"
          onClick={nextPage}
          width="max-content"
        >
          Load More
        </Button>
      )}
    </Stack>
  );
}

export default PhotoGridInSquares;
