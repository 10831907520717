import { Input, InputGroup } from '@chakra-ui/react';
import { mobiScrollDatePickerProps } from '@webapp/types';
import { configuredDayjs as dayjs } from '@webapp/util-time';
import { ChangeEvent } from 'react';
import DEFAULT_CONTROL_STYLE from './styles/DefaultControlStyle';
import DEFAULT_INPUT_CONTAINER_STYLE from './styles/DefaultInputContainerStyle';
import { DateTimeInputProps } from './types/DateTimeInputProps';

const DATE_FORMAT = 'YYYY-MM-DDTHH:mm';

export function DateTimeInput({
  name,
  colors,
  onChange,
  placeholder,
  styles = {},
  timezone,
  value,
}: Omit<DateTimeInputProps, 'type'> &
  mobiScrollDatePickerProps & { timezone: string }) {
  const inputStyles = { ...DEFAULT_CONTROL_STYLE, ...styles.control };

  const containerStyles = {
    ...DEFAULT_INPUT_CONTAINER_STYLE,
    ...styles.inputContainer,
  };

  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    const newValue = e.target.value;
    const formattedDateTime = timezone
      ? dayjs(newValue, DATE_FORMAT).tz(timezone, true).utc().toISOString()
      : dayjs(newValue, DATE_FORMAT).format();
    onChange(formattedDateTime);
  }

  return (
    <InputGroup {...containerStyles}>
      <Input
        onChange={handleChange}
        placeholder={placeholder}
        type="datetime-local"
        value={
          typeof value === 'string'
            ? (timezone ? dayjs(value).tz(timezone) : dayjs(value)).format(
                DATE_FORMAT
              )
            : value.toString()
        }
        {...inputStyles}
      />
    </InputGroup>
  );
}

export default DateTimeInput;
