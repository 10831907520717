import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type FinixTerminalAggregateQueryVariables = Types.Exact<{
  where?: Types.Maybe<Types.FinixTerminal_Bool_Exp>;
}>;


export type FinixTerminalAggregateQuery = { __typename?: 'query_root', finixTerminal_aggregate: { __typename?: 'finixTerminal_aggregate', aggregate?: { __typename?: 'finixTerminal_aggregate_fields', count: number } | null | undefined } };


export const FinixTerminalAggregateDocument = gql`
    query finixTerminalAggregate($where: finixTerminal_bool_exp) {
  finixTerminal_aggregate(where: $where) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useFinixTerminalAggregateQuery__
 *
 * To run a query within a React component, call `useFinixTerminalAggregateQuery` and pass it any options that fit your needs.
 * When your component renders, `useFinixTerminalAggregateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFinixTerminalAggregateQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useFinixTerminalAggregateQuery(baseOptions?: Apollo.QueryHookOptions<FinixTerminalAggregateQuery, FinixTerminalAggregateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FinixTerminalAggregateQuery, FinixTerminalAggregateQueryVariables>(FinixTerminalAggregateDocument, options);
      }
export function useFinixTerminalAggregateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FinixTerminalAggregateQuery, FinixTerminalAggregateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FinixTerminalAggregateQuery, FinixTerminalAggregateQueryVariables>(FinixTerminalAggregateDocument, options);
        }
export type FinixTerminalAggregateQueryHookResult = ReturnType<typeof useFinixTerminalAggregateQuery>;
export type FinixTerminalAggregateLazyQueryHookResult = ReturnType<typeof useFinixTerminalAggregateLazyQuery>;
export type FinixTerminalAggregateQueryResult = Apollo.QueryResult<FinixTerminalAggregateQuery, FinixTerminalAggregateQueryVariables>;