import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { PhotoFieldsFragmentDoc } from '../../fragments/photoFields.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type InsertPhotoMutationVariables = Types.Exact<{
  photo: Types.Photo_Insert_Input;
}>;


export type InsertPhotoMutation = { __typename?: 'mutation_root', insert_photo?: { __typename?: 'photo_mutation_response', returning: Array<{ __typename?: 'photo', id: any, workspaceId: any, patientId?: any | null | undefined, mediaDate?: any | null | undefined, mediaType?: string | null | undefined, mimeType?: string | null | undefined, hasThumbnail?: boolean | null | undefined, isSharedWithPatient: boolean, partialMediaConsent?: boolean | null | undefined, fullMediaConsent?: boolean | null | undefined, patient?: { __typename?: 'patient', firstName: string, id: any, lastName: string } | null | undefined, file?: { __typename?: 'FilePayload', filePath: string, url: string, thumbnailUrl?: string | null | undefined } | null | undefined, photoMediaTags: Array<{ __typename?: 'photoMediaTag', id: any, mediaTag: { __typename?: 'mediaTag', id: any, title: string } }>, uploader?: { __typename?: 'user', firstName: string, id: any, lastName: string } | null | undefined }> } | null | undefined };


export const InsertPhotoDocument = gql`
    mutation insertPhoto($photo: photo_insert_input!) {
  insert_photo(objects: [$photo]) {
    returning {
      ...PhotoFields
    }
  }
}
    ${PhotoFieldsFragmentDoc}`;
export type InsertPhotoMutationFn = Apollo.MutationFunction<InsertPhotoMutation, InsertPhotoMutationVariables>;

/**
 * __useInsertPhotoMutation__
 *
 * To run a mutation, you first call `useInsertPhotoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertPhotoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertPhotoMutation, { data, loading, error }] = useInsertPhotoMutation({
 *   variables: {
 *      photo: // value for 'photo'
 *   },
 * });
 */
export function useInsertPhotoMutation(baseOptions?: Apollo.MutationHookOptions<InsertPhotoMutation, InsertPhotoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertPhotoMutation, InsertPhotoMutationVariables>(InsertPhotoDocument, options);
      }
export type InsertPhotoMutationHookResult = ReturnType<typeof useInsertPhotoMutation>;
export type InsertPhotoMutationResult = Apollo.MutationResult<InsertPhotoMutation>;
export type InsertPhotoMutationOptions = Apollo.BaseMutationOptions<InsertPhotoMutation, InsertPhotoMutationVariables>;