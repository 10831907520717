import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { PatientAddressFieldsFragmentDoc } from '../../fragments/patientAddressFields.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type InsertOnePatientAddressMutationVariables = Types.Exact<{
  addressLine1: Types.Scalars['String'];
  addressLine2?: Types.Maybe<Types.Scalars['String']>;
  city: Types.Scalars['String'];
  isBilling?: Types.Maybe<Types.Scalars['Boolean']>;
  isDefault?: Types.Maybe<Types.Scalars['Boolean']>;
  patientId: Types.Scalars['uuid'];
  state: Types.Scalars['String'];
  zipCode: Types.Scalars['String'];
}>;


export type InsertOnePatientAddressMutation = { __typename?: 'mutation_root', insertPatientAddress: { __typename?: 'InsertPatientAddressOutput', patientAddress?: { __typename?: 'patientAddress', id: any, isDefault: boolean, type: string, status: string, address: { __typename?: 'address', addressLine1: string, addressLine2?: string | null | undefined, city: string, id: any, state: string, zipCode: string } } | null | undefined } };


export const InsertOnePatientAddressDocument = gql`
    mutation InsertOnePatientAddress($addressLine1: String!, $addressLine2: String = "", $city: String!, $isBilling: Boolean = false, $isDefault: Boolean = false, $patientId: uuid!, $state: String!, $zipCode: String!) {
  insertPatientAddress(
    addressLine1: $addressLine1
    addressLine2: $addressLine2
    city: $city
    isBilling: $isBilling
    isDefault: $isDefault
    patientId: $patientId
    state: $state
    zipCode: $zipCode
  ) {
    patientAddress {
      ...PatientAddressFields
    }
  }
}
    ${PatientAddressFieldsFragmentDoc}`;
export type InsertOnePatientAddressMutationFn = Apollo.MutationFunction<InsertOnePatientAddressMutation, InsertOnePatientAddressMutationVariables>;

/**
 * __useInsertOnePatientAddressMutation__
 *
 * To run a mutation, you first call `useInsertOnePatientAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertOnePatientAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertOnePatientAddressMutation, { data, loading, error }] = useInsertOnePatientAddressMutation({
 *   variables: {
 *      addressLine1: // value for 'addressLine1'
 *      addressLine2: // value for 'addressLine2'
 *      city: // value for 'city'
 *      isBilling: // value for 'isBilling'
 *      isDefault: // value for 'isDefault'
 *      patientId: // value for 'patientId'
 *      state: // value for 'state'
 *      zipCode: // value for 'zipCode'
 *   },
 * });
 */
export function useInsertOnePatientAddressMutation(baseOptions?: Apollo.MutationHookOptions<InsertOnePatientAddressMutation, InsertOnePatientAddressMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertOnePatientAddressMutation, InsertOnePatientAddressMutationVariables>(InsertOnePatientAddressDocument, options);
      }
export type InsertOnePatientAddressMutationHookResult = ReturnType<typeof useInsertOnePatientAddressMutation>;
export type InsertOnePatientAddressMutationResult = Apollo.MutationResult<InsertOnePatientAddressMutation>;
export type InsertOnePatientAddressMutationOptions = Apollo.BaseMutationOptions<InsertOnePatientAddressMutation, InsertOnePatientAddressMutationVariables>;