import {
  useState,
  useRef,
  useLayoutEffect,
  MutableRefObject,
  useEffect,
  useCallback,
} from 'react';
import values from 'lodash/values';

export interface UseInfiniteScroll<T> {
  hasMore: boolean;
  items: T[];
  listContainerRef: MutableRefObject<HTMLDivElement | null>;
  loadMoreRef: MutableRefObject<HTMLButtonElement | null>;
  nextPage: () => void;
  page: number;
  receiveItems: (items: T[]) => void;
  reset: () => void;
}

export interface UseInfiniteScrollProps<T> {
  count?: number | undefined;
  identifier?: keyof T & string;
  queryString: string;
}
/**
 * @example A
 * const { items, receiveItems, page } = useInfiniteScroll()
 *
 * const  { data } = useQuery({
 *      onCompleted: (data) => {
 *          const dataArray = data?.FOO?.BAR ?? [];
 *          receiveItems(dataArray)
 *      }
 *      variables: {
 *          ...,
 *          offset: page * PAGE_SIZE
 *      }
 * })
 *
 */

export function useInfiniteScroll<T extends { id: string }>({
  count,
  identifier = 'id',
  queryString,
}: UseInfiniteScrollProps<T>): UseInfiniteScroll<T> {
  const listContainerRef = useRef<HTMLDivElement>(null);
  const loadMoreRef = useRef<HTMLButtonElement>(null);

  const [previousY, setPreviousY] = useState<number | undefined>(undefined);
  const [previousRatio, setPreviousRatio] = useState<number>(0);

  const [page, setPage] = useState<{ [key: string]: number }>({
    [queryString]: 0,
  });

  const [items, receiveItems] = useState<{
    [key: string]: {
      [key: string]: T;
    };
  }>({});

  const itemsArray = values(items[queryString]);

  const [hasMore, setHasMore] = useState<boolean | undefined>(undefined);

  useEffect(() => {
    if (Number.isInteger(count)) {
      if ((count ?? 0) > itemsArray.length) {
        setHasMore(true);
      } else {
        setHasMore(false);
      }
    }
  }, [count, itemsArray.length]);

  function nextPage() {
    setPage({
      ...page,
      [queryString]: (page[queryString] ?? 0) + 1,
    });
  }

  function reset() {
    setPage({});
    receiveItems({});
  }

  const receiveMoreItems = useCallback(
    (newItems: T[]) => {
      const newItemsMap: {
        [key: string]: T;
      } = {};

      newItems.forEach((item) => {
        newItemsMap[`${item[identifier]}`] = item;
      });

      receiveItems({
        ...items,
        [queryString]: {
          ...items[queryString],
          ...newItemsMap,
        },
      });
    },
    [identifier, items, queryString]
  );

  useLayoutEffect(() => {
    const loaderNode = loadMoreRef.current;
    const listContainerNode = listContainerRef.current;

    if (!listContainerNode || !loaderNode || !hasMore) {
      return () => undefined;
    }

    const options: IntersectionObserverInit = {
      root: listContainerNode,
      rootMargin: `0px 0px 0px 0px`,
      threshold: 0,
    };

    const intersectionObserverCallback: IntersectionObserverCallback = (
      entries
    ) => {
      entries.forEach(
        ({ isIntersecting, intersectionRatio, boundingClientRect }) => {
          const { y } = boundingClientRect;

          if (
            isIntersecting &&
            intersectionRatio >= previousRatio &&
            (!previousY || y < previousY)
          ) {
            nextPage();
          }

          setPreviousY(y);
          setPreviousRatio(intersectionRatio);
        }
      );
    };

    const observer = new IntersectionObserver(
      intersectionObserverCallback,
      options
    );

    observer.observe(loaderNode);

    return () => observer.disconnect();
  });

  return {
    hasMore: hasMore ?? false,
    items: values(items[queryString]),
    listContainerRef,
    loadMoreRef,
    nextPage,
    page: page[queryString],
    receiveItems: receiveMoreItems,
    reset,
  };
}

export default useInfiniteScroll;
