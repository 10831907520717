import * as Types from '../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type SubmitLeadFormFieldsMutationVariables = Types.Exact<{
  fieldValues: Array<Types.Maybe<Types.LeadFormFieldValueInput>> | Types.Maybe<Types.LeadFormFieldValueInput>;
}>;


export type SubmitLeadFormFieldsMutation = { __typename?: 'mutation_root', submitLeadFormFields?: { __typename?: 'LeadFormSubmitOutput', patientId: any } | null | undefined };


export const SubmitLeadFormFieldsDocument = gql`
    mutation submitLeadFormFields($fieldValues: [LeadFormFieldValueInput]!) {
  submitLeadFormFields(fieldValues: $fieldValues) {
    patientId
  }
}
    `;
export type SubmitLeadFormFieldsMutationFn = Apollo.MutationFunction<SubmitLeadFormFieldsMutation, SubmitLeadFormFieldsMutationVariables>;

/**
 * __useSubmitLeadFormFieldsMutation__
 *
 * To run a mutation, you first call `useSubmitLeadFormFieldsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitLeadFormFieldsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitLeadFormFieldsMutation, { data, loading, error }] = useSubmitLeadFormFieldsMutation({
 *   variables: {
 *      fieldValues: // value for 'fieldValues'
 *   },
 * });
 */
export function useSubmitLeadFormFieldsMutation(baseOptions?: Apollo.MutationHookOptions<SubmitLeadFormFieldsMutation, SubmitLeadFormFieldsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SubmitLeadFormFieldsMutation, SubmitLeadFormFieldsMutationVariables>(SubmitLeadFormFieldsDocument, options);
      }
export type SubmitLeadFormFieldsMutationHookResult = ReturnType<typeof useSubmitLeadFormFieldsMutation>;
export type SubmitLeadFormFieldsMutationResult = Apollo.MutationResult<SubmitLeadFormFieldsMutation>;
export type SubmitLeadFormFieldsMutationOptions = Apollo.BaseMutationOptions<SubmitLeadFormFieldsMutation, SubmitLeadFormFieldsMutationVariables>;