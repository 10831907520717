import './fullscreen-image-preview.module.scss';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  IconButton,
  useDisclosure,
  Button,
  Image,
  Tooltip,
  Skeleton,
  Center,
} from '@chakra-ui/react';
import { BiFullscreen } from 'react-icons/bi';
import { Photo } from '@webapp/state-models';
import { SimpleVideo } from '@webapp/ui';

import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { getProxyPhotoUrl } from '@webapp/utils';
/* eslint-disable-next-line */
export interface FullscreenImagePreviewProps {
  photo?: Photo;
}

export function FullscreenImagePreview({ photo }: FullscreenImagePreviewProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Tooltip label="View image in fullscreen">
        <IconButton
          icon={<BiFullscreen />}
          aria-label="fullscreen"
          onClick={onOpen}
        ></IconButton>
      </Tooltip>

      <Modal isOpen={isOpen} onClose={onClose} size="full">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Media Preview</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Center>
              {photo?.mimeType && photo?.mimeType.includes('video') ? (
                <Center w={'full'} h={'full'} bg="red.200">
                  <SimpleVideo src={photo.file?.url} />
                </Center>
              ) : (
                <TransformWrapper>
                  <TransformComponent>
                    <Image
                      objectFit={'contain'}
                      src={getProxyPhotoUrl(photo?.file?.url, 'full')}
                      h={'80vh'}
                      w={'100vh'}
                      fallback={<Skeleton />}
                    />
                  </TransformComponent>
                </TransformWrapper>
              )}
            </Center>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default FullscreenImagePreview;
