import * as Types from '../../generated/graphql';

import { gql } from '@apollo/client';
export type IncompatibleServicesFieldsFragment = { __typename?: 'incompatibleServices', id: any, serviceA: { __typename?: 'service', id: any, name: string }, serviceB: { __typename?: 'service', id: any, name: string } };

export const IncompatibleServicesFieldsFragmentDoc = gql`
    fragment IncompatibleServicesFields on incompatibleServices {
  id
  serviceA {
    id
    name
  }
  serviceB {
    id
    name
  }
}
    `;