import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { ConsumableTypeFieldsFullFragmentDoc } from '../../fragments/consumableTypeFieldsFull.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetConsumableTypeQueryVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
}>;


export type GetConsumableTypeQuery = { __typename?: 'query_root', consumableType_by_pk?: { __typename?: 'consumableType', id: any, name: string, requiredFields: any, consumables: Array<{ __typename?: 'consumable', id: any, metadata: any, type: { __typename?: 'consumableType', name: string }, brand?: { __typename?: 'consumableBrand', name: string } | null | undefined }> } | null | undefined };


export const GetConsumableTypeDocument = gql`
    query GetConsumableType($id: uuid!) {
  consumableType_by_pk(id: $id) {
    ...ConsumableTypeFieldsFull
  }
}
    ${ConsumableTypeFieldsFullFragmentDoc}`;

/**
 * __useGetConsumableTypeQuery__
 *
 * To run a query within a React component, call `useGetConsumableTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetConsumableTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetConsumableTypeQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetConsumableTypeQuery(baseOptions: Apollo.QueryHookOptions<GetConsumableTypeQuery, GetConsumableTypeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetConsumableTypeQuery, GetConsumableTypeQueryVariables>(GetConsumableTypeDocument, options);
      }
export function useGetConsumableTypeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetConsumableTypeQuery, GetConsumableTypeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetConsumableTypeQuery, GetConsumableTypeQueryVariables>(GetConsumableTypeDocument, options);
        }
export type GetConsumableTypeQueryHookResult = ReturnType<typeof useGetConsumableTypeQuery>;
export type GetConsumableTypeLazyQueryHookResult = ReturnType<typeof useGetConsumableTypeLazyQuery>;
export type GetConsumableTypeQueryResult = Apollo.QueryResult<GetConsumableTypeQuery, GetConsumableTypeQueryVariables>;