import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
  ModalCloseButton,
} from '@chakra-ui/react';
import FlowsheetSmartNoteModalBody from './FlowsheetSmartNoteModalBody';

/* eslint-disable-next-line */
export interface SmartNoteFlowsheetModalProps {
  isOpen: boolean;
  onClose: () => void;
  patientId: string;
  editorReference: any;
}

export function SmartNoteFlowsheetModal({
  isOpen,
  onClose,
  editorReference,
  patientId,
}: SmartNoteFlowsheetModalProps) {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size={'full'}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Embed Flowsheet Data</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FlowsheetSmartNoteModalBody
            patientId={patientId}
            editorReference={editorReference}
            onClose={onClose}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default SmartNoteFlowsheetModal;
