import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { PayrixTerminalFieldsFragmentDoc } from '../../fragments/payrixTerminalFields.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type ListPayrixTerminalsQueryVariables = Types.Exact<{
  where?: Types.Maybe<Types.PayrixTerminal_Bool_Exp>;
  offset?: Types.Maybe<Types.Scalars['Int']>;
  limit?: Types.Maybe<Types.Scalars['Int']>;
  orderBy?: Types.Maybe<Array<Types.PayrixTerminal_Order_By> | Types.PayrixTerminal_Order_By>;
}>;


export type ListPayrixTerminalsQuery = { __typename?: 'query_root', payrixTerminal: Array<{ __typename?: 'payrixTerminal', id: any, workspaceId: any, locationId: any, deviceMake: string, deviceModel: string, payrixTerminalId: string, name?: string | null | undefined }> };


export const ListPayrixTerminalsDocument = gql`
    query listPayrixTerminals($where: payrixTerminal_bool_exp, $offset: Int = 0, $limit: Int = 20, $orderBy: [payrixTerminal_order_by!]) {
  payrixTerminal(
    where: $where
    offset: $offset
    limit: $limit
    order_by: $orderBy
  ) {
    ...PayrixTerminalFields
  }
}
    ${PayrixTerminalFieldsFragmentDoc}`;

/**
 * __useListPayrixTerminalsQuery__
 *
 * To run a query within a React component, call `useListPayrixTerminalsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListPayrixTerminalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListPayrixTerminalsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useListPayrixTerminalsQuery(baseOptions?: Apollo.QueryHookOptions<ListPayrixTerminalsQuery, ListPayrixTerminalsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListPayrixTerminalsQuery, ListPayrixTerminalsQueryVariables>(ListPayrixTerminalsDocument, options);
      }
export function useListPayrixTerminalsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListPayrixTerminalsQuery, ListPayrixTerminalsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListPayrixTerminalsQuery, ListPayrixTerminalsQueryVariables>(ListPayrixTerminalsDocument, options);
        }
export type ListPayrixTerminalsQueryHookResult = ReturnType<typeof useListPayrixTerminalsQuery>;
export type ListPayrixTerminalsLazyQueryHookResult = ReturnType<typeof useListPayrixTerminalsLazyQuery>;
export type ListPayrixTerminalsQueryResult = Apollo.QueryResult<ListPayrixTerminalsQuery, ListPayrixTerminalsQueryVariables>;