/* eslint-disable max-classes-per-file */
import { createElement } from 'react';
import {
  PropertyGridEditorCollection,
  localization,
} from 'survey-creator-react';
import { useLoadLocation, useLoadLocations } from '@webapp/webapp/hooks';
import { ElementFactory, Question, Serializer, SvgRegistry } from 'survey-core';
import {
  SurveyQuestionElementBase,
  ReactQuestionFactory,
} from 'survey-react-ui';
import { FormControl, FormLabel, Box } from '@chakra-ui/react';
import { GenericObjectSelect } from '@webapp/ui';
import { LocationFieldsFragment } from '@webapp/graphql';
import Icon from './icon.svg?raw';

const CUSTOM_TYPE = 'location-selection';

// A model for the new question type
export class LocationModel extends Question {
  getType() {
    return CUSTOM_TYPE;
  }
}

// Register `LocationModel` as a model for the `book-appointment` type
export function registerLocation() {
  ElementFactory.Instance.registerElement(
    CUSTOM_TYPE,
    (name) => new LocationModel(name)
  );
}

// Specify display names for the question type and its properties
const locale = localization.getLocale('');
locale.qt[CUSTOM_TYPE] = 'Location Selection';

// Register an SVG icon for the question type
SvgRegistry.registerIconFromSvg(CUSTOM_TYPE, Icon);

// Add question type metadata for further serialization into JSON
Serializer.addClass(
  CUSTOM_TYPE,
  [],
  () => new LocationModel('Location'),
  'question'
);

// A class that renders questions of the new type in the UI
export class SurveyQuestionLocation extends SurveyQuestionElementBase {
  constructor(props) {
    super(props);
    this.state = { value: this.question.value };
  }

  get question() {
    return this.questionBase;
  }

  get value() {
    return this.question.value;
  }

  // Support the read-only and design modes
  get style() {
    return this.question.getPropertyValue('readOnly') ||
      this.question.isDesignMode
      ? { pointerEvents: 'none' }
      : undefined;
  }

  renderElement() {
    return (
      <div style={this.style}>
        <FormControl>
          {/* <FormLabel mb={1}>{field.label}</FormLabel> */}
          <Box zIndex={99}>
            <GenericObjectSelect<LocationFieldsFragment>
              isAsync
              isMulti
              defaultValue={this.question.value || []}
              onChange={(newValue) => {
                this.question.value = newValue;
              }}
              useLoadOptions={() =>
                useLoadLocations({
                  where: {
                    workspaceId: {
                      _eq: this.question.leadFormWorkspaceId as string,
                    },
                  },
                })
              }
              placeholder="Select location(s)"
            />
          </Box>
        </FormControl>
      </div>
    );
  }
}

// Register `SurveyQuestionLocation` as a class that renders `book-appointment` questions
ReactQuestionFactory.Instance.registerQuestion(CUSTOM_TYPE, (props) =>
  createElement(SurveyQuestionLocation, props)
);

PropertyGridEditorCollection.register({
  fit(prop) {
    return prop.type === 'location-selection';
  },
  getJSON() {
    return {
      type: CUSTOM_TYPE,
      colorPickerType: 'Compact',
    };
  },
});
