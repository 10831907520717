import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
} from 'recharts';
import { NodeViewWrapper } from '@tiptap/react';
import React, { ChangeEvent } from 'react';

export default (props) => {
  const data = JSON.parse(decodeURIComponent(props.node.attrs.data));

  return (
    <NodeViewWrapper className="embedded-chart">
      <LineChart
        width={600}
        height={300}
        data={data}
        margin={{ top: 5, right: 20, bottom: 5, left: 0 }}
      >
        <Line
          type="monotone"
          dataKey={props.node.attrs.yAxisKey}
          stroke="#8884d8"
        />
        <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
        <XAxis
          label={{
            value: props.node.attrs.xAxisLabel,
            position: 'insideBottom',
          }}
          dataKey={props.node.attrs.xAxisKey}
        />
        <YAxis
          label={{
            value: props.node.attrs.yAxisLabel,
            angle: -90,
            position: 'insideLeft',
          }}
        />
        <Tooltip />
      </LineChart>
    </NodeViewWrapper>
  );
};
