import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetAppointmentAvailabilityQueryVariables = Types.Exact<{
  locationId: Types.Scalars['String'];
  providerId?: Types.Maybe<Types.Scalars['String']>;
  serviceIds?: Types.Maybe<Array<Types.Maybe<Types.Scalars['String']>> | Types.Maybe<Types.Scalars['String']>>;
  day: Types.Scalars['String'];
  numberOfDays?: Types.Maybe<Types.Scalars['Int']>;
}>;


export type GetAppointmentAvailabilityQuery = { __typename?: 'query_root', getProviderAvailabilityForLocation?: { __typename?: 'ScheduleOutput', availability?: Array<{ __typename?: 'AvailabilityRange', start: string, end: string } | null | undefined> | null | undefined } | null | undefined };


export const GetAppointmentAvailabilityDocument = gql`
    query getAppointmentAvailability($locationId: String!, $providerId: String, $serviceIds: [String], $day: String!, $numberOfDays: Int) {
  getProviderAvailabilityForLocation(
    locationId: $locationId
    providerId: $providerId
    serviceIds: $serviceIds
    numberOfDays: $numberOfDays
    day: $day
  ) {
    availability {
      start
      end
    }
  }
}
    `;

/**
 * __useGetAppointmentAvailabilityQuery__
 *
 * To run a query within a React component, call `useGetAppointmentAvailabilityQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAppointmentAvailabilityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAppointmentAvailabilityQuery({
 *   variables: {
 *      locationId: // value for 'locationId'
 *      providerId: // value for 'providerId'
 *      serviceIds: // value for 'serviceIds'
 *      day: // value for 'day'
 *      numberOfDays: // value for 'numberOfDays'
 *   },
 * });
 */
export function useGetAppointmentAvailabilityQuery(baseOptions: Apollo.QueryHookOptions<GetAppointmentAvailabilityQuery, GetAppointmentAvailabilityQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAppointmentAvailabilityQuery, GetAppointmentAvailabilityQueryVariables>(GetAppointmentAvailabilityDocument, options);
      }
export function useGetAppointmentAvailabilityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAppointmentAvailabilityQuery, GetAppointmentAvailabilityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAppointmentAvailabilityQuery, GetAppointmentAvailabilityQueryVariables>(GetAppointmentAvailabilityDocument, options);
        }
export type GetAppointmentAvailabilityQueryHookResult = ReturnType<typeof useGetAppointmentAvailabilityQuery>;
export type GetAppointmentAvailabilityLazyQueryHookResult = ReturnType<typeof useGetAppointmentAvailabilityLazyQuery>;
export type GetAppointmentAvailabilityQueryResult = Apollo.QueryResult<GetAppointmentAvailabilityQuery, GetAppointmentAvailabilityQueryVariables>;