/* eslint-disable camelcase */
// Import the editor styles
import '@pqina/pintura/pintura.css';

// Import the editor default configuration
import {
  // Import the default image reader and writer
  createDefaultImageReader,
  createDefaultImageWriter,

  // The method used to register the plugins
  setPlugins,
  plugin_annotate,

  // The user interface and plugin locale objects
  locale_en_gb,
  plugin_crop_locale_en_gb,
  plugin_finetune_locale_en_gb,
  plugin_annotate_locale_en_gb,
  createMarkupEditorToolStyles,
  createMarkupEditorToolStyle,
  // Because we use the annotate plugin we also need
  // to import the markup editor locale and the shape preprocessor
  markup_editor_locale_en_gb,
  createDefaultShapePreprocessor,

  // Import the default configuration for the markup editor and finetune plugins
  markup_editor_defaults,
  createNode,
  appendNode,
  PinturaEditorDefaultOptions,
} from '@pqina/pintura';

// Import the editor component from `react-pintura`
import { PinturaEditor } from '@pqina/react-pintura';
import { Center } from '@chakra-ui/react';
import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { StorageType } from '@webapp/graphql';
import { uploadToS3 } from '@webapp/ui';
import { UseUploadToS3Params } from '@webapp/hooks';
import { ImageFormat, ExportFormat } from 'photoeditorsdk';
import { Photo as MSTPhoto } from '@webapp/state-models';
import { getProxyPhotoUrl } from '@webapp/utils';

setPlugins(plugin_annotate);
// get default properties
const editorConfig = {
  // This will read the image data (required)
  imageReader: createDefaultImageReader(),
  shapePreprocessor: createDefaultShapePreprocessor(),
  // This will write the output image
  imageWriter: createDefaultImageWriter(),
  // The markup editor default options, tools, shape style controls
  ...markup_editor_defaults,
  locale: {
    ...locale_en_gb,
    ...plugin_crop_locale_en_gb,
    ...plugin_finetune_locale_en_gb,
    ...plugin_annotate_locale_en_gb,
    ...markup_editor_locale_en_gb,
  },
};

/* eslint-disable-next-line */
export interface PinturaImageEditorProps {
  photo: MSTPhoto;
  editorConfigOverrides?: PinturaEditorDefaultOptions;
}

const downloadFile = (file) => {
  // Create a hidden link and set the URL using createObjectURL
  const link = document.createElement('a');
  link.style.display = 'none';
  link.href = URL.createObjectURL(file);
  link.download = file.name;

  // We need to add the link to the DOM for "click()" to work
  document.body.appendChild(link);
  link.click();

  // To make this work on Firefox we need to wait a short moment before clean up
  setTimeout(() => {
    URL.revokeObjectURL(link.href);
    link.parentNode.removeChild(link);
  }, 0);
};

type PinturaImageEditorHandle = {
  saveCurrentImage: () => void;
  imageState: any;
};

export const PinturaImageEditor = forwardRef<
  PinturaImageEditorHandle,
  PinturaImageEditorProps
>(({ photo, editorConfigOverrides }: PinturaImageEditorProps, ref) => {
  const editorRef = useRef(null);
  const [progress, setProgress] = useState<number>(0);
  const handleEditorProcess = (imageState) => {
    downloadFile(imageState.dest);
  };

  useImperativeHandle(ref, () => ({
    async saveCurrentImage(params?: UseUploadToS3Params): Promise<string> {
      if (editorRef) {
        const imageSrc = await editorRef?.current?.editor?.processImage();

        const file = new File(
          [imageSrc.dest as Blob],
          photo?.file?.filePath as string,
          {
            type: 'image/jpeg',
          }
        );

        const filePath = await uploadToS3({
          fileType: 'image/jpeg',
          fileContents: file,
          filePath: photo?.file?.filePath as string,
          storageType: StorageType.Private,
          onProgress: setProgress,
          ...params,
        });

        return { filePath, file };
      }

      return {};
    },
    imageState: () => editorRef?.current?.editor?.imageState,
  }));

  const imageUrl = photo?.file?.url.includes('http')
    ? getProxyPhotoUrl(photo?.file?.url, 'full')
    : `/assets/${photo?.file?.url}`;
  const willRenderShapePresetToolbar = (nodes, addPreset) => {
    // create smile button
    const smileButton = createNode('Button', 'smile-button', {
      label: 'Add smile',
      onclick: () => addPreset('😄'),
    });

    // add it to the node tree
    appendNode(smileButton, nodes);

    // return the new node tree
    return [];
  };
  return (
    <PinturaEditor
      ref={editorRef}
      {...editorConfig}
      layoutDirectionPreference="vertical"
      layoutHorizontalUtilsPreference="left"
      src={imageUrl}
      previewUpscale={true}
      enableButtonExport={false}
      markupEditorToolStyles={createMarkupEditorToolStyles({
        // create the text tool style and override fontSize property
        text: createMarkupEditorToolStyle('text', {
          fontSize: '4%',
        }),
      })}
      {...(editorConfigOverrides && editorConfigOverrides)}
    ></PinturaEditor>
  );
});

export default PinturaImageEditor;
