import { types } from 'mobx-state-tree';

const JsonValue: any = types.union(
  types.string,
  types.number,
  types.integer,
  types.Date,
  types.boolean,
  types.map(types.late(() => AnyJsonValue)),
  types.array(types.late(() => AnyJsonValue)),
  types.undefined,
  types.null
);
export const AnyJsonValue: any = types.union(
  types.string,
  types.number,
  types.integer,
  types.Date,
  types.boolean,
  types.map(types.late(() => JsonValue)),
  types.array(types.late(() => JsonValue)),
  types.undefined,
  types.null
);
