import * as Types from '../../generated/graphql';

import { gql } from '@apollo/client';
import { DocumentFieldsFragmentDoc } from './documentFields.fragment.generated';
import { SignatureFieldsFragmentDoc } from './signatureFields.fragment.generated';
export type ConsentFieldsFragment = { __typename?: 'consent', id: any, title: string, description?: string | null | undefined, recurrence: Types.RecurrenceType_Enum, requiredAll: boolean, isPartialMediaConsent: boolean, isFullMediaConsent: boolean, requireProviderSignature?: boolean | null | undefined, requireWitnessSignature?: boolean | null | undefined, createdAt: any, workspaceId: any, surveyJSJson?: any | null | undefined, workspace: { __typename?: 'workspace', name: string }, document?: { __typename?: 'document', id: any, title?: string | null | undefined, filePath: string, documentType: string, documentTags: Array<{ __typename?: 'documentTag', id: any, tag: { __typename?: 'tag', color?: string | null | undefined, id: any, title: string } }>, file?: { __typename?: 'FileOnlyURL', url: string } | null | undefined } | null | undefined, serviceConsents: Array<{ __typename?: 'serviceConsent', id: any, service: { __typename?: 'service', id: any, name: string } }>, patientConsents: Array<{ __typename?: 'patientConsent', id: any, name: string, date: any, providerName?: string | null | undefined, providerDate?: any | null | undefined, witnessName?: string | null | undefined, witnessDate?: any | null | undefined, appointmentId: any, signature?: { __typename?: 'signature', id: any, filePath: string, file?: { __typename?: 'FileOnlyURL', url: string } | null | undefined } | null | undefined, providerSignature?: { __typename?: 'signature', id: any, filePath: string, file?: { __typename?: 'FileOnlyURL', url: string } | null | undefined } | null | undefined, witnessSignature?: { __typename?: 'signature', id: any, filePath: string, file?: { __typename?: 'FileOnlyURL', url: string } | null | undefined } | null | undefined }> };

export const ConsentFieldsFragmentDoc = gql`
    fragment ConsentFields on consent {
  id
  title
  description
  recurrence
  requiredAll
  isPartialMediaConsent
  isFullMediaConsent
  requireProviderSignature
  requireWitnessSignature
  createdAt
  workspaceId
  workspace {
    name
  }
  surveyJSJson
  document {
    ...DocumentFields
  }
  serviceConsents {
    id
    service {
      id
      name
    }
  }
  patientConsents {
    id
    name
    date
    providerName
    providerDate
    witnessName
    witnessDate
    appointmentId
    signature {
      ...SignatureFields
    }
    providerSignature {
      ...SignatureFields
    }
    witnessSignature {
      ...SignatureFields
    }
  }
}
    ${DocumentFieldsFragmentDoc}
${SignatureFieldsFragmentDoc}`;