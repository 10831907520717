import * as Types from '../../generated/graphql';

import { gql } from '@apollo/client';
export type DocumentFieldsFragment = { __typename?: 'document', id: any, title?: string | null | undefined, filePath: string, documentType: string, documentTags: Array<{ __typename?: 'documentTag', id: any, tag: { __typename?: 'tag', color?: string | null | undefined, id: any, title: string } }>, file?: { __typename?: 'FileOnlyURL', url: string } | null | undefined };

export const DocumentFieldsFragmentDoc = gql`
    fragment DocumentFields on document {
  id
  title
  filePath
  documentTags {
    id
    tag {
      color
      id
      title
    }
  }
  documentType
  file {
    url
  }
}
    `;