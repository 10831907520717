/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useRef, useCallback } from 'react';
import {
  Input,
  Box,
  List,
  ListItem,
  Text,
  VStack,
  useColorModeValue,
  Spinner,
  IconButton,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react';
import { useSearchNoteTitleLazyQuery } from '@webapp/graphql';
import debounce from 'lodash/debounce';
import { useStores } from '@webapp/state-models';
import { CheckIcon } from '@chakra-ui/icons';

export interface ClinicalNoteTypeaheadProps {
  onSaveNoteTitle: (value: string) => void;
  defaultValue: string;
  containerStyle?: BoxProps;
}

const ClinicalNoteTypeahead = ({
  onSaveNoteTitle,
  defaultValue,
  containerStyle = {},
}: ClinicalNoteTypeaheadProps) => {
  const { workspace } = useStores();
  const [inputValue, setInputValue] = useState(defaultValue || '');
  const [suggestions, setSuggestions] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [highlightedIndex, setHighlightedIndex] = useState(-1);
  const [isLoading, setIsLoading] = useState(false);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const inputRef = useRef(null);
  const listRef = useRef(null);

  const [searchNoteTitle, { loading, data }] = useSearchNoteTitleLazyQuery();

  const bgColor = useColorModeValue('white', 'gray.700');
  const hoverBgColor = useColorModeValue('gray.100', 'gray.600');
  const highlightBgColor = useColorModeValue('blue.100', 'blue.600');

  const debouncedSearch = useCallback(
    debounce((searchTerm) => {
      if (
        searchTerm.length > 0 &&
        workspace?.id &&
        searchTerm !== defaultValue
      ) {
        setIsLoading(true);
        searchNoteTitle({
          variables: {
            search: searchTerm,
            workspaceId: workspace?.id,
          },
        });
      } else {
        setSuggestions([]);
        setIsOpen(false);
        setIsLoading(false);
      }
    }, 300),
    [searchNoteTitle, workspace?.id, defaultValue]
  );

  useEffect(() => {
    debouncedSearch(inputValue);
    return () => {
      debouncedSearch.cancel();
    };
  }, [inputValue, debouncedSearch]);

  useEffect(() => {
    if (data && data.search_note_title) {
      setSuggestions(data.search_note_title);
      setIsLoading(false);
      setIsOpen(data.search_note_title.length > 0);
    }
  }, [data]);

  const handleInputChange = (e) => {
    const newValue = e.target.value;
    setInputValue(newValue);
    setHasUnsavedChanges(newValue !== defaultValue);
    if (newValue.length > 0) {
      setIsOpen(true);
      setIsLoading(true);
    } else {
      setIsOpen(false);
      setIsLoading(false);
    }
  };

  const handleSuggestionClick = (suggestion) => {
    setInputValue(suggestion.title);
    setSuggestions([]);
    setIsOpen(false);
    setIsLoading(false);
    setHasUnsavedChanges(suggestion.title !== defaultValue);
    inputRef.current?.focus();
  };

  const handleSave = () => {
    onSaveNoteTitle(inputValue);
    setHasUnsavedChanges(false);
  };

  const handleKeyDown = (e) => {
    if (!isOpen) return;

    switch (e.key) {
      case 'ArrowDown':
        e.preventDefault();
        setHighlightedIndex((prevIndex) =>
          prevIndex < suggestions.length - 1 ? prevIndex + 1 : prevIndex
        );
        break;
      case 'ArrowUp':
        e.preventDefault();
        setHighlightedIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : 0));
        break;
      case 'Enter':
        e.preventDefault();
        if (highlightedIndex >= 0) {
          handleSuggestionClick(suggestions[highlightedIndex]);
        } else if (suggestions.length > 0) {
          handleSuggestionClick(suggestions[0]);
        }
        break;
      case 'Tab':
        if (highlightedIndex >= 0) {
          e.preventDefault();
          handleSuggestionClick(suggestions[highlightedIndex]);
        } else if (suggestions.length > 0) {
          e.preventDefault();
          handleSuggestionClick(suggestions[0]);
        }
        break;
      case 'Escape':
        setSuggestions([]);
        setIsOpen(false);
        setIsLoading(false);
        setHighlightedIndex(-1);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (highlightedIndex >= 0 && listRef.current) {
      const highlightedElement = listRef.current.children[highlightedIndex];
      highlightedElement.scrollIntoView({ block: 'nearest' });
    }
  }, [highlightedIndex]);

  return (
    <Box position="relative" width="100%" minWidth="md" {...containerStyle}>
      <VStack align="stretch" spacing={1}>
        <Text as="label" htmlFor="noteTitle" fontSize="sm" fontWeight="medium">
          Note Title
        </Text>
        <InputGroup>
          <Input
            minW="md"
            id="noteTitle"
            ref={inputRef}
            value={inputValue}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            onFocus={() => inputValue.length > 0 && setIsOpen(true)}
            onBlur={() => setTimeout(() => setIsOpen(false), 200)}
            placeholder="Enter note title or select from suggestions..."
            aria-autocomplete="list"
            aria-controls="suggestion-list"
            aria-expanded={isOpen}
          />
          <InputRightElement>
            <IconButton
              aria-label="Save note title"
              icon={<CheckIcon size={18} />}
              size="sm"
              onClick={handleSave}
              isDisabled={!hasUnsavedChanges}
            />
          </InputRightElement>
        </InputGroup>
      </VStack>
      {isOpen && (isLoading || suggestions.length > 0) && (
        <List
          id="suggestion-list"
          ref={listRef}
          position="absolute"
          zIndex={1}
          width="100%"
          bg={bgColor}
          borderWidth={1}
          borderColor="gray.200"
          borderRadius="md"
          mt={1}
          maxHeight="60vh"
          overflowY="auto"
          boxShadow="md"
          role="listbox"
        >
          {isLoading ? (
            <ListItem px={4} py={2}>
              <Spinner size="sm" /> Loading note title suggestions...
            </ListItem>
          ) : suggestions.length > 0 ? (
            suggestions.map((suggestion, index) => (
              <ListItem
                key={index}
                px={4}
                py={2}
                cursor="pointer"
                bg={
                  index === highlightedIndex ? highlightBgColor : 'transparent'
                }
                _hover={{ bg: hoverBgColor }}
                onClick={() => handleSuggestionClick(suggestion)}
                role="option"
                aria-selected={index === highlightedIndex}
              >
                {suggestion.title}
              </ListItem>
            ))
          ) : (
            <ListItem px={4} py={2}>
              No matching titles found
            </ListItem>
          )}
        </List>
      )}
    </Box>
  );
};

export default ClinicalNoteTypeahead;
