import * as Types from '../../generated/graphql';

import { gql } from '@apollo/client';
export type AppointmentTypeFieldsFragment = { __typename?: 'appointmentType', id: any, name: string, color?: string | null | undefined, maxPerDay?: number | null | undefined };

export const AppointmentTypeFieldsFragmentDoc = gql`
    fragment AppointmentTypeFields on appointmentType {
  id
  name
  color
  maxPerDay
}
    `;