import * as Types from '../../generated/graphql';

import { gql } from '@apollo/client';
export type ServiceConsumableTypeFieldsFragment = { __typename?: 'serviceConsumableType', id: any, numberOfUnits: any, pricePerUnit: number, consumableType: { __typename?: 'consumableType', id: any, name: string, defaultNumberOfUnits: any, defaultPricePerUnit: number, unitStep: any } };

export const ServiceConsumableTypeFieldsFragmentDoc = gql`
    fragment ServiceConsumableTypeFields on serviceConsumableType {
  id
  consumableType {
    id
    name
    defaultNumberOfUnits
    defaultPricePerUnit
    unitStep
  }
  numberOfUnits
  pricePerUnit
}
    `;