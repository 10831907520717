import { useLoadServices } from '@webapp/webapp/hooks';
import { ServiceFieldsFragment } from '@webapp/graphql';
import { Input, Select } from '@chakra-ui/react';
import { useState } from 'react';
import { GenericObjectSelect } from '@webapp/ui';

const getDurationPayload = (unit: string, duration: number): string => {
  switch (unit) {
    case 'Hours':
      return `${duration} hours`;
    case 'Days':
      return `${duration} days`;
    case 'Weeks':
      return `${duration} weeks`;
    case 'Months':
      return `${duration} months`;
    default:
      throw new Error(`Invalid time unit ${unit}`);
  }
};

export interface AudienceFilterTimePredicateProps {
  onChange: ({ label, value }: { label: string; value: string }) => {};
}

export const AudienceFilterTimeServicePredicate = ({
  onChange,
}: AudienceFilterTimePredicateProps) => {
  const [service, setService] = useState({ label: 'Any', value: '*' });
  const [duration, setDuration] = useState(0);
  const [unit, setUnit] = useState('Hours');
  const [astVal, setAstVal] = useState('');

  const currentAstVal = `${getDurationPayload(unit, duration)}^^${
    service.value
  }`;
  if (astVal !== currentAstVal) {
    const label = `${duration} ${unit} for ${service.label}`;
    onChange({ label, value: currentAstVal });
    setAstVal(currentAstVal);
  }

  return (
    <>
      <Input
        size="xs"
        onChange={(e) => setDuration(parseInt(e.target.value))}
      />
      <Select size="xs" onChange={(e) => setUnit(e.target.value)}>
        <option key="hours" value="Hours">
          Hours
        </option>
        <option key="days" value="Days">
          Days
        </option>
        <option key="weeks" value="Weeks">
          Weeks
        </option>
        <option key="months" value="Months">
          Months
        </option>
      </Select>
      <GenericObjectSelect<ServiceFieldsFragment>
        containerStyle={{
          minW: '200px',
        }}
        isAsync
        onChange={(result: any) =>
          setService({ label: result?.label, value: result?.value })
        }
        // @ts-ignore
        useLoadOptions={useLoadServices}
        placeholder="Any Service"
      />
    </>
  );
};

export const AudienceFilterTimePatientCreationPredicate = ({
  onChange,
}: AudienceFilterTimePredicateProps) => {
  const [duration, setDuration] = useState(0);
  const [unit, setUnit] = useState('Hours');
  const [astVal, setAstVal] = useState('');

  const currentAstVal = `${getDurationPayload(unit, duration)}`;
  if (astVal !== currentAstVal) {
    const label = `${duration} ${unit}`;
    onChange({ label, value: currentAstVal });
    setAstVal(currentAstVal);
  }

  return (
    <>
      <Input
        size="xs"
        onChange={(e) => setDuration(parseInt(e.target.value))}
      />
      <Select size="xs" onChange={(e) => setUnit(e.target.value)}>
        <option key="hours" value="Hours">
          Hours
        </option>
        <option key="days" value="Days">
          Days
        </option>
        <option key="weeks" value="Weeks">
          Weeks
        </option>
        <option key="months" value="Months">
          Months
        </option>
      </Select>
    </>
  );
};
