import { Flex, FormLabel, Skeleton } from '@chakra-ui/react';
import { FormInputProps } from '@webapp/types';
import { DEFAULT_LABEL_STYLE } from './constants';

function FormInputLoading(props: FormInputProps) {
  return (
    <Flex
      alignItems={props.labelAlign}
      className="input-outer"
      flexDirection="column"
    >
      {Boolean(props.label) && (
        <FormLabel
          className="input-label"
          margin="0 0 6px 0"
          {...(props.labelStyle ?? DEFAULT_LABEL_STYLE)}
        >
          {props.label}
        </FormLabel>
      )}
      <Skeleton
        endColor="gray.300"
        height="30px"
        startColor="gray.200"
        width="200px"
      />
    </Flex>
  );
}

export default FormInputLoading;
