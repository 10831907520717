import * as Types from '../../generated/graphql';

import { gql } from '@apollo/client';
import { SignatureFieldsFragmentDoc } from './signatureFields.fragment.generated';
export type NoteFieldsFragment = { __typename?: 'note', isLocked?: boolean | null | undefined, createdAt: any, title?: string | null | undefined, isGoodFaithExam?: boolean | null | undefined, id: any, isInternal: boolean, message: string, appointmentId?: any | null | undefined, updatedAt: any, author: { __typename?: 'user', firstName: string, id: any, lastName: string, title?: string | null | undefined, profilePicture?: string | null | undefined }, editedBy?: { __typename?: 'user', firstName: string, id: any, lastName: string, title?: string | null | undefined } | null | undefined, noteSignatures: Array<{ __typename?: 'noteSignature', signature?: { __typename?: 'signature', id: any, filePath: string, provider?: { __typename?: 'provider', firstName: string, id: any, lastName: string, title?: string | null | undefined } | null | undefined, file?: { __typename?: 'FileOnlyURL', url: string } | null | undefined } | null | undefined }>, assignedToProvider?: { __typename?: 'provider', id: any, firstName: string, lastName: string, title?: string | null | undefined } | null | undefined, patient: { __typename?: 'patient', firstName: string, id: any, lastName: string } };

export const NoteFieldsFragmentDoc = gql`
    fragment NoteFields on note {
  author {
    firstName
    id
    lastName
    title
    profilePicture
  }
  editedBy {
    firstName
    id
    lastName
    title
  }
  isLocked
  createdAt
  title
  isGoodFaithExam
  id
  noteSignatures {
    signature {
      ...SignatureFields
      provider {
        firstName
        id
        lastName
        title
      }
    }
  }
  isInternal
  message
  assignedToProvider {
    id
    firstName
    lastName
    title
  }
  appointmentId
  patient {
    firstName
    id
    lastName
  }
  updatedAt
}
    ${SignatureFieldsFragmentDoc}`;