import * as Types from '../../generated/graphql';

import { gql } from '@apollo/client';
export type RoomFieldsFragment = { __typename?: 'room', id: any, name: string };

export const RoomFieldsFragmentDoc = gql`
    fragment RoomFields on room {
  id
  name
}
    `;