import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetConsumableTypesAggregateQueryVariables = Types.Exact<{
  where?: Types.Maybe<Types.ConsumableType_Bool_Exp>;
}>;


export type GetConsumableTypesAggregateQuery = { __typename?: 'query_root', consumableType_aggregate: { __typename?: 'consumableType_aggregate', aggregate?: { __typename?: 'consumableType_aggregate_fields', count: number } | null | undefined } };


export const GetConsumableTypesAggregateDocument = gql`
    query getConsumableTypesAggregate($where: consumableType_bool_exp) {
  consumableType_aggregate(where: $where) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetConsumableTypesAggregateQuery__
 *
 * To run a query within a React component, call `useGetConsumableTypesAggregateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetConsumableTypesAggregateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetConsumableTypesAggregateQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetConsumableTypesAggregateQuery(baseOptions?: Apollo.QueryHookOptions<GetConsumableTypesAggregateQuery, GetConsumableTypesAggregateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetConsumableTypesAggregateQuery, GetConsumableTypesAggregateQueryVariables>(GetConsumableTypesAggregateDocument, options);
      }
export function useGetConsumableTypesAggregateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetConsumableTypesAggregateQuery, GetConsumableTypesAggregateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetConsumableTypesAggregateQuery, GetConsumableTypesAggregateQueryVariables>(GetConsumableTypesAggregateDocument, options);
        }
export type GetConsumableTypesAggregateQueryHookResult = ReturnType<typeof useGetConsumableTypesAggregateQuery>;
export type GetConsumableTypesAggregateLazyQueryHookResult = ReturnType<typeof useGetConsumableTypesAggregateLazyQuery>;
export type GetConsumableTypesAggregateQueryResult = Apollo.QueryResult<GetConsumableTypesAggregateQuery, GetConsumableTypesAggregateQueryVariables>;