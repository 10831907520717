import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { Stack, Text } from '@chakra-ui/react';

const replaceMergeTags = (
  content: string,
  mergeContext: Record<string, string>
) =>
  content.replace(/\{\{([^}]+)\}\}/g, (match, p1) => {
    const replacement = mergeContext[p1] || 'No Value';
    if (typeof replacement === 'string' && replacement.includes('table')) {
      return `<div>${replacement}</div>`;
    }

    return replacement;
  });

export const MentionList = forwardRef((props, ref) => {
  const propsAny = props as any;
  const { editor, mergeTagContext } = propsAny;

  const [selectedIndex, setSelectedIndex] = useState(0);
  const [hasKeyed, setHasKeyed] = useState(false);

  const selectItem = (index: any) => {
    const item = propsAny.items[index];

    if (item) {
      editor.commands.deleteRange(propsAny.range);
      editor.commands.insertContent(
        replaceMergeTags(item.content, mergeTagContext ?? {})
      );
    }
  };

  const upHandler = () => {
    setSelectedIndex(
      (selectedIndex + propsAny.items.length - 1) % propsAny.items.length
    );
    setHasKeyed(true);
  };

  const downHandler = () => {
    setSelectedIndex((selectedIndex + 1) % propsAny.items.length);
    setHasKeyed(true);
  };

  const enterHandler = useCallback(() => {
    selectItem(selectedIndex);
  }, [selectedIndex]);

  useEffect(() => setSelectedIndex(0), [propsAny.items]);

  useImperativeHandle(ref, () => ({
    onKeyDown: ({ event }: { event: any }) => {
      if (event.key === 'ArrowUp') {
        upHandler();
        return true;
      }

      if (event.key === 'ArrowDown') {
        downHandler();
        return true;
      }

      if (event.key === 'Enter') {
        enterHandler();
        return true;
      }

      return false;
    },
  }));

  return (
    <Stack shadow="lg" rounded="md" bg="white" p={2} maxH={300} overflow="auto">
      {propsAny.items.length ? (
        <Stack>
          {propsAny.items.map((item: any, index: number) => (
            <Stack
              key={index}
              onClick={() => selectItem(index)}
              backgroundColor={
                selectedIndex === index && hasKeyed ? 'teal.50' : 'gray.50'
              }
              rounded={'md'}
              p={2}
              justifyContent="space-evenly"
              width="300px"
              borderColor={
                selectedIndex === index && hasKeyed ? 'teal.500' : 'gray.50'
              }
              borderWidth={1}
              cursor="pointer"
              _hover={{
                backgroundColor: 'teal.50',
                border: '1px solid teal',
              }}
            >
              <Text color="teal.500" fontSize="md" fontWeight="bold">
                {item.title}
              </Text>
              <Text color="gray.600" fontSize="sm">
                {item.callPhrase}
              </Text>
            </Stack>
          ))}
        </Stack>
      ) : (
        <div className="item">No result</div>
      )}
    </Stack>
  );
});

export default MentionList;
