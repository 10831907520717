import * as Types from '../../generated/graphql';

import { gql } from '@apollo/client';
export type ResolvedAppointmentConsentsFieldsFragment = { __typename?: 'ResolvedAppointmentConsentsOutput', hasOutstanding: boolean, consents: Array<{ __typename?: 'MinimalResolvedConsent', id: any, title: string, patientConsentId: string, completed: boolean }>, outstanding: Array<{ __typename?: 'MinimalResolvedConsent', id: any, title: string }> };

export const ResolvedAppointmentConsentsFieldsFragmentDoc = gql`
    fragment ResolvedAppointmentConsentsFields on ResolvedAppointmentConsentsOutput {
  consents {
    id
    title
    patientConsentId
    completed
  }
  outstanding {
    id
    title
  }
  hasOutstanding
}
    `;