import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type InsertEmailTemplateMutationVariables = Types.Exact<{
  emailTemplate: Types.EmailTemplate_Insert_Input;
}>;


export type InsertEmailTemplateMutation = { __typename?: 'mutation_root', insert_emailTemplate?: { __typename?: 'emailTemplate_mutation_response', returning: Array<{ __typename?: 'emailTemplate', id: any }> } | null | undefined };


export const InsertEmailTemplateDocument = gql`
    mutation insertEmailTemplate($emailTemplate: emailTemplate_insert_input!) {
  insert_emailTemplate(objects: [$emailTemplate]) {
    returning {
      id
    }
  }
}
    `;
export type InsertEmailTemplateMutationFn = Apollo.MutationFunction<InsertEmailTemplateMutation, InsertEmailTemplateMutationVariables>;

/**
 * __useInsertEmailTemplateMutation__
 *
 * To run a mutation, you first call `useInsertEmailTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertEmailTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertEmailTemplateMutation, { data, loading, error }] = useInsertEmailTemplateMutation({
 *   variables: {
 *      emailTemplate: // value for 'emailTemplate'
 *   },
 * });
 */
export function useInsertEmailTemplateMutation(baseOptions?: Apollo.MutationHookOptions<InsertEmailTemplateMutation, InsertEmailTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertEmailTemplateMutation, InsertEmailTemplateMutationVariables>(InsertEmailTemplateDocument, options);
      }
export type InsertEmailTemplateMutationHookResult = ReturnType<typeof useInsertEmailTemplateMutation>;
export type InsertEmailTemplateMutationResult = Apollo.MutationResult<InsertEmailTemplateMutation>;
export type InsertEmailTemplateMutationOptions = Apollo.BaseMutationOptions<InsertEmailTemplateMutation, InsertEmailTemplateMutationVariables>;