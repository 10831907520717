import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetAvailabilityForMultipleServicesQueryVariables = Types.Exact<{
  locationId: Types.Scalars['uuid'];
  serviceIds: Array<Types.Maybe<Types.Scalars['uuid']>> | Types.Maybe<Types.Scalars['uuid']>;
  day: Types.Scalars['String'];
  numberOfDays?: Types.Maybe<Types.Scalars['Int']>;
  providerId?: Types.Maybe<Types.Scalars['uuid']>;
  isDayAvailableCheck?: Types.Maybe<Types.Scalars['Boolean']>;
}>;


export type GetAvailabilityForMultipleServicesQuery = { __typename?: 'query_root', getAvailabilityForMultipleServices?: Array<{ __typename?: 'MultipleServicesAvailabilityOutput', start: string, formattedDay: string, dateTime: string, providers: Array<{ __typename?: 'AvailableProviderOutput', id: any, firstName: string, lastName: string, profilePicture?: string | null | undefined, serviceIds: Array<any | null | undefined> } | null | undefined> } | null | undefined> | null | undefined };


export const GetAvailabilityForMultipleServicesDocument = gql`
    query GetAvailabilityForMultipleServices($locationId: uuid!, $serviceIds: [uuid]!, $day: String!, $numberOfDays: Int, $providerId: uuid, $isDayAvailableCheck: Boolean) {
  getAvailabilityForMultipleServices(
    locationId: $locationId
    serviceIds: $serviceIds
    day: $day
    numberOfDays: $numberOfDays
    providerId: $providerId
    isDayAvailableCheck: $isDayAvailableCheck
  ) {
    start
    formattedDay
    dateTime
    providers {
      id
      firstName
      lastName
      profilePicture
      serviceIds
    }
  }
}
    `;

/**
 * __useGetAvailabilityForMultipleServicesQuery__
 *
 * To run a query within a React component, call `useGetAvailabilityForMultipleServicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAvailabilityForMultipleServicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAvailabilityForMultipleServicesQuery({
 *   variables: {
 *      locationId: // value for 'locationId'
 *      serviceIds: // value for 'serviceIds'
 *      day: // value for 'day'
 *      numberOfDays: // value for 'numberOfDays'
 *      providerId: // value for 'providerId'
 *      isDayAvailableCheck: // value for 'isDayAvailableCheck'
 *   },
 * });
 */
export function useGetAvailabilityForMultipleServicesQuery(baseOptions: Apollo.QueryHookOptions<GetAvailabilityForMultipleServicesQuery, GetAvailabilityForMultipleServicesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAvailabilityForMultipleServicesQuery, GetAvailabilityForMultipleServicesQueryVariables>(GetAvailabilityForMultipleServicesDocument, options);
      }
export function useGetAvailabilityForMultipleServicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAvailabilityForMultipleServicesQuery, GetAvailabilityForMultipleServicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAvailabilityForMultipleServicesQuery, GetAvailabilityForMultipleServicesQueryVariables>(GetAvailabilityForMultipleServicesDocument, options);
        }
export type GetAvailabilityForMultipleServicesQueryHookResult = ReturnType<typeof useGetAvailabilityForMultipleServicesQuery>;
export type GetAvailabilityForMultipleServicesLazyQueryHookResult = ReturnType<typeof useGetAvailabilityForMultipleServicesLazyQuery>;
export type GetAvailabilityForMultipleServicesQueryResult = Apollo.QueryResult<GetAvailabilityForMultipleServicesQuery, GetAvailabilityForMultipleServicesQueryVariables>;