import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type ExportAudienceV1QueryVariables = Types.Exact<{
  ast: Types.Scalars['jsonb'];
}>;


export type ExportAudienceV1Query = { __typename?: 'query_root', audienceResults?: Array<{ __typename?: 'AudienceResult', patientWorkspace?: { __typename?: 'patientWorkspace', firstName?: string | null | undefined, lastName?: string | null | undefined, dob?: any | null | undefined, email?: string | null | undefined, phoneNumber?: string | null | undefined, emailMarketingEnabled: boolean, smsMarketingEnabled?: boolean | null | undefined, phoneMarketingEnabled?: boolean | null | undefined } | null | undefined } | null | undefined> | null | undefined };


export const ExportAudienceV1Document = gql`
    query exportAudienceV1($ast: jsonb!) {
  audienceResults(ast: $ast) {
    patientWorkspace {
      firstName
      lastName
      dob
      email
      phoneNumber
      emailMarketingEnabled
      smsMarketingEnabled
      phoneMarketingEnabled
    }
  }
}
    `;

/**
 * __useExportAudienceV1Query__
 *
 * To run a query within a React component, call `useExportAudienceV1Query` and pass it any options that fit your needs.
 * When your component renders, `useExportAudienceV1Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportAudienceV1Query({
 *   variables: {
 *      ast: // value for 'ast'
 *   },
 * });
 */
export function useExportAudienceV1Query(baseOptions: Apollo.QueryHookOptions<ExportAudienceV1Query, ExportAudienceV1QueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExportAudienceV1Query, ExportAudienceV1QueryVariables>(ExportAudienceV1Document, options);
      }
export function useExportAudienceV1LazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExportAudienceV1Query, ExportAudienceV1QueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExportAudienceV1Query, ExportAudienceV1QueryVariables>(ExportAudienceV1Document, options);
        }
export type ExportAudienceV1QueryHookResult = ReturnType<typeof useExportAudienceV1Query>;
export type ExportAudienceV1LazyQueryHookResult = ReturnType<typeof useExportAudienceV1LazyQuery>;
export type ExportAudienceV1QueryResult = Apollo.QueryResult<ExportAudienceV1Query, ExportAudienceV1QueryVariables>;