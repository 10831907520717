/* eslint-disable camelcase */
import {
  Service,
  SearchServicesDocument,
  SearchServicesQueryResult,
  SearchServicesQueryVariables,
  Service_Bool_Exp,
} from '@webapp/graphql';
import { ObjectOption } from '@webapp/types';
import { useState } from 'react';
import { groupBy, orderBy } from 'lodash';
import { useApolloClient } from '@apollo/client';

export interface UseLoadServices {
  called: boolean;
  loading: boolean;

  search: (searchValue: string) => Promise<ObjectOption<Service>[]>;
}

export interface UseLoadServicesProps {
  groupByCategory?: boolean;
  showProducts?: boolean;
  where?: Service_Bool_Exp;
  nameAsValue?: boolean;
  locationId?: string;
  locationIds?: string[];
}

export function useLoadServices({
  nameAsValue = false,
  groupByCategory,
  showProducts = false,
  where,
  locationId,
  locationIds,
}: UseLoadServicesProps = {}): UseLoadServices {
  const client = useApolloClient();

  return {
    search: async (searchValue: string) => {
      const result = await client.query<
        SearchServicesQueryResult['data'],
        SearchServicesQueryVariables
      >({
        query: SearchServicesDocument,
        variables: {
          where: {
            name: { _ilike: `%${searchValue}%` },
            active: { _eq: true },
            archived: { _eq: false },
            ...(locationIds && {
              locationServices: {
                locationId: {
                  _in: locationIds,
                },
              },
            }),
            ...(showProducts ? {} : { isProduct: { _eq: false } }),
            ...(where ?? {}),
          },
        },
      });
      //   setLoading(false);

      let services = result.data?.service ?? [];

      // Check for location price or tax overrides
      services = services.map((service) => {
        const mappedService = { ...service };
        let locationPrice;
        let locationTaxRate;

        if (locationId) {
          const relevantLocationService = service.locationServices.find(
            (locationService) => locationService.locationId === locationId
          );
          if (relevantLocationService) {
            locationPrice = relevantLocationService.price;
            locationTaxRate = relevantLocationService.taxRate;
          }
        }

        if (locationPrice) {
          mappedService.price = locationPrice;
        }

        if (locationTaxRate) {
          mappedService.taxRate = locationTaxRate;
        }

        return mappedService;
      });

      const groupedServices = Object.entries(
        groupBy(
          services.map(
            (service): ObjectOption<Service> => ({
              label: service.name,
              value: nameAsValue ? service.name : service.id,
              object: service as Service,
            })
          ),
          'object.serviceCategory.title'
        )
      );

      if (groupByCategory && groupedServices.length > 1) {
        return groupedServices
          .sort(([categoryA], [categoryB]) =>
            categoryA.localeCompare(categoryB)
          )
          .map(([category, options]) => ({
            label:
              category.toLowerCase() === 'undefined'
                ? 'Uncategorized'
                : category,
            options: orderBy(options, 'label'),
          }));
      }
      return orderBy(services, 'label').map(
        (service): ObjectOption<Service> => ({
          label: service.name,
          value: nameAsValue ? service.name : service.id,
          object: service as Service,
        })
      );
    },
  };
}

export default useLoadServices;
