/* eslint-disable max-classes-per-file */
import React, { createElement } from 'react';
import { ElementFactory, Question, Serializer } from 'survey-core';
import {
  SurveyQuestionElementBase,
  ReactQuestionFactory,
} from 'survey-react-ui';
import { localization } from 'survey-creator-core';
import WrappedConsentText from './WrappedConsentText';

const CUSTOM_TYPE = 'custom-consent-text';

// Model for the new question type
export class CustomConsentTextModel extends Question {
  getType() {
    return CUSTOM_TYPE;
  }

  get consentText() {
    return this.getPropertyValue('consentText');
  }

  set consentText(val) {
    this.setPropertyValue('consentText', val);
  }
}

// Register CustomConsentTextModel
export function registerCustomConsentText() {
  ElementFactory.Instance.registerElement(
    CUSTOM_TYPE,
    (name) => new CustomConsentTextModel(name)
  );
}

// Set display name for the question type
const locale = localization.getLocale('');
locale.qt[CUSTOM_TYPE] = 'Custom Consent Text';

// Add question type metadata
Serializer.addClass(
  CUSTOM_TYPE,
  [
    {
      name: 'consentText',
      default: 'Please read the following consent text:',
    },
  ],
  () => new CustomConsentTextModel(''),
  'question'
);

// Class that renders questions of the new type in the UI
export class SurveyQuestionConsentText extends SurveyQuestionElementBase {
  get question() {
    return this.questionBase;
  }

  onValueChange = (v: string) => {
    this.question.value = v;
  };

  renderElement() {
    return (
      <WrappedConsentText
        question={this.question}
        isDesignMode={this.question.isDesignMode}
        onValueChange={this.onValueChange}
      />
    );
  }
}

// Register SurveyQuestionConsentText
ReactQuestionFactory.Instance.registerQuestion(CUSTOM_TYPE, (props) =>
  createElement(SurveyQuestionConsentText, props)
);
