/* eslint-disable import/prefer-default-export */
import { Box } from '@chakra-ui/react';
import { useCountAudienceQuery } from '@webapp/graphql';

export interface AudienceCountProps {
  audienceId?: string | null;
  ast?: any;
}

export const AudienceCount = ({ audienceId, ast }: AudienceCountProps) => {
  const { data, loading } = useCountAudienceQuery({
    variables: { audienceId, ast },
  });

  if (loading) return <>Loading...</>;

  return (
    <Box p="2" borderRadius="md" fontSize="sm" bg="gray.100">
      {data?.audienceCount?.count} total recipients
    </Box>
  );
};
