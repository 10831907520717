import * as Types from '../../generated/graphql';

import { gql } from '@apollo/client';
export type LeadFormLinkFieldsFragment = { __typename?: 'leadFormLink', id: any, patientId: any, formData: any, workspaceId: any, createdAt: any, updatedAt: any };

export const LeadFormLinkFieldsFragmentDoc = gql`
    fragment LeadFormLinkFields on leadFormLink {
  id
  patientId
  formData
  workspaceId
  createdAt
  updatedAt
}
    `;