import { chakra, Skeleton, Text, VStack } from '@chakra-ui/react';
import { toLocalePriceInCents } from '@webapp/util-formatting';

export interface InvoiceLedgerProps {
  items: { label: string; value: number }[];
  isLoading?: boolean;
  printing?: boolean;
}

export function Ledger({
  isLoading = false,
  printing = false,
  items,
}: InvoiceLedgerProps) {
  return (
    <VStack maxWidth="400px" width="100%">
      {items.map((item, index) => (
        <chakra.div
          display="grid"
          fontWeight="bold"
          gridTemplateColumns="repeat(2, 1fr)"
          key={`invoice-ledger-item-${index}`}
          padding={printing ? '4px 4px' : '8px 20px'}
          width="100%"
        >
          <Text textAlign="left" width="100%">
            {item.label}
          </Text>
          {isLoading ? (
            <Skeleton />
          ) : (
            <Text
              data-test-id={`${item.label}-value`}
              textAlign="right"
              width="100%"
            >
              {toLocalePriceInCents(item.value)}
            </Text>
          )}
        </chakra.div>
      ))}
    </VStack>
  );
}

export default Ledger;
