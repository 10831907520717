// lib/apolloClient.js

import {
  ApolloProvider,
  ApolloClient,
  HttpLink,
  from,
  InMemoryCache,
  DefaultOptions,
  split,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import * as React from 'react';
import { onError } from '@apollo/client/link/error';
import { SentryLink } from 'apollo-link-sentry';
import { RetryLink } from '@apollo/client/link/retry';
import * as Sentry from '@sentry/react';
import { firebaseAuth } from '@webapp/auth';
import { getMainDefinition } from '@apollo/client/utilities';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';

const hasuraGraphqlApi = import.meta.env.VITE_GRAPHQL_ENDPOINT;

const websocketEndpoint = import.meta.env.VITE_WEBSOCKET_ENDPOINT;

interface ApolloProviderWrapperProps {
  app?: string;
}

export const ApolloProviderWrapper = ({
  children,
  app = 'providerapp',
}: React.PropsWithChildren<ApolloProviderWrapperProps>) => {
  const giveMeAuth = async (otherHeaders?: any) => {
    let currentWorkspace: string | null | undefined;
    let masqueradingAs: string | null | undefined;
    try {
      currentWorkspace = localStorage.getItem(
        `prspr_wid_${import.meta.env.VITE_ENV}`
      );
      masqueradingAs = localStorage.getItem(
        `prspr_msq_${import.meta.env.VITE_ENV}`
      );
    } catch (err) {
      console.error(err);
    }

    return firebaseAuth.currentUser?.getIdToken().then((token) => ({
      headers: {
        ...otherHeaders,
        app: masqueradingAs ?? app,
        authorization: `Bearer ${token}`,
        currentWorkspace,
      },
    }));
  };

  const authMiddleware = setContext(async (req, { headers }) =>
    giveMeAuth(headers)
  );

  const httpLink = new HttpLink({
    uri: hasuraGraphqlApi,
  });

  const wsLink = new GraphQLWsLink(
    createClient({
      url: websocketEndpoint,
      connectionParams: giveMeAuth,
    })
  );

  const retryLink = new RetryLink({
    delay: {
      initial: 300,
      max: Infinity,
      jitter: true,
    },
    attempts: {
      max: 3,
      retryIf: (error, _operation) =>
        !!error && !_operation.operationName.includes('pay'),
    },
  });

  const errorLink = onError(({ graphQLErrors, networkError }: any) => {
    if (graphQLErrors)
      graphQLErrors.map(({ message, locations, path }: any) => {
        console.error(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        );
      });
    if (networkError) {
      console.error(`[Network error]: ${networkError}`);
      Sentry.captureException(networkError);
    }
  });

  const defaultOptions: DefaultOptions = {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'ignore',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  };

  interface Definition {
    kind: string;
    operation?: string;
  }

  const link = split(
    ({ query }) => {
      const { kind, operation }: Definition = getMainDefinition(query);

      return kind === 'OperationDefinition' && operation === 'subscription';
    },
    errorLink.concat(wsLink),
    errorLink.concat(authMiddleware.concat(httpLink))
  );
  const apolloClient = new ApolloClient({
    link: from([new SentryLink(), retryLink, link]),
    cache: new InMemoryCache({ resultCaching: false }),
    defaultOptions,
  });

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
};

export default ApolloProviderWrapper;
