import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { TaskFieldsFragmentDoc } from '../../fragments/taskFields.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetTasksQueryVariables = Types.Exact<{
  limit?: Types.Maybe<Types.Scalars['Int']>;
  offset?: Types.Maybe<Types.Scalars['Int']>;
  orderBy?: Types.Maybe<Array<Types.Task_Order_By> | Types.Task_Order_By>;
  where?: Types.Maybe<Types.Task_Bool_Exp>;
}>;


export type GetTasksQuery = { __typename?: 'query_root', task: Array<{ __typename?: 'task', description?: string | null | undefined, dueDate: any, id: any, status?: string | null | undefined, comments: Array<{ __typename?: 'taskComment', createdAt: any, comment: string, id: any, updatedAt: any, author: { __typename?: 'user', firstName: string, id: any, lastName: string, title?: string | null | undefined } }>, provider?: { __typename?: 'provider', firstName: string, id: any, lastName: string, profilePicture?: string | null | undefined, title?: string | null | undefined } | null | undefined, patient?: { __typename?: 'patient', firstName: string, id: any, lastName: string, profilePicture?: { __typename?: 'photo', file?: { __typename?: 'FilePayload', thumbnailUrl?: string | null | undefined } | null | undefined } | null | undefined } | null | undefined, user?: { __typename?: 'user', firstName: string, id: any, lastName: string, profilePicture?: string | null | undefined, title?: string | null | undefined } | null | undefined }> };


export const GetTasksDocument = gql`
    query GetTasks($limit: Int = 10, $offset: Int = 0, $orderBy: [task_order_by!], $where: task_bool_exp) {
  task(limit: $limit, offset: $offset, order_by: $orderBy, where: $where) {
    ...TaskFields
  }
}
    ${TaskFieldsFragmentDoc}`;

/**
 * __useGetTasksQuery__
 *
 * To run a query within a React component, call `useGetTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTasksQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      orderBy: // value for 'orderBy'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetTasksQuery(baseOptions?: Apollo.QueryHookOptions<GetTasksQuery, GetTasksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTasksQuery, GetTasksQueryVariables>(GetTasksDocument, options);
      }
export function useGetTasksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTasksQuery, GetTasksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTasksQuery, GetTasksQueryVariables>(GetTasksDocument, options);
        }
export type GetTasksQueryHookResult = ReturnType<typeof useGetTasksQuery>;
export type GetTasksLazyQueryHookResult = ReturnType<typeof useGetTasksLazyQuery>;
export type GetTasksQueryResult = Apollo.QueryResult<GetTasksQuery, GetTasksQueryVariables>;