import * as Types from '../../generated/graphql';

import { gql } from '@apollo/client';
export type GoogleAccountFieldsFragment = { __typename?: 'googleAccount', id: any, accountName?: string | null | undefined, name: string, role?: string | null | undefined, permissionLevel?: string | null | undefined, workspaceId: any, userId: any, createdAt: any };

export const GoogleAccountFieldsFragmentDoc = gql`
    fragment GoogleAccountFields on googleAccount {
  id
  accountName
  name
  role
  permissionLevel
  workspaceId
  userId
  createdAt
}
    `;