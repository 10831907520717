import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type CancelInvoiceMutationVariables = Types.Exact<{
  invoiceId: Types.Scalars['uuid'];
}>;


export type CancelInvoiceMutation = { __typename?: 'mutation_root', cancelInvoice: { __typename?: 'CancelInvoiceOutput', invoice?: { __typename?: 'invoice', id: any, status: string } | null | undefined } };


export const CancelInvoiceDocument = gql`
    mutation CancelInvoice($invoiceId: uuid!) {
  cancelInvoice(invoiceId: $invoiceId) {
    invoice {
      id
      status
    }
  }
}
    `;
export type CancelInvoiceMutationFn = Apollo.MutationFunction<CancelInvoiceMutation, CancelInvoiceMutationVariables>;

/**
 * __useCancelInvoiceMutation__
 *
 * To run a mutation, you first call `useCancelInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelInvoiceMutation, { data, loading, error }] = useCancelInvoiceMutation({
 *   variables: {
 *      invoiceId: // value for 'invoiceId'
 *   },
 * });
 */
export function useCancelInvoiceMutation(baseOptions?: Apollo.MutationHookOptions<CancelInvoiceMutation, CancelInvoiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelInvoiceMutation, CancelInvoiceMutationVariables>(CancelInvoiceDocument, options);
      }
export type CancelInvoiceMutationHookResult = ReturnType<typeof useCancelInvoiceMutation>;
export type CancelInvoiceMutationResult = Apollo.MutationResult<CancelInvoiceMutation>;
export type CancelInvoiceMutationOptions = Apollo.BaseMutationOptions<CancelInvoiceMutation, CancelInvoiceMutationVariables>;