/* eslint-disable no-restricted-syntax */
import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { stringToColor } from '@webapp/webapp/util-display';
import { AnyJsonValue } from '../custom-types/any-json-value';

interface TimeSlot {
  start: string;
  end: string;
}

interface ProviderSchedule {
  schedule: {
    [key: string]: TimeSlot[];
  };
  providerId: string;
  default: string;
  effectiveUntil: string;
  effectiveFrom: string;
  locationId: string;
  location: {
    timezone: string;
  };
  __typename: string;
}

/**
 * A CalendarResourceStore model.
 */
// prettier-ignore
export const model = types.model("CalendarResource").props({
  id: types.string,
  name: types.maybeNull(types.string),
  color: types.maybeNull(types.string),
  schedules: types.maybeNull(AnyJsonValue),
}).actions((self) => ({
  addSchedule(schedule: ProviderSchedule) {
    self.schedules.push(schedule);
  }
}
))
/**
 * The CalendarResource instance.
 */
export const CalendarResourceModel = types.snapshotProcessor(model, {
  preProcessor(sn: any) {
    const CalendarResource = { ...sn };
    if (!sn.color) {
      CalendarResource.color = stringToColor(sn.name);
    }

    return CalendarResource;
  },
});

export type CalendarResource = Instance<typeof CalendarResourceModel>;

/**
 * The data of a CalendarResource.
 */
export type CalendarResourceSnapshot = SnapshotOut<
  typeof CalendarResourceModel
>;
