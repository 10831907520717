import React, { useState, useEffect } from 'react';
import {
  Box,
  VStack,
  Text,
  Textarea,
  Input,
  Button,
  Radio,
  RadioGroup,
  Select,
  Flex,
} from '@chakra-ui/react';
import { TextEditor } from '@webapp/ui';

const consentOptions = [
  'FULL_CONSENT',
  'FULL_ANONYMIZED_CONSENT',
  'CLINICAL_CONSENT',
  'NO_CONSENT',
];

export default function WrappedCustomMediaConsent({
  question,
  isDesignMode,
  creator,
  onValueChange,
}) {
  const [localConsentText, setLocalConsentText] = useState(
    question.consentText
  );
  const [localConsentOptions, setLocalConsentOptions] = useState(
    question.consentOptions ?? []
  );
  const [selectedOption, setSelectedOption] = useState(question.value ?? 0);

  useEffect(() => {
    // Update question object when local state changes
    question.consentText = localConsentText;
    question.consentOptions = localConsentOptions;
  }, [localConsentText, localConsentOptions, question]);

  const handleTextChange = (value) => {
    setLocalConsentText(value);
  };

  const handleOptionChange = (index, value) => {
    const newOptions = [...localConsentOptions];
    newOptions[index].value = value;
    setLocalConsentOptions(newOptions);
    question.consentOptions = newOptions;
  };

  const addOption = () => {
    setLocalConsentOptions([
      ...localConsentOptions,
      { text: 'New Option', value: consentOptions[0] },
    ]);
  };

  const removeOption = (index) => {
    setLocalConsentOptions(localConsentOptions.filter((_, i) => i !== index));
  };

  const handleSelectionChange = (value) => {
    setSelectedOption(value);
    onValueChange(value);
  };

  return (
    <Box p={4}>
      <Box flex="1">
        {isDesignMode ? (
          <TextEditor
            defaultValue={localConsentText}
            onChange={handleTextChange}
          />
        ) : (
          <Text
            whiteSpace="pre-wrap"
            dangerouslySetInnerHTML={{ __html: localConsentText }}
          />
        )}
      </Box>

      <Box mt={4}>
        <Text fontSize="lg" fontWeight="semibold" mb={2}>
          Please select the consent option that best describes your preference:
        </Text>
        {isDesignMode ? (
          <VStack spacing={4} align="stretch">
            {localConsentOptions?.map((option, index) => (
              <Box
                key={index}
                borderWidth="1px"
                borderRadius="md"
                overflow="hidden"
              >
                <Flex
                  bg="gray.100"
                  p={2}
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Select
                    value={option.value}
                    onChange={(e) => handleOptionChange(index, e.target.value)}
                    width="200px"
                  >
                    {consentOptions.map((opt) => (
                      <option key={opt} value={opt}>
                        {opt}
                      </option>
                    ))}
                  </Select>
                  <Button
                    colorScheme="red"
                    size="sm"
                    onClick={() => removeOption(index)}
                  >
                    Remove
                  </Button>
                </Flex>
                <Box p={2}>
                  <TextEditor
                    defaultValue={option.text}
                    onChange={(value) => {
                      const newOptions = [...localConsentOptions];
                      newOptions[index].text = value;
                      setLocalConsentOptions(newOptions);
                    }}
                  />
                </Box>
              </Box>
            ))}
            <Button colorScheme="blue" onClick={addOption} mt={2}>
              Add Option
            </Button>
          </VStack>
        ) : (
          <RadioGroup onChange={handleSelectionChange} value={selectedOption}>
            <VStack align="stretch" spacing={2}>
              {localConsentOptions?.map((option, index) => (
                <Radio key={index} value={option.value}>
                  <p dangerouslySetInnerHTML={{ __html: option.text }} />
                </Radio>
              ))}
            </VStack>
          </RadioGroup>
        )}
      </Box>
    </Box>
  );
}
