import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { SocialMediaPostFieldsFragmentDoc } from '../../fragments/socialMediaPostFields.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type InsertSocialMediaPostMutationVariables = Types.Exact<{
  socialMediaPost: Types.SocialMediaPost_Insert_Input;
}>;


export type InsertSocialMediaPostMutation = { __typename?: 'mutation_root', insert_socialMediaPost?: { __typename?: 'socialMediaPost_mutation_response', returning: Array<{ __typename?: 'socialMediaPost', id: any, title: string, caption?: string | null | undefined, isScheduled: boolean, intendedPostAt: any, privatePhoto?: { __typename?: 'photo', id: any, workspaceId: any, patientId?: any | null | undefined, mediaDate?: any | null | undefined, mediaType?: string | null | undefined, mimeType?: string | null | undefined, hasThumbnail?: boolean | null | undefined, isSharedWithPatient: boolean, partialMediaConsent?: boolean | null | undefined, fullMediaConsent?: boolean | null | undefined, patient?: { __typename?: 'patient', firstName: string, id: any, lastName: string } | null | undefined, file?: { __typename?: 'FilePayload', filePath: string, url: string, thumbnailUrl?: string | null | undefined } | null | undefined, photoMediaTags: Array<{ __typename?: 'photoMediaTag', id: any, mediaTag: { __typename?: 'mediaTag', id: any, title: string } }>, uploader?: { __typename?: 'user', firstName: string, id: any, lastName: string } | null | undefined } | null | undefined, socialMediaAccountPosts: Array<{ __typename?: 'socialMediaAccountPost', status: Types.PostStatus_Enum, likeCount?: number | null | undefined, commentCount?: number | null | undefined, socialMediaAccount: { __typename?: 'socialMediaAccount', id: any, name: string, profilePicture?: string | null | undefined, platform: Types.SocialMediaPlatform_Enum } }> }> } | null | undefined };


export const InsertSocialMediaPostDocument = gql`
    mutation insertSocialMediaPost($socialMediaPost: socialMediaPost_insert_input!) {
  insert_socialMediaPost(objects: [$socialMediaPost]) {
    returning {
      ...SocialMediaPostFields
    }
  }
}
    ${SocialMediaPostFieldsFragmentDoc}`;
export type InsertSocialMediaPostMutationFn = Apollo.MutationFunction<InsertSocialMediaPostMutation, InsertSocialMediaPostMutationVariables>;

/**
 * __useInsertSocialMediaPostMutation__
 *
 * To run a mutation, you first call `useInsertSocialMediaPostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertSocialMediaPostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertSocialMediaPostMutation, { data, loading, error }] = useInsertSocialMediaPostMutation({
 *   variables: {
 *      socialMediaPost: // value for 'socialMediaPost'
 *   },
 * });
 */
export function useInsertSocialMediaPostMutation(baseOptions?: Apollo.MutationHookOptions<InsertSocialMediaPostMutation, InsertSocialMediaPostMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertSocialMediaPostMutation, InsertSocialMediaPostMutationVariables>(InsertSocialMediaPostDocument, options);
      }
export type InsertSocialMediaPostMutationHookResult = ReturnType<typeof useInsertSocialMediaPostMutation>;
export type InsertSocialMediaPostMutationResult = Apollo.MutationResult<InsertSocialMediaPostMutation>;
export type InsertSocialMediaPostMutationOptions = Apollo.BaseMutationOptions<InsertSocialMediaPostMutation, InsertSocialMediaPostMutationVariables>;