import SignatureCanvas from 'react-signature-canvas';

export function blobToBase64(blob: Blob): Promise<unknown> {
  return new Promise((resolve, _) => {
    const reader = new FileReader();

    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
}

export async function loadExistingSignature(
  signatureRef: SignatureCanvas,
  signatureUrl: string
) {
  if (!signatureRef) return;

  if (!signatureUrl) return;

  const fetchResponse = await fetch(signatureUrl);

  const responseBuffer = await fetchResponse.blob();
  const base64 = await blobToBase64(responseBuffer);

  signatureRef.fromDataURL(base64 as string);
}

export const saveSignatureAsBase64 = (
  signatureRef: SignatureCanvas
): string | undefined => {
  const data = signatureRef.toDataURL('image/png');

  return data;
};

export async function dataUrlToFile(
  dataUrl: string,
  fileName: string
): Promise<File> {
  const res: Response = await fetch(dataUrl);
  const blob: Blob = await res.blob();

  return new File([blob], fileName, { type: 'image/png' });
}

export const saveSignatureAndConvertToFile = async (
  signatureRef: SignatureCanvas,
  fileName: string
): Promise<File> => {
  const savedSignature = saveSignatureAsBase64(signatureRef);

  if (!savedSignature) throw new Error('No signature');

  const file = await dataUrlToFile(savedSignature, fileName);

  return file;
};
