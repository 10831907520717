import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type InsertLeadFormEntryTagMutationVariables = Types.Exact<{
  leadFormEntryTag: Types.LeadFormEntryTag_Insert_Input;
}>;


export type InsertLeadFormEntryTagMutation = { __typename?: 'mutation_root', insert_leadFormEntryTag?: { __typename?: 'leadFormEntryTag_mutation_response', returning: Array<{ __typename?: 'leadFormEntryTag', id: any, leadTag: { __typename?: 'leadTag', id: any, title: string } }> } | null | undefined };


export const InsertLeadFormEntryTagDocument = gql`
    mutation insertLeadFormEntryTag($leadFormEntryTag: leadFormEntryTag_insert_input!) {
  insert_leadFormEntryTag(
    objects: [$leadFormEntryTag]
    on_conflict: {constraint: leadFormEntryTag_leadFormEntryId_leadTagId_key, update_columns: updatedAt}
  ) {
    returning {
      id
      leadTag {
        id
        title
      }
    }
  }
}
    `;
export type InsertLeadFormEntryTagMutationFn = Apollo.MutationFunction<InsertLeadFormEntryTagMutation, InsertLeadFormEntryTagMutationVariables>;

/**
 * __useInsertLeadFormEntryTagMutation__
 *
 * To run a mutation, you first call `useInsertLeadFormEntryTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertLeadFormEntryTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertLeadFormEntryTagMutation, { data, loading, error }] = useInsertLeadFormEntryTagMutation({
 *   variables: {
 *      leadFormEntryTag: // value for 'leadFormEntryTag'
 *   },
 * });
 */
export function useInsertLeadFormEntryTagMutation(baseOptions?: Apollo.MutationHookOptions<InsertLeadFormEntryTagMutation, InsertLeadFormEntryTagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertLeadFormEntryTagMutation, InsertLeadFormEntryTagMutationVariables>(InsertLeadFormEntryTagDocument, options);
      }
export type InsertLeadFormEntryTagMutationHookResult = ReturnType<typeof useInsertLeadFormEntryTagMutation>;
export type InsertLeadFormEntryTagMutationResult = Apollo.MutationResult<InsertLeadFormEntryTagMutation>;
export type InsertLeadFormEntryTagMutationOptions = Apollo.BaseMutationOptions<InsertLeadFormEntryTagMutation, InsertLeadFormEntryTagMutationVariables>;