import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type DeleteSmsMarketingTagMutationVariables = Types.Exact<{
  smsTemplateId: Types.Scalars['uuid'];
  marketingTagId: Types.Scalars['uuid'];
}>;


export type DeleteSmsMarketingTagMutation = { __typename?: 'mutation_root', delete_marketingTagSms?: { __typename?: 'marketingTagSms_mutation_response', affected_rows: number } | null | undefined };


export const DeleteSmsMarketingTagDocument = gql`
    mutation deleteSmsMarketingTag($smsTemplateId: uuid!, $marketingTagId: uuid!) {
  delete_marketingTagSms(
    where: {smsTemplateId: {_eq: $smsTemplateId}, marketingTagId: {_eq: $marketingTagId}}
  ) {
    affected_rows
  }
}
    `;
export type DeleteSmsMarketingTagMutationFn = Apollo.MutationFunction<DeleteSmsMarketingTagMutation, DeleteSmsMarketingTagMutationVariables>;

/**
 * __useDeleteSmsMarketingTagMutation__
 *
 * To run a mutation, you first call `useDeleteSmsMarketingTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSmsMarketingTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSmsMarketingTagMutation, { data, loading, error }] = useDeleteSmsMarketingTagMutation({
 *   variables: {
 *      smsTemplateId: // value for 'smsTemplateId'
 *      marketingTagId: // value for 'marketingTagId'
 *   },
 * });
 */
export function useDeleteSmsMarketingTagMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSmsMarketingTagMutation, DeleteSmsMarketingTagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSmsMarketingTagMutation, DeleteSmsMarketingTagMutationVariables>(DeleteSmsMarketingTagDocument, options);
      }
export type DeleteSmsMarketingTagMutationHookResult = ReturnType<typeof useDeleteSmsMarketingTagMutation>;
export type DeleteSmsMarketingTagMutationResult = Apollo.MutationResult<DeleteSmsMarketingTagMutation>;
export type DeleteSmsMarketingTagMutationOptions = Apollo.BaseMutationOptions<DeleteSmsMarketingTagMutation, DeleteSmsMarketingTagMutationVariables>;