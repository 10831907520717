import {
  Button,
  Grid,
  Popover,
  PopoverBody,
  PopoverContent,
  useDisclosure,
} from '@chakra-ui/react';
import { Datepicker } from '@mobiscroll/react';
import { configuredDayjs as dayjs } from '@webapp/util-time';
import { DateInputProps } from './types/DateInputProps';
import PopoverTrigger from '../popover-trigger/popover-trigger';
import DEFAULT_CONTROL_STYLE from './styles/DefaultControlStyle';
import DEFAULT_INPUT_CONTAINER_STYLE from './styles/DefaultInputContainerStyle';

function DateInput({
  colorScheme,
  defaultSelection,
  isDisabled,
  onChange,
  styles = {},
  value,
  controls,
  displayFormat,
  ...props
}: Omit<DateInputProps, 'type'>) {
  const inputStyles = { ...DEFAULT_CONTROL_STYLE, ...styles.control };

  const containerStyles = {
    ...DEFAULT_INPUT_CONTAINER_STYLE,
    ...styles.inputContainer,
  };

  const dateForm = useDisclosure();

  function handleDateChange(input: { value: Date; valueText: string }) {
    onChange(input.value.toUTCString());
    dateForm.onClose();
  }

  return (
    <Grid
      background="white"
      border="1px solid"
      borderColor="gray.200"
      borderRadius="var(--chakra-radii-md)"
      {...containerStyles}
      {...props}
    >
      <Popover
        isOpen={dateForm.isOpen}
        onClose={dateForm.onClose}
        placement="bottom-start"
      >
        <PopoverTrigger>
          <Button
            className="date-time-trigger"
            colorScheme={colorScheme}
            isDisabled={isDisabled}
            justifyContent="start"
            onClick={dateForm.onToggle}
            {...inputStyles}
          >
            {dayjs(value).format(displayFormat ?? 'MM/DD/YYYY')}
          </Button>
        </PopoverTrigger>
        <PopoverContent>
          <PopoverBody
            {...(!dateForm.isOpen && { display: 'none' })}
            padding="0"
          >
            <Datepicker
              value={dayjs(value).toDate()}
              className="date-picker__form_input"
              controls={controls ?? ['calendar']}
              //   {...(colors && { colors })}
              defaultSelection={defaultSelection}
              display="inline"
              onChange={handleDateChange}
              showOuterDays={false}
              theme="material"
              themeVariant="light"
              touchUi={true}
            />
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Grid>
  );
}

export default DateInput;
