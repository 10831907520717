/* eslint-disable camelcase */
import { useApolloClient } from '@apollo/client';
import {
  GraphqlClient,
  SearchPatientWorkspaceQueryResult,
  SearchPatientWorkspaceQueryVariables,
  SearchPatientWorkspaceDocument,
  Patient_Bool_Exp,
  InsertPatientDocument,
} from '@webapp/graphql';
import { useStores } from '@webapp/state-models';
import { PatientsFromQuery, ObjectOption, SelectOption } from '@webapp/types';
import { formatName } from '@webapp/util-formatting';
import toast from 'react-hot-toast';

export interface UseLoadPatients {
  onCreateOption: (value: string) => Promise<SelectOption>;
  search: (searchValue: string) => Promise<ObjectOption<PatientsFromQuery>[]>;
}

export function useLoadPatients(where?: Patient_Bool_Exp): UseLoadPatients {
  const { workspace } = useStores();
  const client = useApolloClient();

  return {
    onCreateOption: async (value: string) => {
      const [firstName, lastName] = value.split('/');

      if (!firstName || !lastName) {
        toast.error(
          'Patient name should be formatted {FirstName}/{LastName}, ex: Bob/Robertson'
        );

        throw new Error('Oops');
      }

      const { data } = await client.mutate({
        mutation: InsertPatientDocument,
        variables: {
          patient: {
            firstName,
            lastName,
            patientWorkspaces: {
              data: [
                {
                  firstName,
                  lastName,
                  workspaceId: workspace?.id,
                },
              ],
            },
          },
        },
      });

      const [patient] = data?.insert_patient.returning ?? [];

      return {
        label: formatName(patient),
        object: patient,
        value: patient.id,
      };
    },

    search: async (searchValue: string) => {
      const result = await client.query<
        SearchPatientWorkspaceQueryResult['data'],
        SearchPatientWorkspaceQueryVariables
      >({
        query: SearchPatientWorkspaceDocument,
        variables: {
          search: `%${searchValue}%`,
          workspaceId: workspace?.id,
          where,
        },
      });

      const patients = result.data?.search_patientWorkspace ?? [];

      return patients.map((patient: PatientsFromQuery) => ({
        label: `${patient.attributes.firstName} ${patient.attributes.lastName}`,
        value: patient.id,
        object: patient,
      }));
    },
  };
}

export default useLoadPatients;
