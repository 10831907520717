import { Button, IconButton } from '@chakra-ui/react';
import { useState } from 'react';
import {
  useSearchPatientTagsLazyQuery,
  useInsertPatientTagsMutation,
} from '@webapp/graphql';
import { IoMdAddCircle, IoMdCloseCircleOutline } from 'react-icons/io';
import { AsyncCreatableSelect } from 'chakra-react-select';

export interface PatientTagOption {
  readonly value: string;
  readonly label: string;
}
export interface AddPatientTagProps {
  patientId: string;
}
export default function AddPatientTag({ patientId }: AddPatientTagProps) {
  const [addingTag, setAddingTag] = useState(false);
  const [search] = useSearchPatientTagsLazyQuery();
  const [insertPatientTags] = useInsertPatientTagsMutation({
    refetchQueries: ['PatientTagsQuery'],
    onCompleted: () => setAddingTag(false),
  });

  const promiseOptions = async (
    inputValue: string,
    callback: (options: LeadTagOption[]) => void
  ) =>
    new Promise((resolve) => {
      search({
        variables: { searchValue: `%${inputValue}%` },
      }).then(({ data }: any) =>
        resolve(
          data?.patientTag?.map(({ id, title }) => ({
            value: id,
            label: title,
          })) as PatientTagOption[]
        )
      );
    });

  const handleChange = (
    a:
      | SingleValue<{
          label: string;
          value: string;
        }>
      | MultiValue<{
          label: string;
          value: string;
        }>,
    b: ActionMeta<{
      label: string;
      value: string;
    }>
  ) => {
    switch (b.action) {
      case 'create-option':
        insertPatientTags({
          variables: {
            patientTags: {
              patientId,
              patientTag: {
                data: {
                  title: b.option.label,
                },
                on_conflict: {
                  constraint: 'patientTag_title_workspaceId_key',
                  update_columns: ['updatedAt'],
                },
              },
            },
          },
        });

        break;
      case 'remove-value':
        if (onRemoveTag) onRemoveTag(b.removedValue.value);
        break;
      case 'clear':
        if (onClear) onClear();
        break;
      default:
        insertPatientTags({
          variables: {
            patientTags: {
              patientId,
              patientTagId: a.value,
            },
          },
        });
        break;
    }
  };

  if (addingTag) {
    return (
      <>
        <AsyncCreatableSelect
          size="sm"
          chakraStyles={{ minW: '200px' }}
          defaultOptions={true}
          onChange={handleChange}
          loadOptions={promiseOptions}
          placeholder="Create or select tag"
          autoFocus
        />
        <IconButton
          size="sm"
          ml={2}
          variant="outline"
          onClick={() => setAddingTag(false)}
          icon={<IoMdCloseCircleOutline />}
        />
      </>
    );
  }

  return (
    <Button
      onClick={() => setAddingTag(true)}
      variant="outline"
      size="xs"
      leftIcon={<IoMdAddCircle />}
    >
      Add Tag
    </Button>
  );
}
