import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GeneratePatientConsentPdfMutationVariables = Types.Exact<{
  patientConsentId: Types.Scalars['uuid'];
}>;


export type GeneratePatientConsentPdfMutation = { __typename?: 'mutation_root', generatePatientConsentPDF?: { __typename?: 'SuccessAnswer', success: boolean } | null | undefined };


export const GeneratePatientConsentPdfDocument = gql`
    mutation GeneratePatientConsentPDF($patientConsentId: uuid!) {
  generatePatientConsentPDF(patientConsentId: $patientConsentId) {
    success
  }
}
    `;
export type GeneratePatientConsentPdfMutationFn = Apollo.MutationFunction<GeneratePatientConsentPdfMutation, GeneratePatientConsentPdfMutationVariables>;

/**
 * __useGeneratePatientConsentPdfMutation__
 *
 * To run a mutation, you first call `useGeneratePatientConsentPdfMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGeneratePatientConsentPdfMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generatePatientConsentPdfMutation, { data, loading, error }] = useGeneratePatientConsentPdfMutation({
 *   variables: {
 *      patientConsentId: // value for 'patientConsentId'
 *   },
 * });
 */
export function useGeneratePatientConsentPdfMutation(baseOptions?: Apollo.MutationHookOptions<GeneratePatientConsentPdfMutation, GeneratePatientConsentPdfMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GeneratePatientConsentPdfMutation, GeneratePatientConsentPdfMutationVariables>(GeneratePatientConsentPdfDocument, options);
      }
export type GeneratePatientConsentPdfMutationHookResult = ReturnType<typeof useGeneratePatientConsentPdfMutation>;
export type GeneratePatientConsentPdfMutationResult = Apollo.MutationResult<GeneratePatientConsentPdfMutation>;
export type GeneratePatientConsentPdfMutationOptions = Apollo.BaseMutationOptions<GeneratePatientConsentPdfMutation, GeneratePatientConsentPdfMutationVariables>;