import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type DeauthorizeMutationVariables = Types.Exact<{
  authProfileId: Types.Scalars['String'];
}>;


export type DeauthorizeMutation = { __typename?: 'mutation_root', deauthorize?: { __typename?: 'SuccessAnswer', success: boolean } | null | undefined };


export const DeauthorizeDocument = gql`
    mutation deauthorize($authProfileId: String!) {
  deauthorize(authProfileId: $authProfileId) {
    success
  }
}
    `;
export type DeauthorizeMutationFn = Apollo.MutationFunction<DeauthorizeMutation, DeauthorizeMutationVariables>;

/**
 * __useDeauthorizeMutation__
 *
 * To run a mutation, you first call `useDeauthorizeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeauthorizeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deauthorizeMutation, { data, loading, error }] = useDeauthorizeMutation({
 *   variables: {
 *      authProfileId: // value for 'authProfileId'
 *   },
 * });
 */
export function useDeauthorizeMutation(baseOptions?: Apollo.MutationHookOptions<DeauthorizeMutation, DeauthorizeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeauthorizeMutation, DeauthorizeMutationVariables>(DeauthorizeDocument, options);
      }
export type DeauthorizeMutationHookResult = ReturnType<typeof useDeauthorizeMutation>;
export type DeauthorizeMutationResult = Apollo.MutationResult<DeauthorizeMutation>;
export type DeauthorizeMutationOptions = Apollo.BaseMutationOptions<DeauthorizeMutation, DeauthorizeMutationVariables>;