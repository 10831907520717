import React from 'react';
import {
  Box,
  Stack,
  Select,
  FormControl,
  FormLabel,
  Switch,
} from '@chakra-ui/react';
import { Photo } from '@webapp/state-models';
import { PatientSearchDropdown, MediaTagInput } from '@webapp/ui';
import 'react-modern-calendar-datepicker/lib/DatePicker.css';
import { Datepicker } from '@mobiscroll/react';
import { observer } from 'mobx-react-lite';
import {
  MediaTag,
  PatientSearchFieldsFragment,
  useDeletePhotoMediaTagByPhotoIdTagIdMutation,
  useDeletePhotoMediaTagMutation,
} from '@webapp/graphql';
import toast from 'react-hot-toast';
import { useFlagsmith } from 'flagsmith-react';
/* eslint-disable-next-line */
export interface ConfigurePhotoFormProps {
  onCreatePatient: () => void;
  photo: Photo;
  multiplePhotos?: Photo[];
}

export const ConfigurePhotoForm = observer(
  ({ onCreatePatient, photo, multiplePhotos }: ConfigurePhotoFormProps) => {
    const { hasFeature } = useFlagsmith();

    function handleShareWithPatientToggle(
      e: React.ChangeEvent<HTMLInputElement>
    ) {
      const booleanValue = e.currentTarget.checked;

      photo.update({
        isSharedWithPatient: booleanValue,
      });
    }

    const [deletePhotoMediaTag] =
      useDeletePhotoMediaTagByPhotoIdTagIdMutation();

    function handleSelectedPatient(patient: PatientSearchFieldsFragment) {
      if (multiplePhotos && multiplePhotos.length > 1) {
        multiplePhotos.map((p) => p.updatePatient(patient));
        toast.success(`Set patient for ${multiplePhotos.length} photos.`);
      } else {
        photo.updatePatient(patient);
      }
    }

    function addNewMediaTag(tag: string) {
      if (multiplePhotos && multiplePhotos.length > 1) {
        multiplePhotos.map((p) => p.addNewMediaTag(tag));
        toast.success(`Added "${tag}" tag to ${multiplePhotos.length} photos.`);
      } else {
        photo.addNewMediaTag(tag);
      }
    }

    function addExistingMediaTag(tag: MediaTag) {
      if (multiplePhotos && multiplePhotos.length > 1) {
        multiplePhotos.map((p) => p.addExistingMediaTag(tag));
        toast.success(
          `Added "${tag.title}" tag to ${multiplePhotos.length} photos.`
        );
      } else {
        photo.addExistingMediaTag(tag);
      }
    }

    function removeMediaTag(tagId: string) {
      if (multiplePhotos && multiplePhotos.length > 1) {
        multiplePhotos.map((p) =>
          deletePhotoMediaTag({
            variables: { photoId: p?.id, mediaTagId: tagId },
          })
        );
        toast.success(`Removed tag from ${multiplePhotos.length} photos.`);
      } else {
        photo.removePhotoMediaTag(tagId);
      }
    }

    function updateMediaType(e: React.ChangeEvent<HTMLSelectElement>) {
      const { value } = e.target;

      if (multiplePhotos && multiplePhotos.length > 1) {
        multiplePhotos.map((p) => p.update({ mediaType: value }));
        toast.success(
          `Updated media type for ${multiplePhotos.length} photos.`
        );
      } else {
        photo.update({ mediaType: value });
      }
    }

    function updateMediaDate(e: any) {
      const { value } = e;

      if (multiplePhotos && multiplePhotos.length > 1) {
        multiplePhotos.map((p) => p.update({ mediaDate: value }));
        toast.success(
          `Updated media date for ${multiplePhotos.length} photos.`
        );
      } else {
        photo.update({ mediaDate: value });
      }
    }

    return (
      <Stack minW={60} w="full" flex={1} justifyContent={'flex-start'}>
        <FormControl id="patient">
          <FormLabel>Patient</FormLabel>
          <PatientSearchDropdown
            key={photo?.id}
            selectedPatient={
              photo?.patient
                ? (photo.patient as unknown as PatientSearchFieldsFragment)
                : undefined
            }
            onCreatePatient={onCreatePatient}
            onSelectPatient={handleSelectedPatient}
          />
          {hasFeature('patient-portal') && (
            <FormControl
              display="grid"
              alignItems="center"
              gridTemplateColumns="max-content max-content"
              padding="10px 0"
            >
              <FormLabel fontSize="12px" htmlFor="isSharedWithPatient" mb="0">
                Share to Patient Portal?
              </FormLabel>
              <Switch
                defaultChecked={Boolean(photo.isSharedWithPatient)}
                id="isSharedWithPatient"
                name="isSharedWithPatient"
                onChange={handleShareWithPatientToggle}
              />
            </FormControl>
          )}
        </FormControl>
        <FormControl id="mediaDate">
          <FormLabel>Media Date</FormLabel>
          <Box borderColor={'gray.200'} borderWidth={1} rounded="md">
            <Datepicker
              themeVariant="light"
              labelStyle="stacked"
              value={photo.mediaDate}
              onChange={updateMediaDate}
            />
          </Box>
        </FormControl>
        <FormControl id="mediaType">
          <FormLabel>Media Type</FormLabel>
          <Select
            placeholder="Select media type"
            value={photo.mediaType as string}
            onChange={updateMediaType}
          >
            <option value="before">Before Photo</option>
            <option value="after">After Photo</option>
            <option value="beforeAndAfter">Before & After Photo</option>
            <option value="video">Video</option>
            <option value="markup">Markup</option>
            <option value="other">Other</option>
          </Select>
        </FormControl>
        <FormControl id="mediaTags" maxW="md">
          <FormLabel>Media Tags</FormLabel>
          <MediaTagInput
            key={`${photo?.id}-${photo.photoMediaTags.length}`}
            onSelectMediaTag={console.log}
            onCreateMediaTag={addNewMediaTag}
            onAddExistingMediaTag={addExistingMediaTag}
            onRemoveTag={removeMediaTag}
            selectedMediaTags={photo.photoMediaTags}
            useTagIdAsValue={false}
          />
        </FormControl>
      </Stack>
    );
  }
);

export default ConfigurePhotoForm;
