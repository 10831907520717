import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type InsertLocationProviderMutationVariables = Types.Exact<{
  locationProvider: Types.LocationProvider_Insert_Input;
}>;


export type InsertLocationProviderMutation = { __typename?: 'mutation_root', insert_locationProvider?: { __typename?: 'locationProvider_mutation_response', returning: Array<{ __typename?: 'locationProvider', id: any, locationId: any, providerId: any }> } | null | undefined };


export const InsertLocationProviderDocument = gql`
    mutation insertLocationProvider($locationProvider: locationProvider_insert_input!) {
  insert_locationProvider(objects: [$locationProvider]) {
    returning {
      id
      locationId
      providerId
    }
  }
}
    `;
export type InsertLocationProviderMutationFn = Apollo.MutationFunction<InsertLocationProviderMutation, InsertLocationProviderMutationVariables>;

/**
 * __useInsertLocationProviderMutation__
 *
 * To run a mutation, you first call `useInsertLocationProviderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertLocationProviderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertLocationProviderMutation, { data, loading, error }] = useInsertLocationProviderMutation({
 *   variables: {
 *      locationProvider: // value for 'locationProvider'
 *   },
 * });
 */
export function useInsertLocationProviderMutation(baseOptions?: Apollo.MutationHookOptions<InsertLocationProviderMutation, InsertLocationProviderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertLocationProviderMutation, InsertLocationProviderMutationVariables>(InsertLocationProviderDocument, options);
      }
export type InsertLocationProviderMutationHookResult = ReturnType<typeof useInsertLocationProviderMutation>;
export type InsertLocationProviderMutationResult = Apollo.MutationResult<InsertLocationProviderMutation>;
export type InsertLocationProviderMutationOptions = Apollo.BaseMutationOptions<InsertLocationProviderMutation, InsertLocationProviderMutationVariables>;