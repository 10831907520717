import {
  HStack,
  Text,
  Skeleton,
  Box,
  Modal,
  Divider,
  ModalBody,
  ModalContent,
  ModalOverlay,
  useDisclosure,
  Input,
  InputGroup,
  InputLeftElement,
  Stack,
  Center,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from '@webapp/state-models';
import { useListAccessibleWorkspacesQuery } from '@webapp/graphql';
import { SearchIcon } from '@chakra-ui/icons';
import { useSearchParams } from 'react-router-dom';

/* eslint-disable-next-line */
export interface WorkspaceSelectorProps {
  minimized?: boolean;
}

export const WorkspaceSelector = observer(
  ({ minimized }: WorkspaceSelectorProps) => {
    const { user, workspace, setWorkspace } = useStores();
    const [searchText, setSearchText] = useState('');
    const [currentWorkspace, setCurrentWorkspace] = useState<
      string | null | undefined
    >(workspace?.id);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [searchParams] = useSearchParams();

    const switchToWorkspaceId = searchParams.get('swid');

    const { data, loading } = useListAccessibleWorkspacesQuery({
      variables: { userId: user?.id },
    });

    const currentWorkspaceObject = data?.userWorkspace?.find(
      (w) => w.workspace.id === currentWorkspace
    );

    useEffect(() => {
      const userWorkspaces = data?.userWorkspace ?? [];

      if (
        currentWorkspace &&
        data?.userWorkspace &&
        workspace?.id !== currentWorkspace
      ) {
        const userWorkspace = userWorkspaces.find(
          (uw) => uw.workspace?.id === currentWorkspace
        );

        if (userWorkspace) {
          const { workspace: selectedWorkspace } = userWorkspace;
          setWorkspace(selectedWorkspace);
          window.location.reload();
        }
      }
    }, [currentWorkspace]);

    useEffect(() => {
      if (switchToWorkspaceId) {
        setCurrentWorkspace(switchToWorkspaceId);
        searchParams.delete('swid');
      }
    }, [switchToWorkspaceId]);

    if (minimized) return null;

    const filteredWorkspaces = searchText
      ? data?.userWorkspace?.filter((uw) =>
          uw.workspace.name.toLowerCase().includes(searchText.toLowerCase())
        )
      : data?.userWorkspace;

    return (
      <Skeleton isLoaded={!loading && !!data}>
        <Center w={'full'} p={2}>
          <Box
            cursor={'pointer'}
            _hover={{
              borderColor: 'teal.800',
            }}
            onClick={onOpen}
            w={'full'}
            h={'10'}
            color={'gray.800'}
            p="2"
            borderWidth={1}
            borderColor="gray.400"
            rounded="md"
          >
            <Text>{workspace?.name}</Text>
          </Box>
        </Center>

        <Modal onClose={onClose} isOpen={isOpen} isCentered>
          <ModalOverlay />
          <ModalContent>
            <ModalBody rounded="xl" minH="400px">
              <Stack spacing={4} pt={2}>
                <InputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                    children={<SearchIcon color="gray.300" />}
                  />
                  <Input
                    type="tel"
                    value={searchText}
                    placeholder="Find a workspace to select"
                    onChange={(e) => setSearchText(e.target.value)}
                  />
                </InputGroup>
                <Divider />
                <Stack maxH={'300px'} overflowY="auto" spacing={2}>
                  {(filteredWorkspaces ?? []).map((uw) => (
                    <HStack
                      rounded="md"
                      _hover={{
                        bg: 'teal.50',
                        borderColor: 'teal.300',
                        borderWidth: 1,
                      }}
                      cursor={'pointer'}
                      p={2}
                      borderColor="gray.300"
                      borderWidth={1}
                      bg="gray.50"
                      key={`workspace-selector-option-${uw.workspace.name}`}
                      onClick={() => setCurrentWorkspace(uw.workspace.id)}
                    >
                      <Text>{uw.workspace.name}</Text>
                    </HStack>
                  ))}
                </Stack>
              </Stack>
            </ModalBody>
          </ModalContent>
        </Modal>
      </Skeleton>
    );
  }
);

export default WorkspaceSelector;
