import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type PatientWorkspaceSubscriptionSubscriptionVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
}>;


export type PatientWorkspaceSubscriptionSubscription = { __typename?: 'subscription_root', patientWorkspace_by_pk?: { __typename?: 'patientWorkspace', id: any, firstName?: string | null | undefined, lastName?: string | null | undefined, hasUnreadMessages?: boolean | null | undefined, phoneNumber?: string | null | undefined, email?: string | null | undefined } | null | undefined };


export const PatientWorkspaceSubscriptionDocument = gql`
    subscription PatientWorkspaceSubscription($id: uuid!) {
  patientWorkspace_by_pk(id: $id) {
    id
    firstName
    lastName
    hasUnreadMessages
    phoneNumber
    email
  }
}
    `;

/**
 * __usePatientWorkspaceSubscriptionSubscription__
 *
 * To run a query within a React component, call `usePatientWorkspaceSubscriptionSubscription` and pass it any options that fit your needs.
 * When your component renders, `usePatientWorkspaceSubscriptionSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePatientWorkspaceSubscriptionSubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePatientWorkspaceSubscriptionSubscription(baseOptions: Apollo.SubscriptionHookOptions<PatientWorkspaceSubscriptionSubscription, PatientWorkspaceSubscriptionSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<PatientWorkspaceSubscriptionSubscription, PatientWorkspaceSubscriptionSubscriptionVariables>(PatientWorkspaceSubscriptionDocument, options);
      }
export type PatientWorkspaceSubscriptionSubscriptionHookResult = ReturnType<typeof usePatientWorkspaceSubscriptionSubscription>;
export type PatientWorkspaceSubscriptionSubscriptionResult = Apollo.SubscriptionResult<PatientWorkspaceSubscriptionSubscription>;