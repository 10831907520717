import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetServicesByCategoryQueryVariables = Types.Exact<{
  where?: Types.Maybe<Types.ServiceCategory_Bool_Exp>;
  limit?: Types.Maybe<Types.Scalars['Int']>;
  offset?: Types.Maybe<Types.Scalars['Int']>;
  orderBy?: Types.Maybe<Array<Types.ServiceCategory_Order_By> | Types.ServiceCategory_Order_By>;
  serviceWhere: Types.Service_Bool_Exp;
}>;


export type GetServicesByCategoryQuery = { __typename?: 'query_root', serviceCategory: Array<{ __typename?: 'serviceCategory', title: string, services: Array<{ __typename?: 'service', id: any, name: string, requiresDeposit?: boolean | null | undefined, deposit?: number | null | undefined, onlineBookingRules?: any | null | undefined, serviceAddOns: Array<{ __typename?: 'serviceAddOn', isRequired: boolean, bookingOrder: number, serviceAddOn: { __typename?: 'service', id: any, name: string } }> }> }>, uncategorizedServices: Array<{ __typename?: 'service', id: any, name: string, requiresDeposit?: boolean | null | undefined, deposit?: number | null | undefined, onlineBookingRules?: any | null | undefined, serviceAddOns: Array<{ __typename?: 'serviceAddOn', isRequired: boolean, bookingOrder: number, serviceAddOn: { __typename?: 'service', id: any, name: string } }> }> };


export const GetServicesByCategoryDocument = gql`
    query GetServicesByCategory($where: serviceCategory_bool_exp, $limit: Int, $offset: Int, $orderBy: [serviceCategory_order_by!], $serviceWhere: service_bool_exp!) {
  serviceCategory(
    where: $where
    limit: $limit
    offset: $offset
    order_by: $orderBy
  ) {
    title
    services(where: {_and: [$serviceWhere]}) {
      id
      name
      requiresDeposit
      deposit
      onlineBookingRules
      serviceAddOns {
        serviceAddOn {
          id
          name
        }
        isRequired
        bookingOrder
      }
    }
  }
  uncategorizedServices: service(
    where: {_and: [$serviceWhere, {serviceCategoryId: {_is_null: true}}]}
  ) {
    id
    name
    requiresDeposit
    deposit
    onlineBookingRules
    serviceAddOns {
      serviceAddOn {
        id
        name
      }
      isRequired
      bookingOrder
    }
  }
}
    `;

/**
 * __useGetServicesByCategoryQuery__
 *
 * To run a query within a React component, call `useGetServicesByCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetServicesByCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetServicesByCategoryQuery({
 *   variables: {
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      orderBy: // value for 'orderBy'
 *      serviceWhere: // value for 'serviceWhere'
 *   },
 * });
 */
export function useGetServicesByCategoryQuery(baseOptions: Apollo.QueryHookOptions<GetServicesByCategoryQuery, GetServicesByCategoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetServicesByCategoryQuery, GetServicesByCategoryQueryVariables>(GetServicesByCategoryDocument, options);
      }
export function useGetServicesByCategoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetServicesByCategoryQuery, GetServicesByCategoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetServicesByCategoryQuery, GetServicesByCategoryQueryVariables>(GetServicesByCategoryDocument, options);
        }
export type GetServicesByCategoryQueryHookResult = ReturnType<typeof useGetServicesByCategoryQuery>;
export type GetServicesByCategoryLazyQueryHookResult = ReturnType<typeof useGetServicesByCategoryLazyQuery>;
export type GetServicesByCategoryQueryResult = Apollo.QueryResult<GetServicesByCategoryQuery, GetServicesByCategoryQueryVariables>;