import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { AnyJsonValue } from '../custom-types/any-json-value';

/**
 * A LocationScheduleStore model.
 */
// prettier-ignore
export const LocationScheduleModel = types
.model('LocationSchedule')
.props({
  id: types.identifier,
  schedule: types.maybeNull(AnyJsonValue),
  locationId: types.string,
  effectiveFrom: types.maybeNull(types.string),
})
.actions((self) => ({
  
}));
/**
 * The LocationSchedule instance.
 */
export type LocationSchedule = Instance<typeof LocationScheduleModel>;

/**
 * The data of a LocationSchedule.
 */
export type LocationScheduleSnapshot = SnapshotOut<
  typeof LocationScheduleModel
>;
