import { Stack } from '@chakra-ui/react';
import { useGetPatientProductHistoryQuery } from '@webapp/graphql';
import { DataTable } from '@webapp/ui';
import { Column } from 'react-table';
import { configuredDayjs as dayjs } from '@webapp/util-time';

const PatientProductHistoryTable = ({ patientId }: { patientId: string }) => {
  const { data, loading } = useGetPatientProductHistoryQuery({
    variables: {
      patientId,
    },
  });
  const COLUMNS: Column[] = [
    {
      Header: 'Product Name',
      accessor: 'name',
      disableSortBy: true,
      defaultCanSort: false,
    },
    {
      Header: 'Quantity',
      accessor: 'totalQuantity',
      disableSortBy: true,
      defaultCanSort: false,
    },
    {
      Header: 'Last Purchased',
      accessor: 'lastPurchased',
      disableSortBy: true,
      defaultCanSort: false,
      Cell: ({ value }: any) => dayjs(value).format('MM/DD/YYYY'),
    },
  ];

  return (
    <Stack>
      {data && (
        <DataTable<any>
          customStyles={{
            borderColor: '#CFEBFF',
            cell: {
              color: '#6C6C72',
            },
            table: {
              borderRadius: '8px',
              minHeight: 'unset',
            },
            tableHeader: {
              background: '#EAFCFF',
              color: '#525257',
              textTransform: 'uppercase',
            },
          }}
          columns={COLUMNS}
          data={data?.getPatientProductHistory?.productHistory}
        />
      )}
    </Stack>
  );
};

export default PatientProductHistoryTable;
