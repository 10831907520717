import { Button, useDisclosure } from '@chakra-ui/react';
import {
  useCancelDraftInvoiceMutation,
  useUpdateOneInvoiceMutation,
} from '@webapp/graphql';
import { FieldErrors, useFormContext } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import PromptModal from '../PromptModal/PromptModal';
import submitInvoiceWrapper from './submitInvoiceWrapper';
import { InvoiceFormValues } from './types';
import { transformInsertUpdateInvoiceValues } from './util';

interface UpdateInvoiceActionsProps {
  onUpdate?: () => void;
}

function UpdateInvoiceActions({
  onUpdate = () => undefined,
}: UpdateInvoiceActionsProps) {
  const { getValues, handleSubmit } = useFormContext<InvoiceFormValues>();
  const navigate = useNavigate();

  const invoiceId = getValues('invoiceId');
  const status = getValues('status');

  const {
    isOpen: isDeleteDraftModalOpen,
    onClose: onDeleteDraftModalClose,
    onOpen: onDeleteDraftModalOpen,
  } = useDisclosure();

  const [cancelInvoice, { loading: cancelLoading }] =
    useCancelDraftInvoiceMutation({
      onCompleted: () => {
        navigate(`/invoices`);
        toast.success('Draft invoice successfully deleted');
      },
      onError: (e) => {
        toast.error(e.message);
      },
    });

  const [updateInvoice, { loading: updateLoading }] =
    useUpdateOneInvoiceMutation({
      onCompleted: () => {
        onUpdate();
        toast.success('Invoice successfully updated');
      },
      onError: (e) => {
        toast.error(e.message);
      },
      refetchQueries: ['GetOneInvoice'],
    });

  if (!invoiceId) {
    return <div></div>;
  }

  function completeDraftInvoice(values: InvoiceFormValues) {
    updateInvoice({
      variables: {
        id: invoiceId,
        set: {
          ...transformInsertUpdateInvoiceValues(values),
          status: 'PAYMENT_OUTSTANDING',
        },
      },
    });
  }

  function updateDraftInvoice(values: InvoiceFormValues) {
    updateInvoice({
      variables: {
        id: invoiceId,
        set: transformInsertUpdateInvoiceValues(values),
      },
    });
  }

  function onError(errors: FieldErrors<InvoiceFormValues>) {
    // eslint-disable-next-line no-console
    console.error(errors, 'Invoice Form Validation Failure');
  }

  return (
    <>
      <Button colorScheme="red" onClick={onDeleteDraftModalOpen}>
        Delete
      </Button>
      <Button
        data-testid="update-invoice-button"
        colorScheme="teal"
        disabled={updateLoading}
        onClick={handleSubmit(
          (values) => submitInvoiceWrapper(values, updateDraftInvoice),
          onError
        )}
        variant="outline"
      >
        {status === 'QUOTE' ? 'Update Quote' : 'Update Invoice'}
      </Button>
      <Button
        data-testid="complete-invoice-button"
        colorScheme="teal"
        isLoading={updateLoading}
        onClick={handleSubmit(
          (values) => submitInvoiceWrapper(values, completeDraftInvoice),
          onError
        )}
      >
        Complete Invoice
      </Button>
      <PromptModal
        bodyText={`Are you sure you want to delete this draft invoice? This cannot be reversed.`}
        headerText="Delete Draft Invoice"
        isOpen={isDeleteDraftModalOpen}
        isLoading={cancelLoading}
        onClose={onDeleteDraftModalClose}
        onConfirm={() => {
          cancelInvoice({
            variables: {
              invoiceId,
            },
          });
        }}
      />
    </>
  );
}

export default UpdateInvoiceActions;
