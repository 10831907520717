import { chakra, Flex, Heading, Text } from '@chakra-ui/react';
import { PhotoGridPhoto } from '@webapp/types';
import { formatName } from '@webapp/util-formatting';
import dayjs from 'dayjs';
import groupBy from 'lodash/groupBy';
import { useSearchParams } from 'react-router-dom';
import Step from '../step/step';
import { Steps } from '../steps/steps';
import PhotoGridCard from './photo-card';

interface PhotoGridByDateProps {
  columnGap?: number;
  onPhotoClick?: (photo: PhotoGridPhoto) => void;
  photos: PhotoGridPhoto[];
  rowGap?: number;
  shouldDisplayTags: boolean;
  variant: 'date' | 'date-and-uploader';
}

function PhotoGridByDate({
  onPhotoClick,
  photos,
  shouldDisplayTags,
  variant,
}: PhotoGridByDateProps) {
  const [searchParams] = useSearchParams();

  const order = searchParams.get('order');

  const photosGroupedByDay = groupBy(photos, (x) => {
    if (variant === 'date-and-uploader' && x.uploader) {
      return `${dayjs(x.mediaDate).format('MM/DD/YYYY')}:${formatName({
        firstName: x.uploader.firstName,
        lastName: x.uploader.lastName,
        title: x.uploader.title,
      })}`;
    }

    return dayjs(x.mediaDate).format('MM/DD/YYYY');
  });

  const dates = Object.keys(photosGroupedByDay).sort((x, y) => {
    const sortOrder = order === 'createdAt:asc' ? -1 : 1;

    const dayX = dayjs(x, 'MM/DD/YYYY');
    const dayY = dayjs(y, 'MM/DD/YYYY');

    if (dayX.isBefore(dayY)) {
      return sortOrder * 1;
    }

    if (dayX.isAfter(dayY)) {
      return sortOrder * -1;
    }

    return 0;
  });

  return (
    <chakra.div
      alignItems="start"
      display="grid"
      height="100%"
      maxW={'95vw'}
      w={'100%'}
    >
      <Steps>
        {dates.map((date) => (
          <Step
            defaultIsOpen={true}
            headingContent={({ onClick }: { onClick: () => void }) => {
              const [formattedDate, uploader] = date.split(':');

              return (
                <Flex
                  alignItems={{ base: 'start', md: 'center' }}
                  cursor="pointer"
                  w={'100%'}
                  direction={{ base: 'column', md: 'row' }}
                  onClick={onClick}
                >
                  <Heading
                    color="#525257"
                    cursor="pointer"
                    fontSize="16px"
                    fontWeight="700"
                    letterSpacing="0.01em"
                    userSelect="none"
                  >
                    {dayjs(formattedDate, 'MM/DD/YYYY').isSame(dayjs(), 'day')
                      ? 'Today'
                      : formattedDate}
                  </Heading>
                  <Text
                    color="#525257"
                    cursor="pointer"
                    fontSize="16px"
                    fontWeight="400"
                    letterSpacing="0.01em"
                    userSelect="none"
                  >
                    ({photosGroupedByDay[date].length}) Images
                  </Text>
                  {uploader && (
                    <Text
                      color="#525257"
                      cursor="pointer"
                      fontSize="16px"
                      fontWeight="400"
                      letterSpacing="0.01em"
                      userSelect="none"
                    >
                      Uploaded by {uploader}
                    </Text>
                  )}
                </Flex>
              );
            }}
            key={`photo-grid-step-${date}`}
          >
            <chakra.div
              className="photo-step-content-container"
              display="grid"
              gridColumnGap={{ base: '8px', md: '32px' }}
              gridRowGap="16px"
              gridTemplateColumns={{
                md: 'repeat(auto-fill, minmax(188px, 1fr))',
              }}
              margin={{ base: '0 0 0 8px', md: '0 0 0 28px' }}
            >
              {photosGroupedByDay[date].map((photo) => (
                <PhotoGridCard
                  key={`photo-grid-card-${photo.id}`}
                  onPhotoClick={onPhotoClick}
                  photo={photo}
                  shouldDisplayTags={shouldDisplayTags}
                />
              ))}
            </chakra.div>
          </Step>
        ))}
      </Steps>
    </chakra.div>
  );
}

export default PhotoGridByDate;
