import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { PhotoFieldsFragmentDoc } from '../../fragments/photoFields.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type UpdatePhotoMutationVariables = Types.Exact<{
  _set?: Types.Maybe<Types.Photo_Set_Input>;
  id: Types.Scalars['uuid'];
}>;


export type UpdatePhotoMutation = { __typename?: 'mutation_root', update_photo?: { __typename?: 'photo_mutation_response', returning: Array<{ __typename?: 'photo', id: any, workspaceId: any, patientId?: any | null | undefined, mediaDate?: any | null | undefined, mediaType?: string | null | undefined, mimeType?: string | null | undefined, hasThumbnail?: boolean | null | undefined, isSharedWithPatient: boolean, partialMediaConsent?: boolean | null | undefined, fullMediaConsent?: boolean | null | undefined, patient?: { __typename?: 'patient', firstName: string, id: any, lastName: string } | null | undefined, file?: { __typename?: 'FilePayload', filePath: string, url: string, thumbnailUrl?: string | null | undefined } | null | undefined, photoMediaTags: Array<{ __typename?: 'photoMediaTag', id: any, mediaTag: { __typename?: 'mediaTag', id: any, title: string } }>, uploader?: { __typename?: 'user', firstName: string, id: any, lastName: string } | null | undefined }> } | null | undefined };


export const UpdatePhotoDocument = gql`
    mutation updatePhoto($_set: photo_set_input, $id: uuid!) {
  update_photo(_set: $_set, where: {id: {_eq: $id}}) {
    returning {
      ...PhotoFields
    }
  }
}
    ${PhotoFieldsFragmentDoc}`;
export type UpdatePhotoMutationFn = Apollo.MutationFunction<UpdatePhotoMutation, UpdatePhotoMutationVariables>;

/**
 * __useUpdatePhotoMutation__
 *
 * To run a mutation, you first call `useUpdatePhotoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePhotoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePhotoMutation, { data, loading, error }] = useUpdatePhotoMutation({
 *   variables: {
 *      _set: // value for '_set'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdatePhotoMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePhotoMutation, UpdatePhotoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePhotoMutation, UpdatePhotoMutationVariables>(UpdatePhotoDocument, options);
      }
export type UpdatePhotoMutationHookResult = ReturnType<typeof useUpdatePhotoMutation>;
export type UpdatePhotoMutationResult = Apollo.MutationResult<UpdatePhotoMutation>;
export type UpdatePhotoMutationOptions = Apollo.BaseMutationOptions<UpdatePhotoMutation, UpdatePhotoMutationVariables>;