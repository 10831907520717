import { Instance, SnapshotOut, types } from 'mobx-state-tree';

export const FileVersion = types.model('FileVersion').props({
  key: types.maybeNull(types.string),
  versionId: types.maybeNull(types.string),
  isLatest: types.maybeNull(types.boolean),
  lastModified: types.maybeNull(types.string),
  imageUrl: types.maybeNull(types.string),
});

/**
 * A FileStore model.
 */
// prettier-ignore
export const FileModel = types.model("File").props({
  filePath: types.string,
  url: types.string,
  thumbnailUrl: types.maybeNull(types.string)
}).actions((self) => ({
  
}
))
/**
 * The File instance.
 */
export type File = Instance<typeof FileModel>;

/**
 * The data of a File.
 */
export type FileSnapshot = SnapshotOut<typeof FileModel>;
