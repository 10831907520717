import * as Types from '../../generated/graphql';

import { gql } from '@apollo/client';
export type GoogleLocationFieldsFragment = { __typename?: 'googleLocation', id: any, name: string, title: string, placeId: string, workspaceId: any };

export const GoogleLocationFieldsFragmentDoc = gql`
    fragment GoogleLocationFields on googleLocation {
  id
  name
  title
  placeId
  workspaceId
}
    `;