import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type InsertRewardProgramEnrollmentMutationVariables = Types.Exact<{
  rewardProgramEnrollment: Types.RewardProgramEnrollment_Insert_Input;
}>;


export type InsertRewardProgramEnrollmentMutation = { __typename?: 'mutation_root', insert_rewardProgramEnrollment_one?: { __typename?: 'rewardProgramEnrollment', id: any } | null | undefined };


export const InsertRewardProgramEnrollmentDocument = gql`
    mutation insertRewardProgramEnrollment($rewardProgramEnrollment: rewardProgramEnrollment_insert_input!) {
  insert_rewardProgramEnrollment_one(object: $rewardProgramEnrollment) {
    id
  }
}
    `;
export type InsertRewardProgramEnrollmentMutationFn = Apollo.MutationFunction<InsertRewardProgramEnrollmentMutation, InsertRewardProgramEnrollmentMutationVariables>;

/**
 * __useInsertRewardProgramEnrollmentMutation__
 *
 * To run a mutation, you first call `useInsertRewardProgramEnrollmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertRewardProgramEnrollmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertRewardProgramEnrollmentMutation, { data, loading, error }] = useInsertRewardProgramEnrollmentMutation({
 *   variables: {
 *      rewardProgramEnrollment: // value for 'rewardProgramEnrollment'
 *   },
 * });
 */
export function useInsertRewardProgramEnrollmentMutation(baseOptions?: Apollo.MutationHookOptions<InsertRewardProgramEnrollmentMutation, InsertRewardProgramEnrollmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertRewardProgramEnrollmentMutation, InsertRewardProgramEnrollmentMutationVariables>(InsertRewardProgramEnrollmentDocument, options);
      }
export type InsertRewardProgramEnrollmentMutationHookResult = ReturnType<typeof useInsertRewardProgramEnrollmentMutation>;
export type InsertRewardProgramEnrollmentMutationResult = Apollo.MutationResult<InsertRewardProgramEnrollmentMutation>;
export type InsertRewardProgramEnrollmentMutationOptions = Apollo.BaseMutationOptions<InsertRewardProgramEnrollmentMutation, InsertRewardProgramEnrollmentMutationVariables>;