import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type SubmitPinMutationVariables = Types.Exact<{
  value: Types.Scalars['String'];
}>;


export type SubmitPinMutation = { __typename?: 'mutation_root', submitPin?: { __typename?: 'SuccessAnswer', success: boolean } | null | undefined };


export const SubmitPinDocument = gql`
    mutation submitPin($value: String!) {
  submitPin(value: $value) {
    success
  }
}
    `;
export type SubmitPinMutationFn = Apollo.MutationFunction<SubmitPinMutation, SubmitPinMutationVariables>;

/**
 * __useSubmitPinMutation__
 *
 * To run a mutation, you first call `useSubmitPinMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitPinMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitPinMutation, { data, loading, error }] = useSubmitPinMutation({
 *   variables: {
 *      value: // value for 'value'
 *   },
 * });
 */
export function useSubmitPinMutation(baseOptions?: Apollo.MutationHookOptions<SubmitPinMutation, SubmitPinMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SubmitPinMutation, SubmitPinMutationVariables>(SubmitPinDocument, options);
      }
export type SubmitPinMutationHookResult = ReturnType<typeof useSubmitPinMutation>;
export type SubmitPinMutationResult = Apollo.MutationResult<SubmitPinMutation>;
export type SubmitPinMutationOptions = Apollo.BaseMutationOptions<SubmitPinMutation, SubmitPinMutationVariables>;