import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type UpdateAppointmentServicesMutationVariables = Types.Exact<{
  object: Types.AppointmentServiceUpdateObject;
}>;


export type UpdateAppointmentServicesMutation = { __typename?: 'mutation_root', updateAppointmentServices?: Array<{ __typename?: 'UpdateAppointmentServicesOutput', appointmentServiceId?: any | null | undefined } | null | undefined> | null | undefined };


export const UpdateAppointmentServicesDocument = gql`
    mutation updateAppointmentServices($object: AppointmentServiceUpdateObject!) {
  updateAppointmentServices(object: $object) {
    appointmentServiceId
  }
}
    `;
export type UpdateAppointmentServicesMutationFn = Apollo.MutationFunction<UpdateAppointmentServicesMutation, UpdateAppointmentServicesMutationVariables>;

/**
 * __useUpdateAppointmentServicesMutation__
 *
 * To run a mutation, you first call `useUpdateAppointmentServicesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAppointmentServicesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAppointmentServicesMutation, { data, loading, error }] = useUpdateAppointmentServicesMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useUpdateAppointmentServicesMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAppointmentServicesMutation, UpdateAppointmentServicesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAppointmentServicesMutation, UpdateAppointmentServicesMutationVariables>(UpdateAppointmentServicesDocument, options);
      }
export type UpdateAppointmentServicesMutationHookResult = ReturnType<typeof useUpdateAppointmentServicesMutation>;
export type UpdateAppointmentServicesMutationResult = Apollo.MutationResult<UpdateAppointmentServicesMutation>;
export type UpdateAppointmentServicesMutationOptions = Apollo.BaseMutationOptions<UpdateAppointmentServicesMutation, UpdateAppointmentServicesMutationVariables>;