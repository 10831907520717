import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type LeadFormLinkAggregateQueryVariables = Types.Exact<{
  where?: Types.Maybe<Types.LeadFormLink_Bool_Exp>;
}>;


export type LeadFormLinkAggregateQuery = { __typename?: 'query_root', leadFormLink_aggregate: { __typename?: 'leadFormLink_aggregate', aggregate?: { __typename?: 'leadFormLink_aggregate_fields', count: number } | null | undefined } };


export const LeadFormLinkAggregateDocument = gql`
    query leadFormLinkAggregate($where: leadFormLink_bool_exp) {
  leadFormLink_aggregate(where: $where) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useLeadFormLinkAggregateQuery__
 *
 * To run a query within a React component, call `useLeadFormLinkAggregateQuery` and pass it any options that fit your needs.
 * When your component renders, `useLeadFormLinkAggregateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLeadFormLinkAggregateQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useLeadFormLinkAggregateQuery(baseOptions?: Apollo.QueryHookOptions<LeadFormLinkAggregateQuery, LeadFormLinkAggregateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LeadFormLinkAggregateQuery, LeadFormLinkAggregateQueryVariables>(LeadFormLinkAggregateDocument, options);
      }
export function useLeadFormLinkAggregateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LeadFormLinkAggregateQuery, LeadFormLinkAggregateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LeadFormLinkAggregateQuery, LeadFormLinkAggregateQueryVariables>(LeadFormLinkAggregateDocument, options);
        }
export type LeadFormLinkAggregateQueryHookResult = ReturnType<typeof useLeadFormLinkAggregateQuery>;
export type LeadFormLinkAggregateLazyQueryHookResult = ReturnType<typeof useLeadFormLinkAggregateLazyQuery>;
export type LeadFormLinkAggregateQueryResult = Apollo.QueryResult<LeadFormLinkAggregateQuery, LeadFormLinkAggregateQueryVariables>;