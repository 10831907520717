import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type UpdatePromotionServicesMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
  promotionServices: Array<Types.PromotionServices_Insert_Input> | Types.PromotionServices_Insert_Input;
}>;


export type UpdatePromotionServicesMutation = { __typename?: 'mutation_root', delete_promotionServices?: { __typename?: 'promotionServices_mutation_response', affected_rows: number } | null | undefined, insert_promotionServices?: { __typename?: 'promotionServices_mutation_response', affected_rows: number } | null | undefined };


export const UpdatePromotionServicesDocument = gql`
    mutation UpdatePromotionServices($id: uuid!, $promotionServices: [promotionServices_insert_input!]!) {
  delete_promotionServices(where: {promotionId: {_eq: $id}}) {
    affected_rows
  }
  insert_promotionServices(objects: $promotionServices) {
    affected_rows
  }
}
    `;
export type UpdatePromotionServicesMutationFn = Apollo.MutationFunction<UpdatePromotionServicesMutation, UpdatePromotionServicesMutationVariables>;

/**
 * __useUpdatePromotionServicesMutation__
 *
 * To run a mutation, you first call `useUpdatePromotionServicesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePromotionServicesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePromotionServicesMutation, { data, loading, error }] = useUpdatePromotionServicesMutation({
 *   variables: {
 *      id: // value for 'id'
 *      promotionServices: // value for 'promotionServices'
 *   },
 * });
 */
export function useUpdatePromotionServicesMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePromotionServicesMutation, UpdatePromotionServicesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePromotionServicesMutation, UpdatePromotionServicesMutationVariables>(UpdatePromotionServicesDocument, options);
      }
export type UpdatePromotionServicesMutationHookResult = ReturnType<typeof useUpdatePromotionServicesMutation>;
export type UpdatePromotionServicesMutationResult = Apollo.MutationResult<UpdatePromotionServicesMutation>;
export type UpdatePromotionServicesMutationOptions = Apollo.BaseMutationOptions<UpdatePromotionServicesMutation, UpdatePromotionServicesMutationVariables>;