import {
  Button,
  IconButton,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useColorModeValue as mode,
} from '@chakra-ui/react';
import {
  ListLeadFormsQueryResult,
  useGetLeadFormByPkLazyQuery,
  useInsertLeadFormMutation,
  useUpdateLeadFormMutation,
} from '@webapp/graphql';
import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';
import { CopyIcon, DeleteIcon } from '@chakra-ui/icons';
import toast from 'react-hot-toast';
import { columns } from './_data';

/* eslint-disable-next-line */
export interface TableContentProps {
  data: ListLeadFormsQueryResult['data'];
}

export const TableContent = observer(({ data }: TableContentProps) => {
  const [updateLeadForm] = useUpdateLeadFormMutation({
    onCompleted: () => {
      toast.success('Lead form archived');
    },
    refetchQueries: ['listLeadForms'],
  });

  const [insertLeadForm] = useInsertLeadFormMutation({
    onCompleted: () => {
      toast.success('Lead form copied');
    },
    refetchQueries: ['listLeadForms'],
  });

  const [getLeadForm] = useGetLeadFormByPkLazyQuery();

  const handleCopy = async (id: string) => {
    const leadForm = await getLeadForm({
      variables: {
        id,
      },
    });

    const leadFormCopy = leadForm.data?.leadForm_by_pk;

    if (!leadFormCopy) {
      return;
    }

    const {
      name,
      successMessage,
      businessLogo,
      sideBannerImage,
      backgroundColor,
      font,
      headerCode,
      formBackground,
      surveyJSJson,
      lockedToServices,
      lockedToLocations,
      themeJSON,
      workspaceId,
    } = leadFormCopy;

    insertLeadForm({
      variables: {
        leadForm: {
          name: `${name} (Copy)`,
          successMessage,
          businessLogo,
          sideBannerImage,
          backgroundColor,
          font,
          headerCode,
          formBackground,
          surveyJSJson,
          lockedToServices,
          lockedToLocations,
          themeJSON,
          workspaceId,
        },
      },
    });
  };

  return (
    <Table my="8" borderWidth="1px" fontSize="sm">
      <Thead bg={mode('gray.50', 'gray.800')}>
        <Tr>
          {columns.map((column, index) => (
            <Th whiteSpace="nowrap" scope="col" key={index}>
              {column.Header}
            </Th>
          ))}
          <Th />
        </Tr>
      </Thead>
      <Tbody>
        {data?.leadForm.map((row, rowIndex) => (
          <Tr key={rowIndex}>
            {columns.map((column, columnIndex) => {
              const cell = row[column.accessor as keyof typeof row];
              const element = column.Cell?.(cell) ?? cell;
              return (
                <Td whiteSpace="nowrap" key={columnIndex}>
                  {element}
                </Td>
              );
            })}
            <Td textAlign="right">
              <Link to={`/settings/leadform/${row.id}/edit`}>
                <Button variant="link" colorScheme="blue">
                  Edit
                </Button>
              </Link>
              <IconButton
                aria-label="Copy lead form"
                variant="link"
                icon={<CopyIcon />}
                onClick={() => handleCopy(row.id)}
              >
                Copy
              </IconButton>
              <IconButton
                aria-label="Delete lead form"
                variant="link"
                colorScheme="red"
                icon={<DeleteIcon />}
                onClick={() =>
                  updateLeadForm({
                    variables: {
                      id: row.id,
                      set: {
                        isArchived: true,
                      },
                    },
                  })
                }
              >
                Delete
              </IconButton>
            </Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
});
export default TableContent;
