import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type RefundOrVoidPayrixTransactionMutationVariables = Types.Exact<{
  transactionId: Types.Scalars['String'];
}>;


export type RefundOrVoidPayrixTransactionMutation = { __typename?: 'mutation_root', refundOrVoidPayrixTransaction: { __typename?: 'PayrixTransaction', id: string, status: number, type: number, invoice?: { __typename?: 'invoice', id: any, status: string, payments: Array<{ __typename?: 'payment', id: any, payrixTransactionId?: string | null | undefined }> } | null | undefined } };


export const RefundOrVoidPayrixTransactionDocument = gql`
    mutation RefundOrVoidPayrixTransaction($transactionId: String!) {
  refundOrVoidPayrixTransaction(transactionId: $transactionId) {
    id
    status
    type
    invoice {
      id
      status
      payments {
        id
        payrixTransactionId
      }
    }
  }
}
    `;
export type RefundOrVoidPayrixTransactionMutationFn = Apollo.MutationFunction<RefundOrVoidPayrixTransactionMutation, RefundOrVoidPayrixTransactionMutationVariables>;

/**
 * __useRefundOrVoidPayrixTransactionMutation__
 *
 * To run a mutation, you first call `useRefundOrVoidPayrixTransactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefundOrVoidPayrixTransactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refundOrVoidPayrixTransactionMutation, { data, loading, error }] = useRefundOrVoidPayrixTransactionMutation({
 *   variables: {
 *      transactionId: // value for 'transactionId'
 *   },
 * });
 */
export function useRefundOrVoidPayrixTransactionMutation(baseOptions?: Apollo.MutationHookOptions<RefundOrVoidPayrixTransactionMutation, RefundOrVoidPayrixTransactionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RefundOrVoidPayrixTransactionMutation, RefundOrVoidPayrixTransactionMutationVariables>(RefundOrVoidPayrixTransactionDocument, options);
      }
export type RefundOrVoidPayrixTransactionMutationHookResult = ReturnType<typeof useRefundOrVoidPayrixTransactionMutation>;
export type RefundOrVoidPayrixTransactionMutationResult = Apollo.MutationResult<RefundOrVoidPayrixTransactionMutation>;
export type RefundOrVoidPayrixTransactionMutationOptions = Apollo.BaseMutationOptions<RefundOrVoidPayrixTransactionMutation, RefundOrVoidPayrixTransactionMutationVariables>;