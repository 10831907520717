import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { VitalsFieldsFragmentDoc } from '../../fragments/vitalsFields.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type UpdateVitalsMutationVariables = Types.Exact<{
  set?: Types.Maybe<Types.Vitals_Set_Input>;
  id: Types.Scalars['uuid'];
}>;


export type UpdateVitalsMutation = { __typename?: 'mutation_root', update_vitals_by_pk?: { __typename?: 'vitals', id: any, temperature: any, pulse: number, bloodPressure?: any | null | undefined, patientWorkspaceId: any, createdAt: any, spo2?: any | null | undefined, updatedAt: any, time?: any | null | undefined } | null | undefined };


export const UpdateVitalsDocument = gql`
    mutation UpdateVitals($set: vitals_set_input, $id: uuid!) {
  update_vitals_by_pk(_set: $set, pk_columns: {id: $id}) {
    ...VitalsFields
  }
}
    ${VitalsFieldsFragmentDoc}`;
export type UpdateVitalsMutationFn = Apollo.MutationFunction<UpdateVitalsMutation, UpdateVitalsMutationVariables>;

/**
 * __useUpdateVitalsMutation__
 *
 * To run a mutation, you first call `useUpdateVitalsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVitalsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVitalsMutation, { data, loading, error }] = useUpdateVitalsMutation({
 *   variables: {
 *      set: // value for 'set'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateVitalsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateVitalsMutation, UpdateVitalsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateVitalsMutation, UpdateVitalsMutationVariables>(UpdateVitalsDocument, options);
      }
export type UpdateVitalsMutationHookResult = ReturnType<typeof useUpdateVitalsMutation>;
export type UpdateVitalsMutationResult = Apollo.MutationResult<UpdateVitalsMutation>;
export type UpdateVitalsMutationOptions = Apollo.BaseMutationOptions<UpdateVitalsMutation, UpdateVitalsMutationVariables>;