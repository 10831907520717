import {
  Button,
  ButtonGroup,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from '@chakra-ui/react';
import {
  Address,
  PatientFieldsFragment,
  useCancelSubscriptionMutation,
  useListPaymentMethodsLazyQuery,
  usePayWithNewMethodMutation,
  usePayWithExistingMethodMutation,
  useListPaymentMethodsQuery,
} from '@webapp/graphql';
import { useStores } from '@webapp/state-models';
import { PayForm, PayrixPayfieldsProps } from '@webapp/ui-composites';
import { generateCUDHookParams } from '@webapp/utils';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useState } from 'react';
import { useFlagsmith } from 'flagsmith-react';

interface UpdatePatientMembershipModalProps {
  patientMembership: PatientFieldsFragment['patientMemberships'][number];
  patient: PatientFieldsFragment;
  isOpen: boolean;
  onClose: () => void;
}

enum PatientMembershipModalMode {
  SELECT = 'SELECT',
  UPDATE_PAYMENT = 'UPDATE_PAYMENT',
  CANCEL = 'CANCEL',
}

export const UpdatePatientMembershipModal = observer(
  ({
    isOpen,
    onClose,
    patientMembership,
    patient,
  }: UpdatePatientMembershipModalProps): JSX.Element => {
    const { workspace } = useStores();
    const [mode, setMode] = useState<PatientMembershipModalMode>(
      PatientMembershipModalMode.SELECT
    );
    const { hasFeature } = useFlagsmith();

    const { data: paymentMethodsData } = useListPaymentMethodsQuery({
      variables: {
        where: {
          patientId: { _eq: patient.id },
          archived: { _eq: false },
          processor: {
            _eq:
              hasFeature('finix') && workspace?.finixMerchantId
                ? 'finix'
                : 'payrix',
          },
        },
      },
      skip: !patient.id,
    });

    const paymentMethods =
      paymentMethodsData?.paymentMethod && patient.id
        ? paymentMethodsData?.paymentMethod
        : [];
    const showSavedCards = paymentMethods.length > 0;

    const getDefaultAddress = useCallback((): Partial<Address> | undefined => {
      const patientAddresses = patient?.patientAddresses ?? [];

      const defaultAddress =
        patientAddresses.find((patientAddress) => patientAddress.isDefault) ??
        patientAddresses[0];

      return defaultAddress?.address;
    }, [patient]);
    // console.log(patient);
    // useEffect(() => {
    //   if (!patient.id) return;
    //   getPatientPaymentMethods({
    //     variables: {
    //       where: {
    //         patientId: { _eq: patient.id },
    //         archived: { _eq: false },
    //         processor: {
    //           _eq:
    //             hasFeature('finix') && workspace?.finixMerchantId
    //               ? 'finix'
    //               : 'payrix',
    //         },
    //       },
    //     },
    //   });
    // }, [patient.id, getPatientPaymentMethods]);

    const commonHookParams = generateCUDHookParams({
      item: 'Subscription Payment Method',
      operation: 'updated',
      callback: onClose,
      refetchQueries: ['GetOnePatient'],
    });

    const [payWithNewMethod] = usePayWithNewMethodMutation(commonHookParams);
    const [payWithExistingMethod] =
      usePayWithExistingMethodMutation(commonHookParams);

    const [cancelSubscription] = useCancelSubscriptionMutation(
      generateCUDHookParams({
        item: 'Subscription',
        operation: 'updated',
        callback: onClose,
        refetchQueries: ['GetOnePatient'],
      })
    );

    const handleCancelMembership = (): void => {
      cancelSubscription({
        variables: {
          patientMembershipId: patientMembership.id,
        },
      });
    };

    const Select = (): JSX.Element => (
      <Stack
        spacing={2}
        w="full"
        justifyContent={'center'}
        alignItems={'center'}
        display={'flex'}
      >
        <Button
          maxW={'300px'}
          colorScheme={'teal'}
          onClick={() => setMode(PatientMembershipModalMode.UPDATE_PAYMENT)}
        >
          Update Payment Method
        </Button>
        <Button
          maxW={'300px'}
          colorScheme={'red'}
          onClick={() => setMode(PatientMembershipModalMode.CANCEL)}
        >
          Cancel Membership
        </Button>
      </Stack>
    );

    const Cancel = (): JSX.Element => (
      <Text>Are you sure you want to cancel this membership?</Text>
    );

    const UpdatePayment = (): JSX.Element => {
      const onPayWithNewMethod: PayrixPayfieldsProps<'token'>['onPayWithNewMethod'] =
        ({ token }) => {
          payWithNewMethod({
            variables: {
              amount: 0,
              caseName: 'UPDATE_SUBSCRIPTION_PAYMENT_METHOD',
              caseContext: {
                patientMembershipId: patientMembership.id,
              },
              patientId: patient.id,
              paymentInfo: token,
              payrixMerchantId: workspace?.payrixMerchantId ?? '',
              workspaceId: workspace?.id,
            },
          });
        };

      const onPayWithExistingMethod: PayrixPayfieldsProps<'token'>['onPayWithExistingMethod'] =
        ({ paymentMethodId }) => {
          payWithExistingMethod({
            variables: {
              amount: 0,
              caseName: 'UPDATE_SUBSCRIPTION_PAYMENT_METHOD',
              caseContext: {
                patientMembershipId: patientMembership.id,
              },
              patientId: patient.id,
              paymentMethodId,
              payrixMerchantId: workspace?.payrixMerchantId ?? '',
              workspaceId: workspace?.id,
            },
          });
        };

      const onPayWithNewFinixMethod = async ({
        token,
        cardOwnerDetails,
        finixFraudSessionId,
      }: any) =>
        payWithNewMethod({
          variables: {
            amount: 0,
            caseName: 'UPDATE_SUBSCRIPTION_PAYMENT_METHOD',
            caseContext: {
              patientMembershipId: patientMembership.id,
            },
            patientId: patient.id,
            paymentInfo: {
              token,
              cardOwnerDetails,
              finixFraudSessionId,
            },
            finixMerchantId: workspace?.finixMerchantId ?? '',
            workspaceId: workspace?.id ?? '',
          },
        });

      return (
        <PayForm
          amount={1}
          authenticated={false}
          mode="token"
          type={
            hasFeature('finix') && workspace?.finixMerchantId
              ? 'cardTokenForm'
              : 'payrix'
          }
          onNewCardToken={onPayWithNewFinixMethod}
          patientId={patient.id}
          paymentMethods={
            hasFeature('finix') && workspace?.finixMerchantId
              ? null
              : paymentMethodsData?.paymentMethod
          }
          onExistingCardToken={onPayWithExistingMethod}
          onPayWithNewMethod={onPayWithNewMethod}
          onPayWithExistingMethod={onPayWithExistingMethod}
          finixMerchantId={workspace?.finixMerchantId ?? ''}
          payrixMerchantId={workspace?.payrixMerchantId ?? ''}
          showPaymentAmount={false}
          showSavedCards={showSavedCards}
          workspaceId={workspace?.id ?? ''}
          cardOwnerDetails={{
            name: `${patient.attributes.firstName} ${patient.attributes.lastName}`,
            email: patient.attributes.email ?? '',
            phoneNumber: patient.attributes.phoneNumber ?? '',
            address: getDefaultAddress() ?? {},
          }}
        />
      );
    };

    const backToSelect = (): void => {
      setMode(PatientMembershipModalMode.SELECT);
    };

    const headerText = (): string => {
      switch (mode) {
        case PatientMembershipModalMode.CANCEL:
          return 'Cancel Membership';

        case PatientMembershipModalMode.UPDATE_PAYMENT:
          return 'Update Payment Method';

        default:
          return 'Select Action';
      }
    };

    const bodyComponent = (): JSX.Element => {
      switch (mode) {
        case PatientMembershipModalMode.CANCEL:
          return <Cancel />;
        case PatientMembershipModalMode.UPDATE_PAYMENT:
          return <UpdatePayment />;
        default:
          return <Select />;
      }
    };

    return (
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent width="700px" maxWidth="unset">
          <ModalHeader>{headerText()}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>{bodyComponent()}</ModalBody>
          <ModalFooter>
            <Flex justifyContent="space-between" w="full">
              <ButtonGroup gap={4}>
                {mode !== PatientMembershipModalMode.SELECT && (
                  <Button onClick={backToSelect}>Back</Button>
                )}
                <Button onClick={() => onClose()}>Close</Button>
              </ButtonGroup>
              {mode === PatientMembershipModalMode.CANCEL && (
                <Button colorScheme={'red'} onClick={handleCancelMembership}>
                  Yes
                </Button>
              )}
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  }
);

export default UpdatePatientMembershipModal;
