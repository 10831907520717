import {
  chakra,
  Button,
  HStack,
  Image,
  Skeleton,
  Tag,
  Text,
  Wrap,
  WrapItem,
  Checkbox,
} from '@chakra-ui/react';
import { PhotoGridPhoto } from '@webapp/types';
import { SimpleVideo } from '@webapp/ui';
import { getProxyPhotoUrl } from '@webapp/utils';
import dayjs from 'dayjs';

interface DefaultPhotoGridProps {
  columnGap?: number;
  isLoading?: boolean;
  nextPage?: () => void;
  onPhotoClick?: (photo: PhotoGridPhoto) => void;
  photos: PhotoGridPhoto[];
  rowGap?: number;
  selectedPhoto?: PhotoGridPhoto | null;
  selectedPhotos?: string[];
  onSelect?: (photo: string) => void;
  shouldDisplayTags: boolean;
  withPhotoMetadata?: boolean;
}

function DefaultPhotoGrid({
  columnGap = 30,
  isLoading = false,
  nextPage,
  onPhotoClick = () => undefined,
  photos,
  rowGap = 30,
  selectedPhoto,
  shouldDisplayTags,
  withPhotoMetadata = true,
  onSelect,
  selectedPhotos = [],
}: DefaultPhotoGridProps) {
  return (
    <chakra.div display="grid">
      <chakra.div
        display="grid"
        gridTemplateColumns="repeat(auto-fill, minmax(188px, 1fr))"
        gridColumnGap={`${columnGap}px`}
        gridRowGap={`${rowGap}px`}
      >
        {photos.map((photo) => (
          <chakra.div
            cursor={'pointer'}
            display="grid"
            gridAutoColumns="100%"
            gridRowGap="12px"
            gridTemplateRows="188px max-content"
            key={photo.id}
            position="relative"
            rounded="md"
          >
            <chakra.div
              maxHeight="188px"
              overflow="hidden"
              position="relative"
              width="100%"
            >
              {photo?.mimeType && photo?.mimeType.includes('video') ? (
                <SimpleVideo
                  onClick={() => {
                    if (!(selectedPhoto?.id === photo.id)) {
                      onPhotoClick(photo);
                    }
                  }}
                  src={photo.file?.url}
                />
              ) : (
                <Image
                  onClick={() => {
                    if (!(selectedPhoto?.id === photo.id)) {
                      onPhotoClick(photo);
                    }
                  }}
                  className="ph-no-capture fs-exclude"
                  borderRadius="8px"
                  fallback={<Skeleton w={'full'} h={'60'} />}
                  minHeight="100%"
                  objectFit="cover"
                  src={getProxyPhotoUrl(photo.file?.url, 'thumbnail')}
                  width="full"
                  {...(!(selectedPhoto?.id === photo.id) && {
                    _hover: {
                      shadow: 'lg',
                      opacity: 0.75,
                    },
                  })}
                />
              )}
              <Checkbox
                onChange={(e) => {
                  e.stopPropagation();
                  onSelect?.(photo.id);
                }}
                isChecked={
                  selectedPhotos.includes(photo.id) ||
                  photo.id === selectedPhoto?.id
                }
                size="lg"
                position={'absolute'}
                top="2"
                left="3"
              />
            </chakra.div>
            {withPhotoMetadata && (
              <chakra.div>
                <Text
                  color="#000"
                  fontWeight="400"
                  letterSpacing="0.03em"
                  size="14px"
                  overflow="hidden"
                  textOverflow="ellipsis"
                  whiteSpace="nowrap"
                >
                  {photo.file?.filePath}
                </Text>
                <Text
                  color="#6C6C72"
                  fontWeight="400"
                  letterSpacing="0.03em"
                  size="12px"
                  overflow="hidden"
                  textOverflow="ellipsis"
                  whiteSpace="nowrap"
                >
                  {dayjs(photo.mediaDate).format('MMM D, YYYY')}
                </Text>
                {shouldDisplayTags && (
                  <HStack pb={2} px={2} w={'full'} overflowY={'auto'}>
                    <Wrap>
                      {photo.photoMediaTags?.map((mediaTag) => (
                        <WrapItem key={`${mediaTag.mediaTag.title}`}>
                          <Tag size={'sm'}>{mediaTag.mediaTag.title}</Tag>
                        </WrapItem>
                      ))}
                    </Wrap>
                  </HStack>
                )}
              </chakra.div>
            )}
          </chakra.div>
        ))}
      </chakra.div>
      {nextPage && (
        <Button
          isLoading={isLoading}
          justifySelf="center"
          margin="50px 0 0"
          onClick={nextPage}
          width="max-content"
        >
          Load More
        </Button>
      )}
    </chakra.div>
  );
}

export default DefaultPhotoGrid;
