import { Button, Flex, Icon, useDisclosure } from '@chakra-ui/react';
import {
  GetAppointmentDocument,
  MinimalResolvedCustomForm,
  useUpdateAppointmentCustomFormsMutation,
} from '@webapp/graphql';
import {
  AppointmentStepProps,
  CompletedSelectOptionAndDocumentId,
} from '@webapp/types';
import { HookForm } from '@webapp/ui';
import { uniqBy } from 'lodash';
import { useMemo, useState } from 'react';
import { HiPencilAlt } from 'react-icons/hi';
import { CustomFormsForm } from './customFormsForm';

interface FormInputs {
  customFormsToDelete: CompletedSelectOptionAndDocumentId[];
  customForms: CompletedSelectOptionAndDocumentId[];
}

export const FormCustomFormSection = ({
  appointment,
  mini,
}: AppointmentStepProps): JSX.Element => {
  const { isOpen, onClose, onToggle } = useDisclosure();
  const [finishedSubmitting, setFinishedSubmitting] = useState(false);

  const [updateAppointmentCustomForms] =
    useUpdateAppointmentCustomFormsMutation({
      onCompleted: () => {
        onClose();
      },
      refetchQueries: ['GetAppointment'],
    });

  const {
    resolvedAppointmentCustomForms: { customForms: resolvedCustomForms },
  } = appointment;

  const optionsArray: CompletedSelectOptionAndDocumentId[] = useMemo(
    () =>
      resolvedCustomForms.map((customForm: MinimalResolvedCustomForm) => ({
        label: customForm.title,
        value: customForm.id,
        completed: customForm.completed,
        documentId: customForm.documentId,
      })),
    [resolvedCustomForms, resolvedCustomForms?.customForms?.length]
  );

  function onSubmit(values: FormInputs) {
    const { customForms, customFormsToDelete } = values;

    const mappedCustomFormsToDelete = customFormsToDelete.map((customForm) => ({
      appointmentId: appointment.id,
      customFormId: customForm.value,
      manuallyRemoved: true,
    }));

    const mappedCustomFormsToCreate = customForms.map((customForm) => ({
      appointmentId: appointment.id,
      customFormId: customForm.value,
      manuallyAdded: true,
    }));

    updateAppointmentCustomForms({
      variables: {
        objects: uniqBy(
          [...mappedCustomFormsToCreate, ...mappedCustomFormsToDelete],
          'customFormId'
        ),
      },
    }).then(() => {
      setFinishedSubmitting(true);
    });
  }

  return (
    <HookForm<FormInputs>
      defaultValues={{
        customForms: optionsArray,
        customFormsToDelete: [],
      }}
      onSubmit={onSubmit}
    >
      <>
        {!mini && (
          <Flex justifyContent="flex-end">
            <Button
              onClick={onToggle}
              variant="ghost"
              _hover={{
                background: '#EAFCFF',
              }}
              w="50px"
            >
              <Icon
                as={HiPencilAlt}
                color="teal.500"
                height="18px"
                width="18px"
              />
            </Button>
          </Flex>
        )}
        <CustomFormsForm
          key={`${appointment.id}-custom-forms-${appointment?.resolvedAppointmentCustomForms?.customForms?.length}`}
          appointment={appointment}
          isEditable={isOpen}
          onToggle={onToggle}
          mini={mini}
          finishedSubmitting={finishedSubmitting}
          setFinishedSubmitting={setFinishedSubmitting}
        />
      </>
    </HookForm>
  );
};

export default FormCustomFormSection;
