import { HStack, Stack, Switch, Text } from '@chakra-ui/react';
import { MinimalPatientWallet } from '@webapp/types';
import { useState } from 'react';
import PatientWalletLedgerLog from './PatientWalletLedgerLog';
import PatientWalletLedgerTableWrapper from './PatientWalletLedgerTableWrapper';

interface PatientWalletLedgerViewsProps {
  patientWallet: MinimalPatientWallet;
}

export const PatientWalletLedgerViews = ({
  patientWallet,
}: PatientWalletLedgerViewsProps): JSX.Element => {
  const [isTableView, setIsTableView] = useState(true);

  return (
    <Stack w={'full'}>
      <HStack>
        <Text>{isTableView ? 'Table' : 'Log'} View</Text>
        <Switch
          defaultChecked
          onChange={(e) => setIsTableView(e.target.checked)}
        />
      </HStack>
      {isTableView && (
        <PatientWalletLedgerTableWrapper patientWallet={patientWallet} />
      )}
      {!isTableView && <PatientWalletLedgerLog patientWallet={patientWallet} />}
    </Stack>
  );
};

export default PatientWalletLedgerViews;
