import { useState, useEffect } from 'react';
import { useGetOrganizationByDomainLazyQuery } from '@webapp/graphql';
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface UseGetOrganizationByHost {
  organizationData:
    | {
        lightLogoSmall: string;
        termsAndConditionsLink: string | null;
        privacyPolicyLink: string | null;
        lightLogo: string;
        whitelabelingEnabled: boolean;
        organization: {
          name: string;
        };
      }
    | null
    | undefined;
  loading: boolean;
}

export function useGetOrganizationByHost(): UseGetOrganizationByHost {
  const [getOrganizationByDomain, { loading }] =
    useGetOrganizationByDomainLazyQuery();
  const [organizationData, setOrganizationData] = useState<any>(null);
  useEffect(() => {
    const getDomain = () => {
      const { hostname } = window.location;
      const parts = hostname.split('.');
      return parts.slice(-2).join('.');
    };

    const fetchOrganization = async () => {
      const domain = getDomain();
      const { data, error } = await getOrganizationByDomain({
        variables: { domain },
      });

      if (!error && data) {
        setOrganizationData(data.organizationConfiguration?.[0]);
      }
    };

    fetchOrganization();
  }, []);
  return { organizationData, loading };
}

export default useGetOrganizationByHost;
