import { useStores } from '@webapp/state-models';
import { Serializer, setLicenseKey } from 'survey-core';
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore */

setLicenseKey(
    'MTMxYTY2ODMtNTQxMC00NWIzLTliMGMtZGMyN2RiMGUzMzgyOzE9MjAyNS0wMi0wOCwyPTIwMjUtMDItMDgsND0yMDI1LTAyLTA4'
  );
import { HStack, Stack, Button } from '@chakra-ui/react';
import {
  useGetLeadFormByPkQuery,
  useUpdateLeadFormMutation,
} from '@webapp/graphql';
import { useParams } from 'react-router-dom';
import { CustomEditableInput } from '@webapp/ui';
import { useEffect, useState } from 'react';
import { LeadFormFieldsFragment } from 'libs/graphql/src/fragments/leadFormFields.fragment.generated';
import toast from 'react-hot-toast';
import { SurveyJSCreator } from './surveyjs-creator';
// import { SurveyJSLeadFormRenderer } from './survey-jsform-renderer';
import configureLeadFormSurveyJSComponents from './configureLeadFormComponents';
/* eslint-disable-next-line */
export interface CreateSurveyJSLeadFormProps {}

const questionTypes = [
  'book-appointment-composite',
  'service-order-ranking',
  'text',
  'checkbox',
  'radiogroup',
  'dropdown',
  'comment',
  'rating',
  'matrix',
  'matricdropdown',
  'matricdynamic',
  'multipletext',
  'panel',
  'paneldynamic',
  'boolean',
  'image',
  'html',
  'ranking',
  'expression',
  'signaturepad',
  // CUSTOM
  'book-appointment-composite',
  'phone-number',
  'date-of-birth',
  'weight',
  'height',
  'email',
  'shipping-address',
  'billing-address',
  'full-name',
  'sex',
  'gender',
  'pregnant',
  'nursing',
  'private-image-upload',
  'wrapped-how-did-you-find-us',
  'service-category',
];

const bootstrapFunction = () => {
  const currentWorkspace = localStorage.getItem(
    `prspr_wid_${import.meta.env.VITE_ENV}`
  );
  Serializer.addProperty('survey', {
    name: 'customBackgroundImage',
    category: 'general',
    visibleIndex: 2,
    questionType: 'image-upload',
    type: 'image-upload',
  });

  Serializer.addProperty('survey', {
    name: 'headerCode',
    category: 'general',
    visibleIndex: 1,
    questionType: 'html',
    type: 'html',
  });

  Serializer.addProperty('survey', {
    name: 'facebookPixelId',
    category: 'Facebook / meta pixel',
    visibleIndex: 1,
    questionType: 'input',
    type: 'input',
  });

  Serializer.addProperty('survey', {
    name: 'facebookNextPageConversionEventName',
    title: 'Next Page Conversion Event Name',
    category: 'Facebook / meta pixel',
    visibleIndex: 2,
    questionType: 'input',
    type: 'input',
  });

  Serializer.addProperty('survey', {
    name: 'facebookFormCompletionConversionEventName',
    title: 'Form Completion Conversion Event Name',
    category: 'Facebook / meta pixel',
    visibleIndex: 3,
    questionType: 'input',
    type: 'input',
  });

  Serializer.addProperty('survey', {
    name: 'websiteHomepage',
    category: 'general',
    visibleIndex: 3,
    questionType: 'input',
    type: 'input',
  });

  Serializer.addProperty('question', {
    name: 'workspaceId',
    type: 'text',
    isRequired: true,
    category: 'general',
    visible: false,
    default: currentWorkspace,
  });

  Serializer.addProperty('survey', {
    name: 'lockedToLocations',
    type: 'location-selection',
    questionType: 'location-selection',
    visibleIndex: 0,
    category: 'Booking Rules',
  });

  Serializer.addProperty('survey', {
    name: 'lockedToServices',
    type: 'service-selection',
    questionType: 'service-selection',
    visibleIndex: 1,
    category: 'Booking Rules',
  });

  Serializer.addProperty('survey', {
    name: 'allowProviderSelection',
    type: 'boolean',
    visibleIndex: 2,
    default: true,
    category: 'Booking Rules',
  });
};

export function CreateSurveyJSLeadForm(props: CreateSurveyJSLeadFormProps) {
  const { workspace } = useStores();
  const { id } = useParams();
  const [ready, setReady] = useState(false);
  const { data } = useGetLeadFormByPkQuery({
    variables: { id },
  });
  const [updateLeadForm] = useUpdateLeadFormMutation({
    onCompleted: () => {
      toast.success('Lead Form updated successfully');
    },
    onError: (error) => {
      toast.error(error.message);
    },
  });

  const [leadForm, setLeadForm] = useState<
    LeadFormFieldsFragment | undefined
  >();

  useEffect(() => {
    if (data?.leadForm_by_pk) {
      setLeadForm(data?.leadForm_by_pk);
    }
  }, [data]);

  useEffect(() => {
    configureLeadFormSurveyJSComponents({
      patientReferralOptions:
        workspace?.workspaceConfiguration?.patientReferralOptions,
    });
    Serializer.addProperty('question', {
      name: 'payrixMerchantId',
      type: 'text',
      isRequired: false,
      category: 'general',
      visible: false,
      default: workspace?.payrixMerchantId,
    });
  }, []);

  function saveLeadForm() {
    updateLeadForm({
      variables: {
        id: data?.leadForm_by_pk?.id,
        set: {
          name: leadForm?.name,
          surveyJSJson: leadForm?.surveyJSJson,
          themeJSON: leadForm?.themeJSON,
        },
      },
    });
  }

  if (!data?.leadForm_by_pk) return null;
  Serializer.addProperty('survey', {
    name: 'serviceOrderRanking',
    category: 'Service Order',
    visibleIndex: 1,
    type: 'service-order-ranking',
    visible: true,
  });

  Serializer.addProperty('survey', {
    name: 'leadStatusBucket',
    category: 'general',
    visibleIndex: 1,
    type: 'dropdown',
    choices: workspace?.workspaceConfiguration?.leadStatusOptions,
    visible: true,
  });

  // @ts-expect-error survey-creator-react types are wrong
  return (
    <Stack maxW="100vw" overflowX={'auto'}>
      <HStack justifyContent={'space-between'} p={2}>
        <CustomEditableInput
          defaultValue={data?.leadForm_by_pk?.name || 'New Form'}
          onChange={(v) => {
            if (v && leadForm)
              setLeadForm({
                ...leadForm,
                name: v,
              });
          }}
          type={'text'}
        ></CustomEditableInput>
        <HStack spacing={2}>
          <Button
            onClick={() => {
              window.open(`/leadform/${data?.leadForm_by_pk?.id}`, '_blank');
            }}
          >
            Test Form
          </Button>
          <Button colorScheme={'teal'} onClick={saveLeadForm}>
            Save
          </Button>
        </HStack>
      </HStack>
      <SurveyJSCreator
        surveyJson={data?.leadForm_by_pk?.surveyJSJson || {}}
        themeJSON={data?.leadForm_by_pk?.themeJSON}
        workspace={workspace}
        onChange={(newJson: any) => {
          if (newJson) {
            setLeadForm({
              ...leadForm,
              surveyJSJson: newJson,
            });
          }
        }}
        onThemeChange={(newJson: any) => {
          if (newJson) {
            setLeadForm({
              ...leadForm,
              themeJSON: newJson,
            });
          }
        }}
        bootstrapFunction={bootstrapFunction}
        questionTypes={questionTypes}
      />
    </Stack>
  );
}

export default CreateSurveyJSLeadForm;
// export { SurveyJSLeadFormRenderer };
