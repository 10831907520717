import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { GoogleLocationFieldsFragmentDoc } from '../../fragments/googleLocationFields.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetGoogleLocationQueryVariables = Types.Exact<{
  where: Types.GoogleLocation_Bool_Exp;
}>;


export type GetGoogleLocationQuery = { __typename?: 'query_root', googleLocation: Array<{ __typename?: 'googleLocation', id: any, name: string, title: string, placeId: string, workspaceId: any }> };


export const GetGoogleLocationDocument = gql`
    query getGoogleLocation($where: googleLocation_bool_exp!) {
  googleLocation(where: $where) {
    ...GoogleLocationFields
  }
}
    ${GoogleLocationFieldsFragmentDoc}`;

/**
 * __useGetGoogleLocationQuery__
 *
 * To run a query within a React component, call `useGetGoogleLocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGoogleLocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGoogleLocationQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetGoogleLocationQuery(baseOptions: Apollo.QueryHookOptions<GetGoogleLocationQuery, GetGoogleLocationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGoogleLocationQuery, GetGoogleLocationQueryVariables>(GetGoogleLocationDocument, options);
      }
export function useGetGoogleLocationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGoogleLocationQuery, GetGoogleLocationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGoogleLocationQuery, GetGoogleLocationQueryVariables>(GetGoogleLocationDocument, options);
        }
export type GetGoogleLocationQueryHookResult = ReturnType<typeof useGetGoogleLocationQuery>;
export type GetGoogleLocationLazyQueryHookResult = ReturnType<typeof useGetGoogleLocationLazyQuery>;
export type GetGoogleLocationQueryResult = Apollo.QueryResult<GetGoogleLocationQuery, GetGoogleLocationQueryVariables>;