import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { CustomFormFieldsFragmentDoc } from '../../fragments/customFormFields.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type UpdateCustomFormByPkMutationVariables = Types.Exact<{
  set?: Types.Maybe<Types.CustomForm_Set_Input>;
  id: Types.Scalars['uuid'];
}>;


export type UpdateCustomFormByPkMutation = { __typename?: 'mutation_root', update_customForm_by_pk?: { __typename?: 'customForm', id: any, title: string, isRequired: boolean, allFieldsRequired: boolean, createdAt: any, recurrence?: string | null | undefined, language?: string | null | undefined, surveyJSJSON?: any | null | undefined, workspaceId: any, rows: Array<{ __typename?: 'customFormRow', id: any, index: number, replicable?: boolean | null | undefined, fields: Array<{ __typename?: 'customFormField', id: any, index: number, label: string, body?: string | null | undefined, category?: Types.CustomFormFieldCategory_Enum | null | undefined, type: Types.CustomFormFieldType_Enum, table?: string | null | undefined, attribute?: string | null | undefined, validation?: any | null | undefined, photoId?: any | null | undefined, row: { __typename?: 'customFormRow', id: any, index: number }, options: Array<{ __typename?: 'customFormOption', id: any, value: string }>, photo?: { __typename?: 'photo', file?: { __typename?: 'FilePayload', url: string } | null | undefined } | null | undefined }> }>, serviceCustomForms: Array<{ __typename?: 'serviceCustomForm', id: any, service: { __typename?: 'service', id: any, name: string } }> } | null | undefined };


export const UpdateCustomFormByPkDocument = gql`
    mutation UpdateCustomFormByPk($set: customForm_set_input, $id: uuid!) {
  update_customForm_by_pk(_set: $set, pk_columns: {id: $id}) {
    ...CustomFormFields
  }
}
    ${CustomFormFieldsFragmentDoc}`;
export type UpdateCustomFormByPkMutationFn = Apollo.MutationFunction<UpdateCustomFormByPkMutation, UpdateCustomFormByPkMutationVariables>;

/**
 * __useUpdateCustomFormByPkMutation__
 *
 * To run a mutation, you first call `useUpdateCustomFormByPkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomFormByPkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomFormByPkMutation, { data, loading, error }] = useUpdateCustomFormByPkMutation({
 *   variables: {
 *      set: // value for 'set'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateCustomFormByPkMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCustomFormByPkMutation, UpdateCustomFormByPkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCustomFormByPkMutation, UpdateCustomFormByPkMutationVariables>(UpdateCustomFormByPkDocument, options);
      }
export type UpdateCustomFormByPkMutationHookResult = ReturnType<typeof useUpdateCustomFormByPkMutation>;
export type UpdateCustomFormByPkMutationResult = Apollo.MutationResult<UpdateCustomFormByPkMutation>;
export type UpdateCustomFormByPkMutationOptions = Apollo.BaseMutationOptions<UpdateCustomFormByPkMutation, UpdateCustomFormByPkMutationVariables>;