import { HStack, Icon, StyleProps, Text } from '@chakra-ui/react';
import capitalize from 'lodash/capitalize';
import { StatusMap } from '@webapp/types';
import DEFAULT_STATUS_MAP from './constants/DefaultStatusMap';

export interface StatusBadgeProps extends StyleProps {
  customStyle?: {
    badge?: StyleProps;
    icon?: StyleProps;
    text?: StyleProps;
  };
  status: string;
  statusMap?: StatusMap;
}

const DefaultBadgeStyle: StyleProps = {
  borderRadius: '6px',
  fontSize: '12px',
  fontWeight: '700',
  letterSpacing: '0.03em',
  lineHeight: '18px',
  height: 'max-content',
  padding: '4px 12px',
  width: 'max-content',
};

export function StatusBadge({
  customStyle = {},
  status,
  statusMap = DEFAULT_STATUS_MAP,
  ...styleProps
}: StatusBadgeProps) {
  const {
    badge: badgeStyle = {},
    icon: iconStyle = {},
    text: textStyle = {},
  } = customStyle;

  function getStatusProps(statusKey?: string) {
    if (statusKey) {
      return statusMap[statusKey] ?? statusMap.DEFAULT;
    }

    return statusMap.DEFAULT;
  }

  const statusProps = getStatusProps(status);

  return (
    <HStack
      background={statusProps.background}
      color={statusProps.color}
      spacing="6px"
      {...DefaultBadgeStyle}
      {...styleProps}
      {...badgeStyle}
    >
      {statusProps.icon && <Icon as={statusProps.icon} {...iconStyle} />}
      <Text {...textStyle}>
        {statusProps.label ?? capitalize(status.replace(/_/, ' '))}
      </Text>
    </HStack>
  );
}

export default StatusBadge;
