import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetEmailCampaignMetricsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetEmailCampaignMetricsQuery = { __typename?: 'query_root', emailCampaignMetrics: { __typename?: 'emailCampaignMetrics_aggregate', aggregate?: { __typename?: 'emailCampaignMetrics_aggregate_fields', sum?: { __typename?: 'emailCampaignMetrics_sum_fields', bounces?: any | null | undefined, clicks?: any | null | undefined, deliveries?: any | null | undefined, opens?: any | null | undefined, spamcomplaints?: any | null | undefined, unsubscribes?: any | null | undefined } | null | undefined } | null | undefined }, emailTemplate: Array<{ __typename?: 'emailTemplate', id: any, emailName?: string | null | undefined, subjectLine?: string | null | undefined, lastSentDate?: any | null | undefined, bounces: number, clicks: number, deliveries: number, opens: number, spamFlags: number, unsubscribes: number, metrics?: { __typename?: 'emailCampaignMetrics', bounces?: any | null | undefined, clicks?: any | null | undefined, deliveries?: any | null | undefined, opens?: any | null | undefined, spamcomplaints?: any | null | undefined, unsubscribes?: any | null | undefined } | null | undefined }> };


export const GetEmailCampaignMetricsDocument = gql`
    query getEmailCampaignMetrics {
  emailCampaignMetrics: emailCampaignMetrics_aggregate {
    aggregate {
      sum {
        bounces
        clicks
        deliveries
        opens
        spamcomplaints
        unsubscribes
      }
    }
  }
  emailTemplate(where: {isActive: {_eq: true}, isTemplate: {_eq: false}}) {
    id
    emailName
    subjectLine
    lastSentDate
    metrics {
      bounces
      clicks
      deliveries
      opens
      spamcomplaints
      unsubscribes
    }
    bounces
    clicks
    deliveries
    opens
    spamFlags
    unsubscribes
  }
}
    `;

/**
 * __useGetEmailCampaignMetricsQuery__
 *
 * To run a query within a React component, call `useGetEmailCampaignMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmailCampaignMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmailCampaignMetricsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetEmailCampaignMetricsQuery(baseOptions?: Apollo.QueryHookOptions<GetEmailCampaignMetricsQuery, GetEmailCampaignMetricsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEmailCampaignMetricsQuery, GetEmailCampaignMetricsQueryVariables>(GetEmailCampaignMetricsDocument, options);
      }
export function useGetEmailCampaignMetricsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEmailCampaignMetricsQuery, GetEmailCampaignMetricsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEmailCampaignMetricsQuery, GetEmailCampaignMetricsQueryVariables>(GetEmailCampaignMetricsDocument, options);
        }
export type GetEmailCampaignMetricsQueryHookResult = ReturnType<typeof useGetEmailCampaignMetricsQuery>;
export type GetEmailCampaignMetricsLazyQueryHookResult = ReturnType<typeof useGetEmailCampaignMetricsLazyQuery>;
export type GetEmailCampaignMetricsQueryResult = Apollo.QueryResult<GetEmailCampaignMetricsQuery, GetEmailCampaignMetricsQueryVariables>;