import { addMessages } from '@webapp/joi-validation';
import { DefaultNullValidation } from '@webapp/util-validation';
import Joi from 'joi';

const CardDetailsValidation = Joi.object({
  address: Joi.object({
    addressLine1: addMessages(Joi.string().required(), 'Address'),
    addressLine2: DefaultNullValidation,
    city: Joi.string().required(),
    formattedAddress: Joi.string().allow(''),
    state: Joi.string().required(),
    zipCode: Joi.string().required(),
  }).options({ allowUnknown: true }),
  email: addMessages(Joi.string().required(), 'Email'),
  name: addMessages(Joi.string().required(), 'Name'),
  phoneNumber: addMessages(Joi.string().required(), 'Phone #'),
});

export default CardDetailsValidation;
