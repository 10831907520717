import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type UpdateUserWorkspaceMutationVariables = Types.Exact<{
  set?: Types.Maybe<Types.UserWorkspace_Set_Input>;
  id: Types.Scalars['uuid'];
}>;


export type UpdateUserWorkspaceMutation = { __typename?: 'mutation_root', update_userWorkspace_by_pk?: { __typename?: 'userWorkspace', id: any } | null | undefined };


export const UpdateUserWorkspaceDocument = gql`
    mutation UpdateUserWorkspace($set: userWorkspace_set_input, $id: uuid!) {
  update_userWorkspace_by_pk(_set: $set, pk_columns: {id: $id}) {
    id
  }
}
    `;
export type UpdateUserWorkspaceMutationFn = Apollo.MutationFunction<UpdateUserWorkspaceMutation, UpdateUserWorkspaceMutationVariables>;

/**
 * __useUpdateUserWorkspaceMutation__
 *
 * To run a mutation, you first call `useUpdateUserWorkspaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserWorkspaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserWorkspaceMutation, { data, loading, error }] = useUpdateUserWorkspaceMutation({
 *   variables: {
 *      set: // value for 'set'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateUserWorkspaceMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserWorkspaceMutation, UpdateUserWorkspaceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserWorkspaceMutation, UpdateUserWorkspaceMutationVariables>(UpdateUserWorkspaceDocument, options);
      }
export type UpdateUserWorkspaceMutationHookResult = ReturnType<typeof useUpdateUserWorkspaceMutation>;
export type UpdateUserWorkspaceMutationResult = Apollo.MutationResult<UpdateUserWorkspaceMutation>;
export type UpdateUserWorkspaceMutationOptions = Apollo.BaseMutationOptions<UpdateUserWorkspaceMutation, UpdateUserWorkspaceMutationVariables>;