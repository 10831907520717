import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetAudiencePreviewQueryVariables = Types.Exact<{
  query: Types.Scalars['jsonb'];
}>;


export type GetAudiencePreviewQuery = { __typename?: 'query_root', previewAudience?: { __typename?: 'PreviewAudienceOutput', count: number, audienceSample?: Array<{ __typename?: 'AudienceMember', id: any, firstName?: string | null | undefined, lastName?: string | null | undefined, email?: string | null | undefined, phoneNumber?: string | null | undefined, status?: string | null | undefined, leadStatus?: string | null | undefined }> | null | undefined } | null | undefined };


export const GetAudiencePreviewDocument = gql`
    query getAudiencePreview($query: jsonb!) {
  previewAudience(query: $query) {
    count
    audienceSample {
      id
      firstName
      lastName
      email
      phoneNumber
      status
      leadStatus
    }
  }
}
    `;

/**
 * __useGetAudiencePreviewQuery__
 *
 * To run a query within a React component, call `useGetAudiencePreviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAudiencePreviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAudiencePreviewQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useGetAudiencePreviewQuery(baseOptions: Apollo.QueryHookOptions<GetAudiencePreviewQuery, GetAudiencePreviewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAudiencePreviewQuery, GetAudiencePreviewQueryVariables>(GetAudiencePreviewDocument, options);
      }
export function useGetAudiencePreviewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAudiencePreviewQuery, GetAudiencePreviewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAudiencePreviewQuery, GetAudiencePreviewQueryVariables>(GetAudiencePreviewDocument, options);
        }
export type GetAudiencePreviewQueryHookResult = ReturnType<typeof useGetAudiencePreviewQuery>;
export type GetAudiencePreviewLazyQueryHookResult = ReturnType<typeof useGetAudiencePreviewLazyQuery>;
export type GetAudiencePreviewQueryResult = Apollo.QueryResult<GetAudiencePreviewQuery, GetAudiencePreviewQueryVariables>;