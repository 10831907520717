import { useApolloClient } from '@apollo/client';
import {
  GetMembershipsDocument,
  GetMembershipsQuery,
  GetMembershipsQueryVariables,
  MembershipFieldsFragment,
} from '@webapp/graphql';
import { useStores } from '@webapp/state-models';
import { ObjectOption } from '@webapp/types';

export type LoadMembershipOption = ObjectOption<MembershipFieldsFragment>;

interface UseLoadMembershipsOutput {
  search(
    searchValue: string
  ): Promise<ObjectOption<MembershipFieldsFragment>[]>;
}

interface UseLoadMembershipsProps {
  locationIds?: string[];
}

export const useLoadMemberships = ({
  locationIds,
}: UseLoadMembershipsProps = {}): UseLoadMembershipsOutput => {
  const client = useApolloClient();

  const { workspace } = useStores();

  return {
    search: async (searchValue: string) => {
      const { data } = await client.query<
        GetMembershipsQuery,
        GetMembershipsQueryVariables
      >({
        query: GetMembershipsDocument,
        variables: {
          where: {
            archived: {
              _eq: false,
            },
            workspaceId: {
              _eq: workspace?.id,
            },

            ...(searchValue && {
              name: {
                _ilike: `%${searchValue}%`,
              },
            }),
          },
        },
      });

      const memberships = data?.membership ?? [];

      return memberships.map((membership) => ({
        label: membership.name,
        value: membership.id,
        object: membership,
      }));
    },
  };
};

export default useLoadMemberships;
