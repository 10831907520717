import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Box,
} from '@chakra-ui/react';
import { ServiceCategory } from '@webapp/graphql';
import { LeadFormField } from '@webapp/state-models';
import { FormInput, GenericObjectSelect } from '@webapp/ui';
import { useLoadServiceCategory } from '@webapp/webapp/hooks';
import { useMemo } from 'react';
import { useFormContext, Controller } from 'react-hook-form';

export default function ServiceCategorySelection(
  field: LeadFormField,
  register: any,
  errors: any
) {
  const { control } = useFormContext();

  return (
    <FormControl
      isInvalid={errors[field.apiFieldKey]}
      key={field.id}
      id={field.apiFieldKey}
    >
      <FormLabel mb={1}>{field.label}</FormLabel>
      <Box zIndex={99}>
        <Controller
          control={control}
          name={field.apiFieldKey}
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <GenericObjectSelect<ServiceCategory>
              isAsync
              isMulti
              onChange={(newValue, actionMeta) => {
                onChange(
                  (newValue as Array<any>).map((v: any) => v.label).join(',')
                );
              }}
              useLoadOptions={() =>
                useLoadServiceCategory({
                  workspaceId: field.workspaceId as string,
                })
              }
              placeholder="Search for a service"
            />
          )}
        />
      </Box>

      <FormErrorMessage>
        {errors[field.apiFieldKey] && errors[field.apiFieldKey].message}
      </FormErrorMessage>
    </FormControl>
  );
}
