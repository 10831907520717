import {
  Button,
  chakra,
  HStack,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';
import { get } from 'lodash';
import { AgGridReact, AgGridReactProps } from 'ag-grid-react';
import {
  ColDef,
  FirstDataRenderedEvent,
  PaginationChangedEvent,
  SelectionChangedEvent,
} from 'ag-grid-community';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FieldValues } from 'react-hook-form';
import { DeleteIcon, EditIcon, PlusSquareIcon } from '@chakra-ui/icons';
import { PromptModal } from '@webapp/webapp/ui-composites';
import RowCreateUpdateModal from './RowCreateUpdateModal';

interface AgGridProps<T extends FieldValues> extends AgGridReactProps {
  canSelect?: boolean;
  columnDefs: ColDef<T>[];
  onCellClick?: AgGridReactProps['onCellClicked'];
  onFirstDataRendered?: (params: FirstDataRenderedEvent<T>) => void;
  data: T[];
  isLoading?: boolean;
  page?: number;
  pageSize?: number;
  showActionTabs?: boolean;
  hideEmptyColumns?: boolean;
  onAddNewRow?: (values: T) => void;
  onDeleteRow?: (params: ColDef<T>['cellRenderer']) => void;
  onDeleteRows?: (params: T[]) => void;
  onUpdateRow?: (row: T) => void;
  onPageChange?: (params: { page: number; pageSize: number }) => void;
  /* Selection change handler with row data */
  onSelectionChange?: (data: T[]) => void;
  /* Selection change handler with raw event */
  onSelectionChanged?: (event: SelectionChangedEvent<T>) => void;
  rowSelection?: AgGridReactProps['rowSelection'];
  transformRowToFormValues?: (row: T) => FieldValues;
  withActions?: {
    label: string | ReactNode;
    onClick: () => void;
  }[];
}

export function AgGrid<T extends FieldValues>({
  canSelect = false,
  columnDefs: columnDefsFromProps,
  data,
  isLoading = false,
  onAddNewRow,
  onCellClick,
  onDeleteRow,
  onDeleteRows,
  onFirstDataRendered = () => undefined,
  onPageChange = () => undefined,
  onSelectionChange,
  onUpdateRow,
  page: pageFromProps = 0,
  rowSelection = 'multiple',
  transformRowToFormValues,
  showActionTabs = true,
  withActions = [],
  hideEmptyColumns = true,
  ...agGridProps
}: AgGridProps<T>) {
  const [selectedRows, selectRows] = useState<T[]>([]);

  const createModal = useDisclosure();
  const updateModal = useDisclosure();
  const deleteModal = useDisclosure();

  const gridApiRef = useRef<AgGridReact | null>(null);

  const columnDefs = useMemo(() => {
    // Add a specific checkbox column at the start of your column definitions
    const checkboxColumn: ColDef = {
      headerCheckboxSelection: true,
      checkboxSelection: true,
      colId: 'checkbox',
      width: 50,
      suppressMenu: true,
    };

    const transformedColDefs: ColDef<T>[] = columnDefsFromProps.map(
      (colDef) => {
        const customCellRenderer = (params: ColDef<T>['cellRenderer']) => {
          /* 
            This allows for nested keys in an object value 

            ex: VALUE.patient.firstName
          */
          if (params.data && colDef.field) {
            return get(params.data, colDef.field);
          }

          return undefined;
        };

        return {
          ...colDef,
          cellRenderer:
            colDef.cellRenderer || colDef.valueFormatter || colDef.rowGroup
              ? colDef.cellRenderer
              : customCellRenderer,
        };
      }
    );

    if (canSelect) {
      // Add the checkbox column definition to the start of your columns array
      const allColumnDefs: ColDef<T>[] = [
        checkboxColumn,
        ...transformedColDefs,
      ];

      return allColumnDefs;
    }

    return transformedColDefs;
  }, [columnDefsFromProps]);

  useEffect(() => {
    gridApiRef.current?.api?.sizeColumnsToFit();
  }, [columnDefs]);

  useEffect(() => {
    if (agGridProps.pagination && gridApiRef.current) {
      const pageSize = agGridProps.paginationPageSize || 10;
      gridApiRef.current.api?.paginationGoToPage(pageFromProps);
      gridApiRef.current.api?.paginationSetPageSize(pageSize);
    }
  }, [agGridProps, pageFromProps]);

  const gridOptions: Pick<
    AgGridReactProps,
    | 'getRowHeight'
    | 'onCellEditRequest'
    | 'onFirstDataRendered'
    | 'onPaginationChanged'
    | 'onSelectionChanged'
    | 'pinnedTopRowData'
  > = {
    ...agGridProps,
    onFirstDataRendered(params: FirstDataRenderedEvent<T>) {
      params.api.sizeColumnsToFit();

      // Specifically auto-size the checkbox column
      // Assuming you have a way to identify the checkbox column, e.g., by field name or column ID
      const allColumnIds = params?.columnApi
        .getAllGridColumns()
        .map((col) => col.getColId());
      const checkboxColumnId = allColumnIds.find((id) => id === 'checkbox');

      if (checkboxColumnId) {
        params.columnApi.autoSizeColumns([checkboxColumnId]);
      }
      onFirstDataRendered(params);
    },
    ...(agGridProps.pagination &&
      onPageChange && {
        onPaginationChanged(event: PaginationChangedEvent<T>) {
          const hasChangedPage = event.newPage;

          if (hasChangedPage) {
            const page = event.api.paginationGetCurrentPage();
            const pageSize = event.api.paginationGetPageSize();
            onPageChange({ page, pageSize });
          }
        },
      }),
  };

  const deleteRows = useCallback(() => {
    if (onDeleteRows && selectedRows.length) {
      onDeleteRows(selectedRows);
    }

    selectRows([]);

    deleteModal.onClose();
  }, [deleteModal, onDeleteRows, selectedRows]);

  const onSelectionChanged = useCallback(
    (e: SelectionChangedEvent<T>) => {
      const selectedNodes = gridApiRef.current?.api.getSelectedNodes() || [];
      const selectedData: T[] = selectedNodes?.map((node) => node.data) || [];

      selectRows(selectedData);

      if (onSelectionChange) {
        onSelectionChange(selectedData);
      }

      if (agGridProps.onSelectionChanged) {
        agGridProps.onSelectionChanged(e);
      }
    },
    [agGridProps, onSelectionChange]
  );

  const onRowDataUpdated = () => {
    const columnsToIgnore = ['flowsheetId', 'row'];
    const columnsToAlwaysShow = ['checkbox'];
    const columns = gridApiRef?.current?.api.getColumns();

    columns?.forEach((column) => {
      const { colId } = column;
      if (columnsToIgnore.includes(colId)) {
        return;
      }
      if (columnsToAlwaysShow.includes(colId)) {
        return;
      }

      if (!hideEmptyColumns) {
        return;
      }

      let hasData = false;
      gridApiRef?.current?.api.forEachNode((node) => {
        if (node.data[colId]) {
          hasData = true;
        }
      });

      gridApiRef?.current?.api.setColumnsVisible([colId], Boolean(hasData));
    });
  };

  return (
    <chakra.div className="ag-theme-alpine" height="100%" width="100%">
      {withActions && (
        <chakra.div py="12px">
          {withActions.map((action) => (
            <Button colorScheme="teal" onClick={action.onClick}>
              {action.label}
            </Button>
          ))}
        </chakra.div>
      )}
      {(onAddNewRow || onUpdateRow || onDeleteRow) && showActionTabs && (
        <HStack spacing="1px">
          <Tooltip label="Add new row">
            <Button
              borderBottomLeftRadius="0"
              borderBottomRightRadius="0"
              color="teal.500"
              isDisabled={!onAddNewRow}
              onClick={createModal.onToggle}
            >
              <PlusSquareIcon />
            </Button>
          </Tooltip>
          <Tooltip
            label={
              selectedRows.length !== 1
                ? 'Select a single row to unlock editing'
                : 'Edit row'
            }
          >
            <Button
              borderBottomLeftRadius="0"
              borderBottomRightRadius="0"
              color="teal.500"
              isDisabled={selectedRows.length !== 1 || !onUpdateRow}
              onClick={updateModal.onToggle}
            >
              <EditIcon />
            </Button>
          </Tooltip>
          <Tooltip
            label={selectedRows.length !== 1 ? 'Delete rows' : 'Delete Row'}
          >
            <Button
              borderBottomLeftRadius="0"
              borderBottomRightRadius="0"
              colorScheme="red"
              isDisabled={!selectedRows.length || !onDeleteRows}
              onClick={deleteModal.onToggle}
            >
              <DeleteIcon />
            </Button>
          </Tooltip>
        </HStack>
      )}
      <AgGridReact<T>
        animateRows
        columnDefs={columnDefs}
        readOnlyEdit
        ref={gridApiRef}
        rowData={data}
        rowSelection={rowSelection}
        onCellClicked={onCellClick}
        onSelectionChanged={onSelectionChanged}
        onRowDataUpdated={onRowDataUpdated}
        {...gridOptions}
      />
      {onDeleteRows && deleteModal.isOpen && selectedRows.length && (
        <PromptModal onConfirm={deleteRows} {...deleteModal} />
      )}
      {onUpdateRow && updateModal.isOpen && selectedRows[0] && (
        <RowCreateUpdateModal
          colDefs={columnDefs}
          /* 
            Some input types do not play well with row values.
            They need to be transformed to properly populate
            update forms with initial values.
          */
          defaultValues={
            transformRowToFormValues
              ? transformRowToFormValues(selectedRows[0])
              : selectedRows[0]
          }
          onSubmit={onUpdateRow}
          {...updateModal}
        />
      )}
      {onAddNewRow && createModal.isOpen && (
        <RowCreateUpdateModal
          colDefs={columnDefs}
          onSubmit={onAddNewRow}
          {...createModal}
        />
      )}
    </chakra.div>
  );
}

export default AgGrid;
