import { Instance, SnapshotOut, types, flow } from 'mobx-state-tree';
import {
  GraphqlClient,
  PhotoMediaTag,
  InsertPhotoMediaTagDocument,
  InsertPhotoMediaTagMutationResult,
  MediaTag,
  UpdatePhotoDocument,
  DeletePhotoMediaTagDocument,
  Patient,
  GetPhotoDocument,
  PatientSearchFieldsFragment,
} from '@webapp/graphql';
import toast from 'react-hot-toast';
import { getErrorMessage } from '@webapp/utils';
import { SerialisationSchema } from 'photoeditorsdk';
import { FileModel, File as MSTFile } from './file';
import { PatientModel } from './patient';
import {
  PhotoMediaTagModel,
  PhotoMediaTag as MSTPhotoMediaTag,
} from './photo-media-tag';
import { DateTime } from './date-time';

export const CropAreaModel = types.model('CropArea').props({
  width: types.number,
  height: types.number,
  x: types.number,
  y: types.number,
});
export type CroppedArea = Instance<typeof CropAreaModel>;

export const CropPointModel = types.model('CropPoint').props({
  x: types.number,
  y: types.number,
});
export type CropPoint = Instance<typeof CropPointModel>;
/**
 * A PhotoStore model.
 */

export const PhotoModel = types
  .model('Photo')
  .props({
    id: types.identifier,
    workspaceId: types.string,
    patientId: types.maybeNull(types.string),
    mediaDate: types.optional(DateTime, Date()),
    mediaType: types.maybeNull(types.string),
    mimeType: types.maybeNull(types.string),
    patient: types.maybeNull(PatientModel),
    hasThumbnail: types.maybeNull(types.boolean),
    isSharedWithPatient: types.maybeNull(types.boolean),
    rotation: types.optional(types.number, 0),
    zoom: types.optional(types.number, 1),
    cropArea: types.maybeNull(CropAreaModel),
    cropPoint: types.maybeNull(CropPointModel),
    partialMediaConsent: types.maybeNull(types.boolean),
    fullMediaConsent: types.maybeNull(types.boolean),
    serializedState: types.maybeNull(types.string),
    file: types.maybeNull(FileModel),
    photoMediaTags: types.optional(types.array(PhotoMediaTagModel), []),
  })
  .views((self) => ({
    get imglyState(): SerialisationSchema | null {
      if (self.serializedState) {
        return JSON.parse(self.serializedState);
      }
      return null;
    },
  }))
  .actions((self) => ({
    setSerializedState(state: SerialisationSchema) {
      self.serializedState = JSON.stringify(state);
    },
    setCroppedArea(croppedArea: CroppedArea) {
      self.cropArea = croppedArea;
    },
    setCroppedPoint(cropPoint: CropPoint) {
      self.cropPoint = cropPoint;
    },
    setRotation(rotation: number) {
      self.rotation = rotation;
    },
    setZoom(zoom: number) {
      self.zoom = zoom;
    },
    setFile(file: MSTFile) {
      self.file = file;
    },
    update: flow(function* (_set: any) {
      const client = GraphqlClient();
      try {
        yield client.mutate({
          mutation: UpdatePhotoDocument,
          variables: {
            id: self.id,
            _set,
          },
        });
        if (_set.mediaDate) self.mediaDate = _set.mediaDate;
        if (_set.mediaType) self.mediaType = _set.mediaType;
        if ('hasThumbnail' in _set) self.hasThumbnail = _set.hasThumbnail;
      } catch (err) {
        toast.error(getErrorMessage(err));
      }
    }),
    refresh: flow(function* () {
      const client = GraphqlClient();
      try {
        const { data } = yield client.query({
          query: GetPhotoDocument,
          variables: { id: self.id },
        });
        self.hasThumbnail = false;
        self.file = data?.photo_by_pk.file;
      } catch (err) {
        console.log(err);
      }
    }),
    updatePatient: flow(function* (patient: PatientSearchFieldsFragment) {
      const client = GraphqlClient();
      try {
        yield client.mutate({
          mutation: UpdatePhotoDocument,
          variables: {
            id: self.id,
            _set: {
              patientId: patient.id,
            },
          },
        });
        self.patientId = patient.id;
        self.patient = PatientModel.create(patient);
      } catch (err) {
        toast.error(getErrorMessage(err));
      }
    }),
    addNewMediaTag: flow(function* (tagTitle: string) {
      const client = GraphqlClient();
      try {
        const { data }: InsertPhotoMediaTagMutationResult = yield client.mutate(
          {
            mutation: InsertPhotoMediaTagDocument,
            variables: {
              photoMediaTag: {
                photoId: self.id,

                mediaTag: {
                  data: {
                    title: tagTitle.toLowerCase(),
                  },
                  on_conflict: {
                    constraint: 'mediaTag_title_workspaceId_key',
                    update_columns: ['updatedAt'],
                  },
                },
              },
            },
          }
        );
        self.photoMediaTags.push(
          data?.insert_photoMediaTag?.returning[0] as PhotoMediaTag
        );
      } catch (err) {
        toast.error(getErrorMessage(err));
      }
    }),
    removePhotoMediaTag: flow(function* (photoMediaTagId: string) {
      const client = GraphqlClient();
      try {
        yield client.mutate({
          mutation: DeletePhotoMediaTagDocument,
          variables: {
            id: photoMediaTagId,
          },
        });
        self.photoMediaTags.remove(
          self.photoMediaTags.find(
            (pmt) => pmt.id === photoMediaTagId
          ) as MSTPhotoMediaTag
        );
      } catch (err) {
        toast.error(getErrorMessage(err));
      }
    }),
    addExistingMediaTag: flow(function* (mediaTag: MediaTag) {
      const client = GraphqlClient();
      try {
        const { data }: InsertPhotoMediaTagMutationResult = yield client.mutate(
          {
            mutation: InsertPhotoMediaTagDocument,
            variables: {
              photoMediaTag: {
                photoId: self.id,
                mediaTagId: mediaTag.id,
              },
            },
          }
        );
        self.photoMediaTags.push(
          data?.insert_photoMediaTag?.returning[0] as PhotoMediaTag
        );
      } catch (err) {
        toast.error(getErrorMessage(err));
      }
    }),
  }));
/**
 * The Photo instance.
 */
export type Photo = Instance<typeof PhotoModel>;

/**
 * The data of a Photo.
 */
export type PhotoSnapshot = SnapshotOut<typeof PhotoModel>;
