import * as Types from '../../generated/graphql';

import { gql } from '@apollo/client';
import { LeadFormFieldFieldsFragmentDoc } from '../fragments/leadFormFieldFields.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type UpdateLeadFormFieldMutationVariables = Types.Exact<{
  _set?: Types.Maybe<Types.LeadFormField_Set_Input>;
  id: Types.Scalars['uuid'];
}>;


export type UpdateLeadFormFieldMutation = { __typename?: 'mutation_root', update_leadFormField?: { __typename?: 'leadFormField_mutation_response', returning: Array<{ __typename?: 'leadFormField', id: any, validationProps?: any | null | undefined, apiFieldKey: string, label: string, order: any, type: string, workspaceId: any }> } | null | undefined };


export const UpdateLeadFormFieldDocument = gql`
    mutation updateLeadFormField($_set: leadFormField_set_input, $id: uuid!) {
  update_leadFormField(_set: $_set, where: {id: {_eq: $id}}) {
    returning {
      ...LeadFormFieldFields
    }
  }
}
    ${LeadFormFieldFieldsFragmentDoc}`;
export type UpdateLeadFormFieldMutationFn = Apollo.MutationFunction<UpdateLeadFormFieldMutation, UpdateLeadFormFieldMutationVariables>;

/**
 * __useUpdateLeadFormFieldMutation__
 *
 * To run a mutation, you first call `useUpdateLeadFormFieldMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLeadFormFieldMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLeadFormFieldMutation, { data, loading, error }] = useUpdateLeadFormFieldMutation({
 *   variables: {
 *      _set: // value for '_set'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateLeadFormFieldMutation(baseOptions?: Apollo.MutationHookOptions<UpdateLeadFormFieldMutation, UpdateLeadFormFieldMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateLeadFormFieldMutation, UpdateLeadFormFieldMutationVariables>(UpdateLeadFormFieldDocument, options);
      }
export type UpdateLeadFormFieldMutationHookResult = ReturnType<typeof useUpdateLeadFormFieldMutation>;
export type UpdateLeadFormFieldMutationResult = Apollo.MutationResult<UpdateLeadFormFieldMutation>;
export type UpdateLeadFormFieldMutationOptions = Apollo.BaseMutationOptions<UpdateLeadFormFieldMutation, UpdateLeadFormFieldMutationVariables>;