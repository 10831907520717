import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type UpdateFlowsheetParameterMutationVariables = Types.Exact<{
  apiKey: Types.Scalars['String'];
  id: Types.Scalars['uuid'];
  isSystemParameter?: Types.Maybe<Types.Scalars['Boolean']>;
  title: Types.Scalars['String'];
  type: Types.Scalars['String'];
  unit: Types.Scalars['String'];
}>;


export type UpdateFlowsheetParameterMutation = { __typename?: 'mutation_root', update_flowsheetParameter_by_pk?: { __typename?: 'flowsheetParameter', apiKey: string, id: any, isSystemParameter: boolean, title: string, type: string, unit: string } | null | undefined };


export const UpdateFlowsheetParameterDocument = gql`
    mutation UpdateFlowsheetParameter($apiKey: String!, $id: uuid!, $isSystemParameter: Boolean = false, $title: String!, $type: String!, $unit: String!) {
  update_flowsheetParameter_by_pk(
    pk_columns: {id: $id}
    _set: {apiKey: $apiKey, isSystemParameter: $isSystemParameter, title: $title, type: $type, unit: $unit}
  ) {
    apiKey
    id
    isSystemParameter
    title
    type
    unit
  }
}
    `;
export type UpdateFlowsheetParameterMutationFn = Apollo.MutationFunction<UpdateFlowsheetParameterMutation, UpdateFlowsheetParameterMutationVariables>;

/**
 * __useUpdateFlowsheetParameterMutation__
 *
 * To run a mutation, you first call `useUpdateFlowsheetParameterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFlowsheetParameterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFlowsheetParameterMutation, { data, loading, error }] = useUpdateFlowsheetParameterMutation({
 *   variables: {
 *      apiKey: // value for 'apiKey'
 *      id: // value for 'id'
 *      isSystemParameter: // value for 'isSystemParameter'
 *      title: // value for 'title'
 *      type: // value for 'type'
 *      unit: // value for 'unit'
 *   },
 * });
 */
export function useUpdateFlowsheetParameterMutation(baseOptions?: Apollo.MutationHookOptions<UpdateFlowsheetParameterMutation, UpdateFlowsheetParameterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateFlowsheetParameterMutation, UpdateFlowsheetParameterMutationVariables>(UpdateFlowsheetParameterDocument, options);
      }
export type UpdateFlowsheetParameterMutationHookResult = ReturnType<typeof useUpdateFlowsheetParameterMutation>;
export type UpdateFlowsheetParameterMutationResult = Apollo.MutationResult<UpdateFlowsheetParameterMutation>;
export type UpdateFlowsheetParameterMutationOptions = Apollo.BaseMutationOptions<UpdateFlowsheetParameterMutation, UpdateFlowsheetParameterMutationVariables>;