import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type PayWithExistingCardMutationVariables = Types.Exact<{
  paymentMethodId: Types.Scalars['uuid'];
  amount: Types.Scalars['Int'];
  payrixType?: Types.Maybe<Types.PayrixPaymentType>;
  type: Types.PaymentType;
}>;


export type PayWithExistingCardMutation = { __typename?: 'mutation_root', payWithExistingCard?: { __typename?: 'IdResponse', id: any } | null | undefined };


export const PayWithExistingCardDocument = gql`
    mutation PayWithExistingCard($paymentMethodId: uuid!, $amount: Int!, $payrixType: PayrixPaymentType, $type: PaymentType!) {
  payWithExistingCard(
    paymentMethodId: $paymentMethodId
    amount: $amount
    payrixType: $payrixType
    type: $type
  ) {
    id
  }
}
    `;
export type PayWithExistingCardMutationFn = Apollo.MutationFunction<PayWithExistingCardMutation, PayWithExistingCardMutationVariables>;

/**
 * __usePayWithExistingCardMutation__
 *
 * To run a mutation, you first call `usePayWithExistingCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePayWithExistingCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [payWithExistingCardMutation, { data, loading, error }] = usePayWithExistingCardMutation({
 *   variables: {
 *      paymentMethodId: // value for 'paymentMethodId'
 *      amount: // value for 'amount'
 *      payrixType: // value for 'payrixType'
 *      type: // value for 'type'
 *   },
 * });
 */
export function usePayWithExistingCardMutation(baseOptions?: Apollo.MutationHookOptions<PayWithExistingCardMutation, PayWithExistingCardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PayWithExistingCardMutation, PayWithExistingCardMutationVariables>(PayWithExistingCardDocument, options);
      }
export type PayWithExistingCardMutationHookResult = ReturnType<typeof usePayWithExistingCardMutation>;
export type PayWithExistingCardMutationResult = Apollo.MutationResult<PayWithExistingCardMutation>;
export type PayWithExistingCardMutationOptions = Apollo.BaseMutationOptions<PayWithExistingCardMutation, PayWithExistingCardMutationVariables>;