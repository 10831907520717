import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type UpdateCustomFormRowsMutationVariables = Types.Exact<{
  where: Types.CustomFormRow_Bool_Exp;
  objects: Array<Types.CustomFormRow_Insert_Input> | Types.CustomFormRow_Insert_Input;
}>;


export type UpdateCustomFormRowsMutation = { __typename?: 'mutation_root', delete_customFormRow?: { __typename?: 'customFormRow_mutation_response', affected_rows: number } | null | undefined, insert_customFormRow?: { __typename?: 'customFormRow_mutation_response', affected_rows: number } | null | undefined };


export const UpdateCustomFormRowsDocument = gql`
    mutation updateCustomFormRows($where: customFormRow_bool_exp!, $objects: [customFormRow_insert_input!]!) {
  delete_customFormRow(where: $where) {
    affected_rows
  }
  insert_customFormRow(objects: $objects) {
    affected_rows
  }
}
    `;
export type UpdateCustomFormRowsMutationFn = Apollo.MutationFunction<UpdateCustomFormRowsMutation, UpdateCustomFormRowsMutationVariables>;

/**
 * __useUpdateCustomFormRowsMutation__
 *
 * To run a mutation, you first call `useUpdateCustomFormRowsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomFormRowsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomFormRowsMutation, { data, loading, error }] = useUpdateCustomFormRowsMutation({
 *   variables: {
 *      where: // value for 'where'
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useUpdateCustomFormRowsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCustomFormRowsMutation, UpdateCustomFormRowsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCustomFormRowsMutation, UpdateCustomFormRowsMutationVariables>(UpdateCustomFormRowsDocument, options);
      }
export type UpdateCustomFormRowsMutationHookResult = ReturnType<typeof useUpdateCustomFormRowsMutation>;
export type UpdateCustomFormRowsMutationResult = Apollo.MutationResult<UpdateCustomFormRowsMutation>;
export type UpdateCustomFormRowsMutationOptions = Apollo.BaseMutationOptions<UpdateCustomFormRowsMutation, UpdateCustomFormRowsMutationVariables>;