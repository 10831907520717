import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetInvoiceNumberQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetInvoiceNumberQuery = { __typename?: 'query_root', getInvoiceNumber?: { __typename?: 'GetInvoiceNumberOutput', invoiceNumber: string } | null | undefined };


export const GetInvoiceNumberDocument = gql`
    query GetInvoiceNumber {
  getInvoiceNumber {
    invoiceNumber
  }
}
    `;

/**
 * __useGetInvoiceNumberQuery__
 *
 * To run a query within a React component, call `useGetInvoiceNumberQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvoiceNumberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvoiceNumberQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetInvoiceNumberQuery(baseOptions?: Apollo.QueryHookOptions<GetInvoiceNumberQuery, GetInvoiceNumberQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInvoiceNumberQuery, GetInvoiceNumberQueryVariables>(GetInvoiceNumberDocument, options);
      }
export function useGetInvoiceNumberLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInvoiceNumberQuery, GetInvoiceNumberQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInvoiceNumberQuery, GetInvoiceNumberQueryVariables>(GetInvoiceNumberDocument, options);
        }
export type GetInvoiceNumberQueryHookResult = ReturnType<typeof useGetInvoiceNumberQuery>;
export type GetInvoiceNumberLazyQueryHookResult = ReturnType<typeof useGetInvoiceNumberLazyQuery>;
export type GetInvoiceNumberQueryResult = Apollo.QueryResult<GetInvoiceNumberQuery, GetInvoiceNumberQueryVariables>;