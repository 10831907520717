import {
  Input,
  InputGroup,
  InputLeftElement,
  StyleProps,
} from '@chakra-ui/react';
import { debounce } from 'lodash';
import { HiSearch } from 'react-icons/hi';
import { useLocation, useNavigate } from 'react-router-dom';
import './query-param-input.module.scss';

export interface QueryParamInputProps {
  id?: string;
  inputVariant?: 'filled' | 'flushed' | 'outline' | 'unstyled';
  maxWidth?: StyleProps['maxWidth'];
  minWidth?: string;
  paramKey?: string;
  placeholder?: string;
  hasIcon?: boolean;
  width?: string;
}

export function QueryParamInput({
  id,
  hasIcon = true,
  inputVariant = 'filled',
  minWidth = '200px',
  maxWidth,
  paramKey = 'search',
  placeholder = 'Search...',
  width = '100%',
}: QueryParamInputProps) {
  const navigate = useNavigate();
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);

  const handleChange = debounce((e) => {
    if (e.target.value?.length) {
      searchParams.set(paramKey, e.target.value);
    } else {
      searchParams.delete(paramKey);
    }

    navigate({
      search: searchParams.toString(),
    });
  }, 500);

  return (
    <InputGroup
      className="query-param-input"
      data-testid={`query-param-input-${id || 'test'}`}
      id={id}
      minWidth={minWidth}
      maxWidth={maxWidth}
      width={width}
    >
      {hasIcon && (
        <InputLeftElement
          pointerEvents="none"
          children={<HiSearch color="rgba(28,28,28, .38)" />}
        />
      )}
      <Input
        boxSizing="border-box"
        fontSize="14px"
        height="40px"
        onChange={handleChange}
        type="text"
        placeholder={placeholder}
        variant={inputVariant}
      />
    </InputGroup>
  );
}

export default QueryParamInput;
