import {
  InputGroup,
  InputRightAddon,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput as ChakraNumberInput,
  NumberInputField,
  NumberInputStepper,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import DEFAULT_CONTROL_STYLE from './styles/DefaultControlStyle';
import DEFAULT_INPUT_CONTAINER_STYLE from './styles/DefaultInputContainerStyle';
import { NumberInputProps } from './types';

export default function NumberInput({
  hasIncrement = true,
  incrementStep = 1,
  max,
  min,
  name,
  onChange,
  placeholder,
  precision = 0,
  styles = {},
  unit,
  value = 0,
  ...props
}: Omit<NumberInputProps, 'type'>) {
  const inputStyles = { ...DEFAULT_CONTROL_STYLE, ...styles.control };

  const containerStyles = {
    ...DEFAULT_INPUT_CONTAINER_STYLE,
    ...styles.inputContainer,
  };

  const [inputValue, setInputValue] = useState<string>(
    value ? value.toString() : '0'
  );

  useEffect(() => {
    const floatValue = parseFloat(inputValue);

    const isEmpty = value === 0 && ['', '0'].includes(inputValue);
    const areEqual = value === floatValue;

    const valuesAreNotEqual = !areEqual && !isEmpty;

    if (!Number.isNaN(value) && valuesAreNotEqual) {
      setInputValue(value.toString());
    }
  }, [inputValue, value]);

  return (
    <InputGroup {...containerStyles}>
      <ChakraNumberInput
        format={(val: string) => val}
        id={name}
        max={max}
        min={min}
        name={name}
        onBlur={() => {
          if (!inputValue.length) {
            setInputValue('0');
          }
        }}
        onChange={(stringValue, numberValue) => {
          setInputValue(stringValue);

          if (!Number.isNaN(numberValue)) {
            onChange(stringValue, numberValue);
          } else {
            onChange('0', 0);
          }
        }}
        precision={precision}
        step={incrementStep}
        value={inputValue}
        width="100%"
        {...props}
      >
        <NumberInputField
          placeholder={placeholder}
          {...inputStyles}
          {...(unit && { borderRight: '0' })}
        />
        {hasIncrement && (
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        )}
      </ChakraNumberInput>
      {unit && <InputRightAddon children={unit} />}
    </InputGroup>
  );
}
