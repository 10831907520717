import React, { useEffect, useState, useRef } from 'react';
import {
  Box,
  Flex,
  Image,
  IconButton,
  useBreakpointValue,
  Menu,
  MenuButton,
  MenuList,
  HStack,
  MenuDivider,
  MenuOptionGroup,
  MenuItemOption,
  SimpleGrid,
} from '@chakra-ui/react';
import { FaCamera } from 'react-icons/fa';
import { HamburgerIcon, ArrowBackIcon } from '@chakra-ui/icons';
import cv from '@techstark/opencv-js';
import SelectOverlayModal from './SelectOverlayModal';
import PhotosTakenModal from './PhotosTakenModal';
import SettingsPopover from './SettingsPopover';

/* eslint-disable-next-line */
export interface TakePhotoV2Props {
  patientId?: string | null | undefined;
  overlayImageUrl?: string | null | undefined;
  beginWithTemplateSelection?: boolean;
  onImageCapture: (image: string) => void;
  goBack: () => void;
}

export function TakePhotoV2({
  onImageCapture,
  goBack,
  patientId,
  overlayImageUrl,
  beginWithTemplateSelection = false,
}: TakePhotoV2Props) {
  const [cameraDevices, setCameraDevices] = useState([]);
  const [selectedCamera, setSelectedCamera] = useState('');
  const [selectedResolution, setSelectedResolution] = useState('3840x2160');
  const videoRef = useRef(null);
  const canvasRef = useRef(null);

  const [imageCapture, setImageCapture] = useState(null);
  const [localStream, setLocalStream] = useState(null);
  const [opacity, setOpacity] = useState<number>(20);
  const [capturedPhotos, setCapturedPhotos] = useState<string[]>([]);
  const [takingPhoto, setTakingPhoto] = useState(false);
  const [overlayImage, setOverlayImage] = useState<string | null>(
    overlayImageUrl
  );
  const [showGrid, setShowGrid] = useState(false);
  const [gridSize, setGridSize] = useState(3);
  useEffect(() => {
    requestCameraPermission();
    loadCameraDevices();
  }, []);

  const handleTouchMove = (event: TouchEvent) => {
    if (event.touches.length === 1) {
      const touch = event.touches[0];
      const screenWidth = window.innerWidth;
      const touchPosition = touch.clientX;
      const newOpacity = (touchPosition / screenWidth) * 100;
      setOpacity(Math.min(Math.max(newOpacity, 0), 100));
    }
  };

  useEffect(() => {
    const overlayImageElement = document.getElementById('overlayImage');
    if (overlayImageElement) {
      overlayImageElement.addEventListener('touchmove', handleTouchMove);
    }
    return () => {
      if (overlayImageElement) {
        overlayImageElement.removeEventListener('touchmove', handleTouchMove);
      }
    };
  }, []);
  const requestCameraPermission = async () => {
    try {
      const constraints = { video: true, audio: false };
      const stream = await navigator.mediaDevices.getUserMedia(constraints);
      const tracks = stream.getTracks();
      tracks.forEach((track) => track.stop());
    } catch (error) {
      console.error('Camera access error:', error);
    }
  };

  const loadCameraDevices = async () => {
    try {
      const constraints = {
        video: {
          facingMode: { ideal: 'environment' },
        },
        audio: false,
      };
      const stream = await navigator.mediaDevices.getUserMedia(constraints);
      const devices = await navigator.mediaDevices.enumerateDevices();

      const videoDevices = devices.filter(
        (device) => device.kind === 'videoinput'
      );
      setCameraDevices(videoDevices);
      const tracks = stream.getTracks();
      tracks.forEach((track) => track.stop());
    } catch (error) {
      console.error('Error loading camera devices:', error);
    }
  };

  const handleStartCamera = () => {
    const options = { facingMode: 'environment' };
    if (selectedCamera) {
      options.deviceId = { exact: selectedCamera };
    }
    if (selectedResolution) {
      const [width, height] = selectedResolution.split('x').map(Number);
      options.width = { ideal: width };
      options.height = { ideal: height };
      options.frameRate = { ideal: 30 };
    }

    play(options);
  };

  const handleTakePhoto = async () => {
    let src;
    setTakingPhoto(true);
    if (imageCapture) {
      try {
        const blob = await imageCapture.takePhoto();
        src = URL.createObjectURL(blob);
        onImageCapture(src);
        setCapturedPhotos([...capturedPhotos, src]);
      } catch (e) {
        src = captureFrame();
        onImageCapture(src);
        setCapturedPhotos([...capturedPhotos, src]);
      }
    } else {
      src = captureFrame();
      onImageCapture(src);
      setCapturedPhotos([...capturedPhotos, src]);
    }
    setTakingPhoto(false);

    // Play sound when photo is captured
    const audio = new Audio('/nice-camera-click-106269.mp3');
    audio
      .play()
      .catch((error) => console.error('Audio playback error:', error));
  };

  const play = (options) => {
    stop();
    videoRef.current.style.display = 'block';
    const constraints = {
      video: options,
      audio: false,
    };

    navigator.mediaDevices
      .getUserMedia(constraints)
      .then((stream) => {
        setLocalStream(stream);
        videoRef.current.srcObject = stream;
        try {
          const track = stream.getVideoTracks()[0];
          setImageCapture(new ImageCapture(track));
        } catch (err) {
          console.error('Error setting image capture', err);
        }
      })
      .catch((err) => {
        console.error('getUserMediaError', err, err.stack);
      });
  };

  useEffect(() => {
    if (imageCapture) {
      imageCapture.getPhotoCapabilities().then((capabilities) => {
        console.log('capabilities', capabilities);
      });
      imageCapture.getPhotoSettings().then((settings) => {
        console.log('settings', settings);
      });
    }
  }, [imageCapture]);

  const stop = () => {
    const video = videoRef.current;

    const stream = video?.srcObject || localStream;
    if (stream) {
      const tracks = stream.getTracks();
      tracks.forEach((track) => {
        track.stop();
      });
      if (video && video.srcObject) {
        video.srcObject = null;
      }
      setLocalStream(null);
    }
  };

  const captureFrame = () => {
    const video = videoRef.current;
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    context.drawImage(video, 0, 0, canvas.width, canvas.height);
    // REmove the opencv processing in case its slow
    // const src = cv.imread(canvas);
    // const dst = new cv.Mat();

    // // Apply noise reduction (e.g., Gaussian Blur)
    // cv.GaussianBlur(src, dst, new cv.Size(5, 5), 0, 0, cv.BORDER_DEFAULT);

    // // Convert the processed image back to the canvas
    // cv.imshow(canvas, dst);

    // // Clean up OpenCV objects to prevent memory leaks
    // src.delete();
    // dst.delete();

    return canvas.toDataURL('image/jpeg');
  };

  useEffect(() => {
    if (cameraDevices.length > 0) {
      const backCamera = cameraDevices.find((device) =>
        device.label.toLowerCase().includes('back')
      );
      setSelectedCamera(
        backCamera ? backCamera.deviceId : cameraDevices[0].deviceId
      );
    }
  }, [cameraDevices]);

  useEffect(() => {
    handleStartCamera();

    return () => {
      stop();
    };
  }, [selectedCamera, selectedResolution]);

  return (
    <Box position="relative" height="100vh">
      <Flex
        position="absolute"
        top="0"
        left="0"
        right="0"
        justify="space-between"
        p={4}
        zIndex="10"
        bgGradient="linear(to-b, black, transparent)"
      >
        <HStack>
          <IconButton
            icon={<ArrowBackIcon color="white" />}
            variant="outline"
            onClick={goBack}
          />
          <SelectOverlayModal
            patientId={patientId}
            onSelectTemplate={(photo) => setOverlayImage(photo.file.url)}
            openByDefault={beginWithTemplateSelection}
          />
        </HStack>
        <HStack>
          <PhotosTakenModal photos={capturedPhotos} />
          <SettingsPopover
            value={opacity}
            onChange={setOpacity}
            gridSize={gridSize}
            setGridSize={setGridSize}
            showGrid={showGrid}
            setShowGrid={setShowGrid}
          />
          <Menu>
            <MenuButton
              as={IconButton}
              aria-label="Options"
              icon={<HamburgerIcon color="white" />}
              variant="outline"
            />
            <MenuList>
              <MenuOptionGroup title="Camera" value={selectedCamera}>
                {cameraDevices.map((device) => (
                  <MenuItemOption
                    onClick={() => setSelectedCamera(device.deviceId)}
                    key={device.deviceId}
                    value={device.deviceId}
                  >
                    {device.label}
                  </MenuItemOption>
                ))}
              </MenuOptionGroup>
              <MenuDivider />
              <MenuOptionGroup title="Resolution" value={selectedResolution}>
                <MenuItemOption
                  value="640x480"
                  onClick={() => setSelectedResolution('640x480')}
                >
                  640x480
                </MenuItemOption>
                <MenuItemOption
                  value="1280x720"
                  onClick={() => setSelectedResolution('1280x720')}
                >
                  1280x720
                </MenuItemOption>
                <MenuItemOption
                  value="1920x1080"
                  onClick={() => setSelectedResolution('1920x1080')}
                >
                  1920x1080
                </MenuItemOption>
                <MenuItemOption
                  value="3840x2160"
                  onClick={() => setSelectedResolution('3840x2160')}
                >
                  3840x2160
                </MenuItemOption>
              </MenuOptionGroup>
            </MenuList>
          </Menu>
        </HStack>
      </Flex>
      <IconButton
        isLoading={takingPhoto}
        icon={<FaCamera size={24} />}
        position="absolute"
        bottom="15vh"
        left="50%"
        transform="translateX(-50%)"
        width={'64px'}
        height={'64px'}
        rounded={'full'}
        shadow={'lg'}
        bg={'white'}
        onClick={handleTakePhoto}
        zIndex="10"
      />
      <video
        ref={videoRef}
        muted
        autoPlay
        playsInline
        webkit-playsinline="true"
        style={{ width: '100%', height: '100%' }}
      ></video>
      <canvas
        id="hiddenCanvas"
        ref={canvasRef}
        style={{ display: 'none' }}
      ></canvas>

      <Image
        id="overlayImage"
        position={'absolute'}
        objectFit={'contain'}
        src={overlayImage}
        opacity={opacity / 100}
        top="50%"
        left="50%"
        transform="translate(-50%, -50%)"
        maxW="100%"
        maxH="95%"
        height={'100%'}
      />
      {showGrid && (
        <SimpleGrid
          columns={gridSize}
          rows={gridSize}
          spacing={0}
          position="absolute"
          top="0"
          left="0"
          right="0"
          bottom="0"
          pointerEvents="none"
          zIndex="5"
        >
          {Array.from({ length: gridSize * gridSize }).map((_, index) => (
            <Box
              key={`grid-item-${index}`}
              borderLeft={index % gridSize === 0 ? 'none' : '2px solid white'}
              borderTop={index < gridSize ? 'none' : '2px solid white'}
              height="100%"
              width="100%"
            />
          ))}
        </SimpleGrid>
      )}
    </Box>
  );
}

export default TakePhotoV2;
