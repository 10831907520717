import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type UpdateProviderLocationsMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
  objects: Array<Types.LocationProvider_Insert_Input> | Types.LocationProvider_Insert_Input;
}>;


export type UpdateProviderLocationsMutation = { __typename?: 'mutation_root', delete_locationProvider?: { __typename?: 'locationProvider_mutation_response', affected_rows: number } | null | undefined, insert_locationProvider?: { __typename?: 'locationProvider_mutation_response', affected_rows: number } | null | undefined };


export const UpdateProviderLocationsDocument = gql`
    mutation updateProviderLocations($id: uuid!, $objects: [locationProvider_insert_input!]!) {
  delete_locationProvider(where: {providerId: {_eq: $id}}) {
    affected_rows
  }
  insert_locationProvider(objects: $objects) {
    affected_rows
  }
}
    `;
export type UpdateProviderLocationsMutationFn = Apollo.MutationFunction<UpdateProviderLocationsMutation, UpdateProviderLocationsMutationVariables>;

/**
 * __useUpdateProviderLocationsMutation__
 *
 * To run a mutation, you first call `useUpdateProviderLocationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProviderLocationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProviderLocationsMutation, { data, loading, error }] = useUpdateProviderLocationsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useUpdateProviderLocationsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProviderLocationsMutation, UpdateProviderLocationsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProviderLocationsMutation, UpdateProviderLocationsMutationVariables>(UpdateProviderLocationsDocument, options);
      }
export type UpdateProviderLocationsMutationHookResult = ReturnType<typeof useUpdateProviderLocationsMutation>;
export type UpdateProviderLocationsMutationResult = Apollo.MutationResult<UpdateProviderLocationsMutation>;
export type UpdateProviderLocationsMutationOptions = Apollo.BaseMutationOptions<UpdateProviderLocationsMutation, UpdateProviderLocationsMutationVariables>;