import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetPrescriptionDetailsAggregateQueryVariables = Types.Exact<{
  where?: Types.Maybe<Types.PrescriptionDetail_Bool_Exp>;
}>;


export type GetPrescriptionDetailsAggregateQuery = { __typename?: 'query_root', prescriptionDetail_aggregate: { __typename?: 'prescriptionDetail_aggregate', aggregate?: { __typename?: 'prescriptionDetail_aggregate_fields', count: number } | null | undefined } };


export const GetPrescriptionDetailsAggregateDocument = gql`
    query GetPrescriptionDetailsAggregate($where: prescriptionDetail_bool_exp) {
  prescriptionDetail_aggregate(where: $where) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetPrescriptionDetailsAggregateQuery__
 *
 * To run a query within a React component, call `useGetPrescriptionDetailsAggregateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPrescriptionDetailsAggregateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPrescriptionDetailsAggregateQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetPrescriptionDetailsAggregateQuery(baseOptions?: Apollo.QueryHookOptions<GetPrescriptionDetailsAggregateQuery, GetPrescriptionDetailsAggregateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPrescriptionDetailsAggregateQuery, GetPrescriptionDetailsAggregateQueryVariables>(GetPrescriptionDetailsAggregateDocument, options);
      }
export function useGetPrescriptionDetailsAggregateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPrescriptionDetailsAggregateQuery, GetPrescriptionDetailsAggregateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPrescriptionDetailsAggregateQuery, GetPrescriptionDetailsAggregateQueryVariables>(GetPrescriptionDetailsAggregateDocument, options);
        }
export type GetPrescriptionDetailsAggregateQueryHookResult = ReturnType<typeof useGetPrescriptionDetailsAggregateQuery>;
export type GetPrescriptionDetailsAggregateLazyQueryHookResult = ReturnType<typeof useGetPrescriptionDetailsAggregateLazyQuery>;
export type GetPrescriptionDetailsAggregateQueryResult = Apollo.QueryResult<GetPrescriptionDetailsAggregateQuery, GetPrescriptionDetailsAggregateQueryVariables>;