import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type PatientWalletAggregateQueryVariables = Types.Exact<{
  where?: Types.Maybe<Types.PatientWallet_Bool_Exp>;
}>;


export type PatientWalletAggregateQuery = { __typename?: 'query_root', patientWallet_aggregate: { __typename?: 'patientWallet_aggregate', aggregate?: { __typename?: 'patientWallet_aggregate_fields', count: number } | null | undefined } };


export const PatientWalletAggregateDocument = gql`
    query patientWalletAggregate($where: patientWallet_bool_exp) {
  patientWallet_aggregate(where: $where) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __usePatientWalletAggregateQuery__
 *
 * To run a query within a React component, call `usePatientWalletAggregateQuery` and pass it any options that fit your needs.
 * When your component renders, `usePatientWalletAggregateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePatientWalletAggregateQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function usePatientWalletAggregateQuery(baseOptions?: Apollo.QueryHookOptions<PatientWalletAggregateQuery, PatientWalletAggregateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PatientWalletAggregateQuery, PatientWalletAggregateQueryVariables>(PatientWalletAggregateDocument, options);
      }
export function usePatientWalletAggregateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PatientWalletAggregateQuery, PatientWalletAggregateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PatientWalletAggregateQuery, PatientWalletAggregateQueryVariables>(PatientWalletAggregateDocument, options);
        }
export type PatientWalletAggregateQueryHookResult = ReturnType<typeof usePatientWalletAggregateQuery>;
export type PatientWalletAggregateLazyQueryHookResult = ReturnType<typeof usePatientWalletAggregateLazyQuery>;
export type PatientWalletAggregateQueryResult = Apollo.QueryResult<PatientWalletAggregateQuery, PatientWalletAggregateQueryVariables>;