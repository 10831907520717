import { useApolloClient } from '@apollo/client';
import {
  GetCustomFormsDocument,
  GetCustomFormsQuery,
  GetCustomFormsQueryVariables,
} from '@webapp/graphql';
import { useStores } from '@webapp/state-models';
import { SelectOption } from '@webapp/types';

interface UseLoadCustomFormsOutput {
  search: (searchValue: string) => Promise<SelectOption[]>;
}

export function useLoadCustomForms(): UseLoadCustomFormsOutput {
  const client = useApolloClient();

  const { workspace } = useStores();

  return {
    search: async (searchValue: string) => {
      const { data } = await client.query<
        GetCustomFormsQuery,
        GetCustomFormsQueryVariables
      >({
        query: GetCustomFormsDocument,
        variables: {
          where: {
            ...(searchValue && {
              title: {
                _ilike: `%${searchValue}%`,
              },
            }),
            workspaceId: {
              _eq: workspace?.id,
            },
          },
        },
      });

      const customForms = data?.customForm ?? [];

      return customForms.map(({ id, title }) => ({
        label: title,
        value: id,
      }));
    },
  };
}

export default useLoadCustomForms;
