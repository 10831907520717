import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { PatientSystemNoticeFieldsFragmentDoc } from '../../fragments/patientSystemNoticeFields.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetPatientSystemNoticesQueryVariables = Types.Exact<{
  where: Types.PatientSystemNotice_Bool_Exp;
  limit?: Types.Maybe<Types.Scalars['Int']>;
  offset?: Types.Maybe<Types.Scalars['Int']>;
  orderBy?: Types.Maybe<Array<Types.PatientSystemNotice_Order_By> | Types.PatientSystemNotice_Order_By>;
}>;


export type GetPatientSystemNoticesQuery = { __typename?: 'query_root', patientSystemNotice: Array<{ __typename?: 'patientSystemNotice', id: any, description: string, category: string, subcategory: string, seen: boolean, createdAt: any }> };


export const GetPatientSystemNoticesDocument = gql`
    query GetPatientSystemNotices($where: patientSystemNotice_bool_exp!, $limit: Int, $offset: Int, $orderBy: [patientSystemNotice_order_by!]) {
  patientSystemNotice(
    where: $where
    limit: $limit
    offset: $offset
    order_by: $orderBy
  ) {
    ...PatientSystemNoticeFields
  }
}
    ${PatientSystemNoticeFieldsFragmentDoc}`;

/**
 * __useGetPatientSystemNoticesQuery__
 *
 * To run a query within a React component, call `useGetPatientSystemNoticesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPatientSystemNoticesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPatientSystemNoticesQuery({
 *   variables: {
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetPatientSystemNoticesQuery(baseOptions: Apollo.QueryHookOptions<GetPatientSystemNoticesQuery, GetPatientSystemNoticesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPatientSystemNoticesQuery, GetPatientSystemNoticesQueryVariables>(GetPatientSystemNoticesDocument, options);
      }
export function useGetPatientSystemNoticesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPatientSystemNoticesQuery, GetPatientSystemNoticesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPatientSystemNoticesQuery, GetPatientSystemNoticesQueryVariables>(GetPatientSystemNoticesDocument, options);
        }
export type GetPatientSystemNoticesQueryHookResult = ReturnType<typeof useGetPatientSystemNoticesQuery>;
export type GetPatientSystemNoticesLazyQueryHookResult = ReturnType<typeof useGetPatientSystemNoticesLazyQuery>;
export type GetPatientSystemNoticesQueryResult = Apollo.QueryResult<GetPatientSystemNoticesQuery, GetPatientSystemNoticesQueryVariables>;