import { DefaultNullValidation } from '@webapp/joi-validation';
import Joi from 'joi';

const PatientAddressValidation = Joi.object({
  addressLine1: Joi.string().required(),
  addressLine2: DefaultNullValidation,
  city: Joi.string().required(),
  isBilling: Joi.boolean().required(),
  isDefault: Joi.boolean().required(),
  state: Joi.string().required(),
  zipCode: Joi.string().required(),
});

export default PatientAddressValidation;
