import {
  Stack,
  CheckboxGroup,
  Checkbox,
  FormLabel,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Box,
  Button,
  Thead,
  Tr,
  Skeleton,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Select,
} from '@chakra-ui/react';
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
} from 'recharts';
import { FlowsheetData } from '@webapp/hooks';
import { useMemo, useState } from 'react';
import { configuredDayjs as dayjs } from '@webapp/util-time';
import { AgGrid } from '@webapp/ui';

export interface SelectExistingFlowsheetDataProps {
  flowsheet: FlowsheetData;
  editorReference: any;
  onClose: () => void;
  insertRow: (row: any) => void;
  updateData: (row: any) => void;
  deleteRows: (row: any) => void;
  insertRowData: (row: any) => void;
  isLoading: boolean;
  insertRowLoading: boolean;
}

type FlowsheetParameter = {
  id: string;
  parameter: {
    title: string;
    unit: string;
    apiKey: string;
    type: string;
  };
};

function mapDataToParameterTitleValue(
  data: any[] = []
): { [key: string]: string }[] {
  return data
    ?.map((item) => {
      const entries = item.row.data.map((datum) => ({
        [datum.parameter.title]: datum.value,
        recordedAt: dayjs(item.recordedAt).format('LL'),
      }));
      return Object.assign({}, ...entries);
    })
    .sort((a, b) => dayjs(a.recordedAt).unix() - dayjs(b.recordedAt).unix());
}

function generateHtmlTable(data: any[], keys: string[]): string {
  const headers = keys.map((key) => `<th>${key}</th>`).join('');
  const rows = data
    .sort((a, b) => dayjs(b.recordedAt).unix() - dayjs(a.recordedAt).unix())
    .map((row) => {
      const cells = keys.map((key) => `<td>${row[key]}</td>`).join('');
      return `<tr><td>${row.recordedAt}</td>${cells}</tr>`;
    })
    .join('');

  return `<table><tbody><tr><th>Recorded At</th>${headers}</tr>${rows}</tbody></table>`;
}

export default function SelectExistingFlowsheetData({
  flowsheet,
  editorReference,
  onClose,
  insertRow,
  updateData,
  deleteRows,
  insertRowData,
  isLoading,
  insertRowLoading,
}: SelectExistingFlowsheetDataProps) {
  const parameters: FlowsheetParameter[] = flowsheet?.rawData?.parameters;
  const [yAxisKey, setYAxisKey] = useState<string>();

  const tableData = useMemo(
    () => mapDataToParameterTitleValue(flowsheet?.gridData?.data),
    [flowsheet?.gridData?.data]
  );

  const defaultParameters = useMemo(() => {
    if (parameters && tableData.length > 0) {
      return parameters
        .filter((param) => {
          const hasData = tableData.some(
            (row) =>
              row[param.parameter.title] !== undefined &&
              row[param.parameter.title] !== null
          );
          return hasData;
        })
        .map((param) => param.id);
    }
    return [];
  }, [parameters, tableData]);

  const numericParameters = parameters?.filter(
    (param) => param.parameter.type === 'number'
  );

  const [selectedParameters, setSelectedParameters] =
    useState<string[]>(defaultParameters);

  function insertFlowsheetData() {
    editorReference?.commands?.insertContent(
      generateHtmlTable(
        tableData,
        selectedParameters?.map(
          (paramId) => parameters.find((p) => p.id === paramId)?.parameter.title
        )
      )
    );

    onClose();
  }

  function insertFlowsheetChartData() {
    const selectedParameter = parameters.find(
      (param) => param.parameter.title === yAxisKey
    );
    editorReference?.commands?.insertContent(
      `<embedded-chart data="${encodeURIComponent(
        JSON.stringify(tableData)
      )}" yAxisLabel="${yAxisKey} (${
        selectedParameter?.parameter?.unit
      })" yAxisKey="${yAxisKey}" xAxisKey="recordedAt"></embedded-chart>`
    );

    onClose();
  }

  if (!flowsheet) {
    return <Skeleton height="100px" />;
  }

  return (
    <Tabs variant="enclosed">
      <TabList>
        <Tab>Table</Tab>
        <Tab>Chart</Tab>
      </TabList>
      <TabPanels>
        <TabPanel>
          <Stack h="100%" spacing={2}>
            <FormLabel>Choose parameters to import</FormLabel>
            <CheckboxGroup
              colorScheme="teal"
              defaultValue={defaultParameters}
              value={selectedParameters}
              onChange={setSelectedParameters}
            >
              <Stack spacing={[1, 5]} direction={['column', 'row']}>
                {parameters.map((flowsheetParameter: FlowsheetParameter) => (
                  <Checkbox
                    key={flowsheetParameter.id}
                    value={flowsheetParameter.id}
                  >
                    {flowsheetParameter.parameter.title}
                  </Checkbox>
                ))}
              </Stack>
            </CheckboxGroup>

            <Stack height="300px">
              {flowsheet && (
                <AgGrid
                  key={flowsheet.id}
                  columnDefs={flowsheet.gridData.colDefs}
                  data={flowsheet.gridData.data}
                  isLoading={isLoading}
                  onAddNewRow={insertRow}
                  onCellEditRequest={updateData}
                  onDeleteRows={deleteRows}
                  onUpdateRow={insertRowData}
                  pagination={false}
                  showActionTabs={false}
                  withActions={[
                    {
                      label: 'Insert into note',
                      onClick: insertFlowsheetData,
                    },
                  ]}
                />
              )}
            </Stack>
          </Stack>
        </TabPanel>
        <TabPanel>
          <Stack>
            <Select
              placeholder="Select value to chart"
              value={yAxisKey}
              onChange={(e) => setYAxisKey(e.target.value)}
            >
              {numericParameters.map(({ parameter }) => (
                <option key={parameter.title} value={parameter.title}>
                  {parameter.title}
                </option>
              ))}
            </Select>
            {yAxisKey && (
              <>
                <LineChart
                  width={600}
                  height={300}
                  data={tableData}
                  margin={{ top: 5, right: 20, bottom: 5, left: 0 }}
                >
                  <Line type="monotone" dataKey={yAxisKey} stroke="#8884d8" />
                  <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                  <XAxis dataKey="recordedAt" />
                  <YAxis />
                  <Tooltip />
                </LineChart>

                <Button onClick={insertFlowsheetChartData}>
                  Insert into note
                </Button>
              </>
            )}
          </Stack>
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
}
