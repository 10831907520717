import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type SendClinicalTestMessageMutationVariables = Types.Exact<{
  messageText: Types.Scalars['String'];
  messageSubject: Types.Scalars['String'];
  fromAddress: Types.Scalars['String'];
  testAddress: Types.Scalars['String'];
  attachmentDocumentId?: Types.Maybe<Types.Scalars['uuid']>;
}>;


export type SendClinicalTestMessageMutation = { __typename?: 'mutation_root', clinicalTestMessage?: { __typename?: 'SuccessAnswer', success: boolean } | null | undefined };


export const SendClinicalTestMessageDocument = gql`
    mutation sendClinicalTestMessage($messageText: String!, $messageSubject: String!, $fromAddress: String!, $testAddress: String!, $attachmentDocumentId: uuid) {
  clinicalTestMessage(
    messageText: $messageText
    messageSubject: $messageSubject
    fromAddress: $fromAddress
    testAddress: $testAddress
    attachmentDocumentId: $attachmentDocumentId
  ) {
    success
  }
}
    `;
export type SendClinicalTestMessageMutationFn = Apollo.MutationFunction<SendClinicalTestMessageMutation, SendClinicalTestMessageMutationVariables>;

/**
 * __useSendClinicalTestMessageMutation__
 *
 * To run a mutation, you first call `useSendClinicalTestMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendClinicalTestMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendClinicalTestMessageMutation, { data, loading, error }] = useSendClinicalTestMessageMutation({
 *   variables: {
 *      messageText: // value for 'messageText'
 *      messageSubject: // value for 'messageSubject'
 *      fromAddress: // value for 'fromAddress'
 *      testAddress: // value for 'testAddress'
 *      attachmentDocumentId: // value for 'attachmentDocumentId'
 *   },
 * });
 */
export function useSendClinicalTestMessageMutation(baseOptions?: Apollo.MutationHookOptions<SendClinicalTestMessageMutation, SendClinicalTestMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendClinicalTestMessageMutation, SendClinicalTestMessageMutationVariables>(SendClinicalTestMessageDocument, options);
      }
export type SendClinicalTestMessageMutationHookResult = ReturnType<typeof useSendClinicalTestMessageMutation>;
export type SendClinicalTestMessageMutationResult = Apollo.MutationResult<SendClinicalTestMessageMutation>;
export type SendClinicalTestMessageMutationOptions = Apollo.BaseMutationOptions<SendClinicalTestMessageMutation, SendClinicalTestMessageMutationVariables>;