import { StyleProps } from '@chakra-ui/react';

export const DEFAULT_GRATUITY_BUTTON_STYLE: StyleProps = {
  width: 'min-content',
};

export const DEFAULT_GRATUITY_OPTIONS = [
  {
    amount: 0.15,
  },
  {
    amount: 0.18,
  },
  {
    amount: 0.2,
  },
];
