import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { MediaTagFieldsFragmentDoc } from '../../fragments/mediaTagFields.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type SearchMediaTagsQueryVariables = Types.Exact<{
  _ilike: Types.Scalars['String'];
}>;


export type SearchMediaTagsQuery = { __typename?: 'query_root', mediaTag: Array<{ __typename?: 'mediaTag', id: any, title: string }> };


export const SearchMediaTagsDocument = gql`
    query searchMediaTags($_ilike: String!) {
  mediaTag(where: {title: {_ilike: $_ilike}}, order_by: {title: asc}) {
    ...MediaTagFields
  }
}
    ${MediaTagFieldsFragmentDoc}`;

/**
 * __useSearchMediaTagsQuery__
 *
 * To run a query within a React component, call `useSearchMediaTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchMediaTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchMediaTagsQuery({
 *   variables: {
 *      _ilike: // value for '_ilike'
 *   },
 * });
 */
export function useSearchMediaTagsQuery(baseOptions: Apollo.QueryHookOptions<SearchMediaTagsQuery, SearchMediaTagsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchMediaTagsQuery, SearchMediaTagsQueryVariables>(SearchMediaTagsDocument, options);
      }
export function useSearchMediaTagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchMediaTagsQuery, SearchMediaTagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchMediaTagsQuery, SearchMediaTagsQueryVariables>(SearchMediaTagsDocument, options);
        }
export type SearchMediaTagsQueryHookResult = ReturnType<typeof useSearchMediaTagsQuery>;
export type SearchMediaTagsLazyQueryHookResult = ReturnType<typeof useSearchMediaTagsLazyQuery>;
export type SearchMediaTagsQueryResult = Apollo.QueryResult<SearchMediaTagsQuery, SearchMediaTagsQueryVariables>;