import * as Types from '../../generated/graphql';

import { gql } from '@apollo/client';
export type AddressFieldsFragment = { __typename?: 'address', addressLine1: string, addressLine2?: string | null | undefined, city: string, id: any, state: string, zipCode: string };

export const AddressFieldsFragmentDoc = gql`
    fragment AddressFields on address {
  addressLine1
  addressLine2
  city
  id
  state
  zipCode
}
    `;