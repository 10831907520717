import * as Types from '../../generated/graphql';

import { gql } from '@apollo/client';
export type MarketingTagFieldsFragment = { __typename?: 'marketingTag', id: any, title: string };

export const MarketingTagFieldsFragmentDoc = gql`
    fragment MarketingTagFields on marketingTag {
  id
  title
}
    `;