import { Box, Heading } from '@chakra-ui/react';
import { useListLeadFormsQuery } from '@webapp/graphql';
import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { TableActions } from './table-actions';
import { TableContent } from './table-content';
import { TablePagination } from './table-pagination';

/* eslint-disable-next-line */
export interface FormTableProps {}

export const FormTable = observer(() => {
  const { data, loading } = useListLeadFormsQuery();
  if (loading) return <div>loading...</div>;

  return (
    <Box as="section" py="12">
      <Box
        maxW={{ base: 'xl', md: '7xl' }}
        mx="auto"
        px={{ base: '6', md: '8' }}
      >
        <Box overflowX="auto">
          <Heading size="lg" mb="6">
            Lead forms
          </Heading>
          <TableActions />
          <TableContent data={data} />
          <TablePagination data={data} />
        </Box>
      </Box>
    </Box>
  );
});
export default FormTable;
