import { PropsWithChildren, Suspense } from 'react';
import {
  Box,
  Center,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Grid,
  HStack,
  Spinner,
  useBreakpointValue,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import { HEADER_HEIGHT, VH_MINUS_HEADER } from '@webapp/webapp/util-display';
import './base-layout.module.scss';
import { Logo, ToggleButton } from '@webapp/ui';
import {
  MainSideBar,
  SettingsSideBar,
  NotificationFeed,
  MenuLinks,
  SupportButton,
} from '@webapp/webapp/ui-composites';
import { Link, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useStores } from '@webapp/state-models';
import Favicon from 'react-favicon';

type BaseLayoutProps = PropsWithChildren<{
  isSticky?: boolean;
}>;

export function BaseLayout({ children, isSticky = false }: BaseLayoutProps) {
  const location = useLocation();
  const { isOpen, onToggle, onClose } = useDisclosure();
  const isMobile = useBreakpointValue({ base: true, md: false });
  const { workspace } = useStores();

  return (
    <Box height="100vh" overflow="hidden" position="relative">
      <Favicon
        url={
          workspace?.organization?.organizationConfiguration?.lightLogoSmall ||
          '/favicon.ico'
        }
      />
      <Helmet>
        <title>{workspace?.organization?.name ?? 'Prospyr'}</title>
      </Helmet>
      <div id="recaptcha-holder"></div>
      <Flex
        align="center"
        bg="white"
        color="white"
        px="6"
        minH="16"
        height={HEADER_HEIGHT}
        borderBottomColor={'gray.200'}
        borderWidth={1}
      >
        <Flex
          justify="space-between"
          align="center"
          justifyItems={'center'}
          alignItems={'center'}
          w="full"
        >
          {/* <MobileHamburgerMenu onClick={toggle} isOpen={isMenuOpen} />
        <NavMenu.Mobile isOpen={isMenuOpen} /> */}
          <span>
            {/* Desktop Logo placement */}
            <Link to="/" h="5" alignSelf="center">
              <Logo
                display={{ base: 'none', lg: 'block' }}
                flexShrink={0}
                h="5"
                alignSelf="center"
                marginEnd="10"
              />
            </Link>

            {/* Desktop Navigation Menu */}
            {/* <NavMenu.Desktop /> */}

            {/* Mobile Logo placement */}
            <Logo
              flex={{ base: '1', lg: '0' }}
              display={{ lg: 'none' }}
              flexShrink={0}
              verticalAlign="center"
              alignSelf="start"
              maxW="100px"
              h="5"
            />
          </span>

          <HStack spacing="3">
            <SupportButton />
            <NotificationFeed />
            {/* <Notification display={{ base: 'none', lg: 'inline-flex' }} /> */}
            {/* TODO: setup USER avatar */}
            {/* <ProfileDropdown /> */}
            {isMobile && (
              <>
                <ToggleButton
                  isOpen={isOpen}
                  aria-label="Open Menu"
                  onClick={onToggle}
                />
                <Drawer
                  isOpen={isOpen}
                  placement="left"
                  onClose={onClose}
                  isFullHeight
                  preserveScrollBarGap
                  // Only disabled for showcase
                  trapFocus={false}
                >
                  <DrawerOverlay />
                  <DrawerContent>
                    <MenuLinks />
                  </DrawerContent>
                </Drawer>
              </>
            )}
          </HStack>
        </Flex>
      </Flex>
      <Grid
        gridTemplateColumns={{
          base: '1fr',

          md: 'max-content 1fr',
        }}
        height={VH_MINUS_HEADER}
        id="app-container"
      >
        {location.pathname.includes('/settings') ? (
          <SettingsSideBar />
        ) : (
          <MainSideBar />
        )}
        <Grid
          bg={useColorModeValue('white', 'gray.800')}
          height={VH_MINUS_HEADER}
          flex="1"
          gridTemplateColumns="1fr"
          overflowX="hidden"
          overflowY="scroll"
          position="relative"
          css={{
            '&::-webkit-scrollbar': {
              display: 'none',
            },
          }}
          sx={{
            scrollbarWidth: 'none',
          }}
        >
          <Suspense
            fallback={
              <Center h="100vh">
                <Spinner size="lg" colorScheme={'teal'} />
              </Center>
            }
          >
            {children}
          </Suspense>
        </Grid>
      </Grid>
    </Box>
  );
}

export default BaseLayout;
