import { ReactRenderer } from '@tiptap/react';
import { usePatientFlowsheets } from '@webapp/hooks';
import { useEffect } from 'react';
import { useListFlowsheetsWithParametersQuery } from '@webapp/graphql';
import tippy from 'tippy.js';

import MergeTagList from './mergeTagList';

const ATTRIBUTES = [
  'appointmentDate',
  'author',
  'additionalAllergyInfo',
  'additionalFamilyHistoryInfo',
  'additionalMedicalHistoryInfo',
  'additionalSymptomInfo',
  'alcoholConsumption',
  'allergiesAdhesiveTape',
  'allergiesBeeStings',
  'allergiesIodine',
  'allergiesLatex',
  'allergiesLidocane',
  'allergiesMedicine',
  'anesthesiaComplications',
  'cancerDetails',
  'cigaretteSmokingStatus',
  'dob',
  'email',
  'familyHistory',
  'firstName',
  'gender',
  'height',
  'historyOfColdSores',
  'howDidYouFindUs',
  'lastMenstrualCycle',
  'lastName',
  'medicalHistory',
  'medicationDosage',
  'isNursing',
  'otherSmokingStatus',
  'pastSurgeries',
  'phoneNumber',
  'planningPregnancy',
  'preferredPharmacy',
  'preferredPharmacyPhoneNumber',
  'isPregnant',
  'problematicAnesthesia',
  'race',
  'recreationalDrugUse',
  'sex',
  'symptoms',
];

interface UseLoadMergeTagsOutput {
  char?: string;
  items: ({ query }: { query: string }) => string[];
  allowSpaces?: boolean;
  render: () => {
    onStart?: (props: any) => void;
    onUpdate?: (props: any) => void;
    onExit?: (props: any) => void;
    onKeyDown?: (props: any) => boolean;
  };
}

export interface UseLoadMergeTagsProps {
  mergeTagContext?: any;
  creatingTemplate?: boolean;
  patientId?: string;
}

export const useLoadMergeTags = ({
  creatingTemplate,
  mergeTagContext,
  patientId,
}: UseLoadMergeTagsProps): UseLoadMergeTagsOutput => {
  const { data } = useListFlowsheetsWithParametersQuery();

  useEffect(() => {
    for (const flowsheet of data?.flowsheet ?? []) {
      for (const parameter of flowsheet.parameters) {
        ATTRIBUTES.push(
          `${flowsheet.title}.${parameter.parameter.title}.lastValue`
        );
        ATTRIBUTES.push(
          `${flowsheet.title}.${parameter.parameter.title}.firstValue`
        );
      }
    }
  }, [data?.flowsheet]);

  return {
    char: '{{{',
    allowSpaces: true,
    items: ({ query }) =>
      ATTRIBUTES.filter((a) => a.toLowerCase().includes(query.toLowerCase())),
    render: () => {
      let reactRenderer: any;
      let popup: any;

      return {
        onStart: (props: any) => {
          reactRenderer = new ReactRenderer(MergeTagList, {
            props: {
              ...props,
              mergeTagContext,
              creatingTemplate,
            },
            editor: props.editor,
          });

          if (!props.clientRect) {
            return;
          }

          popup = tippy('body', {
            getReferenceClientRect: props.clientRect,
            appendTo: () => document.body,
            content: reactRenderer.element,
            showOnCreate: true,
            interactive: true,
            trigger: 'manual',
            placement: 'bottom-start',
          });
        },

        onUpdate(props: any) {
          reactRenderer.updateProps(props);

          if (!props.clientRect) {
            return;
          }

          popup[0].setProps({
            getReferenceClientRect: props.clientRect,
          });
        },

        onKeyDown(props: any) {
          if (props.event.key === 'Escape') {
            popup[0].hide();

            return true;
          }

          return reactRenderer.ref?.onKeyDown(props);
        },

        onExit() {
          popup[0].destroy();
          reactRenderer.destroy();
        },
      };
    },
  };
};

export default useLoadMergeTags;
