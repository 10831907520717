import 'survey-core/defaultV2.min.css';
import { useCallback, useEffect, useState } from 'react';
import { Model, Serializer } from 'survey-core';
import { Survey } from 'survey-react-ui';
import { useStores } from '@webapp/state-models';
import './variables.scss';
import { useSubmitSurveyJsFormMutation } from '@webapp/graphql';
import { Center } from '@chakra-ui/react';
import InnerHTML from 'dangerously-set-html-content';
import { LeadFormFieldsFragment } from 'libs/graphql/src/fragments/leadFormFields.fragment.generated';
import { SurveyPDF } from 'survey-pdf';
import { LoadingFullScreen } from '@webapp/ui-composites';
import { useSearchParams } from 'react-router-dom';
import { registerServiceCategory } from './custom-questions/service-category-selection';
import { registerBookAppointment } from './custom-questions/book-appointment';
import SurveyJSCompletePage from './surveyjs-complete-page';
import configureLeadFormSurveyJSComponents from './configureLeadFormComponents';

const DEFAULT_BACKGROUND_IMAGE =
  'https://images.unsplash.com/photo-1495195129352-aeb325a55b65?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1752&q=80';

/* eslint-disable-next-line */
export interface SurveyJSFormRendererProps {
  surveyJSJson: any;
  leadFormId: string;
  leadFormLinkId?: string;
  leadForm: LeadFormFieldsFragment;
  prefillData?: any;
}

registerBookAppointment();
registerServiceCategory();

const captureUTMParameters = (searchParams: URLSearchParams) => {
  const utmParams = [
    'utm_source',
    'utm_medium',
    'utm_campaign',
    'utm_term',
    'utm_content',
  ];
  const capturedParams: { [key: string]: string | null } = {};

  utmParams.forEach((param) => {
    const value = searchParams.get(param);

    if (value) {
      capturedParams[param] = value;
    }
  });

  return capturedParams;
};

export function SurveyJSFormRenderer({
  surveyJSJson,
  leadFormId,
  leadForm,
  leadFormLinkId,
  prefillData,
}: SurveyJSFormRendererProps) {
  const [complete, setComplete] = useState(false);
  const [utmParams, setUtmParams] = useState<{ [key: string]: string | null }>(
    {}
  );
  const [submitSurveyJSLeadForm] = useSubmitSurveyJsFormMutation({
    ignoreResults: true,
  });
  const [searchParams] = useSearchParams();
  const [ready, setReady] = useState(false);
  const [processedHtml, setProcessedHtml] = useState('');
  useEffect(() => {
    configureLeadFormSurveyJSComponents({
      patientReferralOptions:
        leadForm?.workspace?.workspaceConfiguration?.patientReferralOptions,
    });
    setReady(true);
    setUtmParams(captureUTMParameters(searchParams));
  }, [searchParams]);

  Serializer.addProperty('question', {
    name: 'payrixMerchantId',
    type: 'text',
    isRequired: true,
    category: 'general',
    visible: false,
    default: leadForm?.workspace?.payrixMerchantId,
  });

  Serializer.addProperty('question', {
    name: 'finixMerchantId',
    type: 'text',
    isRequired: true,
    category: 'general',
    visible: false,
    default: leadForm?.workspace?.finixMerchantId,
  });

  Serializer.addProperty('question', {
    name: 'leadFormWorkspaceId',
    type: 'text',
    isRequired: true,
    category: 'general',
    visible: false,
    default: leadForm?.workspaceId,
  });

  Serializer.addProperty('question', {
    name: 'leadFormLinkId',
    type: 'text',
    isRequired: true,
    category: 'general',
    visible: false,
    default: leadFormLinkId,
  });

  const survey = new Model(surveyJSJson);

  if (surveyJSJson.hasOwnProperty('allowProviderSelection')) {
    survey.setVariable(
      'allowProviderSelection',
      surveyJSJson.allowProviderSelection
    );
  } else {
    survey.setVariable('allowProviderSelection', true);
  }

  const submitData = useCallback(
    (data: any) => {
      submitSurveyJSLeadForm({
        variables: {
          input: {
            leadFormId,
            data,
            utmParameters: utmParams,
          },
        },
      }).then((data) => {
        survey.data = {
          ...survey.data,
          ...data.data.submitSurveyJSForm,
        };

        if (data?.data?.submitSurveyJSForm?.patientStatus) {
          survey.setVariable(
            'patientStatus',
            data.data.submitSurveyJSForm.patientStatus
          );
        }
        if (data?.data?.submitSurveyJSForm?.previousServiceIds) {
          survey.setVariable(
            'previousServiceIds',
            data.data.submitSurveyJSForm.previousServiceIds
          );
        }
      });
    },
    [survey?.data, utmParams]
  );

  if (leadForm.themeJSON) {
    survey.applyTheme(leadForm.themeJSON);
  }

  if (prefillData) {
    survey.data = {
      ...survey.data,
      ...prefillData,
    };
  }

  survey.sendResultOnPageNext = true;
  survey.onPartialSend.add((sender) => {
    submitData(survey.getPlainData());
  });
  survey.setVariable('lockedToServices', surveyJSJson.lockedToServices);
  survey.setVariable('lockedToLocations', surveyJSJson.lockedToLocations);
  survey.setVariable('serviceOrderRanking', surveyJSJson.serviceOrderRanking);
  survey.setVariable('leadFormLinkId', leadFormLinkId);

  survey.onCurrentPageChanged.add((sender) => {
    if (
      surveyJSJson.facebookPixelId &&
      surveyJSJson.facebookNextPageConversionEventName
    ) {
      try {
        window.fbq('track', surveyJSJson.facebookNextPageConversionEventName);
      } catch (err) {
        console.error(err, 'FB Event track error');
      }
    }
  });

  survey.onComplete.add((sender) => {
    if (
      surveyJSJson.facebookPixelId &&
      surveyJSJson.facebookFormCompletionConversionEventName
    ) {
      try {
        window.fbq(
          'track',
          surveyJSJson.facebookFormCompletionConversionEventName
        );
      } catch (err) {
        console.error(err, 'FB Event track error');
      }
    }
    setProcessedHtml(sender.processedCompletedHtml);
    submitSurveyJSLeadForm({
      variables: {
        input: {
          leadFormId,
          data: survey.getPlainData(),
          utmParameters: utmParams,
        },
      },
    });
    setComplete(true);
  });

  useEffect(() => {
    if (ready && prefillData) {
      if (prefillData.email && prefillData.firstName && prefillData.lastName) {
        submitData(survey.getPlainData());

        if (survey?.PageCount > 1) {
          survey.currentPageNo = 1;
        }
      }
    }
  }, [prefillData, ready]);

  if (!ready) return <LoadingFullScreen />;

  if (surveyJSJson.customBackgroundImage) {
    return (
      <Center
        key={leadFormId}
        bgImage={surveyJSJson.customBackgroundImage}
        bgRepeat="no-repeat"
        bgPosition="center"
        bgSize="cover"
        w="full"
        h="100vh"
      >
        {surveyJSJson?.headerCode && (
          <InnerHTML html={surveyJSJson?.headerCode} />
        )}
        {complete ? (
          <SurveyJSCompletePage surveyJson={surveyJSJson} />
        ) : (
          <Survey model={survey} showCompletedPage={false} />
        )}
      </Center>
    );
  }
  return (
    <>
      {surveyJSJson?.headerCode && (
        <InnerHTML html={surveyJSJson?.headerCode} />
      )}
      {complete ? (
        <SurveyJSCompletePage
          surveyJson={surveyJSJson}
          completedHtml={processedHtml}
        />
      ) : (
        <Survey model={survey} showCompletedPage={false} />
      )}
    </>
  );
}

export default SurveyJSFormRenderer;
