import { SelectOption } from '@webapp/types';
import { ActionMeta, Select, SingleValue } from 'chakra-react-select';
import DEFAULT_CONTROL_STYLE from './styles/DefaultControlStyle';
import DEFAULT_INPUT_CONTAINER_STYLE from './styles/DefaultInputContainerStyle';
import { SelectInputProps } from './types';

function SelectInput({
  onChange,
  options,
  styles = {},
  ...props
}: SelectInputProps) {
  const inputStyles = { ...DEFAULT_CONTROL_STYLE, ...styles.control };

  const containerStyles = {
    ...DEFAULT_INPUT_CONTAINER_STYLE,
    ...styles.inputContainer,
  };

  const handleChange = (
    newValue: SingleValue<SelectOption>,
    actionMeta: ActionMeta<SingleValue<SelectOption>>
  ) => {
    switch (actionMeta.action) {
      case 'pop-value':
      case 'remove-value': {
        onChange(null);
        break;
      }

      case 'clear': {
        onChange(null);
        break;
      }

      default: {
        if (newValue) {
          onChange(newValue);
        } else {
          onChange(null);
        }

        break;
      }
    }
  };

  return (
    <Select<SingleValue<SelectOption>>
      chakraStyles={{
        container: (provided) => ({ ...provided, ...containerStyles }),
        control: (provided) => ({ ...provided, ...inputStyles }),
      }}
      className="select-input"
      isInvalid={Boolean(props.error)}
      isMulti={false}
      options={options}
      onChange={handleChange}
      id={props.id ? props.id : ''}
      {...props}
    />
  );
}

export default SelectInput;
