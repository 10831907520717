import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { PromotionFieldsFragmentDoc } from '../../fragments/promotionFields.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetPromotionQueryVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
}>;


export type GetPromotionQuery = { __typename?: 'query_root', promotion_by_pk?: { __typename?: 'promotion', id: any, createdAt: any, updatedAt: any, title: string, description: string, isActive: boolean, isArchived: boolean, startsAt?: any | null | undefined, endsAt?: any | null | undefined, image?: string | null | undefined, workspaceId: any, locationId?: any | null | undefined, location?: { __typename?: 'location', id: any, name: string } | null | undefined, promotionServices: Array<{ __typename?: 'promotionServices', id: any, serviceId: any, discountAmount: any, discountType: string, quantity?: number | null | undefined, service: { __typename?: 'service', id: any, name: string } }> } | null | undefined };


export const GetPromotionDocument = gql`
    query GetPromotion($id: uuid!) {
  promotion_by_pk(id: $id) {
    ...PromotionFields
  }
}
    ${PromotionFieldsFragmentDoc}`;

/**
 * __useGetPromotionQuery__
 *
 * To run a query within a React component, call `useGetPromotionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPromotionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPromotionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPromotionQuery(baseOptions: Apollo.QueryHookOptions<GetPromotionQuery, GetPromotionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPromotionQuery, GetPromotionQueryVariables>(GetPromotionDocument, options);
      }
export function useGetPromotionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPromotionQuery, GetPromotionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPromotionQuery, GetPromotionQueryVariables>(GetPromotionDocument, options);
        }
export type GetPromotionQueryHookResult = ReturnType<typeof useGetPromotionQuery>;
export type GetPromotionLazyQueryHookResult = ReturnType<typeof useGetPromotionLazyQuery>;
export type GetPromotionQueryResult = Apollo.QueryResult<GetPromotionQuery, GetPromotionQueryVariables>;