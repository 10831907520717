import * as Types from '../../generated/graphql';

import { gql } from '@apollo/client';
import { LeadFormFieldsFragmentDoc } from '../fragments/leadFormFields.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetLeadFormByPkQueryVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
}>;


export type GetLeadFormByPkQuery = { __typename?: 'query_root', leadForm_by_pk?: { __typename?: 'leadForm', id: any, name: string, businessLogo?: string | null | undefined, layout?: string | null | undefined, backgroundColor?: string | null | undefined, formBackground?: string | null | undefined, font?: string | null | undefined, sideBannerImage?: string | null | undefined, surveyJSJson?: any | null | undefined, themeJSON?: any | null | undefined, headerCode?: string | null | undefined, workspaceId: any, lockedToServices?: any | null | undefined, lockedToLocations?: any | null | undefined, successMessage?: string | null | undefined, steps: Array<{ __typename?: 'leadFormStep', id: any, name?: string | null | undefined, order: number, title?: string | null | undefined, subtitle?: string | null | undefined, callToAction?: string | null | undefined, workspaceId: any, type: string, canDelete: boolean, canReOrder: boolean, properties?: any | null | undefined, fields: Array<{ __typename?: 'leadFormField', id: any, validationProps?: any | null | undefined, apiFieldKey: string, label: string, order: any, type: string, workspaceId: any }> }>, workspace: { __typename?: 'workspace', payrixMerchantId?: string | null | undefined, finixMerchantId?: string | null | undefined, name: string, organization?: { __typename?: 'organization', organizationConfiguration?: { __typename?: 'organizationConfiguration', lightLogoSmall: string, whitelabelingEnabled: boolean } | null | undefined } | null | undefined, workspaceConfiguration?: { __typename?: 'workspaceConfiguration', noShowActive?: boolean | null | undefined, noShowFee?: number | null | undefined, patientReferralOptions?: any | null | undefined } | null | undefined } } | null | undefined };


export const GetLeadFormByPkDocument = gql`
    query getLeadFormByPk($id: uuid!) {
  leadForm_by_pk(id: $id) {
    ...LeadFormFields
  }
}
    ${LeadFormFieldsFragmentDoc}`;

/**
 * __useGetLeadFormByPkQuery__
 *
 * To run a query within a React component, call `useGetLeadFormByPkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLeadFormByPkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLeadFormByPkQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetLeadFormByPkQuery(baseOptions: Apollo.QueryHookOptions<GetLeadFormByPkQuery, GetLeadFormByPkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLeadFormByPkQuery, GetLeadFormByPkQueryVariables>(GetLeadFormByPkDocument, options);
      }
export function useGetLeadFormByPkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLeadFormByPkQuery, GetLeadFormByPkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLeadFormByPkQuery, GetLeadFormByPkQueryVariables>(GetLeadFormByPkDocument, options);
        }
export type GetLeadFormByPkQueryHookResult = ReturnType<typeof useGetLeadFormByPkQuery>;
export type GetLeadFormByPkLazyQueryHookResult = ReturnType<typeof useGetLeadFormByPkLazyQuery>;
export type GetLeadFormByPkQueryResult = Apollo.QueryResult<GetLeadFormByPkQuery, GetLeadFormByPkQueryVariables>;