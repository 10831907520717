import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetActiveInvoiceTotalsQueryVariables = Types.Exact<{
  invoiceId: Types.Scalars['uuid'];
  options?: Types.Maybe<Types.GetActiveInvoiceTotalsOptions>;
}>;


export type GetActiveInvoiceTotalsQuery = { __typename?: 'query_root', getActiveInvoiceTotals: { __typename?: 'GetActiveInvoiceTotalsOutput', bsaDiscount: number, totals: { __typename?: 'GetInvoiceTotalsOutput', balanceDue: number, creditsApplied: number, depositPaid: number, discount: number, gratuity?: number | null | undefined, postTaxTotal: number, preTaxTotal: number, taxTotal: number } } };


export const GetActiveInvoiceTotalsDocument = gql`
    query GetActiveInvoiceTotals($invoiceId: uuid!, $options: GetActiveInvoiceTotalsOptions) {
  getActiveInvoiceTotals(invoiceId: $invoiceId, options: $options) {
    bsaDiscount
    totals {
      balanceDue
      creditsApplied
      depositPaid
      discount
      gratuity
      postTaxTotal
      preTaxTotal
      taxTotal
    }
  }
}
    `;

/**
 * __useGetActiveInvoiceTotalsQuery__
 *
 * To run a query within a React component, call `useGetActiveInvoiceTotalsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActiveInvoiceTotalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActiveInvoiceTotalsQuery({
 *   variables: {
 *      invoiceId: // value for 'invoiceId'
 *      options: // value for 'options'
 *   },
 * });
 */
export function useGetActiveInvoiceTotalsQuery(baseOptions: Apollo.QueryHookOptions<GetActiveInvoiceTotalsQuery, GetActiveInvoiceTotalsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetActiveInvoiceTotalsQuery, GetActiveInvoiceTotalsQueryVariables>(GetActiveInvoiceTotalsDocument, options);
      }
export function useGetActiveInvoiceTotalsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetActiveInvoiceTotalsQuery, GetActiveInvoiceTotalsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetActiveInvoiceTotalsQuery, GetActiveInvoiceTotalsQueryVariables>(GetActiveInvoiceTotalsDocument, options);
        }
export type GetActiveInvoiceTotalsQueryHookResult = ReturnType<typeof useGetActiveInvoiceTotalsQuery>;
export type GetActiveInvoiceTotalsLazyQueryHookResult = ReturnType<typeof useGetActiveInvoiceTotalsLazyQuery>;
export type GetActiveInvoiceTotalsQueryResult = Apollo.QueryResult<GetActiveInvoiceTotalsQuery, GetActiveInvoiceTotalsQueryVariables>;