import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type DeleteConsentMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
}>;


export type DeleteConsentMutation = { __typename?: 'mutation_root', delete_consent_by_pk?: { __typename?: 'consent', id: any } | null | undefined };


export const DeleteConsentDocument = gql`
    mutation deleteConsent($id: uuid!) {
  delete_consent_by_pk(id: $id) {
    id
  }
}
    `;
export type DeleteConsentMutationFn = Apollo.MutationFunction<DeleteConsentMutation, DeleteConsentMutationVariables>;

/**
 * __useDeleteConsentMutation__
 *
 * To run a mutation, you first call `useDeleteConsentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteConsentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteConsentMutation, { data, loading, error }] = useDeleteConsentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteConsentMutation(baseOptions?: Apollo.MutationHookOptions<DeleteConsentMutation, DeleteConsentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteConsentMutation, DeleteConsentMutationVariables>(DeleteConsentDocument, options);
      }
export type DeleteConsentMutationHookResult = ReturnType<typeof useDeleteConsentMutation>;
export type DeleteConsentMutationResult = Apollo.MutationResult<DeleteConsentMutation>;
export type DeleteConsentMutationOptions = Apollo.BaseMutationOptions<DeleteConsentMutation, DeleteConsentMutationVariables>;