import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetAppointmentDepositAmountQueryVariables = Types.Exact<{
  appointmentId: Types.Scalars['uuid'];
}>;


export type GetAppointmentDepositAmountQuery = { __typename?: 'query_root', getAppointmentDepositAmount: { __typename?: 'GetAppointmentDepositAmountOutput', deposit: number } };


export const GetAppointmentDepositAmountDocument = gql`
    query GetAppointmentDepositAmount($appointmentId: uuid!) {
  getAppointmentDepositAmount(appointmentId: $appointmentId) {
    deposit
  }
}
    `;

/**
 * __useGetAppointmentDepositAmountQuery__
 *
 * To run a query within a React component, call `useGetAppointmentDepositAmountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAppointmentDepositAmountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAppointmentDepositAmountQuery({
 *   variables: {
 *      appointmentId: // value for 'appointmentId'
 *   },
 * });
 */
export function useGetAppointmentDepositAmountQuery(baseOptions: Apollo.QueryHookOptions<GetAppointmentDepositAmountQuery, GetAppointmentDepositAmountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAppointmentDepositAmountQuery, GetAppointmentDepositAmountQueryVariables>(GetAppointmentDepositAmountDocument, options);
      }
export function useGetAppointmentDepositAmountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAppointmentDepositAmountQuery, GetAppointmentDepositAmountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAppointmentDepositAmountQuery, GetAppointmentDepositAmountQueryVariables>(GetAppointmentDepositAmountDocument, options);
        }
export type GetAppointmentDepositAmountQueryHookResult = ReturnType<typeof useGetAppointmentDepositAmountQuery>;
export type GetAppointmentDepositAmountLazyQueryHookResult = ReturnType<typeof useGetAppointmentDepositAmountLazyQuery>;
export type GetAppointmentDepositAmountQueryResult = Apollo.QueryResult<GetAppointmentDepositAmountQuery, GetAppointmentDepositAmountQueryVariables>;