import * as React from 'react';
import { Box, Fade, HStack, ScaleFade } from '@chakra-ui/react';
import { BsCaretRightFill } from 'react-icons/bs';

export interface NavItemProps {
  href?: string;
  label: string;
  subtle?: boolean;
  minimized?: boolean;
  active?: boolean;
  icon: React.ReactElement;
  endElement?: React.ReactElement;
  children?: React.ReactNode;
}

export const NavItem = (props: NavItemProps) => {
  const { active, icon, children, label, endElement, href, minimized } = props;
  return (
    <HStack
      as="div"
      h="44px"
      w="full"
      px="3"
      py="2"
      cursor="pointer"
      userSelect="none"
      position="relative"
      rounded="md"
      transition="all 0.2s"
      bg={active ? 'brand.lightGreen' : undefined}
      _hover={{ bg: 'brand.lightGreen' }}
      _active={{ bg: 'brand.lightGreen' }}
      data-testid={`nav-item-${label}`}
    >
      {/* {active && (
        <Box
          bg={'brand.primary'}
          w={4}
          h={'100%'}
          borderRightRadius={4}
          position={'absolute'}
          left={-2}
        ></Box>
      )} */}
      <Box
        fontSize="lg"
        color={active ? 'brand.primary' : 'brand.darkGray'}
        fontWeight={'medium'}
      >
        {icon}
      </Box>
      <ScaleFade initialScale={0.1} in={!minimized}>
        <Box
          flex="1"
          fontWeight="medium"
          color={active ? 'brand.primary' : 'brand.darkGray'}
        >
          {label}
        </Box>
      </ScaleFade>

      {endElement && !children && !minimized && <Box>{endElement}</Box>}
      {children && <Box fontSize="xs" flexShrink={0} as={BsCaretRightFill} />}
    </HStack>
  );
};

export default NavItem;
