import { StatusMap } from '@webapp/types';
import { IoMdWarning } from 'react-icons/io';

const DEFAULT_STATUS_MAP: StatusMap = {
  ACTION_REQUIRED: {
    background: 'gold.50',
    color: 'gold.700',
  },
  DEFAULT: {
    background: '#F6FBFF',
    color: '#284B63',
  },
  HOLD: {
    background: '#F6FBFF',
    color: '#284B63',
    value: 'REMOVED_HOLD',
  },
  REMOVED_HOLD: {
    background: '#F6FBFF',
    color: '#284B63',
    value: 'HOLD',
  },
  BOOKED: {
    background: '#F6FBFF',
    color: '#284B63',
    value: 'BOOKED',
  },
  CONFIRMED: {
    background: '#EAFCFF',
    color: '#00758A',
    value: 'CONFIRMED',
  },
  CHECKED_IN: {
    background: '#EAFCFF',
    color: '#00758A',
    value: 'CHECKED_IN',
  },
  CHECKED_OUT: {
    background: '#EAFCFF',
    color: '#00758A',
    value: 'CHECKED_OUT',
  },
  COMPLETED: {
    background: '#F4F4F3',
    color: '#525257',
    value: 'COMPLETED',
  },
  CANCELED: {
    background: '#FFF5F1',
    color: '#B26844',
    value: 'CANCELED',
  },
  CONFLICT: {
    background: '#FFF5F1',
    color: '#B26844',
    value: 'CONFLICT',
  },
  DRAFT: {
    background: '#FF7800',
    color: '#FFF',
    icon: IoMdWarning,
    value: 'DRAFT',
  },
  NO_SHOW: {
    background: '#FFF9EE',
    color: '#83601B',
    value: 'NO_SHOW',
  },
  INCOMPLETE: {
    background: '#E53E3E',
    color: '#FFF',
    value: 'INCOMPLETE',
  },
  REFUNDED: {
    background: '#EAFCFF',
    color: '#00758A',
    value: 'REFUNDED',
  },
  VOID: {
    background: 'red.500',
    color: '#FFF',
    value: 'VOID',
  },
};

export default DEFAULT_STATUS_MAP;
