/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import { ComponentCollection, SvgRegistry, FunctionFactory } from 'survey-core';

export interface ConfigureLeadFormComponentsProps {
  patientReferralOptions?: string[];
}

export default function configureLeadFormSurveyJSComponents({
  patientReferralOptions,
}: ConfigureLeadFormComponentsProps) {
  try {
    SvgRegistry.registerIconFromSvg(
      'appointment',
      `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="black" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z" />
                </svg>
            `
    );

    SvgRegistry.registerIconFromSvg(
      'email',
      `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#909090" class="w-6 h-6">
            <path stroke-linecap="round" d="M16.5 12a4.5 4.5 0 11-9 0 4.5 4.5 0 019 0zm0 0c0 1.657 1.007 3 2.25 3S21 13.657 21 12a9 9 0 10-2.636 6.364M16.5 12V8.25" />
            </svg>
            `
    );

    SvgRegistry.registerIconFromSvg(
      'birthday',
      `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#909090" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M12 8.25v-1.5m0 1.5c-1.355 0-2.697.056-4.024.166C6.845 8.51 6 9.473 6 10.608v2.513m6-4.87c1.355 0 2.697.055 4.024.165C17.155 8.51 18 9.473 18 10.608v2.513m-3-4.87v-1.5m-6 1.5v-1.5m12 9.75l-1.5.75a3.354 3.354 0 01-3 0 3.354 3.354 0 00-3 0 3.354 3.354 0 01-3 0 3.354 3.354 0 00-3 0 3.354 3.354 0 01-3 0L3 16.5m15-3.38a48.474 48.474 0 00-6-.37c-2.032 0-4.034.125-6 .37m12 0c.39.049.777.102 1.163.16 1.07.16 1.837 1.094 1.837 2.175v5.17c0 .62-.504 1.124-1.125 1.124H4.125A1.125 1.125 0 013 20.625v-5.17c0-1.08.768-2.014 1.837-2.174A47.78 47.78 0 016 13.12M12.265 3.11a.375.375 0 11-.53 0L12 2.845l.265.265zm-3 0a.375.375 0 11-.53 0L9 2.845l.265.265zm6 0a.375.375 0 11-.53 0L15 2.845l.265.265z" />
              </svg>

            `
    );

    SvgRegistry.registerIconFromSvg(
      'weight',
      `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#909090" class="w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round" d="M12 3v17.25m0 0c-1.472 0-2.882.265-4.185.75M12 20.25c1.472 0 2.882.265 4.185.75M18.75 4.97A48.416 48.416 0 0012 4.5c-2.291 0-4.545.16-6.75.47m13.5 0c1.01.143 2.01.317 3 .52m-3-.52l2.62 10.726c.122.499-.106 1.028-.589 1.202a5.988 5.988 0 01-2.031.352 5.988 5.988 0 01-2.031-.352c-.483-.174-.711-.703-.59-1.202L18.75 4.971zm-16.5.52c.99-.203 1.99-.377 3-.52m0 0l2.62 10.726c.122.499-.106 1.028-.589 1.202a5.989 5.989 0 01-2.031.352 5.989 5.989 0 01-2.031-.352c-.483-.174-.711-.703-.59-1.202L5.25 4.971z" />
          </svg>
          `
    );

    SvgRegistry.registerIconFromSvg(
      'question-mark',
      `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#909090" class="w-6 h-6">
          <path stroke-linecap="round" stroke-linejoin="round" d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9 5.25h.008v.008H12v-.008z" />
        </svg>
        `
    );

    SvgRegistry.registerIconFromSvg(
      'house',
      `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#909090" class="w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25" />
          </svg>
        `
    );

    SvgRegistry.registerIconFromSvg(
      'credit-card',
      `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#909090" class="w-6 h-6">
          <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 19.5z" />
        </svg>
        `
    );

    ComponentCollection.Instance.add({
      // A unique name; must use lowercase
      name: 'book-appointment-composite',
      // A display name used in the Toolbox
      title: 'Book Appointment',
      iconName: 'icon-appointment',
      titleLocation: 'hidden',
      // An array of JSON schemas that configure the nested questions
      elementsJSON: [
        {
          type: 'text',
          name: 'firstName',
          title: 'First Name',
          isRequired: true,
          titleLocation: 'top',

          autocomplete: 'given-name',
          visibleIf: 'shouldShowBookingField("firstName")',
        },
        {
          type: 'text',
          name: 'lastName',
          title: 'Last Name',
          isRequired: true,
          startWithNewLine: false,
          titleLocation: 'top',
          autocomplete: 'family-name',
          visibleIf: 'shouldShowBookingField("lastName")',
        },
        {
          type: 'text',
          name: 'email',
          title: 'Email',
          isRequired: true,
          titleLocation: 'top',
          autocomplete: 'email',
          inputType: 'email',
          visibleIf: 'shouldShowBookingField("email")',
          defaultValueExpression: '{email}',
        },

        {
          type: 'text',
          name: 'phoneNumber',
          title: 'Phone Number',
          isRequired: true,
          startWithNewLine: false,
          titleLocation: 'top',
          autocomplete: 'tel',
          inputType: 'tel',
          visibleIf: 'shouldShowBookingField("phoneNumber")',
          defaultValueExpression: '{phoneNumber}',
        },
        {
          type: 'book-appointment',
          name: 'bookAppointment',
          title: 'Book Appointment',
          titleLocation: 'hidden',
          isRequired: true,
        },
      ],
    });

    ComponentCollection.Instance.add({
      // A unique name; must use lowercase
      name: 'phone-number',
      title: 'Phone Number',
      iconName: 'icon-text',
      titleLocation: 'hidden',
      onCreated: (component) => {
        component.titleLocation = 'hidden';
      },
      elementsJSON: [
        {
          type: 'text',
          name: 'phoneNumber',
          title: 'Phone Number',
          defaultValueExpression: '{phoneNumber}',
          isRequired: true,
          autocomplete: 'tel',
          inputType: 'tel',
        },
      ],
    });

    ComponentCollection.Instance.add({
      // A unique name; must use lowercase
      name: 'date-of-birth',
      title: 'Date of Birth',
      iconName: 'icon-birthday',
      titleLocation: 'hidden',
      onCreated: (component) => {
        component.titleLocation = 'hidden';
      },
      elementsJSON: [
        {
          type: 'text',
          name: 'dob',
          title: 'Date of Birth',
          inputType: 'date',
          autocomplete: 'bday',
        },
      ],
    });

    ComponentCollection.Instance.add({
      // A unique name; must use lowercase
      name: 'weight',
      title: 'Weight',
      inputType: 'number',
      iconName: 'icon-weight',
      titleLocation: 'hidden',
      onCreated: (component) => {
        component.titleLocation = 'hidden';
      },
      elementsJSON: [
        {
          type: 'text',
          name: 'weight',
          title: 'Weight (lbs)',
          inputType: 'number',
        },
      ],
    });

    ComponentCollection.Instance.add({
      // A unique name; must use lowercase
      name: 'email',
      title: 'Email Address',
      iconName: 'icon-email',
      titleLocation: 'hidden',
      onCreated: (component) => {
        component.titleLocation = 'hidden';
      },
      elementsJSON: [
        {
          type: 'text',
          name: 'email',
          title: 'Email Address',
          isRequired: true,
          autocomplete: 'email',
          inputType: 'email',
          defaultValueExpression: '{email}',
        },
      ],
    });

    ComponentCollection.Instance.add({
      // A unique name; must use lowercase
      name: 'shipping-address',
      title: 'Shipping Address',
      iconName: 'icon-house',
      titleLocation: 'hidden',
      onCreated: (component) => {
        component.titleLocation = 'hidden';
      },
      elementsJSON: [
        {
          type: 'address',
          name: 'shippingAddress',
          title: 'Shipping Address',
        },
      ],
    });

    ComponentCollection.Instance.add({
      // A unique name; must use lowercase
      name: 'billing-address',
      title: 'Billing Address',
      iconName: 'icon-credit-card',
      titleLocation: 'hidden',
      onCreated: (component) => {
        component.titleLocation = 'hidden';
      },
      elementsJSON: [
        {
          type: 'panel',
          name: 'panel',
          elements: [
            {
              type: 'boolean',
              name: 'billingSameAsShipping',
              title: 'Billing Address same as Shipping',
              defaultValue: false,
            },
            {
              type: 'address',
              name: 'billingAddress',
              /* prettier-ignore */
              visibleIf: "{composite.billingSameAsShipping} <> true",
              titleLocation: 'hidden',
            },
          ],
        },
      ],
    });

    ComponentCollection.Instance.add({
      // A unique name; must use lowercase
      name: 'full-name',
      // A display name used in the Toolbox
      title: 'Full Name',
      iconName: 'icon-text',
      onCreated: (component) => {
        component.titleLocation = 'hidden';
      },
      // An array of JSON schemas that configure the nested questions
      elementsJSON: [
        {
          type: 'text',
          name: 'firstName',
          title: 'First Name',
          isRequired: true,
          autocomplete: 'given-name',
          defaultValueExpression: '{firstName}',
        },
        {
          type: 'text',
          name: 'lastName',
          title: 'Last Name',
          isRequired: true,
          startWithNewLine: false,
          autocomplete: 'family-name',
          defaultValueExpression: '{lastName}',
        },
      ],
    });

    ComponentCollection.Instance.add({
      // A unique name; must use lowercase
      name: 'language',

      // A display name used in the Toolbox
      title: 'What is your preferred language?',
      iconName: 'icon-question-mark',
      onCreated: (component) => {
        //   component.titleLocation = 'hidden';
      },

      // An array of JSON schemas that configure the nested questions
      elementsJSON: [
        {
          type: 'dropdown',
          name: 'language',
          title: 'What is your preferred language?',
          choices: [
            'English',
            'Spanish',
            'Chinese',
            'Russian',
            'Korean',
            'Japanese',
          ],
          showOtherItem: true,
        },
      ],
    });

    ComponentCollection.Instance.add({
      // A unique name; must use lowercase
      name: 'sex',
      // A display name used in the Toolbox
      title: 'Sex',
      iconName: 'icon-question-mark',
      onCreated: (component) => {
        component.titleLocation = 'hidden';
      },
      // An array of JSON schemas that configure the nested questions
      elementsJSON: [
        {
          type: 'dropdown',
          name: 'sex',
          title: 'Sex',
          choices: ['Male', 'Female', 'Intersex'],
          showOtherItem: false,
        },
      ],
    });

    ComponentCollection.Instance.add({
      // A unique name; must use lowercase
      name: 'pregnant',
      // A display name used in the Toolbox
      title: 'Pregnant',
      iconName: 'icon-question-mark',
      onCreated: (component) => {
        component.titleLocation = 'hidden';
      },
      // An array of JSON schemas that configure the nested questions
      elementsJSON: [
        {
          type: 'toggle-switch',
          name: 'pregnant',
          title: 'Pregnant',
          defaultValue: false,
        },
      ],
    });

    ComponentCollection.Instance.add({
      // A unique name; must use lowercase
      name: 'wrapped-how-did-you-find-us',
      // A display name used in the Toolbox
      title: 'How did you find us?',
      iconName: 'icon-question-mark',
      onCreated: (component) => {
        component.titleLocation = 'hidden';
      },
      elementsJSON: [
        {
          type: 'dropdown',
          name: 'howDidYouFindUs',
          title: 'How did you find us?',

          choices: patientReferralOptions ?? [
            'Billboard',
            'Referred by patient',
            'Drive by',
            'Facebook',
            'Friend',
            'Google',
            'Instagram',
            'Magazine',
            'Other',
            'Other practice',
            'TikTok',
            'Yelp',
          ],
          showOtherItem: true,
          defaultValueExpression: '{howDidYouFindUs}',
        },
        {
          type: 'text',
          name: 'referredByPatient',
          title: 'Who were you referred by?',
          defaultValueExpression: '{referredByPatient}',
          visibleIf: "{composite.howDidYouFindUs} = 'Referred by patient'",
        },
      ],
    });

    ComponentCollection.Instance.add({
      // A unique name; must use lowercase
      name: 'freeform-how-did-you-find-us',
      // A display name used in the Toolbox
      title: 'How did you find us? (Freeform)',
      iconName: 'icon-question-mark',
      onCreated: (component) => {
        component.titleLocation = 'hidden';
      },
      // An array of JSON schemas that configure the nested questions
      elementsJSON: [
        {
          type: 'text',
          name: 'howDidYouFindUs',
          title: 'How did you find us?',
        },
      ],
    });

    ComponentCollection.Instance.add({
      // A unique name; must use lowercase
      name: 'nursing',
      // A display name used in the Toolbox
      title: 'Nursing',
      iconName: 'icon-question-mark',
      onCreated: (component) => {
        component.titleLocation = 'hidden';
      },
      // An array of JSON schemas that configure the nested questions
      elementsJSON: [
        {
          type: 'toggle-switch',
          name: 'nursing',
          title: 'Nursing',
          defaultValue: false,
        },
      ],
    });

    ComponentCollection.Instance.add({
      // A unique name; must use lowercase
      name: 'initials',
      // A display name used in the Toolbox
      title: 'Initials',
      iconName: 'icon-signaturepad',
      onCreated: (component) => {
        component.titleLocation = 'hidden';
      },
      // An array of JSON schemas that configure the nested questions
      elementsJSON: [
        {
          type: 'signaturepad',
          signatureWidth: 100,
          signatureHeight: 100,
        },
      ],
    });

    ComponentCollection.Instance.add({
      // A unique name; must use lowercase
      name: 'gender',
      // A display name used in the Toolbox
      title: 'Gender',
      iconName: 'icon-question-mark',
      onCreated: (component) => {
        component.titleLocation = 'hidden';
      },
      // An array of JSON schemas that configure the nested questions
      elementsJSON: [
        {
          type: 'dropdown',
          name: 'gender',
          title: 'Gender',
          choices: [
            'Male',
            'Female',
            'Non-binary',
            'Genderqueer',
            'Transgender',
            'Genderfluid',
            'Agender',
          ],
          showOtherItem: true,
        },
      ],
    });

    ComponentCollection.Instance.add({
      // A unique name; must use lowercase
      name: 'height',
      // A display name used in the Toolbox
      title: 'Height',
      iconName: 'icon-text',
      // onCreated: (component) => {
      //   component.titleLocation = 'hidden';
      // },
      // An array of JSON schemas that configure the nested questions
      elementsJSON: [
        {
          type: 'text',
          inputType: 'number',
          name: 'feet',
          title: 'Feet',
          isRequired: true,
        },
        {
          type: 'text',
          name: 'inches',
          inputType: 'number',
          title: 'Inches',
          isRequired: true,
          startWithNewLine: false,
        },
      ],
    });

    ComponentCollection.Instance.add({
      // A unique name; must use lowercase
      name: 'patient-name',
      // A display name used in the Toolbox
      title: 'Patient Name',
      iconName: 'icon-person',
      onCreated: (component) => {
        component.titleLocation = 'visible';
      },
      // An array of JSON schemas that configure the nested questions
      elementsJSON: [
        {
          type: 'text',
          defaultValueExpression: '{patientName}',
        },
      ],
    });
  } catch (err) {
    console.log(err);
  }

  function flattenValues(surveyData: any) {
    const flattedObj: any = {};

    for (const key in surveyData) {
      if (typeof surveyData[key] === 'object') {
        const obj = surveyData[key];
        for (const key2 in obj) {
          flattedObj[key2] = obj[key2];
        }
      } else {
        flattedObj[key] = surveyData[key];
      }
    }
    return flattedObj;
  }

  function shouldShowBookingField(params: any[]) {
    if (this.survey.getVariable('leadFormLinkId')) return false;
    const [fieldName] = params;

    const flatValues = flattenValues(this.survey.data);
    return !flatValues[fieldName];
  }

  FunctionFactory.Instance.register(
    'shouldShowBookingField',
    shouldShowBookingField
  );
}
