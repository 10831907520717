/* eslint-disable arrow-body-style */
import { HStack, Icon, Stack, Text } from '@chakra-ui/react';
import { MinimalPatientWallet } from '@webapp/types';
import { toLocalePrice } from '@webapp/util-formatting';
import { BsWallet2 } from 'react-icons/bs';
import ApplyDiscount from './ApplyDiscount';

interface PatientWalletWithModalProps {
  patientWallet: MinimalPatientWallet;
}

export const PatientWalletInput = ({
  patientWallet,
}: PatientWalletWithModalProps) => {
  return (
    <Stack>
      <HStack spacing={2}>
        <Icon as={BsWallet2} />
        <Text fontSize="20px" fontWeight="bold">{`${toLocalePrice(
          patientWallet.balance / 100
        )}`}</Text>
      </HStack>
      <ApplyDiscount
        patientId={patientWallet.patientId}
        isMembershipCoupon
        couponIds={patientWallet.patientWalletCoupons.map(
          ({ coupon }) => coupon.id
        )}
      />
    </Stack>
  );
};

export default PatientWalletInput;
