import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetGoogleAccountsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetGoogleAccountsQuery = { __typename?: 'query_root', googleAccounts: { __typename?: 'GoogleAccountsOutput', googleAccounts: any } };


export const GetGoogleAccountsDocument = gql`
    query GetGoogleAccounts {
  googleAccounts {
    googleAccounts
  }
}
    `;

/**
 * __useGetGoogleAccountsQuery__
 *
 * To run a query within a React component, call `useGetGoogleAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGoogleAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGoogleAccountsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetGoogleAccountsQuery(baseOptions?: Apollo.QueryHookOptions<GetGoogleAccountsQuery, GetGoogleAccountsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGoogleAccountsQuery, GetGoogleAccountsQueryVariables>(GetGoogleAccountsDocument, options);
      }
export function useGetGoogleAccountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGoogleAccountsQuery, GetGoogleAccountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGoogleAccountsQuery, GetGoogleAccountsQueryVariables>(GetGoogleAccountsDocument, options);
        }
export type GetGoogleAccountsQueryHookResult = ReturnType<typeof useGetGoogleAccountsQuery>;
export type GetGoogleAccountsLazyQueryHookResult = ReturnType<typeof useGetGoogleAccountsLazyQuery>;
export type GetGoogleAccountsQueryResult = Apollo.QueryResult<GetGoogleAccountsQuery, GetGoogleAccountsQueryVariables>;