import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { PatientTagsFieldsFragmentDoc } from '../../fragments/patientTagsFields.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type InsertPatientTagsMutationVariables = Types.Exact<{
  patientTags: Types.PatientTags_Insert_Input;
}>;


export type InsertPatientTagsMutation = { __typename?: 'mutation_root', insert_patientTags?: { __typename?: 'patientTags_mutation_response', returning: Array<{ __typename?: 'patientTags', id: any, patientTag: { __typename?: 'patientTag', id: any, title: string, color?: string | null | undefined } }> } | null | undefined };


export const InsertPatientTagsDocument = gql`
    mutation insertPatientTags($patientTags: patientTags_insert_input!) {
  insert_patientTags(
    objects: [$patientTags]
    on_conflict: {constraint: patientTags_patientId_patientTagId_key, update_columns: updatedAt}
  ) {
    returning {
      ...PatientTagsFields
    }
  }
}
    ${PatientTagsFieldsFragmentDoc}`;
export type InsertPatientTagsMutationFn = Apollo.MutationFunction<InsertPatientTagsMutation, InsertPatientTagsMutationVariables>;

/**
 * __useInsertPatientTagsMutation__
 *
 * To run a mutation, you first call `useInsertPatientTagsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertPatientTagsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertPatientTagsMutation, { data, loading, error }] = useInsertPatientTagsMutation({
 *   variables: {
 *      patientTags: // value for 'patientTags'
 *   },
 * });
 */
export function useInsertPatientTagsMutation(baseOptions?: Apollo.MutationHookOptions<InsertPatientTagsMutation, InsertPatientTagsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertPatientTagsMutation, InsertPatientTagsMutationVariables>(InsertPatientTagsDocument, options);
      }
export type InsertPatientTagsMutationHookResult = ReturnType<typeof useInsertPatientTagsMutation>;
export type InsertPatientTagsMutationResult = Apollo.MutationResult<InsertPatientTagsMutation>;
export type InsertPatientTagsMutationOptions = Apollo.BaseMutationOptions<InsertPatientTagsMutation, InsertPatientTagsMutationVariables>;