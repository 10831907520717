import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type UpdateConsumableQuantityAtLocationMutationVariables = Types.Exact<{
  input: Types.UpdateConsumableQuantityAtLocationInput;
}>;


export type UpdateConsumableQuantityAtLocationMutation = { __typename?: 'mutation_root', updateConsumableQuantityAtLocation: { __typename?: 'SuccessAnswer', success: boolean } };


export const UpdateConsumableQuantityAtLocationDocument = gql`
    mutation UpdateConsumableQuantityAtLocation($input: UpdateConsumableQuantityAtLocationInput!) {
  updateConsumableQuantityAtLocation(input: $input) {
    success
  }
}
    `;
export type UpdateConsumableQuantityAtLocationMutationFn = Apollo.MutationFunction<UpdateConsumableQuantityAtLocationMutation, UpdateConsumableQuantityAtLocationMutationVariables>;

/**
 * __useUpdateConsumableQuantityAtLocationMutation__
 *
 * To run a mutation, you first call `useUpdateConsumableQuantityAtLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateConsumableQuantityAtLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateConsumableQuantityAtLocationMutation, { data, loading, error }] = useUpdateConsumableQuantityAtLocationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateConsumableQuantityAtLocationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateConsumableQuantityAtLocationMutation, UpdateConsumableQuantityAtLocationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateConsumableQuantityAtLocationMutation, UpdateConsumableQuantityAtLocationMutationVariables>(UpdateConsumableQuantityAtLocationDocument, options);
      }
export type UpdateConsumableQuantityAtLocationMutationHookResult = ReturnType<typeof useUpdateConsumableQuantityAtLocationMutation>;
export type UpdateConsumableQuantityAtLocationMutationResult = Apollo.MutationResult<UpdateConsumableQuantityAtLocationMutation>;
export type UpdateConsumableQuantityAtLocationMutationOptions = Apollo.BaseMutationOptions<UpdateConsumableQuantityAtLocationMutation, UpdateConsumableQuantityAtLocationMutationVariables>;