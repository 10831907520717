import * as Types from '../../generated/graphql';

import { gql } from '@apollo/client';
import { LeadFormFieldFieldsFragmentDoc } from '../fragments/leadFormFieldFields.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type InsertLeadFormFieldMutationVariables = Types.Exact<{
  leadFormField: Types.LeadFormField_Insert_Input;
}>;


export type InsertLeadFormFieldMutation = { __typename?: 'mutation_root', insert_leadFormField?: { __typename?: 'leadFormField_mutation_response', returning: Array<{ __typename?: 'leadFormField', id: any, validationProps?: any | null | undefined, apiFieldKey: string, label: string, order: any, type: string, workspaceId: any }> } | null | undefined };


export const InsertLeadFormFieldDocument = gql`
    mutation insertLeadFormField($leadFormField: leadFormField_insert_input!) {
  insert_leadFormField(objects: [$leadFormField]) {
    returning {
      ...LeadFormFieldFields
    }
  }
}
    ${LeadFormFieldFieldsFragmentDoc}`;
export type InsertLeadFormFieldMutationFn = Apollo.MutationFunction<InsertLeadFormFieldMutation, InsertLeadFormFieldMutationVariables>;

/**
 * __useInsertLeadFormFieldMutation__
 *
 * To run a mutation, you first call `useInsertLeadFormFieldMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertLeadFormFieldMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertLeadFormFieldMutation, { data, loading, error }] = useInsertLeadFormFieldMutation({
 *   variables: {
 *      leadFormField: // value for 'leadFormField'
 *   },
 * });
 */
export function useInsertLeadFormFieldMutation(baseOptions?: Apollo.MutationHookOptions<InsertLeadFormFieldMutation, InsertLeadFormFieldMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertLeadFormFieldMutation, InsertLeadFormFieldMutationVariables>(InsertLeadFormFieldDocument, options);
      }
export type InsertLeadFormFieldMutationHookResult = ReturnType<typeof useInsertLeadFormFieldMutation>;
export type InsertLeadFormFieldMutationResult = Apollo.MutationResult<InsertLeadFormFieldMutation>;
export type InsertLeadFormFieldMutationOptions = Apollo.BaseMutationOptions<InsertLeadFormFieldMutation, InsertLeadFormFieldMutationVariables>;