import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { FinixTerminalFieldsFragmentDoc } from '../../fragments/finixTerminalFields.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetFinixTerminalQueryVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
}>;


export type GetFinixTerminalQuery = { __typename?: 'query_root', finixTerminal_by_pk?: { __typename?: 'finixTerminal', id: any, locationId: any, finixDeviceId: string, serialNumber: string, status: string, model: string, name?: string | null | undefined, createdAt: any, updatedAt: any, location: { __typename?: 'location', id: any, name: string } } | null | undefined };


export const GetFinixTerminalDocument = gql`
    query GetFinixTerminal($id: uuid!) {
  finixTerminal_by_pk(id: $id) {
    ...FinixTerminalFields
  }
}
    ${FinixTerminalFieldsFragmentDoc}`;

/**
 * __useGetFinixTerminalQuery__
 *
 * To run a query within a React component, call `useGetFinixTerminalQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFinixTerminalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFinixTerminalQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetFinixTerminalQuery(baseOptions: Apollo.QueryHookOptions<GetFinixTerminalQuery, GetFinixTerminalQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFinixTerminalQuery, GetFinixTerminalQueryVariables>(GetFinixTerminalDocument, options);
      }
export function useGetFinixTerminalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFinixTerminalQuery, GetFinixTerminalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFinixTerminalQuery, GetFinixTerminalQueryVariables>(GetFinixTerminalDocument, options);
        }
export type GetFinixTerminalQueryHookResult = ReturnType<typeof useGetFinixTerminalQuery>;
export type GetFinixTerminalLazyQueryHookResult = ReturnType<typeof useGetFinixTerminalLazyQuery>;
export type GetFinixTerminalQueryResult = Apollo.QueryResult<GetFinixTerminalQuery, GetFinixTerminalQueryVariables>;