import { extendTheme } from '@chakra-ui/react';

export const theme = extendTheme({
  components: {
    Tabs: {
      variants: {
        prospyr: {
          tablist: {
            borderColor: '#f0f0f0',
            borderBottomWidth: '1px',
          },
          tab: {
            color: 'gray.700',
            _selected: {
              color: '#008399',
              fontWeight: 'bold',
              boxShadow: 'none',
              backgroundColor: 'white',
            },
            _disabled: {
              cursor: 'not-allowed',
              opacity: 0.4,
            },
          },
        },
      },
    },
  },
});

export default theme;
