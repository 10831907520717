import { Button, FormLabel, Grid, Text } from '@chakra-ui/react';
import Async from 'react-select/async';
import {
  SelectOption,
  FormInputProps,
  SelectFormInputProps,
  ObjectOption,
} from '@webapp/types';
import AsyncCreatableSelect from 'react-select/async-creatable';
import { ActionMeta, SingleValue, StylesConfig } from 'react-select';
import { Controller, useFormContext } from 'react-hook-form';
import { useEffect, useMemo, useState } from 'react';
import { ErrorMessage } from '@hookform/error-message';
import { HiPlus } from 'react-icons/hi';
import camelCase from 'lodash/camelCase';
import { DEFAULT_LABEL_STYLE } from './constants';

export default function SelectFormInput({
  canCreate = false,
  containerGridTemplate = '100px 1fr',
  isEditable = true,
  defaultOption = null,
  disabled = false,
  id,
  label,
  labelAlign,
  labelPosition,
  labelStyle = DEFAULT_LABEL_STYLE,
  loadOptions = (where?: any) => ({
    search: async () => [],
  }),
  loadWhere,
  name,
  placeholder,
  shouldDisplayError = true,
  showCreateOption = false,
  style,
  transform,
  removeIndicator = false,
  minWidth = '200px',
}: Omit<FormInputProps, 'selectProps'> & SelectFormInputProps) {
  const [selectedOption, selectOption] = useState<SelectOption | null>(
    defaultOption ?? null
  );

  const {
    control,
    formState: { errors },
    register,
    setValue,
  } = useFormContext();
  const { onCreateOption, search } = loadOptions({ where: loadWhere });

  useEffect(() => {
    register(name);
  });

  const containerColumns = useMemo(() => {
    if (label) {
      if (labelPosition === 'left') {
        return containerGridTemplate;
      }
    }

    return '1fr';
  }, [label, labelPosition]);

  const handleChange = async (
    option: SingleValue<SelectOption>,
    actionMeta: ActionMeta<SelectOption>
  ) => {
    switch (actionMeta.action) {
      case 'create-option': {
        if (onCreateOption) {
          const newOption = await onCreateOption(actionMeta.option.label);

          if (newOption) {
            setValue(name, newOption);
            selectOption(newOption);
          }
        } else {
          // eslint-disable-next-line no-console
          console.error('Create select option handler not provided');
        }

        break;
      }

      case 'pop-value':
      case 'remove-value': {
        if (actionMeta.removedValue?.value) {
          setValue(name, null);
          selectOption(null);
        }

        break;
      }

      case 'clear': {
        setValue(name, null);
        selectOption(null);
        break;
      }

      default: {
        selectOption(option);
        if (option && transform) {
          setValue(name, transform(option as ObjectOption<any>));
        } else {
          setValue(name, option);
        }

        break;
      }
    }
  };

  const styles: StylesConfig<any> = {
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  };

  let customComponents = {};
  if (removeIndicator) {
    customComponents = {
      IndicatorSeparator: () => null,
    };
  }
  return (
    <Grid
      alignItems={labelAlign}
      className="input-outer"
      gridAutoRows="max-content"
      gridRowGap="6px"
      gridTemplateColumns={containerColumns}
      minWidth={minWidth}
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
      }}
      {...style}
    >
      <Grid gridTemplateColumns="1fr max-content">
        <FormLabel
          className="input-label"
          htmlFor={id ?? name}
          margin="0 0 0 0"
          {...labelStyle}
        >
          {label}
        </FormLabel>
        {showCreateOption && (
          <Button
            colorScheme="teal"
            height="20px"
            minWidth="unset"
            padding="0"
            variant="ghost"
            width="20px"
          >
            <HiPlus />
          </Button>
        )}
      </Grid>

      <Grid className="input-inner" gridRowGap="8px">
        <Controller
          control={control}
          name={name}
          render={({ field: { onBlur, ref, value } }) => {
            if (!isEditable) {
              return <Text>{selectedOption?.label || value?.label}</Text>;
            }
            if (canCreate) {
              return (
                <AsyncCreatableSelect
                  defaultOptions
                  inputId={camelCase(label)}
                  isDisabled={disabled}
                  isClearable={false}
                  loadOptions={search}
                  menuShouldScrollIntoView
                  name={name}
                  onBlur={onBlur}
                  onChange={handleChange}
                  placeholder={placeholder}
                  ref={ref}
                  value={selectedOption || value}
                  menuPortalTarget={document.body}
                  styles={styles}
                  components={customComponents}
                />
              );
            }

            return (
              <Async
                defaultOptions
                inputId={camelCase(label)}
                isDisabled={disabled}
                loadOptions={search}
                name={name}
                onBlur={onBlur}
                onChange={handleChange}
                placeholder={placeholder}
                ref={ref}
                value={selectedOption || value}
                menuPortalTarget={document.body}
                styles={styles}
                components={customComponents}
              />
            );
          }}
        />
        {shouldDisplayError && (
          <ErrorMessage
            errors={errors}
            name={name}
            render={({ message }) => (
              <Text
                className="input-error"
                color="red.500"
                _before={{
                  display: 'inline',
                  content: '"⚠ "',
                }}
              >
                {/* TODO: Errors component does not work well with complex fields */}
                {message ?? 'Invalid'}
              </Text>
            )}
          />
        )}
      </Grid>
    </Grid>
  );
}
