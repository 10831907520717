import type { ICustomQuestionTypeConfiguration } from 'survey-core';
import { setLicenseKey } from 'survey-core';

setLicenseKey(
  'MTMxYTY2ODMtNTQxMC00NWIzLTliMGMtZGMyN2RiMGUzMzgyOzE9MjAyNS0wMi0wOCwyPTIwMjUtMDItMDgsND0yMDI1LTAyLTA4'
);

const medicalInfoElements = [
  {
    name: 'allergyHistory',
    title: 'Allergies',
    elements: [
      {
        type: 'checkbox',
        name: 'allergiesMedicine',
        title: 'Medicine Allergies',
        choices: [
          'Acetaminophen',
          'Aspirin',
          'Ibuprofen',
          'Penicillin',
          'Sulfa',
        ],
        showOtherItem: true,
        otherText: 'Other Medication Allergy (Please List)',
        showNoneItem: true,
        noneText: 'No Known Drug Allergies',
        defaultValueExpression: '{allergiesMedicine}',
      },
      {
        type: 'boolean',
        name: 'allergiesAdhesiveTape',
        title: 'Adhesive Tape',
        defaultValueExpression: '{allergiesAdhesiveTape}',
      },
      {
        type: 'boolean',
        name: 'allergiesBeeStings',
        title: 'Bee Stings',
        startWithNewLine: false,
        defaultValueExpression: '{allergiesBeeStings}',
      },
      {
        type: 'boolean',
        name: 'allergiesIodine',
        title: 'Iodine',
        startWithNewLine: true,
        defaultValueExpression: '{allergiesIodine}',
      },
      {
        type: 'boolean',
        name: 'allergiesLatex',
        title: 'Latex',
        startWithNewLine: false,
        defaultValueExpression: '{allergiesLatex}',
      },
      {
        type: 'boolean',
        name: 'allergiesLidocane',
        title: 'Lidocaine',
        startWithNewLine: true,
        defaultValueExpression: '{allergiesLidocane}',
      },
      {
        type: 'boolean',
        name: 'anesthesiaComplications',
        title: 'Previous complications with anesthesia',
        defaultValueExpression: '{anesthesiaComplications}',
      },
      {
        type: 'text',
        name: 'problematicAnesthesia',
        title: 'Problematic Anesthesia',
        startWithNewLine: false,
        defaultValueExpression: '{problematicAnesthesia}',
      },
      {
        type: 'comment',
        name: 'additionalAllergyInfo',
        title: 'Additional Allergy Info',
        defaultValueExpression: '{additionalAllergyInfo}',
      },
    ],
  },
  {
    name: 'pregnancy',
    title: 'Pregnancy',
    elements: [
      {
        type: 'boolean',
        name: 'planningPregnancy',
        title: 'Are you planning on becoming pregnant?',
        defaultValueExpression: '{planningPregnancy}',
      },
      {
        type: 'boolean',
        name: 'nursing',
        title: 'Are you nursing?',
        startWithNewLine: false,
        defaultValueExpression: '{nursing}',
      },
      {
        type: 'boolean',
        name: 'pregnant',
        title: 'Are you pregnant?',
        startWithNewLine: true,
        defaultValueExpression: '{pregnant}',
      },
      {
        type: 'number',
        name: 'timesPregnant',
        title: 'How many times have you been pregnant?',
        startWithNewLine: false,
        defaultValueExpression: '{timesPregnant}',
      },
      {
        type: 'text',
        name: 'lastMenstrualCycle',
        title: 'When was your last menstrual cycle?',
        inputType: 'date',
        startWithNewLine: false,
        defaultValueExpression: '{lastMenstrualCycle}',
      },
    ],
  },
  {
    name: 'symptomsGroup',
    title: 'Symptoms',
    elements: [
      {
        type: 'checkbox',
        name: 'symptoms',
        title: 'Symptoms - Do you have or have you had',
        choices: [
          'Fever',
          'Cough',
          'Shortness of breath',
          'Headache',
          'Chills',
          'Sore throat',
          'Nausea',
          'Vomiting',
          'Diarrhea',
          'Constipation',
          'Abdominal pain',
        ],
        showOtherItem: true,
        otherText: 'Other (Please Describe)',
        showNoneItem: true,
        noneText: 'No Current Symptoms',
        defaultValueExpression: '{symptoms}',
      },
      {
        type: 'boolean',
        name: 'historyOfColdSores',
        title: 'Do you have a history of cold sores?',
        startWithNewLine: false,
        defaultValueExpression: '{historyOfColdSores}',
      },
      {
        type: 'comment',
        name: 'additionalSymptomInfo',
        title: 'Additional Symptom Info',
        startWithNewLine: true,
        defaultValueExpression: '{additionalSymptomInfo}',
      },
    ],
  },
  {
    name: 'history',
    title: 'Medical History',
    defaultValueExpression: '{history}',
    elements: [
      {
        type: 'checkbox',
        name: 'medicalHistory',
        title: 'Medical History',
        choices: [
          'Heart Disease',
          'High Blood Pressure',
          'Diabetes',
          'Asthma',
          'Cancer',
          'Hepatitis',
          'HIV/AIDS',
          'Pancreatitis',
          'Autoimmune Disorder',
          'Hypothyroidism',
        ],
        showOtherItem: true,
        otherText: 'Other Significant Medical History (Please Describe)',
        showNoneItem: true,
        noneText: 'None of the Above',
        defaultValueExpression: '{medicalHistory}',
      },
      {
        type: 'text',
        name: 'cancerDetails',
        title: 'Type of Cancer (Please specify)',
        visibleIf: "{medicalHistory} contains 'Cancer'",
        startWithNewLine: false,
        defaultValueExpression: '{cancerDetails}',
      },
      {
        type: 'checkbox',
        name: 'familyHistory',
        title: 'Family History',
        startWithNewLine: true,
        choices: [
          'Heart Disease',
          'High Blood Pressure',
          'Diabetes',
          'Asthma',
          'Cancer',
        ],
        showNoneItem: true,
        noneText: 'None of the Above',
        defaultValueExpression: '{familyHistory}',
      },
      {
        type: 'comment',
        name: 'additionalMedicalHistoryInfo',
        title: 'Additional Medical History Info',
        startWithNewLine: true,
        defaultValueExpression: '{additionalMedicalHistoryInfo}',
      },
      {
        type: 'comment',
        name: 'additionalFamilyHistoryInfo',
        title: 'Additional Family History Info',
        startWithNewLine: false,
        defaultValueExpression: '{additionalFamilyHistoryInfo}',
      },
    ],
  },
  {
    name: 'socialHistory',
    title: 'Social History',
    elements: [
      {
        type: 'radiogroup',
        name: 'cigaretteSmokingStatus',
        title: 'What is your cigarette smoking status?',
        choices: ['Former smoker', 'Current smoker', 'Non-smoker'],
        defaultValueExpression: '{cigaretteSmokingStatus}',
      },
      {
        type: 'text',
        name: 'quitSmokingDate',
        title: 'What date did you quit smoking?',
        visibleIf: "{cigaretteSmokingStatus} contains 'Former smoker'",
        inputType: 'date',
        defaultValueExpression: '{quitSmokingDate}',
      },
      {
        type: 'radiogroup',
        name: 'otherSmokingStatus',
        title:
          'Do you currently use other nicotine products such as cigars, pipes, e-cigs, hookah, or chewing tobacco?',
        choices: ['Yes', 'No', 'Formerly'],
        defaultValueExpression: '{otherSmokingStatus}',
      },
      {
        type: 'text',
        name: 'quitOtherNicotineDate',
        title: 'When did you quit using other nicotine products?',
        visibleIf: "{otherSmokingStatus} = 'Formerly'",
        inputType: 'date',
        defaultValueExpression: '{quitOtherNicotineDate}',
      },
      {
        type: 'radiogroup',
        name: 'alcoholConsumption',
        title: 'How often do you consume alcohol?',
        choices: [
          'Never',
          'Monthly or Less',
          '2-4 times per month',
          '2-3 times per week',
          '4 or more times per week',
        ],
        defaultValueExpression: '{alcoholConsumption}',
      },
      {
        type: 'checkbox',
        name: 'recreationalDrugUse',
        title: 'Do you have a history of using recreational street drugs?',
        choices: [
          'Marijuana',
          'Cocaine',
          'Heroin',
          'Methamphetamine',
          'None of the above',
        ],
        defaultValueExpression: '{recreationalDrugUse}',
      },
      {
        type: 'comment',
        name: 'additionalSocialHistoryInfo',
        title: 'Any Other Social Info',
        defaultValueExpression: '{additionalSocialHistoryInfo}',
      },
    ],
  },
  {
    elements: [
      {
        type: 'dropdown',
        choices: ['I', 'II', 'III', 'IV', 'V', 'VI'],
        defaultValueExpression: '{fitzpatrickSkinType}',
        titleLocation: 'hidden',
      },
    ],
    name: 'fitzpatrickSkinType',
    title: 'Fitzpatrick Skin Type',
  },
  {
    name: 'patientProfile',
    title: 'Patient Profile',
    elements: [
      {
        type: 'dropdown',
        name: 'height',
        title: 'Height',
        choices: [
          '6\'11"',
          '6\'10"',
          '6\'9"',
          '6\'8"',
          '6\'7"',
          '6\'6"',
          '6\'5"',
          '6\'4"',
          '6\'3"',
          '6\'2"',
          '6\'1"',
          '6\'"',
          '5\'11"',
          '5\'10"',
          '5\'9"',
          '5\'8"',
          '5\'7"',
          '5\'6"',
          '5\'5"',
          '5\'4"',
          '5\'3"',
          '5\'2"',
          '5\'1"',
          '5\'"',
          '4\'11"',
          '4\'10"',
          '4\'9"',
          '4\'8"',
          '4\'7"',
          '4\'6"',
          '4\'5"',
          '4\'4"',
          '4\'3"',
          '4\'2"',
          '4\'1"',
          '4\'"',
          '3\'11"',
          '3\'10"',
          '3\'9"',
          '3\'8"',
          '3\'7"',
          '3\'6"',
          '3\'5"',
          '3\'4"',
          '3\'3"',
          '3\'2"',
          '3\'1"',
          '3\'"',
        ],
        defaultValueExpression: '{height}',
      },
      {
        type: 'text',
        name: 'weight',
        title: 'Weight (lbs)',
        validators: [
          {
            type: 'numeric',
            text: 'Weight must be a number',
          },
        ],
        startWithNewLine: false,
        defaultValueExpression: '{weight}',
      },
      {
        type: 'dropdown',
        name: 'race',
        title: 'Race',
        choices: [
          'White',
          'Hispanic',
          'Black',
          'Native American',
          'Asian',
          'Pacific Islander',
          'Mixed Race',
          'Prefer Not to Answer',
        ],
        startWithNewLine: true,
        defaultValueExpression: '{race}',
      },
      {
        type: 'dropdown',
        name: 'sex',
        title: 'Sex',
        choices: ['Male', 'Female'],
        defaultValueExpression: '{sex}',
      },
      {
        type: 'dropdown',
        name: 'gender',
        title: 'Gender',
        choices: [
          'Man',
          'Woman',
          'Transgender',
          'Non-binary/Non-conforming',
          'Prefer Not to Answer',
        ],
        startWithNewLine: false,
        defaultValueExpression: '{gender}',
      },

      {
        type: 'text',
        name: 'preferredPharmacy',
        title: 'Preferred Pharmacy',
        defaultValueExpression: '{preferredPharmacy}',
      },
      {
        type: 'text',
        name: 'preferredPharmacyPhoneNumber',
        title: 'Preferred Pharmacy Phone Number',
        startWithNewLine: false,
        defaultValueExpression: '{preferredPharmacyPhoneNumber}',
      },
    ],
  },
  {
    name: 'emergencyContact',
    title: 'Emergency Contact',
    elements: [
      {
        type: 'text',
        name: 'emergencyContactName',
        title: 'Emergency Contact Name',
        isRequired: false,
        defaultValueExpression: '{emergencyContactName}',
      },
      {
        type: 'text',
        name: 'emergencyContactEmail',
        startWithNewLine: true,
        title: 'Emergency Contact Email',
        inputType: 'email',
        isRequired: false,
        defaultValueExpression: '{emergencyContactEmail}',
      },
      {
        type: 'text',
        name: 'emergencyContactPhone',
        startWithNewLine: true,
        title: 'Emergency Contact Phone',
        isRequired: false,
        defaultValueExpression: '{emergencyContactPhone}',
      },
    ],
  },
];

const additionalMedicalInfoElements = [
  {
    name: 'medication',
    title: 'Medications',
    elements: [
      {
        name: 'medication_dosage',
        title: 'Medications',
        type: 'paneldynamic',
        templateElements: [
          {
            type: 'text',
            name: 'medication',
            title: 'Medication',
            defaultValueExpression: '{row.medication}',
          },
          {
            type: 'text',
            inputType: 'date',
            name: 'lastTaken',
            title: 'Last Taken',
            startWithNewLine: false,
          },
          {
            type: 'text',
            name: 'frequency',
            title: 'Frequency',
            startWithNewLine: false,
          },
          {
            type: 'text',
            name: 'dosage',
            title: 'Dosage',
            startWithNewLine: false,
          },
        ],
        defaultValueExpression: '{medication_dosage}',
        confirmDelete: true,
      },
      {
        type: 'boolean',
        name: 'accutane',
        title:
          'Are you taking accutane or have you taken it in the last 6 months?',
      },
      {
        questionJSON: {
          type: 'boolean',
        },
        name: 'aspirinTaken',
        title:
          'Have you taken aspirin or any blood thinners in the past 7 days?',
      },
    ],
  },
  {
    title: 'Patient Surgical History',
    elements: [
      {
        type: 'paneldynamic',
        name: 'pastSurgeries',
        title: 'Past Surgeries',
        templateElements: [
          {
            type: 'text',
            name: 'name',
            title: 'Surgery',
          },
          {
            type: 'text',
            inputType: 'date',
            name: 'surgeryDate',
            title: 'Surgery Date',
            startWithNewLine: false,
          },
        ],
        defaultValueExpression: '{pastSurgeries}',
        confirmDelete: true,
      },
    ],
  },
];

const customFormElements = [
  {
    name: 'medication',
    title: 'Medications',
    elements: [
      {
        type: 'boolean',
        name: 'accutane',
        title:
          'Are you taking accutane or have you taken it in the last 6 months?',
        defaultValueExpression: '{accutane}',
      },
      {
        type: 'boolean',
        name: 'aspirinTaken',
        title:
          'Have you taken aspirin or any blood thinners in the past 7 days?',
        defaultValueExpression: '{aspirinTaken}',
      },
      {
        type: 'boolean',
        name: 'takingAnyMedication',
        title: 'Are you taking any other medication?',
        defaultValueExpression: '{takingAnyMedication}',
      },
      {
        name: 'medication_dosage',
        title: 'Medications',
        type: 'paneldynamic',
        templateElements: [
          {
            type: 'text',
            name: 'medication',
            title: 'Medication',
          },
          {
            type: 'text',
            inputType: 'date',
            name: 'lastTaken',
            title: 'Last Taken',
            startWithNewLine: false,
          },
          {
            type: 'text',
            name: 'frequency',
            title: 'Frequency',
            startWithNewLine: false,
          },
          {
            type: 'text',
            name: 'dosage',
            title: 'Dosage',
            startWithNewLine: false,
          },
        ],
        defaultValueExpression: '{medication_dosage}',
        confirmDelete: true,
        visibleIf: '{composite.takingAnyMedication}',
        requiredIf: '{composite.takingAnyMedication}',
      },
    ],
  },
  {
    name: 'pastSurgeriesGroup',
    title: 'Past Surgeries',
    elements: [
      {
        type: 'boolean',
        name: 'hasHadSurgeries',
        title: 'Have you had any past surgeries or procedures?',
        defaultValueExpression: '{hasHadSurgeries}',
      },
      {
        name: 'pastSurgeries',
        type: 'paneldynamic',
        templateElements: [
          {
            type: 'text',
            name: 'name',
            title: 'Surgery',
          },
          {
            type: 'text',
            inputType: 'date',
            name: 'surgeryDate',
            title: 'Surgery Date',
            startWithNewLine: false,
          },
        ],
        defaultValueExpression: '{pastSurgeries}',
        confirmDelete: true,
        visibleIf: '{composite.hasHadSurgeries}',
        requiredIf: '{composite.hasHadSurgeries}',
      },
    ],
  },
  {
    questionJSON: {
      type: 'text',
      defaultValueExpression: '{firstName}',
    },
    name: 'firstName',
    title: 'First Name',
  },
  {
    questionJSON: {
      type: 'text',
      defaultValueExpression: '{lastName}',
    },
    name: 'lastName',
    title: 'Last Name',
  },
  {
    questionJSON: {
      type: 'text',
      defaultValueExpression: '{email}',
    },
    name: 'email',
    title: 'Email Address',
  },
  {
    name: 'patientAddress',
    title: 'Address',
    elements: [
      {
        type: 'text',
        name: 'addressLine1',
        title: 'Street Address',
        defaultValueExpression: '{addressLine1}',
      },
      {
        type: 'text',
        name: 'addressLine2',
        title: 'Street Adddress (Line 2)',
        defaultValueExpression: '{addressLine2}',
      },
      {
        type: 'text',
        name: 'city',
        title: 'City',
        defaultValueExpression: '{city}',
      },
      {
        type: 'text',
        name: 'state',
        title: 'State',
        startWithNewLine: false,
        defaultValueExpression: '{state}',
      },
      {
        type: 'text',
        name: 'zip',
        title: 'ZIP Code',
        startWithNewLine: false,
        defaultValueExpression: '{zip}',
      },
    ],
    defaultValueExpression: '{patientAddress}',
  },
  {
    questionJSON: {
      type: 'boolean',
      defaultValueExpression: '{openToClinicalTrials}',
    },
    name: 'openToClinicalTrials',
    title: 'Open to Clinical Trials?',
  },
  {
    questionJSON: {
      type: 'boolean',
      defaultValueExpression: '{alleLoyaltyNumber}',
    },
    name: 'alleLoyaltyNumber',
    title: 'Alle Loyalty Member?',
  },
  {
    questionJSON: {
      type: 'boolean',
      defaultValueExpression: '{merzLoyalty}',
    },
    name: 'merzLoyalty',
    title: 'Are you an Xperience member?',
  },
  {
    questionJSON: {
      type: 'boolean',
      defaultValueExpression: '{galdermaLoyalty}',
    },
    name: 'galdermaLoyalty',
    title: 'Are you an Aspire member?',
  },
  {
    questionJSON: {
      type: 'boolean',
      defaultValueExpression: '{evolusLoyalty}',
    },
    name: 'evolusLoyalty',
    title: 'Are you an Evolus Rewards member?',
  },
  {
    name: 'serviceCategoriesOfInterest',
    title: 'Services of Interest',
    questionJSON: {
      name: 'serviceCategoriesOfInterest',
      type: 'tagbox',
      choicesLazyLoadEnabled: true,
      defaultValueExpression: '{serviceCategoriesOfInterest}',
    },
  },
  {
    questionJSON: {
      type: 'text',
      defaultValueExpression: '{placeTypeOfEmployment}',
    },
    name: 'placeTypeOfEmployment',
    title: 'Place/Type of Employment',
  },

  {
    questionJSON: {
      type: 'signaturepad',
    },
    name: 'witnessSignature',
    title: 'Witness Signature (Office Use Only)',
  },
  {
    questionJSON: {
      type: 'dropdown',
      choices: ['Email', 'Phone call', 'Text message'],
      defaultValueExpression: '{preferredContactMethod}',
    },
    name: 'preferredContactMethod',
    title: 'Preferred Contact Method',
  },
  {
    questionJSON: {
      type: 'dropdown',
      choices: ['English', 'Spanish', 'French', 'Chinese'],
      showOtherItem: true,
      defaultValueExpression: '{preferredLanguage}',
    },
    name: 'preferredLanguage',
    title: 'Preferred Language',
  },
  {
    questionJSON: {
      type: 'text',
      defaultValueExpression: '{phoneNumber}',
    },
    name: 'phoneNumber',
    title: 'Phone Number',
  },
  {
    questionJSON: {
      type: 'boolean',
      defaultValueExpression: '{canWeLeaveVoicemail}',
    },
    name: 'canWeLeaveVoicemail',
    title: 'Can we leave a voicemail?',
  },
  {
    questionJSON: {
      type: 'text',
      inputType: 'date',
      defaultValueExpression: '{dob}',
    },
    name: 'dateOfBirth',
    title: 'Date of birth',
  },
  {
    elements: [
      {
        type: 'dropdown',
        name: 'howDidYouFindUs',
        title: 'How did you find us?',
        titleLocation: 'hidden',
        choices: [
          'Google',
          'Facebook',
          'Twitter',
          'Instagram',
          'Referred by patient',
        ],
        showOtherItem: true,
        defaultValueExpression: '{howDidYouFindUs}',
      },
      {
        type: 'text',
        name: 'referredByPatient',
        title: 'Who were you referred by?',
        defaultValueExpression: '{referredByPatient}',
        visibleIf: "{composite.howDidYouFindUs} = 'Referred by patient'",
      },
    ],
    titleLocation: 'hidden',
    name: 'howDidYouFindUsGroup',
    title: 'How did you find us?',
  },
  {
    questionJSON: {
      type: 'text',
      defaultValueExpression: '{driversLicense}',
    },
    name: 'driversLicense',
    title: "Driver's License (State & #)",
  },
  {
    questionJSON: {
      type: 'dropdown',
      choices: ['Single', 'Married', 'Divorced', 'Widowed'],
      showOtherItem: true,
      defaultValueExpression: '{maritalStatus}',
    },
    name: 'maritalStatus',
    title: 'Marital Status',
  },
  {
    questionJSON: {
      type: 'text',
      defaultValueExpression: '{spouseName}',
    },
    name: 'spouseName',
    title: 'Spouse Name',
  },
];

const allElements = medicalInfoElements.concat(customFormElements);

export const medicalInfoElementsAsPanels = [medicalInfoElements[0]]
  .concat(additionalMedicalInfoElements)
  .concat(medicalInfoElements.slice(1))
  .map((element) => {
    if (element.elements) {
      element.elements = element.elements.map((el) => ({
        ...el,
        startWithNewLine: false,
      }));
    }
    return {
      type: 'panel',
      state: 'expanded',
      ...element,
    };
  });

export const elementsAsComponentConfiguration: ICustomQuestionTypeConfiguration[] =
  allElements.map(
    ({
      name,
      title,
      elements: elementsJSON,
      questionJSON,
      defaultValueExpression,
      visibleIf,
      titleLocation,
    }) => ({
      iconName: `icon-${name}`,
      name,
      title,
      elementsJSON,
      ...(questionJSON && { questionJSON }),
      ...(titleLocation && { titleLocation }),
      category: 'Others',
      ...(visibleIf && { visibleIf }),
      ...(defaultValueExpression && { defaultValueExpression }),
    })
  );

export const asMedicalInfoPanel = {
  showQuestionNumbers: 'off',
  questionErrorLocation: 'bottom',
  pages: [
    {
      name: 'page1',
      elements: medicalInfoElementsAsPanels,
    },
  ],
};
