import { ObjectOption } from '@webapp/types';
import { GratuityModal } from '@webapp/ui-composites';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { InvoiceFormValues } from './types';

function calculateRelevantTotal(
  services: ObjectOption<{
    allowGratuity: boolean;
    pricePerUnit: number;
    quantity: number;
  }>[]
) {
  const gratuityEnabledServices = services.filter(
    (service) => service?.object?.allowGratuity
  );

  const total = gratuityEnabledServices.reduce((acc, service) => {
    const price = service?.object?.pricePerUnit || 0;
    const quantity = service?.object?.quantity || 0;
    const serviceTotal = price * quantity;
    return acc + serviceTotal;
  }, 0);

  return total;
}

function InvoiceFormGratuityModal() {
  const { getValues, setValue, watch } = useFormContext<InvoiceFormValues>();

  const gratuity = getValues('gratuity');
  const patient = getValues('patient');

  const [relevantTotal, setRelevantTotal] = useState<number>(0);

  const [patientId, setPatientId] = useState<string | null>(
    patient?.value as string
  );

  const [value, setGratuity] = useState<number>(gratuity ?? 0);

  useEffect(() => {
    const services = getValues('services') || [];
    const total = calculateRelevantTotal(services);
    setRelevantTotal(total);
  }, [getValues, setRelevantTotal]);

  useEffect(() => {
    const sub = watch((formData, options) => {
      const { name } = options;

      if (name?.match('service')) {
        const services = formData.services || [];

        const total = calculateRelevantTotal(
          services as ObjectOption<{
            allowGratuity: boolean;
            pricePerUnit: number;
            quantity: number;
          }>[]
        );

        if (total === 0) {
          setValue('gratuity', 0);
        }

        setRelevantTotal(total);
      } else if (name?.match('patient')) {
        if (formData.patient?.value) {
          setPatientId(formData.patient.value as string);
        } else {
          setPatientId(null);
        }
      } else if (name?.match('gratuity')) {
        setGratuity(formData.gratuity || 0);
      }
    });

    return () => sub.unsubscribe();
  }, [setValue, watch]);

  if (!patientId) {
    return <div></div>;
  }

  return (
    <GratuityModal
      defaultValue={value}
      onSign={(signatureId) => {
        setValue('signatureId', signatureId);
      }}
      onSubmit={(newValue) => {
        setValue('gratuity', newValue);
      }}
      patientId={patientId}
      signOnSubmit
      styles={{
        button: {
          width: '100%',
        },
      }}
      total={relevantTotal}
    />
  );
}

export default InvoiceFormGratuityModal;
