import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { GoogleAccountFieldsFragmentDoc } from '../../fragments/googleAccountFields.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetGoogleAccountQueryVariables = Types.Exact<{
  where: Types.GoogleAccount_Bool_Exp;
}>;


export type GetGoogleAccountQuery = { __typename?: 'query_root', googleAccount: Array<{ __typename?: 'googleAccount', id: any, accountName?: string | null | undefined, name: string, role?: string | null | undefined, permissionLevel?: string | null | undefined, workspaceId: any, userId: any, createdAt: any }> };


export const GetGoogleAccountDocument = gql`
    query getGoogleAccount($where: googleAccount_bool_exp!) {
  googleAccount(where: $where) {
    ...GoogleAccountFields
  }
}
    ${GoogleAccountFieldsFragmentDoc}`;

/**
 * __useGetGoogleAccountQuery__
 *
 * To run a query within a React component, call `useGetGoogleAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGoogleAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGoogleAccountQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetGoogleAccountQuery(baseOptions: Apollo.QueryHookOptions<GetGoogleAccountQuery, GetGoogleAccountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGoogleAccountQuery, GetGoogleAccountQueryVariables>(GetGoogleAccountDocument, options);
      }
export function useGetGoogleAccountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGoogleAccountQuery, GetGoogleAccountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGoogleAccountQuery, GetGoogleAccountQueryVariables>(GetGoogleAccountDocument, options);
        }
export type GetGoogleAccountQueryHookResult = ReturnType<typeof useGetGoogleAccountQuery>;
export type GetGoogleAccountLazyQueryHookResult = ReturnType<typeof useGetGoogleAccountLazyQuery>;
export type GetGoogleAccountQueryResult = Apollo.QueryResult<GetGoogleAccountQuery, GetGoogleAccountQueryVariables>;