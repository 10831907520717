import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type RedeemGiftCardMutationVariables = Types.Exact<{
  redemptionCode: Types.Scalars['String'];
  receiverId: Types.Scalars['uuid'];
}>;


export type RedeemGiftCardMutation = { __typename?: 'mutation_root', update_giftCard?: { __typename?: 'giftCard_mutation_response', returning: Array<{ __typename?: 'giftCard', status?: string | null | undefined }> } | null | undefined };


export const RedeemGiftCardDocument = gql`
    mutation RedeemGiftCard($redemptionCode: String!, $receiverId: uuid!) {
  update_giftCard(
    where: {redemptionCode: {_eq: $redemptionCode}, redeemedOn: {_is_null: true}, receiverId: {_is_null: true}}
    _set: {receiverId: $receiverId, redeemedOn: "now()", status: "REDEEMED"}
  ) {
    returning {
      status
    }
  }
}
    `;
export type RedeemGiftCardMutationFn = Apollo.MutationFunction<RedeemGiftCardMutation, RedeemGiftCardMutationVariables>;

/**
 * __useRedeemGiftCardMutation__
 *
 * To run a mutation, you first call `useRedeemGiftCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRedeemGiftCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [redeemGiftCardMutation, { data, loading, error }] = useRedeemGiftCardMutation({
 *   variables: {
 *      redemptionCode: // value for 'redemptionCode'
 *      receiverId: // value for 'receiverId'
 *   },
 * });
 */
export function useRedeemGiftCardMutation(baseOptions?: Apollo.MutationHookOptions<RedeemGiftCardMutation, RedeemGiftCardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RedeemGiftCardMutation, RedeemGiftCardMutationVariables>(RedeemGiftCardDocument, options);
      }
export type RedeemGiftCardMutationHookResult = ReturnType<typeof useRedeemGiftCardMutation>;
export type RedeemGiftCardMutationResult = Apollo.MutationResult<RedeemGiftCardMutation>;
export type RedeemGiftCardMutationOptions = Apollo.BaseMutationOptions<RedeemGiftCardMutation, RedeemGiftCardMutationVariables>;