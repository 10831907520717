import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { LeadFormFieldsFragmentDoc } from '../../fragments/leadFormFields.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type InsertLeadFormMutationVariables = Types.Exact<{
  leadForm: Types.LeadForm_Insert_Input;
}>;


export type InsertLeadFormMutation = { __typename?: 'mutation_root', insert_leadForm?: { __typename?: 'leadForm_mutation_response', returning: Array<{ __typename?: 'leadForm', id: any, name: string, businessLogo?: string | null | undefined, layout?: string | null | undefined, backgroundColor?: string | null | undefined, formBackground?: string | null | undefined, font?: string | null | undefined, sideBannerImage?: string | null | undefined, surveyJSJson?: any | null | undefined, themeJSON?: any | null | undefined, headerCode?: string | null | undefined, workspaceId: any, lockedToServices?: any | null | undefined, lockedToLocations?: any | null | undefined, successMessage?: string | null | undefined, steps: Array<{ __typename?: 'leadFormStep', id: any, name?: string | null | undefined, order: number, title?: string | null | undefined, subtitle?: string | null | undefined, callToAction?: string | null | undefined, workspaceId: any, type: string, canDelete: boolean, canReOrder: boolean, properties?: any | null | undefined, fields: Array<{ __typename?: 'leadFormField', id: any, validationProps?: any | null | undefined, apiFieldKey: string, label: string, order: any, type: string, workspaceId: any }> }>, workspace: { __typename?: 'workspace', payrixMerchantId?: string | null | undefined, finixMerchantId?: string | null | undefined, name: string, organization?: { __typename?: 'organization', organizationConfiguration?: { __typename?: 'organizationConfiguration', lightLogoSmall: string, whitelabelingEnabled: boolean } | null | undefined } | null | undefined, workspaceConfiguration?: { __typename?: 'workspaceConfiguration', noShowActive?: boolean | null | undefined, noShowFee?: number | null | undefined, patientReferralOptions?: any | null | undefined } | null | undefined } }> } | null | undefined };


export const InsertLeadFormDocument = gql`
    mutation insertLeadForm($leadForm: leadForm_insert_input!) {
  insert_leadForm(objects: [$leadForm]) {
    returning {
      ...LeadFormFields
    }
  }
}
    ${LeadFormFieldsFragmentDoc}`;
export type InsertLeadFormMutationFn = Apollo.MutationFunction<InsertLeadFormMutation, InsertLeadFormMutationVariables>;

/**
 * __useInsertLeadFormMutation__
 *
 * To run a mutation, you first call `useInsertLeadFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertLeadFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertLeadFormMutation, { data, loading, error }] = useInsertLeadFormMutation({
 *   variables: {
 *      leadForm: // value for 'leadForm'
 *   },
 * });
 */
export function useInsertLeadFormMutation(baseOptions?: Apollo.MutationHookOptions<InsertLeadFormMutation, InsertLeadFormMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertLeadFormMutation, InsertLeadFormMutationVariables>(InsertLeadFormDocument, options);
      }
export type InsertLeadFormMutationHookResult = ReturnType<typeof useInsertLeadFormMutation>;
export type InsertLeadFormMutationResult = Apollo.MutationResult<InsertLeadFormMutation>;
export type InsertLeadFormMutationOptions = Apollo.BaseMutationOptions<InsertLeadFormMutation, InsertLeadFormMutationVariables>;