import axios from 'axios';
import toast from 'react-hot-toast';

export async function uploadImageToS3(args: {
  fileContents: File;
  onCompleted: () => void;
  onProgress: (progress: number) => void;
  signedUrl: {
    fields: any[];
    url: string;
  };
}) {
  const { fileContents, onCompleted, onProgress, signedUrl } = args;

  const formData = new FormData();

  Object.entries(signedUrl.fields).forEach(([k, v]) => {
    formData.append(k, v as 'string | Blob');
  });

  formData.append('Content-Type', fileContents.type);
  formData.append('file', fileContents); // The file has be the last element

  try {
    await axios.post(signedUrl.url, formData, {
      headers: { 'Content-Type': fileContents.type },
      onUploadProgress(progressEvent: ProgressEvent) {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );

        onProgress(percentCompleted);
      },
    });

    onCompleted();
  } catch (e) {
    toast.error('Failed to upload image to S3');
  }
}

export default uploadImageToS3;
