import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type SendUnscheduledClinicalMessageMutationVariables = Types.Exact<{
  appointmentId: Types.Scalars['uuid'];
  patientId: Types.Scalars['uuid'];
  messageType: Types.Scalars['String'];
}>;


export type SendUnscheduledClinicalMessageMutation = { __typename?: 'mutation_root', clinicalMessageUnscheduled?: { __typename?: 'SuccessAnswer', success: boolean } | null | undefined };


export const SendUnscheduledClinicalMessageDocument = gql`
    mutation sendUnscheduledClinicalMessage($appointmentId: uuid!, $patientId: uuid!, $messageType: String!) {
  clinicalMessageUnscheduled(
    appointmentId: $appointmentId
    patientId: $patientId
    messageType: $messageType
  ) {
    success
  }
}
    `;
export type SendUnscheduledClinicalMessageMutationFn = Apollo.MutationFunction<SendUnscheduledClinicalMessageMutation, SendUnscheduledClinicalMessageMutationVariables>;

/**
 * __useSendUnscheduledClinicalMessageMutation__
 *
 * To run a mutation, you first call `useSendUnscheduledClinicalMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendUnscheduledClinicalMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendUnscheduledClinicalMessageMutation, { data, loading, error }] = useSendUnscheduledClinicalMessageMutation({
 *   variables: {
 *      appointmentId: // value for 'appointmentId'
 *      patientId: // value for 'patientId'
 *      messageType: // value for 'messageType'
 *   },
 * });
 */
export function useSendUnscheduledClinicalMessageMutation(baseOptions?: Apollo.MutationHookOptions<SendUnscheduledClinicalMessageMutation, SendUnscheduledClinicalMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendUnscheduledClinicalMessageMutation, SendUnscheduledClinicalMessageMutationVariables>(SendUnscheduledClinicalMessageDocument, options);
      }
export type SendUnscheduledClinicalMessageMutationHookResult = ReturnType<typeof useSendUnscheduledClinicalMessageMutation>;
export type SendUnscheduledClinicalMessageMutationResult = Apollo.MutationResult<SendUnscheduledClinicalMessageMutation>;
export type SendUnscheduledClinicalMessageMutationOptions = Apollo.BaseMutationOptions<SendUnscheduledClinicalMessageMutation, SendUnscheduledClinicalMessageMutationVariables>;