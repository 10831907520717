import {
  Box,
  Button,
  chakra,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Stack,
  Switch,
  Textarea,
  IconButton,
} from '@chakra-ui/react';
import {
  Document,
  GetConsentsDocument,
  RecurrenceType_Enum,
  StorageType,
  useConvertDocumentToPdfMutation,
  useDeleteConsentMutation,
  useGetDocumentByPkLazyQuery,
  useInsertConsentMutation,
  useInsertDocumentMutation,
  useUpdateConsentMutation,
  useUpdateServiceConsentsMutation,
} from '@webapp/graphql';
import { useStores } from '@webapp/state-models';
import { DropAndUpload, FormInput } from '@webapp/ui';
import { ConsentFromQuery, useLoadServices } from '@webapp/webapp/hooks';
import { useUploadToS3 } from '@webapp/hooks';
import { useMemo, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { FiTrash } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import { Document as PDFDocument, Page, pdfjs } from 'react-pdf';
import DeleteModal from '../delete-modal';
import './consent-form.css';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

type ConsentInput = {
  title: string;
  description?: string;
  requiredAll?: boolean;
  isPartialMediaConsent?: boolean;
  isFullMediaConsent?: boolean;
  requireWitnessSignature?: boolean;
  requireProviderSignature?: boolean;
  serviceConsents?: string[];
  recurrence: RecurrenceType_Enum;
};

interface ConsentFormProps {
  consent?: ConsentFromQuery;
}

const recurrences = [
  { label: 'Every Appointment', value: RecurrenceType_Enum.Always },
  { label: 'First Appointment Only', value: RecurrenceType_Enum.Never },
  { label: 'Annually', value: RecurrenceType_Enum.Annually },
];

export const ConsentForm = ({ consent }: ConsentFormProps): JSX.Element => {
  const methods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    criteriaMode: 'firstError',
    shouldFocusError: true,
  });

  const [numPages, setNumPages] = useState<number | null>(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
    setNumPages(numPages);
    setPageNumber(1);
  }

  function changePage(offset: number) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const navigate = useNavigate();

  const [filesToUpload, setFilesToUpload] = useState<File[]>([]);

  const { workspace } = useStores();

  const { register, handleSubmit, watch } = methods;

  const { uploadToS3 } = useUploadToS3();

  const [convertToPDF] = useConvertDocumentToPdfMutation({});

  const [getDocument] = useGetDocumentByPkLazyQuery({
    onCompleted: (data) => {
      const document = data.document_by_pk;

      if (document) {
        setDocument(document);
      }
    },
  });

  const [insertConsent] = useInsertConsentMutation();

  const [insertDocument] = useInsertDocumentMutation({
    onCompleted: (data) => {
      const document = data?.insert_document?.returning[0];
      if (document) {
        convertToPDF({
          variables: {
            documentId: document.id,
          },
          onCompleted: () => {
            getDocument({
              variables: {
                id: document.id,
              },
            });
          },
        });
      }
    },
    onError: (error) => {
      toast.error(error.message);
    },
  });

  const [updateConsent] = useUpdateConsentMutation();

  const [deleteConsent] = useDeleteConsentMutation();

  const [updateServiceConsents] = useUpdateServiceConsentsMutation();

  const [document, setDocument] = useState<Partial<Document> | null>(
    consent?.document ?? null
  );

  const [progress, setProgress] = useState(0);

  const refetchQueries = [
    {
      query: GetConsentsDocument,
      variables: {
        where: { workspaceId: { _eq: workspace?.id } },
        limit: 10,
        offset: 0,
        orderBy: [],
      },
    },
  ];

  const serviceSelectProps = useMemo(
    () => ({
      defaultOptions:
        consent?.serviceConsents.map(({ service }) => ({
          label: service.name,
          value: service.id,
        })) ?? [],
      loadOptions: useLoadServices,
      canCreate: false,
      isMulti: true,
    }),
    []
  );

  const onSubmit: SubmitHandler<ConsentInput> = async ({
    title,
    description,
    requiredAll,
    isPartialMediaConsent,
    isFullMediaConsent,
    serviceConsents,
    requireProviderSignature,
    requireWitnessSignature,
    recurrence,
  }) => {
    try {
      if (!document) throw new Error('Document not found');

      const innerData = {
        title,
        description,
        requiredAll,
        isPartialMediaConsent,
        isFullMediaConsent,
        requireProviderSignature,
        requireWitnessSignature,
        documentId: document.id,
        workspaceId: workspace?.id,
        recurrence,
      };

      if (consent) {
        if (!requiredAll && serviceConsents?.length) {
          updateServiceConsents({
            variables: {
              where: {
                consentId: { _eq: consent.id },
              },
              objects: serviceConsents.map((serviceId) => ({
                serviceId,
                consentId: consent.id,
              })),
            },
          });
        }

        await updateConsent({
          variables: {
            id: consent.id,
            set: innerData,
          },
          refetchQueries,
        });
      } else {
        await insertConsent({
          variables: {
            consent: {
              ...innerData,
              ...(!requiredAll &&
                serviceConsents?.length && {
                  serviceConsents: {
                    data: serviceConsents.map((serviceId: string) => ({
                      serviceId,
                    })),
                  },
                }),
            },
          },
          refetchQueries,
        });
      }

      toast.success(
        consent
          ? 'Consent successfully updated'
          : 'Consent successfully created'
      );

      navigate('/settings/forms-and-consents/consents');
    } catch (error) {
      toast.error((error as Error).message);
    }
  };

  const onDrop = async (acceptedFiles: File[]): Promise<void> => {
    try {
      setFilesToUpload(acceptedFiles);

      const file = acceptedFiles[0];

      const filePath = await uploadToS3({
        fileType: file.type,
        fileContents: file,
        filePath: file.name,
        storageType: StorageType.Document,
        randomizeFileName: true,
        onProgress: setProgress,
      });

      if (!filePath) {
        throw new Error('Uploading failed. Please try again.');
      }

      await insertDocument({
        variables: {
          document: {
            filePath,
            workspaceId: workspace?.id,
          },
        },
      });
    } catch (error) {
      toast.error((error as Error).message);
    } finally {
      setProgress(0);
    }
  };

  const onOpen = (): void => {
    setDeleteModalOpen(true);
  };

  const onClose = (): void => {
    setDeleteModalOpen(false);
  };

  const handleDelete = (): void => {
    deleteConsent({
      variables: {
        id: consent?.id,
      },
      onCompleted: () => {
        toast.success('Consent successfully deleted');
        navigate('/settings/forms-and-consents/consents');
      },
      onError: (error) => {
        toast.error((error as Error).message);
      },
      refetchQueries,
    });
  };

  const clearDocument = (): void => {
    setDocument(null);
  };

  return (
    <Stack w="full" position="relative" h="full" paddingX="10">
      <FormProvider {...methods}>
        <chakra.form onSubmit={handleSubmit(onSubmit)} h={'full'}>
          <Stack h={'full'} justifyContent="space-between">
            <HStack
              w={'full'}
              spacing={8}
              alignItems="flex-start"
              justifyContent={'space-between'}
            >
              <Stack minW={300}>
                <FormControl id="title">
                  <FormLabel>Document Title</FormLabel>
                  <Input
                    type="text"
                    width="100%"
                    height="40px"
                    defaultValue={consent?.title}
                    {...register('title', {
                      required: 'Title is required',
                    })}
                  />
                </FormControl>
                <FormControl id="description">
                  <FormLabel>Description (optional)</FormLabel>
                  <Textarea
                    defaultValue={consent?.description ?? undefined}
                    {...register('description')}
                  />
                </FormControl>
                <FormControl display="flex" alignItems="center">
                  <FormLabel htmlFor="isPartialMediaConsent" mb="0">
                    Partial Media Consent
                  </FormLabel>
                  <Switch
                    defaultChecked={consent?.isPartialMediaConsent}
                    {...register('isPartialMediaConsent')}
                    id="isPartialMediaConsent"
                  />
                </FormControl>
                <FormControl display="flex" alignItems="center">
                  <FormLabel htmlFor="isFullMediaConsent" mb="0">
                    Full Media Consent
                  </FormLabel>
                  <Switch
                    defaultChecked={consent?.isFullMediaConsent}
                    {...register('isFullMediaConsent')}
                    id="isFullMediaConsent"
                  />
                </FormControl>
                <FormControl display="flex" alignItems="center">
                  <FormLabel htmlFor="requiredAll" mb="0">
                    Required for all services
                  </FormLabel>
                  <Switch
                    defaultChecked={consent?.requiredAll}
                    {...register('requiredAll')}
                    id="requiredAll"
                  />
                </FormControl>
                <FormControl display="flex" alignItems="center">
                  <FormLabel htmlFor="requireProviderSignature" mb="0">
                    Requires Provider Signature
                  </FormLabel>
                  <Switch
                    defaultChecked={Boolean(consent?.requireProviderSignature)}
                    {...register('requireProviderSignature')}
                    id="requireProviderSignature"
                  />
                </FormControl>
                <FormControl display="flex" alignItems="center">
                  <FormLabel htmlFor="requireWitnessSignature" mb="0">
                    Requires Witness Signature
                  </FormLabel>
                  <Switch
                    defaultChecked={Boolean(consent?.requireWitnessSignature)}
                    {...register('requireWitnessSignature')}
                    id="requireWitnessSignature"
                  />
                </FormControl>
                <Box maxW="350px" pt={8}>
                  <FormInput
                    isEditable={!watch('requiredAll')}
                    label="Required services"
                    multiSelectProps={serviceSelectProps}
                    name="serviceConsents"
                    placeholder="Select service(s)"
                    type="multiselect"
                  />
                </Box>
                <FormInput
                  label="Recurrence"
                  name="recurrence"
                  type="select"
                  defaultValue={
                    consent?.recurrence ?? RecurrenceType_Enum.Always
                  }
                  withOptions={recurrences}
                />
              </Stack>
              <Stack w={'full'}>
                {document ? (
                  <Stack width={600} borderWidth={1} borderColor={'gray.200'}>
                    <HStack
                      width="full"
                      justifyContent="space-between"
                      p={2}
                      borderWidth={1}
                      borderColor={'gray.200'}
                    >
                      <HStack>
                        <Button
                          type="button"
                          disabled={pageNumber <= 1}
                          onClick={previousPage}
                        >
                          Previous
                        </Button>
                        <p>
                          Page {pageNumber || (numPages ? 1 : '--')} of{' '}
                          {numPages || '--'}
                        </p>
                        <Button
                          type="button"
                          disabled={numPages ? pageNumber >= numPages : true}
                          onClick={nextPage}
                        >
                          Next
                        </Button>
                      </HStack>

                      <IconButton
                        width="50px"
                        aria-label="delete-consent"
                        variant="outline"
                        colorScheme="red"
                        icon={<FiTrash />}
                        onClick={clearDocument}
                      />
                    </HStack>
                    <Box maxH={'60vh'} overflowY={'auto'} w={'full'}>
                      {/* @ts-ignore-error React 18 */}
                      <PDFDocument
                        file={document.file?.url}
                        onLoadSuccess={onDocumentLoadSuccess}
                      >
                        {/* @ts-ignore-error React 18 */}
                        <Page pageNumber={pageNumber} width={600} />
                      </PDFDocument>
                    </Box>
                  </Stack>
                ) : (
                  <>
                    <FormLabel>Upload file</FormLabel>
                    <DropAndUpload
                      onDrop={onDrop}
                      progress={progress}
                      accept=".doc,.docx,.pdf"
                    />
                  </>
                )}
              </Stack>
            </HStack>
            <Stack width="100%" backgroundColor="white" alignSelf={'flex-end'}>
              <Divider />
              <Flex justifyContent="space-between">
                <Button type="submit">Cancel</Button>
                <HStack>
                  {consent && (
                    <IconButton
                      aria-label="delete-consent"
                      variant="outline"
                      colorScheme="red"
                      icon={<FiTrash />}
                      onClick={onOpen}
                    />
                  )}
                  <Button type="submit" colorScheme="teal">
                    Save consent
                  </Button>
                </HStack>
              </Flex>
            </Stack>
          </Stack>
        </chakra.form>
      </FormProvider>
      <DeleteModal
        itemName="consent"
        isOpen={deleteModalOpen}
        onClose={onClose}
        handleDelete={handleDelete}
      />
    </Stack>
  );
};

export default ConsentForm;
