import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Text,
  ModalFooter,
  Flex,
  Button,
} from '@chakra-ui/react';

interface OverrideExpiredCouponModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

export const OverrideExpiredCouponModal = ({
  isOpen,
  onClose,
  onConfirm,
}: OverrideExpiredCouponModalProps) => {
  const confirmAndClose = (): void => {
    onConfirm();
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Override expiration date</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text>Are you sure?</Text>
        </ModalBody>
        <ModalFooter>
          <Flex justifyContent="flex-end">
            <Button
              variant="solid"
              colorScheme="teal"
              onClick={confirmAndClose}
            >
              Submit
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default OverrideExpiredCouponModal;
