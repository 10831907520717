import {
  Heading,
  HStack,
  Menu,
  MenuButton,
  IconButton,
} from '@chakra-ui/react';
import { BiArrowBack } from 'react-icons/bi';

import { BsPlusLg } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
/* eslint-disable-next-line */
export interface PageHeaderProps {
  title: string;
  hasBorder?: boolean;
  rightMenu?: React.ReactNode;
  rightButton?: React.ReactNode;
  backButton?: boolean;
  backTo?: string;
  badge?: React.ReactNode;
  padding?: number;
}

export function PageHeader({
  title,
  rightMenu,
  rightButton,
  hasBorder = true,
  backButton,
  badge,
  backTo,
  padding = 4,
}: PageHeaderProps) {
  const navigate = useNavigate();
  return (
    <HStack
      justifyContent={'space-between'}
      w={'full'}
      p={padding}
      borderBottomWidth={hasBorder ? 1 : 0}
      borderColor={'grey.200'}
    >
      <HStack>
        {backButton && (
          <IconButton
            aria-label="Back"
            icon={<BiArrowBack />}
            onClick={() => (backTo ? navigate(backTo) : navigate(-1))}
          />
        )}
        <Heading size={'lg'} color="gray.600">
          {title}
        </Heading>
        {badge}
      </HStack>
      <HStack zIndex={3}>
        {rightMenu && (
          <Menu>
            <MenuButton
              rounded={'full'}
              bgColor={'brand.primary'}
              color={'white'}
              as={IconButton}
              aria-label="Options"
              icon={<BsPlusLg />}
              variant="solid"
            />
            {rightMenu}
          </Menu>
        )}
        {rightButton && <>{rightButton}</>}
      </HStack>
    </HStack>
  );
}

export default PageHeader;
