import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type UpdateServiceCustomFormsMutationVariables = Types.Exact<{
  where: Types.ServiceCustomForm_Bool_Exp;
  objects: Array<Types.ServiceCustomForm_Insert_Input> | Types.ServiceCustomForm_Insert_Input;
}>;


export type UpdateServiceCustomFormsMutation = { __typename?: 'mutation_root', delete_serviceCustomForm?: { __typename?: 'serviceCustomForm_mutation_response', affected_rows: number } | null | undefined, insert_serviceCustomForm?: { __typename?: 'serviceCustomForm_mutation_response', affected_rows: number } | null | undefined };


export const UpdateServiceCustomFormsDocument = gql`
    mutation updateServiceCustomForms($where: serviceCustomForm_bool_exp!, $objects: [serviceCustomForm_insert_input!]!) {
  delete_serviceCustomForm(where: $where) {
    affected_rows
  }
  insert_serviceCustomForm(objects: $objects) {
    affected_rows
  }
}
    `;
export type UpdateServiceCustomFormsMutationFn = Apollo.MutationFunction<UpdateServiceCustomFormsMutation, UpdateServiceCustomFormsMutationVariables>;

/**
 * __useUpdateServiceCustomFormsMutation__
 *
 * To run a mutation, you first call `useUpdateServiceCustomFormsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateServiceCustomFormsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateServiceCustomFormsMutation, { data, loading, error }] = useUpdateServiceCustomFormsMutation({
 *   variables: {
 *      where: // value for 'where'
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useUpdateServiceCustomFormsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateServiceCustomFormsMutation, UpdateServiceCustomFormsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateServiceCustomFormsMutation, UpdateServiceCustomFormsMutationVariables>(UpdateServiceCustomFormsDocument, options);
      }
export type UpdateServiceCustomFormsMutationHookResult = ReturnType<typeof useUpdateServiceCustomFormsMutation>;
export type UpdateServiceCustomFormsMutationResult = Apollo.MutationResult<UpdateServiceCustomFormsMutation>;
export type UpdateServiceCustomFormsMutationOptions = Apollo.BaseMutationOptions<UpdateServiceCustomFormsMutation, UpdateServiceCustomFormsMutationVariables>;