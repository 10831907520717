import { Flex, FormLabel, Text } from '@chakra-ui/react';
import { ErrorMessage } from '@hookform/error-message';
import { FormInputProps } from '@webapp/types';
import { PropsWithChildren, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { DEFAULT_LABEL_STYLE } from '../constants';

function FormInputWrapper({
  children,
  defaultValue,
  id,
  isEditable = true,
  label,
  labelAlign,
  labelStyle = DEFAULT_LABEL_STYLE,
  labelSubText,
  name,
}: PropsWithChildren<FormInputProps>) {
  const {
    formState: { errors },
    getValues,
    setValue,
  } = useFormContext();

  const inputId = id ?? name;

  useEffect(() => {
    if (defaultValue) {
      setValue(name, defaultValue);
    }
  }, [defaultValue, name, setValue]);

  return (
    <Flex
      alignItems={labelAlign}
      className="form-input-wrapper"
      flexDirection="column"
    >
      <Flex
        className="input-label-wrapper"
        flexDirection="column"
        margin="0 0 6px 0"
      >
        {Boolean(label) && (
          <FormLabel
            className="input-label"
            htmlFor={inputId}
            margin="0 0 0 0"
            {...labelStyle}
          >
            {label}
          </FormLabel>
        )}
        {Boolean(label && labelSubText) && (
          <Text color="gray.400" fontSize="11px">
            {labelSubText}
          </Text>
        )}
      </Flex>

      {!isEditable && (
        <Text color="#525257">{defaultValue || getValues(name)}</Text>
      )}
      {isEditable && (
        <Flex className="input-inner" margin="0 0 8px 0">
          {children}
        </Flex>
      )}
      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => (
          <Text
            className="input-error"
            color="red.500"
            _before={{
              display: 'inline',
              content: '"⚠ "',
            }}
          >
            {message}
          </Text>
        )}
      />
    </Flex>
  );
}

export default FormInputWrapper;
