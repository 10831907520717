import dayjs from 'dayjs';
import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import DateRangePicker from '../DateRangePicker/DateRangePicker';
export const FILTER_DATE_FORMAT = 'YYYY-MM-DD';

export function DateRangeFilter({
  filterKey,
  placeholder,
}: {
  filterKey: string;
  placeholder?: string;
}) {
  const location = useLocation();
  const navigate = useNavigate();

  const urlParams = new URLSearchParams(location.search);
  const [startKey, endKey] = filterKey.split(':');
  const paramStartDate = urlParams.get(startKey);
  const paramEndDate = urlParams.get(endKey);
  const [currentRange, setCurrentRange] = useState<any | null>(() => {
    const start = dayjs(paramStartDate).isValid()
      ? dayjs(paramStartDate).format('YYYY-MM-DDTHH:MM')
      : undefined;
    const end = dayjs(paramEndDate).isValid()
      ? dayjs(paramEndDate).format('YYYY-MM-DDTHH:MM')
      : undefined;
    return [start, end];
  });

  const onChangeFilter = useCallback((selectedRange: any | null) => {
    setCurrentRange(selectedRange);
  }, []);

  const [startDate, endDate] = currentRange;
  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    if (startDate) {
      urlParams.set(startKey, dayjs(startDate).format(FILTER_DATE_FORMAT));
    } else if (urlParams.get(startKey)) {
      urlParams.delete(startKey);
    }

    if (endDate) {
      urlParams.set(endKey, dayjs(endDate).format(FILTER_DATE_FORMAT));
    } else if (urlParams.get(endKey)) {
      urlParams.delete(endKey);
    }
    navigate({
      search: urlParams.toString(),
    });
  }, [startDate, endDate, location.search]);
  return (
    <DateRangePicker
      currentRange={currentRange ?? undefined}
      onChange={onChangeFilter}
      placeholder={placeholder}
    />
  );
}
export default DateRangeFilter;
