import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type SearchDocumentsQueryVariables = Types.Exact<{
  searchValue: Types.Scalars['String'];
  documentType: Types.Scalars['String'];
  limit?: Types.Maybe<Types.Scalars['Int']>;
}>;


export type SearchDocumentsQuery = { __typename?: 'query_root', document: Array<{ __typename?: 'document', id: any, title?: string | null | undefined, filePath: string, documentType: string, file?: { __typename?: 'FileOnlyURL', url: string } | null | undefined }> };


export const SearchDocumentsDocument = gql`
    query searchDocuments($searchValue: String!, $documentType: String!, $limit: Int) {
  document(
    where: {title: {_ilike: $searchValue}, documentType: {_eq: $documentType}}
  ) {
    id
    title
    filePath
    file {
      url
    }
    documentType
  }
}
    `;

/**
 * __useSearchDocumentsQuery__
 *
 * To run a query within a React component, call `useSearchDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchDocumentsQuery({
 *   variables: {
 *      searchValue: // value for 'searchValue'
 *      documentType: // value for 'documentType'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useSearchDocumentsQuery(baseOptions: Apollo.QueryHookOptions<SearchDocumentsQuery, SearchDocumentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchDocumentsQuery, SearchDocumentsQueryVariables>(SearchDocumentsDocument, options);
      }
export function useSearchDocumentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchDocumentsQuery, SearchDocumentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchDocumentsQuery, SearchDocumentsQueryVariables>(SearchDocumentsDocument, options);
        }
export type SearchDocumentsQueryHookResult = ReturnType<typeof useSearchDocumentsQuery>;
export type SearchDocumentsLazyQueryHookResult = ReturnType<typeof useSearchDocumentsLazyQuery>;
export type SearchDocumentsQueryResult = Apollo.QueryResult<SearchDocumentsQuery, SearchDocumentsQueryVariables>;