import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { FinixTerminalFieldsFragmentDoc } from '../../fragments/finixTerminalFields.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type InsertFinixTerminalMutationVariables = Types.Exact<{
  finixTerminal: Types.FinixTerminal_Insert_Input;
}>;


export type InsertFinixTerminalMutation = { __typename?: 'mutation_root', insert_finixTerminal?: { __typename?: 'finixTerminal_mutation_response', returning: Array<{ __typename?: 'finixTerminal', id: any, locationId: any, finixDeviceId: string, serialNumber: string, status: string, model: string, name?: string | null | undefined, createdAt: any, updatedAt: any, location: { __typename?: 'location', id: any, name: string } }> } | null | undefined };


export const InsertFinixTerminalDocument = gql`
    mutation insertFinixTerminal($finixTerminal: finixTerminal_insert_input!) {
  insert_finixTerminal(objects: [$finixTerminal]) {
    returning {
      ...FinixTerminalFields
    }
  }
}
    ${FinixTerminalFieldsFragmentDoc}`;
export type InsertFinixTerminalMutationFn = Apollo.MutationFunction<InsertFinixTerminalMutation, InsertFinixTerminalMutationVariables>;

/**
 * __useInsertFinixTerminalMutation__
 *
 * To run a mutation, you first call `useInsertFinixTerminalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertFinixTerminalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertFinixTerminalMutation, { data, loading, error }] = useInsertFinixTerminalMutation({
 *   variables: {
 *      finixTerminal: // value for 'finixTerminal'
 *   },
 * });
 */
export function useInsertFinixTerminalMutation(baseOptions?: Apollo.MutationHookOptions<InsertFinixTerminalMutation, InsertFinixTerminalMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertFinixTerminalMutation, InsertFinixTerminalMutationVariables>(InsertFinixTerminalDocument, options);
      }
export type InsertFinixTerminalMutationHookResult = ReturnType<typeof useInsertFinixTerminalMutation>;
export type InsertFinixTerminalMutationResult = Apollo.MutationResult<InsertFinixTerminalMutation>;
export type InsertFinixTerminalMutationOptions = Apollo.BaseMutationOptions<InsertFinixTerminalMutation, InsertFinixTerminalMutationVariables>;