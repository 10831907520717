import { StackProps } from '@chakra-ui/react';
import { EditorOptions, JSONContent } from '@tiptap/react';

export enum SmartTextEditorSize {
  LARGE = 'LARGE',
  SMALL = 'SMALL',
}

export enum SmartTextEditorToolbarVersion {
  DEFAULT = 'DEFAULT',
  SMART_NOTE = 'SMART_NOTE',
  MINIMAL = 'MINIMAL',
}

export interface MergeTagContext {
  [key: string]: any;
}

export interface SmartTextEditorProps {
  content?: string;
  editable?: boolean;
  height?: string;
  hideSave?: boolean;
  editorConfig?: Partial<EditorOptions>;
  onSaveCallback?: (content: string) => void;
  containerStyle?: StackProps;
  innerContainerStyle?: StackProps;
  size?: SmartTextEditorSize;
  toolbarVersion?: SmartTextEditorToolbarVersion;
  mergeTagContext?: MergeTagContext;
  creatingTemplate?: boolean;
  patientId?: string;
  appointmentId?: string;
  workspaceId?: string;
  onUpdate?: (content: JSONContent) => void;
}

export type SmartTextEditorHandle = {
  saveContent: () => string;
  getEditorJSON: (json: any) => void;
};
