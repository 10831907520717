import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetPinQueryVariables = Types.Exact<{
  where?: Types.Maybe<Types.Pin_Bool_Exp>;
}>;


export type GetPinQuery = { __typename?: 'query_root', pin: Array<{ __typename?: 'pin', id: any }> };


export const GetPinDocument = gql`
    query GetPin($where: pin_bool_exp) {
  pin(where: $where) {
    id
  }
}
    `;

/**
 * __useGetPinQuery__
 *
 * To run a query within a React component, call `useGetPinQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPinQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPinQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetPinQuery(baseOptions?: Apollo.QueryHookOptions<GetPinQuery, GetPinQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPinQuery, GetPinQueryVariables>(GetPinDocument, options);
      }
export function useGetPinLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPinQuery, GetPinQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPinQuery, GetPinQueryVariables>(GetPinDocument, options);
        }
export type GetPinQueryHookResult = ReturnType<typeof useGetPinQuery>;
export type GetPinLazyQueryHookResult = ReturnType<typeof useGetPinLazyQuery>;
export type GetPinQueryResult = Apollo.QueryResult<GetPinQuery, GetPinQueryVariables>;