import {
  chakra,
  Button,
  Image,
  Skeleton,
  Tag,
  Text,
  Wrap,
  WrapItem,
} from '@chakra-ui/react';
import { SimpleVideo } from '@webapp/ui';
import dayjs from 'dayjs';
import { useCallback } from 'react';
import { HiDownload } from 'react-icons/hi';
import { PhotoGridPhoto } from './photo-grid-photo';

interface PhotoCardProps {
  onPhotoClick?: (photo: PhotoGridPhoto) => void;
  photo: PhotoGridPhoto;
  shouldDisplayTags: boolean;
}

function PhotoGridCard({
  onPhotoClick = () => undefined,
  photo,
  shouldDisplayTags,
}: PhotoCardProps) {
  const handleClick = useCallback(() => {
    onPhotoClick(photo);
  }, [onPhotoClick, photo]);

  return (
    <chakra.div
      cursor={'pointer'}
      display="grid"
      gridAutoColumns="100%"
      gridRowGap="12px"
      gridTemplateRows="188px max-content"
      key={photo.id}
      onClick={handleClick}
      rounded="md"
    >
      <chakra.div
        className="photo-image-container"
        maxHeight="188px"
        overflow="hidden"
        position="relative"
      >
        <Button
          as="a"
          background="rgba(28, 28, 28, .16)"
          download={true}
          href={photo.file?.url || undefined}
          onClick={(e) => {
            e.stopPropagation();
          }}
          padding="0"
          position="absolute"
          top="5px"
          right="5px"
          zIndex="2"
        >
          <HiDownload color="var(--chakra-grey-200)" />
        </Button>
        {photo?.mimeType && photo?.mimeType.includes('video') ? (
          <SimpleVideo src={photo.file?.url} />
        ) : (
          <Image
            className="ph-no-capture fs-exclude"
            borderRadius="8px"
            fallback={
              <Skeleton borderRadius="8px" width="full" minHeight="100%" />
            }
            minHeight="100%"
            objectFit="cover"
            src={photo.file?.thumbnailUrl || photo.file?.url}
            userSelect="none"
            width="full"
            _hover={{
              shadow: 'lg',
              opacity: 0.75,
            }}
          />
        )}
      </chakra.div>
      <chakra.div>
        <Text
          color="#000"
          fontWeight="400"
          letterSpacing="0.03em"
          size="14px"
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
        >
          {photo.file?.filePath}
        </Text>
        <Text
          color="#6C6C72"
          fontWeight="400"
          letterSpacing="0.03em"
          size="12px"
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
        >
          {dayjs(photo.mediaDate).format('MMM D, YYYY')}
        </Text>
        {shouldDisplayTags && (
          <chakra.div display="grid" marginTop="15px" width="100%">
            <Wrap>
              {photo.photoMediaTags.map((mediaTag, index) => (
                <WrapItem
                  key={`media-tag-${photo.id}-${mediaTag.mediaTag.title}`}
                >
                  <Tag size={'sm'}>{mediaTag.mediaTag.title}</Tag>
                </WrapItem>
              ))}
            </Wrap>
          </chakra.div>
        )}
      </chakra.div>
    </chakra.div>
  );
}

export default PhotoGridCard;
