import * as Types from '../../generated/graphql';

import { gql } from '@apollo/client';
export type VitalsFieldsFragment = { __typename?: 'vitals', id: any, temperature: any, pulse: number, bloodPressure?: any | null | undefined, patientWorkspaceId: any, createdAt: any, spo2?: any | null | undefined, updatedAt: any, time?: any | null | undefined };

export const VitalsFieldsFragmentDoc = gql`
    fragment VitalsFields on vitals {
  id
  temperature
  pulse
  bloodPressure
  patientWorkspaceId
  createdAt
  spo2
  updatedAt
  time
}
    `;