import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type CreateFinixDeviceMutationVariables = Types.Exact<{
  name: Types.Scalars['String'];
  description: Types.Scalars['String'];
  model: Types.Scalars['String'];
  serialNumber: Types.Scalars['String'];
}>;


export type CreateFinixDeviceMutation = { __typename?: 'mutation_root', createFinixDevice?: { __typename?: 'CreateFinixDeviceOutput', finixDeviceId: string } | null | undefined };


export const CreateFinixDeviceDocument = gql`
    mutation CreateFinixDevice($name: String!, $description: String!, $model: String!, $serialNumber: String!) {
  createFinixDevice(
    name: $name
    description: $description
    model: $model
    serialNumber: $serialNumber
  ) {
    finixDeviceId
  }
}
    `;
export type CreateFinixDeviceMutationFn = Apollo.MutationFunction<CreateFinixDeviceMutation, CreateFinixDeviceMutationVariables>;

/**
 * __useCreateFinixDeviceMutation__
 *
 * To run a mutation, you first call `useCreateFinixDeviceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFinixDeviceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFinixDeviceMutation, { data, loading, error }] = useCreateFinixDeviceMutation({
 *   variables: {
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      model: // value for 'model'
 *      serialNumber: // value for 'serialNumber'
 *   },
 * });
 */
export function useCreateFinixDeviceMutation(baseOptions?: Apollo.MutationHookOptions<CreateFinixDeviceMutation, CreateFinixDeviceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateFinixDeviceMutation, CreateFinixDeviceMutationVariables>(CreateFinixDeviceDocument, options);
      }
export type CreateFinixDeviceMutationHookResult = ReturnType<typeof useCreateFinixDeviceMutation>;
export type CreateFinixDeviceMutationResult = Apollo.MutationResult<CreateFinixDeviceMutation>;
export type CreateFinixDeviceMutationOptions = Apollo.BaseMutationOptions<CreateFinixDeviceMutation, CreateFinixDeviceMutationVariables>;