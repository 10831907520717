import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { TaskFieldsFragmentDoc } from '../../fragments/taskFields.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type InsertOneTaskMutationVariables = Types.Exact<{
  object: Types.Task_Insert_Input;
}>;


export type InsertOneTaskMutation = { __typename?: 'mutation_root', insert_task_one?: { __typename?: 'task', description?: string | null | undefined, dueDate: any, id: any, status?: string | null | undefined, comments: Array<{ __typename?: 'taskComment', createdAt: any, comment: string, id: any, updatedAt: any, author: { __typename?: 'user', firstName: string, id: any, lastName: string, title?: string | null | undefined } }>, provider?: { __typename?: 'provider', firstName: string, id: any, lastName: string, profilePicture?: string | null | undefined, title?: string | null | undefined } | null | undefined, patient?: { __typename?: 'patient', firstName: string, id: any, lastName: string, profilePicture?: { __typename?: 'photo', file?: { __typename?: 'FilePayload', thumbnailUrl?: string | null | undefined } | null | undefined } | null | undefined } | null | undefined, user?: { __typename?: 'user', firstName: string, id: any, lastName: string, profilePicture?: string | null | undefined, title?: string | null | undefined } | null | undefined } | null | undefined };


export const InsertOneTaskDocument = gql`
    mutation InsertOneTask($object: task_insert_input!) {
  insert_task_one(object: $object) {
    ...TaskFields
  }
}
    ${TaskFieldsFragmentDoc}`;
export type InsertOneTaskMutationFn = Apollo.MutationFunction<InsertOneTaskMutation, InsertOneTaskMutationVariables>;

/**
 * __useInsertOneTaskMutation__
 *
 * To run a mutation, you first call `useInsertOneTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertOneTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertOneTaskMutation, { data, loading, error }] = useInsertOneTaskMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertOneTaskMutation(baseOptions?: Apollo.MutationHookOptions<InsertOneTaskMutation, InsertOneTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertOneTaskMutation, InsertOneTaskMutationVariables>(InsertOneTaskDocument, options);
      }
export type InsertOneTaskMutationHookResult = ReturnType<typeof useInsertOneTaskMutation>;
export type InsertOneTaskMutationResult = Apollo.MutationResult<InsertOneTaskMutation>;
export type InsertOneTaskMutationOptions = Apollo.BaseMutationOptions<InsertOneTaskMutation, InsertOneTaskMutationVariables>;