import { Flex, Grid, Image } from '@chakra-ui/react';
import { HiOutlinePhotograph } from 'react-icons/hi';

export interface PreviewProps {
  borderRadius?: string;
  files: (File & { preview: string })[];
  defaultValue?: string;
  numOfPreviewsToRender?: number;
  shouldDisplayEmptyPreview: boolean;
}

export function Preview({
  component: Component,
  ...props
}: PreviewProps & { component?: React.ComponentType<PreviewProps> }) {
  const {
    borderRadius = 'full',
    files,
    numOfPreviewsToRender = 1,
    shouldDisplayEmptyPreview,
    defaultValue,
  } = props;

  const previewsToRender = files.slice(0, numOfPreviewsToRender);

  return (
    <>
      {Component && <Component {...props} />}
      {!Component && (
        <Grid>
          {shouldDisplayEmptyPreview &&
            Boolean(!previewsToRender.length) &&
            !defaultValue && (
              <Flex
                alignItems="center"
                background="#eee"
                borderRadius={borderRadius}
                height="100px"
                justifyContent="center"
                width="100px"
              >
                <HiOutlinePhotograph color="#fff" size="64px" />
              </Flex>
            )}
          {defaultValue && previewsToRender.length === 0 && (
            <Image
              className="ph-no-capture fs-exclude"
              objectFit="cover"
              borderRadius={borderRadius}
              boxSize="100px"
              src={defaultValue}
              alt=""
            />
          )}
          {previewsToRender.map((file) => (
            <Image
              className="ph-no-capture fs-exclude"
              objectFit="cover"
              borderRadius={borderRadius}
              boxSize="100px"
              src={file.preview || defaultValue}
              alt=""
              key={`image-preview-${file.name}`}
            />
          ))}
        </Grid>
      )}
    </>
  );
}

export default Preview;
