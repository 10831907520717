/* eslint-disable import/no-cycle */
/* eslint-disable @nx/enforce-module-boundaries */
import React from 'react';
import { Flex, Box, VStack, Heading, Text, css, Image } from '@chakra-ui/react';
import { Logo } from '@webapp/ui';

import { useInvoicePreviewContext } from './InvoicePreviewContext';

export const InvoicePreviewHeader = React.memo(() => {
  const { invoice } = useInvoicePreviewContext();

  return (
    <Flex
      justify="space-between"
      align="center"
      flexDirection={{ base: 'column', sm: 'row' }}
      css={css({ '@media print': { flexDirection: 'row' } })}
      gap={4}
    >
      <Box
        width={{ base: '100%', sm: '200px' }}
        height="80px"
        display="flex"
        alignItems="center"
        justifyContent={{ base: 'center', sm: 'flex-start' }}
        css={css({ '@media print': { justifyContent: 'flex-start' } })}
      >
        {invoice?.workspace?.logo ||
        invoice?.workspace?.workspaceConfiguration?.logo ? (
          <Image
            src={
              invoice?.workspace?.logo ||
              invoice?.workspace?.workspaceConfiguration?.logo
            }
            maxH="60px"
            w="auto"
          />
        ) : (
          <Logo maxH="60px" w="auto" />
        )}
      </Box>
      <VStack
        align={{ base: 'center', sm: 'flex-end' }}
        spacing={1}
        css={css({ '@media print': { alignItems: 'flex-end' } })}
      >
        <Heading
          size={{ base: 'md', sm: 'lg', md: 'xl' }}
          css={css({ '@media print': { fontSize: 'lg' } })}
        >
          {invoice.status === 'QUOTE' ? 'Quote' : 'Invoice'}
        </Heading>
        <Text
          fontSize={{ base: 'sm', md: 'md' }}
          color="gray.600"
          css={css({ '@media print': { fontSize: 'md' } })}
        >
          #{invoice.invoiceNumber}
        </Text>
      </VStack>
    </Flex>
  );
});

export default InvoicePreviewHeader;
