import * as Types from '../../generated/graphql';

import { gql } from '@apollo/client';
export type PhotoFieldsFragment = { __typename?: 'photo', id: any, workspaceId: any, patientId?: any | null | undefined, mediaDate?: any | null | undefined, mediaType?: string | null | undefined, mimeType?: string | null | undefined, hasThumbnail?: boolean | null | undefined, isSharedWithPatient: boolean, partialMediaConsent?: boolean | null | undefined, fullMediaConsent?: boolean | null | undefined, patient?: { __typename?: 'patient', firstName: string, id: any, lastName: string } | null | undefined, file?: { __typename?: 'FilePayload', filePath: string, url: string, thumbnailUrl?: string | null | undefined } | null | undefined, photoMediaTags: Array<{ __typename?: 'photoMediaTag', id: any, mediaTag: { __typename?: 'mediaTag', id: any, title: string } }>, uploader?: { __typename?: 'user', firstName: string, id: any, lastName: string } | null | undefined };

export const PhotoFieldsFragmentDoc = gql`
    fragment PhotoFields on photo {
  id
  workspaceId
  patientId
  patient {
    firstName
    id
    lastName
  }
  mediaDate
  mediaType
  mimeType
  hasThumbnail
  isSharedWithPatient
  partialMediaConsent
  fullMediaConsent
  file {
    filePath
    url
    thumbnailUrl
  }
  photoMediaTags {
    id
    mediaTag {
      id
      title
    }
  }
  uploader {
    firstName
    id
    lastName
  }
}
    `;