import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetInverseAvailabilityMutationVariables = Types.Exact<{
  startDate: Types.Scalars['String'];
  endDate: Types.Scalars['String'];
  locationsFilter?: Types.Maybe<Types.Scalars['String']>;
  providersFilter?: Types.Maybe<Types.Scalars['String']>;
}>;


export type GetInverseAvailabilityMutation = { __typename?: 'mutation_root', getInverseAvailability?: { __typename?: 'InverseAvailabilityOutput', inverseAvailability?: Array<{ __typename?: 'InverseAvailabilityRange', end: string, resource: string, start: string } | null | undefined> | null | undefined } | null | undefined };


export const GetInverseAvailabilityDocument = gql`
    mutation GetInverseAvailability($startDate: String!, $endDate: String!, $locationsFilter: String, $providersFilter: String) {
  getInverseAvailability(
    startDate: $startDate
    endDate: $endDate
    locationsFilter: $locationsFilter
    providersFilter: $providersFilter
  ) {
    inverseAvailability {
      end
      resource
      start
    }
  }
}
    `;
export type GetInverseAvailabilityMutationFn = Apollo.MutationFunction<GetInverseAvailabilityMutation, GetInverseAvailabilityMutationVariables>;

/**
 * __useGetInverseAvailabilityMutation__
 *
 * To run a mutation, you first call `useGetInverseAvailabilityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetInverseAvailabilityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getInverseAvailabilityMutation, { data, loading, error }] = useGetInverseAvailabilityMutation({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      locationsFilter: // value for 'locationsFilter'
 *      providersFilter: // value for 'providersFilter'
 *   },
 * });
 */
export function useGetInverseAvailabilityMutation(baseOptions?: Apollo.MutationHookOptions<GetInverseAvailabilityMutation, GetInverseAvailabilityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GetInverseAvailabilityMutation, GetInverseAvailabilityMutationVariables>(GetInverseAvailabilityDocument, options);
      }
export type GetInverseAvailabilityMutationHookResult = ReturnType<typeof useGetInverseAvailabilityMutation>;
export type GetInverseAvailabilityMutationResult = Apollo.MutationResult<GetInverseAvailabilityMutation>;
export type GetInverseAvailabilityMutationOptions = Apollo.BaseMutationOptions<GetInverseAvailabilityMutation, GetInverseAvailabilityMutationVariables>;