import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetPatientSystemNoticesAggregateQueryVariables = Types.Exact<{
  where: Types.PatientSystemNotice_Bool_Exp;
}>;


export type GetPatientSystemNoticesAggregateQuery = { __typename?: 'query_root', patientSystemNotice_aggregate: { __typename?: 'patientSystemNotice_aggregate', aggregate?: { __typename?: 'patientSystemNotice_aggregate_fields', count: number } | null | undefined } };


export const GetPatientSystemNoticesAggregateDocument = gql`
    query GetPatientSystemNoticesAggregate($where: patientSystemNotice_bool_exp!) {
  patientSystemNotice_aggregate(where: $where) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetPatientSystemNoticesAggregateQuery__
 *
 * To run a query within a React component, call `useGetPatientSystemNoticesAggregateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPatientSystemNoticesAggregateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPatientSystemNoticesAggregateQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetPatientSystemNoticesAggregateQuery(baseOptions: Apollo.QueryHookOptions<GetPatientSystemNoticesAggregateQuery, GetPatientSystemNoticesAggregateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPatientSystemNoticesAggregateQuery, GetPatientSystemNoticesAggregateQueryVariables>(GetPatientSystemNoticesAggregateDocument, options);
      }
export function useGetPatientSystemNoticesAggregateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPatientSystemNoticesAggregateQuery, GetPatientSystemNoticesAggregateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPatientSystemNoticesAggregateQuery, GetPatientSystemNoticesAggregateQueryVariables>(GetPatientSystemNoticesAggregateDocument, options);
        }
export type GetPatientSystemNoticesAggregateQueryHookResult = ReturnType<typeof useGetPatientSystemNoticesAggregateQuery>;
export type GetPatientSystemNoticesAggregateLazyQueryHookResult = ReturnType<typeof useGetPatientSystemNoticesAggregateLazyQuery>;
export type GetPatientSystemNoticesAggregateQueryResult = Apollo.QueryResult<GetPatientSystemNoticesAggregateQuery, GetPatientSystemNoticesAggregateQueryVariables>;