import { Flex, FormLabel, Text } from '@chakra-ui/react';
import { ErrorMessage } from '@hookform/error-message';
import { FormInputProps } from '@webapp/types';
import { PropsWithChildren } from 'react';
import { DEFAULT_LABEL_STYLE } from '../FormInput/constants';

export type InputWrapperProps = {
  displayValue?: string | number;
  errors?: any;
  isEditable?: boolean;
} & Pick<
  FormInputProps,
  | 'id'
  | 'isEditable'
  | 'label'
  | 'labelAlign'
  | 'labelStyle'
  | 'labelSubText'
  | 'name'
  | 'shouldDisplayError'
  | 'style'
>;

export function InputWrapper({
  children,
  displayValue,
  errors,
  id,
  isEditable = true,
  label,
  labelAlign,
  labelStyle = DEFAULT_LABEL_STYLE,
  labelSubText,
  name,
  shouldDisplayError = true,
  style = {},
}: PropsWithChildren<InputWrapperProps>) {
  const inputId = id ?? name;

  return (
    <Flex
      alignItems={labelAlign}
      className="form-input-wrapper"
      flexDirection="column"
      {...style}
    >
      {Boolean(label) && (
        <Flex
          className="input-label-wrapper"
          flexDirection="column"
          margin="0 0 6px 0"
        >
          <FormLabel
            className="input-label"
            htmlFor={inputId}
            margin="0 0 0 0"
            {...labelStyle}
          >
            {label}
          </FormLabel>
          {Boolean(label && labelSubText) && (
            <Text color="gray.400" fontSize="11px">
              {labelSubText}
            </Text>
          )}
        </Flex>
      )}

      {!isEditable && <Text color="#525257">{displayValue}</Text>}
      {isEditable && <Flex className="input-inner">{children}</Flex>}
      {shouldDisplayError && (
        <ErrorMessage
          errors={errors}
          name={name}
          render={({ message }) => (
            <Text
              className="input-error"
              color="red.500"
              margin="8px 0 0"
              _before={{
                display: 'inline',
                content: '"⚠ "',
              }}
            >
              {message}
            </Text>
          )}
        />
      )}
    </Flex>
  );
}

export default InputWrapper;
