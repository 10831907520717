import * as Joi from 'joi';

export const ObjectOptionsArray = Joi.array().items(
  Joi.object({
    label: Joi.string().required(),
    object: Joi.object(),
    value: Joi.string().required(),
  })
);

export default ObjectOptionsArray;
