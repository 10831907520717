import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type UpdatecustomFormFieldResponseMutationVariables = Types.Exact<{
  _set?: Types.Maybe<Types.CustomFormFieldResponse_Set_Input>;
  where: Types.CustomFormFieldResponse_Bool_Exp;
}>;


export type UpdatecustomFormFieldResponseMutation = { __typename?: 'mutation_root', update_customFormFieldResponse?: { __typename?: 'customFormFieldResponse_mutation_response', returning: Array<{ __typename?: 'customFormFieldResponse', id: any }> } | null | undefined };


export const UpdatecustomFormFieldResponseDocument = gql`
    mutation updatecustomFormFieldResponse($_set: customFormFieldResponse_set_input, $where: customFormFieldResponse_bool_exp!) {
  update_customFormFieldResponse(_set: $_set, where: $where) {
    returning {
      id
    }
  }
}
    `;
export type UpdatecustomFormFieldResponseMutationFn = Apollo.MutationFunction<UpdatecustomFormFieldResponseMutation, UpdatecustomFormFieldResponseMutationVariables>;

/**
 * __useUpdatecustomFormFieldResponseMutation__
 *
 * To run a mutation, you first call `useUpdatecustomFormFieldResponseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatecustomFormFieldResponseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatecustomFormFieldResponseMutation, { data, loading, error }] = useUpdatecustomFormFieldResponseMutation({
 *   variables: {
 *      _set: // value for '_set'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdatecustomFormFieldResponseMutation(baseOptions?: Apollo.MutationHookOptions<UpdatecustomFormFieldResponseMutation, UpdatecustomFormFieldResponseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatecustomFormFieldResponseMutation, UpdatecustomFormFieldResponseMutationVariables>(UpdatecustomFormFieldResponseDocument, options);
      }
export type UpdatecustomFormFieldResponseMutationHookResult = ReturnType<typeof useUpdatecustomFormFieldResponseMutation>;
export type UpdatecustomFormFieldResponseMutationResult = Apollo.MutationResult<UpdatecustomFormFieldResponseMutation>;
export type UpdatecustomFormFieldResponseMutationOptions = Apollo.BaseMutationOptions<UpdatecustomFormFieldResponseMutation, UpdatecustomFormFieldResponseMutationVariables>;