import {
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Modal,
  ModalBody,
  ModalFooter,
  Flex,
  Button,
} from '@chakra-ui/react';

interface WarningModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const WarningModal = ({ isOpen, onClose }: WarningModalProps) => (
  <Modal isOpen={isOpen} onClose={onClose}>
    <ModalOverlay />
    <ModalContent>
      <ModalHeader>Warning</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        This form has existing responses. Though all data from these responses
        will be preserved, editing this form will mean you can no longer
        populate the form with the responses.
      </ModalBody>
      <ModalFooter>
        <Flex justifyContent="end">
          <Button variant="solid" colorScheme="red" onClick={onClose}>
            I understand
          </Button>
        </Flex>
      </ModalFooter>
    </ModalContent>
  </Modal>
);

export default WarningModal;
