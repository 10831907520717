import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { PatientWalletTransactionFieldsFragmentDoc } from '../../fragments/patientWalletTransactionFields.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetPatientWalletTransactionsQueryVariables = Types.Exact<{
  where?: Types.Maybe<Types.PatientWalletTransaction_Bool_Exp>;
  limit?: Types.Maybe<Types.Scalars['Int']>;
  offset?: Types.Maybe<Types.Scalars['Int']>;
  orderBy?: Types.Maybe<Array<Types.PatientWalletTransaction_Order_By> | Types.PatientWalletTransaction_Order_By>;
}>;


export type GetPatientWalletTransactionsQuery = { __typename?: 'query_root', patientWalletTransaction: Array<{ __typename?: 'patientWalletTransaction', id: any, totalBefore: number, totalAfter: number, amount: number, note: string, invoiceId?: any | null | undefined, createdAt: any, source: { __typename?: 'GetPatientWalletTransactionSourceOutput', id: any, type: string, name: string } }> };


export const GetPatientWalletTransactionsDocument = gql`
    query GetPatientWalletTransactions($where: patientWalletTransaction_bool_exp, $limit: Int, $offset: Int, $orderBy: [patientWalletTransaction_order_by!]) {
  patientWalletTransaction(
    where: $where
    limit: $limit
    offset: $offset
    order_by: $orderBy
  ) {
    ...PatientWalletTransactionFields
  }
}
    ${PatientWalletTransactionFieldsFragmentDoc}`;

/**
 * __useGetPatientWalletTransactionsQuery__
 *
 * To run a query within a React component, call `useGetPatientWalletTransactionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPatientWalletTransactionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPatientWalletTransactionsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetPatientWalletTransactionsQuery(baseOptions?: Apollo.QueryHookOptions<GetPatientWalletTransactionsQuery, GetPatientWalletTransactionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPatientWalletTransactionsQuery, GetPatientWalletTransactionsQueryVariables>(GetPatientWalletTransactionsDocument, options);
      }
export function useGetPatientWalletTransactionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPatientWalletTransactionsQuery, GetPatientWalletTransactionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPatientWalletTransactionsQuery, GetPatientWalletTransactionsQueryVariables>(GetPatientWalletTransactionsDocument, options);
        }
export type GetPatientWalletTransactionsQueryHookResult = ReturnType<typeof useGetPatientWalletTransactionsQuery>;
export type GetPatientWalletTransactionsLazyQueryHookResult = ReturnType<typeof useGetPatientWalletTransactionsLazyQuery>;
export type GetPatientWalletTransactionsQueryResult = Apollo.QueryResult<GetPatientWalletTransactionsQuery, GetPatientWalletTransactionsQueryVariables>;