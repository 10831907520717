import { Instance, SnapshotOut, types } from 'mobx-state-tree';

/**
 * A MarketingTagModel model.
 */
// prettier-ignore
export const MarketingTagModel = types.model("MarketingTag").props({
  id: types.maybeNull(types.string),
  title: types.string
}).actions((self) => ({
  
}
))
/**
 * The MarketingTag instance.
 */
export type MarketingTag = Instance<typeof MarketingTagModel>;

/**
 * The data of a MarketingTagSnapshot.
 */
export type MarketingTagSnapshot = SnapshotOut<typeof MarketingTagModel>;
