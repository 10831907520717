import { PhotoGrid } from '@webapp/ui';
import {
  OrderBy,
  PhotoFieldsFragment,
  useListPhotosQuery,
} from '@webapp/graphql';
import { Center, Heading, Box, Stack, HStack } from '@chakra-ui/react';
import styles from './patient-photos.module.scss';
import { Filters, Pagination, QueryParamInput } from '@webapp/ui';
import { usePaginatedPhotos } from '@webapp/webapp/hooks';
/* eslint-disable-next-line */
export interface PatientPhotosProps {
  patientId: string;
  onClickPhoto: (photo: PhotoFieldsFragment) => void;
}

export function PatientPhotos({ patientId, onClickPhoto }: PatientPhotosProps) {
  // const { called, data, loading, refetch } = useListPhotosQuery({
  //   variables: {
  //     where: {
  //       patientId: {
  //         _eq: patientId,
  //       },
  //     },
  //     orderBy: {
  //       mediaDate: OrderBy.Desc,
  //     },
  //   },
  // });
  // const photos = data?.photo ?? [];
  const { count, loading, photos, selectPhoto, selectedPhoto } =
    usePaginatedPhotos({ pageSize: 20 });

  const hasNoPhotos = !loading && !photos.length;

  return (
    <Box w={'full'} h={'full'}>
      {Boolean(photos.length) && (
        <Stack>
          <HStack>
            <QueryParamInput
              maxWidth="360px"
              placeholder="Search by tag"
              inputVariant="outline"
            />
            <Filters
              filtersData={[
                {
                  defaultValue: {
                    label: 'Most Recent',
                    value: 'createdAt:desc',
                  },
                  filterKey: 'order',
                  isAsync: false,
                  isClearable: false,
                  isSearchable: false,
                  selectOptions: [
                    {
                      label: 'Most Recent',
                      value: 'createdAt:desc',
                    },
                    {
                      label: 'Oldest',
                      value: 'createdAt:asc',
                    },
                  ],
                  width: '175px',
                },
              ]}
            />
          </HStack>
          <Box h="70vh" overflowY="auto">
            <PhotoGrid
              onPhotoClick={onClickPhoto}
              columnGap={15}
              customStyles={{
                card: {
                  borderRadius: '8px',
                  maxHeight: '200px',
                  width: '200px',
                  height: '200px',
                },
              }}
              photos={photos}
              rowGap={15}
              variant="default"
            />
          </Box>
          <Pagination count={count} perPageLimit={20} />
        </Stack>
      )}
    </Box>
  );
}

export default PatientPhotos;
