import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type SetupSubscriptionsMutationVariables = Types.Exact<{
  patientId?: Types.Maybe<Types.Scalars['uuid']>;
}>;


export type SetupSubscriptionsMutation = { __typename?: 'mutation_root', setupHealthGorillaSubscriptions: { __typename?: 'healthGorillaSubscriptionsOutput', message: string } };


export const SetupSubscriptionsDocument = gql`
    mutation setupSubscriptions($patientId: uuid) {
  setupHealthGorillaSubscriptions {
    message
  }
}
    `;
export type SetupSubscriptionsMutationFn = Apollo.MutationFunction<SetupSubscriptionsMutation, SetupSubscriptionsMutationVariables>;

/**
 * __useSetupSubscriptionsMutation__
 *
 * To run a mutation, you first call `useSetupSubscriptionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetupSubscriptionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setupSubscriptionsMutation, { data, loading, error }] = useSetupSubscriptionsMutation({
 *   variables: {
 *      patientId: // value for 'patientId'
 *   },
 * });
 */
export function useSetupSubscriptionsMutation(baseOptions?: Apollo.MutationHookOptions<SetupSubscriptionsMutation, SetupSubscriptionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetupSubscriptionsMutation, SetupSubscriptionsMutationVariables>(SetupSubscriptionsDocument, options);
      }
export type SetupSubscriptionsMutationHookResult = ReturnType<typeof useSetupSubscriptionsMutation>;
export type SetupSubscriptionsMutationResult = Apollo.MutationResult<SetupSubscriptionsMutation>;
export type SetupSubscriptionsMutationOptions = Apollo.BaseMutationOptions<SetupSubscriptionsMutation, SetupSubscriptionsMutationVariables>;