import * as Types from '../../generated/graphql';

import { gql } from '@apollo/client';
export type LocationProviderFieldsFragment = { __typename?: 'locationProvider', id: any, location: { __typename?: 'location', id: any, name: string }, provider: { __typename?: 'provider', id: any, title?: string | null | undefined, firstName: string, lastName: string, profilePicture?: string | null | undefined } };

export const LocationProviderFieldsFragmentDoc = gql`
    fragment LocationProviderFields on locationProvider {
  id
  location {
    id
    name
  }
  provider {
    id
    title
    firstName
    lastName
    profilePicture
  }
}
    `;