import {
  Box,
  HStack,
  Text,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from '@chakra-ui/react';
import './patient-status-badge.module.css';

/* eslint-disable-next-line */
export interface PatientStatusBadgeProps {
  status?: string | null;
  readonly?: boolean;
  onChange?: (status: string) => void;
}

const STATUS_COLOR_MAP: {
  [key: string]: string;
} = {
  FIRED: 'red.400',
  LEAD: 'green.400',
  ACTIVE: 'gold.500',
  NEW_PATIENT: 'purple.400',
  DECEASED: 'gray.400',
};

const STATUS_TEXT_MAP: {
  [key: string]: string;
} = {
  FIRED: 'Fired',
  LEAD: 'New Lead',
  ACTIVE: 'Existing',
  NEW_PATIENT: 'New Patient',
  DECEASED: 'Deceased',
};

export function PatientStatusBadge({
  status: statusFromProps,
  onChange,
  readonly = false,
}: PatientStatusBadgeProps) {
  const status = statusFromProps ?? 'ACTIVE';

  if (readonly)
    return (
      <HStack className="pm-status-badge">
        <Box w={4} h={4} rounded="full" bg={STATUS_COLOR_MAP[status]}></Box>
        <Text color="gray.600">{STATUS_TEXT_MAP[status]}</Text>
      </HStack>
    );

  return (
    <Menu>
      <MenuButton>
        <HStack className="pm-status-badge">
          <Box w={4} h={4} rounded="full" bg={STATUS_COLOR_MAP[status]}></Box>
          <Text color="gray.600">{STATUS_TEXT_MAP[status]}</Text>
        </HStack>
      </MenuButton>
      <MenuList>
        {Object.keys(STATUS_TEXT_MAP).map((s: string) => (
          <MenuItem
            key={s}
            onClick={() => {
              if (onChange) {
                onChange(s);
              }
            }}
          >
            <HStack className="pm-status-badge">
              <Box w={4} h={4} rounded="full" bg={STATUS_COLOR_MAP[s]}></Box>
              <Text color="gray.600">{STATUS_TEXT_MAP[s]}</Text>
            </HStack>
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
}

export default PatientStatusBadge;
