import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type ListCompensationStrategiesQueryVariables = Types.Exact<{
  limit?: Types.Maybe<Types.Scalars['Int']>;
  offset?: Types.Maybe<Types.Scalars['Int']>;
  orderBy?: Types.Maybe<Array<Types.CompensationStrategy_Order_By> | Types.CompensationStrategy_Order_By>;
  where?: Types.Maybe<Types.CompensationStrategy_Bool_Exp>;
}>;


export type ListCompensationStrategiesQuery = { __typename?: 'query_root', compensationStrategy: Array<{ __typename?: 'compensationStrategy', createdAt: any, id: any, isActive: boolean, name: string, strategyType: string, updatedAt: any, tiers: Array<{ __typename?: 'compensationStrategyTier', id: any, maxApplyValue: number, minApplyValue: number, name: string }> }> };


export const ListCompensationStrategiesDocument = gql`
    query ListCompensationStrategies($limit: Int = 10, $offset: Int = 0, $orderBy: [compensationStrategy_order_by!], $where: compensationStrategy_bool_exp) {
  compensationStrategy(
    limit: $limit
    offset: $offset
    order_by: $orderBy
    where: $where
  ) {
    createdAt
    id
    isActive
    name
    strategyType
    tiers(order_by: [{minApplyValue: asc}]) {
      id
      maxApplyValue
      minApplyValue
      name
    }
    updatedAt
  }
}
    `;

/**
 * __useListCompensationStrategiesQuery__
 *
 * To run a query within a React component, call `useListCompensationStrategiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListCompensationStrategiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListCompensationStrategiesQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      orderBy: // value for 'orderBy'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useListCompensationStrategiesQuery(baseOptions?: Apollo.QueryHookOptions<ListCompensationStrategiesQuery, ListCompensationStrategiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListCompensationStrategiesQuery, ListCompensationStrategiesQueryVariables>(ListCompensationStrategiesDocument, options);
      }
export function useListCompensationStrategiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListCompensationStrategiesQuery, ListCompensationStrategiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListCompensationStrategiesQuery, ListCompensationStrategiesQueryVariables>(ListCompensationStrategiesDocument, options);
        }
export type ListCompensationStrategiesQueryHookResult = ReturnType<typeof useListCompensationStrategiesQuery>;
export type ListCompensationStrategiesLazyQueryHookResult = ReturnType<typeof useListCompensationStrategiesLazyQuery>;
export type ListCompensationStrategiesQueryResult = Apollo.QueryResult<ListCompensationStrategiesQuery, ListCompensationStrategiesQueryVariables>;