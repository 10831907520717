import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type UpdateAppointmentServiceConsumableMutationVariables = Types.Exact<{
  input: Types.UpdateAppointmentServiceConsumableInput;
}>;


export type UpdateAppointmentServiceConsumableMutation = { __typename?: 'mutation_root', updateAppointmentServiceConsumable: { __typename?: 'UpdateAppointmentServiceConsumableOutput', appointmentId: any, locationId: any, consumableId: any, appointmentServiceId: any, creditId: any, debitId: any, quantity: number } };


export const UpdateAppointmentServiceConsumableDocument = gql`
    mutation UpdateAppointmentServiceConsumable($input: UpdateAppointmentServiceConsumableInput!) {
  updateAppointmentServiceConsumable(input: $input) {
    appointmentId
    locationId
    consumableId
    appointmentServiceId
    creditId
    debitId
    quantity
  }
}
    `;
export type UpdateAppointmentServiceConsumableMutationFn = Apollo.MutationFunction<UpdateAppointmentServiceConsumableMutation, UpdateAppointmentServiceConsumableMutationVariables>;

/**
 * __useUpdateAppointmentServiceConsumableMutation__
 *
 * To run a mutation, you first call `useUpdateAppointmentServiceConsumableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAppointmentServiceConsumableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAppointmentServiceConsumableMutation, { data, loading, error }] = useUpdateAppointmentServiceConsumableMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAppointmentServiceConsumableMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAppointmentServiceConsumableMutation, UpdateAppointmentServiceConsumableMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAppointmentServiceConsumableMutation, UpdateAppointmentServiceConsumableMutationVariables>(UpdateAppointmentServiceConsumableDocument, options);
      }
export type UpdateAppointmentServiceConsumableMutationHookResult = ReturnType<typeof useUpdateAppointmentServiceConsumableMutation>;
export type UpdateAppointmentServiceConsumableMutationResult = Apollo.MutationResult<UpdateAppointmentServiceConsumableMutation>;
export type UpdateAppointmentServiceConsumableMutationOptions = Apollo.BaseMutationOptions<UpdateAppointmentServiceConsumableMutation, UpdateAppointmentServiceConsumableMutationVariables>;