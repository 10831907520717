import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type PhoneNumberLookupQueryVariables = Types.Exact<{
  phoneNumber: Types.Scalars['String'];
  countryCode?: Types.Maybe<Types.Scalars['String']>;
}>;


export type PhoneNumberLookupQuery = { __typename?: 'query_root', phoneNumberLookup: { __typename?: 'PhoneNumberLookupOutput', phoneNumber?: any | null | undefined } };


export const PhoneNumberLookupDocument = gql`
    query PhoneNumberLookup($phoneNumber: String!, $countryCode: String) {
  phoneNumberLookup(phoneNumber: $phoneNumber, countryCode: $countryCode) {
    phoneNumber
  }
}
    `;

/**
 * __usePhoneNumberLookupQuery__
 *
 * To run a query within a React component, call `usePhoneNumberLookupQuery` and pass it any options that fit your needs.
 * When your component renders, `usePhoneNumberLookupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePhoneNumberLookupQuery({
 *   variables: {
 *      phoneNumber: // value for 'phoneNumber'
 *      countryCode: // value for 'countryCode'
 *   },
 * });
 */
export function usePhoneNumberLookupQuery(baseOptions: Apollo.QueryHookOptions<PhoneNumberLookupQuery, PhoneNumberLookupQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PhoneNumberLookupQuery, PhoneNumberLookupQueryVariables>(PhoneNumberLookupDocument, options);
      }
export function usePhoneNumberLookupLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PhoneNumberLookupQuery, PhoneNumberLookupQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PhoneNumberLookupQuery, PhoneNumberLookupQueryVariables>(PhoneNumberLookupDocument, options);
        }
export type PhoneNumberLookupQueryHookResult = ReturnType<typeof usePhoneNumberLookupQuery>;
export type PhoneNumberLookupLazyQueryHookResult = ReturnType<typeof usePhoneNumberLookupLazyQuery>;
export type PhoneNumberLookupQueryResult = Apollo.QueryResult<PhoneNumberLookupQuery, PhoneNumberLookupQueryVariables>;