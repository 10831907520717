import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { PrescriptionDetailFieldsFragmentDoc } from '../../fragments/prescriptionDetailFields.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetPrescriptionDetailsQueryVariables = Types.Exact<{
  where: Types.PrescriptionDetail_Bool_Exp;
  limit?: Types.Maybe<Types.Scalars['Int']>;
  offset?: Types.Maybe<Types.Scalars['Int']>;
  orderBy?: Types.Maybe<Array<Types.PrescriptionDetail_Order_By> | Types.PrescriptionDetail_Order_By>;
}>;


export type GetPrescriptionDetailsQuery = { __typename?: 'query_root', prescriptionDetail: Array<{ __typename?: 'prescriptionDetail', id: any, displayName: string, quantity: string, directions: string, dispenseUnitDescription: string, daysSupply: number, drugClassification?: string | null | undefined, refills: string, strength?: string | null | undefined, writtenDate: any, patient: { __typename?: 'patient', id: any, attributes: { __typename?: 'PatientAttributes', firstName?: string | null | undefined, lastName?: string | null | undefined } }, provider: { __typename?: 'provider', id: any, firstName: string, lastName: string }, location: { __typename?: 'location', id: any, name: string } }> };


export const GetPrescriptionDetailsDocument = gql`
    query GetPrescriptionDetails($where: prescriptionDetail_bool_exp!, $limit: Int, $offset: Int, $orderBy: [prescriptionDetail_order_by!]) {
  prescriptionDetail(
    where: $where
    limit: $limit
    offset: $offset
    order_by: $orderBy
  ) {
    ...PrescriptionDetailFields
  }
}
    ${PrescriptionDetailFieldsFragmentDoc}`;

/**
 * __useGetPrescriptionDetailsQuery__
 *
 * To run a query within a React component, call `useGetPrescriptionDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPrescriptionDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPrescriptionDetailsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetPrescriptionDetailsQuery(baseOptions: Apollo.QueryHookOptions<GetPrescriptionDetailsQuery, GetPrescriptionDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPrescriptionDetailsQuery, GetPrescriptionDetailsQueryVariables>(GetPrescriptionDetailsDocument, options);
      }
export function useGetPrescriptionDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPrescriptionDetailsQuery, GetPrescriptionDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPrescriptionDetailsQuery, GetPrescriptionDetailsQueryVariables>(GetPrescriptionDetailsDocument, options);
        }
export type GetPrescriptionDetailsQueryHookResult = ReturnType<typeof useGetPrescriptionDetailsQuery>;
export type GetPrescriptionDetailsLazyQueryHookResult = ReturnType<typeof useGetPrescriptionDetailsLazyQuery>;
export type GetPrescriptionDetailsQueryResult = Apollo.QueryResult<GetPrescriptionDetailsQuery, GetPrescriptionDetailsQueryVariables>;