import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type UpdateManyPatientFlowsheetDataMutationVariables = Types.Exact<{
  updates: Array<Types.PatientFlowsheetData_Updates> | Types.PatientFlowsheetData_Updates;
}>;


export type UpdateManyPatientFlowsheetDataMutation = { __typename?: 'mutation_root', update_patientFlowsheetData_many?: Array<{ __typename?: 'patientFlowsheetData_mutation_response', affected_rows: number } | null | undefined> | null | undefined };


export const UpdateManyPatientFlowsheetDataDocument = gql`
    mutation updateManyPatientFlowsheetData($updates: [patientFlowsheetData_updates!]!) {
  update_patientFlowsheetData_many(updates: $updates) {
    affected_rows
  }
}
    `;
export type UpdateManyPatientFlowsheetDataMutationFn = Apollo.MutationFunction<UpdateManyPatientFlowsheetDataMutation, UpdateManyPatientFlowsheetDataMutationVariables>;

/**
 * __useUpdateManyPatientFlowsheetDataMutation__
 *
 * To run a mutation, you first call `useUpdateManyPatientFlowsheetDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateManyPatientFlowsheetDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateManyPatientFlowsheetDataMutation, { data, loading, error }] = useUpdateManyPatientFlowsheetDataMutation({
 *   variables: {
 *      updates: // value for 'updates'
 *   },
 * });
 */
export function useUpdateManyPatientFlowsheetDataMutation(baseOptions?: Apollo.MutationHookOptions<UpdateManyPatientFlowsheetDataMutation, UpdateManyPatientFlowsheetDataMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateManyPatientFlowsheetDataMutation, UpdateManyPatientFlowsheetDataMutationVariables>(UpdateManyPatientFlowsheetDataDocument, options);
      }
export type UpdateManyPatientFlowsheetDataMutationHookResult = ReturnType<typeof useUpdateManyPatientFlowsheetDataMutation>;
export type UpdateManyPatientFlowsheetDataMutationResult = Apollo.MutationResult<UpdateManyPatientFlowsheetDataMutation>;
export type UpdateManyPatientFlowsheetDataMutationOptions = Apollo.BaseMutationOptions<UpdateManyPatientFlowsheetDataMutation, UpdateManyPatientFlowsheetDataMutationVariables>;