import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type ListLeadTagsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ListLeadTagsQuery = { __typename?: 'query_root', leadTag: Array<{ __typename?: 'leadTag', id: any, title: string }> };


export const ListLeadTagsDocument = gql`
    query ListLeadTags {
  leadTag {
    id
    title
  }
}
    `;

/**
 * __useListLeadTagsQuery__
 *
 * To run a query within a React component, call `useListLeadTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListLeadTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListLeadTagsQuery({
 *   variables: {
 *   },
 * });
 */
export function useListLeadTagsQuery(baseOptions?: Apollo.QueryHookOptions<ListLeadTagsQuery, ListLeadTagsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListLeadTagsQuery, ListLeadTagsQueryVariables>(ListLeadTagsDocument, options);
      }
export function useListLeadTagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListLeadTagsQuery, ListLeadTagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListLeadTagsQuery, ListLeadTagsQueryVariables>(ListLeadTagsDocument, options);
        }
export type ListLeadTagsQueryHookResult = ReturnType<typeof useListLeadTagsQuery>;
export type ListLeadTagsLazyQueryHookResult = ReturnType<typeof useListLeadTagsLazyQuery>;
export type ListLeadTagsQueryResult = Apollo.QueryResult<ListLeadTagsQuery, ListLeadTagsQueryVariables>;