import * as Types from '../../generated/graphql';

import { gql } from '@apollo/client';
export type PayrixTerminalFieldsFragment = { __typename?: 'payrixTerminal', id: any, workspaceId: any, locationId: any, deviceMake: string, deviceModel: string, payrixTerminalId: string, name?: string | null | undefined };

export const PayrixTerminalFieldsFragmentDoc = gql`
    fragment PayrixTerminalFields on payrixTerminal {
  id
  workspaceId
  locationId
  deviceMake
  deviceModel
  payrixTerminalId
  name
}
    `;