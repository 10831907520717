import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetTwilioChatTokenQueryVariables = Types.Exact<{
  workspaceId: Types.Scalars['uuid'];
  identityOverride?: Types.Maybe<Types.Scalars['String']>;
}>;


export type GetTwilioChatTokenQuery = { __typename?: 'query_root', getTwilioChatToken?: { __typename?: 'TwilioChatTokenResponse', token: string } | null | undefined };


export const GetTwilioChatTokenDocument = gql`
    query getTwilioChatToken($workspaceId: uuid!, $identityOverride: String) {
  getTwilioChatToken(
    workspaceId: $workspaceId
    identityOverride: $identityOverride
  ) {
    token
  }
}
    `;

/**
 * __useGetTwilioChatTokenQuery__
 *
 * To run a query within a React component, call `useGetTwilioChatTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTwilioChatTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTwilioChatTokenQuery({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *      identityOverride: // value for 'identityOverride'
 *   },
 * });
 */
export function useGetTwilioChatTokenQuery(baseOptions: Apollo.QueryHookOptions<GetTwilioChatTokenQuery, GetTwilioChatTokenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTwilioChatTokenQuery, GetTwilioChatTokenQueryVariables>(GetTwilioChatTokenDocument, options);
      }
export function useGetTwilioChatTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTwilioChatTokenQuery, GetTwilioChatTokenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTwilioChatTokenQuery, GetTwilioChatTokenQueryVariables>(GetTwilioChatTokenDocument, options);
        }
export type GetTwilioChatTokenQueryHookResult = ReturnType<typeof useGetTwilioChatTokenQuery>;
export type GetTwilioChatTokenLazyQueryHookResult = ReturnType<typeof useGetTwilioChatTokenLazyQuery>;
export type GetTwilioChatTokenQueryResult = Apollo.QueryResult<GetTwilioChatTokenQuery, GetTwilioChatTokenQueryVariables>;