import { isNumber } from '@chakra-ui/utils';
import { FormInputProps } from '@webapp/types';
import { Controller, useFormContext } from 'react-hook-form';
import CurrencyInput from '../CurrencyInput/CurrencyInput';

function CurrencyFormInput(props: FormInputProps) {
  const { control, setValue } = useFormContext();

  const { defaultValue = 0, name, precision: _, ...altProps } = props;

  const defaultValueInt = isNumber(defaultValue)
    ? defaultValue
    : parseInt(`${defaultValue}`, 10);

  function handleChange(value: number) {
    setValue(name, value);
  }

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { value }, formState: { errors } }) => (
        <CurrencyInput
          defaultValue={defaultValueInt}
          errors={errors}
          name={name}
          onChange={handleChange}
          value={value}
          {...altProps}
        />
      )}
    />
  );
}

export default CurrencyFormInput;
