/* eslint-disable camelcase */
import { Box, Stack, Wrap, Text, Tag } from '@chakra-ui/react';
import {
  Eventcalendar,
  MbscCalendarEvent,
  MbscEventcalendarView,
  MbscEventClickEvent,
  MbscEventCreatedEvent,
  MbscEventUpdateEvent,
  MbscResource,
  momentTimezone,
  Popup,
  MbscCalendarColor,
} from '@mobiscroll/react';
import { DateType } from '@mobiscroll/react/dist/src/core/util/datetime';
import { Appointment, useStores } from '@webapp/state-models';
import { observer } from 'mobx-react-lite';
import { useCallback, useRef, useState } from 'react';
import { useFlagsmith } from 'flagsmith-react';
import { PromptModal } from '@webapp/webapp/ui-composites';
import dayjs from 'dayjs';
import CalendarTooltip from './CalendarTooltip';
import PatientIcons from '../../../../../ui/src/lib/PatientIcons/PatientIcons';
import CalendarLabel from './CalendarLabel';

interface BaseCalendarProps {
  calendarDate: DateType;
  colors?: MbscCalendarColor[] | null;
  events: MbscCalendarEvent[];
  isLoading: boolean;
  isOpen: boolean;
  isListMobileView: boolean;
  onClose: () => void;
  onConfirm: () => void;
  onEventClick: (event: MbscEventClickEvent, instance: Eventcalendar) => void;
  onEventCreated: ({ event }: MbscEventCreatedEvent) => Promise<void>;
  onEventUpdate: (args: MbscEventUpdateEvent, inst: Eventcalendar) => void;
  renderResource?: (resource: MbscResource) => void;
  resources?: MbscResource[] | null;
  selectedView: string;
  setCalendarDate: (date: string) => void;
  view: MbscEventcalendarView;
}

function BaseCalendar({
  colors = null,
  resources = null,
  renderResource,
  calendarDate,
  events,
  isLoading,
  isOpen,
  isListMobileView,
  onClose,
  onConfirm,
  onEventClick,
  onEventCreated,
  onEventUpdate,
  selectedView,
  setCalendarDate,
  view,
}: BaseCalendarProps) {
  const [activeEvent, setActiveEvent] = useState<MbscCalendarEvent | null>(
    null
  );
  const [anchor, setAnchor] = useState<HTMLElement | undefined | null>(null);
  const [isPopupOpen, setPopupOpen] = useState<boolean>(false);
  const timerRef = useRef<any>(null);
  const { hasFeature } = useFlagsmith();

  const {
    calendarCacheTime,
    calendarTimeZone,
    localSettings: { schedule },
  } = useStores();

  function onEventHoverIn({ domEvent, event }: MbscEventClickEvent) {
    setActiveEvent(event);
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    setAnchor(domEvent.target);
    if (schedule.showEventPopover) setPopupOpen(true);
  }

  function onEventHoverOut() {
    timerRef.current = setTimeout(() => {
      setPopupOpen(false);
    }, 200);
  }

  const onMouseEnter = useCallback(() => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
  }, []);

  const onMouseLeave = useCallback(() => {
    timerRef.current = setTimeout(() => {
      setPopupOpen(false);
    }, 200);
  }, []);

  const renderLabelContent = (event: MbscCalendarEvent) => {
    let color = event?.status === 'CANCELED' ? 'red' : event.color;
    if (event?.type !== 'patient_appointment' && event?.colorOverride) {
      color = event?.colorOverride;
    }
    return (
      <CalendarLabel
        key={event.id}
        event={event}
        color={color ?? ''}
        view={selectedView}
      />
    );
  };

  const renderEventContent = (event: MbscCalendarEvent) => {
    return (
      <Stack w={'full'} h={'full'}>
        {event?.original?.type !== 'patient_appointment' && (
          <Text>
            {`${event?.original?.note || 'Internal Meeting'} - ${dayjs(
              new Date(event?.original?.start)
            )
              .tz(calendarTimeZone)
              .format('h:mm A z')}
            `}
          </Text>
        )}

        {event?.original?.type == 'patient_appointment' && (
          <Text fontWeight="600">{event?.original?.patientName}</Text>
        )}
        <Text>{event?.original?.providerName}</Text>
        <Wrap>
          {event?.original?.services?.map((service: string, index: number) => (
            <Tag key={index}>{service}</Tag>
          ))}
        </Wrap>
        <PatientIcons
          appointment={event.original as Appointment}
          iconContainerStyles={{
            size: 5,
          }}
          isTooltipDisabled
        />
      </Stack>
    );
  };

  const mobileView = isListMobileView
    ? {
        xsmall: {
          view: {
            calendar: {
              type: selectedView,
            },
            agenda: {
              type: selectedView,
            },
          },
        },
        custom: {
          // Custom breakpoint
          breakpoint: 600,
          view,
        },
      }
    : {
        custom: {
          // Custom breakpoint
          breakpoint: 600,
          view,
        },
      };

  return (
    <div>
      <Eventcalendar
        key={`${calendarDate}-${JSON.stringify(view)}-${calendarCacheTime}`}
        selectedDate={calendarDate}
        renderHeader={() => <Box h={0} display="none" />}
        renderEventContent={renderEventContent}
        renderResource={renderResource}
        height={'82vh'}
        dataTimezone="utc"
        {...(colors && { colors })}
        displayTimezone={calendarTimeZone}
        touchUi={true}
        responsive={mobileView}
        showEventTooltip={false}
        startTime="08:00"
        endTime="18:00"
        timezonePlugin={momentTimezone}
        clickToCreate={true}
        dragToCreate={true}
        dragToMove={true}
        dragToResize={true}
        data={events as MbscCalendarEvent[]}
        {...(resources && { resources })}
        view={view}
        {...(hasFeature('calendar:enhanced-cells')
          ? { renderScheduleEvent: renderLabelContent }
          : {})}
        onEventHoverIn={onEventHoverIn}
        onEventHoverOut={onEventHoverOut}
        onEventUpdate={onEventUpdate}
        onEventCreated={onEventCreated}
        onEventClick={onEventClick}
        onSelectedDateChange={(date) => {
          setCalendarDate(dayjs(date.date.toString()).format('YYYY-MM-DD'));
        }}
      />
      <Popup
        display="anchored"
        isOpen={isPopupOpen}
        anchor={anchor ?? undefined}
        touchUi={false}
        showOverlay={false}
        contentPadding={false}
        closeOnOverlayClick={false}
        width={350}
        cssClass="md-tooltip"
      >
        <div onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
          <CalendarTooltip
            anchor={anchor}
            event={activeEvent}
            isOpen={isPopupOpen}
            view={selectedView}
          />
        </div>
      </Popup>
      <PromptModal
        isLoading={isLoading}
        headerText="Send confirmation SMS?"
        bodyText="Would you like to send the patient a confirmation SMS about their new appointment time?"
        colorScheme="teal"
        denyText={'No, do not send'}
        confirmText={'Yes, send confirmation'}
        isOpen={isOpen}
        onClose={onClose}
        onConfirm={onConfirm}
        withCloseButton={false}
        withCancelButton={true}
      />
    </div>
  );
}

export default observer(BaseCalendar);
