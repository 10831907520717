/* eslint-disable max-classes-per-file */
import { createElement } from 'react';
import {
  PropertyGridEditorCollection,
  localization,
} from 'survey-creator-react';
import { ElementFactory, Question, Serializer, SvgRegistry } from 'survey-core';
import {
  SurveyQuestionElementBase,
  ReactQuestionFactory,
} from 'survey-react-ui';
import { Flex, Input, Stack, Switch, Text } from '@chakra-ui/react';
import { SignatureCaptureModal } from '@webapp/ui-composites';
import {
  FlatRepository,
  PdfBrick,
  FlatQuestion,
  IPoint,
  IRect,
} from 'survey-pdf';

import dayjs from 'dayjs';
import Icon from './icon.svg?raw';

const CUSTOM_TYPE = 'name-signature-date';

// A model for the new question type
export class NameSignatureDateModel extends Question {
  getType() {
    return CUSTOM_TYPE;
  }

  get nameLabel() {
    return this.getPropertyValue('nameLabel');
  }

  set nameLabel(val) {
    this.setPropertyValue('nameLabel', val);
  }

  get signatureLabel() {
    return this.getPropertyValue('signatureLabel');
  }

  set signatureLabel(val) {
    this.setPropertyValue('signatureLabel', val);
  }

  get signatureType() {
    return this.getPropertyValue('signatureType');
  }

  set signatureType(val) {
    this.setPropertyValue('signatureType', val);
  }

  //   validate() {
  //     console.log(this.value, 'value');
  //     if (!this.value?.name) return false;

  //     if (!this.value?.signature) return false;

  //     return true;
  //   }
}

// Register `NameSignatureDateModel` as a model for the `name-signature-date` type
export function registerNameSignatureDate() {
  ElementFactory.Instance.registerElement(
    CUSTOM_TYPE,
    (name) => new NameSignatureDateModel(name)
  );
}

// Specify display names for the question type and its properties
const locale = localization.getLocale('');
locale.qt[CUSTOM_TYPE] = 'Name / Signature / Date';

// Register an SVG icon for the question type
SvgRegistry.registerIconFromSvg(CUSTOM_TYPE, Icon);

// Add question type metadata for further serialization into JSON
Serializer.addClass(
  CUSTOM_TYPE,
  [
    {
      name: 'nameLabel:text',
      default: 'Name',
      category: 'general',
      visibleIndex: 2, // After the Name and Title
      questionJSON: {
        type: 'text',
        defaultValueExpression: '{patientName}',
      },
    },
    {
      name: 'signatureLabel:text',
      default: 'Signature',
      category: 'general',
      visibleIndex: 3, // After the Name and Title
    },
    {
      name: 'signatureType:dropdown',
      default: 'Patient',
      choices: ['Patient', 'Provider', 'Witness'],
      category: 'general',
      visibleIndex: 4, // After the Name and Title
    },
  ],
  () => new NameSignatureDateModel('NameSignatureDate'),
  'question'
);

// A class that renders questions of the new type in the UI
export class SurveyQuestionNameSignatureDate extends SurveyQuestionElementBase {
  constructor(props) {
    super(props);
    this.state = {
      value: {
        ...(this.question.value ?? {}),
        signatureType: this.signatureType,
        name: props.question?.survey?.getVariable('patientName'),
      },
    };
  }

  get question() {
    return this.questionBase;
  }

  get nameLabel() {
    return this.question.nameLabel;
  }

  get signatureLabel() {
    return this.question.signatureLabel;
  }

  get signatureType() {
    return this.question.signatureType;
  }

  get value() {
    if (!this.question?.value?.name) {
      this.question.value.name =
        this.question?.survey?.getVariable('patientName');
    }
    if (!this.question?.value?.date) {
      this.question.value.date =
        this.question?.survey?.getVariable('signatureDate');
    }

    return this.question.value;
  }

  // Support the read-only and design modes
  get style() {
    return this.question.getPropertyValue('readOnly') ||
      this.question.isDesignMode
      ? { pointerEvents: 'none' }
      : undefined;
  }

  handleSignature = (signature: string) => {
    this.question.value = {
      ...(this.question.value || {}),
      signatureType: this.signatureType,
      signature,
      name:
        this.question?.value?.name ||
        this.question?.survey?.getVariable('patientName'),
    };
  };

  handleName = (name: string) => {
    this.question.value = {
      ...(this.question.value || {}),
      signatureType: this.signatureType,

      name,
    };
  };

  handleDate = (date: string) => {
    this.question.value = {
      ...(this.question.value || {}),
      signatureType: this.signatureType,

      date,
    };
  };

  renderElement() {
    return (
      <Flex
        w={'full'}
        alignItems="center"
        justifyContent={'space-between'}
        direction={{ base: 'column', md: 'row' }}
      >
        <Stack w={{ base: 'full', md: 'sm' }}>
          <Input
            value={
              this.question.value?.name ||
              this.question?.survey?.getVariable('patientName')
            }
            onChange={(e) => this.handleName(e.target.value)}
          ></Input>
          <Text>{this.question.nameLabel}</Text>
        </Stack>
        <Stack w={{ base: 'full', md: 'sm' }}>
          <SignatureCaptureModal
            patientName={
              this.question.value?.name ||
              this.question?.survey?.getVariable('patientName')
            }
            existingSignature={this.question.value?.signature}
            onComplete={this.handleSignature}
          />
          <Text>{this.question.signatureLabel}</Text>
        </Stack>
        <Stack w={{ base: 'full', md: 'sm' }}>
          <Input
            type="date"
            value={
              this.question.value?.date ??
              this.question?.survey?.getVariable('signatureDate')
            }
            defaultValue={
              this.question?.survey?.getVariable('signatureDate') ??
              dayjs().format('YYYY-MM-DD')
            }
            onChange={(e) => this.handleDate(e.target.value)}
          ></Input>
          <Text>Date</Text>
        </Stack>
      </Flex>
    );
  }
}

// Register `SurveyQuestionNameSignatureDate` as a class that renders `name-signature-date` questions
ReactQuestionFactory.Instance.registerQuestion(CUSTOM_TYPE, (props) =>
  createElement(SurveyQuestionNameSignatureDate, props)
);

class CustomPdfBrick extends PdfBrick {
  override async renderInteractive() {
    const alignPoint: IPoint = this.alignPoint(this);
    const { doc } = this.controller;

    const pageWidth = doc.internal.pageSize.getWidth();
    const thirdOfPage = (pageWidth - 100) / 3;
    const leftMargin = 10;

    if (!this.question.value || !this.question.value.signature) {
      return;
    }
    // Name
    doc.text(
      this.question.getPropertyValue('nameLabel'),
      alignPoint.xLeft,
      alignPoint.yTop
    );
    doc.text(this.question.value.name, alignPoint.xLeft, alignPoint.yTop + 20);

    doc.line(
      alignPoint.xLeft,
      alignPoint.yTop + 25,
      alignPoint.xLeft + thirdOfPage,
      alignPoint.yTop + 25
    ); // underline for name

    // Signature
    doc.text(
      this.question.getPropertyValue('signatureLabel'),
      alignPoint.xLeft + thirdOfPage + leftMargin,
      alignPoint.yTop
    );

    doc.addImage(
      this.question.value.signature,
      'PNG',
      alignPoint.xLeft + thirdOfPage + leftMargin,
      alignPoint.yTop + 10,
      thirdOfPage,
      44
    );

    // Date
    doc.text(
      'Date:',
      alignPoint.xLeft + leftMargin + thirdOfPage * 2,
      alignPoint.yTop
    );
    doc.text(
      this.question.value.date ||
        dayjs(this.question?.survey?.getVariable('signatureDate')).format(
          'MM/DD/YYYY'
        ),
      alignPoint.xLeft + leftMargin + thirdOfPage * 2,
      alignPoint.yTop + 20
    );
    doc.line(
      alignPoint.xLeft + leftMargin + thirdOfPage * 2,
      alignPoint.yTop + 25,
      alignPoint.xLeft + thirdOfPage * 3,
      alignPoint.yTop + 25
    );
  }

  protected alignPoint(rect: IRect): IPoint {
    return {
      xLeft: rect.xLeft,
      yTop: rect.yTop + (rect.yBot - rect.yTop) / 2.0,
    };
  }
}

class NameSignatureText extends FlatQuestion {
  override async generateFlatsContent(point: any) {
    const rect = { ...point };
    rect.yBot = point.yTop + 20;
    rect.xRight = point.xLeft + 100;
    return [new CustomPdfBrick(this.question, this.controller, rect)];
  }
}
FlatRepository.getInstance().register('name-signature-date', NameSignatureText);
