import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type InsertSmsTemplateMutationVariables = Types.Exact<{
  smsTemplate: Types.SmsTemplate_Insert_Input;
}>;


export type InsertSmsTemplateMutation = { __typename?: 'mutation_root', insert_smsTemplate?: { __typename?: 'smsTemplate_mutation_response', returning: Array<{ __typename?: 'smsTemplate', id: any }> } | null | undefined };


export const InsertSmsTemplateDocument = gql`
    mutation insertSmsTemplate($smsTemplate: smsTemplate_insert_input!) {
  insert_smsTemplate(objects: [$smsTemplate]) {
    returning {
      id
    }
  }
}
    `;
export type InsertSmsTemplateMutationFn = Apollo.MutationFunction<InsertSmsTemplateMutation, InsertSmsTemplateMutationVariables>;

/**
 * __useInsertSmsTemplateMutation__
 *
 * To run a mutation, you first call `useInsertSmsTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertSmsTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertSmsTemplateMutation, { data, loading, error }] = useInsertSmsTemplateMutation({
 *   variables: {
 *      smsTemplate: // value for 'smsTemplate'
 *   },
 * });
 */
export function useInsertSmsTemplateMutation(baseOptions?: Apollo.MutationHookOptions<InsertSmsTemplateMutation, InsertSmsTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertSmsTemplateMutation, InsertSmsTemplateMutationVariables>(InsertSmsTemplateDocument, options);
      }
export type InsertSmsTemplateMutationHookResult = ReturnType<typeof useInsertSmsTemplateMutation>;
export type InsertSmsTemplateMutationResult = Apollo.MutationResult<InsertSmsTemplateMutation>;
export type InsertSmsTemplateMutationOptions = Apollo.BaseMutationOptions<InsertSmsTemplateMutation, InsertSmsTemplateMutationVariables>;