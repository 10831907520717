import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { DeviceTypeFieldsFragmentDoc } from '../../fragments/deviceTypeFields.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type InsertDeviceTypeMutationVariables = Types.Exact<{
  deviceType: Types.DeviceType_Insert_Input;
}>;


export type InsertDeviceTypeMutation = { __typename?: 'mutation_root', insert_deviceType?: { __typename?: 'deviceType_mutation_response', returning: Array<{ __typename?: 'deviceType', id: any, value: string }> } | null | undefined };


export const InsertDeviceTypeDocument = gql`
    mutation insertDeviceType($deviceType: deviceType_insert_input!) {
  insert_deviceType(objects: [$deviceType]) {
    returning {
      ...DeviceTypeFields
    }
  }
}
    ${DeviceTypeFieldsFragmentDoc}`;
export type InsertDeviceTypeMutationFn = Apollo.MutationFunction<InsertDeviceTypeMutation, InsertDeviceTypeMutationVariables>;

/**
 * __useInsertDeviceTypeMutation__
 *
 * To run a mutation, you first call `useInsertDeviceTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertDeviceTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertDeviceTypeMutation, { data, loading, error }] = useInsertDeviceTypeMutation({
 *   variables: {
 *      deviceType: // value for 'deviceType'
 *   },
 * });
 */
export function useInsertDeviceTypeMutation(baseOptions?: Apollo.MutationHookOptions<InsertDeviceTypeMutation, InsertDeviceTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertDeviceTypeMutation, InsertDeviceTypeMutationVariables>(InsertDeviceTypeDocument, options);
      }
export type InsertDeviceTypeMutationHookResult = ReturnType<typeof useInsertDeviceTypeMutation>;
export type InsertDeviceTypeMutationResult = Apollo.MutationResult<InsertDeviceTypeMutation>;
export type InsertDeviceTypeMutationOptions = Apollo.BaseMutationOptions<InsertDeviceTypeMutation, InsertDeviceTypeMutationVariables>;