import * as Types from '../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetSignedUploadUrlQueryVariables = Types.Exact<{
  fileType: Types.Scalars['String'];
  storageType?: Types.Maybe<Types.StorageType>;
  filePath?: Types.Maybe<Types.Scalars['String']>;
}>;


export type GetSignedUploadUrlQuery = { __typename?: 'query_root', getSignedUploadUrl?: { __typename?: 'SignedURLOutput', signedUrl: string } | null | undefined };


export const GetSignedUploadUrlDocument = gql`
    query getSignedUploadUrl($fileType: String!, $storageType: StorageType, $filePath: String) {
  getSignedUploadUrl(
    fileType: $fileType
    storageType: $storageType
    filePath: $filePath
  ) {
    signedUrl
  }
}
    `;

/**
 * __useGetSignedUploadUrlQuery__
 *
 * To run a query within a React component, call `useGetSignedUploadUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSignedUploadUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSignedUploadUrlQuery({
 *   variables: {
 *      fileType: // value for 'fileType'
 *      storageType: // value for 'storageType'
 *      filePath: // value for 'filePath'
 *   },
 * });
 */
export function useGetSignedUploadUrlQuery(baseOptions: Apollo.QueryHookOptions<GetSignedUploadUrlQuery, GetSignedUploadUrlQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSignedUploadUrlQuery, GetSignedUploadUrlQueryVariables>(GetSignedUploadUrlDocument, options);
      }
export function useGetSignedUploadUrlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSignedUploadUrlQuery, GetSignedUploadUrlQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSignedUploadUrlQuery, GetSignedUploadUrlQueryVariables>(GetSignedUploadUrlDocument, options);
        }
export type GetSignedUploadUrlQueryHookResult = ReturnType<typeof useGetSignedUploadUrlQuery>;
export type GetSignedUploadUrlLazyQueryHookResult = ReturnType<typeof useGetSignedUploadUrlLazyQuery>;
export type GetSignedUploadUrlQueryResult = Apollo.QueryResult<GetSignedUploadUrlQuery, GetSignedUploadUrlQueryVariables>;