import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type InsertManyPatientFlowsheetDataMutationVariables = Types.Exact<{
  objects: Array<Types.PatientFlowsheetData_Insert_Input> | Types.PatientFlowsheetData_Insert_Input;
}>;


export type InsertManyPatientFlowsheetDataMutation = { __typename?: 'mutation_root', insert_patientFlowsheetData?: { __typename?: 'patientFlowsheetData_mutation_response', affected_rows: number } | null | undefined };


export const InsertManyPatientFlowsheetDataDocument = gql`
    mutation insertManyPatientFlowsheetData($objects: [patientFlowsheetData_insert_input!]!) {
  insert_patientFlowsheetData(
    objects: $objects
    on_conflict: {constraint: patientFlowsheetData_flowsheetParameterId_patientFlowsheetRowId, update_columns: [recordedAt, value]}
  ) {
    affected_rows
  }
}
    `;
export type InsertManyPatientFlowsheetDataMutationFn = Apollo.MutationFunction<InsertManyPatientFlowsheetDataMutation, InsertManyPatientFlowsheetDataMutationVariables>;

/**
 * __useInsertManyPatientFlowsheetDataMutation__
 *
 * To run a mutation, you first call `useInsertManyPatientFlowsheetDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertManyPatientFlowsheetDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertManyPatientFlowsheetDataMutation, { data, loading, error }] = useInsertManyPatientFlowsheetDataMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useInsertManyPatientFlowsheetDataMutation(baseOptions?: Apollo.MutationHookOptions<InsertManyPatientFlowsheetDataMutation, InsertManyPatientFlowsheetDataMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertManyPatientFlowsheetDataMutation, InsertManyPatientFlowsheetDataMutationVariables>(InsertManyPatientFlowsheetDataDocument, options);
      }
export type InsertManyPatientFlowsheetDataMutationHookResult = ReturnType<typeof useInsertManyPatientFlowsheetDataMutation>;
export type InsertManyPatientFlowsheetDataMutationResult = Apollo.MutationResult<InsertManyPatientFlowsheetDataMutation>;
export type InsertManyPatientFlowsheetDataMutationOptions = Apollo.BaseMutationOptions<InsertManyPatientFlowsheetDataMutation, InsertManyPatientFlowsheetDataMutationVariables>;