import * as Types from '../../generated/graphql';

import { gql } from '@apollo/client';
export type ServiceUnitFieldsFragment = { __typename?: 'serviceUnit', id: any, name: string };

export const ServiceUnitFieldsFragmentDoc = gql`
    fragment ServiceUnitFields on serviceUnit {
  id
  name
}
    `;