import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type UpdateUserInviteMutationVariables = Types.Exact<{
  set?: Types.Maybe<Types.UserInvite_Set_Input>;
  id: Types.Scalars['uuid'];
}>;


export type UpdateUserInviteMutation = { __typename?: 'mutation_root', update_userInvite_by_pk?: { __typename?: 'userInvite', id: any } | null | undefined };


export const UpdateUserInviteDocument = gql`
    mutation UpdateUserInvite($set: userInvite_set_input, $id: uuid!) {
  update_userInvite_by_pk(_set: $set, pk_columns: {id: $id}) {
    id
  }
}
    `;
export type UpdateUserInviteMutationFn = Apollo.MutationFunction<UpdateUserInviteMutation, UpdateUserInviteMutationVariables>;

/**
 * __useUpdateUserInviteMutation__
 *
 * To run a mutation, you first call `useUpdateUserInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserInviteMutation, { data, loading, error }] = useUpdateUserInviteMutation({
 *   variables: {
 *      set: // value for 'set'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateUserInviteMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserInviteMutation, UpdateUserInviteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserInviteMutation, UpdateUserInviteMutationVariables>(UpdateUserInviteDocument, options);
      }
export type UpdateUserInviteMutationHookResult = ReturnType<typeof useUpdateUserInviteMutation>;
export type UpdateUserInviteMutationResult = Apollo.MutationResult<UpdateUserInviteMutation>;
export type UpdateUserInviteMutationOptions = Apollo.BaseMutationOptions<UpdateUserInviteMutation, UpdateUserInviteMutationVariables>;