import {
  Modal,
  Button,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  HStack,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Spinner,
  PinInput,
  Stack,
  PinInputField,
  Heading,
} from '@chakra-ui/react';
import { useVerifyManagerPinLazyQuery } from '@webapp/graphql';
import { useStores } from '@webapp/state-models';
import { observer } from 'mobx-react-lite';
import toast from 'react-hot-toast';

/* eslint-disable-next-line */
export interface ManagerPinModalProps {}

export const ManagerPinModal = observer((props: ManagerPinModalProps) => {
  const {
    workspace,
    ui: {
      managerPinModal: { show },
      setShowManagerPinModal,
      runManagerPinScopedFunction,
    },
  } = useStores();

  const [verifyPin, { loading }] = useVerifyManagerPinLazyQuery({
    onCompleted: (data) => {
      if (data.verifyManagerPin) {
        if (data.verifyManagerPin.success) {
          setShowManagerPinModal(false);
          runManagerPinScopedFunction();
          toast.success('Pin verified');
        } else {
          toast.error('Incorrect pin');
        }
      }
    },
  });

  const possiblyVerifyPin = (pin: string) => {
    if (pin.length === 5) {
      verifyPin({ variables: { pin, workspaceId: workspace?.id } });
    }
  };

  return (
    <Modal isOpen={show} onClose={() => setShowManagerPinModal(false)}>
      <ModalOverlay backdropFilter="auto" backdropBlur="4px" />
      <ModalContent>
        <ModalHeader>🔐 Manager Pin Required</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing={4}>
            <Heading size="xs">
              Your workspace administrator has required that a manager enter a
              pin before allowing this action.
            </Heading>
            <HStack>
              {loading ? (
                <HStack w={'full'} spacing={4}>
                  <Spinner size="sm" />
                  <Heading size="xs">Verifying pin...</Heading>
                </HStack>
              ) : (
                <PinInput
                  autoFocus
                  isDisabled={loading}
                  type="alphanumeric"
                  mask
                  onChange={possiblyVerifyPin}
                >
                  <PinInputField />
                  <PinInputField />
                  <PinInputField />
                  <PinInputField />
                  <PinInputField />
                </PinInput>
              )}
            </HStack>
          </Stack>
        </ModalBody>

        <ModalFooter>
          <Button variant="ghost" onClick={() => setShowManagerPinModal(false)}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
});

export default ManagerPinModal;
