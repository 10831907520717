import {
  Flex,
  Icon,
  PopoverBody,
  PopoverHeader,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Popover,
  Box,
  useDisclosure,
  PopoverArrow,
} from '@chakra-ui/react';
import { FaHistory, FaShoppingCart } from 'react-icons/fa';
import { useEffect } from 'react';
import PatientProductHistoryTable from './PatientProductHistoryTable';

const PatientProductHistoryPopover = ({ patientId }: { patientId: string }) => {
  const { onOpen, onClose, isOpen } = useDisclosure();
  return (
    <Box zIndex={20}>
      <Popover isOpen={isOpen} onOpen={onOpen} onClose={onClose} size="xl">
        <PopoverTrigger>
          <Flex
            position="relative"
            border="1px"
            borderColor="gray.300"
            padding={2}
            borderRadius={4}
            cursor="pointer"
          >
            <Icon as={FaShoppingCart} h={5} w={5} />
            <Icon
              as={FaHistory}
              position="absolute"
              top="5px"
              right="3px"
              h={3}
              w={3}
              color="black"
              bg="white"
              borderRadius="full"
              border="1px"
              borderColor="white"
            />
          </Flex>
        </PopoverTrigger>
        <PopoverContent zIndex="100" width="fit-content">
          <PopoverArrow />
          <PopoverCloseButton />
          <PopoverHeader color={'black'}>Patient Product History</PopoverHeader>
          <PopoverBody zIndex="100" color={'black'} width="fit-content">
            <PatientProductHistoryTable patientId={patientId} />
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Box>
  );
};

export default PatientProductHistoryPopover;
