import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { PaymentMethodFieldsFragmentDoc } from '../../fragments/paymentMethodFields.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type InsertPaymentMethodMutationVariables = Types.Exact<{
  paymentMethod: Types.PaymentMethod_Insert_Input;
}>;


export type InsertPaymentMethodMutation = { __typename?: 'mutation_root', insert_paymentMethod?: { __typename?: 'paymentMethod_mutation_response', returning: Array<{ __typename?: 'paymentMethod', id: any, patientId?: any | null | undefined, workspaceId: any, token: string, payrixCustomerId?: string | null | undefined, last4: string, createdAt: any, default: boolean }> } | null | undefined };


export const InsertPaymentMethodDocument = gql`
    mutation insertPaymentMethod($paymentMethod: paymentMethod_insert_input!) {
  insert_paymentMethod(objects: [$paymentMethod]) {
    returning {
      ...PaymentMethodFields
    }
  }
}
    ${PaymentMethodFieldsFragmentDoc}`;
export type InsertPaymentMethodMutationFn = Apollo.MutationFunction<InsertPaymentMethodMutation, InsertPaymentMethodMutationVariables>;

/**
 * __useInsertPaymentMethodMutation__
 *
 * To run a mutation, you first call `useInsertPaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertPaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertPaymentMethodMutation, { data, loading, error }] = useInsertPaymentMethodMutation({
 *   variables: {
 *      paymentMethod: // value for 'paymentMethod'
 *   },
 * });
 */
export function useInsertPaymentMethodMutation(baseOptions?: Apollo.MutationHookOptions<InsertPaymentMethodMutation, InsertPaymentMethodMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertPaymentMethodMutation, InsertPaymentMethodMutationVariables>(InsertPaymentMethodDocument, options);
      }
export type InsertPaymentMethodMutationHookResult = ReturnType<typeof useInsertPaymentMethodMutation>;
export type InsertPaymentMethodMutationResult = Apollo.MutationResult<InsertPaymentMethodMutation>;
export type InsertPaymentMethodMutationOptions = Apollo.BaseMutationOptions<InsertPaymentMethodMutation, InsertPaymentMethodMutationVariables>;