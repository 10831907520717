import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { CouponWithConfigurationFragmentDoc } from '../../fragments/couponWithConfiguration.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetCouponsQueryVariables = Types.Exact<{
  where?: Types.Maybe<Types.Coupon_Bool_Exp>;
  limit?: Types.Maybe<Types.Scalars['Int']>;
  offset?: Types.Maybe<Types.Scalars['Int']>;
  orderBy?: Types.Maybe<Array<Types.Coupon_Order_By> | Types.Coupon_Order_By>;
}>;


export type GetCouponsQuery = { __typename?: 'query_root', coupon: Array<{ __typename?: 'coupon', id: any, name: string, rule: string, amount: any, type: Types.CouponType_Enum, scope: Types.CouponScope_Enum, validFrom: any, validUntil: any, isActive: boolean, packageId?: any | null | undefined, isSystemGenerated: boolean, configuration?: { __typename?: 'couponConfiguration', maxApplication?: number | null | undefined } | null | undefined, locationCoupons: Array<{ __typename?: 'locationCoupon', location: { __typename?: 'location', id: any, isActive: boolean, name: string, address?: string | null | undefined, phoneNumber?: string | null | undefined, email?: string | null | undefined, maxPatientsPerTimeslot?: number | null | undefined, workspaceId: any, clinicId?: number | null | undefined, addressId?: any | null | undefined, workStartTime?: string | null | undefined, workEndTime?: string | null | undefined, timezone?: string | null | undefined, payrixMerchantId?: string | null | undefined, finixMerchantId?: string | null | undefined, locationSchedules: Array<{ __typename?: 'locationSchedule', id: any, schedule: any, effectiveFrom?: any | null | undefined, createdAt: any, updatedAt: any, locationId: any }>, fullAddress?: { __typename?: 'address', addressLine1: string, addressLine2?: string | null | undefined, city: string, id: any, state: string, zipCode: string } | null | undefined, rooms: Array<{ __typename?: 'room', id: any, name: string }> } }>, serviceCoupons: Array<{ __typename?: 'serviceCoupon', service: { __typename?: 'service', id: any, name: string } }> }> };


export const GetCouponsDocument = gql`
    query GetCoupons($where: coupon_bool_exp, $limit: Int, $offset: Int, $orderBy: [coupon_order_by!]) {
  coupon(where: $where, limit: $limit, offset: $offset, order_by: $orderBy) {
    ...CouponWithConfiguration
  }
}
    ${CouponWithConfigurationFragmentDoc}`;

/**
 * __useGetCouponsQuery__
 *
 * To run a query within a React component, call `useGetCouponsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCouponsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCouponsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetCouponsQuery(baseOptions?: Apollo.QueryHookOptions<GetCouponsQuery, GetCouponsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCouponsQuery, GetCouponsQueryVariables>(GetCouponsDocument, options);
      }
export function useGetCouponsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCouponsQuery, GetCouponsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCouponsQuery, GetCouponsQueryVariables>(GetCouponsDocument, options);
        }
export type GetCouponsQueryHookResult = ReturnType<typeof useGetCouponsQuery>;
export type GetCouponsLazyQueryHookResult = ReturnType<typeof useGetCouponsLazyQuery>;
export type GetCouponsQueryResult = Apollo.QueryResult<GetCouponsQuery, GetCouponsQueryVariables>;