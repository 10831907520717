import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type InsertRewardProgramMutationVariables = Types.Exact<{
  rewardProgram: Types.RewardProgram_Insert_Input;
}>;


export type InsertRewardProgramMutation = { __typename?: 'mutation_root', insert_rewardProgram_one?: { __typename?: 'rewardProgram', id: any } | null | undefined };


export const InsertRewardProgramDocument = gql`
    mutation insertRewardProgram($rewardProgram: rewardProgram_insert_input!) {
  insert_rewardProgram_one(object: $rewardProgram) {
    id
  }
}
    `;
export type InsertRewardProgramMutationFn = Apollo.MutationFunction<InsertRewardProgramMutation, InsertRewardProgramMutationVariables>;

/**
 * __useInsertRewardProgramMutation__
 *
 * To run a mutation, you first call `useInsertRewardProgramMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertRewardProgramMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertRewardProgramMutation, { data, loading, error }] = useInsertRewardProgramMutation({
 *   variables: {
 *      rewardProgram: // value for 'rewardProgram'
 *   },
 * });
 */
export function useInsertRewardProgramMutation(baseOptions?: Apollo.MutationHookOptions<InsertRewardProgramMutation, InsertRewardProgramMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertRewardProgramMutation, InsertRewardProgramMutationVariables>(InsertRewardProgramDocument, options);
      }
export type InsertRewardProgramMutationHookResult = ReturnType<typeof useInsertRewardProgramMutation>;
export type InsertRewardProgramMutationResult = Apollo.MutationResult<InsertRewardProgramMutation>;
export type InsertRewardProgramMutationOptions = Apollo.BaseMutationOptions<InsertRewardProgramMutation, InsertRewardProgramMutationVariables>;