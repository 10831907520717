import {
  FormControl,
  FormLabel,
  Button,
  Stack,
  Textarea,
  useBreakpointValue,
  Switch,
  Text,
  Spinner,
  HStack,
  useDisclosure,
} from '@chakra-ui/react';
import {
  Service,
  useGetServicesLazyQuery,
  LocationFieldsFragment,
  useGetLocationsQuery,
  Patient,
  Service_Bool_Exp,
  Location_Order_By,
  PatientTableFieldsFragment,
} from '@webapp/graphql';
import { AppointmentService, Room, useStores } from '@webapp/state-models';
import {
  GenericObjectSelect,
  PatientSearchDropdown,
  QuickPatientForm,
} from '@webapp/ui';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';

import { useLoadServices } from '@webapp/webapp/hooks';
import { MultiValue, SingleValue } from 'react-select';
import { FaPlus } from 'react-icons/fa';
import { ObjectOption } from '@webapp/types';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import './create-appointment-drawer.module.scss';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import AppointmentManualDate from './appointment-manual-date';
import AppointmentServiceForm from '../AppointmentServiceForm/AppointmentServiceForm';
import PatientTags from '../PatientTags/PatientTags';
import PatientProductHistoryPopover from '../PatientProductHistory/PatientProductHistoryPopover';

dayjs.extend(customParseFormat);
dayjs.extend(utc);
dayjs.extend(timezone);

interface CreatePatientAppointmentProps {
  'data-testid'?: string;
  hasFormError?: boolean;
}

const CreatePatientAppointment = observer(
  (props: CreatePatientAppointmentProps) => {
    const { draftAppointment, workspace } = useStores();
    const { hasFormError } = props;
    const { onOpen, onClose, isOpen } = useDisclosure();

    const [creatingPatient, setCreatingPatient] = useState<boolean>(false);
    const [currentPatient, setCurrentPatient] =
      useState<PatientTableFieldsFragment | null>(null);
    const [profileNote, setProfileNote] = useState<string>('');
    const [isReorderingServices, setIsReorderingServices] =
      useState<boolean>(false);

    const isMobileOrTablet = useBreakpointValue({
      base: true,
      md: true,
      lg: true,
      xl: false,
    });

    const [getServices, { data: services }] = useGetServicesLazyQuery();

    const locationQueryVariables = draftAppointment?.providerId
      ? {
          variables: {
            where: {
              locationProviders: {
                providerId: {
                  _eq: draftAppointment?.providerId,
                },
              },
              isActive: {
                _eq: true,
              },
              isSystemManaged: {
                _eq: false,
              },
            },
            orderBy: [{ name: 'asc' } as Location_Order_By],
          },
        }
      : {
          variables: {
            where: {
              isActive: {
                _eq: true,
              },
              isSystemManaged: {
                _eq: false,
              },
            },
            orderBy: [{ name: 'asc' } as Location_Order_By],
          },
        };

    const { data: locations } = useGetLocationsQuery(locationQueryVariables);

    useEffect(() => {
      if (draftAppointment?.location) {
        const providerFilter = {
          serviceProviders: {
            providerId: { _eq: draftAppointment?.providerId },
          },
        };
        const where: Service_Bool_Exp = {
          _and: [
            {
              locationServices: {
                locationId: { _eq: draftAppointment?.location?.id },
              },
            },
            ...(draftAppointment?.providerId ? [providerFilter] : []),
          ],
        };
        getServices({
          variables: {
            where,
          },
        });
      }
    }, [draftAppointment?.location, draftAppointment?.providerId, getServices]);

    const onCreatePatient = (): void => {
      setCreatingPatient(true);
    };

    const closeCreatePatient = (): void => {
      setCreatingPatient(false);
    };

    const onPatientCreated = (patient: Patient): void => {
      draftAppointment?.setPatient(patient);
      setCreatingPatient(false);
    };

    const onSelectPatient = (
      selectedPatient: PatientTableFieldsFragment
    ): void => {
      draftAppointment?.setPatient(selectedPatient);
      setCurrentPatient(selectedPatient);
      const patientWorkspace = selectedPatient.patientWorkspaces.find(
        (patient) => patient.workspaceId === workspace?.id
      );
      setProfileNote(patientWorkspace?.profileNote || '');
    };

    const handleSelectedService = (
      newValue:
        | SingleValue<ObjectOption<Service | null>>
        | MultiValue<ObjectOption<Service | null>>,
      order: number
    ) => {
      draftAppointment?.addService(
        (newValue as SingleValue<ObjectOption<Service | null>>)
          ?.object as Service,
        order
      );
    };

    const handleSelectedLocation = (
      newValue:
        | SingleValue<ObjectOption<LocationFieldsFragment | null>>
        | MultiValue<ObjectOption<LocationFieldsFragment | null>>
    ) => {
      draftAppointment?.setLocation(
        (newValue as SingleValue<ObjectOption<LocationFieldsFragment | null>>)
          ?.object as LocationFieldsFragment
      );
    };

    const handleSelectedRoom = (
      newValue:
        | SingleValue<ObjectOption<Room | null>>
        | MultiValue<ObjectOption<Room | null>>
    ) => {
      draftAppointment?.setRoom(
        (newValue as SingleValue<ObjectOption<Room | null>>)?.object as Room
      );
    };

    const moveService = (order: number, direction: 'up' | 'down') => {
      if (
        (direction === 'up' && order === 0) ||
        (direction === 'down' &&
          order === draftAppointment?.services.length - 1)
      )
        return;
      const serviceToMove = draftAppointment?.services[order];
      const newOrder = order + (direction === 'up' ? -1 : 1);
      const serviceAtNewIndex = draftAppointment?.services[newOrder];
      serviceToMove?.setOrder(newOrder);
      serviceAtNewIndex?.setOrder(order);
      draftAppointment?.calculateServiceTimeRanges();
    };

    const removeService = (service: AppointmentService) => {
      draftAppointment?.removeService(service);
    };

    if (creatingPatient) {
      return (
        <QuickPatientForm
          onCreatePatient={onPatientCreated}
          onClose={closeCreatePatient}
        />
      );
    }

    return (
      <Stack spacing={4} {...props}>
        {draftAppointment?.type === 'patient_appointment' && (
          <>
            <HStack alignItems="flex-end">
              <FormControl id="patient">
                <FormLabel>Patient</FormLabel>
                <PatientSearchDropdown
                  key={draftAppointment?.patient?.id}
                  selectedPatient={
                    draftAppointment?.patient
                      ? (draftAppointment.patient as any)
                      : undefined
                  }
                  onSelectPatient={onSelectPatient}
                  onCreatePatient={onCreatePatient}
                />
              </FormControl>
              {currentPatient && (
                <PatientProductHistoryPopover patientId={currentPatient.id} />
              )}
            </HStack>
            {profileNote && (
              <Text
                color="blue.500"
                fontStyle="italic"
                fontWeight="500"
                paddingInlineStart={0}
                marginTop={0}
                width="100%"
                style={{
                  marginTop: 0,
                }}
              >
                {profileNote}
              </Text>
            )}
            {currentPatient && <PatientTags patient={currentPatient} />}

            {isMobileOrTablet && (
              <Button
                width="fit-content"
                leftIcon={<FaPlus />}
                colorScheme="teal"
                onClick={onCreatePatient}
                variant="ghost"
                size="xs"
              >
                New Patient
              </Button>
            )}
          </>
        )}
        <FormControl id="location" key={draftAppointment?.location?.id}>
          <FormLabel>Location</FormLabel>
          <GenericObjectSelect<LocationFieldsFragment>
            inputId="select-location"
            isAsync={false}
            defaultValue={
              draftAppointment?.location
                ? {
                    label: draftAppointment?.location?.name as string,
                    value: draftAppointment?.location?.id as string,
                    object:
                      draftAppointment?.location as unknown as LocationFieldsFragment,
                  }
                : null
            }
            onChange={handleSelectedLocation}
            selectOptions={locations?.location.map((location) => ({
              label: location.name,
              value: location.id,
              object: location,
            }))}
            placeholder="Select location"
          />
        </FormControl>
        {draftAppointment?.location &&
          draftAppointment?.location.rooms.length > 0 && (
            <FormControl id="room" key={`room-${draftAppointment?.roomId}`}>
              <FormLabel>Room</FormLabel>
              <GenericObjectSelect<Room>
                isAsync={false}
                defaultValue={
                  draftAppointment?.roomId
                    ? {
                        label: draftAppointment?.location.rooms.filter(
                          (r) => r.id === draftAppointment?.roomId
                        )[0].name as string,
                        value: draftAppointment?.location.rooms.filter(
                          (r) => r.id === draftAppointment?.roomId
                        )[0].id as string,
                        object: draftAppointment?.location.rooms.filter(
                          (r) => r.id === draftAppointment?.roomId
                        )[0] as unknown as Room,
                      }
                    : null
                }
                onChange={handleSelectedRoom}
                selectOptions={draftAppointment?.location?.rooms.map(
                  (room) => ({
                    label: room.name,
                    value: room.id,
                    object: room,
                  })
                )}
                placeholder="Select room"
              />{' '}
            </FormControl>
          )}
        {draftAppointment?.services?.length > 1 && (
          <HStack mt={4}>
            <Switch
              isChecked={isReorderingServices}
              onChange={(e) => setIsReorderingServices(e.target.checked)}
            />
            <Text>Reorder services</Text>
          </HStack>
        )}
        {draftAppointment?.services.map((service, index) => (
          <AppointmentServiceForm
            key={`service-${service.id}`}
            isReordering={isReorderingServices}
            order={service.order}
            service={service}
            moveService={(order: number, direction: 'up' | 'down') => {
              moveService(order, direction);
            }}
            removeService={(service: AppointmentService) => {
              removeService(service);
            }}
            hasFormError={hasFormError}
          />
        ))}
        {draftAppointment?.services?.length > 1 && (
          <HStack mt={4}>
            <Switch
              isChecked={isReorderingServices}
              onChange={(e) => setIsReorderingServices(e.target.checked)}
            />
            <Text>Reorder services</Text>
          </HStack>
        )}
        {services && (
          <HStack alignItems="center">
            <FormControl id="services">
              <FormLabel>
                {(draftAppointment?.services?.length || 0) > 0
                  ? 'Add Service to appointment'
                  : 'Service'}
              </FormLabel>
              <GenericObjectSelect<Service>
                key={draftAppointment?.services?.length}
                inputId="select-service"
                isAsync
                onChange={(newValue) =>
                  handleSelectedService(
                    newValue,
                    draftAppointment?.services?.length || 0
                  )
                }
                useLoadOptions={() =>
                  useLoadServices({
                    groupByCategory: true,
                    where: {
                      _and: [
                        {
                          locationServices: {
                            locationId: {
                              _eq: draftAppointment?.location?.id,
                            },
                          },
                        },
                        // {
                        //   serviceProviders: {
                        //     providerId: {
                        //       _eq: draftAppointment?.provider?.id,
                        //     },
                        //   },
                        // },
                      ],
                    },
                  })
                }
                placeholder="Search for a service"
              />
            </FormControl>
            {draftAppointment?.loading && (
              <Spinner
                color="blue.600"
                emptyColor="gray.200"
                size="sm"
                thickness="4px"
              />
            )}
          </HStack>
        )}
        <AppointmentManualDate></AppointmentManualDate>
        <FormControl id="appointment-note">
          <FormLabel>Appointment note</FormLabel>
          <Textarea
            type="textarea"
            defaultValue={draftAppointment?.note || undefined}
            onChange={(e) => draftAppointment?.setNote(e.target.value)}
            placeholder="Anything of note?"
          ></Textarea>
        </FormControl>
        <FormControl id="appointment-setDisableConfirmationsReminders">
          <FormLabel>Disable confirmations / reminders</FormLabel>
          <Switch
            isChecked={draftAppointment?.disableConfirmationsReminders || false}
            onChange={(e) =>
              draftAppointment?.setDisableConfirmationsReminders(
                e.target.checked
              )
            }
          />
        </FormControl>
      </Stack>
    );
  }
);

export default CreatePatientAppointment;
