import * as Types from '../../generated/graphql';

import { gql } from '@apollo/client';
export type ExternalReviewFieldsFragment = { __typename?: 'externalReview', comment: string, externalId: string, externalSource: string, id: any, rating?: number | null | undefined, response?: string | null | undefined, reviewDate: any, reviewerName: string, reviewerPhotoUrl?: string | null | undefined, location?: { __typename?: 'location', id: any, name: string } | null | undefined, provider?: { __typename?: 'provider', firstName: string, id: any, lastName: string, title?: string | null | undefined } | null | undefined };

export const ExternalReviewFieldsFragmentDoc = gql`
    fragment ExternalReviewFields on externalReview {
  comment
  externalId
  externalSource
  id
  location {
    id
    name
  }
  provider {
    firstName
    id
    lastName
    title
  }
  rating
  response
  reviewDate
  reviewerName
  reviewerPhotoUrl
}
    `;