import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type SearchLocationsQueryVariables = Types.Exact<{
  limit?: Types.Maybe<Types.Scalars['Int']>;
  where: Types.Location_Bool_Exp;
}>;


export type SearchLocationsQuery = { __typename?: 'query_root', location: Array<{ __typename?: 'location', id: any, name: string, timezone?: string | null | undefined }> };


export const SearchLocationsDocument = gql`
    query searchLocations($limit: Int, $where: location_bool_exp!) {
  location(where: $where, order_by: {name: asc}) {
    id
    name
    timezone
  }
}
    `;

/**
 * __useSearchLocationsQuery__
 *
 * To run a query within a React component, call `useSearchLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchLocationsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useSearchLocationsQuery(baseOptions: Apollo.QueryHookOptions<SearchLocationsQuery, SearchLocationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchLocationsQuery, SearchLocationsQueryVariables>(SearchLocationsDocument, options);
      }
export function useSearchLocationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchLocationsQuery, SearchLocationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchLocationsQuery, SearchLocationsQueryVariables>(SearchLocationsDocument, options);
        }
export type SearchLocationsQueryHookResult = ReturnType<typeof useSearchLocationsQuery>;
export type SearchLocationsLazyQueryHookResult = ReturnType<typeof useSearchLocationsLazyQuery>;
export type SearchLocationsQueryResult = Apollo.QueryResult<SearchLocationsQuery, SearchLocationsQueryVariables>;