import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type InsertCouponConfigurationMutationVariables = Types.Exact<{
  config: Types.CouponConfiguration_Insert_Input;
}>;


export type InsertCouponConfigurationMutation = { __typename?: 'mutation_root', insert_couponConfiguration?: { __typename?: 'couponConfiguration_mutation_response', returning: Array<{ __typename?: 'couponConfiguration', couponId: any, id: any, maxApplication?: number | null | undefined }> } | null | undefined };


export const InsertCouponConfigurationDocument = gql`
    mutation insertCouponConfiguration($config: couponConfiguration_insert_input!) {
  insert_couponConfiguration(
    objects: [$config]
    on_conflict: {constraint: couponConfiguration_couponId_key, update_columns: [maxApplication]}
  ) {
    returning {
      couponId
      id
      maxApplication
    }
  }
}
    `;
export type InsertCouponConfigurationMutationFn = Apollo.MutationFunction<InsertCouponConfigurationMutation, InsertCouponConfigurationMutationVariables>;

/**
 * __useInsertCouponConfigurationMutation__
 *
 * To run a mutation, you first call `useInsertCouponConfigurationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertCouponConfigurationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertCouponConfigurationMutation, { data, loading, error }] = useInsertCouponConfigurationMutation({
 *   variables: {
 *      config: // value for 'config'
 *   },
 * });
 */
export function useInsertCouponConfigurationMutation(baseOptions?: Apollo.MutationHookOptions<InsertCouponConfigurationMutation, InsertCouponConfigurationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertCouponConfigurationMutation, InsertCouponConfigurationMutationVariables>(InsertCouponConfigurationDocument, options);
      }
export type InsertCouponConfigurationMutationHookResult = ReturnType<typeof useInsertCouponConfigurationMutation>;
export type InsertCouponConfigurationMutationResult = Apollo.MutationResult<InsertCouponConfigurationMutation>;
export type InsertCouponConfigurationMutationOptions = Apollo.BaseMutationOptions<InsertCouponConfigurationMutation, InsertCouponConfigurationMutationVariables>;