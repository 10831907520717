/* eslint-disable camelcase */
import { GraphqlClient, UpdateDeviceDocument } from '@webapp/graphql';
import { Schedule } from '@webapp/types';
import { getErrorMessage } from '@webapp/utils';
import {
  Instance,
  SnapshotOut,
  types,
  flow,
  applySnapshot,
} from 'mobx-state-tree';
import toast from 'react-hot-toast';
import { AnyJsonValue } from '../custom-types/any-json-value';
import { LocationModel } from './location';

/**
 * A DeviceStore model.
 */
// prettier-ignore
export const DeviceModel = types
.model('Device')
.props({
  id: types.identifier,
  name: types.string,
  deviceType: types.maybeNull(types.model('DeviceType').props({ id: types.identifier, value: types.string})),
  locationId: types.maybeNull(types.string),
  location: types.maybeNull(LocationModel),
  schedule: types.maybeNull(AnyJsonValue),
})
.actions((self) => ({
   update: flow(function* (set:any) {
    
    const client = GraphqlClient();
    try {
      const {
        data: {
          update_device_by_pk,
        },
      } = yield client.mutate({
        mutation: UpdateDeviceDocument,
        variables: {
          
            id: self.id,
            set
          
        },
      });
      applySnapshot(self, update_device_by_pk);
      toast.success(`Device ${self.name} updated`);
    }
    catch (error) {
      toast.error(getErrorMessage(error));
    }
  }),
  createAvailability: flow(function* () {
    const client = GraphqlClient();
    try {
      const {
        data: {
            update_device_by_pk
        },
      } = yield client.mutate({
        mutation: UpdateDeviceDocument,
        variables: {
          id: self.id,
          set: {
            schedule: {
              0: [],
              1: [
                { start: '09:00', end: '12:00' },
                { start: '13:00', end: '17:00' },
              ],
              2: [
                { start: '09:00', end: '12:00' },
                { start: '13:00', end: '17:00' },
              ],
              3: [
                { start: '09:00', end: '12:00' },
                { start: '13:00', end: '17:00' },
              ],
              4: [
                { start: '09:00', end: '12:00' },
                { start: '13:00', end: '17:00' },
              ],
              5: [
                { start: '09:00', end: '12:00' },
                { start: '13:00', end: '17:00' },
              ],
              6: [],
            },
          },
        },
      });
      self.schedule = update_device_by_pk.schedule;
    } catch (e) {
      toast.error(getErrorMessage(e));
    }
  }),
  updateAvailability: flow(function* (

    schedule: Schedule
  ) {
    const client = GraphqlClient();
    try {
       yield client.mutate({
        mutation: UpdateDeviceDocument,
        variables: {
          id: self.id,
          set: {
            schedule,
          },
        },
      });
     
      toast.success('Availability updated');
    } catch (e) {
      toast.error(getErrorMessage(e));
    }
  }),
}));
/**
 * The Device instance.
 */
export type Device = Instance<typeof DeviceModel>;

/**
 * The data of a Device.
 */
export type DeviceSnapshot = SnapshotOut<typeof DeviceModel>;
