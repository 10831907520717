import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { PatientMembershipFieldsFragmentDoc } from '../../fragments/patientMembershipFields.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetPatientMembershipsQueryVariables = Types.Exact<{
  where: Types.PatientMembership_Bool_Exp;
  limit?: Types.Maybe<Types.Scalars['Int']>;
  offset?: Types.Maybe<Types.Scalars['Int']>;
  orderBy?: Types.Maybe<Array<Types.PatientMembership_Order_By> | Types.PatientMembership_Order_By>;
}>;


export type GetPatientMembershipsQuery = { __typename?: 'query_root', patientMembership: Array<{ __typename?: 'patientMembership', id: any, status: string, createdAt: any, updatedAt: any, nextPaymentDate?: any | null | undefined, subscriptionEndDate?: any | null | undefined, membership: { __typename?: 'membership', id: any, name: string, type: Types.MembershipType_Enum, price: number, taxRate: any, description?: string | null | undefined, image?: string | null | undefined, availableInStore?: boolean | null | undefined, discount: number, billingPeriodInDays: number, maxBillingCycles?: number | null | undefined, billingDayOfMonth?: number | null | undefined, createdAt: any, updatedAt: any, locationId?: any | null | undefined, workspaceId: any, color: string, hasCoupons: boolean, hasCredits: boolean, credits: number, archived: boolean, location?: { __typename?: 'location', id: any, name: string } | null | undefined, membershipCoupons: Array<{ __typename?: 'membershipCoupon', count: number, coupon: { __typename?: 'coupon', id: any, name: string } }> }, payments: Array<{ __typename?: 'payment', createdAt: any, id: any, workspaceId: any, paymentMethodId?: any | null | undefined, paymentMethodType: string, payrixType?: string | null | undefined, amount: number, invoiceId?: any | null | undefined, status: string, type?: string | null | undefined }> }> };


export const GetPatientMembershipsDocument = gql`
    query GetPatientMemberships($where: patientMembership_bool_exp!, $limit: Int, $offset: Int, $orderBy: [patientMembership_order_by!]) {
  patientMembership(
    where: $where
    limit: $limit
    offset: $offset
    order_by: $orderBy
  ) {
    ...PatientMembershipFields
  }
}
    ${PatientMembershipFieldsFragmentDoc}`;

/**
 * __useGetPatientMembershipsQuery__
 *
 * To run a query within a React component, call `useGetPatientMembershipsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPatientMembershipsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPatientMembershipsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetPatientMembershipsQuery(baseOptions: Apollo.QueryHookOptions<GetPatientMembershipsQuery, GetPatientMembershipsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPatientMembershipsQuery, GetPatientMembershipsQueryVariables>(GetPatientMembershipsDocument, options);
      }
export function useGetPatientMembershipsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPatientMembershipsQuery, GetPatientMembershipsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPatientMembershipsQuery, GetPatientMembershipsQueryVariables>(GetPatientMembershipsDocument, options);
        }
export type GetPatientMembershipsQueryHookResult = ReturnType<typeof useGetPatientMembershipsQuery>;
export type GetPatientMembershipsLazyQueryHookResult = ReturnType<typeof useGetPatientMembershipsLazyQuery>;
export type GetPatientMembershipsQueryResult = Apollo.QueryResult<GetPatientMembershipsQuery, GetPatientMembershipsQueryVariables>;