import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type UpdateTagsMutationVariables = Types.Exact<{
  set: Types.Tag_Set_Input;
  where: Types.Tag_Bool_Exp;
}>;


export type UpdateTagsMutation = { __typename?: 'mutation_root', update_tag?: { __typename?: 'tag_mutation_response', affected_rows: number } | null | undefined };


export const UpdateTagsDocument = gql`
    mutation UpdateTags($set: tag_set_input!, $where: tag_bool_exp!) {
  update_tag(_set: $set, where: $where) {
    affected_rows
  }
}
    `;
export type UpdateTagsMutationFn = Apollo.MutationFunction<UpdateTagsMutation, UpdateTagsMutationVariables>;

/**
 * __useUpdateTagsMutation__
 *
 * To run a mutation, you first call `useUpdateTagsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTagsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTagsMutation, { data, loading, error }] = useUpdateTagsMutation({
 *   variables: {
 *      set: // value for 'set'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateTagsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTagsMutation, UpdateTagsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTagsMutation, UpdateTagsMutationVariables>(UpdateTagsDocument, options);
      }
export type UpdateTagsMutationHookResult = ReturnType<typeof useUpdateTagsMutation>;
export type UpdateTagsMutationResult = Apollo.MutationResult<UpdateTagsMutation>;
export type UpdateTagsMutationOptions = Apollo.BaseMutationOptions<UpdateTagsMutation, UpdateTagsMutationVariables>;