import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { SocialMediaPostFieldsFragmentDoc } from '../../fragments/socialMediaPostFields.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetSocialMediaPostByPkQueryVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
}>;


export type GetSocialMediaPostByPkQuery = { __typename?: 'query_root', socialMediaPost_by_pk?: { __typename?: 'socialMediaPost', id: any, title: string, caption?: string | null | undefined, isScheduled: boolean, intendedPostAt: any, privatePhoto?: { __typename?: 'photo', id: any, workspaceId: any, patientId?: any | null | undefined, mediaDate?: any | null | undefined, mediaType?: string | null | undefined, mimeType?: string | null | undefined, hasThumbnail?: boolean | null | undefined, isSharedWithPatient: boolean, partialMediaConsent?: boolean | null | undefined, fullMediaConsent?: boolean | null | undefined, patient?: { __typename?: 'patient', firstName: string, id: any, lastName: string } | null | undefined, file?: { __typename?: 'FilePayload', filePath: string, url: string, thumbnailUrl?: string | null | undefined } | null | undefined, photoMediaTags: Array<{ __typename?: 'photoMediaTag', id: any, mediaTag: { __typename?: 'mediaTag', id: any, title: string } }>, uploader?: { __typename?: 'user', firstName: string, id: any, lastName: string } | null | undefined } | null | undefined, socialMediaAccountPosts: Array<{ __typename?: 'socialMediaAccountPost', status: Types.PostStatus_Enum, likeCount?: number | null | undefined, commentCount?: number | null | undefined, socialMediaAccount: { __typename?: 'socialMediaAccount', id: any, name: string, profilePicture?: string | null | undefined, platform: Types.SocialMediaPlatform_Enum } }> } | null | undefined };


export const GetSocialMediaPostByPkDocument = gql`
    query getSocialMediaPostByPk($id: uuid!) {
  socialMediaPost_by_pk(id: $id) {
    ...SocialMediaPostFields
  }
}
    ${SocialMediaPostFieldsFragmentDoc}`;

/**
 * __useGetSocialMediaPostByPkQuery__
 *
 * To run a query within a React component, call `useGetSocialMediaPostByPkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSocialMediaPostByPkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSocialMediaPostByPkQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSocialMediaPostByPkQuery(baseOptions: Apollo.QueryHookOptions<GetSocialMediaPostByPkQuery, GetSocialMediaPostByPkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSocialMediaPostByPkQuery, GetSocialMediaPostByPkQueryVariables>(GetSocialMediaPostByPkDocument, options);
      }
export function useGetSocialMediaPostByPkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSocialMediaPostByPkQuery, GetSocialMediaPostByPkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSocialMediaPostByPkQuery, GetSocialMediaPostByPkQueryVariables>(GetSocialMediaPostByPkDocument, options);
        }
export type GetSocialMediaPostByPkQueryHookResult = ReturnType<typeof useGetSocialMediaPostByPkQuery>;
export type GetSocialMediaPostByPkLazyQueryHookResult = ReturnType<typeof useGetSocialMediaPostByPkLazyQuery>;
export type GetSocialMediaPostByPkQueryResult = Apollo.QueryResult<GetSocialMediaPostByPkQuery, GetSocialMediaPostByPkQueryVariables>;