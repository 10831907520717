/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  Box,
  Button,
  Divider,
  Flex,
  Grid,
  HStack,
  Image,
  Spacer,
  Stack,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { FormInput, ProspyrLogo } from '@webapp/ui';
import {
  useLoadLocations,
  useLoadPatients,
  useLoadProviders,
} from '@webapp/webapp/hooks';
import { observer } from 'mobx-react-lite';
import { useStores } from '@webapp/state-models';
import {
  PatientFieldsFragment,
  useGetInvoiceNumberLazyQuery,
} from '@webapp/graphql';
import { useFormContext, useWatch } from 'react-hook-form';
import { ObjectOption } from '@webapp/types';
import { useEffect, useMemo } from 'react';
import { useFlagsmith } from 'flagsmith-react';
import { CreatePatientModal } from '@webapp/ui-composites';
import { formatName } from '@webapp/util-formatting';
import CreateInvoiceLedger from './InvoiceLedger';
import CreateInvoiceServiceItemForm from './ItemForm';
import MembershipForm from './MembershipForm';
import { InvoiceFormValues } from './types';
import ApplyDiscount from './ApplyDiscount';
import SelectAddressForm from '../SelectAddressForm/SelectAddressForm';
import UpdateInvoiceActions from './UpdateActions';
import PatientWalletInput from './PatientWalletInput';
import PackageForm from './PackageForm';

interface InvoiceFormProps {
  isLoading: boolean;
  onUpdate?: () => void;
  payNow: (values: InvoiceFormValues) => void;
  save: (values: InvoiceFormValues) => void;
}

export function InvoiceFormForm({
  isLoading,
  onUpdate,
  payNow,
  save,
}: InvoiceFormProps) {
  const { user, workspace } = useStores();

  const {
    isOpen: createPatientIsOpen,
    onClose: createPatientOnClose,
    onOpen: createPatientOnOpen,
  } = useDisclosure();

  const { getValues, handleSubmit, setValue } =
    useFormContext<InvoiceFormValues>();

  const { hasFeature } = useFlagsmith();

  const invoiceId = getValues('invoiceId');

  const [getInvoiceNumber, { called, loading: invoiceLoading }] =
    useGetInvoiceNumberLazyQuery({
      onCompleted: ({ getInvoiceNumber: gINData }) => {
        const invoiceNumber = gINData?.invoiceNumber;

        if (invoiceNumber) {
          setValue('invoiceNumber', invoiceNumber);
        }
      },
    });

  const logo = workspace?.logo;

  const invoiceNumber = getValues('invoiceNumber');

  useEffect(() => {
    if (!called && !invoiceId) {
      getInvoiceNumber();
    }
  }, [called, getInvoiceNumber, invoiceId]);

  const patient: ObjectOption<PatientFieldsFragment> | undefined = useWatch({
    name: 'patient',
  });

  const patientWallet = useMemo(
    () =>
      patient?.object?.patientWallets?.length
        ? patient.object.patientWallets[0]
        : undefined,
    [patient]
  );

  useEffect(() => {
    setValue('address', '');
    setValue('email', patient?.object.attributes.email ?? '');
  }, [patient, setValue]);

  if (invoiceLoading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Flex flexDirection="column" width="100%" h={'100%'}>
        <Box padding="0 20px 55px" overflowY="auto">
          <Box
            alignItems="center"
            background="teal.50"
            borderRadius="8px"
            display="grid"
            gridTemplateColumns="1fr max-content"
            marginBottom="30px"
            padding="25px"
            width="100%"
          >
            <Text
              color="gray.800"
              fontSize="16px"
              fontWeight="500"
              letterSpacing="0.01em"
            >
              Invoice Number: {invoiceNumber}
            </Text>
            {logo ? <Image maxHeight="35px" src={logo} /> : <ProspyrLogo />}
          </Box>
          <VStack alignItems="start" spacing="30px" width="100%">
            <HStack alignItems="start" spacing="16px">
              <VStack>
                <FormInput
                  label="Client"
                  name="patient"
                  selectProps={{
                    loadOptions: useLoadPatients,
                  }}
                  type="async-select"
                />
                <Button
                  alignSelf="start"
                  colorScheme="teal"
                  fontSize="12px"
                  height="unset"
                  onClick={createPatientOnOpen}
                  padding="0"
                  textAlign="left"
                  variant="ghost"
                  width="unset"
                  _hover={{
                    background: 'transparent',
                    color: 'teal.400',
                  }}
                >
                  Create New Patient
                </Button>
              </VStack>

              <FormInput
                label="Client Email"
                name="email"
                placeholder="bob@example.com"
                type="email"
              />
              <FormInput
                label="Location"
                name="location"
                selectProps={{
                  loadOptions: () =>
                    useLoadLocations({
                      where: {
                        ...(user?.providerLocationIds?.length
                          ? { id: { _in: user.providerLocationIds } }
                          : {}),
                      },
                    }),
                }}
                type="async-select"
              />
            </HStack>
            {patient && <SelectAddressForm />}
            <HStack alignItems="start" spacing="16px">
              <FormInput
                label="Provider"
                name="provider"
                selectProps={{
                  loadOptions: useLoadProviders,
                }}
                type="async-select"
              />
              <FormInput label="Invoice Date" name="invoiceDate" type="date" />
              <FormInput label="Due Date" name="dueDate" type="date" />
            </HStack>
          </VStack>
          <Divider marginTop="30px" marginBottom="30px" />
          <CreateInvoiceServiceItemForm />
          {hasFeature('memberships') && <MembershipForm />}
          {hasFeature('packages') && <PackageForm />}
          <Grid
            gridTemplateColumns="1fr 1fr 1fr"
            columnGap="200px"
            width="100%"
          >
            <FormInput
              label="Message on Invoice"
              name="message"
              type="textarea"
            />
            <HStack alignItems="start" spacing="16px">
              <Stack>
                <Spacer mb={'30px'} />
                {hasFeature('coupons') && <ApplyDiscount />}
              </Stack>
              {patientWallet && (
                <PatientWalletInput patientWallet={patientWallet} />
              )}
            </HStack>
            <CreateInvoiceLedger invoiceId={invoiceId} />
          </Grid>
        </Box>
      </Flex>
      <HStack
        background="white"
        borderColor="#EDE !important"
        borderTop="1px solid"
        bottom="0"
        justifyContent="end"
        padding="20px"
        position="sticky"
        right="0"
        width="100%"
      >
        {!invoiceId && (
          <>
            <Button
              colorScheme="teal"
              disabled={isLoading ?? invoiceLoading}
              isLoading={isLoading}
              onClick={handleSubmit(save)}
              variant="outline"
            >
              Save Quote
            </Button>
            <Button
              colorScheme="teal"
              isDisabled={isLoading ?? invoiceLoading}
              isLoading={isLoading}
              onClick={handleSubmit(payNow)}
            >
              Pay Now
            </Button>
          </>
        )}
        {invoiceId && <UpdateInvoiceActions onUpdate={onUpdate} />}
        {createPatientIsOpen && (
          <CreatePatientModal
            defaultValues={{
              workspaceId: workspace!.id,
            }}
            isOpen={createPatientIsOpen}
            onClose={createPatientOnClose}
            onCompleted={(newPatient) => {
              setValue('patient', {
                label: formatName(newPatient.attributes),
                object: {
                  attributes: newPatient.attributes,
                  id: newPatient.id,
                  firstName: newPatient.firstName,
                  lastName: newPatient.lastName,
                },
                value: newPatient.id,
              });
            }}
          />
        )}
      </HStack>
    </>
  );
}

export default observer(InvoiceFormForm);
