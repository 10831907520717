import * as Types from '../../generated/graphql';

import { gql } from '@apollo/client';
import { ExternalReviewFieldsFragmentDoc } from '../fragments/externalReviewFields.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetExternalReviewsQueryVariables = Types.Exact<{
  limit: Types.Scalars['Int'];
  offset?: Types.Maybe<Types.Scalars['Int']>;
  orderBy?: Types.Maybe<Array<Types.ExternalReview_Order_By> | Types.ExternalReview_Order_By>;
  where?: Types.Maybe<Types.ExternalReview_Bool_Exp>;
}>;


export type GetExternalReviewsQuery = { __typename?: 'query_root', externalReview: Array<{ __typename?: 'externalReview', comment: string, externalId: string, externalSource: string, id: any, rating?: number | null | undefined, response?: string | null | undefined, reviewDate: any, reviewerName: string, reviewerPhotoUrl?: string | null | undefined, location?: { __typename?: 'location', id: any, name: string } | null | undefined, provider?: { __typename?: 'provider', firstName: string, id: any, lastName: string, title?: string | null | undefined } | null | undefined }> };


export const GetExternalReviewsDocument = gql`
    query GetExternalReviews($limit: Int!, $offset: Int, $orderBy: [externalReview_order_by!], $where: externalReview_bool_exp) {
  externalReview(
    limit: $limit
    offset: $offset
    order_by: $orderBy
    where: $where
  ) {
    ...ExternalReviewFields
  }
}
    ${ExternalReviewFieldsFragmentDoc}`;

/**
 * __useGetExternalReviewsQuery__
 *
 * To run a query within a React component, call `useGetExternalReviewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExternalReviewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExternalReviewsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      orderBy: // value for 'orderBy'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetExternalReviewsQuery(baseOptions: Apollo.QueryHookOptions<GetExternalReviewsQuery, GetExternalReviewsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetExternalReviewsQuery, GetExternalReviewsQueryVariables>(GetExternalReviewsDocument, options);
      }
export function useGetExternalReviewsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetExternalReviewsQuery, GetExternalReviewsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetExternalReviewsQuery, GetExternalReviewsQueryVariables>(GetExternalReviewsDocument, options);
        }
export type GetExternalReviewsQueryHookResult = ReturnType<typeof useGetExternalReviewsQuery>;
export type GetExternalReviewsLazyQueryHookResult = ReturnType<typeof useGetExternalReviewsLazyQuery>;
export type GetExternalReviewsQueryResult = Apollo.QueryResult<GetExternalReviewsQuery, GetExternalReviewsQueryVariables>;