import {
  chakra,
  Button,
  Modal,
  ModalBody,
  ModalContent,
  useDisclosure,
  StyleProps,
  ModalCloseButton,
  ModalProps,
  ButtonGroup,
  Text,
} from '@chakra-ui/react';
import { useSignAndSave } from '@webapp/hooks';
import { toLocalePriceInCents } from '@webapp/util-formatting';
import { useEffect, useMemo, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import ReactSignatureCanvas from 'react-signature-canvas';
import {
  DEFAULT_GRATUITY_BUTTON_STYLE,
  DEFAULT_GRATUITY_OPTIONS,
} from './constants';
import CustomGratuityModalInterface from './CustomGratuityModalInterface';
import DefaultGratuityModalInterface from './DefaultGratuityModalInterface';
import GratuitySignatureView from './GratuitySignatureView';
import { GratuityModalOptions } from './types';

export interface GratuityModalProps
  extends Pick<ModalProps, 'colorScheme' | 'size'> {
  components?: {
    button: React.ReactNode;
  };
  defaultValue?: number;
  options?: GratuityModalOptions;
  onSign?: (signatureId: string) => void;
  onSubmit: (value: number) => void;
  patientId?: string;
  providerId?: string;
  signOnSubmit?: boolean;
  styles?: {
    button?: StyleProps;
  };
  text?: {
    button?: string;
  };
  total?: number | null;
  userId?: string;
}

export function GratuityModal({
  colorScheme = 'teal',
  defaultValue = 0,
  onSign = () => {
    toast.error('Unhandled Signature');
  },
  onSubmit,
  options = DEFAULT_GRATUITY_OPTIONS,
  patientId,
  providerId,
  signOnSubmit = false,
  size = 'full',
  styles = {},
  text = {},
  total,
  userId,
}: GratuityModalProps) {
  const [isEditable, setIsEditable] = useState<boolean>(false);
  const [gratuity, setGratuity] = useState<number>(defaultValue);
  const signatureRef = useRef<ReactSignatureCanvas>(null);
  const [view, setView] = useState<'gratuity' | 'signature'>('gratuity');

  const { base64Signature, initialized, loading, sign } = useSignAndSave({
    onCompleted: (signatureId) => {
      onSign(signatureId);
      onSubmit(gratuity);
      onClose();
    },
    patientId,
    providerId,
    userId,
  });

  const { button: buttonText = 'Add Tip' } = text;
  const { button: buttonStyles = DEFAULT_GRATUITY_BUTTON_STYLE } = styles;

  const modal = useDisclosure();

  const totalNumber = total || 0;
  const totalString = toLocalePriceInCents(totalNumber + gratuity);

  function onClose() {
    setGratuity(defaultValue);
    setView('gratuity');
    modal.onClose();
  }

  useEffect(() => {
    setGratuity(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    if (base64Signature && view === 'signature') {
      signatureRef?.current?.fromDataURL(base64Signature);
    }
  }, [base64Signature, view]);

  const provisionedOptions = useMemo(
    () =>
      options.map((option) => ({
        ...option,
        selected: option.amount === gratuity / totalNumber,
      })),
    [gratuity, options, totalNumber]
  );

  return (
    <>
      <Button
        colorScheme={colorScheme}
        onClick={modal.onToggle}
        {...buttonStyles}
      >
        {buttonText}
      </Button>
      <Modal
        colorScheme={colorScheme}
        isOpen={modal.isOpen}
        onClose={onClose}
        size={size}
      >
        <ModalContent padding={{ base: '0', md: '100px' }}>
          <ModalCloseButton />
          <ModalBody>
            <chakra.div padding={{ base: '25px 0 0', md: '0' }} width="100%">
              <Text fontSize="3xl" fontWeight="bold" textAlign="center">
                Total: {totalString}
              </Text>
              <Text opacity=".65" textAlign="center">
                {toLocalePriceInCents(totalNumber)} +{' '}
                {toLocalePriceInCents(gratuity)} Tip
              </Text>
            </chakra.div>
            <>
              {view === 'gratuity' && (
                <>
                  {!isEditable && (
                    <DefaultGratuityModalInterface
                      colorScheme={colorScheme}
                      gratuity={gratuity}
                      onChange={setGratuity}
                      onEditClick={() => {
                        setGratuity(0);
                        setIsEditable(true);
                      }}
                      options={provisionedOptions}
                      totalNumber={totalNumber}
                    />
                  )}
                  {isEditable && (
                    <CustomGratuityModalInterface
                      gratuity={gratuity}
                      onChange={setGratuity}
                      onReturnClick={() => {
                        setGratuity(0);
                        setIsEditable(false);
                      }}
                    />
                  )}
                </>
              )}
              <GratuitySignatureView
                hide={view !== 'signature'}
                isLoading={!initialized || loading}
                onComplete={() => undefined}
                patientId={patientId}
                providerId={providerId}
                ref={signatureRef}
                userId={userId}
              />
              <ButtonGroup
                display="grid"
                gridColumnGap="8px"
                gridTemplateColumns="1fr 1fr"
                margin="0 auto"
                maxWidth="800px"
                padding="45px"
              >
                <Button fontSize="lg" height="60px" onClick={onClose}>
                  Cancel
                </Button>
                <Button
                  colorScheme={colorScheme}
                  fontSize="lg"
                  height="60px"
                  onClick={() => {
                    if (signOnSubmit && view !== 'signature') {
                      setView('signature');
                    } else if (signOnSubmit) {
                      sign(signatureRef);
                    } else {
                      onSubmit(gratuity);
                      onClose();
                    }
                  }}
                >
                  {view === 'signature' || !signOnSubmit ? 'Submit' : 'Sign'}
                </Button>
              </ButtonGroup>
            </>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default GratuityModal;
