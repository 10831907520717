/* eslint-disable no-nested-ternary */
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
} from '@chakra-ui/react';
import { UploadPhoto } from '@webapp/ui';
import { observer } from 'mobx-react-lite';
import { Photo as MSTPhoto, useStores } from '@webapp/state-models';
import { Photo, StorageType } from '@webapp/graphql';
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { ConfigurePhotoDetails } from '../configure-photo-details/configure-photo-details';

export interface MediaUploadModalProps {
  appointmentId?: string;
  draftPhoto?: MSTPhoto | null | undefined;
  setDraftPhoto?: (draftPhoto?: MSTPhoto) => void;
  isOpen: boolean;
  onClose: () => void;
  onComplete?: (photo: Photo) => void;
  patientId?: string;
  storageType?: StorageType;
  EditComponent?: ({ photo }: { photo: MSTPhoto }) => ReactNode;
}

export const MediaUploadModal = observer(
  ({
    appointmentId,
    isOpen,
    onClose,
    onComplete,
    draftPhoto: draftPhotoFromProps,
    patientId,
    setDraftPhoto: setDraftPhotoFromProps,
    EditComponent,
  }: MediaUploadModalProps) => {
    const {
      draftPhotos,
      clearDraftPhotos,
      currentDraftPhotoIndex,
      bulkFilesUploading,
    } = useStores();

    const [draftPhotoFromState, setDraftPhotoFromState] = useState<
      MSTPhoto | null | undefined
    >(null);

    const draftPhoto = useMemo(() => {
      if (draftPhotoFromProps) {
        return draftPhotoFromProps;
      }

      return draftPhotoFromState;
    }, [draftPhotoFromProps, draftPhotoFromState]);

    function handleMedia(photo?: MSTPhoto) {
      setDraftPhotoFromState(photo);
    }

    const setDraftPhoto = useCallback(
      (photo?: MSTPhoto) => {
        if (setDraftPhotoFromProps) {
          setDraftPhotoFromProps(photo);
        } else {
          handleMedia(photo);
        }
      },
      [setDraftPhotoFromProps]
    );

    useEffect(() => {
      if (
        draftPhotos &&
        draftPhotos[currentDraftPhotoIndex] &&
        !bulkFilesUploading
      ) {
        setDraftPhoto(draftPhotos[currentDraftPhotoIndex]);
      }
    }, [
      currentDraftPhotoIndex,
      draftPhotos,
      draftPhotos.length,
      bulkFilesUploading,
      setDraftPhoto,
    ]);

    function handleModalClose() {
      onClose();
      setDraftPhoto();
      clearDraftPhotos();
    }

    return (
      <Modal isOpen={isOpen} onClose={handleModalClose} size="full">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {draftPhoto ? 'Configure Media Details' : 'Upload Photo or Video'}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {draftPhoto ? (
              typeof EditComponent === 'function' ? (
                EditComponent({ photo: draftPhoto })
              ) : (
                <ConfigurePhotoDetails photo={draftPhoto} />
              )
            ) : (
              <UploadPhoto
                appointmentId={appointmentId}
                onComplete={onComplete}
                patientId={patientId}
              />
            )}
          </ModalBody>

          <ModalFooter>
            <Button variant="ghost" onClick={handleModalClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  }
);

export default MediaUploadModal;
