import { extendTheme } from '@chakra-ui/react';

export const theme = extendTheme({
  components: {
    Drawer: {
      variants: {
        prospyr: {
          header: {
            color: '#027589',
            bg: '#EAFCFF',
            fontWeight: 'bold',
            py: '4',
            pl: '6',
            size: 'lg',
          },
          closeButton: {
            color: '#027589',
            p: '6',
          },
        },
      },
    },
  },
});

export default theme;
