import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { ServiceCategoryFieldsFragmentDoc } from '../../fragments/serviceCategoryFields.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetServiceCategoriesQueryVariables = Types.Exact<{
  where?: Types.Maybe<Types.ServiceCategory_Bool_Exp>;
  limit?: Types.Maybe<Types.Scalars['Int']>;
  offset?: Types.Maybe<Types.Scalars['Int']>;
  orderBy?: Types.Maybe<Array<Types.ServiceCategory_Order_By> | Types.ServiceCategory_Order_By>;
}>;


export type GetServiceCategoriesQuery = { __typename?: 'query_root', serviceCategory: Array<{ __typename?: 'serviceCategory', id: any, title: string, serviceCount: { __typename?: 'service_aggregate', aggregate?: { __typename?: 'service_aggregate_fields', count: number } | null | undefined }, productCount: { __typename?: 'service_aggregate', aggregate?: { __typename?: 'service_aggregate_fields', count: number } | null | undefined } }> };


export const GetServiceCategoriesDocument = gql`
    query GetServiceCategories($where: serviceCategory_bool_exp, $limit: Int, $offset: Int, $orderBy: [serviceCategory_order_by!]) {
  serviceCategory(
    where: $where
    limit: $limit
    offset: $offset
    order_by: $orderBy
  ) {
    ...ServiceCategoryFields
    serviceCount: services_aggregate(where: {isProduct: {_eq: false}}) {
      aggregate {
        count
      }
    }
    productCount: services_aggregate(where: {isProduct: {_eq: true}}) {
      aggregate {
        count
      }
    }
  }
}
    ${ServiceCategoryFieldsFragmentDoc}`;

/**
 * __useGetServiceCategoriesQuery__
 *
 * To run a query within a React component, call `useGetServiceCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetServiceCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetServiceCategoriesQuery({
 *   variables: {
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetServiceCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<GetServiceCategoriesQuery, GetServiceCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetServiceCategoriesQuery, GetServiceCategoriesQueryVariables>(GetServiceCategoriesDocument, options);
      }
export function useGetServiceCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetServiceCategoriesQuery, GetServiceCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetServiceCategoriesQuery, GetServiceCategoriesQueryVariables>(GetServiceCategoriesDocument, options);
        }
export type GetServiceCategoriesQueryHookResult = ReturnType<typeof useGetServiceCategoriesQuery>;
export type GetServiceCategoriesLazyQueryHookResult = ReturnType<typeof useGetServiceCategoriesLazyQuery>;
export type GetServiceCategoriesQueryResult = Apollo.QueryResult<GetServiceCategoriesQuery, GetServiceCategoriesQueryVariables>;