import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type UpdateServiceCouponsMutationVariables = Types.Exact<{
  where: Types.ServiceCoupon_Bool_Exp;
  objects: Array<Types.ServiceCoupon_Insert_Input> | Types.ServiceCoupon_Insert_Input;
}>;


export type UpdateServiceCouponsMutation = { __typename?: 'mutation_root', delete_serviceCoupon?: { __typename?: 'serviceCoupon_mutation_response', affected_rows: number } | null | undefined, insert_serviceCoupon?: { __typename?: 'serviceCoupon_mutation_response', affected_rows: number } | null | undefined };


export const UpdateServiceCouponsDocument = gql`
    mutation updateServiceCoupons($where: serviceCoupon_bool_exp!, $objects: [serviceCoupon_insert_input!]!) {
  delete_serviceCoupon(where: $where) {
    affected_rows
  }
  insert_serviceCoupon(objects: $objects) {
    affected_rows
  }
}
    `;
export type UpdateServiceCouponsMutationFn = Apollo.MutationFunction<UpdateServiceCouponsMutation, UpdateServiceCouponsMutationVariables>;

/**
 * __useUpdateServiceCouponsMutation__
 *
 * To run a mutation, you first call `useUpdateServiceCouponsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateServiceCouponsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateServiceCouponsMutation, { data, loading, error }] = useUpdateServiceCouponsMutation({
 *   variables: {
 *      where: // value for 'where'
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useUpdateServiceCouponsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateServiceCouponsMutation, UpdateServiceCouponsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateServiceCouponsMutation, UpdateServiceCouponsMutationVariables>(UpdateServiceCouponsDocument, options);
      }
export type UpdateServiceCouponsMutationHookResult = ReturnType<typeof useUpdateServiceCouponsMutation>;
export type UpdateServiceCouponsMutationResult = Apollo.MutationResult<UpdateServiceCouponsMutation>;
export type UpdateServiceCouponsMutationOptions = Apollo.BaseMutationOptions<UpdateServiceCouponsMutation, UpdateServiceCouponsMutationVariables>;