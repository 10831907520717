import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { FlowsheetFieldsMinimalFragmentDoc } from '../../fragments/flowsheet/FlowsheetFieldsMinimal.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type ListFlowsheetsQueryVariables = Types.Exact<{
  limit?: Types.Maybe<Types.Scalars['Int']>;
  offset?: Types.Maybe<Types.Scalars['Int']>;
  orderBy?: Types.Maybe<Array<Types.Flowsheet_Order_By> | Types.Flowsheet_Order_By>;
  where?: Types.Maybe<Types.Flowsheet_Bool_Exp>;
}>;


export type ListFlowsheetsQuery = { __typename?: 'query_root', flowsheet: Array<{ __typename?: 'flowsheet', id: any, title: string }> };


export const ListFlowsheetsDocument = gql`
    query ListFlowsheets($limit: Int, $offset: Int = 0, $orderBy: [flowsheet_order_by!], $where: flowsheet_bool_exp) {
  flowsheet(limit: $limit, offset: $offset, order_by: $orderBy, where: $where) {
    ...FlowsheetFieldsMinimal
  }
}
    ${FlowsheetFieldsMinimalFragmentDoc}`;

/**
 * __useListFlowsheetsQuery__
 *
 * To run a query within a React component, call `useListFlowsheetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListFlowsheetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListFlowsheetsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      orderBy: // value for 'orderBy'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useListFlowsheetsQuery(baseOptions?: Apollo.QueryHookOptions<ListFlowsheetsQuery, ListFlowsheetsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListFlowsheetsQuery, ListFlowsheetsQueryVariables>(ListFlowsheetsDocument, options);
      }
export function useListFlowsheetsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListFlowsheetsQuery, ListFlowsheetsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListFlowsheetsQuery, ListFlowsheetsQueryVariables>(ListFlowsheetsDocument, options);
        }
export type ListFlowsheetsQueryHookResult = ReturnType<typeof useListFlowsheetsQuery>;
export type ListFlowsheetsLazyQueryHookResult = ReturnType<typeof useListFlowsheetsLazyQuery>;
export type ListFlowsheetsQueryResult = Apollo.QueryResult<ListFlowsheetsQuery, ListFlowsheetsQueryVariables>;