import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { InventoryTransactionFieldsFragmentDoc } from '../../fragments/inventoryTransactionFields.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetInventoryTransactionsQueryVariables = Types.Exact<{
  where: Types.InventoryTransaction_Bool_Exp;
  limit: Types.Scalars['Int'];
  offset?: Types.Maybe<Types.Scalars['Int']>;
  orderBy?: Types.Maybe<Array<Types.InventoryTransaction_Order_By> | Types.InventoryTransaction_Order_By>;
}>;


export type GetInventoryTransactionsQuery = { __typename?: 'query_root', inventoryTransaction: Array<{ __typename?: 'inventoryTransaction', id: any, type: string, quantity: any, createdAt: any, user: { __typename?: 'user', id: any, firstName: string, lastName: string }, consumable: { __typename?: 'consumable', id: any, metadata: any, isExpirable: boolean, expirationDate?: any | null | undefined } }> };


export const GetInventoryTransactionsDocument = gql`
    query GetInventoryTransactions($where: inventoryTransaction_bool_exp!, $limit: Int!, $offset: Int, $orderBy: [inventoryTransaction_order_by!]) {
  inventoryTransaction(
    where: $where
    limit: $limit
    offset: $offset
    order_by: $orderBy
  ) {
    ...InventoryTransactionFields
  }
}
    ${InventoryTransactionFieldsFragmentDoc}`;

/**
 * __useGetInventoryTransactionsQuery__
 *
 * To run a query within a React component, call `useGetInventoryTransactionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInventoryTransactionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInventoryTransactionsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetInventoryTransactionsQuery(baseOptions: Apollo.QueryHookOptions<GetInventoryTransactionsQuery, GetInventoryTransactionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInventoryTransactionsQuery, GetInventoryTransactionsQueryVariables>(GetInventoryTransactionsDocument, options);
      }
export function useGetInventoryTransactionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInventoryTransactionsQuery, GetInventoryTransactionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInventoryTransactionsQuery, GetInventoryTransactionsQueryVariables>(GetInventoryTransactionsDocument, options);
        }
export type GetInventoryTransactionsQueryHookResult = ReturnType<typeof useGetInventoryTransactionsQuery>;
export type GetInventoryTransactionsLazyQueryHookResult = ReturnType<typeof useGetInventoryTransactionsLazyQuery>;
export type GetInventoryTransactionsQueryResult = Apollo.QueryResult<GetInventoryTransactionsQuery, GetInventoryTransactionsQueryVariables>;