import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { LocationScheduleFieldsFragmentDoc } from '../../fragments/locationScheduleFields.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type InsertLocationScheduleMutationVariables = Types.Exact<{
  locationSchedule: Types.LocationSchedule_Insert_Input;
}>;


export type InsertLocationScheduleMutation = { __typename?: 'mutation_root', insert_locationSchedule?: { __typename?: 'locationSchedule_mutation_response', returning: Array<{ __typename?: 'locationSchedule', id: any, schedule: any, effectiveFrom?: any | null | undefined, createdAt: any, updatedAt: any, locationId: any }> } | null | undefined };


export const InsertLocationScheduleDocument = gql`
    mutation insertLocationSchedule($locationSchedule: locationSchedule_insert_input!) {
  insert_locationSchedule(objects: [$locationSchedule]) {
    returning {
      ...LocationScheduleFields
    }
  }
}
    ${LocationScheduleFieldsFragmentDoc}`;
export type InsertLocationScheduleMutationFn = Apollo.MutationFunction<InsertLocationScheduleMutation, InsertLocationScheduleMutationVariables>;

/**
 * __useInsertLocationScheduleMutation__
 *
 * To run a mutation, you first call `useInsertLocationScheduleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertLocationScheduleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertLocationScheduleMutation, { data, loading, error }] = useInsertLocationScheduleMutation({
 *   variables: {
 *      locationSchedule: // value for 'locationSchedule'
 *   },
 * });
 */
export function useInsertLocationScheduleMutation(baseOptions?: Apollo.MutationHookOptions<InsertLocationScheduleMutation, InsertLocationScheduleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertLocationScheduleMutation, InsertLocationScheduleMutationVariables>(InsertLocationScheduleDocument, options);
      }
export type InsertLocationScheduleMutationHookResult = ReturnType<typeof useInsertLocationScheduleMutation>;
export type InsertLocationScheduleMutationResult = Apollo.MutationResult<InsertLocationScheduleMutation>;
export type InsertLocationScheduleMutationOptions = Apollo.BaseMutationOptions<InsertLocationScheduleMutation, InsertLocationScheduleMutationVariables>;