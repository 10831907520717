import { useState } from 'react';
import {
  Flex,
  Heading,
  Stack,
  TabList,
  Tab,
  Tabs,
  FormControl,
  Tooltip,
  FormLabel,
  Input,
} from '@chakra-ui/react';
import { LeadFormStep, useStores } from '@webapp/state-models';
import Select from 'react-select';
import { observer } from 'mobx-react-lite';
import { RiMenuLine, RiCloseLine } from 'react-icons/ri';
import camelcase from 'camelcase';
import CustomFieldModal from '../custom-field-modal/custom-field-modal';
import FormField from '../form-field/form-field';
/* eslint-disable-next-line */
export interface FormStepProps {
  formStep: LeadFormStep;
  removeStep: (step: LeadFormStep) => void;
}

const options = [
  {
    value: 'firstName',
    label: 'First Name',
    type: 'string',
    validationProps: { required: 'This field is required' },
  },
  {
    value: 'lastName',
    label: 'Last Name',
    type: 'string',
    validationProps: {
      required: 'This field is required',
    },
  },
  {
    value: 'email',
    label: 'Email',
    type: 'string',
    validationProps: {
      required: 'This field is required',
      pattern: {
        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
        message: 'Please enter a valid email address',
      },
    },
  },
  { value: 'phoneNumber', label: 'Phone', type: 'string' },
  //   {
  //     value: 'serviceSelection',
  //     label: 'Service Selection',
  //     type: 'serviceSelection',
  //   },
  {
    apiFieldKey: 'serviceCategoryOfInterest',
    value: 'serviceCategoryOfInterest',
    label: 'What services are you interested in?',
    type: 'serviceCategoryOfInterest',
  },

  { value: 'custom', label: 'Custom Field', type: 'string' },
];

export const FormStep = observer(({ formStep, removeStep }: FormStepProps) => {
  const { draftLeadForm } = useStores();

  const [tabIndex, setTabIndex] = useState(0);
  const [showCustomFieldForm, setShowCustomFieldForm] =
    useState<boolean>(false);

  function addFieldToForm(a: any) {
    if (a.value === 'custom') {
      setShowCustomFieldForm(true);
    } else {
      formStep.addField(a);
    }
  }

  const filteredOptions = options.filter(
    (option) =>
      !draftLeadForm?.allFields.find(
        (f) => f.apiFieldKey === camelcase(option.label)
      )
  );

  return (
    <Stack
      rounded={'md'}
      bg={'white'}
      w={'full'}
      h={'full'}
      p={4}
      borderColor={'gray.200'}
      borderWidth={1}
    >
      <Flex alignItems={'center'}>
        <RiMenuLine width={20} height={40} color="black" />
        <Heading flexGrow={1} size="sm" ml={2}>
          {formStep.name}
        </Heading>
        <RiCloseLine onClick={() => removeStep(formStep)} />
      </Flex>
      <Tabs onChange={(index) => setTabIndex(index)}>
        <TabList
          border="0"
          position="relative"
          zIndex={1}
          w={{ base: '100%', md: 'auto' }}
        >
          <Tab fontWeight="semibold">Settings</Tab>
          {formStep.type !== 'success-page' && (
            <Tab fontWeight="semibold">Form fields</Tab>
          )}
        </TabList>
      </Tabs>
      {tabIndex === 0 && (
        <>
          <FormControl>
            <FormLabel htmlFor="title">Title</FormLabel>
            <Input
              id="title"
              type="title"
              value={formStep?.name || undefined}
              bg={'white'}
              onChange={(e) => formStep.setName(e.target.value)}
            />
          </FormControl>
          {formStep?.type === 'success-page' && (
            <FormControl>
              <FormLabel htmlFor="title">Subtitle</FormLabel>
              <Input
                id="subtitle"
                type="subtitle"
                value={formStep?.subtitle || undefined}
                bg={'white'}
                onChange={(e) => formStep.setSubtitle(e.target.value)}
              />
            </FormControl>
          )}
          {/* TODO: I'd like to come back and refactor this into more of a factory model */}
          {formStep?.type === 'success-page' && (
            <FormControl>
              <Tooltip label="User will be redirected to this webpage after completing form.">
                <FormLabel htmlFor="redirectUrl">Redirect to website</FormLabel>
              </Tooltip>
              <Input
                id="redirectUrl"
                type="text"
                value={formStep?.properties.redirectUrl || undefined}
                bg={'white'}
                onChange={(e) => formStep.setRedirectUrl(e.target.value)}
              />
            </FormControl>
          )}
          <FormControl>
            <FormLabel htmlFor="cta">Call to action</FormLabel>
            <Input
              id="cta"
              type="cta"
              value={formStep?.callToAction || undefined}
              bg={'white'}
              onChange={(e) => formStep.setCallToAction(e.target.value)}
            />
          </FormControl>
        </>
      )}
      {tabIndex === 1 && (
        <Stack py={2}>
          {formStep.sortedFields.map((field) => (
            <FormField
              key={field?.id}
              formField={field}
              onRemove={formStep.removeField}
            />
          ))}

          <Select
            value={null}
            styles={{ menu: (provided) => ({ ...provided, zIndex: 9999 }) }}
            options={filteredOptions}
            placeholder="Add a field to form"
            onChange={addFieldToForm}
          />
        </Stack>
      )}
      <CustomFieldModal
        formStep={formStep}
        isOpen={showCustomFieldForm}
        onClose={() => setShowCustomFieldForm(false)}
      />
    </Stack>
  );
});

export default FormStep;
