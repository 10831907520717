import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { SignatureFieldsFragmentDoc } from '../../fragments/signatureFields.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type InsertSignatureMutationVariables = Types.Exact<{
  signature: Types.Signature_Insert_Input;
}>;


export type InsertSignatureMutation = { __typename?: 'mutation_root', insert_signature?: { __typename?: 'signature_mutation_response', returning: Array<{ __typename?: 'signature', id: any, filePath: string, file?: { __typename?: 'FileOnlyURL', url: string } | null | undefined }> } | null | undefined };


export const InsertSignatureDocument = gql`
    mutation insertSignature($signature: signature_insert_input!) {
  insert_signature(
    objects: [$signature]
    on_conflict: {constraint: signature_filePath_key, update_columns: updatedAt}
  ) {
    returning {
      ...SignatureFields
    }
  }
}
    ${SignatureFieldsFragmentDoc}`;
export type InsertSignatureMutationFn = Apollo.MutationFunction<InsertSignatureMutation, InsertSignatureMutationVariables>;

/**
 * __useInsertSignatureMutation__
 *
 * To run a mutation, you first call `useInsertSignatureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertSignatureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertSignatureMutation, { data, loading, error }] = useInsertSignatureMutation({
 *   variables: {
 *      signature: // value for 'signature'
 *   },
 * });
 */
export function useInsertSignatureMutation(baseOptions?: Apollo.MutationHookOptions<InsertSignatureMutation, InsertSignatureMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertSignatureMutation, InsertSignatureMutationVariables>(InsertSignatureDocument, options);
      }
export type InsertSignatureMutationHookResult = ReturnType<typeof useInsertSignatureMutation>;
export type InsertSignatureMutationResult = Apollo.MutationResult<InsertSignatureMutation>;
export type InsertSignatureMutationOptions = Apollo.BaseMutationOptions<InsertSignatureMutation, InsertSignatureMutationVariables>;