import {
  Stack,
  HStack,
  Divider,
  Heading,
  Box,
  FormControl,
  FormLabel,
  Button,
  Text,
} from '@chakra-ui/react';
import { FormInput } from '@webapp/ui';
import dayjs from 'dayjs';
import { RefObject } from 'react';
import SignatureCanvas from 'react-signature-canvas';

type GenericConsent = {
  [key: string]: any;
  name: string;
  date?: string;
};

interface SignatureGroupProps {
  heading?: string;
  formInputName: string;
  formInputDateName: string;
  consent?: GenericConsent;
  signatureRef?: RefObject<SignatureCanvas>;
  required?: boolean;
}
export default function SignatureGroup({
  heading,
  formInputName,
  consent,
  signatureRef,
  formInputDateName,
  required = true,
}: SignatureGroupProps) {
  return (
    <Stack maxW={window.innerWidth * 0.8}>
      <HStack pb={4}>
        <Divider flex={1} />
        <Heading size="sm" flex={1} textAlign="center" flexShrink={0}>
          {heading || 'Signature'}
        </Heading>
        <Divider flex={1} />
      </HStack>
      <HStack
        px={2}
        mb={4}
        w="full"
        justifyContent={'space-between'}
        alignItems="center"
      >
        <Box flex={1}>
          <FormInput
            label="Name"
            labelAlign="start"
            required={required}
            labelPosition="top"
            placeholder="Add your name here"
            name={formInputName}
            isEditable={!consent || !consent[formInputName]}
            defaultValue={consent ? consent[formInputName] : ''}
          />
        </Box>

        <Stack flex={1}>
          <FormControl>
            <FormLabel>Date</FormLabel>
            {consent && consent[formInputDateName] ? (
              <Text>
                {dayjs(consent[formInputDateName] as string).format(
                  'MM/DD/YYYY'
                )}
              </Text>
            ) : (
              <FormInput type="date" name={formInputDateName} />
            )}
          </FormControl>
        </Stack>
      </HStack>
      <Box borderWidth={1} borderColor="gray.100" mb={4} mx={2}>
        {!consent && (
          <HStack
            px={4}
            bg="gray.50"
            borderBottomWidth={1}
            borderColor={'gray.100'}
            justifyContent="space-between"
            alignItems={'center'}
          >
            <Text fontWeight={'bold'} size="md">
              Draw your signature below
            </Text>
            <Button
              variant={'ghost'}
              colorScheme="teal"
              onClick={() => {
                signatureRef?.current?.clear();
                signatureRef?.current?.on();
              }}
            >
              Clear
            </Button>
          </HStack>
        )}
        {/* @ts-ignore-error React 18 */}
        <SignatureCanvas
          ref={signatureRef}
          canvasProps={{
            width: window.innerWidth * 0.8,
            height: 200,
            className: 'sigCanvas',
          }}
        />
      </Box>
    </Stack>
  );
}
