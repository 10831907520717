import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type InsertMediaTagMutationVariables = Types.Exact<{
  mediaTag: Types.MediaTag_Insert_Input;
}>;


export type InsertMediaTagMutation = { __typename?: 'mutation_root', insert_mediaTag?: { __typename?: 'mediaTag_mutation_response', returning: Array<{ __typename?: 'mediaTag', id: any, title: string }> } | null | undefined };


export const InsertMediaTagDocument = gql`
    mutation insertMediaTag($mediaTag: mediaTag_insert_input!) {
  insert_mediaTag(
    on_conflict: {constraint: mediaTag_title_workspaceId_key, update_columns: [updatedAt]}
    objects: [$mediaTag]
  ) {
    returning {
      id
      title
    }
  }
}
    `;
export type InsertMediaTagMutationFn = Apollo.MutationFunction<InsertMediaTagMutation, InsertMediaTagMutationVariables>;

/**
 * __useInsertMediaTagMutation__
 *
 * To run a mutation, you first call `useInsertMediaTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertMediaTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertMediaTagMutation, { data, loading, error }] = useInsertMediaTagMutation({
 *   variables: {
 *      mediaTag: // value for 'mediaTag'
 *   },
 * });
 */
export function useInsertMediaTagMutation(baseOptions?: Apollo.MutationHookOptions<InsertMediaTagMutation, InsertMediaTagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertMediaTagMutation, InsertMediaTagMutationVariables>(InsertMediaTagDocument, options);
      }
export type InsertMediaTagMutationHookResult = ReturnType<typeof useInsertMediaTagMutation>;
export type InsertMediaTagMutationResult = Apollo.MutationResult<InsertMediaTagMutation>;
export type InsertMediaTagMutationOptions = Apollo.BaseMutationOptions<InsertMediaTagMutation, InsertMediaTagMutationVariables>;