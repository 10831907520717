import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { AddressFieldsFragmentDoc } from '../../fragments/addressFields.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type UpdatePatientContactInfoMutationVariables = Types.Exact<{
  firstName?: Types.Maybe<Types.Scalars['String']>;
  lastName?: Types.Maybe<Types.Scalars['String']>;
  addressId: Types.Scalars['uuid'];
  addressLine1?: Types.Maybe<Types.Scalars['String']>;
  addressLine2?: Types.Maybe<Types.Scalars['String']>;
  city?: Types.Maybe<Types.Scalars['String']>;
  state?: Types.Maybe<Types.Scalars['String']>;
  zipCode?: Types.Maybe<Types.Scalars['String']>;
  patientId: Types.Scalars['uuid'];
  email?: Types.Maybe<Types.Scalars['String']>;
  phoneNumber?: Types.Maybe<Types.Scalars['String']>;
}>;


export type UpdatePatientContactInfoMutation = { __typename?: 'mutation_root', update_address_by_pk?: { __typename?: 'address', addressLine1: string, addressLine2?: string | null | undefined, city: string, id: any, state: string, zipCode: string } | null | undefined, update_patient_by_pk?: { __typename?: 'patient', email?: string | null | undefined, id: any, phoneNumber?: string | null | undefined, firstName: string, lastName: string } | null | undefined };


export const UpdatePatientContactInfoDocument = gql`
    mutation UpdatePatientContactInfo($firstName: String, $lastName: String, $addressId: uuid!, $addressLine1: String, $addressLine2: String, $city: String, $state: String, $zipCode: String, $patientId: uuid!, $email: String, $phoneNumber: String) {
  update_address_by_pk(
    _set: {addressLine1: $addressLine1, addressLine2: $addressLine2, city: $city, state: $state, zipCode: $zipCode}
    pk_columns: {id: $addressId}
  ) {
    ...AddressFields
  }
  update_patient_by_pk(
    _set: {email: $email, phoneNumber: $phoneNumber, firstName: $firstName, lastName: $lastName}
    pk_columns: {id: $patientId}
  ) {
    email
    id
    phoneNumber
    firstName
    lastName
  }
}
    ${AddressFieldsFragmentDoc}`;
export type UpdatePatientContactInfoMutationFn = Apollo.MutationFunction<UpdatePatientContactInfoMutation, UpdatePatientContactInfoMutationVariables>;

/**
 * __useUpdatePatientContactInfoMutation__
 *
 * To run a mutation, you first call `useUpdatePatientContactInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePatientContactInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePatientContactInfoMutation, { data, loading, error }] = useUpdatePatientContactInfoMutation({
 *   variables: {
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      addressId: // value for 'addressId'
 *      addressLine1: // value for 'addressLine1'
 *      addressLine2: // value for 'addressLine2'
 *      city: // value for 'city'
 *      state: // value for 'state'
 *      zipCode: // value for 'zipCode'
 *      patientId: // value for 'patientId'
 *      email: // value for 'email'
 *      phoneNumber: // value for 'phoneNumber'
 *   },
 * });
 */
export function useUpdatePatientContactInfoMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePatientContactInfoMutation, UpdatePatientContactInfoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePatientContactInfoMutation, UpdatePatientContactInfoMutationVariables>(UpdatePatientContactInfoDocument, options);
      }
export type UpdatePatientContactInfoMutationHookResult = ReturnType<typeof useUpdatePatientContactInfoMutation>;
export type UpdatePatientContactInfoMutationResult = Apollo.MutationResult<UpdatePatientContactInfoMutation>;
export type UpdatePatientContactInfoMutationOptions = Apollo.BaseMutationOptions<UpdatePatientContactInfoMutation, UpdatePatientContactInfoMutationVariables>;