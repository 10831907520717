import * as Joi from 'joi';

/* 
    Accepts empty strings, and defaults them to null
    Accepts all other strings
*/
export const DefaultEmptyStringValidation = Joi.alternatives().try(
  Joi.string().valid('').empty('').default(null),
  Joi.string()
);

export default DefaultEmptyStringValidation;
