import {
  useSearchMediaTagsLazyQuery,
  MediaTag,
  SearchMediaTagsQueryResult,
} from '@webapp/graphql';
import Async from 'react-select/async';
import AsyncCreatableSelect from 'react-select/async-creatable';
import { Box } from '@chakra-ui/react';
import { PhotoMediaTag } from '@webapp/state-models';
import { ActionMeta, MultiValue, SingleValue } from 'react-select';

export interface MediaTagOption {
  readonly value: string;
  readonly label: string;
}

/* eslint-disable-next-line */
export interface MediaTagInputProps {
  onCreateMediaTag?: (title: string) => void;
  onAddExistingMediaTag?: (mediaTag: MediaTag) => Promise<void> | void;
  onRemoveTag?: (photoMediaTagId: string) => Promise<void> | void;
  onSelectMediaTag?: (mediaTagId: string) => void;
  selectedMediaTags?: PhotoMediaTag[];
  placeholder?: string;
  creatable?: boolean;
  onClear?: () => void;
  useTagIdAsValue?: boolean;
}

export function MediaTagInput({
  onCreateMediaTag,
  onSelectMediaTag,
  selectedMediaTags,
  onAddExistingMediaTag,
  onRemoveTag,
  placeholder,
  creatable = true,
  onClear,
  useTagIdAsValue = false,
}: MediaTagInputProps) {
  const [searchMediaTag] = useSearchMediaTagsLazyQuery();

  const promiseOptions = async (
    inputValue: string,
    callback: (options: MediaTagOption[]) => void
  ) =>
    new Promise((resolve) => {
      searchMediaTag({
        variables: { _ilike: `%${inputValue}%` },
      }).then(({ data }: SearchMediaTagsQueryResult) =>
        resolve(
          data?.mediaTag?.map(({ id, title }) => ({
            value: id,
            label: title,
          })) as MediaTagOption[]
        )
      );
    });

  const handleChange = (
    a:
      | SingleValue<{
          label: string;
          value: string;
        }>
      | MultiValue<{
          label: string;
          value: string;
        }>,
    b: ActionMeta<{
      label: string;
      value: string;
    }>
  ) => {
    switch (b.action) {
      case 'create-option':
        if (onCreateMediaTag) onCreateMediaTag(b.option.value);
        break;
      case 'remove-value':
        if (onRemoveTag) onRemoveTag(b.removedValue.value);
        break;
      case 'clear':
        if (onClear) onClear();
        break;
      default:
        if (onAddExistingMediaTag && b.option)
          onAddExistingMediaTag({
            id: b.option.value,
            title: b.option.label,
          } as MediaTag);
        break;
    }
  };

  const props = {
    defaultValue: selectedMediaTags
      ? selectedMediaTags.map((selectedMediaTag) => ({
          label: selectedMediaTag.mediaTag.title,
          value: useTagIdAsValue
            ? selectedMediaTag?.mediaTag?.id
            : selectedMediaTag.id,
        }))
      : null,
    isMulti: true,
    defaultOptions: true,

    placeholder: placeholder || 'Select one or more tags',
    loadOptions: promiseOptions,
  };

  return (
    <Box minW={{ base: 100, md: 200 }}>
      {creatable ? (
        <AsyncCreatableSelect onChange={handleChange} {...props} />
      ) : (
        <Async onChange={handleChange} {...props} />
      )}
    </Box>
  );
}

export default MediaTagInput;
