import { Button, Flex, Icon, useDisclosure } from '@chakra-ui/react';
import {
  GetAppointmentDocument,
  MinimalResolvedConsent,
  useUpdateAppointmentConsentsMutation,
} from '@webapp/graphql';
import { HookForm } from '@webapp/ui';
import { HiPencilAlt } from 'react-icons/hi';
import {
  AppointmentStepProps,
  CompletedSelectOption,
  CompletedSelectOptionAndPatientConsentId,
} from '@webapp/types';
import { uniqBy } from 'lodash';
import { useMemo, useState } from 'react';
import { ConsentsForm } from './consentsForm';

interface FormInputs {
  consentsToDelete: CompletedSelectOption[];
  consents: CompletedSelectOption[];
}

export const FormConsentSection = ({
  appointment,
  mini = false,
}: AppointmentStepProps): JSX.Element => {
  const { isOpen, onClose, onToggle } = useDisclosure();
  const [finishedSubmitting, setFinishedSubmitting] = useState(false);
  const [updateAppointmentConsents] = useUpdateAppointmentConsentsMutation({
    onCompleted: () => {
      onClose();
    },
    refetchQueries: [
      {
        query: GetAppointmentDocument,
        variables: {
          id: appointment.id,
        },
      },
    ],
  });

  const {
    resolvedAppointmentConsents: { consents: resolvedConsents },
  } = appointment;

  const mappedConsents: CompletedSelectOptionAndPatientConsentId[] = useMemo(
    () =>
      resolvedConsents.map((consent: MinimalResolvedConsent) => ({
        id: consent.id,
        label: consent.title,
        value: consent.id,
        patientConsentId: consent.patientConsentId,
        completed: consent.completed,
      })),
    [resolvedConsents]
  );

  function onSubmit(values: FormInputs) {
    const { consents, consentsToDelete } = values;

    const mappedConsentsToDelete = consentsToDelete.map((consent) => ({
      appointmentId: appointment.id,
      consentId: consent.value,
      manuallyRemoved: true,
    }));

    const mappedConsentsToCreate = consents.map((consent) => ({
      appointmentId: appointment.id,
      consentId: consent.value,
      manuallyAdded: true,
    }));

    updateAppointmentConsents({
      variables: {
        objects: uniqBy(
          [...mappedConsentsToCreate, ...mappedConsentsToDelete],
          'consentId'
        ),
      },
    });

    setFinishedSubmitting(true);
  }

  return (
    <HookForm<FormInputs>
      defaultValues={{
        consents: mappedConsents,
        consentsToDelete: [],
      }}
      onSubmit={onSubmit}
    >
      <>
        {!mini && (
          <Flex justifyContent="flex-end">
            <Button
              onClick={onToggle}
              variant="ghost"
              _hover={{
                background: '#EAFCFF',
              }}
              w="50px"
            >
              <Icon
                as={HiPencilAlt}
                color="teal.500"
                height="18px"
                width="18px"
              />
            </Button>
          </Flex>
        )}
        <ConsentsForm
          mini={mini}
          appointment={appointment}
          isEditable={isOpen}
          onToggle={onToggle}
          finishedSubmitting={finishedSubmitting}
          setFinishedSubmitting={setFinishedSubmitting}
        />
      </>
    </HookForm>
  );
};

export default FormConsentSection;
