import './two-factor-flow.module.css';
import { firebaseAuth } from '@webapp/auth';

import {
  RecaptchaVerifier,
  PhoneAuthProvider,
  ApplicationVerifier,
  multiFactor,
  PhoneMultiFactorGenerator,
  User,
  sendEmailVerification,
} from 'firebase/auth';
import {
  Stack,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  PinInput,
  PinInputField,
  FormHelperText,
  Button,
} from '@chakra-ui/react';
import { ChangeEvent, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import {
  usePhoneNumberLookupLazyQuery,
  useUpdateUserMutation,
} from '@webapp/graphql';
import { useStores } from '@webapp/state-models';
import { observer } from 'mobx-react-lite';

/* eslint-disable-next-line */
export interface TwoFactorFlowProps {
  onSuccess: () => void;
}

export const TwoFactorFlow = observer(({ onSuccess }: TwoFactorFlowProps) => {
  const { user } = useStores();
  const [loading, setLoading] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState<string>();
  const [verificationId, setVerificationId] = useState<string>();
  const [verificationCode, setVerificationCode] = useState<string>();
  const [phoneNumberLookup] = usePhoneNumberLookupLazyQuery();
  const [updateUser] = useUpdateUserMutation({
    onError: (error) => toast.error(error.message),
  });
  const [recaptchaVerifier, setRecaptchaVerifier] =
    useState<RecaptchaVerifier>();

  async function sendFirebaseVerificationEmail() {
    const currentUser = firebaseAuth?.currentUser;
    if (currentUser && !currentUser.emailVerified) {
      try {
        setLoading(true);
        await sendEmailVerification(currentUser);
        toast.success(
          'Verification email sent successfully. Please check your inbox.'
        );
      } catch (error) {
        toast.error(`Failed to send verification email: ${error.message}`);
      } finally {
        setLoading(false);
      }
    } else {
      toast.info('Email is already verified or user is not logged in.');
    }
  }

  async function verifyPhoneNumber() {
    const currentUser = firebaseAuth?.currentUser;

    if (currentUser && phoneNumber) {
      setLoading(true);
      try {
        const multiFactorUser = multiFactor(currentUser);
        const multiFactorSession = await multiFactorUser.getSession();

        const { data } = await phoneNumberLookup({
          variables: { phoneNumber },
        });
        // Send verification code.
        const phoneAuthProvider = new PhoneAuthProvider(firebaseAuth);
        const phoneInfoOptions = {
          phoneNumber: data?.phoneNumberLookup.phoneNumber.phoneNumber,
          session: multiFactorSession,
        };

        setVerificationId(
          await phoneAuthProvider.verifyPhoneNumber(
            phoneInfoOptions,
            recaptchaVerifier as ApplicationVerifier
          )
        );
      } catch (error) {
        if (error.code === 'auth/unverified-email') {
          toast.error(
            'Please verify your email before enrolling in multi-factor authentication.'
          );
          sendFirebaseVerificationEmail();
        } else {
          toast.error(error.message);
        }
      } finally {
        setLoading(false);
      }
    }
  }
  async function verifyCode() {
    if (verificationId && verificationCode) {
      try {
        setLoading(true);
        const currentUser = firebaseAuth?.currentUser;
        const multiFactorUser = multiFactor(currentUser as User);
        const phoneAuthCredential = PhoneAuthProvider.credential(
          verificationId,
          verificationCode
        );
        const multiFactorAssertion =
          PhoneMultiFactorGenerator.assertion(phoneAuthCredential);
        await multiFactorUser.enroll(multiFactorAssertion);
        setLoading(false);
        toast.success(`Successfully enrolled Multifactor Authentication!`);
        updateUser({
          variables: {
            id: user?.id,
            set: {
              twoFactorAuthEnabled: true,
            },
          },
        });
        onSuccess();
      } catch (err) {
        toast.error((err as Error).message);
      }
    }
  }

  useEffect(() => {
    setRecaptchaVerifier(
      new RecaptchaVerifier(
        'recaptcha-holder',
        {
          size: 'invisible',
        },
        firebaseAuth
      )
    );
  }, []);
  if (verificationId) {
    return (
      <Stack>
        <FormControl>
          <FormLabel>Enter verification code</FormLabel>
          <PinInput autoFocus otp onChange={(v) => setVerificationCode(v)}>
            <PinInputField />
            <PinInputField />
            <PinInputField />
            <PinInputField />
            <PinInputField />
            <PinInputField />
          </PinInput>
        </FormControl>
        <Button onClick={verifyCode} isLoading={loading}>
          Verify code
        </Button>
      </Stack>
    );
  }

  return (
    <Stack>
      <FormControl>
        <FormLabel htmlFor="phoneNumber">Phone number</FormLabel>
        <Input
          id="phoneNumber"
          type="tel"
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setPhoneNumber(e.target.value)
          }
        />
        <FormHelperText>
          We will send a verification code to this phone number.
        </FormHelperText>
      </FormControl>
      <Button
        id="sign-in-button"
        onClick={verifyPhoneNumber}
        isLoading={loading}
      >
        Send Verification Code
      </Button>
    </Stack>
  );
});

export default TwoFactorFlow;
