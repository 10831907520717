/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, {
  useState,
  useEffect,
  ReactNode,
  useRef,
  useLayoutEffect,
  useImperativeHandle,
  forwardRef,
} from 'react';
import { Editor, Range, Extension } from '@tiptap/core';
import Suggestion from '@tiptap/suggestion';
import { ReactRenderer } from '@tiptap/react';
import tippy from 'tippy.js';
import { IoIosArrowDropdownCircle } from 'react-icons/io';
import { FaTable } from 'react-icons/fa';
import { Box, HStack, Text } from '@chakra-ui/react';

import {
  AiOutlineInsertRowAbove,
  AiOutlineInsertRowBelow,
  AiOutlineInsertRowLeft,
  AiOutlineInsertRowRight,
} from 'react-icons/ai';
import { RiDeleteColumn, RiDeleteRow } from 'react-icons/ri';
import { MdDraw } from 'react-icons/md';

interface CommandItemProps {
  title: string;
  description: string;
  icon: ReactNode;
  searchTerms?: string[];
  command?: (props: CommandProps) => void;
}

interface CommandProps {
  editor: Editor;
  range: Range;
}

const Command = Extension.create({
  name: 'slash-command',
  addOptions() {
    return {
      suggestion: {
        char: '/',
        command: ({
          editor,
          range,
          props,
        }: {
          editor: Editor;
          range: Range;
          props: any;
        }) => {
          props.command({ editor, range });
        },
      },
    };
  },
  addProseMirrorPlugins() {
    return [
      Suggestion({
        editor: this.editor,
        ...this.options.suggestion,
      }),
    ];
  },
});

const getSuggestionItems = (props: { query: string }) => {
  const { query, editor } = props;
  const items: CommandItemProps[] = [
    {
      title: 'Dynamic Dropdown',
      description: 'Configure a dynamic dropdown.',
      searchTerms: ['dropdown', 'select', 'dynamic'],
      icon: <IoIosArrowDropdownCircle />,
      command: ({ editor, range }: CommandProps) => {
        editor.commands.deleteRange(range);
        editor.commands.insertContent(`<dynamic-dropdown />`);
      },
    },
    {
      title: 'Insert Table',
      description: 'Insert a Table',
      icon: <FaTable />,
      command: ({ editor, range }: CommandProps) => {
        editor.commands.deleteRange(range);
        editor.commands.insertContent('<h2>Table</h2>');
        editor.commands.insertTable({ rows: 3, cols: 3, withHeaderRow: true });
      },
    },
    {
      title: 'Insert Photo Markup',
      description: 'Markup photo and insert into note.',
      icon: <MdDraw />,
      command: ({ editor, range }: CommandProps) => {
        editor.commands.deleteRange(range);
        editor.commands.insertPhotoMarkup();
      },
    },
    {
      title: 'Insert Flowsheet',
      description: 'Insert Flowsheet into note.',
      icon: <FaTable />,
      command: ({ editor, range }: CommandProps) => {
        editor.commands.deleteRange(range);
        editor.commands.insertFlowsheet();
      },
    },
  ];

  if (editor.isActive('table')) {
    items.push({
      title: 'Add Row After',
      description: 'Add a row after the current row',
      icon: <AiOutlineInsertRowBelow />,
      searchTerms: ['add', 'row', 'after'],
      command: ({ editor, range }: CommandProps) => {
        editor.commands.deleteRange(range);
        editor.commands.addRowAfter();
      },
    });

    // Insert row before current row
    items.push({
      title: 'Add Row Before',
      description: 'Add a row before the current row',
      icon: <AiOutlineInsertRowAbove />,
      searchTerms: ['add', 'row', 'before'],
      command: ({ editor, range }: CommandProps) => {
        editor.commands.deleteRange(range);
        editor.commands.addRowBefore();
      },
    });

    // Add column before
    items.push({
      title: 'Add Column Before',
      description: 'Add a column before the current column',
      icon: <AiOutlineInsertRowLeft />,
      searchTerms: ['add', 'column', 'before'],
      command: ({ editor, range }: CommandProps) => {
        editor.commands.deleteRange(range);
        editor.commands.addColumnBefore();
      },
    });
    // Add column after
    items.push({
      title: 'Add Column After',
      description: 'Add a column after the current column',
      icon: <AiOutlineInsertRowRight />,
      searchTerms: ['add', 'column', 'after'],
      command: ({ editor, range }: CommandProps) => {
        editor.commands.deleteRange(range);
        editor.commands.addColumnAfter();
      },
    });
    // Delete row
    items.push({
      title: 'Delete Row',
      description: 'Delete the current row',
      icon: <RiDeleteRow />,
      searchTerms: ['delete', 'row'],
      command: ({ editor, range }: CommandProps) => {
        editor.commands.deleteRange(range);
        editor.commands.deleteRow();
      },
    });
    // Delete column
    items.push({
      title: 'Delete Column',
      description: 'Delete the current column',
      icon: <RiDeleteColumn />,
      searchTerms: ['delete', 'column'],
      command: ({ editor, range }: CommandProps) => {
        editor.commands.deleteRange(range);
        editor.commands.deleteColumn();
      },
    });
  }

  return items.filter((item) => {
    if (typeof query === 'string' && query.length > 0) {
      const search = query.toLowerCase();
      return (
        item.title.toLowerCase().includes(search) ||
        item.description.toLowerCase().includes(search) ||
        (item.searchTerms &&
          item.searchTerms.some((term: string) => term.includes(search)))
      );
    }
    return true;
  });
};

export const updateScrollView = (container: HTMLElement, item: HTMLElement) => {
  const containerHeight = container.offsetHeight;
  const itemHeight = item ? item.offsetHeight : 0;

  const top = item.offsetTop;
  const bottom = top + itemHeight;

  if (top < container.scrollTop) {
    container.scrollTop -= container.scrollTop - top + 5;
  } else if (bottom > containerHeight + container.scrollTop) {
    container.scrollTop += bottom - containerHeight - container.scrollTop + 5;
  }
};

const CommandList = forwardRef((props, ref) => {
  const { items, command } = props;

  const [selectedIndex, setSelectedIndex] = useState(0);

  const selectItem = (index: number) => {
    const item = items[index];

    if (item) {
      command(item);
    }
  };

  const upHandler = () => {
    setSelectedIndex((selectedIndex + items.length - 1) % items.length);
  };

  const downHandler = () => {
    setSelectedIndex((selectedIndex + 1) % items.length);
  };

  const enterHandler = () => {
    selectItem(selectedIndex);
  };

  useEffect(() => setSelectedIndex(0), [props.items]);

  useImperativeHandle(ref, () => ({
    onKeyDown: ({ event }) => {
      if (event.key === 'ArrowUp') {
        upHandler();
        return true;
      }

      if (event.key === 'ArrowDown') {
        downHandler();
        return true;
      }

      if (event.key === 'Enter') {
        enterHandler();
        return true;
      }

      return false;
    },
  }));

  const commandListContainer = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    const container = commandListContainer?.current;

    const item = container?.children[selectedIndex] as HTMLElement;

    if (item && container) updateScrollView(container, item);
  }, [selectedIndex]);

  return items.length > 0 ? (
    <Box
      id="slash-command"
      ref={commandListContainer}
      zIndex="50"
      h="auto"
      maxH="330px"
      w="72"
      overflowY="auto"
      borderRadius="md"
      border="1px"
      borderColor="teal.200"
      bg="white"
      p={2}
      shadow="md"
      transition="all"
    >
      {items.map((item: CommandItemProps, index: number) => (
        <HStack
          d="flex"
          w="full"
          alignItems="center"
          justifyContent="between"
          borderRadius="md"
          p={2}
          textAlign="left"
          fontSize="sm"
          color="teal.900"
          _hover={{ bg: 'teal.100' }}
          bg={index === selectedIndex ? 'teal.100' : undefined}
          key={index}
          onClick={() => selectItem(index)}
        >
          <Box
            display="flex"
            h="10"
            w="10"
            alignItems="center"
            justifyContent="center"
            borderRadius="md"
            border="1px"
            borderColor="teal.200"
            bg="white"
          >
            {item.icon}
          </Box>
          <Box>
            <Text fontWeight="medium">{item.title}</Text>
            <Text fontSize="xs" color="teal.500">
              {item.description}
            </Text>
          </Box>
        </HStack>
      ))}
    </Box>
  ) : null;
});

const renderItems = () => {
  let component: ReactRenderer | null = null;
  let popup: any | null = null;

  return {
    onStart: (props: { editor: Editor; clientRect: DOMRect }) => {
      component = new ReactRenderer(CommandList, {
        props,
        editor: props.editor,
      });

      // @ts-ignore
      popup = tippy('body', {
        getReferenceClientRect: props.clientRect,
        appendTo: () => document.body,
        content: component.element,
        showOnCreate: true,
        interactive: true,
        trigger: 'manual',
        placement: 'bottom-start',
      });
    },
    onUpdate: (props: { editor: Editor; clientRect: DOMRect }) => {
      component?.updateProps(props);

      popup &&
        popup[0].setProps({
          getReferenceClientRect: props.clientRect,
        });
    },
    onKeyDown: (props: { event: KeyboardEvent }) => {
      if (props.event.key === 'Escape') {
        popup?.[0].hide();

        return true;
      }

      // @ts-ignore
      return component?.ref?.onKeyDown(props);
    },
    onExit: () => {
      popup?.[0].destroy();
      component?.destroy();
    },
  };
};

const SlashCommand = Command.configure({
  suggestion: {
    items: getSuggestionItems,
    render: renderItems,
  },
});

export default SlashCommand;
