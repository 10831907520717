import { ObjectOption, SelectOption } from '@webapp/types';
import { useEffect, useState } from 'react';
import { ActionMeta, SingleValue } from 'react-select';
import { AsyncSelect } from 'chakra-react-select';
import { AsyncSelectInputProps } from './types';
import DEFAULT_CONTROL_STYLE from './styles/DefaultControlStyle';
import DEFAULT_INPUT_CONTAINER_STYLE from './styles/DefaultInputContainerStyle';

function AsyncSelectInput({
  isDisabled,
  loadOptions: useOptions,
  loadOptionsProps = {},
  loadOnMount = false,
  name,
  onBlur,
  onChange,
  placeholder,
  styles = {},
  transform = (option) => option,
  value,
  ...props
}: Omit<AsyncSelectInputProps, 'type'>) {
  const inputStyles = { ...DEFAULT_CONTROL_STYLE, ...styles.control };

  const containerStyles = {
    ...DEFAULT_INPUT_CONTAINER_STYLE,
    ...styles.inputContainer,
  };

  const [initialized, setInitialized] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [defaultOptions, setDefaultOptions] = useState<SelectOption<unknown>[]>(
    []
  );

  const { onCreateOption, search } = useOptions(loadOptionsProps);

  const loadDefaultOptions = async (inputValue = '') => {
    if (!isLoading && !initialized) {
      setIsLoading(true);
      const searchOptions = await search(inputValue);
      setDefaultOptions(searchOptions);
      setInitialized(true);
      setIsLoading(false);
    }
  };

  async function handleChange(
    newValue: SingleValue<ObjectOption<unknown>>,
    actionMeta: ActionMeta<SingleValue<ObjectOption<unknown>>>
  ) {
    switch (actionMeta.action) {
      case 'create-option': {
        if (actionMeta.option && onCreateOption) {
          const newOption = await onCreateOption(actionMeta.option.label);

          if (newOption) {
            onChange(transform(newOption));
          }
        } else {
          // eslint-disable-next-line no-console
          console.error('Create select option handler not provided');
        }

        break;
      }

      case 'pop-value':
      case 'remove-value': {
        onChange(null);
        break;
      }

      case 'clear': {
        onChange(null);
        break;
      }

      default: {
        if (newValue) {
          onChange(transform(newValue));
        } else {
          onChange(null);
        }

        break;
      }
    }
  }

  return (
    <AsyncSelect
      isClearable
      chakraStyles={{
        container: (provided) => ({ ...provided, ...containerStyles }),
        control: (provided) => ({ ...provided, ...inputStyles }),
      }}
      defaultOptions={!loadOnMount ? defaultOptions : true}
      isDisabled={isDisabled}
      isInvalid={Boolean(props.error)}
      loadOptions={search}
      name={name}
      onBlur={onBlur}
      onChange={handleChange}
      onFocus={() => loadDefaultOptions()}
      placeholder={placeholder}
      value={value}
      {...props}
    />
  );
}

export default AsyncSelectInput;
