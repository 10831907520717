import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { PaymentMethodFieldsFragmentDoc } from '../../fragments/paymentMethodFields.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type PatientPaymentMethodsQueryVariables = Types.Exact<{
  patientId: Types.Scalars['uuid'];
  processor: Types.Scalars['String'];
}>;


export type PatientPaymentMethodsQuery = { __typename?: 'query_root', paymentMethod: Array<{ __typename?: 'paymentMethod', id: any, patientId?: any | null | undefined, workspaceId: any, token: string, payrixCustomerId?: string | null | undefined, last4: string, createdAt: any, default: boolean }> };


export const PatientPaymentMethodsDocument = gql`
    query PatientPaymentMethods($patientId: uuid!, $processor: String!) {
  paymentMethod(
    where: {patientId: {_eq: $patientId}, processor: {_eq: $processor}}
  ) {
    ...PaymentMethodFields
  }
}
    ${PaymentMethodFieldsFragmentDoc}`;

/**
 * __usePatientPaymentMethodsQuery__
 *
 * To run a query within a React component, call `usePatientPaymentMethodsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePatientPaymentMethodsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePatientPaymentMethodsQuery({
 *   variables: {
 *      patientId: // value for 'patientId'
 *      processor: // value for 'processor'
 *   },
 * });
 */
export function usePatientPaymentMethodsQuery(baseOptions: Apollo.QueryHookOptions<PatientPaymentMethodsQuery, PatientPaymentMethodsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PatientPaymentMethodsQuery, PatientPaymentMethodsQueryVariables>(PatientPaymentMethodsDocument, options);
      }
export function usePatientPaymentMethodsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PatientPaymentMethodsQuery, PatientPaymentMethodsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PatientPaymentMethodsQuery, PatientPaymentMethodsQueryVariables>(PatientPaymentMethodsDocument, options);
        }
export type PatientPaymentMethodsQueryHookResult = ReturnType<typeof usePatientPaymentMethodsQuery>;
export type PatientPaymentMethodsLazyQueryHookResult = ReturnType<typeof usePatientPaymentMethodsLazyQuery>;
export type PatientPaymentMethodsQueryResult = Apollo.QueryResult<PatientPaymentMethodsQuery, PatientPaymentMethodsQueryVariables>;