import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { PackageFieldsFragmentDoc } from '../../fragments/packageFields.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type InsertPackageMutationVariables = Types.Exact<{
  package: Types.Package_Insert_Input;
}>;


export type InsertPackageMutation = { __typename?: 'mutation_root', insert_package?: { __typename?: 'package_mutation_response', returning: Array<{ __typename?: 'package', id: any, name: string, price: number, taxRate: any, quantity: number, isActive: boolean, createdAt: any, description?: string | null | undefined, image?: string | null | undefined, availableInStore?: boolean | null | undefined, updatedAt: any, service: { __typename?: 'service', id: any, name: string, isProduct?: boolean | null | undefined } }> } | null | undefined };


export const InsertPackageDocument = gql`
    mutation insertPackage($package: package_insert_input!) {
  insert_package(objects: [$package]) {
    returning {
      ...PackageFields
    }
  }
}
    ${PackageFieldsFragmentDoc}`;
export type InsertPackageMutationFn = Apollo.MutationFunction<InsertPackageMutation, InsertPackageMutationVariables>;

/**
 * __useInsertPackageMutation__
 *
 * To run a mutation, you first call `useInsertPackageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertPackageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertPackageMutation, { data, loading, error }] = useInsertPackageMutation({
 *   variables: {
 *      package: // value for 'package'
 *   },
 * });
 */
export function useInsertPackageMutation(baseOptions?: Apollo.MutationHookOptions<InsertPackageMutation, InsertPackageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertPackageMutation, InsertPackageMutationVariables>(InsertPackageDocument, options);
      }
export type InsertPackageMutationHookResult = ReturnType<typeof useInsertPackageMutation>;
export type InsertPackageMutationResult = Apollo.MutationResult<InsertPackageMutation>;
export type InsertPackageMutationOptions = Apollo.BaseMutationOptions<InsertPackageMutation, InsertPackageMutationVariables>;