import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { ProviderScheduleFieldsFragmentDoc } from '../../fragments/providerScheduleFields.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type InsertProviderScheduleMutationVariables = Types.Exact<{
  providerSchedule: Types.ProviderSchedule_Insert_Input;
}>;


export type InsertProviderScheduleMutation = { __typename?: 'mutation_root', insert_providerSchedule?: { __typename?: 'providerSchedule_mutation_response', returning: Array<{ __typename?: 'providerSchedule', id: any, default: boolean, schedule: any, effectiveFrom: any, effectiveUntil?: any | null | undefined, createdAt: any, updatedAt: any, locationId: any, location: { __typename?: 'location', id: any, name: string, timezone?: string | null | undefined } }> } | null | undefined };


export const InsertProviderScheduleDocument = gql`
    mutation insertProviderSchedule($providerSchedule: providerSchedule_insert_input!) {
  insert_providerSchedule(objects: [$providerSchedule]) {
    returning {
      ...ProviderScheduleFields
    }
  }
}
    ${ProviderScheduleFieldsFragmentDoc}`;
export type InsertProviderScheduleMutationFn = Apollo.MutationFunction<InsertProviderScheduleMutation, InsertProviderScheduleMutationVariables>;

/**
 * __useInsertProviderScheduleMutation__
 *
 * To run a mutation, you first call `useInsertProviderScheduleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertProviderScheduleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertProviderScheduleMutation, { data, loading, error }] = useInsertProviderScheduleMutation({
 *   variables: {
 *      providerSchedule: // value for 'providerSchedule'
 *   },
 * });
 */
export function useInsertProviderScheduleMutation(baseOptions?: Apollo.MutationHookOptions<InsertProviderScheduleMutation, InsertProviderScheduleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertProviderScheduleMutation, InsertProviderScheduleMutationVariables>(InsertProviderScheduleDocument, options);
      }
export type InsertProviderScheduleMutationHookResult = ReturnType<typeof useInsertProviderScheduleMutation>;
export type InsertProviderScheduleMutationResult = Apollo.MutationResult<InsertProviderScheduleMutation>;
export type InsertProviderScheduleMutationOptions = Apollo.BaseMutationOptions<InsertProviderScheduleMutation, InsertProviderScheduleMutationVariables>;