import { FormInputProps } from '@webapp/types';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import InnerMaskedDateInput from './InnerMaskedDateInput';

function MaskedDateInput({
  id,
  label,
  labelAlign,
  labelPosition,
  labelStyle,
  containerGridTemplate,
  defaultValue,
  name,
  isEditable = true,
}: FormInputProps): JSX.Element {
  const {
    formState: { errors },
    getValues,
    register,
    setValue,
  } = useFormContext();

  const initialValue = getValues(name) || defaultValue;

  useEffect(() => {
    register(name);
  });

  return (
    <InnerMaskedDateInput
      id={id}
      name={name}
      label={label}
      labelAlign={labelAlign}
      labelPosition={labelPosition}
      labelStyle={labelStyle}
      containerGridTemplate={containerGridTemplate}
      isEditable={isEditable}
      initialValue={initialValue}
      onInputChange={(e) => {
        const v = e.target.value;

        setValue(name, v);
      }}
      errors={errors}
    />
  );
}

export default MaskedDateInput;
