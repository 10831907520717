import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { SimplifiedInventoryTransactionFieldsFragmentDoc } from '../../fragments/simplifiedInventoryTransactionFields.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetInventoryTransactionHistoryQueryVariables = Types.Exact<{
  initialTransaction: Types.InitialTransactionInput;
}>;


export type GetInventoryTransactionHistoryQuery = { __typename?: 'query_root', getInventoryTransactionHistory?: { __typename?: 'GetInventoryTransactionHistoryOutput', inventoryTransactions: Array<{ __typename?: 'SimplfiedInventoryTransaction', from: any, to: any, createdAt: string, quantity: number, note?: string | null | undefined, user: any, consumable: { __typename?: 'FullConsumable', id: any, originalQuantity: number, quantity: number, metadata: any, cost: number, isExpirable: boolean, expirationDate?: string | null | undefined, daysBeforeNotification?: number | null | undefined, shouldWarnQuantity: boolean, warningQuantity?: number | null | undefined, archived: boolean, createdAt: string, updatedAt: string, type: { __typename?: 'IdNameRequiredFields', id: any, name: string, requiredFields: any, unitStep: number, unitName: string, defaultNumberOfUnits: number, defaultPricePerUnit: number }, brand: { __typename?: 'IdName', id: any, name: string }, purchaseOrder?: { __typename?: 'IdOrderNumber', id: any, orderNumber: string } | null | undefined } }> } | null | undefined };


export const GetInventoryTransactionHistoryDocument = gql`
    query GetInventoryTransactionHistory($initialTransaction: InitialTransactionInput!) {
  getInventoryTransactionHistory(initialTransaction: $initialTransaction) {
    inventoryTransactions {
      ...SimplifiedInventoryTransactionFields
    }
  }
}
    ${SimplifiedInventoryTransactionFieldsFragmentDoc}`;

/**
 * __useGetInventoryTransactionHistoryQuery__
 *
 * To run a query within a React component, call `useGetInventoryTransactionHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInventoryTransactionHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInventoryTransactionHistoryQuery({
 *   variables: {
 *      initialTransaction: // value for 'initialTransaction'
 *   },
 * });
 */
export function useGetInventoryTransactionHistoryQuery(baseOptions: Apollo.QueryHookOptions<GetInventoryTransactionHistoryQuery, GetInventoryTransactionHistoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInventoryTransactionHistoryQuery, GetInventoryTransactionHistoryQueryVariables>(GetInventoryTransactionHistoryDocument, options);
      }
export function useGetInventoryTransactionHistoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInventoryTransactionHistoryQuery, GetInventoryTransactionHistoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInventoryTransactionHistoryQuery, GetInventoryTransactionHistoryQueryVariables>(GetInventoryTransactionHistoryDocument, options);
        }
export type GetInventoryTransactionHistoryQueryHookResult = ReturnType<typeof useGetInventoryTransactionHistoryQuery>;
export type GetInventoryTransactionHistoryLazyQueryHookResult = ReturnType<typeof useGetInventoryTransactionHistoryLazyQuery>;
export type GetInventoryTransactionHistoryQueryResult = Apollo.QueryResult<GetInventoryTransactionHistoryQuery, GetInventoryTransactionHistoryQueryVariables>;