import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
export type FlowsheetParameterFieldsMinimalFragment = { __typename?: 'flowsheetParameter', apiKey: string, id: any, isSystemParameter: boolean, title: string, type: string, unit: string };

export const FlowsheetParameterFieldsMinimalFragmentDoc = gql`
    fragment FlowsheetParameterFieldsMinimal on flowsheetParameter {
  apiKey
  id
  isSystemParameter
  title
  type
  unit
}
    `;