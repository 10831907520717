import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetPatientWalletTransactionsAggregateQueryVariables = Types.Exact<{
  where?: Types.Maybe<Types.PatientWalletTransaction_Bool_Exp>;
}>;


export type GetPatientWalletTransactionsAggregateQuery = { __typename?: 'query_root', patientWalletTransaction_aggregate: { __typename?: 'patientWalletTransaction_aggregate', aggregate?: { __typename?: 'patientWalletTransaction_aggregate_fields', count: number } | null | undefined } };


export const GetPatientWalletTransactionsAggregateDocument = gql`
    query GetPatientWalletTransactionsAggregate($where: patientWalletTransaction_bool_exp) {
  patientWalletTransaction_aggregate(where: $where) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetPatientWalletTransactionsAggregateQuery__
 *
 * To run a query within a React component, call `useGetPatientWalletTransactionsAggregateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPatientWalletTransactionsAggregateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPatientWalletTransactionsAggregateQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetPatientWalletTransactionsAggregateQuery(baseOptions?: Apollo.QueryHookOptions<GetPatientWalletTransactionsAggregateQuery, GetPatientWalletTransactionsAggregateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPatientWalletTransactionsAggregateQuery, GetPatientWalletTransactionsAggregateQueryVariables>(GetPatientWalletTransactionsAggregateDocument, options);
      }
export function useGetPatientWalletTransactionsAggregateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPatientWalletTransactionsAggregateQuery, GetPatientWalletTransactionsAggregateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPatientWalletTransactionsAggregateQuery, GetPatientWalletTransactionsAggregateQueryVariables>(GetPatientWalletTransactionsAggregateDocument, options);
        }
export type GetPatientWalletTransactionsAggregateQueryHookResult = ReturnType<typeof useGetPatientWalletTransactionsAggregateQuery>;
export type GetPatientWalletTransactionsAggregateLazyQueryHookResult = ReturnType<typeof useGetPatientWalletTransactionsAggregateLazyQuery>;
export type GetPatientWalletTransactionsAggregateQueryResult = Apollo.QueryResult<GetPatientWalletTransactionsAggregateQuery, GetPatientWalletTransactionsAggregateQueryVariables>;