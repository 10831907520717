import { useApolloClient } from '@apollo/client';
import {
  SearchServiceCategoriesQueryResult,
  SearchServiceCategoriesQueryVariables,
  SearchServiceCategoriesDocument,
  InsertServiceCategoryMutationResult,
  InsertServiceCategoryMutationVariables,
  InsertServiceCategoryDocument,
} from '@webapp/graphql';
import { useStores } from '@webapp/state-models';
import { SelectOption } from '@webapp/types';

export interface UseLoadServiceCategory {
  search: (searchValue: string) => Promise<SelectOption[]>;
  onCreateOption: (title: string) => Promise<SelectOption | undefined>;
}

export function useLoadServiceCategory({
  workspaceId,
  nameAsValue,
}: {
  workspaceId?: string;
  nameAsValue?: boolean;
} = {}): UseLoadServiceCategory {
  const client = useApolloClient();
  const { workspace } = useStores();
  return {
    onCreateOption: async (
      title: string
    ): Promise<SelectOption | undefined> => {
      const { data } = await client.mutate<
        InsertServiceCategoryMutationResult['data'],
        InsertServiceCategoryMutationVariables
      >({
        mutation: InsertServiceCategoryDocument,
        variables: {
          serviceCategory: {
            title,
          },
        },
      });

      const serviceCategories = data?.insert_serviceCategory?.returning ?? [];

      const [serviceCategory] = serviceCategories;

      if (serviceCategory) {
        return {
          label: serviceCategory.title,
          value: nameAsValue ? serviceCategory.title : serviceCategory.id,
        };
      }

      return undefined;
    },
    search: async (searchValue: string) => {
      const { data } = await client.query<
        SearchServiceCategoriesQueryResult['data'],
        SearchServiceCategoriesQueryVariables
      >({
        query: SearchServiceCategoriesDocument,
        variables: {
          searchValue: `%${searchValue}%`,
          workspaceId: workspaceId || workspace?.id,
          limit: 50,
        },
      });

      const serviceCategories = data?.serviceCategory ?? [];

      return serviceCategories.map((category) => ({
        label: category.title,
        value: nameAsValue ? category.title : category.id,
      }));
    },
  };
}

export default useLoadServiceCategory;
