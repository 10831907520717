import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { PatientWalletFieldsFragmentDoc } from '../../fragments/patientWalletFields.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetPatientWalletsQueryVariables = Types.Exact<{
  where?: Types.Maybe<Types.PatientWallet_Bool_Exp>;
  limit?: Types.Maybe<Types.Scalars['Int']>;
  offset?: Types.Maybe<Types.Scalars['Int']>;
  orderBy?: Types.Maybe<Array<Types.PatientWallet_Order_By> | Types.PatientWallet_Order_By>;
}>;


export type GetPatientWalletsQuery = { __typename?: 'query_root', patientWallet: Array<{ __typename?: 'patientWallet', id: any, balance: number, discount: number, patientWalletCoupons: Array<{ __typename?: 'patientWalletCoupon', coupon: { __typename?: 'coupon', id: any, name: string, rule: string, amount: any, type: Types.CouponType_Enum, scope: Types.CouponScope_Enum, validFrom: any, validUntil: any, isActive: boolean, packageId?: any | null | undefined, isSystemGenerated: boolean, locationCoupons: Array<{ __typename?: 'locationCoupon', location: { __typename?: 'location', id: any, isActive: boolean, name: string, address?: string | null | undefined, phoneNumber?: string | null | undefined, email?: string | null | undefined, maxPatientsPerTimeslot?: number | null | undefined, workspaceId: any, clinicId?: number | null | undefined, addressId?: any | null | undefined, workStartTime?: string | null | undefined, workEndTime?: string | null | undefined, timezone?: string | null | undefined, payrixMerchantId?: string | null | undefined, finixMerchantId?: string | null | undefined, locationSchedules: Array<{ __typename?: 'locationSchedule', id: any, schedule: any, effectiveFrom?: any | null | undefined, createdAt: any, updatedAt: any, locationId: any }>, fullAddress?: { __typename?: 'address', addressLine1: string, addressLine2?: string | null | undefined, city: string, id: any, state: string, zipCode: string } | null | undefined, rooms: Array<{ __typename?: 'room', id: any, name: string }> } }>, serviceCoupons: Array<{ __typename?: 'serviceCoupon', service: { __typename?: 'service', id: any, name: string } }> } }> }> };


export const GetPatientWalletsDocument = gql`
    query GetPatientWallets($where: patientWallet_bool_exp, $limit: Int, $offset: Int, $orderBy: [patientWallet_order_by!]) {
  patientWallet(where: $where, limit: $limit, offset: $offset, order_by: $orderBy) {
    ...PatientWalletFields
  }
}
    ${PatientWalletFieldsFragmentDoc}`;

/**
 * __useGetPatientWalletsQuery__
 *
 * To run a query within a React component, call `useGetPatientWalletsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPatientWalletsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPatientWalletsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetPatientWalletsQuery(baseOptions?: Apollo.QueryHookOptions<GetPatientWalletsQuery, GetPatientWalletsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPatientWalletsQuery, GetPatientWalletsQueryVariables>(GetPatientWalletsDocument, options);
      }
export function useGetPatientWalletsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPatientWalletsQuery, GetPatientWalletsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPatientWalletsQuery, GetPatientWalletsQueryVariables>(GetPatientWalletsDocument, options);
        }
export type GetPatientWalletsQueryHookResult = ReturnType<typeof useGetPatientWalletsQuery>;
export type GetPatientWalletsLazyQueryHookResult = ReturnType<typeof useGetPatientWalletsLazyQuery>;
export type GetPatientWalletsQueryResult = Apollo.QueryResult<GetPatientWalletsQuery, GetPatientWalletsQueryVariables>;