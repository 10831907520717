import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { PatientAddressFieldsFragmentDoc } from '../../fragments/patientAddressFields.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type SubscribePatientAddressSubscriptionVariables = Types.Exact<{
  where?: Types.Maybe<Types.PatientAddress_Bool_Exp>;
}>;


export type SubscribePatientAddressSubscription = { __typename?: 'subscription_root', patientAddress: Array<{ __typename?: 'patientAddress', id: any, isDefault: boolean, type: string, status: string, address: { __typename?: 'address', addressLine1: string, addressLine2?: string | null | undefined, city: string, id: any, state: string, zipCode: string } }> };


export const SubscribePatientAddressDocument = gql`
    subscription SubscribePatientAddress($where: patientAddress_bool_exp) {
  patientAddress(where: $where) {
    ...PatientAddressFields
  }
}
    ${PatientAddressFieldsFragmentDoc}`;

/**
 * __useSubscribePatientAddressSubscription__
 *
 * To run a query within a React component, call `useSubscribePatientAddressSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribePatientAddressSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribePatientAddressSubscription({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useSubscribePatientAddressSubscription(baseOptions?: Apollo.SubscriptionHookOptions<SubscribePatientAddressSubscription, SubscribePatientAddressSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<SubscribePatientAddressSubscription, SubscribePatientAddressSubscriptionVariables>(SubscribePatientAddressDocument, options);
      }
export type SubscribePatientAddressSubscriptionHookResult = ReturnType<typeof useSubscribePatientAddressSubscription>;
export type SubscribePatientAddressSubscriptionResult = Apollo.SubscriptionResult<SubscribePatientAddressSubscription>;