import './texteditor.scss';
import 'tiptap-extension-resizable-image/styles.css';
import { Box, Button, Stack, useDisclosure } from '@chakra-ui/react';
import {
  useEditor,
  EditorContent,
  BubbleMenu,
  ReactRenderer,
} from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Mention from '@tiptap/extension-mention';
import { forwardRef, useCallback, useEffect, useImperativeHandle } from 'react';
import Underline from '@tiptap/extension-underline';
import TextStyle from '@tiptap/extension-text-style';
import { Color } from '@tiptap/extension-color';
import Table from '@tiptap/extension-table';
import TableCell from '@tiptap/extension-table-cell';
import TableHeader from '@tiptap/extension-table-header';
import TableRow from '@tiptap/extension-table-row';
import Image from '@tiptap/extension-image';
import TaskItem from '@tiptap/extension-task-item';
import TaskList from '@tiptap/extension-task-list';
import Gapcursor from '@tiptap/extension-gapcursor';
import {
  MarkupModal,
  SmartNoteFlowsheetModal,
} from '@webapp/webapp/ui-composites';
import { useInsertPhotoMutation } from '@webapp/graphql';
import { ResizableImage } from 'tiptap-extension-resizable-image';
import DynamicDropdown from './extensions/DynamicDropdown/Extension';
import Chart from './extensions/Chart/Extension';

import Toolbar from './toolbar';
import {
  SmartTextEditorHandle,
  SmartTextEditorProps,
  SmartTextEditorSize,
  SmartTextEditorToolbarVersion,
} from './types';
import HighlightReplaceExtension from './highlight-replace-extension';
import { useLoadMentions } from './useLoadMentions';
import SlashCommand from './extensions/SlashCommand';
import { useLoadMergeTags } from './useLoadMergeTags';
import MergeTags from './extensions/mergeTags';

const toBase64 = (file: File) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });

export const SmartTextEditor = forwardRef<
  SmartTextEditorHandle,
  SmartTextEditorProps
>(
  (
    {
      size = SmartTextEditorSize.LARGE,
      height,
      toolbarVersion = SmartTextEditorToolbarVersion.DEFAULT,
      containerStyle,
      content,
      hideSave,
      innerContainerStyle,
      editorConfig,
      onSaveCallback,
      mergeTagContext,
      creatingTemplate = false,
      editable = true,
      onUpdate,
      workspaceId,
      patientId,
      appointmentId,
    },
    ref
  ) => {
    const suggestion = useLoadMentions({
      mergeTagContext,
    });
    const [insertPhoto] = useInsertPhotoMutation();
    const {
      isOpen: photoMarkupIsOpen,
      onOpen: onPhotoMarkupOpen,
      onClose: onPhotoMarkupClose,
    } = useDisclosure();
    const {
      isOpen: flowsheetModalIsOpen,
      onOpen: flowsheetModalOnOpen,
      onClose: onFlowsheetModalClose,
    } = useDisclosure();
    const mergeTags = useLoadMergeTags({
      mergeTagContext,
      creatingTemplate,
      patientId,
    });

    const ColorExtended = Color.extend({
      addCommands() {
        return {
          ...this.parent?.(),

          insertPhotoMarkup:
            () =>
            ({ editor, state }) => {
              const { from, to } = state.selection;
              onPhotoMarkupOpen();
            },
          insertFlowsheet:
            () =>
            ({ editor, state }) => {
              const { from, to } = state.selection;
              flowsheetModalOnOpen();
            },
        };
      },
    });

    const editor = useEditor({
      editable,

      extensions: [
        StarterKit,
        Underline,
        TextStyle,
        DynamicDropdown.configure({
          creatingTemplate,
        }),
        Chart.configure({
          creatingTemplate,
        }),

        ColorExtended,
        HighlightReplaceExtension,
        Mention.configure({
          HTMLAttributes: {
            class: 'mention',
          },
          suggestion,
        }),
        Table.configure({
          resizable: true,
          allowTableNodeSelection: true,
          HTMLAttributes: {
            class: 'tiptap-table',
          },
        }),
        TableRow,
        TableHeader,
        TableCell,
        TaskList,
        TaskItem.configure({
          nested: true,
        }),
        SlashCommand,
        ResizableImage.configure({
          allowBase64: true,
        }),
        MergeTags.configure({
          suggestion: mergeTags,
        }),
      ],
      injectNonce: creatingTemplate,
      content: content ?? ``,
      ...editorConfig,
      onUpdate: ({ editor: uEditor }) => {
        const json = uEditor.getJSON();
        onUpdate?.(json);
        // send the content to an API here
      },
    });

    const saveEditorContent = useCallback(() => {
      const content = editor?.getHTML() ?? '';

      if (onSaveCallback) {
        onSaveCallback(content);
      }

      return content;
    }, [editor, onSaveCallback]);

    useImperativeHandle(
      ref,
      () => ({
        saveContent() {
          return saveEditorContent();
        },
        focus() {
          return editor?.commands.focus();
        },
        getEditorJSON() {
          return editor?.getJSON();
        },
      }),
      [saveEditorContent]
    );

    const editorHeight = size === SmartTextEditorSize.LARGE ? 520 : 120;

    useEffect(() => {
      if (!editor?.isEditable) return;

      editor?.commands.focus(null, {
        scrollIntoView: false,
      });
    }, [editor]);

    const handleMarkupCompleted = async (
      filePath: string,
      imageState: any,
      file: File
    ) => {
      if (workspaceId && patientId) {
        insertPhoto({
          variables: {
            photo: {
              filePath,
              appointmentId,
              patientId,
              workspaceId,
              mediaType: 'markup',
              pinturaImageState: imageState,
            },
          },
        });
      }

      editor?.commands.setResizableImage({ src: await toBase64(file) });
      onClose();
    };

    return (
      <Stack
        shadow={editable ? 'md' : 'none'}
        borderWidth={editable ? 1 : 0}
        borderColor={'grey.200'}
        position="relative"
        {...containerStyle}
      >
        {editor && editable && (
          <Toolbar editor={editor} toolbarVersion={toolbarVersion} />
        )}
        <Stack
          h={height || editorHeight}
          overflowY={editable ? 'scroll' : 'auto'}
          {...innerContainerStyle}
        >
          {editor && <EditorContent editor={editor} />}
        </Stack>
        {!hideSave && (
          <Button
            colorScheme="teal"
            position="absolute"
            bottom={5}
            right={5}
            onClick={saveEditorContent}
          >
            Save
          </Button>
        )}
        <MarkupModal
          patientId={patientId}
          fullscreen
          isOpen={photoMarkupIsOpen}
          onClose={onPhotoMarkupClose}
          onMarkupCompleted={handleMarkupCompleted}
        />
        {patientId && (
          <SmartNoteFlowsheetModal
            patientId={patientId}
            isOpen={flowsheetModalIsOpen}
            onClose={onFlowsheetModalClose}
            editorReference={editor}
          />
        )}
      </Stack>
    );
  }
);

export default SmartTextEditor;
