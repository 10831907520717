/* eslint-disable prefer-arrow-callback */
/* eslint-disable arrow-body-style */
import { useEffect, useState } from 'react';
import { useSearchDeviceTypesLazyQuery, DeviceType } from '@webapp/graphql';
import Select, { components, MenuProps } from 'react-select';
import { Button, Box } from '@chakra-ui/react';
import { FaPlus } from 'react-icons/fa';
import { observer } from 'mobx-react-lite';
import { useDebounce } from 'use-debounce';

export interface DeviceTypeOption {
  readonly value: string;
  readonly label: string;
  deviceType: DeviceType;
}
export interface GroupedOption {
  readonly label: string;
  readonly options: readonly DeviceTypeOption[];
}
/* eslint-disable-next-line */
export interface DeviceTypeSearchDropdownProps {
  onCreateDeviceType?: () => void;
  onSelectDeviceType: (deviceType: DeviceType) => void;
  selectedDeviceType?: DeviceType;
  creatable?: boolean;
  placeholder?: string;
  onClear?: () => void;
}

export const DeviceTypeSearchDropdown = observer(
  ({
    onCreateDeviceType,
    onSelectDeviceType,
    selectedDeviceType,
    creatable = true,
    placeholder,
    onClear,
  }: DeviceTypeSearchDropdownProps) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [options, setOptions] = useState<DeviceTypeOption[]>([]);
    const [debouncedSearchTerm] = useDebounce(searchTerm, 300);
    const [searchDeviceType, { data, loading }] =
      useSearchDeviceTypesLazyQuery();

    useEffect(() => {
      searchDeviceType({
        variables: { limit: 10, searchValue: `%${debouncedSearchTerm}%` },
      });
    }, [debouncedSearchTerm, searchDeviceType]);

    useEffect(() => {
      setOptions(
        data?.deviceType?.map((deviceType) => ({
          value: deviceType.id,
          label: `${deviceType.value}`,
          deviceType,
        })) as DeviceTypeOption[]
      );
    }, [data]);

    const handleOnChange = (a: any, b: any) => {
      switch (b.action) {
        case 'clear':
          if (onClear) onClear();
          break;
        case 'remove-value':
          if (onClear) onClear();
          break;
        default:
          onSelectDeviceType(a?.deviceType as DeviceType);
          break;
      }
    };

    const Menu = (props: MenuProps<DeviceTypeOption, false, GroupedOption>) => (
      <components.Menu<DeviceTypeOption, false, GroupedOption> {...props}>
        {props.children}

        {creatable && (
          <Button
            onClick={onCreateDeviceType}
            w={'full'}
            leftIcon={<FaPlus />}
            variant={'ghost'}
          >
            Create new DeviceType
          </Button>
        )}
      </components.Menu>
    );

    return (
      <Box minW={200}>
        <Select
          isClearable
          defaultValue={
            selectedDeviceType
              ? {
                  label: `${selectedDeviceType.value}`,
                  value: selectedDeviceType.id,
                  deviceType: selectedDeviceType,
                }
              : null
          }
          isLoading={loading}
          onInputChange={setSearchTerm}
          onChange={handleOnChange}
          placeholder={placeholder || 'Select a Device Type'}
          options={options}
          components={{ Menu }}
        />
      </Box>
    );
  }
);

export default DeviceTypeSearchDropdown;
