import { Instance, SnapshotOut, types } from 'mobx-state-tree';

/**
 * A DeviceTypeStore model.
 */
// prettier-ignore
export const DeviceTypeModel = types.model("DeviceType").props({
  id: types.identifier,
  value: types.string
}).actions((self) => ({
  
}
))
/**
 * The DeviceType instance.
 */
export type DeviceType = Instance<typeof DeviceTypeModel>;

/**
 * The data of a DeviceType.
 */
export type DeviceTypeSnapshot = SnapshotOut<typeof DeviceTypeModel>;
