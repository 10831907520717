import { PayrixFieldsValues } from './types';

export default function populatePayrixFields(props: PayrixFieldsValues = {}) {
  const name = props.name || '';

  return [
    {
      type: 'number',
      element: '#number',
    },
    { type: 'cvv', element: '#cvv' },
    // {type: 'customer_id', element: '#customer_id', value: payrixCustomerId },
    {
      type: 'name',
      element: '#name',
      values: {
        name,
      },
    },
    { type: 'expiration', element: '#expiration' },
  ];
}
