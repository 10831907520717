import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetGoogleLocationsQueryVariables = Types.Exact<{
  accountName: Types.Scalars['String'];
}>;


export type GetGoogleLocationsQuery = { __typename?: 'query_root', googleLocations: { __typename?: 'GoogleLocationsOutput', googleLocations: any } };


export const GetGoogleLocationsDocument = gql`
    query GetGoogleLocations($accountName: String!) {
  googleLocations(accountName: $accountName) {
    googleLocations
  }
}
    `;

/**
 * __useGetGoogleLocationsQuery__
 *
 * To run a query within a React component, call `useGetGoogleLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGoogleLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGoogleLocationsQuery({
 *   variables: {
 *      accountName: // value for 'accountName'
 *   },
 * });
 */
export function useGetGoogleLocationsQuery(baseOptions: Apollo.QueryHookOptions<GetGoogleLocationsQuery, GetGoogleLocationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGoogleLocationsQuery, GetGoogleLocationsQueryVariables>(GetGoogleLocationsDocument, options);
      }
export function useGetGoogleLocationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGoogleLocationsQuery, GetGoogleLocationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGoogleLocationsQuery, GetGoogleLocationsQueryVariables>(GetGoogleLocationsDocument, options);
        }
export type GetGoogleLocationsQueryHookResult = ReturnType<typeof useGetGoogleLocationsQuery>;
export type GetGoogleLocationsLazyQueryHookResult = ReturnType<typeof useGetGoogleLocationsLazyQuery>;
export type GetGoogleLocationsQueryResult = Apollo.QueryResult<GetGoogleLocationsQuery, GetGoogleLocationsQueryVariables>;