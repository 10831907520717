import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetActivityLogsQueryVariables = Types.Exact<{
  limit?: Types.Maybe<Types.Scalars['Int']>;
  offset?: Types.Maybe<Types.Scalars['Int']>;
  orderBy?: Types.Maybe<Array<Types.ActivityLog_Order_By> | Types.ActivityLog_Order_By>;
  where?: Types.Maybe<Types.ActivityLog_Bool_Exp>;
}>;


export type GetActivityLogsQuery = { __typename?: 'query_root', activityLog_aggregate: { __typename?: 'activityLog_aggregate', aggregate?: { __typename?: 'activityLog_aggregate_fields', count: number } | null | undefined }, activityLog: Array<{ __typename?: 'activityLog', createdAt: any, id: any, metadata: any, objectType: string, operationType: string, actor?: { __typename?: 'user', id: any, firstName: string, lastName: string, title?: string | null | undefined } | null | undefined }> };


export const GetActivityLogsDocument = gql`
    query GetActivityLogs($limit: Int, $offset: Int, $orderBy: [activityLog_order_by!], $where: activityLog_bool_exp) {
  activityLog_aggregate(where: $where) {
    aggregate {
      count
    }
  }
  activityLog(limit: $limit, offset: $offset, order_by: $orderBy, where: $where) {
    actor {
      id
      firstName
      lastName
      title
    }
    createdAt
    id
    metadata
    objectType
    operationType
  }
}
    `;

/**
 * __useGetActivityLogsQuery__
 *
 * To run a query within a React component, call `useGetActivityLogsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActivityLogsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActivityLogsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      orderBy: // value for 'orderBy'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetActivityLogsQuery(baseOptions?: Apollo.QueryHookOptions<GetActivityLogsQuery, GetActivityLogsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetActivityLogsQuery, GetActivityLogsQueryVariables>(GetActivityLogsDocument, options);
      }
export function useGetActivityLogsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetActivityLogsQuery, GetActivityLogsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetActivityLogsQuery, GetActivityLogsQueryVariables>(GetActivityLogsDocument, options);
        }
export type GetActivityLogsQueryHookResult = ReturnType<typeof useGetActivityLogsQuery>;
export type GetActivityLogsLazyQueryHookResult = ReturnType<typeof useGetActivityLogsLazyQuery>;
export type GetActivityLogsQueryResult = Apollo.QueryResult<GetActivityLogsQuery, GetActivityLogsQueryVariables>;