import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type CreateInventoryTransactionMutationVariables = Types.Exact<{
  input: Types.CreateInventoryTransactionInput;
}>;


export type CreateInventoryTransactionMutation = { __typename?: 'mutation_root', createInventoryTransaction: { __typename?: 'CreateInventoryTransactionOutput', creditId: any, debitId: any, quantity: number } };


export const CreateInventoryTransactionDocument = gql`
    mutation CreateInventoryTransaction($input: CreateInventoryTransactionInput!) {
  createInventoryTransaction(input: $input) {
    creditId
    debitId
    quantity
  }
}
    `;
export type CreateInventoryTransactionMutationFn = Apollo.MutationFunction<CreateInventoryTransactionMutation, CreateInventoryTransactionMutationVariables>;

/**
 * __useCreateInventoryTransactionMutation__
 *
 * To run a mutation, you first call `useCreateInventoryTransactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInventoryTransactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInventoryTransactionMutation, { data, loading, error }] = useCreateInventoryTransactionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateInventoryTransactionMutation(baseOptions?: Apollo.MutationHookOptions<CreateInventoryTransactionMutation, CreateInventoryTransactionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateInventoryTransactionMutation, CreateInventoryTransactionMutationVariables>(CreateInventoryTransactionDocument, options);
      }
export type CreateInventoryTransactionMutationHookResult = ReturnType<typeof useCreateInventoryTransactionMutation>;
export type CreateInventoryTransactionMutationResult = Apollo.MutationResult<CreateInventoryTransactionMutation>;
export type CreateInventoryTransactionMutationOptions = Apollo.BaseMutationOptions<CreateInventoryTransactionMutation, CreateInventoryTransactionMutationVariables>;