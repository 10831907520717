import { HStack, NumberInput, NumberInputField, Text } from '@chakra-ui/react';
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import VitalsCellIconButton from '../VitalsCellIconButton/VitalsCellIconButton';

interface BloodPressureCellEditorProps {
  value: { systolic: number; diastolic: number };
  stopEditing?: () => void;
}

export const BloodPressureCellEditor = forwardRef(
  ({ value, stopEditing }: BloodPressureCellEditorProps, ref) => {
    const [systolic, setSystolic] = useState(value.systolic);
    const [diastolic, setDiastolic] = useState(value.diastolic);
    const refInput = useRef<any>(null);

    useEffect(() => {
      refInput?.current?.focus();
    }, []);

    useImperativeHandle(ref, () => ({
      getValue() {
        return { systolic, diastolic };
      },

      isCancelBeforeStart() {
        return false;
      },
    }));

    function handleChange(
      _: string,
      valueAsNumber: number,
      setter: (n: number) => void
    ) {
      setter(valueAsNumber);
    }

    return (
      <HStack spacing={2} p={1} width={250}>
        <NumberInput
          maxHeight="38px"
          maxWidth="200px"
          onChange={(s, n) => handleChange(s, n, setSystolic)}
          value={systolic}
        >
          <NumberInputField h="33px" ref={refInput} />
        </NumberInput>
        <NumberInput
          maxHeight="38px"
          maxWidth="200px"
          onChange={(s, n) => handleChange(s, n, setDiastolic)}
          value={diastolic}
        >
          <NumberInputField h="33px" />
        </NumberInput>
        <Text>mm Hg</Text>
        {stopEditing && <VitalsCellIconButton clickHandler={stopEditing} />}
      </HStack>
    );
  }
);

export default BloodPressureCellEditor;
