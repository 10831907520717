import { useState } from 'react';
import toast from 'react-hot-toast';
import { StorageType } from '@webapp/graphql';
import { Button, Box } from '@chakra-ui/react';
import { useStores } from '@webapp/state-models';
import { useUploadToS3 } from '@webapp/hooks';
import { useInsertDocumentMutation } from '@webapp/graphql';

import { observer } from 'mobx-react-lite';

import { DropAndUploadModal } from '@webapp/webapp/ui-composites';

/* eslint-disable-next-line */
export interface CreateDocumentProps {
  documentType: string;
  onCreate?: (id: string, name: string) => void;
  allowSelectType?: boolean;
}

export const CreateDocumentModal = observer(
  ({ documentType, onCreate, allowSelectType }: CreateDocumentProps) => {
    const { workspace } = useStores();
    // XXX Use static-assets bucket
    const { uploadToS3 } = useUploadToS3();
    const [progress, setProgress] = useState<number>(0);
    const [isOpen, setIsOpen] = useState(false);

    const [insertDocument] = useInsertDocumentMutation({
      onError: (e) => {
        toast.error(e.message);
      },
      refetchQueries: [
        'ListDocuments',
        'AggregateDocuments',
        'searchDocuments',
      ],
    });

    const onSubmit = async (
      files: File[],
      title?: string,
      docType?: 'precare' | 'postcare',
      tags?: { tagId: string }[]
    ): Promise<void> => {
      if (!files.length) throw new Error('No files selected');

      const file = files[0];

      const filePath = await uploadToS3({
        fileType: file.type,
        fileContents: file,
        filePath: file.name,
        storageType: StorageType.Document,
        onProgress: setProgress,
        randomizeFileName: true,
      });

      const doc = await insertDocument({
        variables: {
          document: {
            ...(tags?.length && { documentTags: { data: tags } }),
            title,
            filePath,
            workspaceId: workspace?.id,
            documentType: docType || documentType,
          },
        },
      });
      const id = doc.data?.insert_document?.returning[0].id;
      if (onCreate) onCreate(id, title as string);
    };

    return (
      <Box>
        <Button size="sm" onClick={() => setIsOpen(true)}>
          Add New Document
        </Button>
        <DropAndUploadModal
          defaultValues={{
            title: `New Document`,
            docType: documentType,
          }}
          allowSelectType={allowSelectType}
          hasTitle
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          onSubmitUpload={onSubmit}
          progress={progress}
          setProgress={setProgress}
        />
      </Box>
    );
  }
);

export default CreateDocumentModal;
