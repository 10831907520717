import { configuredDayjs } from '@webapp/util-time';
import { capitalize } from 'lodash';
import { CompoundUncontrolledFormInputProps, FormInputProps } from '../types';

function getInputValue(props: FormInputProps) {
  const coercedProps = props as CompoundUncontrolledFormInputProps;

  if (coercedProps.type === 'date' && coercedProps.value) {
    return configuredDayjs(coercedProps.value).format('LL');
  }

  if (coercedProps.type === 'select') {
    return coercedProps.value?.label ?? 'Empty';
  }

  if (coercedProps.type === 'async-select') {
    return coercedProps.value?.label ?? 'Empty';
  }

  if (coercedProps.type === 'multiselect') {
    if (Array.isArray(coercedProps.value)) {
      return coercedProps.value.map((o) => o.label).join(', ');
    }

    return 'Empty';
  }

  if (coercedProps.type === 'text' || coercedProps.type === 'radio') {
    return coercedProps.value?.length ? coercedProps.value : 'Empty';
  }

  return capitalize(`${coercedProps.value ?? 'Empty'}`);
}

export default getInputValue;
