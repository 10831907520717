import { useStores } from '@webapp/state-models';
import toast from 'react-hot-toast';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface UseCustomPermissions {
  runWithPermissionCheck: (
    action: string,
    fx: (...args: any[]) => any
  ) => (...args: any[]) => any;
  hasPermission: (action: string) => boolean;
}

export const useCustomPermissions = (): UseCustomPermissions => {
  const {
    user,
    ui: { setShowManagerPinModal },
    workspace,
  } = useStores();

  const customPermissions =
    workspace?.workspaceConfiguration?.customPermissions;

  const hasPermission = function (action: string) {
    if (!customPermissions) return true;
    const role = user?.role;
    const actionPermissions = customPermissions.toJSON()[action];
    if (!actionPermissions) {
      return true;
    }
    const rolePermissions = actionPermissions.find((p) => p.role === role);

    if (!rolePermissions) {
      return true;
    }

    switch (rolePermissions.rule) {
      case 'REQUIRES_MANAGER_PIN':
        return false;
      case 'FORBIDDEN':
        return false;
      default:
        return true;
    }
  };

  const runWithPermissionCheck = function (
    action: string,
    fx: (...args: any[]) => any
  ) {
    return function (...args: any[]) {
      const role = user?.role;

      if (!customPermissions) {
        return fx(...args);
      }

      const actionPermissions = customPermissions.toJSON()[action];
      if (!actionPermissions) {
        return fx(...args);
      }
      const rolePermissions = actionPermissions.find((p) => p.role === role);

      if (!rolePermissions) {
        return fx(...args);
      }

      switch (rolePermissions.rule) {
        case 'REQUIRES_MANAGER_PIN':
          setShowManagerPinModal(true, () => fx(...args));
          break;
        case 'FORBIDDEN':
          toast.error('You do not have permission to perform this action', {
            duration: 5000,
          });
          break;
        default:
          fx();
      }
    };
  };
  return { runWithPermissionCheck, hasPermission };
};

export default useCustomPermissions;
