import { Box, Button, ButtonGroup, Flex, Icon, Text } from '@chakra-ui/react';
import { ErrorMessage } from '@hookform/error-message';
import { ObjectOption } from '@webapp/types';
import { DataTable, FormInput } from '@webapp/ui';
import { useLoadCoupons } from '@webapp/webapp/hooks';
import { useFormContext, useFieldArray } from 'react-hook-form';
import { HiTrash } from 'react-icons/hi';
import { RiAddBoxLine } from 'react-icons/ri';
import { Cell } from 'react-table';

type CouponObjectOption = ObjectOption<{
  id: string;
  name: string;
  quantity: number;
}>;

export interface CouponsFormValues {
  coupons: CouponObjectOption[];
}

interface EditablePatientWalletCouponsTableProps {
  handleEdit: (coupon: CouponsFormValues) => void;
}

export const EditablePatientWalletCouponsTable = ({
  handleEdit,
}: EditablePatientWalletCouponsTableProps): JSX.Element => {
  const {
    control,
    formState: { errors },
    getValues,
    handleSubmit,
  } = useFormContext<CouponsFormValues>();

  const { append, fields, remove } = useFieldArray<
    CouponsFormValues,
    'coupons'
  >({
    control,
    name: 'coupons',
  });

  const getValue = (id: string): CouponObjectOption => {
    const index = fields.findIndex((field) => field.id === id);
    const name = `coupons.${index}`;

    return getValues(name as any) as CouponObjectOption;
  };

  const loadCoupons = useLoadCoupons({
    hideSystemGenerated: false,
  });

  return (
    <Box width="100%">
      <DataTable
        customStyles={{
          borderColor: '#CFEBFF',
          cell: {
            color: '#6C6C72',
          },
          table: {
            borderRadius: '8px',
            minHeight: 'unset',
          },
          tableHeader: {
            background: '#EAFCFF',
            color: '#525257',
            textTransform: 'uppercase',
          },
        }}
        data={fields}
        emptyStateText="Add or remove coupons"
        shouldDisplayPagination={false}
        columns={[
          {
            accessor: 'object',
            disableSortBy: true,
            defaultCanSort: false,
            Header: 'Coupon',
            id: 'coupons.name',
            Cell: (originalRow: Cell<Record<'id', string>>) => {
              const index = fields.findIndex(
                (field) => field.id === originalRow.row.original.id
              );

              const name = `coupons.${index}`;

              const currentValue = getValue(originalRow.row.original.id);

              return (
                <Box mb={1}>
                  <FormInput
                    type="async-select"
                    shouldDisplayError={false}
                    name={name}
                    placeholder="Select Coupon"
                    selectProps={{
                      defaultOption: currentValue,
                      loadOptions: () => loadCoupons,
                      transform: (option: CouponObjectOption) => ({
                        id: originalRow.row.original.id,
                        label: option.object.name,
                        value: option.object.id,
                        object: {
                          ...option.object,
                          quantity: 1,
                        },
                      }),
                    }}
                  />
                </Box>
              );
            },
          },
          {
            accessor: 'label',
            disableSortBy: true,
            defaultCanSort: false,
            Header: 'Quantity',
            id: 'coupons.quantity',
            Cell: (originalRow: Cell<Record<'id', string>>) => {
              const index = fields.findIndex(
                (field) => field.id === originalRow.row.original.id
              );

              return (
                <Box w="80px" ml={10}>
                  <FormInput
                    min={1}
                    name={`coupons[${index}][object][quantity]`}
                    type="number"
                  />
                </Box>
              );
            },
          },
          {
            accessor: 'id',
            disableSortBy: true,
            defaultCanSort: false,
            Header: '',
            width: 15,
            Cell: (originalRow: Cell<Record<'id', string>>) => {
              const index = fields.findIndex(
                (el) => el.id === originalRow.row.original.id
              );

              return (
                <Flex justifyContent="end" width="100%">
                  <Button
                    colorScheme="red"
                    onClick={() => {
                      remove(index);
                    }}
                    variant="ghost"
                  >
                    <Icon as={HiTrash} />
                  </Button>
                </Flex>
              );
            },
          },
        ]}
      />
      {errors.coupons && (
        <ErrorMessage
          errors={errors}
          name={'coupons'}
          render={(error) => (
            <Text
              className="input-error"
              color="red.500"
              margin="15px 0 0"
              _before={{
                display: 'inline',
                content: '"⚠ "',
              }}
            >
              {error.message ?? 'Incomplete Coupons'}
            </Text>
          )}
        />
      )}
      <Flex w="full" justifyContent={'flex-end'}>
        <ButtonGroup gap={4}>
          <Button
            color="teal.500"
            justifyContent="start"
            leftIcon={<Icon as={RiAddBoxLine} color="teal.500" />}
            margin="15px 0 0"
            onClick={() =>
              append({
                label: '',
                value: '',
                object: {
                  id: '',
                  name: '',
                  quantity: 1,
                },
              })
            }
            textAlign="left"
            variant="ghost"
            width="max-content"
          >
            Add New Coupon
          </Button>
          <Button
            marginTop={'15px'}
            colorScheme={'teal'}
            onClick={handleSubmit(handleEdit)}
          >
            Submit
          </Button>
        </ButtonGroup>
      </Flex>
    </Box>
  );
};

export default EditablePatientWalletCouponsTable;
