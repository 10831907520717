import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { PhotoModel } from './photo';

export const SocialMediaPostModel = types.model('SocialMediaPost').props({
  id: types.identifier,
  title: types.string,
  caption: types.maybeNull(types.string),
  privatePhoto: types.maybeNull(PhotoModel),
  isScheduled: types.boolean,
  intendedPostAt: types.string,
  socialMediaAccountPosts: types.array(
    types.model({
      status: types.string,
      socialMediaAccount: types.model({
        id: types.identifier,
        name: types.string,
        profilePicure: types.maybeNull(types.string),
        platform: types.string,
      }),
    })
  ),
});

export type SocialMediaPost = Instance<typeof SocialMediaPostModel>;

export type SocialMediaPostSnapshot = SnapshotOut<typeof SocialMediaPostModel>;
