import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type IncompatibleServicesAggregateQueryVariables = Types.Exact<{
  where?: Types.Maybe<Types.IncompatibleServices_Bool_Exp>;
}>;


export type IncompatibleServicesAggregateQuery = { __typename?: 'query_root', incompatibleServices_aggregate: { __typename?: 'incompatibleServices_aggregate', aggregate?: { __typename?: 'incompatibleServices_aggregate_fields', count: number } | null | undefined } };


export const IncompatibleServicesAggregateDocument = gql`
    query incompatibleServicesAggregate($where: incompatibleServices_bool_exp) {
  incompatibleServices_aggregate(where: $where) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useIncompatibleServicesAggregateQuery__
 *
 * To run a query within a React component, call `useIncompatibleServicesAggregateQuery` and pass it any options that fit your needs.
 * When your component renders, `useIncompatibleServicesAggregateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIncompatibleServicesAggregateQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useIncompatibleServicesAggregateQuery(baseOptions?: Apollo.QueryHookOptions<IncompatibleServicesAggregateQuery, IncompatibleServicesAggregateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IncompatibleServicesAggregateQuery, IncompatibleServicesAggregateQueryVariables>(IncompatibleServicesAggregateDocument, options);
      }
export function useIncompatibleServicesAggregateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IncompatibleServicesAggregateQuery, IncompatibleServicesAggregateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IncompatibleServicesAggregateQuery, IncompatibleServicesAggregateQueryVariables>(IncompatibleServicesAggregateDocument, options);
        }
export type IncompatibleServicesAggregateQueryHookResult = ReturnType<typeof useIncompatibleServicesAggregateQuery>;
export type IncompatibleServicesAggregateLazyQueryHookResult = ReturnType<typeof useIncompatibleServicesAggregateLazyQuery>;
export type IncompatibleServicesAggregateQueryResult = Apollo.QueryResult<IncompatibleServicesAggregateQuery, IncompatibleServicesAggregateQueryVariables>;