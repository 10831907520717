import {
  PatientFlowsheetsQuery,
  useDeletePatientFlowsheetDataMutation,
  useDeletePatientFlowsheetRowMutation,
  useInsertPatientFlowsheetDataMutation,
  useInsertPatientFlowsheetRowMutation,
  useUpdatePatientFlowsheetDataMutation,
  useUpdatePatientFlowsheetRowMutation,
} from '@webapp/graphql';
import { ColDef, CellValueChangedEvent } from 'ag-grid-community';
import { useCallback } from 'react';
import { FieldValues } from 'react-hook-form';
import toast from 'react-hot-toast';
import Joi from 'joi';
import useUpdateFlowsheetRowData from './useUpdateFlowsheetRowData';
import useInsertFlowsheetRowData from './useInsertFlowsheetRowData';
import useDeletePatientFlowsheetRows from './useDeletePatientFlowsheetRows';

const ValueRequiredValidation = Joi.string().required().label('Value');

export const usePatientRowMutations = function usePatientRowMutations({
  flowsheet,
  patientWorkspaceId,
}: {
  flowsheet?: {
    gridData: {
      colDefs: ColDef<unknown>[];
      data: FieldValues[];
    };
    id: string;
    rawData: PatientFlowsheetsQuery['flowsheet'][number];
    title: string;
  } | null;
  patientWorkspaceId?: string | null;
}) {
  const [insertRowMutation, { loading: insertRowLoading }] =
    useInsertPatientFlowsheetRowMutation({
      onCompleted: () => {
        toast.success('Row added successfully');
      },
      onError: (e) => {
        toast.error(
          'There was an error inserting the row, please review and try again'
        );
      },
      refetchQueries: ['PatientFlowsheets'],
    });

  const [updateRowMutation, { loading: updateRowLoading }] =
    useUpdatePatientFlowsheetRowMutation({
      onCompleted: () => {
        toast.success('Row updated successfully');
      },
      onError: (e) => {
        toast.error(
          'There was an error updating the row, please review and try again'
        );
      },
      refetchQueries: ['PatientFlowsheets'],
    });

  const [insertDataMutation, { loading: insertDataLoading }] =
    useInsertPatientFlowsheetDataMutation({
      onCompleted: () => {
        toast.success('Value added successfully');
      },
      onError: (e) => {
        toast.error(
          'There was an error inserting the value, please review and try again'
        );
      },
      refetchQueries: ['PatientFlowsheets'],
    });

  const [deleteRowMutation, { loading: deleteRowLoading }] =
    useDeletePatientFlowsheetRowMutation({
      onCompleted: () => {
        toast.success('Row deleted successfully');
      },
      onError: (e) => {
        toast.error(
          'There was an error deleting the row, please review and try again'
        );
      },
      refetchQueries: ['PatientFlowsheets'],
    });

  const [deleteDataMutation, { loading: deleteDataLoading }] =
    useDeletePatientFlowsheetDataMutation({
      onCompleted: () => {
        toast.success('Value deleted successfully');
      },
      onError: (e) => {
        toast.error(
          'There was an error deleting the value, please review and try again'
        );
      },
      refetchQueries: ['PatientFlowsheets'],
    });

  const [update, { loading: updateDataLoading }] =
    useUpdatePatientFlowsheetDataMutation({
      onCompleted: () => {
        toast.success('Value updated successfully');
      },
      onError: (e) => {
        toast.error(
          'There was an error updating the value, please review and try again'
        );
      },
      refetchQueries: ['PatientFlowsheets'],
    });

  const deleteRow = useCallback(
    (
      params: CellValueChangedEvent<{
        row: PatientFlowsheetsQuery['flowsheet'][number]['patientRows'][number];
      }>
    ) => {
      const { data } = params;

      const { row } = data;

      deleteRowMutation({
        variables: {
          id: row.id,
        },
      });
    },
    [deleteRowMutation]
  );

  function deleteData(
    params: CellValueChangedEvent<{
      row: PatientFlowsheetsQuery['flowsheet'][number]['patientRows'][number];
    }>
  ) {
    const { colDef, data } = params;

    const { row } = data;

    const datumToDelete = row.data.find(
      (datum) => datum.parameter.id === colDef.field
    );

    if (datumToDelete) {
      deleteDataMutation({
        variables: {
          id: datumToDelete.id,
        },
      });
    } else {
      toast.error(
        'Could not find the value to delete, please refresh and try again'
      );
    }
  }

  const updateData = useCallback(
    (
      event: CellValueChangedEvent<{
        flowsheetId: string;
        recordedAt: string;
        row: PatientFlowsheetsQuery['flowsheet'][number]['patientRows'][number];
      }>
    ) => {
      const value = event.newValue;

      const validationResult = ValueRequiredValidation.validate(value);

      const { error } = validationResult;

      if (error) {
        toast.error('Value is required');
      } else {
        const { data } = event;

        const { flowsheetId, row } = data;

        const datumToUpdate = row.data.find(
          (datum) => datum.parameter.id === event.colDef.field
        );

        if (event.colDef.field === 'recordedAt') {
          updateRowMutation({
            variables: {
              id: row.id,
              set: {
                recordedAt: value,
              },
            },
          });
        } else if (datumToUpdate) {
          update({
            variables: {
              id: datumToUpdate.id,
              set: {
                value,
              },
            },
          });
        } else if (event.colDef.field) {
          insertDataMutation({
            variables: {
              object: {
                flowsheetId,
                flowsheetParameterId: event.colDef.field,
                patientFlowsheetRowId: row.id,
                patientWorkspaceId,
                value: typeof value === 'string' ? value : value.toString(),
              },
            },
          });
        }
      }
    },
    [insertDataMutation, patientWorkspaceId, update, updateRowMutation]
  );

  const insertRow = useCallback(
    (values: FieldValues) => {
      if (flowsheet) {
        const transformedData = Object.keys(values)
          .filter((fpId) => values[fpId] && fpId !== 'recordedAt')
          .map((fpId) => ({
            flowsheetId: flowsheet.id,
            flowsheetParameterId: fpId,
            patientWorkspaceId,
            value:
              typeof values[fpId] === 'string'
                ? values[fpId]
                : values[fpId].toString(),
          }));

        insertRowMutation({
          variables: {
            object: {
              data: {
                data: transformedData,
              },
              flowsheetId: flowsheet.id,
              patientWorkspaceId,
              // eslint-disable-next-line dot-notation
              recordedAt: values['recordedAt'],
            },
          },
        });
      }
    },
    [flowsheet, insertRowMutation, patientWorkspaceId]
  );

  const [updateRowData, { isLoading: updateRowDataLoading }] =
    useUpdateFlowsheetRowData();

  const [insertRowData, { isLoading: insertRowDataLoading }] =
    useInsertFlowsheetRowData({
      patientWorkspaceId,
    });

  const [deleteRows, { isLoading: deleteRowsLoading }] =
    useDeletePatientFlowsheetRows();

  return {
    deleteData,
    deleteDataLoading,
    deleteRow,
    deleteRowLoading,
    deleteRows,
    deleteRowsLoading,
    insertRow,
    insertRowLoading,
    insertRowData,
    insertRowDataLoading,
    updateData,
    updateDataLoading:
      deleteRowsLoading ||
      insertDataLoading ||
      insertRowDataLoading ||
      updateDataLoading ||
      updateRowLoading ||
      updateRowDataLoading ||
      updateRowData,
  };
};

export default usePatientRowMutations;
