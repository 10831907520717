import * as Types from '../../generated/graphql';

import { gql } from '@apollo/client';
export type SmartNoteFieldsFragment = { __typename?: 'smartNote', id: any, title: string, callPhrase: string, content: string, createdAt: any, updatedAt: any, workspaceId: any };

export const SmartNoteFieldsFragmentDoc = gql`
    fragment SmartNoteFields on smartNote {
  id
  title
  callPhrase
  content
  createdAt
  updatedAt
  workspaceId
}
    `;