import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetSmsTemplatesQueryVariables = Types.Exact<{
  where?: Types.Maybe<Types.SmsTemplate_Bool_Exp>;
  limit?: Types.Maybe<Types.Scalars['Int']>;
  offset?: Types.Maybe<Types.Scalars['Int']>;
  orderBy?: Types.Maybe<Array<Types.SmsTemplate_Order_By> | Types.SmsTemplate_Order_By>;
}>;


export type GetSmsTemplatesQuery = { __typename?: 'query_root', smsTemplate: Array<{ __typename?: 'smsTemplate', id: any, active: boolean, name: string, sendDateTime?: any | null | undefined, smsBody: string, targetAudienceId?: any | null | undefined, isArchived: boolean, lastSent?: any | null | undefined, updatedAt: any, audience?: { __typename?: 'audience', type: string } | null | undefined, marketingTags: Array<{ __typename?: 'marketingTagSms', marketingTag: { __typename?: 'marketingTag', id: any, title: string } }> }> };


export const GetSmsTemplatesDocument = gql`
    query GetSMSTemplates($where: smsTemplate_bool_exp, $limit: Int, $offset: Int, $orderBy: [smsTemplate_order_by!]) {
  smsTemplate(where: $where, limit: $limit, offset: $offset, order_by: $orderBy) {
    id
    active
    name
    sendDateTime
    smsBody
    targetAudienceId
    isArchived
    lastSent
    audience {
      type
    }
    marketingTags {
      marketingTag {
        id
        title
      }
    }
    updatedAt
  }
}
    `;

/**
 * __useGetSmsTemplatesQuery__
 *
 * To run a query within a React component, call `useGetSmsTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSmsTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSmsTemplatesQuery({
 *   variables: {
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetSmsTemplatesQuery(baseOptions?: Apollo.QueryHookOptions<GetSmsTemplatesQuery, GetSmsTemplatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSmsTemplatesQuery, GetSmsTemplatesQueryVariables>(GetSmsTemplatesDocument, options);
      }
export function useGetSmsTemplatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSmsTemplatesQuery, GetSmsTemplatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSmsTemplatesQuery, GetSmsTemplatesQueryVariables>(GetSmsTemplatesDocument, options);
        }
export type GetSmsTemplatesQueryHookResult = ReturnType<typeof useGetSmsTemplatesQuery>;
export type GetSmsTemplatesLazyQueryHookResult = ReturnType<typeof useGetSmsTemplatesLazyQuery>;
export type GetSmsTemplatesQueryResult = Apollo.QueryResult<GetSmsTemplatesQuery, GetSmsTemplatesQueryVariables>;