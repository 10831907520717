import {
  chakra,
  Accordion,
  AccordionItem,
  AccordionPanel,
  Button,
  VStack,
  Stack,
  Text,
  IconButton,
  Heading,
  StyleProps,
} from '@chakra-ui/react';
import { FiEdit2 } from 'react-icons/fi';
import { joiResolver } from '@hookform/resolvers/joi';
import { useInsertOnePatientAddressMutation } from '@webapp/graphql';
import { useHookForm } from '@webapp/hooks';
import { CardTokenForm, CardTokenFormProps, FormInputV2 } from '@webapp/ui';
import { toLocalePriceInCents } from '@webapp/util-formatting';
import { useState, useCallback } from 'react';
import { FormProvider } from 'react-hook-form';
import { FinixPaymentForm } from '@webapp/webapp/ui-composites';
import PayrixPayFields, {
  PayrixPayfieldsProps,
} from '../PayrixPayFields/PayrixPayFields';
import CardDetailsValidation from './CardDetailsValidation';
import CardHolderInputs from './CardHolderInputs';
import { PayformAccordionButton } from './components';
import { CardOwnerDetails } from './types';

function decideStep(cardOwnerDetails: CardOwnerDetails | null) {
  if (
    cardOwnerDetails &&
    cardOwnerDetails.address &&
    cardOwnerDetails.address.addressLine1 &&
    cardOwnerDetails.address.city &&
    cardOwnerDetails.address.state &&
    cardOwnerDetails.address.zipCode &&
    cardOwnerDetails.email &&
    cardOwnerDetails.name &&
    cardOwnerDetails.phoneNumber
  ) {
    return 'pay';
  }
  return 'details';
}

function decideOpenAccordionItem(cardOwnerDetails: CardOwnerDetails | null) {
  if (
    cardOwnerDetails?.name &&
    cardOwnerDetails?.email &&
    cardOwnerDetails?.phoneNumber
  ) {
    return [1];
  }
  return [0, 1];
}

export type CardTokenFormOption = Omit<CardTokenFormProps, 'onComplete'> & {
  onNewCardToken: (args: {
    token: string;
    cardOwnerDetails: CardOwnerDetails;
    finixFraudSessionId: string;
  }) => void;
  onExistingCardToken: (args: { paymentMethodId: string }) => void;
  type: 'cardTokenForm' | 'payrix';
};

type PayrixOption<T extends 'txnToken' | 'token'> = PayrixPayfieldsProps<T> & {
  type: 'payrix' | 'cardTokenForm';
};

export function PayForm<Mode extends 'txnToken' | 'token'>(
  props: (PayrixOption<Mode> | CardTokenFormOption) & {
    amount?: number;
    cardOwnerDetails?: Partial<CardOwnerDetails>;
    detailsButtonText?: string;
    isLoading?: boolean;
    patientId?: string;
    showPaymentHeader?: boolean;
    showPaymentAmount?: boolean;
    styleProps?: {
      amountContainer: StyleProps;
      amountLabel: StyleProps;
      amountText: StyleProps;
      button: StyleProps;
    };
  }
) {
  const {
    amountContainer: amountContainerStyle = {},
    amountLabel: amountLabelStyle = {},
    amountText: amountTextStyle = {},
    button: buttonStyle = {},
  } = props.styleProps || {};

  const {
    address = {
      addressLine1: '',
      addressLine2: '',
      city: '',
      formattedAddress: '',
      state: '',
      zipCode: '',
    },
    email = '',
    name = '',
    phoneNumber = '',
  } = props.cardOwnerDetails || {};

  const [cardOwnerDetails, setCardOwnerDetails] =
    useState<CardOwnerDetails | null>(
      props.cardOwnerDetails
        ? {
            address,
            email,
            name,
            phoneNumber,
          }
        : null
    );

  const initialStep = decideStep(cardOwnerDetails);

  const [insertOnePatientAddressMutation] =
    useInsertOnePatientAddressMutation();

  const [step, setStep] = useState<'details' | 'pay'>(initialStep);

  const form = useHookForm<CardOwnerDetails>({
    defaultValues: {
      address,
      email,
      name,
      phoneNumber,
    },
    resolver: joiResolver(CardDetailsValidation),
    onSubmit: (formValues) => {
      // Payrix specific formatting
      const formattedPhoneNumber = formValues.phoneNumber.replace(/\D/g, '');

      setCardOwnerDetails({ ...formValues, phoneNumber: formattedPhoneNumber });
      setStep('pay');
      console.log('formValues', formValues);
      console.log(props.patientId, 'patientId');
      // Upsert billing address
      if (formValues.address && props.patientId) {
        try {
          insertOnePatientAddressMutation({
            variables: {
              addressLine1: formValues.address.addressLine1,
              addressLine2: formValues.address.addressLine2,
              city: formValues.address.city,
              isBilling: true,
              patientId: props.patientId,
              state: formValues.address.state,
              zipCode: formValues.address.zipCode,
            },
          });
        } catch (err) {
          console.error(err, 'err');
          // if we can't upsert the billing address, we can just continue
        }
      }
    },
  });

  const onComplete = useCallback(
    ({
      token,
      finixFraudSessionId,
    }: {
      token: string;
      finixFraudSessionId: string;
    }) => {
      props?.onNewCardToken?.({
        token,
        cardOwnerDetails,
        finixFraudSessionId,
      });
    },
    [props, cardOwnerDetails?.name, cardOwnerDetails?.address?.addressLine1]
  );

  return (
    <VStack width="100%">
      {props.showPaymentAmount === false ||
      props.showPaymentHeader === false ? null : (
        <Stack spacing="0" width="full" {...amountContainerStyle}>
          <Text size="medium" {...amountLabelStyle}>
            PAYMENT AMOUNT
          </Text>
          <Heading {...amountTextStyle}>
            {toLocalePriceInCents(props.amount || 0)} USD
          </Heading>
        </Stack>
      )}
      {step === 'details' && (
        <FormProvider {...form}>
          <chakra.form width="100%">
            <Accordion
              allowMultiple
              border="0"
              defaultIndex={decideOpenAccordionItem(cardOwnerDetails)}
              width="100%"
            >
              <AccordionItem borderTopWidth="2px" borderColor="white">
                <PayformAccordionButton>Card Holder</PayformAccordionButton>
                <AccordionPanel>
                  <CardHolderInputs />
                </AccordionPanel>
              </AccordionItem>
              <AccordionItem borderTopWidth="2px" borderColor="white">
                <PayformAccordionButton>Billing Address</PayformAccordionButton>
                <AccordionPanel padding="12px" width="100%">
                  <FormInputV2
                    styles={{
                      label: {
                        color:
                          'var(--sjs-font-questiontitle-color, var(--sjs-general-forecolor, var(--foreground, gray.600)))',
                      },
                    }}
                    control={form.control}
                    name="address"
                    type="address"
                  />
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
            <Button
              colorScheme="teal"
              marginTop="15px"
              width="100%"
              onClick={form.onSubmit}
              {...buttonStyle}
            >
              {props.detailsButtonText || 'Confirm'}
            </Button>
          </chakra.form>
        </FormProvider>
      )}

      {step === 'pay' && cardOwnerDetails && (
        <VStack display="grid" gridAutoRows="min-content" width="100%">
          <Stack
            justify="space-between"
            direction="row"
            spacing="4"
            borderWidth={{ base: '0', md: '1px' }}
            p={{ base: '0', md: '4' }}
            borderRadius="lg"
          >
            <Stack spacing="0.5" fontSize="sm">
              <Text
                color="var(--sjs-font-questiontitle-color, var(--sjs-general-forecolor, var(--foreground, gray.600)))"
                fontWeight="medium"
              >
                Billing Address
              </Text>
              <Text color="var(--sjs-font-questiontitle-color, var(--sjs-general-forecolor, var(--foreground, gray.600)))">{`${cardOwnerDetails?.address?.addressLine1} ${cardOwnerDetails?.address?.city} ${cardOwnerDetails?.address?.state} ${cardOwnerDetails?.address?.zipCode}`}</Text>
            </Stack>

            <Stack
              direction={{ base: 'column', sm: 'row' }}
              spacing={{ base: '0', sm: '1' }}
            >
              <IconButton
                icon={<FiEdit2 />}
                variant="tertiary"
                aria-label="Edit billing address"
                color="var(--sjs-font-questiontitle-color, var(--sjs-general-forecolor, var(--foreground, gray.600)))"
                onClick={() => setStep('details')}
              />
            </Stack>
          </Stack>

          {props.type === 'payrix' && (
            <PayrixPayFields defaultValues={cardOwnerDetails} {...props} />
          )}
          {props.type === 'cardTokenForm' && (
            <FinixPaymentForm
              onExistingPaymentMethod={(paymentMethod) =>
                props?.onExistingCardToken?.(paymentMethod, cardOwnerDetails)
              }
              cardOwnerDetails={cardOwnerDetails}
              {...props}
              onNewCardToken={onComplete}
            />
          )}
        </VStack>
      )}
    </VStack>
  );
}

export default PayForm;
