import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { PurchaseOrderFieldsFragmentDoc } from '../../fragments/purchaseOrderFields.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type UpdatePurchaseOrderMutationVariables = Types.Exact<{
  set?: Types.Maybe<Types.PurchaseOrder_Set_Input>;
  id: Types.Scalars['uuid'];
}>;


export type UpdatePurchaseOrderMutation = { __typename?: 'mutation_root', update_purchaseOrder_by_pk?: { __typename?: 'purchaseOrder', id: any, orderNumber: string, vendorOrderNumber?: string | null | undefined, note?: string | null | undefined, status: string, cost: number, createdAt: any, updatedAt: any, archived: boolean, consumableType: { __typename?: 'consumableType', id: any, name: string }, consumableBrand: { __typename?: 'consumableBrand', id: any, name: string }, consumables: Array<{ __typename?: 'consumable', id: any, cost: number, quantity: any, originalQuantity: any, metadata: any, type: { __typename?: 'consumableType', id: any, name: string }, brand?: { __typename?: 'consumableBrand', id: any, name: string } | null | undefined }> } | null | undefined };


export const UpdatePurchaseOrderDocument = gql`
    mutation UpdatePurchaseOrder($set: purchaseOrder_set_input, $id: uuid!) {
  update_purchaseOrder_by_pk(_set: $set, pk_columns: {id: $id}) {
    ...PurchaseOrderFields
  }
}
    ${PurchaseOrderFieldsFragmentDoc}`;
export type UpdatePurchaseOrderMutationFn = Apollo.MutationFunction<UpdatePurchaseOrderMutation, UpdatePurchaseOrderMutationVariables>;

/**
 * __useUpdatePurchaseOrderMutation__
 *
 * To run a mutation, you first call `useUpdatePurchaseOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePurchaseOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePurchaseOrderMutation, { data, loading, error }] = useUpdatePurchaseOrderMutation({
 *   variables: {
 *      set: // value for 'set'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdatePurchaseOrderMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePurchaseOrderMutation, UpdatePurchaseOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePurchaseOrderMutation, UpdatePurchaseOrderMutationVariables>(UpdatePurchaseOrderDocument, options);
      }
export type UpdatePurchaseOrderMutationHookResult = ReturnType<typeof useUpdatePurchaseOrderMutation>;
export type UpdatePurchaseOrderMutationResult = Apollo.MutationResult<UpdatePurchaseOrderMutation>;
export type UpdatePurchaseOrderMutationOptions = Apollo.BaseMutationOptions<UpdatePurchaseOrderMutation, UpdatePurchaseOrderMutationVariables>;