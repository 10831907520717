import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type ListPaymentMethodsQueryVariables = Types.Exact<{
  where?: Types.Maybe<Types.PaymentMethod_Bool_Exp>;
}>;


export type ListPaymentMethodsQuery = { __typename?: 'query_root', paymentMethod: Array<{ __typename?: 'paymentMethod', id: any, last4: string, createdAt: any }> };


export const ListPaymentMethodsDocument = gql`
    query ListPaymentMethods($where: paymentMethod_bool_exp) {
  paymentMethod(where: $where) {
    id
    last4
    createdAt
  }
}
    `;

/**
 * __useListPaymentMethodsQuery__
 *
 * To run a query within a React component, call `useListPaymentMethodsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListPaymentMethodsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListPaymentMethodsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useListPaymentMethodsQuery(baseOptions?: Apollo.QueryHookOptions<ListPaymentMethodsQuery, ListPaymentMethodsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListPaymentMethodsQuery, ListPaymentMethodsQueryVariables>(ListPaymentMethodsDocument, options);
      }
export function useListPaymentMethodsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListPaymentMethodsQuery, ListPaymentMethodsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListPaymentMethodsQuery, ListPaymentMethodsQueryVariables>(ListPaymentMethodsDocument, options);
        }
export type ListPaymentMethodsQueryHookResult = ReturnType<typeof useListPaymentMethodsQuery>;
export type ListPaymentMethodsLazyQueryHookResult = ReturnType<typeof useListPaymentMethodsLazyQuery>;
export type ListPaymentMethodsQueryResult = Apollo.QueryResult<ListPaymentMethodsQuery, ListPaymentMethodsQueryVariables>;