import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetMediaVersionsQueryVariables = Types.Exact<{
  photoId: Types.Scalars['uuid'];
}>;


export type GetMediaVersionsQuery = { __typename?: 'query_root', getMediaVersions?: { __typename?: 'MediaVersionsOutput', versions?: Array<{ __typename?: 'FileVersion', versionId?: string | null | undefined, key?: string | null | undefined, lastModified?: string | null | undefined, imageUrl?: string | null | undefined, isLatest?: string | null | undefined } | null | undefined> | null | undefined } | null | undefined };


export const GetMediaVersionsDocument = gql`
    query getMediaVersions($photoId: uuid!) {
  getMediaVersions(photoId: $photoId) {
    versions {
      versionId
      key
      lastModified
      imageUrl
      isLatest
    }
  }
}
    `;

/**
 * __useGetMediaVersionsQuery__
 *
 * To run a query within a React component, call `useGetMediaVersionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMediaVersionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMediaVersionsQuery({
 *   variables: {
 *      photoId: // value for 'photoId'
 *   },
 * });
 */
export function useGetMediaVersionsQuery(baseOptions: Apollo.QueryHookOptions<GetMediaVersionsQuery, GetMediaVersionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMediaVersionsQuery, GetMediaVersionsQueryVariables>(GetMediaVersionsDocument, options);
      }
export function useGetMediaVersionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMediaVersionsQuery, GetMediaVersionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMediaVersionsQuery, GetMediaVersionsQueryVariables>(GetMediaVersionsDocument, options);
        }
export type GetMediaVersionsQueryHookResult = ReturnType<typeof useGetMediaVersionsQuery>;
export type GetMediaVersionsLazyQueryHookResult = ReturnType<typeof useGetMediaVersionsLazyQuery>;
export type GetMediaVersionsQueryResult = Apollo.QueryResult<GetMediaVersionsQuery, GetMediaVersionsQueryVariables>;