import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetTaskCommentsQueryVariables = Types.Exact<{
  limit?: Types.Maybe<Types.Scalars['Int']>;
  offset?: Types.Maybe<Types.Scalars['Int']>;
  orderBy?: Types.Maybe<Array<Types.TaskComment_Order_By> | Types.TaskComment_Order_By>;
  where?: Types.Maybe<Types.TaskComment_Bool_Exp>;
}>;


export type GetTaskCommentsQuery = { __typename?: 'query_root', taskComment: Array<{ __typename?: 'taskComment', comment: string, createdAt: any, id: any, updatedAt: any, author: { __typename?: 'user', firstName: string, id: any, lastName: string, title?: string | null | undefined } }> };


export const GetTaskCommentsDocument = gql`
    query GetTaskComments($limit: Int = 50, $offset: Int = 0, $orderBy: [taskComment_order_by!], $where: taskComment_bool_exp) {
  taskComment(limit: $limit, offset: $offset, order_by: $orderBy, where: $where) {
    author {
      firstName
      id
      lastName
      title
    }
    comment
    createdAt
    id
    updatedAt
  }
}
    `;

/**
 * __useGetTaskCommentsQuery__
 *
 * To run a query within a React component, call `useGetTaskCommentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaskCommentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaskCommentsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      orderBy: // value for 'orderBy'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetTaskCommentsQuery(baseOptions?: Apollo.QueryHookOptions<GetTaskCommentsQuery, GetTaskCommentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTaskCommentsQuery, GetTaskCommentsQueryVariables>(GetTaskCommentsDocument, options);
      }
export function useGetTaskCommentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTaskCommentsQuery, GetTaskCommentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTaskCommentsQuery, GetTaskCommentsQueryVariables>(GetTaskCommentsDocument, options);
        }
export type GetTaskCommentsQueryHookResult = ReturnType<typeof useGetTaskCommentsQuery>;
export type GetTaskCommentsLazyQueryHookResult = ReturnType<typeof useGetTaskCommentsLazyQuery>;
export type GetTaskCommentsQueryResult = Apollo.QueryResult<GetTaskCommentsQuery, GetTaskCommentsQueryVariables>;