import axios from 'axios';
import {
  GraphqlClient,
  GetSignedUploadUrlDocument,
  StorageType,
} from '@webapp/graphql';

export async function uploadToS3({
  fileType,
  fileContents,
  getPresignedPostUrl,
  onProgress,
  storageType = StorageType.Private,
}: {
  fileType: string;
  fileContents: File;
  getPresignedPostUrl?: any;
  storageType?: StorageType;
  onProgress: (progress: number) => void;
}): Promise<string> {
  const client = GraphqlClient();
  const { data } = await client.query({
    query: GetSignedUploadUrlDocument,
    variables: { fileType, storageType },
    fetchPolicy: 'no-cache',
  });

  const presignedPostUrl = JSON.parse(data.getSignedUploadUrl.signedUrl);
  const formData = new FormData();
  //   formData.append('Content-Type', fileType);
  Object.entries(presignedPostUrl.fields).forEach(([k, v]) => {
    formData.append(k, v as 'string | Blob');
  });

  formData.append('Content-Type', fileType ?? fileContents.type);
  formData.append('file', fileContents); // The file has be the last element

  const config = {
    onUploadProgress(progressEvent: any) {
      const percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );
      onProgress(percentCompleted);
    },
  };

  const response = await axios.post(presignedPostUrl.url, formData, {
    headers: { 'Content-Type': fileType ?? fileContents.type },
    ...config,
  });

  console.log(response, 'response from S#');

  return presignedPostUrl.fields.key;
}

export type PresignedPostUrlResponse = {
  url: string;
  fields: {
    key: string;
    acl: string;
    bucket: string;
  };
  filePath: string;
};
