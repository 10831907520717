import {
  FieldErrors,
  FieldValues,
  FormProvider,
  SubmitHandler,
  useForm,
  UseFormReturn,
} from 'react-hook-form';
import { UseFormProps } from '@webapp/types';
import {
  Children,
  ReactElement,
  Ref,
  useEffect,
  useImperativeHandle,
  useRef,
} from 'react';
import { chakra, GridProps, StyleProps } from '@chakra-ui/react';

/* eslint-disable-next-line */
export interface HookFormProps<FormInputs extends FieldValues>
  extends UseFormProps<FormInputs> {
  as?: 'component' | 'hoc';
  children: ((props: UseFormReturn<FormInputs>) => ReactElement) | JSX.Element;
  containerStyle?: GridProps;
  formRef?: Ref<{ reset: () => void }>;
  formStyle?: StyleProps;
  onSubmit?: SubmitHandler<FormInputs>;
  shouldSubmitOnChange?: boolean;
  shouldResetOnDefaultValues?: boolean;
  logErrors?: boolean;
  values?: any;
}

const Form = chakra.form;

Form.displayName = 'HookFormForm';

function hasElementChildren(child: unknown): child is JSX.Element {
  return Boolean(Children.toArray(child as JSX.Element).length);
}

export function HookForm<FormInputs extends FieldValues>({
  children,
  defaultValues,
  values,
  delayError = undefined,
  formRef,
  formStyle = {
    display: 'grid',
  },
  onSubmit = () => undefined,
  mode = 'onSubmit',
  reValidateMode = 'onChange',
  criteriaMode = 'firstError',
  resolver,
  shouldFocusError = true,
  shouldSubmitOnChange = false,
  shouldUnregister = false,
  shouldUseNativeValidation = false,
  shouldResetOnDefaultValues = true,
  logErrors = false,
}: HookFormProps<FormInputs>) {
  const prevDefaultValuesRef = useRef(defaultValues);

  const methods = useForm<FormInputs>({
    mode,
    reValidateMode,
    defaultValues,
    values,
    criteriaMode,
    resolver,
    shouldFocusError,
    shouldUnregister,
    shouldUseNativeValidation,
    delayError,
    logErrors,
  });

  if (logErrors) {
    // eslint-disable-next-line no-console
    console.log('HookForm logerrors', methods.formState.errors);
  }

  const isHOC = !hasElementChildren(children);

  useImperativeHandle(formRef, () => ({
    reset: methods.reset,
    watch: methods.watch,
    getValues: methods.getValues,
  }));

  useEffect(() => {
    const subscription = methods.watch(() => {
      if (shouldSubmitOnChange) {
        methods.handleSubmit(onSubmit)();
      }
    });
    return () => subscription.unsubscribe();
  }, [methods, onSubmit, shouldSubmitOnChange]);

  useEffect(() => {
    const areEqual =
      JSON.stringify(defaultValues) ===
      JSON.stringify(prevDefaultValuesRef.current);

    prevDefaultValuesRef.current = defaultValues;

    if (areEqual) return;

    if (!shouldResetOnDefaultValues) return;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    methods.reset(defaultValues as any);
  }, [defaultValues, methods, shouldResetOnDefaultValues]);

  function onError(errors: FieldErrors<FieldValues>) {
    // eslint-disable-next-line no-console
    console.error(errors, 'Hook Form Errors');
  }

  return (
    <Form
      onSubmit={methods.handleSubmit(onSubmit, onError)}
      position="relative"
      width="100%"
      {...formStyle}
    >
      <FormProvider {...methods}>
        {!isHOC && children}
        {!hasElementChildren(children) && children(methods)}
      </FormProvider>
    </Form>
  );
}

export default HookForm;
