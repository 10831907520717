import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type PayInvoiceMutationVariables = Types.Exact<{
  amount: Types.Scalars['Int'];
  invoiceId: Types.Scalars['uuid'];
  paymentMethodId?: Types.Maybe<Types.Scalars['uuid']>;
  paymentMethodType: Types.Scalars['String'];
  paymentType?: Types.Maybe<Types.PaymentType>;
  payrixTransactionId?: Types.Maybe<Types.Scalars['String']>;
  walletAmount?: Types.Maybe<Types.Scalars['Int']>;
  finixToken?: Types.Maybe<Types.Scalars['String']>;
  finixCardholderDetails?: Types.Maybe<Types.Scalars['jsonb']>;
  finixFraudSessionId?: Types.Maybe<Types.Scalars['String']>;
}>;


export type PayInvoiceMutation = { __typename?: 'mutation_root', payInvoice?: { __typename?: 'PayInvoiceWithTokenOutput', status: string } | null | undefined };


export const PayInvoiceDocument = gql`
    mutation PayInvoice($amount: Int!, $invoiceId: uuid!, $paymentMethodId: uuid, $paymentMethodType: String!, $paymentType: PaymentType, $payrixTransactionId: String, $walletAmount: Int, $finixToken: String, $finixCardholderDetails: jsonb, $finixFraudSessionId: String) {
  payInvoice(
    amount: $amount
    invoiceId: $invoiceId
    paymentMethodId: $paymentMethodId
    paymentMethodType: $paymentMethodType
    paymentType: $paymentType
    payrixTransactionId: $payrixTransactionId
    walletAmount: $walletAmount
    finixToken: $finixToken
    finixCardholderDetails: $finixCardholderDetails
    finixFraudSessionId: $finixFraudSessionId
  ) {
    status
  }
}
    `;
export type PayInvoiceMutationFn = Apollo.MutationFunction<PayInvoiceMutation, PayInvoiceMutationVariables>;

/**
 * __usePayInvoiceMutation__
 *
 * To run a mutation, you first call `usePayInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePayInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [payInvoiceMutation, { data, loading, error }] = usePayInvoiceMutation({
 *   variables: {
 *      amount: // value for 'amount'
 *      invoiceId: // value for 'invoiceId'
 *      paymentMethodId: // value for 'paymentMethodId'
 *      paymentMethodType: // value for 'paymentMethodType'
 *      paymentType: // value for 'paymentType'
 *      payrixTransactionId: // value for 'payrixTransactionId'
 *      walletAmount: // value for 'walletAmount'
 *      finixToken: // value for 'finixToken'
 *      finixCardholderDetails: // value for 'finixCardholderDetails'
 *      finixFraudSessionId: // value for 'finixFraudSessionId'
 *   },
 * });
 */
export function usePayInvoiceMutation(baseOptions?: Apollo.MutationHookOptions<PayInvoiceMutation, PayInvoiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PayInvoiceMutation, PayInvoiceMutationVariables>(PayInvoiceDocument, options);
      }
export type PayInvoiceMutationHookResult = ReturnType<typeof usePayInvoiceMutation>;
export type PayInvoiceMutationResult = Apollo.MutationResult<PayInvoiceMutation>;
export type PayInvoiceMutationOptions = Apollo.BaseMutationOptions<PayInvoiceMutation, PayInvoiceMutationVariables>;