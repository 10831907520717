import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { FlowsheetParameterFieldsMinimalFragmentDoc } from '../../fragments/flowsheet/FlowsheetParameterFieldsMinimal.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type UpdatePatientFlowsheetDataMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
  set: Types.PatientFlowsheetData_Set_Input;
}>;


export type UpdatePatientFlowsheetDataMutation = { __typename?: 'mutation_root', update_patientFlowsheetData_by_pk?: { __typename?: 'patientFlowsheetData', id: any, value: string, patient: { __typename?: 'patientWorkspace', firstName?: string | null | undefined, id: any, lastName?: string | null | undefined }, parameter: { __typename?: 'flowsheetParameter', apiKey: string, id: any, isSystemParameter: boolean, title: string, type: string, unit: string } } | null | undefined };


export const UpdatePatientFlowsheetDataDocument = gql`
    mutation UpdatePatientFlowsheetData($id: uuid!, $set: patientFlowsheetData_set_input!) {
  update_patientFlowsheetData_by_pk(pk_columns: {id: $id}, _set: $set) {
    id
    patient {
      firstName
      id
      lastName
    }
    parameter {
      ...FlowsheetParameterFieldsMinimal
    }
    value
  }
}
    ${FlowsheetParameterFieldsMinimalFragmentDoc}`;
export type UpdatePatientFlowsheetDataMutationFn = Apollo.MutationFunction<UpdatePatientFlowsheetDataMutation, UpdatePatientFlowsheetDataMutationVariables>;

/**
 * __useUpdatePatientFlowsheetDataMutation__
 *
 * To run a mutation, you first call `useUpdatePatientFlowsheetDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePatientFlowsheetDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePatientFlowsheetDataMutation, { data, loading, error }] = useUpdatePatientFlowsheetDataMutation({
 *   variables: {
 *      id: // value for 'id'
 *      set: // value for 'set'
 *   },
 * });
 */
export function useUpdatePatientFlowsheetDataMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePatientFlowsheetDataMutation, UpdatePatientFlowsheetDataMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePatientFlowsheetDataMutation, UpdatePatientFlowsheetDataMutationVariables>(UpdatePatientFlowsheetDataDocument, options);
      }
export type UpdatePatientFlowsheetDataMutationHookResult = ReturnType<typeof useUpdatePatientFlowsheetDataMutation>;
export type UpdatePatientFlowsheetDataMutationResult = Apollo.MutationResult<UpdatePatientFlowsheetDataMutation>;
export type UpdatePatientFlowsheetDataMutationOptions = Apollo.BaseMutationOptions<UpdatePatientFlowsheetDataMutation, UpdatePatientFlowsheetDataMutationVariables>;