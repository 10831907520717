import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type EditImageMutationVariables = Types.Exact<{
  photoId: Types.Scalars['uuid'];
  top: Types.Scalars['Float'];
  left: Types.Scalars['Float'];
  rotation: Types.Scalars['Float'];
  height: Types.Scalars['Float'];
  width: Types.Scalars['Float'];
}>;


export type EditImageMutation = { __typename?: 'mutation_root', editImage?: { __typename?: 'EditImageOutput', success: boolean, photoId: any, photo?: { __typename?: 'photo', id: any, file?: { __typename?: 'FilePayload', url: string } | null | undefined } | null | undefined } | null | undefined };


export const EditImageDocument = gql`
    mutation editImage($photoId: uuid!, $top: Float!, $left: Float!, $rotation: Float!, $height: Float!, $width: Float!) {
  editImage(
    input: {photoId: $photoId, top: $top, left: $left, rotation: $rotation, height: $height, width: $width}
  ) {
    success
    photoId
    photo {
      id
      file {
        url
      }
    }
  }
}
    `;
export type EditImageMutationFn = Apollo.MutationFunction<EditImageMutation, EditImageMutationVariables>;

/**
 * __useEditImageMutation__
 *
 * To run a mutation, you first call `useEditImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editImageMutation, { data, loading, error }] = useEditImageMutation({
 *   variables: {
 *      photoId: // value for 'photoId'
 *      top: // value for 'top'
 *      left: // value for 'left'
 *      rotation: // value for 'rotation'
 *      height: // value for 'height'
 *      width: // value for 'width'
 *   },
 * });
 */
export function useEditImageMutation(baseOptions?: Apollo.MutationHookOptions<EditImageMutation, EditImageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditImageMutation, EditImageMutationVariables>(EditImageDocument, options);
      }
export type EditImageMutationHookResult = ReturnType<typeof useEditImageMutation>;
export type EditImageMutationResult = Apollo.MutationResult<EditImageMutation>;
export type EditImageMutationOptions = Apollo.BaseMutationOptions<EditImageMutation, EditImageMutationVariables>;