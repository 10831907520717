import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type DeleteProviderScheduleMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
}>;


export type DeleteProviderScheduleMutation = { __typename?: 'mutation_root', delete_providerSchedule_by_pk?: { __typename?: 'providerSchedule', id: any } | null | undefined };


export const DeleteProviderScheduleDocument = gql`
    mutation deleteProviderSchedule($id: uuid!) {
  delete_providerSchedule_by_pk(id: $id) {
    id
  }
}
    `;
export type DeleteProviderScheduleMutationFn = Apollo.MutationFunction<DeleteProviderScheduleMutation, DeleteProviderScheduleMutationVariables>;

/**
 * __useDeleteProviderScheduleMutation__
 *
 * To run a mutation, you first call `useDeleteProviderScheduleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProviderScheduleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProviderScheduleMutation, { data, loading, error }] = useDeleteProviderScheduleMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteProviderScheduleMutation(baseOptions?: Apollo.MutationHookOptions<DeleteProviderScheduleMutation, DeleteProviderScheduleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteProviderScheduleMutation, DeleteProviderScheduleMutationVariables>(DeleteProviderScheduleDocument, options);
      }
export type DeleteProviderScheduleMutationHookResult = ReturnType<typeof useDeleteProviderScheduleMutation>;
export type DeleteProviderScheduleMutationResult = Apollo.MutationResult<DeleteProviderScheduleMutation>;
export type DeleteProviderScheduleMutationOptions = Apollo.BaseMutationOptions<DeleteProviderScheduleMutation, DeleteProviderScheduleMutationVariables>;