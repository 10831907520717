import { StyleProps } from '@chakra-ui/react';

export const DEFAULT_LABEL_STYLE: StyleProps = {
  color: '#525257',
  fontSize: '14px',
  fontWeight: '700',
  letterSpacing: '0.01em',
};

export default DEFAULT_LABEL_STYLE;
