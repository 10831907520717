const PAY_FIELDS_CUSTOMIZATIONS = {
  style: {
    '.form-error': {
      boxSizing: 'border-box',
      padding: '12px 0',
    },
    '.input': {
      display: 'block',
      boxSizing: 'border-box',
      padding: '4px 8px',
      fontSize: '16px',
      width: '100%',
    },
    '.card-icon': {
      top: '4px',
    },
    '.cvv': {
      width: '100%',
      fontSize: '16px',
    },
    '.expiration': {
      width: '100%',
      fontSize: '16px',
    },
  },
};

export default PAY_FIELDS_CUSTOMIZATIONS;
