import React from 'react';
import {
  Flex,
  Box,
  VStack,
  Heading,
  Text,
  Divider,
  css,
} from '@chakra-ui/react';

import { toLocalePriceInCents } from '@webapp/util-formatting';
import { capitalize } from 'lodash';
import { useInvoicePreviewContext } from './InvoicePreviewContext';

export const InvoicePreviewTotals: React.FC = React.memo(() => {
  const { invoice } = useInvoicePreviewContext();

  return (
    <Flex
      justify="space-between"
      align="start"
      flexDirection={{ base: 'column', md: 'row' }}
      gap={4}
      css={css({ '@media print': { flexDirection: 'row' } })}
    >
      <Box
        width={{ base: '100%', md: '60%' }}
        css={css({ '@media print': { width: '60%' } })}
      >
        {invoice.receiptPayments && invoice.receiptPayments.length > 0 && (
          <VStack
            align="stretch"
            spacing={2}
            borderWidth={1}
            borderColor="gray.200"
            borderRadius="md"
            p={4}
            width="100%"
            css={css({
              '@media print': {
                borderWidth: '1px',
                borderColor: 'gray.200',
                borderRadius: 'md',
                p: 4,
                width: '100%',
              },
            })}
          >
            <Heading
              size="sm"
              css={css({ '@media print': { fontSize: 'sm' } })}
            >
              Payments Received
            </Heading>
            {invoice.receiptPayments.map((payment) => (
              <Flex key={payment.id} justify="space-between">
                <Text
                  fontSize={{ base: 'xs', md: 'sm' }}
                  css={css({ '@media print': { fontSize: 'sm' } })}
                >
                  {capitalize(payment.paymentMethodType)}
                </Text>
                <Text
                  fontSize={{ base: 'xs', md: 'sm' }}
                  fontWeight="medium"
                  css={css({ '@media print': { fontSize: 'sm' } })}
                >
                  {toLocalePriceInCents(payment.amount)}
                </Text>
              </Flex>
            ))}
            <Divider />
            <Flex justify="space-between">
              <Text
                fontSize={{ base: 'sm', md: 'md' }}
                fontWeight="bold"
                css={css({ '@media print': { fontSize: 'md' } })}
              >
                Total Paid
              </Text>
              <Text
                fontSize={{ base: 'sm', md: 'md' }}
                fontWeight="bold"
                css={css({ '@media print': { fontSize: 'md' } })}
              >
                {toLocalePriceInCents(
                  invoice.receiptPayments.reduce(
                    (sum, payment) => sum + payment.amount,
                    0
                  )
                )}
              </Text>
            </Flex>
          </VStack>
        )}
      </Box>
      <VStack
        align="stretch"
        spacing={2}
        width={{ base: '100%', md: '35%' }}
        css={css({ '@media print': { width: '35%' } })}
      >
        <Flex justify="space-between">
          <Text
            fontSize={{ base: 'sm', md: 'md' }}
            css={css({ '@media print': { fontSize: 'md' } })}
          >
            Discount:
          </Text>
          <Text
            fontSize={{ base: 'sm', md: 'md' }}
            color="green.500"
            css={css({ '@media print': { fontSize: 'md' } })}
          >
            -{toLocalePriceInCents(invoice.discount)}
          </Text>
        </Flex>
        <Flex justify="space-between">
          <Text
            fontSize={{ base: 'sm', md: 'md' }}
            css={css({ '@media print': { fontSize: 'md' } })}
          >
            Subtotal:
          </Text>
          <Text
            fontSize={{ base: 'sm', md: 'md' }}
            css={css({ '@media print': { fontSize: 'md' } })}
          >
            {toLocalePriceInCents(invoice.preTaxTotal)}
          </Text>
        </Flex>
        <Flex justify="space-between">
          <Text
            fontSize={{ base: 'sm', md: 'md' }}
            css={css({ '@media print': { fontSize: 'md' } })}
          >
            Tax:
          </Text>
          <Text
            fontSize={{ base: 'sm', md: 'md' }}
            css={css({ '@media print': { fontSize: 'md' } })}
          >
            {toLocalePriceInCents(invoice.taxTotal)}
          </Text>
        </Flex>
        {invoice.gratuity > 0 && (
          <Flex justify="space-between">
            <Text
              fontSize={{ base: 'sm', md: 'md' }}
              css={css({ '@media print': { fontSize: 'md' } })}
            >
              Tip:
            </Text>
            <Text
              fontSize={{ base: 'sm', md: 'md' }}
              css={css({ '@media print': { fontSize: 'md' } })}
            >
              {toLocalePriceInCents(invoice.taxTotal)}
            </Text>
          </Flex>
        )}
        <Divider />
        <Flex justify="space-between">
          <Text
            fontSize={{ base: 'sm', md: 'md' }}
            css={css({ '@media print': { fontSize: 'md' } })}
          >
            Total:
          </Text>
          <Text
            fontSize={{ base: 'sm', md: 'md' }}
            css={css({ '@media print': { fontSize: 'md' } })}
          >
            {toLocalePriceInCents(invoice.postTaxTotal)}
          </Text>
        </Flex>
        <Divider />
        <Flex justify="space-between" fontWeight="bold">
          <Text
            fontSize={{ base: 'sm', md: 'md' }}
            css={css({ '@media print': { fontSize: 'md' } })}
          >
            Total Due:
          </Text>
          <Text
            fontSize={{ base: 'md', md: 'lg' }}
            css={css({ '@media print': { fontSize: 'lg' } })}
          >
            {toLocalePriceInCents(invoice.balanceDue)}
          </Text>
        </Flex>
      </VStack>
    </Flex>
  );
});

export default InvoicePreviewTotals;
