import { PatientFieldsFragment } from '@webapp/graphql';
import { MinimalPatientWallet } from '@webapp/types';
import { uniqBy } from 'lodash';

export const determineHasPatientWallet = (
  patient: PatientFieldsFragment
): boolean => {
  const patientWallet = patient.patientWallets[0];

  return !!patientWallet;
};

export interface CouponQuantity {
  id: string;
  quantity: number;
  name: string;
  rule: string;
}

export const returnCouponQuantity = (
  coupons: MinimalPatientWallet['patientWalletCoupons']
): CouponQuantity[] => {
  const idOccurences: { [key: string]: number } = {};

  coupons.forEach(({ coupon }) => {
    if (coupon.id in idOccurences) {
      idOccurences[coupon.id] += 1;
    } else {
      idOccurences[coupon.id] = 1;
    }
  });

  const uniqueCoupons = uniqBy(coupons, 'coupon.id');

  return uniqueCoupons.map(({ coupon }) => ({
    id: coupon.id,
    quantity: idOccurences[coupon.id],
    name: coupon.name,
    rule: coupon.rule,
  }));
};
