import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type SearchPromotionsQueryVariables = Types.Exact<{
  searchValue: Types.Scalars['String'];
  limit?: Types.Maybe<Types.Scalars['Int']>;
}>;


export type SearchPromotionsQuery = { __typename?: 'query_root', promotion: Array<{ __typename?: 'promotion', id: any }> };


export const SearchPromotionsDocument = gql`
    query searchPromotions($searchValue: String!, $limit: Int) {
  promotion(where: {title: {_ilike: $searchValue}}) {
    id
  }
}
    `;

/**
 * __useSearchPromotionsQuery__
 *
 * To run a query within a React component, call `useSearchPromotionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchPromotionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchPromotionsQuery({
 *   variables: {
 *      searchValue: // value for 'searchValue'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useSearchPromotionsQuery(baseOptions: Apollo.QueryHookOptions<SearchPromotionsQuery, SearchPromotionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchPromotionsQuery, SearchPromotionsQueryVariables>(SearchPromotionsDocument, options);
      }
export function useSearchPromotionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchPromotionsQuery, SearchPromotionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchPromotionsQuery, SearchPromotionsQueryVariables>(SearchPromotionsDocument, options);
        }
export type SearchPromotionsQueryHookResult = ReturnType<typeof useSearchPromotionsQuery>;
export type SearchPromotionsLazyQueryHookResult = ReturnType<typeof useSearchPromotionsLazyQuery>;
export type SearchPromotionsQueryResult = Apollo.QueryResult<SearchPromotionsQuery, SearchPromotionsQueryVariables>;