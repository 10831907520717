import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { LeadFormLinkFieldsFragmentDoc } from '../../fragments/leadFormLink.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetLeadFormLinkQueryVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
}>;


export type GetLeadFormLinkQuery = { __typename?: 'query_root', leadFormLink_by_pk?: { __typename?: 'leadFormLink', id: any, patientId: any, formData: any, workspaceId: any, createdAt: any, updatedAt: any } | null | undefined };


export const GetLeadFormLinkDocument = gql`
    query GetLeadFormLink($id: uuid!) {
  leadFormLink_by_pk(id: $id) {
    ...LeadFormLinkFields
  }
}
    ${LeadFormLinkFieldsFragmentDoc}`;

/**
 * __useGetLeadFormLinkQuery__
 *
 * To run a query within a React component, call `useGetLeadFormLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLeadFormLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLeadFormLinkQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetLeadFormLinkQuery(baseOptions: Apollo.QueryHookOptions<GetLeadFormLinkQuery, GetLeadFormLinkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLeadFormLinkQuery, GetLeadFormLinkQueryVariables>(GetLeadFormLinkDocument, options);
      }
export function useGetLeadFormLinkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLeadFormLinkQuery, GetLeadFormLinkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLeadFormLinkQuery, GetLeadFormLinkQueryVariables>(GetLeadFormLinkDocument, options);
        }
export type GetLeadFormLinkQueryHookResult = ReturnType<typeof useGetLeadFormLinkQuery>;
export type GetLeadFormLinkLazyQueryHookResult = ReturnType<typeof useGetLeadFormLinkLazyQuery>;
export type GetLeadFormLinkQueryResult = Apollo.QueryResult<GetLeadFormLinkQuery, GetLeadFormLinkQueryVariables>;