import {
  Button,
  ButtonGroup,
  chakra,
  FormControl,
  FormLabel,
  HStack,
  IconButton,
  Input,
  Select,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import {
  useInsertLeadFormEntryMutation,
  useUpdatePatientWorkspaceMutation,
  Patient,
  PatientSearchFieldsFragment,
} from '@webapp/graphql';
import { useState } from 'react';
import { useStores } from '@webapp/state-models';
import { observer } from 'mobx-react-lite';
import { RiCloseLine } from 'react-icons/ri';
import toast from 'react-hot-toast';
import { PatientSearchDropdown, QuickPatientForm } from '@webapp/ui';
import { useForm } from 'react-hook-form';

/* eslint-disable-next-line */
export interface CreateLeadDrawerProps {
  onClose?: () => void;
}

export const CreateLeadDrawer = observer(
  ({ onClose: onCloseFromProps }: CreateLeadDrawerProps) => {
    const { workspace } = useStores();
    const [patient, setPatient] = useState<
      PatientSearchFieldsFragment | undefined
    >(undefined);
    const [creatingPatient, setCreatingPatient] = useState<boolean>(false);
    const [source, setSource] = useState<string | null>(null);
    const [leadStatus, setLeadStatus] = useState<string | null | undefined>(
      workspace?.workspaceConfiguration?.leadStatusOptions?.[0]
    );
    function onClose() {
      if (onCloseFromProps) {
        onCloseFromProps();
      }
    }

    const [insertLeadFormEntry, { data, loading, error }] =
      useInsertLeadFormEntryMutation({
        onCompleted: (data) => {
          toast.success('Lead created');
          updatePatientWorkspace({
            variables: {
              where: {
                workspaceId: {
                  _eq: workspace?.id,
                },
                patientId: {
                  _eq: patient?.id,
                },
              },
              _set: {
                source,
                leadStatus,
              },
            },
          });
          onClose();
        },
        onError: (error) => {
          if (
            error.graphQLErrors[0].extensions.code === 'constraint-violation'
          ) {
            toast.error('Patient already exists as a lead');
          } else {
            toast.error('Unable to create lead. Please try again.');
          }
        },
      });

    const [updatePatientWorkspace] = useUpdatePatientWorkspaceMutation();

    const onCreatePatient = (): void => {
      setCreatingPatient(true);
    };

    const onSelectPatient = (
      selectedPatient: PatientSearchFieldsFragment
    ): void => {
      setPatient(selectedPatient);
    };

    const createLead = async () => {
      await insertLeadFormEntry({
        variables: {
          object: {
            patientId: patient?.id,
            workspaceId: workspace?.id,
          },
        },
      });
    };

    const createPatient = async (patient: Patient) => {
      setPatient(patient);
      setCreatingPatient(false);
    };

    return (
      <Stack h={'full'} spacing="0" id="create-lead">
        <HStack
          color="teal.500"
          bg="teal.50"
          justifyContent="space-between"
          padding="16px 28px"
        >
          <Text fontSize="xl" fontWeight="bold">
            Create Lead
          </Text>
          <IconButton
            variant={'ghost'}
            onClick={onClose}
            colorScheme="teal"
            /* Source of kebab-case warning -- but this is not incorrect */
            aria-label="Close appointment creation"
            icon={<RiCloseLine size="20px" />}
          />
        </HStack>
        <Stack
          padding="24px 28px"
          spacing="24px"
          overflowY={'auto'}
          maxH="91vh"
          flexGrow={1}
        >
          {creatingPatient ? (
            <QuickPatientForm
              onCreatePatient={(patient: Patient) => createPatient(patient)}
              onClose={() => setCreatingPatient(false)}
            />
          ) : (
            <>
              <FormControl>
                <FormLabel>Patient *</FormLabel>
                <PatientSearchDropdown
                  onSelectPatient={onSelectPatient}
                  onCreatePatient={onCreatePatient}
                  selectedPatient={patient}
                />
              </FormControl>
              <FormControl>
                <FormLabel>Source</FormLabel>
                <Input
                  value={source}
                  onChange={(e) => setSource(e.target.value)}
                  id="source"
                  type="text"
                  required
                />
              </FormControl>
              <FormControl>
                <FormLabel>Status</FormLabel>
                <Select
                  value={leadStatus || undefined}
                  onChange={(e) => setLeadStatus(e.target.value)}
                >
                  {workspace?.workspaceConfiguration?.leadStatusOptions?.map(
                    (status) => (
                      <option value={status}>{status}</option>
                    )
                  )}
                </Select>
              </FormControl>
              <Button
                onClick={createLead}
                type="submit"
                disabled={!patient && !source}
                colorScheme="blue"
                size="lg"
                fontSize="md"
              >
                Create lead
              </Button>
            </>
          )}
        </Stack>
        <HStack
          borderTopWidth={1}
          borderColor={'gray.200'}
          position={'static'}
          justifyContent={'space-between'}
          p={4}
        >
          <ButtonGroup justifyContent="end" width="100%">
            <Button
              onClick={() => {
                onClose();
                if (onCloseFromProps) {
                  onCloseFromProps();
                }
              }}
              variant={'outline'}
            >
              Cancel
            </Button>
          </ButtonGroup>
        </HStack>
      </Stack>
    );
  }
);

export default CreateLeadDrawer;
