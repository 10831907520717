import { useDisclosure } from '@chakra-ui/react';
import { HookForm } from '@webapp/ui';
import {
  DocumentFieldsFragment,
  GetAppointmentDocument,
  useDeleteDocumentMutation,
} from '@webapp/graphql';
import { AppointmentStepProps } from '@webapp/types';
import { DocumentForm } from './documentForm';

interface WithItemId extends DocumentFieldsFragment {
  itemId: string;
}

interface FormInputs {
  documentsToDelete: WithItemId[];
  documents: WithItemId[];
}

export const FormDocumentSection = ({
  appointment,
  mini = false,
}: AppointmentStepProps): JSX.Element => {
  const { isOpen, onClose, onToggle } = useDisclosure();

  const [deleteDocuments] = useDeleteDocumentMutation();

  const onSubmit = ({ documentsToDelete }: FormInputs) => {
    onClose();

    if (!documentsToDelete.length) return;

    deleteDocuments({
      variables: {
        where: {
          id: {
            _in: documentsToDelete.map((doc) => doc.itemId),
          },
        },
      },
      refetchQueries: [
        {
          query: GetAppointmentDocument,
          variables: {
            id: appointment.id,
          },
        },
      ],
    });
  };

  return (
    <HookForm<FormInputs>
      defaultValues={{
        documents: appointment.documents.map((doc: any) => ({
          ...doc,
          itemId: doc.id,
        })),
        documentsToDelete: [],
      }}
      onSubmit={onSubmit}
    >
      <DocumentForm
        isEditable={isOpen}
        appointment={appointment}
        onToggle={onToggle}
        mini={mini}
      />
    </HookForm>
  );
};

export default FormDocumentSection;
