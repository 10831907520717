import {
  GraphqlClient,
  DeleteAppointmentServiceDocument,
} from '@webapp/graphql';
import dayjs from 'dayjs';
import {
  flow,
  getParent,
  IMSTArray,
  Instance,
  SnapshotOut,
  types,
} from 'mobx-state-tree';
import { DeviceModel } from './device';
import { DeviceTypeModel } from './device-type';
import { ServiceModel } from './service';

/**
 * A AppointmentServiceStore model.
 */
// prettier-ignore
export const AppointmentServiceModel = types
  .model('AppointmentService')
  .props({
    appointmentId: types.maybeNull(types.string),
    date: types.maybeNull(types.string),
    availableDevices: types.optional(types.array(DeviceModel), []),
    device: types.maybeNull(DeviceModel),
    deviceId: types.maybeNull(types.string),
    endTime: types.maybeNull(types.string),
    id: types.maybeNull(types.string),
    order: types.maybeNull(types.number),
    providerId: types.maybeNull(types.string),
    provider: types.maybeNull(
      types.model({
        firstName: types.string,
        lastName: types.string,
      })
    ),
    requiredDeviceTypes: types.optional(types.array(DeviceTypeModel), []),
    service: types.maybeNull(ServiceModel),
    serviceId: types.maybeNull(types.string),
    serviceMinutesOverride: types.maybeNull(types.number),
    serviceDurationMinutes: types.maybeNull(types.number),
    overrideDeviceRequirement: types.maybeNull(types.boolean),
    startTime: types.maybeNull(types.string),
    type: types.optional(types.string, 'booked'),
    hasBookedBefore: types.maybeNull(types.boolean),
    timerange: types.maybeNull(types.string),
  })
  .views((self) => ({
    get requiredDeviceButNoDeviceSelected() {
      if (self.overrideDeviceRequirement) {
        return false;
      }
      return (
        self.requiredDeviceTypes.length > 0 &&
        self.deviceId === null &&
        self.device === null
      );
    },
  }))
  .actions((self) => ({
    setOverrideDeviceRequirement(override: boolean) {
      self.overrideDeviceRequirement = override;
    },
    deleteService: flow(function* () {
      const client = GraphqlClient();
      try {
        // Send the delete mutation to GraphQL
        yield client.mutate({
          mutation: DeleteAppointmentServiceDocument,
          variables: {
            id: self.id,
          },
        });
      } catch (err) {
        // Handle any errors
        console.error('Error deleting appointment service:', err);
      }
    }),
    setServiceDurationMinutes(minutes: number) {
      self.serviceDurationMinutes = minutes;
    },
    setServiceMinutesOverride(minutes: number) {
      self.serviceMinutesOverride = minutes;
    },
    setAvailableDevices(devices: IMSTArray<typeof DeviceModel>) {
      self.availableDevices = devices;
    },
    setDeviceId(deviceId: string) {
      self.deviceId = deviceId;
    },
    setProviderId(providerId: string) {
      self.providerId = providerId;
    },
    setHasBookedBefore(booked: boolean) {
      self.hasBookedBefore = booked;
    },
    setTimeRange() {
      self.timerange = JSON.stringify([self.startTime, self.endTime]);
    },
    setOrder(order: number) {
      self.order = order;
    },
  }));
/**
 * The AppointmentService instance.
 */
export type AppointmentService = Instance<typeof AppointmentServiceModel>;

/**
 * The data of a AppointmentService.
 */
export type AppointmentServiceSnapshot = SnapshotOut<
  typeof AppointmentServiceModel
>;
