import { useMemo } from 'react';
import { FormLabel, Grid, Input, StyleProps, Text } from '@chakra-ui/react';
import { ErrorMessage } from '@hookform/error-message';
import dayjs from 'dayjs';
import { DEFAULT_LABEL_STYLE } from './constants';

interface InnerMaskedDateInputProps {
  id?: string;
  name: string;
  label?: string;
  labelAlign?: string;
  labelPosition?: string;
  containerGridTemplate?: string;
  labelStyle?: StyleProps;
  isEditable?: boolean;
  initialValue?: string;
  onInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  errors?: Record<string, any>;
}

export function InnerMaskedDateInput({
  id,
  name,
  label,
  labelAlign = 'center',
  labelPosition = 'top',
  labelStyle = DEFAULT_LABEL_STYLE,
  containerGridTemplate = '100px 1fr',
  isEditable = true,
  initialValue,
  onInputChange,
  errors,
}: InnerMaskedDateInputProps): JSX.Element {
  const containerColumns = useMemo(() => {
    if (label) {
      if (labelPosition === 'left') {
        return containerGridTemplate;
      }
    }

    return '1fr';
  }, [containerGridTemplate, label, labelPosition]);

  const inputId = id ?? name;

  return (
    <Grid
      alignItems={labelAlign}
      className="input-outer"
      gridAutoRows="max-content"
      gridRowGap="6px"
      gridTemplateColumns={containerColumns}
    >
      {Boolean(label) && (
        <FormLabel
          className="input-label"
          htmlFor={inputId}
          margin="0 0 0 0"
          {...labelStyle}
        >
          {label}
        </FormLabel>
      )}
      <Grid className="input-inner" gridRowGap="8px">
        {!isEditable &&
          (initialValue ? (
            <Text data-value-for={name} color="#525257">
              {dayjs(initialValue).format('MM/DD/YYYY')}
            </Text>
          ) : (
            <Text color="#525257">N/A</Text>
          ))}
        {isEditable && (
          <Input
            data-value-for={name}
            defaultValue={
              initialValue
                ? dayjs(initialValue).format('YYYY-MM-DD')
                : undefined
            }
            type="date"
            onChange={onInputChange}
          />
        )}
        {errors && (
          <ErrorMessage
            errors={errors}
            name={name}
            render={({ message }) => (
              <Text
                className="input-error"
                color="red.500"
                _before={{
                  display: 'inline',
                  content: '"⚠ "',
                }}
              >
                {message}
              </Text>
            )}
          />
        )}
      </Grid>
    </Grid>
  );
}

export default InnerMaskedDateInput;
