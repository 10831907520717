import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type MapToErxActionMutationVariables = Types.Exact<{
  patientWorkspaceId: Types.Scalars['uuid'];
}>;


export type MapToErxActionMutation = { __typename?: 'mutation_root', mapToErxAction?: { __typename?: 'MapPatientWorkspaceToERXResponse', success: boolean, dosespotPatientId?: string | null | undefined } | null | undefined };


export const MapToErxActionDocument = gql`
    mutation MapToERXAction($patientWorkspaceId: uuid!) {
  mapToErxAction(patientWorkspaceId: $patientWorkspaceId) {
    success
    dosespotPatientId
  }
}
    `;
export type MapToErxActionMutationFn = Apollo.MutationFunction<MapToErxActionMutation, MapToErxActionMutationVariables>;

/**
 * __useMapToErxActionMutation__
 *
 * To run a mutation, you first call `useMapToErxActionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMapToErxActionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mapToErxActionMutation, { data, loading, error }] = useMapToErxActionMutation({
 *   variables: {
 *      patientWorkspaceId: // value for 'patientWorkspaceId'
 *   },
 * });
 */
export function useMapToErxActionMutation(baseOptions?: Apollo.MutationHookOptions<MapToErxActionMutation, MapToErxActionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MapToErxActionMutation, MapToErxActionMutationVariables>(MapToErxActionDocument, options);
      }
export type MapToErxActionMutationHookResult = ReturnType<typeof useMapToErxActionMutation>;
export type MapToErxActionMutationResult = Apollo.MutationResult<MapToErxActionMutation>;
export type MapToErxActionMutationOptions = Apollo.BaseMutationOptions<MapToErxActionMutation, MapToErxActionMutationVariables>;