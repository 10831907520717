import { useEffect } from 'react';
import {
  FieldValues,
  SubmitErrorHandler,
  SubmitHandler,
  useForm,
  UseFormProps,
} from 'react-hook-form';
import useEnhancedHandleSubmit from './useEnhancedHandleSubmit';

export interface HookFormProps<FormInputs extends FieldValues>
  extends UseFormProps<FormInputs> {
  onError?: SubmitErrorHandler<FormInputs>;
  onSubmit: SubmitHandler<FormInputs>;
  shouldResetOnSubmit?: boolean;
  shouldSubmitOnChange?: boolean;
  shouldLogErrors?: boolean;
  values?: FormInputs;
}

export function useHookForm<FormInputs extends FieldValues>({
  criteriaMode = 'firstError',
  defaultValues,
  delayError = undefined,
  mode = 'onSubmit',
  reValidateMode = 'onChange',
  shouldLogErrors = false,
  onError,
  onSubmit,
  resolver,
  shouldFocusError = true,
  shouldResetOnSubmit = false,
  shouldSubmitOnChange = false,
  shouldUnregister = false,
  shouldUseNativeValidation = false,
  values,
}: HookFormProps<FormInputs>) {
  const form = useForm<FormInputs>({
    mode,
    reValidateMode,
    defaultValues,
    criteriaMode,
    resolver,
    shouldFocusError,
    shouldUnregister,
    shouldUseNativeValidation,
    delayError,
    values,
  });

  const { formState, handleSubmit, reset, watch } = form;

  const { errors } = formState;

  const enhancedHandleSubmit = useEnhancedHandleSubmit({
    handleSubmit,
    onError,
    reset,
    shouldResetOnSubmit,
  });

  /* Submits on changes detected */
  useEffect(() => {
    const subscription = watch(() => {
      if (shouldSubmitOnChange) {
        handleSubmit(onSubmit)();
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, handleSubmit, onSubmit, shouldSubmitOnChange]);

  /* Logs errors to console for DD session debugging */
  useEffect(() => {
    if (shouldLogErrors && Object.keys(errors).length > 0) {
      // eslint-disable-next-line no-console
      console.error('Hook Form Errors:', errors);
    }
  }, [errors, shouldLogErrors]);

  return {
    ...form,
    onSubmit: enhancedHandleSubmit(onSubmit, onError),
    handleSubmit: enhancedHandleSubmit,
  };
}

export default useHookForm;
