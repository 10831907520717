import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type SubscribePatientConsentSubscriptionVariables = Types.Exact<{
  where?: Types.Maybe<Types.PatientConsent_Bool_Exp>;
  orderBy?: Types.Maybe<Array<Types.PatientConsent_Order_By> | Types.PatientConsent_Order_By>;
}>;


export type SubscribePatientConsentSubscription = { __typename?: 'subscription_root', patientConsent: Array<{ __typename?: 'patientConsent', updatedAt: any, consent: { __typename?: 'consent', isFullMediaConsent: boolean, isPartialMediaConsent: boolean } }> };


export const SubscribePatientConsentDocument = gql`
    subscription SubscribePatientConsent($where: patientConsent_bool_exp, $orderBy: [patientConsent_order_by!]) {
  patientConsent(where: $where, order_by: $orderBy) {
    consent {
      isFullMediaConsent
      isPartialMediaConsent
    }
    updatedAt
  }
}
    `;

/**
 * __useSubscribePatientConsentSubscription__
 *
 * To run a query within a React component, call `useSubscribePatientConsentSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribePatientConsentSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribePatientConsentSubscription({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useSubscribePatientConsentSubscription(baseOptions?: Apollo.SubscriptionHookOptions<SubscribePatientConsentSubscription, SubscribePatientConsentSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<SubscribePatientConsentSubscription, SubscribePatientConsentSubscriptionVariables>(SubscribePatientConsentDocument, options);
      }
export type SubscribePatientConsentSubscriptionHookResult = ReturnType<typeof useSubscribePatientConsentSubscription>;
export type SubscribePatientConsentSubscriptionResult = Apollo.SubscriptionResult<SubscribePatientConsentSubscription>;