import { Box } from '@chakra-ui/react';
import { SelectOption } from '@webapp/types';
import { ReactNode } from 'react';
import Select from 'react-select';

export interface ColorSelectorProps {
  color: SelectOption;
  colors: SelectOption[];
  onChange: (selectOption: SelectOption | null) => void;
}

export const ColorSelector = ({
  color,
  colors,
  onChange,
}: ColorSelectorProps) => {
  const formatOptionLabel = ({ value }: SelectOption): ReactNode => {
    return <input width="50px" type="color" disabled value={value} />;
  };

  return (
    <Box w="100px">
      <Select
        value={color}
        onChange={onChange}
        formatOptionLabel={formatOptionLabel}
        options={colors}
        isMulti={false}
      />
    </Box>
  );
};

export default ColorSelector;
