import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type CheckFinixDeviceConnectionQueryVariables = Types.Exact<{
  finixDeviceId: Types.Scalars['String'];
}>;


export type CheckFinixDeviceConnectionQuery = { __typename?: 'query_root', checkFinixDeviceConnection?: { __typename?: 'SuccessAnswer', success: boolean } | null | undefined };


export const CheckFinixDeviceConnectionDocument = gql`
    query CheckFinixDeviceConnection($finixDeviceId: String!) {
  checkFinixDeviceConnection(finixDeviceId: $finixDeviceId) {
    success
  }
}
    `;

/**
 * __useCheckFinixDeviceConnectionQuery__
 *
 * To run a query within a React component, call `useCheckFinixDeviceConnectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckFinixDeviceConnectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckFinixDeviceConnectionQuery({
 *   variables: {
 *      finixDeviceId: // value for 'finixDeviceId'
 *   },
 * });
 */
export function useCheckFinixDeviceConnectionQuery(baseOptions: Apollo.QueryHookOptions<CheckFinixDeviceConnectionQuery, CheckFinixDeviceConnectionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CheckFinixDeviceConnectionQuery, CheckFinixDeviceConnectionQueryVariables>(CheckFinixDeviceConnectionDocument, options);
      }
export function useCheckFinixDeviceConnectionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckFinixDeviceConnectionQuery, CheckFinixDeviceConnectionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CheckFinixDeviceConnectionQuery, CheckFinixDeviceConnectionQueryVariables>(CheckFinixDeviceConnectionDocument, options);
        }
export type CheckFinixDeviceConnectionQueryHookResult = ReturnType<typeof useCheckFinixDeviceConnectionQuery>;
export type CheckFinixDeviceConnectionLazyQueryHookResult = ReturnType<typeof useCheckFinixDeviceConnectionLazyQuery>;
export type CheckFinixDeviceConnectionQueryResult = Apollo.QueryResult<CheckFinixDeviceConnectionQuery, CheckFinixDeviceConnectionQueryVariables>;