import {
  Button,
  Text,
  HStack,
  IconButton,
  Editable,
  EditableInput,
  EditablePreview,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  Select,
  Checkbox,
  Input,
  FormControl,
  FormLabel,
} from '@chakra-ui/react';
import { NodeViewWrapper } from '@tiptap/react';
import React, { ChangeEvent } from 'react';
import { FaCog, FaPlus } from 'react-icons/fa';
import ConfigureOptions from './ConfigureOptions';

function tryDecodeURIComponent(value: string) {
  try {
    return decodeURIComponent(value);
  } catch (e) {
    return value;
  }
}

export default (props) => {
  const onSelect = (e: ChangeEvent<HTMLSelectElement>) => {
    props.updateAttributes({
      value: encodeURIComponent(tryDecodeURIComponent(e.target.value)),
    });
  };

  const onOptionUpdate = (options: string[]) => {
    props.updateAttributes({
      options: [...(options || [])],
    });
  };

  const options =
    typeof props.node.attrs.options === 'string'
      ? props.node.attrs.options.split(',')
      : props.node.attrs.options;

  const addOption = () => {
    props.updateAttributes({
      options: [...options, 'New Option'],
    });
  };

  return (
    <NodeViewWrapper className="dynamic-dropdown">
      <HStack bg={props?.selected ? '#E8E9FF' : 'white'}>
        {props.node.attrs.showLabel && (
          <Text>{props.node.attrs.label || props.node.attrs.name}</Text>
        )}
        <Select
          disabled={!props?.editor?.options?.editable}
          maxW="200px"
          onChange={onSelect}
          value={tryDecodeURIComponent(
            props.node.attrs.value || options?.[0] || ''
          )}
        >
          {options &&
            options?.map((option: string, i: number) => (
              <option key={`option-${i}`} value={tryDecodeURIComponent(option)}>
                {tryDecodeURIComponent(option)}
              </option>
            ))}
        </Select>
        {props?.extension?.options?.creatingTemplate && (
          <Popover>
            <PopoverTrigger>
              <IconButton icon={<FaCog />} aria-label="Configure dropdown" />
            </PopoverTrigger>
            <Portal>
              <PopoverContent>
                <PopoverArrow />
                <PopoverHeader>
                  <Editable
                    defaultValue={props.node.attrs.name}
                    onChange={(v) => props.updateAttributes({ name: v })}
                  >
                    <EditablePreview />
                    <EditableInput />
                  </Editable>
                </PopoverHeader>
                <PopoverCloseButton />
                <PopoverBody>
                  <ConfigureOptions
                    options={options}
                    onChange={onOptionUpdate}
                  />
                </PopoverBody>
                <PopoverFooter>
                  <HStack justify={'between'}>
                    <Button
                      onClick={addOption}
                      variant="ghost"
                      size="sm"
                      leftIcon={<FaPlus />}
                    >
                      Add option
                    </Button>
                    <Checkbox
                      onChange={() =>
                        props.updateAttributes({
                          showLabel: !props.node.attrs.showLabel,
                        })
                      }
                      isChecked={props.node.attrs.showLabel}
                    >
                      Show Label
                    </Checkbox>
                  </HStack>
                  {props.node.attrs.showLabel && (
                    <FormControl>
                      <FormLabel>Label</FormLabel>
                      <Input
                        value={props.node.attrs.label}
                        onChange={(e) =>
                          props.updateAttributes({ label: e.target.value })
                        }
                      />
                    </FormControl>
                  )}
                </PopoverFooter>
              </PopoverContent>
            </Portal>
          </Popover>
        )}
      </HStack>
    </NodeViewWrapper>
  );
};
