import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type DisconnectNylasMutationVariables = Types.Exact<{
  userWorkspaceId: Types.Scalars['String'];
}>;


export type DisconnectNylasMutation = { __typename?: 'mutation_root', disconnectNylas: { __typename?: 'DisconnectNylasOutput', userWorkspaceId: string } };


export const DisconnectNylasDocument = gql`
    mutation DisconnectNylas($userWorkspaceId: String!) {
  disconnectNylas(userWorkspaceId: $userWorkspaceId) {
    userWorkspaceId
  }
}
    `;
export type DisconnectNylasMutationFn = Apollo.MutationFunction<DisconnectNylasMutation, DisconnectNylasMutationVariables>;

/**
 * __useDisconnectNylasMutation__
 *
 * To run a mutation, you first call `useDisconnectNylasMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisconnectNylasMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disconnectNylasMutation, { data, loading, error }] = useDisconnectNylasMutation({
 *   variables: {
 *      userWorkspaceId: // value for 'userWorkspaceId'
 *   },
 * });
 */
export function useDisconnectNylasMutation(baseOptions?: Apollo.MutationHookOptions<DisconnectNylasMutation, DisconnectNylasMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DisconnectNylasMutation, DisconnectNylasMutationVariables>(DisconnectNylasDocument, options);
      }
export type DisconnectNylasMutationHookResult = ReturnType<typeof useDisconnectNylasMutation>;
export type DisconnectNylasMutationResult = Apollo.MutationResult<DisconnectNylasMutation>;
export type DisconnectNylasMutationOptions = Apollo.BaseMutationOptions<DisconnectNylasMutation, DisconnectNylasMutationVariables>;