import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type SendLeadFormLinkMutationVariables = Types.Exact<{
  leadFormLinkId: Types.Scalars['uuid'];
}>;


export type SendLeadFormLinkMutation = { __typename?: 'mutation_root', sendLeadFormLink?: { __typename?: 'SuccessAnswer', success: boolean } | null | undefined };


export const SendLeadFormLinkDocument = gql`
    mutation SendLeadFormLink($leadFormLinkId: uuid!) {
  sendLeadFormLink(leadFormLinkId: $leadFormLinkId) {
    success
  }
}
    `;
export type SendLeadFormLinkMutationFn = Apollo.MutationFunction<SendLeadFormLinkMutation, SendLeadFormLinkMutationVariables>;

/**
 * __useSendLeadFormLinkMutation__
 *
 * To run a mutation, you first call `useSendLeadFormLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendLeadFormLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendLeadFormLinkMutation, { data, loading, error }] = useSendLeadFormLinkMutation({
 *   variables: {
 *      leadFormLinkId: // value for 'leadFormLinkId'
 *   },
 * });
 */
export function useSendLeadFormLinkMutation(baseOptions?: Apollo.MutationHookOptions<SendLeadFormLinkMutation, SendLeadFormLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendLeadFormLinkMutation, SendLeadFormLinkMutationVariables>(SendLeadFormLinkDocument, options);
      }
export type SendLeadFormLinkMutationHookResult = ReturnType<typeof useSendLeadFormLinkMutation>;
export type SendLeadFormLinkMutationResult = Apollo.MutationResult<SendLeadFormLinkMutation>;
export type SendLeadFormLinkMutationOptions = Apollo.BaseMutationOptions<SendLeadFormLinkMutation, SendLeadFormLinkMutationVariables>;