import { ColDef } from 'ag-grid-community';
import { splitCamelCaseToString } from '@webapp/util-formatting';
import { Stack } from '@chakra-ui/react';
import { CompoundControlledFormInputProps } from '@webapp/types';
import { GenericForm } from '@webapp/ui';
export interface InsertFlowsheetDataSmartNoteProps<T> {
  colDefs: ColDef<T>[];
  onSubmit: (values: T) => void;
}
export default function InsertFlowsheetDataSmartNote<T>({
  colDefs,
  onSubmit,
}: InsertFlowsheetDataSmartNoteProps<T>) {
  const fields = colDefs.reduce((acc, colDef) => {
    const Editor = colDef.cellEditor;
    const editorParams = colDef.cellEditorParams || {};
    const inputProps = editorParams.inputProps || {};

    if (Editor && colDef.field && inputProps.type) {
      const { name, ...otherInputProps } = inputProps;

      return [
        ...acc,
        {
          label: splitCamelCaseToString(colDef.headerName || colDef.field),
          name: colDef.field,
          gridArea: colDef.field,
          ...otherInputProps,
        },
      ];
    }

    return acc;
  }, [] as Omit<CompoundControlledFormInputProps, 'control'>[]);
  return (
    <Stack>
      <GenericForm fields={fields} onSubmit={onSubmit} shouldResetOnSubmit />
    </Stack>
  );
}
