import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { FlowsheetFieldsMinimalFragmentDoc } from '../../fragments/flowsheet/FlowsheetFieldsMinimal.fragment.generated';
import { FlowsheetParameterFieldsMinimalFragmentDoc } from '../../fragments/flowsheet/FlowsheetParameterFieldsMinimal.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type UpdateFlowsheetMutationVariables = Types.Exact<{
  flowsheetId: Types.Scalars['uuid'];
  title: Types.Scalars['String'];
  parameterIds: Array<Types.Scalars['uuid']> | Types.Scalars['uuid'];
}>;


export type UpdateFlowsheetMutation = { __typename?: 'mutation_root', updateFlowsheet: { __typename?: 'UpdateFlowsheetOutput', flowsheet?: { __typename?: 'flowsheet', id: any, title: string, parameters: Array<{ __typename?: 'flowsheetParameterFlowsheet', id: any, parameter: { __typename?: 'flowsheetParameter', apiKey: string, id: any, isSystemParameter: boolean, title: string, type: string, unit: string } }> } | null | undefined } };


export const UpdateFlowsheetDocument = gql`
    mutation UpdateFlowsheet($flowsheetId: uuid!, $title: String!, $parameterIds: [uuid!]!) {
  updateFlowsheet(
    flowsheetId: $flowsheetId
    title: $title
    parameterIds: $parameterIds
  ) {
    flowsheet {
      ...FlowsheetFieldsMinimal
      parameters {
        id
        parameter {
          ...FlowsheetParameterFieldsMinimal
        }
      }
    }
  }
}
    ${FlowsheetFieldsMinimalFragmentDoc}
${FlowsheetParameterFieldsMinimalFragmentDoc}`;
export type UpdateFlowsheetMutationFn = Apollo.MutationFunction<UpdateFlowsheetMutation, UpdateFlowsheetMutationVariables>;

/**
 * __useUpdateFlowsheetMutation__
 *
 * To run a mutation, you first call `useUpdateFlowsheetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFlowsheetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFlowsheetMutation, { data, loading, error }] = useUpdateFlowsheetMutation({
 *   variables: {
 *      flowsheetId: // value for 'flowsheetId'
 *      title: // value for 'title'
 *      parameterIds: // value for 'parameterIds'
 *   },
 * });
 */
export function useUpdateFlowsheetMutation(baseOptions?: Apollo.MutationHookOptions<UpdateFlowsheetMutation, UpdateFlowsheetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateFlowsheetMutation, UpdateFlowsheetMutationVariables>(UpdateFlowsheetDocument, options);
      }
export type UpdateFlowsheetMutationHookResult = ReturnType<typeof useUpdateFlowsheetMutation>;
export type UpdateFlowsheetMutationResult = Apollo.MutationResult<UpdateFlowsheetMutation>;
export type UpdateFlowsheetMutationOptions = Apollo.BaseMutationOptions<UpdateFlowsheetMutation, UpdateFlowsheetMutationVariables>;