const ignoreErrorList = [
  'ResizeObserver loop limit exceeded',
  'ResizeObserver loop completed with undelivered notifications',
  'Twilsock: request timeout',
  'Large Render Blocking Asset',
  'disconnected',
  'Maximum attempt count limit reached',
];

export default ignoreErrorList;
