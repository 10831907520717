import * as Types from '../../generated/graphql';

import { gql } from '@apollo/client';
export type InventoryAggregateFieldsFragment = { __typename?: 'GetInventoryAggregateOutput', count: number, expiringCount: number, typeCount: number, brandCount: number, totalValue: number, stockAlertCount: number };

export const InventoryAggregateFieldsFragmentDoc = gql`
    fragment InventoryAggregateFields on GetInventoryAggregateOutput {
  count
  expiringCount
  typeCount
  brandCount
  totalValue
  stockAlertCount
}
    `;