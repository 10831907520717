import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetEmailTemplateQueryVariables = Types.Exact<{
  where: Types.EmailTemplate_Bool_Exp;
}>;


export type GetEmailTemplateQuery = { __typename?: 'query_root', emailTemplate: Array<{ __typename?: 'emailTemplate', id: any, emailName?: string | null | undefined, includesPromotion: boolean, fromAddress?: string | null | undefined, fromName?: string | null | undefined, subjectLine?: string | null | undefined, design: any, html?: string | null | undefined, sendDateTime?: any | null | undefined, lastSentDate?: any | null | undefined, targetAudienceId?: any | null | undefined, omitAudienceId?: any | null | undefined, deliveries: number, opens: number, replies: number, bounces: number, clicks: number, spamFlags: number, unsubscribes: number, allowResend: boolean, isActive: boolean, isTemplate: boolean, outputJson?: string | null | undefined, description?: string | null | undefined, audienceType: string, updatedAt: any, resendWindow?: number | null | undefined, state?: string | null | undefined, targetAudience?: { __typename?: 'audience', type: string, name?: string | null | undefined, snapshot: any, definition: any } | null | undefined, omitAudience?: { __typename?: 'audience', type: string, name?: string | null | undefined, snapshot: any, definition: any } | null | undefined, marketingTags: Array<{ __typename?: 'marketingTagEmail', marketingTag: { __typename?: 'marketingTag', id: any, title: string } }>, emailTemplateScreenshot?: { __typename?: 'FileOnlyURL', url: string } | null | undefined }> };


export const GetEmailTemplateDocument = gql`
    query getEmailTemplate($where: emailTemplate_bool_exp!) {
  emailTemplate(where: $where) {
    id
    emailName
    includesPromotion
    fromAddress
    fromName
    subjectLine
    design
    html
    sendDateTime
    lastSentDate
    targetAudienceId
    targetAudience {
      type
      name
      snapshot
      definition
    }
    omitAudienceId
    omitAudience {
      type
      name
      snapshot
      definition
    }
    deliveries
    opens
    replies
    bounces
    clicks
    spamFlags
    unsubscribes
    allowResend
    isActive
    isTemplate
    outputJson
    description
    audienceType
    updatedAt
    resendWindow
    state
    marketingTags {
      marketingTag {
        id
        title
      }
    }
    emailTemplateScreenshot {
      url
    }
  }
}
    `;

/**
 * __useGetEmailTemplateQuery__
 *
 * To run a query within a React component, call `useGetEmailTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmailTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmailTemplateQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetEmailTemplateQuery(baseOptions: Apollo.QueryHookOptions<GetEmailTemplateQuery, GetEmailTemplateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEmailTemplateQuery, GetEmailTemplateQueryVariables>(GetEmailTemplateDocument, options);
      }
export function useGetEmailTemplateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEmailTemplateQuery, GetEmailTemplateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEmailTemplateQuery, GetEmailTemplateQueryVariables>(GetEmailTemplateDocument, options);
        }
export type GetEmailTemplateQueryHookResult = ReturnType<typeof useGetEmailTemplateQuery>;
export type GetEmailTemplateLazyQueryHookResult = ReturnType<typeof useGetEmailTemplateLazyQuery>;
export type GetEmailTemplateQueryResult = Apollo.QueryResult<GetEmailTemplateQuery, GetEmailTemplateQueryVariables>;