import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type SubscribeNewPhotosSubscriptionVariables = Types.Exact<{
  createdAt?: Types.Maybe<Types.Scalars['timestamptz']>;
  where?: Types.Maybe<Types.Photo_Bool_Exp>;
}>;


export type SubscribeNewPhotosSubscription = { __typename?: 'subscription_root', photo_stream: Array<{ __typename?: 'photo', id: any }> };


export const SubscribeNewPhotosDocument = gql`
    subscription subscribeNewPhotos($createdAt: timestamptz, $where: photo_bool_exp) {
  photo_stream(
    where: $where
    cursor: {initial_value: {createdAt: $createdAt}, ordering: ASC}
    batch_size: 10
  ) {
    id
  }
}
    `;

/**
 * __useSubscribeNewPhotosSubscription__
 *
 * To run a query within a React component, call `useSubscribeNewPhotosSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeNewPhotosSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeNewPhotosSubscription({
 *   variables: {
 *      createdAt: // value for 'createdAt'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useSubscribeNewPhotosSubscription(baseOptions?: Apollo.SubscriptionHookOptions<SubscribeNewPhotosSubscription, SubscribeNewPhotosSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<SubscribeNewPhotosSubscription, SubscribeNewPhotosSubscriptionVariables>(SubscribeNewPhotosDocument, options);
      }
export type SubscribeNewPhotosSubscriptionHookResult = ReturnType<typeof useSubscribeNewPhotosSubscription>;
export type SubscribeNewPhotosSubscriptionResult = Apollo.SubscriptionResult<SubscribeNewPhotosSubscription>;