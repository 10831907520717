import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type ResolveAppointmentServiceConsumableMutationVariables = Types.Exact<{
  input: Types.ResolveAppointmentServiceConsumableInput;
}>;


export type ResolveAppointmentServiceConsumableMutation = { __typename?: 'mutation_root', resolveAppointmentServiceConsumable?: { __typename?: 'ResolveAppointmentServiceConsumableOutput', consumableId: any, appointmentId: any, locationId: any, appointmentServiceId: any, creditId: any, debitId: any, quantity: number } | null | undefined };


export const ResolveAppointmentServiceConsumableDocument = gql`
    mutation ResolveAppointmentServiceConsumable($input: ResolveAppointmentServiceConsumableInput!) {
  resolveAppointmentServiceConsumable(input: $input) {
    consumableId
    appointmentId
    locationId
    appointmentServiceId
    creditId
    debitId
    quantity
  }
}
    `;
export type ResolveAppointmentServiceConsumableMutationFn = Apollo.MutationFunction<ResolveAppointmentServiceConsumableMutation, ResolveAppointmentServiceConsumableMutationVariables>;

/**
 * __useResolveAppointmentServiceConsumableMutation__
 *
 * To run a mutation, you first call `useResolveAppointmentServiceConsumableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResolveAppointmentServiceConsumableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resolveAppointmentServiceConsumableMutation, { data, loading, error }] = useResolveAppointmentServiceConsumableMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResolveAppointmentServiceConsumableMutation(baseOptions?: Apollo.MutationHookOptions<ResolveAppointmentServiceConsumableMutation, ResolveAppointmentServiceConsumableMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResolveAppointmentServiceConsumableMutation, ResolveAppointmentServiceConsumableMutationVariables>(ResolveAppointmentServiceConsumableDocument, options);
      }
export type ResolveAppointmentServiceConsumableMutationHookResult = ReturnType<typeof useResolveAppointmentServiceConsumableMutation>;
export type ResolveAppointmentServiceConsumableMutationResult = Apollo.MutationResult<ResolveAppointmentServiceConsumableMutation>;
export type ResolveAppointmentServiceConsumableMutationOptions = Apollo.BaseMutationOptions<ResolveAppointmentServiceConsumableMutation, ResolveAppointmentServiceConsumableMutationVariables>;