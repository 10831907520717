import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type InsertCustomQuestionOptionMutationVariables = Types.Exact<{
  rows: Array<Types.CustomQuestionOption_Insert_Input> | Types.CustomQuestionOption_Insert_Input;
}>;


export type InsertCustomQuestionOptionMutation = { __typename?: 'mutation_root', insert_customQuestionOption?: { __typename?: 'customQuestionOption_mutation_response', returning: Array<{ __typename?: 'customQuestionOption', id: any }> } | null | undefined };


export const InsertCustomQuestionOptionDocument = gql`
    mutation insertCustomQuestionOption($rows: [customQuestionOption_insert_input!]!) {
  insert_customQuestionOption(objects: $rows) {
    returning {
      id
    }
  }
}
    `;
export type InsertCustomQuestionOptionMutationFn = Apollo.MutationFunction<InsertCustomQuestionOptionMutation, InsertCustomQuestionOptionMutationVariables>;

/**
 * __useInsertCustomQuestionOptionMutation__
 *
 * To run a mutation, you first call `useInsertCustomQuestionOptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertCustomQuestionOptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertCustomQuestionOptionMutation, { data, loading, error }] = useInsertCustomQuestionOptionMutation({
 *   variables: {
 *      rows: // value for 'rows'
 *   },
 * });
 */
export function useInsertCustomQuestionOptionMutation(baseOptions?: Apollo.MutationHookOptions<InsertCustomQuestionOptionMutation, InsertCustomQuestionOptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertCustomQuestionOptionMutation, InsertCustomQuestionOptionMutationVariables>(InsertCustomQuestionOptionDocument, options);
      }
export type InsertCustomQuestionOptionMutationHookResult = ReturnType<typeof useInsertCustomQuestionOptionMutation>;
export type InsertCustomQuestionOptionMutationResult = Apollo.MutationResult<InsertCustomQuestionOptionMutation>;
export type InsertCustomQuestionOptionMutationOptions = Apollo.BaseMutationOptions<InsertCustomQuestionOptionMutation, InsertCustomQuestionOptionMutationVariables>;