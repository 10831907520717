import {
  PayrixToken,
  PayrixTokenResponse,
  PayrixTxnTokenResponse,
} from '@webapp/types';
import isTxnToken from './isTxnToken';

function extractPayrixTokenInformation(
  response: PayrixTokenResponse | PayrixTxnTokenResponse
): PayrixToken[] {
  if (isTxnToken(response)) {
    return response.data.map((datum) => datum.token);
  }

  return response.data;
}

export default extractPayrixTokenInformation;
