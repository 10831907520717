import { Box, Input, Button, Select, Grid, GridItem } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useStores } from '@webapp/state-models';
import { observer } from 'mobx-react-lite';

import { audienceFilterSelectorToLabel, audienceFilterOpToStr } from './util';

type ValueResolver = (variables: any) => Promise<string[]>;

export interface AudienceFilterPredicateProps {
  target: string;
  field: string;
  values: ValueResolver | null;
  valuesComponent: any;
  ops: string[];
}

export const AudienceFilterPredicate = observer(
  /* eslint-disable-next-line */
  ({
    target,
    field,
    values,
    valuesComponent,
    ops,
  }: AudienceFilterPredicateProps) => {
    const { audience, workspace } = useStores();
    const [selectedOp, setOp] = useState(ops[0]);
    const [populatedValues, setPopulatedValues] = useState([] as string[]);
    const [selectedVal, setSelectedVal] = useState('');
    const [selectedValLabel, setSelectedValLabel] = useState('');

    const addPredicate = () =>
      audience?.addCondition(
        target,
        field,
        selectedOp,
        selectedVal,
        selectedValLabel || ''
      );

    // Simple dropdown for detrministic enumerated lists
    useEffect(() => {
      if (!values) return;
      const loadValues = async () => {
        const loadedValues = await values({ workspaceId: workspace?.id });
        if (!selectedVal) {
          setSelectedVal(loadedValues[0]);
          setSelectedValLabel(loadedValues[0]);
        }
        setPopulatedValues(loadedValues);
      };
      loadValues();
    }, []);

    let ValueEntry = <></>;
    // If we have a full values selection component defined for this predicate, use that
    // otherwise use the simple dropdown, otherwise free-text entry
    if (valuesComponent) {
      const selectValue = (result: any) => {
        if (result.length) {
          setSelectedValLabel(result[0].label);
          setSelectedVal(result[0].value);
        } else {
          setSelectedValLabel(result.label);
          setSelectedVal(result.value);
        }
      };
      ValueEntry = valuesComponent(selectValue, workspace?.id);
    } else {
      const selectValue = (value: string) => {
        setSelectedValLabel(value);
        setSelectedVal(value);
      };
      ValueEntry =
        values === null ? (
          <Input size="xs" onChange={(e) => selectValue(e.target.value)} />
        ) : (
          <Select size="xs" onChange={(e) => selectValue(e.target.value)}>
            {populatedValues.map((val: string, idx: number) => (
              <option key={idx} value={val}>
                {val}
              </option>
            ))}
          </Select>
        );
    }
    return (
      <Grid templateColumns="2fr 4fr 4fr 1fr">
        <GridItem p="1">
          <Box fontSize="sm" textAlign="right">
            {audienceFilterSelectorToLabel(target, field)}
          </Box>
        </GridItem>
        <GridItem p="1">
          <Select size="xs" onChange={(e) => setOp(e.target.value)}>
            {ops.map((op: string, idx: number) => (
              <option key={idx} value={op}>
                {audienceFilterOpToStr(op)}
              </option>
            ))}
          </Select>
        </GridItem>
        <GridItem p="1">{ValueEntry}</GridItem>

        <GridItem p="1">
          {!audience.hasPredicate(target, field, selectedOp, selectedVal) && (
            <Button size="xs" colorScheme="blue" onClick={() => addPredicate()}>
              +
            </Button>
          )}
        </GridItem>
      </Grid>
    );
  }
);
