import * as Types from '../../generated/graphql';

import { gql } from '@apollo/client';
export type DegreeDesignationFieldsFragment = { __typename?: 'degreeDesignation', value: string, description: string };

export const DegreeDesignationFieldsFragmentDoc = gql`
    fragment DegreeDesignationFields on degreeDesignation {
  value
  description
}
    `;