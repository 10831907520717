import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { FlowsheetFieldsMinimalFragmentDoc } from '../../fragments/flowsheet/FlowsheetFieldsMinimal.fragment.generated';
import { FlowsheetParameterFieldsMinimalFragmentDoc } from '../../fragments/flowsheet/FlowsheetParameterFieldsMinimal.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type ListParametersWithFlowsheetsQueryVariables = Types.Exact<{
  limit?: Types.Maybe<Types.Scalars['Int']>;
  offset?: Types.Maybe<Types.Scalars['Int']>;
  orderBy?: Types.Maybe<Array<Types.FlowsheetParameterFlowsheet_Order_By> | Types.FlowsheetParameterFlowsheet_Order_By>;
  where?: Types.Maybe<Types.FlowsheetParameterFlowsheet_Bool_Exp>;
}>;


export type ListParametersWithFlowsheetsQuery = { __typename?: 'query_root', flowsheetParameterFlowsheet: Array<{ __typename?: 'flowsheetParameterFlowsheet', id: any, flowsheet: { __typename?: 'flowsheet', id: any, title: string }, parameter: { __typename?: 'flowsheetParameter', apiKey: string, id: any, isSystemParameter: boolean, title: string, type: string, unit: string } }> };


export const ListParametersWithFlowsheetsDocument = gql`
    query ListParametersWithFlowsheets($limit: Int, $offset: Int = 0, $orderBy: [flowsheetParameterFlowsheet_order_by!], $where: flowsheetParameterFlowsheet_bool_exp) {
  flowsheetParameterFlowsheet(
    limit: $limit
    offset: $offset
    order_by: $orderBy
    where: $where
  ) {
    id
    flowsheet {
      ...FlowsheetFieldsMinimal
    }
    parameter {
      ...FlowsheetParameterFieldsMinimal
    }
  }
}
    ${FlowsheetFieldsMinimalFragmentDoc}
${FlowsheetParameterFieldsMinimalFragmentDoc}`;

/**
 * __useListParametersWithFlowsheetsQuery__
 *
 * To run a query within a React component, call `useListParametersWithFlowsheetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListParametersWithFlowsheetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListParametersWithFlowsheetsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      orderBy: // value for 'orderBy'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useListParametersWithFlowsheetsQuery(baseOptions?: Apollo.QueryHookOptions<ListParametersWithFlowsheetsQuery, ListParametersWithFlowsheetsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListParametersWithFlowsheetsQuery, ListParametersWithFlowsheetsQueryVariables>(ListParametersWithFlowsheetsDocument, options);
      }
export function useListParametersWithFlowsheetsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListParametersWithFlowsheetsQuery, ListParametersWithFlowsheetsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListParametersWithFlowsheetsQuery, ListParametersWithFlowsheetsQueryVariables>(ListParametersWithFlowsheetsDocument, options);
        }
export type ListParametersWithFlowsheetsQueryHookResult = ReturnType<typeof useListParametersWithFlowsheetsQuery>;
export type ListParametersWithFlowsheetsLazyQueryHookResult = ReturnType<typeof useListParametersWithFlowsheetsLazyQuery>;
export type ListParametersWithFlowsheetsQueryResult = Apollo.QueryResult<ListParametersWithFlowsheetsQuery, ListParametersWithFlowsheetsQueryVariables>;