import * as Types from '../../generated/graphql';

import { gql } from '@apollo/client';
export type UserFieldsFragment = { __typename?: 'user', id: any, firstName: string, lastName: string, phoneNumber?: string | null | undefined, email: string, twoFactorAuthEnabled?: boolean | null | undefined, userWorkspaces: Array<{ __typename?: 'userWorkspace', id: any, role: string, isManager?: boolean | null | undefined, lockedToLocation?: { __typename?: 'location', id: any, name: string } | null | undefined }>, providers: Array<{ __typename?: 'provider', id: any, firstName: string, lastName: string }> };

export const UserFieldsFragmentDoc = gql`
    fragment UserFields on user {
  id
  firstName
  lastName
  phoneNumber
  email
  twoFactorAuthEnabled
  userWorkspaces {
    id
    role
    isManager
    lockedToLocation {
      id
      name
    }
  }
  providers {
    id
    firstName
    lastName
  }
}
    `;