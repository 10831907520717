import { Stack, Spinner } from '@chakra-ui/react';
import { useGetLeadFormByPkQuery } from '@webapp/graphql';
import { LeadFormModel, useStores } from '@webapp/state-models';
import { useFlagsmith } from 'flagsmith-react';
import { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Favicon from 'react-favicon';
import ConfigurableForm from '../configurable-form/configurable-form';
import SurveyJSFormRenderer from '../create-survey-jslead-form/survey-jsform-renderer';
/* eslint-disable-next-line */
export interface LeadFormPageProps {}

export const LeadFormPage = (props: LeadFormPageProps) => {
  const { user, workspace } = useStores();
  const { formId } = useParams();
  const [searchParams] = useSearchParams();
  const [ready, setReady] = useState(false);
  const { loading, data } = useGetLeadFormByPkQuery({
    variables: { id: formId },
  });
  const { identify, hasFeature } = useFlagsmith();
  const leadFormLink = searchParams.get('l');

  // get version query parameter
  const version = searchParams.get('v');
  const firstName = searchParams.get('firstName');
  const lastName = searchParams.get('lastName');
  const email = searchParams.get('email');
  const phoneNumber = searchParams.get('phone');

  useEffect(() => {
    if (!user || !workspace) {
      identify(user?.id || 'anonymous', {
        workspaceId: data?.leadForm_by_pk?.workspaceId,
        role: user?.userWorkspaces[0]?.role || 'none',
        flags: user?.userWorkspaces[0]?.flags || [],
      });
    }
    setTimeout(() => {
      setReady(true);
    }, 1000);
  }, [data?.leadForm_by_pk?.workspaceId]);

  if (loading || !ready)
    return (
      <Stack
        w={'full'}
        h="100vh"
        justifyContent={'center'}
        alignItems={'center'}
      >
        <Spinner size="lg" colorScheme={'teal'} />
      </Stack>
    );

  if (!data?.leadForm_by_pk) return <div>Form not found :(</div>;

  if (data?.leadForm_by_pk.surveyJSJson) {
    return (
      <>
        <Favicon
          url={
            data?.leadForm_by_pk.workspace?.organization
              ?.organizationConfiguration?.lightLogoSmall ?? '/favicon.ico'
          }
        />
        <Helmet
          title={data?.leadForm_by_pk.workspace?.name}
          script={[
            data?.leadForm_by_pk.surveyJSJson?.facebookPixelId && {
              type: 'text/javascript',
              innerHTML: `
                !function(f,b,e,v,n,t,s)
                {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                n.queue=[];t=b.createElement(e);t.async=!0;
                t.src=v;s=b.getElementsByTagName(e)[0];
                s.parentNode.insertBefore(t,s)}(window, document,'script',
                'https://connect.facebook.net/en_US/fbevents.js');
                fbq('init', '${data.leadForm_by_pk.surveyJSJson.facebookPixelId}');
                fbq('track', 'PageView');
              `,
            },
          ].filter(Boolean)}
        ></Helmet>
        <SurveyJSFormRenderer
          surveyJSJson={data?.leadForm_by_pk.surveyJSJson}
          leadFormId={data?.leadForm_by_pk.id}
          leadFormLinkId={leadFormLink}
          leadForm={data?.leadForm_by_pk}
          prefillData={{
            firstName,
            lastName,
            email,
            phoneNumber,
          }}
        />
      </>
    );
  }

  return (
    <ConfigurableForm
      leadForm={LeadFormModel.create(data?.leadForm_by_pk)}
      leadFormLinkId={leadFormLink}
      prefillData={{
        firstName,
        lastName,
        email,
        phoneNumber,
      }}
    />
  );
};
