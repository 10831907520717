export enum CouponButtonType {
  APPLY = 'APPLY',
  OVERRIDE = 'OVERRIDE',
  REMOVE = 'REMOVE',
}

export enum CouponMessageType {
  VALID = 'VALID',
  INVALID = 'INVALID',
  EXPIRED = 'EXPIRED',
  PLEASE_SELECT = 'PLEASE_SELECT',
  REMOVED = 'REMOVED',
  SELECT_SERVICES = 'SELECT_SERVICES',
  SELECT_LOCATION = 'SELECT_LOCATION',
}

export const CouponMessages = {
  [CouponMessageType.VALID]: 'Discount applied',
  [CouponMessageType.INVALID]: 'Invalid coupon',
  [CouponMessageType.EXPIRED]: 'Coupon expired',
  [CouponMessageType.PLEASE_SELECT]: 'Please select a coupon',
  [CouponMessageType.REMOVED]: 'Discount removed',
  [CouponMessageType.SELECT_SERVICES]: 'Please first select services',
  [CouponMessageType.SELECT_LOCATION]: 'Please first select a location',
};

export const invalidCouponMessageTypes = [
  CouponMessageType.INVALID,
  CouponMessageType.EXPIRED,
  CouponMessageType.PLEASE_SELECT,
  CouponMessageType.SELECT_SERVICES,
  CouponMessageType.SELECT_LOCATION,
];

export const validCouponMessageTypes = [
  CouponMessageType.VALID,
  CouponMessageType.REMOVED,
];
