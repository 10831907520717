import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetUserAccountPageQueryVariables = Types.Exact<{
  userId: Types.Scalars['uuid'];
  workspaceId: Types.Scalars['uuid'];
}>;


export type GetUserAccountPageQuery = { __typename?: 'query_root', user_by_pk?: { __typename?: 'user', id: any, firstName: string, lastName: string, email: string, phoneNumber?: string | null | undefined, profilePicture?: string | null | undefined } | null | undefined, userWorkspace: Array<{ __typename?: 'userWorkspace', id: any, isManager?: boolean | null | undefined, lockTimezoneToDefaultLocation: boolean, sendProviderAppointmentReminder?: boolean | null | undefined, defaultLocation?: { __typename?: 'location', id: any, name: string } | null | undefined }>, provider: Array<{ __typename?: 'provider', id: any, active?: boolean | null | undefined }> };


export const GetUserAccountPageDocument = gql`
    query GetUserAccountPage($userId: uuid!, $workspaceId: uuid!) {
  user_by_pk(id: $userId) {
    id
    firstName
    lastName
    email
    phoneNumber
    profilePicture
  }
  userWorkspace(where: {userId: {_eq: $userId}, workspaceId: {_eq: $workspaceId}}) {
    defaultLocation {
      id
      name
    }
    id
    isManager
    lockTimezoneToDefaultLocation
    sendProviderAppointmentReminder
  }
  provider(where: {userId: {_eq: $userId}}) {
    id
    active
  }
}
    `;

/**
 * __useGetUserAccountPageQuery__
 *
 * To run a query within a React component, call `useGetUserAccountPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserAccountPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserAccountPageQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      workspaceId: // value for 'workspaceId'
 *   },
 * });
 */
export function useGetUserAccountPageQuery(baseOptions: Apollo.QueryHookOptions<GetUserAccountPageQuery, GetUserAccountPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserAccountPageQuery, GetUserAccountPageQueryVariables>(GetUserAccountPageDocument, options);
      }
export function useGetUserAccountPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserAccountPageQuery, GetUserAccountPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserAccountPageQuery, GetUserAccountPageQueryVariables>(GetUserAccountPageDocument, options);
        }
export type GetUserAccountPageQueryHookResult = ReturnType<typeof useGetUserAccountPageQuery>;
export type GetUserAccountPageLazyQueryHookResult = ReturnType<typeof useGetUserAccountPageLazyQuery>;
export type GetUserAccountPageQueryResult = Apollo.QueryResult<GetUserAccountPageQuery, GetUserAccountPageQueryVariables>;