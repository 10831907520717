import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type InsertExternalPaymentMutationVariables = Types.Exact<{
  payment: Types.Payment_Insert_Input;
}>;


export type InsertExternalPaymentMutation = { __typename?: 'mutation_root', insert_payment?: { __typename?: 'payment_mutation_response', returning: Array<{ __typename?: 'payment', id: any }> } | null | undefined };


export const InsertExternalPaymentDocument = gql`
    mutation insertExternalPayment($payment: payment_insert_input!) {
  insert_payment(objects: [$payment]) {
    returning {
      id
    }
  }
}
    `;
export type InsertExternalPaymentMutationFn = Apollo.MutationFunction<InsertExternalPaymentMutation, InsertExternalPaymentMutationVariables>;

/**
 * __useInsertExternalPaymentMutation__
 *
 * To run a mutation, you first call `useInsertExternalPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertExternalPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertExternalPaymentMutation, { data, loading, error }] = useInsertExternalPaymentMutation({
 *   variables: {
 *      payment: // value for 'payment'
 *   },
 * });
 */
export function useInsertExternalPaymentMutation(baseOptions?: Apollo.MutationHookOptions<InsertExternalPaymentMutation, InsertExternalPaymentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertExternalPaymentMutation, InsertExternalPaymentMutationVariables>(InsertExternalPaymentDocument, options);
      }
export type InsertExternalPaymentMutationHookResult = ReturnType<typeof useInsertExternalPaymentMutation>;
export type InsertExternalPaymentMutationResult = Apollo.MutationResult<InsertExternalPaymentMutation>;
export type InsertExternalPaymentMutationOptions = Apollo.BaseMutationOptions<InsertExternalPaymentMutation, InsertExternalPaymentMutationVariables>;