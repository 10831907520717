/* eslint-disable no-nested-ternary */
import './appointment-details.module.scss';
import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  UseDisclosureProps,
  Spinner,
  Stack,
  HStack,
  Avatar,
  FormControl,
  FormLabel,
  IconButton,
  useDisclosure,
  Tag,
  Box,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import {
  AppointmentStatusBadge,
  ConflictBadge,
  DeleteDialog,
  PatientStatusBadge,
  PhoneNumberTextWrapper,
} from '@webapp/ui';
import {
  PatientTags,
  PromptModal,
  RecurringUpdateOptionsModal,
} from '@webapp/webapp/ui-composites';
import {
  useArchiveAppointmentServiceMutation,
  useGetAppointmentQuery,
  useSendAppointmentRescheduleConfirmationMutation,
  useUpdateAppointmentMutation,
} from '@webapp/graphql';
import { SelectOption } from '@webapp/types';
import { useEffect, useState } from 'react';
import {
  AppointmentModel,
  AppointmentService,
  useStores,
} from '@webapp/state-models';
import { configuredDayjs as dayjs } from '@webapp/util-time';
import { observer } from 'mobx-react-lite';
import { FaDollarSign, FaLink } from 'react-icons/fa';
import toast from 'react-hot-toast';
import { useFlagsmith } from 'flagsmith-react';
import { RRule } from 'rrule';
import CreatePatientAppointment from '../create-appointment-drawer/create-patient-appointment';
import CreateOtherAppointment from '../create-appointment-drawer/create-other-appointment';
import PatientDocuments from '../patient-documents/patient-documents';
import NoShowFeeModal from '../no-show-fee-modal';
import AppointmentStatusIcons from '../AppointmentStatusIcons/AppointmentStatusIcons';
import PatientProductHistoryPopover from '../PatientProductHistory/PatientProductHistoryPopover';

interface AppointmentDetailsProps {
  onDelete?: () => void;
  onUpdate?: () => void;
  appointmentId: string;
}

export const AppointmentDetails = observer(
  ({
    isOpen,
    onOpen,
    onClose,
    onDelete = () => undefined,
    onUpdate = () => undefined,
    appointmentId,
  }: AppointmentDetailsProps & UseDisclosureProps) => {
    const { hasFeature } = useFlagsmith();

    const {
      isOpen: isAppointmentUpdateConfirmationOpen,
      onClose: onAppointmentUpdateConfirmationClose,
      onOpen: onAppointmentUpdateConfirmationOpen,
    } = useDisclosure();

    const {
      isOpen: isAppointmentDurationOverrideOpen,
      onClose: onAppointmentDurationOverrideClose,
      onOpen: onAppointmentDurationOverrideOpen,
    } = useDisclosure();

    const {
      isOpen: isRecurringOptionsOpen,
      onClose: onRecurringOptionsClose,
      onOpen: onRecurringOptionsOpen,
    } = useDisclosure();

    const {
      calendarTimeZone,
      calendarCurrentEventDate,
      draftAppointment,
      setDraftAppointment,
      removeCachedAppointment,
      removeCalendarEvent,
      workspace,
    } = useStores();

    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [hasFormError, setHasFormError] = useState<boolean>(false);
    const [showNoShowFeeModal, setShowNoShowFeeModal] =
      useState<boolean>(false);
    const [recurringUpdateOption, setRecurringUpdateOption] =
      useState<SelectOption>({
        label: 'This appointment only',
        value: 'single',
      });
    const [profileNote, setProfileNote] = useState<string>('');
    const [editRecurring, setEditRecurring] = useState<boolean>(
      draftAppointment?.isRecurring || false
    );

    dayjs.tz.setDefault(calendarTimeZone);
    const [
      sendAppointmentRescheduleConfirmation,
      { loading: confirmationLoading },
    ] = useSendAppointmentRescheduleConfirmationMutation({
      onCompleted: () => {
        toast.success('Confirmation SMS sent successfully');
        onAppointmentUpdateConfirmationClose();
      },
    });

    const { data, loading, refetch } = useGetAppointmentQuery({
      variables: {
        id: appointmentId,
      },
      skip: !appointmentId,
    });

    const [updateAppointmentMutation] = useUpdateAppointmentMutation();
    const [archiveAppointmentServiceMutation] =
      useArchiveAppointmentServiceMutation();

    const onUpdateStatus = (status: string): void => {
      const statusIsNoShow = status === 'NO_SHOW';
      const eligibleNoShowStatus =
        draftAppointment?.noShowFeeStatus === 'APPROVED';

      const newNoShowConditionsMet = statusIsNoShow && eligibleNoShowStatus;
      const oldNoShowConditionsMet =
        statusIsNoShow && draftAppointment?.canCollectNoShowFee;

      if (newNoShowConditionsMet || oldNoShowConditionsMet) {
        setShowNoShowFeeModal(true);
      }
    };

    const updateRecurringAppointment = async () => {
      // Update only one appointment
      if (recurringUpdateOption.value === 'single') {
        // Add date to the recurrence exclusion
        const exDate = dayjs(draftAppointment?.startTime)
          .utc()
          .format('YYYY-MM-DD');
        const exceptionDates = draftAppointment?.recurringExceptionDates ?? [];
        updateAppointmentMutation({
          variables: {
            id: draftAppointment?.id,
            set: {
              recurringExceptionDates: JSON.stringify([
                ...exceptionDates,
                exDate,
              ]),
            },
          },
        });

        // Create exception appointment
        const timeOfStartTime = dayjs(draftAppointment?.startTime).format(
          'HH:mm:ssZ'
        );
        draftAppointment?.setStartTime(
          `${calendarCurrentEventDate}T${timeOfStartTime}`
        );
        draftAppointment?.unsetRecurence();
        await draftAppointment?.create();
        // Update recurrence moving forward
      } else if (recurringUpdateOption.value === 'forward') {
        // Update old recurring appointment chain with end date
        const initialOptions = RRule.parseString(
          draftAppointment?.originalRecurringRule || ''
        );
        initialOptions.until = dayjs(calendarCurrentEventDate).toDate();
        const updatedInitialRule = new RRule(initialOptions);
        updateAppointmentMutation({
          variables: {
            id: draftAppointment?.id,
            set: {
              recurringEndDate: calendarCurrentEventDate,
              recurringRule: updatedInitialRule.toString(),
            },
          },
        });

        // Create a new recurring appointment chain with changes
        draftAppointment?.setRecurringStartDate(calendarCurrentEventDate);
        await draftAppointment?.create();
      } else if (recurringUpdateOption.value === 'all') {
        await draftAppointment?.update();
      }
    };

    const updateAppointment = async () => {
      onRecurringOptionsClose();
      setHasFormError(false);
      const hasNullProvider = draftAppointment?.services.some(
        (service: AppointmentService) =>
          service.providerId === null ||
          service.providerId === '' ||
          !/^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i.test(
            service.providerId
          )
      );

      if (hasNullProvider) {
        setHasFormError(true);
        toast.error('Please make sure all services have a provider assigned.');
        return;
      }

      if (
        (!draftAppointment?.services ||
          draftAppointment?.services.length === 0) &&
        draftAppointment?.type !== 'other'
      ) {
        toast.error('Please make sure appointment has at least one service.');
        return;
      }

      if (
        hasFeature('appointment:duration-override') &&
        draftAppointment?.currentDuration !== draftAppointment?.totalDuration
      ) {
        onAppointmentDurationOverrideOpen(); // TODO Add recurring appointment support in upstack
      } else if (draftAppointment?.isRecurring) {
        await updateRecurringAppointment();
      } else {
        await draftAppointment?.update();
      }

      setIsEditing(false);
      onUpdate();
      if (draftAppointment?.timeHasChanged) {
        onAppointmentUpdateConfirmationOpen();
      }
    };

    useEffect(() => {
      setIsEditing(false);
      if (appointmentId) {
        refetch({
          id: appointmentId,
        });
      }
    }, [appointmentId]);

    const appointment = data
      ? AppointmentModel.create(data?.appointment_by_pk)
      : null;
    useEffect(() => {
      if (data?.appointment_by_pk) {
        const updatedDraftAppointment = {
          ...data.appointment_by_pk,
          originalRecurringRule: data.appointment_by_pk.recurringRule || '',
        };
        setDraftAppointment(updatedDraftAppointment);
        const patientWorkspace =
          data?.appointment_by_pk?.patient?.patientWorkspaces.find(
            (patient) => patient.workspaceId === workspace?.id
          );
        setProfileNote(patientWorkspace?.profileNote || '');
      }
    }, [data?.appointment_by_pk, appointmentId]);

    function onConfirmDelete() {
      updateAppointmentMutation({
        variables: {
          id: appointmentId,
          set: {
            isArchived: true,
            status: 'DELETED',
          },
        },
      });

      archiveAppointmentServiceMutation({
        variables: {
          appointmentId,
        },
      });

      removeCachedAppointment(appointmentId);
      removeCalendarEvent(appointmentId);
      if (onClose) onClose();
      if (onUpdate) onUpdate();

      toast.success('Appointment archived successfully');
    }

    return (
      <>
        <Drawer
          size={'sm'}
          isOpen={!!isOpen}
          placement="right"
          onClose={onClose as () => void}
          variant={'prospyr'}
        >
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader>
              <Stack>
                <HStack>
                  <span>
                    {appointment?.type === 'patient_appointment'
                      ? 'Appointment details'
                      : 'Internal Meeting'}
                  </span>
                  {appointment?.type === 'patient_appointment' && (
                    <>
                      <Link
                        to={`/patients/${appointment?.patient?.id}/appointments/${appointment?.id}`}
                      >
                        <IconButton
                          variant={'ghost'}
                          aria-label="link to appointment"
                          icon={<FaLink />}
                          r
                        ></IconButton>
                      </Link>
                      <Link
                        to={`/invoices/create?appointmentId=${appointment.id}`}
                      >
                        <IconButton
                          variant={'ghost'}
                          aria-label="link to invoice"
                          icon={<FaDollarSign />}
                          r
                        ></IconButton>
                      </Link>
                    </>
                  )}
                </HStack>
                {appointment?.type === 'patient_appointment' && (
                  <PatientStatusBadge
                    status={appointment?.patient?.attributes?.status}
                  />
                )}
              </Stack>
            </DrawerHeader>

            <DrawerBody>
              {loading && <Spinner />}
              {isEditing ? (
                appointment?.type === 'patient_appointment' ? (
                  <CreatePatientAppointment
                    data-testid={'create-appointment-details'}
                    hasFormError={hasFormError}
                  />
                ) : (
                  draftAppointment && <CreateOtherAppointment />
                )
              ) : (
                <Stack spacing={6}>
                  {appointment?.type === 'patient_appointment' &&
                    appointment?.conflicts?.map((conflict) => (
                      <ConflictBadge>{conflict.label}</ConflictBadge>
                    ))}
                  <HStack>
                    <Avatar
                      src={
                        appointment?.patient?.attributes?.profilePicture
                          ?.thumbnailUrl ||
                        appointment?.patient?.attributes?.profilePicture?.url
                      }
                    />
                    <Stack>
                      <Text>Status</Text>
                      <AppointmentStatusBadge
                        appointment={draftAppointment}
                        onUpdateStatus={onUpdateStatus}
                      />
                    </Stack>
                  </HStack>

                  {appointment?.type === 'patient_appointment' && (
                    <FormControl id="patient">
                      <HStack alignItems="flex-end" mb={4}>
                        <Stack mr={2}>
                          <FormLabel mb={0}>Patient</FormLabel>
                          <Link to={`/patients/${appointment?.patient?.id}`}>
                            <Text
                              mb={1}
                              cursor={'pointer'}
                              color="teal.600"
                              textDecoration={'underline'}
                            >
                              {appointment?.patient?.name}
                            </Text>
                          </Link>
                        </Stack>
                        <PatientProductHistoryPopover
                          patientId={appointment?.patient?.id}
                        />
                      </HStack>
                      {appointment?.patient?.attributes?.phoneNumber && (
                        <PhoneNumberTextWrapper
                          patientId={appointment?.patient?.id}
                          phoneNumber={
                            appointment?.patient?.attributes?.phoneNumber
                          }
                        />
                      )}

                      <Box py={2} />
                      {profileNote && (
                        <Text
                          color="blue.500"
                          fontStyle="italic"
                          fontWeight="500"
                          paddingInlineStart={0}
                          marginTop={0}
                          marginBottom={6}
                          width="100%"
                          style={{
                            marginTop: 0,
                          }}
                        >
                          {profileNote}
                        </Text>
                      )}
                      {data?.appointment_by_pk?.patient && (
                        <PatientTags
                          patient={data?.appointment_by_pk?.patient}
                        />
                      )}
                    </FormControl>
                  )}

                  {appointment?.type === 'patient_appointment' &&
                    data?.appointment_by_pk?.room && (
                      <FormControl id="room">
                        <FormLabel mb={0}>Room</FormLabel>
                        <Text>{data?.appointment_by_pk?.room?.name}</Text>
                      </FormControl>
                    )}

                  {appointment?.type === 'patient_appointment' ? (
                    <FormControl id="provider">
                      <FormLabel mb={0}>Provider</FormLabel>
                      <Link
                        to={`/settings/providers/edit/${appointment?.provider?.id}`}
                      >
                        <Text cursor={'pointer'}>
                          {appointment?.provider?.firstName}{' '}
                          {appointment?.provider?.lastName}
                        </Text>
                      </Link>
                    </FormControl>
                  ) : (
                    <FormControl id="provider">
                      <FormLabel mb={0}>Providers</FormLabel>
                      {appointment?.internalAppointmentProviders.map(
                        (provider) => (
                          <Link to={`/settings/providers/edit/${provider?.id}`}>
                            <Text cursor={'pointer'}>
                              {provider?.firstName} {provider?.lastName}
                            </Text>
                          </Link>
                        )
                      )}
                    </FormControl>
                  )}

                  <FormControl id="date">
                    <FormLabel mb={0}>
                      {dayjs(appointment?.startTime)
                        .tz(calendarTimeZone)
                        .format('dddd MMMM D')}
                    </FormLabel>
                    <Text id="appointment-details-start-end-times">
                      {dayjs(appointment?.startTime)
                        .tz(calendarTimeZone)
                        .format('h:mm A')}{' '}
                      -{' '}
                      {dayjs(appointment?.endTime)
                        .tz(calendarTimeZone)
                        .format('h:mm A z')}
                    </Text>
                    {appointment?.isRecurring && (
                      <Text>
                        {new RRule(
                          RRule.parseString(appointment?.recurringRule || '')
                        ).toText()}
                      </Text>
                    )}
                  </FormControl>

                  {appointment?.type === 'patient_appointment' && (
                    <FormControl id="service">
                      <FormLabel mb={0}>Service</FormLabel>
                      {data?.appointment_by_pk?.services.map((service) => (
                        <>
                          <HStack mb={2}>
                            <Tag>{service?.service?.name}</Tag>
                            <Text>
                              {service?.provider ? (
                                <>
                                  by
                                  <b>
                                    {` ${service?.provider.firstName} ${service?.provider.lastName}`}
                                  </b>
                                </>
                              ) : (
                                ''
                              )}
                              , {service.serviceDurationMinutes} min
                            </Text>
                          </HStack>
                          {service.device && (
                            <Text>Requires {service?.device?.name}</Text>
                          )}
                        </>
                      ))}
                    </FormControl>
                  )}

                  <FormControl id="location">
                    <FormLabel mb={0}>Location</FormLabel>
                    <Text>{appointment?.location?.name}</Text>
                  </FormControl>

                  <FormControl id="notes">
                    <FormLabel mb={0}>Appointment notes</FormLabel>
                    <Text>{appointment?.note || 'Empty'}</Text>
                  </FormControl>
                  {appointment?.type === 'patient_appointment' &&
                    data?.appointment_by_pk &&
                    appointment?.patient && (
                      <PatientDocuments
                        appointment={{
                          ...data?.appointment_by_pk,
                          patientId: data?.appointment_by_pk?.patient?.id,
                        }}
                        mini={true}
                      />
                    )}
                </Stack>
              )}
            </DrawerBody>

            <DrawerFooter borderTopWidth={1} borderColor="gray.200">
              {isEditing ? (
                <HStack justify={'space-between'} w="full">
                  <Button variant="outline" onClick={() => setIsEditing(false)}>
                    Cancel
                  </Button>
                  <Button
                    colorScheme="teal"
                    onClick={() => {
                      draftAppointment?.isRecurring
                        ? onRecurringOptionsOpen()
                        : updateAppointment();
                    }}
                    isLoading={draftAppointment?.loading}
                  >
                    Done
                  </Button>
                </HStack>
              ) : (
                <HStack spacing={4} w={'full'} justifyContent="flex-end">
                  <DeleteDialog
                    objectToDelete="appointment"
                    handleConfirm={onConfirmDelete}
                  >
                    <Button colorScheme={'red'}>Delete</Button>
                  </DeleteDialog>
                  <Button colorScheme="teal" onClick={() => setIsEditing(true)}>
                    Edit
                  </Button>
                </HStack>
              )}
            </DrawerFooter>
          </DrawerContent>
        </Drawer>
        <NoShowFeeModal
          isOpen={showNoShowFeeModal}
          onClose={() => setShowNoShowFeeModal(false)}
          appointmentId={appointmentId}
        />
        <PromptModal
          isLoading={confirmationLoading}
          headerText="Send confirmation SMS?"
          bodyText="Would you like to send the patient a confirmation SMS about their new appointment time?"
          colorScheme="teal"
          denyText={'No, do not send'}
          confirmText={'Yes, send confirmation'}
          isOpen={isAppointmentUpdateConfirmationOpen}
          onClose={onAppointmentUpdateConfirmationClose}
          onConfirm={() => {
            sendAppointmentRescheduleConfirmation({
              variables: {
                appointmentId,
              },
            });
          }}
          withCloseButton={false}
          withCancelButton={true}
        />
        <PromptModal
          isLoading={draftAppointment?.loading}
          headerText="Change appointment duration?"
          bodyText={`By making changes to appointment services, the appointment duration will also change.  Would you like to keep the original appointment duration of ${draftAppointment?.currentDuration} minutes or update to the new duration of ${draftAppointment?.totalDuration} minutes?`}
          colorScheme="teal"
          denyText={`Keep at ${draftAppointment?.currentDuration} minutes`}
          confirmText={`Update to ${draftAppointment?.totalDuration} minutes`}
          isOpen={isAppointmentDurationOverrideOpen}
          onClose={onAppointmentDurationOverrideClose}
          onConfirm={() => {
            draftAppointment?.update();
          }}
          onCancel={() => {
            draftAppointment?.update(false);
          }}
          shouldCloseOnConfirm={true}
          withCloseButton={false}
          withCancelButton={true}
        />
        <RecurringUpdateOptionsModal
          isOpen={isRecurringOptionsOpen}
          onChange={(newValue) => setRecurringUpdateOption(newValue)}
          onClose={onRecurringOptionsClose}
          onConfirm={updateAppointment}
        />
      </>
    );
  }
);

export default AppointmentDetails;
