import * as Types from '../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetMetabaseEmbedUrlQueryVariables = Types.Exact<{
  workspaceId: Types.Scalars['uuid'];
  reportId: Types.Scalars['String'];
  params?: Types.Maybe<Types.Scalars['String']>;
}>;


export type GetMetabaseEmbedUrlQuery = { __typename?: 'query_root', getMetabaseEmbedUrl?: { __typename?: 'MetabaseEmbedUrlOutput', iframeUrl: string } | null | undefined };


export const GetMetabaseEmbedUrlDocument = gql`
    query GetMetabaseEmbedUrl($workspaceId: uuid!, $reportId: String!, $params: String) {
  getMetabaseEmbedUrl(
    workspaceId: $workspaceId
    reportId: $reportId
    params: $params
  ) {
    iframeUrl
  }
}
    `;

/**
 * __useGetMetabaseEmbedUrlQuery__
 *
 * To run a query within a React component, call `useGetMetabaseEmbedUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMetabaseEmbedUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMetabaseEmbedUrlQuery({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *      reportId: // value for 'reportId'
 *      params: // value for 'params'
 *   },
 * });
 */
export function useGetMetabaseEmbedUrlQuery(baseOptions: Apollo.QueryHookOptions<GetMetabaseEmbedUrlQuery, GetMetabaseEmbedUrlQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMetabaseEmbedUrlQuery, GetMetabaseEmbedUrlQueryVariables>(GetMetabaseEmbedUrlDocument, options);
      }
export function useGetMetabaseEmbedUrlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMetabaseEmbedUrlQuery, GetMetabaseEmbedUrlQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMetabaseEmbedUrlQuery, GetMetabaseEmbedUrlQueryVariables>(GetMetabaseEmbedUrlDocument, options);
        }
export type GetMetabaseEmbedUrlQueryHookResult = ReturnType<typeof useGetMetabaseEmbedUrlQuery>;
export type GetMetabaseEmbedUrlLazyQueryHookResult = ReturnType<typeof useGetMetabaseEmbedUrlLazyQuery>;
export type GetMetabaseEmbedUrlQueryResult = Apollo.QueryResult<GetMetabaseEmbedUrlQuery, GetMetabaseEmbedUrlQueryVariables>;