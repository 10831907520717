import {
  Button,
  chakra,
  FormControl,
  FormLabel,
  HTMLChakraProps,
  Input,
  Stack,
} from '@chakra-ui/react';
import { useState } from 'react';
import { firebaseAuth } from '@webapp/auth';

import toast from 'react-hot-toast';
import { useStores } from '@webapp/state-models';
import { useInsertUserMutation } from '@webapp/graphql';
import { useForm, SubmitHandler } from 'react-hook-form';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { PasswordField } from '../password-field/password-field';

interface IFormInput {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
}

export const SignupForm = (props: HTMLChakraProps<'form'>) => {
  const { register, handleSubmit } = useForm<IFormInput>();

  const [insertUser] = useInsertUserMutation();
  const { initializeUser } = useStores();
  const navigate = useNavigate();

  const onSubmit: SubmitHandler<IFormInput> = async function handleSignUp({
    firstName,
    lastName,
    email,
    password,
  }) {
    const userCred = await createUserWithEmailAndPassword(
      firebaseAuth,
      email,
      password
    );
    const { user } = userCred;
    const token = await user?.getIdToken();
    localStorage.setItem(
      `prospyr_token_${import.meta.env.VITE_ENV}`,
      token as string
    );

    await insertUser({
      variables: {
        user: {
          firstName,
          lastName,
          email,
          firebaseUid: user?.uid,
        },
      },
    });
    await initializeUser(userCred.user);
    navigate('/onboard');
  };

  return (
    <chakra.form onSubmit={handleSubmit(onSubmit)} {...props}>
      <Stack spacing="6">
        <FormControl id="firstName">
          <FormLabel>First Name</FormLabel>
          <Input
            type="firstName"
            autoComplete="firstName"
            required
            {...register('firstName')}
          />
        </FormControl>
        <FormControl id="email">
          <FormLabel>Last Name</FormLabel>
          <Input
            type="lastName"
            autoComplete="lastName"
            required
            {...register('lastName')}
          />
        </FormControl>
        <FormControl id="email">
          <FormLabel>Email address</FormLabel>
          <Input
            type="email"
            autoComplete="email"
            required
            {...register('email')}
          />
        </FormControl>
        <PasswordField showForgotPassword={false} {...register('password')} />
        <Button type="submit" colorScheme="blue" size="lg" fontSize="md">
          Sign up
        </Button>
      </Stack>
    </chakra.form>
  );
};
export default SignupForm;
