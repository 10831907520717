import React, { useEffect, useState, useCallback } from 'react';
import { FaCodeBranch } from 'react-icons/fa';
import {
  NoteFieldsFragment,
  ProviderFieldsFragment,
  useGetAppointmentMinimalQuery,
  useGetMinimalPatientQuery,
  useUpdateNoteByPkMutation,
} from '@webapp/graphql';
import {
  Flex,
  HStack,
  Image,
  Input,
  Box,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Skeleton,
  Spinner,
  Stack,
  Tag,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react';
import { toast } from 'react-hot-toast';
import { GenericObjectSelect } from '@webapp/ui';
import { useLoadProviders } from '@webapp/webapp/hooks';

import { ObjectOption } from '@webapp/types';
import { NotePopover } from '../NotePopover/NotePopover';
import ClinicalNoteTypeahead from './TypeAhead';

export interface NoteHeaderProps {
  patientId?: string;
  appointmentId?: string;
  note?: NoteFieldsFragment;
  parentNote?: NoteFieldsFragment;
  onAssignProvider?: (providerId: string) => void;
  mini?: boolean;
  saving?: boolean;
  onUpdateNoteTitle?: (title: string) => void;
}

export function NoteHeader({
  patientId,
  appointmentId,
  note,
  parentNote,
  onAssignProvider,
  mini = false,
  saving = false,
}: NoteHeaderProps) {
  const isMobile = useBreakpointValue({ base: true, md: false });

  const [updateNoteByPk] = useUpdateNoteByPkMutation();

  const [heading, setHeading] = useState(
    appointmentId ? 'Appointment Note' : 'Patient Note'
  );

  function handleProviderChange(
    provider: ObjectOption<ProviderFieldsFragment>
  ) {
    onAssignProvider?.(provider.value as string);
  }

  function handleNoteTitleChange(newTitle: string) {
    updateNoteByPk({
      variables: {
        id: note?.id,
        set: {
          title: newTitle,
        },
      },
    }).then(() => {
      toast.success('Note title updated');
    });
  }

  const { data: appointmentData, loading: appointmentLoading } =
    useGetAppointmentMinimalQuery({
      variables: {
        id: appointmentId,
      },
      skip: !appointmentId,
    });

  const { data: patientData, loading: patientLoading } =
    useGetMinimalPatientQuery({
      variables: {
        id: patientId,
      },
    });

  useEffect(() => {
    const action = note ? 'Edit' : 'Add';
    const object = appointmentData?.appointment_by_pk
      ? 'Appointment'
      : 'Patient';
    const patientName = patientData
      ? `${patientData?.patient_by_pk?.attributes?.firstName} ${patientData?.patient_by_pk?.attributes?.lastName}`
      : '';

    setHeading(
      `${action} ${object} Note ${patientName ? `for ${patientName}` : ''}`
    );
  }, [appointmentData, patientData, note]);

  const assignedToProvider = note
    ? note?.assignedToProvider || appointmentData?.appointment_by_pk?.provider
    : appointmentData?.appointment_by_pk?.provider;

  return (
    <Flex
      w={'full'}
      direction={mini || isMobile ? 'column' : 'row'}
      justify="space-between"
      p={2}
    >
      <HStack mb={1}>
        <Skeleton isLoaded={!patientLoading && !appointmentLoading}>
          <Stack>
            {!mini && !isMobile && (
              <HStack w={'full'}>
                <ClinicalNoteTypeahead
                  defaultValue={note?.title}
                  onSaveNoteTitle={handleNoteTitleChange}
                />
              </HStack>
            )}
            {note && !mini && (
              <HStack spacing={2} overflowX="auto">
                {note?.parentNote && (
                  <NotePopover note={note?.parentNote}>
                    <Box cursor="pointer">
                      <Tag fontSize={'sm'} colorScheme="teal">
                        <FaCodeBranch />
                        <Text maxW="md" truncate>
                          Follow-up for: {note?.parentNote?.title}
                        </Text>
                      </Tag>
                    </Box>
                  </NotePopover>
                )}
                <Tag fontSize={'sm'} color="gray.500">
                  Authored by: {note?.author?.firstName}{' '}
                  {note?.author?.lastName}
                </Tag>
                {note?.editedBy && (
                  <Tag fontSize={'sm'} color="gray.500">
                    Last edited by: {note?.editedBy?.firstName}{' '}
                    {note?.editedBy?.lastName}
                  </Tag>
                )}
                {note?.noteSignatures &&
                  Array.isArray(note?.noteSignatures) &&
                  note.noteSignatures.map(({ signature }) => (
                    <Popover key={signature.id} trigger="hover">
                      <PopoverTrigger>
                        <Tag
                          cursor="pointer"
                          as={'a'}
                          fontSize={'sm'}
                          color="gray.500"
                        >
                          Signed By {signature.provider?.firstName}{' '}
                          {signature.provider?.lastName}
                        </Tag>
                      </PopoverTrigger>
                      <PopoverContent>
                        <PopoverArrow />
                        <PopoverCloseButton />
                        <PopoverBody>
                          <Image src={signature?.file?.url} />
                        </PopoverBody>
                      </PopoverContent>
                    </Popover>
                  ))}
              </HStack>
            )}
          </Stack>
        </Skeleton>
      </HStack>
      <Skeleton isLoaded={!patientLoading && !appointmentLoading}>
        <HStack w={'full'} alignSelf="end" fontSize={'sm'}>
          {saving && (
            <HStack>
              <Spinner size="xs" />
              <Text>Auto saving...</Text>
            </HStack>
          )}
          <Text>{note ? 'Assigned to' : 'Will be assigned to'}</Text>
          <GenericObjectSelect<ProviderFieldsFragment>
            key={`${note?.id}-${appointmentData?.appointment_by_pk?.provider?.id}}`}
            containerStyle={{
              minW: '200px',
            }}
            defaultValue={
              assignedToProvider
                ? {
                    label: `${assignedToProvider?.firstName} ${assignedToProvider?.lastName}`,
                    value: assignedToProvider?.id,
                    object: assignedToProvider,
                  }
                : undefined
            }
            isAsync
            onChange={handleProviderChange}
            useLoadOptions={useLoadProviders}
            placeholder="No Provider Assigned"
          />
        </HStack>
      </Skeleton>
    </Flex>
  );
}

export default NoteHeader;
