import * as Types from '../../generated/graphql';

import { gql } from '@apollo/client';
import { LeadFormFieldFieldsFragmentDoc } from './leadFormFieldFields.fragment.generated';
export type LeadFormStepFieldsFragment = { __typename?: 'leadFormStep', id: any, name?: string | null | undefined, order: number, title?: string | null | undefined, subtitle?: string | null | undefined, callToAction?: string | null | undefined, workspaceId: any, type: string, canDelete: boolean, canReOrder: boolean, properties?: any | null | undefined, fields: Array<{ __typename?: 'leadFormField', id: any, validationProps?: any | null | undefined, apiFieldKey: string, label: string, order: any, type: string, workspaceId: any }> };

export const LeadFormStepFieldsFragmentDoc = gql`
    fragment LeadFormStepFields on leadFormStep {
  id
  name
  order
  title
  subtitle
  callToAction
  workspaceId
  type
  canDelete
  canReOrder
  properties
  fields: leadFormFields {
    ...LeadFormFieldFields
  }
}
    ${LeadFormFieldFieldsFragmentDoc}`;