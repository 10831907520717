import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetAudienceTableQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetAudienceTableQuery = { __typename?: 'query_root', audience: Array<{ __typename?: 'audience', id: any, name?: string | null | undefined, type: string, updatedAt: any }> };


export const GetAudienceTableDocument = gql`
    query getAudienceTable {
  audience {
    id
    name
    type
    updatedAt
  }
}
    `;

/**
 * __useGetAudienceTableQuery__
 *
 * To run a query within a React component, call `useGetAudienceTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAudienceTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAudienceTableQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAudienceTableQuery(baseOptions?: Apollo.QueryHookOptions<GetAudienceTableQuery, GetAudienceTableQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAudienceTableQuery, GetAudienceTableQueryVariables>(GetAudienceTableDocument, options);
      }
export function useGetAudienceTableLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAudienceTableQuery, GetAudienceTableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAudienceTableQuery, GetAudienceTableQueryVariables>(GetAudienceTableDocument, options);
        }
export type GetAudienceTableQueryHookResult = ReturnType<typeof useGetAudienceTableQuery>;
export type GetAudienceTableLazyQueryHookResult = ReturnType<typeof useGetAudienceTableLazyQuery>;
export type GetAudienceTableQueryResult = Apollo.QueryResult<GetAudienceTableQuery, GetAudienceTableQueryVariables>;