import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { ConsumableBrandFieldsFullFragmentDoc } from '../../fragments/consumableBrandFieldsFull.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetConsumableBrandQueryVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
}>;


export type GetConsumableBrandQuery = { __typename?: 'query_root', consumableBrand_by_pk?: { __typename?: 'consumableBrand', id: any, name: string, contactName?: string | null | undefined, contactEmail?: string | null | undefined, contactPhoneNumber?: string | null | undefined, note?: string | null | undefined, createdAt: any, updatedAt: any, consumables: Array<{ __typename?: 'consumable', id: any, metadata: any, type: { __typename?: 'consumableType', name: string }, brand?: { __typename?: 'consumableBrand', name: string } | null | undefined }> } | null | undefined };


export const GetConsumableBrandDocument = gql`
    query GetConsumableBrand($id: uuid!) {
  consumableBrand_by_pk(id: $id) {
    ...ConsumableBrandFieldsFull
  }
}
    ${ConsumableBrandFieldsFullFragmentDoc}`;

/**
 * __useGetConsumableBrandQuery__
 *
 * To run a query within a React component, call `useGetConsumableBrandQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetConsumableBrandQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetConsumableBrandQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetConsumableBrandQuery(baseOptions: Apollo.QueryHookOptions<GetConsumableBrandQuery, GetConsumableBrandQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetConsumableBrandQuery, GetConsumableBrandQueryVariables>(GetConsumableBrandDocument, options);
      }
export function useGetConsumableBrandLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetConsumableBrandQuery, GetConsumableBrandQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetConsumableBrandQuery, GetConsumableBrandQueryVariables>(GetConsumableBrandDocument, options);
        }
export type GetConsumableBrandQueryHookResult = ReturnType<typeof useGetConsumableBrandQuery>;
export type GetConsumableBrandLazyQueryHookResult = ReturnType<typeof useGetConsumableBrandLazyQuery>;
export type GetConsumableBrandQueryResult = Apollo.QueryResult<GetConsumableBrandQuery, GetConsumableBrandQueryVariables>;