import * as Types from '../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type SendInngestEventMutationVariables = Types.Exact<{
  eventName: Types.Scalars['String'];
  payload: Types.Scalars['String'];
}>;


export type SendInngestEventMutation = { __typename?: 'mutation_root', sendInngestEvent?: { __typename?: 'SuccessAnswer', success: boolean } | null | undefined };


export const SendInngestEventDocument = gql`
    mutation SendInngestEvent($eventName: String!, $payload: String!) {
  sendInngestEvent(eventName: $eventName, payload: $payload) {
    success
  }
}
    `;
export type SendInngestEventMutationFn = Apollo.MutationFunction<SendInngestEventMutation, SendInngestEventMutationVariables>;

/**
 * __useSendInngestEventMutation__
 *
 * To run a mutation, you first call `useSendInngestEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendInngestEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendInngestEventMutation, { data, loading, error }] = useSendInngestEventMutation({
 *   variables: {
 *      eventName: // value for 'eventName'
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useSendInngestEventMutation(baseOptions?: Apollo.MutationHookOptions<SendInngestEventMutation, SendInngestEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendInngestEventMutation, SendInngestEventMutationVariables>(SendInngestEventDocument, options);
      }
export type SendInngestEventMutationHookResult = ReturnType<typeof useSendInngestEventMutation>;
export type SendInngestEventMutationResult = Apollo.MutationResult<SendInngestEventMutation>;
export type SendInngestEventMutationOptions = Apollo.BaseMutationOptions<SendInngestEventMutation, SendInngestEventMutationVariables>;