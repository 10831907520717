import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type PatientTagsQueryQueryVariables = Types.Exact<{
  patientId: Types.Scalars['uuid'];
}>;


export type PatientTagsQueryQuery = { __typename?: 'query_root', patient_by_pk?: { __typename?: 'patient', id: any, patientMemberships: Array<{ __typename?: 'patientMembership', status: string, nextPaymentDate?: any | null | undefined, membership: { __typename?: 'membership', id: any, name: string } }>, patientTags: Array<{ __typename?: 'patientTags', id: any, patientTag: { __typename?: 'patientTag', id: any, title: string, color?: string | null | undefined } }>, patientWorkspaces: Array<{ __typename?: 'patientWorkspace', attributes?: any | null | undefined, fullMediaConsent?: boolean | null | undefined, partialMediaConsent?: boolean | null | undefined, fullAnonymousMediaConsent?: boolean | null | undefined }> } | null | undefined };


export const PatientTagsQueryDocument = gql`
    query PatientTagsQuery($patientId: uuid!) {
  patient_by_pk(id: $patientId) {
    id
    patientMemberships {
      status
      nextPaymentDate
      membership {
        id
        name
      }
    }
    patientTags {
      id
      patientTag {
        id
        title
        color
      }
    }
    patientWorkspaces {
      attributes
      fullMediaConsent
      partialMediaConsent
      fullAnonymousMediaConsent
    }
  }
}
    `;

/**
 * __usePatientTagsQueryQuery__
 *
 * To run a query within a React component, call `usePatientTagsQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `usePatientTagsQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePatientTagsQueryQuery({
 *   variables: {
 *      patientId: // value for 'patientId'
 *   },
 * });
 */
export function usePatientTagsQueryQuery(baseOptions: Apollo.QueryHookOptions<PatientTagsQueryQuery, PatientTagsQueryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PatientTagsQueryQuery, PatientTagsQueryQueryVariables>(PatientTagsQueryDocument, options);
      }
export function usePatientTagsQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PatientTagsQueryQuery, PatientTagsQueryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PatientTagsQueryQuery, PatientTagsQueryQueryVariables>(PatientTagsQueryDocument, options);
        }
export type PatientTagsQueryQueryHookResult = ReturnType<typeof usePatientTagsQueryQuery>;
export type PatientTagsQueryLazyQueryHookResult = ReturnType<typeof usePatientTagsQueryLazyQuery>;
export type PatientTagsQueryQueryResult = Apollo.QueryResult<PatientTagsQueryQuery, PatientTagsQueryQueryVariables>;