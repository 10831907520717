import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type DeletePatientFlowsheetRowsMutationVariables = Types.Exact<{
  where: Types.PatientFlowsheetRow_Bool_Exp;
}>;


export type DeletePatientFlowsheetRowsMutation = { __typename?: 'mutation_root', delete_patientFlowsheetRow?: { __typename?: 'patientFlowsheetRow_mutation_response', affected_rows: number } | null | undefined };


export const DeletePatientFlowsheetRowsDocument = gql`
    mutation deletePatientFlowsheetRows($where: patientFlowsheetRow_bool_exp!) {
  delete_patientFlowsheetRow(where: $where) {
    affected_rows
  }
}
    `;
export type DeletePatientFlowsheetRowsMutationFn = Apollo.MutationFunction<DeletePatientFlowsheetRowsMutation, DeletePatientFlowsheetRowsMutationVariables>;

/**
 * __useDeletePatientFlowsheetRowsMutation__
 *
 * To run a mutation, you first call `useDeletePatientFlowsheetRowsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePatientFlowsheetRowsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePatientFlowsheetRowsMutation, { data, loading, error }] = useDeletePatientFlowsheetRowsMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDeletePatientFlowsheetRowsMutation(baseOptions?: Apollo.MutationHookOptions<DeletePatientFlowsheetRowsMutation, DeletePatientFlowsheetRowsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePatientFlowsheetRowsMutation, DeletePatientFlowsheetRowsMutationVariables>(DeletePatientFlowsheetRowsDocument, options);
      }
export type DeletePatientFlowsheetRowsMutationHookResult = ReturnType<typeof useDeletePatientFlowsheetRowsMutation>;
export type DeletePatientFlowsheetRowsMutationResult = Apollo.MutationResult<DeletePatientFlowsheetRowsMutation>;
export type DeletePatientFlowsheetRowsMutationOptions = Apollo.BaseMutationOptions<DeletePatientFlowsheetRowsMutation, DeletePatientFlowsheetRowsMutationVariables>;