import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type DeleteAppointmentByIdMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
}>;


export type DeleteAppointmentByIdMutation = { __typename?: 'mutation_root', delete_appointment_by_pk?: { __typename?: 'appointment', id: any } | null | undefined };


export const DeleteAppointmentByIdDocument = gql`
    mutation deleteAppointmentById($id: uuid!) {
  delete_appointment_by_pk(id: $id) {
    id
  }
}
    `;
export type DeleteAppointmentByIdMutationFn = Apollo.MutationFunction<DeleteAppointmentByIdMutation, DeleteAppointmentByIdMutationVariables>;

/**
 * __useDeleteAppointmentByIdMutation__
 *
 * To run a mutation, you first call `useDeleteAppointmentByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAppointmentByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAppointmentByIdMutation, { data, loading, error }] = useDeleteAppointmentByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteAppointmentByIdMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAppointmentByIdMutation, DeleteAppointmentByIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteAppointmentByIdMutation, DeleteAppointmentByIdMutationVariables>(DeleteAppointmentByIdDocument, options);
      }
export type DeleteAppointmentByIdMutationHookResult = ReturnType<typeof useDeleteAppointmentByIdMutation>;
export type DeleteAppointmentByIdMutationResult = Apollo.MutationResult<DeleteAppointmentByIdMutation>;
export type DeleteAppointmentByIdMutationOptions = Apollo.BaseMutationOptions<DeleteAppointmentByIdMutation, DeleteAppointmentByIdMutationVariables>;