import * as Types from '../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type PayWithExistingMethodMutationVariables = Types.Exact<{
  amount: Types.Scalars['Int'];
  caseName: Types.Scalars['String'];
  caseContext: Types.Scalars['jsonb'];
  patientId: Types.Scalars['uuid'];
  paymentMethodId: Types.Scalars['uuid'];
  payrixMerchantId?: Types.Maybe<Types.Scalars['String']>;
  finixMerchantId?: Types.Maybe<Types.Scalars['String']>;
  workspaceId: Types.Scalars['uuid'];
}>;


export type PayWithExistingMethodMutation = { __typename?: 'mutation_root', payWithExistingMethod: { __typename?: 'SuccessAnswer', success: boolean } };


export const PayWithExistingMethodDocument = gql`
    mutation PayWithExistingMethod($amount: Int!, $caseName: String!, $caseContext: jsonb!, $patientId: uuid!, $paymentMethodId: uuid!, $payrixMerchantId: String, $finixMerchantId: String, $workspaceId: uuid!) {
  payWithExistingMethod(
    amount: $amount
    caseName: $caseName
    caseContext: $caseContext
    patientId: $patientId
    paymentMethodId: $paymentMethodId
    payrixMerchantId: $payrixMerchantId
    workspaceId: $workspaceId
    finixMerchantId: $finixMerchantId
  ) {
    success
  }
}
    `;
export type PayWithExistingMethodMutationFn = Apollo.MutationFunction<PayWithExistingMethodMutation, PayWithExistingMethodMutationVariables>;

/**
 * __usePayWithExistingMethodMutation__
 *
 * To run a mutation, you first call `usePayWithExistingMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePayWithExistingMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [payWithExistingMethodMutation, { data, loading, error }] = usePayWithExistingMethodMutation({
 *   variables: {
 *      amount: // value for 'amount'
 *      caseName: // value for 'caseName'
 *      caseContext: // value for 'caseContext'
 *      patientId: // value for 'patientId'
 *      paymentMethodId: // value for 'paymentMethodId'
 *      payrixMerchantId: // value for 'payrixMerchantId'
 *      finixMerchantId: // value for 'finixMerchantId'
 *      workspaceId: // value for 'workspaceId'
 *   },
 * });
 */
export function usePayWithExistingMethodMutation(baseOptions?: Apollo.MutationHookOptions<PayWithExistingMethodMutation, PayWithExistingMethodMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PayWithExistingMethodMutation, PayWithExistingMethodMutationVariables>(PayWithExistingMethodDocument, options);
      }
export type PayWithExistingMethodMutationHookResult = ReturnType<typeof usePayWithExistingMethodMutation>;
export type PayWithExistingMethodMutationResult = Apollo.MutationResult<PayWithExistingMethodMutation>;
export type PayWithExistingMethodMutationOptions = Apollo.BaseMutationOptions<PayWithExistingMethodMutation, PayWithExistingMethodMutationVariables>;