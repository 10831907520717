import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetWorkspaceForPatientPortalQueryVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
}>;


export type GetWorkspaceForPatientPortalQuery = { __typename?: 'query_root', workspace_by_pk?: { __typename?: 'workspace', finixMerchantId?: string | null | undefined, id: any, logo?: string | null | undefined, name: string, slug?: string | null | undefined, domain?: string | null | undefined, payrixMerchantId?: string | null | undefined, workspaceConfiguration?: { __typename?: 'workspaceConfiguration', id: any, logo?: string | null | undefined, giftCardOptions?: any | null | undefined, storefrontConfiguration?: any | null | undefined, theme?: any | null | undefined, cloudflareCustomHostId?: string | null | undefined } | null | undefined } | null | undefined };


export const GetWorkspaceForPatientPortalDocument = gql`
    query GetWorkspaceForPatientPortal($id: uuid!) {
  workspace_by_pk(id: $id) {
    finixMerchantId
    id
    logo
    name
    slug
    domain
    payrixMerchantId
    workspaceConfiguration {
      id
      logo
      giftCardOptions
      storefrontConfiguration
      theme
      cloudflareCustomHostId
    }
  }
}
    `;

/**
 * __useGetWorkspaceForPatientPortalQuery__
 *
 * To run a query within a React component, call `useGetWorkspaceForPatientPortalQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkspaceForPatientPortalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkspaceForPatientPortalQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetWorkspaceForPatientPortalQuery(baseOptions: Apollo.QueryHookOptions<GetWorkspaceForPatientPortalQuery, GetWorkspaceForPatientPortalQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWorkspaceForPatientPortalQuery, GetWorkspaceForPatientPortalQueryVariables>(GetWorkspaceForPatientPortalDocument, options);
      }
export function useGetWorkspaceForPatientPortalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWorkspaceForPatientPortalQuery, GetWorkspaceForPatientPortalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWorkspaceForPatientPortalQuery, GetWorkspaceForPatientPortalQueryVariables>(GetWorkspaceForPatientPortalDocument, options);
        }
export type GetWorkspaceForPatientPortalQueryHookResult = ReturnType<typeof useGetWorkspaceForPatientPortalQuery>;
export type GetWorkspaceForPatientPortalLazyQueryHookResult = ReturnType<typeof useGetWorkspaceForPatientPortalLazyQuery>;
export type GetWorkspaceForPatientPortalQueryResult = Apollo.QueryResult<GetWorkspaceForPatientPortalQuery, GetWorkspaceForPatientPortalQueryVariables>;