/* eslint-disable no-nested-ternary */
import {
  Button,
  ButtonGroup,
  Checkbox,
  Flex,
  FlexProps,
  HStack,
  Heading,
  Menu,
  MenuButton,
  Spinner,
  Stack,
  StyleProps,
  Text,
  useColorModeValue,
  VStack,
  useBreakpointValue,
} from '@chakra-ui/react';
import './react-table.d';
import {
  useTable,
  useRowSelect,
  useFlexLayout,
  useSortBy,
  TableOptions,
  Row,
} from 'react-table';

import React, {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import {
  HiArrowCircleDown,
  HiArrowCircleUp,
  HiChevronDown,
} from 'react-icons/hi';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  getSortParametersMap,
  sortParametersMapToArray,
} from '@webapp/webapp/util-url';
import { titleCase } from 'title-case';
import Pagination from '../pagination/pagination';
import './table.module.scss';
import { SearchInput } from './searchInput';

export interface TableProps<T extends Record<string, unknown>>
  extends TableOptions<T> {
  actionsMenu?: React.ComponentType<{
    selectedRows: Record<string, boolean>;
  }>;
  allowResponsive?: boolean;
  canSelectColumns?: boolean;
  count?: number;
  customStyles?: {
    borderColor?: string;
    cell?: StyleProps;
    table?: StyleProps;
    tableBody?: StyleProps;
    tableHeader?: StyleProps;
    tableRow?: FlexProps;
  };
  data: T[];
  defaultSelectedRows?: Record<string, boolean>;
  emptyStateText?: string;
  hasSearchInput?: boolean;
  heading?: string;
  isLoading?: boolean;
  onRowClick?: (row: Row<T>) => void;
  selectCallBack?: Dispatch<SetStateAction<string[]>>;
  shouldDisplayPagination?: boolean;
  mobileCellFlexDirection?: 'column' | 'row';
  updateTableData?: (
    rowIndex: number,
    columnId: string,
    value: unknown
  ) => void;
  withButtons?: { Component: React.ElementType }[];
  forceMobileView?: boolean;
}

function renderSortArrow(map: { [key: string]: string }, id: string) {
  const sortParams = id.split(',');

  const sortId = sortParams.find((sortParam) => map[sortParam]);

  if (sortId && map[sortId] === 'desc') {
    return <HiArrowCircleDown color="#008399" />;
  }

  if (sortId && map[sortId]) {
    return <HiArrowCircleUp color="#008399" />;
  }

  return '';
}

export function DataTable<T extends Record<string, any>>({
  actionsMenu: ActionsMenu,
  allowResponsive = true,
  canSelectColumns = false,
  count,
  columns,
  customStyles = {},
  data,
  defaultSelectedRows,
  emptyStateText,
  hasSearchInput = true,
  heading,
  isLoading = false,
  onRowClick,
  selectCallBack,
  shouldDisplayPagination = true,
  updateMyData,
  updateTableData = () => undefined,
  withButtons = [],
  mobileCellFlexDirection = 'column',
  forceMobileView = false,
}: PropsWithChildren<TableProps<T>>) {
  const borderColor =
    customStyles.borderColor ?? 'var(--chakra-colors-gray-100)';
  const cellStyle = customStyles.cell ?? {};
  const tableBodyStyle = customStyles.tableBody ?? {};
  const tableHeaderStyle = customStyles.tableHeader ?? {};
  const tableStyle = customStyles.table ?? {};
  const tableRowStyle = customStyles.tableRow ?? {};
  let isMobile = useBreakpointValue({ base: true, md: false });
  if (forceMobileView) isMobile = true;

  const canResize = allowResponsive && isMobile;

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable<T>(
      {
        columns,
        data,
        meta: {
          updateTableData,
        },
      },
      useFlexLayout,
      useSortBy,
      useRowSelect
    );

  const [selectedRows, selectRows] = useState<Record<string, boolean>>(
    defaultSelectedRows ?? {}
  );

  useEffect(() => {
    if (selectCallBack) {
      selectCallBack(Object.keys(selectedRows));
    }
  }, [selectedRows]);

  const location = useLocation();
  const navigate = useNavigate();

  const searchParams = new URLSearchParams(location.search);
  const currentOrderMap = getSortParametersMap(searchParams);

  const pageSize = searchParams.get('pageSize');
  const page = searchParams.get('page');

  useEffect(() => {
    if (!defaultSelectedRows) {
      clearAllRows();
    }
  }, [pageSize, page, defaultSelectedRows]);

  function selectAllRows() {
    const ids = data.map((datum) => datum.id);

    const nextSelectedRows = {
      ...selectedRows,
    };

    ids.forEach((id) => {
      if (typeof id === 'string') {
        nextSelectedRows[id] = true;
      }
    });

    selectRows(nextSelectedRows);
  }

  function clearAllRows() {
    selectRows({});
  }

  const selectedRowsLength = Object.keys(selectedRows).length;

  const headerGrey = useColorModeValue('gray.50', 'gray.800');

  return (
    <VStack overflow="initial" width="100%">
      {canSelectColumns && (
        <Flex
          alignItems={isMobile ? 'start' : 'center'}
          justifyItems="start"
          width="100%"
          direction={isMobile ? 'column' : 'row'}
        >
          {/* search input  */}
          {heading && <Heading as="h3">{heading}</Heading>}
          <Stack direction={isMobile ? 'column' : 'row'} spacing={4} w={'full'}>
            {hasSearchInput && (
              <SearchInput
                containerStyle={{
                  minWidth: '150px',
                  width: isMobile ? '100%' : '150px',
                }}
              />
            )}
            {/* Actions Menu */}
            <HStack display={isMobile ? 'none' : 'flex'}>
              <Text color="#6C6C72" minWidth="100px" size="sm">
                {selectedRowsLength} selected
              </Text>
              <ButtonGroup variant="unstyled">
                <Button
                  color="#008399"
                  disabled={
                    selectedRowsLength === parseInt(pageSize ?? '10', 10)
                  }
                  padding="0 9px"
                  onClick={selectAllRows}
                  size="sm"
                >
                  Select All
                </Button>
                <Button
                  color="#008399"
                  disabled={!selectedRowsLength}
                  padding="0 9px"
                  onClick={clearAllRows}
                  size="sm"
                >
                  Clear All
                </Button>
              </ButtonGroup>
              {ActionsMenu && (
                <Menu>
                  <MenuButton
                    as={Button}
                    background="transparent"
                    color="#008399"
                    fontWeight="bold"
                    rightIcon={<HiChevronDown />}
                    size="sm"
                  >
                    Actions
                  </MenuButton>
                  <ActionsMenu selectedRows={selectedRows} />
                </Menu>
              )}
            </HStack>
          </Stack>
          {/* Create button */}
          {Boolean(withButtons.length) && (
            <HStack
              justifyContent={'flex-end'}
              spacing={2}
              w={'full'}
              mt={4}
              mb={2}
            >
              {withButtons.map((button) => (
                <button.Component width={{ base: '100%' }} />
              ))}
            </HStack>
          )}
        </Flex>
      )}
      <Flex
        border={isMobile ? 'none' : `1px solid ${borderColor}`}
        borderColor={borderColor}
        className="table"
        flexDirection="column"
        minHeight="580px"
        overflow="visible"
        width="100%"
        css={{
          '&::-webkit-scrollbar': {
            display: 'none',
          },
        }}
        sx={{
          scrollbarWidth: 'none',
        }}
        {...getTableProps()}
        {...tableStyle}
      >
        <Flex
          display={canResize && isMobile ? 'none' : 'flex'}
          bg={useColorModeValue('gray.50', 'gray.800')}
          borderBottom="var(--chakra-borders-1px)"
          borderColor={borderColor}
          className="thead"
          overflow="hidden"
          width="100%"
        >
          <Flex className="tr" width="100%">
            {canSelectColumns && (
              <Flex
                alignItems="center"
                bg={headerGrey}
                className="th"
                fontFamily="var(--chakra-fonts-heading)"
                justifyContent="center"
                padding="16px 24px"
                scope="col"
                {...tableHeaderStyle}
              >
                <Checkbox className="checkbox_header__disabled" isDisabled />
              </Flex>
            )}
            {headerGroups.map((headerGroup) =>
              headerGroup.headers.map((column, index) => (
                <Flex
                  alignItems="center"
                  bg={headerGrey}
                  className="th"
                  fontFamily="var(--chakra-fonts-heading)"
                  justifyContent="start"
                  scope="col"
                  width="100%"
                  {...tableHeaderStyle}
                  {...column.getHeaderProps()}
                  {...(column.canSort && {
                    cursor: 'pointer',
                    onClick: () => {
                      const sortParams = column.id.split(',');

                      sortParams.forEach((sortParam) => {
                        const sortDirection = currentOrderMap[sortParam];

                        if (sortDirection === 'asc') {
                          currentOrderMap[sortParam] = 'desc';
                        } else if (sortDirection === 'desc') {
                          delete currentOrderMap[sortParam];
                        } else {
                          currentOrderMap[sortParam] = 'asc';
                        }
                      });

                      searchParams.delete('order');

                      const orderParamArray =
                        sortParametersMapToArray(currentOrderMap);

                      if (orderParamArray.length) {
                        searchParams.set('order', orderParamArray.join(','));
                      }

                      navigate({
                        search: searchParams.toString(),
                      });
                    },
                  })}
                >
                  <Text
                    color="inherit"
                    fontSize="var(--chakra-fontSizes-xs)"
                    fontWeight="var(--chakra-fontWeights-bold)"
                    letterSpacing="var(--chakra-letterSpacings-wider)"
                    padding="12px 24px"
                    userSelect="none"
                    {...(column.canSort && {
                      _hover: {
                        color: 'var(--chakra-colors-gray-500)',
                      },
                    })}
                  >
                    {/* @ts-expect-error React18 need to update */}
                    <div>{column.Header}</div>
                  </Text>
                  {renderSortArrow(currentOrderMap, column.id)}
                </Flex>
              ))
            )}
          </Flex>
        </Flex>
        <Flex
          className="tbody"
          flexDirection="column"
          height="100%"
          width="100%"
          {...getTableBodyProps()}
          {...tableBodyStyle}
        >
          {!rows.length && (
            <Flex className="tr" height="100%" width="100%">
              <Flex
                alignItems="center"
                className="td"
                colSpan={canSelectColumns ? columns.length + 1 : columns.length}
                height="100%"
                justifyContent="center"
                padding="20px"
                width="100%"
              >
                <Flex justifyContent="center" width="100%">
                  {!isLoading && (
                    <Text color="gray.500" fontSize="16px" fontWeight="bold">
                      {emptyStateText || 'No Results'}
                    </Text>
                  )}
                  {isLoading && (
                    <Spinner
                      thickness="4px"
                      speed="0.65s"
                      emptyColor="gray.200"
                      color="teal.500"
                      size="xl"
                    />
                  )}
                </Flex>
              </Flex>
            </Flex>
          )}
          {Boolean(rows.length) &&
            rows.map((row: Row<T>) => {
              prepareRow(row);

              return (
                <Flex
                  {...row.getRowProps()}
                  maxH={isMobile ? 400 : 20}
                  borderTop={
                    canResize ? (isMobile ? '1px solid gray' : 'none') : 'none'
                  }
                  borderLeft={
                    canResize ? (isMobile ? '1px solid gray' : 'none') : 'none'
                  }
                  borderRight={
                    canResize ? (isMobile ? '1px solid gray' : 'none') : 'none'
                  }
                  shadow={canResize ? (isMobile ? 'md' : 'none') : 'none'}
                  rounded={canResize ? (isMobile ? 'lg' : 'none') : 'none'}
                  padding={isMobile ? 4 : 0}
                  mb={isMobile ? 4 : 0}
                  borderBottom="var(--chakra-borders-1px)"
                  borderColor={borderColor}
                  className="data-table-row tr"
                  direction={canResize && isMobile ? 'column' : 'row'}
                  onClick={onRowClick ? () => onRowClick(row) : undefined}
                  overflow={'visible'}
                  alignItems={'center'}
                  _last={{
                    borderBottom: 'unset',
                  }}
                  {...tableRowStyle}
                >
                  {canSelectColumns && (
                    <Flex
                      className="td"
                      padding="16px 24px"
                      display={isMobile ? 'none' : 'flex'}
                    >
                      <Checkbox
                        isChecked={
                          selectedRows[row.original.id as string] ?? false
                        }
                        onChange={() => {
                          if (
                            row.original.id &&
                            selectedRows[row.original.id as string]
                          ) {
                            const nextSelectedRows = { ...selectedRows };
                            delete nextSelectedRows[row.original.id as string];
                            selectRows(nextSelectedRows);
                          } else if (row.original.id) {
                            selectRows({
                              ...selectedRows,
                              [row.original.id as string]: true,
                            });
                          }
                        }}
                      />
                    </Flex>
                  )}
                  {row.cells.map((cell, index) =>
                    index === 0 && isMobile && canSelectColumns ? (
                      <HStack
                        w={'full'}
                        justifyContent="space-between"
                        pb={isMobile ? 4 : 0}
                      >
                        <HStack w={'full'} alignItems="center">
                          <Checkbox
                            isChecked={
                              selectedRows[row.original.id as string] ?? false
                            }
                            onChange={() => {
                              if (
                                row.original.id &&
                                selectedRows[row.original.id as string]
                              ) {
                                const nextSelectedRows = { ...selectedRows };
                                delete nextSelectedRows[
                                  row.original.id as string
                                ];
                                selectRows(nextSelectedRows);
                              } else if (row.original.id) {
                                selectRows({
                                  ...selectedRows,
                                  [row.original.id as string]: true,
                                });
                              }
                            }}
                          />

                          {cell.value !== undefined && cell.value !== null
                            ? cell.render('Cell')
                            : 'No Data'}
                        </HStack>
                      </HStack>
                    ) : (
                      <Flex
                        className="td"
                        direction={mobileCellFlexDirection}
                        pb={isMobile ? 4 : 0}
                        fontSize="var(--chakra-fontSizes-sm)"
                        padding={isMobile ? '4px 8px' : '16px 24px'}
                        justifyContent="space-between"
                        whiteSpace="nowrap"
                        {...cellStyle}
                        {...(isMobile
                          ? { width: '100%' }
                          : cell.getCellProps())}
                      >
                        {isMobile && (
                          <Heading size="xs">
                            {typeof cell?.column?.Header === 'string'
                              ? titleCase(
                                  cell?.column?.Header.toLowerCase() || ''
                                )
                              : cell?.column?.Header}
                          </Heading>
                        )}
                        <Text>
                          {/* @ts-expect-error React18 need to update */}
                          {cell.value !== undefined && cell.value !== null
                            ? cell.render('Cell')
                            : 'No Data'}
                        </Text>
                      </Flex>
                    )
                  )}
                </Flex>
              );
            })}
        </Flex>
      </Flex>
      {Number.isInteger(count) && shouldDisplayPagination && (
        <Pagination count={count} />
      )}
    </VStack>
  );
}
