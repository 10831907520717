import { Button, Text, VStack } from '@chakra-ui/react';
import { toLocalePriceInCents } from '@webapp/util-formatting';
import { useMemo } from 'react';

interface GratuityModalAmountButtonProps {
  amount: number;
  colorScheme?: string;
  onChange: (value: number) => void;
  selected?: boolean;
  total: number;
}

function GratuityModalAmountButton({
  amount,
  colorScheme,
  onChange,
  selected = false,
  total,
}: GratuityModalAmountButtonProps) {
  const isPercent = amount < 1;

  const gratuityAmountString = useMemo(() => {
    if (isPercent) {
      return `${Math.round(amount * 100)}%`;
    }

    if (amount % 100 === 0) {
      return toLocalePriceInCents(amount, {
        maximumFractionDigits: 0,
      });
    }

    return toLocalePriceInCents(amount);
  }, [amount, isPercent]);

  function setValue() {
    if (isPercent) {
      const valueInt = Math.round(amount * total);
      onChange(valueInt);
    } else {
      onChange(amount);
    }
  }

  return (
    <Button
      colorScheme={selected ? colorScheme : 'gray'}
      fontSize="lg"
      height="100%"
      onClick={setValue}
    >
      <VStack>
        <Text>{gratuityAmountString}</Text>
        {isPercent && (
          <Text fontSize="sm" fontWeight="normal" opacity=".8">
            {toLocalePriceInCents(amount * total)}
          </Text>
        )}
      </VStack>
    </Button>
  );
}

export default GratuityModalAmountButton;
