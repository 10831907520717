import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { DeviceFieldsFragmentDoc } from '../../fragments/deviceFields.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type InsertDeviceMutationVariables = Types.Exact<{
  device: Types.Device_Insert_Input;
}>;


export type InsertDeviceMutation = { __typename?: 'mutation_root', insert_device?: { __typename?: 'device_mutation_response', returning: Array<{ __typename?: 'device', id: any, name: string, schedule?: any | null | undefined, active?: boolean | null | undefined, deviceType?: { __typename?: 'deviceType', id: any, value: string, serviceDeviceTypes: Array<{ __typename?: 'serviceDeviceType', service: { __typename?: 'service', id: any, name: string } }> } | null | undefined, location?: { __typename?: 'location', id: any, isActive: boolean, name: string, address?: string | null | undefined, phoneNumber?: string | null | undefined, email?: string | null | undefined, maxPatientsPerTimeslot?: number | null | undefined, workspaceId: any, clinicId?: number | null | undefined, addressId?: any | null | undefined, workStartTime?: string | null | undefined, workEndTime?: string | null | undefined, timezone?: string | null | undefined, payrixMerchantId?: string | null | undefined, finixMerchantId?: string | null | undefined, locationSchedules: Array<{ __typename?: 'locationSchedule', id: any, schedule: any, effectiveFrom?: any | null | undefined, createdAt: any, updatedAt: any, locationId: any }>, fullAddress?: { __typename?: 'address', addressLine1: string, addressLine2?: string | null | undefined, city: string, id: any, state: string, zipCode: string } | null | undefined, rooms: Array<{ __typename?: 'room', id: any, name: string }> } | null | undefined }> } | null | undefined };


export const InsertDeviceDocument = gql`
    mutation insertDevice($device: device_insert_input!) {
  insert_device(objects: [$device]) {
    returning {
      ...DeviceFields
    }
  }
}
    ${DeviceFieldsFragmentDoc}`;
export type InsertDeviceMutationFn = Apollo.MutationFunction<InsertDeviceMutation, InsertDeviceMutationVariables>;

/**
 * __useInsertDeviceMutation__
 *
 * To run a mutation, you first call `useInsertDeviceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertDeviceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertDeviceMutation, { data, loading, error }] = useInsertDeviceMutation({
 *   variables: {
 *      device: // value for 'device'
 *   },
 * });
 */
export function useInsertDeviceMutation(baseOptions?: Apollo.MutationHookOptions<InsertDeviceMutation, InsertDeviceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertDeviceMutation, InsertDeviceMutationVariables>(InsertDeviceDocument, options);
      }
export type InsertDeviceMutationHookResult = ReturnType<typeof useInsertDeviceMutation>;
export type InsertDeviceMutationResult = Apollo.MutationResult<InsertDeviceMutation>;
export type InsertDeviceMutationOptions = Apollo.BaseMutationOptions<InsertDeviceMutation, InsertDeviceMutationVariables>;