import * as Types from '../../generated/graphql';

import { gql } from '@apollo/client';
export type InventoryTransactionFieldsFragment = { __typename?: 'inventoryTransaction', id: any, type: string, quantity: any, createdAt: any, user: { __typename?: 'user', id: any, firstName: string, lastName: string }, consumable: { __typename?: 'consumable', id: any, metadata: any, isExpirable: boolean, expirationDate?: any | null | undefined } };

export const InventoryTransactionFieldsFragmentDoc = gql`
    fragment InventoryTransactionFields on inventoryTransaction {
  id
  user {
    id
    firstName
    lastName
  }
  type
  quantity
  consumable {
    id
    metadata
    isExpirable
    expirationDate
  }
  createdAt
}
    `;