import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetPayfieldsPropsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetPayfieldsPropsQuery = { __typename?: 'query_root', patient: Array<{ __typename?: 'patient', id: any }>, paymentMethod: Array<{ __typename?: 'paymentMethod', id: any, last4: string }>, workspace: Array<{ __typename?: 'workspace', id: any, payrixMerchantId?: string | null | undefined }> };


export const GetPayfieldsPropsDocument = gql`
    query GetPayfieldsProps {
  patient(limit: 1) {
    id
  }
  paymentMethod(limit: 4) {
    id
    last4
  }
  workspace {
    id
    payrixMerchantId
  }
}
    `;

/**
 * __useGetPayfieldsPropsQuery__
 *
 * To run a query within a React component, call `useGetPayfieldsPropsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPayfieldsPropsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPayfieldsPropsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPayfieldsPropsQuery(baseOptions?: Apollo.QueryHookOptions<GetPayfieldsPropsQuery, GetPayfieldsPropsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPayfieldsPropsQuery, GetPayfieldsPropsQueryVariables>(GetPayfieldsPropsDocument, options);
      }
export function useGetPayfieldsPropsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPayfieldsPropsQuery, GetPayfieldsPropsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPayfieldsPropsQuery, GetPayfieldsPropsQueryVariables>(GetPayfieldsPropsDocument, options);
        }
export type GetPayfieldsPropsQueryHookResult = ReturnType<typeof useGetPayfieldsPropsQuery>;
export type GetPayfieldsPropsLazyQueryHookResult = ReturnType<typeof useGetPayfieldsPropsLazyQuery>;
export type GetPayfieldsPropsQueryResult = Apollo.QueryResult<GetPayfieldsPropsQuery, GetPayfieldsPropsQueryVariables>;