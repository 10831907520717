import { extendTheme } from '@chakra-ui/react';
import { ComponentsStyleConfig } from '@webapp/types';

export const FormLabelConfig: ComponentsStyleConfig = {
  FormLabel: {
    baseStyle: {
      color: 'text.100',
      fontSize: 'md',
      fontWeight: '600',
      letterSpacing: '0.01em',
    },
  },
};

const FormLabelTheme = extendTheme({ components: FormLabelConfig });

export default FormLabelTheme;
