import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type OverrideAppointmentNoShowFeeMutationVariables = Types.Exact<{
  appointmentId: Types.Scalars['uuid'];
}>;


export type OverrideAppointmentNoShowFeeMutation = { __typename?: 'mutation_root', overrideAppointmentNoShowFee?: { __typename?: 'SuccessAnswer', success: boolean } | null | undefined };


export const OverrideAppointmentNoShowFeeDocument = gql`
    mutation OverrideAppointmentNoShowFee($appointmentId: uuid!) {
  overrideAppointmentNoShowFee(appointmentId: $appointmentId) {
    success
  }
}
    `;
export type OverrideAppointmentNoShowFeeMutationFn = Apollo.MutationFunction<OverrideAppointmentNoShowFeeMutation, OverrideAppointmentNoShowFeeMutationVariables>;

/**
 * __useOverrideAppointmentNoShowFeeMutation__
 *
 * To run a mutation, you first call `useOverrideAppointmentNoShowFeeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOverrideAppointmentNoShowFeeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [overrideAppointmentNoShowFeeMutation, { data, loading, error }] = useOverrideAppointmentNoShowFeeMutation({
 *   variables: {
 *      appointmentId: // value for 'appointmentId'
 *   },
 * });
 */
export function useOverrideAppointmentNoShowFeeMutation(baseOptions?: Apollo.MutationHookOptions<OverrideAppointmentNoShowFeeMutation, OverrideAppointmentNoShowFeeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OverrideAppointmentNoShowFeeMutation, OverrideAppointmentNoShowFeeMutationVariables>(OverrideAppointmentNoShowFeeDocument, options);
      }
export type OverrideAppointmentNoShowFeeMutationHookResult = ReturnType<typeof useOverrideAppointmentNoShowFeeMutation>;
export type OverrideAppointmentNoShowFeeMutationResult = Apollo.MutationResult<OverrideAppointmentNoShowFeeMutation>;
export type OverrideAppointmentNoShowFeeMutationOptions = Apollo.BaseMutationOptions<OverrideAppointmentNoShowFeeMutation, OverrideAppointmentNoShowFeeMutationVariables>;