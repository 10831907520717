import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetSmsTemplateTableQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetSmsTemplateTableQuery = { __typename?: 'query_root', smsTemplate: Array<{ __typename?: 'smsTemplate', id: any, active: boolean, name: string, lastSent?: any | null | undefined, updatedAt: any, audience?: { __typename?: 'audience', type: string } | null | undefined, marketingTags: Array<{ __typename?: 'marketingTagSms', marketingTag: { __typename?: 'marketingTag', id: any, title: string } }> }> };


export const GetSmsTemplateTableDocument = gql`
    query getSMSTemplateTable {
  smsTemplate {
    id
    active
    name
    lastSent
    audience {
      type
    }
    marketingTags {
      marketingTag {
        id
        title
      }
    }
    updatedAt
  }
}
    `;

/**
 * __useGetSmsTemplateTableQuery__
 *
 * To run a query within a React component, call `useGetSmsTemplateTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSmsTemplateTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSmsTemplateTableQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSmsTemplateTableQuery(baseOptions?: Apollo.QueryHookOptions<GetSmsTemplateTableQuery, GetSmsTemplateTableQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSmsTemplateTableQuery, GetSmsTemplateTableQueryVariables>(GetSmsTemplateTableDocument, options);
      }
export function useGetSmsTemplateTableLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSmsTemplateTableQuery, GetSmsTemplateTableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSmsTemplateTableQuery, GetSmsTemplateTableQueryVariables>(GetSmsTemplateTableDocument, options);
        }
export type GetSmsTemplateTableQueryHookResult = ReturnType<typeof useGetSmsTemplateTableQuery>;
export type GetSmsTemplateTableLazyQueryHookResult = ReturnType<typeof useGetSmsTemplateTableLazyQuery>;
export type GetSmsTemplateTableQueryResult = Apollo.QueryResult<GetSmsTemplateTableQuery, GetSmsTemplateTableQueryVariables>;