import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { CustomQuestionFieldsFragmentDoc } from '../../fragments/customQuestionFields.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type UpdateCustomQuestionByPkMutationVariables = Types.Exact<{
  set?: Types.Maybe<Types.CustomQuestion_Set_Input>;
  id: Types.Scalars['uuid'];
}>;


export type UpdateCustomQuestionByPkMutation = { __typename?: 'mutation_root', update_customQuestion_by_pk?: { __typename?: 'customQuestion', id: any, label: string, body?: string | null | undefined, photoId?: any | null | undefined, categoryValueAndDescription?: { __typename?: 'customFormFieldCategory', value: string, description: string } | null | undefined, typeValueAndDescription: { __typename?: 'customFormFieldType', value: string, description: string }, options: Array<{ __typename?: 'customQuestionOption', value: string }>, photo?: { __typename?: 'photo', file?: { __typename?: 'FilePayload', url: string } | null | undefined } | null | undefined } | null | undefined };


export const UpdateCustomQuestionByPkDocument = gql`
    mutation UpdateCustomQuestionByPk($set: customQuestion_set_input, $id: uuid!) {
  update_customQuestion_by_pk(_set: $set, pk_columns: {id: $id}) {
    ...CustomQuestionFields
  }
}
    ${CustomQuestionFieldsFragmentDoc}`;
export type UpdateCustomQuestionByPkMutationFn = Apollo.MutationFunction<UpdateCustomQuestionByPkMutation, UpdateCustomQuestionByPkMutationVariables>;

/**
 * __useUpdateCustomQuestionByPkMutation__
 *
 * To run a mutation, you first call `useUpdateCustomQuestionByPkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomQuestionByPkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomQuestionByPkMutation, { data, loading, error }] = useUpdateCustomQuestionByPkMutation({
 *   variables: {
 *      set: // value for 'set'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateCustomQuestionByPkMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCustomQuestionByPkMutation, UpdateCustomQuestionByPkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCustomQuestionByPkMutation, UpdateCustomQuestionByPkMutationVariables>(UpdateCustomQuestionByPkDocument, options);
      }
export type UpdateCustomQuestionByPkMutationHookResult = ReturnType<typeof useUpdateCustomQuestionByPkMutation>;
export type UpdateCustomQuestionByPkMutationResult = Apollo.MutationResult<UpdateCustomQuestionByPkMutation>;
export type UpdateCustomQuestionByPkMutationOptions = Apollo.BaseMutationOptions<UpdateCustomQuestionByPkMutation, UpdateCustomQuestionByPkMutationVariables>;