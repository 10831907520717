import CreativeEditorSDK, { Configuration } from '@cesdk/cesdk-js';
import {
  Box,
  BoxProps,
  Flex,
  IconButton,
  Stack,
  Tooltip,
} from '@chakra-ui/react';
import {
  Photo_Bool_Exp,
  StorageType,
  useInsertPhotoMutation,
} from '@webapp/graphql';
import { Photo, PhotoModel, useStores } from '@webapp/state-models';
import { useLoadPhotos } from '@webapp/webapp/hooks';
import { useUploadToS3, UseUploadToS3Params } from '@webapp/hooks';
import { nanoid } from 'nanoid';
import {
  useEffect,
  useState,
  useCallback,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { FaSave } from 'react-icons/fa';
import { getProxyPhotoUrl } from '@webapp/utils';

interface CreativeEditorEditableImageProps {
  photo?: Photo | null;
  config?: Configuration;
  assetsFilter?: Photo_Bool_Exp;
  onSaveCallback?: (photo: Photo) => void;
  hideToolbar?: boolean;
  editorStyle?: BoxProps;
}

type CreativeEditorEditableImageHandle = {
  saveCurrentImage: (
    params?: UseUploadToS3Params
  ) => Promise<Photo | undefined>;
};

export const CreativeEditorEditableImage = forwardRef<
  CreativeEditorEditableImageHandle,
  CreativeEditorEditableImageProps
>(
  (
    {
      photo,
      config,
      assetsFilter,
      hideToolbar,
      editorStyle,
      onSaveCallback,
    }: CreativeEditorEditableImageProps,
    ref
  ) => {
    const editorId = `editor-${nanoid()}`;
    const { workspace, draftPhotos } = useStores();
    const [editor, setEditor] = useState<CreativeEditorSDK>();
    const [progress, setProgress] = useState(0);
    const { findAssets } = useLoadPhotos();
    const [insertPhoto] = useInsertPhotoMutation({
      refetchQueries: ['listPhotos'],
    });

    const { uploadToS3 } = useUploadToS3();

    const onSave = useCallback(
      async (params?: UseUploadToS3Params): Promise<Photo | undefined> => {
        if (!editor) return;

        const imageSrc = await editor.export({
          mimeType: 'image/jpeg' as any,
        });

        const fileName = photo?.file?.filePath ?? `${nanoid()}.jpeg`;

        const filePath = await uploadToS3({
          fileType: 'image/jpeg',
          fileContents: new File(imageSrc.blobs, fileName),
          filePath: fileName,
          storageType: StorageType.Private,
          onProgress: setProgress,
          ...params,
        });

        let photoToSet: Photo | null = null;

        if (photo) {
          await photo.update({ hasThumbnail: false });
          await photo.refresh();
          photoToSet = photo;
        } else {
          const newPhoto = await insertPhoto({
            variables: {
              photo: {
                filePath,
                workspaceId: workspace?.id,
              },
            },
          });

          const insertedPhoto = newPhoto.data?.insert_photo?.returning[0];

          if (insertedPhoto) {
            photoToSet = PhotoModel.create(insertedPhoto);
          }
        }

        if (!photoToSet) throw new Error('Photo not set');

        if (onSaveCallback) {
          onSaveCallback(photoToSet);
        }

        return photoToSet;
      },
      [editor, photo, onSaveCallback]
    );

    const initEditor = async () => {
      if (editor) return;

      const cesdk = await CreativeEditorSDK.init(`#${editorId}`, {
        baseURL: '/cesdk',
        callbacks: {
          onUpload: 'local',
        },
        assetSources: {
          photos: {
            findAssets(_, queryData) {
              return findAssets(queryData, assetsFilter);
            },
          },
        },
        ...(photo && {
          initialImageURL: getProxyPhotoUrl(photo?.file?.url, 'full'),
        }),
        locale: 'en',
        theme: 'light',
        role: 'Creator',
        ...config,
        license: import.meta.env.VITE_IMGLY_CE_LICENSE || '',
      });

      setEditor(cesdk);
    };

    useEffect(() => {
      if (editor) return;
      initEditor();
    }, [editor]);

    useEffect(() => {
      if (!editor) return;

      if (!draftPhotos.length) return;

      const placeholders = editor.engine.block.findAllPlaceholders();

      if (!placeholders.length) return;

      placeholders.forEach((placeholder, index) => {
        const photoAtIndex = draftPhotos[index];
        if (photoAtIndex) {
          editor.engine.block.setString(
            placeholder,
            'image/imageFileURI',
            photoAtIndex.file?.url ?? ''
          );

          editor.engine.block.setContentFillMode(placeholder, 'Cover');
        }
      });
    }, [draftPhotos, editor]);

    useImperativeHandle(
      ref,
      () => ({
        async saveCurrentImage(params) {
          return onSave(params);
        },
      }),
      [onSave]
    );

    return (
      <Stack overflow="hidden" h={'full'}>
        {!hideToolbar && (
          <Flex w="90%" justifyContent="flex-end" pt={2}>
            <Tooltip label="Save edits you made to image">
              <IconButton
                aria-label="save image"
                icon={<FaSave />}
                onClick={() => onSave()}
              ></IconButton>
            </Tooltip>
          </Flex>
        )}
        <Box id={editorId} width="full" h={'75vh'} {...editorStyle} />
      </Stack>
    );
  }
);

export default CreativeEditorEditableImage;
