import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type SearchDeviceTypesQueryVariables = Types.Exact<{
  searchValue: Types.Scalars['String'];
  limit?: Types.Maybe<Types.Scalars['Int']>;
}>;


export type SearchDeviceTypesQuery = { __typename?: 'query_root', deviceType: Array<{ __typename?: 'deviceType', id: any, value: string }> };


export const SearchDeviceTypesDocument = gql`
    query searchDeviceTypes($searchValue: String!, $limit: Int) {
  deviceType(where: {value: {_ilike: $searchValue}}) {
    id
    value
  }
}
    `;

/**
 * __useSearchDeviceTypesQuery__
 *
 * To run a query within a React component, call `useSearchDeviceTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchDeviceTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchDeviceTypesQuery({
 *   variables: {
 *      searchValue: // value for 'searchValue'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useSearchDeviceTypesQuery(baseOptions: Apollo.QueryHookOptions<SearchDeviceTypesQuery, SearchDeviceTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchDeviceTypesQuery, SearchDeviceTypesQueryVariables>(SearchDeviceTypesDocument, options);
      }
export function useSearchDeviceTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchDeviceTypesQuery, SearchDeviceTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchDeviceTypesQuery, SearchDeviceTypesQueryVariables>(SearchDeviceTypesDocument, options);
        }
export type SearchDeviceTypesQueryHookResult = ReturnType<typeof useSearchDeviceTypesQuery>;
export type SearchDeviceTypesLazyQueryHookResult = ReturnType<typeof useSearchDeviceTypesLazyQuery>;
export type SearchDeviceTypesQueryResult = Apollo.QueryResult<SearchDeviceTypesQuery, SearchDeviceTypesQueryVariables>;