import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type ConsentAggregateQueryVariables = Types.Exact<{
  where?: Types.Maybe<Types.Consent_Bool_Exp>;
}>;


export type ConsentAggregateQuery = { __typename?: 'query_root', consent_aggregate: { __typename?: 'consent_aggregate', aggregate?: { __typename?: 'consent_aggregate_fields', count: number } | null | undefined } };


export const ConsentAggregateDocument = gql`
    query consentAggregate($where: consent_bool_exp) {
  consent_aggregate(where: $where) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useConsentAggregateQuery__
 *
 * To run a query within a React component, call `useConsentAggregateQuery` and pass it any options that fit your needs.
 * When your component renders, `useConsentAggregateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConsentAggregateQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useConsentAggregateQuery(baseOptions?: Apollo.QueryHookOptions<ConsentAggregateQuery, ConsentAggregateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ConsentAggregateQuery, ConsentAggregateQueryVariables>(ConsentAggregateDocument, options);
      }
export function useConsentAggregateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ConsentAggregateQuery, ConsentAggregateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ConsentAggregateQuery, ConsentAggregateQueryVariables>(ConsentAggregateDocument, options);
        }
export type ConsentAggregateQueryHookResult = ReturnType<typeof useConsentAggregateQuery>;
export type ConsentAggregateLazyQueryHookResult = ReturnType<typeof useConsentAggregateLazyQuery>;
export type ConsentAggregateQueryResult = Apollo.QueryResult<ConsentAggregateQuery, ConsentAggregateQueryVariables>;