import {
  Box,
  Circle,
  Flex,
  Grid,
  Stack,
  StyleProps,
  Icon,
  Button,
  useBreakpointValue,
  useBoolean,
} from '@chakra-ui/react';
import { NavItem } from '@webapp/ui';
import { BiBuoy, BiChat, BiCog, BiHome, BiPhotoAlbum } from 'react-icons/bi';
import { Link as ReactLink, useLocation } from 'react-router-dom';
import { useFlagsmith } from 'flagsmith-react';
import {
  FaChartLine,
  FaFlask,
  FaFunnelDollar,
  FaMoneyBillAlt,
  FaPrescription,
  FaRegCalendar,
  FaRegEnvelope,
  FaRegStar,
  FaRegThumbsUp,
  FaUserFriends,
} from 'react-icons/fa';
import './main-side-bar.module.scss';
import { useCallback, useRef } from 'react';
import { useStores } from '@webapp/state-models';
import { useCustomPermissions } from '@webapp/hooks';
import { MenuLinks } from './menu-links';

export * from './menu-links';

const sidebarContainerStyles: StyleProps = {
  bg: '#fbfafa',
  color: '#fff',
  height: 'full',
  fontSize: 'sm',
  padding: '4px 4px 4px 4px',
};

export function MainSideBar() {
  const location = useLocation();
  const { hasPermission } = useCustomPermissions();
  const { hasFeature } = useFlagsmith();
  const timerRef = useRef<any>(null);
  const [minimized, setMinimized] = useBoolean(true);
  const { user } = useStores();
  const role = user?.userWorkspaces[0]?.role;

  const LINKS = [
    {
      canRender: true,
      pathname: '/',
      label: 'Home',
      icon: BiHome,
    },
    {
      canRender: hasFeature('patients') && role !== 'bookkeeper',
      pathname: '/patients',
      label: 'Patients',
      icon: FaUserFriends,
    },
    {
      canRender: hasFeature('prescriptions') && role !== 'bookkeeper',
      pathname: '/prescriptions',
      label: 'Prescriptions',
      icon: FaPrescription,
    },
    {
      canRender: hasFeature('schedule') && role !== 'bookkeeper',
      pathname: '/schedule',
      label: 'Schedule',
      icon: FaRegCalendar,
    },
    {
      canRender: hasFeature('labs') && role !== 'bookkeeper',
      pathname: '/labs',
      label: 'Labs',
      icon: FaFlask,
    },
    {
      canRender: hasFeature('media_archive') && role !== 'bookkeeper',
      pathname: '/media',
      label: 'Media Archive',
      icon: BiPhotoAlbum,
    },
    {
      canRender: hasFeature('social_media') && role !== 'bookkeeper',
      pathname: '/social-media',
      label: 'Social Media',
      icon: FaRegThumbsUp,
    },
    {
      canRender: hasFeature('email_marketing') && role !== 'bookkeeper',
      pathname: '/email-marketing',
      label: 'Direct Marketing',
      icon: FaRegEnvelope,
    },

    {
      canRender: hasFeature('lead_list') && role !== 'bookkeeper',
      pathname: '/leads',
      label: 'Opportunities',
      icon: FaFunnelDollar,
    },

    {
      canRender: hasFeature('online_reputation') && role !== 'bookkeeper',
      pathname: '/reviews',
      label: 'Online Reputation',
      icon: FaRegStar,
    },

    {
      canRender: hasFeature('reports') && role !== 'bookkeeper',
      pathname: '/reports/financial',
      label: 'Reports',
      icon: FaChartLine,
    },
    {
      canRender: hasFeature('invoices') && hasPermission('viewInvoice'),
      pathname: '/invoices',
      label: 'Invoices',
      icon: FaMoneyBillAlt,
    },
    {
      canRender: hasFeature('conversations'),
      pathname: '/conversations',
      label: 'Conversations',
      icon: BiChat,
    },
  ];

  const featureLinks = LINKS.filter((link) => link.canRender);

  const onMouseEnter = useCallback(() => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    setMinimized.off();
  }, []);

  const onMouseLeave = useCallback(() => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    timerRef.current = setTimeout(() => {
      setMinimized.on();
    }, 500);
  }, []);

  return (
    <Box
      width={minimized ? '60px' : '260px'}
      h={'full'}
      transition="all 0.3s"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <Flex
        bg="red.200"
        display={['none', 'none', 'flex', 'flex', 'flex', 'flex']}
        direction="column"
        zIndex={10}
        {...sidebarContainerStyles}
      >
        <MenuLinks minimized={minimized} />
      </Flex>
    </Box>
  );
}

export default MainSideBar;
