import { useEffect, useState } from 'react';
import {
  HStack,
  Stack,
  Box,
  Image,
  SimpleGrid,
  Heading,
  Skeleton,
  Tooltip,
  Button,
  Flex,
  useBreakpointValue,
} from '@chakra-ui/react';
import { Photo, useStores } from '@webapp/state-models';
import { Patient } from '@webapp/graphql';
import { QuickPatientForm, SimpleVideo } from '@webapp/ui';
import { observer } from 'mobx-react-lite';
import toast from 'react-hot-toast';
import { getProxyPhotoUrl } from '@webapp/utils';
import EditableImage from '../editable-image/editable-image';
import ConfigurePhotoForm from './configure-photo-form';
/* eslint-disable-next-line */
export interface ConfigurePhotoDetailsProps {
  photo: Photo;
}

export const ConfigurePhotoDetails = observer(
  ({ photo }: ConfigurePhotoDetailsProps) => {
    const isMobile = useBreakpointValue({ base: true, md: false });
    const [creatingPatient, setCreatingPatient] = useState(false);
    const [multiSelectedPhotos, setMultiSelectedPhotos] = useState<Photo[]>([]);
    const { draftPhotos, setCurrentDraftPhotoIndex, currentDraftPhotoIndex } =
      useStores();

    function toggleSelectAllPhotos() {
      if (multiSelectedPhotos.length === 0) {
        setMultiSelectedPhotos(draftPhotos);
      } else {
        setMultiSelectedPhotos([]);
      }
    }

    useEffect(() => {
      if (draftPhotos.length > 1) {
        setMultiSelectedPhotos(draftPhotos);
      }
    }, [draftPhotos]);

    async function patientCreated(patient: Patient) {
      if (multiSelectedPhotos && multiSelectedPhotos.length > 1) {
        multiSelectedPhotos.map((p) => p.updatePatient(patient));
        toast.success(`Set patient for ${multiSelectedPhotos.length} photos.`);
      } else {
        photo.updatePatient(patient);
      }
      await photo.updatePatient(patient);
      setCreatingPatient(false);
    }

    function handleImageClick(
      e: React.MouseEvent<HTMLImageElement>,
      index: number
    ) {
      if (e.shiftKey) {
        if (multiSelectedPhotos.length === 0) {
          const selected = [draftPhotos[currentDraftPhotoIndex]];

          if (currentDraftPhotoIndex !== index) {
            selected.push(draftPhotos[index]);
          }
          setMultiSelectedPhotos(selected);
        } else if (
          multiSelectedPhotos.find((p) => p.id === draftPhotos[index].id)
        ) {
          setMultiSelectedPhotos(
            multiSelectedPhotos.filter((p) => p.id !== draftPhotos[index].id)
          );
        } else {
          setMultiSelectedPhotos([...multiSelectedPhotos, draftPhotos[index]]);
        }
      } else {
        setMultiSelectedPhotos([]);
        setCurrentDraftPhotoIndex(index);
      }
    }

    if (creatingPatient) {
      return (
        <QuickPatientForm
          onCreatePatient={patientCreated}
          onClose={() => setCreatingPatient(false)}
        />
      );
    }

    return (
      <Flex
        justifyContent={'flex-start'}
        alignItems={'flex-start'}
        direction={{ base: 'column', md: 'row' }}
      >
        <Flex
          h={{ base: '44px', md: '80vh' }}
          overflowY="auto"
          px={2}
          alignItems={'center'}
          direction={{ base: 'row', md: 'column' }}
        >
          {draftPhotos.length > 1 && (
            <>
              <Button onClick={toggleSelectAllPhotos} mb={2}>
                {multiSelectedPhotos.length > 1 ? 'Deselect All' : 'Select all'}
              </Button>
              {draftPhotos.map((draftPhoto, index) => (
                <Tooltip
                  openDelay={300}
                  label={
                    index === currentDraftPhotoIndex ||
                    !!multiSelectedPhotos.find((p) => p.id === draftPhoto.id)
                      ? 'Click to edit'
                      : 'Shift+Click to select multiple photos'
                  }
                >
                  <Image
                    className="ph-no-capture fs-exclude"
                    onClick={(e) => handleImageClick(e, index)}
                    fallback={<Skeleton />}
                    onShift
                    border={
                      index === currentDraftPhotoIndex ||
                      !!multiSelectedPhotos.find((p) => p.id === draftPhoto.id)
                        ? '4px'
                        : '0px'
                    }
                    borderColor="blue.400"
                    rounded="md"
                    objectFit={'cover'}
                    w={20}
                    h={20}
                    mb={2}
                    cursor={'pointer'}
                    _hover={{ shadow: 'md' }}
                    src={getProxyPhotoUrl(
                      draftPhoto.file?.thumbnailUrl || draftPhoto.file?.url,
                      'full'
                    )}
                  />
                </Tooltip>
              ))}
            </>
          )}
        </Flex>
        <Stack flex={2}>
          {multiSelectedPhotos.length > 1 ? (
            <Stack p={4} border="1px" borderColor="gray.200" h={'80vh'}>
              <Heading size={'md'}>
                You've selected multiple photos, you can set the patient or tag
                for all of them at once.
              </Heading>
              <SimpleGrid columns={4} spacing={10} pt={4}>
                {multiSelectedPhotos.map((p) => (
                  <Image
                    className="ph-no-capture fs-exclude"
                    cursor={'pointer'}
                    objectFit="cover"
                    src={getProxyPhotoUrl(p.file?.url)}
                    h={200}
                    w={200}
                    onClick={(e) =>
                      handleImageClick(
                        e,
                        draftPhotos.findIndex((ph) => p.id === ph.id)
                      )
                    }
                  />
                ))}
              </SimpleGrid>
            </Stack>
          ) : photo?.mimeType && photo?.mimeType.includes('video') ? (
            <SimpleVideo src={photo?.file?.url} maxH="80vh" />
          ) : (
            <EditableImage photo={photo} width={isMobile ? '90vw' : 'full'} />
          )}
        </Stack>
        <Box p={4} border="1px" borderColor="gray.200" h={'80vh'}>
          <ConfigurePhotoForm
            onCreatePatient={() => setCreatingPatient(true)}
            photo={photo}
            multiplePhotos={multiSelectedPhotos}
          />
        </Box>
      </Flex>
    );
  }
);

export default ConfigurePhotoDetails;
