import { ComponentCollection, SvgRegistry, Serializer } from 'survey-core';
import { registerCustomMediaConsent } from '../../../../webapp/ui-composites/src';

export default function configureSurveyJSConsentComponents() {
  try {
    ComponentCollection.Instance.clear();
    SvgRegistry.registerIconFromSvg(
      'document-text',
      `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#909090" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
            </svg>`
    );
    registerCustomMediaConsent();
    SvgRegistry.registerIconFromSvg(
      'person',
      `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#909090" class="w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" />
          </svg>`
    );

    ComponentCollection.Instance.add({
      // A unique name; must use lowercase
      name: 'initials',
      // A display name used in the Toolbox
      title: 'Initials',
      iconName: 'icon-signaturepad',
      onCreated: (component) => {
        component.titleLocation = 'hidden';
      },
      // An array of JSON schemas that configure the nested questions
      elementsJSON: [
        {
          type: 'signaturepad',
          signatureWidth: 100,
          signatureHeight: 100,
        },
      ],
    });

    ComponentCollection.Instance.add({
      // A unique name; must use lowercase
      name: 'patient-name',
      // A display name used in the Toolbox
      title: 'Patient Name',
      iconName: 'icon-person',

      questionJSON: {
        type: 'text',
        defaultValueExpression: '{patientName}',
      },
    });

    ComponentCollection.Instance.add({
      // A unique name; must use lowercase
      name: 'media-consent',
      // A display name used in the Toolbox
      title: 'Media Consent',
      iconName: 'icon-image',

      onInit() {
        [
          'FULL_CONSENT',
          'FULL_ANONYMIZED_CONSENT',
          'CLINICAL_CONSENT',
          'NO_CONSENT',
        ].forEach((consentType) => {
          Serializer.addProperty('media-consent', {
            name: `SHOW_${consentType}`,
            displayName: `Show ${consentType.toLowerCase().replace(/_/g, ' ')}`,
            type: 'boolean',
            default: true,
            category: 'general',
          });
        });
      },

      onLoaded(question) {
        this.updateVisibleChoices(question);
      },

      onPropertyChanged(question, propertyName, newValue) {
        if (propertyName.startsWith('SHOW_')) {
          this.updateVisibleChoices(question);
        }
      },

      updateVisibleChoices(question) {
        const radioGroup = question.contentPanel.getQuestionByName(
          'patient-media-consent-type'
        );
        if (radioGroup) {
          const allChoices = this.elementsJSON[0].choices;
          const visibleChoices = allChoices.filter(
            (choice) => question[`SHOW_${choice.value}`] !== false
          );

          radioGroup.choices = visibleChoices;

          // If the current value is not in the visible choices, clear it
          if (
            radioGroup.value &&
            !visibleChoices.some((c) => c.value === radioGroup.value)
          ) {
            radioGroup.clearValue();
          }
        }
      },

      // An array of JSON schemas that configure the nested questions
      elementsJSON: [
        {
          type: 'radiogroup',
          name: 'patient-media-consent-type',
          title: {
            default: 'Media Consent',
            es: 'Consentimiento de los medios',
          },
          choices: [
            {
              value: 'FULL_CONSENT',
              text: {
                default: `I hereby grant {workspaceName} full consent to use, reproduce, distribute, publicly display, and perform my media, including my name and likeness, for any purpose, including but not limited to educational, promotional, advertising, and testimonial purposes, in any media formats and through any media channels.`,
                es: 'Por la presente otorgo a {workspaceName} pleno consentimiento para utilizar, reproducir, distribuir, mostrar públicamente y ejecutar mis medios, incluyendo mi nombre y semejanza, para cualquier fin, incluyendo pero no limitado a propósitos educativos, promocionales, publicitarios y testimoniales, en cualquier formato y a través de cualquier canal de medios.',
              },
            },
            {
              value: 'FULL_ANONYMIZED_CONSENT',
              text: {
                default: `I hereby grant {workspaceName} consent to use, reproduce, distribute, publicly display, and perform my media, without disclosing my name or any other identifying information, for any purpose, including but not limited to educational, promotional, advertising, and testimonial purposes, in any media formats and through any media channels.`,
                es: 'Por la presente otorgo a {workspaceName} consentimiento para utilizar, reproducir, distribuir, mostrar públicamente y ejecutar mis medios, sin revelar mi nombre u otra información identificativa, para cualquier fin, incluyendo pero no limitado a propósitos educativos, promocionales, publicitarios y testimoniales, en cualquier formato y a través de cualquier canal de medios.',
              },
            },
            {
              value: 'CLINICAL_CONSENT',
              text: {
                default: `I hereby grant {workspaceName} consent to use, reproduce, distribute, publicly display, and perform my media strictly for clinical and educational purposes. My media may be used internally or in educational settings without disclosing my name or any other identifying information.`,
                es: 'Por la presente, otorgo a {workspaceName} el consentimiento para usar, reproducir, distribuir, exhibir públicamente y realizar mi material estrictamente con fines clínicos y educativos. Mi material puede ser utilizado internamente o en entornos educativos sin revelar mi nombre ni ninguna otra información identificativa.',
              },
            },
            {
              value: 'NO_CONSENT',
              text: {
                default: `I do not grant {workspaceName} consent to use, reproduce, distribute, publicly display, or perform my media in any capacity.`,
                es: 'No otorgo consentimiento a {workspaceName} para utilizar, reproducir, distribuir, mostrar públicamente o ejecutar mis medios en ninguna capacidad.',
              },
            },
          ],
        },
      ],
    });
  } catch (err) {
    console.debug(err);
  }
}
