import { CircularProgress, HStack, Text } from '@chakra-ui/react';
import { PatientConsentFieldsFragment } from '@webapp/graphql';

export default function ConsentProgress({
  patientConsent,
}: {
  patientConsent: PatientConsentFieldsFragment;
}) {
  let requiredSignatures = 1;
  let existingSignatures = 0;
  const missingSignatures = [];
  const surveyResponse = patientConsent.surveyJSResponseJSON
    ? patientConsent.surveyJSResponseJSON
    : {};
  const signatureTypes = Object.values(surveyResponse).map(
    (question: any) => question?.signatureType
  );

  if (patientConsent?.consent?.requireProviderSignature) {
    requiredSignatures += 1;
    if (
      patientConsent.providerSignature ||
      signatureTypes.includes('Provider')
    ) {
      existingSignatures += 1;
    } else {
      missingSignatures.push('provider');
    }
  }
  if (patientConsent?.consent?.requireWitnessSignature) {
    requiredSignatures += 1;
    if (patientConsent.witnessSignature || signatureTypes.includes('Witness')) {
      existingSignatures += 1;
    } else {
      missingSignatures.push('witness');
    }
  }

  if (patientConsent?.signature || signatureTypes.includes('Patient')) {
    existingSignatures += 1;
  } else {
    missingSignatures.push('patient');
  }

  return (
    <HStack>
      {missingSignatures.length > 0 && (
        <CircularProgress
          value={(existingSignatures / requiredSignatures) * 100}
          size="25px"
          thickness="15px"
        />
      )}
      <Text>
        {missingSignatures.length > 0
          ? `Need signatures from: ${missingSignatures.join(', ')}`
          : '✅ Complete'}
      </Text>
    </HStack>
  );
}
