import * as Types from '../../generated/graphql';

import { gql } from '@apollo/client';
export type PurchaseOrderFieldsFragment = { __typename?: 'purchaseOrder', id: any, orderNumber: string, vendorOrderNumber?: string | null | undefined, note?: string | null | undefined, status: string, cost: number, createdAt: any, updatedAt: any, archived: boolean, consumableType: { __typename?: 'consumableType', id: any, name: string }, consumableBrand: { __typename?: 'consumableBrand', id: any, name: string }, consumables: Array<{ __typename?: 'consumable', id: any, cost: number, quantity: any, originalQuantity: any, metadata: any, type: { __typename?: 'consumableType', id: any, name: string }, brand?: { __typename?: 'consumableBrand', id: any, name: string } | null | undefined }> };

export const PurchaseOrderFieldsFragmentDoc = gql`
    fragment PurchaseOrderFields on purchaseOrder {
  id
  orderNumber
  vendorOrderNumber
  note
  status
  cost
  consumableType {
    id
    name
  }
  consumableBrand {
    id
    name
  }
  consumables {
    id
    cost
    quantity
    originalQuantity
    metadata
    type {
      id
      name
    }
    brand {
      id
      name
    }
  }
  createdAt
  updatedAt
  archived
}
    `;