import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { PatientConversationFieldsFragmentDoc } from '../../fragments/patientConversationFields.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type ListPatientConversationsQueryVariables = Types.Exact<{
  where: Types.PatientConversation_Bool_Exp;
  limit?: Types.Maybe<Types.Scalars['Int']>;
  offset?: Types.Maybe<Types.Scalars['Int']>;
  orderBy?: Types.Maybe<Array<Types.PatientConversation_Order_By> | Types.PatientConversation_Order_By>;
}>;


export type ListPatientConversationsQuery = { __typename?: 'query_root', patientConversation: Array<{ __typename?: 'patientConversation', id: any, category?: string | null | undefined, patientId: any, patientWorkspaceId: any, lastPatientMessageReceived?: any | null | undefined, lastUserMessageSent?: any | null | undefined, hasUnreadMessages?: boolean | null | undefined, twilioConversationSid: string, participantIdentityOverride?: string | null | undefined, patientWorkspace: { __typename?: 'patientWorkspace', id: any, firstName?: string | null | undefined, lastName?: string | null | undefined, phoneNumber?: string | null | undefined } }> };


export const ListPatientConversationsDocument = gql`
    query ListPatientConversations($where: patientConversation_bool_exp!, $limit: Int, $offset: Int, $orderBy: [patientConversation_order_by!]) {
  patientConversation(
    distinct_on: [lastPatientMessageReceived, patientWorkspaceId]
    where: $where
    limit: $limit
    offset: $offset
    order_by: $orderBy
  ) {
    ...PatientConversationFields
  }
}
    ${PatientConversationFieldsFragmentDoc}`;

/**
 * __useListPatientConversationsQuery__
 *
 * To run a query within a React component, call `useListPatientConversationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListPatientConversationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListPatientConversationsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useListPatientConversationsQuery(baseOptions: Apollo.QueryHookOptions<ListPatientConversationsQuery, ListPatientConversationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListPatientConversationsQuery, ListPatientConversationsQueryVariables>(ListPatientConversationsDocument, options);
      }
export function useListPatientConversationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListPatientConversationsQuery, ListPatientConversationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListPatientConversationsQuery, ListPatientConversationsQueryVariables>(ListPatientConversationsDocument, options);
        }
export type ListPatientConversationsQueryHookResult = ReturnType<typeof useListPatientConversationsQuery>;
export type ListPatientConversationsLazyQueryHookResult = ReturnType<typeof useListPatientConversationsLazyQuery>;
export type ListPatientConversationsQueryResult = Apollo.QueryResult<ListPatientConversationsQuery, ListPatientConversationsQueryVariables>;