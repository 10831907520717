import { InputGroup } from '@chakra-ui/react';
import { FormInputProps } from '@webapp/types';
import { Controller, useFormContext } from 'react-hook-form';
import ColorInput from '../color-input/color-input';
import InputWrapper from '../InputWrapper/InputWrapper';

function FormColorInput(props: FormInputProps) {
  const { control, setValue } = useFormContext();

  const { name, ...formInputProps } = props;

  return (
    <InputWrapper name={name} {...formInputProps}>
      <InputGroup>
        <Controller
          control={control}
          name={name}
          render={({ field: { value, ref }, formState: { errors } }) => (
            <ColorInput
              key={`color-${value}`}
              onChange={(v) => setValue(name, v)}
              value={value}
            />
          )}
        />
      </InputGroup>
    </InputWrapper>
  );
}

export default FormColorInput;
