import { ViewIcon } from '@chakra-ui/icons';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  Image,
  Box,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  IconButton,
  SimpleGrid,
  Text,
} from '@chakra-ui/react';

import { FaRegImages } from 'react-icons/fa';
import { useMemo } from 'react';

function PhotosTakenModal({ photos }: { photos: string[] }) {
  const columns = useMemo(() => {
    const count = photos.length;
    return {
      base: Math.min(1, count),
      md: Math.min(2, count),
      lg: Math.min(3, count),
      xl: Math.min(3, count),
    };
  }, [photos]);

  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <IconButton
        icon={
          <Box position="relative">
            <FaRegImages color="white" />
            {photos.length > 0 && (
              <Box
                position="absolute"
                top="-15px"
                right="-15px"
                px="2"
                py="1"
                fontSize="xs"
                fontWeight="bold"
                color="white"
                bg="red.500"
                borderRadius="full"
              >
                {photos.length}
              </Box>
            )}
          </Box>
        }
        variant="outline"
        onClick={onOpen}
        aria-label="View photos"
      ></IconButton>

      <Modal isOpen={isOpen} onClose={onClose} size="full">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Photos Taken</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text pb="4">
              {photos.length} photos taken and saved so far during this session.
            </Text>
            <SimpleGrid
              columns={columns}
              columnGap={{ base: '4', md: '6' }}
              rowGap={{ base: '8', md: '10' }}
            >
              {photos.map((photo) => (
                <Image src={photo} />
              ))}
            </SimpleGrid>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default PhotosTakenModal;
