import React from 'react';
import { Button, Box, Grid, GridProps } from '@chakra-ui/react';
import { SubmitHandler } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import { ObjectSchema } from 'joi';
import { useHookForm } from '@webapp/hooks';
import { capitalize } from 'lodash';
import { CompoundControlledFormInputProps } from '../FormInputV2/types';
import FormInputV2 from '../FormInputV2';

interface GenericFormProps {
  defaultValues?: any;
  fields: GenericFormField[];
  layout?: GridProps;
  onSubmit: SubmitHandler<any>;
}

interface GenericFormField
  extends Omit<CompoundControlledFormInputProps, 'control'> {
  gridArea?: string;
}

interface GenericFormProps {
  defaultValues?: any;
  fields: GenericFormField[];
  layout?: GridProps;
  onSubmit: SubmitHandler<any>;
  shouldResetOnSubmit?: boolean;
  validationSchema?: ObjectSchema;
}

export const GenericForm: React.FC<GenericFormProps> = ({
  defaultValues,
  fields,
  layout,
  onSubmit,
  shouldResetOnSubmit = false,
  validationSchema,
}) => {
  const gap = layout?.gap || '20px';
  const autoFlow = layout?.autoFlow || 'row';
  const templateAreas = layout?.templateAreas;
  const templateColumns = layout?.templateColumns || '1fr';
  const templateRows = layout?.templateRows;

  const form = useHookForm({
    defaultValues,
    onSubmit,
    shouldResetOnSubmit,
    ...(validationSchema && { resolver: joiResolver(validationSchema) }),
  });

  return (
    <Box as="form" onSubmit={form.handleSubmit(onSubmit)}>
      <Grid
        autoFlow={autoFlow}
        gap={gap}
        gridTemplateColumns={templateColumns}
        gridTemplateAreas={templateAreas}
        gridTemplateRows={templateRows}
      >
        {fields.map(({ gridArea, ...field }) => (
          <Box
            key={`form-input-field-${field.name}`}
            {...(templateAreas && { gridArea })}
          >
            <FormInputV2 control={form.control} {...field} />
          </Box>
        ))}
        <Button
          {...(templateAreas && { gridArea: 'submit' })}
          mt={4}
          colorScheme="blue"
          type="submit"
        >
          Submit
        </Button>
      </Grid>
    </Box>
  );
};

export default GenericForm;
