import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { NoteFieldsFragmentDoc } from '../../fragments/noteFields.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetFollowUpNotesQueryVariables = Types.Exact<{
  parentNoteId: Types.Scalars['uuid'];
}>;


export type GetFollowUpNotesQuery = { __typename?: 'query_root', note: Array<{ __typename?: 'note', isLocked?: boolean | null | undefined, createdAt: any, title?: string | null | undefined, isGoodFaithExam?: boolean | null | undefined, id: any, isInternal: boolean, message: string, appointmentId?: any | null | undefined, updatedAt: any, author: { __typename?: 'user', firstName: string, id: any, lastName: string, title?: string | null | undefined, profilePicture?: string | null | undefined }, editedBy?: { __typename?: 'user', firstName: string, id: any, lastName: string, title?: string | null | undefined } | null | undefined, noteSignatures: Array<{ __typename?: 'noteSignature', signature?: { __typename?: 'signature', id: any, filePath: string, provider?: { __typename?: 'provider', firstName: string, id: any, lastName: string, title?: string | null | undefined } | null | undefined, file?: { __typename?: 'FileOnlyURL', url: string } | null | undefined } | null | undefined }>, assignedToProvider?: { __typename?: 'provider', id: any, firstName: string, lastName: string, title?: string | null | undefined } | null | undefined, patient: { __typename?: 'patient', firstName: string, id: any, lastName: string } }> };


export const GetFollowUpNotesDocument = gql`
    query GetFollowUpNotes($parentNoteId: uuid!) {
  note(where: {parentNoteId: {_eq: $parentNoteId}}) {
    ...NoteFields
  }
}
    ${NoteFieldsFragmentDoc}`;

/**
 * __useGetFollowUpNotesQuery__
 *
 * To run a query within a React component, call `useGetFollowUpNotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFollowUpNotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFollowUpNotesQuery({
 *   variables: {
 *      parentNoteId: // value for 'parentNoteId'
 *   },
 * });
 */
export function useGetFollowUpNotesQuery(baseOptions: Apollo.QueryHookOptions<GetFollowUpNotesQuery, GetFollowUpNotesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFollowUpNotesQuery, GetFollowUpNotesQueryVariables>(GetFollowUpNotesDocument, options);
      }
export function useGetFollowUpNotesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFollowUpNotesQuery, GetFollowUpNotesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFollowUpNotesQuery, GetFollowUpNotesQueryVariables>(GetFollowUpNotesDocument, options);
        }
export type GetFollowUpNotesQueryHookResult = ReturnType<typeof useGetFollowUpNotesQuery>;
export type GetFollowUpNotesLazyQueryHookResult = ReturnType<typeof useGetFollowUpNotesLazyQuery>;
export type GetFollowUpNotesQueryResult = Apollo.QueryResult<GetFollowUpNotesQuery, GetFollowUpNotesQueryVariables>;