import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { nanoid } from 'nanoid';

/**
 * A CustomFormStore model.
 */
export const CustomFormModel = types.model('CustomForm').props({
  id: types.optional(types.string, nanoid()),
  title: types.string,
  isRequired: types.boolean,
  allFieldsRequired: types.boolean,
  services: types.optional(
    types.array(
      types.model({
        label: types.string,
        value: types.string,
      })
    ),
    []
  ),
  surveyJSJSON: types.maybe(types.string),
  rows: types.array(
    types.model({
      index: types.number,
      replicable: types.maybe(types.boolean),
      fields: types.array(
        types.model({
          index: types.number,
          label: types.string,
          body: types.maybe(types.string),
          photo: types.maybe(
            types.model({
              file: types.model({
                url: types.string,
              }),
            })
          ),
          photoId: types.maybe(types.string),
          table: types.maybe(types.string),
          attribute: types.maybe(types.string),
          validation: types.maybe(types.frozen()),
          category: types.maybe(types.string),
          type: types.string,
          options: types.maybe(
            types.array(
              types.model({
                value: types.string,
              })
            )
          ),
        })
      ),
    })
  ),
});
/**
 * The CustomForm instance.
 */
export type CustomForm = Instance<typeof CustomFormModel>;

/**
 * The data of a CustomForm.
 */
export type CustomFormSnapshot = SnapshotOut<typeof CustomFormModel>;
