import {
  FieldErrors,
  FieldValues,
  SubmitErrorHandler,
  SubmitHandler,
  UseFormHandleSubmit,
} from 'react-hook-form';

interface UseEnhancedHandleSubmitProps<FormInputs extends FieldValues> {
  handleSubmit: UseFormHandleSubmit<FormInputs>;
  onError?: SubmitErrorHandler<FormInputs>;
  reset: () => void;
  shouldResetOnSubmit: boolean;
}

function useEnhancedHandleSubmit<FormInputs extends FieldValues>({
  handleSubmit,
  onError,
  reset,
  shouldResetOnSubmit,
}: UseEnhancedHandleSubmitProps<FormInputs>) {
  function enhancedHandleSubmit(
    onValid: SubmitHandler<FormInputs>,
    onInvalid?: SubmitErrorHandler<FormInputs>
  ) {
    function enhancedOnSubmit(formValues: FormInputs) {
      onValid(formValues);

      if (shouldResetOnSubmit) {
        reset();
      }
    }

    function enhancedOnError(formErrors: FieldErrors<FormInputs>) {
      if (onError) {
        onError(formErrors);
      }

      if (onInvalid) {
        onInvalid(formErrors);
      }
    }

    return handleSubmit(enhancedOnSubmit, enhancedOnError);
  }

  return enhancedHandleSubmit;
}

export default useEnhancedHandleSubmit;
