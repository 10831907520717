import * as Types from '../../generated/graphql';

import { gql } from '@apollo/client';
export type LeadTrackedEventFieldsFragment = { __typename?: 'leadTrackedEvent', id: any, outcome: string, type?: string | null | undefined, createdAt: any, updatedAt: any, user?: { __typename?: 'user', firstName: string, lastName: string } | null | undefined };

export const LeadTrackedEventFieldsFragmentDoc = gql`
    fragment LeadTrackedEventFields on leadTrackedEvent {
  id
  outcome
  type
  createdAt
  updatedAt
  user {
    firstName
    lastName
  }
}
    `;