import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type InsertEmailMarketingTagMutationVariables = Types.Exact<{
  marketingTagEmail: Types.MarketingTagEmail_Insert_Input;
}>;


export type InsertEmailMarketingTagMutation = { __typename?: 'mutation_root', insert_marketingTagEmail?: { __typename?: 'marketingTagEmail_mutation_response', returning: Array<{ __typename?: 'marketingTagEmail', id: any }> } | null | undefined };


export const InsertEmailMarketingTagDocument = gql`
    mutation insertEmailMarketingTag($marketingTagEmail: marketingTagEmail_insert_input!) {
  insert_marketingTagEmail(objects: [$marketingTagEmail]) {
    returning {
      id
    }
  }
}
    `;
export type InsertEmailMarketingTagMutationFn = Apollo.MutationFunction<InsertEmailMarketingTagMutation, InsertEmailMarketingTagMutationVariables>;

/**
 * __useInsertEmailMarketingTagMutation__
 *
 * To run a mutation, you first call `useInsertEmailMarketingTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertEmailMarketingTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertEmailMarketingTagMutation, { data, loading, error }] = useInsertEmailMarketingTagMutation({
 *   variables: {
 *      marketingTagEmail: // value for 'marketingTagEmail'
 *   },
 * });
 */
export function useInsertEmailMarketingTagMutation(baseOptions?: Apollo.MutationHookOptions<InsertEmailMarketingTagMutation, InsertEmailMarketingTagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertEmailMarketingTagMutation, InsertEmailMarketingTagMutationVariables>(InsertEmailMarketingTagDocument, options);
      }
export type InsertEmailMarketingTagMutationHookResult = ReturnType<typeof useInsertEmailMarketingTagMutation>;
export type InsertEmailMarketingTagMutationResult = Apollo.MutationResult<InsertEmailMarketingTagMutation>;
export type InsertEmailMarketingTagMutationOptions = Apollo.BaseMutationOptions<InsertEmailMarketingTagMutation, InsertEmailMarketingTagMutationVariables>;