import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { ConsumableSubtypeFieldsFragmentDoc } from '../../fragments/consumableSubtypeFields.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetConsumableSubtypesQueryVariables = Types.Exact<{
  where?: Types.Maybe<Types.ConsumableSubtype_Bool_Exp>;
  limit?: Types.Maybe<Types.Scalars['Int']>;
  offset?: Types.Maybe<Types.Scalars['Int']>;
  orderBy?: Types.Maybe<Array<Types.ConsumableSubtype_Order_By> | Types.ConsumableSubtype_Order_By>;
}>;


export type GetConsumableSubtypesQuery = { __typename?: 'query_root', consumableSubtype: Array<{ __typename?: 'consumableSubtype', id: any, name: string, createdAt: any, updatedAt: any }> };


export const GetConsumableSubtypesDocument = gql`
    query GetConsumableSubtypes($where: consumableSubtype_bool_exp, $limit: Int, $offset: Int, $orderBy: [consumableSubtype_order_by!]) {
  consumableSubtype(
    where: $where
    limit: $limit
    offset: $offset
    order_by: $orderBy
  ) {
    ...ConsumableSubtypeFields
  }
}
    ${ConsumableSubtypeFieldsFragmentDoc}`;

/**
 * __useGetConsumableSubtypesQuery__
 *
 * To run a query within a React component, call `useGetConsumableSubtypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetConsumableSubtypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetConsumableSubtypesQuery({
 *   variables: {
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetConsumableSubtypesQuery(baseOptions?: Apollo.QueryHookOptions<GetConsumableSubtypesQuery, GetConsumableSubtypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetConsumableSubtypesQuery, GetConsumableSubtypesQueryVariables>(GetConsumableSubtypesDocument, options);
      }
export function useGetConsumableSubtypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetConsumableSubtypesQuery, GetConsumableSubtypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetConsumableSubtypesQuery, GetConsumableSubtypesQueryVariables>(GetConsumableSubtypesDocument, options);
        }
export type GetConsumableSubtypesQueryHookResult = ReturnType<typeof useGetConsumableSubtypesQuery>;
export type GetConsumableSubtypesLazyQueryHookResult = ReturnType<typeof useGetConsumableSubtypesLazyQuery>;
export type GetConsumableSubtypesQueryResult = Apollo.QueryResult<GetConsumableSubtypesQuery, GetConsumableSubtypesQueryVariables>;