import { Button, IconButton, useBreakpointValue } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import { BiBuoy } from 'react-icons/bi';
import { useStores } from '@webapp/state-models';

/* eslint-disable-next-line */
export interface SupportButtonProps {}

export function SupportButton(props: SupportButtonProps) {
  const [chatOpen, setChatOpen] = useState(false);
  const { workspace } = useStores();

  const isMobile = useBreakpointValue({ base: true, md: false });
  const toggleChat = () => {
    if (workspace?.organization?.organizationConfiguration?.supportEmail) {
      window.open(
        `mailto:${workspace.organization.organizationConfiguration.supportEmail}`,
        '_blank'
      );
      return;
    }

    if (chatOpen) {
      window.Intercom('hide');
    } else {
      window.Intercom('show');
    }
    setChatOpen(!chatOpen);
  };

  useEffect(() => {
    window?.Intercom?.('onHide', () => {
      setChatOpen(false);
    });
  }, []);

  if (isMobile) {
    return (
      <IconButton
        variant="ghost"
        colorScheme="teal"
        onClick={toggleChat}
        aria-label="Toggle Help Chat"
        icon={<BiBuoy />}
      />
    );
  }

  return (
    <Button
      onClick={toggleChat}
      color={'brand.darkGray'}
      colorScheme="teal"
      variant="ghost"
      leftIcon={chatOpen ? <AiOutlineClose /> : <BiBuoy />}
    >
      {chatOpen ? 'Hide Support Chat' : 'Help & Support'}
    </Button>
  );
}

export default SupportButton;
