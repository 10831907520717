/* eslint-disable camelcase */
import {
  useDisclosure,
  Button,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  Input,
  DrawerFooter,
  Box,
  chakra,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  IconButton,
  Stack,
  Switch,
  Textarea,
} from '@chakra-ui/react';
import {
  ConsentFieldsFragment,
  GetConsentsDocument,
  RecurrenceType_Enum,
  useDeleteConsentMutation,
  useUpdateConsentMutation,
  useUpdateServiceConsentsMutation,
  GetConsentDocument,
} from '@webapp/graphql';
import { useStores } from '@webapp/state-models';
import { FormInput } from '@webapp/ui';
import { useLoadServices } from '@webapp/webapp/hooks';
import { useMemo, useRef, useState } from 'react';
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form';
import { FiTrash } from 'react-icons/fi';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import DeleteModal from '../delete-modal';
import styles from './ConsentConfigurationDrawer.module.scss';

/* eslint-disable-next-line */
export interface ConsentConfigurationDrawerProps {
  consent: ConsentFieldsFragment;
}

type ConsentInput = {
  title: string;
  description?: string;
  requiredAll?: boolean;
  isPartialMediaConsent?: boolean;
  isFullMediaConsent?: boolean;
  requireWitnessSignature?: boolean;
  requireProviderSignature?: boolean;
  serviceConsents?: string[];
  recurrence: RecurrenceType_Enum;
};
const recurrences = [
  { label: 'Every Appointment', value: RecurrenceType_Enum.Always },
  { label: 'First Appointment Only', value: RecurrenceType_Enum.Never },
  { label: 'Annually', value: RecurrenceType_Enum.Annually },
];

export function ConsentConfigurationDrawer({
  consent,
}: ConsentConfigurationDrawerProps) {
  const methods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    criteriaMode: 'firstError',
    shouldFocusError: true,
  });
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = useRef<HTMLButtonElement>(null);
  const { workspace } = useStores();

  const { register, handleSubmit, watch } = methods;
  const [updateConsent] = useUpdateConsentMutation();
  const [updateServiceConsents] = useUpdateServiceConsentsMutation();
  const navigate = useNavigate();

  const [deleteConsent] = useDeleteConsentMutation();

  const serviceSelectProps = useMemo(
    () => ({
      defaultOptions:
        consent?.serviceConsents.map(({ service }) => ({
          label: service.name,
          value: service.id,
        })) ?? [],
      loadOptions: useLoadServices,
      canCreate: false,
      isMulti: true,
    }),
    [consent?.serviceConsents]
  );

  const refetchQueries = [
    {
      query: GetConsentsDocument,
      variables: {
        where: { workspaceId: { _eq: workspace?.id } },
        limit: 10,
        offset: 0,
        orderBy: [],
      },
    },
    GetConsentDocument,
  ];
  const onSubmit: SubmitHandler<ConsentInput> = async ({
    title,
    description,
    requiredAll,
    isPartialMediaConsent,
    isFullMediaConsent,
    serviceConsents,
    requireProviderSignature,
    requireWitnessSignature,
    recurrence,
  }) => {
    try {
      if (!document) throw new Error('Document not found');

      const innerData = {
        title,
        description,
        requiredAll,
        isPartialMediaConsent,
        isFullMediaConsent,
        requireProviderSignature,
        requireWitnessSignature,
        documentId: document.id,
        workspaceId: workspace?.id,
        recurrence,
      };

      if (consent) {
        if (!requiredAll && Array.isArray(serviceConsents)) {
          updateServiceConsents({
            variables: {
              where: {
                consentId: { _eq: consent.id },
              },
              objects: serviceConsents.map((serviceId) => ({
                serviceId,
                consentId: consent.id,
              })),
            },
          });
        }

        await updateConsent({
          variables: {
            id: consent.id,
            set: innerData,
          },
          refetchQueries,
        });
      }

      toast.success(
        consent
          ? 'Consent successfully updated'
          : 'Consent successfully created'
      );

      //   navigate('/settings/forms-and-consents/consents');
      onClose();
    } catch (error) {
      toast.error((error as Error).message);
    }
  };

  const onOpenDeleteModal = (): void => {
    setDeleteModalOpen(true);
  };

  const onCloseDeleteModal = (): void => {
    setDeleteModalOpen(false);
  };

  const handleDelete = (): void => {
    deleteConsent({
      variables: {
        id: consent?.id,
      },
      onCompleted: () => {
        toast.success('Consent successfully deleted');
        navigate('/settings/forms-and-consents/consents');
      },
      onError: (error) => {
        toast.error((error as Error).message);
      },
      refetchQueries,
    });
  };

  return (
    <>
      <Button ref={btnRef} colorScheme="teal" onClick={onOpen}>
        Configure Consent
      </Button>
      <Drawer
        size="md"
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Configure Consent</DrawerHeader>

          <DrawerBody>
            <Stack w="full" position="relative" h="full" paddingX="10">
              <FormProvider {...methods}>
                <chakra.form onSubmit={handleSubmit(onSubmit)} h={'full'}>
                  <Stack h={'full'} justifyContent="space-between">
                    <Stack>
                      <FormControl id="title">
                        <FormLabel>Document Title</FormLabel>
                        <Input
                          type="text"
                          width="100%"
                          height="40px"
                          defaultValue={consent?.title}
                          {...register('title', {
                            required: 'Title is required',
                          })}
                        />
                      </FormControl>
                      <FormControl id="description">
                        <FormLabel>Description (optional)</FormLabel>
                        <Textarea
                          defaultValue={consent?.description ?? undefined}
                          {...register('description')}
                        />
                      </FormControl>
                      <FormControl display="flex" alignItems="center">
                        <FormLabel htmlFor="isPartialMediaConsent" mb="0">
                          Partial Media Consent
                        </FormLabel>
                        <Switch
                          defaultChecked={consent?.isPartialMediaConsent}
                          {...register('isPartialMediaConsent')}
                          id="isPartialMediaConsent"
                        />
                      </FormControl>
                      <FormControl display="flex" alignItems="center">
                        <FormLabel htmlFor="isFullMediaConsent" mb="0">
                          Full Media Consent
                        </FormLabel>
                        <Switch
                          defaultChecked={consent?.isFullMediaConsent}
                          {...register('isFullMediaConsent')}
                          id="isFullMediaConsent"
                        />
                      </FormControl>
                      <FormControl display="flex" alignItems="center">
                        <FormLabel htmlFor="requiredAll" mb="0">
                          Required for all services
                        </FormLabel>
                        <Switch
                          defaultChecked={consent?.requiredAll}
                          {...register('requiredAll')}
                          id="requiredAll"
                        />
                      </FormControl>
                      <FormControl display="flex" alignItems="center">
                        <FormLabel htmlFor="requireProviderSignature" mb="0">
                          Requires Provider Signature
                        </FormLabel>
                        <Switch
                          defaultChecked={Boolean(
                            consent?.requireProviderSignature
                          )}
                          {...register('requireProviderSignature')}
                          id="requireProviderSignature"
                        />
                      </FormControl>
                      <FormControl display="flex" alignItems="center">
                        <FormLabel htmlFor="requireWitnessSignature" mb="0">
                          Requires Witness Signature
                        </FormLabel>
                        <Switch
                          defaultChecked={Boolean(
                            consent?.requireWitnessSignature
                          )}
                          {...register('requireWitnessSignature')}
                          id="requireWitnessSignature"
                        />
                      </FormControl>
                      <Box maxW="350px" pt={8}>
                        <FormInput
                          isEditable={!watch('requiredAll')}
                          label="Required services"
                          multiSelectProps={serviceSelectProps}
                          name="serviceConsents"
                          placeholder="Select service(s)"
                          type="multiselect"
                        />
                      </Box>
                      <FormInput
                        label="Recurrence"
                        name="recurrence"
                        type="select"
                        defaultValue={
                          consent?.recurrence ?? RecurrenceType_Enum.Always
                        }
                        withOptions={recurrences}
                      />
                    </Stack>

                    <Stack
                      width="100%"
                      backgroundColor="white"
                      alignSelf={'flex-end'}
                    >
                      <Divider />
                      <Flex justifyContent="space-between">
                        <Button type="submit">Cancel</Button>
                        <HStack>
                          {consent && (
                            <IconButton
                              aria-label="delete-consent"
                              variant="outline"
                              colorScheme="red"
                              icon={<FiTrash />}
                              onClick={onOpenDeleteModal}
                            />
                          )}
                          <Button type="submit" colorScheme="teal">
                            Save consent
                          </Button>
                        </HStack>
                      </Flex>
                    </Stack>
                  </Stack>
                </chakra.form>
              </FormProvider>
              <DeleteModal
                itemName="consent"
                isOpen={deleteModalOpen}
                onClose={onCloseDeleteModal}
                handleDelete={handleDelete}
              />
            </Stack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
}

export default ConsentConfigurationDrawer;
