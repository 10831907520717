/* eslint-disable camelcase */
import {
  SignatureFieldsFragment,
  useGetSignaturesQuery,
  useInsertSignatureMutation,
} from '@webapp/graphql';

import { RefObject, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import ReactSignatureCanvas from 'react-signature-canvas';
import loadExistingSignatureToBase64 from './loadExistingSignatureToBase64';
import useUploadSignature from './useUploadSignature';

interface UseSignAndSaveProps {
  onCompleted?: (signatureId: string) => void;
  patientId?: string;
  providerId?: string;
  userId?: string;
}

export function useSignAndSave({
  onCompleted,
  patientId,
  providerId,
  userId,
}: UseSignAndSaveProps) {
  if (!patientId && !providerId && !userId) {
    throw new Error('Unconfigured useSignAndSave');
  }

  const [initialized, setInitialized] = useState<boolean>(false);
  const uploadSignature = useUploadSignature();

  const [signature, setSignature] = useState<SignatureFieldsFragment | null>(
    null
  );

  const [stringifiedSignature, setStringifiedSignature] = useState<
    string | null
  >(null);

  const [insertSignature] = useInsertSignatureMutation({
    onCompleted: (data) => {
      const [signatureData] = data.insert_signature?.returning || [];

      if (signatureData && onCompleted) {
        const signatureId = signatureData.id;
        onCompleted(signatureId);
      } else if (onCompleted) {
        toast.error('Signature failure, please try again');
      }
    },
  });

  async function sign(canvas: RefObject<ReactSignatureCanvas>) {
    if (canvas.current) {
      const filePath = await uploadSignature({
        patientId,
        providerId,
        signature: canvas.current,
        userId,
      });

      insertSignature({
        variables: {
          signature: {
            filePath,
            patientId,
            providerId,
            userId,
          },
          ...(patientId && { patientId }),
          ...(providerId && { providerId }),
          ...(userId && { userId }),
        },
      });
    }
  }

  const { loading } = useGetSignaturesQuery({
    onCompleted: (data) => {
      const [existingSignature] = data?.signature || [];

      if (existingSignature?.file?.url) {
        setSignature(existingSignature);
      }

      setInitialized(true);
    },
    variables: {
      limit: 1,
      where: {
        ...(patientId && { patientId: { _eq: patientId } }),
        ...(providerId && { providerId: { _eq: providerId } }),
        ...(userId && { userId: { _eq: userId } }),
      },
    },
  });

  useEffect(() => {
    if (signature && signature?.file?.url) {
      loadExistingSignatureToBase64(signature.file.url).then(
        (base64Signature) => {
          setStringifiedSignature(base64Signature);
        }
      );
    }
  }, [setStringifiedSignature, signature]);

  return {
    base64Signature: stringifiedSignature,
    initialized,
    loading,
    sign,
    signature,
  };
}

export default useSignAndSave;
