import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type SearchLeadFormsQueryVariables = Types.Exact<{
  searchValue: Types.Scalars['String'];
  limit?: Types.Maybe<Types.Scalars['Int']>;
}>;


export type SearchLeadFormsQuery = { __typename?: 'query_root', leadForm: Array<{ __typename?: 'leadForm', id: any, name: string }> };


export const SearchLeadFormsDocument = gql`
    query searchLeadForms($searchValue: String!, $limit: Int) {
  leadForm(where: {name: {_ilike: $searchValue}}) {
    id
    name
  }
}
    `;

/**
 * __useSearchLeadFormsQuery__
 *
 * To run a query within a React component, call `useSearchLeadFormsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchLeadFormsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchLeadFormsQuery({
 *   variables: {
 *      searchValue: // value for 'searchValue'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useSearchLeadFormsQuery(baseOptions: Apollo.QueryHookOptions<SearchLeadFormsQuery, SearchLeadFormsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchLeadFormsQuery, SearchLeadFormsQueryVariables>(SearchLeadFormsDocument, options);
      }
export function useSearchLeadFormsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchLeadFormsQuery, SearchLeadFormsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchLeadFormsQuery, SearchLeadFormsQueryVariables>(SearchLeadFormsDocument, options);
        }
export type SearchLeadFormsQueryHookResult = ReturnType<typeof useSearchLeadFormsQuery>;
export type SearchLeadFormsLazyQueryHookResult = ReturnType<typeof useSearchLeadFormsLazyQuery>;
export type SearchLeadFormsQueryResult = Apollo.QueryResult<SearchLeadFormsQuery, SearchLeadFormsQueryVariables>;