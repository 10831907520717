import React, { useState, useEffect } from 'react';
import { Box, Text } from '@chakra-ui/react';
import { TextEditor } from '@webapp/ui';

interface WrappedConsentTextProps {
  question: any;
  isDesignMode: boolean;
  onValueChange: (value: string) => void;
}

export default function WrappedConsentText({
  question,
  isDesignMode,
  onValueChange,
}: WrappedConsentTextProps) {
  const [localConsentText, setLocalConsentText] = useState(
    question.consentText
  );

  useEffect(() => {
    // Update question object when local state changes
    question.consentText = localConsentText;
  }, [localConsentText, question]);

  const handleTextChange = (value: string) => {
    setLocalConsentText(value);
    onValueChange(value);
  };

  return (
    <Box borderWidth="1px" borderRadius="lg" p={4}>
      {isDesignMode ? (
        <TextEditor
          defaultValue={localConsentText}
          onChange={handleTextChange}
        />
      ) : (
        <Text
          whiteSpace="pre-wrap"
          dangerouslySetInnerHTML={{ __html: localConsentText }}
        />
      )}
    </Box>
  );
}
