import { Stack, Checkbox, CheckboxGroup } from '@chakra-ui/react';
import { SingleField } from '@webapp/types';

interface PopulatedCustomFormMultipleSelectProps {
  field: SingleField;
  defaultValue: any;
  fieldId: string;
  setValue: (field: string, value: any) => void;
}

export const PopulatedCustomFormMultipleSelect = ({
  field,
  defaultValue,
  fieldId,
  setValue,
}: PopulatedCustomFormMultipleSelectProps): JSX.Element => (
  <CheckboxGroup
    defaultValue={defaultValue}
    onChange={(value) => setValue(fieldId, value)}
  >
    <Stack direction="column" spacing={2}>
      {field.options?.map(({ id: optionId, value }) => (
        <Checkbox key={optionId} value={value}>
          {value}
        </Checkbox>
      ))}
    </Stack>
  </CheckboxGroup>
);

export default PopulatedCustomFormMultipleSelect;
