import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type DeleteCustomQuestionMutationVariables = Types.Exact<{
  where: Types.CustomQuestion_Bool_Exp;
}>;


export type DeleteCustomQuestionMutation = { __typename?: 'mutation_root', delete_customQuestion?: { __typename?: 'customQuestion_mutation_response', returning: Array<{ __typename?: 'customQuestion', id: any }> } | null | undefined };


export const DeleteCustomQuestionDocument = gql`
    mutation deleteCustomQuestion($where: customQuestion_bool_exp!) {
  delete_customQuestion(where: $where) {
    returning {
      id
    }
  }
}
    `;
export type DeleteCustomQuestionMutationFn = Apollo.MutationFunction<DeleteCustomQuestionMutation, DeleteCustomQuestionMutationVariables>;

/**
 * __useDeleteCustomQuestionMutation__
 *
 * To run a mutation, you first call `useDeleteCustomQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCustomQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCustomQuestionMutation, { data, loading, error }] = useDeleteCustomQuestionMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDeleteCustomQuestionMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCustomQuestionMutation, DeleteCustomQuestionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCustomQuestionMutation, DeleteCustomQuestionMutationVariables>(DeleteCustomQuestionDocument, options);
      }
export type DeleteCustomQuestionMutationHookResult = ReturnType<typeof useDeleteCustomQuestionMutation>;
export type DeleteCustomQuestionMutationResult = Apollo.MutationResult<DeleteCustomQuestionMutation>;
export type DeleteCustomQuestionMutationOptions = Apollo.BaseMutationOptions<DeleteCustomQuestionMutation, DeleteCustomQuestionMutationVariables>;