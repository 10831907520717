import './fullscreen-image-editor.module.scss';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure,
  HStack,
  Stack,
  Image,
} from '@chakra-ui/react';
// @ts-ignore
import { triggerBase64Download } from 'react-base64-downloader';
import { Photo as MSTPhoto, PhotoModel, useStores } from '@webapp/state-models';
import { BiFullscreen } from 'react-icons/bi';
import { observer } from 'mobx-react-lite';
import { useEffect, useRef } from 'react';
import { SerialisationSchema } from 'photoeditorsdk';
import { useCreateBeforeAndAfterImageMutation } from '@webapp/graphql';
import { FaFileDownload } from 'react-icons/fa';
import EditableImage from '../editable-image/editable-image';
import ConfigurePhotoForm from '../configure-photo-details/configure-photo-form';

/* eslint-disable-next-line */
export interface FullscreenImageEditorProps {
  beforePhoto: MSTPhoto;
  afterPhoto: MSTPhoto;
  beforeOpen: () => void;
  onSerialisation?: (
    beforeImageState: SerialisationSchema,
    afterImageState: SerialisationSchema
  ) => void;
}

export const FullscreenImageEditor = observer(
  ({
    beforePhoto,
    afterPhoto,
    onSerialisation,
    beforeOpen,
  }: FullscreenImageEditorProps) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const beforeImageRef = useRef<any>();
    const afterImageRef = useRef<any>();
    const { draftPhoto, appendToDraftPhotos, clearDraftPhotos } = useStores();
    const [createBeforeAndAfterImage, { data, loading: baLoading }] =
      useCreateBeforeAndAfterImageMutation();

    useEffect(() => {
      if (data && data?.createBeforeAndAfterImage?.photo) {
        appendToDraftPhotos(
          PhotoModel.create(data?.createBeforeAndAfterImage?.photo)
        );
      }
    }, [data]);
    async function endEditing() {
      const beforeImageState =
        await beforeImageRef?.current?.serializeChanges();

      const afterImageState = await afterImageRef?.current?.serializeChanges();
      if (onSerialisation) {
        onSerialisation(beforeImageState, afterImageState);
      }
      beforePhoto.setSerializedState(beforeImageState);
      afterPhoto.setSerializedState(afterImageState);
      clearDraftPhotos();
      onClose();
    }

    function downloadBeforeAfter() {
      triggerBase64Download(
        `data:image/png;base64,${data?.createBeforeAndAfterImage?.image}`,
        `photo-${Date.now()}.png`
      );
    }

    async function handleOpen() {
      if (beforeOpen) await beforeOpen();
      if (beforePhoto.serializedState)
        beforeImageRef?.current?.deserializeChanges(beforePhoto.imglyState);
      if (afterPhoto.serializedState)
        afterImageRef?.current?.deserializeChanges(afterPhoto.imglyState);
      onOpen();
    }

    async function generateBAPhoto() {
      const beforeImageFilePath =
        await beforeImageRef?.current?.saveCurrentImage();

      const afterImageFilePath =
        await afterImageRef?.current?.saveCurrentImage();

      if (beforeImageFilePath && afterImageFilePath)
        createBeforeAndAfterImage({
          variables: {
            beforeFilePath: beforeImageFilePath,
            afterFilePath: afterImageFilePath,
            beforePhotoId: beforePhoto.id,
            afterPhotoId: afterPhoto.id,
          },
        });
    }

    return (
      <>
        <Button size={'md'} leftIcon={<BiFullscreen />} onClick={handleOpen}>
          Edit fullscreen
        </Button>
        <Modal isOpen={isOpen} onClose={endEditing} size="full">
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Before & After Image Creation</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              {data || baLoading ? (
                <HStack>
                  <Stack flex={1}>
                    {data?.createBeforeAndAfterImage?.image ? (
                      <>
                        <Image
                          className="ph-no-capture fs-exclude"
                          flex={1}
                          objectFit="cover"
                          src={`data:image/png;base64,${data?.createBeforeAndAfterImage?.image}`}
                        />
                        <Button
                          onClick={downloadBeforeAfter}
                          leftIcon={<FaFileDownload />}
                        >
                          Download Image
                        </Button>
                      </>
                    ) : (
                      <div>Generating Before & After Photo...</div>
                    )}
                  </Stack>
                  <Stack
                    flex={1}
                    justifyContent="flex-start"
                    h={'60vh'}
                    p={4}
                    border={'1px'}
                    borderColor="gray.200"
                  >
                    {draftPhoto && (
                      <ConfigurePhotoForm
                        onCreatePatient={console.log}
                        photo={draftPhoto}
                      />
                    )}
                  </Stack>
                </HStack>
              ) : (
                <HStack h={'80vh'} justifyContent="flex-end">
                  <EditableImage
                    height="85vh"
                    photo={beforePhoto}
                    showSaveButton={false}
                    ref={beforeImageRef}
                  ></EditableImage>
                  <EditableImage
                    height="85vh"
                    photo={afterPhoto}
                    showSaveButton={false}
                    ref={afterImageRef}
                  ></EditableImage>
                </HStack>
              )}
            </ModalBody>
            <ModalFooter>
              <Button colorScheme="teal" mr={0} onClick={generateBAPhoto}>
                Generate Before & After Photo
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    );
  }
);

export default FullscreenImageEditor;
