import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type ValidateCouponMutationVariables = Types.Exact<{
  couponId: Types.Scalars['uuid'];
  serviceIds?: Types.Maybe<Array<Types.Maybe<Types.Scalars['uuid']>> | Types.Maybe<Types.Scalars['uuid']>>;
  locationId: Types.Scalars['uuid'];
  patientId?: Types.Maybe<Types.Scalars['uuid']>;
  isMembershipCoupon?: Types.Maybe<Types.Scalars['Boolean']>;
}>;


export type ValidateCouponMutation = { __typename?: 'mutation_root', validateCoupon?: { __typename?: 'ValidateCouponOutput', couponId: any, validity: Types.Validity, invalidReason?: string | null | undefined } | null | undefined };


export const ValidateCouponDocument = gql`
    mutation ValidateCoupon($couponId: uuid!, $serviceIds: [uuid], $locationId: uuid!, $patientId: uuid, $isMembershipCoupon: Boolean) {
  validateCoupon(
    couponId: $couponId
    serviceIds: $serviceIds
    locationId: $locationId
    patientId: $patientId
    isMembershipCoupon: $isMembershipCoupon
  ) {
    couponId
    validity
    invalidReason
  }
}
    `;
export type ValidateCouponMutationFn = Apollo.MutationFunction<ValidateCouponMutation, ValidateCouponMutationVariables>;

/**
 * __useValidateCouponMutation__
 *
 * To run a mutation, you first call `useValidateCouponMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useValidateCouponMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [validateCouponMutation, { data, loading, error }] = useValidateCouponMutation({
 *   variables: {
 *      couponId: // value for 'couponId'
 *      serviceIds: // value for 'serviceIds'
 *      locationId: // value for 'locationId'
 *      patientId: // value for 'patientId'
 *      isMembershipCoupon: // value for 'isMembershipCoupon'
 *   },
 * });
 */
export function useValidateCouponMutation(baseOptions?: Apollo.MutationHookOptions<ValidateCouponMutation, ValidateCouponMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ValidateCouponMutation, ValidateCouponMutationVariables>(ValidateCouponDocument, options);
      }
export type ValidateCouponMutationHookResult = ReturnType<typeof useValidateCouponMutation>;
export type ValidateCouponMutationResult = Apollo.MutationResult<ValidateCouponMutation>;
export type ValidateCouponMutationOptions = Apollo.BaseMutationOptions<ValidateCouponMutation, ValidateCouponMutationVariables>;