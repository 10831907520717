import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type DeletePatientFlowsheetDataMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
}>;


export type DeletePatientFlowsheetDataMutation = { __typename?: 'mutation_root', delete_patientFlowsheetData_by_pk?: { __typename?: 'patientFlowsheetData', id: any } | null | undefined };


export const DeletePatientFlowsheetDataDocument = gql`
    mutation DeletePatientFlowsheetData($id: uuid!) {
  delete_patientFlowsheetData_by_pk(id: $id) {
    id
  }
}
    `;
export type DeletePatientFlowsheetDataMutationFn = Apollo.MutationFunction<DeletePatientFlowsheetDataMutation, DeletePatientFlowsheetDataMutationVariables>;

/**
 * __useDeletePatientFlowsheetDataMutation__
 *
 * To run a mutation, you first call `useDeletePatientFlowsheetDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePatientFlowsheetDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePatientFlowsheetDataMutation, { data, loading, error }] = useDeletePatientFlowsheetDataMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePatientFlowsheetDataMutation(baseOptions?: Apollo.MutationHookOptions<DeletePatientFlowsheetDataMutation, DeletePatientFlowsheetDataMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePatientFlowsheetDataMutation, DeletePatientFlowsheetDataMutationVariables>(DeletePatientFlowsheetDataDocument, options);
      }
export type DeletePatientFlowsheetDataMutationHookResult = ReturnType<typeof useDeletePatientFlowsheetDataMutation>;
export type DeletePatientFlowsheetDataMutationResult = Apollo.MutationResult<DeletePatientFlowsheetDataMutation>;
export type DeletePatientFlowsheetDataMutationOptions = Apollo.BaseMutationOptions<DeletePatientFlowsheetDataMutation, DeletePatientFlowsheetDataMutationVariables>;