import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { ConsentFieldsFragmentDoc } from '../../fragments/consentFields.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetConsentQueryVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
}>;


export type GetConsentQuery = { __typename?: 'query_root', consent_by_pk?: { __typename?: 'consent', id: any, title: string, description?: string | null | undefined, recurrence: Types.RecurrenceType_Enum, requiredAll: boolean, isPartialMediaConsent: boolean, isFullMediaConsent: boolean, requireProviderSignature?: boolean | null | undefined, requireWitnessSignature?: boolean | null | undefined, createdAt: any, workspaceId: any, surveyJSJson?: any | null | undefined, workspace: { __typename?: 'workspace', name: string }, document?: { __typename?: 'document', id: any, title?: string | null | undefined, filePath: string, documentType: string, documentTags: Array<{ __typename?: 'documentTag', id: any, tag: { __typename?: 'tag', color?: string | null | undefined, id: any, title: string } }>, file?: { __typename?: 'FileOnlyURL', url: string } | null | undefined } | null | undefined, serviceConsents: Array<{ __typename?: 'serviceConsent', id: any, service: { __typename?: 'service', id: any, name: string } }>, patientConsents: Array<{ __typename?: 'patientConsent', id: any, name: string, date: any, providerName?: string | null | undefined, providerDate?: any | null | undefined, witnessName?: string | null | undefined, witnessDate?: any | null | undefined, appointmentId: any, signature?: { __typename?: 'signature', id: any, filePath: string, file?: { __typename?: 'FileOnlyURL', url: string } | null | undefined } | null | undefined, providerSignature?: { __typename?: 'signature', id: any, filePath: string, file?: { __typename?: 'FileOnlyURL', url: string } | null | undefined } | null | undefined, witnessSignature?: { __typename?: 'signature', id: any, filePath: string, file?: { __typename?: 'FileOnlyURL', url: string } | null | undefined } | null | undefined }> } | null | undefined };


export const GetConsentDocument = gql`
    query GetConsent($id: uuid!) {
  consent_by_pk(id: $id) {
    ...ConsentFields
  }
}
    ${ConsentFieldsFragmentDoc}`;

/**
 * __useGetConsentQuery__
 *
 * To run a query within a React component, call `useGetConsentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetConsentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetConsentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetConsentQuery(baseOptions: Apollo.QueryHookOptions<GetConsentQuery, GetConsentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetConsentQuery, GetConsentQueryVariables>(GetConsentDocument, options);
      }
export function useGetConsentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetConsentQuery, GetConsentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetConsentQuery, GetConsentQueryVariables>(GetConsentDocument, options);
        }
export type GetConsentQueryHookResult = ReturnType<typeof useGetConsentQuery>;
export type GetConsentLazyQueryHookResult = ReturnType<typeof useGetConsentLazyQuery>;
export type GetConsentQueryResult = Apollo.QueryResult<GetConsentQuery, GetConsentQueryVariables>;