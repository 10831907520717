import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type DeleteInvoiceTemplateMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
}>;


export type DeleteInvoiceTemplateMutation = { __typename?: 'mutation_root', delete_invoiceTemplate_by_pk?: { __typename?: 'invoiceTemplate', id: any } | null | undefined };


export const DeleteInvoiceTemplateDocument = gql`
    mutation deleteInvoiceTemplate($id: uuid!) {
  delete_invoiceTemplate_by_pk(id: $id) {
    id
  }
}
    `;
export type DeleteInvoiceTemplateMutationFn = Apollo.MutationFunction<DeleteInvoiceTemplateMutation, DeleteInvoiceTemplateMutationVariables>;

/**
 * __useDeleteInvoiceTemplateMutation__
 *
 * To run a mutation, you first call `useDeleteInvoiceTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteInvoiceTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteInvoiceTemplateMutation, { data, loading, error }] = useDeleteInvoiceTemplateMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteInvoiceTemplateMutation(baseOptions?: Apollo.MutationHookOptions<DeleteInvoiceTemplateMutation, DeleteInvoiceTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteInvoiceTemplateMutation, DeleteInvoiceTemplateMutationVariables>(DeleteInvoiceTemplateDocument, options);
      }
export type DeleteInvoiceTemplateMutationHookResult = ReturnType<typeof useDeleteInvoiceTemplateMutation>;
export type DeleteInvoiceTemplateMutationResult = Apollo.MutationResult<DeleteInvoiceTemplateMutation>;
export type DeleteInvoiceTemplateMutationOptions = Apollo.BaseMutationOptions<DeleteInvoiceTemplateMutation, DeleteInvoiceTemplateMutationVariables>;