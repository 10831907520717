import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { ConsentFieldsFragmentDoc } from '../../fragments/consentFields.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type UpdateConsentMutationVariables = Types.Exact<{
  set?: Types.Maybe<Types.Consent_Set_Input>;
  id: Types.Scalars['uuid'];
}>;


export type UpdateConsentMutation = { __typename?: 'mutation_root', update_consent_by_pk?: { __typename?: 'consent', id: any, title: string, description?: string | null | undefined, recurrence: Types.RecurrenceType_Enum, requiredAll: boolean, isPartialMediaConsent: boolean, isFullMediaConsent: boolean, requireProviderSignature?: boolean | null | undefined, requireWitnessSignature?: boolean | null | undefined, createdAt: any, workspaceId: any, surveyJSJson?: any | null | undefined, workspace: { __typename?: 'workspace', name: string }, document?: { __typename?: 'document', id: any, title?: string | null | undefined, filePath: string, documentType: string, documentTags: Array<{ __typename?: 'documentTag', id: any, tag: { __typename?: 'tag', color?: string | null | undefined, id: any, title: string } }>, file?: { __typename?: 'FileOnlyURL', url: string } | null | undefined } | null | undefined, serviceConsents: Array<{ __typename?: 'serviceConsent', id: any, service: { __typename?: 'service', id: any, name: string } }>, patientConsents: Array<{ __typename?: 'patientConsent', id: any, name: string, date: any, providerName?: string | null | undefined, providerDate?: any | null | undefined, witnessName?: string | null | undefined, witnessDate?: any | null | undefined, appointmentId: any, signature?: { __typename?: 'signature', id: any, filePath: string, file?: { __typename?: 'FileOnlyURL', url: string } | null | undefined } | null | undefined, providerSignature?: { __typename?: 'signature', id: any, filePath: string, file?: { __typename?: 'FileOnlyURL', url: string } | null | undefined } | null | undefined, witnessSignature?: { __typename?: 'signature', id: any, filePath: string, file?: { __typename?: 'FileOnlyURL', url: string } | null | undefined } | null | undefined }> } | null | undefined };


export const UpdateConsentDocument = gql`
    mutation UpdateConsent($set: consent_set_input, $id: uuid!) {
  update_consent_by_pk(_set: $set, pk_columns: {id: $id}) {
    ...ConsentFields
  }
}
    ${ConsentFieldsFragmentDoc}`;
export type UpdateConsentMutationFn = Apollo.MutationFunction<UpdateConsentMutation, UpdateConsentMutationVariables>;

/**
 * __useUpdateConsentMutation__
 *
 * To run a mutation, you first call `useUpdateConsentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateConsentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateConsentMutation, { data, loading, error }] = useUpdateConsentMutation({
 *   variables: {
 *      set: // value for 'set'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateConsentMutation(baseOptions?: Apollo.MutationHookOptions<UpdateConsentMutation, UpdateConsentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateConsentMutation, UpdateConsentMutationVariables>(UpdateConsentDocument, options);
      }
export type UpdateConsentMutationHookResult = ReturnType<typeof useUpdateConsentMutation>;
export type UpdateConsentMutationResult = Apollo.MutationResult<UpdateConsentMutation>;
export type UpdateConsentMutationOptions = Apollo.BaseMutationOptions<UpdateConsentMutation, UpdateConsentMutationVariables>;