import {
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { splitCamelCaseToString } from '@webapp/util-formatting';
import { ColDef } from 'ag-grid-community';
import { FieldValues } from 'react-hook-form';
import { CompoundControlledFormInputProps } from '../FormInputV2/types';
import GenericForm from '../GenericForm/GenericForm';

interface RowCreateUpdateModalProps<T> {
  colDefs: ColDef<T>[];
  defaultValues?: FieldValues;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (values: T) => void;
}

function RowCreateUpdateModal<T>({
  colDefs,
  defaultValues,
  isOpen,
  onClose,
  onSubmit,
}: RowCreateUpdateModalProps<T>) {
  const fields = colDefs.reduce((acc, colDef) => {
    const Editor = colDef.cellEditor;
    const editorParams = colDef.cellEditorParams || {};
    const inputProps = editorParams.inputProps || {};

    if (Editor && colDef.field && inputProps.type) {
      const { name, ...otherInputProps } = inputProps;

      return [
        ...acc,
        {
          label: splitCamelCaseToString(colDef.headerName || colDef.field),
          name: colDef.field,
          gridArea: colDef.field,
          ...otherInputProps,
        },
      ];
    }

    return acc;
  }, [] as Omit<CompoundControlledFormInputProps, 'control'>[]);

  function handleSubmit(values: T) {
    onClose();
    onSubmit(values);
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {defaultValues ? 'Update Row' : 'Add New Row'}
        </ModalHeader>
        <ModalBody padding="0 20px 20px">
          <GenericForm
            defaultValues={defaultValues}
            fields={fields}
            onSubmit={handleSubmit}
            shouldResetOnSubmit
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default RowCreateUpdateModal;
