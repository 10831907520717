import { useLocation, Navigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useAuthState } from 'react-firebase-hooks/auth';
import { firebaseAuth } from './auth';

// eslint-disable-next-line import/prefer-default-export
export const RequireAuth = observer(
  ({ children }: { children: JSX.Element }) => {
    const [user] = useAuthState(firebaseAuth);
    const location = useLocation();

    if (!user) {
      // Redirect them to the /login page, but save the current location they were
      // trying to go to when they were redirected. This allows us to send them
      // along to that page after they login, which is a nicer user experience
      // than dropping them off on the home page.
      return <Navigate to="/login" state={{ from: location }} />;
    }

    return children;
  }
);
