import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type UpdateAppointmentConsentsMutationVariables = Types.Exact<{
  objects: Array<Types.AppointmentConsent_Insert_Input> | Types.AppointmentConsent_Insert_Input;
}>;


export type UpdateAppointmentConsentsMutation = { __typename?: 'mutation_root', insert_appointmentConsent?: { __typename?: 'appointmentConsent_mutation_response', affected_rows: number } | null | undefined };


export const UpdateAppointmentConsentsDocument = gql`
    mutation updateAppointmentConsents($objects: [appointmentConsent_insert_input!]!) {
  insert_appointmentConsent(
    objects: $objects
    on_conflict: {constraint: appointmentConsent_appointmentId_consentId_key, update_columns: [manuallyAdded, manuallyRemoved]}
  ) {
    affected_rows
  }
}
    `;
export type UpdateAppointmentConsentsMutationFn = Apollo.MutationFunction<UpdateAppointmentConsentsMutation, UpdateAppointmentConsentsMutationVariables>;

/**
 * __useUpdateAppointmentConsentsMutation__
 *
 * To run a mutation, you first call `useUpdateAppointmentConsentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAppointmentConsentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAppointmentConsentsMutation, { data, loading, error }] = useUpdateAppointmentConsentsMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useUpdateAppointmentConsentsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAppointmentConsentsMutation, UpdateAppointmentConsentsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAppointmentConsentsMutation, UpdateAppointmentConsentsMutationVariables>(UpdateAppointmentConsentsDocument, options);
      }
export type UpdateAppointmentConsentsMutationHookResult = ReturnType<typeof useUpdateAppointmentConsentsMutation>;
export type UpdateAppointmentConsentsMutationResult = Apollo.MutationResult<UpdateAppointmentConsentsMutation>;
export type UpdateAppointmentConsentsMutationOptions = Apollo.BaseMutationOptions<UpdateAppointmentConsentsMutation, UpdateAppointmentConsentsMutationVariables>;