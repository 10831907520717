import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type PayInvoiceWithTokenMutationVariables = Types.Exact<{
  invoiceId: Types.Scalars['uuid'];
  paymentMethodId?: Types.Maybe<Types.Scalars['uuid']>;
  workspaceId: Types.Scalars['uuid'];
  paymentInfo?: Types.Maybe<Types.Scalars['jsonb']>;
  amount?: Types.Maybe<Types.Scalars['Int']>;
}>;


export type PayInvoiceWithTokenMutation = { __typename?: 'mutation_root', payInvoiceWithToken?: { __typename?: 'PayInvoiceWithTokenOutput', status: string } | null | undefined };


export const PayInvoiceWithTokenDocument = gql`
    mutation PayInvoiceWithToken($invoiceId: uuid!, $paymentMethodId: uuid, $workspaceId: uuid!, $paymentInfo: jsonb, $amount: Int) {
  payInvoiceWithToken(
    invoiceId: $invoiceId
    paymentMethodId: $paymentMethodId
    paymentInfo: $paymentInfo
    workspaceId: $workspaceId
    amount: $amount
  ) {
    status
  }
}
    `;
export type PayInvoiceWithTokenMutationFn = Apollo.MutationFunction<PayInvoiceWithTokenMutation, PayInvoiceWithTokenMutationVariables>;

/**
 * __usePayInvoiceWithTokenMutation__
 *
 * To run a mutation, you first call `usePayInvoiceWithTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePayInvoiceWithTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [payInvoiceWithTokenMutation, { data, loading, error }] = usePayInvoiceWithTokenMutation({
 *   variables: {
 *      invoiceId: // value for 'invoiceId'
 *      paymentMethodId: // value for 'paymentMethodId'
 *      workspaceId: // value for 'workspaceId'
 *      paymentInfo: // value for 'paymentInfo'
 *      amount: // value for 'amount'
 *   },
 * });
 */
export function usePayInvoiceWithTokenMutation(baseOptions?: Apollo.MutationHookOptions<PayInvoiceWithTokenMutation, PayInvoiceWithTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PayInvoiceWithTokenMutation, PayInvoiceWithTokenMutationVariables>(PayInvoiceWithTokenDocument, options);
      }
export type PayInvoiceWithTokenMutationHookResult = ReturnType<typeof usePayInvoiceWithTokenMutation>;
export type PayInvoiceWithTokenMutationResult = Apollo.MutationResult<PayInvoiceWithTokenMutation>;
export type PayInvoiceWithTokenMutationOptions = Apollo.BaseMutationOptions<PayInvoiceWithTokenMutation, PayInvoiceWithTokenMutationVariables>;