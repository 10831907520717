import { Grid, Button } from '@chakra-ui/react';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { ImageInput } from '@webapp/ui';
import { useStores } from '@webapp/state-models';
import { StorageType } from '@webapp/graphql';

export interface SimpleImageUploadProps {
  name: string;
  defaultValue?: string | null;
  workspaceId?: string;
  patientId?: string;
  onChange: (value: string) => void;
  uploadingAnonymously?: boolean;
  storageType?: StorageType;
  selectionScope: React.InputHTMLAttributes<HTMLInputElement>['capture'];
}
export const SimpleImageUpload = function SimpleImageUpload({
  name,
  onChange,
  defaultValue,
  workspaceId,
  patientId,
  uploadingAnonymously = false,
  storageType = StorageType.Private,
  selectionScope,
}: SimpleImageUploadProps) {
  const { workspace } = useStores();

  const methods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      [name]: defaultValue ?? null,
    },
    criteriaMode: 'firstError',
    shouldFocusError: true,
    shouldUnregister: false,
    shouldUseNativeValidation: false,
    delayError: undefined,
  });
  const value = methods.watch(name);
  useEffect(() => {
    if (value) {
      onChange(value);
    }
  }, [value]);

  return (
    <FormProvider {...methods}>
      <ImageInput
        id={name}
        uploadingAnonymously={uploadingAnonymously}
        name={name}
        storageType={storageType}
        shouldHaveBorder={false}
        defaultValues={{
          mediaType: 'other',
          ...(patientId ? { patientId } : undefined),
        }}
        workspaceId={workspaceId || (workspace?.id as string)}
        capture={selectionScope}
      >
        <Grid>
          <Button colorScheme="teal" variant="outline" width="150px">
            Change
          </Button>
        </Grid>
      </ImageInput>
    </FormProvider>
  );
};

export default SimpleImageUpload;
