import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { DeviceFieldsFragmentDoc } from '../../fragments/deviceFields.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type AvailableDevicesForServiceQueryVariables = Types.Exact<{
  day: Types.Scalars['String'];
  endTime: Types.Scalars['String'];
  locationId: Types.Scalars['uuid'];
  serviceId: Types.Scalars['uuid'];
  startTime: Types.Scalars['String'];
}>;


export type AvailableDevicesForServiceQuery = { __typename?: 'query_root', availableDevicesForService?: Array<{ __typename?: 'DeviceAvailabilityOutput', device?: { __typename?: 'device', id: any, name: string, schedule?: any | null | undefined, active?: boolean | null | undefined, deviceType?: { __typename?: 'deviceType', id: any, value: string, serviceDeviceTypes: Array<{ __typename?: 'serviceDeviceType', service: { __typename?: 'service', id: any, name: string } }> } | null | undefined, location?: { __typename?: 'location', id: any, isActive: boolean, name: string, address?: string | null | undefined, phoneNumber?: string | null | undefined, email?: string | null | undefined, maxPatientsPerTimeslot?: number | null | undefined, workspaceId: any, clinicId?: number | null | undefined, addressId?: any | null | undefined, workStartTime?: string | null | undefined, workEndTime?: string | null | undefined, timezone?: string | null | undefined, payrixMerchantId?: string | null | undefined, finixMerchantId?: string | null | undefined, locationSchedules: Array<{ __typename?: 'locationSchedule', id: any, schedule: any, effectiveFrom?: any | null | undefined, createdAt: any, updatedAt: any, locationId: any }>, fullAddress?: { __typename?: 'address', addressLine1: string, addressLine2?: string | null | undefined, city: string, id: any, state: string, zipCode: string } | null | undefined, rooms: Array<{ __typename?: 'room', id: any, name: string }> } | null | undefined } | null | undefined, availability?: Array<{ __typename?: 'AvailabilityRange', start: string, end: string } | null | undefined> | null | undefined } | null | undefined> | null | undefined };


export const AvailableDevicesForServiceDocument = gql`
    query availableDevicesForService($day: String!, $endTime: String!, $locationId: uuid!, $serviceId: uuid!, $startTime: String!) {
  availableDevicesForService(
    day: $day
    endTime: $endTime
    locationId: $locationId
    serviceId: $serviceId
    startTime: $startTime
  ) {
    device {
      ...DeviceFields
    }
    availability {
      start
      end
    }
  }
}
    ${DeviceFieldsFragmentDoc}`;

/**
 * __useAvailableDevicesForServiceQuery__
 *
 * To run a query within a React component, call `useAvailableDevicesForServiceQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvailableDevicesForServiceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailableDevicesForServiceQuery({
 *   variables: {
 *      day: // value for 'day'
 *      endTime: // value for 'endTime'
 *      locationId: // value for 'locationId'
 *      serviceId: // value for 'serviceId'
 *      startTime: // value for 'startTime'
 *   },
 * });
 */
export function useAvailableDevicesForServiceQuery(baseOptions: Apollo.QueryHookOptions<AvailableDevicesForServiceQuery, AvailableDevicesForServiceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AvailableDevicesForServiceQuery, AvailableDevicesForServiceQueryVariables>(AvailableDevicesForServiceDocument, options);
      }
export function useAvailableDevicesForServiceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AvailableDevicesForServiceQuery, AvailableDevicesForServiceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AvailableDevicesForServiceQuery, AvailableDevicesForServiceQueryVariables>(AvailableDevicesForServiceDocument, options);
        }
export type AvailableDevicesForServiceQueryHookResult = ReturnType<typeof useAvailableDevicesForServiceQuery>;
export type AvailableDevicesForServiceLazyQueryHookResult = ReturnType<typeof useAvailableDevicesForServiceLazyQuery>;
export type AvailableDevicesForServiceQueryResult = Apollo.QueryResult<AvailableDevicesForServiceQuery, AvailableDevicesForServiceQueryVariables>;