import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type SendBatchEmailTemplateMutationVariables = Types.Exact<{
  audienceId: Types.Scalars['uuid'];
  emailTemplateId: Types.Scalars['uuid'];
  testEmail?: Types.Maybe<Types.Scalars['String']>;
  op?: Types.Maybe<Types.Scalars['String']>;
}>;


export type SendBatchEmailTemplateMutation = { __typename?: 'mutation_root', batchEmailTemplate?: { __typename?: 'SuccessAnswer', success: boolean } | null | undefined };


export const SendBatchEmailTemplateDocument = gql`
    mutation sendBatchEmailTemplate($audienceId: uuid!, $emailTemplateId: uuid!, $testEmail: String, $op: String) {
  batchEmailTemplate(
    audienceId: $audienceId
    emailTemplateId: $emailTemplateId
    testEmail: $testEmail
    op: $op
  ) {
    success
  }
}
    `;
export type SendBatchEmailTemplateMutationFn = Apollo.MutationFunction<SendBatchEmailTemplateMutation, SendBatchEmailTemplateMutationVariables>;

/**
 * __useSendBatchEmailTemplateMutation__
 *
 * To run a mutation, you first call `useSendBatchEmailTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendBatchEmailTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendBatchEmailTemplateMutation, { data, loading, error }] = useSendBatchEmailTemplateMutation({
 *   variables: {
 *      audienceId: // value for 'audienceId'
 *      emailTemplateId: // value for 'emailTemplateId'
 *      testEmail: // value for 'testEmail'
 *      op: // value for 'op'
 *   },
 * });
 */
export function useSendBatchEmailTemplateMutation(baseOptions?: Apollo.MutationHookOptions<SendBatchEmailTemplateMutation, SendBatchEmailTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendBatchEmailTemplateMutation, SendBatchEmailTemplateMutationVariables>(SendBatchEmailTemplateDocument, options);
      }
export type SendBatchEmailTemplateMutationHookResult = ReturnType<typeof useSendBatchEmailTemplateMutation>;
export type SendBatchEmailTemplateMutationResult = Apollo.MutationResult<SendBatchEmailTemplateMutation>;
export type SendBatchEmailTemplateMutationOptions = Apollo.BaseMutationOptions<SendBatchEmailTemplateMutation, SendBatchEmailTemplateMutationVariables>;