import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type SendPatientAuthLinkQueryVariables = Types.Exact<{
  copy?: Types.Maybe<Types.AuthLinkCopy>;
  locationId?: Types.Maybe<Types.Scalars['String']>;
  patientId: Types.Scalars['String'];
  shouldSendToPatient?: Types.Maybe<Types.Scalars['Boolean']>;
  to?: Types.Maybe<Types.Scalars['String']>;
}>;


export type SendPatientAuthLinkQuery = { __typename?: 'query_root', genPatientAuthLink: { __typename?: 'AuthLinkOutput', link: string, token: string } };


export const SendPatientAuthLinkDocument = gql`
    query SendPatientAuthLink($copy: AuthLinkCopy, $locationId: String, $patientId: String!, $shouldSendToPatient: Boolean = true, $to: String) {
  genPatientAuthLink(
    copy: $copy
    locationId: $locationId
    patientId: $patientId
    shouldSendToPatient: $shouldSendToPatient
    to: $to
  ) {
    link
    token
  }
}
    `;

/**
 * __useSendPatientAuthLinkQuery__
 *
 * To run a query within a React component, call `useSendPatientAuthLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useSendPatientAuthLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSendPatientAuthLinkQuery({
 *   variables: {
 *      copy: // value for 'copy'
 *      locationId: // value for 'locationId'
 *      patientId: // value for 'patientId'
 *      shouldSendToPatient: // value for 'shouldSendToPatient'
 *      to: // value for 'to'
 *   },
 * });
 */
export function useSendPatientAuthLinkQuery(baseOptions: Apollo.QueryHookOptions<SendPatientAuthLinkQuery, SendPatientAuthLinkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SendPatientAuthLinkQuery, SendPatientAuthLinkQueryVariables>(SendPatientAuthLinkDocument, options);
      }
export function useSendPatientAuthLinkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SendPatientAuthLinkQuery, SendPatientAuthLinkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SendPatientAuthLinkQuery, SendPatientAuthLinkQueryVariables>(SendPatientAuthLinkDocument, options);
        }
export type SendPatientAuthLinkQueryHookResult = ReturnType<typeof useSendPatientAuthLinkQuery>;
export type SendPatientAuthLinkLazyQueryHookResult = ReturnType<typeof useSendPatientAuthLinkLazyQuery>;
export type SendPatientAuthLinkQueryResult = Apollo.QueryResult<SendPatientAuthLinkQuery, SendPatientAuthLinkQueryVariables>;