import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { LeadTrackedEventFieldsFragmentDoc } from '../../fragments/leadPhoneCallFields.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetLeadTrackedEventsQueryVariables = Types.Exact<{
  where?: Types.Maybe<Types.LeadTrackedEvent_Bool_Exp>;
  limit?: Types.Maybe<Types.Scalars['Int']>;
  offset?: Types.Maybe<Types.Scalars['Int']>;
  orderBy?: Types.Maybe<Array<Types.LeadTrackedEvent_Order_By> | Types.LeadTrackedEvent_Order_By>;
}>;


export type GetLeadTrackedEventsQuery = { __typename?: 'query_root', leadTrackedEvent: Array<{ __typename?: 'leadTrackedEvent', id: any, outcome: string, type?: string | null | undefined, createdAt: any, updatedAt: any, user?: { __typename?: 'user', firstName: string, lastName: string } | null | undefined }> };


export const GetLeadTrackedEventsDocument = gql`
    query GetLeadTrackedEvents($where: leadTrackedEvent_bool_exp, $limit: Int, $offset: Int, $orderBy: [leadTrackedEvent_order_by!]) {
  leadTrackedEvent(
    where: $where
    limit: $limit
    offset: $offset
    order_by: $orderBy
  ) {
    ...LeadTrackedEventFields
  }
}
    ${LeadTrackedEventFieldsFragmentDoc}`;

/**
 * __useGetLeadTrackedEventsQuery__
 *
 * To run a query within a React component, call `useGetLeadTrackedEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLeadTrackedEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLeadTrackedEventsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetLeadTrackedEventsQuery(baseOptions?: Apollo.QueryHookOptions<GetLeadTrackedEventsQuery, GetLeadTrackedEventsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLeadTrackedEventsQuery, GetLeadTrackedEventsQueryVariables>(GetLeadTrackedEventsDocument, options);
      }
export function useGetLeadTrackedEventsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLeadTrackedEventsQuery, GetLeadTrackedEventsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLeadTrackedEventsQuery, GetLeadTrackedEventsQueryVariables>(GetLeadTrackedEventsDocument, options);
        }
export type GetLeadTrackedEventsQueryHookResult = ReturnType<typeof useGetLeadTrackedEventsQuery>;
export type GetLeadTrackedEventsLazyQueryHookResult = ReturnType<typeof useGetLeadTrackedEventsLazyQuery>;
export type GetLeadTrackedEventsQueryResult = Apollo.QueryResult<GetLeadTrackedEventsQuery, GetLeadTrackedEventsQueryVariables>;