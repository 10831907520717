/* eslint-disable no-nested-ternary */
import React, { PropsWithChildren } from 'react';
import {
  Box,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  Flex,
  HStack,
  useBreakpointValue,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import { HEADER_HEIGHT, VH_MINUS_HEADER } from '@webapp/webapp/util-display';
import './left-drawer-layout.module.scss';
import { Logo, ToggleButton } from '@webapp/ui';
import {
  MainSideBar,
  MenuLinks,
  SettingsSideBar,
  SupportButton,
  NotificationFeed,
} from '@webapp/webapp/ui-composites';
import { Link, useLocation } from 'react-router-dom';
import { useStores } from '@webapp/state-models';
import { observer } from 'mobx-react-lite';
import { Helmet } from 'react-helmet';
import Favicon from 'react-favicon';

/* eslint-disable-next-line */
type LeftDrawerLayoutProps = PropsWithChildren<{
  leftDrawerComponent?: React.ReactNode;
  rightNavComponent?: React.ReactNode;
}>;

export const LeftDrawerLayout = observer(
  ({
    children,
    leftDrawerComponent,
    rightNavComponent,
  }: LeftDrawerLayoutProps) => {
    const location = useLocation();
    const {
      workspace,
      ui: { showCreateAppointmentSidebar },
    } = useStores();
    const { isOpen, onToggle, onClose } = useDisclosure();
    const isMobile = useBreakpointValue({ base: true, md: false });
    return (
      <Box
        height="100vh"
        overflow="hidden"
        position="relative"
        className="left-drawer-layout"
      >
        <Favicon
          url={
            workspace?.organization?.organizationConfiguration
              ?.lightLogoSmall || '/favicon.ico'
          }
        />
        <Helmet>
          <title>{workspace?.organization?.name ?? 'Prospyr'}</title>
        </Helmet>
        <Flex
          align="center"
          bg="white"
          color="white"
          px="6"
          minH="16"
          height={HEADER_HEIGHT}
          borderBottomColor={'gray.200'}
          borderWidth={1}
        >
          <Flex justify="space-between" align="center" w="full">
            {/* <MobileHamburgerMenu onClick={toggle} isOpen={isMenuOpen} />
        <NavMenu.Mobile isOpen={isMenuOpen} /> */}

            {/* Desktop Logo placement */}
            <Link to="/">
              <Logo
                display={{ base: 'none', lg: 'block' }}
                flexShrink={0}
                h="5"
                marginEnd="10"
              />
            </Link>

            {/* Desktop Navigation Menu */}
            {/* <NavMenu.Desktop /> */}

            {/* Mobile Logo placement */}
            {/* <Logo
              flex={{ base: '1', lg: '0' }}
              display={{ lg: 'none' }}
              flexShrink={0}
              h="5"
            /> */}

            <HStack spacing="3">
              {/* <Notification display={{ base: 'none', lg: 'inline-flex' }} /> */}
              {/* TODO: setup USER avatar */}
              {/* <ProfileDropdown /> */}
              <SupportButton />
              {rightNavComponent}
              {isMobile && (
                <>
                  <ToggleButton
                    isOpen={isOpen}
                    aria-label="Open Menu"
                    onClick={onToggle}
                  />
                  <Drawer
                    isOpen={isOpen}
                    placement="left"
                    onClose={onClose}
                    isFullHeight
                    preserveScrollBarGap
                    // Only disabled for showcase
                    trapFocus={false}
                  >
                    <DrawerOverlay />
                    <DrawerContent>
                      <MenuLinks />
                    </DrawerContent>
                  </Drawer>
                </>
              )}
              <NotificationFeed />
            </HStack>
          </Flex>
        </Flex>
        <HStack height={VH_MINUS_HEADER} id="app-container">
          <Box height={VH_MINUS_HEADER}>
            {showCreateAppointmentSidebar && leftDrawerComponent ? (
              <Box
                width={415}
                height={VH_MINUS_HEADER}
                borderRightWidth={1}
                borderColor={'gray.200'}
              >
                {leftDrawerComponent}
              </Box>
            ) : location.pathname.includes('/settings') ? (
              <SettingsSideBar />
            ) : (
              <Box display={{ base: 'none', md: 'flex' }}>
                <MainSideBar />
              </Box>
            )}
          </Box>

          <Box
            bg={useColorModeValue('white', 'gray.800')}
            height={VH_MINUS_HEADER}
            flex={7}
            overflowX="hidden"
            overflowY="scroll"
          >
            {children}
          </Box>
        </HStack>
      </Box>
    );
  }
);

export default LeftDrawerLayout;
