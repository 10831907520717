import { SelectOption } from '@webapp/types';
import {
  ActionMeta,
  MultiValue,
  Select,
  SingleValue,
} from 'chakra-react-select';
import DEFAULT_CONTROL_STYLE from './styles/DefaultControlStyle';
import DEFAULT_INPUT_CONTAINER_STYLE from './styles/DefaultInputContainerStyle';
import { MultiSelectInputProps } from './types';
import isMultiValue from './util/isMultiValue';

function MultiSelectInput({
  isClearable = true,
  name,
  onChange,
  options,
  placeholder,
  styles = {},
  value: valueFromProps,
  ...props
}: Omit<MultiSelectInputProps, 'type'>) {
  const inputStyles = { ...DEFAULT_CONTROL_STYLE, ...styles.control };

  const value = !valueFromProps ? [] : valueFromProps;

  const containerStyles = {
    ...DEFAULT_INPUT_CONTAINER_STYLE,
    ...styles.inputContainer,
  };

  async function handleChange(
    newValue: SingleValue<SelectOption> | MultiValue<SelectOption> | null,
    actionMeta: ActionMeta<SelectOption>
  ) {
    switch (actionMeta.action) {
      case 'create-option': {
        break;
      }

      case 'pop-value':
      case 'remove-value': {
        if (actionMeta.removedValue?.value) {
          const removedValue = actionMeta.removedValue.value;

          const newOptions = value.filter(
            (option) => option.value !== removedValue
          );

          onChange(newOptions);
        }
        break;
      }

      case 'clear': {
        onChange([]);
        break;
      }

      default: {
        if (isMultiValue(newValue)) {
          onChange(Array.from(newValue));
        } else if (newValue) {
          onChange([newValue]);
        } else {
          onChange([]);
        }
      }
    }
  }

  return (
    <Select
      chakraStyles={{
        container: (provided) => ({ ...provided, ...containerStyles }),
        control: (provided) => ({ ...provided, ...inputStyles }),
      }}
      defaultValue={value}
      value={value}
      isInvalid={Boolean(props.error)}
      isMulti
      name={name}
      onChange={handleChange}
      placeholder={placeholder}
      options={options}
      isClearable={isClearable}
      {...props}
    />
  );
}

export default MultiSelectInput;
