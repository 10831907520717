import * as Types from '../../generated/graphql';

import { gql } from '@apollo/client';
import { LeadFormStepFieldsFragmentDoc } from '../fragments/leadFormStepFields.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type InsertLeadFormStepMutationVariables = Types.Exact<{
  leadFormStep: Types.LeadFormStep_Insert_Input;
}>;


export type InsertLeadFormStepMutation = { __typename?: 'mutation_root', insert_leadFormStep?: { __typename?: 'leadFormStep_mutation_response', returning: Array<{ __typename?: 'leadFormStep', id: any, name?: string | null | undefined, order: number, title?: string | null | undefined, subtitle?: string | null | undefined, callToAction?: string | null | undefined, workspaceId: any, type: string, canDelete: boolean, canReOrder: boolean, properties?: any | null | undefined, fields: Array<{ __typename?: 'leadFormField', id: any, validationProps?: any | null | undefined, apiFieldKey: string, label: string, order: any, type: string, workspaceId: any }> }> } | null | undefined };


export const InsertLeadFormStepDocument = gql`
    mutation insertLeadFormStep($leadFormStep: leadFormStep_insert_input!) {
  insert_leadFormStep(objects: [$leadFormStep]) {
    returning {
      ...LeadFormStepFields
    }
  }
}
    ${LeadFormStepFieldsFragmentDoc}`;
export type InsertLeadFormStepMutationFn = Apollo.MutationFunction<InsertLeadFormStepMutation, InsertLeadFormStepMutationVariables>;

/**
 * __useInsertLeadFormStepMutation__
 *
 * To run a mutation, you first call `useInsertLeadFormStepMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertLeadFormStepMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertLeadFormStepMutation, { data, loading, error }] = useInsertLeadFormStepMutation({
 *   variables: {
 *      leadFormStep: // value for 'leadFormStep'
 *   },
 * });
 */
export function useInsertLeadFormStepMutation(baseOptions?: Apollo.MutationHookOptions<InsertLeadFormStepMutation, InsertLeadFormStepMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertLeadFormStepMutation, InsertLeadFormStepMutationVariables>(InsertLeadFormStepDocument, options);
      }
export type InsertLeadFormStepMutationHookResult = ReturnType<typeof useInsertLeadFormStepMutation>;
export type InsertLeadFormStepMutationResult = Apollo.MutationResult<InsertLeadFormStepMutation>;
export type InsertLeadFormStepMutationOptions = Apollo.BaseMutationOptions<InsertLeadFormStepMutation, InsertLeadFormStepMutationVariables>;