import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type InsertLocationServicesMutationVariables = Types.Exact<{
  locationServices: Array<Types.LocationService_Insert_Input> | Types.LocationService_Insert_Input;
}>;


export type InsertLocationServicesMutation = { __typename?: 'mutation_root', insert_locationService?: { __typename?: 'locationService_mutation_response', returning: Array<{ __typename?: 'locationService', id: any, locationId: any, serviceId: any }> } | null | undefined };


export const InsertLocationServicesDocument = gql`
    mutation insertLocationServices($locationServices: [locationService_insert_input!]!) {
  insert_locationService(
    objects: $locationServices
    on_conflict: {constraint: locationService_locationId_serviceId_key, update_columns: updatedAt}
  ) {
    returning {
      id
      locationId
      serviceId
    }
  }
}
    `;
export type InsertLocationServicesMutationFn = Apollo.MutationFunction<InsertLocationServicesMutation, InsertLocationServicesMutationVariables>;

/**
 * __useInsertLocationServicesMutation__
 *
 * To run a mutation, you first call `useInsertLocationServicesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertLocationServicesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertLocationServicesMutation, { data, loading, error }] = useInsertLocationServicesMutation({
 *   variables: {
 *      locationServices: // value for 'locationServices'
 *   },
 * });
 */
export function useInsertLocationServicesMutation(baseOptions?: Apollo.MutationHookOptions<InsertLocationServicesMutation, InsertLocationServicesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertLocationServicesMutation, InsertLocationServicesMutationVariables>(InsertLocationServicesDocument, options);
      }
export type InsertLocationServicesMutationHookResult = ReturnType<typeof useInsertLocationServicesMutation>;
export type InsertLocationServicesMutationResult = Apollo.MutationResult<InsertLocationServicesMutation>;
export type InsertLocationServicesMutationOptions = Apollo.BaseMutationOptions<InsertLocationServicesMutation, InsertLocationServicesMutationVariables>;