import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type SearchLeadTagsQueryVariables = Types.Exact<{
  _ilike: Types.Scalars['String'];
}>;


export type SearchLeadTagsQuery = { __typename?: 'query_root', leadTag: Array<{ __typename?: 'leadTag', id: any, title: string }> };


export const SearchLeadTagsDocument = gql`
    query searchLeadTags($_ilike: String!) {
  leadTag(where: {title: {_ilike: $_ilike}}) {
    id
    title
  }
}
    `;

/**
 * __useSearchLeadTagsQuery__
 *
 * To run a query within a React component, call `useSearchLeadTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchLeadTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchLeadTagsQuery({
 *   variables: {
 *      _ilike: // value for '_ilike'
 *   },
 * });
 */
export function useSearchLeadTagsQuery(baseOptions: Apollo.QueryHookOptions<SearchLeadTagsQuery, SearchLeadTagsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchLeadTagsQuery, SearchLeadTagsQueryVariables>(SearchLeadTagsDocument, options);
      }
export function useSearchLeadTagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchLeadTagsQuery, SearchLeadTagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchLeadTagsQuery, SearchLeadTagsQueryVariables>(SearchLeadTagsDocument, options);
        }
export type SearchLeadTagsQueryHookResult = ReturnType<typeof useSearchLeadTagsQuery>;
export type SearchLeadTagsLazyQueryHookResult = ReturnType<typeof useSearchLeadTagsLazyQuery>;
export type SearchLeadTagsQueryResult = Apollo.QueryResult<SearchLeadTagsQuery, SearchLeadTagsQueryVariables>;