import * as Types from '../../generated/graphql';

import { gql } from '@apollo/client';
export type TelehealthMeetingFieldsFragment = { __typename?: 'telehealthMeeting', id: any, patientId: any, appointmentId?: any | null | undefined, createdAt: any, endTime: any, participantLink: string, hostLink: string };

export const TelehealthMeetingFieldsFragmentDoc = gql`
    fragment TelehealthMeetingFields on telehealthMeeting {
  id
  patientId
  appointmentId
  createdAt
  endTime
  participantLink
  hostLink
}
    `;