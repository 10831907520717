import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { CustomQuestionFieldsFragmentDoc } from '../../fragments/customQuestionFields.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type InsertCustomQuestionMutationVariables = Types.Exact<{
  customQuestion: Types.CustomQuestion_Insert_Input;
}>;


export type InsertCustomQuestionMutation = { __typename?: 'mutation_root', insert_customQuestion?: { __typename?: 'customQuestion_mutation_response', returning: Array<{ __typename?: 'customQuestion', id: any, label: string, body?: string | null | undefined, photoId?: any | null | undefined, categoryValueAndDescription?: { __typename?: 'customFormFieldCategory', value: string, description: string } | null | undefined, typeValueAndDescription: { __typename?: 'customFormFieldType', value: string, description: string }, options: Array<{ __typename?: 'customQuestionOption', value: string }>, photo?: { __typename?: 'photo', file?: { __typename?: 'FilePayload', url: string } | null | undefined } | null | undefined }> } | null | undefined };


export const InsertCustomQuestionDocument = gql`
    mutation insertCustomQuestion($customQuestion: customQuestion_insert_input!) {
  insert_customQuestion(objects: [$customQuestion]) {
    returning {
      ...CustomQuestionFields
    }
  }
}
    ${CustomQuestionFieldsFragmentDoc}`;
export type InsertCustomQuestionMutationFn = Apollo.MutationFunction<InsertCustomQuestionMutation, InsertCustomQuestionMutationVariables>;

/**
 * __useInsertCustomQuestionMutation__
 *
 * To run a mutation, you first call `useInsertCustomQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertCustomQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertCustomQuestionMutation, { data, loading, error }] = useInsertCustomQuestionMutation({
 *   variables: {
 *      customQuestion: // value for 'customQuestion'
 *   },
 * });
 */
export function useInsertCustomQuestionMutation(baseOptions?: Apollo.MutationHookOptions<InsertCustomQuestionMutation, InsertCustomQuestionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertCustomQuestionMutation, InsertCustomQuestionMutationVariables>(InsertCustomQuestionDocument, options);
      }
export type InsertCustomQuestionMutationHookResult = ReturnType<typeof useInsertCustomQuestionMutation>;
export type InsertCustomQuestionMutationResult = Apollo.MutationResult<InsertCustomQuestionMutation>;
export type InsertCustomQuestionMutationOptions = Apollo.BaseMutationOptions<InsertCustomQuestionMutation, InsertCustomQuestionMutationVariables>;