import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { ProviderSearchFieldsFragmentDoc } from '../../fragments/providerSearchFields.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type SearchProviderQueryVariables = Types.Exact<{
  searchValue: Types.Scalars['String'];
  limit?: Types.Maybe<Types.Scalars['Int']>;
  where?: Types.Maybe<Types.Provider_Bool_Exp>;
}>;


export type SearchProviderQuery = { __typename?: 'query_root', provider: Array<{ __typename?: 'provider', id: any, firstName: string, lastName: string, profilePicture?: string | null | undefined, email: string, phoneNumber?: string | null | undefined, address?: string | null | undefined, title?: string | null | undefined, stateLicense?: string | null | undefined, upin?: string | null | undefined, dea?: string | null | undefined, npi?: string | null | undefined }> };


export const SearchProviderDocument = gql`
    query SearchProvider($searchValue: String!, $limit: Int, $where: provider_bool_exp = {}) {
  provider(
    limit: $limit
    where: {_and: [{_or: [{firstName: {_ilike: $searchValue}}, {lastName: {_ilike: $searchValue}}], active: {_eq: true}}, $where]}
    order_by: {firstName: asc}
  ) {
    ...ProviderSearchFields
  }
}
    ${ProviderSearchFieldsFragmentDoc}`;

/**
 * __useSearchProviderQuery__
 *
 * To run a query within a React component, call `useSearchProviderQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchProviderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchProviderQuery({
 *   variables: {
 *      searchValue: // value for 'searchValue'
 *      limit: // value for 'limit'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useSearchProviderQuery(baseOptions: Apollo.QueryHookOptions<SearchProviderQuery, SearchProviderQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchProviderQuery, SearchProviderQueryVariables>(SearchProviderDocument, options);
      }
export function useSearchProviderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchProviderQuery, SearchProviderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchProviderQuery, SearchProviderQueryVariables>(SearchProviderDocument, options);
        }
export type SearchProviderQueryHookResult = ReturnType<typeof useSearchProviderQuery>;
export type SearchProviderLazyQueryHookResult = ReturnType<typeof useSearchProviderLazyQuery>;
export type SearchProviderQueryResult = Apollo.QueryResult<SearchProviderQuery, SearchProviderQueryVariables>;