import { ViewIcon } from '@chakra-ui/icons';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  IconButton,
} from '@chakra-ui/react';
import { useEffect } from 'react';
import { MdOutlinePhotoSizeSelectLarge } from 'react-icons/md';
import SelectPhotoOrTemplate from '../SelectPhotoOrTemplate/SelectPhotoOrTemplate';

function SelectOverlayModal({
  patientId,
  onSelectTemplate,
  openByDefault = false,
}: {
  patientId: string;
  onSelectTemplate: (photo: Photo) => void;
  openByDefault?: boolean;
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    if (openByDefault) {
      onOpen();
    }
  }, [openByDefault, onOpen]);

  return (
    <>
      <IconButton
        icon={<MdOutlinePhotoSizeSelectLarge color="white" />}
        variant="outline"
        onClick={onOpen}
      ></IconButton>

      <Modal isOpen={isOpen} onClose={onClose} size="full">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Select Overlay</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <SelectPhotoOrTemplate
              patientId={patientId}
              setSelectedTemplate={(a) => {
                onSelectTemplate(a);
                onClose();
              }}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default SelectOverlayModal;
