import { Datepicker } from '@mobiscroll/react';
import { FormInputProps } from '@webapp/types';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormLabel, Grid, Text } from '@chakra-ui/react';
import { ErrorMessage } from '@hookform/error-message';
import { DEFAULT_LABEL_STYLE } from './constants';

import './InlineDatePickerFormInput.css';

function InlineDatePickerFormInput({
  id,
  label,
  labelAlign,
  labelStyle = DEFAULT_LABEL_STYLE,
  name,
}: FormInputProps) {
  const {
    formState: { errors },
    getValues,
    register,
    setValue,
  } = useFormContext();

  const initialValue = getValues(name) ?? new Date();

  const inputId = id ?? name;

  useEffect(() => {
    register(name);
  });

  function handleChange({ value }: { value: Date; valueText: string }) {
    setValue(name, value);
  }

  return (
    <Grid
      alignItems={labelAlign}
      className="input-outer"
      gridAutoRows="max-content"
      gridRowGap="6px"
      gridTemplateColumns="1fr"
    >
      {Boolean(label) && (
        <FormLabel
          className="input-label"
          htmlFor={inputId}
          margin="0 0 0 0"
          {...labelStyle}
        >
          {label}
        </FormLabel>
      )}
      <Grid className="input-inner" gridRowGap="8px">
        <Datepicker
          className="date-picker__form_input"
          controls={['calendar']}
          defaultSelection={initialValue}
          display="inline"
          onChange={handleChange}
          showOuterDays={false}
          themeVariant="light"
          touchUi={true}
        />
        <ErrorMessage
          errors={errors}
          name={name}
          render={({ message }) => (
            <Text
              className="input-error"
              color="red.500"
              _before={{
                display: 'inline',
                content: '"⚠ "',
              }}
            >
              {message}
            </Text>
          )}
        />
      </Grid>
    </Grid>
  );
}

export default InlineDatePickerFormInput;
