import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { AnyJsonValue } from '../custom-types/any-json-value';

/**
 * A ProviderScheduleStore model.
 */
// prettier-ignore
export const ProviderScheduleModel = types
.model('ProviderSchedule')
.props({
  id: types.identifier,
  schedule: types.maybeNull(AnyJsonValue),
  locationId: types.string,
  effectiveFrom: types.maybeNull(types.string),
  effectiveUntil: types.maybeNull(types.string),
  default: types.boolean,
})
.actions((self) => ({
  
}));
/**
 * The ProviderSchedule instance.
 */
export type ProviderSchedule = Instance<typeof ProviderScheduleModel>;

/**
 * The data of a ProviderSchedule.
 */
export type ProviderScheduleSnapshot = SnapshotOut<
  typeof ProviderScheduleModel
>;
