import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { FinixTerminalFieldsFragmentDoc } from '../../fragments/finixTerminalFields.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type UpdateFinixTerminalMutationVariables = Types.Exact<{
  set?: Types.Maybe<Types.FinixTerminal_Set_Input>;
  id: Types.Scalars['uuid'];
}>;


export type UpdateFinixTerminalMutation = { __typename?: 'mutation_root', update_finixTerminal_by_pk?: { __typename?: 'finixTerminal', id: any, locationId: any, finixDeviceId: string, serialNumber: string, status: string, model: string, name?: string | null | undefined, createdAt: any, updatedAt: any, location: { __typename?: 'location', id: any, name: string } } | null | undefined };


export const UpdateFinixTerminalDocument = gql`
    mutation UpdateFinixTerminal($set: finixTerminal_set_input, $id: uuid!) {
  update_finixTerminal_by_pk(_set: $set, pk_columns: {id: $id}) {
    ...FinixTerminalFields
  }
}
    ${FinixTerminalFieldsFragmentDoc}`;
export type UpdateFinixTerminalMutationFn = Apollo.MutationFunction<UpdateFinixTerminalMutation, UpdateFinixTerminalMutationVariables>;

/**
 * __useUpdateFinixTerminalMutation__
 *
 * To run a mutation, you first call `useUpdateFinixTerminalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFinixTerminalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFinixTerminalMutation, { data, loading, error }] = useUpdateFinixTerminalMutation({
 *   variables: {
 *      set: // value for 'set'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateFinixTerminalMutation(baseOptions?: Apollo.MutationHookOptions<UpdateFinixTerminalMutation, UpdateFinixTerminalMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateFinixTerminalMutation, UpdateFinixTerminalMutationVariables>(UpdateFinixTerminalDocument, options);
      }
export type UpdateFinixTerminalMutationHookResult = ReturnType<typeof useUpdateFinixTerminalMutation>;
export type UpdateFinixTerminalMutationResult = Apollo.MutationResult<UpdateFinixTerminalMutation>;
export type UpdateFinixTerminalMutationOptions = Apollo.BaseMutationOptions<UpdateFinixTerminalMutation, UpdateFinixTerminalMutationVariables>;