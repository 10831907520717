import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetCalendarResourcesMutationVariables = Types.Exact<{
  startDate: Types.Scalars['String'];
  endDate: Types.Scalars['String'];
  range?: Types.Maybe<Types.Scalars['String']>;
  timezone: Types.Scalars['String'];
  locationsFilter?: Types.Maybe<Types.Scalars['String']>;
  providersFilter?: Types.Maybe<Types.Scalars['String']>;
  serviceFilter?: Types.Maybe<Types.Scalars['String']>;
  statusFilter?: Types.Maybe<Types.Scalars['String']>;
  hideCancelledAppointments?: Types.Maybe<Types.Scalars['Boolean']>;
}>;


export type GetCalendarResourcesMutation = { __typename?: 'mutation_root', getCalendarResources?: { __typename?: 'CalendarResourceOutput', events?: Array<{ __typename?: 'CalendarEvents', id?: string | null | undefined, appointmentId?: string | null | undefined, editable?: boolean | null | undefined, type?: string | null | undefined, appointmentType?: string | null | undefined, start?: string | null | undefined, end?: string | null | undefined, color?: string | null | undefined, resource?: Array<string | null | undefined> | null | undefined, status?: string | null | undefined, note?: string | null | undefined, patientName?: string | null | undefined, title?: string | null | undefined, room?: string | null | undefined, services?: Array<string | null | undefined> | null | undefined, phoneNumber?: string | null | undefined, internalAppointmentProvider?: Array<string | null | undefined> | null | undefined, patientId?: string | null | undefined, providerName?: string | null | undefined, isFirstAppointmentWithProvider?: boolean | null | undefined, isMembershipActive?: boolean | null | undefined, isMembershipTypeSubscription?: boolean | null | undefined, isNewPatient?: boolean | null | undefined, recurring?: string | null | undefined, recurringExceptionDates?: Array<string | null | undefined> | null | undefined, hasPhotos?: boolean | null | undefined, notesSigned?: boolean | null | undefined, consumablesRecorded?: boolean | null | undefined, intakeStatus?: string | null | undefined } | null | undefined> | null | undefined, providers?: Array<{ __typename?: 'CalendarProviders', id: string, name: string, locations?: Array<string | null | undefined> | null | undefined } | null | undefined> | null | undefined } | null | undefined };


export const GetCalendarResourcesDocument = gql`
    mutation GetCalendarResources($startDate: String!, $endDate: String!, $range: String, $timezone: String!, $locationsFilter: String, $providersFilter: String, $serviceFilter: String, $statusFilter: String, $hideCancelledAppointments: Boolean) {
  getCalendarResources(
    startDate: $startDate
    endDate: $endDate
    range: $range
    timezone: $timezone
    locationsFilter: $locationsFilter
    providersFilter: $providersFilter
    serviceFilter: $serviceFilter
    hideCancelledAppointments: $hideCancelledAppointments
  ) {
    events {
      id
      appointmentId
      editable
      type
      appointmentType
      start
      end
      color
      resource
      status
      note
      patientName
      title
      room
      services
      phoneNumber
      internalAppointmentProvider
      patientId
      providerName
      isFirstAppointmentWithProvider
      isMembershipActive
      isMembershipTypeSubscription
      isNewPatient
      recurring
      recurringExceptionDates
      hasPhotos
      notesSigned
      consumablesRecorded
      intakeStatus
    }
    providers {
      id
      name
      locations
    }
  }
}
    `;
export type GetCalendarResourcesMutationFn = Apollo.MutationFunction<GetCalendarResourcesMutation, GetCalendarResourcesMutationVariables>;

/**
 * __useGetCalendarResourcesMutation__
 *
 * To run a mutation, you first call `useGetCalendarResourcesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetCalendarResourcesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getCalendarResourcesMutation, { data, loading, error }] = useGetCalendarResourcesMutation({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      range: // value for 'range'
 *      timezone: // value for 'timezone'
 *      locationsFilter: // value for 'locationsFilter'
 *      providersFilter: // value for 'providersFilter'
 *      serviceFilter: // value for 'serviceFilter'
 *      statusFilter: // value for 'statusFilter'
 *      hideCancelledAppointments: // value for 'hideCancelledAppointments'
 *   },
 * });
 */
export function useGetCalendarResourcesMutation(baseOptions?: Apollo.MutationHookOptions<GetCalendarResourcesMutation, GetCalendarResourcesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GetCalendarResourcesMutation, GetCalendarResourcesMutationVariables>(GetCalendarResourcesDocument, options);
      }
export type GetCalendarResourcesMutationHookResult = ReturnType<typeof useGetCalendarResourcesMutation>;
export type GetCalendarResourcesMutationResult = Apollo.MutationResult<GetCalendarResourcesMutation>;
export type GetCalendarResourcesMutationOptions = Apollo.BaseMutationOptions<GetCalendarResourcesMutation, GetCalendarResourcesMutationVariables>;