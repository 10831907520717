import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetPurchaseOrdersAggregateQueryVariables = Types.Exact<{
  where?: Types.Maybe<Types.PurchaseOrder_Bool_Exp>;
}>;


export type GetPurchaseOrdersAggregateQuery = { __typename?: 'query_root', purchaseOrder_aggregate: { __typename?: 'purchaseOrder_aggregate', aggregate?: { __typename?: 'purchaseOrder_aggregate_fields', count: number } | null | undefined } };


export const GetPurchaseOrdersAggregateDocument = gql`
    query getPurchaseOrdersAggregate($where: purchaseOrder_bool_exp) {
  purchaseOrder_aggregate(where: $where) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetPurchaseOrdersAggregateQuery__
 *
 * To run a query within a React component, call `useGetPurchaseOrdersAggregateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPurchaseOrdersAggregateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPurchaseOrdersAggregateQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetPurchaseOrdersAggregateQuery(baseOptions?: Apollo.QueryHookOptions<GetPurchaseOrdersAggregateQuery, GetPurchaseOrdersAggregateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPurchaseOrdersAggregateQuery, GetPurchaseOrdersAggregateQueryVariables>(GetPurchaseOrdersAggregateDocument, options);
      }
export function useGetPurchaseOrdersAggregateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPurchaseOrdersAggregateQuery, GetPurchaseOrdersAggregateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPurchaseOrdersAggregateQuery, GetPurchaseOrdersAggregateQueryVariables>(GetPurchaseOrdersAggregateDocument, options);
        }
export type GetPurchaseOrdersAggregateQueryHookResult = ReturnType<typeof useGetPurchaseOrdersAggregateQuery>;
export type GetPurchaseOrdersAggregateLazyQueryHookResult = ReturnType<typeof useGetPurchaseOrdersAggregateLazyQuery>;
export type GetPurchaseOrdersAggregateQueryResult = Apollo.QueryResult<GetPurchaseOrdersAggregateQuery, GetPurchaseOrdersAggregateQueryVariables>;