import { FormInputProps } from '@webapp/types';
import { useCallback, useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import NumberInput from '../NumberInput/NumberInput';
import { NumberInputMobile } from '../NumberInputMobile/NumberInputMobile';

function NumberFormInput(props: FormInputProps) {
  const { control, getValues, setValue, watch } = useFormContext();
  const { defaultValue, name, update, itemIndex, ...altProps } = props;
  const initialValue = getValues(name) as number;
  const [displayValue, setDisplayValue] = useState<string>(
    // TODO: Determine if this change doesn't cause downstream effects
    initialValue !== undefined && typeof initialValue === 'number'
      ? initialValue?.toFixed(props.precision)
      : (0).toFixed(props.precision)
  );

  useEffect(() => {
    const sub = watch((data, options) => {
      if (options.name === name && data.name !== parseFloat(displayValue)) {
        setDisplayValue(data.name);
      }
    });

    return sub.unsubscribe();
  }, [displayValue, name, watch]);

  const onChange = useCallback(
    (newValue: number, valueString: string) => {
      setDisplayValue(valueString);

      if (newValue) {
        setValue(name, newValue);
      } else {
        setValue(name, 0);
      }
    },
    [setValue, setDisplayValue]
  );

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { ref }, formState: { errors } }) => (
        <>
          <NumberInput
            defaultValue={defaultValue as number}
            errors={errors}
            onChange={onChange}
            value={displayValue}
            name={name}
            ref={ref}
            {...altProps}
            disabled={props.isEditable === false}
          />
        </>
      )}
    />
  );
}

export default NumberFormInput;
