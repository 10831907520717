import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { ConsentFieldsFragmentDoc } from '../../fragments/consentFields.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type InsertConsentMutationVariables = Types.Exact<{
  consent: Types.Consent_Insert_Input;
}>;


export type InsertConsentMutation = { __typename?: 'mutation_root', insert_consent?: { __typename?: 'consent_mutation_response', returning: Array<{ __typename?: 'consent', id: any, title: string, description?: string | null | undefined, recurrence: Types.RecurrenceType_Enum, requiredAll: boolean, isPartialMediaConsent: boolean, isFullMediaConsent: boolean, requireProviderSignature?: boolean | null | undefined, requireWitnessSignature?: boolean | null | undefined, createdAt: any, workspaceId: any, surveyJSJson?: any | null | undefined, workspace: { __typename?: 'workspace', name: string }, document?: { __typename?: 'document', id: any, title?: string | null | undefined, filePath: string, documentType: string, documentTags: Array<{ __typename?: 'documentTag', id: any, tag: { __typename?: 'tag', color?: string | null | undefined, id: any, title: string } }>, file?: { __typename?: 'FileOnlyURL', url: string } | null | undefined } | null | undefined, serviceConsents: Array<{ __typename?: 'serviceConsent', id: any, service: { __typename?: 'service', id: any, name: string } }>, patientConsents: Array<{ __typename?: 'patientConsent', id: any, name: string, date: any, providerName?: string | null | undefined, providerDate?: any | null | undefined, witnessName?: string | null | undefined, witnessDate?: any | null | undefined, appointmentId: any, signature?: { __typename?: 'signature', id: any, filePath: string, file?: { __typename?: 'FileOnlyURL', url: string } | null | undefined } | null | undefined, providerSignature?: { __typename?: 'signature', id: any, filePath: string, file?: { __typename?: 'FileOnlyURL', url: string } | null | undefined } | null | undefined, witnessSignature?: { __typename?: 'signature', id: any, filePath: string, file?: { __typename?: 'FileOnlyURL', url: string } | null | undefined } | null | undefined }> }> } | null | undefined };


export const InsertConsentDocument = gql`
    mutation insertConsent($consent: consent_insert_input!) {
  insert_consent(objects: [$consent]) {
    returning {
      ...ConsentFields
    }
  }
}
    ${ConsentFieldsFragmentDoc}`;
export type InsertConsentMutationFn = Apollo.MutationFunction<InsertConsentMutation, InsertConsentMutationVariables>;

/**
 * __useInsertConsentMutation__
 *
 * To run a mutation, you first call `useInsertConsentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertConsentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertConsentMutation, { data, loading, error }] = useInsertConsentMutation({
 *   variables: {
 *      consent: // value for 'consent'
 *   },
 * });
 */
export function useInsertConsentMutation(baseOptions?: Apollo.MutationHookOptions<InsertConsentMutation, InsertConsentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertConsentMutation, InsertConsentMutationVariables>(InsertConsentDocument, options);
      }
export type InsertConsentMutationHookResult = ReturnType<typeof useInsertConsentMutation>;
export type InsertConsentMutationResult = Apollo.MutationResult<InsertConsentMutation>;
export type InsertConsentMutationOptions = Apollo.BaseMutationOptions<InsertConsentMutation, InsertConsentMutationVariables>;