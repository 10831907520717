import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { DegreeDesignationFieldsFragmentDoc } from '../../fragments/degreeDesignation.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetDegreeDesignationsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetDegreeDesignationsQuery = { __typename?: 'query_root', degreeDesignation: Array<{ __typename?: 'degreeDesignation', value: string, description: string }> };


export const GetDegreeDesignationsDocument = gql`
    query GetDegreeDesignations {
  degreeDesignation {
    ...DegreeDesignationFields
  }
}
    ${DegreeDesignationFieldsFragmentDoc}`;

/**
 * __useGetDegreeDesignationsQuery__
 *
 * To run a query within a React component, call `useGetDegreeDesignationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDegreeDesignationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDegreeDesignationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDegreeDesignationsQuery(baseOptions?: Apollo.QueryHookOptions<GetDegreeDesignationsQuery, GetDegreeDesignationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDegreeDesignationsQuery, GetDegreeDesignationsQueryVariables>(GetDegreeDesignationsDocument, options);
      }
export function useGetDegreeDesignationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDegreeDesignationsQuery, GetDegreeDesignationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDegreeDesignationsQuery, GetDegreeDesignationsQueryVariables>(GetDegreeDesignationsDocument, options);
        }
export type GetDegreeDesignationsQueryHookResult = ReturnType<typeof useGetDegreeDesignationsQuery>;
export type GetDegreeDesignationsLazyQueryHookResult = ReturnType<typeof useGetDegreeDesignationsLazyQuery>;
export type GetDegreeDesignationsQueryResult = Apollo.QueryResult<GetDegreeDesignationsQuery, GetDegreeDesignationsQueryVariables>;