import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type UpdateMediaTagMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
  set?: Types.Maybe<Types.MediaTag_Set_Input>;
}>;


export type UpdateMediaTagMutation = { __typename?: 'mutation_root', update_mediaTag_by_pk?: { __typename?: 'mediaTag', id: any, title: string } | null | undefined };


export const UpdateMediaTagDocument = gql`
    mutation UpdateMediaTag($id: uuid!, $set: mediaTag_set_input) {
  update_mediaTag_by_pk(_set: $set, pk_columns: {id: $id}) {
    id
    title
  }
}
    `;
export type UpdateMediaTagMutationFn = Apollo.MutationFunction<UpdateMediaTagMutation, UpdateMediaTagMutationVariables>;

/**
 * __useUpdateMediaTagMutation__
 *
 * To run a mutation, you first call `useUpdateMediaTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMediaTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMediaTagMutation, { data, loading, error }] = useUpdateMediaTagMutation({
 *   variables: {
 *      id: // value for 'id'
 *      set: // value for 'set'
 *   },
 * });
 */
export function useUpdateMediaTagMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMediaTagMutation, UpdateMediaTagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMediaTagMutation, UpdateMediaTagMutationVariables>(UpdateMediaTagDocument, options);
      }
export type UpdateMediaTagMutationHookResult = ReturnType<typeof useUpdateMediaTagMutation>;
export type UpdateMediaTagMutationResult = Apollo.MutationResult<UpdateMediaTagMutation>;
export type UpdateMediaTagMutationOptions = Apollo.BaseMutationOptions<UpdateMediaTagMutation, UpdateMediaTagMutationVariables>;