import { Instance, SnapshotOut, types } from 'mobx-state-tree';

/**
 * A UIStore model.
 */
// prettier-ignore
export const UIModel = types
.model('UI')
.props({
  showCreateAppointmentSidebar: types.optional(types.boolean, false),
  loadingAppointments: types.optional(types.boolean, false),
  hideCancelledAppointments: types.optional(types.boolean, true),
  telehealthModal: types.optional(types.model().props({
    show: types.optional(types.boolean, false),
    telehealthMeetingId: types.optional(types.string, ''),
  }), {}),
  managerPinModal: types.optional(types.model().props({
    show: types.optional(types.boolean, false)
  }), {}),
  globalPinLock: types.optional(types.boolean, false),
  stickyNote: types.optional(types.model().props({
    show: types.optional(types.boolean, false),
    content: types.optional(types.string, ''),
    appointmentId: types.optional(types.string, ''),
    patientId: types.optional(types.string, ''),
    noteId: types.optional(types.string, ''),
  }), {})
})
.actions((self) => {
    let pinScopedFunction: ((...args:any[]) => any) | null = null;

    return {
        setShowCreateAppointmentSidebar(value: boolean) {
        self.showCreateAppointmentSidebar = value
    },
    setShowManagerPinModal(value: boolean, associatedAction?: ((...args: any[]) => any)) {
        self.managerPinModal.show = value
        if(associatedAction) {pinScopedFunction = associatedAction}
        
    },
    runManagerPinScopedFunction() {
        if(pinScopedFunction) {
            console.log('running manager pin scoped function', pinScopedFunction)
            pinScopedFunction()
            pinScopedFunction = null
        }
    },
    setHideCancelledAppointments(value: boolean) {
        self.hideCancelledAppointments = value
    },
    showTelehealthModal(meetingId: string) {
        self.telehealthModal.telehealthMeetingId = meetingId
        self.telehealthModal.show = true
    },
    setGlobalPinLock(value: boolean) {
        self.globalPinLock = value
    },
    hideTelehealthModal() {
        self.telehealthModal.show = false
        self.telehealthModal.telehealthMeetingId = ''
    },
    showStickyNote(patientId: string, content?: string, appointmentId?: string, noteId?: string) {
       
        self.stickyNote.show = true
        self.stickyNote.patientId = patientId
        self.stickyNote.content = content || ''
        if(noteId) {
            self.stickyNote.noteId = noteId
        }
        if(appointmentId) {
            self.stickyNote.appointmentId = appointmentId
        }
    },
    setStickyNoteId(noteId: string) {
        self.stickyNote.noteId = noteId
    },
    destroyStickyNote() {
        self.stickyNote.show = false
        self.stickyNote.content = ''
        self.stickyNote.appointmentId = ''
        self.stickyNote.patientId = ''
        self.stickyNote.noteId = ''
    },
    setLoadingAppointments(value: boolean) {
        self.loadingAppointments = value
    }}
});
/**
 * The UI instance.
 */
export type UI = Instance<typeof UIModel>;

/**
 * The data of a UI.
 */
export type UISnapshot = SnapshotOut<typeof UIModel>;
