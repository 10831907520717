import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type SandboxMutationVariables = Types.Exact<{
  input: Types.Scalars['jsonb'];
}>;


export type SandboxMutation = { __typename?: 'mutation_root', sandbox: { __typename?: 'SandboxOutput', result: any } };


export const SandboxDocument = gql`
    mutation Sandbox($input: jsonb!) {
  sandbox(input: $input) {
    result
  }
}
    `;
export type SandboxMutationFn = Apollo.MutationFunction<SandboxMutation, SandboxMutationVariables>;

/**
 * __useSandboxMutation__
 *
 * To run a mutation, you first call `useSandboxMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSandboxMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sandboxMutation, { data, loading, error }] = useSandboxMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSandboxMutation(baseOptions?: Apollo.MutationHookOptions<SandboxMutation, SandboxMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SandboxMutation, SandboxMutationVariables>(SandboxDocument, options);
      }
export type SandboxMutationHookResult = ReturnType<typeof useSandboxMutation>;
export type SandboxMutationResult = Apollo.MutationResult<SandboxMutation>;
export type SandboxMutationOptions = Apollo.BaseMutationOptions<SandboxMutation, SandboxMutationVariables>;