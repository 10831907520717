import * as Types from '../../generated/graphql';

import { gql } from '@apollo/client';
export type SignatureFieldsFragment = { __typename?: 'signature', id: any, filePath: string, file?: { __typename?: 'FileOnlyURL', url: string } | null | undefined };

export const SignatureFieldsFragmentDoc = gql`
    fragment SignatureFields on signature {
  id
  filePath
  file {
    url
  }
}
    `;