import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { SocialMediaAccountPostFieldsFragmentDoc } from '../../fragments/socialMediaAccountPostFields.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type DeleteSocialMediaAccountPostMutationVariables = Types.Exact<{
  where: Types.SocialMediaAccountPost_Bool_Exp;
}>;


export type DeleteSocialMediaAccountPostMutation = { __typename?: 'mutation_root', delete_socialMediaAccountPost?: { __typename?: 'socialMediaAccountPost_mutation_response', returning: Array<{ __typename?: 'socialMediaAccountPost', id: any, likeCount?: number | null | undefined, commentCount?: number | null | undefined, socialMediaAccount: { __typename?: 'socialMediaAccount', id: any, name: string, platform: Types.SocialMediaPlatform_Enum, profilePicture?: string | null | undefined }, socialMediaPost: { __typename?: 'socialMediaPost', id: any, title: string, caption?: string | null | undefined, isScheduled: boolean, intendedPostAt: any, privatePhoto?: { __typename?: 'photo', id: any, workspaceId: any, patientId?: any | null | undefined, mediaDate?: any | null | undefined, mediaType?: string | null | undefined, mimeType?: string | null | undefined, hasThumbnail?: boolean | null | undefined, isSharedWithPatient: boolean, partialMediaConsent?: boolean | null | undefined, fullMediaConsent?: boolean | null | undefined, patient?: { __typename?: 'patient', firstName: string, id: any, lastName: string } | null | undefined, file?: { __typename?: 'FilePayload', filePath: string, url: string, thumbnailUrl?: string | null | undefined } | null | undefined, photoMediaTags: Array<{ __typename?: 'photoMediaTag', id: any, mediaTag: { __typename?: 'mediaTag', id: any, title: string } }>, uploader?: { __typename?: 'user', firstName: string, id: any, lastName: string } | null | undefined } | null | undefined, socialMediaAccountPosts: Array<{ __typename?: 'socialMediaAccountPost', status: Types.PostStatus_Enum, likeCount?: number | null | undefined, commentCount?: number | null | undefined, socialMediaAccount: { __typename?: 'socialMediaAccount', id: any, name: string, profilePicture?: string | null | undefined, platform: Types.SocialMediaPlatform_Enum } }> } }> } | null | undefined };


export const DeleteSocialMediaAccountPostDocument = gql`
    mutation deleteSocialMediaAccountPost($where: socialMediaAccountPost_bool_exp!) {
  delete_socialMediaAccountPost(where: $where) {
    returning {
      ...SocialMediaAccountPostFields
    }
  }
}
    ${SocialMediaAccountPostFieldsFragmentDoc}`;
export type DeleteSocialMediaAccountPostMutationFn = Apollo.MutationFunction<DeleteSocialMediaAccountPostMutation, DeleteSocialMediaAccountPostMutationVariables>;

/**
 * __useDeleteSocialMediaAccountPostMutation__
 *
 * To run a mutation, you first call `useDeleteSocialMediaAccountPostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSocialMediaAccountPostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSocialMediaAccountPostMutation, { data, loading, error }] = useDeleteSocialMediaAccountPostMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDeleteSocialMediaAccountPostMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSocialMediaAccountPostMutation, DeleteSocialMediaAccountPostMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSocialMediaAccountPostMutation, DeleteSocialMediaAccountPostMutationVariables>(DeleteSocialMediaAccountPostDocument, options);
      }
export type DeleteSocialMediaAccountPostMutationHookResult = ReturnType<typeof useDeleteSocialMediaAccountPostMutation>;
export type DeleteSocialMediaAccountPostMutationResult = Apollo.MutationResult<DeleteSocialMediaAccountPostMutation>;
export type DeleteSocialMediaAccountPostMutationOptions = Apollo.BaseMutationOptions<DeleteSocialMediaAccountPostMutation, DeleteSocialMediaAccountPostMutationVariables>;