import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetProvidersAggregateQueryVariables = Types.Exact<{
  where?: Types.Maybe<Types.Provider_Bool_Exp>;
}>;


export type GetProvidersAggregateQuery = { __typename?: 'query_root', provider_aggregate: { __typename?: 'provider_aggregate', aggregate?: { __typename?: 'provider_aggregate_fields', count: number } | null | undefined } };


export const GetProvidersAggregateDocument = gql`
    query GetProvidersAggregate($where: provider_bool_exp) {
  provider_aggregate(where: $where) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetProvidersAggregateQuery__
 *
 * To run a query within a React component, call `useGetProvidersAggregateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProvidersAggregateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProvidersAggregateQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetProvidersAggregateQuery(baseOptions?: Apollo.QueryHookOptions<GetProvidersAggregateQuery, GetProvidersAggregateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProvidersAggregateQuery, GetProvidersAggregateQueryVariables>(GetProvidersAggregateDocument, options);
      }
export function useGetProvidersAggregateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProvidersAggregateQuery, GetProvidersAggregateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProvidersAggregateQuery, GetProvidersAggregateQueryVariables>(GetProvidersAggregateDocument, options);
        }
export type GetProvidersAggregateQueryHookResult = ReturnType<typeof useGetProvidersAggregateQuery>;
export type GetProvidersAggregateLazyQueryHookResult = ReturnType<typeof useGetProvidersAggregateLazyQuery>;
export type GetProvidersAggregateQueryResult = Apollo.QueryResult<GetProvidersAggregateQuery, GetProvidersAggregateQueryVariables>;