import {
  Center,
  Grid,
  Icon,
  Progress,
  useColorModeValue,
} from '@chakra-ui/react';
import { Photo_Insert_Input, StorageType } from '@webapp/graphql';
import { PropsWithChildren } from 'react';
import { AiFillFileAdd } from 'react-icons/ai';
import { Controller, useFormContext } from 'react-hook-form';
import { useUploadImages } from '@webapp/hooks';
import Preview, { PreviewProps } from './preview';
import './image-input.module.scss';

export interface ImageInputProps {
  id?: string;
  name: string;
  uploadingAnonymously?: boolean;
  emptyPreviewComponent?: React.ElementType;
  previewBorderRadius?: string;
  previewComponent?: React.ComponentType<PreviewProps>;
  shouldHaveBorder?: boolean;
  shouldDisplayPreview?: boolean;
  shouldDisplayProgress?: boolean;
  shouldDisplayEmptyPreview?: boolean;
  storageType?: StorageType;
  // eslint-disable-next-line camelcase
  defaultValues?: Photo_Insert_Input;
  workspaceId: string;
  capture: React.InputHTMLAttributes<HTMLInputElement>['capture'];
}

/* 
  WARNING: In order to use this component, it must be
  nested inside of a react-hook-form FormProvider 
*/
export function ImageInput({
  children,
  id,
  name,
  previewBorderRadius,
  previewComponent: PreviewComponent,
  shouldDisplayPreview = true,
  shouldDisplayProgress = false,
  shouldDisplayEmptyPreview = true,
  shouldHaveBorder = true,
  uploadingAnonymously = false,
  storageType = StorageType.Public,
  defaultValues,
  workspaceId,
  capture,
}: PropsWithChildren<ImageInputProps>) {
  const { setValue, control } = useFormContext();

  const {
    images: filesToUpload,
    getInputProps,
    getRootProps,
    isDragActive,
    progress,
  } = useUploadImages({
    defaultValues: defaultValues as any,
    storageType,
    uploadingAnonymously,
    onUpload: (photo) => {
      if (storageType === StorageType.Public) {
        setValue(name, photo?.file?.url);
      } else {
        setValue(name, photo.id);
      }
    },
    onAnonymousUpload: (url) => {
      setValue(name, url);
    },
    workspaceId,
  });

  const dropText = isDragActive
    ? 'Drop the files here ...'
    : "Drag 'n' drop file here, or click to select files";

  const activeBg = useColorModeValue('gray.100', 'gray.600');

  const borderColor = useColorModeValue(
    isDragActive ? 'teal.300' : 'gray.300',
    isDragActive ? 'teal.500' : 'gray.500'
  );

  return (
    <Grid
      id={id}
      gridTemplateColumns="1fr"
      gridAutoRows="max-content"
      gridRowGap="15px"
      width="100%"
    >
      <Grid
        alignItems="center"
        gridAutoFlow="column"
        gridAutoColumns="max-content"
        gridTemplateColumns="1fr"
        gridColumnGap="16px"
        width="100%"
      >
        <Grid
          bg={isDragActive ? activeBg : 'transparent'}
          border={shouldHaveBorder ? '1px dashed' : 'none'}
          borderColor={borderColor}
          borderRadius={4}
          cursor="pointer"
          gridAutoFlow="column"
          gridColumnGap="15px"
          gridTemplateColumns={shouldDisplayPreview ? 'max-content 1fr' : '1fr'}
          padding="8px"
          transition="background-color 0.2s ease"
          width="100%"
          _hover={{ bg: `rgba(200, 200, 200, .1)` }}
          {...getRootProps()}
        >
          <Controller
            control={control}
            name={name}
            render={({ field: { value } }) => (
              <>
                <input capture={capture} {...getInputProps()} />
                {shouldDisplayPreview && (
                  <Preview
                    borderRadius={previewBorderRadius}
                    component={PreviewComponent}
                    files={filesToUpload}
                    defaultValue={value}
                    shouldDisplayEmptyPreview={shouldDisplayEmptyPreview}
                  />
                )}
              </>
            )}
          ></Controller>

          <Grid
            alignContent="center"
            gridAutoFlow="row"
            gridAutoRows="max-content"
          >
            {Boolean(children) && children}
            {!children && (
              <Center>
                <Icon as={AiFillFileAdd} mr={2} />
                <p>{dropText}</p>
              </Center>
            )}
          </Grid>
        </Grid>
      </Grid>
      {shouldDisplayProgress && <Progress value={progress} />}
    </Grid>
  );
}

export default ImageInput;
