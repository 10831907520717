import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetPrescriptionsUrlQueryVariables = Types.Exact<{
  locationProviderId: Types.Scalars['String'];
  clinicId?: Types.Maybe<Types.Scalars['Int']>;
  dosespotPatientId?: Types.Maybe<Types.Scalars['Int']>;
}>;


export type GetPrescriptionsUrlQuery = { __typename?: 'query_root', getPrescriptionsUrl?: { __typename?: 'AuthUrlOutput', url: string } | null | undefined };


export const GetPrescriptionsUrlDocument = gql`
    query GetPrescriptionsUrl($locationProviderId: String!, $clinicId: Int, $dosespotPatientId: Int) {
  getPrescriptionsUrl(
    locationProviderId: $locationProviderId
    clinicId: $clinicId
    dosespotPatientId: $dosespotPatientId
  ) {
    url
  }
}
    `;

/**
 * __useGetPrescriptionsUrlQuery__
 *
 * To run a query within a React component, call `useGetPrescriptionsUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPrescriptionsUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPrescriptionsUrlQuery({
 *   variables: {
 *      locationProviderId: // value for 'locationProviderId'
 *      clinicId: // value for 'clinicId'
 *      dosespotPatientId: // value for 'dosespotPatientId'
 *   },
 * });
 */
export function useGetPrescriptionsUrlQuery(baseOptions: Apollo.QueryHookOptions<GetPrescriptionsUrlQuery, GetPrescriptionsUrlQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPrescriptionsUrlQuery, GetPrescriptionsUrlQueryVariables>(GetPrescriptionsUrlDocument, options);
      }
export function useGetPrescriptionsUrlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPrescriptionsUrlQuery, GetPrescriptionsUrlQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPrescriptionsUrlQuery, GetPrescriptionsUrlQueryVariables>(GetPrescriptionsUrlDocument, options);
        }
export type GetPrescriptionsUrlQueryHookResult = ReturnType<typeof useGetPrescriptionsUrlQuery>;
export type GetPrescriptionsUrlLazyQueryHookResult = ReturnType<typeof useGetPrescriptionsUrlLazyQuery>;
export type GetPrescriptionsUrlQueryResult = Apollo.QueryResult<GetPrescriptionsUrlQuery, GetPrescriptionsUrlQueryVariables>;