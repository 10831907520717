import { AvailabilityRange } from '@webapp/graphql';
import dayjs, { Dayjs } from 'dayjs';
import _ from 'lodash';

export default function generateSuggestedTimes(
  availability: AvailabilityRange[],
  duration: number,
  interval: number,
  timezone?: string
) {
  if (!availability) return {};
  const suggestedTimes: {
    start: string;
    formattedDay: string;
    dateTime: Dayjs;
  }[] = [];

  const tz = timezone ? timezone : dayjs.tz.guess();

  availability.forEach((a) => {
    let start = dayjs(a.start, 'YYYY-MM-DD H:mm:ss+Z').tz(tz);
    const end = dayjs(a.end, 'YYYY-MM-DD H:mm:ss+Z').tz(tz);

    while (
      start.isBefore(end) &&
      start.add(duration - 1, 'minutes').isBefore(end)
    ) {
      suggestedTimes.push({
        start: start.tz(tz).format('hh:mm A'),
        dateTime: start.tz(tz),
        formattedDay: dayjs(start, 'YYYY-MM-DD H:mm:ss+Z')
          .tz(tz)
          .format('dddd, D MMMM'),
      });

      start = start.add(interval, 'minutes');
    }
  });

  return _.groupBy(suggestedTimes, 'formattedDay');
}
