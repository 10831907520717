import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { FileModel } from './file';
/**
 * A PatientStore model.
 */
// prettier-ignore
const Model = types.model("Patient").props({
  id: types.string,
  firstName: types.string,
  lastName: types.string,
  middleName: types.maybeNull(types.string),
//   attributes: types.maybeNull(types.map(AnyJsonValue)),
  profilePicture: types.maybeNull(types.model({ file: types.maybeNull(FileModel)})),
  email: types.maybeNull(types.string),
  attributes: types.maybeNull(
    types.model({
      firstName: types.maybeNull(types.string),
      lastName: types.maybeNull(types.string),
      email: types.maybeNull(types.string),
      middleName: types.maybeNull(types.string),
      status: types.maybeNull(types.string),
      dob: types.maybeNull(types.string),
      phoneNumber: types.maybeNull(types.string),
      profileNote: types.maybeNull(types.string),
      profilePicture: types.maybeNull(FileModel),
      dosespotPatientId: types.maybeNull(types.number),
      clinicId: types.maybeNull(types.number)
    })
  ),
}).actions((self) => ({
  
}
)).views(self => ({
    get name() {
        const firstName = self.attributes?.firstName || self.firstName
        const middleName = self.attributes?.middleName || self.middleName
        const lastName = self.attributes?.lastName || self.lastName

        return `${firstName} ${middleName ? `${middleName[0]  }.` : ''} ${lastName}`
    }
}))

export const PatientModel = types.snapshotProcessor(Model, {
  preProcessor(sn: any) {
    const patient = { ...sn };
    if (sn.attributes) {
      patient.attributes = { ...sn.attributes };
    }
    return patient;
  },
});

/**
 * The Patient instance.
 */
export type Patient = Instance<typeof PatientModel>;

/**
 * The data of a Patient.
 */
export type PatientSnapshot = SnapshotOut<typeof PatientModel>;
