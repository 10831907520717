import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type DeleteCustomFormResponseMutationVariables = Types.Exact<{
  where: Types.CustomFormResponse_Bool_Exp;
}>;


export type DeleteCustomFormResponseMutation = { __typename?: 'mutation_root', delete_customFormResponse?: { __typename?: 'customFormResponse_mutation_response', returning: Array<{ __typename?: 'customFormResponse', id: any }> } | null | undefined };


export const DeleteCustomFormResponseDocument = gql`
    mutation deleteCustomFormResponse($where: customFormResponse_bool_exp!) {
  delete_customFormResponse(where: $where) {
    returning {
      id
    }
  }
}
    `;
export type DeleteCustomFormResponseMutationFn = Apollo.MutationFunction<DeleteCustomFormResponseMutation, DeleteCustomFormResponseMutationVariables>;

/**
 * __useDeleteCustomFormResponseMutation__
 *
 * To run a mutation, you first call `useDeleteCustomFormResponseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCustomFormResponseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCustomFormResponseMutation, { data, loading, error }] = useDeleteCustomFormResponseMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDeleteCustomFormResponseMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCustomFormResponseMutation, DeleteCustomFormResponseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCustomFormResponseMutation, DeleteCustomFormResponseMutationVariables>(DeleteCustomFormResponseDocument, options);
      }
export type DeleteCustomFormResponseMutationHookResult = ReturnType<typeof useDeleteCustomFormResponseMutation>;
export type DeleteCustomFormResponseMutationResult = Apollo.MutationResult<DeleteCustomFormResponseMutation>;
export type DeleteCustomFormResponseMutationOptions = Apollo.BaseMutationOptions<DeleteCustomFormResponseMutation, DeleteCustomFormResponseMutationVariables>;