import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetCustomFormFieldResponsesQueryVariables = Types.Exact<{
  where?: Types.Maybe<Types.CustomFormFieldResponse_Bool_Exp>;
  limit?: Types.Maybe<Types.Scalars['Int']>;
  offset?: Types.Maybe<Types.Scalars['Int']>;
  orderBy?: Types.Maybe<Array<Types.CustomFormFieldResponse_Order_By> | Types.CustomFormFieldResponse_Order_By>;
}>;


export type GetCustomFormFieldResponsesQuery = { __typename?: 'query_root', customFormFieldResponse: Array<{ __typename?: 'customFormFieldResponse', id: any }> };


export const GetCustomFormFieldResponsesDocument = gql`
    query GetCustomFormFieldResponses($where: customFormFieldResponse_bool_exp, $limit: Int, $offset: Int, $orderBy: [customFormFieldResponse_order_by!]) {
  customFormFieldResponse(
    where: $where
    limit: $limit
    offset: $offset
    order_by: $orderBy
  ) {
    id
  }
}
    `;

/**
 * __useGetCustomFormFieldResponsesQuery__
 *
 * To run a query within a React component, call `useGetCustomFormFieldResponsesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomFormFieldResponsesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomFormFieldResponsesQuery({
 *   variables: {
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetCustomFormFieldResponsesQuery(baseOptions?: Apollo.QueryHookOptions<GetCustomFormFieldResponsesQuery, GetCustomFormFieldResponsesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCustomFormFieldResponsesQuery, GetCustomFormFieldResponsesQueryVariables>(GetCustomFormFieldResponsesDocument, options);
      }
export function useGetCustomFormFieldResponsesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCustomFormFieldResponsesQuery, GetCustomFormFieldResponsesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCustomFormFieldResponsesQuery, GetCustomFormFieldResponsesQueryVariables>(GetCustomFormFieldResponsesDocument, options);
        }
export type GetCustomFormFieldResponsesQueryHookResult = ReturnType<typeof useGetCustomFormFieldResponsesQuery>;
export type GetCustomFormFieldResponsesLazyQueryHookResult = ReturnType<typeof useGetCustomFormFieldResponsesLazyQuery>;
export type GetCustomFormFieldResponsesQueryResult = Apollo.QueryResult<GetCustomFormFieldResponsesQuery, GetCustomFormFieldResponsesQueryVariables>;