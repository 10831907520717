import React from 'react';
import {
  Box,
  VStack,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Text,
  Flex,
  useBreakpointValue,
} from '@chakra-ui/react';
import { toLocalePriceInCents } from '@webapp/util-formatting';
import { useInvoicePreviewContext } from './InvoicePreviewContext';

export const InvoicePreviewItems: React.FC = React.memo(() => {
  const { invoice, invoiceConfiguration } = useInvoicePreviewContext();

  const isMobile = useBreakpointValue({ base: true, md: false });
  const isVeryNarrow = useBreakpointValue({ base: true, sm: false });

  const receiptOptions = invoiceConfiguration?.receiptOptions || {
    showServiceDescription: false,
  };

  if (isVeryNarrow) {
    return (
      <VStack align="stretch" spacing={4}>
        {/* Compact layout for very narrow screens */}
        {invoice.genericLineItems.map((item) => (
          <Box key={item.id} p={2} borderWidth={1} borderRadius="md">
            <Text fontWeight="bold" fontSize="sm">
              {item.name}
            </Text>
            {receiptOptions.showServiceDescription &&
              item.service?.description && (
                <Text fontSize="xs" color="gray.600" mt={1}>
                  {item.service.description}
                </Text>
              )}
            <Flex justify="space-between" mt={2}>
              <Text fontSize="xs">Qty: {item.quantity}</Text>
              <Text fontSize="xs">
                Price: {toLocalePriceInCents(item.pricePerUnit)}
              </Text>
            </Flex>
            <Text fontSize="sm" fontWeight="bold" mt={1}>
              Total: {toLocalePriceInCents(item.preTaxTotal)}
            </Text>
          </Box>
        ))}
        {invoice.discountLineItems.map((discount) => (
          <Box key={discount.id} p={2} borderWidth={1} borderRadius="md">
            <Text fontWeight="bold" fontSize="sm">
              Discount: {discount.name}
            </Text>
            <Text fontSize="sm" color="green.500" mt={1}>
              -{toLocalePriceInCents(discount.discountTotal)}
            </Text>
          </Box>
        ))}
        {invoice.feeInvoiceLineItems.map((fee) => (
          <Box key={fee.id} p={2} borderWidth={1} borderRadius="md">
            <Text fontWeight="bold" fontSize="sm">
              Fee: {fee.fee.name}
            </Text>
            <Text fontSize="sm" mt={1}>
              {toLocalePriceInCents(fee.amount)}
            </Text>
          </Box>
        ))}
      </VStack>
    );
  }

  return (
    <Box overflowX="auto" width="100%">
      <Table variant="simple" size={isMobile ? 'sm' : 'md'}>
        <Thead>
          <Tr bg="gray.50">
            <Th fontSize={{ base: 'xs', md: 'sm' }}>Description</Th>
            <Th isNumeric fontSize={{ base: 'xs', md: 'sm' }}>
              Qty
            </Th>
            <Th isNumeric fontSize={{ base: 'xs', md: 'sm' }}>
              Price
            </Th>
            <Th isNumeric fontSize={{ base: 'xs', md: 'sm' }}>
              Total
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {invoice.genericLineItems.map((item) => (
            <React.Fragment key={item.id}>
              <Tr>
                <Td fontSize={{ base: 'xs', md: 'sm' }}>
                  {item.name}
                  {receiptOptions.showServiceDescription &&
                    item.service?.description && (
                      <Text
                        fontSize={{ base: '2xs', sm: 'xs' }}
                        color="gray.600"
                        mt={1}
                      >
                        {item.service.description}
                      </Text>
                    )}
                </Td>
                <Td isNumeric fontSize={{ base: 'xs', md: 'sm' }}>
                  {item.quantity}
                </Td>
                <Td isNumeric fontSize={{ base: 'xs', md: 'sm' }}>
                  {toLocalePriceInCents(item.pricePerUnit)}
                </Td>
                <Td isNumeric fontSize={{ base: 'xs', md: 'sm' }}>
                  {toLocalePriceInCents(item.preTaxTotal)}
                </Td>
              </Tr>
            </React.Fragment>
          ))}
          {invoice.discountLineItems.map((discount) => (
            <Tr key={discount.id}>
              <Td fontSize={{ base: 'xs', md: 'sm' }}>
                Discount: {discount.name}
              </Td>
              <Td></Td>
              <Td></Td>
              <Td
                isNumeric
                fontSize={{ base: 'xs', md: 'sm' }}
                color="green.500"
              >
                -{toLocalePriceInCents(discount.discountTotal)}
              </Td>
            </Tr>
          ))}
          {invoice.feeInvoiceLineItems.map((fee) => (
            <Tr key={fee.id}>
              <Td fontSize={{ base: 'xs', md: 'sm' }}>Fee: {fee.fee.name}</Td>
              <Td></Td>
              <Td></Td>
              <Td isNumeric fontSize={{ base: 'xs', md: 'sm' }}>
                {toLocalePriceInCents(fee.amount)}
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  );
});

export default InvoicePreviewItems;
