import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
} from '@chakra-ui/react';
import { observer } from 'mobx-react-lite';
import { useStores } from '@webapp/state-models';

/* eslint-disable-next-line */
export interface PatientCreationModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const PatientCreationModal = observer(
  ({ isOpen, onClose }: PatientCreationModalProps) => {
    const { draftPhoto } = useStores();
    return (
      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent maxWidth={'60vw'}>
          <ModalHeader>
            {draftPhoto ? 'Configure Media Details' : 'Upload Photo or Video'}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>{/* <QuickPatientForm /> */}</ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
            <Button variant="ghost">Done</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  }
);

export default PatientCreationModal;
