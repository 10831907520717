import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { PatientSystemNoticeFieldsFragmentDoc } from '../../fragments/patientSystemNoticeFields.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type UpdatePatientSystemNoticesMutationVariables = Types.Exact<{
  where: Types.PatientSystemNotice_Bool_Exp;
  _set?: Types.Maybe<Types.PatientSystemNotice_Set_Input>;
}>;


export type UpdatePatientSystemNoticesMutation = { __typename?: 'mutation_root', update_patientSystemNotice?: { __typename?: 'patientSystemNotice_mutation_response', returning: Array<{ __typename?: 'patientSystemNotice', id: any, description: string, category: string, subcategory: string, seen: boolean, createdAt: any }> } | null | undefined };


export const UpdatePatientSystemNoticesDocument = gql`
    mutation updatePatientSystemNotices($where: patientSystemNotice_bool_exp!, $_set: patientSystemNotice_set_input) {
  update_patientSystemNotice(where: $where, _set: $_set) {
    returning {
      ...PatientSystemNoticeFields
    }
  }
}
    ${PatientSystemNoticeFieldsFragmentDoc}`;
export type UpdatePatientSystemNoticesMutationFn = Apollo.MutationFunction<UpdatePatientSystemNoticesMutation, UpdatePatientSystemNoticesMutationVariables>;

/**
 * __useUpdatePatientSystemNoticesMutation__
 *
 * To run a mutation, you first call `useUpdatePatientSystemNoticesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePatientSystemNoticesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePatientSystemNoticesMutation, { data, loading, error }] = useUpdatePatientSystemNoticesMutation({
 *   variables: {
 *      where: // value for 'where'
 *      _set: // value for '_set'
 *   },
 * });
 */
export function useUpdatePatientSystemNoticesMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePatientSystemNoticesMutation, UpdatePatientSystemNoticesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePatientSystemNoticesMutation, UpdatePatientSystemNoticesMutationVariables>(UpdatePatientSystemNoticesDocument, options);
      }
export type UpdatePatientSystemNoticesMutationHookResult = ReturnType<typeof useUpdatePatientSystemNoticesMutation>;
export type UpdatePatientSystemNoticesMutationResult = Apollo.MutationResult<UpdatePatientSystemNoticesMutation>;
export type UpdatePatientSystemNoticesMutationOptions = Apollo.BaseMutationOptions<UpdatePatientSystemNoticesMutation, UpdatePatientSystemNoticesMutationVariables>;