import { SelectOption } from '@webapp/types';

export const colors: SelectOption[] = [
  { label: 'purple', value: '#958DF1' },
  { label: 'red', value: '#F98181' },
  { label: 'orange', value: '#FBBC88' },
  { label: 'yellow', value: '#FAF594' },
  { label: 'blue', value: '#70CFF8' },
  { label: 'teal', value: '#009688' },
  { label: 'green', value: '#B9F18D' },
];
