import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetServicesExternalQueryVariables = Types.Exact<{
  where?: Types.Maybe<Types.Service_Bool_Exp>;
  limit?: Types.Maybe<Types.Scalars['Int']>;
  offset?: Types.Maybe<Types.Scalars['Int']>;
  orderBy?: Types.Maybe<Array<Types.Service_Order_By> | Types.Service_Order_By>;
}>;


export type GetServicesExternalQuery = { __typename?: 'query_root', service: Array<{ __typename?: 'service', id: any, name: string, requiresDeposit?: boolean | null | undefined, deposit?: number | null | undefined, onlineBookingRules?: any | null | undefined, serviceCategory?: { __typename?: 'serviceCategory', title: string } | null | undefined, serviceAddOns: Array<{ __typename?: 'serviceAddOn', isRequired: boolean, bookingOrder: number, serviceAddOn: { __typename?: 'service', id: any, name: string } }> }> };


export const GetServicesExternalDocument = gql`
    query GetServicesExternal($where: service_bool_exp, $limit: Int, $offset: Int, $orderBy: [service_order_by!]) {
  service(where: $where, limit: $limit, offset: $offset, order_by: $orderBy) {
    id
    name
    requiresDeposit
    deposit
    onlineBookingRules
    serviceCategory {
      title
    }
    serviceAddOns {
      serviceAddOn {
        id
        name
      }
      isRequired
      bookingOrder
    }
  }
}
    `;

/**
 * __useGetServicesExternalQuery__
 *
 * To run a query within a React component, call `useGetServicesExternalQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetServicesExternalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetServicesExternalQuery({
 *   variables: {
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetServicesExternalQuery(baseOptions?: Apollo.QueryHookOptions<GetServicesExternalQuery, GetServicesExternalQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetServicesExternalQuery, GetServicesExternalQueryVariables>(GetServicesExternalDocument, options);
      }
export function useGetServicesExternalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetServicesExternalQuery, GetServicesExternalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetServicesExternalQuery, GetServicesExternalQueryVariables>(GetServicesExternalDocument, options);
        }
export type GetServicesExternalQueryHookResult = ReturnType<typeof useGetServicesExternalQuery>;
export type GetServicesExternalLazyQueryHookResult = ReturnType<typeof useGetServicesExternalLazyQuery>;
export type GetServicesExternalQueryResult = Apollo.QueryResult<GetServicesExternalQuery, GetServicesExternalQueryVariables>;