import { Box, Flex } from '@chakra-ui/react';
import { RiCloseLine } from 'react-icons/ri';
import { useStores } from '@webapp/state-models';
import { observer } from 'mobx-react-lite';
import { audienceFilterSelectorToLabel, audienceFilterOpToStr } from './util';

export interface AudienceSelectedFiltersProps {
  editable: boolean;
}

export const AudienceSelectedFilters = observer(
  ({ editable }: AudienceSelectedFiltersProps) => {
    const { audience } = useStores();
    const selectedFilters = audience.predicate.predicates.map(
      (condition, idx) => (
        <Box p="1">
          <Box
            as="button"
            p="1"
            verticalAlign="center"
            bg="gray.100"
            borderRadius="md"
            display="flex"
            onClick={() => editable && audience.removeCondition(condition)}
            key={idx}
          >
            <Box fontSize="xs" p="1" color="#008399" fontWeight="bold">
              {audienceFilterSelectorToLabel(condition.target, condition.field)}
            </Box>
            <Box fontSize="xs" py="1">
              {audienceFilterOpToStr(condition.op)}
            </Box>
            <Box fontSize="xs" p="1" color="#008399" fontWeight="bold">
              {condition.label}
            </Box>
            {editable && (
              <Box fontSize="md" p="1">
                <RiCloseLine />
              </Box>
            )}
          </Box>
        </Box>
      )
    );

    return (
      <Flex spacing="0" flexWrap="wrap">
        {selectedFilters}
      </Flex>
    );
  }
);
