import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type UpdateCompensationStrategyMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
  set: Types.CompensationStrategyInsertInput;
}>;


export type UpdateCompensationStrategyMutation = { __typename?: 'mutation_root', updateCompensationStrategy: { __typename?: 'UpdateCompensationStrategyOutput', compensationStrategy?: { __typename?: 'compensationStrategy', id: any } | null | undefined } };


export const UpdateCompensationStrategyDocument = gql`
    mutation UpdateCompensationStrategy($id: uuid!, $set: CompensationStrategyInsertInput!) {
  updateCompensationStrategy(id: $id, set: $set) {
    compensationStrategy {
      id
    }
  }
}
    `;
export type UpdateCompensationStrategyMutationFn = Apollo.MutationFunction<UpdateCompensationStrategyMutation, UpdateCompensationStrategyMutationVariables>;

/**
 * __useUpdateCompensationStrategyMutation__
 *
 * To run a mutation, you first call `useUpdateCompensationStrategyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompensationStrategyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompensationStrategyMutation, { data, loading, error }] = useUpdateCompensationStrategyMutation({
 *   variables: {
 *      id: // value for 'id'
 *      set: // value for 'set'
 *   },
 * });
 */
export function useUpdateCompensationStrategyMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCompensationStrategyMutation, UpdateCompensationStrategyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCompensationStrategyMutation, UpdateCompensationStrategyMutationVariables>(UpdateCompensationStrategyDocument, options);
      }
export type UpdateCompensationStrategyMutationHookResult = ReturnType<typeof useUpdateCompensationStrategyMutation>;
export type UpdateCompensationStrategyMutationResult = Apollo.MutationResult<UpdateCompensationStrategyMutation>;
export type UpdateCompensationStrategyMutationOptions = Apollo.BaseMutationOptions<UpdateCompensationStrategyMutation, UpdateCompensationStrategyMutationVariables>;