import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type DeleteVitalsMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
}>;


export type DeleteVitalsMutation = { __typename?: 'mutation_root', delete_vitals_by_pk?: { __typename?: 'vitals', id: any } | null | undefined };


export const DeleteVitalsDocument = gql`
    mutation deleteVitals($id: uuid!) {
  delete_vitals_by_pk(id: $id) {
    id
  }
}
    `;
export type DeleteVitalsMutationFn = Apollo.MutationFunction<DeleteVitalsMutation, DeleteVitalsMutationVariables>;

/**
 * __useDeleteVitalsMutation__
 *
 * To run a mutation, you first call `useDeleteVitalsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteVitalsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteVitalsMutation, { data, loading, error }] = useDeleteVitalsMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteVitalsMutation(baseOptions?: Apollo.MutationHookOptions<DeleteVitalsMutation, DeleteVitalsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteVitalsMutation, DeleteVitalsMutationVariables>(DeleteVitalsDocument, options);
      }
export type DeleteVitalsMutationHookResult = ReturnType<typeof useDeleteVitalsMutation>;
export type DeleteVitalsMutationResult = Apollo.MutationResult<DeleteVitalsMutation>;
export type DeleteVitalsMutationOptions = Apollo.BaseMutationOptions<DeleteVitalsMutation, DeleteVitalsMutationVariables>;