import { Button, HStack, IconButton, Stack } from '@chakra-ui/react';
import {
  FaBold,
  FaItalic,
  FaRedo,
  FaStrikethrough,
  FaUnderline,
  FaUndo,
} from 'react-icons/fa';
import { Editor } from '@tiptap/core';
import { MdDraw, MdOutlineFormatListBulleted } from 'react-icons/md';
import { AiOutlineOrderedList } from 'react-icons/ai';
import { SelectOption } from '@webapp/types';
import { FiEdit3 } from 'react-icons/fi';
import { useState } from 'react';
import { RecordAudio } from '@webapp/webapp/ui-composites';
import { useFlagsmith } from 'flagsmith-react';
import { GoTasklist } from 'react-icons/go';
import { SmartTextEditorToolbarVersion } from './types';
import ColorSelector from '../color-selector';
import { colors } from './constants';
import TableToolbar from './TableToolbar';
import { BiTable } from 'react-icons/bi';

export interface ToolbarProps {
  editor: Editor;
  toolbarVersion?: SmartTextEditorToolbarVersion;
}

export default function Toolbar({
  editor,
  toolbarVersion = SmartTextEditorToolbarVersion.DEFAULT,
}: ToolbarProps) {
  const [color, setColor] = useState<SelectOption>({
    label: 'teal',
    value: '#009688',
  });

  const { hasFeature } = useFlagsmith();

  const changeColor = (selectOption: SelectOption | null): void => {
    if (!selectOption) return;

    setColor(selectOption);

    editor.chain().focus().setColor(selectOption.value).run();
  };

  const insertSelected = (): void => {
    const { state } = editor;
    const { from, to } = state.selection;

    if (from === to) return;

    const text = state.doc.textBetween(from, to, ' ');

    editor.commands.insertContent({
      type: 'highlightReplace',
      content: [
        {
          type: 'text',
          text,
        },
      ],
    });
  };

  function onTranscribe(text: string) {
    editor.commands.insertContent({
      type: 'paragraph',
      content: [
        {
          type: 'text',
          text,
        },
      ],
    });
  }

  const standardButtons = [
    {
      label: 'bold',
      onClick: () => editor.chain().focus().toggleBold().run(),
      icon: <FaBold />,
    },
    {
      label: 'italic',
      onClick: () => editor.chain().focus().toggleItalic().run(),
      icon: <FaItalic />,
    },
    {
      label: 'underline',
      onClick: () => editor.chain().focus().toggleUnderline().run(),
      icon: <FaUnderline />,
    },
    {
      label: 'strike',
      onClick: () => editor.chain().focus().toggleStrike().run(),
      icon: <FaStrikethrough />,
    },
    {
      label: 'tasklist',
      onClick: () => editor.chain().focus().toggleTaskList().run(),
      icon: <GoTasklist />,
    },
    {
      label: 'bulletList',
      onClick: () => editor.chain().focus().toggleBulletList().run(),
      icon: <MdOutlineFormatListBulleted />,
    },
    {
      label: 'orderedList',
      onClick: () => editor.chain().focus().toggleOrderedList().run(),
      icon: <AiOutlineOrderedList />,
    },
    {
      label: 'undo',
      onClick: () => editor.chain().focus().undo().run(),
      icon: <FaUndo />,
    },
    {
      label: 'redo',
      onClick: () => editor.chain().focus().redo().run(),
      icon: <FaRedo />,
    },
    {
      label: 'insert-image-markup',
      onClick: () => editor.commands.insertPhotoMarkup(),
      icon: <MdDraw />,
    },
    {
      label: 'insert-flowsheet',
      onClick: () => editor.commands.insertFlowsheet(),
      icon: <BiTable />,
    },
  ];

  return (
    <Stack>
      <HStack
        p={2}
        borderBottomWidth={1}
        borderColor={'grey.200'}
        overflowX={'auto'}
      >
        {hasFeature('dictation') && <RecordAudio onTranscribe={onTranscribe} />}
        {standardButtons.map(({ label, onClick, icon }) => (
          <IconButton
            key={label}
            aria-label={label}
            onClick={onClick}
            icon={icon}
            variant={editor.isActive(label) ? 'solid' : 'outline'}
            colorScheme={editor.isActive(label) ? 'teal' : 'grey'}
          />
        ))}

        {toolbarVersion === SmartTextEditorToolbarVersion.SMART_NOTE && (
          <Button
            onClick={insertSelected}
            colorScheme="teal"
            leftIcon={<FiEdit3 />}
          >
            Highlight
          </Button>
        )}
        {toolbarVersion === SmartTextEditorToolbarVersion.DEFAULT && (
          <HStack>
            <ColorSelector
              color={color}
              colors={colors}
              onChange={changeColor}
            />
            <Button onClick={() => editor.chain().focus().unsetColor().run()}>
              Clear color
            </Button>
          </HStack>
        )}
      </HStack>
      {editor && editor.isActive('table') && <TableToolbar editor={editor} />}
    </Stack>
  );
}
