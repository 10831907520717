import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetUserWorkspaceQueryVariables = Types.Exact<{
  workspaceId?: Types.Maybe<Types.Scalars['uuid']>;
  userId?: Types.Maybe<Types.Scalars['uuid']>;
}>;


export type GetUserWorkspaceQuery = { __typename?: 'query_root', userWorkspace: Array<{ __typename?: 'userWorkspace', id: any, isGoogleCalendarSynced?: boolean | null | undefined, isAppointmentScheduleSynced?: boolean | null | undefined, googleCalendarId?: string | null | undefined, nylasGrant?: string | null | undefined, defaultLocation?: { __typename?: 'location', id: any, name: string, timezone?: string | null | undefined } | null | undefined }> };


export const GetUserWorkspaceDocument = gql`
    query getUserWorkspace($workspaceId: uuid, $userId: uuid) {
  userWorkspace(where: {workspaceId: {_eq: $workspaceId}, userId: {_eq: $userId}}) {
    defaultLocation {
      id
      name
      timezone
    }
    id
    isGoogleCalendarSynced
    isAppointmentScheduleSynced
    googleCalendarId
    nylasGrant
  }
}
    `;

/**
 * __useGetUserWorkspaceQuery__
 *
 * To run a query within a React component, call `useGetUserWorkspaceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserWorkspaceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserWorkspaceQuery({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetUserWorkspaceQuery(baseOptions?: Apollo.QueryHookOptions<GetUserWorkspaceQuery, GetUserWorkspaceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserWorkspaceQuery, GetUserWorkspaceQueryVariables>(GetUserWorkspaceDocument, options);
      }
export function useGetUserWorkspaceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserWorkspaceQuery, GetUserWorkspaceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserWorkspaceQuery, GetUserWorkspaceQueryVariables>(GetUserWorkspaceDocument, options);
        }
export type GetUserWorkspaceQueryHookResult = ReturnType<typeof useGetUserWorkspaceQuery>;
export type GetUserWorkspaceLazyQueryHookResult = ReturnType<typeof useGetUserWorkspaceLazyQuery>;
export type GetUserWorkspaceQueryResult = Apollo.QueryResult<GetUserWorkspaceQuery, GetUserWorkspaceQueryVariables>;