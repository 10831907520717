/* eslint-disable max-classes-per-file */
import { createElement } from 'react';
import {
  PropertyGridEditorCollection,
  localization,
} from 'survey-creator-react';
import { ElementFactory, Question, Serializer, SvgRegistry } from 'survey-core';
import {
  SurveyQuestionElementBase,
  ReactQuestionFactory,
} from 'survey-react-ui';
import {
  Select,
  Input,
  FormControl,
  FormHelperText,
  FormLabel,
  Stack,
} from '@chakra-ui/react';

import Icon from './icon.svg?raw';

const CUSTOM_TYPE = 'how-did-you-find-us';

// A model for the new question type
export class HowDidYouFindUsModel extends Question {
  getType() {
    return CUSTOM_TYPE;
  }
}

// Register `HowDidYouFindUsModel` as a model for the `how-did-you-find-us` type
export function registerHowDidYouFindUs() {
  ElementFactory.Instance.registerElement(
    CUSTOM_TYPE,
    (name) => new HowDidYouFindUsModel(name)
  );
}

// Specify display names for the question type and its properties
const locale = localization.getLocale('');
locale.qt[CUSTOM_TYPE] = 'How did you find us?';

// Register an SVG icon for the question type
SvgRegistry.registerIconFromSvg(CUSTOM_TYPE, Icon);

// Add question type metadata for further serialization into JSON
Serializer.addClass(
  CUSTOM_TYPE,
  [],
  () => new HowDidYouFindUsModel('HowDidYouFindUs'),
  'question'
);

// A class that renders questions of the new type in the UI
export class SurveyQuestionHowDidYouFindUs extends SurveyQuestionElementBase {
  constructor(props) {
    super(props);
    this.state = { value: this.question.value };
    this.question.titleLocation = 'top';
    this.question.title = 'How did you hear about us?';
  }

  get question() {
    return this.questionBase;
  }

  get value() {
    return this.question.value;
  }

  // Support the read-only and design modes
  get style() {
    return this.question.getPropertyValue('readOnly') ||
      this.question.isDesignMode
      ? { pointerEvents: 'none' }
      : undefined;
  }

  renderElement() {
    return (
      <div style={this.style}>
        <Stack>
          <Select
            placeholder="Select option"
            onChange={(v) => (this.question.value = v.target.value)}
          >
            {this.question.howDidYouFindUsOptions?.map(
              ({ value, text }: { value: string; text: string }) => (
                <option value={value}>{text}</option>
              )
            )}
          </Select>
          {(this.question.value?.toLowerCase() === 'other' ||
            !this.question.howDidYouFindUsOptions
              ?.map((v) => v.value)
              .includes(this.question.value)) &&
            this.question.value && (
              <FormControl>
                <FormLabel>How did you hear about us?</FormLabel>
                <Input
                  onChange={(e) => (this.question.value = e.target.value)}
                />
              </FormControl>
            )}
        </Stack>
      </div>
    );
  }
}

// Register `SurveyQuestionHowDidYouFindUs` as a class that renders `how-did-you-find-us` questions
ReactQuestionFactory.Instance.registerQuestion(CUSTOM_TYPE, (props) =>
  createElement(SurveyQuestionHowDidYouFindUs, props)
);
