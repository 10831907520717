import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type InsertInvoiceConfigurationMutationVariables = Types.Exact<{
  object: Types.InvoiceConfiguration_Insert_Input;
}>;


export type InsertInvoiceConfigurationMutation = { __typename?: 'mutation_root', insert_invoiceConfiguration_one?: { __typename?: 'invoiceConfiguration', id: any, receiptOptions: any } | null | undefined };


export const InsertInvoiceConfigurationDocument = gql`
    mutation InsertInvoiceConfiguration($object: invoiceConfiguration_insert_input!) {
  insert_invoiceConfiguration_one(
    object: $object
    on_conflict: {constraint: invoiceConfiguration_workspaceId_key, update_columns: [receiptOptions]}
  ) {
    id
    receiptOptions
  }
}
    `;
export type InsertInvoiceConfigurationMutationFn = Apollo.MutationFunction<InsertInvoiceConfigurationMutation, InsertInvoiceConfigurationMutationVariables>;

/**
 * __useInsertInvoiceConfigurationMutation__
 *
 * To run a mutation, you first call `useInsertInvoiceConfigurationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertInvoiceConfigurationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertInvoiceConfigurationMutation, { data, loading, error }] = useInsertInvoiceConfigurationMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertInvoiceConfigurationMutation(baseOptions?: Apollo.MutationHookOptions<InsertInvoiceConfigurationMutation, InsertInvoiceConfigurationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertInvoiceConfigurationMutation, InsertInvoiceConfigurationMutationVariables>(InsertInvoiceConfigurationDocument, options);
      }
export type InsertInvoiceConfigurationMutationHookResult = ReturnType<typeof useInsertInvoiceConfigurationMutation>;
export type InsertInvoiceConfigurationMutationResult = Apollo.MutationResult<InsertInvoiceConfigurationMutation>;
export type InsertInvoiceConfigurationMutationOptions = Apollo.BaseMutationOptions<InsertInvoiceConfigurationMutation, InsertInvoiceConfigurationMutationVariables>;