/* eslint-disable camelcase */
import {
  Button,
  ButtonGroup,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  VStack,
} from '@chakra-ui/react';
import { joiResolver } from '@hookform/resolvers/joi';
import {
  InsertPatientMutation,
  useInsertPatientMutation,
} from '@webapp/graphql';
import { FormInput, HookForm } from '@webapp/ui';
import { SubmitHandler } from 'react-hook-form';
import toast from 'react-hot-toast';
import CreatePatientModalValidation from './CreatePatientModalValidation';

type InsertedPatient = NonNullable<
  InsertPatientMutation['insert_patient']
>['returning'][number];

interface CreatePatientFormValues {
  email?: string;
  firstName: string;
  lastName: string;
  phoneNumber?: string;
  workspaceId: string;
}

export interface CreatePatientModalProps {
  defaultValues: Partial<CreatePatientFormValues> & { workspaceId: string };
  isOpen: boolean;
  onClose: () => void;
  onCompleted?: (patient: InsertedPatient) => void;
  refetchQueries?: string[];
}

export function CreatePatientModal({
  defaultValues,
  isOpen,
  onClose,
  onCompleted = () => undefined,
  refetchQueries = [],
}: CreatePatientModalProps) {
  const initialValues: CreatePatientFormValues = {
    email: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
    ...defaultValues,
  };

  const [insertPatient] = useInsertPatientMutation({
    onCompleted: ({ insert_patient }) => {
      toast.success('Patient created');

      const [insertedPatient] = insert_patient?.returning ?? [];

      if (insertedPatient) {
        onCompleted(insertedPatient);
        onClose();
      }
    },
    onError: (error) => {
      toast.error(error.message);
    },
    refetchQueries,
  });

  const submit: SubmitHandler<CreatePatientFormValues> = (values) => {
    insertPatient({
      variables: {
        patient: {
          email: values.email,
          firstName: values.firstName,
          lastName: values.lastName,
          phoneNumber: values.phoneNumber,
          patientWorkspaces: {
            data: [
              {
                email: values.email,
                firstName: values.firstName,
                lastName: values.lastName,
                phoneNumber: values.phoneNumber,
                workspaceId: values.workspaceId,
              },
            ],
          },
        },
      },
    });
  };

  return (
    <HookForm
      defaultValues={initialValues}
      onSubmit={submit}
      resolver={joiResolver(CreatePatientModalValidation)}
    >
      {({ handleSubmit }) => (
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent overflow="hidden">
            <ModalHeader background="teal.500" color="white">
              Create New Patient
            </ModalHeader>
            <ModalBody>
              <VStack display="block" spacing="12px" width="100%">
                <HStack>
                  <FormInput
                    label="First Name"
                    name="firstName"
                    placeholder="John"
                  />
                  <FormInput
                    label="Last Name"
                    name="lastName"
                    placeholder="Doe"
                  />
                </HStack>
                <FormInput
                  label="Email"
                  name="email"
                  placeholder="example@example.com"
                  type="email"
                />
                <FormInput label="Phone #" name="phoneNumber" />
              </VStack>
            </ModalBody>
            <ModalFooter>
              <ButtonGroup>
                <Button onClick={onClose}>Cancel</Button>
                <Button colorScheme="teal" onClick={handleSubmit(submit)}>
                  Submit
                </Button>
              </ButtonGroup>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </HookForm>
  );
}

export default CreatePatientModal;
