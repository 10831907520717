import {
  FormLabel,
  Grid,
  Input,
  InputGroup,
  Select,
  Text,
  Textarea,
} from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import capitalize from 'lodash/capitalize';
import { useEffect, useMemo } from 'react';
import { FormInputProps } from '@webapp/types';
import { DEFAULT_INPUT_STYLE, DEFAULT_LABEL_STYLE } from './constants';
import PlacesInput from '../places-input/places-input';

export default function DefaultFormInput({
  defaultValue,
  id,
  inputStyle = DEFAULT_INPUT_STYLE,
  isEditable = true,
  label,
  labelAlign = 'center',
  labelPosition = 'top',
  labelStyle = DEFAULT_LABEL_STYLE,
  uneditableTextStyle = {},
  containerGridTemplate = '100px 1fr',
  name,
  placeholder,
  required = false,
  shouldDisplayError = true,
  type = 'text',
  withOptions = [],
  registerProps = {},
  htmlInputProps = {},
}: FormInputProps) {
  const methods = useFormContext();
  const {
    formState: { errors },
    register,
    getValues,
    setValue,
  } = methods;

  useEffect(() => {
    if (defaultValue) {
      setValue(name, defaultValue);
    }
  }, [defaultValue]);

  const inputId = id ?? name;

  const containerColumns = useMemo(() => {
    if (label) {
      if (labelPosition === 'left') {
        return containerGridTemplate;
      }
    }

    return '1fr';
  }, [label, labelPosition]);

  return (
    <Grid
      alignItems={labelAlign}
      className="input-outer"
      gridAutoRows="max-content"
      gridRowGap="6px"
      gridTemplateColumns={containerColumns}
    >
      {Boolean(label) && (
        <FormLabel
          sx={{
            color:
              'var(--sjs-font-questiontitle-color, var(--sjs-general-forecolor, var(--foreground, #161616)))',
          }}
          className="input-label"
          htmlFor={inputId}
          margin="0 0 0 0"
          {...labelStyle}
        >
          {label}
        </FormLabel>
      )}
      {/* Inner Content */}
      <Grid className="input-inner" gridRowGap="8px">
        {!isEditable && (
          <Text
            data-value-for={name}
            color="#525257"
            {...uneditableTextStyle}
            noOfLines={1}
            wordBreak="break-all"
          >
            {defaultValue || getValues(name)}
          </Text>
        )}
        {isEditable && (
          <>
            {type === 'select' && (
              <Select
                placeholder={placeholder ?? 'Select Option'}
                defaultValue={defaultValue as string}
                id={inputId}
                sx={{
                  color:
                    'var(--sjs-font-questiontitle-color, var(--sjs-general-forecolor, var(--foreground, #161616)))',
                }}
                {...inputStyle}
                {...register(name, {
                  required: required
                    ? `${capitalize(
                        name.split(/(?=[A-Z])+/g).join(' ')
                      )} is required`
                    : undefined,
                })}
              >
                {withOptions.map((option) => (
                  <option
                    key={`select-input-${name}-${option.label}`}
                    value={option.value}
                  >
                    {option.label}
                  </option>
                ))}
              </Select>
            )}
            {(type === 'places' || type === 'address') && (
              <PlacesInput
                name={name}
                shouldSetAddressComponents={type === 'address'}
              />
            )}
            {type === 'textarea' && (
              <Textarea
                className="input-input"
                errorBorderColor="red.300"
                id={inputId}
                {...htmlInputProps}
                isInvalid={errors[name]}
                placeholder={placeholder}
                {...inputStyle}
                {...register(name, {
                  required: required
                    ? `${capitalize(
                        name.split(/(?=[A-Z])+/g).join(' ')
                      )} is required`
                    : undefined,
                  ...(registerProps && registerProps),
                })}
              />
            )}

            {!['address', 'places', 'select', 'textarea'].includes(type) && (
              <InputGroup className="input-group">
                <Input
                  className="input-input"
                  errorBorderColor="red.300"
                  id={inputId}
                  isInvalid={errors[name]}
                  placeholder={placeholder}
                  type={type}
                  {...inputStyle}
                  {...htmlInputProps}
                  {...register(name, {
                    required: required
                      ? `${capitalize(
                          name.split(/(?=[A-Z])+/g).join(' ')
                        )} is required`
                      : undefined,
                    ...(registerProps && registerProps),
                  })}
                />
              </InputGroup>
            )}

            {shouldDisplayError && (
              <ErrorMessage
                errors={errors}
                name={name}
                render={({ message }) => (
                  <Text
                    className="input-error"
                    color="red.500"
                    _before={{
                      display: 'inline',
                      content: '"⚠ "',
                    }}
                  >
                    {message}
                  </Text>
                )}
              />
            )}
          </>
        )}
      </Grid>
    </Grid>
  );
}
