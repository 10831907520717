import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetOrganizationByDomainQueryVariables = Types.Exact<{
  domain: Types.Scalars['String'];
}>;


export type GetOrganizationByDomainQuery = { __typename?: 'query_root', organizationConfiguration: Array<{ __typename?: 'organizationConfiguration', whitelabelingEnabled: boolean, darkLogo: string, lightLogo: string, darkLogoSmall: string, lightLogoSmall: string, whitelabelDomain?: string | null | undefined, termsAndConditionsLink?: string | null | undefined, privacyPolicyLink?: string | null | undefined, organization: { __typename?: 'organization', name: string } }> };


export const GetOrganizationByDomainDocument = gql`
    query GetOrganizationByDomain($domain: String!) {
  organizationConfiguration(where: {whitelabelDomain: {_eq: $domain}}) {
    whitelabelingEnabled
    darkLogo
    lightLogo
    darkLogoSmall
    lightLogoSmall
    whitelabelDomain
    termsAndConditionsLink
    privacyPolicyLink
    organization {
      name
    }
  }
}
    `;

/**
 * __useGetOrganizationByDomainQuery__
 *
 * To run a query within a React component, call `useGetOrganizationByDomainQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationByDomainQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationByDomainQuery({
 *   variables: {
 *      domain: // value for 'domain'
 *   },
 * });
 */
export function useGetOrganizationByDomainQuery(baseOptions: Apollo.QueryHookOptions<GetOrganizationByDomainQuery, GetOrganizationByDomainQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrganizationByDomainQuery, GetOrganizationByDomainQueryVariables>(GetOrganizationByDomainDocument, options);
      }
export function useGetOrganizationByDomainLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrganizationByDomainQuery, GetOrganizationByDomainQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrganizationByDomainQuery, GetOrganizationByDomainQueryVariables>(GetOrganizationByDomainDocument, options);
        }
export type GetOrganizationByDomainQueryHookResult = ReturnType<typeof useGetOrganizationByDomainQuery>;
export type GetOrganizationByDomainLazyQueryHookResult = ReturnType<typeof useGetOrganizationByDomainLazyQuery>;
export type GetOrganizationByDomainQueryResult = Apollo.QueryResult<GetOrganizationByDomainQuery, GetOrganizationByDomainQueryVariables>;