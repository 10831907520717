import React from 'react';
import { Grid, VStack, Text, css } from '@chakra-ui/react';
import { formatName } from '@webapp/util-formatting';
import { configuredDayjs } from '@webapp/util-time';
import { useInvoicePreviewContext } from './InvoicePreviewContext';

export const InvoicePreviewDetails: React.FC = React.memo(() => {
  const { invoice, invoiceConfiguration } = useInvoicePreviewContext();

  const receiptOptions = invoiceConfiguration?.receiptOptions || {
    showDateOfService: false,
    showProviderNPI: false,
  };

  return (
    <Grid
      templateColumns={{ base: '1fr', sm: 'repeat(2, 1fr)' }}
      gap={4}
      css={css({ '@media print': { gridTemplateColumns: 'repeat(2, 1fr)' } })}
    >
      <VStack align="start" spacing={1}>
        <Text
          fontWeight="bold"
          fontSize={{ base: 'sm', md: 'md' }}
          css={css({ '@media print': { fontSize: 'md' } })}
        >
          Date Issued:
        </Text>
        <Text
          fontSize={{ base: 'sm', md: 'md' }}
          css={css({ '@media print': { fontSize: 'md' } })}
        >
          {configuredDayjs(invoice.createdAt).format('LL')}
        </Text>
      </VStack>
      <VStack align="start" spacing={1}>
        <Text
          fontWeight="bold"
          fontSize={{ base: 'sm', md: 'md' }}
          css={css({ '@media print': { fontSize: 'md' } })}
        >
          Due Date:
        </Text>
        <Text
          fontSize={{ base: 'sm', md: 'md' }}
          css={css({ '@media print': { fontSize: 'md' } })}
        >
          {configuredDayjs(invoice.dueDate).format('LL')}
        </Text>
      </VStack>
      {receiptOptions.showDateOfService && invoice.appointment && (
        <VStack align="start" spacing={1}>
          <Text
            fontWeight="bold"
            fontSize={{ base: 'sm', md: 'md' }}
            css={css({ '@media print': { fontSize: 'md' } })}
          >
            Date of Service:
          </Text>
          <Text
            fontSize={{ base: 'sm', md: 'md' }}
            css={css({ '@media print': { fontSize: 'md' } })}
          >
            {configuredDayjs(invoice.appointment.startTime).format('LL')}
          </Text>
        </VStack>
      )}
      <VStack align="start" spacing={1}>
        <Text
          fontWeight="bold"
          fontSize={{ base: 'sm', md: 'md' }}
          css={css({ '@media print': { fontSize: 'md' } })}
        >
          Patient:
        </Text>
        <Text
          fontSize={{ base: 'sm', md: 'md' }}
          css={css({ '@media print': { fontSize: 'md' } })}
        >
          {formatName(invoice.patient)}
        </Text>
        <Text
          fontSize={{ base: 'sm', md: 'md' }}
          css={css({ '@media print': { fontSize: 'md' } })}
        >
          {invoice.address}
        </Text>
      </VStack>
      {invoice.provider && (
        <VStack align="start" spacing={1}>
          <Text
            fontWeight="bold"
            fontSize={{ base: 'sm', md: 'md' }}
            css={css({ '@media print': { fontSize: 'md' } })}
          >
            Provider:
          </Text>
          <Text
            fontSize={{ base: 'sm', md: 'md' }}
            css={css({ '@media print': { fontSize: 'md' } })}
          >
            {formatName(invoice.provider)}
          </Text>
          {receiptOptions.showProviderNPI && invoice.provider.npi && (
            <Text
              fontSize={{ base: 'sm', md: 'md' }}
              css={css({ '@media print': { fontSize: 'md' } })}
            >
              NPI: {invoice.provider.npi}
            </Text>
          )}
        </VStack>
      )}
      <VStack align="start" spacing={1}>
        <Text
          fontWeight="bold"
          fontSize={{ base: 'sm', md: 'md' }}
          css={css({ '@media print': { fontSize: 'md' } })}
        >
          Location:
        </Text>
        <Text
          fontSize={{ base: 'sm', md: 'md' }}
          css={css({ '@media print': { fontSize: 'md' } })}
        >
          {invoice.location.name}
        </Text>
      </VStack>
    </Grid>
  );
});

export default InvoicePreviewDetails;
