/* eslint-disable camelcase */
import {
  GraphqlClient,
  GetUserByFirebaseUidDocument,
  GetUserByFirebaseUidQueryResult,
  Photo,
  UserWorkspace,
  GetAudienceDocument,
  GetEmailTemplateDocument,
  Appointment_Bool_Exp,
  ListAppointmentsScheduleDocument,
  AppointmentFieldsScheduleFragment,
  PatientFieldsFragment,
  ProviderFieldsFragment,
  SearchProviderScheduleDocument,
  ListAccessibleWorkspacesDocument,
  ListAccessibleWorkspacesQueryResult,
  ProviderSchedule,
  Appointment,
} from '@webapp/graphql';
import * as Sentry from '@sentry/react';
import { datadogRum } from '@datadog/browser-rum';
import { Instance, SnapshotOut, types, flow, detach } from 'mobx-state-tree';
import { toast } from 'react-hot-toast';
import { getAuth, signOut } from 'firebase/auth';
import _, { cloneDeep } from 'lodash';
import { configuredDayjs as dayjs } from '@webapp/util-time';
import { WorkspaceFieldsFragment } from 'libs/graphql/src/fragments/workspace.fragment.generated';
import { OpUnitType } from 'dayjs';
import { formatName } from '@webapp/util-formatting';
import { DateType } from '@mobiscroll/react/dist/src/core/util/datetime';
import { getIpAddress } from '@webapp/utils';
import { UserModel } from './models/user';
import { WorkspaceModel } from './models/workspace';
import { LeadFormModel, LeadForm } from './models/lead-form';
import { PhotoModel, Photo as MSTPhoto } from './models/photo';
import {
  AppointmentModel,
  UIModel,
  AudienceModel,
  EmailTemplateModel,
  PatientModel,
} from './models';
import { CalendarEventModel, CalendarEvent } from './models/calendarEventModel';
import {
  CalendarResource,
  CalendarResourceModel,
} from './models/calendarResourceModel';
import { CalendarConfiguration } from '../../../webapp/pages/src/lib/workspace-configuration-page/calendarConfiguration';
import { CustomFormModel } from './models/customFormModel';
import { LocalSettings } from './models/local-settings';
/**
 * A RootStore model.
 */
// prettier-ignore
export const RootStoreModel = types.model("RootStore").props({
    user: types.maybeNull(UserModel),
    workspace: types.maybeNull(WorkspaceModel),
    cachedMediaList: types.maybeNull(types.array(PhotoModel)),
    cachedAppointmentList: types.maybeNull(types.array(AppointmentModel)),
    calendarCurrentEventDate: types.optional(types.string, dayjs().format('YYYY-MM-DD')),
    calendarEvents: types.optional(types.array(CalendarEventModel), []),
    calendarResources: types.optional(types.array(CalendarResourceModel), []),
    calendarFilterDate: types.optional(types.string, dayjs().format('YYYY-MM-DD')),
    calendarCacheTime: types.optional(types.string, dayjs().unix().toString()),
    calendarTimeZone: types.optional(types.string, dayjs.tz.guess()),
    draftLeadForm: types.maybeNull(LeadFormModel),
    draftPhotos: types.optional(types.array(PhotoModel),[]),
    currentDraftPhotoIndex: types.optional(types.number, 0),
    draftAppointment: types.maybeNull(AppointmentModel),
    draftBeforePhoto: types.maybeNull(types.reference(PhotoModel)),
    draftAfterPhoto: types.maybeNull(types.reference(PhotoModel)),
    bulkFilesUploaded: types.optional(types.array(types.string),[]),
    bulkFilesUploading: types.optional(types.boolean, false),
    socialMediaBeforeAndAfterFlow: types.maybeNull(types.string),
    outstandingForms: types.optional(types.array(types.string),[]),
    outstandingConsents: types.optional(types.array(types.string),[]),
    audience: types.optional(AudienceModel, { id: null }),
    emailTemplate: types.optional(EmailTemplateModel, {audienceType: 'active', includesPromotion: false, isActive: true, allowResend: false, sendType: 'now' }),
    ui: types.optional(UIModel, {}),
    localSettings: types.optional(LocalSettings, {}),
    draftCustomForm: types.maybeNull(CustomFormModel),
    patient: types.maybeNull(PatientModel)
})
.views((self) => ({
    get calendarFilterDateObject() {
        return dayjs(self.calendarFilterDate).toDate();
    },
    get draftPhoto(): MSTPhoto | null  {
        if(self.draftPhotos.length > 0) {
            return self.draftPhotos[self.currentDraftPhotoIndex]
        } 
            return null
        
    },
    get calendarEventsSnapshot(): SnapshotOut<typeof CalendarEventModel>[] {
        return JSON.parse(JSON.stringify(self.calendarEvents.toJSON()))
    },
    get calendarResourcesSnapshot(): SnapshotOut<typeof CalendarResourceModel>[] {
        return JSON.parse(JSON.stringify(self.calendarResources.toJSON()))
    }

}))
.views(self => ({
    get boardAppointments(): any {
        const defaultMap = {
            'BOOKED': [],
            'CONFIRMED': [],
            'CHECKED_IN': [],
            'CHECKED_OUT': [],
            'COMPLETED': [],
            'CANCELED': [],
            'NO_SHOW': [],
        }
        const appointmentMap = _.groupBy(self.cachedAppointmentList, 'status')
        const mergedMap = { ...defaultMap, ...appointmentMap}
       
       
        return mergedMap
    }
}))
.actions((self) => ({
    getCachedAppointment(id: string) {
        return self.cachedAppointmentList?.find((appointment) => appointment.id === id)
    },
   
    setCalendarCurrentEventDate(date: string) {
      self.calendarCurrentEventDate = date;
    },
    setCalendarFilterDate(date: Date | DateType) {
        self.calendarFilterDate = dayjs(date).format('YYYY-MM-DD')
    },
    setCalendarTimeZone(timezone: string) {
      self.calendarTimeZone = timezone
    },
  setDraftAppointment(appointment: any | null) {
        self.draftAppointment = appointment ? AppointmentModel.create(appointment) : null
    },
    setCurrentDraftPhotoIndex(i:number) {
        self.currentDraftPhotoIndex = i;
    },
    setBulkFilesUploading(value:boolean) { self.bulkFilesUploading = value},
    setSocialMediaBeforeAndAfterFlow(value: string | null) { self.socialMediaBeforeAndAfterFlow = value},
    setOutstandingConsents(value: string[]) { self.outstandingConsents.clear(); self.outstandingConsents.push(...value)},
    setOutstandingForms(value: string[]) { self.outstandingForms.clear(); self.outstandingForms.push(...value)},
    setAudience(audience: any) {
        self.audience = AudienceModel.create(audience);
    },
    addCalendarResource(resource: CalendarResource) {
        self.calendarResources.push(resource);
    },
    addBulkFileUploaded(file:string) {
        self.bulkFilesUploaded.push(file)
    },
    clearBulkFileUploaded() {
        self.bulkFilesUploaded.clear()
    },
    clearDraftPhotos() {
        self.currentDraftPhotoIndex = 0;
        // @ts-expect-error
        self.draftPhotos = []
    },
    setDraftPhotos(photos: MSTPhoto[]) {
        // @ts-expect-error
        self.draftPhotos = cloneDeep(photos)
    },
    appendToDraftPhotos(photo: MSTPhoto) {
        self.draftPhotos.push(photo)
    },
    initializeUser: flow(function *(user?:any) {
        try {
            if(!user) {
                return console.error(`No user to initialize with`, user)
            }
            
            const token = yield user.getIdToken()
            try {
                localStorage.setItem(`prospyr_token_${import.meta.env.VITE_ENV}`, token);
            } catch(err) {
                console.log(err, 'localStorage init err')
            }
            
            const masqueradingAs = localStorage.getItem(
                `prspr_msq_${import.meta.env.VITE_ENV}`
              );
            
            // if we are masquerading as patient-portal short circuit this
            if(masqueradingAs) return {}

            const client = GraphqlClient()
            
            const { data }: GetUserByFirebaseUidQueryResult = yield client.query({query: GetUserByFirebaseUidDocument, variables: {
                uid: user?.uid
            }}).catch(err => console.log)
            const ipAddress = yield getIpAddress()
           

            if(data?.user?.[0]?.userWorkspaces?.[0]?.workspace?.workspaceConfiguration?.ipWhitelistEnabled) {
                const userWorkspace = data?.user?.[0]?.userWorkspaces?.[0];
             
                const ipWhitelist = data?.user?.[0]?.userWorkspaces?.[0]?.workspace?.workspaceConfiguration?.ipWhitelist
                console.log(ipWhitelist, ipAddress, 'IP CHECK')
                if(userWorkspace?.role !== 'owner' && !ipWhitelist.includes(ipAddress) && window.location.pathname !== '/security-block') {
                    window.location.href = '/security-block'
                }
            }
            
            if((data?.user || []).length > 0) {
                self.user = UserModel.create(data?.user[0])
                
                if(data?.user[0].userWorkspaces) {
                    const selectedWorkspaceId = localStorage.getItem(`prspr_wid_${import.meta.env.VITE_ENV}`)
                    let workspaceToLoad = data?.user[0].userWorkspaces[0].workspace
                    if(selectedWorkspaceId) {
                        const selectedWorkspace = data?.user[0].userWorkspaces?.find((uw:any) => uw.workspace.id === selectedWorkspaceId)

                        if(selectedWorkspace) {
                            workspaceToLoad = (selectedWorkspace as UserWorkspace).workspace
                        }
                    } else {
                        try {
                            localStorage.setItem(`prspr_wid_${import.meta.env.VITE_ENV}`, workspaceToLoad.id)
                        } catch(err) {
                            console.log(err, ' init err')
                        }
                       
                    }
                    self.workspace = WorkspaceModel.create(workspaceToLoad as any)
                }
              
                
                try {
                    // Identify User for Datadog RUM
                    datadogRum.setUser({
                        id: self.user.id,
                        name: `${self.user.firstName} ${self.user.lastName}`,
                        email: self.user.email as string,
                        workspaceId: self.workspace?.id,
                        workspaceName: self.workspace?.name
                    })
                    // Identify User for Sentry
                    Sentry.setUser({
                        id: self.user.id,
                        email: self.user.email as string,
                        workspaceId: self.workspace?.id,
                    })
                } catch(err) {
                    console.log(err, 'error identifying user for datadog and sentry')
                }
              try {
                if (process.env.VITE_ENV !== 'development') {
                   window.Intercom('boot', {
                     api_base: 'https://api-iam.intercom.io',
                     app_id: 'yoa5y39m',
                     user_id: self.user.id, // User ID
                     company: {
                       company_id: self.workspace?.id, // Company ID
                       name: self.workspace?.name, // Company name
                     },
                     role: self.user.role, // User role
                     name: `${self.user.firstName} ${self.user.lastName}`, // Full name
                     email: self.user.email, // the email for your user
                   });
                }
              } catch(err) {
                  console.log(err, 'Error configuring intercom')
              }
                
              
            }
            if (self.workspace) localStorage.setItem(`prspr_wid_${import.meta.env.VITE_ENV}`, self.workspace.id)
          
            // Set calendar timezone
            const userWorkspace = self.user?.userWorkspaces.find(
                (uw) => uw.workspaceId === self.workspace?.id
            );

            const { lockTimezoneToDefaultLocation, defaultLocation } = userWorkspace || {
                lockTimezoneToDefaultLocation: false,
                defaultLocation: null,
            };
            
            if (
                lockTimezoneToDefaultLocation &&
                defaultLocation &&
                defaultLocation.timezone
            ) {
               self.calendarTimeZone = defaultLocation.timezone;
            } else if (self.workspace?.workspaceConfiguration?.timezone) {
                self.calendarTimeZone = self.workspace?.workspaceConfiguration?.timezone;
            }
          
            return { user: self.user, workspace: self.workspace }
             
        } catch(err) {
            const error = <Error>err;


            toast.error(error.message)
        }
    }),
    setCachedMediaList(photos: Photo[]) {
        // @ts-expect-error
        self.cachedMediaList = []
        photos.map(photo => self.cachedMediaList?.push(PhotoModel.create(photo)))
    },
    addToCachedMediaList(photo: MSTPhoto) {
        self.cachedMediaList?.push(photo)
    },
    setDraftLeadForm(draftLeadForm: LeadForm) {
        self.draftLeadForm = draftLeadForm
    },
  
    setDraftBeforePhoto(photo: MSTPhoto) {
        self.draftBeforePhoto = photo
    },
    setDraftAfterPhoto(photo: MSTPhoto) {
        self.draftAfterPhoto = photo
    },
    clearBeforeAfterDraft() {
        self.draftAfterPhoto = null;
        self.draftBeforePhoto = null
    },
    setWorkspace(workspace: WorkspaceFieldsFragment) {
        // @ts-expect-error inaccurate
        self.workspace = {
            ...workspace,
            logo: workspace.logo ?? null,
            payrixMerchantId: workspace.payrixMerchantId ?? null,
        };

        localStorage.setItem(`prspr_wid_${import.meta.env.VITE_ENV}`, workspace.id)
    },
    logout: flow(function*() {
        const auth = getAuth();
        yield signOut(auth);
        self.user = null
        self.workspace = null
        localStorage.removeItem(`prospyr_token_${import.meta.env.VITE_ENV}`);
        window.location.reload()
    }),
    loadAppointments: flow(function *({
        providersFilter = [],
        locationsFilter = [],
        serviceFilter = [],
        startDate = null,
        range = null,
        whereOverride = {},
        quiet = false
    }: {
        providersFilter?: string[] 
        locationsFilter?: string[]
        serviceFilter?: string[]
        startDate?: Date | null
        quiet?: boolean
        range?: OpUnitType | null
        whereOverride?: Appointment_Bool_Exp
    } = {}) {
        const client = GraphqlClient()

        // Pull provider filter from local storage or set defaut
        if (!providersFilter.length) {
          providersFilter = JSON.parse(
            localStorage.getItem(
              `prspr_${import.meta.env.VITE_ENV}_${
                self.workspace?.id
              }_providersFilter`
            ) || '[]'
          ).map((o) => o?.value as string);
        }

        // check for provider filter override
        if(self.workspace?.workspaceConfiguration?.providersOnlySeeOwnSchedule && self.user?.providerId && self.user?.role === 'provider') {
            providersFilter = [self.user?.providerId]
        }

        // Pull location filter from local storage or set defaut
        if (!locationsFilter.length) {
          locationsFilter = JSON.parse(
            localStorage.getItem(
              `prspr_${import.meta.env.VITE_ENV}_${
                self.workspace?.id
              }_locationsFilter`
            ) || '[]'
          ).map((o) => o?.value as string);
        }
        // Pull service filter from local storage or set defaut
        if (!serviceFilter.length) {
          serviceFilter = JSON.parse(
            localStorage.getItem(
              `prspr_${import.meta.env.VITE_ENV}_${
                self.workspace?.id
              }_servicesFilter`
            ) || '[]'
          ).map((o) => o?.value as string);
        }
      
        // Pull start date from local storage or set defaut
        if (!startDate) {
          const dateFilter = localStorage.getItem(
            `prspr_${import.meta.env.VITE_ENV}_${self.workspace?.id}_dateFilter`
          );
          startDate = dateFilter ? dayjs(dateFilter).toDate() : dayjs().toDate();
        }
        // Pull range from local storage or set defaut
        if (!range) {
          const view = JSON.parse(
            localStorage.getItem(
              `prspr_${import.meta.env.VITE_ENV}_${
                self.workspace?.id
              }_calendarView`
            ) || ''
          );
          const parseViewType = view.schedule
            ? view.schedule?.type
            : view?.calendar?.type;
          range = parseViewType || 'week';
        }

        const endDate = dayjs(startDate || self.calendarFilterDate).endOf(range)

        const where : Appointment_Bool_Exp = {
            isArchived: { _eq: false },
            workspaceId: {_eq: self.workspace?.id},
            ...(self.ui.hideCancelledAppointments && {
              status: { _nin: ['CANCELED', 'NO_SHOW'] },
            }),
            ...whereOverride
        }
      
        if (!where._and) {
          where._and = [];
        }

        const timerangeConstraint = {
          _or: [
            {
              timerange: {
                _gte: JSON.stringify([
                  dayjs(startDate).startOf(range),
                  dayjs(startDate).startOf(range),
                ]),
                _lte: JSON.stringify([endDate, endDate]),
              },
            },
            {
              _and: [
                { startTime: { _lte: endDate } },
                { endTime: { _gte: dayjs(startDate).startOf(range) } },
              ],
            },
            {
              isRecurring: { _eq: true },
              recurringStartDate: { _lte: endDate.format('YYYY-MM-DD') },
              recurringEndDate: {
                _gte: dayjs(startDate).startOf(range).format('YYYY-MM-DD'),
              },
            },
          ],
        };
      
        where._and.push(timerangeConstraint);

        if (providersFilter.length > 0) {
          const providerFilterConstraint = {
            _or: [
              {
                internalAppointmentProviders: {
                  providerId: { _in: providersFilter },
                },
              },
              {
                appointmentServices: {
                  providerId: { _in: providersFilter }
                }
              },
              { providerId: { _in: providersFilter } },
            ],
          };
          where._and.push(providerFilterConstraint);
        }

        if(locationsFilter.length > 0) {
            where.locationId = { _in: locationsFilter }
        }

        if(serviceFilter.length > 0) {
            where.appointmentServices = {
                serviceId: { _in: serviceFilter }
            }
        }

        self.ui.setLoadingAppointments(!quiet)
        const { data } = yield client.query({query: ListAppointmentsScheduleDocument, variables: { where } })
        
        function figureOutProviders(type:string, internalAppointmentProviders: {provider: ProviderFieldsFragment}[], provider: ProviderFieldsFragment | null | undefined) {
            
          if (type === 'patient_appointment') {
                return provider ? [provider?.id] : ['No Provider']
            } 
                return internalAppointmentProviders.map(iap => iap.provider.id)
            
        }

        const { data: userWorkspaceData }: ListAccessibleWorkspacesQueryResult = yield client.query({
            query: ListAccessibleWorkspacesDocument,
            variables: {
                userId: self.user?.id
            },
        })

        const calendar: CalendarConfiguration | null = self.workspace?.workspaceConfiguration?.calendar

        function figureOutColor(appointmentServices:any[], status: keyof CalendarConfiguration['status'], type:string, color:string) {
            const customStatusColors = calendar?.status
            if (customStatusColors && customStatusColors[status]) {
                return customStatusColors[status];
            }
            if(status === 'CANCELED') return '#ff0000'
            if(type !== 'patient_appointment' && color) {
                return color
            } 
            if(appointmentServices?.length > 0) return appointmentServices[0].service?.color || appointmentServices[0].service.appointmentType?.color
            return null
        }   

        self.calendarEvents = data?.appointment?.map(
          ({
            color,
            externalId,
            id,
            isFirstAppointmentWithProvider,
            isMembershipActive,
            isMembershipTypeSubscription,
            isNewPatient,
            isRecurring,
            internalAppointmentProviders,
            note,
            notes,
            patient,
            provider,
            room,
            services: appointmentServices,
            startTime,
            status,
            timerange,
            type,
            photos_aggregate,
            recurringExceptionDates,
            recurringRule,
            resolvedAppointmentConsents,
            resolvedAppointmentCustomForms,
            resolvedAppointmentConsumablesData
          }: AppointmentFieldsScheduleFragment) => {
            let intakeStatus; let notesSigned; let hasPhotos; let consumablesRecorded;
            if (type !== 'other') {
              if (
                (!resolvedAppointmentConsents?.consents ||
                  resolvedAppointmentConsents?.consents.length === 0) &&
                (!resolvedAppointmentCustomForms?.customForms ||
                  resolvedAppointmentCustomForms?.customForms.length === 0)
              ) {
                intakeStatus = 'NOT_REQUIRED';
              } else {
                const isIntakeComplete =
                  resolvedAppointmentConsents?.outstanding
                    .length === 0 &&
                  resolvedAppointmentCustomForms?.outstanding
                    .length === 0;
                intakeStatus = isIntakeComplete
                  ? 'COMPLETE'
                  : 'INCOMPLETE';
              }

              notesSigned =
                notes.length > 0
                  ? notes.every((item) => item.noteSignatures.length > 0)
                  : false;

              hasPhotos = photos_aggregate.aggregate ? photos_aggregate.aggregate.count > 0 : false;

              consumablesRecorded =
                resolvedAppointmentConsumablesData.hasCanUpdate &&
                !resolvedAppointmentConsumablesData.hasToResolve;
            }
            
            
            return CalendarEventModel.create({
              appointmentType:
                appointmentServices?.length > 0
                  ? appointmentServices[0].service.appointmentType?.name
                  : 'non-service',
              color: figureOutColor(appointmentServices, status, type, color),
              ...(consumablesRecorded && { consumablesRecorded }),
              end: dayjs
                .utc(JSON.parse(timerange)[1], 'YYYY-MM-DD H:mm:ss+Z')
                .toISOString(),
              externalId,
              ...(hasPhotos && { hasPhotos }),
              id,
              ...(intakeStatus && { intakeStatus }),
              internalAppointmentProviders: internalAppointmentProviders.map(
                (provider) => formatName(provider.provider)
              ),
              isFirstAppointmentWithProvider,
              isMembershipActive,
              isMembershipTypeSubscription,
              isNewPatient,
              note,
              ...(notesSigned && { notesSigned }),
              patientId: patient?.id,
              patientName: formatName(patient?.attributes),
              phoneNumber: patient?.attributes?.phoneNumber,
              providerName: provider ? formatName(provider) : '',
              recurring: isRecurring
                ? recurringRule?.replace('RRULE:', '')
                : '',
              recurringException: recurringExceptionDates ?? [],
              resolvedAppointmentConsents,
              resolvedAppointmentCustomForms,
              resource: figureOutProviders(
                type,
                internalAppointmentProviders,
                provider
              ),
              room: room?.name,
              start: dayjs
                .utc(JSON.parse(timerange)[0], 'YYYY-MM-DD H:mm:ss+Z')
                .toISOString(),
              status,
              services: appointmentServices.map(
                (service) => service.service.name
              ),
              title:
                type === 'patient_appointment'
                  ? `${formatName(patient?.attributes)} - ${dayjs(startTime)
                      .tz(self.calendarTimeZone)
                      .format('h:mm A z')}`
                  : note,
              type,
            });
          }
        );
        
        self.cachedAppointmentList = data?.appointment
        self.ui.setLoadingAppointments(false)
        if(!quiet) {
         self.calendarCacheTime = dayjs().unix().toString()
        }
    }),
    addCalendarEvent(event: CalendarEvent) {
        self.calendarEvents.push(event)
    },
    removeCachedAppointment(id: string) {
        const appt = self.cachedAppointmentList?.find(appointment => appointment.id === id)
        if(appt) self.cachedAppointmentList?.remove(appt)
    },
    removeCalendarEvent(id: string) {
        const event = self.calendarEvents?.find(e => e.id === id)
        if(event) self.calendarEvents?.remove(event)
    },
    addCachedAppointment(appointment: AppointmentFieldsScheduleFragment) {
        self.cachedAppointmentList?.push(appointment)
    },
    loadAudience: flow(function *(audienceId: string) {
        const client = GraphqlClient()
        const { data } = yield client.query({query: GetAudienceDocument, variables: {
            where: {
                id: {
                    _eq: audienceId
                }
            }
        }});
        const audience = data.audience[0];
        self.audience = audience.definition.predicates[0];
        self.audience.id = audience.id;
        self.audience.type = audience.type;
        self.audience.name = audience.name;
        self.audience.snapshot = audience.snapshot;
        audience.definition.predicates.forEach((pred: any) => {
            self.audience.addCondition(pred.target, pred.field, pred.op, pred.val, pred.label)
        });
    }),
    clearAudience: () => {
        self.audience = AudienceModel.create({id: null});
    },
    clearEmailTemplate: () => {
        self.emailTemplate = EmailTemplateModel.create({audienceType: 'active', includesPromotion: false, isActive: true, allowResend: false, sendType: 'now' });
    },
    loadEmailTemplate: flow(function *(emailTemplateId: string) {
        const client = GraphqlClient()
        const { data } = yield client.query({query: GetEmailTemplateDocument, variables: {
            where: {
                id: {
                    _eq: emailTemplateId
                }
            }
        }});
        const et = data.emailTemplate[0];
        self.emailTemplate.id = et.id;
        self.emailTemplate.design = et.design;
        self.emailTemplate.fromName = et.fromName;
        self.emailTemplate.fromAddress = et.fromAddress;
        self.emailTemplate.subjectLine = et.subjectLine ;
        self.emailTemplate.emailName = et.emailName ;
        self.emailTemplate.targetAudienceId = et.targetAudienceId;
        self.emailTemplate.omitAudienceId = et.omitAudienceId;
        self.emailTemplate.includesPromotion = et.includesPromotion;
        self.emailTemplate.sendDateTime = et.sendDateTime;
        self.emailTemplate.audienceType = et.audienceType;
        self.emailTemplate.resendWindow = et.resendWindow;
        self.emailTemplate.allowResend = et.allowResend;
        
        self.emailTemplate.screenshotUrl = et.emailTemplateScreenshot.url;
        self.emailTemplate.marketingTags.replace(et.marketingTags.map(({marketingTag: {id, title}}: {marketingTag: {id: string, title: string}}) => ({id, title})));

        self.emailTemplate.sendType = et.sendDateTime ? 'later' : 'now';
    }),
    setDraftCustomForm(customForm: any) {

        if(self.draftCustomForm) {
          detach(self.draftCustomForm);
        }

        const mstCustomForm = CustomFormModel.create(cloneDeep(customForm));

        self.draftCustomForm = mstCustomForm;
    },
    clearDraftCustomForm() {
        self.draftCustomForm = null;
    },
    setPatient(patient: PatientFieldsFragment ) {
        self.patient = PatientModel.create(patient);
    },
    clearPatient() {
        self.patient = null;
    }
}
))
/**
 * The RootStore instance.
 */
export type RootStore = Instance<typeof RootStoreModel>;

/**
 * The data of a RootStore.
 */
export type RootStoreSnapshot = SnapshotOut<typeof RootStoreModel>;
