import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { CompensationStrategyFieldsFullFragmentDoc } from '../../fragments/compensation/CompensationStrategyFieldsFull.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type LoadCompensationStrategiesQueryVariables = Types.Exact<{
  limit?: Types.Maybe<Types.Scalars['Int']>;
  offset?: Types.Maybe<Types.Scalars['Int']>;
  orderBy?: Types.Maybe<Array<Types.CompensationStrategy_Order_By> | Types.CompensationStrategy_Order_By>;
  where?: Types.Maybe<Types.CompensationStrategy_Bool_Exp>;
}>;


export type LoadCompensationStrategiesQuery = { __typename?: 'query_root', compensationStrategy: Array<{ __typename?: 'compensationStrategy', createdAt: any, id: any, isActive: boolean, name: string, strategyType: string, updatedAt: any, categories: Array<{ __typename?: 'compensationStrategyTierCategory', id: any, serviceCategory: { __typename?: 'serviceCategory', id: any, title: string } }>, commissions: Array<{ __typename?: 'compensationStrategyCommission', compensationAmount: number, compensationType: string, id: any, maxApplyValue: number, minApplyValue: number, membership?: { __typename?: 'membership', id: any, name: string } | null | undefined, package?: { __typename?: 'package', id: any, name: string } | null | undefined, service?: { __typename?: 'service', id: any, name: string } | null | undefined }>, exemptions: Array<{ __typename?: 'compensationStrategyTierExemption', id: any, membership?: { __typename?: 'membership', id: any, name: string } | null | undefined, package?: { __typename?: 'package', id: any, name: string } | null | undefined, service?: { __typename?: 'service', id: any, name: string } | null | undefined }>, inclusions: Array<{ __typename?: 'compensationStrategyTierInclusion', id: any, membership?: { __typename?: 'membership', id: any, name: string } | null | undefined, package?: { __typename?: 'package', id: any, name: string } | null | undefined, service?: { __typename?: 'service', id: any, name: string } | null | undefined }>, tiers: Array<{ __typename?: 'compensationStrategyTier', compensationAmount: number, compensationType: string, id: any, maxApplyValue: number, minApplyValue: number, name: string }> }> };


export const LoadCompensationStrategiesDocument = gql`
    query LoadCompensationStrategies($limit: Int = 10, $offset: Int = 0, $orderBy: [compensationStrategy_order_by!], $where: compensationStrategy_bool_exp) {
  compensationStrategy(
    limit: $limit
    offset: $offset
    order_by: $orderBy
    where: $where
  ) {
    ...CompensationStrategyFieldsFull
  }
}
    ${CompensationStrategyFieldsFullFragmentDoc}`;

/**
 * __useLoadCompensationStrategiesQuery__
 *
 * To run a query within a React component, call `useLoadCompensationStrategiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadCompensationStrategiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadCompensationStrategiesQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      orderBy: // value for 'orderBy'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useLoadCompensationStrategiesQuery(baseOptions?: Apollo.QueryHookOptions<LoadCompensationStrategiesQuery, LoadCompensationStrategiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LoadCompensationStrategiesQuery, LoadCompensationStrategiesQueryVariables>(LoadCompensationStrategiesDocument, options);
      }
export function useLoadCompensationStrategiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LoadCompensationStrategiesQuery, LoadCompensationStrategiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LoadCompensationStrategiesQuery, LoadCompensationStrategiesQueryVariables>(LoadCompensationStrategiesDocument, options);
        }
export type LoadCompensationStrategiesQueryHookResult = ReturnType<typeof useLoadCompensationStrategiesQuery>;
export type LoadCompensationStrategiesLazyQueryHookResult = ReturnType<typeof useLoadCompensationStrategiesLazyQuery>;
export type LoadCompensationStrategiesQueryResult = Apollo.QueryResult<LoadCompensationStrategiesQuery, LoadCompensationStrategiesQueryVariables>;