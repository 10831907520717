import { Icon, IconButton } from '@chakra-ui/react';
import { FaSave } from 'react-icons/fa';
// TODO: Determine if this is the correct place to put this file
interface VitalsCellIconButtonProps {
  clickHandler?: () => void;
}

export const VitalsCellIconButton = ({
  clickHandler,
}: VitalsCellIconButtonProps) => (
  <IconButton
    aria-label="Save"
    height={'20px'}
    width={'10px'}
    onClick={() => clickHandler && clickHandler()}
    icon={<Icon as={FaSave} width={'.75em'} />}
  />
);

export default VitalsCellIconButton;
