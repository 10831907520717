import * as Types from '../../generated/graphql';

import { gql } from '@apollo/client';
import { LocationScheduleFieldsFragmentDoc } from './locationScheduleFields.fragment.generated';
import { AddressFieldsFragmentDoc } from './addressFields.fragment.generated';
import { RoomFieldsFragmentDoc } from './roomFields.fragment.generated';
export type LocationFieldsFragment = { __typename?: 'location', id: any, isActive: boolean, name: string, address?: string | null | undefined, phoneNumber?: string | null | undefined, email?: string | null | undefined, maxPatientsPerTimeslot?: number | null | undefined, workspaceId: any, clinicId?: number | null | undefined, addressId?: any | null | undefined, workStartTime?: string | null | undefined, workEndTime?: string | null | undefined, timezone?: string | null | undefined, payrixMerchantId?: string | null | undefined, finixMerchantId?: string | null | undefined, locationSchedules: Array<{ __typename?: 'locationSchedule', id: any, schedule: any, effectiveFrom?: any | null | undefined, createdAt: any, updatedAt: any, locationId: any }>, fullAddress?: { __typename?: 'address', addressLine1: string, addressLine2?: string | null | undefined, city: string, id: any, state: string, zipCode: string } | null | undefined, rooms: Array<{ __typename?: 'room', id: any, name: string }> };

export const LocationFieldsFragmentDoc = gql`
    fragment LocationFields on location {
  id
  isActive
  name
  address
  phoneNumber
  email
  maxPatientsPerTimeslot
  workspaceId
  clinicId
  addressId
  workStartTime
  workEndTime
  timezone
  payrixMerchantId
  finixMerchantId
  locationSchedules {
    ...LocationScheduleFields
  }
  fullAddress {
    ...AddressFields
  }
  rooms {
    ...RoomFields
  }
}
    ${LocationScheduleFieldsFragmentDoc}
${AddressFieldsFragmentDoc}
${RoomFieldsFragmentDoc}`;