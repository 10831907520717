import { Switch } from '@chakra-ui/react';
import NumberInput from '../NumberInput';
import TextInput from '../TextInput';
import { CompoundUncontrolledFormInputProps } from '../types';
import CurrencyInput from '../CurrencyInput';
import SelectInput from '../SelectInput';
import CheckboxInput from '../CheckboxInput';
import MultiSelectInput from '../MultiSelectFormInput';
import AsyncSelectInput from '../AsyncSelectInput';
import RadioInput from '../RadioInput';
import AsyncMultiSelectInput from '../AsyncMultiSelectInput';
import DateInput from '../DateInput';
import renderReadOnlyText from './renderReadOnlyText';
import PercentInput from '../PercentInput';
import FractionInput from '../FractionInput';
import DateTimeInput from '../DateTimeInput';
import TextAreaInput from '../TextAreaInput';
import AddressInput from '../AddressInput';
import PlacesInput from '../PlacesInput';
import TextEditorInput from '../TextEditorInput';

function renderUncontrolledInput({
  isEditable = true,
  ...props
}: CompoundUncontrolledFormInputProps) {
  if (!isEditable) {
    return renderReadOnlyText({ type: props.type, value: props.value });
  }

  if (props.type === 'address') {
    const { type, uncontrolled, label, error, ...inputProps } = props;
    return <AddressInput {...inputProps} />;
  }

  if (props.type === 'date') {
    const { type, uncontrolled, label, error, ...inputProps } = props;
    return <DateInput {...inputProps} />;
  }

  if (props.type === 'datetime') {
    const { type, uncontrolled, label, error, ...inputProps } = props;
    return <DateTimeInput {...inputProps} />;
  }

  if (props.type === 'checkbox') {
    const { type, uncontrolled, label, error, ...inputProps } = props;
    return <CheckboxInput {...inputProps} />;
  }

  if (props.type === 'number') {
    const { type, uncontrolled, label, error, ...inputProps } = props;
    return <NumberInput {...inputProps} />;
  }

  if (props.type === 'fraction') {
    const { type, uncontrolled, label, error, ...inputProps } = props;
    return <FractionInput {...inputProps} />;
  }

  if (props.type === 'text') {
    const { type, uncontrolled, label, error, ...inputProps } = props;
    return <TextInput {...inputProps} />;
  }

  if (props.type === 'currency') {
    const { type, uncontrolled, label, error, ...inputProps } = props;
    return <CurrencyInput {...inputProps} />;
  }

  if (props.type === 'percent') {
    const { type, uncontrolled, label, error, ...inputProps } = props;
    return <PercentInput {...inputProps} />;
  }

  if (props.type === 'places') {
    const { type, uncontrolled, label, error, ...inputProps } = props;
    return <PlacesInput showFullAddress {...inputProps} />;
  }

  if (props.type === 'select') {
    const { options, ...inputProps } = props;
    return <SelectInput options={options} {...inputProps} />;
  }

  if (props.type === 'multiselect') {
    const { options, ...inputProps } = props;
    return <MultiSelectInput options={options} {...inputProps} />;
  }

  if (props.type === 'async-select') {
    const { type, uncontrolled, label, ...inputProps } = props;
    return <AsyncSelectInput {...inputProps} />;
  }

  if (props.type === 'async-multiselect') {
    const { type, uncontrolled, label, ...inputProps } = props;
    return <AsyncMultiSelectInput {...inputProps} />;
  }

  if (props.type === 'radio') {
    const { type, uncontrolled, label, ...inputProps } = props;
    return <RadioInput {...inputProps} />;
  }

  if (props.type === 'switch') {
    const { type, uncontrolled, label, onChange, error, value, ...inputProps } =
      props;
    return (
      <Switch
        defaultChecked={value || false}
        isChecked={value || false}
        onChange={(e) => {
          onChange(e.target.checked);
        }}
        {...inputProps}
      />
    );
  }

  if (props.type === 'textarea') {
    const { type, uncontrolled, label, error, ...inputProps } = props;
    return <TextAreaInput {...inputProps} />;
  }

  if (props.type === 'texteditor') {
    return <TextEditorInput {...props} />;
  }

  // eslint-disable-next-line no-console
  console.error('Unsupported input type');

  return <div />;
}

export default renderUncontrolledInput;
