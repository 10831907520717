import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { LeadFormLinkFieldsFragmentDoc } from '../../fragments/leadFormLink.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type UpdateLeadFormLinkMutationVariables = Types.Exact<{
  set?: Types.Maybe<Types.LeadFormLink_Set_Input>;
  id: Types.Scalars['uuid'];
}>;


export type UpdateLeadFormLinkMutation = { __typename?: 'mutation_root', update_leadFormLink_by_pk?: { __typename?: 'leadFormLink', id: any, patientId: any, formData: any, workspaceId: any, createdAt: any, updatedAt: any } | null | undefined };


export const UpdateLeadFormLinkDocument = gql`
    mutation UpdateLeadFormLink($set: leadFormLink_set_input, $id: uuid!) {
  update_leadFormLink_by_pk(_set: $set, pk_columns: {id: $id}) {
    ...LeadFormLinkFields
  }
}
    ${LeadFormLinkFieldsFragmentDoc}`;
export type UpdateLeadFormLinkMutationFn = Apollo.MutationFunction<UpdateLeadFormLinkMutation, UpdateLeadFormLinkMutationVariables>;

/**
 * __useUpdateLeadFormLinkMutation__
 *
 * To run a mutation, you first call `useUpdateLeadFormLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLeadFormLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLeadFormLinkMutation, { data, loading, error }] = useUpdateLeadFormLinkMutation({
 *   variables: {
 *      set: // value for 'set'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateLeadFormLinkMutation(baseOptions?: Apollo.MutationHookOptions<UpdateLeadFormLinkMutation, UpdateLeadFormLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateLeadFormLinkMutation, UpdateLeadFormLinkMutationVariables>(UpdateLeadFormLinkDocument, options);
      }
export type UpdateLeadFormLinkMutationHookResult = ReturnType<typeof useUpdateLeadFormLinkMutation>;
export type UpdateLeadFormLinkMutationResult = Apollo.MutationResult<UpdateLeadFormLinkMutation>;
export type UpdateLeadFormLinkMutationOptions = Apollo.BaseMutationOptions<UpdateLeadFormLinkMutation, UpdateLeadFormLinkMutationVariables>;