import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type PatientWorkspaceSourcesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type PatientWorkspaceSourcesQuery = { __typename?: 'query_root', getPatientWorkspaceSources?: { __typename?: 'PatientWorkspaceSourcesResult', sources?: Array<string | null | undefined> | null | undefined } | null | undefined };


export const PatientWorkspaceSourcesDocument = gql`
    query PatientWorkspaceSources {
  getPatientWorkspaceSources {
    sources
  }
}
    `;

/**
 * __usePatientWorkspaceSourcesQuery__
 *
 * To run a query within a React component, call `usePatientWorkspaceSourcesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePatientWorkspaceSourcesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePatientWorkspaceSourcesQuery({
 *   variables: {
 *   },
 * });
 */
export function usePatientWorkspaceSourcesQuery(baseOptions?: Apollo.QueryHookOptions<PatientWorkspaceSourcesQuery, PatientWorkspaceSourcesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PatientWorkspaceSourcesQuery, PatientWorkspaceSourcesQueryVariables>(PatientWorkspaceSourcesDocument, options);
      }
export function usePatientWorkspaceSourcesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PatientWorkspaceSourcesQuery, PatientWorkspaceSourcesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PatientWorkspaceSourcesQuery, PatientWorkspaceSourcesQueryVariables>(PatientWorkspaceSourcesDocument, options);
        }
export type PatientWorkspaceSourcesQueryHookResult = ReturnType<typeof usePatientWorkspaceSourcesQuery>;
export type PatientWorkspaceSourcesLazyQueryHookResult = ReturnType<typeof usePatientWorkspaceSourcesLazyQuery>;
export type PatientWorkspaceSourcesQueryResult = Apollo.QueryResult<PatientWorkspaceSourcesQuery, PatientWorkspaceSourcesQueryVariables>;