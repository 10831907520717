import { Flex, Text } from '@chakra-ui/react';
import { LeadFormField } from '@webapp/state-models';
import { RiMenuLine, RiCloseLine } from 'react-icons/ri';
/* eslint-disable-next-line */
export interface FormFieldProps {
  formField: LeadFormField;
  onRemove: (field: LeadFormField) => void;
}

export function FormField({ formField, onRemove }: FormFieldProps) {
  return (
    <Flex p={2} shadow={'md'} bg={'white'} rounded="md" alignItems={'center'}>
      <RiMenuLine width={20} height={40} color="black" />
      <Text ml={2} flexGrow={1}>
        {formField.label}
      </Text>
      <RiCloseLine onClick={() => onRemove(formField)} />
    </Flex>
  );
}

export default FormField;
