import { useState, useRef, useLayoutEffect } from 'react';
import {
  Box,
  Heading,
  HStack,
  Icon,
  Stack,
  Tag,
  Text,
  Wrap,
  WrapItem,
} from '@chakra-ui/react';
import { MbscCalendarEvent } from '@mobiscroll/react';
import { Appointment } from '@webapp/state-models';
import { AppointmentStatusIcons } from '../AppointmentStatusIcons';
import { PatientIcons } from '@webapp/ui';

interface CalendarLabelProps {
  event: MbscCalendarEvent;
  color: string;
  view: string;
}

export const CalendarLabel = ({
  event = {},
  color,
  view,
}: CalendarLabelProps) => {
  const { original, start } = event;
  const [showIcons, setShowIcons] = useState(true);
  const containerRef = useRef(null);

  useLayoutEffect(() => {
    const containerHeight = containerRef?.current?.clientHeight;
    const thresholdHeight = 30;

    setShowIcons(containerHeight > thresholdHeight);
  }, []);

  let title = '';
  if (view !== 'day') {
    title = event.title || '';
  } else if (original?.type == 'patient_appointment') {
    title = `${original?.patientName} - ${start}`;
  } else {
    title = original?.note
      ? `${original.note.toString()} - ${start}`
      : start?.toString() || '';
  }
  return (
    <Stack
      data-testid="calendar-cell"
      key={original?.appointmentId}
      overflow={'hidden'}
      p={0}
      m={0}
    >
      <Box
        ref={containerRef}
        className="event-calendar-cell-bg"
        rounded="md"
        bg={color}
        opacity={0.3}
        position="absolute"
        left={0}
        right={'2px'}
        top={0}
        bottom={`2px`}
        margin={0}
      ></Box>
      <Box
        rounded="md"
        bg={color}
        position="absolute"
        left={'-1px'}
        width={'4px'}
        top={-2}
        bottom={2}
        margin={0}
      ></Box>
      <Stack mt={0} px={'5px'} zIndex={2} spacing={0} margin={0}>
        {original?.status === 'CANCELED' && (
          <Tag
            size="sm"
            bg="red.800"
            color="white"
            width="fit-content"
            fontWeight={'bold'}
          >
            CANCELLED
          </Tag>
        )}
        {showIcons && original?.type !== 'other' && (
          <AppointmentStatusIcons
            appointmentStatus={original?.status}
            hasPhotos={original?.hasPhotos}
            consumablesRecorded={original?.consumablesRecorded}
            intakeStatus={original?.intakeStatus}
            notesSigned={original?.notesSigned}
            startTime={original?.start}
          ></AppointmentStatusIcons>
        )}

        <Heading
          data-testid="calendar-cell-title"
          color="black"
          fontSize={'clamp(8px, 12px, 16px)'}
          margin={0}
        >
          {title}
        </Heading>
        {original?.phoneNumber && (
          <Text color="black" fontSize={'clamp(8px, 12px, 16px)'}>
            {original.phoneNumber}
          </Text>
        )}
        <Wrap margin={0}>
          {original?.services?.map((service: string, index: number) => (
            <WrapItem key={index}>
              <Text color="black" fontSize={'clamp(8px, 10px, 12px)'}>
                {service}
              </Text>
            </WrapItem>
          ))}
          {original?.room && (
            <WrapItem>
              <Text
                bg="gray.200"
                color="black"
                px={1}
                fontSize={'clamp(8px, 10px, 12px)'}
              >
                {original.room}
              </Text>
            </WrapItem>
          )}
        </Wrap>
        {original?.note && (
          <Text color="black" as="i" fontSize={'clamp(8px, 10px, 12px)'}>
            "{original.note}"
          </Text>
        )}
        <PatientIcons
          isTooltipDisabled={true}
          appointment={event.original as Appointment}
          iconSize={'2'}
          containerStyles={{
            gridGap: '0px',
            gridTemplateColumns: `repeat(auto-fill, minmax(12px, 1fr))`,
          }}
          textStyles={{
            fontSize: 'clamp(2px, 8px, 12px)',
          }}
          iconContainerStyles={{
            padding: 0,
            size: 3,
          }}
        />
      </Stack>
    </Stack>
  );
};

export default CalendarLabel;
