import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { PatientTableFieldsFragmentDoc } from '../../fragments/patientTableFields.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetPatientsQueryVariables = Types.Exact<{
  limit?: Types.Maybe<Types.Scalars['Int']>;
  offset?: Types.Maybe<Types.Scalars['Int']>;
  orderBy?: Types.Maybe<Array<Types.Patient_Order_By> | Types.Patient_Order_By>;
  where?: Types.Maybe<Types.Patient_Bool_Exp>;
}>;


export type GetPatientsQuery = { __typename?: 'query_root', patient: Array<{ __typename?: 'patient', email?: string | null | undefined, id: any, firstName: string, lastName: string, status: string, attributes: { __typename?: 'PatientAttributes', email?: string | null | undefined, firstName?: string | null | undefined, middleName?: string | null | undefined, clinicId?: number | null | undefined, dosespotPatientId?: number | null | undefined, lastName?: string | null | undefined, status?: string | null | undefined, dob?: string | null | undefined, phoneNumber?: string | null | undefined, profilePicture?: { __typename?: 'FilePayload', filePath: string, url: string, thumbnailUrl?: string | null | undefined } | null | undefined }, patientWorkspaces: Array<{ __typename?: 'patientWorkspace', id: any, status?: string | null | undefined, patientReferenceNumber: number, profileNote?: string | null | undefined, workspaceId: any, primaryProvider?: { __typename?: 'provider', id: any, firstName: string, lastName: string } | null | undefined }>, lastAppointment: Array<{ __typename?: 'appointment', id: any, timerange?: any | null | undefined, appointmentServices: Array<{ __typename?: 'appointmentService', service: { __typename?: 'service', name: string } }> }>, patientMemberships: Array<{ __typename?: 'patientMembership', id: any, status: string, createdAt: any, nextPaymentDate?: any | null | undefined, membership: { __typename?: 'membership', name: string, type: Types.MembershipType_Enum, color: string } }>, patientWallets: Array<{ __typename?: 'patientWallet', id: any, patientId: any, balance: number, patientWalletCoupons: Array<{ __typename?: 'patientWalletCoupon', coupon: { __typename?: 'coupon', id: any, name: string, rule: string } }> }> }> };


export const GetPatientsDocument = gql`
    query GetPatients($limit: Int, $offset: Int, $orderBy: [patient_order_by!], $where: patient_bool_exp) {
  patient(limit: $limit, offset: $offset, order_by: $orderBy, where: $where) {
    ...PatientTableFields
  }
}
    ${PatientTableFieldsFragmentDoc}`;

/**
 * __useGetPatientsQuery__
 *
 * To run a query within a React component, call `useGetPatientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPatientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPatientsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      orderBy: // value for 'orderBy'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetPatientsQuery(baseOptions?: Apollo.QueryHookOptions<GetPatientsQuery, GetPatientsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPatientsQuery, GetPatientsQueryVariables>(GetPatientsDocument, options);
      }
export function useGetPatientsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPatientsQuery, GetPatientsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPatientsQuery, GetPatientsQueryVariables>(GetPatientsDocument, options);
        }
export type GetPatientsQueryHookResult = ReturnType<typeof useGetPatientsQuery>;
export type GetPatientsLazyQueryHookResult = ReturnType<typeof useGetPatientsLazyQuery>;
export type GetPatientsQueryResult = Apollo.QueryResult<GetPatientsQuery, GetPatientsQueryVariables>;