import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type SearchServicesQueryVariables = Types.Exact<{
  where: Types.Service_Bool_Exp;
  limit?: Types.Maybe<Types.Scalars['Int']>;
}>;


export type SearchServicesQuery = { __typename?: 'query_root', service: Array<{ __typename?: 'service', allowGratuity: boolean, id: any, name: string, price: number, deposit?: number | null | undefined, requiresDeposit?: boolean | null | undefined, pricingModel: string, minutesToPerform: number, newPatientMinutesToPerform?: number | null | undefined, taxRate?: any | null | undefined, locationServices: Array<{ __typename?: 'locationService', locationId: any, price?: any | null | undefined, taxRate?: any | null | undefined }>, serviceCategory?: { __typename?: 'serviceCategory', title: string } | null | undefined, serviceDeviceTypes: Array<{ __typename?: 'serviceDeviceType', id: any, deviceType: { __typename?: 'deviceType', id: any, value: string } }> }> };


export const SearchServicesDocument = gql`
    query searchServices($where: service_bool_exp!, $limit: Int) {
  service(limit: $limit, where: $where) {
    allowGratuity
    id
    name
    price
    locationServices {
      locationId
      price
      taxRate
    }
    deposit
    requiresDeposit
    pricingModel
    minutesToPerform
    newPatientMinutesToPerform
    serviceCategory {
      title
    }
    serviceDeviceTypes {
      id
      deviceType {
        id
        value
      }
    }
    taxRate
  }
}
    `;

/**
 * __useSearchServicesQuery__
 *
 * To run a query within a React component, call `useSearchServicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchServicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchServicesQuery({
 *   variables: {
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useSearchServicesQuery(baseOptions: Apollo.QueryHookOptions<SearchServicesQuery, SearchServicesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchServicesQuery, SearchServicesQueryVariables>(SearchServicesDocument, options);
      }
export function useSearchServicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchServicesQuery, SearchServicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchServicesQuery, SearchServicesQueryVariables>(SearchServicesDocument, options);
        }
export type SearchServicesQueryHookResult = ReturnType<typeof useSearchServicesQuery>;
export type SearchServicesLazyQueryHookResult = ReturnType<typeof useSearchServicesLazyQuery>;
export type SearchServicesQueryResult = Apollo.QueryResult<SearchServicesQuery, SearchServicesQueryVariables>;