import dayjs, { Dayjs } from 'dayjs';

export function extractDateFromMetadata(metadata: any): Dayjs {
  try {
    if (metadata?.exif) {
      const exifDate = metadata?.exif?.['306'];
      const date = dayjs(exifDate, 'YYYY:MM:DD');

      if (date.isValid()) {
        return date;
      }

      return dayjs();
    }
  } catch (err) {
    return dayjs();
  }

  return dayjs();
}

export default extractDateFromMetadata;
