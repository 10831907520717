import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { PatientSearchFieldsFragmentDoc } from '../../fragments/patientSearchFields.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type SearchPatientsQueryVariables = Types.Exact<{
  _ilike: Types.Scalars['String'];
  limit?: Types.Maybe<Types.Scalars['Int']>;
}>;


export type SearchPatientsQuery = { __typename?: 'query_root', searchPatients: Array<{ __typename?: 'patient', dob?: string | null | undefined, id: any, firstName: string, gender?: string | null | undefined, language: string, lastName: string, email?: string | null | undefined, status: string, phoneNumber?: string | null | undefined, profilePicture?: { __typename?: 'photo', hasThumbnail?: boolean | null | undefined, file?: { __typename?: 'FilePayload', filePath: string, url: string, thumbnailUrl?: string | null | undefined } | null | undefined } | null | undefined, attributes: { __typename?: 'PatientAttributes', attributes?: any | null | undefined, dob?: string | null | undefined, email?: string | null | undefined, firstName?: string | null | undefined, gender?: string | null | undefined, id?: string | null | undefined, lastName?: string | null | undefined, middleName?: string | null | undefined, phoneNumber?: string | null | undefined, profileNote?: string | null | undefined, status?: string | null | undefined, partialMediaConsent?: boolean | null | undefined, fullMediaConsent?: boolean | null | undefined, language?: string | null | undefined, emailMarketingEnabled?: boolean | null | undefined, smsMarketingEnabled?: boolean | null | undefined, phoneMarketingEnabled?: boolean | null | undefined, emailConfirmed?: boolean | null | undefined, phoneConfirmed?: boolean | null | undefined, profilePicture?: { __typename?: 'FilePayload', filePath: string, url: string, thumbnailUrl?: string | null | undefined } | null | undefined } }> };


export const SearchPatientsDocument = gql`
    query searchPatients($_ilike: String!, $limit: Int = 10) {
  searchPatients(args: {search: $_ilike}, limit: $limit) {
    ...PatientSearchFields
  }
}
    ${PatientSearchFieldsFragmentDoc}`;

/**
 * __useSearchPatientsQuery__
 *
 * To run a query within a React component, call `useSearchPatientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchPatientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchPatientsQuery({
 *   variables: {
 *      _ilike: // value for '_ilike'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useSearchPatientsQuery(baseOptions: Apollo.QueryHookOptions<SearchPatientsQuery, SearchPatientsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchPatientsQuery, SearchPatientsQueryVariables>(SearchPatientsDocument, options);
      }
export function useSearchPatientsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchPatientsQuery, SearchPatientsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchPatientsQuery, SearchPatientsQueryVariables>(SearchPatientsDocument, options);
        }
export type SearchPatientsQueryHookResult = ReturnType<typeof useSearchPatientsQuery>;
export type SearchPatientsLazyQueryHookResult = ReturnType<typeof useSearchPatientsLazyQuery>;
export type SearchPatientsQueryResult = Apollo.QueryResult<SearchPatientsQuery, SearchPatientsQueryVariables>;