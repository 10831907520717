import { useApolloClient } from '@apollo/client';
import {
  GetSignedUploadUrlDocument,
  GetSignedUploadUrlQueryVariables,
  StorageType,
} from '@webapp/graphql';
import axios from 'axios';
import { nanoid } from 'nanoid';

export interface UseUploadToS3Params {
  fileType: string;
  fileContents: File;
  storageType: StorageType;
  onProgress: (progress: number) => void;
  filePath?: string;
  randomizeFileName?: boolean;
}

export interface UseUploadToS3Result {
  uploadToS3: (params: UseUploadToS3Params) => Promise<string>;
}

const addRandomnessToFileName = (fileName: string): string => {
  const lastIndex = fileName.lastIndexOf('.');
  const fileNameWithoutExtension = fileName.substring(0, lastIndex);
  const extension = fileName.substring(lastIndex);

  return `${fileNameWithoutExtension}-${nanoid()}${extension}`;
};

export function useUploadToS3(): UseUploadToS3Result {
  const client = useApolloClient();

  const uploadToS3 = async ({
    fileType,
    fileContents,
    storageType,
    onProgress,
    filePath,
    randomizeFileName = false,
  }: UseUploadToS3Params): Promise<string> => {
    const variables: GetSignedUploadUrlQueryVariables = {
      fileType,
      storageType,
    };

    if (filePath) {
      variables.filePath = randomizeFileName
        ? addRandomnessToFileName(filePath)
        : filePath;
    }

    const { data } = await client.query({
      query: GetSignedUploadUrlDocument,
      variables,
      fetchPolicy: 'no-cache',
    });

    const presignedPostUrl = JSON.parse(data.getSignedUploadUrl.signedUrl);
    const formData = new FormData();

    Object.entries(presignedPostUrl.fields).forEach(([k, v]) => {
      formData.append(k, v as 'string | Blob');
    });

    formData.append('Content-Type', fileType ?? fileContents.type);
    formData.append('file', fileContents); // The file has be the last element

    const config = {
      onUploadProgress(progressEvent: any) {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        onProgress(percentCompleted);
      },
    };

    await axios.post(presignedPostUrl.url, formData, {
      headers: { 'Content-Type': fileType ?? fileContents.type },
      ...config,
    });

    const imageKey = presignedPostUrl.fields.key;
    const urlToReturn =
      storageType === StorageType.Public
        ? `${presignedPostUrl.url}/${imageKey.replace('/', '')}`
        : imageKey;

    return urlToReturn.replace('.com//', '.com/');
  };

  return {
    uploadToS3,
  };
}

export type PresignedPostUrlResponse = {
  url: string;
  fields: {
    key: string;
    acl: string;
    bucket: string;
  };
  filePath: string;
};

export default useUploadToS3;
