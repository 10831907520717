import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { LeadFormLinkFieldsFragmentDoc } from '../../fragments/leadFormLink.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetLeadFormLinksQueryVariables = Types.Exact<{
  where?: Types.Maybe<Types.LeadFormLink_Bool_Exp>;
  limit?: Types.Maybe<Types.Scalars['Int']>;
  offset?: Types.Maybe<Types.Scalars['Int']>;
  orderBy?: Types.Maybe<Array<Types.LeadFormLink_Order_By> | Types.LeadFormLink_Order_By>;
}>;


export type GetLeadFormLinksQuery = { __typename?: 'query_root', leadFormLink: Array<{ __typename?: 'leadFormLink', id: any, patientId: any, formData: any, workspaceId: any, createdAt: any, updatedAt: any }> };


export const GetLeadFormLinksDocument = gql`
    query GetLeadFormLinks($where: leadFormLink_bool_exp, $limit: Int, $offset: Int, $orderBy: [leadFormLink_order_by!]) {
  leadFormLink(where: $where, limit: $limit, offset: $offset, order_by: $orderBy) {
    ...LeadFormLinkFields
  }
}
    ${LeadFormLinkFieldsFragmentDoc}`;

/**
 * __useGetLeadFormLinksQuery__
 *
 * To run a query within a React component, call `useGetLeadFormLinksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLeadFormLinksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLeadFormLinksQuery({
 *   variables: {
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetLeadFormLinksQuery(baseOptions?: Apollo.QueryHookOptions<GetLeadFormLinksQuery, GetLeadFormLinksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLeadFormLinksQuery, GetLeadFormLinksQueryVariables>(GetLeadFormLinksDocument, options);
      }
export function useGetLeadFormLinksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLeadFormLinksQuery, GetLeadFormLinksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLeadFormLinksQuery, GetLeadFormLinksQueryVariables>(GetLeadFormLinksDocument, options);
        }
export type GetLeadFormLinksQueryHookResult = ReturnType<typeof useGetLeadFormLinksQuery>;
export type GetLeadFormLinksLazyQueryHookResult = ReturnType<typeof useGetLeadFormLinksLazyQuery>;
export type GetLeadFormLinksQueryResult = Apollo.QueryResult<GetLeadFormLinksQuery, GetLeadFormLinksQueryVariables>;