import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type EnrollWorkspaceERxMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type EnrollWorkspaceERxMutation = { __typename?: 'mutation_root', enrollWorkspaceERx: { __typename?: 'SuccessAnswer', success: boolean } };


export const EnrollWorkspaceERxDocument = gql`
    mutation EnrollWorkspaceERx {
  enrollWorkspaceERx {
    success
  }
}
    `;
export type EnrollWorkspaceERxMutationFn = Apollo.MutationFunction<EnrollWorkspaceERxMutation, EnrollWorkspaceERxMutationVariables>;

/**
 * __useEnrollWorkspaceERxMutation__
 *
 * To run a mutation, you first call `useEnrollWorkspaceERxMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnrollWorkspaceERxMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [enrollWorkspaceERxMutation, { data, loading, error }] = useEnrollWorkspaceERxMutation({
 *   variables: {
 *   },
 * });
 */
export function useEnrollWorkspaceERxMutation(baseOptions?: Apollo.MutationHookOptions<EnrollWorkspaceERxMutation, EnrollWorkspaceERxMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EnrollWorkspaceERxMutation, EnrollWorkspaceERxMutationVariables>(EnrollWorkspaceERxDocument, options);
      }
export type EnrollWorkspaceERxMutationHookResult = ReturnType<typeof useEnrollWorkspaceERxMutation>;
export type EnrollWorkspaceERxMutationResult = Apollo.MutationResult<EnrollWorkspaceERxMutation>;
export type EnrollWorkspaceERxMutationOptions = Apollo.BaseMutationOptions<EnrollWorkspaceERxMutation, EnrollWorkspaceERxMutationVariables>;