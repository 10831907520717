import { LockIcon, UnlockIcon } from '@chakra-ui/icons';
import {
  HStack,
  Button,
  Tag,
  TagLabel,
  TagLeftIcon,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  PopoverAnchor,
} from '@chakra-ui/react';
import { NoteFieldsFragment } from '@webapp/graphql';
import { SmartTextEditor } from '@webapp/ui';

/* eslint-disable-next-line */
export interface NotePopoverProps {
  note: NoteFieldsFragment;
  children: ReactNode;
}

export function NotePopover({ note, children }: NotePopoverProps) {
  return (
    <Popover>
      <PopoverTrigger>
        {children || <Button>Preview Note</Button>}
      </PopoverTrigger>
      <PopoverContent w={'70vw'}>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverHeader>{note?.title}</PopoverHeader>
        <PopoverBody w={'70vw'} maxH={'60vh'} overflow="auto">
          <SmartTextEditor
            content={note.message}
            hideSave
            editable={false}
            height={'100%'}
          />
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
}

export default NotePopover;
