import * as Types from '../../generated/graphql';

import { gql } from '@apollo/client';
export type MembershipFieldsFragment = { __typename?: 'membership', id: any, name: string, type: Types.MembershipType_Enum, price: number, taxRate: any, description?: string | null | undefined, image?: string | null | undefined, availableInStore?: boolean | null | undefined, discount: number, billingPeriodInDays: number, maxBillingCycles?: number | null | undefined, billingDayOfMonth?: number | null | undefined, createdAt: any, updatedAt: any, locationId?: any | null | undefined, workspaceId: any, color: string, hasCoupons: boolean, hasCredits: boolean, credits: number, archived: boolean, location?: { __typename?: 'location', id: any, name: string } | null | undefined, membershipCoupons: Array<{ __typename?: 'membershipCoupon', count: number, coupon: { __typename?: 'coupon', id: any, name: string } }> };

export const MembershipFieldsFragmentDoc = gql`
    fragment MembershipFields on membership {
  id
  name
  type
  price
  taxRate
  description
  image
  availableInStore
  discount
  billingPeriodInDays
  maxBillingCycles
  billingDayOfMonth
  createdAt
  updatedAt
  locationId
  location {
    id
    name
  }
  workspaceId
  color
  hasCoupons
  membershipCoupons {
    coupon {
      id
      name
    }
    count
  }
  hasCredits
  credits
  archived
}
    `;