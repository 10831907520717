import dayjs from 'dayjs';
import { Button, Grid, Link, Text, Tooltip } from '@chakra-ui/react';
import { AppointmentFieldsFullFragment } from '@webapp/graphql';
import { useStores } from '@webapp/state-models';
import { DataTable } from '@webapp/ui';
import { Cell } from 'react-table';

interface InstructionsTableRow {
  serviceId: string;
  sendTime: string | null;
  serviceName: string;
  docType: string;
  title: string;
  rowId: number;
  url?: string;
}

export const InstructionsTable = ({
  appointment,
  mini = false,
}: {
  appointment: AppointmentFieldsFullFragment;
  mini?: boolean;
}) => {
  const findMessageStatus = (msgType: string) => {
    const statuses = appointment.clinicalMessageStatuses
      .filter((cms) => cms.clinicalMessage.messageType === msgType)
      .sort((a, b) => {
        if (a < b) return 1;
        if (a > b) return -1;
        return 0;
      });
    // It's possible that pre/postcare instructions were sent multiple times for this appointment
    // if provider used the "Send Now" button to e.g. re-send instructions manually
    if (statuses.length > 0) return statuses[0];
    return null;
  };
  const serviceRows: InstructionsTableRow[] = [];
  let rowId = 0;

  appointment.services.forEach((service) => {
    const precareSentStatus = findMessageStatus('precare');
    const postcareSentStatus = findMessageStatus('postcare');

    if (precareSentStatus) {
      serviceRows.push({
        serviceId: service.service.id,
        sendTime: dayjs(precareSentStatus.sendTime).format('M/D/YYYY h:mm A'),
        serviceName: service.service.name,
        docType: 'precare',
        title:
          service.service.precareDocument?.title ||
          // Should always be set for this use case, but the general clinical message does not
          // require an attachment document
          'Untitled',
        url: service.service.precareDocument?.file?.url,
        rowId: rowId++,
      });
    } else {
      serviceRows.push({
        serviceId: service.service.id,
        sendTime: 'Not Yet Sent',
        serviceName: service.service.name,
        docType: 'precare',
        title: service.service.precareDocument?.title || 'No instructions set',
        url: service.service.precareDocument?.file?.url,
        rowId: rowId++,
      });
    }
    if (postcareSentStatus) {
      serviceRows.push({
        serviceId: service.service.id,
        sendTime: dayjs(postcareSentStatus.sendTime).format('M/D/YYYY h:mm A'),
        serviceName: service.service.name,
        docType: 'postcare',
        title: service.service.postcareDocument?.title || 'Untitled',
        url: service.service.postcareDocument?.file?.url,
        rowId: rowId++,
      });
    } else {
      serviceRows.push({
        serviceId: service.service.id,
        sendTime: 'Not Yet Sent',
        serviceName: service.service.name,
        docType: 'postcare',
        title: service.service.postcareDocument?.title || 'No instructions set',
        url: service.service.postcareDocument?.file?.url,
        rowId: rowId++,
      });
    }
  });

  const columns = [
    {
      accessor: 'serviceName',
      Cell: (e: Cell<InstructionsTableRow>) => (
        <Tooltip label={e.row.original.serviceName}>
          <Text size="sm" noOfLines={1} wordBreak="break-all" maxW="300px">
            {e.row.original.serviceName}
          </Text>
        </Tooltip>
      ),
      defaultCanSort: false,
      disableSortBy: true,
      Header: 'Service',
    },
    {
      accessor: 'serviceId',
      disableSortBy: true,
      defaultCanSort: false,
      Header: 'Instructions',
      Cell: (originalRow: Cell<InstructionsTableRow>) => {
        const { url, title } = originalRow.row.original;

        if (url)
          return (
            <Link
              variant="link"
              colorScheme="teal"
              maxW={'300px'}
              target="_blank"
              href={url}
            >
              {title}
            </Link>
          );
        return <Text size="sm">{title}</Text>;
      },
    },

    {
      accessor: 'docType',
      Cell: (e: Cell<InstructionsTableRow>) => (
        <Text size="sm">{e.row.original.docType.toUpperCase()}</Text>
      ),
      defaultCanSort: false,
      disableSortBy: true,
      Header: 'Type',
    },
    {
      accessor: 'sendTime',
      Cell: (e: Cell<InstructionsTableRow>) => (
        <Text size="sm">{e.row.original.sendTime}</Text>
      ),
      defaultCanSort: false,
      disableSortBy: true,
      Header: 'Send Date',
    },
  ];

  return (
    <DataTable<any>
      columns={columns}
      forceMobileView={mini}
      data={serviceRows}
      customStyles={{
        borderColor: '#CFEBFF',
        cell: {
          color: '#6C6C72',
        },
        table: {
          borderRadius: '8px',
          minHeight: 'unset',
        },
        tableHeader: {
          background: '#EAFCFF',
          color: '#525257',
          textTransform: 'uppercase',
        },
      }}
      shouldDisplayPagination={false}
    />
  );
};

export default InstructionsTable;
