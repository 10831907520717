import { addMessages, DefaultNullValidation } from '@webapp/joi-validation';
import Joi from 'joi';

const InvoiceFormValidation = Joi.object({
  address: addMessages(Joi.string(), 'Address').allow('', null),
  applyBSADiscount: Joi.boolean(),
  cashier: Joi.object().label('Cashier'),
  consumableOverride: DefaultNullValidation,
  coupons: Joi.object().pattern(
    Joi.string(),
    Joi.alternatives().try(
      Joi.object({
        amount: Joi.number().optional(),
        couponId: Joi.string().optional(),
        expirationOverride: Joi.boolean().optional(),
        isMembershipCoupon: Joi.boolean().optional(),
        name: Joi.string().optional(),
        packageId: Joi.string().optional().allow(null),
        quantity: Joi.number().optional(),
        type: Joi.string().optional(),
      }),
      Joi.valid(null)
    )
  ),
  gratuityEnabledTotal: Joi.number().required(),
  appointmentId: DefaultNullValidation,
  email: DefaultNullValidation,
  dueDate: addMessages(Joi.date().required(), 'Due Date'),
  gratuity: Joi.number(),
  invoiceDate: Joi.date(),
  invoiceId: DefaultNullValidation,
  invoiceNumber: addMessages(Joi.string().required(), 'Invoice Number'),
  lineItems: Joi.array().items(
    Joi.object({
      allowGratuity: Joi.boolean(),
      discountAmount: Joi.number().optional(),
      discountType: Joi.string().optional(),
      itemId: DefaultNullValidation,
      itemType: Joi.string().required(),
      name: DefaultNullValidation,
      pricePerUnit: Joi.number().required(),
      pricingModel: Joi.string().optional(),
      provider: Joi.object({
        firstName: Joi.string().required(),
        lastName: Joi.string().required(),
        profilePicture: DefaultNullValidation,
        id: Joi.string().required(),
      }).allow(null),
      quantity: Joi.number().required(),
      taxRate: Joi.number().required(),
      useCount: Joi.number().required(),
    })
  ),
  location: addMessages(Joi.object().required(), 'Location'),
  message: DefaultNullValidation,
  note: DefaultNullValidation,
  patient: addMessages(Joi.object().required(), 'Client'),
  provider: addMessages(Joi.object(), 'Provider').allow(null),
  signatureId: Joi.string().allow(null),
  invoiceServiceConsumables: Joi.array().items(
    Joi.object({
      label: Joi.string(),
      value: Joi.string(),
      object: Joi.object({
        id: Joi.string(),
        serviceId: Joi.string(),
        quantity: Joi.number(),
      }).unknown(true),
    })
  ),
});

export default InvoiceFormValidation;
