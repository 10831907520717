import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type MergePatientsMutationVariables = Types.Exact<{
  patientId: Types.Scalars['uuid'];
  patientIdToMerge: Types.Scalars['uuid'];
  workspaceId: Types.Scalars['uuid'];
}>;


export type MergePatientsMutation = { __typename?: 'mutation_root', mergePatient?: { __typename?: 'SuccessAnswer', success: boolean } | null | undefined };


export const MergePatientsDocument = gql`
    mutation MergePatients($patientId: uuid!, $patientIdToMerge: uuid!, $workspaceId: uuid!) {
  mergePatient(
    patientId: $patientId
    patientIdToMerge: $patientIdToMerge
    workspaceId: $workspaceId
  ) {
    success
  }
}
    `;
export type MergePatientsMutationFn = Apollo.MutationFunction<MergePatientsMutation, MergePatientsMutationVariables>;

/**
 * __useMergePatientsMutation__
 *
 * To run a mutation, you first call `useMergePatientsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMergePatientsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mergePatientsMutation, { data, loading, error }] = useMergePatientsMutation({
 *   variables: {
 *      patientId: // value for 'patientId'
 *      patientIdToMerge: // value for 'patientIdToMerge'
 *      workspaceId: // value for 'workspaceId'
 *   },
 * });
 */
export function useMergePatientsMutation(baseOptions?: Apollo.MutationHookOptions<MergePatientsMutation, MergePatientsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MergePatientsMutation, MergePatientsMutationVariables>(MergePatientsDocument, options);
      }
export type MergePatientsMutationHookResult = ReturnType<typeof useMergePatientsMutation>;
export type MergePatientsMutationResult = Apollo.MutationResult<MergePatientsMutation>;
export type MergePatientsMutationOptions = Apollo.BaseMutationOptions<MergePatientsMutation, MergePatientsMutationVariables>;