import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type DeleteDevicesMutationVariables = Types.Exact<{
  deviceIds?: Types.Maybe<Array<Types.Scalars['uuid']> | Types.Scalars['uuid']>;
}>;


export type DeleteDevicesMutation = { __typename?: 'mutation_root', delete_device?: { __typename?: 'device_mutation_response', affected_rows: number } | null | undefined };


export const DeleteDevicesDocument = gql`
    mutation deleteDevices($deviceIds: [uuid!]) {
  delete_device(where: {id: {_in: $deviceIds}}) {
    affected_rows
  }
}
    `;
export type DeleteDevicesMutationFn = Apollo.MutationFunction<DeleteDevicesMutation, DeleteDevicesMutationVariables>;

/**
 * __useDeleteDevicesMutation__
 *
 * To run a mutation, you first call `useDeleteDevicesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDevicesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDevicesMutation, { data, loading, error }] = useDeleteDevicesMutation({
 *   variables: {
 *      deviceIds: // value for 'deviceIds'
 *   },
 * });
 */
export function useDeleteDevicesMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDevicesMutation, DeleteDevicesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDevicesMutation, DeleteDevicesMutationVariables>(DeleteDevicesDocument, options);
      }
export type DeleteDevicesMutationHookResult = ReturnType<typeof useDeleteDevicesMutation>;
export type DeleteDevicesMutationResult = Apollo.MutationResult<DeleteDevicesMutation>;
export type DeleteDevicesMutationOptions = Apollo.BaseMutationOptions<DeleteDevicesMutation, DeleteDevicesMutationVariables>;