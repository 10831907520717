import { FormInputProps } from '@webapp/types';
import { ChangeEvent, useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FormLabel, Grid, Switch, Text } from '@chakra-ui/react';
import { ErrorMessage } from '@hookform/error-message';
import { DEFAULT_LABEL_STYLE } from './constants';

function SwitchInput({
  id,
  label,
  labelAlign,
  labelStyle = DEFAULT_LABEL_STYLE,
  name,
  disabled,
  isEditable,
}: FormInputProps) {
  const {
    formState: { errors },
    getValues,
    register,
    setValue,
  } = useFormContext();

  const initialValue = getValues(name) ?? false;
  const inputId = id ?? name;

  useEffect(() => {
    register(name);
  });

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    setValue(name, event.target.checked);
  }

  return (
    <Grid
      alignItems={labelAlign}
      className="input-outer"
      gridAutoRows="max-content"
      gridRowGap="6px"
      gridTemplateColumns="1fr"
    >
      {Boolean(label) && (
        <FormLabel
          className="input-label"
          htmlFor={inputId}
          margin="0 0 0 0"
          {...labelStyle}
        >
          {label}
        </FormLabel>
      )}
      <Grid className="input-inner" gridRowGap="8px">
        <Controller
          name={name}
          render={({ field: { value } }) => (
            <Switch
              data-value-for={name}
              isDisabled={disabled || isEditable === false}
              defaultChecked={initialValue}
              onChange={handleChange}
              isChecked={value}
            />
          )}
        />

        <ErrorMessage
          errors={errors}
          name={name}
          render={({ message }) => (
            <Text
              className="input-error"
              color="red.500"
              _before={{
                display: 'inline',
                content: '"⚠ "',
              }}
            >
              {message}
            </Text>
          )}
        />
      </Grid>
    </Grid>
  );
}

export default SwitchInput;
