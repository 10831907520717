import { CustomInsertInvoiceInput } from '@webapp/graphql';
import { InvoiceFormValues } from './types';

export function transformInsertUpdateInvoiceValues(
  values: InvoiceFormValues
): CustomInsertInvoiceInput {
  return {
    ...(values.appointmentId && { appointmentId: values.appointmentId }),
    address: values.address,
    discount: values.discount ?? 0,
    dueDate: values.dueDate,
    email: values.email,
    gratuity: values.gratuity,
    message: values.message,
    invoiceNumber: values.invoiceNumber,
    locationId: values.location.object.id,
    note: values.note,
    providerId: values.provider?.object.id,
    preTaxTotal: values.preTaxTotal,
    postTaxTotal: values.postTaxTotal,
    serviceItems: values.services.map((service) => ({
      id: service.object.id,
      pricePerUnit: service.object.pricePerUnit,
      price: service.object.pricePerUnit * service.object.quantity,
      providerId: service.object.providerId || values.provider.object.id,
      quantity: service.object.quantity,
      serviceId: service.object.serviceId,
      taxRate: service.object.taxRate,
    })),
    signatureId: values.signatureId,
    taxTotal: values.taxTotal,
    patientId: values.patient.object.id,
    ...(values.memberships && {
      membershipItems: values.memberships.map((membership) => ({
        membershipId: membership.value,
        price: membership.object.price,
        taxRate: membership.object.taxRate,
      })),
    }),
    ...(values.packages && {
      packageItems: values.packages.map((packageItem) => ({
        packageId: packageItem.value,
        quantityToUse: packageItem.object.quantityToUse,
        price: packageItem.object.price,
        taxRate: packageItem.object.taxRate,
      })),
    }),
    ...(values.appliedCoupons && {
      invoiceCoupons: values.appliedCoupons.map(
        ({
          amount,
          couponId,
          expirationOverride,
          isMembershipCoupon,
          packageId,
        }) => ({
          amount,
          couponId,
          expirationOverride,
          isMembershipCoupon,
          packageId,
        })
      ),
    }),
  };
}

export default transformInsertUpdateInvoiceValues;
