import { useApolloClient } from '@apollo/client';
import {
  ListPhotosDocument,
  ListPhotosQueryResult,
  ListPhotosQueryVariables,
  Order_By,
  Photo_Bool_Exp,
} from '@webapp/graphql';

interface Asset {
  id: any;
  type: string;
  thumbUri: string;
  meta: {
    uri: string;
  };
  size: {
    width: number;
    height: number;
  };
  context: {
    sourceId: string;
  };
}

interface QueryData {
  query?: string | undefined;
  page: number;
  perPage: number;
}

interface FindAssetsResult {
  assets: Asset[];
  currentPage: number;
  total: number;
  nextPage: number | undefined;
}

interface UseLoadPhotosOutput {
  findAssets: (
    queryData: QueryData | undefined,
    additionalQuery?: Photo_Bool_Exp
  ) => Promise<FindAssetsResult | undefined>;
}

export const useLoadPhotos = (): UseLoadPhotosOutput => {
  const client = useApolloClient();

  return {
    findAssets: async (
      queryData:
        | { query?: string | undefined; page: number; perPage: number }
        | undefined,
      additionalQuery?: Photo_Bool_Exp
    ): Promise<FindAssetsResult | undefined> => {
      if (!queryData) return undefined;

      const { query, page, perPage } = queryData;

      const currentPage = page > 0 ? page - 1 : 0;

      const { data } = await client.query<
        ListPhotosQueryResult['data'],
        ListPhotosQueryVariables
      >({
        query: ListPhotosDocument,
        variables: {
          where: {
            ...(query && {
              photoMediaTags: {
                mediaTag: {
                  title: { _ilike: `%${query}%` },
                },
              },
            }),
            ...additionalQuery,
          },
          offset: currentPage * perPage,
          limit: perPage,
          orderBy: {
            createdAt: Order_By.Desc,
          },
        },
      });

      const assets =
        data?.photo.map(({ id, file }) => ({
          id,
          type: 'ly.img.image',
          thumbUri: file?.thumbnailUrl ?? '',
          meta: {
            uri: file?.url ?? '',
          },
          size: {
            width: 600,
            height: 600,
          },
          context: {
            sourceId: 'photos',
          },
        })) ?? [];

      const count = data?.photo_aggregate?.aggregate?.count ?? 0;

      const lastPage = Math.ceil(count / perPage);

      return {
        assets,
        currentPage: page,
        total: count,
        nextPage: page > lastPage ? undefined : page + 1,
      };
    },
  };
};

export default useLoadPhotos;
