import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetLocationsExternalQueryVariables = Types.Exact<{
  where?: Types.Maybe<Types.Location_Bool_Exp>;
  limit?: Types.Maybe<Types.Scalars['Int']>;
  offset?: Types.Maybe<Types.Scalars['Int']>;
  orderBy?: Types.Maybe<Array<Types.Location_Order_By> | Types.Location_Order_By>;
}>;


export type GetLocationsExternalQuery = { __typename?: 'query_root', location: Array<{ __typename?: 'location', id: any, name: string, phoneNumber?: string | null | undefined, payrixMerchantId?: string | null | undefined, timezone?: string | null | undefined }> };


export const GetLocationsExternalDocument = gql`
    query GetLocationsExternal($where: location_bool_exp, $limit: Int, $offset: Int, $orderBy: [location_order_by!]) {
  location(where: $where, limit: $limit, offset: $offset, order_by: $orderBy) {
    id
    name
    phoneNumber
    payrixMerchantId
    timezone
  }
}
    `;

/**
 * __useGetLocationsExternalQuery__
 *
 * To run a query within a React component, call `useGetLocationsExternalQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLocationsExternalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLocationsExternalQuery({
 *   variables: {
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetLocationsExternalQuery(baseOptions?: Apollo.QueryHookOptions<GetLocationsExternalQuery, GetLocationsExternalQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLocationsExternalQuery, GetLocationsExternalQueryVariables>(GetLocationsExternalDocument, options);
      }
export function useGetLocationsExternalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLocationsExternalQuery, GetLocationsExternalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLocationsExternalQuery, GetLocationsExternalQueryVariables>(GetLocationsExternalDocument, options);
        }
export type GetLocationsExternalQueryHookResult = ReturnType<typeof useGetLocationsExternalQuery>;
export type GetLocationsExternalLazyQueryHookResult = ReturnType<typeof useGetLocationsExternalLazyQuery>;
export type GetLocationsExternalQueryResult = Apollo.QueryResult<GetLocationsExternalQuery, GetLocationsExternalQueryVariables>;