import React, { useCallback, useState, useEffect } from 'react';
import {
  Text,
  InputGroup,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
} from '@chakra-ui/react';
import { FractionInputProps } from './types/FractionInputProps';
import DEFAULT_CONTROL_STYLE from './styles/DefaultControlStyle';

export default function FractionInput({
  hasIncrement = true,
  incrementStep = 1,
  max,
  min,
  name,
  onChange,
  placeholder,
  precision = 0,
  styles = {},
  value: valueFromProps,
  ...props
}: Omit<FractionInputProps, 'type'>) {
  const value = valueFromProps || '0/1';

  const inputStyles = { ...DEFAULT_CONTROL_STYLE, ...styles.control };

  const [numerator, denominator] = value.split('/');

  const [localNumerator, setLocalNumerator] = useState<string>(
    numerator || '0'
  );

  const [localDenominator, setLocalDenominator] = useState<string>(
    denominator || '1'
  );

  useEffect(() => {
    setLocalNumerator(numerator || '0');
    setLocalDenominator(denominator || '1');
  }, [denominator, numerator, value]);

  const handleNumeratorChange = useCallback(
    (stringValue: string, numberValue: number) => {
      setLocalNumerator(stringValue);
      onChange(`${numberValue}/${localDenominator}`);
    },
    [localDenominator, onChange]
  );

  const handleDenominatorChange = useCallback(
    (stringValue: string) => {
      setLocalDenominator(stringValue);
      onChange(`${localNumerator}/${stringValue}`);
    },
    [localNumerator, onChange]
  );

  return (
    <InputGroup>
      <NumberInput
        format={(val: string) => val}
        id={name}
        max={max}
        min={min}
        onBlur={() => {
          if (!localNumerator) {
            setLocalNumerator('0');
          }
        }}
        onChange={handleNumeratorChange}
        precision={precision}
        step={incrementStep}
        value={localNumerator}
        width="100%"
        {...props}
      >
        <NumberInputField placeholder={placeholder} {...inputStyles} />
        {hasIncrement && (
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        )}
      </NumberInput>
      <Text
        alignItems="center"
        display="flex"
        fontSize="24px"
        height="40px"
        justifyContent="center"
        padding="0 8px"
        lineHeight="40px"
      >
        /
      </Text>
      <NumberInput
        format={(val: string) => val}
        id={name}
        max={max}
        min={min}
        onBlur={() => {
          if (!localDenominator) {
            setLocalDenominator('0');
          }
        }}
        onChange={handleDenominatorChange}
        precision={precision}
        step={incrementStep}
        value={localDenominator}
        width="100%"
        {...props}
      >
        <NumberInputField placeholder={placeholder} {...inputStyles} />
        {hasIncrement && (
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        )}
      </NumberInput>
    </InputGroup>
  );
}
