import { InfoIcon } from '@chakra-ui/icons';
import {
  chakra,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Icon,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { PropsWithChildren } from 'react';
import DEFAULT_LABEL_STYLE from './styles/DefaultLabelStyle';
import DEFAULT_LABEL_SUBTEXT_STYLE from './styles/DefaultLabelSubtextStyle';
import { BaseFormInputProps, BaseInputProps } from './types';

interface FormInputWrapperProps extends BaseInputProps, BaseFormInputProps {
  error?: string;
}

export const FormInputLabel = chakra(FormLabel, DEFAULT_LABEL_STYLE);

export default function FormInputWrapper({
  children,
  error,
  label,
  description,
  labelSubText,
  name,
  styles = {},
  testId,
}: PropsWithChildren<FormInputWrapperProps>) {
  const containerStyle = { ...styles.container };
  const labelStyle = Object.assign(DEFAULT_LABEL_STYLE, styles.label);

  const labelSubTextStyle = Object.assign(
    DEFAULT_LABEL_SUBTEXT_STYLE,
    styles.labelSubText
  );

  const errorMessage = typeof error === 'string' ? error : error?.message;
  return (
    <FormControl data-testid={testId} isInvalid={!!error} {...containerStyle}>
      {label && (
        <FormInputLabel htmlFor={name} {...labelStyle}>
          <HStack spacing="5px">
            <Text>{label}</Text>
            {description && (
              <Tooltip
                label={description}
                placement="top"
                hasArrow
                openDelay={300}
              >
                <Icon
                  as={InfoIcon}
                  boxSize={3}
                  color="gray.500"
                  cursor="help"
                />
              </Tooltip>
            )}
          </HStack>
        </FormInputLabel>
      )}
      {labelSubText && <Text {...labelSubTextStyle}>{labelSubText}</Text>}
      <chakra.div>{children}</chakra.div>
      {error && (
        <FormErrorMessage {...styles.error}>{errorMessage}</FormErrorMessage>
      )}
    </FormControl>
  );
}
