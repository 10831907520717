import * as Types from '../../generated/graphql';

import { gql } from '@apollo/client';
export type FinixTerminalFieldsFragment = { __typename?: 'finixTerminal', id: any, locationId: any, finixDeviceId: string, serialNumber: string, status: string, model: string, name?: string | null | undefined, createdAt: any, updatedAt: any, location: { __typename?: 'location', id: any, name: string } };

export const FinixTerminalFieldsFragmentDoc = gql`
    fragment FinixTerminalFields on finixTerminal {
  id
  locationId
  finixDeviceId
  serialNumber
  status
  model
  name
  createdAt
  updatedAt
  location {
    id
    name
  }
}
    `;