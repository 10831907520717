import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { MembershipFieldsFragmentDoc } from '../../fragments/membershipFields.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type UpdateMembershipMutationVariables = Types.Exact<{
  set?: Types.Maybe<Types.Membership_Set_Input>;
  id: Types.Scalars['uuid'];
}>;


export type UpdateMembershipMutation = { __typename?: 'mutation_root', update_membership_by_pk?: { __typename?: 'membership', id: any, name: string, type: Types.MembershipType_Enum, price: number, taxRate: any, description?: string | null | undefined, image?: string | null | undefined, availableInStore?: boolean | null | undefined, discount: number, billingPeriodInDays: number, maxBillingCycles?: number | null | undefined, billingDayOfMonth?: number | null | undefined, createdAt: any, updatedAt: any, locationId?: any | null | undefined, workspaceId: any, color: string, hasCoupons: boolean, hasCredits: boolean, credits: number, archived: boolean, location?: { __typename?: 'location', id: any, name: string } | null | undefined, membershipCoupons: Array<{ __typename?: 'membershipCoupon', count: number, coupon: { __typename?: 'coupon', id: any, name: string } }> } | null | undefined };


export const UpdateMembershipDocument = gql`
    mutation UpdateMembership($set: membership_set_input, $id: uuid!) {
  update_membership_by_pk(_set: $set, pk_columns: {id: $id}) {
    ...MembershipFields
  }
}
    ${MembershipFieldsFragmentDoc}`;
export type UpdateMembershipMutationFn = Apollo.MutationFunction<UpdateMembershipMutation, UpdateMembershipMutationVariables>;

/**
 * __useUpdateMembershipMutation__
 *
 * To run a mutation, you first call `useUpdateMembershipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMembershipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMembershipMutation, { data, loading, error }] = useUpdateMembershipMutation({
 *   variables: {
 *      set: // value for 'set'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateMembershipMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMembershipMutation, UpdateMembershipMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMembershipMutation, UpdateMembershipMutationVariables>(UpdateMembershipDocument, options);
      }
export type UpdateMembershipMutationHookResult = ReturnType<typeof useUpdateMembershipMutation>;
export type UpdateMembershipMutationResult = Apollo.MutationResult<UpdateMembershipMutation>;
export type UpdateMembershipMutationOptions = Apollo.BaseMutationOptions<UpdateMembershipMutation, UpdateMembershipMutationVariables>;