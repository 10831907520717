import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GoogleRedirectMutationVariables = Types.Exact<{
  code?: Types.Maybe<Types.Scalars['String']>;
  scope?: Types.Maybe<Types.Scalars['String']>;
  state?: Types.Maybe<Types.Scalars['String']>;
}>;


export type GoogleRedirectMutation = { __typename?: 'mutation_root', googleRedirect: { __typename?: 'SuccessAnswer', success: boolean } };


export const GoogleRedirectDocument = gql`
    mutation GoogleRedirect($code: String, $scope: String, $state: String) {
  googleRedirect(code: $code, scope: $scope, state: $state) {
    success
  }
}
    `;
export type GoogleRedirectMutationFn = Apollo.MutationFunction<GoogleRedirectMutation, GoogleRedirectMutationVariables>;

/**
 * __useGoogleRedirectMutation__
 *
 * To run a mutation, you first call `useGoogleRedirectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGoogleRedirectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [googleRedirectMutation, { data, loading, error }] = useGoogleRedirectMutation({
 *   variables: {
 *      code: // value for 'code'
 *      scope: // value for 'scope'
 *      state: // value for 'state'
 *   },
 * });
 */
export function useGoogleRedirectMutation(baseOptions?: Apollo.MutationHookOptions<GoogleRedirectMutation, GoogleRedirectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GoogleRedirectMutation, GoogleRedirectMutationVariables>(GoogleRedirectDocument, options);
      }
export type GoogleRedirectMutationHookResult = ReturnType<typeof useGoogleRedirectMutation>;
export type GoogleRedirectMutationResult = Apollo.MutationResult<GoogleRedirectMutation>;
export type GoogleRedirectMutationOptions = Apollo.BaseMutationOptions<GoogleRedirectMutation, GoogleRedirectMutationVariables>;