/* eslint-disable import/prefer-default-export */
import {
  HStack,
  Stack,
  Box,
  Heading,
  Table,
  Tbody,
  Td,
  Link,
  Th,
  Thead,
  Text,
  Tr,
  useColorModeValue as mode,
  Flex,
  Tag,
} from '@chakra-ui/react';
import { Link as ReactLink } from 'react-router-dom';
import {
  useGenerateAudienceQuery,
  useGetPatientWorkspacesQuery,
} from '@webapp/graphql';
import { useStores } from '@webapp/state-models';
import { observer } from 'mobx-react-lite';
import { configuredDayjs as dayjs } from '@webapp/util-time';
import { AppointmentStatusBadge, PatientStatusBadge } from '@webapp/ui';
import { AudienceCount } from './audience-count';

export const AudiencePreview = observer(() => {
  const { audience } = useStores();
  const { data: generatedAudience, loading } = useGenerateAudienceQuery({
    variables: { ast: audience.getAST(), limit: 20 },
  });
  const { data: staticAudience } = useGetPatientWorkspacesQuery({
    variables: {
      where: {
        id: {
          _in: audience.snapshot.toJSON().slice(0, 20),
        },
      },
    },
  });

  const patients =
    audience.type === 'active' || !audience.id
      ? generatedAudience?.audienceResults?.map(
          (patient) => patient?.patientWorkspace
        )
      : staticAudience?.patientWorkspace;

  if (loading) return <>Loading...</>;
  return (
    <Stack px={4} w={'full'}>
      <HStack>
        <Heading size="sm">Audience preview</Heading>
        <AudienceCount audienceId={audience.id} ast={audience.getAST()} />
      </HStack>
      <Box maxH="100vh" overflow={'auto'} w={'full'}>
        <Table my="2" borderWidth="1px" fontSize="sm" maxHeight="100px">
          <Thead bg={mode('gray.50', 'gray.800')}>
            <Tr>
              <Th
                whiteSpace="nowrap"
                scope="col"
                bg={mode('gray.50', 'gray.800')}
              >
                Name
              </Th>
              <Th
                whiteSpace="nowrap"
                scope="col"
                bg={mode('gray.50', 'gray.800')}
              >
                Last Appointment
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {patients?.map((patientWorkspace, idx) => (
              <Tr key={idx}>
                <Td>
                  <Link
                    as={ReactLink}
                    target="_blank"
                    to={`/patients/${patientWorkspace?.patient?.id}`}
                  >
                    <Stack spacing="1">
                      <Heading size="sm">
                        {patientWorkspace?.firstName}{' '}
                        {patientWorkspace?.lastName}
                      </Heading>
                      <Box>{patientWorkspace?.email}</Box>
                      <PatientStatusBadge
                        status={patientWorkspace?.status}
                        readonly={true}
                      />
                      <Tag w={'fit-content'}>
                        {patientWorkspace?.leadStatus}
                      </Tag>
                    </Stack>
                  </Link>
                </Td>
                <Td>
                  {patientWorkspace?.patient?.lastAppointment &&
                    patientWorkspace?.patient?.lastAppointment.length > 0 && (
                      <Link
                        as={ReactLink}
                        target="_blank"
                        to={`/patients/${patientWorkspace?.patient?.id}/appointments/${patientWorkspace?.patient?.lastAppointment?.[0]?.id}`}
                      >
                        <Stack
                          w={'full'}
                          m={0}
                          bg="gray.600"
                          rounded={'md'}
                          p={2}
                        >
                          <HStack
                            w={'full'}
                            p={0}
                            m={0}
                            justify="space-between"
                          >
                            <Text fontSize={'10px'} color="white">
                              Previous Appointment
                            </Text>
                            <Text fontSize={'10px'} color="white">
                              {dayjs(
                                patientWorkspace?.patient?.lastAppointment?.[0]
                                  ?.startTime
                              ).format('L')}
                            </Text>
                          </HStack>
                          <HStack
                            w={'full'}
                            p={0}
                            m={0}
                            justify="space-between"
                          >
                            <Flex
                              p={0}
                              m={0}
                              flex={3}
                              wrap={'wrap'}
                              flexGrow={1}
                            >
                              {patientWorkspace?.patient?.lastAppointment?.[0]?.appointmentServices?.map(
                                (service) => (
                                  <Tag
                                    size="sm"
                                    p={1}
                                    bg="teal.50"
                                    w={'fit-content'}
                                  >
                                    <Text fontSize={'10px'}>
                                      {service.service.name}
                                    </Text>
                                  </Tag>
                                )
                              )}
                            </Flex>

                            <AppointmentStatusBadge
                              buttonStyleOverrides={{
                                fontSize: '10px',
                                padding: 1,
                                lineHeight: '10px',
                              }}
                              appointment={
                                patientWorkspace?.patient?.lastAppointment?.[0]
                              }
                            />
                          </HStack>
                        </Stack>
                      </Link>
                    )}
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
    </Stack>
  );
});
