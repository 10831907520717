import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type DeleteLocationProvidersMutationVariables = Types.Exact<{
  providerIds?: Types.Maybe<Array<Types.Scalars['uuid']> | Types.Scalars['uuid']>;
  locationId: Types.Scalars['uuid'];
}>;


export type DeleteLocationProvidersMutation = { __typename?: 'mutation_root', delete_locationProvider?: { __typename?: 'locationProvider_mutation_response', affected_rows: number } | null | undefined };


export const DeleteLocationProvidersDocument = gql`
    mutation deleteLocationProviders($providerIds: [uuid!], $locationId: uuid!) {
  delete_locationProvider(
    where: {providerId: {_in: $providerIds}, locationId: {_eq: $locationId}}
  ) {
    affected_rows
  }
}
    `;
export type DeleteLocationProvidersMutationFn = Apollo.MutationFunction<DeleteLocationProvidersMutation, DeleteLocationProvidersMutationVariables>;

/**
 * __useDeleteLocationProvidersMutation__
 *
 * To run a mutation, you first call `useDeleteLocationProvidersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLocationProvidersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLocationProvidersMutation, { data, loading, error }] = useDeleteLocationProvidersMutation({
 *   variables: {
 *      providerIds: // value for 'providerIds'
 *      locationId: // value for 'locationId'
 *   },
 * });
 */
export function useDeleteLocationProvidersMutation(baseOptions?: Apollo.MutationHookOptions<DeleteLocationProvidersMutation, DeleteLocationProvidersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteLocationProvidersMutation, DeleteLocationProvidersMutationVariables>(DeleteLocationProvidersDocument, options);
      }
export type DeleteLocationProvidersMutationHookResult = ReturnType<typeof useDeleteLocationProvidersMutation>;
export type DeleteLocationProvidersMutationResult = Apollo.MutationResult<DeleteLocationProvidersMutation>;
export type DeleteLocationProvidersMutationOptions = Apollo.BaseMutationOptions<DeleteLocationProvidersMutation, DeleteLocationProvidersMutationVariables>;