import { extendTheme } from '@chakra-ui/react';
import { ComponentsStyleConfig } from '@webapp/types';

const TextConfig: ComponentsStyleConfig = {
  Text: {
    baseStyle: {
      /* 
            WARNING: Following has butterfly effects 

            Affected: [
                MenuButton,
            ]
        */
      //   color: 'text.100',
    },
  },
};

const TextTheme = extendTheme({ components: TextConfig });

export default TextTheme;
