import {
  flow,
  getSnapshot,
  Instance,
  SnapshotOut,
  types,
} from 'mobx-state-tree';
import camelcase from 'camelcase';
import { getErrorMessage } from '@webapp/utils';
import {
  InsertLeadFormFieldDocument,
  GraphqlClient,
  InsertLeadFormFieldMutationResult,
  DeleteLeadFormFieldDocument,
  UpdateLeadFormStepDocument,
} from '@webapp/graphql';
import { toast } from 'react-hot-toast';
import { sortBy } from 'lodash';
import { LeadFormFieldModel, LeadFormField } from './lead-form-field';

const LeadFormStepPropertiesModel = types.model('LeadFormStepProperties', {
  redirectUrl: types.maybe(types.string),
});

/**
 * A LeadFormStepStore model.
 */
// prettier-ignore
export const LeadFormStepModel = types.model("LeadFormStep").props({
    id: types.maybeNull(types.identifier),
    name: types.maybeNull(types.string),
    subtitle: types.maybeNull(types.string),
    callToAction: types.maybeNull(types.string),
    fields: types.optional(types.array(LeadFormFieldModel),[]),
    order: types.number,
    properties: types.maybeNull(LeadFormStepPropertiesModel),
    workspaceId: types.maybeNull(types.string),
    canDelete: types.optional(types.boolean, true),
    canReOrder: types.optional(types.boolean, true),
    type: types.optional(types.string, 'input')
})
.views((self) => ({
    get sortedFields() {
        return sortBy(self.fields, 'order')
    },
})
)
.actions((self) => ({
    addField: flow(function*(field: any) {
        const leadFormField = {
            order: self.fields.length,
            apiFieldKey: field.apiFieldKey || camelcase(field.label.replace('?','')),
            label: field.label || `Form field ${self.fields.length + 1}`,
            type: field.type || 'string',
            validationProps: field.validationProps,
            leadFormStepId: self.id,
            workspaceId: self.workspaceId
        }
        try {
            const client = GraphqlClient()
            const {data} : InsertLeadFormFieldMutationResult = yield client.mutate({mutation: InsertLeadFormFieldDocument, variables: { leadFormField }})
            self.fields.push(LeadFormFieldModel.create(data?.insert_leadFormField?.returning[0]))
        } catch(err) {
            toast.error(getErrorMessage(err))
        }
    }),
    removeField: flow(function*(field: LeadFormField) { 
        self.fields.remove(field);
        try {
            const client = GraphqlClient()
            yield client.mutate({mutation: DeleteLeadFormFieldDocument, variables: { id: field.id }})
        } catch(err) {
            toast.error(getErrorMessage(err))
        }
    }),
    setCallToAction: flow(function*(value: string) {
        self.callToAction = value; 
        try {
            const client = GraphqlClient()
            yield client.mutate({mutation: UpdateLeadFormStepDocument, variables: { _set: { callToAction: value}, id: self.id}})
        } catch(err) {
            toast.error(getErrorMessage(err))
        }
    }),
    setRedirectUrl: flow(function*(value: string) {
        self.properties = { ...getSnapshot(self.properties || {}),  redirectUrl: value}; 
        try {
            const client = GraphqlClient()
            yield client.mutate({mutation: UpdateLeadFormStepDocument, variables: { _set: { properties: {
                ...(getSnapshot(self.properties || {})),
                redirectUrl: value
            }}, id: self.id}})
        } catch(err) {
            toast.error(getErrorMessage(err))
        }
    }),
    setName: flow(function*(value: string) {
        self.name = value; 

        try {
            const client = GraphqlClient()
            yield client.mutate({mutation: UpdateLeadFormStepDocument, variables: { _set: { name: value}, id: self.id}})
        } catch(err) {
            toast.error(getErrorMessage(err))
        }
    }),
    setSubtitle: flow(function*(value: string) {
        self.subtitle = value; 

        try {
            const client = GraphqlClient()
            yield client.mutate({mutation: UpdateLeadFormStepDocument, variables: { _set: { subtitle: value}, id: self.id}})
        } catch(err) {
            toast.error(getErrorMessage(err))
        }
    }),
    setOrder: flow(function*(value: number) {
        self.order = value; 
        try {
            const client = GraphqlClient()
            yield client.mutate({mutation: UpdateLeadFormStepDocument, variables: { _set: { order: value}, id: self.id }})
        } catch(err) {
            toast.error(getErrorMessage(err))
        }
    }),
}))
/**
 * The LeadFormStep instance.
 */
export type LeadFormStep = Instance<typeof LeadFormStepModel>;

/**
 * The data of a LeadFormStep.
 */
export type LeadFormStepSnapshot = SnapshotOut<typeof LeadFormStepModel>;
