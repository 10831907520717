import {
  Box,
  Button,
  Container,
  Center,
  Heading,
  Stack,
  Text,
  ChakraProvider,
} from '@chakra-ui/react';

const ErrorComponent = () => (
  <ChakraProvider>
    <Center w={'100vw'} h={'100vh'}>
      <Box as="section" py={{ base: '4', md: '8' }}>
        <Container maxW="3xl">
          <Box
            bg="bg.surface"
            boxShadow="sm"
            borderRadius="lg"
            p={{ base: '4', md: '6' }}
          >
            <Stack spacing="5">
              <Stack spacing="1">
                <Text textStyle="lg" fontWeight="medium">
                  ⚠️ We've encountered an error
                </Text>
                <Text textStyle="sm" color="fg.muted">
                  We encountered an error during your last operation, the
                  engineering team has been notified of the error and will
                  review it.
                </Text>
              </Stack>

              <Stack spacing="3">
                <Heading size="sm">
                  You can get back to work by reloading the page.
                </Heading>
                <Button
                  onClick={() => window.location.reload()}
                  alignSelf={{ sm: 'flex-end' }}
                >
                  Reload Page
                </Button>
              </Stack>
            </Stack>
          </Box>
        </Container>
      </Box>
    </Center>
  </ChakraProvider>
);

const CustomErrorBoundary = ({ children, Sentry }) => {
  const onError = (error) => {
    if (error.message.includes('Failed to fetch dynamically imported module')) {
      window.location.reload();
    }
  };

  return (
    <Sentry.ErrorBoundary fallback={<ErrorComponent />} onError={onError}>
      {children}
    </Sentry.ErrorBoundary>
  );
};

export default CustomErrorBoundary;
