import {
  Center,
  chakra,
  Stack,
  Text,
  Accordion,
  AccordionItem,
  AccordionIcon,
  AccordionButton,
  AccordionPanel,
  Box,
} from '@chakra-ui/react';
import { useGetServicesByCategoryQuery } from '@webapp/graphql';
import { Reorder } from 'framer-motion';
import { useEffect, useState } from 'react';
import { reorderServices } from '../../../util';

const List = chakra(Reorder.Group);
const ListItem = chakra(Reorder.Item);

export const ServiceRanking = ({
  workspaceId,
  onChange,
  defaultValue = {},
}: {
  workspaceId: string;
  onChange: (value: any) => void;
  defaultValue?: {};
}) => {
  const [categoryOrder, setCategoryOrder] = useState<string[]>(
    defaultValue?.categoryOrder || []
  );
  const [serviceOrders, setServiceOrders] = useState<{
    [key: string]: string[];
  }>(defaultValue?.serviceOrders || {});
  const [reorderedCategoryServices, setReorderedCategoryServices] = useState<
    any[]
  >([]);

  const { data: servicesData } = useGetServicesByCategoryQuery({
    variables: {
      where: {
        workspaceId: { _eq: workspaceId },
      },
      serviceWhere: {
        workspaceId: { _eq: workspaceId },
        active: { _eq: true },
        archived: { _eq: false },
        canBeBookedOnline: { _eq: true },
      },
    },
  });

  useEffect(() => {
    if (servicesData) {
      let reorderedServices = [];
      if (categoryOrder && categoryOrder.length === 0) {
        // If no ordering is specified, use the original order but include uncategorized services
        reorderedServices = [...servicesData.serviceCategory];
        // Set default category order
        let defaultCategoryOrder = servicesData.serviceCategory.map(
          (service) => service.title
        );
        if (
          servicesData.uncategorizedServices &&
          servicesData.uncategorizedServices.length > 0
        ) {
          const uncategorizedCategory = {
            title: 'Uncategorized',
            services: servicesData.uncategorizedServices,
          };
          reorderedServices.push(uncategorizedCategory);
          defaultCategoryOrder.push('Uncategorized');
        }
        setCategoryOrder(defaultCategoryOrder);
      } else {
        // Reorder according to specified order
        reorderedServices = reorderServices(
          servicesData,
          categoryOrder,
          serviceOrders
        );

        setCategoryOrder(reorderedServices.map((service) => service.title));
      }

      setReorderedCategoryServices(reorderedServices);
    }
  }, [servicesData]);

  useEffect(() => {
    onChange({ categoryOrder, serviceOrders });
  }, [categoryOrder, serviceOrders, onChange]);

  const handleCategoryReorder = (newOrder: string[]) => {
    setCategoryOrder(newOrder);
    setReorderedCategoryServices((prev) => {
      const newReorderedServices = newOrder
        .map((title) => prev.find((category) => category.title === title))
        .filter(Boolean);
      return newReorderedServices;
    });
  };

  const handleServiceReorder = (categoryTitle: string, newOrder: string[]) => {
    setServiceOrders((prev) => ({
      ...prev,
      [categoryTitle]: newOrder,
    }));

    setReorderedCategoryServices((prev) => {
      return prev.map((category) => {
        if (category.title === categoryTitle) {
          // Update the services order for the specific category
          return {
            ...category,
            services: newOrder
              .map((id) =>
                category.services.find((service) => service.id === id)
              )
              .filter(Boolean), // Filter out any undefined values
          };
        }
        return category; // Return unchanged category
      });
    });
  };

  return (
    <Center maxW="sm" mx="auto" py={{ base: '4', md: '8' }}>
      <Stack spacing="5" flex="1">
        <Stack spacing="1">
          <Text textStyle="lg" fontWeight="medium">
            Service Sort
          </Text>
          <Text color="fg.muted" textStyle="sm">
            Grab a service category and move it in the order you'd like it to
            appear in the form. Expand a category to reorder services within it.
            Services will still appear based on provider & location settings as
            well as any other defined booking rules.
          </Text>
        </Stack>
        <List
          values={categoryOrder}
          onReorder={handleCategoryReorder}
          listStyleType="none"
        >
          <Accordion allowToggle>
            {reorderedCategoryServices.map((categoryService) => (
              <AccordionItem
                key={categoryService.title}
                border="1px solid"
                borderColor="gray.200"
                borderRadius="md"
                bgColor="white"
                mb={2}
              >
                <ListItem value={categoryService.title}>
                  <AccordionButton
                    p={4}
                    _hover={{ bg: 'gray.50' }}
                    borderRadius="md"
                  >
                    <Box flex="1" textAlign="left">
                      {categoryService.title}
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </ListItem>
                <AccordionPanel bgColor="gray.50" pb={4}>
                  <List
                    values={
                      categoryService.services.map((service) => service.id) ||
                      []
                    }
                    onReorder={(newOrder) =>
                      handleServiceReorder(categoryService.title, newOrder)
                    }
                    listStyleType="none"
                  >
                    <Stack spacing="3" width="full">
                      {(categoryService.services || []).map((service) => {
                        return service ? (
                          <ListItem
                            key={service.id}
                            value={service.id}
                            p="4"
                            boxShadow="sm"
                            position="relative"
                            borderRadius="lg"
                            cursor="grab"
                            bg="white"
                            whileTap={{ cursor: 'grabbing', scale: 1.1 }}
                          >
                            <Stack shouldWrapChildren spacing="4">
                              <Text
                                textStyle="sm"
                                fontWeight="medium"
                                color="fg.emphasized"
                              >
                                {service.name}
                              </Text>
                            </Stack>
                          </ListItem>
                        ) : null;
                      })}
                    </Stack>
                  </List>
                </AccordionPanel>
              </AccordionItem>
            ))}
          </Accordion>
        </List>
      </Stack>
    </Center>
  );
};

export default ServiceRanking;
