import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type RevertToMediaVersionMutationVariables = Types.Exact<{
  photoId: Types.Scalars['uuid'];
  versionId: Types.Scalars['String'];
}>;


export type RevertToMediaVersionMutation = { __typename?: 'mutation_root', revertToMediaVersion?: { __typename?: 'RevertToMediaVersionOutput', success: boolean } | null | undefined };


export const RevertToMediaVersionDocument = gql`
    mutation revertToMediaVersion($photoId: uuid!, $versionId: String!) {
  revertToMediaVersion(photoId: $photoId, versionId: $versionId) {
    success
  }
}
    `;
export type RevertToMediaVersionMutationFn = Apollo.MutationFunction<RevertToMediaVersionMutation, RevertToMediaVersionMutationVariables>;

/**
 * __useRevertToMediaVersionMutation__
 *
 * To run a mutation, you first call `useRevertToMediaVersionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRevertToMediaVersionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [revertToMediaVersionMutation, { data, loading, error }] = useRevertToMediaVersionMutation({
 *   variables: {
 *      photoId: // value for 'photoId'
 *      versionId: // value for 'versionId'
 *   },
 * });
 */
export function useRevertToMediaVersionMutation(baseOptions?: Apollo.MutationHookOptions<RevertToMediaVersionMutation, RevertToMediaVersionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RevertToMediaVersionMutation, RevertToMediaVersionMutationVariables>(RevertToMediaVersionDocument, options);
      }
export type RevertToMediaVersionMutationHookResult = ReturnType<typeof useRevertToMediaVersionMutation>;
export type RevertToMediaVersionMutationResult = Apollo.MutationResult<RevertToMediaVersionMutation>;
export type RevertToMediaVersionMutationOptions = Apollo.BaseMutationOptions<RevertToMediaVersionMutation, RevertToMediaVersionMutationVariables>;