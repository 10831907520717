/* eslint-disable max-classes-per-file */
import { createElement } from 'react';
import {
  PropertyGridEditorCollection,
  localization,
} from 'survey-creator-react';
import { useLoadServicesExternal } from '@webapp/webapp/hooks';
import { ElementFactory, Question, Serializer, SvgRegistry } from 'survey-core';
import {
  SurveyQuestionElementBase,
  ReactQuestionFactory,
} from 'survey-react-ui';
import { FormControl, FormLabel, Box } from '@chakra-ui/react';
import { GenericObjectSelect } from '@webapp/ui';
import { ServiceFieldsFragment } from '@webapp/graphql';
import Icon from './icon.svg?raw';

const CUSTOM_TYPE = 'service-selection';

// A model for the new question type
export class ServiceModel extends Question {
  getType() {
    return CUSTOM_TYPE;
  }
}

// Register `ServiceModel` as a model for the `book-appointment` type
export function registerService() {
  ElementFactory.Instance.registerElement(
    CUSTOM_TYPE,
    (name) => new ServiceModel(name)
  );
}

// Specify display names for the question type and its properties
const locale = localization.getLocale('');
locale.qt[CUSTOM_TYPE] = 'Service';

// Register an SVG icon for the question type
SvgRegistry.registerIconFromSvg(CUSTOM_TYPE, Icon);

// Add question type metadata for further serialization into JSON
Serializer.addClass(
  CUSTOM_TYPE,
  [],
  () => new ServiceModel('Service'),
  'question'
);

// A class that renders questions of the new type in the UI
export class SurveyQuestionService extends SurveyQuestionElementBase {
  constructor(props) {
    super(props);
    this.state = { value: this.question.value };
  }

  get question() {
    return this.questionBase;
  }

  get value() {
    return this.question.value;
  }

  // Support the read-only and design modes
  get style() {
    return this.question.getPropertyValue('readOnly') ||
      this.question.isDesignMode
      ? { pointerEvents: 'none' }
      : undefined;
  }

  renderElement() {
    return (
      <div style={this.style}>
        <FormControl>
          {/* <FormLabel mb={1}>{field.label}</FormLabel> */}
          <Box zIndex={99}>
            <GenericObjectSelect<ServiceFieldsFragment>
              isAsync
              isMulti
              defaultValue={this.question.value || []}
              onChange={(newValue) => {
                this.question.value = newValue;
              }}
              useLoadOptions={() =>
                useLoadServicesExternal({
                  groupByCategory: true,
                  where: {
                    active: {
                      _eq: true,
                    },
                    archived: {
                      _eq: false,
                    },
                    workspaceId: {
                      _eq: this.question.leadFormWorkspaceId as string,
                    },
                  },
                })
              }
              placeholder="Select service(s)"
            />
          </Box>
        </FormControl>
      </div>
    );
  }
}

// Register `SurveyQuestionService` as a class that renders `book-appointment` questions
ReactQuestionFactory.Instance.registerQuestion(CUSTOM_TYPE, (props) =>
  createElement(SurveyQuestionService, props)
);

PropertyGridEditorCollection.register({
  fit(prop) {
    return prop.type === 'service-selection';
  },
  getJSON() {
    return {
      type: CUSTOM_TYPE,
      colorPickerType: 'Compact',
    };
  },
});
