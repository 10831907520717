import { useState } from 'react';
import {
  useColorModeValue,
  Center,
  Icon,
  Progress,
  Stack,
  Button,
  Flex,
  Text,
  Spinner,
} from '@chakra-ui/react';
import { useDropzone } from 'react-dropzone';
import { FiUploadCloud } from 'react-icons/fi';
import './DropAndUpload.css';

export interface DragAndUploadProps {
  onDrop: (acceptedFiles: File[]) => void;
  progress: number;
  accept?: string;
}

export const DropAndUpload = ({
  onDrop,
  progress,
  accept = '.jpg,.png,.pdf',
}: DragAndUploadProps): JSX.Element => {
  const [files, setFiles] = useState<File[]>([]);

  const setFilesAndAndDrop = (acceptedFiles: File[]): void => {
    setFiles(acceptedFiles);
    onDrop(acceptedFiles);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: setFilesAndAndDrop,
    accept,
    maxFiles: 50,
    multiple: true,
  });

  const activeBg = useColorModeValue('gray.100', 'gray.600');
  const borderColor = useColorModeValue(
    isDragActive ? 'teal.300' : 'gray.300',
    isDragActive ? 'teal.500' : 'gray.500'
  );

  const fileTypePrefix = accept
    .split(',')
    .map((item) => item.replace('.', '').toUpperCase())
    .join(', ');

  const lastIndexOfSpace = fileTypePrefix.lastIndexOf(' ');

  const fileTypePrefixWithOr = `${fileTypePrefix.substring(
    0,
    lastIndexOfSpace + 1
  )} or ${fileTypePrefix.substring(lastIndexOfSpace)}`;

  return (
    <>
      {progress === 0 && (
        <Center
          p={10}
          cursor="pointer"
          bg={isDragActive ? activeBg : 'transparent'}
          _hover={{ bg: activeBg }}
          transition="background-color 0.2s ease"
          borderRadius={4}
          border="2px dashed"
          borderColor={borderColor}
          {...getRootProps()}
        >
          <Stack spacing={2}>
            <input {...getInputProps()} />
            <Flex justifyContent="center">
              <Icon as={FiUploadCloud} color="gray.500" w="48px" h="48px" />
            </Flex>
            <Text textAlign="center">Select a file or drag and drop here</Text>
            {files.length ? (
              <Text textAlign="center" color="gray.500">
                Selected file: {files[0].name}
              </Text>
            ) : (
              <Text color="gray.500">{`${fileTypePrefixWithOr}, file size no more than 10MB`}</Text>
            )}
            <Flex justifyContent="center">
              <Button colorScheme="teal" variant="outline" w="116px">
                Browse files
              </Button>
            </Flex>
          </Stack>
        </Center>
      )}

      {progress > 0 && (
        <Center height={300} width={300}>
          <Spinner size="lg" />
        </Center>
      )}

      <Progress value={progress} />
    </>
  );
};

export default DropAndUpload;
