import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GenerateAudienceQueryVariables = Types.Exact<{
  ast: Types.Scalars['jsonb'];
  limit?: Types.Maybe<Types.Scalars['Int']>;
}>;


export type GenerateAudienceQuery = { __typename?: 'query_root', audienceResults?: Array<{ __typename?: 'AudienceResult', patientWorkspace?: { __typename?: 'patientWorkspace', firstName?: string | null | undefined, lastName?: string | null | undefined, email?: string | null | undefined, attributes?: any | null | undefined, status?: string | null | undefined, leadStatus?: string | null | undefined, patient: { __typename?: 'patient', id: any, lastAppointment: Array<{ __typename?: 'appointment', id: any, startTime?: any | null | undefined, status?: string | null | undefined, appointmentServices: Array<{ __typename?: 'appointmentService', service: { __typename?: 'service', name: string } }> }> } } | null | undefined } | null | undefined> | null | undefined };


export const GenerateAudienceDocument = gql`
    query generateAudience($ast: jsonb!, $limit: Int) {
  audienceResults(ast: $ast, limit: $limit) {
    patientWorkspace {
      firstName
      lastName
      email
      attributes
      status
      leadStatus
      patient {
        id
        lastAppointment: appointments(
          where: {startTime: {_lt: "now()"}}
          order_by: {startTime: desc}
          limit: 1
        ) {
          id
          startTime
          status
          appointmentServices {
            service {
              name
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGenerateAudienceQuery__
 *
 * To run a query within a React component, call `useGenerateAudienceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGenerateAudienceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGenerateAudienceQuery({
 *   variables: {
 *      ast: // value for 'ast'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGenerateAudienceQuery(baseOptions: Apollo.QueryHookOptions<GenerateAudienceQuery, GenerateAudienceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GenerateAudienceQuery, GenerateAudienceQueryVariables>(GenerateAudienceDocument, options);
      }
export function useGenerateAudienceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GenerateAudienceQuery, GenerateAudienceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GenerateAudienceQuery, GenerateAudienceQueryVariables>(GenerateAudienceDocument, options);
        }
export type GenerateAudienceQueryHookResult = ReturnType<typeof useGenerateAudienceQuery>;
export type GenerateAudienceLazyQueryHookResult = ReturnType<typeof useGenerateAudienceLazyQuery>;
export type GenerateAudienceQueryResult = Apollo.QueryResult<GenerateAudienceQuery, GenerateAudienceQueryVariables>;