/* eslint-disable @typescript-eslint/ban-ts-comment */
import { DataTable, TruncatedCell } from '@webapp/ui';
import { toLocalePriceInCents } from '@webapp/util-formatting';
import { PatientWalletTransactionFieldsFragment } from 'libs/graphql/src/fragments/patientWalletTransactionFields.fragment.generated';
import { Cell, Column } from 'react-table';

interface PatientWalletLedgerTableProps {
  patientWalletTransactions: PatientWalletTransactionFieldsFragment[];
  count: number;
}
export const PatientWalletLedgerTable = ({
  patientWalletTransactions,
  count,
}: PatientWalletLedgerTableProps): JSX.Element => {
  const TABLE_COLUMNS: Column[] = [
    {
      Header: 'Change',
      accessor: 'amount',
      Cell: ({ value }: { value: number }) => (
        <TruncatedCell value={toLocalePriceInCents(value)} />
      ),
    },
    {
      Header: 'Before',
      accessor: 'totalBefore',
      Cell: ({ value }: { value: number }) => (
        <TruncatedCell value={toLocalePriceInCents(value)} />
      ),
    },
    {
      Header: 'After',
      accessor: 'totalAfter',
      Cell: ({ value }: { value: number }) => (
        <TruncatedCell value={toLocalePriceInCents(value)} />
      ),
    },
    {
      Header: 'Source',
      accessor: 'source.name',
      Cell: ({ value }: { value: string }) => <TruncatedCell value={value} />,
    },
    {
      Header: 'Type',
      accessor: 'source.type',
      Cell: ({ value }: { value: string }) => <TruncatedCell value={value} />,
    },
    {
      Header: 'Date',
      accessor: 'createdAt',
      Cell: ({ value }: { value: string }) => (
        <TruncatedCell value={new Date(value).toLocaleDateString()} />
      ),
    },
    {
      Header: 'Reason',
      accessor: 'note',
      // @ts-ignore
      Cell: (e: Cell<PatientWalletTransactionFieldsFragment>) => (
        <TruncatedCell
          link={
            e.row.original.invoiceId
              ? `/invoices/${e.row.original.invoiceId}`
              : undefined
          }
          value={e.row.original.note}
        />
      ),
    },
  ];

  return (
    <DataTable
      columns={TABLE_COLUMNS}
      data={patientWalletTransactions}
      count={count}
    />
  );
};

export default PatientWalletLedgerTable;
