import {
  Button,
  ButtonGroup,
  Grid,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
} from '@chakra-ui/react';
import { joiResolver } from '@hookform/resolvers/joi';
import { US_STATES } from '@webapp/constants';
import { Address, useInsertOnePatientAddressMutation } from '@webapp/graphql';
import { FormInput, HookForm } from '@webapp/ui';
import toast from 'react-hot-toast';
import PatientAddressValidation from './PatientAddressValidation';

export interface PatientAddressFormProps {
  isOpen: boolean;
  onClose: () => void;
  onComplete?: (address: Address) => void;
  patientId: string;
}

interface PatientAddressFormValues {
  addressLine1: string;
  addressLine2?: string;
  city: string;
  isBilling: boolean;
  isDefault: boolean;
  state: string;
  zipCode: string;
}

export function PatientAddressForm({
  isOpen,
  onClose,
  onComplete,
  patientId,
}: PatientAddressFormProps) {
  const [update, { loading }] = useInsertOnePatientAddressMutation({
    onCompleted: (data) => {
      toast.success('Address added successfully');

      if (onComplete) {
        onComplete(data?.insertPatientAddress?.patientAddress?.address);
      }

      onClose();
    },
    onError: (e) => {
      toast.error(e.message);
    },
    refetchQueries: ['GetOnePatient', 'ListPatientAddress'],
  });

  function onSubmit(values: PatientAddressFormValues) {
    update({
      variables: {
        addressLine1: values.addressLine1,
        addressLine2: values.addressLine2,
        city: values.city,
        isBilling: values.isBilling,
        isDefault: values.isDefault,
        patientId,
        state: values.state,
        zipCode: values.zipCode,
      },
    });
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalContent minWidth="500px" padding="20px 30px 0">
        <ModalHeader
          color="text.100"
          fontWeight="bold"
          margin="0 0 15px"
          padding="0"
        >
          Add Address
        </ModalHeader>
        <ModalBody padding="0">
          <HookForm
            defaultValues={{
              addressLine1: '',
              addressLine2: '',
              city: '',
              isBilling: true,
              isDefault: true,
              state: '',
              zipCode: '',
            }}
            onSubmit={onSubmit}
            resolver={joiResolver(PatientAddressValidation)}
          >
            {({ handleSubmit }) => (
              <Grid gridRowGap="15px">
                <FormInput name="addressLine1" type="address" />
                <FormInput
                  name="addressLine2"
                  placeholder="Apartment, Suite, Unit #..."
                />
                <Grid gridTemplateColumns="repeat(3, 1fr)" gridColumnGap="8px">
                  <FormInput name="city" placeholder="City" />
                  <FormInput
                    id="state"
                    name="state"
                    placeholder=""
                    withOptions={US_STATES.map((state) => ({
                      label: state.name,
                      value: state.abbreviation,
                    }))}
                    type="select"
                  />
                  <FormInput name="zipCode" placeholder="Zip Code" />
                  <HStack padding="24px 0 0" spacing="20px">
                    <FormInput name="isDefault" type="checkbox">
                      Default
                    </FormInput>
                    <FormInput name="isBilling" type="checkbox">
                      Billing
                    </FormInput>
                  </HStack>
                </Grid>
                <ButtonGroup justifyContent="end" padding="15px 0">
                  <Button disabled={loading} onClick={onClose}>
                    Cancel
                  </Button>
                  <Button
                    colorScheme="teal"
                    isLoading={loading}
                    onClick={() => handleSubmit(onSubmit)()}
                    type="button"
                  >
                    Add
                  </Button>
                </ButtonGroup>
              </Grid>
            )}
          </HookForm>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default PatientAddressForm;
