import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type DocumentTagsQueryVariables = Types.Exact<{
  where: Types.DocumentTag_Bool_Exp;
}>;


export type DocumentTagsQuery = { __typename?: 'query_root', documentTag: Array<{ __typename?: 'documentTag', id: any, tag: { __typename?: 'tag', id: any, title: string } }> };


export const DocumentTagsDocument = gql`
    query DocumentTags($where: documentTag_bool_exp!) {
  documentTag(where: $where) {
    id
    tag {
      id
      title
    }
  }
}
    `;

/**
 * __useDocumentTagsQuery__
 *
 * To run a query within a React component, call `useDocumentTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentTagsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDocumentTagsQuery(baseOptions: Apollo.QueryHookOptions<DocumentTagsQuery, DocumentTagsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DocumentTagsQuery, DocumentTagsQueryVariables>(DocumentTagsDocument, options);
      }
export function useDocumentTagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DocumentTagsQuery, DocumentTagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DocumentTagsQuery, DocumentTagsQueryVariables>(DocumentTagsDocument, options);
        }
export type DocumentTagsQueryHookResult = ReturnType<typeof useDocumentTagsQuery>;
export type DocumentTagsLazyQueryHookResult = ReturnType<typeof useDocumentTagsLazyQuery>;
export type DocumentTagsQueryResult = Apollo.QueryResult<DocumentTagsQuery, DocumentTagsQueryVariables>;