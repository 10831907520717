import { Node, mergeAttributes } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';
import HighlightReplace from './highlight-replace';

export const HightlightReplaceExtension = Node.create({
  name: 'highlightReplace',

  group: 'inline',

  inline: true,

  atom: true,

  content: 'inline*',

  addAttributes() {
    return {
      linkColor: {
        default: '#009688',
      },
      originalText: {
        default: 'default',
      },
    };
  },
  parseHTML() {
    return [
      {
        tag: 'highlight-replace',
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['highlight-replace', mergeAttributes(HTMLAttributes), 0];
  },

  addNodeView() {
    return ReactNodeViewRenderer(HighlightReplace);
  },
});

export default HightlightReplaceExtension;
