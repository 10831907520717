import React, { useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormLabel,
  HStack,
  IconButton,
  Select,
  Spacer,
  Stack,
  Text,
} from '@chakra-ui/react';
import './AvailabilityTemplate.module.scss';
import dayjs from 'dayjs';
import { FaCopy, FaPlus, FaTrash } from 'react-icons/fa';
import { Availability, Schedule } from '@webapp/types';
import { Datepicker } from '@mobiscroll/react';
import {
  useDeleteProviderScheduleMutation,
  useInsertProviderScheduleMutation,
  useUpdateProviderScheduleMutation,
} from '@webapp/graphql';
import toast, { ErrorIcon } from 'react-hot-toast';

/* eslint-disable-next-line */
export interface AvailabilityTemplateProps {
  locationId?: string;
  providerId?: string;
  scheduleId?: string;
  schedule: Schedule;
  onChange?: (schedule: Schedule) => void;
  effectiveFrom?: string | undefined | null;
  effectiveUntil?: string | undefined | null;
  create?: boolean;
  defaultSchedule?: boolean;
  refetch: () => void;
  toggle?: () => void;
}

function generateTimes() {
  const times = [];
  for (let i = 0; i < 24; i++) {
    times.push(`${i}:00`);
    times.push(`${i}:15`);
    times.push(`${i}:30`);
    times.push(`${i}:45`);
  }
  return times.map((time) => (
    <option value={dayjs(time, 'H:mm').format('HH:mm')}>
      {dayjs(time, 'HH:mm').format('hh:mm a')}
    </option>
  ));
}

export function AvailabilityTemplate(props: AvailabilityTemplateProps) {
  const {
    create,
    defaultSchedule,
    locationId,
    onChange,
    providerId,
    refetch,
    toggle,
    scheduleId,
  } = props;
  const [schedule, setSchedule] = useState<Schedule>({ ...props.schedule });
  const [effectiveFrom, setEffectiveFrom] = useState<String | undefined | null>(
    props.effectiveFrom
  );
  const [effectiveUntil, setEffectiveUntil] = useState<
    String | undefined | null
  >(props.effectiveUntil);

  const [updateProviderSchedule] = useUpdateProviderScheduleMutation({
    onError: (error) => {
      if (
        error.message.includes(
          'providerschedule_no_overlapping_effective_date_ranges'
        )
      ) {
        toast.error(
          'The schedule you are updating conflicts with another schedule.'
        );
      }
    },
    onCompleted: () => {
      toast.success('Schedule successfuly saved!');
      refetch();
    },
  });
  const [createProviderSchedule] = useInsertProviderScheduleMutation({
    onError: (error) => {
      if (
        error.message.includes(
          'providerschedule_no_overlapping_effective_date_ranges'
        )
      ) {
        toast.error(
          'The schedule you are creating conflicts with another schedule.'
        );
      }
      if (error.message.includes('default_effective_provider_constraint')) {
        toast.error('A default schedule already exists.');
      }
    },
    onCompleted: () => {
      toggle(false);
      refetch();
    },
  });
  const [deleteProviderSchedule] = useDeleteProviderScheduleMutation({
    onCompleted: () => {
      toast.success('Availability has been deleted!');
      refetch();
    },
  });

  const updateAvailability = async (scheduleTemplate: Schedule) => {
    if (onChange) {
      onChange(scheduleTemplate);
    } else {
      await updateProviderSchedule({
        variables: {
          id: props.scheduleId,
          _set: {
            schedule: scheduleTemplate,
          },
        },
      });
    }
  };

  const createAvailability = async () => {
    if ((!effectiveFrom || !effectiveUntil) && !defaultSchedule) {
      toast.error('Start date and end date are required.');
    } else {
      await createProviderSchedule({
        variables: {
          providerSchedule: {
            locationId,
            providerId,
            schedule,
            effectiveFrom,
            effectiveUntil,
            default: !!defaultSchedule,
          },
        },
      });
    }
  };

  const deleteAvailability = async () => {
    await deleteProviderSchedule({
      variables: {
        id: props.scheduleId,
      },
    });
  };

  const updateEffectiveFrom = async (newEffectiveFrom: string) => {
    setEffectiveFrom(newEffectiveFrom);
    await updateProviderSchedule({
      variables: {
        id: props.scheduleId,
        _set: {
          effectiveFrom: newEffectiveFrom,
        },
      },
    });
  };

  const updateEffectiveUntil = async (newEffectiveUntil: string) => {
    setEffectiveUntil(newEffectiveUntil);
    await updateProviderSchedule({
      variables: {
        id: props.scheduleId,
        _set: {
          effectiveUntil: newEffectiveUntil,
        },
      },
    });
  };

  const updateSchedule = (
    time: string,
    dayOfWeek: number,
    availabilityChunk: number,
    part: string
  ) => {
    const newSchedule = { ...schedule };
    newSchedule[dayOfWeek][availabilityChunk][part as keyof Availability] =
      time;

    setSchedule(newSchedule);
    if (!create && newSchedule) {
      updateAvailability(newSchedule);
    }
  };

  function removeAvailabilityFromDay(day: number, index: number) {
    const newSchedule = { ...schedule };
    newSchedule[day].splice(index, 1);

    setSchedule(newSchedule);
    if (!create && newSchedule) {
      updateAvailability(newSchedule);
    }
  }

  const toggleAvailability = (day: number) => {
    if (schedule[day].length === 0) {
      const newSchedule = { ...schedule };
      newSchedule[day] = [
        { start: '09:00', end: '12:00' },
        { start: '13:00', end: '17:00' },
      ];
      setSchedule(newSchedule);
      if (!create && newSchedule) {
        updateAvailability(newSchedule);
      }
    } else {
      const newSchedule = { ...schedule };
      newSchedule[day] = [];
      setSchedule(newSchedule);
      if (!create && newSchedule) {
        updateAvailability(newSchedule);
      }
    }
  };

  const addAvailabilityToDay = (day: number) => {
    const newSchedule = { ...schedule };

    const start =
      newSchedule[day].length > 0
        ? dayjs(newSchedule[day][newSchedule[day].length - 1].end, 'HH:mm').add(
            1,
            'hour'
          )
        : dayjs('09:00', 'HH:mm');

    const end = start.add(1, 'hour');
    newSchedule[day].push({
      start: start.format('HH:mm'),
      end: end.format('HH:mm'),
    });
    setSchedule(newSchedule);
    if (!create && newSchedule) {
      updateAvailability(newSchedule);
    }
  };

  return (
    <Stack pt={4}>
      <HStack justifyContent="space-between">
        {!defaultSchedule && (
          <HStack>
            <FormLabel>Start Date</FormLabel>
            <Box borderColor={'gray.200'} borderWidth={1} rounded="md">
              <Datepicker
                themeVariant="light"
                labelStyle="stacked"
                placeholder="Effective from"
                value={effectiveFrom}
                onChange={(event) => {
                  updateEffectiveFrom(event.valueText);
                }}
              />
            </Box>
            <FormLabel>End Date</FormLabel>
            <Box borderColor={'gray.200'} borderWidth={1} rounded="md">
              <Datepicker
                themeVariant="light"
                labelStyle="stacked"
                placeholder="Effective until"
                value={effectiveUntil}
                onChange={(event) => {
                  updateEffectiveUntil(event.valueText);
                }}
              />
            </Box>
          </HStack>
        )}
        <Spacer />
        <HStack>
          {!create && (
            <IconButton
              ml={4}
              onClick={() => deleteAvailability()}
              icon={<FaTrash />}
              aria-label={'Delete availability'}
            />
          )}
          {create && (
            <Button colorScheme="teal" onClick={createAvailability}>
              Save Availability
            </Button>
          )}
        </HStack>
      </HStack>
      {Object.values(schedule).map((availability, index) => (
        <React.Fragment key={index}>
          <HStack
            key={`avail-${availability.length}`}
            py={4}
            justifyContent="space-between"
          >
            <HStack>
              <Checkbox
                onChange={() => toggleAvailability(index)}
                defaultChecked={availability.length > 0}
                mr={10}
                width={24}
              >
                {dayjs().day(index).format('dddd')}
              </Checkbox>
              {availability && availability.length > 0 ? (
                <Stack>
                  {availability.map((a: Availability, k: number) => (
                    <HStack spacing={4} key={`a-${k}`}>
                      <Select
                        id={`availability-start-select-${index}-${k}`}
                        value={a.start}
                        onChange={(v) =>
                          updateSchedule(v.target.value, index, k, 'start')
                        }
                      >
                        {generateTimes()}
                      </Select>
                      <Box>{' - '}</Box>
                      <Select
                        id={`availability-end-select-${index}-${k}`}
                        value={a.end}
                        onChange={(v) =>
                          updateSchedule(v.target.value, index, k, 'end')
                        }
                      >
                        {generateTimes()}
                      </Select>
                      <IconButton
                        ml={4}
                        id={`delete-availability-${index}-${k}`}
                        onClick={() => removeAvailabilityFromDay(index, k)}
                        icon={<FaTrash />}
                        aria-label={'Delete availability'}
                      />
                    </HStack>
                  ))}
                </Stack>
              ) : (
                <Text color="gray.400">Unavailable</Text>
              )}
            </HStack>
            <HStack>
              <IconButton
                onClick={() => addAvailabilityToDay(index)}
                icon={<FaPlus />}
                aria-label={'Add availability'}
              />
              <IconButton
                onClick={() => alert('coming soon!')}
                icon={<FaCopy />}
                aria-label={'Copy availability'}
              />
            </HStack>
          </HStack>
          <Divider />
        </React.Fragment>
      ))}
    </Stack>
  );
}

export default AvailabilityTemplate;
