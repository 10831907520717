import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type CreateDraftInvoiceForAppointmentMutationVariables = Types.Exact<{
  appointmentId: Types.Scalars['uuid'];
  address: Types.Scalars['String'];
  paymentId: Types.Scalars['uuid'];
}>;


export type CreateDraftInvoiceForAppointmentMutation = { __typename?: 'mutation_root', createDraftInvoiceForAppointment?: { __typename?: 'IdResponse', id: any } | null | undefined };


export const CreateDraftInvoiceForAppointmentDocument = gql`
    mutation CreateDraftInvoiceForAppointment($appointmentId: uuid!, $address: String!, $paymentId: uuid!) {
  createDraftInvoiceForAppointment(
    appointmentId: $appointmentId
    address: $address
    paymentId: $paymentId
  ) {
    id
  }
}
    `;
export type CreateDraftInvoiceForAppointmentMutationFn = Apollo.MutationFunction<CreateDraftInvoiceForAppointmentMutation, CreateDraftInvoiceForAppointmentMutationVariables>;

/**
 * __useCreateDraftInvoiceForAppointmentMutation__
 *
 * To run a mutation, you first call `useCreateDraftInvoiceForAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDraftInvoiceForAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDraftInvoiceForAppointmentMutation, { data, loading, error }] = useCreateDraftInvoiceForAppointmentMutation({
 *   variables: {
 *      appointmentId: // value for 'appointmentId'
 *      address: // value for 'address'
 *      paymentId: // value for 'paymentId'
 *   },
 * });
 */
export function useCreateDraftInvoiceForAppointmentMutation(baseOptions?: Apollo.MutationHookOptions<CreateDraftInvoiceForAppointmentMutation, CreateDraftInvoiceForAppointmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDraftInvoiceForAppointmentMutation, CreateDraftInvoiceForAppointmentMutationVariables>(CreateDraftInvoiceForAppointmentDocument, options);
      }
export type CreateDraftInvoiceForAppointmentMutationHookResult = ReturnType<typeof useCreateDraftInvoiceForAppointmentMutation>;
export type CreateDraftInvoiceForAppointmentMutationResult = Apollo.MutationResult<CreateDraftInvoiceForAppointmentMutation>;
export type CreateDraftInvoiceForAppointmentMutationOptions = Apollo.BaseMutationOptions<CreateDraftInvoiceForAppointmentMutation, CreateDraftInvoiceForAppointmentMutationVariables>;