import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type PayrixTerminalTransactionSubscriptionVariables = Types.Exact<{
  payrixTerminalTransactionId: Types.Scalars['uuid'];
}>;


export type PayrixTerminalTransactionSubscription = { __typename?: 'subscription_root', payrixTerminalTransaction_by_pk?: { __typename?: 'payrixTerminalTransaction', id: any, status?: string | null | undefined } | null | undefined };


export const PayrixTerminalTransactionDocument = gql`
    subscription PayrixTerminalTransaction($payrixTerminalTransactionId: uuid!) {
  payrixTerminalTransaction_by_pk(id: $payrixTerminalTransactionId) {
    id
    status
  }
}
    `;

/**
 * __usePayrixTerminalTransactionSubscription__
 *
 * To run a query within a React component, call `usePayrixTerminalTransactionSubscription` and pass it any options that fit your needs.
 * When your component renders, `usePayrixTerminalTransactionSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePayrixTerminalTransactionSubscription({
 *   variables: {
 *      payrixTerminalTransactionId: // value for 'payrixTerminalTransactionId'
 *   },
 * });
 */
export function usePayrixTerminalTransactionSubscription(baseOptions: Apollo.SubscriptionHookOptions<PayrixTerminalTransactionSubscription, PayrixTerminalTransactionSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<PayrixTerminalTransactionSubscription, PayrixTerminalTransactionSubscriptionVariables>(PayrixTerminalTransactionDocument, options);
      }
export type PayrixTerminalTransactionSubscriptionHookResult = ReturnType<typeof usePayrixTerminalTransactionSubscription>;
export type PayrixTerminalTransactionSubscriptionResult = Apollo.SubscriptionResult<PayrixTerminalTransactionSubscription>;