export function getSortParametersMap(parameters: URLSearchParams): {
  [key: string]: string;
} {
  const orderParams = parameters.get('order')?.split(',') ?? [];
  const keyOrderArray = orderParams.map((param) => param.split(':'));

  const columnMap: { [key: string]: string } = {};

  keyOrderArray.forEach((keyOrder) => {
    columnMap[keyOrder[0]] = keyOrder[1] ?? 'asc';
  });

  return columnMap;
}

export function sortParametersMapToArray(sortParametersMap: {
  [key: string]: string;
}) {
  return Object.keys(sortParametersMap).map(
    (key) => `${key}:${sortParametersMap[key]}`
  );
}

export function getSortParameters(searchParams: URLSearchParams) {
  const sortParams = searchParams.get('order');
  const splitSortParams = sortParams?.split(',') ?? [];

  const pairedSortParams = splitSortParams.map((sortParam) =>
    sortParam.split(':')
  );

  return pairedSortParams.map((pairedSortParam) => ({
    [pairedSortParam[0]]: pairedSortParam[1],
  }));
}

export const orderStringToOrderByArray = (orderParams: string | null) => {
  return (
    orderParams?.split(',').map((string) => {
      const splitAgain = string.split(':');
      return { [splitAgain[0]]: splitAgain[1] };
    }) ?? []
  );
};

export default getSortParameters;
