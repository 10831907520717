import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { SimpleAppointmentFieldsFragmentDoc } from '../../fragments/appointment/simpleAppointmentFields.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type PatientAppointmentsQueryVariables = Types.Exact<{
  offset?: Types.Maybe<Types.Scalars['Int']>;
  orderBy?: Types.Maybe<Array<Types.Appointment_Order_By> | Types.Appointment_Order_By>;
  limit?: Types.Maybe<Types.Scalars['Int']>;
  where?: Types.Maybe<Types.Appointment_Bool_Exp>;
}>;


export type PatientAppointmentsQuery = { __typename?: 'query_root', appointment: Array<{ __typename?: 'appointment', id: any, isDraft: boolean, startTime?: any | null | undefined, note?: string | null | undefined, status?: string | null | undefined, timerange?: any | null | undefined, type: string, metadata: { __typename?: 'AppointmentMetadata', requiresAttention: boolean }, services: Array<{ __typename?: 'appointmentService', id: any, providerId?: any | null | undefined, service: { __typename?: 'service', name: string }, provider?: { __typename?: 'provider', id: any, firstName: string, lastName: string, title?: string | null | undefined } | null | undefined }>, location: { __typename?: 'location', id: any, name: string, timezone?: string | null | undefined }, patient?: { __typename?: 'patient', id: any, attributes: { __typename?: 'PatientAttributes', firstName?: string | null | undefined, id?: string | null | undefined, lastName?: string | null | undefined } } | null | undefined, provider?: { __typename?: 'provider', firstName: string, id: any, lastName: string, title?: string | null | undefined } | null | undefined, room?: { __typename?: 'room', id: any, name: string } | null | undefined }> };


export const PatientAppointmentsDocument = gql`
    query PatientAppointments($offset: Int = 0, $orderBy: [appointment_order_by!], $limit: Int = 10, $where: appointment_bool_exp) {
  appointment(offset: $offset, order_by: $orderBy, limit: $limit, where: $where) {
    ...SimpleAppointmentFields
    metadata {
      requiresAttention
    }
    services: appointmentServices {
      id
      service {
        name
      }
      providerId
      provider {
        id
        firstName
        lastName
        title
      }
    }
  }
}
    ${SimpleAppointmentFieldsFragmentDoc}`;

/**
 * __usePatientAppointmentsQuery__
 *
 * To run a query within a React component, call `usePatientAppointmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePatientAppointmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePatientAppointmentsQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      orderBy: // value for 'orderBy'
 *      limit: // value for 'limit'
 *      where: // value for 'where'
 *   },
 * });
 */
export function usePatientAppointmentsQuery(baseOptions?: Apollo.QueryHookOptions<PatientAppointmentsQuery, PatientAppointmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PatientAppointmentsQuery, PatientAppointmentsQueryVariables>(PatientAppointmentsDocument, options);
      }
export function usePatientAppointmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PatientAppointmentsQuery, PatientAppointmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PatientAppointmentsQuery, PatientAppointmentsQueryVariables>(PatientAppointmentsDocument, options);
        }
export type PatientAppointmentsQueryHookResult = ReturnType<typeof usePatientAppointmentsQuery>;
export type PatientAppointmentsLazyQueryHookResult = ReturnType<typeof usePatientAppointmentsLazyQuery>;
export type PatientAppointmentsQueryResult = Apollo.QueryResult<PatientAppointmentsQuery, PatientAppointmentsQueryVariables>;