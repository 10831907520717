import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetPatientWalletCouponsQueryVariables = Types.Exact<{
  where?: Types.Maybe<Types.PatientWalletCoupon_Bool_Exp>;
}>;


export type GetPatientWalletCouponsQuery = { __typename?: 'query_root', patientWalletCoupon: Array<{ __typename?: 'patientWalletCoupon', coupon: { __typename?: 'coupon', id: any, name: string, rule: string } }> };


export const GetPatientWalletCouponsDocument = gql`
    query GetPatientWalletCoupons($where: patientWalletCoupon_bool_exp) {
  patientWalletCoupon(where: $where) {
    coupon {
      id
      name
      rule
    }
  }
}
    `;

/**
 * __useGetPatientWalletCouponsQuery__
 *
 * To run a query within a React component, call `useGetPatientWalletCouponsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPatientWalletCouponsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPatientWalletCouponsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetPatientWalletCouponsQuery(baseOptions?: Apollo.QueryHookOptions<GetPatientWalletCouponsQuery, GetPatientWalletCouponsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPatientWalletCouponsQuery, GetPatientWalletCouponsQueryVariables>(GetPatientWalletCouponsDocument, options);
      }
export function useGetPatientWalletCouponsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPatientWalletCouponsQuery, GetPatientWalletCouponsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPatientWalletCouponsQuery, GetPatientWalletCouponsQueryVariables>(GetPatientWalletCouponsDocument, options);
        }
export type GetPatientWalletCouponsQueryHookResult = ReturnType<typeof useGetPatientWalletCouponsQuery>;
export type GetPatientWalletCouponsLazyQueryHookResult = ReturnType<typeof useGetPatientWalletCouponsLazyQuery>;
export type GetPatientWalletCouponsQueryResult = Apollo.QueryResult<GetPatientWalletCouponsQuery, GetPatientWalletCouponsQueryVariables>;