import * as Joi from 'joi';
import DefaultNullValidation from './DefaultNullValidation';

export const RequireIfAddressLine1 = Joi.when('addressLine1', {
  is: Joi.string(),
  then: Joi.string().required().messages({
    'string.empty': 'Required Field',
    'any.required': 'Required Field',
  }),
  otherwise: DefaultNullValidation,
});

export default RequireIfAddressLine1;
