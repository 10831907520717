import { useApolloClient } from '@apollo/client';
import {
  GetCouponsDocument,
  GetCouponsQuery,
  GetCouponsQueryVariables,
} from '@webapp/graphql';
import { useStores } from '@webapp/state-models';
import { ObjectOption } from '@webapp/types';
import { CouponFieldsFragment } from 'libs/graphql/src/fragments/couponFields.fragment.generated';
import { useCallback } from 'react';

interface UseLoadCouponsOutput {
  search(searchValue: string): Promise<ObjectOption<CouponFieldsFragment>[]>;
}

interface UseLoadCouponsParams {
  couponIds?: string[];
  showOnlyActive?: boolean;
  hideSystemGenerated?: boolean;
}
export const useLoadCoupons = ({
  couponIds = [],
  showOnlyActive = true,
  hideSystemGenerated = true,
}: UseLoadCouponsParams): UseLoadCouponsOutput => {
  const client = useApolloClient();

  const { workspace } = useStores();

  return {
    search: useCallback(
      async (searchValue: string) => {
        const { data } = await client.query<
          GetCouponsQuery,
          GetCouponsQueryVariables
        >({
          query: GetCouponsDocument,
          variables: {
            
            where: {
              workspaceId: {
                _eq: workspace?.id,
              },
              ...(searchValue && {
                name: {
                  _like: `%${searchValue}%`,
                },
              }),
              ...(couponIds.length && {
                id: {
                  _in: couponIds,
                },
              }),
              ...(showOnlyActive && {
                isActive: {
                  _eq: true,
                },
              }),
              ...(hideSystemGenerated && {
                isSystemGenerated: {
                  _eq: false,
                },
              }),
            },
          },
        });

        const coupons = data?.coupon ?? [];

        return coupons.map((coupon) => ({
          label: coupon.name,
          value: coupon.id,
          object: coupon,
        }));
      },
      [client, couponIds, hideSystemGenerated, showOnlyActive, workspace?.id]
    ),
  };
};

export default useLoadCoupons;
