import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { PatientTableFieldsFragmentDoc } from '../../fragments/patientTableFields.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type SearchPatientWorkspaceQueryVariables = Types.Exact<{
  search: Types.Scalars['String'];
  workspaceId: Types.Scalars['uuid'];
  where?: Types.Maybe<Types.Patient_Bool_Exp>;
}>;


export type SearchPatientWorkspaceQuery = { __typename?: 'query_root', search_patientWorkspace: Array<{ __typename?: 'patient', email?: string | null | undefined, id: any, firstName: string, lastName: string, status: string, attributes: { __typename?: 'PatientAttributes', email?: string | null | undefined, firstName?: string | null | undefined, middleName?: string | null | undefined, clinicId?: number | null | undefined, dosespotPatientId?: number | null | undefined, lastName?: string | null | undefined, status?: string | null | undefined, dob?: string | null | undefined, phoneNumber?: string | null | undefined, profilePicture?: { __typename?: 'FilePayload', filePath: string, url: string, thumbnailUrl?: string | null | undefined } | null | undefined }, patientWorkspaces: Array<{ __typename?: 'patientWorkspace', id: any, status?: string | null | undefined, patientReferenceNumber: number, profileNote?: string | null | undefined, workspaceId: any, primaryProvider?: { __typename?: 'provider', id: any, firstName: string, lastName: string } | null | undefined }>, lastAppointment: Array<{ __typename?: 'appointment', id: any, timerange?: any | null | undefined, appointmentServices: Array<{ __typename?: 'appointmentService', service: { __typename?: 'service', name: string } }> }>, patientMemberships: Array<{ __typename?: 'patientMembership', id: any, status: string, createdAt: any, nextPaymentDate?: any | null | undefined, membership: { __typename?: 'membership', name: string, type: Types.MembershipType_Enum, color: string } }>, patientWallets: Array<{ __typename?: 'patientWallet', id: any, patientId: any, balance: number, patientWalletCoupons: Array<{ __typename?: 'patientWalletCoupon', coupon: { __typename?: 'coupon', id: any, name: string, rule: string } }> }> }> };


export const SearchPatientWorkspaceDocument = gql`
    query SearchPatientWorkspace($search: String!, $workspaceId: uuid!, $where: patient_bool_exp) {
  search_patientWorkspace(
    args: {search: $search, workspace_id: $workspaceId}
    where: $where
  ) {
    ...PatientTableFields
  }
}
    ${PatientTableFieldsFragmentDoc}`;

/**
 * __useSearchPatientWorkspaceQuery__
 *
 * To run a query within a React component, call `useSearchPatientWorkspaceQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchPatientWorkspaceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchPatientWorkspaceQuery({
 *   variables: {
 *      search: // value for 'search'
 *      workspaceId: // value for 'workspaceId'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useSearchPatientWorkspaceQuery(baseOptions: Apollo.QueryHookOptions<SearchPatientWorkspaceQuery, SearchPatientWorkspaceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchPatientWorkspaceQuery, SearchPatientWorkspaceQueryVariables>(SearchPatientWorkspaceDocument, options);
      }
export function useSearchPatientWorkspaceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchPatientWorkspaceQuery, SearchPatientWorkspaceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchPatientWorkspaceQuery, SearchPatientWorkspaceQueryVariables>(SearchPatientWorkspaceDocument, options);
        }
export type SearchPatientWorkspaceQueryHookResult = ReturnType<typeof useSearchPatientWorkspaceQuery>;
export type SearchPatientWorkspaceLazyQueryHookResult = ReturnType<typeof useSearchPatientWorkspaceLazyQuery>;
export type SearchPatientWorkspaceQueryResult = Apollo.QueryResult<SearchPatientWorkspaceQuery, SearchPatientWorkspaceQueryVariables>;