import {
  Box,
  BoxProps,
  Icon,
  Text,
  TextProps,
  Tooltip,
  Circle,
  SquareProps,
} from '@chakra-ui/react';
import { IconType } from 'react-icons';
import { FaRegStar } from 'react-icons/fa';
import { RiExchangeDollarFill } from 'react-icons/ri';
import { Appointment } from '@webapp/state-models';

 const PatientIcon = ({
  icon,
  label = '',
  isDisabled = false,
  text = '',
  textStyles,
  iconContainerStyles,
  iconSize = '4',
  iconColor: color = 'black',
}: {
  icon?: IconType;
  label: string;
  isDisabled?: boolean;
  text?: string;
  textStyles?: TextProps;
  iconContainerStyles?: SquareProps;
  iconSize?: string;
  iconColor?: string;
}) => {
  return (
    <Tooltip isDisabled={isDisabled} label={label}>
      <Circle size={3} position={'relative'} {...iconContainerStyles}>
        <Box
          position={'absolute'}
          inset={'0'}
          opacity={0.15}
          backgroundColor={color}
          borderRadius={'full'}
        />
        {icon && <Icon color={color} as={icon} boxSize={iconSize} />}
        {text && (
          <Text
            display={'inline'}
            fontWeight={'400'}
            fontSize={'clamp(0.5rem, 12px, 14px)'}
            color={color}
            verticalAlign={'text-bottom'}
            {...textStyles}
          >
            {text}
          </Text>
        )}
      </Circle>
    </Tooltip>
  );
};
export const PatientIcons = ({
  isTooltipDisabled: isDisabled = false,
  appointment,
  containerStyles = {},
  textStyles = {},
  iconContainerStyles = {},
  iconSize,
}: {
  isTooltipDisabled?: boolean;
  appointment?: Appointment;
  //   TODO: Add implement container queries
  containerStyles?: BoxProps;
  textStyles?: TextProps;
  iconContainerStyles?: SquareProps;
  iconSize?: string;
}) => {
  const {
    isFirstAppointmentWithProvider = false,
    isNewPatient = false,
    isMembershipTypeSubscription = false,
    isMembershipActive = false,
  } = appointment || {};

  return (
    <Box
      paddingBlock={2}
      paddingInline={0}
      display={'grid'}
      gridTemplateColumns={`repeat(auto-fill, minmax(15px, 1fr))`}
      gridGap={'8px'}
      placeItems={'center'}
      {...containerStyles}
    >
      {isMembershipActive && isMembershipTypeSubscription && (
        <PatientIcon
          label="This patient has a recurring subscription."
          icon={RiExchangeDollarFill}
          isDisabled={isDisabled}
          textStyles={textStyles}
          iconContainerStyles={iconContainerStyles}
          iconSize={iconSize}
          iconColor={'gold.700'}
        />
      )}
      {isMembershipActive && !isMembershipTypeSubscription && (
        <PatientIcon
          label="This patient is a BSA member."
          icon={FaRegStar}
          isDisabled={isDisabled}
          textStyles={textStyles}
          iconContainerStyles={iconContainerStyles}
          iconSize={iconSize}
          iconColor={'teal.700'}
        />
      )}
      {isNewPatient && (
        <PatientIcon
          label="This is a new patient."
          text={'N'}
          isDisabled={isDisabled}
          textStyles={textStyles}
          iconContainerStyles={iconContainerStyles}
          iconSize={iconSize}
          iconColor={'purple.700'}
        />
      )}
      {isFirstAppointmentWithProvider && (
        <PatientIcon
          label="This is the provider's first appointment with this patient."
          text={'NP'}
          isDisabled={isDisabled}
          textStyles={textStyles}
          iconContainerStyles={iconContainerStyles}
          iconSize={iconSize}
          iconColor={'pink.700'}
        />
      )}
    </Box>
  );
};

export default PatientIcons;
