import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { InventoryAggregateFieldsFragmentDoc } from '../../fragments/inventoryAggregateFields.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetInventoryAggregateQueryVariables = Types.Exact<{
  input: Types.GetInventoryAggregateInput;
}>;


export type GetInventoryAggregateQuery = { __typename?: 'query_root', getInventoryAggregate: { __typename?: 'GetInventoryAggregateOutput', count: number, expiringCount: number, typeCount: number, brandCount: number, totalValue: number, stockAlertCount: number } };


export const GetInventoryAggregateDocument = gql`
    query GetInventoryAggregate($input: GetInventoryAggregateInput!) {
  getInventoryAggregate(input: $input) {
    ...InventoryAggregateFields
  }
}
    ${InventoryAggregateFieldsFragmentDoc}`;

/**
 * __useGetInventoryAggregateQuery__
 *
 * To run a query within a React component, call `useGetInventoryAggregateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInventoryAggregateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInventoryAggregateQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetInventoryAggregateQuery(baseOptions: Apollo.QueryHookOptions<GetInventoryAggregateQuery, GetInventoryAggregateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInventoryAggregateQuery, GetInventoryAggregateQueryVariables>(GetInventoryAggregateDocument, options);
      }
export function useGetInventoryAggregateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInventoryAggregateQuery, GetInventoryAggregateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInventoryAggregateQuery, GetInventoryAggregateQueryVariables>(GetInventoryAggregateDocument, options);
        }
export type GetInventoryAggregateQueryHookResult = ReturnType<typeof useGetInventoryAggregateQuery>;
export type GetInventoryAggregateLazyQueryHookResult = ReturnType<typeof useGetInventoryAggregateLazyQuery>;
export type GetInventoryAggregateQueryResult = Apollo.QueryResult<GetInventoryAggregateQuery, GetInventoryAggregateQueryVariables>;