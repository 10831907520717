import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { CouponFieldsFragmentDoc } from '../../fragments/couponFields.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetCouponQueryVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
}>;


export type GetCouponQuery = { __typename?: 'query_root', coupon_by_pk?: { __typename?: 'coupon', id: any, name: string, rule: string, amount: any, type: Types.CouponType_Enum, scope: Types.CouponScope_Enum, validFrom: any, validUntil: any, isActive: boolean, packageId?: any | null | undefined, isSystemGenerated: boolean, locationCoupons: Array<{ __typename?: 'locationCoupon', location: { __typename?: 'location', id: any, isActive: boolean, name: string, address?: string | null | undefined, phoneNumber?: string | null | undefined, email?: string | null | undefined, maxPatientsPerTimeslot?: number | null | undefined, workspaceId: any, clinicId?: number | null | undefined, addressId?: any | null | undefined, workStartTime?: string | null | undefined, workEndTime?: string | null | undefined, timezone?: string | null | undefined, payrixMerchantId?: string | null | undefined, finixMerchantId?: string | null | undefined, locationSchedules: Array<{ __typename?: 'locationSchedule', id: any, schedule: any, effectiveFrom?: any | null | undefined, createdAt: any, updatedAt: any, locationId: any }>, fullAddress?: { __typename?: 'address', addressLine1: string, addressLine2?: string | null | undefined, city: string, id: any, state: string, zipCode: string } | null | undefined, rooms: Array<{ __typename?: 'room', id: any, name: string }> } }>, serviceCoupons: Array<{ __typename?: 'serviceCoupon', service: { __typename?: 'service', id: any, name: string } }> } | null | undefined };


export const GetCouponDocument = gql`
    query GetCoupon($id: uuid!) {
  coupon_by_pk(id: $id) {
    ...CouponFields
  }
}
    ${CouponFieldsFragmentDoc}`;

/**
 * __useGetCouponQuery__
 *
 * To run a query within a React component, call `useGetCouponQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCouponQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCouponQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCouponQuery(baseOptions: Apollo.QueryHookOptions<GetCouponQuery, GetCouponQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCouponQuery, GetCouponQueryVariables>(GetCouponDocument, options);
      }
export function useGetCouponLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCouponQuery, GetCouponQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCouponQuery, GetCouponQueryVariables>(GetCouponDocument, options);
        }
export type GetCouponQueryHookResult = ReturnType<typeof useGetCouponQuery>;
export type GetCouponLazyQueryHookResult = ReturnType<typeof useGetCouponLazyQuery>;
export type GetCouponQueryResult = Apollo.QueryResult<GetCouponQuery, GetCouponQueryVariables>;