import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { CustomFormFieldsFragmentDoc } from '../../fragments/customFormFields.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetCustomFormByPkQueryVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
}>;


export type GetCustomFormByPkQuery = { __typename?: 'query_root', customForm_by_pk?: { __typename?: 'customForm', id: any, title: string, isRequired: boolean, allFieldsRequired: boolean, createdAt: any, recurrence?: string | null | undefined, language?: string | null | undefined, surveyJSJSON?: any | null | undefined, workspaceId: any, rows: Array<{ __typename?: 'customFormRow', id: any, index: number, replicable?: boolean | null | undefined, fields: Array<{ __typename?: 'customFormField', id: any, index: number, label: string, body?: string | null | undefined, category?: Types.CustomFormFieldCategory_Enum | null | undefined, type: Types.CustomFormFieldType_Enum, table?: string | null | undefined, attribute?: string | null | undefined, validation?: any | null | undefined, photoId?: any | null | undefined, row: { __typename?: 'customFormRow', id: any, index: number }, options: Array<{ __typename?: 'customFormOption', id: any, value: string }>, photo?: { __typename?: 'photo', file?: { __typename?: 'FilePayload', url: string } | null | undefined } | null | undefined }> }>, serviceCustomForms: Array<{ __typename?: 'serviceCustomForm', id: any, service: { __typename?: 'service', id: any, name: string } }> } | null | undefined };


export const GetCustomFormByPkDocument = gql`
    query GetCustomFormByPk($id: uuid!) {
  customForm_by_pk(id: $id) {
    ...CustomFormFields
  }
}
    ${CustomFormFieldsFragmentDoc}`;

/**
 * __useGetCustomFormByPkQuery__
 *
 * To run a query within a React component, call `useGetCustomFormByPkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomFormByPkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomFormByPkQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCustomFormByPkQuery(baseOptions: Apollo.QueryHookOptions<GetCustomFormByPkQuery, GetCustomFormByPkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCustomFormByPkQuery, GetCustomFormByPkQueryVariables>(GetCustomFormByPkDocument, options);
      }
export function useGetCustomFormByPkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCustomFormByPkQuery, GetCustomFormByPkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCustomFormByPkQuery, GetCustomFormByPkQueryVariables>(GetCustomFormByPkDocument, options);
        }
export type GetCustomFormByPkQueryHookResult = ReturnType<typeof useGetCustomFormByPkQuery>;
export type GetCustomFormByPkLazyQueryHookResult = ReturnType<typeof useGetCustomFormByPkLazyQuery>;
export type GetCustomFormByPkQueryResult = Apollo.QueryResult<GetCustomFormByPkQuery, GetCustomFormByPkQueryVariables>;