import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { ServiceCategoryFieldsFragmentDoc } from '../../fragments/serviceCategoryFields.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type InsertServiceCategoryMutationVariables = Types.Exact<{
  serviceCategory: Types.ServiceCategory_Insert_Input;
}>;


export type InsertServiceCategoryMutation = { __typename?: 'mutation_root', insert_serviceCategory?: { __typename?: 'serviceCategory_mutation_response', returning: Array<{ __typename?: 'serviceCategory', id: any, title: string }> } | null | undefined };


export const InsertServiceCategoryDocument = gql`
    mutation insertServiceCategory($serviceCategory: serviceCategory_insert_input!) {
  insert_serviceCategory(objects: [$serviceCategory]) {
    returning {
      ...ServiceCategoryFields
    }
  }
}
    ${ServiceCategoryFieldsFragmentDoc}`;
export type InsertServiceCategoryMutationFn = Apollo.MutationFunction<InsertServiceCategoryMutation, InsertServiceCategoryMutationVariables>;

/**
 * __useInsertServiceCategoryMutation__
 *
 * To run a mutation, you first call `useInsertServiceCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertServiceCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertServiceCategoryMutation, { data, loading, error }] = useInsertServiceCategoryMutation({
 *   variables: {
 *      serviceCategory: // value for 'serviceCategory'
 *   },
 * });
 */
export function useInsertServiceCategoryMutation(baseOptions?: Apollo.MutationHookOptions<InsertServiceCategoryMutation, InsertServiceCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertServiceCategoryMutation, InsertServiceCategoryMutationVariables>(InsertServiceCategoryDocument, options);
      }
export type InsertServiceCategoryMutationHookResult = ReturnType<typeof useInsertServiceCategoryMutation>;
export type InsertServiceCategoryMutationResult = Apollo.MutationResult<InsertServiceCategoryMutation>;
export type InsertServiceCategoryMutationOptions = Apollo.BaseMutationOptions<InsertServiceCategoryMutation, InsertServiceCategoryMutationVariables>;