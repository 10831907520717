import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type UpdateServiceConsumableTypesMutationVariables = Types.Exact<{
  where: Types.ServiceConsumableType_Bool_Exp;
  objects: Array<Types.ServiceConsumableType_Insert_Input> | Types.ServiceConsumableType_Insert_Input;
}>;


export type UpdateServiceConsumableTypesMutation = { __typename?: 'mutation_root', delete_serviceConsumableType?: { __typename?: 'serviceConsumableType_mutation_response', affected_rows: number } | null | undefined, insert_serviceConsumableType?: { __typename?: 'serviceConsumableType_mutation_response', affected_rows: number } | null | undefined };


export const UpdateServiceConsumableTypesDocument = gql`
    mutation updateServiceConsumableTypes($where: serviceConsumableType_bool_exp!, $objects: [serviceConsumableType_insert_input!]!) {
  delete_serviceConsumableType(where: $where) {
    affected_rows
  }
  insert_serviceConsumableType(objects: $objects) {
    affected_rows
  }
}
    `;
export type UpdateServiceConsumableTypesMutationFn = Apollo.MutationFunction<UpdateServiceConsumableTypesMutation, UpdateServiceConsumableTypesMutationVariables>;

/**
 * __useUpdateServiceConsumableTypesMutation__
 *
 * To run a mutation, you first call `useUpdateServiceConsumableTypesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateServiceConsumableTypesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateServiceConsumableTypesMutation, { data, loading, error }] = useUpdateServiceConsumableTypesMutation({
 *   variables: {
 *      where: // value for 'where'
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useUpdateServiceConsumableTypesMutation(baseOptions?: Apollo.MutationHookOptions<UpdateServiceConsumableTypesMutation, UpdateServiceConsumableTypesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateServiceConsumableTypesMutation, UpdateServiceConsumableTypesMutationVariables>(UpdateServiceConsumableTypesDocument, options);
      }
export type UpdateServiceConsumableTypesMutationHookResult = ReturnType<typeof useUpdateServiceConsumableTypesMutation>;
export type UpdateServiceConsumableTypesMutationResult = Apollo.MutationResult<UpdateServiceConsumableTypesMutation>;
export type UpdateServiceConsumableTypesMutationOptions = Apollo.BaseMutationOptions<UpdateServiceConsumableTypesMutation, UpdateServiceConsumableTypesMutationVariables>;