import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { MarketingTagFieldsFragmentDoc } from '../../fragments/marketingTagFields.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type SearchMarketingTagsQueryVariables = Types.Exact<{
  _ilike: Types.Scalars['String'];
}>;


export type SearchMarketingTagsQuery = { __typename?: 'query_root', marketingTag: Array<{ __typename?: 'marketingTag', id: any, title: string }> };


export const SearchMarketingTagsDocument = gql`
    query searchMarketingTags($_ilike: String!) {
  marketingTag(where: {title: {_ilike: $_ilike}}) {
    ...MarketingTagFields
  }
}
    ${MarketingTagFieldsFragmentDoc}`;

/**
 * __useSearchMarketingTagsQuery__
 *
 * To run a query within a React component, call `useSearchMarketingTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchMarketingTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchMarketingTagsQuery({
 *   variables: {
 *      _ilike: // value for '_ilike'
 *   },
 * });
 */
export function useSearchMarketingTagsQuery(baseOptions: Apollo.QueryHookOptions<SearchMarketingTagsQuery, SearchMarketingTagsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchMarketingTagsQuery, SearchMarketingTagsQueryVariables>(SearchMarketingTagsDocument, options);
      }
export function useSearchMarketingTagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchMarketingTagsQuery, SearchMarketingTagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchMarketingTagsQuery, SearchMarketingTagsQueryVariables>(SearchMarketingTagsDocument, options);
        }
export type SearchMarketingTagsQueryHookResult = ReturnType<typeof useSearchMarketingTagsQuery>;
export type SearchMarketingTagsLazyQueryHookResult = ReturnType<typeof useSearchMarketingTagsLazyQuery>;
export type SearchMarketingTagsQueryResult = Apollo.QueryResult<SearchMarketingTagsQuery, SearchMarketingTagsQueryVariables>;