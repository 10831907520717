import { useCallback } from 'react';
import { LeadFormField } from '@webapp/state-models';
import {
  Avatar,
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Stack,
  FormErrorMessage,
  useColorModeValue as mode,
} from '@chakra-ui/react';
import ServiceCategorySelection from './fields/ServiceCategorySelection';

export interface FieldFactoryProps {
  field: LeadFormField;
  register: any;
  errors: any;
}

function inflateValidationProps(validationProps: any) {
  if (!validationProps) return {};
  const inflatedProps = JSON.parse(JSON.stringify(validationProps.toJSON()));

  // eslint-disable-next-line no-prototype-builtins
  if (inflatedProps.hasOwnProperty('pattern')) {
    inflatedProps.pattern.value = new RegExp(inflatedProps.pattern.value, 'i');
  }

  return inflatedProps;
}

export default function FieldFactory({
  field,
  register,
  errors,
}: FieldFactoryProps) {
  const textField = useCallback(
    (field: LeadFormField, register: any, errors: any) => (
      <FormControl
        isInvalid={errors[field.apiFieldKey]}
        key={field.id}
        id={field.apiFieldKey}
      >
        <FormLabel mb={1}>{field.label}</FormLabel>
        <Input
          autoComplete={field.apiFieldKey}
          {...register(
            field.apiFieldKey,
            inflateValidationProps(field.validationProps)
          )}
        />

        <FormErrorMessage>
          {errors[field.apiFieldKey] && errors[field.apiFieldKey].message}
        </FormErrorMessage>
      </FormControl>
    ),
    [field, errors]
  );

  switch (field.type) {
    case 'string':
      return textField(field, register, errors);
      break;
    case 'serviceCategoryOfInterest':
      return ServiceCategorySelection(field, register, errors);
      break;
    default:
      return textField(field, register, errors);
      break;
  }
}
