import './create-device-form.module.scss';
import { useMemo, useEffect } from 'react';
import { Filters, FormInput, DEFAULT_LABEL_STYLE } from '@webapp/ui';
import {
  FieldValues,
  FormProvider,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import {
  Heading,
  Stack,
  Flex,
  Button,
  chakra,
  FormControl,
  FormLabel,
  Box,
  SimpleGrid,
} from '@chakra-ui/react';
import { useStores } from '@webapp/state-models';
import {
  DeviceFieldsFragment,
  useInsertDeviceMutation,
  useUpdateDeviceMutation,
  useUpdateDeviceTypesForServiceMutation,
} from '@webapp/graphql';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

import { observer } from 'mobx-react-lite';
import {
  useLoadDeviceTypes,
  useLoadLocations,
  useLoadServices,
} from '@webapp/webapp/hooks';

/* eslint-disable-next-line */
export interface CreateDeviceFormProps {
  device?: DeviceFieldsFragment;
}

export const CreateDeviceForm = observer(
  ({ device }: CreateDeviceFormProps) => {
    const [insertDevice] = useInsertDeviceMutation();
    const [updateDevice] = useUpdateDeviceMutation();

    const [updateDeviceTypeForService] =
      useUpdateDeviceTypesForServiceMutation();

    const navigate = useNavigate();

    const { workspace } = useStores();

    const methods = useForm({
      mode: 'onSubmit',
      reValidateMode: 'onChange',
      defaultValues: {
        ...device,
        deviceTypeId: device?.deviceType?.id,
      },
      criteriaMode: 'firstError',
      shouldFocusError: true,
      shouldUnregister: false,
      shouldUseNativeValidation: false,
      delayError: undefined,
    });

    const { handleSubmit } = methods;

    const onSubmit: SubmitHandler<FieldValues> = async (values) => {
      const {
        __typename,
        location,
        deviceType,
        locationId,
        dependentServices,
        deviceTypeId,
        ...deviceFields
      } = values;
      // serviceDeviceType_serviceId_deviceTypeId_key

      try {
        if (device) {
          await updateDevice({
            variables: {
              set: {
                ...deviceFields,
                locationId: location.id,
                deviceTypeId,
              },
              id: device?.id,
            },
          });
          await updateDeviceTypeForService({
            variables: {
              id: deviceTypeId,
              objects: dependentServices.map((serviceId: string) => ({
                deviceTypeId,
                serviceId,
              })),
            },
          });
          toast.success(`Device ${values.name} updated`);
        } else {
          const { data } = await insertDevice({
            variables: {
              device: {
                ...deviceFields,
                locationId: locationId.value,
                workspaceId: workspace?.id,
                deviceTypeId: deviceTypeId.value,
              },
            },
          });

          await updateDeviceTypeForService({
            variables: {
              id: deviceTypeId.value,
              objects: dependentServices.map((serviceId: string) => ({
                deviceTypeId: deviceTypeId.value,
                serviceId,
              })),
            },
          });

          toast.success('Device created successfully');
          navigate(
            `/settings/devices/${data?.insert_device?.returning[0].id}/edit`
          );
        }
      } catch (error) {
        toast.error((error as Error).message);
      }
    };

    const locationSelectProps = useMemo(
      () => ({
        defaultOption: device
          ? {
              value: device?.location?.id as string,
              label: device?.location?.name as string,
            }
          : null,
        loadOptions: useLoadLocations,
        canCreate: false,
      }),
      [device?.location]
    );

    const deviceTypeSelectProps = useMemo(
      () => ({
        defaultOption: device?.deviceType
          ? {
              value: device?.deviceType?.id as string,
              label: device?.deviceType?.value as string,
            }
          : null,
        loadOptions: useLoadDeviceTypes,
        canCreate: true,
      }),
      [device?.deviceType]
    );

    const serviceSelectProps = useMemo(
      () => ({
        defaultOptions: device
          ? device?.deviceType?.serviceDeviceTypes?.map(({ service }) => ({
              value: service.id,
              label: service.name,
            }))
          : [],
        loadOptions: useLoadServices,
        canCreate: false,
      }),
      [device?.deviceType]
    );

    return (
      <FormProvider {...methods}>
        <chakra.form onSubmit={handleSubmit(onSubmit)}>
          <Stack borderWidth={1} borderColor={'gray.400'}>
            <SimpleGrid columns={2} spacing={10} p={10}>
              <FormInput
                label="Name (Unique to device)"
                labelAlign="start"
                required
                labelPosition="top"
                placeholder="LIPO X - Westwood - 1"
                name="name"
              />
              <Box minW={'200px'}>
                <FormInput
                  label="Device type"
                  selectProps={deviceTypeSelectProps}
                  name="deviceTypeId"
                  placeholder="Select or create deviceType"
                  type="async-select"
                />
              </Box>
              <Box minW={'200px'}>
                <FormInput
                  label="Locations"
                  selectProps={locationSelectProps}
                  name="locationId"
                  placeholder="Select or create location"
                  type="async-select"
                />
              </Box>
              <Box minW={'200px'}>
                <FormInput
                  label="Dependent Services"
                  multiSelectProps={serviceSelectProps}
                  name="dependentServices"
                  placeholder="Select service(s)"
                  type="multiselect"
                />
              </Box>
            </SimpleGrid>

            <Flex
              justifyContent={'space-between'}
              py="4"
              px={{ base: '4', md: '6' }}
            >
              {device && device.active && (
                <Button
                  type="button"
                  colorScheme="red"
                  onClick={() =>
                    updateDevice({
                      variables: { id: device?.id, set: { active: false } },
                    })
                  }
                >
                  De-activate device
                </Button>
              )}
              {device && !device.active && (
                <Button
                  type="button"
                  colorScheme="teal"
                  onClick={() =>
                    updateDevice({
                      variables: { id: device?.id, set: { active: true } },
                    })
                  }
                >
                  Activate device
                </Button>
              )}
              <Button type="submit" colorScheme={'teal'}>
                {device ? 'Update Device' : 'Create Device'}
              </Button>
            </Flex>
          </Stack>
        </chakra.form>
      </FormProvider>
    );
  }
);

export default CreateDeviceForm;
