import { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import {
  Box,
  Stack,
  Progress,
  Heading,
  HStack,
  IconButton,
  Tooltip,
  Flex,
  Spacer,
} from '@chakra-ui/react';
import { StorageType } from '@webapp/graphql';
import { Photo as MSTPhoto } from '@webapp/state-models';
import {
  UIEvent,
  PhotoEditorSDKUI,
  BasicTransformControlBarTabs,
  ContainedPrimaryButton,
  ImageFormat,
  ExportFormat,
  CanvasAction,
  LayoutType,
  Tool,
  EditorApi,
  SerialisationSchema,
  Configuration,
} from 'photoeditorsdk';
import { useUploadToS3, UseUploadToS3Params } from '@webapp/hooks';
import { nanoid } from 'nanoid';
import { FaFileDownload, FaHistory, FaSave } from 'react-icons/fa';
import { getProxyPhotoUrl } from '@webapp/utils';
import ImageHistory from './image-history';
import FullscreenImagePreview from '../fullscreen-image-preview/fullscreen-image-preview';

/* eslint-disable-next-line */
export interface EditableImageProps {
  photo: MSTPhoto;
  showSaveButton?: boolean;
  showDownloadButton?: boolean;
  height?: number | string;
  width?: number | string;

  hideSidebar?: boolean;
  layout?: LayoutType;
  order?: 'default' | 'reverse' | undefined;
  onUpdate?: () => void;
  photoEditorConfiguration?: Partial<Configuration>;
}

type EditableImageHandle = {
  saveCurrentImage: () => void;
  serializeChanges: () => Promise<SerialisationSchema | null>;
  deserializeChanges: (state: SerialisationSchema) => void;
};

export const EditableImage = forwardRef<
  EditableImageHandle,
  EditableImageProps
>(
  (
    {
      photo,
      showSaveButton = true,
      showDownloadButton = false,
      width = 'full',
      height = '85vh',
      layout = 'basic',
      order = 'default',
      hideSidebar = false,
      onUpdate,
      photoEditorConfiguration,
    }: EditableImageProps,
    ref
  ) => {
    const [progress, setProgress] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(false);
    const [showImageHistory, setShowImageHistory] = useState<boolean>(false);
    const [editor, setEditor] = useState<EditorApi | undefined>();
    const editorId = `editor-${nanoid()}`;

    function triggerSaveImage() {
      editor
        ?.export({
          format: ImageFormat.JPEG, // `image/png` or `image/jpeg`
          exportType: ExportFormat.BLOB, // `image`, `data-url` or `blob`
          quality: 0.9, // 0.1 - 1.0, defines the quality of jpeg images
          enableDownload: false, // boolean, enable or disable the automatic file download
          preventExportEvent: true, // boolean, enable or disable the UIEvent.EXPORT which is called on every export
        })
        .then((dataURL: any) => {
          saveImage(dataURL);
        })
        .catch((err: any) => {
          console.error('An error has occured while exporting ', err);
        });
    }

    function downloadImage() {
      editor?.export({
        format: ImageFormat.JPEG, // `image/png` or `image/jpeg`
        exportType: ExportFormat.IMAGE, // `image`, `data-url` or `blob`
        quality: 0.9, // 0.1 - 1.0, defines the quality of jpeg images
        enableDownload: true, // boolean, enable or disable the automatic file download
        preventExportEvent: true, // boolean, enable or disable the UIEvent.EXPORT which is called on every export
      });
    }

    async function saveImage(imageSrc: Blob) {
      setLoading(true);

      await uploadToS3({
        fileType: 'image/jpeg',
        fileContents: new File(
          [imageSrc as Blob],
          photo?.file?.filePath as string
        ),
        filePath: photo?.file?.filePath as string,
        storageType: StorageType.Private,
        onProgress: setProgress,
      });

      await photo.update({ hasThumbnail: false });
      await photo.refresh();
      setLoading(false);
      if (onUpdate) onUpdate();
    }

    useEffect(() => {
      if (editor) {
        editor.reset();
        editor.setImage(photo?.file?.url as string);
        editor.on(UIEvent.EXPORT, async (imageSrc) => {
          if (showSaveButton) {
            saveImage(imageSrc);
          }
        });
      } else {
        initEditor();
      }
    }, [photo?.file?.url]);

    const { uploadToS3 } = useUploadToS3();

    useImperativeHandle(ref, () => ({
      async saveCurrentImage(params?: UseUploadToS3Params): Promise<string> {
        if (editor) {
          const imageSrc = await editor.export({
            format: ImageFormat.JPEG, // `image/png` or `image/jpeg`
            exportType: ExportFormat.BLOB, // `image`, `data-url` or `blob`
            quality: 0.9, // 0.1 - 1.0, defines the quality of jpeg images
            enableDownload: false, // boolean, enable or disable the automatic file download
            preventExportEvent: true, // boolean, enable or disable the UIEvent.EXPORT which is called on every export
          });

          const filePath = await uploadToS3({
            fileType: 'image/jpeg',
            fileContents: new File(
              [imageSrc as Blob],
              photo?.file?.filePath as string
            ),
            filePath: photo?.file?.filePath as string,
            storageType: StorageType.Private,
            onProgress: setProgress,
            ...params,
          });

          return filePath;
        }

        return '';
      },
      async serializeChanges(): Promise<SerialisationSchema | null> {
        if (editor) return editor?.serialize({ image: true });
        return null;
      },
      async deserializeChanges(state: SerialisationSchema) {
        if (editor) {
          editor.deserialize(state);
        }
      },
    }));

    const ExportButton = (props: any) => (
      <ContainedPrimaryButton
        {...props}
        label={showDownloadButton ? 'DOWNLOAD IMAGE' : 'SAVE IMAGE CHANGES'}
      ></ContainedPrimaryButton>
    );

    const mainCanvasActions: Array<
      | CanvasAction.UNDO
      | CanvasAction.REDO
      | CanvasAction.CLOSE
      | CanvasAction.EXPORT
      | undefined
    > = [];

    const initEditor = async () => {
      if (photo.file?.url) {
        const localEditor = await PhotoEditorSDKUI.init({
          container: `#${editorId}`,
          layout,
          order,
          theme: 'light',
          tools: [[Tool.TRANSFORM, Tool.ADJUSTMENT]],

          image: photo.file?.url, // Image url or Image path relative to assets folder
          license: import.meta.env.VITE_IMGLY_LICENSE || '',
          displayToolControlBarTitle: false,
          custom: {
            components: {
              buttons: {
                mainCanvasActionExport: ExportButton,
              },
            },
          },

          mainCanvasActions,
          enableZoom: true,
          export: {
            filename: `${photo?.file?.filePath}.jpeg`,
            image: {
              enableDownload: showDownloadButton,
              format: ImageFormat.JPEG,
              exportType: ExportFormat.BLOB,
            },
          },
          transform: {
            basicUIToolControlBarTabsOrder: [
              BasicTransformControlBarTabs.CropSize,
              BasicTransformControlBarTabs.FlipRotate,
            ],
          },
          ...photoEditorConfiguration,
        });

        localEditor.on(UIEvent.EXPORT, async (imageSrc) => {
          if (showSaveButton) {
            saveImage(imageSrc);
          }
        });
        setEditor(localEditor);
      }
    };

    useEffect(() => {
      if (!showImageHistory) {
        initEditor();
      }
    }, [showImageHistory]);

    return (
      <Stack
        justifyContent={'center'}
        alignItems="center"
        position={'relative'}
        borderWidth={1}
        w={width}
        borderColor="grey.400"
      >
        <Flex
          w={width}
          alignItems="start"
          direction={{ base: 'column', md: 'row' }}
        >
          {!hideSidebar && (
            <Flex p={2} direction={{ base: 'row', md: 'column' }}>
              <Tooltip label="Save edits you made to image">
                <IconButton
                  mb={{ base: 0, md: 1 }}
                  mr={{ base: 1, md: 0 }}
                  aria-label="save image"
                  icon={<FaSave />}
                  onClick={triggerSaveImage}
                ></IconButton>
              </Tooltip>

              <Tooltip label="Save image to your computer">
                <IconButton
                  aria-label="download image"
                  icon={<FaFileDownload />}
                  onClick={downloadImage}
                  mb={{ base: 0, md: 1 }}
                  mr={{ base: 1, md: 0 }}
                ></IconButton>
              </Tooltip>

              <Tooltip label="See image history">
                <IconButton
                  aria-label="image history"
                  icon={<FaHistory />}
                  mb={{ base: 0, md: 1 }}
                  mr={{ base: 1, md: 0 }}
                  onClick={() => setShowImageHistory(!showImageHistory)}
                ></IconButton>
              </Tooltip>

              <FullscreenImagePreview photo={photo} />
            </Flex>
          )}
          {showImageHistory ? (
            <ImageHistory
              photo={photo}
              onClose={() => setShowImageHistory(false)}
            />
          ) : (
            <Box
              id={editorId}
              w={width}
              height={height}
              position="relative"
              className="ph-no-capture fs-exclude"
            ></Box>
          )}
          {loading && (
            <Stack
              position="absolute"
              left={0}
              top={0}
              zIndex={999}
              w={'full'}
              h={'full'}
              justifyContent={'center'}
              alignItems="center"
              bg="rgba(75,75,75,0.7)"
            >
              <Heading color={'white'}>Updating Image...</Heading>
              <Progress value={progress} />
            </Stack>
          )}
        </Flex>
      </Stack>
    );
  }
);

export default EditableImage;
