import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GenerateFinixOnboardingLinkMutationVariables = Types.Exact<{
  maxProcessingAmount: Types.Scalars['Int'];
}>;


export type GenerateFinixOnboardingLinkMutation = { __typename?: 'mutation_root', generateFinixOnboardingLink?: { __typename?: 'FinixOnboardingLinkOutput', onboardingLink: string } | null | undefined };


export const GenerateFinixOnboardingLinkDocument = gql`
    mutation GenerateFinixOnboardingLink($maxProcessingAmount: Int!) {
  generateFinixOnboardingLink(maxProcessingAmount: $maxProcessingAmount) {
    onboardingLink
  }
}
    `;
export type GenerateFinixOnboardingLinkMutationFn = Apollo.MutationFunction<GenerateFinixOnboardingLinkMutation, GenerateFinixOnboardingLinkMutationVariables>;

/**
 * __useGenerateFinixOnboardingLinkMutation__
 *
 * To run a mutation, you first call `useGenerateFinixOnboardingLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateFinixOnboardingLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateFinixOnboardingLinkMutation, { data, loading, error }] = useGenerateFinixOnboardingLinkMutation({
 *   variables: {
 *      maxProcessingAmount: // value for 'maxProcessingAmount'
 *   },
 * });
 */
export function useGenerateFinixOnboardingLinkMutation(baseOptions?: Apollo.MutationHookOptions<GenerateFinixOnboardingLinkMutation, GenerateFinixOnboardingLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GenerateFinixOnboardingLinkMutation, GenerateFinixOnboardingLinkMutationVariables>(GenerateFinixOnboardingLinkDocument, options);
      }
export type GenerateFinixOnboardingLinkMutationHookResult = ReturnType<typeof useGenerateFinixOnboardingLinkMutation>;
export type GenerateFinixOnboardingLinkMutationResult = Apollo.MutationResult<GenerateFinixOnboardingLinkMutation>;
export type GenerateFinixOnboardingLinkMutationOptions = Apollo.BaseMutationOptions<GenerateFinixOnboardingLinkMutation, GenerateFinixOnboardingLinkMutationVariables>;