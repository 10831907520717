import { google as Google } from 'google-maps';
import { Input, InputGroup } from '@chakra-ui/react';
import { useCallback, useEffect, useRef } from 'react';
import DEFAULT_CONTROL_STYLE from './styles/DefaultControlStyle';
import DEFAULT_INPUT_CONTAINER_STYLE from './styles/DefaultInputContainerStyle';
import { PlacesInputProps } from './types/PlacesInputProps';

declare const google: Google;

export function PlacesInput({
  onChange,
  showFullAddress = true,
  styles = {},
  value,
  ...props
}: Omit<PlacesInputProps, 'type'>) {
  const inputStyles = { ...DEFAULT_CONTROL_STYLE, ...styles.control };

  const containerStyles = {
    ...DEFAULT_INPUT_CONTAINER_STYLE,
    ...styles.inputContainer,
  };

  const inputRef = useRef<HTMLInputElement>(null);
  const autoRef = useRef<google.maps.places.Autocomplete | null>(null);

  const handlePlaceSelect = useCallback(() => {
    const addressObject = autoRef.current?.getPlace();
    const groupedAddressComponents: Record<string, string> = {};

    if (addressObject) {
      addressObject.address_components?.forEach((component) => {
        component.types.forEach((type) => {
          groupedAddressComponents[type] = component.short_name;
        });
      });

      const addressLine1 = [
        groupedAddressComponents.street_number,
        groupedAddressComponents.route,
      ].join(' ');

      const city = groupedAddressComponents.locality;
      const { country } = groupedAddressComponents;
      const formattedAddress = addressObject.formatted_address || '';
      const state = groupedAddressComponents.administrative_area_level_1;
      const zipCode = groupedAddressComponents.postal_code;

      const lat = addressObject?.geometry?.location.lat();
      const lng = addressObject?.geometry?.location.lng();

      const newValue = {
        addressLine1,
        city,
        country,
        formattedAddress,
        lat,
        lng,
        state,
        zipCode,
      };

      onChange(newValue);
    }
  }, [onChange]);

  useEffect(() => {
    if (inputRef.current && !autoRef.current) {
      const autocomplete = new google.maps.places.Autocomplete(
        inputRef.current
      );

      autocomplete.addListener('place_changed', handlePlaceSelect);
      autoRef.current = autocomplete;
    }
  }, [handlePlaceSelect]);

  const preventEnterSubmit = (
    e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };

  return (
    <InputGroup {...containerStyles}>
      <Input
        id="autocomplete"
        onKeyPress={preventEnterSubmit}
        onChange={(e) => {
          if (showFullAddress) {
            onChange({
              ...value,
              formattedAddress: e.target.value,
            });
          } else {
            onChange({
              ...value,
              addressLine1: e.target.value,
            });
          }
        }}
        ref={inputRef}
        value={showFullAddress ? value?.formattedAddress : value?.addressLine1}
        {...props}
        {...inputStyles}
      />
    </InputGroup>
  );
}

export default PlacesInput;
