import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type SubscribeAppointmentServiceConsumableSubscriptionVariables = Types.Exact<{
  where?: Types.Maybe<Types.AppointmentServiceConsumable_Bool_Exp>;
}>;


export type SubscribeAppointmentServiceConsumableSubscription = { __typename?: 'subscription_root', appointmentServiceConsumable: Array<{ __typename?: 'appointmentServiceConsumable', id: any }> };


export const SubscribeAppointmentServiceConsumableDocument = gql`
    subscription SubscribeAppointmentServiceConsumable($where: appointmentServiceConsumable_bool_exp) {
  appointmentServiceConsumable(where: $where) {
    id
  }
}
    `;

/**
 * __useSubscribeAppointmentServiceConsumableSubscription__
 *
 * To run a query within a React component, call `useSubscribeAppointmentServiceConsumableSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeAppointmentServiceConsumableSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeAppointmentServiceConsumableSubscription({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useSubscribeAppointmentServiceConsumableSubscription(baseOptions?: Apollo.SubscriptionHookOptions<SubscribeAppointmentServiceConsumableSubscription, SubscribeAppointmentServiceConsumableSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<SubscribeAppointmentServiceConsumableSubscription, SubscribeAppointmentServiceConsumableSubscriptionVariables>(SubscribeAppointmentServiceConsumableDocument, options);
      }
export type SubscribeAppointmentServiceConsumableSubscriptionHookResult = ReturnType<typeof useSubscribeAppointmentServiceConsumableSubscription>;
export type SubscribeAppointmentServiceConsumableSubscriptionResult = Apollo.SubscriptionResult<SubscribeAppointmentServiceConsumableSubscription>;