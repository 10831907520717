import * as Types from '../../generated/graphql';

import { gql } from '@apollo/client';
export type ResolvedAppointmentCustomFormsFieldsFragment = { __typename?: 'ResolvedAppointmentCustomFormsOutput', hasOutstanding: boolean, customForms: Array<{ __typename?: 'MinimalResolvedCustomForm', id: any, title: string, documentId: string, completed: boolean }>, outstanding: Array<{ __typename?: 'MinimalResolvedCustomForm', id: any, title: string }> };

export const ResolvedAppointmentCustomFormsFieldsFragmentDoc = gql`
    fragment ResolvedAppointmentCustomFormsFields on ResolvedAppointmentCustomFormsOutput {
  customForms {
    id
    title
    documentId
    completed
  }
  outstanding {
    id
    title
  }
  hasOutstanding
}
    `;