import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetAudienceQueryVariables = Types.Exact<{
  where: Types.Audience_Bool_Exp;
}>;


export type GetAudienceQuery = { __typename?: 'query_root', audience: Array<{ __typename?: 'audience', id: any, name?: string | null | undefined, type: string, definition: any, updatedAt: any, snapshot: any, createdAt: any, workspaceId: any }> };


export const GetAudienceDocument = gql`
    query getAudience($where: audience_bool_exp!) {
  audience(where: $where) {
    id
    name
    type
    definition
    updatedAt
    snapshot
    createdAt
    workspaceId
  }
}
    `;

/**
 * __useGetAudienceQuery__
 *
 * To run a query within a React component, call `useGetAudienceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAudienceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAudienceQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetAudienceQuery(baseOptions: Apollo.QueryHookOptions<GetAudienceQuery, GetAudienceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAudienceQuery, GetAudienceQueryVariables>(GetAudienceDocument, options);
      }
export function useGetAudienceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAudienceQuery, GetAudienceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAudienceQuery, GetAudienceQueryVariables>(GetAudienceDocument, options);
        }
export type GetAudienceQueryHookResult = ReturnType<typeof useGetAudienceQuery>;
export type GetAudienceLazyQueryHookResult = ReturnType<typeof useGetAudienceLazyQuery>;
export type GetAudienceQueryResult = Apollo.QueryResult<GetAudienceQuery, GetAudienceQueryVariables>;