import * as Joi from 'joi';
import DefaultNullValidation from './DefaultNullValidation';

export const RequireIfAddressExists = Joi.when('addressId', {
  is: Joi.string(),
  then: Joi.string().required().messages({
    'string.empty': 'Required Field',
    'any.required': 'Required Field',
  }),
  otherwise: DefaultNullValidation,
});

export default RequireIfAddressExists;
