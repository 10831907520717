import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  StyleProps,
} from '@chakra-ui/react';
import { Appointment } from '@webapp/state-models';
import { STATUSES } from '@webapp/constants';
import { capitalize } from 'lodash';
import './appointment-status-badge.module.scss';
import { observer } from 'mobx-react-lite';

/* eslint-disable-next-line */
export interface AppointmentStatusBadgeProps {
  appointment?: Appointment | null;
  buttonStyleOverrides?: StyleProps;
  onUpdateStatus?: (status: string) => void;
}

export const AppointmentStatusBadge = observer(
  ({
    appointment,
    buttonStyleOverrides,
    onUpdateStatus,
  }: AppointmentStatusBadgeProps) => (
    <Menu variant={appointment?.status as string}>
      <MenuButton
        borderRadius="6px"
        fontSize="12px"
        fontWeight="700"
        letterSpacing="0.03em"
        lineHeight="18px"
        height="max-content"
        padding="4px 12px"
        width="max-content"
        {...buttonStyleOverrides}
      >
        {capitalize(appointment?.status?.replace(/_/, ' '))}
      </MenuButton>
      <MenuList>
        {STATUSES.map((status) => (
          <MenuItem
            disabled={appointment?.status === status.value}
            key={`status-dropdown-${status.value}`}
            onClick={() => {
              appointment?.updateStatus(status.value);
              if (onUpdateStatus) onUpdateStatus(status.value);
            }}
          >
            {capitalize(status.value.replace(/_/, ' '))}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  )
);

export default AppointmentStatusBadge;
