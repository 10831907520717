import { Instance, onSnapshot, SnapshotOut, types } from 'mobx-state-tree';

/**
 * A LocalSettings model.
 */
// prettier-ignore
const model = types
.model('LocalSettings')
.props({
  schedule: types.optional(types.model().props({
    showWeekends: types.optional(types.boolean, true),
    showEventPopover: types.optional(types.boolean, true),
    timeCellStep: types.maybeNull(types.number)
  }), {})
})
.views((self) => ({
    get startDay() {
        return self.schedule.showWeekends ? 0 : 1
    },
    get endDay() {
        return self.schedule.showWeekends ? 6 : 5
    }}))
.actions((self) => ({
    serialize(snapshot: SnapshotOut<any>) {
        localStorage.setItem('prspr-localSettings', JSON.stringify(snapshot));
    }
}))
.actions((self) => ({
    afterCreate() {
        onSnapshot(self, self.serialize)
    },
    updateScheduleSettings(value: any) {
        self.schedule = value
    }
}));
/**
 * The LocalSettings instance.
 */
export type LocalSettings = Instance<typeof LocalSettings>;

/**
 * The data of a LocalSettings.
 */
export type LocalSettingsSnapshot = SnapshotOut<typeof LocalSettings>;

export const LocalSettings = types.snapshotProcessor(model, {
  preProcessor(sn: any) {
    let app = { ...sn };
    try {
      const initialSettings = localStorage.getItem('prspr-localSettings');

      if (initialSettings) {
        app = JSON.parse(initialSettings);
      }
    } catch (err) {
      console.error(err);
    }
    return app;
  },
});
