import { chakra, Text } from '@chakra-ui/react';
import { FormInputV2 } from '@webapp/ui';

interface CustomGratuityModalInterfaceProps {
  gratuity: number;
  onChange(value: number): void;
  onReturnClick(): void;
}

function CustomGratuityModalInterface({
  gratuity,
  onChange,
  onReturnClick,
}: CustomGratuityModalInterfaceProps) {
  return (
    <chakra.div>
      <Text
        fontSize="2xl"
        fontWeight="bold"
        opacity=".5"
        padding="25px 0 25px"
        textAlign="center"
      >
        Custom Tip Amount
      </Text>
      <chakra.div margin="0 auto" maxWidth="400px">
        <FormInputV2
          incrementStep={1}
          uncontrolled
          onChange={(_, intValue) => onChange(intValue)}
          name="customGratuity"
          styles={{
            inputContainer: {
              maxWidth: '400px',
            },
          }}
          type="currency"
          value={gratuity}
        />
        <Text
          color="text.100"
          cursor="pointer"
          onClick={onReturnClick}
          opacity=".4"
          padding="12px"
          textAlign="center"
          width="100%"
          _hover={{
            opacity: '.8',
            textDecoration: 'underline',
          }}
        >
          Return to Tip Selection
        </Text>
      </chakra.div>
    </chakra.div>
  );
}

export default CustomGratuityModalInterface;
