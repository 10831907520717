import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type EnrollLocationERxMutationVariables = Types.Exact<{
  locationId: Types.Scalars['uuid'];
}>;


export type EnrollLocationERxMutation = { __typename?: 'mutation_root', enrollLocationERx: { __typename?: 'SuccessAnswer', success: boolean } };


export const EnrollLocationERxDocument = gql`
    mutation EnrollLocationERx($locationId: uuid!) {
  enrollLocationERx(locationId: $locationId) {
    success
  }
}
    `;
export type EnrollLocationERxMutationFn = Apollo.MutationFunction<EnrollLocationERxMutation, EnrollLocationERxMutationVariables>;

/**
 * __useEnrollLocationERxMutation__
 *
 * To run a mutation, you first call `useEnrollLocationERxMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnrollLocationERxMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [enrollLocationERxMutation, { data, loading, error }] = useEnrollLocationERxMutation({
 *   variables: {
 *      locationId: // value for 'locationId'
 *   },
 * });
 */
export function useEnrollLocationERxMutation(baseOptions?: Apollo.MutationHookOptions<EnrollLocationERxMutation, EnrollLocationERxMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EnrollLocationERxMutation, EnrollLocationERxMutationVariables>(EnrollLocationERxDocument, options);
      }
export type EnrollLocationERxMutationHookResult = ReturnType<typeof useEnrollLocationERxMutation>;
export type EnrollLocationERxMutationResult = Apollo.MutationResult<EnrollLocationERxMutation>;
export type EnrollLocationERxMutationOptions = Apollo.BaseMutationOptions<EnrollLocationERxMutation, EnrollLocationERxMutationVariables>;