import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type ErxEnrollQueryQueryVariables = Types.Exact<{
  patientId: Types.Scalars['uuid'];
  providerId: Types.Scalars['uuid'];
  workspaceId: Types.Scalars['uuid'];
}>;


export type ErxEnrollQueryQuery = { __typename?: 'query_root', patientWorkspace: Array<{ __typename?: 'patientWorkspace', id: any, firstName?: string | null | undefined, lastName?: string | null | undefined, email?: string | null | undefined, dob?: any | null | undefined, phoneNumber?: string | null | undefined, height?: number | null | undefined, weight?: number | null | undefined, dosespotPatientId?: number | null | undefined, clinicId?: number | null | undefined }>, locationProvider: Array<{ __typename?: 'locationProvider', id: any, clinicianId?: number | null | undefined, location: { __typename?: 'location', clinicId?: number | null | undefined } }> };


export const ErxEnrollQueryDocument = gql`
    query ERXEnrollQuery($patientId: uuid!, $providerId: uuid!, $workspaceId: uuid!) {
  patientWorkspace(
    where: {patientId: {_eq: $patientId}, workspaceId: {_eq: $workspaceId}}
  ) {
    id
    firstName
    lastName
    email
    dob
    phoneNumber
    height
    weight
    dosespotPatientId
    clinicId
  }
  locationProvider(
    where: {providerId: {_eq: $providerId}, clinicianId: {_is_null: false}}
  ) {
    id
    clinicianId
    location {
      clinicId
    }
  }
}
    `;

/**
 * __useErxEnrollQueryQuery__
 *
 * To run a query within a React component, call `useErxEnrollQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useErxEnrollQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useErxEnrollQueryQuery({
 *   variables: {
 *      patientId: // value for 'patientId'
 *      providerId: // value for 'providerId'
 *      workspaceId: // value for 'workspaceId'
 *   },
 * });
 */
export function useErxEnrollQueryQuery(baseOptions: Apollo.QueryHookOptions<ErxEnrollQueryQuery, ErxEnrollQueryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ErxEnrollQueryQuery, ErxEnrollQueryQueryVariables>(ErxEnrollQueryDocument, options);
      }
export function useErxEnrollQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ErxEnrollQueryQuery, ErxEnrollQueryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ErxEnrollQueryQuery, ErxEnrollQueryQueryVariables>(ErxEnrollQueryDocument, options);
        }
export type ErxEnrollQueryQueryHookResult = ReturnType<typeof useErxEnrollQueryQuery>;
export type ErxEnrollQueryLazyQueryHookResult = ReturnType<typeof useErxEnrollQueryLazyQuery>;
export type ErxEnrollQueryQueryResult = Apollo.QueryResult<ErxEnrollQueryQuery, ErxEnrollQueryQueryVariables>;