import * as Types from '../../generated/graphql';

import { gql } from '@apollo/client';
export type LeadFormFieldFieldsFragment = { __typename?: 'leadFormField', id: any, validationProps?: any | null | undefined, apiFieldKey: string, label: string, order: any, type: string, workspaceId: any };

export const LeadFormFieldFieldsFragmentDoc = gql`
    fragment LeadFormFieldFields on leadFormField {
  id
  validationProps
  apiFieldKey
  label
  order
  type
  workspaceId
}
    `;