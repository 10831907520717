import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { NoteFieldsFragmentDoc } from '../../fragments/noteFields.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetProviderPendingNotesQueryVariables = Types.Exact<{
  providerId?: Types.Maybe<Types.Scalars['uuid']>;
}>;


export type GetProviderPendingNotesQuery = { __typename?: 'query_root', note: Array<{ __typename?: 'note', isLocked?: boolean | null | undefined, createdAt: any, title?: string | null | undefined, isGoodFaithExam?: boolean | null | undefined, id: any, isInternal: boolean, message: string, appointmentId?: any | null | undefined, updatedAt: any, author: { __typename?: 'user', firstName: string, id: any, lastName: string, title?: string | null | undefined, profilePicture?: string | null | undefined }, editedBy?: { __typename?: 'user', firstName: string, id: any, lastName: string, title?: string | null | undefined } | null | undefined, noteSignatures: Array<{ __typename?: 'noteSignature', signature?: { __typename?: 'signature', id: any, filePath: string, provider?: { __typename?: 'provider', firstName: string, id: any, lastName: string, title?: string | null | undefined } | null | undefined, file?: { __typename?: 'FileOnlyURL', url: string } | null | undefined } | null | undefined }>, assignedToProvider?: { __typename?: 'provider', id: any, firstName: string, lastName: string, title?: string | null | undefined } | null | undefined, patient: { __typename?: 'patient', firstName: string, id: any, lastName: string } }> };


export const GetProviderPendingNotesDocument = gql`
    query GetProviderPendingNotes($providerId: uuid) {
  note(
    where: {assignedToProviderId: {_eq: $providerId}, _not: {noteSignatures: {signature: {providerId: {_eq: $providerId}}}}}
    order_by: {createdAt: desc}
  ) {
    ...NoteFields
  }
}
    ${NoteFieldsFragmentDoc}`;

/**
 * __useGetProviderPendingNotesQuery__
 *
 * To run a query within a React component, call `useGetProviderPendingNotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProviderPendingNotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProviderPendingNotesQuery({
 *   variables: {
 *      providerId: // value for 'providerId'
 *   },
 * });
 */
export function useGetProviderPendingNotesQuery(baseOptions?: Apollo.QueryHookOptions<GetProviderPendingNotesQuery, GetProviderPendingNotesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProviderPendingNotesQuery, GetProviderPendingNotesQueryVariables>(GetProviderPendingNotesDocument, options);
      }
export function useGetProviderPendingNotesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProviderPendingNotesQuery, GetProviderPendingNotesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProviderPendingNotesQuery, GetProviderPendingNotesQueryVariables>(GetProviderPendingNotesDocument, options);
        }
export type GetProviderPendingNotesQueryHookResult = ReturnType<typeof useGetProviderPendingNotesQuery>;
export type GetProviderPendingNotesLazyQueryHookResult = ReturnType<typeof useGetProviderPendingNotesLazyQuery>;
export type GetProviderPendingNotesQueryResult = Apollo.QueryResult<GetProviderPendingNotesQuery, GetProviderPendingNotesQueryVariables>;