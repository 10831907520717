/* eslint-disable */
// @ts-nocheck
import { PayrixFields } from '@webapp/types';
import { useState, useCallback } from 'react';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface UsePayrixFields {
  count: number;
  increment: () => void;
}

function getHeaders() {
  var e = {};
  return (
    PayFields.config.apiKey
      ? (e.APIKEY = PayFields.config.apiKey)
      : (e.SESSIONKEY = PayFields.config.sessionKey),
    (e['Content-Type'] = 'application/json; charset=UTF-8'),
    e
  );
}

function handlePaymentAttempt(e) {
  var i,
    s = PayFields.api.toLowerCase(),
    t = PayFields.jQuery('#payFields-iframe');
  ~e.origin.indexOf(s) &&
    ('payFieldsResponse' === (e = JSON.parse(e.data)).sender
      ? e.hasOwnProperty('responseErrors')
        ? (PayFields.appendErrors(e.responseErrors),
          'function' == typeof PayFields.onFailure &&
            PayFields.onFailure(e.res),
          'function' == typeof PayFields.onFinish && PayFields.onFinish(e.res))
        : e.success &&
          (PayFields.button.element &&
            ((i = PayFields.jQuery(PayFields.button.element)).text('Success'),
            i.css({ backgroundColor: 'green', transition: '2s' })),
          'function' == typeof PayFields.onSuccess &&
            PayFields.onSuccess(e.res),
          'function' == typeof PayFields.onFinish && PayFields.onFinish(e.res),
          setTimeout(function () {
            var e, i;
            PayFields.clearFields(),
              (PayFields.swiped = !1),
              PayFields.button.element &&
                ((e = null),
                PayFields.button.value
                  ? (e = PayFields.button.value)
                  : 'token' === PayFields.config.mode
                  ? (e = 'Save Payment')
                  : ((e = 'Purchase'),
                    PayFields.config.amount == PayFields.buttonAmount &&
                      (e +=
                        ' $' +
                        PayFields.formatAmount(PayFields.config.amount))),
                (i = PayFields.jQuery(PayFields.button.element)).text(e),
                i.css({ backgroundColor: 'rgb(134, 198, 208)' }),
                i.prop('disabled', !1));
          }, 2e3))
      : 'noResponseError' === e.sender
      ? (PayFields.button.element &&
          ((i = PayFields.jQuery(PayFields.button.element)).text('Error'),
          i.css({ backgroundColor: 'red', transition: '2s' })),
        setTimeout(function () {
          var e, i;
          PayFields.button.element &&
            ((e = null),
            PayFields.button.value
              ? (e = PayFields.button.value)
              : 'token' === PayFields.config.mode
              ? (e = 'Save Payment')
              : ((e = 'Purchase'),
                PayFields.config.amount == PayFields.buttonAmount &&
                  (e +=
                    ' $' + PayFields.formatAmount(PayFields.config.amount))),
            (i = PayFields.jQuery(PayFields.button.element)).text(e),
            i.css({ backgroundColor: 'rgb(134, 198, 208)' }),
            i.prop('disabled', !1));
        }, 2e3),
        'function' == typeof PayFields.onFailure && PayFields.onFailure(e.res),
        'function' == typeof PayFields.onFinish && PayFields.onFinish(e.res))
      : 'error' === e.sender
      ? (PayFields.button.element &&
          PayFields.jQuery(PayFields.button.element).prop('disabled', !1),
        !PayFields.fieldsError &&
          e.error &&
          ((PayFields.fieldsErrors = !0),
          'function' == typeof PayFields.onValidationFailure &&
            PayFields.onValidationFailure()))
      : 'closeSwipe' === e.sender &&
        (e.swiped && (PayFields.swiped = !0),
        (PayFields.poppedUp = !1),
        PayFields.jQuery('#payFields-iframe-swiper').css('display', 'none')),
    'closeSwipe' !== e.sender && PayFields.count++,
    0 < PayFields.fields.length &&
      PayFields.count === PayFields.fields.length &&
      !PayFields.fieldsErrors &&
      t
        .get(0)
        .contentWindow?.postMessage(
          JSON.stringify({ sender: 'process' }),
          PayFields.api + '/payFields?section=main'
        ));
}

var PayFields = {
    jQuery:
      'function' == typeof jQuery
        ? jQuery
        : 'function' == typeof $
        ? $
        : function (e) {
            var i = {
              get: function (e) {
                return void 0 !== e && this._elements[e]
                  ? this._elements[e]
                  : this._elements;
              },
              on: function (e, i) {
                for (var s = 0; s < this._elements.length; s++) {
                  var t = this._elements[s],
                    n = !1;
                  'ready' == e &&
                    ((
                      t.nodeType && 9 == t.nodeType
                        ? 'complete' == t.readyState ||
                          ('loading' !== t.readyState &&
                            !t.documentElement.doScroll)
                        : 'complete' == t.ownerDocument.readyState ||
                          ('loading' !== t.ownerDocument.readyState &&
                            !t.ownerDocument.documentElement.doScroll)
                    )
                      ? (i(), (n = !0))
                      : (e = 'DOMContentLoaded')),
                    n ||
                      ((n = function (e) {
                        return (
                          PayFields.jQuery(e.srcElement || e.target).get(0) ==
                            t && ((e = e || window.event), i.call(t, e))
                        );
                      }),
                      t.addEventListener
                        ? t.addEventListener(e, n, !1)
                        : t.attachEvent && t.attachEvent('on' + e, n));
                }
                return this;
              },
              attr: function (e, i) {
                if (void 0 === i)
                  return this.length
                    ? this._elements[0].getAttribute(e)
                    : void 0;
                for (var s = 0; s < this._elements.length; s++)
                  this._elements[s].setAttribute(e, i);
                return this;
              },
              show: function (e) {
                for (var i = 0; i < this._elements.length; i++)
                  this._elements[i].style.display = 'block';
              },
              hide: function (e) {
                for (var i = 0; i < this._elements.length; i++)
                  this._elements[i].style.display = 'none';
              },
              prop: function (e, i) {
                if (void 0 === i)
                  return this.length ? this._elements[0].name : void 0;
                for (var s = 0; s < this._elements.length; s++)
                  this._elements[s][e] = i;
                return this;
              },
              append: function (e) {
                var i = e;
                if (
                  (i =
                    e instanceof Object && !(e instanceof Array) && e.get
                      ? e.get(0)
                      : i)
                )
                  for (var s = 0; s < this._elements.length; s++)
                    this._elements[s].appendChild(i);
                return this;
              },
              css: function (e, i) {
                var s = e instanceof Object && !(e instanceof Array);
                if (void 0 === i && !s)
                  return this.length ? this._elements[0].style[e] : void 0;
                var t = e;
                s || ((t = {})[e] = i);
                for (var n = 0; n < this._elements.length; n++) {
                  var o,
                    a = this._elements[n];
                  for (o in t) t.hasOwnProperty(o) && (a.style[o] = t[o]);
                }
                return this;
              },
              text: function (e) {
                if (void 0 === e)
                  return this.length
                    ? this._elements[0].value || this._elements[0].innerText
                    : void 0;
                for (var i = 0; i < this._elements.length; i++) {
                  var s = this._elements[i];
                  s.innerText = s.value = e;
                }
                return this;
              },
              fadeOut: function (e) {
                e = e || 2e3;
                for (
                  var i = Math.round(e / 10), s = 0;
                  s < this._elements.length;
                  s++
                ) {
                  var t = this._elements[s];
                  t.style.opacity || (t.style.opacity = 1),
                    (function () {
                      var e = setInterval(function () {
                        0 < t.style.opacity
                          ? (t.style.opacity -= 0.1)
                          : clearInterval(e);
                      }, i);
                    })();
                }
              },
              ready: function (e) {
                return this.on('ready', e);
              },
              _elements: [],
            };
            if ('string' == typeof e)
              try {
                i._elements = document.querySelectorAll(e);
              } catch (e) {}
            else
              e instanceof Object &&
                !(e instanceof Array) &&
                (e.get ? (i._elements = e.get()) : (i._elements = [e]));
            return (i.length = i._elements.length), i;
          },
    config: {
      billingAddress: {},
      additionalData: {},
      items: [],
      invoiceResult: {},
      swipe: !1,
      googlePay: {
        googlePayButtonId: 'googlePayButton',
        buttonColor: 'default',
        buttonType: 'plain',
        environment: 'PRODUCTION',
        facilitatorMerchantId: '',
      },
    },
    swipeStyles: {},
    api: import.meta.env.VITE_PAYFIELDS_URL || 'https://test-api.payrix.com',
    count: 0,
    button: {},
    buttonAmount: null,
    customFields: [],
    swipeButton: {},
    customizations: { style: {}, placeholders: {}, optionalFields: [] },
    fieldsErrors: !1,
    fields: [],
    iframes: {},
    appended: !1,
    poppedUp: !1,
    fieldsAdded: [],
    swiped: !1,
    onSuccess: function (e) {},
    onFailure: function (e) {},
    onFinish: function (e) {},
    onValidationFailure: function () {},
    onRestore: function () {},
    onSessionStart: function () {},
    isValid: function () {
      var e = !1,
        i = !1,
        s = [
          'number',
          'expiration',
          'cvv',
          'name',
          'address',
          'customer_id',
          'routing',
          'account_type',
          'account_number',
        ],
        t = [
          '.input',
          '.number',
          '.expiration',
          '.cvv',
          '.name',
          '.address-input',
          '.address1',
          '.city',
          '.state',
          '.zip',
          '.email',
          '.phone',
          '.form-error',
          '.number-error',
          '.expiration-error',
          '.cvv-error',
          '.name-error',
          '.address-form-error',
          '.address1-error',
          '.city-error',
          '.state-error',
          '.zip-error',
          '.email-error',
          '.phone-error',
          '.card-icon',
          '.customer_id',
          'customer_id-error',
          '.routing',
          '.account_type',
          '.account_number',
          '.routing-error',
          '.account_type-error',
          '.account_number-error',
        ],
        n = ['#payFields-iframe-swiper'],
        o = [
          '#payment_number',
          '#expiration',
          '#payment_cvv',
          '#name',
          '#address1',
          '#city',
          '#state',
          '#zip',
          '#email',
          '#phone',
          '#customer_id',
          '#routing',
          '#account_type',
          '#account_number',
        ];
      if (!PayFields.config.sessionKey && !PayFields.config.apiKey)
        return (
          console.log('The sessionKey or apiKey is not properly configured.'),
          !1
        );
      if (!PayFields.config.merchant && 'token' !== PayFields.config.mode)
        return console.log('The merchant is not properly configured.'), !1;
      if ('txnToken' === PayFields.config.mode && PayFields.config.token)
        return (
          console.log('Token cannot be setup in config in txnToken mode'), !1
        );
      if (
        'token' !== PayFields.config.mode &&
        PayFields.config.txnType &&
        !['sale', 'auth', 'ecsale'].includes(PayFields.config.txnType)
      )
        return console.log('Not a valid transaction type'), !1;
      if (
        0 === PayFields.fields.length &&
        !PayFields.config.swipe &&
        !PayFields.config.token
      )
        return console.log('No fields were setup'), !1;
      if (PayFields.customizations.hasOwnProperty('optionalFields')) {
        var a = [
          '#phone',
          '#email',
          '#expiration',
          '#payment_cvv',
          '#address1',
          '#city',
          '#state',
          '#zip',
          '#customer_id',
          '#routing',
          '#account_type',
          '#account_number',
        ];
        for (d in PayFields.customizations.optionalFields)
          PayFields.customizations.optionalFields.hasOwnProperty(d) &&
            ('#expiration' == PayFields.customizations.optionalFields[d] &&
              (i = !0),
            -1 == a.indexOf(PayFields.customizations.optionalFields[d]) &&
              console.log(
                PayFields.customizations.optionalFields[d] +
                  ' is not a valid optional field. The optional fields allow are: ' +
                  a.join(', ')
              ));
      }
      if (0 < PayFields.fields.length) {
        for (var l = 0; l < PayFields.fields.length; l++) {
          if ('object' != typeof PayFields.fields[l])
            return (
              console.log('An object has to be sent for the field options'), !1
            );
          if (-1 === s.indexOf(PayFields.fields[l].type))
            return (
              console.log(
                PayFields.fields[l].type + ' is not a valid type of field'
              ),
              !1
            );
          if (!PayFields.fields[l].element)
            return (
              console.log(
                'You should pass an element for field ' +
                  PayFields.fields[l].type
              ),
              !1
            );
          if (
            ('number' === PayFields.fields[l].type
              ? (e = !0)
              : 'expiration' === PayFields.fields[l].type && (i = !0),
            0 === PayFields.jQuery(PayFields.fields[l].element)?.length)
          )
            return (
              console.log(
                'Please create the div with element ' +
                  PayFields.fields[l].element
              ),
              !1
            );
        }
        if (!PayFields.config.swipe && !PayFields.config.token) {
          if (!e) return console.log('field with type number is required'), !1;
          if (!i)
            return console.log('field with type expiration is required'), !1;
        }
        if (PayFields.customizations.hasOwnProperty('style'))
          for (var d in PayFields.customizations.style)
            if (PayFields.customizations.style.hasOwnProperty(d)) {
              if (-1 === t.indexOf(d) && -1 === n.indexOf(d))
                return (
                  console.log(
                    d +
                      ' element does not exist, please check documentation for proper elements'
                  ),
                  !1
                );
              if ('object' != typeof PayFields.customizations.style[d])
                return (
                  console.log(
                    'You need to pass an object for styling element ' + d
                  ),
                  !1
                );
              '#payFields-iframe-swiper' === d &&
                ((PayFields.swipeStyles = PayFields.customizations.style[d]),
                delete PayFields.customizations.style[d]);
            }
        if (PayFields.customizations.hasOwnProperty('placeholders'))
          for (var d in PayFields.customizations.placeholders)
            if (PayFields.customizations.placeholders.hasOwnProperty(d)) {
              if (-1 === o.indexOf(d))
                return (
                  console.log(
                    d +
                      ' is not a valid placeholder, please check documentation for proper placeholders'
                  ),
                  !1
                );
              if ('string' != typeof PayFields.customizations.placeholders[d])
                return (
                  console.log(
                    'You need to pass a string to customize text of placeholder ' +
                      d
                  ),
                  !1
                );
            }
      }
      return !0;
    },
    clearFields: function (e) {
      try {
        var i,
          s = ['address1', 'city', 'state', 'zip', 'email', 'phone'],
          t = PayFields.jQuery('#payFields-iframe'),
          n = [];
        i = Array.isArray(e) ? e : PayFields.fieldsAdded;
        for (var o, a, l = 0; l < i.length; l++)
          if (0 <= PayFields.fieldsAdded.indexOf(i[l]))
            if (
              (window.frames['payFields_' + i[l]] &&
                ((a = (o = PayFields.jQuery('#payFields-iframe-' + i[l])).attr(
                  'src'
                )),
                o
                  .get(0)
                  .contentWindow?.postMessage(
                    JSON.stringify({ sender: 'clear' }),
                    a
                  ),
                'address' === i[l]
                  ? (n.push(s[0]),
                    n.push(s[1]),
                    n.push(s[2]),
                    n.push(s[3]),
                    n.push(s[4]),
                    n.push(s[5]))
                  : n.push(i[l])),
              !window.frames['payFields_' + i[l]])
            )
              if ('address' === i[l])
                for (var d = 0; d < s.length; d++)
                  -1 === PayFields.customFields.indexOf(s[d]) && n.push(s[d]);
              else -1 === PayFields.customFields.indexOf(i[l]) && n.push(i[l]);
        t.get(0).contentWindow?.postMessage(
          JSON.stringify({ sender: 'clear', clearFields: n }),
          PayFields.api + '/payFields?section=main'
        );
      } catch (error) {
        console.log('clearFields error');
      }
    },
    reload: function (e) {
      for (var i, s, t = 0; t < PayFields.fields.length; t++)
        (i = PayFields.fields[t].element),
          (s = PayFields.fields[t].type),
          (!e && PayFields.jQuery('#payFields-iframe-' + s)?.length) ||
            PayFields.appendTo(s, i);
    },
    restore: function (e) {
      var i,
        s,
        t,
        n = PayFields.jQuery('#payFields-iframe');
      t = Array.isArray(e) ? e : PayFields.fieldsAdded;
      for (var o = 0; o < t.length; o++)
        0 <= PayFields.fieldsAdded.indexOf(t[o]) &&
          ((s = t[o].charAt(0).toUpperCase() + t[o].slice(1)),
          (i = PayFields.jQuery('#payFields-iframe-' + t[o])),
          (function (e) {
            i.on('load', function () {
              n.get(0).contentWindow?.postMessage(
                JSON.stringify({ sender: 'restore' + e }),
                PayFields.api + '/payFields?section=main'
              );
            });
          })(s));
      setTimeout(function () {
        'function' == typeof PayFields.onFailure && PayFields.onRestore();
      }, 100);
    },
    appendTo: function (e, i) {
      return PayFields.appended
        ? e && -1 !== PayFields.fieldsAdded.indexOf(e)
          ? i && 'string' == typeof i
            ? ((i = PayFields.jQuery(i)),
              (PayFields.customizations.hasOwnProperty('style') &&
                Object.getOwnPropertyNames(PayFields.customizations.style)
                  .length) ||
                ('address' === e
                  ? i.css({ height: '440px', width: '300px' })
                  : i.css({ height: '75px', width: '300px' })),
              i.append(PayFields.iframes[e]),
              void PayFields.restore([e]))
            : (console.log('Improper element passed'), !1)
          : (console.log('No field type passed or not one of fields setup'), !1)
        : (console.log('An iframe cannot be moved before it is appended'), !1);
    },
    addWalletButtons: function () {
      var e;
      PayFields.jQuery('apple-pay-button').hide(),
        void 0 !== PayFields.config.merchant &&
          ((e = new XMLHttpRequest()).open(
            'GET',
            PayFields.api + '/walletParams/' + PayFields.config.merchant,
            !0
          ),
          (e.onreadystatechange = function () {
            if (e.readyState === XMLHttpRequest.DONE && 200 === e.status) {
              let s = JSON.parse(this.response);
              if (void 0 === s.errors || 0 === s.errors.length) {
                if (
                  (void 0 !== s.response &&
                    ((s = s.response),
                    1 === s.applePayEnabled &&
                      window.ApplePaySession &&
                      PayFields.jQuery('apple-pay-button') &&
                      (PayFields.jQuery('apple-pay-button').show(),
                      PayFields.jQuery('apple-pay-button').on(
                        'click',
                        PayFields.applePay
                      ))),
                  1 === s.googlePayEnabled &&
                    PayFields.jQuery(
                      Payfields.config.googlePay.googlePayButtonId
                    ))
                ) {
                  if (!window?.postMessage || !window.addEventListener)
                    return (
                      console.log(
                        "The browser doesn't support postMessage or addEventListener"
                      ),
                      void alert(
                        "The browser doesn't support postMessage or addEventListener"
                      )
                    );
                  PayFields.config.googlePay.facilitatorMerchantId =
                    s.google.facilitatorMerchantId;
                  let e = document.createElement('iframe');
                  e.setAttribute('id', 'payGoogle-iframe'),
                    (e.src =
                      PayFields.api +
                      '/payGoogle?environment=' +
                      PayFields.config.googlePay.environment +
                      '&buttonColor=' +
                      PayFields.config.googlePay.buttonColor +
                      '&buttonType=' +
                      PayFields.config.googlePay.buttonType +
                      '&merchantId=' +
                      PayFields.config.merchant +
                      '&facilitatorId=' +
                      PayFields.config.googlePay.facilitatorMerchantId +
                      '&amount=' +
                      PayFields.config.amount +
                      '&txnType=' +
                      PayFields.config.txnType +
                      '&tax=' +
                      Payfields.config.tax),
                    PayFields.config.apiKey
                      ? (e.src += '&APIKEY=' + PayFields.config.apiKey)
                      : (e.src += '&SESSIONKEY=' + PayFields.config.sessionKey),
                    (e.name = 'payGoogle'),
                    e.setAttribute('allowpaymentrequest', !0),
                    (e.title = 'Checkout page'),
                    (e.width = '400'),
                    (e.height = '100'),
                    (e.frameBorder = '0');
                  let i = document.getElementById(
                    Payfields.config.googlePay.googlePayButtonId
                  );
                  i.appendChild(e),
                    PayFields.jQuery('#payGoogle-iframe').on(
                      'load',
                      function () {
                        window.addEventListener('message', function (s) {
                          var e = PayFields.api.toLowerCase();
                          if (~s.origin.indexOf(e)) {
                            s = JSON.parse(s.data);
                            if ('payGoogle' === s.sender)
                              if ('onSessionStart' == s.from)
                                PayFields.onSessionStart();
                              else {
                                let e = !1,
                                  i = s.response;
                                'catch' == s.from
                                  ? (e = !0)
                                  : 'onreadystatechange' == s.from &&
                                    (void 0 !== i.response
                                      ? ((i = i.response),
                                        0 !== i.errors.length
                                          ? (e = !0)
                                          : 0 !== i.data.length && (e = !1))
                                      : (e = !0)),
                                  e
                                    ? (PayFields.onFailure(i),
                                      Payfields.onFinish(i))
                                    : (PayFields.onSuccess(i),
                                      PayFields.onFinish(i));
                              }
                          }
                        });
                      }
                    );
                }
              } else PayFields.onFailure(s);
            }
          }),
          e.setRequestHeader('Content-Type', 'application/json; charset=UTF-8'),
          PayFields.config.apiKey
            ? e.setRequestHeader('APIKEY', PayFields.config.apiKey)
            : e.setRequestHeader('SESSIONKEY', PayFields.config.sessionKey),
          e.send());
    },
    applePay: function () {
      var n = new XMLHttpRequest(),
        s = Number(PayFields.config.amount) / 100,
        e = {
          countryCode: 'US',
          currencyCode: 'USD',
          supportedNetworks: ['visa', 'masterCard', 'amex', 'discover'],
          merchantCapabilities: ['supports3DS'],
          total: {
            label: 'Payrix Merchant',
            amount: (s = (s = s.toFixed(2))
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ',')),
          },
        };
      try {
        var o = new ApplePaySession(3, e);
        (o.onvalidatemerchant = function (e) {
          var i,
            s = { validationDomain: window.location.hostname };
          for (i in (n.open(
            'POST',
            PayFields.api + '/applePayValidation/' + PayFields.config.merchant,
            !1
          ),
          (n.onreadystatechange = function () {
            n.readyState === XMLHttpRequest.DONE &&
              200 === n.status &&
              o.completeMerchantValidation(JSON.parse(n.responseText));
          }),
          (headers = getHeaders()),
          headers))
            n.setRequestHeader(i, headers[i]);
          n.send(JSON.stringify(s));
        }),
          (o.onshippingmethodselected = function (e) {
            var i = { newTotal: s };
            o.completeShippingMethodSelection(i);
          }),
          (o.onpaymentauthorized = function (e) {
            var i = { status: ApplePaySession.STATUS_SUCCESS, errors: [] };
            if ((o.completePayment(i), e.payment.token)) {
              var s,
                t = e.payment.token.paymentData,
                e = PayFields.api + '/txns';
              for (s in (n.open('POST', e, !1),
              (n.onreadystatechange = function () {
                if (n.readyState === XMLHttpRequest.DONE && 200 === n.status) {
                  let e = JSON.parse(n.response);
                  void 0 !== e.response
                    ? ((e = e.response),
                      0 !== e.errors.length
                        ? (PayFields.onFailure(e), Payfields.onFinish(e))
                        : 0 !== e.data.length &&
                          (PayFields.onSuccess(e), PayFields.onFinish(e)))
                    : (PayFields.onFailure(e), PayFields.onFinish(e));
                }
              }),
              (headers = getHeaders()),
              headers))
                n.setRequestHeader(s, headers[s]);
              n.send(
                JSON.stringify({
                  merchant: PayFields.config.merchant,
                  type: PayFields.config.txnType,
                  origin: 2,
                  entryMode: 9,
                  total: PayFields.config.amount,
                  payment: {
                    paymentData: {
                      data: t.data,
                      header: {
                        ephemeralPublicKey: t.header.ephemeralPublicKey,
                      },
                    },
                    version: t.version,
                    encrypted: 'applePaymentToken',
                  },
                })
              );
            }
          }),
          PayFields.onSessionStart(),
          o.begin();
      } catch (e) {
        console.log(e.message);
      }
    },
    submit: function () {
      var e = PayFields.jQuery('#payFields-iframe');
      if (
        (e.get(0).contentWindow?.postMessage(
          JSON.stringify({
            sender: 'payFieldsConfig',
            config: PayFields.config,
          }),
          PayFields.api + '/payFields?section=main'
        ),
        !PayFields.config.amount && 'token' !== PayFields.config.mode)
      )
        return console.log('Submit has been disabled, no amount was set'), !1;
      if (
        ((PayFields.fieldsErrors = !1),
        (PayFields.count = 0),
        PayFields.button.element && PayFields.jQuery(this).prop('disabled', !0),
        0 < PayFields.fields.length)
      )
        for (var i = 0; i < PayFields.fields.length; i++) {
          var s,
            t = PayFields.jQuery(
              '#payFields-iframe-' + PayFields.fields[i].type
            ),
            n = t.attr('src');
          (s =
            ('number' !== PayFields.fields[i].type &&
              'expiration' !== PayFields.fields[i].type &&
              'cvv' !== PayFields.fields[i].type &&
              'customer_id' !== PayFields.fields[i].type &&
              'routing' !== PayFields.fields[i].type &&
              'account_type' !== PayFields.fields[i].type &&
              'account_number' !== PayFields.fields[i].type &&
              'name' !== PayFields.fields[i].type) ||
            !PayFields.swiped
              ? 'name' !== PayFields.fields[i].type ||
                ('token' !== PayFields.config.mode &&
                  'txnToken' !== PayFields.config.mode) ||
                PayFields.config.customer
                ? { sender: 'button' }
                : { sender: 'button', requireLastname: !0 }
              : { sender: 'button', swiped: !0 }),
            PayFields.customizations.hasOwnProperty('optionalFields') &&
              (s.optionals = PayFields.customizations.optionalFields),
            t.length
              ? t.get(0).contentWindow?.postMessage(JSON.stringify(s), n)
              : ((s.field = PayFields.fields[i].type),
                e
                  .get(0)
                  .contentWindow?.postMessage(
                    JSON.stringify(s),
                    PayFields.api + '/payFields?section=main'
                  ));
        }
      else
        0 === PayFields.fields.length &&
          (PayFields.swiped || PayFields.config.token) &&
          e
            .get(0)
            .contentWindow?.postMessage(
              JSON.stringify({ sender: 'process' }),
              PayFields.api + '/payFields?section=main'
            );
    },
    swipePopup: function () {
      if (!PayFields.config.swipe)
        return console.log('Swipe is not enabled!'), !0;
      if (PayFields.poppedUp) return !0;
      var e = PayFields.jQuery('#payFields-iframe-swiper');
      e.css('display', 'block');
      var i = e.attr('src');
      e.get(0).contentWindow?.postMessage(
        JSON.stringify({ sender: 'showSwipe', fields: PayFields.fieldsAdded }),
        i
      );
    },
    addFields: function () {
      var n = !1,
        o = !1,
        a = !0,
        l = {},
        e = [
          '.input',
          '.number',
          '.expiration',
          '.cvv',
          '.name',
          '.address-input',
          '.address1',
          '.city',
          '.state',
          '.zip',
          '.email',
          '.phone',
          '.form-error',
          '.number-error',
          '.expiration-error',
          '.cvv-error',
          '.name-error',
          '.address-form-error',
          '.address1-error',
          '.city-error',
          '.state-error',
          '.zip-error',
          '.email-error',
          '.phone-error',
          '.card-icon',
          'customer_id',
          'customer_id-error',
        ],
        i = {};
      if (
        PayFields.customizations.hasOwnProperty('style') &&
        Object.getOwnPropertyNames(PayFields.customizations.style).length
      ) {
        for (var n = !0, s = 0; s < e.length; s++)
          PayFields.customizations.style.hasOwnProperty(e[s]) &&
            (i[e[s]] = PayFields.customizations.style[e[s]]);
        l.styles = i;
      }
      PayFields.customizations.hasOwnProperty('placeholders') &&
        Object.getOwnPropertyNames(PayFields.customizations.placeholders)
          .length &&
        ((o = !0), (l.placeholders = PayFields.customizations.placeholders)),
        PayFields.customizations.hasOwnProperty('autoComplete') &&
          ((l.autoComplete = PayFields.customizations.autoComplete),
          (a = l.autoComplete));
      for (s = 0; s < PayFields.fields.length; s++) {
        var t,
          d,
          r = PayFields.jQuery(PayFields.fields[s].element),
          y = document.createElement('iframe');
        y.setAttribute('id', 'payFields-iframe-' + PayFields.fields[s].type),
          'address' === PayFields.fields[s].type
            ? ((t = '&standAloneZip=' + PayFields.config.standAloneZip),
              (d = '&standAloneAddress=' + PayFields.config.standAloneAddress),
              (y.src =
                PayFields.api +
                '/payFields?section=' +
                PayFields.fields[s].type +
                t +
                d))
            : (y.src =
                PayFields.api +
                '/payFields?section=' +
                PayFields.fields[s].type),
          (y.name = 'payFields_' + PayFields.fields[s].type),
          (y.style.display = 'none'),
          (y.style.height = '100%'),
          (y.style.width = '100%'),
          (y.frameBorder = '0'),
          (PayFields.iframes[PayFields.fields[s].type] = y),
          PayFields.fieldsAdded.push(PayFields.fields[s].type),
          n ||
            ('address' === PayFields.fields[s].type
              ? (PayFields.config.standAloneZip &&
                  void 0 !== PayFields.config.standAloneZip) ||
                PayFields.config.standAloneAddress ||
                void 0 !== PayFields.config.standAloneAddress
                ? ((d = 0),
                  PayFields.config.standAloneZip &&
                    ((d += 90), r.css({ height: d + 'px', width: '300px' })),
                  PayFields.config.standAloneAddress &&
                    ((d += 90), r.css({ height: d + 'px', width: '300px' })))
                : r.css({ height: '440px', width: '300px' })
              : r.css({ height: '75px', width: '300px' })),
          r.append(y),
          ((('token' === PayFields.config.mode ||
            'txnToken' === PayFields.config.mode) &&
            PayFields.config.customer) ||
            ('token' !== PayFields.config.mode && PayFields.config.token)) &&
            ('name' === PayFields.fields[s].type
              ? (PayFields.fields[s].values = { name: ' **********' })
              : 'address' === PayFields.fields[s].type &&
                (PayFields.fields[s].values = {
                  address: ' **********',
                  email: ' **********',
                  city: ' ********',
                  zip: ' *****',
                  phone: ' **********',
                  state: ' **',
                })),
          'token' !== PayFields.config.mode &&
            PayFields.config.token &&
            ('number' === PayFields.fields[s].type
              ? (PayFields.fields[s].values = {
                  number: ' **** **** **** ****',
                })
              : 'expiration' === PayFields.fields[s].type &&
                (PayFields.fields[s].values = { expiration: ' ** / **' })),
          (function (s, t) {
            t.onload = function () {
              if (
                PayFields.fields[s].values &&
                'object' == typeof PayFields.fields[s].values
              ) {
                if ('address' === PayFields.fields[s].type)
                  for (var e in PayFields.fields[s].values)
                    Payfields.fields[s].values.hasOwnProperty(e) &&
                      ('object' == typeof Payfields.fields[s].values[e] &&
                        (Payfields.fields[s].values[e] =
                          Payfields.fields[s].values[e][e]),
                      Payfields.fields[s].values[e] &&
                        ('address' === e
                          ? PayFields.customFields.push('address1')
                          : PayFields.customFields.push(e)));
                else Payfields.customFields.push(PayFields.fields[s].type);
                var i = {
                  sender: 'customValues',
                  values: PayFields.fields[s].values,
                };
                PayFields.customizations.hasOwnProperty('optionalFields') &&
                  (i.optionals = PayFields.customizations.optionalFields),
                  t.contentWindow?.postMessage(JSON.stringify(i), t.src);
              }
              (n || o || a) &&
                t.contentWindow?.postMessage(
                  JSON.stringify({
                    sender: 'customizations',
                    customizations: l,
                  }),
                  t.src
                ),
                setTimeout(function () {
                  t.style.display = 'block';
                }, 100);
            };
          })(s, y);
      }
    },
    formatAmount: function (e) {
      return (Number(e) / 100)
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
    appendErrors: function (e) {
      var i,
        s = ['address1', 'city', 'state', 'zip', 'email', 'phone'];
      if (e.hasOwnProperty('field'))
        for (var t in e.field)
          if (e.field.hasOwnProperty(t))
            if (0 <= s.indexOf(t)) {
              if (!window.frames.payFields_address) break;
              var n = (o = PayFields.jQuery('#payFields-iframe-address')).attr(
                'src'
              );
              o.get(0).contentWindow?.postMessage(
                JSON.stringify({
                  sender: 'responseErrors',
                  field: t,
                  errorMsg: e.field[t],
                }),
                n
              );
            } else {
              if (!window.frames['payFields_' + t]) break;
              var o,
                n = (o = PayFields.jQuery('#payFields-iframe-' + t)).attr(
                  'src'
                );
              o.get(0).contentWindow?.postMessage(
                JSON.stringify({
                  sender: 'responseErrors',
                  errorMsg: e.field[t],
                }),
                n
              );
            }
      0 < e.msg.length && console.log(e.msg),
        PayFields.button.element &&
          ((i = PayFields.jQuery(PayFields.button.element)).text('Error'),
          i.css({ backgroundColor: 'red', transition: '2s' })),
        setTimeout(function () {
          var e, i;
          PayFields.button.element &&
            ((e = null),
            PayFields.button.value
              ? (e = PayFields.button.value)
              : 'token' === PayFields.config.mode
              ? (e = 'Save Payment')
              : ((e = 'Purchase'),
                PayFields.config.amount == PayFields.buttonAmount &&
                  (e +=
                    ' $' + PayFields.formatAmount(PayFields.config.amount))),
            (i = PayFields.jQuery(PayFields.button.element)).text(e),
            i.css({ backgroundColor: 'rgb(134, 198, 208)' }),
            i.prop('disabled', !1));
        }, 2e3);
    },
    teardown: function () {
      window.PayFields = undefined;
      window.removeEventListener('message', handlePaymentAttempt);

      const elements = document.getElementsByTagName('iframe');

      while (elements.length) {
        elements[0].parentNode?.removeChild(elements[0]);
      }
      Payfields.appended = false;
    },
    appendIframe: function () {
      if (!PayFields.appended && PayFields.isValid()) {
        var e;
        if (
          (PayFields.button.element &&
            ((e = null),
            PayFields.button.value
              ? (e = PayFields.button.value)
              : 'token' === PayFields.config.mode
              ? (e = 'Save Payment')
              : ((e = 'Purchase'),
                PayFields.config.amount &&
                  ((PayFields.buttonAmount = PayFields.config.amount),
                  (e +=
                    ' $' + PayFields.formatAmount(PayFields.config.amount)))),
            (i = PayFields.jQuery(PayFields.button.element)).text(e),
            i.css({
              textTransform: 'uppercase',
              cursor: 'pointer',
              border: 'none',
              minWidth: '150px',
              outline: 'none',
              height: '30px',
              backgroundColor: 'rgb(134, 198, 208)',
              color: 'rgb(255, 255, 255)',
            })),
          !window?.postMessage || !window.addEventListener)
        )
          return (
            console.log(
              "The browser doesn't support postMessage or addEventListener"
            ),
            void alert(
              "The browser doesn't support postMessage or addEventListener"
            )
          );
        var i = document.createElement('iframe');
        i.setAttribute('id', 'payFields-iframe'),
          (i.src = PayFields.api + '/payFields/?section=main'),
          (i.style.display = 'none'),
          (i.name = 'payFields_main'),
          document.body.appendChild(i),
          PayFields.jQuery('#payFields-iframe')?.on('load', function () {
            var e;
            0 < PayFields.fields.length && PayFields.addFields(),
              PayFields.config.swipe &&
                ((e = document.createElement('iframe')).setAttribute(
                  'id',
                  'payFields-iframe-swiper'
                ),
                (e.src = PayFields.api + '/payFields?section=swiper'),
                (e.name = 'payFields_swiper'),
                (e.frameBorder = '0'),
                e.setAttribute('allowtransparency', 'true'),
                e.setAttribute(
                  'style',
                  'background: rgba(0, 0, 0, 0.00392157); position: fixed; top: 0px; left: 0px; width: 100%; height: 100%; display: none; margin: 0px; padding: 0px; z-index: 10000;'
                ),
                PayFields.jQuery('body').append(e),
                0 < Object.keys(PayFields.swipeStyles).length &&
                  PayFields.jQuery('#payFields-iframe-swiper').css(
                    PayFields.swipeStyles
                  ),
                PayFields.swipeButton.element &&
                  ((e = PayFields.jQuery(PayFields.swipeButton.element)),
                  PayFields.swipeButton.value
                    ? e.text(PayFields.swipeButton.value)
                    : (PayFields.swipeButton.value = 'Swipe'),
                  e.css({
                    textTransform: 'uppercase',
                    cursor: 'pointer',
                    border: 'none',
                    minWidth: '150px',
                    outline: 'none',
                    height: '30px',
                    backgroundColor: 'rgb(134, 198, 208)',
                    color: 'rgb(255, 255, 255)',
                  }),
                  e.on('click', PayFields.swipePopup))),
              (Payfields.appended = !0),
              PayFields.jQuery(PayFields.button.element).on(
                'click',
                PayFields.submit
              );
          }),
          window.addEventListener('message', handlePaymentAttempt);
      }
    },
  },
  Payfields = PayFields;

export function usePayrixFields(): PayrixFields {
  window.PayFields = PayFields;
  return Payfields;
}

export default usePayrixFields;
