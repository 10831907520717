import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type UpdateAppointmentCustomFormsMutationVariables = Types.Exact<{
  objects: Array<Types.AppointmentCustomForm_Insert_Input> | Types.AppointmentCustomForm_Insert_Input;
}>;


export type UpdateAppointmentCustomFormsMutation = { __typename?: 'mutation_root', insert_appointmentCustomForm?: { __typename?: 'appointmentCustomForm_mutation_response', affected_rows: number } | null | undefined };


export const UpdateAppointmentCustomFormsDocument = gql`
    mutation updateAppointmentCustomForms($objects: [appointmentCustomForm_insert_input!]!) {
  insert_appointmentCustomForm(
    objects: $objects
    on_conflict: {constraint: appointmentCustomForm_appointmentId_customFormId_key, update_columns: [manuallyAdded, manuallyRemoved]}
  ) {
    affected_rows
  }
}
    `;
export type UpdateAppointmentCustomFormsMutationFn = Apollo.MutationFunction<UpdateAppointmentCustomFormsMutation, UpdateAppointmentCustomFormsMutationVariables>;

/**
 * __useUpdateAppointmentCustomFormsMutation__
 *
 * To run a mutation, you first call `useUpdateAppointmentCustomFormsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAppointmentCustomFormsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAppointmentCustomFormsMutation, { data, loading, error }] = useUpdateAppointmentCustomFormsMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useUpdateAppointmentCustomFormsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAppointmentCustomFormsMutation, UpdateAppointmentCustomFormsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAppointmentCustomFormsMutation, UpdateAppointmentCustomFormsMutationVariables>(UpdateAppointmentCustomFormsDocument, options);
      }
export type UpdateAppointmentCustomFormsMutationHookResult = ReturnType<typeof useUpdateAppointmentCustomFormsMutation>;
export type UpdateAppointmentCustomFormsMutationResult = Apollo.MutationResult<UpdateAppointmentCustomFormsMutation>;
export type UpdateAppointmentCustomFormsMutationOptions = Apollo.BaseMutationOptions<UpdateAppointmentCustomFormsMutation, UpdateAppointmentCustomFormsMutationVariables>;