import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { FlowsheetParameterFieldsMinimalFragmentDoc } from '../../fragments/flowsheet/FlowsheetParameterFieldsMinimal.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type InsertPatientFlowsheetRowMutationVariables = Types.Exact<{
  object: Types.PatientFlowsheetRow_Insert_Input;
}>;


export type InsertPatientFlowsheetRowMutation = { __typename?: 'mutation_root', insert_patientFlowsheetRow_one?: { __typename?: 'patientFlowsheetRow', id: any, flowsheetId: any, recordedAt: any, data: Array<{ __typename?: 'patientFlowsheetData', recordedAt: any, parameter: { __typename?: 'flowsheetParameter', apiKey: string, id: any, isSystemParameter: boolean, title: string, type: string, unit: string }, patient: { __typename?: 'patientWorkspace', firstName?: string | null | undefined, id: any, lastName?: string | null | undefined } }> } | null | undefined };


export const InsertPatientFlowsheetRowDocument = gql`
    mutation InsertPatientFlowsheetRow($object: patientFlowsheetRow_insert_input!) {
  insert_patientFlowsheetRow_one(object: $object) {
    data {
      recordedAt
      parameter {
        ...FlowsheetParameterFieldsMinimal
      }
      patient {
        firstName
        id
        lastName
      }
    }
    id
    flowsheetId
    recordedAt
  }
}
    ${FlowsheetParameterFieldsMinimalFragmentDoc}`;
export type InsertPatientFlowsheetRowMutationFn = Apollo.MutationFunction<InsertPatientFlowsheetRowMutation, InsertPatientFlowsheetRowMutationVariables>;

/**
 * __useInsertPatientFlowsheetRowMutation__
 *
 * To run a mutation, you first call `useInsertPatientFlowsheetRowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertPatientFlowsheetRowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertPatientFlowsheetRowMutation, { data, loading, error }] = useInsertPatientFlowsheetRowMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertPatientFlowsheetRowMutation(baseOptions?: Apollo.MutationHookOptions<InsertPatientFlowsheetRowMutation, InsertPatientFlowsheetRowMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertPatientFlowsheetRowMutation, InsertPatientFlowsheetRowMutationVariables>(InsertPatientFlowsheetRowDocument, options);
      }
export type InsertPatientFlowsheetRowMutationHookResult = ReturnType<typeof useInsertPatientFlowsheetRowMutation>;
export type InsertPatientFlowsheetRowMutationResult = Apollo.MutationResult<InsertPatientFlowsheetRowMutation>;
export type InsertPatientFlowsheetRowMutationOptions = Apollo.BaseMutationOptions<InsertPatientFlowsheetRowMutation, InsertPatientFlowsheetRowMutationVariables>;