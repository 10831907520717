import { HStack, IconButton, Text, TextProps, Tooltip } from '@chakra-ui/react';
import { useCopyToClipboard } from 'usehooks-ts';
import { useEffect } from 'react';
import toast from 'react-hot-toast';
import { BiChat } from 'react-icons/bi';
import { Link } from 'react-router-dom';

/* eslint-disable-next-line */
export interface PhoneNumberTextWrapperProps {
  phoneNumber: string;
  patientId: string;
  textStyle?: TextProps;
}

export function PhoneNumberTextWrapper({
  phoneNumber,
  patientId,
  textStyle = {},
}: PhoneNumberTextWrapperProps) {
  const [clipboardValue, copy] = useCopyToClipboard();

  useEffect(() => {
    if (clipboardValue) {
      toast.success(`Copied "${clipboardValue}" to clipboard!`);
    }
  }, [clipboardValue]);

  return (
    <HStack key={phoneNumber}>
      <Text
        w="max-content"
        cursor={'pointer'}
        _hover={{ textDecoration: 'underline' }}
        onClick={() => copy(phoneNumber)}
        {...textStyle}
      >
        {phoneNumber}
      </Text>

      <Link to={`/patients/${patientId}/conversations`}>
        <IconButton
          size="xs"
          variant={'outline'}
          icon={<BiChat />}
          aria-label="go to chat"
        />
      </Link>
    </HStack>
  );
}

export default PhoneNumberTextWrapper;
