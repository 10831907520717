import { Box } from '@chakra-ui/react';
import { ChangeEvent } from 'react';
import DEFAULT_CONTROL_STYLE from './styles/DefaultControlStyle';
import DEFAULT_INPUT_CONTAINER_STYLE from './styles/DefaultInputContainerStyle';
import { TextEditorInputProps } from './types';
import TextEditor from '../TextEditor/TextEditor';

export function TextEditorInput({
  name,
  onChange,
  placeholder,
  styles = {},
  value,
  ...props
}: Omit<TextEditorInputProps, 'type'>) {
  const inputStyles = { ...DEFAULT_CONTROL_STYLE, ...styles.control };

  const containerStyles = {
    ...DEFAULT_INPUT_CONTAINER_STYLE,
    ...styles.inputContainer,
  };

  function handleChange(content: string) {
    onChange(content);
  }

  return (
    <Box {...containerStyles}>
      <TextEditor
        value={value}
        onChange={handleChange}
        {...inputStyles}
        {...props}
      />
    </Box>
  );
}

export default TextEditorInput;
