import { useEffect, useState, forwardRef } from 'react';
import { useCombobox } from 'downshift';
import { Input, List, ListItem, Flex, Box } from '@chakra-ui/react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ComboboxLoadFunction } from '@webapp/types';

type LIProps = JSX.IntrinsicElements['li'];

type ULProps = JSX.IntrinsicElements['ul'];

const ComboboxInput = forwardRef<HTMLInputElement>(({ ...props }, ref) => (
  <Input {...props} ref={ref} />
));

const ComboboxList = forwardRef<
  HTMLUListElement,
  ULProps & { isOpen: boolean }
>(
  ({ isOpen, ...props }, ref): JSX.Element => (
    <List display={isOpen ? undefined : 'none'} {...props} ref={ref} />
  )
);

const ComboboxItem = forwardRef<
  HTMLLIElement,
  LIProps & { itemIndex: number; highlightedIndex: number }
>(({ itemIndex, highlightedIndex, ...props }, ref): JSX.Element => {
  const isActive = itemIndex === highlightedIndex;

  return (
    <ListItem
      transition="background-color 220ms, color 220ms"
      bg={isActive ? 'teal.100' : 'inherit'}
      px={2}
      py={1}
      borderBottom="1px solid rgba(0,0,0,0.01)"
      cursor="pointer"
      ref={ref}
      {...props}
    />
  );
});

export interface ComboboxProps {
  loadFunction: ComboboxLoadFunction;
  filterKey: string;
}

export const SearchBar = ({
  loadFunction,
  filterKey,
}: ComboboxProps): JSX.Element => {
  const location = useLocation();
  const navigate = useNavigate();
  const searchParameters = new URLSearchParams(location.search);
  const query = searchParameters.get(filterKey);

  const [selectedItem, setSelectedItem] = useState(query ?? null);

  const [inputItems, setInputItems] = loadFunction();
  const {
    isOpen,
    setInputValue,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    highlightedIndex,
    getItemProps,
  } = useCombobox({
    items: inputItems,
    selectedItem,
    onSelectedItemChange: ({ selectedItem }) => {
      if (selectedItem) {
        searchParameters.set(filterKey, selectedItem);
        searchParameters.set('page', '1');
        setSelectedItem(selectedItem);
        navigate({
          search: searchParameters.toString(),
        });
      }
    },
    onInputValueChange: ({ inputValue }) => {
      if (inputValue) {
        setInputItems(inputValue);
      } else {
        searchParameters.delete(filterKey);
        setSelectedItem(null);
        navigate({
          search: searchParameters.toString(),
        });
      }
    },
  });

  useEffect(() => {
    if (query) {
      setInputValue(query);
    }
  }, []);

  return (
    <Flex direction="column" align="center">
      <Flex {...getComboboxProps()} direction="column" flex="1 1 auto">
        <Flex direction="row" alignItems="baseline">
          <ComboboxInput
            {...getInputProps()}
            placeholder="Search..."
            flex="0 0 auto"
            width={500}
            mt={3}
          />
        </Flex>
        <Box>
          <ComboboxList
            isOpen={isOpen}
            {...getMenuProps()}
            position="absolute"
            zIndex={3}
            width={500}
            bg="white"
            borderRadius="4px"
            border={isOpen && '1px solid rgba(0,0,0,0.1)'}
            boxShadow="6px 5px 8px rgba(0,50,30,0.02)"
          >
            {inputItems.map((item, index) => (
              <ComboboxItem
                {...getItemProps({ item, index })}
                itemIndex={index}
                highlightedIndex={highlightedIndex}
                key={index}
              >
                {item}
              </ComboboxItem>
            ))}
          </ComboboxList>
        </Box>
      </Flex>
    </Flex>
  );
};

export default SearchBar;
