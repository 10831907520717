import { Button, ButtonGroup, Flex, Grid, Icon, Text } from '@chakra-ui/react';
import { useSendPatientAuthLinkLazyQuery } from '@webapp/graphql';
import { useStores } from '@webapp/state-models';
import {
  AppointmentStepAppointment,
  CompletedSelectOptionAndDocumentId,
} from '@webapp/types';
import { DataTable, FormInput } from '@webapp/ui';
import { useLoadCustomForms } from '@webapp/webapp/hooks';
import { useFlagsmith } from 'flagsmith-react';
import { pick } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import toast from 'react-hot-toast';
import { HiTrash } from 'react-icons/hi';
import { MdSend } from 'react-icons/md';
import { RiAddBoxLine } from 'react-icons/ri';
import { useNavigate } from 'react-router-dom';
import { Cell, Column } from 'react-table';
import { useUpdateEffect } from 'react-use';
import PromptModal from '../PromptModal/PromptModal';
import { FormModal } from './formModal';

interface CustomFormsFormProps {
  isEditable: boolean;
  onToggle: () => void;
  appointment: AppointmentStepAppointment;
  mini?: boolean;
  finishedSubmitting?: boolean;
  setFinishedSubmitting?: (finishedSubmitting: boolean) => void;
}

export const CustomFormsForm = ({
  isEditable,
  onToggle,
  appointment,
  mini,
  finishedSubmitting,
  setFinishedSubmitting,
}: CustomFormsFormProps) => {
  const { hasFeature } = useFlagsmith();

  const { workspace } = useStores();

  const parsedWorkspace = JSON.parse(JSON.stringify(workspace));

  const { workspaceConfiguration } = parsedWorkspace;

  const completeAppointmentCopy = pick(
    workspaceConfiguration?.completeAppointmentCopy,
    ['emailBody', 'smsBody', 'subject']
  );

  const [formIdToSend, setFormIdToSend] = useState<string | null>(null);
  const { control, getValues, reset, setValue } = useFormContext();
  const navigate = useNavigate();
  const [formModalOpen, setFormModalOpen] = useState(false);
  const [currentForm, setCurrentForm] = useState<{
    id: string;
    title: string;
  } | null>(null);

  function clearFormIdToSend() {
    setFormIdToSend(null);
  }

  const [sendAuthLink, { loading }] = useSendPatientAuthLinkLazyQuery({
    onCompleted: () => {
      toast.success('Link sent');
      clearFormIdToSend();
    },
    onError: (e) => {
      toast.error(e.message);
    },
  });

  const [indexAndItem, setIndexAndItem] = useState<{
    index: number;
    item: CompletedSelectOptionAndDocumentId;
  } | null>(null);

  const closeFormModal = useCallback((): void => {
    setIndexAndItem(null);
    setCurrentForm(null);
    setFormModalOpen(false);
  }, [setIndexAndItem, setCurrentForm, setFormModalOpen]);

  const setFormAndOpenModal = useCallback(
    (form: { id: string; title: string }): void => {
      setCurrentForm(form);
      setFormModalOpen(true);
    },
    [setCurrentForm, setFormModalOpen]
  );

  const { append, fields, remove, update, replace } = useFieldArray({
    control,
    name: 'customForms',
  });

  const getValue = useCallback(
    (id: string): CompletedSelectOptionAndDocumentId | undefined => {
      const index = fields.findIndex((field) => field.id === id);
      const name = `customForms.${index}`;
      return getValues(name);
    },
    [fields, getValues]
  );

  const handleCancelClick = useCallback((): void => {
    reset();
    onToggle();
  }, [reset, onToggle]);

  useEffect(() => {
    if (finishedSubmitting && setFinishedSubmitting) {
      setValue('customFormsToDelete', []);
      setFinishedSubmitting(false);
    }
  }, [finishedSubmitting, setFinishedSubmitting, setValue]);

  useUpdateEffect(() => {
    const mapped = appointment.resolvedAppointmentCustomForms.customForms.map(
      (customForm) => ({
        label: customForm.title,
        value: customForm.id,
        documentId: customForm.documentId,
        completed: customForm.completed,
      })
    );

    replace(mapped);
  }, [
    appointment.resolvedAppointmentCustomForms,
    appointment?.resolvedAppointmentCustomForms?.customForms?.length,
  ]);

  const columns = useMemo(() => {
    const c: Column<Record<'id', string>>[] = [
      {
        accessor: (originalRow: Record<'id', string>) =>
          getValue(originalRow.id)?.label,

        Cell: (originalRow: Cell<Record<'id', string>>) => {
          const index = fields.findIndex(
            (el) => el.id === originalRow.row.original.id
          );

          const name = `customForms.${index}`;

          const fieldValue = getValue(originalRow.row.original.id);

          if (!fieldValue) return null;

          const { value, label, completed, documentId } = fieldValue;

          if (isEditable && !completed) {
            return (
              <FormInput
                isEditable={isEditable}
                name={name}
                placeholder="Add Consent"
                style={{
                  minWidth: '200px',
                }}
                selectProps={{
                  defaultOption: getValues(name),
                  loadOptions: useLoadCustomForms,
                }}
                type="async-select"
              />
            );
          }

          return (
            <Button
              variant="link"
              colorScheme={completed ? 'teal' : 'red'}
              onClick={() => {
                if (completed) {
                  const { patientId } = appointment;

                  const link = documentId
                    ? `/patients/${patientId}/documents/${documentId}`
                    : `/patients/${patientId}/appointments/${appointment.id}/forms/${value}`;

                  navigate(link);
                } else {
                  /* TODO: No Shadowing - Fix this */
                  const xIndex = fields.findIndex(
                    (el) => el.id === originalRow.row.original.id
                  );

                  const item = getValue(originalRow.row.original.id);

                  if (!item) return;

                  setIndexAndItem({
                    index: xIndex,
                    item,
                  });

                  setFormAndOpenModal({
                    id: value as string,
                    title: label,
                  });
                }
              }}
            >
              {label}
            </Button>
          );
        },
        disableSortBy: true,
        defaultCanSort: false,
        Header: 'Form',
        id: 'label',
      },
    ];

    if (hasFeature('send-patient-link') && !isEditable) {
      c.push({
        accessor: (originalRow: Record<'id', string>) =>
          getValue(originalRow.id)?.value,
        Cell: (row) => (
          <Button
            display="flex"
            alignItems="center"
            height="unset"
            minHeight="unset"
            onClick={() => setFormIdToSend(row.value)}
            padding="5px 15px"
          >
            <Icon as={MdSend} mr={2} />
            Send to Patient
          </Button>
        ),
        Header: '',
        id: 'formId',
      });
    }

    if (!mini) {
      c.push({
        disableSortBy: true,
        defaultCanSort: false,
        Header: 'Status',
        id: 'completed',
        accessor: (originalRow: Record<'id', string>) =>
          getValue(originalRow.id)?.value,

        Cell: (originalRow: Cell<Record<'id', string>>) => {
          const fieldValue = getValue(originalRow.row.original.id);

          if (!fieldValue) return null;

          return (
            <Text>{fieldValue.completed ? 'Complete' : 'Incomplete'}</Text>
          );
        },
      });
      c.push({
        id: 'actions',
        accessor: (originalRow: Record<'id', string>) =>
          getValue(originalRow.id)?.value,
        Cell: (originalRow: Cell<Record<'id', string>>) => {
          const fieldValue = getValue(originalRow.row.original.id);

          if (!fieldValue) return null;

          const { completed } = fieldValue;

          if (isEditable && !completed) {
            const index = fields.findIndex(
              (el) => el.id === originalRow.row.original.id
            );

            const name = `customForms.${index}`;

            return (
              <Flex width="100%" justifyContent="flex-end">
                <Button
                  colorScheme="red"
                  onClick={() => {
                    const [element, customFormsToDelete] = getValues([
                      name,
                      'customFormsToDelete',
                    ]);

                    remove(index);

                    if (element?.value) {
                      setValue('customFormsToDelete', [
                        ...customFormsToDelete,
                        element,
                      ]);
                    }
                  }}
                  variant="ghost"
                >
                  <Icon as={HiTrash} />
                </Button>
              </Flex>
            );
          }

          return '';
        },
        disableSortBy: true,
        defaultCanSort: false,
        Header: '',
      });
    }

    return c;
  }, [
    mini,
    getValue,
    fields,
    isEditable,
    getValues,
    appointment,
    navigate,
    setFormAndOpenModal,
    remove,
    setValue,
    appointment?.patientId,
    appointment?.resolvedAppointmentCustomForms?.customForms?.length,
  ]);

  return (
    <Grid>
      <DataTable
        key={`${appointment.id}-custom-forms-${appointment?.resolvedAppointmentCustomForms?.customForms?.length}`}
        columns={columns}
        customStyles={{
          borderColor: '#CFEBFF',
          cell: {
            color: '#6C6C72',
          },
          table: {
            borderRadius: '8px',
            minHeight: 'unset',
          },
          tableHeader: {
            background: '#EAFCFF',
            color: '#525257',
            textTransform: 'uppercase',
          },
        }}
        data={fields}
        shouldDisplayPagination={false}
      />
      {isEditable && (
        <Grid>
          <Button
            color="teal.500"
            justifyContent="start"
            leftIcon={<Icon as={RiAddBoxLine} color="teal.500" />}
            margin="15px 0 0"
            onClick={() =>
              append({ label: '', value: '', documentId: '', completed: false })
            }
            textAlign="left"
            variant="ghost"
            width="max-content"
          >
            Add New Form
          </Button>
        </Grid>
      )}
      {isEditable && (
        <ButtonGroup margin="30px 0 0">
          <Button type="submit" colorScheme="teal">
            Save Changes
          </Button>
          <Button
            colorScheme="teal"
            onClick={handleCancelClick}
            variant="outline"
          >
            Cancel
          </Button>
        </ButtonGroup>
      )}
      <FormModal
        indexAndItem={indexAndItem}
        update={update}
        appointmentId={appointment.id}
        isOpen={formModalOpen}
        patientIdFromProps={appointment.patientId}
        onClose={closeFormModal}
        form={currentForm}
      />
      <PromptModal
        bodyText="Are you sure you want to send this information to the patient? This will send an email and SMS to the patient with a link to the form."
        confirmText="Send"
        headerText="Send Patient Form"
        isLoading={loading}
        isOpen={Boolean(formIdToSend)}
        onClose={clearFormIdToSend}
        onConfirm={() => {
          sendAuthLink({
            variables: {
              copy: completeAppointmentCopy,
              locationId: appointment.locationId,
              patientId: appointment.patientId,
              to: `appointments/${appointment.id}/forms/${formIdToSend}`,
            },
          });
        }}
      />
    </Grid>
  );
};

export default CustomFormsForm;
