import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type PurchaseGiftCardMutationVariables = Types.Exact<{
  amount: Types.Scalars['Int'];
  cardData?: Types.Maybe<Types.CardInformation>;
  cardHolderDetails?: Types.Maybe<Types.CardHolderDetailsInput>;
  fraudSessionId?: Types.Maybe<Types.Scalars['String']>;
  locationId?: Types.Maybe<Types.Scalars['String']>;
  payrixTransactionId?: Types.Maybe<Types.Scalars['String']>;
  purchaserId: Types.Scalars['String'];
  receiverId: Types.Scalars['String'];
  token?: Types.Maybe<Types.Scalars['String']>;
  workspaceId: Types.Scalars['uuid'];
}>;


export type PurchaseGiftCardMutation = { __typename?: 'mutation_root', purchaseGiftCard?: { __typename?: 'SuccessAnswer', success: boolean } | null | undefined };


export const PurchaseGiftCardDocument = gql`
    mutation PurchaseGiftCard($amount: Int!, $cardData: CardInformation, $cardHolderDetails: CardHolderDetailsInput, $fraudSessionId: String, $locationId: String, $payrixTransactionId: String, $purchaserId: String!, $receiverId: String!, $token: String, $workspaceId: uuid!) {
  purchaseGiftCard(
    amount: $amount
    cardData: $cardData
    cardHolderDetails: $cardHolderDetails
    fraudSessionId: $fraudSessionId
    locationId: $locationId
    payrixTransactionId: $payrixTransactionId
    purchaserId: $purchaserId
    receiverId: $receiverId
    token: $token
    workspaceId: $workspaceId
  ) {
    success
  }
}
    `;
export type PurchaseGiftCardMutationFn = Apollo.MutationFunction<PurchaseGiftCardMutation, PurchaseGiftCardMutationVariables>;

/**
 * __usePurchaseGiftCardMutation__
 *
 * To run a mutation, you first call `usePurchaseGiftCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePurchaseGiftCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [purchaseGiftCardMutation, { data, loading, error }] = usePurchaseGiftCardMutation({
 *   variables: {
 *      amount: // value for 'amount'
 *      cardData: // value for 'cardData'
 *      cardHolderDetails: // value for 'cardHolderDetails'
 *      fraudSessionId: // value for 'fraudSessionId'
 *      locationId: // value for 'locationId'
 *      payrixTransactionId: // value for 'payrixTransactionId'
 *      purchaserId: // value for 'purchaserId'
 *      receiverId: // value for 'receiverId'
 *      token: // value for 'token'
 *      workspaceId: // value for 'workspaceId'
 *   },
 * });
 */
export function usePurchaseGiftCardMutation(baseOptions?: Apollo.MutationHookOptions<PurchaseGiftCardMutation, PurchaseGiftCardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PurchaseGiftCardMutation, PurchaseGiftCardMutationVariables>(PurchaseGiftCardDocument, options);
      }
export type PurchaseGiftCardMutationHookResult = ReturnType<typeof usePurchaseGiftCardMutation>;
export type PurchaseGiftCardMutationResult = Apollo.MutationResult<PurchaseGiftCardMutation>;
export type PurchaseGiftCardMutationOptions = Apollo.BaseMutationOptions<PurchaseGiftCardMutation, PurchaseGiftCardMutationVariables>;