import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { PromotionFieldsFragmentDoc } from '../../fragments/promotionFields.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetPromotionsQueryVariables = Types.Exact<{
  where?: Types.Maybe<Types.Promotion_Bool_Exp>;
  limit?: Types.Maybe<Types.Scalars['Int']>;
  offset?: Types.Maybe<Types.Scalars['Int']>;
  orderBy?: Types.Maybe<Array<Types.Promotion_Order_By> | Types.Promotion_Order_By>;
}>;


export type GetPromotionsQuery = { __typename?: 'query_root', promotion: Array<{ __typename?: 'promotion', id: any, createdAt: any, updatedAt: any, title: string, description: string, isActive: boolean, isArchived: boolean, startsAt?: any | null | undefined, endsAt?: any | null | undefined, image?: string | null | undefined, workspaceId: any, locationId?: any | null | undefined, location?: { __typename?: 'location', id: any, name: string } | null | undefined, promotionServices: Array<{ __typename?: 'promotionServices', id: any, serviceId: any, discountAmount: any, discountType: string, quantity?: number | null | undefined, service: { __typename?: 'service', id: any, name: string } }> }>, promotion_aggregate: { __typename?: 'promotion_aggregate', aggregate?: { __typename?: 'promotion_aggregate_fields', count: number } | null | undefined } };


export const GetPromotionsDocument = gql`
    query GetPromotions($where: promotion_bool_exp, $limit: Int, $offset: Int, $orderBy: [promotion_order_by!]) {
  promotion(where: $where, limit: $limit, offset: $offset, order_by: $orderBy) {
    ...PromotionFields
  }
  promotion_aggregate(where: $where) {
    aggregate {
      count
    }
  }
}
    ${PromotionFieldsFragmentDoc}`;

/**
 * __useGetPromotionsQuery__
 *
 * To run a query within a React component, call `useGetPromotionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPromotionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPromotionsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetPromotionsQuery(baseOptions?: Apollo.QueryHookOptions<GetPromotionsQuery, GetPromotionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPromotionsQuery, GetPromotionsQueryVariables>(GetPromotionsDocument, options);
      }
export function useGetPromotionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPromotionsQuery, GetPromotionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPromotionsQuery, GetPromotionsQueryVariables>(GetPromotionsDocument, options);
        }
export type GetPromotionsQueryHookResult = ReturnType<typeof useGetPromotionsQuery>;
export type GetPromotionsLazyQueryHookResult = ReturnType<typeof useGetPromotionsLazyQuery>;
export type GetPromotionsQueryResult = Apollo.QueryResult<GetPromotionsQuery, GetPromotionsQueryVariables>;