/* eslint-disable max-classes-per-file */
import { createElement } from 'react';
import { SliderPicker, SketchPicker, CompactPicker } from 'react-color';
import { ElementFactory, Question, Serializer, SvgRegistry } from 'survey-core';
import {
  SurveyQuestionElementBase,
  ReactQuestionFactory,
} from 'survey-react-ui';
import { PropertyGridEditorCollection } from 'survey-creator-react';
import { localization } from 'survey-creator-core';
import ReactDOMServer from 'react-dom/server';
import WrappedCustomMediaConsent from './WrappedCustomMediaConsent';

const CUSTOM_TYPE = 'custom-media-consent';

// A model for the new question type
export class CustomMediaConsentModel extends Question {
  getType() {
    return CUSTOM_TYPE;
  }

  get consentText() {
    return this.getPropertyValue('consentText');
  }

  set consentText(val) {
    this.setPropertyValue('consentText', val);
  }

  get consentOptions() {
    return this.getPropertyValue('consentOptions');
  }

  set consentOptions(val) {
    this.setPropertyValue('consentOptions', val);
  }
}

// Register `CustomMediaConsentModel` as a model for the `custom-media-consent` type
export function registerCustomMediaConsent() {
  ElementFactory.Instance.registerElement(
    CUSTOM_TYPE,
    (name) => new CustomMediaConsentModel(name)
  );
}

// Specify display names for the question type and its properties
const locale = localization.getLocale('');
locale.qt[CUSTOM_TYPE] = 'Custom Media Consent';

// Register an SVG icon for the question type
// const svg = ReactDOMServer.renderToString(<ColorPickerIcon />);
// SvgRegistry.registerIconFromSvg(CUSTOM_TYPE, svg);

// Add question type metadata for further serialization into JSON
Serializer.addClass(
  CUSTOM_TYPE,
  [
    {
      name: 'consentText',
      default: 'I consent to the use of my media',
    },
    {
      name: 'consentOptions',
      default: [],
    },
  ],
  () => new CustomMediaConsentModel(''),
  'question'
);

// A class that renders questions of the new type in the UI
export class SurveyQuestionCustomMediaConsent extends SurveyQuestionElementBase {
  constructor(props) {
    super(props);
    this.state = {
      value: this.question.value,
      consentText: this.question.consentText,
      consentOptions: this.question.consentOptions,
    };
  }

  get question() {
    return this.questionBase;
  }

  get value() {
    return this.question.value;
  }

  onValueChange = (v: string) => {
    this.question.value = v;
  };

  renderElement() {
    return (
      <WrappedCustomMediaConsent
        question={this.question}
        isDesignMode={this.question.isDesignMode}
        onValueChange={this.onValueChange}
        creator={this.creator}
      />
    );
  }
}

// Register `SurveyQuestionColorPicker` as a class that renders `custom-media-consent` questions
ReactQuestionFactory.Instance.registerQuestion(CUSTOM_TYPE, (props) =>
  createElement(SurveyQuestionCustomMediaConsent, props)
);

// Register the `custom-media-consent` as an editor for properties of the `color` type in the Survey Creator's Property Grid
// PropertyGridEditorCollection.register({
//   fit(prop) {
//     return prop.type === 'color';
//   },
//   getJSON() {
//     return {
//       type: CUSTOM_TYPE,
//       colorPickerType: 'Compact',
//     };
//   },
// });
