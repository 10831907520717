import { RootStoreModel, RootStore } from "./root-store"

/**
 * Setup the root state.
 */
export async function setupRootStore() {
  let rootStore: RootStore

  rootStore = RootStoreModel.create({})

  return rootStore
}
