import * as Types from '../../generated/graphql';

import { gql } from '@apollo/client';
export type MediaTagFieldsFragment = { __typename?: 'mediaTag', id: any, title: string };

export const MediaTagFieldsFragmentDoc = gql`
    fragment MediaTagFields on mediaTag {
  id
  title
}
    `;