import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
export type CompensationStrategyFieldsFullFragment = { __typename?: 'compensationStrategy', createdAt: any, id: any, isActive: boolean, name: string, strategyType: string, updatedAt: any, categories: Array<{ __typename?: 'compensationStrategyTierCategory', id: any, serviceCategory: { __typename?: 'serviceCategory', id: any, title: string } }>, commissions: Array<{ __typename?: 'compensationStrategyCommission', compensationAmount: number, compensationType: string, id: any, maxApplyValue: number, minApplyValue: number, membership?: { __typename?: 'membership', id: any, name: string } | null | undefined, package?: { __typename?: 'package', id: any, name: string } | null | undefined, service?: { __typename?: 'service', id: any, name: string } | null | undefined }>, exemptions: Array<{ __typename?: 'compensationStrategyTierExemption', id: any, membership?: { __typename?: 'membership', id: any, name: string } | null | undefined, package?: { __typename?: 'package', id: any, name: string } | null | undefined, service?: { __typename?: 'service', id: any, name: string } | null | undefined }>, inclusions: Array<{ __typename?: 'compensationStrategyTierInclusion', id: any, membership?: { __typename?: 'membership', id: any, name: string } | null | undefined, package?: { __typename?: 'package', id: any, name: string } | null | undefined, service?: { __typename?: 'service', id: any, name: string } | null | undefined }>, tiers: Array<{ __typename?: 'compensationStrategyTier', compensationAmount: number, compensationType: string, id: any, maxApplyValue: number, minApplyValue: number, name: string }> };

export const CompensationStrategyFieldsFullFragmentDoc = gql`
    fragment CompensationStrategyFieldsFull on compensationStrategy {
  categories {
    id
    serviceCategory {
      id
      title
    }
  }
  commissions {
    compensationAmount
    compensationType
    id
    maxApplyValue
    membership {
      id
      name
    }
    minApplyValue
    package {
      id
      name
    }
    service {
      id
      name
    }
  }
  createdAt
  exemptions {
    id
    membership {
      id
      name
    }
    package {
      id
      name
    }
    service {
      id
      name
    }
  }
  inclusions {
    id
    membership {
      id
      name
    }
    package {
      id
      name
    }
    service {
      id
      name
    }
  }
  tiers {
    compensationAmount
    compensationType
    id
    maxApplyValue
    minApplyValue
    name
  }
  id
  isActive
  name
  strategyType
  updatedAt
}
    `;