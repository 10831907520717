/* eslint-disable import/prefer-default-export */
import {
  Box,
  Grid,
  GridItem,
  Heading,
  Input,
  Spacer,
  RadioGroup,
  Radio,
  Stack,
} from '@chakra-ui/react';
import { useStores } from '@webapp/state-models';
import { observer } from 'mobx-react-lite';

export const AudienceDetail = observer(() => {
  const { audience } = useStores();
  return (
    <Stack spacing="1">
      <Heading margin="5px 0" size="sm" width="100%">
        Audience name
      </Heading>
      <Grid gridTemplateColumns="2fr 3fr">
        <GridItem>
          <Input
            placeholder="e.g. Botox customers"
            defaultValue={audience.name || ''}
            size="md"
            onChange={(e) => audience.setName(e.target.value)}
          />
        </GridItem>
        <GridItem>
          <Spacer></Spacer>
        </GridItem>
      </Grid>
      <RadioGroup
        value={audience.type}
        onChange={(val: string) => audience.setType(val)}
      >
        <Grid py="5" gridTemplateColumns="0fr 2fr 3fr">
          <GridItem>
            <Radio
              colorScheme="teal"
              size="md"
              name="active"
              value="active"
            ></Radio>
          </GridItem>
          <GridItem>
            <Heading size="sm" px="2">
              Active audience
            </Heading>
          </GridItem>
          <GridItem maxWidth="md"></GridItem>
          <GridItem></GridItem>
          <GridItem>
            <Box px="2" fontSize="xs">
              Active audiences will update automatically, patients will leave or
              join the list as their properties change.
            </Box>
          </GridItem>
        </Grid>
        <Grid gridTemplateColumns="0fr 2fr 3fr">
          <GridItem>
            <Radio
              colorScheme="teal"
              size="md"
              name="static"
              value="static"
            ></Radio>
          </GridItem>
          <GridItem>
            <Heading size="sm" px="2">
              Static audience
            </Heading>
          </GridItem>
          <GridItem>
            <Spacer></Spacer>
          </GridItem>
          <GridItem></GridItem>
          <GridItem>
            <Box px="2" fontSize="xs">
              Static audiences will not automatically update, these are usually
              best for one-off messages.
            </Box>
          </GridItem>
        </Grid>
      </RadioGroup>
    </Stack>
  );
});
