import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { PatientConversationFieldsFragmentDoc } from '../../fragments/patientConversationFields.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetOrCreateTwilioConverssationMutationVariables = Types.Exact<{
  patientId: Types.Scalars['uuid'];
}>;


export type GetOrCreateTwilioConverssationMutation = { __typename?: 'mutation_root', getOrCreateTwilioConverssation?: { __typename?: 'GetOrCreateTwilioConversationResponse', success: boolean, patientConversation?: { __typename?: 'patientConversation', id: any, category?: string | null | undefined, patientId: any, patientWorkspaceId: any, lastPatientMessageReceived?: any | null | undefined, lastUserMessageSent?: any | null | undefined, hasUnreadMessages?: boolean | null | undefined, twilioConversationSid: string, participantIdentityOverride?: string | null | undefined, patientWorkspace: { __typename?: 'patientWorkspace', id: any, firstName?: string | null | undefined, lastName?: string | null | undefined, phoneNumber?: string | null | undefined } } | null | undefined } | null | undefined };


export const GetOrCreateTwilioConverssationDocument = gql`
    mutation getOrCreateTwilioConverssation($patientId: uuid!) {
  getOrCreateTwilioConverssation(patientId: $patientId) {
    success
    patientConversation {
      ...PatientConversationFields
    }
  }
}
    ${PatientConversationFieldsFragmentDoc}`;
export type GetOrCreateTwilioConverssationMutationFn = Apollo.MutationFunction<GetOrCreateTwilioConverssationMutation, GetOrCreateTwilioConverssationMutationVariables>;

/**
 * __useGetOrCreateTwilioConverssationMutation__
 *
 * To run a mutation, you first call `useGetOrCreateTwilioConverssationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetOrCreateTwilioConverssationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getOrCreateTwilioConverssationMutation, { data, loading, error }] = useGetOrCreateTwilioConverssationMutation({
 *   variables: {
 *      patientId: // value for 'patientId'
 *   },
 * });
 */
export function useGetOrCreateTwilioConverssationMutation(baseOptions?: Apollo.MutationHookOptions<GetOrCreateTwilioConverssationMutation, GetOrCreateTwilioConverssationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GetOrCreateTwilioConverssationMutation, GetOrCreateTwilioConverssationMutationVariables>(GetOrCreateTwilioConverssationDocument, options);
      }
export type GetOrCreateTwilioConverssationMutationHookResult = ReturnType<typeof useGetOrCreateTwilioConverssationMutation>;
export type GetOrCreateTwilioConverssationMutationResult = Apollo.MutationResult<GetOrCreateTwilioConverssationMutation>;
export type GetOrCreateTwilioConverssationMutationOptions = Apollo.BaseMutationOptions<GetOrCreateTwilioConverssationMutation, GetOrCreateTwilioConverssationMutationVariables>;