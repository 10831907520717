import { StyleProps } from '@chakra-ui/react';

export const DEFAULT_INPUT_STYLE: StyleProps = {
  border: '1px solid',
  borderColor: '#E2E8F0',
  borderRadius: '0.375rem',
  color: 'text.100',
  fontSize: 'medium',
  height: '2.5rem',
  minHeight: '40px',
  padding: '0 16px',
};

export default DEFAULT_INPUT_STYLE;
