import * as Joi from 'joi';

/* 
    Accepts empty strings, and defaults them to null
    Accepts all other strings
*/
export const DefaultNullValidation = Joi.alternatives().try(
  Joi.string().valid('').empty('').allow(null).default(null),
  Joi.string(),
  Joi.number()
);

export default DefaultNullValidation;
