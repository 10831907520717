import { Box, Grid, GridProps, HStack } from '@chakra-ui/react';
import Async, { AsyncProps } from 'react-select/async';
import AsyncCreatableSelect from 'react-select/async-creatable';
import { useLocation, useNavigate } from 'react-router-dom';
import Select, {
  ActionMeta,
  GroupBase,
  MultiValue,
  SingleValue,
  StylesConfig,
} from 'react-select';

import './filters.module.scss';
import { SelectOption } from '@webapp/types';
import isMultiValue from '../../util/isMultiValue';
import DateRangeFilter from './DateRangeFilter';

export { FILTER_DATE_FORMAT } from './DateRangeFilter';

export type SelectOnChangeFunction<T> = (
  newValue: SingleValue<SelectOption<T>> | MultiValue<SelectOption<T>>,
  actionMeta: ActionMeta<SelectOption<T>>
) => void;

interface FilterDatum<T = unknown> {
  creatable?: boolean;
  defaultOptions?: boolean;
  defaultValue?: SelectOption<T>;
  manageStateInUrl?: boolean;
  filterKey: string;
  filterStyle?: StylesConfig<SelectOption<T>>;
  isAsync?: boolean;
  isClearable?: boolean;
  isMulti?: boolean;
  isSearchable?: boolean;
  useLoadOptions?: () => {
    search: (searchParam: string) => Promise<SelectOption<T>[]>;
  };
  placeholder?: string;
  onClear?: () => void;
  onRemoveTag?: () => void;
  selectOptions?: SelectOption<T>[];
  onChange?: SelectOnChangeFunction<T>;
  type?: 'default' | 'date';
  width?: string;
  maxWidth?: string;
}

export interface FilterProps<T> {
  filtersData: FilterDatum<T>[];
  filterStyle?: StylesConfig<SelectOption<T>>;
  containerStyle?: GridProps;
}

export function Filter<T = unknown>({
  creatable = false,
  defaultOptions = true,
  defaultValue,
  manageStateInUrl = true,
  filterKey,
  filterStyle = {
    control: (styles) => ({
      ...styles,
      backgroundColor: 'white',
      border: '1px solid #DCDCDC',
      color: '#525257',
      cursor: 'pointer',
      fontSize: '16px',
      fontWeight: 'bold',
      height: '100%',
      maxHeight: '45px',
      minHeight: '40px',
      letterSpacing: '0.01em',
      width: '100%',
    }),
    indicatorSeparator: (styles) => ({
      ...styles,
      display: 'none',
    }),
    placeholder: (styles) => ({
      ...styles,
      color: 'var(--chakra-colors-gray-400)',
      fontSize: '14px',
      fontWeight: 'normal',
    }),
    singleValue: (styles) => ({
      ...styles,
      color: '#525257',
      fontSize: '16px',
      fontWeight: 'bold',
      letterSpacing: '0.01em',
    }),
    valueContainer: (styles) => ({
      ...styles,
      maxHeight: '40px',
      overflowY: 'scroll',
      scrollbarWidth: 'none',
      '::-webkit-scrollbar': {
        display: 'none',
      },
    }),
  },
  isAsync = false,
  isClearable = true,
  isMulti = false,
  isSearchable = true,
  useLoadOptions = () => ({
    search: async () => [],
  }),
  placeholder = undefined,
  selectOptions = [],
  onChange,
  type = 'default',
  width = '200px',
  maxWidth,
}: FilterDatum<T>) {
  const location = useLocation();
  const navigate = useNavigate();

  const searchParameters = new URLSearchParams(location.search);

  const currentValue = searchParameters.get(filterKey);

  const { search } = useLoadOptions();

  async function loadOptions(
    inputValue: string,
    callback: (results: SelectOption<T>[]) => void
  ) {
    const options = await search(inputValue);
    callback(options);
    return options;
  }

  function handleChange(
    newValue: SingleValue<SelectOption<T>> | MultiValue<SelectOption<T>>,
    actionMeta: ActionMeta<SelectOption<T>>
  ) {
    if (onChange) onChange(newValue, actionMeta);
    searchParameters.set('page', '1');

    switch (actionMeta.action) {
      case 'remove-value': {
        searchParameters.delete(filterKey);

        if (manageStateInUrl)
          navigate({
            search: searchParameters.toString(),
          });

        break;
      }

      case 'select-option': {
        if (isMultiValue(newValue)) {
          searchParameters.set(
            filterKey,
            newValue.map(({ value }) => value).join(',')
          );

          navigate({
            search: searchParameters.toString(),
          });
          break;
        }
        searchParameters.set(filterKey, newValue?.value ?? '');

        if (manageStateInUrl)
          navigate({
            search: searchParameters.toString(),
          });

        break;
      }

      case 'clear': {
        searchParameters.delete(filterKey);

        if (manageStateInUrl)
          navigate({
            search: searchParameters.toString(),
          });

        break;
      }

      case 'create-option':
      case 'deselect-option':
      case 'pop-value':
      default: {
        break;
      }
    }
  }

  const filterProps: Omit<
    AsyncProps<SelectOption<T>, true | false, GroupBase<SelectOption<T>>>,
    'onChange' | 'loadOption'
  > = {
    ...(Boolean(defaultValue) && {
      defaultValue,
    }),
    ...(Boolean(currentValue) &&
      !isAsync && {
        defaultValue: selectOptions.find(
          (option) => option.value === currentValue
        ),
      }),
    ...(currentValue &&
      isAsync && {
        defaultValue: currentValue
          ? ({
              label: currentValue,
              value: currentValue,
            } as SelectOption<T>)
          : undefined,
      }),
    defaultOptions,
    isClearable,
    isMulti,
    isSearchable,
    placeholder,
    styles: filterStyle,
  };

  switch (type) {
    case 'date': {
      return (
        <DateRangeFilter filterKey={filterKey} placeholder={placeholder} />
      );
    }

    default: {
      return (
        <Box minW={width} maxW={maxWidth}>
          {!isAsync && (
            <Select
              onChange={handleChange}
              options={selectOptions}
              {...filterProps}
            />
          )}
          {creatable && isAsync && (
            <AsyncCreatableSelect
              loadOptions={loadOptions}
              onChange={handleChange}
              {...filterProps}
            />
          )}
          {!creatable && isAsync && (
            <Async
              loadOptions={loadOptions}
              onChange={handleChange}
              {...filterProps}
            />
          )}
        </Box>
      );
    }
  }
}

export function Filters<T = unknown>(props: FilterProps<T>) {
  const { filtersData, filterStyle, containerStyle } = props;

  return (
    <HStack
      spacing="15px"
      {...containerStyle}
      //   gridColumnGap={containerStyle?.gridColumnGap ?? '15px'}
      //   gridTemplateColumns={
      //     containerStyle?.gridTemplateColumns ?? 'repeat(5, 1fr)'
      //   }
    >
      {filtersData.map((filter) => (
        <Filter key={filter.filterKey} {...filter} filterStyle={filterStyle} />
      ))}
    </HStack>
  );
}

export default Filters;
