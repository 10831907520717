import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetProvidersExternalQueryVariables = Types.Exact<{
  where: Types.Provider_Bool_Exp;
  limit?: Types.Maybe<Types.Scalars['Int']>;
  offset?: Types.Maybe<Types.Scalars['Int']>;
  orderBy?: Types.Maybe<Array<Types.Provider_Order_By> | Types.Provider_Order_By>;
}>;


export type GetProvidersExternalQuery = { __typename?: 'query_root', provider: Array<{ __typename?: 'provider', id: any, firstName: string, lastName: string, profilePicture?: string | null | undefined }> };


export const GetProvidersExternalDocument = gql`
    query GetProvidersExternal($where: provider_bool_exp!, $limit: Int = 20, $offset: Int = 0, $orderBy: [provider_order_by!] = [{firstName: asc}]) {
  provider(where: $where, limit: $limit, offset: $offset, order_by: $orderBy) {
    id
    firstName
    lastName
    profilePicture
  }
}
    `;

/**
 * __useGetProvidersExternalQuery__
 *
 * To run a query within a React component, call `useGetProvidersExternalQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProvidersExternalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProvidersExternalQuery({
 *   variables: {
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetProvidersExternalQuery(baseOptions: Apollo.QueryHookOptions<GetProvidersExternalQuery, GetProvidersExternalQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProvidersExternalQuery, GetProvidersExternalQueryVariables>(GetProvidersExternalDocument, options);
      }
export function useGetProvidersExternalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProvidersExternalQuery, GetProvidersExternalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProvidersExternalQuery, GetProvidersExternalQueryVariables>(GetProvidersExternalDocument, options);
        }
export type GetProvidersExternalQueryHookResult = ReturnType<typeof useGetProvidersExternalQuery>;
export type GetProvidersExternalLazyQueryHookResult = ReturnType<typeof useGetProvidersExternalLazyQuery>;
export type GetProvidersExternalQueryResult = Apollo.QueryResult<GetProvidersExternalQuery, GetProvidersExternalQueryVariables>;