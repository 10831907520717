import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type UpdateServiceAddOnsMutationVariables = Types.Exact<{
  where: Types.ServiceAddOn_Bool_Exp;
  objects: Array<Types.ServiceAddOn_Insert_Input> | Types.ServiceAddOn_Insert_Input;
}>;


export type UpdateServiceAddOnsMutation = { __typename?: 'mutation_root', delete_serviceAddOn?: { __typename?: 'serviceAddOn_mutation_response', affected_rows: number } | null | undefined, insert_serviceAddOn?: { __typename?: 'serviceAddOn_mutation_response', affected_rows: number } | null | undefined };


export const UpdateServiceAddOnsDocument = gql`
    mutation updateServiceAddOns($where: serviceAddOn_bool_exp!, $objects: [serviceAddOn_insert_input!]!) {
  delete_serviceAddOn(where: $where) {
    affected_rows
  }
  insert_serviceAddOn(objects: $objects) {
    affected_rows
  }
}
    `;
export type UpdateServiceAddOnsMutationFn = Apollo.MutationFunction<UpdateServiceAddOnsMutation, UpdateServiceAddOnsMutationVariables>;

/**
 * __useUpdateServiceAddOnsMutation__
 *
 * To run a mutation, you first call `useUpdateServiceAddOnsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateServiceAddOnsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateServiceAddOnsMutation, { data, loading, error }] = useUpdateServiceAddOnsMutation({
 *   variables: {
 *      where: // value for 'where'
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useUpdateServiceAddOnsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateServiceAddOnsMutation, UpdateServiceAddOnsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateServiceAddOnsMutation, UpdateServiceAddOnsMutationVariables>(UpdateServiceAddOnsDocument, options);
      }
export type UpdateServiceAddOnsMutationHookResult = ReturnType<typeof useUpdateServiceAddOnsMutation>;
export type UpdateServiceAddOnsMutationResult = Apollo.MutationResult<UpdateServiceAddOnsMutation>;
export type UpdateServiceAddOnsMutationOptions = Apollo.BaseMutationOptions<UpdateServiceAddOnsMutation, UpdateServiceAddOnsMutationVariables>;