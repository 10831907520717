import * as Joi from 'joi';
import DefaultNullValidation from './DefaultNullValidation';
import { addDateMessages, addStringMessages } from './generateCustomMessages';
import RequireIfAddressLine1 from './RequireIfAddressLine1';

export const CreatePatientValidation = Joi.object({
  addressLine1: DefaultNullValidation,
  addressLine2: DefaultNullValidation,
  city: RequireIfAddressLine1,
  dob: addDateMessages(Joi.date().required(), 'Date of Birth'),
  email: DefaultNullValidation,
  firstName: addStringMessages(Joi.string().required(), 'First Name'),
  middleName: DefaultNullValidation,
  lastName: addStringMessages(Joi.string().required(), 'Last Name'),
  sex: addStringMessages(Joi.string().required(), 'Sex'),
  phoneNumber: DefaultNullValidation,
  photo: DefaultNullValidation,
  state: RequireIfAddressLine1,
  zipCode: RequireIfAddressLine1,
  referringPatientId: Joi.any().allow(null).default(null),
  howDidYouFindUs: DefaultNullValidation,
  howDidYouFindUsOther: DefaultNullValidation,
});

export default CreatePatientValidation;
