import * as Types from '../../generated/graphql';

import { gql } from '@apollo/client';
import { FullConsumableFieldsFragmentDoc } from './fullConsumableFields.fragment.generated';
export type SimplifiedInventoryTransactionFieldsFragment = { __typename?: 'SimplfiedInventoryTransaction', from: any, to: any, createdAt: string, quantity: number, note?: string | null | undefined, user: any, consumable: { __typename?: 'FullConsumable', id: any, originalQuantity: number, quantity: number, metadata: any, cost: number, isExpirable: boolean, expirationDate?: string | null | undefined, daysBeforeNotification?: number | null | undefined, shouldWarnQuantity: boolean, warningQuantity?: number | null | undefined, archived: boolean, createdAt: string, updatedAt: string, type: { __typename?: 'IdNameRequiredFields', id: any, name: string, requiredFields: any, unitStep: number, unitName: string, defaultNumberOfUnits: number, defaultPricePerUnit: number }, brand: { __typename?: 'IdName', id: any, name: string }, purchaseOrder?: { __typename?: 'IdOrderNumber', id: any, orderNumber: string } | null | undefined } };

export const SimplifiedInventoryTransactionFieldsFragmentDoc = gql`
    fragment SimplifiedInventoryTransactionFields on SimplfiedInventoryTransaction {
  from
  to
  createdAt
  quantity
  note
  user
  consumable {
    ...FullConsumableFields
  }
}
    ${FullConsumableFieldsFragmentDoc}`;