import Decimal from 'decimal.js';
import {
  InputGroup,
  InputLeftAddon,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput as ChakraNumberInput,
  NumberInputField,
  NumberInputStepper,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import DEFAULT_CONTROL_STYLE from './styles/DefaultControlStyle';
import DEFAULT_INPUT_CONTAINER_STYLE from './styles/DefaultInputContainerStyle';
import { NumberInputProps } from './types';

function toUserPercentage(decimalValue: Decimal, precision: number): string {
  return decimalValue.times(100).toFixed(precision);
}

function toFormValue(percentage: string): Decimal {
  return new Decimal(percentage).dividedBy(100);
}

export default function PercentInput({
  hasIncrement = true,
  incrementStep = 1,
  max = 100,
  min = 0,
  name,
  onChange,
  placeholder,
  precision = 2,
  styles = {},
  value,
  ...props
}: Omit<NumberInputProps, 'type'>) {
  const inputStyles = { ...DEFAULT_CONTROL_STYLE, ...styles.control };
  const containerStyles = {
    ...DEFAULT_INPUT_CONTAINER_STYLE,
    ...styles.inputContainer,
  };

  const [inputValue, setInputValue] = useState<string>(
    value !== undefined ? toUserPercentage(new Decimal(value), precision) : '0'
  );

  useEffect(() => {
    const isEmpty = value === 0 && ['', '0.00'].includes(inputValue);

    if (value !== undefined && !isEmpty) {
      const areEqual = new Decimal(inputValue).equals(
        new Decimal(value).times(100)
      );

      if (!areEqual) {
        const newValue = toUserPercentage(new Decimal(value), precision);
        setInputValue(newValue);
      }
    }
  }, [precision, value, inputValue]);

  return (
    <InputGroup {...containerStyles}>
      <InputLeftAddon children="%" />
      <ChakraNumberInput
        id={name}
        max={max}
        min={min}
        onBlur={() => {
          if (!inputValue.length) {
            setInputValue('0.00');
          }
        }}
        onChange={(stringValue) => {
          setInputValue(stringValue);

          if (!Number.isNaN(parseFloat(stringValue))) {
            onChange(stringValue, toFormValue(stringValue).toNumber());
          } else {
            onChange('0', 0);
          }
        }}
        precision={precision}
        step={incrementStep}
        value={inputValue}
        width="100%"
        {...props}
      >
        <NumberInputField placeholder={placeholder} {...inputStyles} />
        {hasIncrement && (
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        )}
      </ChakraNumberInput>
    </InputGroup>
  );
}
