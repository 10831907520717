import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type ArchiveAppointmentServiceMutationVariables = Types.Exact<{
  appointmentId?: Types.Maybe<Types.Scalars['uuid']>;
}>;


export type ArchiveAppointmentServiceMutation = { __typename?: 'mutation_root', update_appointmentService?: { __typename?: 'appointmentService_mutation_response', returning: Array<{ __typename?: 'appointmentService', appointmentId: any }> } | null | undefined };


export const ArchiveAppointmentServiceDocument = gql`
    mutation archiveAppointmentService($appointmentId: uuid) {
  update_appointmentService(
    where: {appointmentId: {_eq: $appointmentId}}
    _set: {isArchived: true}
  ) {
    returning {
      appointmentId
    }
  }
}
    `;
export type ArchiveAppointmentServiceMutationFn = Apollo.MutationFunction<ArchiveAppointmentServiceMutation, ArchiveAppointmentServiceMutationVariables>;

/**
 * __useArchiveAppointmentServiceMutation__
 *
 * To run a mutation, you first call `useArchiveAppointmentServiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveAppointmentServiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveAppointmentServiceMutation, { data, loading, error }] = useArchiveAppointmentServiceMutation({
 *   variables: {
 *      appointmentId: // value for 'appointmentId'
 *   },
 * });
 */
export function useArchiveAppointmentServiceMutation(baseOptions?: Apollo.MutationHookOptions<ArchiveAppointmentServiceMutation, ArchiveAppointmentServiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ArchiveAppointmentServiceMutation, ArchiveAppointmentServiceMutationVariables>(ArchiveAppointmentServiceDocument, options);
      }
export type ArchiveAppointmentServiceMutationHookResult = ReturnType<typeof useArchiveAppointmentServiceMutation>;
export type ArchiveAppointmentServiceMutationResult = Apollo.MutationResult<ArchiveAppointmentServiceMutation>;
export type ArchiveAppointmentServiceMutationOptions = Apollo.BaseMutationOptions<ArchiveAppointmentServiceMutation, ArchiveAppointmentServiceMutationVariables>;