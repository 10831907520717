import range from 'lodash/range';

interface PaginationUtilProps {
  count: number;
  currentPage: number;
  pageNeighbors: number;
  perPageLimit: number;
}

export class PaginationUtil {
  count: number;

  currentPage: number;

  pageNeighbors: number;

  perPageLimit: number;

  constructor({
    currentPage,
    count,
    pageNeighbors,
    perPageLimit,
  }: PaginationUtilProps) {
    this.count = count;
    this.perPageLimit = perPageLimit;
    this.pageNeighbors = pageNeighbors;
    this.currentPage = currentPage;
  }

  init({
    currentPage,
    count,
    pageNeighbors,
    perPageLimit,
  }: PaginationUtilProps) {
    this.count = count;
    this.perPageLimit = perPageLimit;
    this.pageNeighbors = pageNeighbors;
    this.currentPage = currentPage;
  }

  genPages() {
    if (this.totalPages > this.totalBlocks) {
      const hasLeftSpill = this.startPage > 2;
      const hasRightSpill = this.totalPages - this.endPage > 1;
      const spillOffset = this.totalNumbers - (this.pages.length + 1);

      const pages = [];

      switch (true) {
        // handle: (1) < {5 6} [7] {8 9} (10)
        case hasLeftSpill && !hasRightSpill: {
          const extraPages = range(
            this.startPage - spillOffset + 1,
            this.startPage
          );

          pages.push('LEFT_PAGE', ...extraPages, ...this.pages);
          break;
        }

        // handle: (1) {2 3} [4] {5 6} > (10)
        case !hasLeftSpill && hasRightSpill: {
          const extraPages = range(
            this.endPage + 1,
            this.endPage + spillOffset
          );

          pages.push(...this.pages, ...extraPages, 'RIGHT_PAGE');
          break;
        }

        // handle: (1) < {4 5} [6] {7 8} > (10)
        // case hasLeftSpill && hasRightSpill:
        default: {
          pages.push('LEFT_PAGE', ...this.pages, 'RIGHT_PAGE');
          break;
        }
      }

      return [1, ...pages, this.totalPages];
    }

    return range(1, this.totalPages + 1);
  }

  /* getters */
  get basePages() {
    return [1, this.totalPages];
  }

  get endPage() {
    return Math.min(
      this.totalPages - 1,
      this.currentPage + this.pagesToDisplay
    );
  }

  get pages() {
    return range(this.startPage, this.endPage + 1);
  }

  get pagesToDisplay() {
    return Math.max(0, Math.min(this.pageNeighbors, 2));
  }

  get startPage() {
    return Math.max(2, this.currentPage - this.pagesToDisplay);
  }

  get totalBlocks() {
    const totalNumbers = this.pagesToDisplay * 2 + 3;
    return totalNumbers + 2;
  }

  get totalNumbers() {
    return this.pagesToDisplay * 2 + 3;
  }

  get totalPages() {
    return Math.ceil(this.count / this.perPageLimit);
  }
}

export default PaginationUtil;
