/* eslint-disable max-classes-per-file */
import { createElement } from 'react';
import {
  PropertyGridEditorCollection,
  localization,
} from 'survey-creator-react';
import { ElementFactory, Question, Serializer, SvgRegistry } from 'survey-core';
import {
  SurveyQuestionElementBase,
  ReactQuestionFactory,
} from 'survey-react-ui';
import { Switch } from '@chakra-ui/react';

import Icon from './icon.svg?raw';
import WrappedAddress from './wrappedAddressInput';

const CUSTOM_TYPE = 'address';

// A model for the new question type
export class AddressModel extends Question {
  getType() {
    return CUSTOM_TYPE;
  }
}

// Register `AddressModel` as a model for the `address` type
export function registerAddress() {
  ElementFactory.Instance.registerElement(
    CUSTOM_TYPE,
    (name) => new AddressModel(name)
  );
}

// Specify display names for the question type and its properties
const locale = localization.getLocale('');
locale.qt[CUSTOM_TYPE] = 'Address';

// Register an SVG icon for the question type
SvgRegistry.registerIconFromSvg(CUSTOM_TYPE, Icon);

// Add question type metadata for further serialization into JSON
Serializer.addClass(
  CUSTOM_TYPE,
  [],
  () => new AddressModel('Address'),
  'question'
);

// A class that renders questions of the new type in the UI
export class SurveyQuestionAddress extends SurveyQuestionElementBase {
  constructor(props) {
    super(props);
    this.state = { value: this.question.value };
  }

  get question() {
    return this.questionBase;
  }

  get value() {
    return this.question.value;
  }

  // Support the read-only and design modes
  get style() {
    return this.question.getPropertyValue('readOnly') ||
      this.question.isDesignMode
      ? { pointerEvents: 'none' }
      : undefined;
  }

  renderElement() {
    return (
      <div style={this.style}>
        <WrappedAddress
          onChange={(a) => {
            this.question.value = a;
          }}
        />
      </div>
    );
  }
}

// Register `SurveyQuestionAddress` as a class that renders `address` questions
ReactQuestionFactory.Instance.registerQuestion(CUSTOM_TYPE, (props) =>
  createElement(SurveyQuestionAddress, props)
);
