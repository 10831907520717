import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { InvoiceTemplateFieldsFragmentDoc } from '../../fragments/invoiceTemplateFields.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type UpdateInvoiceTemplateMutationVariables = Types.Exact<{
  set?: Types.Maybe<Types.InvoiceTemplate_Set_Input>;
  id: Types.Scalars['uuid'];
}>;


export type UpdateInvoiceTemplateMutation = { __typename?: 'mutation_root', update_invoiceTemplate_by_pk?: { __typename?: 'invoiceTemplate', id: any, title: string, invoiceValues: any } | null | undefined };


export const UpdateInvoiceTemplateDocument = gql`
    mutation UpdateInvoiceTemplate($set: invoiceTemplate_set_input, $id: uuid!) {
  update_invoiceTemplate_by_pk(_set: $set, pk_columns: {id: $id}) {
    ...InvoiceTemplateFields
  }
}
    ${InvoiceTemplateFieldsFragmentDoc}`;
export type UpdateInvoiceTemplateMutationFn = Apollo.MutationFunction<UpdateInvoiceTemplateMutation, UpdateInvoiceTemplateMutationVariables>;

/**
 * __useUpdateInvoiceTemplateMutation__
 *
 * To run a mutation, you first call `useUpdateInvoiceTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInvoiceTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInvoiceTemplateMutation, { data, loading, error }] = useUpdateInvoiceTemplateMutation({
 *   variables: {
 *      set: // value for 'set'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateInvoiceTemplateMutation(baseOptions?: Apollo.MutationHookOptions<UpdateInvoiceTemplateMutation, UpdateInvoiceTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateInvoiceTemplateMutation, UpdateInvoiceTemplateMutationVariables>(UpdateInvoiceTemplateDocument, options);
      }
export type UpdateInvoiceTemplateMutationHookResult = ReturnType<typeof useUpdateInvoiceTemplateMutation>;
export type UpdateInvoiceTemplateMutationResult = Apollo.MutationResult<UpdateInvoiceTemplateMutation>;
export type UpdateInvoiceTemplateMutationOptions = Apollo.BaseMutationOptions<UpdateInvoiceTemplateMutation, UpdateInvoiceTemplateMutationVariables>;