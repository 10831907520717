import { RadioGroup, Stack, Radio } from '@chakra-ui/react';
import { SingleField } from '@webapp/types';

interface PopulatedCustomFormSingleSelectProps {
  field: SingleField;
  defaultValue: any;
  fieldId: string;
  setValue: (field: string, value: any) => void;
}

export const PopulatedCustomFormSingleSelect = ({
  field,
  defaultValue,
  fieldId,
  setValue,
}: PopulatedCustomFormSingleSelectProps): JSX.Element => (
  <RadioGroup
    defaultValue={defaultValue}
    onChange={(value) => setValue(fieldId, value)}
  >
    <Stack direction="column">
      {field.options.map((option) => (
        <Radio key={option.id} value={option.value}>
          {option.value}
        </Radio>
      ))}
    </Stack>
  </RadioGroup>
);

export default PopulatedCustomFormSingleSelect;
