import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Box, Center, ChakraProvider, Spinner, Text } from '@chakra-ui/react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { UserInfo as FirebaseUser } from '@firebase/auth-types';
import { Toaster } from 'react-hot-toast';
import { firebaseAuth } from '@webapp/auth';
import { datadogRum } from '@datadog/browser-rum';
import { LicenseManager } from 'ag-grid-enterprise';
import {
  RootStore,
  RootStoreProvider,
  setupRootStore,
} from '@webapp/state-models';
import { setLicenseKey } from 'survey-core';
import { ModalProvider } from '@webapp/hooks';
import AuthWrapper from './router';
import theme from './chakra-theme';
import './app.module.scss';
import '@mobiscroll/react/dist/css/mobiscroll.scss';

setLicenseKey(
  'MTMxYTY2ODMtNTQxMC00NWIzLTliMGMtZGMyN2RiMGUzMzgyOzE9MjAyNS0wMi0wOCwyPTIwMjUtMDItMDgsND0yMDI1LTAyLTA4'
);

if (process.env.VITE_AG_GRID_KEY) {
  LicenseManager.setLicenseKey(process.env.VITE_AG_GRID_KEY);
}

async function initializeApp(
  user: FirebaseUser | undefined,
  params: {
    navigate: NavigateFunction;
    setInitializing: Dispatch<SetStateAction<boolean>>;
    setRootStore: Dispatch<SetStateAction<RootStore | undefined>>;
  }
) {
  const rootStore = await setupRootStore();

  params.setRootStore(rootStore);

  if (user) {
    await rootStore?.initializeUser(user);
  }

  params.setInitializing(false);
}

export function App() {
  const [rootStore, setRootStore] = useState<RootStore | undefined>(undefined);
  const [initializing, setInitializing] = useState<boolean>(true);
  const [user, loading, error] = useAuthState(firebaseAuth);

  const navigate = useNavigate();

  if (
    import.meta.env.VITE_DATADOG_CLIENT_TOKEN &&
    import.meta.env.VITE_DATADOG_APPLICATION_ID
  ) {
    datadogRum.init({
      applicationId: import.meta.env.VITE_DATADOG_APPLICATION_ID,
      clientToken: import.meta.env.VITE_DATADOG_CLIENT_TOKEN,
      site: 'datadoghq.com',
      service: 'prospyr-webapp',
      version: import.meta.env.VITE_APP_VERSION,
      sessionSampleRate: 100,
      sessionReplaySampleRate: 100,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'allow',
    });
  }

  firebaseAuth.onIdTokenChanged((u) => {
    if (u) {
      u.getIdToken().then((token) =>
        localStorage.setItem(`prospyr_token_${import.meta.env.VITE_ENV}`, token)
      );
    }
  });

  useEffect(() => {
    const initializeProps = { setRootStore, navigate, setInitializing };

    if (user) {
      user
        .getIdToken()
        .then((token) =>
          localStorage.setItem(
            `prospyr_token_${import.meta.env.VITE_ENV}`,
            token
          )
        );

      initializeApp(user, initializeProps);
    } else if (!user && !loading) {
      initializeApp(undefined, initializeProps);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, loading, error]);

  return (
    <ChakraProvider theme={theme} portalZIndex={40}>
      {!rootStore || initializing ? (
        <Center h="100vh">
          <Box textAlign="center">
            <Spinner size="xl" colorScheme="teal" />
            <Text mt={4}>Initializing...</Text>
          </Box>
        </Center>
      ) : (
        <>
          <RootStoreProvider value={rootStore}>
            <ModalProvider>
              <AuthWrapper />
            </ModalProvider>
          </RootStoreProvider>
          <Toaster
            toastOptions={{
              className: '',
              style: {
                zIndex: 9999,
              },
            }}
          />
        </>
      )}
    </ChakraProvider>
  );
}

export default App;
