import { extendTheme } from '@chakra-ui/react';
import { CustomSelectTheme } from '@webapp/ui';
import CustomTabTheme from './themes/tabTheme';
import CustomDrawerTheme from './themes/drawerTheme';
import FormLabelTheme from './themes/formLabelTheme';
import RadioTheme from './themes/radioTheme';
import TextTheme from './themes/textTheme';
import MenuButtonTheme from './themes/menuButtonTheme';

// 2. Call `extendTheme` and pass your custom values
const theme = extendTheme(
  {
    colors: {
      brand: {
        primary: '#008399',
        lightGreen: '#EFF6F8',
        darkGray: '#6C6C72',
      },
      'teal.50': '#EAFCFF',
      'teal.500': '#00758A',
      'text.100': '#525257',
      'gold.50': '#FFF9EE',
      'gold.500': '#E9B550',
      'gold.700': '#83601B',
      'gray.100': '#EDEDED',
      'gray.700': '#525257',
      'peach.50': '#FFF5F2',
      'peach.600': '#B26844',
      'peach.700': '#7F4529',
    },
    fonts: {
      heading: 'Google Sans, sans-serif',
      body: 'Google Sans, sans-serif',
    },
  },
  CustomSelectTheme,
  CustomTabTheme,
  CustomDrawerTheme,
  FormLabelTheme,
  RadioTheme,
  TextTheme,
  MenuButtonTheme
);

export default theme;
