import {
  Button,
  ButtonGroup,
  HStack,
  IconButton,
  Input,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  useDisclosure,
} from '@chakra-ui/react';

import { Editor, NodeViewWrapper } from '@tiptap/react';
import { SelectOption } from '@webapp/types';
import { ChangeEventHandler, useEffect, useRef, useState } from 'react';
import { FiTrash } from 'react-icons/fi';
import { IoMdClose } from 'react-icons/io';
import { PopoverTrigger } from '../popover-trigger/popover-trigger';
import ColorSelector from '../color-selector';
import { colors } from './constants';
import { SmartTextEditorToolbarVersion } from './types';

interface HighlightReplaceProps {
  node: any;
  editor: Editor;
  getPos: () => number;
  deleteNode: () => void;
  updateAttributes: (attrs: { [key: string]: any }) => void;
}

export const HighlightReplace = (props: HighlightReplaceProps): JSX.Element => {
  const { node, updateAttributes, editor, getPos, deleteNode } = props;
  const { onOpen, onClose, isOpen } = useDisclosure();
  const initialFocusRef = useRef<any>(null);

  const creatingTemplate = editor.options.injectNonce;

  const originalText =
    node?.content?.content[0]?.text ?? node.attrs.originalText;

  const [color, setColor] = useState<SelectOption>(
    colors.find((item) => item.value === node.attrs.linkColor) ?? {
      label: 'teal',
      value: '#009688',
    }
  );

  const [text, setText] = useState<string>(originalText);

  const clear = (): void => {
    onClose();
    deleteNode();
  };

  const onChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    setText(event.target.value);
  };

  const changeColor = (selectOption: SelectOption | null): void => {
    if (!selectOption) return;

    setColor(selectOption);

    updateAttributes({
      linkColor: selectOption.value,
    });
  };

  const save = (): void => {
    const position = getPos();

    deleteNode();

    editor.commands.insertContentAt(position, `${text}`);
  };

  useEffect(() => {
    if (originalText) {
      updateAttributes({
        originalText,
      });
    }
  }, [originalText]);

  return (
    <NodeViewWrapper style={{ display: 'inline-block' }}>
      <>
        <Popover
          initialFocusRef={initialFocusRef}
          isOpen={isOpen}
          onOpen={onOpen}
          onClose={onClose}
          placement="auto"
        >
          <PopoverTrigger>
            <Button variant="link" color={color.value}>
              {originalText}
            </Button>
          </PopoverTrigger>

          <PopoverContent w={creatingTemplate ? '220px' : undefined}>
            <PopoverArrow />
            <PopoverBody>
              <HStack>
                {!creatingTemplate ? (
                  <Input
                    ref={initialFocusRef}
                    value={text}
                    onChange={onChange}
                  />
                ) : (
                  <ColorSelector
                    onChange={changeColor}
                    color={color}
                    colors={colors}
                  />
                )}
                <ButtonGroup>
                  <IconButton
                    aria-label="close-highlight-replace"
                    variant="outline"
                    icon={<IoMdClose />}
                    onClick={onClose}
                  />
                  <IconButton
                    aria-label="delete-highlight-replace"
                    variant="outline"
                    colorScheme="red"
                    icon={<FiTrash />}
                    onClick={clear}
                  />
                  {!creatingTemplate && (
                    <Button colorScheme="teal" onClick={save}>
                      Save
                    </Button>
                  )}
                </ButtonGroup>
              </HStack>
            </PopoverBody>
          </PopoverContent>
        </Popover>
      </>
    </NodeViewWrapper>
  );
};

export default HighlightReplace;
