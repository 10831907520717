import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type DeleteCustomFormReplicatedRowMutationVariables = Types.Exact<{
  where: Types.CustomFormReplicatedRow_Bool_Exp;
}>;


export type DeleteCustomFormReplicatedRowMutation = { __typename?: 'mutation_root', delete_customFormReplicatedRow?: { __typename?: 'customFormReplicatedRow_mutation_response', returning: Array<{ __typename?: 'customFormReplicatedRow', id: any }> } | null | undefined };


export const DeleteCustomFormReplicatedRowDocument = gql`
    mutation deleteCustomFormReplicatedRow($where: customFormReplicatedRow_bool_exp!) {
  delete_customFormReplicatedRow(where: $where) {
    returning {
      id
    }
  }
}
    `;
export type DeleteCustomFormReplicatedRowMutationFn = Apollo.MutationFunction<DeleteCustomFormReplicatedRowMutation, DeleteCustomFormReplicatedRowMutationVariables>;

/**
 * __useDeleteCustomFormReplicatedRowMutation__
 *
 * To run a mutation, you first call `useDeleteCustomFormReplicatedRowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCustomFormReplicatedRowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCustomFormReplicatedRowMutation, { data, loading, error }] = useDeleteCustomFormReplicatedRowMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDeleteCustomFormReplicatedRowMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCustomFormReplicatedRowMutation, DeleteCustomFormReplicatedRowMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCustomFormReplicatedRowMutation, DeleteCustomFormReplicatedRowMutationVariables>(DeleteCustomFormReplicatedRowDocument, options);
      }
export type DeleteCustomFormReplicatedRowMutationHookResult = ReturnType<typeof useDeleteCustomFormReplicatedRowMutation>;
export type DeleteCustomFormReplicatedRowMutationResult = Apollo.MutationResult<DeleteCustomFormReplicatedRowMutation>;
export type DeleteCustomFormReplicatedRowMutationOptions = Apollo.BaseMutationOptions<DeleteCustomFormReplicatedRowMutation, DeleteCustomFormReplicatedRowMutationVariables>;