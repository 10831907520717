import {
  GraphqlClient,
  InsertLocationScheduleDocument,
  UpdateLocationDocument,
  UpdateLocationScheduleDocument,
} from '@webapp/graphql';
import { sortBy } from 'lodash';
import { Schedule } from '@webapp/types';
import {
  Instance,
  SnapshotOut,
  types,
  flow,
  applySnapshot,
} from 'mobx-state-tree';
import toast from 'react-hot-toast';
import { getErrorMessage } from '@webapp/utils';
import { configuredDayjs as dayjs } from '@webapp/util-time';
import { Dayjs } from 'dayjs';
import { LocationSchedule, LocationScheduleModel } from './locationSchedule';

export const RoomModel = types.model('Room').props({
  id: types.string,
  name: types.string,
});

export type Room = Instance<typeof RoomModel>;
/**
 * A LocationStore model.
 */
// prettier-ignore
export const LocationModel = types
.model('Location')
.props({
  id: types.identifier,
  name: types.string,
  address: types.maybeNull(types.string),
  email: types.maybeNull(types.string),
  workStartTime: types.maybeNull(types.string),
  workEndTime: types.maybeNull(types.string),
  workspaceId: types.maybeNull(types.string),
  phoneNumber: types.maybeNull(types.string),
  payrixMerchantId: types.maybeNull(types.string),
  maxPatientsPerTimeSlot: types.maybeNull(types.number),
  rooms: types.optional(types.array(RoomModel), []),
  locationSchedules: types.optional(
    types.array(types.late(() => LocationScheduleModel)),
    []
  ),
})
.actions((self) => ({
    createAvailability: flow(function* () {
        const client = GraphqlClient();
        try {
          const {
            data: {
              insert_locationSchedule: { returning },
            },
          } = yield client.mutate({
            mutation: InsertLocationScheduleDocument,
            variables: {
              locationSchedule: {
                locationId: self.id,
                effectiveFrom: dayjs().format('YYYY-MM-DD'),
                schedule: {
                  0: [],
                  1: [
                    { start: '09:00', end: '12:00' },
                    { start: '13:00', end: '17:00' },
                  ],
                  2: [
                    { start: '09:00', end: '12:00' },
                    { start: '13:00', end: '17:00' },
                  ],
                  3: [
                    { start: '09:00', end: '12:00' },
                    { start: '13:00', end: '17:00' },
                  ],
                  4: [
                    { start: '09:00', end: '12:00' },
                    { start: '13:00', end: '17:00' },
                  ],
                  5: [
                    { start: '09:00', end: '12:00' },
                    { start: '13:00', end: '17:00' },
                  ],
                  6: [],
                },
              },
            },
          });
          self.locationSchedules = returning;
        } catch (e) {
          toast.error(getErrorMessage(e));
        }
      }),
      updateAvailability: flow(function* (
        locationScheduleId: string,
        schedule: Schedule
      ) {
        const client = GraphqlClient();
        try {
          const {
            data: {
              update_locationSchedule: { returning },
            },
          } = yield client.mutate({
            mutation: UpdateLocationScheduleDocument,
            variables: {
              id: locationScheduleId,
              _set: {
                schedule,
              },
            },
          });
          const ps = self.locationSchedules.find(
            (p) => p.id === locationScheduleId
          ) as LocationSchedule;
          applySnapshot(ps, returning[0]);
          toast.success('Availability updated');
        } catch (e) {
          toast.error(getErrorMessage(e));
        }
      }),
      updateWorkTimes: flow(function* ({ workStartTime, workEndTime }) {
        const client = GraphqlClient();
        try {
         yield client.mutate({
            mutation: UpdateLocationDocument,
            variables: {
              id: self.id,
              set: {
                workStartTime,
                workEndTime,
              },
            },
          });
    
          toast.success('Work times updated');
        } catch (e: any) {
          toast.error(getErrorMessage(e));
        }
      })
    }))
    .views((self) => ({
    effectiveSchedule(): LocationSchedule | undefined {
      return sortBy(self.locationSchedules, 'effectiveFrom').find(
        (schedule) => dayjs(schedule.effectiveFrom).isBefore(dayjs())
      );
    },
    

})).views((self) => ({
    isWithinWorkHours(appointmentStartTime: Dayjs): boolean {
        let startOfWorkingDay = 8
        let endOfWorkingDay = 18
        const effectiveSchedule = self.effectiveSchedule();
        if (effectiveSchedule) {
            const { schedule } = effectiveSchedule;
            const dayOfWeek = appointmentStartTime.day();
            const daySchedule = schedule[dayOfWeek];

            
            if (daySchedule && daySchedule.length > 0) {
                startOfWorkingDay = dayjs(daySchedule[0].start, 'HH:mm').hour()
                endOfWorkingDay = dayjs(daySchedule[daySchedule.length - 1].end, 'HH:mm').hour()
            }
        }
        return appointmentStartTime.hour() >= startOfWorkingDay && appointmentStartTime.hour() < endOfWorkingDay
    }}))
/**
 * The Location instance.
 */
export type Location = Instance<typeof LocationModel>;

/**
 * The data of a Location.
 */
export type LocationSnapshot = SnapshotOut<typeof LocationModel>;
