import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { LeadTrackedEventFieldsFragmentDoc } from '../../fragments/leadPhoneCallFields.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type UpdateLeadTrackedEventMutationVariables = Types.Exact<{
  set?: Types.Maybe<Types.LeadTrackedEvent_Set_Input>;
  id: Types.Scalars['uuid'];
}>;


export type UpdateLeadTrackedEventMutation = { __typename?: 'mutation_root', update_leadTrackedEvent_by_pk?: { __typename?: 'leadTrackedEvent', id: any, outcome: string, type?: string | null | undefined, createdAt: any, updatedAt: any, user?: { __typename?: 'user', firstName: string, lastName: string } | null | undefined } | null | undefined };


export const UpdateLeadTrackedEventDocument = gql`
    mutation UpdateLeadTrackedEvent($set: leadTrackedEvent_set_input, $id: uuid!) {
  update_leadTrackedEvent_by_pk(_set: $set, pk_columns: {id: $id}) {
    ...LeadTrackedEventFields
  }
}
    ${LeadTrackedEventFieldsFragmentDoc}`;
export type UpdateLeadTrackedEventMutationFn = Apollo.MutationFunction<UpdateLeadTrackedEventMutation, UpdateLeadTrackedEventMutationVariables>;

/**
 * __useUpdateLeadTrackedEventMutation__
 *
 * To run a mutation, you first call `useUpdateLeadTrackedEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLeadTrackedEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLeadTrackedEventMutation, { data, loading, error }] = useUpdateLeadTrackedEventMutation({
 *   variables: {
 *      set: // value for 'set'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateLeadTrackedEventMutation(baseOptions?: Apollo.MutationHookOptions<UpdateLeadTrackedEventMutation, UpdateLeadTrackedEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateLeadTrackedEventMutation, UpdateLeadTrackedEventMutationVariables>(UpdateLeadTrackedEventDocument, options);
      }
export type UpdateLeadTrackedEventMutationHookResult = ReturnType<typeof useUpdateLeadTrackedEventMutation>;
export type UpdateLeadTrackedEventMutationResult = Apollo.MutationResult<UpdateLeadTrackedEventMutation>;
export type UpdateLeadTrackedEventMutationOptions = Apollo.BaseMutationOptions<UpdateLeadTrackedEventMutation, UpdateLeadTrackedEventMutationVariables>;