import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type DeleteConsumableSubtypeMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
}>;


export type DeleteConsumableSubtypeMutation = { __typename?: 'mutation_root', delete_consumableSubtype_by_pk?: { __typename?: 'consumableSubtype', id: any } | null | undefined };


export const DeleteConsumableSubtypeDocument = gql`
    mutation DeleteConsumableSubtype($id: uuid!) {
  delete_consumableSubtype_by_pk(id: $id) {
    id
  }
}
    `;
export type DeleteConsumableSubtypeMutationFn = Apollo.MutationFunction<DeleteConsumableSubtypeMutation, DeleteConsumableSubtypeMutationVariables>;

/**
 * __useDeleteConsumableSubtypeMutation__
 *
 * To run a mutation, you first call `useDeleteConsumableSubtypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteConsumableSubtypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteConsumableSubtypeMutation, { data, loading, error }] = useDeleteConsumableSubtypeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteConsumableSubtypeMutation(baseOptions?: Apollo.MutationHookOptions<DeleteConsumableSubtypeMutation, DeleteConsumableSubtypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteConsumableSubtypeMutation, DeleteConsumableSubtypeMutationVariables>(DeleteConsumableSubtypeDocument, options);
      }
export type DeleteConsumableSubtypeMutationHookResult = ReturnType<typeof useDeleteConsumableSubtypeMutation>;
export type DeleteConsumableSubtypeMutationResult = Apollo.MutationResult<DeleteConsumableSubtypeMutation>;
export type DeleteConsumableSubtypeMutationOptions = Apollo.BaseMutationOptions<DeleteConsumableSubtypeMutation, DeleteConsumableSubtypeMutationVariables>;