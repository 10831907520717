import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type ListCompensationPoliciesQueryVariables = Types.Exact<{
  limit?: Types.Maybe<Types.Scalars['Int']>;
  offset?: Types.Maybe<Types.Scalars['Int']>;
  orderBy?: Types.Maybe<Array<Types.CompensationPolicy_Order_By> | Types.CompensationPolicy_Order_By>;
  where?: Types.Maybe<Types.CompensationPolicy_Bool_Exp>;
}>;


export type ListCompensationPoliciesQuery = { __typename?: 'query_root', compensationPolicy: Array<{ __typename?: 'compensationPolicy', createdAt: any, id: any, isActive: boolean, name: string, strategyType: string, updatedAt: any, strategy: { __typename?: 'compensationStrategy', id: any, name: string } }> };


export const ListCompensationPoliciesDocument = gql`
    query ListCompensationPolicies($limit: Int = 10, $offset: Int = 0, $orderBy: [compensationPolicy_order_by!], $where: compensationPolicy_bool_exp) {
  compensationPolicy(
    limit: $limit
    offset: $offset
    order_by: $orderBy
    where: $where
  ) {
    strategy {
      id
      name
    }
    createdAt
    id
    isActive
    name
    strategyType
    updatedAt
  }
}
    `;

/**
 * __useListCompensationPoliciesQuery__
 *
 * To run a query within a React component, call `useListCompensationPoliciesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListCompensationPoliciesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListCompensationPoliciesQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      orderBy: // value for 'orderBy'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useListCompensationPoliciesQuery(baseOptions?: Apollo.QueryHookOptions<ListCompensationPoliciesQuery, ListCompensationPoliciesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListCompensationPoliciesQuery, ListCompensationPoliciesQueryVariables>(ListCompensationPoliciesDocument, options);
      }
export function useListCompensationPoliciesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListCompensationPoliciesQuery, ListCompensationPoliciesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListCompensationPoliciesQuery, ListCompensationPoliciesQueryVariables>(ListCompensationPoliciesDocument, options);
        }
export type ListCompensationPoliciesQueryHookResult = ReturnType<typeof useListCompensationPoliciesQuery>;
export type ListCompensationPoliciesLazyQueryHookResult = ReturnType<typeof useListCompensationPoliciesLazyQuery>;
export type ListCompensationPoliciesQueryResult = Apollo.QueryResult<ListCompensationPoliciesQuery, ListCompensationPoliciesQueryVariables>;