import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { ConsumableTypeFieldsFragmentDoc } from '../../fragments/consumableTypeFields.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetConsumableTypesQueryVariables = Types.Exact<{
  where?: Types.Maybe<Types.ConsumableType_Bool_Exp>;
  limit?: Types.Maybe<Types.Scalars['Int']>;
  offset?: Types.Maybe<Types.Scalars['Int']>;
  orderBy?: Types.Maybe<Array<Types.ConsumableType_Order_By> | Types.ConsumableType_Order_By>;
}>;


export type GetConsumableTypesQuery = { __typename?: 'query_root', consumableType: Array<{ __typename?: 'consumableType', id: any, name: string, requiredFields: any, defaultNumberOfUnits: any, defaultPricePerUnit: number, unitStep: any, unitName: string, createdAt: any, updatedAt: any, archived: boolean, productBarcodeId?: string | null | undefined, consumableTypeConsumableSubtypes: Array<{ __typename?: 'consumableTypeConsumableSubtype', id: any, consumableSubtype: { __typename?: 'consumableSubtype', id: any, name: string } }> }> };


export const GetConsumableTypesDocument = gql`
    query GetConsumableTypes($where: consumableType_bool_exp, $limit: Int, $offset: Int, $orderBy: [consumableType_order_by!]) {
  consumableType(
    where: $where
    limit: $limit
    offset: $offset
    order_by: $orderBy
  ) {
    ...ConsumableTypeFields
  }
}
    ${ConsumableTypeFieldsFragmentDoc}`;

/**
 * __useGetConsumableTypesQuery__
 *
 * To run a query within a React component, call `useGetConsumableTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetConsumableTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetConsumableTypesQuery({
 *   variables: {
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetConsumableTypesQuery(baseOptions?: Apollo.QueryHookOptions<GetConsumableTypesQuery, GetConsumableTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetConsumableTypesQuery, GetConsumableTypesQueryVariables>(GetConsumableTypesDocument, options);
      }
export function useGetConsumableTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetConsumableTypesQuery, GetConsumableTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetConsumableTypesQuery, GetConsumableTypesQueryVariables>(GetConsumableTypesDocument, options);
        }
export type GetConsumableTypesQueryHookResult = ReturnType<typeof useGetConsumableTypesQuery>;
export type GetConsumableTypesLazyQueryHookResult = ReturnType<typeof useGetConsumableTypesLazyQuery>;
export type GetConsumableTypesQueryResult = Apollo.QueryResult<GetConsumableTypesQuery, GetConsumableTypesQueryVariables>;