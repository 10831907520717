import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { UserFieldsFragmentDoc } from '../../fragments/userFields.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type ListUsersQueryVariables = Types.Exact<{
  limit?: Types.Maybe<Types.Scalars['Int']>;
  offset?: Types.Maybe<Types.Scalars['Int']>;
  where?: Types.Maybe<Types.User_Bool_Exp>;
  orderBy?: Types.Maybe<Array<Types.User_Order_By> | Types.User_Order_By>;
}>;


export type ListUsersQuery = { __typename?: 'query_root', user: Array<{ __typename?: 'user', firebaseUid: string, id: any, firstName: string, lastName: string, phoneNumber?: string | null | undefined, email: string, twoFactorAuthEnabled?: boolean | null | undefined, userWorkspace?: { __typename?: 'userWorkspace', id: any, role: string } | null | undefined, userWorkspaces: Array<{ __typename?: 'userWorkspace', id: any, role: string, isManager?: boolean | null | undefined, lockedToLocation?: { __typename?: 'location', id: any, name: string } | null | undefined }>, providers: Array<{ __typename?: 'provider', id: any, firstName: string, lastName: string }> }> };


export const ListUsersDocument = gql`
    query ListUsers($limit: Int = 20, $offset: Int, $where: user_bool_exp, $orderBy: [user_order_by!]) {
  user(where: $where, limit: $limit, offset: $offset, order_by: $orderBy) {
    ...UserFields
    firebaseUid
    userWorkspace {
      id
      role
    }
  }
}
    ${UserFieldsFragmentDoc}`;

/**
 * __useListUsersQuery__
 *
 * To run a query within a React component, call `useListUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useListUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListUsersQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useListUsersQuery(baseOptions?: Apollo.QueryHookOptions<ListUsersQuery, ListUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListUsersQuery, ListUsersQueryVariables>(ListUsersDocument, options);
      }
export function useListUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListUsersQuery, ListUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListUsersQuery, ListUsersQueryVariables>(ListUsersDocument, options);
        }
export type ListUsersQueryHookResult = ReturnType<typeof useListUsersQuery>;
export type ListUsersLazyQueryHookResult = ReturnType<typeof useListUsersLazyQuery>;
export type ListUsersQueryResult = Apollo.QueryResult<ListUsersQuery, ListUsersQueryVariables>;