import * as Types from '../../generated/graphql';

import { gql } from '@apollo/client';
export type ClinicalMessageFieldsFragment = { __typename?: 'clinicalMessage', id: any, messageText: string, messageSubject: string, messageType: string, eventSendTime: number, fromAddress?: string | null | undefined, attachmentDocument?: { __typename?: 'document', id: any, title?: string | null | undefined } | null | undefined };

export const ClinicalMessageFieldsFragmentDoc = gql`
    fragment ClinicalMessageFields on clinicalMessage {
  id
  messageText
  messageSubject
  messageType
  eventSendTime
  fromAddress
  attachmentDocument {
    id
    title
  }
}
    `;