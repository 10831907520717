import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type SearchServiceCategoriesQueryVariables = Types.Exact<{
  searchValue: Types.Scalars['String'];
  limit?: Types.Maybe<Types.Scalars['Int']>;
  workspaceId?: Types.Maybe<Types.Scalars['uuid']>;
}>;


export type SearchServiceCategoriesQuery = { __typename?: 'query_root', serviceCategory: Array<{ __typename?: 'serviceCategory', id: any, title: string }> };


export const SearchServiceCategoriesDocument = gql`
    query searchServiceCategories($searchValue: String!, $limit: Int, $workspaceId: uuid) {
  serviceCategory(
    where: {title: {_ilike: $searchValue}, workspaceId: {_eq: $workspaceId}}
    limit: $limit
    order_by: {title: asc}
  ) {
    id
    title
  }
}
    `;

/**
 * __useSearchServiceCategoriesQuery__
 *
 * To run a query within a React component, call `useSearchServiceCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchServiceCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchServiceCategoriesQuery({
 *   variables: {
 *      searchValue: // value for 'searchValue'
 *      limit: // value for 'limit'
 *      workspaceId: // value for 'workspaceId'
 *   },
 * });
 */
export function useSearchServiceCategoriesQuery(baseOptions: Apollo.QueryHookOptions<SearchServiceCategoriesQuery, SearchServiceCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchServiceCategoriesQuery, SearchServiceCategoriesQueryVariables>(SearchServiceCategoriesDocument, options);
      }
export function useSearchServiceCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchServiceCategoriesQuery, SearchServiceCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchServiceCategoriesQuery, SearchServiceCategoriesQueryVariables>(SearchServiceCategoriesDocument, options);
        }
export type SearchServiceCategoriesQueryHookResult = ReturnType<typeof useSearchServiceCategoriesQuery>;
export type SearchServiceCategoriesLazyQueryHookResult = ReturnType<typeof useSearchServiceCategoriesLazyQuery>;
export type SearchServiceCategoriesQueryResult = Apollo.QueryResult<SearchServiceCategoriesQuery, SearchServiceCategoriesQueryVariables>;