import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type UpsertPatientMutationVariables = Types.Exact<{
  email: Types.Scalars['String'];
  firstName: Types.Scalars['String'];
  lastName: Types.Scalars['String'];
  phoneNumber: Types.Scalars['String'];
  workspaceId: Types.Scalars['uuid'];
}>;


export type UpsertPatientMutation = { __typename?: 'mutation_root', upsertPatient?: { __typename?: 'upsertPatientOutput', success: boolean, patientId: string } | null | undefined };


export const UpsertPatientDocument = gql`
    mutation UpsertPatient($email: String!, $firstName: String!, $lastName: String!, $phoneNumber: String!, $workspaceId: uuid!) {
  upsertPatient(
    email: $email
    firstName: $firstName
    lastName: $lastName
    phoneNumber: $phoneNumber
    workspaceId: $workspaceId
  ) {
    success
    patientId
  }
}
    `;
export type UpsertPatientMutationFn = Apollo.MutationFunction<UpsertPatientMutation, UpsertPatientMutationVariables>;

/**
 * __useUpsertPatientMutation__
 *
 * To run a mutation, you first call `useUpsertPatientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertPatientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertPatientMutation, { data, loading, error }] = useUpsertPatientMutation({
 *   variables: {
 *      email: // value for 'email'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      phoneNumber: // value for 'phoneNumber'
 *      workspaceId: // value for 'workspaceId'
 *   },
 * });
 */
export function useUpsertPatientMutation(baseOptions?: Apollo.MutationHookOptions<UpsertPatientMutation, UpsertPatientMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertPatientMutation, UpsertPatientMutationVariables>(UpsertPatientDocument, options);
      }
export type UpsertPatientMutationHookResult = ReturnType<typeof useUpsertPatientMutation>;
export type UpsertPatientMutationResult = Apollo.MutationResult<UpsertPatientMutation>;
export type UpsertPatientMutationOptions = Apollo.BaseMutationOptions<UpsertPatientMutation, UpsertPatientMutationVariables>;