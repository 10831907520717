import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type DeletePhotosMutationVariables = Types.Exact<{
  photoIds?: Types.Maybe<Array<Types.Scalars['uuid']> | Types.Scalars['uuid']>;
}>;


export type DeletePhotosMutation = { __typename?: 'mutation_root', delete_photo?: { __typename?: 'photo_mutation_response', affected_rows: number } | null | undefined };


export const DeletePhotosDocument = gql`
    mutation deletePhotos($photoIds: [uuid!]) {
  delete_photo(where: {id: {_in: $photoIds}}) {
    affected_rows
  }
}
    `;
export type DeletePhotosMutationFn = Apollo.MutationFunction<DeletePhotosMutation, DeletePhotosMutationVariables>;

/**
 * __useDeletePhotosMutation__
 *
 * To run a mutation, you first call `useDeletePhotosMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePhotosMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePhotosMutation, { data, loading, error }] = useDeletePhotosMutation({
 *   variables: {
 *      photoIds: // value for 'photoIds'
 *   },
 * });
 */
export function useDeletePhotosMutation(baseOptions?: Apollo.MutationHookOptions<DeletePhotosMutation, DeletePhotosMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePhotosMutation, DeletePhotosMutationVariables>(DeletePhotosDocument, options);
      }
export type DeletePhotosMutationHookResult = ReturnType<typeof useDeletePhotosMutation>;
export type DeletePhotosMutationResult = Apollo.MutationResult<DeletePhotosMutation>;
export type DeletePhotosMutationOptions = Apollo.BaseMutationOptions<DeletePhotosMutation, DeletePhotosMutationVariables>;