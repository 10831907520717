import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { InvoiceTemplateFieldsFragmentDoc } from '../../fragments/invoiceTemplateFields.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type InsertInvoiceTemplateMutationVariables = Types.Exact<{
  invoiceTemplate: Types.InvoiceTemplate_Insert_Input;
}>;


export type InsertInvoiceTemplateMutation = { __typename?: 'mutation_root', insert_invoiceTemplate?: { __typename?: 'invoiceTemplate_mutation_response', returning: Array<{ __typename?: 'invoiceTemplate', id: any, title: string, invoiceValues: any }> } | null | undefined };


export const InsertInvoiceTemplateDocument = gql`
    mutation insertInvoiceTemplate($invoiceTemplate: invoiceTemplate_insert_input!) {
  insert_invoiceTemplate(objects: [$invoiceTemplate]) {
    returning {
      ...InvoiceTemplateFields
    }
  }
}
    ${InvoiceTemplateFieldsFragmentDoc}`;
export type InsertInvoiceTemplateMutationFn = Apollo.MutationFunction<InsertInvoiceTemplateMutation, InsertInvoiceTemplateMutationVariables>;

/**
 * __useInsertInvoiceTemplateMutation__
 *
 * To run a mutation, you first call `useInsertInvoiceTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertInvoiceTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertInvoiceTemplateMutation, { data, loading, error }] = useInsertInvoiceTemplateMutation({
 *   variables: {
 *      invoiceTemplate: // value for 'invoiceTemplate'
 *   },
 * });
 */
export function useInsertInvoiceTemplateMutation(baseOptions?: Apollo.MutationHookOptions<InsertInvoiceTemplateMutation, InsertInvoiceTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertInvoiceTemplateMutation, InsertInvoiceTemplateMutationVariables>(InsertInvoiceTemplateDocument, options);
      }
export type InsertInvoiceTemplateMutationHookResult = ReturnType<typeof useInsertInvoiceTemplateMutation>;
export type InsertInvoiceTemplateMutationResult = Apollo.MutationResult<InsertInvoiceTemplateMutation>;
export type InsertInvoiceTemplateMutationOptions = Apollo.BaseMutationOptions<InsertInvoiceTemplateMutation, InsertInvoiceTemplateMutationVariables>;