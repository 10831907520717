import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { ServiceCategoryFieldsFragmentDoc } from '../../fragments/serviceCategoryFields.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type UpdateServiceCategoryMutationVariables = Types.Exact<{
  set?: Types.Maybe<Types.ServiceCategory_Set_Input>;
  id: Types.Scalars['uuid'];
}>;


export type UpdateServiceCategoryMutation = { __typename?: 'mutation_root', update_serviceCategory_by_pk?: { __typename?: 'serviceCategory', id: any, title: string } | null | undefined };


export const UpdateServiceCategoryDocument = gql`
    mutation UpdateServiceCategory($set: serviceCategory_set_input, $id: uuid!) {
  update_serviceCategory_by_pk(_set: $set, pk_columns: {id: $id}) {
    ...ServiceCategoryFields
  }
}
    ${ServiceCategoryFieldsFragmentDoc}`;
export type UpdateServiceCategoryMutationFn = Apollo.MutationFunction<UpdateServiceCategoryMutation, UpdateServiceCategoryMutationVariables>;

/**
 * __useUpdateServiceCategoryMutation__
 *
 * To run a mutation, you first call `useUpdateServiceCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateServiceCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateServiceCategoryMutation, { data, loading, error }] = useUpdateServiceCategoryMutation({
 *   variables: {
 *      set: // value for 'set'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateServiceCategoryMutation(baseOptions?: Apollo.MutationHookOptions<UpdateServiceCategoryMutation, UpdateServiceCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateServiceCategoryMutation, UpdateServiceCategoryMutationVariables>(UpdateServiceCategoryDocument, options);
      }
export type UpdateServiceCategoryMutationHookResult = ReturnType<typeof useUpdateServiceCategoryMutation>;
export type UpdateServiceCategoryMutationResult = Apollo.MutationResult<UpdateServiceCategoryMutation>;
export type UpdateServiceCategoryMutationOptions = Apollo.BaseMutationOptions<UpdateServiceCategoryMutation, UpdateServiceCategoryMutationVariables>;