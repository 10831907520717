import { useApolloClient } from '@apollo/client';
import {
  GetPackagesDocument,
  GetPackagesQuery,
  GetPackagesQueryVariables,
  PackageFieldsFragment,
} from '@webapp/graphql';
import { useStores } from '@webapp/state-models';
import { ObjectOption } from '@webapp/types';
import { useCallback } from 'react';

export type LoadPackageOption = ObjectOption<PackageFieldsFragment>;

interface UseLoadPackagesOutput {
  search(searchValue: string): Promise<LoadPackageOption[]>;
}

interface UseLoadPackagesProps {
  locationIds?: string[];
  where?: GetPackagesQueryVariables['where'];
}

export const useLoadPackages = ({
  where,
}: UseLoadPackagesProps = {}): UseLoadPackagesOutput => {
  const client = useApolloClient();

  const { workspace } = useStores();

  return {
    search: useCallback(
      async (searchValue: string) => {
        const { data } = await client.query<
          GetPackagesQuery,
          GetPackagesQueryVariables
        >({
          query: GetPackagesDocument,
          variables: {
            where: {
              ...where,
              workspaceId: {
                _eq: workspace?.id,
              },
              ...(searchValue && {
                name: {
                  _ilike: `%${searchValue}%`,
                },
              }),
              isActive: {
                _eq: true,
              },
            },
          },
        });

        const packages = data?.package ?? [];

        return packages.map((singlePackage) => ({
          label: singlePackage.name,
          value: singlePackage.id,
          object: singlePackage,
        }));
      },
      [client, where, workspace]
    ),
  };
};

export default useLoadPackages;
