import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type InsertAudienceMutationVariables = Types.Exact<{
  audience: Types.Audience_Insert_Input;
}>;


export type InsertAudienceMutation = { __typename?: 'mutation_root', insert_audience?: { __typename?: 'audience_mutation_response', returning: Array<{ __typename?: 'audience', id: any }> } | null | undefined };


export const InsertAudienceDocument = gql`
    mutation insertAudience($audience: audience_insert_input!) {
  insert_audience(objects: [$audience]) {
    returning {
      id
    }
  }
}
    `;
export type InsertAudienceMutationFn = Apollo.MutationFunction<InsertAudienceMutation, InsertAudienceMutationVariables>;

/**
 * __useInsertAudienceMutation__
 *
 * To run a mutation, you first call `useInsertAudienceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertAudienceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertAudienceMutation, { data, loading, error }] = useInsertAudienceMutation({
 *   variables: {
 *      audience: // value for 'audience'
 *   },
 * });
 */
export function useInsertAudienceMutation(baseOptions?: Apollo.MutationHookOptions<InsertAudienceMutation, InsertAudienceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertAudienceMutation, InsertAudienceMutationVariables>(InsertAudienceDocument, options);
      }
export type InsertAudienceMutationHookResult = ReturnType<typeof useInsertAudienceMutation>;
export type InsertAudienceMutationResult = Apollo.MutationResult<InsertAudienceMutation>;
export type InsertAudienceMutationOptions = Apollo.BaseMutationOptions<InsertAudienceMutation, InsertAudienceMutationVariables>;