import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type ListPendingUserInvitesQueryVariables = Types.Exact<{
  workspaceId: Types.Scalars['uuid'];
}>;


export type ListPendingUserInvitesQuery = { __typename?: 'query_root', userInvite: Array<{ __typename?: 'userInvite', id: any, firstName: string, lastName?: string | null | undefined, email: string, status: string, role: string }> };


export const ListPendingUserInvitesDocument = gql`
    query listPendingUserInvites($workspaceId: uuid!) {
  userInvite(where: {status: {_eq: "pending"}, workspaceId: {_eq: $workspaceId}}) {
    id
    firstName
    lastName
    email
    status
    role
  }
}
    `;

/**
 * __useListPendingUserInvitesQuery__
 *
 * To run a query within a React component, call `useListPendingUserInvitesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListPendingUserInvitesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListPendingUserInvitesQuery({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *   },
 * });
 */
export function useListPendingUserInvitesQuery(baseOptions: Apollo.QueryHookOptions<ListPendingUserInvitesQuery, ListPendingUserInvitesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListPendingUserInvitesQuery, ListPendingUserInvitesQueryVariables>(ListPendingUserInvitesDocument, options);
      }
export function useListPendingUserInvitesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListPendingUserInvitesQuery, ListPendingUserInvitesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListPendingUserInvitesQuery, ListPendingUserInvitesQueryVariables>(ListPendingUserInvitesDocument, options);
        }
export type ListPendingUserInvitesQueryHookResult = ReturnType<typeof useListPendingUserInvitesQuery>;
export type ListPendingUserInvitesLazyQueryHookResult = ReturnType<typeof useListPendingUserInvitesLazyQuery>;
export type ListPendingUserInvitesQueryResult = Apollo.QueryResult<ListPendingUserInvitesQuery, ListPendingUserInvitesQueryVariables>;