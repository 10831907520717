import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { FlowsheetFieldsMinimalFragmentDoc } from '../../fragments/flowsheet/FlowsheetFieldsMinimal.fragment.generated';
import { FlowsheetParameterFieldsMinimalFragmentDoc } from '../../fragments/flowsheet/FlowsheetParameterFieldsMinimal.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type InsertFlowsheetMutationVariables = Types.Exact<{
  object: Types.Flowsheet_Insert_Input;
}>;


export type InsertFlowsheetMutation = { __typename?: 'mutation_root', insert_flowsheet_one?: { __typename?: 'flowsheet', id: any, title: string, parameters: Array<{ __typename?: 'flowsheetParameterFlowsheet', id: any, parameter: { __typename?: 'flowsheetParameter', apiKey: string, id: any, isSystemParameter: boolean, title: string, type: string, unit: string } }> } | null | undefined };


export const InsertFlowsheetDocument = gql`
    mutation InsertFlowsheet($object: flowsheet_insert_input!) {
  insert_flowsheet_one(object: $object) {
    ...FlowsheetFieldsMinimal
    parameters {
      id
      parameter {
        ...FlowsheetParameterFieldsMinimal
      }
    }
  }
}
    ${FlowsheetFieldsMinimalFragmentDoc}
${FlowsheetParameterFieldsMinimalFragmentDoc}`;
export type InsertFlowsheetMutationFn = Apollo.MutationFunction<InsertFlowsheetMutation, InsertFlowsheetMutationVariables>;

/**
 * __useInsertFlowsheetMutation__
 *
 * To run a mutation, you first call `useInsertFlowsheetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertFlowsheetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertFlowsheetMutation, { data, loading, error }] = useInsertFlowsheetMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertFlowsheetMutation(baseOptions?: Apollo.MutationHookOptions<InsertFlowsheetMutation, InsertFlowsheetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertFlowsheetMutation, InsertFlowsheetMutationVariables>(InsertFlowsheetDocument, options);
      }
export type InsertFlowsheetMutationHookResult = ReturnType<typeof useInsertFlowsheetMutation>;
export type InsertFlowsheetMutationResult = Apollo.MutationResult<InsertFlowsheetMutation>;
export type InsertFlowsheetMutationOptions = Apollo.BaseMutationOptions<InsertFlowsheetMutation, InsertFlowsheetMutationVariables>;