import { PatientConsentFieldsFragment } from '@webapp/graphql';

export function isConsentFormFinished(consent: PatientConsentFieldsFragment) {
  let isFinished = true;

  if (!consent.signature) isFinished = false;
  if (consent.consent.requireProviderSignature && !consent.providerSignature)
    isFinished = false;
  if (consent.consent.requireWitnessSignature && !consent.witnessSignature)
    isFinished = false;
  return isFinished;
}

export default { isConsentFormFinished };
