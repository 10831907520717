/* eslint-disable no-underscore-dangle */
import { Workspace } from '@webapp/state-models';
import { SurveyCreatorComponent, SurveyCreator } from 'survey-creator-react';
import { uploadToS3 } from '@webapp/ui';
import { useState, useEffect } from 'react';
import { StorageType } from '@webapp/graphql';
import { setLicenseKey } from 'survey-core';
import 'survey-core/i18n/spanish';
import 'survey-core/defaultV2.css';
import 'survey-creator-core/survey-creator-core.css';
import './variables.scss';
import { registerBookAppointment } from './custom-questions/book-appointment';
import { registerImageUpload } from './custom-questions/image-upload';
import { registerPublicImageUpload } from './custom-questions/public-image-upload';
import { registerLocation } from './custom-questions/location-selection';
import { registerService } from './custom-questions/service-selection';
import { registerAddress } from './custom-questions/address';
import { registerHowDidYouFindUs } from './custom-questions/how-did-you-find-us';
import { registerRichTextEditor } from './custom-questions/rich-text-editor';
import { registerRichTextReadOnly } from './custom-questions/rich-text-read-only';
import { registerNameSignatureDate } from './custom-questions/name-signature-date';
import { registerServiceOrderRanking } from './custom-questions/service-order-ranking';

export interface SurveyJSCreatorProps {
  surveyJson: any;
  themeJSON?: any;
  workspace: Workspace;
  questionTypes?: string[];
  bootstrapFunction?: () => void;
  creatorConfigFunction?: (creator: SurveyCreator) => void;
  onChange?: (json: any) => void;
  onThemeChange?: (theme: any) => void;
}

registerBookAppointment();
registerImageUpload();
registerPublicImageUpload();
registerLocation();
registerService();
// registerToggleSwitch();
registerAddress();
registerHowDidYouFindUs();
registerRichTextEditor();
registerRichTextReadOnly();
registerNameSignatureDate();
registerServiceOrderRanking();

function postProcess(surveyJson: any) {
  const pages: any[] = [];
  if (surveyJson.pages) {
    surveyJson.pages?.forEach((page: any) => {
      const questions: any[] = [];
      page?.elements?.forEach((question: any) => {
        questions.push({
          ...question,
        });
      });
      pages.push({
        ...page,
        elements: questions,
      });
    });
  }

  return {
    ...surveyJson,
    pages,
  };
}

export const SurveyJSCreator = function SurveyJSCreator({
  surveyJson,
  workspace,
  onChange,
  questionTypes,
  bootstrapFunction,
  creatorConfigFunction,
  onThemeChange,
  themeJSON,
}: SurveyJSCreatorProps) {
  const [creator, setCreator] = useState<SurveyCreator>();
  const [ready, setReady] = useState(false);
  const creatorOptions = {
    showLogicTab: true,
    isAutoSave: true,
    logo: workspace.logo || workspace?.workspaceConfiguration?.logo,
    haveCommercialLicense: true,
    logoWidth: '100px',
    questionTypes,
    questionTitleLocation: 'top',
    showQuestionNumbers: false,
    showThemeTab: true,
  };

  useEffect(() => {
    setLicenseKey(
      'MTMxYTY2ODMtNTQxMC00NWIzLTliMGMtZGMyN2RiMGUzMzgyOzE9MjAyNS0wMi0wOCwyPTIwMjUtMDItMDgsND0yMDI1LTAyLTA4'
    );
    bootstrapFunction?.();

    const _creator = new SurveyCreator(creatorOptions);

    // Object.defineProperty(_creator, 'haveCommercialLicense', {
    //   get () {
    //     return true;
    //   },
    //   set (value) {},
    // });

    creatorConfigFunction?.(_creator);

    _creator.text = JSON.stringify({
      ...creatorOptions,
      ...surveyJson,
    });
    if (themeJSON) {
      _creator.theme = themeJSON;
    }
    _creator.onUploadFile.add((_, options) => {
      uploadToS3({
        fileContents: options.files[0],
        fileType: options.files[0].type,
        onProgress: (p) => options.callback('progress', p),
        storageType: StorageType.Public,
      }).then((res) => {
        options.callback(
          'success',
          `https://${
            import.meta.env.VITE_AWS_S3_PUBLIC_BUCKET
          }.s3.us-east-1.amazonaws.com/${res}`
        );
      });
    });
    _creator.onShowingProperty.add((sender, options) => {
      options.canShow =
        options.property.name === 'howDidYouFindUsOptions'
          ? options.obj.classMetaData.name === 'wrapped-how-did-you-find-us'
          : true;
    });

    _creator.saveThemeFunc = (saveNo, callback) => {
      callback(saveNo, true);

      onThemeChange?.(_creator.theme);
    };
    _creator.isAutoSave = true;
    _creator.saveSurveyFunc = (saveNo: any, callback: any) => {
      if (onChange) {
        onChange(postProcess(_creator.JSON));
      }
      callback(saveNo, true);
    };
    setCreator(_creator);
    setReady(true);
  }, []);

  if (!ready || !creator) return null;

  return <SurveyCreatorComponent creator={creator} />;
};
// export { SurveyJSLeadFormRenderer };
