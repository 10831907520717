import './idle-dialog.module.css';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Button,
} from '@chakra-ui/react';
import { useRef } from 'react';
import { useStores } from '@webapp/state-models';

/* eslint-disable-next-line */
export interface IdleDialogProps {
  isOpen: boolean;
  onClose: () => void;
}

export function IdleDialog({ isOpen, onClose }: IdleDialogProps) {
  const cancelRef = useRef<HTMLButtonElement>(null);
  const { logout } = useStores();
  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Idle warning
          </AlertDialogHeader>

          <AlertDialogBody>
            Are you still using Prospyr? We've noticed you're idle, and will
            sign you out in 1 minute for your protection.
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose}>
              I'm here
            </Button>
            <Button
              onClick={() => {
                logout();
                onClose();
              }}
              colorScheme="red"
              ml={3}
            >
              Yes, sign me out
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}

export default IdleDialog;
