import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type DeleteServicesMutationVariables = Types.Exact<{
  serviceIds?: Types.Maybe<Array<Types.Scalars['uuid']> | Types.Scalars['uuid']>;
}>;


export type DeleteServicesMutation = { __typename?: 'mutation_root', delete_service?: { __typename?: 'service_mutation_response', affected_rows: number } | null | undefined };


export const DeleteServicesDocument = gql`
    mutation deleteServices($serviceIds: [uuid!]) {
  delete_service(where: {id: {_in: $serviceIds}}) {
    affected_rows
  }
}
    `;
export type DeleteServicesMutationFn = Apollo.MutationFunction<DeleteServicesMutation, DeleteServicesMutationVariables>;

/**
 * __useDeleteServicesMutation__
 *
 * To run a mutation, you first call `useDeleteServicesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteServicesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteServicesMutation, { data, loading, error }] = useDeleteServicesMutation({
 *   variables: {
 *      serviceIds: // value for 'serviceIds'
 *   },
 * });
 */
export function useDeleteServicesMutation(baseOptions?: Apollo.MutationHookOptions<DeleteServicesMutation, DeleteServicesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteServicesMutation, DeleteServicesMutationVariables>(DeleteServicesDocument, options);
      }
export type DeleteServicesMutationHookResult = ReturnType<typeof useDeleteServicesMutation>;
export type DeleteServicesMutationResult = Apollo.MutationResult<DeleteServicesMutation>;
export type DeleteServicesMutationOptions = Apollo.BaseMutationOptions<DeleteServicesMutation, DeleteServicesMutationVariables>;