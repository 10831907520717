import * as Types from '../../generated/graphql';

import { gql } from '@apollo/client';
export type PatientWalletTransactionFieldsFragment = { __typename?: 'patientWalletTransaction', id: any, totalBefore: number, totalAfter: number, amount: number, note: string, invoiceId?: any | null | undefined, createdAt: any, source: { __typename?: 'GetPatientWalletTransactionSourceOutput', id: any, type: string, name: string } };

export const PatientWalletTransactionFieldsFragmentDoc = gql`
    fragment PatientWalletTransactionFields on patientWalletTransaction {
  id
  source {
    id
    type
    name
  }
  totalBefore
  totalAfter
  amount
  note
  invoiceId
  createdAt
}
    `;