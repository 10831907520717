import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { InvoiceTemplateFieldsFragmentDoc } from '../../fragments/invoiceTemplateFields.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetInvoiceTemplatesQueryVariables = Types.Exact<{
  where?: Types.Maybe<Types.InvoiceTemplate_Bool_Exp>;
  limit?: Types.Maybe<Types.Scalars['Int']>;
  offset?: Types.Maybe<Types.Scalars['Int']>;
  orderBy?: Types.Maybe<Array<Types.InvoiceTemplate_Order_By> | Types.InvoiceTemplate_Order_By>;
}>;


export type GetInvoiceTemplatesQuery = { __typename?: 'query_root', invoiceTemplate: Array<{ __typename?: 'invoiceTemplate', id: any, title: string, invoiceValues: any }> };


export const GetInvoiceTemplatesDocument = gql`
    query GetInvoiceTemplates($where: invoiceTemplate_bool_exp, $limit: Int, $offset: Int, $orderBy: [invoiceTemplate_order_by!]) {
  invoiceTemplate(
    where: $where
    limit: $limit
    offset: $offset
    order_by: $orderBy
  ) {
    ...InvoiceTemplateFields
  }
}
    ${InvoiceTemplateFieldsFragmentDoc}`;

/**
 * __useGetInvoiceTemplatesQuery__
 *
 * To run a query within a React component, call `useGetInvoiceTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvoiceTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvoiceTemplatesQuery({
 *   variables: {
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetInvoiceTemplatesQuery(baseOptions?: Apollo.QueryHookOptions<GetInvoiceTemplatesQuery, GetInvoiceTemplatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInvoiceTemplatesQuery, GetInvoiceTemplatesQueryVariables>(GetInvoiceTemplatesDocument, options);
      }
export function useGetInvoiceTemplatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInvoiceTemplatesQuery, GetInvoiceTemplatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInvoiceTemplatesQuery, GetInvoiceTemplatesQueryVariables>(GetInvoiceTemplatesDocument, options);
        }
export type GetInvoiceTemplatesQueryHookResult = ReturnType<typeof useGetInvoiceTemplatesQuery>;
export type GetInvoiceTemplatesLazyQueryHookResult = ReturnType<typeof useGetInvoiceTemplatesLazyQuery>;
export type GetInvoiceTemplatesQueryResult = Apollo.QueryResult<GetInvoiceTemplatesQuery, GetInvoiceTemplatesQueryVariables>;