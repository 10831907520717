import * as Types from '../../generated/graphql';

import { gql } from '@apollo/client';
export type ConsumableFieldsFragment = { __typename?: 'consumable', id: any, cost: number, metadata: any, originalQuantity: any, quantity: any, isExpirable: boolean, expirationDate?: any | null | undefined, daysBeforeNotification?: number | null | undefined, shouldWarnQuantity: boolean, warningQuantity?: number | null | undefined, createdAt: any, updatedAt: any, archived: boolean, type: { __typename?: 'consumableType', id: any, name: string, requiredFields: any, unitStep: any, unitName: string, defaultNumberOfUnits: any, defaultPricePerUnit: number }, brand?: { __typename?: 'consumableBrand', id: any, name: string } | null | undefined, purchaseOrder?: { __typename?: 'purchaseOrder', id: any, orderNumber: string } | null | undefined };

export const ConsumableFieldsFragmentDoc = gql`
    fragment ConsumableFields on consumable {
  id
  cost
  metadata
  originalQuantity
  quantity
  isExpirable
  expirationDate
  daysBeforeNotification
  shouldWarnQuantity
  warningQuantity
  createdAt
  updatedAt
  type {
    id
    name
    requiredFields
    unitStep
    unitName
    defaultNumberOfUnits
    defaultPricePerUnit
  }
  brand {
    id
    name
  }
  purchaseOrder {
    id
    orderNumber
  }
  archived
}
    `;