import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { TaskFieldsFragmentDoc } from '../../fragments/taskFields.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetOneTaskQueryVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
}>;


export type GetOneTaskQuery = { __typename?: 'query_root', task_by_pk?: { __typename?: 'task', description?: string | null | undefined, dueDate: any, id: any, status?: string | null | undefined, comments: Array<{ __typename?: 'taskComment', createdAt: any, comment: string, id: any, updatedAt: any, author: { __typename?: 'user', firstName: string, id: any, lastName: string, title?: string | null | undefined } }>, provider?: { __typename?: 'provider', firstName: string, id: any, lastName: string, profilePicture?: string | null | undefined, title?: string | null | undefined } | null | undefined, patient?: { __typename?: 'patient', firstName: string, id: any, lastName: string, profilePicture?: { __typename?: 'photo', file?: { __typename?: 'FilePayload', thumbnailUrl?: string | null | undefined } | null | undefined } | null | undefined } | null | undefined, user?: { __typename?: 'user', firstName: string, id: any, lastName: string, profilePicture?: string | null | undefined, title?: string | null | undefined } | null | undefined } | null | undefined };


export const GetOneTaskDocument = gql`
    query GetOneTask($id: uuid!) {
  task_by_pk(id: $id) {
    ...TaskFields
  }
}
    ${TaskFieldsFragmentDoc}`;

/**
 * __useGetOneTaskQuery__
 *
 * To run a query within a React component, call `useGetOneTaskQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOneTaskQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOneTaskQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetOneTaskQuery(baseOptions: Apollo.QueryHookOptions<GetOneTaskQuery, GetOneTaskQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOneTaskQuery, GetOneTaskQueryVariables>(GetOneTaskDocument, options);
      }
export function useGetOneTaskLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOneTaskQuery, GetOneTaskQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOneTaskQuery, GetOneTaskQueryVariables>(GetOneTaskDocument, options);
        }
export type GetOneTaskQueryHookResult = ReturnType<typeof useGetOneTaskQuery>;
export type GetOneTaskLazyQueryHookResult = ReturnType<typeof useGetOneTaskLazyQuery>;
export type GetOneTaskQueryResult = Apollo.QueryResult<GetOneTaskQuery, GetOneTaskQueryVariables>;