import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { ConsumableBrandFieldsFragmentDoc } from '../../fragments/consumableBrandFields.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetConsumableBrandsQueryVariables = Types.Exact<{
  where?: Types.Maybe<Types.ConsumableBrand_Bool_Exp>;
  limit?: Types.Maybe<Types.Scalars['Int']>;
  offset?: Types.Maybe<Types.Scalars['Int']>;
  orderBy?: Types.Maybe<Array<Types.ConsumableBrand_Order_By> | Types.ConsumableBrand_Order_By>;
}>;


export type GetConsumableBrandsQuery = { __typename?: 'query_root', consumableBrand: Array<{ __typename?: 'consumableBrand', id: any, name: string, contactName?: string | null | undefined, contactEmail?: string | null | undefined, contactPhoneNumber?: string | null | undefined, note?: string | null | undefined, createdAt: any, updatedAt: any, archived: boolean }> };


export const GetConsumableBrandsDocument = gql`
    query GetConsumableBrands($where: consumableBrand_bool_exp, $limit: Int, $offset: Int, $orderBy: [consumableBrand_order_by!]) {
  consumableBrand(
    where: $where
    limit: $limit
    offset: $offset
    order_by: $orderBy
  ) {
    ...ConsumableBrandFields
  }
}
    ${ConsumableBrandFieldsFragmentDoc}`;

/**
 * __useGetConsumableBrandsQuery__
 *
 * To run a query within a React component, call `useGetConsumableBrandsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetConsumableBrandsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetConsumableBrandsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetConsumableBrandsQuery(baseOptions?: Apollo.QueryHookOptions<GetConsumableBrandsQuery, GetConsumableBrandsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetConsumableBrandsQuery, GetConsumableBrandsQueryVariables>(GetConsumableBrandsDocument, options);
      }
export function useGetConsumableBrandsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetConsumableBrandsQuery, GetConsumableBrandsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetConsumableBrandsQuery, GetConsumableBrandsQueryVariables>(GetConsumableBrandsDocument, options);
        }
export type GetConsumableBrandsQueryHookResult = ReturnType<typeof useGetConsumableBrandsQuery>;
export type GetConsumableBrandsLazyQueryHookResult = ReturnType<typeof useGetConsumableBrandsLazyQuery>;
export type GetConsumableBrandsQueryResult = Apollo.QueryResult<GetConsumableBrandsQuery, GetConsumableBrandsQueryVariables>;