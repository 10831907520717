import { PayrixTokenResponse, PayrixTxnTokenResponse } from '@webapp/types';

function isTxnToken(
  x: PayrixTokenResponse | PayrixTxnTokenResponse
): x is PayrixTxnTokenResponse {
  const [data] = x.data;

  return Boolean(
    (data as PayrixTxnTokenResponse['data'][number]).token.customer
  );
}

export default isTxnToken;
