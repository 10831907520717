import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetExternalVendorWebhookTokenQueryVariables = Types.Exact<{
  vendorName: Types.Scalars['String'];
  workspaceId: Types.Scalars['uuid'];
}>;


export type GetExternalVendorWebhookTokenQuery = { __typename?: 'query_root', getExternalVendorWebhookToken?: { __typename?: 'ExternalVendorWebhookTokenOutput', token: string } | null | undefined };


export const GetExternalVendorWebhookTokenDocument = gql`
    query GetExternalVendorWebhookToken($vendorName: String!, $workspaceId: uuid!) {
  getExternalVendorWebhookToken(
    vendorName: $vendorName
    workspaceId: $workspaceId
  ) {
    token
  }
}
    `;

/**
 * __useGetExternalVendorWebhookTokenQuery__
 *
 * To run a query within a React component, call `useGetExternalVendorWebhookTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExternalVendorWebhookTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExternalVendorWebhookTokenQuery({
 *   variables: {
 *      vendorName: // value for 'vendorName'
 *      workspaceId: // value for 'workspaceId'
 *   },
 * });
 */
export function useGetExternalVendorWebhookTokenQuery(baseOptions: Apollo.QueryHookOptions<GetExternalVendorWebhookTokenQuery, GetExternalVendorWebhookTokenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetExternalVendorWebhookTokenQuery, GetExternalVendorWebhookTokenQueryVariables>(GetExternalVendorWebhookTokenDocument, options);
      }
export function useGetExternalVendorWebhookTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetExternalVendorWebhookTokenQuery, GetExternalVendorWebhookTokenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetExternalVendorWebhookTokenQuery, GetExternalVendorWebhookTokenQueryVariables>(GetExternalVendorWebhookTokenDocument, options);
        }
export type GetExternalVendorWebhookTokenQueryHookResult = ReturnType<typeof useGetExternalVendorWebhookTokenQuery>;
export type GetExternalVendorWebhookTokenLazyQueryHookResult = ReturnType<typeof useGetExternalVendorWebhookTokenLazyQuery>;
export type GetExternalVendorWebhookTokenQueryResult = Apollo.QueryResult<GetExternalVendorWebhookTokenQuery, GetExternalVendorWebhookTokenQueryVariables>;