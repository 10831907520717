import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type InsertSmsMarketingTagMutationVariables = Types.Exact<{
  marketingTagSms: Types.MarketingTagSms_Insert_Input;
}>;


export type InsertSmsMarketingTagMutation = { __typename?: 'mutation_root', insert_marketingTagSms?: { __typename?: 'marketingTagSms_mutation_response', returning: Array<{ __typename?: 'marketingTagSms', id: any }> } | null | undefined };


export const InsertSmsMarketingTagDocument = gql`
    mutation insertSmsMarketingTag($marketingTagSms: marketingTagSms_insert_input!) {
  insert_marketingTagSms(objects: [$marketingTagSms]) {
    returning {
      id
    }
  }
}
    `;
export type InsertSmsMarketingTagMutationFn = Apollo.MutationFunction<InsertSmsMarketingTagMutation, InsertSmsMarketingTagMutationVariables>;

/**
 * __useInsertSmsMarketingTagMutation__
 *
 * To run a mutation, you first call `useInsertSmsMarketingTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertSmsMarketingTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertSmsMarketingTagMutation, { data, loading, error }] = useInsertSmsMarketingTagMutation({
 *   variables: {
 *      marketingTagSms: // value for 'marketingTagSms'
 *   },
 * });
 */
export function useInsertSmsMarketingTagMutation(baseOptions?: Apollo.MutationHookOptions<InsertSmsMarketingTagMutation, InsertSmsMarketingTagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertSmsMarketingTagMutation, InsertSmsMarketingTagMutationVariables>(InsertSmsMarketingTagDocument, options);
      }
export type InsertSmsMarketingTagMutationHookResult = ReturnType<typeof useInsertSmsMarketingTagMutation>;
export type InsertSmsMarketingTagMutationResult = Apollo.MutationResult<InsertSmsMarketingTagMutation>;
export type InsertSmsMarketingTagMutationOptions = Apollo.BaseMutationOptions<InsertSmsMarketingTagMutation, InsertSmsMarketingTagMutationVariables>;