/* eslint-disable camelcase */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
/* eslint-disable max-classes-per-file */
import {
  Button,
  Center,
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Spinner,
  Stack,
} from '@chakra-ui/react';

import { SurveyPDF, FlatRepository, PdfBrick, FlatQuestion } from 'survey-pdf';
import {
  ConsentFieldsFragment,
  InsertSignatureDocument,
  StorageType,
  useGetMinimalPatientQuery,
  useGetPatientConsentsQuery,
  useInsertDocumentMutation,
  useInsertPatientConsentMutation,
  PatientConsent_Order_By,
  OrderBy,
  useUpdatePatientConsentMutation,
  useUpdateDocumentMutation,
  useUpdatePatientWorkspaceMutation,
  useGeneratePatientConsentPdfMutation,
  Tag_Update_Column,
  Tag_Constraint,
} from '@webapp/graphql';
import { Survey } from 'survey-react-ui';
import {
  ComponentCollection,
  Model,
  Serializer,
  setLicenseKey,
} from 'survey-core';
import { useEffect, useRef, useState, useCallback } from 'react';
import { dataURLtoFile } from '@webapp/utils';
import { useUploadToS3 } from '@webapp/hooks';
import toast from 'react-hot-toast';
import dayjs from 'dayjs';
import { useApolloClient } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { LoadingFullScreen } from '../loading-full-screen/loading-full-screen';
import { VerifyPinModal } from '../VerifyPinModal/VerifyPinModal';
import styles from './SurveyJSConsentForm.module.scss';
import printStyles from './SurveyJSConsentForm.print.module.scss';
import configureSurveyJSConsentComponents from '../CreateSurveyJSConsentForm/configureSurveyJSConsentComponents';

setLicenseKey(
  'MTMxYTY2ODMtNTQxMC00NWIzLTliMGMtZGMyN2RiMGUzMzgyOzE9MjAyNS0wMi0wOCwyPTIwMjUtMDItMDgsND0yMDI1LTAyLTA4'
);

/* eslint-disable-next-line */
export interface SurveyJSConsentFormProps {
  consent: ConsentFieldsFragment;
  patientId: string;
  appointmentId?: string;
  isPinDisabled?: boolean;
  onCompleted?: () => void;
  onVerify?: () => void;
  savePdf?: boolean;
}

const exportToPdfOptions = {
  fontSize: 12,
  haveCommercialLicense: true,
  htmlRenderAs: 'standard',
};

async function saveSignatures(
  surveyData: any,
  uploadToS3: any,
  workspaceId: string,
  client: any
) {
  const updateFields: any = {};

  for (const key of Object.keys(surveyData)) {
    if (surveyData[key].signatureType) {
      switch (surveyData[key].signatureType) {
        case 'Patient': {
          const file = dataURLtoFile(
            surveyData[key].signature,
            `${workspaceId}-${dayjs().format(
              'YYYY-MM-DD'
            )}-patient-signature.png`
          );
          const filePath = await uploadToS3({
            fileType: file.type,
            fileContents: file,
            filePath: file.name,
            storageType: StorageType.Document,
            randomizeFileName: true,
            onProgress: () => null,
          });
          const { data: sigResponse } = await client.mutate({
            mutation: InsertSignatureDocument,
            variables: {
              signature: {
                filePath,
              },
            },
          });
          updateFields.signatureId =
            sigResponse?.insert_signature?.returning[0].id;
          break;
        }
        case 'Witness': {
          const file = dataURLtoFile(
            surveyData[key].signature,
            `${workspaceId}-${dayjs().format(
              'YYYY-MM-DD'
            )}-witness-signature.png`
          );
          const filePath = await uploadToS3({
            fileType: file.type,
            fileContents: file,
            filePath: file.name,
            storageType: StorageType.Document,
            randomizeFileName: true,
            onProgress: null,
          });
          const { data: sigResponse } = await client.mutate({
            mutation: InsertSignatureDocument,
            variables: {
              signature: {
                filePath,
              },
            },
          });
          updateFields.witnessSignatureId =
            sigResponse?.insert_signature?.returning[0].id;
          break;
        }
        case 'Provider': {
          const file = dataURLtoFile(
            surveyData[key].signature,
            `${workspaceId}-${dayjs().format(
              'YYYY-MM-DD'
            )}-provider-signature.png`
          );
          const filePath = await uploadToS3({
            fileType: file.type,
            fileContents: file,
            filePath: file.name,
            storageType: StorageType.Document,
            randomizeFileName: true,
            onProgress: () => null,
          });
          const { data: sigResponse } = await client.mutate({
            mutation: InsertSignatureDocument,
            variables: {
              signature: {
                filePath,
              },
            },
          });
          updateFields.providerSignatureId =
            sigResponse?.insert_signature?.returning[0].id;
          break;
        }
        default:
          break;
      }
    }
  }

  return updateFields;
}
export function SurveyJSConsentForm({
  consent,
  patientId,
  appointmentId,
  onCompleted = () => undefined,
  savePdf = false,
}: SurveyJSConsentFormProps) {
  const [saving, setSaving] = useState(false);
  const [patientReady, setPatientReady] = useState(false);
  const [pastConsentReady, setPastConsentReady] = useState(false);
  const surveyRef = useRef<any>();
  const [survey, setSurvey] = useState<any>(null);

  // #region Queries
  const { data: minimalPatient } = useGetMinimalPatientQuery({
    variables: {
      id: patientId,
    },
    onCompleted: () => {
      setPatientReady(true);
    },
  });

  const { data: existingPatientConsentData } = useGetPatientConsentsQuery({
    variables: {
      where: {
        patientId: {
          _eq: patientId,
        },
        consentId: {
          _eq: consent.id,
        },
      },
      limit: 1,
      offset: 0,
      orderBy: [
        {
          createdAt: OrderBy.Desc,
        },
      ],
    },
    onCompleted: () => {
      setPastConsentReady(true);
    },
  });
  // #endregion

  // #region Mutations
  const [updatePatientWorkspace] = useUpdatePatientWorkspaceMutation();
  const [updatePatientConsent] = useUpdatePatientConsentMutation();
  const [insertPatientConsent] = useInsertPatientConsentMutation();

  // #endregion

  useEffect(() => {
    if (!pastConsentReady || !patientReady) return;
    const s = new Model(consent?.surveyJSJson);

    s.onValidateQuestion.add((s, options) => {
      if (options.question.classMetaData.name === 'name-signature-date') {
        if (
          !options.value ||
          !options.value.hasOwnProperty('name') ||
          !options.value.name ||
          !options.value.hasOwnProperty('signature') ||
          !options.value.signature
        ) {
          if (options.question.isRequired) {
            options.error = 'Name and signature are both required.';
          }
        }
      }
    });

    if (existingPatientConsentData?.patientConsent) {
      if (existingPatientConsentData?.patientConsent?.length > 0) {
        s.data =
          existingPatientConsentData?.patientConsent[0]?.surveyJSResponseJSON;
      }
    }

    if (
      minimalPatient?.patient_by_pk?.attributes?.firstName &&
      minimalPatient?.patient_by_pk?.attributes?.lastName
    ) {
      s.setVariable(
        'patientName',
        `${minimalPatient?.patient_by_pk?.attributes?.firstName} ${minimalPatient?.patient_by_pk?.attributes?.lastName}`
      );
    } else {
      s.setVariable('patientName', '');
    }

    s.setVariable('workspaceName', consent?.workspace?.name);

    surveyRef.current = s;

    setSurvey(s);
    if (savePdf) {
      s.mode = 'display';
      s.showNavigationButtons = false;
      s.showCompleteButton = false;
      s.showCompletedPage = false;
    } else {
      s.onComplete.add(handleSurveyComplete);
    }
  }, [pastConsentReady, patientReady, savePdf]);

  const handleMediaConsentChanges = async (surveyResponseData: any) => {
    let consentType: string | undefined;

    // Check for the new structure using surveyJSJson
    const mediaConsentQuestion = consent.surveyJSJson.pages
      .flatMap((page: any) => page.elements)
      .find((element: any) => element.type === 'custom-media-consent');

    if (mediaConsentQuestion && surveyResponseData[mediaConsentQuestion.name]) {
      consentType = surveyResponseData[mediaConsentQuestion.name];
    }

    // If not found, check for the old structure
    if (!consentType) {
      for (const key of Object.keys(surveyResponseData)) {
        if (
          Object.keys(surveyResponseData[key]).includes(
            'patient-media-consent-type'
          )
        ) {
          consentType = surveyResponseData[key]['patient-media-consent-type'];
          break;
        }
      }
    }

    if (!consentType) return;

    switch (consentType) {
      case 'FULL_CONSENT':
        await updatePatientWorkspace({
          variables: {
            where: {
              patientId: { _eq: patientId },
              workspaceId: { _eq: consent.workspaceId },
            },
            _set: { fullMediaConsent: true },
          },
        });
        break;
      case 'FULL_ANONYMIZED_CONSENT':
        await updatePatientWorkspace({
          variables: {
            where: {
              patientId: { _eq: patientId },
              workspaceId: { _eq: consent.workspaceId },
            },
            _set: { fullAnonymousMediaConsent: true },
          },
        });
        break;
      case 'NO_CONSENT':
        await updatePatientWorkspace({
          variables: {
            where: {
              patientId: { _eq: patientId },
              workspaceId: { _eq: consent.workspaceId },
            },
            _set: {
              fullAnonymousMediaConsent: false,
              fullMediaConsent: false,
              partialMediaConsent: false,
            },
          },
        });
        break;
      case 'CLINICAL_CONSENT':
        await updatePatientWorkspace({
          variables: {
            where: {
              patientId: { _eq: patientId },
              workspaceId: { _eq: consent.workspaceId },
            },
            _set: {
              partialMediaConsent: true,
              fullAnonymousMediaConsent: false,
              fullMediaConsent: false,
            },
          },
        });
        break;
    }
  };

  const handleSurveyComplete = useCallback(
    async (sender: any) => {
      console.log(sender.data, 'sender.data');
      setSaving(true);

      const name = `${minimalPatient?.patient_by_pk?.attributes.firstName} ${
        minimalPatient?.patient_by_pk?.attributes.lastName
      } - ${consent.title}: ${dayjs().format('MM-DD-YYYY')}`;
      if (
        existingPatientConsentData?.patientConsent &&
        existingPatientConsentData?.patientConsent?.length > 0 &&
        existingPatientConsentData?.patientConsent[0]?.document?.id
      ) {
        await updatePatientConsent({
          variables: {
            set: {
              name,
              date: new Date(),
              appointmentId,
              surveyJSResponseJSON: sender.data,
            },
            id: existingPatientConsentData?.patientConsent[0]?.id,
          },
        });
      } else {
        await insertPatientConsent({
          variables: {
            patientConsent: {
              patientId,
              consentId: consent.id,

              name,
              date: new Date(),
              appointmentId,
              surveyJSResponseJSON: sender.data,
            },
          },
        });
      }

      await handleMediaConsentChanges(sender.data);

      setSaving(false);
      onCompleted();
    },
    [patientReady, pastConsentReady]
  );

  configureSurveyJSConsentComponents();

  if (!survey || !pastConsentReady || !patientReady)
    return <LoadingFullScreen />;

  return (
    <>
      <style jsx global>{`
        @media print {
          .sd-root-modern.sd-progress--pages.sd-root--readonly.sd-root-modern--full-container {
            overflow: visible !important;
          }
          .chakra-text {
            overflow: visible !important;
            page-break-inside: avoid !important;
          }
          .sd-body.sd-body--responsive {
            padding: 4px !important;
          }
        }
      `}</style>
      <Survey model={survey} showCompletedPage={false} />
      <div id="survey-probably-rendered"></div>

      {!savePdf && (
        <Modal isCentered isOpen={saving} onClose={null}>
          <ModalOverlay bg="none" backdropFilter="auto" backdropBlur="4px" />
          <ModalContent>
            <ModalBody>
              <Center>
                <Stack alignItems={'center'}>
                  <Heading size="sm">Saving Consent...</Heading>
                  <Spinner size="lg" />
                </Stack>
              </Center>
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
    </>
  );
}

export default SurveyJSConsentForm;
