import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetCustomFormResponsesAggregateQueryVariables = Types.Exact<{
  where?: Types.Maybe<Types.CustomFormResponse_Bool_Exp>;
}>;


export type GetCustomFormResponsesAggregateQuery = { __typename?: 'query_root', customFormResponse_aggregate: { __typename?: 'customFormResponse_aggregate', aggregate?: { __typename?: 'customFormResponse_aggregate_fields', count: number } | null | undefined } };


export const GetCustomFormResponsesAggregateDocument = gql`
    query GetCustomFormResponsesAggregate($where: customFormResponse_bool_exp) {
  customFormResponse_aggregate(where: $where) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetCustomFormResponsesAggregateQuery__
 *
 * To run a query within a React component, call `useGetCustomFormResponsesAggregateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomFormResponsesAggregateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomFormResponsesAggregateQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetCustomFormResponsesAggregateQuery(baseOptions?: Apollo.QueryHookOptions<GetCustomFormResponsesAggregateQuery, GetCustomFormResponsesAggregateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCustomFormResponsesAggregateQuery, GetCustomFormResponsesAggregateQueryVariables>(GetCustomFormResponsesAggregateDocument, options);
      }
export function useGetCustomFormResponsesAggregateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCustomFormResponsesAggregateQuery, GetCustomFormResponsesAggregateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCustomFormResponsesAggregateQuery, GetCustomFormResponsesAggregateQueryVariables>(GetCustomFormResponsesAggregateDocument, options);
        }
export type GetCustomFormResponsesAggregateQueryHookResult = ReturnType<typeof useGetCustomFormResponsesAggregateQuery>;
export type GetCustomFormResponsesAggregateLazyQueryHookResult = ReturnType<typeof useGetCustomFormResponsesAggregateLazyQuery>;
export type GetCustomFormResponsesAggregateQueryResult = Apollo.QueryResult<GetCustomFormResponsesAggregateQuery, GetCustomFormResponsesAggregateQueryVariables>;