import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetCompensationTotalsQueryVariables = Types.Exact<{
  from?: Types.Maybe<Types.Scalars['date']>;
  until?: Types.Maybe<Types.Scalars['date']>;
  userIds?: Types.Maybe<Array<Types.Scalars['uuid']> | Types.Scalars['uuid']>;
}>;


export type GetCompensationTotalsQuery = { __typename?: 'query_root', compensationTotals: { __typename?: 'CompensationTotalsOutput', users: Array<{ __typename?: 'CompensationByUser', user: { __typename?: 'CompensationUser', id: string, name: string }, summary: { __typename?: 'CompensationSummary', compensationTotal: number }, categories: Array<{ __typename?: 'CompensationCategoryTotal', serviceCategoryId: any, preTaxTotal: number, postTaxTotal: number, quantity: number, compensationTotal: number, category?: { __typename?: 'CompensationCategory', id: string, title: string } | null | undefined }>, services: Array<{ __typename?: 'CompensationServiceTotal', name: string, preTaxTotal: number, postTaxTotal: number, quantity: number, compensationTotal: number, taxTotal: number }> }>, lineItems: Array<{ __typename?: 'CompensationLineItem', compensationTotal: number, createdAt: string, discountTotal: number, invoiceId: string, name: string, paidOn?: string | null | undefined, postTaxTotal: number, preTaxTotal: number, quantity: number, taxTotal: number, serviceCategory?: { __typename?: 'CompensationCategory', id: string, title: string } | null | undefined, user: { __typename?: 'CompensationUser', id: string, name: string } }> }, serviceCategory: Array<{ __typename?: 'serviceCategory', id: any, title: string }>, service: Array<{ __typename?: 'service', id: any, name: string }> };


export const GetCompensationTotalsDocument = gql`
    query GetCompensationTotals($from: date, $until: date, $userIds: [uuid!]) {
  compensationTotals(from: $from, until: $until, userIds: $userIds) {
    users {
      user {
        id
        name
      }
      summary {
        compensationTotal
      }
      categories {
        category {
          id
          title
        }
        serviceCategoryId
        preTaxTotal
        postTaxTotal
        quantity
        compensationTotal
      }
      services {
        name
        preTaxTotal
        postTaxTotal
        quantity
        compensationTotal
        taxTotal
      }
    }
    lineItems {
      compensationTotal
      createdAt
      discountTotal
      invoiceId
      name
      paidOn
      postTaxTotal
      preTaxTotal
      quantity
      serviceCategory {
        id
        title
      }
      taxTotal
      user {
        id
        name
      }
    }
  }
  serviceCategory {
    id
    title
  }
  service {
    id
    name
  }
}
    `;

/**
 * __useGetCompensationTotalsQuery__
 *
 * To run a query within a React component, call `useGetCompensationTotalsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompensationTotalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompensationTotalsQuery({
 *   variables: {
 *      from: // value for 'from'
 *      until: // value for 'until'
 *      userIds: // value for 'userIds'
 *   },
 * });
 */
export function useGetCompensationTotalsQuery(baseOptions?: Apollo.QueryHookOptions<GetCompensationTotalsQuery, GetCompensationTotalsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCompensationTotalsQuery, GetCompensationTotalsQueryVariables>(GetCompensationTotalsDocument, options);
      }
export function useGetCompensationTotalsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCompensationTotalsQuery, GetCompensationTotalsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCompensationTotalsQuery, GetCompensationTotalsQueryVariables>(GetCompensationTotalsDocument, options);
        }
export type GetCompensationTotalsQueryHookResult = ReturnType<typeof useGetCompensationTotalsQuery>;
export type GetCompensationTotalsLazyQueryHookResult = ReturnType<typeof useGetCompensationTotalsLazyQuery>;
export type GetCompensationTotalsQueryResult = Apollo.QueryResult<GetCompensationTotalsQuery, GetCompensationTotalsQueryVariables>;