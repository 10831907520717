import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { AnyJsonValue } from '../custom-types/any-json-value';
/**
 * A LeadFormFieldStore model.
 */
// prettier-ignore
export const LeadFormFieldModel = types.model("LeadFormField").props({
  id: types.maybeNull(types.identifier),
  label: types.string,
  apiFieldKey: types.string,
  type: types.string,
  order: types.number,
  workspaceId:  types.maybeNull(types.string),
  validationProps: types.maybeNull(types.map(AnyJsonValue)),
}).actions((self) => ({
    setOrder(value: number) { self.order = value }
}))
/**
 * The LeadFormField instance.
 */
export type LeadFormField = Instance<typeof LeadFormFieldModel>;

/**
 * The data of a LeadFormField.
 */
export type LeadFormFieldSnapshot = SnapshotOut<typeof LeadFormFieldModel>;
