import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { LocationScheduleFieldsFragmentDoc } from '../../fragments/locationScheduleFields.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type UpdateLocationScheduleMutationVariables = Types.Exact<{
  _set?: Types.Maybe<Types.LocationSchedule_Set_Input>;
  id: Types.Scalars['uuid'];
}>;


export type UpdateLocationScheduleMutation = { __typename?: 'mutation_root', update_locationSchedule?: { __typename?: 'locationSchedule_mutation_response', returning: Array<{ __typename?: 'locationSchedule', id: any, schedule: any, effectiveFrom?: any | null | undefined, createdAt: any, updatedAt: any, locationId: any }> } | null | undefined };


export const UpdateLocationScheduleDocument = gql`
    mutation updateLocationSchedule($_set: locationSchedule_set_input, $id: uuid!) {
  update_locationSchedule(_set: $_set, where: {id: {_eq: $id}}) {
    returning {
      ...LocationScheduleFields
    }
  }
}
    ${LocationScheduleFieldsFragmentDoc}`;
export type UpdateLocationScheduleMutationFn = Apollo.MutationFunction<UpdateLocationScheduleMutation, UpdateLocationScheduleMutationVariables>;

/**
 * __useUpdateLocationScheduleMutation__
 *
 * To run a mutation, you first call `useUpdateLocationScheduleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLocationScheduleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLocationScheduleMutation, { data, loading, error }] = useUpdateLocationScheduleMutation({
 *   variables: {
 *      _set: // value for '_set'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateLocationScheduleMutation(baseOptions?: Apollo.MutationHookOptions<UpdateLocationScheduleMutation, UpdateLocationScheduleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateLocationScheduleMutation, UpdateLocationScheduleMutationVariables>(UpdateLocationScheduleDocument, options);
      }
export type UpdateLocationScheduleMutationHookResult = ReturnType<typeof useUpdateLocationScheduleMutation>;
export type UpdateLocationScheduleMutationResult = Apollo.MutationResult<UpdateLocationScheduleMutation>;
export type UpdateLocationScheduleMutationOptions = Apollo.BaseMutationOptions<UpdateLocationScheduleMutation, UpdateLocationScheduleMutationVariables>;