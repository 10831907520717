import { Instance, SnapshotOut, types } from 'mobx-state-tree';

/**
 * A ServiceStore model.
 */
// prettier-ignore
export const ServiceModel = types
  .model('Service')
  .props({
    appointmentType: types.maybeNull(
      types
        .model('AppointmentType')
        .props({ color: types.maybeNull(types.string) })
    ),
    color: types.maybeNull(types.string),
    deposit: types.maybeNull(types.number),
    id: types.identifier,
    isProduct: types.maybeNull(types.boolean),
    minutesToPerform: types.optional(types.number, 30),
    name: types.string,
    newPatientMinutesToPerform: types.maybeNull(types.number),
    requiresDeposit: types.maybeNull(types.boolean),
  })
  .actions((self) => ({}));
/**
 * The Service instance.
 */
export type Service = Instance<typeof ServiceModel>;

/**
 * The data of a Service.
 */
export type ServiceSnapshot = SnapshotOut<typeof ServiceModel>;
