import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type PatientTagsAggregateQueryVariables = Types.Exact<{
  where?: Types.Maybe<Types.PatientTags_Bool_Exp>;
}>;


export type PatientTagsAggregateQuery = { __typename?: 'query_root', patientTags_aggregate: { __typename?: 'patientTags_aggregate', aggregate?: { __typename?: 'patientTags_aggregate_fields', count: number } | null | undefined } };


export const PatientTagsAggregateDocument = gql`
    query patientTagsAggregate($where: patientTags_bool_exp) {
  patientTags_aggregate(where: $where) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __usePatientTagsAggregateQuery__
 *
 * To run a query within a React component, call `usePatientTagsAggregateQuery` and pass it any options that fit your needs.
 * When your component renders, `usePatientTagsAggregateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePatientTagsAggregateQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function usePatientTagsAggregateQuery(baseOptions?: Apollo.QueryHookOptions<PatientTagsAggregateQuery, PatientTagsAggregateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PatientTagsAggregateQuery, PatientTagsAggregateQueryVariables>(PatientTagsAggregateDocument, options);
      }
export function usePatientTagsAggregateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PatientTagsAggregateQuery, PatientTagsAggregateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PatientTagsAggregateQuery, PatientTagsAggregateQueryVariables>(PatientTagsAggregateDocument, options);
        }
export type PatientTagsAggregateQueryHookResult = ReturnType<typeof usePatientTagsAggregateQuery>;
export type PatientTagsAggregateLazyQueryHookResult = ReturnType<typeof usePatientTagsAggregateLazyQuery>;
export type PatientTagsAggregateQueryResult = Apollo.QueryResult<PatientTagsAggregateQuery, PatientTagsAggregateQueryVariables>;