import * as Types from '../../generated/graphql';

import { gql } from '@apollo/client';
export type ConsumableTypeFieldsFragment = { __typename?: 'consumableType', id: any, name: string, requiredFields: any, defaultNumberOfUnits: any, defaultPricePerUnit: number, unitStep: any, unitName: string, createdAt: any, updatedAt: any, archived: boolean, productBarcodeId?: string | null | undefined, consumableTypeConsumableSubtypes: Array<{ __typename?: 'consumableTypeConsumableSubtype', id: any, consumableSubtype: { __typename?: 'consumableSubtype', id: any, name: string } }> };

export const ConsumableTypeFieldsFragmentDoc = gql`
    fragment ConsumableTypeFields on consumableType {
  id
  name
  requiredFields
  defaultNumberOfUnits
  defaultPricePerUnit
  unitStep
  unitName
  createdAt
  updatedAt
  archived
  productBarcodeId
  consumableTypeConsumableSubtypes {
    id
    consumableSubtype {
      id
      name
    }
  }
}
    `;