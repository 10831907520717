import { VStack } from '@chakra-ui/react';
import {
  GetMembershipsQuery,
  SearchServicesQuery,
  useGetInvoiceTotalsQuery,
} from '@webapp/graphql';
import { ObjectOption } from '@webapp/types';
import { useEffect, useMemo, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import toast from 'react-hot-toast';
import { createInvoiceLedgerItemsDeprecated } from '@webapp/utils';
import { useFlagsmith } from 'flagsmith-react';
import Ledger from '../Ledger/Ledger';
import { AppliedCoupon, PackageObjectOption } from './types';
import InvoiceFormGratuityModal from './InvoiceFormGratuityModal';

interface InvoiceLedgerProps {
  invoiceId?: string;
}

function InvoiceLedger({ invoiceId }: InvoiceLedgerProps) {
  const { getValues, setValue, watch } = useFormContext();
  const { hasFeature } = useFlagsmith();

  const gratuity = useWatch({ name: 'gratuity' });
  const status = getValues('status');

  const services: ObjectOption<SearchServicesQuery['service'][number]>[] =
    useWatch({ name: 'services' });

  const memberships: ObjectOption<GetMembershipsQuery['membership'][number]>[] =
    useWatch({
      name: 'memberships',
    });

  const watchedServices = watch(
    (services ?? []).map((_, index) => `services[${index}]`)
  );

  const servicesMap = useMemo(() => {
    const map: {
      [id: string]: {
        customPrice?: number;
        quantity: number;
        taxRate?: number;
      };
    } = {};

    const reducedMap = watchedServices.reduce((acc, service) => {
      if (service && service.object.serviceId) {
        const key = `${service.object.serviceId}:${service.object.pricePerUnit}`;
        const currentValue = acc[key];
        const currentQuantity = currentValue?.quantity ?? 0;

        return {
          ...acc,
          [key]: {
            customPrice: service.object.pricePerUnit,
            quantity: currentQuantity + service.object.quantity,
            taxRate: service.object.taxRate,
          },
        };
      }

      return acc;
    }, map);

    return reducedMap;
  }, [watchedServices]);

  const packages: PackageObjectOption[] = useWatch({ name: 'packages' });

  const watchedPackages = watch(
    (packages ?? []).map((_, index) => `packages[${index}]`)
  );

  const packagesMap = useMemo(() => {
    const map: {
      [id: string]: {
        quantityToUse: number;
        quantityToBuy: number;
      };
    } = {};

    watchedPackages.forEach((packageOption) => {
      if (packageOption?.object?.id) {
        map[packageOption.object.id] = {
          quantityToUse: packageOption.object.quantityToUse,
          quantityToBuy: 1,
        };
      }
    });

    return map;
  }, [watchedPackages]);

  const appliedCoupons: AppliedCoupon[] = watch('appliedCoupons');

  const couponIds = useMemo(() => {
    const coupons = appliedCoupons;
    return coupons?.map(({ couponId }) => couponId) ?? [];
  }, [appliedCoupons]);

  const membershipIds =
    memberships
      ?.filter((membership) => membership.value)
      .map((membership) => membership.value) ?? [];

  const { data, loading } = useGetInvoiceTotalsQuery({
    onCompleted: ({ getInvoiceTotals }) => {
      setValue('balanceDue', getInvoiceTotals.balanceDue);
      setValue('depositPaid', getInvoiceTotals.depositPaid);
      setValue('discount', getInvoiceTotals.discount);
      setValue('creditsApplied', getInvoiceTotals.creditsApplied);
      setValue('gratuity', getInvoiceTotals.gratuity);
      setValue('preTaxTotal', getInvoiceTotals.preTaxTotal);
      setValue('postTaxTotal', getInvoiceTotals.postTaxTotal);
      setValue('taxTotal', getInvoiceTotals.taxTotal);
    },
    onError: (e) => {
      toast.error(e.message);
    },
    variables: {
      gratuity,
      invoiceId,
      services: servicesMap,
      couponIds,
      membershipIds,
      packages: packagesMap,
    },
  });

  const totals = data?.getInvoiceTotals ?? ({} as any);

  const ledgerItems = createInvoiceLedgerItemsDeprecated({ ...totals, status });

  const [canTip, setCanTip] = useState<boolean>(false);

  useEffect(() => {
    const serviceItems = getValues('services') || [];
    const gratuityEnabledServices = serviceItems.find(
      (service: any) => service?.object?.allowGratuity
    );

    const hasGratuityEnabledServices =
      hasFeature('gratuity') && Boolean(gratuityEnabledServices);

    setCanTip(hasGratuityEnabledServices);
  }, [getValues, hasFeature]);

  useEffect(() => {
    const sub = watch((formData, options) => {
      const { name } = options;

      if (name?.match('service')) {
        const serviceItems = formData.services || [];

        const gratuityEnabledServices = serviceItems.find(
          (service: any) => service?.object?.allowGratuity
        );

        const hasGratuityEnabledServices =
          hasFeature('gratuity') && Boolean(gratuityEnabledServices);

        if (canTip && !hasGratuityEnabledServices) {
          setValue('gratuity', 0);
        }

        setCanTip(hasGratuityEnabledServices);
      }
    });

    return () => sub.unsubscribe();
  }, [watch]);

  return (
    <VStack alignItems="end" maxWidth="400px" width="100%">
      <Ledger isLoading={loading} items={ledgerItems} />
      {canTip && <InvoiceFormGratuityModal />}
    </VStack>
  );
}

export default InvoiceLedger;
