import { observer } from 'mobx-react-lite';
import Draggable from 'react-draggable';
import {
  Box,
  Center,
  Heading,
  HStack,
  IconButton,
  Stack,
} from '@chakra-ui/react';
import { useGetTelehealthMeetingQuery } from '@webapp/graphql';
import { useStores } from '@webapp/state-models';
import { LoadingFullScreen } from '@webapp/ui-composites';
import { useState } from 'react';
import {
  FaMinus,
  FaTimes,
  FaPlus,
  FaWindowMinimize,
  FaWindowMaximize,
} from 'react-icons/fa';
import { VscClose, VscMultipleWindows, VscWindow } from 'react-icons/vsc';
import '@whereby.com/browser-sdk/embed';

/* eslint-disable-next-line */
export interface TelehealthModalProps {}

export const TelehealthModal = observer((props: TelehealthModalProps) => {
  const [minimized, setMinimized] = useState(false);
  const {
    ui: {
      telehealthModal: { show, telehealthMeetingId },
      hideTelehealthModal,
    },
  } = useStores();

  const { data, loading } = useGetTelehealthMeetingQuery({
    variables: {
      where: {
        id: {
          _eq: telehealthMeetingId,
        },
      },
    },
    skip: !telehealthMeetingId,
  });

  if (!show || !telehealthMeetingId) return null;

  return (
    <Draggable
      {...(!minimized && {
        position: { x: 0, y: 0 },
      })}
    >
      <Stack
        bg="white"
        shadow="xl"
        rounded="md"
        position={'absolute'}
        transition={'all'}
        top={'0'}
        left={'0'}
        w={minimized ? '30vw' : '100vw'}
        h={minimized ? '30vw' : '100vh'}
        zIndex={9999999999}
      >
        <Center w={'full'} h={'full'} rounded="md" overflow="hidden">
          {loading ? (
            <LoadingFullScreen />
          ) : (
            <Stack w={'full'} h={'full'}>
              <HStack
                w={'full'}
                p={2}
                justifyContent="space-between"
                alignItems="center"
              >
                <Heading size="xs">
                  Telehealth meeting for{' '}
                  {data?.telehealthMeeting?.[0]?.patient.firstName}{' '}
                  {data?.telehealthMeeting?.[0]?.patient.lastName}
                </Heading>
                <HStack>
                  {minimized ? (
                    <IconButton
                      aria-label="Maximize"
                      icon={<VscWindow />}
                      onClick={() => setMinimized(false)}
                    />
                  ) : (
                    <IconButton
                      aria-label="Minimize"
                      icon={<VscMultipleWindows />}
                      onClick={() => setMinimized(true)}
                    />
                  )}
                  <IconButton
                    aria-label="Close"
                    icon={<VscClose />}
                    onClick={hideTelehealthModal}
                  />
                </HStack>
              </HStack>
              <Box w={'full'} h={'full'}>
                <whereby-embed
                  room={data?.telehealthMeeting[0]?.hostLink}
                  logo="off"
                  style={{ width: '100%', height: '100%' }}
                />
              </Box>
              {/* <iframe
                width={'100%'}
                height={'100%'}
                src={`${data?.telehealthMeeting[0]?.hostLink}&logo=off`}
                allow="camera; microphone; fullscreen; speaker; display-capture; autoplay"
              ></iframe> */}
            </Stack>
          )}
        </Center>
      </Stack>
    </Draggable>
  );
});

export default TelehealthModal;
