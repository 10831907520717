import { toast } from 'react-hot-toast';
import { flow, Instance, SnapshotOut, types } from 'mobx-state-tree';

import _, { sortBy, flattenDeep } from 'lodash';
import { getErrorMessage } from '@webapp/utils';
import {
  DeleteLeadFormStepDocument,
  GraphqlClient,
  InsertLeadFormStepDocument,
  InsertLeadFormStepMutationResult,
  SubmitLeadFormFieldsDocument,
  UpdateLeadFormDocument,
} from '@webapp/graphql';
import { LeadFormStepModel, LeadFormStep } from './lead-form-step';
import { AnyJsonValue } from '../custom-types/any-json-value';

const LockedObjectSelectModel = types.model('LockedObjectSelect').props({
  label: types.string,
  value: types.string,
  object: AnyJsonValue,
});

/**
 * A LeadFormStore model.
 */
// prettier-ignore
const model = types.model("LeadForm").props({
  id: types.identifier,
  name: types.maybeNull(types.string),
  businessLogo: types.maybeNull(types.string),
  sideBannerImage: types.maybeNull(types.string),
  headerCode: types.maybeNull(types.string),
  layout: types.maybeNull(types.string),
  formBackground: types.maybeNull(types.string),
  backgroundColor: types.maybeNull(types.string),
  font: types.maybeNull(types.string),
  lockedToServices: types.maybeNull(types.array(LockedObjectSelectModel)),
  lockedToLocations: types.maybeNull(types.array(LockedObjectSelectModel)),
  steps: types.optional(types.array(LeadFormStepModel),[]),
  workspaceId:  types.maybeNull(types.string),
  workspace: types.model({
    payrixMerchantId: types.maybeNull(types.string),
    workspaceConfiguration: types.maybeNull(types.model({
        noShowActive: types.maybeNull(types.boolean),
        noShowFee: types.maybeNull(types.integer)
    }))
  })
})
.views((self) => ({
    get sortedSteps() {
        return sortBy(self.steps, 'order')
    },
    get allFields() {
       
        return flattenDeep(self.steps.map(({fields}) => fields))
    }
})
)
.actions((self) => ({
  addStep: flow(function* addStep(type = 'input') {
      const leadFormStep = {
        order: self.steps.length - 1,
        name: `Form step ${self.steps.length + 1}`,
        callToAction: 'Continue',
        leadFormId: self.id,
        workspaceId: self.workspaceId,
        type
      } 
     
      try {
        const client = GraphqlClient()
        const {data}: InsertLeadFormStepMutationResult = yield client.mutate({mutation: InsertLeadFormStepDocument, variables: { leadFormStep }})
        
        self.sortedSteps.forEach(step => {
            if(step.order >= self.steps.length - 1) {
                step.setOrder(step.order + 1)
            }
        })
        
        self.steps.push(LeadFormStepModel.create(data?.insert_leadFormStep?.returning[0]))
        
      } catch(err) {
          toast.error(getErrorMessage(err))
          
      }
  }),
  removeStep: flow(function*(step: LeadFormStep) {
    if(!step.canDelete) {
        toast.error('This step cannot be deleted');  
        return
    }
    
    self.steps.remove(step)
      try {
        const client = GraphqlClient()
        yield client.mutate({mutation: DeleteLeadFormStepDocument, variables: { id: step.id }})
      } catch(err) {
          toast.error(getErrorMessage(err))
          // TODO: revert the step we deleted
      }
  }),
  updateBusinessLogo: flow(function*(logo: string) {
    self.businessLogo = logo
    const client = GraphqlClient()
    try {
        yield client.mutate({mutation: UpdateLeadFormDocument, variables: { id: self.id, set: {businessLogo: logo} }})
        toast.success('Business logo updated')
    } catch(err) {
        toast.error(getErrorMessage(err))
    }
  }),
  updateFormBackground: flow(function*(image: string) {
    self.formBackground = image
    const client = GraphqlClient()
    try {
        yield client.mutate({mutation: UpdateLeadFormDocument, variables: { id: self.id, set: {formBackground: image} }})
        toast.success('Form background image updated')
    } catch(err) {
        toast.error(getErrorMessage(err))
    }
  }),
  updateHeaderCode: flow(function*(headerCode: string) {
    self.headerCode = headerCode
    const client = GraphqlClient()
    try {
        yield client.mutate({mutation: UpdateLeadFormDocument, variables: { id: self.id, set: {headerCode} }})
        toast.success('Tracking code updated')
    } catch(err) {
        toast.error(getErrorMessage(err))
    }
    }),
  updateSideBannerImage: flow(function*(sideBannerImage: string) {
    self.sideBannerImage = sideBannerImage
    const client = GraphqlClient()
    try {
        yield client.mutate({mutation: UpdateLeadFormDocument, variables: { id: self.id, set: {sideBannerImage} }})
        toast.success('Side banner updated')
    } catch(err) {
        toast.error(getErrorMessage(err))
    }
  }),
  updateLayout: flow(function*(layout: string) {
    self.layout = layout
    const client = GraphqlClient()
    try {
        yield client.mutate({mutation: UpdateLeadFormDocument, variables: { id: self.id, set: { layout } }})
        toast.success('Layout updated')
    } catch(err) {
        toast.error(getErrorMessage(err))
    }
  }),
  updateLockedServices: flow(function*(lockedToServices: any) {
    self.lockedToServices = lockedToServices
    const client = GraphqlClient()
    try {
        yield client.mutate({mutation: UpdateLeadFormDocument, variables: { id: self.id, set: { lockedToServices } }})
        toast.success('Locked Services updated')
    } catch(err) {
        toast.error(getErrorMessage(err))
    }
  }),
  updateLockedLocations: flow(function*(lockedToLocations: any) {
    self.lockedToLocations = lockedToLocations
    const client = GraphqlClient()
    try {
        yield client.mutate({mutation: UpdateLeadFormDocument, variables: { id: self.id, set: { lockedToLocations } }})
        toast.success('Locked Services updated')
    } catch(err) {
        toast.error(getErrorMessage(err))
    }
  }),
  updateName: flow(function*(name: string ) {

    self.name = name
    const client = GraphqlClient()
    try {
        yield client.mutate({mutation: UpdateLeadFormDocument, variables: { id: self.id, set: { name } }})
        toast.success('Name updated')
    } catch(err) {
        toast.error(getErrorMessage(err))
    }
  }),
  submitForm: flow(function*(data:any) {
    if(!data?.values.email) {
        console.error('Form must have at least an email')
        return
    }

    const { mappedFieldValues} = data

    const client = GraphqlClient()
    try {
        yield client.mutate({mutation: SubmitLeadFormFieldsDocument, variables: { fieldValues: mappedFieldValues }})
       
    } catch(err) {
        toast.error(getErrorMessage(err))
    }
    
  })
}))

export const LeadFormModel = types.snapshotProcessor(model, {
  preProcessor(sn: any) {
    const leadForm = { ...sn };

    if (leadForm?.steps) {
      leadForm.steps = _.sortBy(leadForm.steps, 'order');
    }

    return leadForm;
  },
});

/**
 * The LeadForm instance.
 */
export type LeadForm = Instance<typeof LeadFormModel>;

/**
 * The data of a LeadForm.
 */
export type LeadFormSnapshot = SnapshotOut<typeof LeadFormModel>;
