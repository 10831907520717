/* eslint-disable prefer-template */
import { Text, Tooltip, Link } from '@chakra-ui/react';

const truncateString = (str: string, maxLength: number): string => {
  if (str.length > maxLength) {
    return str.substring(0, maxLength) + '...';
  }
  return str;
};

interface TruncatedCellProps {
  link?: string;
  maxLength?: number;
  noWrap?: boolean;
  value: string;
}

export const TruncatedCell = ({
  link,
  maxLength = 15,
  noWrap = true,
  value,
}: TruncatedCellProps): JSX.Element => {
  const truncatedValue = truncateString(value, maxLength);

  return (
    <Tooltip label={value}>
      {link ? (
        <Link href={link} whiteSpace={noWrap ? 'nowrap' : 'normal'}>
          {truncatedValue}
        </Link>
      ) : (
        <Text whiteSpace={noWrap ? 'nowrap' : 'normal'}>{truncatedValue}</Text>
      )}
    </Tooltip>
  );
};

export default TruncatedCell;
