import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetServicesAggregateQueryVariables = Types.Exact<{
  where?: Types.Maybe<Types.Service_Bool_Exp>;
}>;


export type GetServicesAggregateQuery = { __typename?: 'query_root', service_aggregate: { __typename?: 'service_aggregate', aggregate?: { __typename?: 'service_aggregate_fields', count: number } | null | undefined } };


export const GetServicesAggregateDocument = gql`
    query GetServicesAggregate($where: service_bool_exp) {
  service_aggregate(where: $where) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetServicesAggregateQuery__
 *
 * To run a query within a React component, call `useGetServicesAggregateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetServicesAggregateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetServicesAggregateQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetServicesAggregateQuery(baseOptions?: Apollo.QueryHookOptions<GetServicesAggregateQuery, GetServicesAggregateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetServicesAggregateQuery, GetServicesAggregateQueryVariables>(GetServicesAggregateDocument, options);
      }
export function useGetServicesAggregateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetServicesAggregateQuery, GetServicesAggregateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetServicesAggregateQuery, GetServicesAggregateQueryVariables>(GetServicesAggregateDocument, options);
        }
export type GetServicesAggregateQueryHookResult = ReturnType<typeof useGetServicesAggregateQuery>;
export type GetServicesAggregateLazyQueryHookResult = ReturnType<typeof useGetServicesAggregateLazyQuery>;
export type GetServicesAggregateQueryResult = Apollo.QueryResult<GetServicesAggregateQuery, GetServicesAggregateQueryVariables>;