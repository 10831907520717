import {
  CustomFormFieldType_Enum as CustomFormFieldTypeEnum,
  CustomFormFieldCategory_Enum as CustomFormFieldCategoryEnum,
} from '@webapp/graphql';
import { PossibleQuestions } from '@webapp/types';

// prettier-ignore
const numericRegexAsString = '^(0|[1-9][0-9]*)$';

const phoneValidation = JSON.stringify({
  phone: true,
});

const emailValidation = JSON.stringify({
  email: true,
});

const yesNoDescription = 'S/N';

const textInputDescription = 'Entrada de texto';

const dateInputDescription = 'Entrada de fecha';

const signatureInputDescription = 'Firma';

const multipleChoiceDescription = 'Elección múltiple';

const multipleSelectDescription = 'Selección múltiple';

const multipleChoiceCustomizableDescription =
  'Elección múltiple (personalizable)';

const emergencyContactQuestions = [
  {
    label: 'Nombre de contacto de emergencia',
    type: CustomFormFieldTypeEnum.TextInput,
    category: CustomFormFieldCategoryEnum.EmergencyContact,
    description: textInputDescription,
  },
  {
    label: 'Teléfono de contacto de emergencia',
    type: CustomFormFieldTypeEnum.TextInput,
    category: CustomFormFieldCategoryEnum.EmergencyContact,
    description: textInputDescription,
    validation: phoneValidation,
  },
  {
    label: 'Correo electrónico de contacto de emergencia',
    type: CustomFormFieldTypeEnum.TextInput,
    category: CustomFormFieldCategoryEnum.EmergencyContact,
    description: textInputDescription,
    validation: emailValidation,
  },
];

const allergyQuestions = [
  {
    label: 'Alergias - Látex',
    type: CustomFormFieldTypeEnum.YesNo,
    category: CustomFormFieldCategoryEnum.Allergies,
    description: yesNoDescription,
  },
  {
    label: 'Alergias - Cinta adhesiva',
    type: CustomFormFieldTypeEnum.YesNo,
    category: CustomFormFieldCategoryEnum.Allergies,
    description: yesNoDescription,
  },
  {
    label: 'Alergias - Yodo',
    type: CustomFormFieldTypeEnum.YesNo,
    category: CustomFormFieldCategoryEnum.Allergies,
    description: yesNoDescription,
  },
  {
    label: 'Alergias - Picaduras de abejas',
    type: CustomFormFieldTypeEnum.YesNo,
    category: CustomFormFieldCategoryEnum.Allergies,
    description: yesNoDescription,
  },
  {
    label: 'Alergias - Lidocaína',
    type: CustomFormFieldTypeEnum.YesNo,
    category: CustomFormFieldCategoryEnum.Allergies,
    description: yesNoDescription,
  },
  {
    label: 'Alergias - Medicina',
    type: CustomFormFieldTypeEnum.CreatableSelect,
    category: CustomFormFieldCategoryEnum.Allergies,
    description: multipleSelectDescription,
    optionCustomizable: true,
    options: [
      { value: 'Aspirina' },
      { value: 'Ibuprofeno' },
      { value: 'Paracetamol' },
      { value: 'Penicilina' },
      { value: 'Sulfácido' },
    ],
  },
  {
    label: 'Complicaciones previas con anestesia',
    type: CustomFormFieldTypeEnum.YesNo,
    description: yesNoDescription,
    category: CustomFormFieldCategoryEnum.Allergies,
  },
  {
    label: 'Anestesia problemática',
    type: CustomFormFieldTypeEnum.TextInput,
    description: textInputDescription,
    category: CustomFormFieldCategoryEnum.Allergies,
  },
];

const pregnancyQuestions = [
  {
    label: '¿Embarazada?',
    category: CustomFormFieldCategoryEnum.Pregnancy,
    type: CustomFormFieldTypeEnum.YesNo,
    description: yesNoDescription,
  },
  {
    label: '¿Amamantando?',
    category: CustomFormFieldCategoryEnum.Pregnancy,
    type: CustomFormFieldTypeEnum.YesNo,
    description: yesNoDescription,
  },
  {
    label: '¿Estás planeando quedar embarazada?',
    category: CustomFormFieldCategoryEnum.Pregnancy,
    type: CustomFormFieldTypeEnum.YesNo,
    description: yesNoDescription,
  },
  {
    label: '¿Estás tomando accutane o lo has tomado en los últimos 6 meses?',
    category: CustomFormFieldCategoryEnum.Pregnancy,
    type: CustomFormFieldTypeEnum.YesNo,
    description: yesNoDescription,
  },
];

const symptomsQuestions = [
  {
    label: 'Síntomas - ¿Tienes o has tenido',
    category: CustomFormFieldCategoryEnum.Symptoms,
    type: CustomFormFieldTypeEnum.MultipleSelect,
    description: multipleChoiceDescription,
    optionCustomizable: true,
    options: [
      { value: 'Fiebre' },
      { value: 'Tos' },
      { value: 'Falta de aliento' },
      { value: 'Dolor de cabeza' },
      { value: 'Escalofríos' },
      { value: 'Dolor de garganta' },
      { value: 'Náuseas' },
      { value: 'Vómitos' },
      { value: 'Diarrea' },
      { value: 'Estreñimiento' },
      { value: 'Dolor abdominal' },
    ],
  },
  {
    label: '¿Tienes antecedentes de herpes labial?',
    type: CustomFormFieldTypeEnum.YesNo,
    category: CustomFormFieldCategoryEnum.Symptoms,
    description: yesNoDescription,
  },
];

const historyQuestions = [
  {
    label: 'Historial médico',
    category: CustomFormFieldCategoryEnum.History,
    type: CustomFormFieldTypeEnum.MultipleSelect,
    description: multipleSelectDescription,
    optionCustomizable: true,
    options: [
      { value: 'Enfermedad del corazón' },
      { value: 'Presión arterial alta' },
      { value: 'Diabetes' },
      { value: 'Asma' },
      { value: 'Cáncer' },
      { value: 'Hepatitis' },
      { value: 'VIH/SIDA' },
    ],
  },
  {
    label: 'Historial familiar',
    category: CustomFormFieldCategoryEnum.History,
    type: CustomFormFieldTypeEnum.MultipleSelect,
    description: multipleSelectDescription,
    optionCustomizable: true,
    options: [
      { value: 'Enfermedad del corazón' },
      { value: 'Presión arterial alta' },
      { value: 'Diabetes' },
      { value: 'Asma' },
      { value: 'Cáncer' },
    ],
  },
];

const patientProfileQuestions = [
  {
    label: 'Altura',
    type: CustomFormFieldTypeEnum.DropdownSelect,
    category: CustomFormFieldCategoryEnum.PatientProfile,
    description: 'Seleccionar del menú desplegable',
    options: [
      { value: '6\'11"' },
      { value: '6\'10"' },
      { value: '6\'9"' },
      { value: '6\'8"' },
      { value: '6\'7"' },
      { value: '6\'6"' },
      { value: '6\'5"' },
      { value: '6\'4"' },
      { value: '6\'3"' },
      { value: '6\'2"' },
      { value: '6\'1"' },
      { value: '6\'"' },
      { value: '5\'11"' },
      { value: '5\'10"' },
      { value: '5\'9"' },
      { value: '5\'8"' },
      { value: '5\'7"' },
      { value: '5\'6"' },
      { value: '5\'5"' },
      { value: '5\'4"' },
      { value: '5\'3"' },
      { value: '5\'2"' },
      { value: '5\'1"' },
      { value: '5\'"' },
      { value: '4\'11"' },
      { value: '4\'10"' },
      { value: '4\'9"' },
      { value: '4\'8"' },
      { value: '4\'7"' },
      { value: '4\'6"' },
      { value: '4\'5"' },
      { value: '4\'4"' },
      { value: '4\'3"' },
      { value: '4\'2"' },
      { value: '4\'1"' },
      { value: '4\'"' },
      { value: '3\'11"' },
      { value: '3\'10"' },
      { value: '3\'9"' },
      { value: '3\'8"' },
      { value: '3\'7"' },
      { value: '3\'6"' },
      { value: '3\'5"' },
      { value: '3\'4"' },
      { value: '3\'3"' },
      { value: '3\'2"' },
      { value: '3\'1"' },
      { value: '3\'"' },
    ],
  },
  {
    label: 'Peso',
    type: CustomFormFieldTypeEnum.TextInput,
    category: CustomFormFieldCategoryEnum.PatientProfile,
    description: textInputDescription,
    validation: JSON.stringify({
      pattern: {
        value: numericRegexAsString,
        message: 'El peso debe ser un número',
      },
    }),
  },
  {
    label: 'Farmacia preferida',
    type: CustomFormFieldTypeEnum.TextInput,
    description: textInputDescription,
    category: CustomFormFieldCategoryEnum.PatientProfile,
  },
  {
    label: 'Raza',
    type: CustomFormFieldTypeEnum.SingleSelect,
    description: multipleChoiceDescription,
    options: [
      {
        value: 'Blanco',
      },
      {
        value: 'Hispano',
      },
      {
        value: 'Negro',
      },
      {
        value: 'Nativo Americano',
      },
      {
        value: 'Asiático',
      },
      {
        value: 'Isleño del Pacífico',
      },
      {
        value: 'Raza mixta',
      },
    ],
    category: CustomFormFieldCategoryEnum.PatientProfile,
  },

  {
    label: 'Sexo',
    type: CustomFormFieldTypeEnum.SingleSelect,
    description: multipleChoiceDescription,
    options: [{ value: 'Masculino' }, { value: 'Femenino' }],
    category: CustomFormFieldCategoryEnum.PatientProfile,
  },
  {
    label: 'Género',
    type: CustomFormFieldTypeEnum.SingleSelect,
    description: multipleChoiceDescription,
    optionCustomizable: true,
    options: [{ value: 'Hombre' }, { value: 'Mujer' }, { value: 'Otro' }],
    category: CustomFormFieldCategoryEnum.PatientProfile,
  },
];

const socialHistoryQuestions = [
  {
    label: '¿Cuál es su estado de fumador de cigarrillos?',
    category: CustomFormFieldCategoryEnum.SocialHistory,
    type: CustomFormFieldTypeEnum.MultipleSelect,
    description: multipleSelectDescription,
    options: [
      { value: 'Ex fumador' },
      { value: 'Fumador actual' },
      { value: 'No fumador' },
    ],
  },
  {
    label:
      '¿Alguna vez ha sido fumador de cigarros, pipa, cigarrillo electrónico, o hookah, o ha usado un vaporizador de tabaco u otros productos de nicotina?',
    category: CustomFormFieldCategoryEnum.SocialHistory,
    type: CustomFormFieldTypeEnum.MultipleSelect,
    description: multipleSelectDescription,
    options: [
      {
        value: 'Cigarro',
      },
      {
        value: 'Pipa',
      },
      {
        value: 'Cigarrillo electrónico',
      },
      {
        value: 'Hookah',
      },
      {
        value: 'Vaporizador de tabaco',
      },
      {
        value: 'Ninguno de los anteriores',
      },
    ],
  },
  {
    label: '¿Bebes cerveza, vino o licor?',
    category: CustomFormFieldCategoryEnum.SocialHistory,
    type: CustomFormFieldTypeEnum.MultipleSelect,
    description: multipleSelectDescription,
    options: [
      {
        value: 'Cerveza',
      },
      {
        value: 'Vino',
      },
      {
        value: 'Licor',
      },
      {
        value: 'Ninguno de los anteriores',
      },
    ],
  },
  {
    label: '¿Tienes antecedentes de uso de drogas recreativas?',
    category: CustomFormFieldCategoryEnum.SocialHistory,
    type: CustomFormFieldTypeEnum.MultipleSelect,
    description: multipleSelectDescription,
    options: [
      {
        value: 'Marihuana',
      },
      {
        value: 'Cocaína',
      },
      {
        value: 'Heroína',
      },
      {
        value: 'Metanfetamina',
      },
      {
        value: 'Ninguno de los anteriores',
      },
    ],
  },
];

const medicationQuestions = [
  {
    label: 'Medicación',
    description: textInputDescription,
    type: CustomFormFieldTypeEnum.TextInput,
    category: CustomFormFieldCategoryEnum.Medication,
  },
  {
    label: 'Dosis',
    description: textInputDescription,
    type: CustomFormFieldTypeEnum.TextInput,
    category: CustomFormFieldCategoryEnum.Medication,
  },
  {
    label: 'Frecuencia',
    description: textInputDescription,
    type: CustomFormFieldTypeEnum.TextInput,
    category: CustomFormFieldCategoryEnum.Medication,
  },
  {
    label: 'Última toma',
    description: dateInputDescription,
    type: CustomFormFieldTypeEnum.Date,
    category: CustomFormFieldCategoryEnum.Medication,
  },
];

const proceduresQuestions = [
  {
    label: 'Procedimiento pasado',
    description: textInputDescription,
    type: CustomFormFieldTypeEnum.TextInput,
    category: CustomFormFieldCategoryEnum.Procedures,
  },
  {
    label: 'Fecha del procedimiento',
    description: dateInputDescription,
    type: CustomFormFieldTypeEnum.Date,
    category: CustomFormFieldCategoryEnum.Procedures,
  },
];

const freeResponseQuestions = [
  {
    label: 'Cualquier otra alergia a medicamentos',
    description: textInputDescription,
    type: CustomFormFieldTypeEnum.TextInput,
    category: CustomFormFieldCategoryEnum.FreeResponse,
  },
  {
    label: 'Cualquier otro síntoma - ¿Tienes o has tenido',
    description: textInputDescription,
    type: CustomFormFieldTypeEnum.TextInput,
    category: CustomFormFieldCategoryEnum.FreeResponse,
  },
  {
    label: 'Cualquier otro historial médico',
    description: textInputDescription,
    type: CustomFormFieldTypeEnum.TextInput,
    category: CustomFormFieldCategoryEnum.FreeResponse,
  },
  {
    label: 'Cualquier otro historial familiar',
    description: textInputDescription,
    type: CustomFormFieldTypeEnum.TextInput,
    category: CustomFormFieldCategoryEnum.FreeResponse,
  },
  {
    label: 'Cualquier otro tabaco, drogas, alcohol',
    description: textInputDescription,
    type: CustomFormFieldTypeEnum.TextInput,
    category: CustomFormFieldCategoryEnum.FreeResponse,
  },
];

const emergencyContactGroup = {
  label: 'Todas las preguntas de contacto de emergencia',
  description: 'Información de contacto de emergencia',
  questions: emergencyContactQuestions,
};

const allergyGroup = {
  label: 'Todas las preguntas de alergias',
  description: 'Información de alergias',
  questions: allergyQuestions,
};

const pregnancyGroup = {
  label: 'Todas las preguntas de embarazo',
  description: 'Información de embarazo',
  questions: pregnancyQuestions,
};

const medicineDosageRow = {
  label: 'Medicación y dosis',
  description: 'Medicamentos',
  row: medicationQuestions,
  replicable: true,
};

const procedureDateRow = {
  label: 'Procedimiento pasado y fecha',
  description: 'Procedimiento pasado',
  row: proceduresQuestions,
  replicable: true,
};

const surgeryQuestions = [
  {
    label: 'Cirugía pasada',
    description: textInputDescription,
    type: CustomFormFieldTypeEnum.TextInput,
    category: CustomFormFieldCategoryEnum.Procedures,
  },
  {
    label: 'Fecha de cirugía',
    description: dateInputDescription,
    type: CustomFormFieldTypeEnum.Date,
    category: CustomFormFieldCategoryEnum.Procedures,
  },
];

const surgeryDateRow = {
  label: 'Cirugía pasada y fecha',
  description: 'Cirugía pasada',
  row: surgeryQuestions,
  replicable: true,
};

const aestheticTreatmentQuestions = [
  {
    label: 'Tratamiento estético pasado',
    description: textInputDescription,
    type: CustomFormFieldTypeEnum.TextInput,
    category: CustomFormFieldCategoryEnum.Procedures,
  },
  {
    label: 'Fecha de tratamiento',
    description: dateInputDescription,
    type: CustomFormFieldTypeEnum.Date,
    category: CustomFormFieldCategoryEnum.Procedures,
  },
];

const aestheticTreatmentDateRow = {
  label: 'Tratamiento estético pasado y fecha',
  description: 'Tratamiento estético pasado',
  row: aestheticTreatmentQuestions,
  replicable: true,
};

export const DRAGGABLE_QUESTIONS_SPANISH: PossibleQuestions[] = [
  emergencyContactGroup,
  allergyGroup,
  pregnancyGroup,
  medicineDosageRow,
  procedureDateRow,
  surgeryDateRow,
  aestheticTreatmentDateRow,
  ...surgeryQuestions,
  ...aestheticTreatmentQuestions,
  ...medicationQuestions,
  {
    label: '¿Ha tomado aspirina o algún anticoagulante en los últimos 7 días?',
    description: yesNoDescription,
    type: CustomFormFieldTypeEnum.YesNo,
    category: CustomFormFieldCategoryEnum.Medication,
  },
  ...proceduresQuestions,
  {
    label: 'Nombre',
    type: CustomFormFieldTypeEnum.TextInput,
    description: textInputDescription,
    validation: JSON.stringify({
      required: {
        value: true,
        message: 'El nombre es obligatorio',
      },
    }),
  },
  {
    label: 'Apellido',
    type: CustomFormFieldTypeEnum.TextInput,
    description: textInputDescription,
    validation: JSON.stringify({
      required: {
        value: true,
        message: 'El apellido es obligatorio',
      },
    }),
  },
  {
    label: 'Correo electrónico',
    type: CustomFormFieldTypeEnum.TextInput,
    description: textInputDescription,
    validation: emailValidation,
  },
  {
    label: 'Número de teléfono',
    type: CustomFormFieldTypeEnum.TextInput,
    description: textInputDescription,
    validation: phoneValidation,
  },
  {
    label: 'Método de contacto preferido',
    type: CustomFormFieldTypeEnum.SingleSelect,
    description: multipleChoiceDescription,
    options: [
      { value: 'Correo electrónico' },
      { value: 'Llamada telefónica' },
      {
        value: 'Mensaje de texto',
      },
    ],
  },
  {
    label: 'Idioma preferido',
    type: CustomFormFieldTypeEnum.CreatableSingleSelect,
    description: multipleChoiceCustomizableDescription,
    optionCustomizable: true,
    options: [
      { value: 'Inglés' },
      { value: 'Español' },
      { value: 'Francés' },
      { value: 'Chino' },
    ],
  },
  {
    label: 'Dirección',
    type: CustomFormFieldTypeEnum.Address,
    description: textInputDescription,
  },
  {
    label: 'Firma',
    type: CustomFormFieldTypeEnum.Signature,
    description: signatureInputDescription,
  },
  {
    label: '¿Podemos dejar un correo de voz?',
    type: CustomFormFieldTypeEnum.YesNo,
    description: yesNoDescription,
  },
  {
    label: '¿Abierto a ensayos clínicos?',
    type: CustomFormFieldTypeEnum.YesNo,
    description: yesNoDescription,
  },
  {
    label: 'Fecha de nacimiento',
    type: CustomFormFieldTypeEnum.Date,
    description: 'Fecha',
  },
  {
    label: '¿Cómo nos encontraste?',
    type: CustomFormFieldTypeEnum.SingleSelect,
    description: multipleChoiceCustomizableDescription,
    optionCustomizable: true,
    options: [
      { value: 'Google' },
      { value: 'Facebook' },
      { value: 'Twitter' },
      { value: 'Instagram' },
    ],
  },
  {
    label: 'Licencia de conducir (Estado y #)',
    type: CustomFormFieldTypeEnum.TextInput,
    description: textInputDescription,
  },
  {
    label: 'Estado civil',
    type: CustomFormFieldTypeEnum.SingleSelect,
    description: multipleChoiceDescription,
    optionCustomizable: true,
    options: [
      { value: 'Soltero' },
      { value: 'Casado' },
      { value: 'Divorciado' },
      { value: 'Viudo' },
    ],
  },
  {
    label: 'Nombre del cónyuge',
    type: CustomFormFieldTypeEnum.TextInput,
    description: textInputDescription,
  },
  {
    label: 'Abierto a ensayos clínicos',
    type: CustomFormFieldTypeEnum.YesNo,
    description: yesNoDescription,
  },
  {
    label: 'Número de lealtad de Alle',
    type: CustomFormFieldTypeEnum.TextInput,
    description: textInputDescription,
  },
  {
    label: 'Lealtad de Merz',
    type: CustomFormFieldTypeEnum.TextInput,
    description: textInputDescription,
  },
  {
    label: 'Lealtad de Galderma',
    type: CustomFormFieldTypeEnum.TextInput,
    description: textInputDescription,
  },
  ...emergencyContactQuestions,
  ...allergyQuestions,
  ...pregnancyQuestions,
  ...symptomsQuestions,
  ...historyQuestions,
  ...patientProfileQuestions,
  ...socialHistoryQuestions,
  ...freeResponseQuestions,
  {
    label: 'Servicios de interés',
    type: CustomFormFieldTypeEnum.MultipleSelect,
    description: multipleSelectDescription,
    category: CustomFormFieldCategoryEnum.SocialHistory,
    table: 'service',
    attribute: 'name',
  },
  {
    label: 'Lugar/Tipo de empleo',
    type: CustomFormFieldTypeEnum.TextInput,
    description: textInputDescription,
  },
  {
    label: 'Firma del testigo (Solo uso de la oficina)',
    type: CustomFormFieldTypeEnum.Signature,
    description: signatureInputDescription,
  },
];
