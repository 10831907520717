import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { LocationFieldsFragmentDoc } from '../../fragments/locationFields.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetLocationQueryVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
}>;


export type GetLocationQuery = { __typename?: 'query_root', location_by_pk?: { __typename?: 'location', id: any, isActive: boolean, name: string, address?: string | null | undefined, phoneNumber?: string | null | undefined, email?: string | null | undefined, maxPatientsPerTimeslot?: number | null | undefined, workspaceId: any, clinicId?: number | null | undefined, addressId?: any | null | undefined, workStartTime?: string | null | undefined, workEndTime?: string | null | undefined, timezone?: string | null | undefined, payrixMerchantId?: string | null | undefined, finixMerchantId?: string | null | undefined, locationSchedules: Array<{ __typename?: 'locationSchedule', id: any, schedule: any, effectiveFrom?: any | null | undefined, createdAt: any, updatedAt: any, locationId: any }>, fullAddress?: { __typename?: 'address', addressLine1: string, addressLine2?: string | null | undefined, city: string, id: any, state: string, zipCode: string } | null | undefined, rooms: Array<{ __typename?: 'room', id: any, name: string }> } | null | undefined };


export const GetLocationDocument = gql`
    query GetLocation($id: uuid!) {
  location_by_pk(id: $id) {
    ...LocationFields
  }
}
    ${LocationFieldsFragmentDoc}`;

/**
 * __useGetLocationQuery__
 *
 * To run a query within a React component, call `useGetLocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLocationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetLocationQuery(baseOptions: Apollo.QueryHookOptions<GetLocationQuery, GetLocationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLocationQuery, GetLocationQueryVariables>(GetLocationDocument, options);
      }
export function useGetLocationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLocationQuery, GetLocationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLocationQuery, GetLocationQueryVariables>(GetLocationDocument, options);
        }
export type GetLocationQueryHookResult = ReturnType<typeof useGetLocationQuery>;
export type GetLocationLazyQueryHookResult = ReturnType<typeof useGetLocationLazyQuery>;
export type GetLocationQueryResult = Apollo.QueryResult<GetLocationQuery, GetLocationQueryVariables>;