import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type DeleteSignatureMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
}>;


export type DeleteSignatureMutation = { __typename?: 'mutation_root', delete_signature_by_pk?: { __typename?: 'signature', id: any } | null | undefined };


export const DeleteSignatureDocument = gql`
    mutation deleteSignature($id: uuid!) {
  delete_signature_by_pk(id: $id) {
    id
  }
}
    `;
export type DeleteSignatureMutationFn = Apollo.MutationFunction<DeleteSignatureMutation, DeleteSignatureMutationVariables>;

/**
 * __useDeleteSignatureMutation__
 *
 * To run a mutation, you first call `useDeleteSignatureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSignatureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSignatureMutation, { data, loading, error }] = useDeleteSignatureMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteSignatureMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSignatureMutation, DeleteSignatureMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSignatureMutation, DeleteSignatureMutationVariables>(DeleteSignatureDocument, options);
      }
export type DeleteSignatureMutationHookResult = ReturnType<typeof useDeleteSignatureMutation>;
export type DeleteSignatureMutationResult = Apollo.MutationResult<DeleteSignatureMutation>;
export type DeleteSignatureMutationOptions = Apollo.BaseMutationOptions<DeleteSignatureMutation, DeleteSignatureMutationVariables>;