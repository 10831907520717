import { HStack, NumberInputField, Text, NumberInput } from '@chakra-ui/react';
import {
  forwardRef,
  useState,
  useRef,
  useEffect,
  useImperativeHandle,
} from 'react';
import VitalsCellIconButton from '../VitalsCellIconButton/VitalsCellIconButton';

interface NumericCellEditorProps {
  value: number;
  unit?: string;
  isFloatEditor?: boolean;
  stopEditing?: () => void;
}

export const NumericCellEditor = forwardRef(
  (
    {
      value: incomingValue,
      unit,
      isFloatEditor = false,
      stopEditing,
    }: NumericCellEditorProps,
    ref
  ) => {
    const [value, setValue] = useState<number | string>(() => {
      if (unit === '%' && !incomingValue) return 0;
      return incomingValue;
    });
    const refInput = useRef<any>(null);

    useEffect(() => {
      refInput?.current?.focus();
    }, []);

    useImperativeHandle(ref, () => ({
      getValue() {
        return value;
      },

      isCancelBeforeStart() {
        return false;
      },
    }));

    function handleChange(rawValue: string, valueAsNumber: number) {
      if (isFloatEditor) return setValue(rawValue);
      setValue(valueAsNumber);
    }

    return (
      <HStack spacing={2} p={1}>
        <NumberInput
          maxHeight="38px"
          maxWidth="200px"
          onChange={handleChange}
          value={value}
        >
          <NumberInputField h="33px" ref={refInput} />
        </NumberInput>
        {unit && <Text>{unit}</Text>}
        {stopEditing && <VitalsCellIconButton clickHandler={stopEditing} />}
      </HStack>
    );
  }
);

export default NumericCellEditor;
