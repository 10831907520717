import {
  Button,
  ButtonGroup,
  HStack,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { useCallback, useEffect, useState } from 'react';
import { AppointmentService, useStores } from '@webapp/state-models';
import { observer } from 'mobx-react-lite';
import { RiCloseLine } from 'react-icons/ri';
import dayjs from 'dayjs';
import './create-appointment-drawer.module.scss';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import {
  Address,
  AppointmentFieldsFragment,
  Patient,
  PatientFieldsFragment,
  useListPatientAddressQuery,
  useListPaymentMethodsLazyQuery,
  usePayWithExistingMethodMutation,
  usePayWithNewMethodMutation,
} from '@webapp/graphql';
import toast from 'react-hot-toast';
import { PayrixPayfieldsProps } from '@webapp/ui-composites';
import { generateCUDHookParams } from '@webapp/utils';
import { useFlagsmith } from 'flagsmith-react';
import CreatePatientAppointment from './create-patient-appointment';
import CreateOtherAppointment from './create-other-appointment';
import PaymentModal from '../PaymentModal';
import PromptModal from '../PromptModal/PromptModal';

dayjs.extend(customParseFormat);
/* eslint-disable-next-line */
export interface CreateAppointmentDrawerProps {
  onClose?: () => void;
  patient?:
    | Pick<
        Patient,
        'id' | 'firstName' | 'lastName' | 'profilePicture' | 'email'
      >
    | PatientFieldsFragment;
}

type PaymentMode = 'INTERNAL_DEPOSIT' | 'INTERNAL_NO_SHOW_FEE' | 'NO_PAYMENT';

export const CreateAppointmentDrawer = observer(
  ({
    onClose: onCloseFromProps,
    patient: patientFromProps,
  }: CreateAppointmentDrawerProps): JSX.Element => {
    const {
      calendarTimeZone,
      draftAppointment,
      setDraftAppointment,
      workspace,
      ui: { setShowCreateAppointmentSidebar },
    } = useStores();
    const { hasFeature } = useFlagsmith();
    const {
      isOpen: isTimeWarningOpen,
      onClose: onTimeWarningClose,
      onOpen: onTimeWarningOpen,
    } = useDisclosure();
    const [prepaymentAmount, setPrepaymentAmount] = useState<number>(0);
    const [paymentMode, setPaymentMode] = useState<PaymentMode>('NO_PAYMENT');
    const [paymentModalOpen, setPaymentModalOpen] = useState(false);
    const [hasFormError, setHasFormError] = useState<boolean>(false);
    const [getPatientPaymentMethods, { data: paymentMethodsData }] =
      useListPaymentMethodsLazyQuery();
    const { data: patientAddressesData } = useListPatientAddressQuery({
      variables: {
        where: {
          patientId: { _eq: draftAppointment?.patient?.id },
        },
      },
      skip: !draftAppointment?.patient?.id,
    });
    const paymentMethods = paymentMethodsData?.paymentMethod ?? [];
    const showSavedCards = paymentMethods.length > 0;

    useEffect(() => {
      if (!draftAppointment?.patient?.id) return;

      getPatientPaymentMethods({
        variables: {
          where: {
            patientId: { _eq: draftAppointment.patient.id },
            archived: { _eq: false },
            processor: {
              _eq:
                hasFeature('finix') && workspace?.finixMerchantId
                  ? 'finix'
                  : 'payrix',
            },
          },
        },
      });
    }, [draftAppointment?.patient?.id]);

    const workspaceId = workspace?.id;
    const noShowActive = workspace?.workspaceConfiguration?.noShowActive;
    const noShowFee = workspace?.workspaceConfiguration?.noShowFee;

    const commonHookParams = generateCUDHookParams({
      item: 'Appointment',
      operation: 'created',
      callback: () => {
        closePaymentModal();
        onClose();
      },
      refetchQueries: [
        'listAppointmentsSchedule',
        'GetOnePatient',
        'listAppointments',
      ],
    });

    const [payWithExistingMethod, { loading: payingWithExistingMethod }] =
      usePayWithExistingMethodMutation(commonHookParams);
    const [payWithNewMethod, { loading: payingWithNewMethod }] =
      usePayWithNewMethodMutation(commonHookParams);

    const getDefaultAddress = useCallback((): Partial<Address> | undefined => {
      const patientAs = patientFromProps as PatientFieldsFragment;

      const patientAddresses =
        patientAddressesData?.patientAddress ||
        patientAs?.patientAddresses ||
        [];

      const defaultAddress =
        patientAddresses.find((patientAddress) => patientAddress.isDefault) ??
        patientAddresses[0];

      return defaultAddress?.address;
    }, [patientFromProps, patientAddressesData]);

    const payrixMerchantId =
      draftAppointment?.location?.payrixMerchantId ??
      workspace?.payrixMerchantId ??
      '';

    const finixMerchantId =
      draftAppointment?.location?.finixMerchantId ??
      workspace?.finixMerchantId ??
      '';

    const patientId = draftAppointment?.patient?.id ?? '';

    function onClose() {
      setDraftAppointment(null);
      setShowCreateAppointmentSidebar(false);

      if (onCloseFromProps) {
        onCloseFromProps();
      }
    }

    const closePaymentModal = (): void => {
      setPaymentModalOpen(false);
    };

    useEffect(() => {
      if (draftAppointment?.deposit) {
        setPrepaymentAmount(draftAppointment?.deposit);
        setPaymentMode('INTERNAL_DEPOSIT');
      } else if (noShowActive && noShowFee) {
        setPrepaymentAmount(noShowFee);
        setPaymentMode('INTERNAL_NO_SHOW_FEE');
      } else {
        setPrepaymentAmount(0);
        setPaymentMode('NO_PAYMENT');
      }
    }, [draftAppointment?.deposit, noShowActive, noShowFee]);

    useEffect(() => {
      if (!draftAppointment) {
        setDraftAppointment({
          id: 'DRAFT',
          startTime: dayjs()
            .tz(calendarTimeZone)
            .set('hour', 9)
            .set('minute', 0)
            .set('second', 0)
            .set('millisecond', 0)
            .utc()
            .toISOString(),
          type: 'patient_appointment',
          workspaceId,
          ...(patientFromProps && { patient: patientFromProps }),
        });
      }
    }, [
      setDraftAppointment,
      draftAppointment,
      patientFromProps,
      workspaceId,
      calendarTimeZone,
    ]);

    const openPaymentModal = (): void => {
      setPaymentModalOpen(true);
    };

    const createAppointmentAndClose = async (
      message = 'Appointment created'
    ): Promise<void> => {
      if (draftAppointment?.type === 'patient_appointment') {
        if (!draftAppointment?.patient) {
          toast.error(`Please select a patient`);
          return;
        }
      }
      const app: AppointmentFieldsFragment | undefined =
        await draftAppointment?.create(); // error toast handled in create method

      if (!app) return;

      toast.success(message);

      onClose();
    };

    const skipDepositOrNoShowFee = (): void => {
      closePaymentModal();
      createAppointmentAndClose();
    };

    const onPayWithNewMethod: PayrixPayfieldsProps<'token'>['onPayWithNewMethod'] =
      useCallback(
        async ({ token }) => {
          const appointment: AppointmentFieldsFragment =
            await draftAppointment?.create({
              isDraft: true,
              shouldRefetch: false,
            });

          payWithNewMethod({
            variables: {
              amount: prepaymentAmount,
              caseName: paymentMode,
              caseContext:
                paymentMode === 'INTERNAL_NO_SHOW_FEE'
                  ? { appointmentId: appointment.id }
                  : {
                      appointmentId: appointment.id,
                      locationId: appointment.locationId ?? '',
                      providerId: appointment.provider?.id ?? '',
                      address: token.customer.address1,
                    },
              patientId: appointment.patientId ?? '',
              paymentInfo: token,
              payrixMerchantId,
              workspaceId: workspaceId ?? '',
            },
          });
        },
        [
          draftAppointment,
          payWithNewMethod,
          paymentMode,
          payrixMerchantId,
          prepaymentAmount,
          workspaceId,
        ]
      );

    const onPayWithNewFinixMethod = useCallback(
      async ({ token, cardOwnerDetails, finixFraudSessionId }) => {
        const appointment: AppointmentFieldsFragment =
          await draftAppointment?.create({
            isDraft: true,
            shouldRefetch: false,
          });

        payWithNewMethod({
          variables: {
            amount: prepaymentAmount,
            caseName: paymentMode,
            caseContext:
              paymentMode === 'INTERNAL_NO_SHOW_FEE'
                ? { appointmentId: appointment.id }
                : {
                    appointmentId: appointment.id,
                    locationId: appointment.locationId ?? '',
                    providerId: appointment.provider?.id ?? '',
                    address: cardOwnerDetails.address.addressLine1,
                  },
            patientId: appointment.patientId ?? '',
            paymentInfo: {
              token,
              cardOwnerDetails,
              finixFraudSessionId,
            },
            finixMerchantId,
            workspaceId: workspaceId ?? '',
          },
        });
      },
      [
        draftAppointment,
        payWithNewMethod,
        paymentMode,
        payrixMerchantId,
        finixMerchantId,
        prepaymentAmount,
        workspaceId,
      ]
    );

    const onPayWithExistingMethod: NonNullable<
      PayrixPayfieldsProps<'token'>['onPayWithExistingMethod']
    > = useCallback(
      async ({ amount, paymentMethodId }, billingInfo) => {
        console.log('billingInfo', billingInfo, { amount, paymentMethodId });
        const appointment: AppointmentFieldsFragment =
          await draftAppointment?.create({
            isDraft: true,
            shouldRefetch: false,
          });

        payWithExistingMethod({
          variables: {
            amount,
            caseName: paymentMode,
            caseContext:
              paymentMode === 'INTERNAL_NO_SHOW_FEE'
                ? { appointmentId: appointment.id }
                : {
                    appointmentId: appointment?.id,
                    locationId: appointment.location?.id ?? '',
                    providerId: appointment.provider?.id ?? '',
                    address: billingInfo?.address?.addressLine1,
                  },
            paymentMethodId,
            patientId: appointment.patientId ?? '',
            payrixMerchantId,
            finixMerchantId,
            workspaceId: workspaceId ?? '',
          },
        });
      },
      [
        draftAppointment,
        payWithExistingMethod,
        paymentMode,
        payrixMerchantId,
        workspaceId,
      ]
    );

    async function onSubmit(workingHoursConfirmed = false) {
      setHasFormError(false);
      const hasNullProvider = draftAppointment?.services.some(
        (service: AppointmentService) =>
          service.providerId === null ||
          service.providerId === '' ||
          !/^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i.test(
            service.providerId
          )
      );

      const hasNullDevice = draftAppointment?.services.some(
        (service) => service.requiredDeviceButNoDeviceSelected
      );

      if (hasNullProvider) {
        setHasFormError(true);
        toast.error('Please make sure all services have a provider assigned.');
        return;
      }

      if (hasNullDevice) {
        setHasFormError(true);
        toast.error(
          'Please make sure services requiring a device have a device selected.'
        );
        return;
      }

      if (
        (!draftAppointment?.services ||
          draftAppointment?.services.length === 0) &&
        draftAppointment?.type !== 'other'
      ) {
        toast.error('Please make sure appointment has at least one service.');
        return;
      }

      if (
        !draftAppointment?.location?.isWithinWorkHours(
          dayjs(draftAppointment.startTime).tz(calendarTimeZone)
        ) &&
        !workingHoursConfirmed
      ) {
        return onTimeWarningOpen();
      }

      if (draftAppointment?.type === 'patient_appointment') {
        if (!draftAppointment?.patient) {
          return toast.error(`Please select a patient`);
        }
        if (draftAppointment?.services.length === 0) {
          return toast.error(`Please select at least one service`);
        }
        if (prepaymentAmount > 0) {
          return openPaymentModal();
        }
      }

      createAppointmentAndClose();
    }

    const paymentModeIsDeposit = paymentMode === 'INTERNAL_DEPOSIT';

    return (
      <Stack h={'full'} spacing="0">
        <HStack
          color="teal.500"
          bg="teal.50"
          justifyContent="space-between"
          padding="16px 28px"
        >
          <Text fontSize="xl" fontWeight="bold">
            New event
          </Text>
          <IconButton
            variant={'ghost'}
            onClick={onClose}
            colorScheme="teal"
            /* Source of kebab-case warning -- but this is not incorrect */
            aria-label="Close appointment creation"
            icon={<RiCloseLine size="20px" />}
          />
        </HStack>
        <Stack
          padding="24px 28px"
          spacing="24px"
          overflowY={'auto'}
          maxH="91vh"
          flexGrow={1}
        >
          <RadioGroup
            value={draftAppointment?.type}
            onChange={(e) => draftAppointment?.setType(e)}
          >
            <Stack direction="row" spacing="20px">
              <Radio size="lg" value="patient_appointment">
                Patient appointment
              </Radio>
              <Radio size="lg" value="other">
                Other
              </Radio>
            </Stack>
          </RadioGroup>
          {draftAppointment?.type === 'patient_appointment' && (
            <CreatePatientAppointment hasFormError={hasFormError} />
          )}
          {draftAppointment?.type === 'other' && (
            <CreateOtherAppointment draftAppointment={draftAppointment} />
          )}
        </Stack>
        <HStack
          borderTopWidth={1}
          borderColor={'gray.200'}
          position={'static'}
          justifyContent={'space-between'}
          p={4}
        >
          <ButtonGroup justifyContent="end" width="100%">
            <Button onClick={onClose} variant={'outline'}>
              Cancel
            </Button>
            <Button
              colorScheme="red"
              isDisabled={draftAppointment?.loading}
              variant="solid"
              onClick={() => createAppointmentAndClose('Draft saved')}
            >
              Save Draft
            </Button>
            <Button
              data-testid="create-appointment-button"
              colorScheme="teal"
              variant="solid"
              isDisabled={draftAppointment?.loading}
              onClick={() => onSubmit()}
            >
              Create
            </Button>
          </ButtonGroup>
        </HStack>
        {paymentModalOpen && (
          <PaymentModal
            amount={prepaymentAmount}
            authenticated={false}
            isOpen={paymentModalOpen}
            mode="token"
            onClose={closePaymentModal}
            onNewCardToken={onPayWithNewFinixMethod}
            onExistingCardToken={onPayWithExistingMethod}
            onPayWithNewMethod={onPayWithNewMethod}
            onPayWithExistingMethod={onPayWithExistingMethod}
            isLoading={payingWithExistingMethod || payingWithNewMethod}
            onSkip={skipDepositOrNoShowFee}
            paymentMethods={paymentMethods}
            patientId={patientId}
            finixMerchantId={finixMerchantId}
            payrixMerchantId={payrixMerchantId}
            showPaymentAmount={paymentModeIsDeposit}
            showSavedCards={showSavedCards}
            skipName={paymentModeIsDeposit ? 'Deposit' : 'No Show Fee'}
            workspaceId={workspaceId ?? ''}
            cardOwnerDetails={{
              name: `${draftAppointment?.patient?.attributes?.firstName} ${draftAppointment?.patient?.attributes?.lastName}`,
              email: draftAppointment?.patient?.attributes?.email ?? '',
              phoneNumber:
                draftAppointment?.patient?.attributes?.phoneNumber ?? '',
              address: getDefaultAddress() ?? undefined,
            }}
          />
        )}
        <PromptModal
          bodyText="The appointment you're creating is outside the location's hours, do you wish to continue?"
          colorScheme="red"
          confirmText="Create"
          headerText="Booking outside working hours"
          isLoading={draftAppointment?.loading}
          isOpen={isTimeWarningOpen}
          onClose={onTimeWarningClose}
          onConfirm={() => {
            onSubmit(true);
            onTimeWarningClose();
          }}
        />
      </Stack>
    );
  }
);

export default CreateAppointmentDrawer;
