import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetPackageServiceQueryVariables = Types.Exact<{
  packageId: Types.Scalars['uuid'];
  locationId: Types.Scalars['uuid'];
}>;


export type GetPackageServiceQuery = { __typename?: 'query_root', package_by_pk?: { __typename?: 'package', id: any, service: { __typename?: 'service', allowGratuity: boolean, id: any, name: string, price: number, pricingModel: string, taxRate?: any | null | undefined, locationServices: Array<{ __typename?: 'locationService', price?: any | null | undefined, taxRate?: any | null | undefined }> } } | null | undefined };


export const GetPackageServiceDocument = gql`
    query GetPackageService($packageId: uuid!, $locationId: uuid!) {
  package_by_pk(id: $packageId) {
    id
    service {
      locationServices(where: {locationId: {_eq: $locationId}}) {
        price
        taxRate
      }
      allowGratuity
      id
      name
      price
      pricingModel
      taxRate
    }
  }
}
    `;

/**
 * __useGetPackageServiceQuery__
 *
 * To run a query within a React component, call `useGetPackageServiceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPackageServiceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPackageServiceQuery({
 *   variables: {
 *      packageId: // value for 'packageId'
 *      locationId: // value for 'locationId'
 *   },
 * });
 */
export function useGetPackageServiceQuery(baseOptions: Apollo.QueryHookOptions<GetPackageServiceQuery, GetPackageServiceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPackageServiceQuery, GetPackageServiceQueryVariables>(GetPackageServiceDocument, options);
      }
export function useGetPackageServiceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPackageServiceQuery, GetPackageServiceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPackageServiceQuery, GetPackageServiceQueryVariables>(GetPackageServiceDocument, options);
        }
export type GetPackageServiceQueryHookResult = ReturnType<typeof useGetPackageServiceQuery>;
export type GetPackageServiceLazyQueryHookResult = ReturnType<typeof useGetPackageServiceLazyQuery>;
export type GetPackageServiceQueryResult = Apollo.QueryResult<GetPackageServiceQuery, GetPackageServiceQueryVariables>;