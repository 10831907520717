import { HStack, IconButton, Tooltip } from '@chakra-ui/react';
import { Editor } from '@tiptap/react';
import {
  AiOutlineInsertRowAbove,
  AiOutlineInsertRowBelow,
  AiOutlineInsertRowLeft,
  AiOutlineInsertRowRight,
} from 'react-icons/ai';
import { RiDeleteRow, RiDeleteColumn } from 'react-icons/ri';

export interface TableToolBarProps {
  editor: Editor;
}

export default function TableToolbar({ editor }: TableToolBarProps) {
  const actions = [
    {
      icon: <AiOutlineInsertRowBelow />,
      tip: 'Add row below',
      command: () => {
        editor.commands.addRowAfter();
      },
    },
    {
      icon: <AiOutlineInsertRowAbove />,
      tip: 'Add row above',
      command: () => {
        editor.commands.addRowBefore();
      },
    },
    {
      icon: <AiOutlineInsertRowLeft />,
      tip: 'Add a column before the current column',
      command: () => {
        editor.commands.addColumnBefore();
      },
    },
    {
      icon: <AiOutlineInsertRowRight />,
      tip: 'Add a column after the current column',
      command: () => {
        editor.commands.addColumnAfter();
      },
    },
    // Delete Row
    {
      icon: <RiDeleteRow />,
      tip: 'Delete the current row',
      command: () => {
        editor.commands.deleteRow();
      },
    },
    // Delete Column
    {
      icon: <RiDeleteColumn />,
      tip: 'Delete the current column',
      command: () => {
        editor.commands.deleteColumn();
      },
    },
  ];

  return (
    <HStack
      w={'full'}
      px={2}
      pb={2}
      spacing={2}
      borderBottomWidth={1}
      borderBottomColor="gray.200"
    >
      {actions.map((action) => (
        <Tooltip key={action.tip} label={action.tip}>
          <IconButton
            size="sm"
            variant="outline"
            colorScheme="blackAlpha"
            aria-label="table action"
            onClick={action.command}
            icon={action.icon}
          />
        </Tooltip>
      ))}
    </HStack>
  );
}
