import React, { useRef } from 'react';
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormHelperText,
  FormErrorMessage,
  FormLabel,
  Input,
} from '@chakra-ui/react';

import { useForm } from 'react-hook-form';
import { LeadFormStep } from '@webapp/state-models';
/* eslint-disable-next-line */
export interface CustomFieldModalProps {
  isOpen: boolean;
  onClose: () => void;
  formStep: LeadFormStep;
}

export function CustomFieldModal({
  isOpen,
  onClose,
  formStep,
}: CustomFieldModalProps) {
  const initialRef = useRef<HTMLInputElement | null>(null);

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      name: '',
    },
  });

  const { ref, ...rest } = register('name');

  function onSubmit(values: { name: string }) {
    onClose();
    formStep.addField({
      label: values.name,
      type: 'string',
      validationProps: {},
    });
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} initialFocusRef={initialRef}>
      <ModalOverlay />
      <ModalContent>
        <form id="settings-form" onSubmit={handleSubmit(onSubmit)}>
          <ModalHeader>Add custom field</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl id="name" isInvalid={Boolean(errors.name)}>
              <FormLabel>Name</FormLabel>
              <Input
                id="name"
                placeholder="name"
                {...rest}
                ref={(e) => {
                  ref(e);
                  initialRef.current = e; // you can still assign to ref
                }}
              />
              <FormHelperText>
                Enter a label for your custom field, for example, "Hair Color"
              </FormHelperText>
              <FormErrorMessage>
                {errors.name && errors.name.message}
              </FormErrorMessage>
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button variant="ghost">Cancel</Button>
            <Button
              colorScheme="blue"
              mr={3}
              type="submit"
              isLoading={isSubmitting}
            >
              Create
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
}

export default CustomFieldModal;
