import * as Types from '../../generated/graphql';

import { gql } from '@apollo/client';
export type InvoiceTemplateFieldsFragment = { __typename?: 'invoiceTemplate', id: any, title: string, invoiceValues: any };

export const InvoiceTemplateFieldsFragmentDoc = gql`
    fragment InvoiceTemplateFields on invoiceTemplate {
  id
  title
  invoiceValues
}
    `;