import * as Types from '../../generated/graphql';

import { gql } from '@apollo/client';
export type PromotionFieldsFragment = { __typename?: 'promotion', id: any, createdAt: any, updatedAt: any, title: string, description: string, isActive: boolean, isArchived: boolean, startsAt?: any | null | undefined, endsAt?: any | null | undefined, image?: string | null | undefined, workspaceId: any, locationId?: any | null | undefined, location?: { __typename?: 'location', id: any, name: string } | null | undefined, promotionServices: Array<{ __typename?: 'promotionServices', id: any, serviceId: any, discountAmount: any, discountType: string, quantity?: number | null | undefined, service: { __typename?: 'service', id: any, name: string } }> };

export const PromotionFieldsFragmentDoc = gql`
    fragment PromotionFields on promotion {
  id
  createdAt
  updatedAt
  title
  description
  isActive
  isArchived
  startsAt
  endsAt
  image
  workspaceId
  locationId
  location {
    id
    name
  }
  promotionServices {
    id
    serviceId
    service {
      id
      name
    }
    discountAmount
    discountType
    quantity
  }
}
    `;