import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type UpdateRewardProgramMutationVariables = Types.Exact<{
  rewardProgramId: Types.Scalars['uuid'];
  rewardProgram: Types.RewardProgram_Set_Input;
}>;


export type UpdateRewardProgramMutation = { __typename?: 'mutation_root', update_rewardProgram_by_pk?: { __typename?: 'rewardProgram', id: any } | null | undefined };


export const UpdateRewardProgramDocument = gql`
    mutation updateRewardProgram($rewardProgramId: uuid!, $rewardProgram: rewardProgram_set_input!) {
  update_rewardProgram_by_pk(
    _set: $rewardProgram
    pk_columns: {id: $rewardProgramId}
  ) {
    id
  }
}
    `;
export type UpdateRewardProgramMutationFn = Apollo.MutationFunction<UpdateRewardProgramMutation, UpdateRewardProgramMutationVariables>;

/**
 * __useUpdateRewardProgramMutation__
 *
 * To run a mutation, you first call `useUpdateRewardProgramMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRewardProgramMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRewardProgramMutation, { data, loading, error }] = useUpdateRewardProgramMutation({
 *   variables: {
 *      rewardProgramId: // value for 'rewardProgramId'
 *      rewardProgram: // value for 'rewardProgram'
 *   },
 * });
 */
export function useUpdateRewardProgramMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRewardProgramMutation, UpdateRewardProgramMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateRewardProgramMutation, UpdateRewardProgramMutationVariables>(UpdateRewardProgramDocument, options);
      }
export type UpdateRewardProgramMutationHookResult = ReturnType<typeof useUpdateRewardProgramMutation>;
export type UpdateRewardProgramMutationResult = Apollo.MutationResult<UpdateRewardProgramMutation>;
export type UpdateRewardProgramMutationOptions = Apollo.BaseMutationOptions<UpdateRewardProgramMutation, UpdateRewardProgramMutationVariables>;