/* eslint-disable */
// @ts-nocheck

import { createElement } from 'react';
import { SliderPicker, SketchPicker, CompactPicker } from 'react-color';
import { ElementFactory, Question, Serializer, SvgRegistry } from 'survey-core';
import {
  SurveyQuestionElementBase,
  ReactQuestionFactory,
} from 'survey-react-ui';
import {
  localization,
  PropertyGridEditorCollection,
} from 'survey-creator-react';
import Icon from './icon.svg?raw';
import { ExternalBooking } from '@webapp/webapp/ui-composites';
const CUSTOM_TYPE = 'book-appointment';
import { HookForm } from '@webapp/ui';
import WrappedExternalBooking from './wrappedExternalBooking';
// A model for the new question type

export class BookAppointmentModel extends Question {
  getType() {
    return CUSTOM_TYPE;
  }

  get colorPickerType() {
    return this.getPropertyValue('colorPickerType');
  }

  set colorPickerType(val) {
    this.setPropertyValue('colorPickerType', val);
  }

  get disableAlpha() {
    return this.getPropertyValue('disableAlpha');
  }

  set disableAlpha(val) {
    this.setPropertyValue('disableAlpha', val);
  }
}

// Register `BookAppointmentModel` as a model for the `book-appointment` type
export function registerBookAppointment() {
  ElementFactory.Instance.registerElement(
    CUSTOM_TYPE,
    (name) => new BookAppointmentModel(name)
  );
}

// Specify display names for the question type and its properties
const locale = localization.getLocale('');
locale.qt[CUSTOM_TYPE] = 'Book Appointment';

SvgRegistry.registerIconFromSvg(CUSTOM_TYPE, Icon);

// Add question type metadata for further serialization into JSON
Serializer.addClass(
  CUSTOM_TYPE,
  [],
  () => new BookAppointmentModel('Book Appointment'),
  'question'
);

// A class that renders questions of the new type in the UI
export class SurveyQuestionBookAppointment extends SurveyQuestionElementBase {
  constructor(props) {
    super(props);
    this.state = { value: this.question.value };
  }

  get question() {
    return this.questionBase;
  }

  get value() {
    return this.question.value;
  }

  // Support the read-only and design modes
  get style() {
    return this.question.getPropertyValue('readOnly') ||
      this.question.isDesignMode
      ? { pointerEvents: 'none' }
      : undefined;
  }

  onSubmit = (data) => {
    if (this.question.survey.nextPage()) {
      this.question.survey.currentPageNo =
        this.question.survey.currentPageNo + 1;
    } else {
      this.question.survey.doComplete();
    }
  };

  renderElement() {
    return (
      <WrappedExternalBooking
        patientStatus={this.question.survey.getVariable('patientStatus')}
        previousServiceIds={this.question.survey.getVariable(
          'previousServiceIds'
        )}
        workspaceId={this.question.leadFormWorkspaceId}
        payrixMerchantId={this.question.payrixMerchantId}
        finixMerchantId={this.question.finixMerchantId}
        leadFormLinkId={this.question.survey.getVariable('leadFormLinkId')}
        onCompleted={this.onSubmit}
        data={this.question.survey.data}
        parentSurvey={this.question.survey}
        lockedToLocations={this.question.survey.getVariable(
          'lockedToLocations'
        )}
        lockedToServices={this.question.survey.getVariable('lockedToServices')}
        serviceOrderRanking={this.question.survey.getVariable(
          'serviceOrderRanking'
        )}
        allowProviderSelection={this.question.survey.getVariable(
          'allowProviderSelection'
        )}
      />
    );
  }
}

// Register `SurveyQuestionBookAppointment` as a class that renders `book-appointment` questions
ReactQuestionFactory.Instance.registerQuestion(CUSTOM_TYPE, (props) =>
  createElement(SurveyQuestionBookAppointment, props)
);

// Register the `book-appointment` as an editor for properties of the `color` type in the Survey Creator's Property Grid
// PropertyGridEditorCollection.register({
//   fit(prop) {
//     return prop.type === 'color';
//   },
//   getJSON() {
//     return {
//       type: CUSTOM_TYPE,
//       colorPickerType: 'Compact',
//     };
//   },
// });
