import * as Types from '../../generated/graphql';

import { gql } from '@apollo/client';
export type ServiceCategoryFieldsFragment = { __typename?: 'serviceCategory', id: any, title: string };

export const ServiceCategoryFieldsFragmentDoc = gql`
    fragment ServiceCategoryFields on serviceCategory {
  id
  title
}
    `;