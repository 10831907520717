/* eslint-disable camelcase */
import {
  Patient,
  PatientWorkspace_Constraint,
  PatientWorkspace_Update_Column,
  useInsertPatientMutation,
  useInsertOnePatientAddressMutation,
} from '@webapp/graphql';
import { FormInput } from '@webapp/ui';
import {
  Button,
  chakra,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Stack,
} from '@chakra-ui/react';
import { useLoadPatients } from '@webapp/webapp/hooks';
import toast from 'react-hot-toast';
import { useStores } from '@webapp/state-models';
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form';

import { getErrorMessage } from '@webapp/utils';
import { FaArrowLeft } from 'react-icons/fa';

interface IFormInput {
  firstName: string;
  lastName: string;
  middleName?: string;
  email: string;
  phoneNumber?: string;
  dob?: string;
  address?: any;
  referringPatientId: { value?: string } | null;
  howDidYouFindUs: string | null;
}

/* eslint-disable-next-line */
export interface QuickPatientFormProps {
  onCreatePatient: (patient: Patient) => void;
  onClose: () => void;
}

export function QuickPatientForm({
  onCreatePatient,
  onClose,
}: QuickPatientFormProps) {
  const { workspace } = useStores();
  const methods = useForm<IFormInput>();

  const { register, handleSubmit, watch } = methods;

  const howDidYouFindUs = watch('howDidYouFindUs');

  const [insertPatient] = useInsertPatientMutation();
  const [insertAddress] = useInsertOnePatientAddressMutation();

  const onSubmit: SubmitHandler<IFormInput> = async function handleSignUp({
    firstName,
    lastName,
    middleName,
    email,
    phoneNumber,
    dob,
    address,
    city,
    state,
    zipCode,
    referringPatientId,
    howDidYouFindUs,
    howDidYouFindUsOther,
  }) {
    try {
      const { data } = await insertPatient({
        variables: {
          patient: {
            firstName,
            lastName,
            email: email.toLowerCase(),
            ...(phoneNumber && { phoneNumber }),
            ...(middleName && { middleName }),
            patientWallets: {
              data: [
                {
                  workspaceId: workspace?.id,
                },
              ],
            },
            patientWorkspaces: {
              data: [
                {
                  workspaceId: workspace?.id,
                  firstName,
                  lastName,
                  email: email.toLowerCase(),
                  ...(phoneNumber && { phoneNumber }),
                  ...(middleName && { middleName }),
                  ...(dob && { dob }),
                  ...(referringPatientId && {
                    referringPatientId: referringPatientId?.value,
                  }),
                  ...(howDidYouFindUs && {
                    attributes: {
                      howDidYouFindUs: `${howDidYouFindUs}${
                        howDidYouFindUs === 'Other practice'
                          ? `: ${howDidYouFindUsOther}`
                          : ''
                      }`,
                    },
                  }),
                },
              ],
              on_conflict: {
                constraint:
                  PatientWorkspace_Constraint.PatientWorkspacePatientIdWorkspaceIdKey,
                update_columns: [
                  PatientWorkspace_Update_Column.UpdatedAt,
                  PatientWorkspace_Update_Column.FirstName,
                  PatientWorkspace_Update_Column.LastName,
                  PatientWorkspace_Update_Column.Email,
                ],
              },
            },
          },
        },
      });
      if (data?.insert_patient?.returning) {
        onCreatePatient(data?.insert_patient?.returning?.[0] as Patient);

        if (!address || !city || !state || !zipCode) return;
        insertAddress({
          variables: {
            addressLine1: address,
            addressLine2: '',
            city,
            isDefault: true,
            patientId: data?.insert_patient?.returning?.[0]?.id,
            state,
            zipCode,
          },
        });
      }
    } catch (err) {
      toast.error(getErrorMessage(err));
    }
  };

  return (
    <Stack>
      <Button
        onClick={onClose}
        maxW={'xs'}
        pl={0}
        justifyContent="flex-start"
        leftIcon={<FaArrowLeft />}
        variant={'ghost'}
      >
        Go back
      </Button>
      <FormProvider {...methods}>
        {' '}
        <chakra.form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing="6">
            <HStack>
              <FormControl id="firstName">
                <FormLabel>First Name *</FormLabel>
                <Input
                  type="firstName"
                  autoComplete="firstName"
                  required
                  {...register('firstName')}
                />
              </FormControl>
              <FormControl id="middleName">
                <FormLabel>Middle Name</FormLabel>
                <Input
                  type="middleName"
                  autoComplete="middleName"
                  {...register('middleName')}
                />
              </FormControl>
            </HStack>
            <FormControl id="lastName">
              <FormLabel>Last Name *</FormLabel>
              <Input
                type="lastName"
                autoComplete="lastName"
                required
                {...register('lastName')}
              />
            </FormControl>
            <FormControl id="email">
              <FormLabel>Email address *</FormLabel>
              <Input
                type="email"
                autoComplete="email"
                required
                {...register('email')}
              />
            </FormControl>

            <FormControl id="phoneNumber">
              <FormLabel>Phone Number</FormLabel>
              <Input type="tel" {...register('phoneNumber')} />
            </FormControl>

            <FormControl id="dob">
              <FormLabel>Date of Birth</FormLabel>
              <Input type="date" {...register('dob')} />
            </FormControl>

            <FormInput
              label="Address"
              name="address"
              type="address"
              placeholder="Patient Address (optional)"
            />

            <FormInput
              label="Referred by patient"
              name="referringPatientId"
              selectProps={{ loadOptions: useLoadPatients }}
              type="async-select"
            />
            <FormInput
              label="Referral Source"
              name="howDidYouFindUs"
              type="select"
              withOptions={[
                { label: 'Billboard', value: 'Billboard' },
                { label: 'Current patient', value: 'Current patient' },
                { label: 'Drive by', value: 'Drive by' },
                { label: 'Facebook', value: 'Facebook' },
                { label: 'Friend', value: 'Friend' },
                { label: 'Google', value: 'Google' },
                { label: 'Instagram', value: 'Instagram' },
                { label: 'Other', value: 'Other' },
                { label: 'Other practice', value: 'Other practice' },
                { label: 'TikTok', value: 'TikTok' },
                { label: 'Yelp', value: 'Yelp' },
              ]}
            />
            {howDidYouFindUs === 'Other practice' && (
              <FormInput
                label="Which practice?"
                name="howDidYouFindUsOther"
                type="text"
              />
            )}
            <Button type="submit" colorScheme="blue" size="lg" fontSize="md">
              Create patient
            </Button>
          </Stack>
        </chakra.form>
      </FormProvider>
    </Stack>
  );
}

export default QuickPatientForm;
