import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type InsertLocationServiceMutationVariables = Types.Exact<{
  locationService: Types.LocationService_Insert_Input;
}>;


export type InsertLocationServiceMutation = { __typename?: 'mutation_root', insert_locationService?: { __typename?: 'locationService_mutation_response', returning: Array<{ __typename?: 'locationService', id: any, locationId: any, serviceId: any }> } | null | undefined };


export const InsertLocationServiceDocument = gql`
    mutation insertLocationService($locationService: locationService_insert_input!) {
  insert_locationService(
    objects: [$locationService]
    on_conflict: {constraint: locationService_locationId_serviceId_key, update_columns: updatedAt}
  ) {
    returning {
      id
      locationId
      serviceId
    }
  }
}
    `;
export type InsertLocationServiceMutationFn = Apollo.MutationFunction<InsertLocationServiceMutation, InsertLocationServiceMutationVariables>;

/**
 * __useInsertLocationServiceMutation__
 *
 * To run a mutation, you first call `useInsertLocationServiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertLocationServiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertLocationServiceMutation, { data, loading, error }] = useInsertLocationServiceMutation({
 *   variables: {
 *      locationService: // value for 'locationService'
 *   },
 * });
 */
export function useInsertLocationServiceMutation(baseOptions?: Apollo.MutationHookOptions<InsertLocationServiceMutation, InsertLocationServiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertLocationServiceMutation, InsertLocationServiceMutationVariables>(InsertLocationServiceDocument, options);
      }
export type InsertLocationServiceMutationHookResult = ReturnType<typeof useInsertLocationServiceMutation>;
export type InsertLocationServiceMutationResult = Apollo.MutationResult<InsertLocationServiceMutation>;
export type InsertLocationServiceMutationOptions = Apollo.BaseMutationOptions<InsertLocationServiceMutation, InsertLocationServiceMutationVariables>;