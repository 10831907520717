import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type UpdateMarketingTagMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
  set?: Types.Maybe<Types.MarketingTag_Set_Input>;
}>;


export type UpdateMarketingTagMutation = { __typename?: 'mutation_root', update_marketingTag_by_pk?: { __typename?: 'marketingTag', id: any, title: string } | null | undefined };


export const UpdateMarketingTagDocument = gql`
    mutation UpdateMarketingTag($id: uuid!, $set: marketingTag_set_input) {
  update_marketingTag_by_pk(_set: $set, pk_columns: {id: $id}) {
    id
    title
  }
}
    `;
export type UpdateMarketingTagMutationFn = Apollo.MutationFunction<UpdateMarketingTagMutation, UpdateMarketingTagMutationVariables>;

/**
 * __useUpdateMarketingTagMutation__
 *
 * To run a mutation, you first call `useUpdateMarketingTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMarketingTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMarketingTagMutation, { data, loading, error }] = useUpdateMarketingTagMutation({
 *   variables: {
 *      id: // value for 'id'
 *      set: // value for 'set'
 *   },
 * });
 */
export function useUpdateMarketingTagMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMarketingTagMutation, UpdateMarketingTagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMarketingTagMutation, UpdateMarketingTagMutationVariables>(UpdateMarketingTagDocument, options);
      }
export type UpdateMarketingTagMutationHookResult = ReturnType<typeof useUpdateMarketingTagMutation>;
export type UpdateMarketingTagMutationResult = Apollo.MutationResult<UpdateMarketingTagMutation>;
export type UpdateMarketingTagMutationOptions = Apollo.BaseMutationOptions<UpdateMarketingTagMutation, UpdateMarketingTagMutationVariables>;