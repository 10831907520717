/* eslint-disable import/order */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable no-plusplus */
/* eslint-disable no-restricted-syntax */
/* eslint-disable camelcase */
import {
  Box,
  chakra,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Input,
  VStack,
  Text,
  Icon,
  Divider,
  Button,
  Stack,
  IconButton,
  Switch,
  Grid,
} from '@chakra-ui/react';
import {
  CustomFormFieldCategory_Enum,
  CustomFormFieldType_Enum,
  CustomFormRow_Insert_Input,
  GetCustomFormsAggregateDocument,
  GetCustomFormsDocument,
  GetCustomQuestionsQuery,
  OrderBy,
  useDeleteCustomFormMutation,
  useGetCustomFormResponsesAggregateLazyQuery,
  useGetCustomQuestionsQuery,
  useInsertCustomFormMutation,
  useUpdateCustomFormByPkMutation,
  useUpdateCustomFormRowsMutation,
  useUpdateServiceCustomFormsMutation,
  RecurrenceType_Enum,
} from '@webapp/graphql';
import { useStores } from '@webapp/state-models';
import {
  FormProvider,
  SubmitErrorHandler,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import toast from 'react-hot-toast';
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd';
import { useEffect, useState } from 'react';
import { BiEditAlt, BiGridVertical, BiPlus } from 'react-icons/bi';
import { MdOpenInNew } from 'react-icons/md';
import {
  FiCalendar,
  FiCheckSquare,
  FiToggleLeft,
  FiTrash,
} from 'react-icons/fi';
import { nanoid } from 'nanoid';
import { useLocation, useNavigate } from 'react-router-dom';
import { FormInput, GenericObjectSelect, SearchInput } from '@webapp/ui';
import merge from 'lodash/merge';
import { camelCase } from 'lodash';
import {
  DRAGGABLE_QUESTIONS_SPANISH,
  DRAGGABLE_QUESTIONS as DRAGGABLE_QUESTIONS_ENGLISH,
  rightListId,
  leftListId,
} from '@webapp/constants';
import {
  DefaultQuestion,
  InProgressFormRow,
  FormBuilderInput,
  PossibleQuestions,
  GroupOfQuestions,
  FormBuilderParams,
  RowOfQuestions,
} from '@webapp/types';
import { observer } from 'mobx-react-lite';
import CreateQuestionModal from './create-question-modal';
import DeleteQuestionModal from './deleteQuestionModal';
import { WarningModal } from './warningModal';
import DeleteModal from '../delete-modal';
import './custom-form-builder.css';
import { useLoadServices } from '@webapp/webapp/hooks';
import { useFlagsmith } from 'flagsmith-react';

const getIconFromType = (
  type: CustomFormFieldType_Enum
): JSX.Element | undefined => {
  switch (type) {
    case CustomFormFieldType_Enum.TextInput:
      return <Icon as={BiEditAlt} color="gray.500" />;
    case CustomFormFieldType_Enum.YesNo:
      return <Icon as={FiToggleLeft} color="gray.500" />;
    case CustomFormFieldType_Enum.Date:
      return <Icon as={FiCalendar} color="gray.500" />;
    case CustomFormFieldType_Enum.MultipleSelect:
    case CustomFormFieldType_Enum.SingleSelect:
    default:
      return <Icon as={FiCheckSquare} color="gray.500" />;
  }
};

const recurrences = [
  { label: 'Every Appointment', value: RecurrenceType_Enum.Always },
  { label: 'First Appointment Only', value: RecurrenceType_Enum.Never },
  { label: 'Annually', value: RecurrenceType_Enum.Annually },
  { label: 'First Service', value: RecurrenceType_Enum.FirstService },
];

export const CustomFormBuilder = observer(
  ({ customForm }: FormBuilderParams): JSX.Element => {
    const location = useLocation();
    const searchParameters = new URLSearchParams(location.search);
    const fieldFilter = searchParameters.get('search') ?? '';
    const { hasFeature } = useFlagsmith();
    const { setDraftCustomForm } = useStores();

    const [updateCustomFormRows] = useUpdateCustomFormRowsMutation();
    const [updateServiceCustomForms] = useUpdateServiceCustomFormsMutation();

    const { user, workspace, draftCustomForm, clearDraftCustomForm } =
      useStores();
    const navigate = useNavigate();

    const [deleteForm] = useDeleteCustomFormMutation();

    const [deleteFormModalIsOpen, setDeleteFormModalIsOpen] = useState(false);

    const onOpenDeleteFormModal = (): void => {
      setDeleteFormModalIsOpen(true);
    };

    const onCloseDeleteFormModal = (): void => {
      setDeleteFormModalIsOpen(false);
    };

    const onDelete = (): void => {
      deleteForm({
        variables: {
          where: {
            id: {
              _eq: customForm?.id,
            },
          },
        },
        onCompleted: () => {
          toast.success('Form successfully deleted');
          navigate('/settings/forms-and-consents/forms');
        },
        onError: (error) => {
          toast.error((error as Error).message);
        },
        refetchQueries,
      });
    };

    const goBack = (): void => {
      clearDraftCustomForm();
      navigate(-1);
    };

    const [showWarningModal, setShowWarningModal] = useState(false);

    const onCloseWarningModal = (): void => {
      setShowWarningModal(false);
    };

    const [getResponseAggregate] = useGetCustomFormResponsesAggregateLazyQuery({
      onCompleted: (data) => {
        if (data?.customFormResponse_aggregate?.aggregate?.count) {
          setShowWarningModal(true);
        }
      },
    });

    useEffect(() => {
      if (!customForm) return;

      getResponseAggregate({
        variables: {
          where: {
            customFormId: {
              _eq: customForm.id,
            },
          },
        },
      });
    }, [customForm]);

    const [createOpen, setCreateOpen] = useState(false);
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [currentQuestion, setCurrentQuestion] = useState<
      DefaultQuestion | undefined
    >();

    const methods = useForm({
      mode: 'onChange',
      reValidateMode: 'onChange',
      criteriaMode: 'firstError',
      shouldFocusError: true,
    });

    const { register, handleSubmit, watch, setValue } = methods;

    const language = methods.watch('language');

    const DRAGGABLE_QUESTIONS =
      language === 'SPANISH'
        ? DRAGGABLE_QUESTIONS_SPANISH
        : DRAGGABLE_QUESTIONS_ENGLISH;

    const constructLeftList = (
      customQuestionsData: GetCustomQuestionsQuery
    ) => {
      const listClone = [...DRAGGABLE_QUESTIONS];

      if (customQuestionsData?.customQuestion) {
        const mappedCustom = customQuestionsData?.customQuestion.map(
          (question) => {
            const {
              id,
              label,
              typeValueAndDescription: { value, description },
              options,
              body,
              photo,
              photoId,
            } = question;

            return {
              id,
              label,
              ...(body && { body }),
              ...(photo && { photo }),
              ...(photoId && { photoId }),
              type: value as CustomFormFieldType_Enum,
              description,
              category: question.categoryValueAndDescription
                ?.value as CustomFormFieldCategory_Enum,
              categoryDescription:
                question.categoryValueAndDescription?.description,
              isCustom: true,
              options,
            };
          }
        );

        mappedCustom.forEach((custom) => {
          const index = listClone.findIndex(
            (question) => question.label === custom.label
          );

          if (index !== -1) {
            const merged = merge(listClone[index], custom);

            listClone[index] = merged;
          } else {
            listClone.push(custom as any);
          }
        });
      }

      return listClone;
    };

    const [leftList, setLeftList] =
      useState<PossibleQuestions[]>(DRAGGABLE_QUESTIONS);

    const { data: customQuestionsData, refetch } = useGetCustomQuestionsQuery({
      variables: {
        where: {
          workspaceId: { _eq: workspace?.id },
        },
        orderBy: {
          createdAt: OrderBy.Asc,
        },
      },
      onCompleted: (data) => {
        const constructedList = constructLeftList(data);
        setLeftList(constructedList);
      },
    });

    useEffect(() => {
      refetch();
    }, [language]);

    const [rightList, setRightList] = useState<InProgressFormRow[]>(
      draftCustomForm?.rows.map((row) => ({
        id: nanoid(),
        replicable: row.replicable,
        questions: row.fields.map((field) => ({
          ...field,
          type: field.type as CustomFormFieldType_Enum,
          category: field.category as CustomFormFieldCategory_Enum,
          id: nanoid(),
        })),
      })) ??
        customForm?.rows.map((row) => ({
          id: row.id,
          replicable: row.replicable as boolean,
          questions: row.fields.map((field) => ({
            id: field.id,
            type: field.type,
            label: field.label,
            ...(field.photoId && { photoId: field.photoId }),
            ...(field.photo && { photo: field.photo as any }),
            ...(field.body && { body: field.body }),
            ...(field.options?.length && { options: field.options }),
            ...(field.category && { category: field.category }),
            ...(field.table && { table: field.table }),
            ...(field.attribute && { attribute: field.attribute }),
          })),
        })) ??
        []
    );

    const [insertForm] = useInsertCustomFormMutation();
    const [updateForm] = useUpdateCustomFormByPkMutation();

    const isRequired = watch('isRequired');

    useEffect(() => {
      if (!fieldFilter) {
        setLeftList(DRAGGABLE_QUESTIONS);
      } else {
        const copy = [...leftList];
        const filtered = copy.filter((item) =>
          item.label.toLowerCase().includes(fieldFilter.toLowerCase())
        );
        setLeftList(filtered);
      }
    }, [fieldFilter, language]);

    const refetchQueries = [
      {
        query: GetCustomFormsDocument,
        variables: {
          where: { workspaceId: { _eq: workspace?.id } },
          limit: 10,
          offset: 0,
          orderBy: [],
        },
      },
      {
        query: GetCustomFormsAggregateDocument,
        variables: {
          where: { workspaceId: { _eq: workspace?.id } },
        },
      },
    ];

    const mapRowsToInput = (
      customFormId?: string
    ): CustomFormRow_Insert_Input[] =>
      rightList.map((row, rowIndex) => ({
        index: rowIndex,
        ...(row.replicable && { replicable: row.replicable }),
        customFormId,
        ...(row.questions.length > 1 && {
          apiFieldGroupKey: row.questions
            .map((question) => camelCase(question.label))
            .join('_'),
        }),
        fields: {
          data: row.questions.map(
            (
              {
                label,
                type,
                options,
                category,
                table,
                attribute,
                validation,
                body,
                photoId,
              },
              fieldIndex
            ) => ({
              index: fieldIndex,
              label,
              body,
              apiFieldKey: camelCase(label),
              type,
              table,
              attribute,
              validation,
              ...(photoId && { photoId }),
              ...(category && { category }),
              ...(options?.length && {
                options: {
                  data: options?.map(({ value }) => ({
                    value,
                  })),
                },
              }),
            })
          ),
        },
      }));

    const mapRowsToDraftRows = () => {
      const rows = rightList.map((row, rowIndex) => ({
        index: rowIndex,
        ...(row.replicable && { replicable: row.replicable }),
        fields: row.questions.map((question, fieldIndex) => ({
          ...question,
          index: fieldIndex,
        })),
      }));

      return rows;
    };

    const onSubmit: SubmitHandler<FormBuilderInput> = async ({
      title,
      isRequired,
      allFieldsRequired,
      serviceCustomForms,
      recurrence,
      language,
    }): Promise<void> => {
      try {
        if (customForm) {
          const customFormId = customForm.id;

          if (!isRequired && Array.isArray(serviceCustomForms)) {
            updateServiceCustomForms({
              variables: {
                where: {
                  customFormId: {
                    _eq: customFormId,
                  },
                },
                objects: serviceCustomForms.map(({ value: serviceId }) => ({
                  serviceId,
                  customFormId: customForm.id,
                })),
              },
            });
          }

          updateCustomFormRows({
            variables: {
              where: {
                customFormId: {
                  _eq: customFormId,
                },
              },
              objects: mapRowsToInput(customFormId),
            },
          });

          await updateForm({
            variables: {
              id: customFormId,
              set: {
                title,
                isRequired,
                allFieldsRequired,
                recurrence,
                language,
              },
            },
            refetchQueries,
          });
        } else {
          await insertForm({
            variables: {
              customForm: {
                title,
                isRequired,
                allFieldsRequired,
                recurrence,
                language,
                workspaceId: workspace?.id,
                userId: user?.id,
                rows: {
                  data: mapRowsToInput(),
                },
                ...(!isRequired &&
                  serviceCustomForms?.length && {
                    serviceCustomForms: {
                      data: serviceCustomForms.map(({ value: serviceId }) => ({
                        serviceId,
                      })),
                    },
                  }),
              },
            },
            refetchQueries,
          });
        }

        toast.success(`Form ${customForm ? 'updated' : 'created'}`);
        clearDraftCustomForm();
        navigate('/settings/forms-and-consents/forms');
      } catch (error) {
        toast.error((error as Error).message);
      }
    };

    const onError: SubmitErrorHandler<FormBuilderInput> = ({ title }) => {
      const message = title?.message ?? 'Title is required';

      toast.error(message);
    };

    const onDragEnd = (result: DropResult): void => {
      const { source, destination, draggableId } = result;

      if (!destination) return;

      const destinationIndex = destination.index;

      // no movement
      if (
        destination.droppableId === source.droppableId &&
        destination.index === source.index
      ) {
        return;
      }
      // no right -> left
      if (
        source.droppableId === rightListId &&
        destination.droppableId === leftListId
      ) {
        return;
      }
      // no rearrange left
      if (
        source.droppableId === leftListId &&
        destination.droppableId === leftListId
      ) {
        return;
      }
      // rearrange right
      if (
        source.droppableId === rightListId &&
        destination.droppableId === rightListId
      ) {
        const itemIndex = rightList.findIndex(({ id }) => id === draggableId);

        if (itemIndex === -1) return;

        const item = rightList[itemIndex];

        const newList = [...rightList];

        newList.splice(itemIndex, 1);

        newList.splice(destinationIndex, 0, item);

        setRightList(newList);
      }
      // add to right
      if (
        source.droppableId === leftListId &&
        destination.droppableId === rightListId
      ) {
        const rowAtIndex = rightList[destinationIndex];
        const indexPopulated = Boolean(rowAtIndex);

        const initialItem = leftList.find((q) => q.label === draggableId);

        if (!initialItem) return;

        const newRightList = [...rightList];

        if ((initialItem as RowOfQuestions).row) {
          const { row, replicable } = initialItem as RowOfQuestions;

          const originalQuestions = row
            .map((question) => leftList.find((q) => q.label === question.label))
            .filter(Boolean) as DefaultQuestion[];

          if (!originalQuestions.length) return;

          const newRow: InProgressFormRow = {
            replicable,
            id: nanoid(),
            questions: originalQuestions.map((question) => ({
              ...question,
              id: nanoid(),
            })),
          };

          newRightList.splice(destinationIndex, 0, newRow);

          setRightList(newRightList);

          return;
        }

        if ((initialItem as GroupOfQuestions).questions) {
          const { questions } = initialItem as GroupOfQuestions;

          let currentIndex = destinationIndex;

          for (const question of questions) {
            const rowId = nanoid();

            const originalQuestion = leftList.find(
              (q) => q.label === question.label
            ) as DefaultQuestion;

            if (!originalQuestion) return;

            const newRow: InProgressFormRow = {
              id: rowId,
              questions: [
                {
                  ...originalQuestion,
                  id: `${rowId}-${originalQuestion.label}`,
                },
              ],
            };

            newRightList.splice(currentIndex, 0, newRow);
            currentIndex++;
          }

          setRightList(newRightList);

          return;
        }

        if (!indexPopulated) {
          const rowId = nanoid();

          const newRow: InProgressFormRow = {
            id: rowId,
            questions: [
              {
                ...(initialItem as DefaultQuestion),
                id: `${rowId}-${(initialItem as DefaultQuestion).label}`,
              },
            ],
          };

          newRightList.splice(destinationIndex, 0, newRow);
        }

        if (indexPopulated && rowAtIndex.questions.length < 2) {
          if (
            rowAtIndex.questions.find((q) => q.label === initialItem?.label)
          ) {
            toast.error('Question already exists in this row');
            return;
          }

          const newRow: InProgressFormRow = { ...rowAtIndex };

          newRow.questions.push({
            ...(initialItem as DefaultQuestion),
            id: `${rowAtIndex.id}-${initialItem.label}`,
          });

          newRightList.splice(destinationIndex, 1, newRow);
        }

        setRightList(newRightList);
      }
    };

    const draggableQuestionsComponent = leftList.map((item, index) => (
      // @ts-ignore-error React 18
      <Draggable draggableId={item.label} index={index} key={index}>
        {(provided) => (
          <Flex
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
            justifyContent="space-between"
            borderStyle="solid"
            borderColor="gray.200"
            borderWidth="1px"
            borderRadius="15px"
            width="320px"
            padding={5}
          >
            <HStack>
              <Icon color="gray.500" as={BiGridVertical} marginRight={5} />
              <Stack>
                <Text fontSize="15px" fontWeight="bold">
                  {item.label}
                </Text>
                <Text fontSize="15px" color="gray.500">
                  {item.description}
                </Text>
              </Stack>
            </HStack>
            <HStack>
              {(item as DefaultQuestion).type &&
                getIconFromType((item as DefaultQuestion).type)}
              {((item as DefaultQuestion).isCustom ||
                (item as DefaultQuestion).optionCustomizable) && (
                <IconButton
                  aria-label="edit-question"
                  size={'sm'}
                  icon={<MdOpenInNew />}
                  onClick={() => handleStartEdit(item as DefaultQuestion)}
                />
              )}
              {(item as DefaultQuestion).isCustom &&
                !(item as DefaultQuestion).optionCustomizable && (
                  <IconButton
                    aria-label="delete-question"
                    variant="outline"
                    size={'sm'}
                    colorScheme="red"
                    icon={<FiTrash />}
                    onClick={() => handleStartDelete(item as DefaultQuestion)}
                  />
                )}
            </HStack>
          </Flex>
        )}
      </Draggable>
    ));

    const removeFromList = (rowId: string, questionId: string): void => {
      const newRightList = [...rightList];

      const rowIndex = newRightList.findIndex((r) => r.id === rowId);

      if (rowIndex === -1) return;

      const newRow = { ...newRightList[rowIndex], id: nanoid() };

      newRow.questions = newRow.questions.filter((q) => q.id !== questionId);

      if (newRow.questions.length === 0) {
        newRightList.splice(rowIndex, 1);
      } else {
        newRightList.splice(rowIndex, 1, newRow);
      }

      setRightList(newRightList);
    };

    const clearCurrentQuestion = () => {
      setCurrentQuestion(undefined);
    };

    const handleOpenCreate = (): void => {
      setCreateOpen(true);
    };
    const handleStartDelete = (question: DefaultQuestion): void => {
      setCurrentQuestion(question);
      setDeleteOpen(true);
    };

    const handleCloseCreate = (): void => {
      setCreateOpen(false);
      clearCurrentQuestion();
    };
    const handleCloseDelete = (): void => {
      setDeleteOpen(false);
      clearCurrentQuestion();
    };

    const handleStartEdit = (question: DefaultQuestion) => {
      if (question.optionCustomizable || question.isCustom) {
        setCurrentQuestion(question);
        handleOpenCreate();
      }
    };

    useEffect(() => {
      const title = watch('title');
      const allFieldsRequired = watch('allFieldsRequired');
      const isRequired = watch('isRequired');
      const services = watch('serviceCustomForms');
      const rows = mapRowsToDraftRows();

      const form = {
        title,
        rows,
        isRequired,
        services,
        allFieldsRequired,
      };

      setDraftCustomForm(form);
    }, [
      rightList,
      setDraftCustomForm,
      watch('title'),
      watch('allFieldsRequired'),
      watch('isRequired'),
      watch('serviceCustomForms'),
    ]);

    return (
      <Stack w="full" position="relative" h="85vh" pl={5}>
        <FormProvider {...methods}>
          <chakra.form onSubmit={handleSubmit(onSubmit, onError)}>
            {/* @ts-ignore-error React 18 */}
            <DragDropContext onDragEnd={onDragEnd}>
              <VStack>
                <Grid
                  height="100%"
                  width="100%"
                  gridTemplateColumns="1fr 1fr 1fr"
                  columnGap="100px"
                >
                  <VStack height="80vh">
                    <VStack width="320px">
                      <FormControl id="title">
                        <FormLabel>Document Title</FormLabel>
                        <Input
                          type="text"
                          width="100%"
                          height="40px"
                          defaultValue={
                            draftCustomForm?.title ?? customForm?.title
                          }
                          {...register('title', {
                            required: 'Title is required',
                          })}
                        />
                      </FormControl>
                      <SearchInput placeholder="Search field" />
                      <FormControl display="flex" alignItems="center">
                        <FormLabel htmlFor="isRequired" mb="0">
                          Required regardless of services
                        </FormLabel>
                        <Switch
                          {...register('isRequired')}
                          id="isRequired"
                          defaultChecked={
                            draftCustomForm?.isRequired ??
                            customForm?.isRequired
                          }
                        />
                      </FormControl>
                      {!isRequired && (
                        <HStack w="full">
                          <Box w="300px">
                            <FormLabel htmlFor="serviceCustomForms" mb="0">
                              Required Services
                            </FormLabel>
                            <GenericObjectSelect
                              isAsync
                              isMulti
                              onChange={(val) =>
                                setValue('serviceCustomForms', val)
                              }
                              useLoadOptions={useLoadServices}
                              defaultValue={
                                draftCustomForm?.services ??
                                (customForm?.serviceCustomForms.map(
                                  ({ service }) => ({
                                    label: service.name,
                                    value: service.id,
                                  })
                                ) as any) ??
                                []
                              }
                            />
                          </Box>
                        </HStack>
                      )}

                      <Stack w="full">
                        <FormInput
                          label="Recurrence"
                          name="recurrence"
                          type="select"
                          defaultValue={
                            customForm?.recurrence ?? RecurrenceType_Enum.Always
                          }
                          withOptions={recurrences}
                        />
                        {hasFeature('custom-forms:select-language') && (
                          <FormInput
                            label="Language"
                            name="language"
                            type="select"
                            defaultValue={customForm?.language ?? 'ENGLISH'}
                            withOptions={[
                              { label: 'English', value: 'ENGLISH' },
                              { label: 'Spanish', value: 'SPANISH' },
                            ]}
                          />
                        )}
                        <FormControl display={'flex'} alignItems={'center'}>
                          <FormLabel htmlFor="allFieldsRequired">
                            All Fields Required
                          </FormLabel>
                          <Switch
                            {...register('allFieldsRequired')}
                            id="allFieldsRequired"
                            defaultChecked={
                              draftCustomForm?.allFieldsRequired ??
                              customForm?.allFieldsRequired
                            }
                          />
                        </FormControl>
                      </Stack>
                      <HStack
                        width="100%"
                        padding={2}
                        cursor="pointer"
                        onClick={handleOpenCreate}
                      >
                        <Icon as={BiPlus} color="teal" />
                        <Text color="teal" fontWeight="bold">
                          Add custom question
                        </Text>
                      </HStack>
                    </VStack>
                    {/* @ts-ignore-error React 18 */}
                    <Droppable droppableId={leftListId}>
                      {(provided) => (
                        <Stack
                          minH="40vh"
                          className="form-builder-question-list"
                          overflowY={'scroll'}
                          sx={{
                            '&::-webkit-scrollbar': {
                              width: '0px',
                              borderRadius: '8px',
                              backgroundColor: `rgba(0, 0, 0, 0.05)`,
                            },
                            '&::-webkit-scrollbar-thumb': {
                              backgroundColor: `rgba(0, 0, 0, 0.05)`,
                            },
                          }}
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                        >
                          <>
                            {draggableQuestionsComponent}
                            {provided.placeholder}
                          </>
                        </Stack>
                      )}
                    </Droppable>
                  </VStack>
                  {/* @ts-ignore-error React 18 */}
                  <Droppable droppableId="droppableRight">
                    {(provided) => (
                      <VStack
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        className="form-builder-in-progress-list"
                        w="40vw"
                        overflowY={'scroll'}
                        sx={{
                          '&::-webkit-scrollbar': {
                            width: '0px',
                            borderRadius: '8px',
                            backgroundColor: `rgba(0, 0, 0, 0.05)`,
                          },
                          '&::-webkit-scrollbar-thumb': {
                            backgroundColor: `rgba(0, 0, 0, 0.05)`,
                          },
                        }}
                        borderStyle="dashed"
                        borderColor="gray.200"
                        borderWidth="1px"
                        borderRadius="15px"
                        padding={5}
                      >
                        <>
                          {rightList.map(({ id: rowId, questions }, index) => (
                            // @ts-ignore-error React 18
                            <Draggable
                              draggableId={rowId}
                              index={index}
                              key={rowId}
                            >
                              {(draggableProvided) => (
                                <HStack width="100%">
                                  {questions.map(
                                    ({ id: questionId, label }) => (
                                      <Flex
                                        width="100%"
                                        height="64px"
                                        backgroundColor="gray.100"
                                        borderRadius={15}
                                        key={questionId}
                                        {...draggableProvided.draggableProps}
                                        {...draggableProvided.dragHandleProps}
                                        ref={draggableProvided.innerRef}
                                        justifyContent="space-between"
                                        padding={5}
                                      >
                                        <Text>{label}</Text>
                                        <IconButton
                                          aria-label="delete-field"
                                          variant="outline"
                                          size={'sm'}
                                          colorScheme="red"
                                          icon={<FiTrash />}
                                          onClick={() =>
                                            removeFromList(rowId, questionId)
                                          }
                                        />
                                      </Flex>
                                    )
                                  )}
                                </HStack>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </>
                      </VStack>
                    )}
                  </Droppable>
                </Grid>
              </VStack>
            </DragDropContext>
            <Box
              height={50}
              width="100%"
              position="absolute"
              bottom="8"
              paddingRight="20"
              backgroundColor="white"
            >
              <Divider />
              <Flex justifyContent="space-between" mt={5}>
                <Button onClick={goBack}>Cancel</Button>
                <HStack>
                  {customForm && (
                    <IconButton
                      aria-label="delete-consent"
                      variant="outline"
                      colorScheme="red"
                      icon={<FiTrash />}
                      onClick={onOpenDeleteFormModal}
                    />
                  )}
                  <Button type="submit" colorScheme="teal">
                    Save form
                  </Button>
                </HStack>
              </Flex>
            </Box>
          </chakra.form>
        </FormProvider>
        <CreateQuestionModal
          isOpen={createOpen}
          onClose={handleCloseCreate}
          question={currentQuestion}
          refetchQuestions={refetch}
        />
        <DeleteQuestionModal
          refetchQuestions={refetch}
          isOpen={deleteOpen}
          onClose={handleCloseDelete}
          question={currentQuestion}
        />
        <DeleteModal
          title="Form"
          body="form"
          onClose={onCloseDeleteFormModal}
          isOpen={deleteFormModalIsOpen}
          handleDelete={onDelete}
        />
        <WarningModal isOpen={showWarningModal} onClose={onCloseWarningModal} />
      </Stack>
    );
  }
);

export default CustomFormBuilder;
