import {
  InputGroup,
  InputLeftAddon,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  StyleProps,
} from '@chakra-ui/react';
import { FormInputProps } from '@webapp/types';
import { useState } from 'react';
import InputWrapper from '../InputWrapper/InputWrapper';
import './CurrencyInput.module.scss';

export type CurrencyInputProps = {
  changeMode?: 'onChange' | 'onBlur';
  customStyle?: {
    input?: StyleProps;
  };
  defaultValue?: number;
  errors?: any;
  hasIncrement?: boolean;
  incrementStep?: number;
  max?: number;
  min?: number;
  onBlur?: () => void;
  onChange?: (value: number) => void;
  onMount?: () => void;
  precision?: 0 | 2;
  value?: number;
} & Pick<
  FormInputProps,
  | 'id'
  | 'isEditable'
  | 'label'
  | 'labelAlign'
  | 'labelStyle'
  | 'labelSubText'
  | 'name'
  | 'disabled'
>;

export function CurrencyInput({
  changeMode = 'onChange',
  customStyle = {},
  defaultValue = 0,
  hasIncrement = false,
  incrementStep = 10,
  max,
  min,
  onBlur,
  onChange,
  onMount,
  precision = 2,
  disabled,
  ...formInputProps
}: CurrencyInputProps) {
  const [valueString, setValueString] = useState<string>(
    defaultValue.toFixed(2)
  );

  const [valueNumber, setValueNumber] = useState<number>(defaultValue);

  const { name = '' } = formInputProps;

  function handleBlur() {
    if (changeMode === 'onBlur' && onChange) {
      onChange(Math.round(valueNumber * 100));
    }
  }

  function handleChange(valueAsString: string, valueAsNumber: number) {
    const value = Math.round(valueAsNumber * 100);

    if (changeMode === 'onChange' && onChange) {
      onChange(value);
    }

    setValueNumber(valueAsNumber);
    setValueString(valueAsString);
  }

  return (
    <InputWrapper {...formInputProps}>
      <InputGroup>
        <InputLeftAddon children="$" />
        <NumberInput
          defaultValue={defaultValue}
          isDisabled={disabled}
          name={name}
          maxWidth="200px"
          min={min}
          max={max}
          onBlur={handleBlur}
          onChange={handleChange}
          precision={precision}
          step={incrementStep}
          value={valueString}
          {...customStyle.input}
        >
          <NumberInputField />
          {hasIncrement && (
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          )}
        </NumberInput>
      </InputGroup>
    </InputWrapper>
  );
}

export default CurrencyInput;
