import { Text } from '@chakra-ui/react';
import { FormInputProps } from '@webapp/types';
import { memo } from 'react';
import SelectFormInput from './SelectFormInput';
import DefaultFormInput from './DefaultFormInput';
import MultiSelectFormInput from './MultiSelectFormInput';
import TextEditorInput from './TextEditorInput';
import InlineDatePickerFormInput from './InlineDatePickerFormInput';
import DatePickerFormInput from './DatePickerFormInput';
import SwitchInput from './SwitchInput';
import CheckboxInput from './CheckboxInput';
import DateTimeRangePickerFormInput from './DateTimeRangePickerFormInput';
import FormInputLoading from './FormInputLoading';
import MultiPartDatePickerFormInput from './MultiPartDatePickerFormInput';
import CurrencyFormInput from './CurrencyFormInput';
import NumberFormInput from './NumberFormInput';
import TimeRangePickerFormInput from './TimeRangePickerFormInput';
import FormColorInput from './FormColorInput';
import SearchableSelectInput from './SearchableSelectInput';
import MaskedDateInput from './MaskedDateInput';
import { FlexibleDatePickerInput } from './FlexibleDatePicker';
import HeightInput from './HeightInput';
import MultiSelectFormInputB from './MultiSelectFormInputB';

export * from './HeightInput';
export * from './constants';
/* 
    This FormInput Component must exist within a 
    react-hook-form FormProvider or the app will crash

    The switch statement allows extensibility for potential
    inputs that do not have same straightforwardness of 
    text and generic select inputs.

    Ex. Date Input

    When a new input is required, add a case for that input's type
    and a component that handles that case.

    - Pedro
*/
export function FormInput({
  multiSelectProps,
  selectProps,
  datePickerProps,
  mobiScrollDatePickerProps,
  ...props
}: FormInputProps): JSX.Element {
  if (props.isLoading) {
    return <FormInputLoading {...props} />;
  }

  switch (props.type) {
    case 'checkbox': {
      return <CheckboxInput {...props} />;
    }

    case 'currency': {
      return <CurrencyFormInput {...props} />;
    }

    case 'date':
    case 'datetime':
    case 'datepicker': {
      return <DatePickerFormInput {...props} {...mobiScrollDatePickerProps} />;
    }

    case 'flexible-datepicker': {
      return <FlexibleDatePickerInput {...props} />;
    }

    case 'masked-date': {
      return <MaskedDateInput {...props} />;
    }

    case 'date-timerange-picker': {
      return <DateTimeRangePickerFormInput {...props} {...datePickerProps} />;
    }

    case 'timerange-picker': {
      return <TimeRangePickerFormInput {...props} {...datePickerProps} />;
    }

    case 'inline-datepicker': {
      return <InlineDatePickerFormInput {...props} />;
    }

    case 'text-editor': {
      return <TextEditorInput name={props.name} />;
    }

    case 'height': {
      return <HeightInput {...props} />;
    }

    case 'color': {
      return <FormColorInput {...props} />;
    }

    case 'multiselect-b': {
      if (multiSelectProps) {
        return <MultiSelectFormInputB {...props} {...multiSelectProps} />;
      }

      return <Text>Input not properly configured</Text>;
    }

    case 'multiselect': {
      if (multiSelectProps) {
        return <MultiSelectFormInput {...props} {...multiSelectProps} />;
      }

      return <Text>Input not properly configured</Text>;
    }

    case 'number': {
      return <NumberFormInput {...props} />;
    }

    case 'searchable-select': {
      return <SearchableSelectInput {...props} {...selectProps} />;
    }

    case 'async-select': {
      if (selectProps) {
        return <SelectFormInput {...props} {...selectProps} />;
      }

      return <Text>Input not properly configured</Text>;
    }

    case 'switch': {
      return <SwitchInput {...props} />;
    }

    case 'dob': {
      return <MultiPartDatePickerFormInput {...props} />;
    }

    default: {
      return <DefaultFormInput {...props} />;
    }
  }
}

export default memo(FormInput);
