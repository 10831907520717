import {
  Button,
  ButtonGroup,
  Flex,
  Grid,
  Text,
  Icon,
  IconButton,
  Tooltip,
  VStack,
  Wrap,
  WrapItem,
  Tag,
} from '@chakra-ui/react';
import {
  useInsertDocumentMutation,
  StorageType,
  DocumentFieldsFragment,
  AppointmentFieldsFullFragment,
} from '@webapp/graphql';
import { useStores } from '@webapp/state-models';
import { DataTable } from '@webapp/ui';
import { useUploadToS3 } from '@webapp/hooks';
import { useEffect, useMemo, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { FiEdit, FiUpload } from 'react-icons/fi';
import { HiPencilAlt, HiTrash } from 'react-icons/hi';
import { Cell, Column } from 'react-table';
import toast from 'react-hot-toast';
import { formatName } from '@webapp/util-formatting';
import dayjs from 'dayjs';
import { UpdateDocumentForm } from '@webapp/ui-composites';
import { stringToColor } from '@webapp/webapp/util-display';
import DropAndUploadModal from '../drop-and-upload-modal';

interface DocumentFormProps {
  isEditable: boolean;
  onToggle: () => void;
  appointment: AppointmentFieldsFullFragment;
  mini?: boolean;
}

type AppointmentDocument = AppointmentFieldsFullFragment['documents'][number];

type DocumentColumn = AppointmentDocument & {
  itemId: string;
};

export const DocumentForm = ({
  isEditable,
  onToggle,
  appointment,
  mini = false,
}: DocumentFormProps): JSX.Element => {
  const [documentToUpdate, setDocumentToUpdate] =
    useState<DocumentColumn | null>(null);

  const { workspace } = useStores();

  const { control, getValues, reset, setValue } = useFormContext();

  const [uploadDocumentModalOpen, setUploadDocumentModalOpen] = useState(false);
  const [progress, setProgress] = useState(0);

  const [insertDocument] = useInsertDocumentMutation();

  const { uploadToS3 } = useUploadToS3();

  const onCloseUploadDocumentModal = (): void => {
    setUploadDocumentModalOpen(false);
  };

  const openUploadModal = (): void => {
    setUploadDocumentModalOpen(true);
  };

  const onSubmitUpload = async (
    files: File[],
    title?: string,
    fileType?: string,
    tags?: { tagId: string }[]
  ): Promise<void> => {
    try {
      if (!files.length) throw new Error('No files selected');

      const file = files[0];

      const filePath = await uploadToS3({
        fileType: file.type,
        fileContents: file,
        filePath: file.name,
        storageType: StorageType.Document,
        onProgress: setProgress,
        randomizeFileName: true,
      });

      await insertDocument({
        variables: {
          document: {
            ...(tags?.length && { documentTags: { data: tags } }),
            title,
            filePath,
            workspaceId: workspace?.id,
            patientId: appointment.patientId,
            appointmentId: appointment.id,
          },
        },
        onCompleted: (data) => {
          const document = data.insert_document?.returning[0];

          if (document) {
            append({ ...document, itemId: document.id });
          }
        },
      });
    } catch (error) {
      toast.error((error as Error).message);
    }
  };

  const { append, fields, remove } = useFieldArray({
    control,
    name: 'documents',
  });

  function getValue(id: string): DocumentFieldsFragment {
    const index = fields.findIndex((field) => field.id === id);
    const name = `documents.${index}`;

    return getValues(name);
  }

  function handleCancelClick() {
    reset();
    onToggle();
  }

  const columns = useMemo(() => {
    const c: Column<DocumentColumn>[] = [
      {
        accessor: 'id',
        disableSortBy: true,
        defaultCanSort: false,
        Header: 'Document',
        Cell: (originalRow: Cell<DocumentColumn>) => {
          const { filePath, title, file } = getValue(
            originalRow.row.original.id
          );

          return (
            <Button
              variant="link"
              colorScheme="teal"
              noOfLines={1}
              maxW={mini ? '150px' : 'unset'}
              onClick={() => window.open(file?.url, '_blank')}
            >
              <VStack alignItems="start" justifyContent="start" width="100%">
                <Tooltip label={title ?? filePath} placement="top">
                  <Text noOfLines={1} wordBreak="break-all">
                    {(title ?? filePath)?.split('.')[0]}
                  </Text>
                </Tooltip>
                <Wrap>
                  {originalRow.row.original.documentTags.map((documentTag) => (
                    <WrapItem>
                      <Tag
                        bg={
                          documentTag.tag?.color
                            ? `${documentTag.tag.color}33`
                            : stringToColor(
                                documentTag.tag.title,
                                undefined,
                                '.2'
                              )
                        }
                        color={
                          documentTag.tag.color ||
                          stringToColor(documentTag.tag.title)
                        }
                      >
                        {documentTag.tag.title}
                      </Tag>
                    </WrapItem>
                  ))}
                </Wrap>
              </VStack>
            </Button>
          );
        },
      },
      {
        accessor: 'id',
        Cell: (e: Cell<DocumentColumn>) => (
          <Button
            onClick={() => {
              setDocumentToUpdate(e.row.original);
            }}
            variant="ghost"
          >
            <Icon as={FiEdit} />
          </Button>
        ),

        defaultCanSort: false,
        disableSortBy: true,
        Header: 'Edit',
        id: 'edit',
      },
    ];

    if (!mini && isEditable) {
      c.push({
        id: 'delete',
        accessor: (originalRow: DocumentColumn) =>
          getValue(originalRow.id).filePath,
        defaultCanSort: false,

        Header: '',
        Cell: (originalRow: Cell<DocumentColumn>) => {
          if (isEditable) {
            const index = fields.findIndex(
              (el) => el.id === originalRow.row.original.id
            );

            const name = `documents.${index}`;

            const removeDocument = (): void => {
              const [element, documentsToDelete] = getValues([
                name,
                'documentsToDelete',
              ]);

              remove(index);

              if (element?.itemId) {
                setValue('documentsToDelete', [...documentsToDelete, element]);
              }
            };

            return (
              <IconButton
                aria-label="Delete document"
                icon={<HiTrash />}
                color="red.500"
                variant="ghost"
                onClick={removeDocument}
              />
            );
          }

          return '';
        },
      });
    }

    return c;
  }, [mini, fields, isEditable]);

  useEffect(() => {
    setValue(
      'documents',
      appointment.documents.map((doc: AppointmentDocument) => ({
        ...doc,
        itemId: doc.id,
      }))
    );
  }, [appointment]);

  return (
    <>
      {documentToUpdate && (
        <UpdateDocumentForm
          defaultValues={{
            tags: documentToUpdate.documentTags.map((documentTag) => ({
              label: documentTag.tag.title,
              value: documentTag.tag.id,
            })),
            title: documentToUpdate.title ?? '',
          }}
          documentId={documentToUpdate.itemId}
          isOpen={Boolean(documentToUpdate)}
          onClose={() => {
            setDocumentToUpdate(null);
          }}
          onCompleted={() => {
            setDocumentToUpdate(null);
          }}
          refetchQueries={['GetAppointment']}
        />
      )}
      <Flex justifyContent="flex-end">
        <Button
          onClick={openUploadModal}
          variant="ghost"
          _hover={{
            background: '#EAFCFF',
          }}
          w="50px"
        >
          <Icon as={FiUpload} color="teal.500" height="18px" width="18px" />
        </Button>
        {!mini && (
          <Button
            onClick={onToggle}
            variant="ghost"
            _hover={{
              background: '#EAFCFF',
            }}
            w="50px"
          >
            <Icon
              as={HiPencilAlt}
              color="teal.500"
              height="18px"
              width="18px"
            />
          </Button>
        )}
      </Flex>
      <Grid>
        <DataTable
          columns={columns as Record<'id', string>[]}
          data={fields}
          customStyles={{
            borderColor: '#CFEBFF',
            cell: {
              color: '#6C6C72',
              width: 'min-content',
            },
            table: {
              borderRadius: '8px',
              minHeight: 'unset',
            },
            tableHeader: {
              background: '#EAFCFF',
              color: '#525257',
              textTransform: 'uppercase',
            },
          }}
          shouldDisplayPagination={false}
        />
        {isEditable && (
          <ButtonGroup margin="30px 0 0">
            <Button type="submit" colorScheme="teal">
              Save Changes
            </Button>
            <Button
              colorScheme="teal"
              onClick={handleCancelClick}
              variant="outline"
            >
              Cancel
            </Button>
          </ButtonGroup>
        )}
      </Grid>
      <DropAndUploadModal
        defaultValues={{
          title: `Patient Document: ${
            appointment.patient ? formatName(appointment.patient) : 'Unknown'
          } ${dayjs().format('MM/DD/YYYY')}`,
        }}
        hasTitle
        isOpen={uploadDocumentModalOpen}
        onClose={onCloseUploadDocumentModal}
        onSubmitUpload={onSubmitUpload}
        progress={progress}
        setProgress={setProgress}
      />
    </>
  );
};

export default DocumentForm;
