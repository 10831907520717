import { useSearchParams } from 'react-router-dom';

import { getAuth, signInWithCustomToken } from 'firebase/auth';
import { useEffect, useState } from 'react';
import { firebaseAuth } from '@webapp/auth';
import { useAuthState } from 'react-firebase-hooks/auth';
import toast from 'react-hot-toast';
import { useTradeJwtTokenMutation } from '@webapp/graphql';
import { LoadingFullScreen } from '../loading-full-screen/loading-full-screen';

/* eslint-disable-next-line */
export interface CustomTokenAuthProps {
  children: React.ReactNode;
  unauthenticated?: React.ReactNode;
  unauthenticatedMessage?: string;
}

export function CustomTokenAuth({
  children,
  unauthenticated,
  unauthenticatedMessage,
}: CustomTokenAuthProps) {
  const [searchParams] = useSearchParams();
  const [pageStatus, setPageStatus] = useState('initializing');
  const [user, loading, error] = useAuthState(firebaseAuth);
  const token = searchParams.get('token');
  const tokenv2 = searchParams.get('tokenv2');

  const [tradeJWTToken] = useTradeJwtTokenMutation({
    onError: (err) => {
      setPageStatus('unauthenticated');
    },
  });

  const signIn = async (token: string) => {
    try {
      const auth = getAuth();
      await signInWithCustomToken(auth, token);
    } catch (e) {
      setPageStatus('unauthenticated');
      toast.error(unauthenticatedMessage ?? `Error authenticating, try again.`);
    }
  };

  useEffect(() => {
    if (user) {
      setPageStatus('ready');
    }
  }, [user]);

  useEffect(() => {
    if (!loading || error) {
      if (token) {
        // Msq is short for Masquerade - this is if we want to MASQUERADE as another app 👏
        const msq = searchParams.get('msq');
        if (msq) {
          localStorage.setItem(`prspr_msq_${import.meta.env.VITE_ENV}`, msq);
        }

        if (token) {
          signIn(token);
        }
      }
    }
  }, [token, loading, error]);

  useEffect(() => {
    if (tokenv2) {
      try {
        tradeJWTToken({
          variables: {
            token: tokenv2,
          },
        }).then((d) => {
          if (d?.data?.tradeJWTToken?.token) {
            signIn(d?.data?.tradeJWTToken?.token);
          } else {
            setPageStatus('unauthenticated');
          }
        });
      } catch (err) {
        setPageStatus('unauthenticated');
      }
    }
  }, [tokenv2]);

  if (pageStatus === 'initializing') {
    return <LoadingFullScreen />;
  }
  if (pageStatus === 'unauthenticated' && unauthenticated) {
    return <div>{unauthenticated}</div>;
  }
  return <div>{children}</div>;
}

export default CustomTokenAuth;
