import { HexColorPicker, HexColorInput } from 'react-colorful';
import { useState } from 'react';
import {
  Popover,
  Button,
  HStack,
  Box,
  PopoverTrigger,
  InputGroup,
  InputLeftAddon,
  Input,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
  Portal,
  chakra,
  PopoverFooter,
  Stack,
} from '@chakra-ui/react';

/* eslint-disable-next-line */
export interface ColorInputProps {
  onChange?: (value: string) => void;
  value?: string;
  clearable?: boolean;
}

export function ColorInput({
  value,
  onChange,
  clearable = true,
}: ColorInputProps) {
  const [localValue, setLocalValue] = useState(value || '#ff8303');
  const [setting, setSetting] = useState(!!value);

  if (!setting && !localValue) {
    return <Button onClick={() => setSetting(true)}>Pick a color</Button>;
  }

  return (
    <HStack>
      <Popover isLazy>
        {({ onClose }) => (
          <>
            <PopoverTrigger>
              <Box
                rounded="full"
                shadow="md"
                borderWidth={2}
                borderColor="white"
                bg={`${value} !important` || '#ff8303 !important'}
                w={8}
                h={8}
              ></Box>
            </PopoverTrigger>
            <Portal>
              <PopoverContent>
                <PopoverArrow />
                <PopoverCloseButton />
                <PopoverHeader>Pick a color</PopoverHeader>
                <PopoverBody justifyContent={'center'} alignItems="center">
                  <Stack>
                    <HexColorPicker
                      color={localValue || '#ff8303'}
                      onChange={setLocalValue}
                    />

                    <Input
                      value={localValue}
                      onChange={(e) => setLocalValue(e.target.value)}
                      placeholder="Hex Color Code"
                    />
                  </Stack>
                </PopoverBody>
                <PopoverFooter>
                  <HStack w={'full'} justifyContent="flex-end">
                    <Box
                      w={8}
                      h={8}
                      bg={localValue}
                      rounded="full"
                      shadow="md"
                    />
                    <Button
                      onClick={() => {
                        if (onChange && localValue) {
                          onChange(localValue);
                          onClose();
                        }
                      }}
                    >
                      Select Color
                    </Button>
                  </HStack>
                </PopoverFooter>
              </PopoverContent>
            </Portal>
          </>
        )}
      </Popover>
      {localValue && clearable && (
        <Button
          onClick={() => {
            onChange?.('');
            setLocalValue('');
            setSetting(false);
          }}
        >
          Clear color
        </Button>
      )}
    </HStack>
  );
}

export default ColorInput;
