import {
  Stack,
  HStack,
  Image,
  Text,
  Spinner,
  Heading,
  Button,
  Badge,
} from '@chakra-ui/react';
import { toast } from 'react-hot-toast';
import {
  useGetMediaVersionsQuery,
  useRevertToMediaVersionMutation,
} from '@webapp/graphql';
import { Photo as MSTPhoto } from '@webapp/state-models';
import dayjs from 'dayjs';
import { FaChevronLeft } from 'react-icons/fa';
import { getErrorMessage, getProxyPhotoUrl } from '@webapp/utils';

interface ImageHistoryProps {
  photo: MSTPhoto;
  onClose?: () => void;
}

export default function ImageHistory({ photo, onClose }: ImageHistoryProps) {
  const { data, loading, error, refetch } = useGetMediaVersionsQuery({
    variables: { photoId: photo.id },
  });

  const [revertToMediaVersion, { loading: revertingImage }] =
    useRevertToMediaVersionMutation();

  if (loading) return <Spinner />;
  if (error) return <Text>{error.message}</Text>;

  async function handleRevertingImage(versionId: string) {
    try {
      await revertToMediaVersion({
        variables: {
          photoId: photo?.id,
          versionId,
        },
      });

      await refetch();
      toast.success('Image reverted successfully');
    } catch (error) {
      toast.error(getErrorMessage(error));
    }
  }

  return (
    <Stack p={4}>
      <Heading size="md">Image History</Heading>
      <Button
        w={20}
        size="sm"
        alignSelf="flex-start"
        onClick={onClose}
        variant="ghost"
        leftIcon={<FaChevronLeft />}
      >
        Go back
      </Button>
      {data?.getMediaVersions?.versions?.map((v) => (
        <HStack rounded="md" shadow="md" mb={4} overflow="hidden" p={4}>
          <Image
            className="ph-no-capture fs-exclude"
            h={40}
            w={40}
            objectFit={'cover'}
            src={getProxyPhotoUrl(v?.imageUrl as string)}
          />
          <Stack>
            <Text>
              Created {dayjs(v?.lastModified).format('MMM D, YYYY h:mm A')}
            </Text>
            {v?.isLatest ? (
              <Badge colorScheme="green" size={'md'} w={20} textAlign="center">
                Latest
              </Badge>
            ) : (
              <Button
                isLoading={revertingImage}
                onClick={() => handleRevertingImage(v?.versionId as string)}
              >
                Revert to this version
              </Button>
            )}
          </Stack>
        </HStack>
      ))}
    </Stack>
  );
}
