import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type SendInviteLinkMutationVariables = Types.Exact<{
  email: Types.Scalars['String'];
  role: Types.Scalars['String'];
  firstName: Types.Scalars['String'];
  lastName: Types.Scalars['String'];
  workspaceId: Types.Scalars['uuid'];
}>;


export type SendInviteLinkMutation = { __typename?: 'mutation_root', sendInviteLink?: { __typename?: 'SendInviteLinkResponse', success: boolean } | null | undefined };


export const SendInviteLinkDocument = gql`
    mutation sendInviteLink($email: String!, $role: String!, $firstName: String!, $lastName: String!, $workspaceId: uuid!) {
  sendInviteLink(
    email: $email
    role: $role
    firstName: $firstName
    lastName: $lastName
    workspaceId: $workspaceId
  ) {
    success
  }
}
    `;
export type SendInviteLinkMutationFn = Apollo.MutationFunction<SendInviteLinkMutation, SendInviteLinkMutationVariables>;

/**
 * __useSendInviteLinkMutation__
 *
 * To run a mutation, you first call `useSendInviteLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendInviteLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendInviteLinkMutation, { data, loading, error }] = useSendInviteLinkMutation({
 *   variables: {
 *      email: // value for 'email'
 *      role: // value for 'role'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      workspaceId: // value for 'workspaceId'
 *   },
 * });
 */
export function useSendInviteLinkMutation(baseOptions?: Apollo.MutationHookOptions<SendInviteLinkMutation, SendInviteLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendInviteLinkMutation, SendInviteLinkMutationVariables>(SendInviteLinkDocument, options);
      }
export type SendInviteLinkMutationHookResult = ReturnType<typeof useSendInviteLinkMutation>;
export type SendInviteLinkMutationResult = Apollo.MutationResult<SendInviteLinkMutation>;
export type SendInviteLinkMutationOptions = Apollo.BaseMutationOptions<SendInviteLinkMutation, SendInviteLinkMutationVariables>;