import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { SignatureFieldsFragmentDoc } from '../../fragments/signatureFields.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type UpdateSignatureMutationVariables = Types.Exact<{
  set?: Types.Maybe<Types.Signature_Set_Input>;
  id: Types.Scalars['uuid'];
}>;


export type UpdateSignatureMutation = { __typename?: 'mutation_root', update_signature_by_pk?: { __typename?: 'signature', id: any, filePath: string, file?: { __typename?: 'FileOnlyURL', url: string } | null | undefined } | null | undefined };


export const UpdateSignatureDocument = gql`
    mutation UpdateSignature($set: signature_set_input, $id: uuid!) {
  update_signature_by_pk(_set: $set, pk_columns: {id: $id}) {
    ...SignatureFields
  }
}
    ${SignatureFieldsFragmentDoc}`;
export type UpdateSignatureMutationFn = Apollo.MutationFunction<UpdateSignatureMutation, UpdateSignatureMutationVariables>;

/**
 * __useUpdateSignatureMutation__
 *
 * To run a mutation, you first call `useUpdateSignatureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSignatureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSignatureMutation, { data, loading, error }] = useUpdateSignatureMutation({
 *   variables: {
 *      set: // value for 'set'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateSignatureMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSignatureMutation, UpdateSignatureMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSignatureMutation, UpdateSignatureMutationVariables>(UpdateSignatureDocument, options);
      }
export type UpdateSignatureMutationHookResult = ReturnType<typeof useUpdateSignatureMutation>;
export type UpdateSignatureMutationResult = Apollo.MutationResult<UpdateSignatureMutation>;
export type UpdateSignatureMutationOptions = Apollo.BaseMutationOptions<UpdateSignatureMutation, UpdateSignatureMutationVariables>;