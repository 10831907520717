import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { ConsumableFieldsFragmentDoc } from '../../fragments/consumableFields.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type UpdateConsumableMutationVariables = Types.Exact<{
  _set: Types.Consumable_Set_Input;
  id: Types.Scalars['uuid'];
}>;


export type UpdateConsumableMutation = { __typename?: 'mutation_root', update_consumable_by_pk?: { __typename?: 'consumable', id: any, cost: number, metadata: any, originalQuantity: any, quantity: any, isExpirable: boolean, expirationDate?: any | null | undefined, daysBeforeNotification?: number | null | undefined, shouldWarnQuantity: boolean, warningQuantity?: number | null | undefined, createdAt: any, updatedAt: any, archived: boolean, type: { __typename?: 'consumableType', id: any, name: string, requiredFields: any, unitStep: any, unitName: string, defaultNumberOfUnits: any, defaultPricePerUnit: number }, brand?: { __typename?: 'consumableBrand', id: any, name: string } | null | undefined, purchaseOrder?: { __typename?: 'purchaseOrder', id: any, orderNumber: string } | null | undefined } | null | undefined };


export const UpdateConsumableDocument = gql`
    mutation UpdateConsumable($_set: consumable_set_input!, $id: uuid!) {
  update_consumable_by_pk(_set: $_set, pk_columns: {id: $id}) {
    ...ConsumableFields
  }
}
    ${ConsumableFieldsFragmentDoc}`;
export type UpdateConsumableMutationFn = Apollo.MutationFunction<UpdateConsumableMutation, UpdateConsumableMutationVariables>;

/**
 * __useUpdateConsumableMutation__
 *
 * To run a mutation, you first call `useUpdateConsumableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateConsumableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateConsumableMutation, { data, loading, error }] = useUpdateConsumableMutation({
 *   variables: {
 *      _set: // value for '_set'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateConsumableMutation(baseOptions?: Apollo.MutationHookOptions<UpdateConsumableMutation, UpdateConsumableMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateConsumableMutation, UpdateConsumableMutationVariables>(UpdateConsumableDocument, options);
      }
export type UpdateConsumableMutationHookResult = ReturnType<typeof useUpdateConsumableMutation>;
export type UpdateConsumableMutationResult = Apollo.MutationResult<UpdateConsumableMutation>;
export type UpdateConsumableMutationOptions = Apollo.BaseMutationOptions<UpdateConsumableMutation, UpdateConsumableMutationVariables>;