import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
} from '@chakra-ui/react';
import {
  PayForm,
  PayrixPayfieldsProps,
  CardOwnerDetails,
  CardTokenFormOption,
} from '@webapp/ui-composites';
import { useFlagsmith } from 'flagsmith-react';
import { FaExclamationCircle } from 'react-icons/fa';

export interface PaymentModalProps
  extends PayrixPayfieldsProps<'token'>,
    Partial<CardTokenFormOption> {
  isOpen: boolean;
  onClose: () => void;
  skipName?: string;
  onSkip?: () => void;
  cardOwnerDetails?: Partial<CardOwnerDetails>;
  finixMerchantId?: string;
}

export const PaymentModal = ({
  isOpen,
  onClose,
  skipName,
  onSkip,
  finixMerchantId,
  ...payrixPayFieldsProps
}: PaymentModalProps): JSX.Element => {
  const hasSkip = skipName && onSkip;

  const { hasFeature } = useFlagsmith();

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent maxWidth="unset" w="750px">
        <ModalHeader>{skipName}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <PayForm
            {...payrixPayFieldsProps}
            type={
              hasFeature('finix') && finixMerchantId
                ? 'cardTokenForm'
                : 'payrix'
            }
          />
        </ModalBody>
        <ModalFooter>
          {hasSkip && (
            <Button leftIcon={<FaExclamationCircle />} onClick={onSkip}>
              {`Override ${skipName}`}
            </Button>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default PaymentModal;
