import { Popover, Button, PopoverContent, PopoverBody } from '@chakra-ui/react';

import { Datepicker } from '@mobiscroll/react';
import { PopoverTrigger } from '../popover-trigger/popover-trigger';

interface TimePickerProps {
  isOpen: boolean;
  minTime?: string;
  maxTime?: string;
  onToggle: () => void;
  onClose: () => void;
  time: string;
  handleTimeChange: ({ valueText }: { valueText: string }) => void;
  stepMinute?: number;
}

export default function TimePicker({
  isOpen,
  handleTimeChange,
  onClose,
  onToggle,
  time,
  minTime = '05:00',
  maxTime = '21:00',
  stepMinute = 30,
}: TimePickerProps): JSX.Element {
  return (
    <Popover isOpen={isOpen} onClose={onClose} placement="bottom-start">
      <PopoverTrigger>
        <Button
          borderRadius="0"
          className="date-time-trigger"
          color="text.100"
          fontSize="14px"
          letterSpacing="0.03em"
          onClick={onToggle}
          justifyContent="start"
          variant="ghost"
        >
          {time}
        </Button>
      </PopoverTrigger>
      <PopoverContent
        css={{
          '&::-webkit-scrollbar': {
            display: 'none',
          },
        }}
        sx={{
          scrollbarWidth: 'none',
        }}
      >
        <PopoverBody
          {...(!isOpen && { display: 'none' })}
          padding="0"
          css={{
            '&::-webkit-scrollbar': {
              display: 'none',
            },
          }}
          sx={{
            scrollbarWidth: 'none',
          }}
        >
          <Datepicker
            controls={['timegrid']}
            stepMinute={stepMinute}
            defaultValue={time}
            display="inline"
            onChange={handleTimeChange}
            theme="material"
            themeVariant="light"
            minTime={minTime}
            maxTime={maxTime}
          />
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
}
