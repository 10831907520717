import './prospyr-logo.module.css';
import { Image } from '@chakra-ui/react';
import DEFAULT_LOGO from './assets/logo.svg';

/* eslint-disable-next-line */
export interface ProspyrLogoProps {
  maxHeight?: string;
}

export function ProspyrLogo({ maxHeight = '35px' }: ProspyrLogoProps) {
  return <Image maxHeight={maxHeight} src={DEFAULT_LOGO} />;
}

export default ProspyrLogo;
