import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type CreateFinixPaymentInstrumentMutationVariables = Types.Exact<{
  token: Types.Scalars['String'];
  patientId: Types.Scalars['uuid'];
  cardHolderDetails: Types.Scalars['jsonb'];
}>;


export type CreateFinixPaymentInstrumentMutation = { __typename?: 'mutation_root', createFinixPaymentInstrument?: { __typename?: 'SuccessAnswer', success: boolean } | null | undefined };


export const CreateFinixPaymentInstrumentDocument = gql`
    mutation CreateFinixPaymentInstrument($token: String!, $patientId: uuid!, $cardHolderDetails: jsonb!) {
  createFinixPaymentInstrument(
    token: $token
    patientId: $patientId
    cardHolderDetails: $cardHolderDetails
  ) {
    success
  }
}
    `;
export type CreateFinixPaymentInstrumentMutationFn = Apollo.MutationFunction<CreateFinixPaymentInstrumentMutation, CreateFinixPaymentInstrumentMutationVariables>;

/**
 * __useCreateFinixPaymentInstrumentMutation__
 *
 * To run a mutation, you first call `useCreateFinixPaymentInstrumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFinixPaymentInstrumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFinixPaymentInstrumentMutation, { data, loading, error }] = useCreateFinixPaymentInstrumentMutation({
 *   variables: {
 *      token: // value for 'token'
 *      patientId: // value for 'patientId'
 *      cardHolderDetails: // value for 'cardHolderDetails'
 *   },
 * });
 */
export function useCreateFinixPaymentInstrumentMutation(baseOptions?: Apollo.MutationHookOptions<CreateFinixPaymentInstrumentMutation, CreateFinixPaymentInstrumentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateFinixPaymentInstrumentMutation, CreateFinixPaymentInstrumentMutationVariables>(CreateFinixPaymentInstrumentDocument, options);
      }
export type CreateFinixPaymentInstrumentMutationHookResult = ReturnType<typeof useCreateFinixPaymentInstrumentMutation>;
export type CreateFinixPaymentInstrumentMutationResult = Apollo.MutationResult<CreateFinixPaymentInstrumentMutation>;
export type CreateFinixPaymentInstrumentMutationOptions = Apollo.BaseMutationOptions<CreateFinixPaymentInstrumentMutation, CreateFinixPaymentInstrumentMutationVariables>;