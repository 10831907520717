import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { AppointmentTypeFieldsFragmentDoc } from '../../fragments/appointmentTypeFields.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type UpdateAppointmentTypeMutationVariables = Types.Exact<{
  set?: Types.Maybe<Types.AppointmentType_Set_Input>;
  id: Types.Scalars['uuid'];
}>;


export type UpdateAppointmentTypeMutation = { __typename?: 'mutation_root', update_appointmentType_by_pk?: { __typename?: 'appointmentType', id: any, name: string, color?: string | null | undefined, maxPerDay?: number | null | undefined } | null | undefined };


export const UpdateAppointmentTypeDocument = gql`
    mutation UpdateAppointmentType($set: appointmentType_set_input, $id: uuid!) {
  update_appointmentType_by_pk(_set: $set, pk_columns: {id: $id}) {
    ...AppointmentTypeFields
  }
}
    ${AppointmentTypeFieldsFragmentDoc}`;
export type UpdateAppointmentTypeMutationFn = Apollo.MutationFunction<UpdateAppointmentTypeMutation, UpdateAppointmentTypeMutationVariables>;

/**
 * __useUpdateAppointmentTypeMutation__
 *
 * To run a mutation, you first call `useUpdateAppointmentTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAppointmentTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAppointmentTypeMutation, { data, loading, error }] = useUpdateAppointmentTypeMutation({
 *   variables: {
 *      set: // value for 'set'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateAppointmentTypeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAppointmentTypeMutation, UpdateAppointmentTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAppointmentTypeMutation, UpdateAppointmentTypeMutationVariables>(UpdateAppointmentTypeDocument, options);
      }
export type UpdateAppointmentTypeMutationHookResult = ReturnType<typeof useUpdateAppointmentTypeMutation>;
export type UpdateAppointmentTypeMutationResult = Apollo.MutationResult<UpdateAppointmentTypeMutation>;
export type UpdateAppointmentTypeMutationOptions = Apollo.BaseMutationOptions<UpdateAppointmentTypeMutation, UpdateAppointmentTypeMutationVariables>;