import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type UpdateAppointmentMutationVariables = Types.Exact<{
  set: Types.Scalars['jsonb'];
  id: Types.Scalars['uuid'];
  shouldUpdateServiceTimes?: Types.Maybe<Types.Scalars['Boolean']>;
}>;


export type UpdateAppointmentMutation = { __typename?: 'mutation_root', updateAppointmentAndNotify?: { __typename?: 'UpdateAppointmentWithNotifyOutput', appointmentId?: any | null | undefined, timerange?: string | null | undefined } | null | undefined };


export const UpdateAppointmentDocument = gql`
    mutation UpdateAppointment($set: jsonb!, $id: uuid!, $shouldUpdateServiceTimes: Boolean) {
  updateAppointmentAndNotify(
    id: $id
    set: $set
    shouldUpdateServiceTimes: $shouldUpdateServiceTimes
  ) {
    appointmentId
    timerange
  }
}
    `;
export type UpdateAppointmentMutationFn = Apollo.MutationFunction<UpdateAppointmentMutation, UpdateAppointmentMutationVariables>;

/**
 * __useUpdateAppointmentMutation__
 *
 * To run a mutation, you first call `useUpdateAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAppointmentMutation, { data, loading, error }] = useUpdateAppointmentMutation({
 *   variables: {
 *      set: // value for 'set'
 *      id: // value for 'id'
 *      shouldUpdateServiceTimes: // value for 'shouldUpdateServiceTimes'
 *   },
 * });
 */
export function useUpdateAppointmentMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAppointmentMutation, UpdateAppointmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAppointmentMutation, UpdateAppointmentMutationVariables>(UpdateAppointmentDocument, options);
      }
export type UpdateAppointmentMutationHookResult = ReturnType<typeof useUpdateAppointmentMutation>;
export type UpdateAppointmentMutationResult = Apollo.MutationResult<UpdateAppointmentMutation>;
export type UpdateAppointmentMutationOptions = Apollo.BaseMutationOptions<UpdateAppointmentMutation, UpdateAppointmentMutationVariables>;