import {
  Flex,
  FormLabel,
  Heading,
  Spinner,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react';
import { MbscCalendarEvent } from '@mobiscroll/react';
import { STATUSES } from '@webapp/constants';
import { useStores } from '@webapp/state-models';
import { PhoneNumberTextWrapper, StatusBadge } from '@webapp/ui';
import { configuredDayjs as dayjs } from '@webapp/util-time';
import {PatientIcons} from '@webapp/ui';
import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';
import { AppointmentStatusIcons } from '../AppointmentStatusIcons';

interface CalendarTooltipProps {
  anchor: HTMLElement | null | undefined;
  event: MbscCalendarEvent | null;
  isOpen: boolean;
  view: string;
}

function CalendarTooltip({
  anchor,
  event = {},
  isOpen,
  view,
}: CalendarTooltipProps) {
  const {
    end,
    note,
    phoneNumber,
    patientId,
    patientName,
    providerName,
    start,
    status,
    type,
  } = event || {};
  const { calendarTimeZone } = useStores();
  let title = '';
  const startTime = dayjs(start as string)
    .tz(calendarTimeZone)
    .format('h:mm A z');
  if (view !== 'day') {
    title = event?.title || '';
  } else if (type == 'patient_appointment') {
    title = `${patientName} - ${startTime}`;
  } else {
    title = note ? `${note.toString()} - ${startTime}` : startTime || '';
  }

  const statusStyles = useMemo(() => {
    const appointmentStatus = STATUSES.find(
      (status) => status.value === event?.status
    );
    return appointmentStatus ?? STATUSES[0];
  }, [event]);

  return (
    <Flex flexDirection="column">
      <Flex
        alignItems="center"
        background={statusStyles.color}
        filter={!event ? 'blur(3px)' : undefined}
        justifyContent="space-between"
        padding="10px 16px"
      >
        <Heading color="white" fontSize="16px">
          {title}
        </Heading>
        {status && <StatusBadge status={status} />}
      </Flex>
      {!event && (
        <Flex alignItems="center" height="300px" justifyContent="center">
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color={statusStyles.color}
            size="xl"
          />
        </Flex>
      )}
      <Stack marginBlockStart={2}>
        <PatientIcons
          appointment={event}
          isTooltipDisabled={true}
          containerStyles={{
            paddingInlineStart: '20px',
            paddingBlock: '0',
            marginBlockEnd: '-5px',
            placeItems: 'flex-end',
          }}
          iconContainerStyles={{
            size: 5,
          }}
        />
      </Stack>
      {event && (
        <VStack padding="10px 16px 35px 16px" spacing="15px" width="100%">
          {phoneNumber && patientId && (
            <Flex flexDirection="column" width="100%">
              <FormLabel fontWeight="500">Phone Number</FormLabel>
              <PhoneNumberTextWrapper
                patientId={patientId}
                phoneNumber={phoneNumber}
              />
            </Flex>
          )}
          <Flex flexDirection="column" width="100%">
            <FormLabel fontWeight="500">Timing</FormLabel>
            {
              <Text data-testid="tooltip-timing">
                {start
                  ? dayjs(start as string)
                      .tz(calendarTimeZone)
                      .format('h:mm A')
                  : undefined}{' '}
                to{' '}
                {end
                  ? dayjs(end as string)
                      .tz(calendarTimeZone)
                      .format('h:mm A z')
                  : undefined}
              </Text>
            }
          </Flex>
          <Flex flexDirection="column" width="100%">
            <FormLabel fontWeight="500">Provider</FormLabel>

            {type === 'patient_appointment' ? (
              <Text>{providerName ? providerName : 'N/A'}</Text>
            ) : (
              <Text>
                {event.internalAppointmentProviders &&
                  event.internalAppointmentProviders
                    .map((provider: string) => provider)
                    .join(', ')}
              </Text>
            )}
          </Flex>
          {event.services && (
            <Flex flexDirection="column" width="100%">
              <FormLabel fontWeight="500">Services</FormLabel>
              <VStack spacing="12px">
                {event.services.map((service) => (
                  <Text
                    background="gray.200"
                    borderRadius="4px"
                    color="text.100"
                    key={`appointment-service-${event.serviceId}`}
                    padding="4px 6px"
                    width="100%"
                  >
                    {service}
                  </Text>
                ))}
              </VStack>
            </Flex>
          )}

          {event.room && (
            <Flex flexDirection="column" width="100%">
              <FormLabel fontWeight="500">Room</FormLabel>

              <Text>{event.room}</Text>
            </Flex>
          )}
          {event.note && (
            <Text w={'full'} textAlign="left" as="i">
              "{event.note}"
            </Text>
          )}
          {event.type !== 'other' && (
            <Flex flexDirection="column" width="100%">
              <FormLabel fontWeight="500">Status</FormLabel>
              <AppointmentStatusIcons
                alignment="flex-start"
                appointmentStatus={event?.status}
                hasPhotos={event?.hasPhotos}
                consumablesRecorded={event?.consumablesRecorded}
                intakeStatus={event?.intakeStatus}
                notesSigned={event?.notesSigned}
                startTime={event?.start}
              />
            </Flex>
          )}
        </VStack>
      )}
    </Flex>
  );
}

export default observer(CalendarTooltip);
