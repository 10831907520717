/* eslint-disable import/prefer-default-export */
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Grid,
  GridItem,
  Heading,
  HStack,
  Stack,
} from '@chakra-ui/react';
import { useStores } from '@webapp/state-models';
import { observer } from 'mobx-react-lite';
import {
  AudienceFilterPredicate,
  AudiencePreview,
  AudienceSelectedFilters,
} from '.';
import filterDefinitions from './filter-definitions';

/* eslint-disable-next-line */
interface FilterCategoryProps {
  predicates: any;
}

const FilterCategory = ({ predicates }: FilterCategoryProps) => (
  <Stack spacing="2">
    {predicates.map((pred: any, idx: number) => (
      <AudienceFilterPredicate
        key={idx}
        target={pred.target}
        field={pred.field}
        values={pred.values}
        valuesComponent={pred.valuesComponent}
        ops={pred.ops}
      />
    ))}
  </Stack>
);

export const AudienceFilterBuilder = observer(() => {
  const { audience } = useStores();
  const filterCount = audience.predicate.predicates.length;
  return (
    <HStack alignItems={'flex-start'} justifyContent="space-between" w={'full'}>
      <Stack spacing="2" flex={2}>
        <Box>
          <Heading size="sm">Filters Applied ({filterCount})</Heading>
        </Box>
        <Box p="2">
          <AudienceSelectedFilters editable={true} />
        </Box>
        <Accordion allowMultiple size="xs" minW="400px">
          {filterDefinitions.map((filter: any, idx: number) => (
            <AccordionItem key={idx}>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  <Heading size="xs">{filter.label}</Heading>
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel pb={4}>
                <FilterCategory predicates={filter.predicates} />
              </AccordionPanel>
            </AccordionItem>
          ))}
        </Accordion>
      </Stack>

      <Box flex={3}>
        <AudiencePreview />
      </Box>
    </HStack>
  );
});
