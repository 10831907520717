import { FormInputProps } from '@webapp/types';
import { useFormContext } from 'react-hook-form';
import { memo, useEffect, useState } from 'react';
import {
  FormLabel,
  Grid,
  HStack,
  IconButton,
  Box,
  Text,
  useDisclosure,
} from '@chakra-ui/react';

import { ErrorMessage } from '@hookform/error-message';
import dayjs from 'dayjs';
import { Datepicker } from '@mobiscroll/react';
import { FaCalendar } from 'react-icons/fa';
import { DEFAULT_LABEL_STYLE } from './constants';

const DATE_FORMAT = 'MM/DD/YYYY';
const TIME_FORMAT = 'hh:mm A';

export function FlexibleDatePickerInput({
  id,
  label,
  labelAlign,
  labelStyle = DEFAULT_LABEL_STYLE,
  name,
  type,
}: FormInputProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const {
    formState: { errors },
    getValues,
    register,
    setValue,
  } = useFormContext();
  const initialDate = dayjs(getValues(name)).isValid()
    ? dayjs(getValues(name)).format()
    : null;
  const [date, setDate] = useState<string | null>(initialDate);

  const inputId = id ?? name;

  useEffect(() => {
    register(name);
  });

  useEffect(() => {
    const formattedDate = dayjs(date).isValid()
      ? dayjs(date).format(DATE_FORMAT)
      : null;

    setValue(name, formattedDate);
  }, [date, name, setValue]);

  function handleDateChange({ value }: { value: Date; valueText: string }) {
    setDate(value.toUTCString());
    onClose();
  }

  return (
    <Grid
      alignItems={labelAlign}
      className="form-input-date-picker input-outer"
      gridAutoRows="max-content"
      gridRowGap="6px"
      gridTemplateColumns="1fr"
      key={name}
    >
      {Boolean(label) && (
        <FormLabel
          className="input-label"
          htmlFor={inputId}
          margin="0 0 0 0"
          {...labelStyle}
        >
          {label}
        </FormLabel>
      )}
      <Grid className="input-inner" gridRowGap="8px">
        <Grid
          background="white"
          border="1px solid"
          borderColor="gray.200"
          borderRadius="var(--chakra-radii-md)"
          height="40px"
        >
          <HStack w={'full'} justifyContent="between">
            <Box flex={3} p={1}>
              <Datepicker
                theme="ios"
                themeVariant="light"
                controls={['date']}
                onChange={handleDateChange}
                showOnClick={false}
                showOnFocus={false}
                isOpen={isOpen}
                onClose={onClose}
                dateFormat={'MM/DD/YYYY'}
                touchUi={true}
                inputComponent="input"
                inputProps={{ placeholder: 'MM/DD/YYYY' }}
                defaultValue={initialDate}
              />
            </Box>

            <IconButton
              flex={1}
              aria-label="select-date"
              flexShrink={1}
              icon={<FaCalendar />}
              onClick={onOpen}
            ></IconButton>
          </HStack>
        </Grid>
        <ErrorMessage
          errors={errors}
          name={name}
          render={({ message }) => (
            <Text
              className="input-error"
              color="red.500"
              _before={{
                display: 'inline',
                content: '"⚠ "',
              }}
            >
              {message}
            </Text>
          )}
        />
      </Grid>
    </Grid>
  );
}

export default memo(FlexibleDatePickerInput);
