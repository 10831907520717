import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { PatientTagsFieldsFragmentDoc } from '../../fragments/patientTagsFields.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetPatientTagsQueryVariables = Types.Exact<{
  where?: Types.Maybe<Types.PatientTags_Bool_Exp>;
  limit?: Types.Maybe<Types.Scalars['Int']>;
  offset?: Types.Maybe<Types.Scalars['Int']>;
  orderBy?: Types.Maybe<Array<Types.PatientTags_Order_By> | Types.PatientTags_Order_By>;
}>;


export type GetPatientTagsQuery = { __typename?: 'query_root', patientTags: Array<{ __typename?: 'patientTags', id: any, patientTag: { __typename?: 'patientTag', id: any, title: string, color?: string | null | undefined } }> };


export const GetPatientTagsDocument = gql`
    query GetPatientTags($where: patientTags_bool_exp, $limit: Int, $offset: Int, $orderBy: [patientTags_order_by!]) {
  patientTags(where: $where, limit: $limit, offset: $offset, order_by: $orderBy) {
    ...PatientTagsFields
  }
}
    ${PatientTagsFieldsFragmentDoc}`;

/**
 * __useGetPatientTagsQuery__
 *
 * To run a query within a React component, call `useGetPatientTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPatientTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPatientTagsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetPatientTagsQuery(baseOptions?: Apollo.QueryHookOptions<GetPatientTagsQuery, GetPatientTagsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPatientTagsQuery, GetPatientTagsQueryVariables>(GetPatientTagsDocument, options);
      }
export function useGetPatientTagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPatientTagsQuery, GetPatientTagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPatientTagsQuery, GetPatientTagsQueryVariables>(GetPatientTagsDocument, options);
        }
export type GetPatientTagsQueryHookResult = ReturnType<typeof useGetPatientTagsQuery>;
export type GetPatientTagsLazyQueryHookResult = ReturnType<typeof useGetPatientTagsLazyQuery>;
export type GetPatientTagsQueryResult = Apollo.QueryResult<GetPatientTagsQuery, GetPatientTagsQueryVariables>;