import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type DeleteLeadFormMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
}>;


export type DeleteLeadFormMutation = { __typename?: 'mutation_root', delete_leadForm_by_pk?: { __typename?: 'leadForm', id: any } | null | undefined };


export const DeleteLeadFormDocument = gql`
    mutation deleteLeadForm($id: uuid!) {
  delete_leadForm_by_pk(id: $id) {
    id
  }
}
    `;
export type DeleteLeadFormMutationFn = Apollo.MutationFunction<DeleteLeadFormMutation, DeleteLeadFormMutationVariables>;

/**
 * __useDeleteLeadFormMutation__
 *
 * To run a mutation, you first call `useDeleteLeadFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLeadFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLeadFormMutation, { data, loading, error }] = useDeleteLeadFormMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteLeadFormMutation(baseOptions?: Apollo.MutationHookOptions<DeleteLeadFormMutation, DeleteLeadFormMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteLeadFormMutation, DeleteLeadFormMutationVariables>(DeleteLeadFormDocument, options);
      }
export type DeleteLeadFormMutationHookResult = ReturnType<typeof useDeleteLeadFormMutation>;
export type DeleteLeadFormMutationResult = Apollo.MutationResult<DeleteLeadFormMutation>;
export type DeleteLeadFormMutationOptions = Apollo.BaseMutationOptions<DeleteLeadFormMutation, DeleteLeadFormMutationVariables>;