import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type EnrollProviderERxMutationVariables = Types.Exact<{
  providerId: Types.Scalars['uuid'];
  locationIds: Array<Types.Scalars['uuid']> | Types.Scalars['uuid'];
}>;


export type EnrollProviderERxMutation = { __typename?: 'mutation_root', enrollProviderERx: { __typename?: 'SuccessAnswer', success: boolean } };


export const EnrollProviderERxDocument = gql`
    mutation EnrollProviderERx($providerId: uuid!, $locationIds: [uuid!]!) {
  enrollProviderERx(providerId: $providerId, locationIds: $locationIds) {
    success
  }
}
    `;
export type EnrollProviderERxMutationFn = Apollo.MutationFunction<EnrollProviderERxMutation, EnrollProviderERxMutationVariables>;

/**
 * __useEnrollProviderERxMutation__
 *
 * To run a mutation, you first call `useEnrollProviderERxMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnrollProviderERxMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [enrollProviderERxMutation, { data, loading, error }] = useEnrollProviderERxMutation({
 *   variables: {
 *      providerId: // value for 'providerId'
 *      locationIds: // value for 'locationIds'
 *   },
 * });
 */
export function useEnrollProviderERxMutation(baseOptions?: Apollo.MutationHookOptions<EnrollProviderERxMutation, EnrollProviderERxMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EnrollProviderERxMutation, EnrollProviderERxMutationVariables>(EnrollProviderERxDocument, options);
      }
export type EnrollProviderERxMutationHookResult = ReturnType<typeof useEnrollProviderERxMutation>;
export type EnrollProviderERxMutationResult = Apollo.MutationResult<EnrollProviderERxMutation>;
export type EnrollProviderERxMutationOptions = Apollo.BaseMutationOptions<EnrollProviderERxMutation, EnrollProviderERxMutationVariables>;