import dayjs from 'dayjs';

interface GetLastNYearsOptions {
  endBuffer?: number;
  includeCurrentYear?: boolean;
}

export function getLastNYears(
  n: number,
  options: GetLastNYearsOptions = {}
): string[] {
  const { endBuffer = 0, includeCurrentYear = true } = options;

  function getAdjustedEndBuffer() {
    if (endBuffer === 0 && !includeCurrentYear) {
      return 1;
    }

    return endBuffer;
  }

  const adjustedEndBuffer = getAdjustedEndBuffer();

  const yearEnd = dayjs()
    .subtract(n - 1, 'years')
    .format('YYYY');

  const years: string[] = [];

  for (let i = adjustedEndBuffer; years[years.length - 1] !== yearEnd; i += 1) {
    const yearN = dayjs().subtract(i, 'years');
    years.push(yearN.format('YYYY'));
  }

  return years;
}

export default getLastNYears;
