import * as Types from '../../generated/graphql';

import { gql } from '@apollo/client';
import { LeadFormStepFieldsFragmentDoc } from './leadFormStepFields.fragment.generated';
export type LeadFormFieldsFragment = { __typename?: 'leadForm', id: any, name: string, businessLogo?: string | null | undefined, layout?: string | null | undefined, backgroundColor?: string | null | undefined, formBackground?: string | null | undefined, font?: string | null | undefined, sideBannerImage?: string | null | undefined, surveyJSJson?: any | null | undefined, themeJSON?: any | null | undefined, headerCode?: string | null | undefined, workspaceId: any, lockedToServices?: any | null | undefined, lockedToLocations?: any | null | undefined, successMessage?: string | null | undefined, steps: Array<{ __typename?: 'leadFormStep', id: any, name?: string | null | undefined, order: number, title?: string | null | undefined, subtitle?: string | null | undefined, callToAction?: string | null | undefined, workspaceId: any, type: string, canDelete: boolean, canReOrder: boolean, properties?: any | null | undefined, fields: Array<{ __typename?: 'leadFormField', id: any, validationProps?: any | null | undefined, apiFieldKey: string, label: string, order: any, type: string, workspaceId: any }> }>, workspace: { __typename?: 'workspace', payrixMerchantId?: string | null | undefined, finixMerchantId?: string | null | undefined, name: string, organization?: { __typename?: 'organization', organizationConfiguration?: { __typename?: 'organizationConfiguration', lightLogoSmall: string, whitelabelingEnabled: boolean } | null | undefined } | null | undefined, workspaceConfiguration?: { __typename?: 'workspaceConfiguration', noShowActive?: boolean | null | undefined, noShowFee?: number | null | undefined, patientReferralOptions?: any | null | undefined } | null | undefined } };

export const LeadFormFieldsFragmentDoc = gql`
    fragment LeadFormFields on leadForm {
  id
  name
  businessLogo
  layout
  backgroundColor
  formBackground
  font
  sideBannerImage
  surveyJSJson
  themeJSON
  headerCode
  workspaceId
  lockedToServices
  lockedToLocations
  successMessage
  steps: leadFormSteps {
    ...LeadFormStepFields
  }
  workspace {
    payrixMerchantId
    finixMerchantId
    name
    organization {
      organizationConfiguration {
        lightLogoSmall
        whitelabelingEnabled
      }
    }
    workspaceConfiguration {
      noShowActive
      noShowFee
      patientReferralOptions
    }
  }
}
    ${LeadFormStepFieldsFragmentDoc}`;