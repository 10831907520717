import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { ServiceUnitFieldsFragmentDoc } from '../../fragments/serviceUnitFields.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetServiceUnitQueryVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
}>;


export type GetServiceUnitQuery = { __typename?: 'query_root', serviceUnit_by_pk?: { __typename?: 'serviceUnit', id: any, name: string } | null | undefined };


export const GetServiceUnitDocument = gql`
    query GetServiceUnit($id: uuid!) {
  serviceUnit_by_pk(id: $id) {
    ...ServiceUnitFields
  }
}
    ${ServiceUnitFieldsFragmentDoc}`;

/**
 * __useGetServiceUnitQuery__
 *
 * To run a query within a React component, call `useGetServiceUnitQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetServiceUnitQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetServiceUnitQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetServiceUnitQuery(baseOptions: Apollo.QueryHookOptions<GetServiceUnitQuery, GetServiceUnitQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetServiceUnitQuery, GetServiceUnitQueryVariables>(GetServiceUnitDocument, options);
      }
export function useGetServiceUnitLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetServiceUnitQuery, GetServiceUnitQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetServiceUnitQuery, GetServiceUnitQueryVariables>(GetServiceUnitDocument, options);
        }
export type GetServiceUnitQueryHookResult = ReturnType<typeof useGetServiceUnitQuery>;
export type GetServiceUnitLazyQueryHookResult = ReturnType<typeof useGetServiceUnitLazyQuery>;
export type GetServiceUnitQueryResult = Apollo.QueryResult<GetServiceUnitQuery, GetServiceUnitQueryVariables>;