import { SelectOption } from '@webapp/types';

export const processCustomFormValue = function processCustomFormValue(value: unknown): string {
  if (!value) {
    return '';
  }

  if (value instanceof Date) {
    return value.toISOString();
  }

  if ((value as SelectOption).value && (value as SelectOption).label) {
    return (value as SelectOption).value as string;
  }

  if (
    Array.isArray(value) &&
    value.length > 0 &&
    (value[0] as SelectOption).value &&
    (value[0] as SelectOption).label
  ) {
    return (value as SelectOption[]).map((v) => v.value).join(',');
  }

  if (Array.isArray(value)) {
    return value.join(',');
  }

  if (typeof value === 'string') {
    return value;
  }

  return JSON.stringify(value);
}

export default processCustomFormValue;
