import * as Types from '../../generated/graphql';

import { gql } from '@apollo/client';
import { CustomFormFieldFragmentFragmentDoc } from './customFormFieldFragment.fragment.generated';
export type CustomFormFieldsFragment = { __typename?: 'customForm', id: any, title: string, isRequired: boolean, allFieldsRequired: boolean, createdAt: any, recurrence?: string | null | undefined, language?: string | null | undefined, surveyJSJSON?: any | null | undefined, workspaceId: any, rows: Array<{ __typename?: 'customFormRow', id: any, index: number, replicable?: boolean | null | undefined, fields: Array<{ __typename?: 'customFormField', id: any, index: number, label: string, body?: string | null | undefined, category?: Types.CustomFormFieldCategory_Enum | null | undefined, type: Types.CustomFormFieldType_Enum, table?: string | null | undefined, attribute?: string | null | undefined, validation?: any | null | undefined, photoId?: any | null | undefined, row: { __typename?: 'customFormRow', id: any, index: number }, options: Array<{ __typename?: 'customFormOption', id: any, value: string }>, photo?: { __typename?: 'photo', file?: { __typename?: 'FilePayload', url: string } | null | undefined } | null | undefined }> }>, serviceCustomForms: Array<{ __typename?: 'serviceCustomForm', id: any, service: { __typename?: 'service', id: any, name: string } }> };

export const CustomFormFieldsFragmentDoc = gql`
    fragment CustomFormFields on customForm {
  id
  title
  isRequired
  allFieldsRequired
  createdAt
  recurrence
  language
  surveyJSJSON
  workspaceId
  rows {
    id
    index
    replicable
    fields {
      ...CustomFormFieldFragment
    }
  }
  serviceCustomForms {
    id
    service {
      id
      name
    }
  }
}
    ${CustomFormFieldFragmentFragmentDoc}`;