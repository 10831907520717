import { addMessages } from '@webapp/joi-validation';
import Joi from 'joi';

const UpdateDocumentFormValidationSchema = Joi.object({
  tags: Joi.array().items(
    Joi.object({
      label: Joi.string(),
      object: Joi.any(),
      value: Joi.string(),
    })
  ),
  title: addMessages(Joi.string().required(), 'Title'),
});

export default UpdateDocumentFormValidationSchema;
