import { SelectOption } from '@webapp/types';
import {
  ActionMeta,
  AsyncSelect,
  AsyncCreatableSelect,
  MultiValue,
  SingleValue,
} from 'chakra-react-select';
import { useState } from 'react';
import DEFAULT_CONTROL_STYLE from './styles/DefaultControlStyle';
import DEFAULT_INPUT_CONTAINER_STYLE from './styles/DefaultInputContainerStyle';
import { AsyncMultiSelectInputProps } from './types';
import isMultiValue from './util/isMultiValue';

function AsyncMultiSelectInput({
  isDisabled,
  loadOnMount = false,
  loadOptions: useOptions,
  loadOptionsProps = {},
  name,
  onBlur,
  onChange,
  placeholder,
  styles = {},
  value: valueFromProps,
  noOptionsMessage = 'No options available',
  ...props
}: Omit<AsyncMultiSelectInputProps, 'type'>) {
  const inputStyles = { ...DEFAULT_CONTROL_STYLE, ...styles.control };

  const containerStyles = {
    ...DEFAULT_INPUT_CONTAINER_STYLE,
    ...styles.inputContainer,
  };

  const value = !valueFromProps ? [] : valueFromProps;

  const [initialized, setInitialized] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [defaultOptions, setDefaultOptions] = useState<SelectOption<unknown>[]>(
    []
  );

  const { onCreateOption, search } = useOptions(loadOptionsProps);

  const loadDefaultOptions = async (inputValue = '') => {
    if (!isLoading && !initialized) {
      setIsLoading(true);
      const searchOptions = await search(inputValue);
      setDefaultOptions(searchOptions);
      setInitialized(true);
      setIsLoading(false);
    }
  };

  async function handleChange(
    newValue: SingleValue<SelectOption> | MultiValue<SelectOption> | null,
    actionMeta: ActionMeta<SelectOption>
  ) {
    switch (actionMeta.action) {
      case 'create-option': {
        if (actionMeta.option && onCreateOption) {
          const newOption = await onCreateOption(
            actionMeta.option.value as string
          );

          if (newOption && isMultiValue(newValue)) {
            onChange(Array.from([...value, newOption]));
          } else if (newOption) {
            onChange([newOption]);
          } else {
            onChange([]);
          }
        } else {
          // eslint-disable-next-line no-console
          console.error('Create select option handler not provided');
        }

        break;
      }

      case 'pop-value':
      case 'remove-value': {
        if (actionMeta.removedValue?.value) {
          const removedValue = actionMeta.removedValue.value;

          const newOptions = value.filter(
            (option) => option.value !== removedValue
          );

          onChange(newOptions);
        }
        break;
      }

      case 'clear': {
        onChange([]);
        break;
      }

      default: {
        if (isMultiValue(newValue)) {
          onChange(Array.from(newValue));
        } else if (newValue) {
          onChange([newValue]);
        } else {
          onChange([]);
        }
      }
    }
  }

  if (onCreateOption) {
    return (
      <AsyncCreatableSelect
        chakraStyles={{
          container: (provided) => ({ ...provided, ...containerStyles }),
          control: (provided) => ({ ...provided, ...inputStyles }),
        }}
        defaultOptions={!loadOnMount ? defaultOptions : true}
        isDisabled={isDisabled}
        isInvalid={Boolean(props.error)}
        isMulti
        loadOptions={search}
        name={name}
        onBlur={onBlur}
        onChange={handleChange}
        onFocus={() => loadDefaultOptions()}
        placeholder={placeholder}
        value={value}
        noOptionsMessage={() => noOptionsMessage}
        {...props}
      />
    );
  }

  return (
    <AsyncSelect
      chakraStyles={{
        container: (provided) => ({ ...provided, ...containerStyles }),
        control: (provided) => ({ ...provided, ...inputStyles }),
      }}
      defaultOptions={!loadOnMount ? defaultOptions : true}
      isDisabled={isDisabled}
      isInvalid={Boolean(props.error)}
      isMulti
      loadOptions={search}
      name={name}
      onBlur={onBlur}
      onChange={handleChange}
      onFocus={() => loadDefaultOptions()}
      placeholder={placeholder}
      value={value}
      noOptionsMessage={() => noOptionsMessage}
      {...props}
    />
  );
}

export default AsyncMultiSelectInput;
