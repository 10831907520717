import {
  Button,
  Flex,
  Grid,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Stack,
  ModalContent,
  Divider,
  Box,
} from '@chakra-ui/react';
import { FiUploadCloud } from 'react-icons/fi';
import { useNavigate, useParams } from 'react-router-dom';
import { CgFileDocument } from 'react-icons/cg';
import { useEffect, useState } from 'react';
import { DropAndUpload } from '@webapp/ui';
import { useUploadToS3 } from '@webapp/hooks';
import {
  ListAppointmentsDocument,
  StorageType,
  useInsertCustomFormResponseMutation,
} from '@webapp/graphql';
import toast from 'react-hot-toast';
import { useStores } from '@webapp/state-models';
import { CompletedSelectOptionAndDocumentId } from '@webapp/types';

interface FormModalProps {
  isOpen: boolean;
  form: { id: string; title: string } | null;
  appointmentId: string;
  patientIdFromProps?: string;
  onClose: () => void;
  update: (index: number, value: Partial<unknown>) => void;
  indexAndItem: {
    index: number;
    item: CompletedSelectOptionAndDocumentId;
  } | null;
}

export const FormModal = ({
  isOpen,
  onClose,
  form,
  appointmentId,
  indexAndItem,
  update,
  patientIdFromProps,
}: FormModalProps): JSX.Element => {
  const { patientId: patientIdFromParams } = useParams();

  const patientId = patientIdFromProps || patientIdFromParams;

  const { workspace } = useStores();

  const [selectedItem, setSelectedItem] = useState(0);
  const [progress, setProgress] = useState(0);
  const [filesToUpload, setFilesToUpload] = useState<File[]>([]);

  const { uploadToS3 } = useUploadToS3();

  const [insertCustomFormResponse, { data: insertResponseData }] =
    useInsertCustomFormResponseMutation();

  useEffect(() => {
    if (!insertResponseData) return;

    toast.success('Form response uploaded successfully');
    resetAndClose();
  }, [insertResponseData, filesToUpload]);

  const navigate = useNavigate();

  const navigateForm = (): void => {
    if (!form) return;
    navigate(
      `/patients/${patientId}/appointments/${appointmentId}/forms/${form.id}`
    );
  };

  const resetSelected = (): void => {
    setSelectedItem(0);
  };

  const resetAndClose = (): void => {
    resetSelected();
    onClose();
  };

  const onDrop = (acceptedFiles: File[]): void => {
    setFilesToUpload(acceptedFiles);
  };

  const refetchQueries = [
    {
      query: ListAppointmentsDocument,
      variables: {
        where: {
          patientId: {
            _eq: patientId,
          },
        },
      },
    },
  ];

  const uploadFileToS3 = async (): Promise<void> => {
    try {
      if (!filesToUpload.length) throw new Error('No files to upload');
      const file = filesToUpload[0];

      const filePath = await uploadToS3({
        fileType: file.type,
        fileContents: file,
        filePath: file.name,
        storageType: StorageType.Document,
        onProgress: setProgress,
        randomizeFileName: true,
      });

      await insertCustomFormResponse({
        variables: {
          customFormResponse: {
            customFormId: form?.id,
            patientId,
            appointmentId,
            document: {
              data: {
                title: file.name,
                filePath,
                patientId,
                workspaceId: workspace?.id,
              },
            },
          },
        },
        refetchQueries,
        onCompleted: (data) => {
          if (indexAndItem) {
            update(indexAndItem.index, {
              ...indexAndItem.item,
              documentId:
                data.insert_customFormResponse?.returning[0]?.document?.id,
              completed: true,
            });
          }
          resetAndClose();
        },
      });
    } catch (error) {
      toast.error((error as Error).message);
    } finally {
      setProgress(0);
    }
  };

  const items = [
    { label: 'Upload', icon: FiUploadCloud, selectItem: 1 },
    { label: 'Fill out form', icon: CgFileDocument, selectItem: 2 },
  ];

  const modalInner =
    selectedItem === 1 ? (
      <>
        <ModalBody>
          <DropAndUpload
            onDrop={onDrop}
            progress={progress}
            accept=".doc,.docx,.pdf"
          />
        </ModalBody>
        <Divider />
        <ModalFooter>
          <Flex width="100%" justifyContent="space-between">
            <Button onClick={resetSelected} marginRight="5">
              Back
            </Button>
            <Button
              variant="solid"
              colorScheme="teal"
              type="submit"
              onClick={uploadFileToS3}
            >
              Upload Document
            </Button>
          </Flex>
        </ModalFooter>
      </>
    ) : (
      <>
        <ModalBody>
          <Grid
            p={6}
            height="100%"
            width="100%"
            gridTemplateColumns="1fr 1fr"
            columnGap="50px"
            minH="250px"
          >
            {items.map(({ label, icon, selectItem }) => (
              <Stack
                key={`complete-form-option-${selectItem}`}
                w="264px"
                rounded="lg"
                p={6}
                shadow="base"
                onClick={() => setSelectedItem(selectItem)}
                border={
                  selectedItem === selectItem ? '2px solid #00758A' : undefined
                }
                color={selectedItem === selectItem ? '#00758A' : undefined}
                display="flex"
                justifyContent="center"
              >
                <Box display="flex" justifyContent="center">
                  <Icon
                    as={icon}
                    h="40px"
                    w="40px"
                    color={selectedItem === selectItem ? '#00758A' : 'gray.500'}
                  />
                </Box>
                <Text textAlign="center" fontWeight="bold">
                  {label}
                </Text>
              </Stack>
            ))}
          </Grid>
        </ModalBody>
        <Divider />
        <ModalFooter>
          <Flex width="100%" justifyContent="space-between">
            <Button onClick={resetAndClose} marginRight="5">
              Cancel
            </Button>
            <Button
              variant="solid"
              colorScheme="teal"
              type="submit"
              disabled={selectedItem === 0}
              onClick={navigateForm}
            >
              Continue
            </Button>
          </Flex>
        </ModalFooter>
      </>
    );

  return (
    <Modal isOpen={isOpen} onClose={resetAndClose}>
      <ModalOverlay />
      <ModalContent width="700px" maxWidth="unset">
        <ModalHeader>{form?.title}</ModalHeader>
        <ModalCloseButton />
        {modalInner}
      </ModalContent>
    </Modal>
  );
};

export default FormModal;
