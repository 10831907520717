import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { AppointmentTypeFieldsFragmentDoc } from '../../fragments/appointmentTypeFields.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type InsertAppointmentTypeMutationVariables = Types.Exact<{
  appointmentType: Types.AppointmentType_Insert_Input;
}>;


export type InsertAppointmentTypeMutation = { __typename?: 'mutation_root', insert_appointmentType?: { __typename?: 'appointmentType_mutation_response', returning: Array<{ __typename?: 'appointmentType', id: any, name: string, color?: string | null | undefined, maxPerDay?: number | null | undefined }> } | null | undefined };


export const InsertAppointmentTypeDocument = gql`
    mutation insertAppointmentType($appointmentType: appointmentType_insert_input!) {
  insert_appointmentType(objects: [$appointmentType]) {
    returning {
      ...AppointmentTypeFields
    }
  }
}
    ${AppointmentTypeFieldsFragmentDoc}`;
export type InsertAppointmentTypeMutationFn = Apollo.MutationFunction<InsertAppointmentTypeMutation, InsertAppointmentTypeMutationVariables>;

/**
 * __useInsertAppointmentTypeMutation__
 *
 * To run a mutation, you first call `useInsertAppointmentTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertAppointmentTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertAppointmentTypeMutation, { data, loading, error }] = useInsertAppointmentTypeMutation({
 *   variables: {
 *      appointmentType: // value for 'appointmentType'
 *   },
 * });
 */
export function useInsertAppointmentTypeMutation(baseOptions?: Apollo.MutationHookOptions<InsertAppointmentTypeMutation, InsertAppointmentTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertAppointmentTypeMutation, InsertAppointmentTypeMutationVariables>(InsertAppointmentTypeDocument, options);
      }
export type InsertAppointmentTypeMutationHookResult = ReturnType<typeof useInsertAppointmentTypeMutation>;
export type InsertAppointmentTypeMutationResult = Apollo.MutationResult<InsertAppointmentTypeMutation>;
export type InsertAppointmentTypeMutationOptions = Apollo.BaseMutationOptions<InsertAppointmentTypeMutation, InsertAppointmentTypeMutationVariables>;