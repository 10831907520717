import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { PatientConversationFieldsFragmentDoc } from '../../fragments/patientConversationFields.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetPatientConversationQueryVariables = Types.Exact<{
  where: Types.PatientConversation_Bool_Exp;
}>;


export type GetPatientConversationQuery = { __typename?: 'query_root', patientConversation: Array<{ __typename?: 'patientConversation', id: any, category?: string | null | undefined, patientId: any, patientWorkspaceId: any, lastPatientMessageReceived?: any | null | undefined, lastUserMessageSent?: any | null | undefined, hasUnreadMessages?: boolean | null | undefined, twilioConversationSid: string, participantIdentityOverride?: string | null | undefined, patient: { __typename?: 'patientWorkspace', firstName?: string | null | undefined, lastName?: string | null | undefined, id: any }, patientWorkspace: { __typename?: 'patientWorkspace', id: any, firstName?: string | null | undefined, lastName?: string | null | undefined, phoneNumber?: string | null | undefined } }> };


export const GetPatientConversationDocument = gql`
    query GetPatientConversation($where: patientConversation_bool_exp!) {
  patientConversation(where: $where) {
    ...PatientConversationFields
    patient: patientWorkspace {
      id: patientId
      firstName
      lastName
    }
  }
}
    ${PatientConversationFieldsFragmentDoc}`;

/**
 * __useGetPatientConversationQuery__
 *
 * To run a query within a React component, call `useGetPatientConversationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPatientConversationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPatientConversationQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetPatientConversationQuery(baseOptions: Apollo.QueryHookOptions<GetPatientConversationQuery, GetPatientConversationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPatientConversationQuery, GetPatientConversationQueryVariables>(GetPatientConversationDocument, options);
      }
export function useGetPatientConversationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPatientConversationQuery, GetPatientConversationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPatientConversationQuery, GetPatientConversationQueryVariables>(GetPatientConversationDocument, options);
        }
export type GetPatientConversationQueryHookResult = ReturnType<typeof useGetPatientConversationQuery>;
export type GetPatientConversationLazyQueryHookResult = ReturnType<typeof useGetPatientConversationLazyQuery>;
export type GetPatientConversationQueryResult = Apollo.QueryResult<GetPatientConversationQuery, GetPatientConversationQueryVariables>;