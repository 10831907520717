const reorderServicesInCategory = (services, orderIds) => {
  const reorderedServices = [];

  // Add services in the specified order
  if (orderIds) {
    orderIds.forEach((serviceId) => {
      const service = services.find((s) => s.id === serviceId);
      if (service) {
        reorderedServices.push(service);
      }
    });
  }

  // Add any remaining services not in the order
  services.forEach((service) => {
    if (!reorderedServices.includes(service)) {
      reorderedServices.push(service);
    }
  });

  return reorderedServices;
};

export const reorderServices = (data, categoryOrder, serviceOrders) => {
  const reorderedData = [];
  const originalCategories = new Set(
    data.serviceCategory.map((item) => item.title)
  );

  // Process categories in the specified order
  categoryOrder?.forEach((category) => {
    if (category === 'Uncategorized') {
      // Handle uncategorized services
      if (data.uncategorizedServices && data.uncategorizedServices.length > 0) {
        const uncategorizedCategory = {
          title: 'Uncategorized',
          services: reorderServicesInCategory(
            data.uncategorizedServices,
            serviceOrders.Uncategorized
          ),
        };
        reorderedData.push(uncategorizedCategory);
      }
    } else if (originalCategories.has(category)) {
      const categoryData = data.serviceCategory.find(
        (item) => item.title === category
      );
      const reorderedCategory = {
        title: category,
        services: reorderServicesInCategory(
          categoryData.services,
          serviceOrders[category]
        ),
      };
      reorderedData.push(reorderedCategory);
    }
  });

  // Add any remaining categories not in the categoryOrder
  data.serviceCategory.forEach((item) => {
    if (!categoryOrder?.includes(item.title) && item.services.length > 0) {
      reorderedData.push(item);
    }
  });

  return reorderedData;
};

export const filterServicesWithBookingRules = (
  categoryServices,
  patientStatus,
  previousServiceIds
) =>
  categoryServices
    .map((category) => {
      const filteredServices = category.services.filter((service) => {
        let isValid = true;
        if (patientStatus === 'FIRED') {
          return false;
        }
        if (
          service.onlineBookingRules &&
          (service.onlineBookingRules.patientStatus ||
            service.onlineBookingRules.hasHadServices)
        ) {
          if (
            service.onlineBookingRules.patientStatus &&
            service.onlineBookingRules.patientStatus.length > 0
          ) {
            if (patientStatus) {
              isValid = service.onlineBookingRules.patientStatus
                .map((status) => status.value)
                .includes(patientStatus);
            }
          }

          if (
            service.onlineBookingRules.hasHadServices &&
            service.onlineBookingRules.hasHadServices.length > 0 &&
            previousServiceIds &&
            previousServiceIds.length > 0
          ) {
            isValid = service.onlineBookingRules.hasHadServices.some((s) =>
              previousServiceIds.includes(s.value)
            );
          } else if (
            service.onlineBookingRules.hasHadServices &&
            service.onlineBookingRules.hasHadServices.length > 0 &&
            previousServiceIds &&
            previousServiceIds.length === 0
          ) {
            isValid = false;
          }
        }
        return isValid;
      });

      // Return a new category object with filtered services
      return {
        ...category,
        services: filteredServices,
      };
    })
    .filter((category) => category.services.length > 0); // Remove categories with no services after filtering

export const flattenServices = (categoryServices) =>
  categoryServices.reduce((acc, category) => acc.concat(category.services), []);
