/* eslint-disable camelcase */
import {
  GetPatientWalletTransactionsQuery,
  Order_By,
  PatientWalletTransaction_Bool_Exp,
  useGetPatientWalletTransactionsAggregateLazyQuery,
  useGetPatientWalletTransactionsLazyQuery,
} from '@webapp/graphql';
import { orderStringToOrderByArray } from '@webapp/webapp/util-url';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export interface UseFilterPatientWalletTransactionsOutput {
  patientWalletTransactions: GetPatientWalletTransactionsQuery['patientWalletTransaction'];
  count: number;
  refetch: () => void;
}

export const useFilterPatientWalletTransactions = (
  patientWalletId?: string
) => {
  const location = useLocation();
  const searchParameters = new URLSearchParams(location.search);
  const pageNumber = parseInt(searchParameters.get('page') ?? '1', 10);
  const pageSize = parseInt(searchParameters.get('pageSize') ?? '10', 10);
  const orderParams = searchParameters.get('order') ?? '';

  const [getPatientWalletTransactions, { data, refetch }] =
    useGetPatientWalletTransactionsLazyQuery();
  const [
    getPatientWalletTransactionsAggregate,
    { data: aggregateData, refetch: aggregateRefetch },
  ] = useGetPatientWalletTransactionsAggregateLazyQuery();

  useEffect(() => {
    const where: PatientWalletTransaction_Bool_Exp = {
      ...(patientWalletId && { patientWalletId: { _eq: patientWalletId } }),
    };

    getPatientWalletTransactions({
      variables: {
        where,
        limit: pageSize,
        offset: (pageNumber - 1) * pageSize,
        ...(orderParams && { orderBy: orderStringToOrderByArray(orderParams) }),
        ...(!orderParams && { orderBy: { createdAt: Order_By.Desc } }),
      },
    });

    getPatientWalletTransactionsAggregate({
      variables: {
        where,
      },
    });
  }, [
    getPatientWalletTransactions,
    getPatientWalletTransactionsAggregate,
    orderParams,
    pageNumber,
    pageSize,
    patientWalletId,
  ]);

  return {
    patientWalletTransactions: data?.patientWalletTransaction ?? [],
    count:
      aggregateData?.patientWalletTransaction_aggregate.aggregate?.count ?? 0,
    refetch: () => {
      refetch();
      aggregateRefetch();
    },
  };
};

export default useFilterPatientWalletTransactions;
