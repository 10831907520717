import { Box, Button } from '@chakra-ui/react';
import { Device } from '@webapp/state-models';
import { observer } from 'mobx-react-lite';
import AvailabilityTemplate from '../AvailabilityTemplate/AvailabilityTemplate';
import './device-availability.module.scss';

/* eslint-disable-next-line */
export interface DeviceAvailabilityProps {
  device: Device;
}

export const DeviceAvailability = observer(
  ({ device }: DeviceAvailabilityProps) => (
    <Box>
      {device.schedule ? (
        <AvailabilityTemplate
          defaultSchedule={true}
          onChange={(schedule) => device.updateAvailability(schedule)}
          schedule={JSON.parse(JSON.stringify(device.schedule))}
        />
      ) : (
        <Button onClick={() => device.createAvailability()}>
          Add availability
        </Button>
      )}
    </Box>
  )
);

export default DeviceAvailability;
