import { Button, FormLabel, Grid, Text } from '@chakra-ui/react';
import without from 'lodash/without';
import values from 'lodash/values';
import Async from 'react-select/async';
import {
  SelectOption,
  FormInputProps,
  MultiSelectFormInputProps,
} from '@webapp/types';
import AsyncCreatableSelect from 'react-select/async-creatable';
import Select, { ActionMeta, MultiValue, SingleValue } from 'react-select';
import { Controller, useFormContext } from 'react-hook-form';
import { useEffect, useMemo, useState } from 'react';
import { ErrorMessage } from '@hookform/error-message';
import { HiPlus } from 'react-icons/hi';
import { DEFAULT_LABEL_STYLE } from './constants';

export default function MultiSelectFormInput({
  canCreate = false,
  showCreateOption = false,
  staticOptions = [],
  defaultOptions = [],
  containerGridTemplate = '100px 1fr',
  id,
  async = true,
  isEditable = true,
  label,
  labelAlign,
  labelPosition,
  labelStyle = DEFAULT_LABEL_STYLE,
  loadOptions = () => ({
    search: async () => [],
  }),
  name,
  placeholder,
  shouldSelectFullOption = false,
}: Omit<FormInputProps, 'multiSelectProps'> & MultiSelectFormInputProps) {
  const {
    control,
    formState: { errors },
    getValues,
    register,
    setValue,
  } = useFormContext();
  const defaultOptionsMap = useMemo(
    () =>
      values(defaultOptions).reduce(
        (acc, option) => ({
          ...acc,
          [option.value]: option,
        }),
        {}
      ),
    []
  );
  const [selectedOptions, selectOption] = useState<{
    [key: string]: SelectOption;
  }>(defaultOptionsMap ?? {});
  useEffect(() => {
    selectOption(defaultOptionsMap);
  }, [defaultOptionsMap]);
  const watchedValues = useMemo(
    () => values(selectedOptions),
    [selectedOptions]
  );
  useEffect(() => {
    if (shouldSelectFullOption) {
      setValue(name, watchedValues);
    } else {
      setValue(
        name,
        watchedValues.map((option) => option.value)
      );
    }
  }, [name, setValue, shouldSelectFullOption, watchedValues]);
  const { onCreateOption, search } = loadOptions();
  useEffect(() => {
    register(name);
  });
  const containerColumns = useMemo(() => {
    if (label) {
      if (labelPosition === 'left') {
        return containerGridTemplate;
      }
    }
    return '1fr';
  }, [label, labelPosition]);
  const handleChange = async (
    _: MultiValue<SelectOption> | SingleValue<SelectOption>,
    actionMeta: ActionMeta<SelectOption>
  ) => {
    const currentValue = getValues(name) ?? [];
    switch (actionMeta.action) {
      case 'create-option': {
        if (onCreateOption) {
          const newOption = await onCreateOption(actionMeta.option.label);
          if (newOption) {
            selectOption({
              ...selectedOptions,
              [newOption.value]: newOption,
            });
            setValue(name, [...currentValue, newOption.value]);
          }
        } else {
          // eslint-disable-next-line no-console
          console.error('Create select option handler not provided');
        }
        break;
      }
      case 'pop-value':
      case 'remove-value': {
        if (actionMeta.removedValue?.value) {
          const newState = { ...selectedOptions };
          delete newState[actionMeta.removedValue.value];
          selectOption(newState);
          setValue(name, without(currentValue, actionMeta.removedValue.value));
        }
        break;
      }
      case 'clear': {
        selectOption({});
        setValue(name, []);
        break;
      }
      default: {
        if (actionMeta.option) {
          selectOption({
            ...selectedOptions,
            [actionMeta.option.value]: actionMeta.option,
          });
          setValue(name, [...currentValue, actionMeta.option.value]);
        }
      }
    }
  };
  return (
    <Grid
      data-test-id={`multi-select-${name}`}
      alignItems={labelAlign}
      className="input-outer"
      gridAutoRows="max-content"
      gridRowGap="6px"
      gridTemplateColumns={containerColumns}
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
      }}
    >
      <Grid gridTemplateColumns="1fr max-content">
        <FormLabel
          id={`multi-select-${name}`}
          className="input-label"
          htmlFor={id ?? name}
          margin="0 0 0 0"
          {...labelStyle}
        >
          {label}
        </FormLabel>
        {showCreateOption && (
          <Button
            colorScheme="teal"
            height="20px"
            minWidth="unset"
            padding="0"
            variant="ghost"
            width="20px"
          >
            <HiPlus />
          </Button>
        )}
      </Grid>
      <Grid id={id} className="input-inner" gridRowGap="8px">
        <Controller
          control={control}
          name={name}
          render={({ field: { onBlur, ref, value } }) => {
            if (!async) {
              if (!isEditable) {
                return <Text>{value?.join(', ')}</Text>;
              }
              return (
                <Select
                  defaultValue={value}
                  instanceId={id}
                  value={watchedValues}
                  isMulti
                  name={name}
                  styles={{
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    container: (provided) => ({
                      ...provided,
                      background: 'transparent',
                    }),
                    control: (provided) => ({
                      ...provided,
                      background: 'transparent',
                    }),
                  }}
                  onBlur={onBlur}
                  onChange={handleChange}
                  placeholder={placeholder}
                  options={staticOptions}
                  ref={ref}
                  isClearable={false}
                />
              );
            }
            if (canCreate) {
              return (
                <AsyncCreatableSelect
                  styles={{
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  }}
                  isDisabled={isEditable === false}
                  defaultOptions
                  isClearable={false}
                  isMulti={true}
                  loadOptions={search}
                  menuShouldScrollIntoView
                  name={name}
                  onBlur={onBlur}
                  onChange={handleChange}
                  placeholder={placeholder}
                  ref={ref}
                  value={watchedValues}
                />
              );
            }
            return (
              <Async
                styles={{
                  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                }}
                isDisabled={isEditable === false}
                defaultOptions
                isMulti={true}
                loadOptions={search}
                name={name}
                onBlur={onBlur}
                onChange={handleChange}
                ref={ref}
                value={watchedValues}
              />
            );
          }}
        />
        <ErrorMessage
          errors={errors}
          name={name}
          render={({ message }) => (
            <Text
              className="input-error"
              color="red.500"
              _before={{
                display: 'inline',
                content: '"⚠ "',
              }}
            >
              {message}
            </Text>
          )}
        />
      </Grid>
    </Grid>
  );
}
