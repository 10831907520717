import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { ServiceUnitFieldsFragmentDoc } from '../../fragments/serviceUnitFields.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetServiceUnitsQueryVariables = Types.Exact<{
  where?: Types.Maybe<Types.ServiceUnit_Bool_Exp>;
  limit?: Types.Maybe<Types.Scalars['Int']>;
  offset?: Types.Maybe<Types.Scalars['Int']>;
  orderBy?: Types.Maybe<Array<Types.ServiceUnit_Order_By> | Types.ServiceUnit_Order_By>;
}>;


export type GetServiceUnitsQuery = { __typename?: 'query_root', serviceUnit: Array<{ __typename?: 'serviceUnit', id: any, name: string }> };


export const GetServiceUnitsDocument = gql`
    query GetServiceUnits($where: serviceUnit_bool_exp, $limit: Int, $offset: Int, $orderBy: [serviceUnit_order_by!]) {
  serviceUnit(where: $where, limit: $limit, offset: $offset, order_by: $orderBy) {
    ...ServiceUnitFields
  }
}
    ${ServiceUnitFieldsFragmentDoc}`;

/**
 * __useGetServiceUnitsQuery__
 *
 * To run a query within a React component, call `useGetServiceUnitsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetServiceUnitsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetServiceUnitsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetServiceUnitsQuery(baseOptions?: Apollo.QueryHookOptions<GetServiceUnitsQuery, GetServiceUnitsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetServiceUnitsQuery, GetServiceUnitsQueryVariables>(GetServiceUnitsDocument, options);
      }
export function useGetServiceUnitsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetServiceUnitsQuery, GetServiceUnitsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetServiceUnitsQuery, GetServiceUnitsQueryVariables>(GetServiceUnitsDocument, options);
        }
export type GetServiceUnitsQueryHookResult = ReturnType<typeof useGetServiceUnitsQuery>;
export type GetServiceUnitsLazyQueryHookResult = ReturnType<typeof useGetServiceUnitsLazyQuery>;
export type GetServiceUnitsQueryResult = Apollo.QueryResult<GetServiceUnitsQuery, GetServiceUnitsQueryVariables>;