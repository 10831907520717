/* eslint-disable camelcase */
import {
  Stack,
  Text,
  Button,
  Heading,
  Flex,
  HStack,
  Spacer,
  Tag,
  useUpdateEffect,
} from '@chakra-ui/react';
import {
  Order_By,
  PatientWalletTransaction_Bool_Exp,
  useGetPatientWalletTransactionsAggregateQuery,
  useGetPatientWalletTransactionsQuery,
} from '@webapp/graphql';
import { MinimalPatientWallet } from '@webapp/types';
import { Step, Steps, TruncatedCell } from '@webapp/ui';
import { useInfiniteScroll } from '@webapp/webapp/hooks';
import dayjs from 'dayjs';
import { PatientWalletTransactionFieldsFragment } from 'libs/graphql/src/fragments/patientWalletTransactionFields.fragment.generated';
import { groupBy } from 'lodash';
import React from 'react';

interface PatientWalletLedgerLogProps {
  patientWallet: MinimalPatientWallet;
}

export const PatientWalletLedgerLog = ({
  patientWallet,
}: PatientWalletLedgerLogProps): JSX.Element => {
  const where: PatientWalletTransaction_Bool_Exp = {
    patientWalletId: {
      _eq: patientWallet.id,
    },
  };

  const { data: aggregateData, refetch: refetchAggregate } =
    useGetPatientWalletTransactionsAggregateQuery({
      fetchPolicy: 'network-only',
      variables: {
        where,
      },
    });

  const count =
    aggregateData?.patientWalletTransaction_aggregate.aggregate?.count;

  const { hasMore, items, page, nextPage, receiveItems } =
    useInfiniteScroll<PatientWalletTransactionFieldsFragment>({
      count,
      queryString: JSON.stringify(where),
    });

  const PAGE_SIZE = 10;

  const { loading, refetch } = useGetPatientWalletTransactionsQuery({
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const logs = data?.patientWalletTransaction ?? [];
      receiveItems(logs);
    },
    variables: {
      orderBy: [
        {
          createdAt: Order_By.Desc,
        },
      ],
      limit: PAGE_SIZE,
      offset: PAGE_SIZE * page,
      where,
    },
  });

  const refetchBoth = (): void => {
    refetch();
    refetchAggregate();
  };

  const logsGroupedByDay = groupBy(items, (x) =>
    dayjs(x.createdAt).format('MM/DD/YYYY')
  );

  useUpdateEffect(() => {
    refetchBoth();
  }, [patientWallet.balance]);

  return (
    <Stack
      alignItems="start"
      height="100%"
      w={'full'}
      p={2}
      maxW={{ base: '100vw', md: 'calc(100vw - 135px)' }}
    >
      <Stack
        alignItems="start"
        height="100%"
        w="full"
        maxW="calc(100vw - 135px)"
      >
        <Steps>
          {Object.keys(logsGroupedByDay)
            .sort((x, y) => {
              const dayX = dayjs(x, 'MM/DD/YYYY');
              const dayY = dayjs(y, 'MM/DD/YYYY');

              if (dayX.isBefore(dayY)) {
                return 1;
              }

              if (dayX.isAfter(dayY)) {
                return -1;
              }

              return 0;
            })
            .map((dateKey) => (
              <React.Fragment key={`activity-step-${dateKey}`}>
                <Heading
                  color="#919197"
                  letterSpacing="0.09em"
                  margin="0 0 0 28px"
                  size="14px"
                  textTransform="uppercase"
                >
                  {dayjs(dateKey, 'MM/DD/YYYY').isSame(dayjs(), 'day')
                    ? 'Today'
                    : dateKey}
                </Heading>
                {logsGroupedByDay[dateKey].map((log) => (
                  <HStack w={'full'}>
                    <Step
                      headingContent={({
                        onClick,
                      }: {
                        onClick: () => void;
                      }) => (
                        <Flex
                          alignItems={{ base: 'start', md: 'center' }}
                          onClick={onClick}
                          direction={{ base: 'column', md: 'row' }}
                        >
                          <Heading
                            color="#525257"
                            cursor="pointer"
                            fontSize="16px"
                            fontWeight="700"
                            letterSpacing="0.01em"
                            userSelect="none"
                          >
                            change $
                            {(log.amount / 100).toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                            })}{' '}
                            from $
                            {(log.totalBefore / 100).toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                            })}{' '}
                            to $
                            {(log.totalAfter / 100).toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                            })}{' '}
                          </Heading>
                          <Spacer w={2} />
                          <Text
                            color="#525257"
                            cursor="pointer"
                            fontSize="16px"
                            fontWeight="400"
                            letterSpacing="0.01em"
                            userSelect="none"
                          >
                            from {log.source.name}
                          </Text>
                          <Spacer w={2} />
                          <TruncatedCell
                            value={`reason: ${log.note}`}
                            link={
                              log.invoiceId
                                ? `/invoices/${log.invoiceId}`
                                : undefined
                            }
                          />
                        </Flex>
                      )}
                    ></Step>
                    <Tag
                      minW={'80px'}
                      bg={'white'}
                      color="#6C6C72"
                      fontSize="14px"
                      letterSpacing="0.03em"
                    >
                      {dayjs(log.createdAt).format('h:mm a')}
                    </Tag>
                  </HStack>
                ))}
              </React.Fragment>
            ))}
          {hasMore && (
            <Button
              alignSelf="center"
              justifySelf="center"
              isLoading={loading}
              onClick={nextPage}
              width="max-content"
            >
              Load More
            </Button>
          )}
          {!hasMore && (
            <Text
              alignSelf="center"
              fontSize="16px"
              fontWeight="bold"
              justifySelf="center"
              onClick={nextPage}
              width="max-content"
            >
              You've reached the end of the list!
            </Text>
          )}
        </Steps>
      </Stack>
    </Stack>
  );
};

export default PatientWalletLedgerLog;
