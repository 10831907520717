import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetPatientProductHistoryQueryVariables = Types.Exact<{
  patientId: Types.Scalars['uuid'];
}>;


export type GetPatientProductHistoryQuery = { __typename?: 'query_root', getPatientProductHistory?: { __typename?: 'PatientProductHistoryOutput', productHistory?: Array<{ __typename?: 'ProductHistory', lastPurchased?: string | null | undefined, name?: string | null | undefined, totalQuantity?: number | null | undefined } | null | undefined> | null | undefined } | null | undefined };


export const GetPatientProductHistoryDocument = gql`
    query getPatientProductHistory($patientId: uuid!) {
  getPatientProductHistory(patientId: $patientId) {
    productHistory {
      lastPurchased
      name
      totalQuantity
    }
  }
}
    `;

/**
 * __useGetPatientProductHistoryQuery__
 *
 * To run a query within a React component, call `useGetPatientProductHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPatientProductHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPatientProductHistoryQuery({
 *   variables: {
 *      patientId: // value for 'patientId'
 *   },
 * });
 */
export function useGetPatientProductHistoryQuery(baseOptions: Apollo.QueryHookOptions<GetPatientProductHistoryQuery, GetPatientProductHistoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPatientProductHistoryQuery, GetPatientProductHistoryQueryVariables>(GetPatientProductHistoryDocument, options);
      }
export function useGetPatientProductHistoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPatientProductHistoryQuery, GetPatientProductHistoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPatientProductHistoryQuery, GetPatientProductHistoryQueryVariables>(GetPatientProductHistoryDocument, options);
        }
export type GetPatientProductHistoryQueryHookResult = ReturnType<typeof useGetPatientProductHistoryQuery>;
export type GetPatientProductHistoryLazyQueryHookResult = ReturnType<typeof useGetPatientProductHistoryLazyQuery>;
export type GetPatientProductHistoryQueryResult = Apollo.QueryResult<GetPatientProductHistoryQuery, GetPatientProductHistoryQueryVariables>;