import { chakra, Button, Text } from '@chakra-ui/react';
import GratuityModalAmountButton from './GratuityModalAmountButton';
import { GratuityModalOption } from './types';

interface DefaultGratuityModalInterfaceProps {
  colorScheme?: string;
  gratuity: number;
  onChange(value: number): void;
  onEditClick(): void;
  options: (GratuityModalOption & { selected: boolean })[];
  totalNumber: number;
}

function DefaultGratuityModalInterface({
  colorScheme,
  gratuity,
  onChange,
  onEditClick,
  options,
  totalNumber,
}: DefaultGratuityModalInterfaceProps) {
  return (
    <chakra.div width="100%">
      <chakra.div
        borderColor="teal.500"
        borderRadius="4px"
        borderStyle="solid"
        margin="25px auto 0"
        maxWidth="800px"
        padding={{ base: 0, md: '45px' }}
      >
        <Text
          fontSize="2xl"
          fontWeight="bold"
          opacity=".5"
          padding="0 0 25px"
          textAlign="center"
        >
          Add a Tip?
        </Text>
        <chakra.div
          display="grid"
          gridAutoFlow="row"
          gridRowGap="15px"
          margin="0 auto"
          maxWidth="800px"
        >
          <chakra.div
            display="grid"
            gridColumnGap="15px"
            gridAutoRows="80px"
            gridRowGap="15px"
            gridTemplateColumns={{ base: '1fr', md: 'repeat(4, 1fr)' }}
          >
            {options.map((gratuityOption) => (
              <GratuityModalAmountButton
                amount={gratuityOption.amount}
                colorScheme={colorScheme}
                onChange={onChange}
                key={`gratuity-modal-option-${gratuityOption.amount}`}
                selected={gratuityOption.selected}
                total={totalNumber}
              />
            ))}
            <Button
              colorScheme={gratuity === 0 ? colorScheme : 'gray'}
              fontSize="lg"
              height={{ base: '80px', md: '100%' }}
              onClick={() => {
                onChange(0);
              }}
            >
              No Tip
            </Button>
          </chakra.div>
          <Button
            colorScheme="gray"
            fontSize="lg"
            height={{ base: '80px', md: '60px' }}
            onClick={onEditClick}
          >
            Custom Tip
          </Button>
        </chakra.div>
      </chakra.div>
    </chakra.div>
  );
}

export default DefaultGratuityModalInterface;
