import * as Types from '../../generated/graphql';

import { gql } from '@apollo/client';
import { SocialMediaAccountFieldsFragmentDoc } from './socialMediaAccountFields.fragment.generated';
import { SocialMediaPostFieldsFragmentDoc } from './socialMediaPostFields.fragment.generated';
export type SocialMediaAccountPostFieldsFragment = { __typename?: 'socialMediaAccountPost', id: any, likeCount?: number | null | undefined, commentCount?: number | null | undefined, socialMediaAccount: { __typename?: 'socialMediaAccount', id: any, name: string, platform: Types.SocialMediaPlatform_Enum, profilePicture?: string | null | undefined }, socialMediaPost: { __typename?: 'socialMediaPost', id: any, title: string, caption?: string | null | undefined, isScheduled: boolean, intendedPostAt: any, privatePhoto?: { __typename?: 'photo', id: any, workspaceId: any, patientId?: any | null | undefined, mediaDate?: any | null | undefined, mediaType?: string | null | undefined, mimeType?: string | null | undefined, hasThumbnail?: boolean | null | undefined, isSharedWithPatient: boolean, partialMediaConsent?: boolean | null | undefined, fullMediaConsent?: boolean | null | undefined, patient?: { __typename?: 'patient', firstName: string, id: any, lastName: string } | null | undefined, file?: { __typename?: 'FilePayload', filePath: string, url: string, thumbnailUrl?: string | null | undefined } | null | undefined, photoMediaTags: Array<{ __typename?: 'photoMediaTag', id: any, mediaTag: { __typename?: 'mediaTag', id: any, title: string } }>, uploader?: { __typename?: 'user', firstName: string, id: any, lastName: string } | null | undefined } | null | undefined, socialMediaAccountPosts: Array<{ __typename?: 'socialMediaAccountPost', status: Types.PostStatus_Enum, likeCount?: number | null | undefined, commentCount?: number | null | undefined, socialMediaAccount: { __typename?: 'socialMediaAccount', id: any, name: string, profilePicture?: string | null | undefined, platform: Types.SocialMediaPlatform_Enum } }> } };

export const SocialMediaAccountPostFieldsFragmentDoc = gql`
    fragment SocialMediaAccountPostFields on socialMediaAccountPost {
  id
  socialMediaAccount {
    ...SocialMediaAccountFields
  }
  socialMediaPost {
    ...SocialMediaPostFields
  }
  likeCount
  commentCount
}
    ${SocialMediaAccountFieldsFragmentDoc}
${SocialMediaPostFieldsFragmentDoc}`;