/* eslint-disable no-nested-ternary */
/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
import './external-booking.module.css';
import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  IconButton,
  SimpleGrid,
  Spinner,
  Stack,
  Text,
  VStack,
  useBreakpointValue,
} from '@chakra-ui/react';
import { useFlagsmith } from 'flagsmith-react';
import _, { groupBy, orderBy } from 'lodash';
import {
  ProviderFieldsFragment,
  useGetAppointmentServiceAvailabilityLazyQuery,
  useGetAvailabilityForMultipleServicesLazyQuery,
  useGetExternalBookingWorkspaceConfigurationQuery,
  useGetLocationsExternalQuery,
  useGetProvidersExternalLazyQuery,
  useGetServicesByCategoryLazyQuery,
} from '@webapp/graphql';
import { FormInput, FormInputV2, DEFAULT_LABEL_STYLE } from '@webapp/ui';
import { useLoadLocations } from '@webapp/webapp/hooks';
import { configuredDayjs as dayjs } from '@webapp/util-time';
import { Dayjs } from 'dayjs';
import { useCallback, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { AiOutlineClockCircle } from 'react-icons/ai';
import { FaArrowLeft, FaArrowRight, FaEdit, FaTrash } from 'react-icons/fa';
import { datadogRum } from '@datadog/browser-rum';
import {
  filterServicesWithBookingRules,
  flattenServices,
  reorderServices,
} from '../util';

interface AvailabilityGroup {
  [key: string]: {
    dateTime: string;
    formattedDay: string;
    provider: ProviderFieldsFragment;
    start: string;
  };
}

interface AvailabilityGroups {
  [key: string]: AvailabilityGroup[];
}

/* eslint-disable-next-line */
export interface ExternalBookingProps {
  workspaceId: string;
  hasLeadFormLink?: boolean;
  presetServiceIds?: string[];
  lockedToLocationIds?: string[];
  lockedToServiceIds?: string[];
  serviceOrderRanking?: any;
  onSelectedLocation?: (location: { payrixMerchantId?: string }) => void;
  patientStatus?: string;
  previousServiceIds?: string[];
  allowProviderSelection?: boolean;
}

export const ExternalBooking = ({
  workspaceId,
  hasLeadFormLink = false,
  presetServiceIds,
  lockedToLocationIds,
  lockedToServiceIds,
  onSelectedLocation,
  serviceOrderRanking,
  patientStatus,
  previousServiceIds,
  allowProviderSelection = true,
}: ExternalBookingProps) => {
  const { hasFeature } = useFlagsmith();
  const hasAddOns = hasFeature('appointment:add-ons');
  const [startTime, setStartTime] = useState<string | null>(null);
  const [reorderedCategoryServices, setReorderedCategoryServices] = useState<
    any[]
  >([]);
  const isMobile = useBreakpointValue({ base: true, md: false });
  const { categoryOrder, serviceOrders } = serviceOrderRanking || {
    categoryOrder: [],
    serviceOrders: [],
  };
  const [selectedAddOns, setSelectedAddOns] = useState<string[]>([]);
  const [requiredAddOns, setRequiredAddOns] = useState<any[]>([]);
  const [optionalAddOns, setOptionalAddOns] = useState<any[]>([]);
  const [flattenedServices, setFlattenedServices] = useState<any[]>([]);
  const [orderedServiceIds, setOrderedServiceIds] = useState<any[]>([]);
  const [addOnAvailabilityLoaded, setAddOnAvailabilityLoaded] =
    useState<boolean>(false);

  const DAYS_TO_FETCH = 3;
  const MAX_DAYS = 30;

  // TODO Will need to update query when service category order is merged
  const { data } = useGetExternalBookingWorkspaceConfigurationQuery({
    variables: {
      workspaceId,
    },
  });

  const workspaceConfiguration = data?.workspaceConfiguration[0];
  const noShowActive = workspaceConfiguration?.noShowActive;
  const noShowFee = workspaceConfiguration?.noShowFee;

  const methods = useFormContext();
  const { setValue, watch } = methods;
  const {
    coreServiceIds,
    location,
    providerId,
    availabileProviders,
    serviceIds,
    preferredDate,
    dateTime,
    email,
  } = methods.watch();

  const [currentStartDate, setCurrentStartDate] = useState<Dayjs>(
    dayjs(preferredDate)
  );

  const updateOrderedServiceIds = useCallback(() => {
    const beforeServices: string[] = [];
    const afterServices: string[] = [];
    const mainServices = [...(coreServiceIds || [])];

    if (hasAddOns) {
      // Add required add-ons
      requiredAddOns.forEach((addOn) => {
        if (addOn.bookingOrder === -1) {
          beforeServices.push(addOn.serviceAddOn.id);
        } else if (addOn.bookingOrder === 1) {
          afterServices.push(addOn.serviceAddOn.id);
        } else {
          mainServices.push(addOn.serviceAddOn.id);
        }
      });

      // Add selected optional add-ons
      optionalAddOns.forEach((addOn) => {
        if (selectedAddOns.includes(addOn.serviceAddOn.id)) {
          if (addOn.bookingOrder === -1) {
            beforeServices.push(addOn.serviceAddOn.id);
          } else if (addOn.bookingOrder === 1) {
            afterServices.push(addOn.serviceAddOn.id);
          } else {
            mainServices.push(addOn.serviceAddOn.id);
          }
        }
      });
    }

    const orderedIds = [
      ...beforeServices,
      ...mainServices,
      ...afterServices,
    ].filter(Boolean);
    setOrderedServiceIds(orderedIds);
    setValue('serviceIds', orderedIds);
  }, [
    coreServiceIds,
    requiredAddOns,
    optionalAddOns,
    selectedAddOns,
    setValue,
  ]);

  useEffect(() => {
    updateOrderedServiceIds();
  }, [
    coreServiceIds,
    requiredAddOns,
    optionalAddOns,
    selectedAddOns,
    updateOrderedServiceIds,
  ]);

  useEffect(() => {
    try {
      const v = methods.getValues();
      if (!v) return;

      datadogRum.setUser({
        name: `${v.firstName} ${v.lastName}`,
        email: v.email as string,
      });
    } catch (err) {
      console.log(err, 'datadog init err');
    }
  }, [email]);

  useEffect(() => {
    if (presetServiceIds) setValue('coreServiceIds', presetServiceIds);
  }, [presetServiceIds]);

  const { data: locationData } = useGetLocationsExternalQuery({
    variables: {
      where: {
        isActive: {
          _eq: true,
        },
        isSystemManaged: {
          _eq: false,
        },
        workspaceId: { _eq: workspaceId },
        ...(lockedToLocationIds &&
          lockedToLocationIds.length > 0 && {
            id: { _in: lockedToLocationIds },
          }),
      },
    },
  });

  const [getServices, { data: servicesData }] =
    useGetServicesByCategoryLazyQuery();

  useEffect(() => {
    if (!location) return;

    getServices({
      variables: {
        where: {
          workspaceId: { _eq: workspaceId },
        },
        serviceWhere: {
          locationServices: { locationId: { _eq: location } },
          archived: { _eq: false },
          active: { _eq: true },
          ...(!hasLeadFormLink && { canBeBookedOnline: { _eq: true } }),
          ...(lockedToServiceIds &&
            lockedToServiceIds.length > 0 && {
              id: { _in: lockedToServiceIds },
            }),
        },
      },
    });
  }, [location, workspaceId, hasLeadFormLink]);

  useEffect(() => {
    if (reorderedCategoryServices.length === 0) return;
    setFlattenedServices(flattenServices(reorderedCategoryServices));
  }, [reorderedCategoryServices]);

  useEffect(() => {
    if (servicesData) {
      let reorderedServices = [];

      if (categoryOrder && categoryOrder.length === 0) {
        // If no ordering is specified, use the original order but include uncategorized services
        reorderedServices = [...servicesData.serviceCategory];
      } else {
        // Reorder according to specified order
        reorderedServices = reorderServices(
          servicesData,
          categoryOrder,
          serviceOrders
        );
      }

      if (
        servicesData.uncategorizedServices &&
        servicesData.uncategorizedServices.length > 0
      ) {
        const uncategorizedCategory = {
          title: 'Uncategorized',
          services: servicesData.uncategorizedServices,
        };
        reorderedServices.push(uncategorizedCategory);
      }

      reorderedServices = filterServicesWithBookingRules(
        reorderedServices,
        patientStatus,
        previousServiceIds
      );

      setReorderedCategoryServices(reorderedServices);
    }
  }, [servicesData]);

  const [getProviders, { data: providers }] =
    useGetProvidersExternalLazyQuery();
  const [suggestedTimeGroups, setSuggestedTimeGroups] =
    useState<AvailabilityGroups>({});
  const [orderedSuggestedTimeGroups, setOrderedSuggestedTimeGroups] = useState<
    {
      day: any;
      formattedDay: string;
      availabilityGroup: AvailabilityGroup;
    }[]
  >([]);
  const [
    getAppointmentAvailability,
    { data: appointmentAvailability, loading: appointmentAvailabilityLoading },
  ] = useGetAppointmentServiceAvailabilityLazyQuery();

  const [
    getAvailability,
    { data: availability, loading: availabilityLoading },
  ] = useGetAvailabilityForMultipleServicesLazyQuery();

  const [getDailyAvailabilityForMonth, { data: availabilityForMonth }] =
    useGetAvailabilityForMultipleServicesLazyQuery();

  useEffect(() => {
    if (hasAddOns ? availabilityLoading : appointmentAvailabilityLoading)
      return;

    const availibilityGroupings = hasAddOns
      ? availability?.getAvailabilityForMultipleServices
      : appointmentAvailability?.getAvailabilityForService;

    if (availibilityGroupings && availibilityGroupings.length > 0) {
      if (hasAddOns) {
        setAddOnAvailabilityLoaded(true);
      }

      // Leaving this as any on purpose, because its not in it's final shape
      const groupedByDay: any = _.groupBy(
        availibilityGroupings,
        'formattedDay'
      );

      const orderedDayGroups: {
        day: Dayjs;
        formattedDay: string;
        availabilityGroup: AvailabilityGroup;
      }[] = [];

      for (const day in groupedByDay) {
        groupedByDay[day] = _.groupBy(groupedByDay[day], 'start'); // <- final shape
        orderedDayGroups.push({
          day: dayjs(
            groupedByDay[day][Object.keys(groupedByDay[day])[0]][0].dateTime,
            'YYYY-MM-DDTHH:mm:ss.SSS[Z]'
          ),
          formattedDay: day,
          availabilityGroup: groupedByDay[day],
        });
      }

      setOrderedSuggestedTimeGroups(_.orderBy(orderedDayGroups, 'day', 'asc'));
      setSuggestedTimeGroups(groupedByDay);
    } else if (hasAddOns) {
      const nextStartDate = currentStartDate.add(DAYS_TO_FETCH, 'day');
      if (nextStartDate.diff(dayjs(preferredDate), 'day') < MAX_DAYS) {
        setCurrentStartDate(nextStartDate);
      } else {
        if (hasAddOns) {
          setAddOnAvailabilityLoaded(true);
        }
      }
    }
  }, [appointmentAvailability, availability]);
  useEffect(() => {
    onSelectedLocation?.(locationData?.location[0]);
  }, [locationData]);

  useEffect(() => {
    if (!location || !coreServiceIds) return;
    getProviders({
      variables: {
        where: {
          serviceProviders: { serviceId: { _in: coreServiceIds } },
          locationProviders: { locationId: { _eq: location.value } },
          ...(!hasLeadFormLink && { canBeBookedOnline: { _eq: true } }),
        },
      },
    });
    setCurrentStartDate(dayjs(preferredDate));
  }, [providerId, location, coreServiceIds]);

  useEffect(() => {
    if (preferredDate) {
      setStartTime(null);
      setValue('dateTime', null);
      setCurrentStartDate(dayjs(preferredDate));
    }
  }, [preferredDate]);

  useEffect(() => {
    setStartTime(null);
    setSelectedAddOns([]);
    setSuggestedTimeGroups({});
    setOrderedSuggestedTimeGroups([]);
  }, [coreServiceIds, providerId]);

  useEffect(() => {
    if (
      !location ||
      !coreServiceIds ||
      coreServiceIds.length === 0 ||
      !preferredDate ||
      startTime
    )
      return;

    if (hasAddOns) {
      getAvailability({
        variables: {
          providerId,
          locationId: location,
          serviceIds,
          day: currentStartDate.format('YYYY-MM-DD'),
          numberOfDays: DAYS_TO_FETCH,
        },
      });
      getDailyAvailabilityForMonth({
        variables: {
          providerId,
          locationId: location,
          serviceIds,
          day: dayjs(preferredDate).format('YYYY-MM-DD'),
          numberOfDays: 30,
          isDayAvailableCheck: true,
        },
      });
    } else {
      getAppointmentAvailability({
        variables: {
          providerId,
          locationId: location,
          serviceIds,
          day: dayjs(preferredDate).format('YYYY-MM-DD'),
          numberOfDays: 30,
        },
      });
    }
  }, [providerId, serviceIds, preferredDate, location, currentStartDate]);

  const locationSelectProps = {
    loadOptions: useLoadLocations,

    canCreate: false,
  };

  useEffect(() => {
    if (!flattenedServices.length || !coreServiceIds?.length) {
      setValue('prepayment', undefined);
      setStartTime(null);
      setValue('providerId', null);
      return;
    }

    const filteredServices = flattenedServices.filter(
      (service) =>
        coreServiceIds.includes(service.id) &&
        service.requiresDeposit &&
        service.deposit
    );

    if (!filteredServices.length) {
      setValue('prepayment', undefined);
      return;
    }

    let prepayment = 0;

    filteredServices.forEach((service) => {
      prepayment += service.deposit as number;
    });

    if (prepayment > 0) {
      setValue('prepayment', prepayment);
    } else {
      setValue('prepayment', undefined);
    }
  }, [coreServiceIds, reorderedCategoryServices]);

  useEffect(() => {
    if (!noShowActive || !noShowFee) return;

    setValue('noShowFee', noShowFee);
  }, [noShowActive, noShowFee, setValue]);

  useEffect(() => {
    if (
      !coreServiceIds ||
      !coreServiceIds.length ||
      !flattenedServices.length
    ) {
      setRequiredAddOns([]);
      setOptionalAddOns([]);
      setSelectedAddOns([]);
      return;
    }

    const coreServices = flattenedServices.filter((service) =>
      coreServiceIds.includes(service.id)
    );
    if (!coreServices.length) return;

    const required = coreServices.reduce(
      (acc, service) =>
        acc.concat(service.serviceAddOns.filter((addOn) => addOn.isRequired)),
      []
    );
    const optional = coreServices.reduce(
      (acc, service) =>
        acc.concat(
          service.serviceAddOns.filter(
            (addOn) =>
              !addOn.isRequired &&
              !required.some(
                (req) => req.serviceAddOn.id === addOn.serviceAddOn.id
              )
          )
        ),
      []
    );

    setRequiredAddOns(required);
    setOptionalAddOns(optional);

    // Initialize selected add-ons with required ones
    const initialSelectedAddOns = required.map(
      (addOn) => addOn.serviceAddOn.id
    );
    setSelectedAddOns(initialSelectedAddOns);
  }, [coreServiceIds, flattenedServices]);

  if (!locationData) {
    return (
      <Stack
        w={'full'}
        h="full"
        justifyContent={'center'}
        alignItems={'center'}
      >
        <Spinner size="lg" />
      </Stack>
    );
  }

  const handleAddOnToggle = (addOnId: string) => {
    setSelectedAddOns((prev) => {
      const newSelectedAddOns = prev.includes(addOnId)
        ? prev.filter((id) => id !== addOnId)
        : [...prev, addOnId];
      return newSelectedAddOns;
    });
  };

  const handleSuggestedTimeChange = (key: string) => {
    const selectedTime =
      orderedSuggestedTimeGroups[0].availabilityGroup[key][0];
    setStartTime(selectedTime.dateTime);
    methods.setValue('dateTime', selectedTime.dateTime);
    const availableProviders = selectedTime.providers.map((provider) => {
      const { __typename, ...providerWithoutTypename } = provider;
      return providerWithoutTypename;
    });
    if (hasAddOns) {
      methods.setValue('availableProviders', availableProviders);
    }
  };

  const availabilityIsLoading = hasAddOns
    ? !addOnAvailabilityLoaded && coreServiceIds?.length > 0
    : appointmentAvailabilityLoading;

  return (
    <SimpleGrid
      minChildWidth="200px"
      w={'full'}
      spacing={12}
      alignItems="flex-start"
      py={6}
    >
      <Stack spacing={4}>
        <FormInput
          isEditable={true}
          id="select-location"
          label="Location"
          selectProps={locationSelectProps}
          name="location"
          placeholder="Select location"
          type="select"
          defaultValue={location || locationData?.location?.[0]?.id}
          withOptions={locationData?.location.map(
            (loc: { name: string; id: string }) => ({
              label: loc?.name,
              value: loc?.id,
            })
          )}
        />
        {(location || locationData) && (
          <FormInput
            key={`${location}-${coreServiceIds?.length}`}
            label="Desired Service(s)"
            name="coreServiceIds"
            id="select-service"
            labelStyle={{
              color: `var(
      --sjs-font-questiontitle-color,
      var(--sjs-general-forecolor, var(--foreground, #161616))
    )`,
            }}
            type="multiselect"
            multiSelectProps={{
              async: false,
              defaultOptions: coreServiceIds
                ? flattenServices(reorderedCategoryServices)
                    .filter((s) => coreServiceIds.includes(s.id))
                    .map((service) => ({
                      label: service.name,
                      value: service.id,
                    }))
                : [],
              staticOptions: reorderedCategoryServices.map(
                (categoryService) => ({
                  label: categoryService.title,
                  options: categoryService.services.map((service) => ({
                    label: service.name,
                    value: service.id,
                  })),
                })
              ),
            }}
          />
        )}
        {allowProviderSelection && (
          <>
            {reorderedCategoryServices.length &&
            providers?.provider &&
            !providerId ? (
              <FormInput
                disabled={providers?.provider.length === 0}
                label="Provider Preference"
                name="providerId"
                defaultValue={providerId}
                type="select"
                labelStyle={{
                  color: `var(
                --sjs-font-questiontitle-color,
                var(--sjs-general-forecolor, var(--foreground, #161616))
              )`,
                }}
                placeholder="Any provider"
                withOptions={providers?.provider.map((provider) => ({
                  label: `${provider.firstName} ${provider.lastName}`,
                  value: provider.id,
                }))}
              />
            ) : (
              <Stack m={0} spacing={0}>
                <FormLabel
                  {...DEFAULT_LABEL_STYLE}
                  sx={{
                    color:
                      'var(--sjs-font-questiontitle-color, var(--sjs-general-forecolor, var(--foreground, #161616)))',
                  }}
                >
                  Provider Preference
                </FormLabel>
                <HStack>
                  <Text
                    sx={{
                      color:
                        'var(--sjs-font-questiontitle-color, var(--sjs-general-forecolor, var(--foreground, #161616)))',
                    }}
                  >
                    {
                      providers?.provider.find((p) => p.id === providerId)
                        ?.firstName
                    }{' '}
                    {
                      providers?.provider.find((p) => p.id === providerId)
                        ?.lastName
                    }
                  </Text>
                  <IconButton
                    onClick={() => {
                      setValue('providerId', null);
                      setStartTime(null);
                      setValue('dateTime', null);
                    }}
                    aria-label="clear provider"
                    icon={<FaTrash />}
                  />
                </HStack>
              </Stack>
            )}
          </>
        )}

        {requiredAddOns.length > 0 && hasAddOns && (
          <>
            <Divider />
            <Heading size="md">Required Add-ons</Heading>
            {requiredAddOns.map((addOn) => (
              <HStack
                justifyContent="stretch"
                alignItems="center"
                spacing={4}
                width="100%"
                key={addOn.serviceAddOn.id}
              >
                <Text>{addOn.serviceAddOn.name}</Text>
              </HStack>
            ))}
          </>
        )}
        {optionalAddOns.length > 0 && hasAddOns && (
          <>
            <Divider />
            <Heading size="md">Optional Add-ons</Heading>
            {optionalAddOns.map((addOn) => (
              <HStack
                width="100%"
                justifyContent="space-between"
                alignItems="center"
                spacing={4}
                key={addOn.serviceAddOn.id}
                align="stretch"
              >
                <Checkbox
                  isChecked={selectedAddOns.includes(addOn.serviceAddOn.id)}
                  onChange={() => handleAddOnToggle(addOn.serviceAddOn.id)}
                  id={addOn.serviceAddOn.id}
                >
                  {addOn.serviceAddOn.name}
                </Checkbox>
              </HStack>
            ))}
          </>
        )}
      </Stack>
      <Stack justifyContent="flex-start">
        <HStack justifyContent="space-between" alignItems="flex-end">
          <Box flex={1} ml={2} mr={2}>
            <FormInput
              label="Preferred date"
              type="date"
              name="preferredDate"
              mobiScrollDatePickerProps={{
                colors:
                  availabilityForMonth?.getAvailabilityForMultipleServices?.map(
                    (availability) => ({
                      date: dayjs(availability.dateTime).toDate(),
                      highlight: '#B2F5EA',
                    })
                  ),
                onPageChange: (page) => {
                  getDailyAvailabilityForMonth({
                    variables: {
                      providerId,
                      locationId: location,
                      serviceIds,
                      day: dayjs(page.firstDay).format('YYYY-MM-DD'),
                      numberOfDays: 30,
                      isDayAvailableCheck: true,
                    },
                  });
                },
              }}
            />
          </Box>
          {dayjs().isBefore(dayjs(preferredDate), 'day') && !isMobile && (
            <Button
              onClick={() => {
                const currentDate = dayjs(preferredDate);
                setValue(
                  'preferredDate',
                  currentDate.subtract(1, 'day').format('YYYY-MM-DD')
                );
              }}
              leftIcon={<FaArrowLeft />}
            ></Button>
          )}
          {!isMobile && (
            <Button
              onClick={() => {
                const currentDate = dayjs(preferredDate);
                setValue(
                  'preferredDate',
                  currentDate.add(1, 'day').format('YYYY-MM-DD')
                );
              }}
              rightIcon={<FaArrowRight />}
            >
              {dayjs().isSame(dayjs(preferredDate), 'day') ? 'Tomorrow' : ''}
            </Button>
          )}
        </HStack>
        <Box>
          {availabilityIsLoading ? (
            <Spinner />
          ) : startTime && coreServiceIds.length > 0 ? (
            <FormControl>
              <FormLabel
                htmlFor="preferredTime"
                {...DEFAULT_LABEL_STYLE}
                sx={{
                  color:
                    'var(--sjs-font-questiontitle-color, var(--sjs-general-forecolor, var(--foreground, #161616)))',
                }}
              >
                Preferred Time
              </FormLabel>
              <HStack w={'full'} alignContent={'center'} alignItems="center">
                <AiOutlineClockCircle
                  sx={{
                    color:
                      'var(--sjs-font-questiontitle-color, var(--sjs-general-forecolor, var(--foreground, #161616)))',
                  }}
                />
                <Text
                  sx={{
                    color:
                      'var(--sjs-font-questiontitle-color, var(--sjs-general-forecolor, var(--foreground, #161616)))',
                  }}
                >
                  <b>{dayjs(startTime).format('h:mm A z')}</b> on{' '}
                  <b>{dayjs(dateTime).format('dddd, MMMM Do')}</b>
                </Text>
              </HStack>
              <Button
                mt={2}
                variant={'link'}
                leftIcon={<FaEdit />}
                onClick={() => setStartTime(null)}
                sx={{
                  color:
                    'var(--sjs-font-questiontitle-color, var(--sjs-general-forecolor, var(--foreground, #161616)))',
                }}
              >
                Select different time
              </Button>
            </FormControl>
          ) : Object.keys(suggestedTimeGroups).length > 0 &&
            coreServiceIds.length > 0 ? (
            <Box>
              <Heading
                size="md"
                color={'brand.primary'}
                my={2}
                fontSize="clamp(16px,20px,25px)"
                sx={{
                  color:
                    'var(--sjs-font-questiontitle-color, var(--sjs-general-forecolor, var(--foreground, #161616)))',
                }}
              >
                Suggested available times & dates
              </Heading>

              <Stack>
                {dayjs(preferredDate).format('dddd, D MMMM') !==
                  orderedSuggestedTimeGroups[0].formattedDay && (
                  <Heading
                    size="xs"
                    sx={{
                      color:
                        'var(--sjs-font-questiontitle-color, var(--sjs-general-forecolor, var(--foreground, #161616)))',
                    }}
                  >
                    No availability on{' '}
                    {dayjs(preferredDate).format('dddd, MMMM Do')}, next
                    available times:
                  </Heading>
                )}
                <Text
                  size={'sm'}
                  my={2}
                  sx={{
                    color:
                      'var(--sjs-font-questiontitle-color, var(--sjs-general-forecolor, var(--foreground, #161616)))',
                  }}
                >
                  {orderedSuggestedTimeGroups[0].formattedDay}
                </Text>
                <SimpleGrid
                  minChildWidth={'130px'}
                  spacing={4}
                  sx={{
                    '&::-webkit-scrollbar': {
                      width: '0px',
                      borderRadius: '8px',
                      backgroundColor: `rgba(0, 0, 0, 0.05)`,
                    },
                    '&::-webkit-scrollbar-thumb': {
                      backgroundColor: `rgba(0, 0, 0, 0.05)`,
                    },
                  }}
                  maxH={'165px'}
                  overflowY={'auto'}
                >
                  {Object.keys(
                    orderedSuggestedTimeGroups[0].availabilityGroup
                  ).map((key: string) => (
                    <HStack
                      key={key}
                      spacing={4}
                      justifyContent="space-between"
                      cursor={'pointer'}
                      bg={
                        dayjs(
                          orderedSuggestedTimeGroups[0].availabilityGroup[
                            key
                          ][0].dateTime
                        ).isSame(preferredDate, 'date') &&
                        orderedSuggestedTimeGroups[0].availabilityGroup[key][0]
                          .start === startTime
                          ? 'brand.primary'
                          : 'transparent'
                      }
                      color={
                        dayjs(
                          orderedSuggestedTimeGroups[0].availabilityGroup[
                            key
                          ][0].dateTime
                        ).isSame(preferredDate, 'date') &&
                        orderedSuggestedTimeGroups[0].availabilityGroup[key][0]
                          .start === startTime
                          ? 'white'
                          : 'brand.primary'
                      }
                      _hover={{
                        bg: 'brand.primary',
                        color: 'white',
                      }}
                      rounded="md"
                      borderWidth={2}
                      borderColor={'brand.primary'}
                      p={2}
                      onClick={() => {
                        handleSuggestedTimeChange(key);
                      }}
                    >
                      <Text fontSize="sm">{key}</Text>
                      {hasAddOns ? (
                        <AvatarGroup size="xs" max={2}>
                          {Object.values(
                            orderedSuggestedTimeGroups[0].availabilityGroup[key]
                          ).map(
                            ({
                              providers,
                            }: {
                              providers: ProviderFieldsFragment[];
                            }) =>
                              providers.map((provider) => (
                                <Avatar
                                  key={provider.id}
                                  name={`${provider.firstName} ${provider.lastName}`}
                                  src={provider.profilePicture || undefined}
                                />
                              ))
                          )}
                        </AvatarGroup>
                      ) : (
                        <AvatarGroup size="xs" max={2}>
                          {orderedSuggestedTimeGroups[0].availabilityGroup[
                            key
                          ].map(
                            ({
                              provider,
                            }: {
                              provider: ProviderFieldsFragment;
                            }) => (
                              <Avatar
                                key={provider.id}
                                name={`${provider.firstName} ${provider.lastName}`}
                                src={provider.profilePicture || undefined}
                              />
                            )
                          )}
                        </AvatarGroup>
                      )}
                    </HStack>
                  ))}
                </SimpleGrid>
              </Stack>
            </Box>
          ) : coreServiceIds?.length > 0 ? (
            <Box
              sx={{
                color:
                  'var(--sjs-font-questiontitle-color, var(--sjs-general-forecolor, var(--foreground, #161616)))',
              }}
            >
              No availability, please try a different provider or day - or give
              us a call at {locationData?.location[0].phoneNumber}
            </Box>
          ) : (
            <Box
              sx={{
                color:
                  'var(--sjs-font-questiontitle-color, var(--sjs-general-forecolor, var(--foreground, #161616)))',
              }}
            >
              Select a service to see availability.
            </Box>
          )}
        </Box>
      </Stack>
    </SimpleGrid>
  );
};

export default ExternalBooking;
