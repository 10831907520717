import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { CouponFieldsFragmentDoc } from '../../fragments/couponFields.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type InsertCouponMutationVariables = Types.Exact<{
  coupon: Types.Coupon_Insert_Input;
}>;


export type InsertCouponMutation = { __typename?: 'mutation_root', insert_coupon?: { __typename?: 'coupon_mutation_response', returning: Array<{ __typename?: 'coupon', id: any, name: string, rule: string, amount: any, type: Types.CouponType_Enum, scope: Types.CouponScope_Enum, validFrom: any, validUntil: any, isActive: boolean, packageId?: any | null | undefined, isSystemGenerated: boolean, locationCoupons: Array<{ __typename?: 'locationCoupon', location: { __typename?: 'location', id: any, isActive: boolean, name: string, address?: string | null | undefined, phoneNumber?: string | null | undefined, email?: string | null | undefined, maxPatientsPerTimeslot?: number | null | undefined, workspaceId: any, clinicId?: number | null | undefined, addressId?: any | null | undefined, workStartTime?: string | null | undefined, workEndTime?: string | null | undefined, timezone?: string | null | undefined, payrixMerchantId?: string | null | undefined, finixMerchantId?: string | null | undefined, locationSchedules: Array<{ __typename?: 'locationSchedule', id: any, schedule: any, effectiveFrom?: any | null | undefined, createdAt: any, updatedAt: any, locationId: any }>, fullAddress?: { __typename?: 'address', addressLine1: string, addressLine2?: string | null | undefined, city: string, id: any, state: string, zipCode: string } | null | undefined, rooms: Array<{ __typename?: 'room', id: any, name: string }> } }>, serviceCoupons: Array<{ __typename?: 'serviceCoupon', service: { __typename?: 'service', id: any, name: string } }> }> } | null | undefined };


export const InsertCouponDocument = gql`
    mutation insertCoupon($coupon: coupon_insert_input!) {
  insert_coupon(objects: [$coupon]) {
    returning {
      ...CouponFields
    }
  }
}
    ${CouponFieldsFragmentDoc}`;
export type InsertCouponMutationFn = Apollo.MutationFunction<InsertCouponMutation, InsertCouponMutationVariables>;

/**
 * __useInsertCouponMutation__
 *
 * To run a mutation, you first call `useInsertCouponMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertCouponMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertCouponMutation, { data, loading, error }] = useInsertCouponMutation({
 *   variables: {
 *      coupon: // value for 'coupon'
 *   },
 * });
 */
export function useInsertCouponMutation(baseOptions?: Apollo.MutationHookOptions<InsertCouponMutation, InsertCouponMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertCouponMutation, InsertCouponMutationVariables>(InsertCouponDocument, options);
      }
export type InsertCouponMutationHookResult = ReturnType<typeof useInsertCouponMutation>;
export type InsertCouponMutationResult = Apollo.MutationResult<InsertCouponMutation>;
export type InsertCouponMutationOptions = Apollo.BaseMutationOptions<InsertCouponMutation, InsertCouponMutationVariables>;