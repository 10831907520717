import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetPatientInviteQueryVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
}>;


export type GetPatientInviteQuery = { __typename?: 'query_root', patientInvite_by_pk?: { __typename?: 'patientInvite', email: string, id: any, patientId: any, status: string, workspaceId: any, patient: { __typename?: 'patient', id: any }, workspace: { __typename?: 'workspace', name: string } } | null | undefined };


export const GetPatientInviteDocument = gql`
    query GetPatientInvite($id: uuid!) {
  patientInvite_by_pk(id: $id) {
    email
    id
    patient {
      id
    }
    patientId
    status
    workspace {
      name
    }
    workspaceId
  }
}
    `;

/**
 * __useGetPatientInviteQuery__
 *
 * To run a query within a React component, call `useGetPatientInviteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPatientInviteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPatientInviteQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPatientInviteQuery(baseOptions: Apollo.QueryHookOptions<GetPatientInviteQuery, GetPatientInviteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPatientInviteQuery, GetPatientInviteQueryVariables>(GetPatientInviteDocument, options);
      }
export function useGetPatientInviteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPatientInviteQuery, GetPatientInviteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPatientInviteQuery, GetPatientInviteQueryVariables>(GetPatientInviteDocument, options);
        }
export type GetPatientInviteQueryHookResult = ReturnType<typeof useGetPatientInviteQuery>;
export type GetPatientInviteLazyQueryHookResult = ReturnType<typeof useGetPatientInviteLazyQuery>;
export type GetPatientInviteQueryResult = Apollo.QueryResult<GetPatientInviteQuery, GetPatientInviteQueryVariables>;