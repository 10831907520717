import { CompoundControlledFormInputProps } from '@webapp/ui';
import { splitCamelCaseToString } from '@webapp/util-formatting';
import { ColDef } from 'ag-grid-community';

export function transformColDefsToFormInputs<T>(
  colDefs: ColDef<T>[]
): Omit<CompoundControlledFormInputProps, 'control'>[] {
  const formInputs = colDefs.reduce((acc, colDef) => {
    const Editor = colDef.cellEditor;
    const editorParams = colDef.cellEditorParams || {};
    const inputProps = editorParams.inputProps || {};

    if (Editor && colDef.field && inputProps.type) {
      const { name, ...otherInputProps } = inputProps;

      return [
        ...acc,
        {
          label: splitCamelCaseToString(colDef.headerName || colDef.field),
          name: colDef.field,
          gridArea: colDef.field,
          ...otherInputProps,
        },
      ];
    }

    return acc;
  }, [] as Omit<CompoundControlledFormInputProps, 'control'>[]);

  return formInputs;
}

export default transformColDefsToFormInputs;
