/* eslint-disable prefer-arrow-callback */
import {
  GridItem,
  Box,
  SimpleGrid,
  Image,
  Text,
  HStack,
  Stack,
  Tag,
  Wrap,
  WrapItem,
  Skeleton,
  Checkbox,
  Badge,
  Flex,
} from '@chakra-ui/react';
import { Photo as MSTPhoto } from '@webapp/state-models';
import { observer } from 'mobx-react-lite';
import { configuredDayjs as dayjs } from '@webapp/util-time';
import { SimpleVideo } from '@webapp/ui';
import { getProxyPhotoUrl } from '@webapp/utils';
/* eslint-disable-next-line */
export interface MediaGridProps {
  media: MSTPhoto[];
  onMediaSelect: (photo: MSTPhoto) => void;
  onCheckboxSelect?: (photo: MSTPhoto) => void;
  selectedPhotos?: MSTPhoto[];
  simple?: boolean;
  columns?: number;
  spacing?: number;
  showConsent?: boolean;
}

const renderBadge = (photo: MSTPhoto): JSX.Element => {
  let badge = <Badge colorScheme="red">Requires Consent</Badge>;

  if (!photo.patientId || (photo.patientId && photo.fullMediaConsent)) {
    badge = <Badge colorScheme="green">Can Post</Badge>;
  }

  return (
    <Flex w="full" pr={2} justifyContent="flex-end">
      {badge}
    </Flex>
  );
};

export const MediaGrid = observer(
  ({
    media,
    onMediaSelect,
    simple = false,
    columns = 4,
    spacing = 40,
    onCheckboxSelect,
    selectedPhotos = [],
    showConsent = false,
  }: MediaGridProps) => (
    <Box
      overflowY={'scroll'}
      sx={{
        '&::-webkit-scrollbar': {
          width: '0px',
          borderRadius: '8px',
          backgroundColor: `rgba(0, 0, 0, 0.05)`,
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: `rgba(0, 0, 0, 0.05)`,
        },
      }}
    >
      <SimpleGrid columns={columns} spacing={`${spacing}px`} h="full" w="full">
        {media.length === 0 && <span>No results for current query</span>}
        {media.map((photo: MSTPhoto) => {
          const isSelected = !!selectedPhotos.find((p) => p.id === photo.id);

          return (
            <GridItem
              key={photo.id}
              boxShadow={isSelected ? 'outline' : 'md'}
              cursor={'pointer'}
              rounded="md"
              mb={2}
              _hover={{
                shadow: 'lg',
                opacity: 0.75,
              }}
            >
              <Stack position={'relative'} role="group">
                {photo?.mimeType && photo?.mimeType.includes('video') ? (
                  <SimpleVideo src={photo.file?.url} />
                ) : (
                  <Image
                    className="ph-no-capture fs-exclude"
                    onClick={() => onMediaSelect(photo)}
                    key={`${photo.id}-${photo.hasThumbnail}`}
                    fallback={<Skeleton w={'full'} h={simple ? '40' : '60'} />}
                    imageOrientation={'from-image'}
                    maxH={60}
                    w={'full'}
                    rounded="md"
                    src={
                      getProxyPhotoUrl(
                        photo.hasThumbnail && photo.file?.thumbnailUrl
                          ? (photo.file?.thumbnailUrl as string)
                          : (photo.file?.url as string)
                      ) as string
                    }
                    objectFit={'cover'}
                  />
                )}
                {onCheckboxSelect && (
                  <Checkbox
                    onChange={() => onCheckboxSelect(photo)}
                    isChecked={isSelected}
                    size="lg"
                    position={'absolute'}
                    top="2"
                    left="3"
                    opacity={isSelected ? 1 : '0'}
                    _groupHover={{ opacity: '1' }}
                  />
                )}
                {showConsent && renderBadge(photo)}

                {!simple && (
                  <>
                    <HStack
                      onClick={() => onMediaSelect(photo)}
                      w={'full'}
                      justifyContent={'space-between'}
                      alignItems={'center'}
                    >
                      <Text size="md" fontWeight={'500'} color={'black'} p={2}>
                        {photo.patient?.attributes?.firstName}{' '}
                        {photo.patient?.attributes?.lastName}
                      </Text>

                      <Text color={'gray.600'} px={1}>
                        {dayjs(photo.mediaDate).format('MM/DD/YY')}
                      </Text>
                    </HStack>

                    <HStack pb={2} px={2} w={'full'} overflowY={'auto'}>
                      <Wrap>
                        {photo.photoMediaTags.map((mediaTag) => (
                          <WrapItem key={mediaTag.id}>
                            <Tag size={'sm'}>{mediaTag.mediaTag.title}</Tag>
                          </WrapItem>
                        ))}
                      </Wrap>
                    </HStack>
                  </>
                )}
              </Stack>
            </GridItem>
          );
        })}
      </SimpleGrid>
    </Box>
  )
);

export default MediaGrid;
