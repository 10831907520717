import {
  IconButton,
  HStack,
  Circle,
  Text,
  Button,
  CircularProgress,
  ScaleFade,
  Progress,
  Stack,
  Tooltip,
} from '@chakra-ui/react';
import { FaMicrophone, FaSave, FaStop, FaTrash } from 'react-icons/fa';
import { useUploadToS3 } from '@webapp/hooks';
import {
  StorageType,
  useTranscribeAudioMutation,
  useWarmTranscriberMutation,
} from '@webapp/graphql';
import { useState } from 'react';
import toast from 'react-hot-toast';
import useRecorder, { Recorder } from './use-recorder';
import styles from './record-audio.module.scss';
/* eslint-disable-next-line */
export interface RecordAudioProps {
  onTranscribe?: (text: string) => void;
}

export function RecordAudio({ onTranscribe }: RecordAudioProps) {
  const { recorderState, ...handlers }: Recorder = useRecorder();
  const { audio } = recorderState;
  const { recordingMinutes, recordingSeconds, initRecording } = recorderState;
  const { startRecording, saveRecording, cancelRecording } = handlers;
  const [uploading, setUploading] = useState(false);
  const { uploadToS3 } = useUploadToS3();
  const [progress, setProgress] = useState(0);
  const [readyToTranscribe, setReadyToTranscribe] = useState(false);
  const [transcribeAudio, { loading: transcribing }] =
    useTranscribeAudioMutation({
      onCompleted: (d) => {
        if (onTranscribe && d?.transcribeAudio?.data) {
          onTranscribe(d?.transcribeAudio?.data);
        }
        clear();
      },
      onError: (e) => {
        toast.error(e.message);
        clear();
      },
    });

  async function transcribe() {
    setUploading(true);

    const filePath = await uploadToS3({
      fileType: 'audio/ogg',
      fileContents: audio,
      filePath: `${Date.now()}.ogg`,
      storageType: StorageType.Private,
      onProgress: setProgress,
      randomizeFileName: true,
    });
    setUploading(false);
    await transcribeAudio({ variables: { filePath } });
  }

  function clear() {
    cancelRecording();
    setReadyToTranscribe(false);
    setProgress(0);
  }

  return (
    <HStack
      rounded="full"
      p={2}
      bg="gray.50"
      transition={'all 0.2s ease-in-out'}
      maxW={initRecording || audio ? '150px' : '48px'}
      justifyContent="center"
      alignItems={'center'}
      position="relative"
    >
      {!initRecording && !audio && (
        <IconButton
          size="sm"
          rounded="full"
          icon={<FaMicrophone />}
          onClick={() => {
            startRecording();
            setReadyToTranscribe(false);
          }}
          aria-label="start recording"
        />
      )}
      {initRecording && (
        <>
          <IconButton
            size="sm"
            rounded="full"
            color="red.500"
            icon={<FaStop />}
            onClick={() => {
              saveRecording();
              setReadyToTranscribe(true);
            }}
            aria-label="stop recording"
          />
          <Text>
            {recordingMinutes <= 9 && '0'}
            {recordingMinutes}:{recordingSeconds <= 9 && '0'}
            {recordingSeconds}
          </Text>
        </>
      )}

      {readyToTranscribe && !(uploading || transcribing) && (
        <HStack>
          <IconButton
            size="sm"
            rounded="full"
            icon={<FaSave />}
            onClick={transcribe}
            aria-label="transcribe"
          />
          <IconButton
            size="sm"
            rounded="full"
            icon={<FaTrash />}
            onClick={clear}
            aria-label="transcribe"
          />
        </HStack>
      )}

      {(uploading || transcribing) && (
        <Tooltip label="Please be patient, this process can take 30 to 60 seconds">
          <Stack alignContent={'center'}>
            <Text fontSize={'8px'}>Transcribing...</Text>
            <Progress
              value={progress}
              isIndeterminate={transcribing}
              w={'80px'}
              h={1}
              rounded="md"
            />
          </Stack>
        </Tooltip>
      )}
    </HStack>
  );
}

export default RecordAudio;
