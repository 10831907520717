import { Checkbox, Flex, InputGroup, Text, Tooltip } from '@chakra-ui/react';
import { omit } from 'lodash';
import { ChangeEvent, PropsWithChildren } from 'react';
import DEFAULT_CONTROL_STYLE from './styles/DefaultControlStyle';
import DEFAULT_INPUT_CONTAINER_STYLE from './styles/DefaultInputContainerStyle';
import { CheckboxInputProps } from './types';

function CheckboxInput({
  children,
  description,
  onChange,
  size,
  styles = {},
  value,
  ...props
}: PropsWithChildren<Omit<CheckboxInputProps, 'type'>>) {
  const descriptionStyles = styles.description || {};

  const inputStyles = omit(
    { ...DEFAULT_CONTROL_STYLE, ...styles.control },
    'background'
  );

  const containerStyles = {
    ...DEFAULT_INPUT_CONTAINER_STYLE,
    ...styles.inputContainer,
  };

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    onChange(event.target.checked);
  }
  return (
    <InputGroup {...containerStyles}>
      <Checkbox
        defaultChecked={value}
        isChecked={value}
        onChange={handleChange}
        size={size}
        width="100%"
        {...inputStyles}
        {...props}
      >
        <Tooltip label={description}>
          <Text {...descriptionStyles}>{description}</Text>
        </Tooltip>
      </Checkbox>
    </InputGroup>
  );
}

export default CheckboxInput;
