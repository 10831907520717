import { toast } from 'react-hot-toast';
import {
  GraphqlClient,
  InsertProviderScheduleDocument,
  UpdateProviderScheduleDocument,
} from '@webapp/graphql';
import { Schedule } from '@webapp/types';
import {
  Instance,
  SnapshotOut,
  types,
  flow,
  applySnapshot,
} from 'mobx-state-tree';
import { getErrorMessage } from '@webapp/utils';
import { ProviderSchedule, ProviderScheduleModel } from './providerSchedule';

export const ProviderModel = types
  .model('Provider')
  .props({
    id: types.identifier,
    firstName: types.string,
    lastName: types.string,
    profilePicture: types.maybeNull(types.string),
    email: types.string,
    phoneNumber: types.maybeNull(types.string),
    address: types.maybeNull(types.string),
    title: types.maybeNull(types.string),
    stateLicense: types.maybeNull(types.string),
    upin: types.maybeNull(types.string),
    dea: types.maybeNull(types.string),
    npi: types.maybeNull(types.string),
    providerSchedules: types.optional(
      types.array(types.late(() => ProviderScheduleModel)),
      []
    ),
    degreeDesignation: types.maybeNull(
      types.model({
        value: types.string,
        description: types.string,
      })
    ),
    serviceProviders: types.array(
      types.model({
        service: types.model({
          id: types.identifier,
          name: types.string,
          serviceDeviceTypes: types.array(
            types.model({
              deviceType: types.model({
                value: types.string,
              }),
            })
          ),
        }),
      })
    ),
    locationProviders: types.array(
      types.model({
        location: types.model({
          id: types.identifier,
          name: types.string,
        }),
      })
    ),
  })
  .views((self) => ({
    effectiveSchedule(locationId: string): ProviderSchedule[] | undefined {
      return self.providerSchedules.filter(
        (schedule) => schedule.locationId === locationId
      );
    },
    get name() {
      return `${self.firstName} ${self.lastName}`;
    },
  }))
  .actions((self) => ({
    createAvailability: flow(function* (locationId: string) {
      const client = GraphqlClient();
      try {
        const {
          data: {
            insert_providerSchedule: { returning },
          },
        } = yield client.mutate({
          mutation: InsertProviderScheduleDocument,
          variables: {
            providerSchedule: {
              locationId,
              providerId: self.id,
              schedule: {
                0: [],
                1: [
                  { start: '09:00', end: '12:00' },
                  { start: '13:00', end: '17:00' },
                ],
                2: [
                  { start: '09:00', end: '12:00' },
                  { start: '13:00', end: '17:00' },
                ],
                3: [
                  { start: '09:00', end: '12:00' },
                  { start: '13:00', end: '17:00' },
                ],
                4: [
                  { start: '09:00', end: '12:00' },
                  { start: '13:00', end: '17:00' },
                ],
                5: [
                  { start: '09:00', end: '12:00' },
                  { start: '13:00', end: '17:00' },
                ],
                6: [],
              },
            },
          },
        });
        self.providerSchedules = returning;
      } catch (e) {
        toast.error(getErrorMessage(e));
      }
    }),
    updateAvailability: flow(function* (
      providerScheduleId: string,
      schedule: Schedule
    ) {
      const client = GraphqlClient();
      try {
        const {
          data: {
            update_providerSchedule: { returning },
          },
        } = yield client.mutate({
          mutation: UpdateProviderScheduleDocument,
          variables: {
            id: providerScheduleId,
            _set: {
              schedule,
            },
          },
        });
        const ps = self.providerSchedules.find(
          (p) => p.id === providerScheduleId
        ) as ProviderSchedule;
        applySnapshot(ps, returning[0]);
        toast.success('Availability updated');
      } catch (e) {
        toast.error(getErrorMessage(e));
      }
    }),
  }));

export type Provider = Instance<typeof ProviderModel>;

export type ProviderSnapshot = SnapshotOut<typeof ProviderModel>;
