export const audienceFilterOpToStr = (op: string) => {
  switch (op) {
    case 'eq':
      return 'Is Equal To';
    case 'ne':
      return 'Is Not Equal To';
    case 'gt':
      return 'Is Greater Than';
    case 'ngt':
      return 'Is Not Greater Than';
    case 'lt':
      return 'Is Less Than';
    case 'nlt':
      return 'Is Not Less Than';
    case 'iw':
      return 'Is Within';
    case 'inw':
      return 'Is Not Within';
    case 'ie':
      return 'Is Exactly';
    default:
      return 'Invalid operation';
  }
};

export const audienceFilterSelectorToLabel = (
  target: string,
  field: string
) => {
  switch (`${target}.${field}`) {
    case 'custom.age':
      return 'Age';
    case 'address.zipCode':
      return 'Zip Code';
    case 'patientWorkspace.gender':
      return 'Gender';
    case 'custom.firstAppointment':
      return 'First Appointment';
    case 'custom.lastAppointment':
      return 'Last Appointment';
    case 'patientWorkspace.attributes.race':
      return 'Race';
    case 'patientWorkspace.language':
      return 'Language';
    case 'patientWorkspace.primaryProviderId':
      return 'Primary Provider';
    case 'address.city':
      return 'City';
    case 'address.state':
      return 'State';
    case 'patientWorkspace.primaryLocationId':
      return 'Primary Location';
    case 'custom.servicesOfInterest':
      return 'Service';
    case 'custom.serviceHistory':
      return 'Last Service Time';
    case 'custom.upcomingService':
      return 'Upcoming Service';
    case 'custom.patientCreated':
      return 'Patient Creation';
    case 'custom.birthday':
      return 'Birthday';
    case 'patientWorkspace.status':
      return 'Status';
    case 'patientWorkspace.leadStatus':
      return 'Lead Status';
    case 'patientWorkspace.source':
      return 'Lead Source';
    case 'custom.serviceCategoryOfInterest':
      return 'Service Category of Interest';
    case 'custom.apptCancelledNoShow':
      return 'Appointment Cancelled/No-Show';
    case 'custom.leadFormSource':
      return 'Lead Form Source';
    default:
      return field;
  }
};

export const parseFormConstants = (list: any[], label: string) => {
  const obj = list.find((elem) => elem.label === label);
  return obj.options ? obj.options.map((v: any) => v.value) : null;
};
