import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { ConsumableBrandFieldsFragmentDoc } from '../../fragments/consumableBrandFields.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type InsertConsumableBrandMutationVariables = Types.Exact<{
  consumableBrand: Types.ConsumableBrand_Insert_Input;
}>;


export type InsertConsumableBrandMutation = { __typename?: 'mutation_root', insert_consumableBrand?: { __typename?: 'consumableBrand_mutation_response', returning: Array<{ __typename?: 'consumableBrand', id: any, name: string, contactName?: string | null | undefined, contactEmail?: string | null | undefined, contactPhoneNumber?: string | null | undefined, note?: string | null | undefined, createdAt: any, updatedAt: any, archived: boolean }> } | null | undefined };


export const InsertConsumableBrandDocument = gql`
    mutation insertConsumableBrand($consumableBrand: consumableBrand_insert_input!) {
  insert_consumableBrand(objects: [$consumableBrand]) {
    returning {
      ...ConsumableBrandFields
    }
  }
}
    ${ConsumableBrandFieldsFragmentDoc}`;
export type InsertConsumableBrandMutationFn = Apollo.MutationFunction<InsertConsumableBrandMutation, InsertConsumableBrandMutationVariables>;

/**
 * __useInsertConsumableBrandMutation__
 *
 * To run a mutation, you first call `useInsertConsumableBrandMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertConsumableBrandMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertConsumableBrandMutation, { data, loading, error }] = useInsertConsumableBrandMutation({
 *   variables: {
 *      consumableBrand: // value for 'consumableBrand'
 *   },
 * });
 */
export function useInsertConsumableBrandMutation(baseOptions?: Apollo.MutationHookOptions<InsertConsumableBrandMutation, InsertConsumableBrandMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertConsumableBrandMutation, InsertConsumableBrandMutationVariables>(InsertConsumableBrandDocument, options);
      }
export type InsertConsumableBrandMutationHookResult = ReturnType<typeof useInsertConsumableBrandMutation>;
export type InsertConsumableBrandMutationResult = Apollo.MutationResult<InsertConsumableBrandMutation>;
export type InsertConsumableBrandMutationOptions = Apollo.BaseMutationOptions<InsertConsumableBrandMutation, InsertConsumableBrandMutationVariables>;