import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { MarketingTagFieldsFragmentDoc } from '../../fragments/marketingTagFields.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetMarketingTagsQueryVariables = Types.Exact<{
  where: Types.MarketingTag_Bool_Exp;
}>;


export type GetMarketingTagsQuery = { __typename?: 'query_root', marketingTag: Array<{ __typename?: 'marketingTag', id: any, title: string }> };


export const GetMarketingTagsDocument = gql`
    query getMarketingTags($where: marketingTag_bool_exp!) {
  marketingTag(where: $where) {
    ...MarketingTagFields
  }
}
    ${MarketingTagFieldsFragmentDoc}`;

/**
 * __useGetMarketingTagsQuery__
 *
 * To run a query within a React component, call `useGetMarketingTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMarketingTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMarketingTagsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetMarketingTagsQuery(baseOptions: Apollo.QueryHookOptions<GetMarketingTagsQuery, GetMarketingTagsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMarketingTagsQuery, GetMarketingTagsQueryVariables>(GetMarketingTagsDocument, options);
      }
export function useGetMarketingTagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMarketingTagsQuery, GetMarketingTagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMarketingTagsQuery, GetMarketingTagsQueryVariables>(GetMarketingTagsDocument, options);
        }
export type GetMarketingTagsQueryHookResult = ReturnType<typeof useGetMarketingTagsQuery>;
export type GetMarketingTagsLazyQueryHookResult = ReturnType<typeof useGetMarketingTagsLazyQuery>;
export type GetMarketingTagsQueryResult = Apollo.QueryResult<GetMarketingTagsQuery, GetMarketingTagsQueryVariables>;