import { HStack, InputGroup, VStack } from '@chakra-ui/react';
import FormInputWrapper from './FormInputWrapper';
import PlacesInput from './PlacesInput';
import DEFAULT_CONTROL_STYLE from './styles/DefaultControlStyle';
import DEFAULT_INPUT_CONTAINER_STYLE from './styles/DefaultInputContainerStyle';
import TextInput from './TextInput';
import { AddressInputProps } from './types/AddressInputProps';

function AddressInput({
  error,
  onChange,
  styles = {},
  value,
}: Omit<AddressInputProps, 'type'>) {
  const inputStyles = { ...DEFAULT_CONTROL_STYLE, ...styles.control };

  const containerStyles = {
    ...DEFAULT_INPUT_CONTAINER_STYLE,
    ...styles.inputContainer,
  };

  return (
    <InputGroup as={VStack} {...containerStyles}>
      <FormInputWrapper
        error={error?.addressLine1}
        label="Address Line 1"
        name={`addressLine1`}
        type="places"
      >
        <PlacesInput
          name={`addressLine1`}
          onChange={onChange}
          showFullAddress={false}
          value={value}
        />
      </FormInputWrapper>
      <FormInputWrapper
        error={error?.addressLine2}
        label="Address Line 2"
        name="addressLine2"
        type="text"
      >
        <TextInput
          name={`addressLine2`}
          onChange={(addressLine2) => {
            onChange({
              ...value,
              addressLine2,
            });
          }}
          value={value?.addressLine2 || ''}
        />
      </FormInputWrapper>
      <HStack>
        <FormInputWrapper
          error={error?.city && 'Required'}
          label="City"
          name={`city`}
          type="text"
        >
          <TextInput
            // label="City"
            name={`city`}
            onChange={(city) => {
              onChange({
                ...value,
                city,
              });
            }}
            placeholder="City"
            value={value?.city ?? ''}
            {...inputStyles}
          />
        </FormInputWrapper>
        <FormInputWrapper
          error={error?.state && 'Required'}
          label="State"
          name={`state`}
          type="text"
        >
          <TextInput
            name={`state`}
            onChange={(state) => {
              onChange({
                ...value,
                state,
              });
            }}
            placeholder="State"
            value={value?.state ?? ''}
            {...inputStyles}
          />
        </FormInputWrapper>
        <FormInputWrapper
          error={error?.zipCode && 'Required'}
          label="Zip Code"
          name={`zipCode}`}
          type="text"
        >
          <TextInput
            name={`zipCode`}
            onChange={(zipCode) => {
              onChange({
                ...value,
                zipCode,
              });
            }}
            placeholder="Zip"
            value={value?.zipCode ?? ''}
            {...inputStyles}
          />
        </FormInputWrapper>
      </HStack>
    </InputGroup>
  );
}

export default AddressInput;
