import { StyleProps } from '@chakra-ui/react';

export const DEFAULT_CONTROL_STYLE: StyleProps = {
  alignItems: 'center',
  background: 'white',
  fontSize: 'md',
  minHeight: '40px',
  letterSpacing: '0.01em',
  width: '100%',
};

export default DEFAULT_CONTROL_STYLE;
