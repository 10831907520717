import { Instance, SnapshotOut, types } from 'mobx-state-tree';

/**
 * A NoteStore model.
 */
// prettier-ignore
export const NoteModel = types.model("Note").props({
 id: types.identifier,

}).actions((self) => ({
  
}
))
/**
 * The Note instance.
 */
export type Note = Instance<typeof NoteModel>;

/**
 * The data of a Note.
 */
export type NoteSnapshot = SnapshotOut<typeof NoteModel>;
