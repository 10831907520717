import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
  FormControl,
  FormLabel,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Stack,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  IconButton,
  Switch,
} from '@chakra-ui/react';

import { VscSettings } from 'react-icons/vsc';

function SettingsPopover({
  value,
  onChange,
  gridSize,
  setGridSize,
  showGrid,
  setShowGrid,
}: {
  value: number;
  onChange: (value: number) => void;
  gridSize: number;
  setGridSize: (value: number) => void;
  showGrid: boolean;
  setShowGrid: (value: boolean) => void;
}) {
  return (
    <Popover>
      <PopoverTrigger>
        <IconButton
          aria-label="settings"
          variant="outline"
          icon={<VscSettings color="white" />}
        ></IconButton>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverHeader>Settings</PopoverHeader>
        <PopoverBody>
          <Stack>
            <FormControl>
              <FormLabel>Overlay Image Opacity</FormLabel>
              <Slider
                aria-label="slider-ex-1"
                defaultValue={value}
                onChange={onChange}
              >
                <SliderTrack>
                  <SliderFilledTrack />
                </SliderTrack>
                <SliderThumb />
              </Slider>
            </FormControl>
            <FormControl>
              <FormLabel>Grid Size</FormLabel>
              <NumberInput
                defaultValue={gridSize}
                min={2}
                max={8}
                onChange={(_, num) => setGridSize(num)}
              >
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            </FormControl>
            <FormControl>
              <FormLabel>Show Grid</FormLabel>
              <Switch
                aria-label="switch-ex-1"
                defaultChecked={showGrid}
                onChange={(e) => setShowGrid(e.target.checked)}
              ></Switch>
            </FormControl>
          </Stack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
}

export default SettingsPopover;
