import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetSmsTemplateQueryVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
}>;


export type GetSmsTemplateQuery = { __typename?: 'query_root', smsTemplate_by_pk?: { __typename?: 'smsTemplate', active: boolean, allowResend: boolean, resendWindow?: number | null | undefined, mmsImage?: string | null | undefined, name: string, sendDateTime?: any | null | undefined, smsBody: string, lastSent?: any | null | undefined, targetAudienceId?: any | null | undefined, state?: string | null | undefined, audience?: { __typename?: 'audience', type: string, snapshot: any, name?: string | null | undefined, definition: any } | null | undefined, marketingTags: Array<{ __typename?: 'marketingTagSms', marketingTag: { __typename?: 'marketingTag', id: any, title: string } }> } | null | undefined };


export const GetSmsTemplateDocument = gql`
    query GetSMSTemplate($id: uuid!) {
  smsTemplate_by_pk(id: $id) {
    active
    allowResend
    resendWindow
    mmsImage
    audience {
      type
      snapshot
      name
      definition
    }
    name
    sendDateTime
    smsBody
    lastSent
    targetAudienceId
    state
    marketingTags {
      marketingTag {
        id
        title
      }
    }
  }
}
    `;

/**
 * __useGetSmsTemplateQuery__
 *
 * To run a query within a React component, call `useGetSmsTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSmsTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSmsTemplateQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSmsTemplateQuery(baseOptions: Apollo.QueryHookOptions<GetSmsTemplateQuery, GetSmsTemplateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSmsTemplateQuery, GetSmsTemplateQueryVariables>(GetSmsTemplateDocument, options);
      }
export function useGetSmsTemplateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSmsTemplateQuery, GetSmsTemplateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSmsTemplateQuery, GetSmsTemplateQueryVariables>(GetSmsTemplateDocument, options);
        }
export type GetSmsTemplateQueryHookResult = ReturnType<typeof useGetSmsTemplateQuery>;
export type GetSmsTemplateLazyQueryHookResult = ReturnType<typeof useGetSmsTemplateLazyQuery>;
export type GetSmsTemplateQueryResult = Apollo.QueryResult<GetSmsTemplateQuery, GetSmsTemplateQueryVariables>;