import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { FlowsheetParameterFieldsMinimalFragmentDoc } from '../../fragments/flowsheet/FlowsheetParameterFieldsMinimal.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type InsertPatientFlowsheetDataMutationVariables = Types.Exact<{
  object: Types.PatientFlowsheetData_Insert_Input;
}>;


export type InsertPatientFlowsheetDataMutation = { __typename?: 'mutation_root', insert_patientFlowsheetData_one?: { __typename?: 'patientFlowsheetData', id: any, value: string, patient: { __typename?: 'patientWorkspace', firstName?: string | null | undefined, id: any, lastName?: string | null | undefined }, parameter: { __typename?: 'flowsheetParameter', apiKey: string, id: any, isSystemParameter: boolean, title: string, type: string, unit: string } } | null | undefined };


export const InsertPatientFlowsheetDataDocument = gql`
    mutation InsertPatientFlowsheetData($object: patientFlowsheetData_insert_input!) {
  insert_patientFlowsheetData_one(object: $object) {
    id
    patient {
      firstName
      id
      lastName
    }
    parameter {
      ...FlowsheetParameterFieldsMinimal
    }
    value
  }
}
    ${FlowsheetParameterFieldsMinimalFragmentDoc}`;
export type InsertPatientFlowsheetDataMutationFn = Apollo.MutationFunction<InsertPatientFlowsheetDataMutation, InsertPatientFlowsheetDataMutationVariables>;

/**
 * __useInsertPatientFlowsheetDataMutation__
 *
 * To run a mutation, you first call `useInsertPatientFlowsheetDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertPatientFlowsheetDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertPatientFlowsheetDataMutation, { data, loading, error }] = useInsertPatientFlowsheetDataMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertPatientFlowsheetDataMutation(baseOptions?: Apollo.MutationHookOptions<InsertPatientFlowsheetDataMutation, InsertPatientFlowsheetDataMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertPatientFlowsheetDataMutation, InsertPatientFlowsheetDataMutationVariables>(InsertPatientFlowsheetDataDocument, options);
      }
export type InsertPatientFlowsheetDataMutationHookResult = ReturnType<typeof useInsertPatientFlowsheetDataMutation>;
export type InsertPatientFlowsheetDataMutationResult = Apollo.MutationResult<InsertPatientFlowsheetDataMutation>;
export type InsertPatientFlowsheetDataMutationOptions = Apollo.BaseMutationOptions<InsertPatientFlowsheetDataMutation, InsertPatientFlowsheetDataMutationVariables>;