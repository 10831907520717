import { Stack, IconButton, Flex, Tooltip } from '@chakra-ui/react';
import { NavGroup, NavItem, SideBarWrapper } from '@webapp/ui';
import {
  BiWallet,
  BiUserPlus,
  BiUser,
  BiFace,
  BiChip,
  BiLogOut,
  BiHome,
  BiMap,
  BiFile,
  BiFileBlank,
  BiStore,
  BiCalendar,
  BiTag,
  BiReceipt,
  BiChevronLeft,
  BiChevronRight,
  BiDollarCircle,
} from 'react-icons/bi';
import { CgFileDocument } from 'react-icons/cg';
import { useStores } from '@webapp/state-models';
import { GoPackage } from 'react-icons/go';
import { IoShieldCheckmarkOutline } from 'react-icons/io5';
import { Link } from 'react-router-dom';
import {
  MdCreditCard,
  MdEditNotifications,
  MdOutlineCardGiftcard,
  MdOutlineInventory2,
  MdOutlinePin,
  MdOutlineSettings,
  MdOutlineSettingsInputComponent,
} from 'react-icons/md';
import { FaFileUpload, FaPlug, FaTable, FaWpforms } from 'react-icons/fa';
import { observer } from 'mobx-react-lite';
import {
  ADMIN,
  OWNER,
  PROVIDER,
  MARKETER,
  FRONT_DESK,
  MEDICAL_ASSISTANT,
} from '@webapp/constants';
import { RiCoupon3Line, RiSecurePaymentFill } from 'react-icons/ri';
import { useFlagsmith } from 'flagsmith-react';
import { FiInbox } from 'react-icons/fi';
import { useState } from 'react';
import { VH_MINUS_HEADER } from '@webapp/webapp/util-display';

export const SettingsSideBar = observer((): JSX.Element => {
  const { hasFeature } = useFlagsmith();
  const { logout, user } = useStores();
  const [isCollapsed, setIsCollapsed] = useState(false);

  const toggleCollapse = () => setIsCollapsed(!isCollapsed);

  return (
    <Flex
      h="full"
      bg="#fbfafa"
      color="white"
      fontSize="sm"
      direction="column"
      px="4"
      py="4"
      minWidth={isCollapsed ? '0' : '280'}
      maxH={VH_MINUS_HEADER}
    >
      <Stack spacing="2" flex="1" overflow="auto" position="relative">
        <Tooltip label={isCollapsed ? 'Expand menu' : 'Collapse menu'}>
          <IconButton
            position={isCollapsed ? 'relative' : 'absolute'}
            right={isCollapsed ? '0' : 4}
            top={0}
            aria-label={isCollapsed ? 'Expand menu' : 'Collapse menu'}
            icon={isCollapsed ? <BiChevronRight /> : <BiChevronLeft />}
            onClick={toggleCollapse}
            alignSelf="flex-end"
            size="sm"
          />
        </Tooltip>

        {!isCollapsed && (
          <>
            <NavGroup label="Workspace settings">
              <Link to="/settings/services">
                <NavItem icon={<BiWallet />} label="Catalog" />
              </Link>
              {[ADMIN, OWNER, PROVIDER].includes(user?.role as string) && (
                <Link to="/settings/smart-notes">
                  <NavItem icon={<CgFileDocument />} label="Smart notes" />
                </Link>
              )}
              {[OWNER].includes(user?.role as string) && (
                <Link to="/settings/security">
                  <NavItem
                    icon={<IoShieldCheckmarkOutline />}
                    label="Security"
                  />
                </Link>
              )}
              {[ADMIN, OWNER].includes(user?.role as string) && (
                <>
                  <Link to="/settings/team">
                    <NavItem icon={<BiUserPlus />} label="Team" />
                  </Link>
                  {hasFeature('compensation-strategy') && (
                    <Link to="/settings/compensation">
                      <NavItem
                        icon={<RiSecurePaymentFill />}
                        label="Compensation"
                      />
                    </Link>
                  )}
                  <Link to="/settings/providers">
                    <NavItem icon={<BiUser />} label="Providers" />
                  </Link>
                  {hasFeature('patient-portal:configuration') && (
                    <Link to="/settings/patients">
                      <NavItem icon={<BiUser />} label="Patient Portal" />
                    </Link>
                  )}
                  <Link to="/settings/locations">
                    <NavItem icon={<BiMap />} label="Locations" />
                  </Link>

                  {hasFeature('storefront') && (
                    <Link to="/settings/storefront">
                      <NavItem icon={<BiStore />} label="Storefront" />
                    </Link>
                  )}
                  <Link to="/settings/tags">
                    <NavItem icon={<BiTag />} label="Tags" />
                  </Link>
                  {hasFeature('import') && (
                    <Link to="/settings/import">
                      <NavItem icon={<FaFileUpload />} label="Import" />
                    </Link>
                  )}
                  <Link to="/settings/devices">
                    <NavItem icon={<BiChip />} label="Devices" />
                  </Link>

                  <Link to="/settings/invoices">
                    <NavItem icon={<BiReceipt />} label="Invoices" />
                  </Link>
                  {hasFeature('payments') && (
                    <Link to="/settings/payments">
                      <NavItem icon={<MdCreditCard />} label="Payments" />
                    </Link>
                  )}
                  <Link to="/settings/forms-and-consents/forms">
                    <NavItem icon={<BiFile />} label="Forms and consents" />
                  </Link>
                  <Link to="/settings/documents">
                    <NavItem icon={<BiFileBlank />} label="Pre & Post Care" />
                  </Link>
                  <Link to="/settings/pin">
                    <NavItem icon={<MdOutlinePin />} label="Pin" />
                  </Link>
                  <Link to="/settings/configuration">
                    <NavItem
                      icon={<MdOutlineSettings />}
                      label="Configuration"
                    />
                  </Link>
                  {hasFeature('memberships') && (
                    <Link to="/settings/memberships?archived=false">
                      <NavItem
                        icon={<MdOutlineCardGiftcard />}
                        label="Memberships"
                      />
                    </Link>
                  )}
                  {hasFeature('packages') && (
                    <Link to="/settings/packages">
                      <NavItem icon={<GoPackage />} label="Packages" />
                    </Link>
                  )}
                  {hasFeature('flowsheets') && (
                    <Link to="/settings/flowsheets">
                      <NavItem icon={<FaTable />} label="Flowsheets" />
                    </Link>
                  )}
                  <Link to="/settings/webhooks">
                    <NavItem icon={<FaPlug />} label="Webhooks" />
                  </Link>
                </>
              )}
              {[ADMIN, OWNER, PROVIDER, FRONT_DESK].includes(
                user?.role as string
              ) && (
                <Link to="/settings/coupons">
                  <NavItem icon={<RiCoupon3Line />} label="Coupons" />
                </Link>
              )}
              <Link to="/settings/forms-and-consents/forms">
                <NavItem icon={<BiFile />} label="Forms and consents" />
              </Link>
              <Link to="/settings/documents">
                <NavItem icon={<BiFileBlank />} label="Pre & Post Care" />
              </Link>
              <Link to="/settings/pin">
                <NavItem icon={<MdOutlinePin />} label="Pin" />
              </Link>
              <Link to="/settings/configuration">
                <NavItem
                  icon={<MdOutlineSettingsInputComponent />}
                  label="Configuration"
                />
              </Link>
              {hasFeature('memberships') && (
                <Link to="/settings/memberships?archived=false">
                  <NavItem
                    icon={<MdOutlineCardGiftcard />}
                    label="Memberships"
                  />
                </Link>
              )}
              {hasFeature('inventory_management') &&
                [
                  ADMIN,
                  OWNER,
                  PROVIDER,
                  FRONT_DESK,
                  MEDICAL_ASSISTANT,
                ].includes(user?.role as string) && (
                  <>
                    <Link to="/settings/inventory?tab=Details&archived=false">
                      <NavItem
                        icon={<MdOutlineInventory2 />}
                        label="Inventory"
                      />
                    </Link>
                    <Link to="/settings/inventory-categories">
                      <NavItem
                        icon={<FiInbox />}
                        label="Products and Vendors"
                      />
                    </Link>
                  </>
                )}
              {[ADMIN, OWNER, PROVIDER, MARKETER].includes(
                user?.role as string
              ) && (
                <Link to="/settings/leadform">
                  <NavItem icon={<FaWpforms />} label="Lead forms" />
                </Link>
              )}
              {hasFeature('rewards') &&
                [ADMIN, OWNER].includes(user?.role as string) && (
                  <Link to="/settings/rewards">
                    <NavItem icon={<BiDollarCircle />} label="Rewards" />
                  </Link>
                )}
              {/* TODO: leaving the below menu items commented, we will build them as we go */}
              {/* <NavItem icon={<BiBriefcase />} label="Business information" /> */}
              {/* <NavItem icon={<BiUserPlus />} label="Referral rules" /> */}
            </NavGroup>

            <NavGroup label="Account preferences">
              <Link to="/settings">
                <NavItem icon={<BiFace />} label="Account details" />
              </Link>
              {hasFeature('calendar:external-sync') && (
                <Link to="/settings/calendar-sync">
                  <NavItem icon={<BiCalendar />} label="Calendar Sync" />
                </Link>
              )}
              <Link to="/settings/notification-settings">
                <NavItem
                  icon={<MdEditNotifications />}
                  label="Notification Preferences"
                />
              </Link>
              {/* TODO: need to build */}
              {/* <NavItem icon={<BiEnvelope />} label="Notification preferences" /> */}
              <Link to="/">
                <NavItem icon={<BiHome />} label="Home" />
              </Link>
              <span onClick={logout}>
                <NavItem subtle icon={<BiLogOut />} label="Log out" />
              </span>
            </NavGroup>
          </>
        )}
      </Stack>
    </Flex>
  );
});

export default SettingsSideBar;
