import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type UpdateLocationsForServiceMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
  objects: Array<Types.LocationService_Insert_Input> | Types.LocationService_Insert_Input;
}>;


export type UpdateLocationsForServiceMutation = { __typename?: 'mutation_root', delete_locationService?: { __typename?: 'locationService_mutation_response', affected_rows: number } | null | undefined, insert_locationService?: { __typename?: 'locationService_mutation_response', affected_rows: number } | null | undefined };


export const UpdateLocationsForServiceDocument = gql`
    mutation updateLocationsForService($id: uuid!, $objects: [locationService_insert_input!]!) {
  delete_locationService(where: {serviceId: {_eq: $id}}) {
    affected_rows
  }
  insert_locationService(objects: $objects) {
    affected_rows
  }
}
    `;
export type UpdateLocationsForServiceMutationFn = Apollo.MutationFunction<UpdateLocationsForServiceMutation, UpdateLocationsForServiceMutationVariables>;

/**
 * __useUpdateLocationsForServiceMutation__
 *
 * To run a mutation, you first call `useUpdateLocationsForServiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLocationsForServiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLocationsForServiceMutation, { data, loading, error }] = useUpdateLocationsForServiceMutation({
 *   variables: {
 *      id: // value for 'id'
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useUpdateLocationsForServiceMutation(baseOptions?: Apollo.MutationHookOptions<UpdateLocationsForServiceMutation, UpdateLocationsForServiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateLocationsForServiceMutation, UpdateLocationsForServiceMutationVariables>(UpdateLocationsForServiceDocument, options);
      }
export type UpdateLocationsForServiceMutationHookResult = ReturnType<typeof useUpdateLocationsForServiceMutation>;
export type UpdateLocationsForServiceMutationResult = Apollo.MutationResult<UpdateLocationsForServiceMutation>;
export type UpdateLocationsForServiceMutationOptions = Apollo.BaseMutationOptions<UpdateLocationsForServiceMutation, UpdateLocationsForServiceMutationVariables>;