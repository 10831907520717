import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type InsertTagMutationVariables = Types.Exact<{
  object: Types.Tag_Insert_Input;
}>;


export type InsertTagMutation = { __typename?: 'mutation_root', insert_tag_one?: { __typename?: 'tag', color?: string | null | undefined, id: any, title: string } | null | undefined };


export const InsertTagDocument = gql`
    mutation InsertTag($object: tag_insert_input!) {
  insert_tag_one(
    object: $object
    on_conflict: {constraint: tag_workspaceId_title_type_key, update_columns: []}
  ) {
    color
    id
    title
  }
}
    `;
export type InsertTagMutationFn = Apollo.MutationFunction<InsertTagMutation, InsertTagMutationVariables>;

/**
 * __useInsertTagMutation__
 *
 * To run a mutation, you first call `useInsertTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertTagMutation, { data, loading, error }] = useInsertTagMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertTagMutation(baseOptions?: Apollo.MutationHookOptions<InsertTagMutation, InsertTagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertTagMutation, InsertTagMutationVariables>(InsertTagDocument, options);
      }
export type InsertTagMutationHookResult = ReturnType<typeof useInsertTagMutation>;
export type InsertTagMutationResult = Apollo.MutationResult<InsertTagMutation>;
export type InsertTagMutationOptions = Apollo.BaseMutationOptions<InsertTagMutation, InsertTagMutationVariables>;