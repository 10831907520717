/* eslint-disable camelcase */
import { MembershipType_Enum } from '@webapp/graphql';
import toast from 'react-hot-toast';
import { InvoiceFormValues } from './types';

export const submitInvoiceWrapper = (
  values: InvoiceFormValues,
  callback: (values: InvoiceFormValues) => void
) => {
  if (
    !values.services?.length &&
    !values.memberships?.length &&
    !values.packages?.length
  ) {
    toast.error('Please add at least one service, membership, or package');
    return;
  }
  if (!values?.patient) {
    toast.error('Please select a client');
    return;
  }

  if (!values.location) {
    toast.error('Please select a location');
    return;
  }

  if (values.memberships?.length) {
    const { memberships } = values;

    const bsaMemberships = memberships.filter(
      (membership) => membership.object.type === MembershipType_Enum.Bsa
    );

    if (bsaMemberships.length > 1) {
      toast.error('Only one BSA membership per invoice');
      return;
    }
  }

  callback(values);
};

export default submitInvoiceWrapper;
