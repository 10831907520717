/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { ApolloClient, useApolloClient } from '@apollo/client';
import { Stack, Box, Button } from '@chakra-ui/react';
import {
  GetSignatureQuery,
  GetSignatureQueryVariables,
  GetSignatureDocument,
} from '@webapp/graphql';
import { loadExistingSignature } from '@webapp/utils';
import { Dispatch, MutableRefObject, SetStateAction } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import { SizeMe } from 'react-sizeme';

const clearSignature = (signatureCanvas: SignatureCanvas): void => {
  signatureCanvas.clear();
};

const fetchAndLoadSignature = async (
  signatureCanvas: SignatureCanvas,
  signatureId: string,
  fetchedSignatures: { [x: string]: boolean },
  client: ApolloClient<object>,
  setFetchedSignatures: Dispatch<
    SetStateAction<{
      [x: string]: boolean;
    }>
  >
): Promise<void> => {
  if (!signatureId) return;
  if (!signatureCanvas.isEmpty()) return;
  if (fetchedSignatures[signatureId]) return;

  const localFetched = { ...fetchedSignatures };

  localFetched[signatureId] = true;

  setFetchedSignatures(localFetched);

  const { data } = await client.query<
    GetSignatureQuery,
    GetSignatureQueryVariables
  >({
    query: GetSignatureDocument,
    variables: {
      id: signatureId,
    },
  });

  const url = data?.signature_by_pk?.file?.url;

  if (!url) return;

  loadExistingSignature(signatureCanvas, url);
};

interface PopulatedCustomFormSignatureCanvasProps {
  fieldId: string;
  signatureRefs: MutableRefObject<{
    [x: string]: SignatureCanvas;
  }>;
  fetchedSignatures: { [x: string]: boolean };
  setFetchedSignatures: Dispatch<
    SetStateAction<{
      [x: string]: boolean;
    }>
  >;
  defaultValue: any;
}

export const PopulatedCustomFormSignatureCanvas = ({
  fieldId,
  signatureRefs,
  fetchedSignatures,
  setFetchedSignatures,
  defaultValue,
}: PopulatedCustomFormSignatureCanvasProps): JSX.Element => {
  const client = useApolloClient();

  return (
    <Stack width="fill-content">
      <Box border="1px solid black" borderRadius="15px">
        <SizeMe>
          {({ size }) => (
            <SignatureCanvas
              canvasProps={{
                width: size.width - 2,
                height: size.height - 2,
              }}
              ref={(el) => {
                if (!el) return;
                if (signatureRefs.current[fieldId]) return;

                signatureRefs.current[fieldId] = el;
                fetchAndLoadSignature(
                  el,
                  defaultValue,
                  fetchedSignatures,
                  client,
                  setFetchedSignatures
                );
              }}
            />
          )}
        </SizeMe>
      </Box>
      <Button onClick={() => clearSignature(signatureRefs.current[fieldId])}>
        Clear
      </Button>
    </Stack>
  );
};

export default PopulatedCustomFormSignatureCanvas;
