import React from 'react';
import { Box, Text, VStack, css } from '@chakra-ui/react';
import { compile } from 'handlebars';
import { useInvoicePreviewContext } from './InvoicePreviewContext';

export const InvoicePreviewFooter: React.FC = React.memo(() => {
  const { invoice } = useInvoicePreviewContext();

  const invoiceMessageTemplate = compile(
    invoice.workspace?.workspaceConfiguration?.invoiceMessage ?? ''
  );

  return (
    <>
      {/* Invoice Message */}
      <Box
        borderWidth={1}
        borderColor="gray.200"
        borderRadius="md"
        p={4}
        css={css({
          '@media print': {
            borderWidth: '1px',
            borderColor: 'gray.200',
            borderRadius: 'md',
            p: 4,
          },
        })}
      >
        <Text
          fontStyle="italic"
          fontSize={{ base: 'xs', sm: 'sm', md: 'md' }}
          css={css({ '@media print': { fontSize: 'md' } })}
        >
          {invoice.message ||
            invoiceMessageTemplate({
              location: invoice.location,
              provider: invoice.provider,
              cashier: invoice.cashier,
            })}
        </Text>
      </Box>

      {/* Contact Information */}
      <VStack
        spacing={2}
        pt={4}
        borderTopWidth={1}
        borderTopColor="gray.200"
        align={{ base: 'stretch', md: 'flex-start' }}
        css={css({
          '@media print': {
            borderTopWidth: '1px',
            borderTopColor: 'gray.200',
            alignItems: 'flex-start',
          },
        })}
      >
        <Text
          fontSize={{ base: 'xs', sm: 'sm', md: 'md' }}
          css={css({ '@media print': { fontSize: 'md' } })}
        >
          Email: {invoice.location.email}
        </Text>
        <Text
          fontSize={{ base: 'xs', sm: 'sm', md: 'md' }}
          css={css({ '@media print': { fontSize: 'md' } })}
        >
          Tel: {invoice.location.phoneNumber}
        </Text>
      </VStack>
    </>
  );
});

export default InvoicePreviewFooter;
