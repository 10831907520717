import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { DocumentFieldsFragmentDoc } from '../../fragments/documentFields.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetDocumentByPkQueryVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
}>;


export type GetDocumentByPkQuery = { __typename?: 'query_root', document_by_pk?: { __typename?: 'document', id: any, title?: string | null | undefined, filePath: string, documentType: string, documentTags: Array<{ __typename?: 'documentTag', id: any, tag: { __typename?: 'tag', color?: string | null | undefined, id: any, title: string } }>, file?: { __typename?: 'FileOnlyURL', url: string } | null | undefined } | null | undefined };


export const GetDocumentByPkDocument = gql`
    query GetDocumentByPk($id: uuid!) {
  document_by_pk(id: $id) {
    ...DocumentFields
  }
}
    ${DocumentFieldsFragmentDoc}`;

/**
 * __useGetDocumentByPkQuery__
 *
 * To run a query within a React component, call `useGetDocumentByPkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDocumentByPkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDocumentByPkQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetDocumentByPkQuery(baseOptions: Apollo.QueryHookOptions<GetDocumentByPkQuery, GetDocumentByPkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDocumentByPkQuery, GetDocumentByPkQueryVariables>(GetDocumentByPkDocument, options);
      }
export function useGetDocumentByPkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDocumentByPkQuery, GetDocumentByPkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDocumentByPkQuery, GetDocumentByPkQueryVariables>(GetDocumentByPkDocument, options);
        }
export type GetDocumentByPkQueryHookResult = ReturnType<typeof useGetDocumentByPkQuery>;
export type GetDocumentByPkLazyQueryHookResult = ReturnType<typeof useGetDocumentByPkLazyQuery>;
export type GetDocumentByPkQueryResult = Apollo.QueryResult<GetDocumentByPkQuery, GetDocumentByPkQueryVariables>;