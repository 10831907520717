/* eslint-disable no-plusplus */
/* eslint-disable no-await-in-loop */
import {
  AggregateDocumentsDocument,
  ListDocumentsDocument,
  OrderBy,
  StorageType,
  useInsertDocumentMutation,
} from '@webapp/graphql';
import { useStores } from '@webapp/state-models';
import { useUploadToS3 } from '@webapp/hooks';
import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import toast from 'react-hot-toast';
import DropAndUploadModal from '../drop-and-upload-modal';

export interface CreatePatientDocumentModalProps {
  appointmentId?: string;
  defaultValues?: {
    title?: string;
  };
  onClose: () => void;
  isOpen: boolean;
  patientId: string;
}

function CreatePatientDocumentModal({
  appointmentId,
  defaultValues = {},
  isOpen,
  onClose,
  patientId,
}: CreatePatientDocumentModalProps) {
  const { workspace } = useStores();
  const { uploadToS3 } = useUploadToS3();
  const [progress, setProgress] = useState<number>(0);

  const {
    title: titleFromProps = `Unknown Document: ${dayjs().format('MM/DD/YYYY')}`,
  } = defaultValues;

  const [insertDocument] = useInsertDocumentMutation({
    onCompleted: () => {
      toast.success('Patient Document uploaded successfully');
    },
    onError: (e) => {
      toast.error(e.message);
    },
    refetchQueries: [
      'GetOnePatient',
      'ListDocuments',
      'AggregateDocuments',
      {
        query: AggregateDocumentsDocument,
        variables: {
          where: {
            patientId: {
              _eq: patientId,
            },
          },
        },
      },
      {
        query: ListDocumentsDocument,
        variables: {
          orderBy: [
            {
              createdAt: OrderBy.Desc,
            },
          ],
          offset: 0,
          page: 1,
          where: {
            patientId: {
              _eq: patientId,
            },
          },
        },
      },
    ],
  });

  const onSubmit = async (
    files: File[],
    title?: string,
    docType?: string,
    tags?: {
      tagId: string;
    }[]
  ): Promise<void> => {
    if (!files.length) throw new Error('No files selected');

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const filePath = await uploadToS3({
        fileType: file.type,
        fileContents: file,
        filePath: file.name,
        storageType: StorageType.Document,
        onProgress: setProgress,
        randomizeFileName: true,
      });

      await insertDocument({
        variables: {
          document: {
            ...(tags?.length && { documentTags: { data: tags } }),
            title: file?.name,
            filePath,
            workspaceId: workspace?.id,
            patientId,
            appointmentId,
          },
        },
      });
    }
  };

  return (
    <DropAndUploadModal
      defaultValues={{
        title: titleFromProps,
      }}
      hasTitle
      isOpen={isOpen}
      onClose={onClose}
      onSubmitUpload={onSubmit}
      progress={progress}
      setProgress={setProgress}
    />
  );
}

export default observer(CreatePatientDocumentModal);
