import {
  InputGroup,
  InputLeftElement,
  Input,
  InputGroupProps,
} from '@chakra-ui/react';
import { debounce } from 'lodash';
import { useEffect } from 'react';
import { HiSearch } from 'react-icons/hi';
import { useSearchParams } from 'react-router-dom';

export const SearchInput = ({
  placeholder,
  containerStyle,
}: {
  placeholder?: string;
  containerStyle?: InputGroupProps;
}): JSX.Element => {
  const [searchParams, setSearchParams] = useSearchParams();
  const currentSearch = searchParams.get('search') ?? '';

  useEffect(() => {
    // TODO: Determine how to clear this in the callback handler
    if (searchParams.has('search')) {
      const search = searchParams.get('search');
      if (!search) {
        searchParams.delete('search');
        setSearchParams(searchParams);
      }
    }
  }, [currentSearch]);

  return (
    <InputGroup {...containerStyle}>
      <InputLeftElement
        pointerEvents="none"
        children={<HiSearch color="rgba(28,28,28, .38)" />}
      />
      <Input
        autoFocus
        fontSize="14px"
        onChange={debounce((e) => {
          if (e.target.value?.length) {
            setSearchParams({ search: e?.target?.value });
          } else {
            setSearchParams({ search: '' });
          }
        }, 500)}
        defaultValue={currentSearch}
        type="text"
        placeholder={placeholder ?? 'Search...'}
        variant="filled"
      />
    </InputGroup>
  );
};

export default SearchInput;
