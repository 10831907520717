/* eslint-disable no-inner-declarations */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable func-names */
/* eslint-disable camelcase */
import {
  flow,
  getRoot,
  IMSTArray,
  Instance,
  ISimpleType,
  SnapshotOut,
  types,
} from 'mobx-state-tree';
import {
  Service,
  LocationFieldsFragment,
  PatientFieldsFragment,
  GraphqlClient,
  InsertAppointmentDocument,
  UpdateAppointmentDocument,
  Appointment_Insert_Input,
  AvailableDevicesForServiceDocument,
  Device,
  InternalAppointmentProvider,
  UpdateInternalAppointmentProvidersDocument,
  UpdateAppointmentServicesDocument,
  ProviderFieldsFragment,
  RoomFieldsFragment,
  ListAppointmentsDocument,
} from '@webapp/graphql';
import { getErrorMessage } from '@webapp/utils';

import toast from 'react-hot-toast';
import { configuredDayjs as dayjs } from '@webapp/util-time';
import { PatientsFromQuery } from '@webapp/types';
import { formatName } from '@webapp/util-formatting';
import { RootStore } from '../root-store';
import { LocationModel } from './location';
import { PatientModel } from './patient';
import { NoteModel } from './note';
import { ProviderModel } from './provider';
import {
  AppointmentServiceModel,
  AppointmentService,
} from './appointmentService';
import { ConflictModel } from './conflict';
import { DeviceTypeModel } from './device-type';

const model = types
  .model('Appointment')
  .props({
    approvedNoShowFeeAmount: types.optional(types.number, 0),
    canCollectNoShowFee: types.maybeNull(types.boolean),
    colorOverride: types.maybeNull(types.string),
    conflicts: types.maybeNull(types.array(ConflictModel)),
    date: types.maybeNull(types.string),
    disableConfirmationsReminders: types.optional(types.boolean, false),
    endTime: types.maybeNull(types.string),
    externalId: types.maybeNull(types.string),
    id: types.string,
    internalAppointmentProviderIds: types.optional(
      types.array(types.string),
      []
    ),
    internalAppointmentProviders: types.optional(
      types.array(ProviderModel),
      []
    ),
    isFirstAppointmentWithProvider: types.optional(types.boolean, false),
    isMembershipActive: types.maybeNull(types.boolean),
    isMembershipTypeSubscription: types.maybeNull(types.boolean),
    isNewPatient: types.maybeNull(types.boolean),
    isRecurring: types.maybeNull(types.boolean),
    loading: types.optional(types.boolean, false),
    location: types.maybeNull(LocationModel),
    note: types.maybeNull(types.string),
    notes: types.optional(types.array(NoteModel), []),
    noShowFeePaymentMethodId: types.maybeNull(types.string),
    noShowFeeStatus: types.maybeNull(types.string),
    originalRecurringRule: types.maybeNull(types.string),
    patient: types.maybeNull(PatientModel),
    provider: types.maybeNull(ProviderModel),
    providerId: types.maybeNull(types.string),
    roomId: types.maybeNull(types.string),
    recurringEndDate: types.maybeNull(types.string),
    recurringExceptionDates: types.optional(types.array(types.string), []),
    recurringExceptionRule: types.maybeNull(types.string),
    recurringRule: types.maybeNull(types.string),
    recurringStartDate: types.maybeNull(types.string),
    services: types.optional(types.array(AppointmentServiceModel), []),
    startTime: types.maybeNull(types.string),
    status: types.maybeNull(types.string),
    timeHasChanged: types.optional(types.boolean, false),
    timerange: types.maybeNull(types.string),
    type: types.optional(types.string, 'patient_appointment'),
    workspaceId: types.maybeNull(types.string),
  })
  .views((self) => ({
    get color() {
      return self.services?.length > 0
        ? self.services[0].service?.color ||
            self.services[0]?.service?.appointmentType?.color
        : null;
    },
    get room() {
      if (!self.roomId) return null;
      return self.location?.rooms?.find((r) => r.id === self.roomId);
    },
    get currentDuration() {
      if (self.timerange) {
        const timerange = JSON.parse(self.timerange);
        const end = dayjs(timerange[1]);
        const start = dayjs(timerange[0]);
        return end.diff(start, 'minutes');
      }
      return 0;
    },
    get totalDuration() {
      return self.services.reduce((acc, service) => {
        if (
          !service.hasBookedBefore &&
          service?.service?.newPatientMinutesToPerform
        ) {
          const addedMinutes = service?.serviceMinutesOverride
            ? service.serviceMinutesOverride
            : service.service.newPatientMinutesToPerform;
          return acc + addedMinutes;
        }
        return (
          acc +
          (service?.serviceMinutesOverride ||
            service?.service?.minutesToPerform ||
            0)
        );
      }, 0);
    },
    get timeDayjs() {
      return dayjs(`${self.startTime}`, 'YYYY-MM-DD hh:mm A');
    },
    get deposit() {
      return self.services.reduce((acc, service) => {
        if (!service.service?.requiresDeposit) return acc;
        return acc + (service.service?.deposit ?? 0);
      }, 0);
    },
    get timezone(): string {
      return (getRoot(self) as RootStore).calendarTimeZone;
    },
  }))
  .actions((self) => ({
    selectPrimaryProvider(
      appointmentServices: AppointmentService[]
    ): string | null {
      const providerDurations: { [providerId: string]: number } = {};

      for (const appointmentService of appointmentServices) {
        const startTime = JSON.parse(appointmentService.timerange || '')[0];
        const endTime = JSON.parse(appointmentService.timerange || '')[1];
        const duration = dayjs(endTime).diff(dayjs(startTime), 'milliseconds');

        if (
          appointmentService.providerId &&
          providerDurations[appointmentService.providerId]
        ) {
          providerDurations[appointmentService.providerId] += duration;
        } else if (appointmentService.providerId) {
          providerDurations[appointmentService.providerId] = duration;
        }
      }

      let primaryProviderId: string | null = null;
      let longestDuration = -1;

      for (const providerId in providerDurations) {
        if (providerDurations[providerId] > longestDuration) {
          longestDuration = providerDurations[providerId];
          primaryProviderId = providerId;
        }
      }

      return primaryProviderId;
    },
    checkDeviceAvailability: flow(function* checkDeviceAvailability(
      serviceId: string
    ) {
      const client = GraphqlClient();
      const appointmentService = self.services.find(
        (service) => service.serviceId === serviceId
      );

      if (
        !appointmentService ||
        appointmentService.requiredDeviceTypes.length === 0
      ) {
        return;
      }
      let { endTime } = self;

      if (!endTime) {
        endTime = dayjs(`${self.startTime}`, 'YYYY-MM-DD hh:mm A')
          .add(self.totalDuration, 'minute')
          .format('HH:mm');
      } else {
        endTime = dayjs(endTime).format('HH:mm');
      }

      const { data } = yield client.query({
        query: AvailableDevicesForServiceDocument,
        variables: {
          serviceId,
          locationId: self.location?.id,
          day: dayjs(self.startTime).format('YYYY-MM-DD'),
          startTime: dayjs(self.startTime).format('HH:mm'),
          endTime,
        },
      });
      appointmentService.setAvailableDevices(
        data.availableDevicesForService.map(
          ({ device }: { device: Device }) => device
        )
      );
    }),
    calculateServiceTimeRanges() {
      const sortedServices = self.services.sort((a, b) => a.order - b.order);
      sortedServices.forEach((service, index) => {
        if (
          !service.hasBookedBefore &&
          service?.service?.newPatientMinutesToPerform
        ) {
          service.setServiceDurationMinutes(
            service?.serviceMinutesOverride
              ? service.serviceMinutesOverride
              : service.service.newPatientMinutesToPerform
          );
        } else {
          service.setServiceDurationMinutes(
            service.serviceMinutesOverride
              ? service.serviceMinutesOverride
              : service.service?.minutesToPerform || 0
          );
        }
        if (index === 0) {
          service.startTime = self.startTime;
          service.endTime = dayjs(self.startTime)
            .add(service.serviceDurationMinutes || 0, 'minute')
            .format();
        } else {
          service.startTime = sortedServices[index - 1].endTime;
          service.endTime = dayjs(service.startTime)
            .utc()
            .add(service.serviceDurationMinutes || 0, 'minute')
            .format();
        }
        self.endTime = dayjs(service.endTime).toISOString();
        service.setTimeRange();
      });
    },
  }))
  .actions((self) => ({
    setInternalAppointmentProviderIds(providerIds: string[]) {
      self.internalAppointmentProviderIds = providerIds as IMSTArray<
        typeof types.string
      >;
    },
    setStartTime(startTime: string) {
      self.startTime = startTime;

      self.timeHasChanged = true;
      self.calculateServiceTimeRanges();
    },
    setEndTime(endTime: string) {
      self.endTime = endTime;
      self.timeHasChanged = true;
    },
    setNote(note: string) {
      self.note = note;
    },
    setColor(color: string) {
      self.colorOverride = color;
    },
    setDisableConfirmationsReminders(disable: boolean) {
      self.disableConfirmationsReminders = disable;
    },
    setPatient(
      patient: PatientFieldsFragment | PatientsFromQuery | undefined | null
    ) {
      self.patient = patient ? PatientModel.create(patient) : null;
    },
    setLocation(location: LocationFieldsFragment) {
      self.location = location ? LocationModel.create(location) : null;
    },
    setRoom(room: RoomFieldsFragment) {
      self.roomId = room.id;
    },
    setProvider(provider: ProviderFieldsFragment) {
      self.provider = provider ? ProviderModel.create(provider) : null;
    },
    setNoShowFeePaymentMethodId(paymentMethodId: string) {
      self.noShowFeePaymentMethodId = paymentMethodId;
    },
    setNoShowFeeStatus(status: string) {
      self.noShowFeeStatus = status;
    },
    setApprovedNoShowFeeAmount(amount: number) {
      self.approvedNoShowFeeAmount = amount;
    },
    setIsRecurring(isRecurring: boolean) {
      self.isRecurring = isRecurring;
    },
    setOriginalRecurringRule(recurringRule: string) {
      self.originalRecurringRule = recurringRule;
    },
    setRecurringRule(recurringRule: string) {
      self.recurringRule = recurringRule;
    },
    setRecurringStartDate(recurringStartDate: string) {
      self.recurringStartDate = recurringStartDate;
    },
    setRecurringEndDate(recurringEndDate: string) {
      self.recurringEndDate = recurringEndDate;
    },
    setRecurringExceptionRule(recurringExceptionRule: string) {
      self.recurringExceptionRule = recurringExceptionRule;
    },
    unsetRecurence() {
      self.isRecurring = false;
      self.recurringRule = null;
      self.recurringStartDate = null;
      self.recurringEndDate = null;
    },
    addService: flow(function* (service: Service, order: number) {
      const params: Partial<AppointmentService> = {
        appointmentId: self.id,
        serviceId: service.id,
        hasBookedBefore: true,
        service: service as any,
        order,
      };
      self.loading = true;
      if (service.serviceDeviceTypes.length > 0) {
        params.requiredDeviceTypes = service.serviceDeviceTypes.map((dt) =>
          DeviceTypeModel.create(dt.deviceType)
        ) as IMSTArray<typeof DeviceTypeModel>;
      }
      try {
        const client = GraphqlClient();
        const { data, loading } = yield client.query({
          query: ListAppointmentsDocument,
          variables: {
            where: {
              patientId: { _eq: self.patient?.id },
              appointmentServices: {
                serviceId: { _in: [service.id] },
              },
            },
          },
        });
        self.loading = loading;
        if (data.appointment.length === 0) {
          params.hasBookedBefore = false;
        }
      } catch (err) {
        console.log(err);
      }
      self.services.push(AppointmentServiceModel.create(params));
      self.checkDeviceAvailability(service.id);
      self.calculateServiceTimeRanges();
    }),

    replaceService: flow(function* (service: Service, index: number) {
      self.services[index] = AppointmentServiceModel.create({
        id: self.services[index].id,
        service,
        serviceId: service.id,
        order: self.services[index].order,
        hasBookedBefore: true,
      });
      if (service.serviceDeviceTypes.length > 0) {
        self.services[index].requiredDeviceTypes =
          service.serviceDeviceTypes.map((dt) =>
            DeviceTypeModel.create(dt.deviceType)
          ) as IMSTArray<typeof DeviceTypeModel>;
      }
      try {
        const client = GraphqlClient();
        const { data, loading } = yield client.query({
          query: ListAppointmentsDocument,
          variables: {
            where: {
              patientId: { _eq: self.patient?.id },
              appointmentServices: {
                serviceId: { _in: [service.id] },
              },
            },
          },
        });
        self.loading = loading;
        if (data.appointment.length === 0) {
          self.services[index].hasBookedBefore = false;
        }
      } catch (err) {
        console.log(err);
      }
      self.checkDeviceAvailability(service.id);
      self.calculateServiceTimeRanges();
    }),
    removeService(service: AppointmentService) {
      if (self.id !== 'DRAFT') {
        self.services[service.order || 0].deleteService();
      }
      self.services.remove(service);
    },
    setType(type: string) {
      self.type = type;
    },
    create: flow(function* ({
      isDraft = false,
      shouldRefetch = true,
    }: { isDraft?: boolean; shouldRefetch?: boolean } = {}) {
      const client = GraphqlClient();
      self.calculateServiceTimeRanges();
      const services = self.services?.map((service) => ({
        serviceId: service.serviceId,
        type: service.type,
        deviceId: service.deviceId,
        serviceDurationMinutes: service.serviceDurationMinutes,
        serviceMinutesOverride: service.serviceMinutesOverride,
        providerId: service.providerId,
        order: service.order,
        timerange: service.timerange,
      }));
      const appointment: any = {
        type: self.type,
        isDraft,
        patientId: self.patient?.id,
        locationId: self.location?.id,
        note: self.note,
        disableConfirmationsReminders: self.disableConfirmationsReminders,
        roomId: self.roomId,
        timerange: self.endTime
          ? JSON.stringify([self.startTime, self.endTime])
          : JSON.stringify([
              self.startTime,
              dayjs(self.startTime)
                .add(self.totalDuration, 'minute')
                .toISOString(),
            ]),
        appointmentServices: {
          data: services,
        },
        noShowFeePaymentMethodId: self.noShowFeePaymentMethodId,
        approvedNoShowFeeAmount: self.approvedNoShowFeeAmount,
        noShowFeeStatus: self.noShowFeeStatus,
        ...(self.colorOverride && { color: self.colorOverride }),
        ...(self.internalAppointmentProviderIds.length > 0 && {
          internalAppointmentProviders: {
            data: self.internalAppointmentProviderIds.map((id) => ({
              providerId: id,
            })),
          },
        }),
        ...(self.isRecurring && { isRecurring: self.isRecurring }),
        ...(self.recurringRule && { recurringRule: self.recurringRule }),
        ...(self.isRecurring &&
          self.startTime && {
            recurringStartDate: dayjs(self.startTime).format(),
          }),
        ...(self.recurringEndDate && {
          recurringEndDate: self.recurringEndDate,
        }),
        ...(self.recurringExceptionRule && {
          recurringExceptionRule: self.recurringExceptionRule,
        }),
      };

      appointment.providerId = self.selectPrimaryProvider(
        services as AppointmentService[]
      );
      self.loading = true;

      // TODO: validation here
      try {
        const { data } = yield client.mutate({
          mutation: InsertAppointmentDocument,
          variables: { appointment },
          ...(shouldRefetch && {
            refetchQueries: [
              'listAppointmentsSchedule',
              'GetOnePatient',
              'listAppointments',
            ],
          }),
        });

        (getRoot(self) as RootStore).addCachedAppointment(
          data?.insert_appointment.returning[0]
        );

        function figureOutProviders(
          type: string,
          internalAppointmentProviders: { provider: ProviderFieldsFragment }[],
          provider: ProviderFieldsFragment | null | undefined
        ) {
          if (type === 'patient_appointment') {
            return provider ? [provider?.id] : ['No Provider'];
          }
          return internalAppointmentProviders?.map((iap) => iap.provider.id);
        }

        const {
          id,
          services: appointmentServices,
          timerange,
          note,
          notes,
          type,
          internalAppointmentProviders,
          provider,
          patient,
          photos_aggregate,
          resolvedAppointmentConsents,
          resolvedAppointmentCustomForms,
        } = data?.insert_appointment?.returning[0];

        let intakeStatus;
        if (type !== 'other') {
          if (
            (!resolvedAppointmentConsents?.consents ||
              resolvedAppointmentConsents.consents.length === 0) &&
            (!resolvedAppointmentCustomForms?.customForms ||
              resolvedAppointmentCustomForms.customForms.length === 0)
          ) {
            intakeStatus = 'NOT_REQUIRED';
          } else {
            const isIntakeComplete =
              (!resolvedAppointmentConsents?.outstanding ||
                resolvedAppointmentConsents.outstanding.length === 0) &&
              (!resolvedAppointmentCustomForms?.outstanding ||
                resolvedAppointmentCustomForms.outstanding.length === 0);
            intakeStatus = isIntakeComplete ? 'COMPLETE' : 'INCOMPLETE';
          }
        }

        (getRoot(self) as RootStore).addCalendarEvent({
          id,
          appointmentType:
            appointmentServices?.length > 0
              ? appointmentServices[0].service.appointmentType?.name
              : 'non-service',
          start: dayjs
            .utc(JSON.parse(timerange)[0], 'YYYY-MM-DD H:mm:ss+Z')
            .toISOString(),
          end: dayjs
            .utc(JSON.parse(timerange)[1], 'YYYY-MM-DD H:mm:ss+Z')
            .toISOString(),
          resource: figureOutProviders(
            type,
            internalAppointmentProviders,
            provider
          ) as IMSTArray<ISimpleType<string>>,
          title:
            type === 'patient_appointment'
              ? `${formatName(patient?.attributes)} - ${dayjs(
                  dayjs
                    .utc(JSON.parse(timerange)[0], 'YYYY-MM-DD H:mm:ss')
                    .toISOString()
                ).format('h:mm A')}`
              : note,
          color:
            appointmentServices?.length > 0
              ? appointmentServices[0].service?.color ||
                appointmentServices[0].service.appointmentType?.color
              : null,
          hasPhotos: false,
          notesSigned: false,
          consumablesRecorded: false,
          ...(intakeStatus && { intakeStatus }),
        });
        if (
          !(getRoot(self) as RootStore).calendarResources.find(
            (r) => r.id === (self.provider ? self.provider.id : 'No Provider')
          )
        ) {
          (getRoot(self) as RootStore).addCalendarResource({
            id: self.provider ? self.provider.id : 'No Provider',
            name: self.provider
              ? `${self.provider.firstName} ${self.provider.lastName}`
              : 'No Provider',
            color: null,
          });
        }

        return data?.insert_appointment.returning[0];
      } catch (err) {
        toast.error(getErrorMessage(err));
      }
      self.loading = false;
    }),
    update: flow(function* (shouldUpdateAppointmentTimeRange = true) {
      const client = GraphqlClient();
      const updatePromises = [];
      const appointmentDuration = shouldUpdateAppointmentTimeRange
        ? self.totalDuration
        : self.currentDuration;
      const appointmentUpdates: Appointment_Insert_Input = {
        patientId: self.patient?.id,
        locationId: self.location?.id,
        roomId: self.roomId,
        ...(self.colorOverride && { color: self.colorOverride }),
        timerange:
          self.endTime && self.type === 'other'
            ? JSON.stringify([self.startTime, self.endTime])
            : JSON.stringify([
                self.startTime,
                dayjs(self.startTime)
                  .add(appointmentDuration, 'minute')
                  .format(),
              ]),
        note: self.note,
        isRecurring: self.isRecurring,
        recurringRule: self.recurringRule,
        recurringEndDate: self.recurringEndDate,
        recurringExceptionRule: self.recurringExceptionRule,
      };

      if (self.internalAppointmentProviderIds.length > 0) {
        updatePromises.push(
          client.mutate({
            mutation: UpdateInternalAppointmentProvidersDocument,
            variables: {
              where: {
                appointmentId: { _eq: self.id },
              },
              objects: self.internalAppointmentProviderIds.map((id) => ({
                providerId: id,
                appointmentId: self.id,
              })),
            },
          })
        );
      }

      self.loading = true;
      try {
        if (self.services && self.services.length !== 0) {
          self.calculateServiceTimeRanges();
          yield client.mutate({
            mutation: UpdateAppointmentServicesDocument,
            variables: {
              object: {
                appointmentId: self.id,
                services: self.services,
                shouldUpdateAppointmentTimeRange,
              },
            },
          });
        }

        yield client.mutate({
          mutation: UpdateAppointmentDocument,
          variables: {
            id: self.id,
            set: appointmentUpdates,
          },
        });
        const root = getRoot(self) as RootStore;
        if (root) {
          root.loadAppointments();
        }

        toast.success(`Appointment updated!`);
      } catch (err) {
        toast.error(getErrorMessage(err));
      }
      self.loading = false;
    }),
    updateStatus: flow(function* updateStatus(newStatus: string) {
      try {
        const client = GraphqlClient();
        self.status = newStatus;
        yield client.mutate({
          mutation: UpdateAppointmentDocument,
          variables: {
            id: self.id,
            set: {
              status: newStatus,
            },
          },
          refetchQueries: ['listAppointmentsSchedule'],
        });
        const root = getRoot(self) as RootStore;
        if (root) {
          root.loadAppointments();
        }
        toast.success(`Appointment status updated!`);
      } catch (err) {
        toast.error(getErrorMessage(err));
      }
    }),
    updateRoom: flow(function* updateRoom(newRoomId: string) {
      try {
        const client = GraphqlClient();

        yield client.mutate({
          mutation: UpdateAppointmentDocument,
          variables: {
            id: self.id,
            set: {
              roomId: newRoomId,
            },
          },
          refetchQueries: ['listAppointmentsSchedule'],
        });
        (getRoot(self) as RootStore).loadAppointments();
        toast.success(`Appointment room updated!`);
      } catch (err) {
        toast.error(getErrorMessage(err));
      }
    }),
  }));
/**
 * A AppointmentStore model.
 */
// prettier-ignore
export const AppointmentModel = types.snapshotProcessor(model, {
    preProcessor(sn: any) {

        const app = { ...sn }

        if(!app.conflicts) {
            app.conflicts = []
        }
        if(sn.color) {
            app.colorOverride = sn.color;
        }
        if(sn.room) {
            app.roomId = sn.room.id
        }
        if(sn.internalAppointmentProviders) {
            app.internalAppointmentProviders = sn.internalAppointmentProviders.map(({provider}: InternalAppointmentProvider) => provider);
            app.internalAppointmentProviderIds = sn.internalAppointmentProviders.map(({provider}: InternalAppointmentProvider) => provider.id);
        }
        app.services = sn.appointmentServices || sn.services;
        return app
    },
})
/**
 * The Appointment instance.
 */
export type Appointment = Instance<typeof AppointmentModel>;

/**
 * The data of a Appointment.
 */
export type AppointmentSnapshot = SnapshotOut<typeof AppointmentModel>;
