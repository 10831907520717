import React, { createContext, useContext, ReactNode } from 'react';
import { GetReceiptInvoiceQuery } from '@webapp/graphql'; // Adjust import path as needed

interface InvoicePreviewContextType {
  invoice: NonNullable<GetReceiptInvoiceQuery['invoice_by_pk']>;
  invoiceConfiguration?: NonNullable<
    GetReceiptInvoiceQuery['invoiceConfiguration'][number]
  >;
}

const InvoicePreviewContext = createContext<
  InvoicePreviewContextType | undefined
>(undefined);

export const useInvoicePreviewContext = () => {
  const context = useContext(InvoicePreviewContext);

  if (context === undefined) {
    throw new Error(
      'useInvoicePreviewContext must be used within an InvoicePreviewProvider'
    );
  }

  return context;
};

interface InvoicePreviewProviderProps {
  children: ReactNode;
  invoice: NonNullable<GetReceiptInvoiceQuery['invoice_by_pk']>;
  invoiceConfiguration?: NonNullable<
    GetReceiptInvoiceQuery['invoiceConfiguration'][number]
  >;
}

export const InvoicePreviewProvider: React.FC<InvoicePreviewProviderProps> = ({
  children,
  invoice,
  invoiceConfiguration,
}) => (
  <InvoicePreviewContext.Provider value={{ invoice, invoiceConfiguration }}>
    {children}
  </InvoicePreviewContext.Provider>
);
