import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { ProviderScheduleFieldsFragmentDoc } from '../../fragments/providerScheduleFields.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetProviderScheduleQueryVariables = Types.Exact<{
  providerId: Types.Scalars['uuid'];
}>;


export type GetProviderScheduleQuery = { __typename?: 'query_root', providerSchedule: Array<{ __typename?: 'providerSchedule', id: any, default: boolean, schedule: any, effectiveFrom: any, effectiveUntil?: any | null | undefined, createdAt: any, updatedAt: any, locationId: any, location: { __typename?: 'location', id: any, name: string, timezone?: string | null | undefined } }> };


export const GetProviderScheduleDocument = gql`
    query getProviderSchedule($providerId: uuid!) {
  providerSchedule(
    order_by: {effectiveFrom: asc}
    where: {_and: [{providerId: {_eq: $providerId}}, {_or: [{default: {_eq: true}}, {effectiveUntil: {_gte: "now()"}}]}]}
  ) {
    ...ProviderScheduleFields
  }
}
    ${ProviderScheduleFieldsFragmentDoc}`;

/**
 * __useGetProviderScheduleQuery__
 *
 * To run a query within a React component, call `useGetProviderScheduleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProviderScheduleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProviderScheduleQuery({
 *   variables: {
 *      providerId: // value for 'providerId'
 *   },
 * });
 */
export function useGetProviderScheduleQuery(baseOptions: Apollo.QueryHookOptions<GetProviderScheduleQuery, GetProviderScheduleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProviderScheduleQuery, GetProviderScheduleQueryVariables>(GetProviderScheduleDocument, options);
      }
export function useGetProviderScheduleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProviderScheduleQuery, GetProviderScheduleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProviderScheduleQuery, GetProviderScheduleQueryVariables>(GetProviderScheduleDocument, options);
        }
export type GetProviderScheduleQueryHookResult = ReturnType<typeof useGetProviderScheduleQuery>;
export type GetProviderScheduleLazyQueryHookResult = ReturnType<typeof useGetProviderScheduleLazyQuery>;
export type GetProviderScheduleQueryResult = Apollo.QueryResult<GetProviderScheduleQuery, GetProviderScheduleQueryVariables>;