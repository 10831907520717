import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { ClinicalMessageFieldsFragmentDoc } from '../../fragments/clinicalMessageFields.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type InsertClinicalMessageMutationVariables = Types.Exact<{
  clinicalMessage: Types.ClinicalMessage_Insert_Input;
}>;


export type InsertClinicalMessageMutation = { __typename?: 'mutation_root', insert_clinicalMessage?: { __typename?: 'clinicalMessage_mutation_response', returning: Array<{ __typename?: 'clinicalMessage', id: any, messageText: string, messageSubject: string, messageType: string, eventSendTime: number, fromAddress?: string | null | undefined, attachmentDocument?: { __typename?: 'document', id: any, title?: string | null | undefined } | null | undefined }> } | null | undefined };


export const InsertClinicalMessageDocument = gql`
    mutation insertClinicalMessage($clinicalMessage: clinicalMessage_insert_input!) {
  insert_clinicalMessage(objects: [$clinicalMessage]) {
    returning {
      ...ClinicalMessageFields
    }
  }
}
    ${ClinicalMessageFieldsFragmentDoc}`;
export type InsertClinicalMessageMutationFn = Apollo.MutationFunction<InsertClinicalMessageMutation, InsertClinicalMessageMutationVariables>;

/**
 * __useInsertClinicalMessageMutation__
 *
 * To run a mutation, you first call `useInsertClinicalMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertClinicalMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertClinicalMessageMutation, { data, loading, error }] = useInsertClinicalMessageMutation({
 *   variables: {
 *      clinicalMessage: // value for 'clinicalMessage'
 *   },
 * });
 */
export function useInsertClinicalMessageMutation(baseOptions?: Apollo.MutationHookOptions<InsertClinicalMessageMutation, InsertClinicalMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertClinicalMessageMutation, InsertClinicalMessageMutationVariables>(InsertClinicalMessageDocument, options);
      }
export type InsertClinicalMessageMutationHookResult = ReturnType<typeof useInsertClinicalMessageMutation>;
export type InsertClinicalMessageMutationResult = Apollo.MutationResult<InsertClinicalMessageMutation>;
export type InsertClinicalMessageMutationOptions = Apollo.BaseMutationOptions<InsertClinicalMessageMutation, InsertClinicalMessageMutationVariables>;