import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type CancelDraftInvoiceMutationVariables = Types.Exact<{
  invoiceId: Types.Scalars['uuid'];
}>;


export type CancelDraftInvoiceMutation = { __typename?: 'mutation_root', update_invoice_by_pk?: { __typename?: 'invoice', id: any } | null | undefined };


export const CancelDraftInvoiceDocument = gql`
    mutation CancelDraftInvoice($invoiceId: uuid!) {
  update_invoice_by_pk(
    pk_columns: {id: $invoiceId}
    _set: {isArchived: true, status: "VOID"}
  ) {
    id
  }
}
    `;
export type CancelDraftInvoiceMutationFn = Apollo.MutationFunction<CancelDraftInvoiceMutation, CancelDraftInvoiceMutationVariables>;

/**
 * __useCancelDraftInvoiceMutation__
 *
 * To run a mutation, you first call `useCancelDraftInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelDraftInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelDraftInvoiceMutation, { data, loading, error }] = useCancelDraftInvoiceMutation({
 *   variables: {
 *      invoiceId: // value for 'invoiceId'
 *   },
 * });
 */
export function useCancelDraftInvoiceMutation(baseOptions?: Apollo.MutationHookOptions<CancelDraftInvoiceMutation, CancelDraftInvoiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelDraftInvoiceMutation, CancelDraftInvoiceMutationVariables>(CancelDraftInvoiceDocument, options);
      }
export type CancelDraftInvoiceMutationHookResult = ReturnType<typeof useCancelDraftInvoiceMutation>;
export type CancelDraftInvoiceMutationResult = Apollo.MutationResult<CancelDraftInvoiceMutation>;
export type CancelDraftInvoiceMutationOptions = Apollo.BaseMutationOptions<CancelDraftInvoiceMutation, CancelDraftInvoiceMutationVariables>;