import {
  Editable,
  EditableInput,
  EditablePreview,
  HStack,
  IconButton,
  Stack,
} from '@chakra-ui/react';
import { AiOutlineClose } from 'react-icons/ai';
import { BiDownArrow, BiUpArrow } from 'react-icons/bi';
import { useAutoAnimate } from '@formkit/auto-animate/react';
import CustomEditableInput from '../../../editable-input/editable-input';

function tryDecodeURIComponent(value: string) {
  try {
    return decodeURIComponent(value);
  } catch (e) {
    return value;
  }
}

export interface ConfigureOptionsProps {
  options: string[];
  onChange: (options: string[]) => void;
}
export default function ConfigureOptions({
  options,
  onChange,
}: ConfigureOptionsProps) {
  const [parent, enableAnimations] = useAutoAnimate({
    duration: 200,
    easing: 'ease-out',
  });

  const editOption = (index: number, value: string | undefined) => {
    if (!value) return;
    const newOptions = [...options];
    newOptions[index] = value;
    onChange?.(newOptions);
  };

  const removeOption = (index: number) => {
    const newOptions = [...options];
    newOptions.splice(index, 1);
    onChange?.(newOptions);
  };

  const moveOption = (index: number, direction: 'up' | 'down') => {
    if (
      (direction === 'up' && index === 0) ||
      (direction === 'down' && index === options.length - 1)
    )
      return;
    const newOptions = [...options];
    const temp = newOptions[index];
    newOptions[index] = newOptions[index + (direction === 'up' ? -1 : 1)];
    newOptions[index + (direction === 'up' ? -1 : 1)] = temp;
    onChange?.(newOptions);
  };

  return (
    <Stack as={'ol'} ref={parent} maxH="300px" overflowY={'auto'}>
      {options
        .map((o) => tryDecodeURIComponent(o))
        .map((option, i) => (
          <HStack
            as="li"
            key={`option-input-${i}-${option}`}
            w={'full'}
            rounded="md"
            p={2}
            justify="space-between"
            borderColor="gray.100"
            borderWidth={1}
          >
            <Editable
              defaultValue={tryDecodeURIComponent(option)}
              onSubmit={(v) => editOption(i, v)}
            >
              <EditablePreview />
              <EditableInput />
            </Editable>
            <HStack>
              <IconButton
                size="sm"
                onClick={() => moveOption(i, 'up')}
                aria-label="Move Option Up"
                variant="ghost"
                icon={<BiUpArrow />}
              />
              <IconButton
                size="sm"
                onClick={() => moveOption(i, 'down')}
                aria-label="Move Option Down"
                variant="ghost"
                icon={<BiDownArrow />}
              />
              <IconButton
                onClick={() => removeOption(i)}
                aria-label="Remove option"
                icon={<AiOutlineClose />}
                size="sm"
              />
            </HStack>
          </HStack>
        ))}
    </Stack>
  );
}
