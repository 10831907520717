/* eslint-disable camelcase */
/* eslint-disable react/jsx-no-useless-fragment */
import {
  Stack,
  HStack,
  Tag,
  TagLabel,
  Text,
  Icon,
  Box,
  Tooltip,
  Wrap,
  WrapItem,
} from '@chakra-ui/react';
import {
  MembershipType_Enum,
  PatientFieldsFragment,
  useSubscribePatientWalletBalanceSubscription,
} from '@webapp/graphql';
import { NotificationType, NotificationWithIcon } from '@webapp/ui';
import { toLocalePrice } from '@webapp/util-formatting';
import dayjs from 'dayjs';
import { useMemo, useState } from 'react';
import { BsWallet2 } from 'react-icons/bs';
import { MdSchedule } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import PatientWalletModal from '../patient-wallet-modal';
import { determineHasPatientWallet } from '../util';
import { PatientWalletPatientMembership } from './types';
import UpdatePatientMembershipModal from './update-patient-membership-modal';

interface PatientWalletWithModalProps {
  patient: PatientFieldsFragment;
  showPaymentInformation?: boolean;
  showSubscriptionTag?: boolean;
  showWalletAmount?: boolean;
}

export const PatientWalletWithModal = ({
  patient,
  showPaymentInformation = true,
  showSubscriptionTag = true,
  showWalletAmount = true,
}: PatientWalletWithModalProps): JSX.Element | null => {
  const { patientWallets, patientMemberships, id: patientId } = patient;

  const [currentPatientMembership, setCurrentPatientMembership] =
    useState<PatientWalletPatientMembership>();

  const [isOpen, setIsOpen] = useState(false);

  const patientWallet = patientWallets?.[0];

  const [
    isUpdatePatientMembershipModalOpen,
    setIsUpdatePatientMembershipModalOpen,
  ] = useState(false);

  const closeUpdatePatientMembershipModal = (): void => {
    setIsUpdatePatientMembershipModalOpen(false);
  };

  const { data: patientWalletBalanceData } =
    useSubscribePatientWalletBalanceSubscription({
      variables: {
        id: patientWallet?.id,
      },
      skip: !patientWallet,
    });

  const updatedWallet = useMemo(() => {
    const newWallet = patientWalletBalanceData?.patientWallet_by_pk;

    if (newWallet) {
      return {
        ...patientWallet,
        balance: newWallet.balance,
      };
    }

    return patientWallet;
  }, [patientWalletBalanceData, patientWallet]);

  const setCurrentPatientMembershipAndOpenModal = (
    patientMembership: PatientWalletPatientMembership
  ): void => {
    setCurrentPatientMembership(patientMembership);
    setIsUpdatePatientMembershipModalOpen(true);
  };

  const navigate = useNavigate();

  const openModal = () => setIsOpen(true);

  const onClose = () => setIsOpen(false);

  const hasPatientWallet = determineHasPatientWallet(patient);

  const hasMemberships = patientMemberships.length > 0;

  if (!hasPatientWallet && !hasMemberships) return null;

  const missedPayments = patientMemberships.filter(
    (patientMembership) => patientMembership.status === 'MISSED_PAYMENT'
  );

  const hasMissedPayments = missedPayments.length > 0;

  const missedNames = missedPayments
    .map((missedPayment) => missedPayment.membership.name)
    .join(', ');

  const tooltipText = `Missed payment for the following subscription(s): ${missedNames}`;

  const navigateInvoices = (): void => {
    navigate(
      `/invoices?type=SUBSCRIPTION&status=PAYMENT_OUTSTANDING&patientId=${patientId}`
    );
  };

  return (
    <HStack borderRadius={15} spacing={6}>
      <Stack spacing={0}>
        {hasPatientWallet && showWalletAmount && (
          <>
            <HStack
              alignSelf="center"
              justifySelf={'center'}
              cursor="pointer"
              onClick={openModal}
              spacing={2}
              _hover={{ color: 'green.500' }}
              alignItems="center"
            >
              <Icon as={BsWallet2} />
              <Text fontSize="20px" fontWeight="bold">{`${toLocalePrice(
                updatedWallet.balance / 100
              )}`}</Text>
            </HStack>
            <PatientWalletModal
              isOpen={isOpen}
              onClose={onClose}
              patientWallet={updatedWallet}
            />
          </>
        )}
        {hasMemberships && showSubscriptionTag && (
          <Wrap>
            {patientMemberships.map((patientMembership) => {
              const isSubscription =
                patientMembership.membership.type ===
                MembershipType_Enum.Subscription;

              return (
                <WrapItem>
                  <Tooltip
                    label={`Member since ${dayjs(
                      patientMembership.createdAt
                    ).format('MM/DD/YYYY')}`}
                  >
                    <Tag
                      cursor={isSubscription ? 'pointer' : undefined}
                      onClick={
                        isSubscription
                          ? () =>
                              setCurrentPatientMembershipAndOpenModal(
                                patientMembership
                              )
                          : undefined
                      }
                      _hover={{ bg: 'gray.800', color: 'white' }}
                      size={'md'}
                      borderRadius="full"
                      variant="solid"
                      colorScheme={patientMembership.membership.color}
                      key={`${patientMembership.membership.name}`}
                      w="fit-content"
                    >
                      <TagLabel>{patientMembership.membership.name}</TagLabel>
                      {Boolean(patientMembership.nextPaymentDate) && (
                        <>
                          <Box px={1}>
                            <MdSchedule />
                          </Box>
                          <TagLabel>
                            {dayjs(patientMembership.nextPaymentDate).format(
                              'M/DD'
                            )}
                          </TagLabel>
                        </>
                      )}
                    </Tag>
                  </Tooltip>
                </WrapItem>
              );
            })}
          </Wrap>
        )}
      </Stack>
      {showPaymentInformation && (
        <Stack spacing={2}>
          {hasMissedPayments && (
            <Tooltip label={tooltipText}>
              <Box cursor="pointer" onClick={navigateInvoices}>
                <NotificationWithIcon
                  text="Missed Payment"
                  type={NotificationType.WARNING}
                />
              </Box>
            </Tooltip>
          )}
        </Stack>
      )}
      {currentPatientMembership && patient && patient?.id && (
        <UpdatePatientMembershipModal
          key={patient?.id}
          patient={patient}
          patientMembership={currentPatientMembership}
          onClose={closeUpdatePatientMembershipModal}
          isOpen={isUpdatePatientMembershipModalOpen}
        />
      )}
    </HStack>
  );
};

export default PatientWalletWithModal;
