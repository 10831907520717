import { addMessages } from '@webapp/joi-validation';
import Joi from 'joi';

const CreatePatientModalValidation = Joi.object({
  email: addMessages(Joi.string().required(), 'Email'),
  firstName: addMessages(Joi.string().required(), 'First Name'),
  lastName: addMessages(Joi.string().required(), 'Last Name'),
  phoneNumber: addMessages(Joi.string().required(), 'Phone #'),
  workspaceId: Joi.string(),
});

export default CreatePatientModalValidation;
