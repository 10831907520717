/* eslint-disable no-console */
import { usePayrixFields } from '@webapp/hooks';
import { PayrixTokenResponse } from '@webapp/types';
import { useEffect } from 'react';
import toast from 'react-hot-toast';
import PAY_FIELDS_CUSTOMIZATIONS from './PayFieldsCustomizations';
import populatePayrixFields from './populatePayrixFields';
import { PayrixFieldsValues } from './types';

interface UseConfiguredPayFieldsProps {
  amount: number;
  defaultValues?: PayrixFieldsValues;
  mode?: 'token' | 'txnToken';
  onSuccess: (response: PayrixTokenResponse) => void;
  onFailure: () => void;
  onFinish: () => void;
  payrixMerchantId: string;
  workspaceId: string;
  payrixMode?: 'txnToken' | 'token';
}

export const useConfiguredPayFields = function useConfiguredPayFields({
  amount,
  defaultValues,
  mode = 'txnToken',
  onFailure,
  onFinish,
  onSuccess,
  payrixMerchantId,
  payrixMode,
}: UseConfiguredPayFieldsProps) {
  const PayFields = usePayrixFields();

  useEffect(() => {
    if (PayFields) {
      PayFields.appendIframe();
      PayFields.reload();
    }

    return () => PayFields.teardown();
  }, [PayFields]);

  useEffect(() => {
    if (defaultValues) {
      const { address, email, phoneNumber } = defaultValues;
      const formattedPhoneNumber = phoneNumber
        ? phoneNumber.replace(/\D/g, '')
        : '';
      PayFields.config.billingAddress = {
        address: address.addressLine1,
        address2: address.addressLine2,
        city: address.city,
        email,
        phone: formattedPhoneNumber,
        state: address.state,
        zip: address.zipCode,
      };

      PayFields.fields = populatePayrixFields(defaultValues);
    }
  }, [defaultValues, PayFields]);

  PayFields.customizations.optionalFields = [
    '#address1',
    '#city',
    '#state',
    '#zip',
    '#email',
    '#phone',
  ];

  PayFields.config.apiKey = import.meta.env.VITE_PAYRIX_PUBLIC_KEY;
  PayFields.config.merchant = payrixMerchantId;

  PayFields.config.mode = payrixMode || mode;

  PayFields.fields = populatePayrixFields(defaultValues);

  if (mode === 'token') {
    PayFields.config.amount = null;
    PayFields.config.txnType = 'token';
  } else {
    PayFields.config.amount = amount;
  }

  PayFields.customizations = PAY_FIELDS_CUSTOMIZATIONS;

  PayFields.onFailure = (e) => {
    console.error(e);
    toast.error(e.errors?.[0]?.msg ?? `Payment failed, please try again.`);
    onFailure();
  };

  PayFields.onValidationFailure = (e) => {
    console.log(PayFields, 'Payfields');
    console.error(e);

    toast.error(
      `Information invalid or missing, please check your card & address details.`
    );
    onFailure();
  };

  PayFields.onFinish = onFinish;

  PayFields.onSuccess = onSuccess;

  return PayFields;
};

export default useConfiguredPayFields;
