import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type InsertPurchaseOrderAndAttachConsumablesMutationVariables = Types.Exact<{
  purchaseOrder: Types.Scalars['jsonb'];
  consumableIds: Array<Types.Scalars['uuid']> | Types.Scalars['uuid'];
}>;


export type InsertPurchaseOrderAndAttachConsumablesMutation = { __typename?: 'mutation_root', insertPurchaseOrderAndAttachConsumables: { __typename?: 'IdResponse', id: any } };


export const InsertPurchaseOrderAndAttachConsumablesDocument = gql`
    mutation InsertPurchaseOrderAndAttachConsumables($purchaseOrder: jsonb!, $consumableIds: [uuid!]!) {
  insertPurchaseOrderAndAttachConsumables(
    purchaseOrder: $purchaseOrder
    consumableIds: $consumableIds
  ) {
    id
  }
}
    `;
export type InsertPurchaseOrderAndAttachConsumablesMutationFn = Apollo.MutationFunction<InsertPurchaseOrderAndAttachConsumablesMutation, InsertPurchaseOrderAndAttachConsumablesMutationVariables>;

/**
 * __useInsertPurchaseOrderAndAttachConsumablesMutation__
 *
 * To run a mutation, you first call `useInsertPurchaseOrderAndAttachConsumablesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertPurchaseOrderAndAttachConsumablesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertPurchaseOrderAndAttachConsumablesMutation, { data, loading, error }] = useInsertPurchaseOrderAndAttachConsumablesMutation({
 *   variables: {
 *      purchaseOrder: // value for 'purchaseOrder'
 *      consumableIds: // value for 'consumableIds'
 *   },
 * });
 */
export function useInsertPurchaseOrderAndAttachConsumablesMutation(baseOptions?: Apollo.MutationHookOptions<InsertPurchaseOrderAndAttachConsumablesMutation, InsertPurchaseOrderAndAttachConsumablesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertPurchaseOrderAndAttachConsumablesMutation, InsertPurchaseOrderAndAttachConsumablesMutationVariables>(InsertPurchaseOrderAndAttachConsumablesDocument, options);
      }
export type InsertPurchaseOrderAndAttachConsumablesMutationHookResult = ReturnType<typeof useInsertPurchaseOrderAndAttachConsumablesMutation>;
export type InsertPurchaseOrderAndAttachConsumablesMutationResult = Apollo.MutationResult<InsertPurchaseOrderAndAttachConsumablesMutation>;
export type InsertPurchaseOrderAndAttachConsumablesMutationOptions = Apollo.BaseMutationOptions<InsertPurchaseOrderAndAttachConsumablesMutation, InsertPurchaseOrderAndAttachConsumablesMutationVariables>;