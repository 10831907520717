import * as Types from '../../generated/graphql';

import { gql } from '@apollo/client';
export type CustomFormFieldFragmentFragment = { __typename?: 'customFormField', id: any, index: number, label: string, body?: string | null | undefined, category?: Types.CustomFormFieldCategory_Enum | null | undefined, type: Types.CustomFormFieldType_Enum, table?: string | null | undefined, attribute?: string | null | undefined, validation?: any | null | undefined, photoId?: any | null | undefined, row: { __typename?: 'customFormRow', id: any, index: number }, options: Array<{ __typename?: 'customFormOption', id: any, value: string }>, photo?: { __typename?: 'photo', file?: { __typename?: 'FilePayload', url: string } | null | undefined } | null | undefined };

export const CustomFormFieldFragmentFragmentDoc = gql`
    fragment CustomFormFieldFragment on customFormField {
  id
  index
  label
  body
  category
  type
  table
  attribute
  validation
  row {
    id
    index
  }
  options {
    id
    value
  }
  photoId
  photo {
    file {
      url
    }
  }
}
    `;