import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type VerifyPinMutationVariables = Types.Exact<{
  value: Types.Scalars['String'];
}>;


export type VerifyPinMutation = { __typename?: 'mutation_root', verifyPin?: { __typename?: 'SuccessAnswer', success: boolean } | null | undefined };


export const VerifyPinDocument = gql`
    mutation verifyPin($value: String!) {
  verifyPin(value: $value) {
    success
  }
}
    `;
export type VerifyPinMutationFn = Apollo.MutationFunction<VerifyPinMutation, VerifyPinMutationVariables>;

/**
 * __useVerifyPinMutation__
 *
 * To run a mutation, you first call `useVerifyPinMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyPinMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyPinMutation, { data, loading, error }] = useVerifyPinMutation({
 *   variables: {
 *      value: // value for 'value'
 *   },
 * });
 */
export function useVerifyPinMutation(baseOptions?: Apollo.MutationHookOptions<VerifyPinMutation, VerifyPinMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VerifyPinMutation, VerifyPinMutationVariables>(VerifyPinDocument, options);
      }
export type VerifyPinMutationHookResult = ReturnType<typeof useVerifyPinMutation>;
export type VerifyPinMutationResult = Apollo.MutationResult<VerifyPinMutation>;
export type VerifyPinMutationOptions = Apollo.BaseMutationOptions<VerifyPinMutation, VerifyPinMutationVariables>;