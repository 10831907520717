import * as Types from '../../generated/graphql';

import { gql } from '@apollo/client';
export type DeviceTypeFieldsFragment = { __typename?: 'deviceType', id: any, value: string };

export const DeviceTypeFieldsFragmentDoc = gql`
    fragment DeviceTypeFields on deviceType {
  id
  value
}
    `;