import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { SmartNoteFieldsFragmentDoc } from '../../fragments/smartNoteFields.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type InsertSmartNoteMutationVariables = Types.Exact<{
  smartNote: Types.SmartNote_Insert_Input;
}>;


export type InsertSmartNoteMutation = { __typename?: 'mutation_root', insert_smartNote?: { __typename?: 'smartNote_mutation_response', returning: Array<{ __typename?: 'smartNote', id: any, title: string, callPhrase: string, content: string, createdAt: any, updatedAt: any, workspaceId: any }> } | null | undefined };


export const InsertSmartNoteDocument = gql`
    mutation insertSmartNote($smartNote: smartNote_insert_input!) {
  insert_smartNote(objects: [$smartNote]) {
    returning {
      ...SmartNoteFields
    }
  }
}
    ${SmartNoteFieldsFragmentDoc}`;
export type InsertSmartNoteMutationFn = Apollo.MutationFunction<InsertSmartNoteMutation, InsertSmartNoteMutationVariables>;

/**
 * __useInsertSmartNoteMutation__
 *
 * To run a mutation, you first call `useInsertSmartNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertSmartNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertSmartNoteMutation, { data, loading, error }] = useInsertSmartNoteMutation({
 *   variables: {
 *      smartNote: // value for 'smartNote'
 *   },
 * });
 */
export function useInsertSmartNoteMutation(baseOptions?: Apollo.MutationHookOptions<InsertSmartNoteMutation, InsertSmartNoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertSmartNoteMutation, InsertSmartNoteMutationVariables>(InsertSmartNoteDocument, options);
      }
export type InsertSmartNoteMutationHookResult = ReturnType<typeof useInsertSmartNoteMutation>;
export type InsertSmartNoteMutationResult = Apollo.MutationResult<InsertSmartNoteMutation>;
export type InsertSmartNoteMutationOptions = Apollo.BaseMutationOptions<InsertSmartNoteMutation, InsertSmartNoteMutationVariables>;