import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { DocumentFieldsFragmentDoc } from '../../fragments/documentFields.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type CustomUpdateDocumentMutationVariables = Types.Exact<{
  set: Types.DocumentUpdateInput;
  id: Types.Scalars['uuid'];
}>;


export type CustomUpdateDocumentMutation = { __typename?: 'mutation_root', updateDocument: { __typename?: 'UpdateDocumentOutput', document?: { __typename?: 'document', id: any, title?: string | null | undefined, filePath: string, documentType: string, documentTags: Array<{ __typename?: 'documentTag', id: any, tag: { __typename?: 'tag', color?: string | null | undefined, id: any, title: string } }>, file?: { __typename?: 'FileOnlyURL', url: string } | null | undefined } | null | undefined } };


export const CustomUpdateDocumentDocument = gql`
    mutation CustomUpdateDocument($set: DocumentUpdateInput!, $id: uuid!) {
  updateDocument(set: $set, id: $id) {
    document {
      ...DocumentFields
    }
  }
}
    ${DocumentFieldsFragmentDoc}`;
export type CustomUpdateDocumentMutationFn = Apollo.MutationFunction<CustomUpdateDocumentMutation, CustomUpdateDocumentMutationVariables>;

/**
 * __useCustomUpdateDocumentMutation__
 *
 * To run a mutation, you first call `useCustomUpdateDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCustomUpdateDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [customUpdateDocumentMutation, { data, loading, error }] = useCustomUpdateDocumentMutation({
 *   variables: {
 *      set: // value for 'set'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCustomUpdateDocumentMutation(baseOptions?: Apollo.MutationHookOptions<CustomUpdateDocumentMutation, CustomUpdateDocumentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CustomUpdateDocumentMutation, CustomUpdateDocumentMutationVariables>(CustomUpdateDocumentDocument, options);
      }
export type CustomUpdateDocumentMutationHookResult = ReturnType<typeof useCustomUpdateDocumentMutation>;
export type CustomUpdateDocumentMutationResult = Apollo.MutationResult<CustomUpdateDocumentMutation>;
export type CustomUpdateDocumentMutationOptions = Apollo.BaseMutationOptions<CustomUpdateDocumentMutation, CustomUpdateDocumentMutationVariables>;