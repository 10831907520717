import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { NoteFieldsFragmentDoc } from '../../fragments/noteFields.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type SubscribeListNotesSubscriptionVariables = Types.Exact<{
  where?: Types.Maybe<Types.Note_Bool_Exp>;
  offset?: Types.Maybe<Types.Scalars['Int']>;
  limit?: Types.Maybe<Types.Scalars['Int']>;
  orderBy?: Types.Maybe<Array<Types.Note_Order_By> | Types.Note_Order_By>;
}>;


export type SubscribeListNotesSubscription = { __typename?: 'subscription_root', note: Array<{ __typename?: 'note', isLocked?: boolean | null | undefined, createdAt: any, title?: string | null | undefined, isGoodFaithExam?: boolean | null | undefined, id: any, isInternal: boolean, message: string, appointmentId?: any | null | undefined, updatedAt: any, author: { __typename?: 'user', firstName: string, id: any, lastName: string, title?: string | null | undefined, profilePicture?: string | null | undefined }, editedBy?: { __typename?: 'user', firstName: string, id: any, lastName: string, title?: string | null | undefined } | null | undefined, noteSignatures: Array<{ __typename?: 'noteSignature', signature?: { __typename?: 'signature', id: any, filePath: string, provider?: { __typename?: 'provider', firstName: string, id: any, lastName: string, title?: string | null | undefined } | null | undefined, file?: { __typename?: 'FileOnlyURL', url: string } | null | undefined } | null | undefined }>, assignedToProvider?: { __typename?: 'provider', id: any, firstName: string, lastName: string, title?: string | null | undefined } | null | undefined, patient: { __typename?: 'patient', firstName: string, id: any, lastName: string } }> };


export const SubscribeListNotesDocument = gql`
    subscription SubscribeListNotes($where: note_bool_exp, $offset: Int, $limit: Int = 20, $orderBy: [note_order_by!]) {
  note(where: $where, offset: $offset, limit: $limit, order_by: $orderBy) {
    ...NoteFields
  }
}
    ${NoteFieldsFragmentDoc}`;

/**
 * __useSubscribeListNotesSubscription__
 *
 * To run a query within a React component, call `useSubscribeListNotesSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeListNotesSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeListNotesSubscription({
 *   variables: {
 *      where: // value for 'where'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useSubscribeListNotesSubscription(baseOptions?: Apollo.SubscriptionHookOptions<SubscribeListNotesSubscription, SubscribeListNotesSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<SubscribeListNotesSubscription, SubscribeListNotesSubscriptionVariables>(SubscribeListNotesDocument, options);
      }
export type SubscribeListNotesSubscriptionHookResult = ReturnType<typeof useSubscribeListNotesSubscription>;
export type SubscribeListNotesSubscriptionResult = Apollo.SubscriptionResult<SubscribeListNotesSubscription>;