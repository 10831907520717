import * as Types from '../../generated/graphql';

import { gql } from '@apollo/client';
export type ServiceFieldsFragment = { __typename?: 'service', id: any, taxRate?: any | null | undefined, deposit?: number | null | undefined, requiresDeposit?: boolean | null | undefined, name: string, active?: boolean | null | undefined, description?: string | null | undefined, image?: string | null | undefined, availableInStore?: boolean | null | undefined, canBeBookedOnline?: boolean | null | undefined, minutesToPerform: number, newPatientMinutesToPerform?: number | null | undefined, deviceBufferTime?: number | null | undefined, price: number, isProduct?: boolean | null | undefined, onlineBookingRules?: any | null | undefined, color?: string | null | undefined, serviceUnitId?: any | null | undefined, serviceCategoryId?: any | null | undefined, pricingModel: string, serviceUnit?: { __typename?: 'serviceUnit', id: any, name: string } | null | undefined, locationServices: Array<{ __typename?: 'locationService', id: any, locationId: any, price?: any | null | undefined, taxRate?: any | null | undefined }>, serviceCategory?: { __typename?: 'serviceCategory', title: string } | null | undefined, appointmentType?: { __typename?: 'appointmentType', name: string, color?: string | null | undefined } | null | undefined, serviceDeviceTypes: Array<{ __typename?: 'serviceDeviceType', deviceType: { __typename?: 'deviceType', value: string } }>, serviceConsumableTypes: Array<{ __typename?: 'serviceConsumableType', numberOfUnits: any, pricePerUnit: number, consumableType: { __typename?: 'consumableType', id: any, name: string, requiredFields: any, unitStep: any } }>, precareDocument?: { __typename?: 'document', title?: string | null | undefined, file?: { __typename?: 'FileOnlyURL', url: string } | null | undefined } | null | undefined, postcareDocument?: { __typename?: 'document', title?: string | null | undefined, file?: { __typename?: 'FileOnlyURL', url: string } | null | undefined } | null | undefined };

export const ServiceFieldsFragmentDoc = gql`
    fragment ServiceFields on service {
  id
  taxRate
  deposit
  requiresDeposit
  name
  active
  description
  image
  availableInStore
  canBeBookedOnline
  minutesToPerform
  newPatientMinutesToPerform
  deviceBufferTime
  price
  isProduct
  onlineBookingRules
  color
  serviceUnitId
  serviceUnit {
    id
    name
  }
  locationServices {
    id
    locationId
    price
    taxRate
  }
  serviceCategory {
    title
  }
  appointmentType {
    name
    color
  }
  serviceCategoryId
  pricingModel
  serviceDeviceTypes {
    deviceType {
      value
    }
  }
  serviceConsumableTypes {
    consumableType {
      id
      name
      requiredFields
      unitStep
    }
    numberOfUnits
    pricePerUnit
  }
  precareDocument {
    title
    file {
      url
    }
  }
  postcareDocument {
    title
    file {
      url
    }
  }
}
    `;