import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetCloudflareCustomHostnameQueryVariables = Types.Exact<{
  customHostnameId: Types.Scalars['String'];
}>;


export type GetCloudflareCustomHostnameQuery = { __typename?: 'query_root', getCloudflareCustomHostname?: { __typename?: 'CloudflareCustomHostnameDetails', id: string, hostname: string, status?: string | null | undefined, verification_errors?: Array<string | null | undefined> | null | undefined, created_at?: string | null | undefined, ssl?: { __typename?: 'CFSSL', id: string, type: string, method: string, status: string, txt_name?: string | null | undefined, txt_value?: string | null | undefined, bundle_method?: string | null | undefined, wildcard?: boolean | null | undefined, certificate_authority?: string | null | undefined, validation_records?: Array<{ __typename?: 'CFValidationRecord', status?: string | null | undefined, txt_name?: string | null | undefined, txt_value?: string | null | undefined } | null | undefined> | null | undefined, dcv_delegation_records?: Array<{ __typename?: 'CFDCVDelegationRecord', cname?: string | null | undefined, cname_target?: string | null | undefined } | null | undefined> | null | undefined, settings?: { __typename?: 'CFSSLSettings', min_tls_version?: string | null | undefined } | null | undefined } | null | undefined, ownership_verification?: { __typename?: 'CFOwnershipVerification', type?: string | null | undefined, name?: string | null | undefined, value?: string | null | undefined } | null | undefined, ownership_verification_http?: { __typename?: 'CFOwnershipVerificationHTTP', http_url?: string | null | undefined, http_body?: string | null | undefined } | null | undefined } | null | undefined };


export const GetCloudflareCustomHostnameDocument = gql`
    query GetCloudflareCustomHostname($customHostnameId: String!) {
  getCloudflareCustomHostname(customHostnameId: $customHostnameId) {
    id
    hostname
    ssl {
      id
      type
      method
      status
      txt_name
      txt_value
      validation_records {
        status
        txt_name
        txt_value
      }
      dcv_delegation_records {
        cname
        cname_target
      }
      settings {
        min_tls_version
      }
      bundle_method
      wildcard
      certificate_authority
    }
    status
    verification_errors
    ownership_verification {
      type
      name
      value
    }
    ownership_verification_http {
      http_url
      http_body
    }
    created_at
  }
}
    `;

/**
 * __useGetCloudflareCustomHostnameQuery__
 *
 * To run a query within a React component, call `useGetCloudflareCustomHostnameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCloudflareCustomHostnameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCloudflareCustomHostnameQuery({
 *   variables: {
 *      customHostnameId: // value for 'customHostnameId'
 *   },
 * });
 */
export function useGetCloudflareCustomHostnameQuery(baseOptions: Apollo.QueryHookOptions<GetCloudflareCustomHostnameQuery, GetCloudflareCustomHostnameQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCloudflareCustomHostnameQuery, GetCloudflareCustomHostnameQueryVariables>(GetCloudflareCustomHostnameDocument, options);
      }
export function useGetCloudflareCustomHostnameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCloudflareCustomHostnameQuery, GetCloudflareCustomHostnameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCloudflareCustomHostnameQuery, GetCloudflareCustomHostnameQueryVariables>(GetCloudflareCustomHostnameDocument, options);
        }
export type GetCloudflareCustomHostnameQueryHookResult = ReturnType<typeof useGetCloudflareCustomHostnameQuery>;
export type GetCloudflareCustomHostnameLazyQueryHookResult = ReturnType<typeof useGetCloudflareCustomHostnameLazyQuery>;
export type GetCloudflareCustomHostnameQueryResult = Apollo.QueryResult<GetCloudflareCustomHostnameQuery, GetCloudflareCustomHostnameQueryVariables>;