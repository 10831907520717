import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { GoogleAccountFieldsFragmentDoc } from '../../fragments/googleAccountFields.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type InsertGoogleAccountsMutationVariables = Types.Exact<{
  accounts: Array<Types.GoogleAccount_Insert_Input> | Types.GoogleAccount_Insert_Input;
}>;


export type InsertGoogleAccountsMutation = { __typename?: 'mutation_root', insert_googleAccount?: { __typename?: 'googleAccount_mutation_response', returning: Array<{ __typename?: 'googleAccount', id: any, accountName?: string | null | undefined, name: string, role?: string | null | undefined, permissionLevel?: string | null | undefined, workspaceId: any, userId: any, createdAt: any }> } | null | undefined };


export const InsertGoogleAccountsDocument = gql`
    mutation InsertGoogleAccounts($accounts: [googleAccount_insert_input!]!) {
  insert_googleAccount(
    objects: $accounts
    on_conflict: {constraint: googleAccount_accountName_workspaceId_key, update_columns: [permissionLevel, role, vettedState, verificationState]}
  ) {
    returning {
      ...GoogleAccountFields
    }
  }
}
    ${GoogleAccountFieldsFragmentDoc}`;
export type InsertGoogleAccountsMutationFn = Apollo.MutationFunction<InsertGoogleAccountsMutation, InsertGoogleAccountsMutationVariables>;

/**
 * __useInsertGoogleAccountsMutation__
 *
 * To run a mutation, you first call `useInsertGoogleAccountsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertGoogleAccountsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertGoogleAccountsMutation, { data, loading, error }] = useInsertGoogleAccountsMutation({
 *   variables: {
 *      accounts: // value for 'accounts'
 *   },
 * });
 */
export function useInsertGoogleAccountsMutation(baseOptions?: Apollo.MutationHookOptions<InsertGoogleAccountsMutation, InsertGoogleAccountsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertGoogleAccountsMutation, InsertGoogleAccountsMutationVariables>(InsertGoogleAccountsDocument, options);
      }
export type InsertGoogleAccountsMutationHookResult = ReturnType<typeof useInsertGoogleAccountsMutation>;
export type InsertGoogleAccountsMutationResult = Apollo.MutationResult<InsertGoogleAccountsMutation>;
export type InsertGoogleAccountsMutationOptions = Apollo.BaseMutationOptions<InsertGoogleAccountsMutation, InsertGoogleAccountsMutationVariables>;