import {
  Box,
  Button,
  HStack,
  Textarea,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { ChangeEvent } from 'react';
import DEFAULT_CONTROL_STYLE from './styles/DefaultControlStyle';
import DEFAULT_INPUT_CONTAINER_STYLE from './styles/DefaultInputContainerStyle';
import { TextAreaInputProps } from './types/TextAreaInputProps';

function TextAreaInput({
  howToModal: HowToModal,
  name,
  onChange,
  placeholder,
  styles = {},
  value,
  ...props
}: Omit<TextAreaInputProps, 'type'>) {
  const inputStyles = {
    ...DEFAULT_CONTROL_STYLE,
    ...styles.control,
    minHeight: '100px',
  };

  const containerStyles = {
    ...DEFAULT_INPUT_CONTAINER_STYLE,
    ...styles.inputContainer,
  };

  function handleChange(e: ChangeEvent<HTMLTextAreaElement>) {
    const stringValue = e.currentTarget.value;
    onChange(stringValue);
  }

  return (
    <VStack>
      <HStack alignItems="start" width="100%">
        {HowToModal && HowToModal}
      </HStack>
      <Box {...containerStyles}>
        <Textarea
          onChange={handleChange}
          {...inputStyles}
          {...props}
          value={value}
        />
      </Box>
    </VStack>
  );
}

export default TextAreaInput;
