import { useApolloClient } from '@apollo/client';
import { ReactRenderer } from '@tiptap/react';
import {
  GetSmartNotesDocument,
  GetSmartNotesQuery,
  GetSmartNotesQueryVariables,
  OrderBy,
} from '@webapp/graphql';
import { SmartNoteFromQuery } from '@webapp/webapp/hooks';
import tippy from 'tippy.js';
import MentionList from './mentionList';

interface UseLoadMentionsOutput {
  char?: string;
  items: ({ query }: { query: string }) => Promise<SmartNoteFromQuery[]>;
  allowSpaces?: boolean;
  render: () => {
    onStart?: (props: any) => void;
    onUpdate?: (props: any) => void;
    onExit?: (props: any) => void;
    onKeyDown?: (props: any) => boolean;
  };
}

export interface UseLoadMentionsProps {
  mergeTagContext?: any;
}

export const useLoadMentions = ({
  mergeTagContext,
}: UseLoadMentionsProps): UseLoadMentionsOutput => {
  const client = useApolloClient();

  return {
    char: '+',
    allowSpaces: true,
    items: async ({ query }) => {
      const { data } = await client.query<
        GetSmartNotesQuery,
        GetSmartNotesQueryVariables
      >({
        query: GetSmartNotesDocument,
        variables: {
          where: {
            ...(query && {
              callPhrase: {
                _ilike: `%${query}%`,
              },
            }),
          },
          orderBy: {
            callPhrase: OrderBy.Asc,
          },
        },
      });

      return data.smartNote ?? [];
    },

    render: () => {
      let reactRenderer: any;
      let popup: any;

      return {
        onStart: (props: any) => {
          reactRenderer = new ReactRenderer(MentionList, {
            props: { ...props, mergeTagContext },
            editor: props.editor,
          });

          if (!props.clientRect) {
            return;
          }

          popup = tippy('body', {
            getReferenceClientRect: props.clientRect,
            appendTo: () => document.body,
            content: reactRenderer.element,
            showOnCreate: true,
            interactive: true,
            trigger: 'manual',
            placement: 'bottom-start',
          });
        },

        onUpdate(props: any) {
          reactRenderer.updateProps(props);

          if (!props.clientRect) {
            return;
          }

          popup[0].setProps({
            getReferenceClientRect: props.clientRect,
          });
        },

        onKeyDown(props: any) {
          if (props.event.key === 'Escape') {
            popup[0].hide();

            return true;
          }

          return reactRenderer.ref?.onKeyDown(props);
        },

        onExit() {
          popup[0].destroy();
          reactRenderer.destroy();
        },
      };
    },
  };
};

export default useLoadMentions;
