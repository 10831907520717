/* eslint-disable import/order */
/* eslint-disable arrow-body-style */
import {
  HStack,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Tag,
  TagLabel,
  Text,
  Tooltip,
  Wrap,
  WrapItem,
  Button,
  chakra,
  ButtonGroup,
  IconButton,
  VStack,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  useBreakpointValue,
} from '@chakra-ui/react';
import {
  useManuallyUpdatePatientWalletBalanceMutation,
  useSubscribePatientWalletCouponSubscription,
} from '@webapp/graphql';
import { MinimalPatientWallet } from '@webapp/types';
import { toLocalePrice } from '@webapp/util-formatting';
import { useEffect, useMemo, useState } from 'react';
import { HiPencilAlt } from 'react-icons/hi';
import { BsWallet2 } from 'react-icons/bs';
import { returnCouponQuantity } from '../util';
import toast from 'react-hot-toast';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { FormInputV2, FormInput } from '@webapp/ui';
import { nanoid } from 'nanoid';
import { observer } from 'mobx-react-lite';
import { useStores } from '@webapp/state-models';
import { ADMIN, OWNER } from '@webapp/constants';
import { useCustomPermissions } from '@webapp/hooks';
import PatientWalletLedgerViews from './PatientWalletLedgerViews';

interface PatientWalletModalProps {
  patientWallet: MinimalPatientWallet;
  isOpen: boolean;
  onClose: () => void;
}

interface PatientWalletFormValues {
  balance: number;
  balanceUpdateReason: string;
}

export const PatientWalletModal = observer(
  ({
    patientWallet,
    isOpen,
    onClose,
  }: PatientWalletModalProps): JSX.Element => {
    const [editing, setEditing] = useState(false);
    const { user } = useStores();
    const isMobile = useBreakpointValue({ base: true, md: false });
    const { runWithPermissionCheck } = useCustomPermissions();
    const { data: patientWalletCouponData } =
      useSubscribePatientWalletCouponSubscription({
        variables: {
          where: {
            patientWalletId: {
              _eq: patientWallet.id,
            },
            used: {
              _eq: false,
            },
            archived: {
              _eq: false,
            },
          },
        },
      });

    const [updatePatientWalletBalance] =
      useManuallyUpdatePatientWalletBalanceMutation({
        onCompleted: () => {
          toast.success('Balance updated');
          setEditing(false);
        },
        onError: (error) => {
          toast.error(error.message);
        },
      });

    const couponQuantity = useMemo(() => {
      const subData = patientWalletCouponData?.patientWalletCoupon;

      if (subData) {
        return returnCouponQuantity(subData);
      }

      return returnCouponQuantity(patientWallet.patientWalletCoupons);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [patientWalletCouponData]);

    const toggleEditing = (): void => {
      setEditing(!editing);
    };

    const methods = useForm<PatientWalletFormValues>({
      defaultValues: {
        balance: patientWallet.balance / 100,
      },
      mode: 'onChange',
      reValidateMode: 'onChange',
      criteriaMode: 'firstError',
      shouldFocusError: true,
    });

    const { handleSubmit, setValue, reset } = methods;

    const onSubmit: SubmitHandler<PatientWalletFormValues> = ({
      balance,
      balanceUpdateReason,
    }) => {
      updatePatientWalletBalance({
        variables: {
          input: {
            patientWalletId: patientWallet.id,
            newBalance: balance,
            note: balanceUpdateReason,
          },
        },
      });
    };

    useEffect(() => {
      setValue('balance', patientWallet.balance);
    }, [patientWallet.balance, setValue]);

    useEffect(() => () => reset(), [reset]);

    const updateAuthorized = useMemo(() => {
      return [OWNER, ADMIN].includes(user?.role as string);
    }, [user]);

    return (
      <Modal isOpen={isOpen} onClose={onClose} size={isMobile ? 'full' : '6xl'}>
        <FormProvider {...methods}>
          <chakra.form onSubmit={handleSubmit(onSubmit)}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>
                <HStack spacing={2}>
                  {!editing && (
                    <>
                      <Icon as={BsWallet2} />
                      <Text>{`${toLocalePrice(
                        patientWallet.balance / 100
                      )}`}</Text>
                    </>
                  )}
                  {!editing && updateAuthorized && (
                    <IconButton
                      icon={<Icon as={HiPencilAlt} />}
                      onClick={runWithPermissionCheck(
                        'editPatientWallet',
                        toggleEditing
                      )}
                      aria-label="Edit"
                    />
                  )}
                </HStack>
                {editing && (
                  <VStack align="flex-start" fontSize={14}>
                    <FormInputV2
                      key={`${nanoid()}`}
                      type="currency"
                      label="Balance"
                      name="balance"
                      control={methods.control}
                      incrementStep={0.01}
                    />

                    <FormInput
                      key={`${nanoid()}`}
                      type="textarea"
                      label="Reason for update"
                      name="balanceUpdateReason"
                      required
                    />
                  </VStack>
                )}

                {editing && (
                  <ButtonGroup pt={editing ? 8 : undefined}>
                    <HStack>
                      <Button onClick={toggleEditing}>Cancel</Button>
                      <Button type="submit" colorScheme={'teal'}>
                        Submit
                      </Button>
                    </HStack>
                  </ButtonGroup>
                )}
              </ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Tabs>
                  <TabList>
                    <Tab>Available Coupons</Tab>
                    <Tab>Activity</Tab>
                  </TabList>
                  <TabPanels>
                    <TabPanel>
                      {couponQuantity.length > 0 ? (
                        <Wrap>
                          {couponQuantity.map((patientWalletCoupon) => (
                            <WrapItem key={patientWalletCoupon.id}>
                              <Tooltip
                                label={
                                  patientWalletCoupon.rule
                                    ? patientWalletCoupon.rule
                                    : 'No details provided'
                                }
                              >
                                <Tag
                                  _hover={{ bg: 'gray.800', color: 'white' }}
                                >
                                  <TagLabel>{`${
                                    patientWalletCoupon.quantity > 1
                                      ? `${patientWalletCoupon.quantity}x | `
                                      : ''
                                  }${patientWalletCoupon.name}`}</TagLabel>
                                </Tag>
                              </Tooltip>
                            </WrapItem>
                          ))}
                        </Wrap>
                      ) : (
                        <Text>No coupons available</Text>
                      )}
                    </TabPanel>
                    <TabPanel>
                      <PatientWalletLedgerViews patientWallet={patientWallet} />
                    </TabPanel>
                  </TabPanels>
                </Tabs>
              </ModalBody>
            </ModalContent>
          </chakra.form>
        </FormProvider>
      </Modal>
    );
  }
);

export default PatientWalletModal;
