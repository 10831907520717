import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { FullConsumableFieldsFragmentDoc } from '../../fragments/fullConsumableFields.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type ResolveInvoiceConsumablesMutationVariables = Types.Exact<{
  input: Types.ResolveInvoiceConsumablesInput;
}>;


export type ResolveInvoiceConsumablesMutation = { __typename?: 'mutation_root', resolveInvoiceConsumables: { __typename?: 'ResolveInvoiceConsumablesOutput', consumables: Array<{ __typename?: 'ServiceQuantityConsumable', isAmbiguous: boolean, quantity: number, requiredConsumableType: { __typename?: 'IdName', id: any, name: string }, service: { __typename?: 'IdName', id: any, name: string }, suggestedConsumable?: { __typename?: 'FullConsumable', id: any, originalQuantity: number, quantity: number, metadata: any, cost: number, isExpirable: boolean, expirationDate?: string | null | undefined, daysBeforeNotification?: number | null | undefined, shouldWarnQuantity: boolean, warningQuantity?: number | null | undefined, archived: boolean, createdAt: string, updatedAt: string, type: { __typename?: 'IdNameRequiredFields', id: any, name: string, requiredFields: any, unitStep: number, unitName: string, defaultNumberOfUnits: number, defaultPricePerUnit: number }, brand: { __typename?: 'IdName', id: any, name: string }, purchaseOrder?: { __typename?: 'IdOrderNumber', id: any, orderNumber: string } | null | undefined } | null | undefined }> } };


export const ResolveInvoiceConsumablesDocument = gql`
    mutation ResolveInvoiceConsumables($input: ResolveInvoiceConsumablesInput!) {
  resolveInvoiceConsumables(input: $input) {
    consumables {
      isAmbiguous
      quantity
      requiredConsumableType {
        id
        name
      }
      service {
        id
        name
      }
      suggestedConsumable {
        ...FullConsumableFields
      }
    }
  }
}
    ${FullConsumableFieldsFragmentDoc}`;
export type ResolveInvoiceConsumablesMutationFn = Apollo.MutationFunction<ResolveInvoiceConsumablesMutation, ResolveInvoiceConsumablesMutationVariables>;

/**
 * __useResolveInvoiceConsumablesMutation__
 *
 * To run a mutation, you first call `useResolveInvoiceConsumablesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResolveInvoiceConsumablesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resolveInvoiceConsumablesMutation, { data, loading, error }] = useResolveInvoiceConsumablesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResolveInvoiceConsumablesMutation(baseOptions?: Apollo.MutationHookOptions<ResolveInvoiceConsumablesMutation, ResolveInvoiceConsumablesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResolveInvoiceConsumablesMutation, ResolveInvoiceConsumablesMutationVariables>(ResolveInvoiceConsumablesDocument, options);
      }
export type ResolveInvoiceConsumablesMutationHookResult = ReturnType<typeof useResolveInvoiceConsumablesMutation>;
export type ResolveInvoiceConsumablesMutationResult = Apollo.MutationResult<ResolveInvoiceConsumablesMutation>;
export type ResolveInvoiceConsumablesMutationOptions = Apollo.BaseMutationOptions<ResolveInvoiceConsumablesMutation, ResolveInvoiceConsumablesMutationVariables>;