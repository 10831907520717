import { useRef, useState } from 'react';
import {
  HStack,
  Stack,
  Box,
  Center,
  Heading,
  IconButton,
} from '@chakra-ui/react';
import './sticky-note.module.css';
import Draggable from 'react-draggable';

import {
  HookForm,
  SmartTextEditor,
  SmartTextEditorToolbarVersion,
} from '@webapp/ui';
import { useGenerateMergeTagContext } from '@webapp/hooks';
import { FaExpandArrowsAlt, FaMinus, FaWindowMaximize } from 'react-icons/fa';
import { CloseIcon, DragHandleIcon } from '@chakra-ui/icons';
import { observer } from 'mobx-react-lite';
import { useStores } from '@webapp/state-models';
import { toast } from 'react-hot-toast';
import {
  useGetOneNoteQuery,
  useInsertNoteMutation,
  useInsertNoteSignatureMutation,
  useUpdateNoteByPkMutation,
} from '@webapp/graphql';
import { FiMaximize2, FiMinimize, FiMinimize2 } from 'react-icons/fi';
import { NoteModalForm } from '../NoteModalForm/NoteModalForm';
import { NoteHeader } from '../NoteHeader/NoteHeader';
import { NoteLockStatus } from '../NoteLockStatus/NoteLockStatus';
import SaveAndSign from '../save-and-sign/save-and-sign';
/* eslint-disable-next-line */
export interface StickyNoteProps {}

export const StickyNote = observer((props: StickyNoteProps) => {
  const smartNoteEditorRef = useRef<any>(null);
  const [minimized, setMinimized] = useState(false);
  const [maximized, setMaximized] = useState(false);

  const [assignedToProviderId, setAssignedToProviderId] = useState<
    string | undefined
  >();
  const {
    ui: {
      destroyStickyNote,
      stickyNote: {
        show: showStickyNote,
        appointmentId,
        patientId,
        content,
        noteId,
      },
      setStickyNoteId,
    },
  } = useStores();
  const { data: noteData } = useGetOneNoteQuery({
    variables: {
      id: noteId,
    },
    skip: !noteId,
  });
  const { context: mergeTagContext, contextReady } = useGenerateMergeTagContext(
    { patientId, appointmentId, note: noteData?.note_by_pk }
  );
  const [insertNoteSignature] = useInsertNoteSignatureMutation();
  const [insert] = useInsertNoteMutation({
    onCompleted: () => {
      destroyStickyNote();
      toast.success('Note saved');
    },
    refetchQueries: ['listAppointments', 'ListNotes'],
  });

  const [update] = useUpdateNoteByPkMutation({
    onCompleted: () => {
      destroyStickyNote();
      toast.success('Note saved');
    },
    refetchQueries: ['listAppointments', 'ListNotes'],
  });

  if (!showStickyNote) {
    return null;
  }

  async function saveNote({ signatureId }: { signatureId?: string }) {
    const message = smartNoteEditorRef?.current?.getContent();
    if (message) {
      if (noteId) {
        await update({
          variables: {
            id: noteId,
            set: {
              message,
              ...(signatureId && { isLocked: true }),
            },
          },
        });
        // When updating, insert a new note signature if one doesn't exist for the currently submitted signature
        const signatureAlreadyExists =
          noteData?.note_by_pk?.noteSignatures?.some(
            ({ signature }) => signature?.id === signatureId
          );

        if (signatureId && !signatureAlreadyExists) {
          await insertNoteSignature({
            variables: {
              noteSignature: {
                noteId,
                signatureId,
              },
            },
          });
        }
      } else {
        await insert({
          variables: {
            notes: [
              {
                message,
                patientId,
                ...(appointmentId && { appointmentId }),
                ...(signatureId && {
                  noteSignatures: {
                    data: [
                      {
                        signatureId,
                      },
                    ],
                  },
                }),
              },
            ],
          },
        });
      }
    }
    destroyStickyNote();
  }

  return (
    <Draggable
      {...(minimized && {
        position: { x: 0, y: 0 },
      })}
      handle={'#draggy'}
    >
      <Stack
        bg="white"
        shadow="xl"
        rounded="md"
        position={minimized ? 'fixed' : 'absolute'}
        {...(minimized ? { bottom: 0, right: 75 } : { top: 0, left: 0 })}
        w={'full'}
        maxW={maximized ? '100vw' : '2xl'}
        zIndex={'overlay'}
      >
        <HookForm>
          <>
            {minimized ? (
              <HStack
                p={4}
                h="88px"
                bottom={0}
                left={'50%'}
                bg="teal.50"
                alignContent={'center'}
                alignItems="center"
                justifyContent="center"
                onClick={() => setMinimized(false)}
                cursor={'pointer'}
              >
                <Heading size={'md'} colorScheme="teal">
                  Note
                </Heading>
                <IconButton
                  aria-label="Minimize"
                  colorScheme={'teal'}
                  icon={<FaExpandArrowsAlt />}
                />
              </HStack>
            ) : (
              <HStack p={2} w={'95%'} justifyContent="space-between">
                <HStack id="draggy" rounded="md" flexGrow={1}>
                  <DragHandleIcon w={5} h={5} color="black" />
                  <Heading size="xs">Note</Heading>
                </HStack>

                <HStack flexShrink={1}>
                  {!noteData?.note_by_pk.isLocked && (
                    <SaveAndSign onSigned={saveNote} />
                  )}
                  {noteData?.note_by_pk.isLocked && (
                    <NoteLockStatus
                      note={noteData?.note_by_pk}
                      saveNote={saveNote}
                    />
                  )}
                  <IconButton
                    aria-label="Maximize"
                    icon={maximized ? <FiMinimize2 /> : <FiMaximize2 />}
                    onClick={() => {
                      if (!maximized) {
                        setMinimized(false);
                        setMaximized(true);
                      } else {
                        setMinimized(false);
                        setMaximized(false);
                      }
                    }}
                  />
                  <IconButton
                    aria-label="Minimize"
                    icon={<FaMinus />}
                    onClick={() => {
                      setMinimized(true);
                      setMaximized(false);
                    }}
                  />
                  <IconButton
                    aria-label="Close"
                    icon={<CloseIcon />}
                    onClick={() => destroyStickyNote()}
                  />
                </HStack>
              </HStack>
            )}

            <Box display={minimized ? 'none' : 'flex'} h={'100%'}>
              <NoteModalForm
                noteId={noteId}
                ref={smartNoteEditorRef}
                toolbarVersion={SmartTextEditorToolbarVersion.MINIMAL}
                patientId={patientId}
                appointmentId={appointmentId}
                hideHeader={true}
                containerStyle={{ w: '95%' }}
                innerContainerStyle={{
                  overflowX: 'scroll',
                  w: '100%',
                }}
                hideFooter={true}
                onClose={destroyStickyNote}
                onCreateNote={(note) => setStickyNoteId(note?.id)}
              />
            </Box>
            {!minimized && (
              <NoteHeader
                mini={true}
                patientId={patientId}
                appointmentId={appointmentId}
                note={noteData?.note_by_pk}
                onAssignProvider={setAssignedToProviderId}
              />
            )}
          </>
        </HookForm>
      </Stack>
    </Draggable>
  );
});

export default StickyNote;
