import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type ListMediaTagsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ListMediaTagsQuery = { __typename?: 'query_root', mediaTag: Array<{ __typename?: 'mediaTag', id: any, title: string }> };


export const ListMediaTagsDocument = gql`
    query ListMediaTags {
  mediaTag {
    id
    title
  }
}
    `;

/**
 * __useListMediaTagsQuery__
 *
 * To run a query within a React component, call `useListMediaTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListMediaTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListMediaTagsQuery({
 *   variables: {
 *   },
 * });
 */
export function useListMediaTagsQuery(baseOptions?: Apollo.QueryHookOptions<ListMediaTagsQuery, ListMediaTagsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListMediaTagsQuery, ListMediaTagsQueryVariables>(ListMediaTagsDocument, options);
      }
export function useListMediaTagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListMediaTagsQuery, ListMediaTagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListMediaTagsQuery, ListMediaTagsQueryVariables>(ListMediaTagsDocument, options);
        }
export type ListMediaTagsQueryHookResult = ReturnType<typeof useListMediaTagsQuery>;
export type ListMediaTagsLazyQueryHookResult = ReturnType<typeof useListMediaTagsLazyQuery>;
export type ListMediaTagsQueryResult = Apollo.QueryResult<ListMediaTagsQuery, ListMediaTagsQueryVariables>;