import { Input, InputGroup, InputRightElement } from '@chakra-ui/react';
import './DateRangePicker.css';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { AiOutlineCloseCircle } from 'react-icons/ai';
import {
  Input as MobiInput,
  Popup,
  Select,
  Datepicker,
  Button,
  setOptions,
} from '@mobiscroll/react';
import { useCallback, useEffect, useState } from 'react';
import { configuredDayjs as dayjs } from '@webapp/util-time';

setOptions({
  theme: 'ios',
  themeVariant: 'light',
});
type Nullable<T> = T | null | undefined;
type CurrentRange = [Nullable<string>, Nullable<string>];
export interface DateRangePickerProps {
  currentRange?: CurrentRange;
  onChange: (selection: any | null) => void;
  placeholder?: string;
  styleProps?: React.CSSProperties;
}

const responsiveSelect = {
  xsmall: {
    touchUi: true,
  },
  small: {
    touchUi: false,
  },
};
const dateRangePickerFormat = 'YYYY-MM-DDTHH:MM';
const selectOptions = [
  {
    value: 'custom',
    text: 'Custom',
  },
  {
    value: 'today',
    text: 'Today',
  },
  {
    value: 'yesterday',
    text: 'Yesterday',
  },
  {
    value: 'last-week',
    text: 'Last week',
  },
  {
    value: 'last-month',
    text: 'Last month',
  },
  {
    value: 'last-7-days',
    text: 'Last 7 days',
  },
  {
    value: 'last-30-days',
    text: 'Last 30 days',
  },
];

export function DateRangePicker({
  currentRange = [undefined, undefined],
  onChange: onChangeFilter,
  placeholder = 'Search by date range',
  styleProps = {},
}: DateRangePickerProps) {
  const [startDateProp, endDateProp] = currentRange;
  const [isOpen, setOpen] = useState<boolean>(false);
  const [start, startRef] = useState<any>(null);
  const [end, endRef] = useState<any>(null);
  const [selected, setSelected] = useState<string>('custom');
  const [selectedDate, setSelectedDate] = useState<CurrentRange>([
    startDateProp,
    endDateProp,
  ]);
  const [startDate, endDate] = selectedDate;
  const [inputValue, setInputValue] = useState<string>();
  const [disabledInput, setDisabledInput] = useState<boolean>(false);
  const responsivePopup = {
    xsmall: {
      display: 'bottom',
      touchUi: true,
    },
    custom: {
      breakpoint: 559,
      buttons: [],
      display: 'anchored',
      anchor: document.querySelector('.date-filter-input'),
      anchorAlign: 'start',
      touchUi: false,
      scrollLock: false,
      showArrow: false,
    },
  };

  const inputClick = useCallback(() => {
    setOpen(true);
  }, []);

  const changeInputValue = useCallback(
    (start, end) => {
      const displayStartDate =
        start && dayjs(start).isValid()
          ? dayjs(start).format('MM/DD/YYYY')
          : '';
      const displayEndDate =
        end && dayjs(end).isValid() ? dayjs(end).format('MM/DD/YYYY') : '';
      const displayDate = `${displayStartDate} - ${displayEndDate}`;
      setInputValue(displayDate);
    },
    [startDate, endDate]
  );

  const applyClick = useCallback(() => {
    setSelectedDate([startDate, endDate || startDate]);
    changeInputValue(startDate, endDate || startDate);
    onChangeFilter([startDate, endDate || startDate]);
    setOpen(false);
  }, [startDate, endDate, onChangeFilter, changeInputValue]);

  const cancelClick = useCallback(() => {
    setOpen(false);
    if (startDateProp && endDateProp) {
      return setSelectedDate([startDateProp, endDateProp]);
    }
    if (startDate) {
      setSelectedDate([undefined, undefined]);
      setInputValue('');
    }
  }, [startDateProp, endDateProp, startDate]);

  const onChange = useCallback((event) => {
    if (!event) {
      setSelected('custom');
      onChangeFilter([]);
      return setSelectedDate([]);
    }
    const option = event.value;

    if (option === 'custom') {
      setDisabledInput(false);
    } else {
      setDisabledInput(true);
      //   TODO: Refactor these
      const day = dayjs();
      const today = day.format(dateRangePickerFormat);
      const yesterday = day.subtract(1, 'day').format(dateRangePickerFormat);
      const startOfLastWeek = day
        .subtract(1, 'week')
        .startOf('week')
        .format(dateRangePickerFormat);
      const endOfLastWeek = day
        .subtract(1, 'week')
        .endOf('week')
        .format(dateRangePickerFormat);
      const lastMonth = day.subtract(1, 'month');
      const startOfLastMonth = lastMonth
        .startOf('month')
        .format(dateRangePickerFormat);
      const endOfLastMonth = lastMonth
        .endOf('month')
        .format(dateRangePickerFormat);
      const lastSevenDaysEnding = day
        .subtract(8, 'day')
        .set('millisecond', 0)
        .format(dateRangePickerFormat);
      const lastThirtyDaysEnding = day
        .subtract(31, 'day')
        .format(dateRangePickerFormat);

      switch (option) {
        case 'today':
          setSelectedDate([today, today]);
          break;
        case 'yesterday':
          setSelectedDate([yesterday, yesterday]);
          break;
        case 'last-week':
          setSelectedDate([startOfLastWeek, endOfLastWeek]);
          break;
        case 'last-month':
          setSelectedDate([startOfLastMonth, endOfLastMonth]);
          break;
        case 'last-7-days':
          setSelectedDate([lastSevenDaysEnding, yesterday]);
          break;
        case 'last-30-days':
          setSelectedDate([lastThirtyDaysEnding, yesterday]);
          break;
        default:
          break;
      }
    }
    setSelected(option);
  }, []);

  const onDateChange = useCallback((ev) => {
    const date = ev.value;

    setDisabledInput(false);
    setSelected('custom');
    setSelectedDate(date);
  }, []);

  const onClose = useCallback(() => {
    setOpen(false);
    if (startDateProp && endDateProp) {
      return setSelectedDate([startDateProp, endDateProp]);
    }
    if (startDate) {
      setSelectedDate([undefined, undefined]);
      setInputValue('');
    }
  }, [startDateProp, endDateProp, startDate]);

  useEffect(() => {
    changeInputValue(startDate, endDate);
  }, [startDate, endDate, changeInputValue]);

  useEffect(() => {
    if (!startDateProp && !endDateProp) {
      setSelectedDate([undefined, undefined]);
    }
  }, [startDateProp, endDateProp]);
  return (
    <>
      <InputGroup
        maxW={200}
        minW={'125px'}
        className="date-filter-input"
        {...styleProps}
      >
        <Input
          onClick={inputClick}
          defaultValue={startDate ? inputValue : ''}
          placeholder={placeholder}
        />
        {selectedDate[0] && (
          <InputRightElement
            children={
              <AiOutlineCloseCircle
                color={'grey.500'}
                cursor="pointer"
                onClick={() => onChange(null)}
              />
            }
          />
        )}
      </InputGroup>

      <Popup
        isOpen={isOpen}
        onClose={onClose}
        responsive={responsivePopup}
        cssClass="date-range-date-picker"
      >
        <div className="date-range-date-picker mbsc-grid mbsc-no-padding">
          <div className="mbsc-row">
            <div className="mbsc-col-sm-4 mbsc-push-sm-8">
              <div>
                <Select
                  data={selectOptions}
                  label="Date range"
                  inputProps={{
                    labelStyle: 'stacked',
                    inputStyle: 'box',
                  }}
                  responsive={responsiveSelect}
                  touchUi={true}
                  value={selected}
                  onChange={onChange}
                />
                <MobiInput
                  ref={startRef}
                  disabled={disabledInput}
                  label="Start"
                  labelStyle="stacked"
                  inputStyle="box"
                  className="mbsc-font"
                  placeholder="Please Select..."
                ></MobiInput>
                <MobiInput
                  ref={endRef}
                  disabled={disabledInput}
                  label="End"
                  labelStyle="stacked"
                  inputStyle="box"
                  placeholder="Please Select..."
                ></MobiInput>
              </div>
              {/* TODO: Add styles */}
              <div className="mbsc-button-group-justified">
                <Button className="cancel-button" onClick={cancelClick}>
                  Cancel
                </Button>
                <Button className="apply-button" onClick={applyClick}>
                  Apply
                </Button>
              </div>
            </div>
            <div className="mbsc-col-sm-8 mbsc-pull-sm-4">
              <Datepicker
                controls={['calendar']}
                select="range"
                display="inline"
                theme="material"
                showRangeLabels={false}
                pages="auto"
                startInput={start}
                endInput={end}
                returnFormat="iso8601"
                showOnClick={false}
                showOnFocus={false}
                value={selectedDate}
                onChange={onDateChange}
              ></Datepicker>
            </div>
          </div>
        </div>
      </Popup>
    </>
  );
}

export default DateRangePicker;
