import { useApolloClient } from '@apollo/client';
import {
  SearchDeviceTypesQueryResult,
  SearchDeviceTypesQueryVariables,
  SearchDeviceTypesDocument,
  InsertDeviceTypeDocument,
  InsertDeviceTypeMutationResult,
  InsertDeviceTypeMutationVariables,
  DeviceTypeFieldsFragment,
} from '@webapp/graphql';
import { SelectOption } from '@webapp/types';

export interface useLoadDeviceTypes {
  search: (searchValue: string) => Promise<SelectOption[]>;
  onCreateOption: (value: string) => Promise<SelectOption | undefined>;
}

export function useLoadDeviceTypes(): useLoadDeviceTypes {
  const client = useApolloClient();

  return {
    onCreateOption: async (
      value: string
    ): Promise<SelectOption | undefined> => {
      const { data } = await client.mutate<
        InsertDeviceTypeMutationResult['data'],
        InsertDeviceTypeMutationVariables
      >({
        mutation: InsertDeviceTypeDocument,
        variables: {
          deviceType: {
            value,
          },
        },
      });

      const deviceTypes = data?.insert_deviceType?.returning ?? [];

      const [deviceType] = deviceTypes;

      if (deviceType) {
        return {
          label: deviceType.value,
          value: deviceType.id,
        };
      }

      return undefined;
    },
    search: async (searchValue: string) => {
      const { data } = await client.query<
        SearchDeviceTypesQueryResult['data'],
        SearchDeviceTypesQueryVariables
      >({
        query: SearchDeviceTypesDocument,
        variables: {
          searchValue: `%${searchValue}%`,
        },
      });

      const devices = data?.deviceType ?? [];

      return devices.map((deviceType: DeviceTypeFieldsFragment) => ({
        label: deviceType.value,
        value: deviceType.id,
      }));
    },
  };
}

export default useLoadDeviceTypes;
