import {
  Button,
  ButtonGroup,
  FormControl,
  FormLabel,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  Select,
  Stack,
} from '@chakra-ui/react';
import * as React from 'react';
import { BsSearch } from 'react-icons/bs';
import { RiAddFill } from 'react-icons/ri';
import { observer } from 'mobx-react-lite';
import { useStores } from '@webapp/state-models';
import {
  useInsertLeadFormMutation,
  useUpdateLeadFormMutation,
} from '@webapp/graphql';
import { useNavigate } from 'react-router-dom';

export const TableActions = observer(() => {
  const [insertLeadForm, { data, loading, error }] =
    useInsertLeadFormMutation();

  const { workspace } = useStores();

  const navigate = useNavigate();
  async function createLeadForm() {
    const leadForm = {
      name: 'New Form',
      workspaceId: workspace?.id,
      leadFormSteps: {
        data: [
          {
            name: 'Contact us',
            callToAction: 'Continue',
            order: 0,
            workspaceId: workspace?.id,
            leadFormFields: {
              data: [
                {
                  label: 'First name',
                  apiFieldKey: 'firstName',
                  type: 'string',
                  order: 0,
                  workspaceId: workspace?.id,
                  validationProps: {
                    required: 'This field is required.',
                  },
                },
                {
                  label: 'Last name',
                  apiFieldKey: 'lastName',
                  type: 'string',
                  order: 0,
                  workspaceId: workspace?.id,
                  validationProps: {
                    required: 'This field is required.',
                  },
                },
                {
                  label: 'Email',
                  apiFieldKey: 'email',
                  type: 'string',
                  order: 0,
                  workspaceId: workspace?.id,
                  validationProps: {
                    required: 'This field is required.',
                    pattern: {
                      value: '^[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,}$',
                      message: 'Please enter a valid email address',
                    },
                  },
                },
                {
                  label: 'Phone Number',
                  apiFieldKey: 'phoneNumber',
                  type: 'string',
                  order: 0,
                  workspaceId: workspace?.id,
                  validationProps: {
                    required: 'This field is required.',
                    pattern: {
                      value:
                        '^[+]?[(]?[0-9]{3}[)]?[-s.]?[0-9]{3}[-s.]?[0-9]{4,6}$',
                      message: 'Please enter a valid phone number',
                    },
                  },
                },
              ],
            },
          },
          {
            name: 'Thank you!',
            callToAction: 'Close',
            workspaceId: workspace?.id,
            canDelete: false,
            order: 1,
            type: 'success-page',
            canReOrder: false,
            leadFormFields: {
              data: [
                {
                  label: 'Success',
                  apiFieldKey: 'formComplete',
                  type: 'formComplete',
                  order: 0,
                  workspaceId: workspace?.id,
                },
              ],
            },
          },
        ],
      },
    };

    const data = await insertLeadForm({ variables: { leadForm } });

    navigate(
      `/settings/leadform/${data.data?.insert_leadForm?.returning[0].id}/edit`
    );
  }

  return (
    <Stack
      spacing="4"
      direction={{ base: 'column', md: 'row' }}
      justify="space-between"
    >
      <HStack>
        <FormControl minW={{ md: '320px' }} id="search">
          <InputGroup size="sm">
            <FormLabel srOnly>Filter </FormLabel>
            <InputLeftElement pointerEvents="none" color="gray.400">
              <BsSearch />
            </InputLeftElement>
            <Input rounded="base" type="search" placeholder="Filter..." />
          </InputGroup>
        </FormControl>
        <Select
          w={{ base: '300px', md: 'unset' }}
          rounded="base"
          size="sm"
          placeholder="All status"
        >
          <option>All status</option>
          <option>Published</option>
          <option>Draft</option>
        </Select>
      </HStack>
      <ButtonGroup size="sm" variant="outline">
        <Button
          onClick={createLeadForm}
          iconSpacing="1"
          isLoading={loading}
          leftIcon={<RiAddFill fontSize="1.25em" />}
        >
          New form
        </Button>
        {/* <Button
          iconSpacing="1"
          leftIcon={<RiArrowRightUpLine fontSize="1.25em" />}
        >
          Export CSV
        </Button> */}
      </ButtonGroup>
    </Stack>
  );
});

export default TableActions;
