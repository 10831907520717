import { chakra, Center } from '@chakra-ui/react';
import React, { forwardRef } from 'react';
import ReactSignatureCanvas from 'react-signature-canvas';

interface GratuitySignatureViewProps {
  hide: boolean;
  isLoading: boolean;
  onComplete: () => void;
  patientId?: string;
  providerId?: string;
  userId?: string;
}

const GratuitySignatureView = forwardRef<
  ReactSignatureCanvas,
  GratuitySignatureViewProps
>(({ hide = false, isLoading }, ref) => (
  <Center display={hide ? 'none' : undefined}>
    <chakra.div
      borderColor={'gray.600'}
      borderWidth={1}
      boxSizing="border-box"
      margin="25px 0"
      maxWidth="402px"
    >
      {!isLoading && (
        <>
          {/* @ts-ignore-error React 18 */}
          <ReactSignatureCanvas
            ref={ref}
            canvasProps={{
              height: 200,
              width: 400,
              className: 'sigCanvas',
            }}
          />
        </>
      )}
    </chakra.div>
  </Center>
));

// Display name for DevTools and stack traces (optional but recommended)
GratuitySignatureView.displayName = 'GratuitySignatureView';

export default GratuitySignatureView;
