/* eslint-disable max-classes-per-file */
import { createElement } from 'react';
import {
  PropertyGridEditorCollection,
  localization,
} from 'survey-creator-react';
import { ElementFactory, Question, Serializer, SvgRegistry } from 'survey-core';
import {
  SurveyQuestionElementBase,
  ReactQuestionFactory,
} from 'survey-react-ui';
import { Box } from '@chakra-ui/react';
import { FlatRepository, PdfBrick, FlatQuestion } from 'survey-pdf';

import { TextEditor } from '@webapp/ui';
import Icon from './icon.svg?raw';

const CUSTOM_TYPE = 'rich-text-readonly';

// A model for the new question type
export class RichTextReadOnlyModel extends Question {
  getType() {
    return CUSTOM_TYPE;
  }

  get richText() {
    return this.getPropertyValue('richText');
  }

  set richText(val) {
    this.setPropertyValue('richText', val);
  }
}

// Register `RichTextReadOnlyModel` as a model for the `rich-text-readonly` type
export function registerRichTextReadOnly() {
  ElementFactory.Instance.registerElement(
    CUSTOM_TYPE,
    (name) => new RichTextReadOnlyModel(name)
  );
}

// Specify display names for the question type and its properties
const locale = localization.getLocale('');
locale.qt[CUSTOM_TYPE] = 'RichTextReadOnly';

// Register an SVG icon for the question type
SvgRegistry.registerIconFromSvg(CUSTOM_TYPE, Icon);

// Add question type metadata for further serialization into JSON
Serializer.addClass(
  CUSTOM_TYPE,
  [
    {
      name: 'value:rich-text',
      default: '',
      category: 'general',
      visibleIndex: 2, // After the Name and Title
    },
  ],
  () => new RichTextReadOnlyModel('RichTextReadOnly'),
  'question'
);

// A class that renders questions of the new type in the UI
export class RichTextReadOnly extends SurveyQuestionElementBase {
  constructor(props) {
    super(props);
    this.state = { value: this.question.value };
  }

  get question() {
    return this.questionBase;
  }

  get value() {
    return this.question.value;
  }

  get richText() {
    return this.question.richText;
  }

  set richText(val) {
    this.question.richText = val;
  }

  // Support the read-only and design modes
  get style() {
    return this.question.getPropertyValue('readOnly') ||
      this.question.isDesignMode
      ? undefined
      : { pointerEvents: 'none' };
  }

  renderElement() {
    return (
      <TextEditor
        value={this.question.value}
        readOnly={!this.question.isDesignMode}
        onChange={(value) => {
          this.question.richText = value;
        }}
      />
    );
  }
}

// Register `RichTextReadOnly` as a class that renders `rich-text-readonly` questions
ReactQuestionFactory.Instance.registerQuestion(CUSTOM_TYPE, (props) =>
  createElement(RichTextReadOnly, props)
);

class CustomPdfBrick extends PdfBrick {
  override async renderInteractive() {
    const { doc } = this.controller;
    // Define the dimensions of the brick
    const brickWidth = 300;
    const brickHeight = 200;

    // Define the coordinates where the brick will be placed
    const x = 10;
    const y = 10;

    // Draw a rectangle to represent the brick
    doc.rect(x, y, brickWidth, brickHeight);

    // Insert the text in the brick
    // We add a small offset (2 units) to position the text nicely within the brick
    doc.text(this.question.value, x + 2, y + brickHeight / 2);
  }
}

class FlatRichText extends FlatQuestion {
  override async generateFlatsContent(point: any) {
    const rect = { ...point };
    rect.yBot = point.yTop + 20;
    rect.xRight = point.xLeft + 100;
    return [new CustomPdfBrick(this.question, this.controller, rect)];
  }
}
FlatRepository.getInstance().register(
  'rich-text-readonly',
  FlatRepository.getRenderer('html')
);
