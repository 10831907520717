import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { PurchaseOrderFieldsFragmentDoc } from '../../fragments/purchaseOrderFields.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type InsertPurchaseOrderMutationVariables = Types.Exact<{
  purchaseOrder: Types.PurchaseOrder_Insert_Input;
}>;


export type InsertPurchaseOrderMutation = { __typename?: 'mutation_root', insert_purchaseOrder?: { __typename?: 'purchaseOrder_mutation_response', returning: Array<{ __typename?: 'purchaseOrder', id: any, orderNumber: string, vendorOrderNumber?: string | null | undefined, note?: string | null | undefined, status: string, cost: number, createdAt: any, updatedAt: any, archived: boolean, consumableType: { __typename?: 'consumableType', id: any, name: string }, consumableBrand: { __typename?: 'consumableBrand', id: any, name: string }, consumables: Array<{ __typename?: 'consumable', id: any, cost: number, quantity: any, originalQuantity: any, metadata: any, type: { __typename?: 'consumableType', id: any, name: string }, brand?: { __typename?: 'consumableBrand', id: any, name: string } | null | undefined }> }> } | null | undefined };


export const InsertPurchaseOrderDocument = gql`
    mutation InsertPurchaseOrder($purchaseOrder: purchaseOrder_insert_input!) {
  insert_purchaseOrder(objects: [$purchaseOrder]) {
    returning {
      ...PurchaseOrderFields
    }
  }
}
    ${PurchaseOrderFieldsFragmentDoc}`;
export type InsertPurchaseOrderMutationFn = Apollo.MutationFunction<InsertPurchaseOrderMutation, InsertPurchaseOrderMutationVariables>;

/**
 * __useInsertPurchaseOrderMutation__
 *
 * To run a mutation, you first call `useInsertPurchaseOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertPurchaseOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertPurchaseOrderMutation, { data, loading, error }] = useInsertPurchaseOrderMutation({
 *   variables: {
 *      purchaseOrder: // value for 'purchaseOrder'
 *   },
 * });
 */
export function useInsertPurchaseOrderMutation(baseOptions?: Apollo.MutationHookOptions<InsertPurchaseOrderMutation, InsertPurchaseOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertPurchaseOrderMutation, InsertPurchaseOrderMutationVariables>(InsertPurchaseOrderDocument, options);
      }
export type InsertPurchaseOrderMutationHookResult = ReturnType<typeof useInsertPurchaseOrderMutation>;
export type InsertPurchaseOrderMutationResult = Apollo.MutationResult<InsertPurchaseOrderMutation>;
export type InsertPurchaseOrderMutationOptions = Apollo.BaseMutationOptions<InsertPurchaseOrderMutation, InsertPurchaseOrderMutationVariables>;