import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GoogleOnboardingStatusQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GoogleOnboardingStatusQuery = { __typename?: 'query_root', googleOnboardingStatus: { __typename?: 'GoogleOnboardingStatusOutput', hasAuthProfile: boolean, hasGoogleAccount: boolean, hasGoogleLocation: boolean, authProfiles: Array<{ __typename?: 'RMAuthProfile', id: any, metadata: any }>, googleAccounts: Array<{ __typename?: 'RMGoogleAccount', accountName: string, id: any, name: string, type: string }> } };


export const GoogleOnboardingStatusDocument = gql`
    query GoogleOnboardingStatus {
  googleOnboardingStatus {
    authProfiles {
      id
      metadata
    }
    googleAccounts {
      accountName
      id
      name
      type
    }
    hasAuthProfile
    hasGoogleAccount
    hasGoogleLocation
  }
}
    `;

/**
 * __useGoogleOnboardingStatusQuery__
 *
 * To run a query within a React component, call `useGoogleOnboardingStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGoogleOnboardingStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGoogleOnboardingStatusQuery({
 *   variables: {
 *   },
 * });
 */
export function useGoogleOnboardingStatusQuery(baseOptions?: Apollo.QueryHookOptions<GoogleOnboardingStatusQuery, GoogleOnboardingStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GoogleOnboardingStatusQuery, GoogleOnboardingStatusQueryVariables>(GoogleOnboardingStatusDocument, options);
      }
export function useGoogleOnboardingStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GoogleOnboardingStatusQuery, GoogleOnboardingStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GoogleOnboardingStatusQuery, GoogleOnboardingStatusQueryVariables>(GoogleOnboardingStatusDocument, options);
        }
export type GoogleOnboardingStatusQueryHookResult = ReturnType<typeof useGoogleOnboardingStatusQuery>;
export type GoogleOnboardingStatusLazyQueryHookResult = ReturnType<typeof useGoogleOnboardingStatusLazyQuery>;
export type GoogleOnboardingStatusQueryResult = Apollo.QueryResult<GoogleOnboardingStatusQuery, GoogleOnboardingStatusQueryVariables>;