import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type RetryCreateSubscriptionMutationVariables = Types.Exact<{
  invoiceMembershipId: Types.Scalars['uuid'];
}>;


export type RetryCreateSubscriptionMutation = { __typename?: 'mutation_root', retryCreateSubscription: { __typename?: 'SuccessAnswer', success: boolean } };


export const RetryCreateSubscriptionDocument = gql`
    mutation RetryCreateSubscription($invoiceMembershipId: uuid!) {
  retryCreateSubscription(invoiceMembershipId: $invoiceMembershipId) {
    success
  }
}
    `;
export type RetryCreateSubscriptionMutationFn = Apollo.MutationFunction<RetryCreateSubscriptionMutation, RetryCreateSubscriptionMutationVariables>;

/**
 * __useRetryCreateSubscriptionMutation__
 *
 * To run a mutation, you first call `useRetryCreateSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRetryCreateSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [retryCreateSubscriptionMutation, { data, loading, error }] = useRetryCreateSubscriptionMutation({
 *   variables: {
 *      invoiceMembershipId: // value for 'invoiceMembershipId'
 *   },
 * });
 */
export function useRetryCreateSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<RetryCreateSubscriptionMutation, RetryCreateSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RetryCreateSubscriptionMutation, RetryCreateSubscriptionMutationVariables>(RetryCreateSubscriptionDocument, options);
      }
export type RetryCreateSubscriptionMutationHookResult = ReturnType<typeof useRetryCreateSubscriptionMutation>;
export type RetryCreateSubscriptionMutationResult = Apollo.MutationResult<RetryCreateSubscriptionMutation>;
export type RetryCreateSubscriptionMutationOptions = Apollo.BaseMutationOptions<RetryCreateSubscriptionMutation, RetryCreateSubscriptionMutationVariables>;