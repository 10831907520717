import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetCompensationStrategyQueryVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
}>;


export type GetCompensationStrategyQuery = { __typename?: 'query_root', compensationStrategy_by_pk?: { __typename?: 'compensationStrategy', createdAt: any, id: any, isActive: boolean, name: string, strategyType: string, updatedAt: any, categories: Array<{ __typename?: 'compensationStrategyTierCategory', id: any, serviceCategory: { __typename?: 'serviceCategory', id: any, title: string } }>, commissions: Array<{ __typename?: 'compensationStrategyCommission', compensationAmount: number, compensationType: string, id: any, maxApplyValue: number, minApplyValue: number, membership?: { __typename?: 'membership', id: any, name: string } | null | undefined, package?: { __typename?: 'package', id: any, name: string } | null | undefined, service?: { __typename?: 'service', id: any, name: string } | null | undefined }>, exemptions: Array<{ __typename?: 'compensationStrategyTierExemption', id: any, membership?: { __typename?: 'membership', id: any, name: string } | null | undefined, package?: { __typename?: 'package', id: any, name: string } | null | undefined, service?: { __typename?: 'service', id: any, name: string } | null | undefined }>, inclusions: Array<{ __typename?: 'compensationStrategyTierInclusion', membership?: { __typename?: 'membership', id: any, name: string } | null | undefined, package?: { __typename?: 'package', id: any, name: string } | null | undefined, service?: { __typename?: 'service', id: any, name: string } | null | undefined }>, tiers: Array<{ __typename?: 'compensationStrategyTier', compensationAmount: number, compensationType: string, id: any, maxApplyValue: number, minApplyValue: number, name: string }> } | null | undefined };


export const GetCompensationStrategyDocument = gql`
    query GetCompensationStrategy($id: uuid!) {
  compensationStrategy_by_pk(id: $id) {
    categories {
      id
      serviceCategory {
        id
        title
      }
    }
    commissions {
      compensationAmount
      compensationType
      id
      maxApplyValue
      membership {
        id
        name
      }
      minApplyValue
      package {
        id
        name
      }
      service {
        id
        name
      }
    }
    exemptions {
      id
      membership {
        id
        name
      }
      package {
        id
        name
      }
      service {
        id
        name
      }
    }
    inclusions {
      membership {
        id
        name
      }
      package {
        id
        name
      }
      service {
        id
        name
      }
    }
    tiers {
      compensationAmount
      compensationType
      id
      maxApplyValue
      minApplyValue
      name
    }
    createdAt
    id
    isActive
    name
    strategyType
    updatedAt
  }
}
    `;

/**
 * __useGetCompensationStrategyQuery__
 *
 * To run a query within a React component, call `useGetCompensationStrategyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompensationStrategyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompensationStrategyQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCompensationStrategyQuery(baseOptions: Apollo.QueryHookOptions<GetCompensationStrategyQuery, GetCompensationStrategyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCompensationStrategyQuery, GetCompensationStrategyQueryVariables>(GetCompensationStrategyDocument, options);
      }
export function useGetCompensationStrategyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCompensationStrategyQuery, GetCompensationStrategyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCompensationStrategyQuery, GetCompensationStrategyQueryVariables>(GetCompensationStrategyDocument, options);
        }
export type GetCompensationStrategyQueryHookResult = ReturnType<typeof useGetCompensationStrategyQuery>;
export type GetCompensationStrategyLazyQueryHookResult = ReturnType<typeof useGetCompensationStrategyLazyQuery>;
export type GetCompensationStrategyQueryResult = Apollo.QueryResult<GetCompensationStrategyQuery, GetCompensationStrategyQueryVariables>;