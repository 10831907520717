import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type InitializeNylasExchangeMutationVariables = Types.Exact<{
  code: Types.Scalars['String'];
  state: Types.Scalars['String'];
}>;


export type InitializeNylasExchangeMutation = { __typename?: 'mutation_root', initializeNylasExchange: { __typename?: 'NylasExchangeOutput', message: string } };


export const InitializeNylasExchangeDocument = gql`
    mutation InitializeNylasExchange($code: String!, $state: String!) {
  initializeNylasExchange(code: $code, state: $state) {
    message
  }
}
    `;
export type InitializeNylasExchangeMutationFn = Apollo.MutationFunction<InitializeNylasExchangeMutation, InitializeNylasExchangeMutationVariables>;

/**
 * __useInitializeNylasExchangeMutation__
 *
 * To run a mutation, you first call `useInitializeNylasExchangeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInitializeNylasExchangeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [initializeNylasExchangeMutation, { data, loading, error }] = useInitializeNylasExchangeMutation({
 *   variables: {
 *      code: // value for 'code'
 *      state: // value for 'state'
 *   },
 * });
 */
export function useInitializeNylasExchangeMutation(baseOptions?: Apollo.MutationHookOptions<InitializeNylasExchangeMutation, InitializeNylasExchangeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InitializeNylasExchangeMutation, InitializeNylasExchangeMutationVariables>(InitializeNylasExchangeDocument, options);
      }
export type InitializeNylasExchangeMutationHookResult = ReturnType<typeof useInitializeNylasExchangeMutation>;
export type InitializeNylasExchangeMutationResult = Apollo.MutationResult<InitializeNylasExchangeMutation>;
export type InitializeNylasExchangeMutationOptions = Apollo.BaseMutationOptions<InitializeNylasExchangeMutation, InitializeNylasExchangeMutationVariables>;