import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { PackageFieldsFragmentDoc } from '../../fragments/packageFields.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetPackagesQueryVariables = Types.Exact<{
  where: Types.Package_Bool_Exp;
  limit?: Types.Maybe<Types.Scalars['Int']>;
  offset?: Types.Maybe<Types.Scalars['Int']>;
  orderBy?: Types.Maybe<Array<Types.Package_Order_By> | Types.Package_Order_By>;
}>;


export type GetPackagesQuery = { __typename?: 'query_root', package: Array<{ __typename?: 'package', id: any, name: string, price: number, taxRate: any, quantity: number, isActive: boolean, createdAt: any, description?: string | null | undefined, image?: string | null | undefined, availableInStore?: boolean | null | undefined, updatedAt: any, service: { __typename?: 'service', id: any, name: string, isProduct?: boolean | null | undefined } }> };


export const GetPackagesDocument = gql`
    query GetPackages($where: package_bool_exp!, $limit: Int, $offset: Int, $orderBy: [package_order_by!]) {
  package(where: $where, limit: $limit, offset: $offset, order_by: $orderBy) {
    ...PackageFields
  }
}
    ${PackageFieldsFragmentDoc}`;

/**
 * __useGetPackagesQuery__
 *
 * To run a query within a React component, call `useGetPackagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPackagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPackagesQuery({
 *   variables: {
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetPackagesQuery(baseOptions: Apollo.QueryHookOptions<GetPackagesQuery, GetPackagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPackagesQuery, GetPackagesQueryVariables>(GetPackagesDocument, options);
      }
export function useGetPackagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPackagesQuery, GetPackagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPackagesQuery, GetPackagesQueryVariables>(GetPackagesDocument, options);
        }
export type GetPackagesQueryHookResult = ReturnType<typeof useGetPackagesQuery>;
export type GetPackagesLazyQueryHookResult = ReturnType<typeof useGetPackagesLazyQuery>;
export type GetPackagesQueryResult = Apollo.QueryResult<GetPackagesQuery, GetPackagesQueryVariables>;