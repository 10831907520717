import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetAppointmentServiceAvailabilityQueryVariables = Types.Exact<{
  locationId: Types.Scalars['uuid'];
  serviceIds: Array<Types.Maybe<Types.Scalars['uuid']>> | Types.Maybe<Types.Scalars['uuid']>;
  day: Types.Scalars['String'];
  numberOfDays?: Types.Maybe<Types.Scalars['Int']>;
  providerId?: Types.Maybe<Types.Scalars['uuid']>;
}>;


export type GetAppointmentServiceAvailabilityQuery = { __typename?: 'query_root', getAvailabilityForService?: Array<{ __typename?: 'ServiceAvailabilityOutput', start: string, formattedDay: string, dateTime: string, provider?: { __typename?: 'provider', id: any, firstName: string, lastName: string, profilePicture?: string | null | undefined } | null | undefined } | null | undefined> | null | undefined };


export const GetAppointmentServiceAvailabilityDocument = gql`
    query getAppointmentServiceAvailability($locationId: uuid!, $serviceIds: [uuid]!, $day: String!, $numberOfDays: Int, $providerId: uuid) {
  getAvailabilityForService(
    locationId: $locationId
    serviceIds: $serviceIds
    day: $day
    numberOfDays: $numberOfDays
    providerId: $providerId
  ) {
    start
    formattedDay
    dateTime
    provider {
      id
      firstName
      lastName
      profilePicture
    }
  }
}
    `;

/**
 * __useGetAppointmentServiceAvailabilityQuery__
 *
 * To run a query within a React component, call `useGetAppointmentServiceAvailabilityQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAppointmentServiceAvailabilityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAppointmentServiceAvailabilityQuery({
 *   variables: {
 *      locationId: // value for 'locationId'
 *      serviceIds: // value for 'serviceIds'
 *      day: // value for 'day'
 *      numberOfDays: // value for 'numberOfDays'
 *      providerId: // value for 'providerId'
 *   },
 * });
 */
export function useGetAppointmentServiceAvailabilityQuery(baseOptions: Apollo.QueryHookOptions<GetAppointmentServiceAvailabilityQuery, GetAppointmentServiceAvailabilityQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAppointmentServiceAvailabilityQuery, GetAppointmentServiceAvailabilityQueryVariables>(GetAppointmentServiceAvailabilityDocument, options);
      }
export function useGetAppointmentServiceAvailabilityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAppointmentServiceAvailabilityQuery, GetAppointmentServiceAvailabilityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAppointmentServiceAvailabilityQuery, GetAppointmentServiceAvailabilityQueryVariables>(GetAppointmentServiceAvailabilityDocument, options);
        }
export type GetAppointmentServiceAvailabilityQueryHookResult = ReturnType<typeof useGetAppointmentServiceAvailabilityQuery>;
export type GetAppointmentServiceAvailabilityLazyQueryHookResult = ReturnType<typeof useGetAppointmentServiceAvailabilityLazyQuery>;
export type GetAppointmentServiceAvailabilityQueryResult = Apollo.QueryResult<GetAppointmentServiceAvailabilityQuery, GetAppointmentServiceAvailabilityQueryVariables>;