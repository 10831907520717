import { useDeletePatientFlowsheetRowsMutation } from '@webapp/graphql';
import { FieldValues } from 'react-hook-form';
import toast from 'react-hot-toast';

export function useDeletePatientFlowsheetRows(params?: {
  refetchQueries: string[];
}): [(values: FieldValues[]) => void, { isLoading: boolean }] {
  const refetchQueries = params?.refetchQueries || ['PatientFlowsheets'];

  const [deletePatientFlowsheetRows, { loading }] =
    useDeletePatientFlowsheetRowsMutation({
      onCompleted: () => {
        toast.success('Row updated successfully');
      },
      onError: (error) => {
        toast.error(error.message);
      },
      refetchQueries,
    });

  const deleteFlowsheetRows = (rows: FieldValues[]) => {
    const patientFlowsheetRowIds = rows.map(({ row }) => row.id);

    deletePatientFlowsheetRows({
      variables: {
        where: {
          id: {
            _in: patientFlowsheetRowIds,
          },
        },
      },
    });
  };

  return [deleteFlowsheetRows, { isLoading: loading }];
}

export default useDeletePatientFlowsheetRows;
