import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { DocumentFieldsFragmentDoc } from '../../fragments/documentFields.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type UpdateDocumentMutationVariables = Types.Exact<{
  set: Types.Document_Set_Input;
  id: Types.Scalars['uuid'];
}>;


export type UpdateDocumentMutation = { __typename?: 'mutation_root', update_document_by_pk?: { __typename?: 'document', id: any, title?: string | null | undefined, filePath: string, documentType: string, documentTags: Array<{ __typename?: 'documentTag', id: any, tag: { __typename?: 'tag', color?: string | null | undefined, id: any, title: string } }>, file?: { __typename?: 'FileOnlyURL', url: string } | null | undefined } | null | undefined };


export const UpdateDocumentDocument = gql`
    mutation UpdateDocument($set: document_set_input!, $id: uuid!) {
  update_document_by_pk(_set: $set, pk_columns: {id: $id}) {
    ...DocumentFields
  }
}
    ${DocumentFieldsFragmentDoc}`;
export type UpdateDocumentMutationFn = Apollo.MutationFunction<UpdateDocumentMutation, UpdateDocumentMutationVariables>;

/**
 * __useUpdateDocumentMutation__
 *
 * To run a mutation, you first call `useUpdateDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDocumentMutation, { data, loading, error }] = useUpdateDocumentMutation({
 *   variables: {
 *      set: // value for 'set'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateDocumentMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDocumentMutation, UpdateDocumentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDocumentMutation, UpdateDocumentMutationVariables>(UpdateDocumentDocument, options);
      }
export type UpdateDocumentMutationHookResult = ReturnType<typeof useUpdateDocumentMutation>;
export type UpdateDocumentMutationResult = Apollo.MutationResult<UpdateDocumentMutation>;
export type UpdateDocumentMutationOptions = Apollo.BaseMutationOptions<UpdateDocumentMutation, UpdateDocumentMutationVariables>;