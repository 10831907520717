import * as radio from '@zag-js/radio-group';
import { useMachine, normalizeProps } from '@zag-js/react';
import { Box, chakra, Flex } from '@chakra-ui/react';
import { useId } from 'react';

export interface SegmentedControlInputProps {
  items: { label: string; value: any }[];
  onChange: (value: string) => void;
  value: any;
  defaultValue?: any;
  name: string;
}

export default function SegmentedControlInput({
  items,
  onChange,
  value,
  defaultValue,
  name,
}: SegmentedControlInputProps) {
  const [state, send] = useMachine(
    radio.machine({
      id: useId(),
      name,
      value: value ?? defaultValue,
      onChange: ({ value }) => {
        onChange(value);
      },
    })
  );

  const api = radio.connect(state, send, normalizeProps);

  return (
    <Flex
      align="center"
      pos="relative"
      bg="blackAlpha.200"
      _dark={{
        bg: 'blackAlpha.400',
      }}
      p="1.5"
      rounded="md"
      w="max-content"
      {...api.rootProps}
    >
      <chakra.div
        h="1"
        bg="white"
        zIndex="1"
        rounded="md"
        shadow="base"
        {...api.indicatorProps}
      />
      {items.map((opt) => (
        <chakra.label
          display="flex"
          gap="2"
          userSelect="none"
          cursor="pointer"
          fontSize="16"
          px="3"
          py="1"
          zIndex="2"
          _disabled={{
            cursor: 'not-allowed',
            opacity: 0.4,
          }}
          _readOnly={{
            cursor: 'default',
          }}
          key={opt.value}
          {...api.getRadioProps({ value: opt.value })}
        >
          <chakra.span
            order="2"
            {...api.getRadioLabelProps({ value: opt.value })}
          >
            {opt.label}
          </chakra.span>
          <input
            data-peer
            {...api.getRadioHiddenInputProps({ value: opt.value })}
          />
        </chakra.label>
      ))}
    </Flex>
  );
}
