interface FormatNameOptions {
  firstName?: string | null;
  lastName?: string | null;
  title?: string | null;
}

export function formatName(options?: FormatNameOptions | null) {
  const { firstName, lastName, title } = options ?? {};

  if (!options) {
    return `Unknown`;
  }

  if (title) {
    return `${title} ${firstName} ${lastName}`;
  }

  return `${firstName} ${lastName}`;
}

export default formatName;
