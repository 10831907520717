import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { AnyJsonValue } from '../custom-types/any-json-value';
/**
 * A ConflictStore model.
 */
// prettier-ignore
export const ConflictModel = types.model("Conflict").props({
  code: types.string,
    label: types.maybeNull(types.string),
    meta:  types.maybeNull(types.map(AnyJsonValue)),
}).actions((self) => ({
  
}
))
/**
 * The Conflict instance.
 */
export type Conflict = Instance<typeof ConflictModel>;

/**
 * The data of a Conflict.
 */
export type ConflictSnapshot = SnapshotOut<typeof ConflictModel>;
