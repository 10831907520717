import { Instance, SnapshotOut, types } from 'mobx-state-tree';

/**
 * A OrganizationStore model.
 */
export const OrganizationConfigurationModel = types
  .model('OrganizationConfiguration')
  .props({
    supportEmail: types.maybeNull(types.string),
    whitelabelingEnabled: types.optional(types.boolean, false),
    lightLogo: types.maybeNull(types.string),
    darkLogo: types.maybeNull(types.string),
    lightLogoSmall: types.maybeNull(types.string),
    darkLogoSmall: types.maybeNull(types.string),
    whitelabelDomain: types.maybeNull(types.string),
  });

/**
 * A OrganizationStore model.
 */
export const OrganizationModel = types.model('Organization').props({
  id: types.identifier,
  name: types.string,
  organizationConfiguration: types.maybeNull(OrganizationConfigurationModel),
});

/**
 * The Organization instance.
 */
export type Organization = Instance<typeof OrganizationModel>;

/**
 * The data of a Organization.
 */
export type OrganizationSnapshot = SnapshotOut<typeof OrganizationModel>;
