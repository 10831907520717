import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type SubscribeInvoiceLineItemsSubscriptionVariables = Types.Exact<{
  invoiceId: Types.Scalars['uuid'];
}>;


export type SubscribeInvoiceLineItemsSubscription = { __typename?: 'subscription_root', genericInvoiceLineItem: Array<{ __typename?: 'genericInvoiceLineItem', id: any, membershipId?: any | null | undefined, name: string, packageId?: any | null | undefined, postTaxTotal: any, preTaxTotal: any, pricePerUnit: number, promotionId?: any | null | undefined, quantity: number, serviceId?: any | null | undefined, taxRate: any, taxTotal: any, type?: string | null | undefined, giftCards: Array<{ __typename?: 'giftCard', id: any, redemptionCode: string }>, membership?: { __typename?: 'membership', id: any, type: Types.MembershipType_Enum } | null | undefined, provider?: { __typename?: 'provider', id: any, firstName: string, lastName: string, profilePicture?: string | null | undefined } | null | undefined, service?: { __typename?: 'service', id: any, isProduct?: boolean | null | undefined } | null | undefined }> };


export const SubscribeInvoiceLineItemsDocument = gql`
    subscription SubscribeInvoiceLineItems($invoiceId: uuid!) {
  genericInvoiceLineItem(where: {invoiceId: {_eq: $invoiceId}}) {
    giftCards {
      id
      redemptionCode
    }
    id
    membership {
      id
      type
    }
    membershipId
    name
    packageId
    postTaxTotal
    preTaxTotal
    pricePerUnit
    promotionId
    provider {
      id
      firstName
      lastName
      profilePicture
    }
    quantity
    service {
      id
      isProduct
    }
    serviceId
    taxRate
    taxTotal
    type
  }
}
    `;

/**
 * __useSubscribeInvoiceLineItemsSubscription__
 *
 * To run a query within a React component, call `useSubscribeInvoiceLineItemsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeInvoiceLineItemsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeInvoiceLineItemsSubscription({
 *   variables: {
 *      invoiceId: // value for 'invoiceId'
 *   },
 * });
 */
export function useSubscribeInvoiceLineItemsSubscription(baseOptions: Apollo.SubscriptionHookOptions<SubscribeInvoiceLineItemsSubscription, SubscribeInvoiceLineItemsSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<SubscribeInvoiceLineItemsSubscription, SubscribeInvoiceLineItemsSubscriptionVariables>(SubscribeInvoiceLineItemsDocument, options);
      }
export type SubscribeInvoiceLineItemsSubscriptionHookResult = ReturnType<typeof useSubscribeInvoiceLineItemsSubscription>;
export type SubscribeInvoiceLineItemsSubscriptionResult = Apollo.SubscriptionResult<SubscribeInvoiceLineItemsSubscription>;