import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
export type FlowsheetFieldsMinimalFragment = { __typename?: 'flowsheet', id: any, title: string };

export const FlowsheetFieldsMinimalFragmentDoc = gql`
    fragment FlowsheetFieldsMinimal on flowsheet {
  id
  title
}
    `;