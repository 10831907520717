import * as Types from '../../generated/graphql';

import { gql } from '@apollo/client';
export type SocialMediaAccountFieldsFragment = { __typename?: 'socialMediaAccount', id: any, name: string, platform: Types.SocialMediaPlatform_Enum, profilePicture?: string | null | undefined };

export const SocialMediaAccountFieldsFragmentDoc = gql`
    fragment SocialMediaAccountFields on socialMediaAccount {
  id
  name
  platform
  profilePicture
}
    `;