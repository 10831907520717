import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { PatientWalletFieldsFragmentDoc } from '../../fragments/patientWalletFields.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetPatientWalletQueryVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
}>;


export type GetPatientWalletQuery = { __typename?: 'query_root', patientWallet_by_pk?: { __typename?: 'patientWallet', id: any, balance: number, discount: number, patientWalletCoupons: Array<{ __typename?: 'patientWalletCoupon', coupon: { __typename?: 'coupon', id: any, name: string, rule: string, amount: any, type: Types.CouponType_Enum, scope: Types.CouponScope_Enum, validFrom: any, validUntil: any, isActive: boolean, packageId?: any | null | undefined, isSystemGenerated: boolean, locationCoupons: Array<{ __typename?: 'locationCoupon', location: { __typename?: 'location', id: any, isActive: boolean, name: string, address?: string | null | undefined, phoneNumber?: string | null | undefined, email?: string | null | undefined, maxPatientsPerTimeslot?: number | null | undefined, workspaceId: any, clinicId?: number | null | undefined, addressId?: any | null | undefined, workStartTime?: string | null | undefined, workEndTime?: string | null | undefined, timezone?: string | null | undefined, payrixMerchantId?: string | null | undefined, finixMerchantId?: string | null | undefined, locationSchedules: Array<{ __typename?: 'locationSchedule', id: any, schedule: any, effectiveFrom?: any | null | undefined, createdAt: any, updatedAt: any, locationId: any }>, fullAddress?: { __typename?: 'address', addressLine1: string, addressLine2?: string | null | undefined, city: string, id: any, state: string, zipCode: string } | null | undefined, rooms: Array<{ __typename?: 'room', id: any, name: string }> } }>, serviceCoupons: Array<{ __typename?: 'serviceCoupon', service: { __typename?: 'service', id: any, name: string } }> } }> } | null | undefined };


export const GetPatientWalletDocument = gql`
    query GetPatientWallet($id: uuid!) {
  patientWallet_by_pk(id: $id) {
    ...PatientWalletFields
  }
}
    ${PatientWalletFieldsFragmentDoc}`;

/**
 * __useGetPatientWalletQuery__
 *
 * To run a query within a React component, call `useGetPatientWalletQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPatientWalletQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPatientWalletQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPatientWalletQuery(baseOptions: Apollo.QueryHookOptions<GetPatientWalletQuery, GetPatientWalletQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPatientWalletQuery, GetPatientWalletQueryVariables>(GetPatientWalletDocument, options);
      }
export function useGetPatientWalletLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPatientWalletQuery, GetPatientWalletQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPatientWalletQuery, GetPatientWalletQueryVariables>(GetPatientWalletDocument, options);
        }
export type GetPatientWalletQueryHookResult = ReturnType<typeof useGetPatientWalletQuery>;
export type GetPatientWalletLazyQueryHookResult = ReturnType<typeof useGetPatientWalletLazyQuery>;
export type GetPatientWalletQueryResult = Apollo.QueryResult<GetPatientWalletQuery, GetPatientWalletQueryVariables>;