import { Input, InputGroup } from '@chakra-ui/react';
import { ChangeEvent, useEffect, useRef } from 'react';
import DEFAULT_CONTROL_STYLE from './styles/DefaultControlStyle';
import DEFAULT_INPUT_CONTAINER_STYLE from './styles/DefaultInputContainerStyle';
import { TextInputProps } from './types';

export function TextInput({
  leftElement,
  name,
  onChange,
  placeholder,
  styles = {},
  value,
  autoFocus = false,
  ...props
}: Omit<TextInputProps, 'type'>) {
  const inputRef = useRef<HTMLInputElement>(null);

  const inputStyles = { ...DEFAULT_CONTROL_STYLE, ...styles.control };

  const containerStyles = {
    ...DEFAULT_INPUT_CONTAINER_STYLE,
    ...styles.inputContainer,
  };

  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    const stringValue = e.currentTarget.value;
    onChange(stringValue);
  }

  useEffect(() => {
    if (autoFocus && inputRef.current) {
      inputRef.current.focus();
    }
  }, [autoFocus]);

  return (
    <InputGroup {...containerStyles}>
      {leftElement && leftElement}
      <Input
        ref={inputRef}
        errorBorderColor="red.300"
        id={name}
        onChange={handleChange}
        placeholder={placeholder}
        type="text"
        value={value}
        {...inputStyles}
        {...props}
      />
    </InputGroup>
  );
}

export default TextInput;
