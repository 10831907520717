import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type DeleteUnusedAppointmentServiceConsumableTypeMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
}>;


export type DeleteUnusedAppointmentServiceConsumableTypeMutation = { __typename?: 'mutation_root', delete_unusedAppointmentServiceConsumableType_by_pk?: { __typename?: 'unusedAppointmentServiceConsumableType', id: any } | null | undefined };


export const DeleteUnusedAppointmentServiceConsumableTypeDocument = gql`
    mutation DeleteUnusedAppointmentServiceConsumableType($id: uuid!) {
  delete_unusedAppointmentServiceConsumableType_by_pk(id: $id) {
    id
  }
}
    `;
export type DeleteUnusedAppointmentServiceConsumableTypeMutationFn = Apollo.MutationFunction<DeleteUnusedAppointmentServiceConsumableTypeMutation, DeleteUnusedAppointmentServiceConsumableTypeMutationVariables>;

/**
 * __useDeleteUnusedAppointmentServiceConsumableTypeMutation__
 *
 * To run a mutation, you first call `useDeleteUnusedAppointmentServiceConsumableTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUnusedAppointmentServiceConsumableTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUnusedAppointmentServiceConsumableTypeMutation, { data, loading, error }] = useDeleteUnusedAppointmentServiceConsumableTypeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteUnusedAppointmentServiceConsumableTypeMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUnusedAppointmentServiceConsumableTypeMutation, DeleteUnusedAppointmentServiceConsumableTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUnusedAppointmentServiceConsumableTypeMutation, DeleteUnusedAppointmentServiceConsumableTypeMutationVariables>(DeleteUnusedAppointmentServiceConsumableTypeDocument, options);
      }
export type DeleteUnusedAppointmentServiceConsumableTypeMutationHookResult = ReturnType<typeof useDeleteUnusedAppointmentServiceConsumableTypeMutation>;
export type DeleteUnusedAppointmentServiceConsumableTypeMutationResult = Apollo.MutationResult<DeleteUnusedAppointmentServiceConsumableTypeMutation>;
export type DeleteUnusedAppointmentServiceConsumableTypeMutationOptions = Apollo.BaseMutationOptions<DeleteUnusedAppointmentServiceConsumableTypeMutation, DeleteUnusedAppointmentServiceConsumableTypeMutationVariables>;