import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetAudienceEditorOptionsQueryVariables = Types.Exact<{
  workspaceId: Types.Scalars['uuid'];
}>;


export type GetAudienceEditorOptionsQuery = { __typename?: 'query_root', provider: Array<{ __typename?: 'provider', id: any, firstName: string, lastName: string }>, location: Array<{ __typename?: 'location', id: any, name: string }>, workspace?: { __typename?: 'workspace', workspaceConfiguration?: { __typename?: 'workspaceConfiguration', leadStatusOptions?: any | null | undefined, patientReferralOptions?: any | null | undefined } | null | undefined } | null | undefined, leadFormSource: Array<{ __typename?: 'leadForm', name: string }>, source: Array<{ __typename?: 'patientWorkspace', source?: string | null | undefined }>, serviceCategory: Array<{ __typename?: 'serviceCategory', id: any, title: string }>, service: Array<{ __typename?: 'service', id: any, name: string }>, membership: Array<{ __typename?: 'membership', id: any, name: string }>, patientTag: Array<{ __typename?: 'patientTag', id: any, title: string }> };


export const GetAudienceEditorOptionsDocument = gql`
    query getAudienceEditorOptions($workspaceId: uuid!) {
  provider(where: {active: {_eq: true}}, order_by: {firstName: asc}) {
    id
    firstName
    lastName
  }
  location(where: {isActive: {_eq: true}}, order_by: {name: asc}) {
    id
    name
  }
  workspace: workspace_by_pk(id: $workspaceId) {
    workspaceConfiguration {
      leadStatusOptions
      patientReferralOptions
    }
  }
  leadFormSource: leadForm(distinct_on: name, order_by: {name: asc}) {
    name
  }
  source: patientWorkspace(distinct_on: source, order_by: {source: asc}) {
    source
  }
  serviceCategory(order_by: {title: asc}) {
    id
    title
  }
  service(where: {active: {_eq: true}}, order_by: {name: asc}) {
    id
    name
  }
  membership(where: {archived: {_eq: false}}, order_by: {name: asc}) {
    id
    name
  }
  patientTag(order_by: {title: asc}) {
    id
    title
  }
}
    `;

/**
 * __useGetAudienceEditorOptionsQuery__
 *
 * To run a query within a React component, call `useGetAudienceEditorOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAudienceEditorOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAudienceEditorOptionsQuery({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *   },
 * });
 */
export function useGetAudienceEditorOptionsQuery(baseOptions: Apollo.QueryHookOptions<GetAudienceEditorOptionsQuery, GetAudienceEditorOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAudienceEditorOptionsQuery, GetAudienceEditorOptionsQueryVariables>(GetAudienceEditorOptionsDocument, options);
      }
export function useGetAudienceEditorOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAudienceEditorOptionsQuery, GetAudienceEditorOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAudienceEditorOptionsQuery, GetAudienceEditorOptionsQueryVariables>(GetAudienceEditorOptionsDocument, options);
        }
export type GetAudienceEditorOptionsQueryHookResult = ReturnType<typeof useGetAudienceEditorOptionsQuery>;
export type GetAudienceEditorOptionsLazyQueryHookResult = ReturnType<typeof useGetAudienceEditorOptionsLazyQuery>;
export type GetAudienceEditorOptionsQueryResult = Apollo.QueryResult<GetAudienceEditorOptionsQuery, GetAudienceEditorOptionsQueryVariables>;