import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { GoogleLocationFieldsFragmentDoc } from '../../fragments/googleLocationFields.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type InsertGoogleLocationsMutationVariables = Types.Exact<{
  locations: Array<Types.GoogleLocation_Insert_Input> | Types.GoogleLocation_Insert_Input;
}>;


export type InsertGoogleLocationsMutation = { __typename?: 'mutation_root', insert_googleLocation?: { __typename?: 'googleLocation_mutation_response', returning: Array<{ __typename?: 'googleLocation', id: any, name: string, title: string, placeId: string, workspaceId: any }> } | null | undefined };


export const InsertGoogleLocationsDocument = gql`
    mutation InsertGoogleLocations($locations: [googleLocation_insert_input!]!) {
  insert_googleLocation(
    objects: $locations
    on_conflict: {constraint: googleLocation_name_workspaceId_key, update_columns: [addressLines]}
  ) {
    returning {
      ...GoogleLocationFields
    }
  }
}
    ${GoogleLocationFieldsFragmentDoc}`;
export type InsertGoogleLocationsMutationFn = Apollo.MutationFunction<InsertGoogleLocationsMutation, InsertGoogleLocationsMutationVariables>;

/**
 * __useInsertGoogleLocationsMutation__
 *
 * To run a mutation, you first call `useInsertGoogleLocationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertGoogleLocationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertGoogleLocationsMutation, { data, loading, error }] = useInsertGoogleLocationsMutation({
 *   variables: {
 *      locations: // value for 'locations'
 *   },
 * });
 */
export function useInsertGoogleLocationsMutation(baseOptions?: Apollo.MutationHookOptions<InsertGoogleLocationsMutation, InsertGoogleLocationsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertGoogleLocationsMutation, InsertGoogleLocationsMutationVariables>(InsertGoogleLocationsDocument, options);
      }
export type InsertGoogleLocationsMutationHookResult = ReturnType<typeof useInsertGoogleLocationsMutation>;
export type InsertGoogleLocationsMutationResult = Apollo.MutationResult<InsertGoogleLocationsMutation>;
export type InsertGoogleLocationsMutationOptions = Apollo.BaseMutationOptions<InsertGoogleLocationsMutation, InsertGoogleLocationsMutationVariables>;