import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { ServiceCategoryFieldsFragmentDoc } from '../../fragments/serviceCategoryFields.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetServiceCategoryQueryVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
}>;


export type GetServiceCategoryQuery = { __typename?: 'query_root', serviceCategory_by_pk?: { __typename?: 'serviceCategory', id: any, title: string } | null | undefined };


export const GetServiceCategoryDocument = gql`
    query GetServiceCategory($id: uuid!) {
  serviceCategory_by_pk(id: $id) {
    ...ServiceCategoryFields
  }
}
    ${ServiceCategoryFieldsFragmentDoc}`;

/**
 * __useGetServiceCategoryQuery__
 *
 * To run a query within a React component, call `useGetServiceCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetServiceCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetServiceCategoryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetServiceCategoryQuery(baseOptions: Apollo.QueryHookOptions<GetServiceCategoryQuery, GetServiceCategoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetServiceCategoryQuery, GetServiceCategoryQueryVariables>(GetServiceCategoryDocument, options);
      }
export function useGetServiceCategoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetServiceCategoryQuery, GetServiceCategoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetServiceCategoryQuery, GetServiceCategoryQueryVariables>(GetServiceCategoryDocument, options);
        }
export type GetServiceCategoryQueryHookResult = ReturnType<typeof useGetServiceCategoryQuery>;
export type GetServiceCategoryLazyQueryHookResult = ReturnType<typeof useGetServiceCategoryLazyQuery>;
export type GetServiceCategoryQueryResult = Apollo.QueryResult<GetServiceCategoryQuery, GetServiceCategoryQueryVariables>;