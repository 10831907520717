import './conflict-badge.module.css';
import { HStack, Text } from '@chakra-ui/react';
import { PropsWithChildren } from 'react';
import { WarningIcon } from '@chakra-ui/icons';

/* eslint-disable-next-line */
export interface ConflictBadgeProps {}

export function ConflictBadge({
  children,
}: PropsWithChildren<unknown> & ConflictBadgeProps) {
  return (
    <HStack
      p={2}
      maxW="200"
      rounded="md"
      justifyContent="center"
      alignItems={'center'}
      spacing={1}
      bg="red.50"
    >
      <WarningIcon color="red.600" />
      <Text fontSize={12} fontWeight="bold" color="red.600">
        {children}
      </Text>
    </HStack>
  );
}

export default ConflictBadge;
