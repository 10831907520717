import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type UpdatePatientFlowsheetRowMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
  set: Types.PatientFlowsheetRow_Set_Input;
}>;


export type UpdatePatientFlowsheetRowMutation = { __typename?: 'mutation_root', update_patientFlowsheetRow_by_pk?: { __typename?: 'patientFlowsheetRow', id: any, recordedAt: any } | null | undefined };


export const UpdatePatientFlowsheetRowDocument = gql`
    mutation UpdatePatientFlowsheetRow($id: uuid!, $set: patientFlowsheetRow_set_input!) {
  update_patientFlowsheetRow_by_pk(pk_columns: {id: $id}, _set: $set) {
    id
    recordedAt
  }
}
    `;
export type UpdatePatientFlowsheetRowMutationFn = Apollo.MutationFunction<UpdatePatientFlowsheetRowMutation, UpdatePatientFlowsheetRowMutationVariables>;

/**
 * __useUpdatePatientFlowsheetRowMutation__
 *
 * To run a mutation, you first call `useUpdatePatientFlowsheetRowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePatientFlowsheetRowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePatientFlowsheetRowMutation, { data, loading, error }] = useUpdatePatientFlowsheetRowMutation({
 *   variables: {
 *      id: // value for 'id'
 *      set: // value for 'set'
 *   },
 * });
 */
export function useUpdatePatientFlowsheetRowMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePatientFlowsheetRowMutation, UpdatePatientFlowsheetRowMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePatientFlowsheetRowMutation, UpdatePatientFlowsheetRowMutationVariables>(UpdatePatientFlowsheetRowDocument, options);
      }
export type UpdatePatientFlowsheetRowMutationHookResult = ReturnType<typeof useUpdatePatientFlowsheetRowMutation>;
export type UpdatePatientFlowsheetRowMutationResult = Apollo.MutationResult<UpdatePatientFlowsheetRowMutation>;
export type UpdatePatientFlowsheetRowMutationOptions = Apollo.BaseMutationOptions<UpdatePatientFlowsheetRowMutation, UpdatePatientFlowsheetRowMutationVariables>;