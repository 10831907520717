import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type UpdatePatientTagsWhereMutationVariables = Types.Exact<{
  set?: Types.Maybe<Types.PatientTags_Set_Input>;
  where: Types.PatientTags_Bool_Exp;
}>;


export type UpdatePatientTagsWhereMutation = { __typename?: 'mutation_root', update_patientTags?: { __typename?: 'patientTags_mutation_response', affected_rows: number } | null | undefined };


export const UpdatePatientTagsWhereDocument = gql`
    mutation UpdatePatientTagsWhere($set: patientTags_set_input, $where: patientTags_bool_exp!) {
  update_patientTags(_set: $set, where: $where) {
    affected_rows
  }
}
    `;
export type UpdatePatientTagsWhereMutationFn = Apollo.MutationFunction<UpdatePatientTagsWhereMutation, UpdatePatientTagsWhereMutationVariables>;

/**
 * __useUpdatePatientTagsWhereMutation__
 *
 * To run a mutation, you first call `useUpdatePatientTagsWhereMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePatientTagsWhereMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePatientTagsWhereMutation, { data, loading, error }] = useUpdatePatientTagsWhereMutation({
 *   variables: {
 *      set: // value for 'set'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdatePatientTagsWhereMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePatientTagsWhereMutation, UpdatePatientTagsWhereMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePatientTagsWhereMutation, UpdatePatientTagsWhereMutationVariables>(UpdatePatientTagsWhereDocument, options);
      }
export type UpdatePatientTagsWhereMutationHookResult = ReturnType<typeof useUpdatePatientTagsWhereMutation>;
export type UpdatePatientTagsWhereMutationResult = Apollo.MutationResult<UpdatePatientTagsWhereMutation>;
export type UpdatePatientTagsWhereMutationOptions = Apollo.BaseMutationOptions<UpdatePatientTagsWhereMutation, UpdatePatientTagsWhereMutationVariables>;