import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { LocationProviderFieldsFragmentDoc } from '../../fragments/locationProviderFields.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetLocationProvidersQueryVariables = Types.Exact<{
  where: Types.LocationProvider_Bool_Exp;
  limit?: Types.Maybe<Types.Scalars['Int']>;
  offset?: Types.Maybe<Types.Scalars['Int']>;
  orderBy?: Types.Maybe<Array<Types.LocationProvider_Order_By> | Types.LocationProvider_Order_By>;
}>;


export type GetLocationProvidersQuery = { __typename?: 'query_root', locationProvider: Array<{ __typename?: 'locationProvider', id: any, location: { __typename?: 'location', id: any, name: string }, provider: { __typename?: 'provider', id: any, title?: string | null | undefined, firstName: string, lastName: string, profilePicture?: string | null | undefined } }> };


export const GetLocationProvidersDocument = gql`
    query GetLocationProviders($where: locationProvider_bool_exp!, $limit: Int, $offset: Int, $orderBy: [locationProvider_order_by!]) {
  locationProvider(
    where: $where
    limit: $limit
    offset: $offset
    order_by: $orderBy
  ) {
    ...LocationProviderFields
  }
}
    ${LocationProviderFieldsFragmentDoc}`;

/**
 * __useGetLocationProvidersQuery__
 *
 * To run a query within a React component, call `useGetLocationProvidersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLocationProvidersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLocationProvidersQuery({
 *   variables: {
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetLocationProvidersQuery(baseOptions: Apollo.QueryHookOptions<GetLocationProvidersQuery, GetLocationProvidersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLocationProvidersQuery, GetLocationProvidersQueryVariables>(GetLocationProvidersDocument, options);
      }
export function useGetLocationProvidersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLocationProvidersQuery, GetLocationProvidersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLocationProvidersQuery, GetLocationProvidersQueryVariables>(GetLocationProvidersDocument, options);
        }
export type GetLocationProvidersQueryHookResult = ReturnType<typeof useGetLocationProvidersQuery>;
export type GetLocationProvidersLazyQueryHookResult = ReturnType<typeof useGetLocationProvidersLazyQuery>;
export type GetLocationProvidersQueryResult = Apollo.QueryResult<GetLocationProvidersQuery, GetLocationProvidersQueryVariables>;