import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { PromotionFieldsFragmentDoc } from '../../fragments/promotionFields.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type InsertPromotionMutationVariables = Types.Exact<{
  promotion: Types.Promotion_Insert_Input;
}>;


export type InsertPromotionMutation = { __typename?: 'mutation_root', insert_promotion?: { __typename?: 'promotion_mutation_response', returning: Array<{ __typename?: 'promotion', id: any, createdAt: any, updatedAt: any, title: string, description: string, isActive: boolean, isArchived: boolean, startsAt?: any | null | undefined, endsAt?: any | null | undefined, image?: string | null | undefined, workspaceId: any, locationId?: any | null | undefined, location?: { __typename?: 'location', id: any, name: string } | null | undefined, promotionServices: Array<{ __typename?: 'promotionServices', id: any, serviceId: any, discountAmount: any, discountType: string, quantity?: number | null | undefined, service: { __typename?: 'service', id: any, name: string } }> }> } | null | undefined };


export const InsertPromotionDocument = gql`
    mutation insertPromotion($promotion: promotion_insert_input!) {
  insert_promotion(objects: [$promotion]) {
    returning {
      ...PromotionFields
    }
  }
}
    ${PromotionFieldsFragmentDoc}`;
export type InsertPromotionMutationFn = Apollo.MutationFunction<InsertPromotionMutation, InsertPromotionMutationVariables>;

/**
 * __useInsertPromotionMutation__
 *
 * To run a mutation, you first call `useInsertPromotionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertPromotionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertPromotionMutation, { data, loading, error }] = useInsertPromotionMutation({
 *   variables: {
 *      promotion: // value for 'promotion'
 *   },
 * });
 */
export function useInsertPromotionMutation(baseOptions?: Apollo.MutationHookOptions<InsertPromotionMutation, InsertPromotionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertPromotionMutation, InsertPromotionMutationVariables>(InsertPromotionDocument, options);
      }
export type InsertPromotionMutationHookResult = ReturnType<typeof useInsertPromotionMutation>;
export type InsertPromotionMutationResult = Apollo.MutationResult<InsertPromotionMutation>;
export type InsertPromotionMutationOptions = Apollo.BaseMutationOptions<InsertPromotionMutation, InsertPromotionMutationVariables>;