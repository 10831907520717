import { Text } from '@chakra-ui/react';
import { toLocalePriceInCents } from '@webapp/util-formatting';
import Decimal from 'decimal.js';
import { FormInputTypes } from '../types';

const FORMATTERS: Partial<Record<FormInputTypes, (val: any) => string>> = {
  currency: (val: number) => {
    const coercedValue = !val ? 0 : val;
    return toLocalePriceInCents(coercedValue);
  },
  percent: (val: number) => {
    const coercedValue = typeof val !== 'number' ? 0 : val;
    const percentValue = new Decimal(coercedValue).times(100);
    return `${percentValue}%`;
  },
};

function renderReadOnlyText({
  type,
  value,
}: {
  type: FormInputTypes;
  value: any;
}) {
  const format = FORMATTERS[type];
  const readOnlyText = format ? format(value) : value;
  return <Text>{readOnlyText}</Text>;
}

export default renderReadOnlyText;
