import {
  chakra,
  Button,
  FormControl,
  FormLabel,
  HStack,
  Text,
  InputGroup,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  StyleProps,
  ButtonProps,
} from '@chakra-ui/react';
import { useListPaymentMethodsQuery } from '@webapp/graphql';
import { CardTokenForm, CardTokenFormProps } from '@webapp/ui';
import { RadioCard, RadioCardGroup } from '@webapp/ui-composites';
import { toLocalePriceInCents } from '@webapp/util-formatting';
import { useState } from 'react';
import { FaMoneyCheck } from 'react-icons/fa';
import toast from 'react-hot-toast';

/* eslint-disable-next-line */
export interface FinixPaymentFormProps {
  patientId: string;
  showSavedCards: boolean;
  styleProps?: {
    addressContainer?: StyleProps;
    amountContainer?: StyleProps;
    amountLabel?: StyleProps;
    amountText?: StyleProps;
    container?: StyleProps;
    tab?: StyleProps;
    payButton?: ButtonProps;
    payFieldsContainer?: StyleProps;
  };
  onExistingPaymentMethod?: (
    {
      paymentMethod,
      amount,
    }: {
      paymentMethod: string;
      amount: number;
    },
    billingInfo: CardOwnerDetails
  ) => void;
  onNewCardToken?: ({
    token,
    cardOwnerDetails,
  }: {
    token: string;
    cardOwnerDetails: CardOwnerDetails;
  }) => void;
}

export function FinixPaymentForm(
  props: FinixPaymentFormProps & CardTokenFormProps
) {
  const { data: paymentMethods } = useListPaymentMethodsQuery({
    variables: {
      where: {
        patientId: { _eq: props.patientId },
        archived: { _eq: false },
        processor: { _eq: 'finix' },
      },
    },
  });

  const [paymentMethod, setPaymentMethod] = useState<string | null>(null);

  const {
    container: containerStyle = {},
    tab: tabStyle = {},
    payButton: payButtonStyle = {},
    payFieldsContainer: payFieldsContainerStyle = {},
  } = props.styleProps || {};

  const [tabIndex, setTabIndex] = useState(0);

  return (
    <Stack {...containerStyle}>
      <Tabs
        colorScheme="teal"
        index={tabIndex}
        onChange={(index: number) => setTabIndex(index)}
        width={'full'}
      >
        <TabList>
          {props.showSavedCards &&
            paymentMethods?.paymentMethod?.length > 0 && (
              <Tab isDisabled={!paymentMethods?.paymentMethod?.length}>
                Saved Card
              </Tab>
            )}
          <Tab>New Card</Tab>
        </TabList>

        <TabPanels>
          {props.showSavedCards && paymentMethods?.paymentMethod?.length > 0 && (
            <TabPanel {...tabStyle}>
              <RadioCardGroup
                w={'full'}
                spacing="3"
                value={paymentMethod}
                onChange={setPaymentMethod}
              >
                {paymentMethods?.paymentMethod?.map((pm) => (
                  <RadioCard key={pm.id} value={pm.id}>
                    <Text color="muted" fontWeight="medium" fontSize="sm">
                      Card ending in
                    </Text>
                    <Text color="emphasized" fontSize="sm">
                      {pm.last4}
                    </Text>
                  </RadioCard>
                ))}
              </RadioCardGroup>
              <Button
                colorScheme="teal"
                leftIcon={<FaMoneyCheck />}
                isLoading={props.isLoading || false}
                marginTop="10px"
                onClick={() =>
                  paymentMethod
                    ? props.onExistingPaymentMethod?.(
                        {
                          paymentMethodId: paymentMethod,
                          amount: props.amount,
                        },
                        props.cardOwnerDetails
                      )
                    : toast.error('Please select a payment method.')
                }
                width="100%"
                _hover={{
                  background: 'teal.500',
                  opacity: 0.8,
                }}
              >
                {props.showPaymentAmount ? (
                  <Text>Pay {toLocalePriceInCents(props.amount)}</Text>
                ) : (
                  'Submit'
                )}
              </Button>
            </TabPanel>
          )}
          <TabPanel {...tabStyle}>
            <CardTokenForm
              {...props}
              onComplete={(token: string, finixFraudSessionId: string) => {
                props?.onNewCardToken?.({
                  token,
                  cardOwnerDetails: props.cardOwnerDetails,
                  finixFraudSessionId,
                });
              }}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Stack>
  );
}

export default FinixPaymentForm;
