import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type SubscribePatientWorkspaceSubscriptionVariables = Types.Exact<{
  where?: Types.Maybe<Types.PatientWorkspace_Bool_Exp>;
}>;


export type SubscribePatientWorkspaceSubscription = { __typename?: 'subscription_root', patientWorkspace: Array<{ __typename?: 'patientWorkspace', language: string, dob?: any | null | undefined, status?: string | null | undefined, fullMediaConsent?: boolean | null | undefined, partialMediaConsent?: boolean | null | undefined, smsMarketingEnabled?: boolean | null | undefined, emailMarketingEnabled: boolean, phoneMarketingEnabled?: boolean | null | undefined, phoneConfirmed?: boolean | null | undefined, emailConfirmed?: boolean | null | undefined, updatedAt: any }> };


export const SubscribePatientWorkspaceDocument = gql`
    subscription SubscribePatientWorkspace($where: patientWorkspace_bool_exp) {
  patientWorkspace(where: $where) {
    language
    dob
    status
    fullMediaConsent
    partialMediaConsent
    smsMarketingEnabled
    emailMarketingEnabled
    phoneMarketingEnabled
    phoneConfirmed
    emailConfirmed
    updatedAt
  }
}
    `;

/**
 * __useSubscribePatientWorkspaceSubscription__
 *
 * To run a query within a React component, call `useSubscribePatientWorkspaceSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribePatientWorkspaceSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribePatientWorkspaceSubscription({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useSubscribePatientWorkspaceSubscription(baseOptions?: Apollo.SubscriptionHookOptions<SubscribePatientWorkspaceSubscription, SubscribePatientWorkspaceSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<SubscribePatientWorkspaceSubscription, SubscribePatientWorkspaceSubscriptionVariables>(SubscribePatientWorkspaceDocument, options);
      }
export type SubscribePatientWorkspaceSubscriptionHookResult = ReturnType<typeof useSubscribePatientWorkspaceSubscription>;
export type SubscribePatientWorkspaceSubscriptionResult = Apollo.SubscriptionResult<SubscribePatientWorkspaceSubscription>;