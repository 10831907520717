import { useUpdateEffect } from '@chakra-ui/react';
import { MinimalPatientWallet } from '@webapp/types';
import { useFilterPatientWalletTransactions } from '@webapp/webapp/hooks';
import PatientWalletLedgerTable from './PatientWalletLedgerTable';

interface PatientWalletLedgerTableWrapperProps {
  patientWallet: MinimalPatientWallet;
}

export const PatientWalletLedgerTableWrapper = ({
  patientWallet,
}: PatientWalletLedgerTableWrapperProps): JSX.Element => {
  const { patientWalletTransactions, count, refetch } =
    useFilterPatientWalletTransactions(patientWallet.id);

  useUpdateEffect(() => {
    refetch();
  }, [patientWallet.balance]);

  return (
    <PatientWalletLedgerTable
      patientWalletTransactions={patientWalletTransactions}
      count={count}
    />
  );
};

export default PatientWalletLedgerTableWrapper;
