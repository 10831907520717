import * as Types from '../../generated/graphql';

import { gql } from '@apollo/client';
export type ServiceFieldsMinimalFragment = { __typename?: 'service', id: any, taxRate?: any | null | undefined, deposit?: number | null | undefined, requiresDeposit?: boolean | null | undefined, name: string, active?: boolean | null | undefined, description?: string | null | undefined, image?: string | null | undefined, availableInStore?: boolean | null | undefined, canBeBookedOnline?: boolean | null | undefined, minutesToPerform: number, newPatientMinutesToPerform?: number | null | undefined, deviceBufferTime?: number | null | undefined, price: number, isProduct?: boolean | null | undefined, color?: string | null | undefined, serviceUnitId?: any | null | undefined, onlineBookingRules?: any | null | undefined, serviceCategoryId?: any | null | undefined, pricingModel: string, serviceCategory?: { __typename?: 'serviceCategory', title: string } | null | undefined, appointmentType?: { __typename?: 'appointmentType', name: string, color?: string | null | undefined } | null | undefined, serviceDeviceTypes: Array<{ __typename?: 'serviceDeviceType', deviceType: { __typename?: 'deviceType', value: string } }> };

export const ServiceFieldsMinimalFragmentDoc = gql`
    fragment ServiceFieldsMinimal on service {
  id
  taxRate
  deposit
  requiresDeposit
  name
  active
  description
  image
  availableInStore
  canBeBookedOnline
  minutesToPerform
  newPatientMinutesToPerform
  deviceBufferTime
  price
  isProduct
  color
  serviceUnitId
  onlineBookingRules
  serviceCategory {
    title
  }
  appointmentType {
    name
    color
  }
  serviceCategoryId
  pricingModel
  serviceDeviceTypes {
    deviceType {
      value
    }
  }
}
    `;