import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type SendPatientInviteMutationVariables = Types.Exact<{
  email: Types.Scalars['String'];
  patientId: Types.Scalars['uuid'];
  phoneNumber?: Types.Maybe<Types.Scalars['String']>;
}>;


export type SendPatientInviteMutation = { __typename?: 'mutation_root', sendPatientInvite: { __typename?: 'SuccessAnswer', success: boolean } };


export const SendPatientInviteDocument = gql`
    mutation SendPatientInvite($email: String!, $patientId: uuid!, $phoneNumber: String) {
  sendPatientInvite(
    email: $email
    patientId: $patientId
    phoneNumber: $phoneNumber
  ) {
    success
  }
}
    `;
export type SendPatientInviteMutationFn = Apollo.MutationFunction<SendPatientInviteMutation, SendPatientInviteMutationVariables>;

/**
 * __useSendPatientInviteMutation__
 *
 * To run a mutation, you first call `useSendPatientInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendPatientInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendPatientInviteMutation, { data, loading, error }] = useSendPatientInviteMutation({
 *   variables: {
 *      email: // value for 'email'
 *      patientId: // value for 'patientId'
 *      phoneNumber: // value for 'phoneNumber'
 *   },
 * });
 */
export function useSendPatientInviteMutation(baseOptions?: Apollo.MutationHookOptions<SendPatientInviteMutation, SendPatientInviteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendPatientInviteMutation, SendPatientInviteMutationVariables>(SendPatientInviteDocument, options);
      }
export type SendPatientInviteMutationHookResult = ReturnType<typeof useSendPatientInviteMutation>;
export type SendPatientInviteMutationResult = Apollo.MutationResult<SendPatientInviteMutation>;
export type SendPatientInviteMutationOptions = Apollo.BaseMutationOptions<SendPatientInviteMutation, SendPatientInviteMutationVariables>;