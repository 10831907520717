type LegacyPatientAttributes = {
  [key: string]: {
    entities: Array<{
      apiFieldKey: string;
      category: string;
      label: string;
      // eslint-disable-next-line camelcase
      type: any;
      value: string;
      properties?: {
        rowId: string;
      };
    }>;
  };
};

type SurveyJSData = {
  [key: string]: any;
};

export function convertLegacyPatientAttributesToSurveyData(
  input: LegacyPatientAttributes
): SurveyJSData {
  const output: SurveyJSData = {};

  Object.keys(input ?? {}).forEach((key) => {
    // If we're already flat, just continue:
    if (
      !(
        typeof input[key] === 'object' &&
        input[key] !== null &&
        'entities' in input[key]
      )
    ) {
      output[key] = input[key];
      return;
    }

    if (input[key].entities.length === 1) {
      switch (input[key].entities[0].type) {
        case 'MULTIPLE_SELECT':
        case 'CREATABLE_SELECT':
          output[key] = input[key].entities[0].value.substring(1).split(',');
          break;
        case 'YES_NO':
          output[key] = input[key].entities[0].value === 'true';
          break;
        default:
          output[key] = input[key].entities[0].value;
          break;
      }
    } else if (input[key].entities.length > 1) {
      // Handle cases like medication_dosage which are entity array based:
      output[key] = Object.values(
        input[key].entities.reduce((acc: { [key: string]: any }, obj) => {
          if (!obj.properties) return acc;
          // Need to further investigate this scenario
          // throw new Error('Expected properties to be present');
          const { rowId } = obj.properties;

          if (!acc[rowId])
            acc[rowId] = {
              rowId,
            };

          acc[rowId][obj.apiFieldKey] = obj.value;

          return acc;
        }, {})
      );
    }
  });

  return output;
}

export default { convertLegacyPatientAttributesToSurveyData };
