import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { ConsumableTypeFieldsFragmentDoc } from '../../fragments/consumableTypeFields.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type InsertConsumableTypeMutationVariables = Types.Exact<{
  consumableType: Types.ConsumableType_Insert_Input;
}>;


export type InsertConsumableTypeMutation = { __typename?: 'mutation_root', insert_consumableType?: { __typename?: 'consumableType_mutation_response', returning: Array<{ __typename?: 'consumableType', id: any, name: string, requiredFields: any, defaultNumberOfUnits: any, defaultPricePerUnit: number, unitStep: any, unitName: string, createdAt: any, updatedAt: any, archived: boolean, productBarcodeId?: string | null | undefined, consumableTypeConsumableSubtypes: Array<{ __typename?: 'consumableTypeConsumableSubtype', id: any, consumableSubtype: { __typename?: 'consumableSubtype', id: any, name: string } }> }> } | null | undefined };


export const InsertConsumableTypeDocument = gql`
    mutation insertConsumableType($consumableType: consumableType_insert_input!) {
  insert_consumableType(objects: [$consumableType]) {
    returning {
      ...ConsumableTypeFields
    }
  }
}
    ${ConsumableTypeFieldsFragmentDoc}`;
export type InsertConsumableTypeMutationFn = Apollo.MutationFunction<InsertConsumableTypeMutation, InsertConsumableTypeMutationVariables>;

/**
 * __useInsertConsumableTypeMutation__
 *
 * To run a mutation, you first call `useInsertConsumableTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertConsumableTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertConsumableTypeMutation, { data, loading, error }] = useInsertConsumableTypeMutation({
 *   variables: {
 *      consumableType: // value for 'consumableType'
 *   },
 * });
 */
export function useInsertConsumableTypeMutation(baseOptions?: Apollo.MutationHookOptions<InsertConsumableTypeMutation, InsertConsumableTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertConsumableTypeMutation, InsertConsumableTypeMutationVariables>(InsertConsumableTypeDocument, options);
      }
export type InsertConsumableTypeMutationHookResult = ReturnType<typeof useInsertConsumableTypeMutation>;
export type InsertConsumableTypeMutationResult = Apollo.MutationResult<InsertConsumableTypeMutation>;
export type InsertConsumableTypeMutationOptions = Apollo.BaseMutationOptions<InsertConsumableTypeMutation, InsertConsumableTypeMutationVariables>;