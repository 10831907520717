import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type CreateImportJobMutationVariables = Types.Exact<{
  type: Types.Scalars['String'];
  payload: Types.Scalars['jsonb'];
}>;


export type CreateImportJobMutation = { __typename?: 'mutation_root', insert_importJob?: { __typename?: 'importJob_mutation_response', returning: Array<{ __typename?: 'importJob', id: any, status: string, type: string }> } | null | undefined };


export const CreateImportJobDocument = gql`
    mutation CreateImportJob($type: String!, $payload: jsonb!) {
  insert_importJob(objects: {type: $type, payload: $payload}) {
    returning {
      id
      status
      type
    }
  }
}
    `;
export type CreateImportJobMutationFn = Apollo.MutationFunction<CreateImportJobMutation, CreateImportJobMutationVariables>;

/**
 * __useCreateImportJobMutation__
 *
 * To run a mutation, you first call `useCreateImportJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateImportJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createImportJobMutation, { data, loading, error }] = useCreateImportJobMutation({
 *   variables: {
 *      type: // value for 'type'
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useCreateImportJobMutation(baseOptions?: Apollo.MutationHookOptions<CreateImportJobMutation, CreateImportJobMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateImportJobMutation, CreateImportJobMutationVariables>(CreateImportJobDocument, options);
      }
export type CreateImportJobMutationHookResult = ReturnType<typeof useCreateImportJobMutation>;
export type CreateImportJobMutationResult = Apollo.MutationResult<CreateImportJobMutation>;
export type CreateImportJobMutationOptions = Apollo.BaseMutationOptions<CreateImportJobMutation, CreateImportJobMutationVariables>;