import { observer } from 'mobx-react-lite';
import './appointment-list-view.module.scss';
import { useStores } from '@webapp/state-models';
import { Heading, Stack } from '@chakra-ui/react';
import { configuredDayjs as dayjs } from '@webapp/util-time';
import AppointmentListRow from './appointment-list-row';

/* eslint-disable-next-line */
export interface AppointmentListViewProps {
  onClickAppointment: (appointmentId: string) => void;
}

export const AppointmentListView = observer(
  ({ onClickAppointment }: AppointmentListViewProps) => {
    const { cachedAppointmentList, calendarTimeZone } = useStores();

    return (
      <Stack id="pm-appointment-list" spacing={4}>
        {cachedAppointmentList?.length === 0 && (
          <Heading size="md">No appointments found</Heading>
        )}
        {cachedAppointmentList
          ?.slice()
          .sort((a, b) =>
            dayjs(a.timeDayjs).isAfter(dayjs(b.timeDayjs)) ? 1 : -1
          )
          ?.map((appointment) => {
            return (
              <AppointmentListRow
                key={appointment.id}
                onClick={() => onClickAppointment(appointment.id)}
                appointment={appointment}
                timezone={calendarTimeZone}
              />
            );
          })}
      </Stack>
    );
  }
);

export default AppointmentListView;
