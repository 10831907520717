import { createContext } from 'react';
import { InvoiceFormValues } from '@webapp/types';

const InvoiceFormContext = createContext<{
  isLoading: boolean;
  onUpdate?: () => void;
  payNow: (values: InvoiceFormValues) => void;
  save: (values: InvoiceFormValues) => void;
} | null>(null);

export default InvoiceFormContext;
