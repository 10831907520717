import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { LeadTrackedEventFieldsFragmentDoc } from '../../fragments/leadPhoneCallFields.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetLeadTrackedEventQueryVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
}>;


export type GetLeadTrackedEventQuery = { __typename?: 'query_root', leadTrackedEvent_by_pk?: { __typename?: 'leadTrackedEvent', id: any, outcome: string, type?: string | null | undefined, createdAt: any, updatedAt: any, user?: { __typename?: 'user', firstName: string, lastName: string } | null | undefined } | null | undefined };


export const GetLeadTrackedEventDocument = gql`
    query GetLeadTrackedEvent($id: uuid!) {
  leadTrackedEvent_by_pk(id: $id) {
    ...LeadTrackedEventFields
  }
}
    ${LeadTrackedEventFieldsFragmentDoc}`;

/**
 * __useGetLeadTrackedEventQuery__
 *
 * To run a query within a React component, call `useGetLeadTrackedEventQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLeadTrackedEventQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLeadTrackedEventQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetLeadTrackedEventQuery(baseOptions: Apollo.QueryHookOptions<GetLeadTrackedEventQuery, GetLeadTrackedEventQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLeadTrackedEventQuery, GetLeadTrackedEventQueryVariables>(GetLeadTrackedEventDocument, options);
      }
export function useGetLeadTrackedEventLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLeadTrackedEventQuery, GetLeadTrackedEventQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLeadTrackedEventQuery, GetLeadTrackedEventQueryVariables>(GetLeadTrackedEventDocument, options);
        }
export type GetLeadTrackedEventQueryHookResult = ReturnType<typeof useGetLeadTrackedEventQuery>;
export type GetLeadTrackedEventLazyQueryHookResult = ReturnType<typeof useGetLeadTrackedEventLazyQuery>;
export type GetLeadTrackedEventQueryResult = Apollo.QueryResult<GetLeadTrackedEventQuery, GetLeadTrackedEventQueryVariables>;