import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type TestSmsTemplateMutationVariables = Types.Exact<{
  smsBody: Types.Scalars['String'];
  mmsImage?: Types.Maybe<Types.Scalars['String']>;
  smsNumber: Types.Scalars['String'];
}>;


export type TestSmsTemplateMutation = { __typename?: 'mutation_root', testSmsTemplate?: { __typename?: 'SuccessAnswer', success: boolean } | null | undefined };


export const TestSmsTemplateDocument = gql`
    mutation testSmsTemplate($smsBody: String!, $mmsImage: String, $smsNumber: String!) {
  testSmsTemplate(
    smsBody: $smsBody
    mmsImage: $mmsImage
    testSmsNumber: $smsNumber
  ) {
    success
  }
}
    `;
export type TestSmsTemplateMutationFn = Apollo.MutationFunction<TestSmsTemplateMutation, TestSmsTemplateMutationVariables>;

/**
 * __useTestSmsTemplateMutation__
 *
 * To run a mutation, you first call `useTestSmsTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTestSmsTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [testSmsTemplateMutation, { data, loading, error }] = useTestSmsTemplateMutation({
 *   variables: {
 *      smsBody: // value for 'smsBody'
 *      mmsImage: // value for 'mmsImage'
 *      smsNumber: // value for 'smsNumber'
 *   },
 * });
 */
export function useTestSmsTemplateMutation(baseOptions?: Apollo.MutationHookOptions<TestSmsTemplateMutation, TestSmsTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TestSmsTemplateMutation, TestSmsTemplateMutationVariables>(TestSmsTemplateDocument, options);
      }
export type TestSmsTemplateMutationHookResult = ReturnType<typeof useTestSmsTemplateMutation>;
export type TestSmsTemplateMutationResult = Apollo.MutationResult<TestSmsTemplateMutation>;
export type TestSmsTemplateMutationOptions = Apollo.BaseMutationOptions<TestSmsTemplateMutation, TestSmsTemplateMutationVariables>;