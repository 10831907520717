import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetGiftCardBalanceQueryVariables = Types.Exact<{
  receiverId: Types.Scalars['uuid'];
}>;


export type GetGiftCardBalanceQuery = { __typename?: 'query_root', giftCard_aggregate: { __typename?: 'giftCard_aggregate', aggregate?: { __typename?: 'giftCard_aggregate_fields', sum?: { __typename?: 'giftCard_sum_fields', balance?: number | null | undefined } | null | undefined } | null | undefined } };


export const GetGiftCardBalanceDocument = gql`
    query GetGiftCardBalance($receiverId: uuid!) {
  giftCard_aggregate(where: {receiverId: {_eq: $receiverId}}) {
    aggregate {
      sum {
        balance
      }
    }
  }
}
    `;

/**
 * __useGetGiftCardBalanceQuery__
 *
 * To run a query within a React component, call `useGetGiftCardBalanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGiftCardBalanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGiftCardBalanceQuery({
 *   variables: {
 *      receiverId: // value for 'receiverId'
 *   },
 * });
 */
export function useGetGiftCardBalanceQuery(baseOptions: Apollo.QueryHookOptions<GetGiftCardBalanceQuery, GetGiftCardBalanceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGiftCardBalanceQuery, GetGiftCardBalanceQueryVariables>(GetGiftCardBalanceDocument, options);
      }
export function useGetGiftCardBalanceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGiftCardBalanceQuery, GetGiftCardBalanceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGiftCardBalanceQuery, GetGiftCardBalanceQueryVariables>(GetGiftCardBalanceDocument, options);
        }
export type GetGiftCardBalanceQueryHookResult = ReturnType<typeof useGetGiftCardBalanceQuery>;
export type GetGiftCardBalanceLazyQueryHookResult = ReturnType<typeof useGetGiftCardBalanceLazyQuery>;
export type GetGiftCardBalanceQueryResult = Apollo.QueryResult<GetGiftCardBalanceQuery, GetGiftCardBalanceQueryVariables>;