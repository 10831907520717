import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type DeleteAppointmentTypeMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
}>;


export type DeleteAppointmentTypeMutation = { __typename?: 'mutation_root', delete_appointmentType_by_pk?: { __typename?: 'appointmentType', id: any } | null | undefined };


export const DeleteAppointmentTypeDocument = gql`
    mutation deleteAppointmentType($id: uuid!) {
  delete_appointmentType_by_pk(id: $id) {
    id
  }
}
    `;
export type DeleteAppointmentTypeMutationFn = Apollo.MutationFunction<DeleteAppointmentTypeMutation, DeleteAppointmentTypeMutationVariables>;

/**
 * __useDeleteAppointmentTypeMutation__
 *
 * To run a mutation, you first call `useDeleteAppointmentTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAppointmentTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAppointmentTypeMutation, { data, loading, error }] = useDeleteAppointmentTypeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteAppointmentTypeMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAppointmentTypeMutation, DeleteAppointmentTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteAppointmentTypeMutation, DeleteAppointmentTypeMutationVariables>(DeleteAppointmentTypeDocument, options);
      }
export type DeleteAppointmentTypeMutationHookResult = ReturnType<typeof useDeleteAppointmentTypeMutation>;
export type DeleteAppointmentTypeMutationResult = Apollo.MutationResult<DeleteAppointmentTypeMutation>;
export type DeleteAppointmentTypeMutationOptions = Apollo.BaseMutationOptions<DeleteAppointmentTypeMutation, DeleteAppointmentTypeMutationVariables>;