import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type SearchCouponsQueryVariables = Types.Exact<{
  searchValue: Types.Scalars['String'];
  limit?: Types.Maybe<Types.Scalars['Int']>;
}>;


export type SearchCouponsQuery = { __typename?: 'query_root', coupon: Array<{ __typename?: 'coupon', id: any }> };


export const SearchCouponsDocument = gql`
    query searchCoupons($searchValue: String!, $limit: Int) {
  coupon(where: {name: {_ilike: $searchValue}}) {
    id
  }
}
    `;

/**
 * __useSearchCouponsQuery__
 *
 * To run a query within a React component, call `useSearchCouponsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchCouponsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchCouponsQuery({
 *   variables: {
 *      searchValue: // value for 'searchValue'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useSearchCouponsQuery(baseOptions: Apollo.QueryHookOptions<SearchCouponsQuery, SearchCouponsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchCouponsQuery, SearchCouponsQueryVariables>(SearchCouponsDocument, options);
      }
export function useSearchCouponsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchCouponsQuery, SearchCouponsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchCouponsQuery, SearchCouponsQueryVariables>(SearchCouponsDocument, options);
        }
export type SearchCouponsQueryHookResult = ReturnType<typeof useSearchCouponsQuery>;
export type SearchCouponsLazyQueryHookResult = ReturnType<typeof useSearchCouponsLazyQuery>;
export type SearchCouponsQueryResult = Apollo.QueryResult<SearchCouponsQuery, SearchCouponsQueryVariables>;