import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type UpdateEmailMarketingTagsMutationVariables = Types.Exact<{
  emailTemplateId: Types.Scalars['uuid'];
  emailMarketingTags: Array<Types.MarketingTagEmail_Insert_Input> | Types.MarketingTagEmail_Insert_Input;
}>;


export type UpdateEmailMarketingTagsMutation = { __typename?: 'mutation_root', delete_marketingTagEmail?: { __typename?: 'marketingTagEmail_mutation_response', returning: Array<{ __typename?: 'marketingTagEmail', id: any }> } | null | undefined, insert_marketingTagEmail?: { __typename?: 'marketingTagEmail_mutation_response', affected_rows: number } | null | undefined };


export const UpdateEmailMarketingTagsDocument = gql`
    mutation UpdateEmailMarketingTags($emailTemplateId: uuid!, $emailMarketingTags: [marketingTagEmail_insert_input!]!) {
  delete_marketingTagEmail(where: {emailTemplateId: {_eq: $emailTemplateId}}) {
    returning {
      id
    }
  }
  insert_marketingTagEmail(objects: $emailMarketingTags) {
    affected_rows
  }
}
    `;
export type UpdateEmailMarketingTagsMutationFn = Apollo.MutationFunction<UpdateEmailMarketingTagsMutation, UpdateEmailMarketingTagsMutationVariables>;

/**
 * __useUpdateEmailMarketingTagsMutation__
 *
 * To run a mutation, you first call `useUpdateEmailMarketingTagsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEmailMarketingTagsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEmailMarketingTagsMutation, { data, loading, error }] = useUpdateEmailMarketingTagsMutation({
 *   variables: {
 *      emailTemplateId: // value for 'emailTemplateId'
 *      emailMarketingTags: // value for 'emailMarketingTags'
 *   },
 * });
 */
export function useUpdateEmailMarketingTagsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEmailMarketingTagsMutation, UpdateEmailMarketingTagsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEmailMarketingTagsMutation, UpdateEmailMarketingTagsMutationVariables>(UpdateEmailMarketingTagsDocument, options);
      }
export type UpdateEmailMarketingTagsMutationHookResult = ReturnType<typeof useUpdateEmailMarketingTagsMutation>;
export type UpdateEmailMarketingTagsMutationResult = Apollo.MutationResult<UpdateEmailMarketingTagsMutation>;
export type UpdateEmailMarketingTagsMutationOptions = Apollo.BaseMutationOptions<UpdateEmailMarketingTagsMutation, UpdateEmailMarketingTagsMutationVariables>;