import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { MediaTagModel } from './media-tag';

/**
 * A PhotoMediaTagStore model.
 */
// prettier-ignore
export const PhotoMediaTagModel = types.model("PhotoMediaTag").props({
  id: types.identifier,
  mediaTag: MediaTagModel
}).actions((self) => ({
  
}
))
/**
 * The PhotoMediaTag instance.
 */
export type PhotoMediaTag = Instance<typeof PhotoMediaTagModel>;

/**
 * The data of a PhotoMediaTag.
 */
export type PhotoMediaTagSnapshot = SnapshotOut<typeof PhotoMediaTagModel>;
