import {
  Wrap,
  WrapItem,
  Tag,
  Skeleton,
  TagLabel,
  TagLeftIcon,
  TagRightIcon,
  Tooltip,
} from '@chakra-ui/react';
import { IoPricetagOutline } from 'react-icons/io5';
import { stringToColor } from '@webapp/webapp/util-display';
import {
  usePatientTagsQueryQuery,
  useDeletePatientTagsMutation,
  PatientFieldsFragment,
  useGetPatientWalletCouponsQuery,
} from '@webapp/graphql';
import { CgDanger } from 'react-icons/cg';
import { IoMdCloseCircleOutline } from 'react-icons/io';
import toast from 'react-hot-toast';
import AddPatientTag from './AddPatientTag';

import PatientWalletWithModal from '../patient-wallet-with-modal';

/* eslint-disable-next-line */
export interface PatientTagsProps {
  patient: PatientFieldsFragment;
  canAdd?: boolean;
  showAllergies?: boolean;
  showMediaConsent?: boolean;
}

function convertAttributesToTags(attributes: any) {
  const tags = [];

  if (attributes) {
    if (
      typeof attributes.allergiesLatex === 'boolean' &&
      attributes.allergiesLatex
    ) {
      tags.push(
        <WrapItem>
          <Tag size="md" variant="subtle" colorScheme="red">
            <TagLeftIcon boxSize="12px" as={CgDanger} />
            <TagLabel>Latex Allergy</TagLabel>
          </Tag>
        </WrapItem>
      );
    }
    if (
      typeof attributes.allergiesIodine === 'boolean' &&
      attributes.allergiesIodine
    ) {
      tags.push(
        <WrapItem>
          <Tag size="md" variant="subtle" colorScheme="red">
            <TagLeftIcon boxSize="12px" as={CgDanger} />
            <TagLabel>Iodine Allergy</TagLabel>
          </Tag>
        </WrapItem>
      );
    }
    if (
      (typeof attributes.allergiesLidocane === 'boolean' &&
        attributes.allergiesLidocane) ||
      (typeof attributes.allergiesLidocaine === 'boolean' &&
        attributes.allergiesLidocaine)
    ) {
      tags.push(
        <WrapItem>
          <Tag size="md" variant="subtle" colorScheme="red">
            <TagLeftIcon boxSize="12px" as={CgDanger} />
            <TagLabel>Lidocaine Allergy</TagLabel>
          </Tag>
        </WrapItem>
      );
    }
    if (
      typeof attributes.allergiesBeeStings === 'boolean' &&
      attributes.allergiesBeeStings
    ) {
      tags.push(
        <WrapItem>
          <Tag size="md" variant="subtle" colorScheme="red">
            <TagLeftIcon boxSize="12px" as={CgDanger} />
            <TagLabel>Bee Sting Allergy</TagLabel>
          </Tag>
        </WrapItem>
      );
    }
    if (
      typeof attributes.allergiesAdhesiveTape === 'boolean' &&
      attributes.allergiesAdhesiveTape
    ) {
      tags.push(
        <WrapItem>
          <Tag size="md" variant="subtle" colorScheme="red">
            <TagLeftIcon boxSize="12px" as={CgDanger} />
            <TagLabel>Adhesive Tape Allergy</TagLabel>
          </Tag>
        </WrapItem>
      );
    }

    if (Array.isArray(attributes?.allergiesMedicine)) {
      if (attributes.allergiesMedicine.includes('none')) {
        tags.push(
          <WrapItem>
            <Tag size="md" variant="subtle" colorScheme="green">
              <TagLeftIcon boxSize="12px" as={CgDanger} />
              <TagLabel>NKDA</TagLabel>
            </Tag>
          </WrapItem>
        );
      } else {
        attributes.allergiesMedicine.forEach((allergy: string) => {
          tags.push(
            <WrapItem>
              <Tag size="md" variant="subtle" colorScheme="red">
                <TagLeftIcon boxSize="12px" as={CgDanger} />
                <TagLabel maxW="200px" isTruncated>
                  {allergy === 'other' &&
                  attributes?.['allergiesMedicine-Comment']
                    ? attributes?.['allergiesMedicine-Comment']
                    : allergy}{' '}
                  Allergy
                </TagLabel>
              </Tag>
            </WrapItem>
          );
        });
      }
    }
  }

  return tags;
}

function groupPatientWalletCoupons(
  walletCoupons: { coupon: { id: string; name: string } }[]
) {
  const groupedCoupons: { id: string; name: string; count: number }[] = [];
  walletCoupons.forEach((walletCoupon) => {
    const { coupon } = walletCoupon;
    const existingCoupon = groupedCoupons.find(
      (c) => c.id === coupon.id || c.name === coupon.name
    );
    if (existingCoupon) {
      // eslint-disable-next-line no-plusplus
      existingCoupon.count++;
    } else {
      groupedCoupons.push({ id: coupon.id, name: coupon.name, count: 1 });
    }
  });
  return groupedCoupons;
}

export function PatientTags({
  patient,
  canAdd = true,
  showAllergies = true,
  showMediaConsent = true,
}: PatientTagsProps) {
  const { data, loading } = usePatientTagsQueryQuery({
    variables: {
      patientId: patient?.id,
    },
  });

  const { data: walletData, loading: walletLoading } =
    useGetPatientWalletCouponsQuery({
      variables: {
        where: {
          patientWalletId: {
            _eq: patient?.patientWallets?.[0]?.id,
          },
          used: {
            _eq: false,
          },
          archived: {
            _eq: false,
          },
        },
      },
      skip: !patient?.patientWallets?.[0]?.id,
    });

  const groupedCoupons = groupPatientWalletCoupons(
    walletData?.patientWalletCoupon || []
  );

  const [deletePatientTag] = useDeletePatientTagsMutation({
    refetchQueries: ['PatientTagsQuery'],
    onCompleted: () => {
      toast.success('Patient tag deleted');
    },
  });

  return (
    <Skeleton isLoaded={!loading}>
      <Wrap>
        {data?.patient_by_pk?.patientMemberships &&
          data?.patient_by_pk?.patientMemberships?.length > 0 && (
            <WrapItem>
              <PatientWalletWithModal
                key={patient?.id}
                patient={patient}
                showWalletAmount={false}
                showPaymentInformation={false}
                showSubscriptionTag={true}
              />
            </WrapItem>
          )}
        {data?.patient_by_pk?.patientWorkspaces?.[0]?.fullMediaConsent &&
          showMediaConsent && (
            <WrapItem>
              <Tag size="md" variant="subtle" colorScheme="green">
                Full Media Consent
              </Tag>
            </WrapItem>
          )}
        {data?.patient_by_pk?.patientWorkspaces?.[0]?.partialMediaConsent &&
          showMediaConsent && (
            <WrapItem>
              <Tag size="md" variant="subtle" colorScheme="green">
                Partial Media Consent
              </Tag>
            </WrapItem>
          )}
        {data?.patient_by_pk?.patientWorkspaces?.[0]
          ?.fullAnonymousMediaConsent &&
          showMediaConsent && (
            <WrapItem>
              <Tag size="md" variant="subtle" colorScheme="green">
                Anonymous Media Consent
              </Tag>
            </WrapItem>
          )}
        {showAllergies &&
          convertAttributesToTags(
            data?.patient_by_pk?.patientWorkspaces?.[0]?.attributes
          )}
        {groupedCoupons.map((coupon) => (
          <WrapItem>
            <Tag size="md" variant="subtle" colorScheme="orange">
              <TagLeftIcon boxSize="12px" as={IoPricetagOutline} />
              <Tooltip
                label={`Patient has ${coupon.name} x${coupon.count} in their wallet`}
              >
                <TagLabel truncate maxW="180px" mr={1}>
                  {coupon.name}
                </TagLabel>
              </Tooltip>
              <TagLabel> {`x ${coupon.count}`}</TagLabel>
            </Tag>
          </WrapItem>
        ))}
        {data?.patient_by_pk?.patientTags
          ?.slice()
          .sort((a, b) => a.patientTag.title.localeCompare(b.patientTag.title))
          .map(({ id, patientTag }) => (
            <WrapItem>
              <Tag
                size="md"
                variant="subtle"
                bg={
                  patientTag?.color
                    ? `${patientTag.color}33`
                    : stringToColor(patientTag.title, undefined, '.2')
                }
                color={patientTag.color ?? stringToColor(patientTag.title)}
              >
                <TagLabel>{patientTag.title}</TagLabel>
                <TagRightIcon
                  cursor="pointer"
                  onClick={() =>
                    deletePatientTag({
                      variables: {
                        id,
                      },
                    })
                  }
                  boxSize="12px"
                  as={IoMdCloseCircleOutline}
                />
              </Tag>
            </WrapItem>
          ))}
        {canAdd && (
          <WrapItem>
            <AddPatientTag patientId={patient?.id} />
          </WrapItem>
        )}
      </Wrap>
    </Skeleton>
  );
}

export default PatientTags;
