import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type DeleteAppointmentServicesMutationVariables = Types.Exact<{
  where: Types.AppointmentService_Bool_Exp;
}>;


export type DeleteAppointmentServicesMutation = { __typename?: 'mutation_root', delete_appointmentService?: { __typename?: 'appointmentService_mutation_response', affected_rows: number } | null | undefined };


export const DeleteAppointmentServicesDocument = gql`
    mutation deleteAppointmentServices($where: appointmentService_bool_exp!) {
  delete_appointmentService(where: $where) {
    affected_rows
  }
}
    `;
export type DeleteAppointmentServicesMutationFn = Apollo.MutationFunction<DeleteAppointmentServicesMutation, DeleteAppointmentServicesMutationVariables>;

/**
 * __useDeleteAppointmentServicesMutation__
 *
 * To run a mutation, you first call `useDeleteAppointmentServicesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAppointmentServicesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAppointmentServicesMutation, { data, loading, error }] = useDeleteAppointmentServicesMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDeleteAppointmentServicesMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAppointmentServicesMutation, DeleteAppointmentServicesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteAppointmentServicesMutation, DeleteAppointmentServicesMutationVariables>(DeleteAppointmentServicesDocument, options);
      }
export type DeleteAppointmentServicesMutationHookResult = ReturnType<typeof useDeleteAppointmentServicesMutation>;
export type DeleteAppointmentServicesMutationResult = Apollo.MutationResult<DeleteAppointmentServicesMutation>;
export type DeleteAppointmentServicesMutationOptions = Apollo.BaseMutationOptions<DeleteAppointmentServicesMutation, DeleteAppointmentServicesMutationVariables>;