import {
  InputGroup,
  InputLeftAddon,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput as ChakraNumberInput,
  NumberInputField,
  NumberInputStepper,
} from '@chakra-ui/react';
import { toLocalePrice, toLocalePriceInCents } from '@webapp/util-formatting';
import { useEffect, useState } from 'react';
import DEFAULT_CONTROL_STYLE from './styles/DefaultControlStyle';
import DEFAULT_INPUT_CONTAINER_STYLE from './styles/DefaultInputContainerStyle';
import { NumberInputProps } from './types';

export default function CurrencyInput({
  hasIncrement = true,
  incrementStep = 100,
  max,
  min = 0,
  name,
  onChange,
  placeholder,
  precision = 2,
  styles = {},
  value,
  ...props
}: Omit<NumberInputProps, 'type'>) {
  const inputStyles = { ...DEFAULT_CONTROL_STYLE, ...styles.control };

  const containerStyles = {
    ...DEFAULT_INPUT_CONTAINER_STYLE,
    ...styles.inputContainer,
  };

  const [inputValue, setInputValue] = useState<string>(
    value
      ? toLocalePrice(value, { shouldDisplayCurrency: false }).replace(/,/g, '')
      : '0'
  );

  useEffect(() => {
    const floatValue = parseFloat(inputValue);

    const isEmpty = value === 0 && ['', '0.00'].includes(inputValue);
    const areEqual = value === Math.round(floatValue * 100);

    const valuesAreNotEqual = !areEqual && !isEmpty;

    if (!Number.isNaN(value) && valuesAreNotEqual) {
      setInputValue(
        toLocalePriceInCents(value, { shouldDisplayCurrency: false }).replace(
          /,/g,
          ''
        )
      );
    }
  }, [inputValue, value]);

  return (
    <InputGroup {...containerStyles}>
      <InputLeftAddon children="$" />
      <ChakraNumberInput
        id={name}
        max={max}
        min={min}
        onBlur={() => {
          if (!inputValue.length) {
            setInputValue('0.00');
          }
        }}
        onChange={(stringValue, numberValue) => {
          setInputValue(stringValue);

          if (!Number.isNaN(numberValue)) {
            onChange(stringValue, Math.round(numberValue * 100));
          } else {
            onChange('0', 0);
          }
        }}
        precision={precision}
        step={incrementStep}
        value={inputValue}
        width="100%"
        {...props}
      >
        <NumberInputField placeholder={placeholder} {...inputStyles} />
        {hasIncrement && (
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        )}
      </ChakraNumberInput>
    </InputGroup>
  );
}
