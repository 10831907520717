import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type UpdateLeadTagMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
  set: Types.LeadTag_Set_Input;
}>;


export type UpdateLeadTagMutation = { __typename?: 'mutation_root', update_leadTag_by_pk?: { __typename?: 'leadTag', id: any } | null | undefined };


export const UpdateLeadTagDocument = gql`
    mutation updateLeadTag($id: uuid!, $set: leadTag_set_input!) {
  update_leadTag_by_pk(pk_columns: {id: $id}, _set: $set) {
    id
  }
}
    `;
export type UpdateLeadTagMutationFn = Apollo.MutationFunction<UpdateLeadTagMutation, UpdateLeadTagMutationVariables>;

/**
 * __useUpdateLeadTagMutation__
 *
 * To run a mutation, you first call `useUpdateLeadTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLeadTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLeadTagMutation, { data, loading, error }] = useUpdateLeadTagMutation({
 *   variables: {
 *      id: // value for 'id'
 *      set: // value for 'set'
 *   },
 * });
 */
export function useUpdateLeadTagMutation(baseOptions?: Apollo.MutationHookOptions<UpdateLeadTagMutation, UpdateLeadTagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateLeadTagMutation, UpdateLeadTagMutationVariables>(UpdateLeadTagDocument, options);
      }
export type UpdateLeadTagMutationHookResult = ReturnType<typeof useUpdateLeadTagMutation>;
export type UpdateLeadTagMutationResult = Apollo.MutationResult<UpdateLeadTagMutation>;
export type UpdateLeadTagMutationOptions = Apollo.BaseMutationOptions<UpdateLeadTagMutation, UpdateLeadTagMutationVariables>;