import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetCustomFormsAggregateQueryVariables = Types.Exact<{
  where?: Types.Maybe<Types.CustomForm_Bool_Exp>;
}>;


export type GetCustomFormsAggregateQuery = { __typename?: 'query_root', customForm_aggregate: { __typename?: 'customForm_aggregate', aggregate?: { __typename?: 'customForm_aggregate_fields', count: number } | null | undefined } };


export const GetCustomFormsAggregateDocument = gql`
    query GetCustomFormsAggregate($where: customForm_bool_exp) {
  customForm_aggregate(where: $where) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetCustomFormsAggregateQuery__
 *
 * To run a query within a React component, call `useGetCustomFormsAggregateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomFormsAggregateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomFormsAggregateQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetCustomFormsAggregateQuery(baseOptions?: Apollo.QueryHookOptions<GetCustomFormsAggregateQuery, GetCustomFormsAggregateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCustomFormsAggregateQuery, GetCustomFormsAggregateQueryVariables>(GetCustomFormsAggregateDocument, options);
      }
export function useGetCustomFormsAggregateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCustomFormsAggregateQuery, GetCustomFormsAggregateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCustomFormsAggregateQuery, GetCustomFormsAggregateQueryVariables>(GetCustomFormsAggregateDocument, options);
        }
export type GetCustomFormsAggregateQueryHookResult = ReturnType<typeof useGetCustomFormsAggregateQuery>;
export type GetCustomFormsAggregateLazyQueryHookResult = ReturnType<typeof useGetCustomFormsAggregateLazyQuery>;
export type GetCustomFormsAggregateQueryResult = Apollo.QueryResult<GetCustomFormsAggregateQuery, GetCustomFormsAggregateQueryVariables>;