import { blobToBase64 } from '@webapp/util-transforms';

export async function loadExistingSignatureToBase64(
  signatureUrl: string
): Promise<string> {
  const fetchResponse = await fetch(signatureUrl);
  const responseBuffer = await fetchResponse.blob();
  const base64 = await blobToBase64(responseBuffer);
  return base64 as string;
}

export default loadExistingSignatureToBase64;
