import * as Types from '../../generated/graphql';

import { gql } from '@apollo/client';
import { AddressFieldsFragmentDoc } from './addressFields.fragment.generated';
export type PatientAddressFieldsFragment = { __typename?: 'patientAddress', id: any, isDefault: boolean, type: string, status: string, address: { __typename?: 'address', addressLine1: string, addressLine2?: string | null | undefined, city: string, id: any, state: string, zipCode: string } };

export const PatientAddressFieldsFragmentDoc = gql`
    fragment PatientAddressFields on patientAddress {
  address {
    ...AddressFields
  }
  id
  isDefault
  type
  status
}
    ${AddressFieldsFragmentDoc}`;