import { google as Google } from 'google-maps';
import { Input } from '@chakra-ui/react';
import { useCallback, useEffect, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import './places-input.module.scss';

declare const google: Google;

export interface PlacesInputProps {
  name: string;
  shouldSetAddressComponents?: boolean;
  cityInputName?: string;
  stateInputName?: string;
  zipCodeInputName?: string;
  latLongInputName?: string;
}

export function PlacesInput({
  cityInputName = 'city',
  name,
  shouldSetAddressComponents = false,
  stateInputName = 'state',
  zipCodeInputName = 'zipCode',
  latLongInputName,
}: PlacesInputProps) {
  const { setValue, watch } = useFormContext();
  const inputRef = useRef<HTMLInputElement>(null);
  const autoRef = useRef<google.maps.places.Autocomplete | null>(null);

  const preventEnterSubmit = (
    e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };

  const handlePlaceSelect = useCallback(() => {
    const addressObject = autoRef.current?.getPlace();
    const groupedAddressComponents: Record<string, string> = {};

    if (addressObject) {
      addressObject.address_components?.forEach((component) => {
        component.types.forEach((type) => {
          groupedAddressComponents[type] = component.short_name;
        });
      });

      if (shouldSetAddressComponents) {
        const addressLine1 = [
          groupedAddressComponents.street_number,
          groupedAddressComponents.route,
        ].join(' ');

        const city = groupedAddressComponents.locality;
        const state = groupedAddressComponents.administrative_area_level_1;
        const zipCode = groupedAddressComponents.postal_code;

        setValue(name, addressLine1);
        setValue(cityInputName, city);
        setValue(stateInputName, state);
        setValue(zipCodeInputName, zipCode);
      } else {
        const newValue = addressObject.formatted_address ?? addressObject.name;
        setValue(name, newValue);
      }

      if (latLongInputName && addressObject?.geometry?.location) {
        setValue(latLongInputName, {
          lat: addressObject.geometry.location.lat(),
          lng: addressObject.geometry.location.lng(),
        });
      }
    }
  }, [
    cityInputName,
    latLongInputName,
    name,
    setValue,
    shouldSetAddressComponents,
    stateInputName,
    zipCodeInputName,
  ]);

  useEffect(() => {
    if (inputRef.current && !autoRef.current) {
      const autocomplete = new google.maps.places.Autocomplete(
        inputRef.current
      );

      autocomplete.addListener('place_changed', handlePlaceSelect);
      autoRef.current = autocomplete;
    }
  }, [handlePlaceSelect]);

  return (
    <Input
      id="autocomplete"
      onKeyPress={preventEnterSubmit}
      onChange={(e) => {
        setValue(name, e.target.value);
      }}
      ref={inputRef}
      value={watch(name)}
    />
  );
}

export default PlacesInput;
