import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetCouponsAggregateQueryVariables = Types.Exact<{
  where: Types.Coupon_Bool_Exp;
}>;


export type GetCouponsAggregateQuery = { __typename?: 'query_root', coupon_aggregate: { __typename?: 'coupon_aggregate', aggregate?: { __typename?: 'coupon_aggregate_fields', count: number } | null | undefined } };


export const GetCouponsAggregateDocument = gql`
    query GetCouponsAggregate($where: coupon_bool_exp!) {
  coupon_aggregate(where: $where) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetCouponsAggregateQuery__
 *
 * To run a query within a React component, call `useGetCouponsAggregateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCouponsAggregateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCouponsAggregateQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetCouponsAggregateQuery(baseOptions: Apollo.QueryHookOptions<GetCouponsAggregateQuery, GetCouponsAggregateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCouponsAggregateQuery, GetCouponsAggregateQueryVariables>(GetCouponsAggregateDocument, options);
      }
export function useGetCouponsAggregateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCouponsAggregateQuery, GetCouponsAggregateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCouponsAggregateQuery, GetCouponsAggregateQueryVariables>(GetCouponsAggregateDocument, options);
        }
export type GetCouponsAggregateQueryHookResult = ReturnType<typeof useGetCouponsAggregateQuery>;
export type GetCouponsAggregateLazyQueryHookResult = ReturnType<typeof useGetCouponsAggregateLazyQuery>;
export type GetCouponsAggregateQueryResult = Apollo.QueryResult<GetCouponsAggregateQuery, GetCouponsAggregateQueryVariables>;