import { useCallback, useState, useEffect, useRef } from 'react';
import { chakra, Button, Text } from '@chakra-ui/react';
import { FaMoneyCheck } from 'react-icons/fa';
import { toLocalePriceInCents } from '@webapp/util-formatting';
import CardTokenFormOptions from './CardTokenFormOptions';
import CardTokenFormProps from './CardTokenFormProps';
import { CARD_TOKEN_FORM_ID } from './constants';
import useCardTokenFormOptions from './useCardTokenFormOptions';
import CardTokenResponse from './CardTokenResponse';
import './CardTokenForm.css';

export * from './CardTokenFormProps';

interface CardTokenForm {
  submit(
    env: string,
    applicationId: string,
    cb: (err: unknown, res: CardTokenResponse) => void
  ): void;
}

declare const Finix: {
  CardTokenForm?: (id: string, options: CardTokenFormOptions) => CardTokenForm;
  Auth?: (
    env: string,
    merchantId: string,
    cb: (sessionKey: string) => void
  ) => void;
};

export function CardTokenForm(
  props: Omit<CardTokenFormProps, 'onSubmit'> & {
    isLoading?: boolean;
    showPaymentAmount?: boolean;
    amount?: number;
  }
) {
  const formRef = useRef<CardTokenForm>();
  const [fraudSessionId, setFraudSessionId] = useState<string | undefined>();
  const options = useCardTokenFormOptions(props);

  useEffect(() => {
    if (Finix && typeof Finix !== undefined && Finix.CardTokenForm) {
      const form = Finix.CardTokenForm(CARD_TOKEN_FORM_ID, options);
      formRef.current = form;
    }
  }, [options]);

  useEffect(() => {
    Finix?.Auth?.(
      import.meta.env.VITE_FINIX_ENV,
      props.finixMerchantId,
      (sessionKey) => {
        setFraudSessionId(sessionKey);
      }
    );
  }, []);

  const onSubmit = useCallback(() => {
    const form = formRef.current;

    if (form) {
      form.submit(
        import.meta.env.VITE_FINIX_ENV,
        import.meta.env.VITE_FINIX_APPLICATION_ID,
        (_: unknown, res: CardTokenResponse) => {
          const tokenData = res.data || {};
          const token = tokenData.id;
          props.onComplete(token, fraudSessionId || '');
        }
      );
    }
  }, [props, fraudSessionId]);

  return (
    <chakra.div>
      <chakra.div id={CARD_TOKEN_FORM_ID} />
      <Button
        colorScheme="teal"
        leftIcon={<FaMoneyCheck />}
        isLoading={props.isLoading || false}
        marginTop="10px"
        onClick={onSubmit}
        width="100%"
        _hover={{
          background: 'teal.500',
          opacity: 0.8,
        }}
      >
        {props.showPaymentAmount ? (
          <Text>Pay {toLocalePriceInCents(props.amount)}</Text>
        ) : (
          <Text>Submit</Text>
        )}
      </Button>
    </chakra.div>
  );
}

export default CardTokenForm;
