import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Flex,
  Button,
  Input,
  VStack,
  FormLabel,
  Heading,
  HStack,
  Select,
  FormControl,
  FormHelperText,
  Box,
} from '@chakra-ui/react';
import { useLoadDocumentTags } from '@webapp/hooks';
import { SelectOption } from '@webapp/types';
import { DropAndUpload, FormInputV2 } from '@webapp/ui';
import React, { useEffect, useState } from 'react';
import { FaFileAlt } from 'react-icons/fa';

interface DropAndUploadModalProps {
  defaultValues?: {
    title?: string;
    docType?: 'precare' | 'postcare';
  };
  hasTitle?: boolean;
  isOpen: boolean;
  onClose: () => void;
  onSubmitUpload: (
    files: File[],
    title?: string,
    docType?: 'precare' | 'postcare',
    tags?: { tagId: string }[]
  ) => Promise<void>;
  progress: number;
  setProgress: (progress: number) => void;
  allowSelectType?: boolean;
}

export const DropAndUploadModal = ({
  defaultValues = {},
  hasTitle = false,
  isOpen,
  onClose,
  onSubmitUpload,
  progress,
  setProgress,
  allowSelectType = false,
}: DropAndUploadModalProps): JSX.Element => {
  const { title: defaultTitle = '' } = defaultValues;
  const [docType, setDocType] = useState<'precare' | 'postcare'>('precare');
  const [title, setTitle] = useState<string>(defaultTitle);
  const [filesToUpload, setFilesToUpload] = useState<File[]>([]);
  const [tags, setTags] = useState<SelectOption[] | null>([]);

  const clearAndClose = (): void => {
    onClose();
    setProgress(0);
    setFilesToUpload([]);
  };

  const submitAndClose = async (): Promise<void> => {
    await onSubmitUpload(
      filesToUpload,
      title,
      docType,
      (tags || []).map((tag) => ({ tagId: tag.value as string }))
    );

    clearAndClose();
  };

  function handleTitleChange(e: React.ChangeEvent<HTMLInputElement>) {
    setTitle(e.target.value);
  }

  useEffect(() => {
    if (filesToUpload) {
      if (filesToUpload.length === 1) {
        setTitle(filesToUpload[0].name);
      }
    }
  }, [filesToUpload]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent maxW="600px">
        <ModalHeader>Upload document(s)</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {filesToUpload?.length <= 1 ? (
            <VStack spacing="25px">
              {hasTitle && (
                <VStack justifyContent="start" width="100%">
                  <FormLabel margin="0 0 6px" width="100%">
                    Title
                  </FormLabel>
                  <Input
                    margin="0"
                    onChange={handleTitleChange}
                    type="text"
                    value={title}
                  />
                </VStack>
              )}
              <FormInputV2
                label="Tags (Optional)"
                loadOptions={useLoadDocumentTags}
                onChange={(newTags) => setTags(newTags)}
                name="tags"
                type="async-multiselect"
                uncontrolled
                value={tags}
              />
              {allowSelectType && (
                <FormControl mb={4}>
                  <FormLabel>Document Type</FormLabel>
                  <Select
                    w={{ base: '300px', md: 'unset' }}
                    rounded="base"
                    size="sm"
                    onChange={(v) => setDocType(v.target.value)}
                  >
                    <option value="precare">Pre-care</option>
                    <option value="postcare">Post-care</option>
                  </Select>
                  <FormHelperText>
                    What type of document are you uploading?
                  </FormHelperText>
                </FormControl>
              )}
              <Box width="100%">
                <DropAndUpload
                  onDrop={setFilesToUpload}
                  progress={progress}
                  accept=".doc,.docx,.pdf"
                />
              </Box>
            </VStack>
          ) : (
            <VStack spacing={2}>
              {filesToUpload.map((file, index) => (
                <HStack w={'full'} shadow="md" rounded="md" p={2}>
                  <FaFileAlt />
                  <Heading size={'sm'}>{file.name}</Heading>
                </HStack>
              ))}
            </VStack>
          )}
        </ModalBody>
        <ModalFooter>
          <Flex w="full" justifyContent="space-between">
            <Button onClick={clearAndClose}>Cancel</Button>
            <Button colorScheme="teal" onClick={submitAndClose}>
              Upload Document(s)
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default DropAndUploadModal;
