import { extendTheme } from '@chakra-ui/react';

export const theme = extendTheme({
  components: {
    Menu: {
      variants: {
        BOOKED: {
          button: {
            bg: 'blue.50',
            color: 'blue.700',
          },
        },
        CONFIRMED: {
          button: {
            bg: 'teal.50',
            color: 'teal.500',
          },
        },
        CHECKED_IN: {
          button: {
            bg: 'blue.100',
            color: 'blue.700',
          },
        },
        CANCELED: {
          button: {
            bg: 'peach.50',
            color: 'peach.600',
          },
        },
        NO_SHOW: {
          button: {
            bg: 'gold.50',
            color: 'gold.700',
          },
        },
        CHECKED_OUT: {
          button: {
            bg: 'gray.100',
            color: 'gray.700',
          },
        },
        CONFLICT: {
          button: {
            bg: 'red.50',
            color: 'red.600',
          },
        },
      },
    },
  },
});

export default theme;
