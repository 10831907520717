import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type PatientInvoiceSummaryQueryVariables = Types.Exact<{
  patientId: Types.Scalars['uuid'];
}>;


export type PatientInvoiceSummaryQuery = { __typename?: 'query_root', payment_aggregate: { __typename?: 'payment_aggregate', aggregate?: { __typename?: 'payment_aggregate_fields', sum?: { __typename?: 'payment_sum_fields', amount?: number | null | undefined } | null | undefined } | null | undefined }, refunded: { __typename?: 'payment_aggregate', aggregate?: { __typename?: 'payment_aggregate_fields', sum?: { __typename?: 'payment_sum_fields', amount?: number | null | undefined } | null | undefined } | null | undefined }, paid: { __typename?: 'invoice_aggregate', aggregate?: { __typename?: 'invoice_aggregate_fields', sum?: { __typename?: 'invoice_sum_fields', postTaxTotal?: number | null | undefined } | null | undefined, avg?: { __typename?: 'invoice_avg_fields', postTaxTotal?: number | null | undefined } | null | undefined } | null | undefined }, outstanding: { __typename?: 'invoice_aggregate', aggregate?: { __typename?: 'invoice_aggregate_fields', sum?: { __typename?: 'invoice_sum_fields', balanceDue?: number | null | undefined } | null | undefined } | null | undefined } };


export const PatientInvoiceSummaryDocument = gql`
    query PatientInvoiceSummary($patientId: uuid!) {
  payment_aggregate(
    where: {status: {_eq: "COMPLETED"}, paymentMethodType: {_nin: ["patient-wallet", "gift-card"]}, invoice: {patientId: {_eq: $patientId}, isArchived: {_eq: false}}}
  ) {
    aggregate {
      sum {
        amount
      }
    }
  }
  refunded: payment_aggregate(
    where: {status: {_eq: "REFUNDED"}, invoice: {patientId: {_eq: $patientId}, isArchived: {_eq: false}}}
  ) {
    aggregate {
      sum {
        amount
      }
    }
  }
  paid: invoice_aggregate(
    where: {_or: [{status: {_eq: "PAID_IN_FULL"}}, {status: {_eq: "DEPOSIT"}}], patientId: {_eq: $patientId}, isArchived: {_eq: false}}
  ) {
    aggregate {
      sum {
        postTaxTotal
      }
      avg {
        postTaxTotal
      }
    }
  }
  outstanding: invoice_aggregate(
    where: {_or: [{status: {_eq: "PAYMENT_OUTSTANDING"}}, {status: {_eq: "REQUIRES_DEPOSIT"}}, {status: {_eq: "DEPOSIT_PAID"}}, {status: {_eq: "PARTIAL_PAYMENT"}}, {status: {_eq: "INCOMPLETE"}}, {status: {_eq: "AWAITING_PAYMENT"}}, {status: {_eq: "DRAFT"}}, {status: {_eq: "QUOTE"}}], patientId: {_eq: $patientId}, isArchived: {_eq: false}}
  ) {
    aggregate {
      sum {
        balanceDue
      }
    }
  }
}
    `;

/**
 * __usePatientInvoiceSummaryQuery__
 *
 * To run a query within a React component, call `usePatientInvoiceSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `usePatientInvoiceSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePatientInvoiceSummaryQuery({
 *   variables: {
 *      patientId: // value for 'patientId'
 *   },
 * });
 */
export function usePatientInvoiceSummaryQuery(baseOptions: Apollo.QueryHookOptions<PatientInvoiceSummaryQuery, PatientInvoiceSummaryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PatientInvoiceSummaryQuery, PatientInvoiceSummaryQueryVariables>(PatientInvoiceSummaryDocument, options);
      }
export function usePatientInvoiceSummaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PatientInvoiceSummaryQuery, PatientInvoiceSummaryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PatientInvoiceSummaryQuery, PatientInvoiceSummaryQueryVariables>(PatientInvoiceSummaryDocument, options);
        }
export type PatientInvoiceSummaryQueryHookResult = ReturnType<typeof usePatientInvoiceSummaryQuery>;
export type PatientInvoiceSummaryLazyQueryHookResult = ReturnType<typeof usePatientInvoiceSummaryLazyQuery>;
export type PatientInvoiceSummaryQueryResult = Apollo.QueryResult<PatientInvoiceSummaryQuery, PatientInvoiceSummaryQueryVariables>;