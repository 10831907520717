export const STATUSES = [
  {
    background: '#F6FBFF',
    color: '#284B63',
    stepColor: '#C6F6FF',
    value: 'BOOKED',
  },
  {
    background: '#EAFCFF',
    color: '#00758A',
    stepColor: '#00758A',
    value: 'CONFIRMED',
  },
  {
    background: '#EAFCFF',
    color: '#00758A',
    stepColor: '#00758A',
    value: 'CHECKED_IN',
  },
  {
    background: '#EAFCFF',
    color: '#00758A',
    stepColor: '#00758A',
    value: 'CHECKED_OUT',
  },
  {
    background: '#F4F4F3',
    color: '#525257',
    stepColor: '#DCDCDC',
    value: 'COMPLETED',
  },
  {
    background: '#FC8181',
    color: '#822727',
    stepColor: '#DCDCDC',
    value: 'CANCELED',
  },
  {
    background: '#FFF5F1',
    color: '#B26844',
    stepColor: '#DCDCDC',
    value: 'CONFLICT',
  },
  {
    background: '#FFF9EE',
    color: '#83601B',
    stepColor: '#DCDCDC',
    value: 'NO_SHOW',
  },
  //   TODO: Determine if this is appropriate
] as const;

export const APPOINTMENT_STATUSES = STATUSES;

export const DEFAULT_STATUS = STATUSES[0];

export default APPOINTMENT_STATUSES;
