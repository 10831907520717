import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type DeletePatientByPkMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
}>;


export type DeletePatientByPkMutation = { __typename?: 'mutation_root', delete_patient_by_pk?: { __typename?: 'patient', id: any } | null | undefined };


export const DeletePatientByPkDocument = gql`
    mutation DeletePatientByPk($id: uuid!) {
  delete_patient_by_pk(id: $id) {
    id
  }
}
    `;
export type DeletePatientByPkMutationFn = Apollo.MutationFunction<DeletePatientByPkMutation, DeletePatientByPkMutationVariables>;

/**
 * __useDeletePatientByPkMutation__
 *
 * To run a mutation, you first call `useDeletePatientByPkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePatientByPkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePatientByPkMutation, { data, loading, error }] = useDeletePatientByPkMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePatientByPkMutation(baseOptions?: Apollo.MutationHookOptions<DeletePatientByPkMutation, DeletePatientByPkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePatientByPkMutation, DeletePatientByPkMutationVariables>(DeletePatientByPkDocument, options);
      }
export type DeletePatientByPkMutationHookResult = ReturnType<typeof useDeletePatientByPkMutation>;
export type DeletePatientByPkMutationResult = Apollo.MutationResult<DeletePatientByPkMutation>;
export type DeletePatientByPkMutationOptions = Apollo.BaseMutationOptions<DeletePatientByPkMutation, DeletePatientByPkMutationVariables>;