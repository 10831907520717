import {
  useLoadProviders,
  useLoadLocations,
  useLoadServices,
  useLoadServiceCategory,
} from '@webapp/webapp/hooks';
import {
  GraphqlClient,
  ProviderFieldsFragment,
  LocationFieldsFragment,
  ServiceFieldsFragment,
  WorkspaceConfigurationDocument,
  WorkspaceConfigurationQueryResult,
  PatientWorkspaceSourcesDocument,
  PatientWorkspaceSourcesResult,
  LeadFormSourcesDocument,
  LeadFormSourcesResult,
  ServiceCategory,
} from '@webapp/graphql';
import { GenericObjectSelect } from '@webapp/ui';
import { DRAGGABLE_QUESTIONS } from '@webapp/constants';
import {
  AudienceFilterTimeServicePredicate,
  AudienceFilterTimePatientCreationPredicate,
} from './audience-service-time-predicate';
import { parseFormConstants } from './util';

export default [
  {
    label: 'Provider',
    predicates: [
      {
        target: 'patientWorkspace',
        field: 'primaryProviderId',
        ops: ['eq', 'ne'],
        valuesComponent: (onChange: any) => (
          <GenericObjectSelect<ProviderFieldsFragment>
            containerStyle={{
              minW: '200px',
            }}
            isAsync
            onChange={onChange}
            useLoadOptions={useLoadProviders}
            placeholder="All providers"
          />
        ),
        values: null,
      },
    ],
  },
  {
    label: 'Location',
    predicates: [
      {
        target: 'address',
        field: 'zipCode',
        ops: ['eq', 'ne'],
        values: null,
      },
      // XXX Needs a search hook
      {
        target: 'address',
        field: 'city',
        ops: ['eq', 'ne'],
        values: null,
      },
      {
        target: 'address',
        field: 'state',
        ops: ['eq', 'ne'],
        values: async () => [
          'AL',
          'AK',
          'AS',
          'AZ',
          'AR',
          'CA',
          'CO',
          'CT',
          'DE',
          'DC',
          'FM',
          'FL',
          'GA',
          'GU',
          'HI',
          'ID',
          'IL',
          'IN',
          'IA',
          'KS',
          'KY',
          'LA',
          'ME',
          'MH',
          'MD',
          'MA',
          'MI',
          'MN',
          'MS',
          'MO',
          'MT',
          'NE',
          'NV',
          'NH',
          'NJ',
          'NM',
          'NY',
          'NC',
          'ND',
          'MP',
          'OH',
          'OK',
          'OR',
          'PW',
          'PA',
          'PR',
          'RI',
          'SC',
          'SD',
          'TN',
          'TX',
          'UT',
          'VT',
          'VI',
          'VA',
          'WA',
          'WV',
          'WI',
          'WY',
        ],
      },
      {
        target: 'patientWorkspace',
        field: 'primaryLocationId',
        ops: ['eq', 'ne'],
        valuesComponent: (onChange: any) => (
          <GenericObjectSelect<LocationFieldsFragment>
            containerStyle={{
              minW: '200px',
            }}
            isAsync
            onChange={onChange}
            useLoadOptions={useLoadLocations}
            placeholder="All providers"
          />
        ),
        values: null,
      },
    ],
  },
  {
    label: 'Patient Status',
    predicates: [
      {
        target: 'patientWorkspace',
        field: 'gender',
        ops: ['eq', 'ne'],
        values: async () => parseFormConstants(DRAGGABLE_QUESTIONS, 'Gender'),
      },
      {
        target: 'custom',
        field: 'age',
        ops: ['gt', 'lt', 'eq', 'ne'],
        values: null,
      },
      {
        target: 'patientWorkspace',
        field: 'language',
        ops: ['eq', 'ne'],
        values: null,
      },
      {
        target: 'patientWorkspace',
        field: 'attributes.race',
        ops: ['eq', 'ne'],
        values: async () => parseFormConstants(DRAGGABLE_QUESTIONS, 'Race'),
      },
      {
        target: 'patientWorkspace',
        field: 'status',
        ops: ['eq', 'ne'],
        values: async () => ['LEAD', 'ACTIVE', 'FIRED'],
      },
      {
        target: 'patientWorkspace',
        field: 'leadStatus',
        ops: ['eq', 'ne'],
        values: async () => {
          const client = GraphqlClient();
          const { data } = await client.query<
            WorkspaceConfigurationQueryResult['data']
          >({
            query: WorkspaceConfigurationDocument,
          });
          return data?.workspaceConfiguration[0].leadStatusOptions;
        },
      },
      {
        target: 'custom',
        field: 'leadFormSource',
        ops: ['eq', 'ne'],
        values: async () => {
          const client = GraphqlClient();
          const { data } = await client.query({
            query: LeadFormSourcesDocument,
          });
          return data.getLeadFormSources.sources;
        },
      },
      {
        target: 'patientWorkspace',
        field: 'source',
        ops: ['eq', 'ne'],
        values: async () => {
          const client = GraphqlClient();
          const { data } = await client.query({
            query: PatientWorkspaceSourcesDocument,
          });
          return data.getPatientWorkspaceSources.sources;
        },
      },
      {
        target: 'custom',
        field: 'serviceCategoryOfInterest',
        ops: ['eq', 'ne'],
        valuesComponent: (onChange: any, workspaceId: string) => (
          <GenericObjectSelect<ServiceCategory>
            isAsync
            onChange={(result: any) => {
              onChange({ label: result?.label, value: result?.label });
            }}
            useLoadOptions={() =>
              useLoadServiceCategory({
                workspaceId,
              })
            }
            placeholder="Select service category"
          />
        ),
      },
    ],
  },
  {
    label: 'Services of Interest',
    predicates: [
      {
        target: 'custom',
        field: 'servicesOfInterest',
        ops: ['eq', 'ne'],
        values: null,
        valuesComponent: (onChange: any) => (
          <GenericObjectSelect<ServiceFieldsFragment>
            containerStyle={{
              minW: '200px',
            }}
            isAsync
            // Services of interest are stored as string service names, not IDs
            // thus we send the label as a value - the thing used in the DB comparison -
            onChange={(result: any) =>
              onChange({ label: result?.label, value: result?.label })
            }
            // @ts-ignore
            useLoadOptions={useLoadServices}
            placeholder="Select service"
          />
        ),
      },
    ],
  },
  {
    label: 'Last Seen',
    predicates: [
      {
        target: 'custom',
        field: 'firstAppointment',
        ops: ['eq', 'lt', 'gt'],
        values: null,
      },
      {
        target: 'custom',
        field: 'lastAppointment',
        ops: ['eq', 'lt', 'gt'],
        values: null,
      },
    ],
  },
  {
    label: 'Time-based',
    predicates: [
      {
        target: 'custom',
        field: 'birthday',
        ops: ['ie'],
        values: null,
        valuesComponent: (onChange: any) => (
          <AudienceFilterTimePatientCreationPredicate onChange={onChange} />
        ),
      },
      {
        target: 'custom',
        field: 'patientCreated',
        ops: ['lt', 'gt'],
        values: null,
        valuesComponent: (onChange: any) => (
          <AudienceFilterTimePatientCreationPredicate onChange={onChange} />
        ),
      },
      {
        target: 'custom',
        field: 'serviceHistory',
        ops: ['iw', 'inw', 'ie'],
        values: null,
        valuesComponent: (onChange: any) => (
          <AudienceFilterTimeServicePredicate
            key="aftspp"
            onChange={onChange}
          />
        ),
      },
      {
        target: 'custom',
        field: 'upcomingService',
        ops: ['iw', 'inw', 'ie'],
        values: null,
        valuesComponent: (onChange: any) => (
          <AudienceFilterTimeServicePredicate
            key="aftspus"
            onChange={onChange}
          />
        ),
      },
      {
        target: 'custom',
        field: 'apptCancelledNoShow',
        ops: ['iw', 'inw'],
        values: null,
        valuesComponent: (onChange: any) => (
          <AudienceFilterTimeServicePredicate
            key="aftspacns"
            onChange={onChange}
          />
        ),
      },
    ],
  },
];
