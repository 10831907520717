import { DateTimerangePickerInputProps, FormInputProps } from '@webapp/types';
import { useFormContext } from 'react-hook-form';
import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  FormLabel,
  Grid,
  Popover,
  PopoverBody,
  PopoverContent,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';

import { ErrorMessage } from '@hookform/error-message';
import dayjs from 'dayjs';
import { Datepicker } from '@mobiscroll/react';
import { PopoverTrigger } from '../popover-trigger/popover-trigger';
import { DEFAULT_LABEL_STYLE } from './constants';
import './DatePickerFormInput.css';
import TimePicker from './TimePicker';

const TIME_FORMAT = 'hh:mm A';

export function DateTimeRangePickerFormInput({
  id,
  label,
  labelAlign,
  labelStyle = DEFAULT_LABEL_STYLE,
  name,
  rangeStartName,
  rangeEndName,
  stepMinute,
  timezone,
  type,
}: Omit<FormInputProps, 'datePickerProps'> & DateTimerangePickerInputProps) {
  const {
    isOpen: dateIsOpen,
    onToggle: dateOnToggle,
    onClose: dateOnClose,
  } = useDisclosure();

  const {
    isOpen: startTimeIsOpen,
    onToggle: startTimeOnToggle,
    onClose: startTimeOnClose,
  } = useDisclosure();

  const {
    isOpen: endTimeIsOpen,
    onToggle: endTimeOnToggle,
    onClose: endTimeOnClose,
  } = useDisclosure();

  const {
    formState: { errors },
    getValues,
    register,
    setValue,
  } = useFormContext();

  const initialValue = getValues(name);
  const initialDate = dayjs(initialValue ? getValues(name).date : new Date());

  const initialStartTime = initialValue?.start
    ? timezone
      ? dayjs(initialValue?.start).tz(timezone).format(TIME_FORMAT)
      : dayjs(initialValue?.start).format(TIME_FORMAT)
    : '09:00 AM';

  const initialEndTime =
    initialValue?.end && initialValue?.end !== 'Invalid Date'
      ? timezone
        ? dayjs(initialValue?.end).tz(timezone).format(TIME_FORMAT)
        : dayjs(initialValue?.end).format(TIME_FORMAT)
      : '09:30 AM';

  const [startTime, setStartTime] = useState<string>(initialStartTime);
  const [endTime, setEndTime] = useState<string>(initialEndTime);
  const [date, setDate] = useState<string>(initialDate.format());

  const inputId = id ?? name;

  useEffect(() => {
    register(name);
  });

  useEffect(() => {
    if (rangeStartName) {
      setValue(rangeStartName, dayjs(`${date} ${startTime}`).format());
    }
    if (rangeEndName) {
      setValue(rangeEndName, dayjs(`${date} ${endTime}`).format());
    }
    if (!rangeStartName && !rangeEndName) {
      setValue(name, {
        start: startTime,
        end: endTime,
        date,
      });
    }
  }, [date, name, setValue, startTime, endTime]);

  function handleDateChange({ value }: { value: Date; valueText: string }) {
    setDate(value.toUTCString());
    dateOnClose();
  }

  function handleStartTimeChange({ valueText }: { valueText: string }) {
    setStartTime(valueText);
    startTimeOnClose();
  }

  function handleEndTimeChange({ valueText }: { valueText: string }) {
    setEndTime(valueText);
    endTimeOnClose();
  }

  return (
    <Grid
      alignItems={labelAlign}
      className="form-input-date-picker input-outer"
      gridAutoRows="max-content"
      gridRowGap="6px"
      gridTemplateColumns="1fr"
    >
      {Boolean(label) && (
        <FormLabel
          className="input-label"
          htmlFor={inputId}
          margin="0 0 0 0"
          {...labelStyle}
        >
          {label}
        </FormLabel>
      )}
      <Grid className="input-inner" gridRowGap="8px">
        <Grid
          background="white"
          border="1px solid"
          borderColor="gray.200"
          borderRadius="var(--chakra-radii-md)"
          gridTemplateColumns={'2fr 2fr 1fr 2fr'}
        >
          <Popover
            isOpen={dateIsOpen}
            onClose={dateOnClose}
            placement="bottom-start"
          >
            <PopoverTrigger>
              <Button
                borderRadius="0"
                className="date-time-trigger"
                color="text.100"
                fontSize="14px"
                letterSpacing="0.03em"
                onClick={dateOnToggle}
                justifyContent="start"
                variant="ghost"
              >
                {dayjs(date).format('MM/DD/YYYY')}
              </Button>
            </PopoverTrigger>
            <PopoverContent>
              <PopoverBody
                {...(!dateIsOpen && { display: 'none' })}
                padding="0"
              >
                <Datepicker
                  className="date-picker__form_input"
                  controls={['calendar']}
                  defaultSelection={getValues(name)?.date ?? new Date()}
                  display="inline"
                  onChange={handleDateChange}
                  showOuterDays={false}
                  theme="material"
                  themeVariant="light"
                  touchUi={true}
                />
              </PopoverBody>
            </PopoverContent>
          </Popover>

          <TimePicker
            onToggle={startTimeOnToggle}
            handleTimeChange={handleStartTimeChange}
            isOpen={startTimeIsOpen}
            onClose={startTimeOnClose}
            time={startTime}
            stepMinute={stepMinute}
          />
          <Stack
            w={10}
            h={'full'}
            textAlign="center"
            justifyContent={'center'}
            verticalAlign={'center'}
          >
            <Box>{'to'}</Box>
          </Stack>
          <TimePicker
            onToggle={endTimeOnToggle}
            handleTimeChange={handleEndTimeChange}
            isOpen={endTimeIsOpen}
            onClose={endTimeOnClose}
            time={endTime}
            stepMinute={stepMinute}
          />
        </Grid>
        <ErrorMessage
          errors={errors}
          name={name}
          render={({ message }) => (
            <Text
              className="input-error"
              color="red.500"
              _before={{
                display: 'inline',
                content: '"⚠ "',
              }}
            >
              {message}
            </Text>
          )}
        />
      </Grid>
    </Grid>
  );
}

export default DateTimeRangePickerFormInput;
