import {
  chakra,
  Button,
  ButtonGroup,
  Flex,
  Grid,
  Icon,
  Text,
} from '@chakra-ui/react';
import {
  AppointmentStepAppointment,
  CompletedSelectOptionAndPatientConsentId,
} from '@webapp/types';
import { DataTable, FormInput } from '@webapp/ui';
import { useLoadConsents } from '@webapp/webapp/hooks';
import dayjs from 'dayjs';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { HiTrash } from 'react-icons/hi';
import { RiAddBoxLine } from 'react-icons/ri';
import { useNavigate } from 'react-router-dom';
import { Cell, Column } from 'react-table';
import { useUpdateEffect } from 'react-use';
import orderBy from 'lodash/orderBy';
import { MdSend } from 'react-icons/md';
import { useSendPatientAuthLinkLazyQuery } from '@webapp/graphql';
import toast from 'react-hot-toast';
import { useFlagsmith } from 'flagsmith-react';
import { useStores } from '@webapp/state-models';
import { pick } from 'lodash';
import ConsentProgress from './consentProgress';
import PromptModal from '../PromptModal/PromptModal';

interface ConsentsFormProps {
  isEditable: boolean;
  onToggle: () => void;
  appointment: AppointmentStepAppointment;
  mini?: boolean;
  finishedSubmitting?: boolean;
  setFinishedSubmitting?: (finishedSubmitting: boolean) => void;
}

export const ConsentsForm = ({
  isEditable,
  onToggle,
  appointment,
  mini = false,
  finishedSubmitting,
  setFinishedSubmitting,
}: ConsentsFormProps) => {
  const { hasFeature } = useFlagsmith();

  const { workspace } = useStores();

  const parsedWorkspace = JSON.parse(JSON.stringify(workspace));

  const { workspaceConfiguration } = parsedWorkspace;

  const completeAppointmentCopy = pick(
    workspaceConfiguration?.completeAppointmentCopy,
    ['emailBody', 'smsBody', 'subject']
  );

  const [consentIdToSend, setConsentIdToSend] = useState<string | null>(null);

  function clearConsentIdToSend() {
    setConsentIdToSend(null);
  }

  const [sendAuthLink, { loading }] = useSendPatientAuthLinkLazyQuery({
    onCompleted: () => {
      toast.success('Link sent');
      clearConsentIdToSend();
    },
    onError: (e) => {
      toast.error(e.message);
    },
  });

  const { control, getValues, reset, setValue } = useFormContext();

  const navigate = useNavigate();

  const { append, fields, remove, replace } = useFieldArray({
    control,
    name: 'consents',
  });

  const getValue = useCallback(
    (id: string): CompletedSelectOptionAndPatientConsentId | undefined => {
      const index = fields.findIndex((field) => field.id === id);
      const name = `consents.${index}`;
      return getValues(name);
    },
    [fields, getValues]
  );

  const handleCancelClick = useCallback((): void => {
    reset();
    onToggle();
  }, [reset, onToggle]);

  useEffect(() => {
    if (finishedSubmitting && setFinishedSubmitting) {
      setValue('consentsToDelete', []);
      setFinishedSubmitting(false);
    }
  }, [finishedSubmitting, setFinishedSubmitting, setValue]);

  useUpdateEffect(() => {
    const mapped = appointment.resolvedAppointmentConsents.consents.map(
      (consent) => ({
        label: consent.title,
        value: consent.id,
        patientConsentId: consent.patientConsentId,
        completed: consent.completed,
      })
    );

    replace(mapped);
  }, [appointment.resolvedAppointmentConsents]);

  const columns = useMemo(() => {
    const c: Column<Record<'id', string>>[] = [
      {
        accessor: (originalRow: Record<'id', string>) =>
          getValue(originalRow.id)?.label,

        Cell: (originalRow: Cell<Record<'id', string>>) => {
          const index = fields.findIndex(
            (el) => el.id === originalRow.row.original.id
          );

          const name = `consents.${index}`;

          const fieldValue = getValue(originalRow.row.original.id);

          if (!fieldValue) return null;

          const { value, label, completed, patientConsentId } = fieldValue;

          if (isEditable && !completed) {
            return (
              <FormInput
                name={name}
                placeholder="Add Consent"
                style={{
                  minWidth: '200px',
                }}
                selectProps={{
                  defaultOption: getValues(name),
                  loadOptions: useLoadConsents,
                }}
                type="async-select"
              />
            );
          }

          return (
            <Button
              variant="link"
              colorScheme={completed ? 'teal' : 'red'}
              onClick={() => {
                const { patientId } = appointment;

                const link = `/patients/${patientId}/appointments/${appointment.id}/consents/${value}`;

                navigate(link);
              }}
            >
              <Text>{label}</Text>
            </Button>
          );
        },
        disableSortBy: true,
        defaultCanSort: false,
        Header: 'Consent',
        id: 'label',
      },
    ];

    if (hasFeature('send-patient-link') && !isEditable) {
      c.push({
        accessor: (originalRow: Record<'id', string>) =>
          getValue(originalRow.id)?.value,
        Cell: (row) => (
          <Button
            display="flex"
            alignItems="center"
            height="unset"
            minHeight="unset"
            onClick={() => setConsentIdToSend(row.value)}
            padding="5px 15px"
          >
            <Icon as={MdSend} mr={2} />
            Send to Patient
          </Button>
        ),
        Header: '',
        id: 'consentId',
      });
    }

    if (!mini) {
      c.push({
        disableSortBy: true,
        defaultCanSort: false,
        Header: 'Status',
        id: 'completed',
        accessor: (originalRow: Record<'id', string>) =>
          getValue(originalRow.id)?.value,

        Cell: (originalRow: Cell<Record<'value', string>>) => {
          const consents = orderBy(
            appointment?.patientConsents.filter(
              (p) => p.consent.id === originalRow.row.original.value
            ),
            (consent) => dayjs(consent.date)
          );
          const consent = consents?.[consents.length - 1] ?? null;
          if (!consent) return null;
          return <ConsentProgress patientConsent={consent} />;
        },
      });
      c.push({
        id: 'actions',
        accessor: (originalRow: Record<'id', string>) =>
          getValue(originalRow.id)?.value,
        Cell: (originalRow: Cell<Record<'id', string>>) => {
          const fieldValue = getValue(originalRow.row.original.id);

          if (isEditable && !fieldValue?.completed) {
            const index = fields.findIndex(
              (el) => el.id === originalRow.row.original.id
            );

            const name = `consents.${index}`;

            return (
              <Flex width="100%" justifyContent="flex-end">
                <Button
                  colorScheme="red"
                  onClick={() => {
                    const [element, consentsToDelete] = getValues([
                      name,
                      'consentsToDelete',
                    ]);

                    remove(index);

                    if (element?.value) {
                      setValue('consentsToDelete', [
                        ...consentsToDelete,
                        element,
                      ]);
                    }
                  }}
                  variant="ghost"
                >
                  <Icon as={HiTrash} />
                </Button>
              </Flex>
            );
          }

          return '';
        },
        disableSortBy: true,
        defaultCanSort: false,
        Header: '',
      });
    }

    return c;
  }, [
    mini,
    getValue,
    fields,
    isEditable,
    getValues,
    appointment,
    navigate,
    remove,
    setValue,
  ]);

  return (
    <Grid>
      <DataTable
        columns={columns}
        customStyles={{
          borderColor: '#CFEBFF',
          cell: {
            color: '#6C6C72',
          },
          table: {
            borderRadius: '8px',
            minHeight: 'unset',
          },
          tableHeader: {
            background: '#EAFCFF',
            color: '#525257',
            textTransform: 'uppercase',
          },
        }}
        data={fields}
        shouldDisplayPagination={false}
      />
      {isEditable && (
        <Grid>
          <Button
            color="teal.500"
            justifyContent="start"
            leftIcon={<Icon as={RiAddBoxLine} color="teal.500" />}
            margin="15px 0 0"
            onClick={() =>
              append({
                label: '',
                value: '',
                completed: false,
                patientConsentId: '',
              })
            }
            textAlign="left"
            variant="ghost"
            width="max-content"
          >
            Add New Consent
          </Button>
        </Grid>
      )}
      {isEditable && (
        <ButtonGroup margin="30px 0 0">
          <Button type="submit" colorScheme="teal">
            Save Changes
          </Button>
          <Button
            colorScheme="teal"
            onClick={handleCancelClick}
            variant="outline"
          >
            Cancel
          </Button>
        </ButtonGroup>
      )}
      <PromptModal
        bodyText="Are you sure you want to send this information to the patient? This will send an email and SMS to the patient with a link to the document."
        confirmText="Send"
        headerText="Send Patient Consent"
        isLoading={loading}
        isOpen={Boolean(consentIdToSend)}
        onClose={clearConsentIdToSend}
        onConfirm={() => {
          sendAuthLink({
            variables: {
              copy: completeAppointmentCopy,
              locationId: appointment.locationId,
              patientId: appointment.patientId,
              to: `complete/appointments/${appointment.id}`,
            },
          });
        }}
      />
    </Grid>
  );
};

export default ConsentsForm;
