import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { PaymentFieldsFragmentDoc } from '../../fragments/paymentFields.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type UpdatePaymentMutationVariables = Types.Exact<{
  set: Types.Payment_Set_Input;
  paymentId: Types.Scalars['uuid'];
}>;


export type UpdatePaymentMutation = { __typename?: 'mutation_root', update_payment?: { __typename?: 'payment_mutation_response', returning: Array<{ __typename?: 'payment', createdAt: any, id: any, workspaceId: any, paymentMethodId?: any | null | undefined, paymentMethodType: string, payrixType?: string | null | undefined, amount: number, invoiceId?: any | null | undefined, status: string, type?: string | null | undefined }> } | null | undefined };


export const UpdatePaymentDocument = gql`
    mutation updatePayment($set: payment_set_input!, $paymentId: uuid!) {
  update_payment(_set: $set, where: {id: {_eq: $paymentId}}) {
    returning {
      ...PaymentFields
    }
  }
}
    ${PaymentFieldsFragmentDoc}`;
export type UpdatePaymentMutationFn = Apollo.MutationFunction<UpdatePaymentMutation, UpdatePaymentMutationVariables>;

/**
 * __useUpdatePaymentMutation__
 *
 * To run a mutation, you first call `useUpdatePaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePaymentMutation, { data, loading, error }] = useUpdatePaymentMutation({
 *   variables: {
 *      set: // value for 'set'
 *      paymentId: // value for 'paymentId'
 *   },
 * });
 */
export function useUpdatePaymentMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePaymentMutation, UpdatePaymentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePaymentMutation, UpdatePaymentMutationVariables>(UpdatePaymentDocument, options);
      }
export type UpdatePaymentMutationHookResult = ReturnType<typeof useUpdatePaymentMutation>;
export type UpdatePaymentMutationResult = Apollo.MutationResult<UpdatePaymentMutation>;
export type UpdatePaymentMutationOptions = Apollo.BaseMutationOptions<UpdatePaymentMutation, UpdatePaymentMutationVariables>;