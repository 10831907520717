import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type DeleteLocationServicesMutationVariables = Types.Exact<{
  serviceIds?: Types.Maybe<Array<Types.Scalars['uuid']> | Types.Scalars['uuid']>;
  locationId: Types.Scalars['uuid'];
}>;


export type DeleteLocationServicesMutation = { __typename?: 'mutation_root', delete_locationService?: { __typename?: 'locationService_mutation_response', affected_rows: number } | null | undefined };


export const DeleteLocationServicesDocument = gql`
    mutation deleteLocationServices($serviceIds: [uuid!], $locationId: uuid!) {
  delete_locationService(
    where: {serviceId: {_in: $serviceIds}, locationId: {_eq: $locationId}}
  ) {
    affected_rows
  }
}
    `;
export type DeleteLocationServicesMutationFn = Apollo.MutationFunction<DeleteLocationServicesMutation, DeleteLocationServicesMutationVariables>;

/**
 * __useDeleteLocationServicesMutation__
 *
 * To run a mutation, you first call `useDeleteLocationServicesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLocationServicesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLocationServicesMutation, { data, loading, error }] = useDeleteLocationServicesMutation({
 *   variables: {
 *      serviceIds: // value for 'serviceIds'
 *      locationId: // value for 'locationId'
 *   },
 * });
 */
export function useDeleteLocationServicesMutation(baseOptions?: Apollo.MutationHookOptions<DeleteLocationServicesMutation, DeleteLocationServicesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteLocationServicesMutation, DeleteLocationServicesMutationVariables>(DeleteLocationServicesDocument, options);
      }
export type DeleteLocationServicesMutationHookResult = ReturnType<typeof useDeleteLocationServicesMutation>;
export type DeleteLocationServicesMutationResult = Apollo.MutationResult<DeleteLocationServicesMutation>;
export type DeleteLocationServicesMutationOptions = Apollo.BaseMutationOptions<DeleteLocationServicesMutation, DeleteLocationServicesMutationVariables>;