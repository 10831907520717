import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetInvoiceTotalsQueryVariables = Types.Exact<{
  applyBSADiscount?: Types.Maybe<Types.Scalars['Boolean']>;
  gratuity?: Types.Maybe<Types.Scalars['Int']>;
  invoiceId?: Types.Maybe<Types.Scalars['uuid']>;
  locationId?: Types.Maybe<Types.Scalars['uuid']>;
  patientId?: Types.Maybe<Types.Scalars['uuid']>;
  services: Types.Scalars['jsonb'];
  couponIds?: Types.Maybe<Array<Types.Maybe<Types.Scalars['uuid']>> | Types.Maybe<Types.Scalars['uuid']>>;
  membershipIds?: Types.Maybe<Array<Types.Maybe<Types.Scalars['uuid']>> | Types.Maybe<Types.Scalars['uuid']>>;
  packages?: Types.Maybe<Types.Scalars['jsonb']>;
  giftCards?: Types.Maybe<Array<Types.Maybe<Types.GiftCardItemInput>> | Types.Maybe<Types.GiftCardItemInput>>;
}>;


export type GetInvoiceTotalsQuery = { __typename?: 'query_root', getInvoiceTotals: { __typename?: 'GetInvoiceTotalsOutput', balanceDue: number, creditsApplied: number, depositPaid: number, discount: number, gratuity?: number | null | undefined, postTaxTotal: number, preTaxTotal: number, taxTotal: number, discounts: Array<{ __typename?: 'PotentialInvoiceDiscounts', couponAmount: number, couponName: string, couponScope: string, couponType: string, discountTotal: number, discounts: Array<{ __typename?: 'PotentialInvoiceItemDiscount', discountTotal: number, itemName: string, itemQuantity: number }> }> } };


export const GetInvoiceTotalsDocument = gql`
    query GetInvoiceTotals($applyBSADiscount: Boolean = false, $gratuity: Int = 0, $invoiceId: uuid, $locationId: uuid, $patientId: uuid, $services: jsonb!, $couponIds: [uuid], $membershipIds: [uuid], $packages: jsonb, $giftCards: [GiftCardItemInput]) {
  getInvoiceTotals(
    applyBSADiscount: $applyBSADiscount
    gratuity: $gratuity
    invoiceId: $invoiceId
    locationId: $locationId
    patientId: $patientId
    services: $services
    couponIds: $couponIds
    membershipIds: $membershipIds
    packages: $packages
    giftCards: $giftCards
  ) {
    balanceDue
    creditsApplied
    depositPaid
    discount
    discounts {
      couponAmount
      couponName
      couponScope
      couponType
      discountTotal
      discounts {
        discountTotal
        itemName
        itemQuantity
      }
    }
    gratuity
    postTaxTotal
    preTaxTotal
    taxTotal
  }
}
    `;

/**
 * __useGetInvoiceTotalsQuery__
 *
 * To run a query within a React component, call `useGetInvoiceTotalsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvoiceTotalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvoiceTotalsQuery({
 *   variables: {
 *      applyBSADiscount: // value for 'applyBSADiscount'
 *      gratuity: // value for 'gratuity'
 *      invoiceId: // value for 'invoiceId'
 *      locationId: // value for 'locationId'
 *      patientId: // value for 'patientId'
 *      services: // value for 'services'
 *      couponIds: // value for 'couponIds'
 *      membershipIds: // value for 'membershipIds'
 *      packages: // value for 'packages'
 *      giftCards: // value for 'giftCards'
 *   },
 * });
 */
export function useGetInvoiceTotalsQuery(baseOptions: Apollo.QueryHookOptions<GetInvoiceTotalsQuery, GetInvoiceTotalsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInvoiceTotalsQuery, GetInvoiceTotalsQueryVariables>(GetInvoiceTotalsDocument, options);
      }
export function useGetInvoiceTotalsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInvoiceTotalsQuery, GetInvoiceTotalsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInvoiceTotalsQuery, GetInvoiceTotalsQueryVariables>(GetInvoiceTotalsDocument, options);
        }
export type GetInvoiceTotalsQueryHookResult = ReturnType<typeof useGetInvoiceTotalsQuery>;
export type GetInvoiceTotalsLazyQueryHookResult = ReturnType<typeof useGetInvoiceTotalsLazyQuery>;
export type GetInvoiceTotalsQueryResult = Apollo.QueryResult<GetInvoiceTotalsQuery, GetInvoiceTotalsQueryVariables>;