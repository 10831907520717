import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetSmsTemplatePatientsQueryVariables = Types.Exact<{
  where?: Types.Maybe<Types.SmsTemplatePatient_Bool_Exp>;
}>;


export type GetSmsTemplatePatientsQuery = { __typename?: 'query_root', smsTemplatePatient: Array<{ __typename?: 'smsTemplatePatient', sendDate: any, smsTemplate: { __typename?: 'smsTemplate', id: any, smsBody: string } }> };


export const GetSmsTemplatePatientsDocument = gql`
    query GetSmsTemplatePatients($where: smsTemplatePatient_bool_exp) {
  smsTemplatePatient(where: $where) {
    sendDate
    smsTemplate {
      id
      smsBody
    }
  }
}
    `;

/**
 * __useGetSmsTemplatePatientsQuery__
 *
 * To run a query within a React component, call `useGetSmsTemplatePatientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSmsTemplatePatientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSmsTemplatePatientsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetSmsTemplatePatientsQuery(baseOptions?: Apollo.QueryHookOptions<GetSmsTemplatePatientsQuery, GetSmsTemplatePatientsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSmsTemplatePatientsQuery, GetSmsTemplatePatientsQueryVariables>(GetSmsTemplatePatientsDocument, options);
      }
export function useGetSmsTemplatePatientsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSmsTemplatePatientsQuery, GetSmsTemplatePatientsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSmsTemplatePatientsQuery, GetSmsTemplatePatientsQueryVariables>(GetSmsTemplatePatientsDocument, options);
        }
export type GetSmsTemplatePatientsQueryHookResult = ReturnType<typeof useGetSmsTemplatePatientsQuery>;
export type GetSmsTemplatePatientsLazyQueryHookResult = ReturnType<typeof useGetSmsTemplatePatientsLazyQuery>;
export type GetSmsTemplatePatientsQueryResult = Apollo.QueryResult<GetSmsTemplatePatientsQuery, GetSmsTemplatePatientsQueryVariables>;