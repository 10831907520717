import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { LeadFormLinkFieldsFragmentDoc } from '../../fragments/leadFormLink.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type InsertLeadFormLinkMutationVariables = Types.Exact<{
  leadFormLink: Types.LeadFormLink_Insert_Input;
}>;


export type InsertLeadFormLinkMutation = { __typename?: 'mutation_root', insert_leadFormLink?: { __typename?: 'leadFormLink_mutation_response', returning: Array<{ __typename?: 'leadFormLink', id: any, patientId: any, formData: any, workspaceId: any, createdAt: any, updatedAt: any }> } | null | undefined };


export const InsertLeadFormLinkDocument = gql`
    mutation insertLeadFormLink($leadFormLink: leadFormLink_insert_input!) {
  insert_leadFormLink(objects: [$leadFormLink]) {
    returning {
      ...LeadFormLinkFields
    }
  }
}
    ${LeadFormLinkFieldsFragmentDoc}`;
export type InsertLeadFormLinkMutationFn = Apollo.MutationFunction<InsertLeadFormLinkMutation, InsertLeadFormLinkMutationVariables>;

/**
 * __useInsertLeadFormLinkMutation__
 *
 * To run a mutation, you first call `useInsertLeadFormLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertLeadFormLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertLeadFormLinkMutation, { data, loading, error }] = useInsertLeadFormLinkMutation({
 *   variables: {
 *      leadFormLink: // value for 'leadFormLink'
 *   },
 * });
 */
export function useInsertLeadFormLinkMutation(baseOptions?: Apollo.MutationHookOptions<InsertLeadFormLinkMutation, InsertLeadFormLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertLeadFormLinkMutation, InsertLeadFormLinkMutationVariables>(InsertLeadFormLinkDocument, options);
      }
export type InsertLeadFormLinkMutationHookResult = ReturnType<typeof useInsertLeadFormLinkMutation>;
export type InsertLeadFormLinkMutationResult = Apollo.MutationResult<InsertLeadFormLinkMutation>;
export type InsertLeadFormLinkMutationOptions = Apollo.BaseMutationOptions<InsertLeadFormLinkMutation, InsertLeadFormLinkMutationVariables>;