import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type InsertFlowsheetParameterMutationVariables = Types.Exact<{
  apiKey: Types.Scalars['String'];
  isSystemParameter?: Types.Maybe<Types.Scalars['Boolean']>;
  title: Types.Scalars['String'];
  type: Types.Scalars['String'];
  unit: Types.Scalars['String'];
}>;


export type InsertFlowsheetParameterMutation = { __typename?: 'mutation_root', insert_flowsheetParameter?: { __typename?: 'flowsheetParameter_mutation_response', returning: Array<{ __typename?: 'flowsheetParameter', apiKey: string, id: any, isSystemParameter: boolean, title: string, type: string, unit: string }> } | null | undefined };


export const InsertFlowsheetParameterDocument = gql`
    mutation InsertFlowsheetParameter($apiKey: String!, $isSystemParameter: Boolean = false, $title: String!, $type: String!, $unit: String!) {
  insert_flowsheetParameter(
    objects: [{apiKey: $apiKey, isSystemParameter: $isSystemParameter, title: $title, type: $type, unit: $unit}]
  ) {
    returning {
      apiKey
      id
      isSystemParameter
      title
      type
      unit
    }
  }
}
    `;
export type InsertFlowsheetParameterMutationFn = Apollo.MutationFunction<InsertFlowsheetParameterMutation, InsertFlowsheetParameterMutationVariables>;

/**
 * __useInsertFlowsheetParameterMutation__
 *
 * To run a mutation, you first call `useInsertFlowsheetParameterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertFlowsheetParameterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertFlowsheetParameterMutation, { data, loading, error }] = useInsertFlowsheetParameterMutation({
 *   variables: {
 *      apiKey: // value for 'apiKey'
 *      isSystemParameter: // value for 'isSystemParameter'
 *      title: // value for 'title'
 *      type: // value for 'type'
 *      unit: // value for 'unit'
 *   },
 * });
 */
export function useInsertFlowsheetParameterMutation(baseOptions?: Apollo.MutationHookOptions<InsertFlowsheetParameterMutation, InsertFlowsheetParameterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertFlowsheetParameterMutation, InsertFlowsheetParameterMutationVariables>(InsertFlowsheetParameterDocument, options);
      }
export type InsertFlowsheetParameterMutationHookResult = ReturnType<typeof useInsertFlowsheetParameterMutation>;
export type InsertFlowsheetParameterMutationResult = Apollo.MutationResult<InsertFlowsheetParameterMutation>;
export type InsertFlowsheetParameterMutationOptions = Apollo.BaseMutationOptions<InsertFlowsheetParameterMutation, InsertFlowsheetParameterMutationVariables>;