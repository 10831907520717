import { ChangeEvent, useEffect, useState } from 'react';
import { FaTrash } from 'react-icons/fa';
import { observer } from 'mobx-react-lite';
import { MultiValue, SingleValue } from 'react-select';
import { useFlagsmith } from 'flagsmith-react';
import {
  Button,
  FormControl,
  FormLabel,
  HStack,
  IconButton,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Select,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { configuredDayjs as dayjs } from '@webapp/util-time';
import isBetween from 'dayjs/plugin/isBetween';
import { PromptModal } from '@webapp/webapp/ui-composites';
import { AppointmentService, useStores } from '@webapp/state-models';
import { ObjectOption } from '@webapp/types';
import {
  Service,
  useGetAppointmentAvailabilityLazyQuery,
  useGetProvidersLazyQuery,
} from '@webapp/graphql';
import { GenericObjectSelect } from '@webapp/ui';
import { useLoadServices } from '@webapp/webapp/hooks';
import { BiDownArrow, BiUpArrow } from 'react-icons/bi';

dayjs.extend(isBetween);

interface AppointmentServiceFormProps {
  isReordering: boolean;
  moveService: (order: number, direction: any) => void;
  order: number;
  removeService: (service: AppointmentService) => void;
  service: AppointmentService;
  hasFormError?: boolean;
  key: string;
}

const AppointmentServiceForm = observer(
  ({
    isReordering,
    moveService,
    order,
    removeService,
    service,
    hasFormError,
    key,
  }: AppointmentServiceFormProps) => {
    const { hasFeature } = useFlagsmith();
    const { draftAppointment } = useStores();
    const [getProviders, { data: providers }] = useGetProvidersLazyQuery();

    function handleReplaceService(
      newValue:
        | SingleValue<ObjectOption<Service | null>>
        | MultiValue<ObjectOption<Service | null>>,
      index: number
    ) {
      draftAppointment?.replaceService(
        (newValue as SingleValue<ObjectOption<Service | null>>)
          ?.object as Service,
        index
      );
    }

    const [selectedProviderId, setSelectedProviderId] = useState(
      service.providerId || draftAppointment?.providerId || ''
    );
    const [pendingProviderId, setPendingProviderId] = useState(null);
    const { isOpen, onOpen, onClose } = useDisclosure();

    const [getAppointmentAvailability, { data }] =
      useGetAppointmentAvailabilityLazyQuery();

    const checkProviderAvailability = async (providerId) => {
      const { data } = await getAppointmentAvailability({
        variables: {
          providerId,
          locationId: draftAppointment?.location?.id,
          serviceIds: [service?.service?.id],
          day: dayjs(draftAppointment?.startTime).format('YYYY-MM-DD'),
          numberOfDays: 1,
        },
      });

      const appointmentStart = dayjs(draftAppointment?.startTime);
      const availability =
        data?.getProviderAvailabilityForLocation?.availability || [];

      // Check if the appointment time is within any of the availability ranges
      const isAvailable = availability.some(({ start, end }) => {
        const rangeStart = dayjs(start, 'YYYY-MM-DD HH:mm:ssZ');
        const rangeEnd = dayjs(end, 'YYYY-MM-DD HH:mm:ssZ');
        return appointmentStart.isBetween(rangeStart, rangeEnd, null, '[)');
      });

      return isAvailable;
    };

    const handleProviderChange = async (e) => {
      const providerId = e.target.value;
      if (hasFeature('calendar:double-booking-confirmation')) {
        const available = await checkProviderAvailability(providerId);

        if (available) {
          service.setProviderId(providerId);
          setSelectedProviderId(providerId);
        } else {
          setPendingProviderId(providerId);
          onOpen();
        }
      } else {
        service.setProviderId(providerId);
        setSelectedProviderId(providerId);
      }
    };

    const handleConfirm = () => {
      if (pendingProviderId) {
        service.setProviderId(pendingProviderId);
        setSelectedProviderId(pendingProviderId);
        setPendingProviderId(null);
      }
      onClose();
    };

    useEffect(() => {
      const canProvideService: boolean = providers
        ? providers.provider.find((provider: { id: string }) => {
            return draftAppointment?.providerId === provider.id;
          }) !== undefined
        : false;
      if (draftAppointment?.providerId && canProvideService) {
        service.setProviderId(draftAppointment?.providerId);
      }
    }, [draftAppointment?.providerId, providers, service]);

    useEffect(() => {
      if (draftAppointment?.id != 'DRAFT' && service.serviceMinutesOverride) {
        service.setServiceMinutesOverride(service.serviceMinutesOverride);
      }
    }, []);

    useEffect(() => {
      getProviders({
        variables: {
          where: {
            locationProviders: {
              locationId: { _eq: draftAppointment?.location?.id },
            },
            serviceProviders: {
              serviceId: {
                _in: [service?.service?.id],
              },
            },
          },
        },
      });
    }, [service?.service?.id, draftAppointment?.location, getProviders]);

    return (
      <Stack key={key}>
        {isReordering ? (
          <Stack
            rounded="lg"
            borderWidth={1}
            p={2}
            mb={2}
            borderColor={'brand.primary'}
          >
            <HStack>
              {service?.order?.toString() && (
                <Text color="brand.primary" fontWeight="500" pr={2}>
                  {service.order + 1}
                </Text>
              )}
              <Text width="100%">{service.service?.name as string}</Text>
              {service?.order !== 0 && (
                <IconButton
                  size="sm"
                  onClick={() => moveService(order, 'up')}
                  aria-label="Move Service Up"
                  variant="ghost"
                  icon={<BiUpArrow />}
                />
              )}
              {service?.order !== draftAppointment?.services?.length - 1 && (
                <IconButton
                  size="sm"
                  onClick={() => moveService(order, 'down')}
                  aria-label="Move Service Down"
                  variant="ghost"
                  icon={<BiDownArrow />}
                />
              )}
              <IconButton
                onClick={() => removeService(service)}
                aria-label="remove service from appointment"
                icon={<FaTrash />}
              />
            </HStack>
          </Stack>
        ) : (
          <Stack
            rounded="lg"
            borderWidth={1}
            p={2}
            borderColor={'brand.primary'}
            mb={2}
          >
            <HStack alignItems={'flex-end'}>
              <FormControl id="services" flexGrow={1}>
                <FormLabel>Selected service</FormLabel>
                <GenericObjectSelect<Service>
                  isAsync
                  defaultValue={{
                    label: service.service?.name as string,
                    value: service.id as string,
                    object: service.service as Service,
                  }}
                  onChange={(newValue) => handleReplaceService(newValue, order)}
                  useLoadOptions={() =>
                    useLoadServices({
                      groupByCategory: true,
                      where: {
                        locationServices: {
                          locationId: { _eq: draftAppointment?.location?.id },
                        },
                      },
                    })
                  }
                  placeholder="Search for a service"
                />
              </FormControl>
              <FormControl id="minutes" maxW={20}>
                <FormLabel>Minutes</FormLabel>
                <NumberInput
                  max={480}
                  min={10}
                  onChange={(_valueAsString: string, valueAsNumber: number) => {
                    service.setServiceMinutesOverride(valueAsNumber);
                    service.setServiceDurationMinutes(valueAsNumber);
                  }}
                  value={service.serviceDurationMinutes || 0}
                >
                  <NumberInputField id="amount" />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
              </FormControl>
              <IconButton
                onClick={() => removeService(service)}
                aria-label="remove service from appointment"
                icon={<FaTrash />}
              />
            </HStack>
            <HStack>
              {providers && (
                <FormControl>
                  <FormLabel>Provider</FormLabel>
                  <Select
                    isAsync={false}
                    id={`select-provider-${service.id}`}
                    onChange={handleProviderChange}
                    value={selectedProviderId}
                    placeholder="Select a provider"
                    isInvalid={
                      hasFormError &&
                      !(service.providerId || draftAppointment?.providerId)
                    }
                    errorBorderColor="red.300"
                  >
                    {providers.provider.map((provider) => (
                      <option
                        value={provider.id}
                      >{`${provider.firstName} ${provider.lastName}`}</option>
                    ))}
                  </Select>

                  <PromptModal
                    bodyText="The selected provider is not available at this time. Do you want to proceed with scheduling the appointment?"
                    headerText="Provider is Unavailable"
                    confirmText="Book Appointment"
                    denyText="Cancel"
                    isOpen={isOpen}
                    onClose={onClose}
                    onConfirm={handleConfirm}
                  />
                </FormControl>
              )}
            </HStack>
            {service.requiredDeviceTypes &&
              !service.overrideDeviceRequirement &&
              service.requiredDeviceTypes.length > 0 &&
              (service.availableDevices?.length > 0 ? (
                <FormControl id="select-device">
                  <FormLabel>Required device</FormLabel>
                  <Select
                    isInvalid={hasFormError && !service.deviceId}
                    placeholder="Select device"
                    onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                      service.setDeviceId(e.target.value)
                    }
                  >
                    {service.availableDevices.map((ad) => (
                      <option value={ad.id}>{ad.name}</option>
                    ))}
                  </Select>
                </FormControl>
              ) : (
                <HStack>
                  <Text size="xs" textColor={'red.600'} fontWeight="bold">
                    No devices available for time.
                  </Text>
                  <Button
                    onClick={() => service.setOverrideDeviceRequirement(true)}
                    variant="ghost"
                    colorScheme={'red'}
                  >
                    Override
                  </Button>
                </HStack>
              ))}
          </Stack>
        )}
      </Stack>
    );
  }
);

export default AppointmentServiceForm;
