import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetImportJobQueryVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
}>;


export type GetImportJobQuery = { __typename?: 'query_root', importJob_by_pk?: { __typename?: 'importJob', id: any, status: string, errorRows?: any | null | undefined, successRows?: any | null | undefined } | null | undefined };


export const GetImportJobDocument = gql`
    query GetImportJob($id: uuid!) {
  importJob_by_pk(id: $id) {
    id
    status
    errorRows
    successRows
  }
}
    `;

/**
 * __useGetImportJobQuery__
 *
 * To run a query within a React component, call `useGetImportJobQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetImportJobQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetImportJobQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetImportJobQuery(baseOptions: Apollo.QueryHookOptions<GetImportJobQuery, GetImportJobQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetImportJobQuery, GetImportJobQueryVariables>(GetImportJobDocument, options);
      }
export function useGetImportJobLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetImportJobQuery, GetImportJobQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetImportJobQuery, GetImportJobQueryVariables>(GetImportJobDocument, options);
        }
export type GetImportJobQueryHookResult = ReturnType<typeof useGetImportJobQuery>;
export type GetImportJobLazyQueryHookResult = ReturnType<typeof useGetImportJobLazyQuery>;
export type GetImportJobQueryResult = Apollo.QueryResult<GetImportJobQuery, GetImportJobQueryVariables>;