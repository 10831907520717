import { mergeAttributes, Node } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';

import Component from './Component';

export default Node.create({
  name: 'EmbeddedChart',

  group: 'block',

  atom: true,

  addOptions() {
    return {
      ...this.parent?.(),
      creatingTemplate: false,
    };
  },

  addAttributes() {
    return {
      data: {
        default: '[]',
      },
      yAxisKey: {
        default: '',
      },
      yAxisLabel: {
        default: '',
      },
      xAxisKey: {
        default: '',
      },
      xAxisLabel: {
        default: '',
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'embedded-chart',
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['embedded-chart', mergeAttributes(HTMLAttributes)];
  },

  addNodeView() {
    return ReactNodeViewRenderer(Component);
  },
});
