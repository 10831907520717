import { PropsWithChildren } from 'react';
import {
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  UseDisclosureProps,
} from '@chakra-ui/react';
import { FormInputV2 } from '@webapp/ui';

type RecurringUpdateOptionsModalProps = {
  onCancel?: () => void;
  onChange: (value: any) => void;
  onConfirm: () => void;
  updateOption: { label: string; value: string | number };
} & Required<Pick<UseDisclosureProps, 'isOpen' | 'onClose'>>;

export function RecurringUpdateOptionsModal({
  isOpen,
  onClose,
  onChange,
  onConfirm,
  updateOption,
}: PropsWithChildren<RecurringUpdateOptionsModalProps>) {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Edit recurring appointment</ModalHeader>
        <ModalBody rounded="xl" minH="100px">
          <Stack spacing={4} pt={2}>
            <FormInputV2
              testId="formInput-recurringUpdateOption"
              uncontrolled
              isSearchable={false}
              name="recurringUpdateOption"
              onChange={(newValue) => {
                onChange(newValue);
              }}
              options={[
                { label: 'This appointment only', value: 'single' }, // update appointment add exception
                {
                  label: 'This and following appointments', // update appointment recurring end, create new recurring appoinment
                  value: 'forward',
                },
                { label: 'All appointments', value: 'all' }, // update appointment recurring
              ]}
              type="select"
              value={updateOption}
            />
          </Stack>
        </ModalBody>
        <ModalFooter
          background="white"
          borderBottomLeftRadius="8px"
          borderBottomRightRadius="8px"
          borderColor="gray.300"
          borderStyle="solid"
          borderTopWidth="1px"
        >
          <ButtonGroup
            display="flex"
            justifyContent="space-between"
            width="100%"
          >
            <Button onClick={onClose} variant="ghost" width="max-content">
              <Text>Cancel</Text>
            </Button>
            <Button
              data-testid="updateRecurringAppointmentButton"
              background="blue.600"
              color="white"
              onClick={onConfirm}
              width="max-content"
              _hover={{
                background: 'blue.600',
              }}
            >
              <Text>Update</Text>
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default RecurringUpdateOptionsModal;
