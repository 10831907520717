import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type CalculateInvoiceTotalsQueryVariables = Types.Exact<{
  input: Types.CalculateInvoiceTotalsInput;
}>;


export type CalculateInvoiceTotalsQuery = { __typename?: 'query_root', calculateInvoiceTotals: { __typename?: 'CalculateInvoiceTotalsOutput', amountPaid: number, balanceDue: number, creditsApplied: number, depositPaid: number, discount: number, gratuity: number, gratuityEnabledTotal: number, postTaxTotal: number, preTaxTotal: number, taxTotal: number, discounts: Array<{ __typename?: 'PotentialInvoiceDiscounts', couponAmount: number, couponName: string, couponScope: string, couponType: string, discountTotal: number, discounts: Array<{ __typename?: 'PotentialInvoiceItemDiscount', discountTotal: number, itemName: string, itemQuantity: number }> }> } };


export const CalculateInvoiceTotalsDocument = gql`
    query CalculateInvoiceTotals($input: CalculateInvoiceTotalsInput!) {
  calculateInvoiceTotals(input: $input) {
    amountPaid
    balanceDue
    creditsApplied
    depositPaid
    discount
    discounts {
      couponAmount
      couponName
      couponScope
      couponType
      discountTotal
      discounts {
        discountTotal
        itemName
        itemQuantity
      }
    }
    gratuity
    gratuityEnabledTotal
    postTaxTotal
    preTaxTotal
    taxTotal
  }
}
    `;

/**
 * __useCalculateInvoiceTotalsQuery__
 *
 * To run a query within a React component, call `useCalculateInvoiceTotalsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalculateInvoiceTotalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalculateInvoiceTotalsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCalculateInvoiceTotalsQuery(baseOptions: Apollo.QueryHookOptions<CalculateInvoiceTotalsQuery, CalculateInvoiceTotalsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CalculateInvoiceTotalsQuery, CalculateInvoiceTotalsQueryVariables>(CalculateInvoiceTotalsDocument, options);
      }
export function useCalculateInvoiceTotalsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CalculateInvoiceTotalsQuery, CalculateInvoiceTotalsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CalculateInvoiceTotalsQuery, CalculateInvoiceTotalsQueryVariables>(CalculateInvoiceTotalsDocument, options);
        }
export type CalculateInvoiceTotalsQueryHookResult = ReturnType<typeof useCalculateInvoiceTotalsQuery>;
export type CalculateInvoiceTotalsLazyQueryHookResult = ReturnType<typeof useCalculateInvoiceTotalsLazyQuery>;
export type CalculateInvoiceTotalsQueryResult = Apollo.QueryResult<CalculateInvoiceTotalsQuery, CalculateInvoiceTotalsQueryVariables>;