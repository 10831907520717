import {
  PhotoFieldsFragment,
  StorageType,
  useGetSignedUploadUrlLazyQuery,
  useInsertPhotoMutation,
} from '@webapp/graphql';
import loadImage, { MetaData } from 'blueimp-load-image';
import { Dayjs } from 'dayjs';
import { useState, useCallback, useEffect } from 'react';
import {
  DropzoneInputProps,
  DropzoneRootProps,
  useDropzone,
} from 'react-dropzone';
import toast from 'react-hot-toast';
import extractDateFromMetadata from './extractDateFromMetadata';
import uploadImageToS3 from './uploadImageToS3';

interface UseUploadImagesProps {
  // eslint-disable-next-line camelcase
  defaultValues?: {
    appointmentId?: string;
    isSharedWithPatient?: boolean;
    mediaType?: string;
    patientId?: string;
  };
  onUpload?: (photo: PhotoFieldsFragment) => void;
  onAnonymousUpload?: (photoUrl: string) => void;

  uploadingAnonymously?: boolean;
  refetchQueries?: string[];
  storageType?: StorageType;
  workspaceId: string;
}

type FileWithPreview = File & { preview: string };

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface UseUploadImages {
  getRootProps: (props?: DropzoneRootProps) => DropzoneRootProps;
  getInputProps: (props?: DropzoneInputProps) => DropzoneInputProps;
  images: FileWithPreview[];
  isDragActive: boolean;
  isLoading?: boolean;
  progress: number;
}

export function useUploadImages(props: UseUploadImagesProps): UseUploadImages {
  const {
    defaultValues = {},
    onUpload = () => undefined,
    refetchQueries = [],
    storageType = StorageType.Private,
    uploadingAnonymously = false,
    onAnonymousUpload = (photoUrl: string) => undefined,
    workspaceId,
  } = props ?? {};

  const [mediaDate, setMediaDate] = useState<Dayjs | null>(null);

  const [progress, setProgress] = useState<number>(0);

  const [filesToUpload, setFilesToUpload] = useState<
    (File & { preview: string })[]
  >([]);

  const [insertPhotoMutation, { loading: insertPhotoLoading }] =
    useInsertPhotoMutation({
      onCompleted: (data) => {
        const [photo] = data.insert_photo?.returning ?? [];

        if (photo) {
          onUpload(photo);
        }
      },
      onError: (error) => {
        toast.error(error.message);
      },
      refetchQueries,
    });

  const [getSignedUrl, { loading: getSignedUrlLoading }] =
    useGetSignedUploadUrlLazyQuery({
      onCompleted: async ({ getSignedUploadUrl }) => {
        const signedUrl = getSignedUploadUrl?.signedUrl;

        if (filesToUpload.length && signedUrl) {
          const presignedPostUrl = JSON.parse(signedUrl);
          const imageKey = presignedPostUrl.fields.key;

          const photoUrl =
            storageType === StorageType.Public
              ? `${
                  presignedPostUrl.url[presignedPostUrl.url.length - 1] === '/'
                    ? presignedPostUrl.url.slice(0, -1)
                    : presignedPostUrl.url
                }/${imageKey}`
              : imageKey;

          const [file] = filesToUpload;

          await uploadImageToS3({
            fileContents: file,
            onCompleted: () => {
              if (workspaceId && mediaDate && !uploadingAnonymously) {
                insertPhotoMutation({
                  variables: {
                    photo: {
                      filePath: photoUrl,
                      mediaDate: mediaDate.toISOString(),
                      workspaceId,
                      ...defaultValues,
                    },
                  },
                });
              }
              if (uploadingAnonymously) {
                onAnonymousUpload(photoUrl);
              }
            },
            onProgress: (val: number) => {
              setProgress(val);
            },
            signedUrl: presignedPostUrl,
          });
        }
      },
      onError: (error) => {
        toast.error(error.message);
      },
    });

  /* 
      A new url is generated every time a photo is selected,
      the rationale being, if the file type changes then 
      the url is no longer valid(?)
    */
  useEffect(() => {
    if (filesToUpload.length) {
      const [file] = filesToUpload;

      getSignedUrl({
        variables: { fileType: file.type, storageType },
      });
    }
  }, [filesToUpload, getSignedUrl, storageType]);

  const onDrop = useCallback(async (acceptedFiles: File[]) => {
    if (acceptedFiles && acceptedFiles.length > 0) {
      const metadata: MetaData = await new Promise((resolve) =>
        // eslint-disable-next-line no-promise-executor-return
        loadImage.parseMetaData(
          acceptedFiles[0],
          (data: MetaData) => {
            resolve(data);
          },
          {
            maxMetaDataSize: 5000000,
          }
        )
      );

      setFilesToUpload(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );

      setMediaDate(extractDateFromMetadata(metadata));
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: 'image/*',
    maxFiles: 1,
    multiple: false,
  });

  return {
    images: filesToUpload,
    isLoading: insertPhotoLoading || getSignedUrlLoading,
    progress,
    getRootProps,
    getInputProps,
    isDragActive,
  };
}

export default useUploadImages;
