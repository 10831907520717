export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  _uuid: any;
  bigint: any;
  bpchar: any;
  date: any;
  float8: any;
  json: any;
  jsonb: any;
  numeric: any;
  smallint: any;
  timestamp: any;
  timestamptz: any;
  timetz: any;
  tstzrange: any;
  tsvector: any;
  uuid: any;
};

export type AddressInput = {
  addressLine1: Scalars['String'];
  addressLine2?: InputMaybe<Scalars['String']>;
  city: Scalars['String'];
  country?: InputMaybe<Scalars['String']>;
  formattedAddress?: InputMaybe<Scalars['String']>;
  lat?: InputMaybe<Scalars['Float']>;
  lng?: InputMaybe<Scalars['Float']>;
  state: Scalars['String'];
  zipCode: Scalars['String'];
};

export type AppointmentConsentMetadata = {
  __typename?: 'AppointmentConsentMetadata';
  isComplete: Scalars['Boolean'];
};

export type AppointmentConsumableStatusDetails = {
  __typename?: 'AppointmentConsumableStatusDetails';
  appointmentServiceId: Scalars['uuid'];
  consumableBrandName: Scalars['String'];
  consumableMetadata: Scalars['jsonb'];
  consumableTypeId: Scalars['uuid'];
  consumableTypeName: Scalars['String'];
  consumableTypeUnitName: Scalars['String'];
  quantity: Scalars['Float'];
};

export type AppointmentCustomFormMetadata = {
  __typename?: 'AppointmentCustomFormMetadata';
  isComplete: Scalars['Boolean'];
};

export type AppointmentMetadata = {
  __typename?: 'AppointmentMetadata';
  requiresAttention: Scalars['Boolean'];
};

export type AppointmentServiceConsumableCanUpdate = {
  __typename?: 'AppointmentServiceConsumableCanUpdate';
  appointmentServiceId: Scalars['uuid'];
  consumable: MinimalResolvedConsumable;
  quantity: Scalars['Float'];
  service: MinimalResolvedService;
  text: Scalars['String'];
};

export type AppointmentServiceConsumableTypeMarkedUnused = {
  __typename?: 'AppointmentServiceConsumableTypeMarkedUnused';
  text: Scalars['String'];
  unusedAppointmentServiceConsumableTypeId: Scalars['uuid'];
};

export type AppointmentServiceConsumableTypeToResolve = {
  __typename?: 'AppointmentServiceConsumableTypeToResolve';
  appointmentServiceId: Scalars['uuid'];
  quantity: Scalars['Float'];
  service: MinimalResolvedService;
  text: Scalars['String'];
  type: MinimalResolvedConsumableType;
};

export type AppointmentServiceConsumablesStatusOutput = {
  __typename?: 'AppointmentServiceConsumablesStatusOutput';
  resolvedBase: Array<AppointmentConsumableStatusDetails>;
  text: Scalars['String'];
};

export type AppointmentServiceUpdateObject = {
  appointmentId: Scalars['uuid'];
  services: Array<Scalars['jsonb']>;
  shouldUpdateAppointmentTimeRange?: InputMaybe<Scalars['Boolean']>;
};

export type AudienceCountResult = {
  __typename?: 'AudienceCountResult';
  count?: Maybe<Scalars['Int']>;
};

export type AudienceMember = {
  __typename?: 'AudienceMember';
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  lastName?: Maybe<Scalars['String']>;
  leadStatus?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type AudienceResult = {
  __typename?: 'AudienceResult';
  patientWorkspace?: Maybe<PatientWorkspace>;
  patientWorkspaceId: Scalars['uuid'];
};

export type AuthLinkCopy = {
  emailBody?: InputMaybe<Scalars['String']>;
  smsBody?: InputMaybe<Scalars['String']>;
  subject?: InputMaybe<Scalars['String']>;
};

export type AuthLinkOutput = {
  __typename?: 'AuthLinkOutput';
  link: Scalars['String'];
  token: Scalars['String'];
};

export enum AuthPlatform {
  Facebook = 'FACEBOOK',
  Google = 'GOOGLE'
}

export type AuthUrlOutput = {
  __typename?: 'AuthUrlOutput';
  url: Scalars['String'];
};

export type AvailabilityRange = {
  __typename?: 'AvailabilityRange';
  end: Scalars['String'];
  start: Scalars['String'];
};

export type AvailableProviderInput = {
  firstName?: InputMaybe<Scalars['String']>;
  id: Scalars['uuid'];
  lastName?: InputMaybe<Scalars['String']>;
  profilePicture?: InputMaybe<Scalars['String']>;
  serviceIds?: InputMaybe<Array<InputMaybe<Scalars['uuid']>>>;
};

export type AvailableProviderOutput = {
  __typename?: 'AvailableProviderOutput';
  firstName: Scalars['String'];
  id: Scalars['uuid'];
  lastName: Scalars['String'];
  profilePicture?: Maybe<Scalars['String']>;
  serviceIds: Array<Maybe<Scalars['uuid']>>;
};

export type BeforeAndAfterOutput = {
  __typename?: 'BeforeAndAfterOutput';
  image: Scalars['String'];
  newPhotoId: Scalars['String'];
  photo?: Maybe<Photo>;
};

export type BookExternalAppointmentInput = {
  approvedNoShowFeeAmount?: InputMaybe<Scalars['Int']>;
  availableProviders?: InputMaybe<Array<InputMaybe<AvailableProviderInput>>>;
  billingAddress?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  locationId: Scalars['uuid'];
  noShowFeePaymentMethodId?: InputMaybe<Scalars['uuid']>;
  patientId?: InputMaybe<Scalars['String']>;
  paymentId?: InputMaybe<Scalars['uuid']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  providerId?: InputMaybe<Scalars['uuid']>;
  serviceIds?: InputMaybe<Array<InputMaybe<Scalars['uuid']>>>;
  time: Scalars['String'];
  workspaceId: Scalars['uuid'];
};

export type BookExternalAppointmentOutput = {
  __typename?: 'BookExternalAppointmentOutput';
  appointmentId: Scalars['uuid'];
};

export type BookPatientAppointmentInput = {
  datetime: Scalars['String'];
  deposit?: InputMaybe<PaymentInput>;
  finixDeposit?: InputMaybe<FinixDepositInput>;
  locationId: Scalars['uuid'];
  patientId: Scalars['uuid'];
  providerId?: InputMaybe<Scalars['uuid']>;
  serviceIds: Array<Scalars['uuid']>;
};

export type BookPatientAppointmentOutput = {
  __typename?: 'BookPatientAppointmentOutput';
  appointment?: Maybe<Appointment>;
  appointmentId: Scalars['uuid'];
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Boolean']>;
  _gt?: InputMaybe<Scalars['Boolean']>;
  _gte?: InputMaybe<Scalars['Boolean']>;
  _in?: InputMaybe<Array<Scalars['Boolean']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Boolean']>;
  _lte?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Scalars['Boolean']>;
  _nin?: InputMaybe<Array<Scalars['Boolean']>>;
};

export type BulkScheduleRemindersOutput = {
  __typename?: 'BulkScheduleRemindersOutput';
  jobsSent?: Maybe<Scalars['Int']>;
  potentialAppointments?: Maybe<Scalars['Int']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type CfdcvDelegationRecord = {
  __typename?: 'CFDCVDelegationRecord';
  cname?: Maybe<Scalars['String']>;
  cname_target?: Maybe<Scalars['String']>;
};

export type CfOwnershipVerification = {
  __typename?: 'CFOwnershipVerification';
  name?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type CfOwnershipVerificationHttp = {
  __typename?: 'CFOwnershipVerificationHTTP';
  http_body?: Maybe<Scalars['String']>;
  http_url?: Maybe<Scalars['String']>;
};

export type Cfssl = {
  __typename?: 'CFSSL';
  bundle_method?: Maybe<Scalars['String']>;
  certificate_authority?: Maybe<Scalars['String']>;
  dcv_delegation_records?: Maybe<Array<Maybe<CfdcvDelegationRecord>>>;
  id: Scalars['ID'];
  method: Scalars['String'];
  settings?: Maybe<CfsslSettings>;
  status: Scalars['String'];
  txt_name?: Maybe<Scalars['String']>;
  txt_value?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  validation_records?: Maybe<Array<Maybe<CfValidationRecord>>>;
  wildcard?: Maybe<Scalars['Boolean']>;
};

export type CfsslSettings = {
  __typename?: 'CFSSLSettings';
  min_tls_version?: Maybe<Scalars['String']>;
};

export type CfValidationRecord = {
  __typename?: 'CFValidationRecord';
  status?: Maybe<Scalars['String']>;
  txt_name?: Maybe<Scalars['String']>;
  txt_value?: Maybe<Scalars['String']>;
};

export type CalculateInvoiceTotalsInput = {
  coupons: Array<InvoiceCouponInput>;
  deposits: Array<InvoiceFormDepositInput>;
  fees: Array<InvoiceFormFeeInput>;
  gratuity: Scalars['Int'];
  invoiceId?: InputMaybe<Scalars['uuid']>;
  lineItems: Array<LineItemInput>;
  locationId: Scalars['uuid'];
  patientId: Scalars['uuid'];
  providerId: Scalars['uuid'];
  type?: InputMaybe<Scalars['String']>;
};

export type CalculateInvoiceTotalsOutput = {
  __typename?: 'CalculateInvoiceTotalsOutput';
  amountPaid: Scalars['Int'];
  balanceDue: Scalars['Int'];
  creditsApplied: Scalars['Int'];
  depositPaid: Scalars['Int'];
  discount: Scalars['Int'];
  discounts: Array<PotentialInvoiceDiscounts>;
  gratuity: Scalars['Int'];
  gratuityEnabledTotal: Scalars['Int'];
  postTaxTotal: Scalars['Int'];
  preTaxTotal: Scalars['Int'];
  taxTotal: Scalars['Int'];
};

export type CalendarEvents = {
  __typename?: 'CalendarEvents';
  appointmentId?: Maybe<Scalars['String']>;
  appointmentType?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  consumablesRecorded?: Maybe<Scalars['Boolean']>;
  editable?: Maybe<Scalars['Boolean']>;
  end?: Maybe<Scalars['String']>;
  hasPhotos?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  intakeStatus?: Maybe<Scalars['String']>;
  internalAppointmentProvider?: Maybe<Array<Maybe<Scalars['String']>>>;
  isFirstAppointmentWithProvider?: Maybe<Scalars['Boolean']>;
  isMembershipActive?: Maybe<Scalars['Boolean']>;
  isMembershipTypeSubscription?: Maybe<Scalars['Boolean']>;
  isNewPatient?: Maybe<Scalars['Boolean']>;
  note?: Maybe<Scalars['String']>;
  notesSigned?: Maybe<Scalars['Boolean']>;
  patientId?: Maybe<Scalars['String']>;
  patientName?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  providerName?: Maybe<Scalars['String']>;
  recurring?: Maybe<Scalars['String']>;
  recurringExceptionDates?: Maybe<Array<Maybe<Scalars['String']>>>;
  resource?: Maybe<Array<Maybe<Scalars['String']>>>;
  room?: Maybe<Scalars['String']>;
  services?: Maybe<Array<Maybe<Scalars['String']>>>;
  start?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type CalendarProviders = {
  __typename?: 'CalendarProviders';
  id: Scalars['String'];
  locations?: Maybe<Array<Maybe<Scalars['String']>>>;
  name: Scalars['String'];
};

export type CalendarResourceOutput = {
  __typename?: 'CalendarResourceOutput';
  events?: Maybe<Array<Maybe<CalendarEvents>>>;
  providers?: Maybe<Array<Maybe<CalendarProviders>>>;
};

export type CancelAppointmentOutput = {
  __typename?: 'CancelAppointmentOutput';
  appointment?: Maybe<Appointment>;
  appointmentId: Scalars['uuid'];
};

export type CancelInvoiceOutput = {
  __typename?: 'CancelInvoiceOutput';
  invoice?: Maybe<Invoice>;
  invoiceId: Scalars['uuid'];
};

export type CardHolderDetailsInput = {
  address: AddressInput;
  email: Scalars['String'];
  name: Scalars['String'];
  phoneNumber: Scalars['String'];
};

export type CardInformation = {
  last4: Scalars['String'];
  payrixCustomerId: Scalars['String'];
  token: Scalars['String'];
};

export type CheckErxEligibilityOutput = {
  __typename?: 'CheckERXEligibilityOutput';
  missingData: Array<Maybe<Scalars['String']>>;
};

export type CloudflareCustomHostnameDetails = {
  __typename?: 'CloudflareCustomHostnameDetails';
  created_at?: Maybe<Scalars['String']>;
  hostname: Scalars['String'];
  id: Scalars['ID'];
  ownership_verification?: Maybe<CfOwnershipVerification>;
  ownership_verification_http?: Maybe<CfOwnershipVerificationHttp>;
  ssl?: Maybe<Cfssl>;
  status?: Maybe<Scalars['String']>;
  verification_errors?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type CompensationByUser = {
  __typename?: 'CompensationByUser';
  categories: Array<CompensationCategoryTotal>;
  services: Array<CompensationServiceTotal>;
  summary: CompensationSummary;
  user: CompensationUser;
};

export type CompensationCategory = {
  __typename?: 'CompensationCategory';
  id: Scalars['String'];
  title: Scalars['String'];
};

export type CompensationCategoryInput = {
  data: Array<CompensationTierCategoryInsertInput>;
};

export type CompensationCategoryTotal = {
  __typename?: 'CompensationCategoryTotal';
  category?: Maybe<CompensationCategory>;
  compensationTotal: Scalars['Int'];
  postTaxTotal: Scalars['Int'];
  preTaxTotal: Scalars['Int'];
  quantity: Scalars['Int'];
  serviceCategoryId: Scalars['uuid'];
  taxTotal: Scalars['Int'];
};

export type CompensationCommissionInput = {
  data: Array<CompensationCommissionInsertInput>;
};

export type CompensationCommissionInsertInput = {
  compensationAmount: Scalars['Int'];
  compensationType: Scalars['String'];
  maxApplyValue: Scalars['Int'];
  membershipId?: InputMaybe<Scalars['uuid']>;
  minApplyValue: Scalars['Int'];
  packageId?: InputMaybe<Scalars['uuid']>;
  serviceId?: InputMaybe<Scalars['uuid']>;
};

export type CompensationExemptionInput = {
  data: Array<CompensationTierExemptionInsertInput>;
};

export type CompensationInclusionInput = {
  data: Array<CompensationPolicyTierInclusionInsertInput>;
};

export type CompensationLineItem = {
  __typename?: 'CompensationLineItem';
  compensationTotal: Scalars['Int'];
  createdAt: Scalars['String'];
  discountTotal: Scalars['Int'];
  invoiceId: Scalars['String'];
  name: Scalars['String'];
  paidOn?: Maybe<Scalars['String']>;
  postTaxTotal: Scalars['Int'];
  preTaxTotal: Scalars['Int'];
  quantity: Scalars['Int'];
  serviceCategory?: Maybe<CompensationCategory>;
  taxTotal: Scalars['Int'];
  user: CompensationUser;
};

export type CompensationPolicyTierInclusionInsertInput = {
  membershipId?: InputMaybe<Scalars['uuid']>;
  packageId?: InputMaybe<Scalars['uuid']>;
  serviceId?: InputMaybe<Scalars['uuid']>;
};

export type CompensationPolicyUpdateInput = {
  categories?: InputMaybe<CompensationCategoryInput>;
  commissions?: InputMaybe<CompensationCommissionInput>;
  compensationStrategyId: Scalars['uuid'];
  config?: InputMaybe<Scalars['jsonb']>;
  exemptions?: InputMaybe<CompensationExemptionInput>;
  inclusions?: InputMaybe<CompensationInclusionInput>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  strategyType: Scalars['String'];
  tiers?: InputMaybe<CompensationTierInput>;
};

export type CompensationServiceTotal = {
  __typename?: 'CompensationServiceTotal';
  compensationTotal: Scalars['Int'];
  name: Scalars['String'];
  postTaxTotal: Scalars['Int'];
  preTaxTotal: Scalars['Int'];
  quantity: Scalars['Int'];
  taxTotal: Scalars['Int'];
};

export type CompensationStrategyInsertInput = {
  categories?: InputMaybe<CompensationCategoryInput>;
  commissions?: InputMaybe<CompensationCommissionInput>;
  config?: InputMaybe<Scalars['jsonb']>;
  exemptions?: InputMaybe<CompensationExemptionInput>;
  inclusions?: InputMaybe<CompensationInclusionInput>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  strategyType: Scalars['String'];
  tiers?: InputMaybe<CompensationTierInput>;
};

export type CompensationSummary = {
  __typename?: 'CompensationSummary';
  compensationTotal: Scalars['Int'];
};

export type CompensationTierCategoryInsertInput = {
  serviceCategoryId: Scalars['uuid'];
};

export type CompensationTierExemptionInsertInput = {
  membershipId?: InputMaybe<Scalars['uuid']>;
  packageId?: InputMaybe<Scalars['uuid']>;
  serviceId?: InputMaybe<Scalars['uuid']>;
};

export type CompensationTierInput = {
  data: Array<CompensationTierInsertInput>;
};

export type CompensationTierInsertInput = {
  compensationAmount: Scalars['Int'];
  compensationType: Scalars['String'];
  maxApplyValue: Scalars['Int'];
  minApplyValue: Scalars['Int'];
  name: Scalars['String'];
};

export type CompensationTotalsOutput = {
  __typename?: 'CompensationTotalsOutput';
  lineItems: Array<CompensationLineItem>;
  users: Array<CompensationByUser>;
};

export type CompensationUser = {
  __typename?: 'CompensationUser';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type ComplexUpdateInvoiceInput = {
  address?: InputMaybe<Scalars['String']>;
  appointmentId?: InputMaybe<Scalars['String']>;
  cashierId?: InputMaybe<Scalars['String']>;
  consumableOverride?: InputMaybe<Scalars['String']>;
  consumables?: InputMaybe<Array<ServiceItemConsumableInput>>;
  dueDate?: InputMaybe<Scalars['date']>;
  email?: InputMaybe<Scalars['String']>;
  gratuity?: InputMaybe<Scalars['Int']>;
  invoiceCoupons?: InputMaybe<Array<InvoiceCouponInput>>;
  invoiceNumber?: InputMaybe<Scalars['String']>;
  isArchived?: InputMaybe<Scalars['Boolean']>;
  lineItems?: InputMaybe<Array<InputMaybe<LineItemInput>>>;
  locationId?: InputMaybe<Scalars['String']>;
  message?: InputMaybe<Scalars['String']>;
  note?: InputMaybe<Scalars['String']>;
  paidOn?: InputMaybe<Scalars['date']>;
  patientId?: InputMaybe<Scalars['uuid']>;
  providerId?: InputMaybe<Scalars['String']>;
  signatureId?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

export type ComplexUpdateInvoiceOutput = {
  __typename?: 'ComplexUpdateInvoiceOutput';
  invoice?: Maybe<Invoice>;
  invoiceId: Scalars['uuid'];
};

export type Conflict = {
  __typename?: 'Conflict';
  code: Scalars['String'];
  label?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['jsonb']>;
};

export type Coordinates = {
  lat?: InputMaybe<Scalars['Float']>;
  lng?: InputMaybe<Scalars['Float']>;
};

export type CreateConsumableAndAddToLocationOutput = {
  __typename?: 'CreateConsumableAndAddToLocationOutput';
  consumableId: Scalars['uuid'];
  creditId: Scalars['uuid'];
  debitId: Scalars['uuid'];
  locationId: Scalars['uuid'];
  quantity: Scalars['Float'];
};

export type CreateFinixDeviceOutput = {
  __typename?: 'CreateFinixDeviceOutput';
  finixDeviceId: Scalars['String'];
};

export type CreateFirebaseUserOutput = {
  __typename?: 'CreateFirebaseUserOutput';
  uid: Scalars['String'];
};

export type CreateInventoryTransactionInput = {
  consumableId: Scalars['uuid'];
  from: Scalars['uuid'];
  fromType: InventoryAccountType;
  note?: InputMaybe<Scalars['String']>;
  quantity: Scalars['Float'];
  to: Scalars['uuid'];
  toType: InventoryAccountType;
};

export type CreateInventoryTransactionOutput = {
  __typename?: 'CreateInventoryTransactionOutput';
  creditId: Scalars['uuid'];
  debitId: Scalars['uuid'];
  quantity: Scalars['Float'];
};

export type CustomInsertInvoiceInput = {
  address: Scalars['String'];
  appointmentId?: InputMaybe<Scalars['uuid']>;
  cashierId?: InputMaybe<Scalars['uuid']>;
  consumableOverride?: InputMaybe<Scalars['String']>;
  consumables?: InputMaybe<Array<ServiceItemConsumableInput>>;
  discount?: InputMaybe<Scalars['Int']>;
  dueDate: Scalars['date'];
  email: Scalars['String'];
  giftCardItems?: InputMaybe<Array<GiftCardItemInput>>;
  gratuity?: InputMaybe<Scalars['Int']>;
  invoiceCoupons?: InputMaybe<Array<InvoiceCouponInput>>;
  invoiceNumber: Scalars['String'];
  lineItems?: InputMaybe<Array<LineItemInput>>;
  locationId: Scalars['uuid'];
  membershipItems?: InputMaybe<Array<MembershipItemInput>>;
  message?: InputMaybe<Scalars['String']>;
  note?: InputMaybe<Scalars['String']>;
  packageItems?: InputMaybe<Array<PackageItemInput>>;
  patientId: Scalars['uuid'];
  postTaxTotal?: InputMaybe<Scalars['Int']>;
  preTaxTotal?: InputMaybe<Scalars['Int']>;
  promotionItems?: InputMaybe<Array<PromotionItemInput>>;
  providerId?: InputMaybe<Scalars['uuid']>;
  serviceItems?: InputMaybe<Array<ServiceItemInput>>;
  signatureId?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  taxTotal?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<Scalars['String']>;
};

export type CustomInsertInvoiceOutput = {
  __typename?: 'CustomInsertInvoiceOutput';
  invoice?: Maybe<Invoice>;
  invoiceId: Scalars['String'];
  patient?: Maybe<Patient>;
  patientId: Scalars['String'];
};

export type CustomUpdateInvoiceOutput = {
  __typename?: 'CustomUpdateInvoiceOutput';
  invoice?: Maybe<Invoice>;
  invoiceId: Scalars['uuid'];
};

export type CustomUpdateInvoiceSetInput = {
  address?: InputMaybe<Scalars['String']>;
  appointmentId?: InputMaybe<Scalars['uuid']>;
  cashierId?: InputMaybe<Scalars['uuid']>;
  consumableOverride?: InputMaybe<Scalars['String']>;
  discount?: InputMaybe<Scalars['Int']>;
  dueDate?: InputMaybe<Scalars['date']>;
  email?: InputMaybe<Scalars['String']>;
  giftCardItems?: InputMaybe<Array<GiftCardItemInput>>;
  gratuity?: InputMaybe<Scalars['Int']>;
  invoiceCoupons?: InputMaybe<Array<InvoiceCouponInput>>;
  invoiceNumber?: InputMaybe<Scalars['String']>;
  invoiceServiceConsumables?: InputMaybe<Array<ServiceItemConsumableInput>>;
  locationId?: InputMaybe<Scalars['uuid']>;
  membershipItems?: InputMaybe<Array<MembershipItemInput>>;
  message?: InputMaybe<Scalars['String']>;
  note?: InputMaybe<Scalars['String']>;
  packageItems?: InputMaybe<Array<PackageItemInput>>;
  patientId?: InputMaybe<Scalars['uuid']>;
  postTaxTotal?: InputMaybe<Scalars['Int']>;
  preTaxTotal?: InputMaybe<Scalars['Int']>;
  providerId?: InputMaybe<Scalars['uuid']>;
  serviceItems?: InputMaybe<Array<UpdateServiceItemInput>>;
  signatureId?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  taxTotal?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<Scalars['String']>;
};

export type DateRangeInput = {
  endDate: Scalars['String'];
  startDate: Scalars['String'];
};

export type DeviceAvailabilityOutput = {
  __typename?: 'DeviceAvailabilityOutput';
  availability?: Maybe<Array<Maybe<AvailabilityRange>>>;
  device?: Maybe<Device>;
  deviceId: Scalars['uuid'];
};

export type DisconnectNylasOutput = {
  __typename?: 'DisconnectNylasOutput';
  userWorkspaceId: Scalars['String'];
};

export type DocumentTagInput = {
  tagId: Scalars['String'];
};

export type DocumentUpdateInput = {
  tags: Array<DocumentTagInput>;
  title: Scalars['String'];
};

export type DownloadImageOutput = {
  __typename?: 'DownloadImageOutput';
  image?: Maybe<Scalars['String']>;
};

export type EditImageInput = {
  height?: InputMaybe<Scalars['Float']>;
  left?: InputMaybe<Scalars['Float']>;
  photoId: Scalars['uuid'];
  rotation?: InputMaybe<Scalars['Float']>;
  top?: InputMaybe<Scalars['Float']>;
  width?: InputMaybe<Scalars['Float']>;
};

export type EditImageOutput = {
  __typename?: 'EditImageOutput';
  photo?: Maybe<Photo>;
  photoId: Scalars['uuid'];
  success: Scalars['Boolean'];
};

export type ExistingPaymentInformation = {
  paymentMethodId: Scalars['String'];
};

export type ExternalVendorWebhookTokenOutput = {
  __typename?: 'ExternalVendorWebhookTokenOutput';
  token: Scalars['String'];
};

export type FileOnlyUrl = {
  __typename?: 'FileOnlyURL';
  url: Scalars['String'];
};

export type FilePayload = {
  __typename?: 'FilePayload';
  filePath: Scalars['String'];
  thumbnailUrl?: Maybe<Scalars['String']>;
  url: Scalars['String'];
};

export type FileVersion = {
  __typename?: 'FileVersion';
  imageUrl?: Maybe<Scalars['String']>;
  isLatest?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  lastModified?: Maybe<Scalars['String']>;
  versionId?: Maybe<Scalars['String']>;
};

export type FinixDepositInput = {
  amount: Scalars['Int'];
  finixFraudSessionId?: InputMaybe<Scalars['String']>;
  paymentMethod: FinixPaymentMethodInput;
};

export type FinixOnboardingLinkOutput = {
  __typename?: 'FinixOnboardingLinkOutput';
  onboardingLink: Scalars['String'];
};

export type FinixPaymentMethodCreateInput = {
  cardHolderDetails: CardHolderDetailsInput;
  token: Scalars['String'];
};

export type FinixPaymentMethodInput = {
  connect?: InputMaybe<Scalars['uuid']>;
  create?: InputMaybe<FinixPaymentMethodCreateInput>;
};

/** Boolean expression to compare columns of type "Float". All fields are combined with logical 'AND'. */
export type Float_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Float']>;
  _gt?: InputMaybe<Scalars['Float']>;
  _gte?: InputMaybe<Scalars['Float']>;
  _in?: InputMaybe<Array<Scalars['Float']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Float']>;
  _lte?: InputMaybe<Scalars['Float']>;
  _neq?: InputMaybe<Scalars['Float']>;
  _nin?: InputMaybe<Array<Scalars['Float']>>;
};

export type FullConsumable = {
  __typename?: 'FullConsumable';
  archived: Scalars['Boolean'];
  brand: IdName;
  cost: Scalars['Int'];
  createdAt: Scalars['String'];
  daysBeforeNotification?: Maybe<Scalars['Int']>;
  expirationDate?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  isExpirable: Scalars['Boolean'];
  metadata: Scalars['jsonb'];
  originalQuantity: Scalars['Float'];
  purchaseOrder?: Maybe<IdOrderNumber>;
  quantity: Scalars['Float'];
  shouldWarnQuantity: Scalars['Boolean'];
  type: IdNameRequiredFields;
  updatedAt: Scalars['String'];
  warningQuantity?: Maybe<Scalars['Int']>;
};

export type GetActiveInvoiceTotalsOptions = {
  applyBSADiscount?: InputMaybe<Scalars['Boolean']>;
  regenerateLineItems?: InputMaybe<Scalars['Boolean']>;
};

export type GetActiveInvoiceTotalsOutput = {
  __typename?: 'GetActiveInvoiceTotalsOutput';
  bsaDiscount: Scalars['Int'];
  totals: GetInvoiceTotalsOutput;
};

export type GetAppointmentConsumablesDataOutput = {
  __typename?: 'GetAppointmentConsumablesDataOutput';
  canUpdate: Array<AppointmentServiceConsumableCanUpdate>;
  hasCanUpdate: Scalars['Boolean'];
  hasMarkedUnused: Scalars['Boolean'];
  hasToResolve: Scalars['Boolean'];
  markedUnused: Array<AppointmentServiceConsumableTypeMarkedUnused>;
  toResolve: Array<AppointmentServiceConsumableTypeToResolve>;
};

export type GetAppointmentDepositAmountOutput = {
  __typename?: 'GetAppointmentDepositAmountOutput';
  deposit: Scalars['Int'];
};

export type GetInventoryAggregateInput = {
  consumableWhere: Scalars['jsonb'];
  dates?: InputMaybe<DateRangeInput>;
  expirationStatus?: InputMaybe<Scalars['String']>;
  locations: Array<Scalars['String']>;
  stockAlert?: InputMaybe<Scalars['Boolean']>;
};

export type GetInventoryAggregateOutput = {
  __typename?: 'GetInventoryAggregateOutput';
  brandCount: Scalars['Int'];
  count: Scalars['Int'];
  expiringCount: Scalars['Int'];
  stockAlertCount: Scalars['Int'];
  totalValue: Scalars['Int'];
  typeCount: Scalars['Int'];
};

export type GetInventoryInput = {
  consumableWhere: Scalars['jsonb'];
  dates?: InputMaybe<DateRangeInput>;
  expirationStatus?: InputMaybe<Scalars['String']>;
  limit: Scalars['Int'];
  locations: Array<Scalars['String']>;
  orderBy: Scalars['String'];
  page: Scalars['Int'];
  stockAlert?: InputMaybe<Scalars['Boolean']>;
};

export type GetInventoryOutput = {
  __typename?: 'GetInventoryOutput';
  count: Scalars['Int'];
  inventory: Array<InventoryItem>;
};

export type GetInventoryTransactionHistoryOutput = {
  __typename?: 'GetInventoryTransactionHistoryOutput';
  inventoryTransactions: Array<SimplfiedInventoryTransaction>;
};

export type GetInvoiceNumberOutput = {
  __typename?: 'GetInvoiceNumberOutput';
  invoiceNumber: Scalars['String'];
};

export type GetInvoiceTotalsOutput = {
  __typename?: 'GetInvoiceTotalsOutput';
  balanceDue: Scalars['Int'];
  creditsApplied: Scalars['Int'];
  depositPaid: Scalars['Int'];
  discount: Scalars['Int'];
  discounts: Array<PotentialInvoiceDiscounts>;
  gratuity?: Maybe<Scalars['Int']>;
  postTaxTotal: Scalars['Int'];
  preTaxTotal: Scalars['Int'];
  taxTotal: Scalars['Int'];
};

export type GetOrCreateTwilioConversationResponse = {
  __typename?: 'GetOrCreateTwilioConversationResponse';
  patientConversation?: Maybe<PatientConversation>;
  patientConversationId: Scalars['String'];
  success: Scalars['Boolean'];
};

export type GetPatientWalletTransactionSourceOutput = {
  __typename?: 'GetPatientWalletTransactionSourceOutput';
  id: Scalars['uuid'];
  name: Scalars['String'];
  type: Scalars['String'];
};

export type GetProductDetailsPageOutput = {
  __typename?: 'GetProductDetailsPageOutput';
  consumable: FullConsumable;
  count: Scalars['Int'];
  inventory: Array<InventoryItem>;
  locationHolders: Array<IdNameQuantity>;
  shrinkCount: Scalars['Int'];
  userHolders: Array<IdNameQuantity>;
};

export type GetRsProviderReviewsInput = {
  providerId: Scalars['String'];
};

export type GetRsProviderReviewsOutput = {
  __typename?: 'GetRSProviderReviewsOutput';
  reviews: Scalars['jsonb'];
};

export type GetSimplifiedInventoryTransactionsInput = {
  consumableWhere: Scalars['jsonb'];
  dates: DateRangeInput;
  limit: Scalars['Int'];
  locations: Array<Scalars['String']>;
  orderBy: Scalars['String'];
  page: Scalars['Int'];
  users: Array<Scalars['String']>;
};

export type GetSimplifiedInventoryTransactionsOutput = {
  __typename?: 'GetSimplifiedInventoryTransactionsOutput';
  count: Scalars['Int'];
  inventoryTransactions: Array<SimplfiedInventoryTransaction>;
};

export type GetValidatedCouponsForInvoiceOutput = {
  __typename?: 'GetValidatedCouponsForInvoiceOutput';
  invoiceCoupons: Array<ValidatedCoupon>;
  serviceCoupons: Array<ValidatedCoupon>;
  walletCoupons: Array<ValidatedWalletCoupon>;
};

export type GetValidatedCouponsForServiceOutput = {
  __typename?: 'GetValidatedCouponsForServiceOutput';
  coupons: Array<ValidatedCoupon>;
  walletCoupons: Array<ValidatedWalletCoupon>;
};

export type GetZdProviderReviewsInput = {
  providerId: Scalars['String'];
};

export type GetZdProviderReviewsOutput = {
  __typename?: 'GetZDProviderReviewsOutput';
  reviews: Scalars['jsonb'];
};

export type GiftCardItemInput = {
  pricePerUnit: Scalars['Int'];
  quantity: Scalars['Int'];
};

export type GoogleAccountsOutput = {
  __typename?: 'GoogleAccountsOutput';
  googleAccounts: Scalars['jsonb'];
};

export type GoogleLocationsOutput = {
  __typename?: 'GoogleLocationsOutput';
  googleLocations: Scalars['jsonb'];
};

export type GoogleOnboardingStatusOutput = {
  __typename?: 'GoogleOnboardingStatusOutput';
  authProfiles: Array<RmAuthProfile>;
  googleAccounts: Array<RmGoogleAccount>;
  googleLocations: Array<Scalars['uuid']>;
  hasAuthProfile: Scalars['Boolean'];
  hasGoogleAccount: Scalars['Boolean'];
  hasGoogleLocation: Scalars['Boolean'];
};

export type GoogleReviewsOutput = {
  __typename?: 'GoogleReviewsOutput';
  googleReviews: Scalars['jsonb'];
};

export type IdName = {
  __typename?: 'IdName';
  id: Scalars['uuid'];
  name: Scalars['String'];
};

export type IdNameQuantity = {
  __typename?: 'IdNameQuantity';
  id: Scalars['uuid'];
  name: Scalars['String'];
  quantity: Scalars['Int'];
};

export type IdNameRequiredFields = {
  __typename?: 'IdNameRequiredFields';
  defaultNumberOfUnits: Scalars['Float'];
  defaultPricePerUnit: Scalars['Int'];
  id: Scalars['uuid'];
  name: Scalars['String'];
  requiredFields: Scalars['jsonb'];
  unitName: Scalars['String'];
  unitStep: Scalars['Float'];
};

export type IdOrderNumber = {
  __typename?: 'IdOrderNumber';
  id: Scalars['uuid'];
  orderNumber: Scalars['String'];
};

export type IdResponse = {
  __typename?: 'IdResponse';
  id: Scalars['uuid'];
};

export type InitialTransactionInput = {
  consumableId: Scalars['uuid'];
  createdAt: Scalars['String'];
  toId: Scalars['uuid'];
};

export type InitializeWalletsOutput = {
  __typename?: 'InitializeWalletsOutput';
  patientsWithWallets: Scalars['Int'];
};

export type InsertPatientAddressOutput = {
  __typename?: 'InsertPatientAddressOutput';
  patientAddress?: Maybe<PatientAddress>;
  patientAddressId: Scalars['uuid'];
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Int']>;
  _gt?: InputMaybe<Scalars['Int']>;
  _gte?: InputMaybe<Scalars['Int']>;
  _in?: InputMaybe<Array<Scalars['Int']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Int']>;
  _lte?: InputMaybe<Scalars['Int']>;
  _neq?: InputMaybe<Scalars['Int']>;
  _nin?: InputMaybe<Array<Scalars['Int']>>;
};

export enum InventoryAccountType {
  Appointment = 'APPOINTMENT',
  Consumable = 'CONSUMABLE',
  Invoice = 'INVOICE',
  Location = 'LOCATION',
  Shrink = 'SHRINK',
  User = 'USER'
}

export type InventoryItem = {
  __typename?: 'InventoryItem';
  account: Scalars['jsonb'];
  consumable: FullConsumable;
  createdAt: Scalars['String'];
  metadata: InventoryItemMetadata;
  quantity: Scalars['Float'];
  updatedAt: Scalars['String'];
};

export type InventoryItemMetadata = {
  __typename?: 'InventoryItemMetadata';
  expiration: InventoryMetadataExpiration;
  stockAlert: Scalars['Boolean'];
};

export type InventoryMetadataExpiration = {
  __typename?: 'InventoryMetadataExpiration';
  daysUntilExpiration: Scalars['Int'];
  status: Scalars['String'];
};

export type InverseAvailabilityOutput = {
  __typename?: 'InverseAvailabilityOutput';
  inverseAvailability?: Maybe<Array<Maybe<InverseAvailabilityRange>>>;
};

export type InverseAvailabilityRange = {
  __typename?: 'InverseAvailabilityRange';
  end: Scalars['String'];
  resource: Scalars['String'];
  start: Scalars['String'];
};

export type InvoiceCouponInput = {
  amount: Scalars['Float'];
  couponId: Scalars['uuid'];
  expirationOverride?: InputMaybe<Scalars['Boolean']>;
  isMembershipCoupon: Scalars['Boolean'];
  packageId?: InputMaybe<Scalars['uuid']>;
};

export type InvoiceFormDepositInput = {
  amount: Scalars['Int'];
  note: Scalars['String'];
};

export type InvoiceFormFeeInput = {
  amount: Scalars['Int'];
  feeId: Scalars['String'];
};

export type InvoiceReceiptPdfOutput = {
  __typename?: 'InvoiceReceiptPDFOutput';
  success: Scalars['String'];
};

export type InvoiceReceiptPayment = {
  __typename?: 'InvoiceReceiptPayment';
  amount: Scalars['Int'];
  id: Scalars['uuid'];
  paymentMethodType: Scalars['String'];
};

export type LabOrderUrlOutput = {
  __typename?: 'LabOrderUrlOutput';
  iframeUrl: Scalars['String'];
};

export type LeadFormFieldValueInput = {
  apiFieldKey: Scalars['String'];
  leadFormFieldId: Scalars['uuid'];
  value: Scalars['String'];
};

export type LeadFormSourcesResult = {
  __typename?: 'LeadFormSourcesResult';
  sources?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type LeadFormSubmitOutput = {
  __typename?: 'LeadFormSubmitOutput';
  patientId: Scalars['uuid'];
};

export type LineItemInput = {
  allowGratuity: Scalars['Boolean'];
  discountAmount: Scalars['Float'];
  discountType: Scalars['String'];
  membershipId?: InputMaybe<Scalars['uuid']>;
  name: Scalars['String'];
  packageId?: InputMaybe<Scalars['uuid']>;
  pricePerUnit: Scalars['Int'];
  promotionId?: InputMaybe<Scalars['uuid']>;
  providerId?: InputMaybe<Scalars['String']>;
  quantity: Scalars['Int'];
  serviceId?: InputMaybe<Scalars['uuid']>;
  taxRate: Scalars['Float'];
  type: Scalars['String'];
  useCount: Scalars['Int'];
};

export type LumaCaptureOutput = {
  __typename?: 'LumaCaptureOutput';
  slug: Scalars['String'];
};

export type LumaCheckCaptureOutput = {
  __typename?: 'LumaCheckCaptureOutput';
  link: Scalars['String'];
};

export type ManuallyUpdatePatientWalletBalanceInput = {
  newBalance: Scalars['Int'];
  note: Scalars['String'];
  patientWalletId: Scalars['uuid'];
};

export type MapPatientWorkspaceToErxResponse = {
  __typename?: 'MapPatientWorkspaceToERXResponse';
  dosespotPatientId?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type MeOutput = {
  __typename?: 'MeOutput';
  patient?: Maybe<Patient>;
  patientId?: Maybe<Scalars['uuid']>;
  /** An array relationship */
  patientWorkspaces: Array<PatientWorkspace>;
  /** An aggregate relationship */
  patientWorkspaces_aggregate: PatientWorkspace_Aggregate;
  user?: Maybe<User>;
  userId?: Maybe<Scalars['uuid']>;
  /** An array relationship */
  userWorkspaces: Array<UserWorkspace>;
  /** An aggregate relationship */
  userWorkspaces_aggregate: UserWorkspace_Aggregate;
};


export type MeOutputPatientWorkspacesArgs = {
  distinct_on?: InputMaybe<Array<PatientWorkspace_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PatientWorkspace_Order_By>>;
  where?: InputMaybe<PatientWorkspace_Bool_Exp>;
};


export type MeOutputPatientWorkspaces_AggregateArgs = {
  distinct_on?: InputMaybe<Array<PatientWorkspace_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PatientWorkspace_Order_By>>;
  where?: InputMaybe<PatientWorkspace_Bool_Exp>;
};


export type MeOutputUserWorkspacesArgs = {
  distinct_on?: InputMaybe<Array<UserWorkspace_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserWorkspace_Order_By>>;
  where?: InputMaybe<UserWorkspace_Bool_Exp>;
};


export type MeOutputUserWorkspaces_AggregateArgs = {
  distinct_on?: InputMaybe<Array<UserWorkspace_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserWorkspace_Order_By>>;
  where?: InputMaybe<UserWorkspace_Bool_Exp>;
};

export type MediaVersionsOutput = {
  __typename?: 'MediaVersionsOutput';
  versions?: Maybe<Array<Maybe<FileVersion>>>;
};

export type MembershipItemInput = {
  membershipId: Scalars['uuid'];
  price: Scalars['Int'];
  taxRate: Scalars['Float'];
};

export type MetabaseEmbedUrlOutput = {
  __typename?: 'MetabaseEmbedUrlOutput';
  iframeUrl: Scalars['String'];
};

export type MinimalResolvedConsent = {
  __typename?: 'MinimalResolvedConsent';
  completed: Scalars['Boolean'];
  id: Scalars['uuid'];
  patientConsentId: Scalars['String'];
  title: Scalars['String'];
};

export type MinimalResolvedConsumable = {
  __typename?: 'MinimalResolvedConsumable';
  brand: MinimalResolvedConsumableBrand;
  id: Scalars['uuid'];
  metadata: Scalars['jsonb'];
  type: MinimalResolvedConsumableType;
};

export type MinimalResolvedConsumableBrand = {
  __typename?: 'MinimalResolvedConsumableBrand';
  id: Scalars['uuid'];
  name: Scalars['String'];
};

export type MinimalResolvedConsumableType = {
  __typename?: 'MinimalResolvedConsumableType';
  id: Scalars['uuid'];
  name: Scalars['String'];
};

export type MinimalResolvedCustomForm = {
  __typename?: 'MinimalResolvedCustomForm';
  completed: Scalars['Boolean'];
  documentId: Scalars['String'];
  id: Scalars['uuid'];
  title: Scalars['String'];
};

export type MinimalResolvedService = {
  __typename?: 'MinimalResolvedService';
  id: Scalars['uuid'];
  name: Scalars['String'];
};

export type MultipleServicesAvailabilityOutput = {
  __typename?: 'MultipleServicesAvailabilityOutput';
  dateTime: Scalars['String'];
  formattedDay: Scalars['String'];
  providers: Array<Maybe<AvailableProviderOutput>>;
  start: Scalars['String'];
};

export type NewPaymentInformation = {
  customer: PayrixCustomer;
  payment: PayrixPaymentMethod;
  token: Scalars['String'];
};

export type NylasAuthUrl = {
  __typename?: 'NylasAuthUrl';
  url: Scalars['String'];
};

export type NylasExchangeOutput = {
  __typename?: 'NylasExchangeOutput';
  message: Scalars['String'];
};

export type NylasWebhookOutput = {
  __typename?: 'NylasWebhookOutput';
  message: Scalars['String'];
};

export type OnboardPatientInput = {
  email?: InputMaybe<Scalars['String']>;
  firebaseUid: Scalars['String'];
  patientInviteId: Scalars['uuid'];
  phoneNumber?: InputMaybe<Scalars['String']>;
};

export type PackageItemInput = {
  packageId: Scalars['uuid'];
  price: Scalars['Int'];
  quantityToBuy: Scalars['Int'];
  quantityToUse: Scalars['Int'];
  taxRate: Scalars['Float'];
};

export type PatientAttributes = {
  __typename?: 'PatientAttributes';
  attributes?: Maybe<Scalars['jsonb']>;
  clinicId?: Maybe<Scalars['Int']>;
  dob?: Maybe<Scalars['String']>;
  dosespotPatientId?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['String']>;
  emailConfirmed?: Maybe<Scalars['Boolean']>;
  emailMarketingEnabled?: Maybe<Scalars['Boolean']>;
  firstName?: Maybe<Scalars['String']>;
  fullMediaConsent?: Maybe<Scalars['Boolean']>;
  gender?: Maybe<Scalars['String']>;
  hasAcceptedPatientInvite?: Maybe<Scalars['Boolean']>;
  height?: Maybe<Scalars['Int']>;
  heightUnit?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  partialMediaConsent?: Maybe<Scalars['Boolean']>;
  payrixCustomerId?: Maybe<Scalars['String']>;
  phoneConfirmed?: Maybe<Scalars['Boolean']>;
  phoneMarketingEnabled?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  profileNote?: Maybe<Scalars['String']>;
  profilePicture?: Maybe<FilePayload>;
  sex?: Maybe<Scalars['String']>;
  smsMarketingEnabled?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
};

export type PatientProductHistoryOutput = {
  __typename?: 'PatientProductHistoryOutput';
  productHistory?: Maybe<Array<Maybe<ProductHistory>>>;
};

export type PatientServiceHistory = {
  __typename?: 'PatientServiceHistory';
  completedAppointments?: Maybe<Scalars['Int']>;
  firstAppointment?: Maybe<Scalars['String']>;
  lastAppointment?: Maybe<Scalars['String']>;
  noShowAppointments?: Maybe<Scalars['Int']>;
  serviceCounts?: Maybe<Array<Maybe<ServiceCount>>>;
};

export type PatientWalletCouponAndQuantity = {
  __typename?: 'PatientWalletCouponAndQuantity';
  id: Scalars['uuid'];
  name: Scalars['String'];
  quantity: Scalars['Int'];
  rule: Scalars['String'];
};

export type PatientWorkspaceSourcesResult = {
  __typename?: 'PatientWorkspaceSourcesResult';
  sources?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type PayAppointmentDepositInput = {
  amount: Scalars['Int'];
  appointmentId: Scalars['uuid'];
  paymentMethod: PaymentMethodCreateOrConnect;
  payrixCustomerId?: InputMaybe<Scalars['String']>;
  payrixTransactionId?: InputMaybe<Scalars['String']>;
};

export type PayAppointmentDepositOutput = {
  __typename?: 'PayAppointmentDepositOutput';
  invoice?: Maybe<Invoice>;
  invoiceId: Scalars['uuid'];
};

export type PayInvoiceWithTokenOutput = {
  __typename?: 'PayInvoiceWithTokenOutput';
  status: Scalars['String'];
};

export type PaymentInformationInput = {
  existing?: InputMaybe<ExistingPaymentInformation>;
  new?: InputMaybe<NewPaymentInformation>;
};

export type PaymentInput = {
  amount: Scalars['Int'];
  paymentInfo: PaymentInformationInput;
};

export type PaymentMethodCreateOrConnect = {
  connect?: InputMaybe<Scalars['uuid']>;
  create?: InputMaybe<PaymentMethodInput>;
};

export type PaymentMethodInput = {
  last4: Scalars['String'];
  patientId?: InputMaybe<Scalars['String']>;
  payrixCustomerId?: InputMaybe<Scalars['String']>;
  token: Scalars['String'];
};

export enum PaymentType {
  Deposit = 'DEPOSIT',
  Hold = 'HOLD',
  Payment = 'PAYMENT',
  Refund = 'REFUND',
  RemovedHold = 'REMOVED_HOLD'
}

export type PayrixCustomer = {
  address1: Scalars['String'];
  address2?: InputMaybe<Scalars['String']>;
  city: Scalars['String'];
  email: Scalars['String'];
  first: Scalars['String'];
  id: Scalars['String'];
  last: Scalars['String'];
  merchant?: InputMaybe<Scalars['String']>;
  phone: Scalars['String'];
  state: Scalars['String'];
  zip: Scalars['String'];
};

export type PayrixPaymentMethod = {
  number: Scalars['String'];
};

export enum PayrixPaymentType {
  Authorization = 'AUTHORIZATION',
  Sale = 'SALE',
  SaleAfterAuthorization = 'SALE_AFTER_AUTHORIZATION'
}

export type PayrixTransaction = {
  __typename?: 'PayrixTransaction';
  id: Scalars['String'];
  invoice?: Maybe<Invoice>;
  invoiceId?: Maybe<Scalars['uuid']>;
  status: Scalars['Int'];
  type: Scalars['Int'];
};

export type PhoneNumberLookupOutput = {
  __typename?: 'PhoneNumberLookupOutput';
  phoneNumber?: Maybe<Scalars['jsonb']>;
};

export type PotentialInvoiceDiscounts = {
  __typename?: 'PotentialInvoiceDiscounts';
  couponAmount: Scalars['Int'];
  couponId: Scalars['uuid'];
  couponName: Scalars['String'];
  couponScope: Scalars['String'];
  couponType: Scalars['String'];
  discountTotal: Scalars['Int'];
  discounts: Array<PotentialInvoiceItemDiscount>;
};

export type PotentialInvoiceItemDiscount = {
  __typename?: 'PotentialInvoiceItemDiscount';
  discountTotal: Scalars['Int'];
  itemName: Scalars['String'];
  itemQuantity: Scalars['Int'];
};

export type PreviewAudienceOutput = {
  __typename?: 'PreviewAudienceOutput';
  audienceSample?: Maybe<Array<AudienceMember>>;
  count: Scalars['Int'];
};

export type ProductHistory = {
  __typename?: 'ProductHistory';
  lastPurchased?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  totalQuantity?: Maybe<Scalars['Int']>;
};

export type PromotionItemInput = {
  name: Scalars['String'];
  price: Scalars['Int'];
  promotionId: Scalars['String'];
  quantity: Scalars['Int'];
};

export type ProviderOnReviewPlatform = {
  __typename?: 'ProviderOnReviewPlatform';
  id?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  platform?: Maybe<Scalars['String']>;
  reviewCount?: Maybe<Scalars['Int']>;
  tags?: Maybe<Scalars['String']>;
};

export type PurchaseFromStorefrontInput = {
  addressLine1: Scalars['String'];
  ccLast4?: InputMaybe<Scalars['String']>;
  city: Scalars['String'];
  email: Scalars['String'];
  finixFraudSessionId?: InputMaybe<Scalars['String']>;
  finixToken?: InputMaybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  memberships?: InputMaybe<Array<InputMaybe<MembershipItemInput>>>;
  packages?: InputMaybe<Array<InputMaybe<PackageItemInput>>>;
  payrixCustomerId?: InputMaybe<Scalars['String']>;
  payrixPaymentToken?: InputMaybe<Scalars['String']>;
  payrixTransactionId?: InputMaybe<Scalars['String']>;
  phoneNumber: Scalars['String'];
  promotions?: InputMaybe<Array<InputMaybe<PromotionItemInput>>>;
  services?: InputMaybe<Array<InputMaybe<ServiceItemInput>>>;
  state: Scalars['String'];
  walletAmountToUse?: InputMaybe<Scalars['Int']>;
  workspaceId: Scalars['uuid'];
  zip: Scalars['String'];
};

export type PurchaseFromStorefrontOutput = {
  __typename?: 'PurchaseFromStorefrontOutput';
  invoiceId?: Maybe<Scalars['String']>;
  patientId?: Maybe<Scalars['String']>;
  status: Scalars['String'];
};

export type RmAuthProfile = {
  __typename?: 'RMAuthProfile';
  id: Scalars['uuid'];
  metadata: Scalars['jsonb'];
};

export type RmGoogleAccount = {
  __typename?: 'RMGoogleAccount';
  accountName: Scalars['String'];
  id: Scalars['uuid'];
  name: Scalars['String'];
  type: Scalars['String'];
};

export type RefundPaymentOutput = {
  __typename?: 'RefundPaymentOutput';
  invoice?: Maybe<Invoice>;
  invoiceId: Scalars['uuid'];
  payment?: Maybe<Payment>;
  paymentId: Scalars['uuid'];
};

export type ReplyToGoogleReviewOutput = {
  __typename?: 'ReplyToGoogleReviewOutput';
  externalReview?: Maybe<ExternalReview>;
  externalReviewId: Scalars['uuid'];
};

export type ResolveAppointmentServiceConsumableInput = {
  appointmentId: Scalars['uuid'];
  appointmentServiceId: Scalars['uuid'];
  consumableId: Scalars['uuid'];
  locationId: Scalars['uuid'];
  quantity: Scalars['Float'];
};

export type ResolveAppointmentServiceConsumableOutput = {
  __typename?: 'ResolveAppointmentServiceConsumableOutput';
  appointmentId: Scalars['uuid'];
  appointmentServiceId: Scalars['uuid'];
  consumableId: Scalars['uuid'];
  creditId: Scalars['uuid'];
  debitId: Scalars['uuid'];
  locationId: Scalars['uuid'];
  quantity: Scalars['Float'];
};

export type ResolveInvoiceConsumablesInput = {
  locationName: Scalars['String'];
  services: Array<ServiceAndQuantityInput>;
};

export type ResolveInvoiceConsumablesOutput = {
  __typename?: 'ResolveInvoiceConsumablesOutput';
  consumables: Array<ServiceQuantityConsumable>;
};

export type ResolvedAppointmentConsentsOutput = {
  __typename?: 'ResolvedAppointmentConsentsOutput';
  consents: Array<MinimalResolvedConsent>;
  hasOutstanding: Scalars['Boolean'];
  outstanding: Array<MinimalResolvedConsent>;
};

export type ResolvedAppointmentCustomFormsOutput = {
  __typename?: 'ResolvedAppointmentCustomFormsOutput';
  customForms: Array<MinimalResolvedCustomForm>;
  hasOutstanding: Scalars['Boolean'];
  outstanding: Array<MinimalResolvedCustomForm>;
};

export type RevertToMediaVersionOutput = {
  __typename?: 'RevertToMediaVersionOutput';
  success: Scalars['Boolean'];
};

export type SandboxMagicLinkOutput = {
  __typename?: 'SandboxMagicLinkOutput';
  link: Scalars['String'];
  token: Scalars['String'];
};

export type SandboxOutput = {
  __typename?: 'SandboxOutput';
  result: Scalars['jsonb'];
};

export type ScheduleOutput = {
  __typename?: 'ScheduleOutput';
  availability?: Maybe<Array<Maybe<AvailabilityRange>>>;
};

export type SearchGoogleOutput = {
  __typename?: 'SearchGoogleOutput';
  googleLocations: Scalars['jsonb'];
};

export type SearchRsProvidersInput = {
  keyword: Scalars['String'];
};

export type SearchRsProvidersOutput = {
  __typename?: 'SearchRSProvidersOutput';
  providers: Scalars['jsonb'];
};

export type SeedDemoReviewsOutput = {
  __typename?: 'SeedDemoReviewsOutput';
  success: Scalars['Boolean'];
};

export type SeedDemoWorkspaceOutput = {
  __typename?: 'SeedDemoWorkspaceOutput';
  success: Scalars['Boolean'];
};

export type SendInviteLinkResponse = {
  __typename?: 'SendInviteLinkResponse';
  inviteLink?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type SendPaymentInvoiceLinkOutput = {
  __typename?: 'SendPaymentInvoiceLinkOutput';
  paymentLink: Scalars['String'];
  success: Scalars['Boolean'];
};

export type SendPaymentToFinixDeviceOutput = {
  __typename?: 'SendPaymentToFinixDeviceOutput';
  failureCode?: Maybe<Scalars['String']>;
  failureMessage?: Maybe<Scalars['String']>;
  messages?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type ServiceAndQuantityInput = {
  quantity: Scalars['Int'];
  serviceId: Scalars['uuid'];
};

export type ServiceAvailabilityOutput = {
  __typename?: 'ServiceAvailabilityOutput';
  dateTime: Scalars['String'];
  formattedDay: Scalars['String'];
  provider?: Maybe<Provider>;
  providerId: Scalars['uuid'];
  start: Scalars['String'];
};

export type ServiceCount = {
  __typename?: 'ServiceCount';
  count: Scalars['Int'];
  name: Scalars['String'];
};

export type ServiceItemConsumableInput = {
  consumableId: Scalars['uuid'];
  quantity: Scalars['Int'];
  serviceId: Scalars['uuid'];
};

export type ServiceItemInput = {
  customPrice?: InputMaybe<Scalars['Int']>;
  price: Scalars['Int'];
  pricePerUnit: Scalars['Int'];
  providerId?: InputMaybe<Scalars['uuid']>;
  quantity: Scalars['Int'];
  serviceId: Scalars['String'];
  taxRate: Scalars['Float'];
};

export type ServiceQuantityConsumable = {
  __typename?: 'ServiceQuantityConsumable';
  isAmbiguous: Scalars['Boolean'];
  quantity: Scalars['Int'];
  requiredConsumableType: IdName;
  service: IdName;
  suggestedConsumable?: Maybe<FullConsumable>;
};

export type SetBillingPatientAddressOutput = {
  __typename?: 'SetBillingPatientAddressOutput';
  patientAddress?: Maybe<PatientAddress>;
  patientAddressId: Scalars['uuid'];
};

export type SetDefaultPatientAddressOutput = {
  __typename?: 'SetDefaultPatientAddressOutput';
  patientAddress?: Maybe<PatientAddress>;
  patientAddressId: Scalars['uuid'];
};

export type SignedUrlOutput = {
  __typename?: 'SignedURLOutput';
  signedUrl: Scalars['String'];
};

export type SimpleUpdateInvoiceInput = {
  address?: InputMaybe<Scalars['String']>;
  appointmentId?: InputMaybe<Scalars['String']>;
  cashierId?: InputMaybe<Scalars['String']>;
  dueDate?: InputMaybe<Scalars['date']>;
  invoiceNumber?: InputMaybe<Scalars['String']>;
  isArchived?: InputMaybe<Scalars['Boolean']>;
  locationId?: InputMaybe<Scalars['String']>;
  message?: InputMaybe<Scalars['String']>;
  note?: InputMaybe<Scalars['String']>;
  paidOn?: InputMaybe<Scalars['date']>;
  patientId?: InputMaybe<Scalars['uuid']>;
  providerId?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Scalars['String']>;
};

export type SimpleUpdateInvoiceOutput = {
  __typename?: 'SimpleUpdateInvoiceOutput';
  invoice?: Maybe<Invoice>;
  invoiceId: Scalars['uuid'];
};

export type SimplfiedInventoryTransaction = {
  __typename?: 'SimplfiedInventoryTransaction';
  consumable: FullConsumable;
  createdAt: Scalars['String'];
  from: Scalars['jsonb'];
  note?: Maybe<Scalars['String']>;
  quantity: Scalars['Float'];
  to: Scalars['jsonb'];
  user: Scalars['jsonb'];
};

export enum StorageType {
  Document = 'DOCUMENT',
  Private = 'PRIVATE',
  Public = 'PUBLIC'
}

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['String']>;
  _gt?: InputMaybe<Scalars['String']>;
  _gte?: InputMaybe<Scalars['String']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']>;
  _in?: InputMaybe<Array<Scalars['String']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']>;
  _lt?: InputMaybe<Scalars['String']>;
  _lte?: InputMaybe<Scalars['String']>;
  _neq?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']>;
  _nin?: InputMaybe<Array<Scalars['String']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']>;
};

export type SubmitSurveyJsInput = {
  data: Scalars['json'];
  leadFormId?: InputMaybe<Scalars['uuid']>;
  utmParameters?: InputMaybe<Scalars['json']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

export type SubmitSurveyJsOutput = {
  __typename?: 'SubmitSurveyJSOutput';
  leadFormEntryId?: Maybe<Scalars['uuid']>;
  patientId?: Maybe<Scalars['uuid']>;
  patientStatus?: Maybe<Scalars['String']>;
  previousServiceIds?: Maybe<Array<Maybe<Scalars['uuid']>>>;
  success: Scalars['Boolean'];
};

export type SuccessAnswer = {
  __typename?: 'SuccessAnswer';
  success: Scalars['Boolean'];
};

export type SuccessAnswerWithCount = {
  __typename?: 'SuccessAnswerWithCount';
  count: Scalars['Int'];
  success: Scalars['Boolean'];
};

export type SvixPortalAccessOutput = {
  __typename?: 'SvixPortalAccessOutput';
  token: Scalars['String'];
  url: Scalars['String'];
};

export type SyncExternalReviewsOutput = {
  __typename?: 'SyncExternalReviewsOutput';
  success: Scalars['Boolean'];
};

export type TelehealthMeetingOutput = {
  __typename?: 'TelehealthMeetingOutput';
  hostRoomUrl: Scalars['String'];
  roomUrl: Scalars['String'];
};

export type TerminalTransactionReceiptResponse = {
  __typename?: 'TerminalTransactionReceiptResponse';
  payrixTerminalTransactionId: Scalars['String'];
  terminalTransactionId: Scalars['String'];
};

export type TradeJwtTokenOutput = {
  __typename?: 'TradeJWTTokenOutput';
  token: Scalars['String'];
  workspaceId?: Maybe<Scalars['String']>;
};

export type TranscribeAudioOutput = {
  __typename?: 'TranscribeAudioOutput';
  data?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type TwilioChatTokenResponse = {
  __typename?: 'TwilioChatTokenResponse';
  token: Scalars['String'];
};

export type UpdateAppointmentServiceConsumableInput = {
  appointmentId: Scalars['uuid'];
  appointmentServiceId: Scalars['uuid'];
  locationId: Scalars['uuid'];
  newConsumableId: Scalars['uuid'];
  newQuantity: Scalars['Float'];
  oldConsumableId: Scalars['uuid'];
  oldQuantity: Scalars['Float'];
};

export type UpdateAppointmentServiceConsumableOutput = {
  __typename?: 'UpdateAppointmentServiceConsumableOutput';
  appointmentId: Scalars['uuid'];
  appointmentServiceId: Scalars['uuid'];
  consumableId: Scalars['uuid'];
  creditId: Scalars['uuid'];
  debitId: Scalars['uuid'];
  locationId: Scalars['uuid'];
  quantity: Scalars['Float'];
};

export type UpdateAppointmentServicesOutput = {
  __typename?: 'UpdateAppointmentServicesOutput';
  appointmentServiceId?: Maybe<Scalars['uuid']>;
  appointmentServices?: Maybe<AppointmentService>;
};

export type UpdateAppointmentWithNotifyOutput = {
  __typename?: 'UpdateAppointmentWithNotifyOutput';
  appointmentId?: Maybe<Scalars['uuid']>;
  timerange?: Maybe<Scalars['String']>;
};

export type UpdateCompensationPolicyOutput = {
  __typename?: 'UpdateCompensationPolicyOutput';
  compensationPolicy?: Maybe<CompensationPolicy>;
  compensationPolicyId: Scalars['uuid'];
};

export type UpdateCompensationStrategyOutput = {
  __typename?: 'UpdateCompensationStrategyOutput';
  compensationStrategy?: Maybe<CompensationStrategy>;
  compensationStrategyId: Scalars['uuid'];
};

export type UpdateConsumableQuantityAtLocationInput = {
  consumableId: Scalars['uuid'];
  locationId: Scalars['uuid'];
  newQuantity: Scalars['Float'];
  oldQuantity: Scalars['Float'];
  reason: Scalars['String'];
};

export type UpdateCouponQuantityInput = {
  couponId: Scalars['uuid'];
  quantity: Scalars['Int'];
};

export type UpdateDocumentOutput = {
  __typename?: 'UpdateDocumentOutput';
  document?: Maybe<Document>;
  id: Scalars['uuid'];
};

export type UpdateFlowsheetOutput = {
  __typename?: 'UpdateFlowsheetOutput';
  flowsheet?: Maybe<Flowsheet>;
  flowsheetId: Scalars['uuid'];
};

export type UpdateImageInput = {
  imageData: Scalars['String'];
  photoId: Scalars['uuid'];
};

export type UpdateImageOutput = {
  __typename?: 'UpdateImageOutput';
  success: Scalars['Boolean'];
};

export type UpdatePatientWalletCouponsInput = {
  coupons: Array<UpdateCouponQuantityInput>;
  patientWalletId: Scalars['uuid'];
};

export type UpdatePatientWalletCouponsOutput = {
  __typename?: 'UpdatePatientWalletCouponsOutput';
  coupons: Array<PatientWalletCouponAndQuantity>;
  patientWalletId: Scalars['uuid'];
};

export type UpdateServiceItemInput = {
  customPrice?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  price: Scalars['Int'];
  pricePerUnit: Scalars['Int'];
  providerId?: InputMaybe<Scalars['uuid']>;
  quantity: Scalars['Int'];
  serviceId: Scalars['String'];
  taxRate: Scalars['Float'];
};

export type ValidateCouponOutput = {
  __typename?: 'ValidateCouponOutput';
  couponId: Scalars['uuid'];
  invalidReason?: Maybe<Scalars['String']>;
  validity: Validity;
};

export type ValidatedCoupon = {
  __typename?: 'ValidatedCoupon';
  amount: Scalars['Int'];
  couponId: Scalars['uuid'];
  invalidReason?: Maybe<Scalars['String']>;
  isMembershipCoupon: Scalars['Boolean'];
  name: Scalars['String'];
  type: Scalars['String'];
  validity: Scalars['String'];
};

export type ValidatedWalletCoupon = {
  __typename?: 'ValidatedWalletCoupon';
  amount: Scalars['Int'];
  couponId: Scalars['uuid'];
  invalidReason?: Maybe<Scalars['String']>;
  isMembershipCoupon: Scalars['Boolean'];
  name: Scalars['String'];
  packageId?: Maybe<Scalars['uuid']>;
  quantity: Scalars['Int'];
  type: Scalars['String'];
  validity: Scalars['String'];
};

export enum Validity {
  Expired = 'EXPIRED',
  Invalid = 'INVALID',
  Valid = 'VALID'
}

/** columns and relationships of "activityLog" */
export type ActivityLog = {
  __typename?: 'activityLog';
  /** An object relationship */
  actor?: Maybe<User>;
  actorId?: Maybe<Scalars['uuid']>;
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  isPatientActor: Scalars['Boolean'];
  metadata: Scalars['jsonb'];
  objectType: Scalars['String'];
  operationType: Scalars['String'];
  /** An object relationship */
  patient?: Maybe<Patient>;
  patientId?: Maybe<Scalars['uuid']>;
  /** A computed field, executes function "get_id_from_metadata" */
  relevantObjectId?: Maybe<Scalars['String']>;
  textSearchColumn?: Maybe<Scalars['String']>;
  updatedAt: Scalars['timestamptz'];
  /** An object relationship */
  workspace?: Maybe<Workspace>;
  workspaceId?: Maybe<Scalars['uuid']>;
};


/** columns and relationships of "activityLog" */
export type ActivityLogMetadataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "activityLog" */
export type ActivityLog_Aggregate = {
  __typename?: 'activityLog_aggregate';
  aggregate?: Maybe<ActivityLog_Aggregate_Fields>;
  nodes: Array<ActivityLog>;
};

export type ActivityLog_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<ActivityLog_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<ActivityLog_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<ActivityLog_Aggregate_Bool_Exp_Count>;
};

export type ActivityLog_Aggregate_Bool_Exp_Bool_And = {
  arguments: ActivityLog_Select_Column_ActivityLog_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ActivityLog_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type ActivityLog_Aggregate_Bool_Exp_Bool_Or = {
  arguments: ActivityLog_Select_Column_ActivityLog_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ActivityLog_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type ActivityLog_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<ActivityLog_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ActivityLog_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "activityLog" */
export type ActivityLog_Aggregate_Fields = {
  __typename?: 'activityLog_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ActivityLog_Max_Fields>;
  min?: Maybe<ActivityLog_Min_Fields>;
};


/** aggregate fields of "activityLog" */
export type ActivityLog_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<ActivityLog_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "activityLog" */
export type ActivityLog_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<ActivityLog_Max_Order_By>;
  min?: InputMaybe<ActivityLog_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type ActivityLog_Append_Input = {
  metadata?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "activityLog" */
export type ActivityLog_Arr_Rel_Insert_Input = {
  data: Array<ActivityLog_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<ActivityLog_On_Conflict>;
};

/** Boolean expression to filter rows from the table "activityLog". All fields are combined with a logical 'AND'. */
export type ActivityLog_Bool_Exp = {
  _and?: InputMaybe<Array<ActivityLog_Bool_Exp>>;
  _not?: InputMaybe<ActivityLog_Bool_Exp>;
  _or?: InputMaybe<Array<ActivityLog_Bool_Exp>>;
  actor?: InputMaybe<User_Bool_Exp>;
  actorId?: InputMaybe<Uuid_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  isPatientActor?: InputMaybe<Boolean_Comparison_Exp>;
  metadata?: InputMaybe<Jsonb_Comparison_Exp>;
  objectType?: InputMaybe<String_Comparison_Exp>;
  operationType?: InputMaybe<String_Comparison_Exp>;
  patient?: InputMaybe<Patient_Bool_Exp>;
  patientId?: InputMaybe<Uuid_Comparison_Exp>;
  relevantObjectId?: InputMaybe<String_Comparison_Exp>;
  textSearchColumn?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  workspace?: InputMaybe<Workspace_Bool_Exp>;
  workspaceId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "activityLog" */
export enum ActivityLog_Constraint {
  /** unique or primary key constraint on columns "id" */
  ActivityLogPkey = 'activityLog_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type ActivityLog_Delete_At_Path_Input = {
  metadata?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type ActivityLog_Delete_Elem_Input = {
  metadata?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type ActivityLog_Delete_Key_Input = {
  metadata?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "activityLog" */
export type ActivityLog_Insert_Input = {
  actor?: InputMaybe<User_Obj_Rel_Insert_Input>;
  actorId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  isPatientActor?: InputMaybe<Scalars['Boolean']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  objectType?: InputMaybe<Scalars['String']>;
  operationType?: InputMaybe<Scalars['String']>;
  patient?: InputMaybe<Patient_Obj_Rel_Insert_Input>;
  patientId?: InputMaybe<Scalars['uuid']>;
  textSearchColumn?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspace?: InputMaybe<Workspace_Obj_Rel_Insert_Input>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type ActivityLog_Max_Fields = {
  __typename?: 'activityLog_max_fields';
  actorId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  objectType?: Maybe<Scalars['String']>;
  operationType?: Maybe<Scalars['String']>;
  patientId?: Maybe<Scalars['uuid']>;
  /** A computed field, executes function "get_id_from_metadata" */
  relevantObjectId?: Maybe<Scalars['String']>;
  textSearchColumn?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "activityLog" */
export type ActivityLog_Max_Order_By = {
  actorId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  objectType?: InputMaybe<Order_By>;
  operationType?: InputMaybe<Order_By>;
  patientId?: InputMaybe<Order_By>;
  textSearchColumn?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type ActivityLog_Min_Fields = {
  __typename?: 'activityLog_min_fields';
  actorId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  objectType?: Maybe<Scalars['String']>;
  operationType?: Maybe<Scalars['String']>;
  patientId?: Maybe<Scalars['uuid']>;
  /** A computed field, executes function "get_id_from_metadata" */
  relevantObjectId?: Maybe<Scalars['String']>;
  textSearchColumn?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "activityLog" */
export type ActivityLog_Min_Order_By = {
  actorId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  objectType?: InputMaybe<Order_By>;
  operationType?: InputMaybe<Order_By>;
  patientId?: InputMaybe<Order_By>;
  textSearchColumn?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "activityLog" */
export type ActivityLog_Mutation_Response = {
  __typename?: 'activityLog_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ActivityLog>;
};

/** on_conflict condition type for table "activityLog" */
export type ActivityLog_On_Conflict = {
  constraint: ActivityLog_Constraint;
  update_columns?: Array<ActivityLog_Update_Column>;
  where?: InputMaybe<ActivityLog_Bool_Exp>;
};

/** Ordering options when selecting data from "activityLog". */
export type ActivityLog_Order_By = {
  actor?: InputMaybe<User_Order_By>;
  actorId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  isPatientActor?: InputMaybe<Order_By>;
  metadata?: InputMaybe<Order_By>;
  objectType?: InputMaybe<Order_By>;
  operationType?: InputMaybe<Order_By>;
  patient?: InputMaybe<Patient_Order_By>;
  patientId?: InputMaybe<Order_By>;
  relevantObjectId?: InputMaybe<Order_By>;
  textSearchColumn?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  workspace?: InputMaybe<Workspace_Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: activityLog */
export type ActivityLog_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type ActivityLog_Prepend_Input = {
  metadata?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "activityLog" */
export enum ActivityLog_Select_Column {
  /** column name */
  ActorId = 'actorId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  IsPatientActor = 'isPatientActor',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  ObjectType = 'objectType',
  /** column name */
  OperationType = 'operationType',
  /** column name */
  PatientId = 'patientId',
  /** column name */
  TextSearchColumn = 'textSearchColumn',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WorkspaceId = 'workspaceId'
}

/** select "activityLog_aggregate_bool_exp_bool_and_arguments_columns" columns of table "activityLog" */
export enum ActivityLog_Select_Column_ActivityLog_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsPatientActor = 'isPatientActor'
}

/** select "activityLog_aggregate_bool_exp_bool_or_arguments_columns" columns of table "activityLog" */
export enum ActivityLog_Select_Column_ActivityLog_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsPatientActor = 'isPatientActor'
}

/** input type for updating data in table "activityLog" */
export type ActivityLog_Set_Input = {
  actorId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  isPatientActor?: InputMaybe<Scalars['Boolean']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  objectType?: InputMaybe<Scalars['String']>;
  operationType?: InputMaybe<Scalars['String']>;
  patientId?: InputMaybe<Scalars['uuid']>;
  textSearchColumn?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "activityLog" */
export type ActivityLog_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ActivityLog_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ActivityLog_Stream_Cursor_Value_Input = {
  actorId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  isPatientActor?: InputMaybe<Scalars['Boolean']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  objectType?: InputMaybe<Scalars['String']>;
  operationType?: InputMaybe<Scalars['String']>;
  patientId?: InputMaybe<Scalars['uuid']>;
  textSearchColumn?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "activityLog" */
export enum ActivityLog_Update_Column {
  /** column name */
  ActorId = 'actorId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  IsPatientActor = 'isPatientActor',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  ObjectType = 'objectType',
  /** column name */
  OperationType = 'operationType',
  /** column name */
  PatientId = 'patientId',
  /** column name */
  TextSearchColumn = 'textSearchColumn',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WorkspaceId = 'workspaceId'
}

export type ActivityLog_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<ActivityLog_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<ActivityLog_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<ActivityLog_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<ActivityLog_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<ActivityLog_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ActivityLog_Set_Input>;
  /** filter the rows which have to be updated */
  where: ActivityLog_Bool_Exp;
};

/** columns and relationships of "address" */
export type Address = {
  __typename?: 'address';
  addressLine1: Scalars['String'];
  addressLine2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An array relationship */
  patientAddresses: Array<PatientAddress>;
  /** An aggregate relationship */
  patientAddresses_aggregate: PatientAddress_Aggregate;
  state: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  zipCode: Scalars['String'];
};


/** columns and relationships of "address" */
export type AddressPatientAddressesArgs = {
  distinct_on?: InputMaybe<Array<PatientAddress_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PatientAddress_Order_By>>;
  where?: InputMaybe<PatientAddress_Bool_Exp>;
};


/** columns and relationships of "address" */
export type AddressPatientAddresses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<PatientAddress_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PatientAddress_Order_By>>;
  where?: InputMaybe<PatientAddress_Bool_Exp>;
};

/** aggregated selection of "address" */
export type Address_Aggregate = {
  __typename?: 'address_aggregate';
  aggregate?: Maybe<Address_Aggregate_Fields>;
  nodes: Array<Address>;
};

/** aggregate fields of "address" */
export type Address_Aggregate_Fields = {
  __typename?: 'address_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Address_Max_Fields>;
  min?: Maybe<Address_Min_Fields>;
};


/** aggregate fields of "address" */
export type Address_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Address_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "address". All fields are combined with a logical 'AND'. */
export type Address_Bool_Exp = {
  _and?: InputMaybe<Array<Address_Bool_Exp>>;
  _not?: InputMaybe<Address_Bool_Exp>;
  _or?: InputMaybe<Array<Address_Bool_Exp>>;
  addressLine1?: InputMaybe<String_Comparison_Exp>;
  addressLine2?: InputMaybe<String_Comparison_Exp>;
  city?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  patientAddresses?: InputMaybe<PatientAddress_Bool_Exp>;
  patientAddresses_aggregate?: InputMaybe<PatientAddress_Aggregate_Bool_Exp>;
  state?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  zipCode?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "address" */
export enum Address_Constraint {
  /** unique or primary key constraint on columns "id" */
  AddressPkey = 'address_pkey'
}

/** input type for inserting data into table "address" */
export type Address_Insert_Input = {
  addressLine1?: InputMaybe<Scalars['String']>;
  addressLine2?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  patientAddresses?: InputMaybe<PatientAddress_Arr_Rel_Insert_Input>;
  state?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  zipCode?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Address_Max_Fields = {
  __typename?: 'address_max_fields';
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  state?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  zipCode?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Address_Min_Fields = {
  __typename?: 'address_min_fields';
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  state?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  zipCode?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "address" */
export type Address_Mutation_Response = {
  __typename?: 'address_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Address>;
};

/** input type for inserting object relation for remote table "address" */
export type Address_Obj_Rel_Insert_Input = {
  data: Address_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Address_On_Conflict>;
};

/** on_conflict condition type for table "address" */
export type Address_On_Conflict = {
  constraint: Address_Constraint;
  update_columns?: Array<Address_Update_Column>;
  where?: InputMaybe<Address_Bool_Exp>;
};

/** Ordering options when selecting data from "address". */
export type Address_Order_By = {
  addressLine1?: InputMaybe<Order_By>;
  addressLine2?: InputMaybe<Order_By>;
  city?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patientAddresses_aggregate?: InputMaybe<PatientAddress_Aggregate_Order_By>;
  state?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  zipCode?: InputMaybe<Order_By>;
};

/** primary key columns input for table: address */
export type Address_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "address" */
export enum Address_Select_Column {
  /** column name */
  AddressLine1 = 'addressLine1',
  /** column name */
  AddressLine2 = 'addressLine2',
  /** column name */
  City = 'city',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  State = 'state',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  ZipCode = 'zipCode'
}

/** input type for updating data in table "address" */
export type Address_Set_Input = {
  addressLine1?: InputMaybe<Scalars['String']>;
  addressLine2?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  state?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  zipCode?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "address" */
export type Address_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Address_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Address_Stream_Cursor_Value_Input = {
  addressLine1?: InputMaybe<Scalars['String']>;
  addressLine2?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  state?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  zipCode?: InputMaybe<Scalars['String']>;
};

/** update columns of table "address" */
export enum Address_Update_Column {
  /** column name */
  AddressLine1 = 'addressLine1',
  /** column name */
  AddressLine2 = 'addressLine2',
  /** column name */
  City = 'city',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  State = 'state',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  ZipCode = 'zipCode'
}

export type Address_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Address_Set_Input>;
  /** filter the rows which have to be updated */
  where: Address_Bool_Exp;
};

/** columns and relationships of "appointment" */
export type Appointment = {
  __typename?: 'appointment';
  /** An array relationship */
  appointmentConsents: Array<AppointmentConsent>;
  /** An aggregate relationship */
  appointmentConsents_aggregate: AppointmentConsent_Aggregate;
  /** An array relationship */
  appointmentCustomForms: Array<AppointmentCustomForm>;
  /** An aggregate relationship */
  appointmentCustomForms_aggregate: AppointmentCustomForm_Aggregate;
  /** An array relationship */
  appointmentServices: Array<AppointmentService>;
  /** An aggregate relationship */
  appointmentServices_aggregate: AppointmentService_Aggregate;
  /** An object relationship */
  appointmentType?: Maybe<AppointmentType>;
  appointmentTypeId?: Maybe<Scalars['uuid']>;
  approvedNoShowFeeAmount: Scalars['Int'];
  canCollectNoShowFee: Scalars['Boolean'];
  /** An array relationship */
  clinicalMessageStatuses: Array<ClinicalMessageStatus>;
  /** An aggregate relationship */
  clinicalMessageStatuses_aggregate: ClinicalMessageStatus_Aggregate;
  color?: Maybe<Scalars['String']>;
  conflicts?: Maybe<Array<Maybe<Conflict>>>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  /** An array relationship */
  customFormResponses: Array<CustomFormResponse>;
  /** An aggregate relationship */
  customFormResponses_aggregate: CustomFormResponse_Aggregate;
  disableConfirmationsReminders?: Maybe<Scalars['Boolean']>;
  /** An array relationship */
  documents: Array<Document>;
  /** An aggregate relationship */
  documents_aggregate: Document_Aggregate;
  durationInSeconds?: Maybe<Scalars['Int']>;
  endTime?: Maybe<Scalars['timestamptz']>;
  externalId?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  importJobId?: Maybe<Scalars['uuid']>;
  /** An array relationship */
  internalAppointmentProviders: Array<InternalAppointmentProvider>;
  /** An aggregate relationship */
  internalAppointmentProviders_aggregate: InternalAppointmentProvider_Aggregate;
  /** An array relationship */
  invoices: Array<Invoice>;
  /** An aggregate relationship */
  invoices_aggregate: Invoice_Aggregate;
  isArchived?: Maybe<Scalars['Boolean']>;
  isDraft: Scalars['Boolean'];
  /** A computed field, executes function "is_first_appointment_with_provider" */
  isFirstAppointmentWithProvider?: Maybe<Scalars['Boolean']>;
  isGoogleEvent: Scalars['Boolean'];
  /** A computed field, executes function "is_membership_active" */
  isMembershipActive?: Maybe<Scalars['Boolean']>;
  /** A computed field, executes function "is_membership_type_subscription" */
  isMembershipTypeSubscription?: Maybe<Scalars['Boolean']>;
  /** A computed field, executes function "is_new_patient" */
  isNewPatient?: Maybe<Scalars['Boolean']>;
  isRecurring: Scalars['Boolean'];
  /** An object relationship */
  location: Location;
  locationId: Scalars['uuid'];
  metadata: AppointmentMetadata;
  noShowFeePaymentMethodId?: Maybe<Scalars['uuid']>;
  noShowFeeStatus?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  /** An array relationship */
  notes: Array<Note>;
  /** An aggregate relationship */
  notes_aggregate: Note_Aggregate;
  /** An object relationship */
  patient?: Maybe<Patient>;
  /** An array relationship */
  patientConsents: Array<PatientConsent>;
  /** An aggregate relationship */
  patientConsents_aggregate: PatientConsent_Aggregate;
  patientId?: Maybe<Scalars['uuid']>;
  paymentStatus?: Maybe<Scalars['String']>;
  /** An array relationship */
  photos: Array<Photo>;
  /** An aggregate relationship */
  photos_aggregate: Photo_Aggregate;
  /** An object relationship */
  provider?: Maybe<Provider>;
  providerId?: Maybe<Scalars['uuid']>;
  recurringEndDate?: Maybe<Scalars['date']>;
  recurringExceptionDates?: Maybe<Scalars['jsonb']>;
  recurringExceptionRule?: Maybe<Scalars['String']>;
  recurringRule?: Maybe<Scalars['String']>;
  recurringStartDate?: Maybe<Scalars['date']>;
  resolvedAppointmentConsents: ResolvedAppointmentConsentsOutput;
  resolvedAppointmentConsumablesData: GetAppointmentConsumablesDataOutput;
  resolvedAppointmentCustomForms: ResolvedAppointmentCustomFormsOutput;
  /** An object relationship */
  room?: Maybe<Room>;
  roomId?: Maybe<Scalars['uuid']>;
  startTime?: Maybe<Scalars['timestamptz']>;
  status?: Maybe<Scalars['String']>;
  timerange?: Maybe<Scalars['tstzrange']>;
  type: Scalars['String'];
  updatedAt?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  workspace: Workspace;
  workspaceId: Scalars['uuid'];
};


/** columns and relationships of "appointment" */
export type AppointmentAppointmentConsentsArgs = {
  distinct_on?: InputMaybe<Array<AppointmentConsent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AppointmentConsent_Order_By>>;
  where?: InputMaybe<AppointmentConsent_Bool_Exp>;
};


/** columns and relationships of "appointment" */
export type AppointmentAppointmentConsents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<AppointmentConsent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AppointmentConsent_Order_By>>;
  where?: InputMaybe<AppointmentConsent_Bool_Exp>;
};


/** columns and relationships of "appointment" */
export type AppointmentAppointmentCustomFormsArgs = {
  distinct_on?: InputMaybe<Array<AppointmentCustomForm_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AppointmentCustomForm_Order_By>>;
  where?: InputMaybe<AppointmentCustomForm_Bool_Exp>;
};


/** columns and relationships of "appointment" */
export type AppointmentAppointmentCustomForms_AggregateArgs = {
  distinct_on?: InputMaybe<Array<AppointmentCustomForm_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AppointmentCustomForm_Order_By>>;
  where?: InputMaybe<AppointmentCustomForm_Bool_Exp>;
};


/** columns and relationships of "appointment" */
export type AppointmentAppointmentServicesArgs = {
  distinct_on?: InputMaybe<Array<AppointmentService_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AppointmentService_Order_By>>;
  where?: InputMaybe<AppointmentService_Bool_Exp>;
};


/** columns and relationships of "appointment" */
export type AppointmentAppointmentServices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<AppointmentService_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AppointmentService_Order_By>>;
  where?: InputMaybe<AppointmentService_Bool_Exp>;
};


/** columns and relationships of "appointment" */
export type AppointmentClinicalMessageStatusesArgs = {
  distinct_on?: InputMaybe<Array<ClinicalMessageStatus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ClinicalMessageStatus_Order_By>>;
  where?: InputMaybe<ClinicalMessageStatus_Bool_Exp>;
};


/** columns and relationships of "appointment" */
export type AppointmentClinicalMessageStatuses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ClinicalMessageStatus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ClinicalMessageStatus_Order_By>>;
  where?: InputMaybe<ClinicalMessageStatus_Bool_Exp>;
};


/** columns and relationships of "appointment" */
export type AppointmentCustomFormResponsesArgs = {
  distinct_on?: InputMaybe<Array<CustomFormResponse_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CustomFormResponse_Order_By>>;
  where?: InputMaybe<CustomFormResponse_Bool_Exp>;
};


/** columns and relationships of "appointment" */
export type AppointmentCustomFormResponses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CustomFormResponse_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CustomFormResponse_Order_By>>;
  where?: InputMaybe<CustomFormResponse_Bool_Exp>;
};


/** columns and relationships of "appointment" */
export type AppointmentDocumentsArgs = {
  distinct_on?: InputMaybe<Array<Document_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Document_Order_By>>;
  where?: InputMaybe<Document_Bool_Exp>;
};


/** columns and relationships of "appointment" */
export type AppointmentDocuments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Document_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Document_Order_By>>;
  where?: InputMaybe<Document_Bool_Exp>;
};


/** columns and relationships of "appointment" */
export type AppointmentInternalAppointmentProvidersArgs = {
  distinct_on?: InputMaybe<Array<InternalAppointmentProvider_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<InternalAppointmentProvider_Order_By>>;
  where?: InputMaybe<InternalAppointmentProvider_Bool_Exp>;
};


/** columns and relationships of "appointment" */
export type AppointmentInternalAppointmentProviders_AggregateArgs = {
  distinct_on?: InputMaybe<Array<InternalAppointmentProvider_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<InternalAppointmentProvider_Order_By>>;
  where?: InputMaybe<InternalAppointmentProvider_Bool_Exp>;
};


/** columns and relationships of "appointment" */
export type AppointmentInvoicesArgs = {
  distinct_on?: InputMaybe<Array<Invoice_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Invoice_Order_By>>;
  where?: InputMaybe<Invoice_Bool_Exp>;
};


/** columns and relationships of "appointment" */
export type AppointmentInvoices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Invoice_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Invoice_Order_By>>;
  where?: InputMaybe<Invoice_Bool_Exp>;
};


/** columns and relationships of "appointment" */
export type AppointmentNotesArgs = {
  distinct_on?: InputMaybe<Array<Note_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Note_Order_By>>;
  where?: InputMaybe<Note_Bool_Exp>;
};


/** columns and relationships of "appointment" */
export type AppointmentNotes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Note_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Note_Order_By>>;
  where?: InputMaybe<Note_Bool_Exp>;
};


/** columns and relationships of "appointment" */
export type AppointmentPatientConsentsArgs = {
  distinct_on?: InputMaybe<Array<PatientConsent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PatientConsent_Order_By>>;
  where?: InputMaybe<PatientConsent_Bool_Exp>;
};


/** columns and relationships of "appointment" */
export type AppointmentPatientConsents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<PatientConsent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PatientConsent_Order_By>>;
  where?: InputMaybe<PatientConsent_Bool_Exp>;
};


/** columns and relationships of "appointment" */
export type AppointmentPhotosArgs = {
  distinct_on?: InputMaybe<Array<Photo_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Photo_Order_By>>;
  where?: InputMaybe<Photo_Bool_Exp>;
};


/** columns and relationships of "appointment" */
export type AppointmentPhotos_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Photo_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Photo_Order_By>>;
  where?: InputMaybe<Photo_Bool_Exp>;
};


/** columns and relationships of "appointment" */
export type AppointmentRecurringExceptionDatesArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "appointmentConsent" */
export type AppointmentConsent = {
  __typename?: 'appointmentConsent';
  appointmentId: Scalars['uuid'];
  /** An object relationship */
  consent: Consent;
  consentId: Scalars['uuid'];
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  manuallyAdded: Scalars['Boolean'];
  manuallyRemoved: Scalars['Boolean'];
  metadata: AppointmentConsentMetadata;
  updatedAt: Scalars['timestamptz'];
};

/** aggregated selection of "appointmentConsent" */
export type AppointmentConsent_Aggregate = {
  __typename?: 'appointmentConsent_aggregate';
  aggregate?: Maybe<AppointmentConsent_Aggregate_Fields>;
  nodes: Array<AppointmentConsent>;
};

export type AppointmentConsent_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<AppointmentConsent_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<AppointmentConsent_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<AppointmentConsent_Aggregate_Bool_Exp_Count>;
};

export type AppointmentConsent_Aggregate_Bool_Exp_Bool_And = {
  arguments: AppointmentConsent_Select_Column_AppointmentConsent_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<AppointmentConsent_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type AppointmentConsent_Aggregate_Bool_Exp_Bool_Or = {
  arguments: AppointmentConsent_Select_Column_AppointmentConsent_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<AppointmentConsent_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type AppointmentConsent_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<AppointmentConsent_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<AppointmentConsent_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "appointmentConsent" */
export type AppointmentConsent_Aggregate_Fields = {
  __typename?: 'appointmentConsent_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<AppointmentConsent_Max_Fields>;
  min?: Maybe<AppointmentConsent_Min_Fields>;
};


/** aggregate fields of "appointmentConsent" */
export type AppointmentConsent_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<AppointmentConsent_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "appointmentConsent" */
export type AppointmentConsent_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<AppointmentConsent_Max_Order_By>;
  min?: InputMaybe<AppointmentConsent_Min_Order_By>;
};

/** input type for inserting array relation for remote table "appointmentConsent" */
export type AppointmentConsent_Arr_Rel_Insert_Input = {
  data: Array<AppointmentConsent_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<AppointmentConsent_On_Conflict>;
};

/** Boolean expression to filter rows from the table "appointmentConsent". All fields are combined with a logical 'AND'. */
export type AppointmentConsent_Bool_Exp = {
  _and?: InputMaybe<Array<AppointmentConsent_Bool_Exp>>;
  _not?: InputMaybe<AppointmentConsent_Bool_Exp>;
  _or?: InputMaybe<Array<AppointmentConsent_Bool_Exp>>;
  appointmentId?: InputMaybe<Uuid_Comparison_Exp>;
  consent?: InputMaybe<Consent_Bool_Exp>;
  consentId?: InputMaybe<Uuid_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  manuallyAdded?: InputMaybe<Boolean_Comparison_Exp>;
  manuallyRemoved?: InputMaybe<Boolean_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "appointmentConsent" */
export enum AppointmentConsent_Constraint {
  /** unique or primary key constraint on columns "appointmentId", "consentId" */
  AppointmentConsentAppointmentIdConsentIdKey = 'appointmentConsent_appointmentId_consentId_key',
  /** unique or primary key constraint on columns "id" */
  AppointmentConsentPkey = 'appointmentConsent_pkey'
}

/** input type for inserting data into table "appointmentConsent" */
export type AppointmentConsent_Insert_Input = {
  appointmentId?: InputMaybe<Scalars['uuid']>;
  consent?: InputMaybe<Consent_Obj_Rel_Insert_Input>;
  consentId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  manuallyAdded?: InputMaybe<Scalars['Boolean']>;
  manuallyRemoved?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type AppointmentConsent_Max_Fields = {
  __typename?: 'appointmentConsent_max_fields';
  appointmentId?: Maybe<Scalars['uuid']>;
  consentId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "appointmentConsent" */
export type AppointmentConsent_Max_Order_By = {
  appointmentId?: InputMaybe<Order_By>;
  consentId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type AppointmentConsent_Min_Fields = {
  __typename?: 'appointmentConsent_min_fields';
  appointmentId?: Maybe<Scalars['uuid']>;
  consentId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "appointmentConsent" */
export type AppointmentConsent_Min_Order_By = {
  appointmentId?: InputMaybe<Order_By>;
  consentId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "appointmentConsent" */
export type AppointmentConsent_Mutation_Response = {
  __typename?: 'appointmentConsent_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<AppointmentConsent>;
};

/** on_conflict condition type for table "appointmentConsent" */
export type AppointmentConsent_On_Conflict = {
  constraint: AppointmentConsent_Constraint;
  update_columns?: Array<AppointmentConsent_Update_Column>;
  where?: InputMaybe<AppointmentConsent_Bool_Exp>;
};

/** Ordering options when selecting data from "appointmentConsent". */
export type AppointmentConsent_Order_By = {
  appointmentId?: InputMaybe<Order_By>;
  consent?: InputMaybe<Consent_Order_By>;
  consentId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  manuallyAdded?: InputMaybe<Order_By>;
  manuallyRemoved?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: appointmentConsent */
export type AppointmentConsent_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "appointmentConsent" */
export enum AppointmentConsent_Select_Column {
  /** column name */
  AppointmentId = 'appointmentId',
  /** column name */
  ConsentId = 'consentId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  ManuallyAdded = 'manuallyAdded',
  /** column name */
  ManuallyRemoved = 'manuallyRemoved',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** select "appointmentConsent_aggregate_bool_exp_bool_and_arguments_columns" columns of table "appointmentConsent" */
export enum AppointmentConsent_Select_Column_AppointmentConsent_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  ManuallyAdded = 'manuallyAdded',
  /** column name */
  ManuallyRemoved = 'manuallyRemoved'
}

/** select "appointmentConsent_aggregate_bool_exp_bool_or_arguments_columns" columns of table "appointmentConsent" */
export enum AppointmentConsent_Select_Column_AppointmentConsent_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  ManuallyAdded = 'manuallyAdded',
  /** column name */
  ManuallyRemoved = 'manuallyRemoved'
}

/** input type for updating data in table "appointmentConsent" */
export type AppointmentConsent_Set_Input = {
  appointmentId?: InputMaybe<Scalars['uuid']>;
  consentId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  manuallyAdded?: InputMaybe<Scalars['Boolean']>;
  manuallyRemoved?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "appointmentConsent" */
export type AppointmentConsent_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: AppointmentConsent_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type AppointmentConsent_Stream_Cursor_Value_Input = {
  appointmentId?: InputMaybe<Scalars['uuid']>;
  consentId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  manuallyAdded?: InputMaybe<Scalars['Boolean']>;
  manuallyRemoved?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "appointmentConsent" */
export enum AppointmentConsent_Update_Column {
  /** column name */
  AppointmentId = 'appointmentId',
  /** column name */
  ConsentId = 'consentId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  ManuallyAdded = 'manuallyAdded',
  /** column name */
  ManuallyRemoved = 'manuallyRemoved',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type AppointmentConsent_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AppointmentConsent_Set_Input>;
  /** filter the rows which have to be updated */
  where: AppointmentConsent_Bool_Exp;
};

/** columns and relationships of "appointmentCustomForm" */
export type AppointmentCustomForm = {
  __typename?: 'appointmentCustomForm';
  appointmentId: Scalars['uuid'];
  createdAt: Scalars['timestamptz'];
  /** An object relationship */
  customForm: CustomForm;
  customFormId: Scalars['uuid'];
  id: Scalars['uuid'];
  manuallyAdded: Scalars['Boolean'];
  manuallyRemoved: Scalars['Boolean'];
  metadata: AppointmentCustomFormMetadata;
  updatedAt: Scalars['timestamptz'];
};

/** aggregated selection of "appointmentCustomForm" */
export type AppointmentCustomForm_Aggregate = {
  __typename?: 'appointmentCustomForm_aggregate';
  aggregate?: Maybe<AppointmentCustomForm_Aggregate_Fields>;
  nodes: Array<AppointmentCustomForm>;
};

export type AppointmentCustomForm_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<AppointmentCustomForm_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<AppointmentCustomForm_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<AppointmentCustomForm_Aggregate_Bool_Exp_Count>;
};

export type AppointmentCustomForm_Aggregate_Bool_Exp_Bool_And = {
  arguments: AppointmentCustomForm_Select_Column_AppointmentCustomForm_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<AppointmentCustomForm_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type AppointmentCustomForm_Aggregate_Bool_Exp_Bool_Or = {
  arguments: AppointmentCustomForm_Select_Column_AppointmentCustomForm_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<AppointmentCustomForm_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type AppointmentCustomForm_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<AppointmentCustomForm_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<AppointmentCustomForm_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "appointmentCustomForm" */
export type AppointmentCustomForm_Aggregate_Fields = {
  __typename?: 'appointmentCustomForm_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<AppointmentCustomForm_Max_Fields>;
  min?: Maybe<AppointmentCustomForm_Min_Fields>;
};


/** aggregate fields of "appointmentCustomForm" */
export type AppointmentCustomForm_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<AppointmentCustomForm_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "appointmentCustomForm" */
export type AppointmentCustomForm_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<AppointmentCustomForm_Max_Order_By>;
  min?: InputMaybe<AppointmentCustomForm_Min_Order_By>;
};

/** input type for inserting array relation for remote table "appointmentCustomForm" */
export type AppointmentCustomForm_Arr_Rel_Insert_Input = {
  data: Array<AppointmentCustomForm_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<AppointmentCustomForm_On_Conflict>;
};

/** Boolean expression to filter rows from the table "appointmentCustomForm". All fields are combined with a logical 'AND'. */
export type AppointmentCustomForm_Bool_Exp = {
  _and?: InputMaybe<Array<AppointmentCustomForm_Bool_Exp>>;
  _not?: InputMaybe<AppointmentCustomForm_Bool_Exp>;
  _or?: InputMaybe<Array<AppointmentCustomForm_Bool_Exp>>;
  appointmentId?: InputMaybe<Uuid_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  customForm?: InputMaybe<CustomForm_Bool_Exp>;
  customFormId?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  manuallyAdded?: InputMaybe<Boolean_Comparison_Exp>;
  manuallyRemoved?: InputMaybe<Boolean_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "appointmentCustomForm" */
export enum AppointmentCustomForm_Constraint {
  /** unique or primary key constraint on columns "customFormId", "appointmentId" */
  AppointmentCustomFormAppointmentIdCustomFormIdKey = 'appointmentCustomForm_appointmentId_customFormId_key',
  /** unique or primary key constraint on columns "id" */
  AppointmentCustomFormPkey = 'appointmentCustomForm_pkey'
}

/** input type for inserting data into table "appointmentCustomForm" */
export type AppointmentCustomForm_Insert_Input = {
  appointmentId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  customForm?: InputMaybe<CustomForm_Obj_Rel_Insert_Input>;
  customFormId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  manuallyAdded?: InputMaybe<Scalars['Boolean']>;
  manuallyRemoved?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type AppointmentCustomForm_Max_Fields = {
  __typename?: 'appointmentCustomForm_max_fields';
  appointmentId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  customFormId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "appointmentCustomForm" */
export type AppointmentCustomForm_Max_Order_By = {
  appointmentId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  customFormId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type AppointmentCustomForm_Min_Fields = {
  __typename?: 'appointmentCustomForm_min_fields';
  appointmentId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  customFormId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "appointmentCustomForm" */
export type AppointmentCustomForm_Min_Order_By = {
  appointmentId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  customFormId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "appointmentCustomForm" */
export type AppointmentCustomForm_Mutation_Response = {
  __typename?: 'appointmentCustomForm_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<AppointmentCustomForm>;
};

/** on_conflict condition type for table "appointmentCustomForm" */
export type AppointmentCustomForm_On_Conflict = {
  constraint: AppointmentCustomForm_Constraint;
  update_columns?: Array<AppointmentCustomForm_Update_Column>;
  where?: InputMaybe<AppointmentCustomForm_Bool_Exp>;
};

/** Ordering options when selecting data from "appointmentCustomForm". */
export type AppointmentCustomForm_Order_By = {
  appointmentId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  customForm?: InputMaybe<CustomForm_Order_By>;
  customFormId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  manuallyAdded?: InputMaybe<Order_By>;
  manuallyRemoved?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: appointmentCustomForm */
export type AppointmentCustomForm_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "appointmentCustomForm" */
export enum AppointmentCustomForm_Select_Column {
  /** column name */
  AppointmentId = 'appointmentId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CustomFormId = 'customFormId',
  /** column name */
  Id = 'id',
  /** column name */
  ManuallyAdded = 'manuallyAdded',
  /** column name */
  ManuallyRemoved = 'manuallyRemoved',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** select "appointmentCustomForm_aggregate_bool_exp_bool_and_arguments_columns" columns of table "appointmentCustomForm" */
export enum AppointmentCustomForm_Select_Column_AppointmentCustomForm_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  ManuallyAdded = 'manuallyAdded',
  /** column name */
  ManuallyRemoved = 'manuallyRemoved'
}

/** select "appointmentCustomForm_aggregate_bool_exp_bool_or_arguments_columns" columns of table "appointmentCustomForm" */
export enum AppointmentCustomForm_Select_Column_AppointmentCustomForm_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  ManuallyAdded = 'manuallyAdded',
  /** column name */
  ManuallyRemoved = 'manuallyRemoved'
}

/** input type for updating data in table "appointmentCustomForm" */
export type AppointmentCustomForm_Set_Input = {
  appointmentId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  customFormId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  manuallyAdded?: InputMaybe<Scalars['Boolean']>;
  manuallyRemoved?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "appointmentCustomForm" */
export type AppointmentCustomForm_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: AppointmentCustomForm_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type AppointmentCustomForm_Stream_Cursor_Value_Input = {
  appointmentId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  customFormId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  manuallyAdded?: InputMaybe<Scalars['Boolean']>;
  manuallyRemoved?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "appointmentCustomForm" */
export enum AppointmentCustomForm_Update_Column {
  /** column name */
  AppointmentId = 'appointmentId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CustomFormId = 'customFormId',
  /** column name */
  Id = 'id',
  /** column name */
  ManuallyAdded = 'manuallyAdded',
  /** column name */
  ManuallyRemoved = 'manuallyRemoved',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type AppointmentCustomForm_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AppointmentCustomForm_Set_Input>;
  /** filter the rows which have to be updated */
  where: AppointmentCustomForm_Bool_Exp;
};

/** columns and relationships of "appointmentService" */
export type AppointmentService = {
  __typename?: 'appointmentService';
  /** An object relationship */
  appointment: Appointment;
  appointmentId: Scalars['uuid'];
  /** An array relationship */
  appointmentServiceConsumables: Array<AppointmentServiceConsumable>;
  /** An aggregate relationship */
  appointmentServiceConsumables_aggregate: AppointmentServiceConsumable_Aggregate;
  consumablesStatus?: Maybe<AppointmentServiceConsumablesStatusOutput>;
  createdAt: Scalars['timestamptz'];
  /** An object relationship */
  device?: Maybe<Device>;
  deviceId?: Maybe<Scalars['uuid']>;
  externalId?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  isArchived: Scalars['Boolean'];
  order?: Maybe<Scalars['Int']>;
  /** An object relationship */
  provider?: Maybe<Provider>;
  providerId?: Maybe<Scalars['uuid']>;
  quantity: Scalars['Int'];
  /** An object relationship */
  service: Service;
  serviceDurationMinutes?: Maybe<Scalars['Int']>;
  serviceId: Scalars['uuid'];
  serviceMinutesOverride?: Maybe<Scalars['Int']>;
  timerange?: Maybe<Scalars['tstzrange']>;
  type?: Maybe<Scalars['String']>;
  updatedAt: Scalars['timestamptz'];
  workspaceId: Scalars['uuid'];
};


/** columns and relationships of "appointmentService" */
export type AppointmentServiceAppointmentServiceConsumablesArgs = {
  distinct_on?: InputMaybe<Array<AppointmentServiceConsumable_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AppointmentServiceConsumable_Order_By>>;
  where?: InputMaybe<AppointmentServiceConsumable_Bool_Exp>;
};


/** columns and relationships of "appointmentService" */
export type AppointmentServiceAppointmentServiceConsumables_AggregateArgs = {
  distinct_on?: InputMaybe<Array<AppointmentServiceConsumable_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AppointmentServiceConsumable_Order_By>>;
  where?: InputMaybe<AppointmentServiceConsumable_Bool_Exp>;
};

/** columns and relationships of "appointmentServiceConsumable" */
export type AppointmentServiceConsumable = {
  __typename?: 'appointmentServiceConsumable';
  /** An object relationship */
  appointmentService: AppointmentService;
  appointmentServiceId: Scalars['uuid'];
  /** An object relationship */
  consumable: Consumable;
  consumableId: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  quantity: Scalars['float8'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "appointmentServiceConsumable" */
export type AppointmentServiceConsumable_Aggregate = {
  __typename?: 'appointmentServiceConsumable_aggregate';
  aggregate?: Maybe<AppointmentServiceConsumable_Aggregate_Fields>;
  nodes: Array<AppointmentServiceConsumable>;
};

export type AppointmentServiceConsumable_Aggregate_Bool_Exp = {
  avg?: InputMaybe<AppointmentServiceConsumable_Aggregate_Bool_Exp_Avg>;
  corr?: InputMaybe<AppointmentServiceConsumable_Aggregate_Bool_Exp_Corr>;
  count?: InputMaybe<AppointmentServiceConsumable_Aggregate_Bool_Exp_Count>;
  covar_samp?: InputMaybe<AppointmentServiceConsumable_Aggregate_Bool_Exp_Covar_Samp>;
  max?: InputMaybe<AppointmentServiceConsumable_Aggregate_Bool_Exp_Max>;
  min?: InputMaybe<AppointmentServiceConsumable_Aggregate_Bool_Exp_Min>;
  stddev_samp?: InputMaybe<AppointmentServiceConsumable_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: InputMaybe<AppointmentServiceConsumable_Aggregate_Bool_Exp_Sum>;
  var_samp?: InputMaybe<AppointmentServiceConsumable_Aggregate_Bool_Exp_Var_Samp>;
};

export type AppointmentServiceConsumable_Aggregate_Bool_Exp_Avg = {
  arguments: AppointmentServiceConsumable_Select_Column_AppointmentServiceConsumable_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<AppointmentServiceConsumable_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type AppointmentServiceConsumable_Aggregate_Bool_Exp_Corr = {
  arguments: AppointmentServiceConsumable_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<AppointmentServiceConsumable_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type AppointmentServiceConsumable_Aggregate_Bool_Exp_Corr_Arguments = {
  X: AppointmentServiceConsumable_Select_Column_AppointmentServiceConsumable_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: AppointmentServiceConsumable_Select_Column_AppointmentServiceConsumable_Aggregate_Bool_Exp_Corr_Arguments_Columns;
};

export type AppointmentServiceConsumable_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<AppointmentServiceConsumable_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<AppointmentServiceConsumable_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type AppointmentServiceConsumable_Aggregate_Bool_Exp_Covar_Samp = {
  arguments: AppointmentServiceConsumable_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<AppointmentServiceConsumable_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type AppointmentServiceConsumable_Aggregate_Bool_Exp_Covar_Samp_Arguments = {
  X: AppointmentServiceConsumable_Select_Column_AppointmentServiceConsumable_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  Y: AppointmentServiceConsumable_Select_Column_AppointmentServiceConsumable_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
};

export type AppointmentServiceConsumable_Aggregate_Bool_Exp_Max = {
  arguments: AppointmentServiceConsumable_Select_Column_AppointmentServiceConsumable_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<AppointmentServiceConsumable_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type AppointmentServiceConsumable_Aggregate_Bool_Exp_Min = {
  arguments: AppointmentServiceConsumable_Select_Column_AppointmentServiceConsumable_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<AppointmentServiceConsumable_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type AppointmentServiceConsumable_Aggregate_Bool_Exp_Stddev_Samp = {
  arguments: AppointmentServiceConsumable_Select_Column_AppointmentServiceConsumable_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<AppointmentServiceConsumable_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type AppointmentServiceConsumable_Aggregate_Bool_Exp_Sum = {
  arguments: AppointmentServiceConsumable_Select_Column_AppointmentServiceConsumable_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<AppointmentServiceConsumable_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type AppointmentServiceConsumable_Aggregate_Bool_Exp_Var_Samp = {
  arguments: AppointmentServiceConsumable_Select_Column_AppointmentServiceConsumable_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<AppointmentServiceConsumable_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

/** aggregate fields of "appointmentServiceConsumable" */
export type AppointmentServiceConsumable_Aggregate_Fields = {
  __typename?: 'appointmentServiceConsumable_aggregate_fields';
  avg?: Maybe<AppointmentServiceConsumable_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<AppointmentServiceConsumable_Max_Fields>;
  min?: Maybe<AppointmentServiceConsumable_Min_Fields>;
  stddev?: Maybe<AppointmentServiceConsumable_Stddev_Fields>;
  stddev_pop?: Maybe<AppointmentServiceConsumable_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<AppointmentServiceConsumable_Stddev_Samp_Fields>;
  sum?: Maybe<AppointmentServiceConsumable_Sum_Fields>;
  var_pop?: Maybe<AppointmentServiceConsumable_Var_Pop_Fields>;
  var_samp?: Maybe<AppointmentServiceConsumable_Var_Samp_Fields>;
  variance?: Maybe<AppointmentServiceConsumable_Variance_Fields>;
};


/** aggregate fields of "appointmentServiceConsumable" */
export type AppointmentServiceConsumable_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<AppointmentServiceConsumable_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "appointmentServiceConsumable" */
export type AppointmentServiceConsumable_Aggregate_Order_By = {
  avg?: InputMaybe<AppointmentServiceConsumable_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<AppointmentServiceConsumable_Max_Order_By>;
  min?: InputMaybe<AppointmentServiceConsumable_Min_Order_By>;
  stddev?: InputMaybe<AppointmentServiceConsumable_Stddev_Order_By>;
  stddev_pop?: InputMaybe<AppointmentServiceConsumable_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<AppointmentServiceConsumable_Stddev_Samp_Order_By>;
  sum?: InputMaybe<AppointmentServiceConsumable_Sum_Order_By>;
  var_pop?: InputMaybe<AppointmentServiceConsumable_Var_Pop_Order_By>;
  var_samp?: InputMaybe<AppointmentServiceConsumable_Var_Samp_Order_By>;
  variance?: InputMaybe<AppointmentServiceConsumable_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "appointmentServiceConsumable" */
export type AppointmentServiceConsumable_Arr_Rel_Insert_Input = {
  data: Array<AppointmentServiceConsumable_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<AppointmentServiceConsumable_On_Conflict>;
};

/** aggregate avg on columns */
export type AppointmentServiceConsumable_Avg_Fields = {
  __typename?: 'appointmentServiceConsumable_avg_fields';
  quantity?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "appointmentServiceConsumable" */
export type AppointmentServiceConsumable_Avg_Order_By = {
  quantity?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "appointmentServiceConsumable". All fields are combined with a logical 'AND'. */
export type AppointmentServiceConsumable_Bool_Exp = {
  _and?: InputMaybe<Array<AppointmentServiceConsumable_Bool_Exp>>;
  _not?: InputMaybe<AppointmentServiceConsumable_Bool_Exp>;
  _or?: InputMaybe<Array<AppointmentServiceConsumable_Bool_Exp>>;
  appointmentService?: InputMaybe<AppointmentService_Bool_Exp>;
  appointmentServiceId?: InputMaybe<Uuid_Comparison_Exp>;
  consumable?: InputMaybe<Consumable_Bool_Exp>;
  consumableId?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  quantity?: InputMaybe<Float8_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "appointmentServiceConsumable" */
export enum AppointmentServiceConsumable_Constraint {
  /** unique or primary key constraint on columns "id" */
  AppointmentServiceConsumablePkey = 'appointmentServiceConsumable_pkey'
}

/** input type for incrementing numeric columns in table "appointmentServiceConsumable" */
export type AppointmentServiceConsumable_Inc_Input = {
  quantity?: InputMaybe<Scalars['float8']>;
};

/** input type for inserting data into table "appointmentServiceConsumable" */
export type AppointmentServiceConsumable_Insert_Input = {
  appointmentService?: InputMaybe<AppointmentService_Obj_Rel_Insert_Input>;
  appointmentServiceId?: InputMaybe<Scalars['uuid']>;
  consumable?: InputMaybe<Consumable_Obj_Rel_Insert_Input>;
  consumableId?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  quantity?: InputMaybe<Scalars['float8']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type AppointmentServiceConsumable_Max_Fields = {
  __typename?: 'appointmentServiceConsumable_max_fields';
  appointmentServiceId?: Maybe<Scalars['uuid']>;
  consumableId?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  quantity?: Maybe<Scalars['float8']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "appointmentServiceConsumable" */
export type AppointmentServiceConsumable_Max_Order_By = {
  appointmentServiceId?: InputMaybe<Order_By>;
  consumableId?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type AppointmentServiceConsumable_Min_Fields = {
  __typename?: 'appointmentServiceConsumable_min_fields';
  appointmentServiceId?: Maybe<Scalars['uuid']>;
  consumableId?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  quantity?: Maybe<Scalars['float8']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "appointmentServiceConsumable" */
export type AppointmentServiceConsumable_Min_Order_By = {
  appointmentServiceId?: InputMaybe<Order_By>;
  consumableId?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "appointmentServiceConsumable" */
export type AppointmentServiceConsumable_Mutation_Response = {
  __typename?: 'appointmentServiceConsumable_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<AppointmentServiceConsumable>;
};

/** on_conflict condition type for table "appointmentServiceConsumable" */
export type AppointmentServiceConsumable_On_Conflict = {
  constraint: AppointmentServiceConsumable_Constraint;
  update_columns?: Array<AppointmentServiceConsumable_Update_Column>;
  where?: InputMaybe<AppointmentServiceConsumable_Bool_Exp>;
};

/** Ordering options when selecting data from "appointmentServiceConsumable". */
export type AppointmentServiceConsumable_Order_By = {
  appointmentService?: InputMaybe<AppointmentService_Order_By>;
  appointmentServiceId?: InputMaybe<Order_By>;
  consumable?: InputMaybe<Consumable_Order_By>;
  consumableId?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: appointmentServiceConsumable */
export type AppointmentServiceConsumable_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "appointmentServiceConsumable" */
export enum AppointmentServiceConsumable_Select_Column {
  /** column name */
  AppointmentServiceId = 'appointmentServiceId',
  /** column name */
  ConsumableId = 'consumableId',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Quantity = 'quantity',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "appointmentServiceConsumable_aggregate_bool_exp_avg_arguments_columns" columns of table "appointmentServiceConsumable" */
export enum AppointmentServiceConsumable_Select_Column_AppointmentServiceConsumable_Aggregate_Bool_Exp_Avg_Arguments_Columns {
  /** column name */
  Quantity = 'quantity'
}

/** select "appointmentServiceConsumable_aggregate_bool_exp_corr_arguments_columns" columns of table "appointmentServiceConsumable" */
export enum AppointmentServiceConsumable_Select_Column_AppointmentServiceConsumable_Aggregate_Bool_Exp_Corr_Arguments_Columns {
  /** column name */
  Quantity = 'quantity'
}

/** select "appointmentServiceConsumable_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "appointmentServiceConsumable" */
export enum AppointmentServiceConsumable_Select_Column_AppointmentServiceConsumable_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns {
  /** column name */
  Quantity = 'quantity'
}

/** select "appointmentServiceConsumable_aggregate_bool_exp_max_arguments_columns" columns of table "appointmentServiceConsumable" */
export enum AppointmentServiceConsumable_Select_Column_AppointmentServiceConsumable_Aggregate_Bool_Exp_Max_Arguments_Columns {
  /** column name */
  Quantity = 'quantity'
}

/** select "appointmentServiceConsumable_aggregate_bool_exp_min_arguments_columns" columns of table "appointmentServiceConsumable" */
export enum AppointmentServiceConsumable_Select_Column_AppointmentServiceConsumable_Aggregate_Bool_Exp_Min_Arguments_Columns {
  /** column name */
  Quantity = 'quantity'
}

/** select "appointmentServiceConsumable_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "appointmentServiceConsumable" */
export enum AppointmentServiceConsumable_Select_Column_AppointmentServiceConsumable_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns {
  /** column name */
  Quantity = 'quantity'
}

/** select "appointmentServiceConsumable_aggregate_bool_exp_sum_arguments_columns" columns of table "appointmentServiceConsumable" */
export enum AppointmentServiceConsumable_Select_Column_AppointmentServiceConsumable_Aggregate_Bool_Exp_Sum_Arguments_Columns {
  /** column name */
  Quantity = 'quantity'
}

/** select "appointmentServiceConsumable_aggregate_bool_exp_var_samp_arguments_columns" columns of table "appointmentServiceConsumable" */
export enum AppointmentServiceConsumable_Select_Column_AppointmentServiceConsumable_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns {
  /** column name */
  Quantity = 'quantity'
}

/** input type for updating data in table "appointmentServiceConsumable" */
export type AppointmentServiceConsumable_Set_Input = {
  appointmentServiceId?: InputMaybe<Scalars['uuid']>;
  consumableId?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  quantity?: InputMaybe<Scalars['float8']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type AppointmentServiceConsumable_Stddev_Fields = {
  __typename?: 'appointmentServiceConsumable_stddev_fields';
  quantity?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "appointmentServiceConsumable" */
export type AppointmentServiceConsumable_Stddev_Order_By = {
  quantity?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type AppointmentServiceConsumable_Stddev_Pop_Fields = {
  __typename?: 'appointmentServiceConsumable_stddev_pop_fields';
  quantity?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "appointmentServiceConsumable" */
export type AppointmentServiceConsumable_Stddev_Pop_Order_By = {
  quantity?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type AppointmentServiceConsumable_Stddev_Samp_Fields = {
  __typename?: 'appointmentServiceConsumable_stddev_samp_fields';
  quantity?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "appointmentServiceConsumable" */
export type AppointmentServiceConsumable_Stddev_Samp_Order_By = {
  quantity?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "appointmentServiceConsumable" */
export type AppointmentServiceConsumable_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: AppointmentServiceConsumable_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type AppointmentServiceConsumable_Stream_Cursor_Value_Input = {
  appointmentServiceId?: InputMaybe<Scalars['uuid']>;
  consumableId?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  quantity?: InputMaybe<Scalars['float8']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type AppointmentServiceConsumable_Sum_Fields = {
  __typename?: 'appointmentServiceConsumable_sum_fields';
  quantity?: Maybe<Scalars['float8']>;
};

/** order by sum() on columns of table "appointmentServiceConsumable" */
export type AppointmentServiceConsumable_Sum_Order_By = {
  quantity?: InputMaybe<Order_By>;
};

/** update columns of table "appointmentServiceConsumable" */
export enum AppointmentServiceConsumable_Update_Column {
  /** column name */
  AppointmentServiceId = 'appointmentServiceId',
  /** column name */
  ConsumableId = 'consumableId',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Quantity = 'quantity',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type AppointmentServiceConsumable_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<AppointmentServiceConsumable_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AppointmentServiceConsumable_Set_Input>;
  /** filter the rows which have to be updated */
  where: AppointmentServiceConsumable_Bool_Exp;
};

/** aggregate var_pop on columns */
export type AppointmentServiceConsumable_Var_Pop_Fields = {
  __typename?: 'appointmentServiceConsumable_var_pop_fields';
  quantity?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "appointmentServiceConsumable" */
export type AppointmentServiceConsumable_Var_Pop_Order_By = {
  quantity?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type AppointmentServiceConsumable_Var_Samp_Fields = {
  __typename?: 'appointmentServiceConsumable_var_samp_fields';
  quantity?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "appointmentServiceConsumable" */
export type AppointmentServiceConsumable_Var_Samp_Order_By = {
  quantity?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type AppointmentServiceConsumable_Variance_Fields = {
  __typename?: 'appointmentServiceConsumable_variance_fields';
  quantity?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "appointmentServiceConsumable" */
export type AppointmentServiceConsumable_Variance_Order_By = {
  quantity?: InputMaybe<Order_By>;
};

/** aggregated selection of "appointmentService" */
export type AppointmentService_Aggregate = {
  __typename?: 'appointmentService_aggregate';
  aggregate?: Maybe<AppointmentService_Aggregate_Fields>;
  nodes: Array<AppointmentService>;
};

export type AppointmentService_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<AppointmentService_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<AppointmentService_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<AppointmentService_Aggregate_Bool_Exp_Count>;
};

export type AppointmentService_Aggregate_Bool_Exp_Bool_And = {
  arguments: AppointmentService_Select_Column_AppointmentService_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<AppointmentService_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type AppointmentService_Aggregate_Bool_Exp_Bool_Or = {
  arguments: AppointmentService_Select_Column_AppointmentService_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<AppointmentService_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type AppointmentService_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<AppointmentService_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<AppointmentService_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "appointmentService" */
export type AppointmentService_Aggregate_Fields = {
  __typename?: 'appointmentService_aggregate_fields';
  avg?: Maybe<AppointmentService_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<AppointmentService_Max_Fields>;
  min?: Maybe<AppointmentService_Min_Fields>;
  stddev?: Maybe<AppointmentService_Stddev_Fields>;
  stddev_pop?: Maybe<AppointmentService_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<AppointmentService_Stddev_Samp_Fields>;
  sum?: Maybe<AppointmentService_Sum_Fields>;
  var_pop?: Maybe<AppointmentService_Var_Pop_Fields>;
  var_samp?: Maybe<AppointmentService_Var_Samp_Fields>;
  variance?: Maybe<AppointmentService_Variance_Fields>;
};


/** aggregate fields of "appointmentService" */
export type AppointmentService_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<AppointmentService_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "appointmentService" */
export type AppointmentService_Aggregate_Order_By = {
  avg?: InputMaybe<AppointmentService_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<AppointmentService_Max_Order_By>;
  min?: InputMaybe<AppointmentService_Min_Order_By>;
  stddev?: InputMaybe<AppointmentService_Stddev_Order_By>;
  stddev_pop?: InputMaybe<AppointmentService_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<AppointmentService_Stddev_Samp_Order_By>;
  sum?: InputMaybe<AppointmentService_Sum_Order_By>;
  var_pop?: InputMaybe<AppointmentService_Var_Pop_Order_By>;
  var_samp?: InputMaybe<AppointmentService_Var_Samp_Order_By>;
  variance?: InputMaybe<AppointmentService_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "appointmentService" */
export type AppointmentService_Arr_Rel_Insert_Input = {
  data: Array<AppointmentService_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<AppointmentService_On_Conflict>;
};

/** aggregate avg on columns */
export type AppointmentService_Avg_Fields = {
  __typename?: 'appointmentService_avg_fields';
  order?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  serviceDurationMinutes?: Maybe<Scalars['Float']>;
  serviceMinutesOverride?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "appointmentService" */
export type AppointmentService_Avg_Order_By = {
  order?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  serviceDurationMinutes?: InputMaybe<Order_By>;
  serviceMinutesOverride?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "appointmentService". All fields are combined with a logical 'AND'. */
export type AppointmentService_Bool_Exp = {
  _and?: InputMaybe<Array<AppointmentService_Bool_Exp>>;
  _not?: InputMaybe<AppointmentService_Bool_Exp>;
  _or?: InputMaybe<Array<AppointmentService_Bool_Exp>>;
  appointment?: InputMaybe<Appointment_Bool_Exp>;
  appointmentId?: InputMaybe<Uuid_Comparison_Exp>;
  appointmentServiceConsumables?: InputMaybe<AppointmentServiceConsumable_Bool_Exp>;
  appointmentServiceConsumables_aggregate?: InputMaybe<AppointmentServiceConsumable_Aggregate_Bool_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  device?: InputMaybe<Device_Bool_Exp>;
  deviceId?: InputMaybe<Uuid_Comparison_Exp>;
  externalId?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  isArchived?: InputMaybe<Boolean_Comparison_Exp>;
  order?: InputMaybe<Int_Comparison_Exp>;
  provider?: InputMaybe<Provider_Bool_Exp>;
  providerId?: InputMaybe<Uuid_Comparison_Exp>;
  quantity?: InputMaybe<Int_Comparison_Exp>;
  service?: InputMaybe<Service_Bool_Exp>;
  serviceDurationMinutes?: InputMaybe<Int_Comparison_Exp>;
  serviceId?: InputMaybe<Uuid_Comparison_Exp>;
  serviceMinutesOverride?: InputMaybe<Int_Comparison_Exp>;
  timerange?: InputMaybe<Tstzrange_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  workspaceId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "appointmentService" */
export enum AppointmentService_Constraint {
  /** unique or primary key constraint on columns "externalId" */
  AppointmentServiceExternalIdKey = 'appointmentService_externalId_key',
  /** unique or primary key constraint on columns "id" */
  AppointmentServicePkey = 'appointmentService_pkey'
}

/** input type for incrementing numeric columns in table "appointmentService" */
export type AppointmentService_Inc_Input = {
  order?: InputMaybe<Scalars['Int']>;
  quantity?: InputMaybe<Scalars['Int']>;
  serviceDurationMinutes?: InputMaybe<Scalars['Int']>;
  serviceMinutesOverride?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "appointmentService" */
export type AppointmentService_Insert_Input = {
  appointment?: InputMaybe<Appointment_Obj_Rel_Insert_Input>;
  appointmentId?: InputMaybe<Scalars['uuid']>;
  appointmentServiceConsumables?: InputMaybe<AppointmentServiceConsumable_Arr_Rel_Insert_Input>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  device?: InputMaybe<Device_Obj_Rel_Insert_Input>;
  deviceId?: InputMaybe<Scalars['uuid']>;
  externalId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  isArchived?: InputMaybe<Scalars['Boolean']>;
  order?: InputMaybe<Scalars['Int']>;
  provider?: InputMaybe<Provider_Obj_Rel_Insert_Input>;
  providerId?: InputMaybe<Scalars['uuid']>;
  quantity?: InputMaybe<Scalars['Int']>;
  service?: InputMaybe<Service_Obj_Rel_Insert_Input>;
  serviceDurationMinutes?: InputMaybe<Scalars['Int']>;
  serviceId?: InputMaybe<Scalars['uuid']>;
  serviceMinutesOverride?: InputMaybe<Scalars['Int']>;
  timerange?: InputMaybe<Scalars['tstzrange']>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type AppointmentService_Max_Fields = {
  __typename?: 'appointmentService_max_fields';
  appointmentId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  deviceId?: Maybe<Scalars['uuid']>;
  externalId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  order?: Maybe<Scalars['Int']>;
  providerId?: Maybe<Scalars['uuid']>;
  quantity?: Maybe<Scalars['Int']>;
  serviceDurationMinutes?: Maybe<Scalars['Int']>;
  serviceId?: Maybe<Scalars['uuid']>;
  serviceMinutesOverride?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "appointmentService" */
export type AppointmentService_Max_Order_By = {
  appointmentId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  deviceId?: InputMaybe<Order_By>;
  externalId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  providerId?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  serviceDurationMinutes?: InputMaybe<Order_By>;
  serviceId?: InputMaybe<Order_By>;
  serviceMinutesOverride?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type AppointmentService_Min_Fields = {
  __typename?: 'appointmentService_min_fields';
  appointmentId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  deviceId?: Maybe<Scalars['uuid']>;
  externalId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  order?: Maybe<Scalars['Int']>;
  providerId?: Maybe<Scalars['uuid']>;
  quantity?: Maybe<Scalars['Int']>;
  serviceDurationMinutes?: Maybe<Scalars['Int']>;
  serviceId?: Maybe<Scalars['uuid']>;
  serviceMinutesOverride?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "appointmentService" */
export type AppointmentService_Min_Order_By = {
  appointmentId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  deviceId?: InputMaybe<Order_By>;
  externalId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  providerId?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  serviceDurationMinutes?: InputMaybe<Order_By>;
  serviceId?: InputMaybe<Order_By>;
  serviceMinutesOverride?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "appointmentService" */
export type AppointmentService_Mutation_Response = {
  __typename?: 'appointmentService_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<AppointmentService>;
};

/** input type for inserting object relation for remote table "appointmentService" */
export type AppointmentService_Obj_Rel_Insert_Input = {
  data: AppointmentService_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<AppointmentService_On_Conflict>;
};

/** on_conflict condition type for table "appointmentService" */
export type AppointmentService_On_Conflict = {
  constraint: AppointmentService_Constraint;
  update_columns?: Array<AppointmentService_Update_Column>;
  where?: InputMaybe<AppointmentService_Bool_Exp>;
};

/** Ordering options when selecting data from "appointmentService". */
export type AppointmentService_Order_By = {
  appointment?: InputMaybe<Appointment_Order_By>;
  appointmentId?: InputMaybe<Order_By>;
  appointmentServiceConsumables_aggregate?: InputMaybe<AppointmentServiceConsumable_Aggregate_Order_By>;
  createdAt?: InputMaybe<Order_By>;
  device?: InputMaybe<Device_Order_By>;
  deviceId?: InputMaybe<Order_By>;
  externalId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  isArchived?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  provider?: InputMaybe<Provider_Order_By>;
  providerId?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  service?: InputMaybe<Service_Order_By>;
  serviceDurationMinutes?: InputMaybe<Order_By>;
  serviceId?: InputMaybe<Order_By>;
  serviceMinutesOverride?: InputMaybe<Order_By>;
  timerange?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: appointmentService */
export type AppointmentService_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "appointmentService" */
export enum AppointmentService_Select_Column {
  /** column name */
  AppointmentId = 'appointmentId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DeviceId = 'deviceId',
  /** column name */
  ExternalId = 'externalId',
  /** column name */
  Id = 'id',
  /** column name */
  IsArchived = 'isArchived',
  /** column name */
  Order = 'order',
  /** column name */
  ProviderId = 'providerId',
  /** column name */
  Quantity = 'quantity',
  /** column name */
  ServiceDurationMinutes = 'serviceDurationMinutes',
  /** column name */
  ServiceId = 'serviceId',
  /** column name */
  ServiceMinutesOverride = 'serviceMinutesOverride',
  /** column name */
  Timerange = 'timerange',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WorkspaceId = 'workspaceId'
}

/** select "appointmentService_aggregate_bool_exp_bool_and_arguments_columns" columns of table "appointmentService" */
export enum AppointmentService_Select_Column_AppointmentService_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsArchived = 'isArchived'
}

/** select "appointmentService_aggregate_bool_exp_bool_or_arguments_columns" columns of table "appointmentService" */
export enum AppointmentService_Select_Column_AppointmentService_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsArchived = 'isArchived'
}

/** input type for updating data in table "appointmentService" */
export type AppointmentService_Set_Input = {
  appointmentId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  deviceId?: InputMaybe<Scalars['uuid']>;
  externalId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  isArchived?: InputMaybe<Scalars['Boolean']>;
  order?: InputMaybe<Scalars['Int']>;
  providerId?: InputMaybe<Scalars['uuid']>;
  quantity?: InputMaybe<Scalars['Int']>;
  serviceDurationMinutes?: InputMaybe<Scalars['Int']>;
  serviceId?: InputMaybe<Scalars['uuid']>;
  serviceMinutesOverride?: InputMaybe<Scalars['Int']>;
  timerange?: InputMaybe<Scalars['tstzrange']>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type AppointmentService_Stddev_Fields = {
  __typename?: 'appointmentService_stddev_fields';
  order?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  serviceDurationMinutes?: Maybe<Scalars['Float']>;
  serviceMinutesOverride?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "appointmentService" */
export type AppointmentService_Stddev_Order_By = {
  order?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  serviceDurationMinutes?: InputMaybe<Order_By>;
  serviceMinutesOverride?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type AppointmentService_Stddev_Pop_Fields = {
  __typename?: 'appointmentService_stddev_pop_fields';
  order?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  serviceDurationMinutes?: Maybe<Scalars['Float']>;
  serviceMinutesOverride?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "appointmentService" */
export type AppointmentService_Stddev_Pop_Order_By = {
  order?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  serviceDurationMinutes?: InputMaybe<Order_By>;
  serviceMinutesOverride?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type AppointmentService_Stddev_Samp_Fields = {
  __typename?: 'appointmentService_stddev_samp_fields';
  order?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  serviceDurationMinutes?: Maybe<Scalars['Float']>;
  serviceMinutesOverride?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "appointmentService" */
export type AppointmentService_Stddev_Samp_Order_By = {
  order?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  serviceDurationMinutes?: InputMaybe<Order_By>;
  serviceMinutesOverride?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "appointmentService" */
export type AppointmentService_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: AppointmentService_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type AppointmentService_Stream_Cursor_Value_Input = {
  appointmentId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  deviceId?: InputMaybe<Scalars['uuid']>;
  externalId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  isArchived?: InputMaybe<Scalars['Boolean']>;
  order?: InputMaybe<Scalars['Int']>;
  providerId?: InputMaybe<Scalars['uuid']>;
  quantity?: InputMaybe<Scalars['Int']>;
  serviceDurationMinutes?: InputMaybe<Scalars['Int']>;
  serviceId?: InputMaybe<Scalars['uuid']>;
  serviceMinutesOverride?: InputMaybe<Scalars['Int']>;
  timerange?: InputMaybe<Scalars['tstzrange']>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type AppointmentService_Sum_Fields = {
  __typename?: 'appointmentService_sum_fields';
  order?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
  serviceDurationMinutes?: Maybe<Scalars['Int']>;
  serviceMinutesOverride?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "appointmentService" */
export type AppointmentService_Sum_Order_By = {
  order?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  serviceDurationMinutes?: InputMaybe<Order_By>;
  serviceMinutesOverride?: InputMaybe<Order_By>;
};

/** update columns of table "appointmentService" */
export enum AppointmentService_Update_Column {
  /** column name */
  AppointmentId = 'appointmentId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DeviceId = 'deviceId',
  /** column name */
  ExternalId = 'externalId',
  /** column name */
  Id = 'id',
  /** column name */
  IsArchived = 'isArchived',
  /** column name */
  Order = 'order',
  /** column name */
  ProviderId = 'providerId',
  /** column name */
  Quantity = 'quantity',
  /** column name */
  ServiceDurationMinutes = 'serviceDurationMinutes',
  /** column name */
  ServiceId = 'serviceId',
  /** column name */
  ServiceMinutesOverride = 'serviceMinutesOverride',
  /** column name */
  Timerange = 'timerange',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WorkspaceId = 'workspaceId'
}

export type AppointmentService_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<AppointmentService_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AppointmentService_Set_Input>;
  /** filter the rows which have to be updated */
  where: AppointmentService_Bool_Exp;
};

/** aggregate var_pop on columns */
export type AppointmentService_Var_Pop_Fields = {
  __typename?: 'appointmentService_var_pop_fields';
  order?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  serviceDurationMinutes?: Maybe<Scalars['Float']>;
  serviceMinutesOverride?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "appointmentService" */
export type AppointmentService_Var_Pop_Order_By = {
  order?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  serviceDurationMinutes?: InputMaybe<Order_By>;
  serviceMinutesOverride?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type AppointmentService_Var_Samp_Fields = {
  __typename?: 'appointmentService_var_samp_fields';
  order?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  serviceDurationMinutes?: Maybe<Scalars['Float']>;
  serviceMinutesOverride?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "appointmentService" */
export type AppointmentService_Var_Samp_Order_By = {
  order?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  serviceDurationMinutes?: InputMaybe<Order_By>;
  serviceMinutesOverride?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type AppointmentService_Variance_Fields = {
  __typename?: 'appointmentService_variance_fields';
  order?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  serviceDurationMinutes?: Maybe<Scalars['Float']>;
  serviceMinutesOverride?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "appointmentService" */
export type AppointmentService_Variance_Order_By = {
  order?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  serviceDurationMinutes?: InputMaybe<Order_By>;
  serviceMinutesOverride?: InputMaybe<Order_By>;
};

/** columns and relationships of "appointmentType" */
export type AppointmentType = {
  __typename?: 'appointmentType';
  color?: Maybe<Scalars['String']>;
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  maxPerDay?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  workspaceId: Scalars['uuid'];
};

/** aggregated selection of "appointmentType" */
export type AppointmentType_Aggregate = {
  __typename?: 'appointmentType_aggregate';
  aggregate?: Maybe<AppointmentType_Aggregate_Fields>;
  nodes: Array<AppointmentType>;
};

/** aggregate fields of "appointmentType" */
export type AppointmentType_Aggregate_Fields = {
  __typename?: 'appointmentType_aggregate_fields';
  avg?: Maybe<AppointmentType_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<AppointmentType_Max_Fields>;
  min?: Maybe<AppointmentType_Min_Fields>;
  stddev?: Maybe<AppointmentType_Stddev_Fields>;
  stddev_pop?: Maybe<AppointmentType_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<AppointmentType_Stddev_Samp_Fields>;
  sum?: Maybe<AppointmentType_Sum_Fields>;
  var_pop?: Maybe<AppointmentType_Var_Pop_Fields>;
  var_samp?: Maybe<AppointmentType_Var_Samp_Fields>;
  variance?: Maybe<AppointmentType_Variance_Fields>;
};


/** aggregate fields of "appointmentType" */
export type AppointmentType_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<AppointmentType_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type AppointmentType_Avg_Fields = {
  __typename?: 'appointmentType_avg_fields';
  maxPerDay?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "appointmentType". All fields are combined with a logical 'AND'. */
export type AppointmentType_Bool_Exp = {
  _and?: InputMaybe<Array<AppointmentType_Bool_Exp>>;
  _not?: InputMaybe<AppointmentType_Bool_Exp>;
  _or?: InputMaybe<Array<AppointmentType_Bool_Exp>>;
  color?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  maxPerDay?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  workspaceId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "appointmentType" */
export enum AppointmentType_Constraint {
  /** unique or primary key constraint on columns "workspaceId", "name" */
  AppointmentTypeNameWorkspaceIdKey = 'appointmentType_name_workspaceId_key',
  /** unique or primary key constraint on columns "id" */
  AppointmentTypePkey = 'appointmentType_pkey'
}

/** input type for incrementing numeric columns in table "appointmentType" */
export type AppointmentType_Inc_Input = {
  maxPerDay?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "appointmentType" */
export type AppointmentType_Insert_Input = {
  color?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  maxPerDay?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type AppointmentType_Max_Fields = {
  __typename?: 'appointmentType_max_fields';
  color?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  maxPerDay?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type AppointmentType_Min_Fields = {
  __typename?: 'appointmentType_min_fields';
  color?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  maxPerDay?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "appointmentType" */
export type AppointmentType_Mutation_Response = {
  __typename?: 'appointmentType_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<AppointmentType>;
};

/** input type for inserting object relation for remote table "appointmentType" */
export type AppointmentType_Obj_Rel_Insert_Input = {
  data: AppointmentType_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<AppointmentType_On_Conflict>;
};

/** on_conflict condition type for table "appointmentType" */
export type AppointmentType_On_Conflict = {
  constraint: AppointmentType_Constraint;
  update_columns?: Array<AppointmentType_Update_Column>;
  where?: InputMaybe<AppointmentType_Bool_Exp>;
};

/** Ordering options when selecting data from "appointmentType". */
export type AppointmentType_Order_By = {
  color?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  maxPerDay?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: appointmentType */
export type AppointmentType_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "appointmentType" */
export enum AppointmentType_Select_Column {
  /** column name */
  Color = 'color',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  MaxPerDay = 'maxPerDay',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WorkspaceId = 'workspaceId'
}

/** input type for updating data in table "appointmentType" */
export type AppointmentType_Set_Input = {
  color?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  maxPerDay?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type AppointmentType_Stddev_Fields = {
  __typename?: 'appointmentType_stddev_fields';
  maxPerDay?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type AppointmentType_Stddev_Pop_Fields = {
  __typename?: 'appointmentType_stddev_pop_fields';
  maxPerDay?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type AppointmentType_Stddev_Samp_Fields = {
  __typename?: 'appointmentType_stddev_samp_fields';
  maxPerDay?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "appointmentType" */
export type AppointmentType_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: AppointmentType_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type AppointmentType_Stream_Cursor_Value_Input = {
  color?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  maxPerDay?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type AppointmentType_Sum_Fields = {
  __typename?: 'appointmentType_sum_fields';
  maxPerDay?: Maybe<Scalars['Int']>;
};

/** update columns of table "appointmentType" */
export enum AppointmentType_Update_Column {
  /** column name */
  Color = 'color',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  MaxPerDay = 'maxPerDay',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WorkspaceId = 'workspaceId'
}

export type AppointmentType_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<AppointmentType_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AppointmentType_Set_Input>;
  /** filter the rows which have to be updated */
  where: AppointmentType_Bool_Exp;
};

/** aggregate var_pop on columns */
export type AppointmentType_Var_Pop_Fields = {
  __typename?: 'appointmentType_var_pop_fields';
  maxPerDay?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type AppointmentType_Var_Samp_Fields = {
  __typename?: 'appointmentType_var_samp_fields';
  maxPerDay?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type AppointmentType_Variance_Fields = {
  __typename?: 'appointmentType_variance_fields';
  maxPerDay?: Maybe<Scalars['Float']>;
};

/** aggregated selection of "appointment" */
export type Appointment_Aggregate = {
  __typename?: 'appointment_aggregate';
  aggregate?: Maybe<Appointment_Aggregate_Fields>;
  nodes: Array<Appointment>;
};

export type Appointment_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Appointment_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Appointment_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Appointment_Aggregate_Bool_Exp_Count>;
};

export type Appointment_Aggregate_Bool_Exp_Bool_And = {
  arguments: Appointment_Select_Column_Appointment_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Appointment_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Appointment_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Appointment_Select_Column_Appointment_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Appointment_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Appointment_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Appointment_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Appointment_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "appointment" */
export type Appointment_Aggregate_Fields = {
  __typename?: 'appointment_aggregate_fields';
  avg?: Maybe<Appointment_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Appointment_Max_Fields>;
  min?: Maybe<Appointment_Min_Fields>;
  stddev?: Maybe<Appointment_Stddev_Fields>;
  stddev_pop?: Maybe<Appointment_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Appointment_Stddev_Samp_Fields>;
  sum?: Maybe<Appointment_Sum_Fields>;
  var_pop?: Maybe<Appointment_Var_Pop_Fields>;
  var_samp?: Maybe<Appointment_Var_Samp_Fields>;
  variance?: Maybe<Appointment_Variance_Fields>;
};


/** aggregate fields of "appointment" */
export type Appointment_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Appointment_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "appointment" */
export type Appointment_Aggregate_Order_By = {
  avg?: InputMaybe<Appointment_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Appointment_Max_Order_By>;
  min?: InputMaybe<Appointment_Min_Order_By>;
  stddev?: InputMaybe<Appointment_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Appointment_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Appointment_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Appointment_Sum_Order_By>;
  var_pop?: InputMaybe<Appointment_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Appointment_Var_Samp_Order_By>;
  variance?: InputMaybe<Appointment_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Appointment_Append_Input = {
  recurringExceptionDates?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "appointment" */
export type Appointment_Arr_Rel_Insert_Input = {
  data: Array<Appointment_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Appointment_On_Conflict>;
};

/** aggregate avg on columns */
export type Appointment_Avg_Fields = {
  __typename?: 'appointment_avg_fields';
  approvedNoShowFeeAmount?: Maybe<Scalars['Float']>;
  durationInSeconds?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "appointment" */
export type Appointment_Avg_Order_By = {
  approvedNoShowFeeAmount?: InputMaybe<Order_By>;
  durationInSeconds?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "appointment". All fields are combined with a logical 'AND'. */
export type Appointment_Bool_Exp = {
  _and?: InputMaybe<Array<Appointment_Bool_Exp>>;
  _not?: InputMaybe<Appointment_Bool_Exp>;
  _or?: InputMaybe<Array<Appointment_Bool_Exp>>;
  appointmentConsents?: InputMaybe<AppointmentConsent_Bool_Exp>;
  appointmentConsents_aggregate?: InputMaybe<AppointmentConsent_Aggregate_Bool_Exp>;
  appointmentCustomForms?: InputMaybe<AppointmentCustomForm_Bool_Exp>;
  appointmentCustomForms_aggregate?: InputMaybe<AppointmentCustomForm_Aggregate_Bool_Exp>;
  appointmentServices?: InputMaybe<AppointmentService_Bool_Exp>;
  appointmentServices_aggregate?: InputMaybe<AppointmentService_Aggregate_Bool_Exp>;
  appointmentType?: InputMaybe<AppointmentType_Bool_Exp>;
  appointmentTypeId?: InputMaybe<Uuid_Comparison_Exp>;
  approvedNoShowFeeAmount?: InputMaybe<Int_Comparison_Exp>;
  clinicalMessageStatuses?: InputMaybe<ClinicalMessageStatus_Bool_Exp>;
  clinicalMessageStatuses_aggregate?: InputMaybe<ClinicalMessageStatus_Aggregate_Bool_Exp>;
  color?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  customFormResponses?: InputMaybe<CustomFormResponse_Bool_Exp>;
  customFormResponses_aggregate?: InputMaybe<CustomFormResponse_Aggregate_Bool_Exp>;
  disableConfirmationsReminders?: InputMaybe<Boolean_Comparison_Exp>;
  documents?: InputMaybe<Document_Bool_Exp>;
  documents_aggregate?: InputMaybe<Document_Aggregate_Bool_Exp>;
  durationInSeconds?: InputMaybe<Int_Comparison_Exp>;
  endTime?: InputMaybe<Timestamptz_Comparison_Exp>;
  externalId?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  importJobId?: InputMaybe<Uuid_Comparison_Exp>;
  internalAppointmentProviders?: InputMaybe<InternalAppointmentProvider_Bool_Exp>;
  internalAppointmentProviders_aggregate?: InputMaybe<InternalAppointmentProvider_Aggregate_Bool_Exp>;
  invoices?: InputMaybe<Invoice_Bool_Exp>;
  invoices_aggregate?: InputMaybe<Invoice_Aggregate_Bool_Exp>;
  isArchived?: InputMaybe<Boolean_Comparison_Exp>;
  isDraft?: InputMaybe<Boolean_Comparison_Exp>;
  isFirstAppointmentWithProvider?: InputMaybe<Boolean_Comparison_Exp>;
  isGoogleEvent?: InputMaybe<Boolean_Comparison_Exp>;
  isMembershipActive?: InputMaybe<Boolean_Comparison_Exp>;
  isMembershipTypeSubscription?: InputMaybe<Boolean_Comparison_Exp>;
  isNewPatient?: InputMaybe<Boolean_Comparison_Exp>;
  isRecurring?: InputMaybe<Boolean_Comparison_Exp>;
  location?: InputMaybe<Location_Bool_Exp>;
  locationId?: InputMaybe<Uuid_Comparison_Exp>;
  noShowFeePaymentMethodId?: InputMaybe<Uuid_Comparison_Exp>;
  noShowFeeStatus?: InputMaybe<String_Comparison_Exp>;
  note?: InputMaybe<String_Comparison_Exp>;
  notes?: InputMaybe<Note_Bool_Exp>;
  notes_aggregate?: InputMaybe<Note_Aggregate_Bool_Exp>;
  patient?: InputMaybe<Patient_Bool_Exp>;
  patientConsents?: InputMaybe<PatientConsent_Bool_Exp>;
  patientConsents_aggregate?: InputMaybe<PatientConsent_Aggregate_Bool_Exp>;
  patientId?: InputMaybe<Uuid_Comparison_Exp>;
  paymentStatus?: InputMaybe<String_Comparison_Exp>;
  photos?: InputMaybe<Photo_Bool_Exp>;
  photos_aggregate?: InputMaybe<Photo_Aggregate_Bool_Exp>;
  provider?: InputMaybe<Provider_Bool_Exp>;
  providerId?: InputMaybe<Uuid_Comparison_Exp>;
  recurringEndDate?: InputMaybe<Date_Comparison_Exp>;
  recurringExceptionDates?: InputMaybe<Jsonb_Comparison_Exp>;
  recurringExceptionRule?: InputMaybe<String_Comparison_Exp>;
  recurringRule?: InputMaybe<String_Comparison_Exp>;
  recurringStartDate?: InputMaybe<Date_Comparison_Exp>;
  room?: InputMaybe<Room_Bool_Exp>;
  roomId?: InputMaybe<Uuid_Comparison_Exp>;
  startTime?: InputMaybe<Timestamptz_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  timerange?: InputMaybe<Tstzrange_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  workspace?: InputMaybe<Workspace_Bool_Exp>;
  workspaceId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "appointment" */
export enum Appointment_Constraint {
  /** unique or primary key constraint on columns "id" */
  AppointmentPkey = 'appointment_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Appointment_Delete_At_Path_Input = {
  recurringExceptionDates?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Appointment_Delete_Elem_Input = {
  recurringExceptionDates?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Appointment_Delete_Key_Input = {
  recurringExceptionDates?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "appointment" */
export type Appointment_Inc_Input = {
  approvedNoShowFeeAmount?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "appointment" */
export type Appointment_Insert_Input = {
  appointmentConsents?: InputMaybe<AppointmentConsent_Arr_Rel_Insert_Input>;
  appointmentCustomForms?: InputMaybe<AppointmentCustomForm_Arr_Rel_Insert_Input>;
  appointmentServices?: InputMaybe<AppointmentService_Arr_Rel_Insert_Input>;
  appointmentType?: InputMaybe<AppointmentType_Obj_Rel_Insert_Input>;
  appointmentTypeId?: InputMaybe<Scalars['uuid']>;
  approvedNoShowFeeAmount?: InputMaybe<Scalars['Int']>;
  clinicalMessageStatuses?: InputMaybe<ClinicalMessageStatus_Arr_Rel_Insert_Input>;
  color?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  customFormResponses?: InputMaybe<CustomFormResponse_Arr_Rel_Insert_Input>;
  disableConfirmationsReminders?: InputMaybe<Scalars['Boolean']>;
  documents?: InputMaybe<Document_Arr_Rel_Insert_Input>;
  externalId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  importJobId?: InputMaybe<Scalars['uuid']>;
  internalAppointmentProviders?: InputMaybe<InternalAppointmentProvider_Arr_Rel_Insert_Input>;
  invoices?: InputMaybe<Invoice_Arr_Rel_Insert_Input>;
  isArchived?: InputMaybe<Scalars['Boolean']>;
  isDraft?: InputMaybe<Scalars['Boolean']>;
  isGoogleEvent?: InputMaybe<Scalars['Boolean']>;
  isRecurring?: InputMaybe<Scalars['Boolean']>;
  location?: InputMaybe<Location_Obj_Rel_Insert_Input>;
  locationId?: InputMaybe<Scalars['uuid']>;
  noShowFeePaymentMethodId?: InputMaybe<Scalars['uuid']>;
  noShowFeeStatus?: InputMaybe<Scalars['String']>;
  note?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Note_Arr_Rel_Insert_Input>;
  patient?: InputMaybe<Patient_Obj_Rel_Insert_Input>;
  patientConsents?: InputMaybe<PatientConsent_Arr_Rel_Insert_Input>;
  patientId?: InputMaybe<Scalars['uuid']>;
  paymentStatus?: InputMaybe<Scalars['String']>;
  photos?: InputMaybe<Photo_Arr_Rel_Insert_Input>;
  provider?: InputMaybe<Provider_Obj_Rel_Insert_Input>;
  providerId?: InputMaybe<Scalars['uuid']>;
  recurringEndDate?: InputMaybe<Scalars['date']>;
  recurringExceptionDates?: InputMaybe<Scalars['jsonb']>;
  recurringExceptionRule?: InputMaybe<Scalars['String']>;
  recurringRule?: InputMaybe<Scalars['String']>;
  recurringStartDate?: InputMaybe<Scalars['date']>;
  room?: InputMaybe<Room_Obj_Rel_Insert_Input>;
  roomId?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Scalars['String']>;
  timerange?: InputMaybe<Scalars['tstzrange']>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspace?: InputMaybe<Workspace_Obj_Rel_Insert_Input>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Appointment_Max_Fields = {
  __typename?: 'appointment_max_fields';
  appointmentTypeId?: Maybe<Scalars['uuid']>;
  approvedNoShowFeeAmount?: Maybe<Scalars['Int']>;
  color?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  durationInSeconds?: Maybe<Scalars['Int']>;
  endTime?: Maybe<Scalars['timestamptz']>;
  externalId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  importJobId?: Maybe<Scalars['uuid']>;
  locationId?: Maybe<Scalars['uuid']>;
  noShowFeePaymentMethodId?: Maybe<Scalars['uuid']>;
  noShowFeeStatus?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  patientId?: Maybe<Scalars['uuid']>;
  paymentStatus?: Maybe<Scalars['String']>;
  providerId?: Maybe<Scalars['uuid']>;
  recurringEndDate?: Maybe<Scalars['date']>;
  recurringExceptionRule?: Maybe<Scalars['String']>;
  recurringRule?: Maybe<Scalars['String']>;
  recurringStartDate?: Maybe<Scalars['date']>;
  roomId?: Maybe<Scalars['uuid']>;
  startTime?: Maybe<Scalars['timestamptz']>;
  status?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "appointment" */
export type Appointment_Max_Order_By = {
  appointmentTypeId?: InputMaybe<Order_By>;
  approvedNoShowFeeAmount?: InputMaybe<Order_By>;
  color?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  durationInSeconds?: InputMaybe<Order_By>;
  endTime?: InputMaybe<Order_By>;
  externalId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  importJobId?: InputMaybe<Order_By>;
  locationId?: InputMaybe<Order_By>;
  noShowFeePaymentMethodId?: InputMaybe<Order_By>;
  noShowFeeStatus?: InputMaybe<Order_By>;
  note?: InputMaybe<Order_By>;
  patientId?: InputMaybe<Order_By>;
  paymentStatus?: InputMaybe<Order_By>;
  providerId?: InputMaybe<Order_By>;
  recurringEndDate?: InputMaybe<Order_By>;
  recurringExceptionRule?: InputMaybe<Order_By>;
  recurringRule?: InputMaybe<Order_By>;
  recurringStartDate?: InputMaybe<Order_By>;
  roomId?: InputMaybe<Order_By>;
  startTime?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Appointment_Min_Fields = {
  __typename?: 'appointment_min_fields';
  appointmentTypeId?: Maybe<Scalars['uuid']>;
  approvedNoShowFeeAmount?: Maybe<Scalars['Int']>;
  color?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  durationInSeconds?: Maybe<Scalars['Int']>;
  endTime?: Maybe<Scalars['timestamptz']>;
  externalId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  importJobId?: Maybe<Scalars['uuid']>;
  locationId?: Maybe<Scalars['uuid']>;
  noShowFeePaymentMethodId?: Maybe<Scalars['uuid']>;
  noShowFeeStatus?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  patientId?: Maybe<Scalars['uuid']>;
  paymentStatus?: Maybe<Scalars['String']>;
  providerId?: Maybe<Scalars['uuid']>;
  recurringEndDate?: Maybe<Scalars['date']>;
  recurringExceptionRule?: Maybe<Scalars['String']>;
  recurringRule?: Maybe<Scalars['String']>;
  recurringStartDate?: Maybe<Scalars['date']>;
  roomId?: Maybe<Scalars['uuid']>;
  startTime?: Maybe<Scalars['timestamptz']>;
  status?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "appointment" */
export type Appointment_Min_Order_By = {
  appointmentTypeId?: InputMaybe<Order_By>;
  approvedNoShowFeeAmount?: InputMaybe<Order_By>;
  color?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  durationInSeconds?: InputMaybe<Order_By>;
  endTime?: InputMaybe<Order_By>;
  externalId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  importJobId?: InputMaybe<Order_By>;
  locationId?: InputMaybe<Order_By>;
  noShowFeePaymentMethodId?: InputMaybe<Order_By>;
  noShowFeeStatus?: InputMaybe<Order_By>;
  note?: InputMaybe<Order_By>;
  patientId?: InputMaybe<Order_By>;
  paymentStatus?: InputMaybe<Order_By>;
  providerId?: InputMaybe<Order_By>;
  recurringEndDate?: InputMaybe<Order_By>;
  recurringExceptionRule?: InputMaybe<Order_By>;
  recurringRule?: InputMaybe<Order_By>;
  recurringStartDate?: InputMaybe<Order_By>;
  roomId?: InputMaybe<Order_By>;
  startTime?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "appointment" */
export type Appointment_Mutation_Response = {
  __typename?: 'appointment_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Appointment>;
};

/** input type for inserting object relation for remote table "appointment" */
export type Appointment_Obj_Rel_Insert_Input = {
  data: Appointment_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Appointment_On_Conflict>;
};

/** on_conflict condition type for table "appointment" */
export type Appointment_On_Conflict = {
  constraint: Appointment_Constraint;
  update_columns?: Array<Appointment_Update_Column>;
  where?: InputMaybe<Appointment_Bool_Exp>;
};

/** Ordering options when selecting data from "appointment". */
export type Appointment_Order_By = {
  appointmentConsents_aggregate?: InputMaybe<AppointmentConsent_Aggregate_Order_By>;
  appointmentCustomForms_aggregate?: InputMaybe<AppointmentCustomForm_Aggregate_Order_By>;
  appointmentServices_aggregate?: InputMaybe<AppointmentService_Aggregate_Order_By>;
  appointmentType?: InputMaybe<AppointmentType_Order_By>;
  appointmentTypeId?: InputMaybe<Order_By>;
  approvedNoShowFeeAmount?: InputMaybe<Order_By>;
  clinicalMessageStatuses_aggregate?: InputMaybe<ClinicalMessageStatus_Aggregate_Order_By>;
  color?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  customFormResponses_aggregate?: InputMaybe<CustomFormResponse_Aggregate_Order_By>;
  disableConfirmationsReminders?: InputMaybe<Order_By>;
  documents_aggregate?: InputMaybe<Document_Aggregate_Order_By>;
  durationInSeconds?: InputMaybe<Order_By>;
  endTime?: InputMaybe<Order_By>;
  externalId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  importJobId?: InputMaybe<Order_By>;
  internalAppointmentProviders_aggregate?: InputMaybe<InternalAppointmentProvider_Aggregate_Order_By>;
  invoices_aggregate?: InputMaybe<Invoice_Aggregate_Order_By>;
  isArchived?: InputMaybe<Order_By>;
  isDraft?: InputMaybe<Order_By>;
  isFirstAppointmentWithProvider?: InputMaybe<Order_By>;
  isGoogleEvent?: InputMaybe<Order_By>;
  isMembershipActive?: InputMaybe<Order_By>;
  isMembershipTypeSubscription?: InputMaybe<Order_By>;
  isNewPatient?: InputMaybe<Order_By>;
  isRecurring?: InputMaybe<Order_By>;
  location?: InputMaybe<Location_Order_By>;
  locationId?: InputMaybe<Order_By>;
  noShowFeePaymentMethodId?: InputMaybe<Order_By>;
  noShowFeeStatus?: InputMaybe<Order_By>;
  note?: InputMaybe<Order_By>;
  notes_aggregate?: InputMaybe<Note_Aggregate_Order_By>;
  patient?: InputMaybe<Patient_Order_By>;
  patientConsents_aggregate?: InputMaybe<PatientConsent_Aggregate_Order_By>;
  patientId?: InputMaybe<Order_By>;
  paymentStatus?: InputMaybe<Order_By>;
  photos_aggregate?: InputMaybe<Photo_Aggregate_Order_By>;
  provider?: InputMaybe<Provider_Order_By>;
  providerId?: InputMaybe<Order_By>;
  recurringEndDate?: InputMaybe<Order_By>;
  recurringExceptionDates?: InputMaybe<Order_By>;
  recurringExceptionRule?: InputMaybe<Order_By>;
  recurringRule?: InputMaybe<Order_By>;
  recurringStartDate?: InputMaybe<Order_By>;
  room?: InputMaybe<Room_Order_By>;
  roomId?: InputMaybe<Order_By>;
  startTime?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  timerange?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  workspace?: InputMaybe<Workspace_Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: appointment */
export type Appointment_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Appointment_Prepend_Input = {
  recurringExceptionDates?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "appointment" */
export enum Appointment_Select_Column {
  /** column name */
  AppointmentTypeId = 'appointmentTypeId',
  /** column name */
  ApprovedNoShowFeeAmount = 'approvedNoShowFeeAmount',
  /** column name */
  Color = 'color',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DisableConfirmationsReminders = 'disableConfirmationsReminders',
  /** column name */
  DurationInSeconds = 'durationInSeconds',
  /** column name */
  EndTime = 'endTime',
  /** column name */
  ExternalId = 'externalId',
  /** column name */
  Id = 'id',
  /** column name */
  ImportJobId = 'importJobId',
  /** column name */
  IsArchived = 'isArchived',
  /** column name */
  IsDraft = 'isDraft',
  /** column name */
  IsGoogleEvent = 'isGoogleEvent',
  /** column name */
  IsRecurring = 'isRecurring',
  /** column name */
  LocationId = 'locationId',
  /** column name */
  NoShowFeePaymentMethodId = 'noShowFeePaymentMethodId',
  /** column name */
  NoShowFeeStatus = 'noShowFeeStatus',
  /** column name */
  Note = 'note',
  /** column name */
  PatientId = 'patientId',
  /** column name */
  PaymentStatus = 'paymentStatus',
  /** column name */
  ProviderId = 'providerId',
  /** column name */
  RecurringEndDate = 'recurringEndDate',
  /** column name */
  RecurringExceptionDates = 'recurringExceptionDates',
  /** column name */
  RecurringExceptionRule = 'recurringExceptionRule',
  /** column name */
  RecurringRule = 'recurringRule',
  /** column name */
  RecurringStartDate = 'recurringStartDate',
  /** column name */
  RoomId = 'roomId',
  /** column name */
  StartTime = 'startTime',
  /** column name */
  Status = 'status',
  /** column name */
  Timerange = 'timerange',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WorkspaceId = 'workspaceId'
}

/** select "appointment_aggregate_bool_exp_bool_and_arguments_columns" columns of table "appointment" */
export enum Appointment_Select_Column_Appointment_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  DisableConfirmationsReminders = 'disableConfirmationsReminders',
  /** column name */
  IsArchived = 'isArchived',
  /** column name */
  IsDraft = 'isDraft',
  /** column name */
  IsGoogleEvent = 'isGoogleEvent',
  /** column name */
  IsRecurring = 'isRecurring'
}

/** select "appointment_aggregate_bool_exp_bool_or_arguments_columns" columns of table "appointment" */
export enum Appointment_Select_Column_Appointment_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  DisableConfirmationsReminders = 'disableConfirmationsReminders',
  /** column name */
  IsArchived = 'isArchived',
  /** column name */
  IsDraft = 'isDraft',
  /** column name */
  IsGoogleEvent = 'isGoogleEvent',
  /** column name */
  IsRecurring = 'isRecurring'
}

/** input type for updating data in table "appointment" */
export type Appointment_Set_Input = {
  appointmentTypeId?: InputMaybe<Scalars['uuid']>;
  approvedNoShowFeeAmount?: InputMaybe<Scalars['Int']>;
  color?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  disableConfirmationsReminders?: InputMaybe<Scalars['Boolean']>;
  externalId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  importJobId?: InputMaybe<Scalars['uuid']>;
  isArchived?: InputMaybe<Scalars['Boolean']>;
  isDraft?: InputMaybe<Scalars['Boolean']>;
  isGoogleEvent?: InputMaybe<Scalars['Boolean']>;
  isRecurring?: InputMaybe<Scalars['Boolean']>;
  locationId?: InputMaybe<Scalars['uuid']>;
  noShowFeePaymentMethodId?: InputMaybe<Scalars['uuid']>;
  noShowFeeStatus?: InputMaybe<Scalars['String']>;
  note?: InputMaybe<Scalars['String']>;
  patientId?: InputMaybe<Scalars['uuid']>;
  paymentStatus?: InputMaybe<Scalars['String']>;
  providerId?: InputMaybe<Scalars['uuid']>;
  recurringEndDate?: InputMaybe<Scalars['date']>;
  recurringExceptionDates?: InputMaybe<Scalars['jsonb']>;
  recurringExceptionRule?: InputMaybe<Scalars['String']>;
  recurringRule?: InputMaybe<Scalars['String']>;
  recurringStartDate?: InputMaybe<Scalars['date']>;
  roomId?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Scalars['String']>;
  timerange?: InputMaybe<Scalars['tstzrange']>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Appointment_Stddev_Fields = {
  __typename?: 'appointment_stddev_fields';
  approvedNoShowFeeAmount?: Maybe<Scalars['Float']>;
  durationInSeconds?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "appointment" */
export type Appointment_Stddev_Order_By = {
  approvedNoShowFeeAmount?: InputMaybe<Order_By>;
  durationInSeconds?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Appointment_Stddev_Pop_Fields = {
  __typename?: 'appointment_stddev_pop_fields';
  approvedNoShowFeeAmount?: Maybe<Scalars['Float']>;
  durationInSeconds?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "appointment" */
export type Appointment_Stddev_Pop_Order_By = {
  approvedNoShowFeeAmount?: InputMaybe<Order_By>;
  durationInSeconds?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Appointment_Stddev_Samp_Fields = {
  __typename?: 'appointment_stddev_samp_fields';
  approvedNoShowFeeAmount?: Maybe<Scalars['Float']>;
  durationInSeconds?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "appointment" */
export type Appointment_Stddev_Samp_Order_By = {
  approvedNoShowFeeAmount?: InputMaybe<Order_By>;
  durationInSeconds?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "appointment" */
export type Appointment_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Appointment_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Appointment_Stream_Cursor_Value_Input = {
  appointmentTypeId?: InputMaybe<Scalars['uuid']>;
  approvedNoShowFeeAmount?: InputMaybe<Scalars['Int']>;
  color?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  disableConfirmationsReminders?: InputMaybe<Scalars['Boolean']>;
  durationInSeconds?: InputMaybe<Scalars['Int']>;
  endTime?: InputMaybe<Scalars['timestamptz']>;
  externalId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  importJobId?: InputMaybe<Scalars['uuid']>;
  isArchived?: InputMaybe<Scalars['Boolean']>;
  isDraft?: InputMaybe<Scalars['Boolean']>;
  isGoogleEvent?: InputMaybe<Scalars['Boolean']>;
  isRecurring?: InputMaybe<Scalars['Boolean']>;
  locationId?: InputMaybe<Scalars['uuid']>;
  noShowFeePaymentMethodId?: InputMaybe<Scalars['uuid']>;
  noShowFeeStatus?: InputMaybe<Scalars['String']>;
  note?: InputMaybe<Scalars['String']>;
  patientId?: InputMaybe<Scalars['uuid']>;
  paymentStatus?: InputMaybe<Scalars['String']>;
  providerId?: InputMaybe<Scalars['uuid']>;
  recurringEndDate?: InputMaybe<Scalars['date']>;
  recurringExceptionDates?: InputMaybe<Scalars['jsonb']>;
  recurringExceptionRule?: InputMaybe<Scalars['String']>;
  recurringRule?: InputMaybe<Scalars['String']>;
  recurringStartDate?: InputMaybe<Scalars['date']>;
  roomId?: InputMaybe<Scalars['uuid']>;
  startTime?: InputMaybe<Scalars['timestamptz']>;
  status?: InputMaybe<Scalars['String']>;
  timerange?: InputMaybe<Scalars['tstzrange']>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Appointment_Sum_Fields = {
  __typename?: 'appointment_sum_fields';
  approvedNoShowFeeAmount?: Maybe<Scalars['Int']>;
  durationInSeconds?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "appointment" */
export type Appointment_Sum_Order_By = {
  approvedNoShowFeeAmount?: InputMaybe<Order_By>;
  durationInSeconds?: InputMaybe<Order_By>;
};

/** update columns of table "appointment" */
export enum Appointment_Update_Column {
  /** column name */
  AppointmentTypeId = 'appointmentTypeId',
  /** column name */
  ApprovedNoShowFeeAmount = 'approvedNoShowFeeAmount',
  /** column name */
  Color = 'color',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DisableConfirmationsReminders = 'disableConfirmationsReminders',
  /** column name */
  ExternalId = 'externalId',
  /** column name */
  Id = 'id',
  /** column name */
  ImportJobId = 'importJobId',
  /** column name */
  IsArchived = 'isArchived',
  /** column name */
  IsDraft = 'isDraft',
  /** column name */
  IsGoogleEvent = 'isGoogleEvent',
  /** column name */
  IsRecurring = 'isRecurring',
  /** column name */
  LocationId = 'locationId',
  /** column name */
  NoShowFeePaymentMethodId = 'noShowFeePaymentMethodId',
  /** column name */
  NoShowFeeStatus = 'noShowFeeStatus',
  /** column name */
  Note = 'note',
  /** column name */
  PatientId = 'patientId',
  /** column name */
  PaymentStatus = 'paymentStatus',
  /** column name */
  ProviderId = 'providerId',
  /** column name */
  RecurringEndDate = 'recurringEndDate',
  /** column name */
  RecurringExceptionDates = 'recurringExceptionDates',
  /** column name */
  RecurringExceptionRule = 'recurringExceptionRule',
  /** column name */
  RecurringRule = 'recurringRule',
  /** column name */
  RecurringStartDate = 'recurringStartDate',
  /** column name */
  RoomId = 'roomId',
  /** column name */
  Status = 'status',
  /** column name */
  Timerange = 'timerange',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WorkspaceId = 'workspaceId'
}

export type Appointment_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Appointment_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Appointment_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Appointment_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Appointment_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Appointment_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Appointment_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Appointment_Set_Input>;
  /** filter the rows which have to be updated */
  where: Appointment_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Appointment_Var_Pop_Fields = {
  __typename?: 'appointment_var_pop_fields';
  approvedNoShowFeeAmount?: Maybe<Scalars['Float']>;
  durationInSeconds?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "appointment" */
export type Appointment_Var_Pop_Order_By = {
  approvedNoShowFeeAmount?: InputMaybe<Order_By>;
  durationInSeconds?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Appointment_Var_Samp_Fields = {
  __typename?: 'appointment_var_samp_fields';
  approvedNoShowFeeAmount?: Maybe<Scalars['Float']>;
  durationInSeconds?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "appointment" */
export type Appointment_Var_Samp_Order_By = {
  approvedNoShowFeeAmount?: InputMaybe<Order_By>;
  durationInSeconds?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Appointment_Variance_Fields = {
  __typename?: 'appointment_variance_fields';
  approvedNoShowFeeAmount?: Maybe<Scalars['Float']>;
  durationInSeconds?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "appointment" */
export type Appointment_Variance_Order_By = {
  approvedNoShowFeeAmount?: InputMaybe<Order_By>;
  durationInSeconds?: InputMaybe<Order_By>;
};

/** columns and relationships of "audience" */
export type Audience = {
  __typename?: 'audience';
  createdAt: Scalars['timestamptz'];
  definition: Scalars['jsonb'];
  id: Scalars['uuid'];
  name?: Maybe<Scalars['String']>;
  snapshot: Scalars['jsonb'];
  type: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  workspaceId: Scalars['uuid'];
};


/** columns and relationships of "audience" */
export type AudienceDefinitionArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "audience" */
export type AudienceSnapshotArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "audienceEmail" */
export type AudienceEmail = {
  __typename?: 'audienceEmail';
  audienceId: Scalars['uuid'];
  emailTemplateId: Scalars['uuid'];
  id: Scalars['uuid'];
  sendDate: Scalars['timetz'];
  status?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  workspaceId: Scalars['uuid'];
};

/** aggregated selection of "audienceEmail" */
export type AudienceEmail_Aggregate = {
  __typename?: 'audienceEmail_aggregate';
  aggregate?: Maybe<AudienceEmail_Aggregate_Fields>;
  nodes: Array<AudienceEmail>;
};

/** aggregate fields of "audienceEmail" */
export type AudienceEmail_Aggregate_Fields = {
  __typename?: 'audienceEmail_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<AudienceEmail_Max_Fields>;
  min?: Maybe<AudienceEmail_Min_Fields>;
};


/** aggregate fields of "audienceEmail" */
export type AudienceEmail_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<AudienceEmail_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "audienceEmail". All fields are combined with a logical 'AND'. */
export type AudienceEmail_Bool_Exp = {
  _and?: InputMaybe<Array<AudienceEmail_Bool_Exp>>;
  _not?: InputMaybe<AudienceEmail_Bool_Exp>;
  _or?: InputMaybe<Array<AudienceEmail_Bool_Exp>>;
  audienceId?: InputMaybe<Uuid_Comparison_Exp>;
  emailTemplateId?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  sendDate?: InputMaybe<Timetz_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  workspaceId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "audienceEmail" */
export enum AudienceEmail_Constraint {
  /** unique or primary key constraint on columns "audienceId", "emailTemplateId" */
  AudienceEmailAudienceIdEmailTemplateIdKey = 'audienceEmail_audienceId_emailTemplateId_key',
  /** unique or primary key constraint on columns "id" */
  AudienceEmailPkey = 'audienceEmail_pkey'
}

/** input type for inserting data into table "audienceEmail" */
export type AudienceEmail_Insert_Input = {
  audienceId?: InputMaybe<Scalars['uuid']>;
  emailTemplateId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  sendDate?: InputMaybe<Scalars['timetz']>;
  status?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type AudienceEmail_Max_Fields = {
  __typename?: 'audienceEmail_max_fields';
  audienceId?: Maybe<Scalars['uuid']>;
  emailTemplateId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  sendDate?: Maybe<Scalars['timetz']>;
  status?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type AudienceEmail_Min_Fields = {
  __typename?: 'audienceEmail_min_fields';
  audienceId?: Maybe<Scalars['uuid']>;
  emailTemplateId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  sendDate?: Maybe<Scalars['timetz']>;
  status?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "audienceEmail" */
export type AudienceEmail_Mutation_Response = {
  __typename?: 'audienceEmail_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<AudienceEmail>;
};

/** on_conflict condition type for table "audienceEmail" */
export type AudienceEmail_On_Conflict = {
  constraint: AudienceEmail_Constraint;
  update_columns?: Array<AudienceEmail_Update_Column>;
  where?: InputMaybe<AudienceEmail_Bool_Exp>;
};

/** Ordering options when selecting data from "audienceEmail". */
export type AudienceEmail_Order_By = {
  audienceId?: InputMaybe<Order_By>;
  emailTemplateId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  sendDate?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: audienceEmail */
export type AudienceEmail_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "audienceEmail" */
export enum AudienceEmail_Select_Column {
  /** column name */
  AudienceId = 'audienceId',
  /** column name */
  EmailTemplateId = 'emailTemplateId',
  /** column name */
  Id = 'id',
  /** column name */
  SendDate = 'sendDate',
  /** column name */
  Status = 'status',
  /** column name */
  Type = 'type',
  /** column name */
  WorkspaceId = 'workspaceId'
}

/** input type for updating data in table "audienceEmail" */
export type AudienceEmail_Set_Input = {
  audienceId?: InputMaybe<Scalars['uuid']>;
  emailTemplateId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  sendDate?: InputMaybe<Scalars['timetz']>;
  status?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "audienceEmail" */
export type AudienceEmail_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: AudienceEmail_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type AudienceEmail_Stream_Cursor_Value_Input = {
  audienceId?: InputMaybe<Scalars['uuid']>;
  emailTemplateId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  sendDate?: InputMaybe<Scalars['timetz']>;
  status?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "audienceEmail" */
export enum AudienceEmail_Update_Column {
  /** column name */
  AudienceId = 'audienceId',
  /** column name */
  EmailTemplateId = 'emailTemplateId',
  /** column name */
  Id = 'id',
  /** column name */
  SendDate = 'sendDate',
  /** column name */
  Status = 'status',
  /** column name */
  Type = 'type',
  /** column name */
  WorkspaceId = 'workspaceId'
}

export type AudienceEmail_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AudienceEmail_Set_Input>;
  /** filter the rows which have to be updated */
  where: AudienceEmail_Bool_Exp;
};

/** aggregated selection of "audience" */
export type Audience_Aggregate = {
  __typename?: 'audience_aggregate';
  aggregate?: Maybe<Audience_Aggregate_Fields>;
  nodes: Array<Audience>;
};

/** aggregate fields of "audience" */
export type Audience_Aggregate_Fields = {
  __typename?: 'audience_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Audience_Max_Fields>;
  min?: Maybe<Audience_Min_Fields>;
};


/** aggregate fields of "audience" */
export type Audience_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Audience_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Audience_Append_Input = {
  definition?: InputMaybe<Scalars['jsonb']>;
  snapshot?: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "audience". All fields are combined with a logical 'AND'. */
export type Audience_Bool_Exp = {
  _and?: InputMaybe<Array<Audience_Bool_Exp>>;
  _not?: InputMaybe<Audience_Bool_Exp>;
  _or?: InputMaybe<Array<Audience_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  definition?: InputMaybe<Jsonb_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  snapshot?: InputMaybe<Jsonb_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  workspaceId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "audience" */
export enum Audience_Constraint {
  /** unique or primary key constraint on columns "id" */
  AudiencePkey = 'audience_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Audience_Delete_At_Path_Input = {
  definition?: InputMaybe<Array<Scalars['String']>>;
  snapshot?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Audience_Delete_Elem_Input = {
  definition?: InputMaybe<Scalars['Int']>;
  snapshot?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Audience_Delete_Key_Input = {
  definition?: InputMaybe<Scalars['String']>;
  snapshot?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "audience" */
export type Audience_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  definition?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  snapshot?: InputMaybe<Scalars['jsonb']>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Audience_Max_Fields = {
  __typename?: 'audience_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Audience_Min_Fields = {
  __typename?: 'audience_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "audience" */
export type Audience_Mutation_Response = {
  __typename?: 'audience_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Audience>;
};

/** input type for inserting object relation for remote table "audience" */
export type Audience_Obj_Rel_Insert_Input = {
  data: Audience_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Audience_On_Conflict>;
};

/** on_conflict condition type for table "audience" */
export type Audience_On_Conflict = {
  constraint: Audience_Constraint;
  update_columns?: Array<Audience_Update_Column>;
  where?: InputMaybe<Audience_Bool_Exp>;
};

/** Ordering options when selecting data from "audience". */
export type Audience_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  definition?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  snapshot?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: audience */
export type Audience_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Audience_Prepend_Input = {
  definition?: InputMaybe<Scalars['jsonb']>;
  snapshot?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "audience" */
export enum Audience_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Definition = 'definition',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Snapshot = 'snapshot',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WorkspaceId = 'workspaceId'
}

/** input type for updating data in table "audience" */
export type Audience_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  definition?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  snapshot?: InputMaybe<Scalars['jsonb']>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "audience" */
export type Audience_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Audience_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Audience_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  definition?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  snapshot?: InputMaybe<Scalars['jsonb']>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "audience" */
export enum Audience_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Definition = 'definition',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Snapshot = 'snapshot',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WorkspaceId = 'workspaceId'
}

export type Audience_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Audience_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Audience_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Audience_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Audience_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Audience_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Audience_Set_Input>;
  /** filter the rows which have to be updated */
  where: Audience_Bool_Exp;
};

/** columns and relationships of "authProfile" */
export type AuthProfile = {
  __typename?: 'authProfile';
  accessToken: Scalars['String'];
  createdAt: Scalars['timestamptz'];
  expiryDate?: Maybe<Scalars['bigint']>;
  id: Scalars['uuid'];
  idToken?: Maybe<Scalars['String']>;
  isActive: Scalars['Boolean'];
  metadata: Scalars['jsonb'];
  refreshToken?: Maybe<Scalars['String']>;
  /** An array relationship */
  socialMediaAccounts: Array<SocialMediaAccount>;
  /** An aggregate relationship */
  socialMediaAccounts_aggregate: SocialMediaAccount_Aggregate;
  type: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  /** An object relationship */
  user: User;
  userId: Scalars['uuid'];
  /** An object relationship */
  workspace: Workspace;
  workspaceId: Scalars['uuid'];
};


/** columns and relationships of "authProfile" */
export type AuthProfileMetadataArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "authProfile" */
export type AuthProfileSocialMediaAccountsArgs = {
  distinct_on?: InputMaybe<Array<SocialMediaAccount_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SocialMediaAccount_Order_By>>;
  where?: InputMaybe<SocialMediaAccount_Bool_Exp>;
};


/** columns and relationships of "authProfile" */
export type AuthProfileSocialMediaAccounts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<SocialMediaAccount_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SocialMediaAccount_Order_By>>;
  where?: InputMaybe<SocialMediaAccount_Bool_Exp>;
};

/** aggregated selection of "authProfile" */
export type AuthProfile_Aggregate = {
  __typename?: 'authProfile_aggregate';
  aggregate?: Maybe<AuthProfile_Aggregate_Fields>;
  nodes: Array<AuthProfile>;
};

export type AuthProfile_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<AuthProfile_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<AuthProfile_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<AuthProfile_Aggregate_Bool_Exp_Count>;
};

export type AuthProfile_Aggregate_Bool_Exp_Bool_And = {
  arguments: AuthProfile_Select_Column_AuthProfile_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<AuthProfile_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type AuthProfile_Aggregate_Bool_Exp_Bool_Or = {
  arguments: AuthProfile_Select_Column_AuthProfile_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<AuthProfile_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type AuthProfile_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<AuthProfile_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<AuthProfile_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "authProfile" */
export type AuthProfile_Aggregate_Fields = {
  __typename?: 'authProfile_aggregate_fields';
  avg?: Maybe<AuthProfile_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<AuthProfile_Max_Fields>;
  min?: Maybe<AuthProfile_Min_Fields>;
  stddev?: Maybe<AuthProfile_Stddev_Fields>;
  stddev_pop?: Maybe<AuthProfile_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<AuthProfile_Stddev_Samp_Fields>;
  sum?: Maybe<AuthProfile_Sum_Fields>;
  var_pop?: Maybe<AuthProfile_Var_Pop_Fields>;
  var_samp?: Maybe<AuthProfile_Var_Samp_Fields>;
  variance?: Maybe<AuthProfile_Variance_Fields>;
};


/** aggregate fields of "authProfile" */
export type AuthProfile_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<AuthProfile_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "authProfile" */
export type AuthProfile_Aggregate_Order_By = {
  avg?: InputMaybe<AuthProfile_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<AuthProfile_Max_Order_By>;
  min?: InputMaybe<AuthProfile_Min_Order_By>;
  stddev?: InputMaybe<AuthProfile_Stddev_Order_By>;
  stddev_pop?: InputMaybe<AuthProfile_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<AuthProfile_Stddev_Samp_Order_By>;
  sum?: InputMaybe<AuthProfile_Sum_Order_By>;
  var_pop?: InputMaybe<AuthProfile_Var_Pop_Order_By>;
  var_samp?: InputMaybe<AuthProfile_Var_Samp_Order_By>;
  variance?: InputMaybe<AuthProfile_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type AuthProfile_Append_Input = {
  metadata?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "authProfile" */
export type AuthProfile_Arr_Rel_Insert_Input = {
  data: Array<AuthProfile_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<AuthProfile_On_Conflict>;
};

/** aggregate avg on columns */
export type AuthProfile_Avg_Fields = {
  __typename?: 'authProfile_avg_fields';
  expiryDate?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "authProfile" */
export type AuthProfile_Avg_Order_By = {
  expiryDate?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "authProfile". All fields are combined with a logical 'AND'. */
export type AuthProfile_Bool_Exp = {
  _and?: InputMaybe<Array<AuthProfile_Bool_Exp>>;
  _not?: InputMaybe<AuthProfile_Bool_Exp>;
  _or?: InputMaybe<Array<AuthProfile_Bool_Exp>>;
  accessToken?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  expiryDate?: InputMaybe<Bigint_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  idToken?: InputMaybe<String_Comparison_Exp>;
  isActive?: InputMaybe<Boolean_Comparison_Exp>;
  metadata?: InputMaybe<Jsonb_Comparison_Exp>;
  refreshToken?: InputMaybe<String_Comparison_Exp>;
  socialMediaAccounts?: InputMaybe<SocialMediaAccount_Bool_Exp>;
  socialMediaAccounts_aggregate?: InputMaybe<SocialMediaAccount_Aggregate_Bool_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<User_Bool_Exp>;
  userId?: InputMaybe<Uuid_Comparison_Exp>;
  workspace?: InputMaybe<Workspace_Bool_Exp>;
  workspaceId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "authProfile" */
export enum AuthProfile_Constraint {
  /** unique or primary key constraint on columns "id" */
  AuthProfilePkey = 'authProfile_pkey',
  /** unique or primary key constraint on columns "userId", "type" */
  AuthProfileUserIdTypeKey = 'authProfile_userId_type_key'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type AuthProfile_Delete_At_Path_Input = {
  metadata?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type AuthProfile_Delete_Elem_Input = {
  metadata?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type AuthProfile_Delete_Key_Input = {
  metadata?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "authProfile" */
export type AuthProfile_Inc_Input = {
  expiryDate?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "authProfile" */
export type AuthProfile_Insert_Input = {
  accessToken?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  expiryDate?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['uuid']>;
  idToken?: InputMaybe<Scalars['String']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  refreshToken?: InputMaybe<Scalars['String']>;
  socialMediaAccounts?: InputMaybe<SocialMediaAccount_Arr_Rel_Insert_Input>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<User_Obj_Rel_Insert_Input>;
  userId?: InputMaybe<Scalars['uuid']>;
  workspace?: InputMaybe<Workspace_Obj_Rel_Insert_Input>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type AuthProfile_Max_Fields = {
  __typename?: 'authProfile_max_fields';
  accessToken?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  expiryDate?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['uuid']>;
  idToken?: Maybe<Scalars['String']>;
  refreshToken?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['uuid']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "authProfile" */
export type AuthProfile_Max_Order_By = {
  accessToken?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  expiryDate?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  idToken?: InputMaybe<Order_By>;
  refreshToken?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type AuthProfile_Min_Fields = {
  __typename?: 'authProfile_min_fields';
  accessToken?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  expiryDate?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['uuid']>;
  idToken?: Maybe<Scalars['String']>;
  refreshToken?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['uuid']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "authProfile" */
export type AuthProfile_Min_Order_By = {
  accessToken?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  expiryDate?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  idToken?: InputMaybe<Order_By>;
  refreshToken?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "authProfile" */
export type AuthProfile_Mutation_Response = {
  __typename?: 'authProfile_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<AuthProfile>;
};

/** input type for inserting object relation for remote table "authProfile" */
export type AuthProfile_Obj_Rel_Insert_Input = {
  data: AuthProfile_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<AuthProfile_On_Conflict>;
};

/** on_conflict condition type for table "authProfile" */
export type AuthProfile_On_Conflict = {
  constraint: AuthProfile_Constraint;
  update_columns?: Array<AuthProfile_Update_Column>;
  where?: InputMaybe<AuthProfile_Bool_Exp>;
};

/** Ordering options when selecting data from "authProfile". */
export type AuthProfile_Order_By = {
  accessToken?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  expiryDate?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  idToken?: InputMaybe<Order_By>;
  isActive?: InputMaybe<Order_By>;
  metadata?: InputMaybe<Order_By>;
  refreshToken?: InputMaybe<Order_By>;
  socialMediaAccounts_aggregate?: InputMaybe<SocialMediaAccount_Aggregate_Order_By>;
  type?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  user?: InputMaybe<User_Order_By>;
  userId?: InputMaybe<Order_By>;
  workspace?: InputMaybe<Workspace_Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: authProfile */
export type AuthProfile_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type AuthProfile_Prepend_Input = {
  metadata?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "authProfile" */
export enum AuthProfile_Select_Column {
  /** column name */
  AccessToken = 'accessToken',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  ExpiryDate = 'expiryDate',
  /** column name */
  Id = 'id',
  /** column name */
  IdToken = 'idToken',
  /** column name */
  IsActive = 'isActive',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  RefreshToken = 'refreshToken',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId',
  /** column name */
  WorkspaceId = 'workspaceId'
}

/** select "authProfile_aggregate_bool_exp_bool_and_arguments_columns" columns of table "authProfile" */
export enum AuthProfile_Select_Column_AuthProfile_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsActive = 'isActive'
}

/** select "authProfile_aggregate_bool_exp_bool_or_arguments_columns" columns of table "authProfile" */
export enum AuthProfile_Select_Column_AuthProfile_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsActive = 'isActive'
}

/** input type for updating data in table "authProfile" */
export type AuthProfile_Set_Input = {
  accessToken?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  expiryDate?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['uuid']>;
  idToken?: InputMaybe<Scalars['String']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  refreshToken?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  userId?: InputMaybe<Scalars['uuid']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type AuthProfile_Stddev_Fields = {
  __typename?: 'authProfile_stddev_fields';
  expiryDate?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "authProfile" */
export type AuthProfile_Stddev_Order_By = {
  expiryDate?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type AuthProfile_Stddev_Pop_Fields = {
  __typename?: 'authProfile_stddev_pop_fields';
  expiryDate?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "authProfile" */
export type AuthProfile_Stddev_Pop_Order_By = {
  expiryDate?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type AuthProfile_Stddev_Samp_Fields = {
  __typename?: 'authProfile_stddev_samp_fields';
  expiryDate?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "authProfile" */
export type AuthProfile_Stddev_Samp_Order_By = {
  expiryDate?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "authProfile" */
export type AuthProfile_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: AuthProfile_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type AuthProfile_Stream_Cursor_Value_Input = {
  accessToken?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  expiryDate?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['uuid']>;
  idToken?: InputMaybe<Scalars['String']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  refreshToken?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  userId?: InputMaybe<Scalars['uuid']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type AuthProfile_Sum_Fields = {
  __typename?: 'authProfile_sum_fields';
  expiryDate?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "authProfile" */
export type AuthProfile_Sum_Order_By = {
  expiryDate?: InputMaybe<Order_By>;
};

/** update columns of table "authProfile" */
export enum AuthProfile_Update_Column {
  /** column name */
  AccessToken = 'accessToken',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  ExpiryDate = 'expiryDate',
  /** column name */
  Id = 'id',
  /** column name */
  IdToken = 'idToken',
  /** column name */
  IsActive = 'isActive',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  RefreshToken = 'refreshToken',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId',
  /** column name */
  WorkspaceId = 'workspaceId'
}

export type AuthProfile_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<AuthProfile_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<AuthProfile_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<AuthProfile_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<AuthProfile_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<AuthProfile_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<AuthProfile_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AuthProfile_Set_Input>;
  /** filter the rows which have to be updated */
  where: AuthProfile_Bool_Exp;
};

/** aggregate var_pop on columns */
export type AuthProfile_Var_Pop_Fields = {
  __typename?: 'authProfile_var_pop_fields';
  expiryDate?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "authProfile" */
export type AuthProfile_Var_Pop_Order_By = {
  expiryDate?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type AuthProfile_Var_Samp_Fields = {
  __typename?: 'authProfile_var_samp_fields';
  expiryDate?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "authProfile" */
export type AuthProfile_Var_Samp_Order_By = {
  expiryDate?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type AuthProfile_Variance_Fields = {
  __typename?: 'authProfile_variance_fields';
  expiryDate?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "authProfile" */
export type AuthProfile_Variance_Order_By = {
  expiryDate?: InputMaybe<Order_By>;
};

/** Boolean expression to compare columns of type "bigint". All fields are combined with logical 'AND'. */
export type Bigint_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['bigint']>;
  _gt?: InputMaybe<Scalars['bigint']>;
  _gte?: InputMaybe<Scalars['bigint']>;
  _in?: InputMaybe<Array<Scalars['bigint']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['bigint']>;
  _lte?: InputMaybe<Scalars['bigint']>;
  _neq?: InputMaybe<Scalars['bigint']>;
  _nin?: InputMaybe<Array<Scalars['bigint']>>;
};

/** Boolean expression to compare columns of type "bpchar". All fields are combined with logical 'AND'. */
export type Bpchar_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['bpchar']>;
  _gt?: InputMaybe<Scalars['bpchar']>;
  _gte?: InputMaybe<Scalars['bpchar']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['bpchar']>;
  _in?: InputMaybe<Array<Scalars['bpchar']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['bpchar']>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['bpchar']>;
  _lt?: InputMaybe<Scalars['bpchar']>;
  _lte?: InputMaybe<Scalars['bpchar']>;
  _neq?: InputMaybe<Scalars['bpchar']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['bpchar']>;
  _nin?: InputMaybe<Array<Scalars['bpchar']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['bpchar']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['bpchar']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['bpchar']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['bpchar']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['bpchar']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['bpchar']>;
};

/** columns and relationships of "clinicalMessage" */
export type ClinicalMessage = {
  __typename?: 'clinicalMessage';
  /** An object relationship */
  attachmentDocument?: Maybe<Document>;
  attachmentDocumentId?: Maybe<Scalars['uuid']>;
  eventSendTime: Scalars['Int'];
  fromAddress?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  messageSubject: Scalars['String'];
  messageText: Scalars['String'];
  messageType: Scalars['String'];
  serviceId?: Maybe<Scalars['uuid']>;
  updatedAt: Scalars['timestamptz'];
  workspaceId: Scalars['uuid'];
};

/** columns and relationships of "clinicalMessageStatus" */
export type ClinicalMessageStatus = {
  __typename?: 'clinicalMessageStatus';
  /** An object relationship */
  appointment?: Maybe<Appointment>;
  appointmentId?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  clinicalMessage: ClinicalMessage;
  clinicalMessageId: Scalars['uuid'];
  id: Scalars['uuid'];
  /** An object relationship */
  patientWorkspace: PatientWorkspace;
  patientWorkspaceId: Scalars['uuid'];
  sendTime: Scalars['timestamptz'];
};

/** aggregated selection of "clinicalMessageStatus" */
export type ClinicalMessageStatus_Aggregate = {
  __typename?: 'clinicalMessageStatus_aggregate';
  aggregate?: Maybe<ClinicalMessageStatus_Aggregate_Fields>;
  nodes: Array<ClinicalMessageStatus>;
};

export type ClinicalMessageStatus_Aggregate_Bool_Exp = {
  count?: InputMaybe<ClinicalMessageStatus_Aggregate_Bool_Exp_Count>;
};

export type ClinicalMessageStatus_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<ClinicalMessageStatus_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ClinicalMessageStatus_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "clinicalMessageStatus" */
export type ClinicalMessageStatus_Aggregate_Fields = {
  __typename?: 'clinicalMessageStatus_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ClinicalMessageStatus_Max_Fields>;
  min?: Maybe<ClinicalMessageStatus_Min_Fields>;
};


/** aggregate fields of "clinicalMessageStatus" */
export type ClinicalMessageStatus_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<ClinicalMessageStatus_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "clinicalMessageStatus" */
export type ClinicalMessageStatus_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<ClinicalMessageStatus_Max_Order_By>;
  min?: InputMaybe<ClinicalMessageStatus_Min_Order_By>;
};

/** input type for inserting array relation for remote table "clinicalMessageStatus" */
export type ClinicalMessageStatus_Arr_Rel_Insert_Input = {
  data: Array<ClinicalMessageStatus_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<ClinicalMessageStatus_On_Conflict>;
};

/** Boolean expression to filter rows from the table "clinicalMessageStatus". All fields are combined with a logical 'AND'. */
export type ClinicalMessageStatus_Bool_Exp = {
  _and?: InputMaybe<Array<ClinicalMessageStatus_Bool_Exp>>;
  _not?: InputMaybe<ClinicalMessageStatus_Bool_Exp>;
  _or?: InputMaybe<Array<ClinicalMessageStatus_Bool_Exp>>;
  appointment?: InputMaybe<Appointment_Bool_Exp>;
  appointmentId?: InputMaybe<Uuid_Comparison_Exp>;
  clinicalMessage?: InputMaybe<ClinicalMessage_Bool_Exp>;
  clinicalMessageId?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  patientWorkspace?: InputMaybe<PatientWorkspace_Bool_Exp>;
  patientWorkspaceId?: InputMaybe<Uuid_Comparison_Exp>;
  sendTime?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "clinicalMessageStatus" */
export enum ClinicalMessageStatus_Constraint {
  /** unique or primary key constraint on columns "id" */
  ClinicalMessageStatusPkey = 'clinicalMessageStatus_pkey'
}

/** input type for inserting data into table "clinicalMessageStatus" */
export type ClinicalMessageStatus_Insert_Input = {
  appointment?: InputMaybe<Appointment_Obj_Rel_Insert_Input>;
  appointmentId?: InputMaybe<Scalars['uuid']>;
  clinicalMessage?: InputMaybe<ClinicalMessage_Obj_Rel_Insert_Input>;
  clinicalMessageId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  patientWorkspace?: InputMaybe<PatientWorkspace_Obj_Rel_Insert_Input>;
  patientWorkspaceId?: InputMaybe<Scalars['uuid']>;
  sendTime?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type ClinicalMessageStatus_Max_Fields = {
  __typename?: 'clinicalMessageStatus_max_fields';
  appointmentId?: Maybe<Scalars['uuid']>;
  clinicalMessageId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  patientWorkspaceId?: Maybe<Scalars['uuid']>;
  sendTime?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "clinicalMessageStatus" */
export type ClinicalMessageStatus_Max_Order_By = {
  appointmentId?: InputMaybe<Order_By>;
  clinicalMessageId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patientWorkspaceId?: InputMaybe<Order_By>;
  sendTime?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type ClinicalMessageStatus_Min_Fields = {
  __typename?: 'clinicalMessageStatus_min_fields';
  appointmentId?: Maybe<Scalars['uuid']>;
  clinicalMessageId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  patientWorkspaceId?: Maybe<Scalars['uuid']>;
  sendTime?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "clinicalMessageStatus" */
export type ClinicalMessageStatus_Min_Order_By = {
  appointmentId?: InputMaybe<Order_By>;
  clinicalMessageId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patientWorkspaceId?: InputMaybe<Order_By>;
  sendTime?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "clinicalMessageStatus" */
export type ClinicalMessageStatus_Mutation_Response = {
  __typename?: 'clinicalMessageStatus_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ClinicalMessageStatus>;
};

/** on_conflict condition type for table "clinicalMessageStatus" */
export type ClinicalMessageStatus_On_Conflict = {
  constraint: ClinicalMessageStatus_Constraint;
  update_columns?: Array<ClinicalMessageStatus_Update_Column>;
  where?: InputMaybe<ClinicalMessageStatus_Bool_Exp>;
};

/** Ordering options when selecting data from "clinicalMessageStatus". */
export type ClinicalMessageStatus_Order_By = {
  appointment?: InputMaybe<Appointment_Order_By>;
  appointmentId?: InputMaybe<Order_By>;
  clinicalMessage?: InputMaybe<ClinicalMessage_Order_By>;
  clinicalMessageId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patientWorkspace?: InputMaybe<PatientWorkspace_Order_By>;
  patientWorkspaceId?: InputMaybe<Order_By>;
  sendTime?: InputMaybe<Order_By>;
};

/** primary key columns input for table: clinicalMessageStatus */
export type ClinicalMessageStatus_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "clinicalMessageStatus" */
export enum ClinicalMessageStatus_Select_Column {
  /** column name */
  AppointmentId = 'appointmentId',
  /** column name */
  ClinicalMessageId = 'clinicalMessageId',
  /** column name */
  Id = 'id',
  /** column name */
  PatientWorkspaceId = 'patientWorkspaceId',
  /** column name */
  SendTime = 'sendTime'
}

/** input type for updating data in table "clinicalMessageStatus" */
export type ClinicalMessageStatus_Set_Input = {
  appointmentId?: InputMaybe<Scalars['uuid']>;
  clinicalMessageId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  patientWorkspaceId?: InputMaybe<Scalars['uuid']>;
  sendTime?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "clinicalMessageStatus" */
export type ClinicalMessageStatus_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ClinicalMessageStatus_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ClinicalMessageStatus_Stream_Cursor_Value_Input = {
  appointmentId?: InputMaybe<Scalars['uuid']>;
  clinicalMessageId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  patientWorkspaceId?: InputMaybe<Scalars['uuid']>;
  sendTime?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "clinicalMessageStatus" */
export enum ClinicalMessageStatus_Update_Column {
  /** column name */
  AppointmentId = 'appointmentId',
  /** column name */
  ClinicalMessageId = 'clinicalMessageId',
  /** column name */
  Id = 'id',
  /** column name */
  PatientWorkspaceId = 'patientWorkspaceId',
  /** column name */
  SendTime = 'sendTime'
}

export type ClinicalMessageStatus_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ClinicalMessageStatus_Set_Input>;
  /** filter the rows which have to be updated */
  where: ClinicalMessageStatus_Bool_Exp;
};

/** aggregated selection of "clinicalMessage" */
export type ClinicalMessage_Aggregate = {
  __typename?: 'clinicalMessage_aggregate';
  aggregate?: Maybe<ClinicalMessage_Aggregate_Fields>;
  nodes: Array<ClinicalMessage>;
};

/** aggregate fields of "clinicalMessage" */
export type ClinicalMessage_Aggregate_Fields = {
  __typename?: 'clinicalMessage_aggregate_fields';
  avg?: Maybe<ClinicalMessage_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<ClinicalMessage_Max_Fields>;
  min?: Maybe<ClinicalMessage_Min_Fields>;
  stddev?: Maybe<ClinicalMessage_Stddev_Fields>;
  stddev_pop?: Maybe<ClinicalMessage_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<ClinicalMessage_Stddev_Samp_Fields>;
  sum?: Maybe<ClinicalMessage_Sum_Fields>;
  var_pop?: Maybe<ClinicalMessage_Var_Pop_Fields>;
  var_samp?: Maybe<ClinicalMessage_Var_Samp_Fields>;
  variance?: Maybe<ClinicalMessage_Variance_Fields>;
};


/** aggregate fields of "clinicalMessage" */
export type ClinicalMessage_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<ClinicalMessage_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type ClinicalMessage_Avg_Fields = {
  __typename?: 'clinicalMessage_avg_fields';
  eventSendTime?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "clinicalMessage". All fields are combined with a logical 'AND'. */
export type ClinicalMessage_Bool_Exp = {
  _and?: InputMaybe<Array<ClinicalMessage_Bool_Exp>>;
  _not?: InputMaybe<ClinicalMessage_Bool_Exp>;
  _or?: InputMaybe<Array<ClinicalMessage_Bool_Exp>>;
  attachmentDocument?: InputMaybe<Document_Bool_Exp>;
  attachmentDocumentId?: InputMaybe<Uuid_Comparison_Exp>;
  eventSendTime?: InputMaybe<Int_Comparison_Exp>;
  fromAddress?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  messageSubject?: InputMaybe<String_Comparison_Exp>;
  messageText?: InputMaybe<String_Comparison_Exp>;
  messageType?: InputMaybe<String_Comparison_Exp>;
  serviceId?: InputMaybe<Uuid_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  workspaceId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "clinicalMessage" */
export enum ClinicalMessage_Constraint {
  /** unique or primary key constraint on columns "id" */
  ClinicalMessagePkey = 'clinicalMessage_pkey'
}

/** input type for incrementing numeric columns in table "clinicalMessage" */
export type ClinicalMessage_Inc_Input = {
  eventSendTime?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "clinicalMessage" */
export type ClinicalMessage_Insert_Input = {
  attachmentDocument?: InputMaybe<Document_Obj_Rel_Insert_Input>;
  attachmentDocumentId?: InputMaybe<Scalars['uuid']>;
  eventSendTime?: InputMaybe<Scalars['Int']>;
  fromAddress?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  messageSubject?: InputMaybe<Scalars['String']>;
  messageText?: InputMaybe<Scalars['String']>;
  messageType?: InputMaybe<Scalars['String']>;
  serviceId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type ClinicalMessage_Max_Fields = {
  __typename?: 'clinicalMessage_max_fields';
  attachmentDocumentId?: Maybe<Scalars['uuid']>;
  eventSendTime?: Maybe<Scalars['Int']>;
  fromAddress?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  messageSubject?: Maybe<Scalars['String']>;
  messageText?: Maybe<Scalars['String']>;
  messageType?: Maybe<Scalars['String']>;
  serviceId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type ClinicalMessage_Min_Fields = {
  __typename?: 'clinicalMessage_min_fields';
  attachmentDocumentId?: Maybe<Scalars['uuid']>;
  eventSendTime?: Maybe<Scalars['Int']>;
  fromAddress?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  messageSubject?: Maybe<Scalars['String']>;
  messageText?: Maybe<Scalars['String']>;
  messageType?: Maybe<Scalars['String']>;
  serviceId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "clinicalMessage" */
export type ClinicalMessage_Mutation_Response = {
  __typename?: 'clinicalMessage_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ClinicalMessage>;
};

/** input type for inserting object relation for remote table "clinicalMessage" */
export type ClinicalMessage_Obj_Rel_Insert_Input = {
  data: ClinicalMessage_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<ClinicalMessage_On_Conflict>;
};

/** on_conflict condition type for table "clinicalMessage" */
export type ClinicalMessage_On_Conflict = {
  constraint: ClinicalMessage_Constraint;
  update_columns?: Array<ClinicalMessage_Update_Column>;
  where?: InputMaybe<ClinicalMessage_Bool_Exp>;
};

/** Ordering options when selecting data from "clinicalMessage". */
export type ClinicalMessage_Order_By = {
  attachmentDocument?: InputMaybe<Document_Order_By>;
  attachmentDocumentId?: InputMaybe<Order_By>;
  eventSendTime?: InputMaybe<Order_By>;
  fromAddress?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  messageSubject?: InputMaybe<Order_By>;
  messageText?: InputMaybe<Order_By>;
  messageType?: InputMaybe<Order_By>;
  serviceId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: clinicalMessage */
export type ClinicalMessage_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "clinicalMessage" */
export enum ClinicalMessage_Select_Column {
  /** column name */
  AttachmentDocumentId = 'attachmentDocumentId',
  /** column name */
  EventSendTime = 'eventSendTime',
  /** column name */
  FromAddress = 'fromAddress',
  /** column name */
  Id = 'id',
  /** column name */
  MessageSubject = 'messageSubject',
  /** column name */
  MessageText = 'messageText',
  /** column name */
  MessageType = 'messageType',
  /** column name */
  ServiceId = 'serviceId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WorkspaceId = 'workspaceId'
}

/** input type for updating data in table "clinicalMessage" */
export type ClinicalMessage_Set_Input = {
  attachmentDocumentId?: InputMaybe<Scalars['uuid']>;
  eventSendTime?: InputMaybe<Scalars['Int']>;
  fromAddress?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  messageSubject?: InputMaybe<Scalars['String']>;
  messageText?: InputMaybe<Scalars['String']>;
  messageType?: InputMaybe<Scalars['String']>;
  serviceId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type ClinicalMessage_Stddev_Fields = {
  __typename?: 'clinicalMessage_stddev_fields';
  eventSendTime?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type ClinicalMessage_Stddev_Pop_Fields = {
  __typename?: 'clinicalMessage_stddev_pop_fields';
  eventSendTime?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type ClinicalMessage_Stddev_Samp_Fields = {
  __typename?: 'clinicalMessage_stddev_samp_fields';
  eventSendTime?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "clinicalMessage" */
export type ClinicalMessage_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ClinicalMessage_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ClinicalMessage_Stream_Cursor_Value_Input = {
  attachmentDocumentId?: InputMaybe<Scalars['uuid']>;
  eventSendTime?: InputMaybe<Scalars['Int']>;
  fromAddress?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  messageSubject?: InputMaybe<Scalars['String']>;
  messageText?: InputMaybe<Scalars['String']>;
  messageType?: InputMaybe<Scalars['String']>;
  serviceId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type ClinicalMessage_Sum_Fields = {
  __typename?: 'clinicalMessage_sum_fields';
  eventSendTime?: Maybe<Scalars['Int']>;
};

/** update columns of table "clinicalMessage" */
export enum ClinicalMessage_Update_Column {
  /** column name */
  AttachmentDocumentId = 'attachmentDocumentId',
  /** column name */
  EventSendTime = 'eventSendTime',
  /** column name */
  FromAddress = 'fromAddress',
  /** column name */
  Id = 'id',
  /** column name */
  MessageSubject = 'messageSubject',
  /** column name */
  MessageText = 'messageText',
  /** column name */
  MessageType = 'messageType',
  /** column name */
  ServiceId = 'serviceId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WorkspaceId = 'workspaceId'
}

export type ClinicalMessage_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ClinicalMessage_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ClinicalMessage_Set_Input>;
  /** filter the rows which have to be updated */
  where: ClinicalMessage_Bool_Exp;
};

/** aggregate var_pop on columns */
export type ClinicalMessage_Var_Pop_Fields = {
  __typename?: 'clinicalMessage_var_pop_fields';
  eventSendTime?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type ClinicalMessage_Var_Samp_Fields = {
  __typename?: 'clinicalMessage_var_samp_fields';
  eventSendTime?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type ClinicalMessage_Variance_Fields = {
  __typename?: 'clinicalMessage_variance_fields';
  eventSendTime?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "compensationPolicy" */
export type CompensationPolicy = {
  __typename?: 'compensationPolicy';
  /** An array relationship */
  categories: Array<CompensationPolicyTierCategory>;
  /** An aggregate relationship */
  categories_aggregate: CompensationPolicyTierCategory_Aggregate;
  /** An array relationship */
  commissions: Array<CompensationPolicyCommission>;
  /** An aggregate relationship */
  commissions_aggregate: CompensationPolicyCommission_Aggregate;
  compensationStrategyId: Scalars['uuid'];
  config: Scalars['jsonb'];
  createdAt: Scalars['timestamptz'];
  /** An array relationship */
  exemptions: Array<CompensationPolicyTierExemption>;
  /** An aggregate relationship */
  exemptions_aggregate: CompensationPolicyTierExemption_Aggregate;
  id: Scalars['uuid'];
  /** An array relationship */
  inclusions: Array<CompensationPolicyTierInclusion>;
  /** An aggregate relationship */
  inclusions_aggregate: CompensationPolicyTierInclusion_Aggregate;
  isActive: Scalars['Boolean'];
  name: Scalars['String'];
  /** An object relationship */
  strategy: CompensationStrategy;
  strategyType: Scalars['String'];
  /** An array relationship */
  tiers: Array<CompensationPolicyTier>;
  /** An aggregate relationship */
  tiers_aggregate: CompensationPolicyTier_Aggregate;
  updatedAt: Scalars['timestamptz'];
  /** An object relationship */
  user: User;
  userId: Scalars['uuid'];
  validFrom?: Maybe<Scalars['timestamptz']>;
  validUntil?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  workspace: Workspace;
  workspaceId: Scalars['uuid'];
};


/** columns and relationships of "compensationPolicy" */
export type CompensationPolicyCategoriesArgs = {
  distinct_on?: InputMaybe<Array<CompensationPolicyTierCategory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CompensationPolicyTierCategory_Order_By>>;
  where?: InputMaybe<CompensationPolicyTierCategory_Bool_Exp>;
};


/** columns and relationships of "compensationPolicy" */
export type CompensationPolicyCategories_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CompensationPolicyTierCategory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CompensationPolicyTierCategory_Order_By>>;
  where?: InputMaybe<CompensationPolicyTierCategory_Bool_Exp>;
};


/** columns and relationships of "compensationPolicy" */
export type CompensationPolicyCommissionsArgs = {
  distinct_on?: InputMaybe<Array<CompensationPolicyCommission_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CompensationPolicyCommission_Order_By>>;
  where?: InputMaybe<CompensationPolicyCommission_Bool_Exp>;
};


/** columns and relationships of "compensationPolicy" */
export type CompensationPolicyCommissions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CompensationPolicyCommission_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CompensationPolicyCommission_Order_By>>;
  where?: InputMaybe<CompensationPolicyCommission_Bool_Exp>;
};


/** columns and relationships of "compensationPolicy" */
export type CompensationPolicyConfigArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "compensationPolicy" */
export type CompensationPolicyExemptionsArgs = {
  distinct_on?: InputMaybe<Array<CompensationPolicyTierExemption_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CompensationPolicyTierExemption_Order_By>>;
  where?: InputMaybe<CompensationPolicyTierExemption_Bool_Exp>;
};


/** columns and relationships of "compensationPolicy" */
export type CompensationPolicyExemptions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CompensationPolicyTierExemption_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CompensationPolicyTierExemption_Order_By>>;
  where?: InputMaybe<CompensationPolicyTierExemption_Bool_Exp>;
};


/** columns and relationships of "compensationPolicy" */
export type CompensationPolicyInclusionsArgs = {
  distinct_on?: InputMaybe<Array<CompensationPolicyTierInclusion_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CompensationPolicyTierInclusion_Order_By>>;
  where?: InputMaybe<CompensationPolicyTierInclusion_Bool_Exp>;
};


/** columns and relationships of "compensationPolicy" */
export type CompensationPolicyInclusions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CompensationPolicyTierInclusion_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CompensationPolicyTierInclusion_Order_By>>;
  where?: InputMaybe<CompensationPolicyTierInclusion_Bool_Exp>;
};


/** columns and relationships of "compensationPolicy" */
export type CompensationPolicyTiersArgs = {
  distinct_on?: InputMaybe<Array<CompensationPolicyTier_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CompensationPolicyTier_Order_By>>;
  where?: InputMaybe<CompensationPolicyTier_Bool_Exp>;
};


/** columns and relationships of "compensationPolicy" */
export type CompensationPolicyTiers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CompensationPolicyTier_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CompensationPolicyTier_Order_By>>;
  where?: InputMaybe<CompensationPolicyTier_Bool_Exp>;
};

/** columns and relationships of "compensationPolicyCommission" */
export type CompensationPolicyCommission = {
  __typename?: 'compensationPolicyCommission';
  compensationAmount: Scalars['Int'];
  /** An object relationship */
  compensationPolicy: CompensationPolicy;
  compensationPolicyId: Scalars['uuid'];
  compensationType: Scalars['String'];
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  maxApplyValue: Scalars['Int'];
  /** An object relationship */
  membership?: Maybe<Membership>;
  membershipId?: Maybe<Scalars['uuid']>;
  minApplyValue: Scalars['Int'];
  /** An object relationship */
  package?: Maybe<Package>;
  packageId?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  service?: Maybe<Service>;
  serviceId?: Maybe<Scalars['uuid']>;
  updatedAt: Scalars['timestamptz'];
  /** An object relationship */
  workspace: Workspace;
  workspaceId: Scalars['uuid'];
};

/** aggregated selection of "compensationPolicyCommission" */
export type CompensationPolicyCommission_Aggregate = {
  __typename?: 'compensationPolicyCommission_aggregate';
  aggregate?: Maybe<CompensationPolicyCommission_Aggregate_Fields>;
  nodes: Array<CompensationPolicyCommission>;
};

export type CompensationPolicyCommission_Aggregate_Bool_Exp = {
  count?: InputMaybe<CompensationPolicyCommission_Aggregate_Bool_Exp_Count>;
};

export type CompensationPolicyCommission_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<CompensationPolicyCommission_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<CompensationPolicyCommission_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "compensationPolicyCommission" */
export type CompensationPolicyCommission_Aggregate_Fields = {
  __typename?: 'compensationPolicyCommission_aggregate_fields';
  avg?: Maybe<CompensationPolicyCommission_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<CompensationPolicyCommission_Max_Fields>;
  min?: Maybe<CompensationPolicyCommission_Min_Fields>;
  stddev?: Maybe<CompensationPolicyCommission_Stddev_Fields>;
  stddev_pop?: Maybe<CompensationPolicyCommission_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<CompensationPolicyCommission_Stddev_Samp_Fields>;
  sum?: Maybe<CompensationPolicyCommission_Sum_Fields>;
  var_pop?: Maybe<CompensationPolicyCommission_Var_Pop_Fields>;
  var_samp?: Maybe<CompensationPolicyCommission_Var_Samp_Fields>;
  variance?: Maybe<CompensationPolicyCommission_Variance_Fields>;
};


/** aggregate fields of "compensationPolicyCommission" */
export type CompensationPolicyCommission_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<CompensationPolicyCommission_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "compensationPolicyCommission" */
export type CompensationPolicyCommission_Aggregate_Order_By = {
  avg?: InputMaybe<CompensationPolicyCommission_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<CompensationPolicyCommission_Max_Order_By>;
  min?: InputMaybe<CompensationPolicyCommission_Min_Order_By>;
  stddev?: InputMaybe<CompensationPolicyCommission_Stddev_Order_By>;
  stddev_pop?: InputMaybe<CompensationPolicyCommission_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<CompensationPolicyCommission_Stddev_Samp_Order_By>;
  sum?: InputMaybe<CompensationPolicyCommission_Sum_Order_By>;
  var_pop?: InputMaybe<CompensationPolicyCommission_Var_Pop_Order_By>;
  var_samp?: InputMaybe<CompensationPolicyCommission_Var_Samp_Order_By>;
  variance?: InputMaybe<CompensationPolicyCommission_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "compensationPolicyCommission" */
export type CompensationPolicyCommission_Arr_Rel_Insert_Input = {
  data: Array<CompensationPolicyCommission_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<CompensationPolicyCommission_On_Conflict>;
};

/** aggregate avg on columns */
export type CompensationPolicyCommission_Avg_Fields = {
  __typename?: 'compensationPolicyCommission_avg_fields';
  compensationAmount?: Maybe<Scalars['Float']>;
  maxApplyValue?: Maybe<Scalars['Float']>;
  minApplyValue?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "compensationPolicyCommission" */
export type CompensationPolicyCommission_Avg_Order_By = {
  compensationAmount?: InputMaybe<Order_By>;
  maxApplyValue?: InputMaybe<Order_By>;
  minApplyValue?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "compensationPolicyCommission". All fields are combined with a logical 'AND'. */
export type CompensationPolicyCommission_Bool_Exp = {
  _and?: InputMaybe<Array<CompensationPolicyCommission_Bool_Exp>>;
  _not?: InputMaybe<CompensationPolicyCommission_Bool_Exp>;
  _or?: InputMaybe<Array<CompensationPolicyCommission_Bool_Exp>>;
  compensationAmount?: InputMaybe<Int_Comparison_Exp>;
  compensationPolicy?: InputMaybe<CompensationPolicy_Bool_Exp>;
  compensationPolicyId?: InputMaybe<Uuid_Comparison_Exp>;
  compensationType?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  maxApplyValue?: InputMaybe<Int_Comparison_Exp>;
  membership?: InputMaybe<Membership_Bool_Exp>;
  membershipId?: InputMaybe<Uuid_Comparison_Exp>;
  minApplyValue?: InputMaybe<Int_Comparison_Exp>;
  package?: InputMaybe<Package_Bool_Exp>;
  packageId?: InputMaybe<Uuid_Comparison_Exp>;
  service?: InputMaybe<Service_Bool_Exp>;
  serviceId?: InputMaybe<Uuid_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  workspace?: InputMaybe<Workspace_Bool_Exp>;
  workspaceId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "compensationPolicyCommission" */
export enum CompensationPolicyCommission_Constraint {
  /** unique or primary key constraint on columns "id" */
  CompensationPolicyCommissionPkey = 'compensationPolicyCommission_pkey'
}

/** input type for incrementing numeric columns in table "compensationPolicyCommission" */
export type CompensationPolicyCommission_Inc_Input = {
  compensationAmount?: InputMaybe<Scalars['Int']>;
  maxApplyValue?: InputMaybe<Scalars['Int']>;
  minApplyValue?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "compensationPolicyCommission" */
export type CompensationPolicyCommission_Insert_Input = {
  compensationAmount?: InputMaybe<Scalars['Int']>;
  compensationPolicy?: InputMaybe<CompensationPolicy_Obj_Rel_Insert_Input>;
  compensationPolicyId?: InputMaybe<Scalars['uuid']>;
  compensationType?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  maxApplyValue?: InputMaybe<Scalars['Int']>;
  membership?: InputMaybe<Membership_Obj_Rel_Insert_Input>;
  membershipId?: InputMaybe<Scalars['uuid']>;
  minApplyValue?: InputMaybe<Scalars['Int']>;
  package?: InputMaybe<Package_Obj_Rel_Insert_Input>;
  packageId?: InputMaybe<Scalars['uuid']>;
  service?: InputMaybe<Service_Obj_Rel_Insert_Input>;
  serviceId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspace?: InputMaybe<Workspace_Obj_Rel_Insert_Input>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type CompensationPolicyCommission_Max_Fields = {
  __typename?: 'compensationPolicyCommission_max_fields';
  compensationAmount?: Maybe<Scalars['Int']>;
  compensationPolicyId?: Maybe<Scalars['uuid']>;
  compensationType?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  maxApplyValue?: Maybe<Scalars['Int']>;
  membershipId?: Maybe<Scalars['uuid']>;
  minApplyValue?: Maybe<Scalars['Int']>;
  packageId?: Maybe<Scalars['uuid']>;
  serviceId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "compensationPolicyCommission" */
export type CompensationPolicyCommission_Max_Order_By = {
  compensationAmount?: InputMaybe<Order_By>;
  compensationPolicyId?: InputMaybe<Order_By>;
  compensationType?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  maxApplyValue?: InputMaybe<Order_By>;
  membershipId?: InputMaybe<Order_By>;
  minApplyValue?: InputMaybe<Order_By>;
  packageId?: InputMaybe<Order_By>;
  serviceId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type CompensationPolicyCommission_Min_Fields = {
  __typename?: 'compensationPolicyCommission_min_fields';
  compensationAmount?: Maybe<Scalars['Int']>;
  compensationPolicyId?: Maybe<Scalars['uuid']>;
  compensationType?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  maxApplyValue?: Maybe<Scalars['Int']>;
  membershipId?: Maybe<Scalars['uuid']>;
  minApplyValue?: Maybe<Scalars['Int']>;
  packageId?: Maybe<Scalars['uuid']>;
  serviceId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "compensationPolicyCommission" */
export type CompensationPolicyCommission_Min_Order_By = {
  compensationAmount?: InputMaybe<Order_By>;
  compensationPolicyId?: InputMaybe<Order_By>;
  compensationType?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  maxApplyValue?: InputMaybe<Order_By>;
  membershipId?: InputMaybe<Order_By>;
  minApplyValue?: InputMaybe<Order_By>;
  packageId?: InputMaybe<Order_By>;
  serviceId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "compensationPolicyCommission" */
export type CompensationPolicyCommission_Mutation_Response = {
  __typename?: 'compensationPolicyCommission_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CompensationPolicyCommission>;
};

/** on_conflict condition type for table "compensationPolicyCommission" */
export type CompensationPolicyCommission_On_Conflict = {
  constraint: CompensationPolicyCommission_Constraint;
  update_columns?: Array<CompensationPolicyCommission_Update_Column>;
  where?: InputMaybe<CompensationPolicyCommission_Bool_Exp>;
};

/** Ordering options when selecting data from "compensationPolicyCommission". */
export type CompensationPolicyCommission_Order_By = {
  compensationAmount?: InputMaybe<Order_By>;
  compensationPolicy?: InputMaybe<CompensationPolicy_Order_By>;
  compensationPolicyId?: InputMaybe<Order_By>;
  compensationType?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  maxApplyValue?: InputMaybe<Order_By>;
  membership?: InputMaybe<Membership_Order_By>;
  membershipId?: InputMaybe<Order_By>;
  minApplyValue?: InputMaybe<Order_By>;
  package?: InputMaybe<Package_Order_By>;
  packageId?: InputMaybe<Order_By>;
  service?: InputMaybe<Service_Order_By>;
  serviceId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  workspace?: InputMaybe<Workspace_Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: compensationPolicyCommission */
export type CompensationPolicyCommission_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "compensationPolicyCommission" */
export enum CompensationPolicyCommission_Select_Column {
  /** column name */
  CompensationAmount = 'compensationAmount',
  /** column name */
  CompensationPolicyId = 'compensationPolicyId',
  /** column name */
  CompensationType = 'compensationType',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  MaxApplyValue = 'maxApplyValue',
  /** column name */
  MembershipId = 'membershipId',
  /** column name */
  MinApplyValue = 'minApplyValue',
  /** column name */
  PackageId = 'packageId',
  /** column name */
  ServiceId = 'serviceId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WorkspaceId = 'workspaceId'
}

/** input type for updating data in table "compensationPolicyCommission" */
export type CompensationPolicyCommission_Set_Input = {
  compensationAmount?: InputMaybe<Scalars['Int']>;
  compensationPolicyId?: InputMaybe<Scalars['uuid']>;
  compensationType?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  maxApplyValue?: InputMaybe<Scalars['Int']>;
  membershipId?: InputMaybe<Scalars['uuid']>;
  minApplyValue?: InputMaybe<Scalars['Int']>;
  packageId?: InputMaybe<Scalars['uuid']>;
  serviceId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type CompensationPolicyCommission_Stddev_Fields = {
  __typename?: 'compensationPolicyCommission_stddev_fields';
  compensationAmount?: Maybe<Scalars['Float']>;
  maxApplyValue?: Maybe<Scalars['Float']>;
  minApplyValue?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "compensationPolicyCommission" */
export type CompensationPolicyCommission_Stddev_Order_By = {
  compensationAmount?: InputMaybe<Order_By>;
  maxApplyValue?: InputMaybe<Order_By>;
  minApplyValue?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type CompensationPolicyCommission_Stddev_Pop_Fields = {
  __typename?: 'compensationPolicyCommission_stddev_pop_fields';
  compensationAmount?: Maybe<Scalars['Float']>;
  maxApplyValue?: Maybe<Scalars['Float']>;
  minApplyValue?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "compensationPolicyCommission" */
export type CompensationPolicyCommission_Stddev_Pop_Order_By = {
  compensationAmount?: InputMaybe<Order_By>;
  maxApplyValue?: InputMaybe<Order_By>;
  minApplyValue?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type CompensationPolicyCommission_Stddev_Samp_Fields = {
  __typename?: 'compensationPolicyCommission_stddev_samp_fields';
  compensationAmount?: Maybe<Scalars['Float']>;
  maxApplyValue?: Maybe<Scalars['Float']>;
  minApplyValue?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "compensationPolicyCommission" */
export type CompensationPolicyCommission_Stddev_Samp_Order_By = {
  compensationAmount?: InputMaybe<Order_By>;
  maxApplyValue?: InputMaybe<Order_By>;
  minApplyValue?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "compensationPolicyCommission" */
export type CompensationPolicyCommission_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: CompensationPolicyCommission_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type CompensationPolicyCommission_Stream_Cursor_Value_Input = {
  compensationAmount?: InputMaybe<Scalars['Int']>;
  compensationPolicyId?: InputMaybe<Scalars['uuid']>;
  compensationType?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  maxApplyValue?: InputMaybe<Scalars['Int']>;
  membershipId?: InputMaybe<Scalars['uuid']>;
  minApplyValue?: InputMaybe<Scalars['Int']>;
  packageId?: InputMaybe<Scalars['uuid']>;
  serviceId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type CompensationPolicyCommission_Sum_Fields = {
  __typename?: 'compensationPolicyCommission_sum_fields';
  compensationAmount?: Maybe<Scalars['Int']>;
  maxApplyValue?: Maybe<Scalars['Int']>;
  minApplyValue?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "compensationPolicyCommission" */
export type CompensationPolicyCommission_Sum_Order_By = {
  compensationAmount?: InputMaybe<Order_By>;
  maxApplyValue?: InputMaybe<Order_By>;
  minApplyValue?: InputMaybe<Order_By>;
};

/** update columns of table "compensationPolicyCommission" */
export enum CompensationPolicyCommission_Update_Column {
  /** column name */
  CompensationAmount = 'compensationAmount',
  /** column name */
  CompensationPolicyId = 'compensationPolicyId',
  /** column name */
  CompensationType = 'compensationType',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  MaxApplyValue = 'maxApplyValue',
  /** column name */
  MembershipId = 'membershipId',
  /** column name */
  MinApplyValue = 'minApplyValue',
  /** column name */
  PackageId = 'packageId',
  /** column name */
  ServiceId = 'serviceId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WorkspaceId = 'workspaceId'
}

export type CompensationPolicyCommission_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<CompensationPolicyCommission_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CompensationPolicyCommission_Set_Input>;
  /** filter the rows which have to be updated */
  where: CompensationPolicyCommission_Bool_Exp;
};

/** aggregate var_pop on columns */
export type CompensationPolicyCommission_Var_Pop_Fields = {
  __typename?: 'compensationPolicyCommission_var_pop_fields';
  compensationAmount?: Maybe<Scalars['Float']>;
  maxApplyValue?: Maybe<Scalars['Float']>;
  minApplyValue?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "compensationPolicyCommission" */
export type CompensationPolicyCommission_Var_Pop_Order_By = {
  compensationAmount?: InputMaybe<Order_By>;
  maxApplyValue?: InputMaybe<Order_By>;
  minApplyValue?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type CompensationPolicyCommission_Var_Samp_Fields = {
  __typename?: 'compensationPolicyCommission_var_samp_fields';
  compensationAmount?: Maybe<Scalars['Float']>;
  maxApplyValue?: Maybe<Scalars['Float']>;
  minApplyValue?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "compensationPolicyCommission" */
export type CompensationPolicyCommission_Var_Samp_Order_By = {
  compensationAmount?: InputMaybe<Order_By>;
  maxApplyValue?: InputMaybe<Order_By>;
  minApplyValue?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type CompensationPolicyCommission_Variance_Fields = {
  __typename?: 'compensationPolicyCommission_variance_fields';
  compensationAmount?: Maybe<Scalars['Float']>;
  maxApplyValue?: Maybe<Scalars['Float']>;
  minApplyValue?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "compensationPolicyCommission" */
export type CompensationPolicyCommission_Variance_Order_By = {
  compensationAmount?: InputMaybe<Order_By>;
  maxApplyValue?: InputMaybe<Order_By>;
  minApplyValue?: InputMaybe<Order_By>;
};

/** columns and relationships of "compensationPolicyTier" */
export type CompensationPolicyTier = {
  __typename?: 'compensationPolicyTier';
  compensationAmount: Scalars['Int'];
  compensationPolicyId: Scalars['uuid'];
  compensationType: Scalars['String'];
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  maxApplyValue: Scalars['Int'];
  minApplyValue: Scalars['Int'];
  name: Scalars['String'];
  /** An object relationship */
  policy: CompensationPolicy;
  updatedAt: Scalars['timestamptz'];
  /** An object relationship */
  workspace: Workspace;
  workspaceId: Scalars['uuid'];
};

/** columns and relationships of "compensationPolicyTierCategory" */
export type CompensationPolicyTierCategory = {
  __typename?: 'compensationPolicyTierCategory';
  compensationPolicyId: Scalars['uuid'];
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An object relationship */
  policy: CompensationPolicy;
  /** An object relationship */
  serviceCategory: ServiceCategory;
  serviceCategoryId: Scalars['uuid'];
  updatedAt: Scalars['timestamptz'];
};

/** aggregated selection of "compensationPolicyTierCategory" */
export type CompensationPolicyTierCategory_Aggregate = {
  __typename?: 'compensationPolicyTierCategory_aggregate';
  aggregate?: Maybe<CompensationPolicyTierCategory_Aggregate_Fields>;
  nodes: Array<CompensationPolicyTierCategory>;
};

export type CompensationPolicyTierCategory_Aggregate_Bool_Exp = {
  count?: InputMaybe<CompensationPolicyTierCategory_Aggregate_Bool_Exp_Count>;
};

export type CompensationPolicyTierCategory_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<CompensationPolicyTierCategory_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<CompensationPolicyTierCategory_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "compensationPolicyTierCategory" */
export type CompensationPolicyTierCategory_Aggregate_Fields = {
  __typename?: 'compensationPolicyTierCategory_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<CompensationPolicyTierCategory_Max_Fields>;
  min?: Maybe<CompensationPolicyTierCategory_Min_Fields>;
};


/** aggregate fields of "compensationPolicyTierCategory" */
export type CompensationPolicyTierCategory_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<CompensationPolicyTierCategory_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "compensationPolicyTierCategory" */
export type CompensationPolicyTierCategory_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<CompensationPolicyTierCategory_Max_Order_By>;
  min?: InputMaybe<CompensationPolicyTierCategory_Min_Order_By>;
};

/** input type for inserting array relation for remote table "compensationPolicyTierCategory" */
export type CompensationPolicyTierCategory_Arr_Rel_Insert_Input = {
  data: Array<CompensationPolicyTierCategory_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<CompensationPolicyTierCategory_On_Conflict>;
};

/** Boolean expression to filter rows from the table "compensationPolicyTierCategory". All fields are combined with a logical 'AND'. */
export type CompensationPolicyTierCategory_Bool_Exp = {
  _and?: InputMaybe<Array<CompensationPolicyTierCategory_Bool_Exp>>;
  _not?: InputMaybe<CompensationPolicyTierCategory_Bool_Exp>;
  _or?: InputMaybe<Array<CompensationPolicyTierCategory_Bool_Exp>>;
  compensationPolicyId?: InputMaybe<Uuid_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  policy?: InputMaybe<CompensationPolicy_Bool_Exp>;
  serviceCategory?: InputMaybe<ServiceCategory_Bool_Exp>;
  serviceCategoryId?: InputMaybe<Uuid_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "compensationPolicyTierCategory" */
export enum CompensationPolicyTierCategory_Constraint {
  /** unique or primary key constraint on columns "id" */
  CompensationPolicyTierCategoryPkey = 'compensationPolicyTierCategory_pkey'
}

/** input type for inserting data into table "compensationPolicyTierCategory" */
export type CompensationPolicyTierCategory_Insert_Input = {
  compensationPolicyId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  policy?: InputMaybe<CompensationPolicy_Obj_Rel_Insert_Input>;
  serviceCategory?: InputMaybe<ServiceCategory_Obj_Rel_Insert_Input>;
  serviceCategoryId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type CompensationPolicyTierCategory_Max_Fields = {
  __typename?: 'compensationPolicyTierCategory_max_fields';
  compensationPolicyId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  serviceCategoryId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "compensationPolicyTierCategory" */
export type CompensationPolicyTierCategory_Max_Order_By = {
  compensationPolicyId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  serviceCategoryId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type CompensationPolicyTierCategory_Min_Fields = {
  __typename?: 'compensationPolicyTierCategory_min_fields';
  compensationPolicyId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  serviceCategoryId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "compensationPolicyTierCategory" */
export type CompensationPolicyTierCategory_Min_Order_By = {
  compensationPolicyId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  serviceCategoryId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "compensationPolicyTierCategory" */
export type CompensationPolicyTierCategory_Mutation_Response = {
  __typename?: 'compensationPolicyTierCategory_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CompensationPolicyTierCategory>;
};

/** on_conflict condition type for table "compensationPolicyTierCategory" */
export type CompensationPolicyTierCategory_On_Conflict = {
  constraint: CompensationPolicyTierCategory_Constraint;
  update_columns?: Array<CompensationPolicyTierCategory_Update_Column>;
  where?: InputMaybe<CompensationPolicyTierCategory_Bool_Exp>;
};

/** Ordering options when selecting data from "compensationPolicyTierCategory". */
export type CompensationPolicyTierCategory_Order_By = {
  compensationPolicyId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  policy?: InputMaybe<CompensationPolicy_Order_By>;
  serviceCategory?: InputMaybe<ServiceCategory_Order_By>;
  serviceCategoryId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: compensationPolicyTierCategory */
export type CompensationPolicyTierCategory_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "compensationPolicyTierCategory" */
export enum CompensationPolicyTierCategory_Select_Column {
  /** column name */
  CompensationPolicyId = 'compensationPolicyId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  ServiceCategoryId = 'serviceCategoryId',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "compensationPolicyTierCategory" */
export type CompensationPolicyTierCategory_Set_Input = {
  compensationPolicyId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  serviceCategoryId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "compensationPolicyTierCategory" */
export type CompensationPolicyTierCategory_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: CompensationPolicyTierCategory_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type CompensationPolicyTierCategory_Stream_Cursor_Value_Input = {
  compensationPolicyId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  serviceCategoryId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "compensationPolicyTierCategory" */
export enum CompensationPolicyTierCategory_Update_Column {
  /** column name */
  CompensationPolicyId = 'compensationPolicyId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  ServiceCategoryId = 'serviceCategoryId',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type CompensationPolicyTierCategory_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CompensationPolicyTierCategory_Set_Input>;
  /** filter the rows which have to be updated */
  where: CompensationPolicyTierCategory_Bool_Exp;
};

/** columns and relationships of "compensationPolicyTierExemption" */
export type CompensationPolicyTierExemption = {
  __typename?: 'compensationPolicyTierExemption';
  compensationPolicyId: Scalars['uuid'];
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An object relationship */
  membership?: Maybe<Membership>;
  membershipId?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  package?: Maybe<Package>;
  packageId?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  policy: CompensationPolicy;
  /** An object relationship */
  service?: Maybe<Service>;
  serviceId?: Maybe<Scalars['uuid']>;
  updatedAt: Scalars['timestamptz'];
};

/** aggregated selection of "compensationPolicyTierExemption" */
export type CompensationPolicyTierExemption_Aggregate = {
  __typename?: 'compensationPolicyTierExemption_aggregate';
  aggregate?: Maybe<CompensationPolicyTierExemption_Aggregate_Fields>;
  nodes: Array<CompensationPolicyTierExemption>;
};

export type CompensationPolicyTierExemption_Aggregate_Bool_Exp = {
  count?: InputMaybe<CompensationPolicyTierExemption_Aggregate_Bool_Exp_Count>;
};

export type CompensationPolicyTierExemption_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<CompensationPolicyTierExemption_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<CompensationPolicyTierExemption_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "compensationPolicyTierExemption" */
export type CompensationPolicyTierExemption_Aggregate_Fields = {
  __typename?: 'compensationPolicyTierExemption_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<CompensationPolicyTierExemption_Max_Fields>;
  min?: Maybe<CompensationPolicyTierExemption_Min_Fields>;
};


/** aggregate fields of "compensationPolicyTierExemption" */
export type CompensationPolicyTierExemption_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<CompensationPolicyTierExemption_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "compensationPolicyTierExemption" */
export type CompensationPolicyTierExemption_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<CompensationPolicyTierExemption_Max_Order_By>;
  min?: InputMaybe<CompensationPolicyTierExemption_Min_Order_By>;
};

/** input type for inserting array relation for remote table "compensationPolicyTierExemption" */
export type CompensationPolicyTierExemption_Arr_Rel_Insert_Input = {
  data: Array<CompensationPolicyTierExemption_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<CompensationPolicyTierExemption_On_Conflict>;
};

/** Boolean expression to filter rows from the table "compensationPolicyTierExemption". All fields are combined with a logical 'AND'. */
export type CompensationPolicyTierExemption_Bool_Exp = {
  _and?: InputMaybe<Array<CompensationPolicyTierExemption_Bool_Exp>>;
  _not?: InputMaybe<CompensationPolicyTierExemption_Bool_Exp>;
  _or?: InputMaybe<Array<CompensationPolicyTierExemption_Bool_Exp>>;
  compensationPolicyId?: InputMaybe<Uuid_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  membership?: InputMaybe<Membership_Bool_Exp>;
  membershipId?: InputMaybe<Uuid_Comparison_Exp>;
  package?: InputMaybe<Package_Bool_Exp>;
  packageId?: InputMaybe<Uuid_Comparison_Exp>;
  policy?: InputMaybe<CompensationPolicy_Bool_Exp>;
  service?: InputMaybe<Service_Bool_Exp>;
  serviceId?: InputMaybe<Uuid_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "compensationPolicyTierExemption" */
export enum CompensationPolicyTierExemption_Constraint {
  /** unique or primary key constraint on columns "id" */
  CompensationPolicyTierExemptionPkey = 'compensationPolicyTierExemption_pkey'
}

/** input type for inserting data into table "compensationPolicyTierExemption" */
export type CompensationPolicyTierExemption_Insert_Input = {
  compensationPolicyId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  membership?: InputMaybe<Membership_Obj_Rel_Insert_Input>;
  membershipId?: InputMaybe<Scalars['uuid']>;
  package?: InputMaybe<Package_Obj_Rel_Insert_Input>;
  packageId?: InputMaybe<Scalars['uuid']>;
  policy?: InputMaybe<CompensationPolicy_Obj_Rel_Insert_Input>;
  service?: InputMaybe<Service_Obj_Rel_Insert_Input>;
  serviceId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type CompensationPolicyTierExemption_Max_Fields = {
  __typename?: 'compensationPolicyTierExemption_max_fields';
  compensationPolicyId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  membershipId?: Maybe<Scalars['uuid']>;
  packageId?: Maybe<Scalars['uuid']>;
  serviceId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "compensationPolicyTierExemption" */
export type CompensationPolicyTierExemption_Max_Order_By = {
  compensationPolicyId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  membershipId?: InputMaybe<Order_By>;
  packageId?: InputMaybe<Order_By>;
  serviceId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type CompensationPolicyTierExemption_Min_Fields = {
  __typename?: 'compensationPolicyTierExemption_min_fields';
  compensationPolicyId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  membershipId?: Maybe<Scalars['uuid']>;
  packageId?: Maybe<Scalars['uuid']>;
  serviceId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "compensationPolicyTierExemption" */
export type CompensationPolicyTierExemption_Min_Order_By = {
  compensationPolicyId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  membershipId?: InputMaybe<Order_By>;
  packageId?: InputMaybe<Order_By>;
  serviceId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "compensationPolicyTierExemption" */
export type CompensationPolicyTierExemption_Mutation_Response = {
  __typename?: 'compensationPolicyTierExemption_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CompensationPolicyTierExemption>;
};

/** on_conflict condition type for table "compensationPolicyTierExemption" */
export type CompensationPolicyTierExemption_On_Conflict = {
  constraint: CompensationPolicyTierExemption_Constraint;
  update_columns?: Array<CompensationPolicyTierExemption_Update_Column>;
  where?: InputMaybe<CompensationPolicyTierExemption_Bool_Exp>;
};

/** Ordering options when selecting data from "compensationPolicyTierExemption". */
export type CompensationPolicyTierExemption_Order_By = {
  compensationPolicyId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  membership?: InputMaybe<Membership_Order_By>;
  membershipId?: InputMaybe<Order_By>;
  package?: InputMaybe<Package_Order_By>;
  packageId?: InputMaybe<Order_By>;
  policy?: InputMaybe<CompensationPolicy_Order_By>;
  service?: InputMaybe<Service_Order_By>;
  serviceId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: compensationPolicyTierExemption */
export type CompensationPolicyTierExemption_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "compensationPolicyTierExemption" */
export enum CompensationPolicyTierExemption_Select_Column {
  /** column name */
  CompensationPolicyId = 'compensationPolicyId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  MembershipId = 'membershipId',
  /** column name */
  PackageId = 'packageId',
  /** column name */
  ServiceId = 'serviceId',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "compensationPolicyTierExemption" */
export type CompensationPolicyTierExemption_Set_Input = {
  compensationPolicyId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  membershipId?: InputMaybe<Scalars['uuid']>;
  packageId?: InputMaybe<Scalars['uuid']>;
  serviceId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "compensationPolicyTierExemption" */
export type CompensationPolicyTierExemption_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: CompensationPolicyTierExemption_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type CompensationPolicyTierExemption_Stream_Cursor_Value_Input = {
  compensationPolicyId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  membershipId?: InputMaybe<Scalars['uuid']>;
  packageId?: InputMaybe<Scalars['uuid']>;
  serviceId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "compensationPolicyTierExemption" */
export enum CompensationPolicyTierExemption_Update_Column {
  /** column name */
  CompensationPolicyId = 'compensationPolicyId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  MembershipId = 'membershipId',
  /** column name */
  PackageId = 'packageId',
  /** column name */
  ServiceId = 'serviceId',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type CompensationPolicyTierExemption_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CompensationPolicyTierExemption_Set_Input>;
  /** filter the rows which have to be updated */
  where: CompensationPolicyTierExemption_Bool_Exp;
};

/** columns and relationships of "compensationPolicyTierInclusion" */
export type CompensationPolicyTierInclusion = {
  __typename?: 'compensationPolicyTierInclusion';
  compensationPolicyId: Scalars['uuid'];
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An object relationship */
  membership?: Maybe<Membership>;
  membershipId?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  package?: Maybe<Package>;
  packageId?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  policy: CompensationPolicy;
  /** An object relationship */
  service?: Maybe<Service>;
  serviceId?: Maybe<Scalars['uuid']>;
  updatedAt: Scalars['timestamptz'];
};

/** aggregated selection of "compensationPolicyTierInclusion" */
export type CompensationPolicyTierInclusion_Aggregate = {
  __typename?: 'compensationPolicyTierInclusion_aggregate';
  aggregate?: Maybe<CompensationPolicyTierInclusion_Aggregate_Fields>;
  nodes: Array<CompensationPolicyTierInclusion>;
};

export type CompensationPolicyTierInclusion_Aggregate_Bool_Exp = {
  count?: InputMaybe<CompensationPolicyTierInclusion_Aggregate_Bool_Exp_Count>;
};

export type CompensationPolicyTierInclusion_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<CompensationPolicyTierInclusion_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<CompensationPolicyTierInclusion_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "compensationPolicyTierInclusion" */
export type CompensationPolicyTierInclusion_Aggregate_Fields = {
  __typename?: 'compensationPolicyTierInclusion_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<CompensationPolicyTierInclusion_Max_Fields>;
  min?: Maybe<CompensationPolicyTierInclusion_Min_Fields>;
};


/** aggregate fields of "compensationPolicyTierInclusion" */
export type CompensationPolicyTierInclusion_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<CompensationPolicyTierInclusion_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "compensationPolicyTierInclusion" */
export type CompensationPolicyTierInclusion_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<CompensationPolicyTierInclusion_Max_Order_By>;
  min?: InputMaybe<CompensationPolicyTierInclusion_Min_Order_By>;
};

/** input type for inserting array relation for remote table "compensationPolicyTierInclusion" */
export type CompensationPolicyTierInclusion_Arr_Rel_Insert_Input = {
  data: Array<CompensationPolicyTierInclusion_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<CompensationPolicyTierInclusion_On_Conflict>;
};

/** Boolean expression to filter rows from the table "compensationPolicyTierInclusion". All fields are combined with a logical 'AND'. */
export type CompensationPolicyTierInclusion_Bool_Exp = {
  _and?: InputMaybe<Array<CompensationPolicyTierInclusion_Bool_Exp>>;
  _not?: InputMaybe<CompensationPolicyTierInclusion_Bool_Exp>;
  _or?: InputMaybe<Array<CompensationPolicyTierInclusion_Bool_Exp>>;
  compensationPolicyId?: InputMaybe<Uuid_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  membership?: InputMaybe<Membership_Bool_Exp>;
  membershipId?: InputMaybe<Uuid_Comparison_Exp>;
  package?: InputMaybe<Package_Bool_Exp>;
  packageId?: InputMaybe<Uuid_Comparison_Exp>;
  policy?: InputMaybe<CompensationPolicy_Bool_Exp>;
  service?: InputMaybe<Service_Bool_Exp>;
  serviceId?: InputMaybe<Uuid_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "compensationPolicyTierInclusion" */
export enum CompensationPolicyTierInclusion_Constraint {
  /** unique or primary key constraint on columns "id" */
  CompensationPolicyTierInclusionPkey = 'compensationPolicyTierInclusion_pkey'
}

/** input type for inserting data into table "compensationPolicyTierInclusion" */
export type CompensationPolicyTierInclusion_Insert_Input = {
  compensationPolicyId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  membership?: InputMaybe<Membership_Obj_Rel_Insert_Input>;
  membershipId?: InputMaybe<Scalars['uuid']>;
  package?: InputMaybe<Package_Obj_Rel_Insert_Input>;
  packageId?: InputMaybe<Scalars['uuid']>;
  policy?: InputMaybe<CompensationPolicy_Obj_Rel_Insert_Input>;
  service?: InputMaybe<Service_Obj_Rel_Insert_Input>;
  serviceId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type CompensationPolicyTierInclusion_Max_Fields = {
  __typename?: 'compensationPolicyTierInclusion_max_fields';
  compensationPolicyId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  membershipId?: Maybe<Scalars['uuid']>;
  packageId?: Maybe<Scalars['uuid']>;
  serviceId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "compensationPolicyTierInclusion" */
export type CompensationPolicyTierInclusion_Max_Order_By = {
  compensationPolicyId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  membershipId?: InputMaybe<Order_By>;
  packageId?: InputMaybe<Order_By>;
  serviceId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type CompensationPolicyTierInclusion_Min_Fields = {
  __typename?: 'compensationPolicyTierInclusion_min_fields';
  compensationPolicyId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  membershipId?: Maybe<Scalars['uuid']>;
  packageId?: Maybe<Scalars['uuid']>;
  serviceId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "compensationPolicyTierInclusion" */
export type CompensationPolicyTierInclusion_Min_Order_By = {
  compensationPolicyId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  membershipId?: InputMaybe<Order_By>;
  packageId?: InputMaybe<Order_By>;
  serviceId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "compensationPolicyTierInclusion" */
export type CompensationPolicyTierInclusion_Mutation_Response = {
  __typename?: 'compensationPolicyTierInclusion_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CompensationPolicyTierInclusion>;
};

/** on_conflict condition type for table "compensationPolicyTierInclusion" */
export type CompensationPolicyTierInclusion_On_Conflict = {
  constraint: CompensationPolicyTierInclusion_Constraint;
  update_columns?: Array<CompensationPolicyTierInclusion_Update_Column>;
  where?: InputMaybe<CompensationPolicyTierInclusion_Bool_Exp>;
};

/** Ordering options when selecting data from "compensationPolicyTierInclusion". */
export type CompensationPolicyTierInclusion_Order_By = {
  compensationPolicyId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  membership?: InputMaybe<Membership_Order_By>;
  membershipId?: InputMaybe<Order_By>;
  package?: InputMaybe<Package_Order_By>;
  packageId?: InputMaybe<Order_By>;
  policy?: InputMaybe<CompensationPolicy_Order_By>;
  service?: InputMaybe<Service_Order_By>;
  serviceId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: compensationPolicyTierInclusion */
export type CompensationPolicyTierInclusion_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "compensationPolicyTierInclusion" */
export enum CompensationPolicyTierInclusion_Select_Column {
  /** column name */
  CompensationPolicyId = 'compensationPolicyId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  MembershipId = 'membershipId',
  /** column name */
  PackageId = 'packageId',
  /** column name */
  ServiceId = 'serviceId',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "compensationPolicyTierInclusion" */
export type CompensationPolicyTierInclusion_Set_Input = {
  compensationPolicyId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  membershipId?: InputMaybe<Scalars['uuid']>;
  packageId?: InputMaybe<Scalars['uuid']>;
  serviceId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "compensationPolicyTierInclusion" */
export type CompensationPolicyTierInclusion_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: CompensationPolicyTierInclusion_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type CompensationPolicyTierInclusion_Stream_Cursor_Value_Input = {
  compensationPolicyId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  membershipId?: InputMaybe<Scalars['uuid']>;
  packageId?: InputMaybe<Scalars['uuid']>;
  serviceId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "compensationPolicyTierInclusion" */
export enum CompensationPolicyTierInclusion_Update_Column {
  /** column name */
  CompensationPolicyId = 'compensationPolicyId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  MembershipId = 'membershipId',
  /** column name */
  PackageId = 'packageId',
  /** column name */
  ServiceId = 'serviceId',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type CompensationPolicyTierInclusion_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CompensationPolicyTierInclusion_Set_Input>;
  /** filter the rows which have to be updated */
  where: CompensationPolicyTierInclusion_Bool_Exp;
};

/** aggregated selection of "compensationPolicyTier" */
export type CompensationPolicyTier_Aggregate = {
  __typename?: 'compensationPolicyTier_aggregate';
  aggregate?: Maybe<CompensationPolicyTier_Aggregate_Fields>;
  nodes: Array<CompensationPolicyTier>;
};

export type CompensationPolicyTier_Aggregate_Bool_Exp = {
  count?: InputMaybe<CompensationPolicyTier_Aggregate_Bool_Exp_Count>;
};

export type CompensationPolicyTier_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<CompensationPolicyTier_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<CompensationPolicyTier_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "compensationPolicyTier" */
export type CompensationPolicyTier_Aggregate_Fields = {
  __typename?: 'compensationPolicyTier_aggregate_fields';
  avg?: Maybe<CompensationPolicyTier_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<CompensationPolicyTier_Max_Fields>;
  min?: Maybe<CompensationPolicyTier_Min_Fields>;
  stddev?: Maybe<CompensationPolicyTier_Stddev_Fields>;
  stddev_pop?: Maybe<CompensationPolicyTier_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<CompensationPolicyTier_Stddev_Samp_Fields>;
  sum?: Maybe<CompensationPolicyTier_Sum_Fields>;
  var_pop?: Maybe<CompensationPolicyTier_Var_Pop_Fields>;
  var_samp?: Maybe<CompensationPolicyTier_Var_Samp_Fields>;
  variance?: Maybe<CompensationPolicyTier_Variance_Fields>;
};


/** aggregate fields of "compensationPolicyTier" */
export type CompensationPolicyTier_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<CompensationPolicyTier_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "compensationPolicyTier" */
export type CompensationPolicyTier_Aggregate_Order_By = {
  avg?: InputMaybe<CompensationPolicyTier_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<CompensationPolicyTier_Max_Order_By>;
  min?: InputMaybe<CompensationPolicyTier_Min_Order_By>;
  stddev?: InputMaybe<CompensationPolicyTier_Stddev_Order_By>;
  stddev_pop?: InputMaybe<CompensationPolicyTier_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<CompensationPolicyTier_Stddev_Samp_Order_By>;
  sum?: InputMaybe<CompensationPolicyTier_Sum_Order_By>;
  var_pop?: InputMaybe<CompensationPolicyTier_Var_Pop_Order_By>;
  var_samp?: InputMaybe<CompensationPolicyTier_Var_Samp_Order_By>;
  variance?: InputMaybe<CompensationPolicyTier_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "compensationPolicyTier" */
export type CompensationPolicyTier_Arr_Rel_Insert_Input = {
  data: Array<CompensationPolicyTier_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<CompensationPolicyTier_On_Conflict>;
};

/** aggregate avg on columns */
export type CompensationPolicyTier_Avg_Fields = {
  __typename?: 'compensationPolicyTier_avg_fields';
  compensationAmount?: Maybe<Scalars['Float']>;
  maxApplyValue?: Maybe<Scalars['Float']>;
  minApplyValue?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "compensationPolicyTier" */
export type CompensationPolicyTier_Avg_Order_By = {
  compensationAmount?: InputMaybe<Order_By>;
  maxApplyValue?: InputMaybe<Order_By>;
  minApplyValue?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "compensationPolicyTier". All fields are combined with a logical 'AND'. */
export type CompensationPolicyTier_Bool_Exp = {
  _and?: InputMaybe<Array<CompensationPolicyTier_Bool_Exp>>;
  _not?: InputMaybe<CompensationPolicyTier_Bool_Exp>;
  _or?: InputMaybe<Array<CompensationPolicyTier_Bool_Exp>>;
  compensationAmount?: InputMaybe<Int_Comparison_Exp>;
  compensationPolicyId?: InputMaybe<Uuid_Comparison_Exp>;
  compensationType?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  maxApplyValue?: InputMaybe<Int_Comparison_Exp>;
  minApplyValue?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  policy?: InputMaybe<CompensationPolicy_Bool_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  workspace?: InputMaybe<Workspace_Bool_Exp>;
  workspaceId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "compensationPolicyTier" */
export enum CompensationPolicyTier_Constraint {
  /** unique or primary key constraint on columns "id" */
  CompensationPolicyTierPkey = 'compensationPolicyTier_pkey'
}

/** input type for incrementing numeric columns in table "compensationPolicyTier" */
export type CompensationPolicyTier_Inc_Input = {
  compensationAmount?: InputMaybe<Scalars['Int']>;
  maxApplyValue?: InputMaybe<Scalars['Int']>;
  minApplyValue?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "compensationPolicyTier" */
export type CompensationPolicyTier_Insert_Input = {
  compensationAmount?: InputMaybe<Scalars['Int']>;
  compensationPolicyId?: InputMaybe<Scalars['uuid']>;
  compensationType?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  maxApplyValue?: InputMaybe<Scalars['Int']>;
  minApplyValue?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  policy?: InputMaybe<CompensationPolicy_Obj_Rel_Insert_Input>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspace?: InputMaybe<Workspace_Obj_Rel_Insert_Input>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type CompensationPolicyTier_Max_Fields = {
  __typename?: 'compensationPolicyTier_max_fields';
  compensationAmount?: Maybe<Scalars['Int']>;
  compensationPolicyId?: Maybe<Scalars['uuid']>;
  compensationType?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  maxApplyValue?: Maybe<Scalars['Int']>;
  minApplyValue?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "compensationPolicyTier" */
export type CompensationPolicyTier_Max_Order_By = {
  compensationAmount?: InputMaybe<Order_By>;
  compensationPolicyId?: InputMaybe<Order_By>;
  compensationType?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  maxApplyValue?: InputMaybe<Order_By>;
  minApplyValue?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type CompensationPolicyTier_Min_Fields = {
  __typename?: 'compensationPolicyTier_min_fields';
  compensationAmount?: Maybe<Scalars['Int']>;
  compensationPolicyId?: Maybe<Scalars['uuid']>;
  compensationType?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  maxApplyValue?: Maybe<Scalars['Int']>;
  minApplyValue?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "compensationPolicyTier" */
export type CompensationPolicyTier_Min_Order_By = {
  compensationAmount?: InputMaybe<Order_By>;
  compensationPolicyId?: InputMaybe<Order_By>;
  compensationType?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  maxApplyValue?: InputMaybe<Order_By>;
  minApplyValue?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "compensationPolicyTier" */
export type CompensationPolicyTier_Mutation_Response = {
  __typename?: 'compensationPolicyTier_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CompensationPolicyTier>;
};

/** on_conflict condition type for table "compensationPolicyTier" */
export type CompensationPolicyTier_On_Conflict = {
  constraint: CompensationPolicyTier_Constraint;
  update_columns?: Array<CompensationPolicyTier_Update_Column>;
  where?: InputMaybe<CompensationPolicyTier_Bool_Exp>;
};

/** Ordering options when selecting data from "compensationPolicyTier". */
export type CompensationPolicyTier_Order_By = {
  compensationAmount?: InputMaybe<Order_By>;
  compensationPolicyId?: InputMaybe<Order_By>;
  compensationType?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  maxApplyValue?: InputMaybe<Order_By>;
  minApplyValue?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  policy?: InputMaybe<CompensationPolicy_Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  workspace?: InputMaybe<Workspace_Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: compensationPolicyTier */
export type CompensationPolicyTier_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "compensationPolicyTier" */
export enum CompensationPolicyTier_Select_Column {
  /** column name */
  CompensationAmount = 'compensationAmount',
  /** column name */
  CompensationPolicyId = 'compensationPolicyId',
  /** column name */
  CompensationType = 'compensationType',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  MaxApplyValue = 'maxApplyValue',
  /** column name */
  MinApplyValue = 'minApplyValue',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WorkspaceId = 'workspaceId'
}

/** input type for updating data in table "compensationPolicyTier" */
export type CompensationPolicyTier_Set_Input = {
  compensationAmount?: InputMaybe<Scalars['Int']>;
  compensationPolicyId?: InputMaybe<Scalars['uuid']>;
  compensationType?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  maxApplyValue?: InputMaybe<Scalars['Int']>;
  minApplyValue?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type CompensationPolicyTier_Stddev_Fields = {
  __typename?: 'compensationPolicyTier_stddev_fields';
  compensationAmount?: Maybe<Scalars['Float']>;
  maxApplyValue?: Maybe<Scalars['Float']>;
  minApplyValue?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "compensationPolicyTier" */
export type CompensationPolicyTier_Stddev_Order_By = {
  compensationAmount?: InputMaybe<Order_By>;
  maxApplyValue?: InputMaybe<Order_By>;
  minApplyValue?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type CompensationPolicyTier_Stddev_Pop_Fields = {
  __typename?: 'compensationPolicyTier_stddev_pop_fields';
  compensationAmount?: Maybe<Scalars['Float']>;
  maxApplyValue?: Maybe<Scalars['Float']>;
  minApplyValue?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "compensationPolicyTier" */
export type CompensationPolicyTier_Stddev_Pop_Order_By = {
  compensationAmount?: InputMaybe<Order_By>;
  maxApplyValue?: InputMaybe<Order_By>;
  minApplyValue?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type CompensationPolicyTier_Stddev_Samp_Fields = {
  __typename?: 'compensationPolicyTier_stddev_samp_fields';
  compensationAmount?: Maybe<Scalars['Float']>;
  maxApplyValue?: Maybe<Scalars['Float']>;
  minApplyValue?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "compensationPolicyTier" */
export type CompensationPolicyTier_Stddev_Samp_Order_By = {
  compensationAmount?: InputMaybe<Order_By>;
  maxApplyValue?: InputMaybe<Order_By>;
  minApplyValue?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "compensationPolicyTier" */
export type CompensationPolicyTier_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: CompensationPolicyTier_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type CompensationPolicyTier_Stream_Cursor_Value_Input = {
  compensationAmount?: InputMaybe<Scalars['Int']>;
  compensationPolicyId?: InputMaybe<Scalars['uuid']>;
  compensationType?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  maxApplyValue?: InputMaybe<Scalars['Int']>;
  minApplyValue?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type CompensationPolicyTier_Sum_Fields = {
  __typename?: 'compensationPolicyTier_sum_fields';
  compensationAmount?: Maybe<Scalars['Int']>;
  maxApplyValue?: Maybe<Scalars['Int']>;
  minApplyValue?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "compensationPolicyTier" */
export type CompensationPolicyTier_Sum_Order_By = {
  compensationAmount?: InputMaybe<Order_By>;
  maxApplyValue?: InputMaybe<Order_By>;
  minApplyValue?: InputMaybe<Order_By>;
};

/** update columns of table "compensationPolicyTier" */
export enum CompensationPolicyTier_Update_Column {
  /** column name */
  CompensationAmount = 'compensationAmount',
  /** column name */
  CompensationPolicyId = 'compensationPolicyId',
  /** column name */
  CompensationType = 'compensationType',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  MaxApplyValue = 'maxApplyValue',
  /** column name */
  MinApplyValue = 'minApplyValue',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WorkspaceId = 'workspaceId'
}

export type CompensationPolicyTier_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<CompensationPolicyTier_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CompensationPolicyTier_Set_Input>;
  /** filter the rows which have to be updated */
  where: CompensationPolicyTier_Bool_Exp;
};

/** aggregate var_pop on columns */
export type CompensationPolicyTier_Var_Pop_Fields = {
  __typename?: 'compensationPolicyTier_var_pop_fields';
  compensationAmount?: Maybe<Scalars['Float']>;
  maxApplyValue?: Maybe<Scalars['Float']>;
  minApplyValue?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "compensationPolicyTier" */
export type CompensationPolicyTier_Var_Pop_Order_By = {
  compensationAmount?: InputMaybe<Order_By>;
  maxApplyValue?: InputMaybe<Order_By>;
  minApplyValue?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type CompensationPolicyTier_Var_Samp_Fields = {
  __typename?: 'compensationPolicyTier_var_samp_fields';
  compensationAmount?: Maybe<Scalars['Float']>;
  maxApplyValue?: Maybe<Scalars['Float']>;
  minApplyValue?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "compensationPolicyTier" */
export type CompensationPolicyTier_Var_Samp_Order_By = {
  compensationAmount?: InputMaybe<Order_By>;
  maxApplyValue?: InputMaybe<Order_By>;
  minApplyValue?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type CompensationPolicyTier_Variance_Fields = {
  __typename?: 'compensationPolicyTier_variance_fields';
  compensationAmount?: Maybe<Scalars['Float']>;
  maxApplyValue?: Maybe<Scalars['Float']>;
  minApplyValue?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "compensationPolicyTier" */
export type CompensationPolicyTier_Variance_Order_By = {
  compensationAmount?: InputMaybe<Order_By>;
  maxApplyValue?: InputMaybe<Order_By>;
  minApplyValue?: InputMaybe<Order_By>;
};

/** aggregated selection of "compensationPolicy" */
export type CompensationPolicy_Aggregate = {
  __typename?: 'compensationPolicy_aggregate';
  aggregate?: Maybe<CompensationPolicy_Aggregate_Fields>;
  nodes: Array<CompensationPolicy>;
};

export type CompensationPolicy_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<CompensationPolicy_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<CompensationPolicy_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<CompensationPolicy_Aggregate_Bool_Exp_Count>;
};

export type CompensationPolicy_Aggregate_Bool_Exp_Bool_And = {
  arguments: CompensationPolicy_Select_Column_CompensationPolicy_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<CompensationPolicy_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type CompensationPolicy_Aggregate_Bool_Exp_Bool_Or = {
  arguments: CompensationPolicy_Select_Column_CompensationPolicy_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<CompensationPolicy_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type CompensationPolicy_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<CompensationPolicy_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<CompensationPolicy_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "compensationPolicy" */
export type CompensationPolicy_Aggregate_Fields = {
  __typename?: 'compensationPolicy_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<CompensationPolicy_Max_Fields>;
  min?: Maybe<CompensationPolicy_Min_Fields>;
};


/** aggregate fields of "compensationPolicy" */
export type CompensationPolicy_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<CompensationPolicy_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "compensationPolicy" */
export type CompensationPolicy_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<CompensationPolicy_Max_Order_By>;
  min?: InputMaybe<CompensationPolicy_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type CompensationPolicy_Append_Input = {
  config?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "compensationPolicy" */
export type CompensationPolicy_Arr_Rel_Insert_Input = {
  data: Array<CompensationPolicy_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<CompensationPolicy_On_Conflict>;
};

/** Boolean expression to filter rows from the table "compensationPolicy". All fields are combined with a logical 'AND'. */
export type CompensationPolicy_Bool_Exp = {
  _and?: InputMaybe<Array<CompensationPolicy_Bool_Exp>>;
  _not?: InputMaybe<CompensationPolicy_Bool_Exp>;
  _or?: InputMaybe<Array<CompensationPolicy_Bool_Exp>>;
  categories?: InputMaybe<CompensationPolicyTierCategory_Bool_Exp>;
  categories_aggregate?: InputMaybe<CompensationPolicyTierCategory_Aggregate_Bool_Exp>;
  commissions?: InputMaybe<CompensationPolicyCommission_Bool_Exp>;
  commissions_aggregate?: InputMaybe<CompensationPolicyCommission_Aggregate_Bool_Exp>;
  compensationStrategyId?: InputMaybe<Uuid_Comparison_Exp>;
  config?: InputMaybe<Jsonb_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  exemptions?: InputMaybe<CompensationPolicyTierExemption_Bool_Exp>;
  exemptions_aggregate?: InputMaybe<CompensationPolicyTierExemption_Aggregate_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  inclusions?: InputMaybe<CompensationPolicyTierInclusion_Bool_Exp>;
  inclusions_aggregate?: InputMaybe<CompensationPolicyTierInclusion_Aggregate_Bool_Exp>;
  isActive?: InputMaybe<Boolean_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  strategy?: InputMaybe<CompensationStrategy_Bool_Exp>;
  strategyType?: InputMaybe<String_Comparison_Exp>;
  tiers?: InputMaybe<CompensationPolicyTier_Bool_Exp>;
  tiers_aggregate?: InputMaybe<CompensationPolicyTier_Aggregate_Bool_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<User_Bool_Exp>;
  userId?: InputMaybe<Uuid_Comparison_Exp>;
  validFrom?: InputMaybe<Timestamptz_Comparison_Exp>;
  validUntil?: InputMaybe<Timestamptz_Comparison_Exp>;
  workspace?: InputMaybe<Workspace_Bool_Exp>;
  workspaceId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "compensationPolicy" */
export enum CompensationPolicy_Constraint {
  /** unique or primary key constraint on columns "id" */
  CompensationPolicyPkey = 'compensationPolicy_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type CompensationPolicy_Delete_At_Path_Input = {
  config?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type CompensationPolicy_Delete_Elem_Input = {
  config?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type CompensationPolicy_Delete_Key_Input = {
  config?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "compensationPolicy" */
export type CompensationPolicy_Insert_Input = {
  categories?: InputMaybe<CompensationPolicyTierCategory_Arr_Rel_Insert_Input>;
  commissions?: InputMaybe<CompensationPolicyCommission_Arr_Rel_Insert_Input>;
  compensationStrategyId?: InputMaybe<Scalars['uuid']>;
  config?: InputMaybe<Scalars['jsonb']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  exemptions?: InputMaybe<CompensationPolicyTierExemption_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']>;
  inclusions?: InputMaybe<CompensationPolicyTierInclusion_Arr_Rel_Insert_Input>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  strategy?: InputMaybe<CompensationStrategy_Obj_Rel_Insert_Input>;
  strategyType?: InputMaybe<Scalars['String']>;
  tiers?: InputMaybe<CompensationPolicyTier_Arr_Rel_Insert_Input>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<User_Obj_Rel_Insert_Input>;
  userId?: InputMaybe<Scalars['uuid']>;
  validFrom?: InputMaybe<Scalars['timestamptz']>;
  validUntil?: InputMaybe<Scalars['timestamptz']>;
  workspace?: InputMaybe<Workspace_Obj_Rel_Insert_Input>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type CompensationPolicy_Max_Fields = {
  __typename?: 'compensationPolicy_max_fields';
  compensationStrategyId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  strategyType?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['uuid']>;
  validFrom?: Maybe<Scalars['timestamptz']>;
  validUntil?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "compensationPolicy" */
export type CompensationPolicy_Max_Order_By = {
  compensationStrategyId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  strategyType?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
  validFrom?: InputMaybe<Order_By>;
  validUntil?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type CompensationPolicy_Min_Fields = {
  __typename?: 'compensationPolicy_min_fields';
  compensationStrategyId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  strategyType?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['uuid']>;
  validFrom?: Maybe<Scalars['timestamptz']>;
  validUntil?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "compensationPolicy" */
export type CompensationPolicy_Min_Order_By = {
  compensationStrategyId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  strategyType?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
  validFrom?: InputMaybe<Order_By>;
  validUntil?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "compensationPolicy" */
export type CompensationPolicy_Mutation_Response = {
  __typename?: 'compensationPolicy_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CompensationPolicy>;
};

/** input type for inserting object relation for remote table "compensationPolicy" */
export type CompensationPolicy_Obj_Rel_Insert_Input = {
  data: CompensationPolicy_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<CompensationPolicy_On_Conflict>;
};

/** on_conflict condition type for table "compensationPolicy" */
export type CompensationPolicy_On_Conflict = {
  constraint: CompensationPolicy_Constraint;
  update_columns?: Array<CompensationPolicy_Update_Column>;
  where?: InputMaybe<CompensationPolicy_Bool_Exp>;
};

/** Ordering options when selecting data from "compensationPolicy". */
export type CompensationPolicy_Order_By = {
  categories_aggregate?: InputMaybe<CompensationPolicyTierCategory_Aggregate_Order_By>;
  commissions_aggregate?: InputMaybe<CompensationPolicyCommission_Aggregate_Order_By>;
  compensationStrategyId?: InputMaybe<Order_By>;
  config?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  exemptions_aggregate?: InputMaybe<CompensationPolicyTierExemption_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  inclusions_aggregate?: InputMaybe<CompensationPolicyTierInclusion_Aggregate_Order_By>;
  isActive?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  strategy?: InputMaybe<CompensationStrategy_Order_By>;
  strategyType?: InputMaybe<Order_By>;
  tiers_aggregate?: InputMaybe<CompensationPolicyTier_Aggregate_Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  user?: InputMaybe<User_Order_By>;
  userId?: InputMaybe<Order_By>;
  validFrom?: InputMaybe<Order_By>;
  validUntil?: InputMaybe<Order_By>;
  workspace?: InputMaybe<Workspace_Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: compensationPolicy */
export type CompensationPolicy_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type CompensationPolicy_Prepend_Input = {
  config?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "compensationPolicy" */
export enum CompensationPolicy_Select_Column {
  /** column name */
  CompensationStrategyId = 'compensationStrategyId',
  /** column name */
  Config = 'config',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'isActive',
  /** column name */
  Name = 'name',
  /** column name */
  StrategyType = 'strategyType',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId',
  /** column name */
  ValidFrom = 'validFrom',
  /** column name */
  ValidUntil = 'validUntil',
  /** column name */
  WorkspaceId = 'workspaceId'
}

/** select "compensationPolicy_aggregate_bool_exp_bool_and_arguments_columns" columns of table "compensationPolicy" */
export enum CompensationPolicy_Select_Column_CompensationPolicy_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsActive = 'isActive'
}

/** select "compensationPolicy_aggregate_bool_exp_bool_or_arguments_columns" columns of table "compensationPolicy" */
export enum CompensationPolicy_Select_Column_CompensationPolicy_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsActive = 'isActive'
}

/** input type for updating data in table "compensationPolicy" */
export type CompensationPolicy_Set_Input = {
  compensationStrategyId?: InputMaybe<Scalars['uuid']>;
  config?: InputMaybe<Scalars['jsonb']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  strategyType?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  userId?: InputMaybe<Scalars['uuid']>;
  validFrom?: InputMaybe<Scalars['timestamptz']>;
  validUntil?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "compensationPolicy" */
export type CompensationPolicy_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: CompensationPolicy_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type CompensationPolicy_Stream_Cursor_Value_Input = {
  compensationStrategyId?: InputMaybe<Scalars['uuid']>;
  config?: InputMaybe<Scalars['jsonb']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  strategyType?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  userId?: InputMaybe<Scalars['uuid']>;
  validFrom?: InputMaybe<Scalars['timestamptz']>;
  validUntil?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "compensationPolicy" */
export enum CompensationPolicy_Update_Column {
  /** column name */
  CompensationStrategyId = 'compensationStrategyId',
  /** column name */
  Config = 'config',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'isActive',
  /** column name */
  Name = 'name',
  /** column name */
  StrategyType = 'strategyType',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId',
  /** column name */
  ValidFrom = 'validFrom',
  /** column name */
  ValidUntil = 'validUntil',
  /** column name */
  WorkspaceId = 'workspaceId'
}

export type CompensationPolicy_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<CompensationPolicy_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<CompensationPolicy_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<CompensationPolicy_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<CompensationPolicy_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<CompensationPolicy_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CompensationPolicy_Set_Input>;
  /** filter the rows which have to be updated */
  where: CompensationPolicy_Bool_Exp;
};

/** columns and relationships of "compensationStrategy" */
export type CompensationStrategy = {
  __typename?: 'compensationStrategy';
  /** An array relationship */
  categories: Array<CompensationStrategyTierCategory>;
  /** An aggregate relationship */
  categories_aggregate: CompensationStrategyTierCategory_Aggregate;
  /** An array relationship */
  commissions: Array<CompensationStrategyCommission>;
  /** An aggregate relationship */
  commissions_aggregate: CompensationStrategyCommission_Aggregate;
  config: Scalars['jsonb'];
  createdAt: Scalars['timestamptz'];
  /** An array relationship */
  exemptions: Array<CompensationStrategyTierExemption>;
  /** An aggregate relationship */
  exemptions_aggregate: CompensationStrategyTierExemption_Aggregate;
  id: Scalars['uuid'];
  /** An array relationship */
  inclusions: Array<CompensationStrategyTierInclusion>;
  /** An aggregate relationship */
  inclusions_aggregate: CompensationStrategyTierInclusion_Aggregate;
  isActive: Scalars['Boolean'];
  name: Scalars['String'];
  /** An array relationship */
  policies: Array<CompensationPolicy>;
  /** An aggregate relationship */
  policies_aggregate: CompensationPolicy_Aggregate;
  strategyType: Scalars['String'];
  /** An array relationship */
  tiers: Array<CompensationStrategyTier>;
  /** An aggregate relationship */
  tiers_aggregate: CompensationStrategyTier_Aggregate;
  updatedAt: Scalars['timestamptz'];
  /** An object relationship */
  workspace: Workspace;
  workspaceId: Scalars['uuid'];
};


/** columns and relationships of "compensationStrategy" */
export type CompensationStrategyCategoriesArgs = {
  distinct_on?: InputMaybe<Array<CompensationStrategyTierCategory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CompensationStrategyTierCategory_Order_By>>;
  where?: InputMaybe<CompensationStrategyTierCategory_Bool_Exp>;
};


/** columns and relationships of "compensationStrategy" */
export type CompensationStrategyCategories_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CompensationStrategyTierCategory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CompensationStrategyTierCategory_Order_By>>;
  where?: InputMaybe<CompensationStrategyTierCategory_Bool_Exp>;
};


/** columns and relationships of "compensationStrategy" */
export type CompensationStrategyCommissionsArgs = {
  distinct_on?: InputMaybe<Array<CompensationStrategyCommission_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CompensationStrategyCommission_Order_By>>;
  where?: InputMaybe<CompensationStrategyCommission_Bool_Exp>;
};


/** columns and relationships of "compensationStrategy" */
export type CompensationStrategyCommissions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CompensationStrategyCommission_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CompensationStrategyCommission_Order_By>>;
  where?: InputMaybe<CompensationStrategyCommission_Bool_Exp>;
};


/** columns and relationships of "compensationStrategy" */
export type CompensationStrategyConfigArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "compensationStrategy" */
export type CompensationStrategyExemptionsArgs = {
  distinct_on?: InputMaybe<Array<CompensationStrategyTierExemption_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CompensationStrategyTierExemption_Order_By>>;
  where?: InputMaybe<CompensationStrategyTierExemption_Bool_Exp>;
};


/** columns and relationships of "compensationStrategy" */
export type CompensationStrategyExemptions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CompensationStrategyTierExemption_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CompensationStrategyTierExemption_Order_By>>;
  where?: InputMaybe<CompensationStrategyTierExemption_Bool_Exp>;
};


/** columns and relationships of "compensationStrategy" */
export type CompensationStrategyInclusionsArgs = {
  distinct_on?: InputMaybe<Array<CompensationStrategyTierInclusion_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CompensationStrategyTierInclusion_Order_By>>;
  where?: InputMaybe<CompensationStrategyTierInclusion_Bool_Exp>;
};


/** columns and relationships of "compensationStrategy" */
export type CompensationStrategyInclusions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CompensationStrategyTierInclusion_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CompensationStrategyTierInclusion_Order_By>>;
  where?: InputMaybe<CompensationStrategyTierInclusion_Bool_Exp>;
};


/** columns and relationships of "compensationStrategy" */
export type CompensationStrategyPoliciesArgs = {
  distinct_on?: InputMaybe<Array<CompensationPolicy_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CompensationPolicy_Order_By>>;
  where?: InputMaybe<CompensationPolicy_Bool_Exp>;
};


/** columns and relationships of "compensationStrategy" */
export type CompensationStrategyPolicies_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CompensationPolicy_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CompensationPolicy_Order_By>>;
  where?: InputMaybe<CompensationPolicy_Bool_Exp>;
};


/** columns and relationships of "compensationStrategy" */
export type CompensationStrategyTiersArgs = {
  distinct_on?: InputMaybe<Array<CompensationStrategyTier_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CompensationStrategyTier_Order_By>>;
  where?: InputMaybe<CompensationStrategyTier_Bool_Exp>;
};


/** columns and relationships of "compensationStrategy" */
export type CompensationStrategyTiers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CompensationStrategyTier_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CompensationStrategyTier_Order_By>>;
  where?: InputMaybe<CompensationStrategyTier_Bool_Exp>;
};

/** columns and relationships of "compensationStrategyCommission" */
export type CompensationStrategyCommission = {
  __typename?: 'compensationStrategyCommission';
  compensationAmount: Scalars['Int'];
  /** An object relationship */
  compensationStrategy: CompensationStrategy;
  compensationStrategyId: Scalars['uuid'];
  compensationType: Scalars['String'];
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  maxApplyValue: Scalars['Int'];
  /** An object relationship */
  membership?: Maybe<Membership>;
  membershipId?: Maybe<Scalars['uuid']>;
  minApplyValue: Scalars['Int'];
  /** An object relationship */
  package?: Maybe<Package>;
  packageId?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  service?: Maybe<Service>;
  serviceId?: Maybe<Scalars['uuid']>;
  updatedAt: Scalars['timestamptz'];
  /** An object relationship */
  workspace: Workspace;
  workspaceId: Scalars['uuid'];
};

/** aggregated selection of "compensationStrategyCommission" */
export type CompensationStrategyCommission_Aggregate = {
  __typename?: 'compensationStrategyCommission_aggregate';
  aggregate?: Maybe<CompensationStrategyCommission_Aggregate_Fields>;
  nodes: Array<CompensationStrategyCommission>;
};

export type CompensationStrategyCommission_Aggregate_Bool_Exp = {
  count?: InputMaybe<CompensationStrategyCommission_Aggregate_Bool_Exp_Count>;
};

export type CompensationStrategyCommission_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<CompensationStrategyCommission_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<CompensationStrategyCommission_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "compensationStrategyCommission" */
export type CompensationStrategyCommission_Aggregate_Fields = {
  __typename?: 'compensationStrategyCommission_aggregate_fields';
  avg?: Maybe<CompensationStrategyCommission_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<CompensationStrategyCommission_Max_Fields>;
  min?: Maybe<CompensationStrategyCommission_Min_Fields>;
  stddev?: Maybe<CompensationStrategyCommission_Stddev_Fields>;
  stddev_pop?: Maybe<CompensationStrategyCommission_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<CompensationStrategyCommission_Stddev_Samp_Fields>;
  sum?: Maybe<CompensationStrategyCommission_Sum_Fields>;
  var_pop?: Maybe<CompensationStrategyCommission_Var_Pop_Fields>;
  var_samp?: Maybe<CompensationStrategyCommission_Var_Samp_Fields>;
  variance?: Maybe<CompensationStrategyCommission_Variance_Fields>;
};


/** aggregate fields of "compensationStrategyCommission" */
export type CompensationStrategyCommission_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<CompensationStrategyCommission_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "compensationStrategyCommission" */
export type CompensationStrategyCommission_Aggregate_Order_By = {
  avg?: InputMaybe<CompensationStrategyCommission_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<CompensationStrategyCommission_Max_Order_By>;
  min?: InputMaybe<CompensationStrategyCommission_Min_Order_By>;
  stddev?: InputMaybe<CompensationStrategyCommission_Stddev_Order_By>;
  stddev_pop?: InputMaybe<CompensationStrategyCommission_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<CompensationStrategyCommission_Stddev_Samp_Order_By>;
  sum?: InputMaybe<CompensationStrategyCommission_Sum_Order_By>;
  var_pop?: InputMaybe<CompensationStrategyCommission_Var_Pop_Order_By>;
  var_samp?: InputMaybe<CompensationStrategyCommission_Var_Samp_Order_By>;
  variance?: InputMaybe<CompensationStrategyCommission_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "compensationStrategyCommission" */
export type CompensationStrategyCommission_Arr_Rel_Insert_Input = {
  data: Array<CompensationStrategyCommission_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<CompensationStrategyCommission_On_Conflict>;
};

/** aggregate avg on columns */
export type CompensationStrategyCommission_Avg_Fields = {
  __typename?: 'compensationStrategyCommission_avg_fields';
  compensationAmount?: Maybe<Scalars['Float']>;
  maxApplyValue?: Maybe<Scalars['Float']>;
  minApplyValue?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "compensationStrategyCommission" */
export type CompensationStrategyCommission_Avg_Order_By = {
  compensationAmount?: InputMaybe<Order_By>;
  maxApplyValue?: InputMaybe<Order_By>;
  minApplyValue?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "compensationStrategyCommission". All fields are combined with a logical 'AND'. */
export type CompensationStrategyCommission_Bool_Exp = {
  _and?: InputMaybe<Array<CompensationStrategyCommission_Bool_Exp>>;
  _not?: InputMaybe<CompensationStrategyCommission_Bool_Exp>;
  _or?: InputMaybe<Array<CompensationStrategyCommission_Bool_Exp>>;
  compensationAmount?: InputMaybe<Int_Comparison_Exp>;
  compensationStrategy?: InputMaybe<CompensationStrategy_Bool_Exp>;
  compensationStrategyId?: InputMaybe<Uuid_Comparison_Exp>;
  compensationType?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  maxApplyValue?: InputMaybe<Int_Comparison_Exp>;
  membership?: InputMaybe<Membership_Bool_Exp>;
  membershipId?: InputMaybe<Uuid_Comparison_Exp>;
  minApplyValue?: InputMaybe<Int_Comparison_Exp>;
  package?: InputMaybe<Package_Bool_Exp>;
  packageId?: InputMaybe<Uuid_Comparison_Exp>;
  service?: InputMaybe<Service_Bool_Exp>;
  serviceId?: InputMaybe<Uuid_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  workspace?: InputMaybe<Workspace_Bool_Exp>;
  workspaceId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "compensationStrategyCommission" */
export enum CompensationStrategyCommission_Constraint {
  /** unique or primary key constraint on columns "id" */
  CompensationStrategyCommissionPkey = 'compensationStrategyCommission_pkey'
}

/** input type for incrementing numeric columns in table "compensationStrategyCommission" */
export type CompensationStrategyCommission_Inc_Input = {
  compensationAmount?: InputMaybe<Scalars['Int']>;
  maxApplyValue?: InputMaybe<Scalars['Int']>;
  minApplyValue?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "compensationStrategyCommission" */
export type CompensationStrategyCommission_Insert_Input = {
  compensationAmount?: InputMaybe<Scalars['Int']>;
  compensationStrategy?: InputMaybe<CompensationStrategy_Obj_Rel_Insert_Input>;
  compensationStrategyId?: InputMaybe<Scalars['uuid']>;
  compensationType?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  maxApplyValue?: InputMaybe<Scalars['Int']>;
  membership?: InputMaybe<Membership_Obj_Rel_Insert_Input>;
  membershipId?: InputMaybe<Scalars['uuid']>;
  minApplyValue?: InputMaybe<Scalars['Int']>;
  package?: InputMaybe<Package_Obj_Rel_Insert_Input>;
  packageId?: InputMaybe<Scalars['uuid']>;
  service?: InputMaybe<Service_Obj_Rel_Insert_Input>;
  serviceId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspace?: InputMaybe<Workspace_Obj_Rel_Insert_Input>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type CompensationStrategyCommission_Max_Fields = {
  __typename?: 'compensationStrategyCommission_max_fields';
  compensationAmount?: Maybe<Scalars['Int']>;
  compensationStrategyId?: Maybe<Scalars['uuid']>;
  compensationType?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  maxApplyValue?: Maybe<Scalars['Int']>;
  membershipId?: Maybe<Scalars['uuid']>;
  minApplyValue?: Maybe<Scalars['Int']>;
  packageId?: Maybe<Scalars['uuid']>;
  serviceId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "compensationStrategyCommission" */
export type CompensationStrategyCommission_Max_Order_By = {
  compensationAmount?: InputMaybe<Order_By>;
  compensationStrategyId?: InputMaybe<Order_By>;
  compensationType?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  maxApplyValue?: InputMaybe<Order_By>;
  membershipId?: InputMaybe<Order_By>;
  minApplyValue?: InputMaybe<Order_By>;
  packageId?: InputMaybe<Order_By>;
  serviceId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type CompensationStrategyCommission_Min_Fields = {
  __typename?: 'compensationStrategyCommission_min_fields';
  compensationAmount?: Maybe<Scalars['Int']>;
  compensationStrategyId?: Maybe<Scalars['uuid']>;
  compensationType?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  maxApplyValue?: Maybe<Scalars['Int']>;
  membershipId?: Maybe<Scalars['uuid']>;
  minApplyValue?: Maybe<Scalars['Int']>;
  packageId?: Maybe<Scalars['uuid']>;
  serviceId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "compensationStrategyCommission" */
export type CompensationStrategyCommission_Min_Order_By = {
  compensationAmount?: InputMaybe<Order_By>;
  compensationStrategyId?: InputMaybe<Order_By>;
  compensationType?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  maxApplyValue?: InputMaybe<Order_By>;
  membershipId?: InputMaybe<Order_By>;
  minApplyValue?: InputMaybe<Order_By>;
  packageId?: InputMaybe<Order_By>;
  serviceId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "compensationStrategyCommission" */
export type CompensationStrategyCommission_Mutation_Response = {
  __typename?: 'compensationStrategyCommission_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CompensationStrategyCommission>;
};

/** on_conflict condition type for table "compensationStrategyCommission" */
export type CompensationStrategyCommission_On_Conflict = {
  constraint: CompensationStrategyCommission_Constraint;
  update_columns?: Array<CompensationStrategyCommission_Update_Column>;
  where?: InputMaybe<CompensationStrategyCommission_Bool_Exp>;
};

/** Ordering options when selecting data from "compensationStrategyCommission". */
export type CompensationStrategyCommission_Order_By = {
  compensationAmount?: InputMaybe<Order_By>;
  compensationStrategy?: InputMaybe<CompensationStrategy_Order_By>;
  compensationStrategyId?: InputMaybe<Order_By>;
  compensationType?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  maxApplyValue?: InputMaybe<Order_By>;
  membership?: InputMaybe<Membership_Order_By>;
  membershipId?: InputMaybe<Order_By>;
  minApplyValue?: InputMaybe<Order_By>;
  package?: InputMaybe<Package_Order_By>;
  packageId?: InputMaybe<Order_By>;
  service?: InputMaybe<Service_Order_By>;
  serviceId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  workspace?: InputMaybe<Workspace_Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: compensationStrategyCommission */
export type CompensationStrategyCommission_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "compensationStrategyCommission" */
export enum CompensationStrategyCommission_Select_Column {
  /** column name */
  CompensationAmount = 'compensationAmount',
  /** column name */
  CompensationStrategyId = 'compensationStrategyId',
  /** column name */
  CompensationType = 'compensationType',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  MaxApplyValue = 'maxApplyValue',
  /** column name */
  MembershipId = 'membershipId',
  /** column name */
  MinApplyValue = 'minApplyValue',
  /** column name */
  PackageId = 'packageId',
  /** column name */
  ServiceId = 'serviceId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WorkspaceId = 'workspaceId'
}

/** input type for updating data in table "compensationStrategyCommission" */
export type CompensationStrategyCommission_Set_Input = {
  compensationAmount?: InputMaybe<Scalars['Int']>;
  compensationStrategyId?: InputMaybe<Scalars['uuid']>;
  compensationType?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  maxApplyValue?: InputMaybe<Scalars['Int']>;
  membershipId?: InputMaybe<Scalars['uuid']>;
  minApplyValue?: InputMaybe<Scalars['Int']>;
  packageId?: InputMaybe<Scalars['uuid']>;
  serviceId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type CompensationStrategyCommission_Stddev_Fields = {
  __typename?: 'compensationStrategyCommission_stddev_fields';
  compensationAmount?: Maybe<Scalars['Float']>;
  maxApplyValue?: Maybe<Scalars['Float']>;
  minApplyValue?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "compensationStrategyCommission" */
export type CompensationStrategyCommission_Stddev_Order_By = {
  compensationAmount?: InputMaybe<Order_By>;
  maxApplyValue?: InputMaybe<Order_By>;
  minApplyValue?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type CompensationStrategyCommission_Stddev_Pop_Fields = {
  __typename?: 'compensationStrategyCommission_stddev_pop_fields';
  compensationAmount?: Maybe<Scalars['Float']>;
  maxApplyValue?: Maybe<Scalars['Float']>;
  minApplyValue?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "compensationStrategyCommission" */
export type CompensationStrategyCommission_Stddev_Pop_Order_By = {
  compensationAmount?: InputMaybe<Order_By>;
  maxApplyValue?: InputMaybe<Order_By>;
  minApplyValue?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type CompensationStrategyCommission_Stddev_Samp_Fields = {
  __typename?: 'compensationStrategyCommission_stddev_samp_fields';
  compensationAmount?: Maybe<Scalars['Float']>;
  maxApplyValue?: Maybe<Scalars['Float']>;
  minApplyValue?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "compensationStrategyCommission" */
export type CompensationStrategyCommission_Stddev_Samp_Order_By = {
  compensationAmount?: InputMaybe<Order_By>;
  maxApplyValue?: InputMaybe<Order_By>;
  minApplyValue?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "compensationStrategyCommission" */
export type CompensationStrategyCommission_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: CompensationStrategyCommission_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type CompensationStrategyCommission_Stream_Cursor_Value_Input = {
  compensationAmount?: InputMaybe<Scalars['Int']>;
  compensationStrategyId?: InputMaybe<Scalars['uuid']>;
  compensationType?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  maxApplyValue?: InputMaybe<Scalars['Int']>;
  membershipId?: InputMaybe<Scalars['uuid']>;
  minApplyValue?: InputMaybe<Scalars['Int']>;
  packageId?: InputMaybe<Scalars['uuid']>;
  serviceId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type CompensationStrategyCommission_Sum_Fields = {
  __typename?: 'compensationStrategyCommission_sum_fields';
  compensationAmount?: Maybe<Scalars['Int']>;
  maxApplyValue?: Maybe<Scalars['Int']>;
  minApplyValue?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "compensationStrategyCommission" */
export type CompensationStrategyCommission_Sum_Order_By = {
  compensationAmount?: InputMaybe<Order_By>;
  maxApplyValue?: InputMaybe<Order_By>;
  minApplyValue?: InputMaybe<Order_By>;
};

/** update columns of table "compensationStrategyCommission" */
export enum CompensationStrategyCommission_Update_Column {
  /** column name */
  CompensationAmount = 'compensationAmount',
  /** column name */
  CompensationStrategyId = 'compensationStrategyId',
  /** column name */
  CompensationType = 'compensationType',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  MaxApplyValue = 'maxApplyValue',
  /** column name */
  MembershipId = 'membershipId',
  /** column name */
  MinApplyValue = 'minApplyValue',
  /** column name */
  PackageId = 'packageId',
  /** column name */
  ServiceId = 'serviceId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WorkspaceId = 'workspaceId'
}

export type CompensationStrategyCommission_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<CompensationStrategyCommission_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CompensationStrategyCommission_Set_Input>;
  /** filter the rows which have to be updated */
  where: CompensationStrategyCommission_Bool_Exp;
};

/** aggregate var_pop on columns */
export type CompensationStrategyCommission_Var_Pop_Fields = {
  __typename?: 'compensationStrategyCommission_var_pop_fields';
  compensationAmount?: Maybe<Scalars['Float']>;
  maxApplyValue?: Maybe<Scalars['Float']>;
  minApplyValue?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "compensationStrategyCommission" */
export type CompensationStrategyCommission_Var_Pop_Order_By = {
  compensationAmount?: InputMaybe<Order_By>;
  maxApplyValue?: InputMaybe<Order_By>;
  minApplyValue?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type CompensationStrategyCommission_Var_Samp_Fields = {
  __typename?: 'compensationStrategyCommission_var_samp_fields';
  compensationAmount?: Maybe<Scalars['Float']>;
  maxApplyValue?: Maybe<Scalars['Float']>;
  minApplyValue?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "compensationStrategyCommission" */
export type CompensationStrategyCommission_Var_Samp_Order_By = {
  compensationAmount?: InputMaybe<Order_By>;
  maxApplyValue?: InputMaybe<Order_By>;
  minApplyValue?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type CompensationStrategyCommission_Variance_Fields = {
  __typename?: 'compensationStrategyCommission_variance_fields';
  compensationAmount?: Maybe<Scalars['Float']>;
  maxApplyValue?: Maybe<Scalars['Float']>;
  minApplyValue?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "compensationStrategyCommission" */
export type CompensationStrategyCommission_Variance_Order_By = {
  compensationAmount?: InputMaybe<Order_By>;
  maxApplyValue?: InputMaybe<Order_By>;
  minApplyValue?: InputMaybe<Order_By>;
};

/** columns and relationships of "compensationStrategyTier" */
export type CompensationStrategyTier = {
  __typename?: 'compensationStrategyTier';
  compensationAmount: Scalars['Int'];
  compensationStrategyId: Scalars['uuid'];
  compensationType: Scalars['String'];
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  maxApplyValue: Scalars['Int'];
  minApplyValue: Scalars['Int'];
  name: Scalars['String'];
  /** An object relationship */
  strategy: CompensationStrategy;
  updatedAt: Scalars['timestamptz'];
  /** An object relationship */
  workspace: Workspace;
  workspaceId: Scalars['uuid'];
};

/** columns and relationships of "compensationStrategyTierCategory" */
export type CompensationStrategyTierCategory = {
  __typename?: 'compensationStrategyTierCategory';
  compensationStrategyId: Scalars['uuid'];
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An object relationship */
  serviceCategory: ServiceCategory;
  serviceCategoryId: Scalars['uuid'];
  /** An object relationship */
  strategy: CompensationStrategy;
  updatedAt: Scalars['timestamptz'];
};

/** aggregated selection of "compensationStrategyTierCategory" */
export type CompensationStrategyTierCategory_Aggregate = {
  __typename?: 'compensationStrategyTierCategory_aggregate';
  aggregate?: Maybe<CompensationStrategyTierCategory_Aggregate_Fields>;
  nodes: Array<CompensationStrategyTierCategory>;
};

export type CompensationStrategyTierCategory_Aggregate_Bool_Exp = {
  count?: InputMaybe<CompensationStrategyTierCategory_Aggregate_Bool_Exp_Count>;
};

export type CompensationStrategyTierCategory_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<CompensationStrategyTierCategory_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<CompensationStrategyTierCategory_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "compensationStrategyTierCategory" */
export type CompensationStrategyTierCategory_Aggregate_Fields = {
  __typename?: 'compensationStrategyTierCategory_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<CompensationStrategyTierCategory_Max_Fields>;
  min?: Maybe<CompensationStrategyTierCategory_Min_Fields>;
};


/** aggregate fields of "compensationStrategyTierCategory" */
export type CompensationStrategyTierCategory_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<CompensationStrategyTierCategory_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "compensationStrategyTierCategory" */
export type CompensationStrategyTierCategory_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<CompensationStrategyTierCategory_Max_Order_By>;
  min?: InputMaybe<CompensationStrategyTierCategory_Min_Order_By>;
};

/** input type for inserting array relation for remote table "compensationStrategyTierCategory" */
export type CompensationStrategyTierCategory_Arr_Rel_Insert_Input = {
  data: Array<CompensationStrategyTierCategory_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<CompensationStrategyTierCategory_On_Conflict>;
};

/** Boolean expression to filter rows from the table "compensationStrategyTierCategory". All fields are combined with a logical 'AND'. */
export type CompensationStrategyTierCategory_Bool_Exp = {
  _and?: InputMaybe<Array<CompensationStrategyTierCategory_Bool_Exp>>;
  _not?: InputMaybe<CompensationStrategyTierCategory_Bool_Exp>;
  _or?: InputMaybe<Array<CompensationStrategyTierCategory_Bool_Exp>>;
  compensationStrategyId?: InputMaybe<Uuid_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  serviceCategory?: InputMaybe<ServiceCategory_Bool_Exp>;
  serviceCategoryId?: InputMaybe<Uuid_Comparison_Exp>;
  strategy?: InputMaybe<CompensationStrategy_Bool_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "compensationStrategyTierCategory" */
export enum CompensationStrategyTierCategory_Constraint {
  /** unique or primary key constraint on columns "id" */
  CompensationStrategyTierCategoryPkey = 'compensationStrategyTierCategory_pkey'
}

/** input type for inserting data into table "compensationStrategyTierCategory" */
export type CompensationStrategyTierCategory_Insert_Input = {
  compensationStrategyId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  serviceCategory?: InputMaybe<ServiceCategory_Obj_Rel_Insert_Input>;
  serviceCategoryId?: InputMaybe<Scalars['uuid']>;
  strategy?: InputMaybe<CompensationStrategy_Obj_Rel_Insert_Input>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type CompensationStrategyTierCategory_Max_Fields = {
  __typename?: 'compensationStrategyTierCategory_max_fields';
  compensationStrategyId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  serviceCategoryId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "compensationStrategyTierCategory" */
export type CompensationStrategyTierCategory_Max_Order_By = {
  compensationStrategyId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  serviceCategoryId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type CompensationStrategyTierCategory_Min_Fields = {
  __typename?: 'compensationStrategyTierCategory_min_fields';
  compensationStrategyId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  serviceCategoryId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "compensationStrategyTierCategory" */
export type CompensationStrategyTierCategory_Min_Order_By = {
  compensationStrategyId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  serviceCategoryId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "compensationStrategyTierCategory" */
export type CompensationStrategyTierCategory_Mutation_Response = {
  __typename?: 'compensationStrategyTierCategory_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CompensationStrategyTierCategory>;
};

/** on_conflict condition type for table "compensationStrategyTierCategory" */
export type CompensationStrategyTierCategory_On_Conflict = {
  constraint: CompensationStrategyTierCategory_Constraint;
  update_columns?: Array<CompensationStrategyTierCategory_Update_Column>;
  where?: InputMaybe<CompensationStrategyTierCategory_Bool_Exp>;
};

/** Ordering options when selecting data from "compensationStrategyTierCategory". */
export type CompensationStrategyTierCategory_Order_By = {
  compensationStrategyId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  serviceCategory?: InputMaybe<ServiceCategory_Order_By>;
  serviceCategoryId?: InputMaybe<Order_By>;
  strategy?: InputMaybe<CompensationStrategy_Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: compensationStrategyTierCategory */
export type CompensationStrategyTierCategory_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "compensationStrategyTierCategory" */
export enum CompensationStrategyTierCategory_Select_Column {
  /** column name */
  CompensationStrategyId = 'compensationStrategyId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  ServiceCategoryId = 'serviceCategoryId',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "compensationStrategyTierCategory" */
export type CompensationStrategyTierCategory_Set_Input = {
  compensationStrategyId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  serviceCategoryId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "compensationStrategyTierCategory" */
export type CompensationStrategyTierCategory_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: CompensationStrategyTierCategory_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type CompensationStrategyTierCategory_Stream_Cursor_Value_Input = {
  compensationStrategyId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  serviceCategoryId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "compensationStrategyTierCategory" */
export enum CompensationStrategyTierCategory_Update_Column {
  /** column name */
  CompensationStrategyId = 'compensationStrategyId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  ServiceCategoryId = 'serviceCategoryId',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type CompensationStrategyTierCategory_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CompensationStrategyTierCategory_Set_Input>;
  /** filter the rows which have to be updated */
  where: CompensationStrategyTierCategory_Bool_Exp;
};

/** columns and relationships of "compensationStrategyTierExemption" */
export type CompensationStrategyTierExemption = {
  __typename?: 'compensationStrategyTierExemption';
  compensationStrategyId: Scalars['uuid'];
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An object relationship */
  membership?: Maybe<Membership>;
  membershipId?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  package?: Maybe<Package>;
  packageId?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  service?: Maybe<Service>;
  serviceId?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  strategy: CompensationStrategy;
  updatedAt: Scalars['timestamptz'];
};

/** aggregated selection of "compensationStrategyTierExemption" */
export type CompensationStrategyTierExemption_Aggregate = {
  __typename?: 'compensationStrategyTierExemption_aggregate';
  aggregate?: Maybe<CompensationStrategyTierExemption_Aggregate_Fields>;
  nodes: Array<CompensationStrategyTierExemption>;
};

export type CompensationStrategyTierExemption_Aggregate_Bool_Exp = {
  count?: InputMaybe<CompensationStrategyTierExemption_Aggregate_Bool_Exp_Count>;
};

export type CompensationStrategyTierExemption_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<CompensationStrategyTierExemption_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<CompensationStrategyTierExemption_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "compensationStrategyTierExemption" */
export type CompensationStrategyTierExemption_Aggregate_Fields = {
  __typename?: 'compensationStrategyTierExemption_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<CompensationStrategyTierExemption_Max_Fields>;
  min?: Maybe<CompensationStrategyTierExemption_Min_Fields>;
};


/** aggregate fields of "compensationStrategyTierExemption" */
export type CompensationStrategyTierExemption_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<CompensationStrategyTierExemption_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "compensationStrategyTierExemption" */
export type CompensationStrategyTierExemption_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<CompensationStrategyTierExemption_Max_Order_By>;
  min?: InputMaybe<CompensationStrategyTierExemption_Min_Order_By>;
};

/** input type for inserting array relation for remote table "compensationStrategyTierExemption" */
export type CompensationStrategyTierExemption_Arr_Rel_Insert_Input = {
  data: Array<CompensationStrategyTierExemption_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<CompensationStrategyTierExemption_On_Conflict>;
};

/** Boolean expression to filter rows from the table "compensationStrategyTierExemption". All fields are combined with a logical 'AND'. */
export type CompensationStrategyTierExemption_Bool_Exp = {
  _and?: InputMaybe<Array<CompensationStrategyTierExemption_Bool_Exp>>;
  _not?: InputMaybe<CompensationStrategyTierExemption_Bool_Exp>;
  _or?: InputMaybe<Array<CompensationStrategyTierExemption_Bool_Exp>>;
  compensationStrategyId?: InputMaybe<Uuid_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  membership?: InputMaybe<Membership_Bool_Exp>;
  membershipId?: InputMaybe<Uuid_Comparison_Exp>;
  package?: InputMaybe<Package_Bool_Exp>;
  packageId?: InputMaybe<Uuid_Comparison_Exp>;
  service?: InputMaybe<Service_Bool_Exp>;
  serviceId?: InputMaybe<Uuid_Comparison_Exp>;
  strategy?: InputMaybe<CompensationStrategy_Bool_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "compensationStrategyTierExemption" */
export enum CompensationStrategyTierExemption_Constraint {
  /** unique or primary key constraint on columns "id" */
  CompensationStrategyTierExemptionPkey = 'compensationStrategyTierExemption_pkey'
}

/** input type for inserting data into table "compensationStrategyTierExemption" */
export type CompensationStrategyTierExemption_Insert_Input = {
  compensationStrategyId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  membership?: InputMaybe<Membership_Obj_Rel_Insert_Input>;
  membershipId?: InputMaybe<Scalars['uuid']>;
  package?: InputMaybe<Package_Obj_Rel_Insert_Input>;
  packageId?: InputMaybe<Scalars['uuid']>;
  service?: InputMaybe<Service_Obj_Rel_Insert_Input>;
  serviceId?: InputMaybe<Scalars['uuid']>;
  strategy?: InputMaybe<CompensationStrategy_Obj_Rel_Insert_Input>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type CompensationStrategyTierExemption_Max_Fields = {
  __typename?: 'compensationStrategyTierExemption_max_fields';
  compensationStrategyId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  membershipId?: Maybe<Scalars['uuid']>;
  packageId?: Maybe<Scalars['uuid']>;
  serviceId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "compensationStrategyTierExemption" */
export type CompensationStrategyTierExemption_Max_Order_By = {
  compensationStrategyId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  membershipId?: InputMaybe<Order_By>;
  packageId?: InputMaybe<Order_By>;
  serviceId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type CompensationStrategyTierExemption_Min_Fields = {
  __typename?: 'compensationStrategyTierExemption_min_fields';
  compensationStrategyId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  membershipId?: Maybe<Scalars['uuid']>;
  packageId?: Maybe<Scalars['uuid']>;
  serviceId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "compensationStrategyTierExemption" */
export type CompensationStrategyTierExemption_Min_Order_By = {
  compensationStrategyId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  membershipId?: InputMaybe<Order_By>;
  packageId?: InputMaybe<Order_By>;
  serviceId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "compensationStrategyTierExemption" */
export type CompensationStrategyTierExemption_Mutation_Response = {
  __typename?: 'compensationStrategyTierExemption_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CompensationStrategyTierExemption>;
};

/** on_conflict condition type for table "compensationStrategyTierExemption" */
export type CompensationStrategyTierExemption_On_Conflict = {
  constraint: CompensationStrategyTierExemption_Constraint;
  update_columns?: Array<CompensationStrategyTierExemption_Update_Column>;
  where?: InputMaybe<CompensationStrategyTierExemption_Bool_Exp>;
};

/** Ordering options when selecting data from "compensationStrategyTierExemption". */
export type CompensationStrategyTierExemption_Order_By = {
  compensationStrategyId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  membership?: InputMaybe<Membership_Order_By>;
  membershipId?: InputMaybe<Order_By>;
  package?: InputMaybe<Package_Order_By>;
  packageId?: InputMaybe<Order_By>;
  service?: InputMaybe<Service_Order_By>;
  serviceId?: InputMaybe<Order_By>;
  strategy?: InputMaybe<CompensationStrategy_Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: compensationStrategyTierExemption */
export type CompensationStrategyTierExemption_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "compensationStrategyTierExemption" */
export enum CompensationStrategyTierExemption_Select_Column {
  /** column name */
  CompensationStrategyId = 'compensationStrategyId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  MembershipId = 'membershipId',
  /** column name */
  PackageId = 'packageId',
  /** column name */
  ServiceId = 'serviceId',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "compensationStrategyTierExemption" */
export type CompensationStrategyTierExemption_Set_Input = {
  compensationStrategyId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  membershipId?: InputMaybe<Scalars['uuid']>;
  packageId?: InputMaybe<Scalars['uuid']>;
  serviceId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "compensationStrategyTierExemption" */
export type CompensationStrategyTierExemption_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: CompensationStrategyTierExemption_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type CompensationStrategyTierExemption_Stream_Cursor_Value_Input = {
  compensationStrategyId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  membershipId?: InputMaybe<Scalars['uuid']>;
  packageId?: InputMaybe<Scalars['uuid']>;
  serviceId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "compensationStrategyTierExemption" */
export enum CompensationStrategyTierExemption_Update_Column {
  /** column name */
  CompensationStrategyId = 'compensationStrategyId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  MembershipId = 'membershipId',
  /** column name */
  PackageId = 'packageId',
  /** column name */
  ServiceId = 'serviceId',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type CompensationStrategyTierExemption_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CompensationStrategyTierExemption_Set_Input>;
  /** filter the rows which have to be updated */
  where: CompensationStrategyTierExemption_Bool_Exp;
};

/** columns and relationships of "compensationStrategyTierInclusion" */
export type CompensationStrategyTierInclusion = {
  __typename?: 'compensationStrategyTierInclusion';
  compensationStrategyId: Scalars['uuid'];
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An object relationship */
  membership?: Maybe<Membership>;
  membershipId?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  package?: Maybe<Package>;
  packageId?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  service?: Maybe<Service>;
  serviceId?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  strategy: CompensationStrategy;
  updatedAt: Scalars['timestamptz'];
};

/** aggregated selection of "compensationStrategyTierInclusion" */
export type CompensationStrategyTierInclusion_Aggregate = {
  __typename?: 'compensationStrategyTierInclusion_aggregate';
  aggregate?: Maybe<CompensationStrategyTierInclusion_Aggregate_Fields>;
  nodes: Array<CompensationStrategyTierInclusion>;
};

export type CompensationStrategyTierInclusion_Aggregate_Bool_Exp = {
  count?: InputMaybe<CompensationStrategyTierInclusion_Aggregate_Bool_Exp_Count>;
};

export type CompensationStrategyTierInclusion_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<CompensationStrategyTierInclusion_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<CompensationStrategyTierInclusion_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "compensationStrategyTierInclusion" */
export type CompensationStrategyTierInclusion_Aggregate_Fields = {
  __typename?: 'compensationStrategyTierInclusion_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<CompensationStrategyTierInclusion_Max_Fields>;
  min?: Maybe<CompensationStrategyTierInclusion_Min_Fields>;
};


/** aggregate fields of "compensationStrategyTierInclusion" */
export type CompensationStrategyTierInclusion_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<CompensationStrategyTierInclusion_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "compensationStrategyTierInclusion" */
export type CompensationStrategyTierInclusion_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<CompensationStrategyTierInclusion_Max_Order_By>;
  min?: InputMaybe<CompensationStrategyTierInclusion_Min_Order_By>;
};

/** input type for inserting array relation for remote table "compensationStrategyTierInclusion" */
export type CompensationStrategyTierInclusion_Arr_Rel_Insert_Input = {
  data: Array<CompensationStrategyTierInclusion_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<CompensationStrategyTierInclusion_On_Conflict>;
};

/** Boolean expression to filter rows from the table "compensationStrategyTierInclusion". All fields are combined with a logical 'AND'. */
export type CompensationStrategyTierInclusion_Bool_Exp = {
  _and?: InputMaybe<Array<CompensationStrategyTierInclusion_Bool_Exp>>;
  _not?: InputMaybe<CompensationStrategyTierInclusion_Bool_Exp>;
  _or?: InputMaybe<Array<CompensationStrategyTierInclusion_Bool_Exp>>;
  compensationStrategyId?: InputMaybe<Uuid_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  membership?: InputMaybe<Membership_Bool_Exp>;
  membershipId?: InputMaybe<Uuid_Comparison_Exp>;
  package?: InputMaybe<Package_Bool_Exp>;
  packageId?: InputMaybe<Uuid_Comparison_Exp>;
  service?: InputMaybe<Service_Bool_Exp>;
  serviceId?: InputMaybe<Uuid_Comparison_Exp>;
  strategy?: InputMaybe<CompensationStrategy_Bool_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "compensationStrategyTierInclusion" */
export enum CompensationStrategyTierInclusion_Constraint {
  /** unique or primary key constraint on columns "id" */
  CompensationStrategyTierInclusionPkey = 'compensationStrategyTierInclusion_pkey'
}

/** input type for inserting data into table "compensationStrategyTierInclusion" */
export type CompensationStrategyTierInclusion_Insert_Input = {
  compensationStrategyId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  membership?: InputMaybe<Membership_Obj_Rel_Insert_Input>;
  membershipId?: InputMaybe<Scalars['uuid']>;
  package?: InputMaybe<Package_Obj_Rel_Insert_Input>;
  packageId?: InputMaybe<Scalars['uuid']>;
  service?: InputMaybe<Service_Obj_Rel_Insert_Input>;
  serviceId?: InputMaybe<Scalars['uuid']>;
  strategy?: InputMaybe<CompensationStrategy_Obj_Rel_Insert_Input>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type CompensationStrategyTierInclusion_Max_Fields = {
  __typename?: 'compensationStrategyTierInclusion_max_fields';
  compensationStrategyId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  membershipId?: Maybe<Scalars['uuid']>;
  packageId?: Maybe<Scalars['uuid']>;
  serviceId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "compensationStrategyTierInclusion" */
export type CompensationStrategyTierInclusion_Max_Order_By = {
  compensationStrategyId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  membershipId?: InputMaybe<Order_By>;
  packageId?: InputMaybe<Order_By>;
  serviceId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type CompensationStrategyTierInclusion_Min_Fields = {
  __typename?: 'compensationStrategyTierInclusion_min_fields';
  compensationStrategyId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  membershipId?: Maybe<Scalars['uuid']>;
  packageId?: Maybe<Scalars['uuid']>;
  serviceId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "compensationStrategyTierInclusion" */
export type CompensationStrategyTierInclusion_Min_Order_By = {
  compensationStrategyId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  membershipId?: InputMaybe<Order_By>;
  packageId?: InputMaybe<Order_By>;
  serviceId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "compensationStrategyTierInclusion" */
export type CompensationStrategyTierInclusion_Mutation_Response = {
  __typename?: 'compensationStrategyTierInclusion_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CompensationStrategyTierInclusion>;
};

/** on_conflict condition type for table "compensationStrategyTierInclusion" */
export type CompensationStrategyTierInclusion_On_Conflict = {
  constraint: CompensationStrategyTierInclusion_Constraint;
  update_columns?: Array<CompensationStrategyTierInclusion_Update_Column>;
  where?: InputMaybe<CompensationStrategyTierInclusion_Bool_Exp>;
};

/** Ordering options when selecting data from "compensationStrategyTierInclusion". */
export type CompensationStrategyTierInclusion_Order_By = {
  compensationStrategyId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  membership?: InputMaybe<Membership_Order_By>;
  membershipId?: InputMaybe<Order_By>;
  package?: InputMaybe<Package_Order_By>;
  packageId?: InputMaybe<Order_By>;
  service?: InputMaybe<Service_Order_By>;
  serviceId?: InputMaybe<Order_By>;
  strategy?: InputMaybe<CompensationStrategy_Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: compensationStrategyTierInclusion */
export type CompensationStrategyTierInclusion_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "compensationStrategyTierInclusion" */
export enum CompensationStrategyTierInclusion_Select_Column {
  /** column name */
  CompensationStrategyId = 'compensationStrategyId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  MembershipId = 'membershipId',
  /** column name */
  PackageId = 'packageId',
  /** column name */
  ServiceId = 'serviceId',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "compensationStrategyTierInclusion" */
export type CompensationStrategyTierInclusion_Set_Input = {
  compensationStrategyId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  membershipId?: InputMaybe<Scalars['uuid']>;
  packageId?: InputMaybe<Scalars['uuid']>;
  serviceId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "compensationStrategyTierInclusion" */
export type CompensationStrategyTierInclusion_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: CompensationStrategyTierInclusion_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type CompensationStrategyTierInclusion_Stream_Cursor_Value_Input = {
  compensationStrategyId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  membershipId?: InputMaybe<Scalars['uuid']>;
  packageId?: InputMaybe<Scalars['uuid']>;
  serviceId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "compensationStrategyTierInclusion" */
export enum CompensationStrategyTierInclusion_Update_Column {
  /** column name */
  CompensationStrategyId = 'compensationStrategyId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  MembershipId = 'membershipId',
  /** column name */
  PackageId = 'packageId',
  /** column name */
  ServiceId = 'serviceId',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type CompensationStrategyTierInclusion_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CompensationStrategyTierInclusion_Set_Input>;
  /** filter the rows which have to be updated */
  where: CompensationStrategyTierInclusion_Bool_Exp;
};

/** aggregated selection of "compensationStrategyTier" */
export type CompensationStrategyTier_Aggregate = {
  __typename?: 'compensationStrategyTier_aggregate';
  aggregate?: Maybe<CompensationStrategyTier_Aggregate_Fields>;
  nodes: Array<CompensationStrategyTier>;
};

export type CompensationStrategyTier_Aggregate_Bool_Exp = {
  count?: InputMaybe<CompensationStrategyTier_Aggregate_Bool_Exp_Count>;
};

export type CompensationStrategyTier_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<CompensationStrategyTier_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<CompensationStrategyTier_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "compensationStrategyTier" */
export type CompensationStrategyTier_Aggregate_Fields = {
  __typename?: 'compensationStrategyTier_aggregate_fields';
  avg?: Maybe<CompensationStrategyTier_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<CompensationStrategyTier_Max_Fields>;
  min?: Maybe<CompensationStrategyTier_Min_Fields>;
  stddev?: Maybe<CompensationStrategyTier_Stddev_Fields>;
  stddev_pop?: Maybe<CompensationStrategyTier_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<CompensationStrategyTier_Stddev_Samp_Fields>;
  sum?: Maybe<CompensationStrategyTier_Sum_Fields>;
  var_pop?: Maybe<CompensationStrategyTier_Var_Pop_Fields>;
  var_samp?: Maybe<CompensationStrategyTier_Var_Samp_Fields>;
  variance?: Maybe<CompensationStrategyTier_Variance_Fields>;
};


/** aggregate fields of "compensationStrategyTier" */
export type CompensationStrategyTier_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<CompensationStrategyTier_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "compensationStrategyTier" */
export type CompensationStrategyTier_Aggregate_Order_By = {
  avg?: InputMaybe<CompensationStrategyTier_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<CompensationStrategyTier_Max_Order_By>;
  min?: InputMaybe<CompensationStrategyTier_Min_Order_By>;
  stddev?: InputMaybe<CompensationStrategyTier_Stddev_Order_By>;
  stddev_pop?: InputMaybe<CompensationStrategyTier_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<CompensationStrategyTier_Stddev_Samp_Order_By>;
  sum?: InputMaybe<CompensationStrategyTier_Sum_Order_By>;
  var_pop?: InputMaybe<CompensationStrategyTier_Var_Pop_Order_By>;
  var_samp?: InputMaybe<CompensationStrategyTier_Var_Samp_Order_By>;
  variance?: InputMaybe<CompensationStrategyTier_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "compensationStrategyTier" */
export type CompensationStrategyTier_Arr_Rel_Insert_Input = {
  data: Array<CompensationStrategyTier_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<CompensationStrategyTier_On_Conflict>;
};

/** aggregate avg on columns */
export type CompensationStrategyTier_Avg_Fields = {
  __typename?: 'compensationStrategyTier_avg_fields';
  compensationAmount?: Maybe<Scalars['Float']>;
  maxApplyValue?: Maybe<Scalars['Float']>;
  minApplyValue?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "compensationStrategyTier" */
export type CompensationStrategyTier_Avg_Order_By = {
  compensationAmount?: InputMaybe<Order_By>;
  maxApplyValue?: InputMaybe<Order_By>;
  minApplyValue?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "compensationStrategyTier". All fields are combined with a logical 'AND'. */
export type CompensationStrategyTier_Bool_Exp = {
  _and?: InputMaybe<Array<CompensationStrategyTier_Bool_Exp>>;
  _not?: InputMaybe<CompensationStrategyTier_Bool_Exp>;
  _or?: InputMaybe<Array<CompensationStrategyTier_Bool_Exp>>;
  compensationAmount?: InputMaybe<Int_Comparison_Exp>;
  compensationStrategyId?: InputMaybe<Uuid_Comparison_Exp>;
  compensationType?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  maxApplyValue?: InputMaybe<Int_Comparison_Exp>;
  minApplyValue?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  strategy?: InputMaybe<CompensationStrategy_Bool_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  workspace?: InputMaybe<Workspace_Bool_Exp>;
  workspaceId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "compensationStrategyTier" */
export enum CompensationStrategyTier_Constraint {
  /** unique or primary key constraint on columns "id" */
  CompensationStrategyTierPkey = 'compensationStrategyTier_pkey'
}

/** input type for incrementing numeric columns in table "compensationStrategyTier" */
export type CompensationStrategyTier_Inc_Input = {
  compensationAmount?: InputMaybe<Scalars['Int']>;
  maxApplyValue?: InputMaybe<Scalars['Int']>;
  minApplyValue?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "compensationStrategyTier" */
export type CompensationStrategyTier_Insert_Input = {
  compensationAmount?: InputMaybe<Scalars['Int']>;
  compensationStrategyId?: InputMaybe<Scalars['uuid']>;
  compensationType?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  maxApplyValue?: InputMaybe<Scalars['Int']>;
  minApplyValue?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  strategy?: InputMaybe<CompensationStrategy_Obj_Rel_Insert_Input>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspace?: InputMaybe<Workspace_Obj_Rel_Insert_Input>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type CompensationStrategyTier_Max_Fields = {
  __typename?: 'compensationStrategyTier_max_fields';
  compensationAmount?: Maybe<Scalars['Int']>;
  compensationStrategyId?: Maybe<Scalars['uuid']>;
  compensationType?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  maxApplyValue?: Maybe<Scalars['Int']>;
  minApplyValue?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "compensationStrategyTier" */
export type CompensationStrategyTier_Max_Order_By = {
  compensationAmount?: InputMaybe<Order_By>;
  compensationStrategyId?: InputMaybe<Order_By>;
  compensationType?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  maxApplyValue?: InputMaybe<Order_By>;
  minApplyValue?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type CompensationStrategyTier_Min_Fields = {
  __typename?: 'compensationStrategyTier_min_fields';
  compensationAmount?: Maybe<Scalars['Int']>;
  compensationStrategyId?: Maybe<Scalars['uuid']>;
  compensationType?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  maxApplyValue?: Maybe<Scalars['Int']>;
  minApplyValue?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "compensationStrategyTier" */
export type CompensationStrategyTier_Min_Order_By = {
  compensationAmount?: InputMaybe<Order_By>;
  compensationStrategyId?: InputMaybe<Order_By>;
  compensationType?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  maxApplyValue?: InputMaybe<Order_By>;
  minApplyValue?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "compensationStrategyTier" */
export type CompensationStrategyTier_Mutation_Response = {
  __typename?: 'compensationStrategyTier_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CompensationStrategyTier>;
};

/** on_conflict condition type for table "compensationStrategyTier" */
export type CompensationStrategyTier_On_Conflict = {
  constraint: CompensationStrategyTier_Constraint;
  update_columns?: Array<CompensationStrategyTier_Update_Column>;
  where?: InputMaybe<CompensationStrategyTier_Bool_Exp>;
};

/** Ordering options when selecting data from "compensationStrategyTier". */
export type CompensationStrategyTier_Order_By = {
  compensationAmount?: InputMaybe<Order_By>;
  compensationStrategyId?: InputMaybe<Order_By>;
  compensationType?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  maxApplyValue?: InputMaybe<Order_By>;
  minApplyValue?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  strategy?: InputMaybe<CompensationStrategy_Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  workspace?: InputMaybe<Workspace_Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: compensationStrategyTier */
export type CompensationStrategyTier_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "compensationStrategyTier" */
export enum CompensationStrategyTier_Select_Column {
  /** column name */
  CompensationAmount = 'compensationAmount',
  /** column name */
  CompensationStrategyId = 'compensationStrategyId',
  /** column name */
  CompensationType = 'compensationType',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  MaxApplyValue = 'maxApplyValue',
  /** column name */
  MinApplyValue = 'minApplyValue',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WorkspaceId = 'workspaceId'
}

/** input type for updating data in table "compensationStrategyTier" */
export type CompensationStrategyTier_Set_Input = {
  compensationAmount?: InputMaybe<Scalars['Int']>;
  compensationStrategyId?: InputMaybe<Scalars['uuid']>;
  compensationType?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  maxApplyValue?: InputMaybe<Scalars['Int']>;
  minApplyValue?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type CompensationStrategyTier_Stddev_Fields = {
  __typename?: 'compensationStrategyTier_stddev_fields';
  compensationAmount?: Maybe<Scalars['Float']>;
  maxApplyValue?: Maybe<Scalars['Float']>;
  minApplyValue?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "compensationStrategyTier" */
export type CompensationStrategyTier_Stddev_Order_By = {
  compensationAmount?: InputMaybe<Order_By>;
  maxApplyValue?: InputMaybe<Order_By>;
  minApplyValue?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type CompensationStrategyTier_Stddev_Pop_Fields = {
  __typename?: 'compensationStrategyTier_stddev_pop_fields';
  compensationAmount?: Maybe<Scalars['Float']>;
  maxApplyValue?: Maybe<Scalars['Float']>;
  minApplyValue?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "compensationStrategyTier" */
export type CompensationStrategyTier_Stddev_Pop_Order_By = {
  compensationAmount?: InputMaybe<Order_By>;
  maxApplyValue?: InputMaybe<Order_By>;
  minApplyValue?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type CompensationStrategyTier_Stddev_Samp_Fields = {
  __typename?: 'compensationStrategyTier_stddev_samp_fields';
  compensationAmount?: Maybe<Scalars['Float']>;
  maxApplyValue?: Maybe<Scalars['Float']>;
  minApplyValue?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "compensationStrategyTier" */
export type CompensationStrategyTier_Stddev_Samp_Order_By = {
  compensationAmount?: InputMaybe<Order_By>;
  maxApplyValue?: InputMaybe<Order_By>;
  minApplyValue?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "compensationStrategyTier" */
export type CompensationStrategyTier_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: CompensationStrategyTier_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type CompensationStrategyTier_Stream_Cursor_Value_Input = {
  compensationAmount?: InputMaybe<Scalars['Int']>;
  compensationStrategyId?: InputMaybe<Scalars['uuid']>;
  compensationType?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  maxApplyValue?: InputMaybe<Scalars['Int']>;
  minApplyValue?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type CompensationStrategyTier_Sum_Fields = {
  __typename?: 'compensationStrategyTier_sum_fields';
  compensationAmount?: Maybe<Scalars['Int']>;
  maxApplyValue?: Maybe<Scalars['Int']>;
  minApplyValue?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "compensationStrategyTier" */
export type CompensationStrategyTier_Sum_Order_By = {
  compensationAmount?: InputMaybe<Order_By>;
  maxApplyValue?: InputMaybe<Order_By>;
  minApplyValue?: InputMaybe<Order_By>;
};

/** update columns of table "compensationStrategyTier" */
export enum CompensationStrategyTier_Update_Column {
  /** column name */
  CompensationAmount = 'compensationAmount',
  /** column name */
  CompensationStrategyId = 'compensationStrategyId',
  /** column name */
  CompensationType = 'compensationType',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  MaxApplyValue = 'maxApplyValue',
  /** column name */
  MinApplyValue = 'minApplyValue',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WorkspaceId = 'workspaceId'
}

export type CompensationStrategyTier_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<CompensationStrategyTier_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CompensationStrategyTier_Set_Input>;
  /** filter the rows which have to be updated */
  where: CompensationStrategyTier_Bool_Exp;
};

/** aggregate var_pop on columns */
export type CompensationStrategyTier_Var_Pop_Fields = {
  __typename?: 'compensationStrategyTier_var_pop_fields';
  compensationAmount?: Maybe<Scalars['Float']>;
  maxApplyValue?: Maybe<Scalars['Float']>;
  minApplyValue?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "compensationStrategyTier" */
export type CompensationStrategyTier_Var_Pop_Order_By = {
  compensationAmount?: InputMaybe<Order_By>;
  maxApplyValue?: InputMaybe<Order_By>;
  minApplyValue?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type CompensationStrategyTier_Var_Samp_Fields = {
  __typename?: 'compensationStrategyTier_var_samp_fields';
  compensationAmount?: Maybe<Scalars['Float']>;
  maxApplyValue?: Maybe<Scalars['Float']>;
  minApplyValue?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "compensationStrategyTier" */
export type CompensationStrategyTier_Var_Samp_Order_By = {
  compensationAmount?: InputMaybe<Order_By>;
  maxApplyValue?: InputMaybe<Order_By>;
  minApplyValue?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type CompensationStrategyTier_Variance_Fields = {
  __typename?: 'compensationStrategyTier_variance_fields';
  compensationAmount?: Maybe<Scalars['Float']>;
  maxApplyValue?: Maybe<Scalars['Float']>;
  minApplyValue?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "compensationStrategyTier" */
export type CompensationStrategyTier_Variance_Order_By = {
  compensationAmount?: InputMaybe<Order_By>;
  maxApplyValue?: InputMaybe<Order_By>;
  minApplyValue?: InputMaybe<Order_By>;
};

/** aggregated selection of "compensationStrategy" */
export type CompensationStrategy_Aggregate = {
  __typename?: 'compensationStrategy_aggregate';
  aggregate?: Maybe<CompensationStrategy_Aggregate_Fields>;
  nodes: Array<CompensationStrategy>;
};

/** aggregate fields of "compensationStrategy" */
export type CompensationStrategy_Aggregate_Fields = {
  __typename?: 'compensationStrategy_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<CompensationStrategy_Max_Fields>;
  min?: Maybe<CompensationStrategy_Min_Fields>;
};


/** aggregate fields of "compensationStrategy" */
export type CompensationStrategy_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<CompensationStrategy_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type CompensationStrategy_Append_Input = {
  config?: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "compensationStrategy". All fields are combined with a logical 'AND'. */
export type CompensationStrategy_Bool_Exp = {
  _and?: InputMaybe<Array<CompensationStrategy_Bool_Exp>>;
  _not?: InputMaybe<CompensationStrategy_Bool_Exp>;
  _or?: InputMaybe<Array<CompensationStrategy_Bool_Exp>>;
  categories?: InputMaybe<CompensationStrategyTierCategory_Bool_Exp>;
  categories_aggregate?: InputMaybe<CompensationStrategyTierCategory_Aggregate_Bool_Exp>;
  commissions?: InputMaybe<CompensationStrategyCommission_Bool_Exp>;
  commissions_aggregate?: InputMaybe<CompensationStrategyCommission_Aggregate_Bool_Exp>;
  config?: InputMaybe<Jsonb_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  exemptions?: InputMaybe<CompensationStrategyTierExemption_Bool_Exp>;
  exemptions_aggregate?: InputMaybe<CompensationStrategyTierExemption_Aggregate_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  inclusions?: InputMaybe<CompensationStrategyTierInclusion_Bool_Exp>;
  inclusions_aggregate?: InputMaybe<CompensationStrategyTierInclusion_Aggregate_Bool_Exp>;
  isActive?: InputMaybe<Boolean_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  policies?: InputMaybe<CompensationPolicy_Bool_Exp>;
  policies_aggregate?: InputMaybe<CompensationPolicy_Aggregate_Bool_Exp>;
  strategyType?: InputMaybe<String_Comparison_Exp>;
  tiers?: InputMaybe<CompensationStrategyTier_Bool_Exp>;
  tiers_aggregate?: InputMaybe<CompensationStrategyTier_Aggregate_Bool_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  workspace?: InputMaybe<Workspace_Bool_Exp>;
  workspaceId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "compensationStrategy" */
export enum CompensationStrategy_Constraint {
  /** unique or primary key constraint on columns "id" */
  CompensationStrategyPkey = 'compensationStrategy_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type CompensationStrategy_Delete_At_Path_Input = {
  config?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type CompensationStrategy_Delete_Elem_Input = {
  config?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type CompensationStrategy_Delete_Key_Input = {
  config?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "compensationStrategy" */
export type CompensationStrategy_Insert_Input = {
  categories?: InputMaybe<CompensationStrategyTierCategory_Arr_Rel_Insert_Input>;
  commissions?: InputMaybe<CompensationStrategyCommission_Arr_Rel_Insert_Input>;
  config?: InputMaybe<Scalars['jsonb']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  exemptions?: InputMaybe<CompensationStrategyTierExemption_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']>;
  inclusions?: InputMaybe<CompensationStrategyTierInclusion_Arr_Rel_Insert_Input>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  policies?: InputMaybe<CompensationPolicy_Arr_Rel_Insert_Input>;
  strategyType?: InputMaybe<Scalars['String']>;
  tiers?: InputMaybe<CompensationStrategyTier_Arr_Rel_Insert_Input>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspace?: InputMaybe<Workspace_Obj_Rel_Insert_Input>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type CompensationStrategy_Max_Fields = {
  __typename?: 'compensationStrategy_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  strategyType?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type CompensationStrategy_Min_Fields = {
  __typename?: 'compensationStrategy_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  strategyType?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "compensationStrategy" */
export type CompensationStrategy_Mutation_Response = {
  __typename?: 'compensationStrategy_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CompensationStrategy>;
};

/** input type for inserting object relation for remote table "compensationStrategy" */
export type CompensationStrategy_Obj_Rel_Insert_Input = {
  data: CompensationStrategy_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<CompensationStrategy_On_Conflict>;
};

/** on_conflict condition type for table "compensationStrategy" */
export type CompensationStrategy_On_Conflict = {
  constraint: CompensationStrategy_Constraint;
  update_columns?: Array<CompensationStrategy_Update_Column>;
  where?: InputMaybe<CompensationStrategy_Bool_Exp>;
};

/** Ordering options when selecting data from "compensationStrategy". */
export type CompensationStrategy_Order_By = {
  categories_aggregate?: InputMaybe<CompensationStrategyTierCategory_Aggregate_Order_By>;
  commissions_aggregate?: InputMaybe<CompensationStrategyCommission_Aggregate_Order_By>;
  config?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  exemptions_aggregate?: InputMaybe<CompensationStrategyTierExemption_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  inclusions_aggregate?: InputMaybe<CompensationStrategyTierInclusion_Aggregate_Order_By>;
  isActive?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  policies_aggregate?: InputMaybe<CompensationPolicy_Aggregate_Order_By>;
  strategyType?: InputMaybe<Order_By>;
  tiers_aggregate?: InputMaybe<CompensationStrategyTier_Aggregate_Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  workspace?: InputMaybe<Workspace_Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: compensationStrategy */
export type CompensationStrategy_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type CompensationStrategy_Prepend_Input = {
  config?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "compensationStrategy" */
export enum CompensationStrategy_Select_Column {
  /** column name */
  Config = 'config',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'isActive',
  /** column name */
  Name = 'name',
  /** column name */
  StrategyType = 'strategyType',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WorkspaceId = 'workspaceId'
}

/** input type for updating data in table "compensationStrategy" */
export type CompensationStrategy_Set_Input = {
  config?: InputMaybe<Scalars['jsonb']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  strategyType?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "compensationStrategy" */
export type CompensationStrategy_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: CompensationStrategy_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type CompensationStrategy_Stream_Cursor_Value_Input = {
  config?: InputMaybe<Scalars['jsonb']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  strategyType?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "compensationStrategy" */
export enum CompensationStrategy_Update_Column {
  /** column name */
  Config = 'config',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'isActive',
  /** column name */
  Name = 'name',
  /** column name */
  StrategyType = 'strategyType',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WorkspaceId = 'workspaceId'
}

export type CompensationStrategy_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<CompensationStrategy_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<CompensationStrategy_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<CompensationStrategy_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<CompensationStrategy_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<CompensationStrategy_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CompensationStrategy_Set_Input>;
  /** filter the rows which have to be updated */
  where: CompensationStrategy_Bool_Exp;
};

/** columns and relationships of "consent" */
export type Consent = {
  __typename?: 'consent';
  createdAt: Scalars['timestamptz'];
  description?: Maybe<Scalars['String']>;
  /** An object relationship */
  document?: Maybe<Document>;
  documentId?: Maybe<Scalars['uuid']>;
  id: Scalars['uuid'];
  isFullMediaConsent: Scalars['Boolean'];
  isPartialMediaConsent: Scalars['Boolean'];
  /** An array relationship */
  patientConsents: Array<PatientConsent>;
  /** An aggregate relationship */
  patientConsents_aggregate: PatientConsent_Aggregate;
  recurrence: RecurrenceType_Enum;
  requireProviderSignature?: Maybe<Scalars['Boolean']>;
  requireWitnessSignature?: Maybe<Scalars['Boolean']>;
  requiredAll: Scalars['Boolean'];
  /** An array relationship */
  serviceConsents: Array<ServiceConsent>;
  /** An aggregate relationship */
  serviceConsents_aggregate: ServiceConsent_Aggregate;
  surveyJSJson?: Maybe<Scalars['jsonb']>;
  title: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  /** An object relationship */
  workspace: Workspace;
  workspaceId: Scalars['uuid'];
};


/** columns and relationships of "consent" */
export type ConsentPatientConsentsArgs = {
  distinct_on?: InputMaybe<Array<PatientConsent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PatientConsent_Order_By>>;
  where?: InputMaybe<PatientConsent_Bool_Exp>;
};


/** columns and relationships of "consent" */
export type ConsentPatientConsents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<PatientConsent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PatientConsent_Order_By>>;
  where?: InputMaybe<PatientConsent_Bool_Exp>;
};


/** columns and relationships of "consent" */
export type ConsentServiceConsentsArgs = {
  distinct_on?: InputMaybe<Array<ServiceConsent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ServiceConsent_Order_By>>;
  where?: InputMaybe<ServiceConsent_Bool_Exp>;
};


/** columns and relationships of "consent" */
export type ConsentServiceConsents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ServiceConsent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ServiceConsent_Order_By>>;
  where?: InputMaybe<ServiceConsent_Bool_Exp>;
};


/** columns and relationships of "consent" */
export type ConsentSurveyJsJsonArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "consent" */
export type Consent_Aggregate = {
  __typename?: 'consent_aggregate';
  aggregate?: Maybe<Consent_Aggregate_Fields>;
  nodes: Array<Consent>;
};

/** aggregate fields of "consent" */
export type Consent_Aggregate_Fields = {
  __typename?: 'consent_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Consent_Max_Fields>;
  min?: Maybe<Consent_Min_Fields>;
};


/** aggregate fields of "consent" */
export type Consent_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Consent_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Consent_Append_Input = {
  surveyJSJson?: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "consent". All fields are combined with a logical 'AND'. */
export type Consent_Bool_Exp = {
  _and?: InputMaybe<Array<Consent_Bool_Exp>>;
  _not?: InputMaybe<Consent_Bool_Exp>;
  _or?: InputMaybe<Array<Consent_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  document?: InputMaybe<Document_Bool_Exp>;
  documentId?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  isFullMediaConsent?: InputMaybe<Boolean_Comparison_Exp>;
  isPartialMediaConsent?: InputMaybe<Boolean_Comparison_Exp>;
  patientConsents?: InputMaybe<PatientConsent_Bool_Exp>;
  patientConsents_aggregate?: InputMaybe<PatientConsent_Aggregate_Bool_Exp>;
  recurrence?: InputMaybe<RecurrenceType_Enum_Comparison_Exp>;
  requireProviderSignature?: InputMaybe<Boolean_Comparison_Exp>;
  requireWitnessSignature?: InputMaybe<Boolean_Comparison_Exp>;
  requiredAll?: InputMaybe<Boolean_Comparison_Exp>;
  serviceConsents?: InputMaybe<ServiceConsent_Bool_Exp>;
  serviceConsents_aggregate?: InputMaybe<ServiceConsent_Aggregate_Bool_Exp>;
  surveyJSJson?: InputMaybe<Jsonb_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  workspace?: InputMaybe<Workspace_Bool_Exp>;
  workspaceId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "consent" */
export enum Consent_Constraint {
  /** unique or primary key constraint on columns "id" */
  ConsentPkey = 'consent_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Consent_Delete_At_Path_Input = {
  surveyJSJson?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Consent_Delete_Elem_Input = {
  surveyJSJson?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Consent_Delete_Key_Input = {
  surveyJSJson?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "consent" */
export type Consent_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  document?: InputMaybe<Document_Obj_Rel_Insert_Input>;
  documentId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  isFullMediaConsent?: InputMaybe<Scalars['Boolean']>;
  isPartialMediaConsent?: InputMaybe<Scalars['Boolean']>;
  patientConsents?: InputMaybe<PatientConsent_Arr_Rel_Insert_Input>;
  recurrence?: InputMaybe<RecurrenceType_Enum>;
  requireProviderSignature?: InputMaybe<Scalars['Boolean']>;
  requireWitnessSignature?: InputMaybe<Scalars['Boolean']>;
  requiredAll?: InputMaybe<Scalars['Boolean']>;
  serviceConsents?: InputMaybe<ServiceConsent_Arr_Rel_Insert_Input>;
  surveyJSJson?: InputMaybe<Scalars['jsonb']>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspace?: InputMaybe<Workspace_Obj_Rel_Insert_Input>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Consent_Max_Fields = {
  __typename?: 'consent_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  documentId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Consent_Min_Fields = {
  __typename?: 'consent_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  documentId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "consent" */
export type Consent_Mutation_Response = {
  __typename?: 'consent_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Consent>;
};

/** input type for inserting object relation for remote table "consent" */
export type Consent_Obj_Rel_Insert_Input = {
  data: Consent_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Consent_On_Conflict>;
};

/** on_conflict condition type for table "consent" */
export type Consent_On_Conflict = {
  constraint: Consent_Constraint;
  update_columns?: Array<Consent_Update_Column>;
  where?: InputMaybe<Consent_Bool_Exp>;
};

/** Ordering options when selecting data from "consent". */
export type Consent_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  document?: InputMaybe<Document_Order_By>;
  documentId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  isFullMediaConsent?: InputMaybe<Order_By>;
  isPartialMediaConsent?: InputMaybe<Order_By>;
  patientConsents_aggregate?: InputMaybe<PatientConsent_Aggregate_Order_By>;
  recurrence?: InputMaybe<Order_By>;
  requireProviderSignature?: InputMaybe<Order_By>;
  requireWitnessSignature?: InputMaybe<Order_By>;
  requiredAll?: InputMaybe<Order_By>;
  serviceConsents_aggregate?: InputMaybe<ServiceConsent_Aggregate_Order_By>;
  surveyJSJson?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  workspace?: InputMaybe<Workspace_Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: consent */
export type Consent_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Consent_Prepend_Input = {
  surveyJSJson?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "consent" */
export enum Consent_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  DocumentId = 'documentId',
  /** column name */
  Id = 'id',
  /** column name */
  IsFullMediaConsent = 'isFullMediaConsent',
  /** column name */
  IsPartialMediaConsent = 'isPartialMediaConsent',
  /** column name */
  Recurrence = 'recurrence',
  /** column name */
  RequireProviderSignature = 'requireProviderSignature',
  /** column name */
  RequireWitnessSignature = 'requireWitnessSignature',
  /** column name */
  RequiredAll = 'requiredAll',
  /** column name */
  SurveyJsJson = 'surveyJSJson',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WorkspaceId = 'workspaceId'
}

/** input type for updating data in table "consent" */
export type Consent_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  documentId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  isFullMediaConsent?: InputMaybe<Scalars['Boolean']>;
  isPartialMediaConsent?: InputMaybe<Scalars['Boolean']>;
  recurrence?: InputMaybe<RecurrenceType_Enum>;
  requireProviderSignature?: InputMaybe<Scalars['Boolean']>;
  requireWitnessSignature?: InputMaybe<Scalars['Boolean']>;
  requiredAll?: InputMaybe<Scalars['Boolean']>;
  surveyJSJson?: InputMaybe<Scalars['jsonb']>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "consent" */
export type Consent_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Consent_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Consent_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  documentId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  isFullMediaConsent?: InputMaybe<Scalars['Boolean']>;
  isPartialMediaConsent?: InputMaybe<Scalars['Boolean']>;
  recurrence?: InputMaybe<RecurrenceType_Enum>;
  requireProviderSignature?: InputMaybe<Scalars['Boolean']>;
  requireWitnessSignature?: InputMaybe<Scalars['Boolean']>;
  requiredAll?: InputMaybe<Scalars['Boolean']>;
  surveyJSJson?: InputMaybe<Scalars['jsonb']>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "consent" */
export enum Consent_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  DocumentId = 'documentId',
  /** column name */
  Id = 'id',
  /** column name */
  IsFullMediaConsent = 'isFullMediaConsent',
  /** column name */
  IsPartialMediaConsent = 'isPartialMediaConsent',
  /** column name */
  Recurrence = 'recurrence',
  /** column name */
  RequireProviderSignature = 'requireProviderSignature',
  /** column name */
  RequireWitnessSignature = 'requireWitnessSignature',
  /** column name */
  RequiredAll = 'requiredAll',
  /** column name */
  SurveyJsJson = 'surveyJSJson',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WorkspaceId = 'workspaceId'
}

export type Consent_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Consent_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Consent_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Consent_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Consent_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Consent_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Consent_Set_Input>;
  /** filter the rows which have to be updated */
  where: Consent_Bool_Exp;
};

/** columns and relationships of "consumable" */
export type Consumable = {
  __typename?: 'consumable';
  archived: Scalars['Boolean'];
  /** An object relationship */
  brand?: Maybe<ConsumableBrand>;
  consumableBrandId?: Maybe<Scalars['uuid']>;
  consumableTypeId: Scalars['uuid'];
  cost: Scalars['Int'];
  createdAt: Scalars['timestamptz'];
  daysBeforeNotification?: Maybe<Scalars['Int']>;
  expirationDate?: Maybe<Scalars['date']>;
  id: Scalars['uuid'];
  isExpirable: Scalars['Boolean'];
  metadata: Scalars['jsonb'];
  originalQuantity: Scalars['float8'];
  /** An object relationship */
  purchaseOrder?: Maybe<PurchaseOrder>;
  purchaseOrderId?: Maybe<Scalars['uuid']>;
  quantity: Scalars['float8'];
  shouldWarnQuantity: Scalars['Boolean'];
  /** An object relationship */
  type: ConsumableType;
  updatedAt: Scalars['timestamptz'];
  warningQuantity?: Maybe<Scalars['Int']>;
  workspaceId: Scalars['uuid'];
};


/** columns and relationships of "consumable" */
export type ConsumableMetadataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "consumableBrand" */
export type ConsumableBrand = {
  __typename?: 'consumableBrand';
  archived: Scalars['Boolean'];
  /** An array relationship */
  consumables: Array<Consumable>;
  /** An aggregate relationship */
  consumables_aggregate: Consumable_Aggregate;
  contactEmail?: Maybe<Scalars['String']>;
  contactName?: Maybe<Scalars['String']>;
  contactPhoneNumber?: Maybe<Scalars['String']>;
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  name: Scalars['String'];
  note?: Maybe<Scalars['String']>;
  /** An array relationship */
  purchaseOrders: Array<PurchaseOrder>;
  /** An aggregate relationship */
  purchaseOrders_aggregate: PurchaseOrder_Aggregate;
  updatedAt: Scalars['timestamptz'];
  workspaceId: Scalars['uuid'];
};


/** columns and relationships of "consumableBrand" */
export type ConsumableBrandConsumablesArgs = {
  distinct_on?: InputMaybe<Array<Consumable_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Consumable_Order_By>>;
  where?: InputMaybe<Consumable_Bool_Exp>;
};


/** columns and relationships of "consumableBrand" */
export type ConsumableBrandConsumables_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Consumable_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Consumable_Order_By>>;
  where?: InputMaybe<Consumable_Bool_Exp>;
};


/** columns and relationships of "consumableBrand" */
export type ConsumableBrandPurchaseOrdersArgs = {
  distinct_on?: InputMaybe<Array<PurchaseOrder_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PurchaseOrder_Order_By>>;
  where?: InputMaybe<PurchaseOrder_Bool_Exp>;
};


/** columns and relationships of "consumableBrand" */
export type ConsumableBrandPurchaseOrders_AggregateArgs = {
  distinct_on?: InputMaybe<Array<PurchaseOrder_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PurchaseOrder_Order_By>>;
  where?: InputMaybe<PurchaseOrder_Bool_Exp>;
};

/** aggregated selection of "consumableBrand" */
export type ConsumableBrand_Aggregate = {
  __typename?: 'consumableBrand_aggregate';
  aggregate?: Maybe<ConsumableBrand_Aggregate_Fields>;
  nodes: Array<ConsumableBrand>;
};

/** aggregate fields of "consumableBrand" */
export type ConsumableBrand_Aggregate_Fields = {
  __typename?: 'consumableBrand_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ConsumableBrand_Max_Fields>;
  min?: Maybe<ConsumableBrand_Min_Fields>;
};


/** aggregate fields of "consumableBrand" */
export type ConsumableBrand_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<ConsumableBrand_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "consumableBrand". All fields are combined with a logical 'AND'. */
export type ConsumableBrand_Bool_Exp = {
  _and?: InputMaybe<Array<ConsumableBrand_Bool_Exp>>;
  _not?: InputMaybe<ConsumableBrand_Bool_Exp>;
  _or?: InputMaybe<Array<ConsumableBrand_Bool_Exp>>;
  archived?: InputMaybe<Boolean_Comparison_Exp>;
  consumables?: InputMaybe<Consumable_Bool_Exp>;
  consumables_aggregate?: InputMaybe<Consumable_Aggregate_Bool_Exp>;
  contactEmail?: InputMaybe<String_Comparison_Exp>;
  contactName?: InputMaybe<String_Comparison_Exp>;
  contactPhoneNumber?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  note?: InputMaybe<String_Comparison_Exp>;
  purchaseOrders?: InputMaybe<PurchaseOrder_Bool_Exp>;
  purchaseOrders_aggregate?: InputMaybe<PurchaseOrder_Aggregate_Bool_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  workspaceId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "consumableBrand" */
export enum ConsumableBrand_Constraint {
  /** unique or primary key constraint on columns "workspaceId", "name" */
  ConsumableBrandNameWorkspaceIdKey = 'consumableBrand_name_workspaceId_key',
  /** unique or primary key constraint on columns "id" */
  ConsumableBrandPkey = 'consumableBrand_pkey'
}

/** input type for inserting data into table "consumableBrand" */
export type ConsumableBrand_Insert_Input = {
  archived?: InputMaybe<Scalars['Boolean']>;
  consumables?: InputMaybe<Consumable_Arr_Rel_Insert_Input>;
  contactEmail?: InputMaybe<Scalars['String']>;
  contactName?: InputMaybe<Scalars['String']>;
  contactPhoneNumber?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  note?: InputMaybe<Scalars['String']>;
  purchaseOrders?: InputMaybe<PurchaseOrder_Arr_Rel_Insert_Input>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type ConsumableBrand_Max_Fields = {
  __typename?: 'consumableBrand_max_fields';
  contactEmail?: Maybe<Scalars['String']>;
  contactName?: Maybe<Scalars['String']>;
  contactPhoneNumber?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type ConsumableBrand_Min_Fields = {
  __typename?: 'consumableBrand_min_fields';
  contactEmail?: Maybe<Scalars['String']>;
  contactName?: Maybe<Scalars['String']>;
  contactPhoneNumber?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "consumableBrand" */
export type ConsumableBrand_Mutation_Response = {
  __typename?: 'consumableBrand_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ConsumableBrand>;
};

/** input type for inserting object relation for remote table "consumableBrand" */
export type ConsumableBrand_Obj_Rel_Insert_Input = {
  data: ConsumableBrand_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<ConsumableBrand_On_Conflict>;
};

/** on_conflict condition type for table "consumableBrand" */
export type ConsumableBrand_On_Conflict = {
  constraint: ConsumableBrand_Constraint;
  update_columns?: Array<ConsumableBrand_Update_Column>;
  where?: InputMaybe<ConsumableBrand_Bool_Exp>;
};

/** Ordering options when selecting data from "consumableBrand". */
export type ConsumableBrand_Order_By = {
  archived?: InputMaybe<Order_By>;
  consumables_aggregate?: InputMaybe<Consumable_Aggregate_Order_By>;
  contactEmail?: InputMaybe<Order_By>;
  contactName?: InputMaybe<Order_By>;
  contactPhoneNumber?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  note?: InputMaybe<Order_By>;
  purchaseOrders_aggregate?: InputMaybe<PurchaseOrder_Aggregate_Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: consumableBrand */
export type ConsumableBrand_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "consumableBrand" */
export enum ConsumableBrand_Select_Column {
  /** column name */
  Archived = 'archived',
  /** column name */
  ContactEmail = 'contactEmail',
  /** column name */
  ContactName = 'contactName',
  /** column name */
  ContactPhoneNumber = 'contactPhoneNumber',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Note = 'note',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WorkspaceId = 'workspaceId'
}

/** input type for updating data in table "consumableBrand" */
export type ConsumableBrand_Set_Input = {
  archived?: InputMaybe<Scalars['Boolean']>;
  contactEmail?: InputMaybe<Scalars['String']>;
  contactName?: InputMaybe<Scalars['String']>;
  contactPhoneNumber?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  note?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "consumableBrand" */
export type ConsumableBrand_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ConsumableBrand_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ConsumableBrand_Stream_Cursor_Value_Input = {
  archived?: InputMaybe<Scalars['Boolean']>;
  contactEmail?: InputMaybe<Scalars['String']>;
  contactName?: InputMaybe<Scalars['String']>;
  contactPhoneNumber?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  note?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "consumableBrand" */
export enum ConsumableBrand_Update_Column {
  /** column name */
  Archived = 'archived',
  /** column name */
  ContactEmail = 'contactEmail',
  /** column name */
  ContactName = 'contactName',
  /** column name */
  ContactPhoneNumber = 'contactPhoneNumber',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Note = 'note',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WorkspaceId = 'workspaceId'
}

export type ConsumableBrand_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ConsumableBrand_Set_Input>;
  /** filter the rows which have to be updated */
  where: ConsumableBrand_Bool_Exp;
};

/** columns and relationships of "consumableSubtype" */
export type ConsumableSubtype = {
  __typename?: 'consumableSubtype';
  /** An array relationship */
  consumableTypeConsumableSubtypes: Array<ConsumableTypeConsumableSubtype>;
  /** An aggregate relationship */
  consumableTypeConsumableSubtypes_aggregate: ConsumableTypeConsumableSubtype_Aggregate;
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  name: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  workspaceId: Scalars['uuid'];
};


/** columns and relationships of "consumableSubtype" */
export type ConsumableSubtypeConsumableTypeConsumableSubtypesArgs = {
  distinct_on?: InputMaybe<Array<ConsumableTypeConsumableSubtype_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ConsumableTypeConsumableSubtype_Order_By>>;
  where?: InputMaybe<ConsumableTypeConsumableSubtype_Bool_Exp>;
};


/** columns and relationships of "consumableSubtype" */
export type ConsumableSubtypeConsumableTypeConsumableSubtypes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ConsumableTypeConsumableSubtype_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ConsumableTypeConsumableSubtype_Order_By>>;
  where?: InputMaybe<ConsumableTypeConsumableSubtype_Bool_Exp>;
};

/** aggregated selection of "consumableSubtype" */
export type ConsumableSubtype_Aggregate = {
  __typename?: 'consumableSubtype_aggregate';
  aggregate?: Maybe<ConsumableSubtype_Aggregate_Fields>;
  nodes: Array<ConsumableSubtype>;
};

/** aggregate fields of "consumableSubtype" */
export type ConsumableSubtype_Aggregate_Fields = {
  __typename?: 'consumableSubtype_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ConsumableSubtype_Max_Fields>;
  min?: Maybe<ConsumableSubtype_Min_Fields>;
};


/** aggregate fields of "consumableSubtype" */
export type ConsumableSubtype_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<ConsumableSubtype_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "consumableSubtype". All fields are combined with a logical 'AND'. */
export type ConsumableSubtype_Bool_Exp = {
  _and?: InputMaybe<Array<ConsumableSubtype_Bool_Exp>>;
  _not?: InputMaybe<ConsumableSubtype_Bool_Exp>;
  _or?: InputMaybe<Array<ConsumableSubtype_Bool_Exp>>;
  consumableTypeConsumableSubtypes?: InputMaybe<ConsumableTypeConsumableSubtype_Bool_Exp>;
  consumableTypeConsumableSubtypes_aggregate?: InputMaybe<ConsumableTypeConsumableSubtype_Aggregate_Bool_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  workspaceId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "consumableSubtype" */
export enum ConsumableSubtype_Constraint {
  /** unique or primary key constraint on columns "workspaceId", "name" */
  ConsumableSubtypeNameWorkspaceIdKey = 'consumableSubtype_name_workspaceId_key',
  /** unique or primary key constraint on columns "id" */
  ConsumableSubtypePkey = 'consumableSubtype_pkey'
}

/** input type for inserting data into table "consumableSubtype" */
export type ConsumableSubtype_Insert_Input = {
  consumableTypeConsumableSubtypes?: InputMaybe<ConsumableTypeConsumableSubtype_Arr_Rel_Insert_Input>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type ConsumableSubtype_Max_Fields = {
  __typename?: 'consumableSubtype_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type ConsumableSubtype_Min_Fields = {
  __typename?: 'consumableSubtype_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "consumableSubtype" */
export type ConsumableSubtype_Mutation_Response = {
  __typename?: 'consumableSubtype_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ConsumableSubtype>;
};

/** input type for inserting object relation for remote table "consumableSubtype" */
export type ConsumableSubtype_Obj_Rel_Insert_Input = {
  data: ConsumableSubtype_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<ConsumableSubtype_On_Conflict>;
};

/** on_conflict condition type for table "consumableSubtype" */
export type ConsumableSubtype_On_Conflict = {
  constraint: ConsumableSubtype_Constraint;
  update_columns?: Array<ConsumableSubtype_Update_Column>;
  where?: InputMaybe<ConsumableSubtype_Bool_Exp>;
};

/** Ordering options when selecting data from "consumableSubtype". */
export type ConsumableSubtype_Order_By = {
  consumableTypeConsumableSubtypes_aggregate?: InputMaybe<ConsumableTypeConsumableSubtype_Aggregate_Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: consumableSubtype */
export type ConsumableSubtype_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "consumableSubtype" */
export enum ConsumableSubtype_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WorkspaceId = 'workspaceId'
}

/** input type for updating data in table "consumableSubtype" */
export type ConsumableSubtype_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "consumableSubtype" */
export type ConsumableSubtype_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ConsumableSubtype_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ConsumableSubtype_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "consumableSubtype" */
export enum ConsumableSubtype_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WorkspaceId = 'workspaceId'
}

export type ConsumableSubtype_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ConsumableSubtype_Set_Input>;
  /** filter the rows which have to be updated */
  where: ConsumableSubtype_Bool_Exp;
};

/** columns and relationships of "consumableType" */
export type ConsumableType = {
  __typename?: 'consumableType';
  archived: Scalars['Boolean'];
  /** An array relationship */
  consumableTypeConsumableSubtypes: Array<ConsumableTypeConsumableSubtype>;
  /** An aggregate relationship */
  consumableTypeConsumableSubtypes_aggregate: ConsumableTypeConsumableSubtype_Aggregate;
  /** An array relationship */
  consumables: Array<Consumable>;
  /** An aggregate relationship */
  consumables_aggregate: Consumable_Aggregate;
  createdAt: Scalars['timestamptz'];
  defaultNumberOfUnits: Scalars['float8'];
  defaultPricePerUnit: Scalars['Int'];
  id: Scalars['uuid'];
  name: Scalars['String'];
  productBarcodeId?: Maybe<Scalars['String']>;
  requiredFields: Scalars['jsonb'];
  unitName: Scalars['String'];
  unitStep: Scalars['float8'];
  updatedAt: Scalars['timestamptz'];
  workspaceId: Scalars['uuid'];
};


/** columns and relationships of "consumableType" */
export type ConsumableTypeConsumableTypeConsumableSubtypesArgs = {
  distinct_on?: InputMaybe<Array<ConsumableTypeConsumableSubtype_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ConsumableTypeConsumableSubtype_Order_By>>;
  where?: InputMaybe<ConsumableTypeConsumableSubtype_Bool_Exp>;
};


/** columns and relationships of "consumableType" */
export type ConsumableTypeConsumableTypeConsumableSubtypes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ConsumableTypeConsumableSubtype_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ConsumableTypeConsumableSubtype_Order_By>>;
  where?: InputMaybe<ConsumableTypeConsumableSubtype_Bool_Exp>;
};


/** columns and relationships of "consumableType" */
export type ConsumableTypeConsumablesArgs = {
  distinct_on?: InputMaybe<Array<Consumable_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Consumable_Order_By>>;
  where?: InputMaybe<Consumable_Bool_Exp>;
};


/** columns and relationships of "consumableType" */
export type ConsumableTypeConsumables_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Consumable_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Consumable_Order_By>>;
  where?: InputMaybe<Consumable_Bool_Exp>;
};


/** columns and relationships of "consumableType" */
export type ConsumableTypeRequiredFieldsArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "consumableTypeConsumableSubtype" */
export type ConsumableTypeConsumableSubtype = {
  __typename?: 'consumableTypeConsumableSubtype';
  /** An object relationship */
  consumableSubtype: ConsumableSubtype;
  consumableSubtypeId: Scalars['uuid'];
  /** An object relationship */
  consumableType: ConsumableType;
  consumableTypeId: Scalars['uuid'];
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  updatedAt: Scalars['timestamptz'];
};

/** aggregated selection of "consumableTypeConsumableSubtype" */
export type ConsumableTypeConsumableSubtype_Aggregate = {
  __typename?: 'consumableTypeConsumableSubtype_aggregate';
  aggregate?: Maybe<ConsumableTypeConsumableSubtype_Aggregate_Fields>;
  nodes: Array<ConsumableTypeConsumableSubtype>;
};

export type ConsumableTypeConsumableSubtype_Aggregate_Bool_Exp = {
  count?: InputMaybe<ConsumableTypeConsumableSubtype_Aggregate_Bool_Exp_Count>;
};

export type ConsumableTypeConsumableSubtype_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<ConsumableTypeConsumableSubtype_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ConsumableTypeConsumableSubtype_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "consumableTypeConsumableSubtype" */
export type ConsumableTypeConsumableSubtype_Aggregate_Fields = {
  __typename?: 'consumableTypeConsumableSubtype_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ConsumableTypeConsumableSubtype_Max_Fields>;
  min?: Maybe<ConsumableTypeConsumableSubtype_Min_Fields>;
};


/** aggregate fields of "consumableTypeConsumableSubtype" */
export type ConsumableTypeConsumableSubtype_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<ConsumableTypeConsumableSubtype_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "consumableTypeConsumableSubtype" */
export type ConsumableTypeConsumableSubtype_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<ConsumableTypeConsumableSubtype_Max_Order_By>;
  min?: InputMaybe<ConsumableTypeConsumableSubtype_Min_Order_By>;
};

/** input type for inserting array relation for remote table "consumableTypeConsumableSubtype" */
export type ConsumableTypeConsumableSubtype_Arr_Rel_Insert_Input = {
  data: Array<ConsumableTypeConsumableSubtype_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<ConsumableTypeConsumableSubtype_On_Conflict>;
};

/** Boolean expression to filter rows from the table "consumableTypeConsumableSubtype". All fields are combined with a logical 'AND'. */
export type ConsumableTypeConsumableSubtype_Bool_Exp = {
  _and?: InputMaybe<Array<ConsumableTypeConsumableSubtype_Bool_Exp>>;
  _not?: InputMaybe<ConsumableTypeConsumableSubtype_Bool_Exp>;
  _or?: InputMaybe<Array<ConsumableTypeConsumableSubtype_Bool_Exp>>;
  consumableSubtype?: InputMaybe<ConsumableSubtype_Bool_Exp>;
  consumableSubtypeId?: InputMaybe<Uuid_Comparison_Exp>;
  consumableType?: InputMaybe<ConsumableType_Bool_Exp>;
  consumableTypeId?: InputMaybe<Uuid_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "consumableTypeConsumableSubtype" */
export enum ConsumableTypeConsumableSubtype_Constraint {
  /** unique or primary key constraint on columns "consumableSubtypeId", "consumableTypeId" */
  ConsumableTypeConsumableSubtyConsumableTypeIdConsumableSuKey = 'consumableTypeConsumableSubty_consumableTypeId_consumableSu_key',
  /** unique or primary key constraint on columns "id" */
  ConsumableTypeConsumableSubtypePkey = 'consumableTypeConsumableSubtype_pkey'
}

/** input type for inserting data into table "consumableTypeConsumableSubtype" */
export type ConsumableTypeConsumableSubtype_Insert_Input = {
  consumableSubtype?: InputMaybe<ConsumableSubtype_Obj_Rel_Insert_Input>;
  consumableSubtypeId?: InputMaybe<Scalars['uuid']>;
  consumableType?: InputMaybe<ConsumableType_Obj_Rel_Insert_Input>;
  consumableTypeId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type ConsumableTypeConsumableSubtype_Max_Fields = {
  __typename?: 'consumableTypeConsumableSubtype_max_fields';
  consumableSubtypeId?: Maybe<Scalars['uuid']>;
  consumableTypeId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "consumableTypeConsumableSubtype" */
export type ConsumableTypeConsumableSubtype_Max_Order_By = {
  consumableSubtypeId?: InputMaybe<Order_By>;
  consumableTypeId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type ConsumableTypeConsumableSubtype_Min_Fields = {
  __typename?: 'consumableTypeConsumableSubtype_min_fields';
  consumableSubtypeId?: Maybe<Scalars['uuid']>;
  consumableTypeId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "consumableTypeConsumableSubtype" */
export type ConsumableTypeConsumableSubtype_Min_Order_By = {
  consumableSubtypeId?: InputMaybe<Order_By>;
  consumableTypeId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "consumableTypeConsumableSubtype" */
export type ConsumableTypeConsumableSubtype_Mutation_Response = {
  __typename?: 'consumableTypeConsumableSubtype_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ConsumableTypeConsumableSubtype>;
};

/** on_conflict condition type for table "consumableTypeConsumableSubtype" */
export type ConsumableTypeConsumableSubtype_On_Conflict = {
  constraint: ConsumableTypeConsumableSubtype_Constraint;
  update_columns?: Array<ConsumableTypeConsumableSubtype_Update_Column>;
  where?: InputMaybe<ConsumableTypeConsumableSubtype_Bool_Exp>;
};

/** Ordering options when selecting data from "consumableTypeConsumableSubtype". */
export type ConsumableTypeConsumableSubtype_Order_By = {
  consumableSubtype?: InputMaybe<ConsumableSubtype_Order_By>;
  consumableSubtypeId?: InputMaybe<Order_By>;
  consumableType?: InputMaybe<ConsumableType_Order_By>;
  consumableTypeId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: consumableTypeConsumableSubtype */
export type ConsumableTypeConsumableSubtype_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "consumableTypeConsumableSubtype" */
export enum ConsumableTypeConsumableSubtype_Select_Column {
  /** column name */
  ConsumableSubtypeId = 'consumableSubtypeId',
  /** column name */
  ConsumableTypeId = 'consumableTypeId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "consumableTypeConsumableSubtype" */
export type ConsumableTypeConsumableSubtype_Set_Input = {
  consumableSubtypeId?: InputMaybe<Scalars['uuid']>;
  consumableTypeId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "consumableTypeConsumableSubtype" */
export type ConsumableTypeConsumableSubtype_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ConsumableTypeConsumableSubtype_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ConsumableTypeConsumableSubtype_Stream_Cursor_Value_Input = {
  consumableSubtypeId?: InputMaybe<Scalars['uuid']>;
  consumableTypeId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "consumableTypeConsumableSubtype" */
export enum ConsumableTypeConsumableSubtype_Update_Column {
  /** column name */
  ConsumableSubtypeId = 'consumableSubtypeId',
  /** column name */
  ConsumableTypeId = 'consumableTypeId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type ConsumableTypeConsumableSubtype_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ConsumableTypeConsumableSubtype_Set_Input>;
  /** filter the rows which have to be updated */
  where: ConsumableTypeConsumableSubtype_Bool_Exp;
};

/** aggregated selection of "consumableType" */
export type ConsumableType_Aggregate = {
  __typename?: 'consumableType_aggregate';
  aggregate?: Maybe<ConsumableType_Aggregate_Fields>;
  nodes: Array<ConsumableType>;
};

/** aggregate fields of "consumableType" */
export type ConsumableType_Aggregate_Fields = {
  __typename?: 'consumableType_aggregate_fields';
  avg?: Maybe<ConsumableType_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<ConsumableType_Max_Fields>;
  min?: Maybe<ConsumableType_Min_Fields>;
  stddev?: Maybe<ConsumableType_Stddev_Fields>;
  stddev_pop?: Maybe<ConsumableType_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<ConsumableType_Stddev_Samp_Fields>;
  sum?: Maybe<ConsumableType_Sum_Fields>;
  var_pop?: Maybe<ConsumableType_Var_Pop_Fields>;
  var_samp?: Maybe<ConsumableType_Var_Samp_Fields>;
  variance?: Maybe<ConsumableType_Variance_Fields>;
};


/** aggregate fields of "consumableType" */
export type ConsumableType_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<ConsumableType_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type ConsumableType_Append_Input = {
  requiredFields?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type ConsumableType_Avg_Fields = {
  __typename?: 'consumableType_avg_fields';
  defaultNumberOfUnits?: Maybe<Scalars['Float']>;
  defaultPricePerUnit?: Maybe<Scalars['Float']>;
  unitStep?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "consumableType". All fields are combined with a logical 'AND'. */
export type ConsumableType_Bool_Exp = {
  _and?: InputMaybe<Array<ConsumableType_Bool_Exp>>;
  _not?: InputMaybe<ConsumableType_Bool_Exp>;
  _or?: InputMaybe<Array<ConsumableType_Bool_Exp>>;
  archived?: InputMaybe<Boolean_Comparison_Exp>;
  consumableTypeConsumableSubtypes?: InputMaybe<ConsumableTypeConsumableSubtype_Bool_Exp>;
  consumableTypeConsumableSubtypes_aggregate?: InputMaybe<ConsumableTypeConsumableSubtype_Aggregate_Bool_Exp>;
  consumables?: InputMaybe<Consumable_Bool_Exp>;
  consumables_aggregate?: InputMaybe<Consumable_Aggregate_Bool_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  defaultNumberOfUnits?: InputMaybe<Float8_Comparison_Exp>;
  defaultPricePerUnit?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  productBarcodeId?: InputMaybe<String_Comparison_Exp>;
  requiredFields?: InputMaybe<Jsonb_Comparison_Exp>;
  unitName?: InputMaybe<String_Comparison_Exp>;
  unitStep?: InputMaybe<Float8_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  workspaceId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "consumableType" */
export enum ConsumableType_Constraint {
  /** unique or primary key constraint on columns "id" */
  ConsumableTypePkey = 'consumableType_pkey',
  /** unique or primary key constraint on columns "workspaceId", "name" */
  ConsumableTypeValueWorkspaceIdKey = 'consumableType_value_workspaceId_key'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type ConsumableType_Delete_At_Path_Input = {
  requiredFields?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type ConsumableType_Delete_Elem_Input = {
  requiredFields?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type ConsumableType_Delete_Key_Input = {
  requiredFields?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "consumableType" */
export type ConsumableType_Inc_Input = {
  defaultNumberOfUnits?: InputMaybe<Scalars['float8']>;
  defaultPricePerUnit?: InputMaybe<Scalars['Int']>;
  unitStep?: InputMaybe<Scalars['float8']>;
};

/** input type for inserting data into table "consumableType" */
export type ConsumableType_Insert_Input = {
  archived?: InputMaybe<Scalars['Boolean']>;
  consumableTypeConsumableSubtypes?: InputMaybe<ConsumableTypeConsumableSubtype_Arr_Rel_Insert_Input>;
  consumables?: InputMaybe<Consumable_Arr_Rel_Insert_Input>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  defaultNumberOfUnits?: InputMaybe<Scalars['float8']>;
  defaultPricePerUnit?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  productBarcodeId?: InputMaybe<Scalars['String']>;
  requiredFields?: InputMaybe<Scalars['jsonb']>;
  unitName?: InputMaybe<Scalars['String']>;
  unitStep?: InputMaybe<Scalars['float8']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type ConsumableType_Max_Fields = {
  __typename?: 'consumableType_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  defaultNumberOfUnits?: Maybe<Scalars['float8']>;
  defaultPricePerUnit?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  productBarcodeId?: Maybe<Scalars['String']>;
  unitName?: Maybe<Scalars['String']>;
  unitStep?: Maybe<Scalars['float8']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type ConsumableType_Min_Fields = {
  __typename?: 'consumableType_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  defaultNumberOfUnits?: Maybe<Scalars['float8']>;
  defaultPricePerUnit?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  productBarcodeId?: Maybe<Scalars['String']>;
  unitName?: Maybe<Scalars['String']>;
  unitStep?: Maybe<Scalars['float8']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "consumableType" */
export type ConsumableType_Mutation_Response = {
  __typename?: 'consumableType_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ConsumableType>;
};

/** input type for inserting object relation for remote table "consumableType" */
export type ConsumableType_Obj_Rel_Insert_Input = {
  data: ConsumableType_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<ConsumableType_On_Conflict>;
};

/** on_conflict condition type for table "consumableType" */
export type ConsumableType_On_Conflict = {
  constraint: ConsumableType_Constraint;
  update_columns?: Array<ConsumableType_Update_Column>;
  where?: InputMaybe<ConsumableType_Bool_Exp>;
};

/** Ordering options when selecting data from "consumableType". */
export type ConsumableType_Order_By = {
  archived?: InputMaybe<Order_By>;
  consumableTypeConsumableSubtypes_aggregate?: InputMaybe<ConsumableTypeConsumableSubtype_Aggregate_Order_By>;
  consumables_aggregate?: InputMaybe<Consumable_Aggregate_Order_By>;
  createdAt?: InputMaybe<Order_By>;
  defaultNumberOfUnits?: InputMaybe<Order_By>;
  defaultPricePerUnit?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  productBarcodeId?: InputMaybe<Order_By>;
  requiredFields?: InputMaybe<Order_By>;
  unitName?: InputMaybe<Order_By>;
  unitStep?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: consumableType */
export type ConsumableType_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type ConsumableType_Prepend_Input = {
  requiredFields?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "consumableType" */
export enum ConsumableType_Select_Column {
  /** column name */
  Archived = 'archived',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DefaultNumberOfUnits = 'defaultNumberOfUnits',
  /** column name */
  DefaultPricePerUnit = 'defaultPricePerUnit',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  ProductBarcodeId = 'productBarcodeId',
  /** column name */
  RequiredFields = 'requiredFields',
  /** column name */
  UnitName = 'unitName',
  /** column name */
  UnitStep = 'unitStep',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WorkspaceId = 'workspaceId'
}

/** input type for updating data in table "consumableType" */
export type ConsumableType_Set_Input = {
  archived?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  defaultNumberOfUnits?: InputMaybe<Scalars['float8']>;
  defaultPricePerUnit?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  productBarcodeId?: InputMaybe<Scalars['String']>;
  requiredFields?: InputMaybe<Scalars['jsonb']>;
  unitName?: InputMaybe<Scalars['String']>;
  unitStep?: InputMaybe<Scalars['float8']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type ConsumableType_Stddev_Fields = {
  __typename?: 'consumableType_stddev_fields';
  defaultNumberOfUnits?: Maybe<Scalars['Float']>;
  defaultPricePerUnit?: Maybe<Scalars['Float']>;
  unitStep?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type ConsumableType_Stddev_Pop_Fields = {
  __typename?: 'consumableType_stddev_pop_fields';
  defaultNumberOfUnits?: Maybe<Scalars['Float']>;
  defaultPricePerUnit?: Maybe<Scalars['Float']>;
  unitStep?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type ConsumableType_Stddev_Samp_Fields = {
  __typename?: 'consumableType_stddev_samp_fields';
  defaultNumberOfUnits?: Maybe<Scalars['Float']>;
  defaultPricePerUnit?: Maybe<Scalars['Float']>;
  unitStep?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "consumableType" */
export type ConsumableType_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ConsumableType_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ConsumableType_Stream_Cursor_Value_Input = {
  archived?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  defaultNumberOfUnits?: InputMaybe<Scalars['float8']>;
  defaultPricePerUnit?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  productBarcodeId?: InputMaybe<Scalars['String']>;
  requiredFields?: InputMaybe<Scalars['jsonb']>;
  unitName?: InputMaybe<Scalars['String']>;
  unitStep?: InputMaybe<Scalars['float8']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type ConsumableType_Sum_Fields = {
  __typename?: 'consumableType_sum_fields';
  defaultNumberOfUnits?: Maybe<Scalars['float8']>;
  defaultPricePerUnit?: Maybe<Scalars['Int']>;
  unitStep?: Maybe<Scalars['float8']>;
};

/** update columns of table "consumableType" */
export enum ConsumableType_Update_Column {
  /** column name */
  Archived = 'archived',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DefaultNumberOfUnits = 'defaultNumberOfUnits',
  /** column name */
  DefaultPricePerUnit = 'defaultPricePerUnit',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  ProductBarcodeId = 'productBarcodeId',
  /** column name */
  RequiredFields = 'requiredFields',
  /** column name */
  UnitName = 'unitName',
  /** column name */
  UnitStep = 'unitStep',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WorkspaceId = 'workspaceId'
}

export type ConsumableType_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<ConsumableType_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<ConsumableType_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<ConsumableType_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<ConsumableType_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ConsumableType_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<ConsumableType_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ConsumableType_Set_Input>;
  /** filter the rows which have to be updated */
  where: ConsumableType_Bool_Exp;
};

/** aggregate var_pop on columns */
export type ConsumableType_Var_Pop_Fields = {
  __typename?: 'consumableType_var_pop_fields';
  defaultNumberOfUnits?: Maybe<Scalars['Float']>;
  defaultPricePerUnit?: Maybe<Scalars['Float']>;
  unitStep?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type ConsumableType_Var_Samp_Fields = {
  __typename?: 'consumableType_var_samp_fields';
  defaultNumberOfUnits?: Maybe<Scalars['Float']>;
  defaultPricePerUnit?: Maybe<Scalars['Float']>;
  unitStep?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type ConsumableType_Variance_Fields = {
  __typename?: 'consumableType_variance_fields';
  defaultNumberOfUnits?: Maybe<Scalars['Float']>;
  defaultPricePerUnit?: Maybe<Scalars['Float']>;
  unitStep?: Maybe<Scalars['Float']>;
};

/** aggregated selection of "consumable" */
export type Consumable_Aggregate = {
  __typename?: 'consumable_aggregate';
  aggregate?: Maybe<Consumable_Aggregate_Fields>;
  nodes: Array<Consumable>;
};

export type Consumable_Aggregate_Bool_Exp = {
  avg?: InputMaybe<Consumable_Aggregate_Bool_Exp_Avg>;
  bool_and?: InputMaybe<Consumable_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Consumable_Aggregate_Bool_Exp_Bool_Or>;
  corr?: InputMaybe<Consumable_Aggregate_Bool_Exp_Corr>;
  count?: InputMaybe<Consumable_Aggregate_Bool_Exp_Count>;
  covar_samp?: InputMaybe<Consumable_Aggregate_Bool_Exp_Covar_Samp>;
  max?: InputMaybe<Consumable_Aggregate_Bool_Exp_Max>;
  min?: InputMaybe<Consumable_Aggregate_Bool_Exp_Min>;
  stddev_samp?: InputMaybe<Consumable_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: InputMaybe<Consumable_Aggregate_Bool_Exp_Sum>;
  var_samp?: InputMaybe<Consumable_Aggregate_Bool_Exp_Var_Samp>;
};

export type Consumable_Aggregate_Bool_Exp_Avg = {
  arguments: Consumable_Select_Column_Consumable_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Consumable_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Consumable_Aggregate_Bool_Exp_Bool_And = {
  arguments: Consumable_Select_Column_Consumable_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Consumable_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Consumable_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Consumable_Select_Column_Consumable_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Consumable_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Consumable_Aggregate_Bool_Exp_Corr = {
  arguments: Consumable_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Consumable_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Consumable_Aggregate_Bool_Exp_Corr_Arguments = {
  X: Consumable_Select_Column_Consumable_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: Consumable_Select_Column_Consumable_Aggregate_Bool_Exp_Corr_Arguments_Columns;
};

export type Consumable_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Consumable_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Consumable_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type Consumable_Aggregate_Bool_Exp_Covar_Samp = {
  arguments: Consumable_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Consumable_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Consumable_Aggregate_Bool_Exp_Covar_Samp_Arguments = {
  X: Consumable_Select_Column_Consumable_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  Y: Consumable_Select_Column_Consumable_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
};

export type Consumable_Aggregate_Bool_Exp_Max = {
  arguments: Consumable_Select_Column_Consumable_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Consumable_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Consumable_Aggregate_Bool_Exp_Min = {
  arguments: Consumable_Select_Column_Consumable_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Consumable_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Consumable_Aggregate_Bool_Exp_Stddev_Samp = {
  arguments: Consumable_Select_Column_Consumable_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Consumable_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Consumable_Aggregate_Bool_Exp_Sum = {
  arguments: Consumable_Select_Column_Consumable_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Consumable_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Consumable_Aggregate_Bool_Exp_Var_Samp = {
  arguments: Consumable_Select_Column_Consumable_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Consumable_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

/** aggregate fields of "consumable" */
export type Consumable_Aggregate_Fields = {
  __typename?: 'consumable_aggregate_fields';
  avg?: Maybe<Consumable_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Consumable_Max_Fields>;
  min?: Maybe<Consumable_Min_Fields>;
  stddev?: Maybe<Consumable_Stddev_Fields>;
  stddev_pop?: Maybe<Consumable_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Consumable_Stddev_Samp_Fields>;
  sum?: Maybe<Consumable_Sum_Fields>;
  var_pop?: Maybe<Consumable_Var_Pop_Fields>;
  var_samp?: Maybe<Consumable_Var_Samp_Fields>;
  variance?: Maybe<Consumable_Variance_Fields>;
};


/** aggregate fields of "consumable" */
export type Consumable_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Consumable_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "consumable" */
export type Consumable_Aggregate_Order_By = {
  avg?: InputMaybe<Consumable_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Consumable_Max_Order_By>;
  min?: InputMaybe<Consumable_Min_Order_By>;
  stddev?: InputMaybe<Consumable_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Consumable_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Consumable_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Consumable_Sum_Order_By>;
  var_pop?: InputMaybe<Consumable_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Consumable_Var_Samp_Order_By>;
  variance?: InputMaybe<Consumable_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Consumable_Append_Input = {
  metadata?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "consumable" */
export type Consumable_Arr_Rel_Insert_Input = {
  data: Array<Consumable_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Consumable_On_Conflict>;
};

/** aggregate avg on columns */
export type Consumable_Avg_Fields = {
  __typename?: 'consumable_avg_fields';
  cost?: Maybe<Scalars['Float']>;
  daysBeforeNotification?: Maybe<Scalars['Float']>;
  originalQuantity?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  warningQuantity?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "consumable" */
export type Consumable_Avg_Order_By = {
  cost?: InputMaybe<Order_By>;
  daysBeforeNotification?: InputMaybe<Order_By>;
  originalQuantity?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  warningQuantity?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "consumable". All fields are combined with a logical 'AND'. */
export type Consumable_Bool_Exp = {
  _and?: InputMaybe<Array<Consumable_Bool_Exp>>;
  _not?: InputMaybe<Consumable_Bool_Exp>;
  _or?: InputMaybe<Array<Consumable_Bool_Exp>>;
  archived?: InputMaybe<Boolean_Comparison_Exp>;
  brand?: InputMaybe<ConsumableBrand_Bool_Exp>;
  consumableBrandId?: InputMaybe<Uuid_Comparison_Exp>;
  consumableTypeId?: InputMaybe<Uuid_Comparison_Exp>;
  cost?: InputMaybe<Int_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  daysBeforeNotification?: InputMaybe<Int_Comparison_Exp>;
  expirationDate?: InputMaybe<Date_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  isExpirable?: InputMaybe<Boolean_Comparison_Exp>;
  metadata?: InputMaybe<Jsonb_Comparison_Exp>;
  originalQuantity?: InputMaybe<Float8_Comparison_Exp>;
  purchaseOrder?: InputMaybe<PurchaseOrder_Bool_Exp>;
  purchaseOrderId?: InputMaybe<Uuid_Comparison_Exp>;
  quantity?: InputMaybe<Float8_Comparison_Exp>;
  shouldWarnQuantity?: InputMaybe<Boolean_Comparison_Exp>;
  type?: InputMaybe<ConsumableType_Bool_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  warningQuantity?: InputMaybe<Int_Comparison_Exp>;
  workspaceId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "consumable" */
export enum Consumable_Constraint {
  /** unique or primary key constraint on columns "id" */
  ConsumablePkey = 'consumable_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Consumable_Delete_At_Path_Input = {
  metadata?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Consumable_Delete_Elem_Input = {
  metadata?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Consumable_Delete_Key_Input = {
  metadata?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "consumable" */
export type Consumable_Inc_Input = {
  cost?: InputMaybe<Scalars['Int']>;
  daysBeforeNotification?: InputMaybe<Scalars['Int']>;
  originalQuantity?: InputMaybe<Scalars['float8']>;
  quantity?: InputMaybe<Scalars['float8']>;
  warningQuantity?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "consumable" */
export type Consumable_Insert_Input = {
  archived?: InputMaybe<Scalars['Boolean']>;
  brand?: InputMaybe<ConsumableBrand_Obj_Rel_Insert_Input>;
  consumableBrandId?: InputMaybe<Scalars['uuid']>;
  consumableTypeId?: InputMaybe<Scalars['uuid']>;
  cost?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  daysBeforeNotification?: InputMaybe<Scalars['Int']>;
  expirationDate?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['uuid']>;
  isExpirable?: InputMaybe<Scalars['Boolean']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  originalQuantity?: InputMaybe<Scalars['float8']>;
  purchaseOrder?: InputMaybe<PurchaseOrder_Obj_Rel_Insert_Input>;
  purchaseOrderId?: InputMaybe<Scalars['uuid']>;
  quantity?: InputMaybe<Scalars['float8']>;
  shouldWarnQuantity?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<ConsumableType_Obj_Rel_Insert_Input>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  warningQuantity?: InputMaybe<Scalars['Int']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Consumable_Max_Fields = {
  __typename?: 'consumable_max_fields';
  consumableBrandId?: Maybe<Scalars['uuid']>;
  consumableTypeId?: Maybe<Scalars['uuid']>;
  cost?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  daysBeforeNotification?: Maybe<Scalars['Int']>;
  expirationDate?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  originalQuantity?: Maybe<Scalars['float8']>;
  purchaseOrderId?: Maybe<Scalars['uuid']>;
  quantity?: Maybe<Scalars['float8']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  warningQuantity?: Maybe<Scalars['Int']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "consumable" */
export type Consumable_Max_Order_By = {
  consumableBrandId?: InputMaybe<Order_By>;
  consumableTypeId?: InputMaybe<Order_By>;
  cost?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  daysBeforeNotification?: InputMaybe<Order_By>;
  expirationDate?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  originalQuantity?: InputMaybe<Order_By>;
  purchaseOrderId?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  warningQuantity?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Consumable_Min_Fields = {
  __typename?: 'consumable_min_fields';
  consumableBrandId?: Maybe<Scalars['uuid']>;
  consumableTypeId?: Maybe<Scalars['uuid']>;
  cost?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  daysBeforeNotification?: Maybe<Scalars['Int']>;
  expirationDate?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  originalQuantity?: Maybe<Scalars['float8']>;
  purchaseOrderId?: Maybe<Scalars['uuid']>;
  quantity?: Maybe<Scalars['float8']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  warningQuantity?: Maybe<Scalars['Int']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "consumable" */
export type Consumable_Min_Order_By = {
  consumableBrandId?: InputMaybe<Order_By>;
  consumableTypeId?: InputMaybe<Order_By>;
  cost?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  daysBeforeNotification?: InputMaybe<Order_By>;
  expirationDate?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  originalQuantity?: InputMaybe<Order_By>;
  purchaseOrderId?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  warningQuantity?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "consumable" */
export type Consumable_Mutation_Response = {
  __typename?: 'consumable_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Consumable>;
};

/** input type for inserting object relation for remote table "consumable" */
export type Consumable_Obj_Rel_Insert_Input = {
  data: Consumable_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Consumable_On_Conflict>;
};

/** on_conflict condition type for table "consumable" */
export type Consumable_On_Conflict = {
  constraint: Consumable_Constraint;
  update_columns?: Array<Consumable_Update_Column>;
  where?: InputMaybe<Consumable_Bool_Exp>;
};

/** Ordering options when selecting data from "consumable". */
export type Consumable_Order_By = {
  archived?: InputMaybe<Order_By>;
  brand?: InputMaybe<ConsumableBrand_Order_By>;
  consumableBrandId?: InputMaybe<Order_By>;
  consumableTypeId?: InputMaybe<Order_By>;
  cost?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  daysBeforeNotification?: InputMaybe<Order_By>;
  expirationDate?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  isExpirable?: InputMaybe<Order_By>;
  metadata?: InputMaybe<Order_By>;
  originalQuantity?: InputMaybe<Order_By>;
  purchaseOrder?: InputMaybe<PurchaseOrder_Order_By>;
  purchaseOrderId?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  shouldWarnQuantity?: InputMaybe<Order_By>;
  type?: InputMaybe<ConsumableType_Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  warningQuantity?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: consumable */
export type Consumable_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Consumable_Prepend_Input = {
  metadata?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "consumable" */
export enum Consumable_Select_Column {
  /** column name */
  Archived = 'archived',
  /** column name */
  ConsumableBrandId = 'consumableBrandId',
  /** column name */
  ConsumableTypeId = 'consumableTypeId',
  /** column name */
  Cost = 'cost',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DaysBeforeNotification = 'daysBeforeNotification',
  /** column name */
  ExpirationDate = 'expirationDate',
  /** column name */
  Id = 'id',
  /** column name */
  IsExpirable = 'isExpirable',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  OriginalQuantity = 'originalQuantity',
  /** column name */
  PurchaseOrderId = 'purchaseOrderId',
  /** column name */
  Quantity = 'quantity',
  /** column name */
  ShouldWarnQuantity = 'shouldWarnQuantity',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WarningQuantity = 'warningQuantity',
  /** column name */
  WorkspaceId = 'workspaceId'
}

/** select "consumable_aggregate_bool_exp_avg_arguments_columns" columns of table "consumable" */
export enum Consumable_Select_Column_Consumable_Aggregate_Bool_Exp_Avg_Arguments_Columns {
  /** column name */
  OriginalQuantity = 'originalQuantity',
  /** column name */
  Quantity = 'quantity'
}

/** select "consumable_aggregate_bool_exp_bool_and_arguments_columns" columns of table "consumable" */
export enum Consumable_Select_Column_Consumable_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Archived = 'archived',
  /** column name */
  IsExpirable = 'isExpirable',
  /** column name */
  ShouldWarnQuantity = 'shouldWarnQuantity'
}

/** select "consumable_aggregate_bool_exp_bool_or_arguments_columns" columns of table "consumable" */
export enum Consumable_Select_Column_Consumable_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Archived = 'archived',
  /** column name */
  IsExpirable = 'isExpirable',
  /** column name */
  ShouldWarnQuantity = 'shouldWarnQuantity'
}

/** select "consumable_aggregate_bool_exp_corr_arguments_columns" columns of table "consumable" */
export enum Consumable_Select_Column_Consumable_Aggregate_Bool_Exp_Corr_Arguments_Columns {
  /** column name */
  OriginalQuantity = 'originalQuantity',
  /** column name */
  Quantity = 'quantity'
}

/** select "consumable_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "consumable" */
export enum Consumable_Select_Column_Consumable_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns {
  /** column name */
  OriginalQuantity = 'originalQuantity',
  /** column name */
  Quantity = 'quantity'
}

/** select "consumable_aggregate_bool_exp_max_arguments_columns" columns of table "consumable" */
export enum Consumable_Select_Column_Consumable_Aggregate_Bool_Exp_Max_Arguments_Columns {
  /** column name */
  OriginalQuantity = 'originalQuantity',
  /** column name */
  Quantity = 'quantity'
}

/** select "consumable_aggregate_bool_exp_min_arguments_columns" columns of table "consumable" */
export enum Consumable_Select_Column_Consumable_Aggregate_Bool_Exp_Min_Arguments_Columns {
  /** column name */
  OriginalQuantity = 'originalQuantity',
  /** column name */
  Quantity = 'quantity'
}

/** select "consumable_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "consumable" */
export enum Consumable_Select_Column_Consumable_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns {
  /** column name */
  OriginalQuantity = 'originalQuantity',
  /** column name */
  Quantity = 'quantity'
}

/** select "consumable_aggregate_bool_exp_sum_arguments_columns" columns of table "consumable" */
export enum Consumable_Select_Column_Consumable_Aggregate_Bool_Exp_Sum_Arguments_Columns {
  /** column name */
  OriginalQuantity = 'originalQuantity',
  /** column name */
  Quantity = 'quantity'
}

/** select "consumable_aggregate_bool_exp_var_samp_arguments_columns" columns of table "consumable" */
export enum Consumable_Select_Column_Consumable_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns {
  /** column name */
  OriginalQuantity = 'originalQuantity',
  /** column name */
  Quantity = 'quantity'
}

/** input type for updating data in table "consumable" */
export type Consumable_Set_Input = {
  archived?: InputMaybe<Scalars['Boolean']>;
  consumableBrandId?: InputMaybe<Scalars['uuid']>;
  consumableTypeId?: InputMaybe<Scalars['uuid']>;
  cost?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  daysBeforeNotification?: InputMaybe<Scalars['Int']>;
  expirationDate?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['uuid']>;
  isExpirable?: InputMaybe<Scalars['Boolean']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  originalQuantity?: InputMaybe<Scalars['float8']>;
  purchaseOrderId?: InputMaybe<Scalars['uuid']>;
  quantity?: InputMaybe<Scalars['float8']>;
  shouldWarnQuantity?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  warningQuantity?: InputMaybe<Scalars['Int']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Consumable_Stddev_Fields = {
  __typename?: 'consumable_stddev_fields';
  cost?: Maybe<Scalars['Float']>;
  daysBeforeNotification?: Maybe<Scalars['Float']>;
  originalQuantity?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  warningQuantity?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "consumable" */
export type Consumable_Stddev_Order_By = {
  cost?: InputMaybe<Order_By>;
  daysBeforeNotification?: InputMaybe<Order_By>;
  originalQuantity?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  warningQuantity?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Consumable_Stddev_Pop_Fields = {
  __typename?: 'consumable_stddev_pop_fields';
  cost?: Maybe<Scalars['Float']>;
  daysBeforeNotification?: Maybe<Scalars['Float']>;
  originalQuantity?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  warningQuantity?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "consumable" */
export type Consumable_Stddev_Pop_Order_By = {
  cost?: InputMaybe<Order_By>;
  daysBeforeNotification?: InputMaybe<Order_By>;
  originalQuantity?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  warningQuantity?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Consumable_Stddev_Samp_Fields = {
  __typename?: 'consumable_stddev_samp_fields';
  cost?: Maybe<Scalars['Float']>;
  daysBeforeNotification?: Maybe<Scalars['Float']>;
  originalQuantity?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  warningQuantity?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "consumable" */
export type Consumable_Stddev_Samp_Order_By = {
  cost?: InputMaybe<Order_By>;
  daysBeforeNotification?: InputMaybe<Order_By>;
  originalQuantity?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  warningQuantity?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "consumable" */
export type Consumable_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Consumable_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Consumable_Stream_Cursor_Value_Input = {
  archived?: InputMaybe<Scalars['Boolean']>;
  consumableBrandId?: InputMaybe<Scalars['uuid']>;
  consumableTypeId?: InputMaybe<Scalars['uuid']>;
  cost?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  daysBeforeNotification?: InputMaybe<Scalars['Int']>;
  expirationDate?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['uuid']>;
  isExpirable?: InputMaybe<Scalars['Boolean']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  originalQuantity?: InputMaybe<Scalars['float8']>;
  purchaseOrderId?: InputMaybe<Scalars['uuid']>;
  quantity?: InputMaybe<Scalars['float8']>;
  shouldWarnQuantity?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  warningQuantity?: InputMaybe<Scalars['Int']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Consumable_Sum_Fields = {
  __typename?: 'consumable_sum_fields';
  cost?: Maybe<Scalars['Int']>;
  daysBeforeNotification?: Maybe<Scalars['Int']>;
  originalQuantity?: Maybe<Scalars['float8']>;
  quantity?: Maybe<Scalars['float8']>;
  warningQuantity?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "consumable" */
export type Consumable_Sum_Order_By = {
  cost?: InputMaybe<Order_By>;
  daysBeforeNotification?: InputMaybe<Order_By>;
  originalQuantity?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  warningQuantity?: InputMaybe<Order_By>;
};

/** update columns of table "consumable" */
export enum Consumable_Update_Column {
  /** column name */
  Archived = 'archived',
  /** column name */
  ConsumableBrandId = 'consumableBrandId',
  /** column name */
  ConsumableTypeId = 'consumableTypeId',
  /** column name */
  Cost = 'cost',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DaysBeforeNotification = 'daysBeforeNotification',
  /** column name */
  ExpirationDate = 'expirationDate',
  /** column name */
  Id = 'id',
  /** column name */
  IsExpirable = 'isExpirable',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  OriginalQuantity = 'originalQuantity',
  /** column name */
  PurchaseOrderId = 'purchaseOrderId',
  /** column name */
  Quantity = 'quantity',
  /** column name */
  ShouldWarnQuantity = 'shouldWarnQuantity',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WarningQuantity = 'warningQuantity',
  /** column name */
  WorkspaceId = 'workspaceId'
}

export type Consumable_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Consumable_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Consumable_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Consumable_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Consumable_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Consumable_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Consumable_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Consumable_Set_Input>;
  /** filter the rows which have to be updated */
  where: Consumable_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Consumable_Var_Pop_Fields = {
  __typename?: 'consumable_var_pop_fields';
  cost?: Maybe<Scalars['Float']>;
  daysBeforeNotification?: Maybe<Scalars['Float']>;
  originalQuantity?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  warningQuantity?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "consumable" */
export type Consumable_Var_Pop_Order_By = {
  cost?: InputMaybe<Order_By>;
  daysBeforeNotification?: InputMaybe<Order_By>;
  originalQuantity?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  warningQuantity?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Consumable_Var_Samp_Fields = {
  __typename?: 'consumable_var_samp_fields';
  cost?: Maybe<Scalars['Float']>;
  daysBeforeNotification?: Maybe<Scalars['Float']>;
  originalQuantity?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  warningQuantity?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "consumable" */
export type Consumable_Var_Samp_Order_By = {
  cost?: InputMaybe<Order_By>;
  daysBeforeNotification?: InputMaybe<Order_By>;
  originalQuantity?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  warningQuantity?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Consumable_Variance_Fields = {
  __typename?: 'consumable_variance_fields';
  cost?: Maybe<Scalars['Float']>;
  daysBeforeNotification?: Maybe<Scalars['Float']>;
  originalQuantity?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  warningQuantity?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "consumable" */
export type Consumable_Variance_Order_By = {
  cost?: InputMaybe<Order_By>;
  daysBeforeNotification?: InputMaybe<Order_By>;
  originalQuantity?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  warningQuantity?: InputMaybe<Order_By>;
};

/** columns and relationships of "coupon" */
export type Coupon = {
  __typename?: 'coupon';
  amount: Scalars['float8'];
  /** An object relationship */
  configuration?: Maybe<CouponConfiguration>;
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  isActive: Scalars['Boolean'];
  isArchived?: Maybe<Scalars['Boolean']>;
  isSystemGenerated: Scalars['Boolean'];
  liabilityValue?: Maybe<Scalars['Int']>;
  /** An array relationship */
  locationCoupons: Array<LocationCoupon>;
  /** An aggregate relationship */
  locationCoupons_aggregate: LocationCoupon_Aggregate;
  name: Scalars['String'];
  /** An object relationship */
  package?: Maybe<Package>;
  packageId?: Maybe<Scalars['uuid']>;
  /** An array relationship */
  patientWalletCoupons: Array<PatientWalletCoupon>;
  /** An aggregate relationship */
  patientWalletCoupons_aggregate: PatientWalletCoupon_Aggregate;
  /** An object relationship */
  promotion?: Maybe<Promotion>;
  promotionId?: Maybe<Scalars['uuid']>;
  rule: Scalars['String'];
  scope: CouponScope_Enum;
  /** An array relationship */
  serviceCoupons: Array<ServiceCoupon>;
  /** An aggregate relationship */
  serviceCoupons_aggregate: ServiceCoupon_Aggregate;
  type: CouponType_Enum;
  updatedAt: Scalars['timestamptz'];
  validFrom: Scalars['date'];
  validUntil: Scalars['date'];
  workspaceId: Scalars['uuid'];
};


/** columns and relationships of "coupon" */
export type CouponLocationCouponsArgs = {
  distinct_on?: InputMaybe<Array<LocationCoupon_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<LocationCoupon_Order_By>>;
  where?: InputMaybe<LocationCoupon_Bool_Exp>;
};


/** columns and relationships of "coupon" */
export type CouponLocationCoupons_AggregateArgs = {
  distinct_on?: InputMaybe<Array<LocationCoupon_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<LocationCoupon_Order_By>>;
  where?: InputMaybe<LocationCoupon_Bool_Exp>;
};


/** columns and relationships of "coupon" */
export type CouponPatientWalletCouponsArgs = {
  distinct_on?: InputMaybe<Array<PatientWalletCoupon_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PatientWalletCoupon_Order_By>>;
  where?: InputMaybe<PatientWalletCoupon_Bool_Exp>;
};


/** columns and relationships of "coupon" */
export type CouponPatientWalletCoupons_AggregateArgs = {
  distinct_on?: InputMaybe<Array<PatientWalletCoupon_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PatientWalletCoupon_Order_By>>;
  where?: InputMaybe<PatientWalletCoupon_Bool_Exp>;
};


/** columns and relationships of "coupon" */
export type CouponServiceCouponsArgs = {
  distinct_on?: InputMaybe<Array<ServiceCoupon_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ServiceCoupon_Order_By>>;
  where?: InputMaybe<ServiceCoupon_Bool_Exp>;
};


/** columns and relationships of "coupon" */
export type CouponServiceCoupons_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ServiceCoupon_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ServiceCoupon_Order_By>>;
  where?: InputMaybe<ServiceCoupon_Bool_Exp>;
};

/** columns and relationships of "couponConfiguration" */
export type CouponConfiguration = {
  __typename?: 'couponConfiguration';
  /** An object relationship */
  coupon: Coupon;
  couponId: Scalars['uuid'];
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  maxApplication?: Maybe<Scalars['Int']>;
  updatedAt: Scalars['timestamptz'];
  /** An object relationship */
  workspace: Workspace;
  workspaceId: Scalars['uuid'];
};

/** aggregated selection of "couponConfiguration" */
export type CouponConfiguration_Aggregate = {
  __typename?: 'couponConfiguration_aggregate';
  aggregate?: Maybe<CouponConfiguration_Aggregate_Fields>;
  nodes: Array<CouponConfiguration>;
};

/** aggregate fields of "couponConfiguration" */
export type CouponConfiguration_Aggregate_Fields = {
  __typename?: 'couponConfiguration_aggregate_fields';
  avg?: Maybe<CouponConfiguration_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<CouponConfiguration_Max_Fields>;
  min?: Maybe<CouponConfiguration_Min_Fields>;
  stddev?: Maybe<CouponConfiguration_Stddev_Fields>;
  stddev_pop?: Maybe<CouponConfiguration_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<CouponConfiguration_Stddev_Samp_Fields>;
  sum?: Maybe<CouponConfiguration_Sum_Fields>;
  var_pop?: Maybe<CouponConfiguration_Var_Pop_Fields>;
  var_samp?: Maybe<CouponConfiguration_Var_Samp_Fields>;
  variance?: Maybe<CouponConfiguration_Variance_Fields>;
};


/** aggregate fields of "couponConfiguration" */
export type CouponConfiguration_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<CouponConfiguration_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type CouponConfiguration_Avg_Fields = {
  __typename?: 'couponConfiguration_avg_fields';
  maxApplication?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "couponConfiguration". All fields are combined with a logical 'AND'. */
export type CouponConfiguration_Bool_Exp = {
  _and?: InputMaybe<Array<CouponConfiguration_Bool_Exp>>;
  _not?: InputMaybe<CouponConfiguration_Bool_Exp>;
  _or?: InputMaybe<Array<CouponConfiguration_Bool_Exp>>;
  coupon?: InputMaybe<Coupon_Bool_Exp>;
  couponId?: InputMaybe<Uuid_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  maxApplication?: InputMaybe<Int_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  workspace?: InputMaybe<Workspace_Bool_Exp>;
  workspaceId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "couponConfiguration" */
export enum CouponConfiguration_Constraint {
  /** unique or primary key constraint on columns "couponId" */
  CouponConfigurationCouponIdKey = 'couponConfiguration_couponId_key',
  /** unique or primary key constraint on columns "id" */
  CouponConfigurationPkey = 'couponConfiguration_pkey'
}

/** input type for incrementing numeric columns in table "couponConfiguration" */
export type CouponConfiguration_Inc_Input = {
  maxApplication?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "couponConfiguration" */
export type CouponConfiguration_Insert_Input = {
  coupon?: InputMaybe<Coupon_Obj_Rel_Insert_Input>;
  couponId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  maxApplication?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspace?: InputMaybe<Workspace_Obj_Rel_Insert_Input>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type CouponConfiguration_Max_Fields = {
  __typename?: 'couponConfiguration_max_fields';
  couponId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  maxApplication?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type CouponConfiguration_Min_Fields = {
  __typename?: 'couponConfiguration_min_fields';
  couponId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  maxApplication?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "couponConfiguration" */
export type CouponConfiguration_Mutation_Response = {
  __typename?: 'couponConfiguration_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CouponConfiguration>;
};

/** input type for inserting object relation for remote table "couponConfiguration" */
export type CouponConfiguration_Obj_Rel_Insert_Input = {
  data: CouponConfiguration_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<CouponConfiguration_On_Conflict>;
};

/** on_conflict condition type for table "couponConfiguration" */
export type CouponConfiguration_On_Conflict = {
  constraint: CouponConfiguration_Constraint;
  update_columns?: Array<CouponConfiguration_Update_Column>;
  where?: InputMaybe<CouponConfiguration_Bool_Exp>;
};

/** Ordering options when selecting data from "couponConfiguration". */
export type CouponConfiguration_Order_By = {
  coupon?: InputMaybe<Coupon_Order_By>;
  couponId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  maxApplication?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  workspace?: InputMaybe<Workspace_Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: couponConfiguration */
export type CouponConfiguration_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "couponConfiguration" */
export enum CouponConfiguration_Select_Column {
  /** column name */
  CouponId = 'couponId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  MaxApplication = 'maxApplication',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WorkspaceId = 'workspaceId'
}

/** input type for updating data in table "couponConfiguration" */
export type CouponConfiguration_Set_Input = {
  couponId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  maxApplication?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type CouponConfiguration_Stddev_Fields = {
  __typename?: 'couponConfiguration_stddev_fields';
  maxApplication?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type CouponConfiguration_Stddev_Pop_Fields = {
  __typename?: 'couponConfiguration_stddev_pop_fields';
  maxApplication?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type CouponConfiguration_Stddev_Samp_Fields = {
  __typename?: 'couponConfiguration_stddev_samp_fields';
  maxApplication?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "couponConfiguration" */
export type CouponConfiguration_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: CouponConfiguration_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type CouponConfiguration_Stream_Cursor_Value_Input = {
  couponId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  maxApplication?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type CouponConfiguration_Sum_Fields = {
  __typename?: 'couponConfiguration_sum_fields';
  maxApplication?: Maybe<Scalars['Int']>;
};

/** update columns of table "couponConfiguration" */
export enum CouponConfiguration_Update_Column {
  /** column name */
  CouponId = 'couponId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  MaxApplication = 'maxApplication',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WorkspaceId = 'workspaceId'
}

export type CouponConfiguration_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<CouponConfiguration_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CouponConfiguration_Set_Input>;
  /** filter the rows which have to be updated */
  where: CouponConfiguration_Bool_Exp;
};

/** aggregate var_pop on columns */
export type CouponConfiguration_Var_Pop_Fields = {
  __typename?: 'couponConfiguration_var_pop_fields';
  maxApplication?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type CouponConfiguration_Var_Samp_Fields = {
  __typename?: 'couponConfiguration_var_samp_fields';
  maxApplication?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type CouponConfiguration_Variance_Fields = {
  __typename?: 'couponConfiguration_variance_fields';
  maxApplication?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "couponScope" */
export type CouponScope = {
  __typename?: 'couponScope';
  description: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "couponScope" */
export type CouponScope_Aggregate = {
  __typename?: 'couponScope_aggregate';
  aggregate?: Maybe<CouponScope_Aggregate_Fields>;
  nodes: Array<CouponScope>;
};

/** aggregate fields of "couponScope" */
export type CouponScope_Aggregate_Fields = {
  __typename?: 'couponScope_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<CouponScope_Max_Fields>;
  min?: Maybe<CouponScope_Min_Fields>;
};


/** aggregate fields of "couponScope" */
export type CouponScope_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<CouponScope_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "couponScope". All fields are combined with a logical 'AND'. */
export type CouponScope_Bool_Exp = {
  _and?: InputMaybe<Array<CouponScope_Bool_Exp>>;
  _not?: InputMaybe<CouponScope_Bool_Exp>;
  _or?: InputMaybe<Array<CouponScope_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "couponScope" */
export enum CouponScope_Constraint {
  /** unique or primary key constraint on columns "value" */
  CouponScopePkey = 'couponScope_pkey'
}

export enum CouponScope_Enum {
  /** Invoice Level */
  InvoiceLevel = 'INVOICE_LEVEL',
  /** Service Level */
  ServiceLevel = 'SERVICE_LEVEL'
}

/** Boolean expression to compare columns of type "couponScope_enum". All fields are combined with logical 'AND'. */
export type CouponScope_Enum_Comparison_Exp = {
  _eq?: InputMaybe<CouponScope_Enum>;
  _in?: InputMaybe<Array<CouponScope_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<CouponScope_Enum>;
  _nin?: InputMaybe<Array<CouponScope_Enum>>;
};

/** input type for inserting data into table "couponScope" */
export type CouponScope_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type CouponScope_Max_Fields = {
  __typename?: 'couponScope_max_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type CouponScope_Min_Fields = {
  __typename?: 'couponScope_min_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "couponScope" */
export type CouponScope_Mutation_Response = {
  __typename?: 'couponScope_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CouponScope>;
};

/** on_conflict condition type for table "couponScope" */
export type CouponScope_On_Conflict = {
  constraint: CouponScope_Constraint;
  update_columns?: Array<CouponScope_Update_Column>;
  where?: InputMaybe<CouponScope_Bool_Exp>;
};

/** Ordering options when selecting data from "couponScope". */
export type CouponScope_Order_By = {
  description?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: couponScope */
export type CouponScope_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "couponScope" */
export enum CouponScope_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "couponScope" */
export type CouponScope_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "couponScope" */
export type CouponScope_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: CouponScope_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type CouponScope_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "couponScope" */
export enum CouponScope_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

export type CouponScope_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CouponScope_Set_Input>;
  /** filter the rows which have to be updated */
  where: CouponScope_Bool_Exp;
};

/** columns and relationships of "couponType" */
export type CouponType = {
  __typename?: 'couponType';
  description: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "couponType" */
export type CouponType_Aggregate = {
  __typename?: 'couponType_aggregate';
  aggregate?: Maybe<CouponType_Aggregate_Fields>;
  nodes: Array<CouponType>;
};

/** aggregate fields of "couponType" */
export type CouponType_Aggregate_Fields = {
  __typename?: 'couponType_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<CouponType_Max_Fields>;
  min?: Maybe<CouponType_Min_Fields>;
};


/** aggregate fields of "couponType" */
export type CouponType_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<CouponType_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "couponType". All fields are combined with a logical 'AND'. */
export type CouponType_Bool_Exp = {
  _and?: InputMaybe<Array<CouponType_Bool_Exp>>;
  _not?: InputMaybe<CouponType_Bool_Exp>;
  _or?: InputMaybe<Array<CouponType_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "couponType" */
export enum CouponType_Constraint {
  /** unique or primary key constraint on columns "value" */
  CouponTypePkey = 'couponType_pkey'
}

export enum CouponType_Enum {
  /** Fixed */
  Fixed = 'FIXED',
  /** Percentage */
  Percentage = 'PERCENTAGE'
}

/** Boolean expression to compare columns of type "couponType_enum". All fields are combined with logical 'AND'. */
export type CouponType_Enum_Comparison_Exp = {
  _eq?: InputMaybe<CouponType_Enum>;
  _in?: InputMaybe<Array<CouponType_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<CouponType_Enum>;
  _nin?: InputMaybe<Array<CouponType_Enum>>;
};

/** input type for inserting data into table "couponType" */
export type CouponType_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type CouponType_Max_Fields = {
  __typename?: 'couponType_max_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type CouponType_Min_Fields = {
  __typename?: 'couponType_min_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "couponType" */
export type CouponType_Mutation_Response = {
  __typename?: 'couponType_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CouponType>;
};

/** on_conflict condition type for table "couponType" */
export type CouponType_On_Conflict = {
  constraint: CouponType_Constraint;
  update_columns?: Array<CouponType_Update_Column>;
  where?: InputMaybe<CouponType_Bool_Exp>;
};

/** Ordering options when selecting data from "couponType". */
export type CouponType_Order_By = {
  description?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: couponType */
export type CouponType_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "couponType" */
export enum CouponType_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "couponType" */
export type CouponType_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "couponType" */
export type CouponType_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: CouponType_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type CouponType_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "couponType" */
export enum CouponType_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

export type CouponType_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CouponType_Set_Input>;
  /** filter the rows which have to be updated */
  where: CouponType_Bool_Exp;
};

/** aggregated selection of "coupon" */
export type Coupon_Aggregate = {
  __typename?: 'coupon_aggregate';
  aggregate?: Maybe<Coupon_Aggregate_Fields>;
  nodes: Array<Coupon>;
};

/** aggregate fields of "coupon" */
export type Coupon_Aggregate_Fields = {
  __typename?: 'coupon_aggregate_fields';
  avg?: Maybe<Coupon_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Coupon_Max_Fields>;
  min?: Maybe<Coupon_Min_Fields>;
  stddev?: Maybe<Coupon_Stddev_Fields>;
  stddev_pop?: Maybe<Coupon_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Coupon_Stddev_Samp_Fields>;
  sum?: Maybe<Coupon_Sum_Fields>;
  var_pop?: Maybe<Coupon_Var_Pop_Fields>;
  var_samp?: Maybe<Coupon_Var_Samp_Fields>;
  variance?: Maybe<Coupon_Variance_Fields>;
};


/** aggregate fields of "coupon" */
export type Coupon_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Coupon_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Coupon_Avg_Fields = {
  __typename?: 'coupon_avg_fields';
  amount?: Maybe<Scalars['Float']>;
  liabilityValue?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "coupon". All fields are combined with a logical 'AND'. */
export type Coupon_Bool_Exp = {
  _and?: InputMaybe<Array<Coupon_Bool_Exp>>;
  _not?: InputMaybe<Coupon_Bool_Exp>;
  _or?: InputMaybe<Array<Coupon_Bool_Exp>>;
  amount?: InputMaybe<Float8_Comparison_Exp>;
  configuration?: InputMaybe<CouponConfiguration_Bool_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  isActive?: InputMaybe<Boolean_Comparison_Exp>;
  isArchived?: InputMaybe<Boolean_Comparison_Exp>;
  isSystemGenerated?: InputMaybe<Boolean_Comparison_Exp>;
  liabilityValue?: InputMaybe<Int_Comparison_Exp>;
  locationCoupons?: InputMaybe<LocationCoupon_Bool_Exp>;
  locationCoupons_aggregate?: InputMaybe<LocationCoupon_Aggregate_Bool_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  package?: InputMaybe<Package_Bool_Exp>;
  packageId?: InputMaybe<Uuid_Comparison_Exp>;
  patientWalletCoupons?: InputMaybe<PatientWalletCoupon_Bool_Exp>;
  patientWalletCoupons_aggregate?: InputMaybe<PatientWalletCoupon_Aggregate_Bool_Exp>;
  promotion?: InputMaybe<Promotion_Bool_Exp>;
  promotionId?: InputMaybe<Uuid_Comparison_Exp>;
  rule?: InputMaybe<String_Comparison_Exp>;
  scope?: InputMaybe<CouponScope_Enum_Comparison_Exp>;
  serviceCoupons?: InputMaybe<ServiceCoupon_Bool_Exp>;
  serviceCoupons_aggregate?: InputMaybe<ServiceCoupon_Aggregate_Bool_Exp>;
  type?: InputMaybe<CouponType_Enum_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  validFrom?: InputMaybe<Date_Comparison_Exp>;
  validUntil?: InputMaybe<Date_Comparison_Exp>;
  workspaceId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "coupon" */
export enum Coupon_Constraint {
  /** unique or primary key constraint on columns "packageId" */
  CouponPackageIdKey = 'coupon_packageId_key',
  /** unique or primary key constraint on columns "id" */
  CouponPkey = 'coupon_pkey'
}

/** input type for incrementing numeric columns in table "coupon" */
export type Coupon_Inc_Input = {
  amount?: InputMaybe<Scalars['float8']>;
  liabilityValue?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "coupon" */
export type Coupon_Insert_Input = {
  amount?: InputMaybe<Scalars['float8']>;
  configuration?: InputMaybe<CouponConfiguration_Obj_Rel_Insert_Input>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  isArchived?: InputMaybe<Scalars['Boolean']>;
  isSystemGenerated?: InputMaybe<Scalars['Boolean']>;
  liabilityValue?: InputMaybe<Scalars['Int']>;
  locationCoupons?: InputMaybe<LocationCoupon_Arr_Rel_Insert_Input>;
  name?: InputMaybe<Scalars['String']>;
  package?: InputMaybe<Package_Obj_Rel_Insert_Input>;
  packageId?: InputMaybe<Scalars['uuid']>;
  patientWalletCoupons?: InputMaybe<PatientWalletCoupon_Arr_Rel_Insert_Input>;
  promotion?: InputMaybe<Promotion_Obj_Rel_Insert_Input>;
  promotionId?: InputMaybe<Scalars['uuid']>;
  rule?: InputMaybe<Scalars['String']>;
  scope?: InputMaybe<CouponScope_Enum>;
  serviceCoupons?: InputMaybe<ServiceCoupon_Arr_Rel_Insert_Input>;
  type?: InputMaybe<CouponType_Enum>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  validFrom?: InputMaybe<Scalars['date']>;
  validUntil?: InputMaybe<Scalars['date']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Coupon_Max_Fields = {
  __typename?: 'coupon_max_fields';
  amount?: Maybe<Scalars['float8']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  liabilityValue?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  packageId?: Maybe<Scalars['uuid']>;
  promotionId?: Maybe<Scalars['uuid']>;
  rule?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  validFrom?: Maybe<Scalars['date']>;
  validUntil?: Maybe<Scalars['date']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Coupon_Min_Fields = {
  __typename?: 'coupon_min_fields';
  amount?: Maybe<Scalars['float8']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  liabilityValue?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  packageId?: Maybe<Scalars['uuid']>;
  promotionId?: Maybe<Scalars['uuid']>;
  rule?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  validFrom?: Maybe<Scalars['date']>;
  validUntil?: Maybe<Scalars['date']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "coupon" */
export type Coupon_Mutation_Response = {
  __typename?: 'coupon_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Coupon>;
};

/** input type for inserting object relation for remote table "coupon" */
export type Coupon_Obj_Rel_Insert_Input = {
  data: Coupon_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Coupon_On_Conflict>;
};

/** on_conflict condition type for table "coupon" */
export type Coupon_On_Conflict = {
  constraint: Coupon_Constraint;
  update_columns?: Array<Coupon_Update_Column>;
  where?: InputMaybe<Coupon_Bool_Exp>;
};

/** Ordering options when selecting data from "coupon". */
export type Coupon_Order_By = {
  amount?: InputMaybe<Order_By>;
  configuration?: InputMaybe<CouponConfiguration_Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  isActive?: InputMaybe<Order_By>;
  isArchived?: InputMaybe<Order_By>;
  isSystemGenerated?: InputMaybe<Order_By>;
  liabilityValue?: InputMaybe<Order_By>;
  locationCoupons_aggregate?: InputMaybe<LocationCoupon_Aggregate_Order_By>;
  name?: InputMaybe<Order_By>;
  package?: InputMaybe<Package_Order_By>;
  packageId?: InputMaybe<Order_By>;
  patientWalletCoupons_aggregate?: InputMaybe<PatientWalletCoupon_Aggregate_Order_By>;
  promotion?: InputMaybe<Promotion_Order_By>;
  promotionId?: InputMaybe<Order_By>;
  rule?: InputMaybe<Order_By>;
  scope?: InputMaybe<Order_By>;
  serviceCoupons_aggregate?: InputMaybe<ServiceCoupon_Aggregate_Order_By>;
  type?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  validFrom?: InputMaybe<Order_By>;
  validUntil?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: coupon */
export type Coupon_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "coupon" */
export enum Coupon_Select_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'isActive',
  /** column name */
  IsArchived = 'isArchived',
  /** column name */
  IsSystemGenerated = 'isSystemGenerated',
  /** column name */
  LiabilityValue = 'liabilityValue',
  /** column name */
  Name = 'name',
  /** column name */
  PackageId = 'packageId',
  /** column name */
  PromotionId = 'promotionId',
  /** column name */
  Rule = 'rule',
  /** column name */
  Scope = 'scope',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  ValidFrom = 'validFrom',
  /** column name */
  ValidUntil = 'validUntil',
  /** column name */
  WorkspaceId = 'workspaceId'
}

/** input type for updating data in table "coupon" */
export type Coupon_Set_Input = {
  amount?: InputMaybe<Scalars['float8']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  isArchived?: InputMaybe<Scalars['Boolean']>;
  isSystemGenerated?: InputMaybe<Scalars['Boolean']>;
  liabilityValue?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  packageId?: InputMaybe<Scalars['uuid']>;
  promotionId?: InputMaybe<Scalars['uuid']>;
  rule?: InputMaybe<Scalars['String']>;
  scope?: InputMaybe<CouponScope_Enum>;
  type?: InputMaybe<CouponType_Enum>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  validFrom?: InputMaybe<Scalars['date']>;
  validUntil?: InputMaybe<Scalars['date']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Coupon_Stddev_Fields = {
  __typename?: 'coupon_stddev_fields';
  amount?: Maybe<Scalars['Float']>;
  liabilityValue?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Coupon_Stddev_Pop_Fields = {
  __typename?: 'coupon_stddev_pop_fields';
  amount?: Maybe<Scalars['Float']>;
  liabilityValue?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Coupon_Stddev_Samp_Fields = {
  __typename?: 'coupon_stddev_samp_fields';
  amount?: Maybe<Scalars['Float']>;
  liabilityValue?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "coupon" */
export type Coupon_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Coupon_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Coupon_Stream_Cursor_Value_Input = {
  amount?: InputMaybe<Scalars['float8']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  isArchived?: InputMaybe<Scalars['Boolean']>;
  isSystemGenerated?: InputMaybe<Scalars['Boolean']>;
  liabilityValue?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  packageId?: InputMaybe<Scalars['uuid']>;
  promotionId?: InputMaybe<Scalars['uuid']>;
  rule?: InputMaybe<Scalars['String']>;
  scope?: InputMaybe<CouponScope_Enum>;
  type?: InputMaybe<CouponType_Enum>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  validFrom?: InputMaybe<Scalars['date']>;
  validUntil?: InputMaybe<Scalars['date']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Coupon_Sum_Fields = {
  __typename?: 'coupon_sum_fields';
  amount?: Maybe<Scalars['float8']>;
  liabilityValue?: Maybe<Scalars['Int']>;
};

/** update columns of table "coupon" */
export enum Coupon_Update_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'isActive',
  /** column name */
  IsArchived = 'isArchived',
  /** column name */
  IsSystemGenerated = 'isSystemGenerated',
  /** column name */
  LiabilityValue = 'liabilityValue',
  /** column name */
  Name = 'name',
  /** column name */
  PackageId = 'packageId',
  /** column name */
  PromotionId = 'promotionId',
  /** column name */
  Rule = 'rule',
  /** column name */
  Scope = 'scope',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  ValidFrom = 'validFrom',
  /** column name */
  ValidUntil = 'validUntil',
  /** column name */
  WorkspaceId = 'workspaceId'
}

export type Coupon_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Coupon_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Coupon_Set_Input>;
  /** filter the rows which have to be updated */
  where: Coupon_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Coupon_Var_Pop_Fields = {
  __typename?: 'coupon_var_pop_fields';
  amount?: Maybe<Scalars['Float']>;
  liabilityValue?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Coupon_Var_Samp_Fields = {
  __typename?: 'coupon_var_samp_fields';
  amount?: Maybe<Scalars['Float']>;
  liabilityValue?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Coupon_Variance_Fields = {
  __typename?: 'coupon_variance_fields';
  amount?: Maybe<Scalars['Float']>;
  liabilityValue?: Maybe<Scalars['Float']>;
};

/** ordering argument of a cursor */
export enum Cursor_Ordering {
  /** ascending ordering of the cursor */
  Asc = 'ASC',
  /** descending ordering of the cursor */
  Desc = 'DESC'
}

/** columns and relationships of "customForm" */
export type CustomForm = {
  __typename?: 'customForm';
  allFieldsRequired: Scalars['Boolean'];
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  isRequired: Scalars['Boolean'];
  language?: Maybe<Scalars['String']>;
  recurrence?: Maybe<Scalars['String']>;
  /** An array relationship */
  rows: Array<CustomFormRow>;
  /** An aggregate relationship */
  rows_aggregate: CustomFormRow_Aggregate;
  /** An array relationship */
  serviceCustomForms: Array<ServiceCustomForm>;
  /** An aggregate relationship */
  serviceCustomForms_aggregate: ServiceCustomForm_Aggregate;
  surveyJSJSON?: Maybe<Scalars['jsonb']>;
  title: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  userId?: Maybe<Scalars['uuid']>;
  workspaceId: Scalars['uuid'];
};


/** columns and relationships of "customForm" */
export type CustomFormRowsArgs = {
  distinct_on?: InputMaybe<Array<CustomFormRow_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CustomFormRow_Order_By>>;
  where?: InputMaybe<CustomFormRow_Bool_Exp>;
};


/** columns and relationships of "customForm" */
export type CustomFormRows_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CustomFormRow_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CustomFormRow_Order_By>>;
  where?: InputMaybe<CustomFormRow_Bool_Exp>;
};


/** columns and relationships of "customForm" */
export type CustomFormServiceCustomFormsArgs = {
  distinct_on?: InputMaybe<Array<ServiceCustomForm_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ServiceCustomForm_Order_By>>;
  where?: InputMaybe<ServiceCustomForm_Bool_Exp>;
};


/** columns and relationships of "customForm" */
export type CustomFormServiceCustomForms_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ServiceCustomForm_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ServiceCustomForm_Order_By>>;
  where?: InputMaybe<ServiceCustomForm_Bool_Exp>;
};


/** columns and relationships of "customForm" */
export type CustomFormSurveyJsjsonArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "customFormField" */
export type CustomFormField = {
  __typename?: 'customFormField';
  apiFieldKey: Scalars['String'];
  attribute?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  category?: Maybe<CustomFormFieldCategory_Enum>;
  createdAt: Scalars['timestamptz'];
  customFormRowId: Scalars['uuid'];
  id: Scalars['uuid'];
  index: Scalars['Int'];
  label: Scalars['String'];
  /** An array relationship */
  options: Array<CustomFormOption>;
  /** An aggregate relationship */
  options_aggregate: CustomFormOption_Aggregate;
  /** An object relationship */
  photo?: Maybe<Photo>;
  photoId?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  row: CustomFormRow;
  table?: Maybe<Scalars['String']>;
  type: CustomFormFieldType_Enum;
  updatedAt: Scalars['timestamptz'];
  validation?: Maybe<Scalars['jsonb']>;
};


/** columns and relationships of "customFormField" */
export type CustomFormFieldOptionsArgs = {
  distinct_on?: InputMaybe<Array<CustomFormOption_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CustomFormOption_Order_By>>;
  where?: InputMaybe<CustomFormOption_Bool_Exp>;
};


/** columns and relationships of "customFormField" */
export type CustomFormFieldOptions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CustomFormOption_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CustomFormOption_Order_By>>;
  where?: InputMaybe<CustomFormOption_Bool_Exp>;
};


/** columns and relationships of "customFormField" */
export type CustomFormFieldValidationArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "customFormFieldCategory" */
export type CustomFormFieldCategory = {
  __typename?: 'customFormFieldCategory';
  description: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "customFormFieldCategory" */
export type CustomFormFieldCategory_Aggregate = {
  __typename?: 'customFormFieldCategory_aggregate';
  aggregate?: Maybe<CustomFormFieldCategory_Aggregate_Fields>;
  nodes: Array<CustomFormFieldCategory>;
};

/** aggregate fields of "customFormFieldCategory" */
export type CustomFormFieldCategory_Aggregate_Fields = {
  __typename?: 'customFormFieldCategory_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<CustomFormFieldCategory_Max_Fields>;
  min?: Maybe<CustomFormFieldCategory_Min_Fields>;
};


/** aggregate fields of "customFormFieldCategory" */
export type CustomFormFieldCategory_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<CustomFormFieldCategory_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "customFormFieldCategory". All fields are combined with a logical 'AND'. */
export type CustomFormFieldCategory_Bool_Exp = {
  _and?: InputMaybe<Array<CustomFormFieldCategory_Bool_Exp>>;
  _not?: InputMaybe<CustomFormFieldCategory_Bool_Exp>;
  _or?: InputMaybe<Array<CustomFormFieldCategory_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "customFormFieldCategory" */
export enum CustomFormFieldCategory_Constraint {
  /** unique or primary key constraint on columns "value" */
  CustomFormFieldCategoryPkey = 'customFormFieldCategory_pkey'
}

export enum CustomFormFieldCategory_Enum {
  /** Allergies */
  Allergies = 'ALLERGIES',
  /** Emergency Contact */
  EmergencyContact = 'EMERGENCY_CONTACT',
  /** Free Response */
  FreeResponse = 'FREE_RESPONSE',
  /** History */
  History = 'HISTORY',
  /** Medication */
  Medication = 'MEDICATION',
  /** Patient Profile */
  PatientProfile = 'PATIENT_PROFILE',
  /** Pregnancy */
  Pregnancy = 'PREGNANCY',
  /** Procedures */
  Procedures = 'PROCEDURES',
  /** Social History */
  SocialHistory = 'SOCIAL_HISTORY',
  /** Symptoms */
  Symptoms = 'SYMPTOMS'
}

/** Boolean expression to compare columns of type "customFormFieldCategory_enum". All fields are combined with logical 'AND'. */
export type CustomFormFieldCategory_Enum_Comparison_Exp = {
  _eq?: InputMaybe<CustomFormFieldCategory_Enum>;
  _in?: InputMaybe<Array<CustomFormFieldCategory_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<CustomFormFieldCategory_Enum>;
  _nin?: InputMaybe<Array<CustomFormFieldCategory_Enum>>;
};

/** input type for inserting data into table "customFormFieldCategory" */
export type CustomFormFieldCategory_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type CustomFormFieldCategory_Max_Fields = {
  __typename?: 'customFormFieldCategory_max_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type CustomFormFieldCategory_Min_Fields = {
  __typename?: 'customFormFieldCategory_min_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "customFormFieldCategory" */
export type CustomFormFieldCategory_Mutation_Response = {
  __typename?: 'customFormFieldCategory_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CustomFormFieldCategory>;
};

/** input type for inserting object relation for remote table "customFormFieldCategory" */
export type CustomFormFieldCategory_Obj_Rel_Insert_Input = {
  data: CustomFormFieldCategory_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<CustomFormFieldCategory_On_Conflict>;
};

/** on_conflict condition type for table "customFormFieldCategory" */
export type CustomFormFieldCategory_On_Conflict = {
  constraint: CustomFormFieldCategory_Constraint;
  update_columns?: Array<CustomFormFieldCategory_Update_Column>;
  where?: InputMaybe<CustomFormFieldCategory_Bool_Exp>;
};

/** Ordering options when selecting data from "customFormFieldCategory". */
export type CustomFormFieldCategory_Order_By = {
  description?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: customFormFieldCategory */
export type CustomFormFieldCategory_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "customFormFieldCategory" */
export enum CustomFormFieldCategory_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "customFormFieldCategory" */
export type CustomFormFieldCategory_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "customFormFieldCategory" */
export type CustomFormFieldCategory_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: CustomFormFieldCategory_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type CustomFormFieldCategory_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "customFormFieldCategory" */
export enum CustomFormFieldCategory_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

export type CustomFormFieldCategory_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CustomFormFieldCategory_Set_Input>;
  /** filter the rows which have to be updated */
  where: CustomFormFieldCategory_Bool_Exp;
};

/** columns and relationships of "customFormFieldResponse" */
export type CustomFormFieldResponse = {
  __typename?: 'customFormFieldResponse';
  createdAt: Scalars['timestamptz'];
  customFormFieldId: Scalars['uuid'];
  customFormResponseId: Scalars['uuid'];
  /** An object relationship */
  field: CustomFormField;
  /** An object relationship */
  formResponse: CustomFormResponse;
  id: Scalars['uuid'];
  updatedAt: Scalars['timestamptz'];
  value?: Maybe<Scalars['String']>;
};

/** aggregated selection of "customFormFieldResponse" */
export type CustomFormFieldResponse_Aggregate = {
  __typename?: 'customFormFieldResponse_aggregate';
  aggregate?: Maybe<CustomFormFieldResponse_Aggregate_Fields>;
  nodes: Array<CustomFormFieldResponse>;
};

export type CustomFormFieldResponse_Aggregate_Bool_Exp = {
  count?: InputMaybe<CustomFormFieldResponse_Aggregate_Bool_Exp_Count>;
};

export type CustomFormFieldResponse_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<CustomFormFieldResponse_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<CustomFormFieldResponse_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "customFormFieldResponse" */
export type CustomFormFieldResponse_Aggregate_Fields = {
  __typename?: 'customFormFieldResponse_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<CustomFormFieldResponse_Max_Fields>;
  min?: Maybe<CustomFormFieldResponse_Min_Fields>;
};


/** aggregate fields of "customFormFieldResponse" */
export type CustomFormFieldResponse_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<CustomFormFieldResponse_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "customFormFieldResponse" */
export type CustomFormFieldResponse_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<CustomFormFieldResponse_Max_Order_By>;
  min?: InputMaybe<CustomFormFieldResponse_Min_Order_By>;
};

/** input type for inserting array relation for remote table "customFormFieldResponse" */
export type CustomFormFieldResponse_Arr_Rel_Insert_Input = {
  data: Array<CustomFormFieldResponse_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<CustomFormFieldResponse_On_Conflict>;
};

/** Boolean expression to filter rows from the table "customFormFieldResponse". All fields are combined with a logical 'AND'. */
export type CustomFormFieldResponse_Bool_Exp = {
  _and?: InputMaybe<Array<CustomFormFieldResponse_Bool_Exp>>;
  _not?: InputMaybe<CustomFormFieldResponse_Bool_Exp>;
  _or?: InputMaybe<Array<CustomFormFieldResponse_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  customFormFieldId?: InputMaybe<Uuid_Comparison_Exp>;
  customFormResponseId?: InputMaybe<Uuid_Comparison_Exp>;
  field?: InputMaybe<CustomFormField_Bool_Exp>;
  formResponse?: InputMaybe<CustomFormResponse_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "customFormFieldResponse" */
export enum CustomFormFieldResponse_Constraint {
  /** unique or primary key constraint on columns "id" */
  CustomFormFieldResponsePkey = 'customFormFieldResponse_pkey'
}

/** input type for inserting data into table "customFormFieldResponse" */
export type CustomFormFieldResponse_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  customFormFieldId?: InputMaybe<Scalars['uuid']>;
  customFormResponseId?: InputMaybe<Scalars['uuid']>;
  field?: InputMaybe<CustomFormField_Obj_Rel_Insert_Input>;
  formResponse?: InputMaybe<CustomFormResponse_Obj_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type CustomFormFieldResponse_Max_Fields = {
  __typename?: 'customFormFieldResponse_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  customFormFieldId?: Maybe<Scalars['uuid']>;
  customFormResponseId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "customFormFieldResponse" */
export type CustomFormFieldResponse_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  customFormFieldId?: InputMaybe<Order_By>;
  customFormResponseId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type CustomFormFieldResponse_Min_Fields = {
  __typename?: 'customFormFieldResponse_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  customFormFieldId?: Maybe<Scalars['uuid']>;
  customFormResponseId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "customFormFieldResponse" */
export type CustomFormFieldResponse_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  customFormFieldId?: InputMaybe<Order_By>;
  customFormResponseId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "customFormFieldResponse" */
export type CustomFormFieldResponse_Mutation_Response = {
  __typename?: 'customFormFieldResponse_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CustomFormFieldResponse>;
};

/** on_conflict condition type for table "customFormFieldResponse" */
export type CustomFormFieldResponse_On_Conflict = {
  constraint: CustomFormFieldResponse_Constraint;
  update_columns?: Array<CustomFormFieldResponse_Update_Column>;
  where?: InputMaybe<CustomFormFieldResponse_Bool_Exp>;
};

/** Ordering options when selecting data from "customFormFieldResponse". */
export type CustomFormFieldResponse_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  customFormFieldId?: InputMaybe<Order_By>;
  customFormResponseId?: InputMaybe<Order_By>;
  field?: InputMaybe<CustomFormField_Order_By>;
  formResponse?: InputMaybe<CustomFormResponse_Order_By>;
  id?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: customFormFieldResponse */
export type CustomFormFieldResponse_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "customFormFieldResponse" */
export enum CustomFormFieldResponse_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CustomFormFieldId = 'customFormFieldId',
  /** column name */
  CustomFormResponseId = 'customFormResponseId',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "customFormFieldResponse" */
export type CustomFormFieldResponse_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  customFormFieldId?: InputMaybe<Scalars['uuid']>;
  customFormResponseId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "customFormFieldResponse" */
export type CustomFormFieldResponse_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: CustomFormFieldResponse_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type CustomFormFieldResponse_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  customFormFieldId?: InputMaybe<Scalars['uuid']>;
  customFormResponseId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "customFormFieldResponse" */
export enum CustomFormFieldResponse_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CustomFormFieldId = 'customFormFieldId',
  /** column name */
  CustomFormResponseId = 'customFormResponseId',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Value = 'value'
}

export type CustomFormFieldResponse_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CustomFormFieldResponse_Set_Input>;
  /** filter the rows which have to be updated */
  where: CustomFormFieldResponse_Bool_Exp;
};

/** columns and relationships of "customFormFieldType" */
export type CustomFormFieldType = {
  __typename?: 'customFormFieldType';
  description: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "customFormFieldType" */
export type CustomFormFieldType_Aggregate = {
  __typename?: 'customFormFieldType_aggregate';
  aggregate?: Maybe<CustomFormFieldType_Aggregate_Fields>;
  nodes: Array<CustomFormFieldType>;
};

/** aggregate fields of "customFormFieldType" */
export type CustomFormFieldType_Aggregate_Fields = {
  __typename?: 'customFormFieldType_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<CustomFormFieldType_Max_Fields>;
  min?: Maybe<CustomFormFieldType_Min_Fields>;
};


/** aggregate fields of "customFormFieldType" */
export type CustomFormFieldType_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<CustomFormFieldType_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "customFormFieldType". All fields are combined with a logical 'AND'. */
export type CustomFormFieldType_Bool_Exp = {
  _and?: InputMaybe<Array<CustomFormFieldType_Bool_Exp>>;
  _not?: InputMaybe<CustomFormFieldType_Bool_Exp>;
  _or?: InputMaybe<Array<CustomFormFieldType_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "customFormFieldType" */
export enum CustomFormFieldType_Constraint {
  /** unique or primary key constraint on columns "value" */
  CustomFormFieldTypePkey = 'customFormFieldType_pkey'
}

export enum CustomFormFieldType_Enum {
  /** Address */
  Address = 'ADDRESS',
  /** Creatable select */
  CreatableSelect = 'CREATABLE_SELECT',
  /** Creatable single select */
  CreatableSingleSelect = 'CREATABLE_SINGLE_SELECT',
  /** Date */
  Date = 'DATE',
  /** Dropdown select */
  DropdownSelect = 'DROPDOWN_SELECT',
  /** Formatted Text */
  FormattedText = 'FORMATTED_TEXT',
  /** Multiple select */
  MultipleSelect = 'MULTIPLE_SELECT',
  /** Multiple select with image */
  MultipleSelectWithImage = 'MULTIPLE_SELECT_WITH_IMAGE',
  /** Signature */
  Signature = 'SIGNATURE',
  /** Single select */
  SingleSelect = 'SINGLE_SELECT',
  /** Single select with image */
  SingleSelectWithImage = 'SINGLE_SELECT_WITH_IMAGE',
  /** Text input */
  TextInput = 'TEXT_INPUT',
  /** Y/N */
  YesNo = 'YES_NO'
}

/** Boolean expression to compare columns of type "customFormFieldType_enum". All fields are combined with logical 'AND'. */
export type CustomFormFieldType_Enum_Comparison_Exp = {
  _eq?: InputMaybe<CustomFormFieldType_Enum>;
  _in?: InputMaybe<Array<CustomFormFieldType_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<CustomFormFieldType_Enum>;
  _nin?: InputMaybe<Array<CustomFormFieldType_Enum>>;
};

/** input type for inserting data into table "customFormFieldType" */
export type CustomFormFieldType_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type CustomFormFieldType_Max_Fields = {
  __typename?: 'customFormFieldType_max_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type CustomFormFieldType_Min_Fields = {
  __typename?: 'customFormFieldType_min_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "customFormFieldType" */
export type CustomFormFieldType_Mutation_Response = {
  __typename?: 'customFormFieldType_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CustomFormFieldType>;
};

/** input type for inserting object relation for remote table "customFormFieldType" */
export type CustomFormFieldType_Obj_Rel_Insert_Input = {
  data: CustomFormFieldType_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<CustomFormFieldType_On_Conflict>;
};

/** on_conflict condition type for table "customFormFieldType" */
export type CustomFormFieldType_On_Conflict = {
  constraint: CustomFormFieldType_Constraint;
  update_columns?: Array<CustomFormFieldType_Update_Column>;
  where?: InputMaybe<CustomFormFieldType_Bool_Exp>;
};

/** Ordering options when selecting data from "customFormFieldType". */
export type CustomFormFieldType_Order_By = {
  description?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: customFormFieldType */
export type CustomFormFieldType_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "customFormFieldType" */
export enum CustomFormFieldType_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "customFormFieldType" */
export type CustomFormFieldType_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "customFormFieldType" */
export type CustomFormFieldType_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: CustomFormFieldType_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type CustomFormFieldType_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "customFormFieldType" */
export enum CustomFormFieldType_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

export type CustomFormFieldType_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CustomFormFieldType_Set_Input>;
  /** filter the rows which have to be updated */
  where: CustomFormFieldType_Bool_Exp;
};

/** aggregated selection of "customFormField" */
export type CustomFormField_Aggregate = {
  __typename?: 'customFormField_aggregate';
  aggregate?: Maybe<CustomFormField_Aggregate_Fields>;
  nodes: Array<CustomFormField>;
};

export type CustomFormField_Aggregate_Bool_Exp = {
  count?: InputMaybe<CustomFormField_Aggregate_Bool_Exp_Count>;
};

export type CustomFormField_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<CustomFormField_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<CustomFormField_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "customFormField" */
export type CustomFormField_Aggregate_Fields = {
  __typename?: 'customFormField_aggregate_fields';
  avg?: Maybe<CustomFormField_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<CustomFormField_Max_Fields>;
  min?: Maybe<CustomFormField_Min_Fields>;
  stddev?: Maybe<CustomFormField_Stddev_Fields>;
  stddev_pop?: Maybe<CustomFormField_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<CustomFormField_Stddev_Samp_Fields>;
  sum?: Maybe<CustomFormField_Sum_Fields>;
  var_pop?: Maybe<CustomFormField_Var_Pop_Fields>;
  var_samp?: Maybe<CustomFormField_Var_Samp_Fields>;
  variance?: Maybe<CustomFormField_Variance_Fields>;
};


/** aggregate fields of "customFormField" */
export type CustomFormField_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<CustomFormField_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "customFormField" */
export type CustomFormField_Aggregate_Order_By = {
  avg?: InputMaybe<CustomFormField_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<CustomFormField_Max_Order_By>;
  min?: InputMaybe<CustomFormField_Min_Order_By>;
  stddev?: InputMaybe<CustomFormField_Stddev_Order_By>;
  stddev_pop?: InputMaybe<CustomFormField_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<CustomFormField_Stddev_Samp_Order_By>;
  sum?: InputMaybe<CustomFormField_Sum_Order_By>;
  var_pop?: InputMaybe<CustomFormField_Var_Pop_Order_By>;
  var_samp?: InputMaybe<CustomFormField_Var_Samp_Order_By>;
  variance?: InputMaybe<CustomFormField_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type CustomFormField_Append_Input = {
  validation?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "customFormField" */
export type CustomFormField_Arr_Rel_Insert_Input = {
  data: Array<CustomFormField_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<CustomFormField_On_Conflict>;
};

/** aggregate avg on columns */
export type CustomFormField_Avg_Fields = {
  __typename?: 'customFormField_avg_fields';
  index?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "customFormField" */
export type CustomFormField_Avg_Order_By = {
  index?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "customFormField". All fields are combined with a logical 'AND'. */
export type CustomFormField_Bool_Exp = {
  _and?: InputMaybe<Array<CustomFormField_Bool_Exp>>;
  _not?: InputMaybe<CustomFormField_Bool_Exp>;
  _or?: InputMaybe<Array<CustomFormField_Bool_Exp>>;
  apiFieldKey?: InputMaybe<String_Comparison_Exp>;
  attribute?: InputMaybe<String_Comparison_Exp>;
  body?: InputMaybe<String_Comparison_Exp>;
  category?: InputMaybe<CustomFormFieldCategory_Enum_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  customFormRowId?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  index?: InputMaybe<Int_Comparison_Exp>;
  label?: InputMaybe<String_Comparison_Exp>;
  options?: InputMaybe<CustomFormOption_Bool_Exp>;
  options_aggregate?: InputMaybe<CustomFormOption_Aggregate_Bool_Exp>;
  photo?: InputMaybe<Photo_Bool_Exp>;
  photoId?: InputMaybe<Uuid_Comparison_Exp>;
  row?: InputMaybe<CustomFormRow_Bool_Exp>;
  table?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<CustomFormFieldType_Enum_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  validation?: InputMaybe<Jsonb_Comparison_Exp>;
};

/** unique or primary key constraints on table "customFormField" */
export enum CustomFormField_Constraint {
  /** unique or primary key constraint on columns "id" */
  CustomFormFieldPkey = 'customFormField_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type CustomFormField_Delete_At_Path_Input = {
  validation?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type CustomFormField_Delete_Elem_Input = {
  validation?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type CustomFormField_Delete_Key_Input = {
  validation?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "customFormField" */
export type CustomFormField_Inc_Input = {
  index?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "customFormField" */
export type CustomFormField_Insert_Input = {
  apiFieldKey?: InputMaybe<Scalars['String']>;
  attribute?: InputMaybe<Scalars['String']>;
  body?: InputMaybe<Scalars['String']>;
  category?: InputMaybe<CustomFormFieldCategory_Enum>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  customFormRowId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  index?: InputMaybe<Scalars['Int']>;
  label?: InputMaybe<Scalars['String']>;
  options?: InputMaybe<CustomFormOption_Arr_Rel_Insert_Input>;
  photo?: InputMaybe<Photo_Obj_Rel_Insert_Input>;
  photoId?: InputMaybe<Scalars['uuid']>;
  row?: InputMaybe<CustomFormRow_Obj_Rel_Insert_Input>;
  table?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<CustomFormFieldType_Enum>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  validation?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate max on columns */
export type CustomFormField_Max_Fields = {
  __typename?: 'customFormField_max_fields';
  apiFieldKey?: Maybe<Scalars['String']>;
  attribute?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  customFormRowId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  index?: Maybe<Scalars['Int']>;
  label?: Maybe<Scalars['String']>;
  photoId?: Maybe<Scalars['uuid']>;
  table?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "customFormField" */
export type CustomFormField_Max_Order_By = {
  apiFieldKey?: InputMaybe<Order_By>;
  attribute?: InputMaybe<Order_By>;
  body?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  customFormRowId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  index?: InputMaybe<Order_By>;
  label?: InputMaybe<Order_By>;
  photoId?: InputMaybe<Order_By>;
  table?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type CustomFormField_Min_Fields = {
  __typename?: 'customFormField_min_fields';
  apiFieldKey?: Maybe<Scalars['String']>;
  attribute?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  customFormRowId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  index?: Maybe<Scalars['Int']>;
  label?: Maybe<Scalars['String']>;
  photoId?: Maybe<Scalars['uuid']>;
  table?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "customFormField" */
export type CustomFormField_Min_Order_By = {
  apiFieldKey?: InputMaybe<Order_By>;
  attribute?: InputMaybe<Order_By>;
  body?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  customFormRowId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  index?: InputMaybe<Order_By>;
  label?: InputMaybe<Order_By>;
  photoId?: InputMaybe<Order_By>;
  table?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "customFormField" */
export type CustomFormField_Mutation_Response = {
  __typename?: 'customFormField_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CustomFormField>;
};

/** input type for inserting object relation for remote table "customFormField" */
export type CustomFormField_Obj_Rel_Insert_Input = {
  data: CustomFormField_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<CustomFormField_On_Conflict>;
};

/** on_conflict condition type for table "customFormField" */
export type CustomFormField_On_Conflict = {
  constraint: CustomFormField_Constraint;
  update_columns?: Array<CustomFormField_Update_Column>;
  where?: InputMaybe<CustomFormField_Bool_Exp>;
};

/** Ordering options when selecting data from "customFormField". */
export type CustomFormField_Order_By = {
  apiFieldKey?: InputMaybe<Order_By>;
  attribute?: InputMaybe<Order_By>;
  body?: InputMaybe<Order_By>;
  category?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  customFormRowId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  index?: InputMaybe<Order_By>;
  label?: InputMaybe<Order_By>;
  options_aggregate?: InputMaybe<CustomFormOption_Aggregate_Order_By>;
  photo?: InputMaybe<Photo_Order_By>;
  photoId?: InputMaybe<Order_By>;
  row?: InputMaybe<CustomFormRow_Order_By>;
  table?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  validation?: InputMaybe<Order_By>;
};

/** primary key columns input for table: customFormField */
export type CustomFormField_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type CustomFormField_Prepend_Input = {
  validation?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "customFormField" */
export enum CustomFormField_Select_Column {
  /** column name */
  ApiFieldKey = 'apiFieldKey',
  /** column name */
  Attribute = 'attribute',
  /** column name */
  Body = 'body',
  /** column name */
  Category = 'category',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CustomFormRowId = 'customFormRowId',
  /** column name */
  Id = 'id',
  /** column name */
  Index = 'index',
  /** column name */
  Label = 'label',
  /** column name */
  PhotoId = 'photoId',
  /** column name */
  Table = 'table',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Validation = 'validation'
}

/** input type for updating data in table "customFormField" */
export type CustomFormField_Set_Input = {
  apiFieldKey?: InputMaybe<Scalars['String']>;
  attribute?: InputMaybe<Scalars['String']>;
  body?: InputMaybe<Scalars['String']>;
  category?: InputMaybe<CustomFormFieldCategory_Enum>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  customFormRowId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  index?: InputMaybe<Scalars['Int']>;
  label?: InputMaybe<Scalars['String']>;
  photoId?: InputMaybe<Scalars['uuid']>;
  table?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<CustomFormFieldType_Enum>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  validation?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate stddev on columns */
export type CustomFormField_Stddev_Fields = {
  __typename?: 'customFormField_stddev_fields';
  index?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "customFormField" */
export type CustomFormField_Stddev_Order_By = {
  index?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type CustomFormField_Stddev_Pop_Fields = {
  __typename?: 'customFormField_stddev_pop_fields';
  index?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "customFormField" */
export type CustomFormField_Stddev_Pop_Order_By = {
  index?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type CustomFormField_Stddev_Samp_Fields = {
  __typename?: 'customFormField_stddev_samp_fields';
  index?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "customFormField" */
export type CustomFormField_Stddev_Samp_Order_By = {
  index?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "customFormField" */
export type CustomFormField_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: CustomFormField_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type CustomFormField_Stream_Cursor_Value_Input = {
  apiFieldKey?: InputMaybe<Scalars['String']>;
  attribute?: InputMaybe<Scalars['String']>;
  body?: InputMaybe<Scalars['String']>;
  category?: InputMaybe<CustomFormFieldCategory_Enum>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  customFormRowId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  index?: InputMaybe<Scalars['Int']>;
  label?: InputMaybe<Scalars['String']>;
  photoId?: InputMaybe<Scalars['uuid']>;
  table?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<CustomFormFieldType_Enum>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  validation?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate sum on columns */
export type CustomFormField_Sum_Fields = {
  __typename?: 'customFormField_sum_fields';
  index?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "customFormField" */
export type CustomFormField_Sum_Order_By = {
  index?: InputMaybe<Order_By>;
};

/** update columns of table "customFormField" */
export enum CustomFormField_Update_Column {
  /** column name */
  ApiFieldKey = 'apiFieldKey',
  /** column name */
  Attribute = 'attribute',
  /** column name */
  Body = 'body',
  /** column name */
  Category = 'category',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CustomFormRowId = 'customFormRowId',
  /** column name */
  Id = 'id',
  /** column name */
  Index = 'index',
  /** column name */
  Label = 'label',
  /** column name */
  PhotoId = 'photoId',
  /** column name */
  Table = 'table',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Validation = 'validation'
}

export type CustomFormField_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<CustomFormField_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<CustomFormField_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<CustomFormField_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<CustomFormField_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<CustomFormField_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<CustomFormField_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CustomFormField_Set_Input>;
  /** filter the rows which have to be updated */
  where: CustomFormField_Bool_Exp;
};

/** aggregate var_pop on columns */
export type CustomFormField_Var_Pop_Fields = {
  __typename?: 'customFormField_var_pop_fields';
  index?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "customFormField" */
export type CustomFormField_Var_Pop_Order_By = {
  index?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type CustomFormField_Var_Samp_Fields = {
  __typename?: 'customFormField_var_samp_fields';
  index?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "customFormField" */
export type CustomFormField_Var_Samp_Order_By = {
  index?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type CustomFormField_Variance_Fields = {
  __typename?: 'customFormField_variance_fields';
  index?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "customFormField" */
export type CustomFormField_Variance_Order_By = {
  index?: InputMaybe<Order_By>;
};

/** columns and relationships of "customFormOption" */
export type CustomFormOption = {
  __typename?: 'customFormOption';
  createdAt: Scalars['timestamptz'];
  customFormFieldId: Scalars['uuid'];
  /** An object relationship */
  field: CustomFormField;
  id: Scalars['uuid'];
  updatedAt: Scalars['timestamptz'];
  value: Scalars['String'];
};

/** aggregated selection of "customFormOption" */
export type CustomFormOption_Aggregate = {
  __typename?: 'customFormOption_aggregate';
  aggregate?: Maybe<CustomFormOption_Aggregate_Fields>;
  nodes: Array<CustomFormOption>;
};

export type CustomFormOption_Aggregate_Bool_Exp = {
  count?: InputMaybe<CustomFormOption_Aggregate_Bool_Exp_Count>;
};

export type CustomFormOption_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<CustomFormOption_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<CustomFormOption_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "customFormOption" */
export type CustomFormOption_Aggregate_Fields = {
  __typename?: 'customFormOption_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<CustomFormOption_Max_Fields>;
  min?: Maybe<CustomFormOption_Min_Fields>;
};


/** aggregate fields of "customFormOption" */
export type CustomFormOption_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<CustomFormOption_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "customFormOption" */
export type CustomFormOption_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<CustomFormOption_Max_Order_By>;
  min?: InputMaybe<CustomFormOption_Min_Order_By>;
};

/** input type for inserting array relation for remote table "customFormOption" */
export type CustomFormOption_Arr_Rel_Insert_Input = {
  data: Array<CustomFormOption_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<CustomFormOption_On_Conflict>;
};

/** Boolean expression to filter rows from the table "customFormOption". All fields are combined with a logical 'AND'. */
export type CustomFormOption_Bool_Exp = {
  _and?: InputMaybe<Array<CustomFormOption_Bool_Exp>>;
  _not?: InputMaybe<CustomFormOption_Bool_Exp>;
  _or?: InputMaybe<Array<CustomFormOption_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  customFormFieldId?: InputMaybe<Uuid_Comparison_Exp>;
  field?: InputMaybe<CustomFormField_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "customFormOption" */
export enum CustomFormOption_Constraint {
  /** unique or primary key constraint on columns "id" */
  CustomFormSelectOptionPkey = 'customFormSelectOption_pkey'
}

/** input type for inserting data into table "customFormOption" */
export type CustomFormOption_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  customFormFieldId?: InputMaybe<Scalars['uuid']>;
  field?: InputMaybe<CustomFormField_Obj_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type CustomFormOption_Max_Fields = {
  __typename?: 'customFormOption_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  customFormFieldId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "customFormOption" */
export type CustomFormOption_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  customFormFieldId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type CustomFormOption_Min_Fields = {
  __typename?: 'customFormOption_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  customFormFieldId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "customFormOption" */
export type CustomFormOption_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  customFormFieldId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "customFormOption" */
export type CustomFormOption_Mutation_Response = {
  __typename?: 'customFormOption_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CustomFormOption>;
};

/** on_conflict condition type for table "customFormOption" */
export type CustomFormOption_On_Conflict = {
  constraint: CustomFormOption_Constraint;
  update_columns?: Array<CustomFormOption_Update_Column>;
  where?: InputMaybe<CustomFormOption_Bool_Exp>;
};

/** Ordering options when selecting data from "customFormOption". */
export type CustomFormOption_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  customFormFieldId?: InputMaybe<Order_By>;
  field?: InputMaybe<CustomFormField_Order_By>;
  id?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: customFormOption */
export type CustomFormOption_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "customFormOption" */
export enum CustomFormOption_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CustomFormFieldId = 'customFormFieldId',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "customFormOption" */
export type CustomFormOption_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  customFormFieldId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "customFormOption" */
export type CustomFormOption_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: CustomFormOption_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type CustomFormOption_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  customFormFieldId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "customFormOption" */
export enum CustomFormOption_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CustomFormFieldId = 'customFormFieldId',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Value = 'value'
}

export type CustomFormOption_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CustomFormOption_Set_Input>;
  /** filter the rows which have to be updated */
  where: CustomFormOption_Bool_Exp;
};

/** columns and relationships of "customFormReplicatedField" */
export type CustomFormReplicatedField = {
  __typename?: 'customFormReplicatedField';
  createdAt: Scalars['timestamptz'];
  customFormFieldId: Scalars['uuid'];
  customFormReplicatedRowId: Scalars['uuid'];
  /** An object relationship */
  field: CustomFormField;
  id: Scalars['uuid'];
  updatedAt: Scalars['timestamptz'];
  value?: Maybe<Scalars['String']>;
};

/** aggregated selection of "customFormReplicatedField" */
export type CustomFormReplicatedField_Aggregate = {
  __typename?: 'customFormReplicatedField_aggregate';
  aggregate?: Maybe<CustomFormReplicatedField_Aggregate_Fields>;
  nodes: Array<CustomFormReplicatedField>;
};

export type CustomFormReplicatedField_Aggregate_Bool_Exp = {
  count?: InputMaybe<CustomFormReplicatedField_Aggregate_Bool_Exp_Count>;
};

export type CustomFormReplicatedField_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<CustomFormReplicatedField_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<CustomFormReplicatedField_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "customFormReplicatedField" */
export type CustomFormReplicatedField_Aggregate_Fields = {
  __typename?: 'customFormReplicatedField_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<CustomFormReplicatedField_Max_Fields>;
  min?: Maybe<CustomFormReplicatedField_Min_Fields>;
};


/** aggregate fields of "customFormReplicatedField" */
export type CustomFormReplicatedField_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<CustomFormReplicatedField_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "customFormReplicatedField" */
export type CustomFormReplicatedField_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<CustomFormReplicatedField_Max_Order_By>;
  min?: InputMaybe<CustomFormReplicatedField_Min_Order_By>;
};

/** input type for inserting array relation for remote table "customFormReplicatedField" */
export type CustomFormReplicatedField_Arr_Rel_Insert_Input = {
  data: Array<CustomFormReplicatedField_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<CustomFormReplicatedField_On_Conflict>;
};

/** Boolean expression to filter rows from the table "customFormReplicatedField". All fields are combined with a logical 'AND'. */
export type CustomFormReplicatedField_Bool_Exp = {
  _and?: InputMaybe<Array<CustomFormReplicatedField_Bool_Exp>>;
  _not?: InputMaybe<CustomFormReplicatedField_Bool_Exp>;
  _or?: InputMaybe<Array<CustomFormReplicatedField_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  customFormFieldId?: InputMaybe<Uuid_Comparison_Exp>;
  customFormReplicatedRowId?: InputMaybe<Uuid_Comparison_Exp>;
  field?: InputMaybe<CustomFormField_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "customFormReplicatedField" */
export enum CustomFormReplicatedField_Constraint {
  /** unique or primary key constraint on columns "id" */
  CustomFormReplicatedFieldPkey = 'customFormReplicatedField_pkey'
}

/** input type for inserting data into table "customFormReplicatedField" */
export type CustomFormReplicatedField_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  customFormFieldId?: InputMaybe<Scalars['uuid']>;
  customFormReplicatedRowId?: InputMaybe<Scalars['uuid']>;
  field?: InputMaybe<CustomFormField_Obj_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type CustomFormReplicatedField_Max_Fields = {
  __typename?: 'customFormReplicatedField_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  customFormFieldId?: Maybe<Scalars['uuid']>;
  customFormReplicatedRowId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "customFormReplicatedField" */
export type CustomFormReplicatedField_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  customFormFieldId?: InputMaybe<Order_By>;
  customFormReplicatedRowId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type CustomFormReplicatedField_Min_Fields = {
  __typename?: 'customFormReplicatedField_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  customFormFieldId?: Maybe<Scalars['uuid']>;
  customFormReplicatedRowId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "customFormReplicatedField" */
export type CustomFormReplicatedField_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  customFormFieldId?: InputMaybe<Order_By>;
  customFormReplicatedRowId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "customFormReplicatedField" */
export type CustomFormReplicatedField_Mutation_Response = {
  __typename?: 'customFormReplicatedField_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CustomFormReplicatedField>;
};

/** on_conflict condition type for table "customFormReplicatedField" */
export type CustomFormReplicatedField_On_Conflict = {
  constraint: CustomFormReplicatedField_Constraint;
  update_columns?: Array<CustomFormReplicatedField_Update_Column>;
  where?: InputMaybe<CustomFormReplicatedField_Bool_Exp>;
};

/** Ordering options when selecting data from "customFormReplicatedField". */
export type CustomFormReplicatedField_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  customFormFieldId?: InputMaybe<Order_By>;
  customFormReplicatedRowId?: InputMaybe<Order_By>;
  field?: InputMaybe<CustomFormField_Order_By>;
  id?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: customFormReplicatedField */
export type CustomFormReplicatedField_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "customFormReplicatedField" */
export enum CustomFormReplicatedField_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CustomFormFieldId = 'customFormFieldId',
  /** column name */
  CustomFormReplicatedRowId = 'customFormReplicatedRowId',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "customFormReplicatedField" */
export type CustomFormReplicatedField_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  customFormFieldId?: InputMaybe<Scalars['uuid']>;
  customFormReplicatedRowId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "customFormReplicatedField" */
export type CustomFormReplicatedField_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: CustomFormReplicatedField_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type CustomFormReplicatedField_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  customFormFieldId?: InputMaybe<Scalars['uuid']>;
  customFormReplicatedRowId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "customFormReplicatedField" */
export enum CustomFormReplicatedField_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CustomFormFieldId = 'customFormFieldId',
  /** column name */
  CustomFormReplicatedRowId = 'customFormReplicatedRowId',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Value = 'value'
}

export type CustomFormReplicatedField_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CustomFormReplicatedField_Set_Input>;
  /** filter the rows which have to be updated */
  where: CustomFormReplicatedField_Bool_Exp;
};

/** columns and relationships of "customFormReplicatedRow" */
export type CustomFormReplicatedRow = {
  __typename?: 'customFormReplicatedRow';
  apiFieldGroupKey: Scalars['String'];
  createdAt: Scalars['timestamp'];
  /** An object relationship */
  customFormResponse: CustomFormResponse;
  customFormResponseId: Scalars['uuid'];
  /** An array relationship */
  fields: Array<CustomFormReplicatedField>;
  /** An aggregate relationship */
  fields_aggregate: CustomFormReplicatedField_Aggregate;
  groupLabel: Scalars['String'];
  id: Scalars['uuid'];
  index: Scalars['Int'];
  updatedAt: Scalars['timestamptz'];
};


/** columns and relationships of "customFormReplicatedRow" */
export type CustomFormReplicatedRowFieldsArgs = {
  distinct_on?: InputMaybe<Array<CustomFormReplicatedField_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CustomFormReplicatedField_Order_By>>;
  where?: InputMaybe<CustomFormReplicatedField_Bool_Exp>;
};


/** columns and relationships of "customFormReplicatedRow" */
export type CustomFormReplicatedRowFields_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CustomFormReplicatedField_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CustomFormReplicatedField_Order_By>>;
  where?: InputMaybe<CustomFormReplicatedField_Bool_Exp>;
};

/** aggregated selection of "customFormReplicatedRow" */
export type CustomFormReplicatedRow_Aggregate = {
  __typename?: 'customFormReplicatedRow_aggregate';
  aggregate?: Maybe<CustomFormReplicatedRow_Aggregate_Fields>;
  nodes: Array<CustomFormReplicatedRow>;
};

export type CustomFormReplicatedRow_Aggregate_Bool_Exp = {
  count?: InputMaybe<CustomFormReplicatedRow_Aggregate_Bool_Exp_Count>;
};

export type CustomFormReplicatedRow_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<CustomFormReplicatedRow_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<CustomFormReplicatedRow_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "customFormReplicatedRow" */
export type CustomFormReplicatedRow_Aggregate_Fields = {
  __typename?: 'customFormReplicatedRow_aggregate_fields';
  avg?: Maybe<CustomFormReplicatedRow_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<CustomFormReplicatedRow_Max_Fields>;
  min?: Maybe<CustomFormReplicatedRow_Min_Fields>;
  stddev?: Maybe<CustomFormReplicatedRow_Stddev_Fields>;
  stddev_pop?: Maybe<CustomFormReplicatedRow_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<CustomFormReplicatedRow_Stddev_Samp_Fields>;
  sum?: Maybe<CustomFormReplicatedRow_Sum_Fields>;
  var_pop?: Maybe<CustomFormReplicatedRow_Var_Pop_Fields>;
  var_samp?: Maybe<CustomFormReplicatedRow_Var_Samp_Fields>;
  variance?: Maybe<CustomFormReplicatedRow_Variance_Fields>;
};


/** aggregate fields of "customFormReplicatedRow" */
export type CustomFormReplicatedRow_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<CustomFormReplicatedRow_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "customFormReplicatedRow" */
export type CustomFormReplicatedRow_Aggregate_Order_By = {
  avg?: InputMaybe<CustomFormReplicatedRow_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<CustomFormReplicatedRow_Max_Order_By>;
  min?: InputMaybe<CustomFormReplicatedRow_Min_Order_By>;
  stddev?: InputMaybe<CustomFormReplicatedRow_Stddev_Order_By>;
  stddev_pop?: InputMaybe<CustomFormReplicatedRow_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<CustomFormReplicatedRow_Stddev_Samp_Order_By>;
  sum?: InputMaybe<CustomFormReplicatedRow_Sum_Order_By>;
  var_pop?: InputMaybe<CustomFormReplicatedRow_Var_Pop_Order_By>;
  var_samp?: InputMaybe<CustomFormReplicatedRow_Var_Samp_Order_By>;
  variance?: InputMaybe<CustomFormReplicatedRow_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "customFormReplicatedRow" */
export type CustomFormReplicatedRow_Arr_Rel_Insert_Input = {
  data: Array<CustomFormReplicatedRow_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<CustomFormReplicatedRow_On_Conflict>;
};

/** aggregate avg on columns */
export type CustomFormReplicatedRow_Avg_Fields = {
  __typename?: 'customFormReplicatedRow_avg_fields';
  index?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "customFormReplicatedRow" */
export type CustomFormReplicatedRow_Avg_Order_By = {
  index?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "customFormReplicatedRow". All fields are combined with a logical 'AND'. */
export type CustomFormReplicatedRow_Bool_Exp = {
  _and?: InputMaybe<Array<CustomFormReplicatedRow_Bool_Exp>>;
  _not?: InputMaybe<CustomFormReplicatedRow_Bool_Exp>;
  _or?: InputMaybe<Array<CustomFormReplicatedRow_Bool_Exp>>;
  apiFieldGroupKey?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamp_Comparison_Exp>;
  customFormResponse?: InputMaybe<CustomFormResponse_Bool_Exp>;
  customFormResponseId?: InputMaybe<Uuid_Comparison_Exp>;
  fields?: InputMaybe<CustomFormReplicatedField_Bool_Exp>;
  fields_aggregate?: InputMaybe<CustomFormReplicatedField_Aggregate_Bool_Exp>;
  groupLabel?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  index?: InputMaybe<Int_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "customFormReplicatedRow" */
export enum CustomFormReplicatedRow_Constraint {
  /** unique or primary key constraint on columns "id" */
  CustomFormReplicatedRowPkey = 'customFormReplicatedRow_pkey'
}

/** input type for incrementing numeric columns in table "customFormReplicatedRow" */
export type CustomFormReplicatedRow_Inc_Input = {
  index?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "customFormReplicatedRow" */
export type CustomFormReplicatedRow_Insert_Input = {
  apiFieldGroupKey?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamp']>;
  customFormResponse?: InputMaybe<CustomFormResponse_Obj_Rel_Insert_Input>;
  customFormResponseId?: InputMaybe<Scalars['uuid']>;
  fields?: InputMaybe<CustomFormReplicatedField_Arr_Rel_Insert_Input>;
  groupLabel?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  index?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type CustomFormReplicatedRow_Max_Fields = {
  __typename?: 'customFormReplicatedRow_max_fields';
  apiFieldGroupKey?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  customFormResponseId?: Maybe<Scalars['uuid']>;
  groupLabel?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  index?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "customFormReplicatedRow" */
export type CustomFormReplicatedRow_Max_Order_By = {
  apiFieldGroupKey?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  customFormResponseId?: InputMaybe<Order_By>;
  groupLabel?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  index?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type CustomFormReplicatedRow_Min_Fields = {
  __typename?: 'customFormReplicatedRow_min_fields';
  apiFieldGroupKey?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  customFormResponseId?: Maybe<Scalars['uuid']>;
  groupLabel?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  index?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "customFormReplicatedRow" */
export type CustomFormReplicatedRow_Min_Order_By = {
  apiFieldGroupKey?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  customFormResponseId?: InputMaybe<Order_By>;
  groupLabel?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  index?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "customFormReplicatedRow" */
export type CustomFormReplicatedRow_Mutation_Response = {
  __typename?: 'customFormReplicatedRow_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CustomFormReplicatedRow>;
};

/** on_conflict condition type for table "customFormReplicatedRow" */
export type CustomFormReplicatedRow_On_Conflict = {
  constraint: CustomFormReplicatedRow_Constraint;
  update_columns?: Array<CustomFormReplicatedRow_Update_Column>;
  where?: InputMaybe<CustomFormReplicatedRow_Bool_Exp>;
};

/** Ordering options when selecting data from "customFormReplicatedRow". */
export type CustomFormReplicatedRow_Order_By = {
  apiFieldGroupKey?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  customFormResponse?: InputMaybe<CustomFormResponse_Order_By>;
  customFormResponseId?: InputMaybe<Order_By>;
  fields_aggregate?: InputMaybe<CustomFormReplicatedField_Aggregate_Order_By>;
  groupLabel?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  index?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: customFormReplicatedRow */
export type CustomFormReplicatedRow_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "customFormReplicatedRow" */
export enum CustomFormReplicatedRow_Select_Column {
  /** column name */
  ApiFieldGroupKey = 'apiFieldGroupKey',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CustomFormResponseId = 'customFormResponseId',
  /** column name */
  GroupLabel = 'groupLabel',
  /** column name */
  Id = 'id',
  /** column name */
  Index = 'index',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "customFormReplicatedRow" */
export type CustomFormReplicatedRow_Set_Input = {
  apiFieldGroupKey?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamp']>;
  customFormResponseId?: InputMaybe<Scalars['uuid']>;
  groupLabel?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  index?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type CustomFormReplicatedRow_Stddev_Fields = {
  __typename?: 'customFormReplicatedRow_stddev_fields';
  index?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "customFormReplicatedRow" */
export type CustomFormReplicatedRow_Stddev_Order_By = {
  index?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type CustomFormReplicatedRow_Stddev_Pop_Fields = {
  __typename?: 'customFormReplicatedRow_stddev_pop_fields';
  index?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "customFormReplicatedRow" */
export type CustomFormReplicatedRow_Stddev_Pop_Order_By = {
  index?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type CustomFormReplicatedRow_Stddev_Samp_Fields = {
  __typename?: 'customFormReplicatedRow_stddev_samp_fields';
  index?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "customFormReplicatedRow" */
export type CustomFormReplicatedRow_Stddev_Samp_Order_By = {
  index?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "customFormReplicatedRow" */
export type CustomFormReplicatedRow_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: CustomFormReplicatedRow_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type CustomFormReplicatedRow_Stream_Cursor_Value_Input = {
  apiFieldGroupKey?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamp']>;
  customFormResponseId?: InputMaybe<Scalars['uuid']>;
  groupLabel?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  index?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type CustomFormReplicatedRow_Sum_Fields = {
  __typename?: 'customFormReplicatedRow_sum_fields';
  index?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "customFormReplicatedRow" */
export type CustomFormReplicatedRow_Sum_Order_By = {
  index?: InputMaybe<Order_By>;
};

/** update columns of table "customFormReplicatedRow" */
export enum CustomFormReplicatedRow_Update_Column {
  /** column name */
  ApiFieldGroupKey = 'apiFieldGroupKey',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CustomFormResponseId = 'customFormResponseId',
  /** column name */
  GroupLabel = 'groupLabel',
  /** column name */
  Id = 'id',
  /** column name */
  Index = 'index',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type CustomFormReplicatedRow_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<CustomFormReplicatedRow_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CustomFormReplicatedRow_Set_Input>;
  /** filter the rows which have to be updated */
  where: CustomFormReplicatedRow_Bool_Exp;
};

/** aggregate var_pop on columns */
export type CustomFormReplicatedRow_Var_Pop_Fields = {
  __typename?: 'customFormReplicatedRow_var_pop_fields';
  index?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "customFormReplicatedRow" */
export type CustomFormReplicatedRow_Var_Pop_Order_By = {
  index?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type CustomFormReplicatedRow_Var_Samp_Fields = {
  __typename?: 'customFormReplicatedRow_var_samp_fields';
  index?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "customFormReplicatedRow" */
export type CustomFormReplicatedRow_Var_Samp_Order_By = {
  index?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type CustomFormReplicatedRow_Variance_Fields = {
  __typename?: 'customFormReplicatedRow_variance_fields';
  index?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "customFormReplicatedRow" */
export type CustomFormReplicatedRow_Variance_Order_By = {
  index?: InputMaybe<Order_By>;
};

/** columns and relationships of "customFormResponse" */
export type CustomFormResponse = {
  __typename?: 'customFormResponse';
  appointmentId: Scalars['uuid'];
  createdAt: Scalars['timestamptz'];
  customFormId: Scalars['uuid'];
  /** An object relationship */
  document?: Maybe<Document>;
  documentId?: Maybe<Scalars['uuid']>;
  /** An array relationship */
  fieldResponses: Array<CustomFormFieldResponse>;
  /** An aggregate relationship */
  fieldResponses_aggregate: CustomFormFieldResponse_Aggregate;
  /** An object relationship */
  form: CustomForm;
  id: Scalars['uuid'];
  patientId: Scalars['uuid'];
  /** An array relationship */
  replicatedRows: Array<CustomFormReplicatedRow>;
  /** An aggregate relationship */
  replicatedRows_aggregate: CustomFormReplicatedRow_Aggregate;
  surveyJSData?: Maybe<Scalars['jsonb']>;
  updatedAt: Scalars['timestamptz'];
};


/** columns and relationships of "customFormResponse" */
export type CustomFormResponseFieldResponsesArgs = {
  distinct_on?: InputMaybe<Array<CustomFormFieldResponse_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CustomFormFieldResponse_Order_By>>;
  where?: InputMaybe<CustomFormFieldResponse_Bool_Exp>;
};


/** columns and relationships of "customFormResponse" */
export type CustomFormResponseFieldResponses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CustomFormFieldResponse_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CustomFormFieldResponse_Order_By>>;
  where?: InputMaybe<CustomFormFieldResponse_Bool_Exp>;
};


/** columns and relationships of "customFormResponse" */
export type CustomFormResponseReplicatedRowsArgs = {
  distinct_on?: InputMaybe<Array<CustomFormReplicatedRow_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CustomFormReplicatedRow_Order_By>>;
  where?: InputMaybe<CustomFormReplicatedRow_Bool_Exp>;
};


/** columns and relationships of "customFormResponse" */
export type CustomFormResponseReplicatedRows_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CustomFormReplicatedRow_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CustomFormReplicatedRow_Order_By>>;
  where?: InputMaybe<CustomFormReplicatedRow_Bool_Exp>;
};


/** columns and relationships of "customFormResponse" */
export type CustomFormResponseSurveyJsDataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "customFormResponse" */
export type CustomFormResponse_Aggregate = {
  __typename?: 'customFormResponse_aggregate';
  aggregate?: Maybe<CustomFormResponse_Aggregate_Fields>;
  nodes: Array<CustomFormResponse>;
};

export type CustomFormResponse_Aggregate_Bool_Exp = {
  count?: InputMaybe<CustomFormResponse_Aggregate_Bool_Exp_Count>;
};

export type CustomFormResponse_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<CustomFormResponse_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<CustomFormResponse_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "customFormResponse" */
export type CustomFormResponse_Aggregate_Fields = {
  __typename?: 'customFormResponse_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<CustomFormResponse_Max_Fields>;
  min?: Maybe<CustomFormResponse_Min_Fields>;
};


/** aggregate fields of "customFormResponse" */
export type CustomFormResponse_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<CustomFormResponse_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "customFormResponse" */
export type CustomFormResponse_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<CustomFormResponse_Max_Order_By>;
  min?: InputMaybe<CustomFormResponse_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type CustomFormResponse_Append_Input = {
  surveyJSData?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "customFormResponse" */
export type CustomFormResponse_Arr_Rel_Insert_Input = {
  data: Array<CustomFormResponse_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<CustomFormResponse_On_Conflict>;
};

/** Boolean expression to filter rows from the table "customFormResponse". All fields are combined with a logical 'AND'. */
export type CustomFormResponse_Bool_Exp = {
  _and?: InputMaybe<Array<CustomFormResponse_Bool_Exp>>;
  _not?: InputMaybe<CustomFormResponse_Bool_Exp>;
  _or?: InputMaybe<Array<CustomFormResponse_Bool_Exp>>;
  appointmentId?: InputMaybe<Uuid_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  customFormId?: InputMaybe<Uuid_Comparison_Exp>;
  document?: InputMaybe<Document_Bool_Exp>;
  documentId?: InputMaybe<Uuid_Comparison_Exp>;
  fieldResponses?: InputMaybe<CustomFormFieldResponse_Bool_Exp>;
  fieldResponses_aggregate?: InputMaybe<CustomFormFieldResponse_Aggregate_Bool_Exp>;
  form?: InputMaybe<CustomForm_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  patientId?: InputMaybe<Uuid_Comparison_Exp>;
  replicatedRows?: InputMaybe<CustomFormReplicatedRow_Bool_Exp>;
  replicatedRows_aggregate?: InputMaybe<CustomFormReplicatedRow_Aggregate_Bool_Exp>;
  surveyJSData?: InputMaybe<Jsonb_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "customFormResponse" */
export enum CustomFormResponse_Constraint {
  /** unique or primary key constraint on columns "customFormId", "appointmentId" */
  CustomFormResponseCustomFormIdAppointmentIdKey = 'customFormResponse_customFormId_appointmentId_key',
  /** unique or primary key constraint on columns "id" */
  CustomFormResponsePkey = 'customFormResponse_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type CustomFormResponse_Delete_At_Path_Input = {
  surveyJSData?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type CustomFormResponse_Delete_Elem_Input = {
  surveyJSData?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type CustomFormResponse_Delete_Key_Input = {
  surveyJSData?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "customFormResponse" */
export type CustomFormResponse_Insert_Input = {
  appointmentId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  customFormId?: InputMaybe<Scalars['uuid']>;
  document?: InputMaybe<Document_Obj_Rel_Insert_Input>;
  documentId?: InputMaybe<Scalars['uuid']>;
  fieldResponses?: InputMaybe<CustomFormFieldResponse_Arr_Rel_Insert_Input>;
  form?: InputMaybe<CustomForm_Obj_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']>;
  patientId?: InputMaybe<Scalars['uuid']>;
  replicatedRows?: InputMaybe<CustomFormReplicatedRow_Arr_Rel_Insert_Input>;
  surveyJSData?: InputMaybe<Scalars['jsonb']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type CustomFormResponse_Max_Fields = {
  __typename?: 'customFormResponse_max_fields';
  appointmentId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  customFormId?: Maybe<Scalars['uuid']>;
  documentId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  patientId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "customFormResponse" */
export type CustomFormResponse_Max_Order_By = {
  appointmentId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  customFormId?: InputMaybe<Order_By>;
  documentId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patientId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type CustomFormResponse_Min_Fields = {
  __typename?: 'customFormResponse_min_fields';
  appointmentId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  customFormId?: Maybe<Scalars['uuid']>;
  documentId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  patientId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "customFormResponse" */
export type CustomFormResponse_Min_Order_By = {
  appointmentId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  customFormId?: InputMaybe<Order_By>;
  documentId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patientId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "customFormResponse" */
export type CustomFormResponse_Mutation_Response = {
  __typename?: 'customFormResponse_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CustomFormResponse>;
};

/** input type for inserting object relation for remote table "customFormResponse" */
export type CustomFormResponse_Obj_Rel_Insert_Input = {
  data: CustomFormResponse_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<CustomFormResponse_On_Conflict>;
};

/** on_conflict condition type for table "customFormResponse" */
export type CustomFormResponse_On_Conflict = {
  constraint: CustomFormResponse_Constraint;
  update_columns?: Array<CustomFormResponse_Update_Column>;
  where?: InputMaybe<CustomFormResponse_Bool_Exp>;
};

/** Ordering options when selecting data from "customFormResponse". */
export type CustomFormResponse_Order_By = {
  appointmentId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  customFormId?: InputMaybe<Order_By>;
  document?: InputMaybe<Document_Order_By>;
  documentId?: InputMaybe<Order_By>;
  fieldResponses_aggregate?: InputMaybe<CustomFormFieldResponse_Aggregate_Order_By>;
  form?: InputMaybe<CustomForm_Order_By>;
  id?: InputMaybe<Order_By>;
  patientId?: InputMaybe<Order_By>;
  replicatedRows_aggregate?: InputMaybe<CustomFormReplicatedRow_Aggregate_Order_By>;
  surveyJSData?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: customFormResponse */
export type CustomFormResponse_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type CustomFormResponse_Prepend_Input = {
  surveyJSData?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "customFormResponse" */
export enum CustomFormResponse_Select_Column {
  /** column name */
  AppointmentId = 'appointmentId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CustomFormId = 'customFormId',
  /** column name */
  DocumentId = 'documentId',
  /** column name */
  Id = 'id',
  /** column name */
  PatientId = 'patientId',
  /** column name */
  SurveyJsData = 'surveyJSData',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "customFormResponse" */
export type CustomFormResponse_Set_Input = {
  appointmentId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  customFormId?: InputMaybe<Scalars['uuid']>;
  documentId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  patientId?: InputMaybe<Scalars['uuid']>;
  surveyJSData?: InputMaybe<Scalars['jsonb']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "customFormResponse" */
export type CustomFormResponse_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: CustomFormResponse_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type CustomFormResponse_Stream_Cursor_Value_Input = {
  appointmentId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  customFormId?: InputMaybe<Scalars['uuid']>;
  documentId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  patientId?: InputMaybe<Scalars['uuid']>;
  surveyJSData?: InputMaybe<Scalars['jsonb']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "customFormResponse" */
export enum CustomFormResponse_Update_Column {
  /** column name */
  AppointmentId = 'appointmentId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CustomFormId = 'customFormId',
  /** column name */
  DocumentId = 'documentId',
  /** column name */
  Id = 'id',
  /** column name */
  PatientId = 'patientId',
  /** column name */
  SurveyJsData = 'surveyJSData',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type CustomFormResponse_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<CustomFormResponse_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<CustomFormResponse_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<CustomFormResponse_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<CustomFormResponse_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<CustomFormResponse_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CustomFormResponse_Set_Input>;
  /** filter the rows which have to be updated */
  where: CustomFormResponse_Bool_Exp;
};

/** columns and relationships of "customFormRow" */
export type CustomFormRow = {
  __typename?: 'customFormRow';
  apiFieldGroupKey?: Maybe<Scalars['String']>;
  createdAt: Scalars['timestamptz'];
  customFormId: Scalars['uuid'];
  /** An array relationship */
  fields: Array<CustomFormField>;
  /** An aggregate relationship */
  fields_aggregate: CustomFormField_Aggregate;
  /** An object relationship */
  form: CustomForm;
  id: Scalars['uuid'];
  index: Scalars['Int'];
  replicable?: Maybe<Scalars['Boolean']>;
  updatedAt: Scalars['timestamptz'];
};


/** columns and relationships of "customFormRow" */
export type CustomFormRowFieldsArgs = {
  distinct_on?: InputMaybe<Array<CustomFormField_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CustomFormField_Order_By>>;
  where?: InputMaybe<CustomFormField_Bool_Exp>;
};


/** columns and relationships of "customFormRow" */
export type CustomFormRowFields_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CustomFormField_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CustomFormField_Order_By>>;
  where?: InputMaybe<CustomFormField_Bool_Exp>;
};

/** aggregated selection of "customFormRow" */
export type CustomFormRow_Aggregate = {
  __typename?: 'customFormRow_aggregate';
  aggregate?: Maybe<CustomFormRow_Aggregate_Fields>;
  nodes: Array<CustomFormRow>;
};

export type CustomFormRow_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<CustomFormRow_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<CustomFormRow_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<CustomFormRow_Aggregate_Bool_Exp_Count>;
};

export type CustomFormRow_Aggregate_Bool_Exp_Bool_And = {
  arguments: CustomFormRow_Select_Column_CustomFormRow_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<CustomFormRow_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type CustomFormRow_Aggregate_Bool_Exp_Bool_Or = {
  arguments: CustomFormRow_Select_Column_CustomFormRow_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<CustomFormRow_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type CustomFormRow_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<CustomFormRow_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<CustomFormRow_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "customFormRow" */
export type CustomFormRow_Aggregate_Fields = {
  __typename?: 'customFormRow_aggregate_fields';
  avg?: Maybe<CustomFormRow_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<CustomFormRow_Max_Fields>;
  min?: Maybe<CustomFormRow_Min_Fields>;
  stddev?: Maybe<CustomFormRow_Stddev_Fields>;
  stddev_pop?: Maybe<CustomFormRow_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<CustomFormRow_Stddev_Samp_Fields>;
  sum?: Maybe<CustomFormRow_Sum_Fields>;
  var_pop?: Maybe<CustomFormRow_Var_Pop_Fields>;
  var_samp?: Maybe<CustomFormRow_Var_Samp_Fields>;
  variance?: Maybe<CustomFormRow_Variance_Fields>;
};


/** aggregate fields of "customFormRow" */
export type CustomFormRow_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<CustomFormRow_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "customFormRow" */
export type CustomFormRow_Aggregate_Order_By = {
  avg?: InputMaybe<CustomFormRow_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<CustomFormRow_Max_Order_By>;
  min?: InputMaybe<CustomFormRow_Min_Order_By>;
  stddev?: InputMaybe<CustomFormRow_Stddev_Order_By>;
  stddev_pop?: InputMaybe<CustomFormRow_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<CustomFormRow_Stddev_Samp_Order_By>;
  sum?: InputMaybe<CustomFormRow_Sum_Order_By>;
  var_pop?: InputMaybe<CustomFormRow_Var_Pop_Order_By>;
  var_samp?: InputMaybe<CustomFormRow_Var_Samp_Order_By>;
  variance?: InputMaybe<CustomFormRow_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "customFormRow" */
export type CustomFormRow_Arr_Rel_Insert_Input = {
  data: Array<CustomFormRow_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<CustomFormRow_On_Conflict>;
};

/** aggregate avg on columns */
export type CustomFormRow_Avg_Fields = {
  __typename?: 'customFormRow_avg_fields';
  index?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "customFormRow" */
export type CustomFormRow_Avg_Order_By = {
  index?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "customFormRow". All fields are combined with a logical 'AND'. */
export type CustomFormRow_Bool_Exp = {
  _and?: InputMaybe<Array<CustomFormRow_Bool_Exp>>;
  _not?: InputMaybe<CustomFormRow_Bool_Exp>;
  _or?: InputMaybe<Array<CustomFormRow_Bool_Exp>>;
  apiFieldGroupKey?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  customFormId?: InputMaybe<Uuid_Comparison_Exp>;
  fields?: InputMaybe<CustomFormField_Bool_Exp>;
  fields_aggregate?: InputMaybe<CustomFormField_Aggregate_Bool_Exp>;
  form?: InputMaybe<CustomForm_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  index?: InputMaybe<Int_Comparison_Exp>;
  replicable?: InputMaybe<Boolean_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "customFormRow" */
export enum CustomFormRow_Constraint {
  /** unique or primary key constraint on columns "id" */
  CustomFormRowPkey = 'customFormRow_pkey'
}

/** input type for incrementing numeric columns in table "customFormRow" */
export type CustomFormRow_Inc_Input = {
  index?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "customFormRow" */
export type CustomFormRow_Insert_Input = {
  apiFieldGroupKey?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  customFormId?: InputMaybe<Scalars['uuid']>;
  fields?: InputMaybe<CustomFormField_Arr_Rel_Insert_Input>;
  form?: InputMaybe<CustomForm_Obj_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']>;
  index?: InputMaybe<Scalars['Int']>;
  replicable?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type CustomFormRow_Max_Fields = {
  __typename?: 'customFormRow_max_fields';
  apiFieldGroupKey?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  customFormId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  index?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "customFormRow" */
export type CustomFormRow_Max_Order_By = {
  apiFieldGroupKey?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  customFormId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  index?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type CustomFormRow_Min_Fields = {
  __typename?: 'customFormRow_min_fields';
  apiFieldGroupKey?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  customFormId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  index?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "customFormRow" */
export type CustomFormRow_Min_Order_By = {
  apiFieldGroupKey?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  customFormId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  index?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "customFormRow" */
export type CustomFormRow_Mutation_Response = {
  __typename?: 'customFormRow_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CustomFormRow>;
};

/** input type for inserting object relation for remote table "customFormRow" */
export type CustomFormRow_Obj_Rel_Insert_Input = {
  data: CustomFormRow_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<CustomFormRow_On_Conflict>;
};

/** on_conflict condition type for table "customFormRow" */
export type CustomFormRow_On_Conflict = {
  constraint: CustomFormRow_Constraint;
  update_columns?: Array<CustomFormRow_Update_Column>;
  where?: InputMaybe<CustomFormRow_Bool_Exp>;
};

/** Ordering options when selecting data from "customFormRow". */
export type CustomFormRow_Order_By = {
  apiFieldGroupKey?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  customFormId?: InputMaybe<Order_By>;
  fields_aggregate?: InputMaybe<CustomFormField_Aggregate_Order_By>;
  form?: InputMaybe<CustomForm_Order_By>;
  id?: InputMaybe<Order_By>;
  index?: InputMaybe<Order_By>;
  replicable?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: customFormRow */
export type CustomFormRow_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "customFormRow" */
export enum CustomFormRow_Select_Column {
  /** column name */
  ApiFieldGroupKey = 'apiFieldGroupKey',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CustomFormId = 'customFormId',
  /** column name */
  Id = 'id',
  /** column name */
  Index = 'index',
  /** column name */
  Replicable = 'replicable',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** select "customFormRow_aggregate_bool_exp_bool_and_arguments_columns" columns of table "customFormRow" */
export enum CustomFormRow_Select_Column_CustomFormRow_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Replicable = 'replicable'
}

/** select "customFormRow_aggregate_bool_exp_bool_or_arguments_columns" columns of table "customFormRow" */
export enum CustomFormRow_Select_Column_CustomFormRow_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Replicable = 'replicable'
}

/** input type for updating data in table "customFormRow" */
export type CustomFormRow_Set_Input = {
  apiFieldGroupKey?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  customFormId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  index?: InputMaybe<Scalars['Int']>;
  replicable?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type CustomFormRow_Stddev_Fields = {
  __typename?: 'customFormRow_stddev_fields';
  index?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "customFormRow" */
export type CustomFormRow_Stddev_Order_By = {
  index?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type CustomFormRow_Stddev_Pop_Fields = {
  __typename?: 'customFormRow_stddev_pop_fields';
  index?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "customFormRow" */
export type CustomFormRow_Stddev_Pop_Order_By = {
  index?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type CustomFormRow_Stddev_Samp_Fields = {
  __typename?: 'customFormRow_stddev_samp_fields';
  index?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "customFormRow" */
export type CustomFormRow_Stddev_Samp_Order_By = {
  index?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "customFormRow" */
export type CustomFormRow_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: CustomFormRow_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type CustomFormRow_Stream_Cursor_Value_Input = {
  apiFieldGroupKey?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  customFormId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  index?: InputMaybe<Scalars['Int']>;
  replicable?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type CustomFormRow_Sum_Fields = {
  __typename?: 'customFormRow_sum_fields';
  index?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "customFormRow" */
export type CustomFormRow_Sum_Order_By = {
  index?: InputMaybe<Order_By>;
};

/** update columns of table "customFormRow" */
export enum CustomFormRow_Update_Column {
  /** column name */
  ApiFieldGroupKey = 'apiFieldGroupKey',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CustomFormId = 'customFormId',
  /** column name */
  Id = 'id',
  /** column name */
  Index = 'index',
  /** column name */
  Replicable = 'replicable',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type CustomFormRow_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<CustomFormRow_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CustomFormRow_Set_Input>;
  /** filter the rows which have to be updated */
  where: CustomFormRow_Bool_Exp;
};

/** aggregate var_pop on columns */
export type CustomFormRow_Var_Pop_Fields = {
  __typename?: 'customFormRow_var_pop_fields';
  index?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "customFormRow" */
export type CustomFormRow_Var_Pop_Order_By = {
  index?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type CustomFormRow_Var_Samp_Fields = {
  __typename?: 'customFormRow_var_samp_fields';
  index?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "customFormRow" */
export type CustomFormRow_Var_Samp_Order_By = {
  index?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type CustomFormRow_Variance_Fields = {
  __typename?: 'customFormRow_variance_fields';
  index?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "customFormRow" */
export type CustomFormRow_Variance_Order_By = {
  index?: InputMaybe<Order_By>;
};

/** aggregated selection of "customForm" */
export type CustomForm_Aggregate = {
  __typename?: 'customForm_aggregate';
  aggregate?: Maybe<CustomForm_Aggregate_Fields>;
  nodes: Array<CustomForm>;
};

/** aggregate fields of "customForm" */
export type CustomForm_Aggregate_Fields = {
  __typename?: 'customForm_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<CustomForm_Max_Fields>;
  min?: Maybe<CustomForm_Min_Fields>;
};


/** aggregate fields of "customForm" */
export type CustomForm_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<CustomForm_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type CustomForm_Append_Input = {
  surveyJSJSON?: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "customForm". All fields are combined with a logical 'AND'. */
export type CustomForm_Bool_Exp = {
  _and?: InputMaybe<Array<CustomForm_Bool_Exp>>;
  _not?: InputMaybe<CustomForm_Bool_Exp>;
  _or?: InputMaybe<Array<CustomForm_Bool_Exp>>;
  allFieldsRequired?: InputMaybe<Boolean_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  isRequired?: InputMaybe<Boolean_Comparison_Exp>;
  language?: InputMaybe<String_Comparison_Exp>;
  recurrence?: InputMaybe<String_Comparison_Exp>;
  rows?: InputMaybe<CustomFormRow_Bool_Exp>;
  rows_aggregate?: InputMaybe<CustomFormRow_Aggregate_Bool_Exp>;
  serviceCustomForms?: InputMaybe<ServiceCustomForm_Bool_Exp>;
  serviceCustomForms_aggregate?: InputMaybe<ServiceCustomForm_Aggregate_Bool_Exp>;
  surveyJSJSON?: InputMaybe<Jsonb_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  userId?: InputMaybe<Uuid_Comparison_Exp>;
  workspaceId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "customForm" */
export enum CustomForm_Constraint {
  /** unique or primary key constraint on columns "id" */
  CustomFormPkey = 'customForm_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type CustomForm_Delete_At_Path_Input = {
  surveyJSJSON?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type CustomForm_Delete_Elem_Input = {
  surveyJSJSON?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type CustomForm_Delete_Key_Input = {
  surveyJSJSON?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "customForm" */
export type CustomForm_Insert_Input = {
  allFieldsRequired?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  isRequired?: InputMaybe<Scalars['Boolean']>;
  language?: InputMaybe<Scalars['String']>;
  recurrence?: InputMaybe<Scalars['String']>;
  rows?: InputMaybe<CustomFormRow_Arr_Rel_Insert_Input>;
  serviceCustomForms?: InputMaybe<ServiceCustomForm_Arr_Rel_Insert_Input>;
  surveyJSJSON?: InputMaybe<Scalars['jsonb']>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  userId?: InputMaybe<Scalars['uuid']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type CustomForm_Max_Fields = {
  __typename?: 'customForm_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  language?: Maybe<Scalars['String']>;
  recurrence?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['uuid']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type CustomForm_Min_Fields = {
  __typename?: 'customForm_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  language?: Maybe<Scalars['String']>;
  recurrence?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['uuid']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "customForm" */
export type CustomForm_Mutation_Response = {
  __typename?: 'customForm_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CustomForm>;
};

/** input type for inserting object relation for remote table "customForm" */
export type CustomForm_Obj_Rel_Insert_Input = {
  data: CustomForm_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<CustomForm_On_Conflict>;
};

/** on_conflict condition type for table "customForm" */
export type CustomForm_On_Conflict = {
  constraint: CustomForm_Constraint;
  update_columns?: Array<CustomForm_Update_Column>;
  where?: InputMaybe<CustomForm_Bool_Exp>;
};

/** Ordering options when selecting data from "customForm". */
export type CustomForm_Order_By = {
  allFieldsRequired?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  isRequired?: InputMaybe<Order_By>;
  language?: InputMaybe<Order_By>;
  recurrence?: InputMaybe<Order_By>;
  rows_aggregate?: InputMaybe<CustomFormRow_Aggregate_Order_By>;
  serviceCustomForms_aggregate?: InputMaybe<ServiceCustomForm_Aggregate_Order_By>;
  surveyJSJSON?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: customForm */
export type CustomForm_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type CustomForm_Prepend_Input = {
  surveyJSJSON?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "customForm" */
export enum CustomForm_Select_Column {
  /** column name */
  AllFieldsRequired = 'allFieldsRequired',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  IsRequired = 'isRequired',
  /** column name */
  Language = 'language',
  /** column name */
  Recurrence = 'recurrence',
  /** column name */
  SurveyJsjson = 'surveyJSJSON',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId',
  /** column name */
  WorkspaceId = 'workspaceId'
}

/** input type for updating data in table "customForm" */
export type CustomForm_Set_Input = {
  allFieldsRequired?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  isRequired?: InputMaybe<Scalars['Boolean']>;
  language?: InputMaybe<Scalars['String']>;
  recurrence?: InputMaybe<Scalars['String']>;
  surveyJSJSON?: InputMaybe<Scalars['jsonb']>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  userId?: InputMaybe<Scalars['uuid']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "customForm" */
export type CustomForm_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: CustomForm_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type CustomForm_Stream_Cursor_Value_Input = {
  allFieldsRequired?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  isRequired?: InputMaybe<Scalars['Boolean']>;
  language?: InputMaybe<Scalars['String']>;
  recurrence?: InputMaybe<Scalars['String']>;
  surveyJSJSON?: InputMaybe<Scalars['jsonb']>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  userId?: InputMaybe<Scalars['uuid']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "customForm" */
export enum CustomForm_Update_Column {
  /** column name */
  AllFieldsRequired = 'allFieldsRequired',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  IsRequired = 'isRequired',
  /** column name */
  Language = 'language',
  /** column name */
  Recurrence = 'recurrence',
  /** column name */
  SurveyJsjson = 'surveyJSJSON',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId',
  /** column name */
  WorkspaceId = 'workspaceId'
}

export type CustomForm_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<CustomForm_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<CustomForm_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<CustomForm_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<CustomForm_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<CustomForm_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CustomForm_Set_Input>;
  /** filter the rows which have to be updated */
  where: CustomForm_Bool_Exp;
};

/** columns and relationships of "customQuestion" */
export type CustomQuestion = {
  __typename?: 'customQuestion';
  body?: Maybe<Scalars['String']>;
  category?: Maybe<CustomFormFieldCategory_Enum>;
  /** An object relationship */
  categoryValueAndDescription?: Maybe<CustomFormFieldCategory>;
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  label: Scalars['String'];
  /** An array relationship */
  options: Array<CustomQuestionOption>;
  /** An aggregate relationship */
  options_aggregate: CustomQuestionOption_Aggregate;
  /** An object relationship */
  photo?: Maybe<Photo>;
  photoId?: Maybe<Scalars['uuid']>;
  type: CustomFormFieldType_Enum;
  /** An object relationship */
  typeValueAndDescription: CustomFormFieldType;
  updatedAt: Scalars['timestamptz'];
  userId: Scalars['uuid'];
  workspaceId: Scalars['uuid'];
};


/** columns and relationships of "customQuestion" */
export type CustomQuestionOptionsArgs = {
  distinct_on?: InputMaybe<Array<CustomQuestionOption_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CustomQuestionOption_Order_By>>;
  where?: InputMaybe<CustomQuestionOption_Bool_Exp>;
};


/** columns and relationships of "customQuestion" */
export type CustomQuestionOptions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CustomQuestionOption_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CustomQuestionOption_Order_By>>;
  where?: InputMaybe<CustomQuestionOption_Bool_Exp>;
};

/** columns and relationships of "customQuestionOption" */
export type CustomQuestionOption = {
  __typename?: 'customQuestionOption';
  createdAt: Scalars['timestamptz'];
  customQuestionId: Scalars['uuid'];
  id: Scalars['uuid'];
  /** An object relationship */
  question: CustomQuestion;
  updatedAt: Scalars['timestamptz'];
  value: Scalars['String'];
};

/** aggregated selection of "customQuestionOption" */
export type CustomQuestionOption_Aggregate = {
  __typename?: 'customQuestionOption_aggregate';
  aggregate?: Maybe<CustomQuestionOption_Aggregate_Fields>;
  nodes: Array<CustomQuestionOption>;
};

export type CustomQuestionOption_Aggregate_Bool_Exp = {
  count?: InputMaybe<CustomQuestionOption_Aggregate_Bool_Exp_Count>;
};

export type CustomQuestionOption_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<CustomQuestionOption_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<CustomQuestionOption_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "customQuestionOption" */
export type CustomQuestionOption_Aggregate_Fields = {
  __typename?: 'customQuestionOption_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<CustomQuestionOption_Max_Fields>;
  min?: Maybe<CustomQuestionOption_Min_Fields>;
};


/** aggregate fields of "customQuestionOption" */
export type CustomQuestionOption_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<CustomQuestionOption_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "customQuestionOption" */
export type CustomQuestionOption_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<CustomQuestionOption_Max_Order_By>;
  min?: InputMaybe<CustomQuestionOption_Min_Order_By>;
};

/** input type for inserting array relation for remote table "customQuestionOption" */
export type CustomQuestionOption_Arr_Rel_Insert_Input = {
  data: Array<CustomQuestionOption_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<CustomQuestionOption_On_Conflict>;
};

/** Boolean expression to filter rows from the table "customQuestionOption". All fields are combined with a logical 'AND'. */
export type CustomQuestionOption_Bool_Exp = {
  _and?: InputMaybe<Array<CustomQuestionOption_Bool_Exp>>;
  _not?: InputMaybe<CustomQuestionOption_Bool_Exp>;
  _or?: InputMaybe<Array<CustomQuestionOption_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  customQuestionId?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  question?: InputMaybe<CustomQuestion_Bool_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "customQuestionOption" */
export enum CustomQuestionOption_Constraint {
  /** unique or primary key constraint on columns "id" */
  CustomQuestionOptionPkey = 'customQuestionOption_pkey'
}

/** input type for inserting data into table "customQuestionOption" */
export type CustomQuestionOption_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  customQuestionId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  question?: InputMaybe<CustomQuestion_Obj_Rel_Insert_Input>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type CustomQuestionOption_Max_Fields = {
  __typename?: 'customQuestionOption_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  customQuestionId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "customQuestionOption" */
export type CustomQuestionOption_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  customQuestionId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type CustomQuestionOption_Min_Fields = {
  __typename?: 'customQuestionOption_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  customQuestionId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "customQuestionOption" */
export type CustomQuestionOption_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  customQuestionId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "customQuestionOption" */
export type CustomQuestionOption_Mutation_Response = {
  __typename?: 'customQuestionOption_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CustomQuestionOption>;
};

/** on_conflict condition type for table "customQuestionOption" */
export type CustomQuestionOption_On_Conflict = {
  constraint: CustomQuestionOption_Constraint;
  update_columns?: Array<CustomQuestionOption_Update_Column>;
  where?: InputMaybe<CustomQuestionOption_Bool_Exp>;
};

/** Ordering options when selecting data from "customQuestionOption". */
export type CustomQuestionOption_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  customQuestionId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  question?: InputMaybe<CustomQuestion_Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: customQuestionOption */
export type CustomQuestionOption_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "customQuestionOption" */
export enum CustomQuestionOption_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CustomQuestionId = 'customQuestionId',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "customQuestionOption" */
export type CustomQuestionOption_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  customQuestionId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "customQuestionOption" */
export type CustomQuestionOption_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: CustomQuestionOption_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type CustomQuestionOption_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  customQuestionId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "customQuestionOption" */
export enum CustomQuestionOption_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CustomQuestionId = 'customQuestionId',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Value = 'value'
}

export type CustomQuestionOption_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CustomQuestionOption_Set_Input>;
  /** filter the rows which have to be updated */
  where: CustomQuestionOption_Bool_Exp;
};

/** aggregated selection of "customQuestion" */
export type CustomQuestion_Aggregate = {
  __typename?: 'customQuestion_aggregate';
  aggregate?: Maybe<CustomQuestion_Aggregate_Fields>;
  nodes: Array<CustomQuestion>;
};

/** aggregate fields of "customQuestion" */
export type CustomQuestion_Aggregate_Fields = {
  __typename?: 'customQuestion_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<CustomQuestion_Max_Fields>;
  min?: Maybe<CustomQuestion_Min_Fields>;
};


/** aggregate fields of "customQuestion" */
export type CustomQuestion_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<CustomQuestion_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "customQuestion". All fields are combined with a logical 'AND'. */
export type CustomQuestion_Bool_Exp = {
  _and?: InputMaybe<Array<CustomQuestion_Bool_Exp>>;
  _not?: InputMaybe<CustomQuestion_Bool_Exp>;
  _or?: InputMaybe<Array<CustomQuestion_Bool_Exp>>;
  body?: InputMaybe<String_Comparison_Exp>;
  category?: InputMaybe<CustomFormFieldCategory_Enum_Comparison_Exp>;
  categoryValueAndDescription?: InputMaybe<CustomFormFieldCategory_Bool_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  label?: InputMaybe<String_Comparison_Exp>;
  options?: InputMaybe<CustomQuestionOption_Bool_Exp>;
  options_aggregate?: InputMaybe<CustomQuestionOption_Aggregate_Bool_Exp>;
  photo?: InputMaybe<Photo_Bool_Exp>;
  photoId?: InputMaybe<Uuid_Comparison_Exp>;
  type?: InputMaybe<CustomFormFieldType_Enum_Comparison_Exp>;
  typeValueAndDescription?: InputMaybe<CustomFormFieldType_Bool_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  userId?: InputMaybe<Uuid_Comparison_Exp>;
  workspaceId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "customQuestion" */
export enum CustomQuestion_Constraint {
  /** unique or primary key constraint on columns "label", "workspaceId" */
  CustomQuestionLabelWorkspaceIdKey = 'customQuestion_label_workspaceId_key',
  /** unique or primary key constraint on columns "id" */
  CustomQuestionPkey = 'customQuestion_pkey'
}

/** input type for inserting data into table "customQuestion" */
export type CustomQuestion_Insert_Input = {
  body?: InputMaybe<Scalars['String']>;
  category?: InputMaybe<CustomFormFieldCategory_Enum>;
  categoryValueAndDescription?: InputMaybe<CustomFormFieldCategory_Obj_Rel_Insert_Input>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  label?: InputMaybe<Scalars['String']>;
  options?: InputMaybe<CustomQuestionOption_Arr_Rel_Insert_Input>;
  photo?: InputMaybe<Photo_Obj_Rel_Insert_Input>;
  photoId?: InputMaybe<Scalars['uuid']>;
  type?: InputMaybe<CustomFormFieldType_Enum>;
  typeValueAndDescription?: InputMaybe<CustomFormFieldType_Obj_Rel_Insert_Input>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  userId?: InputMaybe<Scalars['uuid']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type CustomQuestion_Max_Fields = {
  __typename?: 'customQuestion_max_fields';
  body?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  label?: Maybe<Scalars['String']>;
  photoId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['uuid']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type CustomQuestion_Min_Fields = {
  __typename?: 'customQuestion_min_fields';
  body?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  label?: Maybe<Scalars['String']>;
  photoId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['uuid']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "customQuestion" */
export type CustomQuestion_Mutation_Response = {
  __typename?: 'customQuestion_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CustomQuestion>;
};

/** input type for inserting object relation for remote table "customQuestion" */
export type CustomQuestion_Obj_Rel_Insert_Input = {
  data: CustomQuestion_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<CustomQuestion_On_Conflict>;
};

/** on_conflict condition type for table "customQuestion" */
export type CustomQuestion_On_Conflict = {
  constraint: CustomQuestion_Constraint;
  update_columns?: Array<CustomQuestion_Update_Column>;
  where?: InputMaybe<CustomQuestion_Bool_Exp>;
};

/** Ordering options when selecting data from "customQuestion". */
export type CustomQuestion_Order_By = {
  body?: InputMaybe<Order_By>;
  category?: InputMaybe<Order_By>;
  categoryValueAndDescription?: InputMaybe<CustomFormFieldCategory_Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  label?: InputMaybe<Order_By>;
  options_aggregate?: InputMaybe<CustomQuestionOption_Aggregate_Order_By>;
  photo?: InputMaybe<Photo_Order_By>;
  photoId?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  typeValueAndDescription?: InputMaybe<CustomFormFieldType_Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: customQuestion */
export type CustomQuestion_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "customQuestion" */
export enum CustomQuestion_Select_Column {
  /** column name */
  Body = 'body',
  /** column name */
  Category = 'category',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Label = 'label',
  /** column name */
  PhotoId = 'photoId',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId',
  /** column name */
  WorkspaceId = 'workspaceId'
}

/** input type for updating data in table "customQuestion" */
export type CustomQuestion_Set_Input = {
  body?: InputMaybe<Scalars['String']>;
  category?: InputMaybe<CustomFormFieldCategory_Enum>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  label?: InputMaybe<Scalars['String']>;
  photoId?: InputMaybe<Scalars['uuid']>;
  type?: InputMaybe<CustomFormFieldType_Enum>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  userId?: InputMaybe<Scalars['uuid']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "customQuestion" */
export type CustomQuestion_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: CustomQuestion_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type CustomQuestion_Stream_Cursor_Value_Input = {
  body?: InputMaybe<Scalars['String']>;
  category?: InputMaybe<CustomFormFieldCategory_Enum>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  label?: InputMaybe<Scalars['String']>;
  photoId?: InputMaybe<Scalars['uuid']>;
  type?: InputMaybe<CustomFormFieldType_Enum>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  userId?: InputMaybe<Scalars['uuid']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "customQuestion" */
export enum CustomQuestion_Update_Column {
  /** column name */
  Body = 'body',
  /** column name */
  Category = 'category',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Label = 'label',
  /** column name */
  PhotoId = 'photoId',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId',
  /** column name */
  WorkspaceId = 'workspaceId'
}

export type CustomQuestion_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CustomQuestion_Set_Input>;
  /** filter the rows which have to be updated */
  where: CustomQuestion_Bool_Exp;
};

/** Boolean expression to compare columns of type "date". All fields are combined with logical 'AND'. */
export type Date_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['date']>;
  _gt?: InputMaybe<Scalars['date']>;
  _gte?: InputMaybe<Scalars['date']>;
  _in?: InputMaybe<Array<Scalars['date']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['date']>;
  _lte?: InputMaybe<Scalars['date']>;
  _neq?: InputMaybe<Scalars['date']>;
  _nin?: InputMaybe<Array<Scalars['date']>>;
};

/** columns and relationships of "degreeDesignation" */
export type DegreeDesignation = {
  __typename?: 'degreeDesignation';
  description: Scalars['String'];
  /** An array relationship */
  providers: Array<Provider>;
  /** An aggregate relationship */
  providers_aggregate: Provider_Aggregate;
  value: Scalars['String'];
};


/** columns and relationships of "degreeDesignation" */
export type DegreeDesignationProvidersArgs = {
  distinct_on?: InputMaybe<Array<Provider_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Provider_Order_By>>;
  where?: InputMaybe<Provider_Bool_Exp>;
};


/** columns and relationships of "degreeDesignation" */
export type DegreeDesignationProviders_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Provider_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Provider_Order_By>>;
  where?: InputMaybe<Provider_Bool_Exp>;
};

/** aggregated selection of "degreeDesignation" */
export type DegreeDesignation_Aggregate = {
  __typename?: 'degreeDesignation_aggregate';
  aggregate?: Maybe<DegreeDesignation_Aggregate_Fields>;
  nodes: Array<DegreeDesignation>;
};

/** aggregate fields of "degreeDesignation" */
export type DegreeDesignation_Aggregate_Fields = {
  __typename?: 'degreeDesignation_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<DegreeDesignation_Max_Fields>;
  min?: Maybe<DegreeDesignation_Min_Fields>;
};


/** aggregate fields of "degreeDesignation" */
export type DegreeDesignation_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<DegreeDesignation_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "degreeDesignation". All fields are combined with a logical 'AND'. */
export type DegreeDesignation_Bool_Exp = {
  _and?: InputMaybe<Array<DegreeDesignation_Bool_Exp>>;
  _not?: InputMaybe<DegreeDesignation_Bool_Exp>;
  _or?: InputMaybe<Array<DegreeDesignation_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  providers?: InputMaybe<Provider_Bool_Exp>;
  providers_aggregate?: InputMaybe<Provider_Aggregate_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "degreeDesignation" */
export enum DegreeDesignation_Constraint {
  /** unique or primary key constraint on columns "value" */
  DegreeDesignationPkey = 'degreeDesignation_pkey'
}

export enum DegreeDesignation_Enum {
  /** M.D. */
  Md = 'MD'
}

/** Boolean expression to compare columns of type "degreeDesignation_enum". All fields are combined with logical 'AND'. */
export type DegreeDesignation_Enum_Comparison_Exp = {
  _eq?: InputMaybe<DegreeDesignation_Enum>;
  _in?: InputMaybe<Array<DegreeDesignation_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<DegreeDesignation_Enum>;
  _nin?: InputMaybe<Array<DegreeDesignation_Enum>>;
};

/** input type for inserting data into table "degreeDesignation" */
export type DegreeDesignation_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  providers?: InputMaybe<Provider_Arr_Rel_Insert_Input>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type DegreeDesignation_Max_Fields = {
  __typename?: 'degreeDesignation_max_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type DegreeDesignation_Min_Fields = {
  __typename?: 'degreeDesignation_min_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "degreeDesignation" */
export type DegreeDesignation_Mutation_Response = {
  __typename?: 'degreeDesignation_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<DegreeDesignation>;
};

/** input type for inserting object relation for remote table "degreeDesignation" */
export type DegreeDesignation_Obj_Rel_Insert_Input = {
  data: DegreeDesignation_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<DegreeDesignation_On_Conflict>;
};

/** on_conflict condition type for table "degreeDesignation" */
export type DegreeDesignation_On_Conflict = {
  constraint: DegreeDesignation_Constraint;
  update_columns?: Array<DegreeDesignation_Update_Column>;
  where?: InputMaybe<DegreeDesignation_Bool_Exp>;
};

/** Ordering options when selecting data from "degreeDesignation". */
export type DegreeDesignation_Order_By = {
  description?: InputMaybe<Order_By>;
  providers_aggregate?: InputMaybe<Provider_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: degreeDesignation */
export type DegreeDesignation_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "degreeDesignation" */
export enum DegreeDesignation_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "degreeDesignation" */
export type DegreeDesignation_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "degreeDesignation" */
export type DegreeDesignation_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: DegreeDesignation_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type DegreeDesignation_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "degreeDesignation" */
export enum DegreeDesignation_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

export type DegreeDesignation_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<DegreeDesignation_Set_Input>;
  /** filter the rows which have to be updated */
  where: DegreeDesignation_Bool_Exp;
};

/** columns and relationships of "depositInvoiceLineItem" */
export type DepositInvoiceLineItem = {
  __typename?: 'depositInvoiceLineItem';
  amount: Scalars['Int'];
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An object relationship */
  invoice: Invoice;
  invoiceId: Scalars['uuid'];
  note: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  /** An object relationship */
  workspace: Workspace;
  workspaceId: Scalars['uuid'];
};

/** aggregated selection of "depositInvoiceLineItem" */
export type DepositInvoiceLineItem_Aggregate = {
  __typename?: 'depositInvoiceLineItem_aggregate';
  aggregate?: Maybe<DepositInvoiceLineItem_Aggregate_Fields>;
  nodes: Array<DepositInvoiceLineItem>;
};

export type DepositInvoiceLineItem_Aggregate_Bool_Exp = {
  count?: InputMaybe<DepositInvoiceLineItem_Aggregate_Bool_Exp_Count>;
};

export type DepositInvoiceLineItem_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<DepositInvoiceLineItem_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DepositInvoiceLineItem_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "depositInvoiceLineItem" */
export type DepositInvoiceLineItem_Aggregate_Fields = {
  __typename?: 'depositInvoiceLineItem_aggregate_fields';
  avg?: Maybe<DepositInvoiceLineItem_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<DepositInvoiceLineItem_Max_Fields>;
  min?: Maybe<DepositInvoiceLineItem_Min_Fields>;
  stddev?: Maybe<DepositInvoiceLineItem_Stddev_Fields>;
  stddev_pop?: Maybe<DepositInvoiceLineItem_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<DepositInvoiceLineItem_Stddev_Samp_Fields>;
  sum?: Maybe<DepositInvoiceLineItem_Sum_Fields>;
  var_pop?: Maybe<DepositInvoiceLineItem_Var_Pop_Fields>;
  var_samp?: Maybe<DepositInvoiceLineItem_Var_Samp_Fields>;
  variance?: Maybe<DepositInvoiceLineItem_Variance_Fields>;
};


/** aggregate fields of "depositInvoiceLineItem" */
export type DepositInvoiceLineItem_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<DepositInvoiceLineItem_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "depositInvoiceLineItem" */
export type DepositInvoiceLineItem_Aggregate_Order_By = {
  avg?: InputMaybe<DepositInvoiceLineItem_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<DepositInvoiceLineItem_Max_Order_By>;
  min?: InputMaybe<DepositInvoiceLineItem_Min_Order_By>;
  stddev?: InputMaybe<DepositInvoiceLineItem_Stddev_Order_By>;
  stddev_pop?: InputMaybe<DepositInvoiceLineItem_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<DepositInvoiceLineItem_Stddev_Samp_Order_By>;
  sum?: InputMaybe<DepositInvoiceLineItem_Sum_Order_By>;
  var_pop?: InputMaybe<DepositInvoiceLineItem_Var_Pop_Order_By>;
  var_samp?: InputMaybe<DepositInvoiceLineItem_Var_Samp_Order_By>;
  variance?: InputMaybe<DepositInvoiceLineItem_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "depositInvoiceLineItem" */
export type DepositInvoiceLineItem_Arr_Rel_Insert_Input = {
  data: Array<DepositInvoiceLineItem_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<DepositInvoiceLineItem_On_Conflict>;
};

/** aggregate avg on columns */
export type DepositInvoiceLineItem_Avg_Fields = {
  __typename?: 'depositInvoiceLineItem_avg_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "depositInvoiceLineItem" */
export type DepositInvoiceLineItem_Avg_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "depositInvoiceLineItem". All fields are combined with a logical 'AND'. */
export type DepositInvoiceLineItem_Bool_Exp = {
  _and?: InputMaybe<Array<DepositInvoiceLineItem_Bool_Exp>>;
  _not?: InputMaybe<DepositInvoiceLineItem_Bool_Exp>;
  _or?: InputMaybe<Array<DepositInvoiceLineItem_Bool_Exp>>;
  amount?: InputMaybe<Int_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  invoice?: InputMaybe<Invoice_Bool_Exp>;
  invoiceId?: InputMaybe<Uuid_Comparison_Exp>;
  note?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  workspace?: InputMaybe<Workspace_Bool_Exp>;
  workspaceId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "depositInvoiceLineItem" */
export enum DepositInvoiceLineItem_Constraint {
  /** unique or primary key constraint on columns "id" */
  DepositInvoiceLineItemPkey = 'depositInvoiceLineItem_pkey'
}

/** input type for incrementing numeric columns in table "depositInvoiceLineItem" */
export type DepositInvoiceLineItem_Inc_Input = {
  amount?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "depositInvoiceLineItem" */
export type DepositInvoiceLineItem_Insert_Input = {
  amount?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  invoice?: InputMaybe<Invoice_Obj_Rel_Insert_Input>;
  invoiceId?: InputMaybe<Scalars['uuid']>;
  note?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspace?: InputMaybe<Workspace_Obj_Rel_Insert_Input>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type DepositInvoiceLineItem_Max_Fields = {
  __typename?: 'depositInvoiceLineItem_max_fields';
  amount?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  invoiceId?: Maybe<Scalars['uuid']>;
  note?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "depositInvoiceLineItem" */
export type DepositInvoiceLineItem_Max_Order_By = {
  amount?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoiceId?: InputMaybe<Order_By>;
  note?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type DepositInvoiceLineItem_Min_Fields = {
  __typename?: 'depositInvoiceLineItem_min_fields';
  amount?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  invoiceId?: Maybe<Scalars['uuid']>;
  note?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "depositInvoiceLineItem" */
export type DepositInvoiceLineItem_Min_Order_By = {
  amount?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoiceId?: InputMaybe<Order_By>;
  note?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "depositInvoiceLineItem" */
export type DepositInvoiceLineItem_Mutation_Response = {
  __typename?: 'depositInvoiceLineItem_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<DepositInvoiceLineItem>;
};

/** on_conflict condition type for table "depositInvoiceLineItem" */
export type DepositInvoiceLineItem_On_Conflict = {
  constraint: DepositInvoiceLineItem_Constraint;
  update_columns?: Array<DepositInvoiceLineItem_Update_Column>;
  where?: InputMaybe<DepositInvoiceLineItem_Bool_Exp>;
};

/** Ordering options when selecting data from "depositInvoiceLineItem". */
export type DepositInvoiceLineItem_Order_By = {
  amount?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoice?: InputMaybe<Invoice_Order_By>;
  invoiceId?: InputMaybe<Order_By>;
  note?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  workspace?: InputMaybe<Workspace_Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: depositInvoiceLineItem */
export type DepositInvoiceLineItem_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "depositInvoiceLineItem" */
export enum DepositInvoiceLineItem_Select_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  InvoiceId = 'invoiceId',
  /** column name */
  Note = 'note',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WorkspaceId = 'workspaceId'
}

/** input type for updating data in table "depositInvoiceLineItem" */
export type DepositInvoiceLineItem_Set_Input = {
  amount?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  invoiceId?: InputMaybe<Scalars['uuid']>;
  note?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type DepositInvoiceLineItem_Stddev_Fields = {
  __typename?: 'depositInvoiceLineItem_stddev_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "depositInvoiceLineItem" */
export type DepositInvoiceLineItem_Stddev_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type DepositInvoiceLineItem_Stddev_Pop_Fields = {
  __typename?: 'depositInvoiceLineItem_stddev_pop_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "depositInvoiceLineItem" */
export type DepositInvoiceLineItem_Stddev_Pop_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type DepositInvoiceLineItem_Stddev_Samp_Fields = {
  __typename?: 'depositInvoiceLineItem_stddev_samp_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "depositInvoiceLineItem" */
export type DepositInvoiceLineItem_Stddev_Samp_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "depositInvoiceLineItem" */
export type DepositInvoiceLineItem_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: DepositInvoiceLineItem_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type DepositInvoiceLineItem_Stream_Cursor_Value_Input = {
  amount?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  invoiceId?: InputMaybe<Scalars['uuid']>;
  note?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type DepositInvoiceLineItem_Sum_Fields = {
  __typename?: 'depositInvoiceLineItem_sum_fields';
  amount?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "depositInvoiceLineItem" */
export type DepositInvoiceLineItem_Sum_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** update columns of table "depositInvoiceLineItem" */
export enum DepositInvoiceLineItem_Update_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  InvoiceId = 'invoiceId',
  /** column name */
  Note = 'note',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WorkspaceId = 'workspaceId'
}

export type DepositInvoiceLineItem_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<DepositInvoiceLineItem_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<DepositInvoiceLineItem_Set_Input>;
  /** filter the rows which have to be updated */
  where: DepositInvoiceLineItem_Bool_Exp;
};

/** aggregate var_pop on columns */
export type DepositInvoiceLineItem_Var_Pop_Fields = {
  __typename?: 'depositInvoiceLineItem_var_pop_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "depositInvoiceLineItem" */
export type DepositInvoiceLineItem_Var_Pop_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type DepositInvoiceLineItem_Var_Samp_Fields = {
  __typename?: 'depositInvoiceLineItem_var_samp_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "depositInvoiceLineItem" */
export type DepositInvoiceLineItem_Var_Samp_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type DepositInvoiceLineItem_Variance_Fields = {
  __typename?: 'depositInvoiceLineItem_variance_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "depositInvoiceLineItem" */
export type DepositInvoiceLineItem_Variance_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** columns and relationships of "device" */
export type Device = {
  __typename?: 'device';
  active?: Maybe<Scalars['Boolean']>;
  /** An array relationship */
  appointmentServices: Array<AppointmentService>;
  /** An aggregate relationship */
  appointmentServices_aggregate: AppointmentService_Aggregate;
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  deviceType?: Maybe<DeviceType>;
  deviceTypeId?: Maybe<Scalars['uuid']>;
  id: Scalars['uuid'];
  /** An object relationship */
  location?: Maybe<Location>;
  locationId?: Maybe<Scalars['uuid']>;
  name: Scalars['String'];
  schedule?: Maybe<Scalars['jsonb']>;
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  workspace?: Maybe<Workspace>;
  workspaceId?: Maybe<Scalars['uuid']>;
};


/** columns and relationships of "device" */
export type DeviceAppointmentServicesArgs = {
  distinct_on?: InputMaybe<Array<AppointmentService_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AppointmentService_Order_By>>;
  where?: InputMaybe<AppointmentService_Bool_Exp>;
};


/** columns and relationships of "device" */
export type DeviceAppointmentServices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<AppointmentService_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AppointmentService_Order_By>>;
  where?: InputMaybe<AppointmentService_Bool_Exp>;
};


/** columns and relationships of "device" */
export type DeviceScheduleArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "deviceType" */
export type DeviceType = {
  __typename?: 'deviceType';
  created_at: Scalars['timestamptz'];
  /** An array relationship */
  devices: Array<Device>;
  /** An aggregate relationship */
  devices_aggregate: Device_Aggregate;
  id: Scalars['uuid'];
  /** An array relationship */
  serviceDeviceTypes: Array<ServiceDeviceType>;
  /** An aggregate relationship */
  serviceDeviceTypes_aggregate: ServiceDeviceType_Aggregate;
  updated_at: Scalars['timestamptz'];
  value: Scalars['String'];
  /** An object relationship */
  workspace: Workspace;
  workspaceId: Scalars['uuid'];
};


/** columns and relationships of "deviceType" */
export type DeviceTypeDevicesArgs = {
  distinct_on?: InputMaybe<Array<Device_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Device_Order_By>>;
  where?: InputMaybe<Device_Bool_Exp>;
};


/** columns and relationships of "deviceType" */
export type DeviceTypeDevices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Device_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Device_Order_By>>;
  where?: InputMaybe<Device_Bool_Exp>;
};


/** columns and relationships of "deviceType" */
export type DeviceTypeServiceDeviceTypesArgs = {
  distinct_on?: InputMaybe<Array<ServiceDeviceType_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ServiceDeviceType_Order_By>>;
  where?: InputMaybe<ServiceDeviceType_Bool_Exp>;
};


/** columns and relationships of "deviceType" */
export type DeviceTypeServiceDeviceTypes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ServiceDeviceType_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ServiceDeviceType_Order_By>>;
  where?: InputMaybe<ServiceDeviceType_Bool_Exp>;
};

/** aggregated selection of "deviceType" */
export type DeviceType_Aggregate = {
  __typename?: 'deviceType_aggregate';
  aggregate?: Maybe<DeviceType_Aggregate_Fields>;
  nodes: Array<DeviceType>;
};

export type DeviceType_Aggregate_Bool_Exp = {
  count?: InputMaybe<DeviceType_Aggregate_Bool_Exp_Count>;
};

export type DeviceType_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<DeviceType_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DeviceType_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "deviceType" */
export type DeviceType_Aggregate_Fields = {
  __typename?: 'deviceType_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<DeviceType_Max_Fields>;
  min?: Maybe<DeviceType_Min_Fields>;
};


/** aggregate fields of "deviceType" */
export type DeviceType_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<DeviceType_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "deviceType" */
export type DeviceType_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<DeviceType_Max_Order_By>;
  min?: InputMaybe<DeviceType_Min_Order_By>;
};

/** input type for inserting array relation for remote table "deviceType" */
export type DeviceType_Arr_Rel_Insert_Input = {
  data: Array<DeviceType_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<DeviceType_On_Conflict>;
};

/** Boolean expression to filter rows from the table "deviceType". All fields are combined with a logical 'AND'. */
export type DeviceType_Bool_Exp = {
  _and?: InputMaybe<Array<DeviceType_Bool_Exp>>;
  _not?: InputMaybe<DeviceType_Bool_Exp>;
  _or?: InputMaybe<Array<DeviceType_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  devices?: InputMaybe<Device_Bool_Exp>;
  devices_aggregate?: InputMaybe<Device_Aggregate_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  serviceDeviceTypes?: InputMaybe<ServiceDeviceType_Bool_Exp>;
  serviceDeviceTypes_aggregate?: InputMaybe<ServiceDeviceType_Aggregate_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
  workspace?: InputMaybe<Workspace_Bool_Exp>;
  workspaceId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "deviceType" */
export enum DeviceType_Constraint {
  /** unique or primary key constraint on columns "id" */
  DeviceTypeIdKey = 'deviceType_id_key',
  /** unique or primary key constraint on columns "id" */
  DeviceTypePkey = 'deviceType_pkey',
  /** unique or primary key constraint on columns "workspaceId", "value" */
  DeviceTypeValueWorkspaceIdKey = 'deviceType_value_workspaceId_key'
}

/** input type for inserting data into table "deviceType" */
export type DeviceType_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  devices?: InputMaybe<Device_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']>;
  serviceDeviceTypes?: InputMaybe<ServiceDeviceType_Arr_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  value?: InputMaybe<Scalars['String']>;
  workspace?: InputMaybe<Workspace_Obj_Rel_Insert_Input>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type DeviceType_Max_Fields = {
  __typename?: 'deviceType_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['String']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "deviceType" */
export type DeviceType_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type DeviceType_Min_Fields = {
  __typename?: 'deviceType_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['String']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "deviceType" */
export type DeviceType_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "deviceType" */
export type DeviceType_Mutation_Response = {
  __typename?: 'deviceType_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<DeviceType>;
};

/** input type for inserting object relation for remote table "deviceType" */
export type DeviceType_Obj_Rel_Insert_Input = {
  data: DeviceType_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<DeviceType_On_Conflict>;
};

/** on_conflict condition type for table "deviceType" */
export type DeviceType_On_Conflict = {
  constraint: DeviceType_Constraint;
  update_columns?: Array<DeviceType_Update_Column>;
  where?: InputMaybe<DeviceType_Bool_Exp>;
};

/** Ordering options when selecting data from "deviceType". */
export type DeviceType_Order_By = {
  created_at?: InputMaybe<Order_By>;
  devices_aggregate?: InputMaybe<Device_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  serviceDeviceTypes_aggregate?: InputMaybe<ServiceDeviceType_Aggregate_Order_By>;
  updated_at?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
  workspace?: InputMaybe<Workspace_Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: deviceType */
export type DeviceType_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "deviceType" */
export enum DeviceType_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Value = 'value',
  /** column name */
  WorkspaceId = 'workspaceId'
}

/** input type for updating data in table "deviceType" */
export type DeviceType_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  value?: InputMaybe<Scalars['String']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "deviceType" */
export type DeviceType_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: DeviceType_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type DeviceType_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  value?: InputMaybe<Scalars['String']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "deviceType" */
export enum DeviceType_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Value = 'value',
  /** column name */
  WorkspaceId = 'workspaceId'
}

export type DeviceType_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<DeviceType_Set_Input>;
  /** filter the rows which have to be updated */
  where: DeviceType_Bool_Exp;
};

/** aggregated selection of "device" */
export type Device_Aggregate = {
  __typename?: 'device_aggregate';
  aggregate?: Maybe<Device_Aggregate_Fields>;
  nodes: Array<Device>;
};

export type Device_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Device_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Device_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Device_Aggregate_Bool_Exp_Count>;
};

export type Device_Aggregate_Bool_Exp_Bool_And = {
  arguments: Device_Select_Column_Device_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Device_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Device_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Device_Select_Column_Device_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Device_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Device_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Device_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Device_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "device" */
export type Device_Aggregate_Fields = {
  __typename?: 'device_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Device_Max_Fields>;
  min?: Maybe<Device_Min_Fields>;
};


/** aggregate fields of "device" */
export type Device_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Device_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "device" */
export type Device_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Device_Max_Order_By>;
  min?: InputMaybe<Device_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Device_Append_Input = {
  schedule?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "device" */
export type Device_Arr_Rel_Insert_Input = {
  data: Array<Device_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Device_On_Conflict>;
};

/** Boolean expression to filter rows from the table "device". All fields are combined with a logical 'AND'. */
export type Device_Bool_Exp = {
  _and?: InputMaybe<Array<Device_Bool_Exp>>;
  _not?: InputMaybe<Device_Bool_Exp>;
  _or?: InputMaybe<Array<Device_Bool_Exp>>;
  active?: InputMaybe<Boolean_Comparison_Exp>;
  appointmentServices?: InputMaybe<AppointmentService_Bool_Exp>;
  appointmentServices_aggregate?: InputMaybe<AppointmentService_Aggregate_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deviceType?: InputMaybe<DeviceType_Bool_Exp>;
  deviceTypeId?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  location?: InputMaybe<Location_Bool_Exp>;
  locationId?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  schedule?: InputMaybe<Jsonb_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  workspace?: InputMaybe<Workspace_Bool_Exp>;
  workspaceId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "device" */
export enum Device_Constraint {
  /** unique or primary key constraint on columns "id" */
  DevicePkey = 'device_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Device_Delete_At_Path_Input = {
  schedule?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Device_Delete_Elem_Input = {
  schedule?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Device_Delete_Key_Input = {
  schedule?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "device" */
export type Device_Insert_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  appointmentServices?: InputMaybe<AppointmentService_Arr_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deviceType?: InputMaybe<DeviceType_Obj_Rel_Insert_Input>;
  deviceTypeId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  location?: InputMaybe<Location_Obj_Rel_Insert_Input>;
  locationId?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  schedule?: InputMaybe<Scalars['jsonb']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  workspace?: InputMaybe<Workspace_Obj_Rel_Insert_Input>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Device_Max_Fields = {
  __typename?: 'device_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deviceTypeId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  locationId?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "device" */
export type Device_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deviceTypeId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  locationId?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Device_Min_Fields = {
  __typename?: 'device_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deviceTypeId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  locationId?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "device" */
export type Device_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deviceTypeId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  locationId?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "device" */
export type Device_Mutation_Response = {
  __typename?: 'device_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Device>;
};

/** input type for inserting object relation for remote table "device" */
export type Device_Obj_Rel_Insert_Input = {
  data: Device_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Device_On_Conflict>;
};

/** on_conflict condition type for table "device" */
export type Device_On_Conflict = {
  constraint: Device_Constraint;
  update_columns?: Array<Device_Update_Column>;
  where?: InputMaybe<Device_Bool_Exp>;
};

/** Ordering options when selecting data from "device". */
export type Device_Order_By = {
  active?: InputMaybe<Order_By>;
  appointmentServices_aggregate?: InputMaybe<AppointmentService_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  deviceType?: InputMaybe<DeviceType_Order_By>;
  deviceTypeId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  location?: InputMaybe<Location_Order_By>;
  locationId?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  schedule?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  workspace?: InputMaybe<Workspace_Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: device */
export type Device_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Device_Prepend_Input = {
  schedule?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "device" */
export enum Device_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeviceTypeId = 'deviceTypeId',
  /** column name */
  Id = 'id',
  /** column name */
  LocationId = 'locationId',
  /** column name */
  Name = 'name',
  /** column name */
  Schedule = 'schedule',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  WorkspaceId = 'workspaceId'
}

/** select "device_aggregate_bool_exp_bool_and_arguments_columns" columns of table "device" */
export enum Device_Select_Column_Device_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Active = 'active'
}

/** select "device_aggregate_bool_exp_bool_or_arguments_columns" columns of table "device" */
export enum Device_Select_Column_Device_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Active = 'active'
}

/** input type for updating data in table "device" */
export type Device_Set_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deviceTypeId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  locationId?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  schedule?: InputMaybe<Scalars['jsonb']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "device" */
export type Device_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Device_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Device_Stream_Cursor_Value_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deviceTypeId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  locationId?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  schedule?: InputMaybe<Scalars['jsonb']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "device" */
export enum Device_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeviceTypeId = 'deviceTypeId',
  /** column name */
  Id = 'id',
  /** column name */
  LocationId = 'locationId',
  /** column name */
  Name = 'name',
  /** column name */
  Schedule = 'schedule',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  WorkspaceId = 'workspaceId'
}

export type Device_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Device_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Device_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Device_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Device_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Device_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Device_Set_Input>;
  /** filter the rows which have to be updated */
  where: Device_Bool_Exp;
};

/** columns and relationships of "discountInvoiceLineItem" */
export type DiscountInvoiceLineItem = {
  __typename?: 'discountInvoiceLineItem';
  amount: Scalars['float8'];
  couponId?: Maybe<Scalars['uuid']>;
  createdAt: Scalars['timestamptz'];
  discountTotal: Scalars['float8'];
  id: Scalars['uuid'];
  invoiceId: Scalars['uuid'];
  membershipId?: Maybe<Scalars['uuid']>;
  name: Scalars['String'];
  scope: Scalars['String'];
  type: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  walletId?: Maybe<Scalars['uuid']>;
  workspaceId: Scalars['uuid'];
};

/** aggregated selection of "discountInvoiceLineItem" */
export type DiscountInvoiceLineItem_Aggregate = {
  __typename?: 'discountInvoiceLineItem_aggregate';
  aggregate?: Maybe<DiscountInvoiceLineItem_Aggregate_Fields>;
  nodes: Array<DiscountInvoiceLineItem>;
};

export type DiscountInvoiceLineItem_Aggregate_Bool_Exp = {
  avg?: InputMaybe<DiscountInvoiceLineItem_Aggregate_Bool_Exp_Avg>;
  corr?: InputMaybe<DiscountInvoiceLineItem_Aggregate_Bool_Exp_Corr>;
  count?: InputMaybe<DiscountInvoiceLineItem_Aggregate_Bool_Exp_Count>;
  covar_samp?: InputMaybe<DiscountInvoiceLineItem_Aggregate_Bool_Exp_Covar_Samp>;
  max?: InputMaybe<DiscountInvoiceLineItem_Aggregate_Bool_Exp_Max>;
  min?: InputMaybe<DiscountInvoiceLineItem_Aggregate_Bool_Exp_Min>;
  stddev_samp?: InputMaybe<DiscountInvoiceLineItem_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: InputMaybe<DiscountInvoiceLineItem_Aggregate_Bool_Exp_Sum>;
  var_samp?: InputMaybe<DiscountInvoiceLineItem_Aggregate_Bool_Exp_Var_Samp>;
};

export type DiscountInvoiceLineItem_Aggregate_Bool_Exp_Avg = {
  arguments: DiscountInvoiceLineItem_Select_Column_DiscountInvoiceLineItem_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DiscountInvoiceLineItem_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type DiscountInvoiceLineItem_Aggregate_Bool_Exp_Corr = {
  arguments: DiscountInvoiceLineItem_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DiscountInvoiceLineItem_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type DiscountInvoiceLineItem_Aggregate_Bool_Exp_Corr_Arguments = {
  X: DiscountInvoiceLineItem_Select_Column_DiscountInvoiceLineItem_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: DiscountInvoiceLineItem_Select_Column_DiscountInvoiceLineItem_Aggregate_Bool_Exp_Corr_Arguments_Columns;
};

export type DiscountInvoiceLineItem_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<DiscountInvoiceLineItem_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DiscountInvoiceLineItem_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type DiscountInvoiceLineItem_Aggregate_Bool_Exp_Covar_Samp = {
  arguments: DiscountInvoiceLineItem_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DiscountInvoiceLineItem_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type DiscountInvoiceLineItem_Aggregate_Bool_Exp_Covar_Samp_Arguments = {
  X: DiscountInvoiceLineItem_Select_Column_DiscountInvoiceLineItem_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  Y: DiscountInvoiceLineItem_Select_Column_DiscountInvoiceLineItem_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
};

export type DiscountInvoiceLineItem_Aggregate_Bool_Exp_Max = {
  arguments: DiscountInvoiceLineItem_Select_Column_DiscountInvoiceLineItem_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DiscountInvoiceLineItem_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type DiscountInvoiceLineItem_Aggregate_Bool_Exp_Min = {
  arguments: DiscountInvoiceLineItem_Select_Column_DiscountInvoiceLineItem_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DiscountInvoiceLineItem_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type DiscountInvoiceLineItem_Aggregate_Bool_Exp_Stddev_Samp = {
  arguments: DiscountInvoiceLineItem_Select_Column_DiscountInvoiceLineItem_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DiscountInvoiceLineItem_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type DiscountInvoiceLineItem_Aggregate_Bool_Exp_Sum = {
  arguments: DiscountInvoiceLineItem_Select_Column_DiscountInvoiceLineItem_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DiscountInvoiceLineItem_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type DiscountInvoiceLineItem_Aggregate_Bool_Exp_Var_Samp = {
  arguments: DiscountInvoiceLineItem_Select_Column_DiscountInvoiceLineItem_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DiscountInvoiceLineItem_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

/** aggregate fields of "discountInvoiceLineItem" */
export type DiscountInvoiceLineItem_Aggregate_Fields = {
  __typename?: 'discountInvoiceLineItem_aggregate_fields';
  avg?: Maybe<DiscountInvoiceLineItem_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<DiscountInvoiceLineItem_Max_Fields>;
  min?: Maybe<DiscountInvoiceLineItem_Min_Fields>;
  stddev?: Maybe<DiscountInvoiceLineItem_Stddev_Fields>;
  stddev_pop?: Maybe<DiscountInvoiceLineItem_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<DiscountInvoiceLineItem_Stddev_Samp_Fields>;
  sum?: Maybe<DiscountInvoiceLineItem_Sum_Fields>;
  var_pop?: Maybe<DiscountInvoiceLineItem_Var_Pop_Fields>;
  var_samp?: Maybe<DiscountInvoiceLineItem_Var_Samp_Fields>;
  variance?: Maybe<DiscountInvoiceLineItem_Variance_Fields>;
};


/** aggregate fields of "discountInvoiceLineItem" */
export type DiscountInvoiceLineItem_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<DiscountInvoiceLineItem_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "discountInvoiceLineItem" */
export type DiscountInvoiceLineItem_Aggregate_Order_By = {
  avg?: InputMaybe<DiscountInvoiceLineItem_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<DiscountInvoiceLineItem_Max_Order_By>;
  min?: InputMaybe<DiscountInvoiceLineItem_Min_Order_By>;
  stddev?: InputMaybe<DiscountInvoiceLineItem_Stddev_Order_By>;
  stddev_pop?: InputMaybe<DiscountInvoiceLineItem_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<DiscountInvoiceLineItem_Stddev_Samp_Order_By>;
  sum?: InputMaybe<DiscountInvoiceLineItem_Sum_Order_By>;
  var_pop?: InputMaybe<DiscountInvoiceLineItem_Var_Pop_Order_By>;
  var_samp?: InputMaybe<DiscountInvoiceLineItem_Var_Samp_Order_By>;
  variance?: InputMaybe<DiscountInvoiceLineItem_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "discountInvoiceLineItem" */
export type DiscountInvoiceLineItem_Arr_Rel_Insert_Input = {
  data: Array<DiscountInvoiceLineItem_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<DiscountInvoiceLineItem_On_Conflict>;
};

/** aggregate avg on columns */
export type DiscountInvoiceLineItem_Avg_Fields = {
  __typename?: 'discountInvoiceLineItem_avg_fields';
  amount?: Maybe<Scalars['Float']>;
  discountTotal?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "discountInvoiceLineItem" */
export type DiscountInvoiceLineItem_Avg_Order_By = {
  amount?: InputMaybe<Order_By>;
  discountTotal?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "discountInvoiceLineItem". All fields are combined with a logical 'AND'. */
export type DiscountInvoiceLineItem_Bool_Exp = {
  _and?: InputMaybe<Array<DiscountInvoiceLineItem_Bool_Exp>>;
  _not?: InputMaybe<DiscountInvoiceLineItem_Bool_Exp>;
  _or?: InputMaybe<Array<DiscountInvoiceLineItem_Bool_Exp>>;
  amount?: InputMaybe<Float8_Comparison_Exp>;
  couponId?: InputMaybe<Uuid_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  discountTotal?: InputMaybe<Float8_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  invoiceId?: InputMaybe<Uuid_Comparison_Exp>;
  membershipId?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  scope?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  walletId?: InputMaybe<Uuid_Comparison_Exp>;
  workspaceId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "discountInvoiceLineItem" */
export enum DiscountInvoiceLineItem_Constraint {
  /** unique or primary key constraint on columns "id" */
  DiscountInvoiceLineItemPkey = 'discountInvoiceLineItem_pkey'
}

/** input type for incrementing numeric columns in table "discountInvoiceLineItem" */
export type DiscountInvoiceLineItem_Inc_Input = {
  amount?: InputMaybe<Scalars['float8']>;
  discountTotal?: InputMaybe<Scalars['float8']>;
};

/** input type for inserting data into table "discountInvoiceLineItem" */
export type DiscountInvoiceLineItem_Insert_Input = {
  amount?: InputMaybe<Scalars['float8']>;
  couponId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  discountTotal?: InputMaybe<Scalars['float8']>;
  id?: InputMaybe<Scalars['uuid']>;
  invoiceId?: InputMaybe<Scalars['uuid']>;
  membershipId?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  scope?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  walletId?: InputMaybe<Scalars['uuid']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type DiscountInvoiceLineItem_Max_Fields = {
  __typename?: 'discountInvoiceLineItem_max_fields';
  amount?: Maybe<Scalars['float8']>;
  couponId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  discountTotal?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['uuid']>;
  invoiceId?: Maybe<Scalars['uuid']>;
  membershipId?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  scope?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  walletId?: Maybe<Scalars['uuid']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "discountInvoiceLineItem" */
export type DiscountInvoiceLineItem_Max_Order_By = {
  amount?: InputMaybe<Order_By>;
  couponId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  discountTotal?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoiceId?: InputMaybe<Order_By>;
  membershipId?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  scope?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  walletId?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type DiscountInvoiceLineItem_Min_Fields = {
  __typename?: 'discountInvoiceLineItem_min_fields';
  amount?: Maybe<Scalars['float8']>;
  couponId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  discountTotal?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['uuid']>;
  invoiceId?: Maybe<Scalars['uuid']>;
  membershipId?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  scope?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  walletId?: Maybe<Scalars['uuid']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "discountInvoiceLineItem" */
export type DiscountInvoiceLineItem_Min_Order_By = {
  amount?: InputMaybe<Order_By>;
  couponId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  discountTotal?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoiceId?: InputMaybe<Order_By>;
  membershipId?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  scope?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  walletId?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "discountInvoiceLineItem" */
export type DiscountInvoiceLineItem_Mutation_Response = {
  __typename?: 'discountInvoiceLineItem_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<DiscountInvoiceLineItem>;
};

/** on_conflict condition type for table "discountInvoiceLineItem" */
export type DiscountInvoiceLineItem_On_Conflict = {
  constraint: DiscountInvoiceLineItem_Constraint;
  update_columns?: Array<DiscountInvoiceLineItem_Update_Column>;
  where?: InputMaybe<DiscountInvoiceLineItem_Bool_Exp>;
};

/** Ordering options when selecting data from "discountInvoiceLineItem". */
export type DiscountInvoiceLineItem_Order_By = {
  amount?: InputMaybe<Order_By>;
  couponId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  discountTotal?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoiceId?: InputMaybe<Order_By>;
  membershipId?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  scope?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  walletId?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: discountInvoiceLineItem */
export type DiscountInvoiceLineItem_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "discountInvoiceLineItem" */
export enum DiscountInvoiceLineItem_Select_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  CouponId = 'couponId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DiscountTotal = 'discountTotal',
  /** column name */
  Id = 'id',
  /** column name */
  InvoiceId = 'invoiceId',
  /** column name */
  MembershipId = 'membershipId',
  /** column name */
  Name = 'name',
  /** column name */
  Scope = 'scope',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WalletId = 'walletId',
  /** column name */
  WorkspaceId = 'workspaceId'
}

/** select "discountInvoiceLineItem_aggregate_bool_exp_avg_arguments_columns" columns of table "discountInvoiceLineItem" */
export enum DiscountInvoiceLineItem_Select_Column_DiscountInvoiceLineItem_Aggregate_Bool_Exp_Avg_Arguments_Columns {
  /** column name */
  Amount = 'amount',
  /** column name */
  DiscountTotal = 'discountTotal'
}

/** select "discountInvoiceLineItem_aggregate_bool_exp_corr_arguments_columns" columns of table "discountInvoiceLineItem" */
export enum DiscountInvoiceLineItem_Select_Column_DiscountInvoiceLineItem_Aggregate_Bool_Exp_Corr_Arguments_Columns {
  /** column name */
  Amount = 'amount',
  /** column name */
  DiscountTotal = 'discountTotal'
}

/** select "discountInvoiceLineItem_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "discountInvoiceLineItem" */
export enum DiscountInvoiceLineItem_Select_Column_DiscountInvoiceLineItem_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns {
  /** column name */
  Amount = 'amount',
  /** column name */
  DiscountTotal = 'discountTotal'
}

/** select "discountInvoiceLineItem_aggregate_bool_exp_max_arguments_columns" columns of table "discountInvoiceLineItem" */
export enum DiscountInvoiceLineItem_Select_Column_DiscountInvoiceLineItem_Aggregate_Bool_Exp_Max_Arguments_Columns {
  /** column name */
  Amount = 'amount',
  /** column name */
  DiscountTotal = 'discountTotal'
}

/** select "discountInvoiceLineItem_aggregate_bool_exp_min_arguments_columns" columns of table "discountInvoiceLineItem" */
export enum DiscountInvoiceLineItem_Select_Column_DiscountInvoiceLineItem_Aggregate_Bool_Exp_Min_Arguments_Columns {
  /** column name */
  Amount = 'amount',
  /** column name */
  DiscountTotal = 'discountTotal'
}

/** select "discountInvoiceLineItem_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "discountInvoiceLineItem" */
export enum DiscountInvoiceLineItem_Select_Column_DiscountInvoiceLineItem_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns {
  /** column name */
  Amount = 'amount',
  /** column name */
  DiscountTotal = 'discountTotal'
}

/** select "discountInvoiceLineItem_aggregate_bool_exp_sum_arguments_columns" columns of table "discountInvoiceLineItem" */
export enum DiscountInvoiceLineItem_Select_Column_DiscountInvoiceLineItem_Aggregate_Bool_Exp_Sum_Arguments_Columns {
  /** column name */
  Amount = 'amount',
  /** column name */
  DiscountTotal = 'discountTotal'
}

/** select "discountInvoiceLineItem_aggregate_bool_exp_var_samp_arguments_columns" columns of table "discountInvoiceLineItem" */
export enum DiscountInvoiceLineItem_Select_Column_DiscountInvoiceLineItem_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns {
  /** column name */
  Amount = 'amount',
  /** column name */
  DiscountTotal = 'discountTotal'
}

/** input type for updating data in table "discountInvoiceLineItem" */
export type DiscountInvoiceLineItem_Set_Input = {
  amount?: InputMaybe<Scalars['float8']>;
  couponId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  discountTotal?: InputMaybe<Scalars['float8']>;
  id?: InputMaybe<Scalars['uuid']>;
  invoiceId?: InputMaybe<Scalars['uuid']>;
  membershipId?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  scope?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  walletId?: InputMaybe<Scalars['uuid']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type DiscountInvoiceLineItem_Stddev_Fields = {
  __typename?: 'discountInvoiceLineItem_stddev_fields';
  amount?: Maybe<Scalars['Float']>;
  discountTotal?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "discountInvoiceLineItem" */
export type DiscountInvoiceLineItem_Stddev_Order_By = {
  amount?: InputMaybe<Order_By>;
  discountTotal?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type DiscountInvoiceLineItem_Stddev_Pop_Fields = {
  __typename?: 'discountInvoiceLineItem_stddev_pop_fields';
  amount?: Maybe<Scalars['Float']>;
  discountTotal?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "discountInvoiceLineItem" */
export type DiscountInvoiceLineItem_Stddev_Pop_Order_By = {
  amount?: InputMaybe<Order_By>;
  discountTotal?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type DiscountInvoiceLineItem_Stddev_Samp_Fields = {
  __typename?: 'discountInvoiceLineItem_stddev_samp_fields';
  amount?: Maybe<Scalars['Float']>;
  discountTotal?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "discountInvoiceLineItem" */
export type DiscountInvoiceLineItem_Stddev_Samp_Order_By = {
  amount?: InputMaybe<Order_By>;
  discountTotal?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "discountInvoiceLineItem" */
export type DiscountInvoiceLineItem_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: DiscountInvoiceLineItem_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type DiscountInvoiceLineItem_Stream_Cursor_Value_Input = {
  amount?: InputMaybe<Scalars['float8']>;
  couponId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  discountTotal?: InputMaybe<Scalars['float8']>;
  id?: InputMaybe<Scalars['uuid']>;
  invoiceId?: InputMaybe<Scalars['uuid']>;
  membershipId?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  scope?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  walletId?: InputMaybe<Scalars['uuid']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type DiscountInvoiceLineItem_Sum_Fields = {
  __typename?: 'discountInvoiceLineItem_sum_fields';
  amount?: Maybe<Scalars['float8']>;
  discountTotal?: Maybe<Scalars['float8']>;
};

/** order by sum() on columns of table "discountInvoiceLineItem" */
export type DiscountInvoiceLineItem_Sum_Order_By = {
  amount?: InputMaybe<Order_By>;
  discountTotal?: InputMaybe<Order_By>;
};

/** update columns of table "discountInvoiceLineItem" */
export enum DiscountInvoiceLineItem_Update_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  CouponId = 'couponId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DiscountTotal = 'discountTotal',
  /** column name */
  Id = 'id',
  /** column name */
  InvoiceId = 'invoiceId',
  /** column name */
  MembershipId = 'membershipId',
  /** column name */
  Name = 'name',
  /** column name */
  Scope = 'scope',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WalletId = 'walletId',
  /** column name */
  WorkspaceId = 'workspaceId'
}

export type DiscountInvoiceLineItem_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<DiscountInvoiceLineItem_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<DiscountInvoiceLineItem_Set_Input>;
  /** filter the rows which have to be updated */
  where: DiscountInvoiceLineItem_Bool_Exp;
};

/** aggregate var_pop on columns */
export type DiscountInvoiceLineItem_Var_Pop_Fields = {
  __typename?: 'discountInvoiceLineItem_var_pop_fields';
  amount?: Maybe<Scalars['Float']>;
  discountTotal?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "discountInvoiceLineItem" */
export type DiscountInvoiceLineItem_Var_Pop_Order_By = {
  amount?: InputMaybe<Order_By>;
  discountTotal?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type DiscountInvoiceLineItem_Var_Samp_Fields = {
  __typename?: 'discountInvoiceLineItem_var_samp_fields';
  amount?: Maybe<Scalars['Float']>;
  discountTotal?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "discountInvoiceLineItem" */
export type DiscountInvoiceLineItem_Var_Samp_Order_By = {
  amount?: InputMaybe<Order_By>;
  discountTotal?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type DiscountInvoiceLineItem_Variance_Fields = {
  __typename?: 'discountInvoiceLineItem_variance_fields';
  amount?: Maybe<Scalars['Float']>;
  discountTotal?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "discountInvoiceLineItem" */
export type DiscountInvoiceLineItem_Variance_Order_By = {
  amount?: InputMaybe<Order_By>;
  discountTotal?: InputMaybe<Order_By>;
};

/** columns and relationships of "document" */
export type Document = {
  __typename?: 'document';
  appointmentId?: Maybe<Scalars['uuid']>;
  createdAt: Scalars['timestamptz'];
  /** An array relationship */
  documentTags: Array<DocumentTag>;
  /** An aggregate relationship */
  documentTags_aggregate: DocumentTag_Aggregate;
  documentType: Scalars['String'];
  file?: Maybe<FileOnlyUrl>;
  filePath: Scalars['String'];
  id: Scalars['uuid'];
  patientId?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
  updatedAt: Scalars['timestamptz'];
  workspaceId: Scalars['uuid'];
};


/** columns and relationships of "document" */
export type DocumentDocumentTagsArgs = {
  distinct_on?: InputMaybe<Array<DocumentTag_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<DocumentTag_Order_By>>;
  where?: InputMaybe<DocumentTag_Bool_Exp>;
};


/** columns and relationships of "document" */
export type DocumentDocumentTags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<DocumentTag_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<DocumentTag_Order_By>>;
  where?: InputMaybe<DocumentTag_Bool_Exp>;
};

/** columns and relationships of "documentTag" */
export type DocumentTag = {
  __typename?: 'documentTag';
  createdAt: Scalars['timestamptz'];
  /** An object relationship */
  document: Document;
  documentId: Scalars['uuid'];
  id: Scalars['uuid'];
  /** An object relationship */
  tag: Tag;
  tagId: Scalars['uuid'];
  updatedAt: Scalars['timestamptz'];
  workspaceId: Scalars['uuid'];
};

/** aggregated selection of "documentTag" */
export type DocumentTag_Aggregate = {
  __typename?: 'documentTag_aggregate';
  aggregate?: Maybe<DocumentTag_Aggregate_Fields>;
  nodes: Array<DocumentTag>;
};

export type DocumentTag_Aggregate_Bool_Exp = {
  count?: InputMaybe<DocumentTag_Aggregate_Bool_Exp_Count>;
};

export type DocumentTag_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<DocumentTag_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DocumentTag_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "documentTag" */
export type DocumentTag_Aggregate_Fields = {
  __typename?: 'documentTag_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<DocumentTag_Max_Fields>;
  min?: Maybe<DocumentTag_Min_Fields>;
};


/** aggregate fields of "documentTag" */
export type DocumentTag_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<DocumentTag_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "documentTag" */
export type DocumentTag_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<DocumentTag_Max_Order_By>;
  min?: InputMaybe<DocumentTag_Min_Order_By>;
};

/** input type for inserting array relation for remote table "documentTag" */
export type DocumentTag_Arr_Rel_Insert_Input = {
  data: Array<DocumentTag_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<DocumentTag_On_Conflict>;
};

/** Boolean expression to filter rows from the table "documentTag". All fields are combined with a logical 'AND'. */
export type DocumentTag_Bool_Exp = {
  _and?: InputMaybe<Array<DocumentTag_Bool_Exp>>;
  _not?: InputMaybe<DocumentTag_Bool_Exp>;
  _or?: InputMaybe<Array<DocumentTag_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  document?: InputMaybe<Document_Bool_Exp>;
  documentId?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  tag?: InputMaybe<Tag_Bool_Exp>;
  tagId?: InputMaybe<Uuid_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  workspaceId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "documentTag" */
export enum DocumentTag_Constraint {
  /** unique or primary key constraint on columns "tagId", "documentId" */
  DocumentTagDocumentIdTagIdKey = 'documentTag_documentId_tagId_key',
  /** unique or primary key constraint on columns "id" */
  DocumentTagPkey = 'documentTag_pkey'
}

/** input type for inserting data into table "documentTag" */
export type DocumentTag_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  document?: InputMaybe<Document_Obj_Rel_Insert_Input>;
  documentId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  tag?: InputMaybe<Tag_Obj_Rel_Insert_Input>;
  tagId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type DocumentTag_Max_Fields = {
  __typename?: 'documentTag_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  documentId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  tagId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "documentTag" */
export type DocumentTag_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  documentId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tagId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type DocumentTag_Min_Fields = {
  __typename?: 'documentTag_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  documentId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  tagId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "documentTag" */
export type DocumentTag_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  documentId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tagId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "documentTag" */
export type DocumentTag_Mutation_Response = {
  __typename?: 'documentTag_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<DocumentTag>;
};

/** on_conflict condition type for table "documentTag" */
export type DocumentTag_On_Conflict = {
  constraint: DocumentTag_Constraint;
  update_columns?: Array<DocumentTag_Update_Column>;
  where?: InputMaybe<DocumentTag_Bool_Exp>;
};

/** Ordering options when selecting data from "documentTag". */
export type DocumentTag_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  document?: InputMaybe<Document_Order_By>;
  documentId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tag?: InputMaybe<Tag_Order_By>;
  tagId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: documentTag */
export type DocumentTag_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "documentTag" */
export enum DocumentTag_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DocumentId = 'documentId',
  /** column name */
  Id = 'id',
  /** column name */
  TagId = 'tagId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WorkspaceId = 'workspaceId'
}

/** input type for updating data in table "documentTag" */
export type DocumentTag_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  documentId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  tagId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "documentTag" */
export type DocumentTag_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: DocumentTag_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type DocumentTag_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  documentId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  tagId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "documentTag" */
export enum DocumentTag_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DocumentId = 'documentId',
  /** column name */
  Id = 'id',
  /** column name */
  TagId = 'tagId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WorkspaceId = 'workspaceId'
}

export type DocumentTag_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<DocumentTag_Set_Input>;
  /** filter the rows which have to be updated */
  where: DocumentTag_Bool_Exp;
};

/** aggregated selection of "document" */
export type Document_Aggregate = {
  __typename?: 'document_aggregate';
  aggregate?: Maybe<Document_Aggregate_Fields>;
  nodes: Array<Document>;
};

export type Document_Aggregate_Bool_Exp = {
  count?: InputMaybe<Document_Aggregate_Bool_Exp_Count>;
};

export type Document_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Document_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Document_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "document" */
export type Document_Aggregate_Fields = {
  __typename?: 'document_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Document_Max_Fields>;
  min?: Maybe<Document_Min_Fields>;
};


/** aggregate fields of "document" */
export type Document_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Document_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "document" */
export type Document_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Document_Max_Order_By>;
  min?: InputMaybe<Document_Min_Order_By>;
};

/** input type for inserting array relation for remote table "document" */
export type Document_Arr_Rel_Insert_Input = {
  data: Array<Document_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Document_On_Conflict>;
};

/** Boolean expression to filter rows from the table "document". All fields are combined with a logical 'AND'. */
export type Document_Bool_Exp = {
  _and?: InputMaybe<Array<Document_Bool_Exp>>;
  _not?: InputMaybe<Document_Bool_Exp>;
  _or?: InputMaybe<Array<Document_Bool_Exp>>;
  appointmentId?: InputMaybe<Uuid_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  documentTags?: InputMaybe<DocumentTag_Bool_Exp>;
  documentTags_aggregate?: InputMaybe<DocumentTag_Aggregate_Bool_Exp>;
  documentType?: InputMaybe<String_Comparison_Exp>;
  filePath?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  patientId?: InputMaybe<Uuid_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  workspaceId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "document" */
export enum Document_Constraint {
  /** unique or primary key constraint on columns "filePath" */
  DocumentFilePathKey = 'document_filePath_key',
  /** unique or primary key constraint on columns "id" */
  DocumentPkey = 'document_pkey'
}

/** input type for inserting data into table "document" */
export type Document_Insert_Input = {
  appointmentId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  documentTags?: InputMaybe<DocumentTag_Arr_Rel_Insert_Input>;
  documentType?: InputMaybe<Scalars['String']>;
  filePath?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  patientId?: InputMaybe<Scalars['uuid']>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Document_Max_Fields = {
  __typename?: 'document_max_fields';
  appointmentId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  documentType?: Maybe<Scalars['String']>;
  filePath?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  patientId?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "document" */
export type Document_Max_Order_By = {
  appointmentId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  documentType?: InputMaybe<Order_By>;
  filePath?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patientId?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Document_Min_Fields = {
  __typename?: 'document_min_fields';
  appointmentId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  documentType?: Maybe<Scalars['String']>;
  filePath?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  patientId?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "document" */
export type Document_Min_Order_By = {
  appointmentId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  documentType?: InputMaybe<Order_By>;
  filePath?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patientId?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "document" */
export type Document_Mutation_Response = {
  __typename?: 'document_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Document>;
};

/** input type for inserting object relation for remote table "document" */
export type Document_Obj_Rel_Insert_Input = {
  data: Document_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Document_On_Conflict>;
};

/** on_conflict condition type for table "document" */
export type Document_On_Conflict = {
  constraint: Document_Constraint;
  update_columns?: Array<Document_Update_Column>;
  where?: InputMaybe<Document_Bool_Exp>;
};

/** Ordering options when selecting data from "document". */
export type Document_Order_By = {
  appointmentId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  documentTags_aggregate?: InputMaybe<DocumentTag_Aggregate_Order_By>;
  documentType?: InputMaybe<Order_By>;
  filePath?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patientId?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: document */
export type Document_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "document" */
export enum Document_Select_Column {
  /** column name */
  AppointmentId = 'appointmentId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DocumentType = 'documentType',
  /** column name */
  FilePath = 'filePath',
  /** column name */
  Id = 'id',
  /** column name */
  PatientId = 'patientId',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WorkspaceId = 'workspaceId'
}

/** input type for updating data in table "document" */
export type Document_Set_Input = {
  appointmentId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  documentType?: InputMaybe<Scalars['String']>;
  filePath?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  patientId?: InputMaybe<Scalars['uuid']>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "document" */
export type Document_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Document_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Document_Stream_Cursor_Value_Input = {
  appointmentId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  documentType?: InputMaybe<Scalars['String']>;
  filePath?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  patientId?: InputMaybe<Scalars['uuid']>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "document" */
export enum Document_Update_Column {
  /** column name */
  AppointmentId = 'appointmentId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DocumentType = 'documentType',
  /** column name */
  FilePath = 'filePath',
  /** column name */
  Id = 'id',
  /** column name */
  PatientId = 'patientId',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WorkspaceId = 'workspaceId'
}

export type Document_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Document_Set_Input>;
  /** filter the rows which have to be updated */
  where: Document_Bool_Exp;
};

/** columns and relationships of "emailCampaignLog" */
export type EmailCampaignLog = {
  __typename?: 'emailCampaignLog';
  emailTemplateId: Scalars['uuid'];
  id: Scalars['uuid'];
  messageId: Scalars['uuid'];
  /** An object relationship */
  patientWorkspace: PatientWorkspace;
  patientWorkspaceId: Scalars['uuid'];
  recipient: Scalars['String'];
  timestamp: Scalars['timestamptz'];
  type: Scalars['String'];
  workspaceId: Scalars['uuid'];
};

/** aggregated selection of "emailCampaignLog" */
export type EmailCampaignLog_Aggregate = {
  __typename?: 'emailCampaignLog_aggregate';
  aggregate?: Maybe<EmailCampaignLog_Aggregate_Fields>;
  nodes: Array<EmailCampaignLog>;
};

export type EmailCampaignLog_Aggregate_Bool_Exp = {
  count?: InputMaybe<EmailCampaignLog_Aggregate_Bool_Exp_Count>;
};

export type EmailCampaignLog_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<EmailCampaignLog_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<EmailCampaignLog_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "emailCampaignLog" */
export type EmailCampaignLog_Aggregate_Fields = {
  __typename?: 'emailCampaignLog_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<EmailCampaignLog_Max_Fields>;
  min?: Maybe<EmailCampaignLog_Min_Fields>;
};


/** aggregate fields of "emailCampaignLog" */
export type EmailCampaignLog_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<EmailCampaignLog_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "emailCampaignLog" */
export type EmailCampaignLog_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<EmailCampaignLog_Max_Order_By>;
  min?: InputMaybe<EmailCampaignLog_Min_Order_By>;
};

/** input type for inserting array relation for remote table "emailCampaignLog" */
export type EmailCampaignLog_Arr_Rel_Insert_Input = {
  data: Array<EmailCampaignLog_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<EmailCampaignLog_On_Conflict>;
};

/** Boolean expression to filter rows from the table "emailCampaignLog". All fields are combined with a logical 'AND'. */
export type EmailCampaignLog_Bool_Exp = {
  _and?: InputMaybe<Array<EmailCampaignLog_Bool_Exp>>;
  _not?: InputMaybe<EmailCampaignLog_Bool_Exp>;
  _or?: InputMaybe<Array<EmailCampaignLog_Bool_Exp>>;
  emailTemplateId?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  messageId?: InputMaybe<Uuid_Comparison_Exp>;
  patientWorkspace?: InputMaybe<PatientWorkspace_Bool_Exp>;
  patientWorkspaceId?: InputMaybe<Uuid_Comparison_Exp>;
  recipient?: InputMaybe<String_Comparison_Exp>;
  timestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  workspaceId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "emailCampaignLog" */
export enum EmailCampaignLog_Constraint {
  /** unique or primary key constraint on columns "id" */
  EmailCampaignLogPkey = 'emailCampaignLog_pkey'
}

/** input type for inserting data into table "emailCampaignLog" */
export type EmailCampaignLog_Insert_Input = {
  emailTemplateId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  messageId?: InputMaybe<Scalars['uuid']>;
  patientWorkspace?: InputMaybe<PatientWorkspace_Obj_Rel_Insert_Input>;
  patientWorkspaceId?: InputMaybe<Scalars['uuid']>;
  recipient?: InputMaybe<Scalars['String']>;
  timestamp?: InputMaybe<Scalars['timestamptz']>;
  type?: InputMaybe<Scalars['String']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type EmailCampaignLog_Max_Fields = {
  __typename?: 'emailCampaignLog_max_fields';
  emailTemplateId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  messageId?: Maybe<Scalars['uuid']>;
  patientWorkspaceId?: Maybe<Scalars['uuid']>;
  recipient?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['timestamptz']>;
  type?: Maybe<Scalars['String']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "emailCampaignLog" */
export type EmailCampaignLog_Max_Order_By = {
  emailTemplateId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  messageId?: InputMaybe<Order_By>;
  patientWorkspaceId?: InputMaybe<Order_By>;
  recipient?: InputMaybe<Order_By>;
  timestamp?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type EmailCampaignLog_Min_Fields = {
  __typename?: 'emailCampaignLog_min_fields';
  emailTemplateId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  messageId?: Maybe<Scalars['uuid']>;
  patientWorkspaceId?: Maybe<Scalars['uuid']>;
  recipient?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['timestamptz']>;
  type?: Maybe<Scalars['String']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "emailCampaignLog" */
export type EmailCampaignLog_Min_Order_By = {
  emailTemplateId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  messageId?: InputMaybe<Order_By>;
  patientWorkspaceId?: InputMaybe<Order_By>;
  recipient?: InputMaybe<Order_By>;
  timestamp?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "emailCampaignLog" */
export type EmailCampaignLog_Mutation_Response = {
  __typename?: 'emailCampaignLog_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<EmailCampaignLog>;
};

/** on_conflict condition type for table "emailCampaignLog" */
export type EmailCampaignLog_On_Conflict = {
  constraint: EmailCampaignLog_Constraint;
  update_columns?: Array<EmailCampaignLog_Update_Column>;
  where?: InputMaybe<EmailCampaignLog_Bool_Exp>;
};

/** Ordering options when selecting data from "emailCampaignLog". */
export type EmailCampaignLog_Order_By = {
  emailTemplateId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  messageId?: InputMaybe<Order_By>;
  patientWorkspace?: InputMaybe<PatientWorkspace_Order_By>;
  patientWorkspaceId?: InputMaybe<Order_By>;
  recipient?: InputMaybe<Order_By>;
  timestamp?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: emailCampaignLog */
export type EmailCampaignLog_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "emailCampaignLog" */
export enum EmailCampaignLog_Select_Column {
  /** column name */
  EmailTemplateId = 'emailTemplateId',
  /** column name */
  Id = 'id',
  /** column name */
  MessageId = 'messageId',
  /** column name */
  PatientWorkspaceId = 'patientWorkspaceId',
  /** column name */
  Recipient = 'recipient',
  /** column name */
  Timestamp = 'timestamp',
  /** column name */
  Type = 'type',
  /** column name */
  WorkspaceId = 'workspaceId'
}

/** input type for updating data in table "emailCampaignLog" */
export type EmailCampaignLog_Set_Input = {
  emailTemplateId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  messageId?: InputMaybe<Scalars['uuid']>;
  patientWorkspaceId?: InputMaybe<Scalars['uuid']>;
  recipient?: InputMaybe<Scalars['String']>;
  timestamp?: InputMaybe<Scalars['timestamptz']>;
  type?: InputMaybe<Scalars['String']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "emailCampaignLog" */
export type EmailCampaignLog_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: EmailCampaignLog_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type EmailCampaignLog_Stream_Cursor_Value_Input = {
  emailTemplateId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  messageId?: InputMaybe<Scalars['uuid']>;
  patientWorkspaceId?: InputMaybe<Scalars['uuid']>;
  recipient?: InputMaybe<Scalars['String']>;
  timestamp?: InputMaybe<Scalars['timestamptz']>;
  type?: InputMaybe<Scalars['String']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "emailCampaignLog" */
export enum EmailCampaignLog_Update_Column {
  /** column name */
  EmailTemplateId = 'emailTemplateId',
  /** column name */
  Id = 'id',
  /** column name */
  MessageId = 'messageId',
  /** column name */
  PatientWorkspaceId = 'patientWorkspaceId',
  /** column name */
  Recipient = 'recipient',
  /** column name */
  Timestamp = 'timestamp',
  /** column name */
  Type = 'type',
  /** column name */
  WorkspaceId = 'workspaceId'
}

export type EmailCampaignLog_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<EmailCampaignLog_Set_Input>;
  /** filter the rows which have to be updated */
  where: EmailCampaignLog_Bool_Exp;
};

/** columns and relationships of "emailCampaignMetrics" */
export type EmailCampaignMetrics = {
  __typename?: 'emailCampaignMetrics';
  bounces?: Maybe<Scalars['bigint']>;
  clicks?: Maybe<Scalars['bigint']>;
  deliveries?: Maybe<Scalars['bigint']>;
  emailTemplateId?: Maybe<Scalars['uuid']>;
  opens?: Maybe<Scalars['bigint']>;
  spamcomplaints?: Maybe<Scalars['bigint']>;
  unsubscribes?: Maybe<Scalars['bigint']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "emailCampaignMetrics" */
export type EmailCampaignMetrics_Aggregate = {
  __typename?: 'emailCampaignMetrics_aggregate';
  aggregate?: Maybe<EmailCampaignMetrics_Aggregate_Fields>;
  nodes: Array<EmailCampaignMetrics>;
};

/** aggregate fields of "emailCampaignMetrics" */
export type EmailCampaignMetrics_Aggregate_Fields = {
  __typename?: 'emailCampaignMetrics_aggregate_fields';
  avg?: Maybe<EmailCampaignMetrics_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<EmailCampaignMetrics_Max_Fields>;
  min?: Maybe<EmailCampaignMetrics_Min_Fields>;
  stddev?: Maybe<EmailCampaignMetrics_Stddev_Fields>;
  stddev_pop?: Maybe<EmailCampaignMetrics_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<EmailCampaignMetrics_Stddev_Samp_Fields>;
  sum?: Maybe<EmailCampaignMetrics_Sum_Fields>;
  var_pop?: Maybe<EmailCampaignMetrics_Var_Pop_Fields>;
  var_samp?: Maybe<EmailCampaignMetrics_Var_Samp_Fields>;
  variance?: Maybe<EmailCampaignMetrics_Variance_Fields>;
};


/** aggregate fields of "emailCampaignMetrics" */
export type EmailCampaignMetrics_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<EmailCampaignMetrics_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type EmailCampaignMetrics_Avg_Fields = {
  __typename?: 'emailCampaignMetrics_avg_fields';
  bounces?: Maybe<Scalars['Float']>;
  clicks?: Maybe<Scalars['Float']>;
  deliveries?: Maybe<Scalars['Float']>;
  opens?: Maybe<Scalars['Float']>;
  spamcomplaints?: Maybe<Scalars['Float']>;
  unsubscribes?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "emailCampaignMetrics". All fields are combined with a logical 'AND'. */
export type EmailCampaignMetrics_Bool_Exp = {
  _and?: InputMaybe<Array<EmailCampaignMetrics_Bool_Exp>>;
  _not?: InputMaybe<EmailCampaignMetrics_Bool_Exp>;
  _or?: InputMaybe<Array<EmailCampaignMetrics_Bool_Exp>>;
  bounces?: InputMaybe<Bigint_Comparison_Exp>;
  clicks?: InputMaybe<Bigint_Comparison_Exp>;
  deliveries?: InputMaybe<Bigint_Comparison_Exp>;
  emailTemplateId?: InputMaybe<Uuid_Comparison_Exp>;
  opens?: InputMaybe<Bigint_Comparison_Exp>;
  spamcomplaints?: InputMaybe<Bigint_Comparison_Exp>;
  unsubscribes?: InputMaybe<Bigint_Comparison_Exp>;
  workspaceId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** input type for inserting data into table "emailCampaignMetrics" */
export type EmailCampaignMetrics_Insert_Input = {
  bounces?: InputMaybe<Scalars['bigint']>;
  clicks?: InputMaybe<Scalars['bigint']>;
  deliveries?: InputMaybe<Scalars['bigint']>;
  emailTemplateId?: InputMaybe<Scalars['uuid']>;
  opens?: InputMaybe<Scalars['bigint']>;
  spamcomplaints?: InputMaybe<Scalars['bigint']>;
  unsubscribes?: InputMaybe<Scalars['bigint']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type EmailCampaignMetrics_Max_Fields = {
  __typename?: 'emailCampaignMetrics_max_fields';
  bounces?: Maybe<Scalars['bigint']>;
  clicks?: Maybe<Scalars['bigint']>;
  deliveries?: Maybe<Scalars['bigint']>;
  emailTemplateId?: Maybe<Scalars['uuid']>;
  opens?: Maybe<Scalars['bigint']>;
  spamcomplaints?: Maybe<Scalars['bigint']>;
  unsubscribes?: Maybe<Scalars['bigint']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type EmailCampaignMetrics_Min_Fields = {
  __typename?: 'emailCampaignMetrics_min_fields';
  bounces?: Maybe<Scalars['bigint']>;
  clicks?: Maybe<Scalars['bigint']>;
  deliveries?: Maybe<Scalars['bigint']>;
  emailTemplateId?: Maybe<Scalars['uuid']>;
  opens?: Maybe<Scalars['bigint']>;
  spamcomplaints?: Maybe<Scalars['bigint']>;
  unsubscribes?: Maybe<Scalars['bigint']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** input type for inserting object relation for remote table "emailCampaignMetrics" */
export type EmailCampaignMetrics_Obj_Rel_Insert_Input = {
  data: EmailCampaignMetrics_Insert_Input;
};

/** Ordering options when selecting data from "emailCampaignMetrics". */
export type EmailCampaignMetrics_Order_By = {
  bounces?: InputMaybe<Order_By>;
  clicks?: InputMaybe<Order_By>;
  deliveries?: InputMaybe<Order_By>;
  emailTemplateId?: InputMaybe<Order_By>;
  opens?: InputMaybe<Order_By>;
  spamcomplaints?: InputMaybe<Order_By>;
  unsubscribes?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** select columns of table "emailCampaignMetrics" */
export enum EmailCampaignMetrics_Select_Column {
  /** column name */
  Bounces = 'bounces',
  /** column name */
  Clicks = 'clicks',
  /** column name */
  Deliveries = 'deliveries',
  /** column name */
  EmailTemplateId = 'emailTemplateId',
  /** column name */
  Opens = 'opens',
  /** column name */
  Spamcomplaints = 'spamcomplaints',
  /** column name */
  Unsubscribes = 'unsubscribes',
  /** column name */
  WorkspaceId = 'workspaceId'
}

/** aggregate stddev on columns */
export type EmailCampaignMetrics_Stddev_Fields = {
  __typename?: 'emailCampaignMetrics_stddev_fields';
  bounces?: Maybe<Scalars['Float']>;
  clicks?: Maybe<Scalars['Float']>;
  deliveries?: Maybe<Scalars['Float']>;
  opens?: Maybe<Scalars['Float']>;
  spamcomplaints?: Maybe<Scalars['Float']>;
  unsubscribes?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type EmailCampaignMetrics_Stddev_Pop_Fields = {
  __typename?: 'emailCampaignMetrics_stddev_pop_fields';
  bounces?: Maybe<Scalars['Float']>;
  clicks?: Maybe<Scalars['Float']>;
  deliveries?: Maybe<Scalars['Float']>;
  opens?: Maybe<Scalars['Float']>;
  spamcomplaints?: Maybe<Scalars['Float']>;
  unsubscribes?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type EmailCampaignMetrics_Stddev_Samp_Fields = {
  __typename?: 'emailCampaignMetrics_stddev_samp_fields';
  bounces?: Maybe<Scalars['Float']>;
  clicks?: Maybe<Scalars['Float']>;
  deliveries?: Maybe<Scalars['Float']>;
  opens?: Maybe<Scalars['Float']>;
  spamcomplaints?: Maybe<Scalars['Float']>;
  unsubscribes?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "emailCampaignMetrics" */
export type EmailCampaignMetrics_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: EmailCampaignMetrics_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type EmailCampaignMetrics_Stream_Cursor_Value_Input = {
  bounces?: InputMaybe<Scalars['bigint']>;
  clicks?: InputMaybe<Scalars['bigint']>;
  deliveries?: InputMaybe<Scalars['bigint']>;
  emailTemplateId?: InputMaybe<Scalars['uuid']>;
  opens?: InputMaybe<Scalars['bigint']>;
  spamcomplaints?: InputMaybe<Scalars['bigint']>;
  unsubscribes?: InputMaybe<Scalars['bigint']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type EmailCampaignMetrics_Sum_Fields = {
  __typename?: 'emailCampaignMetrics_sum_fields';
  bounces?: Maybe<Scalars['bigint']>;
  clicks?: Maybe<Scalars['bigint']>;
  deliveries?: Maybe<Scalars['bigint']>;
  opens?: Maybe<Scalars['bigint']>;
  spamcomplaints?: Maybe<Scalars['bigint']>;
  unsubscribes?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type EmailCampaignMetrics_Var_Pop_Fields = {
  __typename?: 'emailCampaignMetrics_var_pop_fields';
  bounces?: Maybe<Scalars['Float']>;
  clicks?: Maybe<Scalars['Float']>;
  deliveries?: Maybe<Scalars['Float']>;
  opens?: Maybe<Scalars['Float']>;
  spamcomplaints?: Maybe<Scalars['Float']>;
  unsubscribes?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type EmailCampaignMetrics_Var_Samp_Fields = {
  __typename?: 'emailCampaignMetrics_var_samp_fields';
  bounces?: Maybe<Scalars['Float']>;
  clicks?: Maybe<Scalars['Float']>;
  deliveries?: Maybe<Scalars['Float']>;
  opens?: Maybe<Scalars['Float']>;
  spamcomplaints?: Maybe<Scalars['Float']>;
  unsubscribes?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type EmailCampaignMetrics_Variance_Fields = {
  __typename?: 'emailCampaignMetrics_variance_fields';
  bounces?: Maybe<Scalars['Float']>;
  clicks?: Maybe<Scalars['Float']>;
  deliveries?: Maybe<Scalars['Float']>;
  opens?: Maybe<Scalars['Float']>;
  spamcomplaints?: Maybe<Scalars['Float']>;
  unsubscribes?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "emailTemplate" */
export type EmailTemplate = {
  __typename?: 'emailTemplate';
  allowResend: Scalars['Boolean'];
  audienceType: Scalars['String'];
  bounces: Scalars['Int'];
  clicks: Scalars['Int'];
  deliveries: Scalars['Int'];
  description?: Maybe<Scalars['String']>;
  design: Scalars['jsonb'];
  /** An array relationship */
  emailCampaignLogs: Array<EmailCampaignLog>;
  /** An aggregate relationship */
  emailCampaignLogs_aggregate: EmailCampaignLog_Aggregate;
  emailName?: Maybe<Scalars['String']>;
  emailTemplateScreenshot?: Maybe<FileOnlyUrl>;
  fromAddress?: Maybe<Scalars['String']>;
  fromName?: Maybe<Scalars['String']>;
  html?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  includesPromotion: Scalars['Boolean'];
  inngestJobId?: Maybe<Scalars['String']>;
  isActive: Scalars['Boolean'];
  isArchived?: Maybe<Scalars['Boolean']>;
  isTemplate: Scalars['Boolean'];
  jobId?: Maybe<Scalars['uuid']>;
  /** A computed field, executes function "email_template_last_sent" */
  lastSentDate?: Maybe<Scalars['timestamptz']>;
  /** An array relationship */
  marketingTags: Array<MarketingTagEmail>;
  /** An aggregate relationship */
  marketingTags_aggregate: MarketingTagEmail_Aggregate;
  /** An object relationship */
  metrics?: Maybe<EmailCampaignMetrics>;
  /** An object relationship */
  omitAudience?: Maybe<Audience>;
  omitAudienceId?: Maybe<Scalars['uuid']>;
  opens: Scalars['Int'];
  /** Unlayer template JSON definition */
  outputJson?: Maybe<Scalars['String']>;
  receivedPatients: Scalars['jsonb'];
  replies: Scalars['Int'];
  resendWindow?: Maybe<Scalars['Int']>;
  sendDateTime?: Maybe<Scalars['timestamptz']>;
  spamFlags: Scalars['Int'];
  /** A computed field, executes function "compute_email_template_state" */
  state?: Maybe<Scalars['String']>;
  subjectLine?: Maybe<Scalars['String']>;
  /** An object relationship */
  targetAudience?: Maybe<Audience>;
  targetAudienceId?: Maybe<Scalars['uuid']>;
  thumbnailUrl?: Maybe<Scalars['String']>;
  unsubscribes: Scalars['Int'];
  updatedAt: Scalars['timestamptz'];
  workspaceId: Scalars['uuid'];
};


/** columns and relationships of "emailTemplate" */
export type EmailTemplateDesignArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "emailTemplate" */
export type EmailTemplateEmailCampaignLogsArgs = {
  distinct_on?: InputMaybe<Array<EmailCampaignLog_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EmailCampaignLog_Order_By>>;
  where?: InputMaybe<EmailCampaignLog_Bool_Exp>;
};


/** columns and relationships of "emailTemplate" */
export type EmailTemplateEmailCampaignLogs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<EmailCampaignLog_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EmailCampaignLog_Order_By>>;
  where?: InputMaybe<EmailCampaignLog_Bool_Exp>;
};


/** columns and relationships of "emailTemplate" */
export type EmailTemplateMarketingTagsArgs = {
  distinct_on?: InputMaybe<Array<MarketingTagEmail_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MarketingTagEmail_Order_By>>;
  where?: InputMaybe<MarketingTagEmail_Bool_Exp>;
};


/** columns and relationships of "emailTemplate" */
export type EmailTemplateMarketingTags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<MarketingTagEmail_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MarketingTagEmail_Order_By>>;
  where?: InputMaybe<MarketingTagEmail_Bool_Exp>;
};


/** columns and relationships of "emailTemplate" */
export type EmailTemplateReceivedPatientsArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "emailTemplatePatient" */
export type EmailTemplatePatient = {
  __typename?: 'emailTemplatePatient';
  emailTemplateId: Scalars['uuid'];
  id: Scalars['uuid'];
  patientWorkspaceId: Scalars['uuid'];
  sendDate: Scalars['timestamptz'];
  workspaceId: Scalars['uuid'];
};

/** aggregated selection of "emailTemplatePatient" */
export type EmailTemplatePatient_Aggregate = {
  __typename?: 'emailTemplatePatient_aggregate';
  aggregate?: Maybe<EmailTemplatePatient_Aggregate_Fields>;
  nodes: Array<EmailTemplatePatient>;
};

/** aggregate fields of "emailTemplatePatient" */
export type EmailTemplatePatient_Aggregate_Fields = {
  __typename?: 'emailTemplatePatient_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<EmailTemplatePatient_Max_Fields>;
  min?: Maybe<EmailTemplatePatient_Min_Fields>;
};


/** aggregate fields of "emailTemplatePatient" */
export type EmailTemplatePatient_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<EmailTemplatePatient_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "emailTemplatePatient". All fields are combined with a logical 'AND'. */
export type EmailTemplatePatient_Bool_Exp = {
  _and?: InputMaybe<Array<EmailTemplatePatient_Bool_Exp>>;
  _not?: InputMaybe<EmailTemplatePatient_Bool_Exp>;
  _or?: InputMaybe<Array<EmailTemplatePatient_Bool_Exp>>;
  emailTemplateId?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  patientWorkspaceId?: InputMaybe<Uuid_Comparison_Exp>;
  sendDate?: InputMaybe<Timestamptz_Comparison_Exp>;
  workspaceId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "emailTemplatePatient" */
export enum EmailTemplatePatient_Constraint {
  /** unique or primary key constraint on columns "id" */
  EmailTemplatePatientPkey = 'emailTemplatePatient_pkey'
}

/** input type for inserting data into table "emailTemplatePatient" */
export type EmailTemplatePatient_Insert_Input = {
  emailTemplateId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  patientWorkspaceId?: InputMaybe<Scalars['uuid']>;
  sendDate?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type EmailTemplatePatient_Max_Fields = {
  __typename?: 'emailTemplatePatient_max_fields';
  emailTemplateId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  patientWorkspaceId?: Maybe<Scalars['uuid']>;
  sendDate?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type EmailTemplatePatient_Min_Fields = {
  __typename?: 'emailTemplatePatient_min_fields';
  emailTemplateId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  patientWorkspaceId?: Maybe<Scalars['uuid']>;
  sendDate?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "emailTemplatePatient" */
export type EmailTemplatePatient_Mutation_Response = {
  __typename?: 'emailTemplatePatient_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<EmailTemplatePatient>;
};

/** on_conflict condition type for table "emailTemplatePatient" */
export type EmailTemplatePatient_On_Conflict = {
  constraint: EmailTemplatePatient_Constraint;
  update_columns?: Array<EmailTemplatePatient_Update_Column>;
  where?: InputMaybe<EmailTemplatePatient_Bool_Exp>;
};

/** Ordering options when selecting data from "emailTemplatePatient". */
export type EmailTemplatePatient_Order_By = {
  emailTemplateId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patientWorkspaceId?: InputMaybe<Order_By>;
  sendDate?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: emailTemplatePatient */
export type EmailTemplatePatient_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "emailTemplatePatient" */
export enum EmailTemplatePatient_Select_Column {
  /** column name */
  EmailTemplateId = 'emailTemplateId',
  /** column name */
  Id = 'id',
  /** column name */
  PatientWorkspaceId = 'patientWorkspaceId',
  /** column name */
  SendDate = 'sendDate',
  /** column name */
  WorkspaceId = 'workspaceId'
}

/** input type for updating data in table "emailTemplatePatient" */
export type EmailTemplatePatient_Set_Input = {
  emailTemplateId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  patientWorkspaceId?: InputMaybe<Scalars['uuid']>;
  sendDate?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "emailTemplatePatient" */
export type EmailTemplatePatient_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: EmailTemplatePatient_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type EmailTemplatePatient_Stream_Cursor_Value_Input = {
  emailTemplateId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  patientWorkspaceId?: InputMaybe<Scalars['uuid']>;
  sendDate?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "emailTemplatePatient" */
export enum EmailTemplatePatient_Update_Column {
  /** column name */
  EmailTemplateId = 'emailTemplateId',
  /** column name */
  Id = 'id',
  /** column name */
  PatientWorkspaceId = 'patientWorkspaceId',
  /** column name */
  SendDate = 'sendDate',
  /** column name */
  WorkspaceId = 'workspaceId'
}

export type EmailTemplatePatient_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<EmailTemplatePatient_Set_Input>;
  /** filter the rows which have to be updated */
  where: EmailTemplatePatient_Bool_Exp;
};

/** columns and relationships of "emailTemplateSendQueue" */
export type EmailTemplateSendQueue = {
  __typename?: 'emailTemplateSendQueue';
  createdAt: Scalars['timestamp'];
  emailTemplateId: Scalars['uuid'];
  id: Scalars['uuid'];
  patientWorkspaceId: Scalars['uuid'];
  sendDate?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "emailTemplateSendQueue" */
export type EmailTemplateSendQueue_Aggregate = {
  __typename?: 'emailTemplateSendQueue_aggregate';
  aggregate?: Maybe<EmailTemplateSendQueue_Aggregate_Fields>;
  nodes: Array<EmailTemplateSendQueue>;
};

/** aggregate fields of "emailTemplateSendQueue" */
export type EmailTemplateSendQueue_Aggregate_Fields = {
  __typename?: 'emailTemplateSendQueue_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<EmailTemplateSendQueue_Max_Fields>;
  min?: Maybe<EmailTemplateSendQueue_Min_Fields>;
};


/** aggregate fields of "emailTemplateSendQueue" */
export type EmailTemplateSendQueue_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<EmailTemplateSendQueue_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "emailTemplateSendQueue". All fields are combined with a logical 'AND'. */
export type EmailTemplateSendQueue_Bool_Exp = {
  _and?: InputMaybe<Array<EmailTemplateSendQueue_Bool_Exp>>;
  _not?: InputMaybe<EmailTemplateSendQueue_Bool_Exp>;
  _or?: InputMaybe<Array<EmailTemplateSendQueue_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamp_Comparison_Exp>;
  emailTemplateId?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  patientWorkspaceId?: InputMaybe<Uuid_Comparison_Exp>;
  sendDate?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "emailTemplateSendQueue" */
export enum EmailTemplateSendQueue_Constraint {
  /** unique or primary key constraint on columns "id" */
  EmailTemplateSendQueuePkey = 'emailTemplateSendQueue_pkey'
}

/** input type for inserting data into table "emailTemplateSendQueue" */
export type EmailTemplateSendQueue_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamp']>;
  emailTemplateId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  patientWorkspaceId?: InputMaybe<Scalars['uuid']>;
  sendDate?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type EmailTemplateSendQueue_Max_Fields = {
  __typename?: 'emailTemplateSendQueue_max_fields';
  createdAt?: Maybe<Scalars['timestamp']>;
  emailTemplateId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  patientWorkspaceId?: Maybe<Scalars['uuid']>;
  sendDate?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type EmailTemplateSendQueue_Min_Fields = {
  __typename?: 'emailTemplateSendQueue_min_fields';
  createdAt?: Maybe<Scalars['timestamp']>;
  emailTemplateId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  patientWorkspaceId?: Maybe<Scalars['uuid']>;
  sendDate?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "emailTemplateSendQueue" */
export type EmailTemplateSendQueue_Mutation_Response = {
  __typename?: 'emailTemplateSendQueue_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<EmailTemplateSendQueue>;
};

/** on_conflict condition type for table "emailTemplateSendQueue" */
export type EmailTemplateSendQueue_On_Conflict = {
  constraint: EmailTemplateSendQueue_Constraint;
  update_columns?: Array<EmailTemplateSendQueue_Update_Column>;
  where?: InputMaybe<EmailTemplateSendQueue_Bool_Exp>;
};

/** Ordering options when selecting data from "emailTemplateSendQueue". */
export type EmailTemplateSendQueue_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  emailTemplateId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patientWorkspaceId?: InputMaybe<Order_By>;
  sendDate?: InputMaybe<Order_By>;
};

/** primary key columns input for table: emailTemplateSendQueue */
export type EmailTemplateSendQueue_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "emailTemplateSendQueue" */
export enum EmailTemplateSendQueue_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  EmailTemplateId = 'emailTemplateId',
  /** column name */
  Id = 'id',
  /** column name */
  PatientWorkspaceId = 'patientWorkspaceId',
  /** column name */
  SendDate = 'sendDate'
}

/** input type for updating data in table "emailTemplateSendQueue" */
export type EmailTemplateSendQueue_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamp']>;
  emailTemplateId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  patientWorkspaceId?: InputMaybe<Scalars['uuid']>;
  sendDate?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "emailTemplateSendQueue" */
export type EmailTemplateSendQueue_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: EmailTemplateSendQueue_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type EmailTemplateSendQueue_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamp']>;
  emailTemplateId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  patientWorkspaceId?: InputMaybe<Scalars['uuid']>;
  sendDate?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "emailTemplateSendQueue" */
export enum EmailTemplateSendQueue_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  EmailTemplateId = 'emailTemplateId',
  /** column name */
  Id = 'id',
  /** column name */
  PatientWorkspaceId = 'patientWorkspaceId',
  /** column name */
  SendDate = 'sendDate'
}

export type EmailTemplateSendQueue_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<EmailTemplateSendQueue_Set_Input>;
  /** filter the rows which have to be updated */
  where: EmailTemplateSendQueue_Bool_Exp;
};

/** aggregated selection of "emailTemplate" */
export type EmailTemplate_Aggregate = {
  __typename?: 'emailTemplate_aggregate';
  aggregate?: Maybe<EmailTemplate_Aggregate_Fields>;
  nodes: Array<EmailTemplate>;
};

/** aggregate fields of "emailTemplate" */
export type EmailTemplate_Aggregate_Fields = {
  __typename?: 'emailTemplate_aggregate_fields';
  avg?: Maybe<EmailTemplate_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<EmailTemplate_Max_Fields>;
  min?: Maybe<EmailTemplate_Min_Fields>;
  stddev?: Maybe<EmailTemplate_Stddev_Fields>;
  stddev_pop?: Maybe<EmailTemplate_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<EmailTemplate_Stddev_Samp_Fields>;
  sum?: Maybe<EmailTemplate_Sum_Fields>;
  var_pop?: Maybe<EmailTemplate_Var_Pop_Fields>;
  var_samp?: Maybe<EmailTemplate_Var_Samp_Fields>;
  variance?: Maybe<EmailTemplate_Variance_Fields>;
};


/** aggregate fields of "emailTemplate" */
export type EmailTemplate_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<EmailTemplate_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type EmailTemplate_Append_Input = {
  design?: InputMaybe<Scalars['jsonb']>;
  receivedPatients?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type EmailTemplate_Avg_Fields = {
  __typename?: 'emailTemplate_avg_fields';
  bounces?: Maybe<Scalars['Float']>;
  clicks?: Maybe<Scalars['Float']>;
  deliveries?: Maybe<Scalars['Float']>;
  opens?: Maybe<Scalars['Float']>;
  replies?: Maybe<Scalars['Float']>;
  resendWindow?: Maybe<Scalars['Float']>;
  spamFlags?: Maybe<Scalars['Float']>;
  unsubscribes?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "emailTemplate". All fields are combined with a logical 'AND'. */
export type EmailTemplate_Bool_Exp = {
  _and?: InputMaybe<Array<EmailTemplate_Bool_Exp>>;
  _not?: InputMaybe<EmailTemplate_Bool_Exp>;
  _or?: InputMaybe<Array<EmailTemplate_Bool_Exp>>;
  allowResend?: InputMaybe<Boolean_Comparison_Exp>;
  audienceType?: InputMaybe<String_Comparison_Exp>;
  bounces?: InputMaybe<Int_Comparison_Exp>;
  clicks?: InputMaybe<Int_Comparison_Exp>;
  deliveries?: InputMaybe<Int_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  design?: InputMaybe<Jsonb_Comparison_Exp>;
  emailCampaignLogs?: InputMaybe<EmailCampaignLog_Bool_Exp>;
  emailCampaignLogs_aggregate?: InputMaybe<EmailCampaignLog_Aggregate_Bool_Exp>;
  emailName?: InputMaybe<String_Comparison_Exp>;
  fromAddress?: InputMaybe<String_Comparison_Exp>;
  fromName?: InputMaybe<String_Comparison_Exp>;
  html?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  includesPromotion?: InputMaybe<Boolean_Comparison_Exp>;
  inngestJobId?: InputMaybe<String_Comparison_Exp>;
  isActive?: InputMaybe<Boolean_Comparison_Exp>;
  isArchived?: InputMaybe<Boolean_Comparison_Exp>;
  isTemplate?: InputMaybe<Boolean_Comparison_Exp>;
  jobId?: InputMaybe<Uuid_Comparison_Exp>;
  lastSentDate?: InputMaybe<Timestamptz_Comparison_Exp>;
  marketingTags?: InputMaybe<MarketingTagEmail_Bool_Exp>;
  marketingTags_aggregate?: InputMaybe<MarketingTagEmail_Aggregate_Bool_Exp>;
  metrics?: InputMaybe<EmailCampaignMetrics_Bool_Exp>;
  omitAudience?: InputMaybe<Audience_Bool_Exp>;
  omitAudienceId?: InputMaybe<Uuid_Comparison_Exp>;
  opens?: InputMaybe<Int_Comparison_Exp>;
  outputJson?: InputMaybe<String_Comparison_Exp>;
  receivedPatients?: InputMaybe<Jsonb_Comparison_Exp>;
  replies?: InputMaybe<Int_Comparison_Exp>;
  resendWindow?: InputMaybe<Int_Comparison_Exp>;
  sendDateTime?: InputMaybe<Timestamptz_Comparison_Exp>;
  spamFlags?: InputMaybe<Int_Comparison_Exp>;
  state?: InputMaybe<String_Comparison_Exp>;
  subjectLine?: InputMaybe<String_Comparison_Exp>;
  targetAudience?: InputMaybe<Audience_Bool_Exp>;
  targetAudienceId?: InputMaybe<Uuid_Comparison_Exp>;
  thumbnailUrl?: InputMaybe<String_Comparison_Exp>;
  unsubscribes?: InputMaybe<Int_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  workspaceId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "emailTemplate" */
export enum EmailTemplate_Constraint {
  /** unique or primary key constraint on columns "id" */
  EmailTemplatePkey = 'emailTemplate_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type EmailTemplate_Delete_At_Path_Input = {
  design?: InputMaybe<Array<Scalars['String']>>;
  receivedPatients?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type EmailTemplate_Delete_Elem_Input = {
  design?: InputMaybe<Scalars['Int']>;
  receivedPatients?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type EmailTemplate_Delete_Key_Input = {
  design?: InputMaybe<Scalars['String']>;
  receivedPatients?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "emailTemplate" */
export type EmailTemplate_Inc_Input = {
  bounces?: InputMaybe<Scalars['Int']>;
  clicks?: InputMaybe<Scalars['Int']>;
  deliveries?: InputMaybe<Scalars['Int']>;
  opens?: InputMaybe<Scalars['Int']>;
  replies?: InputMaybe<Scalars['Int']>;
  resendWindow?: InputMaybe<Scalars['Int']>;
  spamFlags?: InputMaybe<Scalars['Int']>;
  unsubscribes?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "emailTemplate" */
export type EmailTemplate_Insert_Input = {
  allowResend?: InputMaybe<Scalars['Boolean']>;
  audienceType?: InputMaybe<Scalars['String']>;
  bounces?: InputMaybe<Scalars['Int']>;
  clicks?: InputMaybe<Scalars['Int']>;
  deliveries?: InputMaybe<Scalars['Int']>;
  description?: InputMaybe<Scalars['String']>;
  design?: InputMaybe<Scalars['jsonb']>;
  emailCampaignLogs?: InputMaybe<EmailCampaignLog_Arr_Rel_Insert_Input>;
  emailName?: InputMaybe<Scalars['String']>;
  fromAddress?: InputMaybe<Scalars['String']>;
  fromName?: InputMaybe<Scalars['String']>;
  html?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  includesPromotion?: InputMaybe<Scalars['Boolean']>;
  inngestJobId?: InputMaybe<Scalars['String']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  isArchived?: InputMaybe<Scalars['Boolean']>;
  isTemplate?: InputMaybe<Scalars['Boolean']>;
  jobId?: InputMaybe<Scalars['uuid']>;
  marketingTags?: InputMaybe<MarketingTagEmail_Arr_Rel_Insert_Input>;
  metrics?: InputMaybe<EmailCampaignMetrics_Obj_Rel_Insert_Input>;
  omitAudience?: InputMaybe<Audience_Obj_Rel_Insert_Input>;
  omitAudienceId?: InputMaybe<Scalars['uuid']>;
  opens?: InputMaybe<Scalars['Int']>;
  /** Unlayer template JSON definition */
  outputJson?: InputMaybe<Scalars['String']>;
  receivedPatients?: InputMaybe<Scalars['jsonb']>;
  replies?: InputMaybe<Scalars['Int']>;
  resendWindow?: InputMaybe<Scalars['Int']>;
  sendDateTime?: InputMaybe<Scalars['timestamptz']>;
  spamFlags?: InputMaybe<Scalars['Int']>;
  subjectLine?: InputMaybe<Scalars['String']>;
  targetAudience?: InputMaybe<Audience_Obj_Rel_Insert_Input>;
  targetAudienceId?: InputMaybe<Scalars['uuid']>;
  thumbnailUrl?: InputMaybe<Scalars['String']>;
  unsubscribes?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type EmailTemplate_Max_Fields = {
  __typename?: 'emailTemplate_max_fields';
  audienceType?: Maybe<Scalars['String']>;
  bounces?: Maybe<Scalars['Int']>;
  clicks?: Maybe<Scalars['Int']>;
  deliveries?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  emailName?: Maybe<Scalars['String']>;
  fromAddress?: Maybe<Scalars['String']>;
  fromName?: Maybe<Scalars['String']>;
  html?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  inngestJobId?: Maybe<Scalars['String']>;
  jobId?: Maybe<Scalars['uuid']>;
  /** A computed field, executes function "email_template_last_sent" */
  lastSentDate?: Maybe<Scalars['timestamptz']>;
  omitAudienceId?: Maybe<Scalars['uuid']>;
  opens?: Maybe<Scalars['Int']>;
  /** Unlayer template JSON definition */
  outputJson?: Maybe<Scalars['String']>;
  replies?: Maybe<Scalars['Int']>;
  resendWindow?: Maybe<Scalars['Int']>;
  sendDateTime?: Maybe<Scalars['timestamptz']>;
  spamFlags?: Maybe<Scalars['Int']>;
  /** A computed field, executes function "compute_email_template_state" */
  state?: Maybe<Scalars['String']>;
  subjectLine?: Maybe<Scalars['String']>;
  targetAudienceId?: Maybe<Scalars['uuid']>;
  thumbnailUrl?: Maybe<Scalars['String']>;
  unsubscribes?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type EmailTemplate_Min_Fields = {
  __typename?: 'emailTemplate_min_fields';
  audienceType?: Maybe<Scalars['String']>;
  bounces?: Maybe<Scalars['Int']>;
  clicks?: Maybe<Scalars['Int']>;
  deliveries?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  emailName?: Maybe<Scalars['String']>;
  fromAddress?: Maybe<Scalars['String']>;
  fromName?: Maybe<Scalars['String']>;
  html?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  inngestJobId?: Maybe<Scalars['String']>;
  jobId?: Maybe<Scalars['uuid']>;
  /** A computed field, executes function "email_template_last_sent" */
  lastSentDate?: Maybe<Scalars['timestamptz']>;
  omitAudienceId?: Maybe<Scalars['uuid']>;
  opens?: Maybe<Scalars['Int']>;
  /** Unlayer template JSON definition */
  outputJson?: Maybe<Scalars['String']>;
  replies?: Maybe<Scalars['Int']>;
  resendWindow?: Maybe<Scalars['Int']>;
  sendDateTime?: Maybe<Scalars['timestamptz']>;
  spamFlags?: Maybe<Scalars['Int']>;
  /** A computed field, executes function "compute_email_template_state" */
  state?: Maybe<Scalars['String']>;
  subjectLine?: Maybe<Scalars['String']>;
  targetAudienceId?: Maybe<Scalars['uuid']>;
  thumbnailUrl?: Maybe<Scalars['String']>;
  unsubscribes?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "emailTemplate" */
export type EmailTemplate_Mutation_Response = {
  __typename?: 'emailTemplate_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<EmailTemplate>;
};

/** input type for inserting object relation for remote table "emailTemplate" */
export type EmailTemplate_Obj_Rel_Insert_Input = {
  data: EmailTemplate_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<EmailTemplate_On_Conflict>;
};

/** on_conflict condition type for table "emailTemplate" */
export type EmailTemplate_On_Conflict = {
  constraint: EmailTemplate_Constraint;
  update_columns?: Array<EmailTemplate_Update_Column>;
  where?: InputMaybe<EmailTemplate_Bool_Exp>;
};

/** Ordering options when selecting data from "emailTemplate". */
export type EmailTemplate_Order_By = {
  allowResend?: InputMaybe<Order_By>;
  audienceType?: InputMaybe<Order_By>;
  bounces?: InputMaybe<Order_By>;
  clicks?: InputMaybe<Order_By>;
  deliveries?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  design?: InputMaybe<Order_By>;
  emailCampaignLogs_aggregate?: InputMaybe<EmailCampaignLog_Aggregate_Order_By>;
  emailName?: InputMaybe<Order_By>;
  fromAddress?: InputMaybe<Order_By>;
  fromName?: InputMaybe<Order_By>;
  html?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  includesPromotion?: InputMaybe<Order_By>;
  inngestJobId?: InputMaybe<Order_By>;
  isActive?: InputMaybe<Order_By>;
  isArchived?: InputMaybe<Order_By>;
  isTemplate?: InputMaybe<Order_By>;
  jobId?: InputMaybe<Order_By>;
  lastSentDate?: InputMaybe<Order_By>;
  marketingTags_aggregate?: InputMaybe<MarketingTagEmail_Aggregate_Order_By>;
  metrics?: InputMaybe<EmailCampaignMetrics_Order_By>;
  omitAudience?: InputMaybe<Audience_Order_By>;
  omitAudienceId?: InputMaybe<Order_By>;
  opens?: InputMaybe<Order_By>;
  outputJson?: InputMaybe<Order_By>;
  receivedPatients?: InputMaybe<Order_By>;
  replies?: InputMaybe<Order_By>;
  resendWindow?: InputMaybe<Order_By>;
  sendDateTime?: InputMaybe<Order_By>;
  spamFlags?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  subjectLine?: InputMaybe<Order_By>;
  targetAudience?: InputMaybe<Audience_Order_By>;
  targetAudienceId?: InputMaybe<Order_By>;
  thumbnailUrl?: InputMaybe<Order_By>;
  unsubscribes?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: emailTemplate */
export type EmailTemplate_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type EmailTemplate_Prepend_Input = {
  design?: InputMaybe<Scalars['jsonb']>;
  receivedPatients?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "emailTemplate" */
export enum EmailTemplate_Select_Column {
  /** column name */
  AllowResend = 'allowResend',
  /** column name */
  AudienceType = 'audienceType',
  /** column name */
  Bounces = 'bounces',
  /** column name */
  Clicks = 'clicks',
  /** column name */
  Deliveries = 'deliveries',
  /** column name */
  Description = 'description',
  /** column name */
  Design = 'design',
  /** column name */
  EmailName = 'emailName',
  /** column name */
  FromAddress = 'fromAddress',
  /** column name */
  FromName = 'fromName',
  /** column name */
  Html = 'html',
  /** column name */
  Id = 'id',
  /** column name */
  IncludesPromotion = 'includesPromotion',
  /** column name */
  InngestJobId = 'inngestJobId',
  /** column name */
  IsActive = 'isActive',
  /** column name */
  IsArchived = 'isArchived',
  /** column name */
  IsTemplate = 'isTemplate',
  /** column name */
  JobId = 'jobId',
  /** column name */
  OmitAudienceId = 'omitAudienceId',
  /** column name */
  Opens = 'opens',
  /** column name */
  OutputJson = 'outputJson',
  /** column name */
  ReceivedPatients = 'receivedPatients',
  /** column name */
  Replies = 'replies',
  /** column name */
  ResendWindow = 'resendWindow',
  /** column name */
  SendDateTime = 'sendDateTime',
  /** column name */
  SpamFlags = 'spamFlags',
  /** column name */
  SubjectLine = 'subjectLine',
  /** column name */
  TargetAudienceId = 'targetAudienceId',
  /** column name */
  ThumbnailUrl = 'thumbnailUrl',
  /** column name */
  Unsubscribes = 'unsubscribes',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WorkspaceId = 'workspaceId'
}

/** input type for updating data in table "emailTemplate" */
export type EmailTemplate_Set_Input = {
  allowResend?: InputMaybe<Scalars['Boolean']>;
  audienceType?: InputMaybe<Scalars['String']>;
  bounces?: InputMaybe<Scalars['Int']>;
  clicks?: InputMaybe<Scalars['Int']>;
  deliveries?: InputMaybe<Scalars['Int']>;
  description?: InputMaybe<Scalars['String']>;
  design?: InputMaybe<Scalars['jsonb']>;
  emailName?: InputMaybe<Scalars['String']>;
  fromAddress?: InputMaybe<Scalars['String']>;
  fromName?: InputMaybe<Scalars['String']>;
  html?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  includesPromotion?: InputMaybe<Scalars['Boolean']>;
  inngestJobId?: InputMaybe<Scalars['String']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  isArchived?: InputMaybe<Scalars['Boolean']>;
  isTemplate?: InputMaybe<Scalars['Boolean']>;
  jobId?: InputMaybe<Scalars['uuid']>;
  omitAudienceId?: InputMaybe<Scalars['uuid']>;
  opens?: InputMaybe<Scalars['Int']>;
  /** Unlayer template JSON definition */
  outputJson?: InputMaybe<Scalars['String']>;
  receivedPatients?: InputMaybe<Scalars['jsonb']>;
  replies?: InputMaybe<Scalars['Int']>;
  resendWindow?: InputMaybe<Scalars['Int']>;
  sendDateTime?: InputMaybe<Scalars['timestamptz']>;
  spamFlags?: InputMaybe<Scalars['Int']>;
  subjectLine?: InputMaybe<Scalars['String']>;
  targetAudienceId?: InputMaybe<Scalars['uuid']>;
  thumbnailUrl?: InputMaybe<Scalars['String']>;
  unsubscribes?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type EmailTemplate_Stddev_Fields = {
  __typename?: 'emailTemplate_stddev_fields';
  bounces?: Maybe<Scalars['Float']>;
  clicks?: Maybe<Scalars['Float']>;
  deliveries?: Maybe<Scalars['Float']>;
  opens?: Maybe<Scalars['Float']>;
  replies?: Maybe<Scalars['Float']>;
  resendWindow?: Maybe<Scalars['Float']>;
  spamFlags?: Maybe<Scalars['Float']>;
  unsubscribes?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type EmailTemplate_Stddev_Pop_Fields = {
  __typename?: 'emailTemplate_stddev_pop_fields';
  bounces?: Maybe<Scalars['Float']>;
  clicks?: Maybe<Scalars['Float']>;
  deliveries?: Maybe<Scalars['Float']>;
  opens?: Maybe<Scalars['Float']>;
  replies?: Maybe<Scalars['Float']>;
  resendWindow?: Maybe<Scalars['Float']>;
  spamFlags?: Maybe<Scalars['Float']>;
  unsubscribes?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type EmailTemplate_Stddev_Samp_Fields = {
  __typename?: 'emailTemplate_stddev_samp_fields';
  bounces?: Maybe<Scalars['Float']>;
  clicks?: Maybe<Scalars['Float']>;
  deliveries?: Maybe<Scalars['Float']>;
  opens?: Maybe<Scalars['Float']>;
  replies?: Maybe<Scalars['Float']>;
  resendWindow?: Maybe<Scalars['Float']>;
  spamFlags?: Maybe<Scalars['Float']>;
  unsubscribes?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "emailTemplate" */
export type EmailTemplate_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: EmailTemplate_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type EmailTemplate_Stream_Cursor_Value_Input = {
  allowResend?: InputMaybe<Scalars['Boolean']>;
  audienceType?: InputMaybe<Scalars['String']>;
  bounces?: InputMaybe<Scalars['Int']>;
  clicks?: InputMaybe<Scalars['Int']>;
  deliveries?: InputMaybe<Scalars['Int']>;
  description?: InputMaybe<Scalars['String']>;
  design?: InputMaybe<Scalars['jsonb']>;
  emailName?: InputMaybe<Scalars['String']>;
  fromAddress?: InputMaybe<Scalars['String']>;
  fromName?: InputMaybe<Scalars['String']>;
  html?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  includesPromotion?: InputMaybe<Scalars['Boolean']>;
  inngestJobId?: InputMaybe<Scalars['String']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  isArchived?: InputMaybe<Scalars['Boolean']>;
  isTemplate?: InputMaybe<Scalars['Boolean']>;
  jobId?: InputMaybe<Scalars['uuid']>;
  omitAudienceId?: InputMaybe<Scalars['uuid']>;
  opens?: InputMaybe<Scalars['Int']>;
  /** Unlayer template JSON definition */
  outputJson?: InputMaybe<Scalars['String']>;
  receivedPatients?: InputMaybe<Scalars['jsonb']>;
  replies?: InputMaybe<Scalars['Int']>;
  resendWindow?: InputMaybe<Scalars['Int']>;
  sendDateTime?: InputMaybe<Scalars['timestamptz']>;
  spamFlags?: InputMaybe<Scalars['Int']>;
  subjectLine?: InputMaybe<Scalars['String']>;
  targetAudienceId?: InputMaybe<Scalars['uuid']>;
  thumbnailUrl?: InputMaybe<Scalars['String']>;
  unsubscribes?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type EmailTemplate_Sum_Fields = {
  __typename?: 'emailTemplate_sum_fields';
  bounces?: Maybe<Scalars['Int']>;
  clicks?: Maybe<Scalars['Int']>;
  deliveries?: Maybe<Scalars['Int']>;
  opens?: Maybe<Scalars['Int']>;
  replies?: Maybe<Scalars['Int']>;
  resendWindow?: Maybe<Scalars['Int']>;
  spamFlags?: Maybe<Scalars['Int']>;
  unsubscribes?: Maybe<Scalars['Int']>;
};

/** update columns of table "emailTemplate" */
export enum EmailTemplate_Update_Column {
  /** column name */
  AllowResend = 'allowResend',
  /** column name */
  AudienceType = 'audienceType',
  /** column name */
  Bounces = 'bounces',
  /** column name */
  Clicks = 'clicks',
  /** column name */
  Deliveries = 'deliveries',
  /** column name */
  Description = 'description',
  /** column name */
  Design = 'design',
  /** column name */
  EmailName = 'emailName',
  /** column name */
  FromAddress = 'fromAddress',
  /** column name */
  FromName = 'fromName',
  /** column name */
  Html = 'html',
  /** column name */
  Id = 'id',
  /** column name */
  IncludesPromotion = 'includesPromotion',
  /** column name */
  InngestJobId = 'inngestJobId',
  /** column name */
  IsActive = 'isActive',
  /** column name */
  IsArchived = 'isArchived',
  /** column name */
  IsTemplate = 'isTemplate',
  /** column name */
  JobId = 'jobId',
  /** column name */
  OmitAudienceId = 'omitAudienceId',
  /** column name */
  Opens = 'opens',
  /** column name */
  OutputJson = 'outputJson',
  /** column name */
  ReceivedPatients = 'receivedPatients',
  /** column name */
  Replies = 'replies',
  /** column name */
  ResendWindow = 'resendWindow',
  /** column name */
  SendDateTime = 'sendDateTime',
  /** column name */
  SpamFlags = 'spamFlags',
  /** column name */
  SubjectLine = 'subjectLine',
  /** column name */
  TargetAudienceId = 'targetAudienceId',
  /** column name */
  ThumbnailUrl = 'thumbnailUrl',
  /** column name */
  Unsubscribes = 'unsubscribes',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WorkspaceId = 'workspaceId'
}

export type EmailTemplate_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<EmailTemplate_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<EmailTemplate_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<EmailTemplate_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<EmailTemplate_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<EmailTemplate_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<EmailTemplate_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<EmailTemplate_Set_Input>;
  /** filter the rows which have to be updated */
  where: EmailTemplate_Bool_Exp;
};

/** aggregate var_pop on columns */
export type EmailTemplate_Var_Pop_Fields = {
  __typename?: 'emailTemplate_var_pop_fields';
  bounces?: Maybe<Scalars['Float']>;
  clicks?: Maybe<Scalars['Float']>;
  deliveries?: Maybe<Scalars['Float']>;
  opens?: Maybe<Scalars['Float']>;
  replies?: Maybe<Scalars['Float']>;
  resendWindow?: Maybe<Scalars['Float']>;
  spamFlags?: Maybe<Scalars['Float']>;
  unsubscribes?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type EmailTemplate_Var_Samp_Fields = {
  __typename?: 'emailTemplate_var_samp_fields';
  bounces?: Maybe<Scalars['Float']>;
  clicks?: Maybe<Scalars['Float']>;
  deliveries?: Maybe<Scalars['Float']>;
  opens?: Maybe<Scalars['Float']>;
  replies?: Maybe<Scalars['Float']>;
  resendWindow?: Maybe<Scalars['Float']>;
  spamFlags?: Maybe<Scalars['Float']>;
  unsubscribes?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type EmailTemplate_Variance_Fields = {
  __typename?: 'emailTemplate_variance_fields';
  bounces?: Maybe<Scalars['Float']>;
  clicks?: Maybe<Scalars['Float']>;
  deliveries?: Maybe<Scalars['Float']>;
  opens?: Maybe<Scalars['Float']>;
  replies?: Maybe<Scalars['Float']>;
  resendWindow?: Maybe<Scalars['Float']>;
  spamFlags?: Maybe<Scalars['Float']>;
  unsubscribes?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "externalLocation" */
export type ExternalLocation = {
  __typename?: 'externalLocation';
  createdAt: Scalars['timestamptz'];
  externalId: Scalars['String'];
  externalSource: Scalars['String'];
  id: Scalars['uuid'];
  /** An object relationship */
  location: Location;
  locationId: Scalars['uuid'];
  updatedAt: Scalars['timestamptz'];
  /** An object relationship */
  workspace: Workspace;
  workspaceId: Scalars['uuid'];
};

/** aggregated selection of "externalLocation" */
export type ExternalLocation_Aggregate = {
  __typename?: 'externalLocation_aggregate';
  aggregate?: Maybe<ExternalLocation_Aggregate_Fields>;
  nodes: Array<ExternalLocation>;
};

export type ExternalLocation_Aggregate_Bool_Exp = {
  count?: InputMaybe<ExternalLocation_Aggregate_Bool_Exp_Count>;
};

export type ExternalLocation_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<ExternalLocation_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ExternalLocation_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "externalLocation" */
export type ExternalLocation_Aggregate_Fields = {
  __typename?: 'externalLocation_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ExternalLocation_Max_Fields>;
  min?: Maybe<ExternalLocation_Min_Fields>;
};


/** aggregate fields of "externalLocation" */
export type ExternalLocation_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<ExternalLocation_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "externalLocation" */
export type ExternalLocation_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<ExternalLocation_Max_Order_By>;
  min?: InputMaybe<ExternalLocation_Min_Order_By>;
};

/** input type for inserting array relation for remote table "externalLocation" */
export type ExternalLocation_Arr_Rel_Insert_Input = {
  data: Array<ExternalLocation_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<ExternalLocation_On_Conflict>;
};

/** Boolean expression to filter rows from the table "externalLocation". All fields are combined with a logical 'AND'. */
export type ExternalLocation_Bool_Exp = {
  _and?: InputMaybe<Array<ExternalLocation_Bool_Exp>>;
  _not?: InputMaybe<ExternalLocation_Bool_Exp>;
  _or?: InputMaybe<Array<ExternalLocation_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  externalId?: InputMaybe<String_Comparison_Exp>;
  externalSource?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  location?: InputMaybe<Location_Bool_Exp>;
  locationId?: InputMaybe<Uuid_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  workspace?: InputMaybe<Workspace_Bool_Exp>;
  workspaceId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "externalLocation" */
export enum ExternalLocation_Constraint {
  /** unique or primary key constraint on columns "externalId", "locationId" */
  ExternalLocationExternalIdLocationIdKey = 'externalLocation_externalId_locationId_key',
  /** unique or primary key constraint on columns "id" */
  ExternalLocationPkey = 'externalLocation_pkey'
}

/** input type for inserting data into table "externalLocation" */
export type ExternalLocation_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  externalId?: InputMaybe<Scalars['String']>;
  externalSource?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  location?: InputMaybe<Location_Obj_Rel_Insert_Input>;
  locationId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspace?: InputMaybe<Workspace_Obj_Rel_Insert_Input>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type ExternalLocation_Max_Fields = {
  __typename?: 'externalLocation_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  externalId?: Maybe<Scalars['String']>;
  externalSource?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  locationId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "externalLocation" */
export type ExternalLocation_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  externalId?: InputMaybe<Order_By>;
  externalSource?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  locationId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type ExternalLocation_Min_Fields = {
  __typename?: 'externalLocation_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  externalId?: Maybe<Scalars['String']>;
  externalSource?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  locationId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "externalLocation" */
export type ExternalLocation_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  externalId?: InputMaybe<Order_By>;
  externalSource?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  locationId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "externalLocation" */
export type ExternalLocation_Mutation_Response = {
  __typename?: 'externalLocation_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ExternalLocation>;
};

/** on_conflict condition type for table "externalLocation" */
export type ExternalLocation_On_Conflict = {
  constraint: ExternalLocation_Constraint;
  update_columns?: Array<ExternalLocation_Update_Column>;
  where?: InputMaybe<ExternalLocation_Bool_Exp>;
};

/** Ordering options when selecting data from "externalLocation". */
export type ExternalLocation_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  externalId?: InputMaybe<Order_By>;
  externalSource?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  location?: InputMaybe<Location_Order_By>;
  locationId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  workspace?: InputMaybe<Workspace_Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: externalLocation */
export type ExternalLocation_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "externalLocation" */
export enum ExternalLocation_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  ExternalId = 'externalId',
  /** column name */
  ExternalSource = 'externalSource',
  /** column name */
  Id = 'id',
  /** column name */
  LocationId = 'locationId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WorkspaceId = 'workspaceId'
}

/** input type for updating data in table "externalLocation" */
export type ExternalLocation_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  externalId?: InputMaybe<Scalars['String']>;
  externalSource?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  locationId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "externalLocation" */
export type ExternalLocation_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ExternalLocation_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ExternalLocation_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  externalId?: InputMaybe<Scalars['String']>;
  externalSource?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  locationId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "externalLocation" */
export enum ExternalLocation_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  ExternalId = 'externalId',
  /** column name */
  ExternalSource = 'externalSource',
  /** column name */
  Id = 'id',
  /** column name */
  LocationId = 'locationId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WorkspaceId = 'workspaceId'
}

export type ExternalLocation_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ExternalLocation_Set_Input>;
  /** filter the rows which have to be updated */
  where: ExternalLocation_Bool_Exp;
};

/** columns and relationships of "externalProvider" */
export type ExternalProvider = {
  __typename?: 'externalProvider';
  createdAt: Scalars['timestamptz'];
  externalId: Scalars['String'];
  externalLocationId?: Maybe<Scalars['String']>;
  externalSource: Scalars['String'];
  id: Scalars['uuid'];
  /** An object relationship */
  provider: Provider;
  providerId: Scalars['uuid'];
  updatedAt: Scalars['timestamptz'];
  uri: Scalars['String'];
  /** An object relationship */
  workspace: Workspace;
  workspaceId: Scalars['uuid'];
};

/** aggregated selection of "externalProvider" */
export type ExternalProvider_Aggregate = {
  __typename?: 'externalProvider_aggregate';
  aggregate?: Maybe<ExternalProvider_Aggregate_Fields>;
  nodes: Array<ExternalProvider>;
};

export type ExternalProvider_Aggregate_Bool_Exp = {
  count?: InputMaybe<ExternalProvider_Aggregate_Bool_Exp_Count>;
};

export type ExternalProvider_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<ExternalProvider_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ExternalProvider_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "externalProvider" */
export type ExternalProvider_Aggregate_Fields = {
  __typename?: 'externalProvider_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ExternalProvider_Max_Fields>;
  min?: Maybe<ExternalProvider_Min_Fields>;
};


/** aggregate fields of "externalProvider" */
export type ExternalProvider_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<ExternalProvider_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "externalProvider" */
export type ExternalProvider_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<ExternalProvider_Max_Order_By>;
  min?: InputMaybe<ExternalProvider_Min_Order_By>;
};

/** input type for inserting array relation for remote table "externalProvider" */
export type ExternalProvider_Arr_Rel_Insert_Input = {
  data: Array<ExternalProvider_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<ExternalProvider_On_Conflict>;
};

/** Boolean expression to filter rows from the table "externalProvider". All fields are combined with a logical 'AND'. */
export type ExternalProvider_Bool_Exp = {
  _and?: InputMaybe<Array<ExternalProvider_Bool_Exp>>;
  _not?: InputMaybe<ExternalProvider_Bool_Exp>;
  _or?: InputMaybe<Array<ExternalProvider_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  externalId?: InputMaybe<String_Comparison_Exp>;
  externalLocationId?: InputMaybe<String_Comparison_Exp>;
  externalSource?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  provider?: InputMaybe<Provider_Bool_Exp>;
  providerId?: InputMaybe<Uuid_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  uri?: InputMaybe<String_Comparison_Exp>;
  workspace?: InputMaybe<Workspace_Bool_Exp>;
  workspaceId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "externalProvider" */
export enum ExternalProvider_Constraint {
  /** unique or primary key constraint on columns "externalId" */
  ExternalProviderExternalIdKey = 'externalProvider_externalId_key',
  /** unique or primary key constraint on columns "id" */
  ExternalProviderPkey = 'externalProvider_pkey'
}

/** input type for inserting data into table "externalProvider" */
export type ExternalProvider_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  externalId?: InputMaybe<Scalars['String']>;
  externalLocationId?: InputMaybe<Scalars['String']>;
  externalSource?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  provider?: InputMaybe<Provider_Obj_Rel_Insert_Input>;
  providerId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  uri?: InputMaybe<Scalars['String']>;
  workspace?: InputMaybe<Workspace_Obj_Rel_Insert_Input>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type ExternalProvider_Max_Fields = {
  __typename?: 'externalProvider_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  externalId?: Maybe<Scalars['String']>;
  externalLocationId?: Maybe<Scalars['String']>;
  externalSource?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  providerId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  uri?: Maybe<Scalars['String']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "externalProvider" */
export type ExternalProvider_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  externalId?: InputMaybe<Order_By>;
  externalLocationId?: InputMaybe<Order_By>;
  externalSource?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  providerId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  uri?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type ExternalProvider_Min_Fields = {
  __typename?: 'externalProvider_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  externalId?: Maybe<Scalars['String']>;
  externalLocationId?: Maybe<Scalars['String']>;
  externalSource?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  providerId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  uri?: Maybe<Scalars['String']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "externalProvider" */
export type ExternalProvider_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  externalId?: InputMaybe<Order_By>;
  externalLocationId?: InputMaybe<Order_By>;
  externalSource?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  providerId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  uri?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "externalProvider" */
export type ExternalProvider_Mutation_Response = {
  __typename?: 'externalProvider_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ExternalProvider>;
};

/** on_conflict condition type for table "externalProvider" */
export type ExternalProvider_On_Conflict = {
  constraint: ExternalProvider_Constraint;
  update_columns?: Array<ExternalProvider_Update_Column>;
  where?: InputMaybe<ExternalProvider_Bool_Exp>;
};

/** Ordering options when selecting data from "externalProvider". */
export type ExternalProvider_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  externalId?: InputMaybe<Order_By>;
  externalLocationId?: InputMaybe<Order_By>;
  externalSource?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  provider?: InputMaybe<Provider_Order_By>;
  providerId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  uri?: InputMaybe<Order_By>;
  workspace?: InputMaybe<Workspace_Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: externalProvider */
export type ExternalProvider_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "externalProvider" */
export enum ExternalProvider_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  ExternalId = 'externalId',
  /** column name */
  ExternalLocationId = 'externalLocationId',
  /** column name */
  ExternalSource = 'externalSource',
  /** column name */
  Id = 'id',
  /** column name */
  ProviderId = 'providerId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Uri = 'uri',
  /** column name */
  WorkspaceId = 'workspaceId'
}

/** input type for updating data in table "externalProvider" */
export type ExternalProvider_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  externalId?: InputMaybe<Scalars['String']>;
  externalLocationId?: InputMaybe<Scalars['String']>;
  externalSource?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  providerId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  uri?: InputMaybe<Scalars['String']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "externalProvider" */
export type ExternalProvider_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ExternalProvider_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ExternalProvider_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  externalId?: InputMaybe<Scalars['String']>;
  externalLocationId?: InputMaybe<Scalars['String']>;
  externalSource?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  providerId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  uri?: InputMaybe<Scalars['String']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "externalProvider" */
export enum ExternalProvider_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  ExternalId = 'externalId',
  /** column name */
  ExternalLocationId = 'externalLocationId',
  /** column name */
  ExternalSource = 'externalSource',
  /** column name */
  Id = 'id',
  /** column name */
  ProviderId = 'providerId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Uri = 'uri',
  /** column name */
  WorkspaceId = 'workspaceId'
}

export type ExternalProvider_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ExternalProvider_Set_Input>;
  /** filter the rows which have to be updated */
  where: ExternalProvider_Bool_Exp;
};

/** columns and relationships of "externalReview" */
export type ExternalReview = {
  __typename?: 'externalReview';
  comment: Scalars['String'];
  createdAt: Scalars['timestamptz'];
  externalId: Scalars['String'];
  externalSource: Scalars['String'];
  id: Scalars['uuid'];
  /** An object relationship */
  location?: Maybe<Location>;
  locationId?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  provider?: Maybe<Provider>;
  providerId?: Maybe<Scalars['uuid']>;
  rating?: Maybe<Scalars['Float']>;
  response?: Maybe<Scalars['String']>;
  reviewDate: Scalars['bpchar'];
  reviewerName: Scalars['String'];
  reviewerPhotoUrl?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt: Scalars['timestamptz'];
  /** An object relationship */
  workspace: Workspace;
  workspaceId: Scalars['uuid'];
};

/** aggregated selection of "externalReview" */
export type ExternalReview_Aggregate = {
  __typename?: 'externalReview_aggregate';
  aggregate?: Maybe<ExternalReview_Aggregate_Fields>;
  nodes: Array<ExternalReview>;
};

export type ExternalReview_Aggregate_Bool_Exp = {
  count?: InputMaybe<ExternalReview_Aggregate_Bool_Exp_Count>;
};

export type ExternalReview_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<ExternalReview_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ExternalReview_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "externalReview" */
export type ExternalReview_Aggregate_Fields = {
  __typename?: 'externalReview_aggregate_fields';
  avg?: Maybe<ExternalReview_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<ExternalReview_Max_Fields>;
  min?: Maybe<ExternalReview_Min_Fields>;
  stddev?: Maybe<ExternalReview_Stddev_Fields>;
  stddev_pop?: Maybe<ExternalReview_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<ExternalReview_Stddev_Samp_Fields>;
  sum?: Maybe<ExternalReview_Sum_Fields>;
  var_pop?: Maybe<ExternalReview_Var_Pop_Fields>;
  var_samp?: Maybe<ExternalReview_Var_Samp_Fields>;
  variance?: Maybe<ExternalReview_Variance_Fields>;
};


/** aggregate fields of "externalReview" */
export type ExternalReview_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<ExternalReview_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "externalReview" */
export type ExternalReview_Aggregate_Order_By = {
  avg?: InputMaybe<ExternalReview_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<ExternalReview_Max_Order_By>;
  min?: InputMaybe<ExternalReview_Min_Order_By>;
  stddev?: InputMaybe<ExternalReview_Stddev_Order_By>;
  stddev_pop?: InputMaybe<ExternalReview_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<ExternalReview_Stddev_Samp_Order_By>;
  sum?: InputMaybe<ExternalReview_Sum_Order_By>;
  var_pop?: InputMaybe<ExternalReview_Var_Pop_Order_By>;
  var_samp?: InputMaybe<ExternalReview_Var_Samp_Order_By>;
  variance?: InputMaybe<ExternalReview_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "externalReview" */
export type ExternalReview_Arr_Rel_Insert_Input = {
  data: Array<ExternalReview_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<ExternalReview_On_Conflict>;
};

/** aggregate avg on columns */
export type ExternalReview_Avg_Fields = {
  __typename?: 'externalReview_avg_fields';
  rating?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "externalReview" */
export type ExternalReview_Avg_Order_By = {
  rating?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "externalReview". All fields are combined with a logical 'AND'. */
export type ExternalReview_Bool_Exp = {
  _and?: InputMaybe<Array<ExternalReview_Bool_Exp>>;
  _not?: InputMaybe<ExternalReview_Bool_Exp>;
  _or?: InputMaybe<Array<ExternalReview_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  externalId?: InputMaybe<String_Comparison_Exp>;
  externalSource?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  location?: InputMaybe<Location_Bool_Exp>;
  locationId?: InputMaybe<Uuid_Comparison_Exp>;
  provider?: InputMaybe<Provider_Bool_Exp>;
  providerId?: InputMaybe<Uuid_Comparison_Exp>;
  rating?: InputMaybe<Float_Comparison_Exp>;
  response?: InputMaybe<String_Comparison_Exp>;
  reviewDate?: InputMaybe<Bpchar_Comparison_Exp>;
  reviewerName?: InputMaybe<String_Comparison_Exp>;
  reviewerPhotoUrl?: InputMaybe<String_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  workspace?: InputMaybe<Workspace_Bool_Exp>;
  workspaceId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "externalReview" */
export enum ExternalReview_Constraint {
  /** unique or primary key constraint on columns "externalId" */
  ExternalReviewExternalIdKey = 'externalReview_externalId_key',
  /** unique or primary key constraint on columns "id" */
  ExternalReviewPkey = 'externalReview_pkey'
}

/** input type for incrementing numeric columns in table "externalReview" */
export type ExternalReview_Inc_Input = {
  rating?: InputMaybe<Scalars['Float']>;
};

/** input type for inserting data into table "externalReview" */
export type ExternalReview_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  externalId?: InputMaybe<Scalars['String']>;
  externalSource?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  location?: InputMaybe<Location_Obj_Rel_Insert_Input>;
  locationId?: InputMaybe<Scalars['uuid']>;
  provider?: InputMaybe<Provider_Obj_Rel_Insert_Input>;
  providerId?: InputMaybe<Scalars['uuid']>;
  rating?: InputMaybe<Scalars['Float']>;
  response?: InputMaybe<Scalars['String']>;
  reviewDate?: InputMaybe<Scalars['bpchar']>;
  reviewerName?: InputMaybe<Scalars['String']>;
  reviewerPhotoUrl?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspace?: InputMaybe<Workspace_Obj_Rel_Insert_Input>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type ExternalReview_Max_Fields = {
  __typename?: 'externalReview_max_fields';
  comment?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  externalId?: Maybe<Scalars['String']>;
  externalSource?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  locationId?: Maybe<Scalars['uuid']>;
  providerId?: Maybe<Scalars['uuid']>;
  rating?: Maybe<Scalars['Float']>;
  response?: Maybe<Scalars['String']>;
  reviewDate?: Maybe<Scalars['bpchar']>;
  reviewerName?: Maybe<Scalars['String']>;
  reviewerPhotoUrl?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "externalReview" */
export type ExternalReview_Max_Order_By = {
  comment?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  externalId?: InputMaybe<Order_By>;
  externalSource?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  locationId?: InputMaybe<Order_By>;
  providerId?: InputMaybe<Order_By>;
  rating?: InputMaybe<Order_By>;
  response?: InputMaybe<Order_By>;
  reviewDate?: InputMaybe<Order_By>;
  reviewerName?: InputMaybe<Order_By>;
  reviewerPhotoUrl?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type ExternalReview_Min_Fields = {
  __typename?: 'externalReview_min_fields';
  comment?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  externalId?: Maybe<Scalars['String']>;
  externalSource?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  locationId?: Maybe<Scalars['uuid']>;
  providerId?: Maybe<Scalars['uuid']>;
  rating?: Maybe<Scalars['Float']>;
  response?: Maybe<Scalars['String']>;
  reviewDate?: Maybe<Scalars['bpchar']>;
  reviewerName?: Maybe<Scalars['String']>;
  reviewerPhotoUrl?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "externalReview" */
export type ExternalReview_Min_Order_By = {
  comment?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  externalId?: InputMaybe<Order_By>;
  externalSource?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  locationId?: InputMaybe<Order_By>;
  providerId?: InputMaybe<Order_By>;
  rating?: InputMaybe<Order_By>;
  response?: InputMaybe<Order_By>;
  reviewDate?: InputMaybe<Order_By>;
  reviewerName?: InputMaybe<Order_By>;
  reviewerPhotoUrl?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "externalReview" */
export type ExternalReview_Mutation_Response = {
  __typename?: 'externalReview_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ExternalReview>;
};

/** on_conflict condition type for table "externalReview" */
export type ExternalReview_On_Conflict = {
  constraint: ExternalReview_Constraint;
  update_columns?: Array<ExternalReview_Update_Column>;
  where?: InputMaybe<ExternalReview_Bool_Exp>;
};

/** Ordering options when selecting data from "externalReview". */
export type ExternalReview_Order_By = {
  comment?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  externalId?: InputMaybe<Order_By>;
  externalSource?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  location?: InputMaybe<Location_Order_By>;
  locationId?: InputMaybe<Order_By>;
  provider?: InputMaybe<Provider_Order_By>;
  providerId?: InputMaybe<Order_By>;
  rating?: InputMaybe<Order_By>;
  response?: InputMaybe<Order_By>;
  reviewDate?: InputMaybe<Order_By>;
  reviewerName?: InputMaybe<Order_By>;
  reviewerPhotoUrl?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  workspace?: InputMaybe<Workspace_Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: externalReview */
export type ExternalReview_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "externalReview" */
export enum ExternalReview_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  ExternalId = 'externalId',
  /** column name */
  ExternalSource = 'externalSource',
  /** column name */
  Id = 'id',
  /** column name */
  LocationId = 'locationId',
  /** column name */
  ProviderId = 'providerId',
  /** column name */
  Rating = 'rating',
  /** column name */
  Response = 'response',
  /** column name */
  ReviewDate = 'reviewDate',
  /** column name */
  ReviewerName = 'reviewerName',
  /** column name */
  ReviewerPhotoUrl = 'reviewerPhotoUrl',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WorkspaceId = 'workspaceId'
}

/** input type for updating data in table "externalReview" */
export type ExternalReview_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  externalId?: InputMaybe<Scalars['String']>;
  externalSource?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  locationId?: InputMaybe<Scalars['uuid']>;
  providerId?: InputMaybe<Scalars['uuid']>;
  rating?: InputMaybe<Scalars['Float']>;
  response?: InputMaybe<Scalars['String']>;
  reviewDate?: InputMaybe<Scalars['bpchar']>;
  reviewerName?: InputMaybe<Scalars['String']>;
  reviewerPhotoUrl?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type ExternalReview_Stddev_Fields = {
  __typename?: 'externalReview_stddev_fields';
  rating?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "externalReview" */
export type ExternalReview_Stddev_Order_By = {
  rating?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type ExternalReview_Stddev_Pop_Fields = {
  __typename?: 'externalReview_stddev_pop_fields';
  rating?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "externalReview" */
export type ExternalReview_Stddev_Pop_Order_By = {
  rating?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type ExternalReview_Stddev_Samp_Fields = {
  __typename?: 'externalReview_stddev_samp_fields';
  rating?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "externalReview" */
export type ExternalReview_Stddev_Samp_Order_By = {
  rating?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "externalReview" */
export type ExternalReview_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ExternalReview_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ExternalReview_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  externalId?: InputMaybe<Scalars['String']>;
  externalSource?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  locationId?: InputMaybe<Scalars['uuid']>;
  providerId?: InputMaybe<Scalars['uuid']>;
  rating?: InputMaybe<Scalars['Float']>;
  response?: InputMaybe<Scalars['String']>;
  reviewDate?: InputMaybe<Scalars['bpchar']>;
  reviewerName?: InputMaybe<Scalars['String']>;
  reviewerPhotoUrl?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type ExternalReview_Sum_Fields = {
  __typename?: 'externalReview_sum_fields';
  rating?: Maybe<Scalars['Float']>;
};

/** order by sum() on columns of table "externalReview" */
export type ExternalReview_Sum_Order_By = {
  rating?: InputMaybe<Order_By>;
};

/** update columns of table "externalReview" */
export enum ExternalReview_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  ExternalId = 'externalId',
  /** column name */
  ExternalSource = 'externalSource',
  /** column name */
  Id = 'id',
  /** column name */
  LocationId = 'locationId',
  /** column name */
  ProviderId = 'providerId',
  /** column name */
  Rating = 'rating',
  /** column name */
  Response = 'response',
  /** column name */
  ReviewDate = 'reviewDate',
  /** column name */
  ReviewerName = 'reviewerName',
  /** column name */
  ReviewerPhotoUrl = 'reviewerPhotoUrl',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WorkspaceId = 'workspaceId'
}

export type ExternalReview_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ExternalReview_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ExternalReview_Set_Input>;
  /** filter the rows which have to be updated */
  where: ExternalReview_Bool_Exp;
};

/** aggregate var_pop on columns */
export type ExternalReview_Var_Pop_Fields = {
  __typename?: 'externalReview_var_pop_fields';
  rating?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "externalReview" */
export type ExternalReview_Var_Pop_Order_By = {
  rating?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type ExternalReview_Var_Samp_Fields = {
  __typename?: 'externalReview_var_samp_fields';
  rating?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "externalReview" */
export type ExternalReview_Var_Samp_Order_By = {
  rating?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type ExternalReview_Variance_Fields = {
  __typename?: 'externalReview_variance_fields';
  rating?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "externalReview" */
export type ExternalReview_Variance_Order_By = {
  rating?: InputMaybe<Order_By>;
};

/** columns and relationships of "fee" */
export type Fee = {
  __typename?: 'fee';
  amount: Scalars['Int'];
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  name: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  workspaceId: Scalars['uuid'];
};

/** columns and relationships of "feeInvoiceLineItem" */
export type FeeInvoiceLineItem = {
  __typename?: 'feeInvoiceLineItem';
  amount: Scalars['Int'];
  createdAt: Scalars['timestamptz'];
  /** An object relationship */
  fee: Fee;
  feeId: Scalars['uuid'];
  id: Scalars['uuid'];
  /** An object relationship */
  invoice: Invoice;
  invoiceId: Scalars['uuid'];
  updatedAt: Scalars['timestamptz'];
  /** An object relationship */
  workspace: Workspace;
  workspaceId: Scalars['uuid'];
};

/** aggregated selection of "feeInvoiceLineItem" */
export type FeeInvoiceLineItem_Aggregate = {
  __typename?: 'feeInvoiceLineItem_aggregate';
  aggregate?: Maybe<FeeInvoiceLineItem_Aggregate_Fields>;
  nodes: Array<FeeInvoiceLineItem>;
};

export type FeeInvoiceLineItem_Aggregate_Bool_Exp = {
  count?: InputMaybe<FeeInvoiceLineItem_Aggregate_Bool_Exp_Count>;
};

export type FeeInvoiceLineItem_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<FeeInvoiceLineItem_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<FeeInvoiceLineItem_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "feeInvoiceLineItem" */
export type FeeInvoiceLineItem_Aggregate_Fields = {
  __typename?: 'feeInvoiceLineItem_aggregate_fields';
  avg?: Maybe<FeeInvoiceLineItem_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<FeeInvoiceLineItem_Max_Fields>;
  min?: Maybe<FeeInvoiceLineItem_Min_Fields>;
  stddev?: Maybe<FeeInvoiceLineItem_Stddev_Fields>;
  stddev_pop?: Maybe<FeeInvoiceLineItem_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<FeeInvoiceLineItem_Stddev_Samp_Fields>;
  sum?: Maybe<FeeInvoiceLineItem_Sum_Fields>;
  var_pop?: Maybe<FeeInvoiceLineItem_Var_Pop_Fields>;
  var_samp?: Maybe<FeeInvoiceLineItem_Var_Samp_Fields>;
  variance?: Maybe<FeeInvoiceLineItem_Variance_Fields>;
};


/** aggregate fields of "feeInvoiceLineItem" */
export type FeeInvoiceLineItem_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<FeeInvoiceLineItem_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "feeInvoiceLineItem" */
export type FeeInvoiceLineItem_Aggregate_Order_By = {
  avg?: InputMaybe<FeeInvoiceLineItem_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<FeeInvoiceLineItem_Max_Order_By>;
  min?: InputMaybe<FeeInvoiceLineItem_Min_Order_By>;
  stddev?: InputMaybe<FeeInvoiceLineItem_Stddev_Order_By>;
  stddev_pop?: InputMaybe<FeeInvoiceLineItem_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<FeeInvoiceLineItem_Stddev_Samp_Order_By>;
  sum?: InputMaybe<FeeInvoiceLineItem_Sum_Order_By>;
  var_pop?: InputMaybe<FeeInvoiceLineItem_Var_Pop_Order_By>;
  var_samp?: InputMaybe<FeeInvoiceLineItem_Var_Samp_Order_By>;
  variance?: InputMaybe<FeeInvoiceLineItem_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "feeInvoiceLineItem" */
export type FeeInvoiceLineItem_Arr_Rel_Insert_Input = {
  data: Array<FeeInvoiceLineItem_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<FeeInvoiceLineItem_On_Conflict>;
};

/** aggregate avg on columns */
export type FeeInvoiceLineItem_Avg_Fields = {
  __typename?: 'feeInvoiceLineItem_avg_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "feeInvoiceLineItem" */
export type FeeInvoiceLineItem_Avg_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "feeInvoiceLineItem". All fields are combined with a logical 'AND'. */
export type FeeInvoiceLineItem_Bool_Exp = {
  _and?: InputMaybe<Array<FeeInvoiceLineItem_Bool_Exp>>;
  _not?: InputMaybe<FeeInvoiceLineItem_Bool_Exp>;
  _or?: InputMaybe<Array<FeeInvoiceLineItem_Bool_Exp>>;
  amount?: InputMaybe<Int_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  fee?: InputMaybe<Fee_Bool_Exp>;
  feeId?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  invoice?: InputMaybe<Invoice_Bool_Exp>;
  invoiceId?: InputMaybe<Uuid_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  workspace?: InputMaybe<Workspace_Bool_Exp>;
  workspaceId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "feeInvoiceLineItem" */
export enum FeeInvoiceLineItem_Constraint {
  /** unique or primary key constraint on columns "id" */
  FeeInvoiceLineItemPkey = 'feeInvoiceLineItem_pkey'
}

/** input type for incrementing numeric columns in table "feeInvoiceLineItem" */
export type FeeInvoiceLineItem_Inc_Input = {
  amount?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "feeInvoiceLineItem" */
export type FeeInvoiceLineItem_Insert_Input = {
  amount?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  fee?: InputMaybe<Fee_Obj_Rel_Insert_Input>;
  feeId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  invoice?: InputMaybe<Invoice_Obj_Rel_Insert_Input>;
  invoiceId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspace?: InputMaybe<Workspace_Obj_Rel_Insert_Input>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type FeeInvoiceLineItem_Max_Fields = {
  __typename?: 'feeInvoiceLineItem_max_fields';
  amount?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  feeId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  invoiceId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "feeInvoiceLineItem" */
export type FeeInvoiceLineItem_Max_Order_By = {
  amount?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  feeId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoiceId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type FeeInvoiceLineItem_Min_Fields = {
  __typename?: 'feeInvoiceLineItem_min_fields';
  amount?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  feeId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  invoiceId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "feeInvoiceLineItem" */
export type FeeInvoiceLineItem_Min_Order_By = {
  amount?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  feeId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoiceId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "feeInvoiceLineItem" */
export type FeeInvoiceLineItem_Mutation_Response = {
  __typename?: 'feeInvoiceLineItem_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<FeeInvoiceLineItem>;
};

/** on_conflict condition type for table "feeInvoiceLineItem" */
export type FeeInvoiceLineItem_On_Conflict = {
  constraint: FeeInvoiceLineItem_Constraint;
  update_columns?: Array<FeeInvoiceLineItem_Update_Column>;
  where?: InputMaybe<FeeInvoiceLineItem_Bool_Exp>;
};

/** Ordering options when selecting data from "feeInvoiceLineItem". */
export type FeeInvoiceLineItem_Order_By = {
  amount?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  fee?: InputMaybe<Fee_Order_By>;
  feeId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoice?: InputMaybe<Invoice_Order_By>;
  invoiceId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  workspace?: InputMaybe<Workspace_Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: feeInvoiceLineItem */
export type FeeInvoiceLineItem_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "feeInvoiceLineItem" */
export enum FeeInvoiceLineItem_Select_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  FeeId = 'feeId',
  /** column name */
  Id = 'id',
  /** column name */
  InvoiceId = 'invoiceId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WorkspaceId = 'workspaceId'
}

/** input type for updating data in table "feeInvoiceLineItem" */
export type FeeInvoiceLineItem_Set_Input = {
  amount?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  feeId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  invoiceId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type FeeInvoiceLineItem_Stddev_Fields = {
  __typename?: 'feeInvoiceLineItem_stddev_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "feeInvoiceLineItem" */
export type FeeInvoiceLineItem_Stddev_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type FeeInvoiceLineItem_Stddev_Pop_Fields = {
  __typename?: 'feeInvoiceLineItem_stddev_pop_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "feeInvoiceLineItem" */
export type FeeInvoiceLineItem_Stddev_Pop_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type FeeInvoiceLineItem_Stddev_Samp_Fields = {
  __typename?: 'feeInvoiceLineItem_stddev_samp_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "feeInvoiceLineItem" */
export type FeeInvoiceLineItem_Stddev_Samp_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "feeInvoiceLineItem" */
export type FeeInvoiceLineItem_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: FeeInvoiceLineItem_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type FeeInvoiceLineItem_Stream_Cursor_Value_Input = {
  amount?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  feeId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  invoiceId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type FeeInvoiceLineItem_Sum_Fields = {
  __typename?: 'feeInvoiceLineItem_sum_fields';
  amount?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "feeInvoiceLineItem" */
export type FeeInvoiceLineItem_Sum_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** update columns of table "feeInvoiceLineItem" */
export enum FeeInvoiceLineItem_Update_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  FeeId = 'feeId',
  /** column name */
  Id = 'id',
  /** column name */
  InvoiceId = 'invoiceId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WorkspaceId = 'workspaceId'
}

export type FeeInvoiceLineItem_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<FeeInvoiceLineItem_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<FeeInvoiceLineItem_Set_Input>;
  /** filter the rows which have to be updated */
  where: FeeInvoiceLineItem_Bool_Exp;
};

/** aggregate var_pop on columns */
export type FeeInvoiceLineItem_Var_Pop_Fields = {
  __typename?: 'feeInvoiceLineItem_var_pop_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "feeInvoiceLineItem" */
export type FeeInvoiceLineItem_Var_Pop_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type FeeInvoiceLineItem_Var_Samp_Fields = {
  __typename?: 'feeInvoiceLineItem_var_samp_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "feeInvoiceLineItem" */
export type FeeInvoiceLineItem_Var_Samp_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type FeeInvoiceLineItem_Variance_Fields = {
  __typename?: 'feeInvoiceLineItem_variance_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "feeInvoiceLineItem" */
export type FeeInvoiceLineItem_Variance_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** aggregated selection of "fee" */
export type Fee_Aggregate = {
  __typename?: 'fee_aggregate';
  aggregate?: Maybe<Fee_Aggregate_Fields>;
  nodes: Array<Fee>;
};

/** aggregate fields of "fee" */
export type Fee_Aggregate_Fields = {
  __typename?: 'fee_aggregate_fields';
  avg?: Maybe<Fee_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Fee_Max_Fields>;
  min?: Maybe<Fee_Min_Fields>;
  stddev?: Maybe<Fee_Stddev_Fields>;
  stddev_pop?: Maybe<Fee_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Fee_Stddev_Samp_Fields>;
  sum?: Maybe<Fee_Sum_Fields>;
  var_pop?: Maybe<Fee_Var_Pop_Fields>;
  var_samp?: Maybe<Fee_Var_Samp_Fields>;
  variance?: Maybe<Fee_Variance_Fields>;
};


/** aggregate fields of "fee" */
export type Fee_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Fee_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Fee_Avg_Fields = {
  __typename?: 'fee_avg_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "fee". All fields are combined with a logical 'AND'. */
export type Fee_Bool_Exp = {
  _and?: InputMaybe<Array<Fee_Bool_Exp>>;
  _not?: InputMaybe<Fee_Bool_Exp>;
  _or?: InputMaybe<Array<Fee_Bool_Exp>>;
  amount?: InputMaybe<Int_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  workspaceId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "fee" */
export enum Fee_Constraint {
  /** unique or primary key constraint on columns "workspaceId", "name" */
  FeeNameWorkspaceIdKey = 'fee_name_workspaceId_key',
  /** unique or primary key constraint on columns "id" */
  FeePkey = 'fee_pkey'
}

/** input type for incrementing numeric columns in table "fee" */
export type Fee_Inc_Input = {
  amount?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "fee" */
export type Fee_Insert_Input = {
  amount?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Fee_Max_Fields = {
  __typename?: 'fee_max_fields';
  amount?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Fee_Min_Fields = {
  __typename?: 'fee_min_fields';
  amount?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "fee" */
export type Fee_Mutation_Response = {
  __typename?: 'fee_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Fee>;
};

/** input type for inserting object relation for remote table "fee" */
export type Fee_Obj_Rel_Insert_Input = {
  data: Fee_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Fee_On_Conflict>;
};

/** on_conflict condition type for table "fee" */
export type Fee_On_Conflict = {
  constraint: Fee_Constraint;
  update_columns?: Array<Fee_Update_Column>;
  where?: InputMaybe<Fee_Bool_Exp>;
};

/** Ordering options when selecting data from "fee". */
export type Fee_Order_By = {
  amount?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: fee */
export type Fee_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "fee" */
export enum Fee_Select_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WorkspaceId = 'workspaceId'
}

/** input type for updating data in table "fee" */
export type Fee_Set_Input = {
  amount?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Fee_Stddev_Fields = {
  __typename?: 'fee_stddev_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Fee_Stddev_Pop_Fields = {
  __typename?: 'fee_stddev_pop_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Fee_Stddev_Samp_Fields = {
  __typename?: 'fee_stddev_samp_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "fee" */
export type Fee_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Fee_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Fee_Stream_Cursor_Value_Input = {
  amount?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Fee_Sum_Fields = {
  __typename?: 'fee_sum_fields';
  amount?: Maybe<Scalars['Int']>;
};

/** update columns of table "fee" */
export enum Fee_Update_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WorkspaceId = 'workspaceId'
}

export type Fee_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Fee_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Fee_Set_Input>;
  /** filter the rows which have to be updated */
  where: Fee_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Fee_Var_Pop_Fields = {
  __typename?: 'fee_var_pop_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Fee_Var_Samp_Fields = {
  __typename?: 'fee_var_samp_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Fee_Variance_Fields = {
  __typename?: 'fee_variance_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "finixTerminal" */
export type FinixTerminal = {
  __typename?: 'finixTerminal';
  createdAt: Scalars['timestamptz'];
  finixDeviceId: Scalars['String'];
  id: Scalars['uuid'];
  /** An object relationship */
  location: Location;
  locationId: Scalars['uuid'];
  model: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  serialNumber: Scalars['String'];
  status: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  workspaceId: Scalars['uuid'];
};

/** aggregated selection of "finixTerminal" */
export type FinixTerminal_Aggregate = {
  __typename?: 'finixTerminal_aggregate';
  aggregate?: Maybe<FinixTerminal_Aggregate_Fields>;
  nodes: Array<FinixTerminal>;
};

/** aggregate fields of "finixTerminal" */
export type FinixTerminal_Aggregate_Fields = {
  __typename?: 'finixTerminal_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<FinixTerminal_Max_Fields>;
  min?: Maybe<FinixTerminal_Min_Fields>;
};


/** aggregate fields of "finixTerminal" */
export type FinixTerminal_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<FinixTerminal_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "finixTerminal". All fields are combined with a logical 'AND'. */
export type FinixTerminal_Bool_Exp = {
  _and?: InputMaybe<Array<FinixTerminal_Bool_Exp>>;
  _not?: InputMaybe<FinixTerminal_Bool_Exp>;
  _or?: InputMaybe<Array<FinixTerminal_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  finixDeviceId?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  location?: InputMaybe<Location_Bool_Exp>;
  locationId?: InputMaybe<Uuid_Comparison_Exp>;
  model?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  serialNumber?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  workspaceId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "finixTerminal" */
export enum FinixTerminal_Constraint {
  /** unique or primary key constraint on columns "id" */
  FinixTerminalPkey = 'finixTerminal_pkey'
}

/** input type for inserting data into table "finixTerminal" */
export type FinixTerminal_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  finixDeviceId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  location?: InputMaybe<Location_Obj_Rel_Insert_Input>;
  locationId?: InputMaybe<Scalars['uuid']>;
  model?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  serialNumber?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type FinixTerminal_Max_Fields = {
  __typename?: 'finixTerminal_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  finixDeviceId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  locationId?: Maybe<Scalars['uuid']>;
  model?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  serialNumber?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type FinixTerminal_Min_Fields = {
  __typename?: 'finixTerminal_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  finixDeviceId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  locationId?: Maybe<Scalars['uuid']>;
  model?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  serialNumber?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "finixTerminal" */
export type FinixTerminal_Mutation_Response = {
  __typename?: 'finixTerminal_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<FinixTerminal>;
};

/** on_conflict condition type for table "finixTerminal" */
export type FinixTerminal_On_Conflict = {
  constraint: FinixTerminal_Constraint;
  update_columns?: Array<FinixTerminal_Update_Column>;
  where?: InputMaybe<FinixTerminal_Bool_Exp>;
};

/** Ordering options when selecting data from "finixTerminal". */
export type FinixTerminal_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  finixDeviceId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  location?: InputMaybe<Location_Order_By>;
  locationId?: InputMaybe<Order_By>;
  model?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  serialNumber?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: finixTerminal */
export type FinixTerminal_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "finixTerminal" */
export enum FinixTerminal_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  FinixDeviceId = 'finixDeviceId',
  /** column name */
  Id = 'id',
  /** column name */
  LocationId = 'locationId',
  /** column name */
  Model = 'model',
  /** column name */
  Name = 'name',
  /** column name */
  SerialNumber = 'serialNumber',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WorkspaceId = 'workspaceId'
}

/** input type for updating data in table "finixTerminal" */
export type FinixTerminal_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  finixDeviceId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  locationId?: InputMaybe<Scalars['uuid']>;
  model?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  serialNumber?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "finixTerminal" */
export type FinixTerminal_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: FinixTerminal_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type FinixTerminal_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  finixDeviceId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  locationId?: InputMaybe<Scalars['uuid']>;
  model?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  serialNumber?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "finixTerminal" */
export enum FinixTerminal_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  FinixDeviceId = 'finixDeviceId',
  /** column name */
  Id = 'id',
  /** column name */
  LocationId = 'locationId',
  /** column name */
  Model = 'model',
  /** column name */
  Name = 'name',
  /** column name */
  SerialNumber = 'serialNumber',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WorkspaceId = 'workspaceId'
}

export type FinixTerminal_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<FinixTerminal_Set_Input>;
  /** filter the rows which have to be updated */
  where: FinixTerminal_Bool_Exp;
};

/** Boolean expression to compare columns of type "float8". All fields are combined with logical 'AND'. */
export type Float8_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['float8']>;
  _gt?: InputMaybe<Scalars['float8']>;
  _gte?: InputMaybe<Scalars['float8']>;
  _in?: InputMaybe<Array<Scalars['float8']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['float8']>;
  _lte?: InputMaybe<Scalars['float8']>;
  _neq?: InputMaybe<Scalars['float8']>;
  _nin?: InputMaybe<Array<Scalars['float8']>>;
};

/** columns and relationships of "flowsheet" */
export type Flowsheet = {
  __typename?: 'flowsheet';
  createdAt: Scalars['timestamp'];
  id: Scalars['uuid'];
  /** An array relationship */
  parameters: Array<FlowsheetParameterFlowsheet>;
  /** An aggregate relationship */
  parameters_aggregate: FlowsheetParameterFlowsheet_Aggregate;
  /** An array relationship */
  patientData: Array<PatientFlowsheetData>;
  /** An aggregate relationship */
  patientData_aggregate: PatientFlowsheetData_Aggregate;
  /** An array relationship */
  patientRows: Array<PatientFlowsheetRow>;
  /** An aggregate relationship */
  patientRows_aggregate: PatientFlowsheetRow_Aggregate;
  title: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  /** An object relationship */
  workspace: Workspace;
  workspaceId: Scalars['uuid'];
};


/** columns and relationships of "flowsheet" */
export type FlowsheetParametersArgs = {
  distinct_on?: InputMaybe<Array<FlowsheetParameterFlowsheet_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<FlowsheetParameterFlowsheet_Order_By>>;
  where?: InputMaybe<FlowsheetParameterFlowsheet_Bool_Exp>;
};


/** columns and relationships of "flowsheet" */
export type FlowsheetParameters_AggregateArgs = {
  distinct_on?: InputMaybe<Array<FlowsheetParameterFlowsheet_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<FlowsheetParameterFlowsheet_Order_By>>;
  where?: InputMaybe<FlowsheetParameterFlowsheet_Bool_Exp>;
};


/** columns and relationships of "flowsheet" */
export type FlowsheetPatientDataArgs = {
  distinct_on?: InputMaybe<Array<PatientFlowsheetData_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PatientFlowsheetData_Order_By>>;
  where?: InputMaybe<PatientFlowsheetData_Bool_Exp>;
};


/** columns and relationships of "flowsheet" */
export type FlowsheetPatientData_AggregateArgs = {
  distinct_on?: InputMaybe<Array<PatientFlowsheetData_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PatientFlowsheetData_Order_By>>;
  where?: InputMaybe<PatientFlowsheetData_Bool_Exp>;
};


/** columns and relationships of "flowsheet" */
export type FlowsheetPatientRowsArgs = {
  distinct_on?: InputMaybe<Array<PatientFlowsheetRow_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PatientFlowsheetRow_Order_By>>;
  where?: InputMaybe<PatientFlowsheetRow_Bool_Exp>;
};


/** columns and relationships of "flowsheet" */
export type FlowsheetPatientRows_AggregateArgs = {
  distinct_on?: InputMaybe<Array<PatientFlowsheetRow_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PatientFlowsheetRow_Order_By>>;
  where?: InputMaybe<PatientFlowsheetRow_Bool_Exp>;
};

/** columns and relationships of "flowsheetParameter" */
export type FlowsheetParameter = {
  __typename?: 'flowsheetParameter';
  apiKey: Scalars['String'];
  createdAt: Scalars['timestamptz'];
  /** An array relationship */
  flowsheets: Array<FlowsheetParameterFlowsheet>;
  /** An aggregate relationship */
  flowsheets_aggregate: FlowsheetParameterFlowsheet_Aggregate;
  id: Scalars['uuid'];
  isSystemParameter: Scalars['Boolean'];
  title: Scalars['String'];
  type: Scalars['String'];
  unit: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  /** An object relationship */
  workspace: Workspace;
  workspaceId: Scalars['uuid'];
};


/** columns and relationships of "flowsheetParameter" */
export type FlowsheetParameterFlowsheetsArgs = {
  distinct_on?: InputMaybe<Array<FlowsheetParameterFlowsheet_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<FlowsheetParameterFlowsheet_Order_By>>;
  where?: InputMaybe<FlowsheetParameterFlowsheet_Bool_Exp>;
};


/** columns and relationships of "flowsheetParameter" */
export type FlowsheetParameterFlowsheets_AggregateArgs = {
  distinct_on?: InputMaybe<Array<FlowsheetParameterFlowsheet_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<FlowsheetParameterFlowsheet_Order_By>>;
  where?: InputMaybe<FlowsheetParameterFlowsheet_Bool_Exp>;
};

/** columns and relationships of "flowsheetParameterFlowsheet" */
export type FlowsheetParameterFlowsheet = {
  __typename?: 'flowsheetParameterFlowsheet';
  createdAt: Scalars['timestamptz'];
  /** An object relationship */
  flowsheet: Flowsheet;
  flowsheetId: Scalars['uuid'];
  flowsheetParameterId: Scalars['uuid'];
  id: Scalars['uuid'];
  /** An object relationship */
  parameter: FlowsheetParameter;
  updatedAt: Scalars['timestamptz'];
  /** An object relationship */
  workspace: Workspace;
  workspaceId: Scalars['uuid'];
};

/** aggregated selection of "flowsheetParameterFlowsheet" */
export type FlowsheetParameterFlowsheet_Aggregate = {
  __typename?: 'flowsheetParameterFlowsheet_aggregate';
  aggregate?: Maybe<FlowsheetParameterFlowsheet_Aggregate_Fields>;
  nodes: Array<FlowsheetParameterFlowsheet>;
};

export type FlowsheetParameterFlowsheet_Aggregate_Bool_Exp = {
  count?: InputMaybe<FlowsheetParameterFlowsheet_Aggregate_Bool_Exp_Count>;
};

export type FlowsheetParameterFlowsheet_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<FlowsheetParameterFlowsheet_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<FlowsheetParameterFlowsheet_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "flowsheetParameterFlowsheet" */
export type FlowsheetParameterFlowsheet_Aggregate_Fields = {
  __typename?: 'flowsheetParameterFlowsheet_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<FlowsheetParameterFlowsheet_Max_Fields>;
  min?: Maybe<FlowsheetParameterFlowsheet_Min_Fields>;
};


/** aggregate fields of "flowsheetParameterFlowsheet" */
export type FlowsheetParameterFlowsheet_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<FlowsheetParameterFlowsheet_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "flowsheetParameterFlowsheet" */
export type FlowsheetParameterFlowsheet_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<FlowsheetParameterFlowsheet_Max_Order_By>;
  min?: InputMaybe<FlowsheetParameterFlowsheet_Min_Order_By>;
};

/** input type for inserting array relation for remote table "flowsheetParameterFlowsheet" */
export type FlowsheetParameterFlowsheet_Arr_Rel_Insert_Input = {
  data: Array<FlowsheetParameterFlowsheet_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<FlowsheetParameterFlowsheet_On_Conflict>;
};

/** Boolean expression to filter rows from the table "flowsheetParameterFlowsheet". All fields are combined with a logical 'AND'. */
export type FlowsheetParameterFlowsheet_Bool_Exp = {
  _and?: InputMaybe<Array<FlowsheetParameterFlowsheet_Bool_Exp>>;
  _not?: InputMaybe<FlowsheetParameterFlowsheet_Bool_Exp>;
  _or?: InputMaybe<Array<FlowsheetParameterFlowsheet_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  flowsheet?: InputMaybe<Flowsheet_Bool_Exp>;
  flowsheetId?: InputMaybe<Uuid_Comparison_Exp>;
  flowsheetParameterId?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  parameter?: InputMaybe<FlowsheetParameter_Bool_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  workspace?: InputMaybe<Workspace_Bool_Exp>;
  workspaceId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "flowsheetParameterFlowsheet" */
export enum FlowsheetParameterFlowsheet_Constraint {
  /** unique or primary key constraint on columns "flowsheetId", "flowsheetParameterId" */
  FlowsheetParameterFlowsheetFlowsheetIdFlowsheetParameterIKey = 'flowsheetParameterFlowsheet_flowsheetId_flowsheetParameterI_key',
  /** unique or primary key constraint on columns "id" */
  FlowsheetParameterFlowsheetPkey = 'flowsheetParameterFlowsheet_pkey'
}

/** input type for inserting data into table "flowsheetParameterFlowsheet" */
export type FlowsheetParameterFlowsheet_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  flowsheet?: InputMaybe<Flowsheet_Obj_Rel_Insert_Input>;
  flowsheetId?: InputMaybe<Scalars['uuid']>;
  flowsheetParameterId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  parameter?: InputMaybe<FlowsheetParameter_Obj_Rel_Insert_Input>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspace?: InputMaybe<Workspace_Obj_Rel_Insert_Input>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type FlowsheetParameterFlowsheet_Max_Fields = {
  __typename?: 'flowsheetParameterFlowsheet_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  flowsheetId?: Maybe<Scalars['uuid']>;
  flowsheetParameterId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "flowsheetParameterFlowsheet" */
export type FlowsheetParameterFlowsheet_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  flowsheetId?: InputMaybe<Order_By>;
  flowsheetParameterId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type FlowsheetParameterFlowsheet_Min_Fields = {
  __typename?: 'flowsheetParameterFlowsheet_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  flowsheetId?: Maybe<Scalars['uuid']>;
  flowsheetParameterId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "flowsheetParameterFlowsheet" */
export type FlowsheetParameterFlowsheet_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  flowsheetId?: InputMaybe<Order_By>;
  flowsheetParameterId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "flowsheetParameterFlowsheet" */
export type FlowsheetParameterFlowsheet_Mutation_Response = {
  __typename?: 'flowsheetParameterFlowsheet_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<FlowsheetParameterFlowsheet>;
};

/** on_conflict condition type for table "flowsheetParameterFlowsheet" */
export type FlowsheetParameterFlowsheet_On_Conflict = {
  constraint: FlowsheetParameterFlowsheet_Constraint;
  update_columns?: Array<FlowsheetParameterFlowsheet_Update_Column>;
  where?: InputMaybe<FlowsheetParameterFlowsheet_Bool_Exp>;
};

/** Ordering options when selecting data from "flowsheetParameterFlowsheet". */
export type FlowsheetParameterFlowsheet_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  flowsheet?: InputMaybe<Flowsheet_Order_By>;
  flowsheetId?: InputMaybe<Order_By>;
  flowsheetParameterId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  parameter?: InputMaybe<FlowsheetParameter_Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  workspace?: InputMaybe<Workspace_Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: flowsheetParameterFlowsheet */
export type FlowsheetParameterFlowsheet_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "flowsheetParameterFlowsheet" */
export enum FlowsheetParameterFlowsheet_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  FlowsheetId = 'flowsheetId',
  /** column name */
  FlowsheetParameterId = 'flowsheetParameterId',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WorkspaceId = 'workspaceId'
}

/** input type for updating data in table "flowsheetParameterFlowsheet" */
export type FlowsheetParameterFlowsheet_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  flowsheetId?: InputMaybe<Scalars['uuid']>;
  flowsheetParameterId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "flowsheetParameterFlowsheet" */
export type FlowsheetParameterFlowsheet_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: FlowsheetParameterFlowsheet_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type FlowsheetParameterFlowsheet_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  flowsheetId?: InputMaybe<Scalars['uuid']>;
  flowsheetParameterId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "flowsheetParameterFlowsheet" */
export enum FlowsheetParameterFlowsheet_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  FlowsheetId = 'flowsheetId',
  /** column name */
  FlowsheetParameterId = 'flowsheetParameterId',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WorkspaceId = 'workspaceId'
}

export type FlowsheetParameterFlowsheet_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<FlowsheetParameterFlowsheet_Set_Input>;
  /** filter the rows which have to be updated */
  where: FlowsheetParameterFlowsheet_Bool_Exp;
};

/** columns and relationships of "flowsheetParameterGroup" */
export type FlowsheetParameterGroup = {
  __typename?: 'flowsheetParameterGroup';
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  title: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  /** An object relationship */
  workspace: Workspace;
  workspaceId: Scalars['uuid'];
};

/** aggregated selection of "flowsheetParameterGroup" */
export type FlowsheetParameterGroup_Aggregate = {
  __typename?: 'flowsheetParameterGroup_aggregate';
  aggregate?: Maybe<FlowsheetParameterGroup_Aggregate_Fields>;
  nodes: Array<FlowsheetParameterGroup>;
};

/** aggregate fields of "flowsheetParameterGroup" */
export type FlowsheetParameterGroup_Aggregate_Fields = {
  __typename?: 'flowsheetParameterGroup_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<FlowsheetParameterGroup_Max_Fields>;
  min?: Maybe<FlowsheetParameterGroup_Min_Fields>;
};


/** aggregate fields of "flowsheetParameterGroup" */
export type FlowsheetParameterGroup_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<FlowsheetParameterGroup_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "flowsheetParameterGroup". All fields are combined with a logical 'AND'. */
export type FlowsheetParameterGroup_Bool_Exp = {
  _and?: InputMaybe<Array<FlowsheetParameterGroup_Bool_Exp>>;
  _not?: InputMaybe<FlowsheetParameterGroup_Bool_Exp>;
  _or?: InputMaybe<Array<FlowsheetParameterGroup_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  workspace?: InputMaybe<Workspace_Bool_Exp>;
  workspaceId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "flowsheetParameterGroup" */
export enum FlowsheetParameterGroup_Constraint {
  /** unique or primary key constraint on columns "id" */
  FlowsheetParameterGroupPkey = 'flowsheetParameterGroup_pkey',
  /** unique or primary key constraint on columns "workspaceId", "title" */
  FlowsheetParameterGroupTitleWorkspaceIdKey = 'flowsheetParameterGroup_title_workspaceId_key'
}

/** input type for inserting data into table "flowsheetParameterGroup" */
export type FlowsheetParameterGroup_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspace?: InputMaybe<Workspace_Obj_Rel_Insert_Input>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type FlowsheetParameterGroup_Max_Fields = {
  __typename?: 'flowsheetParameterGroup_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type FlowsheetParameterGroup_Min_Fields = {
  __typename?: 'flowsheetParameterGroup_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "flowsheetParameterGroup" */
export type FlowsheetParameterGroup_Mutation_Response = {
  __typename?: 'flowsheetParameterGroup_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<FlowsheetParameterGroup>;
};

/** on_conflict condition type for table "flowsheetParameterGroup" */
export type FlowsheetParameterGroup_On_Conflict = {
  constraint: FlowsheetParameterGroup_Constraint;
  update_columns?: Array<FlowsheetParameterGroup_Update_Column>;
  where?: InputMaybe<FlowsheetParameterGroup_Bool_Exp>;
};

/** Ordering options when selecting data from "flowsheetParameterGroup". */
export type FlowsheetParameterGroup_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  workspace?: InputMaybe<Workspace_Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: flowsheetParameterGroup */
export type FlowsheetParameterGroup_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "flowsheetParameterGroup" */
export enum FlowsheetParameterGroup_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WorkspaceId = 'workspaceId'
}

/** input type for updating data in table "flowsheetParameterGroup" */
export type FlowsheetParameterGroup_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "flowsheetParameterGroup" */
export type FlowsheetParameterGroup_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: FlowsheetParameterGroup_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type FlowsheetParameterGroup_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "flowsheetParameterGroup" */
export enum FlowsheetParameterGroup_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WorkspaceId = 'workspaceId'
}

export type FlowsheetParameterGroup_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<FlowsheetParameterGroup_Set_Input>;
  /** filter the rows which have to be updated */
  where: FlowsheetParameterGroup_Bool_Exp;
};

/** aggregated selection of "flowsheetParameter" */
export type FlowsheetParameter_Aggregate = {
  __typename?: 'flowsheetParameter_aggregate';
  aggregate?: Maybe<FlowsheetParameter_Aggregate_Fields>;
  nodes: Array<FlowsheetParameter>;
};

/** aggregate fields of "flowsheetParameter" */
export type FlowsheetParameter_Aggregate_Fields = {
  __typename?: 'flowsheetParameter_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<FlowsheetParameter_Max_Fields>;
  min?: Maybe<FlowsheetParameter_Min_Fields>;
};


/** aggregate fields of "flowsheetParameter" */
export type FlowsheetParameter_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<FlowsheetParameter_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "flowsheetParameter". All fields are combined with a logical 'AND'. */
export type FlowsheetParameter_Bool_Exp = {
  _and?: InputMaybe<Array<FlowsheetParameter_Bool_Exp>>;
  _not?: InputMaybe<FlowsheetParameter_Bool_Exp>;
  _or?: InputMaybe<Array<FlowsheetParameter_Bool_Exp>>;
  apiKey?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  flowsheets?: InputMaybe<FlowsheetParameterFlowsheet_Bool_Exp>;
  flowsheets_aggregate?: InputMaybe<FlowsheetParameterFlowsheet_Aggregate_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  isSystemParameter?: InputMaybe<Boolean_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  unit?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  workspace?: InputMaybe<Workspace_Bool_Exp>;
  workspaceId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "flowsheetParameter" */
export enum FlowsheetParameter_Constraint {
  /** unique or primary key constraint on columns "id" */
  FlowsheetParameterPkey = 'flowsheetParameter_pkey',
  /** unique or primary key constraint on columns "apiKey", "workspaceId", "title" */
  FlowsheetParameterTitleWorkspaceIdApiKeyKey = 'flowsheetParameter_title_workspaceId_apiKey_key'
}

/** input type for inserting data into table "flowsheetParameter" */
export type FlowsheetParameter_Insert_Input = {
  apiKey?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  flowsheets?: InputMaybe<FlowsheetParameterFlowsheet_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']>;
  isSystemParameter?: InputMaybe<Scalars['Boolean']>;
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  unit?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspace?: InputMaybe<Workspace_Obj_Rel_Insert_Input>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type FlowsheetParameter_Max_Fields = {
  __typename?: 'flowsheetParameter_max_fields';
  apiKey?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type FlowsheetParameter_Min_Fields = {
  __typename?: 'flowsheetParameter_min_fields';
  apiKey?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "flowsheetParameter" */
export type FlowsheetParameter_Mutation_Response = {
  __typename?: 'flowsheetParameter_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<FlowsheetParameter>;
};

/** input type for inserting object relation for remote table "flowsheetParameter" */
export type FlowsheetParameter_Obj_Rel_Insert_Input = {
  data: FlowsheetParameter_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<FlowsheetParameter_On_Conflict>;
};

/** on_conflict condition type for table "flowsheetParameter" */
export type FlowsheetParameter_On_Conflict = {
  constraint: FlowsheetParameter_Constraint;
  update_columns?: Array<FlowsheetParameter_Update_Column>;
  where?: InputMaybe<FlowsheetParameter_Bool_Exp>;
};

/** Ordering options when selecting data from "flowsheetParameter". */
export type FlowsheetParameter_Order_By = {
  apiKey?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  flowsheets_aggregate?: InputMaybe<FlowsheetParameterFlowsheet_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  isSystemParameter?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  unit?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  workspace?: InputMaybe<Workspace_Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: flowsheetParameter */
export type FlowsheetParameter_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "flowsheetParameter" */
export enum FlowsheetParameter_Select_Column {
  /** column name */
  ApiKey = 'apiKey',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  IsSystemParameter = 'isSystemParameter',
  /** column name */
  Title = 'title',
  /** column name */
  Type = 'type',
  /** column name */
  Unit = 'unit',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WorkspaceId = 'workspaceId'
}

/** input type for updating data in table "flowsheetParameter" */
export type FlowsheetParameter_Set_Input = {
  apiKey?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  isSystemParameter?: InputMaybe<Scalars['Boolean']>;
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  unit?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "flowsheetParameter" */
export type FlowsheetParameter_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: FlowsheetParameter_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type FlowsheetParameter_Stream_Cursor_Value_Input = {
  apiKey?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  isSystemParameter?: InputMaybe<Scalars['Boolean']>;
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  unit?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "flowsheetParameter" */
export enum FlowsheetParameter_Update_Column {
  /** column name */
  ApiKey = 'apiKey',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  IsSystemParameter = 'isSystemParameter',
  /** column name */
  Title = 'title',
  /** column name */
  Type = 'type',
  /** column name */
  Unit = 'unit',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WorkspaceId = 'workspaceId'
}

export type FlowsheetParameter_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<FlowsheetParameter_Set_Input>;
  /** filter the rows which have to be updated */
  where: FlowsheetParameter_Bool_Exp;
};

/** aggregated selection of "flowsheet" */
export type Flowsheet_Aggregate = {
  __typename?: 'flowsheet_aggregate';
  aggregate?: Maybe<Flowsheet_Aggregate_Fields>;
  nodes: Array<Flowsheet>;
};

/** aggregate fields of "flowsheet" */
export type Flowsheet_Aggregate_Fields = {
  __typename?: 'flowsheet_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Flowsheet_Max_Fields>;
  min?: Maybe<Flowsheet_Min_Fields>;
};


/** aggregate fields of "flowsheet" */
export type Flowsheet_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Flowsheet_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "flowsheet". All fields are combined with a logical 'AND'. */
export type Flowsheet_Bool_Exp = {
  _and?: InputMaybe<Array<Flowsheet_Bool_Exp>>;
  _not?: InputMaybe<Flowsheet_Bool_Exp>;
  _or?: InputMaybe<Array<Flowsheet_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  parameters?: InputMaybe<FlowsheetParameterFlowsheet_Bool_Exp>;
  parameters_aggregate?: InputMaybe<FlowsheetParameterFlowsheet_Aggregate_Bool_Exp>;
  patientData?: InputMaybe<PatientFlowsheetData_Bool_Exp>;
  patientData_aggregate?: InputMaybe<PatientFlowsheetData_Aggregate_Bool_Exp>;
  patientRows?: InputMaybe<PatientFlowsheetRow_Bool_Exp>;
  patientRows_aggregate?: InputMaybe<PatientFlowsheetRow_Aggregate_Bool_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  workspace?: InputMaybe<Workspace_Bool_Exp>;
  workspaceId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "flowsheet" */
export enum Flowsheet_Constraint {
  /** unique or primary key constraint on columns "id" */
  FlowsheetPkey = 'flowsheet_pkey',
  /** unique or primary key constraint on columns "workspaceId", "title" */
  FlowsheetWorkspaceIdTitleKey = 'flowsheet_workspaceId_title_key'
}

/** input type for inserting data into table "flowsheet" */
export type Flowsheet_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['uuid']>;
  parameters?: InputMaybe<FlowsheetParameterFlowsheet_Arr_Rel_Insert_Input>;
  patientData?: InputMaybe<PatientFlowsheetData_Arr_Rel_Insert_Input>;
  patientRows?: InputMaybe<PatientFlowsheetRow_Arr_Rel_Insert_Input>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspace?: InputMaybe<Workspace_Obj_Rel_Insert_Input>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Flowsheet_Max_Fields = {
  __typename?: 'flowsheet_max_fields';
  createdAt?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Flowsheet_Min_Fields = {
  __typename?: 'flowsheet_min_fields';
  createdAt?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "flowsheet" */
export type Flowsheet_Mutation_Response = {
  __typename?: 'flowsheet_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Flowsheet>;
};

/** input type for inserting object relation for remote table "flowsheet" */
export type Flowsheet_Obj_Rel_Insert_Input = {
  data: Flowsheet_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Flowsheet_On_Conflict>;
};

/** on_conflict condition type for table "flowsheet" */
export type Flowsheet_On_Conflict = {
  constraint: Flowsheet_Constraint;
  update_columns?: Array<Flowsheet_Update_Column>;
  where?: InputMaybe<Flowsheet_Bool_Exp>;
};

/** Ordering options when selecting data from "flowsheet". */
export type Flowsheet_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  parameters_aggregate?: InputMaybe<FlowsheetParameterFlowsheet_Aggregate_Order_By>;
  patientData_aggregate?: InputMaybe<PatientFlowsheetData_Aggregate_Order_By>;
  patientRows_aggregate?: InputMaybe<PatientFlowsheetRow_Aggregate_Order_By>;
  title?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  workspace?: InputMaybe<Workspace_Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: flowsheet */
export type Flowsheet_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "flowsheet" */
export enum Flowsheet_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WorkspaceId = 'workspaceId'
}

/** input type for updating data in table "flowsheet" */
export type Flowsheet_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['uuid']>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "flowsheet" */
export type Flowsheet_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Flowsheet_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Flowsheet_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['uuid']>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "flowsheet" */
export enum Flowsheet_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WorkspaceId = 'workspaceId'
}

export type Flowsheet_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Flowsheet_Set_Input>;
  /** filter the rows which have to be updated */
  where: Flowsheet_Bool_Exp;
};

/** columns and relationships of "genericInvoiceLineItem" */
export type GenericInvoiceLineItem = {
  __typename?: 'genericInvoiceLineItem';
  allowGratuity: Scalars['Boolean'];
  createdAt: Scalars['timestamptz'];
  discountAmount: Scalars['float8'];
  discountTotal: Scalars['float8'];
  discountType: Scalars['String'];
  /** An array relationship */
  discounts: Array<GenericInvoiceLineItemDiscount>;
  /** An aggregate relationship */
  discounts_aggregate: GenericInvoiceLineItemDiscount_Aggregate;
  /** An array relationship */
  giftCards: Array<GiftCard>;
  /** An aggregate relationship */
  giftCards_aggregate: GiftCard_Aggregate;
  id: Scalars['uuid'];
  /** An object relationship */
  invoice: Invoice;
  invoiceId: Scalars['uuid'];
  /** An object relationship */
  membership?: Maybe<Membership>;
  membershipId?: Maybe<Scalars['uuid']>;
  name: Scalars['String'];
  /** An object relationship */
  package?: Maybe<Package>;
  packageId?: Maybe<Scalars['uuid']>;
  postTaxTotal: Scalars['float8'];
  preTaxTotal: Scalars['float8'];
  pricePerUnit: Scalars['Int'];
  /** An object relationship */
  promotion?: Maybe<Promotion>;
  promotionId?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  provider?: Maybe<Provider>;
  providerId?: Maybe<Scalars['uuid']>;
  quantity: Scalars['Int'];
  receiverId?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  service?: Maybe<Service>;
  serviceId?: Maybe<Scalars['uuid']>;
  taxRate: Scalars['float8'];
  taxTotal: Scalars['float8'];
  type?: Maybe<Scalars['String']>;
  updatedAt: Scalars['timestamptz'];
  useCount: Scalars['Int'];
  /** An object relationship */
  workspace: Workspace;
  workspaceId: Scalars['uuid'];
};


/** columns and relationships of "genericInvoiceLineItem" */
export type GenericInvoiceLineItemDiscountsArgs = {
  distinct_on?: InputMaybe<Array<GenericInvoiceLineItemDiscount_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GenericInvoiceLineItemDiscount_Order_By>>;
  where?: InputMaybe<GenericInvoiceLineItemDiscount_Bool_Exp>;
};


/** columns and relationships of "genericInvoiceLineItem" */
export type GenericInvoiceLineItemDiscounts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<GenericInvoiceLineItemDiscount_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GenericInvoiceLineItemDiscount_Order_By>>;
  where?: InputMaybe<GenericInvoiceLineItemDiscount_Bool_Exp>;
};


/** columns and relationships of "genericInvoiceLineItem" */
export type GenericInvoiceLineItemGiftCardsArgs = {
  distinct_on?: InputMaybe<Array<GiftCard_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GiftCard_Order_By>>;
  where?: InputMaybe<GiftCard_Bool_Exp>;
};


/** columns and relationships of "genericInvoiceLineItem" */
export type GenericInvoiceLineItemGiftCards_AggregateArgs = {
  distinct_on?: InputMaybe<Array<GiftCard_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GiftCard_Order_By>>;
  where?: InputMaybe<GiftCard_Bool_Exp>;
};

/** columns and relationships of "genericInvoiceLineItemDiscount" */
export type GenericInvoiceLineItemDiscount = {
  __typename?: 'genericInvoiceLineItemDiscount';
  createdAt: Scalars['timestamptz'];
  discount: Scalars['float8'];
  discountInvoiceLineItemId: Scalars['uuid'];
  genericInvoiceLineItemId: Scalars['uuid'];
  id: Scalars['uuid'];
  invoiceId: Scalars['uuid'];
  updatedAt: Scalars['timestamptz'];
  workspaceId: Scalars['uuid'];
};

/** aggregated selection of "genericInvoiceLineItemDiscount" */
export type GenericInvoiceLineItemDiscount_Aggregate = {
  __typename?: 'genericInvoiceLineItemDiscount_aggregate';
  aggregate?: Maybe<GenericInvoiceLineItemDiscount_Aggregate_Fields>;
  nodes: Array<GenericInvoiceLineItemDiscount>;
};

export type GenericInvoiceLineItemDiscount_Aggregate_Bool_Exp = {
  avg?: InputMaybe<GenericInvoiceLineItemDiscount_Aggregate_Bool_Exp_Avg>;
  corr?: InputMaybe<GenericInvoiceLineItemDiscount_Aggregate_Bool_Exp_Corr>;
  count?: InputMaybe<GenericInvoiceLineItemDiscount_Aggregate_Bool_Exp_Count>;
  covar_samp?: InputMaybe<GenericInvoiceLineItemDiscount_Aggregate_Bool_Exp_Covar_Samp>;
  max?: InputMaybe<GenericInvoiceLineItemDiscount_Aggregate_Bool_Exp_Max>;
  min?: InputMaybe<GenericInvoiceLineItemDiscount_Aggregate_Bool_Exp_Min>;
  stddev_samp?: InputMaybe<GenericInvoiceLineItemDiscount_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: InputMaybe<GenericInvoiceLineItemDiscount_Aggregate_Bool_Exp_Sum>;
  var_samp?: InputMaybe<GenericInvoiceLineItemDiscount_Aggregate_Bool_Exp_Var_Samp>;
};

export type GenericInvoiceLineItemDiscount_Aggregate_Bool_Exp_Avg = {
  arguments: GenericInvoiceLineItemDiscount_Select_Column_GenericInvoiceLineItemDiscount_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<GenericInvoiceLineItemDiscount_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type GenericInvoiceLineItemDiscount_Aggregate_Bool_Exp_Corr = {
  arguments: GenericInvoiceLineItemDiscount_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<GenericInvoiceLineItemDiscount_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type GenericInvoiceLineItemDiscount_Aggregate_Bool_Exp_Corr_Arguments = {
  X: GenericInvoiceLineItemDiscount_Select_Column_GenericInvoiceLineItemDiscount_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: GenericInvoiceLineItemDiscount_Select_Column_GenericInvoiceLineItemDiscount_Aggregate_Bool_Exp_Corr_Arguments_Columns;
};

export type GenericInvoiceLineItemDiscount_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<GenericInvoiceLineItemDiscount_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<GenericInvoiceLineItemDiscount_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type GenericInvoiceLineItemDiscount_Aggregate_Bool_Exp_Covar_Samp = {
  arguments: GenericInvoiceLineItemDiscount_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<GenericInvoiceLineItemDiscount_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type GenericInvoiceLineItemDiscount_Aggregate_Bool_Exp_Covar_Samp_Arguments = {
  X: GenericInvoiceLineItemDiscount_Select_Column_GenericInvoiceLineItemDiscount_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  Y: GenericInvoiceLineItemDiscount_Select_Column_GenericInvoiceLineItemDiscount_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
};

export type GenericInvoiceLineItemDiscount_Aggregate_Bool_Exp_Max = {
  arguments: GenericInvoiceLineItemDiscount_Select_Column_GenericInvoiceLineItemDiscount_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<GenericInvoiceLineItemDiscount_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type GenericInvoiceLineItemDiscount_Aggregate_Bool_Exp_Min = {
  arguments: GenericInvoiceLineItemDiscount_Select_Column_GenericInvoiceLineItemDiscount_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<GenericInvoiceLineItemDiscount_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type GenericInvoiceLineItemDiscount_Aggregate_Bool_Exp_Stddev_Samp = {
  arguments: GenericInvoiceLineItemDiscount_Select_Column_GenericInvoiceLineItemDiscount_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<GenericInvoiceLineItemDiscount_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type GenericInvoiceLineItemDiscount_Aggregate_Bool_Exp_Sum = {
  arguments: GenericInvoiceLineItemDiscount_Select_Column_GenericInvoiceLineItemDiscount_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<GenericInvoiceLineItemDiscount_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type GenericInvoiceLineItemDiscount_Aggregate_Bool_Exp_Var_Samp = {
  arguments: GenericInvoiceLineItemDiscount_Select_Column_GenericInvoiceLineItemDiscount_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<GenericInvoiceLineItemDiscount_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

/** aggregate fields of "genericInvoiceLineItemDiscount" */
export type GenericInvoiceLineItemDiscount_Aggregate_Fields = {
  __typename?: 'genericInvoiceLineItemDiscount_aggregate_fields';
  avg?: Maybe<GenericInvoiceLineItemDiscount_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<GenericInvoiceLineItemDiscount_Max_Fields>;
  min?: Maybe<GenericInvoiceLineItemDiscount_Min_Fields>;
  stddev?: Maybe<GenericInvoiceLineItemDiscount_Stddev_Fields>;
  stddev_pop?: Maybe<GenericInvoiceLineItemDiscount_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<GenericInvoiceLineItemDiscount_Stddev_Samp_Fields>;
  sum?: Maybe<GenericInvoiceLineItemDiscount_Sum_Fields>;
  var_pop?: Maybe<GenericInvoiceLineItemDiscount_Var_Pop_Fields>;
  var_samp?: Maybe<GenericInvoiceLineItemDiscount_Var_Samp_Fields>;
  variance?: Maybe<GenericInvoiceLineItemDiscount_Variance_Fields>;
};


/** aggregate fields of "genericInvoiceLineItemDiscount" */
export type GenericInvoiceLineItemDiscount_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<GenericInvoiceLineItemDiscount_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "genericInvoiceLineItemDiscount" */
export type GenericInvoiceLineItemDiscount_Aggregate_Order_By = {
  avg?: InputMaybe<GenericInvoiceLineItemDiscount_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<GenericInvoiceLineItemDiscount_Max_Order_By>;
  min?: InputMaybe<GenericInvoiceLineItemDiscount_Min_Order_By>;
  stddev?: InputMaybe<GenericInvoiceLineItemDiscount_Stddev_Order_By>;
  stddev_pop?: InputMaybe<GenericInvoiceLineItemDiscount_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<GenericInvoiceLineItemDiscount_Stddev_Samp_Order_By>;
  sum?: InputMaybe<GenericInvoiceLineItemDiscount_Sum_Order_By>;
  var_pop?: InputMaybe<GenericInvoiceLineItemDiscount_Var_Pop_Order_By>;
  var_samp?: InputMaybe<GenericInvoiceLineItemDiscount_Var_Samp_Order_By>;
  variance?: InputMaybe<GenericInvoiceLineItemDiscount_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "genericInvoiceLineItemDiscount" */
export type GenericInvoiceLineItemDiscount_Arr_Rel_Insert_Input = {
  data: Array<GenericInvoiceLineItemDiscount_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<GenericInvoiceLineItemDiscount_On_Conflict>;
};

/** aggregate avg on columns */
export type GenericInvoiceLineItemDiscount_Avg_Fields = {
  __typename?: 'genericInvoiceLineItemDiscount_avg_fields';
  discount?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "genericInvoiceLineItemDiscount" */
export type GenericInvoiceLineItemDiscount_Avg_Order_By = {
  discount?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "genericInvoiceLineItemDiscount". All fields are combined with a logical 'AND'. */
export type GenericInvoiceLineItemDiscount_Bool_Exp = {
  _and?: InputMaybe<Array<GenericInvoiceLineItemDiscount_Bool_Exp>>;
  _not?: InputMaybe<GenericInvoiceLineItemDiscount_Bool_Exp>;
  _or?: InputMaybe<Array<GenericInvoiceLineItemDiscount_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  discount?: InputMaybe<Float8_Comparison_Exp>;
  discountInvoiceLineItemId?: InputMaybe<Uuid_Comparison_Exp>;
  genericInvoiceLineItemId?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  invoiceId?: InputMaybe<Uuid_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  workspaceId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "genericInvoiceLineItemDiscount" */
export enum GenericInvoiceLineItemDiscount_Constraint {
  /** unique or primary key constraint on columns "id" */
  GenericInvoiceLineItemDiscountPkey = 'genericInvoiceLineItemDiscount_pkey'
}

/** input type for incrementing numeric columns in table "genericInvoiceLineItemDiscount" */
export type GenericInvoiceLineItemDiscount_Inc_Input = {
  discount?: InputMaybe<Scalars['float8']>;
};

/** input type for inserting data into table "genericInvoiceLineItemDiscount" */
export type GenericInvoiceLineItemDiscount_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  discount?: InputMaybe<Scalars['float8']>;
  discountInvoiceLineItemId?: InputMaybe<Scalars['uuid']>;
  genericInvoiceLineItemId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  invoiceId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type GenericInvoiceLineItemDiscount_Max_Fields = {
  __typename?: 'genericInvoiceLineItemDiscount_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  discount?: Maybe<Scalars['float8']>;
  discountInvoiceLineItemId?: Maybe<Scalars['uuid']>;
  genericInvoiceLineItemId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  invoiceId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "genericInvoiceLineItemDiscount" */
export type GenericInvoiceLineItemDiscount_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  discount?: InputMaybe<Order_By>;
  discountInvoiceLineItemId?: InputMaybe<Order_By>;
  genericInvoiceLineItemId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoiceId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type GenericInvoiceLineItemDiscount_Min_Fields = {
  __typename?: 'genericInvoiceLineItemDiscount_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  discount?: Maybe<Scalars['float8']>;
  discountInvoiceLineItemId?: Maybe<Scalars['uuid']>;
  genericInvoiceLineItemId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  invoiceId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "genericInvoiceLineItemDiscount" */
export type GenericInvoiceLineItemDiscount_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  discount?: InputMaybe<Order_By>;
  discountInvoiceLineItemId?: InputMaybe<Order_By>;
  genericInvoiceLineItemId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoiceId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "genericInvoiceLineItemDiscount" */
export type GenericInvoiceLineItemDiscount_Mutation_Response = {
  __typename?: 'genericInvoiceLineItemDiscount_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<GenericInvoiceLineItemDiscount>;
};

/** on_conflict condition type for table "genericInvoiceLineItemDiscount" */
export type GenericInvoiceLineItemDiscount_On_Conflict = {
  constraint: GenericInvoiceLineItemDiscount_Constraint;
  update_columns?: Array<GenericInvoiceLineItemDiscount_Update_Column>;
  where?: InputMaybe<GenericInvoiceLineItemDiscount_Bool_Exp>;
};

/** Ordering options when selecting data from "genericInvoiceLineItemDiscount". */
export type GenericInvoiceLineItemDiscount_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  discount?: InputMaybe<Order_By>;
  discountInvoiceLineItemId?: InputMaybe<Order_By>;
  genericInvoiceLineItemId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoiceId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: genericInvoiceLineItemDiscount */
export type GenericInvoiceLineItemDiscount_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "genericInvoiceLineItemDiscount" */
export enum GenericInvoiceLineItemDiscount_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Discount = 'discount',
  /** column name */
  DiscountInvoiceLineItemId = 'discountInvoiceLineItemId',
  /** column name */
  GenericInvoiceLineItemId = 'genericInvoiceLineItemId',
  /** column name */
  Id = 'id',
  /** column name */
  InvoiceId = 'invoiceId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WorkspaceId = 'workspaceId'
}

/** select "genericInvoiceLineItemDiscount_aggregate_bool_exp_avg_arguments_columns" columns of table "genericInvoiceLineItemDiscount" */
export enum GenericInvoiceLineItemDiscount_Select_Column_GenericInvoiceLineItemDiscount_Aggregate_Bool_Exp_Avg_Arguments_Columns {
  /** column name */
  Discount = 'discount'
}

/** select "genericInvoiceLineItemDiscount_aggregate_bool_exp_corr_arguments_columns" columns of table "genericInvoiceLineItemDiscount" */
export enum GenericInvoiceLineItemDiscount_Select_Column_GenericInvoiceLineItemDiscount_Aggregate_Bool_Exp_Corr_Arguments_Columns {
  /** column name */
  Discount = 'discount'
}

/** select "genericInvoiceLineItemDiscount_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "genericInvoiceLineItemDiscount" */
export enum GenericInvoiceLineItemDiscount_Select_Column_GenericInvoiceLineItemDiscount_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns {
  /** column name */
  Discount = 'discount'
}

/** select "genericInvoiceLineItemDiscount_aggregate_bool_exp_max_arguments_columns" columns of table "genericInvoiceLineItemDiscount" */
export enum GenericInvoiceLineItemDiscount_Select_Column_GenericInvoiceLineItemDiscount_Aggregate_Bool_Exp_Max_Arguments_Columns {
  /** column name */
  Discount = 'discount'
}

/** select "genericInvoiceLineItemDiscount_aggregate_bool_exp_min_arguments_columns" columns of table "genericInvoiceLineItemDiscount" */
export enum GenericInvoiceLineItemDiscount_Select_Column_GenericInvoiceLineItemDiscount_Aggregate_Bool_Exp_Min_Arguments_Columns {
  /** column name */
  Discount = 'discount'
}

/** select "genericInvoiceLineItemDiscount_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "genericInvoiceLineItemDiscount" */
export enum GenericInvoiceLineItemDiscount_Select_Column_GenericInvoiceLineItemDiscount_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns {
  /** column name */
  Discount = 'discount'
}

/** select "genericInvoiceLineItemDiscount_aggregate_bool_exp_sum_arguments_columns" columns of table "genericInvoiceLineItemDiscount" */
export enum GenericInvoiceLineItemDiscount_Select_Column_GenericInvoiceLineItemDiscount_Aggregate_Bool_Exp_Sum_Arguments_Columns {
  /** column name */
  Discount = 'discount'
}

/** select "genericInvoiceLineItemDiscount_aggregate_bool_exp_var_samp_arguments_columns" columns of table "genericInvoiceLineItemDiscount" */
export enum GenericInvoiceLineItemDiscount_Select_Column_GenericInvoiceLineItemDiscount_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns {
  /** column name */
  Discount = 'discount'
}

/** input type for updating data in table "genericInvoiceLineItemDiscount" */
export type GenericInvoiceLineItemDiscount_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  discount?: InputMaybe<Scalars['float8']>;
  discountInvoiceLineItemId?: InputMaybe<Scalars['uuid']>;
  genericInvoiceLineItemId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  invoiceId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type GenericInvoiceLineItemDiscount_Stddev_Fields = {
  __typename?: 'genericInvoiceLineItemDiscount_stddev_fields';
  discount?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "genericInvoiceLineItemDiscount" */
export type GenericInvoiceLineItemDiscount_Stddev_Order_By = {
  discount?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type GenericInvoiceLineItemDiscount_Stddev_Pop_Fields = {
  __typename?: 'genericInvoiceLineItemDiscount_stddev_pop_fields';
  discount?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "genericInvoiceLineItemDiscount" */
export type GenericInvoiceLineItemDiscount_Stddev_Pop_Order_By = {
  discount?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type GenericInvoiceLineItemDiscount_Stddev_Samp_Fields = {
  __typename?: 'genericInvoiceLineItemDiscount_stddev_samp_fields';
  discount?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "genericInvoiceLineItemDiscount" */
export type GenericInvoiceLineItemDiscount_Stddev_Samp_Order_By = {
  discount?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "genericInvoiceLineItemDiscount" */
export type GenericInvoiceLineItemDiscount_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: GenericInvoiceLineItemDiscount_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type GenericInvoiceLineItemDiscount_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  discount?: InputMaybe<Scalars['float8']>;
  discountInvoiceLineItemId?: InputMaybe<Scalars['uuid']>;
  genericInvoiceLineItemId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  invoiceId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type GenericInvoiceLineItemDiscount_Sum_Fields = {
  __typename?: 'genericInvoiceLineItemDiscount_sum_fields';
  discount?: Maybe<Scalars['float8']>;
};

/** order by sum() on columns of table "genericInvoiceLineItemDiscount" */
export type GenericInvoiceLineItemDiscount_Sum_Order_By = {
  discount?: InputMaybe<Order_By>;
};

/** update columns of table "genericInvoiceLineItemDiscount" */
export enum GenericInvoiceLineItemDiscount_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Discount = 'discount',
  /** column name */
  DiscountInvoiceLineItemId = 'discountInvoiceLineItemId',
  /** column name */
  GenericInvoiceLineItemId = 'genericInvoiceLineItemId',
  /** column name */
  Id = 'id',
  /** column name */
  InvoiceId = 'invoiceId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WorkspaceId = 'workspaceId'
}

export type GenericInvoiceLineItemDiscount_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<GenericInvoiceLineItemDiscount_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<GenericInvoiceLineItemDiscount_Set_Input>;
  /** filter the rows which have to be updated */
  where: GenericInvoiceLineItemDiscount_Bool_Exp;
};

/** aggregate var_pop on columns */
export type GenericInvoiceLineItemDiscount_Var_Pop_Fields = {
  __typename?: 'genericInvoiceLineItemDiscount_var_pop_fields';
  discount?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "genericInvoiceLineItemDiscount" */
export type GenericInvoiceLineItemDiscount_Var_Pop_Order_By = {
  discount?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type GenericInvoiceLineItemDiscount_Var_Samp_Fields = {
  __typename?: 'genericInvoiceLineItemDiscount_var_samp_fields';
  discount?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "genericInvoiceLineItemDiscount" */
export type GenericInvoiceLineItemDiscount_Var_Samp_Order_By = {
  discount?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type GenericInvoiceLineItemDiscount_Variance_Fields = {
  __typename?: 'genericInvoiceLineItemDiscount_variance_fields';
  discount?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "genericInvoiceLineItemDiscount" */
export type GenericInvoiceLineItemDiscount_Variance_Order_By = {
  discount?: InputMaybe<Order_By>;
};

/** aggregated selection of "genericInvoiceLineItem" */
export type GenericInvoiceLineItem_Aggregate = {
  __typename?: 'genericInvoiceLineItem_aggregate';
  aggregate?: Maybe<GenericInvoiceLineItem_Aggregate_Fields>;
  nodes: Array<GenericInvoiceLineItem>;
};

export type GenericInvoiceLineItem_Aggregate_Bool_Exp = {
  avg?: InputMaybe<GenericInvoiceLineItem_Aggregate_Bool_Exp_Avg>;
  bool_and?: InputMaybe<GenericInvoiceLineItem_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<GenericInvoiceLineItem_Aggregate_Bool_Exp_Bool_Or>;
  corr?: InputMaybe<GenericInvoiceLineItem_Aggregate_Bool_Exp_Corr>;
  count?: InputMaybe<GenericInvoiceLineItem_Aggregate_Bool_Exp_Count>;
  covar_samp?: InputMaybe<GenericInvoiceLineItem_Aggregate_Bool_Exp_Covar_Samp>;
  max?: InputMaybe<GenericInvoiceLineItem_Aggregate_Bool_Exp_Max>;
  min?: InputMaybe<GenericInvoiceLineItem_Aggregate_Bool_Exp_Min>;
  stddev_samp?: InputMaybe<GenericInvoiceLineItem_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: InputMaybe<GenericInvoiceLineItem_Aggregate_Bool_Exp_Sum>;
  var_samp?: InputMaybe<GenericInvoiceLineItem_Aggregate_Bool_Exp_Var_Samp>;
};

export type GenericInvoiceLineItem_Aggregate_Bool_Exp_Avg = {
  arguments: GenericInvoiceLineItem_Select_Column_GenericInvoiceLineItem_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<GenericInvoiceLineItem_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type GenericInvoiceLineItem_Aggregate_Bool_Exp_Bool_And = {
  arguments: GenericInvoiceLineItem_Select_Column_GenericInvoiceLineItem_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<GenericInvoiceLineItem_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type GenericInvoiceLineItem_Aggregate_Bool_Exp_Bool_Or = {
  arguments: GenericInvoiceLineItem_Select_Column_GenericInvoiceLineItem_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<GenericInvoiceLineItem_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type GenericInvoiceLineItem_Aggregate_Bool_Exp_Corr = {
  arguments: GenericInvoiceLineItem_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<GenericInvoiceLineItem_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type GenericInvoiceLineItem_Aggregate_Bool_Exp_Corr_Arguments = {
  X: GenericInvoiceLineItem_Select_Column_GenericInvoiceLineItem_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: GenericInvoiceLineItem_Select_Column_GenericInvoiceLineItem_Aggregate_Bool_Exp_Corr_Arguments_Columns;
};

export type GenericInvoiceLineItem_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<GenericInvoiceLineItem_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<GenericInvoiceLineItem_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type GenericInvoiceLineItem_Aggregate_Bool_Exp_Covar_Samp = {
  arguments: GenericInvoiceLineItem_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<GenericInvoiceLineItem_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type GenericInvoiceLineItem_Aggregate_Bool_Exp_Covar_Samp_Arguments = {
  X: GenericInvoiceLineItem_Select_Column_GenericInvoiceLineItem_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  Y: GenericInvoiceLineItem_Select_Column_GenericInvoiceLineItem_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
};

export type GenericInvoiceLineItem_Aggregate_Bool_Exp_Max = {
  arguments: GenericInvoiceLineItem_Select_Column_GenericInvoiceLineItem_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<GenericInvoiceLineItem_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type GenericInvoiceLineItem_Aggregate_Bool_Exp_Min = {
  arguments: GenericInvoiceLineItem_Select_Column_GenericInvoiceLineItem_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<GenericInvoiceLineItem_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type GenericInvoiceLineItem_Aggregate_Bool_Exp_Stddev_Samp = {
  arguments: GenericInvoiceLineItem_Select_Column_GenericInvoiceLineItem_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<GenericInvoiceLineItem_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type GenericInvoiceLineItem_Aggregate_Bool_Exp_Sum = {
  arguments: GenericInvoiceLineItem_Select_Column_GenericInvoiceLineItem_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<GenericInvoiceLineItem_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type GenericInvoiceLineItem_Aggregate_Bool_Exp_Var_Samp = {
  arguments: GenericInvoiceLineItem_Select_Column_GenericInvoiceLineItem_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<GenericInvoiceLineItem_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

/** aggregate fields of "genericInvoiceLineItem" */
export type GenericInvoiceLineItem_Aggregate_Fields = {
  __typename?: 'genericInvoiceLineItem_aggregate_fields';
  avg?: Maybe<GenericInvoiceLineItem_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<GenericInvoiceLineItem_Max_Fields>;
  min?: Maybe<GenericInvoiceLineItem_Min_Fields>;
  stddev?: Maybe<GenericInvoiceLineItem_Stddev_Fields>;
  stddev_pop?: Maybe<GenericInvoiceLineItem_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<GenericInvoiceLineItem_Stddev_Samp_Fields>;
  sum?: Maybe<GenericInvoiceLineItem_Sum_Fields>;
  var_pop?: Maybe<GenericInvoiceLineItem_Var_Pop_Fields>;
  var_samp?: Maybe<GenericInvoiceLineItem_Var_Samp_Fields>;
  variance?: Maybe<GenericInvoiceLineItem_Variance_Fields>;
};


/** aggregate fields of "genericInvoiceLineItem" */
export type GenericInvoiceLineItem_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<GenericInvoiceLineItem_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "genericInvoiceLineItem" */
export type GenericInvoiceLineItem_Aggregate_Order_By = {
  avg?: InputMaybe<GenericInvoiceLineItem_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<GenericInvoiceLineItem_Max_Order_By>;
  min?: InputMaybe<GenericInvoiceLineItem_Min_Order_By>;
  stddev?: InputMaybe<GenericInvoiceLineItem_Stddev_Order_By>;
  stddev_pop?: InputMaybe<GenericInvoiceLineItem_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<GenericInvoiceLineItem_Stddev_Samp_Order_By>;
  sum?: InputMaybe<GenericInvoiceLineItem_Sum_Order_By>;
  var_pop?: InputMaybe<GenericInvoiceLineItem_Var_Pop_Order_By>;
  var_samp?: InputMaybe<GenericInvoiceLineItem_Var_Samp_Order_By>;
  variance?: InputMaybe<GenericInvoiceLineItem_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "genericInvoiceLineItem" */
export type GenericInvoiceLineItem_Arr_Rel_Insert_Input = {
  data: Array<GenericInvoiceLineItem_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<GenericInvoiceLineItem_On_Conflict>;
};

/** aggregate avg on columns */
export type GenericInvoiceLineItem_Avg_Fields = {
  __typename?: 'genericInvoiceLineItem_avg_fields';
  discountAmount?: Maybe<Scalars['Float']>;
  discountTotal?: Maybe<Scalars['Float']>;
  postTaxTotal?: Maybe<Scalars['Float']>;
  preTaxTotal?: Maybe<Scalars['Float']>;
  pricePerUnit?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxTotal?: Maybe<Scalars['Float']>;
  useCount?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "genericInvoiceLineItem" */
export type GenericInvoiceLineItem_Avg_Order_By = {
  discountAmount?: InputMaybe<Order_By>;
  discountTotal?: InputMaybe<Order_By>;
  postTaxTotal?: InputMaybe<Order_By>;
  preTaxTotal?: InputMaybe<Order_By>;
  pricePerUnit?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  taxRate?: InputMaybe<Order_By>;
  taxTotal?: InputMaybe<Order_By>;
  useCount?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "genericInvoiceLineItem". All fields are combined with a logical 'AND'. */
export type GenericInvoiceLineItem_Bool_Exp = {
  _and?: InputMaybe<Array<GenericInvoiceLineItem_Bool_Exp>>;
  _not?: InputMaybe<GenericInvoiceLineItem_Bool_Exp>;
  _or?: InputMaybe<Array<GenericInvoiceLineItem_Bool_Exp>>;
  allowGratuity?: InputMaybe<Boolean_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  discountAmount?: InputMaybe<Float8_Comparison_Exp>;
  discountTotal?: InputMaybe<Float8_Comparison_Exp>;
  discountType?: InputMaybe<String_Comparison_Exp>;
  discounts?: InputMaybe<GenericInvoiceLineItemDiscount_Bool_Exp>;
  discounts_aggregate?: InputMaybe<GenericInvoiceLineItemDiscount_Aggregate_Bool_Exp>;
  giftCards?: InputMaybe<GiftCard_Bool_Exp>;
  giftCards_aggregate?: InputMaybe<GiftCard_Aggregate_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  invoice?: InputMaybe<Invoice_Bool_Exp>;
  invoiceId?: InputMaybe<Uuid_Comparison_Exp>;
  membership?: InputMaybe<Membership_Bool_Exp>;
  membershipId?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  package?: InputMaybe<Package_Bool_Exp>;
  packageId?: InputMaybe<Uuid_Comparison_Exp>;
  postTaxTotal?: InputMaybe<Float8_Comparison_Exp>;
  preTaxTotal?: InputMaybe<Float8_Comparison_Exp>;
  pricePerUnit?: InputMaybe<Int_Comparison_Exp>;
  promotion?: InputMaybe<Promotion_Bool_Exp>;
  promotionId?: InputMaybe<Uuid_Comparison_Exp>;
  provider?: InputMaybe<Provider_Bool_Exp>;
  providerId?: InputMaybe<Uuid_Comparison_Exp>;
  quantity?: InputMaybe<Int_Comparison_Exp>;
  receiverId?: InputMaybe<Uuid_Comparison_Exp>;
  service?: InputMaybe<Service_Bool_Exp>;
  serviceId?: InputMaybe<Uuid_Comparison_Exp>;
  taxRate?: InputMaybe<Float8_Comparison_Exp>;
  taxTotal?: InputMaybe<Float8_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  useCount?: InputMaybe<Int_Comparison_Exp>;
  workspace?: InputMaybe<Workspace_Bool_Exp>;
  workspaceId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "genericInvoiceLineItem" */
export enum GenericInvoiceLineItem_Constraint {
  /** unique or primary key constraint on columns "id" */
  GenericInvoiceLineItemPkey = 'genericInvoiceLineItem_pkey'
}

/** input type for incrementing numeric columns in table "genericInvoiceLineItem" */
export type GenericInvoiceLineItem_Inc_Input = {
  discountAmount?: InputMaybe<Scalars['float8']>;
  discountTotal?: InputMaybe<Scalars['float8']>;
  postTaxTotal?: InputMaybe<Scalars['float8']>;
  preTaxTotal?: InputMaybe<Scalars['float8']>;
  pricePerUnit?: InputMaybe<Scalars['Int']>;
  quantity?: InputMaybe<Scalars['Int']>;
  taxRate?: InputMaybe<Scalars['float8']>;
  taxTotal?: InputMaybe<Scalars['float8']>;
  useCount?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "genericInvoiceLineItem" */
export type GenericInvoiceLineItem_Insert_Input = {
  allowGratuity?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  discountAmount?: InputMaybe<Scalars['float8']>;
  discountTotal?: InputMaybe<Scalars['float8']>;
  discountType?: InputMaybe<Scalars['String']>;
  discounts?: InputMaybe<GenericInvoiceLineItemDiscount_Arr_Rel_Insert_Input>;
  giftCards?: InputMaybe<GiftCard_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']>;
  invoice?: InputMaybe<Invoice_Obj_Rel_Insert_Input>;
  invoiceId?: InputMaybe<Scalars['uuid']>;
  membership?: InputMaybe<Membership_Obj_Rel_Insert_Input>;
  membershipId?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  package?: InputMaybe<Package_Obj_Rel_Insert_Input>;
  packageId?: InputMaybe<Scalars['uuid']>;
  postTaxTotal?: InputMaybe<Scalars['float8']>;
  preTaxTotal?: InputMaybe<Scalars['float8']>;
  pricePerUnit?: InputMaybe<Scalars['Int']>;
  promotion?: InputMaybe<Promotion_Obj_Rel_Insert_Input>;
  promotionId?: InputMaybe<Scalars['uuid']>;
  provider?: InputMaybe<Provider_Obj_Rel_Insert_Input>;
  providerId?: InputMaybe<Scalars['uuid']>;
  quantity?: InputMaybe<Scalars['Int']>;
  receiverId?: InputMaybe<Scalars['uuid']>;
  service?: InputMaybe<Service_Obj_Rel_Insert_Input>;
  serviceId?: InputMaybe<Scalars['uuid']>;
  taxRate?: InputMaybe<Scalars['float8']>;
  taxTotal?: InputMaybe<Scalars['float8']>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  useCount?: InputMaybe<Scalars['Int']>;
  workspace?: InputMaybe<Workspace_Obj_Rel_Insert_Input>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type GenericInvoiceLineItem_Max_Fields = {
  __typename?: 'genericInvoiceLineItem_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  discountAmount?: Maybe<Scalars['float8']>;
  discountTotal?: Maybe<Scalars['float8']>;
  discountType?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  invoiceId?: Maybe<Scalars['uuid']>;
  membershipId?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  packageId?: Maybe<Scalars['uuid']>;
  postTaxTotal?: Maybe<Scalars['float8']>;
  preTaxTotal?: Maybe<Scalars['float8']>;
  pricePerUnit?: Maybe<Scalars['Int']>;
  promotionId?: Maybe<Scalars['uuid']>;
  providerId?: Maybe<Scalars['uuid']>;
  quantity?: Maybe<Scalars['Int']>;
  receiverId?: Maybe<Scalars['uuid']>;
  serviceId?: Maybe<Scalars['uuid']>;
  taxRate?: Maybe<Scalars['float8']>;
  taxTotal?: Maybe<Scalars['float8']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  useCount?: Maybe<Scalars['Int']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "genericInvoiceLineItem" */
export type GenericInvoiceLineItem_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  discountAmount?: InputMaybe<Order_By>;
  discountTotal?: InputMaybe<Order_By>;
  discountType?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoiceId?: InputMaybe<Order_By>;
  membershipId?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  packageId?: InputMaybe<Order_By>;
  postTaxTotal?: InputMaybe<Order_By>;
  preTaxTotal?: InputMaybe<Order_By>;
  pricePerUnit?: InputMaybe<Order_By>;
  promotionId?: InputMaybe<Order_By>;
  providerId?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  receiverId?: InputMaybe<Order_By>;
  serviceId?: InputMaybe<Order_By>;
  taxRate?: InputMaybe<Order_By>;
  taxTotal?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  useCount?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type GenericInvoiceLineItem_Min_Fields = {
  __typename?: 'genericInvoiceLineItem_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  discountAmount?: Maybe<Scalars['float8']>;
  discountTotal?: Maybe<Scalars['float8']>;
  discountType?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  invoiceId?: Maybe<Scalars['uuid']>;
  membershipId?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  packageId?: Maybe<Scalars['uuid']>;
  postTaxTotal?: Maybe<Scalars['float8']>;
  preTaxTotal?: Maybe<Scalars['float8']>;
  pricePerUnit?: Maybe<Scalars['Int']>;
  promotionId?: Maybe<Scalars['uuid']>;
  providerId?: Maybe<Scalars['uuid']>;
  quantity?: Maybe<Scalars['Int']>;
  receiverId?: Maybe<Scalars['uuid']>;
  serviceId?: Maybe<Scalars['uuid']>;
  taxRate?: Maybe<Scalars['float8']>;
  taxTotal?: Maybe<Scalars['float8']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  useCount?: Maybe<Scalars['Int']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "genericInvoiceLineItem" */
export type GenericInvoiceLineItem_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  discountAmount?: InputMaybe<Order_By>;
  discountTotal?: InputMaybe<Order_By>;
  discountType?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoiceId?: InputMaybe<Order_By>;
  membershipId?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  packageId?: InputMaybe<Order_By>;
  postTaxTotal?: InputMaybe<Order_By>;
  preTaxTotal?: InputMaybe<Order_By>;
  pricePerUnit?: InputMaybe<Order_By>;
  promotionId?: InputMaybe<Order_By>;
  providerId?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  receiverId?: InputMaybe<Order_By>;
  serviceId?: InputMaybe<Order_By>;
  taxRate?: InputMaybe<Order_By>;
  taxTotal?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  useCount?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "genericInvoiceLineItem" */
export type GenericInvoiceLineItem_Mutation_Response = {
  __typename?: 'genericInvoiceLineItem_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<GenericInvoiceLineItem>;
};

/** on_conflict condition type for table "genericInvoiceLineItem" */
export type GenericInvoiceLineItem_On_Conflict = {
  constraint: GenericInvoiceLineItem_Constraint;
  update_columns?: Array<GenericInvoiceLineItem_Update_Column>;
  where?: InputMaybe<GenericInvoiceLineItem_Bool_Exp>;
};

/** Ordering options when selecting data from "genericInvoiceLineItem". */
export type GenericInvoiceLineItem_Order_By = {
  allowGratuity?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  discountAmount?: InputMaybe<Order_By>;
  discountTotal?: InputMaybe<Order_By>;
  discountType?: InputMaybe<Order_By>;
  discounts_aggregate?: InputMaybe<GenericInvoiceLineItemDiscount_Aggregate_Order_By>;
  giftCards_aggregate?: InputMaybe<GiftCard_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  invoice?: InputMaybe<Invoice_Order_By>;
  invoiceId?: InputMaybe<Order_By>;
  membership?: InputMaybe<Membership_Order_By>;
  membershipId?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  package?: InputMaybe<Package_Order_By>;
  packageId?: InputMaybe<Order_By>;
  postTaxTotal?: InputMaybe<Order_By>;
  preTaxTotal?: InputMaybe<Order_By>;
  pricePerUnit?: InputMaybe<Order_By>;
  promotion?: InputMaybe<Promotion_Order_By>;
  promotionId?: InputMaybe<Order_By>;
  provider?: InputMaybe<Provider_Order_By>;
  providerId?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  receiverId?: InputMaybe<Order_By>;
  service?: InputMaybe<Service_Order_By>;
  serviceId?: InputMaybe<Order_By>;
  taxRate?: InputMaybe<Order_By>;
  taxTotal?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  useCount?: InputMaybe<Order_By>;
  workspace?: InputMaybe<Workspace_Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: genericInvoiceLineItem */
export type GenericInvoiceLineItem_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "genericInvoiceLineItem" */
export enum GenericInvoiceLineItem_Select_Column {
  /** column name */
  AllowGratuity = 'allowGratuity',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DiscountAmount = 'discountAmount',
  /** column name */
  DiscountTotal = 'discountTotal',
  /** column name */
  DiscountType = 'discountType',
  /** column name */
  Id = 'id',
  /** column name */
  InvoiceId = 'invoiceId',
  /** column name */
  MembershipId = 'membershipId',
  /** column name */
  Name = 'name',
  /** column name */
  PackageId = 'packageId',
  /** column name */
  PostTaxTotal = 'postTaxTotal',
  /** column name */
  PreTaxTotal = 'preTaxTotal',
  /** column name */
  PricePerUnit = 'pricePerUnit',
  /** column name */
  PromotionId = 'promotionId',
  /** column name */
  ProviderId = 'providerId',
  /** column name */
  Quantity = 'quantity',
  /** column name */
  ReceiverId = 'receiverId',
  /** column name */
  ServiceId = 'serviceId',
  /** column name */
  TaxRate = 'taxRate',
  /** column name */
  TaxTotal = 'taxTotal',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UseCount = 'useCount',
  /** column name */
  WorkspaceId = 'workspaceId'
}

/** select "genericInvoiceLineItem_aggregate_bool_exp_avg_arguments_columns" columns of table "genericInvoiceLineItem" */
export enum GenericInvoiceLineItem_Select_Column_GenericInvoiceLineItem_Aggregate_Bool_Exp_Avg_Arguments_Columns {
  /** column name */
  DiscountAmount = 'discountAmount',
  /** column name */
  DiscountTotal = 'discountTotal',
  /** column name */
  PostTaxTotal = 'postTaxTotal',
  /** column name */
  PreTaxTotal = 'preTaxTotal',
  /** column name */
  TaxRate = 'taxRate',
  /** column name */
  TaxTotal = 'taxTotal'
}

/** select "genericInvoiceLineItem_aggregate_bool_exp_bool_and_arguments_columns" columns of table "genericInvoiceLineItem" */
export enum GenericInvoiceLineItem_Select_Column_GenericInvoiceLineItem_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  AllowGratuity = 'allowGratuity'
}

/** select "genericInvoiceLineItem_aggregate_bool_exp_bool_or_arguments_columns" columns of table "genericInvoiceLineItem" */
export enum GenericInvoiceLineItem_Select_Column_GenericInvoiceLineItem_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  AllowGratuity = 'allowGratuity'
}

/** select "genericInvoiceLineItem_aggregate_bool_exp_corr_arguments_columns" columns of table "genericInvoiceLineItem" */
export enum GenericInvoiceLineItem_Select_Column_GenericInvoiceLineItem_Aggregate_Bool_Exp_Corr_Arguments_Columns {
  /** column name */
  DiscountAmount = 'discountAmount',
  /** column name */
  DiscountTotal = 'discountTotal',
  /** column name */
  PostTaxTotal = 'postTaxTotal',
  /** column name */
  PreTaxTotal = 'preTaxTotal',
  /** column name */
  TaxRate = 'taxRate',
  /** column name */
  TaxTotal = 'taxTotal'
}

/** select "genericInvoiceLineItem_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "genericInvoiceLineItem" */
export enum GenericInvoiceLineItem_Select_Column_GenericInvoiceLineItem_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns {
  /** column name */
  DiscountAmount = 'discountAmount',
  /** column name */
  DiscountTotal = 'discountTotal',
  /** column name */
  PostTaxTotal = 'postTaxTotal',
  /** column name */
  PreTaxTotal = 'preTaxTotal',
  /** column name */
  TaxRate = 'taxRate',
  /** column name */
  TaxTotal = 'taxTotal'
}

/** select "genericInvoiceLineItem_aggregate_bool_exp_max_arguments_columns" columns of table "genericInvoiceLineItem" */
export enum GenericInvoiceLineItem_Select_Column_GenericInvoiceLineItem_Aggregate_Bool_Exp_Max_Arguments_Columns {
  /** column name */
  DiscountAmount = 'discountAmount',
  /** column name */
  DiscountTotal = 'discountTotal',
  /** column name */
  PostTaxTotal = 'postTaxTotal',
  /** column name */
  PreTaxTotal = 'preTaxTotal',
  /** column name */
  TaxRate = 'taxRate',
  /** column name */
  TaxTotal = 'taxTotal'
}

/** select "genericInvoiceLineItem_aggregate_bool_exp_min_arguments_columns" columns of table "genericInvoiceLineItem" */
export enum GenericInvoiceLineItem_Select_Column_GenericInvoiceLineItem_Aggregate_Bool_Exp_Min_Arguments_Columns {
  /** column name */
  DiscountAmount = 'discountAmount',
  /** column name */
  DiscountTotal = 'discountTotal',
  /** column name */
  PostTaxTotal = 'postTaxTotal',
  /** column name */
  PreTaxTotal = 'preTaxTotal',
  /** column name */
  TaxRate = 'taxRate',
  /** column name */
  TaxTotal = 'taxTotal'
}

/** select "genericInvoiceLineItem_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "genericInvoiceLineItem" */
export enum GenericInvoiceLineItem_Select_Column_GenericInvoiceLineItem_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns {
  /** column name */
  DiscountAmount = 'discountAmount',
  /** column name */
  DiscountTotal = 'discountTotal',
  /** column name */
  PostTaxTotal = 'postTaxTotal',
  /** column name */
  PreTaxTotal = 'preTaxTotal',
  /** column name */
  TaxRate = 'taxRate',
  /** column name */
  TaxTotal = 'taxTotal'
}

/** select "genericInvoiceLineItem_aggregate_bool_exp_sum_arguments_columns" columns of table "genericInvoiceLineItem" */
export enum GenericInvoiceLineItem_Select_Column_GenericInvoiceLineItem_Aggregate_Bool_Exp_Sum_Arguments_Columns {
  /** column name */
  DiscountAmount = 'discountAmount',
  /** column name */
  DiscountTotal = 'discountTotal',
  /** column name */
  PostTaxTotal = 'postTaxTotal',
  /** column name */
  PreTaxTotal = 'preTaxTotal',
  /** column name */
  TaxRate = 'taxRate',
  /** column name */
  TaxTotal = 'taxTotal'
}

/** select "genericInvoiceLineItem_aggregate_bool_exp_var_samp_arguments_columns" columns of table "genericInvoiceLineItem" */
export enum GenericInvoiceLineItem_Select_Column_GenericInvoiceLineItem_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns {
  /** column name */
  DiscountAmount = 'discountAmount',
  /** column name */
  DiscountTotal = 'discountTotal',
  /** column name */
  PostTaxTotal = 'postTaxTotal',
  /** column name */
  PreTaxTotal = 'preTaxTotal',
  /** column name */
  TaxRate = 'taxRate',
  /** column name */
  TaxTotal = 'taxTotal'
}

/** input type for updating data in table "genericInvoiceLineItem" */
export type GenericInvoiceLineItem_Set_Input = {
  allowGratuity?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  discountAmount?: InputMaybe<Scalars['float8']>;
  discountTotal?: InputMaybe<Scalars['float8']>;
  discountType?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  invoiceId?: InputMaybe<Scalars['uuid']>;
  membershipId?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  packageId?: InputMaybe<Scalars['uuid']>;
  postTaxTotal?: InputMaybe<Scalars['float8']>;
  preTaxTotal?: InputMaybe<Scalars['float8']>;
  pricePerUnit?: InputMaybe<Scalars['Int']>;
  promotionId?: InputMaybe<Scalars['uuid']>;
  providerId?: InputMaybe<Scalars['uuid']>;
  quantity?: InputMaybe<Scalars['Int']>;
  receiverId?: InputMaybe<Scalars['uuid']>;
  serviceId?: InputMaybe<Scalars['uuid']>;
  taxRate?: InputMaybe<Scalars['float8']>;
  taxTotal?: InputMaybe<Scalars['float8']>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  useCount?: InputMaybe<Scalars['Int']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type GenericInvoiceLineItem_Stddev_Fields = {
  __typename?: 'genericInvoiceLineItem_stddev_fields';
  discountAmount?: Maybe<Scalars['Float']>;
  discountTotal?: Maybe<Scalars['Float']>;
  postTaxTotal?: Maybe<Scalars['Float']>;
  preTaxTotal?: Maybe<Scalars['Float']>;
  pricePerUnit?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxTotal?: Maybe<Scalars['Float']>;
  useCount?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "genericInvoiceLineItem" */
export type GenericInvoiceLineItem_Stddev_Order_By = {
  discountAmount?: InputMaybe<Order_By>;
  discountTotal?: InputMaybe<Order_By>;
  postTaxTotal?: InputMaybe<Order_By>;
  preTaxTotal?: InputMaybe<Order_By>;
  pricePerUnit?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  taxRate?: InputMaybe<Order_By>;
  taxTotal?: InputMaybe<Order_By>;
  useCount?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type GenericInvoiceLineItem_Stddev_Pop_Fields = {
  __typename?: 'genericInvoiceLineItem_stddev_pop_fields';
  discountAmount?: Maybe<Scalars['Float']>;
  discountTotal?: Maybe<Scalars['Float']>;
  postTaxTotal?: Maybe<Scalars['Float']>;
  preTaxTotal?: Maybe<Scalars['Float']>;
  pricePerUnit?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxTotal?: Maybe<Scalars['Float']>;
  useCount?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "genericInvoiceLineItem" */
export type GenericInvoiceLineItem_Stddev_Pop_Order_By = {
  discountAmount?: InputMaybe<Order_By>;
  discountTotal?: InputMaybe<Order_By>;
  postTaxTotal?: InputMaybe<Order_By>;
  preTaxTotal?: InputMaybe<Order_By>;
  pricePerUnit?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  taxRate?: InputMaybe<Order_By>;
  taxTotal?: InputMaybe<Order_By>;
  useCount?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type GenericInvoiceLineItem_Stddev_Samp_Fields = {
  __typename?: 'genericInvoiceLineItem_stddev_samp_fields';
  discountAmount?: Maybe<Scalars['Float']>;
  discountTotal?: Maybe<Scalars['Float']>;
  postTaxTotal?: Maybe<Scalars['Float']>;
  preTaxTotal?: Maybe<Scalars['Float']>;
  pricePerUnit?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxTotal?: Maybe<Scalars['Float']>;
  useCount?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "genericInvoiceLineItem" */
export type GenericInvoiceLineItem_Stddev_Samp_Order_By = {
  discountAmount?: InputMaybe<Order_By>;
  discountTotal?: InputMaybe<Order_By>;
  postTaxTotal?: InputMaybe<Order_By>;
  preTaxTotal?: InputMaybe<Order_By>;
  pricePerUnit?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  taxRate?: InputMaybe<Order_By>;
  taxTotal?: InputMaybe<Order_By>;
  useCount?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "genericInvoiceLineItem" */
export type GenericInvoiceLineItem_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: GenericInvoiceLineItem_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type GenericInvoiceLineItem_Stream_Cursor_Value_Input = {
  allowGratuity?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  discountAmount?: InputMaybe<Scalars['float8']>;
  discountTotal?: InputMaybe<Scalars['float8']>;
  discountType?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  invoiceId?: InputMaybe<Scalars['uuid']>;
  membershipId?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  packageId?: InputMaybe<Scalars['uuid']>;
  postTaxTotal?: InputMaybe<Scalars['float8']>;
  preTaxTotal?: InputMaybe<Scalars['float8']>;
  pricePerUnit?: InputMaybe<Scalars['Int']>;
  promotionId?: InputMaybe<Scalars['uuid']>;
  providerId?: InputMaybe<Scalars['uuid']>;
  quantity?: InputMaybe<Scalars['Int']>;
  receiverId?: InputMaybe<Scalars['uuid']>;
  serviceId?: InputMaybe<Scalars['uuid']>;
  taxRate?: InputMaybe<Scalars['float8']>;
  taxTotal?: InputMaybe<Scalars['float8']>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  useCount?: InputMaybe<Scalars['Int']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type GenericInvoiceLineItem_Sum_Fields = {
  __typename?: 'genericInvoiceLineItem_sum_fields';
  discountAmount?: Maybe<Scalars['float8']>;
  discountTotal?: Maybe<Scalars['float8']>;
  postTaxTotal?: Maybe<Scalars['float8']>;
  preTaxTotal?: Maybe<Scalars['float8']>;
  pricePerUnit?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
  taxRate?: Maybe<Scalars['float8']>;
  taxTotal?: Maybe<Scalars['float8']>;
  useCount?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "genericInvoiceLineItem" */
export type GenericInvoiceLineItem_Sum_Order_By = {
  discountAmount?: InputMaybe<Order_By>;
  discountTotal?: InputMaybe<Order_By>;
  postTaxTotal?: InputMaybe<Order_By>;
  preTaxTotal?: InputMaybe<Order_By>;
  pricePerUnit?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  taxRate?: InputMaybe<Order_By>;
  taxTotal?: InputMaybe<Order_By>;
  useCount?: InputMaybe<Order_By>;
};

/** update columns of table "genericInvoiceLineItem" */
export enum GenericInvoiceLineItem_Update_Column {
  /** column name */
  AllowGratuity = 'allowGratuity',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DiscountAmount = 'discountAmount',
  /** column name */
  DiscountTotal = 'discountTotal',
  /** column name */
  DiscountType = 'discountType',
  /** column name */
  Id = 'id',
  /** column name */
  InvoiceId = 'invoiceId',
  /** column name */
  MembershipId = 'membershipId',
  /** column name */
  Name = 'name',
  /** column name */
  PackageId = 'packageId',
  /** column name */
  PostTaxTotal = 'postTaxTotal',
  /** column name */
  PreTaxTotal = 'preTaxTotal',
  /** column name */
  PricePerUnit = 'pricePerUnit',
  /** column name */
  PromotionId = 'promotionId',
  /** column name */
  ProviderId = 'providerId',
  /** column name */
  Quantity = 'quantity',
  /** column name */
  ReceiverId = 'receiverId',
  /** column name */
  ServiceId = 'serviceId',
  /** column name */
  TaxRate = 'taxRate',
  /** column name */
  TaxTotal = 'taxTotal',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UseCount = 'useCount',
  /** column name */
  WorkspaceId = 'workspaceId'
}

export type GenericInvoiceLineItem_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<GenericInvoiceLineItem_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<GenericInvoiceLineItem_Set_Input>;
  /** filter the rows which have to be updated */
  where: GenericInvoiceLineItem_Bool_Exp;
};

/** aggregate var_pop on columns */
export type GenericInvoiceLineItem_Var_Pop_Fields = {
  __typename?: 'genericInvoiceLineItem_var_pop_fields';
  discountAmount?: Maybe<Scalars['Float']>;
  discountTotal?: Maybe<Scalars['Float']>;
  postTaxTotal?: Maybe<Scalars['Float']>;
  preTaxTotal?: Maybe<Scalars['Float']>;
  pricePerUnit?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxTotal?: Maybe<Scalars['Float']>;
  useCount?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "genericInvoiceLineItem" */
export type GenericInvoiceLineItem_Var_Pop_Order_By = {
  discountAmount?: InputMaybe<Order_By>;
  discountTotal?: InputMaybe<Order_By>;
  postTaxTotal?: InputMaybe<Order_By>;
  preTaxTotal?: InputMaybe<Order_By>;
  pricePerUnit?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  taxRate?: InputMaybe<Order_By>;
  taxTotal?: InputMaybe<Order_By>;
  useCount?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type GenericInvoiceLineItem_Var_Samp_Fields = {
  __typename?: 'genericInvoiceLineItem_var_samp_fields';
  discountAmount?: Maybe<Scalars['Float']>;
  discountTotal?: Maybe<Scalars['Float']>;
  postTaxTotal?: Maybe<Scalars['Float']>;
  preTaxTotal?: Maybe<Scalars['Float']>;
  pricePerUnit?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxTotal?: Maybe<Scalars['Float']>;
  useCount?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "genericInvoiceLineItem" */
export type GenericInvoiceLineItem_Var_Samp_Order_By = {
  discountAmount?: InputMaybe<Order_By>;
  discountTotal?: InputMaybe<Order_By>;
  postTaxTotal?: InputMaybe<Order_By>;
  preTaxTotal?: InputMaybe<Order_By>;
  pricePerUnit?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  taxRate?: InputMaybe<Order_By>;
  taxTotal?: InputMaybe<Order_By>;
  useCount?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type GenericInvoiceLineItem_Variance_Fields = {
  __typename?: 'genericInvoiceLineItem_variance_fields';
  discountAmount?: Maybe<Scalars['Float']>;
  discountTotal?: Maybe<Scalars['Float']>;
  postTaxTotal?: Maybe<Scalars['Float']>;
  preTaxTotal?: Maybe<Scalars['Float']>;
  pricePerUnit?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxTotal?: Maybe<Scalars['Float']>;
  useCount?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "genericInvoiceLineItem" */
export type GenericInvoiceLineItem_Variance_Order_By = {
  discountAmount?: InputMaybe<Order_By>;
  discountTotal?: InputMaybe<Order_By>;
  postTaxTotal?: InputMaybe<Order_By>;
  preTaxTotal?: InputMaybe<Order_By>;
  pricePerUnit?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  taxRate?: InputMaybe<Order_By>;
  taxTotal?: InputMaybe<Order_By>;
  useCount?: InputMaybe<Order_By>;
};

/** columns and relationships of "giftCard" */
export type GiftCard = {
  __typename?: 'giftCard';
  amount: Scalars['Int'];
  balance: Scalars['Int'];
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  invoiceId?: Maybe<Scalars['uuid']>;
  lineItemId?: Maybe<Scalars['uuid']>;
  paymentId?: Maybe<Scalars['uuid']>;
  purchaserId?: Maybe<Scalars['uuid']>;
  receiverId?: Maybe<Scalars['uuid']>;
  redeemedByPatientId?: Maybe<Scalars['uuid']>;
  redeemedByUserId?: Maybe<Scalars['uuid']>;
  redeemedOn?: Maybe<Scalars['timestamptz']>;
  redemptionCode: Scalars['String'];
  status?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updatedAt: Scalars['timestamptz'];
  workspaceId: Scalars['uuid'];
};

/** aggregated selection of "giftCard" */
export type GiftCard_Aggregate = {
  __typename?: 'giftCard_aggregate';
  aggregate?: Maybe<GiftCard_Aggregate_Fields>;
  nodes: Array<GiftCard>;
};

export type GiftCard_Aggregate_Bool_Exp = {
  count?: InputMaybe<GiftCard_Aggregate_Bool_Exp_Count>;
};

export type GiftCard_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<GiftCard_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<GiftCard_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "giftCard" */
export type GiftCard_Aggregate_Fields = {
  __typename?: 'giftCard_aggregate_fields';
  avg?: Maybe<GiftCard_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<GiftCard_Max_Fields>;
  min?: Maybe<GiftCard_Min_Fields>;
  stddev?: Maybe<GiftCard_Stddev_Fields>;
  stddev_pop?: Maybe<GiftCard_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<GiftCard_Stddev_Samp_Fields>;
  sum?: Maybe<GiftCard_Sum_Fields>;
  var_pop?: Maybe<GiftCard_Var_Pop_Fields>;
  var_samp?: Maybe<GiftCard_Var_Samp_Fields>;
  variance?: Maybe<GiftCard_Variance_Fields>;
};


/** aggregate fields of "giftCard" */
export type GiftCard_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<GiftCard_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "giftCard" */
export type GiftCard_Aggregate_Order_By = {
  avg?: InputMaybe<GiftCard_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<GiftCard_Max_Order_By>;
  min?: InputMaybe<GiftCard_Min_Order_By>;
  stddev?: InputMaybe<GiftCard_Stddev_Order_By>;
  stddev_pop?: InputMaybe<GiftCard_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<GiftCard_Stddev_Samp_Order_By>;
  sum?: InputMaybe<GiftCard_Sum_Order_By>;
  var_pop?: InputMaybe<GiftCard_Var_Pop_Order_By>;
  var_samp?: InputMaybe<GiftCard_Var_Samp_Order_By>;
  variance?: InputMaybe<GiftCard_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "giftCard" */
export type GiftCard_Arr_Rel_Insert_Input = {
  data: Array<GiftCard_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<GiftCard_On_Conflict>;
};

/** aggregate avg on columns */
export type GiftCard_Avg_Fields = {
  __typename?: 'giftCard_avg_fields';
  amount?: Maybe<Scalars['Float']>;
  balance?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "giftCard" */
export type GiftCard_Avg_Order_By = {
  amount?: InputMaybe<Order_By>;
  balance?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "giftCard". All fields are combined with a logical 'AND'. */
export type GiftCard_Bool_Exp = {
  _and?: InputMaybe<Array<GiftCard_Bool_Exp>>;
  _not?: InputMaybe<GiftCard_Bool_Exp>;
  _or?: InputMaybe<Array<GiftCard_Bool_Exp>>;
  amount?: InputMaybe<Int_Comparison_Exp>;
  balance?: InputMaybe<Int_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  invoiceId?: InputMaybe<Uuid_Comparison_Exp>;
  lineItemId?: InputMaybe<Uuid_Comparison_Exp>;
  paymentId?: InputMaybe<Uuid_Comparison_Exp>;
  purchaserId?: InputMaybe<Uuid_Comparison_Exp>;
  receiverId?: InputMaybe<Uuid_Comparison_Exp>;
  redeemedByPatientId?: InputMaybe<Uuid_Comparison_Exp>;
  redeemedByUserId?: InputMaybe<Uuid_Comparison_Exp>;
  redeemedOn?: InputMaybe<Timestamptz_Comparison_Exp>;
  redemptionCode?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  workspaceId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "giftCard" */
export enum GiftCard_Constraint {
  /** unique or primary key constraint on columns "id" */
  GiftCardPkey = 'giftCard_pkey',
  /** unique or primary key constraint on columns "redemptionCode", "workspaceId" */
  GiftCardRedemptionCodeWorkspaceIdKey = 'giftCard_redemptionCode_workspaceId_key'
}

/** input type for incrementing numeric columns in table "giftCard" */
export type GiftCard_Inc_Input = {
  amount?: InputMaybe<Scalars['Int']>;
  balance?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "giftCard" */
export type GiftCard_Insert_Input = {
  amount?: InputMaybe<Scalars['Int']>;
  balance?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  invoiceId?: InputMaybe<Scalars['uuid']>;
  lineItemId?: InputMaybe<Scalars['uuid']>;
  paymentId?: InputMaybe<Scalars['uuid']>;
  purchaserId?: InputMaybe<Scalars['uuid']>;
  receiverId?: InputMaybe<Scalars['uuid']>;
  redeemedByPatientId?: InputMaybe<Scalars['uuid']>;
  redeemedByUserId?: InputMaybe<Scalars['uuid']>;
  redeemedOn?: InputMaybe<Scalars['timestamptz']>;
  redemptionCode?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type GiftCard_Max_Fields = {
  __typename?: 'giftCard_max_fields';
  amount?: Maybe<Scalars['Int']>;
  balance?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  invoiceId?: Maybe<Scalars['uuid']>;
  lineItemId?: Maybe<Scalars['uuid']>;
  paymentId?: Maybe<Scalars['uuid']>;
  purchaserId?: Maybe<Scalars['uuid']>;
  receiverId?: Maybe<Scalars['uuid']>;
  redeemedByPatientId?: Maybe<Scalars['uuid']>;
  redeemedByUserId?: Maybe<Scalars['uuid']>;
  redeemedOn?: Maybe<Scalars['timestamptz']>;
  redemptionCode?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "giftCard" */
export type GiftCard_Max_Order_By = {
  amount?: InputMaybe<Order_By>;
  balance?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoiceId?: InputMaybe<Order_By>;
  lineItemId?: InputMaybe<Order_By>;
  paymentId?: InputMaybe<Order_By>;
  purchaserId?: InputMaybe<Order_By>;
  receiverId?: InputMaybe<Order_By>;
  redeemedByPatientId?: InputMaybe<Order_By>;
  redeemedByUserId?: InputMaybe<Order_By>;
  redeemedOn?: InputMaybe<Order_By>;
  redemptionCode?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type GiftCard_Min_Fields = {
  __typename?: 'giftCard_min_fields';
  amount?: Maybe<Scalars['Int']>;
  balance?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  invoiceId?: Maybe<Scalars['uuid']>;
  lineItemId?: Maybe<Scalars['uuid']>;
  paymentId?: Maybe<Scalars['uuid']>;
  purchaserId?: Maybe<Scalars['uuid']>;
  receiverId?: Maybe<Scalars['uuid']>;
  redeemedByPatientId?: Maybe<Scalars['uuid']>;
  redeemedByUserId?: Maybe<Scalars['uuid']>;
  redeemedOn?: Maybe<Scalars['timestamptz']>;
  redemptionCode?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "giftCard" */
export type GiftCard_Min_Order_By = {
  amount?: InputMaybe<Order_By>;
  balance?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoiceId?: InputMaybe<Order_By>;
  lineItemId?: InputMaybe<Order_By>;
  paymentId?: InputMaybe<Order_By>;
  purchaserId?: InputMaybe<Order_By>;
  receiverId?: InputMaybe<Order_By>;
  redeemedByPatientId?: InputMaybe<Order_By>;
  redeemedByUserId?: InputMaybe<Order_By>;
  redeemedOn?: InputMaybe<Order_By>;
  redemptionCode?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "giftCard" */
export type GiftCard_Mutation_Response = {
  __typename?: 'giftCard_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<GiftCard>;
};

/** on_conflict condition type for table "giftCard" */
export type GiftCard_On_Conflict = {
  constraint: GiftCard_Constraint;
  update_columns?: Array<GiftCard_Update_Column>;
  where?: InputMaybe<GiftCard_Bool_Exp>;
};

/** Ordering options when selecting data from "giftCard". */
export type GiftCard_Order_By = {
  amount?: InputMaybe<Order_By>;
  balance?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoiceId?: InputMaybe<Order_By>;
  lineItemId?: InputMaybe<Order_By>;
  paymentId?: InputMaybe<Order_By>;
  purchaserId?: InputMaybe<Order_By>;
  receiverId?: InputMaybe<Order_By>;
  redeemedByPatientId?: InputMaybe<Order_By>;
  redeemedByUserId?: InputMaybe<Order_By>;
  redeemedOn?: InputMaybe<Order_By>;
  redemptionCode?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: giftCard */
export type GiftCard_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "giftCard" */
export enum GiftCard_Select_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  Balance = 'balance',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  InvoiceId = 'invoiceId',
  /** column name */
  LineItemId = 'lineItemId',
  /** column name */
  PaymentId = 'paymentId',
  /** column name */
  PurchaserId = 'purchaserId',
  /** column name */
  ReceiverId = 'receiverId',
  /** column name */
  RedeemedByPatientId = 'redeemedByPatientId',
  /** column name */
  RedeemedByUserId = 'redeemedByUserId',
  /** column name */
  RedeemedOn = 'redeemedOn',
  /** column name */
  RedemptionCode = 'redemptionCode',
  /** column name */
  Status = 'status',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WorkspaceId = 'workspaceId'
}

/** input type for updating data in table "giftCard" */
export type GiftCard_Set_Input = {
  amount?: InputMaybe<Scalars['Int']>;
  balance?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  invoiceId?: InputMaybe<Scalars['uuid']>;
  lineItemId?: InputMaybe<Scalars['uuid']>;
  paymentId?: InputMaybe<Scalars['uuid']>;
  purchaserId?: InputMaybe<Scalars['uuid']>;
  receiverId?: InputMaybe<Scalars['uuid']>;
  redeemedByPatientId?: InputMaybe<Scalars['uuid']>;
  redeemedByUserId?: InputMaybe<Scalars['uuid']>;
  redeemedOn?: InputMaybe<Scalars['timestamptz']>;
  redemptionCode?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type GiftCard_Stddev_Fields = {
  __typename?: 'giftCard_stddev_fields';
  amount?: Maybe<Scalars['Float']>;
  balance?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "giftCard" */
export type GiftCard_Stddev_Order_By = {
  amount?: InputMaybe<Order_By>;
  balance?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type GiftCard_Stddev_Pop_Fields = {
  __typename?: 'giftCard_stddev_pop_fields';
  amount?: Maybe<Scalars['Float']>;
  balance?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "giftCard" */
export type GiftCard_Stddev_Pop_Order_By = {
  amount?: InputMaybe<Order_By>;
  balance?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type GiftCard_Stddev_Samp_Fields = {
  __typename?: 'giftCard_stddev_samp_fields';
  amount?: Maybe<Scalars['Float']>;
  balance?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "giftCard" */
export type GiftCard_Stddev_Samp_Order_By = {
  amount?: InputMaybe<Order_By>;
  balance?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "giftCard" */
export type GiftCard_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: GiftCard_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type GiftCard_Stream_Cursor_Value_Input = {
  amount?: InputMaybe<Scalars['Int']>;
  balance?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  invoiceId?: InputMaybe<Scalars['uuid']>;
  lineItemId?: InputMaybe<Scalars['uuid']>;
  paymentId?: InputMaybe<Scalars['uuid']>;
  purchaserId?: InputMaybe<Scalars['uuid']>;
  receiverId?: InputMaybe<Scalars['uuid']>;
  redeemedByPatientId?: InputMaybe<Scalars['uuid']>;
  redeemedByUserId?: InputMaybe<Scalars['uuid']>;
  redeemedOn?: InputMaybe<Scalars['timestamptz']>;
  redemptionCode?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type GiftCard_Sum_Fields = {
  __typename?: 'giftCard_sum_fields';
  amount?: Maybe<Scalars['Int']>;
  balance?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "giftCard" */
export type GiftCard_Sum_Order_By = {
  amount?: InputMaybe<Order_By>;
  balance?: InputMaybe<Order_By>;
};

/** update columns of table "giftCard" */
export enum GiftCard_Update_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  Balance = 'balance',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  InvoiceId = 'invoiceId',
  /** column name */
  LineItemId = 'lineItemId',
  /** column name */
  PaymentId = 'paymentId',
  /** column name */
  PurchaserId = 'purchaserId',
  /** column name */
  ReceiverId = 'receiverId',
  /** column name */
  RedeemedByPatientId = 'redeemedByPatientId',
  /** column name */
  RedeemedByUserId = 'redeemedByUserId',
  /** column name */
  RedeemedOn = 'redeemedOn',
  /** column name */
  RedemptionCode = 'redemptionCode',
  /** column name */
  Status = 'status',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WorkspaceId = 'workspaceId'
}

export type GiftCard_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<GiftCard_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<GiftCard_Set_Input>;
  /** filter the rows which have to be updated */
  where: GiftCard_Bool_Exp;
};

/** aggregate var_pop on columns */
export type GiftCard_Var_Pop_Fields = {
  __typename?: 'giftCard_var_pop_fields';
  amount?: Maybe<Scalars['Float']>;
  balance?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "giftCard" */
export type GiftCard_Var_Pop_Order_By = {
  amount?: InputMaybe<Order_By>;
  balance?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type GiftCard_Var_Samp_Fields = {
  __typename?: 'giftCard_var_samp_fields';
  amount?: Maybe<Scalars['Float']>;
  balance?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "giftCard" */
export type GiftCard_Var_Samp_Order_By = {
  amount?: InputMaybe<Order_By>;
  balance?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type GiftCard_Variance_Fields = {
  __typename?: 'giftCard_variance_fields';
  amount?: Maybe<Scalars['Float']>;
  balance?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "giftCard" */
export type GiftCard_Variance_Order_By = {
  amount?: InputMaybe<Order_By>;
  balance?: InputMaybe<Order_By>;
};

/** columns and relationships of "googleAccount" */
export type GoogleAccount = {
  __typename?: 'googleAccount';
  accountName?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['String']>;
  createdAt: Scalars['timestamptz'];
  /** An array relationship */
  googleLocations: Array<GoogleLocation>;
  /** An aggregate relationship */
  googleLocations_aggregate: GoogleLocation_Aggregate;
  id: Scalars['uuid'];
  name: Scalars['String'];
  permissionLevel?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  /** An object relationship */
  user: User;
  userId: Scalars['uuid'];
  verificationState: Scalars['String'];
  vettedState: Scalars['String'];
  /** An object relationship */
  workspace: Workspace;
  workspaceId: Scalars['uuid'];
};


/** columns and relationships of "googleAccount" */
export type GoogleAccountGoogleLocationsArgs = {
  distinct_on?: InputMaybe<Array<GoogleLocation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GoogleLocation_Order_By>>;
  where?: InputMaybe<GoogleLocation_Bool_Exp>;
};


/** columns and relationships of "googleAccount" */
export type GoogleAccountGoogleLocations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<GoogleLocation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GoogleLocation_Order_By>>;
  where?: InputMaybe<GoogleLocation_Bool_Exp>;
};

/** aggregated selection of "googleAccount" */
export type GoogleAccount_Aggregate = {
  __typename?: 'googleAccount_aggregate';
  aggregate?: Maybe<GoogleAccount_Aggregate_Fields>;
  nodes: Array<GoogleAccount>;
};

export type GoogleAccount_Aggregate_Bool_Exp = {
  count?: InputMaybe<GoogleAccount_Aggregate_Bool_Exp_Count>;
};

export type GoogleAccount_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<GoogleAccount_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<GoogleAccount_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "googleAccount" */
export type GoogleAccount_Aggregate_Fields = {
  __typename?: 'googleAccount_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<GoogleAccount_Max_Fields>;
  min?: Maybe<GoogleAccount_Min_Fields>;
};


/** aggregate fields of "googleAccount" */
export type GoogleAccount_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<GoogleAccount_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "googleAccount" */
export type GoogleAccount_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<GoogleAccount_Max_Order_By>;
  min?: InputMaybe<GoogleAccount_Min_Order_By>;
};

/** input type for inserting array relation for remote table "googleAccount" */
export type GoogleAccount_Arr_Rel_Insert_Input = {
  data: Array<GoogleAccount_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<GoogleAccount_On_Conflict>;
};

/** Boolean expression to filter rows from the table "googleAccount". All fields are combined with a logical 'AND'. */
export type GoogleAccount_Bool_Exp = {
  _and?: InputMaybe<Array<GoogleAccount_Bool_Exp>>;
  _not?: InputMaybe<GoogleAccount_Bool_Exp>;
  _or?: InputMaybe<Array<GoogleAccount_Bool_Exp>>;
  accountName?: InputMaybe<String_Comparison_Exp>;
  accountNumber?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  googleLocations?: InputMaybe<GoogleLocation_Bool_Exp>;
  googleLocations_aggregate?: InputMaybe<GoogleLocation_Aggregate_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  permissionLevel?: InputMaybe<String_Comparison_Exp>;
  role?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<User_Bool_Exp>;
  userId?: InputMaybe<Uuid_Comparison_Exp>;
  verificationState?: InputMaybe<String_Comparison_Exp>;
  vettedState?: InputMaybe<String_Comparison_Exp>;
  workspace?: InputMaybe<Workspace_Bool_Exp>;
  workspaceId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "googleAccount" */
export enum GoogleAccount_Constraint {
  /** unique or primary key constraint on columns "accountName", "workspaceId" */
  GoogleAccountAccountNameWorkspaceIdKey = 'googleAccount_accountName_workspaceId_key',
  /** unique or primary key constraint on columns "id" */
  GoogleAccountPkey = 'googleAccount_pkey'
}

/** input type for inserting data into table "googleAccount" */
export type GoogleAccount_Insert_Input = {
  accountName?: InputMaybe<Scalars['String']>;
  accountNumber?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  googleLocations?: InputMaybe<GoogleLocation_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  permissionLevel?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<User_Obj_Rel_Insert_Input>;
  userId?: InputMaybe<Scalars['uuid']>;
  verificationState?: InputMaybe<Scalars['String']>;
  vettedState?: InputMaybe<Scalars['String']>;
  workspace?: InputMaybe<Workspace_Obj_Rel_Insert_Input>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type GoogleAccount_Max_Fields = {
  __typename?: 'googleAccount_max_fields';
  accountName?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  permissionLevel?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['uuid']>;
  verificationState?: Maybe<Scalars['String']>;
  vettedState?: Maybe<Scalars['String']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "googleAccount" */
export type GoogleAccount_Max_Order_By = {
  accountName?: InputMaybe<Order_By>;
  accountNumber?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  permissionLevel?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
  verificationState?: InputMaybe<Order_By>;
  vettedState?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type GoogleAccount_Min_Fields = {
  __typename?: 'googleAccount_min_fields';
  accountName?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  permissionLevel?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['uuid']>;
  verificationState?: Maybe<Scalars['String']>;
  vettedState?: Maybe<Scalars['String']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "googleAccount" */
export type GoogleAccount_Min_Order_By = {
  accountName?: InputMaybe<Order_By>;
  accountNumber?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  permissionLevel?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
  verificationState?: InputMaybe<Order_By>;
  vettedState?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "googleAccount" */
export type GoogleAccount_Mutation_Response = {
  __typename?: 'googleAccount_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<GoogleAccount>;
};

/** input type for inserting object relation for remote table "googleAccount" */
export type GoogleAccount_Obj_Rel_Insert_Input = {
  data: GoogleAccount_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<GoogleAccount_On_Conflict>;
};

/** on_conflict condition type for table "googleAccount" */
export type GoogleAccount_On_Conflict = {
  constraint: GoogleAccount_Constraint;
  update_columns?: Array<GoogleAccount_Update_Column>;
  where?: InputMaybe<GoogleAccount_Bool_Exp>;
};

/** Ordering options when selecting data from "googleAccount". */
export type GoogleAccount_Order_By = {
  accountName?: InputMaybe<Order_By>;
  accountNumber?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  googleLocations_aggregate?: InputMaybe<GoogleLocation_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  permissionLevel?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  user?: InputMaybe<User_Order_By>;
  userId?: InputMaybe<Order_By>;
  verificationState?: InputMaybe<Order_By>;
  vettedState?: InputMaybe<Order_By>;
  workspace?: InputMaybe<Workspace_Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: googleAccount */
export type GoogleAccount_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "googleAccount" */
export enum GoogleAccount_Select_Column {
  /** column name */
  AccountName = 'accountName',
  /** column name */
  AccountNumber = 'accountNumber',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  PermissionLevel = 'permissionLevel',
  /** column name */
  Role = 'role',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId',
  /** column name */
  VerificationState = 'verificationState',
  /** column name */
  VettedState = 'vettedState',
  /** column name */
  WorkspaceId = 'workspaceId'
}

/** input type for updating data in table "googleAccount" */
export type GoogleAccount_Set_Input = {
  accountName?: InputMaybe<Scalars['String']>;
  accountNumber?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  permissionLevel?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  userId?: InputMaybe<Scalars['uuid']>;
  verificationState?: InputMaybe<Scalars['String']>;
  vettedState?: InputMaybe<Scalars['String']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "googleAccount" */
export type GoogleAccount_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: GoogleAccount_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type GoogleAccount_Stream_Cursor_Value_Input = {
  accountName?: InputMaybe<Scalars['String']>;
  accountNumber?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  permissionLevel?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  userId?: InputMaybe<Scalars['uuid']>;
  verificationState?: InputMaybe<Scalars['String']>;
  vettedState?: InputMaybe<Scalars['String']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "googleAccount" */
export enum GoogleAccount_Update_Column {
  /** column name */
  AccountName = 'accountName',
  /** column name */
  AccountNumber = 'accountNumber',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  PermissionLevel = 'permissionLevel',
  /** column name */
  Role = 'role',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId',
  /** column name */
  VerificationState = 'verificationState',
  /** column name */
  VettedState = 'vettedState',
  /** column name */
  WorkspaceId = 'workspaceId'
}

export type GoogleAccount_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<GoogleAccount_Set_Input>;
  /** filter the rows which have to be updated */
  where: GoogleAccount_Bool_Exp;
};

/** columns and relationships of "googleLocation" */
export type GoogleLocation = {
  __typename?: 'googleLocation';
  addressLines: Scalars['String'];
  administrativeArea?: Maybe<Scalars['String']>;
  canOperateLocalPost: Scalars['Boolean'];
  createdAt: Scalars['timestamptz'];
  /** An object relationship */
  googleAccount: GoogleAccount;
  googleAccountId: Scalars['uuid'];
  id: Scalars['uuid'];
  locality?: Maybe<Scalars['String']>;
  mapsUri: Scalars['String'];
  name: Scalars['String'];
  newReviewUri: Scalars['String'];
  placeId: Scalars['String'];
  postalCode?: Maybe<Scalars['String']>;
  regionCode: Scalars['String'];
  storeCode?: Maybe<Scalars['String']>;
  sublocality?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  websiteUri?: Maybe<Scalars['String']>;
  /** An object relationship */
  workspace: Workspace;
  workspaceId: Scalars['uuid'];
};

/** aggregated selection of "googleLocation" */
export type GoogleLocation_Aggregate = {
  __typename?: 'googleLocation_aggregate';
  aggregate?: Maybe<GoogleLocation_Aggregate_Fields>;
  nodes: Array<GoogleLocation>;
};

export type GoogleLocation_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<GoogleLocation_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<GoogleLocation_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<GoogleLocation_Aggregate_Bool_Exp_Count>;
};

export type GoogleLocation_Aggregate_Bool_Exp_Bool_And = {
  arguments: GoogleLocation_Select_Column_GoogleLocation_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<GoogleLocation_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type GoogleLocation_Aggregate_Bool_Exp_Bool_Or = {
  arguments: GoogleLocation_Select_Column_GoogleLocation_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<GoogleLocation_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type GoogleLocation_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<GoogleLocation_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<GoogleLocation_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "googleLocation" */
export type GoogleLocation_Aggregate_Fields = {
  __typename?: 'googleLocation_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<GoogleLocation_Max_Fields>;
  min?: Maybe<GoogleLocation_Min_Fields>;
};


/** aggregate fields of "googleLocation" */
export type GoogleLocation_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<GoogleLocation_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "googleLocation" */
export type GoogleLocation_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<GoogleLocation_Max_Order_By>;
  min?: InputMaybe<GoogleLocation_Min_Order_By>;
};

/** input type for inserting array relation for remote table "googleLocation" */
export type GoogleLocation_Arr_Rel_Insert_Input = {
  data: Array<GoogleLocation_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<GoogleLocation_On_Conflict>;
};

/** Boolean expression to filter rows from the table "googleLocation". All fields are combined with a logical 'AND'. */
export type GoogleLocation_Bool_Exp = {
  _and?: InputMaybe<Array<GoogleLocation_Bool_Exp>>;
  _not?: InputMaybe<GoogleLocation_Bool_Exp>;
  _or?: InputMaybe<Array<GoogleLocation_Bool_Exp>>;
  addressLines?: InputMaybe<String_Comparison_Exp>;
  administrativeArea?: InputMaybe<String_Comparison_Exp>;
  canOperateLocalPost?: InputMaybe<Boolean_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  googleAccount?: InputMaybe<GoogleAccount_Bool_Exp>;
  googleAccountId?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  locality?: InputMaybe<String_Comparison_Exp>;
  mapsUri?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  newReviewUri?: InputMaybe<String_Comparison_Exp>;
  placeId?: InputMaybe<String_Comparison_Exp>;
  postalCode?: InputMaybe<String_Comparison_Exp>;
  regionCode?: InputMaybe<String_Comparison_Exp>;
  storeCode?: InputMaybe<String_Comparison_Exp>;
  sublocality?: InputMaybe<String_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  websiteUri?: InputMaybe<String_Comparison_Exp>;
  workspace?: InputMaybe<Workspace_Bool_Exp>;
  workspaceId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "googleLocation" */
export enum GoogleLocation_Constraint {
  /** unique or primary key constraint on columns "workspaceId", "name" */
  GoogleLocationNameWorkspaceIdKey = 'googleLocation_name_workspaceId_key',
  /** unique or primary key constraint on columns "id" */
  GoogleLocationPkey = 'googleLocation_pkey'
}

/** input type for inserting data into table "googleLocation" */
export type GoogleLocation_Insert_Input = {
  addressLines?: InputMaybe<Scalars['String']>;
  administrativeArea?: InputMaybe<Scalars['String']>;
  canOperateLocalPost?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  googleAccount?: InputMaybe<GoogleAccount_Obj_Rel_Insert_Input>;
  googleAccountId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  locality?: InputMaybe<Scalars['String']>;
  mapsUri?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  newReviewUri?: InputMaybe<Scalars['String']>;
  placeId?: InputMaybe<Scalars['String']>;
  postalCode?: InputMaybe<Scalars['String']>;
  regionCode?: InputMaybe<Scalars['String']>;
  storeCode?: InputMaybe<Scalars['String']>;
  sublocality?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  websiteUri?: InputMaybe<Scalars['String']>;
  workspace?: InputMaybe<Workspace_Obj_Rel_Insert_Input>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type GoogleLocation_Max_Fields = {
  __typename?: 'googleLocation_max_fields';
  addressLines?: Maybe<Scalars['String']>;
  administrativeArea?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  googleAccountId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  locality?: Maybe<Scalars['String']>;
  mapsUri?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  newReviewUri?: Maybe<Scalars['String']>;
  placeId?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  regionCode?: Maybe<Scalars['String']>;
  storeCode?: Maybe<Scalars['String']>;
  sublocality?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  websiteUri?: Maybe<Scalars['String']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "googleLocation" */
export type GoogleLocation_Max_Order_By = {
  addressLines?: InputMaybe<Order_By>;
  administrativeArea?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  googleAccountId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  locality?: InputMaybe<Order_By>;
  mapsUri?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  newReviewUri?: InputMaybe<Order_By>;
  placeId?: InputMaybe<Order_By>;
  postalCode?: InputMaybe<Order_By>;
  regionCode?: InputMaybe<Order_By>;
  storeCode?: InputMaybe<Order_By>;
  sublocality?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  websiteUri?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type GoogleLocation_Min_Fields = {
  __typename?: 'googleLocation_min_fields';
  addressLines?: Maybe<Scalars['String']>;
  administrativeArea?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  googleAccountId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  locality?: Maybe<Scalars['String']>;
  mapsUri?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  newReviewUri?: Maybe<Scalars['String']>;
  placeId?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  regionCode?: Maybe<Scalars['String']>;
  storeCode?: Maybe<Scalars['String']>;
  sublocality?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  websiteUri?: Maybe<Scalars['String']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "googleLocation" */
export type GoogleLocation_Min_Order_By = {
  addressLines?: InputMaybe<Order_By>;
  administrativeArea?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  googleAccountId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  locality?: InputMaybe<Order_By>;
  mapsUri?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  newReviewUri?: InputMaybe<Order_By>;
  placeId?: InputMaybe<Order_By>;
  postalCode?: InputMaybe<Order_By>;
  regionCode?: InputMaybe<Order_By>;
  storeCode?: InputMaybe<Order_By>;
  sublocality?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  websiteUri?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "googleLocation" */
export type GoogleLocation_Mutation_Response = {
  __typename?: 'googleLocation_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<GoogleLocation>;
};

/** on_conflict condition type for table "googleLocation" */
export type GoogleLocation_On_Conflict = {
  constraint: GoogleLocation_Constraint;
  update_columns?: Array<GoogleLocation_Update_Column>;
  where?: InputMaybe<GoogleLocation_Bool_Exp>;
};

/** Ordering options when selecting data from "googleLocation". */
export type GoogleLocation_Order_By = {
  addressLines?: InputMaybe<Order_By>;
  administrativeArea?: InputMaybe<Order_By>;
  canOperateLocalPost?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  googleAccount?: InputMaybe<GoogleAccount_Order_By>;
  googleAccountId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  locality?: InputMaybe<Order_By>;
  mapsUri?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  newReviewUri?: InputMaybe<Order_By>;
  placeId?: InputMaybe<Order_By>;
  postalCode?: InputMaybe<Order_By>;
  regionCode?: InputMaybe<Order_By>;
  storeCode?: InputMaybe<Order_By>;
  sublocality?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  websiteUri?: InputMaybe<Order_By>;
  workspace?: InputMaybe<Workspace_Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: googleLocation */
export type GoogleLocation_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "googleLocation" */
export enum GoogleLocation_Select_Column {
  /** column name */
  AddressLines = 'addressLines',
  /** column name */
  AdministrativeArea = 'administrativeArea',
  /** column name */
  CanOperateLocalPost = 'canOperateLocalPost',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  GoogleAccountId = 'googleAccountId',
  /** column name */
  Id = 'id',
  /** column name */
  Locality = 'locality',
  /** column name */
  MapsUri = 'mapsUri',
  /** column name */
  Name = 'name',
  /** column name */
  NewReviewUri = 'newReviewUri',
  /** column name */
  PlaceId = 'placeId',
  /** column name */
  PostalCode = 'postalCode',
  /** column name */
  RegionCode = 'regionCode',
  /** column name */
  StoreCode = 'storeCode',
  /** column name */
  Sublocality = 'sublocality',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WebsiteUri = 'websiteUri',
  /** column name */
  WorkspaceId = 'workspaceId'
}

/** select "googleLocation_aggregate_bool_exp_bool_and_arguments_columns" columns of table "googleLocation" */
export enum GoogleLocation_Select_Column_GoogleLocation_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  CanOperateLocalPost = 'canOperateLocalPost'
}

/** select "googleLocation_aggregate_bool_exp_bool_or_arguments_columns" columns of table "googleLocation" */
export enum GoogleLocation_Select_Column_GoogleLocation_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  CanOperateLocalPost = 'canOperateLocalPost'
}

/** input type for updating data in table "googleLocation" */
export type GoogleLocation_Set_Input = {
  addressLines?: InputMaybe<Scalars['String']>;
  administrativeArea?: InputMaybe<Scalars['String']>;
  canOperateLocalPost?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  googleAccountId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  locality?: InputMaybe<Scalars['String']>;
  mapsUri?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  newReviewUri?: InputMaybe<Scalars['String']>;
  placeId?: InputMaybe<Scalars['String']>;
  postalCode?: InputMaybe<Scalars['String']>;
  regionCode?: InputMaybe<Scalars['String']>;
  storeCode?: InputMaybe<Scalars['String']>;
  sublocality?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  websiteUri?: InputMaybe<Scalars['String']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "googleLocation" */
export type GoogleLocation_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: GoogleLocation_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type GoogleLocation_Stream_Cursor_Value_Input = {
  addressLines?: InputMaybe<Scalars['String']>;
  administrativeArea?: InputMaybe<Scalars['String']>;
  canOperateLocalPost?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  googleAccountId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  locality?: InputMaybe<Scalars['String']>;
  mapsUri?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  newReviewUri?: InputMaybe<Scalars['String']>;
  placeId?: InputMaybe<Scalars['String']>;
  postalCode?: InputMaybe<Scalars['String']>;
  regionCode?: InputMaybe<Scalars['String']>;
  storeCode?: InputMaybe<Scalars['String']>;
  sublocality?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  websiteUri?: InputMaybe<Scalars['String']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "googleLocation" */
export enum GoogleLocation_Update_Column {
  /** column name */
  AddressLines = 'addressLines',
  /** column name */
  AdministrativeArea = 'administrativeArea',
  /** column name */
  CanOperateLocalPost = 'canOperateLocalPost',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  GoogleAccountId = 'googleAccountId',
  /** column name */
  Id = 'id',
  /** column name */
  Locality = 'locality',
  /** column name */
  MapsUri = 'mapsUri',
  /** column name */
  Name = 'name',
  /** column name */
  NewReviewUri = 'newReviewUri',
  /** column name */
  PlaceId = 'placeId',
  /** column name */
  PostalCode = 'postalCode',
  /** column name */
  RegionCode = 'regionCode',
  /** column name */
  StoreCode = 'storeCode',
  /** column name */
  Sublocality = 'sublocality',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WebsiteUri = 'websiteUri',
  /** column name */
  WorkspaceId = 'workspaceId'
}

export type GoogleLocation_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<GoogleLocation_Set_Input>;
  /** filter the rows which have to be updated */
  where: GoogleLocation_Bool_Exp;
};

/** columns and relationships of "googleReview" */
export type GoogleReview = {
  __typename?: 'googleReview';
  comment: Scalars['String'];
  createTime: Scalars['String'];
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  name: Scalars['String'];
  reviewId: Scalars['String'];
  reviewReplyText?: Maybe<Scalars['String']>;
  reviewReplyUpdateTime?: Maybe<Scalars['String']>;
  reviewerIsAnonymous: Scalars['Boolean'];
  reviewerName: Scalars['String'];
  reviewerPhotoUrl?: Maybe<Scalars['String']>;
  starRating: Scalars['String'];
  updateTime: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
};

/** aggregated selection of "googleReview" */
export type GoogleReview_Aggregate = {
  __typename?: 'googleReview_aggregate';
  aggregate?: Maybe<GoogleReview_Aggregate_Fields>;
  nodes: Array<GoogleReview>;
};

/** aggregate fields of "googleReview" */
export type GoogleReview_Aggregate_Fields = {
  __typename?: 'googleReview_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<GoogleReview_Max_Fields>;
  min?: Maybe<GoogleReview_Min_Fields>;
};


/** aggregate fields of "googleReview" */
export type GoogleReview_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<GoogleReview_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "googleReview". All fields are combined with a logical 'AND'. */
export type GoogleReview_Bool_Exp = {
  _and?: InputMaybe<Array<GoogleReview_Bool_Exp>>;
  _not?: InputMaybe<GoogleReview_Bool_Exp>;
  _or?: InputMaybe<Array<GoogleReview_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  createTime?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  reviewId?: InputMaybe<String_Comparison_Exp>;
  reviewReplyText?: InputMaybe<String_Comparison_Exp>;
  reviewReplyUpdateTime?: InputMaybe<String_Comparison_Exp>;
  reviewerIsAnonymous?: InputMaybe<Boolean_Comparison_Exp>;
  reviewerName?: InputMaybe<String_Comparison_Exp>;
  reviewerPhotoUrl?: InputMaybe<String_Comparison_Exp>;
  starRating?: InputMaybe<String_Comparison_Exp>;
  updateTime?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "googleReview" */
export enum GoogleReview_Constraint {
  /** unique or primary key constraint on columns "id" */
  GoogleReviewPkey = 'googleReview_pkey',
  /** unique or primary key constraint on columns "reviewId" */
  GoogleReviewReviewIdKey = 'googleReview_reviewId_key'
}

/** input type for inserting data into table "googleReview" */
export type GoogleReview_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  createTime?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  reviewId?: InputMaybe<Scalars['String']>;
  reviewReplyText?: InputMaybe<Scalars['String']>;
  reviewReplyUpdateTime?: InputMaybe<Scalars['String']>;
  reviewerIsAnonymous?: InputMaybe<Scalars['Boolean']>;
  reviewerName?: InputMaybe<Scalars['String']>;
  reviewerPhotoUrl?: InputMaybe<Scalars['String']>;
  starRating?: InputMaybe<Scalars['String']>;
  updateTime?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type GoogleReview_Max_Fields = {
  __typename?: 'googleReview_max_fields';
  comment?: Maybe<Scalars['String']>;
  createTime?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  reviewId?: Maybe<Scalars['String']>;
  reviewReplyText?: Maybe<Scalars['String']>;
  reviewReplyUpdateTime?: Maybe<Scalars['String']>;
  reviewerName?: Maybe<Scalars['String']>;
  reviewerPhotoUrl?: Maybe<Scalars['String']>;
  starRating?: Maybe<Scalars['String']>;
  updateTime?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type GoogleReview_Min_Fields = {
  __typename?: 'googleReview_min_fields';
  comment?: Maybe<Scalars['String']>;
  createTime?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  reviewId?: Maybe<Scalars['String']>;
  reviewReplyText?: Maybe<Scalars['String']>;
  reviewReplyUpdateTime?: Maybe<Scalars['String']>;
  reviewerName?: Maybe<Scalars['String']>;
  reviewerPhotoUrl?: Maybe<Scalars['String']>;
  starRating?: Maybe<Scalars['String']>;
  updateTime?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "googleReview" */
export type GoogleReview_Mutation_Response = {
  __typename?: 'googleReview_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<GoogleReview>;
};

/** on_conflict condition type for table "googleReview" */
export type GoogleReview_On_Conflict = {
  constraint: GoogleReview_Constraint;
  update_columns?: Array<GoogleReview_Update_Column>;
  where?: InputMaybe<GoogleReview_Bool_Exp>;
};

/** Ordering options when selecting data from "googleReview". */
export type GoogleReview_Order_By = {
  comment?: InputMaybe<Order_By>;
  createTime?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  reviewId?: InputMaybe<Order_By>;
  reviewReplyText?: InputMaybe<Order_By>;
  reviewReplyUpdateTime?: InputMaybe<Order_By>;
  reviewerIsAnonymous?: InputMaybe<Order_By>;
  reviewerName?: InputMaybe<Order_By>;
  reviewerPhotoUrl?: InputMaybe<Order_By>;
  starRating?: InputMaybe<Order_By>;
  updateTime?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: googleReview */
export type GoogleReview_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "googleReview" */
export enum GoogleReview_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  CreateTime = 'createTime',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  ReviewId = 'reviewId',
  /** column name */
  ReviewReplyText = 'reviewReplyText',
  /** column name */
  ReviewReplyUpdateTime = 'reviewReplyUpdateTime',
  /** column name */
  ReviewerIsAnonymous = 'reviewerIsAnonymous',
  /** column name */
  ReviewerName = 'reviewerName',
  /** column name */
  ReviewerPhotoUrl = 'reviewerPhotoUrl',
  /** column name */
  StarRating = 'starRating',
  /** column name */
  UpdateTime = 'updateTime',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "googleReview" */
export type GoogleReview_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  createTime?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  reviewId?: InputMaybe<Scalars['String']>;
  reviewReplyText?: InputMaybe<Scalars['String']>;
  reviewReplyUpdateTime?: InputMaybe<Scalars['String']>;
  reviewerIsAnonymous?: InputMaybe<Scalars['Boolean']>;
  reviewerName?: InputMaybe<Scalars['String']>;
  reviewerPhotoUrl?: InputMaybe<Scalars['String']>;
  starRating?: InputMaybe<Scalars['String']>;
  updateTime?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "googleReview" */
export type GoogleReview_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: GoogleReview_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type GoogleReview_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  createTime?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  reviewId?: InputMaybe<Scalars['String']>;
  reviewReplyText?: InputMaybe<Scalars['String']>;
  reviewReplyUpdateTime?: InputMaybe<Scalars['String']>;
  reviewerIsAnonymous?: InputMaybe<Scalars['Boolean']>;
  reviewerName?: InputMaybe<Scalars['String']>;
  reviewerPhotoUrl?: InputMaybe<Scalars['String']>;
  starRating?: InputMaybe<Scalars['String']>;
  updateTime?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "googleReview" */
export enum GoogleReview_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  CreateTime = 'createTime',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  ReviewId = 'reviewId',
  /** column name */
  ReviewReplyText = 'reviewReplyText',
  /** column name */
  ReviewReplyUpdateTime = 'reviewReplyUpdateTime',
  /** column name */
  ReviewerIsAnonymous = 'reviewerIsAnonymous',
  /** column name */
  ReviewerName = 'reviewerName',
  /** column name */
  ReviewerPhotoUrl = 'reviewerPhotoUrl',
  /** column name */
  StarRating = 'starRating',
  /** column name */
  UpdateTime = 'updateTime',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type GoogleReview_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<GoogleReview_Set_Input>;
  /** filter the rows which have to be updated */
  where: GoogleReview_Bool_Exp;
};

export type HealthGorillaSubscriptionsOutput = {
  __typename?: 'healthGorillaSubscriptionsOutput';
  message: Scalars['String'];
};

/** columns and relationships of "importJob" */
export type ImportJob = {
  __typename?: 'importJob';
  createdAt?: Maybe<Scalars['timestamptz']>;
  errorRows?: Maybe<Scalars['jsonb']>;
  id: Scalars['uuid'];
  payload?: Maybe<Scalars['jsonb']>;
  status: Scalars['String'];
  successRows?: Maybe<Scalars['jsonb']>;
  type: Scalars['String'];
  workspaceId: Scalars['uuid'];
};


/** columns and relationships of "importJob" */
export type ImportJobErrorRowsArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "importJob" */
export type ImportJobPayloadArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "importJob" */
export type ImportJobSuccessRowsArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "importJob" */
export type ImportJob_Aggregate = {
  __typename?: 'importJob_aggregate';
  aggregate?: Maybe<ImportJob_Aggregate_Fields>;
  nodes: Array<ImportJob>;
};

/** aggregate fields of "importJob" */
export type ImportJob_Aggregate_Fields = {
  __typename?: 'importJob_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ImportJob_Max_Fields>;
  min?: Maybe<ImportJob_Min_Fields>;
};


/** aggregate fields of "importJob" */
export type ImportJob_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<ImportJob_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type ImportJob_Append_Input = {
  errorRows?: InputMaybe<Scalars['jsonb']>;
  payload?: InputMaybe<Scalars['jsonb']>;
  successRows?: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "importJob". All fields are combined with a logical 'AND'. */
export type ImportJob_Bool_Exp = {
  _and?: InputMaybe<Array<ImportJob_Bool_Exp>>;
  _not?: InputMaybe<ImportJob_Bool_Exp>;
  _or?: InputMaybe<Array<ImportJob_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  errorRows?: InputMaybe<Jsonb_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  payload?: InputMaybe<Jsonb_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  successRows?: InputMaybe<Jsonb_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  workspaceId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "importJob" */
export enum ImportJob_Constraint {
  /** unique or primary key constraint on columns "id" */
  ImportJobPkey = 'importJob_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type ImportJob_Delete_At_Path_Input = {
  errorRows?: InputMaybe<Array<Scalars['String']>>;
  payload?: InputMaybe<Array<Scalars['String']>>;
  successRows?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type ImportJob_Delete_Elem_Input = {
  errorRows?: InputMaybe<Scalars['Int']>;
  payload?: InputMaybe<Scalars['Int']>;
  successRows?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type ImportJob_Delete_Key_Input = {
  errorRows?: InputMaybe<Scalars['String']>;
  payload?: InputMaybe<Scalars['String']>;
  successRows?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "importJob" */
export type ImportJob_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  errorRows?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['uuid']>;
  payload?: InputMaybe<Scalars['jsonb']>;
  status?: InputMaybe<Scalars['String']>;
  successRows?: InputMaybe<Scalars['jsonb']>;
  type?: InputMaybe<Scalars['String']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type ImportJob_Max_Fields = {
  __typename?: 'importJob_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type ImportJob_Min_Fields = {
  __typename?: 'importJob_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "importJob" */
export type ImportJob_Mutation_Response = {
  __typename?: 'importJob_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ImportJob>;
};

/** on_conflict condition type for table "importJob" */
export type ImportJob_On_Conflict = {
  constraint: ImportJob_Constraint;
  update_columns?: Array<ImportJob_Update_Column>;
  where?: InputMaybe<ImportJob_Bool_Exp>;
};

/** Ordering options when selecting data from "importJob". */
export type ImportJob_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  errorRows?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  payload?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  successRows?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: importJob */
export type ImportJob_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type ImportJob_Prepend_Input = {
  errorRows?: InputMaybe<Scalars['jsonb']>;
  payload?: InputMaybe<Scalars['jsonb']>;
  successRows?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "importJob" */
export enum ImportJob_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  ErrorRows = 'errorRows',
  /** column name */
  Id = 'id',
  /** column name */
  Payload = 'payload',
  /** column name */
  Status = 'status',
  /** column name */
  SuccessRows = 'successRows',
  /** column name */
  Type = 'type',
  /** column name */
  WorkspaceId = 'workspaceId'
}

/** input type for updating data in table "importJob" */
export type ImportJob_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  errorRows?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['uuid']>;
  payload?: InputMaybe<Scalars['jsonb']>;
  status?: InputMaybe<Scalars['String']>;
  successRows?: InputMaybe<Scalars['jsonb']>;
  type?: InputMaybe<Scalars['String']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "importJob" */
export type ImportJob_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ImportJob_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ImportJob_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  errorRows?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['uuid']>;
  payload?: InputMaybe<Scalars['jsonb']>;
  status?: InputMaybe<Scalars['String']>;
  successRows?: InputMaybe<Scalars['jsonb']>;
  type?: InputMaybe<Scalars['String']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "importJob" */
export enum ImportJob_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  ErrorRows = 'errorRows',
  /** column name */
  Id = 'id',
  /** column name */
  Payload = 'payload',
  /** column name */
  Status = 'status',
  /** column name */
  SuccessRows = 'successRows',
  /** column name */
  Type = 'type',
  /** column name */
  WorkspaceId = 'workspaceId'
}

export type ImportJob_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<ImportJob_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<ImportJob_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<ImportJob_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<ImportJob_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<ImportJob_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ImportJob_Set_Input>;
  /** filter the rows which have to be updated */
  where: ImportJob_Bool_Exp;
};

/** columns and relationships of "importMetadata" */
export type ImportMetadata = {
  __typename?: 'importMetadata';
  createdAt: Scalars['timestamptz'];
  externalId?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  importJobId?: Maybe<Scalars['uuid']>;
  raw?: Maybe<Scalars['jsonb']>;
  rowId: Scalars['uuid'];
  systemName: Scalars['String'];
  tableName: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  workspaceId?: Maybe<Scalars['uuid']>;
};


/** columns and relationships of "importMetadata" */
export type ImportMetadataRawArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "importMetadata" */
export type ImportMetadata_Aggregate = {
  __typename?: 'importMetadata_aggregate';
  aggregate?: Maybe<ImportMetadata_Aggregate_Fields>;
  nodes: Array<ImportMetadata>;
};

/** aggregate fields of "importMetadata" */
export type ImportMetadata_Aggregate_Fields = {
  __typename?: 'importMetadata_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ImportMetadata_Max_Fields>;
  min?: Maybe<ImportMetadata_Min_Fields>;
};


/** aggregate fields of "importMetadata" */
export type ImportMetadata_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<ImportMetadata_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type ImportMetadata_Append_Input = {
  raw?: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "importMetadata". All fields are combined with a logical 'AND'. */
export type ImportMetadata_Bool_Exp = {
  _and?: InputMaybe<Array<ImportMetadata_Bool_Exp>>;
  _not?: InputMaybe<ImportMetadata_Bool_Exp>;
  _or?: InputMaybe<Array<ImportMetadata_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  externalId?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  importJobId?: InputMaybe<Uuid_Comparison_Exp>;
  raw?: InputMaybe<Jsonb_Comparison_Exp>;
  rowId?: InputMaybe<Uuid_Comparison_Exp>;
  systemName?: InputMaybe<String_Comparison_Exp>;
  tableName?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  workspaceId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "importMetadata" */
export enum ImportMetadata_Constraint {
  /** unique or primary key constraint on columns "id" */
  ImportMetadataPkey = 'importMetadata_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type ImportMetadata_Delete_At_Path_Input = {
  raw?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type ImportMetadata_Delete_Elem_Input = {
  raw?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type ImportMetadata_Delete_Key_Input = {
  raw?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "importMetadata" */
export type ImportMetadata_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  externalId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  importJobId?: InputMaybe<Scalars['uuid']>;
  raw?: InputMaybe<Scalars['jsonb']>;
  rowId?: InputMaybe<Scalars['uuid']>;
  systemName?: InputMaybe<Scalars['String']>;
  tableName?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type ImportMetadata_Max_Fields = {
  __typename?: 'importMetadata_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  externalId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  importJobId?: Maybe<Scalars['uuid']>;
  rowId?: Maybe<Scalars['uuid']>;
  systemName?: Maybe<Scalars['String']>;
  tableName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type ImportMetadata_Min_Fields = {
  __typename?: 'importMetadata_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  externalId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  importJobId?: Maybe<Scalars['uuid']>;
  rowId?: Maybe<Scalars['uuid']>;
  systemName?: Maybe<Scalars['String']>;
  tableName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "importMetadata" */
export type ImportMetadata_Mutation_Response = {
  __typename?: 'importMetadata_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ImportMetadata>;
};

/** on_conflict condition type for table "importMetadata" */
export type ImportMetadata_On_Conflict = {
  constraint: ImportMetadata_Constraint;
  update_columns?: Array<ImportMetadata_Update_Column>;
  where?: InputMaybe<ImportMetadata_Bool_Exp>;
};

/** Ordering options when selecting data from "importMetadata". */
export type ImportMetadata_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  externalId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  importJobId?: InputMaybe<Order_By>;
  raw?: InputMaybe<Order_By>;
  rowId?: InputMaybe<Order_By>;
  systemName?: InputMaybe<Order_By>;
  tableName?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: importMetadata */
export type ImportMetadata_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type ImportMetadata_Prepend_Input = {
  raw?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "importMetadata" */
export enum ImportMetadata_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  ExternalId = 'externalId',
  /** column name */
  Id = 'id',
  /** column name */
  ImportJobId = 'importJobId',
  /** column name */
  Raw = 'raw',
  /** column name */
  RowId = 'rowId',
  /** column name */
  SystemName = 'systemName',
  /** column name */
  TableName = 'tableName',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WorkspaceId = 'workspaceId'
}

/** input type for updating data in table "importMetadata" */
export type ImportMetadata_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  externalId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  importJobId?: InputMaybe<Scalars['uuid']>;
  raw?: InputMaybe<Scalars['jsonb']>;
  rowId?: InputMaybe<Scalars['uuid']>;
  systemName?: InputMaybe<Scalars['String']>;
  tableName?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "importMetadata" */
export type ImportMetadata_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ImportMetadata_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ImportMetadata_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  externalId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  importJobId?: InputMaybe<Scalars['uuid']>;
  raw?: InputMaybe<Scalars['jsonb']>;
  rowId?: InputMaybe<Scalars['uuid']>;
  systemName?: InputMaybe<Scalars['String']>;
  tableName?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "importMetadata" */
export enum ImportMetadata_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  ExternalId = 'externalId',
  /** column name */
  Id = 'id',
  /** column name */
  ImportJobId = 'importJobId',
  /** column name */
  Raw = 'raw',
  /** column name */
  RowId = 'rowId',
  /** column name */
  SystemName = 'systemName',
  /** column name */
  TableName = 'tableName',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WorkspaceId = 'workspaceId'
}

export type ImportMetadata_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<ImportMetadata_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<ImportMetadata_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<ImportMetadata_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<ImportMetadata_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<ImportMetadata_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ImportMetadata_Set_Input>;
  /** filter the rows which have to be updated */
  where: ImportMetadata_Bool_Exp;
};

/** columns and relationships of "incompatibleServices" */
export type IncompatibleServices = {
  __typename?: 'incompatibleServices';
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An object relationship */
  serviceA: Service;
  serviceAId: Scalars['uuid'];
  /** An object relationship */
  serviceB: Service;
  serviceBId: Scalars['uuid'];
  updatedAt: Scalars['timestamptz'];
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "incompatibleServices" */
export type IncompatibleServices_Aggregate = {
  __typename?: 'incompatibleServices_aggregate';
  aggregate?: Maybe<IncompatibleServices_Aggregate_Fields>;
  nodes: Array<IncompatibleServices>;
};

export type IncompatibleServices_Aggregate_Bool_Exp = {
  count?: InputMaybe<IncompatibleServices_Aggregate_Bool_Exp_Count>;
};

export type IncompatibleServices_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<IncompatibleServices_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<IncompatibleServices_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "incompatibleServices" */
export type IncompatibleServices_Aggregate_Fields = {
  __typename?: 'incompatibleServices_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<IncompatibleServices_Max_Fields>;
  min?: Maybe<IncompatibleServices_Min_Fields>;
};


/** aggregate fields of "incompatibleServices" */
export type IncompatibleServices_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<IncompatibleServices_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "incompatibleServices" */
export type IncompatibleServices_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<IncompatibleServices_Max_Order_By>;
  min?: InputMaybe<IncompatibleServices_Min_Order_By>;
};

/** input type for inserting array relation for remote table "incompatibleServices" */
export type IncompatibleServices_Arr_Rel_Insert_Input = {
  data: Array<IncompatibleServices_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<IncompatibleServices_On_Conflict>;
};

/** Boolean expression to filter rows from the table "incompatibleServices". All fields are combined with a logical 'AND'. */
export type IncompatibleServices_Bool_Exp = {
  _and?: InputMaybe<Array<IncompatibleServices_Bool_Exp>>;
  _not?: InputMaybe<IncompatibleServices_Bool_Exp>;
  _or?: InputMaybe<Array<IncompatibleServices_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  serviceA?: InputMaybe<Service_Bool_Exp>;
  serviceAId?: InputMaybe<Uuid_Comparison_Exp>;
  serviceB?: InputMaybe<Service_Bool_Exp>;
  serviceBId?: InputMaybe<Uuid_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  workspaceId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "incompatibleServices" */
export enum IncompatibleServices_Constraint {
  /** unique or primary key constraint on columns "id" */
  IncompatibleServicesPkey = 'incompatibleServices_pkey',
  /** unique or primary key constraint on columns "serviceAId", "serviceBId" */
  IncompatibleServicesServiceAIdServiceBIdKey = 'incompatibleServices_serviceAId_serviceBId_key'
}

/** input type for inserting data into table "incompatibleServices" */
export type IncompatibleServices_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  serviceA?: InputMaybe<Service_Obj_Rel_Insert_Input>;
  serviceAId?: InputMaybe<Scalars['uuid']>;
  serviceB?: InputMaybe<Service_Obj_Rel_Insert_Input>;
  serviceBId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type IncompatibleServices_Max_Fields = {
  __typename?: 'incompatibleServices_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  serviceAId?: Maybe<Scalars['uuid']>;
  serviceBId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "incompatibleServices" */
export type IncompatibleServices_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  serviceAId?: InputMaybe<Order_By>;
  serviceBId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type IncompatibleServices_Min_Fields = {
  __typename?: 'incompatibleServices_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  serviceAId?: Maybe<Scalars['uuid']>;
  serviceBId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "incompatibleServices" */
export type IncompatibleServices_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  serviceAId?: InputMaybe<Order_By>;
  serviceBId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "incompatibleServices" */
export type IncompatibleServices_Mutation_Response = {
  __typename?: 'incompatibleServices_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<IncompatibleServices>;
};

/** on_conflict condition type for table "incompatibleServices" */
export type IncompatibleServices_On_Conflict = {
  constraint: IncompatibleServices_Constraint;
  update_columns?: Array<IncompatibleServices_Update_Column>;
  where?: InputMaybe<IncompatibleServices_Bool_Exp>;
};

/** Ordering options when selecting data from "incompatibleServices". */
export type IncompatibleServices_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  serviceA?: InputMaybe<Service_Order_By>;
  serviceAId?: InputMaybe<Order_By>;
  serviceB?: InputMaybe<Service_Order_By>;
  serviceBId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: incompatibleServices */
export type IncompatibleServices_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "incompatibleServices" */
export enum IncompatibleServices_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  ServiceAId = 'serviceAId',
  /** column name */
  ServiceBId = 'serviceBId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WorkspaceId = 'workspaceId'
}

/** input type for updating data in table "incompatibleServices" */
export type IncompatibleServices_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  serviceAId?: InputMaybe<Scalars['uuid']>;
  serviceBId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "incompatibleServices" */
export type IncompatibleServices_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: IncompatibleServices_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type IncompatibleServices_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  serviceAId?: InputMaybe<Scalars['uuid']>;
  serviceBId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "incompatibleServices" */
export enum IncompatibleServices_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  ServiceAId = 'serviceAId',
  /** column name */
  ServiceBId = 'serviceBId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WorkspaceId = 'workspaceId'
}

export type IncompatibleServices_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<IncompatibleServices_Set_Input>;
  /** filter the rows which have to be updated */
  where: IncompatibleServices_Bool_Exp;
};

/** columns and relationships of "internalAppointmentProvider" */
export type InternalAppointmentProvider = {
  __typename?: 'internalAppointmentProvider';
  /** An object relationship */
  appointment: Appointment;
  appointmentId: Scalars['uuid'];
  id: Scalars['uuid'];
  /** An object relationship */
  provider: Provider;
  providerId: Scalars['uuid'];
};

/** aggregated selection of "internalAppointmentProvider" */
export type InternalAppointmentProvider_Aggregate = {
  __typename?: 'internalAppointmentProvider_aggregate';
  aggregate?: Maybe<InternalAppointmentProvider_Aggregate_Fields>;
  nodes: Array<InternalAppointmentProvider>;
};

export type InternalAppointmentProvider_Aggregate_Bool_Exp = {
  count?: InputMaybe<InternalAppointmentProvider_Aggregate_Bool_Exp_Count>;
};

export type InternalAppointmentProvider_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<InternalAppointmentProvider_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<InternalAppointmentProvider_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "internalAppointmentProvider" */
export type InternalAppointmentProvider_Aggregate_Fields = {
  __typename?: 'internalAppointmentProvider_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<InternalAppointmentProvider_Max_Fields>;
  min?: Maybe<InternalAppointmentProvider_Min_Fields>;
};


/** aggregate fields of "internalAppointmentProvider" */
export type InternalAppointmentProvider_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<InternalAppointmentProvider_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "internalAppointmentProvider" */
export type InternalAppointmentProvider_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<InternalAppointmentProvider_Max_Order_By>;
  min?: InputMaybe<InternalAppointmentProvider_Min_Order_By>;
};

/** input type for inserting array relation for remote table "internalAppointmentProvider" */
export type InternalAppointmentProvider_Arr_Rel_Insert_Input = {
  data: Array<InternalAppointmentProvider_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<InternalAppointmentProvider_On_Conflict>;
};

/** Boolean expression to filter rows from the table "internalAppointmentProvider". All fields are combined with a logical 'AND'. */
export type InternalAppointmentProvider_Bool_Exp = {
  _and?: InputMaybe<Array<InternalAppointmentProvider_Bool_Exp>>;
  _not?: InputMaybe<InternalAppointmentProvider_Bool_Exp>;
  _or?: InputMaybe<Array<InternalAppointmentProvider_Bool_Exp>>;
  appointment?: InputMaybe<Appointment_Bool_Exp>;
  appointmentId?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  provider?: InputMaybe<Provider_Bool_Exp>;
  providerId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "internalAppointmentProvider" */
export enum InternalAppointmentProvider_Constraint {
  /** unique or primary key constraint on columns "id" */
  InternalAppointmentProviderPkey = 'internalAppointmentProvider_pkey',
  /** unique or primary key constraint on columns "providerId", "appointmentId" */
  InternalAppointmentProviderProviderIdAppointmentIdKey = 'internalAppointmentProvider_providerId_appointmentId_key'
}

/** input type for inserting data into table "internalAppointmentProvider" */
export type InternalAppointmentProvider_Insert_Input = {
  appointment?: InputMaybe<Appointment_Obj_Rel_Insert_Input>;
  appointmentId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  provider?: InputMaybe<Provider_Obj_Rel_Insert_Input>;
  providerId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type InternalAppointmentProvider_Max_Fields = {
  __typename?: 'internalAppointmentProvider_max_fields';
  appointmentId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  providerId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "internalAppointmentProvider" */
export type InternalAppointmentProvider_Max_Order_By = {
  appointmentId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  providerId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type InternalAppointmentProvider_Min_Fields = {
  __typename?: 'internalAppointmentProvider_min_fields';
  appointmentId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  providerId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "internalAppointmentProvider" */
export type InternalAppointmentProvider_Min_Order_By = {
  appointmentId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  providerId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "internalAppointmentProvider" */
export type InternalAppointmentProvider_Mutation_Response = {
  __typename?: 'internalAppointmentProvider_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<InternalAppointmentProvider>;
};

/** on_conflict condition type for table "internalAppointmentProvider" */
export type InternalAppointmentProvider_On_Conflict = {
  constraint: InternalAppointmentProvider_Constraint;
  update_columns?: Array<InternalAppointmentProvider_Update_Column>;
  where?: InputMaybe<InternalAppointmentProvider_Bool_Exp>;
};

/** Ordering options when selecting data from "internalAppointmentProvider". */
export type InternalAppointmentProvider_Order_By = {
  appointment?: InputMaybe<Appointment_Order_By>;
  appointmentId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  provider?: InputMaybe<Provider_Order_By>;
  providerId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: internalAppointmentProvider */
export type InternalAppointmentProvider_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "internalAppointmentProvider" */
export enum InternalAppointmentProvider_Select_Column {
  /** column name */
  AppointmentId = 'appointmentId',
  /** column name */
  Id = 'id',
  /** column name */
  ProviderId = 'providerId'
}

/** input type for updating data in table "internalAppointmentProvider" */
export type InternalAppointmentProvider_Set_Input = {
  appointmentId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  providerId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "internalAppointmentProvider" */
export type InternalAppointmentProvider_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: InternalAppointmentProvider_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type InternalAppointmentProvider_Stream_Cursor_Value_Input = {
  appointmentId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  providerId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "internalAppointmentProvider" */
export enum InternalAppointmentProvider_Update_Column {
  /** column name */
  AppointmentId = 'appointmentId',
  /** column name */
  Id = 'id',
  /** column name */
  ProviderId = 'providerId'
}

export type InternalAppointmentProvider_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<InternalAppointmentProvider_Set_Input>;
  /** filter the rows which have to be updated */
  where: InternalAppointmentProvider_Bool_Exp;
};

/** columns and relationships of "inventoryTransaction" */
export type InventoryTransaction = {
  __typename?: 'inventoryTransaction';
  account: Scalars['jsonb'];
  accountId: Scalars['uuid'];
  accountType: Scalars['String'];
  /** An object relationship */
  consumable: Consumable;
  consumableId: Scalars['uuid'];
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  note?: Maybe<Scalars['String']>;
  quantity: Scalars['float8'];
  relatedInventoryTransactionId?: Maybe<Scalars['uuid']>;
  type: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  /** An object relationship */
  user: User;
  userId: Scalars['uuid'];
  workspaceId: Scalars['uuid'];
};

/** aggregated selection of "inventoryTransaction" */
export type InventoryTransaction_Aggregate = {
  __typename?: 'inventoryTransaction_aggregate';
  aggregate?: Maybe<InventoryTransaction_Aggregate_Fields>;
  nodes: Array<InventoryTransaction>;
};

/** aggregate fields of "inventoryTransaction" */
export type InventoryTransaction_Aggregate_Fields = {
  __typename?: 'inventoryTransaction_aggregate_fields';
  avg?: Maybe<InventoryTransaction_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<InventoryTransaction_Max_Fields>;
  min?: Maybe<InventoryTransaction_Min_Fields>;
  stddev?: Maybe<InventoryTransaction_Stddev_Fields>;
  stddev_pop?: Maybe<InventoryTransaction_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<InventoryTransaction_Stddev_Samp_Fields>;
  sum?: Maybe<InventoryTransaction_Sum_Fields>;
  var_pop?: Maybe<InventoryTransaction_Var_Pop_Fields>;
  var_samp?: Maybe<InventoryTransaction_Var_Samp_Fields>;
  variance?: Maybe<InventoryTransaction_Variance_Fields>;
};


/** aggregate fields of "inventoryTransaction" */
export type InventoryTransaction_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<InventoryTransaction_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type InventoryTransaction_Avg_Fields = {
  __typename?: 'inventoryTransaction_avg_fields';
  quantity?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "inventoryTransaction". All fields are combined with a logical 'AND'. */
export type InventoryTransaction_Bool_Exp = {
  _and?: InputMaybe<Array<InventoryTransaction_Bool_Exp>>;
  _not?: InputMaybe<InventoryTransaction_Bool_Exp>;
  _or?: InputMaybe<Array<InventoryTransaction_Bool_Exp>>;
  accountId?: InputMaybe<Uuid_Comparison_Exp>;
  accountType?: InputMaybe<String_Comparison_Exp>;
  consumable?: InputMaybe<Consumable_Bool_Exp>;
  consumableId?: InputMaybe<Uuid_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  note?: InputMaybe<String_Comparison_Exp>;
  quantity?: InputMaybe<Float8_Comparison_Exp>;
  relatedInventoryTransactionId?: InputMaybe<Uuid_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<User_Bool_Exp>;
  userId?: InputMaybe<Uuid_Comparison_Exp>;
  workspaceId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "inventoryTransaction" */
export enum InventoryTransaction_Constraint {
  /** unique or primary key constraint on columns "id" */
  InventoryTransactionPkey = 'inventoryTransaction_pkey'
}

/** input type for incrementing numeric columns in table "inventoryTransaction" */
export type InventoryTransaction_Inc_Input = {
  quantity?: InputMaybe<Scalars['float8']>;
};

/** input type for inserting data into table "inventoryTransaction" */
export type InventoryTransaction_Insert_Input = {
  accountId?: InputMaybe<Scalars['uuid']>;
  accountType?: InputMaybe<Scalars['String']>;
  consumable?: InputMaybe<Consumable_Obj_Rel_Insert_Input>;
  consumableId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  note?: InputMaybe<Scalars['String']>;
  quantity?: InputMaybe<Scalars['float8']>;
  relatedInventoryTransactionId?: InputMaybe<Scalars['uuid']>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<User_Obj_Rel_Insert_Input>;
  userId?: InputMaybe<Scalars['uuid']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type InventoryTransaction_Max_Fields = {
  __typename?: 'inventoryTransaction_max_fields';
  accountId?: Maybe<Scalars['uuid']>;
  accountType?: Maybe<Scalars['String']>;
  consumableId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  note?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['float8']>;
  relatedInventoryTransactionId?: Maybe<Scalars['uuid']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['uuid']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type InventoryTransaction_Min_Fields = {
  __typename?: 'inventoryTransaction_min_fields';
  accountId?: Maybe<Scalars['uuid']>;
  accountType?: Maybe<Scalars['String']>;
  consumableId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  note?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['float8']>;
  relatedInventoryTransactionId?: Maybe<Scalars['uuid']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['uuid']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "inventoryTransaction" */
export type InventoryTransaction_Mutation_Response = {
  __typename?: 'inventoryTransaction_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<InventoryTransaction>;
};

/** on_conflict condition type for table "inventoryTransaction" */
export type InventoryTransaction_On_Conflict = {
  constraint: InventoryTransaction_Constraint;
  update_columns?: Array<InventoryTransaction_Update_Column>;
  where?: InputMaybe<InventoryTransaction_Bool_Exp>;
};

/** Ordering options when selecting data from "inventoryTransaction". */
export type InventoryTransaction_Order_By = {
  accountId?: InputMaybe<Order_By>;
  accountType?: InputMaybe<Order_By>;
  consumable?: InputMaybe<Consumable_Order_By>;
  consumableId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  note?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  relatedInventoryTransactionId?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  user?: InputMaybe<User_Order_By>;
  userId?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: inventoryTransaction */
export type InventoryTransaction_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "inventoryTransaction" */
export enum InventoryTransaction_Select_Column {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  AccountType = 'accountType',
  /** column name */
  ConsumableId = 'consumableId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Note = 'note',
  /** column name */
  Quantity = 'quantity',
  /** column name */
  RelatedInventoryTransactionId = 'relatedInventoryTransactionId',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId',
  /** column name */
  WorkspaceId = 'workspaceId'
}

/** input type for updating data in table "inventoryTransaction" */
export type InventoryTransaction_Set_Input = {
  accountId?: InputMaybe<Scalars['uuid']>;
  accountType?: InputMaybe<Scalars['String']>;
  consumableId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  note?: InputMaybe<Scalars['String']>;
  quantity?: InputMaybe<Scalars['float8']>;
  relatedInventoryTransactionId?: InputMaybe<Scalars['uuid']>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  userId?: InputMaybe<Scalars['uuid']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type InventoryTransaction_Stddev_Fields = {
  __typename?: 'inventoryTransaction_stddev_fields';
  quantity?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type InventoryTransaction_Stddev_Pop_Fields = {
  __typename?: 'inventoryTransaction_stddev_pop_fields';
  quantity?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type InventoryTransaction_Stddev_Samp_Fields = {
  __typename?: 'inventoryTransaction_stddev_samp_fields';
  quantity?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "inventoryTransaction" */
export type InventoryTransaction_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: InventoryTransaction_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type InventoryTransaction_Stream_Cursor_Value_Input = {
  accountId?: InputMaybe<Scalars['uuid']>;
  accountType?: InputMaybe<Scalars['String']>;
  consumableId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  note?: InputMaybe<Scalars['String']>;
  quantity?: InputMaybe<Scalars['float8']>;
  relatedInventoryTransactionId?: InputMaybe<Scalars['uuid']>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  userId?: InputMaybe<Scalars['uuid']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type InventoryTransaction_Sum_Fields = {
  __typename?: 'inventoryTransaction_sum_fields';
  quantity?: Maybe<Scalars['float8']>;
};

/** update columns of table "inventoryTransaction" */
export enum InventoryTransaction_Update_Column {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  AccountType = 'accountType',
  /** column name */
  ConsumableId = 'consumableId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Note = 'note',
  /** column name */
  Quantity = 'quantity',
  /** column name */
  RelatedInventoryTransactionId = 'relatedInventoryTransactionId',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId',
  /** column name */
  WorkspaceId = 'workspaceId'
}

export type InventoryTransaction_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<InventoryTransaction_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<InventoryTransaction_Set_Input>;
  /** filter the rows which have to be updated */
  where: InventoryTransaction_Bool_Exp;
};

/** aggregate var_pop on columns */
export type InventoryTransaction_Var_Pop_Fields = {
  __typename?: 'inventoryTransaction_var_pop_fields';
  quantity?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type InventoryTransaction_Var_Samp_Fields = {
  __typename?: 'inventoryTransaction_var_samp_fields';
  quantity?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type InventoryTransaction_Variance_Fields = {
  __typename?: 'inventoryTransaction_variance_fields';
  quantity?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "invoice" */
export type Invoice = {
  __typename?: 'invoice';
  address?: Maybe<Scalars['String']>;
  /** An object relationship */
  appointment?: Maybe<Appointment>;
  appointmentId?: Maybe<Scalars['uuid']>;
  balanceDue: Scalars['Int'];
  /** An object relationship */
  cashier?: Maybe<User>;
  cashierId?: Maybe<Scalars['uuid']>;
  consumableOverride?: Maybe<Scalars['String']>;
  createdAt: Scalars['timestamptz'];
  creditsApplied: Scalars['Int'];
  /** An array relationship */
  depositInvoiceLineItems: Array<DepositInvoiceLineItem>;
  /** An aggregate relationship */
  depositInvoiceLineItems_aggregate: DepositInvoiceLineItem_Aggregate;
  depositPaid: Scalars['Int'];
  discount: Scalars['Int'];
  /** An array relationship */
  discountLineItems: Array<DiscountInvoiceLineItem>;
  /** An aggregate relationship */
  discountLineItems_aggregate: DiscountInvoiceLineItem_Aggregate;
  dueDate: Scalars['timestamptz'];
  /** An array relationship */
  feeInvoiceLineItems: Array<FeeInvoiceLineItem>;
  /** An aggregate relationship */
  feeInvoiceLineItems_aggregate: FeeInvoiceLineItem_Aggregate;
  /** An array relationship */
  genericLineItemDiscounts: Array<GenericInvoiceLineItemDiscount>;
  /** An aggregate relationship */
  genericLineItemDiscounts_aggregate: GenericInvoiceLineItemDiscount_Aggregate;
  /** An array relationship */
  genericLineItems: Array<GenericInvoiceLineItem>;
  /** An aggregate relationship */
  genericLineItems_aggregate: GenericInvoiceLineItem_Aggregate;
  gratuity: Scalars['Int'];
  id: Scalars['uuid'];
  /** An array relationship */
  invoiceCoupons: Array<InvoiceCoupon>;
  /** An aggregate relationship */
  invoiceCoupons_aggregate: InvoiceCoupon_Aggregate;
  /** An array relationship */
  invoiceMemberships: Array<InvoiceMembership>;
  /** An aggregate relationship */
  invoiceMemberships_aggregate: InvoiceMembership_Aggregate;
  invoiceNumber: Scalars['String'];
  /** An array relationship */
  invoicePackages: Array<InvoicePackage>;
  /** An aggregate relationship */
  invoicePackages_aggregate: InvoicePackage_Aggregate;
  /** An array relationship */
  invoiceServiceConsumables: Array<InvoiceServiceConsumable>;
  /** An aggregate relationship */
  invoiceServiceConsumables_aggregate: InvoiceServiceConsumable_Aggregate;
  isArchived?: Maybe<Scalars['Boolean']>;
  /** An object relationship */
  location: Location;
  locationId: Scalars['uuid'];
  message?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  paidOn?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  patient: Patient;
  patientId: Scalars['uuid'];
  patientMembershipId?: Maybe<Scalars['uuid']>;
  patientWalletDiscountApplied: Scalars['Boolean'];
  /** An array relationship */
  payments: Array<Payment>;
  /** An aggregate relationship */
  payments_aggregate: Payment_Aggregate;
  postTaxTotal: Scalars['Int'];
  preTaxTotal: Scalars['Int'];
  /** An array relationship */
  productItems: Array<InvoiceProductItem>;
  /** An aggregate relationship */
  productItems_aggregate: InvoiceProductItem_Aggregate;
  /** An object relationship */
  provider?: Maybe<Provider>;
  providerId?: Maybe<Scalars['uuid']>;
  receiptPayments: Array<InvoiceReceiptPayment>;
  /** An array relationship */
  serviceItems: Array<InvoiceServiceItem>;
  /** An aggregate relationship */
  serviceItems_aggregate: InvoiceServiceItem_Aggregate;
  signatureId?: Maybe<Scalars['uuid']>;
  status: Scalars['String'];
  taxTotal: Scalars['Int'];
  type: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  /** An object relationship */
  workspace: Workspace;
  workspaceId: Scalars['uuid'];
};


/** columns and relationships of "invoice" */
export type InvoiceDepositInvoiceLineItemsArgs = {
  distinct_on?: InputMaybe<Array<DepositInvoiceLineItem_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<DepositInvoiceLineItem_Order_By>>;
  where?: InputMaybe<DepositInvoiceLineItem_Bool_Exp>;
};


/** columns and relationships of "invoice" */
export type InvoiceDepositInvoiceLineItems_AggregateArgs = {
  distinct_on?: InputMaybe<Array<DepositInvoiceLineItem_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<DepositInvoiceLineItem_Order_By>>;
  where?: InputMaybe<DepositInvoiceLineItem_Bool_Exp>;
};


/** columns and relationships of "invoice" */
export type InvoiceDiscountLineItemsArgs = {
  distinct_on?: InputMaybe<Array<DiscountInvoiceLineItem_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<DiscountInvoiceLineItem_Order_By>>;
  where?: InputMaybe<DiscountInvoiceLineItem_Bool_Exp>;
};


/** columns and relationships of "invoice" */
export type InvoiceDiscountLineItems_AggregateArgs = {
  distinct_on?: InputMaybe<Array<DiscountInvoiceLineItem_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<DiscountInvoiceLineItem_Order_By>>;
  where?: InputMaybe<DiscountInvoiceLineItem_Bool_Exp>;
};


/** columns and relationships of "invoice" */
export type InvoiceFeeInvoiceLineItemsArgs = {
  distinct_on?: InputMaybe<Array<FeeInvoiceLineItem_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<FeeInvoiceLineItem_Order_By>>;
  where?: InputMaybe<FeeInvoiceLineItem_Bool_Exp>;
};


/** columns and relationships of "invoice" */
export type InvoiceFeeInvoiceLineItems_AggregateArgs = {
  distinct_on?: InputMaybe<Array<FeeInvoiceLineItem_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<FeeInvoiceLineItem_Order_By>>;
  where?: InputMaybe<FeeInvoiceLineItem_Bool_Exp>;
};


/** columns and relationships of "invoice" */
export type InvoiceGenericLineItemDiscountsArgs = {
  distinct_on?: InputMaybe<Array<GenericInvoiceLineItemDiscount_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GenericInvoiceLineItemDiscount_Order_By>>;
  where?: InputMaybe<GenericInvoiceLineItemDiscount_Bool_Exp>;
};


/** columns and relationships of "invoice" */
export type InvoiceGenericLineItemDiscounts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<GenericInvoiceLineItemDiscount_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GenericInvoiceLineItemDiscount_Order_By>>;
  where?: InputMaybe<GenericInvoiceLineItemDiscount_Bool_Exp>;
};


/** columns and relationships of "invoice" */
export type InvoiceGenericLineItemsArgs = {
  distinct_on?: InputMaybe<Array<GenericInvoiceLineItem_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GenericInvoiceLineItem_Order_By>>;
  where?: InputMaybe<GenericInvoiceLineItem_Bool_Exp>;
};


/** columns and relationships of "invoice" */
export type InvoiceGenericLineItems_AggregateArgs = {
  distinct_on?: InputMaybe<Array<GenericInvoiceLineItem_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GenericInvoiceLineItem_Order_By>>;
  where?: InputMaybe<GenericInvoiceLineItem_Bool_Exp>;
};


/** columns and relationships of "invoice" */
export type InvoiceInvoiceCouponsArgs = {
  distinct_on?: InputMaybe<Array<InvoiceCoupon_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<InvoiceCoupon_Order_By>>;
  where?: InputMaybe<InvoiceCoupon_Bool_Exp>;
};


/** columns and relationships of "invoice" */
export type InvoiceInvoiceCoupons_AggregateArgs = {
  distinct_on?: InputMaybe<Array<InvoiceCoupon_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<InvoiceCoupon_Order_By>>;
  where?: InputMaybe<InvoiceCoupon_Bool_Exp>;
};


/** columns and relationships of "invoice" */
export type InvoiceInvoiceMembershipsArgs = {
  distinct_on?: InputMaybe<Array<InvoiceMembership_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<InvoiceMembership_Order_By>>;
  where?: InputMaybe<InvoiceMembership_Bool_Exp>;
};


/** columns and relationships of "invoice" */
export type InvoiceInvoiceMemberships_AggregateArgs = {
  distinct_on?: InputMaybe<Array<InvoiceMembership_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<InvoiceMembership_Order_By>>;
  where?: InputMaybe<InvoiceMembership_Bool_Exp>;
};


/** columns and relationships of "invoice" */
export type InvoiceInvoicePackagesArgs = {
  distinct_on?: InputMaybe<Array<InvoicePackage_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<InvoicePackage_Order_By>>;
  where?: InputMaybe<InvoicePackage_Bool_Exp>;
};


/** columns and relationships of "invoice" */
export type InvoiceInvoicePackages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<InvoicePackage_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<InvoicePackage_Order_By>>;
  where?: InputMaybe<InvoicePackage_Bool_Exp>;
};


/** columns and relationships of "invoice" */
export type InvoiceInvoiceServiceConsumablesArgs = {
  distinct_on?: InputMaybe<Array<InvoiceServiceConsumable_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<InvoiceServiceConsumable_Order_By>>;
  where?: InputMaybe<InvoiceServiceConsumable_Bool_Exp>;
};


/** columns and relationships of "invoice" */
export type InvoiceInvoiceServiceConsumables_AggregateArgs = {
  distinct_on?: InputMaybe<Array<InvoiceServiceConsumable_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<InvoiceServiceConsumable_Order_By>>;
  where?: InputMaybe<InvoiceServiceConsumable_Bool_Exp>;
};


/** columns and relationships of "invoice" */
export type InvoicePaymentsArgs = {
  distinct_on?: InputMaybe<Array<Payment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Payment_Order_By>>;
  where?: InputMaybe<Payment_Bool_Exp>;
};


/** columns and relationships of "invoice" */
export type InvoicePayments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Payment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Payment_Order_By>>;
  where?: InputMaybe<Payment_Bool_Exp>;
};


/** columns and relationships of "invoice" */
export type InvoiceProductItemsArgs = {
  distinct_on?: InputMaybe<Array<InvoiceProductItem_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<InvoiceProductItem_Order_By>>;
  where?: InputMaybe<InvoiceProductItem_Bool_Exp>;
};


/** columns and relationships of "invoice" */
export type InvoiceProductItems_AggregateArgs = {
  distinct_on?: InputMaybe<Array<InvoiceProductItem_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<InvoiceProductItem_Order_By>>;
  where?: InputMaybe<InvoiceProductItem_Bool_Exp>;
};


/** columns and relationships of "invoice" */
export type InvoiceServiceItemsArgs = {
  distinct_on?: InputMaybe<Array<InvoiceServiceItem_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<InvoiceServiceItem_Order_By>>;
  where?: InputMaybe<InvoiceServiceItem_Bool_Exp>;
};


/** columns and relationships of "invoice" */
export type InvoiceServiceItems_AggregateArgs = {
  distinct_on?: InputMaybe<Array<InvoiceServiceItem_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<InvoiceServiceItem_Order_By>>;
  where?: InputMaybe<InvoiceServiceItem_Bool_Exp>;
};

/** columns and relationships of "invoiceConfiguration" */
export type InvoiceConfiguration = {
  __typename?: 'invoiceConfiguration';
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  receiptOptions: Scalars['jsonb'];
  updatedAt: Scalars['timestamptz'];
  workspaceId: Scalars['uuid'];
};


/** columns and relationships of "invoiceConfiguration" */
export type InvoiceConfigurationReceiptOptionsArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "invoiceConfiguration" */
export type InvoiceConfiguration_Aggregate = {
  __typename?: 'invoiceConfiguration_aggregate';
  aggregate?: Maybe<InvoiceConfiguration_Aggregate_Fields>;
  nodes: Array<InvoiceConfiguration>;
};

/** aggregate fields of "invoiceConfiguration" */
export type InvoiceConfiguration_Aggregate_Fields = {
  __typename?: 'invoiceConfiguration_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<InvoiceConfiguration_Max_Fields>;
  min?: Maybe<InvoiceConfiguration_Min_Fields>;
};


/** aggregate fields of "invoiceConfiguration" */
export type InvoiceConfiguration_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<InvoiceConfiguration_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type InvoiceConfiguration_Append_Input = {
  receiptOptions?: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "invoiceConfiguration". All fields are combined with a logical 'AND'. */
export type InvoiceConfiguration_Bool_Exp = {
  _and?: InputMaybe<Array<InvoiceConfiguration_Bool_Exp>>;
  _not?: InputMaybe<InvoiceConfiguration_Bool_Exp>;
  _or?: InputMaybe<Array<InvoiceConfiguration_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  receiptOptions?: InputMaybe<Jsonb_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  workspaceId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "invoiceConfiguration" */
export enum InvoiceConfiguration_Constraint {
  /** unique or primary key constraint on columns "id" */
  InvoiceConfigurationPkey = 'invoiceConfiguration_pkey',
  /** unique or primary key constraint on columns "workspaceId" */
  InvoiceConfigurationWorkspaceIdKey = 'invoiceConfiguration_workspaceId_key'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type InvoiceConfiguration_Delete_At_Path_Input = {
  receiptOptions?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type InvoiceConfiguration_Delete_Elem_Input = {
  receiptOptions?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type InvoiceConfiguration_Delete_Key_Input = {
  receiptOptions?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "invoiceConfiguration" */
export type InvoiceConfiguration_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  receiptOptions?: InputMaybe<Scalars['jsonb']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type InvoiceConfiguration_Max_Fields = {
  __typename?: 'invoiceConfiguration_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type InvoiceConfiguration_Min_Fields = {
  __typename?: 'invoiceConfiguration_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "invoiceConfiguration" */
export type InvoiceConfiguration_Mutation_Response = {
  __typename?: 'invoiceConfiguration_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<InvoiceConfiguration>;
};

/** on_conflict condition type for table "invoiceConfiguration" */
export type InvoiceConfiguration_On_Conflict = {
  constraint: InvoiceConfiguration_Constraint;
  update_columns?: Array<InvoiceConfiguration_Update_Column>;
  where?: InputMaybe<InvoiceConfiguration_Bool_Exp>;
};

/** Ordering options when selecting data from "invoiceConfiguration". */
export type InvoiceConfiguration_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  receiptOptions?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: invoiceConfiguration */
export type InvoiceConfiguration_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type InvoiceConfiguration_Prepend_Input = {
  receiptOptions?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "invoiceConfiguration" */
export enum InvoiceConfiguration_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  ReceiptOptions = 'receiptOptions',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WorkspaceId = 'workspaceId'
}

/** input type for updating data in table "invoiceConfiguration" */
export type InvoiceConfiguration_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  receiptOptions?: InputMaybe<Scalars['jsonb']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "invoiceConfiguration" */
export type InvoiceConfiguration_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: InvoiceConfiguration_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type InvoiceConfiguration_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  receiptOptions?: InputMaybe<Scalars['jsonb']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "invoiceConfiguration" */
export enum InvoiceConfiguration_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  ReceiptOptions = 'receiptOptions',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WorkspaceId = 'workspaceId'
}

export type InvoiceConfiguration_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<InvoiceConfiguration_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<InvoiceConfiguration_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<InvoiceConfiguration_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<InvoiceConfiguration_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<InvoiceConfiguration_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<InvoiceConfiguration_Set_Input>;
  /** filter the rows which have to be updated */
  where: InvoiceConfiguration_Bool_Exp;
};

/** columns and relationships of "invoiceCoupon" */
export type InvoiceCoupon = {
  __typename?: 'invoiceCoupon';
  amount: Scalars['float8'];
  /** An object relationship */
  coupon: Coupon;
  couponId: Scalars['uuid'];
  createdAt: Scalars['timestamptz'];
  expirationOverride: Scalars['Boolean'];
  id: Scalars['uuid'];
  /** An object relationship */
  invoice: Invoice;
  invoiceId: Scalars['uuid'];
  isMembershipCoupon: Scalars['Boolean'];
  isSystemGenerated: Scalars['Boolean'];
  packageId?: Maybe<Scalars['uuid']>;
  updatedAt: Scalars['timestamptz'];
};

/** aggregated selection of "invoiceCoupon" */
export type InvoiceCoupon_Aggregate = {
  __typename?: 'invoiceCoupon_aggregate';
  aggregate?: Maybe<InvoiceCoupon_Aggregate_Fields>;
  nodes: Array<InvoiceCoupon>;
};

export type InvoiceCoupon_Aggregate_Bool_Exp = {
  avg?: InputMaybe<InvoiceCoupon_Aggregate_Bool_Exp_Avg>;
  bool_and?: InputMaybe<InvoiceCoupon_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<InvoiceCoupon_Aggregate_Bool_Exp_Bool_Or>;
  corr?: InputMaybe<InvoiceCoupon_Aggregate_Bool_Exp_Corr>;
  count?: InputMaybe<InvoiceCoupon_Aggregate_Bool_Exp_Count>;
  covar_samp?: InputMaybe<InvoiceCoupon_Aggregate_Bool_Exp_Covar_Samp>;
  max?: InputMaybe<InvoiceCoupon_Aggregate_Bool_Exp_Max>;
  min?: InputMaybe<InvoiceCoupon_Aggregate_Bool_Exp_Min>;
  stddev_samp?: InputMaybe<InvoiceCoupon_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: InputMaybe<InvoiceCoupon_Aggregate_Bool_Exp_Sum>;
  var_samp?: InputMaybe<InvoiceCoupon_Aggregate_Bool_Exp_Var_Samp>;
};

export type InvoiceCoupon_Aggregate_Bool_Exp_Avg = {
  arguments: InvoiceCoupon_Select_Column_InvoiceCoupon_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<InvoiceCoupon_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type InvoiceCoupon_Aggregate_Bool_Exp_Bool_And = {
  arguments: InvoiceCoupon_Select_Column_InvoiceCoupon_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<InvoiceCoupon_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type InvoiceCoupon_Aggregate_Bool_Exp_Bool_Or = {
  arguments: InvoiceCoupon_Select_Column_InvoiceCoupon_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<InvoiceCoupon_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type InvoiceCoupon_Aggregate_Bool_Exp_Corr = {
  arguments: InvoiceCoupon_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<InvoiceCoupon_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type InvoiceCoupon_Aggregate_Bool_Exp_Corr_Arguments = {
  X: InvoiceCoupon_Select_Column_InvoiceCoupon_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: InvoiceCoupon_Select_Column_InvoiceCoupon_Aggregate_Bool_Exp_Corr_Arguments_Columns;
};

export type InvoiceCoupon_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<InvoiceCoupon_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<InvoiceCoupon_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type InvoiceCoupon_Aggregate_Bool_Exp_Covar_Samp = {
  arguments: InvoiceCoupon_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<InvoiceCoupon_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type InvoiceCoupon_Aggregate_Bool_Exp_Covar_Samp_Arguments = {
  X: InvoiceCoupon_Select_Column_InvoiceCoupon_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  Y: InvoiceCoupon_Select_Column_InvoiceCoupon_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
};

export type InvoiceCoupon_Aggregate_Bool_Exp_Max = {
  arguments: InvoiceCoupon_Select_Column_InvoiceCoupon_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<InvoiceCoupon_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type InvoiceCoupon_Aggregate_Bool_Exp_Min = {
  arguments: InvoiceCoupon_Select_Column_InvoiceCoupon_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<InvoiceCoupon_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type InvoiceCoupon_Aggregate_Bool_Exp_Stddev_Samp = {
  arguments: InvoiceCoupon_Select_Column_InvoiceCoupon_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<InvoiceCoupon_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type InvoiceCoupon_Aggregate_Bool_Exp_Sum = {
  arguments: InvoiceCoupon_Select_Column_InvoiceCoupon_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<InvoiceCoupon_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type InvoiceCoupon_Aggregate_Bool_Exp_Var_Samp = {
  arguments: InvoiceCoupon_Select_Column_InvoiceCoupon_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<InvoiceCoupon_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

/** aggregate fields of "invoiceCoupon" */
export type InvoiceCoupon_Aggregate_Fields = {
  __typename?: 'invoiceCoupon_aggregate_fields';
  avg?: Maybe<InvoiceCoupon_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<InvoiceCoupon_Max_Fields>;
  min?: Maybe<InvoiceCoupon_Min_Fields>;
  stddev?: Maybe<InvoiceCoupon_Stddev_Fields>;
  stddev_pop?: Maybe<InvoiceCoupon_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<InvoiceCoupon_Stddev_Samp_Fields>;
  sum?: Maybe<InvoiceCoupon_Sum_Fields>;
  var_pop?: Maybe<InvoiceCoupon_Var_Pop_Fields>;
  var_samp?: Maybe<InvoiceCoupon_Var_Samp_Fields>;
  variance?: Maybe<InvoiceCoupon_Variance_Fields>;
};


/** aggregate fields of "invoiceCoupon" */
export type InvoiceCoupon_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<InvoiceCoupon_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "invoiceCoupon" */
export type InvoiceCoupon_Aggregate_Order_By = {
  avg?: InputMaybe<InvoiceCoupon_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<InvoiceCoupon_Max_Order_By>;
  min?: InputMaybe<InvoiceCoupon_Min_Order_By>;
  stddev?: InputMaybe<InvoiceCoupon_Stddev_Order_By>;
  stddev_pop?: InputMaybe<InvoiceCoupon_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<InvoiceCoupon_Stddev_Samp_Order_By>;
  sum?: InputMaybe<InvoiceCoupon_Sum_Order_By>;
  var_pop?: InputMaybe<InvoiceCoupon_Var_Pop_Order_By>;
  var_samp?: InputMaybe<InvoiceCoupon_Var_Samp_Order_By>;
  variance?: InputMaybe<InvoiceCoupon_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "invoiceCoupon" */
export type InvoiceCoupon_Arr_Rel_Insert_Input = {
  data: Array<InvoiceCoupon_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<InvoiceCoupon_On_Conflict>;
};

/** aggregate avg on columns */
export type InvoiceCoupon_Avg_Fields = {
  __typename?: 'invoiceCoupon_avg_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "invoiceCoupon" */
export type InvoiceCoupon_Avg_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "invoiceCoupon". All fields are combined with a logical 'AND'. */
export type InvoiceCoupon_Bool_Exp = {
  _and?: InputMaybe<Array<InvoiceCoupon_Bool_Exp>>;
  _not?: InputMaybe<InvoiceCoupon_Bool_Exp>;
  _or?: InputMaybe<Array<InvoiceCoupon_Bool_Exp>>;
  amount?: InputMaybe<Float8_Comparison_Exp>;
  coupon?: InputMaybe<Coupon_Bool_Exp>;
  couponId?: InputMaybe<Uuid_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  expirationOverride?: InputMaybe<Boolean_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  invoice?: InputMaybe<Invoice_Bool_Exp>;
  invoiceId?: InputMaybe<Uuid_Comparison_Exp>;
  isMembershipCoupon?: InputMaybe<Boolean_Comparison_Exp>;
  isSystemGenerated?: InputMaybe<Boolean_Comparison_Exp>;
  packageId?: InputMaybe<Uuid_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "invoiceCoupon" */
export enum InvoiceCoupon_Constraint {
  /** unique or primary key constraint on columns "id" */
  InvoiceCouponPkey = 'invoiceCoupon_pkey'
}

/** input type for incrementing numeric columns in table "invoiceCoupon" */
export type InvoiceCoupon_Inc_Input = {
  amount?: InputMaybe<Scalars['float8']>;
};

/** input type for inserting data into table "invoiceCoupon" */
export type InvoiceCoupon_Insert_Input = {
  amount?: InputMaybe<Scalars['float8']>;
  coupon?: InputMaybe<Coupon_Obj_Rel_Insert_Input>;
  couponId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  expirationOverride?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['uuid']>;
  invoice?: InputMaybe<Invoice_Obj_Rel_Insert_Input>;
  invoiceId?: InputMaybe<Scalars['uuid']>;
  isMembershipCoupon?: InputMaybe<Scalars['Boolean']>;
  isSystemGenerated?: InputMaybe<Scalars['Boolean']>;
  packageId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type InvoiceCoupon_Max_Fields = {
  __typename?: 'invoiceCoupon_max_fields';
  amount?: Maybe<Scalars['float8']>;
  couponId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  invoiceId?: Maybe<Scalars['uuid']>;
  packageId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "invoiceCoupon" */
export type InvoiceCoupon_Max_Order_By = {
  amount?: InputMaybe<Order_By>;
  couponId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoiceId?: InputMaybe<Order_By>;
  packageId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type InvoiceCoupon_Min_Fields = {
  __typename?: 'invoiceCoupon_min_fields';
  amount?: Maybe<Scalars['float8']>;
  couponId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  invoiceId?: Maybe<Scalars['uuid']>;
  packageId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "invoiceCoupon" */
export type InvoiceCoupon_Min_Order_By = {
  amount?: InputMaybe<Order_By>;
  couponId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoiceId?: InputMaybe<Order_By>;
  packageId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "invoiceCoupon" */
export type InvoiceCoupon_Mutation_Response = {
  __typename?: 'invoiceCoupon_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<InvoiceCoupon>;
};

/** on_conflict condition type for table "invoiceCoupon" */
export type InvoiceCoupon_On_Conflict = {
  constraint: InvoiceCoupon_Constraint;
  update_columns?: Array<InvoiceCoupon_Update_Column>;
  where?: InputMaybe<InvoiceCoupon_Bool_Exp>;
};

/** Ordering options when selecting data from "invoiceCoupon". */
export type InvoiceCoupon_Order_By = {
  amount?: InputMaybe<Order_By>;
  coupon?: InputMaybe<Coupon_Order_By>;
  couponId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  expirationOverride?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoice?: InputMaybe<Invoice_Order_By>;
  invoiceId?: InputMaybe<Order_By>;
  isMembershipCoupon?: InputMaybe<Order_By>;
  isSystemGenerated?: InputMaybe<Order_By>;
  packageId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: invoiceCoupon */
export type InvoiceCoupon_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "invoiceCoupon" */
export enum InvoiceCoupon_Select_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  CouponId = 'couponId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  ExpirationOverride = 'expirationOverride',
  /** column name */
  Id = 'id',
  /** column name */
  InvoiceId = 'invoiceId',
  /** column name */
  IsMembershipCoupon = 'isMembershipCoupon',
  /** column name */
  IsSystemGenerated = 'isSystemGenerated',
  /** column name */
  PackageId = 'packageId',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** select "invoiceCoupon_aggregate_bool_exp_avg_arguments_columns" columns of table "invoiceCoupon" */
export enum InvoiceCoupon_Select_Column_InvoiceCoupon_Aggregate_Bool_Exp_Avg_Arguments_Columns {
  /** column name */
  Amount = 'amount'
}

/** select "invoiceCoupon_aggregate_bool_exp_bool_and_arguments_columns" columns of table "invoiceCoupon" */
export enum InvoiceCoupon_Select_Column_InvoiceCoupon_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  ExpirationOverride = 'expirationOverride',
  /** column name */
  IsMembershipCoupon = 'isMembershipCoupon',
  /** column name */
  IsSystemGenerated = 'isSystemGenerated'
}

/** select "invoiceCoupon_aggregate_bool_exp_bool_or_arguments_columns" columns of table "invoiceCoupon" */
export enum InvoiceCoupon_Select_Column_InvoiceCoupon_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  ExpirationOverride = 'expirationOverride',
  /** column name */
  IsMembershipCoupon = 'isMembershipCoupon',
  /** column name */
  IsSystemGenerated = 'isSystemGenerated'
}

/** select "invoiceCoupon_aggregate_bool_exp_corr_arguments_columns" columns of table "invoiceCoupon" */
export enum InvoiceCoupon_Select_Column_InvoiceCoupon_Aggregate_Bool_Exp_Corr_Arguments_Columns {
  /** column name */
  Amount = 'amount'
}

/** select "invoiceCoupon_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "invoiceCoupon" */
export enum InvoiceCoupon_Select_Column_InvoiceCoupon_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns {
  /** column name */
  Amount = 'amount'
}

/** select "invoiceCoupon_aggregate_bool_exp_max_arguments_columns" columns of table "invoiceCoupon" */
export enum InvoiceCoupon_Select_Column_InvoiceCoupon_Aggregate_Bool_Exp_Max_Arguments_Columns {
  /** column name */
  Amount = 'amount'
}

/** select "invoiceCoupon_aggregate_bool_exp_min_arguments_columns" columns of table "invoiceCoupon" */
export enum InvoiceCoupon_Select_Column_InvoiceCoupon_Aggregate_Bool_Exp_Min_Arguments_Columns {
  /** column name */
  Amount = 'amount'
}

/** select "invoiceCoupon_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "invoiceCoupon" */
export enum InvoiceCoupon_Select_Column_InvoiceCoupon_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns {
  /** column name */
  Amount = 'amount'
}

/** select "invoiceCoupon_aggregate_bool_exp_sum_arguments_columns" columns of table "invoiceCoupon" */
export enum InvoiceCoupon_Select_Column_InvoiceCoupon_Aggregate_Bool_Exp_Sum_Arguments_Columns {
  /** column name */
  Amount = 'amount'
}

/** select "invoiceCoupon_aggregate_bool_exp_var_samp_arguments_columns" columns of table "invoiceCoupon" */
export enum InvoiceCoupon_Select_Column_InvoiceCoupon_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns {
  /** column name */
  Amount = 'amount'
}

/** input type for updating data in table "invoiceCoupon" */
export type InvoiceCoupon_Set_Input = {
  amount?: InputMaybe<Scalars['float8']>;
  couponId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  expirationOverride?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['uuid']>;
  invoiceId?: InputMaybe<Scalars['uuid']>;
  isMembershipCoupon?: InputMaybe<Scalars['Boolean']>;
  isSystemGenerated?: InputMaybe<Scalars['Boolean']>;
  packageId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type InvoiceCoupon_Stddev_Fields = {
  __typename?: 'invoiceCoupon_stddev_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "invoiceCoupon" */
export type InvoiceCoupon_Stddev_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type InvoiceCoupon_Stddev_Pop_Fields = {
  __typename?: 'invoiceCoupon_stddev_pop_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "invoiceCoupon" */
export type InvoiceCoupon_Stddev_Pop_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type InvoiceCoupon_Stddev_Samp_Fields = {
  __typename?: 'invoiceCoupon_stddev_samp_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "invoiceCoupon" */
export type InvoiceCoupon_Stddev_Samp_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "invoiceCoupon" */
export type InvoiceCoupon_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: InvoiceCoupon_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type InvoiceCoupon_Stream_Cursor_Value_Input = {
  amount?: InputMaybe<Scalars['float8']>;
  couponId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  expirationOverride?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['uuid']>;
  invoiceId?: InputMaybe<Scalars['uuid']>;
  isMembershipCoupon?: InputMaybe<Scalars['Boolean']>;
  isSystemGenerated?: InputMaybe<Scalars['Boolean']>;
  packageId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type InvoiceCoupon_Sum_Fields = {
  __typename?: 'invoiceCoupon_sum_fields';
  amount?: Maybe<Scalars['float8']>;
};

/** order by sum() on columns of table "invoiceCoupon" */
export type InvoiceCoupon_Sum_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** update columns of table "invoiceCoupon" */
export enum InvoiceCoupon_Update_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  CouponId = 'couponId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  ExpirationOverride = 'expirationOverride',
  /** column name */
  Id = 'id',
  /** column name */
  InvoiceId = 'invoiceId',
  /** column name */
  IsMembershipCoupon = 'isMembershipCoupon',
  /** column name */
  IsSystemGenerated = 'isSystemGenerated',
  /** column name */
  PackageId = 'packageId',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type InvoiceCoupon_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<InvoiceCoupon_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<InvoiceCoupon_Set_Input>;
  /** filter the rows which have to be updated */
  where: InvoiceCoupon_Bool_Exp;
};

/** aggregate var_pop on columns */
export type InvoiceCoupon_Var_Pop_Fields = {
  __typename?: 'invoiceCoupon_var_pop_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "invoiceCoupon" */
export type InvoiceCoupon_Var_Pop_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type InvoiceCoupon_Var_Samp_Fields = {
  __typename?: 'invoiceCoupon_var_samp_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "invoiceCoupon" */
export type InvoiceCoupon_Var_Samp_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type InvoiceCoupon_Variance_Fields = {
  __typename?: 'invoiceCoupon_variance_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "invoiceCoupon" */
export type InvoiceCoupon_Variance_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** columns and relationships of "invoiceMembership" */
export type InvoiceMembership = {
  __typename?: 'invoiceMembership';
  createdAt: Scalars['timestamptz'];
  errorMessage?: Maybe<Scalars['String']>;
  errorStatus?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  /** An object relationship */
  invoice: Invoice;
  invoiceId: Scalars['uuid'];
  /** An object relationship */
  membership: Membership;
  membershipId: Scalars['uuid'];
  price: Scalars['Int'];
  retracted: Scalars['Boolean'];
  taxRate: Scalars['float8'];
  updatedAt: Scalars['timestamptz'];
};

/** aggregated selection of "invoiceMembership" */
export type InvoiceMembership_Aggregate = {
  __typename?: 'invoiceMembership_aggregate';
  aggregate?: Maybe<InvoiceMembership_Aggregate_Fields>;
  nodes: Array<InvoiceMembership>;
};

export type InvoiceMembership_Aggregate_Bool_Exp = {
  avg?: InputMaybe<InvoiceMembership_Aggregate_Bool_Exp_Avg>;
  bool_and?: InputMaybe<InvoiceMembership_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<InvoiceMembership_Aggregate_Bool_Exp_Bool_Or>;
  corr?: InputMaybe<InvoiceMembership_Aggregate_Bool_Exp_Corr>;
  count?: InputMaybe<InvoiceMembership_Aggregate_Bool_Exp_Count>;
  covar_samp?: InputMaybe<InvoiceMembership_Aggregate_Bool_Exp_Covar_Samp>;
  max?: InputMaybe<InvoiceMembership_Aggregate_Bool_Exp_Max>;
  min?: InputMaybe<InvoiceMembership_Aggregate_Bool_Exp_Min>;
  stddev_samp?: InputMaybe<InvoiceMembership_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: InputMaybe<InvoiceMembership_Aggregate_Bool_Exp_Sum>;
  var_samp?: InputMaybe<InvoiceMembership_Aggregate_Bool_Exp_Var_Samp>;
};

export type InvoiceMembership_Aggregate_Bool_Exp_Avg = {
  arguments: InvoiceMembership_Select_Column_InvoiceMembership_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<InvoiceMembership_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type InvoiceMembership_Aggregate_Bool_Exp_Bool_And = {
  arguments: InvoiceMembership_Select_Column_InvoiceMembership_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<InvoiceMembership_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type InvoiceMembership_Aggregate_Bool_Exp_Bool_Or = {
  arguments: InvoiceMembership_Select_Column_InvoiceMembership_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<InvoiceMembership_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type InvoiceMembership_Aggregate_Bool_Exp_Corr = {
  arguments: InvoiceMembership_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<InvoiceMembership_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type InvoiceMembership_Aggregate_Bool_Exp_Corr_Arguments = {
  X: InvoiceMembership_Select_Column_InvoiceMembership_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: InvoiceMembership_Select_Column_InvoiceMembership_Aggregate_Bool_Exp_Corr_Arguments_Columns;
};

export type InvoiceMembership_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<InvoiceMembership_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<InvoiceMembership_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type InvoiceMembership_Aggregate_Bool_Exp_Covar_Samp = {
  arguments: InvoiceMembership_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<InvoiceMembership_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type InvoiceMembership_Aggregate_Bool_Exp_Covar_Samp_Arguments = {
  X: InvoiceMembership_Select_Column_InvoiceMembership_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  Y: InvoiceMembership_Select_Column_InvoiceMembership_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
};

export type InvoiceMembership_Aggregate_Bool_Exp_Max = {
  arguments: InvoiceMembership_Select_Column_InvoiceMembership_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<InvoiceMembership_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type InvoiceMembership_Aggregate_Bool_Exp_Min = {
  arguments: InvoiceMembership_Select_Column_InvoiceMembership_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<InvoiceMembership_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type InvoiceMembership_Aggregate_Bool_Exp_Stddev_Samp = {
  arguments: InvoiceMembership_Select_Column_InvoiceMembership_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<InvoiceMembership_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type InvoiceMembership_Aggregate_Bool_Exp_Sum = {
  arguments: InvoiceMembership_Select_Column_InvoiceMembership_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<InvoiceMembership_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type InvoiceMembership_Aggregate_Bool_Exp_Var_Samp = {
  arguments: InvoiceMembership_Select_Column_InvoiceMembership_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<InvoiceMembership_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

/** aggregate fields of "invoiceMembership" */
export type InvoiceMembership_Aggregate_Fields = {
  __typename?: 'invoiceMembership_aggregate_fields';
  avg?: Maybe<InvoiceMembership_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<InvoiceMembership_Max_Fields>;
  min?: Maybe<InvoiceMembership_Min_Fields>;
  stddev?: Maybe<InvoiceMembership_Stddev_Fields>;
  stddev_pop?: Maybe<InvoiceMembership_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<InvoiceMembership_Stddev_Samp_Fields>;
  sum?: Maybe<InvoiceMembership_Sum_Fields>;
  var_pop?: Maybe<InvoiceMembership_Var_Pop_Fields>;
  var_samp?: Maybe<InvoiceMembership_Var_Samp_Fields>;
  variance?: Maybe<InvoiceMembership_Variance_Fields>;
};


/** aggregate fields of "invoiceMembership" */
export type InvoiceMembership_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<InvoiceMembership_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "invoiceMembership" */
export type InvoiceMembership_Aggregate_Order_By = {
  avg?: InputMaybe<InvoiceMembership_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<InvoiceMembership_Max_Order_By>;
  min?: InputMaybe<InvoiceMembership_Min_Order_By>;
  stddev?: InputMaybe<InvoiceMembership_Stddev_Order_By>;
  stddev_pop?: InputMaybe<InvoiceMembership_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<InvoiceMembership_Stddev_Samp_Order_By>;
  sum?: InputMaybe<InvoiceMembership_Sum_Order_By>;
  var_pop?: InputMaybe<InvoiceMembership_Var_Pop_Order_By>;
  var_samp?: InputMaybe<InvoiceMembership_Var_Samp_Order_By>;
  variance?: InputMaybe<InvoiceMembership_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "invoiceMembership" */
export type InvoiceMembership_Arr_Rel_Insert_Input = {
  data: Array<InvoiceMembership_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<InvoiceMembership_On_Conflict>;
};

/** aggregate avg on columns */
export type InvoiceMembership_Avg_Fields = {
  __typename?: 'invoiceMembership_avg_fields';
  price?: Maybe<Scalars['Float']>;
  taxRate?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "invoiceMembership" */
export type InvoiceMembership_Avg_Order_By = {
  price?: InputMaybe<Order_By>;
  taxRate?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "invoiceMembership". All fields are combined with a logical 'AND'. */
export type InvoiceMembership_Bool_Exp = {
  _and?: InputMaybe<Array<InvoiceMembership_Bool_Exp>>;
  _not?: InputMaybe<InvoiceMembership_Bool_Exp>;
  _or?: InputMaybe<Array<InvoiceMembership_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  errorMessage?: InputMaybe<String_Comparison_Exp>;
  errorStatus?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  invoice?: InputMaybe<Invoice_Bool_Exp>;
  invoiceId?: InputMaybe<Uuid_Comparison_Exp>;
  membership?: InputMaybe<Membership_Bool_Exp>;
  membershipId?: InputMaybe<Uuid_Comparison_Exp>;
  price?: InputMaybe<Int_Comparison_Exp>;
  retracted?: InputMaybe<Boolean_Comparison_Exp>;
  taxRate?: InputMaybe<Float8_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "invoiceMembership" */
export enum InvoiceMembership_Constraint {
  /** unique or primary key constraint on columns "membershipId", "invoiceId" */
  InvoiceMembershipInvoiceIdMembershipIdKey = 'invoiceMembership_invoiceId_membershipId_key',
  /** unique or primary key constraint on columns "id" */
  InvoiceMembershipPkey = 'invoiceMembership_pkey'
}

/** input type for incrementing numeric columns in table "invoiceMembership" */
export type InvoiceMembership_Inc_Input = {
  price?: InputMaybe<Scalars['Int']>;
  taxRate?: InputMaybe<Scalars['float8']>;
};

/** input type for inserting data into table "invoiceMembership" */
export type InvoiceMembership_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  errorMessage?: InputMaybe<Scalars['String']>;
  errorStatus?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  invoice?: InputMaybe<Invoice_Obj_Rel_Insert_Input>;
  invoiceId?: InputMaybe<Scalars['uuid']>;
  membership?: InputMaybe<Membership_Obj_Rel_Insert_Input>;
  membershipId?: InputMaybe<Scalars['uuid']>;
  price?: InputMaybe<Scalars['Int']>;
  retracted?: InputMaybe<Scalars['Boolean']>;
  taxRate?: InputMaybe<Scalars['float8']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type InvoiceMembership_Max_Fields = {
  __typename?: 'invoiceMembership_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  errorMessage?: Maybe<Scalars['String']>;
  errorStatus?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  invoiceId?: Maybe<Scalars['uuid']>;
  membershipId?: Maybe<Scalars['uuid']>;
  price?: Maybe<Scalars['Int']>;
  taxRate?: Maybe<Scalars['float8']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "invoiceMembership" */
export type InvoiceMembership_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  errorMessage?: InputMaybe<Order_By>;
  errorStatus?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoiceId?: InputMaybe<Order_By>;
  membershipId?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  taxRate?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type InvoiceMembership_Min_Fields = {
  __typename?: 'invoiceMembership_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  errorMessage?: Maybe<Scalars['String']>;
  errorStatus?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  invoiceId?: Maybe<Scalars['uuid']>;
  membershipId?: Maybe<Scalars['uuid']>;
  price?: Maybe<Scalars['Int']>;
  taxRate?: Maybe<Scalars['float8']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "invoiceMembership" */
export type InvoiceMembership_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  errorMessage?: InputMaybe<Order_By>;
  errorStatus?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoiceId?: InputMaybe<Order_By>;
  membershipId?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  taxRate?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "invoiceMembership" */
export type InvoiceMembership_Mutation_Response = {
  __typename?: 'invoiceMembership_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<InvoiceMembership>;
};

/** on_conflict condition type for table "invoiceMembership" */
export type InvoiceMembership_On_Conflict = {
  constraint: InvoiceMembership_Constraint;
  update_columns?: Array<InvoiceMembership_Update_Column>;
  where?: InputMaybe<InvoiceMembership_Bool_Exp>;
};

/** Ordering options when selecting data from "invoiceMembership". */
export type InvoiceMembership_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  errorMessage?: InputMaybe<Order_By>;
  errorStatus?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoice?: InputMaybe<Invoice_Order_By>;
  invoiceId?: InputMaybe<Order_By>;
  membership?: InputMaybe<Membership_Order_By>;
  membershipId?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  retracted?: InputMaybe<Order_By>;
  taxRate?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: invoiceMembership */
export type InvoiceMembership_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "invoiceMembership" */
export enum InvoiceMembership_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  ErrorMessage = 'errorMessage',
  /** column name */
  ErrorStatus = 'errorStatus',
  /** column name */
  Id = 'id',
  /** column name */
  InvoiceId = 'invoiceId',
  /** column name */
  MembershipId = 'membershipId',
  /** column name */
  Price = 'price',
  /** column name */
  Retracted = 'retracted',
  /** column name */
  TaxRate = 'taxRate',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** select "invoiceMembership_aggregate_bool_exp_avg_arguments_columns" columns of table "invoiceMembership" */
export enum InvoiceMembership_Select_Column_InvoiceMembership_Aggregate_Bool_Exp_Avg_Arguments_Columns {
  /** column name */
  TaxRate = 'taxRate'
}

/** select "invoiceMembership_aggregate_bool_exp_bool_and_arguments_columns" columns of table "invoiceMembership" */
export enum InvoiceMembership_Select_Column_InvoiceMembership_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Retracted = 'retracted'
}

/** select "invoiceMembership_aggregate_bool_exp_bool_or_arguments_columns" columns of table "invoiceMembership" */
export enum InvoiceMembership_Select_Column_InvoiceMembership_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Retracted = 'retracted'
}

/** select "invoiceMembership_aggregate_bool_exp_corr_arguments_columns" columns of table "invoiceMembership" */
export enum InvoiceMembership_Select_Column_InvoiceMembership_Aggregate_Bool_Exp_Corr_Arguments_Columns {
  /** column name */
  TaxRate = 'taxRate'
}

/** select "invoiceMembership_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "invoiceMembership" */
export enum InvoiceMembership_Select_Column_InvoiceMembership_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns {
  /** column name */
  TaxRate = 'taxRate'
}

/** select "invoiceMembership_aggregate_bool_exp_max_arguments_columns" columns of table "invoiceMembership" */
export enum InvoiceMembership_Select_Column_InvoiceMembership_Aggregate_Bool_Exp_Max_Arguments_Columns {
  /** column name */
  TaxRate = 'taxRate'
}

/** select "invoiceMembership_aggregate_bool_exp_min_arguments_columns" columns of table "invoiceMembership" */
export enum InvoiceMembership_Select_Column_InvoiceMembership_Aggregate_Bool_Exp_Min_Arguments_Columns {
  /** column name */
  TaxRate = 'taxRate'
}

/** select "invoiceMembership_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "invoiceMembership" */
export enum InvoiceMembership_Select_Column_InvoiceMembership_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns {
  /** column name */
  TaxRate = 'taxRate'
}

/** select "invoiceMembership_aggregate_bool_exp_sum_arguments_columns" columns of table "invoiceMembership" */
export enum InvoiceMembership_Select_Column_InvoiceMembership_Aggregate_Bool_Exp_Sum_Arguments_Columns {
  /** column name */
  TaxRate = 'taxRate'
}

/** select "invoiceMembership_aggregate_bool_exp_var_samp_arguments_columns" columns of table "invoiceMembership" */
export enum InvoiceMembership_Select_Column_InvoiceMembership_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns {
  /** column name */
  TaxRate = 'taxRate'
}

/** input type for updating data in table "invoiceMembership" */
export type InvoiceMembership_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  errorMessage?: InputMaybe<Scalars['String']>;
  errorStatus?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  invoiceId?: InputMaybe<Scalars['uuid']>;
  membershipId?: InputMaybe<Scalars['uuid']>;
  price?: InputMaybe<Scalars['Int']>;
  retracted?: InputMaybe<Scalars['Boolean']>;
  taxRate?: InputMaybe<Scalars['float8']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type InvoiceMembership_Stddev_Fields = {
  __typename?: 'invoiceMembership_stddev_fields';
  price?: Maybe<Scalars['Float']>;
  taxRate?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "invoiceMembership" */
export type InvoiceMembership_Stddev_Order_By = {
  price?: InputMaybe<Order_By>;
  taxRate?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type InvoiceMembership_Stddev_Pop_Fields = {
  __typename?: 'invoiceMembership_stddev_pop_fields';
  price?: Maybe<Scalars['Float']>;
  taxRate?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "invoiceMembership" */
export type InvoiceMembership_Stddev_Pop_Order_By = {
  price?: InputMaybe<Order_By>;
  taxRate?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type InvoiceMembership_Stddev_Samp_Fields = {
  __typename?: 'invoiceMembership_stddev_samp_fields';
  price?: Maybe<Scalars['Float']>;
  taxRate?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "invoiceMembership" */
export type InvoiceMembership_Stddev_Samp_Order_By = {
  price?: InputMaybe<Order_By>;
  taxRate?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "invoiceMembership" */
export type InvoiceMembership_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: InvoiceMembership_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type InvoiceMembership_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  errorMessage?: InputMaybe<Scalars['String']>;
  errorStatus?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  invoiceId?: InputMaybe<Scalars['uuid']>;
  membershipId?: InputMaybe<Scalars['uuid']>;
  price?: InputMaybe<Scalars['Int']>;
  retracted?: InputMaybe<Scalars['Boolean']>;
  taxRate?: InputMaybe<Scalars['float8']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type InvoiceMembership_Sum_Fields = {
  __typename?: 'invoiceMembership_sum_fields';
  price?: Maybe<Scalars['Int']>;
  taxRate?: Maybe<Scalars['float8']>;
};

/** order by sum() on columns of table "invoiceMembership" */
export type InvoiceMembership_Sum_Order_By = {
  price?: InputMaybe<Order_By>;
  taxRate?: InputMaybe<Order_By>;
};

/** update columns of table "invoiceMembership" */
export enum InvoiceMembership_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  ErrorMessage = 'errorMessage',
  /** column name */
  ErrorStatus = 'errorStatus',
  /** column name */
  Id = 'id',
  /** column name */
  InvoiceId = 'invoiceId',
  /** column name */
  MembershipId = 'membershipId',
  /** column name */
  Price = 'price',
  /** column name */
  Retracted = 'retracted',
  /** column name */
  TaxRate = 'taxRate',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type InvoiceMembership_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<InvoiceMembership_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<InvoiceMembership_Set_Input>;
  /** filter the rows which have to be updated */
  where: InvoiceMembership_Bool_Exp;
};

/** aggregate var_pop on columns */
export type InvoiceMembership_Var_Pop_Fields = {
  __typename?: 'invoiceMembership_var_pop_fields';
  price?: Maybe<Scalars['Float']>;
  taxRate?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "invoiceMembership" */
export type InvoiceMembership_Var_Pop_Order_By = {
  price?: InputMaybe<Order_By>;
  taxRate?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type InvoiceMembership_Var_Samp_Fields = {
  __typename?: 'invoiceMembership_var_samp_fields';
  price?: Maybe<Scalars['Float']>;
  taxRate?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "invoiceMembership" */
export type InvoiceMembership_Var_Samp_Order_By = {
  price?: InputMaybe<Order_By>;
  taxRate?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type InvoiceMembership_Variance_Fields = {
  __typename?: 'invoiceMembership_variance_fields';
  price?: Maybe<Scalars['Float']>;
  taxRate?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "invoiceMembership" */
export type InvoiceMembership_Variance_Order_By = {
  price?: InputMaybe<Order_By>;
  taxRate?: InputMaybe<Order_By>;
};

/** columns and relationships of "invoicePackage" */
export type InvoicePackage = {
  __typename?: 'invoicePackage';
  createdAt: Scalars['timestamp'];
  id: Scalars['uuid'];
  invoiceId: Scalars['uuid'];
  /** An object relationship */
  package: Package;
  packageId: Scalars['uuid'];
  price: Scalars['Int'];
  quantityToBuy: Scalars['Int'];
  quantityToUse: Scalars['Int'];
  retracted: Scalars['Boolean'];
  taxRate: Scalars['float8'];
  updatedAt: Scalars['timestamptz'];
};

/** aggregated selection of "invoicePackage" */
export type InvoicePackage_Aggregate = {
  __typename?: 'invoicePackage_aggregate';
  aggregate?: Maybe<InvoicePackage_Aggregate_Fields>;
  nodes: Array<InvoicePackage>;
};

export type InvoicePackage_Aggregate_Bool_Exp = {
  avg?: InputMaybe<InvoicePackage_Aggregate_Bool_Exp_Avg>;
  bool_and?: InputMaybe<InvoicePackage_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<InvoicePackage_Aggregate_Bool_Exp_Bool_Or>;
  corr?: InputMaybe<InvoicePackage_Aggregate_Bool_Exp_Corr>;
  count?: InputMaybe<InvoicePackage_Aggregate_Bool_Exp_Count>;
  covar_samp?: InputMaybe<InvoicePackage_Aggregate_Bool_Exp_Covar_Samp>;
  max?: InputMaybe<InvoicePackage_Aggregate_Bool_Exp_Max>;
  min?: InputMaybe<InvoicePackage_Aggregate_Bool_Exp_Min>;
  stddev_samp?: InputMaybe<InvoicePackage_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: InputMaybe<InvoicePackage_Aggregate_Bool_Exp_Sum>;
  var_samp?: InputMaybe<InvoicePackage_Aggregate_Bool_Exp_Var_Samp>;
};

export type InvoicePackage_Aggregate_Bool_Exp_Avg = {
  arguments: InvoicePackage_Select_Column_InvoicePackage_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<InvoicePackage_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type InvoicePackage_Aggregate_Bool_Exp_Bool_And = {
  arguments: InvoicePackage_Select_Column_InvoicePackage_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<InvoicePackage_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type InvoicePackage_Aggregate_Bool_Exp_Bool_Or = {
  arguments: InvoicePackage_Select_Column_InvoicePackage_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<InvoicePackage_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type InvoicePackage_Aggregate_Bool_Exp_Corr = {
  arguments: InvoicePackage_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<InvoicePackage_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type InvoicePackage_Aggregate_Bool_Exp_Corr_Arguments = {
  X: InvoicePackage_Select_Column_InvoicePackage_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: InvoicePackage_Select_Column_InvoicePackage_Aggregate_Bool_Exp_Corr_Arguments_Columns;
};

export type InvoicePackage_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<InvoicePackage_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<InvoicePackage_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type InvoicePackage_Aggregate_Bool_Exp_Covar_Samp = {
  arguments: InvoicePackage_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<InvoicePackage_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type InvoicePackage_Aggregate_Bool_Exp_Covar_Samp_Arguments = {
  X: InvoicePackage_Select_Column_InvoicePackage_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  Y: InvoicePackage_Select_Column_InvoicePackage_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
};

export type InvoicePackage_Aggregate_Bool_Exp_Max = {
  arguments: InvoicePackage_Select_Column_InvoicePackage_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<InvoicePackage_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type InvoicePackage_Aggregate_Bool_Exp_Min = {
  arguments: InvoicePackage_Select_Column_InvoicePackage_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<InvoicePackage_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type InvoicePackage_Aggregate_Bool_Exp_Stddev_Samp = {
  arguments: InvoicePackage_Select_Column_InvoicePackage_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<InvoicePackage_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type InvoicePackage_Aggregate_Bool_Exp_Sum = {
  arguments: InvoicePackage_Select_Column_InvoicePackage_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<InvoicePackage_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type InvoicePackage_Aggregate_Bool_Exp_Var_Samp = {
  arguments: InvoicePackage_Select_Column_InvoicePackage_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<InvoicePackage_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

/** aggregate fields of "invoicePackage" */
export type InvoicePackage_Aggregate_Fields = {
  __typename?: 'invoicePackage_aggregate_fields';
  avg?: Maybe<InvoicePackage_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<InvoicePackage_Max_Fields>;
  min?: Maybe<InvoicePackage_Min_Fields>;
  stddev?: Maybe<InvoicePackage_Stddev_Fields>;
  stddev_pop?: Maybe<InvoicePackage_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<InvoicePackage_Stddev_Samp_Fields>;
  sum?: Maybe<InvoicePackage_Sum_Fields>;
  var_pop?: Maybe<InvoicePackage_Var_Pop_Fields>;
  var_samp?: Maybe<InvoicePackage_Var_Samp_Fields>;
  variance?: Maybe<InvoicePackage_Variance_Fields>;
};


/** aggregate fields of "invoicePackage" */
export type InvoicePackage_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<InvoicePackage_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "invoicePackage" */
export type InvoicePackage_Aggregate_Order_By = {
  avg?: InputMaybe<InvoicePackage_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<InvoicePackage_Max_Order_By>;
  min?: InputMaybe<InvoicePackage_Min_Order_By>;
  stddev?: InputMaybe<InvoicePackage_Stddev_Order_By>;
  stddev_pop?: InputMaybe<InvoicePackage_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<InvoicePackage_Stddev_Samp_Order_By>;
  sum?: InputMaybe<InvoicePackage_Sum_Order_By>;
  var_pop?: InputMaybe<InvoicePackage_Var_Pop_Order_By>;
  var_samp?: InputMaybe<InvoicePackage_Var_Samp_Order_By>;
  variance?: InputMaybe<InvoicePackage_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "invoicePackage" */
export type InvoicePackage_Arr_Rel_Insert_Input = {
  data: Array<InvoicePackage_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<InvoicePackage_On_Conflict>;
};

/** aggregate avg on columns */
export type InvoicePackage_Avg_Fields = {
  __typename?: 'invoicePackage_avg_fields';
  price?: Maybe<Scalars['Float']>;
  quantityToBuy?: Maybe<Scalars['Float']>;
  quantityToUse?: Maybe<Scalars['Float']>;
  taxRate?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "invoicePackage" */
export type InvoicePackage_Avg_Order_By = {
  price?: InputMaybe<Order_By>;
  quantityToBuy?: InputMaybe<Order_By>;
  quantityToUse?: InputMaybe<Order_By>;
  taxRate?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "invoicePackage". All fields are combined with a logical 'AND'. */
export type InvoicePackage_Bool_Exp = {
  _and?: InputMaybe<Array<InvoicePackage_Bool_Exp>>;
  _not?: InputMaybe<InvoicePackage_Bool_Exp>;
  _or?: InputMaybe<Array<InvoicePackage_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  invoiceId?: InputMaybe<Uuid_Comparison_Exp>;
  package?: InputMaybe<Package_Bool_Exp>;
  packageId?: InputMaybe<Uuid_Comparison_Exp>;
  price?: InputMaybe<Int_Comparison_Exp>;
  quantityToBuy?: InputMaybe<Int_Comparison_Exp>;
  quantityToUse?: InputMaybe<Int_Comparison_Exp>;
  retracted?: InputMaybe<Boolean_Comparison_Exp>;
  taxRate?: InputMaybe<Float8_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "invoicePackage" */
export enum InvoicePackage_Constraint {
  /** unique or primary key constraint on columns "invoiceId", "packageId" */
  InvoicePackageInvoiceIdPackageIdKey = 'invoicePackage_invoiceId_packageId_key',
  /** unique or primary key constraint on columns "id" */
  InvoicePackagePkey = 'invoicePackage_pkey'
}

/** input type for incrementing numeric columns in table "invoicePackage" */
export type InvoicePackage_Inc_Input = {
  price?: InputMaybe<Scalars['Int']>;
  quantityToBuy?: InputMaybe<Scalars['Int']>;
  quantityToUse?: InputMaybe<Scalars['Int']>;
  taxRate?: InputMaybe<Scalars['float8']>;
};

/** input type for inserting data into table "invoicePackage" */
export type InvoicePackage_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['uuid']>;
  invoiceId?: InputMaybe<Scalars['uuid']>;
  package?: InputMaybe<Package_Obj_Rel_Insert_Input>;
  packageId?: InputMaybe<Scalars['uuid']>;
  price?: InputMaybe<Scalars['Int']>;
  quantityToBuy?: InputMaybe<Scalars['Int']>;
  quantityToUse?: InputMaybe<Scalars['Int']>;
  retracted?: InputMaybe<Scalars['Boolean']>;
  taxRate?: InputMaybe<Scalars['float8']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type InvoicePackage_Max_Fields = {
  __typename?: 'invoicePackage_max_fields';
  createdAt?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  invoiceId?: Maybe<Scalars['uuid']>;
  packageId?: Maybe<Scalars['uuid']>;
  price?: Maybe<Scalars['Int']>;
  quantityToBuy?: Maybe<Scalars['Int']>;
  quantityToUse?: Maybe<Scalars['Int']>;
  taxRate?: Maybe<Scalars['float8']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "invoicePackage" */
export type InvoicePackage_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoiceId?: InputMaybe<Order_By>;
  packageId?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  quantityToBuy?: InputMaybe<Order_By>;
  quantityToUse?: InputMaybe<Order_By>;
  taxRate?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type InvoicePackage_Min_Fields = {
  __typename?: 'invoicePackage_min_fields';
  createdAt?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  invoiceId?: Maybe<Scalars['uuid']>;
  packageId?: Maybe<Scalars['uuid']>;
  price?: Maybe<Scalars['Int']>;
  quantityToBuy?: Maybe<Scalars['Int']>;
  quantityToUse?: Maybe<Scalars['Int']>;
  taxRate?: Maybe<Scalars['float8']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "invoicePackage" */
export type InvoicePackage_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoiceId?: InputMaybe<Order_By>;
  packageId?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  quantityToBuy?: InputMaybe<Order_By>;
  quantityToUse?: InputMaybe<Order_By>;
  taxRate?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "invoicePackage" */
export type InvoicePackage_Mutation_Response = {
  __typename?: 'invoicePackage_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<InvoicePackage>;
};

/** on_conflict condition type for table "invoicePackage" */
export type InvoicePackage_On_Conflict = {
  constraint: InvoicePackage_Constraint;
  update_columns?: Array<InvoicePackage_Update_Column>;
  where?: InputMaybe<InvoicePackage_Bool_Exp>;
};

/** Ordering options when selecting data from "invoicePackage". */
export type InvoicePackage_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoiceId?: InputMaybe<Order_By>;
  package?: InputMaybe<Package_Order_By>;
  packageId?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  quantityToBuy?: InputMaybe<Order_By>;
  quantityToUse?: InputMaybe<Order_By>;
  retracted?: InputMaybe<Order_By>;
  taxRate?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: invoicePackage */
export type InvoicePackage_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "invoicePackage" */
export enum InvoicePackage_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  InvoiceId = 'invoiceId',
  /** column name */
  PackageId = 'packageId',
  /** column name */
  Price = 'price',
  /** column name */
  QuantityToBuy = 'quantityToBuy',
  /** column name */
  QuantityToUse = 'quantityToUse',
  /** column name */
  Retracted = 'retracted',
  /** column name */
  TaxRate = 'taxRate',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** select "invoicePackage_aggregate_bool_exp_avg_arguments_columns" columns of table "invoicePackage" */
export enum InvoicePackage_Select_Column_InvoicePackage_Aggregate_Bool_Exp_Avg_Arguments_Columns {
  /** column name */
  TaxRate = 'taxRate'
}

/** select "invoicePackage_aggregate_bool_exp_bool_and_arguments_columns" columns of table "invoicePackage" */
export enum InvoicePackage_Select_Column_InvoicePackage_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Retracted = 'retracted'
}

/** select "invoicePackage_aggregate_bool_exp_bool_or_arguments_columns" columns of table "invoicePackage" */
export enum InvoicePackage_Select_Column_InvoicePackage_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Retracted = 'retracted'
}

/** select "invoicePackage_aggregate_bool_exp_corr_arguments_columns" columns of table "invoicePackage" */
export enum InvoicePackage_Select_Column_InvoicePackage_Aggregate_Bool_Exp_Corr_Arguments_Columns {
  /** column name */
  TaxRate = 'taxRate'
}

/** select "invoicePackage_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "invoicePackage" */
export enum InvoicePackage_Select_Column_InvoicePackage_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns {
  /** column name */
  TaxRate = 'taxRate'
}

/** select "invoicePackage_aggregate_bool_exp_max_arguments_columns" columns of table "invoicePackage" */
export enum InvoicePackage_Select_Column_InvoicePackage_Aggregate_Bool_Exp_Max_Arguments_Columns {
  /** column name */
  TaxRate = 'taxRate'
}

/** select "invoicePackage_aggregate_bool_exp_min_arguments_columns" columns of table "invoicePackage" */
export enum InvoicePackage_Select_Column_InvoicePackage_Aggregate_Bool_Exp_Min_Arguments_Columns {
  /** column name */
  TaxRate = 'taxRate'
}

/** select "invoicePackage_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "invoicePackage" */
export enum InvoicePackage_Select_Column_InvoicePackage_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns {
  /** column name */
  TaxRate = 'taxRate'
}

/** select "invoicePackage_aggregate_bool_exp_sum_arguments_columns" columns of table "invoicePackage" */
export enum InvoicePackage_Select_Column_InvoicePackage_Aggregate_Bool_Exp_Sum_Arguments_Columns {
  /** column name */
  TaxRate = 'taxRate'
}

/** select "invoicePackage_aggregate_bool_exp_var_samp_arguments_columns" columns of table "invoicePackage" */
export enum InvoicePackage_Select_Column_InvoicePackage_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns {
  /** column name */
  TaxRate = 'taxRate'
}

/** input type for updating data in table "invoicePackage" */
export type InvoicePackage_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['uuid']>;
  invoiceId?: InputMaybe<Scalars['uuid']>;
  packageId?: InputMaybe<Scalars['uuid']>;
  price?: InputMaybe<Scalars['Int']>;
  quantityToBuy?: InputMaybe<Scalars['Int']>;
  quantityToUse?: InputMaybe<Scalars['Int']>;
  retracted?: InputMaybe<Scalars['Boolean']>;
  taxRate?: InputMaybe<Scalars['float8']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type InvoicePackage_Stddev_Fields = {
  __typename?: 'invoicePackage_stddev_fields';
  price?: Maybe<Scalars['Float']>;
  quantityToBuy?: Maybe<Scalars['Float']>;
  quantityToUse?: Maybe<Scalars['Float']>;
  taxRate?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "invoicePackage" */
export type InvoicePackage_Stddev_Order_By = {
  price?: InputMaybe<Order_By>;
  quantityToBuy?: InputMaybe<Order_By>;
  quantityToUse?: InputMaybe<Order_By>;
  taxRate?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type InvoicePackage_Stddev_Pop_Fields = {
  __typename?: 'invoicePackage_stddev_pop_fields';
  price?: Maybe<Scalars['Float']>;
  quantityToBuy?: Maybe<Scalars['Float']>;
  quantityToUse?: Maybe<Scalars['Float']>;
  taxRate?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "invoicePackage" */
export type InvoicePackage_Stddev_Pop_Order_By = {
  price?: InputMaybe<Order_By>;
  quantityToBuy?: InputMaybe<Order_By>;
  quantityToUse?: InputMaybe<Order_By>;
  taxRate?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type InvoicePackage_Stddev_Samp_Fields = {
  __typename?: 'invoicePackage_stddev_samp_fields';
  price?: Maybe<Scalars['Float']>;
  quantityToBuy?: Maybe<Scalars['Float']>;
  quantityToUse?: Maybe<Scalars['Float']>;
  taxRate?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "invoicePackage" */
export type InvoicePackage_Stddev_Samp_Order_By = {
  price?: InputMaybe<Order_By>;
  quantityToBuy?: InputMaybe<Order_By>;
  quantityToUse?: InputMaybe<Order_By>;
  taxRate?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "invoicePackage" */
export type InvoicePackage_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: InvoicePackage_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type InvoicePackage_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['uuid']>;
  invoiceId?: InputMaybe<Scalars['uuid']>;
  packageId?: InputMaybe<Scalars['uuid']>;
  price?: InputMaybe<Scalars['Int']>;
  quantityToBuy?: InputMaybe<Scalars['Int']>;
  quantityToUse?: InputMaybe<Scalars['Int']>;
  retracted?: InputMaybe<Scalars['Boolean']>;
  taxRate?: InputMaybe<Scalars['float8']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type InvoicePackage_Sum_Fields = {
  __typename?: 'invoicePackage_sum_fields';
  price?: Maybe<Scalars['Int']>;
  quantityToBuy?: Maybe<Scalars['Int']>;
  quantityToUse?: Maybe<Scalars['Int']>;
  taxRate?: Maybe<Scalars['float8']>;
};

/** order by sum() on columns of table "invoicePackage" */
export type InvoicePackage_Sum_Order_By = {
  price?: InputMaybe<Order_By>;
  quantityToBuy?: InputMaybe<Order_By>;
  quantityToUse?: InputMaybe<Order_By>;
  taxRate?: InputMaybe<Order_By>;
};

/** update columns of table "invoicePackage" */
export enum InvoicePackage_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  InvoiceId = 'invoiceId',
  /** column name */
  PackageId = 'packageId',
  /** column name */
  Price = 'price',
  /** column name */
  QuantityToBuy = 'quantityToBuy',
  /** column name */
  QuantityToUse = 'quantityToUse',
  /** column name */
  Retracted = 'retracted',
  /** column name */
  TaxRate = 'taxRate',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type InvoicePackage_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<InvoicePackage_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<InvoicePackage_Set_Input>;
  /** filter the rows which have to be updated */
  where: InvoicePackage_Bool_Exp;
};

/** aggregate var_pop on columns */
export type InvoicePackage_Var_Pop_Fields = {
  __typename?: 'invoicePackage_var_pop_fields';
  price?: Maybe<Scalars['Float']>;
  quantityToBuy?: Maybe<Scalars['Float']>;
  quantityToUse?: Maybe<Scalars['Float']>;
  taxRate?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "invoicePackage" */
export type InvoicePackage_Var_Pop_Order_By = {
  price?: InputMaybe<Order_By>;
  quantityToBuy?: InputMaybe<Order_By>;
  quantityToUse?: InputMaybe<Order_By>;
  taxRate?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type InvoicePackage_Var_Samp_Fields = {
  __typename?: 'invoicePackage_var_samp_fields';
  price?: Maybe<Scalars['Float']>;
  quantityToBuy?: Maybe<Scalars['Float']>;
  quantityToUse?: Maybe<Scalars['Float']>;
  taxRate?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "invoicePackage" */
export type InvoicePackage_Var_Samp_Order_By = {
  price?: InputMaybe<Order_By>;
  quantityToBuy?: InputMaybe<Order_By>;
  quantityToUse?: InputMaybe<Order_By>;
  taxRate?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type InvoicePackage_Variance_Fields = {
  __typename?: 'invoicePackage_variance_fields';
  price?: Maybe<Scalars['Float']>;
  quantityToBuy?: Maybe<Scalars['Float']>;
  quantityToUse?: Maybe<Scalars['Float']>;
  taxRate?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "invoicePackage" */
export type InvoicePackage_Variance_Order_By = {
  price?: InputMaybe<Order_By>;
  quantityToBuy?: InputMaybe<Order_By>;
  quantityToUse?: InputMaybe<Order_By>;
  taxRate?: InputMaybe<Order_By>;
};

/** columns and relationships of "invoiceProductItem" */
export type InvoiceProductItem = {
  __typename?: 'invoiceProductItem';
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An object relationship */
  invoice: Invoice;
  invoiceId: Scalars['uuid'];
  price: Scalars['Int'];
  pricePerUnit: Scalars['Int'];
  productId: Scalars['uuid'];
  quantity: Scalars['Int'];
  /** An object relationship */
  service: Service;
  serviceId: Scalars['uuid'];
  taxRate: Scalars['float8'];
  updatedAt: Scalars['timestamptz'];
};

/** aggregated selection of "invoiceProductItem" */
export type InvoiceProductItem_Aggregate = {
  __typename?: 'invoiceProductItem_aggregate';
  aggregate?: Maybe<InvoiceProductItem_Aggregate_Fields>;
  nodes: Array<InvoiceProductItem>;
};

export type InvoiceProductItem_Aggregate_Bool_Exp = {
  avg?: InputMaybe<InvoiceProductItem_Aggregate_Bool_Exp_Avg>;
  corr?: InputMaybe<InvoiceProductItem_Aggregate_Bool_Exp_Corr>;
  count?: InputMaybe<InvoiceProductItem_Aggregate_Bool_Exp_Count>;
  covar_samp?: InputMaybe<InvoiceProductItem_Aggregate_Bool_Exp_Covar_Samp>;
  max?: InputMaybe<InvoiceProductItem_Aggregate_Bool_Exp_Max>;
  min?: InputMaybe<InvoiceProductItem_Aggregate_Bool_Exp_Min>;
  stddev_samp?: InputMaybe<InvoiceProductItem_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: InputMaybe<InvoiceProductItem_Aggregate_Bool_Exp_Sum>;
  var_samp?: InputMaybe<InvoiceProductItem_Aggregate_Bool_Exp_Var_Samp>;
};

export type InvoiceProductItem_Aggregate_Bool_Exp_Avg = {
  arguments: InvoiceProductItem_Select_Column_InvoiceProductItem_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<InvoiceProductItem_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type InvoiceProductItem_Aggregate_Bool_Exp_Corr = {
  arguments: InvoiceProductItem_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<InvoiceProductItem_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type InvoiceProductItem_Aggregate_Bool_Exp_Corr_Arguments = {
  X: InvoiceProductItem_Select_Column_InvoiceProductItem_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: InvoiceProductItem_Select_Column_InvoiceProductItem_Aggregate_Bool_Exp_Corr_Arguments_Columns;
};

export type InvoiceProductItem_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<InvoiceProductItem_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<InvoiceProductItem_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type InvoiceProductItem_Aggregate_Bool_Exp_Covar_Samp = {
  arguments: InvoiceProductItem_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<InvoiceProductItem_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type InvoiceProductItem_Aggregate_Bool_Exp_Covar_Samp_Arguments = {
  X: InvoiceProductItem_Select_Column_InvoiceProductItem_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  Y: InvoiceProductItem_Select_Column_InvoiceProductItem_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
};

export type InvoiceProductItem_Aggregate_Bool_Exp_Max = {
  arguments: InvoiceProductItem_Select_Column_InvoiceProductItem_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<InvoiceProductItem_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type InvoiceProductItem_Aggregate_Bool_Exp_Min = {
  arguments: InvoiceProductItem_Select_Column_InvoiceProductItem_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<InvoiceProductItem_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type InvoiceProductItem_Aggregate_Bool_Exp_Stddev_Samp = {
  arguments: InvoiceProductItem_Select_Column_InvoiceProductItem_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<InvoiceProductItem_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type InvoiceProductItem_Aggregate_Bool_Exp_Sum = {
  arguments: InvoiceProductItem_Select_Column_InvoiceProductItem_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<InvoiceProductItem_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type InvoiceProductItem_Aggregate_Bool_Exp_Var_Samp = {
  arguments: InvoiceProductItem_Select_Column_InvoiceProductItem_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<InvoiceProductItem_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

/** aggregate fields of "invoiceProductItem" */
export type InvoiceProductItem_Aggregate_Fields = {
  __typename?: 'invoiceProductItem_aggregate_fields';
  avg?: Maybe<InvoiceProductItem_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<InvoiceProductItem_Max_Fields>;
  min?: Maybe<InvoiceProductItem_Min_Fields>;
  stddev?: Maybe<InvoiceProductItem_Stddev_Fields>;
  stddev_pop?: Maybe<InvoiceProductItem_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<InvoiceProductItem_Stddev_Samp_Fields>;
  sum?: Maybe<InvoiceProductItem_Sum_Fields>;
  var_pop?: Maybe<InvoiceProductItem_Var_Pop_Fields>;
  var_samp?: Maybe<InvoiceProductItem_Var_Samp_Fields>;
  variance?: Maybe<InvoiceProductItem_Variance_Fields>;
};


/** aggregate fields of "invoiceProductItem" */
export type InvoiceProductItem_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<InvoiceProductItem_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "invoiceProductItem" */
export type InvoiceProductItem_Aggregate_Order_By = {
  avg?: InputMaybe<InvoiceProductItem_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<InvoiceProductItem_Max_Order_By>;
  min?: InputMaybe<InvoiceProductItem_Min_Order_By>;
  stddev?: InputMaybe<InvoiceProductItem_Stddev_Order_By>;
  stddev_pop?: InputMaybe<InvoiceProductItem_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<InvoiceProductItem_Stddev_Samp_Order_By>;
  sum?: InputMaybe<InvoiceProductItem_Sum_Order_By>;
  var_pop?: InputMaybe<InvoiceProductItem_Var_Pop_Order_By>;
  var_samp?: InputMaybe<InvoiceProductItem_Var_Samp_Order_By>;
  variance?: InputMaybe<InvoiceProductItem_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "invoiceProductItem" */
export type InvoiceProductItem_Arr_Rel_Insert_Input = {
  data: Array<InvoiceProductItem_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<InvoiceProductItem_On_Conflict>;
};

/** aggregate avg on columns */
export type InvoiceProductItem_Avg_Fields = {
  __typename?: 'invoiceProductItem_avg_fields';
  price?: Maybe<Scalars['Float']>;
  pricePerUnit?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  taxRate?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "invoiceProductItem" */
export type InvoiceProductItem_Avg_Order_By = {
  price?: InputMaybe<Order_By>;
  pricePerUnit?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  taxRate?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "invoiceProductItem". All fields are combined with a logical 'AND'. */
export type InvoiceProductItem_Bool_Exp = {
  _and?: InputMaybe<Array<InvoiceProductItem_Bool_Exp>>;
  _not?: InputMaybe<InvoiceProductItem_Bool_Exp>;
  _or?: InputMaybe<Array<InvoiceProductItem_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  invoice?: InputMaybe<Invoice_Bool_Exp>;
  invoiceId?: InputMaybe<Uuid_Comparison_Exp>;
  price?: InputMaybe<Int_Comparison_Exp>;
  pricePerUnit?: InputMaybe<Int_Comparison_Exp>;
  productId?: InputMaybe<Uuid_Comparison_Exp>;
  quantity?: InputMaybe<Int_Comparison_Exp>;
  service?: InputMaybe<Service_Bool_Exp>;
  serviceId?: InputMaybe<Uuid_Comparison_Exp>;
  taxRate?: InputMaybe<Float8_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "invoiceProductItem" */
export enum InvoiceProductItem_Constraint {
  /** unique or primary key constraint on columns "id" */
  InvoiceProductItemPkey = 'invoiceProductItem_pkey'
}

/** input type for incrementing numeric columns in table "invoiceProductItem" */
export type InvoiceProductItem_Inc_Input = {
  price?: InputMaybe<Scalars['Int']>;
  pricePerUnit?: InputMaybe<Scalars['Int']>;
  quantity?: InputMaybe<Scalars['Int']>;
  taxRate?: InputMaybe<Scalars['float8']>;
};

/** input type for inserting data into table "invoiceProductItem" */
export type InvoiceProductItem_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  invoice?: InputMaybe<Invoice_Obj_Rel_Insert_Input>;
  invoiceId?: InputMaybe<Scalars['uuid']>;
  price?: InputMaybe<Scalars['Int']>;
  pricePerUnit?: InputMaybe<Scalars['Int']>;
  productId?: InputMaybe<Scalars['uuid']>;
  quantity?: InputMaybe<Scalars['Int']>;
  service?: InputMaybe<Service_Obj_Rel_Insert_Input>;
  serviceId?: InputMaybe<Scalars['uuid']>;
  taxRate?: InputMaybe<Scalars['float8']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type InvoiceProductItem_Max_Fields = {
  __typename?: 'invoiceProductItem_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  invoiceId?: Maybe<Scalars['uuid']>;
  price?: Maybe<Scalars['Int']>;
  pricePerUnit?: Maybe<Scalars['Int']>;
  productId?: Maybe<Scalars['uuid']>;
  quantity?: Maybe<Scalars['Int']>;
  serviceId?: Maybe<Scalars['uuid']>;
  taxRate?: Maybe<Scalars['float8']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "invoiceProductItem" */
export type InvoiceProductItem_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoiceId?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  pricePerUnit?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  serviceId?: InputMaybe<Order_By>;
  taxRate?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type InvoiceProductItem_Min_Fields = {
  __typename?: 'invoiceProductItem_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  invoiceId?: Maybe<Scalars['uuid']>;
  price?: Maybe<Scalars['Int']>;
  pricePerUnit?: Maybe<Scalars['Int']>;
  productId?: Maybe<Scalars['uuid']>;
  quantity?: Maybe<Scalars['Int']>;
  serviceId?: Maybe<Scalars['uuid']>;
  taxRate?: Maybe<Scalars['float8']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "invoiceProductItem" */
export type InvoiceProductItem_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoiceId?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  pricePerUnit?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  serviceId?: InputMaybe<Order_By>;
  taxRate?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "invoiceProductItem" */
export type InvoiceProductItem_Mutation_Response = {
  __typename?: 'invoiceProductItem_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<InvoiceProductItem>;
};

/** on_conflict condition type for table "invoiceProductItem" */
export type InvoiceProductItem_On_Conflict = {
  constraint: InvoiceProductItem_Constraint;
  update_columns?: Array<InvoiceProductItem_Update_Column>;
  where?: InputMaybe<InvoiceProductItem_Bool_Exp>;
};

/** Ordering options when selecting data from "invoiceProductItem". */
export type InvoiceProductItem_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoice?: InputMaybe<Invoice_Order_By>;
  invoiceId?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  pricePerUnit?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  service?: InputMaybe<Service_Order_By>;
  serviceId?: InputMaybe<Order_By>;
  taxRate?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: invoiceProductItem */
export type InvoiceProductItem_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "invoiceProductItem" */
export enum InvoiceProductItem_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  InvoiceId = 'invoiceId',
  /** column name */
  Price = 'price',
  /** column name */
  PricePerUnit = 'pricePerUnit',
  /** column name */
  ProductId = 'productId',
  /** column name */
  Quantity = 'quantity',
  /** column name */
  ServiceId = 'serviceId',
  /** column name */
  TaxRate = 'taxRate',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** select "invoiceProductItem_aggregate_bool_exp_avg_arguments_columns" columns of table "invoiceProductItem" */
export enum InvoiceProductItem_Select_Column_InvoiceProductItem_Aggregate_Bool_Exp_Avg_Arguments_Columns {
  /** column name */
  TaxRate = 'taxRate'
}

/** select "invoiceProductItem_aggregate_bool_exp_corr_arguments_columns" columns of table "invoiceProductItem" */
export enum InvoiceProductItem_Select_Column_InvoiceProductItem_Aggregate_Bool_Exp_Corr_Arguments_Columns {
  /** column name */
  TaxRate = 'taxRate'
}

/** select "invoiceProductItem_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "invoiceProductItem" */
export enum InvoiceProductItem_Select_Column_InvoiceProductItem_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns {
  /** column name */
  TaxRate = 'taxRate'
}

/** select "invoiceProductItem_aggregate_bool_exp_max_arguments_columns" columns of table "invoiceProductItem" */
export enum InvoiceProductItem_Select_Column_InvoiceProductItem_Aggregate_Bool_Exp_Max_Arguments_Columns {
  /** column name */
  TaxRate = 'taxRate'
}

/** select "invoiceProductItem_aggregate_bool_exp_min_arguments_columns" columns of table "invoiceProductItem" */
export enum InvoiceProductItem_Select_Column_InvoiceProductItem_Aggregate_Bool_Exp_Min_Arguments_Columns {
  /** column name */
  TaxRate = 'taxRate'
}

/** select "invoiceProductItem_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "invoiceProductItem" */
export enum InvoiceProductItem_Select_Column_InvoiceProductItem_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns {
  /** column name */
  TaxRate = 'taxRate'
}

/** select "invoiceProductItem_aggregate_bool_exp_sum_arguments_columns" columns of table "invoiceProductItem" */
export enum InvoiceProductItem_Select_Column_InvoiceProductItem_Aggregate_Bool_Exp_Sum_Arguments_Columns {
  /** column name */
  TaxRate = 'taxRate'
}

/** select "invoiceProductItem_aggregate_bool_exp_var_samp_arguments_columns" columns of table "invoiceProductItem" */
export enum InvoiceProductItem_Select_Column_InvoiceProductItem_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns {
  /** column name */
  TaxRate = 'taxRate'
}

/** input type for updating data in table "invoiceProductItem" */
export type InvoiceProductItem_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  invoiceId?: InputMaybe<Scalars['uuid']>;
  price?: InputMaybe<Scalars['Int']>;
  pricePerUnit?: InputMaybe<Scalars['Int']>;
  productId?: InputMaybe<Scalars['uuid']>;
  quantity?: InputMaybe<Scalars['Int']>;
  serviceId?: InputMaybe<Scalars['uuid']>;
  taxRate?: InputMaybe<Scalars['float8']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type InvoiceProductItem_Stddev_Fields = {
  __typename?: 'invoiceProductItem_stddev_fields';
  price?: Maybe<Scalars['Float']>;
  pricePerUnit?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  taxRate?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "invoiceProductItem" */
export type InvoiceProductItem_Stddev_Order_By = {
  price?: InputMaybe<Order_By>;
  pricePerUnit?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  taxRate?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type InvoiceProductItem_Stddev_Pop_Fields = {
  __typename?: 'invoiceProductItem_stddev_pop_fields';
  price?: Maybe<Scalars['Float']>;
  pricePerUnit?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  taxRate?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "invoiceProductItem" */
export type InvoiceProductItem_Stddev_Pop_Order_By = {
  price?: InputMaybe<Order_By>;
  pricePerUnit?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  taxRate?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type InvoiceProductItem_Stddev_Samp_Fields = {
  __typename?: 'invoiceProductItem_stddev_samp_fields';
  price?: Maybe<Scalars['Float']>;
  pricePerUnit?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  taxRate?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "invoiceProductItem" */
export type InvoiceProductItem_Stddev_Samp_Order_By = {
  price?: InputMaybe<Order_By>;
  pricePerUnit?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  taxRate?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "invoiceProductItem" */
export type InvoiceProductItem_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: InvoiceProductItem_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type InvoiceProductItem_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  invoiceId?: InputMaybe<Scalars['uuid']>;
  price?: InputMaybe<Scalars['Int']>;
  pricePerUnit?: InputMaybe<Scalars['Int']>;
  productId?: InputMaybe<Scalars['uuid']>;
  quantity?: InputMaybe<Scalars['Int']>;
  serviceId?: InputMaybe<Scalars['uuid']>;
  taxRate?: InputMaybe<Scalars['float8']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type InvoiceProductItem_Sum_Fields = {
  __typename?: 'invoiceProductItem_sum_fields';
  price?: Maybe<Scalars['Int']>;
  pricePerUnit?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
  taxRate?: Maybe<Scalars['float8']>;
};

/** order by sum() on columns of table "invoiceProductItem" */
export type InvoiceProductItem_Sum_Order_By = {
  price?: InputMaybe<Order_By>;
  pricePerUnit?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  taxRate?: InputMaybe<Order_By>;
};

/** update columns of table "invoiceProductItem" */
export enum InvoiceProductItem_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  InvoiceId = 'invoiceId',
  /** column name */
  Price = 'price',
  /** column name */
  PricePerUnit = 'pricePerUnit',
  /** column name */
  ProductId = 'productId',
  /** column name */
  Quantity = 'quantity',
  /** column name */
  ServiceId = 'serviceId',
  /** column name */
  TaxRate = 'taxRate',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type InvoiceProductItem_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<InvoiceProductItem_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<InvoiceProductItem_Set_Input>;
  /** filter the rows which have to be updated */
  where: InvoiceProductItem_Bool_Exp;
};

/** aggregate var_pop on columns */
export type InvoiceProductItem_Var_Pop_Fields = {
  __typename?: 'invoiceProductItem_var_pop_fields';
  price?: Maybe<Scalars['Float']>;
  pricePerUnit?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  taxRate?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "invoiceProductItem" */
export type InvoiceProductItem_Var_Pop_Order_By = {
  price?: InputMaybe<Order_By>;
  pricePerUnit?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  taxRate?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type InvoiceProductItem_Var_Samp_Fields = {
  __typename?: 'invoiceProductItem_var_samp_fields';
  price?: Maybe<Scalars['Float']>;
  pricePerUnit?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  taxRate?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "invoiceProductItem" */
export type InvoiceProductItem_Var_Samp_Order_By = {
  price?: InputMaybe<Order_By>;
  pricePerUnit?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  taxRate?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type InvoiceProductItem_Variance_Fields = {
  __typename?: 'invoiceProductItem_variance_fields';
  price?: Maybe<Scalars['Float']>;
  pricePerUnit?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  taxRate?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "invoiceProductItem" */
export type InvoiceProductItem_Variance_Order_By = {
  price?: InputMaybe<Order_By>;
  pricePerUnit?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  taxRate?: InputMaybe<Order_By>;
};

/** columns and relationships of "invoiceServiceConsumable" */
export type InvoiceServiceConsumable = {
  __typename?: 'invoiceServiceConsumable';
  /** An object relationship */
  consumable: Consumable;
  consumableId: Scalars['uuid'];
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An object relationship */
  invoice: Invoice;
  invoiceId: Scalars['uuid'];
  quantity: Scalars['float8'];
  /** An object relationship */
  service: Service;
  serviceId: Scalars['uuid'];
  updatedAt: Scalars['timestamptz'];
};

/** aggregated selection of "invoiceServiceConsumable" */
export type InvoiceServiceConsumable_Aggregate = {
  __typename?: 'invoiceServiceConsumable_aggregate';
  aggregate?: Maybe<InvoiceServiceConsumable_Aggregate_Fields>;
  nodes: Array<InvoiceServiceConsumable>;
};

export type InvoiceServiceConsumable_Aggregate_Bool_Exp = {
  avg?: InputMaybe<InvoiceServiceConsumable_Aggregate_Bool_Exp_Avg>;
  corr?: InputMaybe<InvoiceServiceConsumable_Aggregate_Bool_Exp_Corr>;
  count?: InputMaybe<InvoiceServiceConsumable_Aggregate_Bool_Exp_Count>;
  covar_samp?: InputMaybe<InvoiceServiceConsumable_Aggregate_Bool_Exp_Covar_Samp>;
  max?: InputMaybe<InvoiceServiceConsumable_Aggregate_Bool_Exp_Max>;
  min?: InputMaybe<InvoiceServiceConsumable_Aggregate_Bool_Exp_Min>;
  stddev_samp?: InputMaybe<InvoiceServiceConsumable_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: InputMaybe<InvoiceServiceConsumable_Aggregate_Bool_Exp_Sum>;
  var_samp?: InputMaybe<InvoiceServiceConsumable_Aggregate_Bool_Exp_Var_Samp>;
};

export type InvoiceServiceConsumable_Aggregate_Bool_Exp_Avg = {
  arguments: InvoiceServiceConsumable_Select_Column_InvoiceServiceConsumable_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<InvoiceServiceConsumable_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type InvoiceServiceConsumable_Aggregate_Bool_Exp_Corr = {
  arguments: InvoiceServiceConsumable_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<InvoiceServiceConsumable_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type InvoiceServiceConsumable_Aggregate_Bool_Exp_Corr_Arguments = {
  X: InvoiceServiceConsumable_Select_Column_InvoiceServiceConsumable_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: InvoiceServiceConsumable_Select_Column_InvoiceServiceConsumable_Aggregate_Bool_Exp_Corr_Arguments_Columns;
};

export type InvoiceServiceConsumable_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<InvoiceServiceConsumable_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<InvoiceServiceConsumable_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type InvoiceServiceConsumable_Aggregate_Bool_Exp_Covar_Samp = {
  arguments: InvoiceServiceConsumable_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<InvoiceServiceConsumable_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type InvoiceServiceConsumable_Aggregate_Bool_Exp_Covar_Samp_Arguments = {
  X: InvoiceServiceConsumable_Select_Column_InvoiceServiceConsumable_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  Y: InvoiceServiceConsumable_Select_Column_InvoiceServiceConsumable_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
};

export type InvoiceServiceConsumable_Aggregate_Bool_Exp_Max = {
  arguments: InvoiceServiceConsumable_Select_Column_InvoiceServiceConsumable_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<InvoiceServiceConsumable_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type InvoiceServiceConsumable_Aggregate_Bool_Exp_Min = {
  arguments: InvoiceServiceConsumable_Select_Column_InvoiceServiceConsumable_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<InvoiceServiceConsumable_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type InvoiceServiceConsumable_Aggregate_Bool_Exp_Stddev_Samp = {
  arguments: InvoiceServiceConsumable_Select_Column_InvoiceServiceConsumable_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<InvoiceServiceConsumable_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type InvoiceServiceConsumable_Aggregate_Bool_Exp_Sum = {
  arguments: InvoiceServiceConsumable_Select_Column_InvoiceServiceConsumable_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<InvoiceServiceConsumable_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type InvoiceServiceConsumable_Aggregate_Bool_Exp_Var_Samp = {
  arguments: InvoiceServiceConsumable_Select_Column_InvoiceServiceConsumable_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<InvoiceServiceConsumable_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

/** aggregate fields of "invoiceServiceConsumable" */
export type InvoiceServiceConsumable_Aggregate_Fields = {
  __typename?: 'invoiceServiceConsumable_aggregate_fields';
  avg?: Maybe<InvoiceServiceConsumable_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<InvoiceServiceConsumable_Max_Fields>;
  min?: Maybe<InvoiceServiceConsumable_Min_Fields>;
  stddev?: Maybe<InvoiceServiceConsumable_Stddev_Fields>;
  stddev_pop?: Maybe<InvoiceServiceConsumable_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<InvoiceServiceConsumable_Stddev_Samp_Fields>;
  sum?: Maybe<InvoiceServiceConsumable_Sum_Fields>;
  var_pop?: Maybe<InvoiceServiceConsumable_Var_Pop_Fields>;
  var_samp?: Maybe<InvoiceServiceConsumable_Var_Samp_Fields>;
  variance?: Maybe<InvoiceServiceConsumable_Variance_Fields>;
};


/** aggregate fields of "invoiceServiceConsumable" */
export type InvoiceServiceConsumable_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<InvoiceServiceConsumable_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "invoiceServiceConsumable" */
export type InvoiceServiceConsumable_Aggregate_Order_By = {
  avg?: InputMaybe<InvoiceServiceConsumable_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<InvoiceServiceConsumable_Max_Order_By>;
  min?: InputMaybe<InvoiceServiceConsumable_Min_Order_By>;
  stddev?: InputMaybe<InvoiceServiceConsumable_Stddev_Order_By>;
  stddev_pop?: InputMaybe<InvoiceServiceConsumable_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<InvoiceServiceConsumable_Stddev_Samp_Order_By>;
  sum?: InputMaybe<InvoiceServiceConsumable_Sum_Order_By>;
  var_pop?: InputMaybe<InvoiceServiceConsumable_Var_Pop_Order_By>;
  var_samp?: InputMaybe<InvoiceServiceConsumable_Var_Samp_Order_By>;
  variance?: InputMaybe<InvoiceServiceConsumable_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "invoiceServiceConsumable" */
export type InvoiceServiceConsumable_Arr_Rel_Insert_Input = {
  data: Array<InvoiceServiceConsumable_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<InvoiceServiceConsumable_On_Conflict>;
};

/** aggregate avg on columns */
export type InvoiceServiceConsumable_Avg_Fields = {
  __typename?: 'invoiceServiceConsumable_avg_fields';
  quantity?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "invoiceServiceConsumable" */
export type InvoiceServiceConsumable_Avg_Order_By = {
  quantity?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "invoiceServiceConsumable". All fields are combined with a logical 'AND'. */
export type InvoiceServiceConsumable_Bool_Exp = {
  _and?: InputMaybe<Array<InvoiceServiceConsumable_Bool_Exp>>;
  _not?: InputMaybe<InvoiceServiceConsumable_Bool_Exp>;
  _or?: InputMaybe<Array<InvoiceServiceConsumable_Bool_Exp>>;
  consumable?: InputMaybe<Consumable_Bool_Exp>;
  consumableId?: InputMaybe<Uuid_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  invoice?: InputMaybe<Invoice_Bool_Exp>;
  invoiceId?: InputMaybe<Uuid_Comparison_Exp>;
  quantity?: InputMaybe<Float8_Comparison_Exp>;
  service?: InputMaybe<Service_Bool_Exp>;
  serviceId?: InputMaybe<Uuid_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "invoiceServiceConsumable" */
export enum InvoiceServiceConsumable_Constraint {
  /** unique or primary key constraint on columns "id" */
  InvoiceServiceConsumablePkey = 'invoiceServiceConsumable_pkey'
}

/** input type for incrementing numeric columns in table "invoiceServiceConsumable" */
export type InvoiceServiceConsumable_Inc_Input = {
  quantity?: InputMaybe<Scalars['float8']>;
};

/** input type for inserting data into table "invoiceServiceConsumable" */
export type InvoiceServiceConsumable_Insert_Input = {
  consumable?: InputMaybe<Consumable_Obj_Rel_Insert_Input>;
  consumableId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  invoice?: InputMaybe<Invoice_Obj_Rel_Insert_Input>;
  invoiceId?: InputMaybe<Scalars['uuid']>;
  quantity?: InputMaybe<Scalars['float8']>;
  service?: InputMaybe<Service_Obj_Rel_Insert_Input>;
  serviceId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type InvoiceServiceConsumable_Max_Fields = {
  __typename?: 'invoiceServiceConsumable_max_fields';
  consumableId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  invoiceId?: Maybe<Scalars['uuid']>;
  quantity?: Maybe<Scalars['float8']>;
  serviceId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "invoiceServiceConsumable" */
export type InvoiceServiceConsumable_Max_Order_By = {
  consumableId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoiceId?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  serviceId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type InvoiceServiceConsumable_Min_Fields = {
  __typename?: 'invoiceServiceConsumable_min_fields';
  consumableId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  invoiceId?: Maybe<Scalars['uuid']>;
  quantity?: Maybe<Scalars['float8']>;
  serviceId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "invoiceServiceConsumable" */
export type InvoiceServiceConsumable_Min_Order_By = {
  consumableId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoiceId?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  serviceId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "invoiceServiceConsumable" */
export type InvoiceServiceConsumable_Mutation_Response = {
  __typename?: 'invoiceServiceConsumable_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<InvoiceServiceConsumable>;
};

/** on_conflict condition type for table "invoiceServiceConsumable" */
export type InvoiceServiceConsumable_On_Conflict = {
  constraint: InvoiceServiceConsumable_Constraint;
  update_columns?: Array<InvoiceServiceConsumable_Update_Column>;
  where?: InputMaybe<InvoiceServiceConsumable_Bool_Exp>;
};

/** Ordering options when selecting data from "invoiceServiceConsumable". */
export type InvoiceServiceConsumable_Order_By = {
  consumable?: InputMaybe<Consumable_Order_By>;
  consumableId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoice?: InputMaybe<Invoice_Order_By>;
  invoiceId?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  service?: InputMaybe<Service_Order_By>;
  serviceId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: invoiceServiceConsumable */
export type InvoiceServiceConsumable_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "invoiceServiceConsumable" */
export enum InvoiceServiceConsumable_Select_Column {
  /** column name */
  ConsumableId = 'consumableId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  InvoiceId = 'invoiceId',
  /** column name */
  Quantity = 'quantity',
  /** column name */
  ServiceId = 'serviceId',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** select "invoiceServiceConsumable_aggregate_bool_exp_avg_arguments_columns" columns of table "invoiceServiceConsumable" */
export enum InvoiceServiceConsumable_Select_Column_InvoiceServiceConsumable_Aggregate_Bool_Exp_Avg_Arguments_Columns {
  /** column name */
  Quantity = 'quantity'
}

/** select "invoiceServiceConsumable_aggregate_bool_exp_corr_arguments_columns" columns of table "invoiceServiceConsumable" */
export enum InvoiceServiceConsumable_Select_Column_InvoiceServiceConsumable_Aggregate_Bool_Exp_Corr_Arguments_Columns {
  /** column name */
  Quantity = 'quantity'
}

/** select "invoiceServiceConsumable_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "invoiceServiceConsumable" */
export enum InvoiceServiceConsumable_Select_Column_InvoiceServiceConsumable_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns {
  /** column name */
  Quantity = 'quantity'
}

/** select "invoiceServiceConsumable_aggregate_bool_exp_max_arguments_columns" columns of table "invoiceServiceConsumable" */
export enum InvoiceServiceConsumable_Select_Column_InvoiceServiceConsumable_Aggregate_Bool_Exp_Max_Arguments_Columns {
  /** column name */
  Quantity = 'quantity'
}

/** select "invoiceServiceConsumable_aggregate_bool_exp_min_arguments_columns" columns of table "invoiceServiceConsumable" */
export enum InvoiceServiceConsumable_Select_Column_InvoiceServiceConsumable_Aggregate_Bool_Exp_Min_Arguments_Columns {
  /** column name */
  Quantity = 'quantity'
}

/** select "invoiceServiceConsumable_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "invoiceServiceConsumable" */
export enum InvoiceServiceConsumable_Select_Column_InvoiceServiceConsumable_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns {
  /** column name */
  Quantity = 'quantity'
}

/** select "invoiceServiceConsumable_aggregate_bool_exp_sum_arguments_columns" columns of table "invoiceServiceConsumable" */
export enum InvoiceServiceConsumable_Select_Column_InvoiceServiceConsumable_Aggregate_Bool_Exp_Sum_Arguments_Columns {
  /** column name */
  Quantity = 'quantity'
}

/** select "invoiceServiceConsumable_aggregate_bool_exp_var_samp_arguments_columns" columns of table "invoiceServiceConsumable" */
export enum InvoiceServiceConsumable_Select_Column_InvoiceServiceConsumable_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns {
  /** column name */
  Quantity = 'quantity'
}

/** input type for updating data in table "invoiceServiceConsumable" */
export type InvoiceServiceConsumable_Set_Input = {
  consumableId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  invoiceId?: InputMaybe<Scalars['uuid']>;
  quantity?: InputMaybe<Scalars['float8']>;
  serviceId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type InvoiceServiceConsumable_Stddev_Fields = {
  __typename?: 'invoiceServiceConsumable_stddev_fields';
  quantity?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "invoiceServiceConsumable" */
export type InvoiceServiceConsumable_Stddev_Order_By = {
  quantity?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type InvoiceServiceConsumable_Stddev_Pop_Fields = {
  __typename?: 'invoiceServiceConsumable_stddev_pop_fields';
  quantity?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "invoiceServiceConsumable" */
export type InvoiceServiceConsumable_Stddev_Pop_Order_By = {
  quantity?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type InvoiceServiceConsumable_Stddev_Samp_Fields = {
  __typename?: 'invoiceServiceConsumable_stddev_samp_fields';
  quantity?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "invoiceServiceConsumable" */
export type InvoiceServiceConsumable_Stddev_Samp_Order_By = {
  quantity?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "invoiceServiceConsumable" */
export type InvoiceServiceConsumable_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: InvoiceServiceConsumable_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type InvoiceServiceConsumable_Stream_Cursor_Value_Input = {
  consumableId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  invoiceId?: InputMaybe<Scalars['uuid']>;
  quantity?: InputMaybe<Scalars['float8']>;
  serviceId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type InvoiceServiceConsumable_Sum_Fields = {
  __typename?: 'invoiceServiceConsumable_sum_fields';
  quantity?: Maybe<Scalars['float8']>;
};

/** order by sum() on columns of table "invoiceServiceConsumable" */
export type InvoiceServiceConsumable_Sum_Order_By = {
  quantity?: InputMaybe<Order_By>;
};

/** update columns of table "invoiceServiceConsumable" */
export enum InvoiceServiceConsumable_Update_Column {
  /** column name */
  ConsumableId = 'consumableId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  InvoiceId = 'invoiceId',
  /** column name */
  Quantity = 'quantity',
  /** column name */
  ServiceId = 'serviceId',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type InvoiceServiceConsumable_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<InvoiceServiceConsumable_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<InvoiceServiceConsumable_Set_Input>;
  /** filter the rows which have to be updated */
  where: InvoiceServiceConsumable_Bool_Exp;
};

/** aggregate var_pop on columns */
export type InvoiceServiceConsumable_Var_Pop_Fields = {
  __typename?: 'invoiceServiceConsumable_var_pop_fields';
  quantity?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "invoiceServiceConsumable" */
export type InvoiceServiceConsumable_Var_Pop_Order_By = {
  quantity?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type InvoiceServiceConsumable_Var_Samp_Fields = {
  __typename?: 'invoiceServiceConsumable_var_samp_fields';
  quantity?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "invoiceServiceConsumable" */
export type InvoiceServiceConsumable_Var_Samp_Order_By = {
  quantity?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type InvoiceServiceConsumable_Variance_Fields = {
  __typename?: 'invoiceServiceConsumable_variance_fields';
  quantity?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "invoiceServiceConsumable" */
export type InvoiceServiceConsumable_Variance_Order_By = {
  quantity?: InputMaybe<Order_By>;
};

/** columns and relationships of "invoiceServiceItem" */
export type InvoiceServiceItem = {
  __typename?: 'invoiceServiceItem';
  createdAt: Scalars['timestamptz'];
  customServiceName?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  /** An object relationship */
  invoice: Invoice;
  invoiceId: Scalars['uuid'];
  price: Scalars['Int'];
  pricePerUnit: Scalars['Int'];
  /** An object relationship */
  provider?: Maybe<Provider>;
  providerId?: Maybe<Scalars['uuid']>;
  quantity: Scalars['Int'];
  /** An object relationship */
  service: Service;
  serviceId: Scalars['uuid'];
  taxRate: Scalars['float8'];
  updatedAt: Scalars['timestamptz'];
  /** An object relationship */
  workspace: Workspace;
  workspaceId: Scalars['uuid'];
};

/** aggregated selection of "invoiceServiceItem" */
export type InvoiceServiceItem_Aggregate = {
  __typename?: 'invoiceServiceItem_aggregate';
  aggregate?: Maybe<InvoiceServiceItem_Aggregate_Fields>;
  nodes: Array<InvoiceServiceItem>;
};

export type InvoiceServiceItem_Aggregate_Bool_Exp = {
  avg?: InputMaybe<InvoiceServiceItem_Aggregate_Bool_Exp_Avg>;
  corr?: InputMaybe<InvoiceServiceItem_Aggregate_Bool_Exp_Corr>;
  count?: InputMaybe<InvoiceServiceItem_Aggregate_Bool_Exp_Count>;
  covar_samp?: InputMaybe<InvoiceServiceItem_Aggregate_Bool_Exp_Covar_Samp>;
  max?: InputMaybe<InvoiceServiceItem_Aggregate_Bool_Exp_Max>;
  min?: InputMaybe<InvoiceServiceItem_Aggregate_Bool_Exp_Min>;
  stddev_samp?: InputMaybe<InvoiceServiceItem_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: InputMaybe<InvoiceServiceItem_Aggregate_Bool_Exp_Sum>;
  var_samp?: InputMaybe<InvoiceServiceItem_Aggregate_Bool_Exp_Var_Samp>;
};

export type InvoiceServiceItem_Aggregate_Bool_Exp_Avg = {
  arguments: InvoiceServiceItem_Select_Column_InvoiceServiceItem_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<InvoiceServiceItem_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type InvoiceServiceItem_Aggregate_Bool_Exp_Corr = {
  arguments: InvoiceServiceItem_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<InvoiceServiceItem_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type InvoiceServiceItem_Aggregate_Bool_Exp_Corr_Arguments = {
  X: InvoiceServiceItem_Select_Column_InvoiceServiceItem_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: InvoiceServiceItem_Select_Column_InvoiceServiceItem_Aggregate_Bool_Exp_Corr_Arguments_Columns;
};

export type InvoiceServiceItem_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<InvoiceServiceItem_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<InvoiceServiceItem_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type InvoiceServiceItem_Aggregate_Bool_Exp_Covar_Samp = {
  arguments: InvoiceServiceItem_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<InvoiceServiceItem_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type InvoiceServiceItem_Aggregate_Bool_Exp_Covar_Samp_Arguments = {
  X: InvoiceServiceItem_Select_Column_InvoiceServiceItem_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  Y: InvoiceServiceItem_Select_Column_InvoiceServiceItem_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
};

export type InvoiceServiceItem_Aggregate_Bool_Exp_Max = {
  arguments: InvoiceServiceItem_Select_Column_InvoiceServiceItem_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<InvoiceServiceItem_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type InvoiceServiceItem_Aggregate_Bool_Exp_Min = {
  arguments: InvoiceServiceItem_Select_Column_InvoiceServiceItem_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<InvoiceServiceItem_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type InvoiceServiceItem_Aggregate_Bool_Exp_Stddev_Samp = {
  arguments: InvoiceServiceItem_Select_Column_InvoiceServiceItem_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<InvoiceServiceItem_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type InvoiceServiceItem_Aggregate_Bool_Exp_Sum = {
  arguments: InvoiceServiceItem_Select_Column_InvoiceServiceItem_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<InvoiceServiceItem_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type InvoiceServiceItem_Aggregate_Bool_Exp_Var_Samp = {
  arguments: InvoiceServiceItem_Select_Column_InvoiceServiceItem_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<InvoiceServiceItem_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

/** aggregate fields of "invoiceServiceItem" */
export type InvoiceServiceItem_Aggregate_Fields = {
  __typename?: 'invoiceServiceItem_aggregate_fields';
  avg?: Maybe<InvoiceServiceItem_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<InvoiceServiceItem_Max_Fields>;
  min?: Maybe<InvoiceServiceItem_Min_Fields>;
  stddev?: Maybe<InvoiceServiceItem_Stddev_Fields>;
  stddev_pop?: Maybe<InvoiceServiceItem_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<InvoiceServiceItem_Stddev_Samp_Fields>;
  sum?: Maybe<InvoiceServiceItem_Sum_Fields>;
  var_pop?: Maybe<InvoiceServiceItem_Var_Pop_Fields>;
  var_samp?: Maybe<InvoiceServiceItem_Var_Samp_Fields>;
  variance?: Maybe<InvoiceServiceItem_Variance_Fields>;
};


/** aggregate fields of "invoiceServiceItem" */
export type InvoiceServiceItem_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<InvoiceServiceItem_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "invoiceServiceItem" */
export type InvoiceServiceItem_Aggregate_Order_By = {
  avg?: InputMaybe<InvoiceServiceItem_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<InvoiceServiceItem_Max_Order_By>;
  min?: InputMaybe<InvoiceServiceItem_Min_Order_By>;
  stddev?: InputMaybe<InvoiceServiceItem_Stddev_Order_By>;
  stddev_pop?: InputMaybe<InvoiceServiceItem_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<InvoiceServiceItem_Stddev_Samp_Order_By>;
  sum?: InputMaybe<InvoiceServiceItem_Sum_Order_By>;
  var_pop?: InputMaybe<InvoiceServiceItem_Var_Pop_Order_By>;
  var_samp?: InputMaybe<InvoiceServiceItem_Var_Samp_Order_By>;
  variance?: InputMaybe<InvoiceServiceItem_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "invoiceServiceItem" */
export type InvoiceServiceItem_Arr_Rel_Insert_Input = {
  data: Array<InvoiceServiceItem_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<InvoiceServiceItem_On_Conflict>;
};

/** aggregate avg on columns */
export type InvoiceServiceItem_Avg_Fields = {
  __typename?: 'invoiceServiceItem_avg_fields';
  price?: Maybe<Scalars['Float']>;
  pricePerUnit?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  taxRate?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "invoiceServiceItem" */
export type InvoiceServiceItem_Avg_Order_By = {
  price?: InputMaybe<Order_By>;
  pricePerUnit?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  taxRate?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "invoiceServiceItem". All fields are combined with a logical 'AND'. */
export type InvoiceServiceItem_Bool_Exp = {
  _and?: InputMaybe<Array<InvoiceServiceItem_Bool_Exp>>;
  _not?: InputMaybe<InvoiceServiceItem_Bool_Exp>;
  _or?: InputMaybe<Array<InvoiceServiceItem_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  customServiceName?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  invoice?: InputMaybe<Invoice_Bool_Exp>;
  invoiceId?: InputMaybe<Uuid_Comparison_Exp>;
  price?: InputMaybe<Int_Comparison_Exp>;
  pricePerUnit?: InputMaybe<Int_Comparison_Exp>;
  provider?: InputMaybe<Provider_Bool_Exp>;
  providerId?: InputMaybe<Uuid_Comparison_Exp>;
  quantity?: InputMaybe<Int_Comparison_Exp>;
  service?: InputMaybe<Service_Bool_Exp>;
  serviceId?: InputMaybe<Uuid_Comparison_Exp>;
  taxRate?: InputMaybe<Float8_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  workspace?: InputMaybe<Workspace_Bool_Exp>;
  workspaceId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "invoiceServiceItem" */
export enum InvoiceServiceItem_Constraint {
  /** unique or primary key constraint on columns "id" */
  InvoiceServiceItemPkey = 'invoiceServiceItem_pkey'
}

/** input type for incrementing numeric columns in table "invoiceServiceItem" */
export type InvoiceServiceItem_Inc_Input = {
  price?: InputMaybe<Scalars['Int']>;
  pricePerUnit?: InputMaybe<Scalars['Int']>;
  quantity?: InputMaybe<Scalars['Int']>;
  taxRate?: InputMaybe<Scalars['float8']>;
};

/** input type for inserting data into table "invoiceServiceItem" */
export type InvoiceServiceItem_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  customServiceName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  invoice?: InputMaybe<Invoice_Obj_Rel_Insert_Input>;
  invoiceId?: InputMaybe<Scalars['uuid']>;
  price?: InputMaybe<Scalars['Int']>;
  pricePerUnit?: InputMaybe<Scalars['Int']>;
  provider?: InputMaybe<Provider_Obj_Rel_Insert_Input>;
  providerId?: InputMaybe<Scalars['uuid']>;
  quantity?: InputMaybe<Scalars['Int']>;
  service?: InputMaybe<Service_Obj_Rel_Insert_Input>;
  serviceId?: InputMaybe<Scalars['uuid']>;
  taxRate?: InputMaybe<Scalars['float8']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspace?: InputMaybe<Workspace_Obj_Rel_Insert_Input>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type InvoiceServiceItem_Max_Fields = {
  __typename?: 'invoiceServiceItem_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  customServiceName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  invoiceId?: Maybe<Scalars['uuid']>;
  price?: Maybe<Scalars['Int']>;
  pricePerUnit?: Maybe<Scalars['Int']>;
  providerId?: Maybe<Scalars['uuid']>;
  quantity?: Maybe<Scalars['Int']>;
  serviceId?: Maybe<Scalars['uuid']>;
  taxRate?: Maybe<Scalars['float8']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "invoiceServiceItem" */
export type InvoiceServiceItem_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  customServiceName?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoiceId?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  pricePerUnit?: InputMaybe<Order_By>;
  providerId?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  serviceId?: InputMaybe<Order_By>;
  taxRate?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type InvoiceServiceItem_Min_Fields = {
  __typename?: 'invoiceServiceItem_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  customServiceName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  invoiceId?: Maybe<Scalars['uuid']>;
  price?: Maybe<Scalars['Int']>;
  pricePerUnit?: Maybe<Scalars['Int']>;
  providerId?: Maybe<Scalars['uuid']>;
  quantity?: Maybe<Scalars['Int']>;
  serviceId?: Maybe<Scalars['uuid']>;
  taxRate?: Maybe<Scalars['float8']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "invoiceServiceItem" */
export type InvoiceServiceItem_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  customServiceName?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoiceId?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  pricePerUnit?: InputMaybe<Order_By>;
  providerId?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  serviceId?: InputMaybe<Order_By>;
  taxRate?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "invoiceServiceItem" */
export type InvoiceServiceItem_Mutation_Response = {
  __typename?: 'invoiceServiceItem_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<InvoiceServiceItem>;
};

/** on_conflict condition type for table "invoiceServiceItem" */
export type InvoiceServiceItem_On_Conflict = {
  constraint: InvoiceServiceItem_Constraint;
  update_columns?: Array<InvoiceServiceItem_Update_Column>;
  where?: InputMaybe<InvoiceServiceItem_Bool_Exp>;
};

/** Ordering options when selecting data from "invoiceServiceItem". */
export type InvoiceServiceItem_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  customServiceName?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoice?: InputMaybe<Invoice_Order_By>;
  invoiceId?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  pricePerUnit?: InputMaybe<Order_By>;
  provider?: InputMaybe<Provider_Order_By>;
  providerId?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  service?: InputMaybe<Service_Order_By>;
  serviceId?: InputMaybe<Order_By>;
  taxRate?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  workspace?: InputMaybe<Workspace_Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: invoiceServiceItem */
export type InvoiceServiceItem_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "invoiceServiceItem" */
export enum InvoiceServiceItem_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CustomServiceName = 'customServiceName',
  /** column name */
  Id = 'id',
  /** column name */
  InvoiceId = 'invoiceId',
  /** column name */
  Price = 'price',
  /** column name */
  PricePerUnit = 'pricePerUnit',
  /** column name */
  ProviderId = 'providerId',
  /** column name */
  Quantity = 'quantity',
  /** column name */
  ServiceId = 'serviceId',
  /** column name */
  TaxRate = 'taxRate',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WorkspaceId = 'workspaceId'
}

/** select "invoiceServiceItem_aggregate_bool_exp_avg_arguments_columns" columns of table "invoiceServiceItem" */
export enum InvoiceServiceItem_Select_Column_InvoiceServiceItem_Aggregate_Bool_Exp_Avg_Arguments_Columns {
  /** column name */
  TaxRate = 'taxRate'
}

/** select "invoiceServiceItem_aggregate_bool_exp_corr_arguments_columns" columns of table "invoiceServiceItem" */
export enum InvoiceServiceItem_Select_Column_InvoiceServiceItem_Aggregate_Bool_Exp_Corr_Arguments_Columns {
  /** column name */
  TaxRate = 'taxRate'
}

/** select "invoiceServiceItem_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "invoiceServiceItem" */
export enum InvoiceServiceItem_Select_Column_InvoiceServiceItem_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns {
  /** column name */
  TaxRate = 'taxRate'
}

/** select "invoiceServiceItem_aggregate_bool_exp_max_arguments_columns" columns of table "invoiceServiceItem" */
export enum InvoiceServiceItem_Select_Column_InvoiceServiceItem_Aggregate_Bool_Exp_Max_Arguments_Columns {
  /** column name */
  TaxRate = 'taxRate'
}

/** select "invoiceServiceItem_aggregate_bool_exp_min_arguments_columns" columns of table "invoiceServiceItem" */
export enum InvoiceServiceItem_Select_Column_InvoiceServiceItem_Aggregate_Bool_Exp_Min_Arguments_Columns {
  /** column name */
  TaxRate = 'taxRate'
}

/** select "invoiceServiceItem_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "invoiceServiceItem" */
export enum InvoiceServiceItem_Select_Column_InvoiceServiceItem_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns {
  /** column name */
  TaxRate = 'taxRate'
}

/** select "invoiceServiceItem_aggregate_bool_exp_sum_arguments_columns" columns of table "invoiceServiceItem" */
export enum InvoiceServiceItem_Select_Column_InvoiceServiceItem_Aggregate_Bool_Exp_Sum_Arguments_Columns {
  /** column name */
  TaxRate = 'taxRate'
}

/** select "invoiceServiceItem_aggregate_bool_exp_var_samp_arguments_columns" columns of table "invoiceServiceItem" */
export enum InvoiceServiceItem_Select_Column_InvoiceServiceItem_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns {
  /** column name */
  TaxRate = 'taxRate'
}

/** input type for updating data in table "invoiceServiceItem" */
export type InvoiceServiceItem_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  customServiceName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  invoiceId?: InputMaybe<Scalars['uuid']>;
  price?: InputMaybe<Scalars['Int']>;
  pricePerUnit?: InputMaybe<Scalars['Int']>;
  providerId?: InputMaybe<Scalars['uuid']>;
  quantity?: InputMaybe<Scalars['Int']>;
  serviceId?: InputMaybe<Scalars['uuid']>;
  taxRate?: InputMaybe<Scalars['float8']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type InvoiceServiceItem_Stddev_Fields = {
  __typename?: 'invoiceServiceItem_stddev_fields';
  price?: Maybe<Scalars['Float']>;
  pricePerUnit?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  taxRate?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "invoiceServiceItem" */
export type InvoiceServiceItem_Stddev_Order_By = {
  price?: InputMaybe<Order_By>;
  pricePerUnit?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  taxRate?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type InvoiceServiceItem_Stddev_Pop_Fields = {
  __typename?: 'invoiceServiceItem_stddev_pop_fields';
  price?: Maybe<Scalars['Float']>;
  pricePerUnit?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  taxRate?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "invoiceServiceItem" */
export type InvoiceServiceItem_Stddev_Pop_Order_By = {
  price?: InputMaybe<Order_By>;
  pricePerUnit?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  taxRate?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type InvoiceServiceItem_Stddev_Samp_Fields = {
  __typename?: 'invoiceServiceItem_stddev_samp_fields';
  price?: Maybe<Scalars['Float']>;
  pricePerUnit?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  taxRate?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "invoiceServiceItem" */
export type InvoiceServiceItem_Stddev_Samp_Order_By = {
  price?: InputMaybe<Order_By>;
  pricePerUnit?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  taxRate?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "invoiceServiceItem" */
export type InvoiceServiceItem_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: InvoiceServiceItem_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type InvoiceServiceItem_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  customServiceName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  invoiceId?: InputMaybe<Scalars['uuid']>;
  price?: InputMaybe<Scalars['Int']>;
  pricePerUnit?: InputMaybe<Scalars['Int']>;
  providerId?: InputMaybe<Scalars['uuid']>;
  quantity?: InputMaybe<Scalars['Int']>;
  serviceId?: InputMaybe<Scalars['uuid']>;
  taxRate?: InputMaybe<Scalars['float8']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type InvoiceServiceItem_Sum_Fields = {
  __typename?: 'invoiceServiceItem_sum_fields';
  price?: Maybe<Scalars['Int']>;
  pricePerUnit?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
  taxRate?: Maybe<Scalars['float8']>;
};

/** order by sum() on columns of table "invoiceServiceItem" */
export type InvoiceServiceItem_Sum_Order_By = {
  price?: InputMaybe<Order_By>;
  pricePerUnit?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  taxRate?: InputMaybe<Order_By>;
};

/** update columns of table "invoiceServiceItem" */
export enum InvoiceServiceItem_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CustomServiceName = 'customServiceName',
  /** column name */
  Id = 'id',
  /** column name */
  InvoiceId = 'invoiceId',
  /** column name */
  Price = 'price',
  /** column name */
  PricePerUnit = 'pricePerUnit',
  /** column name */
  ProviderId = 'providerId',
  /** column name */
  Quantity = 'quantity',
  /** column name */
  ServiceId = 'serviceId',
  /** column name */
  TaxRate = 'taxRate',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WorkspaceId = 'workspaceId'
}

export type InvoiceServiceItem_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<InvoiceServiceItem_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<InvoiceServiceItem_Set_Input>;
  /** filter the rows which have to be updated */
  where: InvoiceServiceItem_Bool_Exp;
};

/** aggregate var_pop on columns */
export type InvoiceServiceItem_Var_Pop_Fields = {
  __typename?: 'invoiceServiceItem_var_pop_fields';
  price?: Maybe<Scalars['Float']>;
  pricePerUnit?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  taxRate?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "invoiceServiceItem" */
export type InvoiceServiceItem_Var_Pop_Order_By = {
  price?: InputMaybe<Order_By>;
  pricePerUnit?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  taxRate?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type InvoiceServiceItem_Var_Samp_Fields = {
  __typename?: 'invoiceServiceItem_var_samp_fields';
  price?: Maybe<Scalars['Float']>;
  pricePerUnit?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  taxRate?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "invoiceServiceItem" */
export type InvoiceServiceItem_Var_Samp_Order_By = {
  price?: InputMaybe<Order_By>;
  pricePerUnit?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  taxRate?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type InvoiceServiceItem_Variance_Fields = {
  __typename?: 'invoiceServiceItem_variance_fields';
  price?: Maybe<Scalars['Float']>;
  pricePerUnit?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  taxRate?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "invoiceServiceItem" */
export type InvoiceServiceItem_Variance_Order_By = {
  price?: InputMaybe<Order_By>;
  pricePerUnit?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  taxRate?: InputMaybe<Order_By>;
};

/** columns and relationships of "invoiceTemplate" */
export type InvoiceTemplate = {
  __typename?: 'invoiceTemplate';
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  invoiceValues: Scalars['jsonb'];
  title: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  workspaceId: Scalars['uuid'];
};


/** columns and relationships of "invoiceTemplate" */
export type InvoiceTemplateInvoiceValuesArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "invoiceTemplate" */
export type InvoiceTemplate_Aggregate = {
  __typename?: 'invoiceTemplate_aggregate';
  aggregate?: Maybe<InvoiceTemplate_Aggregate_Fields>;
  nodes: Array<InvoiceTemplate>;
};

/** aggregate fields of "invoiceTemplate" */
export type InvoiceTemplate_Aggregate_Fields = {
  __typename?: 'invoiceTemplate_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<InvoiceTemplate_Max_Fields>;
  min?: Maybe<InvoiceTemplate_Min_Fields>;
};


/** aggregate fields of "invoiceTemplate" */
export type InvoiceTemplate_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<InvoiceTemplate_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type InvoiceTemplate_Append_Input = {
  invoiceValues?: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "invoiceTemplate". All fields are combined with a logical 'AND'. */
export type InvoiceTemplate_Bool_Exp = {
  _and?: InputMaybe<Array<InvoiceTemplate_Bool_Exp>>;
  _not?: InputMaybe<InvoiceTemplate_Bool_Exp>;
  _or?: InputMaybe<Array<InvoiceTemplate_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  invoiceValues?: InputMaybe<Jsonb_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  workspaceId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "invoiceTemplate" */
export enum InvoiceTemplate_Constraint {
  /** unique or primary key constraint on columns "id" */
  InvoiceTemplatePkey = 'invoiceTemplate_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type InvoiceTemplate_Delete_At_Path_Input = {
  invoiceValues?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type InvoiceTemplate_Delete_Elem_Input = {
  invoiceValues?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type InvoiceTemplate_Delete_Key_Input = {
  invoiceValues?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "invoiceTemplate" */
export type InvoiceTemplate_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  invoiceValues?: InputMaybe<Scalars['jsonb']>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type InvoiceTemplate_Max_Fields = {
  __typename?: 'invoiceTemplate_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type InvoiceTemplate_Min_Fields = {
  __typename?: 'invoiceTemplate_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "invoiceTemplate" */
export type InvoiceTemplate_Mutation_Response = {
  __typename?: 'invoiceTemplate_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<InvoiceTemplate>;
};

/** on_conflict condition type for table "invoiceTemplate" */
export type InvoiceTemplate_On_Conflict = {
  constraint: InvoiceTemplate_Constraint;
  update_columns?: Array<InvoiceTemplate_Update_Column>;
  where?: InputMaybe<InvoiceTemplate_Bool_Exp>;
};

/** Ordering options when selecting data from "invoiceTemplate". */
export type InvoiceTemplate_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoiceValues?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: invoiceTemplate */
export type InvoiceTemplate_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type InvoiceTemplate_Prepend_Input = {
  invoiceValues?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "invoiceTemplate" */
export enum InvoiceTemplate_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  InvoiceValues = 'invoiceValues',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WorkspaceId = 'workspaceId'
}

/** input type for updating data in table "invoiceTemplate" */
export type InvoiceTemplate_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  invoiceValues?: InputMaybe<Scalars['jsonb']>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "invoiceTemplate" */
export type InvoiceTemplate_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: InvoiceTemplate_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type InvoiceTemplate_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  invoiceValues?: InputMaybe<Scalars['jsonb']>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "invoiceTemplate" */
export enum InvoiceTemplate_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  InvoiceValues = 'invoiceValues',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WorkspaceId = 'workspaceId'
}

export type InvoiceTemplate_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<InvoiceTemplate_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<InvoiceTemplate_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<InvoiceTemplate_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<InvoiceTemplate_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<InvoiceTemplate_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<InvoiceTemplate_Set_Input>;
  /** filter the rows which have to be updated */
  where: InvoiceTemplate_Bool_Exp;
};

/** aggregated selection of "invoice" */
export type Invoice_Aggregate = {
  __typename?: 'invoice_aggregate';
  aggregate?: Maybe<Invoice_Aggregate_Fields>;
  nodes: Array<Invoice>;
};

export type Invoice_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Invoice_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Invoice_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Invoice_Aggregate_Bool_Exp_Count>;
};

export type Invoice_Aggregate_Bool_Exp_Bool_And = {
  arguments: Invoice_Select_Column_Invoice_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Invoice_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Invoice_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Invoice_Select_Column_Invoice_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Invoice_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Invoice_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Invoice_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Invoice_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "invoice" */
export type Invoice_Aggregate_Fields = {
  __typename?: 'invoice_aggregate_fields';
  avg?: Maybe<Invoice_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Invoice_Max_Fields>;
  min?: Maybe<Invoice_Min_Fields>;
  stddev?: Maybe<Invoice_Stddev_Fields>;
  stddev_pop?: Maybe<Invoice_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Invoice_Stddev_Samp_Fields>;
  sum?: Maybe<Invoice_Sum_Fields>;
  var_pop?: Maybe<Invoice_Var_Pop_Fields>;
  var_samp?: Maybe<Invoice_Var_Samp_Fields>;
  variance?: Maybe<Invoice_Variance_Fields>;
};


/** aggregate fields of "invoice" */
export type Invoice_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Invoice_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "invoice" */
export type Invoice_Aggregate_Order_By = {
  avg?: InputMaybe<Invoice_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Invoice_Max_Order_By>;
  min?: InputMaybe<Invoice_Min_Order_By>;
  stddev?: InputMaybe<Invoice_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Invoice_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Invoice_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Invoice_Sum_Order_By>;
  var_pop?: InputMaybe<Invoice_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Invoice_Var_Samp_Order_By>;
  variance?: InputMaybe<Invoice_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "invoice" */
export type Invoice_Arr_Rel_Insert_Input = {
  data: Array<Invoice_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Invoice_On_Conflict>;
};

/** aggregate avg on columns */
export type Invoice_Avg_Fields = {
  __typename?: 'invoice_avg_fields';
  balanceDue?: Maybe<Scalars['Float']>;
  creditsApplied?: Maybe<Scalars['Float']>;
  depositPaid?: Maybe<Scalars['Float']>;
  discount?: Maybe<Scalars['Float']>;
  gratuity?: Maybe<Scalars['Float']>;
  postTaxTotal?: Maybe<Scalars['Float']>;
  preTaxTotal?: Maybe<Scalars['Float']>;
  taxTotal?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "invoice" */
export type Invoice_Avg_Order_By = {
  balanceDue?: InputMaybe<Order_By>;
  creditsApplied?: InputMaybe<Order_By>;
  depositPaid?: InputMaybe<Order_By>;
  discount?: InputMaybe<Order_By>;
  gratuity?: InputMaybe<Order_By>;
  postTaxTotal?: InputMaybe<Order_By>;
  preTaxTotal?: InputMaybe<Order_By>;
  taxTotal?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "invoice". All fields are combined with a logical 'AND'. */
export type Invoice_Bool_Exp = {
  _and?: InputMaybe<Array<Invoice_Bool_Exp>>;
  _not?: InputMaybe<Invoice_Bool_Exp>;
  _or?: InputMaybe<Array<Invoice_Bool_Exp>>;
  address?: InputMaybe<String_Comparison_Exp>;
  appointment?: InputMaybe<Appointment_Bool_Exp>;
  appointmentId?: InputMaybe<Uuid_Comparison_Exp>;
  balanceDue?: InputMaybe<Int_Comparison_Exp>;
  cashier?: InputMaybe<User_Bool_Exp>;
  cashierId?: InputMaybe<Uuid_Comparison_Exp>;
  consumableOverride?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  creditsApplied?: InputMaybe<Int_Comparison_Exp>;
  depositInvoiceLineItems?: InputMaybe<DepositInvoiceLineItem_Bool_Exp>;
  depositInvoiceLineItems_aggregate?: InputMaybe<DepositInvoiceLineItem_Aggregate_Bool_Exp>;
  depositPaid?: InputMaybe<Int_Comparison_Exp>;
  discount?: InputMaybe<Int_Comparison_Exp>;
  discountLineItems?: InputMaybe<DiscountInvoiceLineItem_Bool_Exp>;
  discountLineItems_aggregate?: InputMaybe<DiscountInvoiceLineItem_Aggregate_Bool_Exp>;
  dueDate?: InputMaybe<Timestamptz_Comparison_Exp>;
  feeInvoiceLineItems?: InputMaybe<FeeInvoiceLineItem_Bool_Exp>;
  feeInvoiceLineItems_aggregate?: InputMaybe<FeeInvoiceLineItem_Aggregate_Bool_Exp>;
  genericLineItemDiscounts?: InputMaybe<GenericInvoiceLineItemDiscount_Bool_Exp>;
  genericLineItemDiscounts_aggregate?: InputMaybe<GenericInvoiceLineItemDiscount_Aggregate_Bool_Exp>;
  genericLineItems?: InputMaybe<GenericInvoiceLineItem_Bool_Exp>;
  genericLineItems_aggregate?: InputMaybe<GenericInvoiceLineItem_Aggregate_Bool_Exp>;
  gratuity?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  invoiceCoupons?: InputMaybe<InvoiceCoupon_Bool_Exp>;
  invoiceCoupons_aggregate?: InputMaybe<InvoiceCoupon_Aggregate_Bool_Exp>;
  invoiceMemberships?: InputMaybe<InvoiceMembership_Bool_Exp>;
  invoiceMemberships_aggregate?: InputMaybe<InvoiceMembership_Aggregate_Bool_Exp>;
  invoiceNumber?: InputMaybe<String_Comparison_Exp>;
  invoicePackages?: InputMaybe<InvoicePackage_Bool_Exp>;
  invoicePackages_aggregate?: InputMaybe<InvoicePackage_Aggregate_Bool_Exp>;
  invoiceServiceConsumables?: InputMaybe<InvoiceServiceConsumable_Bool_Exp>;
  invoiceServiceConsumables_aggregate?: InputMaybe<InvoiceServiceConsumable_Aggregate_Bool_Exp>;
  isArchived?: InputMaybe<Boolean_Comparison_Exp>;
  location?: InputMaybe<Location_Bool_Exp>;
  locationId?: InputMaybe<Uuid_Comparison_Exp>;
  message?: InputMaybe<String_Comparison_Exp>;
  note?: InputMaybe<String_Comparison_Exp>;
  paidOn?: InputMaybe<Timestamptz_Comparison_Exp>;
  patient?: InputMaybe<Patient_Bool_Exp>;
  patientId?: InputMaybe<Uuid_Comparison_Exp>;
  patientMembershipId?: InputMaybe<Uuid_Comparison_Exp>;
  patientWalletDiscountApplied?: InputMaybe<Boolean_Comparison_Exp>;
  payments?: InputMaybe<Payment_Bool_Exp>;
  payments_aggregate?: InputMaybe<Payment_Aggregate_Bool_Exp>;
  postTaxTotal?: InputMaybe<Int_Comparison_Exp>;
  preTaxTotal?: InputMaybe<Int_Comparison_Exp>;
  productItems?: InputMaybe<InvoiceProductItem_Bool_Exp>;
  productItems_aggregate?: InputMaybe<InvoiceProductItem_Aggregate_Bool_Exp>;
  provider?: InputMaybe<Provider_Bool_Exp>;
  providerId?: InputMaybe<Uuid_Comparison_Exp>;
  serviceItems?: InputMaybe<InvoiceServiceItem_Bool_Exp>;
  serviceItems_aggregate?: InputMaybe<InvoiceServiceItem_Aggregate_Bool_Exp>;
  signatureId?: InputMaybe<Uuid_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  taxTotal?: InputMaybe<Int_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  workspace?: InputMaybe<Workspace_Bool_Exp>;
  workspaceId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "invoice" */
export enum Invoice_Constraint {
  /** unique or primary key constraint on columns "id" */
  InvoicePkey = 'invoice_pkey'
}

/** input type for incrementing numeric columns in table "invoice" */
export type Invoice_Inc_Input = {
  balanceDue?: InputMaybe<Scalars['Int']>;
  creditsApplied?: InputMaybe<Scalars['Int']>;
  depositPaid?: InputMaybe<Scalars['Int']>;
  discount?: InputMaybe<Scalars['Int']>;
  gratuity?: InputMaybe<Scalars['Int']>;
  postTaxTotal?: InputMaybe<Scalars['Int']>;
  preTaxTotal?: InputMaybe<Scalars['Int']>;
  taxTotal?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "invoice" */
export type Invoice_Insert_Input = {
  address?: InputMaybe<Scalars['String']>;
  appointment?: InputMaybe<Appointment_Obj_Rel_Insert_Input>;
  appointmentId?: InputMaybe<Scalars['uuid']>;
  balanceDue?: InputMaybe<Scalars['Int']>;
  cashier?: InputMaybe<User_Obj_Rel_Insert_Input>;
  cashierId?: InputMaybe<Scalars['uuid']>;
  consumableOverride?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  creditsApplied?: InputMaybe<Scalars['Int']>;
  depositInvoiceLineItems?: InputMaybe<DepositInvoiceLineItem_Arr_Rel_Insert_Input>;
  depositPaid?: InputMaybe<Scalars['Int']>;
  discount?: InputMaybe<Scalars['Int']>;
  discountLineItems?: InputMaybe<DiscountInvoiceLineItem_Arr_Rel_Insert_Input>;
  dueDate?: InputMaybe<Scalars['timestamptz']>;
  feeInvoiceLineItems?: InputMaybe<FeeInvoiceLineItem_Arr_Rel_Insert_Input>;
  genericLineItemDiscounts?: InputMaybe<GenericInvoiceLineItemDiscount_Arr_Rel_Insert_Input>;
  genericLineItems?: InputMaybe<GenericInvoiceLineItem_Arr_Rel_Insert_Input>;
  gratuity?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  invoiceCoupons?: InputMaybe<InvoiceCoupon_Arr_Rel_Insert_Input>;
  invoiceMemberships?: InputMaybe<InvoiceMembership_Arr_Rel_Insert_Input>;
  invoiceNumber?: InputMaybe<Scalars['String']>;
  invoicePackages?: InputMaybe<InvoicePackage_Arr_Rel_Insert_Input>;
  invoiceServiceConsumables?: InputMaybe<InvoiceServiceConsumable_Arr_Rel_Insert_Input>;
  isArchived?: InputMaybe<Scalars['Boolean']>;
  location?: InputMaybe<Location_Obj_Rel_Insert_Input>;
  locationId?: InputMaybe<Scalars['uuid']>;
  message?: InputMaybe<Scalars['String']>;
  note?: InputMaybe<Scalars['String']>;
  paidOn?: InputMaybe<Scalars['timestamptz']>;
  patient?: InputMaybe<Patient_Obj_Rel_Insert_Input>;
  patientId?: InputMaybe<Scalars['uuid']>;
  patientMembershipId?: InputMaybe<Scalars['uuid']>;
  patientWalletDiscountApplied?: InputMaybe<Scalars['Boolean']>;
  payments?: InputMaybe<Payment_Arr_Rel_Insert_Input>;
  postTaxTotal?: InputMaybe<Scalars['Int']>;
  preTaxTotal?: InputMaybe<Scalars['Int']>;
  productItems?: InputMaybe<InvoiceProductItem_Arr_Rel_Insert_Input>;
  provider?: InputMaybe<Provider_Obj_Rel_Insert_Input>;
  providerId?: InputMaybe<Scalars['uuid']>;
  serviceItems?: InputMaybe<InvoiceServiceItem_Arr_Rel_Insert_Input>;
  signatureId?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Scalars['String']>;
  taxTotal?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspace?: InputMaybe<Workspace_Obj_Rel_Insert_Input>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Invoice_Max_Fields = {
  __typename?: 'invoice_max_fields';
  address?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['uuid']>;
  balanceDue?: Maybe<Scalars['Int']>;
  cashierId?: Maybe<Scalars['uuid']>;
  consumableOverride?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  creditsApplied?: Maybe<Scalars['Int']>;
  depositPaid?: Maybe<Scalars['Int']>;
  discount?: Maybe<Scalars['Int']>;
  dueDate?: Maybe<Scalars['timestamptz']>;
  gratuity?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  invoiceNumber?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['uuid']>;
  message?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  paidOn?: Maybe<Scalars['timestamptz']>;
  patientId?: Maybe<Scalars['uuid']>;
  patientMembershipId?: Maybe<Scalars['uuid']>;
  postTaxTotal?: Maybe<Scalars['Int']>;
  preTaxTotal?: Maybe<Scalars['Int']>;
  providerId?: Maybe<Scalars['uuid']>;
  signatureId?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
  taxTotal?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "invoice" */
export type Invoice_Max_Order_By = {
  address?: InputMaybe<Order_By>;
  appointmentId?: InputMaybe<Order_By>;
  balanceDue?: InputMaybe<Order_By>;
  cashierId?: InputMaybe<Order_By>;
  consumableOverride?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  creditsApplied?: InputMaybe<Order_By>;
  depositPaid?: InputMaybe<Order_By>;
  discount?: InputMaybe<Order_By>;
  dueDate?: InputMaybe<Order_By>;
  gratuity?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoiceNumber?: InputMaybe<Order_By>;
  locationId?: InputMaybe<Order_By>;
  message?: InputMaybe<Order_By>;
  note?: InputMaybe<Order_By>;
  paidOn?: InputMaybe<Order_By>;
  patientId?: InputMaybe<Order_By>;
  patientMembershipId?: InputMaybe<Order_By>;
  postTaxTotal?: InputMaybe<Order_By>;
  preTaxTotal?: InputMaybe<Order_By>;
  providerId?: InputMaybe<Order_By>;
  signatureId?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  taxTotal?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Invoice_Min_Fields = {
  __typename?: 'invoice_min_fields';
  address?: Maybe<Scalars['String']>;
  appointmentId?: Maybe<Scalars['uuid']>;
  balanceDue?: Maybe<Scalars['Int']>;
  cashierId?: Maybe<Scalars['uuid']>;
  consumableOverride?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  creditsApplied?: Maybe<Scalars['Int']>;
  depositPaid?: Maybe<Scalars['Int']>;
  discount?: Maybe<Scalars['Int']>;
  dueDate?: Maybe<Scalars['timestamptz']>;
  gratuity?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  invoiceNumber?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['uuid']>;
  message?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  paidOn?: Maybe<Scalars['timestamptz']>;
  patientId?: Maybe<Scalars['uuid']>;
  patientMembershipId?: Maybe<Scalars['uuid']>;
  postTaxTotal?: Maybe<Scalars['Int']>;
  preTaxTotal?: Maybe<Scalars['Int']>;
  providerId?: Maybe<Scalars['uuid']>;
  signatureId?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
  taxTotal?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "invoice" */
export type Invoice_Min_Order_By = {
  address?: InputMaybe<Order_By>;
  appointmentId?: InputMaybe<Order_By>;
  balanceDue?: InputMaybe<Order_By>;
  cashierId?: InputMaybe<Order_By>;
  consumableOverride?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  creditsApplied?: InputMaybe<Order_By>;
  depositPaid?: InputMaybe<Order_By>;
  discount?: InputMaybe<Order_By>;
  dueDate?: InputMaybe<Order_By>;
  gratuity?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoiceNumber?: InputMaybe<Order_By>;
  locationId?: InputMaybe<Order_By>;
  message?: InputMaybe<Order_By>;
  note?: InputMaybe<Order_By>;
  paidOn?: InputMaybe<Order_By>;
  patientId?: InputMaybe<Order_By>;
  patientMembershipId?: InputMaybe<Order_By>;
  postTaxTotal?: InputMaybe<Order_By>;
  preTaxTotal?: InputMaybe<Order_By>;
  providerId?: InputMaybe<Order_By>;
  signatureId?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  taxTotal?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "invoice" */
export type Invoice_Mutation_Response = {
  __typename?: 'invoice_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Invoice>;
};

/** input type for inserting object relation for remote table "invoice" */
export type Invoice_Obj_Rel_Insert_Input = {
  data: Invoice_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Invoice_On_Conflict>;
};

/** on_conflict condition type for table "invoice" */
export type Invoice_On_Conflict = {
  constraint: Invoice_Constraint;
  update_columns?: Array<Invoice_Update_Column>;
  where?: InputMaybe<Invoice_Bool_Exp>;
};

/** Ordering options when selecting data from "invoice". */
export type Invoice_Order_By = {
  address?: InputMaybe<Order_By>;
  appointment?: InputMaybe<Appointment_Order_By>;
  appointmentId?: InputMaybe<Order_By>;
  balanceDue?: InputMaybe<Order_By>;
  cashier?: InputMaybe<User_Order_By>;
  cashierId?: InputMaybe<Order_By>;
  consumableOverride?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  creditsApplied?: InputMaybe<Order_By>;
  depositInvoiceLineItems_aggregate?: InputMaybe<DepositInvoiceLineItem_Aggregate_Order_By>;
  depositPaid?: InputMaybe<Order_By>;
  discount?: InputMaybe<Order_By>;
  discountLineItems_aggregate?: InputMaybe<DiscountInvoiceLineItem_Aggregate_Order_By>;
  dueDate?: InputMaybe<Order_By>;
  feeInvoiceLineItems_aggregate?: InputMaybe<FeeInvoiceLineItem_Aggregate_Order_By>;
  genericLineItemDiscounts_aggregate?: InputMaybe<GenericInvoiceLineItemDiscount_Aggregate_Order_By>;
  genericLineItems_aggregate?: InputMaybe<GenericInvoiceLineItem_Aggregate_Order_By>;
  gratuity?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoiceCoupons_aggregate?: InputMaybe<InvoiceCoupon_Aggregate_Order_By>;
  invoiceMemberships_aggregate?: InputMaybe<InvoiceMembership_Aggregate_Order_By>;
  invoiceNumber?: InputMaybe<Order_By>;
  invoicePackages_aggregate?: InputMaybe<InvoicePackage_Aggregate_Order_By>;
  invoiceServiceConsumables_aggregate?: InputMaybe<InvoiceServiceConsumable_Aggregate_Order_By>;
  isArchived?: InputMaybe<Order_By>;
  location?: InputMaybe<Location_Order_By>;
  locationId?: InputMaybe<Order_By>;
  message?: InputMaybe<Order_By>;
  note?: InputMaybe<Order_By>;
  paidOn?: InputMaybe<Order_By>;
  patient?: InputMaybe<Patient_Order_By>;
  patientId?: InputMaybe<Order_By>;
  patientMembershipId?: InputMaybe<Order_By>;
  patientWalletDiscountApplied?: InputMaybe<Order_By>;
  payments_aggregate?: InputMaybe<Payment_Aggregate_Order_By>;
  postTaxTotal?: InputMaybe<Order_By>;
  preTaxTotal?: InputMaybe<Order_By>;
  productItems_aggregate?: InputMaybe<InvoiceProductItem_Aggregate_Order_By>;
  provider?: InputMaybe<Provider_Order_By>;
  providerId?: InputMaybe<Order_By>;
  serviceItems_aggregate?: InputMaybe<InvoiceServiceItem_Aggregate_Order_By>;
  signatureId?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  taxTotal?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  workspace?: InputMaybe<Workspace_Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: invoice */
export type Invoice_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "invoice" */
export enum Invoice_Select_Column {
  /** column name */
  Address = 'address',
  /** column name */
  AppointmentId = 'appointmentId',
  /** column name */
  BalanceDue = 'balanceDue',
  /** column name */
  CashierId = 'cashierId',
  /** column name */
  ConsumableOverride = 'consumableOverride',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CreditsApplied = 'creditsApplied',
  /** column name */
  DepositPaid = 'depositPaid',
  /** column name */
  Discount = 'discount',
  /** column name */
  DueDate = 'dueDate',
  /** column name */
  Gratuity = 'gratuity',
  /** column name */
  Id = 'id',
  /** column name */
  InvoiceNumber = 'invoiceNumber',
  /** column name */
  IsArchived = 'isArchived',
  /** column name */
  LocationId = 'locationId',
  /** column name */
  Message = 'message',
  /** column name */
  Note = 'note',
  /** column name */
  PaidOn = 'paidOn',
  /** column name */
  PatientId = 'patientId',
  /** column name */
  PatientMembershipId = 'patientMembershipId',
  /** column name */
  PatientWalletDiscountApplied = 'patientWalletDiscountApplied',
  /** column name */
  PostTaxTotal = 'postTaxTotal',
  /** column name */
  PreTaxTotal = 'preTaxTotal',
  /** column name */
  ProviderId = 'providerId',
  /** column name */
  SignatureId = 'signatureId',
  /** column name */
  Status = 'status',
  /** column name */
  TaxTotal = 'taxTotal',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WorkspaceId = 'workspaceId'
}

/** select "invoice_aggregate_bool_exp_bool_and_arguments_columns" columns of table "invoice" */
export enum Invoice_Select_Column_Invoice_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsArchived = 'isArchived',
  /** column name */
  PatientWalletDiscountApplied = 'patientWalletDiscountApplied'
}

/** select "invoice_aggregate_bool_exp_bool_or_arguments_columns" columns of table "invoice" */
export enum Invoice_Select_Column_Invoice_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsArchived = 'isArchived',
  /** column name */
  PatientWalletDiscountApplied = 'patientWalletDiscountApplied'
}

/** input type for updating data in table "invoice" */
export type Invoice_Set_Input = {
  address?: InputMaybe<Scalars['String']>;
  appointmentId?: InputMaybe<Scalars['uuid']>;
  balanceDue?: InputMaybe<Scalars['Int']>;
  cashierId?: InputMaybe<Scalars['uuid']>;
  consumableOverride?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  creditsApplied?: InputMaybe<Scalars['Int']>;
  depositPaid?: InputMaybe<Scalars['Int']>;
  discount?: InputMaybe<Scalars['Int']>;
  dueDate?: InputMaybe<Scalars['timestamptz']>;
  gratuity?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  invoiceNumber?: InputMaybe<Scalars['String']>;
  isArchived?: InputMaybe<Scalars['Boolean']>;
  locationId?: InputMaybe<Scalars['uuid']>;
  message?: InputMaybe<Scalars['String']>;
  note?: InputMaybe<Scalars['String']>;
  paidOn?: InputMaybe<Scalars['timestamptz']>;
  patientId?: InputMaybe<Scalars['uuid']>;
  patientMembershipId?: InputMaybe<Scalars['uuid']>;
  patientWalletDiscountApplied?: InputMaybe<Scalars['Boolean']>;
  postTaxTotal?: InputMaybe<Scalars['Int']>;
  preTaxTotal?: InputMaybe<Scalars['Int']>;
  providerId?: InputMaybe<Scalars['uuid']>;
  signatureId?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Scalars['String']>;
  taxTotal?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Invoice_Stddev_Fields = {
  __typename?: 'invoice_stddev_fields';
  balanceDue?: Maybe<Scalars['Float']>;
  creditsApplied?: Maybe<Scalars['Float']>;
  depositPaid?: Maybe<Scalars['Float']>;
  discount?: Maybe<Scalars['Float']>;
  gratuity?: Maybe<Scalars['Float']>;
  postTaxTotal?: Maybe<Scalars['Float']>;
  preTaxTotal?: Maybe<Scalars['Float']>;
  taxTotal?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "invoice" */
export type Invoice_Stddev_Order_By = {
  balanceDue?: InputMaybe<Order_By>;
  creditsApplied?: InputMaybe<Order_By>;
  depositPaid?: InputMaybe<Order_By>;
  discount?: InputMaybe<Order_By>;
  gratuity?: InputMaybe<Order_By>;
  postTaxTotal?: InputMaybe<Order_By>;
  preTaxTotal?: InputMaybe<Order_By>;
  taxTotal?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Invoice_Stddev_Pop_Fields = {
  __typename?: 'invoice_stddev_pop_fields';
  balanceDue?: Maybe<Scalars['Float']>;
  creditsApplied?: Maybe<Scalars['Float']>;
  depositPaid?: Maybe<Scalars['Float']>;
  discount?: Maybe<Scalars['Float']>;
  gratuity?: Maybe<Scalars['Float']>;
  postTaxTotal?: Maybe<Scalars['Float']>;
  preTaxTotal?: Maybe<Scalars['Float']>;
  taxTotal?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "invoice" */
export type Invoice_Stddev_Pop_Order_By = {
  balanceDue?: InputMaybe<Order_By>;
  creditsApplied?: InputMaybe<Order_By>;
  depositPaid?: InputMaybe<Order_By>;
  discount?: InputMaybe<Order_By>;
  gratuity?: InputMaybe<Order_By>;
  postTaxTotal?: InputMaybe<Order_By>;
  preTaxTotal?: InputMaybe<Order_By>;
  taxTotal?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Invoice_Stddev_Samp_Fields = {
  __typename?: 'invoice_stddev_samp_fields';
  balanceDue?: Maybe<Scalars['Float']>;
  creditsApplied?: Maybe<Scalars['Float']>;
  depositPaid?: Maybe<Scalars['Float']>;
  discount?: Maybe<Scalars['Float']>;
  gratuity?: Maybe<Scalars['Float']>;
  postTaxTotal?: Maybe<Scalars['Float']>;
  preTaxTotal?: Maybe<Scalars['Float']>;
  taxTotal?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "invoice" */
export type Invoice_Stddev_Samp_Order_By = {
  balanceDue?: InputMaybe<Order_By>;
  creditsApplied?: InputMaybe<Order_By>;
  depositPaid?: InputMaybe<Order_By>;
  discount?: InputMaybe<Order_By>;
  gratuity?: InputMaybe<Order_By>;
  postTaxTotal?: InputMaybe<Order_By>;
  preTaxTotal?: InputMaybe<Order_By>;
  taxTotal?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "invoice" */
export type Invoice_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Invoice_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Invoice_Stream_Cursor_Value_Input = {
  address?: InputMaybe<Scalars['String']>;
  appointmentId?: InputMaybe<Scalars['uuid']>;
  balanceDue?: InputMaybe<Scalars['Int']>;
  cashierId?: InputMaybe<Scalars['uuid']>;
  consumableOverride?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  creditsApplied?: InputMaybe<Scalars['Int']>;
  depositPaid?: InputMaybe<Scalars['Int']>;
  discount?: InputMaybe<Scalars['Int']>;
  dueDate?: InputMaybe<Scalars['timestamptz']>;
  gratuity?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  invoiceNumber?: InputMaybe<Scalars['String']>;
  isArchived?: InputMaybe<Scalars['Boolean']>;
  locationId?: InputMaybe<Scalars['uuid']>;
  message?: InputMaybe<Scalars['String']>;
  note?: InputMaybe<Scalars['String']>;
  paidOn?: InputMaybe<Scalars['timestamptz']>;
  patientId?: InputMaybe<Scalars['uuid']>;
  patientMembershipId?: InputMaybe<Scalars['uuid']>;
  patientWalletDiscountApplied?: InputMaybe<Scalars['Boolean']>;
  postTaxTotal?: InputMaybe<Scalars['Int']>;
  preTaxTotal?: InputMaybe<Scalars['Int']>;
  providerId?: InputMaybe<Scalars['uuid']>;
  signatureId?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Scalars['String']>;
  taxTotal?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Invoice_Sum_Fields = {
  __typename?: 'invoice_sum_fields';
  balanceDue?: Maybe<Scalars['Int']>;
  creditsApplied?: Maybe<Scalars['Int']>;
  depositPaid?: Maybe<Scalars['Int']>;
  discount?: Maybe<Scalars['Int']>;
  gratuity?: Maybe<Scalars['Int']>;
  postTaxTotal?: Maybe<Scalars['Int']>;
  preTaxTotal?: Maybe<Scalars['Int']>;
  taxTotal?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "invoice" */
export type Invoice_Sum_Order_By = {
  balanceDue?: InputMaybe<Order_By>;
  creditsApplied?: InputMaybe<Order_By>;
  depositPaid?: InputMaybe<Order_By>;
  discount?: InputMaybe<Order_By>;
  gratuity?: InputMaybe<Order_By>;
  postTaxTotal?: InputMaybe<Order_By>;
  preTaxTotal?: InputMaybe<Order_By>;
  taxTotal?: InputMaybe<Order_By>;
};

/** update columns of table "invoice" */
export enum Invoice_Update_Column {
  /** column name */
  Address = 'address',
  /** column name */
  AppointmentId = 'appointmentId',
  /** column name */
  BalanceDue = 'balanceDue',
  /** column name */
  CashierId = 'cashierId',
  /** column name */
  ConsumableOverride = 'consumableOverride',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CreditsApplied = 'creditsApplied',
  /** column name */
  DepositPaid = 'depositPaid',
  /** column name */
  Discount = 'discount',
  /** column name */
  DueDate = 'dueDate',
  /** column name */
  Gratuity = 'gratuity',
  /** column name */
  Id = 'id',
  /** column name */
  InvoiceNumber = 'invoiceNumber',
  /** column name */
  IsArchived = 'isArchived',
  /** column name */
  LocationId = 'locationId',
  /** column name */
  Message = 'message',
  /** column name */
  Note = 'note',
  /** column name */
  PaidOn = 'paidOn',
  /** column name */
  PatientId = 'patientId',
  /** column name */
  PatientMembershipId = 'patientMembershipId',
  /** column name */
  PatientWalletDiscountApplied = 'patientWalletDiscountApplied',
  /** column name */
  PostTaxTotal = 'postTaxTotal',
  /** column name */
  PreTaxTotal = 'preTaxTotal',
  /** column name */
  ProviderId = 'providerId',
  /** column name */
  SignatureId = 'signatureId',
  /** column name */
  Status = 'status',
  /** column name */
  TaxTotal = 'taxTotal',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WorkspaceId = 'workspaceId'
}

export type Invoice_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Invoice_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Invoice_Set_Input>;
  /** filter the rows which have to be updated */
  where: Invoice_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Invoice_Var_Pop_Fields = {
  __typename?: 'invoice_var_pop_fields';
  balanceDue?: Maybe<Scalars['Float']>;
  creditsApplied?: Maybe<Scalars['Float']>;
  depositPaid?: Maybe<Scalars['Float']>;
  discount?: Maybe<Scalars['Float']>;
  gratuity?: Maybe<Scalars['Float']>;
  postTaxTotal?: Maybe<Scalars['Float']>;
  preTaxTotal?: Maybe<Scalars['Float']>;
  taxTotal?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "invoice" */
export type Invoice_Var_Pop_Order_By = {
  balanceDue?: InputMaybe<Order_By>;
  creditsApplied?: InputMaybe<Order_By>;
  depositPaid?: InputMaybe<Order_By>;
  discount?: InputMaybe<Order_By>;
  gratuity?: InputMaybe<Order_By>;
  postTaxTotal?: InputMaybe<Order_By>;
  preTaxTotal?: InputMaybe<Order_By>;
  taxTotal?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Invoice_Var_Samp_Fields = {
  __typename?: 'invoice_var_samp_fields';
  balanceDue?: Maybe<Scalars['Float']>;
  creditsApplied?: Maybe<Scalars['Float']>;
  depositPaid?: Maybe<Scalars['Float']>;
  discount?: Maybe<Scalars['Float']>;
  gratuity?: Maybe<Scalars['Float']>;
  postTaxTotal?: Maybe<Scalars['Float']>;
  preTaxTotal?: Maybe<Scalars['Float']>;
  taxTotal?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "invoice" */
export type Invoice_Var_Samp_Order_By = {
  balanceDue?: InputMaybe<Order_By>;
  creditsApplied?: InputMaybe<Order_By>;
  depositPaid?: InputMaybe<Order_By>;
  discount?: InputMaybe<Order_By>;
  gratuity?: InputMaybe<Order_By>;
  postTaxTotal?: InputMaybe<Order_By>;
  preTaxTotal?: InputMaybe<Order_By>;
  taxTotal?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Invoice_Variance_Fields = {
  __typename?: 'invoice_variance_fields';
  balanceDue?: Maybe<Scalars['Float']>;
  creditsApplied?: Maybe<Scalars['Float']>;
  depositPaid?: Maybe<Scalars['Float']>;
  discount?: Maybe<Scalars['Float']>;
  gratuity?: Maybe<Scalars['Float']>;
  postTaxTotal?: Maybe<Scalars['Float']>;
  preTaxTotal?: Maybe<Scalars['Float']>;
  taxTotal?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "invoice" */
export type Invoice_Variance_Order_By = {
  balanceDue?: InputMaybe<Order_By>;
  creditsApplied?: InputMaybe<Order_By>;
  depositPaid?: InputMaybe<Order_By>;
  discount?: InputMaybe<Order_By>;
  gratuity?: InputMaybe<Order_By>;
  postTaxTotal?: InputMaybe<Order_By>;
  preTaxTotal?: InputMaybe<Order_By>;
  taxTotal?: InputMaybe<Order_By>;
};

/** columns and relationships of "job" */
export type Job = {
  __typename?: 'job';
  action: Scalars['String'];
  actualDequeueAt?: Maybe<Scalars['timestamptz']>;
  created_at: Scalars['timestamptz'];
  enqueuedAt?: Maybe<Scalars['timestamptz']>;
  errorMessage?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  intendedDequeueAt?: Maybe<Scalars['timestamptz']>;
  isDelayed: Scalars['Boolean'];
  /** An object relationship */
  jobStatus: JobStatus;
  resolution: ResolutionStatus_Enum;
  /** An object relationship */
  resolutionStatus: ResolutionStatus;
  resourceIds?: Maybe<Scalars['String']>;
  retryCount: Scalars['Int'];
  /** An array relationship */
  socialMediaAccountPosts: Array<SocialMediaAccountPost>;
  /** An aggregate relationship */
  socialMediaAccountPosts_aggregate: SocialMediaAccountPost_Aggregate;
  status: JobStatus_Enum;
  type: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "job" */
export type JobSocialMediaAccountPostsArgs = {
  distinct_on?: InputMaybe<Array<SocialMediaAccountPost_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SocialMediaAccountPost_Order_By>>;
  where?: InputMaybe<SocialMediaAccountPost_Bool_Exp>;
};


/** columns and relationships of "job" */
export type JobSocialMediaAccountPosts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<SocialMediaAccountPost_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SocialMediaAccountPost_Order_By>>;
  where?: InputMaybe<SocialMediaAccountPost_Bool_Exp>;
};

/** columns and relationships of "jobStatus" */
export type JobStatus = {
  __typename?: 'jobStatus';
  /** An array relationship */
  jobs: Array<Job>;
  /** An aggregate relationship */
  jobs_aggregate: Job_Aggregate;
  value: Scalars['String'];
};


/** columns and relationships of "jobStatus" */
export type JobStatusJobsArgs = {
  distinct_on?: InputMaybe<Array<Job_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Job_Order_By>>;
  where?: InputMaybe<Job_Bool_Exp>;
};


/** columns and relationships of "jobStatus" */
export type JobStatusJobs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Job_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Job_Order_By>>;
  where?: InputMaybe<Job_Bool_Exp>;
};

/** aggregated selection of "jobStatus" */
export type JobStatus_Aggregate = {
  __typename?: 'jobStatus_aggregate';
  aggregate?: Maybe<JobStatus_Aggregate_Fields>;
  nodes: Array<JobStatus>;
};

/** aggregate fields of "jobStatus" */
export type JobStatus_Aggregate_Fields = {
  __typename?: 'jobStatus_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<JobStatus_Max_Fields>;
  min?: Maybe<JobStatus_Min_Fields>;
};


/** aggregate fields of "jobStatus" */
export type JobStatus_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<JobStatus_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "jobStatus". All fields are combined with a logical 'AND'. */
export type JobStatus_Bool_Exp = {
  _and?: InputMaybe<Array<JobStatus_Bool_Exp>>;
  _not?: InputMaybe<JobStatus_Bool_Exp>;
  _or?: InputMaybe<Array<JobStatus_Bool_Exp>>;
  jobs?: InputMaybe<Job_Bool_Exp>;
  jobs_aggregate?: InputMaybe<Job_Aggregate_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "jobStatus" */
export enum JobStatus_Constraint {
  /** unique or primary key constraint on columns "value" */
  JobTypePkey = 'jobType_pkey'
}

export enum JobStatus_Enum {
  Canceled = 'CANCELED',
  Created = 'CREATED',
  Dequeued = 'DEQUEUED',
  Enqueued = 'ENQUEUED'
}

/** Boolean expression to compare columns of type "jobStatus_enum". All fields are combined with logical 'AND'. */
export type JobStatus_Enum_Comparison_Exp = {
  _eq?: InputMaybe<JobStatus_Enum>;
  _in?: InputMaybe<Array<JobStatus_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<JobStatus_Enum>;
  _nin?: InputMaybe<Array<JobStatus_Enum>>;
};

/** input type for inserting data into table "jobStatus" */
export type JobStatus_Insert_Input = {
  jobs?: InputMaybe<Job_Arr_Rel_Insert_Input>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type JobStatus_Max_Fields = {
  __typename?: 'jobStatus_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type JobStatus_Min_Fields = {
  __typename?: 'jobStatus_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "jobStatus" */
export type JobStatus_Mutation_Response = {
  __typename?: 'jobStatus_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<JobStatus>;
};

/** input type for inserting object relation for remote table "jobStatus" */
export type JobStatus_Obj_Rel_Insert_Input = {
  data: JobStatus_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<JobStatus_On_Conflict>;
};

/** on_conflict condition type for table "jobStatus" */
export type JobStatus_On_Conflict = {
  constraint: JobStatus_Constraint;
  update_columns?: Array<JobStatus_Update_Column>;
  where?: InputMaybe<JobStatus_Bool_Exp>;
};

/** Ordering options when selecting data from "jobStatus". */
export type JobStatus_Order_By = {
  jobs_aggregate?: InputMaybe<Job_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: jobStatus */
export type JobStatus_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "jobStatus" */
export enum JobStatus_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "jobStatus" */
export type JobStatus_Set_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "jobStatus" */
export type JobStatus_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: JobStatus_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type JobStatus_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "jobStatus" */
export enum JobStatus_Update_Column {
  /** column name */
  Value = 'value'
}

export type JobStatus_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<JobStatus_Set_Input>;
  /** filter the rows which have to be updated */
  where: JobStatus_Bool_Exp;
};

/** aggregated selection of "job" */
export type Job_Aggregate = {
  __typename?: 'job_aggregate';
  aggregate?: Maybe<Job_Aggregate_Fields>;
  nodes: Array<Job>;
};

export type Job_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Job_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Job_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Job_Aggregate_Bool_Exp_Count>;
};

export type Job_Aggregate_Bool_Exp_Bool_And = {
  arguments: Job_Select_Column_Job_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Job_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Job_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Job_Select_Column_Job_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Job_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Job_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Job_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Job_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "job" */
export type Job_Aggregate_Fields = {
  __typename?: 'job_aggregate_fields';
  avg?: Maybe<Job_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Job_Max_Fields>;
  min?: Maybe<Job_Min_Fields>;
  stddev?: Maybe<Job_Stddev_Fields>;
  stddev_pop?: Maybe<Job_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Job_Stddev_Samp_Fields>;
  sum?: Maybe<Job_Sum_Fields>;
  var_pop?: Maybe<Job_Var_Pop_Fields>;
  var_samp?: Maybe<Job_Var_Samp_Fields>;
  variance?: Maybe<Job_Variance_Fields>;
};


/** aggregate fields of "job" */
export type Job_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Job_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "job" */
export type Job_Aggregate_Order_By = {
  avg?: InputMaybe<Job_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Job_Max_Order_By>;
  min?: InputMaybe<Job_Min_Order_By>;
  stddev?: InputMaybe<Job_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Job_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Job_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Job_Sum_Order_By>;
  var_pop?: InputMaybe<Job_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Job_Var_Samp_Order_By>;
  variance?: InputMaybe<Job_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "job" */
export type Job_Arr_Rel_Insert_Input = {
  data: Array<Job_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Job_On_Conflict>;
};

/** aggregate avg on columns */
export type Job_Avg_Fields = {
  __typename?: 'job_avg_fields';
  retryCount?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "job" */
export type Job_Avg_Order_By = {
  retryCount?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "job". All fields are combined with a logical 'AND'. */
export type Job_Bool_Exp = {
  _and?: InputMaybe<Array<Job_Bool_Exp>>;
  _not?: InputMaybe<Job_Bool_Exp>;
  _or?: InputMaybe<Array<Job_Bool_Exp>>;
  action?: InputMaybe<String_Comparison_Exp>;
  actualDequeueAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  enqueuedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  errorMessage?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  intendedDequeueAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  isDelayed?: InputMaybe<Boolean_Comparison_Exp>;
  jobStatus?: InputMaybe<JobStatus_Bool_Exp>;
  resolution?: InputMaybe<ResolutionStatus_Enum_Comparison_Exp>;
  resolutionStatus?: InputMaybe<ResolutionStatus_Bool_Exp>;
  resourceIds?: InputMaybe<String_Comparison_Exp>;
  retryCount?: InputMaybe<Int_Comparison_Exp>;
  socialMediaAccountPosts?: InputMaybe<SocialMediaAccountPost_Bool_Exp>;
  socialMediaAccountPosts_aggregate?: InputMaybe<SocialMediaAccountPost_Aggregate_Bool_Exp>;
  status?: InputMaybe<JobStatus_Enum_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "job" */
export enum Job_Constraint {
  /** unique or primary key constraint on columns "id" */
  JobPkey = 'job_pkey'
}

/** input type for incrementing numeric columns in table "job" */
export type Job_Inc_Input = {
  retryCount?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "job" */
export type Job_Insert_Input = {
  action?: InputMaybe<Scalars['String']>;
  actualDequeueAt?: InputMaybe<Scalars['timestamptz']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  enqueuedAt?: InputMaybe<Scalars['timestamptz']>;
  errorMessage?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  intendedDequeueAt?: InputMaybe<Scalars['timestamptz']>;
  isDelayed?: InputMaybe<Scalars['Boolean']>;
  jobStatus?: InputMaybe<JobStatus_Obj_Rel_Insert_Input>;
  resolution?: InputMaybe<ResolutionStatus_Enum>;
  resolutionStatus?: InputMaybe<ResolutionStatus_Obj_Rel_Insert_Input>;
  resourceIds?: InputMaybe<Scalars['String']>;
  retryCount?: InputMaybe<Scalars['Int']>;
  socialMediaAccountPosts?: InputMaybe<SocialMediaAccountPost_Arr_Rel_Insert_Input>;
  status?: InputMaybe<JobStatus_Enum>;
  type?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Job_Max_Fields = {
  __typename?: 'job_max_fields';
  action?: Maybe<Scalars['String']>;
  actualDequeueAt?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  enqueuedAt?: Maybe<Scalars['timestamptz']>;
  errorMessage?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  intendedDequeueAt?: Maybe<Scalars['timestamptz']>;
  resourceIds?: Maybe<Scalars['String']>;
  retryCount?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "job" */
export type Job_Max_Order_By = {
  action?: InputMaybe<Order_By>;
  actualDequeueAt?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  enqueuedAt?: InputMaybe<Order_By>;
  errorMessage?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  intendedDequeueAt?: InputMaybe<Order_By>;
  resourceIds?: InputMaybe<Order_By>;
  retryCount?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Job_Min_Fields = {
  __typename?: 'job_min_fields';
  action?: Maybe<Scalars['String']>;
  actualDequeueAt?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  enqueuedAt?: Maybe<Scalars['timestamptz']>;
  errorMessage?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  intendedDequeueAt?: Maybe<Scalars['timestamptz']>;
  resourceIds?: Maybe<Scalars['String']>;
  retryCount?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "job" */
export type Job_Min_Order_By = {
  action?: InputMaybe<Order_By>;
  actualDequeueAt?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  enqueuedAt?: InputMaybe<Order_By>;
  errorMessage?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  intendedDequeueAt?: InputMaybe<Order_By>;
  resourceIds?: InputMaybe<Order_By>;
  retryCount?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "job" */
export type Job_Mutation_Response = {
  __typename?: 'job_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Job>;
};

/** input type for inserting object relation for remote table "job" */
export type Job_Obj_Rel_Insert_Input = {
  data: Job_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Job_On_Conflict>;
};

/** on_conflict condition type for table "job" */
export type Job_On_Conflict = {
  constraint: Job_Constraint;
  update_columns?: Array<Job_Update_Column>;
  where?: InputMaybe<Job_Bool_Exp>;
};

/** Ordering options when selecting data from "job". */
export type Job_Order_By = {
  action?: InputMaybe<Order_By>;
  actualDequeueAt?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  enqueuedAt?: InputMaybe<Order_By>;
  errorMessage?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  intendedDequeueAt?: InputMaybe<Order_By>;
  isDelayed?: InputMaybe<Order_By>;
  jobStatus?: InputMaybe<JobStatus_Order_By>;
  resolution?: InputMaybe<Order_By>;
  resolutionStatus?: InputMaybe<ResolutionStatus_Order_By>;
  resourceIds?: InputMaybe<Order_By>;
  retryCount?: InputMaybe<Order_By>;
  socialMediaAccountPosts_aggregate?: InputMaybe<SocialMediaAccountPost_Aggregate_Order_By>;
  status?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: job */
export type Job_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "job" */
export enum Job_Select_Column {
  /** column name */
  Action = 'action',
  /** column name */
  ActualDequeueAt = 'actualDequeueAt',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EnqueuedAt = 'enqueuedAt',
  /** column name */
  ErrorMessage = 'errorMessage',
  /** column name */
  Id = 'id',
  /** column name */
  IntendedDequeueAt = 'intendedDequeueAt',
  /** column name */
  IsDelayed = 'isDelayed',
  /** column name */
  Resolution = 'resolution',
  /** column name */
  ResourceIds = 'resourceIds',
  /** column name */
  RetryCount = 'retryCount',
  /** column name */
  Status = 'status',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "job_aggregate_bool_exp_bool_and_arguments_columns" columns of table "job" */
export enum Job_Select_Column_Job_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsDelayed = 'isDelayed'
}

/** select "job_aggregate_bool_exp_bool_or_arguments_columns" columns of table "job" */
export enum Job_Select_Column_Job_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsDelayed = 'isDelayed'
}

/** input type for updating data in table "job" */
export type Job_Set_Input = {
  action?: InputMaybe<Scalars['String']>;
  actualDequeueAt?: InputMaybe<Scalars['timestamptz']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  enqueuedAt?: InputMaybe<Scalars['timestamptz']>;
  errorMessage?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  intendedDequeueAt?: InputMaybe<Scalars['timestamptz']>;
  isDelayed?: InputMaybe<Scalars['Boolean']>;
  resolution?: InputMaybe<ResolutionStatus_Enum>;
  resourceIds?: InputMaybe<Scalars['String']>;
  retryCount?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<JobStatus_Enum>;
  type?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Job_Stddev_Fields = {
  __typename?: 'job_stddev_fields';
  retryCount?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "job" */
export type Job_Stddev_Order_By = {
  retryCount?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Job_Stddev_Pop_Fields = {
  __typename?: 'job_stddev_pop_fields';
  retryCount?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "job" */
export type Job_Stddev_Pop_Order_By = {
  retryCount?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Job_Stddev_Samp_Fields = {
  __typename?: 'job_stddev_samp_fields';
  retryCount?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "job" */
export type Job_Stddev_Samp_Order_By = {
  retryCount?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "job" */
export type Job_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Job_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Job_Stream_Cursor_Value_Input = {
  action?: InputMaybe<Scalars['String']>;
  actualDequeueAt?: InputMaybe<Scalars['timestamptz']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  enqueuedAt?: InputMaybe<Scalars['timestamptz']>;
  errorMessage?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  intendedDequeueAt?: InputMaybe<Scalars['timestamptz']>;
  isDelayed?: InputMaybe<Scalars['Boolean']>;
  resolution?: InputMaybe<ResolutionStatus_Enum>;
  resourceIds?: InputMaybe<Scalars['String']>;
  retryCount?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<JobStatus_Enum>;
  type?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Job_Sum_Fields = {
  __typename?: 'job_sum_fields';
  retryCount?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "job" */
export type Job_Sum_Order_By = {
  retryCount?: InputMaybe<Order_By>;
};

/** update columns of table "job" */
export enum Job_Update_Column {
  /** column name */
  Action = 'action',
  /** column name */
  ActualDequeueAt = 'actualDequeueAt',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EnqueuedAt = 'enqueuedAt',
  /** column name */
  ErrorMessage = 'errorMessage',
  /** column name */
  Id = 'id',
  /** column name */
  IntendedDequeueAt = 'intendedDequeueAt',
  /** column name */
  IsDelayed = 'isDelayed',
  /** column name */
  Resolution = 'resolution',
  /** column name */
  ResourceIds = 'resourceIds',
  /** column name */
  RetryCount = 'retryCount',
  /** column name */
  Status = 'status',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Job_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Job_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Job_Set_Input>;
  /** filter the rows which have to be updated */
  where: Job_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Job_Var_Pop_Fields = {
  __typename?: 'job_var_pop_fields';
  retryCount?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "job" */
export type Job_Var_Pop_Order_By = {
  retryCount?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Job_Var_Samp_Fields = {
  __typename?: 'job_var_samp_fields';
  retryCount?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "job" */
export type Job_Var_Samp_Order_By = {
  retryCount?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Job_Variance_Fields = {
  __typename?: 'job_variance_fields';
  retryCount?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "job" */
export type Job_Variance_Order_By = {
  retryCount?: InputMaybe<Order_By>;
};

export type Jsonb_Cast_Exp = {
  String?: InputMaybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type Jsonb_Comparison_Exp = {
  _cast?: InputMaybe<Jsonb_Cast_Exp>;
  /** is the column contained in the given json value */
  _contained_in?: InputMaybe<Scalars['jsonb']>;
  /** does the column contain the given json value at the top level */
  _contains?: InputMaybe<Scalars['jsonb']>;
  _eq?: InputMaybe<Scalars['jsonb']>;
  _gt?: InputMaybe<Scalars['jsonb']>;
  _gte?: InputMaybe<Scalars['jsonb']>;
  /** does the string exist as a top-level key in the column */
  _has_key?: InputMaybe<Scalars['String']>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: InputMaybe<Array<Scalars['String']>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: InputMaybe<Array<Scalars['String']>>;
  _in?: InputMaybe<Array<Scalars['jsonb']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['jsonb']>;
  _lte?: InputMaybe<Scalars['jsonb']>;
  _neq?: InputMaybe<Scalars['jsonb']>;
  _nin?: InputMaybe<Array<Scalars['jsonb']>>;
};

/** columns and relationships of "labResult" */
export type LabResult = {
  __typename?: 'labResult';
  createdAt: Scalars['timestamptz'];
  documents?: Maybe<Scalars['jsonb']>;
  id: Scalars['uuid'];
  patientWorkspaceId?: Maybe<Scalars['uuid']>;
  result: Scalars['jsonb'];
  updatedAt: Scalars['timestamptz'];
  workspaceId?: Maybe<Scalars['uuid']>;
};


/** columns and relationships of "labResult" */
export type LabResultDocumentsArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "labResult" */
export type LabResultResultArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "labResult" */
export type LabResult_Aggregate = {
  __typename?: 'labResult_aggregate';
  aggregate?: Maybe<LabResult_Aggregate_Fields>;
  nodes: Array<LabResult>;
};

/** aggregate fields of "labResult" */
export type LabResult_Aggregate_Fields = {
  __typename?: 'labResult_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<LabResult_Max_Fields>;
  min?: Maybe<LabResult_Min_Fields>;
};


/** aggregate fields of "labResult" */
export type LabResult_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<LabResult_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type LabResult_Append_Input = {
  documents?: InputMaybe<Scalars['jsonb']>;
  result?: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "labResult". All fields are combined with a logical 'AND'. */
export type LabResult_Bool_Exp = {
  _and?: InputMaybe<Array<LabResult_Bool_Exp>>;
  _not?: InputMaybe<LabResult_Bool_Exp>;
  _or?: InputMaybe<Array<LabResult_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  documents?: InputMaybe<Jsonb_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  patientWorkspaceId?: InputMaybe<Uuid_Comparison_Exp>;
  result?: InputMaybe<Jsonb_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  workspaceId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "labResult" */
export enum LabResult_Constraint {
  /** unique or primary key constraint on columns "id" */
  LabResultPkey = 'labResult_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type LabResult_Delete_At_Path_Input = {
  documents?: InputMaybe<Array<Scalars['String']>>;
  result?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type LabResult_Delete_Elem_Input = {
  documents?: InputMaybe<Scalars['Int']>;
  result?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type LabResult_Delete_Key_Input = {
  documents?: InputMaybe<Scalars['String']>;
  result?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "labResult" */
export type LabResult_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  documents?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['uuid']>;
  patientWorkspaceId?: InputMaybe<Scalars['uuid']>;
  result?: InputMaybe<Scalars['jsonb']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type LabResult_Max_Fields = {
  __typename?: 'labResult_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  patientWorkspaceId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type LabResult_Min_Fields = {
  __typename?: 'labResult_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  patientWorkspaceId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "labResult" */
export type LabResult_Mutation_Response = {
  __typename?: 'labResult_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<LabResult>;
};

/** on_conflict condition type for table "labResult" */
export type LabResult_On_Conflict = {
  constraint: LabResult_Constraint;
  update_columns?: Array<LabResult_Update_Column>;
  where?: InputMaybe<LabResult_Bool_Exp>;
};

/** Ordering options when selecting data from "labResult". */
export type LabResult_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  documents?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patientWorkspaceId?: InputMaybe<Order_By>;
  result?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: labResult */
export type LabResult_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type LabResult_Prepend_Input = {
  documents?: InputMaybe<Scalars['jsonb']>;
  result?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "labResult" */
export enum LabResult_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Documents = 'documents',
  /** column name */
  Id = 'id',
  /** column name */
  PatientWorkspaceId = 'patientWorkspaceId',
  /** column name */
  Result = 'result',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WorkspaceId = 'workspaceId'
}

/** input type for updating data in table "labResult" */
export type LabResult_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  documents?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['uuid']>;
  patientWorkspaceId?: InputMaybe<Scalars['uuid']>;
  result?: InputMaybe<Scalars['jsonb']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "labResult" */
export type LabResult_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: LabResult_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type LabResult_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  documents?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['uuid']>;
  patientWorkspaceId?: InputMaybe<Scalars['uuid']>;
  result?: InputMaybe<Scalars['jsonb']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "labResult" */
export enum LabResult_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Documents = 'documents',
  /** column name */
  Id = 'id',
  /** column name */
  PatientWorkspaceId = 'patientWorkspaceId',
  /** column name */
  Result = 'result',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WorkspaceId = 'workspaceId'
}

export type LabResult_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<LabResult_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<LabResult_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<LabResult_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<LabResult_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<LabResult_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<LabResult_Set_Input>;
  /** filter the rows which have to be updated */
  where: LabResult_Bool_Exp;
};

/** columns and relationships of "leadForm" */
export type LeadForm = {
  __typename?: 'leadForm';
  backgroundColor?: Maybe<Scalars['String']>;
  businessLogo?: Maybe<Scalars['String']>;
  createdAt: Scalars['timestamptz'];
  font?: Maybe<Scalars['String']>;
  formBackground?: Maybe<Scalars['String']>;
  headerCode?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  isArchived?: Maybe<Scalars['Boolean']>;
  layout?: Maybe<Scalars['String']>;
  /** An array relationship */
  leadFormEntries: Array<LeadFormEntry>;
  /** An aggregate relationship */
  leadFormEntries_aggregate: LeadFormEntry_Aggregate;
  /** An array relationship */
  leadFormSteps: Array<LeadFormStep>;
  /** An aggregate relationship */
  leadFormSteps_aggregate: LeadFormStep_Aggregate;
  lockedToLocations?: Maybe<Scalars['jsonb']>;
  lockedToServices?: Maybe<Scalars['jsonb']>;
  name: Scalars['String'];
  sideBannerImage?: Maybe<Scalars['String']>;
  successMessage?: Maybe<Scalars['String']>;
  surveyJSJson?: Maybe<Scalars['jsonb']>;
  themeJSON?: Maybe<Scalars['jsonb']>;
  updatedAt: Scalars['timestamptz'];
  /** An object relationship */
  workspace: Workspace;
  workspaceId: Scalars['uuid'];
};


/** columns and relationships of "leadForm" */
export type LeadFormLeadFormEntriesArgs = {
  distinct_on?: InputMaybe<Array<LeadFormEntry_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<LeadFormEntry_Order_By>>;
  where?: InputMaybe<LeadFormEntry_Bool_Exp>;
};


/** columns and relationships of "leadForm" */
export type LeadFormLeadFormEntries_AggregateArgs = {
  distinct_on?: InputMaybe<Array<LeadFormEntry_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<LeadFormEntry_Order_By>>;
  where?: InputMaybe<LeadFormEntry_Bool_Exp>;
};


/** columns and relationships of "leadForm" */
export type LeadFormLeadFormStepsArgs = {
  distinct_on?: InputMaybe<Array<LeadFormStep_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<LeadFormStep_Order_By>>;
  where?: InputMaybe<LeadFormStep_Bool_Exp>;
};


/** columns and relationships of "leadForm" */
export type LeadFormLeadFormSteps_AggregateArgs = {
  distinct_on?: InputMaybe<Array<LeadFormStep_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<LeadFormStep_Order_By>>;
  where?: InputMaybe<LeadFormStep_Bool_Exp>;
};


/** columns and relationships of "leadForm" */
export type LeadFormLockedToLocationsArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "leadForm" */
export type LeadFormLockedToServicesArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "leadForm" */
export type LeadFormSurveyJsJsonArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "leadForm" */
export type LeadFormThemeJsonArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "leadFormEntry" */
export type LeadFormEntry = {
  __typename?: 'leadFormEntry';
  archived?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An object relationship */
  leadForm?: Maybe<LeadForm>;
  /** An array relationship */
  leadFormEntryTags: Array<LeadFormEntryTag>;
  /** An aggregate relationship */
  leadFormEntryTags_aggregate: LeadFormEntryTag_Aggregate;
  /** An array relationship */
  leadFormFieldValues: Array<LeadFormFieldValue>;
  /** An aggregate relationship */
  leadFormFieldValues_aggregate: LeadFormFieldValue_Aggregate;
  leadFormId?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  patient: Patient;
  patientId: Scalars['uuid'];
  surveyJSData?: Maybe<Scalars['jsonb']>;
  updatedAt: Scalars['timestamptz'];
  utmParameters?: Maybe<Scalars['jsonb']>;
  /** An object relationship */
  workspace: Workspace;
  workspaceId: Scalars['uuid'];
};


/** columns and relationships of "leadFormEntry" */
export type LeadFormEntryLeadFormEntryTagsArgs = {
  distinct_on?: InputMaybe<Array<LeadFormEntryTag_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<LeadFormEntryTag_Order_By>>;
  where?: InputMaybe<LeadFormEntryTag_Bool_Exp>;
};


/** columns and relationships of "leadFormEntry" */
export type LeadFormEntryLeadFormEntryTags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<LeadFormEntryTag_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<LeadFormEntryTag_Order_By>>;
  where?: InputMaybe<LeadFormEntryTag_Bool_Exp>;
};


/** columns and relationships of "leadFormEntry" */
export type LeadFormEntryLeadFormFieldValuesArgs = {
  distinct_on?: InputMaybe<Array<LeadFormFieldValue_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<LeadFormFieldValue_Order_By>>;
  where?: InputMaybe<LeadFormFieldValue_Bool_Exp>;
};


/** columns and relationships of "leadFormEntry" */
export type LeadFormEntryLeadFormFieldValues_AggregateArgs = {
  distinct_on?: InputMaybe<Array<LeadFormFieldValue_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<LeadFormFieldValue_Order_By>>;
  where?: InputMaybe<LeadFormFieldValue_Bool_Exp>;
};


/** columns and relationships of "leadFormEntry" */
export type LeadFormEntrySurveyJsDataArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "leadFormEntry" */
export type LeadFormEntryUtmParametersArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "leadFormEntryTag" */
export type LeadFormEntryTag = {
  __typename?: 'leadFormEntryTag';
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An object relationship */
  leadFormEntry: LeadFormEntry;
  leadFormEntryId: Scalars['uuid'];
  /** An object relationship */
  leadTag: LeadTag;
  leadTagId: Scalars['uuid'];
  updatedAt: Scalars['timestamptz'];
};

/** aggregated selection of "leadFormEntryTag" */
export type LeadFormEntryTag_Aggregate = {
  __typename?: 'leadFormEntryTag_aggregate';
  aggregate?: Maybe<LeadFormEntryTag_Aggregate_Fields>;
  nodes: Array<LeadFormEntryTag>;
};

export type LeadFormEntryTag_Aggregate_Bool_Exp = {
  count?: InputMaybe<LeadFormEntryTag_Aggregate_Bool_Exp_Count>;
};

export type LeadFormEntryTag_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<LeadFormEntryTag_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<LeadFormEntryTag_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "leadFormEntryTag" */
export type LeadFormEntryTag_Aggregate_Fields = {
  __typename?: 'leadFormEntryTag_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<LeadFormEntryTag_Max_Fields>;
  min?: Maybe<LeadFormEntryTag_Min_Fields>;
};


/** aggregate fields of "leadFormEntryTag" */
export type LeadFormEntryTag_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<LeadFormEntryTag_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "leadFormEntryTag" */
export type LeadFormEntryTag_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<LeadFormEntryTag_Max_Order_By>;
  min?: InputMaybe<LeadFormEntryTag_Min_Order_By>;
};

/** input type for inserting array relation for remote table "leadFormEntryTag" */
export type LeadFormEntryTag_Arr_Rel_Insert_Input = {
  data: Array<LeadFormEntryTag_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<LeadFormEntryTag_On_Conflict>;
};

/** Boolean expression to filter rows from the table "leadFormEntryTag". All fields are combined with a logical 'AND'. */
export type LeadFormEntryTag_Bool_Exp = {
  _and?: InputMaybe<Array<LeadFormEntryTag_Bool_Exp>>;
  _not?: InputMaybe<LeadFormEntryTag_Bool_Exp>;
  _or?: InputMaybe<Array<LeadFormEntryTag_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  leadFormEntry?: InputMaybe<LeadFormEntry_Bool_Exp>;
  leadFormEntryId?: InputMaybe<Uuid_Comparison_Exp>;
  leadTag?: InputMaybe<LeadTag_Bool_Exp>;
  leadTagId?: InputMaybe<Uuid_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "leadFormEntryTag" */
export enum LeadFormEntryTag_Constraint {
  /** unique or primary key constraint on columns "leadFormEntryId", "leadTagId" */
  LeadFormEntryTagLeadFormEntryIdLeadTagIdKey = 'leadFormEntryTag_leadFormEntryId_leadTagId_key',
  /** unique or primary key constraint on columns "id" */
  LeadFormEntryTagPkey = 'leadFormEntryTag_pkey'
}

/** input type for inserting data into table "leadFormEntryTag" */
export type LeadFormEntryTag_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  leadFormEntry?: InputMaybe<LeadFormEntry_Obj_Rel_Insert_Input>;
  leadFormEntryId?: InputMaybe<Scalars['uuid']>;
  leadTag?: InputMaybe<LeadTag_Obj_Rel_Insert_Input>;
  leadTagId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type LeadFormEntryTag_Max_Fields = {
  __typename?: 'leadFormEntryTag_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  leadFormEntryId?: Maybe<Scalars['uuid']>;
  leadTagId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "leadFormEntryTag" */
export type LeadFormEntryTag_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  leadFormEntryId?: InputMaybe<Order_By>;
  leadTagId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type LeadFormEntryTag_Min_Fields = {
  __typename?: 'leadFormEntryTag_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  leadFormEntryId?: Maybe<Scalars['uuid']>;
  leadTagId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "leadFormEntryTag" */
export type LeadFormEntryTag_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  leadFormEntryId?: InputMaybe<Order_By>;
  leadTagId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "leadFormEntryTag" */
export type LeadFormEntryTag_Mutation_Response = {
  __typename?: 'leadFormEntryTag_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<LeadFormEntryTag>;
};

/** on_conflict condition type for table "leadFormEntryTag" */
export type LeadFormEntryTag_On_Conflict = {
  constraint: LeadFormEntryTag_Constraint;
  update_columns?: Array<LeadFormEntryTag_Update_Column>;
  where?: InputMaybe<LeadFormEntryTag_Bool_Exp>;
};

/** Ordering options when selecting data from "leadFormEntryTag". */
export type LeadFormEntryTag_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  leadFormEntry?: InputMaybe<LeadFormEntry_Order_By>;
  leadFormEntryId?: InputMaybe<Order_By>;
  leadTag?: InputMaybe<LeadTag_Order_By>;
  leadTagId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: leadFormEntryTag */
export type LeadFormEntryTag_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "leadFormEntryTag" */
export enum LeadFormEntryTag_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  LeadFormEntryId = 'leadFormEntryId',
  /** column name */
  LeadTagId = 'leadTagId',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "leadFormEntryTag" */
export type LeadFormEntryTag_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  leadFormEntryId?: InputMaybe<Scalars['uuid']>;
  leadTagId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "leadFormEntryTag" */
export type LeadFormEntryTag_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: LeadFormEntryTag_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type LeadFormEntryTag_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  leadFormEntryId?: InputMaybe<Scalars['uuid']>;
  leadTagId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "leadFormEntryTag" */
export enum LeadFormEntryTag_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  LeadFormEntryId = 'leadFormEntryId',
  /** column name */
  LeadTagId = 'leadTagId',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type LeadFormEntryTag_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<LeadFormEntryTag_Set_Input>;
  /** filter the rows which have to be updated */
  where: LeadFormEntryTag_Bool_Exp;
};

/** aggregated selection of "leadFormEntry" */
export type LeadFormEntry_Aggregate = {
  __typename?: 'leadFormEntry_aggregate';
  aggregate?: Maybe<LeadFormEntry_Aggregate_Fields>;
  nodes: Array<LeadFormEntry>;
};

export type LeadFormEntry_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<LeadFormEntry_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<LeadFormEntry_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<LeadFormEntry_Aggregate_Bool_Exp_Count>;
};

export type LeadFormEntry_Aggregate_Bool_Exp_Bool_And = {
  arguments: LeadFormEntry_Select_Column_LeadFormEntry_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<LeadFormEntry_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type LeadFormEntry_Aggregate_Bool_Exp_Bool_Or = {
  arguments: LeadFormEntry_Select_Column_LeadFormEntry_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<LeadFormEntry_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type LeadFormEntry_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<LeadFormEntry_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<LeadFormEntry_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "leadFormEntry" */
export type LeadFormEntry_Aggregate_Fields = {
  __typename?: 'leadFormEntry_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<LeadFormEntry_Max_Fields>;
  min?: Maybe<LeadFormEntry_Min_Fields>;
};


/** aggregate fields of "leadFormEntry" */
export type LeadFormEntry_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<LeadFormEntry_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "leadFormEntry" */
export type LeadFormEntry_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<LeadFormEntry_Max_Order_By>;
  min?: InputMaybe<LeadFormEntry_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type LeadFormEntry_Append_Input = {
  surveyJSData?: InputMaybe<Scalars['jsonb']>;
  utmParameters?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "leadFormEntry" */
export type LeadFormEntry_Arr_Rel_Insert_Input = {
  data: Array<LeadFormEntry_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<LeadFormEntry_On_Conflict>;
};

/** Boolean expression to filter rows from the table "leadFormEntry". All fields are combined with a logical 'AND'. */
export type LeadFormEntry_Bool_Exp = {
  _and?: InputMaybe<Array<LeadFormEntry_Bool_Exp>>;
  _not?: InputMaybe<LeadFormEntry_Bool_Exp>;
  _or?: InputMaybe<Array<LeadFormEntry_Bool_Exp>>;
  archived?: InputMaybe<Boolean_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  leadForm?: InputMaybe<LeadForm_Bool_Exp>;
  leadFormEntryTags?: InputMaybe<LeadFormEntryTag_Bool_Exp>;
  leadFormEntryTags_aggregate?: InputMaybe<LeadFormEntryTag_Aggregate_Bool_Exp>;
  leadFormFieldValues?: InputMaybe<LeadFormFieldValue_Bool_Exp>;
  leadFormFieldValues_aggregate?: InputMaybe<LeadFormFieldValue_Aggregate_Bool_Exp>;
  leadFormId?: InputMaybe<Uuid_Comparison_Exp>;
  patient?: InputMaybe<Patient_Bool_Exp>;
  patientId?: InputMaybe<Uuid_Comparison_Exp>;
  surveyJSData?: InputMaybe<Jsonb_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  utmParameters?: InputMaybe<Jsonb_Comparison_Exp>;
  workspace?: InputMaybe<Workspace_Bool_Exp>;
  workspaceId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "leadFormEntry" */
export enum LeadFormEntry_Constraint {
  /** unique or primary key constraint on columns "leadFormId", "patientId" */
  LeadFormEntryPatientIdLeadFormIdKey = 'leadFormEntry_patientId_leadFormId_key',
  /** unique or primary key constraint on columns "id" */
  LeadFormEntryPkey = 'leadFormEntry_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type LeadFormEntry_Delete_At_Path_Input = {
  surveyJSData?: InputMaybe<Array<Scalars['String']>>;
  utmParameters?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type LeadFormEntry_Delete_Elem_Input = {
  surveyJSData?: InputMaybe<Scalars['Int']>;
  utmParameters?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type LeadFormEntry_Delete_Key_Input = {
  surveyJSData?: InputMaybe<Scalars['String']>;
  utmParameters?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "leadFormEntry" */
export type LeadFormEntry_Insert_Input = {
  archived?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  leadForm?: InputMaybe<LeadForm_Obj_Rel_Insert_Input>;
  leadFormEntryTags?: InputMaybe<LeadFormEntryTag_Arr_Rel_Insert_Input>;
  leadFormFieldValues?: InputMaybe<LeadFormFieldValue_Arr_Rel_Insert_Input>;
  leadFormId?: InputMaybe<Scalars['uuid']>;
  patient?: InputMaybe<Patient_Obj_Rel_Insert_Input>;
  patientId?: InputMaybe<Scalars['uuid']>;
  surveyJSData?: InputMaybe<Scalars['jsonb']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  utmParameters?: InputMaybe<Scalars['jsonb']>;
  workspace?: InputMaybe<Workspace_Obj_Rel_Insert_Input>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type LeadFormEntry_Max_Fields = {
  __typename?: 'leadFormEntry_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  leadFormId?: Maybe<Scalars['uuid']>;
  patientId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "leadFormEntry" */
export type LeadFormEntry_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  leadFormId?: InputMaybe<Order_By>;
  patientId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type LeadFormEntry_Min_Fields = {
  __typename?: 'leadFormEntry_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  leadFormId?: Maybe<Scalars['uuid']>;
  patientId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "leadFormEntry" */
export type LeadFormEntry_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  leadFormId?: InputMaybe<Order_By>;
  patientId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "leadFormEntry" */
export type LeadFormEntry_Mutation_Response = {
  __typename?: 'leadFormEntry_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<LeadFormEntry>;
};

/** input type for inserting object relation for remote table "leadFormEntry" */
export type LeadFormEntry_Obj_Rel_Insert_Input = {
  data: LeadFormEntry_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<LeadFormEntry_On_Conflict>;
};

/** on_conflict condition type for table "leadFormEntry" */
export type LeadFormEntry_On_Conflict = {
  constraint: LeadFormEntry_Constraint;
  update_columns?: Array<LeadFormEntry_Update_Column>;
  where?: InputMaybe<LeadFormEntry_Bool_Exp>;
};

/** Ordering options when selecting data from "leadFormEntry". */
export type LeadFormEntry_Order_By = {
  archived?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  leadForm?: InputMaybe<LeadForm_Order_By>;
  leadFormEntryTags_aggregate?: InputMaybe<LeadFormEntryTag_Aggregate_Order_By>;
  leadFormFieldValues_aggregate?: InputMaybe<LeadFormFieldValue_Aggregate_Order_By>;
  leadFormId?: InputMaybe<Order_By>;
  patient?: InputMaybe<Patient_Order_By>;
  patientId?: InputMaybe<Order_By>;
  surveyJSData?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  utmParameters?: InputMaybe<Order_By>;
  workspace?: InputMaybe<Workspace_Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: leadFormEntry */
export type LeadFormEntry_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type LeadFormEntry_Prepend_Input = {
  surveyJSData?: InputMaybe<Scalars['jsonb']>;
  utmParameters?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "leadFormEntry" */
export enum LeadFormEntry_Select_Column {
  /** column name */
  Archived = 'archived',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  LeadFormId = 'leadFormId',
  /** column name */
  PatientId = 'patientId',
  /** column name */
  SurveyJsData = 'surveyJSData',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UtmParameters = 'utmParameters',
  /** column name */
  WorkspaceId = 'workspaceId'
}

/** select "leadFormEntry_aggregate_bool_exp_bool_and_arguments_columns" columns of table "leadFormEntry" */
export enum LeadFormEntry_Select_Column_LeadFormEntry_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Archived = 'archived'
}

/** select "leadFormEntry_aggregate_bool_exp_bool_or_arguments_columns" columns of table "leadFormEntry" */
export enum LeadFormEntry_Select_Column_LeadFormEntry_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Archived = 'archived'
}

/** input type for updating data in table "leadFormEntry" */
export type LeadFormEntry_Set_Input = {
  archived?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  leadFormId?: InputMaybe<Scalars['uuid']>;
  patientId?: InputMaybe<Scalars['uuid']>;
  surveyJSData?: InputMaybe<Scalars['jsonb']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  utmParameters?: InputMaybe<Scalars['jsonb']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "leadFormEntry" */
export type LeadFormEntry_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: LeadFormEntry_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type LeadFormEntry_Stream_Cursor_Value_Input = {
  archived?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  leadFormId?: InputMaybe<Scalars['uuid']>;
  patientId?: InputMaybe<Scalars['uuid']>;
  surveyJSData?: InputMaybe<Scalars['jsonb']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  utmParameters?: InputMaybe<Scalars['jsonb']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "leadFormEntry" */
export enum LeadFormEntry_Update_Column {
  /** column name */
  Archived = 'archived',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  LeadFormId = 'leadFormId',
  /** column name */
  PatientId = 'patientId',
  /** column name */
  SurveyJsData = 'surveyJSData',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UtmParameters = 'utmParameters',
  /** column name */
  WorkspaceId = 'workspaceId'
}

export type LeadFormEntry_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<LeadFormEntry_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<LeadFormEntry_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<LeadFormEntry_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<LeadFormEntry_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<LeadFormEntry_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<LeadFormEntry_Set_Input>;
  /** filter the rows which have to be updated */
  where: LeadFormEntry_Bool_Exp;
};

/** columns and relationships of "leadFormField" */
export type LeadFormField = {
  __typename?: 'leadFormField';
  apiFieldKey: Scalars['String'];
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  label: Scalars['String'];
  /** An array relationship */
  leadFormFieldValues: Array<LeadFormFieldValue>;
  /** An aggregate relationship */
  leadFormFieldValues_aggregate: LeadFormFieldValue_Aggregate;
  /** An object relationship */
  leadFormStep: LeadFormStep;
  leadFormStepId: Scalars['uuid'];
  order: Scalars['smallint'];
  type: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  validationProps?: Maybe<Scalars['jsonb']>;
  /** An object relationship */
  workspace: Workspace;
  workspaceId: Scalars['uuid'];
};


/** columns and relationships of "leadFormField" */
export type LeadFormFieldLeadFormFieldValuesArgs = {
  distinct_on?: InputMaybe<Array<LeadFormFieldValue_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<LeadFormFieldValue_Order_By>>;
  where?: InputMaybe<LeadFormFieldValue_Bool_Exp>;
};


/** columns and relationships of "leadFormField" */
export type LeadFormFieldLeadFormFieldValues_AggregateArgs = {
  distinct_on?: InputMaybe<Array<LeadFormFieldValue_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<LeadFormFieldValue_Order_By>>;
  where?: InputMaybe<LeadFormFieldValue_Bool_Exp>;
};


/** columns and relationships of "leadFormField" */
export type LeadFormFieldValidationPropsArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "leadFormFieldValue" */
export type LeadFormFieldValue = {
  __typename?: 'leadFormFieldValue';
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An object relationship */
  leadFormEntry: LeadFormEntry;
  leadFormEntryId: Scalars['uuid'];
  /** An object relationship */
  leadFormField: LeadFormField;
  leadFormFieldId: Scalars['uuid'];
  updatedAt: Scalars['timestamptz'];
  value: Scalars['String'];
};

/** aggregated selection of "leadFormFieldValue" */
export type LeadFormFieldValue_Aggregate = {
  __typename?: 'leadFormFieldValue_aggregate';
  aggregate?: Maybe<LeadFormFieldValue_Aggregate_Fields>;
  nodes: Array<LeadFormFieldValue>;
};

export type LeadFormFieldValue_Aggregate_Bool_Exp = {
  count?: InputMaybe<LeadFormFieldValue_Aggregate_Bool_Exp_Count>;
};

export type LeadFormFieldValue_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<LeadFormFieldValue_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<LeadFormFieldValue_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "leadFormFieldValue" */
export type LeadFormFieldValue_Aggregate_Fields = {
  __typename?: 'leadFormFieldValue_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<LeadFormFieldValue_Max_Fields>;
  min?: Maybe<LeadFormFieldValue_Min_Fields>;
};


/** aggregate fields of "leadFormFieldValue" */
export type LeadFormFieldValue_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<LeadFormFieldValue_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "leadFormFieldValue" */
export type LeadFormFieldValue_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<LeadFormFieldValue_Max_Order_By>;
  min?: InputMaybe<LeadFormFieldValue_Min_Order_By>;
};

/** input type for inserting array relation for remote table "leadFormFieldValue" */
export type LeadFormFieldValue_Arr_Rel_Insert_Input = {
  data: Array<LeadFormFieldValue_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<LeadFormFieldValue_On_Conflict>;
};

/** Boolean expression to filter rows from the table "leadFormFieldValue". All fields are combined with a logical 'AND'. */
export type LeadFormFieldValue_Bool_Exp = {
  _and?: InputMaybe<Array<LeadFormFieldValue_Bool_Exp>>;
  _not?: InputMaybe<LeadFormFieldValue_Bool_Exp>;
  _or?: InputMaybe<Array<LeadFormFieldValue_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  leadFormEntry?: InputMaybe<LeadFormEntry_Bool_Exp>;
  leadFormEntryId?: InputMaybe<Uuid_Comparison_Exp>;
  leadFormField?: InputMaybe<LeadFormField_Bool_Exp>;
  leadFormFieldId?: InputMaybe<Uuid_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "leadFormFieldValue" */
export enum LeadFormFieldValue_Constraint {
  /** unique or primary key constraint on columns "leadFormFieldId", "leadFormEntryId" */
  LeadFormFieldValueLeadFormFieldIdLeadFormEntryIdKey = 'leadFormFieldValue_leadFormFieldId_leadFormEntryId_key',
  /** unique or primary key constraint on columns "id" */
  LeadFormFieldValuePkey = 'leadFormFieldValue_pkey'
}

/** input type for inserting data into table "leadFormFieldValue" */
export type LeadFormFieldValue_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  leadFormEntry?: InputMaybe<LeadFormEntry_Obj_Rel_Insert_Input>;
  leadFormEntryId?: InputMaybe<Scalars['uuid']>;
  leadFormField?: InputMaybe<LeadFormField_Obj_Rel_Insert_Input>;
  leadFormFieldId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type LeadFormFieldValue_Max_Fields = {
  __typename?: 'leadFormFieldValue_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  leadFormEntryId?: Maybe<Scalars['uuid']>;
  leadFormFieldId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "leadFormFieldValue" */
export type LeadFormFieldValue_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  leadFormEntryId?: InputMaybe<Order_By>;
  leadFormFieldId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type LeadFormFieldValue_Min_Fields = {
  __typename?: 'leadFormFieldValue_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  leadFormEntryId?: Maybe<Scalars['uuid']>;
  leadFormFieldId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "leadFormFieldValue" */
export type LeadFormFieldValue_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  leadFormEntryId?: InputMaybe<Order_By>;
  leadFormFieldId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "leadFormFieldValue" */
export type LeadFormFieldValue_Mutation_Response = {
  __typename?: 'leadFormFieldValue_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<LeadFormFieldValue>;
};

/** on_conflict condition type for table "leadFormFieldValue" */
export type LeadFormFieldValue_On_Conflict = {
  constraint: LeadFormFieldValue_Constraint;
  update_columns?: Array<LeadFormFieldValue_Update_Column>;
  where?: InputMaybe<LeadFormFieldValue_Bool_Exp>;
};

/** Ordering options when selecting data from "leadFormFieldValue". */
export type LeadFormFieldValue_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  leadFormEntry?: InputMaybe<LeadFormEntry_Order_By>;
  leadFormEntryId?: InputMaybe<Order_By>;
  leadFormField?: InputMaybe<LeadFormField_Order_By>;
  leadFormFieldId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: leadFormFieldValue */
export type LeadFormFieldValue_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "leadFormFieldValue" */
export enum LeadFormFieldValue_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  LeadFormEntryId = 'leadFormEntryId',
  /** column name */
  LeadFormFieldId = 'leadFormFieldId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "leadFormFieldValue" */
export type LeadFormFieldValue_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  leadFormEntryId?: InputMaybe<Scalars['uuid']>;
  leadFormFieldId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "leadFormFieldValue" */
export type LeadFormFieldValue_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: LeadFormFieldValue_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type LeadFormFieldValue_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  leadFormEntryId?: InputMaybe<Scalars['uuid']>;
  leadFormFieldId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "leadFormFieldValue" */
export enum LeadFormFieldValue_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  LeadFormEntryId = 'leadFormEntryId',
  /** column name */
  LeadFormFieldId = 'leadFormFieldId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Value = 'value'
}

export type LeadFormFieldValue_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<LeadFormFieldValue_Set_Input>;
  /** filter the rows which have to be updated */
  where: LeadFormFieldValue_Bool_Exp;
};

/** aggregated selection of "leadFormField" */
export type LeadFormField_Aggregate = {
  __typename?: 'leadFormField_aggregate';
  aggregate?: Maybe<LeadFormField_Aggregate_Fields>;
  nodes: Array<LeadFormField>;
};

export type LeadFormField_Aggregate_Bool_Exp = {
  count?: InputMaybe<LeadFormField_Aggregate_Bool_Exp_Count>;
};

export type LeadFormField_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<LeadFormField_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<LeadFormField_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "leadFormField" */
export type LeadFormField_Aggregate_Fields = {
  __typename?: 'leadFormField_aggregate_fields';
  avg?: Maybe<LeadFormField_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<LeadFormField_Max_Fields>;
  min?: Maybe<LeadFormField_Min_Fields>;
  stddev?: Maybe<LeadFormField_Stddev_Fields>;
  stddev_pop?: Maybe<LeadFormField_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<LeadFormField_Stddev_Samp_Fields>;
  sum?: Maybe<LeadFormField_Sum_Fields>;
  var_pop?: Maybe<LeadFormField_Var_Pop_Fields>;
  var_samp?: Maybe<LeadFormField_Var_Samp_Fields>;
  variance?: Maybe<LeadFormField_Variance_Fields>;
};


/** aggregate fields of "leadFormField" */
export type LeadFormField_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<LeadFormField_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "leadFormField" */
export type LeadFormField_Aggregate_Order_By = {
  avg?: InputMaybe<LeadFormField_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<LeadFormField_Max_Order_By>;
  min?: InputMaybe<LeadFormField_Min_Order_By>;
  stddev?: InputMaybe<LeadFormField_Stddev_Order_By>;
  stddev_pop?: InputMaybe<LeadFormField_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<LeadFormField_Stddev_Samp_Order_By>;
  sum?: InputMaybe<LeadFormField_Sum_Order_By>;
  var_pop?: InputMaybe<LeadFormField_Var_Pop_Order_By>;
  var_samp?: InputMaybe<LeadFormField_Var_Samp_Order_By>;
  variance?: InputMaybe<LeadFormField_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type LeadFormField_Append_Input = {
  validationProps?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "leadFormField" */
export type LeadFormField_Arr_Rel_Insert_Input = {
  data: Array<LeadFormField_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<LeadFormField_On_Conflict>;
};

/** aggregate avg on columns */
export type LeadFormField_Avg_Fields = {
  __typename?: 'leadFormField_avg_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "leadFormField" */
export type LeadFormField_Avg_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "leadFormField". All fields are combined with a logical 'AND'. */
export type LeadFormField_Bool_Exp = {
  _and?: InputMaybe<Array<LeadFormField_Bool_Exp>>;
  _not?: InputMaybe<LeadFormField_Bool_Exp>;
  _or?: InputMaybe<Array<LeadFormField_Bool_Exp>>;
  apiFieldKey?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  label?: InputMaybe<String_Comparison_Exp>;
  leadFormFieldValues?: InputMaybe<LeadFormFieldValue_Bool_Exp>;
  leadFormFieldValues_aggregate?: InputMaybe<LeadFormFieldValue_Aggregate_Bool_Exp>;
  leadFormStep?: InputMaybe<LeadFormStep_Bool_Exp>;
  leadFormStepId?: InputMaybe<Uuid_Comparison_Exp>;
  order?: InputMaybe<Smallint_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  validationProps?: InputMaybe<Jsonb_Comparison_Exp>;
  workspace?: InputMaybe<Workspace_Bool_Exp>;
  workspaceId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "leadFormField" */
export enum LeadFormField_Constraint {
  /** unique or primary key constraint on columns "id" */
  LeadFormFieldPkey = 'leadFormField_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type LeadFormField_Delete_At_Path_Input = {
  validationProps?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type LeadFormField_Delete_Elem_Input = {
  validationProps?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type LeadFormField_Delete_Key_Input = {
  validationProps?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "leadFormField" */
export type LeadFormField_Inc_Input = {
  order?: InputMaybe<Scalars['smallint']>;
};

/** input type for inserting data into table "leadFormField" */
export type LeadFormField_Insert_Input = {
  apiFieldKey?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  label?: InputMaybe<Scalars['String']>;
  leadFormFieldValues?: InputMaybe<LeadFormFieldValue_Arr_Rel_Insert_Input>;
  leadFormStep?: InputMaybe<LeadFormStep_Obj_Rel_Insert_Input>;
  leadFormStepId?: InputMaybe<Scalars['uuid']>;
  order?: InputMaybe<Scalars['smallint']>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  validationProps?: InputMaybe<Scalars['jsonb']>;
  workspace?: InputMaybe<Workspace_Obj_Rel_Insert_Input>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type LeadFormField_Max_Fields = {
  __typename?: 'leadFormField_max_fields';
  apiFieldKey?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  label?: Maybe<Scalars['String']>;
  leadFormStepId?: Maybe<Scalars['uuid']>;
  order?: Maybe<Scalars['smallint']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "leadFormField" */
export type LeadFormField_Max_Order_By = {
  apiFieldKey?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  label?: InputMaybe<Order_By>;
  leadFormStepId?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type LeadFormField_Min_Fields = {
  __typename?: 'leadFormField_min_fields';
  apiFieldKey?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  label?: Maybe<Scalars['String']>;
  leadFormStepId?: Maybe<Scalars['uuid']>;
  order?: Maybe<Scalars['smallint']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "leadFormField" */
export type LeadFormField_Min_Order_By = {
  apiFieldKey?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  label?: InputMaybe<Order_By>;
  leadFormStepId?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "leadFormField" */
export type LeadFormField_Mutation_Response = {
  __typename?: 'leadFormField_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<LeadFormField>;
};

/** input type for inserting object relation for remote table "leadFormField" */
export type LeadFormField_Obj_Rel_Insert_Input = {
  data: LeadFormField_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<LeadFormField_On_Conflict>;
};

/** on_conflict condition type for table "leadFormField" */
export type LeadFormField_On_Conflict = {
  constraint: LeadFormField_Constraint;
  update_columns?: Array<LeadFormField_Update_Column>;
  where?: InputMaybe<LeadFormField_Bool_Exp>;
};

/** Ordering options when selecting data from "leadFormField". */
export type LeadFormField_Order_By = {
  apiFieldKey?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  label?: InputMaybe<Order_By>;
  leadFormFieldValues_aggregate?: InputMaybe<LeadFormFieldValue_Aggregate_Order_By>;
  leadFormStep?: InputMaybe<LeadFormStep_Order_By>;
  leadFormStepId?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  validationProps?: InputMaybe<Order_By>;
  workspace?: InputMaybe<Workspace_Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: leadFormField */
export type LeadFormField_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type LeadFormField_Prepend_Input = {
  validationProps?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "leadFormField" */
export enum LeadFormField_Select_Column {
  /** column name */
  ApiFieldKey = 'apiFieldKey',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Label = 'label',
  /** column name */
  LeadFormStepId = 'leadFormStepId',
  /** column name */
  Order = 'order',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  ValidationProps = 'validationProps',
  /** column name */
  WorkspaceId = 'workspaceId'
}

/** input type for updating data in table "leadFormField" */
export type LeadFormField_Set_Input = {
  apiFieldKey?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  label?: InputMaybe<Scalars['String']>;
  leadFormStepId?: InputMaybe<Scalars['uuid']>;
  order?: InputMaybe<Scalars['smallint']>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  validationProps?: InputMaybe<Scalars['jsonb']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type LeadFormField_Stddev_Fields = {
  __typename?: 'leadFormField_stddev_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "leadFormField" */
export type LeadFormField_Stddev_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type LeadFormField_Stddev_Pop_Fields = {
  __typename?: 'leadFormField_stddev_pop_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "leadFormField" */
export type LeadFormField_Stddev_Pop_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type LeadFormField_Stddev_Samp_Fields = {
  __typename?: 'leadFormField_stddev_samp_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "leadFormField" */
export type LeadFormField_Stddev_Samp_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "leadFormField" */
export type LeadFormField_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: LeadFormField_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type LeadFormField_Stream_Cursor_Value_Input = {
  apiFieldKey?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  label?: InputMaybe<Scalars['String']>;
  leadFormStepId?: InputMaybe<Scalars['uuid']>;
  order?: InputMaybe<Scalars['smallint']>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  validationProps?: InputMaybe<Scalars['jsonb']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type LeadFormField_Sum_Fields = {
  __typename?: 'leadFormField_sum_fields';
  order?: Maybe<Scalars['smallint']>;
};

/** order by sum() on columns of table "leadFormField" */
export type LeadFormField_Sum_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** update columns of table "leadFormField" */
export enum LeadFormField_Update_Column {
  /** column name */
  ApiFieldKey = 'apiFieldKey',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Label = 'label',
  /** column name */
  LeadFormStepId = 'leadFormStepId',
  /** column name */
  Order = 'order',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  ValidationProps = 'validationProps',
  /** column name */
  WorkspaceId = 'workspaceId'
}

export type LeadFormField_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<LeadFormField_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<LeadFormField_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<LeadFormField_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<LeadFormField_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<LeadFormField_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<LeadFormField_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<LeadFormField_Set_Input>;
  /** filter the rows which have to be updated */
  where: LeadFormField_Bool_Exp;
};

/** aggregate var_pop on columns */
export type LeadFormField_Var_Pop_Fields = {
  __typename?: 'leadFormField_var_pop_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "leadFormField" */
export type LeadFormField_Var_Pop_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type LeadFormField_Var_Samp_Fields = {
  __typename?: 'leadFormField_var_samp_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "leadFormField" */
export type LeadFormField_Var_Samp_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type LeadFormField_Variance_Fields = {
  __typename?: 'leadFormField_variance_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "leadFormField" */
export type LeadFormField_Variance_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** columns and relationships of "leadFormLink" */
export type LeadFormLink = {
  __typename?: 'leadFormLink';
  createdAt: Scalars['timestamptz'];
  formData: Scalars['jsonb'];
  id: Scalars['uuid'];
  leadFormId: Scalars['uuid'];
  patientId: Scalars['uuid'];
  updatedAt: Scalars['timestamptz'];
  workspaceId: Scalars['uuid'];
};


/** columns and relationships of "leadFormLink" */
export type LeadFormLinkFormDataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "leadFormLink" */
export type LeadFormLink_Aggregate = {
  __typename?: 'leadFormLink_aggregate';
  aggregate?: Maybe<LeadFormLink_Aggregate_Fields>;
  nodes: Array<LeadFormLink>;
};

/** aggregate fields of "leadFormLink" */
export type LeadFormLink_Aggregate_Fields = {
  __typename?: 'leadFormLink_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<LeadFormLink_Max_Fields>;
  min?: Maybe<LeadFormLink_Min_Fields>;
};


/** aggregate fields of "leadFormLink" */
export type LeadFormLink_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<LeadFormLink_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type LeadFormLink_Append_Input = {
  formData?: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "leadFormLink". All fields are combined with a logical 'AND'. */
export type LeadFormLink_Bool_Exp = {
  _and?: InputMaybe<Array<LeadFormLink_Bool_Exp>>;
  _not?: InputMaybe<LeadFormLink_Bool_Exp>;
  _or?: InputMaybe<Array<LeadFormLink_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  formData?: InputMaybe<Jsonb_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  leadFormId?: InputMaybe<Uuid_Comparison_Exp>;
  patientId?: InputMaybe<Uuid_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  workspaceId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "leadFormLink" */
export enum LeadFormLink_Constraint {
  /** unique or primary key constraint on columns "id" */
  LeadFormLinkPkey = 'leadFormLink_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type LeadFormLink_Delete_At_Path_Input = {
  formData?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type LeadFormLink_Delete_Elem_Input = {
  formData?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type LeadFormLink_Delete_Key_Input = {
  formData?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "leadFormLink" */
export type LeadFormLink_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  formData?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['uuid']>;
  leadFormId?: InputMaybe<Scalars['uuid']>;
  patientId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type LeadFormLink_Max_Fields = {
  __typename?: 'leadFormLink_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  leadFormId?: Maybe<Scalars['uuid']>;
  patientId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type LeadFormLink_Min_Fields = {
  __typename?: 'leadFormLink_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  leadFormId?: Maybe<Scalars['uuid']>;
  patientId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "leadFormLink" */
export type LeadFormLink_Mutation_Response = {
  __typename?: 'leadFormLink_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<LeadFormLink>;
};

/** on_conflict condition type for table "leadFormLink" */
export type LeadFormLink_On_Conflict = {
  constraint: LeadFormLink_Constraint;
  update_columns?: Array<LeadFormLink_Update_Column>;
  where?: InputMaybe<LeadFormLink_Bool_Exp>;
};

/** Ordering options when selecting data from "leadFormLink". */
export type LeadFormLink_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  formData?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  leadFormId?: InputMaybe<Order_By>;
  patientId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: leadFormLink */
export type LeadFormLink_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type LeadFormLink_Prepend_Input = {
  formData?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "leadFormLink" */
export enum LeadFormLink_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  FormData = 'formData',
  /** column name */
  Id = 'id',
  /** column name */
  LeadFormId = 'leadFormId',
  /** column name */
  PatientId = 'patientId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WorkspaceId = 'workspaceId'
}

/** input type for updating data in table "leadFormLink" */
export type LeadFormLink_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  formData?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['uuid']>;
  leadFormId?: InputMaybe<Scalars['uuid']>;
  patientId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "leadFormLink" */
export type LeadFormLink_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: LeadFormLink_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type LeadFormLink_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  formData?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['uuid']>;
  leadFormId?: InputMaybe<Scalars['uuid']>;
  patientId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "leadFormLink" */
export enum LeadFormLink_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  FormData = 'formData',
  /** column name */
  Id = 'id',
  /** column name */
  LeadFormId = 'leadFormId',
  /** column name */
  PatientId = 'patientId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WorkspaceId = 'workspaceId'
}

export type LeadFormLink_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<LeadFormLink_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<LeadFormLink_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<LeadFormLink_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<LeadFormLink_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<LeadFormLink_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<LeadFormLink_Set_Input>;
  /** filter the rows which have to be updated */
  where: LeadFormLink_Bool_Exp;
};

/** columns and relationships of "leadFormStep" */
export type LeadFormStep = {
  __typename?: 'leadFormStep';
  callToAction?: Maybe<Scalars['String']>;
  canDelete: Scalars['Boolean'];
  canReOrder: Scalars['Boolean'];
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An object relationship */
  leadForm: LeadForm;
  /** An array relationship */
  leadFormFields: Array<LeadFormField>;
  /** An aggregate relationship */
  leadFormFields_aggregate: LeadFormField_Aggregate;
  leadFormId: Scalars['uuid'];
  name?: Maybe<Scalars['String']>;
  order: Scalars['Int'];
  properties?: Maybe<Scalars['jsonb']>;
  subtitle?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  workspaceId: Scalars['uuid'];
};


/** columns and relationships of "leadFormStep" */
export type LeadFormStepLeadFormFieldsArgs = {
  distinct_on?: InputMaybe<Array<LeadFormField_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<LeadFormField_Order_By>>;
  where?: InputMaybe<LeadFormField_Bool_Exp>;
};


/** columns and relationships of "leadFormStep" */
export type LeadFormStepLeadFormFields_AggregateArgs = {
  distinct_on?: InputMaybe<Array<LeadFormField_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<LeadFormField_Order_By>>;
  where?: InputMaybe<LeadFormField_Bool_Exp>;
};


/** columns and relationships of "leadFormStep" */
export type LeadFormStepPropertiesArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "leadFormStep" */
export type LeadFormStep_Aggregate = {
  __typename?: 'leadFormStep_aggregate';
  aggregate?: Maybe<LeadFormStep_Aggregate_Fields>;
  nodes: Array<LeadFormStep>;
};

export type LeadFormStep_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<LeadFormStep_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<LeadFormStep_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<LeadFormStep_Aggregate_Bool_Exp_Count>;
};

export type LeadFormStep_Aggregate_Bool_Exp_Bool_And = {
  arguments: LeadFormStep_Select_Column_LeadFormStep_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<LeadFormStep_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type LeadFormStep_Aggregate_Bool_Exp_Bool_Or = {
  arguments: LeadFormStep_Select_Column_LeadFormStep_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<LeadFormStep_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type LeadFormStep_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<LeadFormStep_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<LeadFormStep_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "leadFormStep" */
export type LeadFormStep_Aggregate_Fields = {
  __typename?: 'leadFormStep_aggregate_fields';
  avg?: Maybe<LeadFormStep_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<LeadFormStep_Max_Fields>;
  min?: Maybe<LeadFormStep_Min_Fields>;
  stddev?: Maybe<LeadFormStep_Stddev_Fields>;
  stddev_pop?: Maybe<LeadFormStep_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<LeadFormStep_Stddev_Samp_Fields>;
  sum?: Maybe<LeadFormStep_Sum_Fields>;
  var_pop?: Maybe<LeadFormStep_Var_Pop_Fields>;
  var_samp?: Maybe<LeadFormStep_Var_Samp_Fields>;
  variance?: Maybe<LeadFormStep_Variance_Fields>;
};


/** aggregate fields of "leadFormStep" */
export type LeadFormStep_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<LeadFormStep_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "leadFormStep" */
export type LeadFormStep_Aggregate_Order_By = {
  avg?: InputMaybe<LeadFormStep_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<LeadFormStep_Max_Order_By>;
  min?: InputMaybe<LeadFormStep_Min_Order_By>;
  stddev?: InputMaybe<LeadFormStep_Stddev_Order_By>;
  stddev_pop?: InputMaybe<LeadFormStep_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<LeadFormStep_Stddev_Samp_Order_By>;
  sum?: InputMaybe<LeadFormStep_Sum_Order_By>;
  var_pop?: InputMaybe<LeadFormStep_Var_Pop_Order_By>;
  var_samp?: InputMaybe<LeadFormStep_Var_Samp_Order_By>;
  variance?: InputMaybe<LeadFormStep_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type LeadFormStep_Append_Input = {
  properties?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "leadFormStep" */
export type LeadFormStep_Arr_Rel_Insert_Input = {
  data: Array<LeadFormStep_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<LeadFormStep_On_Conflict>;
};

/** aggregate avg on columns */
export type LeadFormStep_Avg_Fields = {
  __typename?: 'leadFormStep_avg_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "leadFormStep" */
export type LeadFormStep_Avg_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "leadFormStep". All fields are combined with a logical 'AND'. */
export type LeadFormStep_Bool_Exp = {
  _and?: InputMaybe<Array<LeadFormStep_Bool_Exp>>;
  _not?: InputMaybe<LeadFormStep_Bool_Exp>;
  _or?: InputMaybe<Array<LeadFormStep_Bool_Exp>>;
  callToAction?: InputMaybe<String_Comparison_Exp>;
  canDelete?: InputMaybe<Boolean_Comparison_Exp>;
  canReOrder?: InputMaybe<Boolean_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  leadForm?: InputMaybe<LeadForm_Bool_Exp>;
  leadFormFields?: InputMaybe<LeadFormField_Bool_Exp>;
  leadFormFields_aggregate?: InputMaybe<LeadFormField_Aggregate_Bool_Exp>;
  leadFormId?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  order?: InputMaybe<Int_Comparison_Exp>;
  properties?: InputMaybe<Jsonb_Comparison_Exp>;
  subtitle?: InputMaybe<String_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  workspaceId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "leadFormStep" */
export enum LeadFormStep_Constraint {
  /** unique or primary key constraint on columns "id" */
  LeadFormStepPkey = 'leadFormStep_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type LeadFormStep_Delete_At_Path_Input = {
  properties?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type LeadFormStep_Delete_Elem_Input = {
  properties?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type LeadFormStep_Delete_Key_Input = {
  properties?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "leadFormStep" */
export type LeadFormStep_Inc_Input = {
  order?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "leadFormStep" */
export type LeadFormStep_Insert_Input = {
  callToAction?: InputMaybe<Scalars['String']>;
  canDelete?: InputMaybe<Scalars['Boolean']>;
  canReOrder?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  leadForm?: InputMaybe<LeadForm_Obj_Rel_Insert_Input>;
  leadFormFields?: InputMaybe<LeadFormField_Arr_Rel_Insert_Input>;
  leadFormId?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
  properties?: InputMaybe<Scalars['jsonb']>;
  subtitle?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type LeadFormStep_Max_Fields = {
  __typename?: 'leadFormStep_max_fields';
  callToAction?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  leadFormId?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  subtitle?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "leadFormStep" */
export type LeadFormStep_Max_Order_By = {
  callToAction?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  leadFormId?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  subtitle?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type LeadFormStep_Min_Fields = {
  __typename?: 'leadFormStep_min_fields';
  callToAction?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  leadFormId?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  subtitle?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "leadFormStep" */
export type LeadFormStep_Min_Order_By = {
  callToAction?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  leadFormId?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  subtitle?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "leadFormStep" */
export type LeadFormStep_Mutation_Response = {
  __typename?: 'leadFormStep_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<LeadFormStep>;
};

/** input type for inserting object relation for remote table "leadFormStep" */
export type LeadFormStep_Obj_Rel_Insert_Input = {
  data: LeadFormStep_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<LeadFormStep_On_Conflict>;
};

/** on_conflict condition type for table "leadFormStep" */
export type LeadFormStep_On_Conflict = {
  constraint: LeadFormStep_Constraint;
  update_columns?: Array<LeadFormStep_Update_Column>;
  where?: InputMaybe<LeadFormStep_Bool_Exp>;
};

/** Ordering options when selecting data from "leadFormStep". */
export type LeadFormStep_Order_By = {
  callToAction?: InputMaybe<Order_By>;
  canDelete?: InputMaybe<Order_By>;
  canReOrder?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  leadForm?: InputMaybe<LeadForm_Order_By>;
  leadFormFields_aggregate?: InputMaybe<LeadFormField_Aggregate_Order_By>;
  leadFormId?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  properties?: InputMaybe<Order_By>;
  subtitle?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: leadFormStep */
export type LeadFormStep_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type LeadFormStep_Prepend_Input = {
  properties?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "leadFormStep" */
export enum LeadFormStep_Select_Column {
  /** column name */
  CallToAction = 'callToAction',
  /** column name */
  CanDelete = 'canDelete',
  /** column name */
  CanReOrder = 'canReOrder',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  LeadFormId = 'leadFormId',
  /** column name */
  Name = 'name',
  /** column name */
  Order = 'order',
  /** column name */
  Properties = 'properties',
  /** column name */
  Subtitle = 'subtitle',
  /** column name */
  Title = 'title',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WorkspaceId = 'workspaceId'
}

/** select "leadFormStep_aggregate_bool_exp_bool_and_arguments_columns" columns of table "leadFormStep" */
export enum LeadFormStep_Select_Column_LeadFormStep_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  CanDelete = 'canDelete',
  /** column name */
  CanReOrder = 'canReOrder'
}

/** select "leadFormStep_aggregate_bool_exp_bool_or_arguments_columns" columns of table "leadFormStep" */
export enum LeadFormStep_Select_Column_LeadFormStep_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  CanDelete = 'canDelete',
  /** column name */
  CanReOrder = 'canReOrder'
}

/** input type for updating data in table "leadFormStep" */
export type LeadFormStep_Set_Input = {
  callToAction?: InputMaybe<Scalars['String']>;
  canDelete?: InputMaybe<Scalars['Boolean']>;
  canReOrder?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  leadFormId?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
  properties?: InputMaybe<Scalars['jsonb']>;
  subtitle?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type LeadFormStep_Stddev_Fields = {
  __typename?: 'leadFormStep_stddev_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "leadFormStep" */
export type LeadFormStep_Stddev_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type LeadFormStep_Stddev_Pop_Fields = {
  __typename?: 'leadFormStep_stddev_pop_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "leadFormStep" */
export type LeadFormStep_Stddev_Pop_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type LeadFormStep_Stddev_Samp_Fields = {
  __typename?: 'leadFormStep_stddev_samp_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "leadFormStep" */
export type LeadFormStep_Stddev_Samp_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "leadFormStep" */
export type LeadFormStep_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: LeadFormStep_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type LeadFormStep_Stream_Cursor_Value_Input = {
  callToAction?: InputMaybe<Scalars['String']>;
  canDelete?: InputMaybe<Scalars['Boolean']>;
  canReOrder?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  leadFormId?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
  properties?: InputMaybe<Scalars['jsonb']>;
  subtitle?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type LeadFormStep_Sum_Fields = {
  __typename?: 'leadFormStep_sum_fields';
  order?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "leadFormStep" */
export type LeadFormStep_Sum_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** update columns of table "leadFormStep" */
export enum LeadFormStep_Update_Column {
  /** column name */
  CallToAction = 'callToAction',
  /** column name */
  CanDelete = 'canDelete',
  /** column name */
  CanReOrder = 'canReOrder',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  LeadFormId = 'leadFormId',
  /** column name */
  Name = 'name',
  /** column name */
  Order = 'order',
  /** column name */
  Properties = 'properties',
  /** column name */
  Subtitle = 'subtitle',
  /** column name */
  Title = 'title',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WorkspaceId = 'workspaceId'
}

export type LeadFormStep_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<LeadFormStep_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<LeadFormStep_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<LeadFormStep_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<LeadFormStep_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<LeadFormStep_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<LeadFormStep_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<LeadFormStep_Set_Input>;
  /** filter the rows which have to be updated */
  where: LeadFormStep_Bool_Exp;
};

/** aggregate var_pop on columns */
export type LeadFormStep_Var_Pop_Fields = {
  __typename?: 'leadFormStep_var_pop_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "leadFormStep" */
export type LeadFormStep_Var_Pop_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type LeadFormStep_Var_Samp_Fields = {
  __typename?: 'leadFormStep_var_samp_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "leadFormStep" */
export type LeadFormStep_Var_Samp_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type LeadFormStep_Variance_Fields = {
  __typename?: 'leadFormStep_variance_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "leadFormStep" */
export type LeadFormStep_Variance_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** aggregated selection of "leadForm" */
export type LeadForm_Aggregate = {
  __typename?: 'leadForm_aggregate';
  aggregate?: Maybe<LeadForm_Aggregate_Fields>;
  nodes: Array<LeadForm>;
};

export type LeadForm_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<LeadForm_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<LeadForm_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<LeadForm_Aggregate_Bool_Exp_Count>;
};

export type LeadForm_Aggregate_Bool_Exp_Bool_And = {
  arguments: LeadForm_Select_Column_LeadForm_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<LeadForm_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type LeadForm_Aggregate_Bool_Exp_Bool_Or = {
  arguments: LeadForm_Select_Column_LeadForm_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<LeadForm_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type LeadForm_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<LeadForm_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<LeadForm_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "leadForm" */
export type LeadForm_Aggregate_Fields = {
  __typename?: 'leadForm_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<LeadForm_Max_Fields>;
  min?: Maybe<LeadForm_Min_Fields>;
};


/** aggregate fields of "leadForm" */
export type LeadForm_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<LeadForm_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "leadForm" */
export type LeadForm_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<LeadForm_Max_Order_By>;
  min?: InputMaybe<LeadForm_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type LeadForm_Append_Input = {
  lockedToLocations?: InputMaybe<Scalars['jsonb']>;
  lockedToServices?: InputMaybe<Scalars['jsonb']>;
  surveyJSJson?: InputMaybe<Scalars['jsonb']>;
  themeJSON?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "leadForm" */
export type LeadForm_Arr_Rel_Insert_Input = {
  data: Array<LeadForm_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<LeadForm_On_Conflict>;
};

/** Boolean expression to filter rows from the table "leadForm". All fields are combined with a logical 'AND'. */
export type LeadForm_Bool_Exp = {
  _and?: InputMaybe<Array<LeadForm_Bool_Exp>>;
  _not?: InputMaybe<LeadForm_Bool_Exp>;
  _or?: InputMaybe<Array<LeadForm_Bool_Exp>>;
  backgroundColor?: InputMaybe<String_Comparison_Exp>;
  businessLogo?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  font?: InputMaybe<String_Comparison_Exp>;
  formBackground?: InputMaybe<String_Comparison_Exp>;
  headerCode?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  isArchived?: InputMaybe<Boolean_Comparison_Exp>;
  layout?: InputMaybe<String_Comparison_Exp>;
  leadFormEntries?: InputMaybe<LeadFormEntry_Bool_Exp>;
  leadFormEntries_aggregate?: InputMaybe<LeadFormEntry_Aggregate_Bool_Exp>;
  leadFormSteps?: InputMaybe<LeadFormStep_Bool_Exp>;
  leadFormSteps_aggregate?: InputMaybe<LeadFormStep_Aggregate_Bool_Exp>;
  lockedToLocations?: InputMaybe<Jsonb_Comparison_Exp>;
  lockedToServices?: InputMaybe<Jsonb_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  sideBannerImage?: InputMaybe<String_Comparison_Exp>;
  successMessage?: InputMaybe<String_Comparison_Exp>;
  surveyJSJson?: InputMaybe<Jsonb_Comparison_Exp>;
  themeJSON?: InputMaybe<Jsonb_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  workspace?: InputMaybe<Workspace_Bool_Exp>;
  workspaceId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "leadForm" */
export enum LeadForm_Constraint {
  /** unique or primary key constraint on columns "id" */
  LeadFormPkey = 'leadForm_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type LeadForm_Delete_At_Path_Input = {
  lockedToLocations?: InputMaybe<Array<Scalars['String']>>;
  lockedToServices?: InputMaybe<Array<Scalars['String']>>;
  surveyJSJson?: InputMaybe<Array<Scalars['String']>>;
  themeJSON?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type LeadForm_Delete_Elem_Input = {
  lockedToLocations?: InputMaybe<Scalars['Int']>;
  lockedToServices?: InputMaybe<Scalars['Int']>;
  surveyJSJson?: InputMaybe<Scalars['Int']>;
  themeJSON?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type LeadForm_Delete_Key_Input = {
  lockedToLocations?: InputMaybe<Scalars['String']>;
  lockedToServices?: InputMaybe<Scalars['String']>;
  surveyJSJson?: InputMaybe<Scalars['String']>;
  themeJSON?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "leadForm" */
export type LeadForm_Insert_Input = {
  backgroundColor?: InputMaybe<Scalars['String']>;
  businessLogo?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  font?: InputMaybe<Scalars['String']>;
  formBackground?: InputMaybe<Scalars['String']>;
  headerCode?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  isArchived?: InputMaybe<Scalars['Boolean']>;
  layout?: InputMaybe<Scalars['String']>;
  leadFormEntries?: InputMaybe<LeadFormEntry_Arr_Rel_Insert_Input>;
  leadFormSteps?: InputMaybe<LeadFormStep_Arr_Rel_Insert_Input>;
  lockedToLocations?: InputMaybe<Scalars['jsonb']>;
  lockedToServices?: InputMaybe<Scalars['jsonb']>;
  name?: InputMaybe<Scalars['String']>;
  sideBannerImage?: InputMaybe<Scalars['String']>;
  successMessage?: InputMaybe<Scalars['String']>;
  surveyJSJson?: InputMaybe<Scalars['jsonb']>;
  themeJSON?: InputMaybe<Scalars['jsonb']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspace?: InputMaybe<Workspace_Obj_Rel_Insert_Input>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type LeadForm_Max_Fields = {
  __typename?: 'leadForm_max_fields';
  backgroundColor?: Maybe<Scalars['String']>;
  businessLogo?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  font?: Maybe<Scalars['String']>;
  formBackground?: Maybe<Scalars['String']>;
  headerCode?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  layout?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  sideBannerImage?: Maybe<Scalars['String']>;
  successMessage?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "leadForm" */
export type LeadForm_Max_Order_By = {
  backgroundColor?: InputMaybe<Order_By>;
  businessLogo?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  font?: InputMaybe<Order_By>;
  formBackground?: InputMaybe<Order_By>;
  headerCode?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  layout?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  sideBannerImage?: InputMaybe<Order_By>;
  successMessage?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type LeadForm_Min_Fields = {
  __typename?: 'leadForm_min_fields';
  backgroundColor?: Maybe<Scalars['String']>;
  businessLogo?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  font?: Maybe<Scalars['String']>;
  formBackground?: Maybe<Scalars['String']>;
  headerCode?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  layout?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  sideBannerImage?: Maybe<Scalars['String']>;
  successMessage?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "leadForm" */
export type LeadForm_Min_Order_By = {
  backgroundColor?: InputMaybe<Order_By>;
  businessLogo?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  font?: InputMaybe<Order_By>;
  formBackground?: InputMaybe<Order_By>;
  headerCode?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  layout?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  sideBannerImage?: InputMaybe<Order_By>;
  successMessage?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "leadForm" */
export type LeadForm_Mutation_Response = {
  __typename?: 'leadForm_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<LeadForm>;
};

/** input type for inserting object relation for remote table "leadForm" */
export type LeadForm_Obj_Rel_Insert_Input = {
  data: LeadForm_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<LeadForm_On_Conflict>;
};

/** on_conflict condition type for table "leadForm" */
export type LeadForm_On_Conflict = {
  constraint: LeadForm_Constraint;
  update_columns?: Array<LeadForm_Update_Column>;
  where?: InputMaybe<LeadForm_Bool_Exp>;
};

/** Ordering options when selecting data from "leadForm". */
export type LeadForm_Order_By = {
  backgroundColor?: InputMaybe<Order_By>;
  businessLogo?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  font?: InputMaybe<Order_By>;
  formBackground?: InputMaybe<Order_By>;
  headerCode?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  isArchived?: InputMaybe<Order_By>;
  layout?: InputMaybe<Order_By>;
  leadFormEntries_aggregate?: InputMaybe<LeadFormEntry_Aggregate_Order_By>;
  leadFormSteps_aggregate?: InputMaybe<LeadFormStep_Aggregate_Order_By>;
  lockedToLocations?: InputMaybe<Order_By>;
  lockedToServices?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  sideBannerImage?: InputMaybe<Order_By>;
  successMessage?: InputMaybe<Order_By>;
  surveyJSJson?: InputMaybe<Order_By>;
  themeJSON?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  workspace?: InputMaybe<Workspace_Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: leadForm */
export type LeadForm_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type LeadForm_Prepend_Input = {
  lockedToLocations?: InputMaybe<Scalars['jsonb']>;
  lockedToServices?: InputMaybe<Scalars['jsonb']>;
  surveyJSJson?: InputMaybe<Scalars['jsonb']>;
  themeJSON?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "leadForm" */
export enum LeadForm_Select_Column {
  /** column name */
  BackgroundColor = 'backgroundColor',
  /** column name */
  BusinessLogo = 'businessLogo',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Font = 'font',
  /** column name */
  FormBackground = 'formBackground',
  /** column name */
  HeaderCode = 'headerCode',
  /** column name */
  Id = 'id',
  /** column name */
  IsArchived = 'isArchived',
  /** column name */
  Layout = 'layout',
  /** column name */
  LockedToLocations = 'lockedToLocations',
  /** column name */
  LockedToServices = 'lockedToServices',
  /** column name */
  Name = 'name',
  /** column name */
  SideBannerImage = 'sideBannerImage',
  /** column name */
  SuccessMessage = 'successMessage',
  /** column name */
  SurveyJsJson = 'surveyJSJson',
  /** column name */
  ThemeJson = 'themeJSON',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WorkspaceId = 'workspaceId'
}

/** select "leadForm_aggregate_bool_exp_bool_and_arguments_columns" columns of table "leadForm" */
export enum LeadForm_Select_Column_LeadForm_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsArchived = 'isArchived'
}

/** select "leadForm_aggregate_bool_exp_bool_or_arguments_columns" columns of table "leadForm" */
export enum LeadForm_Select_Column_LeadForm_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsArchived = 'isArchived'
}

/** input type for updating data in table "leadForm" */
export type LeadForm_Set_Input = {
  backgroundColor?: InputMaybe<Scalars['String']>;
  businessLogo?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  font?: InputMaybe<Scalars['String']>;
  formBackground?: InputMaybe<Scalars['String']>;
  headerCode?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  isArchived?: InputMaybe<Scalars['Boolean']>;
  layout?: InputMaybe<Scalars['String']>;
  lockedToLocations?: InputMaybe<Scalars['jsonb']>;
  lockedToServices?: InputMaybe<Scalars['jsonb']>;
  name?: InputMaybe<Scalars['String']>;
  sideBannerImage?: InputMaybe<Scalars['String']>;
  successMessage?: InputMaybe<Scalars['String']>;
  surveyJSJson?: InputMaybe<Scalars['jsonb']>;
  themeJSON?: InputMaybe<Scalars['jsonb']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "leadForm" */
export type LeadForm_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: LeadForm_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type LeadForm_Stream_Cursor_Value_Input = {
  backgroundColor?: InputMaybe<Scalars['String']>;
  businessLogo?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  font?: InputMaybe<Scalars['String']>;
  formBackground?: InputMaybe<Scalars['String']>;
  headerCode?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  isArchived?: InputMaybe<Scalars['Boolean']>;
  layout?: InputMaybe<Scalars['String']>;
  lockedToLocations?: InputMaybe<Scalars['jsonb']>;
  lockedToServices?: InputMaybe<Scalars['jsonb']>;
  name?: InputMaybe<Scalars['String']>;
  sideBannerImage?: InputMaybe<Scalars['String']>;
  successMessage?: InputMaybe<Scalars['String']>;
  surveyJSJson?: InputMaybe<Scalars['jsonb']>;
  themeJSON?: InputMaybe<Scalars['jsonb']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "leadForm" */
export enum LeadForm_Update_Column {
  /** column name */
  BackgroundColor = 'backgroundColor',
  /** column name */
  BusinessLogo = 'businessLogo',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Font = 'font',
  /** column name */
  FormBackground = 'formBackground',
  /** column name */
  HeaderCode = 'headerCode',
  /** column name */
  Id = 'id',
  /** column name */
  IsArchived = 'isArchived',
  /** column name */
  Layout = 'layout',
  /** column name */
  LockedToLocations = 'lockedToLocations',
  /** column name */
  LockedToServices = 'lockedToServices',
  /** column name */
  Name = 'name',
  /** column name */
  SideBannerImage = 'sideBannerImage',
  /** column name */
  SuccessMessage = 'successMessage',
  /** column name */
  SurveyJsJson = 'surveyJSJson',
  /** column name */
  ThemeJson = 'themeJSON',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WorkspaceId = 'workspaceId'
}

export type LeadForm_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<LeadForm_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<LeadForm_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<LeadForm_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<LeadForm_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<LeadForm_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<LeadForm_Set_Input>;
  /** filter the rows which have to be updated */
  where: LeadForm_Bool_Exp;
};

/** columns and relationships of "leadTag" */
export type LeadTag = {
  __typename?: 'leadTag';
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  title: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  workspaceId: Scalars['uuid'];
};

/** aggregated selection of "leadTag" */
export type LeadTag_Aggregate = {
  __typename?: 'leadTag_aggregate';
  aggregate?: Maybe<LeadTag_Aggregate_Fields>;
  nodes: Array<LeadTag>;
};

/** aggregate fields of "leadTag" */
export type LeadTag_Aggregate_Fields = {
  __typename?: 'leadTag_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<LeadTag_Max_Fields>;
  min?: Maybe<LeadTag_Min_Fields>;
};


/** aggregate fields of "leadTag" */
export type LeadTag_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<LeadTag_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "leadTag". All fields are combined with a logical 'AND'. */
export type LeadTag_Bool_Exp = {
  _and?: InputMaybe<Array<LeadTag_Bool_Exp>>;
  _not?: InputMaybe<LeadTag_Bool_Exp>;
  _or?: InputMaybe<Array<LeadTag_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  workspaceId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "leadTag" */
export enum LeadTag_Constraint {
  /** unique or primary key constraint on columns "id" */
  LeadTagPkey = 'leadTag_pkey',
  /** unique or primary key constraint on columns "workspaceId", "title" */
  LeadTagWorkspaceIdTitleKey = 'leadTag_workspaceId_title_key'
}

/** input type for inserting data into table "leadTag" */
export type LeadTag_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type LeadTag_Max_Fields = {
  __typename?: 'leadTag_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type LeadTag_Min_Fields = {
  __typename?: 'leadTag_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "leadTag" */
export type LeadTag_Mutation_Response = {
  __typename?: 'leadTag_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<LeadTag>;
};

/** input type for inserting object relation for remote table "leadTag" */
export type LeadTag_Obj_Rel_Insert_Input = {
  data: LeadTag_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<LeadTag_On_Conflict>;
};

/** on_conflict condition type for table "leadTag" */
export type LeadTag_On_Conflict = {
  constraint: LeadTag_Constraint;
  update_columns?: Array<LeadTag_Update_Column>;
  where?: InputMaybe<LeadTag_Bool_Exp>;
};

/** Ordering options when selecting data from "leadTag". */
export type LeadTag_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: leadTag */
export type LeadTag_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "leadTag" */
export enum LeadTag_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WorkspaceId = 'workspaceId'
}

/** input type for updating data in table "leadTag" */
export type LeadTag_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "leadTag" */
export type LeadTag_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: LeadTag_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type LeadTag_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "leadTag" */
export enum LeadTag_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WorkspaceId = 'workspaceId'
}

export type LeadTag_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<LeadTag_Set_Input>;
  /** filter the rows which have to be updated */
  where: LeadTag_Bool_Exp;
};

/** columns and relationships of "leadTrackedEvent" */
export type LeadTrackedEvent = {
  __typename?: 'leadTrackedEvent';
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An object relationship */
  leadFormEntry?: Maybe<LeadFormEntry>;
  leadFormEntryId?: Maybe<Scalars['uuid']>;
  outcome: Scalars['String'];
  patientId?: Maybe<Scalars['uuid']>;
  type?: Maybe<Scalars['String']>;
  updatedAt: Scalars['timestamptz'];
  /** An object relationship */
  user?: Maybe<User>;
  userId?: Maybe<Scalars['uuid']>;
  workspaceId: Scalars['uuid'];
};

/** aggregated selection of "leadTrackedEvent" */
export type LeadTrackedEvent_Aggregate = {
  __typename?: 'leadTrackedEvent_aggregate';
  aggregate?: Maybe<LeadTrackedEvent_Aggregate_Fields>;
  nodes: Array<LeadTrackedEvent>;
};

/** aggregate fields of "leadTrackedEvent" */
export type LeadTrackedEvent_Aggregate_Fields = {
  __typename?: 'leadTrackedEvent_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<LeadTrackedEvent_Max_Fields>;
  min?: Maybe<LeadTrackedEvent_Min_Fields>;
};


/** aggregate fields of "leadTrackedEvent" */
export type LeadTrackedEvent_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<LeadTrackedEvent_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "leadTrackedEvent". All fields are combined with a logical 'AND'. */
export type LeadTrackedEvent_Bool_Exp = {
  _and?: InputMaybe<Array<LeadTrackedEvent_Bool_Exp>>;
  _not?: InputMaybe<LeadTrackedEvent_Bool_Exp>;
  _or?: InputMaybe<Array<LeadTrackedEvent_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  leadFormEntry?: InputMaybe<LeadFormEntry_Bool_Exp>;
  leadFormEntryId?: InputMaybe<Uuid_Comparison_Exp>;
  outcome?: InputMaybe<String_Comparison_Exp>;
  patientId?: InputMaybe<Uuid_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<User_Bool_Exp>;
  userId?: InputMaybe<Uuid_Comparison_Exp>;
  workspaceId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "leadTrackedEvent" */
export enum LeadTrackedEvent_Constraint {
  /** unique or primary key constraint on columns "id" */
  LeadPhoneCallPkey = 'leadPhoneCall_pkey'
}

/** input type for inserting data into table "leadTrackedEvent" */
export type LeadTrackedEvent_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  leadFormEntry?: InputMaybe<LeadFormEntry_Obj_Rel_Insert_Input>;
  leadFormEntryId?: InputMaybe<Scalars['uuid']>;
  outcome?: InputMaybe<Scalars['String']>;
  patientId?: InputMaybe<Scalars['uuid']>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<User_Obj_Rel_Insert_Input>;
  userId?: InputMaybe<Scalars['uuid']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type LeadTrackedEvent_Max_Fields = {
  __typename?: 'leadTrackedEvent_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  leadFormEntryId?: Maybe<Scalars['uuid']>;
  outcome?: Maybe<Scalars['String']>;
  patientId?: Maybe<Scalars['uuid']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['uuid']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type LeadTrackedEvent_Min_Fields = {
  __typename?: 'leadTrackedEvent_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  leadFormEntryId?: Maybe<Scalars['uuid']>;
  outcome?: Maybe<Scalars['String']>;
  patientId?: Maybe<Scalars['uuid']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['uuid']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "leadTrackedEvent" */
export type LeadTrackedEvent_Mutation_Response = {
  __typename?: 'leadTrackedEvent_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<LeadTrackedEvent>;
};

/** on_conflict condition type for table "leadTrackedEvent" */
export type LeadTrackedEvent_On_Conflict = {
  constraint: LeadTrackedEvent_Constraint;
  update_columns?: Array<LeadTrackedEvent_Update_Column>;
  where?: InputMaybe<LeadTrackedEvent_Bool_Exp>;
};

/** Ordering options when selecting data from "leadTrackedEvent". */
export type LeadTrackedEvent_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  leadFormEntry?: InputMaybe<LeadFormEntry_Order_By>;
  leadFormEntryId?: InputMaybe<Order_By>;
  outcome?: InputMaybe<Order_By>;
  patientId?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  user?: InputMaybe<User_Order_By>;
  userId?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: leadTrackedEvent */
export type LeadTrackedEvent_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "leadTrackedEvent" */
export enum LeadTrackedEvent_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  LeadFormEntryId = 'leadFormEntryId',
  /** column name */
  Outcome = 'outcome',
  /** column name */
  PatientId = 'patientId',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId',
  /** column name */
  WorkspaceId = 'workspaceId'
}

/** input type for updating data in table "leadTrackedEvent" */
export type LeadTrackedEvent_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  leadFormEntryId?: InputMaybe<Scalars['uuid']>;
  outcome?: InputMaybe<Scalars['String']>;
  patientId?: InputMaybe<Scalars['uuid']>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  userId?: InputMaybe<Scalars['uuid']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "leadTrackedEvent" */
export type LeadTrackedEvent_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: LeadTrackedEvent_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type LeadTrackedEvent_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  leadFormEntryId?: InputMaybe<Scalars['uuid']>;
  outcome?: InputMaybe<Scalars['String']>;
  patientId?: InputMaybe<Scalars['uuid']>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  userId?: InputMaybe<Scalars['uuid']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "leadTrackedEvent" */
export enum LeadTrackedEvent_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  LeadFormEntryId = 'leadFormEntryId',
  /** column name */
  Outcome = 'outcome',
  /** column name */
  PatientId = 'patientId',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId',
  /** column name */
  WorkspaceId = 'workspaceId'
}

export type LeadTrackedEvent_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<LeadTrackedEvent_Set_Input>;
  /** filter the rows which have to be updated */
  where: LeadTrackedEvent_Bool_Exp;
};

/** columns and relationships of "location" */
export type Location = {
  __typename?: 'location';
  address?: Maybe<Scalars['String']>;
  addressId?: Maybe<Scalars['uuid']>;
  /** An array relationship */
  appointments: Array<Appointment>;
  /** An aggregate relationship */
  appointments_aggregate: Appointment_Aggregate;
  clinicGroupId?: Maybe<Scalars['Int']>;
  clinicGroupName?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['Int']>;
  clinicKey?: Maybe<Scalars['String']>;
  createdAt: Scalars['timestamptz'];
  /** An array relationship */
  devices: Array<Device>;
  /** An aggregate relationship */
  devices_aggregate: Device_Aggregate;
  email?: Maybe<Scalars['String']>;
  /** An array relationship */
  externalLocations: Array<ExternalLocation>;
  /** An aggregate relationship */
  externalLocations_aggregate: ExternalLocation_Aggregate;
  /** An array relationship */
  externalReviews: Array<ExternalReview>;
  /** An aggregate relationship */
  externalReviews_aggregate: ExternalReview_Aggregate;
  finixMerchantId?: Maybe<Scalars['String']>;
  /** An object relationship */
  fullAddress?: Maybe<Address>;
  id: Scalars['uuid'];
  isActive: Scalars['Boolean'];
  isSystemManaged?: Maybe<Scalars['Boolean']>;
  /** An array relationship */
  locationProviders: Array<LocationProvider>;
  /** An aggregate relationship */
  locationProviders_aggregate: LocationProvider_Aggregate;
  /** An array relationship */
  locationSchedules: Array<LocationSchedule>;
  /** An aggregate relationship */
  locationSchedules_aggregate: LocationSchedule_Aggregate;
  /** An array relationship */
  locationServices: Array<LocationService>;
  /** An aggregate relationship */
  locationServices_aggregate: LocationService_Aggregate;
  maxPatientsPerTimeslot?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  payrixMerchantId?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  /** An array relationship */
  providerSchedules: Array<ProviderSchedule>;
  /** An aggregate relationship */
  providerSchedules_aggregate: ProviderSchedule_Aggregate;
  /** An array relationship */
  rooms: Array<Room>;
  /** An aggregate relationship */
  rooms_aggregate: Room_Aggregate;
  timezone?: Maybe<Scalars['String']>;
  twilioPhoneNumber?: Maybe<Scalars['String']>;
  updatedAt: Scalars['timestamptz'];
  workEndTime?: Maybe<Scalars['String']>;
  workStartTime?: Maybe<Scalars['String']>;
  /** An object relationship */
  workspace: Workspace;
  workspaceId: Scalars['uuid'];
};


/** columns and relationships of "location" */
export type LocationAppointmentsArgs = {
  distinct_on?: InputMaybe<Array<Appointment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Appointment_Order_By>>;
  where?: InputMaybe<Appointment_Bool_Exp>;
};


/** columns and relationships of "location" */
export type LocationAppointments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Appointment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Appointment_Order_By>>;
  where?: InputMaybe<Appointment_Bool_Exp>;
};


/** columns and relationships of "location" */
export type LocationDevicesArgs = {
  distinct_on?: InputMaybe<Array<Device_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Device_Order_By>>;
  where?: InputMaybe<Device_Bool_Exp>;
};


/** columns and relationships of "location" */
export type LocationDevices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Device_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Device_Order_By>>;
  where?: InputMaybe<Device_Bool_Exp>;
};


/** columns and relationships of "location" */
export type LocationExternalLocationsArgs = {
  distinct_on?: InputMaybe<Array<ExternalLocation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ExternalLocation_Order_By>>;
  where?: InputMaybe<ExternalLocation_Bool_Exp>;
};


/** columns and relationships of "location" */
export type LocationExternalLocations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ExternalLocation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ExternalLocation_Order_By>>;
  where?: InputMaybe<ExternalLocation_Bool_Exp>;
};


/** columns and relationships of "location" */
export type LocationExternalReviewsArgs = {
  distinct_on?: InputMaybe<Array<ExternalReview_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ExternalReview_Order_By>>;
  where?: InputMaybe<ExternalReview_Bool_Exp>;
};


/** columns and relationships of "location" */
export type LocationExternalReviews_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ExternalReview_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ExternalReview_Order_By>>;
  where?: InputMaybe<ExternalReview_Bool_Exp>;
};


/** columns and relationships of "location" */
export type LocationLocationProvidersArgs = {
  distinct_on?: InputMaybe<Array<LocationProvider_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<LocationProvider_Order_By>>;
  where?: InputMaybe<LocationProvider_Bool_Exp>;
};


/** columns and relationships of "location" */
export type LocationLocationProviders_AggregateArgs = {
  distinct_on?: InputMaybe<Array<LocationProvider_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<LocationProvider_Order_By>>;
  where?: InputMaybe<LocationProvider_Bool_Exp>;
};


/** columns and relationships of "location" */
export type LocationLocationSchedulesArgs = {
  distinct_on?: InputMaybe<Array<LocationSchedule_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<LocationSchedule_Order_By>>;
  where?: InputMaybe<LocationSchedule_Bool_Exp>;
};


/** columns and relationships of "location" */
export type LocationLocationSchedules_AggregateArgs = {
  distinct_on?: InputMaybe<Array<LocationSchedule_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<LocationSchedule_Order_By>>;
  where?: InputMaybe<LocationSchedule_Bool_Exp>;
};


/** columns and relationships of "location" */
export type LocationLocationServicesArgs = {
  distinct_on?: InputMaybe<Array<LocationService_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<LocationService_Order_By>>;
  where?: InputMaybe<LocationService_Bool_Exp>;
};


/** columns and relationships of "location" */
export type LocationLocationServices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<LocationService_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<LocationService_Order_By>>;
  where?: InputMaybe<LocationService_Bool_Exp>;
};


/** columns and relationships of "location" */
export type LocationProviderSchedulesArgs = {
  distinct_on?: InputMaybe<Array<ProviderSchedule_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ProviderSchedule_Order_By>>;
  where?: InputMaybe<ProviderSchedule_Bool_Exp>;
};


/** columns and relationships of "location" */
export type LocationProviderSchedules_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ProviderSchedule_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ProviderSchedule_Order_By>>;
  where?: InputMaybe<ProviderSchedule_Bool_Exp>;
};


/** columns and relationships of "location" */
export type LocationRoomsArgs = {
  distinct_on?: InputMaybe<Array<Room_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Room_Order_By>>;
  where?: InputMaybe<Room_Bool_Exp>;
};


/** columns and relationships of "location" */
export type LocationRooms_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Room_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Room_Order_By>>;
  where?: InputMaybe<Room_Bool_Exp>;
};

/** columns and relationships of "locationCoupon" */
export type LocationCoupon = {
  __typename?: 'locationCoupon';
  /** An object relationship */
  coupon: Coupon;
  couponId: Scalars['uuid'];
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An object relationship */
  location: Location;
  locationId: Scalars['uuid'];
  updatedAt: Scalars['timestamptz'];
};

/** aggregated selection of "locationCoupon" */
export type LocationCoupon_Aggregate = {
  __typename?: 'locationCoupon_aggregate';
  aggregate?: Maybe<LocationCoupon_Aggregate_Fields>;
  nodes: Array<LocationCoupon>;
};

export type LocationCoupon_Aggregate_Bool_Exp = {
  count?: InputMaybe<LocationCoupon_Aggregate_Bool_Exp_Count>;
};

export type LocationCoupon_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<LocationCoupon_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<LocationCoupon_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "locationCoupon" */
export type LocationCoupon_Aggregate_Fields = {
  __typename?: 'locationCoupon_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<LocationCoupon_Max_Fields>;
  min?: Maybe<LocationCoupon_Min_Fields>;
};


/** aggregate fields of "locationCoupon" */
export type LocationCoupon_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<LocationCoupon_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "locationCoupon" */
export type LocationCoupon_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<LocationCoupon_Max_Order_By>;
  min?: InputMaybe<LocationCoupon_Min_Order_By>;
};

/** input type for inserting array relation for remote table "locationCoupon" */
export type LocationCoupon_Arr_Rel_Insert_Input = {
  data: Array<LocationCoupon_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<LocationCoupon_On_Conflict>;
};

/** Boolean expression to filter rows from the table "locationCoupon". All fields are combined with a logical 'AND'. */
export type LocationCoupon_Bool_Exp = {
  _and?: InputMaybe<Array<LocationCoupon_Bool_Exp>>;
  _not?: InputMaybe<LocationCoupon_Bool_Exp>;
  _or?: InputMaybe<Array<LocationCoupon_Bool_Exp>>;
  coupon?: InputMaybe<Coupon_Bool_Exp>;
  couponId?: InputMaybe<Uuid_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  location?: InputMaybe<Location_Bool_Exp>;
  locationId?: InputMaybe<Uuid_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "locationCoupon" */
export enum LocationCoupon_Constraint {
  /** unique or primary key constraint on columns "couponId", "locationId" */
  LocationCouponLocationIdCouponIdKey = 'locationCoupon_locationId_couponId_key',
  /** unique or primary key constraint on columns "id" */
  LocationCouponPkey = 'locationCoupon_pkey'
}

/** input type for inserting data into table "locationCoupon" */
export type LocationCoupon_Insert_Input = {
  coupon?: InputMaybe<Coupon_Obj_Rel_Insert_Input>;
  couponId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  location?: InputMaybe<Location_Obj_Rel_Insert_Input>;
  locationId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type LocationCoupon_Max_Fields = {
  __typename?: 'locationCoupon_max_fields';
  couponId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  locationId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "locationCoupon" */
export type LocationCoupon_Max_Order_By = {
  couponId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  locationId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type LocationCoupon_Min_Fields = {
  __typename?: 'locationCoupon_min_fields';
  couponId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  locationId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "locationCoupon" */
export type LocationCoupon_Min_Order_By = {
  couponId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  locationId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "locationCoupon" */
export type LocationCoupon_Mutation_Response = {
  __typename?: 'locationCoupon_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<LocationCoupon>;
};

/** on_conflict condition type for table "locationCoupon" */
export type LocationCoupon_On_Conflict = {
  constraint: LocationCoupon_Constraint;
  update_columns?: Array<LocationCoupon_Update_Column>;
  where?: InputMaybe<LocationCoupon_Bool_Exp>;
};

/** Ordering options when selecting data from "locationCoupon". */
export type LocationCoupon_Order_By = {
  coupon?: InputMaybe<Coupon_Order_By>;
  couponId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  location?: InputMaybe<Location_Order_By>;
  locationId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: locationCoupon */
export type LocationCoupon_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "locationCoupon" */
export enum LocationCoupon_Select_Column {
  /** column name */
  CouponId = 'couponId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  LocationId = 'locationId',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "locationCoupon" */
export type LocationCoupon_Set_Input = {
  couponId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  locationId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "locationCoupon" */
export type LocationCoupon_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: LocationCoupon_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type LocationCoupon_Stream_Cursor_Value_Input = {
  couponId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  locationId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "locationCoupon" */
export enum LocationCoupon_Update_Column {
  /** column name */
  CouponId = 'couponId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  LocationId = 'locationId',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type LocationCoupon_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<LocationCoupon_Set_Input>;
  /** filter the rows which have to be updated */
  where: LocationCoupon_Bool_Exp;
};

/** columns and relationships of "locationProvider" */
export type LocationProvider = {
  __typename?: 'locationProvider';
  clinicianId?: Maybe<Scalars['Int']>;
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An object relationship */
  location: Location;
  locationId: Scalars['uuid'];
  /** An object relationship */
  provider: Provider;
  providerId: Scalars['uuid'];
  registrationStatus?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "locationProvider" */
export type LocationProvider_Aggregate = {
  __typename?: 'locationProvider_aggregate';
  aggregate?: Maybe<LocationProvider_Aggregate_Fields>;
  nodes: Array<LocationProvider>;
};

export type LocationProvider_Aggregate_Bool_Exp = {
  count?: InputMaybe<LocationProvider_Aggregate_Bool_Exp_Count>;
};

export type LocationProvider_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<LocationProvider_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<LocationProvider_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "locationProvider" */
export type LocationProvider_Aggregate_Fields = {
  __typename?: 'locationProvider_aggregate_fields';
  avg?: Maybe<LocationProvider_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<LocationProvider_Max_Fields>;
  min?: Maybe<LocationProvider_Min_Fields>;
  stddev?: Maybe<LocationProvider_Stddev_Fields>;
  stddev_pop?: Maybe<LocationProvider_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<LocationProvider_Stddev_Samp_Fields>;
  sum?: Maybe<LocationProvider_Sum_Fields>;
  var_pop?: Maybe<LocationProvider_Var_Pop_Fields>;
  var_samp?: Maybe<LocationProvider_Var_Samp_Fields>;
  variance?: Maybe<LocationProvider_Variance_Fields>;
};


/** aggregate fields of "locationProvider" */
export type LocationProvider_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<LocationProvider_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "locationProvider" */
export type LocationProvider_Aggregate_Order_By = {
  avg?: InputMaybe<LocationProvider_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<LocationProvider_Max_Order_By>;
  min?: InputMaybe<LocationProvider_Min_Order_By>;
  stddev?: InputMaybe<LocationProvider_Stddev_Order_By>;
  stddev_pop?: InputMaybe<LocationProvider_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<LocationProvider_Stddev_Samp_Order_By>;
  sum?: InputMaybe<LocationProvider_Sum_Order_By>;
  var_pop?: InputMaybe<LocationProvider_Var_Pop_Order_By>;
  var_samp?: InputMaybe<LocationProvider_Var_Samp_Order_By>;
  variance?: InputMaybe<LocationProvider_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "locationProvider" */
export type LocationProvider_Arr_Rel_Insert_Input = {
  data: Array<LocationProvider_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<LocationProvider_On_Conflict>;
};

/** aggregate avg on columns */
export type LocationProvider_Avg_Fields = {
  __typename?: 'locationProvider_avg_fields';
  clinicianId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "locationProvider" */
export type LocationProvider_Avg_Order_By = {
  clinicianId?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "locationProvider". All fields are combined with a logical 'AND'. */
export type LocationProvider_Bool_Exp = {
  _and?: InputMaybe<Array<LocationProvider_Bool_Exp>>;
  _not?: InputMaybe<LocationProvider_Bool_Exp>;
  _or?: InputMaybe<Array<LocationProvider_Bool_Exp>>;
  clinicianId?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  location?: InputMaybe<Location_Bool_Exp>;
  locationId?: InputMaybe<Uuid_Comparison_Exp>;
  provider?: InputMaybe<Provider_Bool_Exp>;
  providerId?: InputMaybe<Uuid_Comparison_Exp>;
  registrationStatus?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "locationProvider" */
export enum LocationProvider_Constraint {
  /** unique or primary key constraint on columns "providerId", "locationId" */
  LocationProviderLocationIdProviderIdKey = 'locationProvider_locationId_providerId_key',
  /** unique or primary key constraint on columns "id" */
  LocationProviderPkey = 'locationProvider_pkey'
}

/** input type for incrementing numeric columns in table "locationProvider" */
export type LocationProvider_Inc_Input = {
  clinicianId?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "locationProvider" */
export type LocationProvider_Insert_Input = {
  clinicianId?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  location?: InputMaybe<Location_Obj_Rel_Insert_Input>;
  locationId?: InputMaybe<Scalars['uuid']>;
  provider?: InputMaybe<Provider_Obj_Rel_Insert_Input>;
  providerId?: InputMaybe<Scalars['uuid']>;
  registrationStatus?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type LocationProvider_Max_Fields = {
  __typename?: 'locationProvider_max_fields';
  clinicianId?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  locationId?: Maybe<Scalars['uuid']>;
  providerId?: Maybe<Scalars['uuid']>;
  registrationStatus?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "locationProvider" */
export type LocationProvider_Max_Order_By = {
  clinicianId?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  locationId?: InputMaybe<Order_By>;
  providerId?: InputMaybe<Order_By>;
  registrationStatus?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type LocationProvider_Min_Fields = {
  __typename?: 'locationProvider_min_fields';
  clinicianId?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  locationId?: Maybe<Scalars['uuid']>;
  providerId?: Maybe<Scalars['uuid']>;
  registrationStatus?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "locationProvider" */
export type LocationProvider_Min_Order_By = {
  clinicianId?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  locationId?: InputMaybe<Order_By>;
  providerId?: InputMaybe<Order_By>;
  registrationStatus?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "locationProvider" */
export type LocationProvider_Mutation_Response = {
  __typename?: 'locationProvider_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<LocationProvider>;
};

/** on_conflict condition type for table "locationProvider" */
export type LocationProvider_On_Conflict = {
  constraint: LocationProvider_Constraint;
  update_columns?: Array<LocationProvider_Update_Column>;
  where?: InputMaybe<LocationProvider_Bool_Exp>;
};

/** Ordering options when selecting data from "locationProvider". */
export type LocationProvider_Order_By = {
  clinicianId?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  location?: InputMaybe<Location_Order_By>;
  locationId?: InputMaybe<Order_By>;
  provider?: InputMaybe<Provider_Order_By>;
  providerId?: InputMaybe<Order_By>;
  registrationStatus?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: locationProvider */
export type LocationProvider_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "locationProvider" */
export enum LocationProvider_Select_Column {
  /** column name */
  ClinicianId = 'clinicianId',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  LocationId = 'locationId',
  /** column name */
  ProviderId = 'providerId',
  /** column name */
  RegistrationStatus = 'registrationStatus',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "locationProvider" */
export type LocationProvider_Set_Input = {
  clinicianId?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  locationId?: InputMaybe<Scalars['uuid']>;
  providerId?: InputMaybe<Scalars['uuid']>;
  registrationStatus?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type LocationProvider_Stddev_Fields = {
  __typename?: 'locationProvider_stddev_fields';
  clinicianId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "locationProvider" */
export type LocationProvider_Stddev_Order_By = {
  clinicianId?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type LocationProvider_Stddev_Pop_Fields = {
  __typename?: 'locationProvider_stddev_pop_fields';
  clinicianId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "locationProvider" */
export type LocationProvider_Stddev_Pop_Order_By = {
  clinicianId?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type LocationProvider_Stddev_Samp_Fields = {
  __typename?: 'locationProvider_stddev_samp_fields';
  clinicianId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "locationProvider" */
export type LocationProvider_Stddev_Samp_Order_By = {
  clinicianId?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "locationProvider" */
export type LocationProvider_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: LocationProvider_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type LocationProvider_Stream_Cursor_Value_Input = {
  clinicianId?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  locationId?: InputMaybe<Scalars['uuid']>;
  providerId?: InputMaybe<Scalars['uuid']>;
  registrationStatus?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type LocationProvider_Sum_Fields = {
  __typename?: 'locationProvider_sum_fields';
  clinicianId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "locationProvider" */
export type LocationProvider_Sum_Order_By = {
  clinicianId?: InputMaybe<Order_By>;
};

/** update columns of table "locationProvider" */
export enum LocationProvider_Update_Column {
  /** column name */
  ClinicianId = 'clinicianId',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  LocationId = 'locationId',
  /** column name */
  ProviderId = 'providerId',
  /** column name */
  RegistrationStatus = 'registrationStatus',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type LocationProvider_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<LocationProvider_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<LocationProvider_Set_Input>;
  /** filter the rows which have to be updated */
  where: LocationProvider_Bool_Exp;
};

/** aggregate var_pop on columns */
export type LocationProvider_Var_Pop_Fields = {
  __typename?: 'locationProvider_var_pop_fields';
  clinicianId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "locationProvider" */
export type LocationProvider_Var_Pop_Order_By = {
  clinicianId?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type LocationProvider_Var_Samp_Fields = {
  __typename?: 'locationProvider_var_samp_fields';
  clinicianId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "locationProvider" */
export type LocationProvider_Var_Samp_Order_By = {
  clinicianId?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type LocationProvider_Variance_Fields = {
  __typename?: 'locationProvider_variance_fields';
  clinicianId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "locationProvider" */
export type LocationProvider_Variance_Order_By = {
  clinicianId?: InputMaybe<Order_By>;
};

/** columns and relationships of "locationSchedule" */
export type LocationSchedule = {
  __typename?: 'locationSchedule';
  createdAt: Scalars['timestamptz'];
  effectiveFrom?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  /** An object relationship */
  location: Location;
  locationId: Scalars['uuid'];
  schedule: Scalars['jsonb'];
  updatedAt: Scalars['timestamptz'];
};


/** columns and relationships of "locationSchedule" */
export type LocationScheduleScheduleArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "locationSchedule" */
export type LocationSchedule_Aggregate = {
  __typename?: 'locationSchedule_aggregate';
  aggregate?: Maybe<LocationSchedule_Aggregate_Fields>;
  nodes: Array<LocationSchedule>;
};

export type LocationSchedule_Aggregate_Bool_Exp = {
  count?: InputMaybe<LocationSchedule_Aggregate_Bool_Exp_Count>;
};

export type LocationSchedule_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<LocationSchedule_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<LocationSchedule_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "locationSchedule" */
export type LocationSchedule_Aggregate_Fields = {
  __typename?: 'locationSchedule_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<LocationSchedule_Max_Fields>;
  min?: Maybe<LocationSchedule_Min_Fields>;
};


/** aggregate fields of "locationSchedule" */
export type LocationSchedule_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<LocationSchedule_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "locationSchedule" */
export type LocationSchedule_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<LocationSchedule_Max_Order_By>;
  min?: InputMaybe<LocationSchedule_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type LocationSchedule_Append_Input = {
  schedule?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "locationSchedule" */
export type LocationSchedule_Arr_Rel_Insert_Input = {
  data: Array<LocationSchedule_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<LocationSchedule_On_Conflict>;
};

/** Boolean expression to filter rows from the table "locationSchedule". All fields are combined with a logical 'AND'. */
export type LocationSchedule_Bool_Exp = {
  _and?: InputMaybe<Array<LocationSchedule_Bool_Exp>>;
  _not?: InputMaybe<LocationSchedule_Bool_Exp>;
  _or?: InputMaybe<Array<LocationSchedule_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  effectiveFrom?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  location?: InputMaybe<Location_Bool_Exp>;
  locationId?: InputMaybe<Uuid_Comparison_Exp>;
  schedule?: InputMaybe<Jsonb_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "locationSchedule" */
export enum LocationSchedule_Constraint {
  /** unique or primary key constraint on columns "id" */
  LocationSchedulePkey = 'locationSchedule_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type LocationSchedule_Delete_At_Path_Input = {
  schedule?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type LocationSchedule_Delete_Elem_Input = {
  schedule?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type LocationSchedule_Delete_Key_Input = {
  schedule?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "locationSchedule" */
export type LocationSchedule_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  effectiveFrom?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  location?: InputMaybe<Location_Obj_Rel_Insert_Input>;
  locationId?: InputMaybe<Scalars['uuid']>;
  schedule?: InputMaybe<Scalars['jsonb']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type LocationSchedule_Max_Fields = {
  __typename?: 'locationSchedule_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  effectiveFrom?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  locationId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "locationSchedule" */
export type LocationSchedule_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  effectiveFrom?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  locationId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type LocationSchedule_Min_Fields = {
  __typename?: 'locationSchedule_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  effectiveFrom?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  locationId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "locationSchedule" */
export type LocationSchedule_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  effectiveFrom?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  locationId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "locationSchedule" */
export type LocationSchedule_Mutation_Response = {
  __typename?: 'locationSchedule_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<LocationSchedule>;
};

/** on_conflict condition type for table "locationSchedule" */
export type LocationSchedule_On_Conflict = {
  constraint: LocationSchedule_Constraint;
  update_columns?: Array<LocationSchedule_Update_Column>;
  where?: InputMaybe<LocationSchedule_Bool_Exp>;
};

/** Ordering options when selecting data from "locationSchedule". */
export type LocationSchedule_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  effectiveFrom?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  location?: InputMaybe<Location_Order_By>;
  locationId?: InputMaybe<Order_By>;
  schedule?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: locationSchedule */
export type LocationSchedule_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type LocationSchedule_Prepend_Input = {
  schedule?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "locationSchedule" */
export enum LocationSchedule_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  EffectiveFrom = 'effectiveFrom',
  /** column name */
  Id = 'id',
  /** column name */
  LocationId = 'locationId',
  /** column name */
  Schedule = 'schedule',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "locationSchedule" */
export type LocationSchedule_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  effectiveFrom?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  locationId?: InputMaybe<Scalars['uuid']>;
  schedule?: InputMaybe<Scalars['jsonb']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "locationSchedule" */
export type LocationSchedule_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: LocationSchedule_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type LocationSchedule_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  effectiveFrom?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  locationId?: InputMaybe<Scalars['uuid']>;
  schedule?: InputMaybe<Scalars['jsonb']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "locationSchedule" */
export enum LocationSchedule_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  EffectiveFrom = 'effectiveFrom',
  /** column name */
  Id = 'id',
  /** column name */
  LocationId = 'locationId',
  /** column name */
  Schedule = 'schedule',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type LocationSchedule_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<LocationSchedule_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<LocationSchedule_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<LocationSchedule_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<LocationSchedule_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<LocationSchedule_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<LocationSchedule_Set_Input>;
  /** filter the rows which have to be updated */
  where: LocationSchedule_Bool_Exp;
};

/** columns and relationships of "locationService" */
export type LocationService = {
  __typename?: 'locationService';
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An object relationship */
  location: Location;
  locationId: Scalars['uuid'];
  price?: Maybe<Scalars['float8']>;
  /** An object relationship */
  service: Service;
  serviceId: Scalars['uuid'];
  taxRate?: Maybe<Scalars['float8']>;
  updatedAt: Scalars['timestamptz'];
};

/** aggregated selection of "locationService" */
export type LocationService_Aggregate = {
  __typename?: 'locationService_aggregate';
  aggregate?: Maybe<LocationService_Aggregate_Fields>;
  nodes: Array<LocationService>;
};

export type LocationService_Aggregate_Bool_Exp = {
  avg?: InputMaybe<LocationService_Aggregate_Bool_Exp_Avg>;
  corr?: InputMaybe<LocationService_Aggregate_Bool_Exp_Corr>;
  count?: InputMaybe<LocationService_Aggregate_Bool_Exp_Count>;
  covar_samp?: InputMaybe<LocationService_Aggregate_Bool_Exp_Covar_Samp>;
  max?: InputMaybe<LocationService_Aggregate_Bool_Exp_Max>;
  min?: InputMaybe<LocationService_Aggregate_Bool_Exp_Min>;
  stddev_samp?: InputMaybe<LocationService_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: InputMaybe<LocationService_Aggregate_Bool_Exp_Sum>;
  var_samp?: InputMaybe<LocationService_Aggregate_Bool_Exp_Var_Samp>;
};

export type LocationService_Aggregate_Bool_Exp_Avg = {
  arguments: LocationService_Select_Column_LocationService_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<LocationService_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type LocationService_Aggregate_Bool_Exp_Corr = {
  arguments: LocationService_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<LocationService_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type LocationService_Aggregate_Bool_Exp_Corr_Arguments = {
  X: LocationService_Select_Column_LocationService_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: LocationService_Select_Column_LocationService_Aggregate_Bool_Exp_Corr_Arguments_Columns;
};

export type LocationService_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<LocationService_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<LocationService_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type LocationService_Aggregate_Bool_Exp_Covar_Samp = {
  arguments: LocationService_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<LocationService_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type LocationService_Aggregate_Bool_Exp_Covar_Samp_Arguments = {
  X: LocationService_Select_Column_LocationService_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  Y: LocationService_Select_Column_LocationService_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
};

export type LocationService_Aggregate_Bool_Exp_Max = {
  arguments: LocationService_Select_Column_LocationService_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<LocationService_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type LocationService_Aggregate_Bool_Exp_Min = {
  arguments: LocationService_Select_Column_LocationService_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<LocationService_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type LocationService_Aggregate_Bool_Exp_Stddev_Samp = {
  arguments: LocationService_Select_Column_LocationService_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<LocationService_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type LocationService_Aggregate_Bool_Exp_Sum = {
  arguments: LocationService_Select_Column_LocationService_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<LocationService_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type LocationService_Aggregate_Bool_Exp_Var_Samp = {
  arguments: LocationService_Select_Column_LocationService_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<LocationService_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

/** aggregate fields of "locationService" */
export type LocationService_Aggregate_Fields = {
  __typename?: 'locationService_aggregate_fields';
  avg?: Maybe<LocationService_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<LocationService_Max_Fields>;
  min?: Maybe<LocationService_Min_Fields>;
  stddev?: Maybe<LocationService_Stddev_Fields>;
  stddev_pop?: Maybe<LocationService_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<LocationService_Stddev_Samp_Fields>;
  sum?: Maybe<LocationService_Sum_Fields>;
  var_pop?: Maybe<LocationService_Var_Pop_Fields>;
  var_samp?: Maybe<LocationService_Var_Samp_Fields>;
  variance?: Maybe<LocationService_Variance_Fields>;
};


/** aggregate fields of "locationService" */
export type LocationService_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<LocationService_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "locationService" */
export type LocationService_Aggregate_Order_By = {
  avg?: InputMaybe<LocationService_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<LocationService_Max_Order_By>;
  min?: InputMaybe<LocationService_Min_Order_By>;
  stddev?: InputMaybe<LocationService_Stddev_Order_By>;
  stddev_pop?: InputMaybe<LocationService_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<LocationService_Stddev_Samp_Order_By>;
  sum?: InputMaybe<LocationService_Sum_Order_By>;
  var_pop?: InputMaybe<LocationService_Var_Pop_Order_By>;
  var_samp?: InputMaybe<LocationService_Var_Samp_Order_By>;
  variance?: InputMaybe<LocationService_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "locationService" */
export type LocationService_Arr_Rel_Insert_Input = {
  data: Array<LocationService_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<LocationService_On_Conflict>;
};

/** aggregate avg on columns */
export type LocationService_Avg_Fields = {
  __typename?: 'locationService_avg_fields';
  price?: Maybe<Scalars['Float']>;
  taxRate?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "locationService" */
export type LocationService_Avg_Order_By = {
  price?: InputMaybe<Order_By>;
  taxRate?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "locationService". All fields are combined with a logical 'AND'. */
export type LocationService_Bool_Exp = {
  _and?: InputMaybe<Array<LocationService_Bool_Exp>>;
  _not?: InputMaybe<LocationService_Bool_Exp>;
  _or?: InputMaybe<Array<LocationService_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  location?: InputMaybe<Location_Bool_Exp>;
  locationId?: InputMaybe<Uuid_Comparison_Exp>;
  price?: InputMaybe<Float8_Comparison_Exp>;
  service?: InputMaybe<Service_Bool_Exp>;
  serviceId?: InputMaybe<Uuid_Comparison_Exp>;
  taxRate?: InputMaybe<Float8_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "locationService" */
export enum LocationService_Constraint {
  /** unique or primary key constraint on columns "serviceId", "locationId" */
  LocationServiceLocationIdServiceIdKey = 'locationService_locationId_serviceId_key',
  /** unique or primary key constraint on columns "id" */
  LocationServicePkey = 'locationService_pkey'
}

/** input type for incrementing numeric columns in table "locationService" */
export type LocationService_Inc_Input = {
  price?: InputMaybe<Scalars['float8']>;
  taxRate?: InputMaybe<Scalars['float8']>;
};

/** input type for inserting data into table "locationService" */
export type LocationService_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  location?: InputMaybe<Location_Obj_Rel_Insert_Input>;
  locationId?: InputMaybe<Scalars['uuid']>;
  price?: InputMaybe<Scalars['float8']>;
  service?: InputMaybe<Service_Obj_Rel_Insert_Input>;
  serviceId?: InputMaybe<Scalars['uuid']>;
  taxRate?: InputMaybe<Scalars['float8']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type LocationService_Max_Fields = {
  __typename?: 'locationService_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  locationId?: Maybe<Scalars['uuid']>;
  price?: Maybe<Scalars['float8']>;
  serviceId?: Maybe<Scalars['uuid']>;
  taxRate?: Maybe<Scalars['float8']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "locationService" */
export type LocationService_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  locationId?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  serviceId?: InputMaybe<Order_By>;
  taxRate?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type LocationService_Min_Fields = {
  __typename?: 'locationService_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  locationId?: Maybe<Scalars['uuid']>;
  price?: Maybe<Scalars['float8']>;
  serviceId?: Maybe<Scalars['uuid']>;
  taxRate?: Maybe<Scalars['float8']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "locationService" */
export type LocationService_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  locationId?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  serviceId?: InputMaybe<Order_By>;
  taxRate?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "locationService" */
export type LocationService_Mutation_Response = {
  __typename?: 'locationService_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<LocationService>;
};

/** on_conflict condition type for table "locationService" */
export type LocationService_On_Conflict = {
  constraint: LocationService_Constraint;
  update_columns?: Array<LocationService_Update_Column>;
  where?: InputMaybe<LocationService_Bool_Exp>;
};

/** Ordering options when selecting data from "locationService". */
export type LocationService_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  location?: InputMaybe<Location_Order_By>;
  locationId?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  service?: InputMaybe<Service_Order_By>;
  serviceId?: InputMaybe<Order_By>;
  taxRate?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: locationService */
export type LocationService_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "locationService" */
export enum LocationService_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  LocationId = 'locationId',
  /** column name */
  Price = 'price',
  /** column name */
  ServiceId = 'serviceId',
  /** column name */
  TaxRate = 'taxRate',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** select "locationService_aggregate_bool_exp_avg_arguments_columns" columns of table "locationService" */
export enum LocationService_Select_Column_LocationService_Aggregate_Bool_Exp_Avg_Arguments_Columns {
  /** column name */
  Price = 'price',
  /** column name */
  TaxRate = 'taxRate'
}

/** select "locationService_aggregate_bool_exp_corr_arguments_columns" columns of table "locationService" */
export enum LocationService_Select_Column_LocationService_Aggregate_Bool_Exp_Corr_Arguments_Columns {
  /** column name */
  Price = 'price',
  /** column name */
  TaxRate = 'taxRate'
}

/** select "locationService_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "locationService" */
export enum LocationService_Select_Column_LocationService_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns {
  /** column name */
  Price = 'price',
  /** column name */
  TaxRate = 'taxRate'
}

/** select "locationService_aggregate_bool_exp_max_arguments_columns" columns of table "locationService" */
export enum LocationService_Select_Column_LocationService_Aggregate_Bool_Exp_Max_Arguments_Columns {
  /** column name */
  Price = 'price',
  /** column name */
  TaxRate = 'taxRate'
}

/** select "locationService_aggregate_bool_exp_min_arguments_columns" columns of table "locationService" */
export enum LocationService_Select_Column_LocationService_Aggregate_Bool_Exp_Min_Arguments_Columns {
  /** column name */
  Price = 'price',
  /** column name */
  TaxRate = 'taxRate'
}

/** select "locationService_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "locationService" */
export enum LocationService_Select_Column_LocationService_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns {
  /** column name */
  Price = 'price',
  /** column name */
  TaxRate = 'taxRate'
}

/** select "locationService_aggregate_bool_exp_sum_arguments_columns" columns of table "locationService" */
export enum LocationService_Select_Column_LocationService_Aggregate_Bool_Exp_Sum_Arguments_Columns {
  /** column name */
  Price = 'price',
  /** column name */
  TaxRate = 'taxRate'
}

/** select "locationService_aggregate_bool_exp_var_samp_arguments_columns" columns of table "locationService" */
export enum LocationService_Select_Column_LocationService_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns {
  /** column name */
  Price = 'price',
  /** column name */
  TaxRate = 'taxRate'
}

/** input type for updating data in table "locationService" */
export type LocationService_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  locationId?: InputMaybe<Scalars['uuid']>;
  price?: InputMaybe<Scalars['float8']>;
  serviceId?: InputMaybe<Scalars['uuid']>;
  taxRate?: InputMaybe<Scalars['float8']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type LocationService_Stddev_Fields = {
  __typename?: 'locationService_stddev_fields';
  price?: Maybe<Scalars['Float']>;
  taxRate?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "locationService" */
export type LocationService_Stddev_Order_By = {
  price?: InputMaybe<Order_By>;
  taxRate?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type LocationService_Stddev_Pop_Fields = {
  __typename?: 'locationService_stddev_pop_fields';
  price?: Maybe<Scalars['Float']>;
  taxRate?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "locationService" */
export type LocationService_Stddev_Pop_Order_By = {
  price?: InputMaybe<Order_By>;
  taxRate?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type LocationService_Stddev_Samp_Fields = {
  __typename?: 'locationService_stddev_samp_fields';
  price?: Maybe<Scalars['Float']>;
  taxRate?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "locationService" */
export type LocationService_Stddev_Samp_Order_By = {
  price?: InputMaybe<Order_By>;
  taxRate?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "locationService" */
export type LocationService_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: LocationService_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type LocationService_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  locationId?: InputMaybe<Scalars['uuid']>;
  price?: InputMaybe<Scalars['float8']>;
  serviceId?: InputMaybe<Scalars['uuid']>;
  taxRate?: InputMaybe<Scalars['float8']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type LocationService_Sum_Fields = {
  __typename?: 'locationService_sum_fields';
  price?: Maybe<Scalars['float8']>;
  taxRate?: Maybe<Scalars['float8']>;
};

/** order by sum() on columns of table "locationService" */
export type LocationService_Sum_Order_By = {
  price?: InputMaybe<Order_By>;
  taxRate?: InputMaybe<Order_By>;
};

/** update columns of table "locationService" */
export enum LocationService_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  LocationId = 'locationId',
  /** column name */
  Price = 'price',
  /** column name */
  ServiceId = 'serviceId',
  /** column name */
  TaxRate = 'taxRate',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type LocationService_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<LocationService_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<LocationService_Set_Input>;
  /** filter the rows which have to be updated */
  where: LocationService_Bool_Exp;
};

/** aggregate var_pop on columns */
export type LocationService_Var_Pop_Fields = {
  __typename?: 'locationService_var_pop_fields';
  price?: Maybe<Scalars['Float']>;
  taxRate?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "locationService" */
export type LocationService_Var_Pop_Order_By = {
  price?: InputMaybe<Order_By>;
  taxRate?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type LocationService_Var_Samp_Fields = {
  __typename?: 'locationService_var_samp_fields';
  price?: Maybe<Scalars['Float']>;
  taxRate?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "locationService" */
export type LocationService_Var_Samp_Order_By = {
  price?: InputMaybe<Order_By>;
  taxRate?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type LocationService_Variance_Fields = {
  __typename?: 'locationService_variance_fields';
  price?: Maybe<Scalars['Float']>;
  taxRate?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "locationService" */
export type LocationService_Variance_Order_By = {
  price?: InputMaybe<Order_By>;
  taxRate?: InputMaybe<Order_By>;
};

/** aggregated selection of "location" */
export type Location_Aggregate = {
  __typename?: 'location_aggregate';
  aggregate?: Maybe<Location_Aggregate_Fields>;
  nodes: Array<Location>;
};

export type Location_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Location_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Location_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Location_Aggregate_Bool_Exp_Count>;
};

export type Location_Aggregate_Bool_Exp_Bool_And = {
  arguments: Location_Select_Column_Location_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Location_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Location_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Location_Select_Column_Location_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Location_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Location_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Location_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Location_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "location" */
export type Location_Aggregate_Fields = {
  __typename?: 'location_aggregate_fields';
  avg?: Maybe<Location_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Location_Max_Fields>;
  min?: Maybe<Location_Min_Fields>;
  stddev?: Maybe<Location_Stddev_Fields>;
  stddev_pop?: Maybe<Location_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Location_Stddev_Samp_Fields>;
  sum?: Maybe<Location_Sum_Fields>;
  var_pop?: Maybe<Location_Var_Pop_Fields>;
  var_samp?: Maybe<Location_Var_Samp_Fields>;
  variance?: Maybe<Location_Variance_Fields>;
};


/** aggregate fields of "location" */
export type Location_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Location_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "location" */
export type Location_Aggregate_Order_By = {
  avg?: InputMaybe<Location_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Location_Max_Order_By>;
  min?: InputMaybe<Location_Min_Order_By>;
  stddev?: InputMaybe<Location_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Location_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Location_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Location_Sum_Order_By>;
  var_pop?: InputMaybe<Location_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Location_Var_Samp_Order_By>;
  variance?: InputMaybe<Location_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "location" */
export type Location_Arr_Rel_Insert_Input = {
  data: Array<Location_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Location_On_Conflict>;
};

/** aggregate avg on columns */
export type Location_Avg_Fields = {
  __typename?: 'location_avg_fields';
  clinicGroupId?: Maybe<Scalars['Float']>;
  clinicId?: Maybe<Scalars['Float']>;
  maxPatientsPerTimeslot?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "location" */
export type Location_Avg_Order_By = {
  clinicGroupId?: InputMaybe<Order_By>;
  clinicId?: InputMaybe<Order_By>;
  maxPatientsPerTimeslot?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "location". All fields are combined with a logical 'AND'. */
export type Location_Bool_Exp = {
  _and?: InputMaybe<Array<Location_Bool_Exp>>;
  _not?: InputMaybe<Location_Bool_Exp>;
  _or?: InputMaybe<Array<Location_Bool_Exp>>;
  address?: InputMaybe<String_Comparison_Exp>;
  addressId?: InputMaybe<Uuid_Comparison_Exp>;
  appointments?: InputMaybe<Appointment_Bool_Exp>;
  appointments_aggregate?: InputMaybe<Appointment_Aggregate_Bool_Exp>;
  clinicGroupId?: InputMaybe<Int_Comparison_Exp>;
  clinicGroupName?: InputMaybe<String_Comparison_Exp>;
  clinicId?: InputMaybe<Int_Comparison_Exp>;
  clinicKey?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  devices?: InputMaybe<Device_Bool_Exp>;
  devices_aggregate?: InputMaybe<Device_Aggregate_Bool_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  externalLocations?: InputMaybe<ExternalLocation_Bool_Exp>;
  externalLocations_aggregate?: InputMaybe<ExternalLocation_Aggregate_Bool_Exp>;
  externalReviews?: InputMaybe<ExternalReview_Bool_Exp>;
  externalReviews_aggregate?: InputMaybe<ExternalReview_Aggregate_Bool_Exp>;
  finixMerchantId?: InputMaybe<String_Comparison_Exp>;
  fullAddress?: InputMaybe<Address_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  isActive?: InputMaybe<Boolean_Comparison_Exp>;
  isSystemManaged?: InputMaybe<Boolean_Comparison_Exp>;
  locationProviders?: InputMaybe<LocationProvider_Bool_Exp>;
  locationProviders_aggregate?: InputMaybe<LocationProvider_Aggregate_Bool_Exp>;
  locationSchedules?: InputMaybe<LocationSchedule_Bool_Exp>;
  locationSchedules_aggregate?: InputMaybe<LocationSchedule_Aggregate_Bool_Exp>;
  locationServices?: InputMaybe<LocationService_Bool_Exp>;
  locationServices_aggregate?: InputMaybe<LocationService_Aggregate_Bool_Exp>;
  maxPatientsPerTimeslot?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  payrixMerchantId?: InputMaybe<String_Comparison_Exp>;
  phoneNumber?: InputMaybe<String_Comparison_Exp>;
  providerSchedules?: InputMaybe<ProviderSchedule_Bool_Exp>;
  providerSchedules_aggregate?: InputMaybe<ProviderSchedule_Aggregate_Bool_Exp>;
  rooms?: InputMaybe<Room_Bool_Exp>;
  rooms_aggregate?: InputMaybe<Room_Aggregate_Bool_Exp>;
  timezone?: InputMaybe<String_Comparison_Exp>;
  twilioPhoneNumber?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  workEndTime?: InputMaybe<String_Comparison_Exp>;
  workStartTime?: InputMaybe<String_Comparison_Exp>;
  workspace?: InputMaybe<Workspace_Bool_Exp>;
  workspaceId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "location" */
export enum Location_Constraint {
  /** unique or primary key constraint on columns "id" */
  LocationPkey = 'location_pkey'
}

/** input type for incrementing numeric columns in table "location" */
export type Location_Inc_Input = {
  clinicGroupId?: InputMaybe<Scalars['Int']>;
  clinicId?: InputMaybe<Scalars['Int']>;
  maxPatientsPerTimeslot?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "location" */
export type Location_Insert_Input = {
  address?: InputMaybe<Scalars['String']>;
  addressId?: InputMaybe<Scalars['uuid']>;
  appointments?: InputMaybe<Appointment_Arr_Rel_Insert_Input>;
  clinicGroupId?: InputMaybe<Scalars['Int']>;
  clinicGroupName?: InputMaybe<Scalars['String']>;
  clinicId?: InputMaybe<Scalars['Int']>;
  clinicKey?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  devices?: InputMaybe<Device_Arr_Rel_Insert_Input>;
  email?: InputMaybe<Scalars['String']>;
  externalLocations?: InputMaybe<ExternalLocation_Arr_Rel_Insert_Input>;
  externalReviews?: InputMaybe<ExternalReview_Arr_Rel_Insert_Input>;
  finixMerchantId?: InputMaybe<Scalars['String']>;
  fullAddress?: InputMaybe<Address_Obj_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  isSystemManaged?: InputMaybe<Scalars['Boolean']>;
  locationProviders?: InputMaybe<LocationProvider_Arr_Rel_Insert_Input>;
  locationSchedules?: InputMaybe<LocationSchedule_Arr_Rel_Insert_Input>;
  locationServices?: InputMaybe<LocationService_Arr_Rel_Insert_Input>;
  maxPatientsPerTimeslot?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  payrixMerchantId?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  providerSchedules?: InputMaybe<ProviderSchedule_Arr_Rel_Insert_Input>;
  rooms?: InputMaybe<Room_Arr_Rel_Insert_Input>;
  timezone?: InputMaybe<Scalars['String']>;
  twilioPhoneNumber?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workEndTime?: InputMaybe<Scalars['String']>;
  workStartTime?: InputMaybe<Scalars['String']>;
  workspace?: InputMaybe<Workspace_Obj_Rel_Insert_Input>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Location_Max_Fields = {
  __typename?: 'location_max_fields';
  address?: Maybe<Scalars['String']>;
  addressId?: Maybe<Scalars['uuid']>;
  clinicGroupId?: Maybe<Scalars['Int']>;
  clinicGroupName?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['Int']>;
  clinicKey?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  finixMerchantId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  maxPatientsPerTimeslot?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  payrixMerchantId?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  twilioPhoneNumber?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workEndTime?: Maybe<Scalars['String']>;
  workStartTime?: Maybe<Scalars['String']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "location" */
export type Location_Max_Order_By = {
  address?: InputMaybe<Order_By>;
  addressId?: InputMaybe<Order_By>;
  clinicGroupId?: InputMaybe<Order_By>;
  clinicGroupName?: InputMaybe<Order_By>;
  clinicId?: InputMaybe<Order_By>;
  clinicKey?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  finixMerchantId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  maxPatientsPerTimeslot?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  payrixMerchantId?: InputMaybe<Order_By>;
  phoneNumber?: InputMaybe<Order_By>;
  timezone?: InputMaybe<Order_By>;
  twilioPhoneNumber?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  workEndTime?: InputMaybe<Order_By>;
  workStartTime?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Location_Min_Fields = {
  __typename?: 'location_min_fields';
  address?: Maybe<Scalars['String']>;
  addressId?: Maybe<Scalars['uuid']>;
  clinicGroupId?: Maybe<Scalars['Int']>;
  clinicGroupName?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['Int']>;
  clinicKey?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  finixMerchantId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  maxPatientsPerTimeslot?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  payrixMerchantId?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  twilioPhoneNumber?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workEndTime?: Maybe<Scalars['String']>;
  workStartTime?: Maybe<Scalars['String']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "location" */
export type Location_Min_Order_By = {
  address?: InputMaybe<Order_By>;
  addressId?: InputMaybe<Order_By>;
  clinicGroupId?: InputMaybe<Order_By>;
  clinicGroupName?: InputMaybe<Order_By>;
  clinicId?: InputMaybe<Order_By>;
  clinicKey?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  finixMerchantId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  maxPatientsPerTimeslot?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  payrixMerchantId?: InputMaybe<Order_By>;
  phoneNumber?: InputMaybe<Order_By>;
  timezone?: InputMaybe<Order_By>;
  twilioPhoneNumber?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  workEndTime?: InputMaybe<Order_By>;
  workStartTime?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "location" */
export type Location_Mutation_Response = {
  __typename?: 'location_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Location>;
};

/** input type for inserting object relation for remote table "location" */
export type Location_Obj_Rel_Insert_Input = {
  data: Location_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Location_On_Conflict>;
};

/** on_conflict condition type for table "location" */
export type Location_On_Conflict = {
  constraint: Location_Constraint;
  update_columns?: Array<Location_Update_Column>;
  where?: InputMaybe<Location_Bool_Exp>;
};

/** Ordering options when selecting data from "location". */
export type Location_Order_By = {
  address?: InputMaybe<Order_By>;
  addressId?: InputMaybe<Order_By>;
  appointments_aggregate?: InputMaybe<Appointment_Aggregate_Order_By>;
  clinicGroupId?: InputMaybe<Order_By>;
  clinicGroupName?: InputMaybe<Order_By>;
  clinicId?: InputMaybe<Order_By>;
  clinicKey?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  devices_aggregate?: InputMaybe<Device_Aggregate_Order_By>;
  email?: InputMaybe<Order_By>;
  externalLocations_aggregate?: InputMaybe<ExternalLocation_Aggregate_Order_By>;
  externalReviews_aggregate?: InputMaybe<ExternalReview_Aggregate_Order_By>;
  finixMerchantId?: InputMaybe<Order_By>;
  fullAddress?: InputMaybe<Address_Order_By>;
  id?: InputMaybe<Order_By>;
  isActive?: InputMaybe<Order_By>;
  isSystemManaged?: InputMaybe<Order_By>;
  locationProviders_aggregate?: InputMaybe<LocationProvider_Aggregate_Order_By>;
  locationSchedules_aggregate?: InputMaybe<LocationSchedule_Aggregate_Order_By>;
  locationServices_aggregate?: InputMaybe<LocationService_Aggregate_Order_By>;
  maxPatientsPerTimeslot?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  payrixMerchantId?: InputMaybe<Order_By>;
  phoneNumber?: InputMaybe<Order_By>;
  providerSchedules_aggregate?: InputMaybe<ProviderSchedule_Aggregate_Order_By>;
  rooms_aggregate?: InputMaybe<Room_Aggregate_Order_By>;
  timezone?: InputMaybe<Order_By>;
  twilioPhoneNumber?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  workEndTime?: InputMaybe<Order_By>;
  workStartTime?: InputMaybe<Order_By>;
  workspace?: InputMaybe<Workspace_Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: location */
export type Location_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "location" */
export enum Location_Select_Column {
  /** column name */
  Address = 'address',
  /** column name */
  AddressId = 'addressId',
  /** column name */
  ClinicGroupId = 'clinicGroupId',
  /** column name */
  ClinicGroupName = 'clinicGroupName',
  /** column name */
  ClinicId = 'clinicId',
  /** column name */
  ClinicKey = 'clinicKey',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Email = 'email',
  /** column name */
  FinixMerchantId = 'finixMerchantId',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'isActive',
  /** column name */
  IsSystemManaged = 'isSystemManaged',
  /** column name */
  MaxPatientsPerTimeslot = 'maxPatientsPerTimeslot',
  /** column name */
  Name = 'name',
  /** column name */
  PayrixMerchantId = 'payrixMerchantId',
  /** column name */
  PhoneNumber = 'phoneNumber',
  /** column name */
  Timezone = 'timezone',
  /** column name */
  TwilioPhoneNumber = 'twilioPhoneNumber',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WorkEndTime = 'workEndTime',
  /** column name */
  WorkStartTime = 'workStartTime',
  /** column name */
  WorkspaceId = 'workspaceId'
}

/** select "location_aggregate_bool_exp_bool_and_arguments_columns" columns of table "location" */
export enum Location_Select_Column_Location_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsActive = 'isActive',
  /** column name */
  IsSystemManaged = 'isSystemManaged'
}

/** select "location_aggregate_bool_exp_bool_or_arguments_columns" columns of table "location" */
export enum Location_Select_Column_Location_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsActive = 'isActive',
  /** column name */
  IsSystemManaged = 'isSystemManaged'
}

/** input type for updating data in table "location" */
export type Location_Set_Input = {
  address?: InputMaybe<Scalars['String']>;
  addressId?: InputMaybe<Scalars['uuid']>;
  clinicGroupId?: InputMaybe<Scalars['Int']>;
  clinicGroupName?: InputMaybe<Scalars['String']>;
  clinicId?: InputMaybe<Scalars['Int']>;
  clinicKey?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  finixMerchantId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  isSystemManaged?: InputMaybe<Scalars['Boolean']>;
  maxPatientsPerTimeslot?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  payrixMerchantId?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  timezone?: InputMaybe<Scalars['String']>;
  twilioPhoneNumber?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workEndTime?: InputMaybe<Scalars['String']>;
  workStartTime?: InputMaybe<Scalars['String']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Location_Stddev_Fields = {
  __typename?: 'location_stddev_fields';
  clinicGroupId?: Maybe<Scalars['Float']>;
  clinicId?: Maybe<Scalars['Float']>;
  maxPatientsPerTimeslot?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "location" */
export type Location_Stddev_Order_By = {
  clinicGroupId?: InputMaybe<Order_By>;
  clinicId?: InputMaybe<Order_By>;
  maxPatientsPerTimeslot?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Location_Stddev_Pop_Fields = {
  __typename?: 'location_stddev_pop_fields';
  clinicGroupId?: Maybe<Scalars['Float']>;
  clinicId?: Maybe<Scalars['Float']>;
  maxPatientsPerTimeslot?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "location" */
export type Location_Stddev_Pop_Order_By = {
  clinicGroupId?: InputMaybe<Order_By>;
  clinicId?: InputMaybe<Order_By>;
  maxPatientsPerTimeslot?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Location_Stddev_Samp_Fields = {
  __typename?: 'location_stddev_samp_fields';
  clinicGroupId?: Maybe<Scalars['Float']>;
  clinicId?: Maybe<Scalars['Float']>;
  maxPatientsPerTimeslot?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "location" */
export type Location_Stddev_Samp_Order_By = {
  clinicGroupId?: InputMaybe<Order_By>;
  clinicId?: InputMaybe<Order_By>;
  maxPatientsPerTimeslot?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "location" */
export type Location_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Location_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Location_Stream_Cursor_Value_Input = {
  address?: InputMaybe<Scalars['String']>;
  addressId?: InputMaybe<Scalars['uuid']>;
  clinicGroupId?: InputMaybe<Scalars['Int']>;
  clinicGroupName?: InputMaybe<Scalars['String']>;
  clinicId?: InputMaybe<Scalars['Int']>;
  clinicKey?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  finixMerchantId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  isSystemManaged?: InputMaybe<Scalars['Boolean']>;
  maxPatientsPerTimeslot?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  payrixMerchantId?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  timezone?: InputMaybe<Scalars['String']>;
  twilioPhoneNumber?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workEndTime?: InputMaybe<Scalars['String']>;
  workStartTime?: InputMaybe<Scalars['String']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Location_Sum_Fields = {
  __typename?: 'location_sum_fields';
  clinicGroupId?: Maybe<Scalars['Int']>;
  clinicId?: Maybe<Scalars['Int']>;
  maxPatientsPerTimeslot?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "location" */
export type Location_Sum_Order_By = {
  clinicGroupId?: InputMaybe<Order_By>;
  clinicId?: InputMaybe<Order_By>;
  maxPatientsPerTimeslot?: InputMaybe<Order_By>;
};

/** update columns of table "location" */
export enum Location_Update_Column {
  /** column name */
  Address = 'address',
  /** column name */
  AddressId = 'addressId',
  /** column name */
  ClinicGroupId = 'clinicGroupId',
  /** column name */
  ClinicGroupName = 'clinicGroupName',
  /** column name */
  ClinicId = 'clinicId',
  /** column name */
  ClinicKey = 'clinicKey',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Email = 'email',
  /** column name */
  FinixMerchantId = 'finixMerchantId',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'isActive',
  /** column name */
  IsSystemManaged = 'isSystemManaged',
  /** column name */
  MaxPatientsPerTimeslot = 'maxPatientsPerTimeslot',
  /** column name */
  Name = 'name',
  /** column name */
  PayrixMerchantId = 'payrixMerchantId',
  /** column name */
  PhoneNumber = 'phoneNumber',
  /** column name */
  Timezone = 'timezone',
  /** column name */
  TwilioPhoneNumber = 'twilioPhoneNumber',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WorkEndTime = 'workEndTime',
  /** column name */
  WorkStartTime = 'workStartTime',
  /** column name */
  WorkspaceId = 'workspaceId'
}

export type Location_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Location_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Location_Set_Input>;
  /** filter the rows which have to be updated */
  where: Location_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Location_Var_Pop_Fields = {
  __typename?: 'location_var_pop_fields';
  clinicGroupId?: Maybe<Scalars['Float']>;
  clinicId?: Maybe<Scalars['Float']>;
  maxPatientsPerTimeslot?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "location" */
export type Location_Var_Pop_Order_By = {
  clinicGroupId?: InputMaybe<Order_By>;
  clinicId?: InputMaybe<Order_By>;
  maxPatientsPerTimeslot?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Location_Var_Samp_Fields = {
  __typename?: 'location_var_samp_fields';
  clinicGroupId?: Maybe<Scalars['Float']>;
  clinicId?: Maybe<Scalars['Float']>;
  maxPatientsPerTimeslot?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "location" */
export type Location_Var_Samp_Order_By = {
  clinicGroupId?: InputMaybe<Order_By>;
  clinicId?: InputMaybe<Order_By>;
  maxPatientsPerTimeslot?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Location_Variance_Fields = {
  __typename?: 'location_variance_fields';
  clinicGroupId?: Maybe<Scalars['Float']>;
  clinicId?: Maybe<Scalars['Float']>;
  maxPatientsPerTimeslot?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "location" */
export type Location_Variance_Order_By = {
  clinicGroupId?: InputMaybe<Order_By>;
  clinicId?: InputMaybe<Order_By>;
  maxPatientsPerTimeslot?: InputMaybe<Order_By>;
};

/** columns and relationships of "marketingTag" */
export type MarketingTag = {
  __typename?: 'marketingTag';
  createdAt: Scalars['timestamp'];
  id: Scalars['uuid'];
  title: Scalars['String'];
  updatedAt: Scalars['timestamp'];
  workspaceId: Scalars['uuid'];
};

/** columns and relationships of "marketingTagEmail" */
export type MarketingTagEmail = {
  __typename?: 'marketingTagEmail';
  createdAt: Scalars['timestamptz'];
  /** An object relationship */
  emailTemplate: EmailTemplate;
  emailTemplateId: Scalars['uuid'];
  id: Scalars['uuid'];
  /** An object relationship */
  marketingTag: MarketingTag;
  marketingTagId: Scalars['uuid'];
  updatedAt: Scalars['timestamp'];
};

/** aggregated selection of "marketingTagEmail" */
export type MarketingTagEmail_Aggregate = {
  __typename?: 'marketingTagEmail_aggregate';
  aggregate?: Maybe<MarketingTagEmail_Aggregate_Fields>;
  nodes: Array<MarketingTagEmail>;
};

export type MarketingTagEmail_Aggregate_Bool_Exp = {
  count?: InputMaybe<MarketingTagEmail_Aggregate_Bool_Exp_Count>;
};

export type MarketingTagEmail_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<MarketingTagEmail_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<MarketingTagEmail_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "marketingTagEmail" */
export type MarketingTagEmail_Aggregate_Fields = {
  __typename?: 'marketingTagEmail_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<MarketingTagEmail_Max_Fields>;
  min?: Maybe<MarketingTagEmail_Min_Fields>;
};


/** aggregate fields of "marketingTagEmail" */
export type MarketingTagEmail_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<MarketingTagEmail_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "marketingTagEmail" */
export type MarketingTagEmail_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<MarketingTagEmail_Max_Order_By>;
  min?: InputMaybe<MarketingTagEmail_Min_Order_By>;
};

/** input type for inserting array relation for remote table "marketingTagEmail" */
export type MarketingTagEmail_Arr_Rel_Insert_Input = {
  data: Array<MarketingTagEmail_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<MarketingTagEmail_On_Conflict>;
};

/** Boolean expression to filter rows from the table "marketingTagEmail". All fields are combined with a logical 'AND'. */
export type MarketingTagEmail_Bool_Exp = {
  _and?: InputMaybe<Array<MarketingTagEmail_Bool_Exp>>;
  _not?: InputMaybe<MarketingTagEmail_Bool_Exp>;
  _or?: InputMaybe<Array<MarketingTagEmail_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  emailTemplate?: InputMaybe<EmailTemplate_Bool_Exp>;
  emailTemplateId?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  marketingTag?: InputMaybe<MarketingTag_Bool_Exp>;
  marketingTagId?: InputMaybe<Uuid_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "marketingTagEmail" */
export enum MarketingTagEmail_Constraint {
  /** unique or primary key constraint on columns "id" */
  MarektingTagEmailPkey = 'marektingTagEmail_pkey',
  /** unique or primary key constraint on columns "marketingTagId", "emailTemplateId" */
  MarketingTagEmailMarketingTagIdEmailTemplateIdKey = 'marketingTagEmail_marketingTagId_emailTemplateId_key'
}

/** input type for inserting data into table "marketingTagEmail" */
export type MarketingTagEmail_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  emailTemplate?: InputMaybe<EmailTemplate_Obj_Rel_Insert_Input>;
  emailTemplateId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  marketingTag?: InputMaybe<MarketingTag_Obj_Rel_Insert_Input>;
  marketingTagId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamp']>;
};

/** aggregate max on columns */
export type MarketingTagEmail_Max_Fields = {
  __typename?: 'marketingTagEmail_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  emailTemplateId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  marketingTagId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
};

/** order by max() on columns of table "marketingTagEmail" */
export type MarketingTagEmail_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  emailTemplateId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  marketingTagId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type MarketingTagEmail_Min_Fields = {
  __typename?: 'marketingTagEmail_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  emailTemplateId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  marketingTagId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
};

/** order by min() on columns of table "marketingTagEmail" */
export type MarketingTagEmail_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  emailTemplateId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  marketingTagId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "marketingTagEmail" */
export type MarketingTagEmail_Mutation_Response = {
  __typename?: 'marketingTagEmail_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<MarketingTagEmail>;
};

/** on_conflict condition type for table "marketingTagEmail" */
export type MarketingTagEmail_On_Conflict = {
  constraint: MarketingTagEmail_Constraint;
  update_columns?: Array<MarketingTagEmail_Update_Column>;
  where?: InputMaybe<MarketingTagEmail_Bool_Exp>;
};

/** Ordering options when selecting data from "marketingTagEmail". */
export type MarketingTagEmail_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  emailTemplate?: InputMaybe<EmailTemplate_Order_By>;
  emailTemplateId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  marketingTag?: InputMaybe<MarketingTag_Order_By>;
  marketingTagId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: marketingTagEmail */
export type MarketingTagEmail_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "marketingTagEmail" */
export enum MarketingTagEmail_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  EmailTemplateId = 'emailTemplateId',
  /** column name */
  Id = 'id',
  /** column name */
  MarketingTagId = 'marketingTagId',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "marketingTagEmail" */
export type MarketingTagEmail_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  emailTemplateId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  marketingTagId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamp']>;
};

/** Streaming cursor of the table "marketingTagEmail" */
export type MarketingTagEmail_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: MarketingTagEmail_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type MarketingTagEmail_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  emailTemplateId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  marketingTagId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamp']>;
};

/** update columns of table "marketingTagEmail" */
export enum MarketingTagEmail_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  EmailTemplateId = 'emailTemplateId',
  /** column name */
  Id = 'id',
  /** column name */
  MarketingTagId = 'marketingTagId',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type MarketingTagEmail_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<MarketingTagEmail_Set_Input>;
  /** filter the rows which have to be updated */
  where: MarketingTagEmail_Bool_Exp;
};

/** columns and relationships of "marketingTagSms" */
export type MarketingTagSms = {
  __typename?: 'marketingTagSms';
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An object relationship */
  marketingTag: MarketingTag;
  marketingTagId: Scalars['uuid'];
  smsTemplateId: Scalars['uuid'];
  updatedAt: Scalars['timestamptz'];
};

/** aggregated selection of "marketingTagSms" */
export type MarketingTagSms_Aggregate = {
  __typename?: 'marketingTagSms_aggregate';
  aggregate?: Maybe<MarketingTagSms_Aggregate_Fields>;
  nodes: Array<MarketingTagSms>;
};

export type MarketingTagSms_Aggregate_Bool_Exp = {
  count?: InputMaybe<MarketingTagSms_Aggregate_Bool_Exp_Count>;
};

export type MarketingTagSms_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<MarketingTagSms_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<MarketingTagSms_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "marketingTagSms" */
export type MarketingTagSms_Aggregate_Fields = {
  __typename?: 'marketingTagSms_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<MarketingTagSms_Max_Fields>;
  min?: Maybe<MarketingTagSms_Min_Fields>;
};


/** aggregate fields of "marketingTagSms" */
export type MarketingTagSms_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<MarketingTagSms_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "marketingTagSms" */
export type MarketingTagSms_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<MarketingTagSms_Max_Order_By>;
  min?: InputMaybe<MarketingTagSms_Min_Order_By>;
};

/** input type for inserting array relation for remote table "marketingTagSms" */
export type MarketingTagSms_Arr_Rel_Insert_Input = {
  data: Array<MarketingTagSms_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<MarketingTagSms_On_Conflict>;
};

/** Boolean expression to filter rows from the table "marketingTagSms". All fields are combined with a logical 'AND'. */
export type MarketingTagSms_Bool_Exp = {
  _and?: InputMaybe<Array<MarketingTagSms_Bool_Exp>>;
  _not?: InputMaybe<MarketingTagSms_Bool_Exp>;
  _or?: InputMaybe<Array<MarketingTagSms_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  marketingTag?: InputMaybe<MarketingTag_Bool_Exp>;
  marketingTagId?: InputMaybe<Uuid_Comparison_Exp>;
  smsTemplateId?: InputMaybe<Uuid_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "marketingTagSms" */
export enum MarketingTagSms_Constraint {
  /** unique or primary key constraint on columns "marketingTagId", "smsTemplateId" */
  MarketingTagSmsMarketingTagIdSmsTemplateIdKey = 'marketingTagSms_marketingTagId_smsTemplateId_key',
  /** unique or primary key constraint on columns "id" */
  MarketingTagSmsPkey = 'marketingTagSms_pkey'
}

/** input type for inserting data into table "marketingTagSms" */
export type MarketingTagSms_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  marketingTag?: InputMaybe<MarketingTag_Obj_Rel_Insert_Input>;
  marketingTagId?: InputMaybe<Scalars['uuid']>;
  smsTemplateId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type MarketingTagSms_Max_Fields = {
  __typename?: 'marketingTagSms_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  marketingTagId?: Maybe<Scalars['uuid']>;
  smsTemplateId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "marketingTagSms" */
export type MarketingTagSms_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  marketingTagId?: InputMaybe<Order_By>;
  smsTemplateId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type MarketingTagSms_Min_Fields = {
  __typename?: 'marketingTagSms_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  marketingTagId?: Maybe<Scalars['uuid']>;
  smsTemplateId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "marketingTagSms" */
export type MarketingTagSms_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  marketingTagId?: InputMaybe<Order_By>;
  smsTemplateId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "marketingTagSms" */
export type MarketingTagSms_Mutation_Response = {
  __typename?: 'marketingTagSms_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<MarketingTagSms>;
};

/** on_conflict condition type for table "marketingTagSms" */
export type MarketingTagSms_On_Conflict = {
  constraint: MarketingTagSms_Constraint;
  update_columns?: Array<MarketingTagSms_Update_Column>;
  where?: InputMaybe<MarketingTagSms_Bool_Exp>;
};

/** Ordering options when selecting data from "marketingTagSms". */
export type MarketingTagSms_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  marketingTag?: InputMaybe<MarketingTag_Order_By>;
  marketingTagId?: InputMaybe<Order_By>;
  smsTemplateId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: marketingTagSms */
export type MarketingTagSms_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "marketingTagSms" */
export enum MarketingTagSms_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  MarketingTagId = 'marketingTagId',
  /** column name */
  SmsTemplateId = 'smsTemplateId',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "marketingTagSms" */
export type MarketingTagSms_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  marketingTagId?: InputMaybe<Scalars['uuid']>;
  smsTemplateId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "marketingTagSms" */
export type MarketingTagSms_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: MarketingTagSms_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type MarketingTagSms_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  marketingTagId?: InputMaybe<Scalars['uuid']>;
  smsTemplateId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "marketingTagSms" */
export enum MarketingTagSms_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  MarketingTagId = 'marketingTagId',
  /** column name */
  SmsTemplateId = 'smsTemplateId',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type MarketingTagSms_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<MarketingTagSms_Set_Input>;
  /** filter the rows which have to be updated */
  where: MarketingTagSms_Bool_Exp;
};

/** aggregated selection of "marketingTag" */
export type MarketingTag_Aggregate = {
  __typename?: 'marketingTag_aggregate';
  aggregate?: Maybe<MarketingTag_Aggregate_Fields>;
  nodes: Array<MarketingTag>;
};

/** aggregate fields of "marketingTag" */
export type MarketingTag_Aggregate_Fields = {
  __typename?: 'marketingTag_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<MarketingTag_Max_Fields>;
  min?: Maybe<MarketingTag_Min_Fields>;
};


/** aggregate fields of "marketingTag" */
export type MarketingTag_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<MarketingTag_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "marketingTag". All fields are combined with a logical 'AND'. */
export type MarketingTag_Bool_Exp = {
  _and?: InputMaybe<Array<MarketingTag_Bool_Exp>>;
  _not?: InputMaybe<MarketingTag_Bool_Exp>;
  _or?: InputMaybe<Array<MarketingTag_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamp_Comparison_Exp>;
  workspaceId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "marketingTag" */
export enum MarketingTag_Constraint {
  /** unique or primary key constraint on columns "id" */
  MarketingTagPkey = 'marketingTag_pkey',
  /** unique or primary key constraint on columns "title" */
  MarketingTagTitleKey = 'marketingTag_title_key'
}

/** input type for inserting data into table "marketingTag" */
export type MarketingTag_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['uuid']>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamp']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type MarketingTag_Max_Fields = {
  __typename?: 'marketingTag_max_fields';
  createdAt?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type MarketingTag_Min_Fields = {
  __typename?: 'marketingTag_min_fields';
  createdAt?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "marketingTag" */
export type MarketingTag_Mutation_Response = {
  __typename?: 'marketingTag_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<MarketingTag>;
};

/** input type for inserting object relation for remote table "marketingTag" */
export type MarketingTag_Obj_Rel_Insert_Input = {
  data: MarketingTag_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<MarketingTag_On_Conflict>;
};

/** on_conflict condition type for table "marketingTag" */
export type MarketingTag_On_Conflict = {
  constraint: MarketingTag_Constraint;
  update_columns?: Array<MarketingTag_Update_Column>;
  where?: InputMaybe<MarketingTag_Bool_Exp>;
};

/** Ordering options when selecting data from "marketingTag". */
export type MarketingTag_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: marketingTag */
export type MarketingTag_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "marketingTag" */
export enum MarketingTag_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WorkspaceId = 'workspaceId'
}

/** input type for updating data in table "marketingTag" */
export type MarketingTag_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['uuid']>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamp']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "marketingTag" */
export type MarketingTag_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: MarketingTag_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type MarketingTag_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['uuid']>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamp']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "marketingTag" */
export enum MarketingTag_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WorkspaceId = 'workspaceId'
}

export type MarketingTag_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<MarketingTag_Set_Input>;
  /** filter the rows which have to be updated */
  where: MarketingTag_Bool_Exp;
};

/** columns and relationships of "mediaTag" */
export type MediaTag = {
  __typename?: 'mediaTag';
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An array relationship */
  photoMediaTags: Array<PhotoMediaTag>;
  /** An aggregate relationship */
  photoMediaTags_aggregate: PhotoMediaTag_Aggregate;
  title: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  /** An object relationship */
  workspace: Workspace;
  workspaceId: Scalars['uuid'];
};


/** columns and relationships of "mediaTag" */
export type MediaTagPhotoMediaTagsArgs = {
  distinct_on?: InputMaybe<Array<PhotoMediaTag_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PhotoMediaTag_Order_By>>;
  where?: InputMaybe<PhotoMediaTag_Bool_Exp>;
};


/** columns and relationships of "mediaTag" */
export type MediaTagPhotoMediaTags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<PhotoMediaTag_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PhotoMediaTag_Order_By>>;
  where?: InputMaybe<PhotoMediaTag_Bool_Exp>;
};

/** aggregated selection of "mediaTag" */
export type MediaTag_Aggregate = {
  __typename?: 'mediaTag_aggregate';
  aggregate?: Maybe<MediaTag_Aggregate_Fields>;
  nodes: Array<MediaTag>;
};

export type MediaTag_Aggregate_Bool_Exp = {
  count?: InputMaybe<MediaTag_Aggregate_Bool_Exp_Count>;
};

export type MediaTag_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<MediaTag_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<MediaTag_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "mediaTag" */
export type MediaTag_Aggregate_Fields = {
  __typename?: 'mediaTag_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<MediaTag_Max_Fields>;
  min?: Maybe<MediaTag_Min_Fields>;
};


/** aggregate fields of "mediaTag" */
export type MediaTag_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<MediaTag_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "mediaTag" */
export type MediaTag_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<MediaTag_Max_Order_By>;
  min?: InputMaybe<MediaTag_Min_Order_By>;
};

/** input type for inserting array relation for remote table "mediaTag" */
export type MediaTag_Arr_Rel_Insert_Input = {
  data: Array<MediaTag_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<MediaTag_On_Conflict>;
};

/** Boolean expression to filter rows from the table "mediaTag". All fields are combined with a logical 'AND'. */
export type MediaTag_Bool_Exp = {
  _and?: InputMaybe<Array<MediaTag_Bool_Exp>>;
  _not?: InputMaybe<MediaTag_Bool_Exp>;
  _or?: InputMaybe<Array<MediaTag_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  photoMediaTags?: InputMaybe<PhotoMediaTag_Bool_Exp>;
  photoMediaTags_aggregate?: InputMaybe<PhotoMediaTag_Aggregate_Bool_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  workspace?: InputMaybe<Workspace_Bool_Exp>;
  workspaceId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "mediaTag" */
export enum MediaTag_Constraint {
  /** unique or primary key constraint on columns "id" */
  MediaTagPkey = 'mediaTag_pkey',
  /** unique or primary key constraint on columns "workspaceId", "title" */
  MediaTagTitleWorkspaceIdKey = 'mediaTag_title_workspaceId_key'
}

/** input type for inserting data into table "mediaTag" */
export type MediaTag_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  photoMediaTags?: InputMaybe<PhotoMediaTag_Arr_Rel_Insert_Input>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspace?: InputMaybe<Workspace_Obj_Rel_Insert_Input>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type MediaTag_Max_Fields = {
  __typename?: 'mediaTag_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "mediaTag" */
export type MediaTag_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type MediaTag_Min_Fields = {
  __typename?: 'mediaTag_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "mediaTag" */
export type MediaTag_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "mediaTag" */
export type MediaTag_Mutation_Response = {
  __typename?: 'mediaTag_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<MediaTag>;
};

/** input type for inserting object relation for remote table "mediaTag" */
export type MediaTag_Obj_Rel_Insert_Input = {
  data: MediaTag_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<MediaTag_On_Conflict>;
};

/** on_conflict condition type for table "mediaTag" */
export type MediaTag_On_Conflict = {
  constraint: MediaTag_Constraint;
  update_columns?: Array<MediaTag_Update_Column>;
  where?: InputMaybe<MediaTag_Bool_Exp>;
};

/** Ordering options when selecting data from "mediaTag". */
export type MediaTag_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  photoMediaTags_aggregate?: InputMaybe<PhotoMediaTag_Aggregate_Order_By>;
  title?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  workspace?: InputMaybe<Workspace_Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: mediaTag */
export type MediaTag_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "mediaTag" */
export enum MediaTag_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WorkspaceId = 'workspaceId'
}

/** input type for updating data in table "mediaTag" */
export type MediaTag_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "mediaTag" */
export type MediaTag_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: MediaTag_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type MediaTag_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "mediaTag" */
export enum MediaTag_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WorkspaceId = 'workspaceId'
}

export type MediaTag_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<MediaTag_Set_Input>;
  /** filter the rows which have to be updated */
  where: MediaTag_Bool_Exp;
};

/** columns and relationships of "membership" */
export type Membership = {
  __typename?: 'membership';
  archived: Scalars['Boolean'];
  availableInStore?: Maybe<Scalars['Boolean']>;
  billingDayOfMonth?: Maybe<Scalars['Int']>;
  billingPeriodInDays: Scalars['Int'];
  color: Scalars['String'];
  createdAt: Scalars['timestamptz'];
  credits: Scalars['Int'];
  description?: Maybe<Scalars['String']>;
  discount: Scalars['Int'];
  hasCoupons: Scalars['Boolean'];
  hasCredits: Scalars['Boolean'];
  id: Scalars['uuid'];
  image?: Maybe<Scalars['String']>;
  /** An object relationship */
  location?: Maybe<Location>;
  locationId?: Maybe<Scalars['uuid']>;
  maxBillingCycles?: Maybe<Scalars['Int']>;
  /** An array relationship */
  membershipCoupons: Array<MembershipCoupon>;
  /** An aggregate relationship */
  membershipCoupons_aggregate: MembershipCoupon_Aggregate;
  name: Scalars['String'];
  /** An array relationship */
  patientMemberships: Array<PatientMembership>;
  /** An aggregate relationship */
  patientMemberships_aggregate: PatientMembership_Aggregate;
  payrixPlanId?: Maybe<Scalars['String']>;
  price: Scalars['Int'];
  taxRate: Scalars['float8'];
  type: MembershipType_Enum;
  updatedAt: Scalars['timestamptz'];
  /** An object relationship */
  workspace: Workspace;
  workspaceId: Scalars['uuid'];
};


/** columns and relationships of "membership" */
export type MembershipMembershipCouponsArgs = {
  distinct_on?: InputMaybe<Array<MembershipCoupon_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MembershipCoupon_Order_By>>;
  where?: InputMaybe<MembershipCoupon_Bool_Exp>;
};


/** columns and relationships of "membership" */
export type MembershipMembershipCoupons_AggregateArgs = {
  distinct_on?: InputMaybe<Array<MembershipCoupon_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MembershipCoupon_Order_By>>;
  where?: InputMaybe<MembershipCoupon_Bool_Exp>;
};


/** columns and relationships of "membership" */
export type MembershipPatientMembershipsArgs = {
  distinct_on?: InputMaybe<Array<PatientMembership_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PatientMembership_Order_By>>;
  where?: InputMaybe<PatientMembership_Bool_Exp>;
};


/** columns and relationships of "membership" */
export type MembershipPatientMemberships_AggregateArgs = {
  distinct_on?: InputMaybe<Array<PatientMembership_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PatientMembership_Order_By>>;
  where?: InputMaybe<PatientMembership_Bool_Exp>;
};

/** columns and relationships of "membershipCoupon" */
export type MembershipCoupon = {
  __typename?: 'membershipCoupon';
  count: Scalars['Int'];
  /** An object relationship */
  coupon: Coupon;
  couponId: Scalars['uuid'];
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An object relationship */
  membership: Membership;
  membershipId: Scalars['uuid'];
  updatedAt: Scalars['timestamptz'];
};

/** aggregated selection of "membershipCoupon" */
export type MembershipCoupon_Aggregate = {
  __typename?: 'membershipCoupon_aggregate';
  aggregate?: Maybe<MembershipCoupon_Aggregate_Fields>;
  nodes: Array<MembershipCoupon>;
};

export type MembershipCoupon_Aggregate_Bool_Exp = {
  count?: InputMaybe<MembershipCoupon_Aggregate_Bool_Exp_Count>;
};

export type MembershipCoupon_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<MembershipCoupon_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<MembershipCoupon_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "membershipCoupon" */
export type MembershipCoupon_Aggregate_Fields = {
  __typename?: 'membershipCoupon_aggregate_fields';
  avg?: Maybe<MembershipCoupon_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<MembershipCoupon_Max_Fields>;
  min?: Maybe<MembershipCoupon_Min_Fields>;
  stddev?: Maybe<MembershipCoupon_Stddev_Fields>;
  stddev_pop?: Maybe<MembershipCoupon_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<MembershipCoupon_Stddev_Samp_Fields>;
  sum?: Maybe<MembershipCoupon_Sum_Fields>;
  var_pop?: Maybe<MembershipCoupon_Var_Pop_Fields>;
  var_samp?: Maybe<MembershipCoupon_Var_Samp_Fields>;
  variance?: Maybe<MembershipCoupon_Variance_Fields>;
};


/** aggregate fields of "membershipCoupon" */
export type MembershipCoupon_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<MembershipCoupon_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "membershipCoupon" */
export type MembershipCoupon_Aggregate_Order_By = {
  avg?: InputMaybe<MembershipCoupon_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<MembershipCoupon_Max_Order_By>;
  min?: InputMaybe<MembershipCoupon_Min_Order_By>;
  stddev?: InputMaybe<MembershipCoupon_Stddev_Order_By>;
  stddev_pop?: InputMaybe<MembershipCoupon_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<MembershipCoupon_Stddev_Samp_Order_By>;
  sum?: InputMaybe<MembershipCoupon_Sum_Order_By>;
  var_pop?: InputMaybe<MembershipCoupon_Var_Pop_Order_By>;
  var_samp?: InputMaybe<MembershipCoupon_Var_Samp_Order_By>;
  variance?: InputMaybe<MembershipCoupon_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "membershipCoupon" */
export type MembershipCoupon_Arr_Rel_Insert_Input = {
  data: Array<MembershipCoupon_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<MembershipCoupon_On_Conflict>;
};

/** aggregate avg on columns */
export type MembershipCoupon_Avg_Fields = {
  __typename?: 'membershipCoupon_avg_fields';
  count?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "membershipCoupon" */
export type MembershipCoupon_Avg_Order_By = {
  count?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "membershipCoupon". All fields are combined with a logical 'AND'. */
export type MembershipCoupon_Bool_Exp = {
  _and?: InputMaybe<Array<MembershipCoupon_Bool_Exp>>;
  _not?: InputMaybe<MembershipCoupon_Bool_Exp>;
  _or?: InputMaybe<Array<MembershipCoupon_Bool_Exp>>;
  count?: InputMaybe<Int_Comparison_Exp>;
  coupon?: InputMaybe<Coupon_Bool_Exp>;
  couponId?: InputMaybe<Uuid_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  membership?: InputMaybe<Membership_Bool_Exp>;
  membershipId?: InputMaybe<Uuid_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "membershipCoupon" */
export enum MembershipCoupon_Constraint {
  /** unique or primary key constraint on columns "membershipId", "couponId" */
  MembershipCouponMembershipIdCouponIdKey = 'membershipCoupon_membershipId_couponId_key',
  /** unique or primary key constraint on columns "id" */
  MembershipCouponPkey = 'membershipCoupon_pkey'
}

/** input type for incrementing numeric columns in table "membershipCoupon" */
export type MembershipCoupon_Inc_Input = {
  count?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "membershipCoupon" */
export type MembershipCoupon_Insert_Input = {
  count?: InputMaybe<Scalars['Int']>;
  coupon?: InputMaybe<Coupon_Obj_Rel_Insert_Input>;
  couponId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  membership?: InputMaybe<Membership_Obj_Rel_Insert_Input>;
  membershipId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type MembershipCoupon_Max_Fields = {
  __typename?: 'membershipCoupon_max_fields';
  count?: Maybe<Scalars['Int']>;
  couponId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  membershipId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "membershipCoupon" */
export type MembershipCoupon_Max_Order_By = {
  count?: InputMaybe<Order_By>;
  couponId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  membershipId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type MembershipCoupon_Min_Fields = {
  __typename?: 'membershipCoupon_min_fields';
  count?: Maybe<Scalars['Int']>;
  couponId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  membershipId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "membershipCoupon" */
export type MembershipCoupon_Min_Order_By = {
  count?: InputMaybe<Order_By>;
  couponId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  membershipId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "membershipCoupon" */
export type MembershipCoupon_Mutation_Response = {
  __typename?: 'membershipCoupon_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<MembershipCoupon>;
};

/** on_conflict condition type for table "membershipCoupon" */
export type MembershipCoupon_On_Conflict = {
  constraint: MembershipCoupon_Constraint;
  update_columns?: Array<MembershipCoupon_Update_Column>;
  where?: InputMaybe<MembershipCoupon_Bool_Exp>;
};

/** Ordering options when selecting data from "membershipCoupon". */
export type MembershipCoupon_Order_By = {
  count?: InputMaybe<Order_By>;
  coupon?: InputMaybe<Coupon_Order_By>;
  couponId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  membership?: InputMaybe<Membership_Order_By>;
  membershipId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: membershipCoupon */
export type MembershipCoupon_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "membershipCoupon" */
export enum MembershipCoupon_Select_Column {
  /** column name */
  Count = 'count',
  /** column name */
  CouponId = 'couponId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  MembershipId = 'membershipId',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "membershipCoupon" */
export type MembershipCoupon_Set_Input = {
  count?: InputMaybe<Scalars['Int']>;
  couponId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  membershipId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type MembershipCoupon_Stddev_Fields = {
  __typename?: 'membershipCoupon_stddev_fields';
  count?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "membershipCoupon" */
export type MembershipCoupon_Stddev_Order_By = {
  count?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type MembershipCoupon_Stddev_Pop_Fields = {
  __typename?: 'membershipCoupon_stddev_pop_fields';
  count?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "membershipCoupon" */
export type MembershipCoupon_Stddev_Pop_Order_By = {
  count?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type MembershipCoupon_Stddev_Samp_Fields = {
  __typename?: 'membershipCoupon_stddev_samp_fields';
  count?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "membershipCoupon" */
export type MembershipCoupon_Stddev_Samp_Order_By = {
  count?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "membershipCoupon" */
export type MembershipCoupon_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: MembershipCoupon_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type MembershipCoupon_Stream_Cursor_Value_Input = {
  count?: InputMaybe<Scalars['Int']>;
  couponId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  membershipId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type MembershipCoupon_Sum_Fields = {
  __typename?: 'membershipCoupon_sum_fields';
  count?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "membershipCoupon" */
export type MembershipCoupon_Sum_Order_By = {
  count?: InputMaybe<Order_By>;
};

/** update columns of table "membershipCoupon" */
export enum MembershipCoupon_Update_Column {
  /** column name */
  Count = 'count',
  /** column name */
  CouponId = 'couponId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  MembershipId = 'membershipId',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type MembershipCoupon_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<MembershipCoupon_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<MembershipCoupon_Set_Input>;
  /** filter the rows which have to be updated */
  where: MembershipCoupon_Bool_Exp;
};

/** aggregate var_pop on columns */
export type MembershipCoupon_Var_Pop_Fields = {
  __typename?: 'membershipCoupon_var_pop_fields';
  count?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "membershipCoupon" */
export type MembershipCoupon_Var_Pop_Order_By = {
  count?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type MembershipCoupon_Var_Samp_Fields = {
  __typename?: 'membershipCoupon_var_samp_fields';
  count?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "membershipCoupon" */
export type MembershipCoupon_Var_Samp_Order_By = {
  count?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type MembershipCoupon_Variance_Fields = {
  __typename?: 'membershipCoupon_variance_fields';
  count?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "membershipCoupon" */
export type MembershipCoupon_Variance_Order_By = {
  count?: InputMaybe<Order_By>;
};

/** columns and relationships of "membershipType" */
export type MembershipType = {
  __typename?: 'membershipType';
  description: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "membershipType" */
export type MembershipType_Aggregate = {
  __typename?: 'membershipType_aggregate';
  aggregate?: Maybe<MembershipType_Aggregate_Fields>;
  nodes: Array<MembershipType>;
};

/** aggregate fields of "membershipType" */
export type MembershipType_Aggregate_Fields = {
  __typename?: 'membershipType_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<MembershipType_Max_Fields>;
  min?: Maybe<MembershipType_Min_Fields>;
};


/** aggregate fields of "membershipType" */
export type MembershipType_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<MembershipType_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "membershipType". All fields are combined with a logical 'AND'. */
export type MembershipType_Bool_Exp = {
  _and?: InputMaybe<Array<MembershipType_Bool_Exp>>;
  _not?: InputMaybe<MembershipType_Bool_Exp>;
  _or?: InputMaybe<Array<MembershipType_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "membershipType" */
export enum MembershipType_Constraint {
  /** unique or primary key constraint on columns "value" */
  MembershipTypePkey = 'membershipType_pkey'
}

export enum MembershipType_Enum {
  /** BSA */
  Bsa = 'BSA',
  /** Subscription */
  Subscription = 'SUBSCRIPTION'
}

/** Boolean expression to compare columns of type "membershipType_enum". All fields are combined with logical 'AND'. */
export type MembershipType_Enum_Comparison_Exp = {
  _eq?: InputMaybe<MembershipType_Enum>;
  _in?: InputMaybe<Array<MembershipType_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<MembershipType_Enum>;
  _nin?: InputMaybe<Array<MembershipType_Enum>>;
};

/** input type for inserting data into table "membershipType" */
export type MembershipType_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type MembershipType_Max_Fields = {
  __typename?: 'membershipType_max_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type MembershipType_Min_Fields = {
  __typename?: 'membershipType_min_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "membershipType" */
export type MembershipType_Mutation_Response = {
  __typename?: 'membershipType_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<MembershipType>;
};

/** on_conflict condition type for table "membershipType" */
export type MembershipType_On_Conflict = {
  constraint: MembershipType_Constraint;
  update_columns?: Array<MembershipType_Update_Column>;
  where?: InputMaybe<MembershipType_Bool_Exp>;
};

/** Ordering options when selecting data from "membershipType". */
export type MembershipType_Order_By = {
  description?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: membershipType */
export type MembershipType_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "membershipType" */
export enum MembershipType_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "membershipType" */
export type MembershipType_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "membershipType" */
export type MembershipType_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: MembershipType_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type MembershipType_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "membershipType" */
export enum MembershipType_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

export type MembershipType_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<MembershipType_Set_Input>;
  /** filter the rows which have to be updated */
  where: MembershipType_Bool_Exp;
};

/** aggregated selection of "membership" */
export type Membership_Aggregate = {
  __typename?: 'membership_aggregate';
  aggregate?: Maybe<Membership_Aggregate_Fields>;
  nodes: Array<Membership>;
};

/** aggregate fields of "membership" */
export type Membership_Aggregate_Fields = {
  __typename?: 'membership_aggregate_fields';
  avg?: Maybe<Membership_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Membership_Max_Fields>;
  min?: Maybe<Membership_Min_Fields>;
  stddev?: Maybe<Membership_Stddev_Fields>;
  stddev_pop?: Maybe<Membership_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Membership_Stddev_Samp_Fields>;
  sum?: Maybe<Membership_Sum_Fields>;
  var_pop?: Maybe<Membership_Var_Pop_Fields>;
  var_samp?: Maybe<Membership_Var_Samp_Fields>;
  variance?: Maybe<Membership_Variance_Fields>;
};


/** aggregate fields of "membership" */
export type Membership_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Membership_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Membership_Avg_Fields = {
  __typename?: 'membership_avg_fields';
  billingDayOfMonth?: Maybe<Scalars['Float']>;
  billingPeriodInDays?: Maybe<Scalars['Float']>;
  credits?: Maybe<Scalars['Float']>;
  discount?: Maybe<Scalars['Float']>;
  maxBillingCycles?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  taxRate?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "membership". All fields are combined with a logical 'AND'. */
export type Membership_Bool_Exp = {
  _and?: InputMaybe<Array<Membership_Bool_Exp>>;
  _not?: InputMaybe<Membership_Bool_Exp>;
  _or?: InputMaybe<Array<Membership_Bool_Exp>>;
  archived?: InputMaybe<Boolean_Comparison_Exp>;
  availableInStore?: InputMaybe<Boolean_Comparison_Exp>;
  billingDayOfMonth?: InputMaybe<Int_Comparison_Exp>;
  billingPeriodInDays?: InputMaybe<Int_Comparison_Exp>;
  color?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  credits?: InputMaybe<Int_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  discount?: InputMaybe<Int_Comparison_Exp>;
  hasCoupons?: InputMaybe<Boolean_Comparison_Exp>;
  hasCredits?: InputMaybe<Boolean_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  image?: InputMaybe<String_Comparison_Exp>;
  location?: InputMaybe<Location_Bool_Exp>;
  locationId?: InputMaybe<Uuid_Comparison_Exp>;
  maxBillingCycles?: InputMaybe<Int_Comparison_Exp>;
  membershipCoupons?: InputMaybe<MembershipCoupon_Bool_Exp>;
  membershipCoupons_aggregate?: InputMaybe<MembershipCoupon_Aggregate_Bool_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  patientMemberships?: InputMaybe<PatientMembership_Bool_Exp>;
  patientMemberships_aggregate?: InputMaybe<PatientMembership_Aggregate_Bool_Exp>;
  payrixPlanId?: InputMaybe<String_Comparison_Exp>;
  price?: InputMaybe<Int_Comparison_Exp>;
  taxRate?: InputMaybe<Float8_Comparison_Exp>;
  type?: InputMaybe<MembershipType_Enum_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  workspace?: InputMaybe<Workspace_Bool_Exp>;
  workspaceId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "membership" */
export enum Membership_Constraint {
  /** unique or primary key constraint on columns "id" */
  MembershipPkey = 'membership_pkey',
  /** unique or primary key constraint on columns "workspaceId", "name" */
  MembershipWorkspaceIdNameKey = 'membership_workspaceId_name_key'
}

/** input type for incrementing numeric columns in table "membership" */
export type Membership_Inc_Input = {
  billingDayOfMonth?: InputMaybe<Scalars['Int']>;
  billingPeriodInDays?: InputMaybe<Scalars['Int']>;
  credits?: InputMaybe<Scalars['Int']>;
  discount?: InputMaybe<Scalars['Int']>;
  maxBillingCycles?: InputMaybe<Scalars['Int']>;
  price?: InputMaybe<Scalars['Int']>;
  taxRate?: InputMaybe<Scalars['float8']>;
};

/** input type for inserting data into table "membership" */
export type Membership_Insert_Input = {
  archived?: InputMaybe<Scalars['Boolean']>;
  availableInStore?: InputMaybe<Scalars['Boolean']>;
  billingDayOfMonth?: InputMaybe<Scalars['Int']>;
  billingPeriodInDays?: InputMaybe<Scalars['Int']>;
  color?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  credits?: InputMaybe<Scalars['Int']>;
  description?: InputMaybe<Scalars['String']>;
  discount?: InputMaybe<Scalars['Int']>;
  hasCoupons?: InputMaybe<Scalars['Boolean']>;
  hasCredits?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['uuid']>;
  image?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Location_Obj_Rel_Insert_Input>;
  locationId?: InputMaybe<Scalars['uuid']>;
  maxBillingCycles?: InputMaybe<Scalars['Int']>;
  membershipCoupons?: InputMaybe<MembershipCoupon_Arr_Rel_Insert_Input>;
  name?: InputMaybe<Scalars['String']>;
  patientMemberships?: InputMaybe<PatientMembership_Arr_Rel_Insert_Input>;
  payrixPlanId?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['Int']>;
  taxRate?: InputMaybe<Scalars['float8']>;
  type?: InputMaybe<MembershipType_Enum>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspace?: InputMaybe<Workspace_Obj_Rel_Insert_Input>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Membership_Max_Fields = {
  __typename?: 'membership_max_fields';
  billingDayOfMonth?: Maybe<Scalars['Int']>;
  billingPeriodInDays?: Maybe<Scalars['Int']>;
  color?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  credits?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  discount?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  image?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['uuid']>;
  maxBillingCycles?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  payrixPlanId?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Int']>;
  taxRate?: Maybe<Scalars['float8']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Membership_Min_Fields = {
  __typename?: 'membership_min_fields';
  billingDayOfMonth?: Maybe<Scalars['Int']>;
  billingPeriodInDays?: Maybe<Scalars['Int']>;
  color?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  credits?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  discount?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  image?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['uuid']>;
  maxBillingCycles?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  payrixPlanId?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Int']>;
  taxRate?: Maybe<Scalars['float8']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "membership" */
export type Membership_Mutation_Response = {
  __typename?: 'membership_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Membership>;
};

/** input type for inserting object relation for remote table "membership" */
export type Membership_Obj_Rel_Insert_Input = {
  data: Membership_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Membership_On_Conflict>;
};

/** on_conflict condition type for table "membership" */
export type Membership_On_Conflict = {
  constraint: Membership_Constraint;
  update_columns?: Array<Membership_Update_Column>;
  where?: InputMaybe<Membership_Bool_Exp>;
};

/** Ordering options when selecting data from "membership". */
export type Membership_Order_By = {
  archived?: InputMaybe<Order_By>;
  availableInStore?: InputMaybe<Order_By>;
  billingDayOfMonth?: InputMaybe<Order_By>;
  billingPeriodInDays?: InputMaybe<Order_By>;
  color?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  credits?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  discount?: InputMaybe<Order_By>;
  hasCoupons?: InputMaybe<Order_By>;
  hasCredits?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  image?: InputMaybe<Order_By>;
  location?: InputMaybe<Location_Order_By>;
  locationId?: InputMaybe<Order_By>;
  maxBillingCycles?: InputMaybe<Order_By>;
  membershipCoupons_aggregate?: InputMaybe<MembershipCoupon_Aggregate_Order_By>;
  name?: InputMaybe<Order_By>;
  patientMemberships_aggregate?: InputMaybe<PatientMembership_Aggregate_Order_By>;
  payrixPlanId?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  taxRate?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  workspace?: InputMaybe<Workspace_Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: membership */
export type Membership_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "membership" */
export enum Membership_Select_Column {
  /** column name */
  Archived = 'archived',
  /** column name */
  AvailableInStore = 'availableInStore',
  /** column name */
  BillingDayOfMonth = 'billingDayOfMonth',
  /** column name */
  BillingPeriodInDays = 'billingPeriodInDays',
  /** column name */
  Color = 'color',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Credits = 'credits',
  /** column name */
  Description = 'description',
  /** column name */
  Discount = 'discount',
  /** column name */
  HasCoupons = 'hasCoupons',
  /** column name */
  HasCredits = 'hasCredits',
  /** column name */
  Id = 'id',
  /** column name */
  Image = 'image',
  /** column name */
  LocationId = 'locationId',
  /** column name */
  MaxBillingCycles = 'maxBillingCycles',
  /** column name */
  Name = 'name',
  /** column name */
  PayrixPlanId = 'payrixPlanId',
  /** column name */
  Price = 'price',
  /** column name */
  TaxRate = 'taxRate',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WorkspaceId = 'workspaceId'
}

/** input type for updating data in table "membership" */
export type Membership_Set_Input = {
  archived?: InputMaybe<Scalars['Boolean']>;
  availableInStore?: InputMaybe<Scalars['Boolean']>;
  billingDayOfMonth?: InputMaybe<Scalars['Int']>;
  billingPeriodInDays?: InputMaybe<Scalars['Int']>;
  color?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  credits?: InputMaybe<Scalars['Int']>;
  description?: InputMaybe<Scalars['String']>;
  discount?: InputMaybe<Scalars['Int']>;
  hasCoupons?: InputMaybe<Scalars['Boolean']>;
  hasCredits?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['uuid']>;
  image?: InputMaybe<Scalars['String']>;
  locationId?: InputMaybe<Scalars['uuid']>;
  maxBillingCycles?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  payrixPlanId?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['Int']>;
  taxRate?: InputMaybe<Scalars['float8']>;
  type?: InputMaybe<MembershipType_Enum>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Membership_Stddev_Fields = {
  __typename?: 'membership_stddev_fields';
  billingDayOfMonth?: Maybe<Scalars['Float']>;
  billingPeriodInDays?: Maybe<Scalars['Float']>;
  credits?: Maybe<Scalars['Float']>;
  discount?: Maybe<Scalars['Float']>;
  maxBillingCycles?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  taxRate?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Membership_Stddev_Pop_Fields = {
  __typename?: 'membership_stddev_pop_fields';
  billingDayOfMonth?: Maybe<Scalars['Float']>;
  billingPeriodInDays?: Maybe<Scalars['Float']>;
  credits?: Maybe<Scalars['Float']>;
  discount?: Maybe<Scalars['Float']>;
  maxBillingCycles?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  taxRate?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Membership_Stddev_Samp_Fields = {
  __typename?: 'membership_stddev_samp_fields';
  billingDayOfMonth?: Maybe<Scalars['Float']>;
  billingPeriodInDays?: Maybe<Scalars['Float']>;
  credits?: Maybe<Scalars['Float']>;
  discount?: Maybe<Scalars['Float']>;
  maxBillingCycles?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  taxRate?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "membership" */
export type Membership_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Membership_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Membership_Stream_Cursor_Value_Input = {
  archived?: InputMaybe<Scalars['Boolean']>;
  availableInStore?: InputMaybe<Scalars['Boolean']>;
  billingDayOfMonth?: InputMaybe<Scalars['Int']>;
  billingPeriodInDays?: InputMaybe<Scalars['Int']>;
  color?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  credits?: InputMaybe<Scalars['Int']>;
  description?: InputMaybe<Scalars['String']>;
  discount?: InputMaybe<Scalars['Int']>;
  hasCoupons?: InputMaybe<Scalars['Boolean']>;
  hasCredits?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['uuid']>;
  image?: InputMaybe<Scalars['String']>;
  locationId?: InputMaybe<Scalars['uuid']>;
  maxBillingCycles?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  payrixPlanId?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['Int']>;
  taxRate?: InputMaybe<Scalars['float8']>;
  type?: InputMaybe<MembershipType_Enum>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Membership_Sum_Fields = {
  __typename?: 'membership_sum_fields';
  billingDayOfMonth?: Maybe<Scalars['Int']>;
  billingPeriodInDays?: Maybe<Scalars['Int']>;
  credits?: Maybe<Scalars['Int']>;
  discount?: Maybe<Scalars['Int']>;
  maxBillingCycles?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['Int']>;
  taxRate?: Maybe<Scalars['float8']>;
};

/** update columns of table "membership" */
export enum Membership_Update_Column {
  /** column name */
  Archived = 'archived',
  /** column name */
  AvailableInStore = 'availableInStore',
  /** column name */
  BillingDayOfMonth = 'billingDayOfMonth',
  /** column name */
  BillingPeriodInDays = 'billingPeriodInDays',
  /** column name */
  Color = 'color',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Credits = 'credits',
  /** column name */
  Description = 'description',
  /** column name */
  Discount = 'discount',
  /** column name */
  HasCoupons = 'hasCoupons',
  /** column name */
  HasCredits = 'hasCredits',
  /** column name */
  Id = 'id',
  /** column name */
  Image = 'image',
  /** column name */
  LocationId = 'locationId',
  /** column name */
  MaxBillingCycles = 'maxBillingCycles',
  /** column name */
  Name = 'name',
  /** column name */
  PayrixPlanId = 'payrixPlanId',
  /** column name */
  Price = 'price',
  /** column name */
  TaxRate = 'taxRate',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WorkspaceId = 'workspaceId'
}

export type Membership_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Membership_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Membership_Set_Input>;
  /** filter the rows which have to be updated */
  where: Membership_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Membership_Var_Pop_Fields = {
  __typename?: 'membership_var_pop_fields';
  billingDayOfMonth?: Maybe<Scalars['Float']>;
  billingPeriodInDays?: Maybe<Scalars['Float']>;
  credits?: Maybe<Scalars['Float']>;
  discount?: Maybe<Scalars['Float']>;
  maxBillingCycles?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  taxRate?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Membership_Var_Samp_Fields = {
  __typename?: 'membership_var_samp_fields';
  billingDayOfMonth?: Maybe<Scalars['Float']>;
  billingPeriodInDays?: Maybe<Scalars['Float']>;
  credits?: Maybe<Scalars['Float']>;
  discount?: Maybe<Scalars['Float']>;
  maxBillingCycles?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  taxRate?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Membership_Variance_Fields = {
  __typename?: 'membership_variance_fields';
  billingDayOfMonth?: Maybe<Scalars['Float']>;
  billingPeriodInDays?: Maybe<Scalars['Float']>;
  credits?: Maybe<Scalars['Float']>;
  discount?: Maybe<Scalars['Float']>;
  maxBillingCycles?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  taxRate?: Maybe<Scalars['Float']>;
};

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root';
  activateFinixDevice?: Maybe<SuccessAnswer>;
  applyBackwardsLineItems: SuccessAnswer;
  applyCreditsApplied: SuccessAnswer;
  archiveInvoice?: Maybe<SuccessAnswer>;
  /** batchEmailTemplate */
  batchEmailTemplate?: Maybe<SuccessAnswer>;
  /** Inserting appointment for external facing online booking */
  bookExternalAppointment?: Maybe<BookExternalAppointmentOutput>;
  bookPatientAppointment: BookPatientAppointmentOutput;
  bulkDosespotPatientImport: SuccessAnswer;
  bulkScheduleAppointmentReminders?: Maybe<BulkScheduleRemindersOutput>;
  cancelAppointment: CancelAppointmentOutput;
  cancelDraftInvoice: SuccessAnswer;
  cancelInvoice: CancelInvoiceOutput;
  cancelSubscription: SuccessAnswer;
  clinicalMessage?: Maybe<SuccessAnswer>;
  /** clinicalMessageUnscheduled */
  clinicalMessageUnscheduled?: Maybe<SuccessAnswer>;
  clinicalTestMessage?: Maybe<SuccessAnswer>;
  collectAppointmentNoShowFee?: Maybe<SuccessAnswer>;
  complexUpdateInvoice: ComplexUpdateInvoiceOutput;
  convertDocumentToPDF?: Maybe<SuccessAnswer>;
  convertFileToSurveyJSConsent?: Maybe<SuccessAnswer>;
  createBeforeAndAfterImage?: Maybe<BeforeAndAfterOutput>;
  createConsumableAndAddToLocation: CreateConsumableAndAddToLocationOutput;
  createCustomCloudflareHostname?: Maybe<SuccessAnswer>;
  createDraftInvoiceForAppointment?: Maybe<IdResponse>;
  createFinixDevice?: Maybe<CreateFinixDeviceOutput>;
  createFinixPaymentInstrument?: Maybe<SuccessAnswer>;
  createFirebaseUser?: Maybe<CreateFirebaseUserOutput>;
  createInventoryTransaction: CreateInventoryTransactionOutput;
  createPayrixWebAlerts: SuccessAnswerWithCount;
  createTelehealthMeeting?: Maybe<TelehealthMeetingOutput>;
  customInsertConsumable: Scalars['jsonb'];
  customInsertInvoice: CustomInsertInvoiceOutput;
  customUpdateConsumable: Scalars['jsonb'];
  customUpdateInvoice: CustomUpdateInvoiceOutput;
  deauthorize?: Maybe<SuccessAnswer>;
  /** deleteFirebaseUser */
  deleteFirebaseUser?: Maybe<SuccessAnswer>;
  deleteGoogleProfile: SuccessAnswer;
  /** delete data from the table: "activityLog" */
  delete_activityLog?: Maybe<ActivityLog_Mutation_Response>;
  /** delete single row from the table: "activityLog" */
  delete_activityLog_by_pk?: Maybe<ActivityLog>;
  /** delete data from the table: "address" */
  delete_address?: Maybe<Address_Mutation_Response>;
  /** delete single row from the table: "address" */
  delete_address_by_pk?: Maybe<Address>;
  /** delete data from the table: "appointment" */
  delete_appointment?: Maybe<Appointment_Mutation_Response>;
  /** delete data from the table: "appointmentConsent" */
  delete_appointmentConsent?: Maybe<AppointmentConsent_Mutation_Response>;
  /** delete single row from the table: "appointmentConsent" */
  delete_appointmentConsent_by_pk?: Maybe<AppointmentConsent>;
  /** delete data from the table: "appointmentCustomForm" */
  delete_appointmentCustomForm?: Maybe<AppointmentCustomForm_Mutation_Response>;
  /** delete single row from the table: "appointmentCustomForm" */
  delete_appointmentCustomForm_by_pk?: Maybe<AppointmentCustomForm>;
  /** delete data from the table: "appointmentService" */
  delete_appointmentService?: Maybe<AppointmentService_Mutation_Response>;
  /** delete data from the table: "appointmentServiceConsumable" */
  delete_appointmentServiceConsumable?: Maybe<AppointmentServiceConsumable_Mutation_Response>;
  /** delete single row from the table: "appointmentServiceConsumable" */
  delete_appointmentServiceConsumable_by_pk?: Maybe<AppointmentServiceConsumable>;
  /** delete single row from the table: "appointmentService" */
  delete_appointmentService_by_pk?: Maybe<AppointmentService>;
  /** delete data from the table: "appointmentType" */
  delete_appointmentType?: Maybe<AppointmentType_Mutation_Response>;
  /** delete single row from the table: "appointmentType" */
  delete_appointmentType_by_pk?: Maybe<AppointmentType>;
  /** delete single row from the table: "appointment" */
  delete_appointment_by_pk?: Maybe<Appointment>;
  /** delete data from the table: "audience" */
  delete_audience?: Maybe<Audience_Mutation_Response>;
  /** delete data from the table: "audienceEmail" */
  delete_audienceEmail?: Maybe<AudienceEmail_Mutation_Response>;
  /** delete single row from the table: "audienceEmail" */
  delete_audienceEmail_by_pk?: Maybe<AudienceEmail>;
  /** delete single row from the table: "audience" */
  delete_audience_by_pk?: Maybe<Audience>;
  /** delete data from the table: "authProfile" */
  delete_authProfile?: Maybe<AuthProfile_Mutation_Response>;
  /** delete single row from the table: "authProfile" */
  delete_authProfile_by_pk?: Maybe<AuthProfile>;
  /** delete data from the table: "clinicalMessage" */
  delete_clinicalMessage?: Maybe<ClinicalMessage_Mutation_Response>;
  /** delete data from the table: "clinicalMessageStatus" */
  delete_clinicalMessageStatus?: Maybe<ClinicalMessageStatus_Mutation_Response>;
  /** delete single row from the table: "clinicalMessageStatus" */
  delete_clinicalMessageStatus_by_pk?: Maybe<ClinicalMessageStatus>;
  /** delete single row from the table: "clinicalMessage" */
  delete_clinicalMessage_by_pk?: Maybe<ClinicalMessage>;
  /** delete data from the table: "compensationPolicy" */
  delete_compensationPolicy?: Maybe<CompensationPolicy_Mutation_Response>;
  /** delete data from the table: "compensationPolicyCommission" */
  delete_compensationPolicyCommission?: Maybe<CompensationPolicyCommission_Mutation_Response>;
  /** delete single row from the table: "compensationPolicyCommission" */
  delete_compensationPolicyCommission_by_pk?: Maybe<CompensationPolicyCommission>;
  /** delete data from the table: "compensationPolicyTier" */
  delete_compensationPolicyTier?: Maybe<CompensationPolicyTier_Mutation_Response>;
  /** delete data from the table: "compensationPolicyTierCategory" */
  delete_compensationPolicyTierCategory?: Maybe<CompensationPolicyTierCategory_Mutation_Response>;
  /** delete single row from the table: "compensationPolicyTierCategory" */
  delete_compensationPolicyTierCategory_by_pk?: Maybe<CompensationPolicyTierCategory>;
  /** delete data from the table: "compensationPolicyTierExemption" */
  delete_compensationPolicyTierExemption?: Maybe<CompensationPolicyTierExemption_Mutation_Response>;
  /** delete single row from the table: "compensationPolicyTierExemption" */
  delete_compensationPolicyTierExemption_by_pk?: Maybe<CompensationPolicyTierExemption>;
  /** delete data from the table: "compensationPolicyTierInclusion" */
  delete_compensationPolicyTierInclusion?: Maybe<CompensationPolicyTierInclusion_Mutation_Response>;
  /** delete single row from the table: "compensationPolicyTierInclusion" */
  delete_compensationPolicyTierInclusion_by_pk?: Maybe<CompensationPolicyTierInclusion>;
  /** delete single row from the table: "compensationPolicyTier" */
  delete_compensationPolicyTier_by_pk?: Maybe<CompensationPolicyTier>;
  /** delete single row from the table: "compensationPolicy" */
  delete_compensationPolicy_by_pk?: Maybe<CompensationPolicy>;
  /** delete data from the table: "compensationStrategy" */
  delete_compensationStrategy?: Maybe<CompensationStrategy_Mutation_Response>;
  /** delete data from the table: "compensationStrategyCommission" */
  delete_compensationStrategyCommission?: Maybe<CompensationStrategyCommission_Mutation_Response>;
  /** delete single row from the table: "compensationStrategyCommission" */
  delete_compensationStrategyCommission_by_pk?: Maybe<CompensationStrategyCommission>;
  /** delete data from the table: "compensationStrategyTier" */
  delete_compensationStrategyTier?: Maybe<CompensationStrategyTier_Mutation_Response>;
  /** delete data from the table: "compensationStrategyTierCategory" */
  delete_compensationStrategyTierCategory?: Maybe<CompensationStrategyTierCategory_Mutation_Response>;
  /** delete single row from the table: "compensationStrategyTierCategory" */
  delete_compensationStrategyTierCategory_by_pk?: Maybe<CompensationStrategyTierCategory>;
  /** delete data from the table: "compensationStrategyTierExemption" */
  delete_compensationStrategyTierExemption?: Maybe<CompensationStrategyTierExemption_Mutation_Response>;
  /** delete single row from the table: "compensationStrategyTierExemption" */
  delete_compensationStrategyTierExemption_by_pk?: Maybe<CompensationStrategyTierExemption>;
  /** delete data from the table: "compensationStrategyTierInclusion" */
  delete_compensationStrategyTierInclusion?: Maybe<CompensationStrategyTierInclusion_Mutation_Response>;
  /** delete single row from the table: "compensationStrategyTierInclusion" */
  delete_compensationStrategyTierInclusion_by_pk?: Maybe<CompensationStrategyTierInclusion>;
  /** delete single row from the table: "compensationStrategyTier" */
  delete_compensationStrategyTier_by_pk?: Maybe<CompensationStrategyTier>;
  /** delete single row from the table: "compensationStrategy" */
  delete_compensationStrategy_by_pk?: Maybe<CompensationStrategy>;
  /** delete data from the table: "consent" */
  delete_consent?: Maybe<Consent_Mutation_Response>;
  /** delete single row from the table: "consent" */
  delete_consent_by_pk?: Maybe<Consent>;
  /** delete data from the table: "consumable" */
  delete_consumable?: Maybe<Consumable_Mutation_Response>;
  /** delete data from the table: "consumableBrand" */
  delete_consumableBrand?: Maybe<ConsumableBrand_Mutation_Response>;
  /** delete single row from the table: "consumableBrand" */
  delete_consumableBrand_by_pk?: Maybe<ConsumableBrand>;
  /** delete data from the table: "consumableSubtype" */
  delete_consumableSubtype?: Maybe<ConsumableSubtype_Mutation_Response>;
  /** delete single row from the table: "consumableSubtype" */
  delete_consumableSubtype_by_pk?: Maybe<ConsumableSubtype>;
  /** delete data from the table: "consumableType" */
  delete_consumableType?: Maybe<ConsumableType_Mutation_Response>;
  /** delete data from the table: "consumableTypeConsumableSubtype" */
  delete_consumableTypeConsumableSubtype?: Maybe<ConsumableTypeConsumableSubtype_Mutation_Response>;
  /** delete single row from the table: "consumableTypeConsumableSubtype" */
  delete_consumableTypeConsumableSubtype_by_pk?: Maybe<ConsumableTypeConsumableSubtype>;
  /** delete single row from the table: "consumableType" */
  delete_consumableType_by_pk?: Maybe<ConsumableType>;
  /** delete single row from the table: "consumable" */
  delete_consumable_by_pk?: Maybe<Consumable>;
  /** delete data from the table: "coupon" */
  delete_coupon?: Maybe<Coupon_Mutation_Response>;
  /** delete data from the table: "couponConfiguration" */
  delete_couponConfiguration?: Maybe<CouponConfiguration_Mutation_Response>;
  /** delete single row from the table: "couponConfiguration" */
  delete_couponConfiguration_by_pk?: Maybe<CouponConfiguration>;
  /** delete data from the table: "couponScope" */
  delete_couponScope?: Maybe<CouponScope_Mutation_Response>;
  /** delete single row from the table: "couponScope" */
  delete_couponScope_by_pk?: Maybe<CouponScope>;
  /** delete data from the table: "couponType" */
  delete_couponType?: Maybe<CouponType_Mutation_Response>;
  /** delete single row from the table: "couponType" */
  delete_couponType_by_pk?: Maybe<CouponType>;
  /** delete single row from the table: "coupon" */
  delete_coupon_by_pk?: Maybe<Coupon>;
  /** delete data from the table: "customForm" */
  delete_customForm?: Maybe<CustomForm_Mutation_Response>;
  /** delete data from the table: "customFormField" */
  delete_customFormField?: Maybe<CustomFormField_Mutation_Response>;
  /** delete data from the table: "customFormFieldCategory" */
  delete_customFormFieldCategory?: Maybe<CustomFormFieldCategory_Mutation_Response>;
  /** delete single row from the table: "customFormFieldCategory" */
  delete_customFormFieldCategory_by_pk?: Maybe<CustomFormFieldCategory>;
  /** delete data from the table: "customFormFieldResponse" */
  delete_customFormFieldResponse?: Maybe<CustomFormFieldResponse_Mutation_Response>;
  /** delete single row from the table: "customFormFieldResponse" */
  delete_customFormFieldResponse_by_pk?: Maybe<CustomFormFieldResponse>;
  /** delete data from the table: "customFormFieldType" */
  delete_customFormFieldType?: Maybe<CustomFormFieldType_Mutation_Response>;
  /** delete single row from the table: "customFormFieldType" */
  delete_customFormFieldType_by_pk?: Maybe<CustomFormFieldType>;
  /** delete single row from the table: "customFormField" */
  delete_customFormField_by_pk?: Maybe<CustomFormField>;
  /** delete data from the table: "customFormOption" */
  delete_customFormOption?: Maybe<CustomFormOption_Mutation_Response>;
  /** delete single row from the table: "customFormOption" */
  delete_customFormOption_by_pk?: Maybe<CustomFormOption>;
  /** delete data from the table: "customFormReplicatedField" */
  delete_customFormReplicatedField?: Maybe<CustomFormReplicatedField_Mutation_Response>;
  /** delete single row from the table: "customFormReplicatedField" */
  delete_customFormReplicatedField_by_pk?: Maybe<CustomFormReplicatedField>;
  /** delete data from the table: "customFormReplicatedRow" */
  delete_customFormReplicatedRow?: Maybe<CustomFormReplicatedRow_Mutation_Response>;
  /** delete single row from the table: "customFormReplicatedRow" */
  delete_customFormReplicatedRow_by_pk?: Maybe<CustomFormReplicatedRow>;
  /** delete data from the table: "customFormResponse" */
  delete_customFormResponse?: Maybe<CustomFormResponse_Mutation_Response>;
  /** delete single row from the table: "customFormResponse" */
  delete_customFormResponse_by_pk?: Maybe<CustomFormResponse>;
  /** delete data from the table: "customFormRow" */
  delete_customFormRow?: Maybe<CustomFormRow_Mutation_Response>;
  /** delete single row from the table: "customFormRow" */
  delete_customFormRow_by_pk?: Maybe<CustomFormRow>;
  /** delete single row from the table: "customForm" */
  delete_customForm_by_pk?: Maybe<CustomForm>;
  /** delete data from the table: "customQuestion" */
  delete_customQuestion?: Maybe<CustomQuestion_Mutation_Response>;
  /** delete data from the table: "customQuestionOption" */
  delete_customQuestionOption?: Maybe<CustomQuestionOption_Mutation_Response>;
  /** delete single row from the table: "customQuestionOption" */
  delete_customQuestionOption_by_pk?: Maybe<CustomQuestionOption>;
  /** delete single row from the table: "customQuestion" */
  delete_customQuestion_by_pk?: Maybe<CustomQuestion>;
  /** delete data from the table: "degreeDesignation" */
  delete_degreeDesignation?: Maybe<DegreeDesignation_Mutation_Response>;
  /** delete single row from the table: "degreeDesignation" */
  delete_degreeDesignation_by_pk?: Maybe<DegreeDesignation>;
  /** delete data from the table: "depositInvoiceLineItem" */
  delete_depositInvoiceLineItem?: Maybe<DepositInvoiceLineItem_Mutation_Response>;
  /** delete single row from the table: "depositInvoiceLineItem" */
  delete_depositInvoiceLineItem_by_pk?: Maybe<DepositInvoiceLineItem>;
  /** delete data from the table: "device" */
  delete_device?: Maybe<Device_Mutation_Response>;
  /** delete data from the table: "deviceType" */
  delete_deviceType?: Maybe<DeviceType_Mutation_Response>;
  /** delete single row from the table: "deviceType" */
  delete_deviceType_by_pk?: Maybe<DeviceType>;
  /** delete single row from the table: "device" */
  delete_device_by_pk?: Maybe<Device>;
  /** delete data from the table: "discountInvoiceLineItem" */
  delete_discountInvoiceLineItem?: Maybe<DiscountInvoiceLineItem_Mutation_Response>;
  /** delete single row from the table: "discountInvoiceLineItem" */
  delete_discountInvoiceLineItem_by_pk?: Maybe<DiscountInvoiceLineItem>;
  /** delete data from the table: "document" */
  delete_document?: Maybe<Document_Mutation_Response>;
  /** delete data from the table: "documentTag" */
  delete_documentTag?: Maybe<DocumentTag_Mutation_Response>;
  /** delete single row from the table: "documentTag" */
  delete_documentTag_by_pk?: Maybe<DocumentTag>;
  /** delete single row from the table: "document" */
  delete_document_by_pk?: Maybe<Document>;
  /** delete data from the table: "emailCampaignLog" */
  delete_emailCampaignLog?: Maybe<EmailCampaignLog_Mutation_Response>;
  /** delete single row from the table: "emailCampaignLog" */
  delete_emailCampaignLog_by_pk?: Maybe<EmailCampaignLog>;
  /** delete data from the table: "emailTemplate" */
  delete_emailTemplate?: Maybe<EmailTemplate_Mutation_Response>;
  /** delete data from the table: "emailTemplatePatient" */
  delete_emailTemplatePatient?: Maybe<EmailTemplatePatient_Mutation_Response>;
  /** delete single row from the table: "emailTemplatePatient" */
  delete_emailTemplatePatient_by_pk?: Maybe<EmailTemplatePatient>;
  /** delete data from the table: "emailTemplateSendQueue" */
  delete_emailTemplateSendQueue?: Maybe<EmailTemplateSendQueue_Mutation_Response>;
  /** delete single row from the table: "emailTemplateSendQueue" */
  delete_emailTemplateSendQueue_by_pk?: Maybe<EmailTemplateSendQueue>;
  /** delete single row from the table: "emailTemplate" */
  delete_emailTemplate_by_pk?: Maybe<EmailTemplate>;
  /** delete data from the table: "externalLocation" */
  delete_externalLocation?: Maybe<ExternalLocation_Mutation_Response>;
  /** delete single row from the table: "externalLocation" */
  delete_externalLocation_by_pk?: Maybe<ExternalLocation>;
  /** delete data from the table: "externalProvider" */
  delete_externalProvider?: Maybe<ExternalProvider_Mutation_Response>;
  /** delete single row from the table: "externalProvider" */
  delete_externalProvider_by_pk?: Maybe<ExternalProvider>;
  /** delete data from the table: "externalReview" */
  delete_externalReview?: Maybe<ExternalReview_Mutation_Response>;
  /** delete single row from the table: "externalReview" */
  delete_externalReview_by_pk?: Maybe<ExternalReview>;
  /** delete data from the table: "fee" */
  delete_fee?: Maybe<Fee_Mutation_Response>;
  /** delete data from the table: "feeInvoiceLineItem" */
  delete_feeInvoiceLineItem?: Maybe<FeeInvoiceLineItem_Mutation_Response>;
  /** delete single row from the table: "feeInvoiceLineItem" */
  delete_feeInvoiceLineItem_by_pk?: Maybe<FeeInvoiceLineItem>;
  /** delete single row from the table: "fee" */
  delete_fee_by_pk?: Maybe<Fee>;
  /** delete data from the table: "finixTerminal" */
  delete_finixTerminal?: Maybe<FinixTerminal_Mutation_Response>;
  /** delete single row from the table: "finixTerminal" */
  delete_finixTerminal_by_pk?: Maybe<FinixTerminal>;
  /** delete data from the table: "flowsheet" */
  delete_flowsheet?: Maybe<Flowsheet_Mutation_Response>;
  /** delete data from the table: "flowsheetParameter" */
  delete_flowsheetParameter?: Maybe<FlowsheetParameter_Mutation_Response>;
  /** delete data from the table: "flowsheetParameterFlowsheet" */
  delete_flowsheetParameterFlowsheet?: Maybe<FlowsheetParameterFlowsheet_Mutation_Response>;
  /** delete single row from the table: "flowsheetParameterFlowsheet" */
  delete_flowsheetParameterFlowsheet_by_pk?: Maybe<FlowsheetParameterFlowsheet>;
  /** delete data from the table: "flowsheetParameterGroup" */
  delete_flowsheetParameterGroup?: Maybe<FlowsheetParameterGroup_Mutation_Response>;
  /** delete single row from the table: "flowsheetParameterGroup" */
  delete_flowsheetParameterGroup_by_pk?: Maybe<FlowsheetParameterGroup>;
  /** delete single row from the table: "flowsheetParameter" */
  delete_flowsheetParameter_by_pk?: Maybe<FlowsheetParameter>;
  /** delete single row from the table: "flowsheet" */
  delete_flowsheet_by_pk?: Maybe<Flowsheet>;
  /** delete data from the table: "genericInvoiceLineItem" */
  delete_genericInvoiceLineItem?: Maybe<GenericInvoiceLineItem_Mutation_Response>;
  /** delete data from the table: "genericInvoiceLineItemDiscount" */
  delete_genericInvoiceLineItemDiscount?: Maybe<GenericInvoiceLineItemDiscount_Mutation_Response>;
  /** delete single row from the table: "genericInvoiceLineItemDiscount" */
  delete_genericInvoiceLineItemDiscount_by_pk?: Maybe<GenericInvoiceLineItemDiscount>;
  /** delete single row from the table: "genericInvoiceLineItem" */
  delete_genericInvoiceLineItem_by_pk?: Maybe<GenericInvoiceLineItem>;
  /** delete data from the table: "giftCard" */
  delete_giftCard?: Maybe<GiftCard_Mutation_Response>;
  /** delete single row from the table: "giftCard" */
  delete_giftCard_by_pk?: Maybe<GiftCard>;
  /** delete data from the table: "googleAccount" */
  delete_googleAccount?: Maybe<GoogleAccount_Mutation_Response>;
  /** delete single row from the table: "googleAccount" */
  delete_googleAccount_by_pk?: Maybe<GoogleAccount>;
  /** delete data from the table: "googleLocation" */
  delete_googleLocation?: Maybe<GoogleLocation_Mutation_Response>;
  /** delete single row from the table: "googleLocation" */
  delete_googleLocation_by_pk?: Maybe<GoogleLocation>;
  /** delete data from the table: "googleReview" */
  delete_googleReview?: Maybe<GoogleReview_Mutation_Response>;
  /** delete single row from the table: "googleReview" */
  delete_googleReview_by_pk?: Maybe<GoogleReview>;
  /** delete data from the table: "importJob" */
  delete_importJob?: Maybe<ImportJob_Mutation_Response>;
  /** delete single row from the table: "importJob" */
  delete_importJob_by_pk?: Maybe<ImportJob>;
  /** delete data from the table: "importMetadata" */
  delete_importMetadata?: Maybe<ImportMetadata_Mutation_Response>;
  /** delete single row from the table: "importMetadata" */
  delete_importMetadata_by_pk?: Maybe<ImportMetadata>;
  /** delete data from the table: "incompatibleServices" */
  delete_incompatibleServices?: Maybe<IncompatibleServices_Mutation_Response>;
  /** delete single row from the table: "incompatibleServices" */
  delete_incompatibleServices_by_pk?: Maybe<IncompatibleServices>;
  /** delete data from the table: "internalAppointmentProvider" */
  delete_internalAppointmentProvider?: Maybe<InternalAppointmentProvider_Mutation_Response>;
  /** delete single row from the table: "internalAppointmentProvider" */
  delete_internalAppointmentProvider_by_pk?: Maybe<InternalAppointmentProvider>;
  /** delete data from the table: "inventoryTransaction" */
  delete_inventoryTransaction?: Maybe<InventoryTransaction_Mutation_Response>;
  /** delete single row from the table: "inventoryTransaction" */
  delete_inventoryTransaction_by_pk?: Maybe<InventoryTransaction>;
  /** delete data from the table: "invoice" */
  delete_invoice?: Maybe<Invoice_Mutation_Response>;
  /** delete data from the table: "invoiceConfiguration" */
  delete_invoiceConfiguration?: Maybe<InvoiceConfiguration_Mutation_Response>;
  /** delete single row from the table: "invoiceConfiguration" */
  delete_invoiceConfiguration_by_pk?: Maybe<InvoiceConfiguration>;
  /** delete data from the table: "invoiceCoupon" */
  delete_invoiceCoupon?: Maybe<InvoiceCoupon_Mutation_Response>;
  /** delete single row from the table: "invoiceCoupon" */
  delete_invoiceCoupon_by_pk?: Maybe<InvoiceCoupon>;
  /** delete data from the table: "invoiceMembership" */
  delete_invoiceMembership?: Maybe<InvoiceMembership_Mutation_Response>;
  /** delete single row from the table: "invoiceMembership" */
  delete_invoiceMembership_by_pk?: Maybe<InvoiceMembership>;
  /** delete data from the table: "invoicePackage" */
  delete_invoicePackage?: Maybe<InvoicePackage_Mutation_Response>;
  /** delete single row from the table: "invoicePackage" */
  delete_invoicePackage_by_pk?: Maybe<InvoicePackage>;
  /** delete data from the table: "invoiceProductItem" */
  delete_invoiceProductItem?: Maybe<InvoiceProductItem_Mutation_Response>;
  /** delete single row from the table: "invoiceProductItem" */
  delete_invoiceProductItem_by_pk?: Maybe<InvoiceProductItem>;
  /** delete data from the table: "invoiceServiceConsumable" */
  delete_invoiceServiceConsumable?: Maybe<InvoiceServiceConsumable_Mutation_Response>;
  /** delete single row from the table: "invoiceServiceConsumable" */
  delete_invoiceServiceConsumable_by_pk?: Maybe<InvoiceServiceConsumable>;
  /** delete data from the table: "invoiceServiceItem" */
  delete_invoiceServiceItem?: Maybe<InvoiceServiceItem_Mutation_Response>;
  /** delete single row from the table: "invoiceServiceItem" */
  delete_invoiceServiceItem_by_pk?: Maybe<InvoiceServiceItem>;
  /** delete data from the table: "invoiceTemplate" */
  delete_invoiceTemplate?: Maybe<InvoiceTemplate_Mutation_Response>;
  /** delete single row from the table: "invoiceTemplate" */
  delete_invoiceTemplate_by_pk?: Maybe<InvoiceTemplate>;
  /** delete single row from the table: "invoice" */
  delete_invoice_by_pk?: Maybe<Invoice>;
  /** delete data from the table: "job" */
  delete_job?: Maybe<Job_Mutation_Response>;
  /** delete data from the table: "jobStatus" */
  delete_jobStatus?: Maybe<JobStatus_Mutation_Response>;
  /** delete single row from the table: "jobStatus" */
  delete_jobStatus_by_pk?: Maybe<JobStatus>;
  /** delete single row from the table: "job" */
  delete_job_by_pk?: Maybe<Job>;
  /** delete data from the table: "labResult" */
  delete_labResult?: Maybe<LabResult_Mutation_Response>;
  /** delete single row from the table: "labResult" */
  delete_labResult_by_pk?: Maybe<LabResult>;
  /** delete data from the table: "leadForm" */
  delete_leadForm?: Maybe<LeadForm_Mutation_Response>;
  /** delete data from the table: "leadFormEntry" */
  delete_leadFormEntry?: Maybe<LeadFormEntry_Mutation_Response>;
  /** delete data from the table: "leadFormEntryTag" */
  delete_leadFormEntryTag?: Maybe<LeadFormEntryTag_Mutation_Response>;
  /** delete single row from the table: "leadFormEntryTag" */
  delete_leadFormEntryTag_by_pk?: Maybe<LeadFormEntryTag>;
  /** delete single row from the table: "leadFormEntry" */
  delete_leadFormEntry_by_pk?: Maybe<LeadFormEntry>;
  /** delete data from the table: "leadFormField" */
  delete_leadFormField?: Maybe<LeadFormField_Mutation_Response>;
  /** delete data from the table: "leadFormFieldValue" */
  delete_leadFormFieldValue?: Maybe<LeadFormFieldValue_Mutation_Response>;
  /** delete single row from the table: "leadFormFieldValue" */
  delete_leadFormFieldValue_by_pk?: Maybe<LeadFormFieldValue>;
  /** delete single row from the table: "leadFormField" */
  delete_leadFormField_by_pk?: Maybe<LeadFormField>;
  /** delete data from the table: "leadFormLink" */
  delete_leadFormLink?: Maybe<LeadFormLink_Mutation_Response>;
  /** delete single row from the table: "leadFormLink" */
  delete_leadFormLink_by_pk?: Maybe<LeadFormLink>;
  /** delete data from the table: "leadFormStep" */
  delete_leadFormStep?: Maybe<LeadFormStep_Mutation_Response>;
  /** delete single row from the table: "leadFormStep" */
  delete_leadFormStep_by_pk?: Maybe<LeadFormStep>;
  /** delete single row from the table: "leadForm" */
  delete_leadForm_by_pk?: Maybe<LeadForm>;
  /** delete data from the table: "leadTag" */
  delete_leadTag?: Maybe<LeadTag_Mutation_Response>;
  /** delete single row from the table: "leadTag" */
  delete_leadTag_by_pk?: Maybe<LeadTag>;
  /** delete data from the table: "leadTrackedEvent" */
  delete_leadTrackedEvent?: Maybe<LeadTrackedEvent_Mutation_Response>;
  /** delete single row from the table: "leadTrackedEvent" */
  delete_leadTrackedEvent_by_pk?: Maybe<LeadTrackedEvent>;
  /** delete data from the table: "location" */
  delete_location?: Maybe<Location_Mutation_Response>;
  /** delete data from the table: "locationCoupon" */
  delete_locationCoupon?: Maybe<LocationCoupon_Mutation_Response>;
  /** delete single row from the table: "locationCoupon" */
  delete_locationCoupon_by_pk?: Maybe<LocationCoupon>;
  /** delete data from the table: "locationProvider" */
  delete_locationProvider?: Maybe<LocationProvider_Mutation_Response>;
  /** delete single row from the table: "locationProvider" */
  delete_locationProvider_by_pk?: Maybe<LocationProvider>;
  /** delete data from the table: "locationSchedule" */
  delete_locationSchedule?: Maybe<LocationSchedule_Mutation_Response>;
  /** delete single row from the table: "locationSchedule" */
  delete_locationSchedule_by_pk?: Maybe<LocationSchedule>;
  /** delete data from the table: "locationService" */
  delete_locationService?: Maybe<LocationService_Mutation_Response>;
  /** delete single row from the table: "locationService" */
  delete_locationService_by_pk?: Maybe<LocationService>;
  /** delete single row from the table: "location" */
  delete_location_by_pk?: Maybe<Location>;
  /** delete data from the table: "marketingTag" */
  delete_marketingTag?: Maybe<MarketingTag_Mutation_Response>;
  /** delete data from the table: "marketingTagEmail" */
  delete_marketingTagEmail?: Maybe<MarketingTagEmail_Mutation_Response>;
  /** delete single row from the table: "marketingTagEmail" */
  delete_marketingTagEmail_by_pk?: Maybe<MarketingTagEmail>;
  /** delete data from the table: "marketingTagSms" */
  delete_marketingTagSms?: Maybe<MarketingTagSms_Mutation_Response>;
  /** delete single row from the table: "marketingTagSms" */
  delete_marketingTagSms_by_pk?: Maybe<MarketingTagSms>;
  /** delete single row from the table: "marketingTag" */
  delete_marketingTag_by_pk?: Maybe<MarketingTag>;
  /** delete data from the table: "mediaTag" */
  delete_mediaTag?: Maybe<MediaTag_Mutation_Response>;
  /** delete single row from the table: "mediaTag" */
  delete_mediaTag_by_pk?: Maybe<MediaTag>;
  /** delete data from the table: "membership" */
  delete_membership?: Maybe<Membership_Mutation_Response>;
  /** delete data from the table: "membershipCoupon" */
  delete_membershipCoupon?: Maybe<MembershipCoupon_Mutation_Response>;
  /** delete single row from the table: "membershipCoupon" */
  delete_membershipCoupon_by_pk?: Maybe<MembershipCoupon>;
  /** delete data from the table: "membershipType" */
  delete_membershipType?: Maybe<MembershipType_Mutation_Response>;
  /** delete single row from the table: "membershipType" */
  delete_membershipType_by_pk?: Maybe<MembershipType>;
  /** delete single row from the table: "membership" */
  delete_membership_by_pk?: Maybe<Membership>;
  /** delete data from the table: "note" */
  delete_note?: Maybe<Note_Mutation_Response>;
  /** delete data from the table: "noteSignature" */
  delete_noteSignature?: Maybe<NoteSignature_Mutation_Response>;
  /** delete single row from the table: "noteSignature" */
  delete_noteSignature_by_pk?: Maybe<NoteSignature>;
  /** delete single row from the table: "note" */
  delete_note_by_pk?: Maybe<Note>;
  /** delete data from the table: "organization" */
  delete_organization?: Maybe<Organization_Mutation_Response>;
  /** delete data from the table: "organizationConfiguration" */
  delete_organizationConfiguration?: Maybe<OrganizationConfiguration_Mutation_Response>;
  /** delete single row from the table: "organizationConfiguration" */
  delete_organizationConfiguration_by_pk?: Maybe<OrganizationConfiguration>;
  /** delete single row from the table: "organization" */
  delete_organization_by_pk?: Maybe<Organization>;
  /** delete data from the table: "package" */
  delete_package?: Maybe<Package_Mutation_Response>;
  /** delete single row from the table: "package" */
  delete_package_by_pk?: Maybe<Package>;
  /** delete data from the table: "patient" */
  delete_patient?: Maybe<Patient_Mutation_Response>;
  /** delete data from the table: "patientAddress" */
  delete_patientAddress?: Maybe<PatientAddress_Mutation_Response>;
  /** delete single row from the table: "patientAddress" */
  delete_patientAddress_by_pk?: Maybe<PatientAddress>;
  /** delete data from the table: "patientConsent" */
  delete_patientConsent?: Maybe<PatientConsent_Mutation_Response>;
  /** delete single row from the table: "patientConsent" */
  delete_patientConsent_by_pk?: Maybe<PatientConsent>;
  /** delete data from the table: "patientConversation" */
  delete_patientConversation?: Maybe<PatientConversation_Mutation_Response>;
  /** delete single row from the table: "patientConversation" */
  delete_patientConversation_by_pk?: Maybe<PatientConversation>;
  /** delete data from the table: "patientFlowsheetData" */
  delete_patientFlowsheetData?: Maybe<PatientFlowsheetData_Mutation_Response>;
  /** delete single row from the table: "patientFlowsheetData" */
  delete_patientFlowsheetData_by_pk?: Maybe<PatientFlowsheetData>;
  /** delete data from the table: "patientFlowsheetRow" */
  delete_patientFlowsheetRow?: Maybe<PatientFlowsheetRow_Mutation_Response>;
  /** delete single row from the table: "patientFlowsheetRow" */
  delete_patientFlowsheetRow_by_pk?: Maybe<PatientFlowsheetRow>;
  /** delete data from the table: "patientInvite" */
  delete_patientInvite?: Maybe<PatientInvite_Mutation_Response>;
  /** delete single row from the table: "patientInvite" */
  delete_patientInvite_by_pk?: Maybe<PatientInvite>;
  /** delete data from the table: "patientMembership" */
  delete_patientMembership?: Maybe<PatientMembership_Mutation_Response>;
  /** delete single row from the table: "patientMembership" */
  delete_patientMembership_by_pk?: Maybe<PatientMembership>;
  /** delete data from the table: "patientSystemNotice" */
  delete_patientSystemNotice?: Maybe<PatientSystemNotice_Mutation_Response>;
  /** delete single row from the table: "patientSystemNotice" */
  delete_patientSystemNotice_by_pk?: Maybe<PatientSystemNotice>;
  /** delete data from the table: "patientTag" */
  delete_patientTag?: Maybe<PatientTag_Mutation_Response>;
  /** delete single row from the table: "patientTag" */
  delete_patientTag_by_pk?: Maybe<PatientTag>;
  /** delete data from the table: "patientTags" */
  delete_patientTags?: Maybe<PatientTags_Mutation_Response>;
  /** delete single row from the table: "patientTags" */
  delete_patientTags_by_pk?: Maybe<PatientTags>;
  /** delete data from the table: "patientWallet" */
  delete_patientWallet?: Maybe<PatientWallet_Mutation_Response>;
  /** delete data from the table: "patientWalletCoupon" */
  delete_patientWalletCoupon?: Maybe<PatientWalletCoupon_Mutation_Response>;
  /** delete single row from the table: "patientWalletCoupon" */
  delete_patientWalletCoupon_by_pk?: Maybe<PatientWalletCoupon>;
  /** delete data from the table: "patientWalletTransaction" */
  delete_patientWalletTransaction?: Maybe<PatientWalletTransaction_Mutation_Response>;
  /** delete single row from the table: "patientWalletTransaction" */
  delete_patientWalletTransaction_by_pk?: Maybe<PatientWalletTransaction>;
  /** delete single row from the table: "patientWallet" */
  delete_patientWallet_by_pk?: Maybe<PatientWallet>;
  /** delete data from the table: "patientWorkspace" */
  delete_patientWorkspace?: Maybe<PatientWorkspace_Mutation_Response>;
  /** delete single row from the table: "patientWorkspace" */
  delete_patientWorkspace_by_pk?: Maybe<PatientWorkspace>;
  /** delete single row from the table: "patient" */
  delete_patient_by_pk?: Maybe<Patient>;
  /** delete data from the table: "payment" */
  delete_payment?: Maybe<Payment_Mutation_Response>;
  /** delete data from the table: "paymentMethod" */
  delete_paymentMethod?: Maybe<PaymentMethod_Mutation_Response>;
  /** delete single row from the table: "paymentMethod" */
  delete_paymentMethod_by_pk?: Maybe<PaymentMethod>;
  /** delete data from the table: "paymentRelationship" */
  delete_paymentRelationship?: Maybe<PaymentRelationship_Mutation_Response>;
  /** delete single row from the table: "paymentRelationship" */
  delete_paymentRelationship_by_pk?: Maybe<PaymentRelationship>;
  /** delete single row from the table: "payment" */
  delete_payment_by_pk?: Maybe<Payment>;
  /** delete data from the table: "payrixTerminal" */
  delete_payrixTerminal?: Maybe<PayrixTerminal_Mutation_Response>;
  /** delete data from the table: "payrixTerminalTransaction" */
  delete_payrixTerminalTransaction?: Maybe<PayrixTerminalTransaction_Mutation_Response>;
  /** delete single row from the table: "payrixTerminalTransaction" */
  delete_payrixTerminalTransaction_by_pk?: Maybe<PayrixTerminalTransaction>;
  /** delete single row from the table: "payrixTerminal" */
  delete_payrixTerminal_by_pk?: Maybe<PayrixTerminal>;
  /** delete data from the table: "payrixWebAlert" */
  delete_payrixWebAlert?: Maybe<PayrixWebAlert_Mutation_Response>;
  /** delete single row from the table: "payrixWebAlert" */
  delete_payrixWebAlert_by_pk?: Maybe<PayrixWebAlert>;
  /** delete data from the table: "photo" */
  delete_photo?: Maybe<Photo_Mutation_Response>;
  /** delete data from the table: "photoMediaTag" */
  delete_photoMediaTag?: Maybe<PhotoMediaTag_Mutation_Response>;
  /** delete single row from the table: "photoMediaTag" */
  delete_photoMediaTag_by_pk?: Maybe<PhotoMediaTag>;
  /** delete single row from the table: "photo" */
  delete_photo_by_pk?: Maybe<Photo>;
  /** delete data from the table: "pin" */
  delete_pin?: Maybe<Pin_Mutation_Response>;
  /** delete single row from the table: "pin" */
  delete_pin_by_pk?: Maybe<Pin>;
  /** delete data from the table: "postStatus" */
  delete_postStatus?: Maybe<PostStatus_Mutation_Response>;
  /** delete single row from the table: "postStatus" */
  delete_postStatus_by_pk?: Maybe<PostStatus>;
  /** delete data from the table: "prescription" */
  delete_prescription?: Maybe<Prescription_Mutation_Response>;
  /** delete data from the table: "prescriptionDetail" */
  delete_prescriptionDetail?: Maybe<PrescriptionDetail_Mutation_Response>;
  /** delete single row from the table: "prescriptionDetail" */
  delete_prescriptionDetail_by_pk?: Maybe<PrescriptionDetail>;
  /** delete single row from the table: "prescription" */
  delete_prescription_by_pk?: Maybe<Prescription>;
  /** delete data from the table: "promotion" */
  delete_promotion?: Maybe<Promotion_Mutation_Response>;
  /** delete data from the table: "promotionCoupons" */
  delete_promotionCoupons?: Maybe<PromotionCoupons_Mutation_Response>;
  /** delete single row from the table: "promotionCoupons" */
  delete_promotionCoupons_by_pk?: Maybe<PromotionCoupons>;
  /** delete data from the table: "promotionServices" */
  delete_promotionServices?: Maybe<PromotionServices_Mutation_Response>;
  /** delete single row from the table: "promotionServices" */
  delete_promotionServices_by_pk?: Maybe<PromotionServices>;
  /** delete single row from the table: "promotion" */
  delete_promotion_by_pk?: Maybe<Promotion>;
  /** delete data from the table: "provider" */
  delete_provider?: Maybe<Provider_Mutation_Response>;
  /** delete data from the table: "providerSchedule" */
  delete_providerSchedule?: Maybe<ProviderSchedule_Mutation_Response>;
  /** delete single row from the table: "providerSchedule" */
  delete_providerSchedule_by_pk?: Maybe<ProviderSchedule>;
  /** delete single row from the table: "provider" */
  delete_provider_by_pk?: Maybe<Provider>;
  /** delete data from the table: "purchaseOrder" */
  delete_purchaseOrder?: Maybe<PurchaseOrder_Mutation_Response>;
  /** delete single row from the table: "purchaseOrder" */
  delete_purchaseOrder_by_pk?: Maybe<PurchaseOrder>;
  /** delete data from the table: "recurrenceType" */
  delete_recurrenceType?: Maybe<RecurrenceType_Mutation_Response>;
  /** delete single row from the table: "recurrenceType" */
  delete_recurrenceType_by_pk?: Maybe<RecurrenceType>;
  /** delete data from the table: "refund" */
  delete_refund?: Maybe<Refund_Mutation_Response>;
  /** delete single row from the table: "refund" */
  delete_refund_by_pk?: Maybe<Refund>;
  /** delete data from the table: "resolutionStatus" */
  delete_resolutionStatus?: Maybe<ResolutionStatus_Mutation_Response>;
  /** delete single row from the table: "resolutionStatus" */
  delete_resolutionStatus_by_pk?: Maybe<ResolutionStatus>;
  /** delete data from the table: "rewardProgram" */
  delete_rewardProgram?: Maybe<RewardProgram_Mutation_Response>;
  /** delete data from the table: "rewardProgramEnrollment" */
  delete_rewardProgramEnrollment?: Maybe<RewardProgramEnrollment_Mutation_Response>;
  /** delete single row from the table: "rewardProgramEnrollment" */
  delete_rewardProgramEnrollment_by_pk?: Maybe<RewardProgramEnrollment>;
  /** delete single row from the table: "rewardProgram" */
  delete_rewardProgram_by_pk?: Maybe<RewardProgram>;
  /** delete data from the table: "room" */
  delete_room?: Maybe<Room_Mutation_Response>;
  /** delete single row from the table: "room" */
  delete_room_by_pk?: Maybe<Room>;
  /** delete data from the table: "rsReview" */
  delete_rsReview?: Maybe<RsReview_Mutation_Response>;
  /** delete single row from the table: "rsReview" */
  delete_rsReview_by_pk?: Maybe<RsReview>;
  /** delete data from the table: "service" */
  delete_service?: Maybe<Service_Mutation_Response>;
  /** delete data from the table: "serviceAddOn" */
  delete_serviceAddOn?: Maybe<ServiceAddOn_Mutation_Response>;
  /** delete single row from the table: "serviceAddOn" */
  delete_serviceAddOn_by_pk?: Maybe<ServiceAddOn>;
  /** delete data from the table: "serviceCategory" */
  delete_serviceCategory?: Maybe<ServiceCategory_Mutation_Response>;
  /** delete single row from the table: "serviceCategory" */
  delete_serviceCategory_by_pk?: Maybe<ServiceCategory>;
  /** delete data from the table: "serviceConsent" */
  delete_serviceConsent?: Maybe<ServiceConsent_Mutation_Response>;
  /** delete single row from the table: "serviceConsent" */
  delete_serviceConsent_by_pk?: Maybe<ServiceConsent>;
  /** delete data from the table: "serviceConsumableType" */
  delete_serviceConsumableType?: Maybe<ServiceConsumableType_Mutation_Response>;
  /** delete single row from the table: "serviceConsumableType" */
  delete_serviceConsumableType_by_pk?: Maybe<ServiceConsumableType>;
  /** delete data from the table: "serviceCoupon" */
  delete_serviceCoupon?: Maybe<ServiceCoupon_Mutation_Response>;
  /** delete single row from the table: "serviceCoupon" */
  delete_serviceCoupon_by_pk?: Maybe<ServiceCoupon>;
  /** delete data from the table: "serviceCustomForm" */
  delete_serviceCustomForm?: Maybe<ServiceCustomForm_Mutation_Response>;
  /** delete single row from the table: "serviceCustomForm" */
  delete_serviceCustomForm_by_pk?: Maybe<ServiceCustomForm>;
  /** delete data from the table: "serviceDeviceType" */
  delete_serviceDeviceType?: Maybe<ServiceDeviceType_Mutation_Response>;
  /** delete single row from the table: "serviceDeviceType" */
  delete_serviceDeviceType_by_pk?: Maybe<ServiceDeviceType>;
  /** delete data from the table: "serviceProvider" */
  delete_serviceProvider?: Maybe<ServiceProvider_Mutation_Response>;
  /** delete single row from the table: "serviceProvider" */
  delete_serviceProvider_by_pk?: Maybe<ServiceProvider>;
  /** delete data from the table: "serviceUnit" */
  delete_serviceUnit?: Maybe<ServiceUnit_Mutation_Response>;
  /** delete single row from the table: "serviceUnit" */
  delete_serviceUnit_by_pk?: Maybe<ServiceUnit>;
  /** delete single row from the table: "service" */
  delete_service_by_pk?: Maybe<Service>;
  /** delete data from the table: "shrink" */
  delete_shrink?: Maybe<Shrink_Mutation_Response>;
  /** delete single row from the table: "shrink" */
  delete_shrink_by_pk?: Maybe<Shrink>;
  /** delete data from the table: "signature" */
  delete_signature?: Maybe<Signature_Mutation_Response>;
  /** delete single row from the table: "signature" */
  delete_signature_by_pk?: Maybe<Signature>;
  /** delete data from the table: "smartNote" */
  delete_smartNote?: Maybe<SmartNote_Mutation_Response>;
  /** delete single row from the table: "smartNote" */
  delete_smartNote_by_pk?: Maybe<SmartNote>;
  /** delete data from the table: "smsTemplate" */
  delete_smsTemplate?: Maybe<SmsTemplate_Mutation_Response>;
  /** delete data from the table: "smsTemplatePatient" */
  delete_smsTemplatePatient?: Maybe<SmsTemplatePatient_Mutation_Response>;
  /** delete single row from the table: "smsTemplatePatient" */
  delete_smsTemplatePatient_by_pk?: Maybe<SmsTemplatePatient>;
  /** delete data from the table: "smsTemplateSendQueue" */
  delete_smsTemplateSendQueue?: Maybe<SmsTemplateSendQueue_Mutation_Response>;
  /** delete single row from the table: "smsTemplateSendQueue" */
  delete_smsTemplateSendQueue_by_pk?: Maybe<SmsTemplateSendQueue>;
  /** delete single row from the table: "smsTemplate" */
  delete_smsTemplate_by_pk?: Maybe<SmsTemplate>;
  /** delete data from the table: "socialMediaAccount" */
  delete_socialMediaAccount?: Maybe<SocialMediaAccount_Mutation_Response>;
  /** delete data from the table: "socialMediaAccountPost" */
  delete_socialMediaAccountPost?: Maybe<SocialMediaAccountPost_Mutation_Response>;
  /** delete single row from the table: "socialMediaAccountPost" */
  delete_socialMediaAccountPost_by_pk?: Maybe<SocialMediaAccountPost>;
  /** delete single row from the table: "socialMediaAccount" */
  delete_socialMediaAccount_by_pk?: Maybe<SocialMediaAccount>;
  /** delete data from the table: "socialMediaPlatform" */
  delete_socialMediaPlatform?: Maybe<SocialMediaPlatform_Mutation_Response>;
  /** delete single row from the table: "socialMediaPlatform" */
  delete_socialMediaPlatform_by_pk?: Maybe<SocialMediaPlatform>;
  /** delete data from the table: "socialMediaPost" */
  delete_socialMediaPost?: Maybe<SocialMediaPost_Mutation_Response>;
  /** delete single row from the table: "socialMediaPost" */
  delete_socialMediaPost_by_pk?: Maybe<SocialMediaPost>;
  /** delete data from the table: "tag" */
  delete_tag?: Maybe<Tag_Mutation_Response>;
  /** delete single row from the table: "tag" */
  delete_tag_by_pk?: Maybe<Tag>;
  /** delete data from the table: "task" */
  delete_task?: Maybe<Task_Mutation_Response>;
  /** delete data from the table: "taskComment" */
  delete_taskComment?: Maybe<TaskComment_Mutation_Response>;
  /** delete single row from the table: "taskComment" */
  delete_taskComment_by_pk?: Maybe<TaskComment>;
  /** delete single row from the table: "task" */
  delete_task_by_pk?: Maybe<Task>;
  /** delete data from the table: "telehealthMeeting" */
  delete_telehealthMeeting?: Maybe<TelehealthMeeting_Mutation_Response>;
  /** delete single row from the table: "telehealthMeeting" */
  delete_telehealthMeeting_by_pk?: Maybe<TelehealthMeeting>;
  /** delete data from the table: "unusedAppointmentServiceConsumableType" */
  delete_unusedAppointmentServiceConsumableType?: Maybe<UnusedAppointmentServiceConsumableType_Mutation_Response>;
  /** delete single row from the table: "unusedAppointmentServiceConsumableType" */
  delete_unusedAppointmentServiceConsumableType_by_pk?: Maybe<UnusedAppointmentServiceConsumableType>;
  /** delete data from the table: "user" */
  delete_user?: Maybe<User_Mutation_Response>;
  /** delete data from the table: "userInvite" */
  delete_userInvite?: Maybe<UserInvite_Mutation_Response>;
  /** delete single row from the table: "userInvite" */
  delete_userInvite_by_pk?: Maybe<UserInvite>;
  /** delete data from the table: "userOrganization" */
  delete_userOrganization?: Maybe<UserOrganization_Mutation_Response>;
  /** delete single row from the table: "userOrganization" */
  delete_userOrganization_by_pk?: Maybe<UserOrganization>;
  /** delete data from the table: "userWorkspace" */
  delete_userWorkspace?: Maybe<UserWorkspace_Mutation_Response>;
  /** delete single row from the table: "userWorkspace" */
  delete_userWorkspace_by_pk?: Maybe<UserWorkspace>;
  /** delete single row from the table: "user" */
  delete_user_by_pk?: Maybe<User>;
  /** delete data from the table: "vitals" */
  delete_vitals?: Maybe<Vitals_Mutation_Response>;
  /** delete single row from the table: "vitals" */
  delete_vitals_by_pk?: Maybe<Vitals>;
  /** delete data from the table: "workspace" */
  delete_workspace?: Maybe<Workspace_Mutation_Response>;
  /** delete data from the table: "workspaceConfiguration" */
  delete_workspaceConfiguration?: Maybe<WorkspaceConfiguration_Mutation_Response>;
  /** delete single row from the table: "workspaceConfiguration" */
  delete_workspaceConfiguration_by_pk?: Maybe<WorkspaceConfiguration>;
  /** delete single row from the table: "workspace" */
  delete_workspace_by_pk?: Maybe<Workspace>;
  /** delete data from the table: "zdReview" */
  delete_zdReview?: Maybe<ZdReview_Mutation_Response>;
  /** delete single row from the table: "zdReview" */
  delete_zdReview_by_pk?: Maybe<ZdReview>;
  /** disconnectNylas */
  disconnectNylas: DisconnectNylasOutput;
  editImage?: Maybe<EditImageOutput>;
  enrollLocationERx: SuccessAnswer;
  enrollProviderERx: SuccessAnswer;
  enrollWorkspaceERx: SuccessAnswer;
  /** facebookRedirect */
  facebookRedirect: SuccessAnswer;
  /** bulkFormatPatientWorkspacePhoneNumbers */
  formatAllPatientWorkspacePhoneNumbers?: Maybe<SuccessAnswer>;
  generateFinixOnboardingLink?: Maybe<FinixOnboardingLinkOutput>;
  generateInvoiceReceiptPDF?: Maybe<InvoiceReceiptPdfOutput>;
  generatePatientConsentPDF?: Maybe<SuccessAnswer>;
  /** getCalendarResources */
  getCalendarResources?: Maybe<CalendarResourceOutput>;
  /** getInverseAvailability */
  getInverseAvailability?: Maybe<InverseAvailabilityOutput>;
  /** getNylasAuthUrl */
  getNylasAuthUrl: NylasAuthUrl;
  getOrCreateTwilioConverssation?: Maybe<GetOrCreateTwilioConversationResponse>;
  /** Generates an authenticated lab network iframe url */
  getPatientLabOrderUrl: LabOrderUrlOutput;
  googleRedirect: SuccessAnswer;
  /** initializeNylasExchange */
  initializeNylasExchange: NylasExchangeOutput;
  initializeWallets?: Maybe<InitializeWalletsOutput>;
  insertGoogleLocation?: Maybe<SuccessAnswer>;
  insertPatientAddress: InsertPatientAddressOutput;
  insertPurchaseOrderAndAttachConsumables: IdResponse;
  /** insert data into the table: "activityLog" */
  insert_activityLog?: Maybe<ActivityLog_Mutation_Response>;
  /** insert a single row into the table: "activityLog" */
  insert_activityLog_one?: Maybe<ActivityLog>;
  /** insert data into the table: "address" */
  insert_address?: Maybe<Address_Mutation_Response>;
  /** insert a single row into the table: "address" */
  insert_address_one?: Maybe<Address>;
  /** insert data into the table: "appointment" */
  insert_appointment?: Maybe<Appointment_Mutation_Response>;
  /** insert data into the table: "appointmentConsent" */
  insert_appointmentConsent?: Maybe<AppointmentConsent_Mutation_Response>;
  /** insert a single row into the table: "appointmentConsent" */
  insert_appointmentConsent_one?: Maybe<AppointmentConsent>;
  /** insert data into the table: "appointmentCustomForm" */
  insert_appointmentCustomForm?: Maybe<AppointmentCustomForm_Mutation_Response>;
  /** insert a single row into the table: "appointmentCustomForm" */
  insert_appointmentCustomForm_one?: Maybe<AppointmentCustomForm>;
  /** insert data into the table: "appointmentService" */
  insert_appointmentService?: Maybe<AppointmentService_Mutation_Response>;
  /** insert data into the table: "appointmentServiceConsumable" */
  insert_appointmentServiceConsumable?: Maybe<AppointmentServiceConsumable_Mutation_Response>;
  /** insert a single row into the table: "appointmentServiceConsumable" */
  insert_appointmentServiceConsumable_one?: Maybe<AppointmentServiceConsumable>;
  /** insert a single row into the table: "appointmentService" */
  insert_appointmentService_one?: Maybe<AppointmentService>;
  /** insert data into the table: "appointmentType" */
  insert_appointmentType?: Maybe<AppointmentType_Mutation_Response>;
  /** insert a single row into the table: "appointmentType" */
  insert_appointmentType_one?: Maybe<AppointmentType>;
  /** insert a single row into the table: "appointment" */
  insert_appointment_one?: Maybe<Appointment>;
  /** insert data into the table: "audience" */
  insert_audience?: Maybe<Audience_Mutation_Response>;
  /** insert data into the table: "audienceEmail" */
  insert_audienceEmail?: Maybe<AudienceEmail_Mutation_Response>;
  /** insert a single row into the table: "audienceEmail" */
  insert_audienceEmail_one?: Maybe<AudienceEmail>;
  /** insert a single row into the table: "audience" */
  insert_audience_one?: Maybe<Audience>;
  /** insert data into the table: "authProfile" */
  insert_authProfile?: Maybe<AuthProfile_Mutation_Response>;
  /** insert a single row into the table: "authProfile" */
  insert_authProfile_one?: Maybe<AuthProfile>;
  /** insert data into the table: "clinicalMessage" */
  insert_clinicalMessage?: Maybe<ClinicalMessage_Mutation_Response>;
  /** insert data into the table: "clinicalMessageStatus" */
  insert_clinicalMessageStatus?: Maybe<ClinicalMessageStatus_Mutation_Response>;
  /** insert a single row into the table: "clinicalMessageStatus" */
  insert_clinicalMessageStatus_one?: Maybe<ClinicalMessageStatus>;
  /** insert a single row into the table: "clinicalMessage" */
  insert_clinicalMessage_one?: Maybe<ClinicalMessage>;
  /** insert data into the table: "compensationPolicy" */
  insert_compensationPolicy?: Maybe<CompensationPolicy_Mutation_Response>;
  /** insert data into the table: "compensationPolicyCommission" */
  insert_compensationPolicyCommission?: Maybe<CompensationPolicyCommission_Mutation_Response>;
  /** insert a single row into the table: "compensationPolicyCommission" */
  insert_compensationPolicyCommission_one?: Maybe<CompensationPolicyCommission>;
  /** insert data into the table: "compensationPolicyTier" */
  insert_compensationPolicyTier?: Maybe<CompensationPolicyTier_Mutation_Response>;
  /** insert data into the table: "compensationPolicyTierCategory" */
  insert_compensationPolicyTierCategory?: Maybe<CompensationPolicyTierCategory_Mutation_Response>;
  /** insert a single row into the table: "compensationPolicyTierCategory" */
  insert_compensationPolicyTierCategory_one?: Maybe<CompensationPolicyTierCategory>;
  /** insert data into the table: "compensationPolicyTierExemption" */
  insert_compensationPolicyTierExemption?: Maybe<CompensationPolicyTierExemption_Mutation_Response>;
  /** insert a single row into the table: "compensationPolicyTierExemption" */
  insert_compensationPolicyTierExemption_one?: Maybe<CompensationPolicyTierExemption>;
  /** insert data into the table: "compensationPolicyTierInclusion" */
  insert_compensationPolicyTierInclusion?: Maybe<CompensationPolicyTierInclusion_Mutation_Response>;
  /** insert a single row into the table: "compensationPolicyTierInclusion" */
  insert_compensationPolicyTierInclusion_one?: Maybe<CompensationPolicyTierInclusion>;
  /** insert a single row into the table: "compensationPolicyTier" */
  insert_compensationPolicyTier_one?: Maybe<CompensationPolicyTier>;
  /** insert a single row into the table: "compensationPolicy" */
  insert_compensationPolicy_one?: Maybe<CompensationPolicy>;
  /** insert data into the table: "compensationStrategy" */
  insert_compensationStrategy?: Maybe<CompensationStrategy_Mutation_Response>;
  /** insert data into the table: "compensationStrategyCommission" */
  insert_compensationStrategyCommission?: Maybe<CompensationStrategyCommission_Mutation_Response>;
  /** insert a single row into the table: "compensationStrategyCommission" */
  insert_compensationStrategyCommission_one?: Maybe<CompensationStrategyCommission>;
  /** insert data into the table: "compensationStrategyTier" */
  insert_compensationStrategyTier?: Maybe<CompensationStrategyTier_Mutation_Response>;
  /** insert data into the table: "compensationStrategyTierCategory" */
  insert_compensationStrategyTierCategory?: Maybe<CompensationStrategyTierCategory_Mutation_Response>;
  /** insert a single row into the table: "compensationStrategyTierCategory" */
  insert_compensationStrategyTierCategory_one?: Maybe<CompensationStrategyTierCategory>;
  /** insert data into the table: "compensationStrategyTierExemption" */
  insert_compensationStrategyTierExemption?: Maybe<CompensationStrategyTierExemption_Mutation_Response>;
  /** insert a single row into the table: "compensationStrategyTierExemption" */
  insert_compensationStrategyTierExemption_one?: Maybe<CompensationStrategyTierExemption>;
  /** insert data into the table: "compensationStrategyTierInclusion" */
  insert_compensationStrategyTierInclusion?: Maybe<CompensationStrategyTierInclusion_Mutation_Response>;
  /** insert a single row into the table: "compensationStrategyTierInclusion" */
  insert_compensationStrategyTierInclusion_one?: Maybe<CompensationStrategyTierInclusion>;
  /** insert a single row into the table: "compensationStrategyTier" */
  insert_compensationStrategyTier_one?: Maybe<CompensationStrategyTier>;
  /** insert a single row into the table: "compensationStrategy" */
  insert_compensationStrategy_one?: Maybe<CompensationStrategy>;
  /** insert data into the table: "consent" */
  insert_consent?: Maybe<Consent_Mutation_Response>;
  /** insert a single row into the table: "consent" */
  insert_consent_one?: Maybe<Consent>;
  /** insert data into the table: "consumable" */
  insert_consumable?: Maybe<Consumable_Mutation_Response>;
  /** insert data into the table: "consumableBrand" */
  insert_consumableBrand?: Maybe<ConsumableBrand_Mutation_Response>;
  /** insert a single row into the table: "consumableBrand" */
  insert_consumableBrand_one?: Maybe<ConsumableBrand>;
  /** insert data into the table: "consumableSubtype" */
  insert_consumableSubtype?: Maybe<ConsumableSubtype_Mutation_Response>;
  /** insert a single row into the table: "consumableSubtype" */
  insert_consumableSubtype_one?: Maybe<ConsumableSubtype>;
  /** insert data into the table: "consumableType" */
  insert_consumableType?: Maybe<ConsumableType_Mutation_Response>;
  /** insert data into the table: "consumableTypeConsumableSubtype" */
  insert_consumableTypeConsumableSubtype?: Maybe<ConsumableTypeConsumableSubtype_Mutation_Response>;
  /** insert a single row into the table: "consumableTypeConsumableSubtype" */
  insert_consumableTypeConsumableSubtype_one?: Maybe<ConsumableTypeConsumableSubtype>;
  /** insert a single row into the table: "consumableType" */
  insert_consumableType_one?: Maybe<ConsumableType>;
  /** insert a single row into the table: "consumable" */
  insert_consumable_one?: Maybe<Consumable>;
  /** insert data into the table: "coupon" */
  insert_coupon?: Maybe<Coupon_Mutation_Response>;
  /** insert data into the table: "couponConfiguration" */
  insert_couponConfiguration?: Maybe<CouponConfiguration_Mutation_Response>;
  /** insert a single row into the table: "couponConfiguration" */
  insert_couponConfiguration_one?: Maybe<CouponConfiguration>;
  /** insert data into the table: "couponScope" */
  insert_couponScope?: Maybe<CouponScope_Mutation_Response>;
  /** insert a single row into the table: "couponScope" */
  insert_couponScope_one?: Maybe<CouponScope>;
  /** insert data into the table: "couponType" */
  insert_couponType?: Maybe<CouponType_Mutation_Response>;
  /** insert a single row into the table: "couponType" */
  insert_couponType_one?: Maybe<CouponType>;
  /** insert a single row into the table: "coupon" */
  insert_coupon_one?: Maybe<Coupon>;
  /** insert data into the table: "customForm" */
  insert_customForm?: Maybe<CustomForm_Mutation_Response>;
  /** insert data into the table: "customFormField" */
  insert_customFormField?: Maybe<CustomFormField_Mutation_Response>;
  /** insert data into the table: "customFormFieldCategory" */
  insert_customFormFieldCategory?: Maybe<CustomFormFieldCategory_Mutation_Response>;
  /** insert a single row into the table: "customFormFieldCategory" */
  insert_customFormFieldCategory_one?: Maybe<CustomFormFieldCategory>;
  /** insert data into the table: "customFormFieldResponse" */
  insert_customFormFieldResponse?: Maybe<CustomFormFieldResponse_Mutation_Response>;
  /** insert a single row into the table: "customFormFieldResponse" */
  insert_customFormFieldResponse_one?: Maybe<CustomFormFieldResponse>;
  /** insert data into the table: "customFormFieldType" */
  insert_customFormFieldType?: Maybe<CustomFormFieldType_Mutation_Response>;
  /** insert a single row into the table: "customFormFieldType" */
  insert_customFormFieldType_one?: Maybe<CustomFormFieldType>;
  /** insert a single row into the table: "customFormField" */
  insert_customFormField_one?: Maybe<CustomFormField>;
  /** insert data into the table: "customFormOption" */
  insert_customFormOption?: Maybe<CustomFormOption_Mutation_Response>;
  /** insert a single row into the table: "customFormOption" */
  insert_customFormOption_one?: Maybe<CustomFormOption>;
  /** insert data into the table: "customFormReplicatedField" */
  insert_customFormReplicatedField?: Maybe<CustomFormReplicatedField_Mutation_Response>;
  /** insert a single row into the table: "customFormReplicatedField" */
  insert_customFormReplicatedField_one?: Maybe<CustomFormReplicatedField>;
  /** insert data into the table: "customFormReplicatedRow" */
  insert_customFormReplicatedRow?: Maybe<CustomFormReplicatedRow_Mutation_Response>;
  /** insert a single row into the table: "customFormReplicatedRow" */
  insert_customFormReplicatedRow_one?: Maybe<CustomFormReplicatedRow>;
  /** insert data into the table: "customFormResponse" */
  insert_customFormResponse?: Maybe<CustomFormResponse_Mutation_Response>;
  /** insert a single row into the table: "customFormResponse" */
  insert_customFormResponse_one?: Maybe<CustomFormResponse>;
  /** insert data into the table: "customFormRow" */
  insert_customFormRow?: Maybe<CustomFormRow_Mutation_Response>;
  /** insert a single row into the table: "customFormRow" */
  insert_customFormRow_one?: Maybe<CustomFormRow>;
  /** insert a single row into the table: "customForm" */
  insert_customForm_one?: Maybe<CustomForm>;
  /** insert data into the table: "customQuestion" */
  insert_customQuestion?: Maybe<CustomQuestion_Mutation_Response>;
  /** insert data into the table: "customQuestionOption" */
  insert_customQuestionOption?: Maybe<CustomQuestionOption_Mutation_Response>;
  /** insert a single row into the table: "customQuestionOption" */
  insert_customQuestionOption_one?: Maybe<CustomQuestionOption>;
  /** insert a single row into the table: "customQuestion" */
  insert_customQuestion_one?: Maybe<CustomQuestion>;
  /** insert data into the table: "degreeDesignation" */
  insert_degreeDesignation?: Maybe<DegreeDesignation_Mutation_Response>;
  /** insert a single row into the table: "degreeDesignation" */
  insert_degreeDesignation_one?: Maybe<DegreeDesignation>;
  /** insert data into the table: "depositInvoiceLineItem" */
  insert_depositInvoiceLineItem?: Maybe<DepositInvoiceLineItem_Mutation_Response>;
  /** insert a single row into the table: "depositInvoiceLineItem" */
  insert_depositInvoiceLineItem_one?: Maybe<DepositInvoiceLineItem>;
  /** insert data into the table: "device" */
  insert_device?: Maybe<Device_Mutation_Response>;
  /** insert data into the table: "deviceType" */
  insert_deviceType?: Maybe<DeviceType_Mutation_Response>;
  /** insert a single row into the table: "deviceType" */
  insert_deviceType_one?: Maybe<DeviceType>;
  /** insert a single row into the table: "device" */
  insert_device_one?: Maybe<Device>;
  /** insert data into the table: "discountInvoiceLineItem" */
  insert_discountInvoiceLineItem?: Maybe<DiscountInvoiceLineItem_Mutation_Response>;
  /** insert a single row into the table: "discountInvoiceLineItem" */
  insert_discountInvoiceLineItem_one?: Maybe<DiscountInvoiceLineItem>;
  /** insert data into the table: "document" */
  insert_document?: Maybe<Document_Mutation_Response>;
  /** insert data into the table: "documentTag" */
  insert_documentTag?: Maybe<DocumentTag_Mutation_Response>;
  /** insert a single row into the table: "documentTag" */
  insert_documentTag_one?: Maybe<DocumentTag>;
  /** insert a single row into the table: "document" */
  insert_document_one?: Maybe<Document>;
  /** insert data into the table: "emailCampaignLog" */
  insert_emailCampaignLog?: Maybe<EmailCampaignLog_Mutation_Response>;
  /** insert a single row into the table: "emailCampaignLog" */
  insert_emailCampaignLog_one?: Maybe<EmailCampaignLog>;
  /** insert data into the table: "emailTemplate" */
  insert_emailTemplate?: Maybe<EmailTemplate_Mutation_Response>;
  /** insert data into the table: "emailTemplatePatient" */
  insert_emailTemplatePatient?: Maybe<EmailTemplatePatient_Mutation_Response>;
  /** insert a single row into the table: "emailTemplatePatient" */
  insert_emailTemplatePatient_one?: Maybe<EmailTemplatePatient>;
  /** insert data into the table: "emailTemplateSendQueue" */
  insert_emailTemplateSendQueue?: Maybe<EmailTemplateSendQueue_Mutation_Response>;
  /** insert a single row into the table: "emailTemplateSendQueue" */
  insert_emailTemplateSendQueue_one?: Maybe<EmailTemplateSendQueue>;
  /** insert a single row into the table: "emailTemplate" */
  insert_emailTemplate_one?: Maybe<EmailTemplate>;
  /** insert data into the table: "externalLocation" */
  insert_externalLocation?: Maybe<ExternalLocation_Mutation_Response>;
  /** insert a single row into the table: "externalLocation" */
  insert_externalLocation_one?: Maybe<ExternalLocation>;
  /** insert data into the table: "externalProvider" */
  insert_externalProvider?: Maybe<ExternalProvider_Mutation_Response>;
  /** insert a single row into the table: "externalProvider" */
  insert_externalProvider_one?: Maybe<ExternalProvider>;
  /** insert data into the table: "externalReview" */
  insert_externalReview?: Maybe<ExternalReview_Mutation_Response>;
  /** insert a single row into the table: "externalReview" */
  insert_externalReview_one?: Maybe<ExternalReview>;
  /** insert data into the table: "fee" */
  insert_fee?: Maybe<Fee_Mutation_Response>;
  /** insert data into the table: "feeInvoiceLineItem" */
  insert_feeInvoiceLineItem?: Maybe<FeeInvoiceLineItem_Mutation_Response>;
  /** insert a single row into the table: "feeInvoiceLineItem" */
  insert_feeInvoiceLineItem_one?: Maybe<FeeInvoiceLineItem>;
  /** insert a single row into the table: "fee" */
  insert_fee_one?: Maybe<Fee>;
  /** insert data into the table: "finixTerminal" */
  insert_finixTerminal?: Maybe<FinixTerminal_Mutation_Response>;
  /** insert a single row into the table: "finixTerminal" */
  insert_finixTerminal_one?: Maybe<FinixTerminal>;
  /** insert data into the table: "flowsheet" */
  insert_flowsheet?: Maybe<Flowsheet_Mutation_Response>;
  /** insert data into the table: "flowsheetParameter" */
  insert_flowsheetParameter?: Maybe<FlowsheetParameter_Mutation_Response>;
  /** insert data into the table: "flowsheetParameterFlowsheet" */
  insert_flowsheetParameterFlowsheet?: Maybe<FlowsheetParameterFlowsheet_Mutation_Response>;
  /** insert a single row into the table: "flowsheetParameterFlowsheet" */
  insert_flowsheetParameterFlowsheet_one?: Maybe<FlowsheetParameterFlowsheet>;
  /** insert data into the table: "flowsheetParameterGroup" */
  insert_flowsheetParameterGroup?: Maybe<FlowsheetParameterGroup_Mutation_Response>;
  /** insert a single row into the table: "flowsheetParameterGroup" */
  insert_flowsheetParameterGroup_one?: Maybe<FlowsheetParameterGroup>;
  /** insert a single row into the table: "flowsheetParameter" */
  insert_flowsheetParameter_one?: Maybe<FlowsheetParameter>;
  /** insert a single row into the table: "flowsheet" */
  insert_flowsheet_one?: Maybe<Flowsheet>;
  /** insert data into the table: "genericInvoiceLineItem" */
  insert_genericInvoiceLineItem?: Maybe<GenericInvoiceLineItem_Mutation_Response>;
  /** insert data into the table: "genericInvoiceLineItemDiscount" */
  insert_genericInvoiceLineItemDiscount?: Maybe<GenericInvoiceLineItemDiscount_Mutation_Response>;
  /** insert a single row into the table: "genericInvoiceLineItemDiscount" */
  insert_genericInvoiceLineItemDiscount_one?: Maybe<GenericInvoiceLineItemDiscount>;
  /** insert a single row into the table: "genericInvoiceLineItem" */
  insert_genericInvoiceLineItem_one?: Maybe<GenericInvoiceLineItem>;
  /** insert data into the table: "giftCard" */
  insert_giftCard?: Maybe<GiftCard_Mutation_Response>;
  /** insert a single row into the table: "giftCard" */
  insert_giftCard_one?: Maybe<GiftCard>;
  /** insert data into the table: "googleAccount" */
  insert_googleAccount?: Maybe<GoogleAccount_Mutation_Response>;
  /** insert a single row into the table: "googleAccount" */
  insert_googleAccount_one?: Maybe<GoogleAccount>;
  /** insert data into the table: "googleLocation" */
  insert_googleLocation?: Maybe<GoogleLocation_Mutation_Response>;
  /** insert a single row into the table: "googleLocation" */
  insert_googleLocation_one?: Maybe<GoogleLocation>;
  /** insert data into the table: "googleReview" */
  insert_googleReview?: Maybe<GoogleReview_Mutation_Response>;
  /** insert a single row into the table: "googleReview" */
  insert_googleReview_one?: Maybe<GoogleReview>;
  /** insert data into the table: "importJob" */
  insert_importJob?: Maybe<ImportJob_Mutation_Response>;
  /** insert a single row into the table: "importJob" */
  insert_importJob_one?: Maybe<ImportJob>;
  /** insert data into the table: "importMetadata" */
  insert_importMetadata?: Maybe<ImportMetadata_Mutation_Response>;
  /** insert a single row into the table: "importMetadata" */
  insert_importMetadata_one?: Maybe<ImportMetadata>;
  /** insert data into the table: "incompatibleServices" */
  insert_incompatibleServices?: Maybe<IncompatibleServices_Mutation_Response>;
  /** insert a single row into the table: "incompatibleServices" */
  insert_incompatibleServices_one?: Maybe<IncompatibleServices>;
  /** insert data into the table: "internalAppointmentProvider" */
  insert_internalAppointmentProvider?: Maybe<InternalAppointmentProvider_Mutation_Response>;
  /** insert a single row into the table: "internalAppointmentProvider" */
  insert_internalAppointmentProvider_one?: Maybe<InternalAppointmentProvider>;
  /** insert data into the table: "inventoryTransaction" */
  insert_inventoryTransaction?: Maybe<InventoryTransaction_Mutation_Response>;
  /** insert a single row into the table: "inventoryTransaction" */
  insert_inventoryTransaction_one?: Maybe<InventoryTransaction>;
  /** insert data into the table: "invoice" */
  insert_invoice?: Maybe<Invoice_Mutation_Response>;
  /** insert data into the table: "invoiceConfiguration" */
  insert_invoiceConfiguration?: Maybe<InvoiceConfiguration_Mutation_Response>;
  /** insert a single row into the table: "invoiceConfiguration" */
  insert_invoiceConfiguration_one?: Maybe<InvoiceConfiguration>;
  /** insert data into the table: "invoiceCoupon" */
  insert_invoiceCoupon?: Maybe<InvoiceCoupon_Mutation_Response>;
  /** insert a single row into the table: "invoiceCoupon" */
  insert_invoiceCoupon_one?: Maybe<InvoiceCoupon>;
  /** insert data into the table: "invoiceMembership" */
  insert_invoiceMembership?: Maybe<InvoiceMembership_Mutation_Response>;
  /** insert a single row into the table: "invoiceMembership" */
  insert_invoiceMembership_one?: Maybe<InvoiceMembership>;
  /** insert data into the table: "invoicePackage" */
  insert_invoicePackage?: Maybe<InvoicePackage_Mutation_Response>;
  /** insert a single row into the table: "invoicePackage" */
  insert_invoicePackage_one?: Maybe<InvoicePackage>;
  /** insert data into the table: "invoiceProductItem" */
  insert_invoiceProductItem?: Maybe<InvoiceProductItem_Mutation_Response>;
  /** insert a single row into the table: "invoiceProductItem" */
  insert_invoiceProductItem_one?: Maybe<InvoiceProductItem>;
  /** insert data into the table: "invoiceServiceConsumable" */
  insert_invoiceServiceConsumable?: Maybe<InvoiceServiceConsumable_Mutation_Response>;
  /** insert a single row into the table: "invoiceServiceConsumable" */
  insert_invoiceServiceConsumable_one?: Maybe<InvoiceServiceConsumable>;
  /** insert data into the table: "invoiceServiceItem" */
  insert_invoiceServiceItem?: Maybe<InvoiceServiceItem_Mutation_Response>;
  /** insert a single row into the table: "invoiceServiceItem" */
  insert_invoiceServiceItem_one?: Maybe<InvoiceServiceItem>;
  /** insert data into the table: "invoiceTemplate" */
  insert_invoiceTemplate?: Maybe<InvoiceTemplate_Mutation_Response>;
  /** insert a single row into the table: "invoiceTemplate" */
  insert_invoiceTemplate_one?: Maybe<InvoiceTemplate>;
  /** insert a single row into the table: "invoice" */
  insert_invoice_one?: Maybe<Invoice>;
  /** insert data into the table: "job" */
  insert_job?: Maybe<Job_Mutation_Response>;
  /** insert data into the table: "jobStatus" */
  insert_jobStatus?: Maybe<JobStatus_Mutation_Response>;
  /** insert a single row into the table: "jobStatus" */
  insert_jobStatus_one?: Maybe<JobStatus>;
  /** insert a single row into the table: "job" */
  insert_job_one?: Maybe<Job>;
  /** insert data into the table: "labResult" */
  insert_labResult?: Maybe<LabResult_Mutation_Response>;
  /** insert a single row into the table: "labResult" */
  insert_labResult_one?: Maybe<LabResult>;
  /** insert data into the table: "leadForm" */
  insert_leadForm?: Maybe<LeadForm_Mutation_Response>;
  /** insert data into the table: "leadFormEntry" */
  insert_leadFormEntry?: Maybe<LeadFormEntry_Mutation_Response>;
  /** insert data into the table: "leadFormEntryTag" */
  insert_leadFormEntryTag?: Maybe<LeadFormEntryTag_Mutation_Response>;
  /** insert a single row into the table: "leadFormEntryTag" */
  insert_leadFormEntryTag_one?: Maybe<LeadFormEntryTag>;
  /** insert a single row into the table: "leadFormEntry" */
  insert_leadFormEntry_one?: Maybe<LeadFormEntry>;
  /** insert data into the table: "leadFormField" */
  insert_leadFormField?: Maybe<LeadFormField_Mutation_Response>;
  /** insert data into the table: "leadFormFieldValue" */
  insert_leadFormFieldValue?: Maybe<LeadFormFieldValue_Mutation_Response>;
  /** insert a single row into the table: "leadFormFieldValue" */
  insert_leadFormFieldValue_one?: Maybe<LeadFormFieldValue>;
  /** insert a single row into the table: "leadFormField" */
  insert_leadFormField_one?: Maybe<LeadFormField>;
  /** insert data into the table: "leadFormLink" */
  insert_leadFormLink?: Maybe<LeadFormLink_Mutation_Response>;
  /** insert a single row into the table: "leadFormLink" */
  insert_leadFormLink_one?: Maybe<LeadFormLink>;
  /** insert data into the table: "leadFormStep" */
  insert_leadFormStep?: Maybe<LeadFormStep_Mutation_Response>;
  /** insert a single row into the table: "leadFormStep" */
  insert_leadFormStep_one?: Maybe<LeadFormStep>;
  /** insert a single row into the table: "leadForm" */
  insert_leadForm_one?: Maybe<LeadForm>;
  /** insert data into the table: "leadTag" */
  insert_leadTag?: Maybe<LeadTag_Mutation_Response>;
  /** insert a single row into the table: "leadTag" */
  insert_leadTag_one?: Maybe<LeadTag>;
  /** insert data into the table: "leadTrackedEvent" */
  insert_leadTrackedEvent?: Maybe<LeadTrackedEvent_Mutation_Response>;
  /** insert a single row into the table: "leadTrackedEvent" */
  insert_leadTrackedEvent_one?: Maybe<LeadTrackedEvent>;
  /** insert data into the table: "location" */
  insert_location?: Maybe<Location_Mutation_Response>;
  /** insert data into the table: "locationCoupon" */
  insert_locationCoupon?: Maybe<LocationCoupon_Mutation_Response>;
  /** insert a single row into the table: "locationCoupon" */
  insert_locationCoupon_one?: Maybe<LocationCoupon>;
  /** insert data into the table: "locationProvider" */
  insert_locationProvider?: Maybe<LocationProvider_Mutation_Response>;
  /** insert a single row into the table: "locationProvider" */
  insert_locationProvider_one?: Maybe<LocationProvider>;
  /** insert data into the table: "locationSchedule" */
  insert_locationSchedule?: Maybe<LocationSchedule_Mutation_Response>;
  /** insert a single row into the table: "locationSchedule" */
  insert_locationSchedule_one?: Maybe<LocationSchedule>;
  /** insert data into the table: "locationService" */
  insert_locationService?: Maybe<LocationService_Mutation_Response>;
  /** insert a single row into the table: "locationService" */
  insert_locationService_one?: Maybe<LocationService>;
  /** insert a single row into the table: "location" */
  insert_location_one?: Maybe<Location>;
  /** insert data into the table: "marketingTag" */
  insert_marketingTag?: Maybe<MarketingTag_Mutation_Response>;
  /** insert data into the table: "marketingTagEmail" */
  insert_marketingTagEmail?: Maybe<MarketingTagEmail_Mutation_Response>;
  /** insert a single row into the table: "marketingTagEmail" */
  insert_marketingTagEmail_one?: Maybe<MarketingTagEmail>;
  /** insert data into the table: "marketingTagSms" */
  insert_marketingTagSms?: Maybe<MarketingTagSms_Mutation_Response>;
  /** insert a single row into the table: "marketingTagSms" */
  insert_marketingTagSms_one?: Maybe<MarketingTagSms>;
  /** insert a single row into the table: "marketingTag" */
  insert_marketingTag_one?: Maybe<MarketingTag>;
  /** insert data into the table: "mediaTag" */
  insert_mediaTag?: Maybe<MediaTag_Mutation_Response>;
  /** insert a single row into the table: "mediaTag" */
  insert_mediaTag_one?: Maybe<MediaTag>;
  /** insert data into the table: "membership" */
  insert_membership?: Maybe<Membership_Mutation_Response>;
  /** insert data into the table: "membershipCoupon" */
  insert_membershipCoupon?: Maybe<MembershipCoupon_Mutation_Response>;
  /** insert a single row into the table: "membershipCoupon" */
  insert_membershipCoupon_one?: Maybe<MembershipCoupon>;
  /** insert data into the table: "membershipType" */
  insert_membershipType?: Maybe<MembershipType_Mutation_Response>;
  /** insert a single row into the table: "membershipType" */
  insert_membershipType_one?: Maybe<MembershipType>;
  /** insert a single row into the table: "membership" */
  insert_membership_one?: Maybe<Membership>;
  /** insert data into the table: "note" */
  insert_note?: Maybe<Note_Mutation_Response>;
  /** insert data into the table: "noteSignature" */
  insert_noteSignature?: Maybe<NoteSignature_Mutation_Response>;
  /** insert a single row into the table: "noteSignature" */
  insert_noteSignature_one?: Maybe<NoteSignature>;
  /** insert a single row into the table: "note" */
  insert_note_one?: Maybe<Note>;
  /** insert data into the table: "organization" */
  insert_organization?: Maybe<Organization_Mutation_Response>;
  /** insert data into the table: "organizationConfiguration" */
  insert_organizationConfiguration?: Maybe<OrganizationConfiguration_Mutation_Response>;
  /** insert a single row into the table: "organizationConfiguration" */
  insert_organizationConfiguration_one?: Maybe<OrganizationConfiguration>;
  /** insert a single row into the table: "organization" */
  insert_organization_one?: Maybe<Organization>;
  /** insert data into the table: "package" */
  insert_package?: Maybe<Package_Mutation_Response>;
  /** insert a single row into the table: "package" */
  insert_package_one?: Maybe<Package>;
  /** insert data into the table: "patient" */
  insert_patient?: Maybe<Patient_Mutation_Response>;
  /** insert data into the table: "patientAddress" */
  insert_patientAddress?: Maybe<PatientAddress_Mutation_Response>;
  /** insert a single row into the table: "patientAddress" */
  insert_patientAddress_one?: Maybe<PatientAddress>;
  /** insert data into the table: "patientConsent" */
  insert_patientConsent?: Maybe<PatientConsent_Mutation_Response>;
  /** insert a single row into the table: "patientConsent" */
  insert_patientConsent_one?: Maybe<PatientConsent>;
  /** insert data into the table: "patientConversation" */
  insert_patientConversation?: Maybe<PatientConversation_Mutation_Response>;
  /** insert a single row into the table: "patientConversation" */
  insert_patientConversation_one?: Maybe<PatientConversation>;
  /** insert data into the table: "patientFlowsheetData" */
  insert_patientFlowsheetData?: Maybe<PatientFlowsheetData_Mutation_Response>;
  /** insert a single row into the table: "patientFlowsheetData" */
  insert_patientFlowsheetData_one?: Maybe<PatientFlowsheetData>;
  /** insert data into the table: "patientFlowsheetRow" */
  insert_patientFlowsheetRow?: Maybe<PatientFlowsheetRow_Mutation_Response>;
  /** insert a single row into the table: "patientFlowsheetRow" */
  insert_patientFlowsheetRow_one?: Maybe<PatientFlowsheetRow>;
  /** insert data into the table: "patientInvite" */
  insert_patientInvite?: Maybe<PatientInvite_Mutation_Response>;
  /** insert a single row into the table: "patientInvite" */
  insert_patientInvite_one?: Maybe<PatientInvite>;
  /** insert data into the table: "patientMembership" */
  insert_patientMembership?: Maybe<PatientMembership_Mutation_Response>;
  /** insert a single row into the table: "patientMembership" */
  insert_patientMembership_one?: Maybe<PatientMembership>;
  /** insert data into the table: "patientSystemNotice" */
  insert_patientSystemNotice?: Maybe<PatientSystemNotice_Mutation_Response>;
  /** insert a single row into the table: "patientSystemNotice" */
  insert_patientSystemNotice_one?: Maybe<PatientSystemNotice>;
  /** insert data into the table: "patientTag" */
  insert_patientTag?: Maybe<PatientTag_Mutation_Response>;
  /** insert a single row into the table: "patientTag" */
  insert_patientTag_one?: Maybe<PatientTag>;
  /** insert data into the table: "patientTags" */
  insert_patientTags?: Maybe<PatientTags_Mutation_Response>;
  /** insert a single row into the table: "patientTags" */
  insert_patientTags_one?: Maybe<PatientTags>;
  /** insert data into the table: "patientWallet" */
  insert_patientWallet?: Maybe<PatientWallet_Mutation_Response>;
  /** insert data into the table: "patientWalletCoupon" */
  insert_patientWalletCoupon?: Maybe<PatientWalletCoupon_Mutation_Response>;
  /** insert a single row into the table: "patientWalletCoupon" */
  insert_patientWalletCoupon_one?: Maybe<PatientWalletCoupon>;
  /** insert data into the table: "patientWalletTransaction" */
  insert_patientWalletTransaction?: Maybe<PatientWalletTransaction_Mutation_Response>;
  /** insert a single row into the table: "patientWalletTransaction" */
  insert_patientWalletTransaction_one?: Maybe<PatientWalletTransaction>;
  /** insert a single row into the table: "patientWallet" */
  insert_patientWallet_one?: Maybe<PatientWallet>;
  /** insert data into the table: "patientWorkspace" */
  insert_patientWorkspace?: Maybe<PatientWorkspace_Mutation_Response>;
  /** insert a single row into the table: "patientWorkspace" */
  insert_patientWorkspace_one?: Maybe<PatientWorkspace>;
  /** insert a single row into the table: "patient" */
  insert_patient_one?: Maybe<Patient>;
  /** insert data into the table: "payment" */
  insert_payment?: Maybe<Payment_Mutation_Response>;
  /** insert data into the table: "paymentMethod" */
  insert_paymentMethod?: Maybe<PaymentMethod_Mutation_Response>;
  /** insert a single row into the table: "paymentMethod" */
  insert_paymentMethod_one?: Maybe<PaymentMethod>;
  /** insert data into the table: "paymentRelationship" */
  insert_paymentRelationship?: Maybe<PaymentRelationship_Mutation_Response>;
  /** insert a single row into the table: "paymentRelationship" */
  insert_paymentRelationship_one?: Maybe<PaymentRelationship>;
  /** insert a single row into the table: "payment" */
  insert_payment_one?: Maybe<Payment>;
  /** insert data into the table: "payrixTerminal" */
  insert_payrixTerminal?: Maybe<PayrixTerminal_Mutation_Response>;
  /** insert data into the table: "payrixTerminalTransaction" */
  insert_payrixTerminalTransaction?: Maybe<PayrixTerminalTransaction_Mutation_Response>;
  /** insert a single row into the table: "payrixTerminalTransaction" */
  insert_payrixTerminalTransaction_one?: Maybe<PayrixTerminalTransaction>;
  /** insert a single row into the table: "payrixTerminal" */
  insert_payrixTerminal_one?: Maybe<PayrixTerminal>;
  /** insert data into the table: "payrixWebAlert" */
  insert_payrixWebAlert?: Maybe<PayrixWebAlert_Mutation_Response>;
  /** insert a single row into the table: "payrixWebAlert" */
  insert_payrixWebAlert_one?: Maybe<PayrixWebAlert>;
  /** insert data into the table: "photo" */
  insert_photo?: Maybe<Photo_Mutation_Response>;
  /** insert data into the table: "photoMediaTag" */
  insert_photoMediaTag?: Maybe<PhotoMediaTag_Mutation_Response>;
  /** insert a single row into the table: "photoMediaTag" */
  insert_photoMediaTag_one?: Maybe<PhotoMediaTag>;
  /** insert a single row into the table: "photo" */
  insert_photo_one?: Maybe<Photo>;
  /** insert data into the table: "pin" */
  insert_pin?: Maybe<Pin_Mutation_Response>;
  /** insert a single row into the table: "pin" */
  insert_pin_one?: Maybe<Pin>;
  /** insert data into the table: "postStatus" */
  insert_postStatus?: Maybe<PostStatus_Mutation_Response>;
  /** insert a single row into the table: "postStatus" */
  insert_postStatus_one?: Maybe<PostStatus>;
  /** insert data into the table: "prescription" */
  insert_prescription?: Maybe<Prescription_Mutation_Response>;
  /** insert data into the table: "prescriptionDetail" */
  insert_prescriptionDetail?: Maybe<PrescriptionDetail_Mutation_Response>;
  /** insert a single row into the table: "prescriptionDetail" */
  insert_prescriptionDetail_one?: Maybe<PrescriptionDetail>;
  /** insert a single row into the table: "prescription" */
  insert_prescription_one?: Maybe<Prescription>;
  /** insert data into the table: "promotion" */
  insert_promotion?: Maybe<Promotion_Mutation_Response>;
  /** insert data into the table: "promotionCoupons" */
  insert_promotionCoupons?: Maybe<PromotionCoupons_Mutation_Response>;
  /** insert a single row into the table: "promotionCoupons" */
  insert_promotionCoupons_one?: Maybe<PromotionCoupons>;
  /** insert data into the table: "promotionServices" */
  insert_promotionServices?: Maybe<PromotionServices_Mutation_Response>;
  /** insert a single row into the table: "promotionServices" */
  insert_promotionServices_one?: Maybe<PromotionServices>;
  /** insert a single row into the table: "promotion" */
  insert_promotion_one?: Maybe<Promotion>;
  /** insert data into the table: "provider" */
  insert_provider?: Maybe<Provider_Mutation_Response>;
  /** insert data into the table: "providerSchedule" */
  insert_providerSchedule?: Maybe<ProviderSchedule_Mutation_Response>;
  /** insert a single row into the table: "providerSchedule" */
  insert_providerSchedule_one?: Maybe<ProviderSchedule>;
  /** insert a single row into the table: "provider" */
  insert_provider_one?: Maybe<Provider>;
  /** insert data into the table: "purchaseOrder" */
  insert_purchaseOrder?: Maybe<PurchaseOrder_Mutation_Response>;
  /** insert a single row into the table: "purchaseOrder" */
  insert_purchaseOrder_one?: Maybe<PurchaseOrder>;
  /** insert data into the table: "recurrenceType" */
  insert_recurrenceType?: Maybe<RecurrenceType_Mutation_Response>;
  /** insert a single row into the table: "recurrenceType" */
  insert_recurrenceType_one?: Maybe<RecurrenceType>;
  /** insert data into the table: "refund" */
  insert_refund?: Maybe<Refund_Mutation_Response>;
  /** insert a single row into the table: "refund" */
  insert_refund_one?: Maybe<Refund>;
  /** insert data into the table: "resolutionStatus" */
  insert_resolutionStatus?: Maybe<ResolutionStatus_Mutation_Response>;
  /** insert a single row into the table: "resolutionStatus" */
  insert_resolutionStatus_one?: Maybe<ResolutionStatus>;
  /** insert data into the table: "rewardProgram" */
  insert_rewardProgram?: Maybe<RewardProgram_Mutation_Response>;
  /** insert data into the table: "rewardProgramEnrollment" */
  insert_rewardProgramEnrollment?: Maybe<RewardProgramEnrollment_Mutation_Response>;
  /** insert a single row into the table: "rewardProgramEnrollment" */
  insert_rewardProgramEnrollment_one?: Maybe<RewardProgramEnrollment>;
  /** insert a single row into the table: "rewardProgram" */
  insert_rewardProgram_one?: Maybe<RewardProgram>;
  /** insert data into the table: "room" */
  insert_room?: Maybe<Room_Mutation_Response>;
  /** insert a single row into the table: "room" */
  insert_room_one?: Maybe<Room>;
  /** insert data into the table: "rsReview" */
  insert_rsReview?: Maybe<RsReview_Mutation_Response>;
  /** insert a single row into the table: "rsReview" */
  insert_rsReview_one?: Maybe<RsReview>;
  /** insert data into the table: "service" */
  insert_service?: Maybe<Service_Mutation_Response>;
  /** insert data into the table: "serviceAddOn" */
  insert_serviceAddOn?: Maybe<ServiceAddOn_Mutation_Response>;
  /** insert a single row into the table: "serviceAddOn" */
  insert_serviceAddOn_one?: Maybe<ServiceAddOn>;
  /** insert data into the table: "serviceCategory" */
  insert_serviceCategory?: Maybe<ServiceCategory_Mutation_Response>;
  /** insert a single row into the table: "serviceCategory" */
  insert_serviceCategory_one?: Maybe<ServiceCategory>;
  /** insert data into the table: "serviceConsent" */
  insert_serviceConsent?: Maybe<ServiceConsent_Mutation_Response>;
  /** insert a single row into the table: "serviceConsent" */
  insert_serviceConsent_one?: Maybe<ServiceConsent>;
  /** insert data into the table: "serviceConsumableType" */
  insert_serviceConsumableType?: Maybe<ServiceConsumableType_Mutation_Response>;
  /** insert a single row into the table: "serviceConsumableType" */
  insert_serviceConsumableType_one?: Maybe<ServiceConsumableType>;
  /** insert data into the table: "serviceCoupon" */
  insert_serviceCoupon?: Maybe<ServiceCoupon_Mutation_Response>;
  /** insert a single row into the table: "serviceCoupon" */
  insert_serviceCoupon_one?: Maybe<ServiceCoupon>;
  /** insert data into the table: "serviceCustomForm" */
  insert_serviceCustomForm?: Maybe<ServiceCustomForm_Mutation_Response>;
  /** insert a single row into the table: "serviceCustomForm" */
  insert_serviceCustomForm_one?: Maybe<ServiceCustomForm>;
  /** insert data into the table: "serviceDeviceType" */
  insert_serviceDeviceType?: Maybe<ServiceDeviceType_Mutation_Response>;
  /** insert a single row into the table: "serviceDeviceType" */
  insert_serviceDeviceType_one?: Maybe<ServiceDeviceType>;
  /** insert data into the table: "serviceProvider" */
  insert_serviceProvider?: Maybe<ServiceProvider_Mutation_Response>;
  /** insert a single row into the table: "serviceProvider" */
  insert_serviceProvider_one?: Maybe<ServiceProvider>;
  /** insert data into the table: "serviceUnit" */
  insert_serviceUnit?: Maybe<ServiceUnit_Mutation_Response>;
  /** insert a single row into the table: "serviceUnit" */
  insert_serviceUnit_one?: Maybe<ServiceUnit>;
  /** insert a single row into the table: "service" */
  insert_service_one?: Maybe<Service>;
  /** insert data into the table: "shrink" */
  insert_shrink?: Maybe<Shrink_Mutation_Response>;
  /** insert a single row into the table: "shrink" */
  insert_shrink_one?: Maybe<Shrink>;
  /** insert data into the table: "signature" */
  insert_signature?: Maybe<Signature_Mutation_Response>;
  /** insert a single row into the table: "signature" */
  insert_signature_one?: Maybe<Signature>;
  /** insert data into the table: "smartNote" */
  insert_smartNote?: Maybe<SmartNote_Mutation_Response>;
  /** insert a single row into the table: "smartNote" */
  insert_smartNote_one?: Maybe<SmartNote>;
  /** insert data into the table: "smsTemplate" */
  insert_smsTemplate?: Maybe<SmsTemplate_Mutation_Response>;
  /** insert data into the table: "smsTemplatePatient" */
  insert_smsTemplatePatient?: Maybe<SmsTemplatePatient_Mutation_Response>;
  /** insert a single row into the table: "smsTemplatePatient" */
  insert_smsTemplatePatient_one?: Maybe<SmsTemplatePatient>;
  /** insert data into the table: "smsTemplateSendQueue" */
  insert_smsTemplateSendQueue?: Maybe<SmsTemplateSendQueue_Mutation_Response>;
  /** insert a single row into the table: "smsTemplateSendQueue" */
  insert_smsTemplateSendQueue_one?: Maybe<SmsTemplateSendQueue>;
  /** insert a single row into the table: "smsTemplate" */
  insert_smsTemplate_one?: Maybe<SmsTemplate>;
  /** insert data into the table: "socialMediaAccount" */
  insert_socialMediaAccount?: Maybe<SocialMediaAccount_Mutation_Response>;
  /** insert data into the table: "socialMediaAccountPost" */
  insert_socialMediaAccountPost?: Maybe<SocialMediaAccountPost_Mutation_Response>;
  /** insert a single row into the table: "socialMediaAccountPost" */
  insert_socialMediaAccountPost_one?: Maybe<SocialMediaAccountPost>;
  /** insert a single row into the table: "socialMediaAccount" */
  insert_socialMediaAccount_one?: Maybe<SocialMediaAccount>;
  /** insert data into the table: "socialMediaPlatform" */
  insert_socialMediaPlatform?: Maybe<SocialMediaPlatform_Mutation_Response>;
  /** insert a single row into the table: "socialMediaPlatform" */
  insert_socialMediaPlatform_one?: Maybe<SocialMediaPlatform>;
  /** insert data into the table: "socialMediaPost" */
  insert_socialMediaPost?: Maybe<SocialMediaPost_Mutation_Response>;
  /** insert a single row into the table: "socialMediaPost" */
  insert_socialMediaPost_one?: Maybe<SocialMediaPost>;
  /** insert data into the table: "tag" */
  insert_tag?: Maybe<Tag_Mutation_Response>;
  /** insert a single row into the table: "tag" */
  insert_tag_one?: Maybe<Tag>;
  /** insert data into the table: "task" */
  insert_task?: Maybe<Task_Mutation_Response>;
  /** insert data into the table: "taskComment" */
  insert_taskComment?: Maybe<TaskComment_Mutation_Response>;
  /** insert a single row into the table: "taskComment" */
  insert_taskComment_one?: Maybe<TaskComment>;
  /** insert a single row into the table: "task" */
  insert_task_one?: Maybe<Task>;
  /** insert data into the table: "telehealthMeeting" */
  insert_telehealthMeeting?: Maybe<TelehealthMeeting_Mutation_Response>;
  /** insert a single row into the table: "telehealthMeeting" */
  insert_telehealthMeeting_one?: Maybe<TelehealthMeeting>;
  /** insert data into the table: "unusedAppointmentServiceConsumableType" */
  insert_unusedAppointmentServiceConsumableType?: Maybe<UnusedAppointmentServiceConsumableType_Mutation_Response>;
  /** insert a single row into the table: "unusedAppointmentServiceConsumableType" */
  insert_unusedAppointmentServiceConsumableType_one?: Maybe<UnusedAppointmentServiceConsumableType>;
  /** insert data into the table: "user" */
  insert_user?: Maybe<User_Mutation_Response>;
  /** insert data into the table: "userInvite" */
  insert_userInvite?: Maybe<UserInvite_Mutation_Response>;
  /** insert a single row into the table: "userInvite" */
  insert_userInvite_one?: Maybe<UserInvite>;
  /** insert data into the table: "userOrganization" */
  insert_userOrganization?: Maybe<UserOrganization_Mutation_Response>;
  /** insert a single row into the table: "userOrganization" */
  insert_userOrganization_one?: Maybe<UserOrganization>;
  /** insert data into the table: "userWorkspace" */
  insert_userWorkspace?: Maybe<UserWorkspace_Mutation_Response>;
  /** insert a single row into the table: "userWorkspace" */
  insert_userWorkspace_one?: Maybe<UserWorkspace>;
  /** insert a single row into the table: "user" */
  insert_user_one?: Maybe<User>;
  /** insert data into the table: "vitals" */
  insert_vitals?: Maybe<Vitals_Mutation_Response>;
  /** insert a single row into the table: "vitals" */
  insert_vitals_one?: Maybe<Vitals>;
  /** insert data into the table: "workspace" */
  insert_workspace?: Maybe<Workspace_Mutation_Response>;
  /** insert data into the table: "workspaceConfiguration" */
  insert_workspaceConfiguration?: Maybe<WorkspaceConfiguration_Mutation_Response>;
  /** insert a single row into the table: "workspaceConfiguration" */
  insert_workspaceConfiguration_one?: Maybe<WorkspaceConfiguration>;
  /** insert a single row into the table: "workspace" */
  insert_workspace_one?: Maybe<Workspace>;
  /** insert data into the table: "zdReview" */
  insert_zdReview?: Maybe<ZdReview_Mutation_Response>;
  /** insert a single row into the table: "zdReview" */
  insert_zdReview_one?: Maybe<ZdReview>;
  lumaCapture?: Maybe<LumaCaptureOutput>;
  manuallyUpdatePatientWalletBalance: SuccessAnswer;
  mapToErxAction?: Maybe<MapPatientWorkspaceToErxResponse>;
  markAsPaid: SuccessAnswer;
  mergePatient?: Maybe<SuccessAnswer>;
  migrateLeadForms?: Maybe<SuccessAnswer>;
  migrateTwilioConversations?: Maybe<SuccessAnswer>;
  migrateVitals: SuccessAnswer;
  /** nylasWebhook */
  nylasWebhook: NylasWebhookOutput;
  onboardPatient: SuccessAnswer;
  overrideAppointmentNoShowFee?: Maybe<SuccessAnswer>;
  payAppointmentDeposit: PayAppointmentDepositOutput;
  payInvoice?: Maybe<PayInvoiceWithTokenOutput>;
  /** payInvoiceWithToken */
  payInvoiceWithToken?: Maybe<PayInvoiceWithTokenOutput>;
  payWithExistingCard?: Maybe<IdResponse>;
  payWithExistingMethod: SuccessAnswer;
  payWithNewMethod: SuccessAnswer;
  paymentFailedLater: SuccessAnswer;
  /** populateStaticAudience */
  populateStaticAudience?: Maybe<SuccessAnswer>;
  purchaseFromStorefront?: Maybe<PurchaseFromStorefrontOutput>;
  purchaseGiftCard?: Maybe<SuccessAnswer>;
  refundOrVoidPayrixTransaction: PayrixTransaction;
  refundPayment: RefundPaymentOutput;
  regenerateAppointmentReminders?: Maybe<SuccessAnswer>;
  regenerateInvoiceTotals: SuccessAnswer;
  regeneratePrePostCareMessages?: Maybe<SuccessAnswer>;
  replyToGoogleReview: ReplyToGoogleReviewOutput;
  /** resendActiveAudiences */
  resendActiveAudiences?: Maybe<SuccessAnswer>;
  /** Resending user invite */
  resendUserInvite?: Maybe<SuccessAnswer>;
  resolveAppointmentServiceConsumable?: Maybe<ResolveAppointmentServiceConsumableOutput>;
  resolveInvoiceConsumables: ResolveInvoiceConsumablesOutput;
  retractInvoiceMembership: SuccessAnswer;
  retractInvoicePackage: SuccessAnswer;
  retryCreateSubscription: SuccessAnswer;
  reverseAuthorizationHold?: Maybe<IdResponse>;
  revertToMediaVersion?: Maybe<RevertToMediaVersionOutput>;
  sandbox: SandboxOutput;
  sandboxMagicLink: SandboxMagicLinkOutput;
  saveUrlToPatientPhotos?: Maybe<SuccessAnswer>;
  scheduleSmsSend?: Maybe<SuccessAnswer>;
  /** seedDemoReviews */
  seedDemoReviews?: Maybe<SeedDemoReviewsOutput>;
  /** seedDemoWorkspace */
  seedDemoWorkspace?: Maybe<SeedDemoWorkspaceOutput>;
  sendAppointmentRescheduleConfirmation?: Maybe<SuccessAnswer>;
  /** Send document to patient */
  sendDocumentToPatientEmail?: Maybe<SuccessAnswer>;
  /** Payload will be parsed as JSON */
  sendInngestEvent?: Maybe<SuccessAnswer>;
  sendInviteLink?: Maybe<SendInviteLinkResponse>;
  /** Send invoice payment link to patient */
  sendInvoicePaymentLink?: Maybe<SendPaymentInvoiceLinkOutput>;
  sendInvoiceReceiptToPatient: SuccessAnswer;
  /** Send invoice to patient */
  sendInvoiceToPatient?: Maybe<SuccessAnswer>;
  sendLeadFormLink?: Maybe<SuccessAnswer>;
  sendPatientInvite: SuccessAnswer;
  sendPatientLoginLink: SuccessAnswer;
  sendPaymentToFinixDevice?: Maybe<SendPaymentToFinixDeviceOutput>;
  sendSmsTemplate?: Maybe<SuccessAnswer>;
  sendTransactionToTerminal?: Maybe<TerminalTransactionReceiptResponse>;
  setBillingPatientAddress?: Maybe<Array<SetBillingPatientAddressOutput>>;
  setDefaultPatientAddress?: Maybe<Array<SetDefaultPatientAddressOutput>>;
  /** Health Gorilla Subscriptions Setup */
  setupHealthGorillaSubscriptions: HealthGorillaSubscriptionsOutput;
  simpleUpdateInvoice: SimpleUpdateInvoiceOutput;
  submitLeadFormFields?: Maybe<LeadFormSubmitOutput>;
  submitPin?: Maybe<SuccessAnswer>;
  submitSurveyJSForm?: Maybe<SubmitSurveyJsOutput>;
  testSmsTemplate?: Maybe<SuccessAnswer>;
  tradeJWTToken?: Maybe<TradeJwtTokenOutput>;
  transcribeAudio?: Maybe<TranscribeAudioOutput>;
  transformInvoiceTemplates: SuccessAnswer;
  unenrollProviderERx: SuccessAnswer;
  updateAppointmentAndNotify?: Maybe<UpdateAppointmentWithNotifyOutput>;
  updateAppointmentServiceConsumable: UpdateAppointmentServiceConsumableOutput;
  updateAppointmentServices?: Maybe<Array<Maybe<UpdateAppointmentServicesOutput>>>;
  updateCompensationPolicy: UpdateCompensationPolicyOutput;
  updateCompensationStrategy: UpdateCompensationStrategyOutput;
  updateConsumableQuantityAtLocation: SuccessAnswer;
  updateDocument: UpdateDocumentOutput;
  updateFlowsheet: UpdateFlowsheetOutput;
  updateImage?: Maybe<UpdateImageOutput>;
  updatePatientWalletCoupons: UpdatePatientWalletCouponsOutput;
  updateSubscriptionPaymentMethod: SuccessAnswer;
  /** update data of the table: "activityLog" */
  update_activityLog?: Maybe<ActivityLog_Mutation_Response>;
  /** update single row of the table: "activityLog" */
  update_activityLog_by_pk?: Maybe<ActivityLog>;
  /** update multiples rows of table: "activityLog" */
  update_activityLog_many?: Maybe<Array<Maybe<ActivityLog_Mutation_Response>>>;
  /** update data of the table: "address" */
  update_address?: Maybe<Address_Mutation_Response>;
  /** update single row of the table: "address" */
  update_address_by_pk?: Maybe<Address>;
  /** update multiples rows of table: "address" */
  update_address_many?: Maybe<Array<Maybe<Address_Mutation_Response>>>;
  /** update data of the table: "appointment" */
  update_appointment?: Maybe<Appointment_Mutation_Response>;
  /** update data of the table: "appointmentConsent" */
  update_appointmentConsent?: Maybe<AppointmentConsent_Mutation_Response>;
  /** update single row of the table: "appointmentConsent" */
  update_appointmentConsent_by_pk?: Maybe<AppointmentConsent>;
  /** update multiples rows of table: "appointmentConsent" */
  update_appointmentConsent_many?: Maybe<Array<Maybe<AppointmentConsent_Mutation_Response>>>;
  /** update data of the table: "appointmentCustomForm" */
  update_appointmentCustomForm?: Maybe<AppointmentCustomForm_Mutation_Response>;
  /** update single row of the table: "appointmentCustomForm" */
  update_appointmentCustomForm_by_pk?: Maybe<AppointmentCustomForm>;
  /** update multiples rows of table: "appointmentCustomForm" */
  update_appointmentCustomForm_many?: Maybe<Array<Maybe<AppointmentCustomForm_Mutation_Response>>>;
  /** update data of the table: "appointmentService" */
  update_appointmentService?: Maybe<AppointmentService_Mutation_Response>;
  /** update data of the table: "appointmentServiceConsumable" */
  update_appointmentServiceConsumable?: Maybe<AppointmentServiceConsumable_Mutation_Response>;
  /** update single row of the table: "appointmentServiceConsumable" */
  update_appointmentServiceConsumable_by_pk?: Maybe<AppointmentServiceConsumable>;
  /** update multiples rows of table: "appointmentServiceConsumable" */
  update_appointmentServiceConsumable_many?: Maybe<Array<Maybe<AppointmentServiceConsumable_Mutation_Response>>>;
  /** update single row of the table: "appointmentService" */
  update_appointmentService_by_pk?: Maybe<AppointmentService>;
  /** update multiples rows of table: "appointmentService" */
  update_appointmentService_many?: Maybe<Array<Maybe<AppointmentService_Mutation_Response>>>;
  /** update data of the table: "appointmentType" */
  update_appointmentType?: Maybe<AppointmentType_Mutation_Response>;
  /** update single row of the table: "appointmentType" */
  update_appointmentType_by_pk?: Maybe<AppointmentType>;
  /** update multiples rows of table: "appointmentType" */
  update_appointmentType_many?: Maybe<Array<Maybe<AppointmentType_Mutation_Response>>>;
  /** update single row of the table: "appointment" */
  update_appointment_by_pk?: Maybe<Appointment>;
  /** update multiples rows of table: "appointment" */
  update_appointment_many?: Maybe<Array<Maybe<Appointment_Mutation_Response>>>;
  /** update data of the table: "audience" */
  update_audience?: Maybe<Audience_Mutation_Response>;
  /** update data of the table: "audienceEmail" */
  update_audienceEmail?: Maybe<AudienceEmail_Mutation_Response>;
  /** update single row of the table: "audienceEmail" */
  update_audienceEmail_by_pk?: Maybe<AudienceEmail>;
  /** update multiples rows of table: "audienceEmail" */
  update_audienceEmail_many?: Maybe<Array<Maybe<AudienceEmail_Mutation_Response>>>;
  /** update single row of the table: "audience" */
  update_audience_by_pk?: Maybe<Audience>;
  /** update multiples rows of table: "audience" */
  update_audience_many?: Maybe<Array<Maybe<Audience_Mutation_Response>>>;
  /** update data of the table: "authProfile" */
  update_authProfile?: Maybe<AuthProfile_Mutation_Response>;
  /** update single row of the table: "authProfile" */
  update_authProfile_by_pk?: Maybe<AuthProfile>;
  /** update multiples rows of table: "authProfile" */
  update_authProfile_many?: Maybe<Array<Maybe<AuthProfile_Mutation_Response>>>;
  /** update data of the table: "clinicalMessage" */
  update_clinicalMessage?: Maybe<ClinicalMessage_Mutation_Response>;
  /** update data of the table: "clinicalMessageStatus" */
  update_clinicalMessageStatus?: Maybe<ClinicalMessageStatus_Mutation_Response>;
  /** update single row of the table: "clinicalMessageStatus" */
  update_clinicalMessageStatus_by_pk?: Maybe<ClinicalMessageStatus>;
  /** update multiples rows of table: "clinicalMessageStatus" */
  update_clinicalMessageStatus_many?: Maybe<Array<Maybe<ClinicalMessageStatus_Mutation_Response>>>;
  /** update single row of the table: "clinicalMessage" */
  update_clinicalMessage_by_pk?: Maybe<ClinicalMessage>;
  /** update multiples rows of table: "clinicalMessage" */
  update_clinicalMessage_many?: Maybe<Array<Maybe<ClinicalMessage_Mutation_Response>>>;
  /** update data of the table: "compensationPolicy" */
  update_compensationPolicy?: Maybe<CompensationPolicy_Mutation_Response>;
  /** update data of the table: "compensationPolicyCommission" */
  update_compensationPolicyCommission?: Maybe<CompensationPolicyCommission_Mutation_Response>;
  /** update single row of the table: "compensationPolicyCommission" */
  update_compensationPolicyCommission_by_pk?: Maybe<CompensationPolicyCommission>;
  /** update multiples rows of table: "compensationPolicyCommission" */
  update_compensationPolicyCommission_many?: Maybe<Array<Maybe<CompensationPolicyCommission_Mutation_Response>>>;
  /** update data of the table: "compensationPolicyTier" */
  update_compensationPolicyTier?: Maybe<CompensationPolicyTier_Mutation_Response>;
  /** update data of the table: "compensationPolicyTierCategory" */
  update_compensationPolicyTierCategory?: Maybe<CompensationPolicyTierCategory_Mutation_Response>;
  /** update single row of the table: "compensationPolicyTierCategory" */
  update_compensationPolicyTierCategory_by_pk?: Maybe<CompensationPolicyTierCategory>;
  /** update multiples rows of table: "compensationPolicyTierCategory" */
  update_compensationPolicyTierCategory_many?: Maybe<Array<Maybe<CompensationPolicyTierCategory_Mutation_Response>>>;
  /** update data of the table: "compensationPolicyTierExemption" */
  update_compensationPolicyTierExemption?: Maybe<CompensationPolicyTierExemption_Mutation_Response>;
  /** update single row of the table: "compensationPolicyTierExemption" */
  update_compensationPolicyTierExemption_by_pk?: Maybe<CompensationPolicyTierExemption>;
  /** update multiples rows of table: "compensationPolicyTierExemption" */
  update_compensationPolicyTierExemption_many?: Maybe<Array<Maybe<CompensationPolicyTierExemption_Mutation_Response>>>;
  /** update data of the table: "compensationPolicyTierInclusion" */
  update_compensationPolicyTierInclusion?: Maybe<CompensationPolicyTierInclusion_Mutation_Response>;
  /** update single row of the table: "compensationPolicyTierInclusion" */
  update_compensationPolicyTierInclusion_by_pk?: Maybe<CompensationPolicyTierInclusion>;
  /** update multiples rows of table: "compensationPolicyTierInclusion" */
  update_compensationPolicyTierInclusion_many?: Maybe<Array<Maybe<CompensationPolicyTierInclusion_Mutation_Response>>>;
  /** update single row of the table: "compensationPolicyTier" */
  update_compensationPolicyTier_by_pk?: Maybe<CompensationPolicyTier>;
  /** update multiples rows of table: "compensationPolicyTier" */
  update_compensationPolicyTier_many?: Maybe<Array<Maybe<CompensationPolicyTier_Mutation_Response>>>;
  /** update single row of the table: "compensationPolicy" */
  update_compensationPolicy_by_pk?: Maybe<CompensationPolicy>;
  /** update multiples rows of table: "compensationPolicy" */
  update_compensationPolicy_many?: Maybe<Array<Maybe<CompensationPolicy_Mutation_Response>>>;
  /** update data of the table: "compensationStrategy" */
  update_compensationStrategy?: Maybe<CompensationStrategy_Mutation_Response>;
  /** update data of the table: "compensationStrategyCommission" */
  update_compensationStrategyCommission?: Maybe<CompensationStrategyCommission_Mutation_Response>;
  /** update single row of the table: "compensationStrategyCommission" */
  update_compensationStrategyCommission_by_pk?: Maybe<CompensationStrategyCommission>;
  /** update multiples rows of table: "compensationStrategyCommission" */
  update_compensationStrategyCommission_many?: Maybe<Array<Maybe<CompensationStrategyCommission_Mutation_Response>>>;
  /** update data of the table: "compensationStrategyTier" */
  update_compensationStrategyTier?: Maybe<CompensationStrategyTier_Mutation_Response>;
  /** update data of the table: "compensationStrategyTierCategory" */
  update_compensationStrategyTierCategory?: Maybe<CompensationStrategyTierCategory_Mutation_Response>;
  /** update single row of the table: "compensationStrategyTierCategory" */
  update_compensationStrategyTierCategory_by_pk?: Maybe<CompensationStrategyTierCategory>;
  /** update multiples rows of table: "compensationStrategyTierCategory" */
  update_compensationStrategyTierCategory_many?: Maybe<Array<Maybe<CompensationStrategyTierCategory_Mutation_Response>>>;
  /** update data of the table: "compensationStrategyTierExemption" */
  update_compensationStrategyTierExemption?: Maybe<CompensationStrategyTierExemption_Mutation_Response>;
  /** update single row of the table: "compensationStrategyTierExemption" */
  update_compensationStrategyTierExemption_by_pk?: Maybe<CompensationStrategyTierExemption>;
  /** update multiples rows of table: "compensationStrategyTierExemption" */
  update_compensationStrategyTierExemption_many?: Maybe<Array<Maybe<CompensationStrategyTierExemption_Mutation_Response>>>;
  /** update data of the table: "compensationStrategyTierInclusion" */
  update_compensationStrategyTierInclusion?: Maybe<CompensationStrategyTierInclusion_Mutation_Response>;
  /** update single row of the table: "compensationStrategyTierInclusion" */
  update_compensationStrategyTierInclusion_by_pk?: Maybe<CompensationStrategyTierInclusion>;
  /** update multiples rows of table: "compensationStrategyTierInclusion" */
  update_compensationStrategyTierInclusion_many?: Maybe<Array<Maybe<CompensationStrategyTierInclusion_Mutation_Response>>>;
  /** update single row of the table: "compensationStrategyTier" */
  update_compensationStrategyTier_by_pk?: Maybe<CompensationStrategyTier>;
  /** update multiples rows of table: "compensationStrategyTier" */
  update_compensationStrategyTier_many?: Maybe<Array<Maybe<CompensationStrategyTier_Mutation_Response>>>;
  /** update single row of the table: "compensationStrategy" */
  update_compensationStrategy_by_pk?: Maybe<CompensationStrategy>;
  /** update multiples rows of table: "compensationStrategy" */
  update_compensationStrategy_many?: Maybe<Array<Maybe<CompensationStrategy_Mutation_Response>>>;
  /** update data of the table: "consent" */
  update_consent?: Maybe<Consent_Mutation_Response>;
  /** update single row of the table: "consent" */
  update_consent_by_pk?: Maybe<Consent>;
  /** update multiples rows of table: "consent" */
  update_consent_many?: Maybe<Array<Maybe<Consent_Mutation_Response>>>;
  /** update data of the table: "consumable" */
  update_consumable?: Maybe<Consumable_Mutation_Response>;
  /** update data of the table: "consumableBrand" */
  update_consumableBrand?: Maybe<ConsumableBrand_Mutation_Response>;
  /** update single row of the table: "consumableBrand" */
  update_consumableBrand_by_pk?: Maybe<ConsumableBrand>;
  /** update multiples rows of table: "consumableBrand" */
  update_consumableBrand_many?: Maybe<Array<Maybe<ConsumableBrand_Mutation_Response>>>;
  /** update data of the table: "consumableSubtype" */
  update_consumableSubtype?: Maybe<ConsumableSubtype_Mutation_Response>;
  /** update single row of the table: "consumableSubtype" */
  update_consumableSubtype_by_pk?: Maybe<ConsumableSubtype>;
  /** update multiples rows of table: "consumableSubtype" */
  update_consumableSubtype_many?: Maybe<Array<Maybe<ConsumableSubtype_Mutation_Response>>>;
  /** update data of the table: "consumableType" */
  update_consumableType?: Maybe<ConsumableType_Mutation_Response>;
  /** update data of the table: "consumableTypeConsumableSubtype" */
  update_consumableTypeConsumableSubtype?: Maybe<ConsumableTypeConsumableSubtype_Mutation_Response>;
  /** update single row of the table: "consumableTypeConsumableSubtype" */
  update_consumableTypeConsumableSubtype_by_pk?: Maybe<ConsumableTypeConsumableSubtype>;
  /** update multiples rows of table: "consumableTypeConsumableSubtype" */
  update_consumableTypeConsumableSubtype_many?: Maybe<Array<Maybe<ConsumableTypeConsumableSubtype_Mutation_Response>>>;
  /** update single row of the table: "consumableType" */
  update_consumableType_by_pk?: Maybe<ConsumableType>;
  /** update multiples rows of table: "consumableType" */
  update_consumableType_many?: Maybe<Array<Maybe<ConsumableType_Mutation_Response>>>;
  /** update single row of the table: "consumable" */
  update_consumable_by_pk?: Maybe<Consumable>;
  /** update multiples rows of table: "consumable" */
  update_consumable_many?: Maybe<Array<Maybe<Consumable_Mutation_Response>>>;
  /** update data of the table: "coupon" */
  update_coupon?: Maybe<Coupon_Mutation_Response>;
  /** update data of the table: "couponConfiguration" */
  update_couponConfiguration?: Maybe<CouponConfiguration_Mutation_Response>;
  /** update single row of the table: "couponConfiguration" */
  update_couponConfiguration_by_pk?: Maybe<CouponConfiguration>;
  /** update multiples rows of table: "couponConfiguration" */
  update_couponConfiguration_many?: Maybe<Array<Maybe<CouponConfiguration_Mutation_Response>>>;
  /** update data of the table: "couponScope" */
  update_couponScope?: Maybe<CouponScope_Mutation_Response>;
  /** update single row of the table: "couponScope" */
  update_couponScope_by_pk?: Maybe<CouponScope>;
  /** update multiples rows of table: "couponScope" */
  update_couponScope_many?: Maybe<Array<Maybe<CouponScope_Mutation_Response>>>;
  /** update data of the table: "couponType" */
  update_couponType?: Maybe<CouponType_Mutation_Response>;
  /** update single row of the table: "couponType" */
  update_couponType_by_pk?: Maybe<CouponType>;
  /** update multiples rows of table: "couponType" */
  update_couponType_many?: Maybe<Array<Maybe<CouponType_Mutation_Response>>>;
  /** update single row of the table: "coupon" */
  update_coupon_by_pk?: Maybe<Coupon>;
  /** update multiples rows of table: "coupon" */
  update_coupon_many?: Maybe<Array<Maybe<Coupon_Mutation_Response>>>;
  /** update data of the table: "customForm" */
  update_customForm?: Maybe<CustomForm_Mutation_Response>;
  /** update data of the table: "customFormField" */
  update_customFormField?: Maybe<CustomFormField_Mutation_Response>;
  /** update data of the table: "customFormFieldCategory" */
  update_customFormFieldCategory?: Maybe<CustomFormFieldCategory_Mutation_Response>;
  /** update single row of the table: "customFormFieldCategory" */
  update_customFormFieldCategory_by_pk?: Maybe<CustomFormFieldCategory>;
  /** update multiples rows of table: "customFormFieldCategory" */
  update_customFormFieldCategory_many?: Maybe<Array<Maybe<CustomFormFieldCategory_Mutation_Response>>>;
  /** update data of the table: "customFormFieldResponse" */
  update_customFormFieldResponse?: Maybe<CustomFormFieldResponse_Mutation_Response>;
  /** update single row of the table: "customFormFieldResponse" */
  update_customFormFieldResponse_by_pk?: Maybe<CustomFormFieldResponse>;
  /** update multiples rows of table: "customFormFieldResponse" */
  update_customFormFieldResponse_many?: Maybe<Array<Maybe<CustomFormFieldResponse_Mutation_Response>>>;
  /** update data of the table: "customFormFieldType" */
  update_customFormFieldType?: Maybe<CustomFormFieldType_Mutation_Response>;
  /** update single row of the table: "customFormFieldType" */
  update_customFormFieldType_by_pk?: Maybe<CustomFormFieldType>;
  /** update multiples rows of table: "customFormFieldType" */
  update_customFormFieldType_many?: Maybe<Array<Maybe<CustomFormFieldType_Mutation_Response>>>;
  /** update single row of the table: "customFormField" */
  update_customFormField_by_pk?: Maybe<CustomFormField>;
  /** update multiples rows of table: "customFormField" */
  update_customFormField_many?: Maybe<Array<Maybe<CustomFormField_Mutation_Response>>>;
  /** update data of the table: "customFormOption" */
  update_customFormOption?: Maybe<CustomFormOption_Mutation_Response>;
  /** update single row of the table: "customFormOption" */
  update_customFormOption_by_pk?: Maybe<CustomFormOption>;
  /** update multiples rows of table: "customFormOption" */
  update_customFormOption_many?: Maybe<Array<Maybe<CustomFormOption_Mutation_Response>>>;
  /** update data of the table: "customFormReplicatedField" */
  update_customFormReplicatedField?: Maybe<CustomFormReplicatedField_Mutation_Response>;
  /** update single row of the table: "customFormReplicatedField" */
  update_customFormReplicatedField_by_pk?: Maybe<CustomFormReplicatedField>;
  /** update multiples rows of table: "customFormReplicatedField" */
  update_customFormReplicatedField_many?: Maybe<Array<Maybe<CustomFormReplicatedField_Mutation_Response>>>;
  /** update data of the table: "customFormReplicatedRow" */
  update_customFormReplicatedRow?: Maybe<CustomFormReplicatedRow_Mutation_Response>;
  /** update single row of the table: "customFormReplicatedRow" */
  update_customFormReplicatedRow_by_pk?: Maybe<CustomFormReplicatedRow>;
  /** update multiples rows of table: "customFormReplicatedRow" */
  update_customFormReplicatedRow_many?: Maybe<Array<Maybe<CustomFormReplicatedRow_Mutation_Response>>>;
  /** update data of the table: "customFormResponse" */
  update_customFormResponse?: Maybe<CustomFormResponse_Mutation_Response>;
  /** update single row of the table: "customFormResponse" */
  update_customFormResponse_by_pk?: Maybe<CustomFormResponse>;
  /** update multiples rows of table: "customFormResponse" */
  update_customFormResponse_many?: Maybe<Array<Maybe<CustomFormResponse_Mutation_Response>>>;
  /** update data of the table: "customFormRow" */
  update_customFormRow?: Maybe<CustomFormRow_Mutation_Response>;
  /** update single row of the table: "customFormRow" */
  update_customFormRow_by_pk?: Maybe<CustomFormRow>;
  /** update multiples rows of table: "customFormRow" */
  update_customFormRow_many?: Maybe<Array<Maybe<CustomFormRow_Mutation_Response>>>;
  /** update single row of the table: "customForm" */
  update_customForm_by_pk?: Maybe<CustomForm>;
  /** update multiples rows of table: "customForm" */
  update_customForm_many?: Maybe<Array<Maybe<CustomForm_Mutation_Response>>>;
  /** update data of the table: "customQuestion" */
  update_customQuestion?: Maybe<CustomQuestion_Mutation_Response>;
  /** update data of the table: "customQuestionOption" */
  update_customQuestionOption?: Maybe<CustomQuestionOption_Mutation_Response>;
  /** update single row of the table: "customQuestionOption" */
  update_customQuestionOption_by_pk?: Maybe<CustomQuestionOption>;
  /** update multiples rows of table: "customQuestionOption" */
  update_customQuestionOption_many?: Maybe<Array<Maybe<CustomQuestionOption_Mutation_Response>>>;
  /** update single row of the table: "customQuestion" */
  update_customQuestion_by_pk?: Maybe<CustomQuestion>;
  /** update multiples rows of table: "customQuestion" */
  update_customQuestion_many?: Maybe<Array<Maybe<CustomQuestion_Mutation_Response>>>;
  /** update data of the table: "degreeDesignation" */
  update_degreeDesignation?: Maybe<DegreeDesignation_Mutation_Response>;
  /** update single row of the table: "degreeDesignation" */
  update_degreeDesignation_by_pk?: Maybe<DegreeDesignation>;
  /** update multiples rows of table: "degreeDesignation" */
  update_degreeDesignation_many?: Maybe<Array<Maybe<DegreeDesignation_Mutation_Response>>>;
  /** update data of the table: "depositInvoiceLineItem" */
  update_depositInvoiceLineItem?: Maybe<DepositInvoiceLineItem_Mutation_Response>;
  /** update single row of the table: "depositInvoiceLineItem" */
  update_depositInvoiceLineItem_by_pk?: Maybe<DepositInvoiceLineItem>;
  /** update multiples rows of table: "depositInvoiceLineItem" */
  update_depositInvoiceLineItem_many?: Maybe<Array<Maybe<DepositInvoiceLineItem_Mutation_Response>>>;
  /** update data of the table: "device" */
  update_device?: Maybe<Device_Mutation_Response>;
  /** update data of the table: "deviceType" */
  update_deviceType?: Maybe<DeviceType_Mutation_Response>;
  /** update single row of the table: "deviceType" */
  update_deviceType_by_pk?: Maybe<DeviceType>;
  /** update multiples rows of table: "deviceType" */
  update_deviceType_many?: Maybe<Array<Maybe<DeviceType_Mutation_Response>>>;
  /** update single row of the table: "device" */
  update_device_by_pk?: Maybe<Device>;
  /** update multiples rows of table: "device" */
  update_device_many?: Maybe<Array<Maybe<Device_Mutation_Response>>>;
  /** update data of the table: "discountInvoiceLineItem" */
  update_discountInvoiceLineItem?: Maybe<DiscountInvoiceLineItem_Mutation_Response>;
  /** update single row of the table: "discountInvoiceLineItem" */
  update_discountInvoiceLineItem_by_pk?: Maybe<DiscountInvoiceLineItem>;
  /** update multiples rows of table: "discountInvoiceLineItem" */
  update_discountInvoiceLineItem_many?: Maybe<Array<Maybe<DiscountInvoiceLineItem_Mutation_Response>>>;
  /** update data of the table: "document" */
  update_document?: Maybe<Document_Mutation_Response>;
  /** update data of the table: "documentTag" */
  update_documentTag?: Maybe<DocumentTag_Mutation_Response>;
  /** update single row of the table: "documentTag" */
  update_documentTag_by_pk?: Maybe<DocumentTag>;
  /** update multiples rows of table: "documentTag" */
  update_documentTag_many?: Maybe<Array<Maybe<DocumentTag_Mutation_Response>>>;
  /** update single row of the table: "document" */
  update_document_by_pk?: Maybe<Document>;
  /** update multiples rows of table: "document" */
  update_document_many?: Maybe<Array<Maybe<Document_Mutation_Response>>>;
  /** update data of the table: "emailCampaignLog" */
  update_emailCampaignLog?: Maybe<EmailCampaignLog_Mutation_Response>;
  /** update single row of the table: "emailCampaignLog" */
  update_emailCampaignLog_by_pk?: Maybe<EmailCampaignLog>;
  /** update multiples rows of table: "emailCampaignLog" */
  update_emailCampaignLog_many?: Maybe<Array<Maybe<EmailCampaignLog_Mutation_Response>>>;
  /** update data of the table: "emailTemplate" */
  update_emailTemplate?: Maybe<EmailTemplate_Mutation_Response>;
  /** update data of the table: "emailTemplatePatient" */
  update_emailTemplatePatient?: Maybe<EmailTemplatePatient_Mutation_Response>;
  /** update single row of the table: "emailTemplatePatient" */
  update_emailTemplatePatient_by_pk?: Maybe<EmailTemplatePatient>;
  /** update multiples rows of table: "emailTemplatePatient" */
  update_emailTemplatePatient_many?: Maybe<Array<Maybe<EmailTemplatePatient_Mutation_Response>>>;
  /** update data of the table: "emailTemplateSendQueue" */
  update_emailTemplateSendQueue?: Maybe<EmailTemplateSendQueue_Mutation_Response>;
  /** update single row of the table: "emailTemplateSendQueue" */
  update_emailTemplateSendQueue_by_pk?: Maybe<EmailTemplateSendQueue>;
  /** update multiples rows of table: "emailTemplateSendQueue" */
  update_emailTemplateSendQueue_many?: Maybe<Array<Maybe<EmailTemplateSendQueue_Mutation_Response>>>;
  /** update single row of the table: "emailTemplate" */
  update_emailTemplate_by_pk?: Maybe<EmailTemplate>;
  /** update multiples rows of table: "emailTemplate" */
  update_emailTemplate_many?: Maybe<Array<Maybe<EmailTemplate_Mutation_Response>>>;
  /** update data of the table: "externalLocation" */
  update_externalLocation?: Maybe<ExternalLocation_Mutation_Response>;
  /** update single row of the table: "externalLocation" */
  update_externalLocation_by_pk?: Maybe<ExternalLocation>;
  /** update multiples rows of table: "externalLocation" */
  update_externalLocation_many?: Maybe<Array<Maybe<ExternalLocation_Mutation_Response>>>;
  /** update data of the table: "externalProvider" */
  update_externalProvider?: Maybe<ExternalProvider_Mutation_Response>;
  /** update single row of the table: "externalProvider" */
  update_externalProvider_by_pk?: Maybe<ExternalProvider>;
  /** update multiples rows of table: "externalProvider" */
  update_externalProvider_many?: Maybe<Array<Maybe<ExternalProvider_Mutation_Response>>>;
  /** update data of the table: "externalReview" */
  update_externalReview?: Maybe<ExternalReview_Mutation_Response>;
  /** update single row of the table: "externalReview" */
  update_externalReview_by_pk?: Maybe<ExternalReview>;
  /** update multiples rows of table: "externalReview" */
  update_externalReview_many?: Maybe<Array<Maybe<ExternalReview_Mutation_Response>>>;
  /** update data of the table: "fee" */
  update_fee?: Maybe<Fee_Mutation_Response>;
  /** update data of the table: "feeInvoiceLineItem" */
  update_feeInvoiceLineItem?: Maybe<FeeInvoiceLineItem_Mutation_Response>;
  /** update single row of the table: "feeInvoiceLineItem" */
  update_feeInvoiceLineItem_by_pk?: Maybe<FeeInvoiceLineItem>;
  /** update multiples rows of table: "feeInvoiceLineItem" */
  update_feeInvoiceLineItem_many?: Maybe<Array<Maybe<FeeInvoiceLineItem_Mutation_Response>>>;
  /** update single row of the table: "fee" */
  update_fee_by_pk?: Maybe<Fee>;
  /** update multiples rows of table: "fee" */
  update_fee_many?: Maybe<Array<Maybe<Fee_Mutation_Response>>>;
  /** update data of the table: "finixTerminal" */
  update_finixTerminal?: Maybe<FinixTerminal_Mutation_Response>;
  /** update single row of the table: "finixTerminal" */
  update_finixTerminal_by_pk?: Maybe<FinixTerminal>;
  /** update multiples rows of table: "finixTerminal" */
  update_finixTerminal_many?: Maybe<Array<Maybe<FinixTerminal_Mutation_Response>>>;
  /** update data of the table: "flowsheet" */
  update_flowsheet?: Maybe<Flowsheet_Mutation_Response>;
  /** update data of the table: "flowsheetParameter" */
  update_flowsheetParameter?: Maybe<FlowsheetParameter_Mutation_Response>;
  /** update data of the table: "flowsheetParameterFlowsheet" */
  update_flowsheetParameterFlowsheet?: Maybe<FlowsheetParameterFlowsheet_Mutation_Response>;
  /** update single row of the table: "flowsheetParameterFlowsheet" */
  update_flowsheetParameterFlowsheet_by_pk?: Maybe<FlowsheetParameterFlowsheet>;
  /** update multiples rows of table: "flowsheetParameterFlowsheet" */
  update_flowsheetParameterFlowsheet_many?: Maybe<Array<Maybe<FlowsheetParameterFlowsheet_Mutation_Response>>>;
  /** update data of the table: "flowsheetParameterGroup" */
  update_flowsheetParameterGroup?: Maybe<FlowsheetParameterGroup_Mutation_Response>;
  /** update single row of the table: "flowsheetParameterGroup" */
  update_flowsheetParameterGroup_by_pk?: Maybe<FlowsheetParameterGroup>;
  /** update multiples rows of table: "flowsheetParameterGroup" */
  update_flowsheetParameterGroup_many?: Maybe<Array<Maybe<FlowsheetParameterGroup_Mutation_Response>>>;
  /** update single row of the table: "flowsheetParameter" */
  update_flowsheetParameter_by_pk?: Maybe<FlowsheetParameter>;
  /** update multiples rows of table: "flowsheetParameter" */
  update_flowsheetParameter_many?: Maybe<Array<Maybe<FlowsheetParameter_Mutation_Response>>>;
  /** update single row of the table: "flowsheet" */
  update_flowsheet_by_pk?: Maybe<Flowsheet>;
  /** update multiples rows of table: "flowsheet" */
  update_flowsheet_many?: Maybe<Array<Maybe<Flowsheet_Mutation_Response>>>;
  /** update data of the table: "genericInvoiceLineItem" */
  update_genericInvoiceLineItem?: Maybe<GenericInvoiceLineItem_Mutation_Response>;
  /** update data of the table: "genericInvoiceLineItemDiscount" */
  update_genericInvoiceLineItemDiscount?: Maybe<GenericInvoiceLineItemDiscount_Mutation_Response>;
  /** update single row of the table: "genericInvoiceLineItemDiscount" */
  update_genericInvoiceLineItemDiscount_by_pk?: Maybe<GenericInvoiceLineItemDiscount>;
  /** update multiples rows of table: "genericInvoiceLineItemDiscount" */
  update_genericInvoiceLineItemDiscount_many?: Maybe<Array<Maybe<GenericInvoiceLineItemDiscount_Mutation_Response>>>;
  /** update single row of the table: "genericInvoiceLineItem" */
  update_genericInvoiceLineItem_by_pk?: Maybe<GenericInvoiceLineItem>;
  /** update multiples rows of table: "genericInvoiceLineItem" */
  update_genericInvoiceLineItem_many?: Maybe<Array<Maybe<GenericInvoiceLineItem_Mutation_Response>>>;
  /** update data of the table: "giftCard" */
  update_giftCard?: Maybe<GiftCard_Mutation_Response>;
  /** update single row of the table: "giftCard" */
  update_giftCard_by_pk?: Maybe<GiftCard>;
  /** update multiples rows of table: "giftCard" */
  update_giftCard_many?: Maybe<Array<Maybe<GiftCard_Mutation_Response>>>;
  /** update data of the table: "googleAccount" */
  update_googleAccount?: Maybe<GoogleAccount_Mutation_Response>;
  /** update single row of the table: "googleAccount" */
  update_googleAccount_by_pk?: Maybe<GoogleAccount>;
  /** update multiples rows of table: "googleAccount" */
  update_googleAccount_many?: Maybe<Array<Maybe<GoogleAccount_Mutation_Response>>>;
  /** update data of the table: "googleLocation" */
  update_googleLocation?: Maybe<GoogleLocation_Mutation_Response>;
  /** update single row of the table: "googleLocation" */
  update_googleLocation_by_pk?: Maybe<GoogleLocation>;
  /** update multiples rows of table: "googleLocation" */
  update_googleLocation_many?: Maybe<Array<Maybe<GoogleLocation_Mutation_Response>>>;
  /** update data of the table: "googleReview" */
  update_googleReview?: Maybe<GoogleReview_Mutation_Response>;
  /** update single row of the table: "googleReview" */
  update_googleReview_by_pk?: Maybe<GoogleReview>;
  /** update multiples rows of table: "googleReview" */
  update_googleReview_many?: Maybe<Array<Maybe<GoogleReview_Mutation_Response>>>;
  /** update data of the table: "importJob" */
  update_importJob?: Maybe<ImportJob_Mutation_Response>;
  /** update single row of the table: "importJob" */
  update_importJob_by_pk?: Maybe<ImportJob>;
  /** update multiples rows of table: "importJob" */
  update_importJob_many?: Maybe<Array<Maybe<ImportJob_Mutation_Response>>>;
  /** update data of the table: "importMetadata" */
  update_importMetadata?: Maybe<ImportMetadata_Mutation_Response>;
  /** update single row of the table: "importMetadata" */
  update_importMetadata_by_pk?: Maybe<ImportMetadata>;
  /** update multiples rows of table: "importMetadata" */
  update_importMetadata_many?: Maybe<Array<Maybe<ImportMetadata_Mutation_Response>>>;
  /** update data of the table: "incompatibleServices" */
  update_incompatibleServices?: Maybe<IncompatibleServices_Mutation_Response>;
  /** update single row of the table: "incompatibleServices" */
  update_incompatibleServices_by_pk?: Maybe<IncompatibleServices>;
  /** update multiples rows of table: "incompatibleServices" */
  update_incompatibleServices_many?: Maybe<Array<Maybe<IncompatibleServices_Mutation_Response>>>;
  /** update data of the table: "internalAppointmentProvider" */
  update_internalAppointmentProvider?: Maybe<InternalAppointmentProvider_Mutation_Response>;
  /** update single row of the table: "internalAppointmentProvider" */
  update_internalAppointmentProvider_by_pk?: Maybe<InternalAppointmentProvider>;
  /** update multiples rows of table: "internalAppointmentProvider" */
  update_internalAppointmentProvider_many?: Maybe<Array<Maybe<InternalAppointmentProvider_Mutation_Response>>>;
  /** update data of the table: "inventoryTransaction" */
  update_inventoryTransaction?: Maybe<InventoryTransaction_Mutation_Response>;
  /** update single row of the table: "inventoryTransaction" */
  update_inventoryTransaction_by_pk?: Maybe<InventoryTransaction>;
  /** update multiples rows of table: "inventoryTransaction" */
  update_inventoryTransaction_many?: Maybe<Array<Maybe<InventoryTransaction_Mutation_Response>>>;
  /** update data of the table: "invoice" */
  update_invoice?: Maybe<Invoice_Mutation_Response>;
  /** update data of the table: "invoiceConfiguration" */
  update_invoiceConfiguration?: Maybe<InvoiceConfiguration_Mutation_Response>;
  /** update single row of the table: "invoiceConfiguration" */
  update_invoiceConfiguration_by_pk?: Maybe<InvoiceConfiguration>;
  /** update multiples rows of table: "invoiceConfiguration" */
  update_invoiceConfiguration_many?: Maybe<Array<Maybe<InvoiceConfiguration_Mutation_Response>>>;
  /** update data of the table: "invoiceCoupon" */
  update_invoiceCoupon?: Maybe<InvoiceCoupon_Mutation_Response>;
  /** update single row of the table: "invoiceCoupon" */
  update_invoiceCoupon_by_pk?: Maybe<InvoiceCoupon>;
  /** update multiples rows of table: "invoiceCoupon" */
  update_invoiceCoupon_many?: Maybe<Array<Maybe<InvoiceCoupon_Mutation_Response>>>;
  /** update data of the table: "invoiceMembership" */
  update_invoiceMembership?: Maybe<InvoiceMembership_Mutation_Response>;
  /** update single row of the table: "invoiceMembership" */
  update_invoiceMembership_by_pk?: Maybe<InvoiceMembership>;
  /** update multiples rows of table: "invoiceMembership" */
  update_invoiceMembership_many?: Maybe<Array<Maybe<InvoiceMembership_Mutation_Response>>>;
  /** update data of the table: "invoicePackage" */
  update_invoicePackage?: Maybe<InvoicePackage_Mutation_Response>;
  /** update single row of the table: "invoicePackage" */
  update_invoicePackage_by_pk?: Maybe<InvoicePackage>;
  /** update multiples rows of table: "invoicePackage" */
  update_invoicePackage_many?: Maybe<Array<Maybe<InvoicePackage_Mutation_Response>>>;
  /** update data of the table: "invoiceProductItem" */
  update_invoiceProductItem?: Maybe<InvoiceProductItem_Mutation_Response>;
  /** update single row of the table: "invoiceProductItem" */
  update_invoiceProductItem_by_pk?: Maybe<InvoiceProductItem>;
  /** update multiples rows of table: "invoiceProductItem" */
  update_invoiceProductItem_many?: Maybe<Array<Maybe<InvoiceProductItem_Mutation_Response>>>;
  /** update data of the table: "invoiceServiceConsumable" */
  update_invoiceServiceConsumable?: Maybe<InvoiceServiceConsumable_Mutation_Response>;
  /** update single row of the table: "invoiceServiceConsumable" */
  update_invoiceServiceConsumable_by_pk?: Maybe<InvoiceServiceConsumable>;
  /** update multiples rows of table: "invoiceServiceConsumable" */
  update_invoiceServiceConsumable_many?: Maybe<Array<Maybe<InvoiceServiceConsumable_Mutation_Response>>>;
  /** update data of the table: "invoiceServiceItem" */
  update_invoiceServiceItem?: Maybe<InvoiceServiceItem_Mutation_Response>;
  /** update single row of the table: "invoiceServiceItem" */
  update_invoiceServiceItem_by_pk?: Maybe<InvoiceServiceItem>;
  /** update multiples rows of table: "invoiceServiceItem" */
  update_invoiceServiceItem_many?: Maybe<Array<Maybe<InvoiceServiceItem_Mutation_Response>>>;
  /** update data of the table: "invoiceTemplate" */
  update_invoiceTemplate?: Maybe<InvoiceTemplate_Mutation_Response>;
  /** update single row of the table: "invoiceTemplate" */
  update_invoiceTemplate_by_pk?: Maybe<InvoiceTemplate>;
  /** update multiples rows of table: "invoiceTemplate" */
  update_invoiceTemplate_many?: Maybe<Array<Maybe<InvoiceTemplate_Mutation_Response>>>;
  /** update single row of the table: "invoice" */
  update_invoice_by_pk?: Maybe<Invoice>;
  /** update multiples rows of table: "invoice" */
  update_invoice_many?: Maybe<Array<Maybe<Invoice_Mutation_Response>>>;
  /** update data of the table: "job" */
  update_job?: Maybe<Job_Mutation_Response>;
  /** update data of the table: "jobStatus" */
  update_jobStatus?: Maybe<JobStatus_Mutation_Response>;
  /** update single row of the table: "jobStatus" */
  update_jobStatus_by_pk?: Maybe<JobStatus>;
  /** update multiples rows of table: "jobStatus" */
  update_jobStatus_many?: Maybe<Array<Maybe<JobStatus_Mutation_Response>>>;
  /** update single row of the table: "job" */
  update_job_by_pk?: Maybe<Job>;
  /** update multiples rows of table: "job" */
  update_job_many?: Maybe<Array<Maybe<Job_Mutation_Response>>>;
  /** update data of the table: "labResult" */
  update_labResult?: Maybe<LabResult_Mutation_Response>;
  /** update single row of the table: "labResult" */
  update_labResult_by_pk?: Maybe<LabResult>;
  /** update multiples rows of table: "labResult" */
  update_labResult_many?: Maybe<Array<Maybe<LabResult_Mutation_Response>>>;
  /** update data of the table: "leadForm" */
  update_leadForm?: Maybe<LeadForm_Mutation_Response>;
  /** update data of the table: "leadFormEntry" */
  update_leadFormEntry?: Maybe<LeadFormEntry_Mutation_Response>;
  /** update data of the table: "leadFormEntryTag" */
  update_leadFormEntryTag?: Maybe<LeadFormEntryTag_Mutation_Response>;
  /** update single row of the table: "leadFormEntryTag" */
  update_leadFormEntryTag_by_pk?: Maybe<LeadFormEntryTag>;
  /** update multiples rows of table: "leadFormEntryTag" */
  update_leadFormEntryTag_many?: Maybe<Array<Maybe<LeadFormEntryTag_Mutation_Response>>>;
  /** update single row of the table: "leadFormEntry" */
  update_leadFormEntry_by_pk?: Maybe<LeadFormEntry>;
  /** update multiples rows of table: "leadFormEntry" */
  update_leadFormEntry_many?: Maybe<Array<Maybe<LeadFormEntry_Mutation_Response>>>;
  /** update data of the table: "leadFormField" */
  update_leadFormField?: Maybe<LeadFormField_Mutation_Response>;
  /** update data of the table: "leadFormFieldValue" */
  update_leadFormFieldValue?: Maybe<LeadFormFieldValue_Mutation_Response>;
  /** update single row of the table: "leadFormFieldValue" */
  update_leadFormFieldValue_by_pk?: Maybe<LeadFormFieldValue>;
  /** update multiples rows of table: "leadFormFieldValue" */
  update_leadFormFieldValue_many?: Maybe<Array<Maybe<LeadFormFieldValue_Mutation_Response>>>;
  /** update single row of the table: "leadFormField" */
  update_leadFormField_by_pk?: Maybe<LeadFormField>;
  /** update multiples rows of table: "leadFormField" */
  update_leadFormField_many?: Maybe<Array<Maybe<LeadFormField_Mutation_Response>>>;
  /** update data of the table: "leadFormLink" */
  update_leadFormLink?: Maybe<LeadFormLink_Mutation_Response>;
  /** update single row of the table: "leadFormLink" */
  update_leadFormLink_by_pk?: Maybe<LeadFormLink>;
  /** update multiples rows of table: "leadFormLink" */
  update_leadFormLink_many?: Maybe<Array<Maybe<LeadFormLink_Mutation_Response>>>;
  /** update data of the table: "leadFormStep" */
  update_leadFormStep?: Maybe<LeadFormStep_Mutation_Response>;
  /** update single row of the table: "leadFormStep" */
  update_leadFormStep_by_pk?: Maybe<LeadFormStep>;
  /** update multiples rows of table: "leadFormStep" */
  update_leadFormStep_many?: Maybe<Array<Maybe<LeadFormStep_Mutation_Response>>>;
  /** update single row of the table: "leadForm" */
  update_leadForm_by_pk?: Maybe<LeadForm>;
  /** update multiples rows of table: "leadForm" */
  update_leadForm_many?: Maybe<Array<Maybe<LeadForm_Mutation_Response>>>;
  /** update data of the table: "leadTag" */
  update_leadTag?: Maybe<LeadTag_Mutation_Response>;
  /** update single row of the table: "leadTag" */
  update_leadTag_by_pk?: Maybe<LeadTag>;
  /** update multiples rows of table: "leadTag" */
  update_leadTag_many?: Maybe<Array<Maybe<LeadTag_Mutation_Response>>>;
  /** update data of the table: "leadTrackedEvent" */
  update_leadTrackedEvent?: Maybe<LeadTrackedEvent_Mutation_Response>;
  /** update single row of the table: "leadTrackedEvent" */
  update_leadTrackedEvent_by_pk?: Maybe<LeadTrackedEvent>;
  /** update multiples rows of table: "leadTrackedEvent" */
  update_leadTrackedEvent_many?: Maybe<Array<Maybe<LeadTrackedEvent_Mutation_Response>>>;
  /** update data of the table: "location" */
  update_location?: Maybe<Location_Mutation_Response>;
  /** update data of the table: "locationCoupon" */
  update_locationCoupon?: Maybe<LocationCoupon_Mutation_Response>;
  /** update single row of the table: "locationCoupon" */
  update_locationCoupon_by_pk?: Maybe<LocationCoupon>;
  /** update multiples rows of table: "locationCoupon" */
  update_locationCoupon_many?: Maybe<Array<Maybe<LocationCoupon_Mutation_Response>>>;
  /** update data of the table: "locationProvider" */
  update_locationProvider?: Maybe<LocationProvider_Mutation_Response>;
  /** update single row of the table: "locationProvider" */
  update_locationProvider_by_pk?: Maybe<LocationProvider>;
  /** update multiples rows of table: "locationProvider" */
  update_locationProvider_many?: Maybe<Array<Maybe<LocationProvider_Mutation_Response>>>;
  /** update data of the table: "locationSchedule" */
  update_locationSchedule?: Maybe<LocationSchedule_Mutation_Response>;
  /** update single row of the table: "locationSchedule" */
  update_locationSchedule_by_pk?: Maybe<LocationSchedule>;
  /** update multiples rows of table: "locationSchedule" */
  update_locationSchedule_many?: Maybe<Array<Maybe<LocationSchedule_Mutation_Response>>>;
  /** update data of the table: "locationService" */
  update_locationService?: Maybe<LocationService_Mutation_Response>;
  /** update single row of the table: "locationService" */
  update_locationService_by_pk?: Maybe<LocationService>;
  /** update multiples rows of table: "locationService" */
  update_locationService_many?: Maybe<Array<Maybe<LocationService_Mutation_Response>>>;
  /** update single row of the table: "location" */
  update_location_by_pk?: Maybe<Location>;
  /** update multiples rows of table: "location" */
  update_location_many?: Maybe<Array<Maybe<Location_Mutation_Response>>>;
  /** update data of the table: "marketingTag" */
  update_marketingTag?: Maybe<MarketingTag_Mutation_Response>;
  /** update data of the table: "marketingTagEmail" */
  update_marketingTagEmail?: Maybe<MarketingTagEmail_Mutation_Response>;
  /** update single row of the table: "marketingTagEmail" */
  update_marketingTagEmail_by_pk?: Maybe<MarketingTagEmail>;
  /** update multiples rows of table: "marketingTagEmail" */
  update_marketingTagEmail_many?: Maybe<Array<Maybe<MarketingTagEmail_Mutation_Response>>>;
  /** update data of the table: "marketingTagSms" */
  update_marketingTagSms?: Maybe<MarketingTagSms_Mutation_Response>;
  /** update single row of the table: "marketingTagSms" */
  update_marketingTagSms_by_pk?: Maybe<MarketingTagSms>;
  /** update multiples rows of table: "marketingTagSms" */
  update_marketingTagSms_many?: Maybe<Array<Maybe<MarketingTagSms_Mutation_Response>>>;
  /** update single row of the table: "marketingTag" */
  update_marketingTag_by_pk?: Maybe<MarketingTag>;
  /** update multiples rows of table: "marketingTag" */
  update_marketingTag_many?: Maybe<Array<Maybe<MarketingTag_Mutation_Response>>>;
  /** update data of the table: "mediaTag" */
  update_mediaTag?: Maybe<MediaTag_Mutation_Response>;
  /** update single row of the table: "mediaTag" */
  update_mediaTag_by_pk?: Maybe<MediaTag>;
  /** update multiples rows of table: "mediaTag" */
  update_mediaTag_many?: Maybe<Array<Maybe<MediaTag_Mutation_Response>>>;
  /** update data of the table: "membership" */
  update_membership?: Maybe<Membership_Mutation_Response>;
  /** update data of the table: "membershipCoupon" */
  update_membershipCoupon?: Maybe<MembershipCoupon_Mutation_Response>;
  /** update single row of the table: "membershipCoupon" */
  update_membershipCoupon_by_pk?: Maybe<MembershipCoupon>;
  /** update multiples rows of table: "membershipCoupon" */
  update_membershipCoupon_many?: Maybe<Array<Maybe<MembershipCoupon_Mutation_Response>>>;
  /** update data of the table: "membershipType" */
  update_membershipType?: Maybe<MembershipType_Mutation_Response>;
  /** update single row of the table: "membershipType" */
  update_membershipType_by_pk?: Maybe<MembershipType>;
  /** update multiples rows of table: "membershipType" */
  update_membershipType_many?: Maybe<Array<Maybe<MembershipType_Mutation_Response>>>;
  /** update single row of the table: "membership" */
  update_membership_by_pk?: Maybe<Membership>;
  /** update multiples rows of table: "membership" */
  update_membership_many?: Maybe<Array<Maybe<Membership_Mutation_Response>>>;
  /** update data of the table: "note" */
  update_note?: Maybe<Note_Mutation_Response>;
  /** update data of the table: "noteSignature" */
  update_noteSignature?: Maybe<NoteSignature_Mutation_Response>;
  /** update single row of the table: "noteSignature" */
  update_noteSignature_by_pk?: Maybe<NoteSignature>;
  /** update multiples rows of table: "noteSignature" */
  update_noteSignature_many?: Maybe<Array<Maybe<NoteSignature_Mutation_Response>>>;
  /** update single row of the table: "note" */
  update_note_by_pk?: Maybe<Note>;
  /** update multiples rows of table: "note" */
  update_note_many?: Maybe<Array<Maybe<Note_Mutation_Response>>>;
  /** update data of the table: "organization" */
  update_organization?: Maybe<Organization_Mutation_Response>;
  /** update data of the table: "organizationConfiguration" */
  update_organizationConfiguration?: Maybe<OrganizationConfiguration_Mutation_Response>;
  /** update single row of the table: "organizationConfiguration" */
  update_organizationConfiguration_by_pk?: Maybe<OrganizationConfiguration>;
  /** update multiples rows of table: "organizationConfiguration" */
  update_organizationConfiguration_many?: Maybe<Array<Maybe<OrganizationConfiguration_Mutation_Response>>>;
  /** update single row of the table: "organization" */
  update_organization_by_pk?: Maybe<Organization>;
  /** update multiples rows of table: "organization" */
  update_organization_many?: Maybe<Array<Maybe<Organization_Mutation_Response>>>;
  /** update data of the table: "package" */
  update_package?: Maybe<Package_Mutation_Response>;
  /** update single row of the table: "package" */
  update_package_by_pk?: Maybe<Package>;
  /** update multiples rows of table: "package" */
  update_package_many?: Maybe<Array<Maybe<Package_Mutation_Response>>>;
  /** update data of the table: "patient" */
  update_patient?: Maybe<Patient_Mutation_Response>;
  /** update data of the table: "patientAddress" */
  update_patientAddress?: Maybe<PatientAddress_Mutation_Response>;
  /** update single row of the table: "patientAddress" */
  update_patientAddress_by_pk?: Maybe<PatientAddress>;
  /** update multiples rows of table: "patientAddress" */
  update_patientAddress_many?: Maybe<Array<Maybe<PatientAddress_Mutation_Response>>>;
  /** update data of the table: "patientConsent" */
  update_patientConsent?: Maybe<PatientConsent_Mutation_Response>;
  /** update single row of the table: "patientConsent" */
  update_patientConsent_by_pk?: Maybe<PatientConsent>;
  /** update multiples rows of table: "patientConsent" */
  update_patientConsent_many?: Maybe<Array<Maybe<PatientConsent_Mutation_Response>>>;
  /** update data of the table: "patientConversation" */
  update_patientConversation?: Maybe<PatientConversation_Mutation_Response>;
  /** update single row of the table: "patientConversation" */
  update_patientConversation_by_pk?: Maybe<PatientConversation>;
  /** update multiples rows of table: "patientConversation" */
  update_patientConversation_many?: Maybe<Array<Maybe<PatientConversation_Mutation_Response>>>;
  /** update data of the table: "patientFlowsheetData" */
  update_patientFlowsheetData?: Maybe<PatientFlowsheetData_Mutation_Response>;
  /** update single row of the table: "patientFlowsheetData" */
  update_patientFlowsheetData_by_pk?: Maybe<PatientFlowsheetData>;
  /** update multiples rows of table: "patientFlowsheetData" */
  update_patientFlowsheetData_many?: Maybe<Array<Maybe<PatientFlowsheetData_Mutation_Response>>>;
  /** update data of the table: "patientFlowsheetRow" */
  update_patientFlowsheetRow?: Maybe<PatientFlowsheetRow_Mutation_Response>;
  /** update single row of the table: "patientFlowsheetRow" */
  update_patientFlowsheetRow_by_pk?: Maybe<PatientFlowsheetRow>;
  /** update multiples rows of table: "patientFlowsheetRow" */
  update_patientFlowsheetRow_many?: Maybe<Array<Maybe<PatientFlowsheetRow_Mutation_Response>>>;
  /** update data of the table: "patientInvite" */
  update_patientInvite?: Maybe<PatientInvite_Mutation_Response>;
  /** update single row of the table: "patientInvite" */
  update_patientInvite_by_pk?: Maybe<PatientInvite>;
  /** update multiples rows of table: "patientInvite" */
  update_patientInvite_many?: Maybe<Array<Maybe<PatientInvite_Mutation_Response>>>;
  /** update data of the table: "patientMembership" */
  update_patientMembership?: Maybe<PatientMembership_Mutation_Response>;
  /** update single row of the table: "patientMembership" */
  update_patientMembership_by_pk?: Maybe<PatientMembership>;
  /** update multiples rows of table: "patientMembership" */
  update_patientMembership_many?: Maybe<Array<Maybe<PatientMembership_Mutation_Response>>>;
  /** update data of the table: "patientSystemNotice" */
  update_patientSystemNotice?: Maybe<PatientSystemNotice_Mutation_Response>;
  /** update single row of the table: "patientSystemNotice" */
  update_patientSystemNotice_by_pk?: Maybe<PatientSystemNotice>;
  /** update multiples rows of table: "patientSystemNotice" */
  update_patientSystemNotice_many?: Maybe<Array<Maybe<PatientSystemNotice_Mutation_Response>>>;
  /** update data of the table: "patientTag" */
  update_patientTag?: Maybe<PatientTag_Mutation_Response>;
  /** update single row of the table: "patientTag" */
  update_patientTag_by_pk?: Maybe<PatientTag>;
  /** update multiples rows of table: "patientTag" */
  update_patientTag_many?: Maybe<Array<Maybe<PatientTag_Mutation_Response>>>;
  /** update data of the table: "patientTags" */
  update_patientTags?: Maybe<PatientTags_Mutation_Response>;
  /** update single row of the table: "patientTags" */
  update_patientTags_by_pk?: Maybe<PatientTags>;
  /** update multiples rows of table: "patientTags" */
  update_patientTags_many?: Maybe<Array<Maybe<PatientTags_Mutation_Response>>>;
  /** update data of the table: "patientWallet" */
  update_patientWallet?: Maybe<PatientWallet_Mutation_Response>;
  /** update data of the table: "patientWalletCoupon" */
  update_patientWalletCoupon?: Maybe<PatientWalletCoupon_Mutation_Response>;
  /** update single row of the table: "patientWalletCoupon" */
  update_patientWalletCoupon_by_pk?: Maybe<PatientWalletCoupon>;
  /** update multiples rows of table: "patientWalletCoupon" */
  update_patientWalletCoupon_many?: Maybe<Array<Maybe<PatientWalletCoupon_Mutation_Response>>>;
  /** update data of the table: "patientWalletTransaction" */
  update_patientWalletTransaction?: Maybe<PatientWalletTransaction_Mutation_Response>;
  /** update single row of the table: "patientWalletTransaction" */
  update_patientWalletTransaction_by_pk?: Maybe<PatientWalletTransaction>;
  /** update multiples rows of table: "patientWalletTransaction" */
  update_patientWalletTransaction_many?: Maybe<Array<Maybe<PatientWalletTransaction_Mutation_Response>>>;
  /** update single row of the table: "patientWallet" */
  update_patientWallet_by_pk?: Maybe<PatientWallet>;
  /** update multiples rows of table: "patientWallet" */
  update_patientWallet_many?: Maybe<Array<Maybe<PatientWallet_Mutation_Response>>>;
  /** update data of the table: "patientWorkspace" */
  update_patientWorkspace?: Maybe<PatientWorkspace_Mutation_Response>;
  /** update single row of the table: "patientWorkspace" */
  update_patientWorkspace_by_pk?: Maybe<PatientWorkspace>;
  /** update multiples rows of table: "patientWorkspace" */
  update_patientWorkspace_many?: Maybe<Array<Maybe<PatientWorkspace_Mutation_Response>>>;
  /** update single row of the table: "patient" */
  update_patient_by_pk?: Maybe<Patient>;
  /** update multiples rows of table: "patient" */
  update_patient_many?: Maybe<Array<Maybe<Patient_Mutation_Response>>>;
  /** update data of the table: "payment" */
  update_payment?: Maybe<Payment_Mutation_Response>;
  /** update data of the table: "paymentMethod" */
  update_paymentMethod?: Maybe<PaymentMethod_Mutation_Response>;
  /** update single row of the table: "paymentMethod" */
  update_paymentMethod_by_pk?: Maybe<PaymentMethod>;
  /** update multiples rows of table: "paymentMethod" */
  update_paymentMethod_many?: Maybe<Array<Maybe<PaymentMethod_Mutation_Response>>>;
  /** update data of the table: "paymentRelationship" */
  update_paymentRelationship?: Maybe<PaymentRelationship_Mutation_Response>;
  /** update single row of the table: "paymentRelationship" */
  update_paymentRelationship_by_pk?: Maybe<PaymentRelationship>;
  /** update multiples rows of table: "paymentRelationship" */
  update_paymentRelationship_many?: Maybe<Array<Maybe<PaymentRelationship_Mutation_Response>>>;
  /** update single row of the table: "payment" */
  update_payment_by_pk?: Maybe<Payment>;
  /** update multiples rows of table: "payment" */
  update_payment_many?: Maybe<Array<Maybe<Payment_Mutation_Response>>>;
  /** update data of the table: "payrixTerminal" */
  update_payrixTerminal?: Maybe<PayrixTerminal_Mutation_Response>;
  /** update data of the table: "payrixTerminalTransaction" */
  update_payrixTerminalTransaction?: Maybe<PayrixTerminalTransaction_Mutation_Response>;
  /** update single row of the table: "payrixTerminalTransaction" */
  update_payrixTerminalTransaction_by_pk?: Maybe<PayrixTerminalTransaction>;
  /** update multiples rows of table: "payrixTerminalTransaction" */
  update_payrixTerminalTransaction_many?: Maybe<Array<Maybe<PayrixTerminalTransaction_Mutation_Response>>>;
  /** update single row of the table: "payrixTerminal" */
  update_payrixTerminal_by_pk?: Maybe<PayrixTerminal>;
  /** update multiples rows of table: "payrixTerminal" */
  update_payrixTerminal_many?: Maybe<Array<Maybe<PayrixTerminal_Mutation_Response>>>;
  /** update data of the table: "payrixWebAlert" */
  update_payrixWebAlert?: Maybe<PayrixWebAlert_Mutation_Response>;
  /** update single row of the table: "payrixWebAlert" */
  update_payrixWebAlert_by_pk?: Maybe<PayrixWebAlert>;
  /** update multiples rows of table: "payrixWebAlert" */
  update_payrixWebAlert_many?: Maybe<Array<Maybe<PayrixWebAlert_Mutation_Response>>>;
  /** update data of the table: "photo" */
  update_photo?: Maybe<Photo_Mutation_Response>;
  /** update data of the table: "photoMediaTag" */
  update_photoMediaTag?: Maybe<PhotoMediaTag_Mutation_Response>;
  /** update single row of the table: "photoMediaTag" */
  update_photoMediaTag_by_pk?: Maybe<PhotoMediaTag>;
  /** update multiples rows of table: "photoMediaTag" */
  update_photoMediaTag_many?: Maybe<Array<Maybe<PhotoMediaTag_Mutation_Response>>>;
  /** update single row of the table: "photo" */
  update_photo_by_pk?: Maybe<Photo>;
  /** update multiples rows of table: "photo" */
  update_photo_many?: Maybe<Array<Maybe<Photo_Mutation_Response>>>;
  /** update data of the table: "pin" */
  update_pin?: Maybe<Pin_Mutation_Response>;
  /** update single row of the table: "pin" */
  update_pin_by_pk?: Maybe<Pin>;
  /** update multiples rows of table: "pin" */
  update_pin_many?: Maybe<Array<Maybe<Pin_Mutation_Response>>>;
  /** update data of the table: "postStatus" */
  update_postStatus?: Maybe<PostStatus_Mutation_Response>;
  /** update single row of the table: "postStatus" */
  update_postStatus_by_pk?: Maybe<PostStatus>;
  /** update multiples rows of table: "postStatus" */
  update_postStatus_many?: Maybe<Array<Maybe<PostStatus_Mutation_Response>>>;
  /** update data of the table: "prescription" */
  update_prescription?: Maybe<Prescription_Mutation_Response>;
  /** update data of the table: "prescriptionDetail" */
  update_prescriptionDetail?: Maybe<PrescriptionDetail_Mutation_Response>;
  /** update single row of the table: "prescriptionDetail" */
  update_prescriptionDetail_by_pk?: Maybe<PrescriptionDetail>;
  /** update multiples rows of table: "prescriptionDetail" */
  update_prescriptionDetail_many?: Maybe<Array<Maybe<PrescriptionDetail_Mutation_Response>>>;
  /** update single row of the table: "prescription" */
  update_prescription_by_pk?: Maybe<Prescription>;
  /** update multiples rows of table: "prescription" */
  update_prescription_many?: Maybe<Array<Maybe<Prescription_Mutation_Response>>>;
  /** update data of the table: "promotion" */
  update_promotion?: Maybe<Promotion_Mutation_Response>;
  /** update data of the table: "promotionCoupons" */
  update_promotionCoupons?: Maybe<PromotionCoupons_Mutation_Response>;
  /** update single row of the table: "promotionCoupons" */
  update_promotionCoupons_by_pk?: Maybe<PromotionCoupons>;
  /** update multiples rows of table: "promotionCoupons" */
  update_promotionCoupons_many?: Maybe<Array<Maybe<PromotionCoupons_Mutation_Response>>>;
  /** update data of the table: "promotionServices" */
  update_promotionServices?: Maybe<PromotionServices_Mutation_Response>;
  /** update single row of the table: "promotionServices" */
  update_promotionServices_by_pk?: Maybe<PromotionServices>;
  /** update multiples rows of table: "promotionServices" */
  update_promotionServices_many?: Maybe<Array<Maybe<PromotionServices_Mutation_Response>>>;
  /** update single row of the table: "promotion" */
  update_promotion_by_pk?: Maybe<Promotion>;
  /** update multiples rows of table: "promotion" */
  update_promotion_many?: Maybe<Array<Maybe<Promotion_Mutation_Response>>>;
  /** update data of the table: "provider" */
  update_provider?: Maybe<Provider_Mutation_Response>;
  /** update data of the table: "providerSchedule" */
  update_providerSchedule?: Maybe<ProviderSchedule_Mutation_Response>;
  /** update single row of the table: "providerSchedule" */
  update_providerSchedule_by_pk?: Maybe<ProviderSchedule>;
  /** update multiples rows of table: "providerSchedule" */
  update_providerSchedule_many?: Maybe<Array<Maybe<ProviderSchedule_Mutation_Response>>>;
  /** update single row of the table: "provider" */
  update_provider_by_pk?: Maybe<Provider>;
  /** update multiples rows of table: "provider" */
  update_provider_many?: Maybe<Array<Maybe<Provider_Mutation_Response>>>;
  /** update data of the table: "purchaseOrder" */
  update_purchaseOrder?: Maybe<PurchaseOrder_Mutation_Response>;
  /** update single row of the table: "purchaseOrder" */
  update_purchaseOrder_by_pk?: Maybe<PurchaseOrder>;
  /** update multiples rows of table: "purchaseOrder" */
  update_purchaseOrder_many?: Maybe<Array<Maybe<PurchaseOrder_Mutation_Response>>>;
  /** update data of the table: "recurrenceType" */
  update_recurrenceType?: Maybe<RecurrenceType_Mutation_Response>;
  /** update single row of the table: "recurrenceType" */
  update_recurrenceType_by_pk?: Maybe<RecurrenceType>;
  /** update multiples rows of table: "recurrenceType" */
  update_recurrenceType_many?: Maybe<Array<Maybe<RecurrenceType_Mutation_Response>>>;
  /** update data of the table: "refund" */
  update_refund?: Maybe<Refund_Mutation_Response>;
  /** update single row of the table: "refund" */
  update_refund_by_pk?: Maybe<Refund>;
  /** update multiples rows of table: "refund" */
  update_refund_many?: Maybe<Array<Maybe<Refund_Mutation_Response>>>;
  /** update data of the table: "resolutionStatus" */
  update_resolutionStatus?: Maybe<ResolutionStatus_Mutation_Response>;
  /** update single row of the table: "resolutionStatus" */
  update_resolutionStatus_by_pk?: Maybe<ResolutionStatus>;
  /** update multiples rows of table: "resolutionStatus" */
  update_resolutionStatus_many?: Maybe<Array<Maybe<ResolutionStatus_Mutation_Response>>>;
  /** update data of the table: "rewardProgram" */
  update_rewardProgram?: Maybe<RewardProgram_Mutation_Response>;
  /** update data of the table: "rewardProgramEnrollment" */
  update_rewardProgramEnrollment?: Maybe<RewardProgramEnrollment_Mutation_Response>;
  /** update single row of the table: "rewardProgramEnrollment" */
  update_rewardProgramEnrollment_by_pk?: Maybe<RewardProgramEnrollment>;
  /** update multiples rows of table: "rewardProgramEnrollment" */
  update_rewardProgramEnrollment_many?: Maybe<Array<Maybe<RewardProgramEnrollment_Mutation_Response>>>;
  /** update single row of the table: "rewardProgram" */
  update_rewardProgram_by_pk?: Maybe<RewardProgram>;
  /** update multiples rows of table: "rewardProgram" */
  update_rewardProgram_many?: Maybe<Array<Maybe<RewardProgram_Mutation_Response>>>;
  /** update data of the table: "room" */
  update_room?: Maybe<Room_Mutation_Response>;
  /** update single row of the table: "room" */
  update_room_by_pk?: Maybe<Room>;
  /** update multiples rows of table: "room" */
  update_room_many?: Maybe<Array<Maybe<Room_Mutation_Response>>>;
  /** update data of the table: "rsReview" */
  update_rsReview?: Maybe<RsReview_Mutation_Response>;
  /** update single row of the table: "rsReview" */
  update_rsReview_by_pk?: Maybe<RsReview>;
  /** update multiples rows of table: "rsReview" */
  update_rsReview_many?: Maybe<Array<Maybe<RsReview_Mutation_Response>>>;
  /** update data of the table: "service" */
  update_service?: Maybe<Service_Mutation_Response>;
  /** update data of the table: "serviceAddOn" */
  update_serviceAddOn?: Maybe<ServiceAddOn_Mutation_Response>;
  /** update single row of the table: "serviceAddOn" */
  update_serviceAddOn_by_pk?: Maybe<ServiceAddOn>;
  /** update multiples rows of table: "serviceAddOn" */
  update_serviceAddOn_many?: Maybe<Array<Maybe<ServiceAddOn_Mutation_Response>>>;
  /** update data of the table: "serviceCategory" */
  update_serviceCategory?: Maybe<ServiceCategory_Mutation_Response>;
  /** update single row of the table: "serviceCategory" */
  update_serviceCategory_by_pk?: Maybe<ServiceCategory>;
  /** update multiples rows of table: "serviceCategory" */
  update_serviceCategory_many?: Maybe<Array<Maybe<ServiceCategory_Mutation_Response>>>;
  /** update data of the table: "serviceConsent" */
  update_serviceConsent?: Maybe<ServiceConsent_Mutation_Response>;
  /** update single row of the table: "serviceConsent" */
  update_serviceConsent_by_pk?: Maybe<ServiceConsent>;
  /** update multiples rows of table: "serviceConsent" */
  update_serviceConsent_many?: Maybe<Array<Maybe<ServiceConsent_Mutation_Response>>>;
  /** update data of the table: "serviceConsumableType" */
  update_serviceConsumableType?: Maybe<ServiceConsumableType_Mutation_Response>;
  /** update single row of the table: "serviceConsumableType" */
  update_serviceConsumableType_by_pk?: Maybe<ServiceConsumableType>;
  /** update multiples rows of table: "serviceConsumableType" */
  update_serviceConsumableType_many?: Maybe<Array<Maybe<ServiceConsumableType_Mutation_Response>>>;
  /** update data of the table: "serviceCoupon" */
  update_serviceCoupon?: Maybe<ServiceCoupon_Mutation_Response>;
  /** update single row of the table: "serviceCoupon" */
  update_serviceCoupon_by_pk?: Maybe<ServiceCoupon>;
  /** update multiples rows of table: "serviceCoupon" */
  update_serviceCoupon_many?: Maybe<Array<Maybe<ServiceCoupon_Mutation_Response>>>;
  /** update data of the table: "serviceCustomForm" */
  update_serviceCustomForm?: Maybe<ServiceCustomForm_Mutation_Response>;
  /** update single row of the table: "serviceCustomForm" */
  update_serviceCustomForm_by_pk?: Maybe<ServiceCustomForm>;
  /** update multiples rows of table: "serviceCustomForm" */
  update_serviceCustomForm_many?: Maybe<Array<Maybe<ServiceCustomForm_Mutation_Response>>>;
  /** update data of the table: "serviceDeviceType" */
  update_serviceDeviceType?: Maybe<ServiceDeviceType_Mutation_Response>;
  /** update single row of the table: "serviceDeviceType" */
  update_serviceDeviceType_by_pk?: Maybe<ServiceDeviceType>;
  /** update multiples rows of table: "serviceDeviceType" */
  update_serviceDeviceType_many?: Maybe<Array<Maybe<ServiceDeviceType_Mutation_Response>>>;
  /** update data of the table: "serviceProvider" */
  update_serviceProvider?: Maybe<ServiceProvider_Mutation_Response>;
  /** update single row of the table: "serviceProvider" */
  update_serviceProvider_by_pk?: Maybe<ServiceProvider>;
  /** update multiples rows of table: "serviceProvider" */
  update_serviceProvider_many?: Maybe<Array<Maybe<ServiceProvider_Mutation_Response>>>;
  /** update data of the table: "serviceUnit" */
  update_serviceUnit?: Maybe<ServiceUnit_Mutation_Response>;
  /** update single row of the table: "serviceUnit" */
  update_serviceUnit_by_pk?: Maybe<ServiceUnit>;
  /** update multiples rows of table: "serviceUnit" */
  update_serviceUnit_many?: Maybe<Array<Maybe<ServiceUnit_Mutation_Response>>>;
  /** update single row of the table: "service" */
  update_service_by_pk?: Maybe<Service>;
  /** update multiples rows of table: "service" */
  update_service_many?: Maybe<Array<Maybe<Service_Mutation_Response>>>;
  /** update data of the table: "shrink" */
  update_shrink?: Maybe<Shrink_Mutation_Response>;
  /** update single row of the table: "shrink" */
  update_shrink_by_pk?: Maybe<Shrink>;
  /** update multiples rows of table: "shrink" */
  update_shrink_many?: Maybe<Array<Maybe<Shrink_Mutation_Response>>>;
  /** update data of the table: "signature" */
  update_signature?: Maybe<Signature_Mutation_Response>;
  /** update single row of the table: "signature" */
  update_signature_by_pk?: Maybe<Signature>;
  /** update multiples rows of table: "signature" */
  update_signature_many?: Maybe<Array<Maybe<Signature_Mutation_Response>>>;
  /** update data of the table: "smartNote" */
  update_smartNote?: Maybe<SmartNote_Mutation_Response>;
  /** update single row of the table: "smartNote" */
  update_smartNote_by_pk?: Maybe<SmartNote>;
  /** update multiples rows of table: "smartNote" */
  update_smartNote_many?: Maybe<Array<Maybe<SmartNote_Mutation_Response>>>;
  /** update data of the table: "smsTemplate" */
  update_smsTemplate?: Maybe<SmsTemplate_Mutation_Response>;
  /** update data of the table: "smsTemplatePatient" */
  update_smsTemplatePatient?: Maybe<SmsTemplatePatient_Mutation_Response>;
  /** update single row of the table: "smsTemplatePatient" */
  update_smsTemplatePatient_by_pk?: Maybe<SmsTemplatePatient>;
  /** update multiples rows of table: "smsTemplatePatient" */
  update_smsTemplatePatient_many?: Maybe<Array<Maybe<SmsTemplatePatient_Mutation_Response>>>;
  /** update data of the table: "smsTemplateSendQueue" */
  update_smsTemplateSendQueue?: Maybe<SmsTemplateSendQueue_Mutation_Response>;
  /** update single row of the table: "smsTemplateSendQueue" */
  update_smsTemplateSendQueue_by_pk?: Maybe<SmsTemplateSendQueue>;
  /** update multiples rows of table: "smsTemplateSendQueue" */
  update_smsTemplateSendQueue_many?: Maybe<Array<Maybe<SmsTemplateSendQueue_Mutation_Response>>>;
  /** update single row of the table: "smsTemplate" */
  update_smsTemplate_by_pk?: Maybe<SmsTemplate>;
  /** update multiples rows of table: "smsTemplate" */
  update_smsTemplate_many?: Maybe<Array<Maybe<SmsTemplate_Mutation_Response>>>;
  /** update data of the table: "socialMediaAccount" */
  update_socialMediaAccount?: Maybe<SocialMediaAccount_Mutation_Response>;
  /** update data of the table: "socialMediaAccountPost" */
  update_socialMediaAccountPost?: Maybe<SocialMediaAccountPost_Mutation_Response>;
  /** update single row of the table: "socialMediaAccountPost" */
  update_socialMediaAccountPost_by_pk?: Maybe<SocialMediaAccountPost>;
  /** update multiples rows of table: "socialMediaAccountPost" */
  update_socialMediaAccountPost_many?: Maybe<Array<Maybe<SocialMediaAccountPost_Mutation_Response>>>;
  /** update single row of the table: "socialMediaAccount" */
  update_socialMediaAccount_by_pk?: Maybe<SocialMediaAccount>;
  /** update multiples rows of table: "socialMediaAccount" */
  update_socialMediaAccount_many?: Maybe<Array<Maybe<SocialMediaAccount_Mutation_Response>>>;
  /** update data of the table: "socialMediaPlatform" */
  update_socialMediaPlatform?: Maybe<SocialMediaPlatform_Mutation_Response>;
  /** update single row of the table: "socialMediaPlatform" */
  update_socialMediaPlatform_by_pk?: Maybe<SocialMediaPlatform>;
  /** update multiples rows of table: "socialMediaPlatform" */
  update_socialMediaPlatform_many?: Maybe<Array<Maybe<SocialMediaPlatform_Mutation_Response>>>;
  /** update data of the table: "socialMediaPost" */
  update_socialMediaPost?: Maybe<SocialMediaPost_Mutation_Response>;
  /** update single row of the table: "socialMediaPost" */
  update_socialMediaPost_by_pk?: Maybe<SocialMediaPost>;
  /** update multiples rows of table: "socialMediaPost" */
  update_socialMediaPost_many?: Maybe<Array<Maybe<SocialMediaPost_Mutation_Response>>>;
  /** update data of the table: "tag" */
  update_tag?: Maybe<Tag_Mutation_Response>;
  /** update single row of the table: "tag" */
  update_tag_by_pk?: Maybe<Tag>;
  /** update multiples rows of table: "tag" */
  update_tag_many?: Maybe<Array<Maybe<Tag_Mutation_Response>>>;
  /** update data of the table: "task" */
  update_task?: Maybe<Task_Mutation_Response>;
  /** update data of the table: "taskComment" */
  update_taskComment?: Maybe<TaskComment_Mutation_Response>;
  /** update single row of the table: "taskComment" */
  update_taskComment_by_pk?: Maybe<TaskComment>;
  /** update multiples rows of table: "taskComment" */
  update_taskComment_many?: Maybe<Array<Maybe<TaskComment_Mutation_Response>>>;
  /** update single row of the table: "task" */
  update_task_by_pk?: Maybe<Task>;
  /** update multiples rows of table: "task" */
  update_task_many?: Maybe<Array<Maybe<Task_Mutation_Response>>>;
  /** update data of the table: "telehealthMeeting" */
  update_telehealthMeeting?: Maybe<TelehealthMeeting_Mutation_Response>;
  /** update single row of the table: "telehealthMeeting" */
  update_telehealthMeeting_by_pk?: Maybe<TelehealthMeeting>;
  /** update multiples rows of table: "telehealthMeeting" */
  update_telehealthMeeting_many?: Maybe<Array<Maybe<TelehealthMeeting_Mutation_Response>>>;
  /** update data of the table: "unusedAppointmentServiceConsumableType" */
  update_unusedAppointmentServiceConsumableType?: Maybe<UnusedAppointmentServiceConsumableType_Mutation_Response>;
  /** update single row of the table: "unusedAppointmentServiceConsumableType" */
  update_unusedAppointmentServiceConsumableType_by_pk?: Maybe<UnusedAppointmentServiceConsumableType>;
  /** update multiples rows of table: "unusedAppointmentServiceConsumableType" */
  update_unusedAppointmentServiceConsumableType_many?: Maybe<Array<Maybe<UnusedAppointmentServiceConsumableType_Mutation_Response>>>;
  /** update data of the table: "user" */
  update_user?: Maybe<User_Mutation_Response>;
  /** update data of the table: "userInvite" */
  update_userInvite?: Maybe<UserInvite_Mutation_Response>;
  /** update single row of the table: "userInvite" */
  update_userInvite_by_pk?: Maybe<UserInvite>;
  /** update multiples rows of table: "userInvite" */
  update_userInvite_many?: Maybe<Array<Maybe<UserInvite_Mutation_Response>>>;
  /** update data of the table: "userOrganization" */
  update_userOrganization?: Maybe<UserOrganization_Mutation_Response>;
  /** update single row of the table: "userOrganization" */
  update_userOrganization_by_pk?: Maybe<UserOrganization>;
  /** update multiples rows of table: "userOrganization" */
  update_userOrganization_many?: Maybe<Array<Maybe<UserOrganization_Mutation_Response>>>;
  /** update data of the table: "userWorkspace" */
  update_userWorkspace?: Maybe<UserWorkspace_Mutation_Response>;
  /** update single row of the table: "userWorkspace" */
  update_userWorkspace_by_pk?: Maybe<UserWorkspace>;
  /** update multiples rows of table: "userWorkspace" */
  update_userWorkspace_many?: Maybe<Array<Maybe<UserWorkspace_Mutation_Response>>>;
  /** update single row of the table: "user" */
  update_user_by_pk?: Maybe<User>;
  /** update multiples rows of table: "user" */
  update_user_many?: Maybe<Array<Maybe<User_Mutation_Response>>>;
  /** update data of the table: "vitals" */
  update_vitals?: Maybe<Vitals_Mutation_Response>;
  /** update single row of the table: "vitals" */
  update_vitals_by_pk?: Maybe<Vitals>;
  /** update multiples rows of table: "vitals" */
  update_vitals_many?: Maybe<Array<Maybe<Vitals_Mutation_Response>>>;
  /** update data of the table: "workspace" */
  update_workspace?: Maybe<Workspace_Mutation_Response>;
  /** update data of the table: "workspaceConfiguration" */
  update_workspaceConfiguration?: Maybe<WorkspaceConfiguration_Mutation_Response>;
  /** update single row of the table: "workspaceConfiguration" */
  update_workspaceConfiguration_by_pk?: Maybe<WorkspaceConfiguration>;
  /** update multiples rows of table: "workspaceConfiguration" */
  update_workspaceConfiguration_many?: Maybe<Array<Maybe<WorkspaceConfiguration_Mutation_Response>>>;
  /** update single row of the table: "workspace" */
  update_workspace_by_pk?: Maybe<Workspace>;
  /** update multiples rows of table: "workspace" */
  update_workspace_many?: Maybe<Array<Maybe<Workspace_Mutation_Response>>>;
  /** update data of the table: "zdReview" */
  update_zdReview?: Maybe<ZdReview_Mutation_Response>;
  /** update single row of the table: "zdReview" */
  update_zdReview_by_pk?: Maybe<ZdReview>;
  /** update multiples rows of table: "zdReview" */
  update_zdReview_many?: Maybe<Array<Maybe<ZdReview_Mutation_Response>>>;
  /** Allows patient upsert to anonymous users without exposing extra Hasura permissions */
  upsertPatient?: Maybe<UpsertPatientOutput>;
  validateCoupon?: Maybe<ValidateCouponOutput>;
  verifyPin?: Maybe<SuccessAnswer>;
  warmTranscriber?: Maybe<TranscribeAudioOutput>;
};


/** mutation root */
export type Mutation_RootActivateFinixDeviceArgs = {
  finixDeviceId: Scalars['String'];
};


/** mutation root */
export type Mutation_RootApplyBackwardsLineItemsArgs = {
  workspaceId?: InputMaybe<Scalars['uuid']>;
};


/** mutation root */
export type Mutation_RootArchiveInvoiceArgs = {
  invoiceId: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootBatchEmailTemplateArgs = {
  audienceId: Scalars['uuid'];
  emailTemplateId: Scalars['uuid'];
  op?: InputMaybe<Scalars['String']>;
  testEmail?: InputMaybe<Scalars['String']>;
};


/** mutation root */
export type Mutation_RootBookExternalAppointmentArgs = {
  input: BookExternalAppointmentInput;
};


/** mutation root */
export type Mutation_RootBookPatientAppointmentArgs = {
  input: BookPatientAppointmentInput;
};


/** mutation root */
export type Mutation_RootBulkScheduleAppointmentRemindersArgs = {
  workspaceId: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootCancelAppointmentArgs = {
  appointmentId: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootCancelDraftInvoiceArgs = {
  invoiceId: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootCancelInvoiceArgs = {
  invoiceId: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootCancelSubscriptionArgs = {
  patientMembershipId: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootClinicalMessageArgs = {
  appointmentId?: InputMaybe<Scalars['uuid']>;
  attachmentDocumentIds?: InputMaybe<Scalars['String']>;
  clinicalMessageId: Scalars['uuid'];
  locationId?: InputMaybe<Scalars['uuid']>;
  patientWorkspaceId: Scalars['uuid'];
  serviceNames?: InputMaybe<Scalars['String']>;
};


/** mutation root */
export type Mutation_RootClinicalMessageUnscheduledArgs = {
  appointmentId: Scalars['uuid'];
  messageType?: InputMaybe<Scalars['String']>;
  patientId: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootClinicalTestMessageArgs = {
  attachmentDocumentId?: InputMaybe<Scalars['uuid']>;
  fromAddress: Scalars['String'];
  messageSubject: Scalars['String'];
  messageText: Scalars['String'];
  testAddress: Scalars['String'];
};


/** mutation root */
export type Mutation_RootCollectAppointmentNoShowFeeArgs = {
  appointmentId: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootComplexUpdateInvoiceArgs = {
  invoiceId: Scalars['uuid'];
  set: ComplexUpdateInvoiceInput;
};


/** mutation root */
export type Mutation_RootConvertDocumentToPdfArgs = {
  documentId: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootConvertFileToSurveyJsConsentArgs = {
  consentId: Scalars['uuid'];
  filePath: Scalars['String'];
};


/** mutation root */
export type Mutation_RootCreateBeforeAndAfterImageArgs = {
  afterFilePath?: InputMaybe<Scalars['String']>;
  afterPhotoId: Scalars['uuid'];
  beforeFilePath?: InputMaybe<Scalars['String']>;
  beforePhotoId: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootCreateConsumableAndAddToLocationArgs = {
  consumable: Scalars['jsonb'];
  locationId: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootCreateCustomCloudflareHostnameArgs = {
  domain: Scalars['String'];
};


/** mutation root */
export type Mutation_RootCreateDraftInvoiceForAppointmentArgs = {
  address: Scalars['String'];
  appointmentId: Scalars['uuid'];
  paymentId: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootCreateFinixDeviceArgs = {
  description?: InputMaybe<Scalars['String']>;
  model: Scalars['String'];
  name: Scalars['String'];
  serialNumber?: InputMaybe<Scalars['String']>;
};


/** mutation root */
export type Mutation_RootCreateFinixPaymentInstrumentArgs = {
  cardHolderDetails?: InputMaybe<Scalars['jsonb']>;
  patientId: Scalars['uuid'];
  token: Scalars['String'];
};


/** mutation root */
export type Mutation_RootCreateFirebaseUserArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


/** mutation root */
export type Mutation_RootCreateInventoryTransactionArgs = {
  input: CreateInventoryTransactionInput;
};


/** mutation root */
export type Mutation_RootCreateTelehealthMeetingArgs = {
  appointmentId?: InputMaybe<Scalars['uuid']>;
  patientId: Scalars['uuid'];
  workspaceId: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootCustomInsertConsumableArgs = {
  consumable: Scalars['jsonb'];
};


/** mutation root */
export type Mutation_RootCustomInsertInvoiceArgs = {
  object: CustomInsertInvoiceInput;
};


/** mutation root */
export type Mutation_RootCustomUpdateConsumableArgs = {
  _set: Scalars['jsonb'];
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootCustomUpdateInvoiceArgs = {
  invoiceId: Scalars['uuid'];
  set: CustomUpdateInvoiceSetInput;
};


/** mutation root */
export type Mutation_RootDeauthorizeArgs = {
  authProfileId: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDeleteFirebaseUserArgs = {
  uid: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_ActivityLogArgs = {
  where: ActivityLog_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_ActivityLog_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_AddressArgs = {
  where: Address_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Address_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_AppointmentArgs = {
  where: Appointment_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_AppointmentConsentArgs = {
  where: AppointmentConsent_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_AppointmentConsent_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_AppointmentCustomFormArgs = {
  where: AppointmentCustomForm_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_AppointmentCustomForm_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_AppointmentServiceArgs = {
  where: AppointmentService_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_AppointmentServiceConsumableArgs = {
  where: AppointmentServiceConsumable_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_AppointmentServiceConsumable_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_AppointmentService_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_AppointmentTypeArgs = {
  where: AppointmentType_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_AppointmentType_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Appointment_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_AudienceArgs = {
  where: Audience_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_AudienceEmailArgs = {
  where: AudienceEmail_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_AudienceEmail_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Audience_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_AuthProfileArgs = {
  where: AuthProfile_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_AuthProfile_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_ClinicalMessageArgs = {
  where: ClinicalMessage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_ClinicalMessageStatusArgs = {
  where: ClinicalMessageStatus_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_ClinicalMessageStatus_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_ClinicalMessage_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_CompensationPolicyArgs = {
  where: CompensationPolicy_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_CompensationPolicyCommissionArgs = {
  where: CompensationPolicyCommission_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_CompensationPolicyCommission_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_CompensationPolicyTierArgs = {
  where: CompensationPolicyTier_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_CompensationPolicyTierCategoryArgs = {
  where: CompensationPolicyTierCategory_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_CompensationPolicyTierCategory_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_CompensationPolicyTierExemptionArgs = {
  where: CompensationPolicyTierExemption_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_CompensationPolicyTierExemption_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_CompensationPolicyTierInclusionArgs = {
  where: CompensationPolicyTierInclusion_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_CompensationPolicyTierInclusion_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_CompensationPolicyTier_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_CompensationPolicy_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_CompensationStrategyArgs = {
  where: CompensationStrategy_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_CompensationStrategyCommissionArgs = {
  where: CompensationStrategyCommission_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_CompensationStrategyCommission_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_CompensationStrategyTierArgs = {
  where: CompensationStrategyTier_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_CompensationStrategyTierCategoryArgs = {
  where: CompensationStrategyTierCategory_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_CompensationStrategyTierCategory_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_CompensationStrategyTierExemptionArgs = {
  where: CompensationStrategyTierExemption_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_CompensationStrategyTierExemption_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_CompensationStrategyTierInclusionArgs = {
  where: CompensationStrategyTierInclusion_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_CompensationStrategyTierInclusion_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_CompensationStrategyTier_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_CompensationStrategy_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_ConsentArgs = {
  where: Consent_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Consent_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_ConsumableArgs = {
  where: Consumable_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_ConsumableBrandArgs = {
  where: ConsumableBrand_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_ConsumableBrand_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_ConsumableSubtypeArgs = {
  where: ConsumableSubtype_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_ConsumableSubtype_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_ConsumableTypeArgs = {
  where: ConsumableType_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_ConsumableTypeConsumableSubtypeArgs = {
  where: ConsumableTypeConsumableSubtype_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_ConsumableTypeConsumableSubtype_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_ConsumableType_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Consumable_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_CouponArgs = {
  where: Coupon_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_CouponConfigurationArgs = {
  where: CouponConfiguration_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_CouponConfiguration_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_CouponScopeArgs = {
  where: CouponScope_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_CouponScope_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_CouponTypeArgs = {
  where: CouponType_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_CouponType_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Coupon_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_CustomFormArgs = {
  where: CustomForm_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_CustomFormFieldArgs = {
  where: CustomFormField_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_CustomFormFieldCategoryArgs = {
  where: CustomFormFieldCategory_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_CustomFormFieldCategory_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_CustomFormFieldResponseArgs = {
  where: CustomFormFieldResponse_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_CustomFormFieldResponse_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_CustomFormFieldTypeArgs = {
  where: CustomFormFieldType_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_CustomFormFieldType_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_CustomFormField_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_CustomFormOptionArgs = {
  where: CustomFormOption_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_CustomFormOption_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_CustomFormReplicatedFieldArgs = {
  where: CustomFormReplicatedField_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_CustomFormReplicatedField_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_CustomFormReplicatedRowArgs = {
  where: CustomFormReplicatedRow_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_CustomFormReplicatedRow_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_CustomFormResponseArgs = {
  where: CustomFormResponse_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_CustomFormResponse_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_CustomFormRowArgs = {
  where: CustomFormRow_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_CustomFormRow_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_CustomForm_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_CustomQuestionArgs = {
  where: CustomQuestion_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_CustomQuestionOptionArgs = {
  where: CustomQuestionOption_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_CustomQuestionOption_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_CustomQuestion_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_DegreeDesignationArgs = {
  where: DegreeDesignation_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_DegreeDesignation_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_DepositInvoiceLineItemArgs = {
  where: DepositInvoiceLineItem_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_DepositInvoiceLineItem_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_DeviceArgs = {
  where: Device_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_DeviceTypeArgs = {
  where: DeviceType_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_DeviceType_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Device_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_DiscountInvoiceLineItemArgs = {
  where: DiscountInvoiceLineItem_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_DiscountInvoiceLineItem_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_DocumentArgs = {
  where: Document_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_DocumentTagArgs = {
  where: DocumentTag_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_DocumentTag_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Document_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_EmailCampaignLogArgs = {
  where: EmailCampaignLog_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_EmailCampaignLog_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_EmailTemplateArgs = {
  where: EmailTemplate_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_EmailTemplatePatientArgs = {
  where: EmailTemplatePatient_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_EmailTemplatePatient_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_EmailTemplateSendQueueArgs = {
  where: EmailTemplateSendQueue_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_EmailTemplateSendQueue_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_EmailTemplate_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_ExternalLocationArgs = {
  where: ExternalLocation_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_ExternalLocation_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_ExternalProviderArgs = {
  where: ExternalProvider_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_ExternalProvider_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_ExternalReviewArgs = {
  where: ExternalReview_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_ExternalReview_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_FeeArgs = {
  where: Fee_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_FeeInvoiceLineItemArgs = {
  where: FeeInvoiceLineItem_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_FeeInvoiceLineItem_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Fee_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_FinixTerminalArgs = {
  where: FinixTerminal_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_FinixTerminal_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_FlowsheetArgs = {
  where: Flowsheet_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_FlowsheetParameterArgs = {
  where: FlowsheetParameter_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_FlowsheetParameterFlowsheetArgs = {
  where: FlowsheetParameterFlowsheet_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_FlowsheetParameterFlowsheet_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_FlowsheetParameterGroupArgs = {
  where: FlowsheetParameterGroup_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_FlowsheetParameterGroup_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_FlowsheetParameter_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Flowsheet_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_GenericInvoiceLineItemArgs = {
  where: GenericInvoiceLineItem_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_GenericInvoiceLineItemDiscountArgs = {
  where: GenericInvoiceLineItemDiscount_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_GenericInvoiceLineItemDiscount_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_GenericInvoiceLineItem_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_GiftCardArgs = {
  where: GiftCard_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_GiftCard_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_GoogleAccountArgs = {
  where: GoogleAccount_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_GoogleAccount_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_GoogleLocationArgs = {
  where: GoogleLocation_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_GoogleLocation_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_GoogleReviewArgs = {
  where: GoogleReview_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_GoogleReview_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_ImportJobArgs = {
  where: ImportJob_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_ImportJob_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_ImportMetadataArgs = {
  where: ImportMetadata_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_ImportMetadata_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_IncompatibleServicesArgs = {
  where: IncompatibleServices_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_IncompatibleServices_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_InternalAppointmentProviderArgs = {
  where: InternalAppointmentProvider_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_InternalAppointmentProvider_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_InventoryTransactionArgs = {
  where: InventoryTransaction_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_InventoryTransaction_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_InvoiceArgs = {
  where: Invoice_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_InvoiceConfigurationArgs = {
  where: InvoiceConfiguration_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_InvoiceConfiguration_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_InvoiceCouponArgs = {
  where: InvoiceCoupon_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_InvoiceCoupon_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_InvoiceMembershipArgs = {
  where: InvoiceMembership_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_InvoiceMembership_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_InvoicePackageArgs = {
  where: InvoicePackage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_InvoicePackage_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_InvoiceProductItemArgs = {
  where: InvoiceProductItem_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_InvoiceProductItem_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_InvoiceServiceConsumableArgs = {
  where: InvoiceServiceConsumable_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_InvoiceServiceConsumable_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_InvoiceServiceItemArgs = {
  where: InvoiceServiceItem_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_InvoiceServiceItem_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_InvoiceTemplateArgs = {
  where: InvoiceTemplate_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_InvoiceTemplate_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Invoice_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_JobArgs = {
  where: Job_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_JobStatusArgs = {
  where: JobStatus_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_JobStatus_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Job_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_LabResultArgs = {
  where: LabResult_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_LabResult_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_LeadFormArgs = {
  where: LeadForm_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_LeadFormEntryArgs = {
  where: LeadFormEntry_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_LeadFormEntryTagArgs = {
  where: LeadFormEntryTag_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_LeadFormEntryTag_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_LeadFormEntry_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_LeadFormFieldArgs = {
  where: LeadFormField_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_LeadFormFieldValueArgs = {
  where: LeadFormFieldValue_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_LeadFormFieldValue_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_LeadFormField_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_LeadFormLinkArgs = {
  where: LeadFormLink_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_LeadFormLink_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_LeadFormStepArgs = {
  where: LeadFormStep_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_LeadFormStep_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_LeadForm_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_LeadTagArgs = {
  where: LeadTag_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_LeadTag_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_LeadTrackedEventArgs = {
  where: LeadTrackedEvent_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_LeadTrackedEvent_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_LocationArgs = {
  where: Location_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_LocationCouponArgs = {
  where: LocationCoupon_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_LocationCoupon_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_LocationProviderArgs = {
  where: LocationProvider_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_LocationProvider_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_LocationScheduleArgs = {
  where: LocationSchedule_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_LocationSchedule_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_LocationServiceArgs = {
  where: LocationService_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_LocationService_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Location_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_MarketingTagArgs = {
  where: MarketingTag_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_MarketingTagEmailArgs = {
  where: MarketingTagEmail_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_MarketingTagEmail_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_MarketingTagSmsArgs = {
  where: MarketingTagSms_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_MarketingTagSms_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_MarketingTag_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_MediaTagArgs = {
  where: MediaTag_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_MediaTag_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_MembershipArgs = {
  where: Membership_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_MembershipCouponArgs = {
  where: MembershipCoupon_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_MembershipCoupon_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_MembershipTypeArgs = {
  where: MembershipType_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_MembershipType_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Membership_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_NoteArgs = {
  where: Note_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_NoteSignatureArgs = {
  where: NoteSignature_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_NoteSignature_By_PkArgs = {
  noteId: Scalars['uuid'];
  signatureId: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Note_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_OrganizationArgs = {
  where: Organization_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_OrganizationConfigurationArgs = {
  where: OrganizationConfiguration_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_OrganizationConfiguration_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Organization_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_PackageArgs = {
  where: Package_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Package_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_PatientArgs = {
  where: Patient_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_PatientAddressArgs = {
  where: PatientAddress_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_PatientAddress_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_PatientConsentArgs = {
  where: PatientConsent_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_PatientConsent_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_PatientConversationArgs = {
  where: PatientConversation_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_PatientConversation_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_PatientFlowsheetDataArgs = {
  where: PatientFlowsheetData_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_PatientFlowsheetData_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_PatientFlowsheetRowArgs = {
  where: PatientFlowsheetRow_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_PatientFlowsheetRow_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_PatientInviteArgs = {
  where: PatientInvite_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_PatientInvite_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_PatientMembershipArgs = {
  where: PatientMembership_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_PatientMembership_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_PatientSystemNoticeArgs = {
  where: PatientSystemNotice_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_PatientSystemNotice_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_PatientTagArgs = {
  where: PatientTag_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_PatientTag_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_PatientTagsArgs = {
  where: PatientTags_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_PatientTags_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_PatientWalletArgs = {
  where: PatientWallet_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_PatientWalletCouponArgs = {
  where: PatientWalletCoupon_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_PatientWalletCoupon_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_PatientWalletTransactionArgs = {
  where: PatientWalletTransaction_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_PatientWalletTransaction_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_PatientWallet_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_PatientWorkspaceArgs = {
  where: PatientWorkspace_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_PatientWorkspace_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Patient_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_PaymentArgs = {
  where: Payment_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_PaymentMethodArgs = {
  where: PaymentMethod_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_PaymentMethod_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_PaymentRelationshipArgs = {
  where: PaymentRelationship_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_PaymentRelationship_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Payment_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_PayrixTerminalArgs = {
  where: PayrixTerminal_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_PayrixTerminalTransactionArgs = {
  where: PayrixTerminalTransaction_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_PayrixTerminalTransaction_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_PayrixTerminal_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_PayrixWebAlertArgs = {
  where: PayrixWebAlert_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_PayrixWebAlert_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_PhotoArgs = {
  where: Photo_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_PhotoMediaTagArgs = {
  where: PhotoMediaTag_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_PhotoMediaTag_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Photo_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_PinArgs = {
  where: Pin_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Pin_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_PostStatusArgs = {
  where: PostStatus_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_PostStatus_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_PrescriptionArgs = {
  where: Prescription_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_PrescriptionDetailArgs = {
  where: PrescriptionDetail_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_PrescriptionDetail_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Prescription_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_PromotionArgs = {
  where: Promotion_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_PromotionCouponsArgs = {
  where: PromotionCoupons_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_PromotionCoupons_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_PromotionServicesArgs = {
  where: PromotionServices_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_PromotionServices_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Promotion_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_ProviderArgs = {
  where: Provider_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_ProviderScheduleArgs = {
  where: ProviderSchedule_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_ProviderSchedule_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Provider_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_PurchaseOrderArgs = {
  where: PurchaseOrder_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_PurchaseOrder_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_RecurrenceTypeArgs = {
  where: RecurrenceType_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_RecurrenceType_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_RefundArgs = {
  where: Refund_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Refund_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_ResolutionStatusArgs = {
  where: ResolutionStatus_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_ResolutionStatus_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_RewardProgramArgs = {
  where: RewardProgram_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_RewardProgramEnrollmentArgs = {
  where: RewardProgramEnrollment_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_RewardProgramEnrollment_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_RewardProgram_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_RoomArgs = {
  where: Room_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Room_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_RsReviewArgs = {
  where: RsReview_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_RsReview_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_ServiceArgs = {
  where: Service_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_ServiceAddOnArgs = {
  where: ServiceAddOn_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_ServiceAddOn_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_ServiceCategoryArgs = {
  where: ServiceCategory_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_ServiceCategory_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_ServiceConsentArgs = {
  where: ServiceConsent_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_ServiceConsent_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_ServiceConsumableTypeArgs = {
  where: ServiceConsumableType_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_ServiceConsumableType_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_ServiceCouponArgs = {
  where: ServiceCoupon_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_ServiceCoupon_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_ServiceCustomFormArgs = {
  where: ServiceCustomForm_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_ServiceCustomForm_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_ServiceDeviceTypeArgs = {
  where: ServiceDeviceType_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_ServiceDeviceType_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_ServiceProviderArgs = {
  where: ServiceProvider_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_ServiceProvider_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_ServiceUnitArgs = {
  where: ServiceUnit_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_ServiceUnit_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Service_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_ShrinkArgs = {
  where: Shrink_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Shrink_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_SignatureArgs = {
  where: Signature_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Signature_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_SmartNoteArgs = {
  where: SmartNote_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_SmartNote_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_SmsTemplateArgs = {
  where: SmsTemplate_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_SmsTemplatePatientArgs = {
  where: SmsTemplatePatient_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_SmsTemplatePatient_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_SmsTemplateSendQueueArgs = {
  where: SmsTemplateSendQueue_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_SmsTemplateSendQueue_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_SmsTemplate_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_SocialMediaAccountArgs = {
  where: SocialMediaAccount_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_SocialMediaAccountPostArgs = {
  where: SocialMediaAccountPost_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_SocialMediaAccountPost_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_SocialMediaAccount_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_SocialMediaPlatformArgs = {
  where: SocialMediaPlatform_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_SocialMediaPlatform_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_SocialMediaPostArgs = {
  where: SocialMediaPost_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_SocialMediaPost_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_TagArgs = {
  where: Tag_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Tag_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_TaskArgs = {
  where: Task_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_TaskCommentArgs = {
  where: TaskComment_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_TaskComment_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Task_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_TelehealthMeetingArgs = {
  where: TelehealthMeeting_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_TelehealthMeeting_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_UnusedAppointmentServiceConsumableTypeArgs = {
  where: UnusedAppointmentServiceConsumableType_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_UnusedAppointmentServiceConsumableType_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_UserArgs = {
  where: User_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_UserInviteArgs = {
  where: UserInvite_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_UserInvite_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_UserOrganizationArgs = {
  where: UserOrganization_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_UserOrganization_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_UserWorkspaceArgs = {
  where: UserWorkspace_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_UserWorkspace_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_User_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_VitalsArgs = {
  where: Vitals_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Vitals_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_WorkspaceArgs = {
  where: Workspace_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_WorkspaceConfigurationArgs = {
  where: WorkspaceConfiguration_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_WorkspaceConfiguration_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Workspace_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_ZdReviewArgs = {
  where: ZdReview_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_ZdReview_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDisconnectNylasArgs = {
  userWorkspaceId: Scalars['String'];
};


/** mutation root */
export type Mutation_RootEditImageArgs = {
  input: EditImageInput;
};


/** mutation root */
export type Mutation_RootEnrollLocationERxArgs = {
  locationId: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootEnrollProviderERxArgs = {
  locationIds: Array<Scalars['uuid']>;
  providerId: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootFacebookRedirectArgs = {
  code: Scalars['String'];
  state: Scalars['String'];
};


/** mutation root */
export type Mutation_RootGenerateFinixOnboardingLinkArgs = {
  maxProcessingAmount: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootGenerateInvoiceReceiptPdfArgs = {
  invoiceId: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootGeneratePatientConsentPdfArgs = {
  patientConsentId: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootGetCalendarResourcesArgs = {
  endDate: Scalars['String'];
  hideCancelledAppointments?: InputMaybe<Scalars['Boolean']>;
  locationsFilter?: InputMaybe<Scalars['String']>;
  providersFilter?: InputMaybe<Scalars['String']>;
  range?: InputMaybe<Scalars['String']>;
  serviceFilter?: InputMaybe<Scalars['String']>;
  startDate: Scalars['String'];
  statusFilter?: InputMaybe<Scalars['String']>;
  timezone: Scalars['String'];
};


/** mutation root */
export type Mutation_RootGetInverseAvailabilityArgs = {
  endDate: Scalars['String'];
  locationsFilter?: InputMaybe<Scalars['String']>;
  providersFilter?: InputMaybe<Scalars['String']>;
  startDate: Scalars['String'];
};


/** mutation root */
export type Mutation_RootGetNylasAuthUrlArgs = {
  syncOption: Scalars['String'];
  userWorkspaceId: Scalars['String'];
};


/** mutation root */
export type Mutation_RootGetOrCreateTwilioConverssationArgs = {
  patientId: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootGetPatientLabOrderUrlArgs = {
  patientId?: InputMaybe<Scalars['uuid']>;
};


/** mutation root */
export type Mutation_RootGoogleRedirectArgs = {
  code?: InputMaybe<Scalars['String']>;
  scope?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
};


/** mutation root */
export type Mutation_RootInitializeNylasExchangeArgs = {
  code: Scalars['String'];
  state: Scalars['String'];
};


/** mutation root */
export type Mutation_RootInitializeWalletsArgs = {
  workspaceId?: InputMaybe<Scalars['uuid']>;
};


/** mutation root */
export type Mutation_RootInsertGoogleLocationArgs = {
  locationId: Scalars['String'];
  locationName: Scalars['String'];
};


/** mutation root */
export type Mutation_RootInsertPatientAddressArgs = {
  addressLine1: Scalars['String'];
  addressLine2?: InputMaybe<Scalars['String']>;
  city: Scalars['String'];
  isBilling?: InputMaybe<Scalars['Boolean']>;
  isDefault?: InputMaybe<Scalars['Boolean']>;
  patientId: Scalars['uuid'];
  state: Scalars['String'];
  zipCode: Scalars['String'];
};


/** mutation root */
export type Mutation_RootInsertPurchaseOrderAndAttachConsumablesArgs = {
  consumableIds: Array<Scalars['uuid']>;
  purchaseOrder: Scalars['jsonb'];
};


/** mutation root */
export type Mutation_RootInsert_ActivityLogArgs = {
  objects: Array<ActivityLog_Insert_Input>;
  on_conflict?: InputMaybe<ActivityLog_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ActivityLog_OneArgs = {
  object: ActivityLog_Insert_Input;
  on_conflict?: InputMaybe<ActivityLog_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_AddressArgs = {
  objects: Array<Address_Insert_Input>;
  on_conflict?: InputMaybe<Address_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Address_OneArgs = {
  object: Address_Insert_Input;
  on_conflict?: InputMaybe<Address_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_AppointmentArgs = {
  objects: Array<Appointment_Insert_Input>;
  on_conflict?: InputMaybe<Appointment_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_AppointmentConsentArgs = {
  objects: Array<AppointmentConsent_Insert_Input>;
  on_conflict?: InputMaybe<AppointmentConsent_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_AppointmentConsent_OneArgs = {
  object: AppointmentConsent_Insert_Input;
  on_conflict?: InputMaybe<AppointmentConsent_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_AppointmentCustomFormArgs = {
  objects: Array<AppointmentCustomForm_Insert_Input>;
  on_conflict?: InputMaybe<AppointmentCustomForm_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_AppointmentCustomForm_OneArgs = {
  object: AppointmentCustomForm_Insert_Input;
  on_conflict?: InputMaybe<AppointmentCustomForm_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_AppointmentServiceArgs = {
  objects: Array<AppointmentService_Insert_Input>;
  on_conflict?: InputMaybe<AppointmentService_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_AppointmentServiceConsumableArgs = {
  objects: Array<AppointmentServiceConsumable_Insert_Input>;
  on_conflict?: InputMaybe<AppointmentServiceConsumable_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_AppointmentServiceConsumable_OneArgs = {
  object: AppointmentServiceConsumable_Insert_Input;
  on_conflict?: InputMaybe<AppointmentServiceConsumable_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_AppointmentService_OneArgs = {
  object: AppointmentService_Insert_Input;
  on_conflict?: InputMaybe<AppointmentService_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_AppointmentTypeArgs = {
  objects: Array<AppointmentType_Insert_Input>;
  on_conflict?: InputMaybe<AppointmentType_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_AppointmentType_OneArgs = {
  object: AppointmentType_Insert_Input;
  on_conflict?: InputMaybe<AppointmentType_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Appointment_OneArgs = {
  object: Appointment_Insert_Input;
  on_conflict?: InputMaybe<Appointment_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_AudienceArgs = {
  objects: Array<Audience_Insert_Input>;
  on_conflict?: InputMaybe<Audience_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_AudienceEmailArgs = {
  objects: Array<AudienceEmail_Insert_Input>;
  on_conflict?: InputMaybe<AudienceEmail_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_AudienceEmail_OneArgs = {
  object: AudienceEmail_Insert_Input;
  on_conflict?: InputMaybe<AudienceEmail_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Audience_OneArgs = {
  object: Audience_Insert_Input;
  on_conflict?: InputMaybe<Audience_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_AuthProfileArgs = {
  objects: Array<AuthProfile_Insert_Input>;
  on_conflict?: InputMaybe<AuthProfile_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_AuthProfile_OneArgs = {
  object: AuthProfile_Insert_Input;
  on_conflict?: InputMaybe<AuthProfile_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ClinicalMessageArgs = {
  objects: Array<ClinicalMessage_Insert_Input>;
  on_conflict?: InputMaybe<ClinicalMessage_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ClinicalMessageStatusArgs = {
  objects: Array<ClinicalMessageStatus_Insert_Input>;
  on_conflict?: InputMaybe<ClinicalMessageStatus_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ClinicalMessageStatus_OneArgs = {
  object: ClinicalMessageStatus_Insert_Input;
  on_conflict?: InputMaybe<ClinicalMessageStatus_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ClinicalMessage_OneArgs = {
  object: ClinicalMessage_Insert_Input;
  on_conflict?: InputMaybe<ClinicalMessage_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CompensationPolicyArgs = {
  objects: Array<CompensationPolicy_Insert_Input>;
  on_conflict?: InputMaybe<CompensationPolicy_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CompensationPolicyCommissionArgs = {
  objects: Array<CompensationPolicyCommission_Insert_Input>;
  on_conflict?: InputMaybe<CompensationPolicyCommission_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CompensationPolicyCommission_OneArgs = {
  object: CompensationPolicyCommission_Insert_Input;
  on_conflict?: InputMaybe<CompensationPolicyCommission_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CompensationPolicyTierArgs = {
  objects: Array<CompensationPolicyTier_Insert_Input>;
  on_conflict?: InputMaybe<CompensationPolicyTier_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CompensationPolicyTierCategoryArgs = {
  objects: Array<CompensationPolicyTierCategory_Insert_Input>;
  on_conflict?: InputMaybe<CompensationPolicyTierCategory_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CompensationPolicyTierCategory_OneArgs = {
  object: CompensationPolicyTierCategory_Insert_Input;
  on_conflict?: InputMaybe<CompensationPolicyTierCategory_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CompensationPolicyTierExemptionArgs = {
  objects: Array<CompensationPolicyTierExemption_Insert_Input>;
  on_conflict?: InputMaybe<CompensationPolicyTierExemption_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CompensationPolicyTierExemption_OneArgs = {
  object: CompensationPolicyTierExemption_Insert_Input;
  on_conflict?: InputMaybe<CompensationPolicyTierExemption_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CompensationPolicyTierInclusionArgs = {
  objects: Array<CompensationPolicyTierInclusion_Insert_Input>;
  on_conflict?: InputMaybe<CompensationPolicyTierInclusion_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CompensationPolicyTierInclusion_OneArgs = {
  object: CompensationPolicyTierInclusion_Insert_Input;
  on_conflict?: InputMaybe<CompensationPolicyTierInclusion_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CompensationPolicyTier_OneArgs = {
  object: CompensationPolicyTier_Insert_Input;
  on_conflict?: InputMaybe<CompensationPolicyTier_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CompensationPolicy_OneArgs = {
  object: CompensationPolicy_Insert_Input;
  on_conflict?: InputMaybe<CompensationPolicy_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CompensationStrategyArgs = {
  objects: Array<CompensationStrategy_Insert_Input>;
  on_conflict?: InputMaybe<CompensationStrategy_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CompensationStrategyCommissionArgs = {
  objects: Array<CompensationStrategyCommission_Insert_Input>;
  on_conflict?: InputMaybe<CompensationStrategyCommission_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CompensationStrategyCommission_OneArgs = {
  object: CompensationStrategyCommission_Insert_Input;
  on_conflict?: InputMaybe<CompensationStrategyCommission_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CompensationStrategyTierArgs = {
  objects: Array<CompensationStrategyTier_Insert_Input>;
  on_conflict?: InputMaybe<CompensationStrategyTier_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CompensationStrategyTierCategoryArgs = {
  objects: Array<CompensationStrategyTierCategory_Insert_Input>;
  on_conflict?: InputMaybe<CompensationStrategyTierCategory_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CompensationStrategyTierCategory_OneArgs = {
  object: CompensationStrategyTierCategory_Insert_Input;
  on_conflict?: InputMaybe<CompensationStrategyTierCategory_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CompensationStrategyTierExemptionArgs = {
  objects: Array<CompensationStrategyTierExemption_Insert_Input>;
  on_conflict?: InputMaybe<CompensationStrategyTierExemption_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CompensationStrategyTierExemption_OneArgs = {
  object: CompensationStrategyTierExemption_Insert_Input;
  on_conflict?: InputMaybe<CompensationStrategyTierExemption_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CompensationStrategyTierInclusionArgs = {
  objects: Array<CompensationStrategyTierInclusion_Insert_Input>;
  on_conflict?: InputMaybe<CompensationStrategyTierInclusion_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CompensationStrategyTierInclusion_OneArgs = {
  object: CompensationStrategyTierInclusion_Insert_Input;
  on_conflict?: InputMaybe<CompensationStrategyTierInclusion_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CompensationStrategyTier_OneArgs = {
  object: CompensationStrategyTier_Insert_Input;
  on_conflict?: InputMaybe<CompensationStrategyTier_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CompensationStrategy_OneArgs = {
  object: CompensationStrategy_Insert_Input;
  on_conflict?: InputMaybe<CompensationStrategy_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ConsentArgs = {
  objects: Array<Consent_Insert_Input>;
  on_conflict?: InputMaybe<Consent_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Consent_OneArgs = {
  object: Consent_Insert_Input;
  on_conflict?: InputMaybe<Consent_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ConsumableArgs = {
  objects: Array<Consumable_Insert_Input>;
  on_conflict?: InputMaybe<Consumable_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ConsumableBrandArgs = {
  objects: Array<ConsumableBrand_Insert_Input>;
  on_conflict?: InputMaybe<ConsumableBrand_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ConsumableBrand_OneArgs = {
  object: ConsumableBrand_Insert_Input;
  on_conflict?: InputMaybe<ConsumableBrand_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ConsumableSubtypeArgs = {
  objects: Array<ConsumableSubtype_Insert_Input>;
  on_conflict?: InputMaybe<ConsumableSubtype_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ConsumableSubtype_OneArgs = {
  object: ConsumableSubtype_Insert_Input;
  on_conflict?: InputMaybe<ConsumableSubtype_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ConsumableTypeArgs = {
  objects: Array<ConsumableType_Insert_Input>;
  on_conflict?: InputMaybe<ConsumableType_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ConsumableTypeConsumableSubtypeArgs = {
  objects: Array<ConsumableTypeConsumableSubtype_Insert_Input>;
  on_conflict?: InputMaybe<ConsumableTypeConsumableSubtype_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ConsumableTypeConsumableSubtype_OneArgs = {
  object: ConsumableTypeConsumableSubtype_Insert_Input;
  on_conflict?: InputMaybe<ConsumableTypeConsumableSubtype_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ConsumableType_OneArgs = {
  object: ConsumableType_Insert_Input;
  on_conflict?: InputMaybe<ConsumableType_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Consumable_OneArgs = {
  object: Consumable_Insert_Input;
  on_conflict?: InputMaybe<Consumable_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CouponArgs = {
  objects: Array<Coupon_Insert_Input>;
  on_conflict?: InputMaybe<Coupon_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CouponConfigurationArgs = {
  objects: Array<CouponConfiguration_Insert_Input>;
  on_conflict?: InputMaybe<CouponConfiguration_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CouponConfiguration_OneArgs = {
  object: CouponConfiguration_Insert_Input;
  on_conflict?: InputMaybe<CouponConfiguration_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CouponScopeArgs = {
  objects: Array<CouponScope_Insert_Input>;
  on_conflict?: InputMaybe<CouponScope_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CouponScope_OneArgs = {
  object: CouponScope_Insert_Input;
  on_conflict?: InputMaybe<CouponScope_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CouponTypeArgs = {
  objects: Array<CouponType_Insert_Input>;
  on_conflict?: InputMaybe<CouponType_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CouponType_OneArgs = {
  object: CouponType_Insert_Input;
  on_conflict?: InputMaybe<CouponType_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Coupon_OneArgs = {
  object: Coupon_Insert_Input;
  on_conflict?: InputMaybe<Coupon_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CustomFormArgs = {
  objects: Array<CustomForm_Insert_Input>;
  on_conflict?: InputMaybe<CustomForm_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CustomFormFieldArgs = {
  objects: Array<CustomFormField_Insert_Input>;
  on_conflict?: InputMaybe<CustomFormField_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CustomFormFieldCategoryArgs = {
  objects: Array<CustomFormFieldCategory_Insert_Input>;
  on_conflict?: InputMaybe<CustomFormFieldCategory_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CustomFormFieldCategory_OneArgs = {
  object: CustomFormFieldCategory_Insert_Input;
  on_conflict?: InputMaybe<CustomFormFieldCategory_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CustomFormFieldResponseArgs = {
  objects: Array<CustomFormFieldResponse_Insert_Input>;
  on_conflict?: InputMaybe<CustomFormFieldResponse_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CustomFormFieldResponse_OneArgs = {
  object: CustomFormFieldResponse_Insert_Input;
  on_conflict?: InputMaybe<CustomFormFieldResponse_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CustomFormFieldTypeArgs = {
  objects: Array<CustomFormFieldType_Insert_Input>;
  on_conflict?: InputMaybe<CustomFormFieldType_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CustomFormFieldType_OneArgs = {
  object: CustomFormFieldType_Insert_Input;
  on_conflict?: InputMaybe<CustomFormFieldType_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CustomFormField_OneArgs = {
  object: CustomFormField_Insert_Input;
  on_conflict?: InputMaybe<CustomFormField_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CustomFormOptionArgs = {
  objects: Array<CustomFormOption_Insert_Input>;
  on_conflict?: InputMaybe<CustomFormOption_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CustomFormOption_OneArgs = {
  object: CustomFormOption_Insert_Input;
  on_conflict?: InputMaybe<CustomFormOption_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CustomFormReplicatedFieldArgs = {
  objects: Array<CustomFormReplicatedField_Insert_Input>;
  on_conflict?: InputMaybe<CustomFormReplicatedField_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CustomFormReplicatedField_OneArgs = {
  object: CustomFormReplicatedField_Insert_Input;
  on_conflict?: InputMaybe<CustomFormReplicatedField_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CustomFormReplicatedRowArgs = {
  objects: Array<CustomFormReplicatedRow_Insert_Input>;
  on_conflict?: InputMaybe<CustomFormReplicatedRow_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CustomFormReplicatedRow_OneArgs = {
  object: CustomFormReplicatedRow_Insert_Input;
  on_conflict?: InputMaybe<CustomFormReplicatedRow_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CustomFormResponseArgs = {
  objects: Array<CustomFormResponse_Insert_Input>;
  on_conflict?: InputMaybe<CustomFormResponse_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CustomFormResponse_OneArgs = {
  object: CustomFormResponse_Insert_Input;
  on_conflict?: InputMaybe<CustomFormResponse_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CustomFormRowArgs = {
  objects: Array<CustomFormRow_Insert_Input>;
  on_conflict?: InputMaybe<CustomFormRow_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CustomFormRow_OneArgs = {
  object: CustomFormRow_Insert_Input;
  on_conflict?: InputMaybe<CustomFormRow_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CustomForm_OneArgs = {
  object: CustomForm_Insert_Input;
  on_conflict?: InputMaybe<CustomForm_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CustomQuestionArgs = {
  objects: Array<CustomQuestion_Insert_Input>;
  on_conflict?: InputMaybe<CustomQuestion_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CustomQuestionOptionArgs = {
  objects: Array<CustomQuestionOption_Insert_Input>;
  on_conflict?: InputMaybe<CustomQuestionOption_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CustomQuestionOption_OneArgs = {
  object: CustomQuestionOption_Insert_Input;
  on_conflict?: InputMaybe<CustomQuestionOption_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CustomQuestion_OneArgs = {
  object: CustomQuestion_Insert_Input;
  on_conflict?: InputMaybe<CustomQuestion_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_DegreeDesignationArgs = {
  objects: Array<DegreeDesignation_Insert_Input>;
  on_conflict?: InputMaybe<DegreeDesignation_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_DegreeDesignation_OneArgs = {
  object: DegreeDesignation_Insert_Input;
  on_conflict?: InputMaybe<DegreeDesignation_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_DepositInvoiceLineItemArgs = {
  objects: Array<DepositInvoiceLineItem_Insert_Input>;
  on_conflict?: InputMaybe<DepositInvoiceLineItem_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_DepositInvoiceLineItem_OneArgs = {
  object: DepositInvoiceLineItem_Insert_Input;
  on_conflict?: InputMaybe<DepositInvoiceLineItem_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_DeviceArgs = {
  objects: Array<Device_Insert_Input>;
  on_conflict?: InputMaybe<Device_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_DeviceTypeArgs = {
  objects: Array<DeviceType_Insert_Input>;
  on_conflict?: InputMaybe<DeviceType_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_DeviceType_OneArgs = {
  object: DeviceType_Insert_Input;
  on_conflict?: InputMaybe<DeviceType_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Device_OneArgs = {
  object: Device_Insert_Input;
  on_conflict?: InputMaybe<Device_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_DiscountInvoiceLineItemArgs = {
  objects: Array<DiscountInvoiceLineItem_Insert_Input>;
  on_conflict?: InputMaybe<DiscountInvoiceLineItem_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_DiscountInvoiceLineItem_OneArgs = {
  object: DiscountInvoiceLineItem_Insert_Input;
  on_conflict?: InputMaybe<DiscountInvoiceLineItem_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_DocumentArgs = {
  objects: Array<Document_Insert_Input>;
  on_conflict?: InputMaybe<Document_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_DocumentTagArgs = {
  objects: Array<DocumentTag_Insert_Input>;
  on_conflict?: InputMaybe<DocumentTag_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_DocumentTag_OneArgs = {
  object: DocumentTag_Insert_Input;
  on_conflict?: InputMaybe<DocumentTag_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Document_OneArgs = {
  object: Document_Insert_Input;
  on_conflict?: InputMaybe<Document_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_EmailCampaignLogArgs = {
  objects: Array<EmailCampaignLog_Insert_Input>;
  on_conflict?: InputMaybe<EmailCampaignLog_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_EmailCampaignLog_OneArgs = {
  object: EmailCampaignLog_Insert_Input;
  on_conflict?: InputMaybe<EmailCampaignLog_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_EmailTemplateArgs = {
  objects: Array<EmailTemplate_Insert_Input>;
  on_conflict?: InputMaybe<EmailTemplate_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_EmailTemplatePatientArgs = {
  objects: Array<EmailTemplatePatient_Insert_Input>;
  on_conflict?: InputMaybe<EmailTemplatePatient_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_EmailTemplatePatient_OneArgs = {
  object: EmailTemplatePatient_Insert_Input;
  on_conflict?: InputMaybe<EmailTemplatePatient_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_EmailTemplateSendQueueArgs = {
  objects: Array<EmailTemplateSendQueue_Insert_Input>;
  on_conflict?: InputMaybe<EmailTemplateSendQueue_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_EmailTemplateSendQueue_OneArgs = {
  object: EmailTemplateSendQueue_Insert_Input;
  on_conflict?: InputMaybe<EmailTemplateSendQueue_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_EmailTemplate_OneArgs = {
  object: EmailTemplate_Insert_Input;
  on_conflict?: InputMaybe<EmailTemplate_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ExternalLocationArgs = {
  objects: Array<ExternalLocation_Insert_Input>;
  on_conflict?: InputMaybe<ExternalLocation_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ExternalLocation_OneArgs = {
  object: ExternalLocation_Insert_Input;
  on_conflict?: InputMaybe<ExternalLocation_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ExternalProviderArgs = {
  objects: Array<ExternalProvider_Insert_Input>;
  on_conflict?: InputMaybe<ExternalProvider_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ExternalProvider_OneArgs = {
  object: ExternalProvider_Insert_Input;
  on_conflict?: InputMaybe<ExternalProvider_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ExternalReviewArgs = {
  objects: Array<ExternalReview_Insert_Input>;
  on_conflict?: InputMaybe<ExternalReview_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ExternalReview_OneArgs = {
  object: ExternalReview_Insert_Input;
  on_conflict?: InputMaybe<ExternalReview_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_FeeArgs = {
  objects: Array<Fee_Insert_Input>;
  on_conflict?: InputMaybe<Fee_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_FeeInvoiceLineItemArgs = {
  objects: Array<FeeInvoiceLineItem_Insert_Input>;
  on_conflict?: InputMaybe<FeeInvoiceLineItem_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_FeeInvoiceLineItem_OneArgs = {
  object: FeeInvoiceLineItem_Insert_Input;
  on_conflict?: InputMaybe<FeeInvoiceLineItem_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Fee_OneArgs = {
  object: Fee_Insert_Input;
  on_conflict?: InputMaybe<Fee_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_FinixTerminalArgs = {
  objects: Array<FinixTerminal_Insert_Input>;
  on_conflict?: InputMaybe<FinixTerminal_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_FinixTerminal_OneArgs = {
  object: FinixTerminal_Insert_Input;
  on_conflict?: InputMaybe<FinixTerminal_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_FlowsheetArgs = {
  objects: Array<Flowsheet_Insert_Input>;
  on_conflict?: InputMaybe<Flowsheet_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_FlowsheetParameterArgs = {
  objects: Array<FlowsheetParameter_Insert_Input>;
  on_conflict?: InputMaybe<FlowsheetParameter_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_FlowsheetParameterFlowsheetArgs = {
  objects: Array<FlowsheetParameterFlowsheet_Insert_Input>;
  on_conflict?: InputMaybe<FlowsheetParameterFlowsheet_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_FlowsheetParameterFlowsheet_OneArgs = {
  object: FlowsheetParameterFlowsheet_Insert_Input;
  on_conflict?: InputMaybe<FlowsheetParameterFlowsheet_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_FlowsheetParameterGroupArgs = {
  objects: Array<FlowsheetParameterGroup_Insert_Input>;
  on_conflict?: InputMaybe<FlowsheetParameterGroup_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_FlowsheetParameterGroup_OneArgs = {
  object: FlowsheetParameterGroup_Insert_Input;
  on_conflict?: InputMaybe<FlowsheetParameterGroup_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_FlowsheetParameter_OneArgs = {
  object: FlowsheetParameter_Insert_Input;
  on_conflict?: InputMaybe<FlowsheetParameter_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Flowsheet_OneArgs = {
  object: Flowsheet_Insert_Input;
  on_conflict?: InputMaybe<Flowsheet_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_GenericInvoiceLineItemArgs = {
  objects: Array<GenericInvoiceLineItem_Insert_Input>;
  on_conflict?: InputMaybe<GenericInvoiceLineItem_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_GenericInvoiceLineItemDiscountArgs = {
  objects: Array<GenericInvoiceLineItemDiscount_Insert_Input>;
  on_conflict?: InputMaybe<GenericInvoiceLineItemDiscount_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_GenericInvoiceLineItemDiscount_OneArgs = {
  object: GenericInvoiceLineItemDiscount_Insert_Input;
  on_conflict?: InputMaybe<GenericInvoiceLineItemDiscount_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_GenericInvoiceLineItem_OneArgs = {
  object: GenericInvoiceLineItem_Insert_Input;
  on_conflict?: InputMaybe<GenericInvoiceLineItem_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_GiftCardArgs = {
  objects: Array<GiftCard_Insert_Input>;
  on_conflict?: InputMaybe<GiftCard_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_GiftCard_OneArgs = {
  object: GiftCard_Insert_Input;
  on_conflict?: InputMaybe<GiftCard_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_GoogleAccountArgs = {
  objects: Array<GoogleAccount_Insert_Input>;
  on_conflict?: InputMaybe<GoogleAccount_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_GoogleAccount_OneArgs = {
  object: GoogleAccount_Insert_Input;
  on_conflict?: InputMaybe<GoogleAccount_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_GoogleLocationArgs = {
  objects: Array<GoogleLocation_Insert_Input>;
  on_conflict?: InputMaybe<GoogleLocation_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_GoogleLocation_OneArgs = {
  object: GoogleLocation_Insert_Input;
  on_conflict?: InputMaybe<GoogleLocation_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_GoogleReviewArgs = {
  objects: Array<GoogleReview_Insert_Input>;
  on_conflict?: InputMaybe<GoogleReview_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_GoogleReview_OneArgs = {
  object: GoogleReview_Insert_Input;
  on_conflict?: InputMaybe<GoogleReview_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ImportJobArgs = {
  objects: Array<ImportJob_Insert_Input>;
  on_conflict?: InputMaybe<ImportJob_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ImportJob_OneArgs = {
  object: ImportJob_Insert_Input;
  on_conflict?: InputMaybe<ImportJob_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ImportMetadataArgs = {
  objects: Array<ImportMetadata_Insert_Input>;
  on_conflict?: InputMaybe<ImportMetadata_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ImportMetadata_OneArgs = {
  object: ImportMetadata_Insert_Input;
  on_conflict?: InputMaybe<ImportMetadata_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_IncompatibleServicesArgs = {
  objects: Array<IncompatibleServices_Insert_Input>;
  on_conflict?: InputMaybe<IncompatibleServices_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_IncompatibleServices_OneArgs = {
  object: IncompatibleServices_Insert_Input;
  on_conflict?: InputMaybe<IncompatibleServices_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_InternalAppointmentProviderArgs = {
  objects: Array<InternalAppointmentProvider_Insert_Input>;
  on_conflict?: InputMaybe<InternalAppointmentProvider_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_InternalAppointmentProvider_OneArgs = {
  object: InternalAppointmentProvider_Insert_Input;
  on_conflict?: InputMaybe<InternalAppointmentProvider_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_InventoryTransactionArgs = {
  objects: Array<InventoryTransaction_Insert_Input>;
  on_conflict?: InputMaybe<InventoryTransaction_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_InventoryTransaction_OneArgs = {
  object: InventoryTransaction_Insert_Input;
  on_conflict?: InputMaybe<InventoryTransaction_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_InvoiceArgs = {
  objects: Array<Invoice_Insert_Input>;
  on_conflict?: InputMaybe<Invoice_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_InvoiceConfigurationArgs = {
  objects: Array<InvoiceConfiguration_Insert_Input>;
  on_conflict?: InputMaybe<InvoiceConfiguration_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_InvoiceConfiguration_OneArgs = {
  object: InvoiceConfiguration_Insert_Input;
  on_conflict?: InputMaybe<InvoiceConfiguration_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_InvoiceCouponArgs = {
  objects: Array<InvoiceCoupon_Insert_Input>;
  on_conflict?: InputMaybe<InvoiceCoupon_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_InvoiceCoupon_OneArgs = {
  object: InvoiceCoupon_Insert_Input;
  on_conflict?: InputMaybe<InvoiceCoupon_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_InvoiceMembershipArgs = {
  objects: Array<InvoiceMembership_Insert_Input>;
  on_conflict?: InputMaybe<InvoiceMembership_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_InvoiceMembership_OneArgs = {
  object: InvoiceMembership_Insert_Input;
  on_conflict?: InputMaybe<InvoiceMembership_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_InvoicePackageArgs = {
  objects: Array<InvoicePackage_Insert_Input>;
  on_conflict?: InputMaybe<InvoicePackage_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_InvoicePackage_OneArgs = {
  object: InvoicePackage_Insert_Input;
  on_conflict?: InputMaybe<InvoicePackage_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_InvoiceProductItemArgs = {
  objects: Array<InvoiceProductItem_Insert_Input>;
  on_conflict?: InputMaybe<InvoiceProductItem_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_InvoiceProductItem_OneArgs = {
  object: InvoiceProductItem_Insert_Input;
  on_conflict?: InputMaybe<InvoiceProductItem_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_InvoiceServiceConsumableArgs = {
  objects: Array<InvoiceServiceConsumable_Insert_Input>;
  on_conflict?: InputMaybe<InvoiceServiceConsumable_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_InvoiceServiceConsumable_OneArgs = {
  object: InvoiceServiceConsumable_Insert_Input;
  on_conflict?: InputMaybe<InvoiceServiceConsumable_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_InvoiceServiceItemArgs = {
  objects: Array<InvoiceServiceItem_Insert_Input>;
  on_conflict?: InputMaybe<InvoiceServiceItem_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_InvoiceServiceItem_OneArgs = {
  object: InvoiceServiceItem_Insert_Input;
  on_conflict?: InputMaybe<InvoiceServiceItem_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_InvoiceTemplateArgs = {
  objects: Array<InvoiceTemplate_Insert_Input>;
  on_conflict?: InputMaybe<InvoiceTemplate_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_InvoiceTemplate_OneArgs = {
  object: InvoiceTemplate_Insert_Input;
  on_conflict?: InputMaybe<InvoiceTemplate_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Invoice_OneArgs = {
  object: Invoice_Insert_Input;
  on_conflict?: InputMaybe<Invoice_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_JobArgs = {
  objects: Array<Job_Insert_Input>;
  on_conflict?: InputMaybe<Job_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_JobStatusArgs = {
  objects: Array<JobStatus_Insert_Input>;
  on_conflict?: InputMaybe<JobStatus_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_JobStatus_OneArgs = {
  object: JobStatus_Insert_Input;
  on_conflict?: InputMaybe<JobStatus_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Job_OneArgs = {
  object: Job_Insert_Input;
  on_conflict?: InputMaybe<Job_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_LabResultArgs = {
  objects: Array<LabResult_Insert_Input>;
  on_conflict?: InputMaybe<LabResult_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_LabResult_OneArgs = {
  object: LabResult_Insert_Input;
  on_conflict?: InputMaybe<LabResult_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_LeadFormArgs = {
  objects: Array<LeadForm_Insert_Input>;
  on_conflict?: InputMaybe<LeadForm_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_LeadFormEntryArgs = {
  objects: Array<LeadFormEntry_Insert_Input>;
  on_conflict?: InputMaybe<LeadFormEntry_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_LeadFormEntryTagArgs = {
  objects: Array<LeadFormEntryTag_Insert_Input>;
  on_conflict?: InputMaybe<LeadFormEntryTag_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_LeadFormEntryTag_OneArgs = {
  object: LeadFormEntryTag_Insert_Input;
  on_conflict?: InputMaybe<LeadFormEntryTag_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_LeadFormEntry_OneArgs = {
  object: LeadFormEntry_Insert_Input;
  on_conflict?: InputMaybe<LeadFormEntry_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_LeadFormFieldArgs = {
  objects: Array<LeadFormField_Insert_Input>;
  on_conflict?: InputMaybe<LeadFormField_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_LeadFormFieldValueArgs = {
  objects: Array<LeadFormFieldValue_Insert_Input>;
  on_conflict?: InputMaybe<LeadFormFieldValue_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_LeadFormFieldValue_OneArgs = {
  object: LeadFormFieldValue_Insert_Input;
  on_conflict?: InputMaybe<LeadFormFieldValue_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_LeadFormField_OneArgs = {
  object: LeadFormField_Insert_Input;
  on_conflict?: InputMaybe<LeadFormField_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_LeadFormLinkArgs = {
  objects: Array<LeadFormLink_Insert_Input>;
  on_conflict?: InputMaybe<LeadFormLink_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_LeadFormLink_OneArgs = {
  object: LeadFormLink_Insert_Input;
  on_conflict?: InputMaybe<LeadFormLink_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_LeadFormStepArgs = {
  objects: Array<LeadFormStep_Insert_Input>;
  on_conflict?: InputMaybe<LeadFormStep_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_LeadFormStep_OneArgs = {
  object: LeadFormStep_Insert_Input;
  on_conflict?: InputMaybe<LeadFormStep_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_LeadForm_OneArgs = {
  object: LeadForm_Insert_Input;
  on_conflict?: InputMaybe<LeadForm_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_LeadTagArgs = {
  objects: Array<LeadTag_Insert_Input>;
  on_conflict?: InputMaybe<LeadTag_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_LeadTag_OneArgs = {
  object: LeadTag_Insert_Input;
  on_conflict?: InputMaybe<LeadTag_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_LeadTrackedEventArgs = {
  objects: Array<LeadTrackedEvent_Insert_Input>;
  on_conflict?: InputMaybe<LeadTrackedEvent_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_LeadTrackedEvent_OneArgs = {
  object: LeadTrackedEvent_Insert_Input;
  on_conflict?: InputMaybe<LeadTrackedEvent_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_LocationArgs = {
  objects: Array<Location_Insert_Input>;
  on_conflict?: InputMaybe<Location_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_LocationCouponArgs = {
  objects: Array<LocationCoupon_Insert_Input>;
  on_conflict?: InputMaybe<LocationCoupon_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_LocationCoupon_OneArgs = {
  object: LocationCoupon_Insert_Input;
  on_conflict?: InputMaybe<LocationCoupon_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_LocationProviderArgs = {
  objects: Array<LocationProvider_Insert_Input>;
  on_conflict?: InputMaybe<LocationProvider_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_LocationProvider_OneArgs = {
  object: LocationProvider_Insert_Input;
  on_conflict?: InputMaybe<LocationProvider_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_LocationScheduleArgs = {
  objects: Array<LocationSchedule_Insert_Input>;
  on_conflict?: InputMaybe<LocationSchedule_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_LocationSchedule_OneArgs = {
  object: LocationSchedule_Insert_Input;
  on_conflict?: InputMaybe<LocationSchedule_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_LocationServiceArgs = {
  objects: Array<LocationService_Insert_Input>;
  on_conflict?: InputMaybe<LocationService_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_LocationService_OneArgs = {
  object: LocationService_Insert_Input;
  on_conflict?: InputMaybe<LocationService_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Location_OneArgs = {
  object: Location_Insert_Input;
  on_conflict?: InputMaybe<Location_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_MarketingTagArgs = {
  objects: Array<MarketingTag_Insert_Input>;
  on_conflict?: InputMaybe<MarketingTag_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_MarketingTagEmailArgs = {
  objects: Array<MarketingTagEmail_Insert_Input>;
  on_conflict?: InputMaybe<MarketingTagEmail_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_MarketingTagEmail_OneArgs = {
  object: MarketingTagEmail_Insert_Input;
  on_conflict?: InputMaybe<MarketingTagEmail_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_MarketingTagSmsArgs = {
  objects: Array<MarketingTagSms_Insert_Input>;
  on_conflict?: InputMaybe<MarketingTagSms_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_MarketingTagSms_OneArgs = {
  object: MarketingTagSms_Insert_Input;
  on_conflict?: InputMaybe<MarketingTagSms_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_MarketingTag_OneArgs = {
  object: MarketingTag_Insert_Input;
  on_conflict?: InputMaybe<MarketingTag_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_MediaTagArgs = {
  objects: Array<MediaTag_Insert_Input>;
  on_conflict?: InputMaybe<MediaTag_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_MediaTag_OneArgs = {
  object: MediaTag_Insert_Input;
  on_conflict?: InputMaybe<MediaTag_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_MembershipArgs = {
  objects: Array<Membership_Insert_Input>;
  on_conflict?: InputMaybe<Membership_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_MembershipCouponArgs = {
  objects: Array<MembershipCoupon_Insert_Input>;
  on_conflict?: InputMaybe<MembershipCoupon_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_MembershipCoupon_OneArgs = {
  object: MembershipCoupon_Insert_Input;
  on_conflict?: InputMaybe<MembershipCoupon_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_MembershipTypeArgs = {
  objects: Array<MembershipType_Insert_Input>;
  on_conflict?: InputMaybe<MembershipType_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_MembershipType_OneArgs = {
  object: MembershipType_Insert_Input;
  on_conflict?: InputMaybe<MembershipType_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Membership_OneArgs = {
  object: Membership_Insert_Input;
  on_conflict?: InputMaybe<Membership_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_NoteArgs = {
  objects: Array<Note_Insert_Input>;
  on_conflict?: InputMaybe<Note_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_NoteSignatureArgs = {
  objects: Array<NoteSignature_Insert_Input>;
  on_conflict?: InputMaybe<NoteSignature_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_NoteSignature_OneArgs = {
  object: NoteSignature_Insert_Input;
  on_conflict?: InputMaybe<NoteSignature_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Note_OneArgs = {
  object: Note_Insert_Input;
  on_conflict?: InputMaybe<Note_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_OrganizationArgs = {
  objects: Array<Organization_Insert_Input>;
  on_conflict?: InputMaybe<Organization_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_OrganizationConfigurationArgs = {
  objects: Array<OrganizationConfiguration_Insert_Input>;
  on_conflict?: InputMaybe<OrganizationConfiguration_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_OrganizationConfiguration_OneArgs = {
  object: OrganizationConfiguration_Insert_Input;
  on_conflict?: InputMaybe<OrganizationConfiguration_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Organization_OneArgs = {
  object: Organization_Insert_Input;
  on_conflict?: InputMaybe<Organization_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PackageArgs = {
  objects: Array<Package_Insert_Input>;
  on_conflict?: InputMaybe<Package_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Package_OneArgs = {
  object: Package_Insert_Input;
  on_conflict?: InputMaybe<Package_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PatientArgs = {
  objects: Array<Patient_Insert_Input>;
  on_conflict?: InputMaybe<Patient_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PatientAddressArgs = {
  objects: Array<PatientAddress_Insert_Input>;
  on_conflict?: InputMaybe<PatientAddress_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PatientAddress_OneArgs = {
  object: PatientAddress_Insert_Input;
  on_conflict?: InputMaybe<PatientAddress_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PatientConsentArgs = {
  objects: Array<PatientConsent_Insert_Input>;
  on_conflict?: InputMaybe<PatientConsent_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PatientConsent_OneArgs = {
  object: PatientConsent_Insert_Input;
  on_conflict?: InputMaybe<PatientConsent_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PatientConversationArgs = {
  objects: Array<PatientConversation_Insert_Input>;
  on_conflict?: InputMaybe<PatientConversation_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PatientConversation_OneArgs = {
  object: PatientConversation_Insert_Input;
  on_conflict?: InputMaybe<PatientConversation_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PatientFlowsheetDataArgs = {
  objects: Array<PatientFlowsheetData_Insert_Input>;
  on_conflict?: InputMaybe<PatientFlowsheetData_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PatientFlowsheetData_OneArgs = {
  object: PatientFlowsheetData_Insert_Input;
  on_conflict?: InputMaybe<PatientFlowsheetData_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PatientFlowsheetRowArgs = {
  objects: Array<PatientFlowsheetRow_Insert_Input>;
  on_conflict?: InputMaybe<PatientFlowsheetRow_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PatientFlowsheetRow_OneArgs = {
  object: PatientFlowsheetRow_Insert_Input;
  on_conflict?: InputMaybe<PatientFlowsheetRow_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PatientInviteArgs = {
  objects: Array<PatientInvite_Insert_Input>;
  on_conflict?: InputMaybe<PatientInvite_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PatientInvite_OneArgs = {
  object: PatientInvite_Insert_Input;
  on_conflict?: InputMaybe<PatientInvite_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PatientMembershipArgs = {
  objects: Array<PatientMembership_Insert_Input>;
  on_conflict?: InputMaybe<PatientMembership_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PatientMembership_OneArgs = {
  object: PatientMembership_Insert_Input;
  on_conflict?: InputMaybe<PatientMembership_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PatientSystemNoticeArgs = {
  objects: Array<PatientSystemNotice_Insert_Input>;
  on_conflict?: InputMaybe<PatientSystemNotice_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PatientSystemNotice_OneArgs = {
  object: PatientSystemNotice_Insert_Input;
  on_conflict?: InputMaybe<PatientSystemNotice_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PatientTagArgs = {
  objects: Array<PatientTag_Insert_Input>;
  on_conflict?: InputMaybe<PatientTag_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PatientTag_OneArgs = {
  object: PatientTag_Insert_Input;
  on_conflict?: InputMaybe<PatientTag_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PatientTagsArgs = {
  objects: Array<PatientTags_Insert_Input>;
  on_conflict?: InputMaybe<PatientTags_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PatientTags_OneArgs = {
  object: PatientTags_Insert_Input;
  on_conflict?: InputMaybe<PatientTags_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PatientWalletArgs = {
  objects: Array<PatientWallet_Insert_Input>;
  on_conflict?: InputMaybe<PatientWallet_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PatientWalletCouponArgs = {
  objects: Array<PatientWalletCoupon_Insert_Input>;
  on_conflict?: InputMaybe<PatientWalletCoupon_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PatientWalletCoupon_OneArgs = {
  object: PatientWalletCoupon_Insert_Input;
  on_conflict?: InputMaybe<PatientWalletCoupon_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PatientWalletTransactionArgs = {
  objects: Array<PatientWalletTransaction_Insert_Input>;
  on_conflict?: InputMaybe<PatientWalletTransaction_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PatientWalletTransaction_OneArgs = {
  object: PatientWalletTransaction_Insert_Input;
  on_conflict?: InputMaybe<PatientWalletTransaction_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PatientWallet_OneArgs = {
  object: PatientWallet_Insert_Input;
  on_conflict?: InputMaybe<PatientWallet_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PatientWorkspaceArgs = {
  objects: Array<PatientWorkspace_Insert_Input>;
  on_conflict?: InputMaybe<PatientWorkspace_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PatientWorkspace_OneArgs = {
  object: PatientWorkspace_Insert_Input;
  on_conflict?: InputMaybe<PatientWorkspace_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Patient_OneArgs = {
  object: Patient_Insert_Input;
  on_conflict?: InputMaybe<Patient_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PaymentArgs = {
  objects: Array<Payment_Insert_Input>;
  on_conflict?: InputMaybe<Payment_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PaymentMethodArgs = {
  objects: Array<PaymentMethod_Insert_Input>;
  on_conflict?: InputMaybe<PaymentMethod_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PaymentMethod_OneArgs = {
  object: PaymentMethod_Insert_Input;
  on_conflict?: InputMaybe<PaymentMethod_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PaymentRelationshipArgs = {
  objects: Array<PaymentRelationship_Insert_Input>;
  on_conflict?: InputMaybe<PaymentRelationship_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PaymentRelationship_OneArgs = {
  object: PaymentRelationship_Insert_Input;
  on_conflict?: InputMaybe<PaymentRelationship_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Payment_OneArgs = {
  object: Payment_Insert_Input;
  on_conflict?: InputMaybe<Payment_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PayrixTerminalArgs = {
  objects: Array<PayrixTerminal_Insert_Input>;
  on_conflict?: InputMaybe<PayrixTerminal_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PayrixTerminalTransactionArgs = {
  objects: Array<PayrixTerminalTransaction_Insert_Input>;
  on_conflict?: InputMaybe<PayrixTerminalTransaction_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PayrixTerminalTransaction_OneArgs = {
  object: PayrixTerminalTransaction_Insert_Input;
  on_conflict?: InputMaybe<PayrixTerminalTransaction_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PayrixTerminal_OneArgs = {
  object: PayrixTerminal_Insert_Input;
  on_conflict?: InputMaybe<PayrixTerminal_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PayrixWebAlertArgs = {
  objects: Array<PayrixWebAlert_Insert_Input>;
  on_conflict?: InputMaybe<PayrixWebAlert_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PayrixWebAlert_OneArgs = {
  object: PayrixWebAlert_Insert_Input;
  on_conflict?: InputMaybe<PayrixWebAlert_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PhotoArgs = {
  objects: Array<Photo_Insert_Input>;
  on_conflict?: InputMaybe<Photo_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PhotoMediaTagArgs = {
  objects: Array<PhotoMediaTag_Insert_Input>;
  on_conflict?: InputMaybe<PhotoMediaTag_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PhotoMediaTag_OneArgs = {
  object: PhotoMediaTag_Insert_Input;
  on_conflict?: InputMaybe<PhotoMediaTag_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Photo_OneArgs = {
  object: Photo_Insert_Input;
  on_conflict?: InputMaybe<Photo_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PinArgs = {
  objects: Array<Pin_Insert_Input>;
  on_conflict?: InputMaybe<Pin_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Pin_OneArgs = {
  object: Pin_Insert_Input;
  on_conflict?: InputMaybe<Pin_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PostStatusArgs = {
  objects: Array<PostStatus_Insert_Input>;
  on_conflict?: InputMaybe<PostStatus_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PostStatus_OneArgs = {
  object: PostStatus_Insert_Input;
  on_conflict?: InputMaybe<PostStatus_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PrescriptionArgs = {
  objects: Array<Prescription_Insert_Input>;
  on_conflict?: InputMaybe<Prescription_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PrescriptionDetailArgs = {
  objects: Array<PrescriptionDetail_Insert_Input>;
  on_conflict?: InputMaybe<PrescriptionDetail_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PrescriptionDetail_OneArgs = {
  object: PrescriptionDetail_Insert_Input;
  on_conflict?: InputMaybe<PrescriptionDetail_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Prescription_OneArgs = {
  object: Prescription_Insert_Input;
  on_conflict?: InputMaybe<Prescription_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PromotionArgs = {
  objects: Array<Promotion_Insert_Input>;
  on_conflict?: InputMaybe<Promotion_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PromotionCouponsArgs = {
  objects: Array<PromotionCoupons_Insert_Input>;
  on_conflict?: InputMaybe<PromotionCoupons_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PromotionCoupons_OneArgs = {
  object: PromotionCoupons_Insert_Input;
  on_conflict?: InputMaybe<PromotionCoupons_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PromotionServicesArgs = {
  objects: Array<PromotionServices_Insert_Input>;
  on_conflict?: InputMaybe<PromotionServices_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PromotionServices_OneArgs = {
  object: PromotionServices_Insert_Input;
  on_conflict?: InputMaybe<PromotionServices_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Promotion_OneArgs = {
  object: Promotion_Insert_Input;
  on_conflict?: InputMaybe<Promotion_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ProviderArgs = {
  objects: Array<Provider_Insert_Input>;
  on_conflict?: InputMaybe<Provider_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ProviderScheduleArgs = {
  objects: Array<ProviderSchedule_Insert_Input>;
  on_conflict?: InputMaybe<ProviderSchedule_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ProviderSchedule_OneArgs = {
  object: ProviderSchedule_Insert_Input;
  on_conflict?: InputMaybe<ProviderSchedule_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Provider_OneArgs = {
  object: Provider_Insert_Input;
  on_conflict?: InputMaybe<Provider_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PurchaseOrderArgs = {
  objects: Array<PurchaseOrder_Insert_Input>;
  on_conflict?: InputMaybe<PurchaseOrder_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PurchaseOrder_OneArgs = {
  object: PurchaseOrder_Insert_Input;
  on_conflict?: InputMaybe<PurchaseOrder_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_RecurrenceTypeArgs = {
  objects: Array<RecurrenceType_Insert_Input>;
  on_conflict?: InputMaybe<RecurrenceType_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_RecurrenceType_OneArgs = {
  object: RecurrenceType_Insert_Input;
  on_conflict?: InputMaybe<RecurrenceType_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_RefundArgs = {
  objects: Array<Refund_Insert_Input>;
  on_conflict?: InputMaybe<Refund_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Refund_OneArgs = {
  object: Refund_Insert_Input;
  on_conflict?: InputMaybe<Refund_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ResolutionStatusArgs = {
  objects: Array<ResolutionStatus_Insert_Input>;
  on_conflict?: InputMaybe<ResolutionStatus_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ResolutionStatus_OneArgs = {
  object: ResolutionStatus_Insert_Input;
  on_conflict?: InputMaybe<ResolutionStatus_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_RewardProgramArgs = {
  objects: Array<RewardProgram_Insert_Input>;
  on_conflict?: InputMaybe<RewardProgram_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_RewardProgramEnrollmentArgs = {
  objects: Array<RewardProgramEnrollment_Insert_Input>;
  on_conflict?: InputMaybe<RewardProgramEnrollment_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_RewardProgramEnrollment_OneArgs = {
  object: RewardProgramEnrollment_Insert_Input;
  on_conflict?: InputMaybe<RewardProgramEnrollment_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_RewardProgram_OneArgs = {
  object: RewardProgram_Insert_Input;
  on_conflict?: InputMaybe<RewardProgram_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_RoomArgs = {
  objects: Array<Room_Insert_Input>;
  on_conflict?: InputMaybe<Room_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Room_OneArgs = {
  object: Room_Insert_Input;
  on_conflict?: InputMaybe<Room_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_RsReviewArgs = {
  objects: Array<RsReview_Insert_Input>;
  on_conflict?: InputMaybe<RsReview_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_RsReview_OneArgs = {
  object: RsReview_Insert_Input;
  on_conflict?: InputMaybe<RsReview_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ServiceArgs = {
  objects: Array<Service_Insert_Input>;
  on_conflict?: InputMaybe<Service_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ServiceAddOnArgs = {
  objects: Array<ServiceAddOn_Insert_Input>;
  on_conflict?: InputMaybe<ServiceAddOn_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ServiceAddOn_OneArgs = {
  object: ServiceAddOn_Insert_Input;
  on_conflict?: InputMaybe<ServiceAddOn_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ServiceCategoryArgs = {
  objects: Array<ServiceCategory_Insert_Input>;
  on_conflict?: InputMaybe<ServiceCategory_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ServiceCategory_OneArgs = {
  object: ServiceCategory_Insert_Input;
  on_conflict?: InputMaybe<ServiceCategory_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ServiceConsentArgs = {
  objects: Array<ServiceConsent_Insert_Input>;
  on_conflict?: InputMaybe<ServiceConsent_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ServiceConsent_OneArgs = {
  object: ServiceConsent_Insert_Input;
  on_conflict?: InputMaybe<ServiceConsent_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ServiceConsumableTypeArgs = {
  objects: Array<ServiceConsumableType_Insert_Input>;
  on_conflict?: InputMaybe<ServiceConsumableType_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ServiceConsumableType_OneArgs = {
  object: ServiceConsumableType_Insert_Input;
  on_conflict?: InputMaybe<ServiceConsumableType_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ServiceCouponArgs = {
  objects: Array<ServiceCoupon_Insert_Input>;
  on_conflict?: InputMaybe<ServiceCoupon_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ServiceCoupon_OneArgs = {
  object: ServiceCoupon_Insert_Input;
  on_conflict?: InputMaybe<ServiceCoupon_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ServiceCustomFormArgs = {
  objects: Array<ServiceCustomForm_Insert_Input>;
  on_conflict?: InputMaybe<ServiceCustomForm_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ServiceCustomForm_OneArgs = {
  object: ServiceCustomForm_Insert_Input;
  on_conflict?: InputMaybe<ServiceCustomForm_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ServiceDeviceTypeArgs = {
  objects: Array<ServiceDeviceType_Insert_Input>;
  on_conflict?: InputMaybe<ServiceDeviceType_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ServiceDeviceType_OneArgs = {
  object: ServiceDeviceType_Insert_Input;
  on_conflict?: InputMaybe<ServiceDeviceType_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ServiceProviderArgs = {
  objects: Array<ServiceProvider_Insert_Input>;
  on_conflict?: InputMaybe<ServiceProvider_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ServiceProvider_OneArgs = {
  object: ServiceProvider_Insert_Input;
  on_conflict?: InputMaybe<ServiceProvider_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ServiceUnitArgs = {
  objects: Array<ServiceUnit_Insert_Input>;
  on_conflict?: InputMaybe<ServiceUnit_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ServiceUnit_OneArgs = {
  object: ServiceUnit_Insert_Input;
  on_conflict?: InputMaybe<ServiceUnit_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Service_OneArgs = {
  object: Service_Insert_Input;
  on_conflict?: InputMaybe<Service_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ShrinkArgs = {
  objects: Array<Shrink_Insert_Input>;
  on_conflict?: InputMaybe<Shrink_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Shrink_OneArgs = {
  object: Shrink_Insert_Input;
  on_conflict?: InputMaybe<Shrink_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_SignatureArgs = {
  objects: Array<Signature_Insert_Input>;
  on_conflict?: InputMaybe<Signature_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Signature_OneArgs = {
  object: Signature_Insert_Input;
  on_conflict?: InputMaybe<Signature_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_SmartNoteArgs = {
  objects: Array<SmartNote_Insert_Input>;
  on_conflict?: InputMaybe<SmartNote_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_SmartNote_OneArgs = {
  object: SmartNote_Insert_Input;
  on_conflict?: InputMaybe<SmartNote_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_SmsTemplateArgs = {
  objects: Array<SmsTemplate_Insert_Input>;
  on_conflict?: InputMaybe<SmsTemplate_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_SmsTemplatePatientArgs = {
  objects: Array<SmsTemplatePatient_Insert_Input>;
  on_conflict?: InputMaybe<SmsTemplatePatient_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_SmsTemplatePatient_OneArgs = {
  object: SmsTemplatePatient_Insert_Input;
  on_conflict?: InputMaybe<SmsTemplatePatient_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_SmsTemplateSendQueueArgs = {
  objects: Array<SmsTemplateSendQueue_Insert_Input>;
  on_conflict?: InputMaybe<SmsTemplateSendQueue_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_SmsTemplateSendQueue_OneArgs = {
  object: SmsTemplateSendQueue_Insert_Input;
  on_conflict?: InputMaybe<SmsTemplateSendQueue_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_SmsTemplate_OneArgs = {
  object: SmsTemplate_Insert_Input;
  on_conflict?: InputMaybe<SmsTemplate_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_SocialMediaAccountArgs = {
  objects: Array<SocialMediaAccount_Insert_Input>;
  on_conflict?: InputMaybe<SocialMediaAccount_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_SocialMediaAccountPostArgs = {
  objects: Array<SocialMediaAccountPost_Insert_Input>;
  on_conflict?: InputMaybe<SocialMediaAccountPost_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_SocialMediaAccountPost_OneArgs = {
  object: SocialMediaAccountPost_Insert_Input;
  on_conflict?: InputMaybe<SocialMediaAccountPost_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_SocialMediaAccount_OneArgs = {
  object: SocialMediaAccount_Insert_Input;
  on_conflict?: InputMaybe<SocialMediaAccount_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_SocialMediaPlatformArgs = {
  objects: Array<SocialMediaPlatform_Insert_Input>;
  on_conflict?: InputMaybe<SocialMediaPlatform_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_SocialMediaPlatform_OneArgs = {
  object: SocialMediaPlatform_Insert_Input;
  on_conflict?: InputMaybe<SocialMediaPlatform_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_SocialMediaPostArgs = {
  objects: Array<SocialMediaPost_Insert_Input>;
  on_conflict?: InputMaybe<SocialMediaPost_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_SocialMediaPost_OneArgs = {
  object: SocialMediaPost_Insert_Input;
  on_conflict?: InputMaybe<SocialMediaPost_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_TagArgs = {
  objects: Array<Tag_Insert_Input>;
  on_conflict?: InputMaybe<Tag_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tag_OneArgs = {
  object: Tag_Insert_Input;
  on_conflict?: InputMaybe<Tag_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_TaskArgs = {
  objects: Array<Task_Insert_Input>;
  on_conflict?: InputMaybe<Task_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_TaskCommentArgs = {
  objects: Array<TaskComment_Insert_Input>;
  on_conflict?: InputMaybe<TaskComment_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_TaskComment_OneArgs = {
  object: TaskComment_Insert_Input;
  on_conflict?: InputMaybe<TaskComment_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Task_OneArgs = {
  object: Task_Insert_Input;
  on_conflict?: InputMaybe<Task_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_TelehealthMeetingArgs = {
  objects: Array<TelehealthMeeting_Insert_Input>;
  on_conflict?: InputMaybe<TelehealthMeeting_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_TelehealthMeeting_OneArgs = {
  object: TelehealthMeeting_Insert_Input;
  on_conflict?: InputMaybe<TelehealthMeeting_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_UnusedAppointmentServiceConsumableTypeArgs = {
  objects: Array<UnusedAppointmentServiceConsumableType_Insert_Input>;
  on_conflict?: InputMaybe<UnusedAppointmentServiceConsumableType_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_UnusedAppointmentServiceConsumableType_OneArgs = {
  object: UnusedAppointmentServiceConsumableType_Insert_Input;
  on_conflict?: InputMaybe<UnusedAppointmentServiceConsumableType_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_UserArgs = {
  objects: Array<User_Insert_Input>;
  on_conflict?: InputMaybe<User_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_UserInviteArgs = {
  objects: Array<UserInvite_Insert_Input>;
  on_conflict?: InputMaybe<UserInvite_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_UserInvite_OneArgs = {
  object: UserInvite_Insert_Input;
  on_conflict?: InputMaybe<UserInvite_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_UserOrganizationArgs = {
  objects: Array<UserOrganization_Insert_Input>;
  on_conflict?: InputMaybe<UserOrganization_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_UserOrganization_OneArgs = {
  object: UserOrganization_Insert_Input;
  on_conflict?: InputMaybe<UserOrganization_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_UserWorkspaceArgs = {
  objects: Array<UserWorkspace_Insert_Input>;
  on_conflict?: InputMaybe<UserWorkspace_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_UserWorkspace_OneArgs = {
  object: UserWorkspace_Insert_Input;
  on_conflict?: InputMaybe<UserWorkspace_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_OneArgs = {
  object: User_Insert_Input;
  on_conflict?: InputMaybe<User_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_VitalsArgs = {
  objects: Array<Vitals_Insert_Input>;
  on_conflict?: InputMaybe<Vitals_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Vitals_OneArgs = {
  object: Vitals_Insert_Input;
  on_conflict?: InputMaybe<Vitals_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_WorkspaceArgs = {
  objects: Array<Workspace_Insert_Input>;
  on_conflict?: InputMaybe<Workspace_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_WorkspaceConfigurationArgs = {
  objects: Array<WorkspaceConfiguration_Insert_Input>;
  on_conflict?: InputMaybe<WorkspaceConfiguration_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_WorkspaceConfiguration_OneArgs = {
  object: WorkspaceConfiguration_Insert_Input;
  on_conflict?: InputMaybe<WorkspaceConfiguration_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Workspace_OneArgs = {
  object: Workspace_Insert_Input;
  on_conflict?: InputMaybe<Workspace_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ZdReviewArgs = {
  objects: Array<ZdReview_Insert_Input>;
  on_conflict?: InputMaybe<ZdReview_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ZdReview_OneArgs = {
  object: ZdReview_Insert_Input;
  on_conflict?: InputMaybe<ZdReview_On_Conflict>;
};


/** mutation root */
export type Mutation_RootLumaCaptureArgs = {
  mediaId: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootManuallyUpdatePatientWalletBalanceArgs = {
  input: ManuallyUpdatePatientWalletBalanceInput;
};


/** mutation root */
export type Mutation_RootMapToErxActionArgs = {
  patientWorkspaceId: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootMarkAsPaidArgs = {
  invoiceId: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootMergePatientArgs = {
  patientId: Scalars['uuid'];
  patientIdToMerge: Scalars['uuid'];
  workspaceId: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootMigrateLeadFormsArgs = {
  workspaceId: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootMigrateVitalsArgs = {
  workspaceIds: Array<Scalars['uuid']>;
};


/** mutation root */
export type Mutation_RootNylasWebhookArgs = {
  event?: InputMaybe<Scalars['String']>;
};


/** mutation root */
export type Mutation_RootOnboardPatientArgs = {
  input: OnboardPatientInput;
};


/** mutation root */
export type Mutation_RootOverrideAppointmentNoShowFeeArgs = {
  appointmentId: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootPayAppointmentDepositArgs = {
  input: PayAppointmentDepositInput;
};


/** mutation root */
export type Mutation_RootPayInvoiceArgs = {
  amount: Scalars['Int'];
  finixCardholderDetails?: InputMaybe<Scalars['jsonb']>;
  finixFraudSessionId?: InputMaybe<Scalars['String']>;
  finixToken?: InputMaybe<Scalars['String']>;
  invoiceId: Scalars['uuid'];
  paymentMethodId?: InputMaybe<Scalars['uuid']>;
  paymentMethodType: Scalars['String'];
  paymentType?: InputMaybe<PaymentType>;
  payrixTerminalTransactionId?: InputMaybe<Scalars['String']>;
  payrixTransactionId?: InputMaybe<Scalars['String']>;
  walletAmount?: InputMaybe<Scalars['Int']>;
};


/** mutation root */
export type Mutation_RootPayInvoiceWithTokenArgs = {
  amount?: InputMaybe<Scalars['Int']>;
  invoiceId: Scalars['uuid'];
  paymentInfo?: InputMaybe<Scalars['jsonb']>;
  paymentMethodId?: InputMaybe<Scalars['uuid']>;
  workspaceId: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootPayWithExistingCardArgs = {
  amount: Scalars['Int'];
  finixMerchantId?: InputMaybe<Scalars['String']>;
  paymentMethodId: Scalars['uuid'];
  payrixMerchantId?: InputMaybe<Scalars['String']>;
  payrixType?: InputMaybe<PayrixPaymentType>;
  type: PaymentType;
};


/** mutation root */
export type Mutation_RootPayWithExistingMethodArgs = {
  amount: Scalars['Int'];
  caseContext: Scalars['jsonb'];
  caseName: Scalars['String'];
  finixMerchantId?: InputMaybe<Scalars['String']>;
  patientId: Scalars['uuid'];
  paymentMethodId: Scalars['uuid'];
  payrixMerchantId?: InputMaybe<Scalars['String']>;
  workspaceId: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootPayWithNewMethodArgs = {
  amount: Scalars['Int'];
  caseContext: Scalars['jsonb'];
  caseName: Scalars['String'];
  finixMerchantId?: InputMaybe<Scalars['String']>;
  patientId?: InputMaybe<Scalars['uuid']>;
  paymentInfo: Scalars['jsonb'];
  payrixMerchantId?: InputMaybe<Scalars['String']>;
  workspaceId: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootPaymentFailedLaterArgs = {
  response: Scalars['jsonb'];
};


/** mutation root */
export type Mutation_RootPopulateStaticAudienceArgs = {
  audienceId: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootPurchaseFromStorefrontArgs = {
  input: PurchaseFromStorefrontInput;
};


/** mutation root */
export type Mutation_RootPurchaseGiftCardArgs = {
  amount: Scalars['Int'];
  cardData?: InputMaybe<CardInformation>;
  cardHolderDetails?: InputMaybe<CardHolderDetailsInput>;
  fraudSessionId?: InputMaybe<Scalars['String']>;
  locationId?: InputMaybe<Scalars['String']>;
  payrixTransactionId?: InputMaybe<Scalars['String']>;
  purchaserId: Scalars['String'];
  receiverId: Scalars['String'];
  token?: InputMaybe<Scalars['String']>;
  workspaceId: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootRefundOrVoidPayrixTransactionArgs = {
  transactionId: Scalars['String'];
};


/** mutation root */
export type Mutation_RootRefundPaymentArgs = {
  amount?: InputMaybe<Scalars['Int']>;
  paymentId: Scalars['uuid'];
  reason?: InputMaybe<Scalars['String']>;
  shouldReturnToBalance?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['String']>;
};


/** mutation root */
export type Mutation_RootRegenerateAppointmentRemindersArgs = {
  appointmentId: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootRegenerateInvoiceTotalsArgs = {
  invoiceIds: Array<Scalars['uuid']>;
};


/** mutation root */
export type Mutation_RootRegeneratePrePostCareMessagesArgs = {
  appointmentId: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootReplyToGoogleReviewArgs = {
  comment: Scalars['String'];
  externalId: Scalars['String'];
};


/** mutation root */
export type Mutation_RootResendUserInviteArgs = {
  inviteId: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootResolveAppointmentServiceConsumableArgs = {
  input: ResolveAppointmentServiceConsumableInput;
};


/** mutation root */
export type Mutation_RootResolveInvoiceConsumablesArgs = {
  input: ResolveInvoiceConsumablesInput;
};


/** mutation root */
export type Mutation_RootRetractInvoiceMembershipArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootRetractInvoicePackageArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootRetryCreateSubscriptionArgs = {
  invoiceMembershipId: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootReverseAuthorizationHoldArgs = {
  paymentId: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootRevertToMediaVersionArgs = {
  photoId: Scalars['uuid'];
  versionId: Scalars['String'];
};


/** mutation root */
export type Mutation_RootSandboxArgs = {
  input: Scalars['jsonb'];
};


/** mutation root */
export type Mutation_RootSandboxMagicLinkArgs = {
  app: Scalars['String'];
  firebaseUid: Scalars['String'];
};


/** mutation root */
export type Mutation_RootSaveUrlToPatientPhotosArgs = {
  patientId: Scalars['uuid'];
  url: Scalars['String'];
  workspaceId: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootScheduleSmsSendArgs = {
  smsTemplateId: Scalars['String'];
};


/** mutation root */
export type Mutation_RootSeedDemoReviewsArgs = {
  workspaceId: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootSeedDemoWorkspaceArgs = {
  workspaceId: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootSendAppointmentRescheduleConfirmationArgs = {
  appointmentId: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootSendDocumentToPatientEmailArgs = {
  documentId: Scalars['uuid'];
  patientId: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootSendInngestEventArgs = {
  eventName: Scalars['String'];
  payload: Scalars['String'];
};


/** mutation root */
export type Mutation_RootSendInviteLinkArgs = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  role: Scalars['String'];
  test?: InputMaybe<Scalars['Boolean']>;
  workspaceId: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootSendInvoicePaymentLinkArgs = {
  channel?: InputMaybe<Scalars['String']>;
  invoiceId: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootSendInvoiceReceiptToPatientArgs = {
  invoiceId: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootSendInvoiceToPatientArgs = {
  invoiceId: Scalars['uuid'];
  method?: InputMaybe<Scalars['String']>;
};


/** mutation root */
export type Mutation_RootSendLeadFormLinkArgs = {
  leadFormLinkId: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootSendPatientInviteArgs = {
  email: Scalars['String'];
  patientId: Scalars['uuid'];
  phoneNumber?: InputMaybe<Scalars['String']>;
};


/** mutation root */
export type Mutation_RootSendPatientLoginLinkArgs = {
  email: Scalars['String'];
};


/** mutation root */
export type Mutation_RootSendPaymentToFinixDeviceArgs = {
  amount: Scalars['Int'];
  finixDeviceId: Scalars['String'];
  invoiceId: Scalars['uuid'];
  tags?: InputMaybe<Scalars['jsonb']>;
};


/** mutation root */
export type Mutation_RootSendSmsTemplateArgs = {
  smsTemplateId: Scalars['String'];
};


/** mutation root */
export type Mutation_RootSendTransactionToTerminalArgs = {
  amount: Scalars['Int'];
  invoiceId: Scalars['uuid'];
  payrixTerminalId: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootSetBillingPatientAddressArgs = {
  patientAddressId: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootSetDefaultPatientAddressArgs = {
  patientAddressId: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootSimpleUpdateInvoiceArgs = {
  invoiceId: Scalars['uuid'];
  set: SimpleUpdateInvoiceInput;
};


/** mutation root */
export type Mutation_RootSubmitLeadFormFieldsArgs = {
  fieldValues?: InputMaybe<Array<InputMaybe<LeadFormFieldValueInput>>>;
};


/** mutation root */
export type Mutation_RootSubmitPinArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootSubmitSurveyJsFormArgs = {
  input: SubmitSurveyJsInput;
};


/** mutation root */
export type Mutation_RootTestSmsTemplateArgs = {
  mmsImage?: InputMaybe<Scalars['String']>;
  smsBody: Scalars['String'];
  testSmsNumber: Scalars['String'];
};


/** mutation root */
export type Mutation_RootTradeJwtTokenArgs = {
  token: Scalars['String'];
};


/** mutation root */
export type Mutation_RootTranscribeAudioArgs = {
  filePath: Scalars['String'];
};


/** mutation root */
export type Mutation_RootUnenrollProviderERxArgs = {
  locationIds: Array<Scalars['uuid']>;
  providerId: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootUpdateAppointmentAndNotifyArgs = {
  id: Scalars['uuid'];
  set: Scalars['jsonb'];
  shouldUpdateServiceTimes?: InputMaybe<Scalars['Boolean']>;
};


/** mutation root */
export type Mutation_RootUpdateAppointmentServiceConsumableArgs = {
  input: UpdateAppointmentServiceConsumableInput;
};


/** mutation root */
export type Mutation_RootUpdateAppointmentServicesArgs = {
  object: AppointmentServiceUpdateObject;
};


/** mutation root */
export type Mutation_RootUpdateCompensationPolicyArgs = {
  id: Scalars['uuid'];
  set: CompensationPolicyUpdateInput;
};


/** mutation root */
export type Mutation_RootUpdateCompensationStrategyArgs = {
  id: Scalars['uuid'];
  set: CompensationStrategyInsertInput;
};


/** mutation root */
export type Mutation_RootUpdateConsumableQuantityAtLocationArgs = {
  input: UpdateConsumableQuantityAtLocationInput;
};


/** mutation root */
export type Mutation_RootUpdateDocumentArgs = {
  id: Scalars['uuid'];
  set: DocumentUpdateInput;
};


/** mutation root */
export type Mutation_RootUpdateFlowsheetArgs = {
  flowsheetId: Scalars['uuid'];
  parameterIds: Array<Scalars['uuid']>;
  title: Scalars['String'];
};


/** mutation root */
export type Mutation_RootUpdateImageArgs = {
  input: UpdateImageInput;
};


/** mutation root */
export type Mutation_RootUpdatePatientWalletCouponsArgs = {
  object: UpdatePatientWalletCouponsInput;
};


/** mutation root */
export type Mutation_RootUpdateSubscriptionPaymentMethodArgs = {
  patientMembershipId: Scalars['uuid'];
  paymentMethodId: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootUpdate_ActivityLogArgs = {
  _append?: InputMaybe<ActivityLog_Append_Input>;
  _delete_at_path?: InputMaybe<ActivityLog_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<ActivityLog_Delete_Elem_Input>;
  _delete_key?: InputMaybe<ActivityLog_Delete_Key_Input>;
  _prepend?: InputMaybe<ActivityLog_Prepend_Input>;
  _set?: InputMaybe<ActivityLog_Set_Input>;
  where: ActivityLog_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_ActivityLog_By_PkArgs = {
  _append?: InputMaybe<ActivityLog_Append_Input>;
  _delete_at_path?: InputMaybe<ActivityLog_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<ActivityLog_Delete_Elem_Input>;
  _delete_key?: InputMaybe<ActivityLog_Delete_Key_Input>;
  _prepend?: InputMaybe<ActivityLog_Prepend_Input>;
  _set?: InputMaybe<ActivityLog_Set_Input>;
  pk_columns: ActivityLog_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ActivityLog_ManyArgs = {
  updates: Array<ActivityLog_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_AddressArgs = {
  _set?: InputMaybe<Address_Set_Input>;
  where: Address_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Address_By_PkArgs = {
  _set?: InputMaybe<Address_Set_Input>;
  pk_columns: Address_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Address_ManyArgs = {
  updates: Array<Address_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_AppointmentArgs = {
  _append?: InputMaybe<Appointment_Append_Input>;
  _delete_at_path?: InputMaybe<Appointment_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Appointment_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Appointment_Delete_Key_Input>;
  _inc?: InputMaybe<Appointment_Inc_Input>;
  _prepend?: InputMaybe<Appointment_Prepend_Input>;
  _set?: InputMaybe<Appointment_Set_Input>;
  where: Appointment_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_AppointmentConsentArgs = {
  _set?: InputMaybe<AppointmentConsent_Set_Input>;
  where: AppointmentConsent_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_AppointmentConsent_By_PkArgs = {
  _set?: InputMaybe<AppointmentConsent_Set_Input>;
  pk_columns: AppointmentConsent_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_AppointmentConsent_ManyArgs = {
  updates: Array<AppointmentConsent_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_AppointmentCustomFormArgs = {
  _set?: InputMaybe<AppointmentCustomForm_Set_Input>;
  where: AppointmentCustomForm_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_AppointmentCustomForm_By_PkArgs = {
  _set?: InputMaybe<AppointmentCustomForm_Set_Input>;
  pk_columns: AppointmentCustomForm_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_AppointmentCustomForm_ManyArgs = {
  updates: Array<AppointmentCustomForm_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_AppointmentServiceArgs = {
  _inc?: InputMaybe<AppointmentService_Inc_Input>;
  _set?: InputMaybe<AppointmentService_Set_Input>;
  where: AppointmentService_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_AppointmentServiceConsumableArgs = {
  _inc?: InputMaybe<AppointmentServiceConsumable_Inc_Input>;
  _set?: InputMaybe<AppointmentServiceConsumable_Set_Input>;
  where: AppointmentServiceConsumable_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_AppointmentServiceConsumable_By_PkArgs = {
  _inc?: InputMaybe<AppointmentServiceConsumable_Inc_Input>;
  _set?: InputMaybe<AppointmentServiceConsumable_Set_Input>;
  pk_columns: AppointmentServiceConsumable_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_AppointmentServiceConsumable_ManyArgs = {
  updates: Array<AppointmentServiceConsumable_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_AppointmentService_By_PkArgs = {
  _inc?: InputMaybe<AppointmentService_Inc_Input>;
  _set?: InputMaybe<AppointmentService_Set_Input>;
  pk_columns: AppointmentService_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_AppointmentService_ManyArgs = {
  updates: Array<AppointmentService_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_AppointmentTypeArgs = {
  _inc?: InputMaybe<AppointmentType_Inc_Input>;
  _set?: InputMaybe<AppointmentType_Set_Input>;
  where: AppointmentType_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_AppointmentType_By_PkArgs = {
  _inc?: InputMaybe<AppointmentType_Inc_Input>;
  _set?: InputMaybe<AppointmentType_Set_Input>;
  pk_columns: AppointmentType_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_AppointmentType_ManyArgs = {
  updates: Array<AppointmentType_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Appointment_By_PkArgs = {
  _append?: InputMaybe<Appointment_Append_Input>;
  _delete_at_path?: InputMaybe<Appointment_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Appointment_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Appointment_Delete_Key_Input>;
  _inc?: InputMaybe<Appointment_Inc_Input>;
  _prepend?: InputMaybe<Appointment_Prepend_Input>;
  _set?: InputMaybe<Appointment_Set_Input>;
  pk_columns: Appointment_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Appointment_ManyArgs = {
  updates: Array<Appointment_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_AudienceArgs = {
  _append?: InputMaybe<Audience_Append_Input>;
  _delete_at_path?: InputMaybe<Audience_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Audience_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Audience_Delete_Key_Input>;
  _prepend?: InputMaybe<Audience_Prepend_Input>;
  _set?: InputMaybe<Audience_Set_Input>;
  where: Audience_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_AudienceEmailArgs = {
  _set?: InputMaybe<AudienceEmail_Set_Input>;
  where: AudienceEmail_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_AudienceEmail_By_PkArgs = {
  _set?: InputMaybe<AudienceEmail_Set_Input>;
  pk_columns: AudienceEmail_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_AudienceEmail_ManyArgs = {
  updates: Array<AudienceEmail_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Audience_By_PkArgs = {
  _append?: InputMaybe<Audience_Append_Input>;
  _delete_at_path?: InputMaybe<Audience_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Audience_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Audience_Delete_Key_Input>;
  _prepend?: InputMaybe<Audience_Prepend_Input>;
  _set?: InputMaybe<Audience_Set_Input>;
  pk_columns: Audience_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Audience_ManyArgs = {
  updates: Array<Audience_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_AuthProfileArgs = {
  _append?: InputMaybe<AuthProfile_Append_Input>;
  _delete_at_path?: InputMaybe<AuthProfile_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<AuthProfile_Delete_Elem_Input>;
  _delete_key?: InputMaybe<AuthProfile_Delete_Key_Input>;
  _inc?: InputMaybe<AuthProfile_Inc_Input>;
  _prepend?: InputMaybe<AuthProfile_Prepend_Input>;
  _set?: InputMaybe<AuthProfile_Set_Input>;
  where: AuthProfile_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_AuthProfile_By_PkArgs = {
  _append?: InputMaybe<AuthProfile_Append_Input>;
  _delete_at_path?: InputMaybe<AuthProfile_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<AuthProfile_Delete_Elem_Input>;
  _delete_key?: InputMaybe<AuthProfile_Delete_Key_Input>;
  _inc?: InputMaybe<AuthProfile_Inc_Input>;
  _prepend?: InputMaybe<AuthProfile_Prepend_Input>;
  _set?: InputMaybe<AuthProfile_Set_Input>;
  pk_columns: AuthProfile_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_AuthProfile_ManyArgs = {
  updates: Array<AuthProfile_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ClinicalMessageArgs = {
  _inc?: InputMaybe<ClinicalMessage_Inc_Input>;
  _set?: InputMaybe<ClinicalMessage_Set_Input>;
  where: ClinicalMessage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_ClinicalMessageStatusArgs = {
  _set?: InputMaybe<ClinicalMessageStatus_Set_Input>;
  where: ClinicalMessageStatus_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_ClinicalMessageStatus_By_PkArgs = {
  _set?: InputMaybe<ClinicalMessageStatus_Set_Input>;
  pk_columns: ClinicalMessageStatus_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ClinicalMessageStatus_ManyArgs = {
  updates: Array<ClinicalMessageStatus_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ClinicalMessage_By_PkArgs = {
  _inc?: InputMaybe<ClinicalMessage_Inc_Input>;
  _set?: InputMaybe<ClinicalMessage_Set_Input>;
  pk_columns: ClinicalMessage_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ClinicalMessage_ManyArgs = {
  updates: Array<ClinicalMessage_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_CompensationPolicyArgs = {
  _append?: InputMaybe<CompensationPolicy_Append_Input>;
  _delete_at_path?: InputMaybe<CompensationPolicy_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<CompensationPolicy_Delete_Elem_Input>;
  _delete_key?: InputMaybe<CompensationPolicy_Delete_Key_Input>;
  _prepend?: InputMaybe<CompensationPolicy_Prepend_Input>;
  _set?: InputMaybe<CompensationPolicy_Set_Input>;
  where: CompensationPolicy_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_CompensationPolicyCommissionArgs = {
  _inc?: InputMaybe<CompensationPolicyCommission_Inc_Input>;
  _set?: InputMaybe<CompensationPolicyCommission_Set_Input>;
  where: CompensationPolicyCommission_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_CompensationPolicyCommission_By_PkArgs = {
  _inc?: InputMaybe<CompensationPolicyCommission_Inc_Input>;
  _set?: InputMaybe<CompensationPolicyCommission_Set_Input>;
  pk_columns: CompensationPolicyCommission_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_CompensationPolicyCommission_ManyArgs = {
  updates: Array<CompensationPolicyCommission_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_CompensationPolicyTierArgs = {
  _inc?: InputMaybe<CompensationPolicyTier_Inc_Input>;
  _set?: InputMaybe<CompensationPolicyTier_Set_Input>;
  where: CompensationPolicyTier_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_CompensationPolicyTierCategoryArgs = {
  _set?: InputMaybe<CompensationPolicyTierCategory_Set_Input>;
  where: CompensationPolicyTierCategory_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_CompensationPolicyTierCategory_By_PkArgs = {
  _set?: InputMaybe<CompensationPolicyTierCategory_Set_Input>;
  pk_columns: CompensationPolicyTierCategory_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_CompensationPolicyTierCategory_ManyArgs = {
  updates: Array<CompensationPolicyTierCategory_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_CompensationPolicyTierExemptionArgs = {
  _set?: InputMaybe<CompensationPolicyTierExemption_Set_Input>;
  where: CompensationPolicyTierExemption_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_CompensationPolicyTierExemption_By_PkArgs = {
  _set?: InputMaybe<CompensationPolicyTierExemption_Set_Input>;
  pk_columns: CompensationPolicyTierExemption_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_CompensationPolicyTierExemption_ManyArgs = {
  updates: Array<CompensationPolicyTierExemption_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_CompensationPolicyTierInclusionArgs = {
  _set?: InputMaybe<CompensationPolicyTierInclusion_Set_Input>;
  where: CompensationPolicyTierInclusion_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_CompensationPolicyTierInclusion_By_PkArgs = {
  _set?: InputMaybe<CompensationPolicyTierInclusion_Set_Input>;
  pk_columns: CompensationPolicyTierInclusion_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_CompensationPolicyTierInclusion_ManyArgs = {
  updates: Array<CompensationPolicyTierInclusion_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_CompensationPolicyTier_By_PkArgs = {
  _inc?: InputMaybe<CompensationPolicyTier_Inc_Input>;
  _set?: InputMaybe<CompensationPolicyTier_Set_Input>;
  pk_columns: CompensationPolicyTier_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_CompensationPolicyTier_ManyArgs = {
  updates: Array<CompensationPolicyTier_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_CompensationPolicy_By_PkArgs = {
  _append?: InputMaybe<CompensationPolicy_Append_Input>;
  _delete_at_path?: InputMaybe<CompensationPolicy_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<CompensationPolicy_Delete_Elem_Input>;
  _delete_key?: InputMaybe<CompensationPolicy_Delete_Key_Input>;
  _prepend?: InputMaybe<CompensationPolicy_Prepend_Input>;
  _set?: InputMaybe<CompensationPolicy_Set_Input>;
  pk_columns: CompensationPolicy_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_CompensationPolicy_ManyArgs = {
  updates: Array<CompensationPolicy_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_CompensationStrategyArgs = {
  _append?: InputMaybe<CompensationStrategy_Append_Input>;
  _delete_at_path?: InputMaybe<CompensationStrategy_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<CompensationStrategy_Delete_Elem_Input>;
  _delete_key?: InputMaybe<CompensationStrategy_Delete_Key_Input>;
  _prepend?: InputMaybe<CompensationStrategy_Prepend_Input>;
  _set?: InputMaybe<CompensationStrategy_Set_Input>;
  where: CompensationStrategy_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_CompensationStrategyCommissionArgs = {
  _inc?: InputMaybe<CompensationStrategyCommission_Inc_Input>;
  _set?: InputMaybe<CompensationStrategyCommission_Set_Input>;
  where: CompensationStrategyCommission_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_CompensationStrategyCommission_By_PkArgs = {
  _inc?: InputMaybe<CompensationStrategyCommission_Inc_Input>;
  _set?: InputMaybe<CompensationStrategyCommission_Set_Input>;
  pk_columns: CompensationStrategyCommission_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_CompensationStrategyCommission_ManyArgs = {
  updates: Array<CompensationStrategyCommission_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_CompensationStrategyTierArgs = {
  _inc?: InputMaybe<CompensationStrategyTier_Inc_Input>;
  _set?: InputMaybe<CompensationStrategyTier_Set_Input>;
  where: CompensationStrategyTier_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_CompensationStrategyTierCategoryArgs = {
  _set?: InputMaybe<CompensationStrategyTierCategory_Set_Input>;
  where: CompensationStrategyTierCategory_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_CompensationStrategyTierCategory_By_PkArgs = {
  _set?: InputMaybe<CompensationStrategyTierCategory_Set_Input>;
  pk_columns: CompensationStrategyTierCategory_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_CompensationStrategyTierCategory_ManyArgs = {
  updates: Array<CompensationStrategyTierCategory_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_CompensationStrategyTierExemptionArgs = {
  _set?: InputMaybe<CompensationStrategyTierExemption_Set_Input>;
  where: CompensationStrategyTierExemption_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_CompensationStrategyTierExemption_By_PkArgs = {
  _set?: InputMaybe<CompensationStrategyTierExemption_Set_Input>;
  pk_columns: CompensationStrategyTierExemption_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_CompensationStrategyTierExemption_ManyArgs = {
  updates: Array<CompensationStrategyTierExemption_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_CompensationStrategyTierInclusionArgs = {
  _set?: InputMaybe<CompensationStrategyTierInclusion_Set_Input>;
  where: CompensationStrategyTierInclusion_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_CompensationStrategyTierInclusion_By_PkArgs = {
  _set?: InputMaybe<CompensationStrategyTierInclusion_Set_Input>;
  pk_columns: CompensationStrategyTierInclusion_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_CompensationStrategyTierInclusion_ManyArgs = {
  updates: Array<CompensationStrategyTierInclusion_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_CompensationStrategyTier_By_PkArgs = {
  _inc?: InputMaybe<CompensationStrategyTier_Inc_Input>;
  _set?: InputMaybe<CompensationStrategyTier_Set_Input>;
  pk_columns: CompensationStrategyTier_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_CompensationStrategyTier_ManyArgs = {
  updates: Array<CompensationStrategyTier_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_CompensationStrategy_By_PkArgs = {
  _append?: InputMaybe<CompensationStrategy_Append_Input>;
  _delete_at_path?: InputMaybe<CompensationStrategy_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<CompensationStrategy_Delete_Elem_Input>;
  _delete_key?: InputMaybe<CompensationStrategy_Delete_Key_Input>;
  _prepend?: InputMaybe<CompensationStrategy_Prepend_Input>;
  _set?: InputMaybe<CompensationStrategy_Set_Input>;
  pk_columns: CompensationStrategy_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_CompensationStrategy_ManyArgs = {
  updates: Array<CompensationStrategy_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ConsentArgs = {
  _append?: InputMaybe<Consent_Append_Input>;
  _delete_at_path?: InputMaybe<Consent_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Consent_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Consent_Delete_Key_Input>;
  _prepend?: InputMaybe<Consent_Prepend_Input>;
  _set?: InputMaybe<Consent_Set_Input>;
  where: Consent_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Consent_By_PkArgs = {
  _append?: InputMaybe<Consent_Append_Input>;
  _delete_at_path?: InputMaybe<Consent_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Consent_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Consent_Delete_Key_Input>;
  _prepend?: InputMaybe<Consent_Prepend_Input>;
  _set?: InputMaybe<Consent_Set_Input>;
  pk_columns: Consent_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Consent_ManyArgs = {
  updates: Array<Consent_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ConsumableArgs = {
  _append?: InputMaybe<Consumable_Append_Input>;
  _delete_at_path?: InputMaybe<Consumable_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Consumable_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Consumable_Delete_Key_Input>;
  _inc?: InputMaybe<Consumable_Inc_Input>;
  _prepend?: InputMaybe<Consumable_Prepend_Input>;
  _set?: InputMaybe<Consumable_Set_Input>;
  where: Consumable_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_ConsumableBrandArgs = {
  _set?: InputMaybe<ConsumableBrand_Set_Input>;
  where: ConsumableBrand_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_ConsumableBrand_By_PkArgs = {
  _set?: InputMaybe<ConsumableBrand_Set_Input>;
  pk_columns: ConsumableBrand_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ConsumableBrand_ManyArgs = {
  updates: Array<ConsumableBrand_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ConsumableSubtypeArgs = {
  _set?: InputMaybe<ConsumableSubtype_Set_Input>;
  where: ConsumableSubtype_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_ConsumableSubtype_By_PkArgs = {
  _set?: InputMaybe<ConsumableSubtype_Set_Input>;
  pk_columns: ConsumableSubtype_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ConsumableSubtype_ManyArgs = {
  updates: Array<ConsumableSubtype_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ConsumableTypeArgs = {
  _append?: InputMaybe<ConsumableType_Append_Input>;
  _delete_at_path?: InputMaybe<ConsumableType_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<ConsumableType_Delete_Elem_Input>;
  _delete_key?: InputMaybe<ConsumableType_Delete_Key_Input>;
  _inc?: InputMaybe<ConsumableType_Inc_Input>;
  _prepend?: InputMaybe<ConsumableType_Prepend_Input>;
  _set?: InputMaybe<ConsumableType_Set_Input>;
  where: ConsumableType_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_ConsumableTypeConsumableSubtypeArgs = {
  _set?: InputMaybe<ConsumableTypeConsumableSubtype_Set_Input>;
  where: ConsumableTypeConsumableSubtype_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_ConsumableTypeConsumableSubtype_By_PkArgs = {
  _set?: InputMaybe<ConsumableTypeConsumableSubtype_Set_Input>;
  pk_columns: ConsumableTypeConsumableSubtype_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ConsumableTypeConsumableSubtype_ManyArgs = {
  updates: Array<ConsumableTypeConsumableSubtype_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ConsumableType_By_PkArgs = {
  _append?: InputMaybe<ConsumableType_Append_Input>;
  _delete_at_path?: InputMaybe<ConsumableType_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<ConsumableType_Delete_Elem_Input>;
  _delete_key?: InputMaybe<ConsumableType_Delete_Key_Input>;
  _inc?: InputMaybe<ConsumableType_Inc_Input>;
  _prepend?: InputMaybe<ConsumableType_Prepend_Input>;
  _set?: InputMaybe<ConsumableType_Set_Input>;
  pk_columns: ConsumableType_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ConsumableType_ManyArgs = {
  updates: Array<ConsumableType_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Consumable_By_PkArgs = {
  _append?: InputMaybe<Consumable_Append_Input>;
  _delete_at_path?: InputMaybe<Consumable_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Consumable_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Consumable_Delete_Key_Input>;
  _inc?: InputMaybe<Consumable_Inc_Input>;
  _prepend?: InputMaybe<Consumable_Prepend_Input>;
  _set?: InputMaybe<Consumable_Set_Input>;
  pk_columns: Consumable_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Consumable_ManyArgs = {
  updates: Array<Consumable_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_CouponArgs = {
  _inc?: InputMaybe<Coupon_Inc_Input>;
  _set?: InputMaybe<Coupon_Set_Input>;
  where: Coupon_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_CouponConfigurationArgs = {
  _inc?: InputMaybe<CouponConfiguration_Inc_Input>;
  _set?: InputMaybe<CouponConfiguration_Set_Input>;
  where: CouponConfiguration_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_CouponConfiguration_By_PkArgs = {
  _inc?: InputMaybe<CouponConfiguration_Inc_Input>;
  _set?: InputMaybe<CouponConfiguration_Set_Input>;
  pk_columns: CouponConfiguration_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_CouponConfiguration_ManyArgs = {
  updates: Array<CouponConfiguration_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_CouponScopeArgs = {
  _set?: InputMaybe<CouponScope_Set_Input>;
  where: CouponScope_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_CouponScope_By_PkArgs = {
  _set?: InputMaybe<CouponScope_Set_Input>;
  pk_columns: CouponScope_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_CouponScope_ManyArgs = {
  updates: Array<CouponScope_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_CouponTypeArgs = {
  _set?: InputMaybe<CouponType_Set_Input>;
  where: CouponType_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_CouponType_By_PkArgs = {
  _set?: InputMaybe<CouponType_Set_Input>;
  pk_columns: CouponType_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_CouponType_ManyArgs = {
  updates: Array<CouponType_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Coupon_By_PkArgs = {
  _inc?: InputMaybe<Coupon_Inc_Input>;
  _set?: InputMaybe<Coupon_Set_Input>;
  pk_columns: Coupon_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Coupon_ManyArgs = {
  updates: Array<Coupon_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_CustomFormArgs = {
  _append?: InputMaybe<CustomForm_Append_Input>;
  _delete_at_path?: InputMaybe<CustomForm_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<CustomForm_Delete_Elem_Input>;
  _delete_key?: InputMaybe<CustomForm_Delete_Key_Input>;
  _prepend?: InputMaybe<CustomForm_Prepend_Input>;
  _set?: InputMaybe<CustomForm_Set_Input>;
  where: CustomForm_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_CustomFormFieldArgs = {
  _append?: InputMaybe<CustomFormField_Append_Input>;
  _delete_at_path?: InputMaybe<CustomFormField_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<CustomFormField_Delete_Elem_Input>;
  _delete_key?: InputMaybe<CustomFormField_Delete_Key_Input>;
  _inc?: InputMaybe<CustomFormField_Inc_Input>;
  _prepend?: InputMaybe<CustomFormField_Prepend_Input>;
  _set?: InputMaybe<CustomFormField_Set_Input>;
  where: CustomFormField_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_CustomFormFieldCategoryArgs = {
  _set?: InputMaybe<CustomFormFieldCategory_Set_Input>;
  where: CustomFormFieldCategory_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_CustomFormFieldCategory_By_PkArgs = {
  _set?: InputMaybe<CustomFormFieldCategory_Set_Input>;
  pk_columns: CustomFormFieldCategory_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_CustomFormFieldCategory_ManyArgs = {
  updates: Array<CustomFormFieldCategory_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_CustomFormFieldResponseArgs = {
  _set?: InputMaybe<CustomFormFieldResponse_Set_Input>;
  where: CustomFormFieldResponse_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_CustomFormFieldResponse_By_PkArgs = {
  _set?: InputMaybe<CustomFormFieldResponse_Set_Input>;
  pk_columns: CustomFormFieldResponse_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_CustomFormFieldResponse_ManyArgs = {
  updates: Array<CustomFormFieldResponse_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_CustomFormFieldTypeArgs = {
  _set?: InputMaybe<CustomFormFieldType_Set_Input>;
  where: CustomFormFieldType_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_CustomFormFieldType_By_PkArgs = {
  _set?: InputMaybe<CustomFormFieldType_Set_Input>;
  pk_columns: CustomFormFieldType_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_CustomFormFieldType_ManyArgs = {
  updates: Array<CustomFormFieldType_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_CustomFormField_By_PkArgs = {
  _append?: InputMaybe<CustomFormField_Append_Input>;
  _delete_at_path?: InputMaybe<CustomFormField_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<CustomFormField_Delete_Elem_Input>;
  _delete_key?: InputMaybe<CustomFormField_Delete_Key_Input>;
  _inc?: InputMaybe<CustomFormField_Inc_Input>;
  _prepend?: InputMaybe<CustomFormField_Prepend_Input>;
  _set?: InputMaybe<CustomFormField_Set_Input>;
  pk_columns: CustomFormField_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_CustomFormField_ManyArgs = {
  updates: Array<CustomFormField_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_CustomFormOptionArgs = {
  _set?: InputMaybe<CustomFormOption_Set_Input>;
  where: CustomFormOption_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_CustomFormOption_By_PkArgs = {
  _set?: InputMaybe<CustomFormOption_Set_Input>;
  pk_columns: CustomFormOption_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_CustomFormOption_ManyArgs = {
  updates: Array<CustomFormOption_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_CustomFormReplicatedFieldArgs = {
  _set?: InputMaybe<CustomFormReplicatedField_Set_Input>;
  where: CustomFormReplicatedField_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_CustomFormReplicatedField_By_PkArgs = {
  _set?: InputMaybe<CustomFormReplicatedField_Set_Input>;
  pk_columns: CustomFormReplicatedField_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_CustomFormReplicatedField_ManyArgs = {
  updates: Array<CustomFormReplicatedField_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_CustomFormReplicatedRowArgs = {
  _inc?: InputMaybe<CustomFormReplicatedRow_Inc_Input>;
  _set?: InputMaybe<CustomFormReplicatedRow_Set_Input>;
  where: CustomFormReplicatedRow_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_CustomFormReplicatedRow_By_PkArgs = {
  _inc?: InputMaybe<CustomFormReplicatedRow_Inc_Input>;
  _set?: InputMaybe<CustomFormReplicatedRow_Set_Input>;
  pk_columns: CustomFormReplicatedRow_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_CustomFormReplicatedRow_ManyArgs = {
  updates: Array<CustomFormReplicatedRow_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_CustomFormResponseArgs = {
  _append?: InputMaybe<CustomFormResponse_Append_Input>;
  _delete_at_path?: InputMaybe<CustomFormResponse_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<CustomFormResponse_Delete_Elem_Input>;
  _delete_key?: InputMaybe<CustomFormResponse_Delete_Key_Input>;
  _prepend?: InputMaybe<CustomFormResponse_Prepend_Input>;
  _set?: InputMaybe<CustomFormResponse_Set_Input>;
  where: CustomFormResponse_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_CustomFormResponse_By_PkArgs = {
  _append?: InputMaybe<CustomFormResponse_Append_Input>;
  _delete_at_path?: InputMaybe<CustomFormResponse_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<CustomFormResponse_Delete_Elem_Input>;
  _delete_key?: InputMaybe<CustomFormResponse_Delete_Key_Input>;
  _prepend?: InputMaybe<CustomFormResponse_Prepend_Input>;
  _set?: InputMaybe<CustomFormResponse_Set_Input>;
  pk_columns: CustomFormResponse_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_CustomFormResponse_ManyArgs = {
  updates: Array<CustomFormResponse_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_CustomFormRowArgs = {
  _inc?: InputMaybe<CustomFormRow_Inc_Input>;
  _set?: InputMaybe<CustomFormRow_Set_Input>;
  where: CustomFormRow_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_CustomFormRow_By_PkArgs = {
  _inc?: InputMaybe<CustomFormRow_Inc_Input>;
  _set?: InputMaybe<CustomFormRow_Set_Input>;
  pk_columns: CustomFormRow_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_CustomFormRow_ManyArgs = {
  updates: Array<CustomFormRow_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_CustomForm_By_PkArgs = {
  _append?: InputMaybe<CustomForm_Append_Input>;
  _delete_at_path?: InputMaybe<CustomForm_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<CustomForm_Delete_Elem_Input>;
  _delete_key?: InputMaybe<CustomForm_Delete_Key_Input>;
  _prepend?: InputMaybe<CustomForm_Prepend_Input>;
  _set?: InputMaybe<CustomForm_Set_Input>;
  pk_columns: CustomForm_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_CustomForm_ManyArgs = {
  updates: Array<CustomForm_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_CustomQuestionArgs = {
  _set?: InputMaybe<CustomQuestion_Set_Input>;
  where: CustomQuestion_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_CustomQuestionOptionArgs = {
  _set?: InputMaybe<CustomQuestionOption_Set_Input>;
  where: CustomQuestionOption_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_CustomQuestionOption_By_PkArgs = {
  _set?: InputMaybe<CustomQuestionOption_Set_Input>;
  pk_columns: CustomQuestionOption_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_CustomQuestionOption_ManyArgs = {
  updates: Array<CustomQuestionOption_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_CustomQuestion_By_PkArgs = {
  _set?: InputMaybe<CustomQuestion_Set_Input>;
  pk_columns: CustomQuestion_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_CustomQuestion_ManyArgs = {
  updates: Array<CustomQuestion_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_DegreeDesignationArgs = {
  _set?: InputMaybe<DegreeDesignation_Set_Input>;
  where: DegreeDesignation_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_DegreeDesignation_By_PkArgs = {
  _set?: InputMaybe<DegreeDesignation_Set_Input>;
  pk_columns: DegreeDesignation_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_DegreeDesignation_ManyArgs = {
  updates: Array<DegreeDesignation_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_DepositInvoiceLineItemArgs = {
  _inc?: InputMaybe<DepositInvoiceLineItem_Inc_Input>;
  _set?: InputMaybe<DepositInvoiceLineItem_Set_Input>;
  where: DepositInvoiceLineItem_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_DepositInvoiceLineItem_By_PkArgs = {
  _inc?: InputMaybe<DepositInvoiceLineItem_Inc_Input>;
  _set?: InputMaybe<DepositInvoiceLineItem_Set_Input>;
  pk_columns: DepositInvoiceLineItem_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_DepositInvoiceLineItem_ManyArgs = {
  updates: Array<DepositInvoiceLineItem_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_DeviceArgs = {
  _append?: InputMaybe<Device_Append_Input>;
  _delete_at_path?: InputMaybe<Device_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Device_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Device_Delete_Key_Input>;
  _prepend?: InputMaybe<Device_Prepend_Input>;
  _set?: InputMaybe<Device_Set_Input>;
  where: Device_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_DeviceTypeArgs = {
  _set?: InputMaybe<DeviceType_Set_Input>;
  where: DeviceType_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_DeviceType_By_PkArgs = {
  _set?: InputMaybe<DeviceType_Set_Input>;
  pk_columns: DeviceType_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_DeviceType_ManyArgs = {
  updates: Array<DeviceType_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Device_By_PkArgs = {
  _append?: InputMaybe<Device_Append_Input>;
  _delete_at_path?: InputMaybe<Device_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Device_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Device_Delete_Key_Input>;
  _prepend?: InputMaybe<Device_Prepend_Input>;
  _set?: InputMaybe<Device_Set_Input>;
  pk_columns: Device_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Device_ManyArgs = {
  updates: Array<Device_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_DiscountInvoiceLineItemArgs = {
  _inc?: InputMaybe<DiscountInvoiceLineItem_Inc_Input>;
  _set?: InputMaybe<DiscountInvoiceLineItem_Set_Input>;
  where: DiscountInvoiceLineItem_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_DiscountInvoiceLineItem_By_PkArgs = {
  _inc?: InputMaybe<DiscountInvoiceLineItem_Inc_Input>;
  _set?: InputMaybe<DiscountInvoiceLineItem_Set_Input>;
  pk_columns: DiscountInvoiceLineItem_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_DiscountInvoiceLineItem_ManyArgs = {
  updates: Array<DiscountInvoiceLineItem_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_DocumentArgs = {
  _set?: InputMaybe<Document_Set_Input>;
  where: Document_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_DocumentTagArgs = {
  _set?: InputMaybe<DocumentTag_Set_Input>;
  where: DocumentTag_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_DocumentTag_By_PkArgs = {
  _set?: InputMaybe<DocumentTag_Set_Input>;
  pk_columns: DocumentTag_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_DocumentTag_ManyArgs = {
  updates: Array<DocumentTag_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Document_By_PkArgs = {
  _set?: InputMaybe<Document_Set_Input>;
  pk_columns: Document_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Document_ManyArgs = {
  updates: Array<Document_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_EmailCampaignLogArgs = {
  _set?: InputMaybe<EmailCampaignLog_Set_Input>;
  where: EmailCampaignLog_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_EmailCampaignLog_By_PkArgs = {
  _set?: InputMaybe<EmailCampaignLog_Set_Input>;
  pk_columns: EmailCampaignLog_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_EmailCampaignLog_ManyArgs = {
  updates: Array<EmailCampaignLog_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_EmailTemplateArgs = {
  _append?: InputMaybe<EmailTemplate_Append_Input>;
  _delete_at_path?: InputMaybe<EmailTemplate_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<EmailTemplate_Delete_Elem_Input>;
  _delete_key?: InputMaybe<EmailTemplate_Delete_Key_Input>;
  _inc?: InputMaybe<EmailTemplate_Inc_Input>;
  _prepend?: InputMaybe<EmailTemplate_Prepend_Input>;
  _set?: InputMaybe<EmailTemplate_Set_Input>;
  where: EmailTemplate_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_EmailTemplatePatientArgs = {
  _set?: InputMaybe<EmailTemplatePatient_Set_Input>;
  where: EmailTemplatePatient_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_EmailTemplatePatient_By_PkArgs = {
  _set?: InputMaybe<EmailTemplatePatient_Set_Input>;
  pk_columns: EmailTemplatePatient_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_EmailTemplatePatient_ManyArgs = {
  updates: Array<EmailTemplatePatient_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_EmailTemplateSendQueueArgs = {
  _set?: InputMaybe<EmailTemplateSendQueue_Set_Input>;
  where: EmailTemplateSendQueue_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_EmailTemplateSendQueue_By_PkArgs = {
  _set?: InputMaybe<EmailTemplateSendQueue_Set_Input>;
  pk_columns: EmailTemplateSendQueue_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_EmailTemplateSendQueue_ManyArgs = {
  updates: Array<EmailTemplateSendQueue_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_EmailTemplate_By_PkArgs = {
  _append?: InputMaybe<EmailTemplate_Append_Input>;
  _delete_at_path?: InputMaybe<EmailTemplate_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<EmailTemplate_Delete_Elem_Input>;
  _delete_key?: InputMaybe<EmailTemplate_Delete_Key_Input>;
  _inc?: InputMaybe<EmailTemplate_Inc_Input>;
  _prepend?: InputMaybe<EmailTemplate_Prepend_Input>;
  _set?: InputMaybe<EmailTemplate_Set_Input>;
  pk_columns: EmailTemplate_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_EmailTemplate_ManyArgs = {
  updates: Array<EmailTemplate_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ExternalLocationArgs = {
  _set?: InputMaybe<ExternalLocation_Set_Input>;
  where: ExternalLocation_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_ExternalLocation_By_PkArgs = {
  _set?: InputMaybe<ExternalLocation_Set_Input>;
  pk_columns: ExternalLocation_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ExternalLocation_ManyArgs = {
  updates: Array<ExternalLocation_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ExternalProviderArgs = {
  _set?: InputMaybe<ExternalProvider_Set_Input>;
  where: ExternalProvider_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_ExternalProvider_By_PkArgs = {
  _set?: InputMaybe<ExternalProvider_Set_Input>;
  pk_columns: ExternalProvider_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ExternalProvider_ManyArgs = {
  updates: Array<ExternalProvider_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ExternalReviewArgs = {
  _inc?: InputMaybe<ExternalReview_Inc_Input>;
  _set?: InputMaybe<ExternalReview_Set_Input>;
  where: ExternalReview_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_ExternalReview_By_PkArgs = {
  _inc?: InputMaybe<ExternalReview_Inc_Input>;
  _set?: InputMaybe<ExternalReview_Set_Input>;
  pk_columns: ExternalReview_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ExternalReview_ManyArgs = {
  updates: Array<ExternalReview_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_FeeArgs = {
  _inc?: InputMaybe<Fee_Inc_Input>;
  _set?: InputMaybe<Fee_Set_Input>;
  where: Fee_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_FeeInvoiceLineItemArgs = {
  _inc?: InputMaybe<FeeInvoiceLineItem_Inc_Input>;
  _set?: InputMaybe<FeeInvoiceLineItem_Set_Input>;
  where: FeeInvoiceLineItem_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_FeeInvoiceLineItem_By_PkArgs = {
  _inc?: InputMaybe<FeeInvoiceLineItem_Inc_Input>;
  _set?: InputMaybe<FeeInvoiceLineItem_Set_Input>;
  pk_columns: FeeInvoiceLineItem_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_FeeInvoiceLineItem_ManyArgs = {
  updates: Array<FeeInvoiceLineItem_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Fee_By_PkArgs = {
  _inc?: InputMaybe<Fee_Inc_Input>;
  _set?: InputMaybe<Fee_Set_Input>;
  pk_columns: Fee_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Fee_ManyArgs = {
  updates: Array<Fee_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_FinixTerminalArgs = {
  _set?: InputMaybe<FinixTerminal_Set_Input>;
  where: FinixTerminal_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_FinixTerminal_By_PkArgs = {
  _set?: InputMaybe<FinixTerminal_Set_Input>;
  pk_columns: FinixTerminal_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_FinixTerminal_ManyArgs = {
  updates: Array<FinixTerminal_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_FlowsheetArgs = {
  _set?: InputMaybe<Flowsheet_Set_Input>;
  where: Flowsheet_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_FlowsheetParameterArgs = {
  _set?: InputMaybe<FlowsheetParameter_Set_Input>;
  where: FlowsheetParameter_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_FlowsheetParameterFlowsheetArgs = {
  _set?: InputMaybe<FlowsheetParameterFlowsheet_Set_Input>;
  where: FlowsheetParameterFlowsheet_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_FlowsheetParameterFlowsheet_By_PkArgs = {
  _set?: InputMaybe<FlowsheetParameterFlowsheet_Set_Input>;
  pk_columns: FlowsheetParameterFlowsheet_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_FlowsheetParameterFlowsheet_ManyArgs = {
  updates: Array<FlowsheetParameterFlowsheet_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_FlowsheetParameterGroupArgs = {
  _set?: InputMaybe<FlowsheetParameterGroup_Set_Input>;
  where: FlowsheetParameterGroup_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_FlowsheetParameterGroup_By_PkArgs = {
  _set?: InputMaybe<FlowsheetParameterGroup_Set_Input>;
  pk_columns: FlowsheetParameterGroup_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_FlowsheetParameterGroup_ManyArgs = {
  updates: Array<FlowsheetParameterGroup_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_FlowsheetParameter_By_PkArgs = {
  _set?: InputMaybe<FlowsheetParameter_Set_Input>;
  pk_columns: FlowsheetParameter_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_FlowsheetParameter_ManyArgs = {
  updates: Array<FlowsheetParameter_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Flowsheet_By_PkArgs = {
  _set?: InputMaybe<Flowsheet_Set_Input>;
  pk_columns: Flowsheet_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Flowsheet_ManyArgs = {
  updates: Array<Flowsheet_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_GenericInvoiceLineItemArgs = {
  _inc?: InputMaybe<GenericInvoiceLineItem_Inc_Input>;
  _set?: InputMaybe<GenericInvoiceLineItem_Set_Input>;
  where: GenericInvoiceLineItem_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_GenericInvoiceLineItemDiscountArgs = {
  _inc?: InputMaybe<GenericInvoiceLineItemDiscount_Inc_Input>;
  _set?: InputMaybe<GenericInvoiceLineItemDiscount_Set_Input>;
  where: GenericInvoiceLineItemDiscount_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_GenericInvoiceLineItemDiscount_By_PkArgs = {
  _inc?: InputMaybe<GenericInvoiceLineItemDiscount_Inc_Input>;
  _set?: InputMaybe<GenericInvoiceLineItemDiscount_Set_Input>;
  pk_columns: GenericInvoiceLineItemDiscount_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_GenericInvoiceLineItemDiscount_ManyArgs = {
  updates: Array<GenericInvoiceLineItemDiscount_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_GenericInvoiceLineItem_By_PkArgs = {
  _inc?: InputMaybe<GenericInvoiceLineItem_Inc_Input>;
  _set?: InputMaybe<GenericInvoiceLineItem_Set_Input>;
  pk_columns: GenericInvoiceLineItem_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_GenericInvoiceLineItem_ManyArgs = {
  updates: Array<GenericInvoiceLineItem_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_GiftCardArgs = {
  _inc?: InputMaybe<GiftCard_Inc_Input>;
  _set?: InputMaybe<GiftCard_Set_Input>;
  where: GiftCard_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_GiftCard_By_PkArgs = {
  _inc?: InputMaybe<GiftCard_Inc_Input>;
  _set?: InputMaybe<GiftCard_Set_Input>;
  pk_columns: GiftCard_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_GiftCard_ManyArgs = {
  updates: Array<GiftCard_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_GoogleAccountArgs = {
  _set?: InputMaybe<GoogleAccount_Set_Input>;
  where: GoogleAccount_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_GoogleAccount_By_PkArgs = {
  _set?: InputMaybe<GoogleAccount_Set_Input>;
  pk_columns: GoogleAccount_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_GoogleAccount_ManyArgs = {
  updates: Array<GoogleAccount_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_GoogleLocationArgs = {
  _set?: InputMaybe<GoogleLocation_Set_Input>;
  where: GoogleLocation_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_GoogleLocation_By_PkArgs = {
  _set?: InputMaybe<GoogleLocation_Set_Input>;
  pk_columns: GoogleLocation_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_GoogleLocation_ManyArgs = {
  updates: Array<GoogleLocation_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_GoogleReviewArgs = {
  _set?: InputMaybe<GoogleReview_Set_Input>;
  where: GoogleReview_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_GoogleReview_By_PkArgs = {
  _set?: InputMaybe<GoogleReview_Set_Input>;
  pk_columns: GoogleReview_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_GoogleReview_ManyArgs = {
  updates: Array<GoogleReview_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ImportJobArgs = {
  _append?: InputMaybe<ImportJob_Append_Input>;
  _delete_at_path?: InputMaybe<ImportJob_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<ImportJob_Delete_Elem_Input>;
  _delete_key?: InputMaybe<ImportJob_Delete_Key_Input>;
  _prepend?: InputMaybe<ImportJob_Prepend_Input>;
  _set?: InputMaybe<ImportJob_Set_Input>;
  where: ImportJob_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_ImportJob_By_PkArgs = {
  _append?: InputMaybe<ImportJob_Append_Input>;
  _delete_at_path?: InputMaybe<ImportJob_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<ImportJob_Delete_Elem_Input>;
  _delete_key?: InputMaybe<ImportJob_Delete_Key_Input>;
  _prepend?: InputMaybe<ImportJob_Prepend_Input>;
  _set?: InputMaybe<ImportJob_Set_Input>;
  pk_columns: ImportJob_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ImportJob_ManyArgs = {
  updates: Array<ImportJob_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ImportMetadataArgs = {
  _append?: InputMaybe<ImportMetadata_Append_Input>;
  _delete_at_path?: InputMaybe<ImportMetadata_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<ImportMetadata_Delete_Elem_Input>;
  _delete_key?: InputMaybe<ImportMetadata_Delete_Key_Input>;
  _prepend?: InputMaybe<ImportMetadata_Prepend_Input>;
  _set?: InputMaybe<ImportMetadata_Set_Input>;
  where: ImportMetadata_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_ImportMetadata_By_PkArgs = {
  _append?: InputMaybe<ImportMetadata_Append_Input>;
  _delete_at_path?: InputMaybe<ImportMetadata_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<ImportMetadata_Delete_Elem_Input>;
  _delete_key?: InputMaybe<ImportMetadata_Delete_Key_Input>;
  _prepend?: InputMaybe<ImportMetadata_Prepend_Input>;
  _set?: InputMaybe<ImportMetadata_Set_Input>;
  pk_columns: ImportMetadata_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ImportMetadata_ManyArgs = {
  updates: Array<ImportMetadata_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_IncompatibleServicesArgs = {
  _set?: InputMaybe<IncompatibleServices_Set_Input>;
  where: IncompatibleServices_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_IncompatibleServices_By_PkArgs = {
  _set?: InputMaybe<IncompatibleServices_Set_Input>;
  pk_columns: IncompatibleServices_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_IncompatibleServices_ManyArgs = {
  updates: Array<IncompatibleServices_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_InternalAppointmentProviderArgs = {
  _set?: InputMaybe<InternalAppointmentProvider_Set_Input>;
  where: InternalAppointmentProvider_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_InternalAppointmentProvider_By_PkArgs = {
  _set?: InputMaybe<InternalAppointmentProvider_Set_Input>;
  pk_columns: InternalAppointmentProvider_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_InternalAppointmentProvider_ManyArgs = {
  updates: Array<InternalAppointmentProvider_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_InventoryTransactionArgs = {
  _inc?: InputMaybe<InventoryTransaction_Inc_Input>;
  _set?: InputMaybe<InventoryTransaction_Set_Input>;
  where: InventoryTransaction_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_InventoryTransaction_By_PkArgs = {
  _inc?: InputMaybe<InventoryTransaction_Inc_Input>;
  _set?: InputMaybe<InventoryTransaction_Set_Input>;
  pk_columns: InventoryTransaction_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_InventoryTransaction_ManyArgs = {
  updates: Array<InventoryTransaction_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_InvoiceArgs = {
  _inc?: InputMaybe<Invoice_Inc_Input>;
  _set?: InputMaybe<Invoice_Set_Input>;
  where: Invoice_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_InvoiceConfigurationArgs = {
  _append?: InputMaybe<InvoiceConfiguration_Append_Input>;
  _delete_at_path?: InputMaybe<InvoiceConfiguration_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<InvoiceConfiguration_Delete_Elem_Input>;
  _delete_key?: InputMaybe<InvoiceConfiguration_Delete_Key_Input>;
  _prepend?: InputMaybe<InvoiceConfiguration_Prepend_Input>;
  _set?: InputMaybe<InvoiceConfiguration_Set_Input>;
  where: InvoiceConfiguration_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_InvoiceConfiguration_By_PkArgs = {
  _append?: InputMaybe<InvoiceConfiguration_Append_Input>;
  _delete_at_path?: InputMaybe<InvoiceConfiguration_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<InvoiceConfiguration_Delete_Elem_Input>;
  _delete_key?: InputMaybe<InvoiceConfiguration_Delete_Key_Input>;
  _prepend?: InputMaybe<InvoiceConfiguration_Prepend_Input>;
  _set?: InputMaybe<InvoiceConfiguration_Set_Input>;
  pk_columns: InvoiceConfiguration_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_InvoiceConfiguration_ManyArgs = {
  updates: Array<InvoiceConfiguration_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_InvoiceCouponArgs = {
  _inc?: InputMaybe<InvoiceCoupon_Inc_Input>;
  _set?: InputMaybe<InvoiceCoupon_Set_Input>;
  where: InvoiceCoupon_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_InvoiceCoupon_By_PkArgs = {
  _inc?: InputMaybe<InvoiceCoupon_Inc_Input>;
  _set?: InputMaybe<InvoiceCoupon_Set_Input>;
  pk_columns: InvoiceCoupon_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_InvoiceCoupon_ManyArgs = {
  updates: Array<InvoiceCoupon_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_InvoiceMembershipArgs = {
  _inc?: InputMaybe<InvoiceMembership_Inc_Input>;
  _set?: InputMaybe<InvoiceMembership_Set_Input>;
  where: InvoiceMembership_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_InvoiceMembership_By_PkArgs = {
  _inc?: InputMaybe<InvoiceMembership_Inc_Input>;
  _set?: InputMaybe<InvoiceMembership_Set_Input>;
  pk_columns: InvoiceMembership_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_InvoiceMembership_ManyArgs = {
  updates: Array<InvoiceMembership_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_InvoicePackageArgs = {
  _inc?: InputMaybe<InvoicePackage_Inc_Input>;
  _set?: InputMaybe<InvoicePackage_Set_Input>;
  where: InvoicePackage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_InvoicePackage_By_PkArgs = {
  _inc?: InputMaybe<InvoicePackage_Inc_Input>;
  _set?: InputMaybe<InvoicePackage_Set_Input>;
  pk_columns: InvoicePackage_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_InvoicePackage_ManyArgs = {
  updates: Array<InvoicePackage_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_InvoiceProductItemArgs = {
  _inc?: InputMaybe<InvoiceProductItem_Inc_Input>;
  _set?: InputMaybe<InvoiceProductItem_Set_Input>;
  where: InvoiceProductItem_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_InvoiceProductItem_By_PkArgs = {
  _inc?: InputMaybe<InvoiceProductItem_Inc_Input>;
  _set?: InputMaybe<InvoiceProductItem_Set_Input>;
  pk_columns: InvoiceProductItem_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_InvoiceProductItem_ManyArgs = {
  updates: Array<InvoiceProductItem_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_InvoiceServiceConsumableArgs = {
  _inc?: InputMaybe<InvoiceServiceConsumable_Inc_Input>;
  _set?: InputMaybe<InvoiceServiceConsumable_Set_Input>;
  where: InvoiceServiceConsumable_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_InvoiceServiceConsumable_By_PkArgs = {
  _inc?: InputMaybe<InvoiceServiceConsumable_Inc_Input>;
  _set?: InputMaybe<InvoiceServiceConsumable_Set_Input>;
  pk_columns: InvoiceServiceConsumable_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_InvoiceServiceConsumable_ManyArgs = {
  updates: Array<InvoiceServiceConsumable_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_InvoiceServiceItemArgs = {
  _inc?: InputMaybe<InvoiceServiceItem_Inc_Input>;
  _set?: InputMaybe<InvoiceServiceItem_Set_Input>;
  where: InvoiceServiceItem_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_InvoiceServiceItem_By_PkArgs = {
  _inc?: InputMaybe<InvoiceServiceItem_Inc_Input>;
  _set?: InputMaybe<InvoiceServiceItem_Set_Input>;
  pk_columns: InvoiceServiceItem_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_InvoiceServiceItem_ManyArgs = {
  updates: Array<InvoiceServiceItem_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_InvoiceTemplateArgs = {
  _append?: InputMaybe<InvoiceTemplate_Append_Input>;
  _delete_at_path?: InputMaybe<InvoiceTemplate_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<InvoiceTemplate_Delete_Elem_Input>;
  _delete_key?: InputMaybe<InvoiceTemplate_Delete_Key_Input>;
  _prepend?: InputMaybe<InvoiceTemplate_Prepend_Input>;
  _set?: InputMaybe<InvoiceTemplate_Set_Input>;
  where: InvoiceTemplate_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_InvoiceTemplate_By_PkArgs = {
  _append?: InputMaybe<InvoiceTemplate_Append_Input>;
  _delete_at_path?: InputMaybe<InvoiceTemplate_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<InvoiceTemplate_Delete_Elem_Input>;
  _delete_key?: InputMaybe<InvoiceTemplate_Delete_Key_Input>;
  _prepend?: InputMaybe<InvoiceTemplate_Prepend_Input>;
  _set?: InputMaybe<InvoiceTemplate_Set_Input>;
  pk_columns: InvoiceTemplate_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_InvoiceTemplate_ManyArgs = {
  updates: Array<InvoiceTemplate_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Invoice_By_PkArgs = {
  _inc?: InputMaybe<Invoice_Inc_Input>;
  _set?: InputMaybe<Invoice_Set_Input>;
  pk_columns: Invoice_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Invoice_ManyArgs = {
  updates: Array<Invoice_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_JobArgs = {
  _inc?: InputMaybe<Job_Inc_Input>;
  _set?: InputMaybe<Job_Set_Input>;
  where: Job_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_JobStatusArgs = {
  _set?: InputMaybe<JobStatus_Set_Input>;
  where: JobStatus_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_JobStatus_By_PkArgs = {
  _set?: InputMaybe<JobStatus_Set_Input>;
  pk_columns: JobStatus_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_JobStatus_ManyArgs = {
  updates: Array<JobStatus_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Job_By_PkArgs = {
  _inc?: InputMaybe<Job_Inc_Input>;
  _set?: InputMaybe<Job_Set_Input>;
  pk_columns: Job_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Job_ManyArgs = {
  updates: Array<Job_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_LabResultArgs = {
  _append?: InputMaybe<LabResult_Append_Input>;
  _delete_at_path?: InputMaybe<LabResult_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<LabResult_Delete_Elem_Input>;
  _delete_key?: InputMaybe<LabResult_Delete_Key_Input>;
  _prepend?: InputMaybe<LabResult_Prepend_Input>;
  _set?: InputMaybe<LabResult_Set_Input>;
  where: LabResult_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_LabResult_By_PkArgs = {
  _append?: InputMaybe<LabResult_Append_Input>;
  _delete_at_path?: InputMaybe<LabResult_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<LabResult_Delete_Elem_Input>;
  _delete_key?: InputMaybe<LabResult_Delete_Key_Input>;
  _prepend?: InputMaybe<LabResult_Prepend_Input>;
  _set?: InputMaybe<LabResult_Set_Input>;
  pk_columns: LabResult_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_LabResult_ManyArgs = {
  updates: Array<LabResult_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_LeadFormArgs = {
  _append?: InputMaybe<LeadForm_Append_Input>;
  _delete_at_path?: InputMaybe<LeadForm_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<LeadForm_Delete_Elem_Input>;
  _delete_key?: InputMaybe<LeadForm_Delete_Key_Input>;
  _prepend?: InputMaybe<LeadForm_Prepend_Input>;
  _set?: InputMaybe<LeadForm_Set_Input>;
  where: LeadForm_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_LeadFormEntryArgs = {
  _append?: InputMaybe<LeadFormEntry_Append_Input>;
  _delete_at_path?: InputMaybe<LeadFormEntry_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<LeadFormEntry_Delete_Elem_Input>;
  _delete_key?: InputMaybe<LeadFormEntry_Delete_Key_Input>;
  _prepend?: InputMaybe<LeadFormEntry_Prepend_Input>;
  _set?: InputMaybe<LeadFormEntry_Set_Input>;
  where: LeadFormEntry_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_LeadFormEntryTagArgs = {
  _set?: InputMaybe<LeadFormEntryTag_Set_Input>;
  where: LeadFormEntryTag_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_LeadFormEntryTag_By_PkArgs = {
  _set?: InputMaybe<LeadFormEntryTag_Set_Input>;
  pk_columns: LeadFormEntryTag_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_LeadFormEntryTag_ManyArgs = {
  updates: Array<LeadFormEntryTag_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_LeadFormEntry_By_PkArgs = {
  _append?: InputMaybe<LeadFormEntry_Append_Input>;
  _delete_at_path?: InputMaybe<LeadFormEntry_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<LeadFormEntry_Delete_Elem_Input>;
  _delete_key?: InputMaybe<LeadFormEntry_Delete_Key_Input>;
  _prepend?: InputMaybe<LeadFormEntry_Prepend_Input>;
  _set?: InputMaybe<LeadFormEntry_Set_Input>;
  pk_columns: LeadFormEntry_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_LeadFormEntry_ManyArgs = {
  updates: Array<LeadFormEntry_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_LeadFormFieldArgs = {
  _append?: InputMaybe<LeadFormField_Append_Input>;
  _delete_at_path?: InputMaybe<LeadFormField_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<LeadFormField_Delete_Elem_Input>;
  _delete_key?: InputMaybe<LeadFormField_Delete_Key_Input>;
  _inc?: InputMaybe<LeadFormField_Inc_Input>;
  _prepend?: InputMaybe<LeadFormField_Prepend_Input>;
  _set?: InputMaybe<LeadFormField_Set_Input>;
  where: LeadFormField_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_LeadFormFieldValueArgs = {
  _set?: InputMaybe<LeadFormFieldValue_Set_Input>;
  where: LeadFormFieldValue_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_LeadFormFieldValue_By_PkArgs = {
  _set?: InputMaybe<LeadFormFieldValue_Set_Input>;
  pk_columns: LeadFormFieldValue_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_LeadFormFieldValue_ManyArgs = {
  updates: Array<LeadFormFieldValue_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_LeadFormField_By_PkArgs = {
  _append?: InputMaybe<LeadFormField_Append_Input>;
  _delete_at_path?: InputMaybe<LeadFormField_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<LeadFormField_Delete_Elem_Input>;
  _delete_key?: InputMaybe<LeadFormField_Delete_Key_Input>;
  _inc?: InputMaybe<LeadFormField_Inc_Input>;
  _prepend?: InputMaybe<LeadFormField_Prepend_Input>;
  _set?: InputMaybe<LeadFormField_Set_Input>;
  pk_columns: LeadFormField_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_LeadFormField_ManyArgs = {
  updates: Array<LeadFormField_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_LeadFormLinkArgs = {
  _append?: InputMaybe<LeadFormLink_Append_Input>;
  _delete_at_path?: InputMaybe<LeadFormLink_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<LeadFormLink_Delete_Elem_Input>;
  _delete_key?: InputMaybe<LeadFormLink_Delete_Key_Input>;
  _prepend?: InputMaybe<LeadFormLink_Prepend_Input>;
  _set?: InputMaybe<LeadFormLink_Set_Input>;
  where: LeadFormLink_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_LeadFormLink_By_PkArgs = {
  _append?: InputMaybe<LeadFormLink_Append_Input>;
  _delete_at_path?: InputMaybe<LeadFormLink_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<LeadFormLink_Delete_Elem_Input>;
  _delete_key?: InputMaybe<LeadFormLink_Delete_Key_Input>;
  _prepend?: InputMaybe<LeadFormLink_Prepend_Input>;
  _set?: InputMaybe<LeadFormLink_Set_Input>;
  pk_columns: LeadFormLink_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_LeadFormLink_ManyArgs = {
  updates: Array<LeadFormLink_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_LeadFormStepArgs = {
  _append?: InputMaybe<LeadFormStep_Append_Input>;
  _delete_at_path?: InputMaybe<LeadFormStep_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<LeadFormStep_Delete_Elem_Input>;
  _delete_key?: InputMaybe<LeadFormStep_Delete_Key_Input>;
  _inc?: InputMaybe<LeadFormStep_Inc_Input>;
  _prepend?: InputMaybe<LeadFormStep_Prepend_Input>;
  _set?: InputMaybe<LeadFormStep_Set_Input>;
  where: LeadFormStep_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_LeadFormStep_By_PkArgs = {
  _append?: InputMaybe<LeadFormStep_Append_Input>;
  _delete_at_path?: InputMaybe<LeadFormStep_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<LeadFormStep_Delete_Elem_Input>;
  _delete_key?: InputMaybe<LeadFormStep_Delete_Key_Input>;
  _inc?: InputMaybe<LeadFormStep_Inc_Input>;
  _prepend?: InputMaybe<LeadFormStep_Prepend_Input>;
  _set?: InputMaybe<LeadFormStep_Set_Input>;
  pk_columns: LeadFormStep_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_LeadFormStep_ManyArgs = {
  updates: Array<LeadFormStep_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_LeadForm_By_PkArgs = {
  _append?: InputMaybe<LeadForm_Append_Input>;
  _delete_at_path?: InputMaybe<LeadForm_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<LeadForm_Delete_Elem_Input>;
  _delete_key?: InputMaybe<LeadForm_Delete_Key_Input>;
  _prepend?: InputMaybe<LeadForm_Prepend_Input>;
  _set?: InputMaybe<LeadForm_Set_Input>;
  pk_columns: LeadForm_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_LeadForm_ManyArgs = {
  updates: Array<LeadForm_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_LeadTagArgs = {
  _set?: InputMaybe<LeadTag_Set_Input>;
  where: LeadTag_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_LeadTag_By_PkArgs = {
  _set?: InputMaybe<LeadTag_Set_Input>;
  pk_columns: LeadTag_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_LeadTag_ManyArgs = {
  updates: Array<LeadTag_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_LeadTrackedEventArgs = {
  _set?: InputMaybe<LeadTrackedEvent_Set_Input>;
  where: LeadTrackedEvent_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_LeadTrackedEvent_By_PkArgs = {
  _set?: InputMaybe<LeadTrackedEvent_Set_Input>;
  pk_columns: LeadTrackedEvent_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_LeadTrackedEvent_ManyArgs = {
  updates: Array<LeadTrackedEvent_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_LocationArgs = {
  _inc?: InputMaybe<Location_Inc_Input>;
  _set?: InputMaybe<Location_Set_Input>;
  where: Location_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_LocationCouponArgs = {
  _set?: InputMaybe<LocationCoupon_Set_Input>;
  where: LocationCoupon_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_LocationCoupon_By_PkArgs = {
  _set?: InputMaybe<LocationCoupon_Set_Input>;
  pk_columns: LocationCoupon_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_LocationCoupon_ManyArgs = {
  updates: Array<LocationCoupon_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_LocationProviderArgs = {
  _inc?: InputMaybe<LocationProvider_Inc_Input>;
  _set?: InputMaybe<LocationProvider_Set_Input>;
  where: LocationProvider_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_LocationProvider_By_PkArgs = {
  _inc?: InputMaybe<LocationProvider_Inc_Input>;
  _set?: InputMaybe<LocationProvider_Set_Input>;
  pk_columns: LocationProvider_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_LocationProvider_ManyArgs = {
  updates: Array<LocationProvider_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_LocationScheduleArgs = {
  _append?: InputMaybe<LocationSchedule_Append_Input>;
  _delete_at_path?: InputMaybe<LocationSchedule_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<LocationSchedule_Delete_Elem_Input>;
  _delete_key?: InputMaybe<LocationSchedule_Delete_Key_Input>;
  _prepend?: InputMaybe<LocationSchedule_Prepend_Input>;
  _set?: InputMaybe<LocationSchedule_Set_Input>;
  where: LocationSchedule_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_LocationSchedule_By_PkArgs = {
  _append?: InputMaybe<LocationSchedule_Append_Input>;
  _delete_at_path?: InputMaybe<LocationSchedule_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<LocationSchedule_Delete_Elem_Input>;
  _delete_key?: InputMaybe<LocationSchedule_Delete_Key_Input>;
  _prepend?: InputMaybe<LocationSchedule_Prepend_Input>;
  _set?: InputMaybe<LocationSchedule_Set_Input>;
  pk_columns: LocationSchedule_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_LocationSchedule_ManyArgs = {
  updates: Array<LocationSchedule_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_LocationServiceArgs = {
  _inc?: InputMaybe<LocationService_Inc_Input>;
  _set?: InputMaybe<LocationService_Set_Input>;
  where: LocationService_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_LocationService_By_PkArgs = {
  _inc?: InputMaybe<LocationService_Inc_Input>;
  _set?: InputMaybe<LocationService_Set_Input>;
  pk_columns: LocationService_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_LocationService_ManyArgs = {
  updates: Array<LocationService_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Location_By_PkArgs = {
  _inc?: InputMaybe<Location_Inc_Input>;
  _set?: InputMaybe<Location_Set_Input>;
  pk_columns: Location_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Location_ManyArgs = {
  updates: Array<Location_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_MarketingTagArgs = {
  _set?: InputMaybe<MarketingTag_Set_Input>;
  where: MarketingTag_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_MarketingTagEmailArgs = {
  _set?: InputMaybe<MarketingTagEmail_Set_Input>;
  where: MarketingTagEmail_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_MarketingTagEmail_By_PkArgs = {
  _set?: InputMaybe<MarketingTagEmail_Set_Input>;
  pk_columns: MarketingTagEmail_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_MarketingTagEmail_ManyArgs = {
  updates: Array<MarketingTagEmail_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_MarketingTagSmsArgs = {
  _set?: InputMaybe<MarketingTagSms_Set_Input>;
  where: MarketingTagSms_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_MarketingTagSms_By_PkArgs = {
  _set?: InputMaybe<MarketingTagSms_Set_Input>;
  pk_columns: MarketingTagSms_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_MarketingTagSms_ManyArgs = {
  updates: Array<MarketingTagSms_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_MarketingTag_By_PkArgs = {
  _set?: InputMaybe<MarketingTag_Set_Input>;
  pk_columns: MarketingTag_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_MarketingTag_ManyArgs = {
  updates: Array<MarketingTag_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_MediaTagArgs = {
  _set?: InputMaybe<MediaTag_Set_Input>;
  where: MediaTag_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_MediaTag_By_PkArgs = {
  _set?: InputMaybe<MediaTag_Set_Input>;
  pk_columns: MediaTag_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_MediaTag_ManyArgs = {
  updates: Array<MediaTag_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_MembershipArgs = {
  _inc?: InputMaybe<Membership_Inc_Input>;
  _set?: InputMaybe<Membership_Set_Input>;
  where: Membership_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_MembershipCouponArgs = {
  _inc?: InputMaybe<MembershipCoupon_Inc_Input>;
  _set?: InputMaybe<MembershipCoupon_Set_Input>;
  where: MembershipCoupon_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_MembershipCoupon_By_PkArgs = {
  _inc?: InputMaybe<MembershipCoupon_Inc_Input>;
  _set?: InputMaybe<MembershipCoupon_Set_Input>;
  pk_columns: MembershipCoupon_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_MembershipCoupon_ManyArgs = {
  updates: Array<MembershipCoupon_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_MembershipTypeArgs = {
  _set?: InputMaybe<MembershipType_Set_Input>;
  where: MembershipType_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_MembershipType_By_PkArgs = {
  _set?: InputMaybe<MembershipType_Set_Input>;
  pk_columns: MembershipType_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_MembershipType_ManyArgs = {
  updates: Array<MembershipType_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Membership_By_PkArgs = {
  _inc?: InputMaybe<Membership_Inc_Input>;
  _set?: InputMaybe<Membership_Set_Input>;
  pk_columns: Membership_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Membership_ManyArgs = {
  updates: Array<Membership_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_NoteArgs = {
  _append?: InputMaybe<Note_Append_Input>;
  _delete_at_path?: InputMaybe<Note_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Note_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Note_Delete_Key_Input>;
  _prepend?: InputMaybe<Note_Prepend_Input>;
  _set?: InputMaybe<Note_Set_Input>;
  where: Note_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_NoteSignatureArgs = {
  _set?: InputMaybe<NoteSignature_Set_Input>;
  where: NoteSignature_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_NoteSignature_By_PkArgs = {
  _set?: InputMaybe<NoteSignature_Set_Input>;
  pk_columns: NoteSignature_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_NoteSignature_ManyArgs = {
  updates: Array<NoteSignature_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Note_By_PkArgs = {
  _append?: InputMaybe<Note_Append_Input>;
  _delete_at_path?: InputMaybe<Note_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Note_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Note_Delete_Key_Input>;
  _prepend?: InputMaybe<Note_Prepend_Input>;
  _set?: InputMaybe<Note_Set_Input>;
  pk_columns: Note_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Note_ManyArgs = {
  updates: Array<Note_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_OrganizationArgs = {
  _set?: InputMaybe<Organization_Set_Input>;
  where: Organization_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_OrganizationConfigurationArgs = {
  _set?: InputMaybe<OrganizationConfiguration_Set_Input>;
  where: OrganizationConfiguration_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_OrganizationConfiguration_By_PkArgs = {
  _set?: InputMaybe<OrganizationConfiguration_Set_Input>;
  pk_columns: OrganizationConfiguration_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_OrganizationConfiguration_ManyArgs = {
  updates: Array<OrganizationConfiguration_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Organization_By_PkArgs = {
  _set?: InputMaybe<Organization_Set_Input>;
  pk_columns: Organization_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Organization_ManyArgs = {
  updates: Array<Organization_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_PackageArgs = {
  _inc?: InputMaybe<Package_Inc_Input>;
  _set?: InputMaybe<Package_Set_Input>;
  where: Package_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Package_By_PkArgs = {
  _inc?: InputMaybe<Package_Inc_Input>;
  _set?: InputMaybe<Package_Set_Input>;
  pk_columns: Package_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Package_ManyArgs = {
  updates: Array<Package_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_PatientArgs = {
  _inc?: InputMaybe<Patient_Inc_Input>;
  _set?: InputMaybe<Patient_Set_Input>;
  where: Patient_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_PatientAddressArgs = {
  _set?: InputMaybe<PatientAddress_Set_Input>;
  where: PatientAddress_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_PatientAddress_By_PkArgs = {
  _set?: InputMaybe<PatientAddress_Set_Input>;
  pk_columns: PatientAddress_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_PatientAddress_ManyArgs = {
  updates: Array<PatientAddress_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_PatientConsentArgs = {
  _append?: InputMaybe<PatientConsent_Append_Input>;
  _delete_at_path?: InputMaybe<PatientConsent_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<PatientConsent_Delete_Elem_Input>;
  _delete_key?: InputMaybe<PatientConsent_Delete_Key_Input>;
  _prepend?: InputMaybe<PatientConsent_Prepend_Input>;
  _set?: InputMaybe<PatientConsent_Set_Input>;
  where: PatientConsent_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_PatientConsent_By_PkArgs = {
  _append?: InputMaybe<PatientConsent_Append_Input>;
  _delete_at_path?: InputMaybe<PatientConsent_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<PatientConsent_Delete_Elem_Input>;
  _delete_key?: InputMaybe<PatientConsent_Delete_Key_Input>;
  _prepend?: InputMaybe<PatientConsent_Prepend_Input>;
  _set?: InputMaybe<PatientConsent_Set_Input>;
  pk_columns: PatientConsent_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_PatientConsent_ManyArgs = {
  updates: Array<PatientConsent_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_PatientConversationArgs = {
  _set?: InputMaybe<PatientConversation_Set_Input>;
  where: PatientConversation_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_PatientConversation_By_PkArgs = {
  _set?: InputMaybe<PatientConversation_Set_Input>;
  pk_columns: PatientConversation_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_PatientConversation_ManyArgs = {
  updates: Array<PatientConversation_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_PatientFlowsheetDataArgs = {
  _set?: InputMaybe<PatientFlowsheetData_Set_Input>;
  where: PatientFlowsheetData_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_PatientFlowsheetData_By_PkArgs = {
  _set?: InputMaybe<PatientFlowsheetData_Set_Input>;
  pk_columns: PatientFlowsheetData_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_PatientFlowsheetData_ManyArgs = {
  updates: Array<PatientFlowsheetData_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_PatientFlowsheetRowArgs = {
  _set?: InputMaybe<PatientFlowsheetRow_Set_Input>;
  where: PatientFlowsheetRow_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_PatientFlowsheetRow_By_PkArgs = {
  _set?: InputMaybe<PatientFlowsheetRow_Set_Input>;
  pk_columns: PatientFlowsheetRow_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_PatientFlowsheetRow_ManyArgs = {
  updates: Array<PatientFlowsheetRow_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_PatientInviteArgs = {
  _set?: InputMaybe<PatientInvite_Set_Input>;
  where: PatientInvite_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_PatientInvite_By_PkArgs = {
  _set?: InputMaybe<PatientInvite_Set_Input>;
  pk_columns: PatientInvite_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_PatientInvite_ManyArgs = {
  updates: Array<PatientInvite_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_PatientMembershipArgs = {
  _set?: InputMaybe<PatientMembership_Set_Input>;
  where: PatientMembership_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_PatientMembership_By_PkArgs = {
  _set?: InputMaybe<PatientMembership_Set_Input>;
  pk_columns: PatientMembership_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_PatientMembership_ManyArgs = {
  updates: Array<PatientMembership_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_PatientSystemNoticeArgs = {
  _set?: InputMaybe<PatientSystemNotice_Set_Input>;
  where: PatientSystemNotice_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_PatientSystemNotice_By_PkArgs = {
  _set?: InputMaybe<PatientSystemNotice_Set_Input>;
  pk_columns: PatientSystemNotice_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_PatientSystemNotice_ManyArgs = {
  updates: Array<PatientSystemNotice_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_PatientTagArgs = {
  _set?: InputMaybe<PatientTag_Set_Input>;
  where: PatientTag_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_PatientTag_By_PkArgs = {
  _set?: InputMaybe<PatientTag_Set_Input>;
  pk_columns: PatientTag_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_PatientTag_ManyArgs = {
  updates: Array<PatientTag_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_PatientTagsArgs = {
  _set?: InputMaybe<PatientTags_Set_Input>;
  where: PatientTags_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_PatientTags_By_PkArgs = {
  _set?: InputMaybe<PatientTags_Set_Input>;
  pk_columns: PatientTags_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_PatientTags_ManyArgs = {
  updates: Array<PatientTags_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_PatientWalletArgs = {
  _inc?: InputMaybe<PatientWallet_Inc_Input>;
  _set?: InputMaybe<PatientWallet_Set_Input>;
  where: PatientWallet_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_PatientWalletCouponArgs = {
  _set?: InputMaybe<PatientWalletCoupon_Set_Input>;
  where: PatientWalletCoupon_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_PatientWalletCoupon_By_PkArgs = {
  _set?: InputMaybe<PatientWalletCoupon_Set_Input>;
  pk_columns: PatientWalletCoupon_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_PatientWalletCoupon_ManyArgs = {
  updates: Array<PatientWalletCoupon_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_PatientWalletTransactionArgs = {
  _inc?: InputMaybe<PatientWalletTransaction_Inc_Input>;
  _set?: InputMaybe<PatientWalletTransaction_Set_Input>;
  where: PatientWalletTransaction_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_PatientWalletTransaction_By_PkArgs = {
  _inc?: InputMaybe<PatientWalletTransaction_Inc_Input>;
  _set?: InputMaybe<PatientWalletTransaction_Set_Input>;
  pk_columns: PatientWalletTransaction_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_PatientWalletTransaction_ManyArgs = {
  updates: Array<PatientWalletTransaction_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_PatientWallet_By_PkArgs = {
  _inc?: InputMaybe<PatientWallet_Inc_Input>;
  _set?: InputMaybe<PatientWallet_Set_Input>;
  pk_columns: PatientWallet_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_PatientWallet_ManyArgs = {
  updates: Array<PatientWallet_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_PatientWorkspaceArgs = {
  _append?: InputMaybe<PatientWorkspace_Append_Input>;
  _delete_at_path?: InputMaybe<PatientWorkspace_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<PatientWorkspace_Delete_Elem_Input>;
  _delete_key?: InputMaybe<PatientWorkspace_Delete_Key_Input>;
  _inc?: InputMaybe<PatientWorkspace_Inc_Input>;
  _prepend?: InputMaybe<PatientWorkspace_Prepend_Input>;
  _set?: InputMaybe<PatientWorkspace_Set_Input>;
  where: PatientWorkspace_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_PatientWorkspace_By_PkArgs = {
  _append?: InputMaybe<PatientWorkspace_Append_Input>;
  _delete_at_path?: InputMaybe<PatientWorkspace_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<PatientWorkspace_Delete_Elem_Input>;
  _delete_key?: InputMaybe<PatientWorkspace_Delete_Key_Input>;
  _inc?: InputMaybe<PatientWorkspace_Inc_Input>;
  _prepend?: InputMaybe<PatientWorkspace_Prepend_Input>;
  _set?: InputMaybe<PatientWorkspace_Set_Input>;
  pk_columns: PatientWorkspace_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_PatientWorkspace_ManyArgs = {
  updates: Array<PatientWorkspace_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Patient_By_PkArgs = {
  _inc?: InputMaybe<Patient_Inc_Input>;
  _set?: InputMaybe<Patient_Set_Input>;
  pk_columns: Patient_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Patient_ManyArgs = {
  updates: Array<Patient_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_PaymentArgs = {
  _inc?: InputMaybe<Payment_Inc_Input>;
  _set?: InputMaybe<Payment_Set_Input>;
  where: Payment_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_PaymentMethodArgs = {
  _inc?: InputMaybe<PaymentMethod_Inc_Input>;
  _set?: InputMaybe<PaymentMethod_Set_Input>;
  where: PaymentMethod_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_PaymentMethod_By_PkArgs = {
  _inc?: InputMaybe<PaymentMethod_Inc_Input>;
  _set?: InputMaybe<PaymentMethod_Set_Input>;
  pk_columns: PaymentMethod_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_PaymentMethod_ManyArgs = {
  updates: Array<PaymentMethod_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_PaymentRelationshipArgs = {
  _set?: InputMaybe<PaymentRelationship_Set_Input>;
  where: PaymentRelationship_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_PaymentRelationship_By_PkArgs = {
  _set?: InputMaybe<PaymentRelationship_Set_Input>;
  pk_columns: PaymentRelationship_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_PaymentRelationship_ManyArgs = {
  updates: Array<PaymentRelationship_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Payment_By_PkArgs = {
  _inc?: InputMaybe<Payment_Inc_Input>;
  _set?: InputMaybe<Payment_Set_Input>;
  pk_columns: Payment_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Payment_ManyArgs = {
  updates: Array<Payment_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_PayrixTerminalArgs = {
  _set?: InputMaybe<PayrixTerminal_Set_Input>;
  where: PayrixTerminal_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_PayrixTerminalTransactionArgs = {
  _inc?: InputMaybe<PayrixTerminalTransaction_Inc_Input>;
  _set?: InputMaybe<PayrixTerminalTransaction_Set_Input>;
  where: PayrixTerminalTransaction_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_PayrixTerminalTransaction_By_PkArgs = {
  _inc?: InputMaybe<PayrixTerminalTransaction_Inc_Input>;
  _set?: InputMaybe<PayrixTerminalTransaction_Set_Input>;
  pk_columns: PayrixTerminalTransaction_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_PayrixTerminalTransaction_ManyArgs = {
  updates: Array<PayrixTerminalTransaction_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_PayrixTerminal_By_PkArgs = {
  _set?: InputMaybe<PayrixTerminal_Set_Input>;
  pk_columns: PayrixTerminal_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_PayrixTerminal_ManyArgs = {
  updates: Array<PayrixTerminal_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_PayrixWebAlertArgs = {
  _set?: InputMaybe<PayrixWebAlert_Set_Input>;
  where: PayrixWebAlert_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_PayrixWebAlert_By_PkArgs = {
  _set?: InputMaybe<PayrixWebAlert_Set_Input>;
  pk_columns: PayrixWebAlert_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_PayrixWebAlert_ManyArgs = {
  updates: Array<PayrixWebAlert_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_PhotoArgs = {
  _append?: InputMaybe<Photo_Append_Input>;
  _delete_at_path?: InputMaybe<Photo_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Photo_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Photo_Delete_Key_Input>;
  _prepend?: InputMaybe<Photo_Prepend_Input>;
  _set?: InputMaybe<Photo_Set_Input>;
  where: Photo_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_PhotoMediaTagArgs = {
  _set?: InputMaybe<PhotoMediaTag_Set_Input>;
  where: PhotoMediaTag_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_PhotoMediaTag_By_PkArgs = {
  _set?: InputMaybe<PhotoMediaTag_Set_Input>;
  pk_columns: PhotoMediaTag_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_PhotoMediaTag_ManyArgs = {
  updates: Array<PhotoMediaTag_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Photo_By_PkArgs = {
  _append?: InputMaybe<Photo_Append_Input>;
  _delete_at_path?: InputMaybe<Photo_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Photo_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Photo_Delete_Key_Input>;
  _prepend?: InputMaybe<Photo_Prepend_Input>;
  _set?: InputMaybe<Photo_Set_Input>;
  pk_columns: Photo_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Photo_ManyArgs = {
  updates: Array<Photo_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_PinArgs = {
  _set?: InputMaybe<Pin_Set_Input>;
  where: Pin_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Pin_By_PkArgs = {
  _set?: InputMaybe<Pin_Set_Input>;
  pk_columns: Pin_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Pin_ManyArgs = {
  updates: Array<Pin_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_PostStatusArgs = {
  _set?: InputMaybe<PostStatus_Set_Input>;
  where: PostStatus_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_PostStatus_By_PkArgs = {
  _set?: InputMaybe<PostStatus_Set_Input>;
  pk_columns: PostStatus_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_PostStatus_ManyArgs = {
  updates: Array<PostStatus_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_PrescriptionArgs = {
  _append?: InputMaybe<Prescription_Append_Input>;
  _delete_at_path?: InputMaybe<Prescription_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Prescription_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Prescription_Delete_Key_Input>;
  _inc?: InputMaybe<Prescription_Inc_Input>;
  _prepend?: InputMaybe<Prescription_Prepend_Input>;
  _set?: InputMaybe<Prescription_Set_Input>;
  where: Prescription_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_PrescriptionDetailArgs = {
  _inc?: InputMaybe<PrescriptionDetail_Inc_Input>;
  _set?: InputMaybe<PrescriptionDetail_Set_Input>;
  where: PrescriptionDetail_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_PrescriptionDetail_By_PkArgs = {
  _inc?: InputMaybe<PrescriptionDetail_Inc_Input>;
  _set?: InputMaybe<PrescriptionDetail_Set_Input>;
  pk_columns: PrescriptionDetail_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_PrescriptionDetail_ManyArgs = {
  updates: Array<PrescriptionDetail_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Prescription_By_PkArgs = {
  _append?: InputMaybe<Prescription_Append_Input>;
  _delete_at_path?: InputMaybe<Prescription_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Prescription_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Prescription_Delete_Key_Input>;
  _inc?: InputMaybe<Prescription_Inc_Input>;
  _prepend?: InputMaybe<Prescription_Prepend_Input>;
  _set?: InputMaybe<Prescription_Set_Input>;
  pk_columns: Prescription_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Prescription_ManyArgs = {
  updates: Array<Prescription_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_PromotionArgs = {
  _set?: InputMaybe<Promotion_Set_Input>;
  where: Promotion_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_PromotionCouponsArgs = {
  _set?: InputMaybe<PromotionCoupons_Set_Input>;
  where: PromotionCoupons_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_PromotionCoupons_By_PkArgs = {
  _set?: InputMaybe<PromotionCoupons_Set_Input>;
  pk_columns: PromotionCoupons_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_PromotionCoupons_ManyArgs = {
  updates: Array<PromotionCoupons_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_PromotionServicesArgs = {
  _inc?: InputMaybe<PromotionServices_Inc_Input>;
  _set?: InputMaybe<PromotionServices_Set_Input>;
  where: PromotionServices_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_PromotionServices_By_PkArgs = {
  _inc?: InputMaybe<PromotionServices_Inc_Input>;
  _set?: InputMaybe<PromotionServices_Set_Input>;
  pk_columns: PromotionServices_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_PromotionServices_ManyArgs = {
  updates: Array<PromotionServices_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Promotion_By_PkArgs = {
  _set?: InputMaybe<Promotion_Set_Input>;
  pk_columns: Promotion_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Promotion_ManyArgs = {
  updates: Array<Promotion_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ProviderArgs = {
  _inc?: InputMaybe<Provider_Inc_Input>;
  _set?: InputMaybe<Provider_Set_Input>;
  where: Provider_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_ProviderScheduleArgs = {
  _append?: InputMaybe<ProviderSchedule_Append_Input>;
  _delete_at_path?: InputMaybe<ProviderSchedule_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<ProviderSchedule_Delete_Elem_Input>;
  _delete_key?: InputMaybe<ProviderSchedule_Delete_Key_Input>;
  _prepend?: InputMaybe<ProviderSchedule_Prepend_Input>;
  _set?: InputMaybe<ProviderSchedule_Set_Input>;
  where: ProviderSchedule_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_ProviderSchedule_By_PkArgs = {
  _append?: InputMaybe<ProviderSchedule_Append_Input>;
  _delete_at_path?: InputMaybe<ProviderSchedule_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<ProviderSchedule_Delete_Elem_Input>;
  _delete_key?: InputMaybe<ProviderSchedule_Delete_Key_Input>;
  _prepend?: InputMaybe<ProviderSchedule_Prepend_Input>;
  _set?: InputMaybe<ProviderSchedule_Set_Input>;
  pk_columns: ProviderSchedule_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ProviderSchedule_ManyArgs = {
  updates: Array<ProviderSchedule_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Provider_By_PkArgs = {
  _inc?: InputMaybe<Provider_Inc_Input>;
  _set?: InputMaybe<Provider_Set_Input>;
  pk_columns: Provider_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Provider_ManyArgs = {
  updates: Array<Provider_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_PurchaseOrderArgs = {
  _inc?: InputMaybe<PurchaseOrder_Inc_Input>;
  _set?: InputMaybe<PurchaseOrder_Set_Input>;
  where: PurchaseOrder_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_PurchaseOrder_By_PkArgs = {
  _inc?: InputMaybe<PurchaseOrder_Inc_Input>;
  _set?: InputMaybe<PurchaseOrder_Set_Input>;
  pk_columns: PurchaseOrder_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_PurchaseOrder_ManyArgs = {
  updates: Array<PurchaseOrder_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_RecurrenceTypeArgs = {
  _set?: InputMaybe<RecurrenceType_Set_Input>;
  where: RecurrenceType_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_RecurrenceType_By_PkArgs = {
  _set?: InputMaybe<RecurrenceType_Set_Input>;
  pk_columns: RecurrenceType_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_RecurrenceType_ManyArgs = {
  updates: Array<RecurrenceType_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_RefundArgs = {
  _inc?: InputMaybe<Refund_Inc_Input>;
  _set?: InputMaybe<Refund_Set_Input>;
  where: Refund_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Refund_By_PkArgs = {
  _inc?: InputMaybe<Refund_Inc_Input>;
  _set?: InputMaybe<Refund_Set_Input>;
  pk_columns: Refund_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Refund_ManyArgs = {
  updates: Array<Refund_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ResolutionStatusArgs = {
  _set?: InputMaybe<ResolutionStatus_Set_Input>;
  where: ResolutionStatus_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_ResolutionStatus_By_PkArgs = {
  _set?: InputMaybe<ResolutionStatus_Set_Input>;
  pk_columns: ResolutionStatus_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ResolutionStatus_ManyArgs = {
  updates: Array<ResolutionStatus_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_RewardProgramArgs = {
  _set?: InputMaybe<RewardProgram_Set_Input>;
  where: RewardProgram_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_RewardProgramEnrollmentArgs = {
  _set?: InputMaybe<RewardProgramEnrollment_Set_Input>;
  where: RewardProgramEnrollment_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_RewardProgramEnrollment_By_PkArgs = {
  _set?: InputMaybe<RewardProgramEnrollment_Set_Input>;
  pk_columns: RewardProgramEnrollment_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_RewardProgramEnrollment_ManyArgs = {
  updates: Array<RewardProgramEnrollment_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_RewardProgram_By_PkArgs = {
  _set?: InputMaybe<RewardProgram_Set_Input>;
  pk_columns: RewardProgram_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_RewardProgram_ManyArgs = {
  updates: Array<RewardProgram_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_RoomArgs = {
  _set?: InputMaybe<Room_Set_Input>;
  where: Room_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Room_By_PkArgs = {
  _set?: InputMaybe<Room_Set_Input>;
  pk_columns: Room_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Room_ManyArgs = {
  updates: Array<Room_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_RsReviewArgs = {
  _set?: InputMaybe<RsReview_Set_Input>;
  where: RsReview_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_RsReview_By_PkArgs = {
  _set?: InputMaybe<RsReview_Set_Input>;
  pk_columns: RsReview_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_RsReview_ManyArgs = {
  updates: Array<RsReview_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ServiceArgs = {
  _append?: InputMaybe<Service_Append_Input>;
  _delete_at_path?: InputMaybe<Service_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Service_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Service_Delete_Key_Input>;
  _inc?: InputMaybe<Service_Inc_Input>;
  _prepend?: InputMaybe<Service_Prepend_Input>;
  _set?: InputMaybe<Service_Set_Input>;
  where: Service_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_ServiceAddOnArgs = {
  _inc?: InputMaybe<ServiceAddOn_Inc_Input>;
  _set?: InputMaybe<ServiceAddOn_Set_Input>;
  where: ServiceAddOn_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_ServiceAddOn_By_PkArgs = {
  _inc?: InputMaybe<ServiceAddOn_Inc_Input>;
  _set?: InputMaybe<ServiceAddOn_Set_Input>;
  pk_columns: ServiceAddOn_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ServiceAddOn_ManyArgs = {
  updates: Array<ServiceAddOn_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ServiceCategoryArgs = {
  _set?: InputMaybe<ServiceCategory_Set_Input>;
  where: ServiceCategory_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_ServiceCategory_By_PkArgs = {
  _set?: InputMaybe<ServiceCategory_Set_Input>;
  pk_columns: ServiceCategory_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ServiceCategory_ManyArgs = {
  updates: Array<ServiceCategory_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ServiceConsentArgs = {
  _set?: InputMaybe<ServiceConsent_Set_Input>;
  where: ServiceConsent_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_ServiceConsent_By_PkArgs = {
  _set?: InputMaybe<ServiceConsent_Set_Input>;
  pk_columns: ServiceConsent_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ServiceConsent_ManyArgs = {
  updates: Array<ServiceConsent_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ServiceConsumableTypeArgs = {
  _inc?: InputMaybe<ServiceConsumableType_Inc_Input>;
  _set?: InputMaybe<ServiceConsumableType_Set_Input>;
  where: ServiceConsumableType_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_ServiceConsumableType_By_PkArgs = {
  _inc?: InputMaybe<ServiceConsumableType_Inc_Input>;
  _set?: InputMaybe<ServiceConsumableType_Set_Input>;
  pk_columns: ServiceConsumableType_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ServiceConsumableType_ManyArgs = {
  updates: Array<ServiceConsumableType_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ServiceCouponArgs = {
  _set?: InputMaybe<ServiceCoupon_Set_Input>;
  where: ServiceCoupon_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_ServiceCoupon_By_PkArgs = {
  _set?: InputMaybe<ServiceCoupon_Set_Input>;
  pk_columns: ServiceCoupon_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ServiceCoupon_ManyArgs = {
  updates: Array<ServiceCoupon_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ServiceCustomFormArgs = {
  _set?: InputMaybe<ServiceCustomForm_Set_Input>;
  where: ServiceCustomForm_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_ServiceCustomForm_By_PkArgs = {
  _set?: InputMaybe<ServiceCustomForm_Set_Input>;
  pk_columns: ServiceCustomForm_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ServiceCustomForm_ManyArgs = {
  updates: Array<ServiceCustomForm_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ServiceDeviceTypeArgs = {
  _set?: InputMaybe<ServiceDeviceType_Set_Input>;
  where: ServiceDeviceType_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_ServiceDeviceType_By_PkArgs = {
  _set?: InputMaybe<ServiceDeviceType_Set_Input>;
  pk_columns: ServiceDeviceType_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ServiceDeviceType_ManyArgs = {
  updates: Array<ServiceDeviceType_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ServiceProviderArgs = {
  _set?: InputMaybe<ServiceProvider_Set_Input>;
  where: ServiceProvider_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_ServiceProvider_By_PkArgs = {
  _set?: InputMaybe<ServiceProvider_Set_Input>;
  pk_columns: ServiceProvider_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ServiceProvider_ManyArgs = {
  updates: Array<ServiceProvider_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ServiceUnitArgs = {
  _set?: InputMaybe<ServiceUnit_Set_Input>;
  where: ServiceUnit_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_ServiceUnit_By_PkArgs = {
  _set?: InputMaybe<ServiceUnit_Set_Input>;
  pk_columns: ServiceUnit_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ServiceUnit_ManyArgs = {
  updates: Array<ServiceUnit_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Service_By_PkArgs = {
  _append?: InputMaybe<Service_Append_Input>;
  _delete_at_path?: InputMaybe<Service_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Service_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Service_Delete_Key_Input>;
  _inc?: InputMaybe<Service_Inc_Input>;
  _prepend?: InputMaybe<Service_Prepend_Input>;
  _set?: InputMaybe<Service_Set_Input>;
  pk_columns: Service_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Service_ManyArgs = {
  updates: Array<Service_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ShrinkArgs = {
  _set?: InputMaybe<Shrink_Set_Input>;
  where: Shrink_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Shrink_By_PkArgs = {
  _set?: InputMaybe<Shrink_Set_Input>;
  pk_columns: Shrink_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Shrink_ManyArgs = {
  updates: Array<Shrink_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_SignatureArgs = {
  _set?: InputMaybe<Signature_Set_Input>;
  where: Signature_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Signature_By_PkArgs = {
  _set?: InputMaybe<Signature_Set_Input>;
  pk_columns: Signature_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Signature_ManyArgs = {
  updates: Array<Signature_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_SmartNoteArgs = {
  _set?: InputMaybe<SmartNote_Set_Input>;
  where: SmartNote_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_SmartNote_By_PkArgs = {
  _set?: InputMaybe<SmartNote_Set_Input>;
  pk_columns: SmartNote_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_SmartNote_ManyArgs = {
  updates: Array<SmartNote_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_SmsTemplateArgs = {
  _inc?: InputMaybe<SmsTemplate_Inc_Input>;
  _set?: InputMaybe<SmsTemplate_Set_Input>;
  where: SmsTemplate_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_SmsTemplatePatientArgs = {
  _set?: InputMaybe<SmsTemplatePatient_Set_Input>;
  where: SmsTemplatePatient_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_SmsTemplatePatient_By_PkArgs = {
  _set?: InputMaybe<SmsTemplatePatient_Set_Input>;
  pk_columns: SmsTemplatePatient_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_SmsTemplatePatient_ManyArgs = {
  updates: Array<SmsTemplatePatient_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_SmsTemplateSendQueueArgs = {
  _set?: InputMaybe<SmsTemplateSendQueue_Set_Input>;
  where: SmsTemplateSendQueue_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_SmsTemplateSendQueue_By_PkArgs = {
  _set?: InputMaybe<SmsTemplateSendQueue_Set_Input>;
  pk_columns: SmsTemplateSendQueue_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_SmsTemplateSendQueue_ManyArgs = {
  updates: Array<SmsTemplateSendQueue_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_SmsTemplate_By_PkArgs = {
  _inc?: InputMaybe<SmsTemplate_Inc_Input>;
  _set?: InputMaybe<SmsTemplate_Set_Input>;
  pk_columns: SmsTemplate_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_SmsTemplate_ManyArgs = {
  updates: Array<SmsTemplate_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_SocialMediaAccountArgs = {
  _set?: InputMaybe<SocialMediaAccount_Set_Input>;
  where: SocialMediaAccount_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_SocialMediaAccountPostArgs = {
  _inc?: InputMaybe<SocialMediaAccountPost_Inc_Input>;
  _set?: InputMaybe<SocialMediaAccountPost_Set_Input>;
  where: SocialMediaAccountPost_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_SocialMediaAccountPost_By_PkArgs = {
  _inc?: InputMaybe<SocialMediaAccountPost_Inc_Input>;
  _set?: InputMaybe<SocialMediaAccountPost_Set_Input>;
  pk_columns: SocialMediaAccountPost_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_SocialMediaAccountPost_ManyArgs = {
  updates: Array<SocialMediaAccountPost_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_SocialMediaAccount_By_PkArgs = {
  _set?: InputMaybe<SocialMediaAccount_Set_Input>;
  pk_columns: SocialMediaAccount_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_SocialMediaAccount_ManyArgs = {
  updates: Array<SocialMediaAccount_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_SocialMediaPlatformArgs = {
  _set?: InputMaybe<SocialMediaPlatform_Set_Input>;
  where: SocialMediaPlatform_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_SocialMediaPlatform_By_PkArgs = {
  _set?: InputMaybe<SocialMediaPlatform_Set_Input>;
  pk_columns: SocialMediaPlatform_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_SocialMediaPlatform_ManyArgs = {
  updates: Array<SocialMediaPlatform_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_SocialMediaPostArgs = {
  _set?: InputMaybe<SocialMediaPost_Set_Input>;
  where: SocialMediaPost_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_SocialMediaPost_By_PkArgs = {
  _set?: InputMaybe<SocialMediaPost_Set_Input>;
  pk_columns: SocialMediaPost_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_SocialMediaPost_ManyArgs = {
  updates: Array<SocialMediaPost_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_TagArgs = {
  _set?: InputMaybe<Tag_Set_Input>;
  where: Tag_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Tag_By_PkArgs = {
  _set?: InputMaybe<Tag_Set_Input>;
  pk_columns: Tag_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Tag_ManyArgs = {
  updates: Array<Tag_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_TaskArgs = {
  _set?: InputMaybe<Task_Set_Input>;
  where: Task_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_TaskCommentArgs = {
  _set?: InputMaybe<TaskComment_Set_Input>;
  where: TaskComment_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_TaskComment_By_PkArgs = {
  _set?: InputMaybe<TaskComment_Set_Input>;
  pk_columns: TaskComment_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_TaskComment_ManyArgs = {
  updates: Array<TaskComment_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Task_By_PkArgs = {
  _set?: InputMaybe<Task_Set_Input>;
  pk_columns: Task_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Task_ManyArgs = {
  updates: Array<Task_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_TelehealthMeetingArgs = {
  _set?: InputMaybe<TelehealthMeeting_Set_Input>;
  where: TelehealthMeeting_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_TelehealthMeeting_By_PkArgs = {
  _set?: InputMaybe<TelehealthMeeting_Set_Input>;
  pk_columns: TelehealthMeeting_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_TelehealthMeeting_ManyArgs = {
  updates: Array<TelehealthMeeting_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_UnusedAppointmentServiceConsumableTypeArgs = {
  _set?: InputMaybe<UnusedAppointmentServiceConsumableType_Set_Input>;
  where: UnusedAppointmentServiceConsumableType_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_UnusedAppointmentServiceConsumableType_By_PkArgs = {
  _set?: InputMaybe<UnusedAppointmentServiceConsumableType_Set_Input>;
  pk_columns: UnusedAppointmentServiceConsumableType_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_UnusedAppointmentServiceConsumableType_ManyArgs = {
  updates: Array<UnusedAppointmentServiceConsumableType_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_UserArgs = {
  _set?: InputMaybe<User_Set_Input>;
  where: User_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_UserInviteArgs = {
  _set?: InputMaybe<UserInvite_Set_Input>;
  where: UserInvite_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_UserInvite_By_PkArgs = {
  _set?: InputMaybe<UserInvite_Set_Input>;
  pk_columns: UserInvite_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_UserInvite_ManyArgs = {
  updates: Array<UserInvite_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_UserOrganizationArgs = {
  _set?: InputMaybe<UserOrganization_Set_Input>;
  where: UserOrganization_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_UserOrganization_By_PkArgs = {
  _set?: InputMaybe<UserOrganization_Set_Input>;
  pk_columns: UserOrganization_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_UserOrganization_ManyArgs = {
  updates: Array<UserOrganization_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_UserWorkspaceArgs = {
  _set?: InputMaybe<UserWorkspace_Set_Input>;
  where: UserWorkspace_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_UserWorkspace_By_PkArgs = {
  _set?: InputMaybe<UserWorkspace_Set_Input>;
  pk_columns: UserWorkspace_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_UserWorkspace_ManyArgs = {
  updates: Array<UserWorkspace_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_User_By_PkArgs = {
  _set?: InputMaybe<User_Set_Input>;
  pk_columns: User_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_ManyArgs = {
  updates: Array<User_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_VitalsArgs = {
  _append?: InputMaybe<Vitals_Append_Input>;
  _delete_at_path?: InputMaybe<Vitals_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Vitals_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Vitals_Delete_Key_Input>;
  _inc?: InputMaybe<Vitals_Inc_Input>;
  _prepend?: InputMaybe<Vitals_Prepend_Input>;
  _set?: InputMaybe<Vitals_Set_Input>;
  where: Vitals_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Vitals_By_PkArgs = {
  _append?: InputMaybe<Vitals_Append_Input>;
  _delete_at_path?: InputMaybe<Vitals_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Vitals_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Vitals_Delete_Key_Input>;
  _inc?: InputMaybe<Vitals_Inc_Input>;
  _prepend?: InputMaybe<Vitals_Prepend_Input>;
  _set?: InputMaybe<Vitals_Set_Input>;
  pk_columns: Vitals_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Vitals_ManyArgs = {
  updates: Array<Vitals_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_WorkspaceArgs = {
  _append?: InputMaybe<Workspace_Append_Input>;
  _delete_at_path?: InputMaybe<Workspace_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Workspace_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Workspace_Delete_Key_Input>;
  _prepend?: InputMaybe<Workspace_Prepend_Input>;
  _set?: InputMaybe<Workspace_Set_Input>;
  where: Workspace_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_WorkspaceConfigurationArgs = {
  _append?: InputMaybe<WorkspaceConfiguration_Append_Input>;
  _delete_at_path?: InputMaybe<WorkspaceConfiguration_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<WorkspaceConfiguration_Delete_Elem_Input>;
  _delete_key?: InputMaybe<WorkspaceConfiguration_Delete_Key_Input>;
  _inc?: InputMaybe<WorkspaceConfiguration_Inc_Input>;
  _prepend?: InputMaybe<WorkspaceConfiguration_Prepend_Input>;
  _set?: InputMaybe<WorkspaceConfiguration_Set_Input>;
  where: WorkspaceConfiguration_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_WorkspaceConfiguration_By_PkArgs = {
  _append?: InputMaybe<WorkspaceConfiguration_Append_Input>;
  _delete_at_path?: InputMaybe<WorkspaceConfiguration_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<WorkspaceConfiguration_Delete_Elem_Input>;
  _delete_key?: InputMaybe<WorkspaceConfiguration_Delete_Key_Input>;
  _inc?: InputMaybe<WorkspaceConfiguration_Inc_Input>;
  _prepend?: InputMaybe<WorkspaceConfiguration_Prepend_Input>;
  _set?: InputMaybe<WorkspaceConfiguration_Set_Input>;
  pk_columns: WorkspaceConfiguration_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_WorkspaceConfiguration_ManyArgs = {
  updates: Array<WorkspaceConfiguration_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Workspace_By_PkArgs = {
  _append?: InputMaybe<Workspace_Append_Input>;
  _delete_at_path?: InputMaybe<Workspace_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Workspace_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Workspace_Delete_Key_Input>;
  _prepend?: InputMaybe<Workspace_Prepend_Input>;
  _set?: InputMaybe<Workspace_Set_Input>;
  pk_columns: Workspace_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Workspace_ManyArgs = {
  updates: Array<Workspace_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ZdReviewArgs = {
  _inc?: InputMaybe<ZdReview_Inc_Input>;
  _set?: InputMaybe<ZdReview_Set_Input>;
  where: ZdReview_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_ZdReview_By_PkArgs = {
  _inc?: InputMaybe<ZdReview_Inc_Input>;
  _set?: InputMaybe<ZdReview_Set_Input>;
  pk_columns: ZdReview_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ZdReview_ManyArgs = {
  updates: Array<ZdReview_Updates>;
};


/** mutation root */
export type Mutation_RootUpsertPatientArgs = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phoneNumber: Scalars['String'];
  workspaceId: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootValidateCouponArgs = {
  couponId: Scalars['uuid'];
  isMembershipCoupon?: InputMaybe<Scalars['Boolean']>;
  locationId: Scalars['uuid'];
  patientId?: InputMaybe<Scalars['uuid']>;
  serviceIds?: InputMaybe<Array<InputMaybe<Scalars['uuid']>>>;
};


/** mutation root */
export type Mutation_RootVerifyPinArgs = {
  value: Scalars['String'];
};

/** columns and relationships of "note" */
export type Note = {
  __typename?: 'note';
  /** An object relationship */
  appointment?: Maybe<Appointment>;
  appointmentId?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  assignedToProvider?: Maybe<Provider>;
  assignedToProviderId?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  author: User;
  authorId: Scalars['uuid'];
  createdAt: Scalars['timestamptz'];
  /** An object relationship */
  editedBy?: Maybe<User>;
  editorJSON?: Maybe<Scalars['jsonb']>;
  executiveSummary?: Maybe<Scalars['String']>;
  /** An array relationship */
  followUpNotes: Array<Note>;
  /** An aggregate relationship */
  followUpNotes_aggregate: Note_Aggregate;
  id: Scalars['uuid'];
  importJobId?: Maybe<Scalars['uuid']>;
  isGoodFaithExam?: Maybe<Scalars['Boolean']>;
  isInternal: Scalars['Boolean'];
  isLocked?: Maybe<Scalars['Boolean']>;
  lastEditedBy?: Maybe<Scalars['uuid']>;
  message: Scalars['String'];
  /** An array relationship */
  noteSignatures: Array<NoteSignature>;
  /** An aggregate relationship */
  noteSignatures_aggregate: NoteSignature_Aggregate;
  /** An object relationship */
  parentNote?: Maybe<Note>;
  parentNoteId?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  patient: Patient;
  patientId: Scalars['uuid'];
  title?: Maybe<Scalars['String']>;
  updatedAt: Scalars['timestamptz'];
  /** An object relationship */
  workspace: Workspace;
  workspaceId: Scalars['uuid'];
};


/** columns and relationships of "note" */
export type NoteEditorJsonArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "note" */
export type NoteFollowUpNotesArgs = {
  distinct_on?: InputMaybe<Array<Note_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Note_Order_By>>;
  where?: InputMaybe<Note_Bool_Exp>;
};


/** columns and relationships of "note" */
export type NoteFollowUpNotes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Note_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Note_Order_By>>;
  where?: InputMaybe<Note_Bool_Exp>;
};


/** columns and relationships of "note" */
export type NoteNoteSignaturesArgs = {
  distinct_on?: InputMaybe<Array<NoteSignature_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<NoteSignature_Order_By>>;
  where?: InputMaybe<NoteSignature_Bool_Exp>;
};


/** columns and relationships of "note" */
export type NoteNoteSignatures_AggregateArgs = {
  distinct_on?: InputMaybe<Array<NoteSignature_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<NoteSignature_Order_By>>;
  where?: InputMaybe<NoteSignature_Bool_Exp>;
};

/** columns and relationships of "noteSignature" */
export type NoteSignature = {
  __typename?: 'noteSignature';
  createdAt: Scalars['timestamp'];
  /** An object relationship */
  note?: Maybe<Note>;
  noteId: Scalars['uuid'];
  /** An object relationship */
  signature?: Maybe<Signature>;
  signatureId: Scalars['uuid'];
  updatedAt: Scalars['timestamptz'];
};

/** aggregated selection of "noteSignature" */
export type NoteSignature_Aggregate = {
  __typename?: 'noteSignature_aggregate';
  aggregate?: Maybe<NoteSignature_Aggregate_Fields>;
  nodes: Array<NoteSignature>;
};

export type NoteSignature_Aggregate_Bool_Exp = {
  count?: InputMaybe<NoteSignature_Aggregate_Bool_Exp_Count>;
};

export type NoteSignature_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<NoteSignature_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<NoteSignature_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "noteSignature" */
export type NoteSignature_Aggregate_Fields = {
  __typename?: 'noteSignature_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<NoteSignature_Max_Fields>;
  min?: Maybe<NoteSignature_Min_Fields>;
};


/** aggregate fields of "noteSignature" */
export type NoteSignature_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<NoteSignature_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "noteSignature" */
export type NoteSignature_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<NoteSignature_Max_Order_By>;
  min?: InputMaybe<NoteSignature_Min_Order_By>;
};

/** input type for inserting array relation for remote table "noteSignature" */
export type NoteSignature_Arr_Rel_Insert_Input = {
  data: Array<NoteSignature_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<NoteSignature_On_Conflict>;
};

/** Boolean expression to filter rows from the table "noteSignature". All fields are combined with a logical 'AND'. */
export type NoteSignature_Bool_Exp = {
  _and?: InputMaybe<Array<NoteSignature_Bool_Exp>>;
  _not?: InputMaybe<NoteSignature_Bool_Exp>;
  _or?: InputMaybe<Array<NoteSignature_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamp_Comparison_Exp>;
  note?: InputMaybe<Note_Bool_Exp>;
  noteId?: InputMaybe<Uuid_Comparison_Exp>;
  signature?: InputMaybe<Signature_Bool_Exp>;
  signatureId?: InputMaybe<Uuid_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "noteSignature" */
export enum NoteSignature_Constraint {
  /** unique or primary key constraint on columns "noteId", "signatureId" */
  NoteSignaturePkey = 'noteSignature_pkey'
}

/** input type for inserting data into table "noteSignature" */
export type NoteSignature_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamp']>;
  note?: InputMaybe<Note_Obj_Rel_Insert_Input>;
  noteId?: InputMaybe<Scalars['uuid']>;
  signature?: InputMaybe<Signature_Obj_Rel_Insert_Input>;
  signatureId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type NoteSignature_Max_Fields = {
  __typename?: 'noteSignature_max_fields';
  createdAt?: Maybe<Scalars['timestamp']>;
  noteId?: Maybe<Scalars['uuid']>;
  signatureId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "noteSignature" */
export type NoteSignature_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  noteId?: InputMaybe<Order_By>;
  signatureId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type NoteSignature_Min_Fields = {
  __typename?: 'noteSignature_min_fields';
  createdAt?: Maybe<Scalars['timestamp']>;
  noteId?: Maybe<Scalars['uuid']>;
  signatureId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "noteSignature" */
export type NoteSignature_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  noteId?: InputMaybe<Order_By>;
  signatureId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "noteSignature" */
export type NoteSignature_Mutation_Response = {
  __typename?: 'noteSignature_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<NoteSignature>;
};

/** on_conflict condition type for table "noteSignature" */
export type NoteSignature_On_Conflict = {
  constraint: NoteSignature_Constraint;
  update_columns?: Array<NoteSignature_Update_Column>;
  where?: InputMaybe<NoteSignature_Bool_Exp>;
};

/** Ordering options when selecting data from "noteSignature". */
export type NoteSignature_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  note?: InputMaybe<Note_Order_By>;
  noteId?: InputMaybe<Order_By>;
  signature?: InputMaybe<Signature_Order_By>;
  signatureId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: noteSignature */
export type NoteSignature_Pk_Columns_Input = {
  noteId: Scalars['uuid'];
  signatureId: Scalars['uuid'];
};

/** select columns of table "noteSignature" */
export enum NoteSignature_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  NoteId = 'noteId',
  /** column name */
  SignatureId = 'signatureId',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "noteSignature" */
export type NoteSignature_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamp']>;
  noteId?: InputMaybe<Scalars['uuid']>;
  signatureId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "noteSignature" */
export type NoteSignature_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: NoteSignature_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type NoteSignature_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamp']>;
  noteId?: InputMaybe<Scalars['uuid']>;
  signatureId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "noteSignature" */
export enum NoteSignature_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  NoteId = 'noteId',
  /** column name */
  SignatureId = 'signatureId',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type NoteSignature_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<NoteSignature_Set_Input>;
  /** filter the rows which have to be updated */
  where: NoteSignature_Bool_Exp;
};

/** aggregated selection of "note" */
export type Note_Aggregate = {
  __typename?: 'note_aggregate';
  aggregate?: Maybe<Note_Aggregate_Fields>;
  nodes: Array<Note>;
};

export type Note_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Note_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Note_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Note_Aggregate_Bool_Exp_Count>;
};

export type Note_Aggregate_Bool_Exp_Bool_And = {
  arguments: Note_Select_Column_Note_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Note_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Note_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Note_Select_Column_Note_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Note_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Note_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Note_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Note_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "note" */
export type Note_Aggregate_Fields = {
  __typename?: 'note_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Note_Max_Fields>;
  min?: Maybe<Note_Min_Fields>;
};


/** aggregate fields of "note" */
export type Note_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Note_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "note" */
export type Note_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Note_Max_Order_By>;
  min?: InputMaybe<Note_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Note_Append_Input = {
  editorJSON?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "note" */
export type Note_Arr_Rel_Insert_Input = {
  data: Array<Note_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Note_On_Conflict>;
};

/** Boolean expression to filter rows from the table "note". All fields are combined with a logical 'AND'. */
export type Note_Bool_Exp = {
  _and?: InputMaybe<Array<Note_Bool_Exp>>;
  _not?: InputMaybe<Note_Bool_Exp>;
  _or?: InputMaybe<Array<Note_Bool_Exp>>;
  appointment?: InputMaybe<Appointment_Bool_Exp>;
  appointmentId?: InputMaybe<Uuid_Comparison_Exp>;
  assignedToProvider?: InputMaybe<Provider_Bool_Exp>;
  assignedToProviderId?: InputMaybe<Uuid_Comparison_Exp>;
  author?: InputMaybe<User_Bool_Exp>;
  authorId?: InputMaybe<Uuid_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  editedBy?: InputMaybe<User_Bool_Exp>;
  editorJSON?: InputMaybe<Jsonb_Comparison_Exp>;
  executiveSummary?: InputMaybe<String_Comparison_Exp>;
  followUpNotes?: InputMaybe<Note_Bool_Exp>;
  followUpNotes_aggregate?: InputMaybe<Note_Aggregate_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  importJobId?: InputMaybe<Uuid_Comparison_Exp>;
  isGoodFaithExam?: InputMaybe<Boolean_Comparison_Exp>;
  isInternal?: InputMaybe<Boolean_Comparison_Exp>;
  isLocked?: InputMaybe<Boolean_Comparison_Exp>;
  lastEditedBy?: InputMaybe<Uuid_Comparison_Exp>;
  message?: InputMaybe<String_Comparison_Exp>;
  noteSignatures?: InputMaybe<NoteSignature_Bool_Exp>;
  noteSignatures_aggregate?: InputMaybe<NoteSignature_Aggregate_Bool_Exp>;
  parentNote?: InputMaybe<Note_Bool_Exp>;
  parentNoteId?: InputMaybe<Uuid_Comparison_Exp>;
  patient?: InputMaybe<Patient_Bool_Exp>;
  patientId?: InputMaybe<Uuid_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  workspace?: InputMaybe<Workspace_Bool_Exp>;
  workspaceId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "note" */
export enum Note_Constraint {
  /** unique or primary key constraint on columns "id" */
  PatientNotePkey = 'patientNote_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Note_Delete_At_Path_Input = {
  editorJSON?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Note_Delete_Elem_Input = {
  editorJSON?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Note_Delete_Key_Input = {
  editorJSON?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "note" */
export type Note_Insert_Input = {
  appointment?: InputMaybe<Appointment_Obj_Rel_Insert_Input>;
  appointmentId?: InputMaybe<Scalars['uuid']>;
  assignedToProvider?: InputMaybe<Provider_Obj_Rel_Insert_Input>;
  assignedToProviderId?: InputMaybe<Scalars['uuid']>;
  author?: InputMaybe<User_Obj_Rel_Insert_Input>;
  authorId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  editedBy?: InputMaybe<User_Obj_Rel_Insert_Input>;
  editorJSON?: InputMaybe<Scalars['jsonb']>;
  executiveSummary?: InputMaybe<Scalars['String']>;
  followUpNotes?: InputMaybe<Note_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']>;
  importJobId?: InputMaybe<Scalars['uuid']>;
  isGoodFaithExam?: InputMaybe<Scalars['Boolean']>;
  isInternal?: InputMaybe<Scalars['Boolean']>;
  isLocked?: InputMaybe<Scalars['Boolean']>;
  lastEditedBy?: InputMaybe<Scalars['uuid']>;
  message?: InputMaybe<Scalars['String']>;
  noteSignatures?: InputMaybe<NoteSignature_Arr_Rel_Insert_Input>;
  parentNote?: InputMaybe<Note_Obj_Rel_Insert_Input>;
  parentNoteId?: InputMaybe<Scalars['uuid']>;
  patient?: InputMaybe<Patient_Obj_Rel_Insert_Input>;
  patientId?: InputMaybe<Scalars['uuid']>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspace?: InputMaybe<Workspace_Obj_Rel_Insert_Input>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Note_Max_Fields = {
  __typename?: 'note_max_fields';
  appointmentId?: Maybe<Scalars['uuid']>;
  assignedToProviderId?: Maybe<Scalars['uuid']>;
  authorId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  executiveSummary?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  importJobId?: Maybe<Scalars['uuid']>;
  lastEditedBy?: Maybe<Scalars['uuid']>;
  message?: Maybe<Scalars['String']>;
  parentNoteId?: Maybe<Scalars['uuid']>;
  patientId?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "note" */
export type Note_Max_Order_By = {
  appointmentId?: InputMaybe<Order_By>;
  assignedToProviderId?: InputMaybe<Order_By>;
  authorId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  executiveSummary?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  importJobId?: InputMaybe<Order_By>;
  lastEditedBy?: InputMaybe<Order_By>;
  message?: InputMaybe<Order_By>;
  parentNoteId?: InputMaybe<Order_By>;
  patientId?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Note_Min_Fields = {
  __typename?: 'note_min_fields';
  appointmentId?: Maybe<Scalars['uuid']>;
  assignedToProviderId?: Maybe<Scalars['uuid']>;
  authorId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  executiveSummary?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  importJobId?: Maybe<Scalars['uuid']>;
  lastEditedBy?: Maybe<Scalars['uuid']>;
  message?: Maybe<Scalars['String']>;
  parentNoteId?: Maybe<Scalars['uuid']>;
  patientId?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "note" */
export type Note_Min_Order_By = {
  appointmentId?: InputMaybe<Order_By>;
  assignedToProviderId?: InputMaybe<Order_By>;
  authorId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  executiveSummary?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  importJobId?: InputMaybe<Order_By>;
  lastEditedBy?: InputMaybe<Order_By>;
  message?: InputMaybe<Order_By>;
  parentNoteId?: InputMaybe<Order_By>;
  patientId?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "note" */
export type Note_Mutation_Response = {
  __typename?: 'note_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Note>;
};

/** input type for inserting object relation for remote table "note" */
export type Note_Obj_Rel_Insert_Input = {
  data: Note_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Note_On_Conflict>;
};

/** on_conflict condition type for table "note" */
export type Note_On_Conflict = {
  constraint: Note_Constraint;
  update_columns?: Array<Note_Update_Column>;
  where?: InputMaybe<Note_Bool_Exp>;
};

/** Ordering options when selecting data from "note". */
export type Note_Order_By = {
  appointment?: InputMaybe<Appointment_Order_By>;
  appointmentId?: InputMaybe<Order_By>;
  assignedToProvider?: InputMaybe<Provider_Order_By>;
  assignedToProviderId?: InputMaybe<Order_By>;
  author?: InputMaybe<User_Order_By>;
  authorId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  editedBy?: InputMaybe<User_Order_By>;
  editorJSON?: InputMaybe<Order_By>;
  executiveSummary?: InputMaybe<Order_By>;
  followUpNotes_aggregate?: InputMaybe<Note_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  importJobId?: InputMaybe<Order_By>;
  isGoodFaithExam?: InputMaybe<Order_By>;
  isInternal?: InputMaybe<Order_By>;
  isLocked?: InputMaybe<Order_By>;
  lastEditedBy?: InputMaybe<Order_By>;
  message?: InputMaybe<Order_By>;
  noteSignatures_aggregate?: InputMaybe<NoteSignature_Aggregate_Order_By>;
  parentNote?: InputMaybe<Note_Order_By>;
  parentNoteId?: InputMaybe<Order_By>;
  patient?: InputMaybe<Patient_Order_By>;
  patientId?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  workspace?: InputMaybe<Workspace_Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: note */
export type Note_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Note_Prepend_Input = {
  editorJSON?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "note" */
export enum Note_Select_Column {
  /** column name */
  AppointmentId = 'appointmentId',
  /** column name */
  AssignedToProviderId = 'assignedToProviderId',
  /** column name */
  AuthorId = 'authorId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  EditorJson = 'editorJSON',
  /** column name */
  ExecutiveSummary = 'executiveSummary',
  /** column name */
  Id = 'id',
  /** column name */
  ImportJobId = 'importJobId',
  /** column name */
  IsGoodFaithExam = 'isGoodFaithExam',
  /** column name */
  IsInternal = 'isInternal',
  /** column name */
  IsLocked = 'isLocked',
  /** column name */
  LastEditedBy = 'lastEditedBy',
  /** column name */
  Message = 'message',
  /** column name */
  ParentNoteId = 'parentNoteId',
  /** column name */
  PatientId = 'patientId',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WorkspaceId = 'workspaceId'
}

/** select "note_aggregate_bool_exp_bool_and_arguments_columns" columns of table "note" */
export enum Note_Select_Column_Note_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsGoodFaithExam = 'isGoodFaithExam',
  /** column name */
  IsInternal = 'isInternal',
  /** column name */
  IsLocked = 'isLocked'
}

/** select "note_aggregate_bool_exp_bool_or_arguments_columns" columns of table "note" */
export enum Note_Select_Column_Note_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsGoodFaithExam = 'isGoodFaithExam',
  /** column name */
  IsInternal = 'isInternal',
  /** column name */
  IsLocked = 'isLocked'
}

/** input type for updating data in table "note" */
export type Note_Set_Input = {
  appointmentId?: InputMaybe<Scalars['uuid']>;
  assignedToProviderId?: InputMaybe<Scalars['uuid']>;
  authorId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  editorJSON?: InputMaybe<Scalars['jsonb']>;
  executiveSummary?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  importJobId?: InputMaybe<Scalars['uuid']>;
  isGoodFaithExam?: InputMaybe<Scalars['Boolean']>;
  isInternal?: InputMaybe<Scalars['Boolean']>;
  isLocked?: InputMaybe<Scalars['Boolean']>;
  lastEditedBy?: InputMaybe<Scalars['uuid']>;
  message?: InputMaybe<Scalars['String']>;
  parentNoteId?: InputMaybe<Scalars['uuid']>;
  patientId?: InputMaybe<Scalars['uuid']>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "note" */
export type Note_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Note_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Note_Stream_Cursor_Value_Input = {
  appointmentId?: InputMaybe<Scalars['uuid']>;
  assignedToProviderId?: InputMaybe<Scalars['uuid']>;
  authorId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  editorJSON?: InputMaybe<Scalars['jsonb']>;
  executiveSummary?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  importJobId?: InputMaybe<Scalars['uuid']>;
  isGoodFaithExam?: InputMaybe<Scalars['Boolean']>;
  isInternal?: InputMaybe<Scalars['Boolean']>;
  isLocked?: InputMaybe<Scalars['Boolean']>;
  lastEditedBy?: InputMaybe<Scalars['uuid']>;
  message?: InputMaybe<Scalars['String']>;
  parentNoteId?: InputMaybe<Scalars['uuid']>;
  patientId?: InputMaybe<Scalars['uuid']>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "note" */
export enum Note_Update_Column {
  /** column name */
  AppointmentId = 'appointmentId',
  /** column name */
  AssignedToProviderId = 'assignedToProviderId',
  /** column name */
  AuthorId = 'authorId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  EditorJson = 'editorJSON',
  /** column name */
  ExecutiveSummary = 'executiveSummary',
  /** column name */
  Id = 'id',
  /** column name */
  ImportJobId = 'importJobId',
  /** column name */
  IsGoodFaithExam = 'isGoodFaithExam',
  /** column name */
  IsInternal = 'isInternal',
  /** column name */
  IsLocked = 'isLocked',
  /** column name */
  LastEditedBy = 'lastEditedBy',
  /** column name */
  Message = 'message',
  /** column name */
  ParentNoteId = 'parentNoteId',
  /** column name */
  PatientId = 'patientId',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WorkspaceId = 'workspaceId'
}

export type Note_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Note_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Note_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Note_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Note_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Note_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Note_Set_Input>;
  /** filter the rows which have to be updated */
  where: Note_Bool_Exp;
};

/** Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'. */
export type Numeric_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['numeric']>;
  _gt?: InputMaybe<Scalars['numeric']>;
  _gte?: InputMaybe<Scalars['numeric']>;
  _in?: InputMaybe<Array<Scalars['numeric']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['numeric']>;
  _lte?: InputMaybe<Scalars['numeric']>;
  _neq?: InputMaybe<Scalars['numeric']>;
  _nin?: InputMaybe<Array<Scalars['numeric']>>;
};

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

/** columns and relationships of "organization" */
export type Organization = {
  __typename?: 'organization';
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  name: Scalars['String'];
  /** An object relationship */
  organizationConfiguration?: Maybe<OrganizationConfiguration>;
  updatedAt: Scalars['timestamptz'];
  /** An array relationship */
  workspaces: Array<Workspace>;
  /** An aggregate relationship */
  workspaces_aggregate: Workspace_Aggregate;
};


/** columns and relationships of "organization" */
export type OrganizationWorkspacesArgs = {
  distinct_on?: InputMaybe<Array<Workspace_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Workspace_Order_By>>;
  where?: InputMaybe<Workspace_Bool_Exp>;
};


/** columns and relationships of "organization" */
export type OrganizationWorkspaces_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Workspace_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Workspace_Order_By>>;
  where?: InputMaybe<Workspace_Bool_Exp>;
};

/** columns and relationships of "organizationConfiguration" */
export type OrganizationConfiguration = {
  __typename?: 'organizationConfiguration';
  appBaseUrl?: Maybe<Scalars['String']>;
  createdAt: Scalars['timestamptz'];
  darkLogo: Scalars['String'];
  darkLogoSmall: Scalars['String'];
  id: Scalars['uuid'];
  lightLogo: Scalars['String'];
  lightLogoSmall: Scalars['String'];
  /** An object relationship */
  organization: Organization;
  organizationId: Scalars['uuid'];
  patientPortalBaseUrl?: Maybe<Scalars['String']>;
  privacyPolicyLink?: Maybe<Scalars['String']>;
  supportEmail?: Maybe<Scalars['String']>;
  termsAndConditionsLink?: Maybe<Scalars['String']>;
  updatedAt: Scalars['timestamptz'];
  whitelabelDomain?: Maybe<Scalars['String']>;
  whitelabelingEnabled: Scalars['Boolean'];
};

/** aggregated selection of "organizationConfiguration" */
export type OrganizationConfiguration_Aggregate = {
  __typename?: 'organizationConfiguration_aggregate';
  aggregate?: Maybe<OrganizationConfiguration_Aggregate_Fields>;
  nodes: Array<OrganizationConfiguration>;
};

/** aggregate fields of "organizationConfiguration" */
export type OrganizationConfiguration_Aggregate_Fields = {
  __typename?: 'organizationConfiguration_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<OrganizationConfiguration_Max_Fields>;
  min?: Maybe<OrganizationConfiguration_Min_Fields>;
};


/** aggregate fields of "organizationConfiguration" */
export type OrganizationConfiguration_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<OrganizationConfiguration_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "organizationConfiguration". All fields are combined with a logical 'AND'. */
export type OrganizationConfiguration_Bool_Exp = {
  _and?: InputMaybe<Array<OrganizationConfiguration_Bool_Exp>>;
  _not?: InputMaybe<OrganizationConfiguration_Bool_Exp>;
  _or?: InputMaybe<Array<OrganizationConfiguration_Bool_Exp>>;
  appBaseUrl?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  darkLogo?: InputMaybe<String_Comparison_Exp>;
  darkLogoSmall?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  lightLogo?: InputMaybe<String_Comparison_Exp>;
  lightLogoSmall?: InputMaybe<String_Comparison_Exp>;
  organization?: InputMaybe<Organization_Bool_Exp>;
  organizationId?: InputMaybe<Uuid_Comparison_Exp>;
  patientPortalBaseUrl?: InputMaybe<String_Comparison_Exp>;
  privacyPolicyLink?: InputMaybe<String_Comparison_Exp>;
  supportEmail?: InputMaybe<String_Comparison_Exp>;
  termsAndConditionsLink?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  whitelabelDomain?: InputMaybe<String_Comparison_Exp>;
  whitelabelingEnabled?: InputMaybe<Boolean_Comparison_Exp>;
};

/** unique or primary key constraints on table "organizationConfiguration" */
export enum OrganizationConfiguration_Constraint {
  /** unique or primary key constraint on columns "id" */
  OrganizationConfigurationPkey = 'organizationConfiguration_pkey'
}

/** input type for inserting data into table "organizationConfiguration" */
export type OrganizationConfiguration_Insert_Input = {
  appBaseUrl?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  darkLogo?: InputMaybe<Scalars['String']>;
  darkLogoSmall?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  lightLogo?: InputMaybe<Scalars['String']>;
  lightLogoSmall?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Organization_Obj_Rel_Insert_Input>;
  organizationId?: InputMaybe<Scalars['uuid']>;
  patientPortalBaseUrl?: InputMaybe<Scalars['String']>;
  privacyPolicyLink?: InputMaybe<Scalars['String']>;
  supportEmail?: InputMaybe<Scalars['String']>;
  termsAndConditionsLink?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  whitelabelDomain?: InputMaybe<Scalars['String']>;
  whitelabelingEnabled?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate max on columns */
export type OrganizationConfiguration_Max_Fields = {
  __typename?: 'organizationConfiguration_max_fields';
  appBaseUrl?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  darkLogo?: Maybe<Scalars['String']>;
  darkLogoSmall?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  lightLogo?: Maybe<Scalars['String']>;
  lightLogoSmall?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['uuid']>;
  patientPortalBaseUrl?: Maybe<Scalars['String']>;
  privacyPolicyLink?: Maybe<Scalars['String']>;
  supportEmail?: Maybe<Scalars['String']>;
  termsAndConditionsLink?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  whitelabelDomain?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type OrganizationConfiguration_Min_Fields = {
  __typename?: 'organizationConfiguration_min_fields';
  appBaseUrl?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  darkLogo?: Maybe<Scalars['String']>;
  darkLogoSmall?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  lightLogo?: Maybe<Scalars['String']>;
  lightLogoSmall?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['uuid']>;
  patientPortalBaseUrl?: Maybe<Scalars['String']>;
  privacyPolicyLink?: Maybe<Scalars['String']>;
  supportEmail?: Maybe<Scalars['String']>;
  termsAndConditionsLink?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  whitelabelDomain?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "organizationConfiguration" */
export type OrganizationConfiguration_Mutation_Response = {
  __typename?: 'organizationConfiguration_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<OrganizationConfiguration>;
};

/** input type for inserting object relation for remote table "organizationConfiguration" */
export type OrganizationConfiguration_Obj_Rel_Insert_Input = {
  data: OrganizationConfiguration_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<OrganizationConfiguration_On_Conflict>;
};

/** on_conflict condition type for table "organizationConfiguration" */
export type OrganizationConfiguration_On_Conflict = {
  constraint: OrganizationConfiguration_Constraint;
  update_columns?: Array<OrganizationConfiguration_Update_Column>;
  where?: InputMaybe<OrganizationConfiguration_Bool_Exp>;
};

/** Ordering options when selecting data from "organizationConfiguration". */
export type OrganizationConfiguration_Order_By = {
  appBaseUrl?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  darkLogo?: InputMaybe<Order_By>;
  darkLogoSmall?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lightLogo?: InputMaybe<Order_By>;
  lightLogoSmall?: InputMaybe<Order_By>;
  organization?: InputMaybe<Organization_Order_By>;
  organizationId?: InputMaybe<Order_By>;
  patientPortalBaseUrl?: InputMaybe<Order_By>;
  privacyPolicyLink?: InputMaybe<Order_By>;
  supportEmail?: InputMaybe<Order_By>;
  termsAndConditionsLink?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  whitelabelDomain?: InputMaybe<Order_By>;
  whitelabelingEnabled?: InputMaybe<Order_By>;
};

/** primary key columns input for table: organizationConfiguration */
export type OrganizationConfiguration_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "organizationConfiguration" */
export enum OrganizationConfiguration_Select_Column {
  /** column name */
  AppBaseUrl = 'appBaseUrl',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DarkLogo = 'darkLogo',
  /** column name */
  DarkLogoSmall = 'darkLogoSmall',
  /** column name */
  Id = 'id',
  /** column name */
  LightLogo = 'lightLogo',
  /** column name */
  LightLogoSmall = 'lightLogoSmall',
  /** column name */
  OrganizationId = 'organizationId',
  /** column name */
  PatientPortalBaseUrl = 'patientPortalBaseUrl',
  /** column name */
  PrivacyPolicyLink = 'privacyPolicyLink',
  /** column name */
  SupportEmail = 'supportEmail',
  /** column name */
  TermsAndConditionsLink = 'termsAndConditionsLink',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WhitelabelDomain = 'whitelabelDomain',
  /** column name */
  WhitelabelingEnabled = 'whitelabelingEnabled'
}

/** input type for updating data in table "organizationConfiguration" */
export type OrganizationConfiguration_Set_Input = {
  appBaseUrl?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  darkLogo?: InputMaybe<Scalars['String']>;
  darkLogoSmall?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  lightLogo?: InputMaybe<Scalars['String']>;
  lightLogoSmall?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['uuid']>;
  patientPortalBaseUrl?: InputMaybe<Scalars['String']>;
  privacyPolicyLink?: InputMaybe<Scalars['String']>;
  supportEmail?: InputMaybe<Scalars['String']>;
  termsAndConditionsLink?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  whitelabelDomain?: InputMaybe<Scalars['String']>;
  whitelabelingEnabled?: InputMaybe<Scalars['Boolean']>;
};

/** Streaming cursor of the table "organizationConfiguration" */
export type OrganizationConfiguration_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: OrganizationConfiguration_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type OrganizationConfiguration_Stream_Cursor_Value_Input = {
  appBaseUrl?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  darkLogo?: InputMaybe<Scalars['String']>;
  darkLogoSmall?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  lightLogo?: InputMaybe<Scalars['String']>;
  lightLogoSmall?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['uuid']>;
  patientPortalBaseUrl?: InputMaybe<Scalars['String']>;
  privacyPolicyLink?: InputMaybe<Scalars['String']>;
  supportEmail?: InputMaybe<Scalars['String']>;
  termsAndConditionsLink?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  whitelabelDomain?: InputMaybe<Scalars['String']>;
  whitelabelingEnabled?: InputMaybe<Scalars['Boolean']>;
};

/** update columns of table "organizationConfiguration" */
export enum OrganizationConfiguration_Update_Column {
  /** column name */
  AppBaseUrl = 'appBaseUrl',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DarkLogo = 'darkLogo',
  /** column name */
  DarkLogoSmall = 'darkLogoSmall',
  /** column name */
  Id = 'id',
  /** column name */
  LightLogo = 'lightLogo',
  /** column name */
  LightLogoSmall = 'lightLogoSmall',
  /** column name */
  OrganizationId = 'organizationId',
  /** column name */
  PatientPortalBaseUrl = 'patientPortalBaseUrl',
  /** column name */
  PrivacyPolicyLink = 'privacyPolicyLink',
  /** column name */
  SupportEmail = 'supportEmail',
  /** column name */
  TermsAndConditionsLink = 'termsAndConditionsLink',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WhitelabelDomain = 'whitelabelDomain',
  /** column name */
  WhitelabelingEnabled = 'whitelabelingEnabled'
}

export type OrganizationConfiguration_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<OrganizationConfiguration_Set_Input>;
  /** filter the rows which have to be updated */
  where: OrganizationConfiguration_Bool_Exp;
};

/** aggregated selection of "organization" */
export type Organization_Aggregate = {
  __typename?: 'organization_aggregate';
  aggregate?: Maybe<Organization_Aggregate_Fields>;
  nodes: Array<Organization>;
};

/** aggregate fields of "organization" */
export type Organization_Aggregate_Fields = {
  __typename?: 'organization_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Organization_Max_Fields>;
  min?: Maybe<Organization_Min_Fields>;
};


/** aggregate fields of "organization" */
export type Organization_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Organization_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "organization". All fields are combined with a logical 'AND'. */
export type Organization_Bool_Exp = {
  _and?: InputMaybe<Array<Organization_Bool_Exp>>;
  _not?: InputMaybe<Organization_Bool_Exp>;
  _or?: InputMaybe<Array<Organization_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  organizationConfiguration?: InputMaybe<OrganizationConfiguration_Bool_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  workspaces?: InputMaybe<Workspace_Bool_Exp>;
  workspaces_aggregate?: InputMaybe<Workspace_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "organization" */
export enum Organization_Constraint {
  /** unique or primary key constraint on columns "id" */
  OrganizationPkey = 'organization_pkey'
}

/** input type for inserting data into table "organization" */
export type Organization_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  organizationConfiguration?: InputMaybe<OrganizationConfiguration_Obj_Rel_Insert_Input>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaces?: InputMaybe<Workspace_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Organization_Max_Fields = {
  __typename?: 'organization_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Organization_Min_Fields = {
  __typename?: 'organization_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "organization" */
export type Organization_Mutation_Response = {
  __typename?: 'organization_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Organization>;
};

/** input type for inserting object relation for remote table "organization" */
export type Organization_Obj_Rel_Insert_Input = {
  data: Organization_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Organization_On_Conflict>;
};

/** on_conflict condition type for table "organization" */
export type Organization_On_Conflict = {
  constraint: Organization_Constraint;
  update_columns?: Array<Organization_Update_Column>;
  where?: InputMaybe<Organization_Bool_Exp>;
};

/** Ordering options when selecting data from "organization". */
export type Organization_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organizationConfiguration?: InputMaybe<OrganizationConfiguration_Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  workspaces_aggregate?: InputMaybe<Workspace_Aggregate_Order_By>;
};

/** primary key columns input for table: organization */
export type Organization_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "organization" */
export enum Organization_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "organization" */
export type Organization_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "organization" */
export type Organization_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Organization_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Organization_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "organization" */
export enum Organization_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Organization_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Organization_Set_Input>;
  /** filter the rows which have to be updated */
  where: Organization_Bool_Exp;
};

/** columns and relationships of "package" */
export type Package = {
  __typename?: 'package';
  availableInStore?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['timestamptz'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  image?: Maybe<Scalars['String']>;
  isActive: Scalars['Boolean'];
  name: Scalars['String'];
  price: Scalars['Int'];
  quantity: Scalars['Int'];
  /** An object relationship */
  service: Service;
  serviceId: Scalars['uuid'];
  taxRate: Scalars['float8'];
  updatedAt: Scalars['timestamptz'];
  /** An object relationship */
  workspace: Workspace;
  workspaceId: Scalars['uuid'];
};

/** aggregated selection of "package" */
export type Package_Aggregate = {
  __typename?: 'package_aggregate';
  aggregate?: Maybe<Package_Aggregate_Fields>;
  nodes: Array<Package>;
};

/** aggregate fields of "package" */
export type Package_Aggregate_Fields = {
  __typename?: 'package_aggregate_fields';
  avg?: Maybe<Package_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Package_Max_Fields>;
  min?: Maybe<Package_Min_Fields>;
  stddev?: Maybe<Package_Stddev_Fields>;
  stddev_pop?: Maybe<Package_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Package_Stddev_Samp_Fields>;
  sum?: Maybe<Package_Sum_Fields>;
  var_pop?: Maybe<Package_Var_Pop_Fields>;
  var_samp?: Maybe<Package_Var_Samp_Fields>;
  variance?: Maybe<Package_Variance_Fields>;
};


/** aggregate fields of "package" */
export type Package_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Package_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Package_Avg_Fields = {
  __typename?: 'package_avg_fields';
  price?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  taxRate?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "package". All fields are combined with a logical 'AND'. */
export type Package_Bool_Exp = {
  _and?: InputMaybe<Array<Package_Bool_Exp>>;
  _not?: InputMaybe<Package_Bool_Exp>;
  _or?: InputMaybe<Array<Package_Bool_Exp>>;
  availableInStore?: InputMaybe<Boolean_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  image?: InputMaybe<String_Comparison_Exp>;
  isActive?: InputMaybe<Boolean_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  price?: InputMaybe<Int_Comparison_Exp>;
  quantity?: InputMaybe<Int_Comparison_Exp>;
  service?: InputMaybe<Service_Bool_Exp>;
  serviceId?: InputMaybe<Uuid_Comparison_Exp>;
  taxRate?: InputMaybe<Float8_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  workspace?: InputMaybe<Workspace_Bool_Exp>;
  workspaceId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "package" */
export enum Package_Constraint {
  /** unique or primary key constraint on columns "workspaceId", "name" */
  PackageNameWorkspaceIdKey = 'package_name_workspaceId_key',
  /** unique or primary key constraint on columns "id" */
  PackagePkey = 'package_pkey'
}

/** input type for incrementing numeric columns in table "package" */
export type Package_Inc_Input = {
  price?: InputMaybe<Scalars['Int']>;
  quantity?: InputMaybe<Scalars['Int']>;
  taxRate?: InputMaybe<Scalars['float8']>;
};

/** input type for inserting data into table "package" */
export type Package_Insert_Input = {
  availableInStore?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  image?: InputMaybe<Scalars['String']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['Int']>;
  quantity?: InputMaybe<Scalars['Int']>;
  service?: InputMaybe<Service_Obj_Rel_Insert_Input>;
  serviceId?: InputMaybe<Scalars['uuid']>;
  taxRate?: InputMaybe<Scalars['float8']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspace?: InputMaybe<Workspace_Obj_Rel_Insert_Input>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Package_Max_Fields = {
  __typename?: 'package_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  image?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
  serviceId?: Maybe<Scalars['uuid']>;
  taxRate?: Maybe<Scalars['float8']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Package_Min_Fields = {
  __typename?: 'package_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  image?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
  serviceId?: Maybe<Scalars['uuid']>;
  taxRate?: Maybe<Scalars['float8']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "package" */
export type Package_Mutation_Response = {
  __typename?: 'package_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Package>;
};

/** input type for inserting object relation for remote table "package" */
export type Package_Obj_Rel_Insert_Input = {
  data: Package_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Package_On_Conflict>;
};

/** on_conflict condition type for table "package" */
export type Package_On_Conflict = {
  constraint: Package_Constraint;
  update_columns?: Array<Package_Update_Column>;
  where?: InputMaybe<Package_Bool_Exp>;
};

/** Ordering options when selecting data from "package". */
export type Package_Order_By = {
  availableInStore?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  image?: InputMaybe<Order_By>;
  isActive?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  service?: InputMaybe<Service_Order_By>;
  serviceId?: InputMaybe<Order_By>;
  taxRate?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  workspace?: InputMaybe<Workspace_Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: package */
export type Package_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "package" */
export enum Package_Select_Column {
  /** column name */
  AvailableInStore = 'availableInStore',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Image = 'image',
  /** column name */
  IsActive = 'isActive',
  /** column name */
  Name = 'name',
  /** column name */
  Price = 'price',
  /** column name */
  Quantity = 'quantity',
  /** column name */
  ServiceId = 'serviceId',
  /** column name */
  TaxRate = 'taxRate',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WorkspaceId = 'workspaceId'
}

/** input type for updating data in table "package" */
export type Package_Set_Input = {
  availableInStore?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  image?: InputMaybe<Scalars['String']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['Int']>;
  quantity?: InputMaybe<Scalars['Int']>;
  serviceId?: InputMaybe<Scalars['uuid']>;
  taxRate?: InputMaybe<Scalars['float8']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Package_Stddev_Fields = {
  __typename?: 'package_stddev_fields';
  price?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  taxRate?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Package_Stddev_Pop_Fields = {
  __typename?: 'package_stddev_pop_fields';
  price?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  taxRate?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Package_Stddev_Samp_Fields = {
  __typename?: 'package_stddev_samp_fields';
  price?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  taxRate?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "package" */
export type Package_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Package_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Package_Stream_Cursor_Value_Input = {
  availableInStore?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  image?: InputMaybe<Scalars['String']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['Int']>;
  quantity?: InputMaybe<Scalars['Int']>;
  serviceId?: InputMaybe<Scalars['uuid']>;
  taxRate?: InputMaybe<Scalars['float8']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Package_Sum_Fields = {
  __typename?: 'package_sum_fields';
  price?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
  taxRate?: Maybe<Scalars['float8']>;
};

/** update columns of table "package" */
export enum Package_Update_Column {
  /** column name */
  AvailableInStore = 'availableInStore',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Image = 'image',
  /** column name */
  IsActive = 'isActive',
  /** column name */
  Name = 'name',
  /** column name */
  Price = 'price',
  /** column name */
  Quantity = 'quantity',
  /** column name */
  ServiceId = 'serviceId',
  /** column name */
  TaxRate = 'taxRate',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WorkspaceId = 'workspaceId'
}

export type Package_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Package_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Package_Set_Input>;
  /** filter the rows which have to be updated */
  where: Package_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Package_Var_Pop_Fields = {
  __typename?: 'package_var_pop_fields';
  price?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  taxRate?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Package_Var_Samp_Fields = {
  __typename?: 'package_var_samp_fields';
  price?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  taxRate?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Package_Variance_Fields = {
  __typename?: 'package_variance_fields';
  price?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  taxRate?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "patient" */
export type Patient = {
  __typename?: 'patient';
  /** An array relationship */
  activityLogs: Array<ActivityLog>;
  /** An aggregate relationship */
  activityLogs_aggregate: ActivityLog_Aggregate;
  /** An array relationship */
  appointments: Array<Appointment>;
  /** An aggregate relationship */
  appointments_aggregate: Appointment_Aggregate;
  attributes: PatientAttributes;
  balanceDue: Scalars['Int'];
  createdAt: Scalars['timestamptz'];
  dob?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  finixId?: Maybe<Scalars['String']>;
  firebaseUid?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  gender?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  importJobId?: Maybe<Scalars['uuid']>;
  /** An array relationship */
  invoices: Array<Invoice>;
  /** An aggregate relationship */
  invoices_aggregate: Invoice_Aggregate;
  language: Scalars['String'];
  lastName: Scalars['String'];
  /** An array relationship */
  leadFormEntries: Array<LeadFormEntry>;
  /** An aggregate relationship */
  leadFormEntries_aggregate: LeadFormEntry_Aggregate;
  middleName?: Maybe<Scalars['String']>;
  /** An array relationship */
  notes: Array<Note>;
  /** An aggregate relationship */
  notes_aggregate: Note_Aggregate;
  /** An array relationship */
  patientAddresses: Array<PatientAddress>;
  /** An aggregate relationship */
  patientAddresses_aggregate: PatientAddress_Aggregate;
  /** An array relationship */
  patientMemberships: Array<PatientMembership>;
  /** An aggregate relationship */
  patientMemberships_aggregate: PatientMembership_Aggregate;
  /** An array relationship */
  patientTags: Array<PatientTags>;
  /** An aggregate relationship */
  patientTags_aggregate: PatientTags_Aggregate;
  /** An array relationship */
  patientWallets: Array<PatientWallet>;
  /** An aggregate relationship */
  patientWallets_aggregate: PatientWallet_Aggregate;
  /** An array relationship */
  patientWorkspaces: Array<PatientWorkspace>;
  /** An aggregate relationship */
  patientWorkspaces_aggregate: PatientWorkspace_Aggregate;
  phoneNumber?: Maybe<Scalars['String']>;
  /** An array relationship */
  photos: Array<Photo>;
  /** An aggregate relationship */
  photos_aggregate: Photo_Aggregate;
  /** An object relationship */
  profilePicture?: Maybe<Photo>;
  profilePictureId?: Maybe<Scalars['uuid']>;
  /** An array relationship */
  rewardProgramEnrollments: Array<RewardProgramEnrollment>;
  /** An aggregate relationship */
  rewardProgramEnrollments_aggregate: RewardProgramEnrollment_Aggregate;
  serviceHistory?: Maybe<PatientServiceHistory>;
  status: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
};


/** columns and relationships of "patient" */
export type PatientActivityLogsArgs = {
  distinct_on?: InputMaybe<Array<ActivityLog_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ActivityLog_Order_By>>;
  where?: InputMaybe<ActivityLog_Bool_Exp>;
};


/** columns and relationships of "patient" */
export type PatientActivityLogs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ActivityLog_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ActivityLog_Order_By>>;
  where?: InputMaybe<ActivityLog_Bool_Exp>;
};


/** columns and relationships of "patient" */
export type PatientAppointmentsArgs = {
  distinct_on?: InputMaybe<Array<Appointment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Appointment_Order_By>>;
  where?: InputMaybe<Appointment_Bool_Exp>;
};


/** columns and relationships of "patient" */
export type PatientAppointments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Appointment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Appointment_Order_By>>;
  where?: InputMaybe<Appointment_Bool_Exp>;
};


/** columns and relationships of "patient" */
export type PatientInvoicesArgs = {
  distinct_on?: InputMaybe<Array<Invoice_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Invoice_Order_By>>;
  where?: InputMaybe<Invoice_Bool_Exp>;
};


/** columns and relationships of "patient" */
export type PatientInvoices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Invoice_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Invoice_Order_By>>;
  where?: InputMaybe<Invoice_Bool_Exp>;
};


/** columns and relationships of "patient" */
export type PatientLeadFormEntriesArgs = {
  distinct_on?: InputMaybe<Array<LeadFormEntry_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<LeadFormEntry_Order_By>>;
  where?: InputMaybe<LeadFormEntry_Bool_Exp>;
};


/** columns and relationships of "patient" */
export type PatientLeadFormEntries_AggregateArgs = {
  distinct_on?: InputMaybe<Array<LeadFormEntry_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<LeadFormEntry_Order_By>>;
  where?: InputMaybe<LeadFormEntry_Bool_Exp>;
};


/** columns and relationships of "patient" */
export type PatientNotesArgs = {
  distinct_on?: InputMaybe<Array<Note_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Note_Order_By>>;
  where?: InputMaybe<Note_Bool_Exp>;
};


/** columns and relationships of "patient" */
export type PatientNotes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Note_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Note_Order_By>>;
  where?: InputMaybe<Note_Bool_Exp>;
};


/** columns and relationships of "patient" */
export type PatientPatientAddressesArgs = {
  distinct_on?: InputMaybe<Array<PatientAddress_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PatientAddress_Order_By>>;
  where?: InputMaybe<PatientAddress_Bool_Exp>;
};


/** columns and relationships of "patient" */
export type PatientPatientAddresses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<PatientAddress_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PatientAddress_Order_By>>;
  where?: InputMaybe<PatientAddress_Bool_Exp>;
};


/** columns and relationships of "patient" */
export type PatientPatientMembershipsArgs = {
  distinct_on?: InputMaybe<Array<PatientMembership_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PatientMembership_Order_By>>;
  where?: InputMaybe<PatientMembership_Bool_Exp>;
};


/** columns and relationships of "patient" */
export type PatientPatientMemberships_AggregateArgs = {
  distinct_on?: InputMaybe<Array<PatientMembership_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PatientMembership_Order_By>>;
  where?: InputMaybe<PatientMembership_Bool_Exp>;
};


/** columns and relationships of "patient" */
export type PatientPatientTagsArgs = {
  distinct_on?: InputMaybe<Array<PatientTags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PatientTags_Order_By>>;
  where?: InputMaybe<PatientTags_Bool_Exp>;
};


/** columns and relationships of "patient" */
export type PatientPatientTags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<PatientTags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PatientTags_Order_By>>;
  where?: InputMaybe<PatientTags_Bool_Exp>;
};


/** columns and relationships of "patient" */
export type PatientPatientWalletsArgs = {
  distinct_on?: InputMaybe<Array<PatientWallet_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PatientWallet_Order_By>>;
  where?: InputMaybe<PatientWallet_Bool_Exp>;
};


/** columns and relationships of "patient" */
export type PatientPatientWallets_AggregateArgs = {
  distinct_on?: InputMaybe<Array<PatientWallet_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PatientWallet_Order_By>>;
  where?: InputMaybe<PatientWallet_Bool_Exp>;
};


/** columns and relationships of "patient" */
export type PatientPatientWorkspacesArgs = {
  distinct_on?: InputMaybe<Array<PatientWorkspace_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PatientWorkspace_Order_By>>;
  where?: InputMaybe<PatientWorkspace_Bool_Exp>;
};


/** columns and relationships of "patient" */
export type PatientPatientWorkspaces_AggregateArgs = {
  distinct_on?: InputMaybe<Array<PatientWorkspace_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PatientWorkspace_Order_By>>;
  where?: InputMaybe<PatientWorkspace_Bool_Exp>;
};


/** columns and relationships of "patient" */
export type PatientPhotosArgs = {
  distinct_on?: InputMaybe<Array<Photo_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Photo_Order_By>>;
  where?: InputMaybe<Photo_Bool_Exp>;
};


/** columns and relationships of "patient" */
export type PatientPhotos_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Photo_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Photo_Order_By>>;
  where?: InputMaybe<Photo_Bool_Exp>;
};


/** columns and relationships of "patient" */
export type PatientRewardProgramEnrollmentsArgs = {
  distinct_on?: InputMaybe<Array<RewardProgramEnrollment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RewardProgramEnrollment_Order_By>>;
  where?: InputMaybe<RewardProgramEnrollment_Bool_Exp>;
};


/** columns and relationships of "patient" */
export type PatientRewardProgramEnrollments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<RewardProgramEnrollment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RewardProgramEnrollment_Order_By>>;
  where?: InputMaybe<RewardProgramEnrollment_Bool_Exp>;
};

/** columns and relationships of "patientAddress" */
export type PatientAddress = {
  __typename?: 'patientAddress';
  /** An object relationship */
  address: Address;
  addressId: Scalars['uuid'];
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  isDefault: Scalars['Boolean'];
  /** An object relationship */
  patient: Patient;
  patientId: Scalars['uuid'];
  status: Scalars['String'];
  type: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  workspaceId: Scalars['uuid'];
};

/** aggregated selection of "patientAddress" */
export type PatientAddress_Aggregate = {
  __typename?: 'patientAddress_aggregate';
  aggregate?: Maybe<PatientAddress_Aggregate_Fields>;
  nodes: Array<PatientAddress>;
};

export type PatientAddress_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<PatientAddress_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<PatientAddress_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<PatientAddress_Aggregate_Bool_Exp_Count>;
};

export type PatientAddress_Aggregate_Bool_Exp_Bool_And = {
  arguments: PatientAddress_Select_Column_PatientAddress_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<PatientAddress_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type PatientAddress_Aggregate_Bool_Exp_Bool_Or = {
  arguments: PatientAddress_Select_Column_PatientAddress_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<PatientAddress_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type PatientAddress_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<PatientAddress_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<PatientAddress_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "patientAddress" */
export type PatientAddress_Aggregate_Fields = {
  __typename?: 'patientAddress_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<PatientAddress_Max_Fields>;
  min?: Maybe<PatientAddress_Min_Fields>;
};


/** aggregate fields of "patientAddress" */
export type PatientAddress_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<PatientAddress_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "patientAddress" */
export type PatientAddress_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<PatientAddress_Max_Order_By>;
  min?: InputMaybe<PatientAddress_Min_Order_By>;
};

/** input type for inserting array relation for remote table "patientAddress" */
export type PatientAddress_Arr_Rel_Insert_Input = {
  data: Array<PatientAddress_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<PatientAddress_On_Conflict>;
};

/** Boolean expression to filter rows from the table "patientAddress". All fields are combined with a logical 'AND'. */
export type PatientAddress_Bool_Exp = {
  _and?: InputMaybe<Array<PatientAddress_Bool_Exp>>;
  _not?: InputMaybe<PatientAddress_Bool_Exp>;
  _or?: InputMaybe<Array<PatientAddress_Bool_Exp>>;
  address?: InputMaybe<Address_Bool_Exp>;
  addressId?: InputMaybe<Uuid_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  isDefault?: InputMaybe<Boolean_Comparison_Exp>;
  patient?: InputMaybe<Patient_Bool_Exp>;
  patientId?: InputMaybe<Uuid_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  workspaceId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "patientAddress" */
export enum PatientAddress_Constraint {
  /** unique or primary key constraint on columns "id" */
  PatientAddressPkey = 'patientAddress_pkey'
}

/** input type for inserting data into table "patientAddress" */
export type PatientAddress_Insert_Input = {
  address?: InputMaybe<Address_Obj_Rel_Insert_Input>;
  addressId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  isDefault?: InputMaybe<Scalars['Boolean']>;
  patient?: InputMaybe<Patient_Obj_Rel_Insert_Input>;
  patientId?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type PatientAddress_Max_Fields = {
  __typename?: 'patientAddress_max_fields';
  addressId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  patientId?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "patientAddress" */
export type PatientAddress_Max_Order_By = {
  addressId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patientId?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type PatientAddress_Min_Fields = {
  __typename?: 'patientAddress_min_fields';
  addressId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  patientId?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "patientAddress" */
export type PatientAddress_Min_Order_By = {
  addressId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patientId?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "patientAddress" */
export type PatientAddress_Mutation_Response = {
  __typename?: 'patientAddress_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<PatientAddress>;
};

/** on_conflict condition type for table "patientAddress" */
export type PatientAddress_On_Conflict = {
  constraint: PatientAddress_Constraint;
  update_columns?: Array<PatientAddress_Update_Column>;
  where?: InputMaybe<PatientAddress_Bool_Exp>;
};

/** Ordering options when selecting data from "patientAddress". */
export type PatientAddress_Order_By = {
  address?: InputMaybe<Address_Order_By>;
  addressId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  isDefault?: InputMaybe<Order_By>;
  patient?: InputMaybe<Patient_Order_By>;
  patientId?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: patientAddress */
export type PatientAddress_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "patientAddress" */
export enum PatientAddress_Select_Column {
  /** column name */
  AddressId = 'addressId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  IsDefault = 'isDefault',
  /** column name */
  PatientId = 'patientId',
  /** column name */
  Status = 'status',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WorkspaceId = 'workspaceId'
}

/** select "patientAddress_aggregate_bool_exp_bool_and_arguments_columns" columns of table "patientAddress" */
export enum PatientAddress_Select_Column_PatientAddress_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsDefault = 'isDefault'
}

/** select "patientAddress_aggregate_bool_exp_bool_or_arguments_columns" columns of table "patientAddress" */
export enum PatientAddress_Select_Column_PatientAddress_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsDefault = 'isDefault'
}

/** input type for updating data in table "patientAddress" */
export type PatientAddress_Set_Input = {
  addressId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  isDefault?: InputMaybe<Scalars['Boolean']>;
  patientId?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "patientAddress" */
export type PatientAddress_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: PatientAddress_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type PatientAddress_Stream_Cursor_Value_Input = {
  addressId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  isDefault?: InputMaybe<Scalars['Boolean']>;
  patientId?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "patientAddress" */
export enum PatientAddress_Update_Column {
  /** column name */
  AddressId = 'addressId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  IsDefault = 'isDefault',
  /** column name */
  PatientId = 'patientId',
  /** column name */
  Status = 'status',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WorkspaceId = 'workspaceId'
}

export type PatientAddress_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<PatientAddress_Set_Input>;
  /** filter the rows which have to be updated */
  where: PatientAddress_Bool_Exp;
};

/** columns and relationships of "patientConsent" */
export type PatientConsent = {
  __typename?: 'patientConsent';
  appointmentId: Scalars['uuid'];
  /** An object relationship */
  consent: Consent;
  consentId: Scalars['uuid'];
  createdAt: Scalars['timestamptz'];
  date: Scalars['timestamptz'];
  /** An object relationship */
  document?: Maybe<Document>;
  documentId?: Maybe<Scalars['uuid']>;
  id: Scalars['uuid'];
  name: Scalars['String'];
  patientId: Scalars['uuid'];
  providerDate?: Maybe<Scalars['timestamptz']>;
  providerName?: Maybe<Scalars['String']>;
  /** An object relationship */
  providerSignature?: Maybe<Signature>;
  providerSignatureId?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  signature?: Maybe<Signature>;
  signatureId?: Maybe<Scalars['uuid']>;
  surveyJSResponseJSON?: Maybe<Scalars['jsonb']>;
  updatedAt: Scalars['timestamptz'];
  witnessDate?: Maybe<Scalars['timestamptz']>;
  witnessName?: Maybe<Scalars['String']>;
  /** An object relationship */
  witnessSignature?: Maybe<Signature>;
  witnessSignatureId?: Maybe<Scalars['uuid']>;
};


/** columns and relationships of "patientConsent" */
export type PatientConsentSurveyJsResponseJsonArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "patientConsent" */
export type PatientConsent_Aggregate = {
  __typename?: 'patientConsent_aggregate';
  aggregate?: Maybe<PatientConsent_Aggregate_Fields>;
  nodes: Array<PatientConsent>;
};

export type PatientConsent_Aggregate_Bool_Exp = {
  count?: InputMaybe<PatientConsent_Aggregate_Bool_Exp_Count>;
};

export type PatientConsent_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<PatientConsent_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<PatientConsent_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "patientConsent" */
export type PatientConsent_Aggregate_Fields = {
  __typename?: 'patientConsent_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<PatientConsent_Max_Fields>;
  min?: Maybe<PatientConsent_Min_Fields>;
};


/** aggregate fields of "patientConsent" */
export type PatientConsent_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<PatientConsent_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "patientConsent" */
export type PatientConsent_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<PatientConsent_Max_Order_By>;
  min?: InputMaybe<PatientConsent_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type PatientConsent_Append_Input = {
  surveyJSResponseJSON?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "patientConsent" */
export type PatientConsent_Arr_Rel_Insert_Input = {
  data: Array<PatientConsent_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<PatientConsent_On_Conflict>;
};

/** Boolean expression to filter rows from the table "patientConsent". All fields are combined with a logical 'AND'. */
export type PatientConsent_Bool_Exp = {
  _and?: InputMaybe<Array<PatientConsent_Bool_Exp>>;
  _not?: InputMaybe<PatientConsent_Bool_Exp>;
  _or?: InputMaybe<Array<PatientConsent_Bool_Exp>>;
  appointmentId?: InputMaybe<Uuid_Comparison_Exp>;
  consent?: InputMaybe<Consent_Bool_Exp>;
  consentId?: InputMaybe<Uuid_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  date?: InputMaybe<Timestamptz_Comparison_Exp>;
  document?: InputMaybe<Document_Bool_Exp>;
  documentId?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  patientId?: InputMaybe<Uuid_Comparison_Exp>;
  providerDate?: InputMaybe<Timestamptz_Comparison_Exp>;
  providerName?: InputMaybe<String_Comparison_Exp>;
  providerSignature?: InputMaybe<Signature_Bool_Exp>;
  providerSignatureId?: InputMaybe<Uuid_Comparison_Exp>;
  signature?: InputMaybe<Signature_Bool_Exp>;
  signatureId?: InputMaybe<Uuid_Comparison_Exp>;
  surveyJSResponseJSON?: InputMaybe<Jsonb_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  witnessDate?: InputMaybe<Timestamptz_Comparison_Exp>;
  witnessName?: InputMaybe<String_Comparison_Exp>;
  witnessSignature?: InputMaybe<Signature_Bool_Exp>;
  witnessSignatureId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "patientConsent" */
export enum PatientConsent_Constraint {
  /** unique or primary key constraint on columns "id" */
  PatientConsentPkey = 'patientConsent_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type PatientConsent_Delete_At_Path_Input = {
  surveyJSResponseJSON?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type PatientConsent_Delete_Elem_Input = {
  surveyJSResponseJSON?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type PatientConsent_Delete_Key_Input = {
  surveyJSResponseJSON?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "patientConsent" */
export type PatientConsent_Insert_Input = {
  appointmentId?: InputMaybe<Scalars['uuid']>;
  consent?: InputMaybe<Consent_Obj_Rel_Insert_Input>;
  consentId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  date?: InputMaybe<Scalars['timestamptz']>;
  document?: InputMaybe<Document_Obj_Rel_Insert_Input>;
  documentId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  patientId?: InputMaybe<Scalars['uuid']>;
  providerDate?: InputMaybe<Scalars['timestamptz']>;
  providerName?: InputMaybe<Scalars['String']>;
  providerSignature?: InputMaybe<Signature_Obj_Rel_Insert_Input>;
  providerSignatureId?: InputMaybe<Scalars['uuid']>;
  signature?: InputMaybe<Signature_Obj_Rel_Insert_Input>;
  signatureId?: InputMaybe<Scalars['uuid']>;
  surveyJSResponseJSON?: InputMaybe<Scalars['jsonb']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  witnessDate?: InputMaybe<Scalars['timestamptz']>;
  witnessName?: InputMaybe<Scalars['String']>;
  witnessSignature?: InputMaybe<Signature_Obj_Rel_Insert_Input>;
  witnessSignatureId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type PatientConsent_Max_Fields = {
  __typename?: 'patientConsent_max_fields';
  appointmentId?: Maybe<Scalars['uuid']>;
  consentId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  date?: Maybe<Scalars['timestamptz']>;
  documentId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  patientId?: Maybe<Scalars['uuid']>;
  providerDate?: Maybe<Scalars['timestamptz']>;
  providerName?: Maybe<Scalars['String']>;
  providerSignatureId?: Maybe<Scalars['uuid']>;
  signatureId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  witnessDate?: Maybe<Scalars['timestamptz']>;
  witnessName?: Maybe<Scalars['String']>;
  witnessSignatureId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "patientConsent" */
export type PatientConsent_Max_Order_By = {
  appointmentId?: InputMaybe<Order_By>;
  consentId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
  documentId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  patientId?: InputMaybe<Order_By>;
  providerDate?: InputMaybe<Order_By>;
  providerName?: InputMaybe<Order_By>;
  providerSignatureId?: InputMaybe<Order_By>;
  signatureId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  witnessDate?: InputMaybe<Order_By>;
  witnessName?: InputMaybe<Order_By>;
  witnessSignatureId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type PatientConsent_Min_Fields = {
  __typename?: 'patientConsent_min_fields';
  appointmentId?: Maybe<Scalars['uuid']>;
  consentId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  date?: Maybe<Scalars['timestamptz']>;
  documentId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  patientId?: Maybe<Scalars['uuid']>;
  providerDate?: Maybe<Scalars['timestamptz']>;
  providerName?: Maybe<Scalars['String']>;
  providerSignatureId?: Maybe<Scalars['uuid']>;
  signatureId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  witnessDate?: Maybe<Scalars['timestamptz']>;
  witnessName?: Maybe<Scalars['String']>;
  witnessSignatureId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "patientConsent" */
export type PatientConsent_Min_Order_By = {
  appointmentId?: InputMaybe<Order_By>;
  consentId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
  documentId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  patientId?: InputMaybe<Order_By>;
  providerDate?: InputMaybe<Order_By>;
  providerName?: InputMaybe<Order_By>;
  providerSignatureId?: InputMaybe<Order_By>;
  signatureId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  witnessDate?: InputMaybe<Order_By>;
  witnessName?: InputMaybe<Order_By>;
  witnessSignatureId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "patientConsent" */
export type PatientConsent_Mutation_Response = {
  __typename?: 'patientConsent_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<PatientConsent>;
};

/** on_conflict condition type for table "patientConsent" */
export type PatientConsent_On_Conflict = {
  constraint: PatientConsent_Constraint;
  update_columns?: Array<PatientConsent_Update_Column>;
  where?: InputMaybe<PatientConsent_Bool_Exp>;
};

/** Ordering options when selecting data from "patientConsent". */
export type PatientConsent_Order_By = {
  appointmentId?: InputMaybe<Order_By>;
  consent?: InputMaybe<Consent_Order_By>;
  consentId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
  document?: InputMaybe<Document_Order_By>;
  documentId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  patientId?: InputMaybe<Order_By>;
  providerDate?: InputMaybe<Order_By>;
  providerName?: InputMaybe<Order_By>;
  providerSignature?: InputMaybe<Signature_Order_By>;
  providerSignatureId?: InputMaybe<Order_By>;
  signature?: InputMaybe<Signature_Order_By>;
  signatureId?: InputMaybe<Order_By>;
  surveyJSResponseJSON?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  witnessDate?: InputMaybe<Order_By>;
  witnessName?: InputMaybe<Order_By>;
  witnessSignature?: InputMaybe<Signature_Order_By>;
  witnessSignatureId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: patientConsent */
export type PatientConsent_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type PatientConsent_Prepend_Input = {
  surveyJSResponseJSON?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "patientConsent" */
export enum PatientConsent_Select_Column {
  /** column name */
  AppointmentId = 'appointmentId',
  /** column name */
  ConsentId = 'consentId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Date = 'date',
  /** column name */
  DocumentId = 'documentId',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  PatientId = 'patientId',
  /** column name */
  ProviderDate = 'providerDate',
  /** column name */
  ProviderName = 'providerName',
  /** column name */
  ProviderSignatureId = 'providerSignatureId',
  /** column name */
  SignatureId = 'signatureId',
  /** column name */
  SurveyJsResponseJson = 'surveyJSResponseJSON',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WitnessDate = 'witnessDate',
  /** column name */
  WitnessName = 'witnessName',
  /** column name */
  WitnessSignatureId = 'witnessSignatureId'
}

/** input type for updating data in table "patientConsent" */
export type PatientConsent_Set_Input = {
  appointmentId?: InputMaybe<Scalars['uuid']>;
  consentId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  date?: InputMaybe<Scalars['timestamptz']>;
  documentId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  patientId?: InputMaybe<Scalars['uuid']>;
  providerDate?: InputMaybe<Scalars['timestamptz']>;
  providerName?: InputMaybe<Scalars['String']>;
  providerSignatureId?: InputMaybe<Scalars['uuid']>;
  signatureId?: InputMaybe<Scalars['uuid']>;
  surveyJSResponseJSON?: InputMaybe<Scalars['jsonb']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  witnessDate?: InputMaybe<Scalars['timestamptz']>;
  witnessName?: InputMaybe<Scalars['String']>;
  witnessSignatureId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "patientConsent" */
export type PatientConsent_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: PatientConsent_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type PatientConsent_Stream_Cursor_Value_Input = {
  appointmentId?: InputMaybe<Scalars['uuid']>;
  consentId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  date?: InputMaybe<Scalars['timestamptz']>;
  documentId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  patientId?: InputMaybe<Scalars['uuid']>;
  providerDate?: InputMaybe<Scalars['timestamptz']>;
  providerName?: InputMaybe<Scalars['String']>;
  providerSignatureId?: InputMaybe<Scalars['uuid']>;
  signatureId?: InputMaybe<Scalars['uuid']>;
  surveyJSResponseJSON?: InputMaybe<Scalars['jsonb']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  witnessDate?: InputMaybe<Scalars['timestamptz']>;
  witnessName?: InputMaybe<Scalars['String']>;
  witnessSignatureId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "patientConsent" */
export enum PatientConsent_Update_Column {
  /** column name */
  AppointmentId = 'appointmentId',
  /** column name */
  ConsentId = 'consentId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Date = 'date',
  /** column name */
  DocumentId = 'documentId',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  PatientId = 'patientId',
  /** column name */
  ProviderDate = 'providerDate',
  /** column name */
  ProviderName = 'providerName',
  /** column name */
  ProviderSignatureId = 'providerSignatureId',
  /** column name */
  SignatureId = 'signatureId',
  /** column name */
  SurveyJsResponseJson = 'surveyJSResponseJSON',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WitnessDate = 'witnessDate',
  /** column name */
  WitnessName = 'witnessName',
  /** column name */
  WitnessSignatureId = 'witnessSignatureId'
}

export type PatientConsent_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<PatientConsent_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<PatientConsent_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<PatientConsent_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<PatientConsent_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<PatientConsent_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<PatientConsent_Set_Input>;
  /** filter the rows which have to be updated */
  where: PatientConsent_Bool_Exp;
};

/** columns and relationships of "patientConversation" */
export type PatientConversation = {
  __typename?: 'patientConversation';
  category?: Maybe<Scalars['String']>;
  createdAt: Scalars['timestamptz'];
  hasUnreadMessages?: Maybe<Scalars['Boolean']>;
  id: Scalars['uuid'];
  lastPatientMessageReceived?: Maybe<Scalars['timestamptz']>;
  lastUserMessageSent?: Maybe<Scalars['timestamptz']>;
  participantIdentityOverride?: Maybe<Scalars['String']>;
  patientId: Scalars['uuid'];
  /** An object relationship */
  patientWorkspace: PatientWorkspace;
  patientWorkspaceId: Scalars['uuid'];
  twilioConversationSid: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  workspaceId: Scalars['uuid'];
};

/** aggregated selection of "patientConversation" */
export type PatientConversation_Aggregate = {
  __typename?: 'patientConversation_aggregate';
  aggregate?: Maybe<PatientConversation_Aggregate_Fields>;
  nodes: Array<PatientConversation>;
};

/** aggregate fields of "patientConversation" */
export type PatientConversation_Aggregate_Fields = {
  __typename?: 'patientConversation_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<PatientConversation_Max_Fields>;
  min?: Maybe<PatientConversation_Min_Fields>;
};


/** aggregate fields of "patientConversation" */
export type PatientConversation_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<PatientConversation_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "patientConversation". All fields are combined with a logical 'AND'. */
export type PatientConversation_Bool_Exp = {
  _and?: InputMaybe<Array<PatientConversation_Bool_Exp>>;
  _not?: InputMaybe<PatientConversation_Bool_Exp>;
  _or?: InputMaybe<Array<PatientConversation_Bool_Exp>>;
  category?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  hasUnreadMessages?: InputMaybe<Boolean_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  lastPatientMessageReceived?: InputMaybe<Timestamptz_Comparison_Exp>;
  lastUserMessageSent?: InputMaybe<Timestamptz_Comparison_Exp>;
  participantIdentityOverride?: InputMaybe<String_Comparison_Exp>;
  patientId?: InputMaybe<Uuid_Comparison_Exp>;
  patientWorkspace?: InputMaybe<PatientWorkspace_Bool_Exp>;
  patientWorkspaceId?: InputMaybe<Uuid_Comparison_Exp>;
  twilioConversationSid?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  workspaceId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "patientConversation" */
export enum PatientConversation_Constraint {
  /** unique or primary key constraint on columns "id" */
  PatientConversationPkey = 'patientConversation_pkey'
}

/** input type for inserting data into table "patientConversation" */
export type PatientConversation_Insert_Input = {
  category?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  hasUnreadMessages?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['uuid']>;
  lastPatientMessageReceived?: InputMaybe<Scalars['timestamptz']>;
  lastUserMessageSent?: InputMaybe<Scalars['timestamptz']>;
  participantIdentityOverride?: InputMaybe<Scalars['String']>;
  patientId?: InputMaybe<Scalars['uuid']>;
  patientWorkspace?: InputMaybe<PatientWorkspace_Obj_Rel_Insert_Input>;
  patientWorkspaceId?: InputMaybe<Scalars['uuid']>;
  twilioConversationSid?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type PatientConversation_Max_Fields = {
  __typename?: 'patientConversation_max_fields';
  category?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  lastPatientMessageReceived?: Maybe<Scalars['timestamptz']>;
  lastUserMessageSent?: Maybe<Scalars['timestamptz']>;
  participantIdentityOverride?: Maybe<Scalars['String']>;
  patientId?: Maybe<Scalars['uuid']>;
  patientWorkspaceId?: Maybe<Scalars['uuid']>;
  twilioConversationSid?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type PatientConversation_Min_Fields = {
  __typename?: 'patientConversation_min_fields';
  category?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  lastPatientMessageReceived?: Maybe<Scalars['timestamptz']>;
  lastUserMessageSent?: Maybe<Scalars['timestamptz']>;
  participantIdentityOverride?: Maybe<Scalars['String']>;
  patientId?: Maybe<Scalars['uuid']>;
  patientWorkspaceId?: Maybe<Scalars['uuid']>;
  twilioConversationSid?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "patientConversation" */
export type PatientConversation_Mutation_Response = {
  __typename?: 'patientConversation_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<PatientConversation>;
};

/** on_conflict condition type for table "patientConversation" */
export type PatientConversation_On_Conflict = {
  constraint: PatientConversation_Constraint;
  update_columns?: Array<PatientConversation_Update_Column>;
  where?: InputMaybe<PatientConversation_Bool_Exp>;
};

/** Ordering options when selecting data from "patientConversation". */
export type PatientConversation_Order_By = {
  category?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  hasUnreadMessages?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lastPatientMessageReceived?: InputMaybe<Order_By>;
  lastUserMessageSent?: InputMaybe<Order_By>;
  participantIdentityOverride?: InputMaybe<Order_By>;
  patientId?: InputMaybe<Order_By>;
  patientWorkspace?: InputMaybe<PatientWorkspace_Order_By>;
  patientWorkspaceId?: InputMaybe<Order_By>;
  twilioConversationSid?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: patientConversation */
export type PatientConversation_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "patientConversation" */
export enum PatientConversation_Select_Column {
  /** column name */
  Category = 'category',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  HasUnreadMessages = 'hasUnreadMessages',
  /** column name */
  Id = 'id',
  /** column name */
  LastPatientMessageReceived = 'lastPatientMessageReceived',
  /** column name */
  LastUserMessageSent = 'lastUserMessageSent',
  /** column name */
  ParticipantIdentityOverride = 'participantIdentityOverride',
  /** column name */
  PatientId = 'patientId',
  /** column name */
  PatientWorkspaceId = 'patientWorkspaceId',
  /** column name */
  TwilioConversationSid = 'twilioConversationSid',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WorkspaceId = 'workspaceId'
}

/** input type for updating data in table "patientConversation" */
export type PatientConversation_Set_Input = {
  category?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  hasUnreadMessages?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['uuid']>;
  lastPatientMessageReceived?: InputMaybe<Scalars['timestamptz']>;
  lastUserMessageSent?: InputMaybe<Scalars['timestamptz']>;
  participantIdentityOverride?: InputMaybe<Scalars['String']>;
  patientId?: InputMaybe<Scalars['uuid']>;
  patientWorkspaceId?: InputMaybe<Scalars['uuid']>;
  twilioConversationSid?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "patientConversation" */
export type PatientConversation_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: PatientConversation_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type PatientConversation_Stream_Cursor_Value_Input = {
  category?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  hasUnreadMessages?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['uuid']>;
  lastPatientMessageReceived?: InputMaybe<Scalars['timestamptz']>;
  lastUserMessageSent?: InputMaybe<Scalars['timestamptz']>;
  participantIdentityOverride?: InputMaybe<Scalars['String']>;
  patientId?: InputMaybe<Scalars['uuid']>;
  patientWorkspaceId?: InputMaybe<Scalars['uuid']>;
  twilioConversationSid?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "patientConversation" */
export enum PatientConversation_Update_Column {
  /** column name */
  Category = 'category',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  HasUnreadMessages = 'hasUnreadMessages',
  /** column name */
  Id = 'id',
  /** column name */
  LastPatientMessageReceived = 'lastPatientMessageReceived',
  /** column name */
  LastUserMessageSent = 'lastUserMessageSent',
  /** column name */
  ParticipantIdentityOverride = 'participantIdentityOverride',
  /** column name */
  PatientId = 'patientId',
  /** column name */
  PatientWorkspaceId = 'patientWorkspaceId',
  /** column name */
  TwilioConversationSid = 'twilioConversationSid',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WorkspaceId = 'workspaceId'
}

export type PatientConversation_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<PatientConversation_Set_Input>;
  /** filter the rows which have to be updated */
  where: PatientConversation_Bool_Exp;
};

/** columns and relationships of "patientFlowsheetData" */
export type PatientFlowsheetData = {
  __typename?: 'patientFlowsheetData';
  /** An object relationship */
  appointment?: Maybe<Appointment>;
  appointmentId?: Maybe<Scalars['uuid']>;
  createdAt: Scalars['timestamptz'];
  /** An object relationship */
  flowsheet: Flowsheet;
  flowsheetId: Scalars['uuid'];
  flowsheetParameterId: Scalars['uuid'];
  id: Scalars['uuid'];
  /** An object relationship */
  parameter: FlowsheetParameter;
  /** An object relationship */
  patient: PatientWorkspace;
  patientFlowsheetRowId: Scalars['uuid'];
  patientWorkspaceId: Scalars['uuid'];
  recordedAt: Scalars['timestamptz'];
  /** An object relationship */
  row: PatientFlowsheetRow;
  updatedAt: Scalars['timestamptz'];
  value: Scalars['String'];
  /** An object relationship */
  workspace: Workspace;
  workspaceId: Scalars['uuid'];
};

/** aggregated selection of "patientFlowsheetData" */
export type PatientFlowsheetData_Aggregate = {
  __typename?: 'patientFlowsheetData_aggregate';
  aggregate?: Maybe<PatientFlowsheetData_Aggregate_Fields>;
  nodes: Array<PatientFlowsheetData>;
};

export type PatientFlowsheetData_Aggregate_Bool_Exp = {
  count?: InputMaybe<PatientFlowsheetData_Aggregate_Bool_Exp_Count>;
};

export type PatientFlowsheetData_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<PatientFlowsheetData_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<PatientFlowsheetData_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "patientFlowsheetData" */
export type PatientFlowsheetData_Aggregate_Fields = {
  __typename?: 'patientFlowsheetData_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<PatientFlowsheetData_Max_Fields>;
  min?: Maybe<PatientFlowsheetData_Min_Fields>;
};


/** aggregate fields of "patientFlowsheetData" */
export type PatientFlowsheetData_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<PatientFlowsheetData_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "patientFlowsheetData" */
export type PatientFlowsheetData_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<PatientFlowsheetData_Max_Order_By>;
  min?: InputMaybe<PatientFlowsheetData_Min_Order_By>;
};

/** input type for inserting array relation for remote table "patientFlowsheetData" */
export type PatientFlowsheetData_Arr_Rel_Insert_Input = {
  data: Array<PatientFlowsheetData_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<PatientFlowsheetData_On_Conflict>;
};

/** Boolean expression to filter rows from the table "patientFlowsheetData". All fields are combined with a logical 'AND'. */
export type PatientFlowsheetData_Bool_Exp = {
  _and?: InputMaybe<Array<PatientFlowsheetData_Bool_Exp>>;
  _not?: InputMaybe<PatientFlowsheetData_Bool_Exp>;
  _or?: InputMaybe<Array<PatientFlowsheetData_Bool_Exp>>;
  appointment?: InputMaybe<Appointment_Bool_Exp>;
  appointmentId?: InputMaybe<Uuid_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  flowsheet?: InputMaybe<Flowsheet_Bool_Exp>;
  flowsheetId?: InputMaybe<Uuid_Comparison_Exp>;
  flowsheetParameterId?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  parameter?: InputMaybe<FlowsheetParameter_Bool_Exp>;
  patient?: InputMaybe<PatientWorkspace_Bool_Exp>;
  patientFlowsheetRowId?: InputMaybe<Uuid_Comparison_Exp>;
  patientWorkspaceId?: InputMaybe<Uuid_Comparison_Exp>;
  recordedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  row?: InputMaybe<PatientFlowsheetRow_Bool_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
  workspace?: InputMaybe<Workspace_Bool_Exp>;
  workspaceId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "patientFlowsheetData" */
export enum PatientFlowsheetData_Constraint {
  /** unique or primary key constraint on columns "patientFlowsheetRowId", "flowsheetParameterId" */
  PatientFlowsheetDataFlowsheetParameterIdPatientFlowsheetRowId = 'patientFlowsheetData_flowsheetParameterId_patientFlowsheetRowId',
  /** unique or primary key constraint on columns "id" */
  PatientFlowsheetDataPkey = 'patientFlowsheetData_pkey'
}

/** input type for inserting data into table "patientFlowsheetData" */
export type PatientFlowsheetData_Insert_Input = {
  appointment?: InputMaybe<Appointment_Obj_Rel_Insert_Input>;
  appointmentId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  flowsheet?: InputMaybe<Flowsheet_Obj_Rel_Insert_Input>;
  flowsheetId?: InputMaybe<Scalars['uuid']>;
  flowsheetParameterId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  parameter?: InputMaybe<FlowsheetParameter_Obj_Rel_Insert_Input>;
  patient?: InputMaybe<PatientWorkspace_Obj_Rel_Insert_Input>;
  patientFlowsheetRowId?: InputMaybe<Scalars['uuid']>;
  patientWorkspaceId?: InputMaybe<Scalars['uuid']>;
  recordedAt?: InputMaybe<Scalars['timestamptz']>;
  row?: InputMaybe<PatientFlowsheetRow_Obj_Rel_Insert_Input>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  value?: InputMaybe<Scalars['String']>;
  workspace?: InputMaybe<Workspace_Obj_Rel_Insert_Input>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type PatientFlowsheetData_Max_Fields = {
  __typename?: 'patientFlowsheetData_max_fields';
  appointmentId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  flowsheetId?: Maybe<Scalars['uuid']>;
  flowsheetParameterId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  patientFlowsheetRowId?: Maybe<Scalars['uuid']>;
  patientWorkspaceId?: Maybe<Scalars['uuid']>;
  recordedAt?: Maybe<Scalars['timestamptz']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['String']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "patientFlowsheetData" */
export type PatientFlowsheetData_Max_Order_By = {
  appointmentId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  flowsheetId?: InputMaybe<Order_By>;
  flowsheetParameterId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patientFlowsheetRowId?: InputMaybe<Order_By>;
  patientWorkspaceId?: InputMaybe<Order_By>;
  recordedAt?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type PatientFlowsheetData_Min_Fields = {
  __typename?: 'patientFlowsheetData_min_fields';
  appointmentId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  flowsheetId?: Maybe<Scalars['uuid']>;
  flowsheetParameterId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  patientFlowsheetRowId?: Maybe<Scalars['uuid']>;
  patientWorkspaceId?: Maybe<Scalars['uuid']>;
  recordedAt?: Maybe<Scalars['timestamptz']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['String']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "patientFlowsheetData" */
export type PatientFlowsheetData_Min_Order_By = {
  appointmentId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  flowsheetId?: InputMaybe<Order_By>;
  flowsheetParameterId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patientFlowsheetRowId?: InputMaybe<Order_By>;
  patientWorkspaceId?: InputMaybe<Order_By>;
  recordedAt?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "patientFlowsheetData" */
export type PatientFlowsheetData_Mutation_Response = {
  __typename?: 'patientFlowsheetData_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<PatientFlowsheetData>;
};

/** on_conflict condition type for table "patientFlowsheetData" */
export type PatientFlowsheetData_On_Conflict = {
  constraint: PatientFlowsheetData_Constraint;
  update_columns?: Array<PatientFlowsheetData_Update_Column>;
  where?: InputMaybe<PatientFlowsheetData_Bool_Exp>;
};

/** Ordering options when selecting data from "patientFlowsheetData". */
export type PatientFlowsheetData_Order_By = {
  appointment?: InputMaybe<Appointment_Order_By>;
  appointmentId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  flowsheet?: InputMaybe<Flowsheet_Order_By>;
  flowsheetId?: InputMaybe<Order_By>;
  flowsheetParameterId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  parameter?: InputMaybe<FlowsheetParameter_Order_By>;
  patient?: InputMaybe<PatientWorkspace_Order_By>;
  patientFlowsheetRowId?: InputMaybe<Order_By>;
  patientWorkspaceId?: InputMaybe<Order_By>;
  recordedAt?: InputMaybe<Order_By>;
  row?: InputMaybe<PatientFlowsheetRow_Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
  workspace?: InputMaybe<Workspace_Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: patientFlowsheetData */
export type PatientFlowsheetData_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "patientFlowsheetData" */
export enum PatientFlowsheetData_Select_Column {
  /** column name */
  AppointmentId = 'appointmentId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  FlowsheetId = 'flowsheetId',
  /** column name */
  FlowsheetParameterId = 'flowsheetParameterId',
  /** column name */
  Id = 'id',
  /** column name */
  PatientFlowsheetRowId = 'patientFlowsheetRowId',
  /** column name */
  PatientWorkspaceId = 'patientWorkspaceId',
  /** column name */
  RecordedAt = 'recordedAt',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Value = 'value',
  /** column name */
  WorkspaceId = 'workspaceId'
}

/** input type for updating data in table "patientFlowsheetData" */
export type PatientFlowsheetData_Set_Input = {
  appointmentId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  flowsheetId?: InputMaybe<Scalars['uuid']>;
  flowsheetParameterId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  patientFlowsheetRowId?: InputMaybe<Scalars['uuid']>;
  patientWorkspaceId?: InputMaybe<Scalars['uuid']>;
  recordedAt?: InputMaybe<Scalars['timestamptz']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  value?: InputMaybe<Scalars['String']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "patientFlowsheetData" */
export type PatientFlowsheetData_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: PatientFlowsheetData_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type PatientFlowsheetData_Stream_Cursor_Value_Input = {
  appointmentId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  flowsheetId?: InputMaybe<Scalars['uuid']>;
  flowsheetParameterId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  patientFlowsheetRowId?: InputMaybe<Scalars['uuid']>;
  patientWorkspaceId?: InputMaybe<Scalars['uuid']>;
  recordedAt?: InputMaybe<Scalars['timestamptz']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  value?: InputMaybe<Scalars['String']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "patientFlowsheetData" */
export enum PatientFlowsheetData_Update_Column {
  /** column name */
  AppointmentId = 'appointmentId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  FlowsheetId = 'flowsheetId',
  /** column name */
  FlowsheetParameterId = 'flowsheetParameterId',
  /** column name */
  Id = 'id',
  /** column name */
  PatientFlowsheetRowId = 'patientFlowsheetRowId',
  /** column name */
  PatientWorkspaceId = 'patientWorkspaceId',
  /** column name */
  RecordedAt = 'recordedAt',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Value = 'value',
  /** column name */
  WorkspaceId = 'workspaceId'
}

export type PatientFlowsheetData_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<PatientFlowsheetData_Set_Input>;
  /** filter the rows which have to be updated */
  where: PatientFlowsheetData_Bool_Exp;
};

/** columns and relationships of "patientFlowsheetRow" */
export type PatientFlowsheetRow = {
  __typename?: 'patientFlowsheetRow';
  createdAt: Scalars['timestamp'];
  /** An array relationship */
  data: Array<PatientFlowsheetData>;
  /** An aggregate relationship */
  data_aggregate: PatientFlowsheetData_Aggregate;
  /** An object relationship */
  flowsheet: Flowsheet;
  flowsheetId: Scalars['uuid'];
  id: Scalars['uuid'];
  patientWorkspaceId: Scalars['uuid'];
  recordedAt: Scalars['timestamptz'];
  updatedAt: Scalars['timestamptz'];
  /** An object relationship */
  workspace: Workspace;
  workspaceId: Scalars['uuid'];
};


/** columns and relationships of "patientFlowsheetRow" */
export type PatientFlowsheetRowDataArgs = {
  distinct_on?: InputMaybe<Array<PatientFlowsheetData_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PatientFlowsheetData_Order_By>>;
  where?: InputMaybe<PatientFlowsheetData_Bool_Exp>;
};


/** columns and relationships of "patientFlowsheetRow" */
export type PatientFlowsheetRowData_AggregateArgs = {
  distinct_on?: InputMaybe<Array<PatientFlowsheetData_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PatientFlowsheetData_Order_By>>;
  where?: InputMaybe<PatientFlowsheetData_Bool_Exp>;
};

/** aggregated selection of "patientFlowsheetRow" */
export type PatientFlowsheetRow_Aggregate = {
  __typename?: 'patientFlowsheetRow_aggregate';
  aggregate?: Maybe<PatientFlowsheetRow_Aggregate_Fields>;
  nodes: Array<PatientFlowsheetRow>;
};

export type PatientFlowsheetRow_Aggregate_Bool_Exp = {
  count?: InputMaybe<PatientFlowsheetRow_Aggregate_Bool_Exp_Count>;
};

export type PatientFlowsheetRow_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<PatientFlowsheetRow_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<PatientFlowsheetRow_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "patientFlowsheetRow" */
export type PatientFlowsheetRow_Aggregate_Fields = {
  __typename?: 'patientFlowsheetRow_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<PatientFlowsheetRow_Max_Fields>;
  min?: Maybe<PatientFlowsheetRow_Min_Fields>;
};


/** aggregate fields of "patientFlowsheetRow" */
export type PatientFlowsheetRow_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<PatientFlowsheetRow_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "patientFlowsheetRow" */
export type PatientFlowsheetRow_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<PatientFlowsheetRow_Max_Order_By>;
  min?: InputMaybe<PatientFlowsheetRow_Min_Order_By>;
};

/** input type for inserting array relation for remote table "patientFlowsheetRow" */
export type PatientFlowsheetRow_Arr_Rel_Insert_Input = {
  data: Array<PatientFlowsheetRow_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<PatientFlowsheetRow_On_Conflict>;
};

/** Boolean expression to filter rows from the table "patientFlowsheetRow". All fields are combined with a logical 'AND'. */
export type PatientFlowsheetRow_Bool_Exp = {
  _and?: InputMaybe<Array<PatientFlowsheetRow_Bool_Exp>>;
  _not?: InputMaybe<PatientFlowsheetRow_Bool_Exp>;
  _or?: InputMaybe<Array<PatientFlowsheetRow_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamp_Comparison_Exp>;
  data?: InputMaybe<PatientFlowsheetData_Bool_Exp>;
  data_aggregate?: InputMaybe<PatientFlowsheetData_Aggregate_Bool_Exp>;
  flowsheet?: InputMaybe<Flowsheet_Bool_Exp>;
  flowsheetId?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  patientWorkspaceId?: InputMaybe<Uuid_Comparison_Exp>;
  recordedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  workspace?: InputMaybe<Workspace_Bool_Exp>;
  workspaceId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "patientFlowsheetRow" */
export enum PatientFlowsheetRow_Constraint {
  /** unique or primary key constraint on columns "recordedAt", "patientWorkspaceId", "flowsheetId" */
  PatientFlowsheetRowPatientWorkspaceIdRecordedAtFlowsheetIdK = 'patientFlowsheetRow_patientWorkspaceId_recordedAt_flowsheetId_k',
  /** unique or primary key constraint on columns "id" */
  PatientFlowsheetRowPkey = 'patientFlowsheetRow_pkey'
}

/** input type for inserting data into table "patientFlowsheetRow" */
export type PatientFlowsheetRow_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamp']>;
  data?: InputMaybe<PatientFlowsheetData_Arr_Rel_Insert_Input>;
  flowsheet?: InputMaybe<Flowsheet_Obj_Rel_Insert_Input>;
  flowsheetId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  patientWorkspaceId?: InputMaybe<Scalars['uuid']>;
  recordedAt?: InputMaybe<Scalars['timestamptz']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspace?: InputMaybe<Workspace_Obj_Rel_Insert_Input>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type PatientFlowsheetRow_Max_Fields = {
  __typename?: 'patientFlowsheetRow_max_fields';
  createdAt?: Maybe<Scalars['timestamp']>;
  flowsheetId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  patientWorkspaceId?: Maybe<Scalars['uuid']>;
  recordedAt?: Maybe<Scalars['timestamptz']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "patientFlowsheetRow" */
export type PatientFlowsheetRow_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  flowsheetId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patientWorkspaceId?: InputMaybe<Order_By>;
  recordedAt?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type PatientFlowsheetRow_Min_Fields = {
  __typename?: 'patientFlowsheetRow_min_fields';
  createdAt?: Maybe<Scalars['timestamp']>;
  flowsheetId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  patientWorkspaceId?: Maybe<Scalars['uuid']>;
  recordedAt?: Maybe<Scalars['timestamptz']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "patientFlowsheetRow" */
export type PatientFlowsheetRow_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  flowsheetId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patientWorkspaceId?: InputMaybe<Order_By>;
  recordedAt?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "patientFlowsheetRow" */
export type PatientFlowsheetRow_Mutation_Response = {
  __typename?: 'patientFlowsheetRow_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<PatientFlowsheetRow>;
};

/** input type for inserting object relation for remote table "patientFlowsheetRow" */
export type PatientFlowsheetRow_Obj_Rel_Insert_Input = {
  data: PatientFlowsheetRow_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<PatientFlowsheetRow_On_Conflict>;
};

/** on_conflict condition type for table "patientFlowsheetRow" */
export type PatientFlowsheetRow_On_Conflict = {
  constraint: PatientFlowsheetRow_Constraint;
  update_columns?: Array<PatientFlowsheetRow_Update_Column>;
  where?: InputMaybe<PatientFlowsheetRow_Bool_Exp>;
};

/** Ordering options when selecting data from "patientFlowsheetRow". */
export type PatientFlowsheetRow_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  data_aggregate?: InputMaybe<PatientFlowsheetData_Aggregate_Order_By>;
  flowsheet?: InputMaybe<Flowsheet_Order_By>;
  flowsheetId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patientWorkspaceId?: InputMaybe<Order_By>;
  recordedAt?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  workspace?: InputMaybe<Workspace_Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: patientFlowsheetRow */
export type PatientFlowsheetRow_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "patientFlowsheetRow" */
export enum PatientFlowsheetRow_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  FlowsheetId = 'flowsheetId',
  /** column name */
  Id = 'id',
  /** column name */
  PatientWorkspaceId = 'patientWorkspaceId',
  /** column name */
  RecordedAt = 'recordedAt',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WorkspaceId = 'workspaceId'
}

/** input type for updating data in table "patientFlowsheetRow" */
export type PatientFlowsheetRow_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamp']>;
  flowsheetId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  patientWorkspaceId?: InputMaybe<Scalars['uuid']>;
  recordedAt?: InputMaybe<Scalars['timestamptz']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "patientFlowsheetRow" */
export type PatientFlowsheetRow_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: PatientFlowsheetRow_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type PatientFlowsheetRow_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamp']>;
  flowsheetId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  patientWorkspaceId?: InputMaybe<Scalars['uuid']>;
  recordedAt?: InputMaybe<Scalars['timestamptz']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "patientFlowsheetRow" */
export enum PatientFlowsheetRow_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  FlowsheetId = 'flowsheetId',
  /** column name */
  Id = 'id',
  /** column name */
  PatientWorkspaceId = 'patientWorkspaceId',
  /** column name */
  RecordedAt = 'recordedAt',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WorkspaceId = 'workspaceId'
}

export type PatientFlowsheetRow_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<PatientFlowsheetRow_Set_Input>;
  /** filter the rows which have to be updated */
  where: PatientFlowsheetRow_Bool_Exp;
};

/** columns and relationships of "patientInvite" */
export type PatientInvite = {
  __typename?: 'patientInvite';
  createdAt: Scalars['timestamptz'];
  email: Scalars['String'];
  id: Scalars['uuid'];
  invitedByUserId: Scalars['uuid'];
  /** An object relationship */
  patient: Patient;
  patientId: Scalars['uuid'];
  status: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  /** An object relationship */
  user: User;
  /** An object relationship */
  workspace: Workspace;
  workspaceId: Scalars['uuid'];
};

/** aggregated selection of "patientInvite" */
export type PatientInvite_Aggregate = {
  __typename?: 'patientInvite_aggregate';
  aggregate?: Maybe<PatientInvite_Aggregate_Fields>;
  nodes: Array<PatientInvite>;
};

/** aggregate fields of "patientInvite" */
export type PatientInvite_Aggregate_Fields = {
  __typename?: 'patientInvite_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<PatientInvite_Max_Fields>;
  min?: Maybe<PatientInvite_Min_Fields>;
};


/** aggregate fields of "patientInvite" */
export type PatientInvite_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<PatientInvite_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "patientInvite". All fields are combined with a logical 'AND'. */
export type PatientInvite_Bool_Exp = {
  _and?: InputMaybe<Array<PatientInvite_Bool_Exp>>;
  _not?: InputMaybe<PatientInvite_Bool_Exp>;
  _or?: InputMaybe<Array<PatientInvite_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  invitedByUserId?: InputMaybe<Uuid_Comparison_Exp>;
  patient?: InputMaybe<Patient_Bool_Exp>;
  patientId?: InputMaybe<Uuid_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<User_Bool_Exp>;
  workspace?: InputMaybe<Workspace_Bool_Exp>;
  workspaceId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "patientInvite" */
export enum PatientInvite_Constraint {
  /** unique or primary key constraint on columns "id" */
  PatientInvitePkey = 'patientInvite_pkey'
}

/** input type for inserting data into table "patientInvite" */
export type PatientInvite_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  invitedByUserId?: InputMaybe<Scalars['uuid']>;
  patient?: InputMaybe<Patient_Obj_Rel_Insert_Input>;
  patientId?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<User_Obj_Rel_Insert_Input>;
  workspace?: InputMaybe<Workspace_Obj_Rel_Insert_Input>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type PatientInvite_Max_Fields = {
  __typename?: 'patientInvite_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  invitedByUserId?: Maybe<Scalars['uuid']>;
  patientId?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type PatientInvite_Min_Fields = {
  __typename?: 'patientInvite_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  invitedByUserId?: Maybe<Scalars['uuid']>;
  patientId?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "patientInvite" */
export type PatientInvite_Mutation_Response = {
  __typename?: 'patientInvite_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<PatientInvite>;
};

/** on_conflict condition type for table "patientInvite" */
export type PatientInvite_On_Conflict = {
  constraint: PatientInvite_Constraint;
  update_columns?: Array<PatientInvite_Update_Column>;
  where?: InputMaybe<PatientInvite_Bool_Exp>;
};

/** Ordering options when selecting data from "patientInvite". */
export type PatientInvite_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invitedByUserId?: InputMaybe<Order_By>;
  patient?: InputMaybe<Patient_Order_By>;
  patientId?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  user?: InputMaybe<User_Order_By>;
  workspace?: InputMaybe<Workspace_Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: patientInvite */
export type PatientInvite_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "patientInvite" */
export enum PatientInvite_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  InvitedByUserId = 'invitedByUserId',
  /** column name */
  PatientId = 'patientId',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WorkspaceId = 'workspaceId'
}

/** input type for updating data in table "patientInvite" */
export type PatientInvite_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  invitedByUserId?: InputMaybe<Scalars['uuid']>;
  patientId?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "patientInvite" */
export type PatientInvite_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: PatientInvite_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type PatientInvite_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  invitedByUserId?: InputMaybe<Scalars['uuid']>;
  patientId?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "patientInvite" */
export enum PatientInvite_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  InvitedByUserId = 'invitedByUserId',
  /** column name */
  PatientId = 'patientId',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WorkspaceId = 'workspaceId'
}

export type PatientInvite_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<PatientInvite_Set_Input>;
  /** filter the rows which have to be updated */
  where: PatientInvite_Bool_Exp;
};

/** columns and relationships of "patientMembership" */
export type PatientMembership = {
  __typename?: 'patientMembership';
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  invoiceId: Scalars['uuid'];
  lastAwardedInvoiceId: Scalars['uuid'];
  /** An object relationship */
  membership: Membership;
  membershipId: Scalars['uuid'];
  nextPaymentDate?: Maybe<Scalars['date']>;
  nextSubscriptionPaymentDate?: Maybe<Scalars['String']>;
  patientId: Scalars['uuid'];
  /** An object relationship */
  paymentMethod?: Maybe<PaymentMethod>;
  paymentMethodId?: Maybe<Scalars['uuid']>;
  /** An array relationship */
  payments: Array<Payment>;
  /** An aggregate relationship */
  payments_aggregate: Payment_Aggregate;
  payrixSubscriptionId?: Maybe<Scalars['String']>;
  payrixSubscriptionTokenId?: Maybe<Scalars['String']>;
  status: Scalars['String'];
  subscriptionEndDate?: Maybe<Scalars['timestamptz']>;
  subscriptionManager?: Maybe<Scalars['String']>;
  updatedAt: Scalars['timestamptz'];
  workspaceId: Scalars['uuid'];
};


/** columns and relationships of "patientMembership" */
export type PatientMembershipPaymentsArgs = {
  distinct_on?: InputMaybe<Array<Payment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Payment_Order_By>>;
  where?: InputMaybe<Payment_Bool_Exp>;
};


/** columns and relationships of "patientMembership" */
export type PatientMembershipPayments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Payment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Payment_Order_By>>;
  where?: InputMaybe<Payment_Bool_Exp>;
};

/** aggregated selection of "patientMembership" */
export type PatientMembership_Aggregate = {
  __typename?: 'patientMembership_aggregate';
  aggregate?: Maybe<PatientMembership_Aggregate_Fields>;
  nodes: Array<PatientMembership>;
};

export type PatientMembership_Aggregate_Bool_Exp = {
  count?: InputMaybe<PatientMembership_Aggregate_Bool_Exp_Count>;
};

export type PatientMembership_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<PatientMembership_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<PatientMembership_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "patientMembership" */
export type PatientMembership_Aggregate_Fields = {
  __typename?: 'patientMembership_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<PatientMembership_Max_Fields>;
  min?: Maybe<PatientMembership_Min_Fields>;
};


/** aggregate fields of "patientMembership" */
export type PatientMembership_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<PatientMembership_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "patientMembership" */
export type PatientMembership_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<PatientMembership_Max_Order_By>;
  min?: InputMaybe<PatientMembership_Min_Order_By>;
};

/** input type for inserting array relation for remote table "patientMembership" */
export type PatientMembership_Arr_Rel_Insert_Input = {
  data: Array<PatientMembership_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<PatientMembership_On_Conflict>;
};

/** Boolean expression to filter rows from the table "patientMembership". All fields are combined with a logical 'AND'. */
export type PatientMembership_Bool_Exp = {
  _and?: InputMaybe<Array<PatientMembership_Bool_Exp>>;
  _not?: InputMaybe<PatientMembership_Bool_Exp>;
  _or?: InputMaybe<Array<PatientMembership_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  invoiceId?: InputMaybe<Uuid_Comparison_Exp>;
  lastAwardedInvoiceId?: InputMaybe<Uuid_Comparison_Exp>;
  membership?: InputMaybe<Membership_Bool_Exp>;
  membershipId?: InputMaybe<Uuid_Comparison_Exp>;
  nextPaymentDate?: InputMaybe<Date_Comparison_Exp>;
  patientId?: InputMaybe<Uuid_Comparison_Exp>;
  paymentMethod?: InputMaybe<PaymentMethod_Bool_Exp>;
  paymentMethodId?: InputMaybe<Uuid_Comparison_Exp>;
  payments?: InputMaybe<Payment_Bool_Exp>;
  payments_aggregate?: InputMaybe<Payment_Aggregate_Bool_Exp>;
  payrixSubscriptionId?: InputMaybe<String_Comparison_Exp>;
  payrixSubscriptionTokenId?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  subscriptionEndDate?: InputMaybe<Timestamptz_Comparison_Exp>;
  subscriptionManager?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  workspaceId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "patientMembership" */
export enum PatientMembership_Constraint {
  /** unique or primary key constraint on columns "membershipId", "patientId" */
  PatientMembershipPatientIdMembershipIdKey = 'patientMembership_patientId_membershipId_key',
  /** unique or primary key constraint on columns "id" */
  PatientMembershipPkey = 'patientMembership_pkey'
}

/** input type for inserting data into table "patientMembership" */
export type PatientMembership_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  invoiceId?: InputMaybe<Scalars['uuid']>;
  lastAwardedInvoiceId?: InputMaybe<Scalars['uuid']>;
  membership?: InputMaybe<Membership_Obj_Rel_Insert_Input>;
  membershipId?: InputMaybe<Scalars['uuid']>;
  nextPaymentDate?: InputMaybe<Scalars['date']>;
  patientId?: InputMaybe<Scalars['uuid']>;
  paymentMethod?: InputMaybe<PaymentMethod_Obj_Rel_Insert_Input>;
  paymentMethodId?: InputMaybe<Scalars['uuid']>;
  payments?: InputMaybe<Payment_Arr_Rel_Insert_Input>;
  payrixSubscriptionId?: InputMaybe<Scalars['String']>;
  payrixSubscriptionTokenId?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  subscriptionEndDate?: InputMaybe<Scalars['timestamptz']>;
  subscriptionManager?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type PatientMembership_Max_Fields = {
  __typename?: 'patientMembership_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  invoiceId?: Maybe<Scalars['uuid']>;
  lastAwardedInvoiceId?: Maybe<Scalars['uuid']>;
  membershipId?: Maybe<Scalars['uuid']>;
  nextPaymentDate?: Maybe<Scalars['date']>;
  patientId?: Maybe<Scalars['uuid']>;
  paymentMethodId?: Maybe<Scalars['uuid']>;
  payrixSubscriptionId?: Maybe<Scalars['String']>;
  payrixSubscriptionTokenId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  subscriptionEndDate?: Maybe<Scalars['timestamptz']>;
  subscriptionManager?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "patientMembership" */
export type PatientMembership_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoiceId?: InputMaybe<Order_By>;
  lastAwardedInvoiceId?: InputMaybe<Order_By>;
  membershipId?: InputMaybe<Order_By>;
  nextPaymentDate?: InputMaybe<Order_By>;
  patientId?: InputMaybe<Order_By>;
  paymentMethodId?: InputMaybe<Order_By>;
  payrixSubscriptionId?: InputMaybe<Order_By>;
  payrixSubscriptionTokenId?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  subscriptionEndDate?: InputMaybe<Order_By>;
  subscriptionManager?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type PatientMembership_Min_Fields = {
  __typename?: 'patientMembership_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  invoiceId?: Maybe<Scalars['uuid']>;
  lastAwardedInvoiceId?: Maybe<Scalars['uuid']>;
  membershipId?: Maybe<Scalars['uuid']>;
  nextPaymentDate?: Maybe<Scalars['date']>;
  patientId?: Maybe<Scalars['uuid']>;
  paymentMethodId?: Maybe<Scalars['uuid']>;
  payrixSubscriptionId?: Maybe<Scalars['String']>;
  payrixSubscriptionTokenId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  subscriptionEndDate?: Maybe<Scalars['timestamptz']>;
  subscriptionManager?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "patientMembership" */
export type PatientMembership_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoiceId?: InputMaybe<Order_By>;
  lastAwardedInvoiceId?: InputMaybe<Order_By>;
  membershipId?: InputMaybe<Order_By>;
  nextPaymentDate?: InputMaybe<Order_By>;
  patientId?: InputMaybe<Order_By>;
  paymentMethodId?: InputMaybe<Order_By>;
  payrixSubscriptionId?: InputMaybe<Order_By>;
  payrixSubscriptionTokenId?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  subscriptionEndDate?: InputMaybe<Order_By>;
  subscriptionManager?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "patientMembership" */
export type PatientMembership_Mutation_Response = {
  __typename?: 'patientMembership_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<PatientMembership>;
};

/** on_conflict condition type for table "patientMembership" */
export type PatientMembership_On_Conflict = {
  constraint: PatientMembership_Constraint;
  update_columns?: Array<PatientMembership_Update_Column>;
  where?: InputMaybe<PatientMembership_Bool_Exp>;
};

/** Ordering options when selecting data from "patientMembership". */
export type PatientMembership_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoiceId?: InputMaybe<Order_By>;
  lastAwardedInvoiceId?: InputMaybe<Order_By>;
  membership?: InputMaybe<Membership_Order_By>;
  membershipId?: InputMaybe<Order_By>;
  nextPaymentDate?: InputMaybe<Order_By>;
  patientId?: InputMaybe<Order_By>;
  paymentMethod?: InputMaybe<PaymentMethod_Order_By>;
  paymentMethodId?: InputMaybe<Order_By>;
  payments_aggregate?: InputMaybe<Payment_Aggregate_Order_By>;
  payrixSubscriptionId?: InputMaybe<Order_By>;
  payrixSubscriptionTokenId?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  subscriptionEndDate?: InputMaybe<Order_By>;
  subscriptionManager?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: patientMembership */
export type PatientMembership_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "patientMembership" */
export enum PatientMembership_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  InvoiceId = 'invoiceId',
  /** column name */
  LastAwardedInvoiceId = 'lastAwardedInvoiceId',
  /** column name */
  MembershipId = 'membershipId',
  /** column name */
  NextPaymentDate = 'nextPaymentDate',
  /** column name */
  PatientId = 'patientId',
  /** column name */
  PaymentMethodId = 'paymentMethodId',
  /** column name */
  PayrixSubscriptionId = 'payrixSubscriptionId',
  /** column name */
  PayrixSubscriptionTokenId = 'payrixSubscriptionTokenId',
  /** column name */
  Status = 'status',
  /** column name */
  SubscriptionEndDate = 'subscriptionEndDate',
  /** column name */
  SubscriptionManager = 'subscriptionManager',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WorkspaceId = 'workspaceId'
}

/** input type for updating data in table "patientMembership" */
export type PatientMembership_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  invoiceId?: InputMaybe<Scalars['uuid']>;
  lastAwardedInvoiceId?: InputMaybe<Scalars['uuid']>;
  membershipId?: InputMaybe<Scalars['uuid']>;
  nextPaymentDate?: InputMaybe<Scalars['date']>;
  patientId?: InputMaybe<Scalars['uuid']>;
  paymentMethodId?: InputMaybe<Scalars['uuid']>;
  payrixSubscriptionId?: InputMaybe<Scalars['String']>;
  payrixSubscriptionTokenId?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  subscriptionEndDate?: InputMaybe<Scalars['timestamptz']>;
  subscriptionManager?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "patientMembership" */
export type PatientMembership_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: PatientMembership_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type PatientMembership_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  invoiceId?: InputMaybe<Scalars['uuid']>;
  lastAwardedInvoiceId?: InputMaybe<Scalars['uuid']>;
  membershipId?: InputMaybe<Scalars['uuid']>;
  nextPaymentDate?: InputMaybe<Scalars['date']>;
  patientId?: InputMaybe<Scalars['uuid']>;
  paymentMethodId?: InputMaybe<Scalars['uuid']>;
  payrixSubscriptionId?: InputMaybe<Scalars['String']>;
  payrixSubscriptionTokenId?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  subscriptionEndDate?: InputMaybe<Scalars['timestamptz']>;
  subscriptionManager?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "patientMembership" */
export enum PatientMembership_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  InvoiceId = 'invoiceId',
  /** column name */
  LastAwardedInvoiceId = 'lastAwardedInvoiceId',
  /** column name */
  MembershipId = 'membershipId',
  /** column name */
  NextPaymentDate = 'nextPaymentDate',
  /** column name */
  PatientId = 'patientId',
  /** column name */
  PaymentMethodId = 'paymentMethodId',
  /** column name */
  PayrixSubscriptionId = 'payrixSubscriptionId',
  /** column name */
  PayrixSubscriptionTokenId = 'payrixSubscriptionTokenId',
  /** column name */
  Status = 'status',
  /** column name */
  SubscriptionEndDate = 'subscriptionEndDate',
  /** column name */
  SubscriptionManager = 'subscriptionManager',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WorkspaceId = 'workspaceId'
}

export type PatientMembership_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<PatientMembership_Set_Input>;
  /** filter the rows which have to be updated */
  where: PatientMembership_Bool_Exp;
};

/** columns and relationships of "patientSystemNotice" */
export type PatientSystemNotice = {
  __typename?: 'patientSystemNotice';
  category: Scalars['String'];
  createdAt: Scalars['timestamptz'];
  description: Scalars['String'];
  id: Scalars['uuid'];
  patientId: Scalars['uuid'];
  seen: Scalars['Boolean'];
  subcategory: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  workspaceId: Scalars['uuid'];
};

/** aggregated selection of "patientSystemNotice" */
export type PatientSystemNotice_Aggregate = {
  __typename?: 'patientSystemNotice_aggregate';
  aggregate?: Maybe<PatientSystemNotice_Aggregate_Fields>;
  nodes: Array<PatientSystemNotice>;
};

/** aggregate fields of "patientSystemNotice" */
export type PatientSystemNotice_Aggregate_Fields = {
  __typename?: 'patientSystemNotice_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<PatientSystemNotice_Max_Fields>;
  min?: Maybe<PatientSystemNotice_Min_Fields>;
};


/** aggregate fields of "patientSystemNotice" */
export type PatientSystemNotice_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<PatientSystemNotice_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "patientSystemNotice". All fields are combined with a logical 'AND'. */
export type PatientSystemNotice_Bool_Exp = {
  _and?: InputMaybe<Array<PatientSystemNotice_Bool_Exp>>;
  _not?: InputMaybe<PatientSystemNotice_Bool_Exp>;
  _or?: InputMaybe<Array<PatientSystemNotice_Bool_Exp>>;
  category?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  patientId?: InputMaybe<Uuid_Comparison_Exp>;
  seen?: InputMaybe<Boolean_Comparison_Exp>;
  subcategory?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  workspaceId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "patientSystemNotice" */
export enum PatientSystemNotice_Constraint {
  /** unique or primary key constraint on columns "id" */
  PatientSystemNoticePkey = 'patientSystemNotice_pkey'
}

/** input type for inserting data into table "patientSystemNotice" */
export type PatientSystemNotice_Insert_Input = {
  category?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  patientId?: InputMaybe<Scalars['uuid']>;
  seen?: InputMaybe<Scalars['Boolean']>;
  subcategory?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type PatientSystemNotice_Max_Fields = {
  __typename?: 'patientSystemNotice_max_fields';
  category?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  patientId?: Maybe<Scalars['uuid']>;
  subcategory?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type PatientSystemNotice_Min_Fields = {
  __typename?: 'patientSystemNotice_min_fields';
  category?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  patientId?: Maybe<Scalars['uuid']>;
  subcategory?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "patientSystemNotice" */
export type PatientSystemNotice_Mutation_Response = {
  __typename?: 'patientSystemNotice_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<PatientSystemNotice>;
};

/** on_conflict condition type for table "patientSystemNotice" */
export type PatientSystemNotice_On_Conflict = {
  constraint: PatientSystemNotice_Constraint;
  update_columns?: Array<PatientSystemNotice_Update_Column>;
  where?: InputMaybe<PatientSystemNotice_Bool_Exp>;
};

/** Ordering options when selecting data from "patientSystemNotice". */
export type PatientSystemNotice_Order_By = {
  category?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patientId?: InputMaybe<Order_By>;
  seen?: InputMaybe<Order_By>;
  subcategory?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: patientSystemNotice */
export type PatientSystemNotice_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "patientSystemNotice" */
export enum PatientSystemNotice_Select_Column {
  /** column name */
  Category = 'category',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  PatientId = 'patientId',
  /** column name */
  Seen = 'seen',
  /** column name */
  Subcategory = 'subcategory',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WorkspaceId = 'workspaceId'
}

/** input type for updating data in table "patientSystemNotice" */
export type PatientSystemNotice_Set_Input = {
  category?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  patientId?: InputMaybe<Scalars['uuid']>;
  seen?: InputMaybe<Scalars['Boolean']>;
  subcategory?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "patientSystemNotice" */
export type PatientSystemNotice_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: PatientSystemNotice_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type PatientSystemNotice_Stream_Cursor_Value_Input = {
  category?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  patientId?: InputMaybe<Scalars['uuid']>;
  seen?: InputMaybe<Scalars['Boolean']>;
  subcategory?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "patientSystemNotice" */
export enum PatientSystemNotice_Update_Column {
  /** column name */
  Category = 'category',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  PatientId = 'patientId',
  /** column name */
  Seen = 'seen',
  /** column name */
  Subcategory = 'subcategory',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WorkspaceId = 'workspaceId'
}

export type PatientSystemNotice_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<PatientSystemNotice_Set_Input>;
  /** filter the rows which have to be updated */
  where: PatientSystemNotice_Bool_Exp;
};

/** columns and relationships of "patientTag" */
export type PatientTag = {
  __typename?: 'patientTag';
  color?: Maybe<Scalars['String']>;
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An array relationship */
  patientTags: Array<PatientTags>;
  /** An aggregate relationship */
  patientTags_aggregate: PatientTags_Aggregate;
  title: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  workspaceId: Scalars['uuid'];
};


/** columns and relationships of "patientTag" */
export type PatientTagPatientTagsArgs = {
  distinct_on?: InputMaybe<Array<PatientTags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PatientTags_Order_By>>;
  where?: InputMaybe<PatientTags_Bool_Exp>;
};


/** columns and relationships of "patientTag" */
export type PatientTagPatientTags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<PatientTags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PatientTags_Order_By>>;
  where?: InputMaybe<PatientTags_Bool_Exp>;
};

/** aggregated selection of "patientTag" */
export type PatientTag_Aggregate = {
  __typename?: 'patientTag_aggregate';
  aggregate?: Maybe<PatientTag_Aggregate_Fields>;
  nodes: Array<PatientTag>;
};

/** aggregate fields of "patientTag" */
export type PatientTag_Aggregate_Fields = {
  __typename?: 'patientTag_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<PatientTag_Max_Fields>;
  min?: Maybe<PatientTag_Min_Fields>;
};


/** aggregate fields of "patientTag" */
export type PatientTag_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<PatientTag_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "patientTag". All fields are combined with a logical 'AND'. */
export type PatientTag_Bool_Exp = {
  _and?: InputMaybe<Array<PatientTag_Bool_Exp>>;
  _not?: InputMaybe<PatientTag_Bool_Exp>;
  _or?: InputMaybe<Array<PatientTag_Bool_Exp>>;
  color?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  patientTags?: InputMaybe<PatientTags_Bool_Exp>;
  patientTags_aggregate?: InputMaybe<PatientTags_Aggregate_Bool_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  workspaceId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "patientTag" */
export enum PatientTag_Constraint {
  /** unique or primary key constraint on columns "id" */
  PatientTagPkey = 'patientTag_pkey',
  /** unique or primary key constraint on columns "workspaceId", "title" */
  PatientTagTitleWorkspaceIdKey = 'patientTag_title_workspaceId_key'
}

/** input type for inserting data into table "patientTag" */
export type PatientTag_Insert_Input = {
  color?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  patientTags?: InputMaybe<PatientTags_Arr_Rel_Insert_Input>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type PatientTag_Max_Fields = {
  __typename?: 'patientTag_max_fields';
  color?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type PatientTag_Min_Fields = {
  __typename?: 'patientTag_min_fields';
  color?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "patientTag" */
export type PatientTag_Mutation_Response = {
  __typename?: 'patientTag_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<PatientTag>;
};

/** input type for inserting object relation for remote table "patientTag" */
export type PatientTag_Obj_Rel_Insert_Input = {
  data: PatientTag_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<PatientTag_On_Conflict>;
};

/** on_conflict condition type for table "patientTag" */
export type PatientTag_On_Conflict = {
  constraint: PatientTag_Constraint;
  update_columns?: Array<PatientTag_Update_Column>;
  where?: InputMaybe<PatientTag_Bool_Exp>;
};

/** Ordering options when selecting data from "patientTag". */
export type PatientTag_Order_By = {
  color?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patientTags_aggregate?: InputMaybe<PatientTags_Aggregate_Order_By>;
  title?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: patientTag */
export type PatientTag_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "patientTag" */
export enum PatientTag_Select_Column {
  /** column name */
  Color = 'color',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WorkspaceId = 'workspaceId'
}

/** input type for updating data in table "patientTag" */
export type PatientTag_Set_Input = {
  color?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "patientTag" */
export type PatientTag_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: PatientTag_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type PatientTag_Stream_Cursor_Value_Input = {
  color?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "patientTag" */
export enum PatientTag_Update_Column {
  /** column name */
  Color = 'color',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WorkspaceId = 'workspaceId'
}

export type PatientTag_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<PatientTag_Set_Input>;
  /** filter the rows which have to be updated */
  where: PatientTag_Bool_Exp;
};

/** columns and relationships of "patientTags" */
export type PatientTags = {
  __typename?: 'patientTags';
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An object relationship */
  patient: Patient;
  patientId: Scalars['uuid'];
  /** An object relationship */
  patientTag: PatientTag;
  patientTagId: Scalars['uuid'];
  updatedAt: Scalars['timestamptz'];
};

/** aggregated selection of "patientTags" */
export type PatientTags_Aggregate = {
  __typename?: 'patientTags_aggregate';
  aggregate?: Maybe<PatientTags_Aggregate_Fields>;
  nodes: Array<PatientTags>;
};

export type PatientTags_Aggregate_Bool_Exp = {
  count?: InputMaybe<PatientTags_Aggregate_Bool_Exp_Count>;
};

export type PatientTags_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<PatientTags_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<PatientTags_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "patientTags" */
export type PatientTags_Aggregate_Fields = {
  __typename?: 'patientTags_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<PatientTags_Max_Fields>;
  min?: Maybe<PatientTags_Min_Fields>;
};


/** aggregate fields of "patientTags" */
export type PatientTags_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<PatientTags_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "patientTags" */
export type PatientTags_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<PatientTags_Max_Order_By>;
  min?: InputMaybe<PatientTags_Min_Order_By>;
};

/** input type for inserting array relation for remote table "patientTags" */
export type PatientTags_Arr_Rel_Insert_Input = {
  data: Array<PatientTags_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<PatientTags_On_Conflict>;
};

/** Boolean expression to filter rows from the table "patientTags". All fields are combined with a logical 'AND'. */
export type PatientTags_Bool_Exp = {
  _and?: InputMaybe<Array<PatientTags_Bool_Exp>>;
  _not?: InputMaybe<PatientTags_Bool_Exp>;
  _or?: InputMaybe<Array<PatientTags_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  patient?: InputMaybe<Patient_Bool_Exp>;
  patientId?: InputMaybe<Uuid_Comparison_Exp>;
  patientTag?: InputMaybe<PatientTag_Bool_Exp>;
  patientTagId?: InputMaybe<Uuid_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "patientTags" */
export enum PatientTags_Constraint {
  /** unique or primary key constraint on columns "patientId", "patientTagId" */
  PatientTagsPatientIdPatientTagIdKey = 'patientTags_patientId_patientTagId_key',
  /** unique or primary key constraint on columns "id" */
  PatientTagsPkey = 'patientTags_pkey'
}

/** input type for inserting data into table "patientTags" */
export type PatientTags_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  patient?: InputMaybe<Patient_Obj_Rel_Insert_Input>;
  patientId?: InputMaybe<Scalars['uuid']>;
  patientTag?: InputMaybe<PatientTag_Obj_Rel_Insert_Input>;
  patientTagId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type PatientTags_Max_Fields = {
  __typename?: 'patientTags_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  patientId?: Maybe<Scalars['uuid']>;
  patientTagId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "patientTags" */
export type PatientTags_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patientId?: InputMaybe<Order_By>;
  patientTagId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type PatientTags_Min_Fields = {
  __typename?: 'patientTags_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  patientId?: Maybe<Scalars['uuid']>;
  patientTagId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "patientTags" */
export type PatientTags_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patientId?: InputMaybe<Order_By>;
  patientTagId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "patientTags" */
export type PatientTags_Mutation_Response = {
  __typename?: 'patientTags_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<PatientTags>;
};

/** on_conflict condition type for table "patientTags" */
export type PatientTags_On_Conflict = {
  constraint: PatientTags_Constraint;
  update_columns?: Array<PatientTags_Update_Column>;
  where?: InputMaybe<PatientTags_Bool_Exp>;
};

/** Ordering options when selecting data from "patientTags". */
export type PatientTags_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patient?: InputMaybe<Patient_Order_By>;
  patientId?: InputMaybe<Order_By>;
  patientTag?: InputMaybe<PatientTag_Order_By>;
  patientTagId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: patientTags */
export type PatientTags_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "patientTags" */
export enum PatientTags_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  PatientId = 'patientId',
  /** column name */
  PatientTagId = 'patientTagId',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "patientTags" */
export type PatientTags_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  patientId?: InputMaybe<Scalars['uuid']>;
  patientTagId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "patientTags" */
export type PatientTags_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: PatientTags_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type PatientTags_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  patientId?: InputMaybe<Scalars['uuid']>;
  patientTagId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "patientTags" */
export enum PatientTags_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  PatientId = 'patientId',
  /** column name */
  PatientTagId = 'patientTagId',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type PatientTags_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<PatientTags_Set_Input>;
  /** filter the rows which have to be updated */
  where: PatientTags_Bool_Exp;
};

/** columns and relationships of "patientWallet" */
export type PatientWallet = {
  __typename?: 'patientWallet';
  balance: Scalars['Int'];
  balanceUpdateReason?: Maybe<Scalars['String']>;
  createdAt: Scalars['timestamptz'];
  discount: Scalars['Int'];
  id: Scalars['uuid'];
  /** An object relationship */
  patient: Patient;
  patientId: Scalars['uuid'];
  /** An array relationship */
  patientWalletCoupons: Array<PatientWalletCoupon>;
  /** An aggregate relationship */
  patientWalletCoupons_aggregate: PatientWalletCoupon_Aggregate;
  updatedAt: Scalars['timestamptz'];
  workspaceId: Scalars['uuid'];
};


/** columns and relationships of "patientWallet" */
export type PatientWalletPatientWalletCouponsArgs = {
  distinct_on?: InputMaybe<Array<PatientWalletCoupon_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PatientWalletCoupon_Order_By>>;
  where?: InputMaybe<PatientWalletCoupon_Bool_Exp>;
};


/** columns and relationships of "patientWallet" */
export type PatientWalletPatientWalletCoupons_AggregateArgs = {
  distinct_on?: InputMaybe<Array<PatientWalletCoupon_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PatientWalletCoupon_Order_By>>;
  where?: InputMaybe<PatientWalletCoupon_Bool_Exp>;
};

/** columns and relationships of "patientWalletCoupon" */
export type PatientWalletCoupon = {
  __typename?: 'patientWalletCoupon';
  archived: Scalars['Boolean'];
  /** An object relationship */
  coupon: Coupon;
  couponId: Scalars['uuid'];
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  invoiceId?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  patientWallet: PatientWallet;
  patientWalletId: Scalars['uuid'];
  updatedAt: Scalars['timestamptz'];
  used: Scalars['Boolean'];
};

/** aggregated selection of "patientWalletCoupon" */
export type PatientWalletCoupon_Aggregate = {
  __typename?: 'patientWalletCoupon_aggregate';
  aggregate?: Maybe<PatientWalletCoupon_Aggregate_Fields>;
  nodes: Array<PatientWalletCoupon>;
};

export type PatientWalletCoupon_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<PatientWalletCoupon_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<PatientWalletCoupon_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<PatientWalletCoupon_Aggregate_Bool_Exp_Count>;
};

export type PatientWalletCoupon_Aggregate_Bool_Exp_Bool_And = {
  arguments: PatientWalletCoupon_Select_Column_PatientWalletCoupon_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<PatientWalletCoupon_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type PatientWalletCoupon_Aggregate_Bool_Exp_Bool_Or = {
  arguments: PatientWalletCoupon_Select_Column_PatientWalletCoupon_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<PatientWalletCoupon_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type PatientWalletCoupon_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<PatientWalletCoupon_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<PatientWalletCoupon_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "patientWalletCoupon" */
export type PatientWalletCoupon_Aggregate_Fields = {
  __typename?: 'patientWalletCoupon_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<PatientWalletCoupon_Max_Fields>;
  min?: Maybe<PatientWalletCoupon_Min_Fields>;
};


/** aggregate fields of "patientWalletCoupon" */
export type PatientWalletCoupon_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<PatientWalletCoupon_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "patientWalletCoupon" */
export type PatientWalletCoupon_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<PatientWalletCoupon_Max_Order_By>;
  min?: InputMaybe<PatientWalletCoupon_Min_Order_By>;
};

/** input type for inserting array relation for remote table "patientWalletCoupon" */
export type PatientWalletCoupon_Arr_Rel_Insert_Input = {
  data: Array<PatientWalletCoupon_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<PatientWalletCoupon_On_Conflict>;
};

/** Boolean expression to filter rows from the table "patientWalletCoupon". All fields are combined with a logical 'AND'. */
export type PatientWalletCoupon_Bool_Exp = {
  _and?: InputMaybe<Array<PatientWalletCoupon_Bool_Exp>>;
  _not?: InputMaybe<PatientWalletCoupon_Bool_Exp>;
  _or?: InputMaybe<Array<PatientWalletCoupon_Bool_Exp>>;
  archived?: InputMaybe<Boolean_Comparison_Exp>;
  coupon?: InputMaybe<Coupon_Bool_Exp>;
  couponId?: InputMaybe<Uuid_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  invoiceId?: InputMaybe<Uuid_Comparison_Exp>;
  patientWallet?: InputMaybe<PatientWallet_Bool_Exp>;
  patientWalletId?: InputMaybe<Uuid_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  used?: InputMaybe<Boolean_Comparison_Exp>;
};

/** unique or primary key constraints on table "patientWalletCoupon" */
export enum PatientWalletCoupon_Constraint {
  /** unique or primary key constraint on columns "id" */
  PatientWalletCouponPkey = 'patientWalletCoupon_pkey'
}

/** input type for inserting data into table "patientWalletCoupon" */
export type PatientWalletCoupon_Insert_Input = {
  archived?: InputMaybe<Scalars['Boolean']>;
  coupon?: InputMaybe<Coupon_Obj_Rel_Insert_Input>;
  couponId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  invoiceId?: InputMaybe<Scalars['uuid']>;
  patientWallet?: InputMaybe<PatientWallet_Obj_Rel_Insert_Input>;
  patientWalletId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  used?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate max on columns */
export type PatientWalletCoupon_Max_Fields = {
  __typename?: 'patientWalletCoupon_max_fields';
  couponId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  invoiceId?: Maybe<Scalars['uuid']>;
  patientWalletId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "patientWalletCoupon" */
export type PatientWalletCoupon_Max_Order_By = {
  couponId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoiceId?: InputMaybe<Order_By>;
  patientWalletId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type PatientWalletCoupon_Min_Fields = {
  __typename?: 'patientWalletCoupon_min_fields';
  couponId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  invoiceId?: Maybe<Scalars['uuid']>;
  patientWalletId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "patientWalletCoupon" */
export type PatientWalletCoupon_Min_Order_By = {
  couponId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoiceId?: InputMaybe<Order_By>;
  patientWalletId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "patientWalletCoupon" */
export type PatientWalletCoupon_Mutation_Response = {
  __typename?: 'patientWalletCoupon_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<PatientWalletCoupon>;
};

/** on_conflict condition type for table "patientWalletCoupon" */
export type PatientWalletCoupon_On_Conflict = {
  constraint: PatientWalletCoupon_Constraint;
  update_columns?: Array<PatientWalletCoupon_Update_Column>;
  where?: InputMaybe<PatientWalletCoupon_Bool_Exp>;
};

/** Ordering options when selecting data from "patientWalletCoupon". */
export type PatientWalletCoupon_Order_By = {
  archived?: InputMaybe<Order_By>;
  coupon?: InputMaybe<Coupon_Order_By>;
  couponId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoiceId?: InputMaybe<Order_By>;
  patientWallet?: InputMaybe<PatientWallet_Order_By>;
  patientWalletId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  used?: InputMaybe<Order_By>;
};

/** primary key columns input for table: patientWalletCoupon */
export type PatientWalletCoupon_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "patientWalletCoupon" */
export enum PatientWalletCoupon_Select_Column {
  /** column name */
  Archived = 'archived',
  /** column name */
  CouponId = 'couponId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  InvoiceId = 'invoiceId',
  /** column name */
  PatientWalletId = 'patientWalletId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Used = 'used'
}

/** select "patientWalletCoupon_aggregate_bool_exp_bool_and_arguments_columns" columns of table "patientWalletCoupon" */
export enum PatientWalletCoupon_Select_Column_PatientWalletCoupon_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Archived = 'archived',
  /** column name */
  Used = 'used'
}

/** select "patientWalletCoupon_aggregate_bool_exp_bool_or_arguments_columns" columns of table "patientWalletCoupon" */
export enum PatientWalletCoupon_Select_Column_PatientWalletCoupon_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Archived = 'archived',
  /** column name */
  Used = 'used'
}

/** input type for updating data in table "patientWalletCoupon" */
export type PatientWalletCoupon_Set_Input = {
  archived?: InputMaybe<Scalars['Boolean']>;
  couponId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  invoiceId?: InputMaybe<Scalars['uuid']>;
  patientWalletId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  used?: InputMaybe<Scalars['Boolean']>;
};

/** Streaming cursor of the table "patientWalletCoupon" */
export type PatientWalletCoupon_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: PatientWalletCoupon_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type PatientWalletCoupon_Stream_Cursor_Value_Input = {
  archived?: InputMaybe<Scalars['Boolean']>;
  couponId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  invoiceId?: InputMaybe<Scalars['uuid']>;
  patientWalletId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  used?: InputMaybe<Scalars['Boolean']>;
};

/** update columns of table "patientWalletCoupon" */
export enum PatientWalletCoupon_Update_Column {
  /** column name */
  Archived = 'archived',
  /** column name */
  CouponId = 'couponId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  InvoiceId = 'invoiceId',
  /** column name */
  PatientWalletId = 'patientWalletId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Used = 'used'
}

export type PatientWalletCoupon_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<PatientWalletCoupon_Set_Input>;
  /** filter the rows which have to be updated */
  where: PatientWalletCoupon_Bool_Exp;
};

/** columns and relationships of "patientWalletTransaction" */
export type PatientWalletTransaction = {
  __typename?: 'patientWalletTransaction';
  amount: Scalars['Int'];
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  invoiceId?: Maybe<Scalars['uuid']>;
  note: Scalars['String'];
  patientId: Scalars['uuid'];
  patientWalletId: Scalars['uuid'];
  source: GetPatientWalletTransactionSourceOutput;
  sourceId: Scalars['uuid'];
  sourceType: Scalars['String'];
  totalAfter: Scalars['Int'];
  totalBefore: Scalars['Int'];
  type: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  workspaceId: Scalars['uuid'];
};

/** aggregated selection of "patientWalletTransaction" */
export type PatientWalletTransaction_Aggregate = {
  __typename?: 'patientWalletTransaction_aggregate';
  aggregate?: Maybe<PatientWalletTransaction_Aggregate_Fields>;
  nodes: Array<PatientWalletTransaction>;
};

/** aggregate fields of "patientWalletTransaction" */
export type PatientWalletTransaction_Aggregate_Fields = {
  __typename?: 'patientWalletTransaction_aggregate_fields';
  avg?: Maybe<PatientWalletTransaction_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<PatientWalletTransaction_Max_Fields>;
  min?: Maybe<PatientWalletTransaction_Min_Fields>;
  stddev?: Maybe<PatientWalletTransaction_Stddev_Fields>;
  stddev_pop?: Maybe<PatientWalletTransaction_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<PatientWalletTransaction_Stddev_Samp_Fields>;
  sum?: Maybe<PatientWalletTransaction_Sum_Fields>;
  var_pop?: Maybe<PatientWalletTransaction_Var_Pop_Fields>;
  var_samp?: Maybe<PatientWalletTransaction_Var_Samp_Fields>;
  variance?: Maybe<PatientWalletTransaction_Variance_Fields>;
};


/** aggregate fields of "patientWalletTransaction" */
export type PatientWalletTransaction_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<PatientWalletTransaction_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type PatientWalletTransaction_Avg_Fields = {
  __typename?: 'patientWalletTransaction_avg_fields';
  amount?: Maybe<Scalars['Float']>;
  totalAfter?: Maybe<Scalars['Float']>;
  totalBefore?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "patientWalletTransaction". All fields are combined with a logical 'AND'. */
export type PatientWalletTransaction_Bool_Exp = {
  _and?: InputMaybe<Array<PatientWalletTransaction_Bool_Exp>>;
  _not?: InputMaybe<PatientWalletTransaction_Bool_Exp>;
  _or?: InputMaybe<Array<PatientWalletTransaction_Bool_Exp>>;
  amount?: InputMaybe<Int_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  invoiceId?: InputMaybe<Uuid_Comparison_Exp>;
  note?: InputMaybe<String_Comparison_Exp>;
  patientId?: InputMaybe<Uuid_Comparison_Exp>;
  patientWalletId?: InputMaybe<Uuid_Comparison_Exp>;
  sourceId?: InputMaybe<Uuid_Comparison_Exp>;
  sourceType?: InputMaybe<String_Comparison_Exp>;
  totalAfter?: InputMaybe<Int_Comparison_Exp>;
  totalBefore?: InputMaybe<Int_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  workspaceId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "patientWalletTransaction" */
export enum PatientWalletTransaction_Constraint {
  /** unique or primary key constraint on columns "id" */
  PatientWalletTransactionPkey = 'patientWalletTransaction_pkey'
}

/** input type for incrementing numeric columns in table "patientWalletTransaction" */
export type PatientWalletTransaction_Inc_Input = {
  amount?: InputMaybe<Scalars['Int']>;
  totalAfter?: InputMaybe<Scalars['Int']>;
  totalBefore?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "patientWalletTransaction" */
export type PatientWalletTransaction_Insert_Input = {
  amount?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  invoiceId?: InputMaybe<Scalars['uuid']>;
  note?: InputMaybe<Scalars['String']>;
  patientId?: InputMaybe<Scalars['uuid']>;
  patientWalletId?: InputMaybe<Scalars['uuid']>;
  sourceId?: InputMaybe<Scalars['uuid']>;
  sourceType?: InputMaybe<Scalars['String']>;
  totalAfter?: InputMaybe<Scalars['Int']>;
  totalBefore?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type PatientWalletTransaction_Max_Fields = {
  __typename?: 'patientWalletTransaction_max_fields';
  amount?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  invoiceId?: Maybe<Scalars['uuid']>;
  note?: Maybe<Scalars['String']>;
  patientId?: Maybe<Scalars['uuid']>;
  patientWalletId?: Maybe<Scalars['uuid']>;
  sourceId?: Maybe<Scalars['uuid']>;
  sourceType?: Maybe<Scalars['String']>;
  totalAfter?: Maybe<Scalars['Int']>;
  totalBefore?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type PatientWalletTransaction_Min_Fields = {
  __typename?: 'patientWalletTransaction_min_fields';
  amount?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  invoiceId?: Maybe<Scalars['uuid']>;
  note?: Maybe<Scalars['String']>;
  patientId?: Maybe<Scalars['uuid']>;
  patientWalletId?: Maybe<Scalars['uuid']>;
  sourceId?: Maybe<Scalars['uuid']>;
  sourceType?: Maybe<Scalars['String']>;
  totalAfter?: Maybe<Scalars['Int']>;
  totalBefore?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "patientWalletTransaction" */
export type PatientWalletTransaction_Mutation_Response = {
  __typename?: 'patientWalletTransaction_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<PatientWalletTransaction>;
};

/** on_conflict condition type for table "patientWalletTransaction" */
export type PatientWalletTransaction_On_Conflict = {
  constraint: PatientWalletTransaction_Constraint;
  update_columns?: Array<PatientWalletTransaction_Update_Column>;
  where?: InputMaybe<PatientWalletTransaction_Bool_Exp>;
};

/** Ordering options when selecting data from "patientWalletTransaction". */
export type PatientWalletTransaction_Order_By = {
  amount?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoiceId?: InputMaybe<Order_By>;
  note?: InputMaybe<Order_By>;
  patientId?: InputMaybe<Order_By>;
  patientWalletId?: InputMaybe<Order_By>;
  sourceId?: InputMaybe<Order_By>;
  sourceType?: InputMaybe<Order_By>;
  totalAfter?: InputMaybe<Order_By>;
  totalBefore?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: patientWalletTransaction */
export type PatientWalletTransaction_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "patientWalletTransaction" */
export enum PatientWalletTransaction_Select_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  InvoiceId = 'invoiceId',
  /** column name */
  Note = 'note',
  /** column name */
  PatientId = 'patientId',
  /** column name */
  PatientWalletId = 'patientWalletId',
  /** column name */
  SourceId = 'sourceId',
  /** column name */
  SourceType = 'sourceType',
  /** column name */
  TotalAfter = 'totalAfter',
  /** column name */
  TotalBefore = 'totalBefore',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WorkspaceId = 'workspaceId'
}

/** input type for updating data in table "patientWalletTransaction" */
export type PatientWalletTransaction_Set_Input = {
  amount?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  invoiceId?: InputMaybe<Scalars['uuid']>;
  note?: InputMaybe<Scalars['String']>;
  patientId?: InputMaybe<Scalars['uuid']>;
  patientWalletId?: InputMaybe<Scalars['uuid']>;
  sourceId?: InputMaybe<Scalars['uuid']>;
  sourceType?: InputMaybe<Scalars['String']>;
  totalAfter?: InputMaybe<Scalars['Int']>;
  totalBefore?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type PatientWalletTransaction_Stddev_Fields = {
  __typename?: 'patientWalletTransaction_stddev_fields';
  amount?: Maybe<Scalars['Float']>;
  totalAfter?: Maybe<Scalars['Float']>;
  totalBefore?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type PatientWalletTransaction_Stddev_Pop_Fields = {
  __typename?: 'patientWalletTransaction_stddev_pop_fields';
  amount?: Maybe<Scalars['Float']>;
  totalAfter?: Maybe<Scalars['Float']>;
  totalBefore?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type PatientWalletTransaction_Stddev_Samp_Fields = {
  __typename?: 'patientWalletTransaction_stddev_samp_fields';
  amount?: Maybe<Scalars['Float']>;
  totalAfter?: Maybe<Scalars['Float']>;
  totalBefore?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "patientWalletTransaction" */
export type PatientWalletTransaction_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: PatientWalletTransaction_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type PatientWalletTransaction_Stream_Cursor_Value_Input = {
  amount?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  invoiceId?: InputMaybe<Scalars['uuid']>;
  note?: InputMaybe<Scalars['String']>;
  patientId?: InputMaybe<Scalars['uuid']>;
  patientWalletId?: InputMaybe<Scalars['uuid']>;
  sourceId?: InputMaybe<Scalars['uuid']>;
  sourceType?: InputMaybe<Scalars['String']>;
  totalAfter?: InputMaybe<Scalars['Int']>;
  totalBefore?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type PatientWalletTransaction_Sum_Fields = {
  __typename?: 'patientWalletTransaction_sum_fields';
  amount?: Maybe<Scalars['Int']>;
  totalAfter?: Maybe<Scalars['Int']>;
  totalBefore?: Maybe<Scalars['Int']>;
};

/** update columns of table "patientWalletTransaction" */
export enum PatientWalletTransaction_Update_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  InvoiceId = 'invoiceId',
  /** column name */
  Note = 'note',
  /** column name */
  PatientId = 'patientId',
  /** column name */
  PatientWalletId = 'patientWalletId',
  /** column name */
  SourceId = 'sourceId',
  /** column name */
  SourceType = 'sourceType',
  /** column name */
  TotalAfter = 'totalAfter',
  /** column name */
  TotalBefore = 'totalBefore',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WorkspaceId = 'workspaceId'
}

export type PatientWalletTransaction_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<PatientWalletTransaction_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<PatientWalletTransaction_Set_Input>;
  /** filter the rows which have to be updated */
  where: PatientWalletTransaction_Bool_Exp;
};

/** aggregate var_pop on columns */
export type PatientWalletTransaction_Var_Pop_Fields = {
  __typename?: 'patientWalletTransaction_var_pop_fields';
  amount?: Maybe<Scalars['Float']>;
  totalAfter?: Maybe<Scalars['Float']>;
  totalBefore?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type PatientWalletTransaction_Var_Samp_Fields = {
  __typename?: 'patientWalletTransaction_var_samp_fields';
  amount?: Maybe<Scalars['Float']>;
  totalAfter?: Maybe<Scalars['Float']>;
  totalBefore?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type PatientWalletTransaction_Variance_Fields = {
  __typename?: 'patientWalletTransaction_variance_fields';
  amount?: Maybe<Scalars['Float']>;
  totalAfter?: Maybe<Scalars['Float']>;
  totalBefore?: Maybe<Scalars['Float']>;
};

/** aggregated selection of "patientWallet" */
export type PatientWallet_Aggregate = {
  __typename?: 'patientWallet_aggregate';
  aggregate?: Maybe<PatientWallet_Aggregate_Fields>;
  nodes: Array<PatientWallet>;
};

export type PatientWallet_Aggregate_Bool_Exp = {
  count?: InputMaybe<PatientWallet_Aggregate_Bool_Exp_Count>;
};

export type PatientWallet_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<PatientWallet_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<PatientWallet_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "patientWallet" */
export type PatientWallet_Aggregate_Fields = {
  __typename?: 'patientWallet_aggregate_fields';
  avg?: Maybe<PatientWallet_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<PatientWallet_Max_Fields>;
  min?: Maybe<PatientWallet_Min_Fields>;
  stddev?: Maybe<PatientWallet_Stddev_Fields>;
  stddev_pop?: Maybe<PatientWallet_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<PatientWallet_Stddev_Samp_Fields>;
  sum?: Maybe<PatientWallet_Sum_Fields>;
  var_pop?: Maybe<PatientWallet_Var_Pop_Fields>;
  var_samp?: Maybe<PatientWallet_Var_Samp_Fields>;
  variance?: Maybe<PatientWallet_Variance_Fields>;
};


/** aggregate fields of "patientWallet" */
export type PatientWallet_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<PatientWallet_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "patientWallet" */
export type PatientWallet_Aggregate_Order_By = {
  avg?: InputMaybe<PatientWallet_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<PatientWallet_Max_Order_By>;
  min?: InputMaybe<PatientWallet_Min_Order_By>;
  stddev?: InputMaybe<PatientWallet_Stddev_Order_By>;
  stddev_pop?: InputMaybe<PatientWallet_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<PatientWallet_Stddev_Samp_Order_By>;
  sum?: InputMaybe<PatientWallet_Sum_Order_By>;
  var_pop?: InputMaybe<PatientWallet_Var_Pop_Order_By>;
  var_samp?: InputMaybe<PatientWallet_Var_Samp_Order_By>;
  variance?: InputMaybe<PatientWallet_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "patientWallet" */
export type PatientWallet_Arr_Rel_Insert_Input = {
  data: Array<PatientWallet_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<PatientWallet_On_Conflict>;
};

/** aggregate avg on columns */
export type PatientWallet_Avg_Fields = {
  __typename?: 'patientWallet_avg_fields';
  balance?: Maybe<Scalars['Float']>;
  discount?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "patientWallet" */
export type PatientWallet_Avg_Order_By = {
  balance?: InputMaybe<Order_By>;
  discount?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "patientWallet". All fields are combined with a logical 'AND'. */
export type PatientWallet_Bool_Exp = {
  _and?: InputMaybe<Array<PatientWallet_Bool_Exp>>;
  _not?: InputMaybe<PatientWallet_Bool_Exp>;
  _or?: InputMaybe<Array<PatientWallet_Bool_Exp>>;
  balance?: InputMaybe<Int_Comparison_Exp>;
  balanceUpdateReason?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  discount?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  patient?: InputMaybe<Patient_Bool_Exp>;
  patientId?: InputMaybe<Uuid_Comparison_Exp>;
  patientWalletCoupons?: InputMaybe<PatientWalletCoupon_Bool_Exp>;
  patientWalletCoupons_aggregate?: InputMaybe<PatientWalletCoupon_Aggregate_Bool_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  workspaceId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "patientWallet" */
export enum PatientWallet_Constraint {
  /** unique or primary key constraint on columns "patientId", "workspaceId" */
  PatientWalletPatientIdWorkspaceIdKey = 'patientWallet_patientId_workspaceId_key',
  /** unique or primary key constraint on columns "id" */
  PatientWalletPkey = 'patientWallet_pkey'
}

/** input type for incrementing numeric columns in table "patientWallet" */
export type PatientWallet_Inc_Input = {
  balance?: InputMaybe<Scalars['Int']>;
  discount?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "patientWallet" */
export type PatientWallet_Insert_Input = {
  balance?: InputMaybe<Scalars['Int']>;
  balanceUpdateReason?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  discount?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  patient?: InputMaybe<Patient_Obj_Rel_Insert_Input>;
  patientId?: InputMaybe<Scalars['uuid']>;
  patientWalletCoupons?: InputMaybe<PatientWalletCoupon_Arr_Rel_Insert_Input>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type PatientWallet_Max_Fields = {
  __typename?: 'patientWallet_max_fields';
  balance?: Maybe<Scalars['Int']>;
  balanceUpdateReason?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  discount?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  patientId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "patientWallet" */
export type PatientWallet_Max_Order_By = {
  balance?: InputMaybe<Order_By>;
  balanceUpdateReason?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  discount?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patientId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type PatientWallet_Min_Fields = {
  __typename?: 'patientWallet_min_fields';
  balance?: Maybe<Scalars['Int']>;
  balanceUpdateReason?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  discount?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  patientId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "patientWallet" */
export type PatientWallet_Min_Order_By = {
  balance?: InputMaybe<Order_By>;
  balanceUpdateReason?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  discount?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patientId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "patientWallet" */
export type PatientWallet_Mutation_Response = {
  __typename?: 'patientWallet_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<PatientWallet>;
};

/** input type for inserting object relation for remote table "patientWallet" */
export type PatientWallet_Obj_Rel_Insert_Input = {
  data: PatientWallet_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<PatientWallet_On_Conflict>;
};

/** on_conflict condition type for table "patientWallet" */
export type PatientWallet_On_Conflict = {
  constraint: PatientWallet_Constraint;
  update_columns?: Array<PatientWallet_Update_Column>;
  where?: InputMaybe<PatientWallet_Bool_Exp>;
};

/** Ordering options when selecting data from "patientWallet". */
export type PatientWallet_Order_By = {
  balance?: InputMaybe<Order_By>;
  balanceUpdateReason?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  discount?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patient?: InputMaybe<Patient_Order_By>;
  patientId?: InputMaybe<Order_By>;
  patientWalletCoupons_aggregate?: InputMaybe<PatientWalletCoupon_Aggregate_Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: patientWallet */
export type PatientWallet_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "patientWallet" */
export enum PatientWallet_Select_Column {
  /** column name */
  Balance = 'balance',
  /** column name */
  BalanceUpdateReason = 'balanceUpdateReason',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Discount = 'discount',
  /** column name */
  Id = 'id',
  /** column name */
  PatientId = 'patientId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WorkspaceId = 'workspaceId'
}

/** input type for updating data in table "patientWallet" */
export type PatientWallet_Set_Input = {
  balance?: InputMaybe<Scalars['Int']>;
  balanceUpdateReason?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  discount?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  patientId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type PatientWallet_Stddev_Fields = {
  __typename?: 'patientWallet_stddev_fields';
  balance?: Maybe<Scalars['Float']>;
  discount?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "patientWallet" */
export type PatientWallet_Stddev_Order_By = {
  balance?: InputMaybe<Order_By>;
  discount?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type PatientWallet_Stddev_Pop_Fields = {
  __typename?: 'patientWallet_stddev_pop_fields';
  balance?: Maybe<Scalars['Float']>;
  discount?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "patientWallet" */
export type PatientWallet_Stddev_Pop_Order_By = {
  balance?: InputMaybe<Order_By>;
  discount?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type PatientWallet_Stddev_Samp_Fields = {
  __typename?: 'patientWallet_stddev_samp_fields';
  balance?: Maybe<Scalars['Float']>;
  discount?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "patientWallet" */
export type PatientWallet_Stddev_Samp_Order_By = {
  balance?: InputMaybe<Order_By>;
  discount?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "patientWallet" */
export type PatientWallet_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: PatientWallet_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type PatientWallet_Stream_Cursor_Value_Input = {
  balance?: InputMaybe<Scalars['Int']>;
  balanceUpdateReason?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  discount?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  patientId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type PatientWallet_Sum_Fields = {
  __typename?: 'patientWallet_sum_fields';
  balance?: Maybe<Scalars['Int']>;
  discount?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "patientWallet" */
export type PatientWallet_Sum_Order_By = {
  balance?: InputMaybe<Order_By>;
  discount?: InputMaybe<Order_By>;
};

/** update columns of table "patientWallet" */
export enum PatientWallet_Update_Column {
  /** column name */
  Balance = 'balance',
  /** column name */
  BalanceUpdateReason = 'balanceUpdateReason',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Discount = 'discount',
  /** column name */
  Id = 'id',
  /** column name */
  PatientId = 'patientId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WorkspaceId = 'workspaceId'
}

export type PatientWallet_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<PatientWallet_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<PatientWallet_Set_Input>;
  /** filter the rows which have to be updated */
  where: PatientWallet_Bool_Exp;
};

/** aggregate var_pop on columns */
export type PatientWallet_Var_Pop_Fields = {
  __typename?: 'patientWallet_var_pop_fields';
  balance?: Maybe<Scalars['Float']>;
  discount?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "patientWallet" */
export type PatientWallet_Var_Pop_Order_By = {
  balance?: InputMaybe<Order_By>;
  discount?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type PatientWallet_Var_Samp_Fields = {
  __typename?: 'patientWallet_var_samp_fields';
  balance?: Maybe<Scalars['Float']>;
  discount?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "patientWallet" */
export type PatientWallet_Var_Samp_Order_By = {
  balance?: InputMaybe<Order_By>;
  discount?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type PatientWallet_Variance_Fields = {
  __typename?: 'patientWallet_variance_fields';
  balance?: Maybe<Scalars['Float']>;
  discount?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "patientWallet" */
export type PatientWallet_Variance_Order_By = {
  balance?: InputMaybe<Order_By>;
  discount?: InputMaybe<Order_By>;
};

/** columns and relationships of "patientWorkspace" */
export type PatientWorkspace = {
  __typename?: 'patientWorkspace';
  archived?: Maybe<Scalars['Boolean']>;
  attributeValues?: Maybe<Scalars['jsonb']>;
  attributes?: Maybe<Scalars['jsonb']>;
  balanceDue: Scalars['Int'];
  clinicId?: Maybe<Scalars['Int']>;
  createdAt: Scalars['timestamptz'];
  dob?: Maybe<Scalars['date']>;
  dosespotPatientId?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['String']>;
  emailConfirmed?: Maybe<Scalars['Boolean']>;
  emailMarketingEnabled: Scalars['Boolean'];
  firstName?: Maybe<Scalars['String']>;
  formattedPhoneNumber?: Maybe<Scalars['String']>;
  fullAnonymousMediaConsent?: Maybe<Scalars['Boolean']>;
  fullMediaConsent?: Maybe<Scalars['Boolean']>;
  gender?: Maybe<Scalars['String']>;
  hasUnreadMessages?: Maybe<Scalars['Boolean']>;
  height?: Maybe<Scalars['Float']>;
  heightUnit?: Maybe<Scalars['String']>;
  hgId?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  importJobId?: Maybe<Scalars['uuid']>;
  language: Scalars['String'];
  lastName?: Maybe<Scalars['String']>;
  leadStatus?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  partialMediaConsent?: Maybe<Scalars['Boolean']>;
  /** An object relationship */
  patient: Patient;
  patientId: Scalars['uuid'];
  patientReferenceNumber: Scalars['Int'];
  /** An object relationship */
  patientWallet?: Maybe<PatientWallet>;
  patientWalletId?: Maybe<Scalars['uuid']>;
  payrixCustomerId?: Maybe<Scalars['String']>;
  phoneConfirmed?: Maybe<Scalars['Boolean']>;
  phoneMarketingEnabled?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  /** An object relationship */
  primaryLocation?: Maybe<Location>;
  primaryLocationId?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  primaryProvider?: Maybe<Provider>;
  primaryProviderId?: Maybe<Scalars['uuid']>;
  profileNote?: Maybe<Scalars['String']>;
  /** An object relationship */
  profilePicture?: Maybe<Photo>;
  profilePictureId?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  referringPatient?: Maybe<Patient>;
  referringPatientId?: Maybe<Scalars['uuid']>;
  sex?: Maybe<Scalars['String']>;
  smsMarketingEnabled?: Maybe<Scalars['Boolean']>;
  source?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  textsearchable_index_col?: Maybe<Scalars['tsvector']>;
  twilioConversationSid?: Maybe<Scalars['String']>;
  updatedAt: Scalars['timestamptz'];
  /** An array relationship */
  vitals: Array<Vitals>;
  /** An aggregate relationship */
  vitals_aggregate: Vitals_Aggregate;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  /** An object relationship */
  workspace: Workspace;
  workspaceId: Scalars['uuid'];
};


/** columns and relationships of "patientWorkspace" */
export type PatientWorkspaceAttributeValuesArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "patientWorkspace" */
export type PatientWorkspaceAttributesArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "patientWorkspace" */
export type PatientWorkspaceVitalsArgs = {
  distinct_on?: InputMaybe<Array<Vitals_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vitals_Order_By>>;
  where?: InputMaybe<Vitals_Bool_Exp>;
};


/** columns and relationships of "patientWorkspace" */
export type PatientWorkspaceVitals_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Vitals_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vitals_Order_By>>;
  where?: InputMaybe<Vitals_Bool_Exp>;
};

/** aggregated selection of "patientWorkspace" */
export type PatientWorkspace_Aggregate = {
  __typename?: 'patientWorkspace_aggregate';
  aggregate?: Maybe<PatientWorkspace_Aggregate_Fields>;
  nodes: Array<PatientWorkspace>;
};

export type PatientWorkspace_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<PatientWorkspace_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<PatientWorkspace_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<PatientWorkspace_Aggregate_Bool_Exp_Count>;
};

export type PatientWorkspace_Aggregate_Bool_Exp_Bool_And = {
  arguments: PatientWorkspace_Select_Column_PatientWorkspace_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<PatientWorkspace_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type PatientWorkspace_Aggregate_Bool_Exp_Bool_Or = {
  arguments: PatientWorkspace_Select_Column_PatientWorkspace_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<PatientWorkspace_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type PatientWorkspace_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<PatientWorkspace_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<PatientWorkspace_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "patientWorkspace" */
export type PatientWorkspace_Aggregate_Fields = {
  __typename?: 'patientWorkspace_aggregate_fields';
  avg?: Maybe<PatientWorkspace_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<PatientWorkspace_Max_Fields>;
  min?: Maybe<PatientWorkspace_Min_Fields>;
  stddev?: Maybe<PatientWorkspace_Stddev_Fields>;
  stddev_pop?: Maybe<PatientWorkspace_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<PatientWorkspace_Stddev_Samp_Fields>;
  sum?: Maybe<PatientWorkspace_Sum_Fields>;
  var_pop?: Maybe<PatientWorkspace_Var_Pop_Fields>;
  var_samp?: Maybe<PatientWorkspace_Var_Samp_Fields>;
  variance?: Maybe<PatientWorkspace_Variance_Fields>;
};


/** aggregate fields of "patientWorkspace" */
export type PatientWorkspace_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<PatientWorkspace_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "patientWorkspace" */
export type PatientWorkspace_Aggregate_Order_By = {
  avg?: InputMaybe<PatientWorkspace_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<PatientWorkspace_Max_Order_By>;
  min?: InputMaybe<PatientWorkspace_Min_Order_By>;
  stddev?: InputMaybe<PatientWorkspace_Stddev_Order_By>;
  stddev_pop?: InputMaybe<PatientWorkspace_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<PatientWorkspace_Stddev_Samp_Order_By>;
  sum?: InputMaybe<PatientWorkspace_Sum_Order_By>;
  var_pop?: InputMaybe<PatientWorkspace_Var_Pop_Order_By>;
  var_samp?: InputMaybe<PatientWorkspace_Var_Samp_Order_By>;
  variance?: InputMaybe<PatientWorkspace_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type PatientWorkspace_Append_Input = {
  attributeValues?: InputMaybe<Scalars['jsonb']>;
  attributes?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "patientWorkspace" */
export type PatientWorkspace_Arr_Rel_Insert_Input = {
  data: Array<PatientWorkspace_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<PatientWorkspace_On_Conflict>;
};

/** aggregate avg on columns */
export type PatientWorkspace_Avg_Fields = {
  __typename?: 'patientWorkspace_avg_fields';
  balanceDue?: Maybe<Scalars['Float']>;
  clinicId?: Maybe<Scalars['Float']>;
  dosespotPatientId?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  patientReferenceNumber?: Maybe<Scalars['Float']>;
  weight?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "patientWorkspace" */
export type PatientWorkspace_Avg_Order_By = {
  balanceDue?: InputMaybe<Order_By>;
  clinicId?: InputMaybe<Order_By>;
  dosespotPatientId?: InputMaybe<Order_By>;
  height?: InputMaybe<Order_By>;
  patientReferenceNumber?: InputMaybe<Order_By>;
  weight?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "patientWorkspace". All fields are combined with a logical 'AND'. */
export type PatientWorkspace_Bool_Exp = {
  _and?: InputMaybe<Array<PatientWorkspace_Bool_Exp>>;
  _not?: InputMaybe<PatientWorkspace_Bool_Exp>;
  _or?: InputMaybe<Array<PatientWorkspace_Bool_Exp>>;
  archived?: InputMaybe<Boolean_Comparison_Exp>;
  attributeValues?: InputMaybe<Jsonb_Comparison_Exp>;
  attributes?: InputMaybe<Jsonb_Comparison_Exp>;
  balanceDue?: InputMaybe<Int_Comparison_Exp>;
  clinicId?: InputMaybe<Int_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  dob?: InputMaybe<Date_Comparison_Exp>;
  dosespotPatientId?: InputMaybe<Int_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  emailConfirmed?: InputMaybe<Boolean_Comparison_Exp>;
  emailMarketingEnabled?: InputMaybe<Boolean_Comparison_Exp>;
  firstName?: InputMaybe<String_Comparison_Exp>;
  formattedPhoneNumber?: InputMaybe<String_Comparison_Exp>;
  fullAnonymousMediaConsent?: InputMaybe<Boolean_Comparison_Exp>;
  fullMediaConsent?: InputMaybe<Boolean_Comparison_Exp>;
  gender?: InputMaybe<String_Comparison_Exp>;
  hasUnreadMessages?: InputMaybe<Boolean_Comparison_Exp>;
  height?: InputMaybe<Float_Comparison_Exp>;
  heightUnit?: InputMaybe<String_Comparison_Exp>;
  hgId?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  importJobId?: InputMaybe<Uuid_Comparison_Exp>;
  language?: InputMaybe<String_Comparison_Exp>;
  lastName?: InputMaybe<String_Comparison_Exp>;
  leadStatus?: InputMaybe<String_Comparison_Exp>;
  middleName?: InputMaybe<String_Comparison_Exp>;
  partialMediaConsent?: InputMaybe<Boolean_Comparison_Exp>;
  patient?: InputMaybe<Patient_Bool_Exp>;
  patientId?: InputMaybe<Uuid_Comparison_Exp>;
  patientReferenceNumber?: InputMaybe<Int_Comparison_Exp>;
  patientWallet?: InputMaybe<PatientWallet_Bool_Exp>;
  patientWalletId?: InputMaybe<Uuid_Comparison_Exp>;
  payrixCustomerId?: InputMaybe<String_Comparison_Exp>;
  phoneConfirmed?: InputMaybe<Boolean_Comparison_Exp>;
  phoneMarketingEnabled?: InputMaybe<Boolean_Comparison_Exp>;
  phoneNumber?: InputMaybe<String_Comparison_Exp>;
  primaryLocation?: InputMaybe<Location_Bool_Exp>;
  primaryLocationId?: InputMaybe<Uuid_Comparison_Exp>;
  primaryProvider?: InputMaybe<Provider_Bool_Exp>;
  primaryProviderId?: InputMaybe<Uuid_Comparison_Exp>;
  profileNote?: InputMaybe<String_Comparison_Exp>;
  profilePicture?: InputMaybe<Photo_Bool_Exp>;
  profilePictureId?: InputMaybe<Uuid_Comparison_Exp>;
  referringPatient?: InputMaybe<Patient_Bool_Exp>;
  referringPatientId?: InputMaybe<Uuid_Comparison_Exp>;
  sex?: InputMaybe<String_Comparison_Exp>;
  smsMarketingEnabled?: InputMaybe<Boolean_Comparison_Exp>;
  source?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  textsearchable_index_col?: InputMaybe<Tsvector_Comparison_Exp>;
  twilioConversationSid?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  vitals?: InputMaybe<Vitals_Bool_Exp>;
  vitals_aggregate?: InputMaybe<Vitals_Aggregate_Bool_Exp>;
  weight?: InputMaybe<Float_Comparison_Exp>;
  weightUnit?: InputMaybe<String_Comparison_Exp>;
  workspace?: InputMaybe<Workspace_Bool_Exp>;
  workspaceId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "patientWorkspace" */
export enum PatientWorkspace_Constraint {
  /** unique or primary key constraint on columns "hgId" */
  PatientWorkspaceHgIdKey = 'patientWorkspace_hgId_key',
  /** unique or primary key constraint on columns "patientId", "workspaceId" */
  PatientWorkspacePatientIdWorkspaceIdKey = 'patientWorkspace_patientId_workspaceId_key',
  /** unique or primary key constraint on columns "patientWalletId" */
  PatientWorkspacePatientWalletIdKey = 'patientWorkspace_patientWalletId_key',
  /** unique or primary key constraint on columns "id" */
  PatientWorkspacePkey = 'patientWorkspace_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type PatientWorkspace_Delete_At_Path_Input = {
  attributeValues?: InputMaybe<Array<Scalars['String']>>;
  attributes?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type PatientWorkspace_Delete_Elem_Input = {
  attributeValues?: InputMaybe<Scalars['Int']>;
  attributes?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type PatientWorkspace_Delete_Key_Input = {
  attributeValues?: InputMaybe<Scalars['String']>;
  attributes?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "patientWorkspace" */
export type PatientWorkspace_Inc_Input = {
  balanceDue?: InputMaybe<Scalars['Int']>;
  clinicId?: InputMaybe<Scalars['Int']>;
  dosespotPatientId?: InputMaybe<Scalars['Int']>;
  height?: InputMaybe<Scalars['Float']>;
  patientReferenceNumber?: InputMaybe<Scalars['Int']>;
  weight?: InputMaybe<Scalars['Float']>;
};

/** input type for inserting data into table "patientWorkspace" */
export type PatientWorkspace_Insert_Input = {
  archived?: InputMaybe<Scalars['Boolean']>;
  attributeValues?: InputMaybe<Scalars['jsonb']>;
  attributes?: InputMaybe<Scalars['jsonb']>;
  balanceDue?: InputMaybe<Scalars['Int']>;
  clinicId?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  dob?: InputMaybe<Scalars['date']>;
  dosespotPatientId?: InputMaybe<Scalars['Int']>;
  email?: InputMaybe<Scalars['String']>;
  emailConfirmed?: InputMaybe<Scalars['Boolean']>;
  emailMarketingEnabled?: InputMaybe<Scalars['Boolean']>;
  firstName?: InputMaybe<Scalars['String']>;
  formattedPhoneNumber?: InputMaybe<Scalars['String']>;
  fullAnonymousMediaConsent?: InputMaybe<Scalars['Boolean']>;
  fullMediaConsent?: InputMaybe<Scalars['Boolean']>;
  gender?: InputMaybe<Scalars['String']>;
  hasUnreadMessages?: InputMaybe<Scalars['Boolean']>;
  height?: InputMaybe<Scalars['Float']>;
  heightUnit?: InputMaybe<Scalars['String']>;
  hgId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  importJobId?: InputMaybe<Scalars['uuid']>;
  language?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  leadStatus?: InputMaybe<Scalars['String']>;
  middleName?: InputMaybe<Scalars['String']>;
  partialMediaConsent?: InputMaybe<Scalars['Boolean']>;
  patient?: InputMaybe<Patient_Obj_Rel_Insert_Input>;
  patientId?: InputMaybe<Scalars['uuid']>;
  patientReferenceNumber?: InputMaybe<Scalars['Int']>;
  patientWallet?: InputMaybe<PatientWallet_Obj_Rel_Insert_Input>;
  patientWalletId?: InputMaybe<Scalars['uuid']>;
  payrixCustomerId?: InputMaybe<Scalars['String']>;
  phoneConfirmed?: InputMaybe<Scalars['Boolean']>;
  phoneMarketingEnabled?: InputMaybe<Scalars['Boolean']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  primaryLocation?: InputMaybe<Location_Obj_Rel_Insert_Input>;
  primaryLocationId?: InputMaybe<Scalars['uuid']>;
  primaryProvider?: InputMaybe<Provider_Obj_Rel_Insert_Input>;
  primaryProviderId?: InputMaybe<Scalars['uuid']>;
  profileNote?: InputMaybe<Scalars['String']>;
  profilePicture?: InputMaybe<Photo_Obj_Rel_Insert_Input>;
  profilePictureId?: InputMaybe<Scalars['uuid']>;
  referringPatient?: InputMaybe<Patient_Obj_Rel_Insert_Input>;
  referringPatientId?: InputMaybe<Scalars['uuid']>;
  sex?: InputMaybe<Scalars['String']>;
  smsMarketingEnabled?: InputMaybe<Scalars['Boolean']>;
  source?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  twilioConversationSid?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  vitals?: InputMaybe<Vitals_Arr_Rel_Insert_Input>;
  weight?: InputMaybe<Scalars['Float']>;
  weightUnit?: InputMaybe<Scalars['String']>;
  workspace?: InputMaybe<Workspace_Obj_Rel_Insert_Input>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type PatientWorkspace_Max_Fields = {
  __typename?: 'patientWorkspace_max_fields';
  balanceDue?: Maybe<Scalars['Int']>;
  clinicId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  dob?: Maybe<Scalars['date']>;
  dosespotPatientId?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  formattedPhoneNumber?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Float']>;
  heightUnit?: Maybe<Scalars['String']>;
  hgId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  importJobId?: Maybe<Scalars['uuid']>;
  language?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  leadStatus?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  patientId?: Maybe<Scalars['uuid']>;
  patientReferenceNumber?: Maybe<Scalars['Int']>;
  patientWalletId?: Maybe<Scalars['uuid']>;
  payrixCustomerId?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  primaryLocationId?: Maybe<Scalars['uuid']>;
  primaryProviderId?: Maybe<Scalars['uuid']>;
  profileNote?: Maybe<Scalars['String']>;
  profilePictureId?: Maybe<Scalars['uuid']>;
  referringPatientId?: Maybe<Scalars['uuid']>;
  sex?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  twilioConversationSid?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "patientWorkspace" */
export type PatientWorkspace_Max_Order_By = {
  balanceDue?: InputMaybe<Order_By>;
  clinicId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  dob?: InputMaybe<Order_By>;
  dosespotPatientId?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  firstName?: InputMaybe<Order_By>;
  formattedPhoneNumber?: InputMaybe<Order_By>;
  gender?: InputMaybe<Order_By>;
  height?: InputMaybe<Order_By>;
  heightUnit?: InputMaybe<Order_By>;
  hgId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  importJobId?: InputMaybe<Order_By>;
  language?: InputMaybe<Order_By>;
  lastName?: InputMaybe<Order_By>;
  leadStatus?: InputMaybe<Order_By>;
  middleName?: InputMaybe<Order_By>;
  patientId?: InputMaybe<Order_By>;
  patientReferenceNumber?: InputMaybe<Order_By>;
  patientWalletId?: InputMaybe<Order_By>;
  payrixCustomerId?: InputMaybe<Order_By>;
  phoneNumber?: InputMaybe<Order_By>;
  primaryLocationId?: InputMaybe<Order_By>;
  primaryProviderId?: InputMaybe<Order_By>;
  profileNote?: InputMaybe<Order_By>;
  profilePictureId?: InputMaybe<Order_By>;
  referringPatientId?: InputMaybe<Order_By>;
  sex?: InputMaybe<Order_By>;
  source?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  twilioConversationSid?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  weight?: InputMaybe<Order_By>;
  weightUnit?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type PatientWorkspace_Min_Fields = {
  __typename?: 'patientWorkspace_min_fields';
  balanceDue?: Maybe<Scalars['Int']>;
  clinicId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  dob?: Maybe<Scalars['date']>;
  dosespotPatientId?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  formattedPhoneNumber?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Float']>;
  heightUnit?: Maybe<Scalars['String']>;
  hgId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  importJobId?: Maybe<Scalars['uuid']>;
  language?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  leadStatus?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  patientId?: Maybe<Scalars['uuid']>;
  patientReferenceNumber?: Maybe<Scalars['Int']>;
  patientWalletId?: Maybe<Scalars['uuid']>;
  payrixCustomerId?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  primaryLocationId?: Maybe<Scalars['uuid']>;
  primaryProviderId?: Maybe<Scalars['uuid']>;
  profileNote?: Maybe<Scalars['String']>;
  profilePictureId?: Maybe<Scalars['uuid']>;
  referringPatientId?: Maybe<Scalars['uuid']>;
  sex?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  twilioConversationSid?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "patientWorkspace" */
export type PatientWorkspace_Min_Order_By = {
  balanceDue?: InputMaybe<Order_By>;
  clinicId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  dob?: InputMaybe<Order_By>;
  dosespotPatientId?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  firstName?: InputMaybe<Order_By>;
  formattedPhoneNumber?: InputMaybe<Order_By>;
  gender?: InputMaybe<Order_By>;
  height?: InputMaybe<Order_By>;
  heightUnit?: InputMaybe<Order_By>;
  hgId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  importJobId?: InputMaybe<Order_By>;
  language?: InputMaybe<Order_By>;
  lastName?: InputMaybe<Order_By>;
  leadStatus?: InputMaybe<Order_By>;
  middleName?: InputMaybe<Order_By>;
  patientId?: InputMaybe<Order_By>;
  patientReferenceNumber?: InputMaybe<Order_By>;
  patientWalletId?: InputMaybe<Order_By>;
  payrixCustomerId?: InputMaybe<Order_By>;
  phoneNumber?: InputMaybe<Order_By>;
  primaryLocationId?: InputMaybe<Order_By>;
  primaryProviderId?: InputMaybe<Order_By>;
  profileNote?: InputMaybe<Order_By>;
  profilePictureId?: InputMaybe<Order_By>;
  referringPatientId?: InputMaybe<Order_By>;
  sex?: InputMaybe<Order_By>;
  source?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  twilioConversationSid?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  weight?: InputMaybe<Order_By>;
  weightUnit?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "patientWorkspace" */
export type PatientWorkspace_Mutation_Response = {
  __typename?: 'patientWorkspace_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<PatientWorkspace>;
};

/** input type for inserting object relation for remote table "patientWorkspace" */
export type PatientWorkspace_Obj_Rel_Insert_Input = {
  data: PatientWorkspace_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<PatientWorkspace_On_Conflict>;
};

/** on_conflict condition type for table "patientWorkspace" */
export type PatientWorkspace_On_Conflict = {
  constraint: PatientWorkspace_Constraint;
  update_columns?: Array<PatientWorkspace_Update_Column>;
  where?: InputMaybe<PatientWorkspace_Bool_Exp>;
};

/** Ordering options when selecting data from "patientWorkspace". */
export type PatientWorkspace_Order_By = {
  archived?: InputMaybe<Order_By>;
  attributeValues?: InputMaybe<Order_By>;
  attributes?: InputMaybe<Order_By>;
  balanceDue?: InputMaybe<Order_By>;
  clinicId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  dob?: InputMaybe<Order_By>;
  dosespotPatientId?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  emailConfirmed?: InputMaybe<Order_By>;
  emailMarketingEnabled?: InputMaybe<Order_By>;
  firstName?: InputMaybe<Order_By>;
  formattedPhoneNumber?: InputMaybe<Order_By>;
  fullAnonymousMediaConsent?: InputMaybe<Order_By>;
  fullMediaConsent?: InputMaybe<Order_By>;
  gender?: InputMaybe<Order_By>;
  hasUnreadMessages?: InputMaybe<Order_By>;
  height?: InputMaybe<Order_By>;
  heightUnit?: InputMaybe<Order_By>;
  hgId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  importJobId?: InputMaybe<Order_By>;
  language?: InputMaybe<Order_By>;
  lastName?: InputMaybe<Order_By>;
  leadStatus?: InputMaybe<Order_By>;
  middleName?: InputMaybe<Order_By>;
  partialMediaConsent?: InputMaybe<Order_By>;
  patient?: InputMaybe<Patient_Order_By>;
  patientId?: InputMaybe<Order_By>;
  patientReferenceNumber?: InputMaybe<Order_By>;
  patientWallet?: InputMaybe<PatientWallet_Order_By>;
  patientWalletId?: InputMaybe<Order_By>;
  payrixCustomerId?: InputMaybe<Order_By>;
  phoneConfirmed?: InputMaybe<Order_By>;
  phoneMarketingEnabled?: InputMaybe<Order_By>;
  phoneNumber?: InputMaybe<Order_By>;
  primaryLocation?: InputMaybe<Location_Order_By>;
  primaryLocationId?: InputMaybe<Order_By>;
  primaryProvider?: InputMaybe<Provider_Order_By>;
  primaryProviderId?: InputMaybe<Order_By>;
  profileNote?: InputMaybe<Order_By>;
  profilePicture?: InputMaybe<Photo_Order_By>;
  profilePictureId?: InputMaybe<Order_By>;
  referringPatient?: InputMaybe<Patient_Order_By>;
  referringPatientId?: InputMaybe<Order_By>;
  sex?: InputMaybe<Order_By>;
  smsMarketingEnabled?: InputMaybe<Order_By>;
  source?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  textsearchable_index_col?: InputMaybe<Order_By>;
  twilioConversationSid?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  vitals_aggregate?: InputMaybe<Vitals_Aggregate_Order_By>;
  weight?: InputMaybe<Order_By>;
  weightUnit?: InputMaybe<Order_By>;
  workspace?: InputMaybe<Workspace_Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: patientWorkspace */
export type PatientWorkspace_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type PatientWorkspace_Prepend_Input = {
  attributeValues?: InputMaybe<Scalars['jsonb']>;
  attributes?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "patientWorkspace" */
export enum PatientWorkspace_Select_Column {
  /** column name */
  Archived = 'archived',
  /** column name */
  AttributeValues = 'attributeValues',
  /** column name */
  Attributes = 'attributes',
  /** column name */
  BalanceDue = 'balanceDue',
  /** column name */
  ClinicId = 'clinicId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Dob = 'dob',
  /** column name */
  DosespotPatientId = 'dosespotPatientId',
  /** column name */
  Email = 'email',
  /** column name */
  EmailConfirmed = 'emailConfirmed',
  /** column name */
  EmailMarketingEnabled = 'emailMarketingEnabled',
  /** column name */
  FirstName = 'firstName',
  /** column name */
  FormattedPhoneNumber = 'formattedPhoneNumber',
  /** column name */
  FullAnonymousMediaConsent = 'fullAnonymousMediaConsent',
  /** column name */
  FullMediaConsent = 'fullMediaConsent',
  /** column name */
  Gender = 'gender',
  /** column name */
  HasUnreadMessages = 'hasUnreadMessages',
  /** column name */
  Height = 'height',
  /** column name */
  HeightUnit = 'heightUnit',
  /** column name */
  HgId = 'hgId',
  /** column name */
  Id = 'id',
  /** column name */
  ImportJobId = 'importJobId',
  /** column name */
  Language = 'language',
  /** column name */
  LastName = 'lastName',
  /** column name */
  LeadStatus = 'leadStatus',
  /** column name */
  MiddleName = 'middleName',
  /** column name */
  PartialMediaConsent = 'partialMediaConsent',
  /** column name */
  PatientId = 'patientId',
  /** column name */
  PatientReferenceNumber = 'patientReferenceNumber',
  /** column name */
  PatientWalletId = 'patientWalletId',
  /** column name */
  PayrixCustomerId = 'payrixCustomerId',
  /** column name */
  PhoneConfirmed = 'phoneConfirmed',
  /** column name */
  PhoneMarketingEnabled = 'phoneMarketingEnabled',
  /** column name */
  PhoneNumber = 'phoneNumber',
  /** column name */
  PrimaryLocationId = 'primaryLocationId',
  /** column name */
  PrimaryProviderId = 'primaryProviderId',
  /** column name */
  ProfileNote = 'profileNote',
  /** column name */
  ProfilePictureId = 'profilePictureId',
  /** column name */
  ReferringPatientId = 'referringPatientId',
  /** column name */
  Sex = 'sex',
  /** column name */
  SmsMarketingEnabled = 'smsMarketingEnabled',
  /** column name */
  Source = 'source',
  /** column name */
  Status = 'status',
  /** column name */
  TextsearchableIndexCol = 'textsearchable_index_col',
  /** column name */
  TwilioConversationSid = 'twilioConversationSid',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Weight = 'weight',
  /** column name */
  WeightUnit = 'weightUnit',
  /** column name */
  WorkspaceId = 'workspaceId'
}

/** select "patientWorkspace_aggregate_bool_exp_bool_and_arguments_columns" columns of table "patientWorkspace" */
export enum PatientWorkspace_Select_Column_PatientWorkspace_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Archived = 'archived',
  /** column name */
  EmailConfirmed = 'emailConfirmed',
  /** column name */
  EmailMarketingEnabled = 'emailMarketingEnabled',
  /** column name */
  FullAnonymousMediaConsent = 'fullAnonymousMediaConsent',
  /** column name */
  FullMediaConsent = 'fullMediaConsent',
  /** column name */
  HasUnreadMessages = 'hasUnreadMessages',
  /** column name */
  PartialMediaConsent = 'partialMediaConsent',
  /** column name */
  PhoneConfirmed = 'phoneConfirmed',
  /** column name */
  PhoneMarketingEnabled = 'phoneMarketingEnabled',
  /** column name */
  SmsMarketingEnabled = 'smsMarketingEnabled'
}

/** select "patientWorkspace_aggregate_bool_exp_bool_or_arguments_columns" columns of table "patientWorkspace" */
export enum PatientWorkspace_Select_Column_PatientWorkspace_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Archived = 'archived',
  /** column name */
  EmailConfirmed = 'emailConfirmed',
  /** column name */
  EmailMarketingEnabled = 'emailMarketingEnabled',
  /** column name */
  FullAnonymousMediaConsent = 'fullAnonymousMediaConsent',
  /** column name */
  FullMediaConsent = 'fullMediaConsent',
  /** column name */
  HasUnreadMessages = 'hasUnreadMessages',
  /** column name */
  PartialMediaConsent = 'partialMediaConsent',
  /** column name */
  PhoneConfirmed = 'phoneConfirmed',
  /** column name */
  PhoneMarketingEnabled = 'phoneMarketingEnabled',
  /** column name */
  SmsMarketingEnabled = 'smsMarketingEnabled'
}

/** input type for updating data in table "patientWorkspace" */
export type PatientWorkspace_Set_Input = {
  archived?: InputMaybe<Scalars['Boolean']>;
  attributeValues?: InputMaybe<Scalars['jsonb']>;
  attributes?: InputMaybe<Scalars['jsonb']>;
  balanceDue?: InputMaybe<Scalars['Int']>;
  clinicId?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  dob?: InputMaybe<Scalars['date']>;
  dosespotPatientId?: InputMaybe<Scalars['Int']>;
  email?: InputMaybe<Scalars['String']>;
  emailConfirmed?: InputMaybe<Scalars['Boolean']>;
  emailMarketingEnabled?: InputMaybe<Scalars['Boolean']>;
  firstName?: InputMaybe<Scalars['String']>;
  formattedPhoneNumber?: InputMaybe<Scalars['String']>;
  fullAnonymousMediaConsent?: InputMaybe<Scalars['Boolean']>;
  fullMediaConsent?: InputMaybe<Scalars['Boolean']>;
  gender?: InputMaybe<Scalars['String']>;
  hasUnreadMessages?: InputMaybe<Scalars['Boolean']>;
  height?: InputMaybe<Scalars['Float']>;
  heightUnit?: InputMaybe<Scalars['String']>;
  hgId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  importJobId?: InputMaybe<Scalars['uuid']>;
  language?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  leadStatus?: InputMaybe<Scalars['String']>;
  middleName?: InputMaybe<Scalars['String']>;
  partialMediaConsent?: InputMaybe<Scalars['Boolean']>;
  patientId?: InputMaybe<Scalars['uuid']>;
  patientReferenceNumber?: InputMaybe<Scalars['Int']>;
  patientWalletId?: InputMaybe<Scalars['uuid']>;
  payrixCustomerId?: InputMaybe<Scalars['String']>;
  phoneConfirmed?: InputMaybe<Scalars['Boolean']>;
  phoneMarketingEnabled?: InputMaybe<Scalars['Boolean']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  primaryLocationId?: InputMaybe<Scalars['uuid']>;
  primaryProviderId?: InputMaybe<Scalars['uuid']>;
  profileNote?: InputMaybe<Scalars['String']>;
  profilePictureId?: InputMaybe<Scalars['uuid']>;
  referringPatientId?: InputMaybe<Scalars['uuid']>;
  sex?: InputMaybe<Scalars['String']>;
  smsMarketingEnabled?: InputMaybe<Scalars['Boolean']>;
  source?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  twilioConversationSid?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  weight?: InputMaybe<Scalars['Float']>;
  weightUnit?: InputMaybe<Scalars['String']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type PatientWorkspace_Stddev_Fields = {
  __typename?: 'patientWorkspace_stddev_fields';
  balanceDue?: Maybe<Scalars['Float']>;
  clinicId?: Maybe<Scalars['Float']>;
  dosespotPatientId?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  patientReferenceNumber?: Maybe<Scalars['Float']>;
  weight?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "patientWorkspace" */
export type PatientWorkspace_Stddev_Order_By = {
  balanceDue?: InputMaybe<Order_By>;
  clinicId?: InputMaybe<Order_By>;
  dosespotPatientId?: InputMaybe<Order_By>;
  height?: InputMaybe<Order_By>;
  patientReferenceNumber?: InputMaybe<Order_By>;
  weight?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type PatientWorkspace_Stddev_Pop_Fields = {
  __typename?: 'patientWorkspace_stddev_pop_fields';
  balanceDue?: Maybe<Scalars['Float']>;
  clinicId?: Maybe<Scalars['Float']>;
  dosespotPatientId?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  patientReferenceNumber?: Maybe<Scalars['Float']>;
  weight?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "patientWorkspace" */
export type PatientWorkspace_Stddev_Pop_Order_By = {
  balanceDue?: InputMaybe<Order_By>;
  clinicId?: InputMaybe<Order_By>;
  dosespotPatientId?: InputMaybe<Order_By>;
  height?: InputMaybe<Order_By>;
  patientReferenceNumber?: InputMaybe<Order_By>;
  weight?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type PatientWorkspace_Stddev_Samp_Fields = {
  __typename?: 'patientWorkspace_stddev_samp_fields';
  balanceDue?: Maybe<Scalars['Float']>;
  clinicId?: Maybe<Scalars['Float']>;
  dosespotPatientId?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  patientReferenceNumber?: Maybe<Scalars['Float']>;
  weight?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "patientWorkspace" */
export type PatientWorkspace_Stddev_Samp_Order_By = {
  balanceDue?: InputMaybe<Order_By>;
  clinicId?: InputMaybe<Order_By>;
  dosespotPatientId?: InputMaybe<Order_By>;
  height?: InputMaybe<Order_By>;
  patientReferenceNumber?: InputMaybe<Order_By>;
  weight?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "patientWorkspace" */
export type PatientWorkspace_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: PatientWorkspace_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type PatientWorkspace_Stream_Cursor_Value_Input = {
  archived?: InputMaybe<Scalars['Boolean']>;
  attributeValues?: InputMaybe<Scalars['jsonb']>;
  attributes?: InputMaybe<Scalars['jsonb']>;
  balanceDue?: InputMaybe<Scalars['Int']>;
  clinicId?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  dob?: InputMaybe<Scalars['date']>;
  dosespotPatientId?: InputMaybe<Scalars['Int']>;
  email?: InputMaybe<Scalars['String']>;
  emailConfirmed?: InputMaybe<Scalars['Boolean']>;
  emailMarketingEnabled?: InputMaybe<Scalars['Boolean']>;
  firstName?: InputMaybe<Scalars['String']>;
  formattedPhoneNumber?: InputMaybe<Scalars['String']>;
  fullAnonymousMediaConsent?: InputMaybe<Scalars['Boolean']>;
  fullMediaConsent?: InputMaybe<Scalars['Boolean']>;
  gender?: InputMaybe<Scalars['String']>;
  hasUnreadMessages?: InputMaybe<Scalars['Boolean']>;
  height?: InputMaybe<Scalars['Float']>;
  heightUnit?: InputMaybe<Scalars['String']>;
  hgId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  importJobId?: InputMaybe<Scalars['uuid']>;
  language?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  leadStatus?: InputMaybe<Scalars['String']>;
  middleName?: InputMaybe<Scalars['String']>;
  partialMediaConsent?: InputMaybe<Scalars['Boolean']>;
  patientId?: InputMaybe<Scalars['uuid']>;
  patientReferenceNumber?: InputMaybe<Scalars['Int']>;
  patientWalletId?: InputMaybe<Scalars['uuid']>;
  payrixCustomerId?: InputMaybe<Scalars['String']>;
  phoneConfirmed?: InputMaybe<Scalars['Boolean']>;
  phoneMarketingEnabled?: InputMaybe<Scalars['Boolean']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  primaryLocationId?: InputMaybe<Scalars['uuid']>;
  primaryProviderId?: InputMaybe<Scalars['uuid']>;
  profileNote?: InputMaybe<Scalars['String']>;
  profilePictureId?: InputMaybe<Scalars['uuid']>;
  referringPatientId?: InputMaybe<Scalars['uuid']>;
  sex?: InputMaybe<Scalars['String']>;
  smsMarketingEnabled?: InputMaybe<Scalars['Boolean']>;
  source?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  textsearchable_index_col?: InputMaybe<Scalars['tsvector']>;
  twilioConversationSid?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  weight?: InputMaybe<Scalars['Float']>;
  weightUnit?: InputMaybe<Scalars['String']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type PatientWorkspace_Sum_Fields = {
  __typename?: 'patientWorkspace_sum_fields';
  balanceDue?: Maybe<Scalars['Int']>;
  clinicId?: Maybe<Scalars['Int']>;
  dosespotPatientId?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Float']>;
  patientReferenceNumber?: Maybe<Scalars['Int']>;
  weight?: Maybe<Scalars['Float']>;
};

/** order by sum() on columns of table "patientWorkspace" */
export type PatientWorkspace_Sum_Order_By = {
  balanceDue?: InputMaybe<Order_By>;
  clinicId?: InputMaybe<Order_By>;
  dosespotPatientId?: InputMaybe<Order_By>;
  height?: InputMaybe<Order_By>;
  patientReferenceNumber?: InputMaybe<Order_By>;
  weight?: InputMaybe<Order_By>;
};

/** update columns of table "patientWorkspace" */
export enum PatientWorkspace_Update_Column {
  /** column name */
  Archived = 'archived',
  /** column name */
  AttributeValues = 'attributeValues',
  /** column name */
  Attributes = 'attributes',
  /** column name */
  BalanceDue = 'balanceDue',
  /** column name */
  ClinicId = 'clinicId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Dob = 'dob',
  /** column name */
  DosespotPatientId = 'dosespotPatientId',
  /** column name */
  Email = 'email',
  /** column name */
  EmailConfirmed = 'emailConfirmed',
  /** column name */
  EmailMarketingEnabled = 'emailMarketingEnabled',
  /** column name */
  FirstName = 'firstName',
  /** column name */
  FormattedPhoneNumber = 'formattedPhoneNumber',
  /** column name */
  FullAnonymousMediaConsent = 'fullAnonymousMediaConsent',
  /** column name */
  FullMediaConsent = 'fullMediaConsent',
  /** column name */
  Gender = 'gender',
  /** column name */
  HasUnreadMessages = 'hasUnreadMessages',
  /** column name */
  Height = 'height',
  /** column name */
  HeightUnit = 'heightUnit',
  /** column name */
  HgId = 'hgId',
  /** column name */
  Id = 'id',
  /** column name */
  ImportJobId = 'importJobId',
  /** column name */
  Language = 'language',
  /** column name */
  LastName = 'lastName',
  /** column name */
  LeadStatus = 'leadStatus',
  /** column name */
  MiddleName = 'middleName',
  /** column name */
  PartialMediaConsent = 'partialMediaConsent',
  /** column name */
  PatientId = 'patientId',
  /** column name */
  PatientReferenceNumber = 'patientReferenceNumber',
  /** column name */
  PatientWalletId = 'patientWalletId',
  /** column name */
  PayrixCustomerId = 'payrixCustomerId',
  /** column name */
  PhoneConfirmed = 'phoneConfirmed',
  /** column name */
  PhoneMarketingEnabled = 'phoneMarketingEnabled',
  /** column name */
  PhoneNumber = 'phoneNumber',
  /** column name */
  PrimaryLocationId = 'primaryLocationId',
  /** column name */
  PrimaryProviderId = 'primaryProviderId',
  /** column name */
  ProfileNote = 'profileNote',
  /** column name */
  ProfilePictureId = 'profilePictureId',
  /** column name */
  ReferringPatientId = 'referringPatientId',
  /** column name */
  Sex = 'sex',
  /** column name */
  SmsMarketingEnabled = 'smsMarketingEnabled',
  /** column name */
  Source = 'source',
  /** column name */
  Status = 'status',
  /** column name */
  TwilioConversationSid = 'twilioConversationSid',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Weight = 'weight',
  /** column name */
  WeightUnit = 'weightUnit',
  /** column name */
  WorkspaceId = 'workspaceId'
}

export type PatientWorkspace_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<PatientWorkspace_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<PatientWorkspace_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<PatientWorkspace_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<PatientWorkspace_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<PatientWorkspace_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<PatientWorkspace_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<PatientWorkspace_Set_Input>;
  /** filter the rows which have to be updated */
  where: PatientWorkspace_Bool_Exp;
};

/** aggregate var_pop on columns */
export type PatientWorkspace_Var_Pop_Fields = {
  __typename?: 'patientWorkspace_var_pop_fields';
  balanceDue?: Maybe<Scalars['Float']>;
  clinicId?: Maybe<Scalars['Float']>;
  dosespotPatientId?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  patientReferenceNumber?: Maybe<Scalars['Float']>;
  weight?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "patientWorkspace" */
export type PatientWorkspace_Var_Pop_Order_By = {
  balanceDue?: InputMaybe<Order_By>;
  clinicId?: InputMaybe<Order_By>;
  dosespotPatientId?: InputMaybe<Order_By>;
  height?: InputMaybe<Order_By>;
  patientReferenceNumber?: InputMaybe<Order_By>;
  weight?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type PatientWorkspace_Var_Samp_Fields = {
  __typename?: 'patientWorkspace_var_samp_fields';
  balanceDue?: Maybe<Scalars['Float']>;
  clinicId?: Maybe<Scalars['Float']>;
  dosespotPatientId?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  patientReferenceNumber?: Maybe<Scalars['Float']>;
  weight?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "patientWorkspace" */
export type PatientWorkspace_Var_Samp_Order_By = {
  balanceDue?: InputMaybe<Order_By>;
  clinicId?: InputMaybe<Order_By>;
  dosespotPatientId?: InputMaybe<Order_By>;
  height?: InputMaybe<Order_By>;
  patientReferenceNumber?: InputMaybe<Order_By>;
  weight?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type PatientWorkspace_Variance_Fields = {
  __typename?: 'patientWorkspace_variance_fields';
  balanceDue?: Maybe<Scalars['Float']>;
  clinicId?: Maybe<Scalars['Float']>;
  dosespotPatientId?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  patientReferenceNumber?: Maybe<Scalars['Float']>;
  weight?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "patientWorkspace" */
export type PatientWorkspace_Variance_Order_By = {
  balanceDue?: InputMaybe<Order_By>;
  clinicId?: InputMaybe<Order_By>;
  dosespotPatientId?: InputMaybe<Order_By>;
  height?: InputMaybe<Order_By>;
  patientReferenceNumber?: InputMaybe<Order_By>;
  weight?: InputMaybe<Order_By>;
};

/** aggregated selection of "patient" */
export type Patient_Aggregate = {
  __typename?: 'patient_aggregate';
  aggregate?: Maybe<Patient_Aggregate_Fields>;
  nodes: Array<Patient>;
};

/** aggregate fields of "patient" */
export type Patient_Aggregate_Fields = {
  __typename?: 'patient_aggregate_fields';
  avg?: Maybe<Patient_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Patient_Max_Fields>;
  min?: Maybe<Patient_Min_Fields>;
  stddev?: Maybe<Patient_Stddev_Fields>;
  stddev_pop?: Maybe<Patient_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Patient_Stddev_Samp_Fields>;
  sum?: Maybe<Patient_Sum_Fields>;
  var_pop?: Maybe<Patient_Var_Pop_Fields>;
  var_samp?: Maybe<Patient_Var_Samp_Fields>;
  variance?: Maybe<Patient_Variance_Fields>;
};


/** aggregate fields of "patient" */
export type Patient_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Patient_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Patient_Avg_Fields = {
  __typename?: 'patient_avg_fields';
  balanceDue?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "patient". All fields are combined with a logical 'AND'. */
export type Patient_Bool_Exp = {
  _and?: InputMaybe<Array<Patient_Bool_Exp>>;
  _not?: InputMaybe<Patient_Bool_Exp>;
  _or?: InputMaybe<Array<Patient_Bool_Exp>>;
  activityLogs?: InputMaybe<ActivityLog_Bool_Exp>;
  activityLogs_aggregate?: InputMaybe<ActivityLog_Aggregate_Bool_Exp>;
  appointments?: InputMaybe<Appointment_Bool_Exp>;
  appointments_aggregate?: InputMaybe<Appointment_Aggregate_Bool_Exp>;
  balanceDue?: InputMaybe<Int_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  dob?: InputMaybe<String_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  finixId?: InputMaybe<String_Comparison_Exp>;
  firebaseUid?: InputMaybe<String_Comparison_Exp>;
  firstName?: InputMaybe<String_Comparison_Exp>;
  gender?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  importJobId?: InputMaybe<Uuid_Comparison_Exp>;
  invoices?: InputMaybe<Invoice_Bool_Exp>;
  invoices_aggregate?: InputMaybe<Invoice_Aggregate_Bool_Exp>;
  language?: InputMaybe<String_Comparison_Exp>;
  lastName?: InputMaybe<String_Comparison_Exp>;
  leadFormEntries?: InputMaybe<LeadFormEntry_Bool_Exp>;
  leadFormEntries_aggregate?: InputMaybe<LeadFormEntry_Aggregate_Bool_Exp>;
  middleName?: InputMaybe<String_Comparison_Exp>;
  notes?: InputMaybe<Note_Bool_Exp>;
  notes_aggregate?: InputMaybe<Note_Aggregate_Bool_Exp>;
  patientAddresses?: InputMaybe<PatientAddress_Bool_Exp>;
  patientAddresses_aggregate?: InputMaybe<PatientAddress_Aggregate_Bool_Exp>;
  patientMemberships?: InputMaybe<PatientMembership_Bool_Exp>;
  patientMemberships_aggregate?: InputMaybe<PatientMembership_Aggregate_Bool_Exp>;
  patientTags?: InputMaybe<PatientTags_Bool_Exp>;
  patientTags_aggregate?: InputMaybe<PatientTags_Aggregate_Bool_Exp>;
  patientWallets?: InputMaybe<PatientWallet_Bool_Exp>;
  patientWallets_aggregate?: InputMaybe<PatientWallet_Aggregate_Bool_Exp>;
  patientWorkspaces?: InputMaybe<PatientWorkspace_Bool_Exp>;
  patientWorkspaces_aggregate?: InputMaybe<PatientWorkspace_Aggregate_Bool_Exp>;
  phoneNumber?: InputMaybe<String_Comparison_Exp>;
  photos?: InputMaybe<Photo_Bool_Exp>;
  photos_aggregate?: InputMaybe<Photo_Aggregate_Bool_Exp>;
  profilePicture?: InputMaybe<Photo_Bool_Exp>;
  profilePictureId?: InputMaybe<Uuid_Comparison_Exp>;
  rewardProgramEnrollments?: InputMaybe<RewardProgramEnrollment_Bool_Exp>;
  rewardProgramEnrollments_aggregate?: InputMaybe<RewardProgramEnrollment_Aggregate_Bool_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "patient" */
export enum Patient_Constraint {
  /** unique or primary key constraint on columns "email" */
  PatientEmailKey = 'patient_email_key',
  /** unique or primary key constraint on columns "id" */
  PatientPkey = 'patient_pkey'
}

/** input type for incrementing numeric columns in table "patient" */
export type Patient_Inc_Input = {
  balanceDue?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "patient" */
export type Patient_Insert_Input = {
  activityLogs?: InputMaybe<ActivityLog_Arr_Rel_Insert_Input>;
  appointments?: InputMaybe<Appointment_Arr_Rel_Insert_Input>;
  balanceDue?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  dob?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  finixId?: InputMaybe<Scalars['String']>;
  firebaseUid?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  importJobId?: InputMaybe<Scalars['uuid']>;
  invoices?: InputMaybe<Invoice_Arr_Rel_Insert_Input>;
  language?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  leadFormEntries?: InputMaybe<LeadFormEntry_Arr_Rel_Insert_Input>;
  middleName?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Note_Arr_Rel_Insert_Input>;
  patientAddresses?: InputMaybe<PatientAddress_Arr_Rel_Insert_Input>;
  patientMemberships?: InputMaybe<PatientMembership_Arr_Rel_Insert_Input>;
  patientTags?: InputMaybe<PatientTags_Arr_Rel_Insert_Input>;
  patientWallets?: InputMaybe<PatientWallet_Arr_Rel_Insert_Input>;
  patientWorkspaces?: InputMaybe<PatientWorkspace_Arr_Rel_Insert_Input>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  photos?: InputMaybe<Photo_Arr_Rel_Insert_Input>;
  profilePicture?: InputMaybe<Photo_Obj_Rel_Insert_Input>;
  profilePictureId?: InputMaybe<Scalars['uuid']>;
  rewardProgramEnrollments?: InputMaybe<RewardProgramEnrollment_Arr_Rel_Insert_Input>;
  status?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Patient_Max_Fields = {
  __typename?: 'patient_max_fields';
  balanceDue?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  dob?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  finixId?: Maybe<Scalars['String']>;
  firebaseUid?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  importJobId?: Maybe<Scalars['uuid']>;
  language?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  profilePictureId?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Patient_Min_Fields = {
  __typename?: 'patient_min_fields';
  balanceDue?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  dob?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  finixId?: Maybe<Scalars['String']>;
  firebaseUid?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  importJobId?: Maybe<Scalars['uuid']>;
  language?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  profilePictureId?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "patient" */
export type Patient_Mutation_Response = {
  __typename?: 'patient_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Patient>;
};

/** input type for inserting object relation for remote table "patient" */
export type Patient_Obj_Rel_Insert_Input = {
  data: Patient_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Patient_On_Conflict>;
};

/** on_conflict condition type for table "patient" */
export type Patient_On_Conflict = {
  constraint: Patient_Constraint;
  update_columns?: Array<Patient_Update_Column>;
  where?: InputMaybe<Patient_Bool_Exp>;
};

/** Ordering options when selecting data from "patient". */
export type Patient_Order_By = {
  activityLogs_aggregate?: InputMaybe<ActivityLog_Aggregate_Order_By>;
  appointments_aggregate?: InputMaybe<Appointment_Aggregate_Order_By>;
  balanceDue?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  dob?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  finixId?: InputMaybe<Order_By>;
  firebaseUid?: InputMaybe<Order_By>;
  firstName?: InputMaybe<Order_By>;
  gender?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  importJobId?: InputMaybe<Order_By>;
  invoices_aggregate?: InputMaybe<Invoice_Aggregate_Order_By>;
  language?: InputMaybe<Order_By>;
  lastName?: InputMaybe<Order_By>;
  leadFormEntries_aggregate?: InputMaybe<LeadFormEntry_Aggregate_Order_By>;
  middleName?: InputMaybe<Order_By>;
  notes_aggregate?: InputMaybe<Note_Aggregate_Order_By>;
  patientAddresses_aggregate?: InputMaybe<PatientAddress_Aggregate_Order_By>;
  patientMemberships_aggregate?: InputMaybe<PatientMembership_Aggregate_Order_By>;
  patientTags_aggregate?: InputMaybe<PatientTags_Aggregate_Order_By>;
  patientWallets_aggregate?: InputMaybe<PatientWallet_Aggregate_Order_By>;
  patientWorkspaces_aggregate?: InputMaybe<PatientWorkspace_Aggregate_Order_By>;
  phoneNumber?: InputMaybe<Order_By>;
  photos_aggregate?: InputMaybe<Photo_Aggregate_Order_By>;
  profilePicture?: InputMaybe<Photo_Order_By>;
  profilePictureId?: InputMaybe<Order_By>;
  rewardProgramEnrollments_aggregate?: InputMaybe<RewardProgramEnrollment_Aggregate_Order_By>;
  status?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: patient */
export type Patient_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "patient" */
export enum Patient_Select_Column {
  /** column name */
  BalanceDue = 'balanceDue',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Dob = 'dob',
  /** column name */
  Email = 'email',
  /** column name */
  FinixId = 'finixId',
  /** column name */
  FirebaseUid = 'firebaseUid',
  /** column name */
  FirstName = 'firstName',
  /** column name */
  Gender = 'gender',
  /** column name */
  Id = 'id',
  /** column name */
  ImportJobId = 'importJobId',
  /** column name */
  Language = 'language',
  /** column name */
  LastName = 'lastName',
  /** column name */
  MiddleName = 'middleName',
  /** column name */
  PhoneNumber = 'phoneNumber',
  /** column name */
  ProfilePictureId = 'profilePictureId',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "patient" */
export type Patient_Set_Input = {
  balanceDue?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  dob?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  finixId?: InputMaybe<Scalars['String']>;
  firebaseUid?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  importJobId?: InputMaybe<Scalars['uuid']>;
  language?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  middleName?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  profilePictureId?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Patient_Stddev_Fields = {
  __typename?: 'patient_stddev_fields';
  balanceDue?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Patient_Stddev_Pop_Fields = {
  __typename?: 'patient_stddev_pop_fields';
  balanceDue?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Patient_Stddev_Samp_Fields = {
  __typename?: 'patient_stddev_samp_fields';
  balanceDue?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "patient" */
export type Patient_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Patient_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Patient_Stream_Cursor_Value_Input = {
  balanceDue?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  dob?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  finixId?: InputMaybe<Scalars['String']>;
  firebaseUid?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  importJobId?: InputMaybe<Scalars['uuid']>;
  language?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  middleName?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  profilePictureId?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Patient_Sum_Fields = {
  __typename?: 'patient_sum_fields';
  balanceDue?: Maybe<Scalars['Int']>;
};

/** update columns of table "patient" */
export enum Patient_Update_Column {
  /** column name */
  BalanceDue = 'balanceDue',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Dob = 'dob',
  /** column name */
  Email = 'email',
  /** column name */
  FinixId = 'finixId',
  /** column name */
  FirebaseUid = 'firebaseUid',
  /** column name */
  FirstName = 'firstName',
  /** column name */
  Gender = 'gender',
  /** column name */
  Id = 'id',
  /** column name */
  ImportJobId = 'importJobId',
  /** column name */
  Language = 'language',
  /** column name */
  LastName = 'lastName',
  /** column name */
  MiddleName = 'middleName',
  /** column name */
  PhoneNumber = 'phoneNumber',
  /** column name */
  ProfilePictureId = 'profilePictureId',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Patient_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Patient_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Patient_Set_Input>;
  /** filter the rows which have to be updated */
  where: Patient_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Patient_Var_Pop_Fields = {
  __typename?: 'patient_var_pop_fields';
  balanceDue?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Patient_Var_Samp_Fields = {
  __typename?: 'patient_var_samp_fields';
  balanceDue?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Patient_Variance_Fields = {
  __typename?: 'patient_variance_fields';
  balanceDue?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "payment" */
export type Payment = {
  __typename?: 'payment';
  amount: Scalars['Int'];
  createdAt: Scalars['timestamptz'];
  finixMerchantId?: Maybe<Scalars['String']>;
  finixStatus?: Maybe<Scalars['String']>;
  finixTransferId?: Maybe<Scalars['String']>;
  finixType?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  /** An object relationship */
  invoice?: Maybe<Invoice>;
  invoiceId?: Maybe<Scalars['uuid']>;
  isArchived?: Maybe<Scalars['Boolean']>;
  /** An array relationship */
  parentPaymentRelationships: Array<PaymentRelationship>;
  /** An aggregate relationship */
  parentPaymentRelationships_aggregate: PaymentRelationship_Aggregate;
  patientId?: Maybe<Scalars['uuid']>;
  patientMembershipId?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  paymentMethod?: Maybe<PaymentMethod>;
  paymentMethodId?: Maybe<Scalars['uuid']>;
  paymentMethodType: Scalars['String'];
  payrixMerchantId?: Maybe<Scalars['String']>;
  payrixStatus?: Maybe<Scalars['String']>;
  payrixTerminalTransactionId?: Maybe<Scalars['String']>;
  payrixTransactionId?: Maybe<Scalars['String']>;
  payrixType?: Maybe<Scalars['String']>;
  receipt?: Maybe<FileOnlyUrl>;
  /** An array relationship */
  refunds: Array<Refund>;
  /** An aggregate relationship */
  refunds_aggregate: Refund_Aggregate;
  status: Scalars['String'];
  type?: Maybe<Scalars['String']>;
  updatedAt: Scalars['timestamptz'];
  /** An object relationship */
  workspace: Workspace;
  workspaceId: Scalars['uuid'];
};


/** columns and relationships of "payment" */
export type PaymentParentPaymentRelationshipsArgs = {
  distinct_on?: InputMaybe<Array<PaymentRelationship_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PaymentRelationship_Order_By>>;
  where?: InputMaybe<PaymentRelationship_Bool_Exp>;
};


/** columns and relationships of "payment" */
export type PaymentParentPaymentRelationships_AggregateArgs = {
  distinct_on?: InputMaybe<Array<PaymentRelationship_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PaymentRelationship_Order_By>>;
  where?: InputMaybe<PaymentRelationship_Bool_Exp>;
};


/** columns and relationships of "payment" */
export type PaymentRefundsArgs = {
  distinct_on?: InputMaybe<Array<Refund_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Refund_Order_By>>;
  where?: InputMaybe<Refund_Bool_Exp>;
};


/** columns and relationships of "payment" */
export type PaymentRefunds_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Refund_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Refund_Order_By>>;
  where?: InputMaybe<Refund_Bool_Exp>;
};

/** columns and relationships of "paymentMethod" */
export type PaymentMethod = {
  __typename?: 'paymentMethod';
  archived: Scalars['Boolean'];
  brand?: Maybe<Scalars['String']>;
  cardHolderName?: Maybe<Scalars['String']>;
  createdAt: Scalars['timestamptz'];
  default: Scalars['Boolean'];
  expirationMonth?: Maybe<Scalars['Int']>;
  expirationYear?: Maybe<Scalars['Int']>;
  id: Scalars['uuid'];
  last4: Scalars['String'];
  patientId?: Maybe<Scalars['uuid']>;
  payrixCustomerId?: Maybe<Scalars['String']>;
  processor?: Maybe<Scalars['String']>;
  token: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  validity: Scalars['String'];
  workspaceId: Scalars['uuid'];
};

/** aggregated selection of "paymentMethod" */
export type PaymentMethod_Aggregate = {
  __typename?: 'paymentMethod_aggregate';
  aggregate?: Maybe<PaymentMethod_Aggregate_Fields>;
  nodes: Array<PaymentMethod>;
};

/** aggregate fields of "paymentMethod" */
export type PaymentMethod_Aggregate_Fields = {
  __typename?: 'paymentMethod_aggregate_fields';
  avg?: Maybe<PaymentMethod_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<PaymentMethod_Max_Fields>;
  min?: Maybe<PaymentMethod_Min_Fields>;
  stddev?: Maybe<PaymentMethod_Stddev_Fields>;
  stddev_pop?: Maybe<PaymentMethod_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<PaymentMethod_Stddev_Samp_Fields>;
  sum?: Maybe<PaymentMethod_Sum_Fields>;
  var_pop?: Maybe<PaymentMethod_Var_Pop_Fields>;
  var_samp?: Maybe<PaymentMethod_Var_Samp_Fields>;
  variance?: Maybe<PaymentMethod_Variance_Fields>;
};


/** aggregate fields of "paymentMethod" */
export type PaymentMethod_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<PaymentMethod_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type PaymentMethod_Avg_Fields = {
  __typename?: 'paymentMethod_avg_fields';
  expirationMonth?: Maybe<Scalars['Float']>;
  expirationYear?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "paymentMethod". All fields are combined with a logical 'AND'. */
export type PaymentMethod_Bool_Exp = {
  _and?: InputMaybe<Array<PaymentMethod_Bool_Exp>>;
  _not?: InputMaybe<PaymentMethod_Bool_Exp>;
  _or?: InputMaybe<Array<PaymentMethod_Bool_Exp>>;
  archived?: InputMaybe<Boolean_Comparison_Exp>;
  brand?: InputMaybe<String_Comparison_Exp>;
  cardHolderName?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  default?: InputMaybe<Boolean_Comparison_Exp>;
  expirationMonth?: InputMaybe<Int_Comparison_Exp>;
  expirationYear?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  last4?: InputMaybe<String_Comparison_Exp>;
  patientId?: InputMaybe<Uuid_Comparison_Exp>;
  payrixCustomerId?: InputMaybe<String_Comparison_Exp>;
  processor?: InputMaybe<String_Comparison_Exp>;
  token?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  validity?: InputMaybe<String_Comparison_Exp>;
  workspaceId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "paymentMethod" */
export enum PaymentMethod_Constraint {
  /** unique or primary key constraint on columns "id" */
  PaymentMethodPkey = 'paymentMethod_pkey'
}

/** input type for incrementing numeric columns in table "paymentMethod" */
export type PaymentMethod_Inc_Input = {
  expirationMonth?: InputMaybe<Scalars['Int']>;
  expirationYear?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "paymentMethod" */
export type PaymentMethod_Insert_Input = {
  archived?: InputMaybe<Scalars['Boolean']>;
  brand?: InputMaybe<Scalars['String']>;
  cardHolderName?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  default?: InputMaybe<Scalars['Boolean']>;
  expirationMonth?: InputMaybe<Scalars['Int']>;
  expirationYear?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  last4?: InputMaybe<Scalars['String']>;
  patientId?: InputMaybe<Scalars['uuid']>;
  payrixCustomerId?: InputMaybe<Scalars['String']>;
  processor?: InputMaybe<Scalars['String']>;
  token?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  validity?: InputMaybe<Scalars['String']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type PaymentMethod_Max_Fields = {
  __typename?: 'paymentMethod_max_fields';
  brand?: Maybe<Scalars['String']>;
  cardHolderName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  expirationMonth?: Maybe<Scalars['Int']>;
  expirationYear?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  last4?: Maybe<Scalars['String']>;
  patientId?: Maybe<Scalars['uuid']>;
  payrixCustomerId?: Maybe<Scalars['String']>;
  processor?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  validity?: Maybe<Scalars['String']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type PaymentMethod_Min_Fields = {
  __typename?: 'paymentMethod_min_fields';
  brand?: Maybe<Scalars['String']>;
  cardHolderName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  expirationMonth?: Maybe<Scalars['Int']>;
  expirationYear?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  last4?: Maybe<Scalars['String']>;
  patientId?: Maybe<Scalars['uuid']>;
  payrixCustomerId?: Maybe<Scalars['String']>;
  processor?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  validity?: Maybe<Scalars['String']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "paymentMethod" */
export type PaymentMethod_Mutation_Response = {
  __typename?: 'paymentMethod_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<PaymentMethod>;
};

/** input type for inserting object relation for remote table "paymentMethod" */
export type PaymentMethod_Obj_Rel_Insert_Input = {
  data: PaymentMethod_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<PaymentMethod_On_Conflict>;
};

/** on_conflict condition type for table "paymentMethod" */
export type PaymentMethod_On_Conflict = {
  constraint: PaymentMethod_Constraint;
  update_columns?: Array<PaymentMethod_Update_Column>;
  where?: InputMaybe<PaymentMethod_Bool_Exp>;
};

/** Ordering options when selecting data from "paymentMethod". */
export type PaymentMethod_Order_By = {
  archived?: InputMaybe<Order_By>;
  brand?: InputMaybe<Order_By>;
  cardHolderName?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  default?: InputMaybe<Order_By>;
  expirationMonth?: InputMaybe<Order_By>;
  expirationYear?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last4?: InputMaybe<Order_By>;
  patientId?: InputMaybe<Order_By>;
  payrixCustomerId?: InputMaybe<Order_By>;
  processor?: InputMaybe<Order_By>;
  token?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  validity?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: paymentMethod */
export type PaymentMethod_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "paymentMethod" */
export enum PaymentMethod_Select_Column {
  /** column name */
  Archived = 'archived',
  /** column name */
  Brand = 'brand',
  /** column name */
  CardHolderName = 'cardHolderName',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Default = 'default',
  /** column name */
  ExpirationMonth = 'expirationMonth',
  /** column name */
  ExpirationYear = 'expirationYear',
  /** column name */
  Id = 'id',
  /** column name */
  Last4 = 'last4',
  /** column name */
  PatientId = 'patientId',
  /** column name */
  PayrixCustomerId = 'payrixCustomerId',
  /** column name */
  Processor = 'processor',
  /** column name */
  Token = 'token',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Validity = 'validity',
  /** column name */
  WorkspaceId = 'workspaceId'
}

/** input type for updating data in table "paymentMethod" */
export type PaymentMethod_Set_Input = {
  archived?: InputMaybe<Scalars['Boolean']>;
  brand?: InputMaybe<Scalars['String']>;
  cardHolderName?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  default?: InputMaybe<Scalars['Boolean']>;
  expirationMonth?: InputMaybe<Scalars['Int']>;
  expirationYear?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  last4?: InputMaybe<Scalars['String']>;
  patientId?: InputMaybe<Scalars['uuid']>;
  payrixCustomerId?: InputMaybe<Scalars['String']>;
  processor?: InputMaybe<Scalars['String']>;
  token?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  validity?: InputMaybe<Scalars['String']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type PaymentMethod_Stddev_Fields = {
  __typename?: 'paymentMethod_stddev_fields';
  expirationMonth?: Maybe<Scalars['Float']>;
  expirationYear?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type PaymentMethod_Stddev_Pop_Fields = {
  __typename?: 'paymentMethod_stddev_pop_fields';
  expirationMonth?: Maybe<Scalars['Float']>;
  expirationYear?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type PaymentMethod_Stddev_Samp_Fields = {
  __typename?: 'paymentMethod_stddev_samp_fields';
  expirationMonth?: Maybe<Scalars['Float']>;
  expirationYear?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "paymentMethod" */
export type PaymentMethod_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: PaymentMethod_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type PaymentMethod_Stream_Cursor_Value_Input = {
  archived?: InputMaybe<Scalars['Boolean']>;
  brand?: InputMaybe<Scalars['String']>;
  cardHolderName?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  default?: InputMaybe<Scalars['Boolean']>;
  expirationMonth?: InputMaybe<Scalars['Int']>;
  expirationYear?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  last4?: InputMaybe<Scalars['String']>;
  patientId?: InputMaybe<Scalars['uuid']>;
  payrixCustomerId?: InputMaybe<Scalars['String']>;
  processor?: InputMaybe<Scalars['String']>;
  token?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  validity?: InputMaybe<Scalars['String']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type PaymentMethod_Sum_Fields = {
  __typename?: 'paymentMethod_sum_fields';
  expirationMonth?: Maybe<Scalars['Int']>;
  expirationYear?: Maybe<Scalars['Int']>;
};

/** update columns of table "paymentMethod" */
export enum PaymentMethod_Update_Column {
  /** column name */
  Archived = 'archived',
  /** column name */
  Brand = 'brand',
  /** column name */
  CardHolderName = 'cardHolderName',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Default = 'default',
  /** column name */
  ExpirationMonth = 'expirationMonth',
  /** column name */
  ExpirationYear = 'expirationYear',
  /** column name */
  Id = 'id',
  /** column name */
  Last4 = 'last4',
  /** column name */
  PatientId = 'patientId',
  /** column name */
  PayrixCustomerId = 'payrixCustomerId',
  /** column name */
  Processor = 'processor',
  /** column name */
  Token = 'token',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Validity = 'validity',
  /** column name */
  WorkspaceId = 'workspaceId'
}

export type PaymentMethod_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<PaymentMethod_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<PaymentMethod_Set_Input>;
  /** filter the rows which have to be updated */
  where: PaymentMethod_Bool_Exp;
};

/** aggregate var_pop on columns */
export type PaymentMethod_Var_Pop_Fields = {
  __typename?: 'paymentMethod_var_pop_fields';
  expirationMonth?: Maybe<Scalars['Float']>;
  expirationYear?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type PaymentMethod_Var_Samp_Fields = {
  __typename?: 'paymentMethod_var_samp_fields';
  expirationMonth?: Maybe<Scalars['Float']>;
  expirationYear?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type PaymentMethod_Variance_Fields = {
  __typename?: 'paymentMethod_variance_fields';
  expirationMonth?: Maybe<Scalars['Float']>;
  expirationYear?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "paymentRelationship" */
export type PaymentRelationship = {
  __typename?: 'paymentRelationship';
  /** An object relationship */
  childPayment: Payment;
  childPaymentId: Scalars['uuid'];
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An object relationship */
  parentPayment: Payment;
  parentPaymentId: Scalars['uuid'];
  type: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
};

/** aggregated selection of "paymentRelationship" */
export type PaymentRelationship_Aggregate = {
  __typename?: 'paymentRelationship_aggregate';
  aggregate?: Maybe<PaymentRelationship_Aggregate_Fields>;
  nodes: Array<PaymentRelationship>;
};

export type PaymentRelationship_Aggregate_Bool_Exp = {
  count?: InputMaybe<PaymentRelationship_Aggregate_Bool_Exp_Count>;
};

export type PaymentRelationship_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<PaymentRelationship_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<PaymentRelationship_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "paymentRelationship" */
export type PaymentRelationship_Aggregate_Fields = {
  __typename?: 'paymentRelationship_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<PaymentRelationship_Max_Fields>;
  min?: Maybe<PaymentRelationship_Min_Fields>;
};


/** aggregate fields of "paymentRelationship" */
export type PaymentRelationship_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<PaymentRelationship_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "paymentRelationship" */
export type PaymentRelationship_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<PaymentRelationship_Max_Order_By>;
  min?: InputMaybe<PaymentRelationship_Min_Order_By>;
};

/** input type for inserting array relation for remote table "paymentRelationship" */
export type PaymentRelationship_Arr_Rel_Insert_Input = {
  data: Array<PaymentRelationship_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<PaymentRelationship_On_Conflict>;
};

/** Boolean expression to filter rows from the table "paymentRelationship". All fields are combined with a logical 'AND'. */
export type PaymentRelationship_Bool_Exp = {
  _and?: InputMaybe<Array<PaymentRelationship_Bool_Exp>>;
  _not?: InputMaybe<PaymentRelationship_Bool_Exp>;
  _or?: InputMaybe<Array<PaymentRelationship_Bool_Exp>>;
  childPayment?: InputMaybe<Payment_Bool_Exp>;
  childPaymentId?: InputMaybe<Uuid_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  parentPayment?: InputMaybe<Payment_Bool_Exp>;
  parentPaymentId?: InputMaybe<Uuid_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "paymentRelationship" */
export enum PaymentRelationship_Constraint {
  /** unique or primary key constraint on columns "parentPaymentId", "childPaymentId" */
  PaymentRelationshipParentPaymentIdChildPaymentIdKey = 'paymentRelationship_parentPaymentId_childPaymentId_key',
  /** unique or primary key constraint on columns "id" */
  PaymentRelationshipPkey = 'paymentRelationship_pkey'
}

/** input type for inserting data into table "paymentRelationship" */
export type PaymentRelationship_Insert_Input = {
  childPayment?: InputMaybe<Payment_Obj_Rel_Insert_Input>;
  childPaymentId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  parentPayment?: InputMaybe<Payment_Obj_Rel_Insert_Input>;
  parentPaymentId?: InputMaybe<Scalars['uuid']>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type PaymentRelationship_Max_Fields = {
  __typename?: 'paymentRelationship_max_fields';
  childPaymentId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  parentPaymentId?: Maybe<Scalars['uuid']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "paymentRelationship" */
export type PaymentRelationship_Max_Order_By = {
  childPaymentId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  parentPaymentId?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type PaymentRelationship_Min_Fields = {
  __typename?: 'paymentRelationship_min_fields';
  childPaymentId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  parentPaymentId?: Maybe<Scalars['uuid']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "paymentRelationship" */
export type PaymentRelationship_Min_Order_By = {
  childPaymentId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  parentPaymentId?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "paymentRelationship" */
export type PaymentRelationship_Mutation_Response = {
  __typename?: 'paymentRelationship_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<PaymentRelationship>;
};

/** on_conflict condition type for table "paymentRelationship" */
export type PaymentRelationship_On_Conflict = {
  constraint: PaymentRelationship_Constraint;
  update_columns?: Array<PaymentRelationship_Update_Column>;
  where?: InputMaybe<PaymentRelationship_Bool_Exp>;
};

/** Ordering options when selecting data from "paymentRelationship". */
export type PaymentRelationship_Order_By = {
  childPayment?: InputMaybe<Payment_Order_By>;
  childPaymentId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  parentPayment?: InputMaybe<Payment_Order_By>;
  parentPaymentId?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: paymentRelationship */
export type PaymentRelationship_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "paymentRelationship" */
export enum PaymentRelationship_Select_Column {
  /** column name */
  ChildPaymentId = 'childPaymentId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  ParentPaymentId = 'parentPaymentId',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "paymentRelationship" */
export type PaymentRelationship_Set_Input = {
  childPaymentId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  parentPaymentId?: InputMaybe<Scalars['uuid']>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "paymentRelationship" */
export type PaymentRelationship_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: PaymentRelationship_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type PaymentRelationship_Stream_Cursor_Value_Input = {
  childPaymentId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  parentPaymentId?: InputMaybe<Scalars['uuid']>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "paymentRelationship" */
export enum PaymentRelationship_Update_Column {
  /** column name */
  ChildPaymentId = 'childPaymentId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  ParentPaymentId = 'parentPaymentId',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type PaymentRelationship_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<PaymentRelationship_Set_Input>;
  /** filter the rows which have to be updated */
  where: PaymentRelationship_Bool_Exp;
};

/** aggregated selection of "payment" */
export type Payment_Aggregate = {
  __typename?: 'payment_aggregate';
  aggregate?: Maybe<Payment_Aggregate_Fields>;
  nodes: Array<Payment>;
};

export type Payment_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Payment_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Payment_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Payment_Aggregate_Bool_Exp_Count>;
};

export type Payment_Aggregate_Bool_Exp_Bool_And = {
  arguments: Payment_Select_Column_Payment_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Payment_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Payment_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Payment_Select_Column_Payment_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Payment_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Payment_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Payment_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Payment_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "payment" */
export type Payment_Aggregate_Fields = {
  __typename?: 'payment_aggregate_fields';
  avg?: Maybe<Payment_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Payment_Max_Fields>;
  min?: Maybe<Payment_Min_Fields>;
  stddev?: Maybe<Payment_Stddev_Fields>;
  stddev_pop?: Maybe<Payment_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Payment_Stddev_Samp_Fields>;
  sum?: Maybe<Payment_Sum_Fields>;
  var_pop?: Maybe<Payment_Var_Pop_Fields>;
  var_samp?: Maybe<Payment_Var_Samp_Fields>;
  variance?: Maybe<Payment_Variance_Fields>;
};


/** aggregate fields of "payment" */
export type Payment_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Payment_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "payment" */
export type Payment_Aggregate_Order_By = {
  avg?: InputMaybe<Payment_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Payment_Max_Order_By>;
  min?: InputMaybe<Payment_Min_Order_By>;
  stddev?: InputMaybe<Payment_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Payment_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Payment_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Payment_Sum_Order_By>;
  var_pop?: InputMaybe<Payment_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Payment_Var_Samp_Order_By>;
  variance?: InputMaybe<Payment_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "payment" */
export type Payment_Arr_Rel_Insert_Input = {
  data: Array<Payment_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Payment_On_Conflict>;
};

/** aggregate avg on columns */
export type Payment_Avg_Fields = {
  __typename?: 'payment_avg_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "payment" */
export type Payment_Avg_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "payment". All fields are combined with a logical 'AND'. */
export type Payment_Bool_Exp = {
  _and?: InputMaybe<Array<Payment_Bool_Exp>>;
  _not?: InputMaybe<Payment_Bool_Exp>;
  _or?: InputMaybe<Array<Payment_Bool_Exp>>;
  amount?: InputMaybe<Int_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  finixMerchantId?: InputMaybe<String_Comparison_Exp>;
  finixStatus?: InputMaybe<String_Comparison_Exp>;
  finixTransferId?: InputMaybe<String_Comparison_Exp>;
  finixType?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  invoice?: InputMaybe<Invoice_Bool_Exp>;
  invoiceId?: InputMaybe<Uuid_Comparison_Exp>;
  isArchived?: InputMaybe<Boolean_Comparison_Exp>;
  parentPaymentRelationships?: InputMaybe<PaymentRelationship_Bool_Exp>;
  parentPaymentRelationships_aggregate?: InputMaybe<PaymentRelationship_Aggregate_Bool_Exp>;
  patientId?: InputMaybe<Uuid_Comparison_Exp>;
  patientMembershipId?: InputMaybe<Uuid_Comparison_Exp>;
  paymentMethod?: InputMaybe<PaymentMethod_Bool_Exp>;
  paymentMethodId?: InputMaybe<Uuid_Comparison_Exp>;
  paymentMethodType?: InputMaybe<String_Comparison_Exp>;
  payrixMerchantId?: InputMaybe<String_Comparison_Exp>;
  payrixStatus?: InputMaybe<String_Comparison_Exp>;
  payrixTerminalTransactionId?: InputMaybe<String_Comparison_Exp>;
  payrixTransactionId?: InputMaybe<String_Comparison_Exp>;
  payrixType?: InputMaybe<String_Comparison_Exp>;
  refunds?: InputMaybe<Refund_Bool_Exp>;
  refunds_aggregate?: InputMaybe<Refund_Aggregate_Bool_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  workspace?: InputMaybe<Workspace_Bool_Exp>;
  workspaceId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "payment" */
export enum Payment_Constraint {
  /** unique or primary key constraint on columns "id" */
  PaymentPkey = 'payment_pkey'
}

/** input type for incrementing numeric columns in table "payment" */
export type Payment_Inc_Input = {
  amount?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "payment" */
export type Payment_Insert_Input = {
  amount?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  finixMerchantId?: InputMaybe<Scalars['String']>;
  finixStatus?: InputMaybe<Scalars['String']>;
  finixTransferId?: InputMaybe<Scalars['String']>;
  finixType?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  invoice?: InputMaybe<Invoice_Obj_Rel_Insert_Input>;
  invoiceId?: InputMaybe<Scalars['uuid']>;
  isArchived?: InputMaybe<Scalars['Boolean']>;
  parentPaymentRelationships?: InputMaybe<PaymentRelationship_Arr_Rel_Insert_Input>;
  patientId?: InputMaybe<Scalars['uuid']>;
  patientMembershipId?: InputMaybe<Scalars['uuid']>;
  paymentMethod?: InputMaybe<PaymentMethod_Obj_Rel_Insert_Input>;
  paymentMethodId?: InputMaybe<Scalars['uuid']>;
  paymentMethodType?: InputMaybe<Scalars['String']>;
  payrixMerchantId?: InputMaybe<Scalars['String']>;
  payrixStatus?: InputMaybe<Scalars['String']>;
  payrixTerminalTransactionId?: InputMaybe<Scalars['String']>;
  payrixTransactionId?: InputMaybe<Scalars['String']>;
  payrixType?: InputMaybe<Scalars['String']>;
  refunds?: InputMaybe<Refund_Arr_Rel_Insert_Input>;
  status?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspace?: InputMaybe<Workspace_Obj_Rel_Insert_Input>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Payment_Max_Fields = {
  __typename?: 'payment_max_fields';
  amount?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  finixMerchantId?: Maybe<Scalars['String']>;
  finixStatus?: Maybe<Scalars['String']>;
  finixTransferId?: Maybe<Scalars['String']>;
  finixType?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  invoiceId?: Maybe<Scalars['uuid']>;
  patientId?: Maybe<Scalars['uuid']>;
  patientMembershipId?: Maybe<Scalars['uuid']>;
  paymentMethodId?: Maybe<Scalars['uuid']>;
  paymentMethodType?: Maybe<Scalars['String']>;
  payrixMerchantId?: Maybe<Scalars['String']>;
  payrixStatus?: Maybe<Scalars['String']>;
  payrixTerminalTransactionId?: Maybe<Scalars['String']>;
  payrixTransactionId?: Maybe<Scalars['String']>;
  payrixType?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "payment" */
export type Payment_Max_Order_By = {
  amount?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  finixMerchantId?: InputMaybe<Order_By>;
  finixStatus?: InputMaybe<Order_By>;
  finixTransferId?: InputMaybe<Order_By>;
  finixType?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoiceId?: InputMaybe<Order_By>;
  patientId?: InputMaybe<Order_By>;
  patientMembershipId?: InputMaybe<Order_By>;
  paymentMethodId?: InputMaybe<Order_By>;
  paymentMethodType?: InputMaybe<Order_By>;
  payrixMerchantId?: InputMaybe<Order_By>;
  payrixStatus?: InputMaybe<Order_By>;
  payrixTerminalTransactionId?: InputMaybe<Order_By>;
  payrixTransactionId?: InputMaybe<Order_By>;
  payrixType?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Payment_Min_Fields = {
  __typename?: 'payment_min_fields';
  amount?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  finixMerchantId?: Maybe<Scalars['String']>;
  finixStatus?: Maybe<Scalars['String']>;
  finixTransferId?: Maybe<Scalars['String']>;
  finixType?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  invoiceId?: Maybe<Scalars['uuid']>;
  patientId?: Maybe<Scalars['uuid']>;
  patientMembershipId?: Maybe<Scalars['uuid']>;
  paymentMethodId?: Maybe<Scalars['uuid']>;
  paymentMethodType?: Maybe<Scalars['String']>;
  payrixMerchantId?: Maybe<Scalars['String']>;
  payrixStatus?: Maybe<Scalars['String']>;
  payrixTerminalTransactionId?: Maybe<Scalars['String']>;
  payrixTransactionId?: Maybe<Scalars['String']>;
  payrixType?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "payment" */
export type Payment_Min_Order_By = {
  amount?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  finixMerchantId?: InputMaybe<Order_By>;
  finixStatus?: InputMaybe<Order_By>;
  finixTransferId?: InputMaybe<Order_By>;
  finixType?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoiceId?: InputMaybe<Order_By>;
  patientId?: InputMaybe<Order_By>;
  patientMembershipId?: InputMaybe<Order_By>;
  paymentMethodId?: InputMaybe<Order_By>;
  paymentMethodType?: InputMaybe<Order_By>;
  payrixMerchantId?: InputMaybe<Order_By>;
  payrixStatus?: InputMaybe<Order_By>;
  payrixTerminalTransactionId?: InputMaybe<Order_By>;
  payrixTransactionId?: InputMaybe<Order_By>;
  payrixType?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "payment" */
export type Payment_Mutation_Response = {
  __typename?: 'payment_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Payment>;
};

/** input type for inserting object relation for remote table "payment" */
export type Payment_Obj_Rel_Insert_Input = {
  data: Payment_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Payment_On_Conflict>;
};

/** on_conflict condition type for table "payment" */
export type Payment_On_Conflict = {
  constraint: Payment_Constraint;
  update_columns?: Array<Payment_Update_Column>;
  where?: InputMaybe<Payment_Bool_Exp>;
};

/** Ordering options when selecting data from "payment". */
export type Payment_Order_By = {
  amount?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  finixMerchantId?: InputMaybe<Order_By>;
  finixStatus?: InputMaybe<Order_By>;
  finixTransferId?: InputMaybe<Order_By>;
  finixType?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoice?: InputMaybe<Invoice_Order_By>;
  invoiceId?: InputMaybe<Order_By>;
  isArchived?: InputMaybe<Order_By>;
  parentPaymentRelationships_aggregate?: InputMaybe<PaymentRelationship_Aggregate_Order_By>;
  patientId?: InputMaybe<Order_By>;
  patientMembershipId?: InputMaybe<Order_By>;
  paymentMethod?: InputMaybe<PaymentMethod_Order_By>;
  paymentMethodId?: InputMaybe<Order_By>;
  paymentMethodType?: InputMaybe<Order_By>;
  payrixMerchantId?: InputMaybe<Order_By>;
  payrixStatus?: InputMaybe<Order_By>;
  payrixTerminalTransactionId?: InputMaybe<Order_By>;
  payrixTransactionId?: InputMaybe<Order_By>;
  payrixType?: InputMaybe<Order_By>;
  refunds_aggregate?: InputMaybe<Refund_Aggregate_Order_By>;
  status?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  workspace?: InputMaybe<Workspace_Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: payment */
export type Payment_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "payment" */
export enum Payment_Select_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  FinixMerchantId = 'finixMerchantId',
  /** column name */
  FinixStatus = 'finixStatus',
  /** column name */
  FinixTransferId = 'finixTransferId',
  /** column name */
  FinixType = 'finixType',
  /** column name */
  Id = 'id',
  /** column name */
  InvoiceId = 'invoiceId',
  /** column name */
  IsArchived = 'isArchived',
  /** column name */
  PatientId = 'patientId',
  /** column name */
  PatientMembershipId = 'patientMembershipId',
  /** column name */
  PaymentMethodId = 'paymentMethodId',
  /** column name */
  PaymentMethodType = 'paymentMethodType',
  /** column name */
  PayrixMerchantId = 'payrixMerchantId',
  /** column name */
  PayrixStatus = 'payrixStatus',
  /** column name */
  PayrixTerminalTransactionId = 'payrixTerminalTransactionId',
  /** column name */
  PayrixTransactionId = 'payrixTransactionId',
  /** column name */
  PayrixType = 'payrixType',
  /** column name */
  Status = 'status',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WorkspaceId = 'workspaceId'
}

/** select "payment_aggregate_bool_exp_bool_and_arguments_columns" columns of table "payment" */
export enum Payment_Select_Column_Payment_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsArchived = 'isArchived'
}

/** select "payment_aggregate_bool_exp_bool_or_arguments_columns" columns of table "payment" */
export enum Payment_Select_Column_Payment_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsArchived = 'isArchived'
}

/** input type for updating data in table "payment" */
export type Payment_Set_Input = {
  amount?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  finixMerchantId?: InputMaybe<Scalars['String']>;
  finixStatus?: InputMaybe<Scalars['String']>;
  finixTransferId?: InputMaybe<Scalars['String']>;
  finixType?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  invoiceId?: InputMaybe<Scalars['uuid']>;
  isArchived?: InputMaybe<Scalars['Boolean']>;
  patientId?: InputMaybe<Scalars['uuid']>;
  patientMembershipId?: InputMaybe<Scalars['uuid']>;
  paymentMethodId?: InputMaybe<Scalars['uuid']>;
  paymentMethodType?: InputMaybe<Scalars['String']>;
  payrixMerchantId?: InputMaybe<Scalars['String']>;
  payrixStatus?: InputMaybe<Scalars['String']>;
  payrixTerminalTransactionId?: InputMaybe<Scalars['String']>;
  payrixTransactionId?: InputMaybe<Scalars['String']>;
  payrixType?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Payment_Stddev_Fields = {
  __typename?: 'payment_stddev_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "payment" */
export type Payment_Stddev_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Payment_Stddev_Pop_Fields = {
  __typename?: 'payment_stddev_pop_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "payment" */
export type Payment_Stddev_Pop_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Payment_Stddev_Samp_Fields = {
  __typename?: 'payment_stddev_samp_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "payment" */
export type Payment_Stddev_Samp_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "payment" */
export type Payment_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Payment_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Payment_Stream_Cursor_Value_Input = {
  amount?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  finixMerchantId?: InputMaybe<Scalars['String']>;
  finixStatus?: InputMaybe<Scalars['String']>;
  finixTransferId?: InputMaybe<Scalars['String']>;
  finixType?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  invoiceId?: InputMaybe<Scalars['uuid']>;
  isArchived?: InputMaybe<Scalars['Boolean']>;
  patientId?: InputMaybe<Scalars['uuid']>;
  patientMembershipId?: InputMaybe<Scalars['uuid']>;
  paymentMethodId?: InputMaybe<Scalars['uuid']>;
  paymentMethodType?: InputMaybe<Scalars['String']>;
  payrixMerchantId?: InputMaybe<Scalars['String']>;
  payrixStatus?: InputMaybe<Scalars['String']>;
  payrixTerminalTransactionId?: InputMaybe<Scalars['String']>;
  payrixTransactionId?: InputMaybe<Scalars['String']>;
  payrixType?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Payment_Sum_Fields = {
  __typename?: 'payment_sum_fields';
  amount?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "payment" */
export type Payment_Sum_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** update columns of table "payment" */
export enum Payment_Update_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  FinixMerchantId = 'finixMerchantId',
  /** column name */
  FinixStatus = 'finixStatus',
  /** column name */
  FinixTransferId = 'finixTransferId',
  /** column name */
  FinixType = 'finixType',
  /** column name */
  Id = 'id',
  /** column name */
  InvoiceId = 'invoiceId',
  /** column name */
  IsArchived = 'isArchived',
  /** column name */
  PatientId = 'patientId',
  /** column name */
  PatientMembershipId = 'patientMembershipId',
  /** column name */
  PaymentMethodId = 'paymentMethodId',
  /** column name */
  PaymentMethodType = 'paymentMethodType',
  /** column name */
  PayrixMerchantId = 'payrixMerchantId',
  /** column name */
  PayrixStatus = 'payrixStatus',
  /** column name */
  PayrixTerminalTransactionId = 'payrixTerminalTransactionId',
  /** column name */
  PayrixTransactionId = 'payrixTransactionId',
  /** column name */
  PayrixType = 'payrixType',
  /** column name */
  Status = 'status',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WorkspaceId = 'workspaceId'
}

export type Payment_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Payment_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Payment_Set_Input>;
  /** filter the rows which have to be updated */
  where: Payment_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Payment_Var_Pop_Fields = {
  __typename?: 'payment_var_pop_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "payment" */
export type Payment_Var_Pop_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Payment_Var_Samp_Fields = {
  __typename?: 'payment_var_samp_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "payment" */
export type Payment_Var_Samp_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Payment_Variance_Fields = {
  __typename?: 'payment_variance_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "payment" */
export type Payment_Variance_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** columns and relationships of "payrixTerminal" */
export type PayrixTerminal = {
  __typename?: 'payrixTerminal';
  createdAt: Scalars['timestamptz'];
  deviceMake: Scalars['String'];
  deviceModel: Scalars['String'];
  id: Scalars['uuid'];
  locationId: Scalars['uuid'];
  name?: Maybe<Scalars['String']>;
  payrixTerminalId: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  workspaceId: Scalars['uuid'];
};

/** columns and relationships of "payrixTerminalTransaction" */
export type PayrixTerminalTransaction = {
  __typename?: 'payrixTerminalTransaction';
  amount?: Maybe<Scalars['Int']>;
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  initiatedByUserId?: Maybe<Scalars['uuid']>;
  invoiceId?: Maybe<Scalars['uuid']>;
  payrixTerminalId: Scalars['uuid'];
  payrixTerminalTransactionId: Scalars['String'];
  status?: Maybe<Scalars['String']>;
  updatedAt: Scalars['timestamptz'];
  workspaceId: Scalars['uuid'];
};

/** aggregated selection of "payrixTerminalTransaction" */
export type PayrixTerminalTransaction_Aggregate = {
  __typename?: 'payrixTerminalTransaction_aggregate';
  aggregate?: Maybe<PayrixTerminalTransaction_Aggregate_Fields>;
  nodes: Array<PayrixTerminalTransaction>;
};

/** aggregate fields of "payrixTerminalTransaction" */
export type PayrixTerminalTransaction_Aggregate_Fields = {
  __typename?: 'payrixTerminalTransaction_aggregate_fields';
  avg?: Maybe<PayrixTerminalTransaction_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<PayrixTerminalTransaction_Max_Fields>;
  min?: Maybe<PayrixTerminalTransaction_Min_Fields>;
  stddev?: Maybe<PayrixTerminalTransaction_Stddev_Fields>;
  stddev_pop?: Maybe<PayrixTerminalTransaction_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<PayrixTerminalTransaction_Stddev_Samp_Fields>;
  sum?: Maybe<PayrixTerminalTransaction_Sum_Fields>;
  var_pop?: Maybe<PayrixTerminalTransaction_Var_Pop_Fields>;
  var_samp?: Maybe<PayrixTerminalTransaction_Var_Samp_Fields>;
  variance?: Maybe<PayrixTerminalTransaction_Variance_Fields>;
};


/** aggregate fields of "payrixTerminalTransaction" */
export type PayrixTerminalTransaction_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<PayrixTerminalTransaction_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type PayrixTerminalTransaction_Avg_Fields = {
  __typename?: 'payrixTerminalTransaction_avg_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "payrixTerminalTransaction". All fields are combined with a logical 'AND'. */
export type PayrixTerminalTransaction_Bool_Exp = {
  _and?: InputMaybe<Array<PayrixTerminalTransaction_Bool_Exp>>;
  _not?: InputMaybe<PayrixTerminalTransaction_Bool_Exp>;
  _or?: InputMaybe<Array<PayrixTerminalTransaction_Bool_Exp>>;
  amount?: InputMaybe<Int_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  initiatedByUserId?: InputMaybe<Uuid_Comparison_Exp>;
  invoiceId?: InputMaybe<Uuid_Comparison_Exp>;
  payrixTerminalId?: InputMaybe<Uuid_Comparison_Exp>;
  payrixTerminalTransactionId?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  workspaceId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "payrixTerminalTransaction" */
export enum PayrixTerminalTransaction_Constraint {
  /** unique or primary key constraint on columns "id" */
  PayrixTerminalTransactionPkey = 'payrixTerminalTransaction_pkey'
}

/** input type for incrementing numeric columns in table "payrixTerminalTransaction" */
export type PayrixTerminalTransaction_Inc_Input = {
  amount?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "payrixTerminalTransaction" */
export type PayrixTerminalTransaction_Insert_Input = {
  amount?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  initiatedByUserId?: InputMaybe<Scalars['uuid']>;
  invoiceId?: InputMaybe<Scalars['uuid']>;
  payrixTerminalId?: InputMaybe<Scalars['uuid']>;
  payrixTerminalTransactionId?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type PayrixTerminalTransaction_Max_Fields = {
  __typename?: 'payrixTerminalTransaction_max_fields';
  amount?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  initiatedByUserId?: Maybe<Scalars['uuid']>;
  invoiceId?: Maybe<Scalars['uuid']>;
  payrixTerminalId?: Maybe<Scalars['uuid']>;
  payrixTerminalTransactionId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type PayrixTerminalTransaction_Min_Fields = {
  __typename?: 'payrixTerminalTransaction_min_fields';
  amount?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  initiatedByUserId?: Maybe<Scalars['uuid']>;
  invoiceId?: Maybe<Scalars['uuid']>;
  payrixTerminalId?: Maybe<Scalars['uuid']>;
  payrixTerminalTransactionId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "payrixTerminalTransaction" */
export type PayrixTerminalTransaction_Mutation_Response = {
  __typename?: 'payrixTerminalTransaction_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<PayrixTerminalTransaction>;
};

/** on_conflict condition type for table "payrixTerminalTransaction" */
export type PayrixTerminalTransaction_On_Conflict = {
  constraint: PayrixTerminalTransaction_Constraint;
  update_columns?: Array<PayrixTerminalTransaction_Update_Column>;
  where?: InputMaybe<PayrixTerminalTransaction_Bool_Exp>;
};

/** Ordering options when selecting data from "payrixTerminalTransaction". */
export type PayrixTerminalTransaction_Order_By = {
  amount?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  initiatedByUserId?: InputMaybe<Order_By>;
  invoiceId?: InputMaybe<Order_By>;
  payrixTerminalId?: InputMaybe<Order_By>;
  payrixTerminalTransactionId?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: payrixTerminalTransaction */
export type PayrixTerminalTransaction_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "payrixTerminalTransaction" */
export enum PayrixTerminalTransaction_Select_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  InitiatedByUserId = 'initiatedByUserId',
  /** column name */
  InvoiceId = 'invoiceId',
  /** column name */
  PayrixTerminalId = 'payrixTerminalId',
  /** column name */
  PayrixTerminalTransactionId = 'payrixTerminalTransactionId',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WorkspaceId = 'workspaceId'
}

/** input type for updating data in table "payrixTerminalTransaction" */
export type PayrixTerminalTransaction_Set_Input = {
  amount?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  initiatedByUserId?: InputMaybe<Scalars['uuid']>;
  invoiceId?: InputMaybe<Scalars['uuid']>;
  payrixTerminalId?: InputMaybe<Scalars['uuid']>;
  payrixTerminalTransactionId?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type PayrixTerminalTransaction_Stddev_Fields = {
  __typename?: 'payrixTerminalTransaction_stddev_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type PayrixTerminalTransaction_Stddev_Pop_Fields = {
  __typename?: 'payrixTerminalTransaction_stddev_pop_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type PayrixTerminalTransaction_Stddev_Samp_Fields = {
  __typename?: 'payrixTerminalTransaction_stddev_samp_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "payrixTerminalTransaction" */
export type PayrixTerminalTransaction_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: PayrixTerminalTransaction_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type PayrixTerminalTransaction_Stream_Cursor_Value_Input = {
  amount?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  initiatedByUserId?: InputMaybe<Scalars['uuid']>;
  invoiceId?: InputMaybe<Scalars['uuid']>;
  payrixTerminalId?: InputMaybe<Scalars['uuid']>;
  payrixTerminalTransactionId?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type PayrixTerminalTransaction_Sum_Fields = {
  __typename?: 'payrixTerminalTransaction_sum_fields';
  amount?: Maybe<Scalars['Int']>;
};

/** update columns of table "payrixTerminalTransaction" */
export enum PayrixTerminalTransaction_Update_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  InitiatedByUserId = 'initiatedByUserId',
  /** column name */
  InvoiceId = 'invoiceId',
  /** column name */
  PayrixTerminalId = 'payrixTerminalId',
  /** column name */
  PayrixTerminalTransactionId = 'payrixTerminalTransactionId',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WorkspaceId = 'workspaceId'
}

export type PayrixTerminalTransaction_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<PayrixTerminalTransaction_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<PayrixTerminalTransaction_Set_Input>;
  /** filter the rows which have to be updated */
  where: PayrixTerminalTransaction_Bool_Exp;
};

/** aggregate var_pop on columns */
export type PayrixTerminalTransaction_Var_Pop_Fields = {
  __typename?: 'payrixTerminalTransaction_var_pop_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type PayrixTerminalTransaction_Var_Samp_Fields = {
  __typename?: 'payrixTerminalTransaction_var_samp_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type PayrixTerminalTransaction_Variance_Fields = {
  __typename?: 'payrixTerminalTransaction_variance_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** aggregated selection of "payrixTerminal" */
export type PayrixTerminal_Aggregate = {
  __typename?: 'payrixTerminal_aggregate';
  aggregate?: Maybe<PayrixTerminal_Aggregate_Fields>;
  nodes: Array<PayrixTerminal>;
};

/** aggregate fields of "payrixTerminal" */
export type PayrixTerminal_Aggregate_Fields = {
  __typename?: 'payrixTerminal_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<PayrixTerminal_Max_Fields>;
  min?: Maybe<PayrixTerminal_Min_Fields>;
};


/** aggregate fields of "payrixTerminal" */
export type PayrixTerminal_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<PayrixTerminal_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "payrixTerminal". All fields are combined with a logical 'AND'. */
export type PayrixTerminal_Bool_Exp = {
  _and?: InputMaybe<Array<PayrixTerminal_Bool_Exp>>;
  _not?: InputMaybe<PayrixTerminal_Bool_Exp>;
  _or?: InputMaybe<Array<PayrixTerminal_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  deviceMake?: InputMaybe<String_Comparison_Exp>;
  deviceModel?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  locationId?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  payrixTerminalId?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  workspaceId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "payrixTerminal" */
export enum PayrixTerminal_Constraint {
  /** unique or primary key constraint on columns "id" */
  PayrixTerminalPkey = 'payrixTerminal_pkey'
}

/** input type for inserting data into table "payrixTerminal" */
export type PayrixTerminal_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  deviceMake?: InputMaybe<Scalars['String']>;
  deviceModel?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  locationId?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  payrixTerminalId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type PayrixTerminal_Max_Fields = {
  __typename?: 'payrixTerminal_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  deviceMake?: Maybe<Scalars['String']>;
  deviceModel?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  locationId?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  payrixTerminalId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type PayrixTerminal_Min_Fields = {
  __typename?: 'payrixTerminal_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  deviceMake?: Maybe<Scalars['String']>;
  deviceModel?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  locationId?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  payrixTerminalId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "payrixTerminal" */
export type PayrixTerminal_Mutation_Response = {
  __typename?: 'payrixTerminal_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<PayrixTerminal>;
};

/** on_conflict condition type for table "payrixTerminal" */
export type PayrixTerminal_On_Conflict = {
  constraint: PayrixTerminal_Constraint;
  update_columns?: Array<PayrixTerminal_Update_Column>;
  where?: InputMaybe<PayrixTerminal_Bool_Exp>;
};

/** Ordering options when selecting data from "payrixTerminal". */
export type PayrixTerminal_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  deviceMake?: InputMaybe<Order_By>;
  deviceModel?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  locationId?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  payrixTerminalId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: payrixTerminal */
export type PayrixTerminal_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "payrixTerminal" */
export enum PayrixTerminal_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DeviceMake = 'deviceMake',
  /** column name */
  DeviceModel = 'deviceModel',
  /** column name */
  Id = 'id',
  /** column name */
  LocationId = 'locationId',
  /** column name */
  Name = 'name',
  /** column name */
  PayrixTerminalId = 'payrixTerminalId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WorkspaceId = 'workspaceId'
}

/** input type for updating data in table "payrixTerminal" */
export type PayrixTerminal_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  deviceMake?: InputMaybe<Scalars['String']>;
  deviceModel?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  locationId?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  payrixTerminalId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "payrixTerminal" */
export type PayrixTerminal_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: PayrixTerminal_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type PayrixTerminal_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  deviceMake?: InputMaybe<Scalars['String']>;
  deviceModel?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  locationId?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  payrixTerminalId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "payrixTerminal" */
export enum PayrixTerminal_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DeviceMake = 'deviceMake',
  /** column name */
  DeviceModel = 'deviceModel',
  /** column name */
  Id = 'id',
  /** column name */
  LocationId = 'locationId',
  /** column name */
  Name = 'name',
  /** column name */
  PayrixTerminalId = 'payrixTerminalId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WorkspaceId = 'workspaceId'
}

export type PayrixTerminal_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<PayrixTerminal_Set_Input>;
  /** filter the rows which have to be updated */
  where: PayrixTerminal_Bool_Exp;
};

/** columns and relationships of "payrixWebAlert" */
export type PayrixWebAlert = {
  __typename?: 'payrixWebAlert';
  created_at: Scalars['timestamptz'];
  event: Scalars['String'];
  id: Scalars['uuid'];
  name: Scalars['String'];
  payrixId: Scalars['String'];
  resource: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  value: Scalars['String'];
};

/** aggregated selection of "payrixWebAlert" */
export type PayrixWebAlert_Aggregate = {
  __typename?: 'payrixWebAlert_aggregate';
  aggregate?: Maybe<PayrixWebAlert_Aggregate_Fields>;
  nodes: Array<PayrixWebAlert>;
};

/** aggregate fields of "payrixWebAlert" */
export type PayrixWebAlert_Aggregate_Fields = {
  __typename?: 'payrixWebAlert_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<PayrixWebAlert_Max_Fields>;
  min?: Maybe<PayrixWebAlert_Min_Fields>;
};


/** aggregate fields of "payrixWebAlert" */
export type PayrixWebAlert_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<PayrixWebAlert_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "payrixWebAlert". All fields are combined with a logical 'AND'. */
export type PayrixWebAlert_Bool_Exp = {
  _and?: InputMaybe<Array<PayrixWebAlert_Bool_Exp>>;
  _not?: InputMaybe<PayrixWebAlert_Bool_Exp>;
  _or?: InputMaybe<Array<PayrixWebAlert_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  event?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  payrixId?: InputMaybe<String_Comparison_Exp>;
  resource?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "payrixWebAlert" */
export enum PayrixWebAlert_Constraint {
  /** unique or primary key constraint on columns "resource", "event" */
  PayrixWebAlertEventResourceKey = 'payrixWebAlert_event_resource_key',
  /** unique or primary key constraint on columns "payrixId" */
  PayrixWebAlertPayrixIdKey = 'payrixWebAlert_payrixId_key',
  /** unique or primary key constraint on columns "id" */
  PayrixWebAlertPkey = 'payrixWebAlert_pkey'
}

/** input type for inserting data into table "payrixWebAlert" */
export type PayrixWebAlert_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  event?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  payrixId?: InputMaybe<Scalars['String']>;
  resource?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type PayrixWebAlert_Max_Fields = {
  __typename?: 'payrixWebAlert_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  event?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  payrixId?: Maybe<Scalars['String']>;
  resource?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type PayrixWebAlert_Min_Fields = {
  __typename?: 'payrixWebAlert_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  event?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  payrixId?: Maybe<Scalars['String']>;
  resource?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "payrixWebAlert" */
export type PayrixWebAlert_Mutation_Response = {
  __typename?: 'payrixWebAlert_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<PayrixWebAlert>;
};

/** on_conflict condition type for table "payrixWebAlert" */
export type PayrixWebAlert_On_Conflict = {
  constraint: PayrixWebAlert_Constraint;
  update_columns?: Array<PayrixWebAlert_Update_Column>;
  where?: InputMaybe<PayrixWebAlert_Bool_Exp>;
};

/** Ordering options when selecting data from "payrixWebAlert". */
export type PayrixWebAlert_Order_By = {
  created_at?: InputMaybe<Order_By>;
  event?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  payrixId?: InputMaybe<Order_By>;
  resource?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: payrixWebAlert */
export type PayrixWebAlert_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "payrixWebAlert" */
export enum PayrixWebAlert_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Event = 'event',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  PayrixId = 'payrixId',
  /** column name */
  Resource = 'resource',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "payrixWebAlert" */
export type PayrixWebAlert_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  event?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  payrixId?: InputMaybe<Scalars['String']>;
  resource?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "payrixWebAlert" */
export type PayrixWebAlert_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: PayrixWebAlert_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type PayrixWebAlert_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  event?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  payrixId?: InputMaybe<Scalars['String']>;
  resource?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "payrixWebAlert" */
export enum PayrixWebAlert_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Event = 'event',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  PayrixId = 'payrixId',
  /** column name */
  Resource = 'resource',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Value = 'value'
}

export type PayrixWebAlert_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<PayrixWebAlert_Set_Input>;
  /** filter the rows which have to be updated */
  where: PayrixWebAlert_Bool_Exp;
};

/** columns and relationships of "photo" */
export type Photo = {
  __typename?: 'photo';
  appointmentId?: Maybe<Scalars['uuid']>;
  createdAt: Scalars['timestamptz'];
  file?: Maybe<FilePayload>;
  filePath: Scalars['String'];
  fullMediaConsent?: Maybe<Scalars['Boolean']>;
  hasThumbnail?: Maybe<Scalars['Boolean']>;
  id: Scalars['uuid'];
  isPublic: Scalars['Boolean'];
  isSharedWithPatient: Scalars['Boolean'];
  mediaDate?: Maybe<Scalars['timestamptz']>;
  mediaType?: Maybe<Scalars['String']>;
  mimeType?: Maybe<Scalars['String']>;
  partialMediaConsent?: Maybe<Scalars['Boolean']>;
  /** An object relationship */
  patient?: Maybe<Patient>;
  patientId?: Maybe<Scalars['uuid']>;
  /** An array relationship */
  photoMediaTags: Array<PhotoMediaTag>;
  /** An aggregate relationship */
  photoMediaTags_aggregate: PhotoMediaTag_Aggregate;
  pinturaImageState?: Maybe<Scalars['jsonb']>;
  updatedAt: Scalars['timestamptz'];
  /** An object relationship */
  uploader?: Maybe<User>;
  uploaderId?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  workspace: Workspace;
  workspaceId: Scalars['uuid'];
};


/** columns and relationships of "photo" */
export type PhotoPhotoMediaTagsArgs = {
  distinct_on?: InputMaybe<Array<PhotoMediaTag_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PhotoMediaTag_Order_By>>;
  where?: InputMaybe<PhotoMediaTag_Bool_Exp>;
};


/** columns and relationships of "photo" */
export type PhotoPhotoMediaTags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<PhotoMediaTag_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PhotoMediaTag_Order_By>>;
  where?: InputMaybe<PhotoMediaTag_Bool_Exp>;
};


/** columns and relationships of "photo" */
export type PhotoPinturaImageStateArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "photoMediaTag" */
export type PhotoMediaTag = {
  __typename?: 'photoMediaTag';
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An object relationship */
  mediaTag: MediaTag;
  mediaTagId: Scalars['uuid'];
  /** An object relationship */
  photo: Photo;
  photoId: Scalars['uuid'];
  updatedAt: Scalars['timestamptz'];
};

/** aggregated selection of "photoMediaTag" */
export type PhotoMediaTag_Aggregate = {
  __typename?: 'photoMediaTag_aggregate';
  aggregate?: Maybe<PhotoMediaTag_Aggregate_Fields>;
  nodes: Array<PhotoMediaTag>;
};

export type PhotoMediaTag_Aggregate_Bool_Exp = {
  count?: InputMaybe<PhotoMediaTag_Aggregate_Bool_Exp_Count>;
};

export type PhotoMediaTag_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<PhotoMediaTag_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<PhotoMediaTag_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "photoMediaTag" */
export type PhotoMediaTag_Aggregate_Fields = {
  __typename?: 'photoMediaTag_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<PhotoMediaTag_Max_Fields>;
  min?: Maybe<PhotoMediaTag_Min_Fields>;
};


/** aggregate fields of "photoMediaTag" */
export type PhotoMediaTag_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<PhotoMediaTag_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "photoMediaTag" */
export type PhotoMediaTag_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<PhotoMediaTag_Max_Order_By>;
  min?: InputMaybe<PhotoMediaTag_Min_Order_By>;
};

/** input type for inserting array relation for remote table "photoMediaTag" */
export type PhotoMediaTag_Arr_Rel_Insert_Input = {
  data: Array<PhotoMediaTag_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<PhotoMediaTag_On_Conflict>;
};

/** Boolean expression to filter rows from the table "photoMediaTag". All fields are combined with a logical 'AND'. */
export type PhotoMediaTag_Bool_Exp = {
  _and?: InputMaybe<Array<PhotoMediaTag_Bool_Exp>>;
  _not?: InputMaybe<PhotoMediaTag_Bool_Exp>;
  _or?: InputMaybe<Array<PhotoMediaTag_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  mediaTag?: InputMaybe<MediaTag_Bool_Exp>;
  mediaTagId?: InputMaybe<Uuid_Comparison_Exp>;
  photo?: InputMaybe<Photo_Bool_Exp>;
  photoId?: InputMaybe<Uuid_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "photoMediaTag" */
export enum PhotoMediaTag_Constraint {
  /** unique or primary key constraint on columns "mediaTagId", "photoId" */
  PhotoMediaTagPhotoIdMediaTagIdKey = 'photoMediaTag_photoId_mediaTagId_key',
  /** unique or primary key constraint on columns "id" */
  PhotoMediaTagPkey = 'photoMediaTag_pkey'
}

/** input type for inserting data into table "photoMediaTag" */
export type PhotoMediaTag_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  mediaTag?: InputMaybe<MediaTag_Obj_Rel_Insert_Input>;
  mediaTagId?: InputMaybe<Scalars['uuid']>;
  photo?: InputMaybe<Photo_Obj_Rel_Insert_Input>;
  photoId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type PhotoMediaTag_Max_Fields = {
  __typename?: 'photoMediaTag_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  mediaTagId?: Maybe<Scalars['uuid']>;
  photoId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "photoMediaTag" */
export type PhotoMediaTag_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  mediaTagId?: InputMaybe<Order_By>;
  photoId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type PhotoMediaTag_Min_Fields = {
  __typename?: 'photoMediaTag_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  mediaTagId?: Maybe<Scalars['uuid']>;
  photoId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "photoMediaTag" */
export type PhotoMediaTag_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  mediaTagId?: InputMaybe<Order_By>;
  photoId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "photoMediaTag" */
export type PhotoMediaTag_Mutation_Response = {
  __typename?: 'photoMediaTag_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<PhotoMediaTag>;
};

/** on_conflict condition type for table "photoMediaTag" */
export type PhotoMediaTag_On_Conflict = {
  constraint: PhotoMediaTag_Constraint;
  update_columns?: Array<PhotoMediaTag_Update_Column>;
  where?: InputMaybe<PhotoMediaTag_Bool_Exp>;
};

/** Ordering options when selecting data from "photoMediaTag". */
export type PhotoMediaTag_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  mediaTag?: InputMaybe<MediaTag_Order_By>;
  mediaTagId?: InputMaybe<Order_By>;
  photo?: InputMaybe<Photo_Order_By>;
  photoId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: photoMediaTag */
export type PhotoMediaTag_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "photoMediaTag" */
export enum PhotoMediaTag_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  MediaTagId = 'mediaTagId',
  /** column name */
  PhotoId = 'photoId',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "photoMediaTag" */
export type PhotoMediaTag_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  mediaTagId?: InputMaybe<Scalars['uuid']>;
  photoId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "photoMediaTag" */
export type PhotoMediaTag_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: PhotoMediaTag_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type PhotoMediaTag_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  mediaTagId?: InputMaybe<Scalars['uuid']>;
  photoId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "photoMediaTag" */
export enum PhotoMediaTag_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  MediaTagId = 'mediaTagId',
  /** column name */
  PhotoId = 'photoId',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type PhotoMediaTag_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<PhotoMediaTag_Set_Input>;
  /** filter the rows which have to be updated */
  where: PhotoMediaTag_Bool_Exp;
};

/** aggregated selection of "photo" */
export type Photo_Aggregate = {
  __typename?: 'photo_aggregate';
  aggregate?: Maybe<Photo_Aggregate_Fields>;
  nodes: Array<Photo>;
};

export type Photo_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Photo_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Photo_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Photo_Aggregate_Bool_Exp_Count>;
};

export type Photo_Aggregate_Bool_Exp_Bool_And = {
  arguments: Photo_Select_Column_Photo_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Photo_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Photo_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Photo_Select_Column_Photo_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Photo_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Photo_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Photo_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Photo_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "photo" */
export type Photo_Aggregate_Fields = {
  __typename?: 'photo_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Photo_Max_Fields>;
  min?: Maybe<Photo_Min_Fields>;
};


/** aggregate fields of "photo" */
export type Photo_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Photo_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "photo" */
export type Photo_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Photo_Max_Order_By>;
  min?: InputMaybe<Photo_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Photo_Append_Input = {
  pinturaImageState?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "photo" */
export type Photo_Arr_Rel_Insert_Input = {
  data: Array<Photo_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Photo_On_Conflict>;
};

/** Boolean expression to filter rows from the table "photo". All fields are combined with a logical 'AND'. */
export type Photo_Bool_Exp = {
  _and?: InputMaybe<Array<Photo_Bool_Exp>>;
  _not?: InputMaybe<Photo_Bool_Exp>;
  _or?: InputMaybe<Array<Photo_Bool_Exp>>;
  appointmentId?: InputMaybe<Uuid_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  filePath?: InputMaybe<String_Comparison_Exp>;
  fullMediaConsent?: InputMaybe<Boolean_Comparison_Exp>;
  hasThumbnail?: InputMaybe<Boolean_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  isPublic?: InputMaybe<Boolean_Comparison_Exp>;
  isSharedWithPatient?: InputMaybe<Boolean_Comparison_Exp>;
  mediaDate?: InputMaybe<Timestamptz_Comparison_Exp>;
  mediaType?: InputMaybe<String_Comparison_Exp>;
  mimeType?: InputMaybe<String_Comparison_Exp>;
  partialMediaConsent?: InputMaybe<Boolean_Comparison_Exp>;
  patient?: InputMaybe<Patient_Bool_Exp>;
  patientId?: InputMaybe<Uuid_Comparison_Exp>;
  photoMediaTags?: InputMaybe<PhotoMediaTag_Bool_Exp>;
  photoMediaTags_aggregate?: InputMaybe<PhotoMediaTag_Aggregate_Bool_Exp>;
  pinturaImageState?: InputMaybe<Jsonb_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  uploader?: InputMaybe<User_Bool_Exp>;
  uploaderId?: InputMaybe<Uuid_Comparison_Exp>;
  workspace?: InputMaybe<Workspace_Bool_Exp>;
  workspaceId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "photo" */
export enum Photo_Constraint {
  /** unique or primary key constraint on columns "filePath" */
  PhotoFilePathKey = 'photo_filePath_key',
  /** unique or primary key constraint on columns "id" */
  PhotoPkey = 'photo_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Photo_Delete_At_Path_Input = {
  pinturaImageState?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Photo_Delete_Elem_Input = {
  pinturaImageState?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Photo_Delete_Key_Input = {
  pinturaImageState?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "photo" */
export type Photo_Insert_Input = {
  appointmentId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  filePath?: InputMaybe<Scalars['String']>;
  fullMediaConsent?: InputMaybe<Scalars['Boolean']>;
  hasThumbnail?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['uuid']>;
  isPublic?: InputMaybe<Scalars['Boolean']>;
  isSharedWithPatient?: InputMaybe<Scalars['Boolean']>;
  mediaDate?: InputMaybe<Scalars['timestamptz']>;
  mediaType?: InputMaybe<Scalars['String']>;
  mimeType?: InputMaybe<Scalars['String']>;
  partialMediaConsent?: InputMaybe<Scalars['Boolean']>;
  patient?: InputMaybe<Patient_Obj_Rel_Insert_Input>;
  patientId?: InputMaybe<Scalars['uuid']>;
  photoMediaTags?: InputMaybe<PhotoMediaTag_Arr_Rel_Insert_Input>;
  pinturaImageState?: InputMaybe<Scalars['jsonb']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  uploader?: InputMaybe<User_Obj_Rel_Insert_Input>;
  uploaderId?: InputMaybe<Scalars['uuid']>;
  workspace?: InputMaybe<Workspace_Obj_Rel_Insert_Input>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Photo_Max_Fields = {
  __typename?: 'photo_max_fields';
  appointmentId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  filePath?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  mediaDate?: Maybe<Scalars['timestamptz']>;
  mediaType?: Maybe<Scalars['String']>;
  mimeType?: Maybe<Scalars['String']>;
  patientId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  uploaderId?: Maybe<Scalars['uuid']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "photo" */
export type Photo_Max_Order_By = {
  appointmentId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  filePath?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  mediaDate?: InputMaybe<Order_By>;
  mediaType?: InputMaybe<Order_By>;
  mimeType?: InputMaybe<Order_By>;
  patientId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  uploaderId?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Photo_Min_Fields = {
  __typename?: 'photo_min_fields';
  appointmentId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  filePath?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  mediaDate?: Maybe<Scalars['timestamptz']>;
  mediaType?: Maybe<Scalars['String']>;
  mimeType?: Maybe<Scalars['String']>;
  patientId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  uploaderId?: Maybe<Scalars['uuid']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "photo" */
export type Photo_Min_Order_By = {
  appointmentId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  filePath?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  mediaDate?: InputMaybe<Order_By>;
  mediaType?: InputMaybe<Order_By>;
  mimeType?: InputMaybe<Order_By>;
  patientId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  uploaderId?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "photo" */
export type Photo_Mutation_Response = {
  __typename?: 'photo_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Photo>;
};

/** input type for inserting object relation for remote table "photo" */
export type Photo_Obj_Rel_Insert_Input = {
  data: Photo_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Photo_On_Conflict>;
};

/** on_conflict condition type for table "photo" */
export type Photo_On_Conflict = {
  constraint: Photo_Constraint;
  update_columns?: Array<Photo_Update_Column>;
  where?: InputMaybe<Photo_Bool_Exp>;
};

/** Ordering options when selecting data from "photo". */
export type Photo_Order_By = {
  appointmentId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  filePath?: InputMaybe<Order_By>;
  fullMediaConsent?: InputMaybe<Order_By>;
  hasThumbnail?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  isPublic?: InputMaybe<Order_By>;
  isSharedWithPatient?: InputMaybe<Order_By>;
  mediaDate?: InputMaybe<Order_By>;
  mediaType?: InputMaybe<Order_By>;
  mimeType?: InputMaybe<Order_By>;
  partialMediaConsent?: InputMaybe<Order_By>;
  patient?: InputMaybe<Patient_Order_By>;
  patientId?: InputMaybe<Order_By>;
  photoMediaTags_aggregate?: InputMaybe<PhotoMediaTag_Aggregate_Order_By>;
  pinturaImageState?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  uploader?: InputMaybe<User_Order_By>;
  uploaderId?: InputMaybe<Order_By>;
  workspace?: InputMaybe<Workspace_Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: photo */
export type Photo_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Photo_Prepend_Input = {
  pinturaImageState?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "photo" */
export enum Photo_Select_Column {
  /** column name */
  AppointmentId = 'appointmentId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  FilePath = 'filePath',
  /** column name */
  FullMediaConsent = 'fullMediaConsent',
  /** column name */
  HasThumbnail = 'hasThumbnail',
  /** column name */
  Id = 'id',
  /** column name */
  IsPublic = 'isPublic',
  /** column name */
  IsSharedWithPatient = 'isSharedWithPatient',
  /** column name */
  MediaDate = 'mediaDate',
  /** column name */
  MediaType = 'mediaType',
  /** column name */
  MimeType = 'mimeType',
  /** column name */
  PartialMediaConsent = 'partialMediaConsent',
  /** column name */
  PatientId = 'patientId',
  /** column name */
  PinturaImageState = 'pinturaImageState',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UploaderId = 'uploaderId',
  /** column name */
  WorkspaceId = 'workspaceId'
}

/** select "photo_aggregate_bool_exp_bool_and_arguments_columns" columns of table "photo" */
export enum Photo_Select_Column_Photo_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  FullMediaConsent = 'fullMediaConsent',
  /** column name */
  HasThumbnail = 'hasThumbnail',
  /** column name */
  IsPublic = 'isPublic',
  /** column name */
  IsSharedWithPatient = 'isSharedWithPatient',
  /** column name */
  PartialMediaConsent = 'partialMediaConsent'
}

/** select "photo_aggregate_bool_exp_bool_or_arguments_columns" columns of table "photo" */
export enum Photo_Select_Column_Photo_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  FullMediaConsent = 'fullMediaConsent',
  /** column name */
  HasThumbnail = 'hasThumbnail',
  /** column name */
  IsPublic = 'isPublic',
  /** column name */
  IsSharedWithPatient = 'isSharedWithPatient',
  /** column name */
  PartialMediaConsent = 'partialMediaConsent'
}

/** input type for updating data in table "photo" */
export type Photo_Set_Input = {
  appointmentId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  filePath?: InputMaybe<Scalars['String']>;
  fullMediaConsent?: InputMaybe<Scalars['Boolean']>;
  hasThumbnail?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['uuid']>;
  isPublic?: InputMaybe<Scalars['Boolean']>;
  isSharedWithPatient?: InputMaybe<Scalars['Boolean']>;
  mediaDate?: InputMaybe<Scalars['timestamptz']>;
  mediaType?: InputMaybe<Scalars['String']>;
  mimeType?: InputMaybe<Scalars['String']>;
  partialMediaConsent?: InputMaybe<Scalars['Boolean']>;
  patientId?: InputMaybe<Scalars['uuid']>;
  pinturaImageState?: InputMaybe<Scalars['jsonb']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  uploaderId?: InputMaybe<Scalars['uuid']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "photo" */
export type Photo_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Photo_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Photo_Stream_Cursor_Value_Input = {
  appointmentId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  filePath?: InputMaybe<Scalars['String']>;
  fullMediaConsent?: InputMaybe<Scalars['Boolean']>;
  hasThumbnail?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['uuid']>;
  isPublic?: InputMaybe<Scalars['Boolean']>;
  isSharedWithPatient?: InputMaybe<Scalars['Boolean']>;
  mediaDate?: InputMaybe<Scalars['timestamptz']>;
  mediaType?: InputMaybe<Scalars['String']>;
  mimeType?: InputMaybe<Scalars['String']>;
  partialMediaConsent?: InputMaybe<Scalars['Boolean']>;
  patientId?: InputMaybe<Scalars['uuid']>;
  pinturaImageState?: InputMaybe<Scalars['jsonb']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  uploaderId?: InputMaybe<Scalars['uuid']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "photo" */
export enum Photo_Update_Column {
  /** column name */
  AppointmentId = 'appointmentId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  FilePath = 'filePath',
  /** column name */
  FullMediaConsent = 'fullMediaConsent',
  /** column name */
  HasThumbnail = 'hasThumbnail',
  /** column name */
  Id = 'id',
  /** column name */
  IsPublic = 'isPublic',
  /** column name */
  IsSharedWithPatient = 'isSharedWithPatient',
  /** column name */
  MediaDate = 'mediaDate',
  /** column name */
  MediaType = 'mediaType',
  /** column name */
  MimeType = 'mimeType',
  /** column name */
  PartialMediaConsent = 'partialMediaConsent',
  /** column name */
  PatientId = 'patientId',
  /** column name */
  PinturaImageState = 'pinturaImageState',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UploaderId = 'uploaderId',
  /** column name */
  WorkspaceId = 'workspaceId'
}

export type Photo_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Photo_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Photo_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Photo_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Photo_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Photo_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Photo_Set_Input>;
  /** filter the rows which have to be updated */
  where: Photo_Bool_Exp;
};

/** columns and relationships of "pin" */
export type Pin = {
  __typename?: 'pin';
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  updatedAt: Scalars['timestamptz'];
  value: Scalars['String'];
  workspaceId: Scalars['uuid'];
};

/** aggregated selection of "pin" */
export type Pin_Aggregate = {
  __typename?: 'pin_aggregate';
  aggregate?: Maybe<Pin_Aggregate_Fields>;
  nodes: Array<Pin>;
};

/** aggregate fields of "pin" */
export type Pin_Aggregate_Fields = {
  __typename?: 'pin_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Pin_Max_Fields>;
  min?: Maybe<Pin_Min_Fields>;
};


/** aggregate fields of "pin" */
export type Pin_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Pin_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "pin". All fields are combined with a logical 'AND'. */
export type Pin_Bool_Exp = {
  _and?: InputMaybe<Array<Pin_Bool_Exp>>;
  _not?: InputMaybe<Pin_Bool_Exp>;
  _or?: InputMaybe<Array<Pin_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
  workspaceId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "pin" */
export enum Pin_Constraint {
  /** unique or primary key constraint on columns "id" */
  PinPkey = 'pin_pkey',
  /** unique or primary key constraint on columns "workspaceId" */
  PinWorkspaceIdKey = 'pin_workspaceId_key'
}

/** input type for inserting data into table "pin" */
export type Pin_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  value?: InputMaybe<Scalars['String']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Pin_Max_Fields = {
  __typename?: 'pin_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['String']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Pin_Min_Fields = {
  __typename?: 'pin_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['String']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "pin" */
export type Pin_Mutation_Response = {
  __typename?: 'pin_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Pin>;
};

/** on_conflict condition type for table "pin" */
export type Pin_On_Conflict = {
  constraint: Pin_Constraint;
  update_columns?: Array<Pin_Update_Column>;
  where?: InputMaybe<Pin_Bool_Exp>;
};

/** Ordering options when selecting data from "pin". */
export type Pin_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: pin */
export type Pin_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "pin" */
export enum Pin_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Value = 'value',
  /** column name */
  WorkspaceId = 'workspaceId'
}

/** input type for updating data in table "pin" */
export type Pin_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  value?: InputMaybe<Scalars['String']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "pin" */
export type Pin_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Pin_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Pin_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  value?: InputMaybe<Scalars['String']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "pin" */
export enum Pin_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Value = 'value',
  /** column name */
  WorkspaceId = 'workspaceId'
}

export type Pin_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Pin_Set_Input>;
  /** filter the rows which have to be updated */
  where: Pin_Bool_Exp;
};

/** columns and relationships of "postStatus" */
export type PostStatus = {
  __typename?: 'postStatus';
  description: Scalars['String'];
  /** An array relationship */
  socialMediaAccountPosts: Array<SocialMediaAccountPost>;
  /** An aggregate relationship */
  socialMediaAccountPosts_aggregate: SocialMediaAccountPost_Aggregate;
  value: Scalars['String'];
};


/** columns and relationships of "postStatus" */
export type PostStatusSocialMediaAccountPostsArgs = {
  distinct_on?: InputMaybe<Array<SocialMediaAccountPost_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SocialMediaAccountPost_Order_By>>;
  where?: InputMaybe<SocialMediaAccountPost_Bool_Exp>;
};


/** columns and relationships of "postStatus" */
export type PostStatusSocialMediaAccountPosts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<SocialMediaAccountPost_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SocialMediaAccountPost_Order_By>>;
  where?: InputMaybe<SocialMediaAccountPost_Bool_Exp>;
};

/** aggregated selection of "postStatus" */
export type PostStatus_Aggregate = {
  __typename?: 'postStatus_aggregate';
  aggregate?: Maybe<PostStatus_Aggregate_Fields>;
  nodes: Array<PostStatus>;
};

/** aggregate fields of "postStatus" */
export type PostStatus_Aggregate_Fields = {
  __typename?: 'postStatus_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<PostStatus_Max_Fields>;
  min?: Maybe<PostStatus_Min_Fields>;
};


/** aggregate fields of "postStatus" */
export type PostStatus_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<PostStatus_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "postStatus". All fields are combined with a logical 'AND'. */
export type PostStatus_Bool_Exp = {
  _and?: InputMaybe<Array<PostStatus_Bool_Exp>>;
  _not?: InputMaybe<PostStatus_Bool_Exp>;
  _or?: InputMaybe<Array<PostStatus_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  socialMediaAccountPosts?: InputMaybe<SocialMediaAccountPost_Bool_Exp>;
  socialMediaAccountPosts_aggregate?: InputMaybe<SocialMediaAccountPost_Aggregate_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "postStatus" */
export enum PostStatus_Constraint {
  /** unique or primary key constraint on columns "value" */
  PostStatusPkey = 'postStatus_pkey'
}

export enum PostStatus_Enum {
  /** Created */
  Created = 'CREATED',
  /** Failed to post */
  FailedToPost = 'FAILED_TO_POST',
  /** Posted */
  Posted = 'POSTED'
}

/** Boolean expression to compare columns of type "postStatus_enum". All fields are combined with logical 'AND'. */
export type PostStatus_Enum_Comparison_Exp = {
  _eq?: InputMaybe<PostStatus_Enum>;
  _in?: InputMaybe<Array<PostStatus_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<PostStatus_Enum>;
  _nin?: InputMaybe<Array<PostStatus_Enum>>;
};

/** input type for inserting data into table "postStatus" */
export type PostStatus_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  socialMediaAccountPosts?: InputMaybe<SocialMediaAccountPost_Arr_Rel_Insert_Input>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type PostStatus_Max_Fields = {
  __typename?: 'postStatus_max_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type PostStatus_Min_Fields = {
  __typename?: 'postStatus_min_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "postStatus" */
export type PostStatus_Mutation_Response = {
  __typename?: 'postStatus_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<PostStatus>;
};

/** input type for inserting object relation for remote table "postStatus" */
export type PostStatus_Obj_Rel_Insert_Input = {
  data: PostStatus_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<PostStatus_On_Conflict>;
};

/** on_conflict condition type for table "postStatus" */
export type PostStatus_On_Conflict = {
  constraint: PostStatus_Constraint;
  update_columns?: Array<PostStatus_Update_Column>;
  where?: InputMaybe<PostStatus_Bool_Exp>;
};

/** Ordering options when selecting data from "postStatus". */
export type PostStatus_Order_By = {
  description?: InputMaybe<Order_By>;
  socialMediaAccountPosts_aggregate?: InputMaybe<SocialMediaAccountPost_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: postStatus */
export type PostStatus_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "postStatus" */
export enum PostStatus_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "postStatus" */
export type PostStatus_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "postStatus" */
export type PostStatus_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: PostStatus_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type PostStatus_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "postStatus" */
export enum PostStatus_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

export type PostStatus_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<PostStatus_Set_Input>;
  /** filter the rows which have to be updated */
  where: PostStatus_Bool_Exp;
};

/** columns and relationships of "prescription" */
export type Prescription = {
  __typename?: 'prescription';
  clinicId: Scalars['Int'];
  clinicianId: Scalars['Int'];
  created_at: Scalars['timestamptz'];
  details?: Maybe<Scalars['jsonb']>;
  dosespotPatientId: Scalars['Int'];
  dosespotPrescriptionId: Scalars['Int'];
  eventType: Scalars['String'];
  id: Scalars['uuid'];
  /** An object relationship */
  location: Location;
  locationId: Scalars['uuid'];
  /** An object relationship */
  patient: Patient;
  patientId: Scalars['uuid'];
  prescriptionDetailId?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  provider: Provider;
  providerId: Scalars['uuid'];
  statusDateTime: Scalars['timestamptz'];
  statusDetails: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  workspaceId: Scalars['uuid'];
};


/** columns and relationships of "prescription" */
export type PrescriptionDetailsArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "prescriptionDetail" */
export type PrescriptionDetail = {
  __typename?: 'prescriptionDetail';
  clinicId: Scalars['Int'];
  createdAt: Scalars['timestamptz'];
  daysSupply: Scalars['Int'];
  directions: Scalars['String'];
  dispenseUnitDescription: Scalars['String'];
  displayName: Scalars['String'];
  dosespotPatientId: Scalars['Int'];
  dosespotPrescriptionId: Scalars['Int'];
  drugClassification?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  /** An object relationship */
  location: Location;
  locationId: Scalars['uuid'];
  /** An object relationship */
  patient: Patient;
  patientId: Scalars['uuid'];
  pharmacyId: Scalars['Int'];
  prescriberId: Scalars['Int'];
  /** An array relationship */
  prescriptions: Array<Prescription>;
  /** An aggregate relationship */
  prescriptions_aggregate: Prescription_Aggregate;
  /** An object relationship */
  provider: Provider;
  providerId: Scalars['uuid'];
  quantity: Scalars['String'];
  refills: Scalars['String'];
  strength?: Maybe<Scalars['String']>;
  updatedAt: Scalars['timestamptz'];
  workspaceId: Scalars['uuid'];
  writtenDate: Scalars['timestamptz'];
};


/** columns and relationships of "prescriptionDetail" */
export type PrescriptionDetailPrescriptionsArgs = {
  distinct_on?: InputMaybe<Array<Prescription_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Prescription_Order_By>>;
  where?: InputMaybe<Prescription_Bool_Exp>;
};


/** columns and relationships of "prescriptionDetail" */
export type PrescriptionDetailPrescriptions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Prescription_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Prescription_Order_By>>;
  where?: InputMaybe<Prescription_Bool_Exp>;
};

/** aggregated selection of "prescriptionDetail" */
export type PrescriptionDetail_Aggregate = {
  __typename?: 'prescriptionDetail_aggregate';
  aggregate?: Maybe<PrescriptionDetail_Aggregate_Fields>;
  nodes: Array<PrescriptionDetail>;
};

/** aggregate fields of "prescriptionDetail" */
export type PrescriptionDetail_Aggregate_Fields = {
  __typename?: 'prescriptionDetail_aggregate_fields';
  avg?: Maybe<PrescriptionDetail_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<PrescriptionDetail_Max_Fields>;
  min?: Maybe<PrescriptionDetail_Min_Fields>;
  stddev?: Maybe<PrescriptionDetail_Stddev_Fields>;
  stddev_pop?: Maybe<PrescriptionDetail_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<PrescriptionDetail_Stddev_Samp_Fields>;
  sum?: Maybe<PrescriptionDetail_Sum_Fields>;
  var_pop?: Maybe<PrescriptionDetail_Var_Pop_Fields>;
  var_samp?: Maybe<PrescriptionDetail_Var_Samp_Fields>;
  variance?: Maybe<PrescriptionDetail_Variance_Fields>;
};


/** aggregate fields of "prescriptionDetail" */
export type PrescriptionDetail_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<PrescriptionDetail_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type PrescriptionDetail_Avg_Fields = {
  __typename?: 'prescriptionDetail_avg_fields';
  clinicId?: Maybe<Scalars['Float']>;
  daysSupply?: Maybe<Scalars['Float']>;
  dosespotPatientId?: Maybe<Scalars['Float']>;
  dosespotPrescriptionId?: Maybe<Scalars['Float']>;
  pharmacyId?: Maybe<Scalars['Float']>;
  prescriberId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "prescriptionDetail". All fields are combined with a logical 'AND'. */
export type PrescriptionDetail_Bool_Exp = {
  _and?: InputMaybe<Array<PrescriptionDetail_Bool_Exp>>;
  _not?: InputMaybe<PrescriptionDetail_Bool_Exp>;
  _or?: InputMaybe<Array<PrescriptionDetail_Bool_Exp>>;
  clinicId?: InputMaybe<Int_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  daysSupply?: InputMaybe<Int_Comparison_Exp>;
  directions?: InputMaybe<String_Comparison_Exp>;
  dispenseUnitDescription?: InputMaybe<String_Comparison_Exp>;
  displayName?: InputMaybe<String_Comparison_Exp>;
  dosespotPatientId?: InputMaybe<Int_Comparison_Exp>;
  dosespotPrescriptionId?: InputMaybe<Int_Comparison_Exp>;
  drugClassification?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  location?: InputMaybe<Location_Bool_Exp>;
  locationId?: InputMaybe<Uuid_Comparison_Exp>;
  patient?: InputMaybe<Patient_Bool_Exp>;
  patientId?: InputMaybe<Uuid_Comparison_Exp>;
  pharmacyId?: InputMaybe<Int_Comparison_Exp>;
  prescriberId?: InputMaybe<Int_Comparison_Exp>;
  prescriptions?: InputMaybe<Prescription_Bool_Exp>;
  prescriptions_aggregate?: InputMaybe<Prescription_Aggregate_Bool_Exp>;
  provider?: InputMaybe<Provider_Bool_Exp>;
  providerId?: InputMaybe<Uuid_Comparison_Exp>;
  quantity?: InputMaybe<String_Comparison_Exp>;
  refills?: InputMaybe<String_Comparison_Exp>;
  strength?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  workspaceId?: InputMaybe<Uuid_Comparison_Exp>;
  writtenDate?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "prescriptionDetail" */
export enum PrescriptionDetail_Constraint {
  /** unique or primary key constraint on columns "dosespotPrescriptionId" */
  PrescriptionDetailDosespotPrescriptionIdKey = 'prescriptionDetail_dosespotPrescriptionId_key',
  /** unique or primary key constraint on columns "id" */
  PrescriptionDetailPkey = 'prescriptionDetail_pkey'
}

/** input type for incrementing numeric columns in table "prescriptionDetail" */
export type PrescriptionDetail_Inc_Input = {
  clinicId?: InputMaybe<Scalars['Int']>;
  daysSupply?: InputMaybe<Scalars['Int']>;
  dosespotPatientId?: InputMaybe<Scalars['Int']>;
  dosespotPrescriptionId?: InputMaybe<Scalars['Int']>;
  pharmacyId?: InputMaybe<Scalars['Int']>;
  prescriberId?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "prescriptionDetail" */
export type PrescriptionDetail_Insert_Input = {
  clinicId?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  daysSupply?: InputMaybe<Scalars['Int']>;
  directions?: InputMaybe<Scalars['String']>;
  dispenseUnitDescription?: InputMaybe<Scalars['String']>;
  displayName?: InputMaybe<Scalars['String']>;
  dosespotPatientId?: InputMaybe<Scalars['Int']>;
  dosespotPrescriptionId?: InputMaybe<Scalars['Int']>;
  drugClassification?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  location?: InputMaybe<Location_Obj_Rel_Insert_Input>;
  locationId?: InputMaybe<Scalars['uuid']>;
  patient?: InputMaybe<Patient_Obj_Rel_Insert_Input>;
  patientId?: InputMaybe<Scalars['uuid']>;
  pharmacyId?: InputMaybe<Scalars['Int']>;
  prescriberId?: InputMaybe<Scalars['Int']>;
  prescriptions?: InputMaybe<Prescription_Arr_Rel_Insert_Input>;
  provider?: InputMaybe<Provider_Obj_Rel_Insert_Input>;
  providerId?: InputMaybe<Scalars['uuid']>;
  quantity?: InputMaybe<Scalars['String']>;
  refills?: InputMaybe<Scalars['String']>;
  strength?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
  writtenDate?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type PrescriptionDetail_Max_Fields = {
  __typename?: 'prescriptionDetail_max_fields';
  clinicId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  daysSupply?: Maybe<Scalars['Int']>;
  directions?: Maybe<Scalars['String']>;
  dispenseUnitDescription?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  dosespotPatientId?: Maybe<Scalars['Int']>;
  dosespotPrescriptionId?: Maybe<Scalars['Int']>;
  drugClassification?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  locationId?: Maybe<Scalars['uuid']>;
  patientId?: Maybe<Scalars['uuid']>;
  pharmacyId?: Maybe<Scalars['Int']>;
  prescriberId?: Maybe<Scalars['Int']>;
  providerId?: Maybe<Scalars['uuid']>;
  quantity?: Maybe<Scalars['String']>;
  refills?: Maybe<Scalars['String']>;
  strength?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
  writtenDate?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type PrescriptionDetail_Min_Fields = {
  __typename?: 'prescriptionDetail_min_fields';
  clinicId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  daysSupply?: Maybe<Scalars['Int']>;
  directions?: Maybe<Scalars['String']>;
  dispenseUnitDescription?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  dosespotPatientId?: Maybe<Scalars['Int']>;
  dosespotPrescriptionId?: Maybe<Scalars['Int']>;
  drugClassification?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  locationId?: Maybe<Scalars['uuid']>;
  patientId?: Maybe<Scalars['uuid']>;
  pharmacyId?: Maybe<Scalars['Int']>;
  prescriberId?: Maybe<Scalars['Int']>;
  providerId?: Maybe<Scalars['uuid']>;
  quantity?: Maybe<Scalars['String']>;
  refills?: Maybe<Scalars['String']>;
  strength?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
  writtenDate?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "prescriptionDetail" */
export type PrescriptionDetail_Mutation_Response = {
  __typename?: 'prescriptionDetail_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<PrescriptionDetail>;
};

/** on_conflict condition type for table "prescriptionDetail" */
export type PrescriptionDetail_On_Conflict = {
  constraint: PrescriptionDetail_Constraint;
  update_columns?: Array<PrescriptionDetail_Update_Column>;
  where?: InputMaybe<PrescriptionDetail_Bool_Exp>;
};

/** Ordering options when selecting data from "prescriptionDetail". */
export type PrescriptionDetail_Order_By = {
  clinicId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  daysSupply?: InputMaybe<Order_By>;
  directions?: InputMaybe<Order_By>;
  dispenseUnitDescription?: InputMaybe<Order_By>;
  displayName?: InputMaybe<Order_By>;
  dosespotPatientId?: InputMaybe<Order_By>;
  dosespotPrescriptionId?: InputMaybe<Order_By>;
  drugClassification?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  location?: InputMaybe<Location_Order_By>;
  locationId?: InputMaybe<Order_By>;
  patient?: InputMaybe<Patient_Order_By>;
  patientId?: InputMaybe<Order_By>;
  pharmacyId?: InputMaybe<Order_By>;
  prescriberId?: InputMaybe<Order_By>;
  prescriptions_aggregate?: InputMaybe<Prescription_Aggregate_Order_By>;
  provider?: InputMaybe<Provider_Order_By>;
  providerId?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  refills?: InputMaybe<Order_By>;
  strength?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
  writtenDate?: InputMaybe<Order_By>;
};

/** primary key columns input for table: prescriptionDetail */
export type PrescriptionDetail_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "prescriptionDetail" */
export enum PrescriptionDetail_Select_Column {
  /** column name */
  ClinicId = 'clinicId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DaysSupply = 'daysSupply',
  /** column name */
  Directions = 'directions',
  /** column name */
  DispenseUnitDescription = 'dispenseUnitDescription',
  /** column name */
  DisplayName = 'displayName',
  /** column name */
  DosespotPatientId = 'dosespotPatientId',
  /** column name */
  DosespotPrescriptionId = 'dosespotPrescriptionId',
  /** column name */
  DrugClassification = 'drugClassification',
  /** column name */
  Id = 'id',
  /** column name */
  LocationId = 'locationId',
  /** column name */
  PatientId = 'patientId',
  /** column name */
  PharmacyId = 'pharmacyId',
  /** column name */
  PrescriberId = 'prescriberId',
  /** column name */
  ProviderId = 'providerId',
  /** column name */
  Quantity = 'quantity',
  /** column name */
  Refills = 'refills',
  /** column name */
  Strength = 'strength',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WorkspaceId = 'workspaceId',
  /** column name */
  WrittenDate = 'writtenDate'
}

/** input type for updating data in table "prescriptionDetail" */
export type PrescriptionDetail_Set_Input = {
  clinicId?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  daysSupply?: InputMaybe<Scalars['Int']>;
  directions?: InputMaybe<Scalars['String']>;
  dispenseUnitDescription?: InputMaybe<Scalars['String']>;
  displayName?: InputMaybe<Scalars['String']>;
  dosespotPatientId?: InputMaybe<Scalars['Int']>;
  dosespotPrescriptionId?: InputMaybe<Scalars['Int']>;
  drugClassification?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  locationId?: InputMaybe<Scalars['uuid']>;
  patientId?: InputMaybe<Scalars['uuid']>;
  pharmacyId?: InputMaybe<Scalars['Int']>;
  prescriberId?: InputMaybe<Scalars['Int']>;
  providerId?: InputMaybe<Scalars['uuid']>;
  quantity?: InputMaybe<Scalars['String']>;
  refills?: InputMaybe<Scalars['String']>;
  strength?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
  writtenDate?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type PrescriptionDetail_Stddev_Fields = {
  __typename?: 'prescriptionDetail_stddev_fields';
  clinicId?: Maybe<Scalars['Float']>;
  daysSupply?: Maybe<Scalars['Float']>;
  dosespotPatientId?: Maybe<Scalars['Float']>;
  dosespotPrescriptionId?: Maybe<Scalars['Float']>;
  pharmacyId?: Maybe<Scalars['Float']>;
  prescriberId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type PrescriptionDetail_Stddev_Pop_Fields = {
  __typename?: 'prescriptionDetail_stddev_pop_fields';
  clinicId?: Maybe<Scalars['Float']>;
  daysSupply?: Maybe<Scalars['Float']>;
  dosespotPatientId?: Maybe<Scalars['Float']>;
  dosespotPrescriptionId?: Maybe<Scalars['Float']>;
  pharmacyId?: Maybe<Scalars['Float']>;
  prescriberId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type PrescriptionDetail_Stddev_Samp_Fields = {
  __typename?: 'prescriptionDetail_stddev_samp_fields';
  clinicId?: Maybe<Scalars['Float']>;
  daysSupply?: Maybe<Scalars['Float']>;
  dosespotPatientId?: Maybe<Scalars['Float']>;
  dosespotPrescriptionId?: Maybe<Scalars['Float']>;
  pharmacyId?: Maybe<Scalars['Float']>;
  prescriberId?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "prescriptionDetail" */
export type PrescriptionDetail_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: PrescriptionDetail_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type PrescriptionDetail_Stream_Cursor_Value_Input = {
  clinicId?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  daysSupply?: InputMaybe<Scalars['Int']>;
  directions?: InputMaybe<Scalars['String']>;
  dispenseUnitDescription?: InputMaybe<Scalars['String']>;
  displayName?: InputMaybe<Scalars['String']>;
  dosespotPatientId?: InputMaybe<Scalars['Int']>;
  dosespotPrescriptionId?: InputMaybe<Scalars['Int']>;
  drugClassification?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  locationId?: InputMaybe<Scalars['uuid']>;
  patientId?: InputMaybe<Scalars['uuid']>;
  pharmacyId?: InputMaybe<Scalars['Int']>;
  prescriberId?: InputMaybe<Scalars['Int']>;
  providerId?: InputMaybe<Scalars['uuid']>;
  quantity?: InputMaybe<Scalars['String']>;
  refills?: InputMaybe<Scalars['String']>;
  strength?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
  writtenDate?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type PrescriptionDetail_Sum_Fields = {
  __typename?: 'prescriptionDetail_sum_fields';
  clinicId?: Maybe<Scalars['Int']>;
  daysSupply?: Maybe<Scalars['Int']>;
  dosespotPatientId?: Maybe<Scalars['Int']>;
  dosespotPrescriptionId?: Maybe<Scalars['Int']>;
  pharmacyId?: Maybe<Scalars['Int']>;
  prescriberId?: Maybe<Scalars['Int']>;
};

/** update columns of table "prescriptionDetail" */
export enum PrescriptionDetail_Update_Column {
  /** column name */
  ClinicId = 'clinicId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DaysSupply = 'daysSupply',
  /** column name */
  Directions = 'directions',
  /** column name */
  DispenseUnitDescription = 'dispenseUnitDescription',
  /** column name */
  DisplayName = 'displayName',
  /** column name */
  DosespotPatientId = 'dosespotPatientId',
  /** column name */
  DosespotPrescriptionId = 'dosespotPrescriptionId',
  /** column name */
  DrugClassification = 'drugClassification',
  /** column name */
  Id = 'id',
  /** column name */
  LocationId = 'locationId',
  /** column name */
  PatientId = 'patientId',
  /** column name */
  PharmacyId = 'pharmacyId',
  /** column name */
  PrescriberId = 'prescriberId',
  /** column name */
  ProviderId = 'providerId',
  /** column name */
  Quantity = 'quantity',
  /** column name */
  Refills = 'refills',
  /** column name */
  Strength = 'strength',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WorkspaceId = 'workspaceId',
  /** column name */
  WrittenDate = 'writtenDate'
}

export type PrescriptionDetail_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<PrescriptionDetail_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<PrescriptionDetail_Set_Input>;
  /** filter the rows which have to be updated */
  where: PrescriptionDetail_Bool_Exp;
};

/** aggregate var_pop on columns */
export type PrescriptionDetail_Var_Pop_Fields = {
  __typename?: 'prescriptionDetail_var_pop_fields';
  clinicId?: Maybe<Scalars['Float']>;
  daysSupply?: Maybe<Scalars['Float']>;
  dosespotPatientId?: Maybe<Scalars['Float']>;
  dosespotPrescriptionId?: Maybe<Scalars['Float']>;
  pharmacyId?: Maybe<Scalars['Float']>;
  prescriberId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type PrescriptionDetail_Var_Samp_Fields = {
  __typename?: 'prescriptionDetail_var_samp_fields';
  clinicId?: Maybe<Scalars['Float']>;
  daysSupply?: Maybe<Scalars['Float']>;
  dosespotPatientId?: Maybe<Scalars['Float']>;
  dosespotPrescriptionId?: Maybe<Scalars['Float']>;
  pharmacyId?: Maybe<Scalars['Float']>;
  prescriberId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type PrescriptionDetail_Variance_Fields = {
  __typename?: 'prescriptionDetail_variance_fields';
  clinicId?: Maybe<Scalars['Float']>;
  daysSupply?: Maybe<Scalars['Float']>;
  dosespotPatientId?: Maybe<Scalars['Float']>;
  dosespotPrescriptionId?: Maybe<Scalars['Float']>;
  pharmacyId?: Maybe<Scalars['Float']>;
  prescriberId?: Maybe<Scalars['Float']>;
};

/** aggregated selection of "prescription" */
export type Prescription_Aggregate = {
  __typename?: 'prescription_aggregate';
  aggregate?: Maybe<Prescription_Aggregate_Fields>;
  nodes: Array<Prescription>;
};

export type Prescription_Aggregate_Bool_Exp = {
  count?: InputMaybe<Prescription_Aggregate_Bool_Exp_Count>;
};

export type Prescription_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Prescription_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Prescription_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "prescription" */
export type Prescription_Aggregate_Fields = {
  __typename?: 'prescription_aggregate_fields';
  avg?: Maybe<Prescription_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Prescription_Max_Fields>;
  min?: Maybe<Prescription_Min_Fields>;
  stddev?: Maybe<Prescription_Stddev_Fields>;
  stddev_pop?: Maybe<Prescription_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Prescription_Stddev_Samp_Fields>;
  sum?: Maybe<Prescription_Sum_Fields>;
  var_pop?: Maybe<Prescription_Var_Pop_Fields>;
  var_samp?: Maybe<Prescription_Var_Samp_Fields>;
  variance?: Maybe<Prescription_Variance_Fields>;
};


/** aggregate fields of "prescription" */
export type Prescription_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Prescription_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "prescription" */
export type Prescription_Aggregate_Order_By = {
  avg?: InputMaybe<Prescription_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Prescription_Max_Order_By>;
  min?: InputMaybe<Prescription_Min_Order_By>;
  stddev?: InputMaybe<Prescription_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Prescription_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Prescription_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Prescription_Sum_Order_By>;
  var_pop?: InputMaybe<Prescription_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Prescription_Var_Samp_Order_By>;
  variance?: InputMaybe<Prescription_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Prescription_Append_Input = {
  details?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "prescription" */
export type Prescription_Arr_Rel_Insert_Input = {
  data: Array<Prescription_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Prescription_On_Conflict>;
};

/** aggregate avg on columns */
export type Prescription_Avg_Fields = {
  __typename?: 'prescription_avg_fields';
  clinicId?: Maybe<Scalars['Float']>;
  clinicianId?: Maybe<Scalars['Float']>;
  dosespotPatientId?: Maybe<Scalars['Float']>;
  dosespotPrescriptionId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "prescription" */
export type Prescription_Avg_Order_By = {
  clinicId?: InputMaybe<Order_By>;
  clinicianId?: InputMaybe<Order_By>;
  dosespotPatientId?: InputMaybe<Order_By>;
  dosespotPrescriptionId?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "prescription". All fields are combined with a logical 'AND'. */
export type Prescription_Bool_Exp = {
  _and?: InputMaybe<Array<Prescription_Bool_Exp>>;
  _not?: InputMaybe<Prescription_Bool_Exp>;
  _or?: InputMaybe<Array<Prescription_Bool_Exp>>;
  clinicId?: InputMaybe<Int_Comparison_Exp>;
  clinicianId?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  details?: InputMaybe<Jsonb_Comparison_Exp>;
  dosespotPatientId?: InputMaybe<Int_Comparison_Exp>;
  dosespotPrescriptionId?: InputMaybe<Int_Comparison_Exp>;
  eventType?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  location?: InputMaybe<Location_Bool_Exp>;
  locationId?: InputMaybe<Uuid_Comparison_Exp>;
  patient?: InputMaybe<Patient_Bool_Exp>;
  patientId?: InputMaybe<Uuid_Comparison_Exp>;
  prescriptionDetailId?: InputMaybe<Uuid_Comparison_Exp>;
  provider?: InputMaybe<Provider_Bool_Exp>;
  providerId?: InputMaybe<Uuid_Comparison_Exp>;
  statusDateTime?: InputMaybe<Timestamptz_Comparison_Exp>;
  statusDetails?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  workspaceId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "prescription" */
export enum Prescription_Constraint {
  /** unique or primary key constraint on columns "id" */
  PrescriptionPkey = 'prescription_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Prescription_Delete_At_Path_Input = {
  details?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Prescription_Delete_Elem_Input = {
  details?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Prescription_Delete_Key_Input = {
  details?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "prescription" */
export type Prescription_Inc_Input = {
  clinicId?: InputMaybe<Scalars['Int']>;
  clinicianId?: InputMaybe<Scalars['Int']>;
  dosespotPatientId?: InputMaybe<Scalars['Int']>;
  dosespotPrescriptionId?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "prescription" */
export type Prescription_Insert_Input = {
  clinicId?: InputMaybe<Scalars['Int']>;
  clinicianId?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  details?: InputMaybe<Scalars['jsonb']>;
  dosespotPatientId?: InputMaybe<Scalars['Int']>;
  dosespotPrescriptionId?: InputMaybe<Scalars['Int']>;
  eventType?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  location?: InputMaybe<Location_Obj_Rel_Insert_Input>;
  locationId?: InputMaybe<Scalars['uuid']>;
  patient?: InputMaybe<Patient_Obj_Rel_Insert_Input>;
  patientId?: InputMaybe<Scalars['uuid']>;
  prescriptionDetailId?: InputMaybe<Scalars['uuid']>;
  provider?: InputMaybe<Provider_Obj_Rel_Insert_Input>;
  providerId?: InputMaybe<Scalars['uuid']>;
  statusDateTime?: InputMaybe<Scalars['timestamptz']>;
  statusDetails?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Prescription_Max_Fields = {
  __typename?: 'prescription_max_fields';
  clinicId?: Maybe<Scalars['Int']>;
  clinicianId?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  dosespotPatientId?: Maybe<Scalars['Int']>;
  dosespotPrescriptionId?: Maybe<Scalars['Int']>;
  eventType?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  locationId?: Maybe<Scalars['uuid']>;
  patientId?: Maybe<Scalars['uuid']>;
  prescriptionDetailId?: Maybe<Scalars['uuid']>;
  providerId?: Maybe<Scalars['uuid']>;
  statusDateTime?: Maybe<Scalars['timestamptz']>;
  statusDetails?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "prescription" */
export type Prescription_Max_Order_By = {
  clinicId?: InputMaybe<Order_By>;
  clinicianId?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  dosespotPatientId?: InputMaybe<Order_By>;
  dosespotPrescriptionId?: InputMaybe<Order_By>;
  eventType?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  locationId?: InputMaybe<Order_By>;
  patientId?: InputMaybe<Order_By>;
  prescriptionDetailId?: InputMaybe<Order_By>;
  providerId?: InputMaybe<Order_By>;
  statusDateTime?: InputMaybe<Order_By>;
  statusDetails?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Prescription_Min_Fields = {
  __typename?: 'prescription_min_fields';
  clinicId?: Maybe<Scalars['Int']>;
  clinicianId?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  dosespotPatientId?: Maybe<Scalars['Int']>;
  dosespotPrescriptionId?: Maybe<Scalars['Int']>;
  eventType?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  locationId?: Maybe<Scalars['uuid']>;
  patientId?: Maybe<Scalars['uuid']>;
  prescriptionDetailId?: Maybe<Scalars['uuid']>;
  providerId?: Maybe<Scalars['uuid']>;
  statusDateTime?: Maybe<Scalars['timestamptz']>;
  statusDetails?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "prescription" */
export type Prescription_Min_Order_By = {
  clinicId?: InputMaybe<Order_By>;
  clinicianId?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  dosespotPatientId?: InputMaybe<Order_By>;
  dosespotPrescriptionId?: InputMaybe<Order_By>;
  eventType?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  locationId?: InputMaybe<Order_By>;
  patientId?: InputMaybe<Order_By>;
  prescriptionDetailId?: InputMaybe<Order_By>;
  providerId?: InputMaybe<Order_By>;
  statusDateTime?: InputMaybe<Order_By>;
  statusDetails?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "prescription" */
export type Prescription_Mutation_Response = {
  __typename?: 'prescription_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Prescription>;
};

/** on_conflict condition type for table "prescription" */
export type Prescription_On_Conflict = {
  constraint: Prescription_Constraint;
  update_columns?: Array<Prescription_Update_Column>;
  where?: InputMaybe<Prescription_Bool_Exp>;
};

/** Ordering options when selecting data from "prescription". */
export type Prescription_Order_By = {
  clinicId?: InputMaybe<Order_By>;
  clinicianId?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  details?: InputMaybe<Order_By>;
  dosespotPatientId?: InputMaybe<Order_By>;
  dosespotPrescriptionId?: InputMaybe<Order_By>;
  eventType?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  location?: InputMaybe<Location_Order_By>;
  locationId?: InputMaybe<Order_By>;
  patient?: InputMaybe<Patient_Order_By>;
  patientId?: InputMaybe<Order_By>;
  prescriptionDetailId?: InputMaybe<Order_By>;
  provider?: InputMaybe<Provider_Order_By>;
  providerId?: InputMaybe<Order_By>;
  statusDateTime?: InputMaybe<Order_By>;
  statusDetails?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: prescription */
export type Prescription_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Prescription_Prepend_Input = {
  details?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "prescription" */
export enum Prescription_Select_Column {
  /** column name */
  ClinicId = 'clinicId',
  /** column name */
  ClinicianId = 'clinicianId',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Details = 'details',
  /** column name */
  DosespotPatientId = 'dosespotPatientId',
  /** column name */
  DosespotPrescriptionId = 'dosespotPrescriptionId',
  /** column name */
  EventType = 'eventType',
  /** column name */
  Id = 'id',
  /** column name */
  LocationId = 'locationId',
  /** column name */
  PatientId = 'patientId',
  /** column name */
  PrescriptionDetailId = 'prescriptionDetailId',
  /** column name */
  ProviderId = 'providerId',
  /** column name */
  StatusDateTime = 'statusDateTime',
  /** column name */
  StatusDetails = 'statusDetails',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  WorkspaceId = 'workspaceId'
}

/** input type for updating data in table "prescription" */
export type Prescription_Set_Input = {
  clinicId?: InputMaybe<Scalars['Int']>;
  clinicianId?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  details?: InputMaybe<Scalars['jsonb']>;
  dosespotPatientId?: InputMaybe<Scalars['Int']>;
  dosespotPrescriptionId?: InputMaybe<Scalars['Int']>;
  eventType?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  locationId?: InputMaybe<Scalars['uuid']>;
  patientId?: InputMaybe<Scalars['uuid']>;
  prescriptionDetailId?: InputMaybe<Scalars['uuid']>;
  providerId?: InputMaybe<Scalars['uuid']>;
  statusDateTime?: InputMaybe<Scalars['timestamptz']>;
  statusDetails?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Prescription_Stddev_Fields = {
  __typename?: 'prescription_stddev_fields';
  clinicId?: Maybe<Scalars['Float']>;
  clinicianId?: Maybe<Scalars['Float']>;
  dosespotPatientId?: Maybe<Scalars['Float']>;
  dosespotPrescriptionId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "prescription" */
export type Prescription_Stddev_Order_By = {
  clinicId?: InputMaybe<Order_By>;
  clinicianId?: InputMaybe<Order_By>;
  dosespotPatientId?: InputMaybe<Order_By>;
  dosespotPrescriptionId?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Prescription_Stddev_Pop_Fields = {
  __typename?: 'prescription_stddev_pop_fields';
  clinicId?: Maybe<Scalars['Float']>;
  clinicianId?: Maybe<Scalars['Float']>;
  dosespotPatientId?: Maybe<Scalars['Float']>;
  dosespotPrescriptionId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "prescription" */
export type Prescription_Stddev_Pop_Order_By = {
  clinicId?: InputMaybe<Order_By>;
  clinicianId?: InputMaybe<Order_By>;
  dosespotPatientId?: InputMaybe<Order_By>;
  dosespotPrescriptionId?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Prescription_Stddev_Samp_Fields = {
  __typename?: 'prescription_stddev_samp_fields';
  clinicId?: Maybe<Scalars['Float']>;
  clinicianId?: Maybe<Scalars['Float']>;
  dosespotPatientId?: Maybe<Scalars['Float']>;
  dosespotPrescriptionId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "prescription" */
export type Prescription_Stddev_Samp_Order_By = {
  clinicId?: InputMaybe<Order_By>;
  clinicianId?: InputMaybe<Order_By>;
  dosespotPatientId?: InputMaybe<Order_By>;
  dosespotPrescriptionId?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "prescription" */
export type Prescription_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Prescription_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Prescription_Stream_Cursor_Value_Input = {
  clinicId?: InputMaybe<Scalars['Int']>;
  clinicianId?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  details?: InputMaybe<Scalars['jsonb']>;
  dosespotPatientId?: InputMaybe<Scalars['Int']>;
  dosespotPrescriptionId?: InputMaybe<Scalars['Int']>;
  eventType?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  locationId?: InputMaybe<Scalars['uuid']>;
  patientId?: InputMaybe<Scalars['uuid']>;
  prescriptionDetailId?: InputMaybe<Scalars['uuid']>;
  providerId?: InputMaybe<Scalars['uuid']>;
  statusDateTime?: InputMaybe<Scalars['timestamptz']>;
  statusDetails?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Prescription_Sum_Fields = {
  __typename?: 'prescription_sum_fields';
  clinicId?: Maybe<Scalars['Int']>;
  clinicianId?: Maybe<Scalars['Int']>;
  dosespotPatientId?: Maybe<Scalars['Int']>;
  dosespotPrescriptionId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "prescription" */
export type Prescription_Sum_Order_By = {
  clinicId?: InputMaybe<Order_By>;
  clinicianId?: InputMaybe<Order_By>;
  dosespotPatientId?: InputMaybe<Order_By>;
  dosespotPrescriptionId?: InputMaybe<Order_By>;
};

/** update columns of table "prescription" */
export enum Prescription_Update_Column {
  /** column name */
  ClinicId = 'clinicId',
  /** column name */
  ClinicianId = 'clinicianId',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Details = 'details',
  /** column name */
  DosespotPatientId = 'dosespotPatientId',
  /** column name */
  DosespotPrescriptionId = 'dosespotPrescriptionId',
  /** column name */
  EventType = 'eventType',
  /** column name */
  Id = 'id',
  /** column name */
  LocationId = 'locationId',
  /** column name */
  PatientId = 'patientId',
  /** column name */
  PrescriptionDetailId = 'prescriptionDetailId',
  /** column name */
  ProviderId = 'providerId',
  /** column name */
  StatusDateTime = 'statusDateTime',
  /** column name */
  StatusDetails = 'statusDetails',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  WorkspaceId = 'workspaceId'
}

export type Prescription_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Prescription_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Prescription_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Prescription_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Prescription_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Prescription_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Prescription_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Prescription_Set_Input>;
  /** filter the rows which have to be updated */
  where: Prescription_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Prescription_Var_Pop_Fields = {
  __typename?: 'prescription_var_pop_fields';
  clinicId?: Maybe<Scalars['Float']>;
  clinicianId?: Maybe<Scalars['Float']>;
  dosespotPatientId?: Maybe<Scalars['Float']>;
  dosespotPrescriptionId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "prescription" */
export type Prescription_Var_Pop_Order_By = {
  clinicId?: InputMaybe<Order_By>;
  clinicianId?: InputMaybe<Order_By>;
  dosespotPatientId?: InputMaybe<Order_By>;
  dosespotPrescriptionId?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Prescription_Var_Samp_Fields = {
  __typename?: 'prescription_var_samp_fields';
  clinicId?: Maybe<Scalars['Float']>;
  clinicianId?: Maybe<Scalars['Float']>;
  dosespotPatientId?: Maybe<Scalars['Float']>;
  dosespotPrescriptionId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "prescription" */
export type Prescription_Var_Samp_Order_By = {
  clinicId?: InputMaybe<Order_By>;
  clinicianId?: InputMaybe<Order_By>;
  dosespotPatientId?: InputMaybe<Order_By>;
  dosespotPrescriptionId?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Prescription_Variance_Fields = {
  __typename?: 'prescription_variance_fields';
  clinicId?: Maybe<Scalars['Float']>;
  clinicianId?: Maybe<Scalars['Float']>;
  dosespotPatientId?: Maybe<Scalars['Float']>;
  dosespotPrescriptionId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "prescription" */
export type Prescription_Variance_Order_By = {
  clinicId?: InputMaybe<Order_By>;
  clinicianId?: InputMaybe<Order_By>;
  dosespotPatientId?: InputMaybe<Order_By>;
  dosespotPrescriptionId?: InputMaybe<Order_By>;
};

/** columns and relationships of "promotion" */
export type Promotion = {
  __typename?: 'promotion';
  createdAt: Scalars['timestamptz'];
  description: Scalars['String'];
  endsAt?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  image?: Maybe<Scalars['String']>;
  isActive: Scalars['Boolean'];
  isArchived: Scalars['Boolean'];
  /** An object relationship */
  location?: Maybe<Location>;
  locationId?: Maybe<Scalars['uuid']>;
  /** A computed field, executes function "calculate_promotion_price" */
  price?: Maybe<Scalars['numeric']>;
  /** An array relationship */
  promotionServices: Array<PromotionServices>;
  /** An aggregate relationship */
  promotionServices_aggregate: PromotionServices_Aggregate;
  startsAt?: Maybe<Scalars['timestamptz']>;
  title: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  /** An object relationship */
  workspace: Workspace;
  workspaceId: Scalars['uuid'];
};


/** columns and relationships of "promotion" */
export type PromotionPromotionServicesArgs = {
  distinct_on?: InputMaybe<Array<PromotionServices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PromotionServices_Order_By>>;
  where?: InputMaybe<PromotionServices_Bool_Exp>;
};


/** columns and relationships of "promotion" */
export type PromotionPromotionServices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<PromotionServices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PromotionServices_Order_By>>;
  where?: InputMaybe<PromotionServices_Bool_Exp>;
};

/** columns and relationships of "promotionCoupons" */
export type PromotionCoupons = {
  __typename?: 'promotionCoupons';
  couponId: Scalars['uuid'];
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  promotionId: Scalars['uuid'];
  updatedAt: Scalars['timestamptz'];
  workspaceId: Scalars['uuid'];
};

/** aggregated selection of "promotionCoupons" */
export type PromotionCoupons_Aggregate = {
  __typename?: 'promotionCoupons_aggregate';
  aggregate?: Maybe<PromotionCoupons_Aggregate_Fields>;
  nodes: Array<PromotionCoupons>;
};

/** aggregate fields of "promotionCoupons" */
export type PromotionCoupons_Aggregate_Fields = {
  __typename?: 'promotionCoupons_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<PromotionCoupons_Max_Fields>;
  min?: Maybe<PromotionCoupons_Min_Fields>;
};


/** aggregate fields of "promotionCoupons" */
export type PromotionCoupons_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<PromotionCoupons_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "promotionCoupons". All fields are combined with a logical 'AND'. */
export type PromotionCoupons_Bool_Exp = {
  _and?: InputMaybe<Array<PromotionCoupons_Bool_Exp>>;
  _not?: InputMaybe<PromotionCoupons_Bool_Exp>;
  _or?: InputMaybe<Array<PromotionCoupons_Bool_Exp>>;
  couponId?: InputMaybe<Uuid_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  promotionId?: InputMaybe<Uuid_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  workspaceId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "promotionCoupons" */
export enum PromotionCoupons_Constraint {
  /** unique or primary key constraint on columns "id" */
  PromotionCouponsPkey = 'promotionCoupons_pkey'
}

/** input type for inserting data into table "promotionCoupons" */
export type PromotionCoupons_Insert_Input = {
  couponId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  promotionId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type PromotionCoupons_Max_Fields = {
  __typename?: 'promotionCoupons_max_fields';
  couponId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  promotionId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type PromotionCoupons_Min_Fields = {
  __typename?: 'promotionCoupons_min_fields';
  couponId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  promotionId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "promotionCoupons" */
export type PromotionCoupons_Mutation_Response = {
  __typename?: 'promotionCoupons_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<PromotionCoupons>;
};

/** on_conflict condition type for table "promotionCoupons" */
export type PromotionCoupons_On_Conflict = {
  constraint: PromotionCoupons_Constraint;
  update_columns?: Array<PromotionCoupons_Update_Column>;
  where?: InputMaybe<PromotionCoupons_Bool_Exp>;
};

/** Ordering options when selecting data from "promotionCoupons". */
export type PromotionCoupons_Order_By = {
  couponId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  promotionId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: promotionCoupons */
export type PromotionCoupons_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "promotionCoupons" */
export enum PromotionCoupons_Select_Column {
  /** column name */
  CouponId = 'couponId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  PromotionId = 'promotionId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WorkspaceId = 'workspaceId'
}

/** input type for updating data in table "promotionCoupons" */
export type PromotionCoupons_Set_Input = {
  couponId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  promotionId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "promotionCoupons" */
export type PromotionCoupons_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: PromotionCoupons_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type PromotionCoupons_Stream_Cursor_Value_Input = {
  couponId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  promotionId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "promotionCoupons" */
export enum PromotionCoupons_Update_Column {
  /** column name */
  CouponId = 'couponId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  PromotionId = 'promotionId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WorkspaceId = 'workspaceId'
}

export type PromotionCoupons_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<PromotionCoupons_Set_Input>;
  /** filter the rows which have to be updated */
  where: PromotionCoupons_Bool_Exp;
};

/** columns and relationships of "promotionServices" */
export type PromotionServices = {
  __typename?: 'promotionServices';
  createdAt: Scalars['timestamptz'];
  discountAmount: Scalars['float8'];
  discountType: Scalars['String'];
  id: Scalars['uuid'];
  promotionId: Scalars['uuid'];
  quantity?: Maybe<Scalars['Int']>;
  /** An object relationship */
  service: Service;
  serviceId: Scalars['uuid'];
  updatedAt: Scalars['timestamptz'];
  workspaceId: Scalars['uuid'];
};

/** aggregated selection of "promotionServices" */
export type PromotionServices_Aggregate = {
  __typename?: 'promotionServices_aggregate';
  aggregate?: Maybe<PromotionServices_Aggregate_Fields>;
  nodes: Array<PromotionServices>;
};

export type PromotionServices_Aggregate_Bool_Exp = {
  avg?: InputMaybe<PromotionServices_Aggregate_Bool_Exp_Avg>;
  corr?: InputMaybe<PromotionServices_Aggregate_Bool_Exp_Corr>;
  count?: InputMaybe<PromotionServices_Aggregate_Bool_Exp_Count>;
  covar_samp?: InputMaybe<PromotionServices_Aggregate_Bool_Exp_Covar_Samp>;
  max?: InputMaybe<PromotionServices_Aggregate_Bool_Exp_Max>;
  min?: InputMaybe<PromotionServices_Aggregate_Bool_Exp_Min>;
  stddev_samp?: InputMaybe<PromotionServices_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: InputMaybe<PromotionServices_Aggregate_Bool_Exp_Sum>;
  var_samp?: InputMaybe<PromotionServices_Aggregate_Bool_Exp_Var_Samp>;
};

export type PromotionServices_Aggregate_Bool_Exp_Avg = {
  arguments: PromotionServices_Select_Column_PromotionServices_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<PromotionServices_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type PromotionServices_Aggregate_Bool_Exp_Corr = {
  arguments: PromotionServices_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<PromotionServices_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type PromotionServices_Aggregate_Bool_Exp_Corr_Arguments = {
  X: PromotionServices_Select_Column_PromotionServices_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: PromotionServices_Select_Column_PromotionServices_Aggregate_Bool_Exp_Corr_Arguments_Columns;
};

export type PromotionServices_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<PromotionServices_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<PromotionServices_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type PromotionServices_Aggregate_Bool_Exp_Covar_Samp = {
  arguments: PromotionServices_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<PromotionServices_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type PromotionServices_Aggregate_Bool_Exp_Covar_Samp_Arguments = {
  X: PromotionServices_Select_Column_PromotionServices_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  Y: PromotionServices_Select_Column_PromotionServices_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
};

export type PromotionServices_Aggregate_Bool_Exp_Max = {
  arguments: PromotionServices_Select_Column_PromotionServices_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<PromotionServices_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type PromotionServices_Aggregate_Bool_Exp_Min = {
  arguments: PromotionServices_Select_Column_PromotionServices_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<PromotionServices_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type PromotionServices_Aggregate_Bool_Exp_Stddev_Samp = {
  arguments: PromotionServices_Select_Column_PromotionServices_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<PromotionServices_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type PromotionServices_Aggregate_Bool_Exp_Sum = {
  arguments: PromotionServices_Select_Column_PromotionServices_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<PromotionServices_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type PromotionServices_Aggregate_Bool_Exp_Var_Samp = {
  arguments: PromotionServices_Select_Column_PromotionServices_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<PromotionServices_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

/** aggregate fields of "promotionServices" */
export type PromotionServices_Aggregate_Fields = {
  __typename?: 'promotionServices_aggregate_fields';
  avg?: Maybe<PromotionServices_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<PromotionServices_Max_Fields>;
  min?: Maybe<PromotionServices_Min_Fields>;
  stddev?: Maybe<PromotionServices_Stddev_Fields>;
  stddev_pop?: Maybe<PromotionServices_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<PromotionServices_Stddev_Samp_Fields>;
  sum?: Maybe<PromotionServices_Sum_Fields>;
  var_pop?: Maybe<PromotionServices_Var_Pop_Fields>;
  var_samp?: Maybe<PromotionServices_Var_Samp_Fields>;
  variance?: Maybe<PromotionServices_Variance_Fields>;
};


/** aggregate fields of "promotionServices" */
export type PromotionServices_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<PromotionServices_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "promotionServices" */
export type PromotionServices_Aggregate_Order_By = {
  avg?: InputMaybe<PromotionServices_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<PromotionServices_Max_Order_By>;
  min?: InputMaybe<PromotionServices_Min_Order_By>;
  stddev?: InputMaybe<PromotionServices_Stddev_Order_By>;
  stddev_pop?: InputMaybe<PromotionServices_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<PromotionServices_Stddev_Samp_Order_By>;
  sum?: InputMaybe<PromotionServices_Sum_Order_By>;
  var_pop?: InputMaybe<PromotionServices_Var_Pop_Order_By>;
  var_samp?: InputMaybe<PromotionServices_Var_Samp_Order_By>;
  variance?: InputMaybe<PromotionServices_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "promotionServices" */
export type PromotionServices_Arr_Rel_Insert_Input = {
  data: Array<PromotionServices_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<PromotionServices_On_Conflict>;
};

/** aggregate avg on columns */
export type PromotionServices_Avg_Fields = {
  __typename?: 'promotionServices_avg_fields';
  discountAmount?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "promotionServices" */
export type PromotionServices_Avg_Order_By = {
  discountAmount?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "promotionServices". All fields are combined with a logical 'AND'. */
export type PromotionServices_Bool_Exp = {
  _and?: InputMaybe<Array<PromotionServices_Bool_Exp>>;
  _not?: InputMaybe<PromotionServices_Bool_Exp>;
  _or?: InputMaybe<Array<PromotionServices_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  discountAmount?: InputMaybe<Float8_Comparison_Exp>;
  discountType?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  promotionId?: InputMaybe<Uuid_Comparison_Exp>;
  quantity?: InputMaybe<Int_Comparison_Exp>;
  service?: InputMaybe<Service_Bool_Exp>;
  serviceId?: InputMaybe<Uuid_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  workspaceId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "promotionServices" */
export enum PromotionServices_Constraint {
  /** unique or primary key constraint on columns "id" */
  PromotionServicesPkey = 'promotionServices_pkey'
}

/** input type for incrementing numeric columns in table "promotionServices" */
export type PromotionServices_Inc_Input = {
  discountAmount?: InputMaybe<Scalars['float8']>;
  quantity?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "promotionServices" */
export type PromotionServices_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  discountAmount?: InputMaybe<Scalars['float8']>;
  discountType?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  promotionId?: InputMaybe<Scalars['uuid']>;
  quantity?: InputMaybe<Scalars['Int']>;
  service?: InputMaybe<Service_Obj_Rel_Insert_Input>;
  serviceId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type PromotionServices_Max_Fields = {
  __typename?: 'promotionServices_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  discountAmount?: Maybe<Scalars['float8']>;
  discountType?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  promotionId?: Maybe<Scalars['uuid']>;
  quantity?: Maybe<Scalars['Int']>;
  serviceId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "promotionServices" */
export type PromotionServices_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  discountAmount?: InputMaybe<Order_By>;
  discountType?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  promotionId?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  serviceId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type PromotionServices_Min_Fields = {
  __typename?: 'promotionServices_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  discountAmount?: Maybe<Scalars['float8']>;
  discountType?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  promotionId?: Maybe<Scalars['uuid']>;
  quantity?: Maybe<Scalars['Int']>;
  serviceId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "promotionServices" */
export type PromotionServices_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  discountAmount?: InputMaybe<Order_By>;
  discountType?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  promotionId?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  serviceId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "promotionServices" */
export type PromotionServices_Mutation_Response = {
  __typename?: 'promotionServices_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<PromotionServices>;
};

/** on_conflict condition type for table "promotionServices" */
export type PromotionServices_On_Conflict = {
  constraint: PromotionServices_Constraint;
  update_columns?: Array<PromotionServices_Update_Column>;
  where?: InputMaybe<PromotionServices_Bool_Exp>;
};

/** Ordering options when selecting data from "promotionServices". */
export type PromotionServices_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  discountAmount?: InputMaybe<Order_By>;
  discountType?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  promotionId?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  service?: InputMaybe<Service_Order_By>;
  serviceId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: promotionServices */
export type PromotionServices_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "promotionServices" */
export enum PromotionServices_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DiscountAmount = 'discountAmount',
  /** column name */
  DiscountType = 'discountType',
  /** column name */
  Id = 'id',
  /** column name */
  PromotionId = 'promotionId',
  /** column name */
  Quantity = 'quantity',
  /** column name */
  ServiceId = 'serviceId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WorkspaceId = 'workspaceId'
}

/** select "promotionServices_aggregate_bool_exp_avg_arguments_columns" columns of table "promotionServices" */
export enum PromotionServices_Select_Column_PromotionServices_Aggregate_Bool_Exp_Avg_Arguments_Columns {
  /** column name */
  DiscountAmount = 'discountAmount'
}

/** select "promotionServices_aggregate_bool_exp_corr_arguments_columns" columns of table "promotionServices" */
export enum PromotionServices_Select_Column_PromotionServices_Aggregate_Bool_Exp_Corr_Arguments_Columns {
  /** column name */
  DiscountAmount = 'discountAmount'
}

/** select "promotionServices_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "promotionServices" */
export enum PromotionServices_Select_Column_PromotionServices_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns {
  /** column name */
  DiscountAmount = 'discountAmount'
}

/** select "promotionServices_aggregate_bool_exp_max_arguments_columns" columns of table "promotionServices" */
export enum PromotionServices_Select_Column_PromotionServices_Aggregate_Bool_Exp_Max_Arguments_Columns {
  /** column name */
  DiscountAmount = 'discountAmount'
}

/** select "promotionServices_aggregate_bool_exp_min_arguments_columns" columns of table "promotionServices" */
export enum PromotionServices_Select_Column_PromotionServices_Aggregate_Bool_Exp_Min_Arguments_Columns {
  /** column name */
  DiscountAmount = 'discountAmount'
}

/** select "promotionServices_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "promotionServices" */
export enum PromotionServices_Select_Column_PromotionServices_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns {
  /** column name */
  DiscountAmount = 'discountAmount'
}

/** select "promotionServices_aggregate_bool_exp_sum_arguments_columns" columns of table "promotionServices" */
export enum PromotionServices_Select_Column_PromotionServices_Aggregate_Bool_Exp_Sum_Arguments_Columns {
  /** column name */
  DiscountAmount = 'discountAmount'
}

/** select "promotionServices_aggregate_bool_exp_var_samp_arguments_columns" columns of table "promotionServices" */
export enum PromotionServices_Select_Column_PromotionServices_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns {
  /** column name */
  DiscountAmount = 'discountAmount'
}

/** input type for updating data in table "promotionServices" */
export type PromotionServices_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  discountAmount?: InputMaybe<Scalars['float8']>;
  discountType?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  promotionId?: InputMaybe<Scalars['uuid']>;
  quantity?: InputMaybe<Scalars['Int']>;
  serviceId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type PromotionServices_Stddev_Fields = {
  __typename?: 'promotionServices_stddev_fields';
  discountAmount?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "promotionServices" */
export type PromotionServices_Stddev_Order_By = {
  discountAmount?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type PromotionServices_Stddev_Pop_Fields = {
  __typename?: 'promotionServices_stddev_pop_fields';
  discountAmount?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "promotionServices" */
export type PromotionServices_Stddev_Pop_Order_By = {
  discountAmount?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type PromotionServices_Stddev_Samp_Fields = {
  __typename?: 'promotionServices_stddev_samp_fields';
  discountAmount?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "promotionServices" */
export type PromotionServices_Stddev_Samp_Order_By = {
  discountAmount?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "promotionServices" */
export type PromotionServices_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: PromotionServices_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type PromotionServices_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  discountAmount?: InputMaybe<Scalars['float8']>;
  discountType?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  promotionId?: InputMaybe<Scalars['uuid']>;
  quantity?: InputMaybe<Scalars['Int']>;
  serviceId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type PromotionServices_Sum_Fields = {
  __typename?: 'promotionServices_sum_fields';
  discountAmount?: Maybe<Scalars['float8']>;
  quantity?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "promotionServices" */
export type PromotionServices_Sum_Order_By = {
  discountAmount?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
};

/** update columns of table "promotionServices" */
export enum PromotionServices_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DiscountAmount = 'discountAmount',
  /** column name */
  DiscountType = 'discountType',
  /** column name */
  Id = 'id',
  /** column name */
  PromotionId = 'promotionId',
  /** column name */
  Quantity = 'quantity',
  /** column name */
  ServiceId = 'serviceId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WorkspaceId = 'workspaceId'
}

export type PromotionServices_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<PromotionServices_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<PromotionServices_Set_Input>;
  /** filter the rows which have to be updated */
  where: PromotionServices_Bool_Exp;
};

/** aggregate var_pop on columns */
export type PromotionServices_Var_Pop_Fields = {
  __typename?: 'promotionServices_var_pop_fields';
  discountAmount?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "promotionServices" */
export type PromotionServices_Var_Pop_Order_By = {
  discountAmount?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type PromotionServices_Var_Samp_Fields = {
  __typename?: 'promotionServices_var_samp_fields';
  discountAmount?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "promotionServices" */
export type PromotionServices_Var_Samp_Order_By = {
  discountAmount?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type PromotionServices_Variance_Fields = {
  __typename?: 'promotionServices_variance_fields';
  discountAmount?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "promotionServices" */
export type PromotionServices_Variance_Order_By = {
  discountAmount?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
};

/** aggregated selection of "promotion" */
export type Promotion_Aggregate = {
  __typename?: 'promotion_aggregate';
  aggregate?: Maybe<Promotion_Aggregate_Fields>;
  nodes: Array<Promotion>;
};

/** aggregate fields of "promotion" */
export type Promotion_Aggregate_Fields = {
  __typename?: 'promotion_aggregate_fields';
  avg?: Maybe<Promotion_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Promotion_Max_Fields>;
  min?: Maybe<Promotion_Min_Fields>;
  stddev?: Maybe<Promotion_Stddev_Fields>;
  stddev_pop?: Maybe<Promotion_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Promotion_Stddev_Samp_Fields>;
  sum?: Maybe<Promotion_Sum_Fields>;
  var_pop?: Maybe<Promotion_Var_Pop_Fields>;
  var_samp?: Maybe<Promotion_Var_Samp_Fields>;
  variance?: Maybe<Promotion_Variance_Fields>;
};


/** aggregate fields of "promotion" */
export type Promotion_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Promotion_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Promotion_Avg_Fields = {
  __typename?: 'promotion_avg_fields';
  /** A computed field, executes function "calculate_promotion_price" */
  price?: Maybe<Scalars['numeric']>;
};

/** Boolean expression to filter rows from the table "promotion". All fields are combined with a logical 'AND'. */
export type Promotion_Bool_Exp = {
  _and?: InputMaybe<Array<Promotion_Bool_Exp>>;
  _not?: InputMaybe<Promotion_Bool_Exp>;
  _or?: InputMaybe<Array<Promotion_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  endsAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  image?: InputMaybe<String_Comparison_Exp>;
  isActive?: InputMaybe<Boolean_Comparison_Exp>;
  isArchived?: InputMaybe<Boolean_Comparison_Exp>;
  location?: InputMaybe<Location_Bool_Exp>;
  locationId?: InputMaybe<Uuid_Comparison_Exp>;
  price?: InputMaybe<Numeric_Comparison_Exp>;
  promotionServices?: InputMaybe<PromotionServices_Bool_Exp>;
  promotionServices_aggregate?: InputMaybe<PromotionServices_Aggregate_Bool_Exp>;
  startsAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  workspace?: InputMaybe<Workspace_Bool_Exp>;
  workspaceId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "promotion" */
export enum Promotion_Constraint {
  /** unique or primary key constraint on columns "id" */
  PromotionPkey = 'promotion_pkey'
}

/** input type for inserting data into table "promotion" */
export type Promotion_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  endsAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  image?: InputMaybe<Scalars['String']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  isArchived?: InputMaybe<Scalars['Boolean']>;
  location?: InputMaybe<Location_Obj_Rel_Insert_Input>;
  locationId?: InputMaybe<Scalars['uuid']>;
  promotionServices?: InputMaybe<PromotionServices_Arr_Rel_Insert_Input>;
  startsAt?: InputMaybe<Scalars['timestamptz']>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspace?: InputMaybe<Workspace_Obj_Rel_Insert_Input>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Promotion_Max_Fields = {
  __typename?: 'promotion_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  endsAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  image?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['uuid']>;
  /** A computed field, executes function "calculate_promotion_price" */
  price?: Maybe<Scalars['numeric']>;
  startsAt?: Maybe<Scalars['timestamptz']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Promotion_Min_Fields = {
  __typename?: 'promotion_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  endsAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  image?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['uuid']>;
  /** A computed field, executes function "calculate_promotion_price" */
  price?: Maybe<Scalars['numeric']>;
  startsAt?: Maybe<Scalars['timestamptz']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "promotion" */
export type Promotion_Mutation_Response = {
  __typename?: 'promotion_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Promotion>;
};

/** input type for inserting object relation for remote table "promotion" */
export type Promotion_Obj_Rel_Insert_Input = {
  data: Promotion_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Promotion_On_Conflict>;
};

/** on_conflict condition type for table "promotion" */
export type Promotion_On_Conflict = {
  constraint: Promotion_Constraint;
  update_columns?: Array<Promotion_Update_Column>;
  where?: InputMaybe<Promotion_Bool_Exp>;
};

/** Ordering options when selecting data from "promotion". */
export type Promotion_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  endsAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  image?: InputMaybe<Order_By>;
  isActive?: InputMaybe<Order_By>;
  isArchived?: InputMaybe<Order_By>;
  location?: InputMaybe<Location_Order_By>;
  locationId?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  promotionServices_aggregate?: InputMaybe<PromotionServices_Aggregate_Order_By>;
  startsAt?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  workspace?: InputMaybe<Workspace_Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: promotion */
export type Promotion_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "promotion" */
export enum Promotion_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  EndsAt = 'endsAt',
  /** column name */
  Id = 'id',
  /** column name */
  Image = 'image',
  /** column name */
  IsActive = 'isActive',
  /** column name */
  IsArchived = 'isArchived',
  /** column name */
  LocationId = 'locationId',
  /** column name */
  StartsAt = 'startsAt',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WorkspaceId = 'workspaceId'
}

/** input type for updating data in table "promotion" */
export type Promotion_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  endsAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  image?: InputMaybe<Scalars['String']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  isArchived?: InputMaybe<Scalars['Boolean']>;
  locationId?: InputMaybe<Scalars['uuid']>;
  startsAt?: InputMaybe<Scalars['timestamptz']>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Promotion_Stddev_Fields = {
  __typename?: 'promotion_stddev_fields';
  /** A computed field, executes function "calculate_promotion_price" */
  price?: Maybe<Scalars['numeric']>;
};

/** aggregate stddev_pop on columns */
export type Promotion_Stddev_Pop_Fields = {
  __typename?: 'promotion_stddev_pop_fields';
  /** A computed field, executes function "calculate_promotion_price" */
  price?: Maybe<Scalars['numeric']>;
};

/** aggregate stddev_samp on columns */
export type Promotion_Stddev_Samp_Fields = {
  __typename?: 'promotion_stddev_samp_fields';
  /** A computed field, executes function "calculate_promotion_price" */
  price?: Maybe<Scalars['numeric']>;
};

/** Streaming cursor of the table "promotion" */
export type Promotion_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Promotion_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Promotion_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  endsAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  image?: InputMaybe<Scalars['String']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  isArchived?: InputMaybe<Scalars['Boolean']>;
  locationId?: InputMaybe<Scalars['uuid']>;
  startsAt?: InputMaybe<Scalars['timestamptz']>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Promotion_Sum_Fields = {
  __typename?: 'promotion_sum_fields';
  /** A computed field, executes function "calculate_promotion_price" */
  price?: Maybe<Scalars['numeric']>;
};

/** update columns of table "promotion" */
export enum Promotion_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  EndsAt = 'endsAt',
  /** column name */
  Id = 'id',
  /** column name */
  Image = 'image',
  /** column name */
  IsActive = 'isActive',
  /** column name */
  IsArchived = 'isArchived',
  /** column name */
  LocationId = 'locationId',
  /** column name */
  StartsAt = 'startsAt',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WorkspaceId = 'workspaceId'
}

export type Promotion_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Promotion_Set_Input>;
  /** filter the rows which have to be updated */
  where: Promotion_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Promotion_Var_Pop_Fields = {
  __typename?: 'promotion_var_pop_fields';
  /** A computed field, executes function "calculate_promotion_price" */
  price?: Maybe<Scalars['numeric']>;
};

/** aggregate var_samp on columns */
export type Promotion_Var_Samp_Fields = {
  __typename?: 'promotion_var_samp_fields';
  /** A computed field, executes function "calculate_promotion_price" */
  price?: Maybe<Scalars['numeric']>;
};

/** aggregate variance on columns */
export type Promotion_Variance_Fields = {
  __typename?: 'promotion_variance_fields';
  /** A computed field, executes function "calculate_promotion_price" */
  price?: Maybe<Scalars['numeric']>;
};

/** columns and relationships of "provider" */
export type Provider = {
  __typename?: 'provider';
  active?: Maybe<Scalars['Boolean']>;
  address?: Maybe<Scalars['String']>;
  addressId?: Maybe<Scalars['uuid']>;
  /** An array relationship */
  appointments: Array<Appointment>;
  /** An aggregate relationship */
  appointments_aggregate: Appointment_Aggregate;
  canBeBookedOnline?: Maybe<Scalars['Boolean']>;
  canPerformGFE?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['timestamptz'];
  dea?: Maybe<Scalars['String']>;
  degree?: Maybe<DegreeDesignation_Enum>;
  /** An object relationship */
  degreeDesignation?: Maybe<DegreeDesignation>;
  dob?: Maybe<Scalars['date']>;
  email: Scalars['String'];
  /** An array relationship */
  externalProviders: Array<ExternalProvider>;
  /** An aggregate relationship */
  externalProviders_aggregate: ExternalProvider_Aggregate;
  /** An array relationship */
  externalReviews: Array<ExternalReview>;
  /** An aggregate relationship */
  externalReviews_aggregate: ExternalReview_Aggregate;
  firstName: Scalars['String'];
  /** An object relationship */
  fullAddress?: Maybe<Address>;
  hourlyRate?: Maybe<Scalars['float8']>;
  id: Scalars['uuid'];
  lastName: Scalars['String'];
  /** An array relationship */
  locationProviders: Array<LocationProvider>;
  /** An aggregate relationship */
  locationProviders_aggregate: LocationProvider_Aggregate;
  npi?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  profilePicture?: Maybe<Scalars['String']>;
  /** An array relationship */
  providerSchedules: Array<ProviderSchedule>;
  /** An aggregate relationship */
  providerSchedules_aggregate: ProviderSchedule_Aggregate;
  scheduleBuffer: Scalars['Int'];
  /** An array relationship */
  serviceProviders: Array<ServiceProvider>;
  /** An aggregate relationship */
  serviceProviders_aggregate: ServiceProvider_Aggregate;
  stateLicense?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt: Scalars['timestamptz'];
  upin?: Maybe<Scalars['String']>;
  /** An object relationship */
  user?: Maybe<User>;
  userId?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  workspace: Workspace;
  workspaceId: Scalars['uuid'];
};


/** columns and relationships of "provider" */
export type ProviderAppointmentsArgs = {
  distinct_on?: InputMaybe<Array<Appointment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Appointment_Order_By>>;
  where?: InputMaybe<Appointment_Bool_Exp>;
};


/** columns and relationships of "provider" */
export type ProviderAppointments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Appointment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Appointment_Order_By>>;
  where?: InputMaybe<Appointment_Bool_Exp>;
};


/** columns and relationships of "provider" */
export type ProviderExternalProvidersArgs = {
  distinct_on?: InputMaybe<Array<ExternalProvider_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ExternalProvider_Order_By>>;
  where?: InputMaybe<ExternalProvider_Bool_Exp>;
};


/** columns and relationships of "provider" */
export type ProviderExternalProviders_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ExternalProvider_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ExternalProvider_Order_By>>;
  where?: InputMaybe<ExternalProvider_Bool_Exp>;
};


/** columns and relationships of "provider" */
export type ProviderExternalReviewsArgs = {
  distinct_on?: InputMaybe<Array<ExternalReview_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ExternalReview_Order_By>>;
  where?: InputMaybe<ExternalReview_Bool_Exp>;
};


/** columns and relationships of "provider" */
export type ProviderExternalReviews_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ExternalReview_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ExternalReview_Order_By>>;
  where?: InputMaybe<ExternalReview_Bool_Exp>;
};


/** columns and relationships of "provider" */
export type ProviderLocationProvidersArgs = {
  distinct_on?: InputMaybe<Array<LocationProvider_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<LocationProvider_Order_By>>;
  where?: InputMaybe<LocationProvider_Bool_Exp>;
};


/** columns and relationships of "provider" */
export type ProviderLocationProviders_AggregateArgs = {
  distinct_on?: InputMaybe<Array<LocationProvider_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<LocationProvider_Order_By>>;
  where?: InputMaybe<LocationProvider_Bool_Exp>;
};


/** columns and relationships of "provider" */
export type ProviderProviderSchedulesArgs = {
  distinct_on?: InputMaybe<Array<ProviderSchedule_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ProviderSchedule_Order_By>>;
  where?: InputMaybe<ProviderSchedule_Bool_Exp>;
};


/** columns and relationships of "provider" */
export type ProviderProviderSchedules_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ProviderSchedule_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ProviderSchedule_Order_By>>;
  where?: InputMaybe<ProviderSchedule_Bool_Exp>;
};


/** columns and relationships of "provider" */
export type ProviderServiceProvidersArgs = {
  distinct_on?: InputMaybe<Array<ServiceProvider_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ServiceProvider_Order_By>>;
  where?: InputMaybe<ServiceProvider_Bool_Exp>;
};


/** columns and relationships of "provider" */
export type ProviderServiceProviders_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ServiceProvider_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ServiceProvider_Order_By>>;
  where?: InputMaybe<ServiceProvider_Bool_Exp>;
};

/** columns and relationships of "providerSchedule" */
export type ProviderSchedule = {
  __typename?: 'providerSchedule';
  createdAt: Scalars['timestamptz'];
  default: Scalars['Boolean'];
  effectiveFrom: Scalars['date'];
  effectiveUntil?: Maybe<Scalars['date']>;
  id: Scalars['uuid'];
  /** An object relationship */
  location: Location;
  locationId: Scalars['uuid'];
  /** An object relationship */
  provider: Provider;
  providerId: Scalars['uuid'];
  schedule: Scalars['jsonb'];
  updatedAt: Scalars['timestamptz'];
};


/** columns and relationships of "providerSchedule" */
export type ProviderScheduleScheduleArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "providerSchedule" */
export type ProviderSchedule_Aggregate = {
  __typename?: 'providerSchedule_aggregate';
  aggregate?: Maybe<ProviderSchedule_Aggregate_Fields>;
  nodes: Array<ProviderSchedule>;
};

export type ProviderSchedule_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<ProviderSchedule_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<ProviderSchedule_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<ProviderSchedule_Aggregate_Bool_Exp_Count>;
};

export type ProviderSchedule_Aggregate_Bool_Exp_Bool_And = {
  arguments: ProviderSchedule_Select_Column_ProviderSchedule_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ProviderSchedule_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type ProviderSchedule_Aggregate_Bool_Exp_Bool_Or = {
  arguments: ProviderSchedule_Select_Column_ProviderSchedule_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ProviderSchedule_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type ProviderSchedule_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<ProviderSchedule_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ProviderSchedule_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "providerSchedule" */
export type ProviderSchedule_Aggregate_Fields = {
  __typename?: 'providerSchedule_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ProviderSchedule_Max_Fields>;
  min?: Maybe<ProviderSchedule_Min_Fields>;
};


/** aggregate fields of "providerSchedule" */
export type ProviderSchedule_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<ProviderSchedule_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "providerSchedule" */
export type ProviderSchedule_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<ProviderSchedule_Max_Order_By>;
  min?: InputMaybe<ProviderSchedule_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type ProviderSchedule_Append_Input = {
  schedule?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "providerSchedule" */
export type ProviderSchedule_Arr_Rel_Insert_Input = {
  data: Array<ProviderSchedule_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<ProviderSchedule_On_Conflict>;
};

/** Boolean expression to filter rows from the table "providerSchedule". All fields are combined with a logical 'AND'. */
export type ProviderSchedule_Bool_Exp = {
  _and?: InputMaybe<Array<ProviderSchedule_Bool_Exp>>;
  _not?: InputMaybe<ProviderSchedule_Bool_Exp>;
  _or?: InputMaybe<Array<ProviderSchedule_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  default?: InputMaybe<Boolean_Comparison_Exp>;
  effectiveFrom?: InputMaybe<Date_Comparison_Exp>;
  effectiveUntil?: InputMaybe<Date_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  location?: InputMaybe<Location_Bool_Exp>;
  locationId?: InputMaybe<Uuid_Comparison_Exp>;
  provider?: InputMaybe<Provider_Bool_Exp>;
  providerId?: InputMaybe<Uuid_Comparison_Exp>;
  schedule?: InputMaybe<Jsonb_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "providerSchedule" */
export enum ProviderSchedule_Constraint {
  /** unique or primary key constraint on columns "id" */
  ProviderSchedulePkey = 'providerSchedule_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type ProviderSchedule_Delete_At_Path_Input = {
  schedule?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type ProviderSchedule_Delete_Elem_Input = {
  schedule?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type ProviderSchedule_Delete_Key_Input = {
  schedule?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "providerSchedule" */
export type ProviderSchedule_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  default?: InputMaybe<Scalars['Boolean']>;
  effectiveFrom?: InputMaybe<Scalars['date']>;
  effectiveUntil?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['uuid']>;
  location?: InputMaybe<Location_Obj_Rel_Insert_Input>;
  locationId?: InputMaybe<Scalars['uuid']>;
  provider?: InputMaybe<Provider_Obj_Rel_Insert_Input>;
  providerId?: InputMaybe<Scalars['uuid']>;
  schedule?: InputMaybe<Scalars['jsonb']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type ProviderSchedule_Max_Fields = {
  __typename?: 'providerSchedule_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  effectiveFrom?: Maybe<Scalars['date']>;
  effectiveUntil?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  locationId?: Maybe<Scalars['uuid']>;
  providerId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "providerSchedule" */
export type ProviderSchedule_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  effectiveFrom?: InputMaybe<Order_By>;
  effectiveUntil?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  locationId?: InputMaybe<Order_By>;
  providerId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type ProviderSchedule_Min_Fields = {
  __typename?: 'providerSchedule_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  effectiveFrom?: Maybe<Scalars['date']>;
  effectiveUntil?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  locationId?: Maybe<Scalars['uuid']>;
  providerId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "providerSchedule" */
export type ProviderSchedule_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  effectiveFrom?: InputMaybe<Order_By>;
  effectiveUntil?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  locationId?: InputMaybe<Order_By>;
  providerId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "providerSchedule" */
export type ProviderSchedule_Mutation_Response = {
  __typename?: 'providerSchedule_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ProviderSchedule>;
};

/** on_conflict condition type for table "providerSchedule" */
export type ProviderSchedule_On_Conflict = {
  constraint: ProviderSchedule_Constraint;
  update_columns?: Array<ProviderSchedule_Update_Column>;
  where?: InputMaybe<ProviderSchedule_Bool_Exp>;
};

/** Ordering options when selecting data from "providerSchedule". */
export type ProviderSchedule_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  default?: InputMaybe<Order_By>;
  effectiveFrom?: InputMaybe<Order_By>;
  effectiveUntil?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  location?: InputMaybe<Location_Order_By>;
  locationId?: InputMaybe<Order_By>;
  provider?: InputMaybe<Provider_Order_By>;
  providerId?: InputMaybe<Order_By>;
  schedule?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: providerSchedule */
export type ProviderSchedule_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type ProviderSchedule_Prepend_Input = {
  schedule?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "providerSchedule" */
export enum ProviderSchedule_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Default = 'default',
  /** column name */
  EffectiveFrom = 'effectiveFrom',
  /** column name */
  EffectiveUntil = 'effectiveUntil',
  /** column name */
  Id = 'id',
  /** column name */
  LocationId = 'locationId',
  /** column name */
  ProviderId = 'providerId',
  /** column name */
  Schedule = 'schedule',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** select "providerSchedule_aggregate_bool_exp_bool_and_arguments_columns" columns of table "providerSchedule" */
export enum ProviderSchedule_Select_Column_ProviderSchedule_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Default = 'default'
}

/** select "providerSchedule_aggregate_bool_exp_bool_or_arguments_columns" columns of table "providerSchedule" */
export enum ProviderSchedule_Select_Column_ProviderSchedule_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Default = 'default'
}

/** input type for updating data in table "providerSchedule" */
export type ProviderSchedule_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  default?: InputMaybe<Scalars['Boolean']>;
  effectiveFrom?: InputMaybe<Scalars['date']>;
  effectiveUntil?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['uuid']>;
  locationId?: InputMaybe<Scalars['uuid']>;
  providerId?: InputMaybe<Scalars['uuid']>;
  schedule?: InputMaybe<Scalars['jsonb']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "providerSchedule" */
export type ProviderSchedule_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ProviderSchedule_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ProviderSchedule_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  default?: InputMaybe<Scalars['Boolean']>;
  effectiveFrom?: InputMaybe<Scalars['date']>;
  effectiveUntil?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['uuid']>;
  locationId?: InputMaybe<Scalars['uuid']>;
  providerId?: InputMaybe<Scalars['uuid']>;
  schedule?: InputMaybe<Scalars['jsonb']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "providerSchedule" */
export enum ProviderSchedule_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Default = 'default',
  /** column name */
  EffectiveFrom = 'effectiveFrom',
  /** column name */
  EffectiveUntil = 'effectiveUntil',
  /** column name */
  Id = 'id',
  /** column name */
  LocationId = 'locationId',
  /** column name */
  ProviderId = 'providerId',
  /** column name */
  Schedule = 'schedule',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type ProviderSchedule_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<ProviderSchedule_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<ProviderSchedule_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<ProviderSchedule_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<ProviderSchedule_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<ProviderSchedule_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ProviderSchedule_Set_Input>;
  /** filter the rows which have to be updated */
  where: ProviderSchedule_Bool_Exp;
};

/** aggregated selection of "provider" */
export type Provider_Aggregate = {
  __typename?: 'provider_aggregate';
  aggregate?: Maybe<Provider_Aggregate_Fields>;
  nodes: Array<Provider>;
};

export type Provider_Aggregate_Bool_Exp = {
  avg?: InputMaybe<Provider_Aggregate_Bool_Exp_Avg>;
  bool_and?: InputMaybe<Provider_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Provider_Aggregate_Bool_Exp_Bool_Or>;
  corr?: InputMaybe<Provider_Aggregate_Bool_Exp_Corr>;
  count?: InputMaybe<Provider_Aggregate_Bool_Exp_Count>;
  covar_samp?: InputMaybe<Provider_Aggregate_Bool_Exp_Covar_Samp>;
  max?: InputMaybe<Provider_Aggregate_Bool_Exp_Max>;
  min?: InputMaybe<Provider_Aggregate_Bool_Exp_Min>;
  stddev_samp?: InputMaybe<Provider_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: InputMaybe<Provider_Aggregate_Bool_Exp_Sum>;
  var_samp?: InputMaybe<Provider_Aggregate_Bool_Exp_Var_Samp>;
};

export type Provider_Aggregate_Bool_Exp_Avg = {
  arguments: Provider_Select_Column_Provider_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Provider_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Provider_Aggregate_Bool_Exp_Bool_And = {
  arguments: Provider_Select_Column_Provider_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Provider_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Provider_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Provider_Select_Column_Provider_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Provider_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Provider_Aggregate_Bool_Exp_Corr = {
  arguments: Provider_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Provider_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Provider_Aggregate_Bool_Exp_Corr_Arguments = {
  X: Provider_Select_Column_Provider_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: Provider_Select_Column_Provider_Aggregate_Bool_Exp_Corr_Arguments_Columns;
};

export type Provider_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Provider_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Provider_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type Provider_Aggregate_Bool_Exp_Covar_Samp = {
  arguments: Provider_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Provider_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Provider_Aggregate_Bool_Exp_Covar_Samp_Arguments = {
  X: Provider_Select_Column_Provider_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  Y: Provider_Select_Column_Provider_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
};

export type Provider_Aggregate_Bool_Exp_Max = {
  arguments: Provider_Select_Column_Provider_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Provider_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Provider_Aggregate_Bool_Exp_Min = {
  arguments: Provider_Select_Column_Provider_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Provider_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Provider_Aggregate_Bool_Exp_Stddev_Samp = {
  arguments: Provider_Select_Column_Provider_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Provider_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Provider_Aggregate_Bool_Exp_Sum = {
  arguments: Provider_Select_Column_Provider_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Provider_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Provider_Aggregate_Bool_Exp_Var_Samp = {
  arguments: Provider_Select_Column_Provider_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Provider_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

/** aggregate fields of "provider" */
export type Provider_Aggregate_Fields = {
  __typename?: 'provider_aggregate_fields';
  avg?: Maybe<Provider_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Provider_Max_Fields>;
  min?: Maybe<Provider_Min_Fields>;
  stddev?: Maybe<Provider_Stddev_Fields>;
  stddev_pop?: Maybe<Provider_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Provider_Stddev_Samp_Fields>;
  sum?: Maybe<Provider_Sum_Fields>;
  var_pop?: Maybe<Provider_Var_Pop_Fields>;
  var_samp?: Maybe<Provider_Var_Samp_Fields>;
  variance?: Maybe<Provider_Variance_Fields>;
};


/** aggregate fields of "provider" */
export type Provider_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Provider_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "provider" */
export type Provider_Aggregate_Order_By = {
  avg?: InputMaybe<Provider_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Provider_Max_Order_By>;
  min?: InputMaybe<Provider_Min_Order_By>;
  stddev?: InputMaybe<Provider_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Provider_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Provider_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Provider_Sum_Order_By>;
  var_pop?: InputMaybe<Provider_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Provider_Var_Samp_Order_By>;
  variance?: InputMaybe<Provider_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "provider" */
export type Provider_Arr_Rel_Insert_Input = {
  data: Array<Provider_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Provider_On_Conflict>;
};

/** aggregate avg on columns */
export type Provider_Avg_Fields = {
  __typename?: 'provider_avg_fields';
  hourlyRate?: Maybe<Scalars['Float']>;
  scheduleBuffer?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "provider" */
export type Provider_Avg_Order_By = {
  hourlyRate?: InputMaybe<Order_By>;
  scheduleBuffer?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "provider". All fields are combined with a logical 'AND'. */
export type Provider_Bool_Exp = {
  _and?: InputMaybe<Array<Provider_Bool_Exp>>;
  _not?: InputMaybe<Provider_Bool_Exp>;
  _or?: InputMaybe<Array<Provider_Bool_Exp>>;
  active?: InputMaybe<Boolean_Comparison_Exp>;
  address?: InputMaybe<String_Comparison_Exp>;
  addressId?: InputMaybe<Uuid_Comparison_Exp>;
  appointments?: InputMaybe<Appointment_Bool_Exp>;
  appointments_aggregate?: InputMaybe<Appointment_Aggregate_Bool_Exp>;
  canBeBookedOnline?: InputMaybe<Boolean_Comparison_Exp>;
  canPerformGFE?: InputMaybe<Boolean_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  dea?: InputMaybe<String_Comparison_Exp>;
  degree?: InputMaybe<DegreeDesignation_Enum_Comparison_Exp>;
  degreeDesignation?: InputMaybe<DegreeDesignation_Bool_Exp>;
  dob?: InputMaybe<Date_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  externalProviders?: InputMaybe<ExternalProvider_Bool_Exp>;
  externalProviders_aggregate?: InputMaybe<ExternalProvider_Aggregate_Bool_Exp>;
  externalReviews?: InputMaybe<ExternalReview_Bool_Exp>;
  externalReviews_aggregate?: InputMaybe<ExternalReview_Aggregate_Bool_Exp>;
  firstName?: InputMaybe<String_Comparison_Exp>;
  fullAddress?: InputMaybe<Address_Bool_Exp>;
  hourlyRate?: InputMaybe<Float8_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  lastName?: InputMaybe<String_Comparison_Exp>;
  locationProviders?: InputMaybe<LocationProvider_Bool_Exp>;
  locationProviders_aggregate?: InputMaybe<LocationProvider_Aggregate_Bool_Exp>;
  npi?: InputMaybe<String_Comparison_Exp>;
  phoneNumber?: InputMaybe<String_Comparison_Exp>;
  profilePicture?: InputMaybe<String_Comparison_Exp>;
  providerSchedules?: InputMaybe<ProviderSchedule_Bool_Exp>;
  providerSchedules_aggregate?: InputMaybe<ProviderSchedule_Aggregate_Bool_Exp>;
  scheduleBuffer?: InputMaybe<Int_Comparison_Exp>;
  serviceProviders?: InputMaybe<ServiceProvider_Bool_Exp>;
  serviceProviders_aggregate?: InputMaybe<ServiceProvider_Aggregate_Bool_Exp>;
  stateLicense?: InputMaybe<String_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  upin?: InputMaybe<String_Comparison_Exp>;
  user?: InputMaybe<User_Bool_Exp>;
  userId?: InputMaybe<Uuid_Comparison_Exp>;
  workspace?: InputMaybe<Workspace_Bool_Exp>;
  workspaceId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "provider" */
export enum Provider_Constraint {
  /** unique or primary key constraint on columns "email" */
  ProviderEmailKey = 'provider_email_key',
  /** unique or primary key constraint on columns "id" */
  ProviderPkey = 'provider_pkey'
}

/** input type for incrementing numeric columns in table "provider" */
export type Provider_Inc_Input = {
  hourlyRate?: InputMaybe<Scalars['float8']>;
  scheduleBuffer?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "provider" */
export type Provider_Insert_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  address?: InputMaybe<Scalars['String']>;
  addressId?: InputMaybe<Scalars['uuid']>;
  appointments?: InputMaybe<Appointment_Arr_Rel_Insert_Input>;
  canBeBookedOnline?: InputMaybe<Scalars['Boolean']>;
  canPerformGFE?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  dea?: InputMaybe<Scalars['String']>;
  degree?: InputMaybe<DegreeDesignation_Enum>;
  degreeDesignation?: InputMaybe<DegreeDesignation_Obj_Rel_Insert_Input>;
  dob?: InputMaybe<Scalars['date']>;
  email?: InputMaybe<Scalars['String']>;
  externalProviders?: InputMaybe<ExternalProvider_Arr_Rel_Insert_Input>;
  externalReviews?: InputMaybe<ExternalReview_Arr_Rel_Insert_Input>;
  firstName?: InputMaybe<Scalars['String']>;
  fullAddress?: InputMaybe<Address_Obj_Rel_Insert_Input>;
  hourlyRate?: InputMaybe<Scalars['float8']>;
  id?: InputMaybe<Scalars['uuid']>;
  lastName?: InputMaybe<Scalars['String']>;
  locationProviders?: InputMaybe<LocationProvider_Arr_Rel_Insert_Input>;
  npi?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  profilePicture?: InputMaybe<Scalars['String']>;
  providerSchedules?: InputMaybe<ProviderSchedule_Arr_Rel_Insert_Input>;
  scheduleBuffer?: InputMaybe<Scalars['Int']>;
  serviceProviders?: InputMaybe<ServiceProvider_Arr_Rel_Insert_Input>;
  stateLicense?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  upin?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<User_Obj_Rel_Insert_Input>;
  userId?: InputMaybe<Scalars['uuid']>;
  workspace?: InputMaybe<Workspace_Obj_Rel_Insert_Input>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Provider_Max_Fields = {
  __typename?: 'provider_max_fields';
  address?: Maybe<Scalars['String']>;
  addressId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  dea?: Maybe<Scalars['String']>;
  dob?: Maybe<Scalars['date']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  hourlyRate?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['uuid']>;
  lastName?: Maybe<Scalars['String']>;
  npi?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  profilePicture?: Maybe<Scalars['String']>;
  scheduleBuffer?: Maybe<Scalars['Int']>;
  stateLicense?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  upin?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['uuid']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "provider" */
export type Provider_Max_Order_By = {
  address?: InputMaybe<Order_By>;
  addressId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  dea?: InputMaybe<Order_By>;
  dob?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  firstName?: InputMaybe<Order_By>;
  hourlyRate?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lastName?: InputMaybe<Order_By>;
  npi?: InputMaybe<Order_By>;
  phoneNumber?: InputMaybe<Order_By>;
  profilePicture?: InputMaybe<Order_By>;
  scheduleBuffer?: InputMaybe<Order_By>;
  stateLicense?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  upin?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Provider_Min_Fields = {
  __typename?: 'provider_min_fields';
  address?: Maybe<Scalars['String']>;
  addressId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  dea?: Maybe<Scalars['String']>;
  dob?: Maybe<Scalars['date']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  hourlyRate?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['uuid']>;
  lastName?: Maybe<Scalars['String']>;
  npi?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  profilePicture?: Maybe<Scalars['String']>;
  scheduleBuffer?: Maybe<Scalars['Int']>;
  stateLicense?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  upin?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['uuid']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "provider" */
export type Provider_Min_Order_By = {
  address?: InputMaybe<Order_By>;
  addressId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  dea?: InputMaybe<Order_By>;
  dob?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  firstName?: InputMaybe<Order_By>;
  hourlyRate?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lastName?: InputMaybe<Order_By>;
  npi?: InputMaybe<Order_By>;
  phoneNumber?: InputMaybe<Order_By>;
  profilePicture?: InputMaybe<Order_By>;
  scheduleBuffer?: InputMaybe<Order_By>;
  stateLicense?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  upin?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "provider" */
export type Provider_Mutation_Response = {
  __typename?: 'provider_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Provider>;
};

/** input type for inserting object relation for remote table "provider" */
export type Provider_Obj_Rel_Insert_Input = {
  data: Provider_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Provider_On_Conflict>;
};

/** on_conflict condition type for table "provider" */
export type Provider_On_Conflict = {
  constraint: Provider_Constraint;
  update_columns?: Array<Provider_Update_Column>;
  where?: InputMaybe<Provider_Bool_Exp>;
};

/** Ordering options when selecting data from "provider". */
export type Provider_Order_By = {
  active?: InputMaybe<Order_By>;
  address?: InputMaybe<Order_By>;
  addressId?: InputMaybe<Order_By>;
  appointments_aggregate?: InputMaybe<Appointment_Aggregate_Order_By>;
  canBeBookedOnline?: InputMaybe<Order_By>;
  canPerformGFE?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  dea?: InputMaybe<Order_By>;
  degree?: InputMaybe<Order_By>;
  degreeDesignation?: InputMaybe<DegreeDesignation_Order_By>;
  dob?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  externalProviders_aggregate?: InputMaybe<ExternalProvider_Aggregate_Order_By>;
  externalReviews_aggregate?: InputMaybe<ExternalReview_Aggregate_Order_By>;
  firstName?: InputMaybe<Order_By>;
  fullAddress?: InputMaybe<Address_Order_By>;
  hourlyRate?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lastName?: InputMaybe<Order_By>;
  locationProviders_aggregate?: InputMaybe<LocationProvider_Aggregate_Order_By>;
  npi?: InputMaybe<Order_By>;
  phoneNumber?: InputMaybe<Order_By>;
  profilePicture?: InputMaybe<Order_By>;
  providerSchedules_aggregate?: InputMaybe<ProviderSchedule_Aggregate_Order_By>;
  scheduleBuffer?: InputMaybe<Order_By>;
  serviceProviders_aggregate?: InputMaybe<ServiceProvider_Aggregate_Order_By>;
  stateLicense?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  upin?: InputMaybe<Order_By>;
  user?: InputMaybe<User_Order_By>;
  userId?: InputMaybe<Order_By>;
  workspace?: InputMaybe<Workspace_Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: provider */
export type Provider_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "provider" */
export enum Provider_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  Address = 'address',
  /** column name */
  AddressId = 'addressId',
  /** column name */
  CanBeBookedOnline = 'canBeBookedOnline',
  /** column name */
  CanPerformGfe = 'canPerformGFE',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Dea = 'dea',
  /** column name */
  Degree = 'degree',
  /** column name */
  Dob = 'dob',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'firstName',
  /** column name */
  HourlyRate = 'hourlyRate',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'lastName',
  /** column name */
  Npi = 'npi',
  /** column name */
  PhoneNumber = 'phoneNumber',
  /** column name */
  ProfilePicture = 'profilePicture',
  /** column name */
  ScheduleBuffer = 'scheduleBuffer',
  /** column name */
  StateLicense = 'stateLicense',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Upin = 'upin',
  /** column name */
  UserId = 'userId',
  /** column name */
  WorkspaceId = 'workspaceId'
}

/** select "provider_aggregate_bool_exp_avg_arguments_columns" columns of table "provider" */
export enum Provider_Select_Column_Provider_Aggregate_Bool_Exp_Avg_Arguments_Columns {
  /** column name */
  HourlyRate = 'hourlyRate'
}

/** select "provider_aggregate_bool_exp_bool_and_arguments_columns" columns of table "provider" */
export enum Provider_Select_Column_Provider_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Active = 'active',
  /** column name */
  CanBeBookedOnline = 'canBeBookedOnline',
  /** column name */
  CanPerformGfe = 'canPerformGFE'
}

/** select "provider_aggregate_bool_exp_bool_or_arguments_columns" columns of table "provider" */
export enum Provider_Select_Column_Provider_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Active = 'active',
  /** column name */
  CanBeBookedOnline = 'canBeBookedOnline',
  /** column name */
  CanPerformGfe = 'canPerformGFE'
}

/** select "provider_aggregate_bool_exp_corr_arguments_columns" columns of table "provider" */
export enum Provider_Select_Column_Provider_Aggregate_Bool_Exp_Corr_Arguments_Columns {
  /** column name */
  HourlyRate = 'hourlyRate'
}

/** select "provider_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "provider" */
export enum Provider_Select_Column_Provider_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns {
  /** column name */
  HourlyRate = 'hourlyRate'
}

/** select "provider_aggregate_bool_exp_max_arguments_columns" columns of table "provider" */
export enum Provider_Select_Column_Provider_Aggregate_Bool_Exp_Max_Arguments_Columns {
  /** column name */
  HourlyRate = 'hourlyRate'
}

/** select "provider_aggregate_bool_exp_min_arguments_columns" columns of table "provider" */
export enum Provider_Select_Column_Provider_Aggregate_Bool_Exp_Min_Arguments_Columns {
  /** column name */
  HourlyRate = 'hourlyRate'
}

/** select "provider_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "provider" */
export enum Provider_Select_Column_Provider_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns {
  /** column name */
  HourlyRate = 'hourlyRate'
}

/** select "provider_aggregate_bool_exp_sum_arguments_columns" columns of table "provider" */
export enum Provider_Select_Column_Provider_Aggregate_Bool_Exp_Sum_Arguments_Columns {
  /** column name */
  HourlyRate = 'hourlyRate'
}

/** select "provider_aggregate_bool_exp_var_samp_arguments_columns" columns of table "provider" */
export enum Provider_Select_Column_Provider_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns {
  /** column name */
  HourlyRate = 'hourlyRate'
}

/** input type for updating data in table "provider" */
export type Provider_Set_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  address?: InputMaybe<Scalars['String']>;
  addressId?: InputMaybe<Scalars['uuid']>;
  canBeBookedOnline?: InputMaybe<Scalars['Boolean']>;
  canPerformGFE?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  dea?: InputMaybe<Scalars['String']>;
  degree?: InputMaybe<DegreeDesignation_Enum>;
  dob?: InputMaybe<Scalars['date']>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  hourlyRate?: InputMaybe<Scalars['float8']>;
  id?: InputMaybe<Scalars['uuid']>;
  lastName?: InputMaybe<Scalars['String']>;
  npi?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  profilePicture?: InputMaybe<Scalars['String']>;
  scheduleBuffer?: InputMaybe<Scalars['Int']>;
  stateLicense?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  upin?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['uuid']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Provider_Stddev_Fields = {
  __typename?: 'provider_stddev_fields';
  hourlyRate?: Maybe<Scalars['Float']>;
  scheduleBuffer?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "provider" */
export type Provider_Stddev_Order_By = {
  hourlyRate?: InputMaybe<Order_By>;
  scheduleBuffer?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Provider_Stddev_Pop_Fields = {
  __typename?: 'provider_stddev_pop_fields';
  hourlyRate?: Maybe<Scalars['Float']>;
  scheduleBuffer?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "provider" */
export type Provider_Stddev_Pop_Order_By = {
  hourlyRate?: InputMaybe<Order_By>;
  scheduleBuffer?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Provider_Stddev_Samp_Fields = {
  __typename?: 'provider_stddev_samp_fields';
  hourlyRate?: Maybe<Scalars['Float']>;
  scheduleBuffer?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "provider" */
export type Provider_Stddev_Samp_Order_By = {
  hourlyRate?: InputMaybe<Order_By>;
  scheduleBuffer?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "provider" */
export type Provider_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Provider_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Provider_Stream_Cursor_Value_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  address?: InputMaybe<Scalars['String']>;
  addressId?: InputMaybe<Scalars['uuid']>;
  canBeBookedOnline?: InputMaybe<Scalars['Boolean']>;
  canPerformGFE?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  dea?: InputMaybe<Scalars['String']>;
  degree?: InputMaybe<DegreeDesignation_Enum>;
  dob?: InputMaybe<Scalars['date']>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  hourlyRate?: InputMaybe<Scalars['float8']>;
  id?: InputMaybe<Scalars['uuid']>;
  lastName?: InputMaybe<Scalars['String']>;
  npi?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  profilePicture?: InputMaybe<Scalars['String']>;
  scheduleBuffer?: InputMaybe<Scalars['Int']>;
  stateLicense?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  upin?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['uuid']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Provider_Sum_Fields = {
  __typename?: 'provider_sum_fields';
  hourlyRate?: Maybe<Scalars['float8']>;
  scheduleBuffer?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "provider" */
export type Provider_Sum_Order_By = {
  hourlyRate?: InputMaybe<Order_By>;
  scheduleBuffer?: InputMaybe<Order_By>;
};

/** update columns of table "provider" */
export enum Provider_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  Address = 'address',
  /** column name */
  AddressId = 'addressId',
  /** column name */
  CanBeBookedOnline = 'canBeBookedOnline',
  /** column name */
  CanPerformGfe = 'canPerformGFE',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Dea = 'dea',
  /** column name */
  Degree = 'degree',
  /** column name */
  Dob = 'dob',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'firstName',
  /** column name */
  HourlyRate = 'hourlyRate',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'lastName',
  /** column name */
  Npi = 'npi',
  /** column name */
  PhoneNumber = 'phoneNumber',
  /** column name */
  ProfilePicture = 'profilePicture',
  /** column name */
  ScheduleBuffer = 'scheduleBuffer',
  /** column name */
  StateLicense = 'stateLicense',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Upin = 'upin',
  /** column name */
  UserId = 'userId',
  /** column name */
  WorkspaceId = 'workspaceId'
}

export type Provider_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Provider_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Provider_Set_Input>;
  /** filter the rows which have to be updated */
  where: Provider_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Provider_Var_Pop_Fields = {
  __typename?: 'provider_var_pop_fields';
  hourlyRate?: Maybe<Scalars['Float']>;
  scheduleBuffer?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "provider" */
export type Provider_Var_Pop_Order_By = {
  hourlyRate?: InputMaybe<Order_By>;
  scheduleBuffer?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Provider_Var_Samp_Fields = {
  __typename?: 'provider_var_samp_fields';
  hourlyRate?: Maybe<Scalars['Float']>;
  scheduleBuffer?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "provider" */
export type Provider_Var_Samp_Order_By = {
  hourlyRate?: InputMaybe<Order_By>;
  scheduleBuffer?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Provider_Variance_Fields = {
  __typename?: 'provider_variance_fields';
  hourlyRate?: Maybe<Scalars['Float']>;
  scheduleBuffer?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "provider" */
export type Provider_Variance_Order_By = {
  hourlyRate?: InputMaybe<Order_By>;
  scheduleBuffer?: InputMaybe<Order_By>;
};

/** columns and relationships of "purchaseOrder" */
export type PurchaseOrder = {
  __typename?: 'purchaseOrder';
  archived: Scalars['Boolean'];
  /** An object relationship */
  consumableBrand: ConsumableBrand;
  consumableBrandId: Scalars['uuid'];
  /** An object relationship */
  consumableType: ConsumableType;
  consumableTypeId: Scalars['uuid'];
  /** An array relationship */
  consumables: Array<Consumable>;
  /** An aggregate relationship */
  consumables_aggregate: Consumable_Aggregate;
  cost: Scalars['Int'];
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  note?: Maybe<Scalars['String']>;
  orderNumber: Scalars['String'];
  status: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  vendorOrderNumber?: Maybe<Scalars['String']>;
  workspaceId: Scalars['uuid'];
};


/** columns and relationships of "purchaseOrder" */
export type PurchaseOrderConsumablesArgs = {
  distinct_on?: InputMaybe<Array<Consumable_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Consumable_Order_By>>;
  where?: InputMaybe<Consumable_Bool_Exp>;
};


/** columns and relationships of "purchaseOrder" */
export type PurchaseOrderConsumables_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Consumable_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Consumable_Order_By>>;
  where?: InputMaybe<Consumable_Bool_Exp>;
};

/** aggregated selection of "purchaseOrder" */
export type PurchaseOrder_Aggregate = {
  __typename?: 'purchaseOrder_aggregate';
  aggregate?: Maybe<PurchaseOrder_Aggregate_Fields>;
  nodes: Array<PurchaseOrder>;
};

export type PurchaseOrder_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<PurchaseOrder_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<PurchaseOrder_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<PurchaseOrder_Aggregate_Bool_Exp_Count>;
};

export type PurchaseOrder_Aggregate_Bool_Exp_Bool_And = {
  arguments: PurchaseOrder_Select_Column_PurchaseOrder_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<PurchaseOrder_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type PurchaseOrder_Aggregate_Bool_Exp_Bool_Or = {
  arguments: PurchaseOrder_Select_Column_PurchaseOrder_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<PurchaseOrder_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type PurchaseOrder_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<PurchaseOrder_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<PurchaseOrder_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "purchaseOrder" */
export type PurchaseOrder_Aggregate_Fields = {
  __typename?: 'purchaseOrder_aggregate_fields';
  avg?: Maybe<PurchaseOrder_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<PurchaseOrder_Max_Fields>;
  min?: Maybe<PurchaseOrder_Min_Fields>;
  stddev?: Maybe<PurchaseOrder_Stddev_Fields>;
  stddev_pop?: Maybe<PurchaseOrder_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<PurchaseOrder_Stddev_Samp_Fields>;
  sum?: Maybe<PurchaseOrder_Sum_Fields>;
  var_pop?: Maybe<PurchaseOrder_Var_Pop_Fields>;
  var_samp?: Maybe<PurchaseOrder_Var_Samp_Fields>;
  variance?: Maybe<PurchaseOrder_Variance_Fields>;
};


/** aggregate fields of "purchaseOrder" */
export type PurchaseOrder_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<PurchaseOrder_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "purchaseOrder" */
export type PurchaseOrder_Aggregate_Order_By = {
  avg?: InputMaybe<PurchaseOrder_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<PurchaseOrder_Max_Order_By>;
  min?: InputMaybe<PurchaseOrder_Min_Order_By>;
  stddev?: InputMaybe<PurchaseOrder_Stddev_Order_By>;
  stddev_pop?: InputMaybe<PurchaseOrder_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<PurchaseOrder_Stddev_Samp_Order_By>;
  sum?: InputMaybe<PurchaseOrder_Sum_Order_By>;
  var_pop?: InputMaybe<PurchaseOrder_Var_Pop_Order_By>;
  var_samp?: InputMaybe<PurchaseOrder_Var_Samp_Order_By>;
  variance?: InputMaybe<PurchaseOrder_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "purchaseOrder" */
export type PurchaseOrder_Arr_Rel_Insert_Input = {
  data: Array<PurchaseOrder_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<PurchaseOrder_On_Conflict>;
};

/** aggregate avg on columns */
export type PurchaseOrder_Avg_Fields = {
  __typename?: 'purchaseOrder_avg_fields';
  cost?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "purchaseOrder" */
export type PurchaseOrder_Avg_Order_By = {
  cost?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "purchaseOrder". All fields are combined with a logical 'AND'. */
export type PurchaseOrder_Bool_Exp = {
  _and?: InputMaybe<Array<PurchaseOrder_Bool_Exp>>;
  _not?: InputMaybe<PurchaseOrder_Bool_Exp>;
  _or?: InputMaybe<Array<PurchaseOrder_Bool_Exp>>;
  archived?: InputMaybe<Boolean_Comparison_Exp>;
  consumableBrand?: InputMaybe<ConsumableBrand_Bool_Exp>;
  consumableBrandId?: InputMaybe<Uuid_Comparison_Exp>;
  consumableType?: InputMaybe<ConsumableType_Bool_Exp>;
  consumableTypeId?: InputMaybe<Uuid_Comparison_Exp>;
  consumables?: InputMaybe<Consumable_Bool_Exp>;
  consumables_aggregate?: InputMaybe<Consumable_Aggregate_Bool_Exp>;
  cost?: InputMaybe<Int_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  note?: InputMaybe<String_Comparison_Exp>;
  orderNumber?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  vendorOrderNumber?: InputMaybe<String_Comparison_Exp>;
  workspaceId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "purchaseOrder" */
export enum PurchaseOrder_Constraint {
  /** unique or primary key constraint on columns "id" */
  PurchaseOrderPkey = 'purchaseOrder_pkey'
}

/** input type for incrementing numeric columns in table "purchaseOrder" */
export type PurchaseOrder_Inc_Input = {
  cost?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "purchaseOrder" */
export type PurchaseOrder_Insert_Input = {
  archived?: InputMaybe<Scalars['Boolean']>;
  consumableBrand?: InputMaybe<ConsumableBrand_Obj_Rel_Insert_Input>;
  consumableBrandId?: InputMaybe<Scalars['uuid']>;
  consumableType?: InputMaybe<ConsumableType_Obj_Rel_Insert_Input>;
  consumableTypeId?: InputMaybe<Scalars['uuid']>;
  consumables?: InputMaybe<Consumable_Arr_Rel_Insert_Input>;
  cost?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  note?: InputMaybe<Scalars['String']>;
  orderNumber?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  vendorOrderNumber?: InputMaybe<Scalars['String']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type PurchaseOrder_Max_Fields = {
  __typename?: 'purchaseOrder_max_fields';
  consumableBrandId?: Maybe<Scalars['uuid']>;
  consumableTypeId?: Maybe<Scalars['uuid']>;
  cost?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  note?: Maybe<Scalars['String']>;
  orderNumber?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  vendorOrderNumber?: Maybe<Scalars['String']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "purchaseOrder" */
export type PurchaseOrder_Max_Order_By = {
  consumableBrandId?: InputMaybe<Order_By>;
  consumableTypeId?: InputMaybe<Order_By>;
  cost?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  note?: InputMaybe<Order_By>;
  orderNumber?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  vendorOrderNumber?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type PurchaseOrder_Min_Fields = {
  __typename?: 'purchaseOrder_min_fields';
  consumableBrandId?: Maybe<Scalars['uuid']>;
  consumableTypeId?: Maybe<Scalars['uuid']>;
  cost?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  note?: Maybe<Scalars['String']>;
  orderNumber?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  vendorOrderNumber?: Maybe<Scalars['String']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "purchaseOrder" */
export type PurchaseOrder_Min_Order_By = {
  consumableBrandId?: InputMaybe<Order_By>;
  consumableTypeId?: InputMaybe<Order_By>;
  cost?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  note?: InputMaybe<Order_By>;
  orderNumber?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  vendorOrderNumber?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "purchaseOrder" */
export type PurchaseOrder_Mutation_Response = {
  __typename?: 'purchaseOrder_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<PurchaseOrder>;
};

/** input type for inserting object relation for remote table "purchaseOrder" */
export type PurchaseOrder_Obj_Rel_Insert_Input = {
  data: PurchaseOrder_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<PurchaseOrder_On_Conflict>;
};

/** on_conflict condition type for table "purchaseOrder" */
export type PurchaseOrder_On_Conflict = {
  constraint: PurchaseOrder_Constraint;
  update_columns?: Array<PurchaseOrder_Update_Column>;
  where?: InputMaybe<PurchaseOrder_Bool_Exp>;
};

/** Ordering options when selecting data from "purchaseOrder". */
export type PurchaseOrder_Order_By = {
  archived?: InputMaybe<Order_By>;
  consumableBrand?: InputMaybe<ConsumableBrand_Order_By>;
  consumableBrandId?: InputMaybe<Order_By>;
  consumableType?: InputMaybe<ConsumableType_Order_By>;
  consumableTypeId?: InputMaybe<Order_By>;
  consumables_aggregate?: InputMaybe<Consumable_Aggregate_Order_By>;
  cost?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  note?: InputMaybe<Order_By>;
  orderNumber?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  vendorOrderNumber?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: purchaseOrder */
export type PurchaseOrder_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "purchaseOrder" */
export enum PurchaseOrder_Select_Column {
  /** column name */
  Archived = 'archived',
  /** column name */
  ConsumableBrandId = 'consumableBrandId',
  /** column name */
  ConsumableTypeId = 'consumableTypeId',
  /** column name */
  Cost = 'cost',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Note = 'note',
  /** column name */
  OrderNumber = 'orderNumber',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  VendorOrderNumber = 'vendorOrderNumber',
  /** column name */
  WorkspaceId = 'workspaceId'
}

/** select "purchaseOrder_aggregate_bool_exp_bool_and_arguments_columns" columns of table "purchaseOrder" */
export enum PurchaseOrder_Select_Column_PurchaseOrder_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Archived = 'archived'
}

/** select "purchaseOrder_aggregate_bool_exp_bool_or_arguments_columns" columns of table "purchaseOrder" */
export enum PurchaseOrder_Select_Column_PurchaseOrder_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Archived = 'archived'
}

/** input type for updating data in table "purchaseOrder" */
export type PurchaseOrder_Set_Input = {
  archived?: InputMaybe<Scalars['Boolean']>;
  consumableBrandId?: InputMaybe<Scalars['uuid']>;
  consumableTypeId?: InputMaybe<Scalars['uuid']>;
  cost?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  note?: InputMaybe<Scalars['String']>;
  orderNumber?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  vendorOrderNumber?: InputMaybe<Scalars['String']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type PurchaseOrder_Stddev_Fields = {
  __typename?: 'purchaseOrder_stddev_fields';
  cost?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "purchaseOrder" */
export type PurchaseOrder_Stddev_Order_By = {
  cost?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type PurchaseOrder_Stddev_Pop_Fields = {
  __typename?: 'purchaseOrder_stddev_pop_fields';
  cost?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "purchaseOrder" */
export type PurchaseOrder_Stddev_Pop_Order_By = {
  cost?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type PurchaseOrder_Stddev_Samp_Fields = {
  __typename?: 'purchaseOrder_stddev_samp_fields';
  cost?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "purchaseOrder" */
export type PurchaseOrder_Stddev_Samp_Order_By = {
  cost?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "purchaseOrder" */
export type PurchaseOrder_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: PurchaseOrder_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type PurchaseOrder_Stream_Cursor_Value_Input = {
  archived?: InputMaybe<Scalars['Boolean']>;
  consumableBrandId?: InputMaybe<Scalars['uuid']>;
  consumableTypeId?: InputMaybe<Scalars['uuid']>;
  cost?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  note?: InputMaybe<Scalars['String']>;
  orderNumber?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  vendorOrderNumber?: InputMaybe<Scalars['String']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type PurchaseOrder_Sum_Fields = {
  __typename?: 'purchaseOrder_sum_fields';
  cost?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "purchaseOrder" */
export type PurchaseOrder_Sum_Order_By = {
  cost?: InputMaybe<Order_By>;
};

/** update columns of table "purchaseOrder" */
export enum PurchaseOrder_Update_Column {
  /** column name */
  Archived = 'archived',
  /** column name */
  ConsumableBrandId = 'consumableBrandId',
  /** column name */
  ConsumableTypeId = 'consumableTypeId',
  /** column name */
  Cost = 'cost',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Note = 'note',
  /** column name */
  OrderNumber = 'orderNumber',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  VendorOrderNumber = 'vendorOrderNumber',
  /** column name */
  WorkspaceId = 'workspaceId'
}

export type PurchaseOrder_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<PurchaseOrder_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<PurchaseOrder_Set_Input>;
  /** filter the rows which have to be updated */
  where: PurchaseOrder_Bool_Exp;
};

/** aggregate var_pop on columns */
export type PurchaseOrder_Var_Pop_Fields = {
  __typename?: 'purchaseOrder_var_pop_fields';
  cost?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "purchaseOrder" */
export type PurchaseOrder_Var_Pop_Order_By = {
  cost?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type PurchaseOrder_Var_Samp_Fields = {
  __typename?: 'purchaseOrder_var_samp_fields';
  cost?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "purchaseOrder" */
export type PurchaseOrder_Var_Samp_Order_By = {
  cost?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type PurchaseOrder_Variance_Fields = {
  __typename?: 'purchaseOrder_variance_fields';
  cost?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "purchaseOrder" */
export type PurchaseOrder_Variance_Order_By = {
  cost?: InputMaybe<Order_By>;
};

export type Query_Root = {
  __typename?: 'query_root';
  /** fetch data from the table: "activityLog" */
  activityLog: Array<ActivityLog>;
  /** fetch aggregated fields from the table: "activityLog" */
  activityLog_aggregate: ActivityLog_Aggregate;
  /** fetch data from the table: "activityLog" using primary key columns */
  activityLog_by_pk?: Maybe<ActivityLog>;
  /** fetch data from the table: "address" */
  address: Array<Address>;
  /** fetch aggregated fields from the table: "address" */
  address_aggregate: Address_Aggregate;
  /** fetch data from the table: "address" using primary key columns */
  address_by_pk?: Maybe<Address>;
  /** fetch data from the table: "appointment" */
  appointment: Array<Appointment>;
  /** fetch data from the table: "appointmentConsent" */
  appointmentConsent: Array<AppointmentConsent>;
  /** fetch aggregated fields from the table: "appointmentConsent" */
  appointmentConsent_aggregate: AppointmentConsent_Aggregate;
  /** fetch data from the table: "appointmentConsent" using primary key columns */
  appointmentConsent_by_pk?: Maybe<AppointmentConsent>;
  /** fetch data from the table: "appointmentCustomForm" */
  appointmentCustomForm: Array<AppointmentCustomForm>;
  /** fetch aggregated fields from the table: "appointmentCustomForm" */
  appointmentCustomForm_aggregate: AppointmentCustomForm_Aggregate;
  /** fetch data from the table: "appointmentCustomForm" using primary key columns */
  appointmentCustomForm_by_pk?: Maybe<AppointmentCustomForm>;
  /** fetch data from the table: "appointmentService" */
  appointmentService: Array<AppointmentService>;
  /** fetch data from the table: "appointmentServiceConsumable" */
  appointmentServiceConsumable: Array<AppointmentServiceConsumable>;
  /** fetch aggregated fields from the table: "appointmentServiceConsumable" */
  appointmentServiceConsumable_aggregate: AppointmentServiceConsumable_Aggregate;
  /** fetch data from the table: "appointmentServiceConsumable" using primary key columns */
  appointmentServiceConsumable_by_pk?: Maybe<AppointmentServiceConsumable>;
  /** fetch aggregated fields from the table: "appointmentService" */
  appointmentService_aggregate: AppointmentService_Aggregate;
  /** fetch data from the table: "appointmentService" using primary key columns */
  appointmentService_by_pk?: Maybe<AppointmentService>;
  /** fetch data from the table: "appointmentType" */
  appointmentType: Array<AppointmentType>;
  /** fetch aggregated fields from the table: "appointmentType" */
  appointmentType_aggregate: AppointmentType_Aggregate;
  /** fetch data from the table: "appointmentType" using primary key columns */
  appointmentType_by_pk?: Maybe<AppointmentType>;
  /** fetch aggregated fields from the table: "appointment" */
  appointment_aggregate: Appointment_Aggregate;
  /** fetch data from the table: "appointment" using primary key columns */
  appointment_by_pk?: Maybe<Appointment>;
  /** fetch data from the table: "audience" */
  audience: Array<Audience>;
  /** audienceCount */
  audienceCount?: Maybe<AudienceCountResult>;
  /** fetch data from the table: "audienceEmail" */
  audienceEmail: Array<AudienceEmail>;
  /** fetch aggregated fields from the table: "audienceEmail" */
  audienceEmail_aggregate: AudienceEmail_Aggregate;
  /** fetch data from the table: "audienceEmail" using primary key columns */
  audienceEmail_by_pk?: Maybe<AudienceEmail>;
  audienceResults?: Maybe<Array<Maybe<AudienceResult>>>;
  /** fetch aggregated fields from the table: "audience" */
  audience_aggregate: Audience_Aggregate;
  /** fetch data from the table: "audience" using primary key columns */
  audience_by_pk?: Maybe<Audience>;
  /** fetch data from the table: "authProfile" */
  authProfile: Array<AuthProfile>;
  /** fetch aggregated fields from the table: "authProfile" */
  authProfile_aggregate: AuthProfile_Aggregate;
  /** fetch data from the table: "authProfile" using primary key columns */
  authProfile_by_pk?: Maybe<AuthProfile>;
  availableDevicesForService?: Maybe<Array<Maybe<DeviceAvailabilityOutput>>>;
  calculateInvoiceTotals: CalculateInvoiceTotalsOutput;
  checkCapture?: Maybe<LumaCheckCaptureOutput>;
  checkERXEligibility?: Maybe<CheckErxEligibilityOutput>;
  checkFinixDeviceConnection?: Maybe<SuccessAnswer>;
  /** fetch data from the table: "clinicalMessage" */
  clinicalMessage: Array<ClinicalMessage>;
  /** fetch data from the table: "clinicalMessageStatus" */
  clinicalMessageStatus: Array<ClinicalMessageStatus>;
  /** fetch aggregated fields from the table: "clinicalMessageStatus" */
  clinicalMessageStatus_aggregate: ClinicalMessageStatus_Aggregate;
  /** fetch data from the table: "clinicalMessageStatus" using primary key columns */
  clinicalMessageStatus_by_pk?: Maybe<ClinicalMessageStatus>;
  /** fetch aggregated fields from the table: "clinicalMessage" */
  clinicalMessage_aggregate: ClinicalMessage_Aggregate;
  /** fetch data from the table: "clinicalMessage" using primary key columns */
  clinicalMessage_by_pk?: Maybe<ClinicalMessage>;
  /** fetch data from the table: "compensationPolicy" */
  compensationPolicy: Array<CompensationPolicy>;
  /** fetch data from the table: "compensationPolicyCommission" */
  compensationPolicyCommission: Array<CompensationPolicyCommission>;
  /** fetch aggregated fields from the table: "compensationPolicyCommission" */
  compensationPolicyCommission_aggregate: CompensationPolicyCommission_Aggregate;
  /** fetch data from the table: "compensationPolicyCommission" using primary key columns */
  compensationPolicyCommission_by_pk?: Maybe<CompensationPolicyCommission>;
  /** fetch data from the table: "compensationPolicyTier" */
  compensationPolicyTier: Array<CompensationPolicyTier>;
  /** fetch data from the table: "compensationPolicyTierCategory" */
  compensationPolicyTierCategory: Array<CompensationPolicyTierCategory>;
  /** fetch aggregated fields from the table: "compensationPolicyTierCategory" */
  compensationPolicyTierCategory_aggregate: CompensationPolicyTierCategory_Aggregate;
  /** fetch data from the table: "compensationPolicyTierCategory" using primary key columns */
  compensationPolicyTierCategory_by_pk?: Maybe<CompensationPolicyTierCategory>;
  /** fetch data from the table: "compensationPolicyTierExemption" */
  compensationPolicyTierExemption: Array<CompensationPolicyTierExemption>;
  /** fetch aggregated fields from the table: "compensationPolicyTierExemption" */
  compensationPolicyTierExemption_aggregate: CompensationPolicyTierExemption_Aggregate;
  /** fetch data from the table: "compensationPolicyTierExemption" using primary key columns */
  compensationPolicyTierExemption_by_pk?: Maybe<CompensationPolicyTierExemption>;
  /** fetch data from the table: "compensationPolicyTierInclusion" */
  compensationPolicyTierInclusion: Array<CompensationPolicyTierInclusion>;
  /** fetch aggregated fields from the table: "compensationPolicyTierInclusion" */
  compensationPolicyTierInclusion_aggregate: CompensationPolicyTierInclusion_Aggregate;
  /** fetch data from the table: "compensationPolicyTierInclusion" using primary key columns */
  compensationPolicyTierInclusion_by_pk?: Maybe<CompensationPolicyTierInclusion>;
  /** fetch aggregated fields from the table: "compensationPolicyTier" */
  compensationPolicyTier_aggregate: CompensationPolicyTier_Aggregate;
  /** fetch data from the table: "compensationPolicyTier" using primary key columns */
  compensationPolicyTier_by_pk?: Maybe<CompensationPolicyTier>;
  /** fetch aggregated fields from the table: "compensationPolicy" */
  compensationPolicy_aggregate: CompensationPolicy_Aggregate;
  /** fetch data from the table: "compensationPolicy" using primary key columns */
  compensationPolicy_by_pk?: Maybe<CompensationPolicy>;
  /** fetch data from the table: "compensationStrategy" */
  compensationStrategy: Array<CompensationStrategy>;
  /** fetch data from the table: "compensationStrategyCommission" */
  compensationStrategyCommission: Array<CompensationStrategyCommission>;
  /** fetch aggregated fields from the table: "compensationStrategyCommission" */
  compensationStrategyCommission_aggregate: CompensationStrategyCommission_Aggregate;
  /** fetch data from the table: "compensationStrategyCommission" using primary key columns */
  compensationStrategyCommission_by_pk?: Maybe<CompensationStrategyCommission>;
  /** fetch data from the table: "compensationStrategyTier" */
  compensationStrategyTier: Array<CompensationStrategyTier>;
  /** fetch data from the table: "compensationStrategyTierCategory" */
  compensationStrategyTierCategory: Array<CompensationStrategyTierCategory>;
  /** fetch aggregated fields from the table: "compensationStrategyTierCategory" */
  compensationStrategyTierCategory_aggregate: CompensationStrategyTierCategory_Aggregate;
  /** fetch data from the table: "compensationStrategyTierCategory" using primary key columns */
  compensationStrategyTierCategory_by_pk?: Maybe<CompensationStrategyTierCategory>;
  /** fetch data from the table: "compensationStrategyTierExemption" */
  compensationStrategyTierExemption: Array<CompensationStrategyTierExemption>;
  /** fetch aggregated fields from the table: "compensationStrategyTierExemption" */
  compensationStrategyTierExemption_aggregate: CompensationStrategyTierExemption_Aggregate;
  /** fetch data from the table: "compensationStrategyTierExemption" using primary key columns */
  compensationStrategyTierExemption_by_pk?: Maybe<CompensationStrategyTierExemption>;
  /** fetch data from the table: "compensationStrategyTierInclusion" */
  compensationStrategyTierInclusion: Array<CompensationStrategyTierInclusion>;
  /** fetch aggregated fields from the table: "compensationStrategyTierInclusion" */
  compensationStrategyTierInclusion_aggregate: CompensationStrategyTierInclusion_Aggregate;
  /** fetch data from the table: "compensationStrategyTierInclusion" using primary key columns */
  compensationStrategyTierInclusion_by_pk?: Maybe<CompensationStrategyTierInclusion>;
  /** fetch aggregated fields from the table: "compensationStrategyTier" */
  compensationStrategyTier_aggregate: CompensationStrategyTier_Aggregate;
  /** fetch data from the table: "compensationStrategyTier" using primary key columns */
  compensationStrategyTier_by_pk?: Maybe<CompensationStrategyTier>;
  /** fetch aggregated fields from the table: "compensationStrategy" */
  compensationStrategy_aggregate: CompensationStrategy_Aggregate;
  /** fetch data from the table: "compensationStrategy" using primary key columns */
  compensationStrategy_by_pk?: Maybe<CompensationStrategy>;
  compensationTotals: CompensationTotalsOutput;
  /** fetch data from the table: "consent" */
  consent: Array<Consent>;
  /** fetch aggregated fields from the table: "consent" */
  consent_aggregate: Consent_Aggregate;
  /** fetch data from the table: "consent" using primary key columns */
  consent_by_pk?: Maybe<Consent>;
  /** fetch data from the table: "consumable" */
  consumable: Array<Consumable>;
  /** fetch data from the table: "consumableBrand" */
  consumableBrand: Array<ConsumableBrand>;
  /** fetch aggregated fields from the table: "consumableBrand" */
  consumableBrand_aggregate: ConsumableBrand_Aggregate;
  /** fetch data from the table: "consumableBrand" using primary key columns */
  consumableBrand_by_pk?: Maybe<ConsumableBrand>;
  /** fetch data from the table: "consumableSubtype" */
  consumableSubtype: Array<ConsumableSubtype>;
  /** fetch aggregated fields from the table: "consumableSubtype" */
  consumableSubtype_aggregate: ConsumableSubtype_Aggregate;
  /** fetch data from the table: "consumableSubtype" using primary key columns */
  consumableSubtype_by_pk?: Maybe<ConsumableSubtype>;
  /** fetch data from the table: "consumableType" */
  consumableType: Array<ConsumableType>;
  /** fetch data from the table: "consumableTypeConsumableSubtype" */
  consumableTypeConsumableSubtype: Array<ConsumableTypeConsumableSubtype>;
  /** fetch aggregated fields from the table: "consumableTypeConsumableSubtype" */
  consumableTypeConsumableSubtype_aggregate: ConsumableTypeConsumableSubtype_Aggregate;
  /** fetch data from the table: "consumableTypeConsumableSubtype" using primary key columns */
  consumableTypeConsumableSubtype_by_pk?: Maybe<ConsumableTypeConsumableSubtype>;
  /** fetch aggregated fields from the table: "consumableType" */
  consumableType_aggregate: ConsumableType_Aggregate;
  /** fetch data from the table: "consumableType" using primary key columns */
  consumableType_by_pk?: Maybe<ConsumableType>;
  /** fetch aggregated fields from the table: "consumable" */
  consumable_aggregate: Consumable_Aggregate;
  /** fetch data from the table: "consumable" using primary key columns */
  consumable_by_pk?: Maybe<Consumable>;
  /** fetch data from the table: "coupon" */
  coupon: Array<Coupon>;
  /** fetch data from the table: "couponConfiguration" */
  couponConfiguration: Array<CouponConfiguration>;
  /** fetch aggregated fields from the table: "couponConfiguration" */
  couponConfiguration_aggregate: CouponConfiguration_Aggregate;
  /** fetch data from the table: "couponConfiguration" using primary key columns */
  couponConfiguration_by_pk?: Maybe<CouponConfiguration>;
  /** fetch data from the table: "couponScope" */
  couponScope: Array<CouponScope>;
  /** fetch aggregated fields from the table: "couponScope" */
  couponScope_aggregate: CouponScope_Aggregate;
  /** fetch data from the table: "couponScope" using primary key columns */
  couponScope_by_pk?: Maybe<CouponScope>;
  /** fetch data from the table: "couponType" */
  couponType: Array<CouponType>;
  /** fetch aggregated fields from the table: "couponType" */
  couponType_aggregate: CouponType_Aggregate;
  /** fetch data from the table: "couponType" using primary key columns */
  couponType_by_pk?: Maybe<CouponType>;
  /** fetch aggregated fields from the table: "coupon" */
  coupon_aggregate: Coupon_Aggregate;
  /** fetch data from the table: "coupon" using primary key columns */
  coupon_by_pk?: Maybe<Coupon>;
  /** fetch data from the table: "customForm" */
  customForm: Array<CustomForm>;
  /** fetch data from the table: "customFormField" */
  customFormField: Array<CustomFormField>;
  /** fetch data from the table: "customFormFieldCategory" */
  customFormFieldCategory: Array<CustomFormFieldCategory>;
  /** fetch aggregated fields from the table: "customFormFieldCategory" */
  customFormFieldCategory_aggregate: CustomFormFieldCategory_Aggregate;
  /** fetch data from the table: "customFormFieldCategory" using primary key columns */
  customFormFieldCategory_by_pk?: Maybe<CustomFormFieldCategory>;
  /** fetch data from the table: "customFormFieldResponse" */
  customFormFieldResponse: Array<CustomFormFieldResponse>;
  /** fetch aggregated fields from the table: "customFormFieldResponse" */
  customFormFieldResponse_aggregate: CustomFormFieldResponse_Aggregate;
  /** fetch data from the table: "customFormFieldResponse" using primary key columns */
  customFormFieldResponse_by_pk?: Maybe<CustomFormFieldResponse>;
  /** fetch data from the table: "customFormFieldType" */
  customFormFieldType: Array<CustomFormFieldType>;
  /** fetch aggregated fields from the table: "customFormFieldType" */
  customFormFieldType_aggregate: CustomFormFieldType_Aggregate;
  /** fetch data from the table: "customFormFieldType" using primary key columns */
  customFormFieldType_by_pk?: Maybe<CustomFormFieldType>;
  /** fetch aggregated fields from the table: "customFormField" */
  customFormField_aggregate: CustomFormField_Aggregate;
  /** fetch data from the table: "customFormField" using primary key columns */
  customFormField_by_pk?: Maybe<CustomFormField>;
  /** fetch data from the table: "customFormOption" */
  customFormOption: Array<CustomFormOption>;
  /** fetch aggregated fields from the table: "customFormOption" */
  customFormOption_aggregate: CustomFormOption_Aggregate;
  /** fetch data from the table: "customFormOption" using primary key columns */
  customFormOption_by_pk?: Maybe<CustomFormOption>;
  /** fetch data from the table: "customFormReplicatedField" */
  customFormReplicatedField: Array<CustomFormReplicatedField>;
  /** fetch aggregated fields from the table: "customFormReplicatedField" */
  customFormReplicatedField_aggregate: CustomFormReplicatedField_Aggregate;
  /** fetch data from the table: "customFormReplicatedField" using primary key columns */
  customFormReplicatedField_by_pk?: Maybe<CustomFormReplicatedField>;
  /** fetch data from the table: "customFormReplicatedRow" */
  customFormReplicatedRow: Array<CustomFormReplicatedRow>;
  /** fetch aggregated fields from the table: "customFormReplicatedRow" */
  customFormReplicatedRow_aggregate: CustomFormReplicatedRow_Aggregate;
  /** fetch data from the table: "customFormReplicatedRow" using primary key columns */
  customFormReplicatedRow_by_pk?: Maybe<CustomFormReplicatedRow>;
  /** fetch data from the table: "customFormResponse" */
  customFormResponse: Array<CustomFormResponse>;
  /** fetch aggregated fields from the table: "customFormResponse" */
  customFormResponse_aggregate: CustomFormResponse_Aggregate;
  /** fetch data from the table: "customFormResponse" using primary key columns */
  customFormResponse_by_pk?: Maybe<CustomFormResponse>;
  /** fetch data from the table: "customFormRow" */
  customFormRow: Array<CustomFormRow>;
  /** fetch aggregated fields from the table: "customFormRow" */
  customFormRow_aggregate: CustomFormRow_Aggregate;
  /** fetch data from the table: "customFormRow" using primary key columns */
  customFormRow_by_pk?: Maybe<CustomFormRow>;
  /** fetch aggregated fields from the table: "customForm" */
  customForm_aggregate: CustomForm_Aggregate;
  /** fetch data from the table: "customForm" using primary key columns */
  customForm_by_pk?: Maybe<CustomForm>;
  /** fetch data from the table: "customQuestion" */
  customQuestion: Array<CustomQuestion>;
  /** fetch data from the table: "customQuestionOption" */
  customQuestionOption: Array<CustomQuestionOption>;
  /** fetch aggregated fields from the table: "customQuestionOption" */
  customQuestionOption_aggregate: CustomQuestionOption_Aggregate;
  /** fetch data from the table: "customQuestionOption" using primary key columns */
  customQuestionOption_by_pk?: Maybe<CustomQuestionOption>;
  /** fetch aggregated fields from the table: "customQuestion" */
  customQuestion_aggregate: CustomQuestion_Aggregate;
  /** fetch data from the table: "customQuestion" using primary key columns */
  customQuestion_by_pk?: Maybe<CustomQuestion>;
  /** fetch data from the table: "degreeDesignation" */
  degreeDesignation: Array<DegreeDesignation>;
  /** fetch aggregated fields from the table: "degreeDesignation" */
  degreeDesignation_aggregate: DegreeDesignation_Aggregate;
  /** fetch data from the table: "degreeDesignation" using primary key columns */
  degreeDesignation_by_pk?: Maybe<DegreeDesignation>;
  /** fetch data from the table: "depositInvoiceLineItem" */
  depositInvoiceLineItem: Array<DepositInvoiceLineItem>;
  /** fetch aggregated fields from the table: "depositInvoiceLineItem" */
  depositInvoiceLineItem_aggregate: DepositInvoiceLineItem_Aggregate;
  /** fetch data from the table: "depositInvoiceLineItem" using primary key columns */
  depositInvoiceLineItem_by_pk?: Maybe<DepositInvoiceLineItem>;
  /** fetch data from the table: "device" */
  device: Array<Device>;
  /** fetch data from the table: "deviceType" */
  deviceType: Array<DeviceType>;
  /** fetch aggregated fields from the table: "deviceType" */
  deviceType_aggregate: DeviceType_Aggregate;
  /** fetch data from the table: "deviceType" using primary key columns */
  deviceType_by_pk?: Maybe<DeviceType>;
  /** fetch aggregated fields from the table: "device" */
  device_aggregate: Device_Aggregate;
  /** fetch data from the table: "device" using primary key columns */
  device_by_pk?: Maybe<Device>;
  /** fetch data from the table: "discountInvoiceLineItem" */
  discountInvoiceLineItem: Array<DiscountInvoiceLineItem>;
  /** fetch aggregated fields from the table: "discountInvoiceLineItem" */
  discountInvoiceLineItem_aggregate: DiscountInvoiceLineItem_Aggregate;
  /** fetch data from the table: "discountInvoiceLineItem" using primary key columns */
  discountInvoiceLineItem_by_pk?: Maybe<DiscountInvoiceLineItem>;
  /** fetch data from the table: "document" */
  document: Array<Document>;
  /** fetch data from the table: "documentTag" */
  documentTag: Array<DocumentTag>;
  /** fetch aggregated fields from the table: "documentTag" */
  documentTag_aggregate: DocumentTag_Aggregate;
  /** fetch data from the table: "documentTag" using primary key columns */
  documentTag_by_pk?: Maybe<DocumentTag>;
  /** fetch aggregated fields from the table: "document" */
  document_aggregate: Document_Aggregate;
  /** fetch data from the table: "document" using primary key columns */
  document_by_pk?: Maybe<Document>;
  downloadImage?: Maybe<DownloadImageOutput>;
  drChronoCallback?: Maybe<SuccessAnswer>;
  /** fetch data from the table: "emailCampaignLog" */
  emailCampaignLog: Array<EmailCampaignLog>;
  /** fetch aggregated fields from the table: "emailCampaignLog" */
  emailCampaignLog_aggregate: EmailCampaignLog_Aggregate;
  /** fetch data from the table: "emailCampaignLog" using primary key columns */
  emailCampaignLog_by_pk?: Maybe<EmailCampaignLog>;
  /** fetch data from the table: "emailCampaignMetrics" */
  emailCampaignMetrics: Array<EmailCampaignMetrics>;
  /** fetch aggregated fields from the table: "emailCampaignMetrics" */
  emailCampaignMetrics_aggregate: EmailCampaignMetrics_Aggregate;
  /** fetch data from the table: "emailTemplate" */
  emailTemplate: Array<EmailTemplate>;
  /** fetch data from the table: "emailTemplatePatient" */
  emailTemplatePatient: Array<EmailTemplatePatient>;
  /** fetch aggregated fields from the table: "emailTemplatePatient" */
  emailTemplatePatient_aggregate: EmailTemplatePatient_Aggregate;
  /** fetch data from the table: "emailTemplatePatient" using primary key columns */
  emailTemplatePatient_by_pk?: Maybe<EmailTemplatePatient>;
  /** fetch data from the table: "emailTemplateSendQueue" */
  emailTemplateSendQueue: Array<EmailTemplateSendQueue>;
  /** fetch aggregated fields from the table: "emailTemplateSendQueue" */
  emailTemplateSendQueue_aggregate: EmailTemplateSendQueue_Aggregate;
  /** fetch data from the table: "emailTemplateSendQueue" using primary key columns */
  emailTemplateSendQueue_by_pk?: Maybe<EmailTemplateSendQueue>;
  /** fetch aggregated fields from the table: "emailTemplate" */
  emailTemplate_aggregate: EmailTemplate_Aggregate;
  /** fetch data from the table: "emailTemplate" using primary key columns */
  emailTemplate_by_pk?: Maybe<EmailTemplate>;
  /** fetch data from the table: "externalLocation" */
  externalLocation: Array<ExternalLocation>;
  /** fetch aggregated fields from the table: "externalLocation" */
  externalLocation_aggregate: ExternalLocation_Aggregate;
  /** fetch data from the table: "externalLocation" using primary key columns */
  externalLocation_by_pk?: Maybe<ExternalLocation>;
  /** fetch data from the table: "externalProvider" */
  externalProvider: Array<ExternalProvider>;
  /** fetch aggregated fields from the table: "externalProvider" */
  externalProvider_aggregate: ExternalProvider_Aggregate;
  /** fetch data from the table: "externalProvider" using primary key columns */
  externalProvider_by_pk?: Maybe<ExternalProvider>;
  /** fetch data from the table: "externalReview" */
  externalReview: Array<ExternalReview>;
  /** fetch aggregated fields from the table: "externalReview" */
  externalReview_aggregate: ExternalReview_Aggregate;
  /** fetch data from the table: "externalReview" using primary key columns */
  externalReview_by_pk?: Maybe<ExternalReview>;
  /** fetch data from the table: "fee" */
  fee: Array<Fee>;
  /** fetch data from the table: "feeInvoiceLineItem" */
  feeInvoiceLineItem: Array<FeeInvoiceLineItem>;
  /** fetch aggregated fields from the table: "feeInvoiceLineItem" */
  feeInvoiceLineItem_aggregate: FeeInvoiceLineItem_Aggregate;
  /** fetch data from the table: "feeInvoiceLineItem" using primary key columns */
  feeInvoiceLineItem_by_pk?: Maybe<FeeInvoiceLineItem>;
  /** fetch aggregated fields from the table: "fee" */
  fee_aggregate: Fee_Aggregate;
  /** fetch data from the table: "fee" using primary key columns */
  fee_by_pk?: Maybe<Fee>;
  fetchProviderAcrossReviewPlatforms?: Maybe<Array<Maybe<ProviderOnReviewPlatform>>>;
  /** fetch data from the table: "finixTerminal" */
  finixTerminal: Array<FinixTerminal>;
  /** fetch aggregated fields from the table: "finixTerminal" */
  finixTerminal_aggregate: FinixTerminal_Aggregate;
  /** fetch data from the table: "finixTerminal" using primary key columns */
  finixTerminal_by_pk?: Maybe<FinixTerminal>;
  /** fetch data from the table: "flowsheet" */
  flowsheet: Array<Flowsheet>;
  /** fetch data from the table: "flowsheetParameter" */
  flowsheetParameter: Array<FlowsheetParameter>;
  /** fetch data from the table: "flowsheetParameterFlowsheet" */
  flowsheetParameterFlowsheet: Array<FlowsheetParameterFlowsheet>;
  /** fetch aggregated fields from the table: "flowsheetParameterFlowsheet" */
  flowsheetParameterFlowsheet_aggregate: FlowsheetParameterFlowsheet_Aggregate;
  /** fetch data from the table: "flowsheetParameterFlowsheet" using primary key columns */
  flowsheetParameterFlowsheet_by_pk?: Maybe<FlowsheetParameterFlowsheet>;
  /** fetch data from the table: "flowsheetParameterGroup" */
  flowsheetParameterGroup: Array<FlowsheetParameterGroup>;
  /** fetch aggregated fields from the table: "flowsheetParameterGroup" */
  flowsheetParameterGroup_aggregate: FlowsheetParameterGroup_Aggregate;
  /** fetch data from the table: "flowsheetParameterGroup" using primary key columns */
  flowsheetParameterGroup_by_pk?: Maybe<FlowsheetParameterGroup>;
  /** fetch aggregated fields from the table: "flowsheetParameter" */
  flowsheetParameter_aggregate: FlowsheetParameter_Aggregate;
  /** fetch data from the table: "flowsheetParameter" using primary key columns */
  flowsheetParameter_by_pk?: Maybe<FlowsheetParameter>;
  /** fetch aggregated fields from the table: "flowsheet" */
  flowsheet_aggregate: Flowsheet_Aggregate;
  /** fetch data from the table: "flowsheet" using primary key columns */
  flowsheet_by_pk?: Maybe<Flowsheet>;
  genPatientAuthLink: AuthLinkOutput;
  /** fetch data from the table: "genericInvoiceLineItem" */
  genericInvoiceLineItem: Array<GenericInvoiceLineItem>;
  /** fetch data from the table: "genericInvoiceLineItemDiscount" */
  genericInvoiceLineItemDiscount: Array<GenericInvoiceLineItemDiscount>;
  /** fetch aggregated fields from the table: "genericInvoiceLineItemDiscount" */
  genericInvoiceLineItemDiscount_aggregate: GenericInvoiceLineItemDiscount_Aggregate;
  /** fetch data from the table: "genericInvoiceLineItemDiscount" using primary key columns */
  genericInvoiceLineItemDiscount_by_pk?: Maybe<GenericInvoiceLineItemDiscount>;
  /** fetch aggregated fields from the table: "genericInvoiceLineItem" */
  genericInvoiceLineItem_aggregate: GenericInvoiceLineItem_Aggregate;
  /** fetch data from the table: "genericInvoiceLineItem" using primary key columns */
  genericInvoiceLineItem_by_pk?: Maybe<GenericInvoiceLineItem>;
  getActiveInvoiceTotals: GetActiveInvoiceTotalsOutput;
  getAppointmentCanCollectNoShowFee: Scalars['Boolean'];
  getAppointmentConflicts?: Maybe<Array<Maybe<Conflict>>>;
  getAppointmentConsentMetadata: AppointmentConsentMetadata;
  getAppointmentConsumablesData: GetAppointmentConsumablesDataOutput;
  getAppointmentCustomFormMetadata: AppointmentCustomFormMetadata;
  getAppointmentDepositAmount: GetAppointmentDepositAmountOutput;
  getAppointmentMetadata: AppointmentMetadata;
  getAppointmentServiceConsumablesStatus?: Maybe<AppointmentServiceConsumablesStatusOutput>;
  getAuthUrl?: Maybe<AuthUrlOutput>;
  /** getAvailabilityForMultipleServices */
  getAvailabilityForMultipleServices?: Maybe<Array<Maybe<MultipleServicesAvailabilityOutput>>>;
  getAvailabilityForService?: Maybe<Array<Maybe<ServiceAvailabilityOutput>>>;
  getCloudflareCustomHostname?: Maybe<CloudflareCustomHostnameDetails>;
  getDocumentFile?: Maybe<FileOnlyUrl>;
  getEmailTemplateScreenshot?: Maybe<FileOnlyUrl>;
  getExternalVendorWebhookToken?: Maybe<ExternalVendorWebhookTokenOutput>;
  getGoogleAuthUrl?: Maybe<AuthUrlOutput>;
  getInventory: GetInventoryOutput;
  getInventoryAggregate: GetInventoryAggregateOutput;
  getInventoryTransactionAccount: Scalars['jsonb'];
  getInventoryTransactionHistory?: Maybe<GetInventoryTransactionHistoryOutput>;
  getInvoiceNumber?: Maybe<GetInvoiceNumberOutput>;
  getInvoiceReceiptPayments: Array<InvoiceReceiptPayment>;
  getInvoiceTotals: GetInvoiceTotalsOutput;
  getKnockUserToken?: Maybe<Scalars['String']>;
  /** getLeadFormSources */
  getLeadFormSources?: Maybe<LeadFormSourcesResult>;
  getMediaVersions?: Maybe<MediaVersionsOutput>;
  getMetabaseEmbedUrl?: Maybe<MetabaseEmbedUrlOutput>;
  getNextSubscriptionPaymentDate?: Maybe<Scalars['String']>;
  getPatientAttributes: PatientAttributes;
  /** getPatientProductHistory */
  getPatientProductHistory?: Maybe<PatientProductHistoryOutput>;
  getPatientServiceHistory?: Maybe<PatientServiceHistory>;
  getPatientWalletTransactionSource: GetPatientWalletTransactionSourceOutput;
  /** getPatientWorkspaceSources */
  getPatientWorkspaceSources?: Maybe<PatientWorkspaceSourcesResult>;
  getPaymentReceipt?: Maybe<FileOnlyUrl>;
  getPayrixTransaction: PayrixTransaction;
  getPrescriptionsUrl?: Maybe<AuthUrlOutput>;
  getProductDetailsPage: GetProductDetailsPageOutput;
  getProviderAvailabilityForLocation?: Maybe<ScheduleOutput>;
  getRSProviderReviews: GetRsProviderReviewsOutput;
  getResolvedAppointmentConsents: ResolvedAppointmentConsentsOutput;
  getResolvedAppointmentCustomForms: ResolvedAppointmentCustomFormsOutput;
  getSignatureFile?: Maybe<FileOnlyUrl>;
  /** Get a pre-signed url for uploading files to photo bucket */
  getSignedUploadUrl?: Maybe<SignedUrlOutput>;
  getSimplifiedInventoryTransactions: GetSimplifiedInventoryTransactionsOutput;
  getTwilioChatToken?: Maybe<TwilioChatTokenResponse>;
  getValidatedCouponsForInvoice: GetValidatedCouponsForInvoiceOutput;
  getValidatedCouponsForService: GetValidatedCouponsForServiceOutput;
  getZDProviderReviews: GetZdProviderReviewsOutput;
  get_file?: Maybe<FilePayload>;
  /** fetch data from the table: "giftCard" */
  giftCard: Array<GiftCard>;
  /** fetch aggregated fields from the table: "giftCard" */
  giftCard_aggregate: GiftCard_Aggregate;
  /** fetch data from the table: "giftCard" using primary key columns */
  giftCard_by_pk?: Maybe<GiftCard>;
  /** fetch data from the table: "googleAccount" */
  googleAccount: Array<GoogleAccount>;
  /** fetch aggregated fields from the table: "googleAccount" */
  googleAccount_aggregate: GoogleAccount_Aggregate;
  /** fetch data from the table: "googleAccount" using primary key columns */
  googleAccount_by_pk?: Maybe<GoogleAccount>;
  googleAccounts: GoogleAccountsOutput;
  /** fetch data from the table: "googleLocation" */
  googleLocation: Array<GoogleLocation>;
  /** fetch aggregated fields from the table: "googleLocation" */
  googleLocation_aggregate: GoogleLocation_Aggregate;
  /** fetch data from the table: "googleLocation" using primary key columns */
  googleLocation_by_pk?: Maybe<GoogleLocation>;
  googleLocations: GoogleLocationsOutput;
  googleOnboardingStatus: GoogleOnboardingStatusOutput;
  /** fetch data from the table: "googleReview" */
  googleReview: Array<GoogleReview>;
  /** fetch aggregated fields from the table: "googleReview" */
  googleReview_aggregate: GoogleReview_Aggregate;
  /** fetch data from the table: "googleReview" using primary key columns */
  googleReview_by_pk?: Maybe<GoogleReview>;
  googleReviews: GoogleReviewsOutput;
  /** fetch data from the table: "importJob" */
  importJob: Array<ImportJob>;
  /** fetch aggregated fields from the table: "importJob" */
  importJob_aggregate: ImportJob_Aggregate;
  /** fetch data from the table: "importJob" using primary key columns */
  importJob_by_pk?: Maybe<ImportJob>;
  /** fetch data from the table: "importMetadata" */
  importMetadata: Array<ImportMetadata>;
  /** fetch aggregated fields from the table: "importMetadata" */
  importMetadata_aggregate: ImportMetadata_Aggregate;
  /** fetch data from the table: "importMetadata" using primary key columns */
  importMetadata_by_pk?: Maybe<ImportMetadata>;
  /** An array relationship */
  incompatibleServices: Array<IncompatibleServices>;
  /** An aggregate relationship */
  incompatibleServices_aggregate: IncompatibleServices_Aggregate;
  /** fetch data from the table: "incompatibleServices" using primary key columns */
  incompatibleServices_by_pk?: Maybe<IncompatibleServices>;
  /** fetch data from the table: "internalAppointmentProvider" */
  internalAppointmentProvider: Array<InternalAppointmentProvider>;
  /** fetch aggregated fields from the table: "internalAppointmentProvider" */
  internalAppointmentProvider_aggregate: InternalAppointmentProvider_Aggregate;
  /** fetch data from the table: "internalAppointmentProvider" using primary key columns */
  internalAppointmentProvider_by_pk?: Maybe<InternalAppointmentProvider>;
  /** fetch data from the table: "inventoryTransaction" */
  inventoryTransaction: Array<InventoryTransaction>;
  /** fetch aggregated fields from the table: "inventoryTransaction" */
  inventoryTransaction_aggregate: InventoryTransaction_Aggregate;
  /** fetch data from the table: "inventoryTransaction" using primary key columns */
  inventoryTransaction_by_pk?: Maybe<InventoryTransaction>;
  /** fetch data from the table: "invoice" */
  invoice: Array<Invoice>;
  /** fetch data from the table: "invoiceConfiguration" */
  invoiceConfiguration: Array<InvoiceConfiguration>;
  /** fetch aggregated fields from the table: "invoiceConfiguration" */
  invoiceConfiguration_aggregate: InvoiceConfiguration_Aggregate;
  /** fetch data from the table: "invoiceConfiguration" using primary key columns */
  invoiceConfiguration_by_pk?: Maybe<InvoiceConfiguration>;
  /** fetch data from the table: "invoiceCoupon" */
  invoiceCoupon: Array<InvoiceCoupon>;
  /** fetch aggregated fields from the table: "invoiceCoupon" */
  invoiceCoupon_aggregate: InvoiceCoupon_Aggregate;
  /** fetch data from the table: "invoiceCoupon" using primary key columns */
  invoiceCoupon_by_pk?: Maybe<InvoiceCoupon>;
  /** fetch data from the table: "invoiceMembership" */
  invoiceMembership: Array<InvoiceMembership>;
  /** fetch aggregated fields from the table: "invoiceMembership" */
  invoiceMembership_aggregate: InvoiceMembership_Aggregate;
  /** fetch data from the table: "invoiceMembership" using primary key columns */
  invoiceMembership_by_pk?: Maybe<InvoiceMembership>;
  /** fetch data from the table: "invoicePackage" */
  invoicePackage: Array<InvoicePackage>;
  /** fetch aggregated fields from the table: "invoicePackage" */
  invoicePackage_aggregate: InvoicePackage_Aggregate;
  /** fetch data from the table: "invoicePackage" using primary key columns */
  invoicePackage_by_pk?: Maybe<InvoicePackage>;
  /** fetch data from the table: "invoiceProductItem" */
  invoiceProductItem: Array<InvoiceProductItem>;
  /** fetch aggregated fields from the table: "invoiceProductItem" */
  invoiceProductItem_aggregate: InvoiceProductItem_Aggregate;
  /** fetch data from the table: "invoiceProductItem" using primary key columns */
  invoiceProductItem_by_pk?: Maybe<InvoiceProductItem>;
  /** fetch data from the table: "invoiceServiceConsumable" */
  invoiceServiceConsumable: Array<InvoiceServiceConsumable>;
  /** fetch aggregated fields from the table: "invoiceServiceConsumable" */
  invoiceServiceConsumable_aggregate: InvoiceServiceConsumable_Aggregate;
  /** fetch data from the table: "invoiceServiceConsumable" using primary key columns */
  invoiceServiceConsumable_by_pk?: Maybe<InvoiceServiceConsumable>;
  /** fetch data from the table: "invoiceServiceItem" */
  invoiceServiceItem: Array<InvoiceServiceItem>;
  /** fetch aggregated fields from the table: "invoiceServiceItem" */
  invoiceServiceItem_aggregate: InvoiceServiceItem_Aggregate;
  /** fetch data from the table: "invoiceServiceItem" using primary key columns */
  invoiceServiceItem_by_pk?: Maybe<InvoiceServiceItem>;
  /** fetch data from the table: "invoiceTemplate" */
  invoiceTemplate: Array<InvoiceTemplate>;
  /** fetch aggregated fields from the table: "invoiceTemplate" */
  invoiceTemplate_aggregate: InvoiceTemplate_Aggregate;
  /** fetch data from the table: "invoiceTemplate" using primary key columns */
  invoiceTemplate_by_pk?: Maybe<InvoiceTemplate>;
  /** fetch aggregated fields from the table: "invoice" */
  invoice_aggregate: Invoice_Aggregate;
  /** fetch data from the table: "invoice" using primary key columns */
  invoice_by_pk?: Maybe<Invoice>;
  /** fetch data from the table: "job" */
  job: Array<Job>;
  /** fetch data from the table: "jobStatus" */
  jobStatus: Array<JobStatus>;
  /** fetch aggregated fields from the table: "jobStatus" */
  jobStatus_aggregate: JobStatus_Aggregate;
  /** fetch data from the table: "jobStatus" using primary key columns */
  jobStatus_by_pk?: Maybe<JobStatus>;
  /** fetch aggregated fields from the table: "job" */
  job_aggregate: Job_Aggregate;
  /** fetch data from the table: "job" using primary key columns */
  job_by_pk?: Maybe<Job>;
  /** fetch data from the table: "labResult" */
  labResult: Array<LabResult>;
  /** fetch aggregated fields from the table: "labResult" */
  labResult_aggregate: LabResult_Aggregate;
  /** fetch data from the table: "labResult" using primary key columns */
  labResult_by_pk?: Maybe<LabResult>;
  /** fetch data from the table: "leadForm" */
  leadForm: Array<LeadForm>;
  /** fetch data from the table: "leadFormEntry" */
  leadFormEntry: Array<LeadFormEntry>;
  /** fetch data from the table: "leadFormEntryTag" */
  leadFormEntryTag: Array<LeadFormEntryTag>;
  /** fetch aggregated fields from the table: "leadFormEntryTag" */
  leadFormEntryTag_aggregate: LeadFormEntryTag_Aggregate;
  /** fetch data from the table: "leadFormEntryTag" using primary key columns */
  leadFormEntryTag_by_pk?: Maybe<LeadFormEntryTag>;
  /** fetch aggregated fields from the table: "leadFormEntry" */
  leadFormEntry_aggregate: LeadFormEntry_Aggregate;
  /** fetch data from the table: "leadFormEntry" using primary key columns */
  leadFormEntry_by_pk?: Maybe<LeadFormEntry>;
  /** fetch data from the table: "leadFormField" */
  leadFormField: Array<LeadFormField>;
  /** fetch data from the table: "leadFormFieldValue" */
  leadFormFieldValue: Array<LeadFormFieldValue>;
  /** fetch aggregated fields from the table: "leadFormFieldValue" */
  leadFormFieldValue_aggregate: LeadFormFieldValue_Aggregate;
  /** fetch data from the table: "leadFormFieldValue" using primary key columns */
  leadFormFieldValue_by_pk?: Maybe<LeadFormFieldValue>;
  /** fetch aggregated fields from the table: "leadFormField" */
  leadFormField_aggregate: LeadFormField_Aggregate;
  /** fetch data from the table: "leadFormField" using primary key columns */
  leadFormField_by_pk?: Maybe<LeadFormField>;
  /** fetch data from the table: "leadFormLink" */
  leadFormLink: Array<LeadFormLink>;
  /** fetch aggregated fields from the table: "leadFormLink" */
  leadFormLink_aggregate: LeadFormLink_Aggregate;
  /** fetch data from the table: "leadFormLink" using primary key columns */
  leadFormLink_by_pk?: Maybe<LeadFormLink>;
  /** fetch data from the table: "leadFormStep" */
  leadFormStep: Array<LeadFormStep>;
  /** fetch aggregated fields from the table: "leadFormStep" */
  leadFormStep_aggregate: LeadFormStep_Aggregate;
  /** fetch data from the table: "leadFormStep" using primary key columns */
  leadFormStep_by_pk?: Maybe<LeadFormStep>;
  /** fetch aggregated fields from the table: "leadForm" */
  leadForm_aggregate: LeadForm_Aggregate;
  /** fetch data from the table: "leadForm" using primary key columns */
  leadForm_by_pk?: Maybe<LeadForm>;
  /** fetch data from the table: "leadTag" */
  leadTag: Array<LeadTag>;
  /** fetch aggregated fields from the table: "leadTag" */
  leadTag_aggregate: LeadTag_Aggregate;
  /** fetch data from the table: "leadTag" using primary key columns */
  leadTag_by_pk?: Maybe<LeadTag>;
  /** fetch data from the table: "leadTrackedEvent" */
  leadTrackedEvent: Array<LeadTrackedEvent>;
  /** fetch aggregated fields from the table: "leadTrackedEvent" */
  leadTrackedEvent_aggregate: LeadTrackedEvent_Aggregate;
  /** fetch data from the table: "leadTrackedEvent" using primary key columns */
  leadTrackedEvent_by_pk?: Maybe<LeadTrackedEvent>;
  /** fetch data from the table: "location" */
  location: Array<Location>;
  /** fetch data from the table: "locationCoupon" */
  locationCoupon: Array<LocationCoupon>;
  /** fetch aggregated fields from the table: "locationCoupon" */
  locationCoupon_aggregate: LocationCoupon_Aggregate;
  /** fetch data from the table: "locationCoupon" using primary key columns */
  locationCoupon_by_pk?: Maybe<LocationCoupon>;
  /** fetch data from the table: "locationProvider" */
  locationProvider: Array<LocationProvider>;
  /** fetch aggregated fields from the table: "locationProvider" */
  locationProvider_aggregate: LocationProvider_Aggregate;
  /** fetch data from the table: "locationProvider" using primary key columns */
  locationProvider_by_pk?: Maybe<LocationProvider>;
  /** fetch data from the table: "locationSchedule" */
  locationSchedule: Array<LocationSchedule>;
  /** fetch aggregated fields from the table: "locationSchedule" */
  locationSchedule_aggregate: LocationSchedule_Aggregate;
  /** fetch data from the table: "locationSchedule" using primary key columns */
  locationSchedule_by_pk?: Maybe<LocationSchedule>;
  /** fetch data from the table: "locationService" */
  locationService: Array<LocationService>;
  /** fetch aggregated fields from the table: "locationService" */
  locationService_aggregate: LocationService_Aggregate;
  /** fetch data from the table: "locationService" using primary key columns */
  locationService_by_pk?: Maybe<LocationService>;
  /** fetch aggregated fields from the table: "location" */
  location_aggregate: Location_Aggregate;
  /** fetch data from the table: "location" using primary key columns */
  location_by_pk?: Maybe<Location>;
  /** fetch data from the table: "marketingTag" */
  marketingTag: Array<MarketingTag>;
  /** fetch data from the table: "marketingTagEmail" */
  marketingTagEmail: Array<MarketingTagEmail>;
  /** fetch aggregated fields from the table: "marketingTagEmail" */
  marketingTagEmail_aggregate: MarketingTagEmail_Aggregate;
  /** fetch data from the table: "marketingTagEmail" using primary key columns */
  marketingTagEmail_by_pk?: Maybe<MarketingTagEmail>;
  /** fetch data from the table: "marketingTagSms" */
  marketingTagSms: Array<MarketingTagSms>;
  /** fetch aggregated fields from the table: "marketingTagSms" */
  marketingTagSms_aggregate: MarketingTagSms_Aggregate;
  /** fetch data from the table: "marketingTagSms" using primary key columns */
  marketingTagSms_by_pk?: Maybe<MarketingTagSms>;
  /** fetch aggregated fields from the table: "marketingTag" */
  marketingTag_aggregate: MarketingTag_Aggregate;
  /** fetch data from the table: "marketingTag" using primary key columns */
  marketingTag_by_pk?: Maybe<MarketingTag>;
  me: MeOutput;
  mePatient: MeOutput;
  /** fetch data from the table: "mediaTag" */
  mediaTag: Array<MediaTag>;
  /** fetch aggregated fields from the table: "mediaTag" */
  mediaTag_aggregate: MediaTag_Aggregate;
  /** fetch data from the table: "mediaTag" using primary key columns */
  mediaTag_by_pk?: Maybe<MediaTag>;
  /** fetch data from the table: "membership" */
  membership: Array<Membership>;
  /** fetch data from the table: "membershipCoupon" */
  membershipCoupon: Array<MembershipCoupon>;
  /** fetch aggregated fields from the table: "membershipCoupon" */
  membershipCoupon_aggregate: MembershipCoupon_Aggregate;
  /** fetch data from the table: "membershipCoupon" using primary key columns */
  membershipCoupon_by_pk?: Maybe<MembershipCoupon>;
  /** fetch data from the table: "membershipType" */
  membershipType: Array<MembershipType>;
  /** fetch aggregated fields from the table: "membershipType" */
  membershipType_aggregate: MembershipType_Aggregate;
  /** fetch data from the table: "membershipType" using primary key columns */
  membershipType_by_pk?: Maybe<MembershipType>;
  /** fetch aggregated fields from the table: "membership" */
  membership_aggregate: Membership_Aggregate;
  /** fetch data from the table: "membership" using primary key columns */
  membership_by_pk?: Maybe<Membership>;
  /** fetch data from the table: "note" */
  note: Array<Note>;
  /** fetch data from the table: "noteSignature" */
  noteSignature: Array<NoteSignature>;
  /** fetch aggregated fields from the table: "noteSignature" */
  noteSignature_aggregate: NoteSignature_Aggregate;
  /** fetch data from the table: "noteSignature" using primary key columns */
  noteSignature_by_pk?: Maybe<NoteSignature>;
  /** fetch aggregated fields from the table: "note" */
  note_aggregate: Note_Aggregate;
  /** fetch data from the table: "note" using primary key columns */
  note_by_pk?: Maybe<Note>;
  /** fetch data from the table: "organization" */
  organization: Array<Organization>;
  /** fetch data from the table: "organizationConfiguration" */
  organizationConfiguration: Array<OrganizationConfiguration>;
  /** fetch aggregated fields from the table: "organizationConfiguration" */
  organizationConfiguration_aggregate: OrganizationConfiguration_Aggregate;
  /** fetch data from the table: "organizationConfiguration" using primary key columns */
  organizationConfiguration_by_pk?: Maybe<OrganizationConfiguration>;
  /** fetch aggregated fields from the table: "organization" */
  organization_aggregate: Organization_Aggregate;
  /** fetch data from the table: "organization" using primary key columns */
  organization_by_pk?: Maybe<Organization>;
  /** fetch data from the table: "package" */
  package: Array<Package>;
  /** fetch aggregated fields from the table: "package" */
  package_aggregate: Package_Aggregate;
  /** fetch data from the table: "package" using primary key columns */
  package_by_pk?: Maybe<Package>;
  /** fetch data from the table: "patient" */
  patient: Array<Patient>;
  /** fetch data from the table: "patientAddress" */
  patientAddress: Array<PatientAddress>;
  /** fetch aggregated fields from the table: "patientAddress" */
  patientAddress_aggregate: PatientAddress_Aggregate;
  /** fetch data from the table: "patientAddress" using primary key columns */
  patientAddress_by_pk?: Maybe<PatientAddress>;
  /** fetch data from the table: "patientConsent" */
  patientConsent: Array<PatientConsent>;
  /** fetch aggregated fields from the table: "patientConsent" */
  patientConsent_aggregate: PatientConsent_Aggregate;
  /** fetch data from the table: "patientConsent" using primary key columns */
  patientConsent_by_pk?: Maybe<PatientConsent>;
  /** fetch data from the table: "patientConversation" */
  patientConversation: Array<PatientConversation>;
  /** fetch aggregated fields from the table: "patientConversation" */
  patientConversation_aggregate: PatientConversation_Aggregate;
  /** fetch data from the table: "patientConversation" using primary key columns */
  patientConversation_by_pk?: Maybe<PatientConversation>;
  /** fetch data from the table: "patientFlowsheetData" */
  patientFlowsheetData: Array<PatientFlowsheetData>;
  /** fetch aggregated fields from the table: "patientFlowsheetData" */
  patientFlowsheetData_aggregate: PatientFlowsheetData_Aggregate;
  /** fetch data from the table: "patientFlowsheetData" using primary key columns */
  patientFlowsheetData_by_pk?: Maybe<PatientFlowsheetData>;
  /** fetch data from the table: "patientFlowsheetRow" */
  patientFlowsheetRow: Array<PatientFlowsheetRow>;
  /** fetch aggregated fields from the table: "patientFlowsheetRow" */
  patientFlowsheetRow_aggregate: PatientFlowsheetRow_Aggregate;
  /** fetch data from the table: "patientFlowsheetRow" using primary key columns */
  patientFlowsheetRow_by_pk?: Maybe<PatientFlowsheetRow>;
  /** fetch data from the table: "patientInvite" */
  patientInvite: Array<PatientInvite>;
  /** fetch aggregated fields from the table: "patientInvite" */
  patientInvite_aggregate: PatientInvite_Aggregate;
  /** fetch data from the table: "patientInvite" using primary key columns */
  patientInvite_by_pk?: Maybe<PatientInvite>;
  /** fetch data from the table: "patientMembership" */
  patientMembership: Array<PatientMembership>;
  /** fetch aggregated fields from the table: "patientMembership" */
  patientMembership_aggregate: PatientMembership_Aggregate;
  /** fetch data from the table: "patientMembership" using primary key columns */
  patientMembership_by_pk?: Maybe<PatientMembership>;
  /** fetch data from the table: "patientSystemNotice" */
  patientSystemNotice: Array<PatientSystemNotice>;
  /** fetch aggregated fields from the table: "patientSystemNotice" */
  patientSystemNotice_aggregate: PatientSystemNotice_Aggregate;
  /** fetch data from the table: "patientSystemNotice" using primary key columns */
  patientSystemNotice_by_pk?: Maybe<PatientSystemNotice>;
  /** fetch data from the table: "patientTag" */
  patientTag: Array<PatientTag>;
  /** fetch aggregated fields from the table: "patientTag" */
  patientTag_aggregate: PatientTag_Aggregate;
  /** fetch data from the table: "patientTag" using primary key columns */
  patientTag_by_pk?: Maybe<PatientTag>;
  /** An array relationship */
  patientTags: Array<PatientTags>;
  /** An aggregate relationship */
  patientTags_aggregate: PatientTags_Aggregate;
  /** fetch data from the table: "patientTags" using primary key columns */
  patientTags_by_pk?: Maybe<PatientTags>;
  /** fetch data from the table: "patientWallet" */
  patientWallet: Array<PatientWallet>;
  /** fetch data from the table: "patientWalletCoupon" */
  patientWalletCoupon: Array<PatientWalletCoupon>;
  /** fetch aggregated fields from the table: "patientWalletCoupon" */
  patientWalletCoupon_aggregate: PatientWalletCoupon_Aggregate;
  /** fetch data from the table: "patientWalletCoupon" using primary key columns */
  patientWalletCoupon_by_pk?: Maybe<PatientWalletCoupon>;
  /** fetch data from the table: "patientWalletTransaction" */
  patientWalletTransaction: Array<PatientWalletTransaction>;
  /** fetch aggregated fields from the table: "patientWalletTransaction" */
  patientWalletTransaction_aggregate: PatientWalletTransaction_Aggregate;
  /** fetch data from the table: "patientWalletTransaction" using primary key columns */
  patientWalletTransaction_by_pk?: Maybe<PatientWalletTransaction>;
  /** fetch aggregated fields from the table: "patientWallet" */
  patientWallet_aggregate: PatientWallet_Aggregate;
  /** fetch data from the table: "patientWallet" using primary key columns */
  patientWallet_by_pk?: Maybe<PatientWallet>;
  /** fetch data from the table: "patientWorkspace" */
  patientWorkspace: Array<PatientWorkspace>;
  /** fetch aggregated fields from the table: "patientWorkspace" */
  patientWorkspace_aggregate: PatientWorkspace_Aggregate;
  /** fetch data from the table: "patientWorkspace" using primary key columns */
  patientWorkspace_by_pk?: Maybe<PatientWorkspace>;
  /** fetch aggregated fields from the table: "patient" */
  patient_aggregate: Patient_Aggregate;
  /** fetch data from the table: "patient" using primary key columns */
  patient_by_pk?: Maybe<Patient>;
  /** fetch data from the table: "payment" */
  payment: Array<Payment>;
  /** fetch data from the table: "paymentMethod" */
  paymentMethod: Array<PaymentMethod>;
  /** fetch aggregated fields from the table: "paymentMethod" */
  paymentMethod_aggregate: PaymentMethod_Aggregate;
  /** fetch data from the table: "paymentMethod" using primary key columns */
  paymentMethod_by_pk?: Maybe<PaymentMethod>;
  /** fetch data from the table: "paymentRelationship" */
  paymentRelationship: Array<PaymentRelationship>;
  /** fetch aggregated fields from the table: "paymentRelationship" */
  paymentRelationship_aggregate: PaymentRelationship_Aggregate;
  /** fetch data from the table: "paymentRelationship" using primary key columns */
  paymentRelationship_by_pk?: Maybe<PaymentRelationship>;
  /** fetch aggregated fields from the table: "payment" */
  payment_aggregate: Payment_Aggregate;
  /** fetch data from the table: "payment" using primary key columns */
  payment_by_pk?: Maybe<Payment>;
  /** fetch data from the table: "payrixTerminal" */
  payrixTerminal: Array<PayrixTerminal>;
  /** fetch data from the table: "payrixTerminalTransaction" */
  payrixTerminalTransaction: Array<PayrixTerminalTransaction>;
  /** fetch aggregated fields from the table: "payrixTerminalTransaction" */
  payrixTerminalTransaction_aggregate: PayrixTerminalTransaction_Aggregate;
  /** fetch data from the table: "payrixTerminalTransaction" using primary key columns */
  payrixTerminalTransaction_by_pk?: Maybe<PayrixTerminalTransaction>;
  /** fetch aggregated fields from the table: "payrixTerminal" */
  payrixTerminal_aggregate: PayrixTerminal_Aggregate;
  /** fetch data from the table: "payrixTerminal" using primary key columns */
  payrixTerminal_by_pk?: Maybe<PayrixTerminal>;
  /** fetch data from the table: "payrixWebAlert" */
  payrixWebAlert: Array<PayrixWebAlert>;
  /** fetch aggregated fields from the table: "payrixWebAlert" */
  payrixWebAlert_aggregate: PayrixWebAlert_Aggregate;
  /** fetch data from the table: "payrixWebAlert" using primary key columns */
  payrixWebAlert_by_pk?: Maybe<PayrixWebAlert>;
  phoneNumberLookup: PhoneNumberLookupOutput;
  /** fetch data from the table: "photo" */
  photo: Array<Photo>;
  /** fetch data from the table: "photoMediaTag" */
  photoMediaTag: Array<PhotoMediaTag>;
  /** fetch aggregated fields from the table: "photoMediaTag" */
  photoMediaTag_aggregate: PhotoMediaTag_Aggregate;
  /** fetch data from the table: "photoMediaTag" using primary key columns */
  photoMediaTag_by_pk?: Maybe<PhotoMediaTag>;
  /** fetch aggregated fields from the table: "photo" */
  photo_aggregate: Photo_Aggregate;
  /** fetch data from the table: "photo" using primary key columns */
  photo_by_pk?: Maybe<Photo>;
  /** fetch data from the table: "pin" */
  pin: Array<Pin>;
  /** fetch aggregated fields from the table: "pin" */
  pin_aggregate: Pin_Aggregate;
  /** fetch data from the table: "pin" using primary key columns */
  pin_by_pk?: Maybe<Pin>;
  /** fetch data from the table: "postStatus" */
  postStatus: Array<PostStatus>;
  /** fetch aggregated fields from the table: "postStatus" */
  postStatus_aggregate: PostStatus_Aggregate;
  /** fetch data from the table: "postStatus" using primary key columns */
  postStatus_by_pk?: Maybe<PostStatus>;
  /** fetch data from the table: "prescription" */
  prescription: Array<Prescription>;
  /** fetch data from the table: "prescriptionDetail" */
  prescriptionDetail: Array<PrescriptionDetail>;
  /** fetch aggregated fields from the table: "prescriptionDetail" */
  prescriptionDetail_aggregate: PrescriptionDetail_Aggregate;
  /** fetch data from the table: "prescriptionDetail" using primary key columns */
  prescriptionDetail_by_pk?: Maybe<PrescriptionDetail>;
  /** fetch aggregated fields from the table: "prescription" */
  prescription_aggregate: Prescription_Aggregate;
  /** fetch data from the table: "prescription" using primary key columns */
  prescription_by_pk?: Maybe<Prescription>;
  previewAudience?: Maybe<PreviewAudienceOutput>;
  /** fetch data from the table: "promotion" */
  promotion: Array<Promotion>;
  /** fetch data from the table: "promotionCoupons" */
  promotionCoupons: Array<PromotionCoupons>;
  /** fetch aggregated fields from the table: "promotionCoupons" */
  promotionCoupons_aggregate: PromotionCoupons_Aggregate;
  /** fetch data from the table: "promotionCoupons" using primary key columns */
  promotionCoupons_by_pk?: Maybe<PromotionCoupons>;
  /** An array relationship */
  promotionServices: Array<PromotionServices>;
  /** An aggregate relationship */
  promotionServices_aggregate: PromotionServices_Aggregate;
  /** fetch data from the table: "promotionServices" using primary key columns */
  promotionServices_by_pk?: Maybe<PromotionServices>;
  /** fetch aggregated fields from the table: "promotion" */
  promotion_aggregate: Promotion_Aggregate;
  /** fetch data from the table: "promotion" using primary key columns */
  promotion_by_pk?: Maybe<Promotion>;
  /** fetch data from the table: "provider" */
  provider: Array<Provider>;
  /** fetch data from the table: "providerSchedule" */
  providerSchedule: Array<ProviderSchedule>;
  /** fetch aggregated fields from the table: "providerSchedule" */
  providerSchedule_aggregate: ProviderSchedule_Aggregate;
  /** fetch data from the table: "providerSchedule" using primary key columns */
  providerSchedule_by_pk?: Maybe<ProviderSchedule>;
  /** fetch aggregated fields from the table: "provider" */
  provider_aggregate: Provider_Aggregate;
  /** fetch data from the table: "provider" using primary key columns */
  provider_by_pk?: Maybe<Provider>;
  pullGoogleProviderReviews: SuccessAnswer;
  pullProviderRSReviews: SuccessAnswer;
  pullProviderZDReviews: SuccessAnswer;
  /** fetch data from the table: "purchaseOrder" */
  purchaseOrder: Array<PurchaseOrder>;
  /** fetch aggregated fields from the table: "purchaseOrder" */
  purchaseOrder_aggregate: PurchaseOrder_Aggregate;
  /** fetch data from the table: "purchaseOrder" using primary key columns */
  purchaseOrder_by_pk?: Maybe<PurchaseOrder>;
  /** fetch data from the table: "recurrenceType" */
  recurrenceType: Array<RecurrenceType>;
  /** fetch aggregated fields from the table: "recurrenceType" */
  recurrenceType_aggregate: RecurrenceType_Aggregate;
  /** fetch data from the table: "recurrenceType" using primary key columns */
  recurrenceType_by_pk?: Maybe<RecurrenceType>;
  /** fetch data from the table: "refund" */
  refund: Array<Refund>;
  /** fetch aggregated fields from the table: "refund" */
  refund_aggregate: Refund_Aggregate;
  /** fetch data from the table: "refund" using primary key columns */
  refund_by_pk?: Maybe<Refund>;
  /** fetch data from the table: "resolutionStatus" */
  resolutionStatus: Array<ResolutionStatus>;
  /** fetch aggregated fields from the table: "resolutionStatus" */
  resolutionStatus_aggregate: ResolutionStatus_Aggregate;
  /** fetch data from the table: "resolutionStatus" using primary key columns */
  resolutionStatus_by_pk?: Maybe<ResolutionStatus>;
  /** fetch data from the table: "rewardProgram" */
  rewardProgram: Array<RewardProgram>;
  /** fetch data from the table: "rewardProgramEnrollment" */
  rewardProgramEnrollment: Array<RewardProgramEnrollment>;
  /** fetch aggregated fields from the table: "rewardProgramEnrollment" */
  rewardProgramEnrollment_aggregate: RewardProgramEnrollment_Aggregate;
  /** fetch data from the table: "rewardProgramEnrollment" using primary key columns */
  rewardProgramEnrollment_by_pk?: Maybe<RewardProgramEnrollment>;
  /** fetch aggregated fields from the table: "rewardProgram" */
  rewardProgram_aggregate: RewardProgram_Aggregate;
  /** fetch data from the table: "rewardProgram" using primary key columns */
  rewardProgram_by_pk?: Maybe<RewardProgram>;
  /** fetch data from the table: "room" */
  room: Array<Room>;
  /** fetch aggregated fields from the table: "room" */
  room_aggregate: Room_Aggregate;
  /** fetch data from the table: "room" using primary key columns */
  room_by_pk?: Maybe<Room>;
  /** fetch data from the table: "rsReview" */
  rsReview: Array<RsReview>;
  /** fetch aggregated fields from the table: "rsReview" */
  rsReview_aggregate: RsReview_Aggregate;
  /** fetch data from the table: "rsReview" using primary key columns */
  rsReview_by_pk?: Maybe<RsReview>;
  /** execute function "searchConsumableTypes" which returns "consumableType" */
  searchConsumableTypes: Array<ConsumableType>;
  /** execute function "searchConsumableTypes" and query aggregates on result of table type "consumableType" */
  searchConsumableTypes_aggregate: ConsumableType_Aggregate;
  searchGoogleLocations: SearchGoogleOutput;
  /** execute function "searchPatients" which returns "patient" */
  searchPatients: Array<Patient>;
  /** execute function "searchPatients" and query aggregates on result of table type "patient" */
  searchPatients_aggregate: Patient_Aggregate;
  /** execute function "searchProviderSchedule" which returns "providerSchedule" */
  searchProviderSchedule: Array<ProviderSchedule>;
  /** execute function "searchProviderSchedule" and query aggregates on result of table type "providerSchedule" */
  searchProviderSchedule_aggregate: ProviderSchedule_Aggregate;
  searchRSProviders?: Maybe<SearchRsProvidersOutput>;
  /** execute function "search_note_title" which returns "note" */
  search_note_title: Array<Note>;
  /** execute function "search_note_title" and query aggregates on result of table type "note" */
  search_note_title_aggregate: Note_Aggregate;
  /** execute function "search_patientWorkspace" which returns "patient" */
  search_patientWorkspace: Array<Patient>;
  /** execute function "search_patientWorkspace" and query aggregates on result of table type "patient" */
  search_patientWorkspace_aggregate: Patient_Aggregate;
  /** fetch data from the table: "service" */
  service: Array<Service>;
  /** fetch data from the table: "serviceAddOn" */
  serviceAddOn: Array<ServiceAddOn>;
  /** fetch aggregated fields from the table: "serviceAddOn" */
  serviceAddOn_aggregate: ServiceAddOn_Aggregate;
  /** fetch data from the table: "serviceAddOn" using primary key columns */
  serviceAddOn_by_pk?: Maybe<ServiceAddOn>;
  /** fetch data from the table: "serviceCategory" */
  serviceCategory: Array<ServiceCategory>;
  /** fetch aggregated fields from the table: "serviceCategory" */
  serviceCategory_aggregate: ServiceCategory_Aggregate;
  /** fetch data from the table: "serviceCategory" using primary key columns */
  serviceCategory_by_pk?: Maybe<ServiceCategory>;
  /** fetch data from the table: "serviceConsent" */
  serviceConsent: Array<ServiceConsent>;
  /** fetch aggregated fields from the table: "serviceConsent" */
  serviceConsent_aggregate: ServiceConsent_Aggregate;
  /** fetch data from the table: "serviceConsent" using primary key columns */
  serviceConsent_by_pk?: Maybe<ServiceConsent>;
  /** fetch data from the table: "serviceConsumableType" */
  serviceConsumableType: Array<ServiceConsumableType>;
  /** fetch aggregated fields from the table: "serviceConsumableType" */
  serviceConsumableType_aggregate: ServiceConsumableType_Aggregate;
  /** fetch data from the table: "serviceConsumableType" using primary key columns */
  serviceConsumableType_by_pk?: Maybe<ServiceConsumableType>;
  /** fetch data from the table: "serviceCoupon" */
  serviceCoupon: Array<ServiceCoupon>;
  /** fetch aggregated fields from the table: "serviceCoupon" */
  serviceCoupon_aggregate: ServiceCoupon_Aggregate;
  /** fetch data from the table: "serviceCoupon" using primary key columns */
  serviceCoupon_by_pk?: Maybe<ServiceCoupon>;
  /** fetch data from the table: "serviceCustomForm" */
  serviceCustomForm: Array<ServiceCustomForm>;
  /** fetch aggregated fields from the table: "serviceCustomForm" */
  serviceCustomForm_aggregate: ServiceCustomForm_Aggregate;
  /** fetch data from the table: "serviceCustomForm" using primary key columns */
  serviceCustomForm_by_pk?: Maybe<ServiceCustomForm>;
  /** fetch data from the table: "serviceDeviceType" */
  serviceDeviceType: Array<ServiceDeviceType>;
  /** fetch aggregated fields from the table: "serviceDeviceType" */
  serviceDeviceType_aggregate: ServiceDeviceType_Aggregate;
  /** fetch data from the table: "serviceDeviceType" using primary key columns */
  serviceDeviceType_by_pk?: Maybe<ServiceDeviceType>;
  /** fetch data from the table: "serviceProvider" */
  serviceProvider: Array<ServiceProvider>;
  /** fetch aggregated fields from the table: "serviceProvider" */
  serviceProvider_aggregate: ServiceProvider_Aggregate;
  /** fetch data from the table: "serviceProvider" using primary key columns */
  serviceProvider_by_pk?: Maybe<ServiceProvider>;
  /** fetch data from the table: "serviceUnit" */
  serviceUnit: Array<ServiceUnit>;
  /** fetch aggregated fields from the table: "serviceUnit" */
  serviceUnit_aggregate: ServiceUnit_Aggregate;
  /** fetch data from the table: "serviceUnit" using primary key columns */
  serviceUnit_by_pk?: Maybe<ServiceUnit>;
  /** fetch aggregated fields from the table: "service" */
  service_aggregate: Service_Aggregate;
  /** fetch data from the table: "service" using primary key columns */
  service_by_pk?: Maybe<Service>;
  /** fetch data from the table: "shrink" */
  shrink: Array<Shrink>;
  /** fetch aggregated fields from the table: "shrink" */
  shrink_aggregate: Shrink_Aggregate;
  /** fetch data from the table: "shrink" using primary key columns */
  shrink_by_pk?: Maybe<Shrink>;
  /** fetch data from the table: "signature" */
  signature: Array<Signature>;
  /** fetch aggregated fields from the table: "signature" */
  signature_aggregate: Signature_Aggregate;
  /** fetch data from the table: "signature" using primary key columns */
  signature_by_pk?: Maybe<Signature>;
  /** fetch data from the table: "smartNote" */
  smartNote: Array<SmartNote>;
  /** fetch aggregated fields from the table: "smartNote" */
  smartNote_aggregate: SmartNote_Aggregate;
  /** fetch data from the table: "smartNote" using primary key columns */
  smartNote_by_pk?: Maybe<SmartNote>;
  /** fetch data from the table: "smsTemplate" */
  smsTemplate: Array<SmsTemplate>;
  /** fetch data from the table: "smsTemplatePatient" */
  smsTemplatePatient: Array<SmsTemplatePatient>;
  /** fetch aggregated fields from the table: "smsTemplatePatient" */
  smsTemplatePatient_aggregate: SmsTemplatePatient_Aggregate;
  /** fetch data from the table: "smsTemplatePatient" using primary key columns */
  smsTemplatePatient_by_pk?: Maybe<SmsTemplatePatient>;
  /** fetch data from the table: "smsTemplateSendQueue" */
  smsTemplateSendQueue: Array<SmsTemplateSendQueue>;
  /** fetch aggregated fields from the table: "smsTemplateSendQueue" */
  smsTemplateSendQueue_aggregate: SmsTemplateSendQueue_Aggregate;
  /** fetch data from the table: "smsTemplateSendQueue" using primary key columns */
  smsTemplateSendQueue_by_pk?: Maybe<SmsTemplateSendQueue>;
  /** fetch aggregated fields from the table: "smsTemplate" */
  smsTemplate_aggregate: SmsTemplate_Aggregate;
  /** fetch data from the table: "smsTemplate" using primary key columns */
  smsTemplate_by_pk?: Maybe<SmsTemplate>;
  /** fetch data from the table: "socialMediaAccount" */
  socialMediaAccount: Array<SocialMediaAccount>;
  /** fetch data from the table: "socialMediaAccountPost" */
  socialMediaAccountPost: Array<SocialMediaAccountPost>;
  /** fetch aggregated fields from the table: "socialMediaAccountPost" */
  socialMediaAccountPost_aggregate: SocialMediaAccountPost_Aggregate;
  /** fetch data from the table: "socialMediaAccountPost" using primary key columns */
  socialMediaAccountPost_by_pk?: Maybe<SocialMediaAccountPost>;
  /** fetch aggregated fields from the table: "socialMediaAccount" */
  socialMediaAccount_aggregate: SocialMediaAccount_Aggregate;
  /** fetch data from the table: "socialMediaAccount" using primary key columns */
  socialMediaAccount_by_pk?: Maybe<SocialMediaAccount>;
  /** fetch data from the table: "socialMediaPlatform" */
  socialMediaPlatform: Array<SocialMediaPlatform>;
  /** fetch aggregated fields from the table: "socialMediaPlatform" */
  socialMediaPlatform_aggregate: SocialMediaPlatform_Aggregate;
  /** fetch data from the table: "socialMediaPlatform" using primary key columns */
  socialMediaPlatform_by_pk?: Maybe<SocialMediaPlatform>;
  /** fetch data from the table: "socialMediaPost" */
  socialMediaPost: Array<SocialMediaPost>;
  /** fetch aggregated fields from the table: "socialMediaPost" */
  socialMediaPost_aggregate: SocialMediaPost_Aggregate;
  /** fetch data from the table: "socialMediaPost" using primary key columns */
  socialMediaPost_by_pk?: Maybe<SocialMediaPost>;
  svixPortalAccess?: Maybe<SvixPortalAccessOutput>;
  syncExternalReviews?: Maybe<SyncExternalReviewsOutput>;
  /** fetch data from the table: "tag" */
  tag: Array<Tag>;
  /** fetch aggregated fields from the table: "tag" */
  tag_aggregate: Tag_Aggregate;
  /** fetch data from the table: "tag" using primary key columns */
  tag_by_pk?: Maybe<Tag>;
  /** fetch data from the table: "task" */
  task: Array<Task>;
  /** fetch data from the table: "taskComment" */
  taskComment: Array<TaskComment>;
  /** fetch aggregated fields from the table: "taskComment" */
  taskComment_aggregate: TaskComment_Aggregate;
  /** fetch data from the table: "taskComment" using primary key columns */
  taskComment_by_pk?: Maybe<TaskComment>;
  /** fetch aggregated fields from the table: "task" */
  task_aggregate: Task_Aggregate;
  /** fetch data from the table: "task" using primary key columns */
  task_by_pk?: Maybe<Task>;
  /** fetch data from the table: "telehealthMeeting" */
  telehealthMeeting: Array<TelehealthMeeting>;
  /** fetch aggregated fields from the table: "telehealthMeeting" */
  telehealthMeeting_aggregate: TelehealthMeeting_Aggregate;
  /** fetch data from the table: "telehealthMeeting" using primary key columns */
  telehealthMeeting_by_pk?: Maybe<TelehealthMeeting>;
  /** fetch data from the table: "unusedAppointmentServiceConsumableType" */
  unusedAppointmentServiceConsumableType: Array<UnusedAppointmentServiceConsumableType>;
  /** fetch aggregated fields from the table: "unusedAppointmentServiceConsumableType" */
  unusedAppointmentServiceConsumableType_aggregate: UnusedAppointmentServiceConsumableType_Aggregate;
  /** fetch data from the table: "unusedAppointmentServiceConsumableType" using primary key columns */
  unusedAppointmentServiceConsumableType_by_pk?: Maybe<UnusedAppointmentServiceConsumableType>;
  /** fetch data from the table: "user" */
  user: Array<User>;
  /** fetch data from the table: "userInvite" */
  userInvite: Array<UserInvite>;
  /** fetch aggregated fields from the table: "userInvite" */
  userInvite_aggregate: UserInvite_Aggregate;
  /** fetch data from the table: "userInvite" using primary key columns */
  userInvite_by_pk?: Maybe<UserInvite>;
  /** fetch data from the table: "userOrganization" */
  userOrganization: Array<UserOrganization>;
  /** fetch aggregated fields from the table: "userOrganization" */
  userOrganization_aggregate: UserOrganization_Aggregate;
  /** fetch data from the table: "userOrganization" using primary key columns */
  userOrganization_by_pk?: Maybe<UserOrganization>;
  /** fetch data from the table: "userWorkspace" */
  userWorkspace: Array<UserWorkspace>;
  /** fetch aggregated fields from the table: "userWorkspace" */
  userWorkspace_aggregate: UserWorkspace_Aggregate;
  /** fetch data from the table: "userWorkspace" using primary key columns */
  userWorkspace_by_pk?: Maybe<UserWorkspace>;
  /** fetch aggregated fields from the table: "user" */
  user_aggregate: User_Aggregate;
  /** fetch data from the table: "user" using primary key columns */
  user_by_pk?: Maybe<User>;
  verifyManagerPin?: Maybe<SuccessAnswer>;
  /** An array relationship */
  vitals: Array<Vitals>;
  /** An aggregate relationship */
  vitals_aggregate: Vitals_Aggregate;
  /** fetch data from the table: "vitals" using primary key columns */
  vitals_by_pk?: Maybe<Vitals>;
  /** fetch data from the table: "workspace" */
  workspace: Array<Workspace>;
  /** fetch data from the table: "workspaceConfiguration" */
  workspaceConfiguration: Array<WorkspaceConfiguration>;
  /** fetch aggregated fields from the table: "workspaceConfiguration" */
  workspaceConfiguration_aggregate: WorkspaceConfiguration_Aggregate;
  /** fetch data from the table: "workspaceConfiguration" using primary key columns */
  workspaceConfiguration_by_pk?: Maybe<WorkspaceConfiguration>;
  /** fetch aggregated fields from the table: "workspace" */
  workspace_aggregate: Workspace_Aggregate;
  /** fetch data from the table: "workspace" using primary key columns */
  workspace_by_pk?: Maybe<Workspace>;
  /** fetch data from the table: "zdReview" */
  zdReview: Array<ZdReview>;
  /** fetch aggregated fields from the table: "zdReview" */
  zdReview_aggregate: ZdReview_Aggregate;
  /** fetch data from the table: "zdReview" using primary key columns */
  zdReview_by_pk?: Maybe<ZdReview>;
};


export type Query_RootActivityLogArgs = {
  distinct_on?: InputMaybe<Array<ActivityLog_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ActivityLog_Order_By>>;
  where?: InputMaybe<ActivityLog_Bool_Exp>;
};


export type Query_RootActivityLog_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ActivityLog_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ActivityLog_Order_By>>;
  where?: InputMaybe<ActivityLog_Bool_Exp>;
};


export type Query_RootActivityLog_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootAddressArgs = {
  distinct_on?: InputMaybe<Array<Address_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Address_Order_By>>;
  where?: InputMaybe<Address_Bool_Exp>;
};


export type Query_RootAddress_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Address_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Address_Order_By>>;
  where?: InputMaybe<Address_Bool_Exp>;
};


export type Query_RootAddress_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootAppointmentArgs = {
  distinct_on?: InputMaybe<Array<Appointment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Appointment_Order_By>>;
  where?: InputMaybe<Appointment_Bool_Exp>;
};


export type Query_RootAppointmentConsentArgs = {
  distinct_on?: InputMaybe<Array<AppointmentConsent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AppointmentConsent_Order_By>>;
  where?: InputMaybe<AppointmentConsent_Bool_Exp>;
};


export type Query_RootAppointmentConsent_AggregateArgs = {
  distinct_on?: InputMaybe<Array<AppointmentConsent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AppointmentConsent_Order_By>>;
  where?: InputMaybe<AppointmentConsent_Bool_Exp>;
};


export type Query_RootAppointmentConsent_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootAppointmentCustomFormArgs = {
  distinct_on?: InputMaybe<Array<AppointmentCustomForm_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AppointmentCustomForm_Order_By>>;
  where?: InputMaybe<AppointmentCustomForm_Bool_Exp>;
};


export type Query_RootAppointmentCustomForm_AggregateArgs = {
  distinct_on?: InputMaybe<Array<AppointmentCustomForm_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AppointmentCustomForm_Order_By>>;
  where?: InputMaybe<AppointmentCustomForm_Bool_Exp>;
};


export type Query_RootAppointmentCustomForm_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootAppointmentServiceArgs = {
  distinct_on?: InputMaybe<Array<AppointmentService_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AppointmentService_Order_By>>;
  where?: InputMaybe<AppointmentService_Bool_Exp>;
};


export type Query_RootAppointmentServiceConsumableArgs = {
  distinct_on?: InputMaybe<Array<AppointmentServiceConsumable_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AppointmentServiceConsumable_Order_By>>;
  where?: InputMaybe<AppointmentServiceConsumable_Bool_Exp>;
};


export type Query_RootAppointmentServiceConsumable_AggregateArgs = {
  distinct_on?: InputMaybe<Array<AppointmentServiceConsumable_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AppointmentServiceConsumable_Order_By>>;
  where?: InputMaybe<AppointmentServiceConsumable_Bool_Exp>;
};


export type Query_RootAppointmentServiceConsumable_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootAppointmentService_AggregateArgs = {
  distinct_on?: InputMaybe<Array<AppointmentService_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AppointmentService_Order_By>>;
  where?: InputMaybe<AppointmentService_Bool_Exp>;
};


export type Query_RootAppointmentService_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootAppointmentTypeArgs = {
  distinct_on?: InputMaybe<Array<AppointmentType_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AppointmentType_Order_By>>;
  where?: InputMaybe<AppointmentType_Bool_Exp>;
};


export type Query_RootAppointmentType_AggregateArgs = {
  distinct_on?: InputMaybe<Array<AppointmentType_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AppointmentType_Order_By>>;
  where?: InputMaybe<AppointmentType_Bool_Exp>;
};


export type Query_RootAppointmentType_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootAppointment_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Appointment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Appointment_Order_By>>;
  where?: InputMaybe<Appointment_Bool_Exp>;
};


export type Query_RootAppointment_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootAudienceArgs = {
  distinct_on?: InputMaybe<Array<Audience_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Audience_Order_By>>;
  where?: InputMaybe<Audience_Bool_Exp>;
};


export type Query_RootAudienceCountArgs = {
  ast?: InputMaybe<Scalars['jsonb']>;
  audienceId?: InputMaybe<Scalars['uuid']>;
};


export type Query_RootAudienceEmailArgs = {
  distinct_on?: InputMaybe<Array<AudienceEmail_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AudienceEmail_Order_By>>;
  where?: InputMaybe<AudienceEmail_Bool_Exp>;
};


export type Query_RootAudienceEmail_AggregateArgs = {
  distinct_on?: InputMaybe<Array<AudienceEmail_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AudienceEmail_Order_By>>;
  where?: InputMaybe<AudienceEmail_Bool_Exp>;
};


export type Query_RootAudienceEmail_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootAudienceResultsArgs = {
  ast: Scalars['jsonb'];
  limit?: InputMaybe<Scalars['Int']>;
};


export type Query_RootAudience_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Audience_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Audience_Order_By>>;
  where?: InputMaybe<Audience_Bool_Exp>;
};


export type Query_RootAudience_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootAuthProfileArgs = {
  distinct_on?: InputMaybe<Array<AuthProfile_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AuthProfile_Order_By>>;
  where?: InputMaybe<AuthProfile_Bool_Exp>;
};


export type Query_RootAuthProfile_AggregateArgs = {
  distinct_on?: InputMaybe<Array<AuthProfile_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AuthProfile_Order_By>>;
  where?: InputMaybe<AuthProfile_Bool_Exp>;
};


export type Query_RootAuthProfile_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootAvailableDevicesForServiceArgs = {
  day: Scalars['String'];
  endTime: Scalars['String'];
  locationId: Scalars['uuid'];
  serviceId: Scalars['uuid'];
  startTime: Scalars['String'];
};


export type Query_RootCalculateInvoiceTotalsArgs = {
  input: CalculateInvoiceTotalsInput;
};


export type Query_RootCheckCaptureArgs = {
  slug: Scalars['String'];
};


export type Query_RootCheckErxEligibilityArgs = {
  patientWorkspaceId: Scalars['uuid'];
};


export type Query_RootCheckFinixDeviceConnectionArgs = {
  finixDeviceId: Scalars['String'];
};


export type Query_RootClinicalMessageArgs = {
  distinct_on?: InputMaybe<Array<ClinicalMessage_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ClinicalMessage_Order_By>>;
  where?: InputMaybe<ClinicalMessage_Bool_Exp>;
};


export type Query_RootClinicalMessageStatusArgs = {
  distinct_on?: InputMaybe<Array<ClinicalMessageStatus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ClinicalMessageStatus_Order_By>>;
  where?: InputMaybe<ClinicalMessageStatus_Bool_Exp>;
};


export type Query_RootClinicalMessageStatus_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ClinicalMessageStatus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ClinicalMessageStatus_Order_By>>;
  where?: InputMaybe<ClinicalMessageStatus_Bool_Exp>;
};


export type Query_RootClinicalMessageStatus_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootClinicalMessage_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ClinicalMessage_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ClinicalMessage_Order_By>>;
  where?: InputMaybe<ClinicalMessage_Bool_Exp>;
};


export type Query_RootClinicalMessage_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootCompensationPolicyArgs = {
  distinct_on?: InputMaybe<Array<CompensationPolicy_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CompensationPolicy_Order_By>>;
  where?: InputMaybe<CompensationPolicy_Bool_Exp>;
};


export type Query_RootCompensationPolicyCommissionArgs = {
  distinct_on?: InputMaybe<Array<CompensationPolicyCommission_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CompensationPolicyCommission_Order_By>>;
  where?: InputMaybe<CompensationPolicyCommission_Bool_Exp>;
};


export type Query_RootCompensationPolicyCommission_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CompensationPolicyCommission_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CompensationPolicyCommission_Order_By>>;
  where?: InputMaybe<CompensationPolicyCommission_Bool_Exp>;
};


export type Query_RootCompensationPolicyCommission_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootCompensationPolicyTierArgs = {
  distinct_on?: InputMaybe<Array<CompensationPolicyTier_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CompensationPolicyTier_Order_By>>;
  where?: InputMaybe<CompensationPolicyTier_Bool_Exp>;
};


export type Query_RootCompensationPolicyTierCategoryArgs = {
  distinct_on?: InputMaybe<Array<CompensationPolicyTierCategory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CompensationPolicyTierCategory_Order_By>>;
  where?: InputMaybe<CompensationPolicyTierCategory_Bool_Exp>;
};


export type Query_RootCompensationPolicyTierCategory_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CompensationPolicyTierCategory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CompensationPolicyTierCategory_Order_By>>;
  where?: InputMaybe<CompensationPolicyTierCategory_Bool_Exp>;
};


export type Query_RootCompensationPolicyTierCategory_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootCompensationPolicyTierExemptionArgs = {
  distinct_on?: InputMaybe<Array<CompensationPolicyTierExemption_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CompensationPolicyTierExemption_Order_By>>;
  where?: InputMaybe<CompensationPolicyTierExemption_Bool_Exp>;
};


export type Query_RootCompensationPolicyTierExemption_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CompensationPolicyTierExemption_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CompensationPolicyTierExemption_Order_By>>;
  where?: InputMaybe<CompensationPolicyTierExemption_Bool_Exp>;
};


export type Query_RootCompensationPolicyTierExemption_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootCompensationPolicyTierInclusionArgs = {
  distinct_on?: InputMaybe<Array<CompensationPolicyTierInclusion_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CompensationPolicyTierInclusion_Order_By>>;
  where?: InputMaybe<CompensationPolicyTierInclusion_Bool_Exp>;
};


export type Query_RootCompensationPolicyTierInclusion_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CompensationPolicyTierInclusion_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CompensationPolicyTierInclusion_Order_By>>;
  where?: InputMaybe<CompensationPolicyTierInclusion_Bool_Exp>;
};


export type Query_RootCompensationPolicyTierInclusion_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootCompensationPolicyTier_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CompensationPolicyTier_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CompensationPolicyTier_Order_By>>;
  where?: InputMaybe<CompensationPolicyTier_Bool_Exp>;
};


export type Query_RootCompensationPolicyTier_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootCompensationPolicy_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CompensationPolicy_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CompensationPolicy_Order_By>>;
  where?: InputMaybe<CompensationPolicy_Bool_Exp>;
};


export type Query_RootCompensationPolicy_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootCompensationStrategyArgs = {
  distinct_on?: InputMaybe<Array<CompensationStrategy_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CompensationStrategy_Order_By>>;
  where?: InputMaybe<CompensationStrategy_Bool_Exp>;
};


export type Query_RootCompensationStrategyCommissionArgs = {
  distinct_on?: InputMaybe<Array<CompensationStrategyCommission_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CompensationStrategyCommission_Order_By>>;
  where?: InputMaybe<CompensationStrategyCommission_Bool_Exp>;
};


export type Query_RootCompensationStrategyCommission_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CompensationStrategyCommission_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CompensationStrategyCommission_Order_By>>;
  where?: InputMaybe<CompensationStrategyCommission_Bool_Exp>;
};


export type Query_RootCompensationStrategyCommission_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootCompensationStrategyTierArgs = {
  distinct_on?: InputMaybe<Array<CompensationStrategyTier_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CompensationStrategyTier_Order_By>>;
  where?: InputMaybe<CompensationStrategyTier_Bool_Exp>;
};


export type Query_RootCompensationStrategyTierCategoryArgs = {
  distinct_on?: InputMaybe<Array<CompensationStrategyTierCategory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CompensationStrategyTierCategory_Order_By>>;
  where?: InputMaybe<CompensationStrategyTierCategory_Bool_Exp>;
};


export type Query_RootCompensationStrategyTierCategory_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CompensationStrategyTierCategory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CompensationStrategyTierCategory_Order_By>>;
  where?: InputMaybe<CompensationStrategyTierCategory_Bool_Exp>;
};


export type Query_RootCompensationStrategyTierCategory_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootCompensationStrategyTierExemptionArgs = {
  distinct_on?: InputMaybe<Array<CompensationStrategyTierExemption_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CompensationStrategyTierExemption_Order_By>>;
  where?: InputMaybe<CompensationStrategyTierExemption_Bool_Exp>;
};


export type Query_RootCompensationStrategyTierExemption_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CompensationStrategyTierExemption_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CompensationStrategyTierExemption_Order_By>>;
  where?: InputMaybe<CompensationStrategyTierExemption_Bool_Exp>;
};


export type Query_RootCompensationStrategyTierExemption_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootCompensationStrategyTierInclusionArgs = {
  distinct_on?: InputMaybe<Array<CompensationStrategyTierInclusion_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CompensationStrategyTierInclusion_Order_By>>;
  where?: InputMaybe<CompensationStrategyTierInclusion_Bool_Exp>;
};


export type Query_RootCompensationStrategyTierInclusion_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CompensationStrategyTierInclusion_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CompensationStrategyTierInclusion_Order_By>>;
  where?: InputMaybe<CompensationStrategyTierInclusion_Bool_Exp>;
};


export type Query_RootCompensationStrategyTierInclusion_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootCompensationStrategyTier_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CompensationStrategyTier_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CompensationStrategyTier_Order_By>>;
  where?: InputMaybe<CompensationStrategyTier_Bool_Exp>;
};


export type Query_RootCompensationStrategyTier_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootCompensationStrategy_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CompensationStrategy_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CompensationStrategy_Order_By>>;
  where?: InputMaybe<CompensationStrategy_Bool_Exp>;
};


export type Query_RootCompensationStrategy_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootCompensationTotalsArgs = {
  from?: InputMaybe<Scalars['date']>;
  until?: InputMaybe<Scalars['date']>;
  userIds?: InputMaybe<Array<Scalars['uuid']>>;
};


export type Query_RootConsentArgs = {
  distinct_on?: InputMaybe<Array<Consent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Consent_Order_By>>;
  where?: InputMaybe<Consent_Bool_Exp>;
};


export type Query_RootConsent_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Consent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Consent_Order_By>>;
  where?: InputMaybe<Consent_Bool_Exp>;
};


export type Query_RootConsent_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootConsumableArgs = {
  distinct_on?: InputMaybe<Array<Consumable_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Consumable_Order_By>>;
  where?: InputMaybe<Consumable_Bool_Exp>;
};


export type Query_RootConsumableBrandArgs = {
  distinct_on?: InputMaybe<Array<ConsumableBrand_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ConsumableBrand_Order_By>>;
  where?: InputMaybe<ConsumableBrand_Bool_Exp>;
};


export type Query_RootConsumableBrand_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ConsumableBrand_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ConsumableBrand_Order_By>>;
  where?: InputMaybe<ConsumableBrand_Bool_Exp>;
};


export type Query_RootConsumableBrand_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootConsumableSubtypeArgs = {
  distinct_on?: InputMaybe<Array<ConsumableSubtype_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ConsumableSubtype_Order_By>>;
  where?: InputMaybe<ConsumableSubtype_Bool_Exp>;
};


export type Query_RootConsumableSubtype_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ConsumableSubtype_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ConsumableSubtype_Order_By>>;
  where?: InputMaybe<ConsumableSubtype_Bool_Exp>;
};


export type Query_RootConsumableSubtype_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootConsumableTypeArgs = {
  distinct_on?: InputMaybe<Array<ConsumableType_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ConsumableType_Order_By>>;
  where?: InputMaybe<ConsumableType_Bool_Exp>;
};


export type Query_RootConsumableTypeConsumableSubtypeArgs = {
  distinct_on?: InputMaybe<Array<ConsumableTypeConsumableSubtype_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ConsumableTypeConsumableSubtype_Order_By>>;
  where?: InputMaybe<ConsumableTypeConsumableSubtype_Bool_Exp>;
};


export type Query_RootConsumableTypeConsumableSubtype_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ConsumableTypeConsumableSubtype_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ConsumableTypeConsumableSubtype_Order_By>>;
  where?: InputMaybe<ConsumableTypeConsumableSubtype_Bool_Exp>;
};


export type Query_RootConsumableTypeConsumableSubtype_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootConsumableType_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ConsumableType_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ConsumableType_Order_By>>;
  where?: InputMaybe<ConsumableType_Bool_Exp>;
};


export type Query_RootConsumableType_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootConsumable_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Consumable_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Consumable_Order_By>>;
  where?: InputMaybe<Consumable_Bool_Exp>;
};


export type Query_RootConsumable_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootCouponArgs = {
  distinct_on?: InputMaybe<Array<Coupon_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Coupon_Order_By>>;
  where?: InputMaybe<Coupon_Bool_Exp>;
};


export type Query_RootCouponConfigurationArgs = {
  distinct_on?: InputMaybe<Array<CouponConfiguration_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CouponConfiguration_Order_By>>;
  where?: InputMaybe<CouponConfiguration_Bool_Exp>;
};


export type Query_RootCouponConfiguration_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CouponConfiguration_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CouponConfiguration_Order_By>>;
  where?: InputMaybe<CouponConfiguration_Bool_Exp>;
};


export type Query_RootCouponConfiguration_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootCouponScopeArgs = {
  distinct_on?: InputMaybe<Array<CouponScope_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CouponScope_Order_By>>;
  where?: InputMaybe<CouponScope_Bool_Exp>;
};


export type Query_RootCouponScope_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CouponScope_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CouponScope_Order_By>>;
  where?: InputMaybe<CouponScope_Bool_Exp>;
};


export type Query_RootCouponScope_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootCouponTypeArgs = {
  distinct_on?: InputMaybe<Array<CouponType_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CouponType_Order_By>>;
  where?: InputMaybe<CouponType_Bool_Exp>;
};


export type Query_RootCouponType_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CouponType_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CouponType_Order_By>>;
  where?: InputMaybe<CouponType_Bool_Exp>;
};


export type Query_RootCouponType_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootCoupon_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Coupon_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Coupon_Order_By>>;
  where?: InputMaybe<Coupon_Bool_Exp>;
};


export type Query_RootCoupon_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootCustomFormArgs = {
  distinct_on?: InputMaybe<Array<CustomForm_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CustomForm_Order_By>>;
  where?: InputMaybe<CustomForm_Bool_Exp>;
};


export type Query_RootCustomFormFieldArgs = {
  distinct_on?: InputMaybe<Array<CustomFormField_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CustomFormField_Order_By>>;
  where?: InputMaybe<CustomFormField_Bool_Exp>;
};


export type Query_RootCustomFormFieldCategoryArgs = {
  distinct_on?: InputMaybe<Array<CustomFormFieldCategory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CustomFormFieldCategory_Order_By>>;
  where?: InputMaybe<CustomFormFieldCategory_Bool_Exp>;
};


export type Query_RootCustomFormFieldCategory_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CustomFormFieldCategory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CustomFormFieldCategory_Order_By>>;
  where?: InputMaybe<CustomFormFieldCategory_Bool_Exp>;
};


export type Query_RootCustomFormFieldCategory_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootCustomFormFieldResponseArgs = {
  distinct_on?: InputMaybe<Array<CustomFormFieldResponse_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CustomFormFieldResponse_Order_By>>;
  where?: InputMaybe<CustomFormFieldResponse_Bool_Exp>;
};


export type Query_RootCustomFormFieldResponse_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CustomFormFieldResponse_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CustomFormFieldResponse_Order_By>>;
  where?: InputMaybe<CustomFormFieldResponse_Bool_Exp>;
};


export type Query_RootCustomFormFieldResponse_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootCustomFormFieldTypeArgs = {
  distinct_on?: InputMaybe<Array<CustomFormFieldType_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CustomFormFieldType_Order_By>>;
  where?: InputMaybe<CustomFormFieldType_Bool_Exp>;
};


export type Query_RootCustomFormFieldType_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CustomFormFieldType_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CustomFormFieldType_Order_By>>;
  where?: InputMaybe<CustomFormFieldType_Bool_Exp>;
};


export type Query_RootCustomFormFieldType_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootCustomFormField_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CustomFormField_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CustomFormField_Order_By>>;
  where?: InputMaybe<CustomFormField_Bool_Exp>;
};


export type Query_RootCustomFormField_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootCustomFormOptionArgs = {
  distinct_on?: InputMaybe<Array<CustomFormOption_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CustomFormOption_Order_By>>;
  where?: InputMaybe<CustomFormOption_Bool_Exp>;
};


export type Query_RootCustomFormOption_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CustomFormOption_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CustomFormOption_Order_By>>;
  where?: InputMaybe<CustomFormOption_Bool_Exp>;
};


export type Query_RootCustomFormOption_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootCustomFormReplicatedFieldArgs = {
  distinct_on?: InputMaybe<Array<CustomFormReplicatedField_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CustomFormReplicatedField_Order_By>>;
  where?: InputMaybe<CustomFormReplicatedField_Bool_Exp>;
};


export type Query_RootCustomFormReplicatedField_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CustomFormReplicatedField_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CustomFormReplicatedField_Order_By>>;
  where?: InputMaybe<CustomFormReplicatedField_Bool_Exp>;
};


export type Query_RootCustomFormReplicatedField_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootCustomFormReplicatedRowArgs = {
  distinct_on?: InputMaybe<Array<CustomFormReplicatedRow_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CustomFormReplicatedRow_Order_By>>;
  where?: InputMaybe<CustomFormReplicatedRow_Bool_Exp>;
};


export type Query_RootCustomFormReplicatedRow_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CustomFormReplicatedRow_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CustomFormReplicatedRow_Order_By>>;
  where?: InputMaybe<CustomFormReplicatedRow_Bool_Exp>;
};


export type Query_RootCustomFormReplicatedRow_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootCustomFormResponseArgs = {
  distinct_on?: InputMaybe<Array<CustomFormResponse_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CustomFormResponse_Order_By>>;
  where?: InputMaybe<CustomFormResponse_Bool_Exp>;
};


export type Query_RootCustomFormResponse_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CustomFormResponse_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CustomFormResponse_Order_By>>;
  where?: InputMaybe<CustomFormResponse_Bool_Exp>;
};


export type Query_RootCustomFormResponse_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootCustomFormRowArgs = {
  distinct_on?: InputMaybe<Array<CustomFormRow_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CustomFormRow_Order_By>>;
  where?: InputMaybe<CustomFormRow_Bool_Exp>;
};


export type Query_RootCustomFormRow_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CustomFormRow_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CustomFormRow_Order_By>>;
  where?: InputMaybe<CustomFormRow_Bool_Exp>;
};


export type Query_RootCustomFormRow_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootCustomForm_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CustomForm_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CustomForm_Order_By>>;
  where?: InputMaybe<CustomForm_Bool_Exp>;
};


export type Query_RootCustomForm_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootCustomQuestionArgs = {
  distinct_on?: InputMaybe<Array<CustomQuestion_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CustomQuestion_Order_By>>;
  where?: InputMaybe<CustomQuestion_Bool_Exp>;
};


export type Query_RootCustomQuestionOptionArgs = {
  distinct_on?: InputMaybe<Array<CustomQuestionOption_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CustomQuestionOption_Order_By>>;
  where?: InputMaybe<CustomQuestionOption_Bool_Exp>;
};


export type Query_RootCustomQuestionOption_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CustomQuestionOption_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CustomQuestionOption_Order_By>>;
  where?: InputMaybe<CustomQuestionOption_Bool_Exp>;
};


export type Query_RootCustomQuestionOption_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootCustomQuestion_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CustomQuestion_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CustomQuestion_Order_By>>;
  where?: InputMaybe<CustomQuestion_Bool_Exp>;
};


export type Query_RootCustomQuestion_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootDegreeDesignationArgs = {
  distinct_on?: InputMaybe<Array<DegreeDesignation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<DegreeDesignation_Order_By>>;
  where?: InputMaybe<DegreeDesignation_Bool_Exp>;
};


export type Query_RootDegreeDesignation_AggregateArgs = {
  distinct_on?: InputMaybe<Array<DegreeDesignation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<DegreeDesignation_Order_By>>;
  where?: InputMaybe<DegreeDesignation_Bool_Exp>;
};


export type Query_RootDegreeDesignation_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootDepositInvoiceLineItemArgs = {
  distinct_on?: InputMaybe<Array<DepositInvoiceLineItem_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<DepositInvoiceLineItem_Order_By>>;
  where?: InputMaybe<DepositInvoiceLineItem_Bool_Exp>;
};


export type Query_RootDepositInvoiceLineItem_AggregateArgs = {
  distinct_on?: InputMaybe<Array<DepositInvoiceLineItem_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<DepositInvoiceLineItem_Order_By>>;
  where?: InputMaybe<DepositInvoiceLineItem_Bool_Exp>;
};


export type Query_RootDepositInvoiceLineItem_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootDeviceArgs = {
  distinct_on?: InputMaybe<Array<Device_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Device_Order_By>>;
  where?: InputMaybe<Device_Bool_Exp>;
};


export type Query_RootDeviceTypeArgs = {
  distinct_on?: InputMaybe<Array<DeviceType_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<DeviceType_Order_By>>;
  where?: InputMaybe<DeviceType_Bool_Exp>;
};


export type Query_RootDeviceType_AggregateArgs = {
  distinct_on?: InputMaybe<Array<DeviceType_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<DeviceType_Order_By>>;
  where?: InputMaybe<DeviceType_Bool_Exp>;
};


export type Query_RootDeviceType_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootDevice_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Device_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Device_Order_By>>;
  where?: InputMaybe<Device_Bool_Exp>;
};


export type Query_RootDevice_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootDiscountInvoiceLineItemArgs = {
  distinct_on?: InputMaybe<Array<DiscountInvoiceLineItem_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<DiscountInvoiceLineItem_Order_By>>;
  where?: InputMaybe<DiscountInvoiceLineItem_Bool_Exp>;
};


export type Query_RootDiscountInvoiceLineItem_AggregateArgs = {
  distinct_on?: InputMaybe<Array<DiscountInvoiceLineItem_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<DiscountInvoiceLineItem_Order_By>>;
  where?: InputMaybe<DiscountInvoiceLineItem_Bool_Exp>;
};


export type Query_RootDiscountInvoiceLineItem_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootDocumentArgs = {
  distinct_on?: InputMaybe<Array<Document_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Document_Order_By>>;
  where?: InputMaybe<Document_Bool_Exp>;
};


export type Query_RootDocumentTagArgs = {
  distinct_on?: InputMaybe<Array<DocumentTag_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<DocumentTag_Order_By>>;
  where?: InputMaybe<DocumentTag_Bool_Exp>;
};


export type Query_RootDocumentTag_AggregateArgs = {
  distinct_on?: InputMaybe<Array<DocumentTag_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<DocumentTag_Order_By>>;
  where?: InputMaybe<DocumentTag_Bool_Exp>;
};


export type Query_RootDocumentTag_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootDocument_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Document_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Document_Order_By>>;
  where?: InputMaybe<Document_Bool_Exp>;
};


export type Query_RootDocument_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootDownloadImageArgs = {
  input: EditImageInput;
};


export type Query_RootDrChronoCallbackArgs = {
  code: Scalars['String'];
};


export type Query_RootEmailCampaignLogArgs = {
  distinct_on?: InputMaybe<Array<EmailCampaignLog_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EmailCampaignLog_Order_By>>;
  where?: InputMaybe<EmailCampaignLog_Bool_Exp>;
};


export type Query_RootEmailCampaignLog_AggregateArgs = {
  distinct_on?: InputMaybe<Array<EmailCampaignLog_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EmailCampaignLog_Order_By>>;
  where?: InputMaybe<EmailCampaignLog_Bool_Exp>;
};


export type Query_RootEmailCampaignLog_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootEmailCampaignMetricsArgs = {
  distinct_on?: InputMaybe<Array<EmailCampaignMetrics_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EmailCampaignMetrics_Order_By>>;
  where?: InputMaybe<EmailCampaignMetrics_Bool_Exp>;
};


export type Query_RootEmailCampaignMetrics_AggregateArgs = {
  distinct_on?: InputMaybe<Array<EmailCampaignMetrics_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EmailCampaignMetrics_Order_By>>;
  where?: InputMaybe<EmailCampaignMetrics_Bool_Exp>;
};


export type Query_RootEmailTemplateArgs = {
  distinct_on?: InputMaybe<Array<EmailTemplate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EmailTemplate_Order_By>>;
  where?: InputMaybe<EmailTemplate_Bool_Exp>;
};


export type Query_RootEmailTemplatePatientArgs = {
  distinct_on?: InputMaybe<Array<EmailTemplatePatient_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EmailTemplatePatient_Order_By>>;
  where?: InputMaybe<EmailTemplatePatient_Bool_Exp>;
};


export type Query_RootEmailTemplatePatient_AggregateArgs = {
  distinct_on?: InputMaybe<Array<EmailTemplatePatient_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EmailTemplatePatient_Order_By>>;
  where?: InputMaybe<EmailTemplatePatient_Bool_Exp>;
};


export type Query_RootEmailTemplatePatient_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootEmailTemplateSendQueueArgs = {
  distinct_on?: InputMaybe<Array<EmailTemplateSendQueue_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EmailTemplateSendQueue_Order_By>>;
  where?: InputMaybe<EmailTemplateSendQueue_Bool_Exp>;
};


export type Query_RootEmailTemplateSendQueue_AggregateArgs = {
  distinct_on?: InputMaybe<Array<EmailTemplateSendQueue_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EmailTemplateSendQueue_Order_By>>;
  where?: InputMaybe<EmailTemplateSendQueue_Bool_Exp>;
};


export type Query_RootEmailTemplateSendQueue_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootEmailTemplate_AggregateArgs = {
  distinct_on?: InputMaybe<Array<EmailTemplate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EmailTemplate_Order_By>>;
  where?: InputMaybe<EmailTemplate_Bool_Exp>;
};


export type Query_RootEmailTemplate_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootExternalLocationArgs = {
  distinct_on?: InputMaybe<Array<ExternalLocation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ExternalLocation_Order_By>>;
  where?: InputMaybe<ExternalLocation_Bool_Exp>;
};


export type Query_RootExternalLocation_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ExternalLocation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ExternalLocation_Order_By>>;
  where?: InputMaybe<ExternalLocation_Bool_Exp>;
};


export type Query_RootExternalLocation_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootExternalProviderArgs = {
  distinct_on?: InputMaybe<Array<ExternalProvider_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ExternalProvider_Order_By>>;
  where?: InputMaybe<ExternalProvider_Bool_Exp>;
};


export type Query_RootExternalProvider_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ExternalProvider_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ExternalProvider_Order_By>>;
  where?: InputMaybe<ExternalProvider_Bool_Exp>;
};


export type Query_RootExternalProvider_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootExternalReviewArgs = {
  distinct_on?: InputMaybe<Array<ExternalReview_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ExternalReview_Order_By>>;
  where?: InputMaybe<ExternalReview_Bool_Exp>;
};


export type Query_RootExternalReview_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ExternalReview_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ExternalReview_Order_By>>;
  where?: InputMaybe<ExternalReview_Bool_Exp>;
};


export type Query_RootExternalReview_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootFeeArgs = {
  distinct_on?: InputMaybe<Array<Fee_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Fee_Order_By>>;
  where?: InputMaybe<Fee_Bool_Exp>;
};


export type Query_RootFeeInvoiceLineItemArgs = {
  distinct_on?: InputMaybe<Array<FeeInvoiceLineItem_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<FeeInvoiceLineItem_Order_By>>;
  where?: InputMaybe<FeeInvoiceLineItem_Bool_Exp>;
};


export type Query_RootFeeInvoiceLineItem_AggregateArgs = {
  distinct_on?: InputMaybe<Array<FeeInvoiceLineItem_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<FeeInvoiceLineItem_Order_By>>;
  where?: InputMaybe<FeeInvoiceLineItem_Bool_Exp>;
};


export type Query_RootFeeInvoiceLineItem_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootFee_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Fee_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Fee_Order_By>>;
  where?: InputMaybe<Fee_Bool_Exp>;
};


export type Query_RootFee_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootFetchProviderAcrossReviewPlatformsArgs = {
  categories?: InputMaybe<Scalars['String']>;
  coordinates?: InputMaybe<Coordinates>;
  providerLocation: Scalars['String'];
  providerName: Scalars['String'];
};


export type Query_RootFinixTerminalArgs = {
  distinct_on?: InputMaybe<Array<FinixTerminal_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<FinixTerminal_Order_By>>;
  where?: InputMaybe<FinixTerminal_Bool_Exp>;
};


export type Query_RootFinixTerminal_AggregateArgs = {
  distinct_on?: InputMaybe<Array<FinixTerminal_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<FinixTerminal_Order_By>>;
  where?: InputMaybe<FinixTerminal_Bool_Exp>;
};


export type Query_RootFinixTerminal_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootFlowsheetArgs = {
  distinct_on?: InputMaybe<Array<Flowsheet_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Flowsheet_Order_By>>;
  where?: InputMaybe<Flowsheet_Bool_Exp>;
};


export type Query_RootFlowsheetParameterArgs = {
  distinct_on?: InputMaybe<Array<FlowsheetParameter_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<FlowsheetParameter_Order_By>>;
  where?: InputMaybe<FlowsheetParameter_Bool_Exp>;
};


export type Query_RootFlowsheetParameterFlowsheetArgs = {
  distinct_on?: InputMaybe<Array<FlowsheetParameterFlowsheet_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<FlowsheetParameterFlowsheet_Order_By>>;
  where?: InputMaybe<FlowsheetParameterFlowsheet_Bool_Exp>;
};


export type Query_RootFlowsheetParameterFlowsheet_AggregateArgs = {
  distinct_on?: InputMaybe<Array<FlowsheetParameterFlowsheet_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<FlowsheetParameterFlowsheet_Order_By>>;
  where?: InputMaybe<FlowsheetParameterFlowsheet_Bool_Exp>;
};


export type Query_RootFlowsheetParameterFlowsheet_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootFlowsheetParameterGroupArgs = {
  distinct_on?: InputMaybe<Array<FlowsheetParameterGroup_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<FlowsheetParameterGroup_Order_By>>;
  where?: InputMaybe<FlowsheetParameterGroup_Bool_Exp>;
};


export type Query_RootFlowsheetParameterGroup_AggregateArgs = {
  distinct_on?: InputMaybe<Array<FlowsheetParameterGroup_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<FlowsheetParameterGroup_Order_By>>;
  where?: InputMaybe<FlowsheetParameterGroup_Bool_Exp>;
};


export type Query_RootFlowsheetParameterGroup_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootFlowsheetParameter_AggregateArgs = {
  distinct_on?: InputMaybe<Array<FlowsheetParameter_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<FlowsheetParameter_Order_By>>;
  where?: InputMaybe<FlowsheetParameter_Bool_Exp>;
};


export type Query_RootFlowsheetParameter_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootFlowsheet_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Flowsheet_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Flowsheet_Order_By>>;
  where?: InputMaybe<Flowsheet_Bool_Exp>;
};


export type Query_RootFlowsheet_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootGenPatientAuthLinkArgs = {
  copy?: InputMaybe<AuthLinkCopy>;
  locationId?: InputMaybe<Scalars['String']>;
  patientId: Scalars['String'];
  shouldSendToPatient?: InputMaybe<Scalars['Boolean']>;
  to?: InputMaybe<Scalars['String']>;
};


export type Query_RootGenericInvoiceLineItemArgs = {
  distinct_on?: InputMaybe<Array<GenericInvoiceLineItem_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GenericInvoiceLineItem_Order_By>>;
  where?: InputMaybe<GenericInvoiceLineItem_Bool_Exp>;
};


export type Query_RootGenericInvoiceLineItemDiscountArgs = {
  distinct_on?: InputMaybe<Array<GenericInvoiceLineItemDiscount_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GenericInvoiceLineItemDiscount_Order_By>>;
  where?: InputMaybe<GenericInvoiceLineItemDiscount_Bool_Exp>;
};


export type Query_RootGenericInvoiceLineItemDiscount_AggregateArgs = {
  distinct_on?: InputMaybe<Array<GenericInvoiceLineItemDiscount_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GenericInvoiceLineItemDiscount_Order_By>>;
  where?: InputMaybe<GenericInvoiceLineItemDiscount_Bool_Exp>;
};


export type Query_RootGenericInvoiceLineItemDiscount_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootGenericInvoiceLineItem_AggregateArgs = {
  distinct_on?: InputMaybe<Array<GenericInvoiceLineItem_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GenericInvoiceLineItem_Order_By>>;
  where?: InputMaybe<GenericInvoiceLineItem_Bool_Exp>;
};


export type Query_RootGenericInvoiceLineItem_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootGetActiveInvoiceTotalsArgs = {
  invoiceId: Scalars['uuid'];
  options?: InputMaybe<GetActiveInvoiceTotalsOptions>;
};


export type Query_RootGetAppointmentCanCollectNoShowFeeArgs = {
  id: Scalars['uuid'];
};


export type Query_RootGetAppointmentConflictsArgs = {
  appointmentId: Scalars['uuid'];
};


export type Query_RootGetAppointmentConsentMetadataArgs = {
  appointmentConsentId: Scalars['uuid'];
};


export type Query_RootGetAppointmentConsumablesDataArgs = {
  id: Scalars['uuid'];
};


export type Query_RootGetAppointmentCustomFormMetadataArgs = {
  appointmentCustomFormId: Scalars['uuid'];
};


export type Query_RootGetAppointmentDepositAmountArgs = {
  appointmentId: Scalars['uuid'];
};


export type Query_RootGetAppointmentMetadataArgs = {
  appointmentId: Scalars['uuid'];
};


export type Query_RootGetAppointmentServiceConsumablesStatusArgs = {
  id: Scalars['uuid'];
};


export type Query_RootGetAuthUrlArgs = {
  platform: AuthPlatform;
};


export type Query_RootGetAvailabilityForMultipleServicesArgs = {
  day: Scalars['String'];
  isDayAvailableCheck?: InputMaybe<Scalars['Boolean']>;
  locationId: Scalars['uuid'];
  numberOfDays?: InputMaybe<Scalars['Int']>;
  providerId?: InputMaybe<Scalars['uuid']>;
  serviceIds: Array<InputMaybe<Scalars['uuid']>>;
};


export type Query_RootGetAvailabilityForServiceArgs = {
  day: Scalars['String'];
  locationId: Scalars['uuid'];
  numberOfDays?: InputMaybe<Scalars['Int']>;
  providerId?: InputMaybe<Scalars['uuid']>;
  serviceIds: Array<InputMaybe<Scalars['uuid']>>;
};


export type Query_RootGetCloudflareCustomHostnameArgs = {
  customHostnameId: Scalars['String'];
};


export type Query_RootGetDocumentFileArgs = {
  filePath: Scalars['String'];
};


export type Query_RootGetEmailTemplateScreenshotArgs = {
  id: Scalars['uuid'];
};


export type Query_RootGetExternalVendorWebhookTokenArgs = {
  vendorName: Scalars['String'];
  workspaceId: Scalars['uuid'];
};


export type Query_RootGetInventoryArgs = {
  input: GetInventoryInput;
};


export type Query_RootGetInventoryAggregateArgs = {
  input: GetInventoryAggregateInput;
};


export type Query_RootGetInventoryTransactionAccountArgs = {
  accountId: Scalars['uuid'];
  accountType: Scalars['String'];
};


export type Query_RootGetInventoryTransactionHistoryArgs = {
  initialTransaction: InitialTransactionInput;
};


export type Query_RootGetInvoiceReceiptPaymentsArgs = {
  id: Scalars['uuid'];
};


export type Query_RootGetInvoiceTotalsArgs = {
  applyBSADiscount?: InputMaybe<Scalars['Boolean']>;
  couponIds?: InputMaybe<Array<InputMaybe<Scalars['uuid']>>>;
  giftCards?: InputMaybe<Array<InputMaybe<GiftCardItemInput>>>;
  gratuity?: InputMaybe<Scalars['Int']>;
  invoiceId?: InputMaybe<Scalars['uuid']>;
  locationId?: InputMaybe<Scalars['uuid']>;
  membershipIds?: InputMaybe<Array<InputMaybe<Scalars['uuid']>>>;
  packages?: InputMaybe<Scalars['jsonb']>;
  patientId?: InputMaybe<Scalars['uuid']>;
  services: Scalars['jsonb'];
};


export type Query_RootGetKnockUserTokenArgs = {
  id: Scalars['uuid'];
};


export type Query_RootGetMediaVersionsArgs = {
  photoId: Scalars['uuid'];
};


export type Query_RootGetMetabaseEmbedUrlArgs = {
  params?: InputMaybe<Scalars['String']>;
  reportId: Scalars['String'];
  workspaceId: Scalars['uuid'];
};


export type Query_RootGetNextSubscriptionPaymentDateArgs = {
  id: Scalars['uuid'];
  membershipId: Scalars['uuid'];
  updatedAt: Scalars['String'];
};


export type Query_RootGetPatientAttributesArgs = {
  patientId: Scalars['uuid'];
};


export type Query_RootGetPatientProductHistoryArgs = {
  patientId: Scalars['uuid'];
};


export type Query_RootGetPatientServiceHistoryArgs = {
  patientId: Scalars['uuid'];
};


export type Query_RootGetPatientWalletTransactionSourceArgs = {
  sourceId: Scalars['uuid'];
  sourceType: Scalars['String'];
};


export type Query_RootGetPaymentReceiptArgs = {
  id: Scalars['uuid'];
};


export type Query_RootGetPayrixTransactionArgs = {
  transactionId: Scalars['String'];
};


export type Query_RootGetPrescriptionsUrlArgs = {
  clinicId?: InputMaybe<Scalars['Int']>;
  dosespotPatientId?: InputMaybe<Scalars['Int']>;
  locationProviderId: Scalars['String'];
};


export type Query_RootGetProductDetailsPageArgs = {
  id: Scalars['uuid'];
};


export type Query_RootGetProviderAvailabilityForLocationArgs = {
  day: Scalars['String'];
  locationId: Scalars['String'];
  numberOfDays?: InputMaybe<Scalars['Int']>;
  providerId?: InputMaybe<Scalars['String']>;
  serviceIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type Query_RootGetRsProviderReviewsArgs = {
  count: Scalars['Int'];
  where: GetRsProviderReviewsInput;
};


export type Query_RootGetResolvedAppointmentConsentsArgs = {
  id: Scalars['uuid'];
  patientId: Scalars['uuid'];
  workspaceId: Scalars['uuid'];
};


export type Query_RootGetResolvedAppointmentCustomFormsArgs = {
  id: Scalars['uuid'];
  workspaceId: Scalars['uuid'];
};


export type Query_RootGetSignatureFileArgs = {
  filePath: Scalars['String'];
};


export type Query_RootGetSignedUploadUrlArgs = {
  filePath?: InputMaybe<Scalars['String']>;
  fileType: Scalars['String'];
  storageType?: InputMaybe<StorageType>;
};


export type Query_RootGetSimplifiedInventoryTransactionsArgs = {
  input: GetSimplifiedInventoryTransactionsInput;
};


export type Query_RootGetTwilioChatTokenArgs = {
  identityOverride?: InputMaybe<Scalars['String']>;
  workspaceId: Scalars['uuid'];
};


export type Query_RootGetValidatedCouponsForInvoiceArgs = {
  locationId: Scalars['uuid'];
  patientId?: InputMaybe<Scalars['uuid']>;
  serviceIds: Array<Scalars['uuid']>;
};


export type Query_RootGetValidatedCouponsForServiceArgs = {
  locationId: Scalars['uuid'];
  patientId?: InputMaybe<Scalars['uuid']>;
  serviceId: Scalars['uuid'];
};


export type Query_RootGetZdProviderReviewsArgs = {
  count: Scalars['Int'];
  where: GetZdProviderReviewsInput;
};


export type Query_RootGet_FileArgs = {
  filePath?: InputMaybe<Scalars['String']>;
};


export type Query_RootGiftCardArgs = {
  distinct_on?: InputMaybe<Array<GiftCard_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GiftCard_Order_By>>;
  where?: InputMaybe<GiftCard_Bool_Exp>;
};


export type Query_RootGiftCard_AggregateArgs = {
  distinct_on?: InputMaybe<Array<GiftCard_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GiftCard_Order_By>>;
  where?: InputMaybe<GiftCard_Bool_Exp>;
};


export type Query_RootGiftCard_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootGoogleAccountArgs = {
  distinct_on?: InputMaybe<Array<GoogleAccount_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GoogleAccount_Order_By>>;
  where?: InputMaybe<GoogleAccount_Bool_Exp>;
};


export type Query_RootGoogleAccount_AggregateArgs = {
  distinct_on?: InputMaybe<Array<GoogleAccount_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GoogleAccount_Order_By>>;
  where?: InputMaybe<GoogleAccount_Bool_Exp>;
};


export type Query_RootGoogleAccount_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootGoogleLocationArgs = {
  distinct_on?: InputMaybe<Array<GoogleLocation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GoogleLocation_Order_By>>;
  where?: InputMaybe<GoogleLocation_Bool_Exp>;
};


export type Query_RootGoogleLocation_AggregateArgs = {
  distinct_on?: InputMaybe<Array<GoogleLocation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GoogleLocation_Order_By>>;
  where?: InputMaybe<GoogleLocation_Bool_Exp>;
};


export type Query_RootGoogleLocation_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootGoogleLocationsArgs = {
  accountName: Scalars['String'];
};


export type Query_RootGoogleReviewArgs = {
  distinct_on?: InputMaybe<Array<GoogleReview_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GoogleReview_Order_By>>;
  where?: InputMaybe<GoogleReview_Bool_Exp>;
};


export type Query_RootGoogleReview_AggregateArgs = {
  distinct_on?: InputMaybe<Array<GoogleReview_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GoogleReview_Order_By>>;
  where?: InputMaybe<GoogleReview_Bool_Exp>;
};


export type Query_RootGoogleReview_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootGoogleReviewsArgs = {
  accountName: Scalars['String'];
  locationName: Scalars['String'];
};


export type Query_RootImportJobArgs = {
  distinct_on?: InputMaybe<Array<ImportJob_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ImportJob_Order_By>>;
  where?: InputMaybe<ImportJob_Bool_Exp>;
};


export type Query_RootImportJob_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ImportJob_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ImportJob_Order_By>>;
  where?: InputMaybe<ImportJob_Bool_Exp>;
};


export type Query_RootImportJob_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootImportMetadataArgs = {
  distinct_on?: InputMaybe<Array<ImportMetadata_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ImportMetadata_Order_By>>;
  where?: InputMaybe<ImportMetadata_Bool_Exp>;
};


export type Query_RootImportMetadata_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ImportMetadata_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ImportMetadata_Order_By>>;
  where?: InputMaybe<ImportMetadata_Bool_Exp>;
};


export type Query_RootImportMetadata_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootIncompatibleServicesArgs = {
  distinct_on?: InputMaybe<Array<IncompatibleServices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IncompatibleServices_Order_By>>;
  where?: InputMaybe<IncompatibleServices_Bool_Exp>;
};


export type Query_RootIncompatibleServices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<IncompatibleServices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IncompatibleServices_Order_By>>;
  where?: InputMaybe<IncompatibleServices_Bool_Exp>;
};


export type Query_RootIncompatibleServices_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootInternalAppointmentProviderArgs = {
  distinct_on?: InputMaybe<Array<InternalAppointmentProvider_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<InternalAppointmentProvider_Order_By>>;
  where?: InputMaybe<InternalAppointmentProvider_Bool_Exp>;
};


export type Query_RootInternalAppointmentProvider_AggregateArgs = {
  distinct_on?: InputMaybe<Array<InternalAppointmentProvider_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<InternalAppointmentProvider_Order_By>>;
  where?: InputMaybe<InternalAppointmentProvider_Bool_Exp>;
};


export type Query_RootInternalAppointmentProvider_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootInventoryTransactionArgs = {
  distinct_on?: InputMaybe<Array<InventoryTransaction_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<InventoryTransaction_Order_By>>;
  where?: InputMaybe<InventoryTransaction_Bool_Exp>;
};


export type Query_RootInventoryTransaction_AggregateArgs = {
  distinct_on?: InputMaybe<Array<InventoryTransaction_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<InventoryTransaction_Order_By>>;
  where?: InputMaybe<InventoryTransaction_Bool_Exp>;
};


export type Query_RootInventoryTransaction_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootInvoiceArgs = {
  distinct_on?: InputMaybe<Array<Invoice_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Invoice_Order_By>>;
  where?: InputMaybe<Invoice_Bool_Exp>;
};


export type Query_RootInvoiceConfigurationArgs = {
  distinct_on?: InputMaybe<Array<InvoiceConfiguration_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<InvoiceConfiguration_Order_By>>;
  where?: InputMaybe<InvoiceConfiguration_Bool_Exp>;
};


export type Query_RootInvoiceConfiguration_AggregateArgs = {
  distinct_on?: InputMaybe<Array<InvoiceConfiguration_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<InvoiceConfiguration_Order_By>>;
  where?: InputMaybe<InvoiceConfiguration_Bool_Exp>;
};


export type Query_RootInvoiceConfiguration_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootInvoiceCouponArgs = {
  distinct_on?: InputMaybe<Array<InvoiceCoupon_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<InvoiceCoupon_Order_By>>;
  where?: InputMaybe<InvoiceCoupon_Bool_Exp>;
};


export type Query_RootInvoiceCoupon_AggregateArgs = {
  distinct_on?: InputMaybe<Array<InvoiceCoupon_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<InvoiceCoupon_Order_By>>;
  where?: InputMaybe<InvoiceCoupon_Bool_Exp>;
};


export type Query_RootInvoiceCoupon_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootInvoiceMembershipArgs = {
  distinct_on?: InputMaybe<Array<InvoiceMembership_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<InvoiceMembership_Order_By>>;
  where?: InputMaybe<InvoiceMembership_Bool_Exp>;
};


export type Query_RootInvoiceMembership_AggregateArgs = {
  distinct_on?: InputMaybe<Array<InvoiceMembership_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<InvoiceMembership_Order_By>>;
  where?: InputMaybe<InvoiceMembership_Bool_Exp>;
};


export type Query_RootInvoiceMembership_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootInvoicePackageArgs = {
  distinct_on?: InputMaybe<Array<InvoicePackage_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<InvoicePackage_Order_By>>;
  where?: InputMaybe<InvoicePackage_Bool_Exp>;
};


export type Query_RootInvoicePackage_AggregateArgs = {
  distinct_on?: InputMaybe<Array<InvoicePackage_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<InvoicePackage_Order_By>>;
  where?: InputMaybe<InvoicePackage_Bool_Exp>;
};


export type Query_RootInvoicePackage_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootInvoiceProductItemArgs = {
  distinct_on?: InputMaybe<Array<InvoiceProductItem_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<InvoiceProductItem_Order_By>>;
  where?: InputMaybe<InvoiceProductItem_Bool_Exp>;
};


export type Query_RootInvoiceProductItem_AggregateArgs = {
  distinct_on?: InputMaybe<Array<InvoiceProductItem_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<InvoiceProductItem_Order_By>>;
  where?: InputMaybe<InvoiceProductItem_Bool_Exp>;
};


export type Query_RootInvoiceProductItem_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootInvoiceServiceConsumableArgs = {
  distinct_on?: InputMaybe<Array<InvoiceServiceConsumable_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<InvoiceServiceConsumable_Order_By>>;
  where?: InputMaybe<InvoiceServiceConsumable_Bool_Exp>;
};


export type Query_RootInvoiceServiceConsumable_AggregateArgs = {
  distinct_on?: InputMaybe<Array<InvoiceServiceConsumable_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<InvoiceServiceConsumable_Order_By>>;
  where?: InputMaybe<InvoiceServiceConsumable_Bool_Exp>;
};


export type Query_RootInvoiceServiceConsumable_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootInvoiceServiceItemArgs = {
  distinct_on?: InputMaybe<Array<InvoiceServiceItem_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<InvoiceServiceItem_Order_By>>;
  where?: InputMaybe<InvoiceServiceItem_Bool_Exp>;
};


export type Query_RootInvoiceServiceItem_AggregateArgs = {
  distinct_on?: InputMaybe<Array<InvoiceServiceItem_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<InvoiceServiceItem_Order_By>>;
  where?: InputMaybe<InvoiceServiceItem_Bool_Exp>;
};


export type Query_RootInvoiceServiceItem_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootInvoiceTemplateArgs = {
  distinct_on?: InputMaybe<Array<InvoiceTemplate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<InvoiceTemplate_Order_By>>;
  where?: InputMaybe<InvoiceTemplate_Bool_Exp>;
};


export type Query_RootInvoiceTemplate_AggregateArgs = {
  distinct_on?: InputMaybe<Array<InvoiceTemplate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<InvoiceTemplate_Order_By>>;
  where?: InputMaybe<InvoiceTemplate_Bool_Exp>;
};


export type Query_RootInvoiceTemplate_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootInvoice_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Invoice_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Invoice_Order_By>>;
  where?: InputMaybe<Invoice_Bool_Exp>;
};


export type Query_RootInvoice_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootJobArgs = {
  distinct_on?: InputMaybe<Array<Job_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Job_Order_By>>;
  where?: InputMaybe<Job_Bool_Exp>;
};


export type Query_RootJobStatusArgs = {
  distinct_on?: InputMaybe<Array<JobStatus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<JobStatus_Order_By>>;
  where?: InputMaybe<JobStatus_Bool_Exp>;
};


export type Query_RootJobStatus_AggregateArgs = {
  distinct_on?: InputMaybe<Array<JobStatus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<JobStatus_Order_By>>;
  where?: InputMaybe<JobStatus_Bool_Exp>;
};


export type Query_RootJobStatus_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootJob_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Job_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Job_Order_By>>;
  where?: InputMaybe<Job_Bool_Exp>;
};


export type Query_RootJob_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootLabResultArgs = {
  distinct_on?: InputMaybe<Array<LabResult_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<LabResult_Order_By>>;
  where?: InputMaybe<LabResult_Bool_Exp>;
};


export type Query_RootLabResult_AggregateArgs = {
  distinct_on?: InputMaybe<Array<LabResult_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<LabResult_Order_By>>;
  where?: InputMaybe<LabResult_Bool_Exp>;
};


export type Query_RootLabResult_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootLeadFormArgs = {
  distinct_on?: InputMaybe<Array<LeadForm_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<LeadForm_Order_By>>;
  where?: InputMaybe<LeadForm_Bool_Exp>;
};


export type Query_RootLeadFormEntryArgs = {
  distinct_on?: InputMaybe<Array<LeadFormEntry_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<LeadFormEntry_Order_By>>;
  where?: InputMaybe<LeadFormEntry_Bool_Exp>;
};


export type Query_RootLeadFormEntryTagArgs = {
  distinct_on?: InputMaybe<Array<LeadFormEntryTag_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<LeadFormEntryTag_Order_By>>;
  where?: InputMaybe<LeadFormEntryTag_Bool_Exp>;
};


export type Query_RootLeadFormEntryTag_AggregateArgs = {
  distinct_on?: InputMaybe<Array<LeadFormEntryTag_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<LeadFormEntryTag_Order_By>>;
  where?: InputMaybe<LeadFormEntryTag_Bool_Exp>;
};


export type Query_RootLeadFormEntryTag_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootLeadFormEntry_AggregateArgs = {
  distinct_on?: InputMaybe<Array<LeadFormEntry_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<LeadFormEntry_Order_By>>;
  where?: InputMaybe<LeadFormEntry_Bool_Exp>;
};


export type Query_RootLeadFormEntry_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootLeadFormFieldArgs = {
  distinct_on?: InputMaybe<Array<LeadFormField_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<LeadFormField_Order_By>>;
  where?: InputMaybe<LeadFormField_Bool_Exp>;
};


export type Query_RootLeadFormFieldValueArgs = {
  distinct_on?: InputMaybe<Array<LeadFormFieldValue_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<LeadFormFieldValue_Order_By>>;
  where?: InputMaybe<LeadFormFieldValue_Bool_Exp>;
};


export type Query_RootLeadFormFieldValue_AggregateArgs = {
  distinct_on?: InputMaybe<Array<LeadFormFieldValue_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<LeadFormFieldValue_Order_By>>;
  where?: InputMaybe<LeadFormFieldValue_Bool_Exp>;
};


export type Query_RootLeadFormFieldValue_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootLeadFormField_AggregateArgs = {
  distinct_on?: InputMaybe<Array<LeadFormField_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<LeadFormField_Order_By>>;
  where?: InputMaybe<LeadFormField_Bool_Exp>;
};


export type Query_RootLeadFormField_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootLeadFormLinkArgs = {
  distinct_on?: InputMaybe<Array<LeadFormLink_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<LeadFormLink_Order_By>>;
  where?: InputMaybe<LeadFormLink_Bool_Exp>;
};


export type Query_RootLeadFormLink_AggregateArgs = {
  distinct_on?: InputMaybe<Array<LeadFormLink_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<LeadFormLink_Order_By>>;
  where?: InputMaybe<LeadFormLink_Bool_Exp>;
};


export type Query_RootLeadFormLink_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootLeadFormStepArgs = {
  distinct_on?: InputMaybe<Array<LeadFormStep_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<LeadFormStep_Order_By>>;
  where?: InputMaybe<LeadFormStep_Bool_Exp>;
};


export type Query_RootLeadFormStep_AggregateArgs = {
  distinct_on?: InputMaybe<Array<LeadFormStep_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<LeadFormStep_Order_By>>;
  where?: InputMaybe<LeadFormStep_Bool_Exp>;
};


export type Query_RootLeadFormStep_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootLeadForm_AggregateArgs = {
  distinct_on?: InputMaybe<Array<LeadForm_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<LeadForm_Order_By>>;
  where?: InputMaybe<LeadForm_Bool_Exp>;
};


export type Query_RootLeadForm_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootLeadTagArgs = {
  distinct_on?: InputMaybe<Array<LeadTag_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<LeadTag_Order_By>>;
  where?: InputMaybe<LeadTag_Bool_Exp>;
};


export type Query_RootLeadTag_AggregateArgs = {
  distinct_on?: InputMaybe<Array<LeadTag_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<LeadTag_Order_By>>;
  where?: InputMaybe<LeadTag_Bool_Exp>;
};


export type Query_RootLeadTag_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootLeadTrackedEventArgs = {
  distinct_on?: InputMaybe<Array<LeadTrackedEvent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<LeadTrackedEvent_Order_By>>;
  where?: InputMaybe<LeadTrackedEvent_Bool_Exp>;
};


export type Query_RootLeadTrackedEvent_AggregateArgs = {
  distinct_on?: InputMaybe<Array<LeadTrackedEvent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<LeadTrackedEvent_Order_By>>;
  where?: InputMaybe<LeadTrackedEvent_Bool_Exp>;
};


export type Query_RootLeadTrackedEvent_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootLocationArgs = {
  distinct_on?: InputMaybe<Array<Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Location_Order_By>>;
  where?: InputMaybe<Location_Bool_Exp>;
};


export type Query_RootLocationCouponArgs = {
  distinct_on?: InputMaybe<Array<LocationCoupon_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<LocationCoupon_Order_By>>;
  where?: InputMaybe<LocationCoupon_Bool_Exp>;
};


export type Query_RootLocationCoupon_AggregateArgs = {
  distinct_on?: InputMaybe<Array<LocationCoupon_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<LocationCoupon_Order_By>>;
  where?: InputMaybe<LocationCoupon_Bool_Exp>;
};


export type Query_RootLocationCoupon_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootLocationProviderArgs = {
  distinct_on?: InputMaybe<Array<LocationProvider_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<LocationProvider_Order_By>>;
  where?: InputMaybe<LocationProvider_Bool_Exp>;
};


export type Query_RootLocationProvider_AggregateArgs = {
  distinct_on?: InputMaybe<Array<LocationProvider_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<LocationProvider_Order_By>>;
  where?: InputMaybe<LocationProvider_Bool_Exp>;
};


export type Query_RootLocationProvider_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootLocationScheduleArgs = {
  distinct_on?: InputMaybe<Array<LocationSchedule_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<LocationSchedule_Order_By>>;
  where?: InputMaybe<LocationSchedule_Bool_Exp>;
};


export type Query_RootLocationSchedule_AggregateArgs = {
  distinct_on?: InputMaybe<Array<LocationSchedule_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<LocationSchedule_Order_By>>;
  where?: InputMaybe<LocationSchedule_Bool_Exp>;
};


export type Query_RootLocationSchedule_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootLocationServiceArgs = {
  distinct_on?: InputMaybe<Array<LocationService_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<LocationService_Order_By>>;
  where?: InputMaybe<LocationService_Bool_Exp>;
};


export type Query_RootLocationService_AggregateArgs = {
  distinct_on?: InputMaybe<Array<LocationService_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<LocationService_Order_By>>;
  where?: InputMaybe<LocationService_Bool_Exp>;
};


export type Query_RootLocationService_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootLocation_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Location_Order_By>>;
  where?: InputMaybe<Location_Bool_Exp>;
};


export type Query_RootLocation_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootMarketingTagArgs = {
  distinct_on?: InputMaybe<Array<MarketingTag_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MarketingTag_Order_By>>;
  where?: InputMaybe<MarketingTag_Bool_Exp>;
};


export type Query_RootMarketingTagEmailArgs = {
  distinct_on?: InputMaybe<Array<MarketingTagEmail_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MarketingTagEmail_Order_By>>;
  where?: InputMaybe<MarketingTagEmail_Bool_Exp>;
};


export type Query_RootMarketingTagEmail_AggregateArgs = {
  distinct_on?: InputMaybe<Array<MarketingTagEmail_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MarketingTagEmail_Order_By>>;
  where?: InputMaybe<MarketingTagEmail_Bool_Exp>;
};


export type Query_RootMarketingTagEmail_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootMarketingTagSmsArgs = {
  distinct_on?: InputMaybe<Array<MarketingTagSms_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MarketingTagSms_Order_By>>;
  where?: InputMaybe<MarketingTagSms_Bool_Exp>;
};


export type Query_RootMarketingTagSms_AggregateArgs = {
  distinct_on?: InputMaybe<Array<MarketingTagSms_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MarketingTagSms_Order_By>>;
  where?: InputMaybe<MarketingTagSms_Bool_Exp>;
};


export type Query_RootMarketingTagSms_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootMarketingTag_AggregateArgs = {
  distinct_on?: InputMaybe<Array<MarketingTag_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MarketingTag_Order_By>>;
  where?: InputMaybe<MarketingTag_Bool_Exp>;
};


export type Query_RootMarketingTag_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootMeArgs = {
  firebaseUid: Scalars['String'];
};


export type Query_RootMePatientArgs = {
  firebaseUid: Scalars['String'];
};


export type Query_RootMediaTagArgs = {
  distinct_on?: InputMaybe<Array<MediaTag_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MediaTag_Order_By>>;
  where?: InputMaybe<MediaTag_Bool_Exp>;
};


export type Query_RootMediaTag_AggregateArgs = {
  distinct_on?: InputMaybe<Array<MediaTag_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MediaTag_Order_By>>;
  where?: InputMaybe<MediaTag_Bool_Exp>;
};


export type Query_RootMediaTag_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootMembershipArgs = {
  distinct_on?: InputMaybe<Array<Membership_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Membership_Order_By>>;
  where?: InputMaybe<Membership_Bool_Exp>;
};


export type Query_RootMembershipCouponArgs = {
  distinct_on?: InputMaybe<Array<MembershipCoupon_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MembershipCoupon_Order_By>>;
  where?: InputMaybe<MembershipCoupon_Bool_Exp>;
};


export type Query_RootMembershipCoupon_AggregateArgs = {
  distinct_on?: InputMaybe<Array<MembershipCoupon_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MembershipCoupon_Order_By>>;
  where?: InputMaybe<MembershipCoupon_Bool_Exp>;
};


export type Query_RootMembershipCoupon_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootMembershipTypeArgs = {
  distinct_on?: InputMaybe<Array<MembershipType_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MembershipType_Order_By>>;
  where?: InputMaybe<MembershipType_Bool_Exp>;
};


export type Query_RootMembershipType_AggregateArgs = {
  distinct_on?: InputMaybe<Array<MembershipType_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MembershipType_Order_By>>;
  where?: InputMaybe<MembershipType_Bool_Exp>;
};


export type Query_RootMembershipType_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootMembership_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Membership_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Membership_Order_By>>;
  where?: InputMaybe<Membership_Bool_Exp>;
};


export type Query_RootMembership_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootNoteArgs = {
  distinct_on?: InputMaybe<Array<Note_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Note_Order_By>>;
  where?: InputMaybe<Note_Bool_Exp>;
};


export type Query_RootNoteSignatureArgs = {
  distinct_on?: InputMaybe<Array<NoteSignature_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<NoteSignature_Order_By>>;
  where?: InputMaybe<NoteSignature_Bool_Exp>;
};


export type Query_RootNoteSignature_AggregateArgs = {
  distinct_on?: InputMaybe<Array<NoteSignature_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<NoteSignature_Order_By>>;
  where?: InputMaybe<NoteSignature_Bool_Exp>;
};


export type Query_RootNoteSignature_By_PkArgs = {
  noteId: Scalars['uuid'];
  signatureId: Scalars['uuid'];
};


export type Query_RootNote_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Note_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Note_Order_By>>;
  where?: InputMaybe<Note_Bool_Exp>;
};


export type Query_RootNote_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootOrganizationArgs = {
  distinct_on?: InputMaybe<Array<Organization_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_Order_By>>;
  where?: InputMaybe<Organization_Bool_Exp>;
};


export type Query_RootOrganizationConfigurationArgs = {
  distinct_on?: InputMaybe<Array<OrganizationConfiguration_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<OrganizationConfiguration_Order_By>>;
  where?: InputMaybe<OrganizationConfiguration_Bool_Exp>;
};


export type Query_RootOrganizationConfiguration_AggregateArgs = {
  distinct_on?: InputMaybe<Array<OrganizationConfiguration_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<OrganizationConfiguration_Order_By>>;
  where?: InputMaybe<OrganizationConfiguration_Bool_Exp>;
};


export type Query_RootOrganizationConfiguration_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootOrganization_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Organization_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_Order_By>>;
  where?: InputMaybe<Organization_Bool_Exp>;
};


export type Query_RootOrganization_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootPackageArgs = {
  distinct_on?: InputMaybe<Array<Package_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Package_Order_By>>;
  where?: InputMaybe<Package_Bool_Exp>;
};


export type Query_RootPackage_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Package_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Package_Order_By>>;
  where?: InputMaybe<Package_Bool_Exp>;
};


export type Query_RootPackage_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootPatientArgs = {
  distinct_on?: InputMaybe<Array<Patient_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Patient_Order_By>>;
  where?: InputMaybe<Patient_Bool_Exp>;
};


export type Query_RootPatientAddressArgs = {
  distinct_on?: InputMaybe<Array<PatientAddress_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PatientAddress_Order_By>>;
  where?: InputMaybe<PatientAddress_Bool_Exp>;
};


export type Query_RootPatientAddress_AggregateArgs = {
  distinct_on?: InputMaybe<Array<PatientAddress_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PatientAddress_Order_By>>;
  where?: InputMaybe<PatientAddress_Bool_Exp>;
};


export type Query_RootPatientAddress_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootPatientConsentArgs = {
  distinct_on?: InputMaybe<Array<PatientConsent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PatientConsent_Order_By>>;
  where?: InputMaybe<PatientConsent_Bool_Exp>;
};


export type Query_RootPatientConsent_AggregateArgs = {
  distinct_on?: InputMaybe<Array<PatientConsent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PatientConsent_Order_By>>;
  where?: InputMaybe<PatientConsent_Bool_Exp>;
};


export type Query_RootPatientConsent_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootPatientConversationArgs = {
  distinct_on?: InputMaybe<Array<PatientConversation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PatientConversation_Order_By>>;
  where?: InputMaybe<PatientConversation_Bool_Exp>;
};


export type Query_RootPatientConversation_AggregateArgs = {
  distinct_on?: InputMaybe<Array<PatientConversation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PatientConversation_Order_By>>;
  where?: InputMaybe<PatientConversation_Bool_Exp>;
};


export type Query_RootPatientConversation_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootPatientFlowsheetDataArgs = {
  distinct_on?: InputMaybe<Array<PatientFlowsheetData_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PatientFlowsheetData_Order_By>>;
  where?: InputMaybe<PatientFlowsheetData_Bool_Exp>;
};


export type Query_RootPatientFlowsheetData_AggregateArgs = {
  distinct_on?: InputMaybe<Array<PatientFlowsheetData_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PatientFlowsheetData_Order_By>>;
  where?: InputMaybe<PatientFlowsheetData_Bool_Exp>;
};


export type Query_RootPatientFlowsheetData_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootPatientFlowsheetRowArgs = {
  distinct_on?: InputMaybe<Array<PatientFlowsheetRow_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PatientFlowsheetRow_Order_By>>;
  where?: InputMaybe<PatientFlowsheetRow_Bool_Exp>;
};


export type Query_RootPatientFlowsheetRow_AggregateArgs = {
  distinct_on?: InputMaybe<Array<PatientFlowsheetRow_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PatientFlowsheetRow_Order_By>>;
  where?: InputMaybe<PatientFlowsheetRow_Bool_Exp>;
};


export type Query_RootPatientFlowsheetRow_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootPatientInviteArgs = {
  distinct_on?: InputMaybe<Array<PatientInvite_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PatientInvite_Order_By>>;
  where?: InputMaybe<PatientInvite_Bool_Exp>;
};


export type Query_RootPatientInvite_AggregateArgs = {
  distinct_on?: InputMaybe<Array<PatientInvite_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PatientInvite_Order_By>>;
  where?: InputMaybe<PatientInvite_Bool_Exp>;
};


export type Query_RootPatientInvite_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootPatientMembershipArgs = {
  distinct_on?: InputMaybe<Array<PatientMembership_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PatientMembership_Order_By>>;
  where?: InputMaybe<PatientMembership_Bool_Exp>;
};


export type Query_RootPatientMembership_AggregateArgs = {
  distinct_on?: InputMaybe<Array<PatientMembership_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PatientMembership_Order_By>>;
  where?: InputMaybe<PatientMembership_Bool_Exp>;
};


export type Query_RootPatientMembership_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootPatientSystemNoticeArgs = {
  distinct_on?: InputMaybe<Array<PatientSystemNotice_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PatientSystemNotice_Order_By>>;
  where?: InputMaybe<PatientSystemNotice_Bool_Exp>;
};


export type Query_RootPatientSystemNotice_AggregateArgs = {
  distinct_on?: InputMaybe<Array<PatientSystemNotice_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PatientSystemNotice_Order_By>>;
  where?: InputMaybe<PatientSystemNotice_Bool_Exp>;
};


export type Query_RootPatientSystemNotice_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootPatientTagArgs = {
  distinct_on?: InputMaybe<Array<PatientTag_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PatientTag_Order_By>>;
  where?: InputMaybe<PatientTag_Bool_Exp>;
};


export type Query_RootPatientTag_AggregateArgs = {
  distinct_on?: InputMaybe<Array<PatientTag_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PatientTag_Order_By>>;
  where?: InputMaybe<PatientTag_Bool_Exp>;
};


export type Query_RootPatientTag_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootPatientTagsArgs = {
  distinct_on?: InputMaybe<Array<PatientTags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PatientTags_Order_By>>;
  where?: InputMaybe<PatientTags_Bool_Exp>;
};


export type Query_RootPatientTags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<PatientTags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PatientTags_Order_By>>;
  where?: InputMaybe<PatientTags_Bool_Exp>;
};


export type Query_RootPatientTags_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootPatientWalletArgs = {
  distinct_on?: InputMaybe<Array<PatientWallet_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PatientWallet_Order_By>>;
  where?: InputMaybe<PatientWallet_Bool_Exp>;
};


export type Query_RootPatientWalletCouponArgs = {
  distinct_on?: InputMaybe<Array<PatientWalletCoupon_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PatientWalletCoupon_Order_By>>;
  where?: InputMaybe<PatientWalletCoupon_Bool_Exp>;
};


export type Query_RootPatientWalletCoupon_AggregateArgs = {
  distinct_on?: InputMaybe<Array<PatientWalletCoupon_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PatientWalletCoupon_Order_By>>;
  where?: InputMaybe<PatientWalletCoupon_Bool_Exp>;
};


export type Query_RootPatientWalletCoupon_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootPatientWalletTransactionArgs = {
  distinct_on?: InputMaybe<Array<PatientWalletTransaction_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PatientWalletTransaction_Order_By>>;
  where?: InputMaybe<PatientWalletTransaction_Bool_Exp>;
};


export type Query_RootPatientWalletTransaction_AggregateArgs = {
  distinct_on?: InputMaybe<Array<PatientWalletTransaction_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PatientWalletTransaction_Order_By>>;
  where?: InputMaybe<PatientWalletTransaction_Bool_Exp>;
};


export type Query_RootPatientWalletTransaction_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootPatientWallet_AggregateArgs = {
  distinct_on?: InputMaybe<Array<PatientWallet_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PatientWallet_Order_By>>;
  where?: InputMaybe<PatientWallet_Bool_Exp>;
};


export type Query_RootPatientWallet_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootPatientWorkspaceArgs = {
  distinct_on?: InputMaybe<Array<PatientWorkspace_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PatientWorkspace_Order_By>>;
  where?: InputMaybe<PatientWorkspace_Bool_Exp>;
};


export type Query_RootPatientWorkspace_AggregateArgs = {
  distinct_on?: InputMaybe<Array<PatientWorkspace_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PatientWorkspace_Order_By>>;
  where?: InputMaybe<PatientWorkspace_Bool_Exp>;
};


export type Query_RootPatientWorkspace_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootPatient_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Patient_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Patient_Order_By>>;
  where?: InputMaybe<Patient_Bool_Exp>;
};


export type Query_RootPatient_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootPaymentArgs = {
  distinct_on?: InputMaybe<Array<Payment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Payment_Order_By>>;
  where?: InputMaybe<Payment_Bool_Exp>;
};


export type Query_RootPaymentMethodArgs = {
  distinct_on?: InputMaybe<Array<PaymentMethod_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PaymentMethod_Order_By>>;
  where?: InputMaybe<PaymentMethod_Bool_Exp>;
};


export type Query_RootPaymentMethod_AggregateArgs = {
  distinct_on?: InputMaybe<Array<PaymentMethod_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PaymentMethod_Order_By>>;
  where?: InputMaybe<PaymentMethod_Bool_Exp>;
};


export type Query_RootPaymentMethod_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootPaymentRelationshipArgs = {
  distinct_on?: InputMaybe<Array<PaymentRelationship_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PaymentRelationship_Order_By>>;
  where?: InputMaybe<PaymentRelationship_Bool_Exp>;
};


export type Query_RootPaymentRelationship_AggregateArgs = {
  distinct_on?: InputMaybe<Array<PaymentRelationship_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PaymentRelationship_Order_By>>;
  where?: InputMaybe<PaymentRelationship_Bool_Exp>;
};


export type Query_RootPaymentRelationship_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootPayment_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Payment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Payment_Order_By>>;
  where?: InputMaybe<Payment_Bool_Exp>;
};


export type Query_RootPayment_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootPayrixTerminalArgs = {
  distinct_on?: InputMaybe<Array<PayrixTerminal_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PayrixTerminal_Order_By>>;
  where?: InputMaybe<PayrixTerminal_Bool_Exp>;
};


export type Query_RootPayrixTerminalTransactionArgs = {
  distinct_on?: InputMaybe<Array<PayrixTerminalTransaction_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PayrixTerminalTransaction_Order_By>>;
  where?: InputMaybe<PayrixTerminalTransaction_Bool_Exp>;
};


export type Query_RootPayrixTerminalTransaction_AggregateArgs = {
  distinct_on?: InputMaybe<Array<PayrixTerminalTransaction_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PayrixTerminalTransaction_Order_By>>;
  where?: InputMaybe<PayrixTerminalTransaction_Bool_Exp>;
};


export type Query_RootPayrixTerminalTransaction_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootPayrixTerminal_AggregateArgs = {
  distinct_on?: InputMaybe<Array<PayrixTerminal_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PayrixTerminal_Order_By>>;
  where?: InputMaybe<PayrixTerminal_Bool_Exp>;
};


export type Query_RootPayrixTerminal_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootPayrixWebAlertArgs = {
  distinct_on?: InputMaybe<Array<PayrixWebAlert_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PayrixWebAlert_Order_By>>;
  where?: InputMaybe<PayrixWebAlert_Bool_Exp>;
};


export type Query_RootPayrixWebAlert_AggregateArgs = {
  distinct_on?: InputMaybe<Array<PayrixWebAlert_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PayrixWebAlert_Order_By>>;
  where?: InputMaybe<PayrixWebAlert_Bool_Exp>;
};


export type Query_RootPayrixWebAlert_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootPhoneNumberLookupArgs = {
  countryCode?: InputMaybe<Scalars['String']>;
  phoneNumber: Scalars['String'];
};


export type Query_RootPhotoArgs = {
  distinct_on?: InputMaybe<Array<Photo_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Photo_Order_By>>;
  where?: InputMaybe<Photo_Bool_Exp>;
};


export type Query_RootPhotoMediaTagArgs = {
  distinct_on?: InputMaybe<Array<PhotoMediaTag_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PhotoMediaTag_Order_By>>;
  where?: InputMaybe<PhotoMediaTag_Bool_Exp>;
};


export type Query_RootPhotoMediaTag_AggregateArgs = {
  distinct_on?: InputMaybe<Array<PhotoMediaTag_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PhotoMediaTag_Order_By>>;
  where?: InputMaybe<PhotoMediaTag_Bool_Exp>;
};


export type Query_RootPhotoMediaTag_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootPhoto_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Photo_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Photo_Order_By>>;
  where?: InputMaybe<Photo_Bool_Exp>;
};


export type Query_RootPhoto_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootPinArgs = {
  distinct_on?: InputMaybe<Array<Pin_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Pin_Order_By>>;
  where?: InputMaybe<Pin_Bool_Exp>;
};


export type Query_RootPin_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Pin_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Pin_Order_By>>;
  where?: InputMaybe<Pin_Bool_Exp>;
};


export type Query_RootPin_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootPostStatusArgs = {
  distinct_on?: InputMaybe<Array<PostStatus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PostStatus_Order_By>>;
  where?: InputMaybe<PostStatus_Bool_Exp>;
};


export type Query_RootPostStatus_AggregateArgs = {
  distinct_on?: InputMaybe<Array<PostStatus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PostStatus_Order_By>>;
  where?: InputMaybe<PostStatus_Bool_Exp>;
};


export type Query_RootPostStatus_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootPrescriptionArgs = {
  distinct_on?: InputMaybe<Array<Prescription_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Prescription_Order_By>>;
  where?: InputMaybe<Prescription_Bool_Exp>;
};


export type Query_RootPrescriptionDetailArgs = {
  distinct_on?: InputMaybe<Array<PrescriptionDetail_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PrescriptionDetail_Order_By>>;
  where?: InputMaybe<PrescriptionDetail_Bool_Exp>;
};


export type Query_RootPrescriptionDetail_AggregateArgs = {
  distinct_on?: InputMaybe<Array<PrescriptionDetail_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PrescriptionDetail_Order_By>>;
  where?: InputMaybe<PrescriptionDetail_Bool_Exp>;
};


export type Query_RootPrescriptionDetail_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootPrescription_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Prescription_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Prescription_Order_By>>;
  where?: InputMaybe<Prescription_Bool_Exp>;
};


export type Query_RootPrescription_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootPreviewAudienceArgs = {
  query: Scalars['jsonb'];
};


export type Query_RootPromotionArgs = {
  distinct_on?: InputMaybe<Array<Promotion_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Promotion_Order_By>>;
  where?: InputMaybe<Promotion_Bool_Exp>;
};


export type Query_RootPromotionCouponsArgs = {
  distinct_on?: InputMaybe<Array<PromotionCoupons_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PromotionCoupons_Order_By>>;
  where?: InputMaybe<PromotionCoupons_Bool_Exp>;
};


export type Query_RootPromotionCoupons_AggregateArgs = {
  distinct_on?: InputMaybe<Array<PromotionCoupons_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PromotionCoupons_Order_By>>;
  where?: InputMaybe<PromotionCoupons_Bool_Exp>;
};


export type Query_RootPromotionCoupons_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootPromotionServicesArgs = {
  distinct_on?: InputMaybe<Array<PromotionServices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PromotionServices_Order_By>>;
  where?: InputMaybe<PromotionServices_Bool_Exp>;
};


export type Query_RootPromotionServices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<PromotionServices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PromotionServices_Order_By>>;
  where?: InputMaybe<PromotionServices_Bool_Exp>;
};


export type Query_RootPromotionServices_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootPromotion_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Promotion_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Promotion_Order_By>>;
  where?: InputMaybe<Promotion_Bool_Exp>;
};


export type Query_RootPromotion_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootProviderArgs = {
  distinct_on?: InputMaybe<Array<Provider_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Provider_Order_By>>;
  where?: InputMaybe<Provider_Bool_Exp>;
};


export type Query_RootProviderScheduleArgs = {
  distinct_on?: InputMaybe<Array<ProviderSchedule_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ProviderSchedule_Order_By>>;
  where?: InputMaybe<ProviderSchedule_Bool_Exp>;
};


export type Query_RootProviderSchedule_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ProviderSchedule_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ProviderSchedule_Order_By>>;
  where?: InputMaybe<ProviderSchedule_Bool_Exp>;
};


export type Query_RootProviderSchedule_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootProvider_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Provider_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Provider_Order_By>>;
  where?: InputMaybe<Provider_Bool_Exp>;
};


export type Query_RootProvider_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootPullGoogleProviderReviewsArgs = {
  accountName: Scalars['String'];
  locationName: Scalars['String'];
};


export type Query_RootPullProviderRsReviewsArgs = {
  providerId: Scalars['uuid'];
};


export type Query_RootPullProviderZdReviewsArgs = {
  providerId: Scalars['uuid'];
};


export type Query_RootPurchaseOrderArgs = {
  distinct_on?: InputMaybe<Array<PurchaseOrder_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PurchaseOrder_Order_By>>;
  where?: InputMaybe<PurchaseOrder_Bool_Exp>;
};


export type Query_RootPurchaseOrder_AggregateArgs = {
  distinct_on?: InputMaybe<Array<PurchaseOrder_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PurchaseOrder_Order_By>>;
  where?: InputMaybe<PurchaseOrder_Bool_Exp>;
};


export type Query_RootPurchaseOrder_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootRecurrenceTypeArgs = {
  distinct_on?: InputMaybe<Array<RecurrenceType_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RecurrenceType_Order_By>>;
  where?: InputMaybe<RecurrenceType_Bool_Exp>;
};


export type Query_RootRecurrenceType_AggregateArgs = {
  distinct_on?: InputMaybe<Array<RecurrenceType_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RecurrenceType_Order_By>>;
  where?: InputMaybe<RecurrenceType_Bool_Exp>;
};


export type Query_RootRecurrenceType_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootRefundArgs = {
  distinct_on?: InputMaybe<Array<Refund_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Refund_Order_By>>;
  where?: InputMaybe<Refund_Bool_Exp>;
};


export type Query_RootRefund_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Refund_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Refund_Order_By>>;
  where?: InputMaybe<Refund_Bool_Exp>;
};


export type Query_RootRefund_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootResolutionStatusArgs = {
  distinct_on?: InputMaybe<Array<ResolutionStatus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ResolutionStatus_Order_By>>;
  where?: InputMaybe<ResolutionStatus_Bool_Exp>;
};


export type Query_RootResolutionStatus_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ResolutionStatus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ResolutionStatus_Order_By>>;
  where?: InputMaybe<ResolutionStatus_Bool_Exp>;
};


export type Query_RootResolutionStatus_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootRewardProgramArgs = {
  distinct_on?: InputMaybe<Array<RewardProgram_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RewardProgram_Order_By>>;
  where?: InputMaybe<RewardProgram_Bool_Exp>;
};


export type Query_RootRewardProgramEnrollmentArgs = {
  distinct_on?: InputMaybe<Array<RewardProgramEnrollment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RewardProgramEnrollment_Order_By>>;
  where?: InputMaybe<RewardProgramEnrollment_Bool_Exp>;
};


export type Query_RootRewardProgramEnrollment_AggregateArgs = {
  distinct_on?: InputMaybe<Array<RewardProgramEnrollment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RewardProgramEnrollment_Order_By>>;
  where?: InputMaybe<RewardProgramEnrollment_Bool_Exp>;
};


export type Query_RootRewardProgramEnrollment_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootRewardProgram_AggregateArgs = {
  distinct_on?: InputMaybe<Array<RewardProgram_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RewardProgram_Order_By>>;
  where?: InputMaybe<RewardProgram_Bool_Exp>;
};


export type Query_RootRewardProgram_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootRoomArgs = {
  distinct_on?: InputMaybe<Array<Room_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Room_Order_By>>;
  where?: InputMaybe<Room_Bool_Exp>;
};


export type Query_RootRoom_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Room_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Room_Order_By>>;
  where?: InputMaybe<Room_Bool_Exp>;
};


export type Query_RootRoom_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootRsReviewArgs = {
  distinct_on?: InputMaybe<Array<RsReview_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RsReview_Order_By>>;
  where?: InputMaybe<RsReview_Bool_Exp>;
};


export type Query_RootRsReview_AggregateArgs = {
  distinct_on?: InputMaybe<Array<RsReview_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RsReview_Order_By>>;
  where?: InputMaybe<RsReview_Bool_Exp>;
};


export type Query_RootRsReview_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootSearchConsumableTypesArgs = {
  args: SearchConsumableTypes_Args;
  distinct_on?: InputMaybe<Array<ConsumableType_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ConsumableType_Order_By>>;
  where?: InputMaybe<ConsumableType_Bool_Exp>;
};


export type Query_RootSearchConsumableTypes_AggregateArgs = {
  args: SearchConsumableTypes_Args;
  distinct_on?: InputMaybe<Array<ConsumableType_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ConsumableType_Order_By>>;
  where?: InputMaybe<ConsumableType_Bool_Exp>;
};


export type Query_RootSearchGoogleLocationsArgs = {
  query: Scalars['String'];
};


export type Query_RootSearchPatientsArgs = {
  args: SearchPatients_Args;
  distinct_on?: InputMaybe<Array<Patient_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Patient_Order_By>>;
  where?: InputMaybe<Patient_Bool_Exp>;
};


export type Query_RootSearchPatients_AggregateArgs = {
  args: SearchPatients_Args;
  distinct_on?: InputMaybe<Array<Patient_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Patient_Order_By>>;
  where?: InputMaybe<Patient_Bool_Exp>;
};


export type Query_RootSearchProviderScheduleArgs = {
  args: SearchProviderSchedule_Args;
  distinct_on?: InputMaybe<Array<ProviderSchedule_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ProviderSchedule_Order_By>>;
  where?: InputMaybe<ProviderSchedule_Bool_Exp>;
};


export type Query_RootSearchProviderSchedule_AggregateArgs = {
  args: SearchProviderSchedule_Args;
  distinct_on?: InputMaybe<Array<ProviderSchedule_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ProviderSchedule_Order_By>>;
  where?: InputMaybe<ProviderSchedule_Bool_Exp>;
};


export type Query_RootSearchRsProvidersArgs = {
  count: Scalars['Int'];
  where: SearchRsProvidersInput;
};


export type Query_RootSearch_Note_TitleArgs = {
  args: Search_Note_Title_Args;
  distinct_on?: InputMaybe<Array<Note_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Note_Order_By>>;
  where?: InputMaybe<Note_Bool_Exp>;
};


export type Query_RootSearch_Note_Title_AggregateArgs = {
  args: Search_Note_Title_Args;
  distinct_on?: InputMaybe<Array<Note_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Note_Order_By>>;
  where?: InputMaybe<Note_Bool_Exp>;
};


export type Query_RootSearch_PatientWorkspaceArgs = {
  args: Search_PatientWorkspace_Args;
  distinct_on?: InputMaybe<Array<Patient_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Patient_Order_By>>;
  where?: InputMaybe<Patient_Bool_Exp>;
};


export type Query_RootSearch_PatientWorkspace_AggregateArgs = {
  args: Search_PatientWorkspace_Args;
  distinct_on?: InputMaybe<Array<Patient_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Patient_Order_By>>;
  where?: InputMaybe<Patient_Bool_Exp>;
};


export type Query_RootServiceArgs = {
  distinct_on?: InputMaybe<Array<Service_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Service_Order_By>>;
  where?: InputMaybe<Service_Bool_Exp>;
};


export type Query_RootServiceAddOnArgs = {
  distinct_on?: InputMaybe<Array<ServiceAddOn_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ServiceAddOn_Order_By>>;
  where?: InputMaybe<ServiceAddOn_Bool_Exp>;
};


export type Query_RootServiceAddOn_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ServiceAddOn_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ServiceAddOn_Order_By>>;
  where?: InputMaybe<ServiceAddOn_Bool_Exp>;
};


export type Query_RootServiceAddOn_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootServiceCategoryArgs = {
  distinct_on?: InputMaybe<Array<ServiceCategory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ServiceCategory_Order_By>>;
  where?: InputMaybe<ServiceCategory_Bool_Exp>;
};


export type Query_RootServiceCategory_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ServiceCategory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ServiceCategory_Order_By>>;
  where?: InputMaybe<ServiceCategory_Bool_Exp>;
};


export type Query_RootServiceCategory_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootServiceConsentArgs = {
  distinct_on?: InputMaybe<Array<ServiceConsent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ServiceConsent_Order_By>>;
  where?: InputMaybe<ServiceConsent_Bool_Exp>;
};


export type Query_RootServiceConsent_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ServiceConsent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ServiceConsent_Order_By>>;
  where?: InputMaybe<ServiceConsent_Bool_Exp>;
};


export type Query_RootServiceConsent_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootServiceConsumableTypeArgs = {
  distinct_on?: InputMaybe<Array<ServiceConsumableType_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ServiceConsumableType_Order_By>>;
  where?: InputMaybe<ServiceConsumableType_Bool_Exp>;
};


export type Query_RootServiceConsumableType_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ServiceConsumableType_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ServiceConsumableType_Order_By>>;
  where?: InputMaybe<ServiceConsumableType_Bool_Exp>;
};


export type Query_RootServiceConsumableType_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootServiceCouponArgs = {
  distinct_on?: InputMaybe<Array<ServiceCoupon_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ServiceCoupon_Order_By>>;
  where?: InputMaybe<ServiceCoupon_Bool_Exp>;
};


export type Query_RootServiceCoupon_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ServiceCoupon_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ServiceCoupon_Order_By>>;
  where?: InputMaybe<ServiceCoupon_Bool_Exp>;
};


export type Query_RootServiceCoupon_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootServiceCustomFormArgs = {
  distinct_on?: InputMaybe<Array<ServiceCustomForm_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ServiceCustomForm_Order_By>>;
  where?: InputMaybe<ServiceCustomForm_Bool_Exp>;
};


export type Query_RootServiceCustomForm_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ServiceCustomForm_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ServiceCustomForm_Order_By>>;
  where?: InputMaybe<ServiceCustomForm_Bool_Exp>;
};


export type Query_RootServiceCustomForm_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootServiceDeviceTypeArgs = {
  distinct_on?: InputMaybe<Array<ServiceDeviceType_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ServiceDeviceType_Order_By>>;
  where?: InputMaybe<ServiceDeviceType_Bool_Exp>;
};


export type Query_RootServiceDeviceType_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ServiceDeviceType_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ServiceDeviceType_Order_By>>;
  where?: InputMaybe<ServiceDeviceType_Bool_Exp>;
};


export type Query_RootServiceDeviceType_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootServiceProviderArgs = {
  distinct_on?: InputMaybe<Array<ServiceProvider_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ServiceProvider_Order_By>>;
  where?: InputMaybe<ServiceProvider_Bool_Exp>;
};


export type Query_RootServiceProvider_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ServiceProvider_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ServiceProvider_Order_By>>;
  where?: InputMaybe<ServiceProvider_Bool_Exp>;
};


export type Query_RootServiceProvider_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootServiceUnitArgs = {
  distinct_on?: InputMaybe<Array<ServiceUnit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ServiceUnit_Order_By>>;
  where?: InputMaybe<ServiceUnit_Bool_Exp>;
};


export type Query_RootServiceUnit_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ServiceUnit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ServiceUnit_Order_By>>;
  where?: InputMaybe<ServiceUnit_Bool_Exp>;
};


export type Query_RootServiceUnit_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootService_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Service_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Service_Order_By>>;
  where?: InputMaybe<Service_Bool_Exp>;
};


export type Query_RootService_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootShrinkArgs = {
  distinct_on?: InputMaybe<Array<Shrink_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Shrink_Order_By>>;
  where?: InputMaybe<Shrink_Bool_Exp>;
};


export type Query_RootShrink_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Shrink_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Shrink_Order_By>>;
  where?: InputMaybe<Shrink_Bool_Exp>;
};


export type Query_RootShrink_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootSignatureArgs = {
  distinct_on?: InputMaybe<Array<Signature_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Signature_Order_By>>;
  where?: InputMaybe<Signature_Bool_Exp>;
};


export type Query_RootSignature_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Signature_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Signature_Order_By>>;
  where?: InputMaybe<Signature_Bool_Exp>;
};


export type Query_RootSignature_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootSmartNoteArgs = {
  distinct_on?: InputMaybe<Array<SmartNote_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SmartNote_Order_By>>;
  where?: InputMaybe<SmartNote_Bool_Exp>;
};


export type Query_RootSmartNote_AggregateArgs = {
  distinct_on?: InputMaybe<Array<SmartNote_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SmartNote_Order_By>>;
  where?: InputMaybe<SmartNote_Bool_Exp>;
};


export type Query_RootSmartNote_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootSmsTemplateArgs = {
  distinct_on?: InputMaybe<Array<SmsTemplate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SmsTemplate_Order_By>>;
  where?: InputMaybe<SmsTemplate_Bool_Exp>;
};


export type Query_RootSmsTemplatePatientArgs = {
  distinct_on?: InputMaybe<Array<SmsTemplatePatient_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SmsTemplatePatient_Order_By>>;
  where?: InputMaybe<SmsTemplatePatient_Bool_Exp>;
};


export type Query_RootSmsTemplatePatient_AggregateArgs = {
  distinct_on?: InputMaybe<Array<SmsTemplatePatient_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SmsTemplatePatient_Order_By>>;
  where?: InputMaybe<SmsTemplatePatient_Bool_Exp>;
};


export type Query_RootSmsTemplatePatient_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootSmsTemplateSendQueueArgs = {
  distinct_on?: InputMaybe<Array<SmsTemplateSendQueue_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SmsTemplateSendQueue_Order_By>>;
  where?: InputMaybe<SmsTemplateSendQueue_Bool_Exp>;
};


export type Query_RootSmsTemplateSendQueue_AggregateArgs = {
  distinct_on?: InputMaybe<Array<SmsTemplateSendQueue_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SmsTemplateSendQueue_Order_By>>;
  where?: InputMaybe<SmsTemplateSendQueue_Bool_Exp>;
};


export type Query_RootSmsTemplateSendQueue_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootSmsTemplate_AggregateArgs = {
  distinct_on?: InputMaybe<Array<SmsTemplate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SmsTemplate_Order_By>>;
  where?: InputMaybe<SmsTemplate_Bool_Exp>;
};


export type Query_RootSmsTemplate_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootSocialMediaAccountArgs = {
  distinct_on?: InputMaybe<Array<SocialMediaAccount_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SocialMediaAccount_Order_By>>;
  where?: InputMaybe<SocialMediaAccount_Bool_Exp>;
};


export type Query_RootSocialMediaAccountPostArgs = {
  distinct_on?: InputMaybe<Array<SocialMediaAccountPost_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SocialMediaAccountPost_Order_By>>;
  where?: InputMaybe<SocialMediaAccountPost_Bool_Exp>;
};


export type Query_RootSocialMediaAccountPost_AggregateArgs = {
  distinct_on?: InputMaybe<Array<SocialMediaAccountPost_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SocialMediaAccountPost_Order_By>>;
  where?: InputMaybe<SocialMediaAccountPost_Bool_Exp>;
};


export type Query_RootSocialMediaAccountPost_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootSocialMediaAccount_AggregateArgs = {
  distinct_on?: InputMaybe<Array<SocialMediaAccount_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SocialMediaAccount_Order_By>>;
  where?: InputMaybe<SocialMediaAccount_Bool_Exp>;
};


export type Query_RootSocialMediaAccount_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootSocialMediaPlatformArgs = {
  distinct_on?: InputMaybe<Array<SocialMediaPlatform_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SocialMediaPlatform_Order_By>>;
  where?: InputMaybe<SocialMediaPlatform_Bool_Exp>;
};


export type Query_RootSocialMediaPlatform_AggregateArgs = {
  distinct_on?: InputMaybe<Array<SocialMediaPlatform_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SocialMediaPlatform_Order_By>>;
  where?: InputMaybe<SocialMediaPlatform_Bool_Exp>;
};


export type Query_RootSocialMediaPlatform_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootSocialMediaPostArgs = {
  distinct_on?: InputMaybe<Array<SocialMediaPost_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SocialMediaPost_Order_By>>;
  where?: InputMaybe<SocialMediaPost_Bool_Exp>;
};


export type Query_RootSocialMediaPost_AggregateArgs = {
  distinct_on?: InputMaybe<Array<SocialMediaPost_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SocialMediaPost_Order_By>>;
  where?: InputMaybe<SocialMediaPost_Bool_Exp>;
};


export type Query_RootSocialMediaPost_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootTagArgs = {
  distinct_on?: InputMaybe<Array<Tag_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tag_Order_By>>;
  where?: InputMaybe<Tag_Bool_Exp>;
};


export type Query_RootTag_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tag_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tag_Order_By>>;
  where?: InputMaybe<Tag_Bool_Exp>;
};


export type Query_RootTag_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootTaskArgs = {
  distinct_on?: InputMaybe<Array<Task_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_Order_By>>;
  where?: InputMaybe<Task_Bool_Exp>;
};


export type Query_RootTaskCommentArgs = {
  distinct_on?: InputMaybe<Array<TaskComment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TaskComment_Order_By>>;
  where?: InputMaybe<TaskComment_Bool_Exp>;
};


export type Query_RootTaskComment_AggregateArgs = {
  distinct_on?: InputMaybe<Array<TaskComment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TaskComment_Order_By>>;
  where?: InputMaybe<TaskComment_Bool_Exp>;
};


export type Query_RootTaskComment_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootTask_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Task_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_Order_By>>;
  where?: InputMaybe<Task_Bool_Exp>;
};


export type Query_RootTask_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootTelehealthMeetingArgs = {
  distinct_on?: InputMaybe<Array<TelehealthMeeting_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TelehealthMeeting_Order_By>>;
  where?: InputMaybe<TelehealthMeeting_Bool_Exp>;
};


export type Query_RootTelehealthMeeting_AggregateArgs = {
  distinct_on?: InputMaybe<Array<TelehealthMeeting_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TelehealthMeeting_Order_By>>;
  where?: InputMaybe<TelehealthMeeting_Bool_Exp>;
};


export type Query_RootTelehealthMeeting_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootUnusedAppointmentServiceConsumableTypeArgs = {
  distinct_on?: InputMaybe<Array<UnusedAppointmentServiceConsumableType_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UnusedAppointmentServiceConsumableType_Order_By>>;
  where?: InputMaybe<UnusedAppointmentServiceConsumableType_Bool_Exp>;
};


export type Query_RootUnusedAppointmentServiceConsumableType_AggregateArgs = {
  distinct_on?: InputMaybe<Array<UnusedAppointmentServiceConsumableType_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UnusedAppointmentServiceConsumableType_Order_By>>;
  where?: InputMaybe<UnusedAppointmentServiceConsumableType_Bool_Exp>;
};


export type Query_RootUnusedAppointmentServiceConsumableType_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootUserArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Order_By>>;
  where?: InputMaybe<User_Bool_Exp>;
};


export type Query_RootUserInviteArgs = {
  distinct_on?: InputMaybe<Array<UserInvite_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserInvite_Order_By>>;
  where?: InputMaybe<UserInvite_Bool_Exp>;
};


export type Query_RootUserInvite_AggregateArgs = {
  distinct_on?: InputMaybe<Array<UserInvite_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserInvite_Order_By>>;
  where?: InputMaybe<UserInvite_Bool_Exp>;
};


export type Query_RootUserInvite_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootUserOrganizationArgs = {
  distinct_on?: InputMaybe<Array<UserOrganization_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserOrganization_Order_By>>;
  where?: InputMaybe<UserOrganization_Bool_Exp>;
};


export type Query_RootUserOrganization_AggregateArgs = {
  distinct_on?: InputMaybe<Array<UserOrganization_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserOrganization_Order_By>>;
  where?: InputMaybe<UserOrganization_Bool_Exp>;
};


export type Query_RootUserOrganization_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootUserWorkspaceArgs = {
  distinct_on?: InputMaybe<Array<UserWorkspace_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserWorkspace_Order_By>>;
  where?: InputMaybe<UserWorkspace_Bool_Exp>;
};


export type Query_RootUserWorkspace_AggregateArgs = {
  distinct_on?: InputMaybe<Array<UserWorkspace_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserWorkspace_Order_By>>;
  where?: InputMaybe<UserWorkspace_Bool_Exp>;
};


export type Query_RootUserWorkspace_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootUser_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Order_By>>;
  where?: InputMaybe<User_Bool_Exp>;
};


export type Query_RootUser_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootVerifyManagerPinArgs = {
  value: Scalars['String'];
  workspaceId: Scalars['uuid'];
};


export type Query_RootVitalsArgs = {
  distinct_on?: InputMaybe<Array<Vitals_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vitals_Order_By>>;
  where?: InputMaybe<Vitals_Bool_Exp>;
};


export type Query_RootVitals_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Vitals_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vitals_Order_By>>;
  where?: InputMaybe<Vitals_Bool_Exp>;
};


export type Query_RootVitals_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootWorkspaceArgs = {
  distinct_on?: InputMaybe<Array<Workspace_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Workspace_Order_By>>;
  where?: InputMaybe<Workspace_Bool_Exp>;
};


export type Query_RootWorkspaceConfigurationArgs = {
  distinct_on?: InputMaybe<Array<WorkspaceConfiguration_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<WorkspaceConfiguration_Order_By>>;
  where?: InputMaybe<WorkspaceConfiguration_Bool_Exp>;
};


export type Query_RootWorkspaceConfiguration_AggregateArgs = {
  distinct_on?: InputMaybe<Array<WorkspaceConfiguration_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<WorkspaceConfiguration_Order_By>>;
  where?: InputMaybe<WorkspaceConfiguration_Bool_Exp>;
};


export type Query_RootWorkspaceConfiguration_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootWorkspace_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Workspace_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Workspace_Order_By>>;
  where?: InputMaybe<Workspace_Bool_Exp>;
};


export type Query_RootWorkspace_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootZdReviewArgs = {
  distinct_on?: InputMaybe<Array<ZdReview_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZdReview_Order_By>>;
  where?: InputMaybe<ZdReview_Bool_Exp>;
};


export type Query_RootZdReview_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ZdReview_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZdReview_Order_By>>;
  where?: InputMaybe<ZdReview_Bool_Exp>;
};


export type Query_RootZdReview_By_PkArgs = {
  id: Scalars['uuid'];
};

/** columns and relationships of "recurrenceType" */
export type RecurrenceType = {
  __typename?: 'recurrenceType';
  description: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "recurrenceType" */
export type RecurrenceType_Aggregate = {
  __typename?: 'recurrenceType_aggregate';
  aggregate?: Maybe<RecurrenceType_Aggregate_Fields>;
  nodes: Array<RecurrenceType>;
};

/** aggregate fields of "recurrenceType" */
export type RecurrenceType_Aggregate_Fields = {
  __typename?: 'recurrenceType_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<RecurrenceType_Max_Fields>;
  min?: Maybe<RecurrenceType_Min_Fields>;
};


/** aggregate fields of "recurrenceType" */
export type RecurrenceType_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<RecurrenceType_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "recurrenceType". All fields are combined with a logical 'AND'. */
export type RecurrenceType_Bool_Exp = {
  _and?: InputMaybe<Array<RecurrenceType_Bool_Exp>>;
  _not?: InputMaybe<RecurrenceType_Bool_Exp>;
  _or?: InputMaybe<Array<RecurrenceType_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "recurrenceType" */
export enum RecurrenceType_Constraint {
  /** unique or primary key constraint on columns "value" */
  RecurrenceTypePkey = 'recurrenceType_pkey'
}

export enum RecurrenceType_Enum {
  /** Always */
  Always = 'ALWAYS',
  /** Annually */
  Annually = 'ANNUALLY',
  /** FIRST_SERVICE */
  FirstService = 'FIRST_SERVICE',
  /** Never */
  Never = 'NEVER',
  /** NEW_PATIENTS */
  NewPatients = 'NEW_PATIENTS'
}

/** Boolean expression to compare columns of type "recurrenceType_enum". All fields are combined with logical 'AND'. */
export type RecurrenceType_Enum_Comparison_Exp = {
  _eq?: InputMaybe<RecurrenceType_Enum>;
  _in?: InputMaybe<Array<RecurrenceType_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<RecurrenceType_Enum>;
  _nin?: InputMaybe<Array<RecurrenceType_Enum>>;
};

/** input type for inserting data into table "recurrenceType" */
export type RecurrenceType_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type RecurrenceType_Max_Fields = {
  __typename?: 'recurrenceType_max_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type RecurrenceType_Min_Fields = {
  __typename?: 'recurrenceType_min_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "recurrenceType" */
export type RecurrenceType_Mutation_Response = {
  __typename?: 'recurrenceType_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<RecurrenceType>;
};

/** on_conflict condition type for table "recurrenceType" */
export type RecurrenceType_On_Conflict = {
  constraint: RecurrenceType_Constraint;
  update_columns?: Array<RecurrenceType_Update_Column>;
  where?: InputMaybe<RecurrenceType_Bool_Exp>;
};

/** Ordering options when selecting data from "recurrenceType". */
export type RecurrenceType_Order_By = {
  description?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: recurrenceType */
export type RecurrenceType_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "recurrenceType" */
export enum RecurrenceType_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "recurrenceType" */
export type RecurrenceType_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "recurrenceType" */
export type RecurrenceType_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: RecurrenceType_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type RecurrenceType_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "recurrenceType" */
export enum RecurrenceType_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

export type RecurrenceType_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<RecurrenceType_Set_Input>;
  /** filter the rows which have to be updated */
  where: RecurrenceType_Bool_Exp;
};

/** columns and relationships of "refund" */
export type Refund = {
  __typename?: 'refund';
  amount: Scalars['Int'];
  createdAt: Scalars['timestamptz'];
  currency: Scalars['String'];
  id: Scalars['uuid'];
  isArchived?: Maybe<Scalars['Boolean']>;
  /** An object relationship */
  payment: Payment;
  paymentId: Scalars['uuid'];
  payrixTransactionId?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  status: Scalars['String'];
  type: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  /** An object relationship */
  workspace: Workspace;
  workspaceId: Scalars['uuid'];
};

/** aggregated selection of "refund" */
export type Refund_Aggregate = {
  __typename?: 'refund_aggregate';
  aggregate?: Maybe<Refund_Aggregate_Fields>;
  nodes: Array<Refund>;
};

export type Refund_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Refund_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Refund_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Refund_Aggregate_Bool_Exp_Count>;
};

export type Refund_Aggregate_Bool_Exp_Bool_And = {
  arguments: Refund_Select_Column_Refund_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Refund_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Refund_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Refund_Select_Column_Refund_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Refund_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Refund_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Refund_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Refund_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "refund" */
export type Refund_Aggregate_Fields = {
  __typename?: 'refund_aggregate_fields';
  avg?: Maybe<Refund_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Refund_Max_Fields>;
  min?: Maybe<Refund_Min_Fields>;
  stddev?: Maybe<Refund_Stddev_Fields>;
  stddev_pop?: Maybe<Refund_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Refund_Stddev_Samp_Fields>;
  sum?: Maybe<Refund_Sum_Fields>;
  var_pop?: Maybe<Refund_Var_Pop_Fields>;
  var_samp?: Maybe<Refund_Var_Samp_Fields>;
  variance?: Maybe<Refund_Variance_Fields>;
};


/** aggregate fields of "refund" */
export type Refund_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Refund_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "refund" */
export type Refund_Aggregate_Order_By = {
  avg?: InputMaybe<Refund_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Refund_Max_Order_By>;
  min?: InputMaybe<Refund_Min_Order_By>;
  stddev?: InputMaybe<Refund_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Refund_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Refund_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Refund_Sum_Order_By>;
  var_pop?: InputMaybe<Refund_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Refund_Var_Samp_Order_By>;
  variance?: InputMaybe<Refund_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "refund" */
export type Refund_Arr_Rel_Insert_Input = {
  data: Array<Refund_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Refund_On_Conflict>;
};

/** aggregate avg on columns */
export type Refund_Avg_Fields = {
  __typename?: 'refund_avg_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "refund" */
export type Refund_Avg_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "refund". All fields are combined with a logical 'AND'. */
export type Refund_Bool_Exp = {
  _and?: InputMaybe<Array<Refund_Bool_Exp>>;
  _not?: InputMaybe<Refund_Bool_Exp>;
  _or?: InputMaybe<Array<Refund_Bool_Exp>>;
  amount?: InputMaybe<Int_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  currency?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  isArchived?: InputMaybe<Boolean_Comparison_Exp>;
  payment?: InputMaybe<Payment_Bool_Exp>;
  paymentId?: InputMaybe<Uuid_Comparison_Exp>;
  payrixTransactionId?: InputMaybe<String_Comparison_Exp>;
  reason?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  workspace?: InputMaybe<Workspace_Bool_Exp>;
  workspaceId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "refund" */
export enum Refund_Constraint {
  /** unique or primary key constraint on columns "id" */
  RefundPkey = 'refund_pkey'
}

/** input type for incrementing numeric columns in table "refund" */
export type Refund_Inc_Input = {
  amount?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "refund" */
export type Refund_Insert_Input = {
  amount?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  currency?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  isArchived?: InputMaybe<Scalars['Boolean']>;
  payment?: InputMaybe<Payment_Obj_Rel_Insert_Input>;
  paymentId?: InputMaybe<Scalars['uuid']>;
  payrixTransactionId?: InputMaybe<Scalars['String']>;
  reason?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspace?: InputMaybe<Workspace_Obj_Rel_Insert_Input>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Refund_Max_Fields = {
  __typename?: 'refund_max_fields';
  amount?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  currency?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  paymentId?: Maybe<Scalars['uuid']>;
  payrixTransactionId?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "refund" */
export type Refund_Max_Order_By = {
  amount?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  currency?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  paymentId?: InputMaybe<Order_By>;
  payrixTransactionId?: InputMaybe<Order_By>;
  reason?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Refund_Min_Fields = {
  __typename?: 'refund_min_fields';
  amount?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  currency?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  paymentId?: Maybe<Scalars['uuid']>;
  payrixTransactionId?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "refund" */
export type Refund_Min_Order_By = {
  amount?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  currency?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  paymentId?: InputMaybe<Order_By>;
  payrixTransactionId?: InputMaybe<Order_By>;
  reason?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "refund" */
export type Refund_Mutation_Response = {
  __typename?: 'refund_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Refund>;
};

/** on_conflict condition type for table "refund" */
export type Refund_On_Conflict = {
  constraint: Refund_Constraint;
  update_columns?: Array<Refund_Update_Column>;
  where?: InputMaybe<Refund_Bool_Exp>;
};

/** Ordering options when selecting data from "refund". */
export type Refund_Order_By = {
  amount?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  currency?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  isArchived?: InputMaybe<Order_By>;
  payment?: InputMaybe<Payment_Order_By>;
  paymentId?: InputMaybe<Order_By>;
  payrixTransactionId?: InputMaybe<Order_By>;
  reason?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  workspace?: InputMaybe<Workspace_Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: refund */
export type Refund_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "refund" */
export enum Refund_Select_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Currency = 'currency',
  /** column name */
  Id = 'id',
  /** column name */
  IsArchived = 'isArchived',
  /** column name */
  PaymentId = 'paymentId',
  /** column name */
  PayrixTransactionId = 'payrixTransactionId',
  /** column name */
  Reason = 'reason',
  /** column name */
  Status = 'status',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WorkspaceId = 'workspaceId'
}

/** select "refund_aggregate_bool_exp_bool_and_arguments_columns" columns of table "refund" */
export enum Refund_Select_Column_Refund_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsArchived = 'isArchived'
}

/** select "refund_aggregate_bool_exp_bool_or_arguments_columns" columns of table "refund" */
export enum Refund_Select_Column_Refund_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsArchived = 'isArchived'
}

/** input type for updating data in table "refund" */
export type Refund_Set_Input = {
  amount?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  currency?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  isArchived?: InputMaybe<Scalars['Boolean']>;
  paymentId?: InputMaybe<Scalars['uuid']>;
  payrixTransactionId?: InputMaybe<Scalars['String']>;
  reason?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Refund_Stddev_Fields = {
  __typename?: 'refund_stddev_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "refund" */
export type Refund_Stddev_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Refund_Stddev_Pop_Fields = {
  __typename?: 'refund_stddev_pop_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "refund" */
export type Refund_Stddev_Pop_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Refund_Stddev_Samp_Fields = {
  __typename?: 'refund_stddev_samp_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "refund" */
export type Refund_Stddev_Samp_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "refund" */
export type Refund_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Refund_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Refund_Stream_Cursor_Value_Input = {
  amount?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  currency?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  isArchived?: InputMaybe<Scalars['Boolean']>;
  paymentId?: InputMaybe<Scalars['uuid']>;
  payrixTransactionId?: InputMaybe<Scalars['String']>;
  reason?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Refund_Sum_Fields = {
  __typename?: 'refund_sum_fields';
  amount?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "refund" */
export type Refund_Sum_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** update columns of table "refund" */
export enum Refund_Update_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Currency = 'currency',
  /** column name */
  Id = 'id',
  /** column name */
  IsArchived = 'isArchived',
  /** column name */
  PaymentId = 'paymentId',
  /** column name */
  PayrixTransactionId = 'payrixTransactionId',
  /** column name */
  Reason = 'reason',
  /** column name */
  Status = 'status',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WorkspaceId = 'workspaceId'
}

export type Refund_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Refund_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Refund_Set_Input>;
  /** filter the rows which have to be updated */
  where: Refund_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Refund_Var_Pop_Fields = {
  __typename?: 'refund_var_pop_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "refund" */
export type Refund_Var_Pop_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Refund_Var_Samp_Fields = {
  __typename?: 'refund_var_samp_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "refund" */
export type Refund_Var_Samp_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Refund_Variance_Fields = {
  __typename?: 'refund_variance_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "refund" */
export type Refund_Variance_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** columns and relationships of "resolutionStatus" */
export type ResolutionStatus = {
  __typename?: 'resolutionStatus';
  description: Scalars['String'];
  /** An array relationship */
  jobs: Array<Job>;
  /** An aggregate relationship */
  jobs_aggregate: Job_Aggregate;
  value: Scalars['String'];
};


/** columns and relationships of "resolutionStatus" */
export type ResolutionStatusJobsArgs = {
  distinct_on?: InputMaybe<Array<Job_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Job_Order_By>>;
  where?: InputMaybe<Job_Bool_Exp>;
};


/** columns and relationships of "resolutionStatus" */
export type ResolutionStatusJobs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Job_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Job_Order_By>>;
  where?: InputMaybe<Job_Bool_Exp>;
};

/** aggregated selection of "resolutionStatus" */
export type ResolutionStatus_Aggregate = {
  __typename?: 'resolutionStatus_aggregate';
  aggregate?: Maybe<ResolutionStatus_Aggregate_Fields>;
  nodes: Array<ResolutionStatus>;
};

/** aggregate fields of "resolutionStatus" */
export type ResolutionStatus_Aggregate_Fields = {
  __typename?: 'resolutionStatus_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ResolutionStatus_Max_Fields>;
  min?: Maybe<ResolutionStatus_Min_Fields>;
};


/** aggregate fields of "resolutionStatus" */
export type ResolutionStatus_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<ResolutionStatus_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "resolutionStatus". All fields are combined with a logical 'AND'. */
export type ResolutionStatus_Bool_Exp = {
  _and?: InputMaybe<Array<ResolutionStatus_Bool_Exp>>;
  _not?: InputMaybe<ResolutionStatus_Bool_Exp>;
  _or?: InputMaybe<Array<ResolutionStatus_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  jobs?: InputMaybe<Job_Bool_Exp>;
  jobs_aggregate?: InputMaybe<Job_Aggregate_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "resolutionStatus" */
export enum ResolutionStatus_Constraint {
  /** unique or primary key constraint on columns "value" */
  ResolutionStatusPkey = 'resolutionStatus_pkey'
}

export enum ResolutionStatus_Enum {
  /** Canceled */
  Canceled = 'CANCELED',
  /** One or more items failed after several retries. */
  FailureMaxRetries = 'FAILURE_MAX_RETRIES',
  /** Encountered and unrecoverable error */
  FailureUnrecoverable = 'FAILURE_UNRECOVERABLE',
  /** Pending */
  Pending = 'PENDING',
  /** Successfully processed */
  Success = 'SUCCESS'
}

/** Boolean expression to compare columns of type "resolutionStatus_enum". All fields are combined with logical 'AND'. */
export type ResolutionStatus_Enum_Comparison_Exp = {
  _eq?: InputMaybe<ResolutionStatus_Enum>;
  _in?: InputMaybe<Array<ResolutionStatus_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<ResolutionStatus_Enum>;
  _nin?: InputMaybe<Array<ResolutionStatus_Enum>>;
};

/** input type for inserting data into table "resolutionStatus" */
export type ResolutionStatus_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  jobs?: InputMaybe<Job_Arr_Rel_Insert_Input>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type ResolutionStatus_Max_Fields = {
  __typename?: 'resolutionStatus_max_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type ResolutionStatus_Min_Fields = {
  __typename?: 'resolutionStatus_min_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "resolutionStatus" */
export type ResolutionStatus_Mutation_Response = {
  __typename?: 'resolutionStatus_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ResolutionStatus>;
};

/** input type for inserting object relation for remote table "resolutionStatus" */
export type ResolutionStatus_Obj_Rel_Insert_Input = {
  data: ResolutionStatus_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<ResolutionStatus_On_Conflict>;
};

/** on_conflict condition type for table "resolutionStatus" */
export type ResolutionStatus_On_Conflict = {
  constraint: ResolutionStatus_Constraint;
  update_columns?: Array<ResolutionStatus_Update_Column>;
  where?: InputMaybe<ResolutionStatus_Bool_Exp>;
};

/** Ordering options when selecting data from "resolutionStatus". */
export type ResolutionStatus_Order_By = {
  description?: InputMaybe<Order_By>;
  jobs_aggregate?: InputMaybe<Job_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: resolutionStatus */
export type ResolutionStatus_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "resolutionStatus" */
export enum ResolutionStatus_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "resolutionStatus" */
export type ResolutionStatus_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "resolutionStatus" */
export type ResolutionStatus_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ResolutionStatus_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ResolutionStatus_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "resolutionStatus" */
export enum ResolutionStatus_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

export type ResolutionStatus_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ResolutionStatus_Set_Input>;
  /** filter the rows which have to be updated */
  where: ResolutionStatus_Bool_Exp;
};

/** columns and relationships of "rewardProgram" */
export type RewardProgram = {
  __typename?: 'rewardProgram';
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  termsAndConditions: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  /** An object relationship */
  workspace: Workspace;
  workspaceId: Scalars['uuid'];
};

/** columns and relationships of "rewardProgramEnrollment" */
export type RewardProgramEnrollment = {
  __typename?: 'rewardProgramEnrollment';
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An object relationship */
  patient: Patient;
  patientId: Scalars['uuid'];
  /** An object relationship */
  rewardProgram: RewardProgram;
  rewardProgramId: Scalars['uuid'];
  updatedAt: Scalars['timestamptz'];
  /** An object relationship */
  workspace: Workspace;
  workspaceId: Scalars['uuid'];
};

/** aggregated selection of "rewardProgramEnrollment" */
export type RewardProgramEnrollment_Aggregate = {
  __typename?: 'rewardProgramEnrollment_aggregate';
  aggregate?: Maybe<RewardProgramEnrollment_Aggregate_Fields>;
  nodes: Array<RewardProgramEnrollment>;
};

export type RewardProgramEnrollment_Aggregate_Bool_Exp = {
  count?: InputMaybe<RewardProgramEnrollment_Aggregate_Bool_Exp_Count>;
};

export type RewardProgramEnrollment_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<RewardProgramEnrollment_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<RewardProgramEnrollment_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "rewardProgramEnrollment" */
export type RewardProgramEnrollment_Aggregate_Fields = {
  __typename?: 'rewardProgramEnrollment_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<RewardProgramEnrollment_Max_Fields>;
  min?: Maybe<RewardProgramEnrollment_Min_Fields>;
};


/** aggregate fields of "rewardProgramEnrollment" */
export type RewardProgramEnrollment_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<RewardProgramEnrollment_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "rewardProgramEnrollment" */
export type RewardProgramEnrollment_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<RewardProgramEnrollment_Max_Order_By>;
  min?: InputMaybe<RewardProgramEnrollment_Min_Order_By>;
};

/** input type for inserting array relation for remote table "rewardProgramEnrollment" */
export type RewardProgramEnrollment_Arr_Rel_Insert_Input = {
  data: Array<RewardProgramEnrollment_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<RewardProgramEnrollment_On_Conflict>;
};

/** Boolean expression to filter rows from the table "rewardProgramEnrollment". All fields are combined with a logical 'AND'. */
export type RewardProgramEnrollment_Bool_Exp = {
  _and?: InputMaybe<Array<RewardProgramEnrollment_Bool_Exp>>;
  _not?: InputMaybe<RewardProgramEnrollment_Bool_Exp>;
  _or?: InputMaybe<Array<RewardProgramEnrollment_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  patient?: InputMaybe<Patient_Bool_Exp>;
  patientId?: InputMaybe<Uuid_Comparison_Exp>;
  rewardProgram?: InputMaybe<RewardProgram_Bool_Exp>;
  rewardProgramId?: InputMaybe<Uuid_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  workspace?: InputMaybe<Workspace_Bool_Exp>;
  workspaceId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "rewardProgramEnrollment" */
export enum RewardProgramEnrollment_Constraint {
  /** unique or primary key constraint on columns "id" */
  RewardProgramEnrollmentPkey = 'rewardProgramEnrollment_pkey',
  /** unique or primary key constraint on columns "rewardProgramId", "patientId", "workspaceId" */
  RewardProgramEnrollmentWorkspaceIdRewardProgramIdPatientKey = 'rewardProgramEnrollment_workspaceId_rewardProgramId_patient_key'
}

/** input type for inserting data into table "rewardProgramEnrollment" */
export type RewardProgramEnrollment_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  patient?: InputMaybe<Patient_Obj_Rel_Insert_Input>;
  patientId?: InputMaybe<Scalars['uuid']>;
  rewardProgram?: InputMaybe<RewardProgram_Obj_Rel_Insert_Input>;
  rewardProgramId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspace?: InputMaybe<Workspace_Obj_Rel_Insert_Input>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type RewardProgramEnrollment_Max_Fields = {
  __typename?: 'rewardProgramEnrollment_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  patientId?: Maybe<Scalars['uuid']>;
  rewardProgramId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "rewardProgramEnrollment" */
export type RewardProgramEnrollment_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patientId?: InputMaybe<Order_By>;
  rewardProgramId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type RewardProgramEnrollment_Min_Fields = {
  __typename?: 'rewardProgramEnrollment_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  patientId?: Maybe<Scalars['uuid']>;
  rewardProgramId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "rewardProgramEnrollment" */
export type RewardProgramEnrollment_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patientId?: InputMaybe<Order_By>;
  rewardProgramId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "rewardProgramEnrollment" */
export type RewardProgramEnrollment_Mutation_Response = {
  __typename?: 'rewardProgramEnrollment_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<RewardProgramEnrollment>;
};

/** on_conflict condition type for table "rewardProgramEnrollment" */
export type RewardProgramEnrollment_On_Conflict = {
  constraint: RewardProgramEnrollment_Constraint;
  update_columns?: Array<RewardProgramEnrollment_Update_Column>;
  where?: InputMaybe<RewardProgramEnrollment_Bool_Exp>;
};

/** Ordering options when selecting data from "rewardProgramEnrollment". */
export type RewardProgramEnrollment_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patient?: InputMaybe<Patient_Order_By>;
  patientId?: InputMaybe<Order_By>;
  rewardProgram?: InputMaybe<RewardProgram_Order_By>;
  rewardProgramId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  workspace?: InputMaybe<Workspace_Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: rewardProgramEnrollment */
export type RewardProgramEnrollment_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "rewardProgramEnrollment" */
export enum RewardProgramEnrollment_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  PatientId = 'patientId',
  /** column name */
  RewardProgramId = 'rewardProgramId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WorkspaceId = 'workspaceId'
}

/** input type for updating data in table "rewardProgramEnrollment" */
export type RewardProgramEnrollment_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  patientId?: InputMaybe<Scalars['uuid']>;
  rewardProgramId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "rewardProgramEnrollment" */
export type RewardProgramEnrollment_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: RewardProgramEnrollment_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type RewardProgramEnrollment_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  patientId?: InputMaybe<Scalars['uuid']>;
  rewardProgramId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "rewardProgramEnrollment" */
export enum RewardProgramEnrollment_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  PatientId = 'patientId',
  /** column name */
  RewardProgramId = 'rewardProgramId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WorkspaceId = 'workspaceId'
}

export type RewardProgramEnrollment_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<RewardProgramEnrollment_Set_Input>;
  /** filter the rows which have to be updated */
  where: RewardProgramEnrollment_Bool_Exp;
};

/** aggregated selection of "rewardProgram" */
export type RewardProgram_Aggregate = {
  __typename?: 'rewardProgram_aggregate';
  aggregate?: Maybe<RewardProgram_Aggregate_Fields>;
  nodes: Array<RewardProgram>;
};

/** aggregate fields of "rewardProgram" */
export type RewardProgram_Aggregate_Fields = {
  __typename?: 'rewardProgram_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<RewardProgram_Max_Fields>;
  min?: Maybe<RewardProgram_Min_Fields>;
};


/** aggregate fields of "rewardProgram" */
export type RewardProgram_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<RewardProgram_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "rewardProgram". All fields are combined with a logical 'AND'. */
export type RewardProgram_Bool_Exp = {
  _and?: InputMaybe<Array<RewardProgram_Bool_Exp>>;
  _not?: InputMaybe<RewardProgram_Bool_Exp>;
  _or?: InputMaybe<Array<RewardProgram_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  termsAndConditions?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  workspace?: InputMaybe<Workspace_Bool_Exp>;
  workspaceId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "rewardProgram" */
export enum RewardProgram_Constraint {
  /** unique or primary key constraint on columns "id" */
  RewardProgramPkey = 'rewardProgram_pkey',
  /** unique or primary key constraint on columns "workspaceId" */
  RewardProgramWorkspaceIdKey = 'rewardProgram_workspaceId_key'
}

/** input type for inserting data into table "rewardProgram" */
export type RewardProgram_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  termsAndConditions?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspace?: InputMaybe<Workspace_Obj_Rel_Insert_Input>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type RewardProgram_Max_Fields = {
  __typename?: 'rewardProgram_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  termsAndConditions?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type RewardProgram_Min_Fields = {
  __typename?: 'rewardProgram_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  termsAndConditions?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "rewardProgram" */
export type RewardProgram_Mutation_Response = {
  __typename?: 'rewardProgram_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<RewardProgram>;
};

/** input type for inserting object relation for remote table "rewardProgram" */
export type RewardProgram_Obj_Rel_Insert_Input = {
  data: RewardProgram_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<RewardProgram_On_Conflict>;
};

/** on_conflict condition type for table "rewardProgram" */
export type RewardProgram_On_Conflict = {
  constraint: RewardProgram_Constraint;
  update_columns?: Array<RewardProgram_Update_Column>;
  where?: InputMaybe<RewardProgram_Bool_Exp>;
};

/** Ordering options when selecting data from "rewardProgram". */
export type RewardProgram_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  termsAndConditions?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  workspace?: InputMaybe<Workspace_Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: rewardProgram */
export type RewardProgram_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "rewardProgram" */
export enum RewardProgram_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  TermsAndConditions = 'termsAndConditions',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WorkspaceId = 'workspaceId'
}

/** input type for updating data in table "rewardProgram" */
export type RewardProgram_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  termsAndConditions?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "rewardProgram" */
export type RewardProgram_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: RewardProgram_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type RewardProgram_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  termsAndConditions?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "rewardProgram" */
export enum RewardProgram_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  TermsAndConditions = 'termsAndConditions',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WorkspaceId = 'workspaceId'
}

export type RewardProgram_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<RewardProgram_Set_Input>;
  /** filter the rows which have to be updated */
  where: RewardProgram_Bool_Exp;
};

/** columns and relationships of "room" */
export type Room = {
  __typename?: 'room';
  active: Scalars['Boolean'];
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  locationId: Scalars['uuid'];
  name: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  workspaceId: Scalars['uuid'];
};

/** aggregated selection of "room" */
export type Room_Aggregate = {
  __typename?: 'room_aggregate';
  aggregate?: Maybe<Room_Aggregate_Fields>;
  nodes: Array<Room>;
};

export type Room_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Room_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Room_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Room_Aggregate_Bool_Exp_Count>;
};

export type Room_Aggregate_Bool_Exp_Bool_And = {
  arguments: Room_Select_Column_Room_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Room_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Room_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Room_Select_Column_Room_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Room_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Room_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Room_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Room_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "room" */
export type Room_Aggregate_Fields = {
  __typename?: 'room_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Room_Max_Fields>;
  min?: Maybe<Room_Min_Fields>;
};


/** aggregate fields of "room" */
export type Room_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Room_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "room" */
export type Room_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Room_Max_Order_By>;
  min?: InputMaybe<Room_Min_Order_By>;
};

/** input type for inserting array relation for remote table "room" */
export type Room_Arr_Rel_Insert_Input = {
  data: Array<Room_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Room_On_Conflict>;
};

/** Boolean expression to filter rows from the table "room". All fields are combined with a logical 'AND'. */
export type Room_Bool_Exp = {
  _and?: InputMaybe<Array<Room_Bool_Exp>>;
  _not?: InputMaybe<Room_Bool_Exp>;
  _or?: InputMaybe<Array<Room_Bool_Exp>>;
  active?: InputMaybe<Boolean_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  locationId?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  workspaceId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "room" */
export enum Room_Constraint {
  /** unique or primary key constraint on columns "id" */
  RoomPkey = 'room_pkey'
}

/** input type for inserting data into table "room" */
export type Room_Insert_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  locationId?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Room_Max_Fields = {
  __typename?: 'room_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  locationId?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "room" */
export type Room_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  locationId?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Room_Min_Fields = {
  __typename?: 'room_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  locationId?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "room" */
export type Room_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  locationId?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "room" */
export type Room_Mutation_Response = {
  __typename?: 'room_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Room>;
};

/** input type for inserting object relation for remote table "room" */
export type Room_Obj_Rel_Insert_Input = {
  data: Room_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Room_On_Conflict>;
};

/** on_conflict condition type for table "room" */
export type Room_On_Conflict = {
  constraint: Room_Constraint;
  update_columns?: Array<Room_Update_Column>;
  where?: InputMaybe<Room_Bool_Exp>;
};

/** Ordering options when selecting data from "room". */
export type Room_Order_By = {
  active?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  locationId?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: room */
export type Room_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "room" */
export enum Room_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  LocationId = 'locationId',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WorkspaceId = 'workspaceId'
}

/** select "room_aggregate_bool_exp_bool_and_arguments_columns" columns of table "room" */
export enum Room_Select_Column_Room_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Active = 'active'
}

/** select "room_aggregate_bool_exp_bool_or_arguments_columns" columns of table "room" */
export enum Room_Select_Column_Room_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Active = 'active'
}

/** input type for updating data in table "room" */
export type Room_Set_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  locationId?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "room" */
export type Room_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Room_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Room_Stream_Cursor_Value_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  locationId?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "room" */
export enum Room_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  LocationId = 'locationId',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WorkspaceId = 'workspaceId'
}

export type Room_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Room_Set_Input>;
  /** filter the rows which have to be updated */
  where: Room_Bool_Exp;
};

/** columns and relationships of "rsReview" */
export type RsReview = {
  __typename?: 'rsReview';
  body: Scalars['String'];
  cost?: Maybe<Scalars['String']>;
  createdAt: Scalars['timestamptz'];
  externalId: Scalars['String'];
  id: Scalars['uuid'];
  isFeatured?: Maybe<Scalars['Boolean']>;
  rating?: Maybe<Scalars['String']>;
  response?: Maybe<Scalars['String']>;
  reviewDate?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  topic?: Maybe<Scalars['String']>;
  topicId?: Maybe<Scalars['String']>;
  treatmentDate?: Maybe<Scalars['String']>;
  updatedAt: Scalars['timestamptz'];
  uri?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  userName?: Maybe<Scalars['String']>;
  userPhotoUri?: Maybe<Scalars['String']>;
};

/** aggregated selection of "rsReview" */
export type RsReview_Aggregate = {
  __typename?: 'rsReview_aggregate';
  aggregate?: Maybe<RsReview_Aggregate_Fields>;
  nodes: Array<RsReview>;
};

/** aggregate fields of "rsReview" */
export type RsReview_Aggregate_Fields = {
  __typename?: 'rsReview_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<RsReview_Max_Fields>;
  min?: Maybe<RsReview_Min_Fields>;
};


/** aggregate fields of "rsReview" */
export type RsReview_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<RsReview_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "rsReview". All fields are combined with a logical 'AND'. */
export type RsReview_Bool_Exp = {
  _and?: InputMaybe<Array<RsReview_Bool_Exp>>;
  _not?: InputMaybe<RsReview_Bool_Exp>;
  _or?: InputMaybe<Array<RsReview_Bool_Exp>>;
  body?: InputMaybe<String_Comparison_Exp>;
  cost?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  externalId?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  isFeatured?: InputMaybe<Boolean_Comparison_Exp>;
  rating?: InputMaybe<String_Comparison_Exp>;
  response?: InputMaybe<String_Comparison_Exp>;
  reviewDate?: InputMaybe<String_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  topic?: InputMaybe<String_Comparison_Exp>;
  topicId?: InputMaybe<String_Comparison_Exp>;
  treatmentDate?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  uri?: InputMaybe<String_Comparison_Exp>;
  userId?: InputMaybe<String_Comparison_Exp>;
  userName?: InputMaybe<String_Comparison_Exp>;
  userPhotoUri?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "rsReview" */
export enum RsReview_Constraint {
  /** unique or primary key constraint on columns "externalId" */
  RsReviewExternalIdKey = 'RSReview_externalId_key',
  /** unique or primary key constraint on columns "id" */
  RsReviewPkey = 'RSReview_pkey'
}

/** input type for inserting data into table "rsReview" */
export type RsReview_Insert_Input = {
  body?: InputMaybe<Scalars['String']>;
  cost?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  externalId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  isFeatured?: InputMaybe<Scalars['Boolean']>;
  rating?: InputMaybe<Scalars['String']>;
  response?: InputMaybe<Scalars['String']>;
  reviewDate?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  topic?: InputMaybe<Scalars['String']>;
  topicId?: InputMaybe<Scalars['String']>;
  treatmentDate?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  uri?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
  userName?: InputMaybe<Scalars['String']>;
  userPhotoUri?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type RsReview_Max_Fields = {
  __typename?: 'rsReview_max_fields';
  body?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  externalId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  rating?: Maybe<Scalars['String']>;
  response?: Maybe<Scalars['String']>;
  reviewDate?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  topic?: Maybe<Scalars['String']>;
  topicId?: Maybe<Scalars['String']>;
  treatmentDate?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  uri?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  userName?: Maybe<Scalars['String']>;
  userPhotoUri?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type RsReview_Min_Fields = {
  __typename?: 'rsReview_min_fields';
  body?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  externalId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  rating?: Maybe<Scalars['String']>;
  response?: Maybe<Scalars['String']>;
  reviewDate?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  topic?: Maybe<Scalars['String']>;
  topicId?: Maybe<Scalars['String']>;
  treatmentDate?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  uri?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  userName?: Maybe<Scalars['String']>;
  userPhotoUri?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "rsReview" */
export type RsReview_Mutation_Response = {
  __typename?: 'rsReview_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<RsReview>;
};

/** on_conflict condition type for table "rsReview" */
export type RsReview_On_Conflict = {
  constraint: RsReview_Constraint;
  update_columns?: Array<RsReview_Update_Column>;
  where?: InputMaybe<RsReview_Bool_Exp>;
};

/** Ordering options when selecting data from "rsReview". */
export type RsReview_Order_By = {
  body?: InputMaybe<Order_By>;
  cost?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  externalId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  isFeatured?: InputMaybe<Order_By>;
  rating?: InputMaybe<Order_By>;
  response?: InputMaybe<Order_By>;
  reviewDate?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  topic?: InputMaybe<Order_By>;
  topicId?: InputMaybe<Order_By>;
  treatmentDate?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  uri?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
  userName?: InputMaybe<Order_By>;
  userPhotoUri?: InputMaybe<Order_By>;
};

/** primary key columns input for table: rsReview */
export type RsReview_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "rsReview" */
export enum RsReview_Select_Column {
  /** column name */
  Body = 'body',
  /** column name */
  Cost = 'cost',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  ExternalId = 'externalId',
  /** column name */
  Id = 'id',
  /** column name */
  IsFeatured = 'isFeatured',
  /** column name */
  Rating = 'rating',
  /** column name */
  Response = 'response',
  /** column name */
  ReviewDate = 'reviewDate',
  /** column name */
  Title = 'title',
  /** column name */
  Topic = 'topic',
  /** column name */
  TopicId = 'topicId',
  /** column name */
  TreatmentDate = 'treatmentDate',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Uri = 'uri',
  /** column name */
  UserId = 'userId',
  /** column name */
  UserName = 'userName',
  /** column name */
  UserPhotoUri = 'userPhotoUri'
}

/** input type for updating data in table "rsReview" */
export type RsReview_Set_Input = {
  body?: InputMaybe<Scalars['String']>;
  cost?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  externalId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  isFeatured?: InputMaybe<Scalars['Boolean']>;
  rating?: InputMaybe<Scalars['String']>;
  response?: InputMaybe<Scalars['String']>;
  reviewDate?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  topic?: InputMaybe<Scalars['String']>;
  topicId?: InputMaybe<Scalars['String']>;
  treatmentDate?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  uri?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
  userName?: InputMaybe<Scalars['String']>;
  userPhotoUri?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "rsReview" */
export type RsReview_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: RsReview_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type RsReview_Stream_Cursor_Value_Input = {
  body?: InputMaybe<Scalars['String']>;
  cost?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  externalId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  isFeatured?: InputMaybe<Scalars['Boolean']>;
  rating?: InputMaybe<Scalars['String']>;
  response?: InputMaybe<Scalars['String']>;
  reviewDate?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  topic?: InputMaybe<Scalars['String']>;
  topicId?: InputMaybe<Scalars['String']>;
  treatmentDate?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  uri?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
  userName?: InputMaybe<Scalars['String']>;
  userPhotoUri?: InputMaybe<Scalars['String']>;
};

/** update columns of table "rsReview" */
export enum RsReview_Update_Column {
  /** column name */
  Body = 'body',
  /** column name */
  Cost = 'cost',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  ExternalId = 'externalId',
  /** column name */
  Id = 'id',
  /** column name */
  IsFeatured = 'isFeatured',
  /** column name */
  Rating = 'rating',
  /** column name */
  Response = 'response',
  /** column name */
  ReviewDate = 'reviewDate',
  /** column name */
  Title = 'title',
  /** column name */
  Topic = 'topic',
  /** column name */
  TopicId = 'topicId',
  /** column name */
  TreatmentDate = 'treatmentDate',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Uri = 'uri',
  /** column name */
  UserId = 'userId',
  /** column name */
  UserName = 'userName',
  /** column name */
  UserPhotoUri = 'userPhotoUri'
}

export type RsReview_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<RsReview_Set_Input>;
  /** filter the rows which have to be updated */
  where: RsReview_Bool_Exp;
};

export type SearchConsumableTypes_Args = {
  search?: InputMaybe<Scalars['String']>;
  workspace_id?: InputMaybe<Scalars['uuid']>;
};

export type SearchPatients_Args = {
  search?: InputMaybe<Scalars['String']>;
};

export type SearchProviderSchedule_Args = {
  locationids?: InputMaybe<Scalars['_uuid']>;
  providerids?: InputMaybe<Scalars['_uuid']>;
  workday?: InputMaybe<Scalars['String']>;
  workspaceid?: InputMaybe<Scalars['uuid']>;
};

export type Search_Note_Title_Args = {
  search?: InputMaybe<Scalars['String']>;
  workspace_id?: InputMaybe<Scalars['uuid']>;
};

export type Search_PatientWorkspace_Args = {
  search?: InputMaybe<Scalars['String']>;
  workspace_id?: InputMaybe<Scalars['uuid']>;
};

/** columns and relationships of "service" */
export type Service = {
  __typename?: 'service';
  active?: Maybe<Scalars['Boolean']>;
  allowGratuity: Scalars['Boolean'];
  /** An array relationship */
  appointmentServices: Array<AppointmentService>;
  /** An aggregate relationship */
  appointmentServices_aggregate: AppointmentService_Aggregate;
  /** An object relationship */
  appointmentType?: Maybe<AppointmentType>;
  appointmentTypeId?: Maybe<Scalars['uuid']>;
  archived?: Maybe<Scalars['Boolean']>;
  availableInStore?: Maybe<Scalars['Boolean']>;
  canBeBookedOnline?: Maybe<Scalars['Boolean']>;
  color?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Int']>;
  created_at: Scalars['timestamptz'];
  deposit?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  deviceBufferTime?: Maybe<Scalars['Int']>;
  id: Scalars['uuid'];
  image?: Maybe<Scalars['String']>;
  importJobId?: Maybe<Scalars['uuid']>;
  /** An array relationship */
  incompatibleServices: Array<IncompatibleServices>;
  /** An array relationship */
  incompatibleServicesByServicebid: Array<IncompatibleServices>;
  /** An aggregate relationship */
  incompatibleServicesByServicebid_aggregate: IncompatibleServices_Aggregate;
  /** An aggregate relationship */
  incompatibleServices_aggregate: IncompatibleServices_Aggregate;
  /** An array relationship */
  invoiceServiceItems: Array<InvoiceServiceItem>;
  /** An aggregate relationship */
  invoiceServiceItems_aggregate: InvoiceServiceItem_Aggregate;
  isProduct?: Maybe<Scalars['Boolean']>;
  /** An array relationship */
  locationServices: Array<LocationService>;
  /** An aggregate relationship */
  locationServices_aggregate: LocationService_Aggregate;
  minutesToPerform: Scalars['Int'];
  name: Scalars['String'];
  newPatientMinutesToPerform?: Maybe<Scalars['Int']>;
  onlineBookingRules?: Maybe<Scalars['jsonb']>;
  patientPortalInviteAddendum?: Maybe<Scalars['String']>;
  patientPortalInviteSMSAddendum?: Maybe<Scalars['String']>;
  /** An object relationship */
  postcareDocument?: Maybe<Document>;
  postcareDocumentId?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  precareDocument?: Maybe<Document>;
  precareDocumentId?: Maybe<Scalars['uuid']>;
  price: Scalars['Int'];
  pricingModel: Scalars['String'];
  productBarcodeId?: Maybe<Scalars['String']>;
  requiresDeposit?: Maybe<Scalars['Boolean']>;
  /** An array relationship */
  serviceAddOns: Array<ServiceAddOn>;
  /** An aggregate relationship */
  serviceAddOns_aggregate: ServiceAddOn_Aggregate;
  /** An object relationship */
  serviceCategory?: Maybe<ServiceCategory>;
  serviceCategoryId?: Maybe<Scalars['uuid']>;
  /** An array relationship */
  serviceConsents: Array<ServiceConsent>;
  /** An aggregate relationship */
  serviceConsents_aggregate: ServiceConsent_Aggregate;
  /** An array relationship */
  serviceConsumableTypes: Array<ServiceConsumableType>;
  /** An aggregate relationship */
  serviceConsumableTypes_aggregate: ServiceConsumableType_Aggregate;
  /** An array relationship */
  serviceCoupons: Array<ServiceCoupon>;
  /** An aggregate relationship */
  serviceCoupons_aggregate: ServiceCoupon_Aggregate;
  /** An array relationship */
  serviceCustomForms: Array<ServiceCustomForm>;
  /** An aggregate relationship */
  serviceCustomForms_aggregate: ServiceCustomForm_Aggregate;
  /** An array relationship */
  serviceDeviceTypes: Array<ServiceDeviceType>;
  /** An aggregate relationship */
  serviceDeviceTypes_aggregate: ServiceDeviceType_Aggregate;
  /** An array relationship */
  serviceProviders: Array<ServiceProvider>;
  /** An aggregate relationship */
  serviceProviders_aggregate: ServiceProvider_Aggregate;
  /** An object relationship */
  serviceUnit?: Maybe<ServiceUnit>;
  serviceUnitId?: Maybe<Scalars['uuid']>;
  taxRate?: Maybe<Scalars['float8']>;
  triggersPatientPortalInvite?: Maybe<Scalars['Boolean']>;
  updated_at: Scalars['timestamptz'];
  viewOnlyInStore?: Maybe<Scalars['Boolean']>;
  /** An object relationship */
  workspace: Workspace;
  workspaceId: Scalars['uuid'];
};


/** columns and relationships of "service" */
export type ServiceAppointmentServicesArgs = {
  distinct_on?: InputMaybe<Array<AppointmentService_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AppointmentService_Order_By>>;
  where?: InputMaybe<AppointmentService_Bool_Exp>;
};


/** columns and relationships of "service" */
export type ServiceAppointmentServices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<AppointmentService_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AppointmentService_Order_By>>;
  where?: InputMaybe<AppointmentService_Bool_Exp>;
};


/** columns and relationships of "service" */
export type ServiceIncompatibleServicesArgs = {
  distinct_on?: InputMaybe<Array<IncompatibleServices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IncompatibleServices_Order_By>>;
  where?: InputMaybe<IncompatibleServices_Bool_Exp>;
};


/** columns and relationships of "service" */
export type ServiceIncompatibleServicesByServicebidArgs = {
  distinct_on?: InputMaybe<Array<IncompatibleServices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IncompatibleServices_Order_By>>;
  where?: InputMaybe<IncompatibleServices_Bool_Exp>;
};


/** columns and relationships of "service" */
export type ServiceIncompatibleServicesByServicebid_AggregateArgs = {
  distinct_on?: InputMaybe<Array<IncompatibleServices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IncompatibleServices_Order_By>>;
  where?: InputMaybe<IncompatibleServices_Bool_Exp>;
};


/** columns and relationships of "service" */
export type ServiceIncompatibleServices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<IncompatibleServices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IncompatibleServices_Order_By>>;
  where?: InputMaybe<IncompatibleServices_Bool_Exp>;
};


/** columns and relationships of "service" */
export type ServiceInvoiceServiceItemsArgs = {
  distinct_on?: InputMaybe<Array<InvoiceServiceItem_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<InvoiceServiceItem_Order_By>>;
  where?: InputMaybe<InvoiceServiceItem_Bool_Exp>;
};


/** columns and relationships of "service" */
export type ServiceInvoiceServiceItems_AggregateArgs = {
  distinct_on?: InputMaybe<Array<InvoiceServiceItem_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<InvoiceServiceItem_Order_By>>;
  where?: InputMaybe<InvoiceServiceItem_Bool_Exp>;
};


/** columns and relationships of "service" */
export type ServiceLocationServicesArgs = {
  distinct_on?: InputMaybe<Array<LocationService_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<LocationService_Order_By>>;
  where?: InputMaybe<LocationService_Bool_Exp>;
};


/** columns and relationships of "service" */
export type ServiceLocationServices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<LocationService_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<LocationService_Order_By>>;
  where?: InputMaybe<LocationService_Bool_Exp>;
};


/** columns and relationships of "service" */
export type ServiceOnlineBookingRulesArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "service" */
export type ServiceServiceAddOnsArgs = {
  distinct_on?: InputMaybe<Array<ServiceAddOn_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ServiceAddOn_Order_By>>;
  where?: InputMaybe<ServiceAddOn_Bool_Exp>;
};


/** columns and relationships of "service" */
export type ServiceServiceAddOns_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ServiceAddOn_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ServiceAddOn_Order_By>>;
  where?: InputMaybe<ServiceAddOn_Bool_Exp>;
};


/** columns and relationships of "service" */
export type ServiceServiceConsentsArgs = {
  distinct_on?: InputMaybe<Array<ServiceConsent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ServiceConsent_Order_By>>;
  where?: InputMaybe<ServiceConsent_Bool_Exp>;
};


/** columns and relationships of "service" */
export type ServiceServiceConsents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ServiceConsent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ServiceConsent_Order_By>>;
  where?: InputMaybe<ServiceConsent_Bool_Exp>;
};


/** columns and relationships of "service" */
export type ServiceServiceConsumableTypesArgs = {
  distinct_on?: InputMaybe<Array<ServiceConsumableType_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ServiceConsumableType_Order_By>>;
  where?: InputMaybe<ServiceConsumableType_Bool_Exp>;
};


/** columns and relationships of "service" */
export type ServiceServiceConsumableTypes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ServiceConsumableType_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ServiceConsumableType_Order_By>>;
  where?: InputMaybe<ServiceConsumableType_Bool_Exp>;
};


/** columns and relationships of "service" */
export type ServiceServiceCouponsArgs = {
  distinct_on?: InputMaybe<Array<ServiceCoupon_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ServiceCoupon_Order_By>>;
  where?: InputMaybe<ServiceCoupon_Bool_Exp>;
};


/** columns and relationships of "service" */
export type ServiceServiceCoupons_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ServiceCoupon_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ServiceCoupon_Order_By>>;
  where?: InputMaybe<ServiceCoupon_Bool_Exp>;
};


/** columns and relationships of "service" */
export type ServiceServiceCustomFormsArgs = {
  distinct_on?: InputMaybe<Array<ServiceCustomForm_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ServiceCustomForm_Order_By>>;
  where?: InputMaybe<ServiceCustomForm_Bool_Exp>;
};


/** columns and relationships of "service" */
export type ServiceServiceCustomForms_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ServiceCustomForm_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ServiceCustomForm_Order_By>>;
  where?: InputMaybe<ServiceCustomForm_Bool_Exp>;
};


/** columns and relationships of "service" */
export type ServiceServiceDeviceTypesArgs = {
  distinct_on?: InputMaybe<Array<ServiceDeviceType_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ServiceDeviceType_Order_By>>;
  where?: InputMaybe<ServiceDeviceType_Bool_Exp>;
};


/** columns and relationships of "service" */
export type ServiceServiceDeviceTypes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ServiceDeviceType_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ServiceDeviceType_Order_By>>;
  where?: InputMaybe<ServiceDeviceType_Bool_Exp>;
};


/** columns and relationships of "service" */
export type ServiceServiceProvidersArgs = {
  distinct_on?: InputMaybe<Array<ServiceProvider_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ServiceProvider_Order_By>>;
  where?: InputMaybe<ServiceProvider_Bool_Exp>;
};


/** columns and relationships of "service" */
export type ServiceServiceProviders_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ServiceProvider_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ServiceProvider_Order_By>>;
  where?: InputMaybe<ServiceProvider_Bool_Exp>;
};

/** columns and relationships of "serviceAddOn" */
export type ServiceAddOn = {
  __typename?: 'serviceAddOn';
  addOnServiceId: Scalars['uuid'];
  bookingOrder: Scalars['Int'];
  coreServiceId: Scalars['uuid'];
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  isRequired: Scalars['Boolean'];
  /** An object relationship */
  serviceAddOn: Service;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workspaceId: Scalars['uuid'];
};

/** aggregated selection of "serviceAddOn" */
export type ServiceAddOn_Aggregate = {
  __typename?: 'serviceAddOn_aggregate';
  aggregate?: Maybe<ServiceAddOn_Aggregate_Fields>;
  nodes: Array<ServiceAddOn>;
};

export type ServiceAddOn_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<ServiceAddOn_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<ServiceAddOn_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<ServiceAddOn_Aggregate_Bool_Exp_Count>;
};

export type ServiceAddOn_Aggregate_Bool_Exp_Bool_And = {
  arguments: ServiceAddOn_Select_Column_ServiceAddOn_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ServiceAddOn_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type ServiceAddOn_Aggregate_Bool_Exp_Bool_Or = {
  arguments: ServiceAddOn_Select_Column_ServiceAddOn_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ServiceAddOn_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type ServiceAddOn_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<ServiceAddOn_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ServiceAddOn_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "serviceAddOn" */
export type ServiceAddOn_Aggregate_Fields = {
  __typename?: 'serviceAddOn_aggregate_fields';
  avg?: Maybe<ServiceAddOn_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<ServiceAddOn_Max_Fields>;
  min?: Maybe<ServiceAddOn_Min_Fields>;
  stddev?: Maybe<ServiceAddOn_Stddev_Fields>;
  stddev_pop?: Maybe<ServiceAddOn_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<ServiceAddOn_Stddev_Samp_Fields>;
  sum?: Maybe<ServiceAddOn_Sum_Fields>;
  var_pop?: Maybe<ServiceAddOn_Var_Pop_Fields>;
  var_samp?: Maybe<ServiceAddOn_Var_Samp_Fields>;
  variance?: Maybe<ServiceAddOn_Variance_Fields>;
};


/** aggregate fields of "serviceAddOn" */
export type ServiceAddOn_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<ServiceAddOn_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "serviceAddOn" */
export type ServiceAddOn_Aggregate_Order_By = {
  avg?: InputMaybe<ServiceAddOn_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<ServiceAddOn_Max_Order_By>;
  min?: InputMaybe<ServiceAddOn_Min_Order_By>;
  stddev?: InputMaybe<ServiceAddOn_Stddev_Order_By>;
  stddev_pop?: InputMaybe<ServiceAddOn_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<ServiceAddOn_Stddev_Samp_Order_By>;
  sum?: InputMaybe<ServiceAddOn_Sum_Order_By>;
  var_pop?: InputMaybe<ServiceAddOn_Var_Pop_Order_By>;
  var_samp?: InputMaybe<ServiceAddOn_Var_Samp_Order_By>;
  variance?: InputMaybe<ServiceAddOn_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "serviceAddOn" */
export type ServiceAddOn_Arr_Rel_Insert_Input = {
  data: Array<ServiceAddOn_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<ServiceAddOn_On_Conflict>;
};

/** aggregate avg on columns */
export type ServiceAddOn_Avg_Fields = {
  __typename?: 'serviceAddOn_avg_fields';
  bookingOrder?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "serviceAddOn" */
export type ServiceAddOn_Avg_Order_By = {
  bookingOrder?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "serviceAddOn". All fields are combined with a logical 'AND'. */
export type ServiceAddOn_Bool_Exp = {
  _and?: InputMaybe<Array<ServiceAddOn_Bool_Exp>>;
  _not?: InputMaybe<ServiceAddOn_Bool_Exp>;
  _or?: InputMaybe<Array<ServiceAddOn_Bool_Exp>>;
  addOnServiceId?: InputMaybe<Uuid_Comparison_Exp>;
  bookingOrder?: InputMaybe<Int_Comparison_Exp>;
  coreServiceId?: InputMaybe<Uuid_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  isRequired?: InputMaybe<Boolean_Comparison_Exp>;
  serviceAddOn?: InputMaybe<Service_Bool_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  workspaceId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "serviceAddOn" */
export enum ServiceAddOn_Constraint {
  /** unique or primary key constraint on columns "id" */
  ServiceAddOnPkey = 'serviceAddOn_pkey'
}

/** input type for incrementing numeric columns in table "serviceAddOn" */
export type ServiceAddOn_Inc_Input = {
  bookingOrder?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "serviceAddOn" */
export type ServiceAddOn_Insert_Input = {
  addOnServiceId?: InputMaybe<Scalars['uuid']>;
  bookingOrder?: InputMaybe<Scalars['Int']>;
  coreServiceId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  isRequired?: InputMaybe<Scalars['Boolean']>;
  serviceAddOn?: InputMaybe<Service_Obj_Rel_Insert_Input>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type ServiceAddOn_Max_Fields = {
  __typename?: 'serviceAddOn_max_fields';
  addOnServiceId?: Maybe<Scalars['uuid']>;
  bookingOrder?: Maybe<Scalars['Int']>;
  coreServiceId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "serviceAddOn" */
export type ServiceAddOn_Max_Order_By = {
  addOnServiceId?: InputMaybe<Order_By>;
  bookingOrder?: InputMaybe<Order_By>;
  coreServiceId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type ServiceAddOn_Min_Fields = {
  __typename?: 'serviceAddOn_min_fields';
  addOnServiceId?: Maybe<Scalars['uuid']>;
  bookingOrder?: Maybe<Scalars['Int']>;
  coreServiceId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "serviceAddOn" */
export type ServiceAddOn_Min_Order_By = {
  addOnServiceId?: InputMaybe<Order_By>;
  bookingOrder?: InputMaybe<Order_By>;
  coreServiceId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "serviceAddOn" */
export type ServiceAddOn_Mutation_Response = {
  __typename?: 'serviceAddOn_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ServiceAddOn>;
};

/** on_conflict condition type for table "serviceAddOn" */
export type ServiceAddOn_On_Conflict = {
  constraint: ServiceAddOn_Constraint;
  update_columns?: Array<ServiceAddOn_Update_Column>;
  where?: InputMaybe<ServiceAddOn_Bool_Exp>;
};

/** Ordering options when selecting data from "serviceAddOn". */
export type ServiceAddOn_Order_By = {
  addOnServiceId?: InputMaybe<Order_By>;
  bookingOrder?: InputMaybe<Order_By>;
  coreServiceId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  isRequired?: InputMaybe<Order_By>;
  serviceAddOn?: InputMaybe<Service_Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: serviceAddOn */
export type ServiceAddOn_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "serviceAddOn" */
export enum ServiceAddOn_Select_Column {
  /** column name */
  AddOnServiceId = 'addOnServiceId',
  /** column name */
  BookingOrder = 'bookingOrder',
  /** column name */
  CoreServiceId = 'coreServiceId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  IsRequired = 'isRequired',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WorkspaceId = 'workspaceId'
}

/** select "serviceAddOn_aggregate_bool_exp_bool_and_arguments_columns" columns of table "serviceAddOn" */
export enum ServiceAddOn_Select_Column_ServiceAddOn_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsRequired = 'isRequired'
}

/** select "serviceAddOn_aggregate_bool_exp_bool_or_arguments_columns" columns of table "serviceAddOn" */
export enum ServiceAddOn_Select_Column_ServiceAddOn_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsRequired = 'isRequired'
}

/** input type for updating data in table "serviceAddOn" */
export type ServiceAddOn_Set_Input = {
  addOnServiceId?: InputMaybe<Scalars['uuid']>;
  bookingOrder?: InputMaybe<Scalars['Int']>;
  coreServiceId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  isRequired?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type ServiceAddOn_Stddev_Fields = {
  __typename?: 'serviceAddOn_stddev_fields';
  bookingOrder?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "serviceAddOn" */
export type ServiceAddOn_Stddev_Order_By = {
  bookingOrder?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type ServiceAddOn_Stddev_Pop_Fields = {
  __typename?: 'serviceAddOn_stddev_pop_fields';
  bookingOrder?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "serviceAddOn" */
export type ServiceAddOn_Stddev_Pop_Order_By = {
  bookingOrder?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type ServiceAddOn_Stddev_Samp_Fields = {
  __typename?: 'serviceAddOn_stddev_samp_fields';
  bookingOrder?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "serviceAddOn" */
export type ServiceAddOn_Stddev_Samp_Order_By = {
  bookingOrder?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "serviceAddOn" */
export type ServiceAddOn_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ServiceAddOn_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ServiceAddOn_Stream_Cursor_Value_Input = {
  addOnServiceId?: InputMaybe<Scalars['uuid']>;
  bookingOrder?: InputMaybe<Scalars['Int']>;
  coreServiceId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  isRequired?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type ServiceAddOn_Sum_Fields = {
  __typename?: 'serviceAddOn_sum_fields';
  bookingOrder?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "serviceAddOn" */
export type ServiceAddOn_Sum_Order_By = {
  bookingOrder?: InputMaybe<Order_By>;
};

/** update columns of table "serviceAddOn" */
export enum ServiceAddOn_Update_Column {
  /** column name */
  AddOnServiceId = 'addOnServiceId',
  /** column name */
  BookingOrder = 'bookingOrder',
  /** column name */
  CoreServiceId = 'coreServiceId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  IsRequired = 'isRequired',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WorkspaceId = 'workspaceId'
}

export type ServiceAddOn_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ServiceAddOn_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ServiceAddOn_Set_Input>;
  /** filter the rows which have to be updated */
  where: ServiceAddOn_Bool_Exp;
};

/** aggregate var_pop on columns */
export type ServiceAddOn_Var_Pop_Fields = {
  __typename?: 'serviceAddOn_var_pop_fields';
  bookingOrder?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "serviceAddOn" */
export type ServiceAddOn_Var_Pop_Order_By = {
  bookingOrder?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type ServiceAddOn_Var_Samp_Fields = {
  __typename?: 'serviceAddOn_var_samp_fields';
  bookingOrder?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "serviceAddOn" */
export type ServiceAddOn_Var_Samp_Order_By = {
  bookingOrder?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type ServiceAddOn_Variance_Fields = {
  __typename?: 'serviceAddOn_variance_fields';
  bookingOrder?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "serviceAddOn" */
export type ServiceAddOn_Variance_Order_By = {
  bookingOrder?: InputMaybe<Order_By>;
};

/** columns and relationships of "serviceCategory" */
export type ServiceCategory = {
  __typename?: 'serviceCategory';
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An array relationship */
  services: Array<Service>;
  /** An aggregate relationship */
  services_aggregate: Service_Aggregate;
  title: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  workspaceId: Scalars['uuid'];
};


/** columns and relationships of "serviceCategory" */
export type ServiceCategoryServicesArgs = {
  distinct_on?: InputMaybe<Array<Service_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Service_Order_By>>;
  where?: InputMaybe<Service_Bool_Exp>;
};


/** columns and relationships of "serviceCategory" */
export type ServiceCategoryServices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Service_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Service_Order_By>>;
  where?: InputMaybe<Service_Bool_Exp>;
};

/** aggregated selection of "serviceCategory" */
export type ServiceCategory_Aggregate = {
  __typename?: 'serviceCategory_aggregate';
  aggregate?: Maybe<ServiceCategory_Aggregate_Fields>;
  nodes: Array<ServiceCategory>;
};

/** aggregate fields of "serviceCategory" */
export type ServiceCategory_Aggregate_Fields = {
  __typename?: 'serviceCategory_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ServiceCategory_Max_Fields>;
  min?: Maybe<ServiceCategory_Min_Fields>;
};


/** aggregate fields of "serviceCategory" */
export type ServiceCategory_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<ServiceCategory_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "serviceCategory". All fields are combined with a logical 'AND'. */
export type ServiceCategory_Bool_Exp = {
  _and?: InputMaybe<Array<ServiceCategory_Bool_Exp>>;
  _not?: InputMaybe<ServiceCategory_Bool_Exp>;
  _or?: InputMaybe<Array<ServiceCategory_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  services?: InputMaybe<Service_Bool_Exp>;
  services_aggregate?: InputMaybe<Service_Aggregate_Bool_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  workspaceId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "serviceCategory" */
export enum ServiceCategory_Constraint {
  /** unique or primary key constraint on columns "id" */
  ServiceCategoryPkey = 'serviceCategory_pkey',
  /** unique or primary key constraint on columns "workspaceId", "title" */
  ServiceCategoryWorkspaceIdTitleKey = 'serviceCategory_workspaceId_title_key'
}

/** input type for inserting data into table "serviceCategory" */
export type ServiceCategory_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  services?: InputMaybe<Service_Arr_Rel_Insert_Input>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type ServiceCategory_Max_Fields = {
  __typename?: 'serviceCategory_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type ServiceCategory_Min_Fields = {
  __typename?: 'serviceCategory_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "serviceCategory" */
export type ServiceCategory_Mutation_Response = {
  __typename?: 'serviceCategory_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ServiceCategory>;
};

/** input type for inserting object relation for remote table "serviceCategory" */
export type ServiceCategory_Obj_Rel_Insert_Input = {
  data: ServiceCategory_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<ServiceCategory_On_Conflict>;
};

/** on_conflict condition type for table "serviceCategory" */
export type ServiceCategory_On_Conflict = {
  constraint: ServiceCategory_Constraint;
  update_columns?: Array<ServiceCategory_Update_Column>;
  where?: InputMaybe<ServiceCategory_Bool_Exp>;
};

/** Ordering options when selecting data from "serviceCategory". */
export type ServiceCategory_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  services_aggregate?: InputMaybe<Service_Aggregate_Order_By>;
  title?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: serviceCategory */
export type ServiceCategory_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "serviceCategory" */
export enum ServiceCategory_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WorkspaceId = 'workspaceId'
}

/** input type for updating data in table "serviceCategory" */
export type ServiceCategory_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "serviceCategory" */
export type ServiceCategory_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ServiceCategory_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ServiceCategory_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "serviceCategory" */
export enum ServiceCategory_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WorkspaceId = 'workspaceId'
}

export type ServiceCategory_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ServiceCategory_Set_Input>;
  /** filter the rows which have to be updated */
  where: ServiceCategory_Bool_Exp;
};

/** columns and relationships of "serviceConsent" */
export type ServiceConsent = {
  __typename?: 'serviceConsent';
  /** An object relationship */
  consent: Consent;
  consentId: Scalars['uuid'];
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An object relationship */
  service: Service;
  serviceId: Scalars['uuid'];
  updatedAt: Scalars['timestamptz'];
};

/** aggregated selection of "serviceConsent" */
export type ServiceConsent_Aggregate = {
  __typename?: 'serviceConsent_aggregate';
  aggregate?: Maybe<ServiceConsent_Aggregate_Fields>;
  nodes: Array<ServiceConsent>;
};

export type ServiceConsent_Aggregate_Bool_Exp = {
  count?: InputMaybe<ServiceConsent_Aggregate_Bool_Exp_Count>;
};

export type ServiceConsent_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<ServiceConsent_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ServiceConsent_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "serviceConsent" */
export type ServiceConsent_Aggregate_Fields = {
  __typename?: 'serviceConsent_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ServiceConsent_Max_Fields>;
  min?: Maybe<ServiceConsent_Min_Fields>;
};


/** aggregate fields of "serviceConsent" */
export type ServiceConsent_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<ServiceConsent_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "serviceConsent" */
export type ServiceConsent_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<ServiceConsent_Max_Order_By>;
  min?: InputMaybe<ServiceConsent_Min_Order_By>;
};

/** input type for inserting array relation for remote table "serviceConsent" */
export type ServiceConsent_Arr_Rel_Insert_Input = {
  data: Array<ServiceConsent_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<ServiceConsent_On_Conflict>;
};

/** Boolean expression to filter rows from the table "serviceConsent". All fields are combined with a logical 'AND'. */
export type ServiceConsent_Bool_Exp = {
  _and?: InputMaybe<Array<ServiceConsent_Bool_Exp>>;
  _not?: InputMaybe<ServiceConsent_Bool_Exp>;
  _or?: InputMaybe<Array<ServiceConsent_Bool_Exp>>;
  consent?: InputMaybe<Consent_Bool_Exp>;
  consentId?: InputMaybe<Uuid_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  service?: InputMaybe<Service_Bool_Exp>;
  serviceId?: InputMaybe<Uuid_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "serviceConsent" */
export enum ServiceConsent_Constraint {
  /** unique or primary key constraint on columns "id" */
  ServiceConsentPkey = 'serviceConsent_pkey',
  /** unique or primary key constraint on columns "serviceId", "consentId" */
  ServiceConsentServiceIdConsentIdKey = 'serviceConsent_serviceId_consentId_key'
}

/** input type for inserting data into table "serviceConsent" */
export type ServiceConsent_Insert_Input = {
  consent?: InputMaybe<Consent_Obj_Rel_Insert_Input>;
  consentId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  service?: InputMaybe<Service_Obj_Rel_Insert_Input>;
  serviceId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type ServiceConsent_Max_Fields = {
  __typename?: 'serviceConsent_max_fields';
  consentId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  serviceId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "serviceConsent" */
export type ServiceConsent_Max_Order_By = {
  consentId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  serviceId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type ServiceConsent_Min_Fields = {
  __typename?: 'serviceConsent_min_fields';
  consentId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  serviceId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "serviceConsent" */
export type ServiceConsent_Min_Order_By = {
  consentId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  serviceId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "serviceConsent" */
export type ServiceConsent_Mutation_Response = {
  __typename?: 'serviceConsent_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ServiceConsent>;
};

/** on_conflict condition type for table "serviceConsent" */
export type ServiceConsent_On_Conflict = {
  constraint: ServiceConsent_Constraint;
  update_columns?: Array<ServiceConsent_Update_Column>;
  where?: InputMaybe<ServiceConsent_Bool_Exp>;
};

/** Ordering options when selecting data from "serviceConsent". */
export type ServiceConsent_Order_By = {
  consent?: InputMaybe<Consent_Order_By>;
  consentId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  service?: InputMaybe<Service_Order_By>;
  serviceId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: serviceConsent */
export type ServiceConsent_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "serviceConsent" */
export enum ServiceConsent_Select_Column {
  /** column name */
  ConsentId = 'consentId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  ServiceId = 'serviceId',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "serviceConsent" */
export type ServiceConsent_Set_Input = {
  consentId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  serviceId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "serviceConsent" */
export type ServiceConsent_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ServiceConsent_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ServiceConsent_Stream_Cursor_Value_Input = {
  consentId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  serviceId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "serviceConsent" */
export enum ServiceConsent_Update_Column {
  /** column name */
  ConsentId = 'consentId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  ServiceId = 'serviceId',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type ServiceConsent_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ServiceConsent_Set_Input>;
  /** filter the rows which have to be updated */
  where: ServiceConsent_Bool_Exp;
};

/** columns and relationships of "serviceConsumableType" */
export type ServiceConsumableType = {
  __typename?: 'serviceConsumableType';
  /** An object relationship */
  consumableType: ConsumableType;
  consumableTypeId: Scalars['uuid'];
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  numberOfUnits: Scalars['float8'];
  pricePerUnit: Scalars['Int'];
  serviceId: Scalars['uuid'];
  updatedAt: Scalars['timestamptz'];
};

/** aggregated selection of "serviceConsumableType" */
export type ServiceConsumableType_Aggregate = {
  __typename?: 'serviceConsumableType_aggregate';
  aggregate?: Maybe<ServiceConsumableType_Aggregate_Fields>;
  nodes: Array<ServiceConsumableType>;
};

export type ServiceConsumableType_Aggregate_Bool_Exp = {
  avg?: InputMaybe<ServiceConsumableType_Aggregate_Bool_Exp_Avg>;
  corr?: InputMaybe<ServiceConsumableType_Aggregate_Bool_Exp_Corr>;
  count?: InputMaybe<ServiceConsumableType_Aggregate_Bool_Exp_Count>;
  covar_samp?: InputMaybe<ServiceConsumableType_Aggregate_Bool_Exp_Covar_Samp>;
  max?: InputMaybe<ServiceConsumableType_Aggregate_Bool_Exp_Max>;
  min?: InputMaybe<ServiceConsumableType_Aggregate_Bool_Exp_Min>;
  stddev_samp?: InputMaybe<ServiceConsumableType_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: InputMaybe<ServiceConsumableType_Aggregate_Bool_Exp_Sum>;
  var_samp?: InputMaybe<ServiceConsumableType_Aggregate_Bool_Exp_Var_Samp>;
};

export type ServiceConsumableType_Aggregate_Bool_Exp_Avg = {
  arguments: ServiceConsumableType_Select_Column_ServiceConsumableType_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ServiceConsumableType_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type ServiceConsumableType_Aggregate_Bool_Exp_Corr = {
  arguments: ServiceConsumableType_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ServiceConsumableType_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type ServiceConsumableType_Aggregate_Bool_Exp_Corr_Arguments = {
  X: ServiceConsumableType_Select_Column_ServiceConsumableType_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: ServiceConsumableType_Select_Column_ServiceConsumableType_Aggregate_Bool_Exp_Corr_Arguments_Columns;
};

export type ServiceConsumableType_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<ServiceConsumableType_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ServiceConsumableType_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type ServiceConsumableType_Aggregate_Bool_Exp_Covar_Samp = {
  arguments: ServiceConsumableType_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ServiceConsumableType_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type ServiceConsumableType_Aggregate_Bool_Exp_Covar_Samp_Arguments = {
  X: ServiceConsumableType_Select_Column_ServiceConsumableType_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  Y: ServiceConsumableType_Select_Column_ServiceConsumableType_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
};

export type ServiceConsumableType_Aggregate_Bool_Exp_Max = {
  arguments: ServiceConsumableType_Select_Column_ServiceConsumableType_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ServiceConsumableType_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type ServiceConsumableType_Aggregate_Bool_Exp_Min = {
  arguments: ServiceConsumableType_Select_Column_ServiceConsumableType_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ServiceConsumableType_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type ServiceConsumableType_Aggregate_Bool_Exp_Stddev_Samp = {
  arguments: ServiceConsumableType_Select_Column_ServiceConsumableType_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ServiceConsumableType_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type ServiceConsumableType_Aggregate_Bool_Exp_Sum = {
  arguments: ServiceConsumableType_Select_Column_ServiceConsumableType_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ServiceConsumableType_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type ServiceConsumableType_Aggregate_Bool_Exp_Var_Samp = {
  arguments: ServiceConsumableType_Select_Column_ServiceConsumableType_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ServiceConsumableType_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

/** aggregate fields of "serviceConsumableType" */
export type ServiceConsumableType_Aggregate_Fields = {
  __typename?: 'serviceConsumableType_aggregate_fields';
  avg?: Maybe<ServiceConsumableType_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<ServiceConsumableType_Max_Fields>;
  min?: Maybe<ServiceConsumableType_Min_Fields>;
  stddev?: Maybe<ServiceConsumableType_Stddev_Fields>;
  stddev_pop?: Maybe<ServiceConsumableType_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<ServiceConsumableType_Stddev_Samp_Fields>;
  sum?: Maybe<ServiceConsumableType_Sum_Fields>;
  var_pop?: Maybe<ServiceConsumableType_Var_Pop_Fields>;
  var_samp?: Maybe<ServiceConsumableType_Var_Samp_Fields>;
  variance?: Maybe<ServiceConsumableType_Variance_Fields>;
};


/** aggregate fields of "serviceConsumableType" */
export type ServiceConsumableType_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<ServiceConsumableType_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "serviceConsumableType" */
export type ServiceConsumableType_Aggregate_Order_By = {
  avg?: InputMaybe<ServiceConsumableType_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<ServiceConsumableType_Max_Order_By>;
  min?: InputMaybe<ServiceConsumableType_Min_Order_By>;
  stddev?: InputMaybe<ServiceConsumableType_Stddev_Order_By>;
  stddev_pop?: InputMaybe<ServiceConsumableType_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<ServiceConsumableType_Stddev_Samp_Order_By>;
  sum?: InputMaybe<ServiceConsumableType_Sum_Order_By>;
  var_pop?: InputMaybe<ServiceConsumableType_Var_Pop_Order_By>;
  var_samp?: InputMaybe<ServiceConsumableType_Var_Samp_Order_By>;
  variance?: InputMaybe<ServiceConsumableType_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "serviceConsumableType" */
export type ServiceConsumableType_Arr_Rel_Insert_Input = {
  data: Array<ServiceConsumableType_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<ServiceConsumableType_On_Conflict>;
};

/** aggregate avg on columns */
export type ServiceConsumableType_Avg_Fields = {
  __typename?: 'serviceConsumableType_avg_fields';
  numberOfUnits?: Maybe<Scalars['Float']>;
  pricePerUnit?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "serviceConsumableType" */
export type ServiceConsumableType_Avg_Order_By = {
  numberOfUnits?: InputMaybe<Order_By>;
  pricePerUnit?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "serviceConsumableType". All fields are combined with a logical 'AND'. */
export type ServiceConsumableType_Bool_Exp = {
  _and?: InputMaybe<Array<ServiceConsumableType_Bool_Exp>>;
  _not?: InputMaybe<ServiceConsumableType_Bool_Exp>;
  _or?: InputMaybe<Array<ServiceConsumableType_Bool_Exp>>;
  consumableType?: InputMaybe<ConsumableType_Bool_Exp>;
  consumableTypeId?: InputMaybe<Uuid_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  numberOfUnits?: InputMaybe<Float8_Comparison_Exp>;
  pricePerUnit?: InputMaybe<Int_Comparison_Exp>;
  serviceId?: InputMaybe<Uuid_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "serviceConsumableType" */
export enum ServiceConsumableType_Constraint {
  /** unique or primary key constraint on columns "id" */
  ServiceConsumableTypePkey = 'serviceConsumableType_pkey',
  /** unique or primary key constraint on columns "serviceId", "consumableTypeId" */
  ServiceConsumableTypeServiceIdConsumableTypeIdKey = 'serviceConsumableType_serviceId_consumableTypeId_key'
}

/** input type for incrementing numeric columns in table "serviceConsumableType" */
export type ServiceConsumableType_Inc_Input = {
  numberOfUnits?: InputMaybe<Scalars['float8']>;
  pricePerUnit?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "serviceConsumableType" */
export type ServiceConsumableType_Insert_Input = {
  consumableType?: InputMaybe<ConsumableType_Obj_Rel_Insert_Input>;
  consumableTypeId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  numberOfUnits?: InputMaybe<Scalars['float8']>;
  pricePerUnit?: InputMaybe<Scalars['Int']>;
  serviceId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type ServiceConsumableType_Max_Fields = {
  __typename?: 'serviceConsumableType_max_fields';
  consumableTypeId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  numberOfUnits?: Maybe<Scalars['float8']>;
  pricePerUnit?: Maybe<Scalars['Int']>;
  serviceId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "serviceConsumableType" */
export type ServiceConsumableType_Max_Order_By = {
  consumableTypeId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  numberOfUnits?: InputMaybe<Order_By>;
  pricePerUnit?: InputMaybe<Order_By>;
  serviceId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type ServiceConsumableType_Min_Fields = {
  __typename?: 'serviceConsumableType_min_fields';
  consumableTypeId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  numberOfUnits?: Maybe<Scalars['float8']>;
  pricePerUnit?: Maybe<Scalars['Int']>;
  serviceId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "serviceConsumableType" */
export type ServiceConsumableType_Min_Order_By = {
  consumableTypeId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  numberOfUnits?: InputMaybe<Order_By>;
  pricePerUnit?: InputMaybe<Order_By>;
  serviceId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "serviceConsumableType" */
export type ServiceConsumableType_Mutation_Response = {
  __typename?: 'serviceConsumableType_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ServiceConsumableType>;
};

/** on_conflict condition type for table "serviceConsumableType" */
export type ServiceConsumableType_On_Conflict = {
  constraint: ServiceConsumableType_Constraint;
  update_columns?: Array<ServiceConsumableType_Update_Column>;
  where?: InputMaybe<ServiceConsumableType_Bool_Exp>;
};

/** Ordering options when selecting data from "serviceConsumableType". */
export type ServiceConsumableType_Order_By = {
  consumableType?: InputMaybe<ConsumableType_Order_By>;
  consumableTypeId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  numberOfUnits?: InputMaybe<Order_By>;
  pricePerUnit?: InputMaybe<Order_By>;
  serviceId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: serviceConsumableType */
export type ServiceConsumableType_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "serviceConsumableType" */
export enum ServiceConsumableType_Select_Column {
  /** column name */
  ConsumableTypeId = 'consumableTypeId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  NumberOfUnits = 'numberOfUnits',
  /** column name */
  PricePerUnit = 'pricePerUnit',
  /** column name */
  ServiceId = 'serviceId',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** select "serviceConsumableType_aggregate_bool_exp_avg_arguments_columns" columns of table "serviceConsumableType" */
export enum ServiceConsumableType_Select_Column_ServiceConsumableType_Aggregate_Bool_Exp_Avg_Arguments_Columns {
  /** column name */
  NumberOfUnits = 'numberOfUnits'
}

/** select "serviceConsumableType_aggregate_bool_exp_corr_arguments_columns" columns of table "serviceConsumableType" */
export enum ServiceConsumableType_Select_Column_ServiceConsumableType_Aggregate_Bool_Exp_Corr_Arguments_Columns {
  /** column name */
  NumberOfUnits = 'numberOfUnits'
}

/** select "serviceConsumableType_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "serviceConsumableType" */
export enum ServiceConsumableType_Select_Column_ServiceConsumableType_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns {
  /** column name */
  NumberOfUnits = 'numberOfUnits'
}

/** select "serviceConsumableType_aggregate_bool_exp_max_arguments_columns" columns of table "serviceConsumableType" */
export enum ServiceConsumableType_Select_Column_ServiceConsumableType_Aggregate_Bool_Exp_Max_Arguments_Columns {
  /** column name */
  NumberOfUnits = 'numberOfUnits'
}

/** select "serviceConsumableType_aggregate_bool_exp_min_arguments_columns" columns of table "serviceConsumableType" */
export enum ServiceConsumableType_Select_Column_ServiceConsumableType_Aggregate_Bool_Exp_Min_Arguments_Columns {
  /** column name */
  NumberOfUnits = 'numberOfUnits'
}

/** select "serviceConsumableType_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "serviceConsumableType" */
export enum ServiceConsumableType_Select_Column_ServiceConsumableType_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns {
  /** column name */
  NumberOfUnits = 'numberOfUnits'
}

/** select "serviceConsumableType_aggregate_bool_exp_sum_arguments_columns" columns of table "serviceConsumableType" */
export enum ServiceConsumableType_Select_Column_ServiceConsumableType_Aggregate_Bool_Exp_Sum_Arguments_Columns {
  /** column name */
  NumberOfUnits = 'numberOfUnits'
}

/** select "serviceConsumableType_aggregate_bool_exp_var_samp_arguments_columns" columns of table "serviceConsumableType" */
export enum ServiceConsumableType_Select_Column_ServiceConsumableType_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns {
  /** column name */
  NumberOfUnits = 'numberOfUnits'
}

/** input type for updating data in table "serviceConsumableType" */
export type ServiceConsumableType_Set_Input = {
  consumableTypeId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  numberOfUnits?: InputMaybe<Scalars['float8']>;
  pricePerUnit?: InputMaybe<Scalars['Int']>;
  serviceId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type ServiceConsumableType_Stddev_Fields = {
  __typename?: 'serviceConsumableType_stddev_fields';
  numberOfUnits?: Maybe<Scalars['Float']>;
  pricePerUnit?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "serviceConsumableType" */
export type ServiceConsumableType_Stddev_Order_By = {
  numberOfUnits?: InputMaybe<Order_By>;
  pricePerUnit?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type ServiceConsumableType_Stddev_Pop_Fields = {
  __typename?: 'serviceConsumableType_stddev_pop_fields';
  numberOfUnits?: Maybe<Scalars['Float']>;
  pricePerUnit?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "serviceConsumableType" */
export type ServiceConsumableType_Stddev_Pop_Order_By = {
  numberOfUnits?: InputMaybe<Order_By>;
  pricePerUnit?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type ServiceConsumableType_Stddev_Samp_Fields = {
  __typename?: 'serviceConsumableType_stddev_samp_fields';
  numberOfUnits?: Maybe<Scalars['Float']>;
  pricePerUnit?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "serviceConsumableType" */
export type ServiceConsumableType_Stddev_Samp_Order_By = {
  numberOfUnits?: InputMaybe<Order_By>;
  pricePerUnit?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "serviceConsumableType" */
export type ServiceConsumableType_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ServiceConsumableType_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ServiceConsumableType_Stream_Cursor_Value_Input = {
  consumableTypeId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  numberOfUnits?: InputMaybe<Scalars['float8']>;
  pricePerUnit?: InputMaybe<Scalars['Int']>;
  serviceId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type ServiceConsumableType_Sum_Fields = {
  __typename?: 'serviceConsumableType_sum_fields';
  numberOfUnits?: Maybe<Scalars['float8']>;
  pricePerUnit?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "serviceConsumableType" */
export type ServiceConsumableType_Sum_Order_By = {
  numberOfUnits?: InputMaybe<Order_By>;
  pricePerUnit?: InputMaybe<Order_By>;
};

/** update columns of table "serviceConsumableType" */
export enum ServiceConsumableType_Update_Column {
  /** column name */
  ConsumableTypeId = 'consumableTypeId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  NumberOfUnits = 'numberOfUnits',
  /** column name */
  PricePerUnit = 'pricePerUnit',
  /** column name */
  ServiceId = 'serviceId',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type ServiceConsumableType_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ServiceConsumableType_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ServiceConsumableType_Set_Input>;
  /** filter the rows which have to be updated */
  where: ServiceConsumableType_Bool_Exp;
};

/** aggregate var_pop on columns */
export type ServiceConsumableType_Var_Pop_Fields = {
  __typename?: 'serviceConsumableType_var_pop_fields';
  numberOfUnits?: Maybe<Scalars['Float']>;
  pricePerUnit?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "serviceConsumableType" */
export type ServiceConsumableType_Var_Pop_Order_By = {
  numberOfUnits?: InputMaybe<Order_By>;
  pricePerUnit?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type ServiceConsumableType_Var_Samp_Fields = {
  __typename?: 'serviceConsumableType_var_samp_fields';
  numberOfUnits?: Maybe<Scalars['Float']>;
  pricePerUnit?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "serviceConsumableType" */
export type ServiceConsumableType_Var_Samp_Order_By = {
  numberOfUnits?: InputMaybe<Order_By>;
  pricePerUnit?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type ServiceConsumableType_Variance_Fields = {
  __typename?: 'serviceConsumableType_variance_fields';
  numberOfUnits?: Maybe<Scalars['Float']>;
  pricePerUnit?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "serviceConsumableType" */
export type ServiceConsumableType_Variance_Order_By = {
  numberOfUnits?: InputMaybe<Order_By>;
  pricePerUnit?: InputMaybe<Order_By>;
};

/** columns and relationships of "serviceCoupon" */
export type ServiceCoupon = {
  __typename?: 'serviceCoupon';
  /** An object relationship */
  coupon: Coupon;
  couponId: Scalars['uuid'];
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An object relationship */
  service: Service;
  serviceId: Scalars['uuid'];
  updatedAt: Scalars['timestamptz'];
};

/** aggregated selection of "serviceCoupon" */
export type ServiceCoupon_Aggregate = {
  __typename?: 'serviceCoupon_aggregate';
  aggregate?: Maybe<ServiceCoupon_Aggregate_Fields>;
  nodes: Array<ServiceCoupon>;
};

export type ServiceCoupon_Aggregate_Bool_Exp = {
  count?: InputMaybe<ServiceCoupon_Aggregate_Bool_Exp_Count>;
};

export type ServiceCoupon_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<ServiceCoupon_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ServiceCoupon_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "serviceCoupon" */
export type ServiceCoupon_Aggregate_Fields = {
  __typename?: 'serviceCoupon_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ServiceCoupon_Max_Fields>;
  min?: Maybe<ServiceCoupon_Min_Fields>;
};


/** aggregate fields of "serviceCoupon" */
export type ServiceCoupon_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<ServiceCoupon_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "serviceCoupon" */
export type ServiceCoupon_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<ServiceCoupon_Max_Order_By>;
  min?: InputMaybe<ServiceCoupon_Min_Order_By>;
};

/** input type for inserting array relation for remote table "serviceCoupon" */
export type ServiceCoupon_Arr_Rel_Insert_Input = {
  data: Array<ServiceCoupon_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<ServiceCoupon_On_Conflict>;
};

/** Boolean expression to filter rows from the table "serviceCoupon". All fields are combined with a logical 'AND'. */
export type ServiceCoupon_Bool_Exp = {
  _and?: InputMaybe<Array<ServiceCoupon_Bool_Exp>>;
  _not?: InputMaybe<ServiceCoupon_Bool_Exp>;
  _or?: InputMaybe<Array<ServiceCoupon_Bool_Exp>>;
  coupon?: InputMaybe<Coupon_Bool_Exp>;
  couponId?: InputMaybe<Uuid_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  service?: InputMaybe<Service_Bool_Exp>;
  serviceId?: InputMaybe<Uuid_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "serviceCoupon" */
export enum ServiceCoupon_Constraint {
  /** unique or primary key constraint on columns "id" */
  ServiceCouponPkey = 'serviceCoupon_pkey',
  /** unique or primary key constraint on columns "serviceId", "couponId" */
  ServiceCouponServiceIdCouponIdKey = 'serviceCoupon_serviceId_couponId_key'
}

/** input type for inserting data into table "serviceCoupon" */
export type ServiceCoupon_Insert_Input = {
  coupon?: InputMaybe<Coupon_Obj_Rel_Insert_Input>;
  couponId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  service?: InputMaybe<Service_Obj_Rel_Insert_Input>;
  serviceId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type ServiceCoupon_Max_Fields = {
  __typename?: 'serviceCoupon_max_fields';
  couponId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  serviceId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "serviceCoupon" */
export type ServiceCoupon_Max_Order_By = {
  couponId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  serviceId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type ServiceCoupon_Min_Fields = {
  __typename?: 'serviceCoupon_min_fields';
  couponId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  serviceId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "serviceCoupon" */
export type ServiceCoupon_Min_Order_By = {
  couponId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  serviceId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "serviceCoupon" */
export type ServiceCoupon_Mutation_Response = {
  __typename?: 'serviceCoupon_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ServiceCoupon>;
};

/** on_conflict condition type for table "serviceCoupon" */
export type ServiceCoupon_On_Conflict = {
  constraint: ServiceCoupon_Constraint;
  update_columns?: Array<ServiceCoupon_Update_Column>;
  where?: InputMaybe<ServiceCoupon_Bool_Exp>;
};

/** Ordering options when selecting data from "serviceCoupon". */
export type ServiceCoupon_Order_By = {
  coupon?: InputMaybe<Coupon_Order_By>;
  couponId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  service?: InputMaybe<Service_Order_By>;
  serviceId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: serviceCoupon */
export type ServiceCoupon_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "serviceCoupon" */
export enum ServiceCoupon_Select_Column {
  /** column name */
  CouponId = 'couponId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  ServiceId = 'serviceId',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "serviceCoupon" */
export type ServiceCoupon_Set_Input = {
  couponId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  serviceId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "serviceCoupon" */
export type ServiceCoupon_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ServiceCoupon_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ServiceCoupon_Stream_Cursor_Value_Input = {
  couponId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  serviceId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "serviceCoupon" */
export enum ServiceCoupon_Update_Column {
  /** column name */
  CouponId = 'couponId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  ServiceId = 'serviceId',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type ServiceCoupon_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ServiceCoupon_Set_Input>;
  /** filter the rows which have to be updated */
  where: ServiceCoupon_Bool_Exp;
};

/** columns and relationships of "serviceCustomForm" */
export type ServiceCustomForm = {
  __typename?: 'serviceCustomForm';
  createdAt: Scalars['timestamptz'];
  customFormId: Scalars['uuid'];
  /** An object relationship */
  form: CustomForm;
  id: Scalars['uuid'];
  /** An object relationship */
  service: Service;
  serviceId: Scalars['uuid'];
  updatedAt: Scalars['timestamptz'];
};

/** aggregated selection of "serviceCustomForm" */
export type ServiceCustomForm_Aggregate = {
  __typename?: 'serviceCustomForm_aggregate';
  aggregate?: Maybe<ServiceCustomForm_Aggregate_Fields>;
  nodes: Array<ServiceCustomForm>;
};

export type ServiceCustomForm_Aggregate_Bool_Exp = {
  count?: InputMaybe<ServiceCustomForm_Aggregate_Bool_Exp_Count>;
};

export type ServiceCustomForm_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<ServiceCustomForm_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ServiceCustomForm_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "serviceCustomForm" */
export type ServiceCustomForm_Aggregate_Fields = {
  __typename?: 'serviceCustomForm_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ServiceCustomForm_Max_Fields>;
  min?: Maybe<ServiceCustomForm_Min_Fields>;
};


/** aggregate fields of "serviceCustomForm" */
export type ServiceCustomForm_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<ServiceCustomForm_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "serviceCustomForm" */
export type ServiceCustomForm_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<ServiceCustomForm_Max_Order_By>;
  min?: InputMaybe<ServiceCustomForm_Min_Order_By>;
};

/** input type for inserting array relation for remote table "serviceCustomForm" */
export type ServiceCustomForm_Arr_Rel_Insert_Input = {
  data: Array<ServiceCustomForm_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<ServiceCustomForm_On_Conflict>;
};

/** Boolean expression to filter rows from the table "serviceCustomForm". All fields are combined with a logical 'AND'. */
export type ServiceCustomForm_Bool_Exp = {
  _and?: InputMaybe<Array<ServiceCustomForm_Bool_Exp>>;
  _not?: InputMaybe<ServiceCustomForm_Bool_Exp>;
  _or?: InputMaybe<Array<ServiceCustomForm_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  customFormId?: InputMaybe<Uuid_Comparison_Exp>;
  form?: InputMaybe<CustomForm_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  service?: InputMaybe<Service_Bool_Exp>;
  serviceId?: InputMaybe<Uuid_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "serviceCustomForm" */
export enum ServiceCustomForm_Constraint {
  /** unique or primary key constraint on columns "id" */
  ServiceCustomFormPkey = 'serviceCustomForm_pkey',
  /** unique or primary key constraint on columns "customFormId", "serviceId" */
  ServiceCustomFormServiceIdCustomFormIdKey = 'serviceCustomForm_serviceId_customFormId_key'
}

/** input type for inserting data into table "serviceCustomForm" */
export type ServiceCustomForm_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  customFormId?: InputMaybe<Scalars['uuid']>;
  form?: InputMaybe<CustomForm_Obj_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']>;
  service?: InputMaybe<Service_Obj_Rel_Insert_Input>;
  serviceId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type ServiceCustomForm_Max_Fields = {
  __typename?: 'serviceCustomForm_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  customFormId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  serviceId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "serviceCustomForm" */
export type ServiceCustomForm_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  customFormId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  serviceId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type ServiceCustomForm_Min_Fields = {
  __typename?: 'serviceCustomForm_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  customFormId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  serviceId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "serviceCustomForm" */
export type ServiceCustomForm_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  customFormId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  serviceId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "serviceCustomForm" */
export type ServiceCustomForm_Mutation_Response = {
  __typename?: 'serviceCustomForm_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ServiceCustomForm>;
};

/** on_conflict condition type for table "serviceCustomForm" */
export type ServiceCustomForm_On_Conflict = {
  constraint: ServiceCustomForm_Constraint;
  update_columns?: Array<ServiceCustomForm_Update_Column>;
  where?: InputMaybe<ServiceCustomForm_Bool_Exp>;
};

/** Ordering options when selecting data from "serviceCustomForm". */
export type ServiceCustomForm_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  customFormId?: InputMaybe<Order_By>;
  form?: InputMaybe<CustomForm_Order_By>;
  id?: InputMaybe<Order_By>;
  service?: InputMaybe<Service_Order_By>;
  serviceId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: serviceCustomForm */
export type ServiceCustomForm_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "serviceCustomForm" */
export enum ServiceCustomForm_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CustomFormId = 'customFormId',
  /** column name */
  Id = 'id',
  /** column name */
  ServiceId = 'serviceId',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "serviceCustomForm" */
export type ServiceCustomForm_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  customFormId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  serviceId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "serviceCustomForm" */
export type ServiceCustomForm_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ServiceCustomForm_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ServiceCustomForm_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  customFormId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  serviceId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "serviceCustomForm" */
export enum ServiceCustomForm_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CustomFormId = 'customFormId',
  /** column name */
  Id = 'id',
  /** column name */
  ServiceId = 'serviceId',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type ServiceCustomForm_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ServiceCustomForm_Set_Input>;
  /** filter the rows which have to be updated */
  where: ServiceCustomForm_Bool_Exp;
};

/** columns and relationships of "serviceDeviceType" */
export type ServiceDeviceType = {
  __typename?: 'serviceDeviceType';
  createdAt: Scalars['timestamptz'];
  /** An object relationship */
  deviceType: DeviceType;
  deviceTypeId: Scalars['uuid'];
  id: Scalars['uuid'];
  /** An object relationship */
  service: Service;
  serviceId: Scalars['uuid'];
  updatedAt: Scalars['timestamptz'];
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "serviceDeviceType" */
export type ServiceDeviceType_Aggregate = {
  __typename?: 'serviceDeviceType_aggregate';
  aggregate?: Maybe<ServiceDeviceType_Aggregate_Fields>;
  nodes: Array<ServiceDeviceType>;
};

export type ServiceDeviceType_Aggregate_Bool_Exp = {
  count?: InputMaybe<ServiceDeviceType_Aggregate_Bool_Exp_Count>;
};

export type ServiceDeviceType_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<ServiceDeviceType_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ServiceDeviceType_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "serviceDeviceType" */
export type ServiceDeviceType_Aggregate_Fields = {
  __typename?: 'serviceDeviceType_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ServiceDeviceType_Max_Fields>;
  min?: Maybe<ServiceDeviceType_Min_Fields>;
};


/** aggregate fields of "serviceDeviceType" */
export type ServiceDeviceType_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<ServiceDeviceType_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "serviceDeviceType" */
export type ServiceDeviceType_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<ServiceDeviceType_Max_Order_By>;
  min?: InputMaybe<ServiceDeviceType_Min_Order_By>;
};

/** input type for inserting array relation for remote table "serviceDeviceType" */
export type ServiceDeviceType_Arr_Rel_Insert_Input = {
  data: Array<ServiceDeviceType_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<ServiceDeviceType_On_Conflict>;
};

/** Boolean expression to filter rows from the table "serviceDeviceType". All fields are combined with a logical 'AND'. */
export type ServiceDeviceType_Bool_Exp = {
  _and?: InputMaybe<Array<ServiceDeviceType_Bool_Exp>>;
  _not?: InputMaybe<ServiceDeviceType_Bool_Exp>;
  _or?: InputMaybe<Array<ServiceDeviceType_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  deviceType?: InputMaybe<DeviceType_Bool_Exp>;
  deviceTypeId?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  service?: InputMaybe<Service_Bool_Exp>;
  serviceId?: InputMaybe<Uuid_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  workspaceId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "serviceDeviceType" */
export enum ServiceDeviceType_Constraint {
  /** unique or primary key constraint on columns "id" */
  ServiceDeviceTypePkey = 'serviceDeviceType_pkey',
  /** unique or primary key constraint on columns "serviceId", "deviceTypeId" */
  ServiceDeviceTypeServiceIdDeviceTypeIdKey = 'serviceDeviceType_serviceId_deviceTypeId_key'
}

/** input type for inserting data into table "serviceDeviceType" */
export type ServiceDeviceType_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  deviceType?: InputMaybe<DeviceType_Obj_Rel_Insert_Input>;
  deviceTypeId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  service?: InputMaybe<Service_Obj_Rel_Insert_Input>;
  serviceId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type ServiceDeviceType_Max_Fields = {
  __typename?: 'serviceDeviceType_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  deviceTypeId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  serviceId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "serviceDeviceType" */
export type ServiceDeviceType_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  deviceTypeId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  serviceId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type ServiceDeviceType_Min_Fields = {
  __typename?: 'serviceDeviceType_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  deviceTypeId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  serviceId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "serviceDeviceType" */
export type ServiceDeviceType_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  deviceTypeId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  serviceId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "serviceDeviceType" */
export type ServiceDeviceType_Mutation_Response = {
  __typename?: 'serviceDeviceType_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ServiceDeviceType>;
};

/** on_conflict condition type for table "serviceDeviceType" */
export type ServiceDeviceType_On_Conflict = {
  constraint: ServiceDeviceType_Constraint;
  update_columns?: Array<ServiceDeviceType_Update_Column>;
  where?: InputMaybe<ServiceDeviceType_Bool_Exp>;
};

/** Ordering options when selecting data from "serviceDeviceType". */
export type ServiceDeviceType_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  deviceType?: InputMaybe<DeviceType_Order_By>;
  deviceTypeId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  service?: InputMaybe<Service_Order_By>;
  serviceId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: serviceDeviceType */
export type ServiceDeviceType_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "serviceDeviceType" */
export enum ServiceDeviceType_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DeviceTypeId = 'deviceTypeId',
  /** column name */
  Id = 'id',
  /** column name */
  ServiceId = 'serviceId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WorkspaceId = 'workspaceId'
}

/** input type for updating data in table "serviceDeviceType" */
export type ServiceDeviceType_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  deviceTypeId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  serviceId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "serviceDeviceType" */
export type ServiceDeviceType_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ServiceDeviceType_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ServiceDeviceType_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  deviceTypeId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  serviceId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "serviceDeviceType" */
export enum ServiceDeviceType_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DeviceTypeId = 'deviceTypeId',
  /** column name */
  Id = 'id',
  /** column name */
  ServiceId = 'serviceId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WorkspaceId = 'workspaceId'
}

export type ServiceDeviceType_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ServiceDeviceType_Set_Input>;
  /** filter the rows which have to be updated */
  where: ServiceDeviceType_Bool_Exp;
};

/** columns and relationships of "serviceProvider" */
export type ServiceProvider = {
  __typename?: 'serviceProvider';
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An object relationship */
  provider: Provider;
  providerId: Scalars['uuid'];
  /** An object relationship */
  service: Service;
  serviceId: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "serviceProvider" */
export type ServiceProvider_Aggregate = {
  __typename?: 'serviceProvider_aggregate';
  aggregate?: Maybe<ServiceProvider_Aggregate_Fields>;
  nodes: Array<ServiceProvider>;
};

export type ServiceProvider_Aggregate_Bool_Exp = {
  count?: InputMaybe<ServiceProvider_Aggregate_Bool_Exp_Count>;
};

export type ServiceProvider_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<ServiceProvider_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ServiceProvider_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "serviceProvider" */
export type ServiceProvider_Aggregate_Fields = {
  __typename?: 'serviceProvider_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ServiceProvider_Max_Fields>;
  min?: Maybe<ServiceProvider_Min_Fields>;
};


/** aggregate fields of "serviceProvider" */
export type ServiceProvider_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<ServiceProvider_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "serviceProvider" */
export type ServiceProvider_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<ServiceProvider_Max_Order_By>;
  min?: InputMaybe<ServiceProvider_Min_Order_By>;
};

/** input type for inserting array relation for remote table "serviceProvider" */
export type ServiceProvider_Arr_Rel_Insert_Input = {
  data: Array<ServiceProvider_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<ServiceProvider_On_Conflict>;
};

/** Boolean expression to filter rows from the table "serviceProvider". All fields are combined with a logical 'AND'. */
export type ServiceProvider_Bool_Exp = {
  _and?: InputMaybe<Array<ServiceProvider_Bool_Exp>>;
  _not?: InputMaybe<ServiceProvider_Bool_Exp>;
  _or?: InputMaybe<Array<ServiceProvider_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  provider?: InputMaybe<Provider_Bool_Exp>;
  providerId?: InputMaybe<Uuid_Comparison_Exp>;
  service?: InputMaybe<Service_Bool_Exp>;
  serviceId?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "serviceProvider" */
export enum ServiceProvider_Constraint {
  /** unique or primary key constraint on columns "id" */
  ServiceProviderPkey = 'serviceProvider_pkey',
  /** unique or primary key constraint on columns "serviceId", "providerId" */
  ServiceProviderServiceIdProviderIdKey = 'serviceProvider_serviceId_providerId_key'
}

/** input type for inserting data into table "serviceProvider" */
export type ServiceProvider_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  provider?: InputMaybe<Provider_Obj_Rel_Insert_Input>;
  providerId?: InputMaybe<Scalars['uuid']>;
  service?: InputMaybe<Service_Obj_Rel_Insert_Input>;
  serviceId?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type ServiceProvider_Max_Fields = {
  __typename?: 'serviceProvider_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  providerId?: Maybe<Scalars['uuid']>;
  serviceId?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "serviceProvider" */
export type ServiceProvider_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  providerId?: InputMaybe<Order_By>;
  serviceId?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type ServiceProvider_Min_Fields = {
  __typename?: 'serviceProvider_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  providerId?: Maybe<Scalars['uuid']>;
  serviceId?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "serviceProvider" */
export type ServiceProvider_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  providerId?: InputMaybe<Order_By>;
  serviceId?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "serviceProvider" */
export type ServiceProvider_Mutation_Response = {
  __typename?: 'serviceProvider_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ServiceProvider>;
};

/** on_conflict condition type for table "serviceProvider" */
export type ServiceProvider_On_Conflict = {
  constraint: ServiceProvider_Constraint;
  update_columns?: Array<ServiceProvider_Update_Column>;
  where?: InputMaybe<ServiceProvider_Bool_Exp>;
};

/** Ordering options when selecting data from "serviceProvider". */
export type ServiceProvider_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  provider?: InputMaybe<Provider_Order_By>;
  providerId?: InputMaybe<Order_By>;
  service?: InputMaybe<Service_Order_By>;
  serviceId?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: serviceProvider */
export type ServiceProvider_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "serviceProvider" */
export enum ServiceProvider_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ProviderId = 'providerId',
  /** column name */
  ServiceId = 'serviceId',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "serviceProvider" */
export type ServiceProvider_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  providerId?: InputMaybe<Scalars['uuid']>;
  serviceId?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "serviceProvider" */
export type ServiceProvider_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ServiceProvider_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ServiceProvider_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  providerId?: InputMaybe<Scalars['uuid']>;
  serviceId?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "serviceProvider" */
export enum ServiceProvider_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ProviderId = 'providerId',
  /** column name */
  ServiceId = 'serviceId',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type ServiceProvider_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ServiceProvider_Set_Input>;
  /** filter the rows which have to be updated */
  where: ServiceProvider_Bool_Exp;
};

/** columns and relationships of "serviceUnit" */
export type ServiceUnit = {
  __typename?: 'serviceUnit';
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  name: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  workspaceId: Scalars['uuid'];
};

/** aggregated selection of "serviceUnit" */
export type ServiceUnit_Aggregate = {
  __typename?: 'serviceUnit_aggregate';
  aggregate?: Maybe<ServiceUnit_Aggregate_Fields>;
  nodes: Array<ServiceUnit>;
};

/** aggregate fields of "serviceUnit" */
export type ServiceUnit_Aggregate_Fields = {
  __typename?: 'serviceUnit_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ServiceUnit_Max_Fields>;
  min?: Maybe<ServiceUnit_Min_Fields>;
};


/** aggregate fields of "serviceUnit" */
export type ServiceUnit_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<ServiceUnit_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "serviceUnit". All fields are combined with a logical 'AND'. */
export type ServiceUnit_Bool_Exp = {
  _and?: InputMaybe<Array<ServiceUnit_Bool_Exp>>;
  _not?: InputMaybe<ServiceUnit_Bool_Exp>;
  _or?: InputMaybe<Array<ServiceUnit_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  workspaceId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "serviceUnit" */
export enum ServiceUnit_Constraint {
  /** unique or primary key constraint on columns "workspaceId", "name" */
  ServiceUnitNameWorkspaceIdKey = 'serviceUnit_name_workspaceId_key',
  /** unique or primary key constraint on columns "id" */
  ServiceUnitPkey = 'serviceUnit_pkey'
}

/** input type for inserting data into table "serviceUnit" */
export type ServiceUnit_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type ServiceUnit_Max_Fields = {
  __typename?: 'serviceUnit_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type ServiceUnit_Min_Fields = {
  __typename?: 'serviceUnit_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "serviceUnit" */
export type ServiceUnit_Mutation_Response = {
  __typename?: 'serviceUnit_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ServiceUnit>;
};

/** input type for inserting object relation for remote table "serviceUnit" */
export type ServiceUnit_Obj_Rel_Insert_Input = {
  data: ServiceUnit_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<ServiceUnit_On_Conflict>;
};

/** on_conflict condition type for table "serviceUnit" */
export type ServiceUnit_On_Conflict = {
  constraint: ServiceUnit_Constraint;
  update_columns?: Array<ServiceUnit_Update_Column>;
  where?: InputMaybe<ServiceUnit_Bool_Exp>;
};

/** Ordering options when selecting data from "serviceUnit". */
export type ServiceUnit_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: serviceUnit */
export type ServiceUnit_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "serviceUnit" */
export enum ServiceUnit_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WorkspaceId = 'workspaceId'
}

/** input type for updating data in table "serviceUnit" */
export type ServiceUnit_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "serviceUnit" */
export type ServiceUnit_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ServiceUnit_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ServiceUnit_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "serviceUnit" */
export enum ServiceUnit_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WorkspaceId = 'workspaceId'
}

export type ServiceUnit_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ServiceUnit_Set_Input>;
  /** filter the rows which have to be updated */
  where: ServiceUnit_Bool_Exp;
};

/** aggregated selection of "service" */
export type Service_Aggregate = {
  __typename?: 'service_aggregate';
  aggregate?: Maybe<Service_Aggregate_Fields>;
  nodes: Array<Service>;
};

export type Service_Aggregate_Bool_Exp = {
  avg?: InputMaybe<Service_Aggregate_Bool_Exp_Avg>;
  bool_and?: InputMaybe<Service_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Service_Aggregate_Bool_Exp_Bool_Or>;
  corr?: InputMaybe<Service_Aggregate_Bool_Exp_Corr>;
  count?: InputMaybe<Service_Aggregate_Bool_Exp_Count>;
  covar_samp?: InputMaybe<Service_Aggregate_Bool_Exp_Covar_Samp>;
  max?: InputMaybe<Service_Aggregate_Bool_Exp_Max>;
  min?: InputMaybe<Service_Aggregate_Bool_Exp_Min>;
  stddev_samp?: InputMaybe<Service_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: InputMaybe<Service_Aggregate_Bool_Exp_Sum>;
  var_samp?: InputMaybe<Service_Aggregate_Bool_Exp_Var_Samp>;
};

export type Service_Aggregate_Bool_Exp_Avg = {
  arguments: Service_Select_Column_Service_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Service_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Service_Aggregate_Bool_Exp_Bool_And = {
  arguments: Service_Select_Column_Service_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Service_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Service_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Service_Select_Column_Service_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Service_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Service_Aggregate_Bool_Exp_Corr = {
  arguments: Service_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Service_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Service_Aggregate_Bool_Exp_Corr_Arguments = {
  X: Service_Select_Column_Service_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: Service_Select_Column_Service_Aggregate_Bool_Exp_Corr_Arguments_Columns;
};

export type Service_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Service_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Service_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type Service_Aggregate_Bool_Exp_Covar_Samp = {
  arguments: Service_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Service_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Service_Aggregate_Bool_Exp_Covar_Samp_Arguments = {
  X: Service_Select_Column_Service_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  Y: Service_Select_Column_Service_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
};

export type Service_Aggregate_Bool_Exp_Max = {
  arguments: Service_Select_Column_Service_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Service_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Service_Aggregate_Bool_Exp_Min = {
  arguments: Service_Select_Column_Service_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Service_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Service_Aggregate_Bool_Exp_Stddev_Samp = {
  arguments: Service_Select_Column_Service_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Service_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Service_Aggregate_Bool_Exp_Sum = {
  arguments: Service_Select_Column_Service_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Service_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Service_Aggregate_Bool_Exp_Var_Samp = {
  arguments: Service_Select_Column_Service_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Service_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

/** aggregate fields of "service" */
export type Service_Aggregate_Fields = {
  __typename?: 'service_aggregate_fields';
  avg?: Maybe<Service_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Service_Max_Fields>;
  min?: Maybe<Service_Min_Fields>;
  stddev?: Maybe<Service_Stddev_Fields>;
  stddev_pop?: Maybe<Service_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Service_Stddev_Samp_Fields>;
  sum?: Maybe<Service_Sum_Fields>;
  var_pop?: Maybe<Service_Var_Pop_Fields>;
  var_samp?: Maybe<Service_Var_Samp_Fields>;
  variance?: Maybe<Service_Variance_Fields>;
};


/** aggregate fields of "service" */
export type Service_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Service_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "service" */
export type Service_Aggregate_Order_By = {
  avg?: InputMaybe<Service_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Service_Max_Order_By>;
  min?: InputMaybe<Service_Min_Order_By>;
  stddev?: InputMaybe<Service_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Service_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Service_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Service_Sum_Order_By>;
  var_pop?: InputMaybe<Service_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Service_Var_Samp_Order_By>;
  variance?: InputMaybe<Service_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Service_Append_Input = {
  onlineBookingRules?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "service" */
export type Service_Arr_Rel_Insert_Input = {
  data: Array<Service_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Service_On_Conflict>;
};

/** aggregate avg on columns */
export type Service_Avg_Fields = {
  __typename?: 'service_avg_fields';
  cost?: Maybe<Scalars['Float']>;
  deposit?: Maybe<Scalars['Float']>;
  deviceBufferTime?: Maybe<Scalars['Float']>;
  minutesToPerform?: Maybe<Scalars['Float']>;
  newPatientMinutesToPerform?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  taxRate?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "service" */
export type Service_Avg_Order_By = {
  cost?: InputMaybe<Order_By>;
  deposit?: InputMaybe<Order_By>;
  deviceBufferTime?: InputMaybe<Order_By>;
  minutesToPerform?: InputMaybe<Order_By>;
  newPatientMinutesToPerform?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  taxRate?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "service". All fields are combined with a logical 'AND'. */
export type Service_Bool_Exp = {
  _and?: InputMaybe<Array<Service_Bool_Exp>>;
  _not?: InputMaybe<Service_Bool_Exp>;
  _or?: InputMaybe<Array<Service_Bool_Exp>>;
  active?: InputMaybe<Boolean_Comparison_Exp>;
  allowGratuity?: InputMaybe<Boolean_Comparison_Exp>;
  appointmentServices?: InputMaybe<AppointmentService_Bool_Exp>;
  appointmentServices_aggregate?: InputMaybe<AppointmentService_Aggregate_Bool_Exp>;
  appointmentType?: InputMaybe<AppointmentType_Bool_Exp>;
  appointmentTypeId?: InputMaybe<Uuid_Comparison_Exp>;
  archived?: InputMaybe<Boolean_Comparison_Exp>;
  availableInStore?: InputMaybe<Boolean_Comparison_Exp>;
  canBeBookedOnline?: InputMaybe<Boolean_Comparison_Exp>;
  color?: InputMaybe<String_Comparison_Exp>;
  cost?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deposit?: InputMaybe<Int_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  deviceBufferTime?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  image?: InputMaybe<String_Comparison_Exp>;
  importJobId?: InputMaybe<Uuid_Comparison_Exp>;
  incompatibleServices?: InputMaybe<IncompatibleServices_Bool_Exp>;
  incompatibleServicesByServicebid?: InputMaybe<IncompatibleServices_Bool_Exp>;
  incompatibleServicesByServicebid_aggregate?: InputMaybe<IncompatibleServices_Aggregate_Bool_Exp>;
  incompatibleServices_aggregate?: InputMaybe<IncompatibleServices_Aggregate_Bool_Exp>;
  invoiceServiceItems?: InputMaybe<InvoiceServiceItem_Bool_Exp>;
  invoiceServiceItems_aggregate?: InputMaybe<InvoiceServiceItem_Aggregate_Bool_Exp>;
  isProduct?: InputMaybe<Boolean_Comparison_Exp>;
  locationServices?: InputMaybe<LocationService_Bool_Exp>;
  locationServices_aggregate?: InputMaybe<LocationService_Aggregate_Bool_Exp>;
  minutesToPerform?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  newPatientMinutesToPerform?: InputMaybe<Int_Comparison_Exp>;
  onlineBookingRules?: InputMaybe<Jsonb_Comparison_Exp>;
  patientPortalInviteAddendum?: InputMaybe<String_Comparison_Exp>;
  patientPortalInviteSMSAddendum?: InputMaybe<String_Comparison_Exp>;
  postcareDocument?: InputMaybe<Document_Bool_Exp>;
  postcareDocumentId?: InputMaybe<Uuid_Comparison_Exp>;
  precareDocument?: InputMaybe<Document_Bool_Exp>;
  precareDocumentId?: InputMaybe<Uuid_Comparison_Exp>;
  price?: InputMaybe<Int_Comparison_Exp>;
  pricingModel?: InputMaybe<String_Comparison_Exp>;
  productBarcodeId?: InputMaybe<String_Comparison_Exp>;
  requiresDeposit?: InputMaybe<Boolean_Comparison_Exp>;
  serviceAddOns?: InputMaybe<ServiceAddOn_Bool_Exp>;
  serviceAddOns_aggregate?: InputMaybe<ServiceAddOn_Aggregate_Bool_Exp>;
  serviceCategory?: InputMaybe<ServiceCategory_Bool_Exp>;
  serviceCategoryId?: InputMaybe<Uuid_Comparison_Exp>;
  serviceConsents?: InputMaybe<ServiceConsent_Bool_Exp>;
  serviceConsents_aggregate?: InputMaybe<ServiceConsent_Aggregate_Bool_Exp>;
  serviceConsumableTypes?: InputMaybe<ServiceConsumableType_Bool_Exp>;
  serviceConsumableTypes_aggregate?: InputMaybe<ServiceConsumableType_Aggregate_Bool_Exp>;
  serviceCoupons?: InputMaybe<ServiceCoupon_Bool_Exp>;
  serviceCoupons_aggregate?: InputMaybe<ServiceCoupon_Aggregate_Bool_Exp>;
  serviceCustomForms?: InputMaybe<ServiceCustomForm_Bool_Exp>;
  serviceCustomForms_aggregate?: InputMaybe<ServiceCustomForm_Aggregate_Bool_Exp>;
  serviceDeviceTypes?: InputMaybe<ServiceDeviceType_Bool_Exp>;
  serviceDeviceTypes_aggregate?: InputMaybe<ServiceDeviceType_Aggregate_Bool_Exp>;
  serviceProviders?: InputMaybe<ServiceProvider_Bool_Exp>;
  serviceProviders_aggregate?: InputMaybe<ServiceProvider_Aggregate_Bool_Exp>;
  serviceUnit?: InputMaybe<ServiceUnit_Bool_Exp>;
  serviceUnitId?: InputMaybe<Uuid_Comparison_Exp>;
  taxRate?: InputMaybe<Float8_Comparison_Exp>;
  triggersPatientPortalInvite?: InputMaybe<Boolean_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  viewOnlyInStore?: InputMaybe<Boolean_Comparison_Exp>;
  workspace?: InputMaybe<Workspace_Bool_Exp>;
  workspaceId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "service" */
export enum Service_Constraint {
  /** unique or primary key constraint on columns "id" */
  ServicePkey = 'service_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Service_Delete_At_Path_Input = {
  onlineBookingRules?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Service_Delete_Elem_Input = {
  onlineBookingRules?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Service_Delete_Key_Input = {
  onlineBookingRules?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "service" */
export type Service_Inc_Input = {
  cost?: InputMaybe<Scalars['Int']>;
  deposit?: InputMaybe<Scalars['Int']>;
  deviceBufferTime?: InputMaybe<Scalars['Int']>;
  minutesToPerform?: InputMaybe<Scalars['Int']>;
  newPatientMinutesToPerform?: InputMaybe<Scalars['Int']>;
  price?: InputMaybe<Scalars['Int']>;
  taxRate?: InputMaybe<Scalars['float8']>;
};

/** input type for inserting data into table "service" */
export type Service_Insert_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  allowGratuity?: InputMaybe<Scalars['Boolean']>;
  appointmentServices?: InputMaybe<AppointmentService_Arr_Rel_Insert_Input>;
  appointmentType?: InputMaybe<AppointmentType_Obj_Rel_Insert_Input>;
  appointmentTypeId?: InputMaybe<Scalars['uuid']>;
  archived?: InputMaybe<Scalars['Boolean']>;
  availableInStore?: InputMaybe<Scalars['Boolean']>;
  canBeBookedOnline?: InputMaybe<Scalars['Boolean']>;
  color?: InputMaybe<Scalars['String']>;
  cost?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deposit?: InputMaybe<Scalars['Int']>;
  description?: InputMaybe<Scalars['String']>;
  deviceBufferTime?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  image?: InputMaybe<Scalars['String']>;
  importJobId?: InputMaybe<Scalars['uuid']>;
  incompatibleServices?: InputMaybe<IncompatibleServices_Arr_Rel_Insert_Input>;
  incompatibleServicesByServicebid?: InputMaybe<IncompatibleServices_Arr_Rel_Insert_Input>;
  invoiceServiceItems?: InputMaybe<InvoiceServiceItem_Arr_Rel_Insert_Input>;
  isProduct?: InputMaybe<Scalars['Boolean']>;
  locationServices?: InputMaybe<LocationService_Arr_Rel_Insert_Input>;
  minutesToPerform?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  newPatientMinutesToPerform?: InputMaybe<Scalars['Int']>;
  onlineBookingRules?: InputMaybe<Scalars['jsonb']>;
  patientPortalInviteAddendum?: InputMaybe<Scalars['String']>;
  patientPortalInviteSMSAddendum?: InputMaybe<Scalars['String']>;
  postcareDocument?: InputMaybe<Document_Obj_Rel_Insert_Input>;
  postcareDocumentId?: InputMaybe<Scalars['uuid']>;
  precareDocument?: InputMaybe<Document_Obj_Rel_Insert_Input>;
  precareDocumentId?: InputMaybe<Scalars['uuid']>;
  price?: InputMaybe<Scalars['Int']>;
  pricingModel?: InputMaybe<Scalars['String']>;
  productBarcodeId?: InputMaybe<Scalars['String']>;
  requiresDeposit?: InputMaybe<Scalars['Boolean']>;
  serviceAddOns?: InputMaybe<ServiceAddOn_Arr_Rel_Insert_Input>;
  serviceCategory?: InputMaybe<ServiceCategory_Obj_Rel_Insert_Input>;
  serviceCategoryId?: InputMaybe<Scalars['uuid']>;
  serviceConsents?: InputMaybe<ServiceConsent_Arr_Rel_Insert_Input>;
  serviceConsumableTypes?: InputMaybe<ServiceConsumableType_Arr_Rel_Insert_Input>;
  serviceCoupons?: InputMaybe<ServiceCoupon_Arr_Rel_Insert_Input>;
  serviceCustomForms?: InputMaybe<ServiceCustomForm_Arr_Rel_Insert_Input>;
  serviceDeviceTypes?: InputMaybe<ServiceDeviceType_Arr_Rel_Insert_Input>;
  serviceProviders?: InputMaybe<ServiceProvider_Arr_Rel_Insert_Input>;
  serviceUnit?: InputMaybe<ServiceUnit_Obj_Rel_Insert_Input>;
  serviceUnitId?: InputMaybe<Scalars['uuid']>;
  taxRate?: InputMaybe<Scalars['float8']>;
  triggersPatientPortalInvite?: InputMaybe<Scalars['Boolean']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  viewOnlyInStore?: InputMaybe<Scalars['Boolean']>;
  workspace?: InputMaybe<Workspace_Obj_Rel_Insert_Input>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Service_Max_Fields = {
  __typename?: 'service_max_fields';
  appointmentTypeId?: Maybe<Scalars['uuid']>;
  color?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deposit?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  deviceBufferTime?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  image?: Maybe<Scalars['String']>;
  importJobId?: Maybe<Scalars['uuid']>;
  minutesToPerform?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  newPatientMinutesToPerform?: Maybe<Scalars['Int']>;
  patientPortalInviteAddendum?: Maybe<Scalars['String']>;
  patientPortalInviteSMSAddendum?: Maybe<Scalars['String']>;
  postcareDocumentId?: Maybe<Scalars['uuid']>;
  precareDocumentId?: Maybe<Scalars['uuid']>;
  price?: Maybe<Scalars['Int']>;
  pricingModel?: Maybe<Scalars['String']>;
  productBarcodeId?: Maybe<Scalars['String']>;
  serviceCategoryId?: Maybe<Scalars['uuid']>;
  serviceUnitId?: Maybe<Scalars['uuid']>;
  taxRate?: Maybe<Scalars['float8']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "service" */
export type Service_Max_Order_By = {
  appointmentTypeId?: InputMaybe<Order_By>;
  color?: InputMaybe<Order_By>;
  cost?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deposit?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  deviceBufferTime?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  image?: InputMaybe<Order_By>;
  importJobId?: InputMaybe<Order_By>;
  minutesToPerform?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  newPatientMinutesToPerform?: InputMaybe<Order_By>;
  patientPortalInviteAddendum?: InputMaybe<Order_By>;
  patientPortalInviteSMSAddendum?: InputMaybe<Order_By>;
  postcareDocumentId?: InputMaybe<Order_By>;
  precareDocumentId?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  pricingModel?: InputMaybe<Order_By>;
  productBarcodeId?: InputMaybe<Order_By>;
  serviceCategoryId?: InputMaybe<Order_By>;
  serviceUnitId?: InputMaybe<Order_By>;
  taxRate?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Service_Min_Fields = {
  __typename?: 'service_min_fields';
  appointmentTypeId?: Maybe<Scalars['uuid']>;
  color?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deposit?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  deviceBufferTime?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  image?: Maybe<Scalars['String']>;
  importJobId?: Maybe<Scalars['uuid']>;
  minutesToPerform?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  newPatientMinutesToPerform?: Maybe<Scalars['Int']>;
  patientPortalInviteAddendum?: Maybe<Scalars['String']>;
  patientPortalInviteSMSAddendum?: Maybe<Scalars['String']>;
  postcareDocumentId?: Maybe<Scalars['uuid']>;
  precareDocumentId?: Maybe<Scalars['uuid']>;
  price?: Maybe<Scalars['Int']>;
  pricingModel?: Maybe<Scalars['String']>;
  productBarcodeId?: Maybe<Scalars['String']>;
  serviceCategoryId?: Maybe<Scalars['uuid']>;
  serviceUnitId?: Maybe<Scalars['uuid']>;
  taxRate?: Maybe<Scalars['float8']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "service" */
export type Service_Min_Order_By = {
  appointmentTypeId?: InputMaybe<Order_By>;
  color?: InputMaybe<Order_By>;
  cost?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deposit?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  deviceBufferTime?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  image?: InputMaybe<Order_By>;
  importJobId?: InputMaybe<Order_By>;
  minutesToPerform?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  newPatientMinutesToPerform?: InputMaybe<Order_By>;
  patientPortalInviteAddendum?: InputMaybe<Order_By>;
  patientPortalInviteSMSAddendum?: InputMaybe<Order_By>;
  postcareDocumentId?: InputMaybe<Order_By>;
  precareDocumentId?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  pricingModel?: InputMaybe<Order_By>;
  productBarcodeId?: InputMaybe<Order_By>;
  serviceCategoryId?: InputMaybe<Order_By>;
  serviceUnitId?: InputMaybe<Order_By>;
  taxRate?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "service" */
export type Service_Mutation_Response = {
  __typename?: 'service_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Service>;
};

/** input type for inserting object relation for remote table "service" */
export type Service_Obj_Rel_Insert_Input = {
  data: Service_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Service_On_Conflict>;
};

/** on_conflict condition type for table "service" */
export type Service_On_Conflict = {
  constraint: Service_Constraint;
  update_columns?: Array<Service_Update_Column>;
  where?: InputMaybe<Service_Bool_Exp>;
};

/** Ordering options when selecting data from "service". */
export type Service_Order_By = {
  active?: InputMaybe<Order_By>;
  allowGratuity?: InputMaybe<Order_By>;
  appointmentServices_aggregate?: InputMaybe<AppointmentService_Aggregate_Order_By>;
  appointmentType?: InputMaybe<AppointmentType_Order_By>;
  appointmentTypeId?: InputMaybe<Order_By>;
  archived?: InputMaybe<Order_By>;
  availableInStore?: InputMaybe<Order_By>;
  canBeBookedOnline?: InputMaybe<Order_By>;
  color?: InputMaybe<Order_By>;
  cost?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deposit?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  deviceBufferTime?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  image?: InputMaybe<Order_By>;
  importJobId?: InputMaybe<Order_By>;
  incompatibleServicesByServicebid_aggregate?: InputMaybe<IncompatibleServices_Aggregate_Order_By>;
  incompatibleServices_aggregate?: InputMaybe<IncompatibleServices_Aggregate_Order_By>;
  invoiceServiceItems_aggregate?: InputMaybe<InvoiceServiceItem_Aggregate_Order_By>;
  isProduct?: InputMaybe<Order_By>;
  locationServices_aggregate?: InputMaybe<LocationService_Aggregate_Order_By>;
  minutesToPerform?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  newPatientMinutesToPerform?: InputMaybe<Order_By>;
  onlineBookingRules?: InputMaybe<Order_By>;
  patientPortalInviteAddendum?: InputMaybe<Order_By>;
  patientPortalInviteSMSAddendum?: InputMaybe<Order_By>;
  postcareDocument?: InputMaybe<Document_Order_By>;
  postcareDocumentId?: InputMaybe<Order_By>;
  precareDocument?: InputMaybe<Document_Order_By>;
  precareDocumentId?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  pricingModel?: InputMaybe<Order_By>;
  productBarcodeId?: InputMaybe<Order_By>;
  requiresDeposit?: InputMaybe<Order_By>;
  serviceAddOns_aggregate?: InputMaybe<ServiceAddOn_Aggregate_Order_By>;
  serviceCategory?: InputMaybe<ServiceCategory_Order_By>;
  serviceCategoryId?: InputMaybe<Order_By>;
  serviceConsents_aggregate?: InputMaybe<ServiceConsent_Aggregate_Order_By>;
  serviceConsumableTypes_aggregate?: InputMaybe<ServiceConsumableType_Aggregate_Order_By>;
  serviceCoupons_aggregate?: InputMaybe<ServiceCoupon_Aggregate_Order_By>;
  serviceCustomForms_aggregate?: InputMaybe<ServiceCustomForm_Aggregate_Order_By>;
  serviceDeviceTypes_aggregate?: InputMaybe<ServiceDeviceType_Aggregate_Order_By>;
  serviceProviders_aggregate?: InputMaybe<ServiceProvider_Aggregate_Order_By>;
  serviceUnit?: InputMaybe<ServiceUnit_Order_By>;
  serviceUnitId?: InputMaybe<Order_By>;
  taxRate?: InputMaybe<Order_By>;
  triggersPatientPortalInvite?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  viewOnlyInStore?: InputMaybe<Order_By>;
  workspace?: InputMaybe<Workspace_Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: service */
export type Service_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Service_Prepend_Input = {
  onlineBookingRules?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "service" */
export enum Service_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  AllowGratuity = 'allowGratuity',
  /** column name */
  AppointmentTypeId = 'appointmentTypeId',
  /** column name */
  Archived = 'archived',
  /** column name */
  AvailableInStore = 'availableInStore',
  /** column name */
  CanBeBookedOnline = 'canBeBookedOnline',
  /** column name */
  Color = 'color',
  /** column name */
  Cost = 'cost',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Deposit = 'deposit',
  /** column name */
  Description = 'description',
  /** column name */
  DeviceBufferTime = 'deviceBufferTime',
  /** column name */
  Id = 'id',
  /** column name */
  Image = 'image',
  /** column name */
  ImportJobId = 'importJobId',
  /** column name */
  IsProduct = 'isProduct',
  /** column name */
  MinutesToPerform = 'minutesToPerform',
  /** column name */
  Name = 'name',
  /** column name */
  NewPatientMinutesToPerform = 'newPatientMinutesToPerform',
  /** column name */
  OnlineBookingRules = 'onlineBookingRules',
  /** column name */
  PatientPortalInviteAddendum = 'patientPortalInviteAddendum',
  /** column name */
  PatientPortalInviteSmsAddendum = 'patientPortalInviteSMSAddendum',
  /** column name */
  PostcareDocumentId = 'postcareDocumentId',
  /** column name */
  PrecareDocumentId = 'precareDocumentId',
  /** column name */
  Price = 'price',
  /** column name */
  PricingModel = 'pricingModel',
  /** column name */
  ProductBarcodeId = 'productBarcodeId',
  /** column name */
  RequiresDeposit = 'requiresDeposit',
  /** column name */
  ServiceCategoryId = 'serviceCategoryId',
  /** column name */
  ServiceUnitId = 'serviceUnitId',
  /** column name */
  TaxRate = 'taxRate',
  /** column name */
  TriggersPatientPortalInvite = 'triggersPatientPortalInvite',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  ViewOnlyInStore = 'viewOnlyInStore',
  /** column name */
  WorkspaceId = 'workspaceId'
}

/** select "service_aggregate_bool_exp_avg_arguments_columns" columns of table "service" */
export enum Service_Select_Column_Service_Aggregate_Bool_Exp_Avg_Arguments_Columns {
  /** column name */
  TaxRate = 'taxRate'
}

/** select "service_aggregate_bool_exp_bool_and_arguments_columns" columns of table "service" */
export enum Service_Select_Column_Service_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Active = 'active',
  /** column name */
  AllowGratuity = 'allowGratuity',
  /** column name */
  Archived = 'archived',
  /** column name */
  AvailableInStore = 'availableInStore',
  /** column name */
  CanBeBookedOnline = 'canBeBookedOnline',
  /** column name */
  IsProduct = 'isProduct',
  /** column name */
  RequiresDeposit = 'requiresDeposit',
  /** column name */
  TriggersPatientPortalInvite = 'triggersPatientPortalInvite',
  /** column name */
  ViewOnlyInStore = 'viewOnlyInStore'
}

/** select "service_aggregate_bool_exp_bool_or_arguments_columns" columns of table "service" */
export enum Service_Select_Column_Service_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Active = 'active',
  /** column name */
  AllowGratuity = 'allowGratuity',
  /** column name */
  Archived = 'archived',
  /** column name */
  AvailableInStore = 'availableInStore',
  /** column name */
  CanBeBookedOnline = 'canBeBookedOnline',
  /** column name */
  IsProduct = 'isProduct',
  /** column name */
  RequiresDeposit = 'requiresDeposit',
  /** column name */
  TriggersPatientPortalInvite = 'triggersPatientPortalInvite',
  /** column name */
  ViewOnlyInStore = 'viewOnlyInStore'
}

/** select "service_aggregate_bool_exp_corr_arguments_columns" columns of table "service" */
export enum Service_Select_Column_Service_Aggregate_Bool_Exp_Corr_Arguments_Columns {
  /** column name */
  TaxRate = 'taxRate'
}

/** select "service_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "service" */
export enum Service_Select_Column_Service_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns {
  /** column name */
  TaxRate = 'taxRate'
}

/** select "service_aggregate_bool_exp_max_arguments_columns" columns of table "service" */
export enum Service_Select_Column_Service_Aggregate_Bool_Exp_Max_Arguments_Columns {
  /** column name */
  TaxRate = 'taxRate'
}

/** select "service_aggregate_bool_exp_min_arguments_columns" columns of table "service" */
export enum Service_Select_Column_Service_Aggregate_Bool_Exp_Min_Arguments_Columns {
  /** column name */
  TaxRate = 'taxRate'
}

/** select "service_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "service" */
export enum Service_Select_Column_Service_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns {
  /** column name */
  TaxRate = 'taxRate'
}

/** select "service_aggregate_bool_exp_sum_arguments_columns" columns of table "service" */
export enum Service_Select_Column_Service_Aggregate_Bool_Exp_Sum_Arguments_Columns {
  /** column name */
  TaxRate = 'taxRate'
}

/** select "service_aggregate_bool_exp_var_samp_arguments_columns" columns of table "service" */
export enum Service_Select_Column_Service_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns {
  /** column name */
  TaxRate = 'taxRate'
}

/** input type for updating data in table "service" */
export type Service_Set_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  allowGratuity?: InputMaybe<Scalars['Boolean']>;
  appointmentTypeId?: InputMaybe<Scalars['uuid']>;
  archived?: InputMaybe<Scalars['Boolean']>;
  availableInStore?: InputMaybe<Scalars['Boolean']>;
  canBeBookedOnline?: InputMaybe<Scalars['Boolean']>;
  color?: InputMaybe<Scalars['String']>;
  cost?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deposit?: InputMaybe<Scalars['Int']>;
  description?: InputMaybe<Scalars['String']>;
  deviceBufferTime?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  image?: InputMaybe<Scalars['String']>;
  importJobId?: InputMaybe<Scalars['uuid']>;
  isProduct?: InputMaybe<Scalars['Boolean']>;
  minutesToPerform?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  newPatientMinutesToPerform?: InputMaybe<Scalars['Int']>;
  onlineBookingRules?: InputMaybe<Scalars['jsonb']>;
  patientPortalInviteAddendum?: InputMaybe<Scalars['String']>;
  patientPortalInviteSMSAddendum?: InputMaybe<Scalars['String']>;
  postcareDocumentId?: InputMaybe<Scalars['uuid']>;
  precareDocumentId?: InputMaybe<Scalars['uuid']>;
  price?: InputMaybe<Scalars['Int']>;
  pricingModel?: InputMaybe<Scalars['String']>;
  productBarcodeId?: InputMaybe<Scalars['String']>;
  requiresDeposit?: InputMaybe<Scalars['Boolean']>;
  serviceCategoryId?: InputMaybe<Scalars['uuid']>;
  serviceUnitId?: InputMaybe<Scalars['uuid']>;
  taxRate?: InputMaybe<Scalars['float8']>;
  triggersPatientPortalInvite?: InputMaybe<Scalars['Boolean']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  viewOnlyInStore?: InputMaybe<Scalars['Boolean']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Service_Stddev_Fields = {
  __typename?: 'service_stddev_fields';
  cost?: Maybe<Scalars['Float']>;
  deposit?: Maybe<Scalars['Float']>;
  deviceBufferTime?: Maybe<Scalars['Float']>;
  minutesToPerform?: Maybe<Scalars['Float']>;
  newPatientMinutesToPerform?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  taxRate?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "service" */
export type Service_Stddev_Order_By = {
  cost?: InputMaybe<Order_By>;
  deposit?: InputMaybe<Order_By>;
  deviceBufferTime?: InputMaybe<Order_By>;
  minutesToPerform?: InputMaybe<Order_By>;
  newPatientMinutesToPerform?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  taxRate?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Service_Stddev_Pop_Fields = {
  __typename?: 'service_stddev_pop_fields';
  cost?: Maybe<Scalars['Float']>;
  deposit?: Maybe<Scalars['Float']>;
  deviceBufferTime?: Maybe<Scalars['Float']>;
  minutesToPerform?: Maybe<Scalars['Float']>;
  newPatientMinutesToPerform?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  taxRate?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "service" */
export type Service_Stddev_Pop_Order_By = {
  cost?: InputMaybe<Order_By>;
  deposit?: InputMaybe<Order_By>;
  deviceBufferTime?: InputMaybe<Order_By>;
  minutesToPerform?: InputMaybe<Order_By>;
  newPatientMinutesToPerform?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  taxRate?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Service_Stddev_Samp_Fields = {
  __typename?: 'service_stddev_samp_fields';
  cost?: Maybe<Scalars['Float']>;
  deposit?: Maybe<Scalars['Float']>;
  deviceBufferTime?: Maybe<Scalars['Float']>;
  minutesToPerform?: Maybe<Scalars['Float']>;
  newPatientMinutesToPerform?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  taxRate?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "service" */
export type Service_Stddev_Samp_Order_By = {
  cost?: InputMaybe<Order_By>;
  deposit?: InputMaybe<Order_By>;
  deviceBufferTime?: InputMaybe<Order_By>;
  minutesToPerform?: InputMaybe<Order_By>;
  newPatientMinutesToPerform?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  taxRate?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "service" */
export type Service_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Service_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Service_Stream_Cursor_Value_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  allowGratuity?: InputMaybe<Scalars['Boolean']>;
  appointmentTypeId?: InputMaybe<Scalars['uuid']>;
  archived?: InputMaybe<Scalars['Boolean']>;
  availableInStore?: InputMaybe<Scalars['Boolean']>;
  canBeBookedOnline?: InputMaybe<Scalars['Boolean']>;
  color?: InputMaybe<Scalars['String']>;
  cost?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deposit?: InputMaybe<Scalars['Int']>;
  description?: InputMaybe<Scalars['String']>;
  deviceBufferTime?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  image?: InputMaybe<Scalars['String']>;
  importJobId?: InputMaybe<Scalars['uuid']>;
  isProduct?: InputMaybe<Scalars['Boolean']>;
  minutesToPerform?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  newPatientMinutesToPerform?: InputMaybe<Scalars['Int']>;
  onlineBookingRules?: InputMaybe<Scalars['jsonb']>;
  patientPortalInviteAddendum?: InputMaybe<Scalars['String']>;
  patientPortalInviteSMSAddendum?: InputMaybe<Scalars['String']>;
  postcareDocumentId?: InputMaybe<Scalars['uuid']>;
  precareDocumentId?: InputMaybe<Scalars['uuid']>;
  price?: InputMaybe<Scalars['Int']>;
  pricingModel?: InputMaybe<Scalars['String']>;
  productBarcodeId?: InputMaybe<Scalars['String']>;
  requiresDeposit?: InputMaybe<Scalars['Boolean']>;
  serviceCategoryId?: InputMaybe<Scalars['uuid']>;
  serviceUnitId?: InputMaybe<Scalars['uuid']>;
  taxRate?: InputMaybe<Scalars['float8']>;
  triggersPatientPortalInvite?: InputMaybe<Scalars['Boolean']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  viewOnlyInStore?: InputMaybe<Scalars['Boolean']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Service_Sum_Fields = {
  __typename?: 'service_sum_fields';
  cost?: Maybe<Scalars['Int']>;
  deposit?: Maybe<Scalars['Int']>;
  deviceBufferTime?: Maybe<Scalars['Int']>;
  minutesToPerform?: Maybe<Scalars['Int']>;
  newPatientMinutesToPerform?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['Int']>;
  taxRate?: Maybe<Scalars['float8']>;
};

/** order by sum() on columns of table "service" */
export type Service_Sum_Order_By = {
  cost?: InputMaybe<Order_By>;
  deposit?: InputMaybe<Order_By>;
  deviceBufferTime?: InputMaybe<Order_By>;
  minutesToPerform?: InputMaybe<Order_By>;
  newPatientMinutesToPerform?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  taxRate?: InputMaybe<Order_By>;
};

/** update columns of table "service" */
export enum Service_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  AllowGratuity = 'allowGratuity',
  /** column name */
  AppointmentTypeId = 'appointmentTypeId',
  /** column name */
  Archived = 'archived',
  /** column name */
  AvailableInStore = 'availableInStore',
  /** column name */
  CanBeBookedOnline = 'canBeBookedOnline',
  /** column name */
  Color = 'color',
  /** column name */
  Cost = 'cost',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Deposit = 'deposit',
  /** column name */
  Description = 'description',
  /** column name */
  DeviceBufferTime = 'deviceBufferTime',
  /** column name */
  Id = 'id',
  /** column name */
  Image = 'image',
  /** column name */
  ImportJobId = 'importJobId',
  /** column name */
  IsProduct = 'isProduct',
  /** column name */
  MinutesToPerform = 'minutesToPerform',
  /** column name */
  Name = 'name',
  /** column name */
  NewPatientMinutesToPerform = 'newPatientMinutesToPerform',
  /** column name */
  OnlineBookingRules = 'onlineBookingRules',
  /** column name */
  PatientPortalInviteAddendum = 'patientPortalInviteAddendum',
  /** column name */
  PatientPortalInviteSmsAddendum = 'patientPortalInviteSMSAddendum',
  /** column name */
  PostcareDocumentId = 'postcareDocumentId',
  /** column name */
  PrecareDocumentId = 'precareDocumentId',
  /** column name */
  Price = 'price',
  /** column name */
  PricingModel = 'pricingModel',
  /** column name */
  ProductBarcodeId = 'productBarcodeId',
  /** column name */
  RequiresDeposit = 'requiresDeposit',
  /** column name */
  ServiceCategoryId = 'serviceCategoryId',
  /** column name */
  ServiceUnitId = 'serviceUnitId',
  /** column name */
  TaxRate = 'taxRate',
  /** column name */
  TriggersPatientPortalInvite = 'triggersPatientPortalInvite',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  ViewOnlyInStore = 'viewOnlyInStore',
  /** column name */
  WorkspaceId = 'workspaceId'
}

export type Service_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Service_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Service_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Service_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Service_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Service_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Service_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Service_Set_Input>;
  /** filter the rows which have to be updated */
  where: Service_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Service_Var_Pop_Fields = {
  __typename?: 'service_var_pop_fields';
  cost?: Maybe<Scalars['Float']>;
  deposit?: Maybe<Scalars['Float']>;
  deviceBufferTime?: Maybe<Scalars['Float']>;
  minutesToPerform?: Maybe<Scalars['Float']>;
  newPatientMinutesToPerform?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  taxRate?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "service" */
export type Service_Var_Pop_Order_By = {
  cost?: InputMaybe<Order_By>;
  deposit?: InputMaybe<Order_By>;
  deviceBufferTime?: InputMaybe<Order_By>;
  minutesToPerform?: InputMaybe<Order_By>;
  newPatientMinutesToPerform?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  taxRate?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Service_Var_Samp_Fields = {
  __typename?: 'service_var_samp_fields';
  cost?: Maybe<Scalars['Float']>;
  deposit?: Maybe<Scalars['Float']>;
  deviceBufferTime?: Maybe<Scalars['Float']>;
  minutesToPerform?: Maybe<Scalars['Float']>;
  newPatientMinutesToPerform?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  taxRate?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "service" */
export type Service_Var_Samp_Order_By = {
  cost?: InputMaybe<Order_By>;
  deposit?: InputMaybe<Order_By>;
  deviceBufferTime?: InputMaybe<Order_By>;
  minutesToPerform?: InputMaybe<Order_By>;
  newPatientMinutesToPerform?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  taxRate?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Service_Variance_Fields = {
  __typename?: 'service_variance_fields';
  cost?: Maybe<Scalars['Float']>;
  deposit?: Maybe<Scalars['Float']>;
  deviceBufferTime?: Maybe<Scalars['Float']>;
  minutesToPerform?: Maybe<Scalars['Float']>;
  newPatientMinutesToPerform?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  taxRate?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "service" */
export type Service_Variance_Order_By = {
  cost?: InputMaybe<Order_By>;
  deposit?: InputMaybe<Order_By>;
  deviceBufferTime?: InputMaybe<Order_By>;
  minutesToPerform?: InputMaybe<Order_By>;
  newPatientMinutesToPerform?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  taxRate?: InputMaybe<Order_By>;
};

/** columns and relationships of "shrink" */
export type Shrink = {
  __typename?: 'shrink';
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  updatedAt: Scalars['timestamptz'];
  workspaceId: Scalars['uuid'];
};

/** aggregated selection of "shrink" */
export type Shrink_Aggregate = {
  __typename?: 'shrink_aggregate';
  aggregate?: Maybe<Shrink_Aggregate_Fields>;
  nodes: Array<Shrink>;
};

/** aggregate fields of "shrink" */
export type Shrink_Aggregate_Fields = {
  __typename?: 'shrink_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Shrink_Max_Fields>;
  min?: Maybe<Shrink_Min_Fields>;
};


/** aggregate fields of "shrink" */
export type Shrink_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Shrink_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "shrink". All fields are combined with a logical 'AND'. */
export type Shrink_Bool_Exp = {
  _and?: InputMaybe<Array<Shrink_Bool_Exp>>;
  _not?: InputMaybe<Shrink_Bool_Exp>;
  _or?: InputMaybe<Array<Shrink_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  workspaceId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "shrink" */
export enum Shrink_Constraint {
  /** unique or primary key constraint on columns "id" */
  ShrinkPkey = 'shrink_pkey',
  /** unique or primary key constraint on columns "workspaceId" */
  ShrinkWorkspaceIdKey = 'shrink_workspaceId_key'
}

/** input type for inserting data into table "shrink" */
export type Shrink_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Shrink_Max_Fields = {
  __typename?: 'shrink_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Shrink_Min_Fields = {
  __typename?: 'shrink_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "shrink" */
export type Shrink_Mutation_Response = {
  __typename?: 'shrink_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Shrink>;
};

/** on_conflict condition type for table "shrink" */
export type Shrink_On_Conflict = {
  constraint: Shrink_Constraint;
  update_columns?: Array<Shrink_Update_Column>;
  where?: InputMaybe<Shrink_Bool_Exp>;
};

/** Ordering options when selecting data from "shrink". */
export type Shrink_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: shrink */
export type Shrink_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "shrink" */
export enum Shrink_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WorkspaceId = 'workspaceId'
}

/** input type for updating data in table "shrink" */
export type Shrink_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "shrink" */
export type Shrink_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Shrink_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Shrink_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "shrink" */
export enum Shrink_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WorkspaceId = 'workspaceId'
}

export type Shrink_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Shrink_Set_Input>;
  /** filter the rows which have to be updated */
  where: Shrink_Bool_Exp;
};

/** columns and relationships of "signature" */
export type Signature = {
  __typename?: 'signature';
  createdAt: Scalars['timestamptz'];
  file?: Maybe<FileOnlyUrl>;
  filePath: Scalars['String'];
  id: Scalars['uuid'];
  patientId?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  provider?: Maybe<Provider>;
  providerId?: Maybe<Scalars['uuid']>;
  updatedAt: Scalars['timestamptz'];
  userId?: Maybe<Scalars['uuid']>;
  workspaceId: Scalars['uuid'];
};

/** aggregated selection of "signature" */
export type Signature_Aggregate = {
  __typename?: 'signature_aggregate';
  aggregate?: Maybe<Signature_Aggregate_Fields>;
  nodes: Array<Signature>;
};

/** aggregate fields of "signature" */
export type Signature_Aggregate_Fields = {
  __typename?: 'signature_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Signature_Max_Fields>;
  min?: Maybe<Signature_Min_Fields>;
};


/** aggregate fields of "signature" */
export type Signature_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Signature_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "signature". All fields are combined with a logical 'AND'. */
export type Signature_Bool_Exp = {
  _and?: InputMaybe<Array<Signature_Bool_Exp>>;
  _not?: InputMaybe<Signature_Bool_Exp>;
  _or?: InputMaybe<Array<Signature_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  filePath?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  patientId?: InputMaybe<Uuid_Comparison_Exp>;
  provider?: InputMaybe<Provider_Bool_Exp>;
  providerId?: InputMaybe<Uuid_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  userId?: InputMaybe<Uuid_Comparison_Exp>;
  workspaceId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "signature" */
export enum Signature_Constraint {
  /** unique or primary key constraint on columns "filePath" */
  SignatureFilePathKey = 'signature_filePath_key',
  /** unique or primary key constraint on columns "id" */
  SignaturePkey = 'signature_pkey'
}

/** input type for inserting data into table "signature" */
export type Signature_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  filePath?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  patientId?: InputMaybe<Scalars['uuid']>;
  provider?: InputMaybe<Provider_Obj_Rel_Insert_Input>;
  providerId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  userId?: InputMaybe<Scalars['uuid']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Signature_Max_Fields = {
  __typename?: 'signature_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  filePath?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  patientId?: Maybe<Scalars['uuid']>;
  providerId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['uuid']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Signature_Min_Fields = {
  __typename?: 'signature_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  filePath?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  patientId?: Maybe<Scalars['uuid']>;
  providerId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['uuid']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "signature" */
export type Signature_Mutation_Response = {
  __typename?: 'signature_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Signature>;
};

/** input type for inserting object relation for remote table "signature" */
export type Signature_Obj_Rel_Insert_Input = {
  data: Signature_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Signature_On_Conflict>;
};

/** on_conflict condition type for table "signature" */
export type Signature_On_Conflict = {
  constraint: Signature_Constraint;
  update_columns?: Array<Signature_Update_Column>;
  where?: InputMaybe<Signature_Bool_Exp>;
};

/** Ordering options when selecting data from "signature". */
export type Signature_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  filePath?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patientId?: InputMaybe<Order_By>;
  provider?: InputMaybe<Provider_Order_By>;
  providerId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: signature */
export type Signature_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "signature" */
export enum Signature_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  FilePath = 'filePath',
  /** column name */
  Id = 'id',
  /** column name */
  PatientId = 'patientId',
  /** column name */
  ProviderId = 'providerId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId',
  /** column name */
  WorkspaceId = 'workspaceId'
}

/** input type for updating data in table "signature" */
export type Signature_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  filePath?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  patientId?: InputMaybe<Scalars['uuid']>;
  providerId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  userId?: InputMaybe<Scalars['uuid']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "signature" */
export type Signature_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Signature_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Signature_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  filePath?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  patientId?: InputMaybe<Scalars['uuid']>;
  providerId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  userId?: InputMaybe<Scalars['uuid']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "signature" */
export enum Signature_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  FilePath = 'filePath',
  /** column name */
  Id = 'id',
  /** column name */
  PatientId = 'patientId',
  /** column name */
  ProviderId = 'providerId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId',
  /** column name */
  WorkspaceId = 'workspaceId'
}

export type Signature_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Signature_Set_Input>;
  /** filter the rows which have to be updated */
  where: Signature_Bool_Exp;
};

/** Boolean expression to compare columns of type "smallint". All fields are combined with logical 'AND'. */
export type Smallint_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['smallint']>;
  _gt?: InputMaybe<Scalars['smallint']>;
  _gte?: InputMaybe<Scalars['smallint']>;
  _in?: InputMaybe<Array<Scalars['smallint']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['smallint']>;
  _lte?: InputMaybe<Scalars['smallint']>;
  _neq?: InputMaybe<Scalars['smallint']>;
  _nin?: InputMaybe<Array<Scalars['smallint']>>;
};

/** columns and relationships of "smartNote" */
export type SmartNote = {
  __typename?: 'smartNote';
  callPhrase: Scalars['String'];
  content: Scalars['String'];
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  title: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  workspaceId: Scalars['uuid'];
};

/** aggregated selection of "smartNote" */
export type SmartNote_Aggregate = {
  __typename?: 'smartNote_aggregate';
  aggregate?: Maybe<SmartNote_Aggregate_Fields>;
  nodes: Array<SmartNote>;
};

/** aggregate fields of "smartNote" */
export type SmartNote_Aggregate_Fields = {
  __typename?: 'smartNote_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<SmartNote_Max_Fields>;
  min?: Maybe<SmartNote_Min_Fields>;
};


/** aggregate fields of "smartNote" */
export type SmartNote_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<SmartNote_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "smartNote". All fields are combined with a logical 'AND'. */
export type SmartNote_Bool_Exp = {
  _and?: InputMaybe<Array<SmartNote_Bool_Exp>>;
  _not?: InputMaybe<SmartNote_Bool_Exp>;
  _or?: InputMaybe<Array<SmartNote_Bool_Exp>>;
  callPhrase?: InputMaybe<String_Comparison_Exp>;
  content?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  workspaceId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "smartNote" */
export enum SmartNote_Constraint {
  /** unique or primary key constraint on columns "workspaceId", "callPhrase" */
  SmartNoteCallPhraseWorkspaceIdKey = 'smartNote_callPhrase_workspaceId_key',
  /** unique or primary key constraint on columns "id" */
  SmartNotePkey = 'smartNote_pkey',
  /** unique or primary key constraint on columns "workspaceId", "title" */
  SmartNoteTitleWorkspaceIdKey = 'smartNote_title_workspaceId_key'
}

/** input type for inserting data into table "smartNote" */
export type SmartNote_Insert_Input = {
  callPhrase?: InputMaybe<Scalars['String']>;
  content?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type SmartNote_Max_Fields = {
  __typename?: 'smartNote_max_fields';
  callPhrase?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type SmartNote_Min_Fields = {
  __typename?: 'smartNote_min_fields';
  callPhrase?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "smartNote" */
export type SmartNote_Mutation_Response = {
  __typename?: 'smartNote_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<SmartNote>;
};

/** on_conflict condition type for table "smartNote" */
export type SmartNote_On_Conflict = {
  constraint: SmartNote_Constraint;
  update_columns?: Array<SmartNote_Update_Column>;
  where?: InputMaybe<SmartNote_Bool_Exp>;
};

/** Ordering options when selecting data from "smartNote". */
export type SmartNote_Order_By = {
  callPhrase?: InputMaybe<Order_By>;
  content?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: smartNote */
export type SmartNote_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "smartNote" */
export enum SmartNote_Select_Column {
  /** column name */
  CallPhrase = 'callPhrase',
  /** column name */
  Content = 'content',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WorkspaceId = 'workspaceId'
}

/** input type for updating data in table "smartNote" */
export type SmartNote_Set_Input = {
  callPhrase?: InputMaybe<Scalars['String']>;
  content?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "smartNote" */
export type SmartNote_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: SmartNote_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type SmartNote_Stream_Cursor_Value_Input = {
  callPhrase?: InputMaybe<Scalars['String']>;
  content?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "smartNote" */
export enum SmartNote_Update_Column {
  /** column name */
  CallPhrase = 'callPhrase',
  /** column name */
  Content = 'content',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WorkspaceId = 'workspaceId'
}

export type SmartNote_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<SmartNote_Set_Input>;
  /** filter the rows which have to be updated */
  where: SmartNote_Bool_Exp;
};

/** columns and relationships of "smsTemplate" */
export type SmsTemplate = {
  __typename?: 'smsTemplate';
  active: Scalars['Boolean'];
  allowResend: Scalars['Boolean'];
  /** An object relationship */
  audience?: Maybe<Audience>;
  deliveries?: Maybe<Scalars['Int']>;
  errors?: Maybe<Scalars['Int']>;
  id: Scalars['uuid'];
  inngestJobId?: Maybe<Scalars['String']>;
  isArchived: Scalars['Boolean'];
  jobId?: Maybe<Scalars['uuid']>;
  lastSent?: Maybe<Scalars['timestamptz']>;
  /** An array relationship */
  marketingTags: Array<MarketingTagSms>;
  /** An aggregate relationship */
  marketingTags_aggregate: MarketingTagSms_Aggregate;
  mmsImage?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  resendWindow?: Maybe<Scalars['Int']>;
  sendDateTime?: Maybe<Scalars['timestamptz']>;
  smsBody: Scalars['String'];
  /** A computed field, executes function "compute_sms_template_state" */
  state?: Maybe<Scalars['String']>;
  targetAudienceId?: Maybe<Scalars['uuid']>;
  updatedAt: Scalars['timestamptz'];
  workspaceId: Scalars['uuid'];
};


/** columns and relationships of "smsTemplate" */
export type SmsTemplateMarketingTagsArgs = {
  distinct_on?: InputMaybe<Array<MarketingTagSms_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MarketingTagSms_Order_By>>;
  where?: InputMaybe<MarketingTagSms_Bool_Exp>;
};


/** columns and relationships of "smsTemplate" */
export type SmsTemplateMarketingTags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<MarketingTagSms_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MarketingTagSms_Order_By>>;
  where?: InputMaybe<MarketingTagSms_Bool_Exp>;
};

/** columns and relationships of "smsTemplatePatient" */
export type SmsTemplatePatient = {
  __typename?: 'smsTemplatePatient';
  id: Scalars['uuid'];
  patientWorkspaceId: Scalars['uuid'];
  sendDate: Scalars['timestamptz'];
  /** An object relationship */
  smsTemplate: SmsTemplate;
  smsTemplateId: Scalars['uuid'];
  workspaceId: Scalars['uuid'];
};

/** aggregated selection of "smsTemplatePatient" */
export type SmsTemplatePatient_Aggregate = {
  __typename?: 'smsTemplatePatient_aggregate';
  aggregate?: Maybe<SmsTemplatePatient_Aggregate_Fields>;
  nodes: Array<SmsTemplatePatient>;
};

/** aggregate fields of "smsTemplatePatient" */
export type SmsTemplatePatient_Aggregate_Fields = {
  __typename?: 'smsTemplatePatient_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<SmsTemplatePatient_Max_Fields>;
  min?: Maybe<SmsTemplatePatient_Min_Fields>;
};


/** aggregate fields of "smsTemplatePatient" */
export type SmsTemplatePatient_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<SmsTemplatePatient_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "smsTemplatePatient". All fields are combined with a logical 'AND'. */
export type SmsTemplatePatient_Bool_Exp = {
  _and?: InputMaybe<Array<SmsTemplatePatient_Bool_Exp>>;
  _not?: InputMaybe<SmsTemplatePatient_Bool_Exp>;
  _or?: InputMaybe<Array<SmsTemplatePatient_Bool_Exp>>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  patientWorkspaceId?: InputMaybe<Uuid_Comparison_Exp>;
  sendDate?: InputMaybe<Timestamptz_Comparison_Exp>;
  smsTemplate?: InputMaybe<SmsTemplate_Bool_Exp>;
  smsTemplateId?: InputMaybe<Uuid_Comparison_Exp>;
  workspaceId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "smsTemplatePatient" */
export enum SmsTemplatePatient_Constraint {
  /** unique or primary key constraint on columns "id" */
  SmsTemplatePatientPkey = 'smsTemplatePatient_pkey'
}

/** input type for inserting data into table "smsTemplatePatient" */
export type SmsTemplatePatient_Insert_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  patientWorkspaceId?: InputMaybe<Scalars['uuid']>;
  sendDate?: InputMaybe<Scalars['timestamptz']>;
  smsTemplate?: InputMaybe<SmsTemplate_Obj_Rel_Insert_Input>;
  smsTemplateId?: InputMaybe<Scalars['uuid']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type SmsTemplatePatient_Max_Fields = {
  __typename?: 'smsTemplatePatient_max_fields';
  id?: Maybe<Scalars['uuid']>;
  patientWorkspaceId?: Maybe<Scalars['uuid']>;
  sendDate?: Maybe<Scalars['timestamptz']>;
  smsTemplateId?: Maybe<Scalars['uuid']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type SmsTemplatePatient_Min_Fields = {
  __typename?: 'smsTemplatePatient_min_fields';
  id?: Maybe<Scalars['uuid']>;
  patientWorkspaceId?: Maybe<Scalars['uuid']>;
  sendDate?: Maybe<Scalars['timestamptz']>;
  smsTemplateId?: Maybe<Scalars['uuid']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "smsTemplatePatient" */
export type SmsTemplatePatient_Mutation_Response = {
  __typename?: 'smsTemplatePatient_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<SmsTemplatePatient>;
};

/** on_conflict condition type for table "smsTemplatePatient" */
export type SmsTemplatePatient_On_Conflict = {
  constraint: SmsTemplatePatient_Constraint;
  update_columns?: Array<SmsTemplatePatient_Update_Column>;
  where?: InputMaybe<SmsTemplatePatient_Bool_Exp>;
};

/** Ordering options when selecting data from "smsTemplatePatient". */
export type SmsTemplatePatient_Order_By = {
  id?: InputMaybe<Order_By>;
  patientWorkspaceId?: InputMaybe<Order_By>;
  sendDate?: InputMaybe<Order_By>;
  smsTemplate?: InputMaybe<SmsTemplate_Order_By>;
  smsTemplateId?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: smsTemplatePatient */
export type SmsTemplatePatient_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "smsTemplatePatient" */
export enum SmsTemplatePatient_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  PatientWorkspaceId = 'patientWorkspaceId',
  /** column name */
  SendDate = 'sendDate',
  /** column name */
  SmsTemplateId = 'smsTemplateId',
  /** column name */
  WorkspaceId = 'workspaceId'
}

/** input type for updating data in table "smsTemplatePatient" */
export type SmsTemplatePatient_Set_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  patientWorkspaceId?: InputMaybe<Scalars['uuid']>;
  sendDate?: InputMaybe<Scalars['timestamptz']>;
  smsTemplateId?: InputMaybe<Scalars['uuid']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "smsTemplatePatient" */
export type SmsTemplatePatient_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: SmsTemplatePatient_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type SmsTemplatePatient_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  patientWorkspaceId?: InputMaybe<Scalars['uuid']>;
  sendDate?: InputMaybe<Scalars['timestamptz']>;
  smsTemplateId?: InputMaybe<Scalars['uuid']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "smsTemplatePatient" */
export enum SmsTemplatePatient_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  PatientWorkspaceId = 'patientWorkspaceId',
  /** column name */
  SendDate = 'sendDate',
  /** column name */
  SmsTemplateId = 'smsTemplateId',
  /** column name */
  WorkspaceId = 'workspaceId'
}

export type SmsTemplatePatient_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<SmsTemplatePatient_Set_Input>;
  /** filter the rows which have to be updated */
  where: SmsTemplatePatient_Bool_Exp;
};

/** columns and relationships of "smsTemplateSendQueue" */
export type SmsTemplateSendQueue = {
  __typename?: 'smsTemplateSendQueue';
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  patientWorkspaceId: Scalars['uuid'];
  sendDate?: Maybe<Scalars['timestamptz']>;
  smsTemplateId: Scalars['uuid'];
};

/** aggregated selection of "smsTemplateSendQueue" */
export type SmsTemplateSendQueue_Aggregate = {
  __typename?: 'smsTemplateSendQueue_aggregate';
  aggregate?: Maybe<SmsTemplateSendQueue_Aggregate_Fields>;
  nodes: Array<SmsTemplateSendQueue>;
};

/** aggregate fields of "smsTemplateSendQueue" */
export type SmsTemplateSendQueue_Aggregate_Fields = {
  __typename?: 'smsTemplateSendQueue_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<SmsTemplateSendQueue_Max_Fields>;
  min?: Maybe<SmsTemplateSendQueue_Min_Fields>;
};


/** aggregate fields of "smsTemplateSendQueue" */
export type SmsTemplateSendQueue_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<SmsTemplateSendQueue_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "smsTemplateSendQueue". All fields are combined with a logical 'AND'. */
export type SmsTemplateSendQueue_Bool_Exp = {
  _and?: InputMaybe<Array<SmsTemplateSendQueue_Bool_Exp>>;
  _not?: InputMaybe<SmsTemplateSendQueue_Bool_Exp>;
  _or?: InputMaybe<Array<SmsTemplateSendQueue_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  patientWorkspaceId?: InputMaybe<Uuid_Comparison_Exp>;
  sendDate?: InputMaybe<Timestamptz_Comparison_Exp>;
  smsTemplateId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "smsTemplateSendQueue" */
export enum SmsTemplateSendQueue_Constraint {
  /** unique or primary key constraint on columns "id" */
  SmsTemplateSendQueuePkey = 'smsTemplateSendQueue_pkey'
}

/** input type for inserting data into table "smsTemplateSendQueue" */
export type SmsTemplateSendQueue_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  patientWorkspaceId?: InputMaybe<Scalars['uuid']>;
  sendDate?: InputMaybe<Scalars['timestamptz']>;
  smsTemplateId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type SmsTemplateSendQueue_Max_Fields = {
  __typename?: 'smsTemplateSendQueue_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  patientWorkspaceId?: Maybe<Scalars['uuid']>;
  sendDate?: Maybe<Scalars['timestamptz']>;
  smsTemplateId?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type SmsTemplateSendQueue_Min_Fields = {
  __typename?: 'smsTemplateSendQueue_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  patientWorkspaceId?: Maybe<Scalars['uuid']>;
  sendDate?: Maybe<Scalars['timestamptz']>;
  smsTemplateId?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "smsTemplateSendQueue" */
export type SmsTemplateSendQueue_Mutation_Response = {
  __typename?: 'smsTemplateSendQueue_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<SmsTemplateSendQueue>;
};

/** on_conflict condition type for table "smsTemplateSendQueue" */
export type SmsTemplateSendQueue_On_Conflict = {
  constraint: SmsTemplateSendQueue_Constraint;
  update_columns?: Array<SmsTemplateSendQueue_Update_Column>;
  where?: InputMaybe<SmsTemplateSendQueue_Bool_Exp>;
};

/** Ordering options when selecting data from "smsTemplateSendQueue". */
export type SmsTemplateSendQueue_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patientWorkspaceId?: InputMaybe<Order_By>;
  sendDate?: InputMaybe<Order_By>;
  smsTemplateId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: smsTemplateSendQueue */
export type SmsTemplateSendQueue_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "smsTemplateSendQueue" */
export enum SmsTemplateSendQueue_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  PatientWorkspaceId = 'patientWorkspaceId',
  /** column name */
  SendDate = 'sendDate',
  /** column name */
  SmsTemplateId = 'smsTemplateId'
}

/** input type for updating data in table "smsTemplateSendQueue" */
export type SmsTemplateSendQueue_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  patientWorkspaceId?: InputMaybe<Scalars['uuid']>;
  sendDate?: InputMaybe<Scalars['timestamptz']>;
  smsTemplateId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "smsTemplateSendQueue" */
export type SmsTemplateSendQueue_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: SmsTemplateSendQueue_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type SmsTemplateSendQueue_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  patientWorkspaceId?: InputMaybe<Scalars['uuid']>;
  sendDate?: InputMaybe<Scalars['timestamptz']>;
  smsTemplateId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "smsTemplateSendQueue" */
export enum SmsTemplateSendQueue_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  PatientWorkspaceId = 'patientWorkspaceId',
  /** column name */
  SendDate = 'sendDate',
  /** column name */
  SmsTemplateId = 'smsTemplateId'
}

export type SmsTemplateSendQueue_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<SmsTemplateSendQueue_Set_Input>;
  /** filter the rows which have to be updated */
  where: SmsTemplateSendQueue_Bool_Exp;
};

/** aggregated selection of "smsTemplate" */
export type SmsTemplate_Aggregate = {
  __typename?: 'smsTemplate_aggregate';
  aggregate?: Maybe<SmsTemplate_Aggregate_Fields>;
  nodes: Array<SmsTemplate>;
};

/** aggregate fields of "smsTemplate" */
export type SmsTemplate_Aggregate_Fields = {
  __typename?: 'smsTemplate_aggregate_fields';
  avg?: Maybe<SmsTemplate_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<SmsTemplate_Max_Fields>;
  min?: Maybe<SmsTemplate_Min_Fields>;
  stddev?: Maybe<SmsTemplate_Stddev_Fields>;
  stddev_pop?: Maybe<SmsTemplate_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<SmsTemplate_Stddev_Samp_Fields>;
  sum?: Maybe<SmsTemplate_Sum_Fields>;
  var_pop?: Maybe<SmsTemplate_Var_Pop_Fields>;
  var_samp?: Maybe<SmsTemplate_Var_Samp_Fields>;
  variance?: Maybe<SmsTemplate_Variance_Fields>;
};


/** aggregate fields of "smsTemplate" */
export type SmsTemplate_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<SmsTemplate_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type SmsTemplate_Avg_Fields = {
  __typename?: 'smsTemplate_avg_fields';
  deliveries?: Maybe<Scalars['Float']>;
  errors?: Maybe<Scalars['Float']>;
  resendWindow?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "smsTemplate". All fields are combined with a logical 'AND'. */
export type SmsTemplate_Bool_Exp = {
  _and?: InputMaybe<Array<SmsTemplate_Bool_Exp>>;
  _not?: InputMaybe<SmsTemplate_Bool_Exp>;
  _or?: InputMaybe<Array<SmsTemplate_Bool_Exp>>;
  active?: InputMaybe<Boolean_Comparison_Exp>;
  allowResend?: InputMaybe<Boolean_Comparison_Exp>;
  audience?: InputMaybe<Audience_Bool_Exp>;
  deliveries?: InputMaybe<Int_Comparison_Exp>;
  errors?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  inngestJobId?: InputMaybe<String_Comparison_Exp>;
  isArchived?: InputMaybe<Boolean_Comparison_Exp>;
  jobId?: InputMaybe<Uuid_Comparison_Exp>;
  lastSent?: InputMaybe<Timestamptz_Comparison_Exp>;
  marketingTags?: InputMaybe<MarketingTagSms_Bool_Exp>;
  marketingTags_aggregate?: InputMaybe<MarketingTagSms_Aggregate_Bool_Exp>;
  mmsImage?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  resendWindow?: InputMaybe<Int_Comparison_Exp>;
  sendDateTime?: InputMaybe<Timestamptz_Comparison_Exp>;
  smsBody?: InputMaybe<String_Comparison_Exp>;
  state?: InputMaybe<String_Comparison_Exp>;
  targetAudienceId?: InputMaybe<Uuid_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  workspaceId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "smsTemplate" */
export enum SmsTemplate_Constraint {
  /** unique or primary key constraint on columns "id" */
  SmsTemplatePkey = 'smsTemplate_pkey'
}

/** input type for incrementing numeric columns in table "smsTemplate" */
export type SmsTemplate_Inc_Input = {
  deliveries?: InputMaybe<Scalars['Int']>;
  errors?: InputMaybe<Scalars['Int']>;
  resendWindow?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "smsTemplate" */
export type SmsTemplate_Insert_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  allowResend?: InputMaybe<Scalars['Boolean']>;
  audience?: InputMaybe<Audience_Obj_Rel_Insert_Input>;
  deliveries?: InputMaybe<Scalars['Int']>;
  errors?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  inngestJobId?: InputMaybe<Scalars['String']>;
  isArchived?: InputMaybe<Scalars['Boolean']>;
  jobId?: InputMaybe<Scalars['uuid']>;
  lastSent?: InputMaybe<Scalars['timestamptz']>;
  marketingTags?: InputMaybe<MarketingTagSms_Arr_Rel_Insert_Input>;
  mmsImage?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  resendWindow?: InputMaybe<Scalars['Int']>;
  sendDateTime?: InputMaybe<Scalars['timestamptz']>;
  smsBody?: InputMaybe<Scalars['String']>;
  targetAudienceId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type SmsTemplate_Max_Fields = {
  __typename?: 'smsTemplate_max_fields';
  deliveries?: Maybe<Scalars['Int']>;
  errors?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  inngestJobId?: Maybe<Scalars['String']>;
  jobId?: Maybe<Scalars['uuid']>;
  lastSent?: Maybe<Scalars['timestamptz']>;
  mmsImage?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  resendWindow?: Maybe<Scalars['Int']>;
  sendDateTime?: Maybe<Scalars['timestamptz']>;
  smsBody?: Maybe<Scalars['String']>;
  /** A computed field, executes function "compute_sms_template_state" */
  state?: Maybe<Scalars['String']>;
  targetAudienceId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type SmsTemplate_Min_Fields = {
  __typename?: 'smsTemplate_min_fields';
  deliveries?: Maybe<Scalars['Int']>;
  errors?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  inngestJobId?: Maybe<Scalars['String']>;
  jobId?: Maybe<Scalars['uuid']>;
  lastSent?: Maybe<Scalars['timestamptz']>;
  mmsImage?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  resendWindow?: Maybe<Scalars['Int']>;
  sendDateTime?: Maybe<Scalars['timestamptz']>;
  smsBody?: Maybe<Scalars['String']>;
  /** A computed field, executes function "compute_sms_template_state" */
  state?: Maybe<Scalars['String']>;
  targetAudienceId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "smsTemplate" */
export type SmsTemplate_Mutation_Response = {
  __typename?: 'smsTemplate_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<SmsTemplate>;
};

/** input type for inserting object relation for remote table "smsTemplate" */
export type SmsTemplate_Obj_Rel_Insert_Input = {
  data: SmsTemplate_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<SmsTemplate_On_Conflict>;
};

/** on_conflict condition type for table "smsTemplate" */
export type SmsTemplate_On_Conflict = {
  constraint: SmsTemplate_Constraint;
  update_columns?: Array<SmsTemplate_Update_Column>;
  where?: InputMaybe<SmsTemplate_Bool_Exp>;
};

/** Ordering options when selecting data from "smsTemplate". */
export type SmsTemplate_Order_By = {
  active?: InputMaybe<Order_By>;
  allowResend?: InputMaybe<Order_By>;
  audience?: InputMaybe<Audience_Order_By>;
  deliveries?: InputMaybe<Order_By>;
  errors?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  inngestJobId?: InputMaybe<Order_By>;
  isArchived?: InputMaybe<Order_By>;
  jobId?: InputMaybe<Order_By>;
  lastSent?: InputMaybe<Order_By>;
  marketingTags_aggregate?: InputMaybe<MarketingTagSms_Aggregate_Order_By>;
  mmsImage?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  resendWindow?: InputMaybe<Order_By>;
  sendDateTime?: InputMaybe<Order_By>;
  smsBody?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  targetAudienceId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: smsTemplate */
export type SmsTemplate_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "smsTemplate" */
export enum SmsTemplate_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  AllowResend = 'allowResend',
  /** column name */
  Deliveries = 'deliveries',
  /** column name */
  Errors = 'errors',
  /** column name */
  Id = 'id',
  /** column name */
  InngestJobId = 'inngestJobId',
  /** column name */
  IsArchived = 'isArchived',
  /** column name */
  JobId = 'jobId',
  /** column name */
  LastSent = 'lastSent',
  /** column name */
  MmsImage = 'mmsImage',
  /** column name */
  Name = 'name',
  /** column name */
  ResendWindow = 'resendWindow',
  /** column name */
  SendDateTime = 'sendDateTime',
  /** column name */
  SmsBody = 'smsBody',
  /** column name */
  TargetAudienceId = 'targetAudienceId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WorkspaceId = 'workspaceId'
}

/** input type for updating data in table "smsTemplate" */
export type SmsTemplate_Set_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  allowResend?: InputMaybe<Scalars['Boolean']>;
  deliveries?: InputMaybe<Scalars['Int']>;
  errors?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  inngestJobId?: InputMaybe<Scalars['String']>;
  isArchived?: InputMaybe<Scalars['Boolean']>;
  jobId?: InputMaybe<Scalars['uuid']>;
  lastSent?: InputMaybe<Scalars['timestamptz']>;
  mmsImage?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  resendWindow?: InputMaybe<Scalars['Int']>;
  sendDateTime?: InputMaybe<Scalars['timestamptz']>;
  smsBody?: InputMaybe<Scalars['String']>;
  targetAudienceId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type SmsTemplate_Stddev_Fields = {
  __typename?: 'smsTemplate_stddev_fields';
  deliveries?: Maybe<Scalars['Float']>;
  errors?: Maybe<Scalars['Float']>;
  resendWindow?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type SmsTemplate_Stddev_Pop_Fields = {
  __typename?: 'smsTemplate_stddev_pop_fields';
  deliveries?: Maybe<Scalars['Float']>;
  errors?: Maybe<Scalars['Float']>;
  resendWindow?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type SmsTemplate_Stddev_Samp_Fields = {
  __typename?: 'smsTemplate_stddev_samp_fields';
  deliveries?: Maybe<Scalars['Float']>;
  errors?: Maybe<Scalars['Float']>;
  resendWindow?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "smsTemplate" */
export type SmsTemplate_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: SmsTemplate_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type SmsTemplate_Stream_Cursor_Value_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  allowResend?: InputMaybe<Scalars['Boolean']>;
  deliveries?: InputMaybe<Scalars['Int']>;
  errors?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  inngestJobId?: InputMaybe<Scalars['String']>;
  isArchived?: InputMaybe<Scalars['Boolean']>;
  jobId?: InputMaybe<Scalars['uuid']>;
  lastSent?: InputMaybe<Scalars['timestamptz']>;
  mmsImage?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  resendWindow?: InputMaybe<Scalars['Int']>;
  sendDateTime?: InputMaybe<Scalars['timestamptz']>;
  smsBody?: InputMaybe<Scalars['String']>;
  targetAudienceId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type SmsTemplate_Sum_Fields = {
  __typename?: 'smsTemplate_sum_fields';
  deliveries?: Maybe<Scalars['Int']>;
  errors?: Maybe<Scalars['Int']>;
  resendWindow?: Maybe<Scalars['Int']>;
};

/** update columns of table "smsTemplate" */
export enum SmsTemplate_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  AllowResend = 'allowResend',
  /** column name */
  Deliveries = 'deliveries',
  /** column name */
  Errors = 'errors',
  /** column name */
  Id = 'id',
  /** column name */
  InngestJobId = 'inngestJobId',
  /** column name */
  IsArchived = 'isArchived',
  /** column name */
  JobId = 'jobId',
  /** column name */
  LastSent = 'lastSent',
  /** column name */
  MmsImage = 'mmsImage',
  /** column name */
  Name = 'name',
  /** column name */
  ResendWindow = 'resendWindow',
  /** column name */
  SendDateTime = 'sendDateTime',
  /** column name */
  SmsBody = 'smsBody',
  /** column name */
  TargetAudienceId = 'targetAudienceId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WorkspaceId = 'workspaceId'
}

export type SmsTemplate_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<SmsTemplate_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<SmsTemplate_Set_Input>;
  /** filter the rows which have to be updated */
  where: SmsTemplate_Bool_Exp;
};

/** aggregate var_pop on columns */
export type SmsTemplate_Var_Pop_Fields = {
  __typename?: 'smsTemplate_var_pop_fields';
  deliveries?: Maybe<Scalars['Float']>;
  errors?: Maybe<Scalars['Float']>;
  resendWindow?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type SmsTemplate_Var_Samp_Fields = {
  __typename?: 'smsTemplate_var_samp_fields';
  deliveries?: Maybe<Scalars['Float']>;
  errors?: Maybe<Scalars['Float']>;
  resendWindow?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type SmsTemplate_Variance_Fields = {
  __typename?: 'smsTemplate_variance_fields';
  deliveries?: Maybe<Scalars['Float']>;
  errors?: Maybe<Scalars['Float']>;
  resendWindow?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "socialMediaAccount" */
export type SocialMediaAccount = {
  __typename?: 'socialMediaAccount';
  /** An object relationship */
  authProfile: AuthProfile;
  authProfileId: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  externalId: Scalars['String'];
  id: Scalars['uuid'];
  name: Scalars['String'];
  platform: SocialMediaPlatform_Enum;
  profilePicture?: Maybe<Scalars['String']>;
  /** An array relationship */
  socialMediaAccountPosts: Array<SocialMediaAccountPost>;
  /** An aggregate relationship */
  socialMediaAccountPosts_aggregate: SocialMediaAccountPost_Aggregate;
  /** An object relationship */
  socialMediaPlatform: SocialMediaPlatform;
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user: User;
  userId: Scalars['uuid'];
  /** An object relationship */
  workspace: Workspace;
  workspaceId: Scalars['uuid'];
};


/** columns and relationships of "socialMediaAccount" */
export type SocialMediaAccountSocialMediaAccountPostsArgs = {
  distinct_on?: InputMaybe<Array<SocialMediaAccountPost_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SocialMediaAccountPost_Order_By>>;
  where?: InputMaybe<SocialMediaAccountPost_Bool_Exp>;
};


/** columns and relationships of "socialMediaAccount" */
export type SocialMediaAccountSocialMediaAccountPosts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<SocialMediaAccountPost_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SocialMediaAccountPost_Order_By>>;
  where?: InputMaybe<SocialMediaAccountPost_Bool_Exp>;
};

/** columns and relationships of "socialMediaAccountPost" */
export type SocialMediaAccountPost = {
  __typename?: 'socialMediaAccountPost';
  commentCount?: Maybe<Scalars['Int']>;
  created_at: Scalars['timestamptz'];
  externalId?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  /** An object relationship */
  job?: Maybe<Job>;
  jobId?: Maybe<Scalars['uuid']>;
  likeCount?: Maybe<Scalars['Int']>;
  /** An object relationship */
  postStatus: PostStatus;
  postedAt?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  socialMediaAccount: SocialMediaAccount;
  socialMediaAccountId: Scalars['uuid'];
  /** An object relationship */
  socialMediaPost: SocialMediaPost;
  socialMediaPostId: Scalars['uuid'];
  status: PostStatus_Enum;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "socialMediaAccountPost" */
export type SocialMediaAccountPost_Aggregate = {
  __typename?: 'socialMediaAccountPost_aggregate';
  aggregate?: Maybe<SocialMediaAccountPost_Aggregate_Fields>;
  nodes: Array<SocialMediaAccountPost>;
};

export type SocialMediaAccountPost_Aggregate_Bool_Exp = {
  count?: InputMaybe<SocialMediaAccountPost_Aggregate_Bool_Exp_Count>;
};

export type SocialMediaAccountPost_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<SocialMediaAccountPost_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<SocialMediaAccountPost_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "socialMediaAccountPost" */
export type SocialMediaAccountPost_Aggregate_Fields = {
  __typename?: 'socialMediaAccountPost_aggregate_fields';
  avg?: Maybe<SocialMediaAccountPost_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<SocialMediaAccountPost_Max_Fields>;
  min?: Maybe<SocialMediaAccountPost_Min_Fields>;
  stddev?: Maybe<SocialMediaAccountPost_Stddev_Fields>;
  stddev_pop?: Maybe<SocialMediaAccountPost_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<SocialMediaAccountPost_Stddev_Samp_Fields>;
  sum?: Maybe<SocialMediaAccountPost_Sum_Fields>;
  var_pop?: Maybe<SocialMediaAccountPost_Var_Pop_Fields>;
  var_samp?: Maybe<SocialMediaAccountPost_Var_Samp_Fields>;
  variance?: Maybe<SocialMediaAccountPost_Variance_Fields>;
};


/** aggregate fields of "socialMediaAccountPost" */
export type SocialMediaAccountPost_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<SocialMediaAccountPost_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "socialMediaAccountPost" */
export type SocialMediaAccountPost_Aggregate_Order_By = {
  avg?: InputMaybe<SocialMediaAccountPost_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<SocialMediaAccountPost_Max_Order_By>;
  min?: InputMaybe<SocialMediaAccountPost_Min_Order_By>;
  stddev?: InputMaybe<SocialMediaAccountPost_Stddev_Order_By>;
  stddev_pop?: InputMaybe<SocialMediaAccountPost_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<SocialMediaAccountPost_Stddev_Samp_Order_By>;
  sum?: InputMaybe<SocialMediaAccountPost_Sum_Order_By>;
  var_pop?: InputMaybe<SocialMediaAccountPost_Var_Pop_Order_By>;
  var_samp?: InputMaybe<SocialMediaAccountPost_Var_Samp_Order_By>;
  variance?: InputMaybe<SocialMediaAccountPost_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "socialMediaAccountPost" */
export type SocialMediaAccountPost_Arr_Rel_Insert_Input = {
  data: Array<SocialMediaAccountPost_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<SocialMediaAccountPost_On_Conflict>;
};

/** aggregate avg on columns */
export type SocialMediaAccountPost_Avg_Fields = {
  __typename?: 'socialMediaAccountPost_avg_fields';
  commentCount?: Maybe<Scalars['Float']>;
  likeCount?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "socialMediaAccountPost" */
export type SocialMediaAccountPost_Avg_Order_By = {
  commentCount?: InputMaybe<Order_By>;
  likeCount?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "socialMediaAccountPost". All fields are combined with a logical 'AND'. */
export type SocialMediaAccountPost_Bool_Exp = {
  _and?: InputMaybe<Array<SocialMediaAccountPost_Bool_Exp>>;
  _not?: InputMaybe<SocialMediaAccountPost_Bool_Exp>;
  _or?: InputMaybe<Array<SocialMediaAccountPost_Bool_Exp>>;
  commentCount?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  externalId?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  job?: InputMaybe<Job_Bool_Exp>;
  jobId?: InputMaybe<Uuid_Comparison_Exp>;
  likeCount?: InputMaybe<Int_Comparison_Exp>;
  postStatus?: InputMaybe<PostStatus_Bool_Exp>;
  postedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  socialMediaAccount?: InputMaybe<SocialMediaAccount_Bool_Exp>;
  socialMediaAccountId?: InputMaybe<Uuid_Comparison_Exp>;
  socialMediaPost?: InputMaybe<SocialMediaPost_Bool_Exp>;
  socialMediaPostId?: InputMaybe<Uuid_Comparison_Exp>;
  status?: InputMaybe<PostStatus_Enum_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "socialMediaAccountPost" */
export enum SocialMediaAccountPost_Constraint {
  /** unique or primary key constraint on columns "socialMediaAccountId", "socialMediaPostId" */
  SocialMediaPostPlatformSocialMediaPostIdSocialMediaAccountId = 'socialMediaPostPlatform_socialMediaPostId_socialMediaAccountId_',
  /** unique or primary key constraint on columns "id" */
  SocialMediaPostSocialMediaPlatformPkey = 'socialMediaPostSocialMediaPlatform_pkey'
}

/** input type for incrementing numeric columns in table "socialMediaAccountPost" */
export type SocialMediaAccountPost_Inc_Input = {
  commentCount?: InputMaybe<Scalars['Int']>;
  likeCount?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "socialMediaAccountPost" */
export type SocialMediaAccountPost_Insert_Input = {
  commentCount?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  externalId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  job?: InputMaybe<Job_Obj_Rel_Insert_Input>;
  jobId?: InputMaybe<Scalars['uuid']>;
  likeCount?: InputMaybe<Scalars['Int']>;
  postStatus?: InputMaybe<PostStatus_Obj_Rel_Insert_Input>;
  postedAt?: InputMaybe<Scalars['timestamptz']>;
  socialMediaAccount?: InputMaybe<SocialMediaAccount_Obj_Rel_Insert_Input>;
  socialMediaAccountId?: InputMaybe<Scalars['uuid']>;
  socialMediaPost?: InputMaybe<SocialMediaPost_Obj_Rel_Insert_Input>;
  socialMediaPostId?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<PostStatus_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type SocialMediaAccountPost_Max_Fields = {
  __typename?: 'socialMediaAccountPost_max_fields';
  commentCount?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  externalId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  jobId?: Maybe<Scalars['uuid']>;
  likeCount?: Maybe<Scalars['Int']>;
  postedAt?: Maybe<Scalars['timestamptz']>;
  socialMediaAccountId?: Maybe<Scalars['uuid']>;
  socialMediaPostId?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "socialMediaAccountPost" */
export type SocialMediaAccountPost_Max_Order_By = {
  commentCount?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  externalId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  jobId?: InputMaybe<Order_By>;
  likeCount?: InputMaybe<Order_By>;
  postedAt?: InputMaybe<Order_By>;
  socialMediaAccountId?: InputMaybe<Order_By>;
  socialMediaPostId?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type SocialMediaAccountPost_Min_Fields = {
  __typename?: 'socialMediaAccountPost_min_fields';
  commentCount?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  externalId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  jobId?: Maybe<Scalars['uuid']>;
  likeCount?: Maybe<Scalars['Int']>;
  postedAt?: Maybe<Scalars['timestamptz']>;
  socialMediaAccountId?: Maybe<Scalars['uuid']>;
  socialMediaPostId?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "socialMediaAccountPost" */
export type SocialMediaAccountPost_Min_Order_By = {
  commentCount?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  externalId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  jobId?: InputMaybe<Order_By>;
  likeCount?: InputMaybe<Order_By>;
  postedAt?: InputMaybe<Order_By>;
  socialMediaAccountId?: InputMaybe<Order_By>;
  socialMediaPostId?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "socialMediaAccountPost" */
export type SocialMediaAccountPost_Mutation_Response = {
  __typename?: 'socialMediaAccountPost_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<SocialMediaAccountPost>;
};

/** on_conflict condition type for table "socialMediaAccountPost" */
export type SocialMediaAccountPost_On_Conflict = {
  constraint: SocialMediaAccountPost_Constraint;
  update_columns?: Array<SocialMediaAccountPost_Update_Column>;
  where?: InputMaybe<SocialMediaAccountPost_Bool_Exp>;
};

/** Ordering options when selecting data from "socialMediaAccountPost". */
export type SocialMediaAccountPost_Order_By = {
  commentCount?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  externalId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  job?: InputMaybe<Job_Order_By>;
  jobId?: InputMaybe<Order_By>;
  likeCount?: InputMaybe<Order_By>;
  postStatus?: InputMaybe<PostStatus_Order_By>;
  postedAt?: InputMaybe<Order_By>;
  socialMediaAccount?: InputMaybe<SocialMediaAccount_Order_By>;
  socialMediaAccountId?: InputMaybe<Order_By>;
  socialMediaPost?: InputMaybe<SocialMediaPost_Order_By>;
  socialMediaPostId?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: socialMediaAccountPost */
export type SocialMediaAccountPost_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "socialMediaAccountPost" */
export enum SocialMediaAccountPost_Select_Column {
  /** column name */
  CommentCount = 'commentCount',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExternalId = 'externalId',
  /** column name */
  Id = 'id',
  /** column name */
  JobId = 'jobId',
  /** column name */
  LikeCount = 'likeCount',
  /** column name */
  PostedAt = 'postedAt',
  /** column name */
  SocialMediaAccountId = 'socialMediaAccountId',
  /** column name */
  SocialMediaPostId = 'socialMediaPostId',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "socialMediaAccountPost" */
export type SocialMediaAccountPost_Set_Input = {
  commentCount?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  externalId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  jobId?: InputMaybe<Scalars['uuid']>;
  likeCount?: InputMaybe<Scalars['Int']>;
  postedAt?: InputMaybe<Scalars['timestamptz']>;
  socialMediaAccountId?: InputMaybe<Scalars['uuid']>;
  socialMediaPostId?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<PostStatus_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type SocialMediaAccountPost_Stddev_Fields = {
  __typename?: 'socialMediaAccountPost_stddev_fields';
  commentCount?: Maybe<Scalars['Float']>;
  likeCount?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "socialMediaAccountPost" */
export type SocialMediaAccountPost_Stddev_Order_By = {
  commentCount?: InputMaybe<Order_By>;
  likeCount?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type SocialMediaAccountPost_Stddev_Pop_Fields = {
  __typename?: 'socialMediaAccountPost_stddev_pop_fields';
  commentCount?: Maybe<Scalars['Float']>;
  likeCount?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "socialMediaAccountPost" */
export type SocialMediaAccountPost_Stddev_Pop_Order_By = {
  commentCount?: InputMaybe<Order_By>;
  likeCount?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type SocialMediaAccountPost_Stddev_Samp_Fields = {
  __typename?: 'socialMediaAccountPost_stddev_samp_fields';
  commentCount?: Maybe<Scalars['Float']>;
  likeCount?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "socialMediaAccountPost" */
export type SocialMediaAccountPost_Stddev_Samp_Order_By = {
  commentCount?: InputMaybe<Order_By>;
  likeCount?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "socialMediaAccountPost" */
export type SocialMediaAccountPost_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: SocialMediaAccountPost_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type SocialMediaAccountPost_Stream_Cursor_Value_Input = {
  commentCount?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  externalId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  jobId?: InputMaybe<Scalars['uuid']>;
  likeCount?: InputMaybe<Scalars['Int']>;
  postedAt?: InputMaybe<Scalars['timestamptz']>;
  socialMediaAccountId?: InputMaybe<Scalars['uuid']>;
  socialMediaPostId?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<PostStatus_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type SocialMediaAccountPost_Sum_Fields = {
  __typename?: 'socialMediaAccountPost_sum_fields';
  commentCount?: Maybe<Scalars['Int']>;
  likeCount?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "socialMediaAccountPost" */
export type SocialMediaAccountPost_Sum_Order_By = {
  commentCount?: InputMaybe<Order_By>;
  likeCount?: InputMaybe<Order_By>;
};

/** update columns of table "socialMediaAccountPost" */
export enum SocialMediaAccountPost_Update_Column {
  /** column name */
  CommentCount = 'commentCount',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExternalId = 'externalId',
  /** column name */
  Id = 'id',
  /** column name */
  JobId = 'jobId',
  /** column name */
  LikeCount = 'likeCount',
  /** column name */
  PostedAt = 'postedAt',
  /** column name */
  SocialMediaAccountId = 'socialMediaAccountId',
  /** column name */
  SocialMediaPostId = 'socialMediaPostId',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type SocialMediaAccountPost_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<SocialMediaAccountPost_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<SocialMediaAccountPost_Set_Input>;
  /** filter the rows which have to be updated */
  where: SocialMediaAccountPost_Bool_Exp;
};

/** aggregate var_pop on columns */
export type SocialMediaAccountPost_Var_Pop_Fields = {
  __typename?: 'socialMediaAccountPost_var_pop_fields';
  commentCount?: Maybe<Scalars['Float']>;
  likeCount?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "socialMediaAccountPost" */
export type SocialMediaAccountPost_Var_Pop_Order_By = {
  commentCount?: InputMaybe<Order_By>;
  likeCount?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type SocialMediaAccountPost_Var_Samp_Fields = {
  __typename?: 'socialMediaAccountPost_var_samp_fields';
  commentCount?: Maybe<Scalars['Float']>;
  likeCount?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "socialMediaAccountPost" */
export type SocialMediaAccountPost_Var_Samp_Order_By = {
  commentCount?: InputMaybe<Order_By>;
  likeCount?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type SocialMediaAccountPost_Variance_Fields = {
  __typename?: 'socialMediaAccountPost_variance_fields';
  commentCount?: Maybe<Scalars['Float']>;
  likeCount?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "socialMediaAccountPost" */
export type SocialMediaAccountPost_Variance_Order_By = {
  commentCount?: InputMaybe<Order_By>;
  likeCount?: InputMaybe<Order_By>;
};

/** aggregated selection of "socialMediaAccount" */
export type SocialMediaAccount_Aggregate = {
  __typename?: 'socialMediaAccount_aggregate';
  aggregate?: Maybe<SocialMediaAccount_Aggregate_Fields>;
  nodes: Array<SocialMediaAccount>;
};

export type SocialMediaAccount_Aggregate_Bool_Exp = {
  count?: InputMaybe<SocialMediaAccount_Aggregate_Bool_Exp_Count>;
};

export type SocialMediaAccount_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<SocialMediaAccount_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<SocialMediaAccount_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "socialMediaAccount" */
export type SocialMediaAccount_Aggregate_Fields = {
  __typename?: 'socialMediaAccount_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<SocialMediaAccount_Max_Fields>;
  min?: Maybe<SocialMediaAccount_Min_Fields>;
};


/** aggregate fields of "socialMediaAccount" */
export type SocialMediaAccount_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<SocialMediaAccount_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "socialMediaAccount" */
export type SocialMediaAccount_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<SocialMediaAccount_Max_Order_By>;
  min?: InputMaybe<SocialMediaAccount_Min_Order_By>;
};

/** input type for inserting array relation for remote table "socialMediaAccount" */
export type SocialMediaAccount_Arr_Rel_Insert_Input = {
  data: Array<SocialMediaAccount_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<SocialMediaAccount_On_Conflict>;
};

/** Boolean expression to filter rows from the table "socialMediaAccount". All fields are combined with a logical 'AND'. */
export type SocialMediaAccount_Bool_Exp = {
  _and?: InputMaybe<Array<SocialMediaAccount_Bool_Exp>>;
  _not?: InputMaybe<SocialMediaAccount_Bool_Exp>;
  _or?: InputMaybe<Array<SocialMediaAccount_Bool_Exp>>;
  authProfile?: InputMaybe<AuthProfile_Bool_Exp>;
  authProfileId?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  externalId?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  platform?: InputMaybe<SocialMediaPlatform_Enum_Comparison_Exp>;
  profilePicture?: InputMaybe<String_Comparison_Exp>;
  socialMediaAccountPosts?: InputMaybe<SocialMediaAccountPost_Bool_Exp>;
  socialMediaAccountPosts_aggregate?: InputMaybe<SocialMediaAccountPost_Aggregate_Bool_Exp>;
  socialMediaPlatform?: InputMaybe<SocialMediaPlatform_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<User_Bool_Exp>;
  userId?: InputMaybe<Uuid_Comparison_Exp>;
  workspace?: InputMaybe<Workspace_Bool_Exp>;
  workspaceId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "socialMediaAccount" */
export enum SocialMediaAccount_Constraint {
  /** unique or primary key constraint on columns "id" */
  SocialMediaAccountPkey = 'socialMediaAccount_pkey',
  /** unique or primary key constraint on columns "externalId", "workspaceId", "platform" */
  SocialMediaAccountPlatformExternalIdWorkspaceIdKey = 'socialMediaAccount_platform_externalId_workspaceId_key'
}

/** input type for inserting data into table "socialMediaAccount" */
export type SocialMediaAccount_Insert_Input = {
  authProfile?: InputMaybe<AuthProfile_Obj_Rel_Insert_Input>;
  authProfileId?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  externalId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  platform?: InputMaybe<SocialMediaPlatform_Enum>;
  profilePicture?: InputMaybe<Scalars['String']>;
  socialMediaAccountPosts?: InputMaybe<SocialMediaAccountPost_Arr_Rel_Insert_Input>;
  socialMediaPlatform?: InputMaybe<SocialMediaPlatform_Obj_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<User_Obj_Rel_Insert_Input>;
  userId?: InputMaybe<Scalars['uuid']>;
  workspace?: InputMaybe<Workspace_Obj_Rel_Insert_Input>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type SocialMediaAccount_Max_Fields = {
  __typename?: 'socialMediaAccount_max_fields';
  authProfileId?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  externalId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  profilePicture?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['uuid']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "socialMediaAccount" */
export type SocialMediaAccount_Max_Order_By = {
  authProfileId?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  externalId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  profilePicture?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type SocialMediaAccount_Min_Fields = {
  __typename?: 'socialMediaAccount_min_fields';
  authProfileId?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  externalId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  profilePicture?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['uuid']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "socialMediaAccount" */
export type SocialMediaAccount_Min_Order_By = {
  authProfileId?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  externalId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  profilePicture?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "socialMediaAccount" */
export type SocialMediaAccount_Mutation_Response = {
  __typename?: 'socialMediaAccount_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<SocialMediaAccount>;
};

/** input type for inserting object relation for remote table "socialMediaAccount" */
export type SocialMediaAccount_Obj_Rel_Insert_Input = {
  data: SocialMediaAccount_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<SocialMediaAccount_On_Conflict>;
};

/** on_conflict condition type for table "socialMediaAccount" */
export type SocialMediaAccount_On_Conflict = {
  constraint: SocialMediaAccount_Constraint;
  update_columns?: Array<SocialMediaAccount_Update_Column>;
  where?: InputMaybe<SocialMediaAccount_Bool_Exp>;
};

/** Ordering options when selecting data from "socialMediaAccount". */
export type SocialMediaAccount_Order_By = {
  authProfile?: InputMaybe<AuthProfile_Order_By>;
  authProfileId?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  externalId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  platform?: InputMaybe<Order_By>;
  profilePicture?: InputMaybe<Order_By>;
  socialMediaAccountPosts_aggregate?: InputMaybe<SocialMediaAccountPost_Aggregate_Order_By>;
  socialMediaPlatform?: InputMaybe<SocialMediaPlatform_Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<User_Order_By>;
  userId?: InputMaybe<Order_By>;
  workspace?: InputMaybe<Workspace_Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: socialMediaAccount */
export type SocialMediaAccount_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "socialMediaAccount" */
export enum SocialMediaAccount_Select_Column {
  /** column name */
  AuthProfileId = 'authProfileId',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExternalId = 'externalId',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Platform = 'platform',
  /** column name */
  ProfilePicture = 'profilePicture',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'userId',
  /** column name */
  WorkspaceId = 'workspaceId'
}

/** input type for updating data in table "socialMediaAccount" */
export type SocialMediaAccount_Set_Input = {
  authProfileId?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  externalId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  platform?: InputMaybe<SocialMediaPlatform_Enum>;
  profilePicture?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  userId?: InputMaybe<Scalars['uuid']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "socialMediaAccount" */
export type SocialMediaAccount_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: SocialMediaAccount_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type SocialMediaAccount_Stream_Cursor_Value_Input = {
  authProfileId?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  externalId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  platform?: InputMaybe<SocialMediaPlatform_Enum>;
  profilePicture?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  userId?: InputMaybe<Scalars['uuid']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "socialMediaAccount" */
export enum SocialMediaAccount_Update_Column {
  /** column name */
  AuthProfileId = 'authProfileId',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExternalId = 'externalId',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Platform = 'platform',
  /** column name */
  ProfilePicture = 'profilePicture',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'userId',
  /** column name */
  WorkspaceId = 'workspaceId'
}

export type SocialMediaAccount_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<SocialMediaAccount_Set_Input>;
  /** filter the rows which have to be updated */
  where: SocialMediaAccount_Bool_Exp;
};

/** columns and relationships of "socialMediaPlatform" */
export type SocialMediaPlatform = {
  __typename?: 'socialMediaPlatform';
  description: Scalars['String'];
  /** An array relationship */
  socialMediaAccounts: Array<SocialMediaAccount>;
  /** An aggregate relationship */
  socialMediaAccounts_aggregate: SocialMediaAccount_Aggregate;
  value: Scalars['String'];
};


/** columns and relationships of "socialMediaPlatform" */
export type SocialMediaPlatformSocialMediaAccountsArgs = {
  distinct_on?: InputMaybe<Array<SocialMediaAccount_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SocialMediaAccount_Order_By>>;
  where?: InputMaybe<SocialMediaAccount_Bool_Exp>;
};


/** columns and relationships of "socialMediaPlatform" */
export type SocialMediaPlatformSocialMediaAccounts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<SocialMediaAccount_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SocialMediaAccount_Order_By>>;
  where?: InputMaybe<SocialMediaAccount_Bool_Exp>;
};

/** aggregated selection of "socialMediaPlatform" */
export type SocialMediaPlatform_Aggregate = {
  __typename?: 'socialMediaPlatform_aggregate';
  aggregate?: Maybe<SocialMediaPlatform_Aggregate_Fields>;
  nodes: Array<SocialMediaPlatform>;
};

/** aggregate fields of "socialMediaPlatform" */
export type SocialMediaPlatform_Aggregate_Fields = {
  __typename?: 'socialMediaPlatform_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<SocialMediaPlatform_Max_Fields>;
  min?: Maybe<SocialMediaPlatform_Min_Fields>;
};


/** aggregate fields of "socialMediaPlatform" */
export type SocialMediaPlatform_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<SocialMediaPlatform_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "socialMediaPlatform". All fields are combined with a logical 'AND'. */
export type SocialMediaPlatform_Bool_Exp = {
  _and?: InputMaybe<Array<SocialMediaPlatform_Bool_Exp>>;
  _not?: InputMaybe<SocialMediaPlatform_Bool_Exp>;
  _or?: InputMaybe<Array<SocialMediaPlatform_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  socialMediaAccounts?: InputMaybe<SocialMediaAccount_Bool_Exp>;
  socialMediaAccounts_aggregate?: InputMaybe<SocialMediaAccount_Aggregate_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "socialMediaPlatform" */
export enum SocialMediaPlatform_Constraint {
  /** unique or primary key constraint on columns "value" */
  SocialMediaPlatformPkey = 'socialMediaPlatform_pkey'
}

export enum SocialMediaPlatform_Enum {
  /** Facebook */
  Fb = 'FB',
  /** Instagram */
  Ig = 'IG'
}

/** Boolean expression to compare columns of type "socialMediaPlatform_enum". All fields are combined with logical 'AND'. */
export type SocialMediaPlatform_Enum_Comparison_Exp = {
  _eq?: InputMaybe<SocialMediaPlatform_Enum>;
  _in?: InputMaybe<Array<SocialMediaPlatform_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<SocialMediaPlatform_Enum>;
  _nin?: InputMaybe<Array<SocialMediaPlatform_Enum>>;
};

/** input type for inserting data into table "socialMediaPlatform" */
export type SocialMediaPlatform_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  socialMediaAccounts?: InputMaybe<SocialMediaAccount_Arr_Rel_Insert_Input>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type SocialMediaPlatform_Max_Fields = {
  __typename?: 'socialMediaPlatform_max_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type SocialMediaPlatform_Min_Fields = {
  __typename?: 'socialMediaPlatform_min_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "socialMediaPlatform" */
export type SocialMediaPlatform_Mutation_Response = {
  __typename?: 'socialMediaPlatform_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<SocialMediaPlatform>;
};

/** input type for inserting object relation for remote table "socialMediaPlatform" */
export type SocialMediaPlatform_Obj_Rel_Insert_Input = {
  data: SocialMediaPlatform_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<SocialMediaPlatform_On_Conflict>;
};

/** on_conflict condition type for table "socialMediaPlatform" */
export type SocialMediaPlatform_On_Conflict = {
  constraint: SocialMediaPlatform_Constraint;
  update_columns?: Array<SocialMediaPlatform_Update_Column>;
  where?: InputMaybe<SocialMediaPlatform_Bool_Exp>;
};

/** Ordering options when selecting data from "socialMediaPlatform". */
export type SocialMediaPlatform_Order_By = {
  description?: InputMaybe<Order_By>;
  socialMediaAccounts_aggregate?: InputMaybe<SocialMediaAccount_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: socialMediaPlatform */
export type SocialMediaPlatform_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "socialMediaPlatform" */
export enum SocialMediaPlatform_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "socialMediaPlatform" */
export type SocialMediaPlatform_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "socialMediaPlatform" */
export type SocialMediaPlatform_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: SocialMediaPlatform_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type SocialMediaPlatform_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "socialMediaPlatform" */
export enum SocialMediaPlatform_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

export type SocialMediaPlatform_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<SocialMediaPlatform_Set_Input>;
  /** filter the rows which have to be updated */
  where: SocialMediaPlatform_Bool_Exp;
};

/** columns and relationships of "socialMediaPost" */
export type SocialMediaPost = {
  __typename?: 'socialMediaPost';
  caption?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  intendedPostAt: Scalars['timestamptz'];
  isScheduled: Scalars['Boolean'];
  /** An object relationship */
  privatePhoto?: Maybe<Photo>;
  privatePhotoId?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  publicPhoto?: Maybe<Photo>;
  publicPhotoId?: Maybe<Scalars['uuid']>;
  /** An array relationship */
  socialMediaAccountPosts: Array<SocialMediaAccountPost>;
  /** An aggregate relationship */
  socialMediaAccountPosts_aggregate: SocialMediaAccountPost_Aggregate;
  title: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  userId: Scalars['uuid'];
  workspaceId: Scalars['uuid'];
};


/** columns and relationships of "socialMediaPost" */
export type SocialMediaPostSocialMediaAccountPostsArgs = {
  distinct_on?: InputMaybe<Array<SocialMediaAccountPost_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SocialMediaAccountPost_Order_By>>;
  where?: InputMaybe<SocialMediaAccountPost_Bool_Exp>;
};


/** columns and relationships of "socialMediaPost" */
export type SocialMediaPostSocialMediaAccountPosts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<SocialMediaAccountPost_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SocialMediaAccountPost_Order_By>>;
  where?: InputMaybe<SocialMediaAccountPost_Bool_Exp>;
};

/** aggregated selection of "socialMediaPost" */
export type SocialMediaPost_Aggregate = {
  __typename?: 'socialMediaPost_aggregate';
  aggregate?: Maybe<SocialMediaPost_Aggregate_Fields>;
  nodes: Array<SocialMediaPost>;
};

/** aggregate fields of "socialMediaPost" */
export type SocialMediaPost_Aggregate_Fields = {
  __typename?: 'socialMediaPost_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<SocialMediaPost_Max_Fields>;
  min?: Maybe<SocialMediaPost_Min_Fields>;
};


/** aggregate fields of "socialMediaPost" */
export type SocialMediaPost_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<SocialMediaPost_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "socialMediaPost". All fields are combined with a logical 'AND'. */
export type SocialMediaPost_Bool_Exp = {
  _and?: InputMaybe<Array<SocialMediaPost_Bool_Exp>>;
  _not?: InputMaybe<SocialMediaPost_Bool_Exp>;
  _or?: InputMaybe<Array<SocialMediaPost_Bool_Exp>>;
  caption?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  intendedPostAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  isScheduled?: InputMaybe<Boolean_Comparison_Exp>;
  privatePhoto?: InputMaybe<Photo_Bool_Exp>;
  privatePhotoId?: InputMaybe<Uuid_Comparison_Exp>;
  publicPhoto?: InputMaybe<Photo_Bool_Exp>;
  publicPhotoId?: InputMaybe<Uuid_Comparison_Exp>;
  socialMediaAccountPosts?: InputMaybe<SocialMediaAccountPost_Bool_Exp>;
  socialMediaAccountPosts_aggregate?: InputMaybe<SocialMediaAccountPost_Aggregate_Bool_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  userId?: InputMaybe<Uuid_Comparison_Exp>;
  workspaceId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "socialMediaPost" */
export enum SocialMediaPost_Constraint {
  /** unique or primary key constraint on columns "id" */
  SocialMediaPostPkey = 'socialMediaPost_pkey'
}

/** input type for inserting data into table "socialMediaPost" */
export type SocialMediaPost_Insert_Input = {
  caption?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  intendedPostAt?: InputMaybe<Scalars['timestamptz']>;
  isScheduled?: InputMaybe<Scalars['Boolean']>;
  privatePhoto?: InputMaybe<Photo_Obj_Rel_Insert_Input>;
  privatePhotoId?: InputMaybe<Scalars['uuid']>;
  publicPhoto?: InputMaybe<Photo_Obj_Rel_Insert_Input>;
  publicPhotoId?: InputMaybe<Scalars['uuid']>;
  socialMediaAccountPosts?: InputMaybe<SocialMediaAccountPost_Arr_Rel_Insert_Input>;
  title?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  userId?: InputMaybe<Scalars['uuid']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type SocialMediaPost_Max_Fields = {
  __typename?: 'socialMediaPost_max_fields';
  caption?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  intendedPostAt?: Maybe<Scalars['timestamptz']>;
  privatePhotoId?: Maybe<Scalars['uuid']>;
  publicPhotoId?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['uuid']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type SocialMediaPost_Min_Fields = {
  __typename?: 'socialMediaPost_min_fields';
  caption?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  intendedPostAt?: Maybe<Scalars['timestamptz']>;
  privatePhotoId?: Maybe<Scalars['uuid']>;
  publicPhotoId?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['uuid']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "socialMediaPost" */
export type SocialMediaPost_Mutation_Response = {
  __typename?: 'socialMediaPost_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<SocialMediaPost>;
};

/** input type for inserting object relation for remote table "socialMediaPost" */
export type SocialMediaPost_Obj_Rel_Insert_Input = {
  data: SocialMediaPost_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<SocialMediaPost_On_Conflict>;
};

/** on_conflict condition type for table "socialMediaPost" */
export type SocialMediaPost_On_Conflict = {
  constraint: SocialMediaPost_Constraint;
  update_columns?: Array<SocialMediaPost_Update_Column>;
  where?: InputMaybe<SocialMediaPost_Bool_Exp>;
};

/** Ordering options when selecting data from "socialMediaPost". */
export type SocialMediaPost_Order_By = {
  caption?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  intendedPostAt?: InputMaybe<Order_By>;
  isScheduled?: InputMaybe<Order_By>;
  privatePhoto?: InputMaybe<Photo_Order_By>;
  privatePhotoId?: InputMaybe<Order_By>;
  publicPhoto?: InputMaybe<Photo_Order_By>;
  publicPhotoId?: InputMaybe<Order_By>;
  socialMediaAccountPosts_aggregate?: InputMaybe<SocialMediaAccountPost_Aggregate_Order_By>;
  title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: socialMediaPost */
export type SocialMediaPost_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "socialMediaPost" */
export enum SocialMediaPost_Select_Column {
  /** column name */
  Caption = 'caption',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IntendedPostAt = 'intendedPostAt',
  /** column name */
  IsScheduled = 'isScheduled',
  /** column name */
  PrivatePhotoId = 'privatePhotoId',
  /** column name */
  PublicPhotoId = 'publicPhotoId',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'userId',
  /** column name */
  WorkspaceId = 'workspaceId'
}

/** input type for updating data in table "socialMediaPost" */
export type SocialMediaPost_Set_Input = {
  caption?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  intendedPostAt?: InputMaybe<Scalars['timestamptz']>;
  isScheduled?: InputMaybe<Scalars['Boolean']>;
  privatePhotoId?: InputMaybe<Scalars['uuid']>;
  publicPhotoId?: InputMaybe<Scalars['uuid']>;
  title?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  userId?: InputMaybe<Scalars['uuid']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "socialMediaPost" */
export type SocialMediaPost_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: SocialMediaPost_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type SocialMediaPost_Stream_Cursor_Value_Input = {
  caption?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  intendedPostAt?: InputMaybe<Scalars['timestamptz']>;
  isScheduled?: InputMaybe<Scalars['Boolean']>;
  privatePhotoId?: InputMaybe<Scalars['uuid']>;
  publicPhotoId?: InputMaybe<Scalars['uuid']>;
  title?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  userId?: InputMaybe<Scalars['uuid']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "socialMediaPost" */
export enum SocialMediaPost_Update_Column {
  /** column name */
  Caption = 'caption',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IntendedPostAt = 'intendedPostAt',
  /** column name */
  IsScheduled = 'isScheduled',
  /** column name */
  PrivatePhotoId = 'privatePhotoId',
  /** column name */
  PublicPhotoId = 'publicPhotoId',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'userId',
  /** column name */
  WorkspaceId = 'workspaceId'
}

export type SocialMediaPost_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<SocialMediaPost_Set_Input>;
  /** filter the rows which have to be updated */
  where: SocialMediaPost_Bool_Exp;
};

export type Subscription_Root = {
  __typename?: 'subscription_root';
  /** fetch data from the table: "activityLog" */
  activityLog: Array<ActivityLog>;
  /** fetch aggregated fields from the table: "activityLog" */
  activityLog_aggregate: ActivityLog_Aggregate;
  /** fetch data from the table: "activityLog" using primary key columns */
  activityLog_by_pk?: Maybe<ActivityLog>;
  /** fetch data from the table in a streaming manner: "activityLog" */
  activityLog_stream: Array<ActivityLog>;
  /** fetch data from the table: "address" */
  address: Array<Address>;
  /** fetch aggregated fields from the table: "address" */
  address_aggregate: Address_Aggregate;
  /** fetch data from the table: "address" using primary key columns */
  address_by_pk?: Maybe<Address>;
  /** fetch data from the table in a streaming manner: "address" */
  address_stream: Array<Address>;
  /** fetch data from the table: "appointment" */
  appointment: Array<Appointment>;
  /** fetch data from the table: "appointmentConsent" */
  appointmentConsent: Array<AppointmentConsent>;
  /** fetch aggregated fields from the table: "appointmentConsent" */
  appointmentConsent_aggregate: AppointmentConsent_Aggregate;
  /** fetch data from the table: "appointmentConsent" using primary key columns */
  appointmentConsent_by_pk?: Maybe<AppointmentConsent>;
  /** fetch data from the table in a streaming manner: "appointmentConsent" */
  appointmentConsent_stream: Array<AppointmentConsent>;
  /** fetch data from the table: "appointmentCustomForm" */
  appointmentCustomForm: Array<AppointmentCustomForm>;
  /** fetch aggregated fields from the table: "appointmentCustomForm" */
  appointmentCustomForm_aggregate: AppointmentCustomForm_Aggregate;
  /** fetch data from the table: "appointmentCustomForm" using primary key columns */
  appointmentCustomForm_by_pk?: Maybe<AppointmentCustomForm>;
  /** fetch data from the table in a streaming manner: "appointmentCustomForm" */
  appointmentCustomForm_stream: Array<AppointmentCustomForm>;
  /** fetch data from the table: "appointmentService" */
  appointmentService: Array<AppointmentService>;
  /** fetch data from the table: "appointmentServiceConsumable" */
  appointmentServiceConsumable: Array<AppointmentServiceConsumable>;
  /** fetch aggregated fields from the table: "appointmentServiceConsumable" */
  appointmentServiceConsumable_aggregate: AppointmentServiceConsumable_Aggregate;
  /** fetch data from the table: "appointmentServiceConsumable" using primary key columns */
  appointmentServiceConsumable_by_pk?: Maybe<AppointmentServiceConsumable>;
  /** fetch data from the table in a streaming manner: "appointmentServiceConsumable" */
  appointmentServiceConsumable_stream: Array<AppointmentServiceConsumable>;
  /** fetch aggregated fields from the table: "appointmentService" */
  appointmentService_aggregate: AppointmentService_Aggregate;
  /** fetch data from the table: "appointmentService" using primary key columns */
  appointmentService_by_pk?: Maybe<AppointmentService>;
  /** fetch data from the table in a streaming manner: "appointmentService" */
  appointmentService_stream: Array<AppointmentService>;
  /** fetch data from the table: "appointmentType" */
  appointmentType: Array<AppointmentType>;
  /** fetch aggregated fields from the table: "appointmentType" */
  appointmentType_aggregate: AppointmentType_Aggregate;
  /** fetch data from the table: "appointmentType" using primary key columns */
  appointmentType_by_pk?: Maybe<AppointmentType>;
  /** fetch data from the table in a streaming manner: "appointmentType" */
  appointmentType_stream: Array<AppointmentType>;
  /** fetch aggregated fields from the table: "appointment" */
  appointment_aggregate: Appointment_Aggregate;
  /** fetch data from the table: "appointment" using primary key columns */
  appointment_by_pk?: Maybe<Appointment>;
  /** fetch data from the table in a streaming manner: "appointment" */
  appointment_stream: Array<Appointment>;
  /** fetch data from the table: "audience" */
  audience: Array<Audience>;
  /** fetch data from the table: "audienceEmail" */
  audienceEmail: Array<AudienceEmail>;
  /** fetch aggregated fields from the table: "audienceEmail" */
  audienceEmail_aggregate: AudienceEmail_Aggregate;
  /** fetch data from the table: "audienceEmail" using primary key columns */
  audienceEmail_by_pk?: Maybe<AudienceEmail>;
  /** fetch data from the table in a streaming manner: "audienceEmail" */
  audienceEmail_stream: Array<AudienceEmail>;
  /** fetch aggregated fields from the table: "audience" */
  audience_aggregate: Audience_Aggregate;
  /** fetch data from the table: "audience" using primary key columns */
  audience_by_pk?: Maybe<Audience>;
  /** fetch data from the table in a streaming manner: "audience" */
  audience_stream: Array<Audience>;
  /** fetch data from the table: "authProfile" */
  authProfile: Array<AuthProfile>;
  /** fetch aggregated fields from the table: "authProfile" */
  authProfile_aggregate: AuthProfile_Aggregate;
  /** fetch data from the table: "authProfile" using primary key columns */
  authProfile_by_pk?: Maybe<AuthProfile>;
  /** fetch data from the table in a streaming manner: "authProfile" */
  authProfile_stream: Array<AuthProfile>;
  /** fetch data from the table: "clinicalMessage" */
  clinicalMessage: Array<ClinicalMessage>;
  /** fetch data from the table: "clinicalMessageStatus" */
  clinicalMessageStatus: Array<ClinicalMessageStatus>;
  /** fetch aggregated fields from the table: "clinicalMessageStatus" */
  clinicalMessageStatus_aggregate: ClinicalMessageStatus_Aggregate;
  /** fetch data from the table: "clinicalMessageStatus" using primary key columns */
  clinicalMessageStatus_by_pk?: Maybe<ClinicalMessageStatus>;
  /** fetch data from the table in a streaming manner: "clinicalMessageStatus" */
  clinicalMessageStatus_stream: Array<ClinicalMessageStatus>;
  /** fetch aggregated fields from the table: "clinicalMessage" */
  clinicalMessage_aggregate: ClinicalMessage_Aggregate;
  /** fetch data from the table: "clinicalMessage" using primary key columns */
  clinicalMessage_by_pk?: Maybe<ClinicalMessage>;
  /** fetch data from the table in a streaming manner: "clinicalMessage" */
  clinicalMessage_stream: Array<ClinicalMessage>;
  /** fetch data from the table: "compensationPolicy" */
  compensationPolicy: Array<CompensationPolicy>;
  /** fetch data from the table: "compensationPolicyCommission" */
  compensationPolicyCommission: Array<CompensationPolicyCommission>;
  /** fetch aggregated fields from the table: "compensationPolicyCommission" */
  compensationPolicyCommission_aggregate: CompensationPolicyCommission_Aggregate;
  /** fetch data from the table: "compensationPolicyCommission" using primary key columns */
  compensationPolicyCommission_by_pk?: Maybe<CompensationPolicyCommission>;
  /** fetch data from the table in a streaming manner: "compensationPolicyCommission" */
  compensationPolicyCommission_stream: Array<CompensationPolicyCommission>;
  /** fetch data from the table: "compensationPolicyTier" */
  compensationPolicyTier: Array<CompensationPolicyTier>;
  /** fetch data from the table: "compensationPolicyTierCategory" */
  compensationPolicyTierCategory: Array<CompensationPolicyTierCategory>;
  /** fetch aggregated fields from the table: "compensationPolicyTierCategory" */
  compensationPolicyTierCategory_aggregate: CompensationPolicyTierCategory_Aggregate;
  /** fetch data from the table: "compensationPolicyTierCategory" using primary key columns */
  compensationPolicyTierCategory_by_pk?: Maybe<CompensationPolicyTierCategory>;
  /** fetch data from the table in a streaming manner: "compensationPolicyTierCategory" */
  compensationPolicyTierCategory_stream: Array<CompensationPolicyTierCategory>;
  /** fetch data from the table: "compensationPolicyTierExemption" */
  compensationPolicyTierExemption: Array<CompensationPolicyTierExemption>;
  /** fetch aggregated fields from the table: "compensationPolicyTierExemption" */
  compensationPolicyTierExemption_aggregate: CompensationPolicyTierExemption_Aggregate;
  /** fetch data from the table: "compensationPolicyTierExemption" using primary key columns */
  compensationPolicyTierExemption_by_pk?: Maybe<CompensationPolicyTierExemption>;
  /** fetch data from the table in a streaming manner: "compensationPolicyTierExemption" */
  compensationPolicyTierExemption_stream: Array<CompensationPolicyTierExemption>;
  /** fetch data from the table: "compensationPolicyTierInclusion" */
  compensationPolicyTierInclusion: Array<CompensationPolicyTierInclusion>;
  /** fetch aggregated fields from the table: "compensationPolicyTierInclusion" */
  compensationPolicyTierInclusion_aggregate: CompensationPolicyTierInclusion_Aggregate;
  /** fetch data from the table: "compensationPolicyTierInclusion" using primary key columns */
  compensationPolicyTierInclusion_by_pk?: Maybe<CompensationPolicyTierInclusion>;
  /** fetch data from the table in a streaming manner: "compensationPolicyTierInclusion" */
  compensationPolicyTierInclusion_stream: Array<CompensationPolicyTierInclusion>;
  /** fetch aggregated fields from the table: "compensationPolicyTier" */
  compensationPolicyTier_aggregate: CompensationPolicyTier_Aggregate;
  /** fetch data from the table: "compensationPolicyTier" using primary key columns */
  compensationPolicyTier_by_pk?: Maybe<CompensationPolicyTier>;
  /** fetch data from the table in a streaming manner: "compensationPolicyTier" */
  compensationPolicyTier_stream: Array<CompensationPolicyTier>;
  /** fetch aggregated fields from the table: "compensationPolicy" */
  compensationPolicy_aggregate: CompensationPolicy_Aggregate;
  /** fetch data from the table: "compensationPolicy" using primary key columns */
  compensationPolicy_by_pk?: Maybe<CompensationPolicy>;
  /** fetch data from the table in a streaming manner: "compensationPolicy" */
  compensationPolicy_stream: Array<CompensationPolicy>;
  /** fetch data from the table: "compensationStrategy" */
  compensationStrategy: Array<CompensationStrategy>;
  /** fetch data from the table: "compensationStrategyCommission" */
  compensationStrategyCommission: Array<CompensationStrategyCommission>;
  /** fetch aggregated fields from the table: "compensationStrategyCommission" */
  compensationStrategyCommission_aggregate: CompensationStrategyCommission_Aggregate;
  /** fetch data from the table: "compensationStrategyCommission" using primary key columns */
  compensationStrategyCommission_by_pk?: Maybe<CompensationStrategyCommission>;
  /** fetch data from the table in a streaming manner: "compensationStrategyCommission" */
  compensationStrategyCommission_stream: Array<CompensationStrategyCommission>;
  /** fetch data from the table: "compensationStrategyTier" */
  compensationStrategyTier: Array<CompensationStrategyTier>;
  /** fetch data from the table: "compensationStrategyTierCategory" */
  compensationStrategyTierCategory: Array<CompensationStrategyTierCategory>;
  /** fetch aggregated fields from the table: "compensationStrategyTierCategory" */
  compensationStrategyTierCategory_aggregate: CompensationStrategyTierCategory_Aggregate;
  /** fetch data from the table: "compensationStrategyTierCategory" using primary key columns */
  compensationStrategyTierCategory_by_pk?: Maybe<CompensationStrategyTierCategory>;
  /** fetch data from the table in a streaming manner: "compensationStrategyTierCategory" */
  compensationStrategyTierCategory_stream: Array<CompensationStrategyTierCategory>;
  /** fetch data from the table: "compensationStrategyTierExemption" */
  compensationStrategyTierExemption: Array<CompensationStrategyTierExemption>;
  /** fetch aggregated fields from the table: "compensationStrategyTierExemption" */
  compensationStrategyTierExemption_aggregate: CompensationStrategyTierExemption_Aggregate;
  /** fetch data from the table: "compensationStrategyTierExemption" using primary key columns */
  compensationStrategyTierExemption_by_pk?: Maybe<CompensationStrategyTierExemption>;
  /** fetch data from the table in a streaming manner: "compensationStrategyTierExemption" */
  compensationStrategyTierExemption_stream: Array<CompensationStrategyTierExemption>;
  /** fetch data from the table: "compensationStrategyTierInclusion" */
  compensationStrategyTierInclusion: Array<CompensationStrategyTierInclusion>;
  /** fetch aggregated fields from the table: "compensationStrategyTierInclusion" */
  compensationStrategyTierInclusion_aggregate: CompensationStrategyTierInclusion_Aggregate;
  /** fetch data from the table: "compensationStrategyTierInclusion" using primary key columns */
  compensationStrategyTierInclusion_by_pk?: Maybe<CompensationStrategyTierInclusion>;
  /** fetch data from the table in a streaming manner: "compensationStrategyTierInclusion" */
  compensationStrategyTierInclusion_stream: Array<CompensationStrategyTierInclusion>;
  /** fetch aggregated fields from the table: "compensationStrategyTier" */
  compensationStrategyTier_aggregate: CompensationStrategyTier_Aggregate;
  /** fetch data from the table: "compensationStrategyTier" using primary key columns */
  compensationStrategyTier_by_pk?: Maybe<CompensationStrategyTier>;
  /** fetch data from the table in a streaming manner: "compensationStrategyTier" */
  compensationStrategyTier_stream: Array<CompensationStrategyTier>;
  /** fetch aggregated fields from the table: "compensationStrategy" */
  compensationStrategy_aggregate: CompensationStrategy_Aggregate;
  /** fetch data from the table: "compensationStrategy" using primary key columns */
  compensationStrategy_by_pk?: Maybe<CompensationStrategy>;
  /** fetch data from the table in a streaming manner: "compensationStrategy" */
  compensationStrategy_stream: Array<CompensationStrategy>;
  /** fetch data from the table: "consent" */
  consent: Array<Consent>;
  /** fetch aggregated fields from the table: "consent" */
  consent_aggregate: Consent_Aggregate;
  /** fetch data from the table: "consent" using primary key columns */
  consent_by_pk?: Maybe<Consent>;
  /** fetch data from the table in a streaming manner: "consent" */
  consent_stream: Array<Consent>;
  /** fetch data from the table: "consumable" */
  consumable: Array<Consumable>;
  /** fetch data from the table: "consumableBrand" */
  consumableBrand: Array<ConsumableBrand>;
  /** fetch aggregated fields from the table: "consumableBrand" */
  consumableBrand_aggregate: ConsumableBrand_Aggregate;
  /** fetch data from the table: "consumableBrand" using primary key columns */
  consumableBrand_by_pk?: Maybe<ConsumableBrand>;
  /** fetch data from the table in a streaming manner: "consumableBrand" */
  consumableBrand_stream: Array<ConsumableBrand>;
  /** fetch data from the table: "consumableSubtype" */
  consumableSubtype: Array<ConsumableSubtype>;
  /** fetch aggregated fields from the table: "consumableSubtype" */
  consumableSubtype_aggregate: ConsumableSubtype_Aggregate;
  /** fetch data from the table: "consumableSubtype" using primary key columns */
  consumableSubtype_by_pk?: Maybe<ConsumableSubtype>;
  /** fetch data from the table in a streaming manner: "consumableSubtype" */
  consumableSubtype_stream: Array<ConsumableSubtype>;
  /** fetch data from the table: "consumableType" */
  consumableType: Array<ConsumableType>;
  /** fetch data from the table: "consumableTypeConsumableSubtype" */
  consumableTypeConsumableSubtype: Array<ConsumableTypeConsumableSubtype>;
  /** fetch aggregated fields from the table: "consumableTypeConsumableSubtype" */
  consumableTypeConsumableSubtype_aggregate: ConsumableTypeConsumableSubtype_Aggregate;
  /** fetch data from the table: "consumableTypeConsumableSubtype" using primary key columns */
  consumableTypeConsumableSubtype_by_pk?: Maybe<ConsumableTypeConsumableSubtype>;
  /** fetch data from the table in a streaming manner: "consumableTypeConsumableSubtype" */
  consumableTypeConsumableSubtype_stream: Array<ConsumableTypeConsumableSubtype>;
  /** fetch aggregated fields from the table: "consumableType" */
  consumableType_aggregate: ConsumableType_Aggregate;
  /** fetch data from the table: "consumableType" using primary key columns */
  consumableType_by_pk?: Maybe<ConsumableType>;
  /** fetch data from the table in a streaming manner: "consumableType" */
  consumableType_stream: Array<ConsumableType>;
  /** fetch aggregated fields from the table: "consumable" */
  consumable_aggregate: Consumable_Aggregate;
  /** fetch data from the table: "consumable" using primary key columns */
  consumable_by_pk?: Maybe<Consumable>;
  /** fetch data from the table in a streaming manner: "consumable" */
  consumable_stream: Array<Consumable>;
  /** fetch data from the table: "coupon" */
  coupon: Array<Coupon>;
  /** fetch data from the table: "couponConfiguration" */
  couponConfiguration: Array<CouponConfiguration>;
  /** fetch aggregated fields from the table: "couponConfiguration" */
  couponConfiguration_aggregate: CouponConfiguration_Aggregate;
  /** fetch data from the table: "couponConfiguration" using primary key columns */
  couponConfiguration_by_pk?: Maybe<CouponConfiguration>;
  /** fetch data from the table in a streaming manner: "couponConfiguration" */
  couponConfiguration_stream: Array<CouponConfiguration>;
  /** fetch data from the table: "couponScope" */
  couponScope: Array<CouponScope>;
  /** fetch aggregated fields from the table: "couponScope" */
  couponScope_aggregate: CouponScope_Aggregate;
  /** fetch data from the table: "couponScope" using primary key columns */
  couponScope_by_pk?: Maybe<CouponScope>;
  /** fetch data from the table in a streaming manner: "couponScope" */
  couponScope_stream: Array<CouponScope>;
  /** fetch data from the table: "couponType" */
  couponType: Array<CouponType>;
  /** fetch aggregated fields from the table: "couponType" */
  couponType_aggregate: CouponType_Aggregate;
  /** fetch data from the table: "couponType" using primary key columns */
  couponType_by_pk?: Maybe<CouponType>;
  /** fetch data from the table in a streaming manner: "couponType" */
  couponType_stream: Array<CouponType>;
  /** fetch aggregated fields from the table: "coupon" */
  coupon_aggregate: Coupon_Aggregate;
  /** fetch data from the table: "coupon" using primary key columns */
  coupon_by_pk?: Maybe<Coupon>;
  /** fetch data from the table in a streaming manner: "coupon" */
  coupon_stream: Array<Coupon>;
  /** fetch data from the table: "customForm" */
  customForm: Array<CustomForm>;
  /** fetch data from the table: "customFormField" */
  customFormField: Array<CustomFormField>;
  /** fetch data from the table: "customFormFieldCategory" */
  customFormFieldCategory: Array<CustomFormFieldCategory>;
  /** fetch aggregated fields from the table: "customFormFieldCategory" */
  customFormFieldCategory_aggregate: CustomFormFieldCategory_Aggregate;
  /** fetch data from the table: "customFormFieldCategory" using primary key columns */
  customFormFieldCategory_by_pk?: Maybe<CustomFormFieldCategory>;
  /** fetch data from the table in a streaming manner: "customFormFieldCategory" */
  customFormFieldCategory_stream: Array<CustomFormFieldCategory>;
  /** fetch data from the table: "customFormFieldResponse" */
  customFormFieldResponse: Array<CustomFormFieldResponse>;
  /** fetch aggregated fields from the table: "customFormFieldResponse" */
  customFormFieldResponse_aggregate: CustomFormFieldResponse_Aggregate;
  /** fetch data from the table: "customFormFieldResponse" using primary key columns */
  customFormFieldResponse_by_pk?: Maybe<CustomFormFieldResponse>;
  /** fetch data from the table in a streaming manner: "customFormFieldResponse" */
  customFormFieldResponse_stream: Array<CustomFormFieldResponse>;
  /** fetch data from the table: "customFormFieldType" */
  customFormFieldType: Array<CustomFormFieldType>;
  /** fetch aggregated fields from the table: "customFormFieldType" */
  customFormFieldType_aggregate: CustomFormFieldType_Aggregate;
  /** fetch data from the table: "customFormFieldType" using primary key columns */
  customFormFieldType_by_pk?: Maybe<CustomFormFieldType>;
  /** fetch data from the table in a streaming manner: "customFormFieldType" */
  customFormFieldType_stream: Array<CustomFormFieldType>;
  /** fetch aggregated fields from the table: "customFormField" */
  customFormField_aggregate: CustomFormField_Aggregate;
  /** fetch data from the table: "customFormField" using primary key columns */
  customFormField_by_pk?: Maybe<CustomFormField>;
  /** fetch data from the table in a streaming manner: "customFormField" */
  customFormField_stream: Array<CustomFormField>;
  /** fetch data from the table: "customFormOption" */
  customFormOption: Array<CustomFormOption>;
  /** fetch aggregated fields from the table: "customFormOption" */
  customFormOption_aggregate: CustomFormOption_Aggregate;
  /** fetch data from the table: "customFormOption" using primary key columns */
  customFormOption_by_pk?: Maybe<CustomFormOption>;
  /** fetch data from the table in a streaming manner: "customFormOption" */
  customFormOption_stream: Array<CustomFormOption>;
  /** fetch data from the table: "customFormReplicatedField" */
  customFormReplicatedField: Array<CustomFormReplicatedField>;
  /** fetch aggregated fields from the table: "customFormReplicatedField" */
  customFormReplicatedField_aggregate: CustomFormReplicatedField_Aggregate;
  /** fetch data from the table: "customFormReplicatedField" using primary key columns */
  customFormReplicatedField_by_pk?: Maybe<CustomFormReplicatedField>;
  /** fetch data from the table in a streaming manner: "customFormReplicatedField" */
  customFormReplicatedField_stream: Array<CustomFormReplicatedField>;
  /** fetch data from the table: "customFormReplicatedRow" */
  customFormReplicatedRow: Array<CustomFormReplicatedRow>;
  /** fetch aggregated fields from the table: "customFormReplicatedRow" */
  customFormReplicatedRow_aggregate: CustomFormReplicatedRow_Aggregate;
  /** fetch data from the table: "customFormReplicatedRow" using primary key columns */
  customFormReplicatedRow_by_pk?: Maybe<CustomFormReplicatedRow>;
  /** fetch data from the table in a streaming manner: "customFormReplicatedRow" */
  customFormReplicatedRow_stream: Array<CustomFormReplicatedRow>;
  /** fetch data from the table: "customFormResponse" */
  customFormResponse: Array<CustomFormResponse>;
  /** fetch aggregated fields from the table: "customFormResponse" */
  customFormResponse_aggregate: CustomFormResponse_Aggregate;
  /** fetch data from the table: "customFormResponse" using primary key columns */
  customFormResponse_by_pk?: Maybe<CustomFormResponse>;
  /** fetch data from the table in a streaming manner: "customFormResponse" */
  customFormResponse_stream: Array<CustomFormResponse>;
  /** fetch data from the table: "customFormRow" */
  customFormRow: Array<CustomFormRow>;
  /** fetch aggregated fields from the table: "customFormRow" */
  customFormRow_aggregate: CustomFormRow_Aggregate;
  /** fetch data from the table: "customFormRow" using primary key columns */
  customFormRow_by_pk?: Maybe<CustomFormRow>;
  /** fetch data from the table in a streaming manner: "customFormRow" */
  customFormRow_stream: Array<CustomFormRow>;
  /** fetch aggregated fields from the table: "customForm" */
  customForm_aggregate: CustomForm_Aggregate;
  /** fetch data from the table: "customForm" using primary key columns */
  customForm_by_pk?: Maybe<CustomForm>;
  /** fetch data from the table in a streaming manner: "customForm" */
  customForm_stream: Array<CustomForm>;
  /** fetch data from the table: "customQuestion" */
  customQuestion: Array<CustomQuestion>;
  /** fetch data from the table: "customQuestionOption" */
  customQuestionOption: Array<CustomQuestionOption>;
  /** fetch aggregated fields from the table: "customQuestionOption" */
  customQuestionOption_aggregate: CustomQuestionOption_Aggregate;
  /** fetch data from the table: "customQuestionOption" using primary key columns */
  customQuestionOption_by_pk?: Maybe<CustomQuestionOption>;
  /** fetch data from the table in a streaming manner: "customQuestionOption" */
  customQuestionOption_stream: Array<CustomQuestionOption>;
  /** fetch aggregated fields from the table: "customQuestion" */
  customQuestion_aggregate: CustomQuestion_Aggregate;
  /** fetch data from the table: "customQuestion" using primary key columns */
  customQuestion_by_pk?: Maybe<CustomQuestion>;
  /** fetch data from the table in a streaming manner: "customQuestion" */
  customQuestion_stream: Array<CustomQuestion>;
  /** fetch data from the table: "degreeDesignation" */
  degreeDesignation: Array<DegreeDesignation>;
  /** fetch aggregated fields from the table: "degreeDesignation" */
  degreeDesignation_aggregate: DegreeDesignation_Aggregate;
  /** fetch data from the table: "degreeDesignation" using primary key columns */
  degreeDesignation_by_pk?: Maybe<DegreeDesignation>;
  /** fetch data from the table in a streaming manner: "degreeDesignation" */
  degreeDesignation_stream: Array<DegreeDesignation>;
  /** fetch data from the table: "depositInvoiceLineItem" */
  depositInvoiceLineItem: Array<DepositInvoiceLineItem>;
  /** fetch aggregated fields from the table: "depositInvoiceLineItem" */
  depositInvoiceLineItem_aggregate: DepositInvoiceLineItem_Aggregate;
  /** fetch data from the table: "depositInvoiceLineItem" using primary key columns */
  depositInvoiceLineItem_by_pk?: Maybe<DepositInvoiceLineItem>;
  /** fetch data from the table in a streaming manner: "depositInvoiceLineItem" */
  depositInvoiceLineItem_stream: Array<DepositInvoiceLineItem>;
  /** fetch data from the table: "device" */
  device: Array<Device>;
  /** fetch data from the table: "deviceType" */
  deviceType: Array<DeviceType>;
  /** fetch aggregated fields from the table: "deviceType" */
  deviceType_aggregate: DeviceType_Aggregate;
  /** fetch data from the table: "deviceType" using primary key columns */
  deviceType_by_pk?: Maybe<DeviceType>;
  /** fetch data from the table in a streaming manner: "deviceType" */
  deviceType_stream: Array<DeviceType>;
  /** fetch aggregated fields from the table: "device" */
  device_aggregate: Device_Aggregate;
  /** fetch data from the table: "device" using primary key columns */
  device_by_pk?: Maybe<Device>;
  /** fetch data from the table in a streaming manner: "device" */
  device_stream: Array<Device>;
  /** fetch data from the table: "discountInvoiceLineItem" */
  discountInvoiceLineItem: Array<DiscountInvoiceLineItem>;
  /** fetch aggregated fields from the table: "discountInvoiceLineItem" */
  discountInvoiceLineItem_aggregate: DiscountInvoiceLineItem_Aggregate;
  /** fetch data from the table: "discountInvoiceLineItem" using primary key columns */
  discountInvoiceLineItem_by_pk?: Maybe<DiscountInvoiceLineItem>;
  /** fetch data from the table in a streaming manner: "discountInvoiceLineItem" */
  discountInvoiceLineItem_stream: Array<DiscountInvoiceLineItem>;
  /** fetch data from the table: "document" */
  document: Array<Document>;
  /** fetch data from the table: "documentTag" */
  documentTag: Array<DocumentTag>;
  /** fetch aggregated fields from the table: "documentTag" */
  documentTag_aggregate: DocumentTag_Aggregate;
  /** fetch data from the table: "documentTag" using primary key columns */
  documentTag_by_pk?: Maybe<DocumentTag>;
  /** fetch data from the table in a streaming manner: "documentTag" */
  documentTag_stream: Array<DocumentTag>;
  /** fetch aggregated fields from the table: "document" */
  document_aggregate: Document_Aggregate;
  /** fetch data from the table: "document" using primary key columns */
  document_by_pk?: Maybe<Document>;
  /** fetch data from the table in a streaming manner: "document" */
  document_stream: Array<Document>;
  /** fetch data from the table: "emailCampaignLog" */
  emailCampaignLog: Array<EmailCampaignLog>;
  /** fetch aggregated fields from the table: "emailCampaignLog" */
  emailCampaignLog_aggregate: EmailCampaignLog_Aggregate;
  /** fetch data from the table: "emailCampaignLog" using primary key columns */
  emailCampaignLog_by_pk?: Maybe<EmailCampaignLog>;
  /** fetch data from the table in a streaming manner: "emailCampaignLog" */
  emailCampaignLog_stream: Array<EmailCampaignLog>;
  /** fetch data from the table: "emailCampaignMetrics" */
  emailCampaignMetrics: Array<EmailCampaignMetrics>;
  /** fetch aggregated fields from the table: "emailCampaignMetrics" */
  emailCampaignMetrics_aggregate: EmailCampaignMetrics_Aggregate;
  /** fetch data from the table in a streaming manner: "emailCampaignMetrics" */
  emailCampaignMetrics_stream: Array<EmailCampaignMetrics>;
  /** fetch data from the table: "emailTemplate" */
  emailTemplate: Array<EmailTemplate>;
  /** fetch data from the table: "emailTemplatePatient" */
  emailTemplatePatient: Array<EmailTemplatePatient>;
  /** fetch aggregated fields from the table: "emailTemplatePatient" */
  emailTemplatePatient_aggregate: EmailTemplatePatient_Aggregate;
  /** fetch data from the table: "emailTemplatePatient" using primary key columns */
  emailTemplatePatient_by_pk?: Maybe<EmailTemplatePatient>;
  /** fetch data from the table in a streaming manner: "emailTemplatePatient" */
  emailTemplatePatient_stream: Array<EmailTemplatePatient>;
  /** fetch data from the table: "emailTemplateSendQueue" */
  emailTemplateSendQueue: Array<EmailTemplateSendQueue>;
  /** fetch aggregated fields from the table: "emailTemplateSendQueue" */
  emailTemplateSendQueue_aggregate: EmailTemplateSendQueue_Aggregate;
  /** fetch data from the table: "emailTemplateSendQueue" using primary key columns */
  emailTemplateSendQueue_by_pk?: Maybe<EmailTemplateSendQueue>;
  /** fetch data from the table in a streaming manner: "emailTemplateSendQueue" */
  emailTemplateSendQueue_stream: Array<EmailTemplateSendQueue>;
  /** fetch aggregated fields from the table: "emailTemplate" */
  emailTemplate_aggregate: EmailTemplate_Aggregate;
  /** fetch data from the table: "emailTemplate" using primary key columns */
  emailTemplate_by_pk?: Maybe<EmailTemplate>;
  /** fetch data from the table in a streaming manner: "emailTemplate" */
  emailTemplate_stream: Array<EmailTemplate>;
  /** fetch data from the table: "externalLocation" */
  externalLocation: Array<ExternalLocation>;
  /** fetch aggregated fields from the table: "externalLocation" */
  externalLocation_aggregate: ExternalLocation_Aggregate;
  /** fetch data from the table: "externalLocation" using primary key columns */
  externalLocation_by_pk?: Maybe<ExternalLocation>;
  /** fetch data from the table in a streaming manner: "externalLocation" */
  externalLocation_stream: Array<ExternalLocation>;
  /** fetch data from the table: "externalProvider" */
  externalProvider: Array<ExternalProvider>;
  /** fetch aggregated fields from the table: "externalProvider" */
  externalProvider_aggregate: ExternalProvider_Aggregate;
  /** fetch data from the table: "externalProvider" using primary key columns */
  externalProvider_by_pk?: Maybe<ExternalProvider>;
  /** fetch data from the table in a streaming manner: "externalProvider" */
  externalProvider_stream: Array<ExternalProvider>;
  /** fetch data from the table: "externalReview" */
  externalReview: Array<ExternalReview>;
  /** fetch aggregated fields from the table: "externalReview" */
  externalReview_aggregate: ExternalReview_Aggregate;
  /** fetch data from the table: "externalReview" using primary key columns */
  externalReview_by_pk?: Maybe<ExternalReview>;
  /** fetch data from the table in a streaming manner: "externalReview" */
  externalReview_stream: Array<ExternalReview>;
  /** fetch data from the table: "fee" */
  fee: Array<Fee>;
  /** fetch data from the table: "feeInvoiceLineItem" */
  feeInvoiceLineItem: Array<FeeInvoiceLineItem>;
  /** fetch aggregated fields from the table: "feeInvoiceLineItem" */
  feeInvoiceLineItem_aggregate: FeeInvoiceLineItem_Aggregate;
  /** fetch data from the table: "feeInvoiceLineItem" using primary key columns */
  feeInvoiceLineItem_by_pk?: Maybe<FeeInvoiceLineItem>;
  /** fetch data from the table in a streaming manner: "feeInvoiceLineItem" */
  feeInvoiceLineItem_stream: Array<FeeInvoiceLineItem>;
  /** fetch aggregated fields from the table: "fee" */
  fee_aggregate: Fee_Aggregate;
  /** fetch data from the table: "fee" using primary key columns */
  fee_by_pk?: Maybe<Fee>;
  /** fetch data from the table in a streaming manner: "fee" */
  fee_stream: Array<Fee>;
  /** fetch data from the table: "finixTerminal" */
  finixTerminal: Array<FinixTerminal>;
  /** fetch aggregated fields from the table: "finixTerminal" */
  finixTerminal_aggregate: FinixTerminal_Aggregate;
  /** fetch data from the table: "finixTerminal" using primary key columns */
  finixTerminal_by_pk?: Maybe<FinixTerminal>;
  /** fetch data from the table in a streaming manner: "finixTerminal" */
  finixTerminal_stream: Array<FinixTerminal>;
  /** fetch data from the table: "flowsheet" */
  flowsheet: Array<Flowsheet>;
  /** fetch data from the table: "flowsheetParameter" */
  flowsheetParameter: Array<FlowsheetParameter>;
  /** fetch data from the table: "flowsheetParameterFlowsheet" */
  flowsheetParameterFlowsheet: Array<FlowsheetParameterFlowsheet>;
  /** fetch aggregated fields from the table: "flowsheetParameterFlowsheet" */
  flowsheetParameterFlowsheet_aggregate: FlowsheetParameterFlowsheet_Aggregate;
  /** fetch data from the table: "flowsheetParameterFlowsheet" using primary key columns */
  flowsheetParameterFlowsheet_by_pk?: Maybe<FlowsheetParameterFlowsheet>;
  /** fetch data from the table in a streaming manner: "flowsheetParameterFlowsheet" */
  flowsheetParameterFlowsheet_stream: Array<FlowsheetParameterFlowsheet>;
  /** fetch data from the table: "flowsheetParameterGroup" */
  flowsheetParameterGroup: Array<FlowsheetParameterGroup>;
  /** fetch aggregated fields from the table: "flowsheetParameterGroup" */
  flowsheetParameterGroup_aggregate: FlowsheetParameterGroup_Aggregate;
  /** fetch data from the table: "flowsheetParameterGroup" using primary key columns */
  flowsheetParameterGroup_by_pk?: Maybe<FlowsheetParameterGroup>;
  /** fetch data from the table in a streaming manner: "flowsheetParameterGroup" */
  flowsheetParameterGroup_stream: Array<FlowsheetParameterGroup>;
  /** fetch aggregated fields from the table: "flowsheetParameter" */
  flowsheetParameter_aggregate: FlowsheetParameter_Aggregate;
  /** fetch data from the table: "flowsheetParameter" using primary key columns */
  flowsheetParameter_by_pk?: Maybe<FlowsheetParameter>;
  /** fetch data from the table in a streaming manner: "flowsheetParameter" */
  flowsheetParameter_stream: Array<FlowsheetParameter>;
  /** fetch aggregated fields from the table: "flowsheet" */
  flowsheet_aggregate: Flowsheet_Aggregate;
  /** fetch data from the table: "flowsheet" using primary key columns */
  flowsheet_by_pk?: Maybe<Flowsheet>;
  /** fetch data from the table in a streaming manner: "flowsheet" */
  flowsheet_stream: Array<Flowsheet>;
  /** fetch data from the table: "genericInvoiceLineItem" */
  genericInvoiceLineItem: Array<GenericInvoiceLineItem>;
  /** fetch data from the table: "genericInvoiceLineItemDiscount" */
  genericInvoiceLineItemDiscount: Array<GenericInvoiceLineItemDiscount>;
  /** fetch aggregated fields from the table: "genericInvoiceLineItemDiscount" */
  genericInvoiceLineItemDiscount_aggregate: GenericInvoiceLineItemDiscount_Aggregate;
  /** fetch data from the table: "genericInvoiceLineItemDiscount" using primary key columns */
  genericInvoiceLineItemDiscount_by_pk?: Maybe<GenericInvoiceLineItemDiscount>;
  /** fetch data from the table in a streaming manner: "genericInvoiceLineItemDiscount" */
  genericInvoiceLineItemDiscount_stream: Array<GenericInvoiceLineItemDiscount>;
  /** fetch aggregated fields from the table: "genericInvoiceLineItem" */
  genericInvoiceLineItem_aggregate: GenericInvoiceLineItem_Aggregate;
  /** fetch data from the table: "genericInvoiceLineItem" using primary key columns */
  genericInvoiceLineItem_by_pk?: Maybe<GenericInvoiceLineItem>;
  /** fetch data from the table in a streaming manner: "genericInvoiceLineItem" */
  genericInvoiceLineItem_stream: Array<GenericInvoiceLineItem>;
  /** fetch data from the table: "giftCard" */
  giftCard: Array<GiftCard>;
  /** fetch aggregated fields from the table: "giftCard" */
  giftCard_aggregate: GiftCard_Aggregate;
  /** fetch data from the table: "giftCard" using primary key columns */
  giftCard_by_pk?: Maybe<GiftCard>;
  /** fetch data from the table in a streaming manner: "giftCard" */
  giftCard_stream: Array<GiftCard>;
  /** fetch data from the table: "googleAccount" */
  googleAccount: Array<GoogleAccount>;
  /** fetch aggregated fields from the table: "googleAccount" */
  googleAccount_aggregate: GoogleAccount_Aggregate;
  /** fetch data from the table: "googleAccount" using primary key columns */
  googleAccount_by_pk?: Maybe<GoogleAccount>;
  /** fetch data from the table in a streaming manner: "googleAccount" */
  googleAccount_stream: Array<GoogleAccount>;
  /** fetch data from the table: "googleLocation" */
  googleLocation: Array<GoogleLocation>;
  /** fetch aggregated fields from the table: "googleLocation" */
  googleLocation_aggregate: GoogleLocation_Aggregate;
  /** fetch data from the table: "googleLocation" using primary key columns */
  googleLocation_by_pk?: Maybe<GoogleLocation>;
  /** fetch data from the table in a streaming manner: "googleLocation" */
  googleLocation_stream: Array<GoogleLocation>;
  /** fetch data from the table: "googleReview" */
  googleReview: Array<GoogleReview>;
  /** fetch aggregated fields from the table: "googleReview" */
  googleReview_aggregate: GoogleReview_Aggregate;
  /** fetch data from the table: "googleReview" using primary key columns */
  googleReview_by_pk?: Maybe<GoogleReview>;
  /** fetch data from the table in a streaming manner: "googleReview" */
  googleReview_stream: Array<GoogleReview>;
  /** fetch data from the table: "importJob" */
  importJob: Array<ImportJob>;
  /** fetch aggregated fields from the table: "importJob" */
  importJob_aggregate: ImportJob_Aggregate;
  /** fetch data from the table: "importJob" using primary key columns */
  importJob_by_pk?: Maybe<ImportJob>;
  /** fetch data from the table in a streaming manner: "importJob" */
  importJob_stream: Array<ImportJob>;
  /** fetch data from the table: "importMetadata" */
  importMetadata: Array<ImportMetadata>;
  /** fetch aggregated fields from the table: "importMetadata" */
  importMetadata_aggregate: ImportMetadata_Aggregate;
  /** fetch data from the table: "importMetadata" using primary key columns */
  importMetadata_by_pk?: Maybe<ImportMetadata>;
  /** fetch data from the table in a streaming manner: "importMetadata" */
  importMetadata_stream: Array<ImportMetadata>;
  /** An array relationship */
  incompatibleServices: Array<IncompatibleServices>;
  /** An aggregate relationship */
  incompatibleServices_aggregate: IncompatibleServices_Aggregate;
  /** fetch data from the table: "incompatibleServices" using primary key columns */
  incompatibleServices_by_pk?: Maybe<IncompatibleServices>;
  /** fetch data from the table in a streaming manner: "incompatibleServices" */
  incompatibleServices_stream: Array<IncompatibleServices>;
  /** fetch data from the table: "internalAppointmentProvider" */
  internalAppointmentProvider: Array<InternalAppointmentProvider>;
  /** fetch aggregated fields from the table: "internalAppointmentProvider" */
  internalAppointmentProvider_aggregate: InternalAppointmentProvider_Aggregate;
  /** fetch data from the table: "internalAppointmentProvider" using primary key columns */
  internalAppointmentProvider_by_pk?: Maybe<InternalAppointmentProvider>;
  /** fetch data from the table in a streaming manner: "internalAppointmentProvider" */
  internalAppointmentProvider_stream: Array<InternalAppointmentProvider>;
  /** fetch data from the table: "inventoryTransaction" */
  inventoryTransaction: Array<InventoryTransaction>;
  /** fetch aggregated fields from the table: "inventoryTransaction" */
  inventoryTransaction_aggregate: InventoryTransaction_Aggregate;
  /** fetch data from the table: "inventoryTransaction" using primary key columns */
  inventoryTransaction_by_pk?: Maybe<InventoryTransaction>;
  /** fetch data from the table in a streaming manner: "inventoryTransaction" */
  inventoryTransaction_stream: Array<InventoryTransaction>;
  /** fetch data from the table: "invoice" */
  invoice: Array<Invoice>;
  /** fetch data from the table: "invoiceConfiguration" */
  invoiceConfiguration: Array<InvoiceConfiguration>;
  /** fetch aggregated fields from the table: "invoiceConfiguration" */
  invoiceConfiguration_aggregate: InvoiceConfiguration_Aggregate;
  /** fetch data from the table: "invoiceConfiguration" using primary key columns */
  invoiceConfiguration_by_pk?: Maybe<InvoiceConfiguration>;
  /** fetch data from the table in a streaming manner: "invoiceConfiguration" */
  invoiceConfiguration_stream: Array<InvoiceConfiguration>;
  /** fetch data from the table: "invoiceCoupon" */
  invoiceCoupon: Array<InvoiceCoupon>;
  /** fetch aggregated fields from the table: "invoiceCoupon" */
  invoiceCoupon_aggregate: InvoiceCoupon_Aggregate;
  /** fetch data from the table: "invoiceCoupon" using primary key columns */
  invoiceCoupon_by_pk?: Maybe<InvoiceCoupon>;
  /** fetch data from the table in a streaming manner: "invoiceCoupon" */
  invoiceCoupon_stream: Array<InvoiceCoupon>;
  /** fetch data from the table: "invoiceMembership" */
  invoiceMembership: Array<InvoiceMembership>;
  /** fetch aggregated fields from the table: "invoiceMembership" */
  invoiceMembership_aggregate: InvoiceMembership_Aggregate;
  /** fetch data from the table: "invoiceMembership" using primary key columns */
  invoiceMembership_by_pk?: Maybe<InvoiceMembership>;
  /** fetch data from the table in a streaming manner: "invoiceMembership" */
  invoiceMembership_stream: Array<InvoiceMembership>;
  /** fetch data from the table: "invoicePackage" */
  invoicePackage: Array<InvoicePackage>;
  /** fetch aggregated fields from the table: "invoicePackage" */
  invoicePackage_aggregate: InvoicePackage_Aggregate;
  /** fetch data from the table: "invoicePackage" using primary key columns */
  invoicePackage_by_pk?: Maybe<InvoicePackage>;
  /** fetch data from the table in a streaming manner: "invoicePackage" */
  invoicePackage_stream: Array<InvoicePackage>;
  /** fetch data from the table: "invoiceProductItem" */
  invoiceProductItem: Array<InvoiceProductItem>;
  /** fetch aggregated fields from the table: "invoiceProductItem" */
  invoiceProductItem_aggregate: InvoiceProductItem_Aggregate;
  /** fetch data from the table: "invoiceProductItem" using primary key columns */
  invoiceProductItem_by_pk?: Maybe<InvoiceProductItem>;
  /** fetch data from the table in a streaming manner: "invoiceProductItem" */
  invoiceProductItem_stream: Array<InvoiceProductItem>;
  /** fetch data from the table: "invoiceServiceConsumable" */
  invoiceServiceConsumable: Array<InvoiceServiceConsumable>;
  /** fetch aggregated fields from the table: "invoiceServiceConsumable" */
  invoiceServiceConsumable_aggregate: InvoiceServiceConsumable_Aggregate;
  /** fetch data from the table: "invoiceServiceConsumable" using primary key columns */
  invoiceServiceConsumable_by_pk?: Maybe<InvoiceServiceConsumable>;
  /** fetch data from the table in a streaming manner: "invoiceServiceConsumable" */
  invoiceServiceConsumable_stream: Array<InvoiceServiceConsumable>;
  /** fetch data from the table: "invoiceServiceItem" */
  invoiceServiceItem: Array<InvoiceServiceItem>;
  /** fetch aggregated fields from the table: "invoiceServiceItem" */
  invoiceServiceItem_aggregate: InvoiceServiceItem_Aggregate;
  /** fetch data from the table: "invoiceServiceItem" using primary key columns */
  invoiceServiceItem_by_pk?: Maybe<InvoiceServiceItem>;
  /** fetch data from the table in a streaming manner: "invoiceServiceItem" */
  invoiceServiceItem_stream: Array<InvoiceServiceItem>;
  /** fetch data from the table: "invoiceTemplate" */
  invoiceTemplate: Array<InvoiceTemplate>;
  /** fetch aggregated fields from the table: "invoiceTemplate" */
  invoiceTemplate_aggregate: InvoiceTemplate_Aggregate;
  /** fetch data from the table: "invoiceTemplate" using primary key columns */
  invoiceTemplate_by_pk?: Maybe<InvoiceTemplate>;
  /** fetch data from the table in a streaming manner: "invoiceTemplate" */
  invoiceTemplate_stream: Array<InvoiceTemplate>;
  /** fetch aggregated fields from the table: "invoice" */
  invoice_aggregate: Invoice_Aggregate;
  /** fetch data from the table: "invoice" using primary key columns */
  invoice_by_pk?: Maybe<Invoice>;
  /** fetch data from the table in a streaming manner: "invoice" */
  invoice_stream: Array<Invoice>;
  /** fetch data from the table: "job" */
  job: Array<Job>;
  /** fetch data from the table: "jobStatus" */
  jobStatus: Array<JobStatus>;
  /** fetch aggregated fields from the table: "jobStatus" */
  jobStatus_aggregate: JobStatus_Aggregate;
  /** fetch data from the table: "jobStatus" using primary key columns */
  jobStatus_by_pk?: Maybe<JobStatus>;
  /** fetch data from the table in a streaming manner: "jobStatus" */
  jobStatus_stream: Array<JobStatus>;
  /** fetch aggregated fields from the table: "job" */
  job_aggregate: Job_Aggregate;
  /** fetch data from the table: "job" using primary key columns */
  job_by_pk?: Maybe<Job>;
  /** fetch data from the table in a streaming manner: "job" */
  job_stream: Array<Job>;
  /** fetch data from the table: "labResult" */
  labResult: Array<LabResult>;
  /** fetch aggregated fields from the table: "labResult" */
  labResult_aggregate: LabResult_Aggregate;
  /** fetch data from the table: "labResult" using primary key columns */
  labResult_by_pk?: Maybe<LabResult>;
  /** fetch data from the table in a streaming manner: "labResult" */
  labResult_stream: Array<LabResult>;
  /** fetch data from the table: "leadForm" */
  leadForm: Array<LeadForm>;
  /** fetch data from the table: "leadFormEntry" */
  leadFormEntry: Array<LeadFormEntry>;
  /** fetch data from the table: "leadFormEntryTag" */
  leadFormEntryTag: Array<LeadFormEntryTag>;
  /** fetch aggregated fields from the table: "leadFormEntryTag" */
  leadFormEntryTag_aggregate: LeadFormEntryTag_Aggregate;
  /** fetch data from the table: "leadFormEntryTag" using primary key columns */
  leadFormEntryTag_by_pk?: Maybe<LeadFormEntryTag>;
  /** fetch data from the table in a streaming manner: "leadFormEntryTag" */
  leadFormEntryTag_stream: Array<LeadFormEntryTag>;
  /** fetch aggregated fields from the table: "leadFormEntry" */
  leadFormEntry_aggregate: LeadFormEntry_Aggregate;
  /** fetch data from the table: "leadFormEntry" using primary key columns */
  leadFormEntry_by_pk?: Maybe<LeadFormEntry>;
  /** fetch data from the table in a streaming manner: "leadFormEntry" */
  leadFormEntry_stream: Array<LeadFormEntry>;
  /** fetch data from the table: "leadFormField" */
  leadFormField: Array<LeadFormField>;
  /** fetch data from the table: "leadFormFieldValue" */
  leadFormFieldValue: Array<LeadFormFieldValue>;
  /** fetch aggregated fields from the table: "leadFormFieldValue" */
  leadFormFieldValue_aggregate: LeadFormFieldValue_Aggregate;
  /** fetch data from the table: "leadFormFieldValue" using primary key columns */
  leadFormFieldValue_by_pk?: Maybe<LeadFormFieldValue>;
  /** fetch data from the table in a streaming manner: "leadFormFieldValue" */
  leadFormFieldValue_stream: Array<LeadFormFieldValue>;
  /** fetch aggregated fields from the table: "leadFormField" */
  leadFormField_aggregate: LeadFormField_Aggregate;
  /** fetch data from the table: "leadFormField" using primary key columns */
  leadFormField_by_pk?: Maybe<LeadFormField>;
  /** fetch data from the table in a streaming manner: "leadFormField" */
  leadFormField_stream: Array<LeadFormField>;
  /** fetch data from the table: "leadFormLink" */
  leadFormLink: Array<LeadFormLink>;
  /** fetch aggregated fields from the table: "leadFormLink" */
  leadFormLink_aggregate: LeadFormLink_Aggregate;
  /** fetch data from the table: "leadFormLink" using primary key columns */
  leadFormLink_by_pk?: Maybe<LeadFormLink>;
  /** fetch data from the table in a streaming manner: "leadFormLink" */
  leadFormLink_stream: Array<LeadFormLink>;
  /** fetch data from the table: "leadFormStep" */
  leadFormStep: Array<LeadFormStep>;
  /** fetch aggregated fields from the table: "leadFormStep" */
  leadFormStep_aggregate: LeadFormStep_Aggregate;
  /** fetch data from the table: "leadFormStep" using primary key columns */
  leadFormStep_by_pk?: Maybe<LeadFormStep>;
  /** fetch data from the table in a streaming manner: "leadFormStep" */
  leadFormStep_stream: Array<LeadFormStep>;
  /** fetch aggregated fields from the table: "leadForm" */
  leadForm_aggregate: LeadForm_Aggregate;
  /** fetch data from the table: "leadForm" using primary key columns */
  leadForm_by_pk?: Maybe<LeadForm>;
  /** fetch data from the table in a streaming manner: "leadForm" */
  leadForm_stream: Array<LeadForm>;
  /** fetch data from the table: "leadTag" */
  leadTag: Array<LeadTag>;
  /** fetch aggregated fields from the table: "leadTag" */
  leadTag_aggregate: LeadTag_Aggregate;
  /** fetch data from the table: "leadTag" using primary key columns */
  leadTag_by_pk?: Maybe<LeadTag>;
  /** fetch data from the table in a streaming manner: "leadTag" */
  leadTag_stream: Array<LeadTag>;
  /** fetch data from the table: "leadTrackedEvent" */
  leadTrackedEvent: Array<LeadTrackedEvent>;
  /** fetch aggregated fields from the table: "leadTrackedEvent" */
  leadTrackedEvent_aggregate: LeadTrackedEvent_Aggregate;
  /** fetch data from the table: "leadTrackedEvent" using primary key columns */
  leadTrackedEvent_by_pk?: Maybe<LeadTrackedEvent>;
  /** fetch data from the table in a streaming manner: "leadTrackedEvent" */
  leadTrackedEvent_stream: Array<LeadTrackedEvent>;
  /** fetch data from the table: "location" */
  location: Array<Location>;
  /** fetch data from the table: "locationCoupon" */
  locationCoupon: Array<LocationCoupon>;
  /** fetch aggregated fields from the table: "locationCoupon" */
  locationCoupon_aggregate: LocationCoupon_Aggregate;
  /** fetch data from the table: "locationCoupon" using primary key columns */
  locationCoupon_by_pk?: Maybe<LocationCoupon>;
  /** fetch data from the table in a streaming manner: "locationCoupon" */
  locationCoupon_stream: Array<LocationCoupon>;
  /** fetch data from the table: "locationProvider" */
  locationProvider: Array<LocationProvider>;
  /** fetch aggregated fields from the table: "locationProvider" */
  locationProvider_aggregate: LocationProvider_Aggregate;
  /** fetch data from the table: "locationProvider" using primary key columns */
  locationProvider_by_pk?: Maybe<LocationProvider>;
  /** fetch data from the table in a streaming manner: "locationProvider" */
  locationProvider_stream: Array<LocationProvider>;
  /** fetch data from the table: "locationSchedule" */
  locationSchedule: Array<LocationSchedule>;
  /** fetch aggregated fields from the table: "locationSchedule" */
  locationSchedule_aggregate: LocationSchedule_Aggregate;
  /** fetch data from the table: "locationSchedule" using primary key columns */
  locationSchedule_by_pk?: Maybe<LocationSchedule>;
  /** fetch data from the table in a streaming manner: "locationSchedule" */
  locationSchedule_stream: Array<LocationSchedule>;
  /** fetch data from the table: "locationService" */
  locationService: Array<LocationService>;
  /** fetch aggregated fields from the table: "locationService" */
  locationService_aggregate: LocationService_Aggregate;
  /** fetch data from the table: "locationService" using primary key columns */
  locationService_by_pk?: Maybe<LocationService>;
  /** fetch data from the table in a streaming manner: "locationService" */
  locationService_stream: Array<LocationService>;
  /** fetch aggregated fields from the table: "location" */
  location_aggregate: Location_Aggregate;
  /** fetch data from the table: "location" using primary key columns */
  location_by_pk?: Maybe<Location>;
  /** fetch data from the table in a streaming manner: "location" */
  location_stream: Array<Location>;
  /** fetch data from the table: "marketingTag" */
  marketingTag: Array<MarketingTag>;
  /** fetch data from the table: "marketingTagEmail" */
  marketingTagEmail: Array<MarketingTagEmail>;
  /** fetch aggregated fields from the table: "marketingTagEmail" */
  marketingTagEmail_aggregate: MarketingTagEmail_Aggregate;
  /** fetch data from the table: "marketingTagEmail" using primary key columns */
  marketingTagEmail_by_pk?: Maybe<MarketingTagEmail>;
  /** fetch data from the table in a streaming manner: "marketingTagEmail" */
  marketingTagEmail_stream: Array<MarketingTagEmail>;
  /** fetch data from the table: "marketingTagSms" */
  marketingTagSms: Array<MarketingTagSms>;
  /** fetch aggregated fields from the table: "marketingTagSms" */
  marketingTagSms_aggregate: MarketingTagSms_Aggregate;
  /** fetch data from the table: "marketingTagSms" using primary key columns */
  marketingTagSms_by_pk?: Maybe<MarketingTagSms>;
  /** fetch data from the table in a streaming manner: "marketingTagSms" */
  marketingTagSms_stream: Array<MarketingTagSms>;
  /** fetch aggregated fields from the table: "marketingTag" */
  marketingTag_aggregate: MarketingTag_Aggregate;
  /** fetch data from the table: "marketingTag" using primary key columns */
  marketingTag_by_pk?: Maybe<MarketingTag>;
  /** fetch data from the table in a streaming manner: "marketingTag" */
  marketingTag_stream: Array<MarketingTag>;
  /** fetch data from the table: "mediaTag" */
  mediaTag: Array<MediaTag>;
  /** fetch aggregated fields from the table: "mediaTag" */
  mediaTag_aggregate: MediaTag_Aggregate;
  /** fetch data from the table: "mediaTag" using primary key columns */
  mediaTag_by_pk?: Maybe<MediaTag>;
  /** fetch data from the table in a streaming manner: "mediaTag" */
  mediaTag_stream: Array<MediaTag>;
  /** fetch data from the table: "membership" */
  membership: Array<Membership>;
  /** fetch data from the table: "membershipCoupon" */
  membershipCoupon: Array<MembershipCoupon>;
  /** fetch aggregated fields from the table: "membershipCoupon" */
  membershipCoupon_aggregate: MembershipCoupon_Aggregate;
  /** fetch data from the table: "membershipCoupon" using primary key columns */
  membershipCoupon_by_pk?: Maybe<MembershipCoupon>;
  /** fetch data from the table in a streaming manner: "membershipCoupon" */
  membershipCoupon_stream: Array<MembershipCoupon>;
  /** fetch data from the table: "membershipType" */
  membershipType: Array<MembershipType>;
  /** fetch aggregated fields from the table: "membershipType" */
  membershipType_aggregate: MembershipType_Aggregate;
  /** fetch data from the table: "membershipType" using primary key columns */
  membershipType_by_pk?: Maybe<MembershipType>;
  /** fetch data from the table in a streaming manner: "membershipType" */
  membershipType_stream: Array<MembershipType>;
  /** fetch aggregated fields from the table: "membership" */
  membership_aggregate: Membership_Aggregate;
  /** fetch data from the table: "membership" using primary key columns */
  membership_by_pk?: Maybe<Membership>;
  /** fetch data from the table in a streaming manner: "membership" */
  membership_stream: Array<Membership>;
  /** fetch data from the table: "note" */
  note: Array<Note>;
  /** fetch data from the table: "noteSignature" */
  noteSignature: Array<NoteSignature>;
  /** fetch aggregated fields from the table: "noteSignature" */
  noteSignature_aggregate: NoteSignature_Aggregate;
  /** fetch data from the table: "noteSignature" using primary key columns */
  noteSignature_by_pk?: Maybe<NoteSignature>;
  /** fetch data from the table in a streaming manner: "noteSignature" */
  noteSignature_stream: Array<NoteSignature>;
  /** fetch aggregated fields from the table: "note" */
  note_aggregate: Note_Aggregate;
  /** fetch data from the table: "note" using primary key columns */
  note_by_pk?: Maybe<Note>;
  /** fetch data from the table in a streaming manner: "note" */
  note_stream: Array<Note>;
  /** fetch data from the table: "organization" */
  organization: Array<Organization>;
  /** fetch data from the table: "organizationConfiguration" */
  organizationConfiguration: Array<OrganizationConfiguration>;
  /** fetch aggregated fields from the table: "organizationConfiguration" */
  organizationConfiguration_aggregate: OrganizationConfiguration_Aggregate;
  /** fetch data from the table: "organizationConfiguration" using primary key columns */
  organizationConfiguration_by_pk?: Maybe<OrganizationConfiguration>;
  /** fetch data from the table in a streaming manner: "organizationConfiguration" */
  organizationConfiguration_stream: Array<OrganizationConfiguration>;
  /** fetch aggregated fields from the table: "organization" */
  organization_aggregate: Organization_Aggregate;
  /** fetch data from the table: "organization" using primary key columns */
  organization_by_pk?: Maybe<Organization>;
  /** fetch data from the table in a streaming manner: "organization" */
  organization_stream: Array<Organization>;
  /** fetch data from the table: "package" */
  package: Array<Package>;
  /** fetch aggregated fields from the table: "package" */
  package_aggregate: Package_Aggregate;
  /** fetch data from the table: "package" using primary key columns */
  package_by_pk?: Maybe<Package>;
  /** fetch data from the table in a streaming manner: "package" */
  package_stream: Array<Package>;
  /** fetch data from the table: "patient" */
  patient: Array<Patient>;
  /** fetch data from the table: "patientAddress" */
  patientAddress: Array<PatientAddress>;
  /** fetch aggregated fields from the table: "patientAddress" */
  patientAddress_aggregate: PatientAddress_Aggregate;
  /** fetch data from the table: "patientAddress" using primary key columns */
  patientAddress_by_pk?: Maybe<PatientAddress>;
  /** fetch data from the table in a streaming manner: "patientAddress" */
  patientAddress_stream: Array<PatientAddress>;
  /** fetch data from the table: "patientConsent" */
  patientConsent: Array<PatientConsent>;
  /** fetch aggregated fields from the table: "patientConsent" */
  patientConsent_aggregate: PatientConsent_Aggregate;
  /** fetch data from the table: "patientConsent" using primary key columns */
  patientConsent_by_pk?: Maybe<PatientConsent>;
  /** fetch data from the table in a streaming manner: "patientConsent" */
  patientConsent_stream: Array<PatientConsent>;
  /** fetch data from the table: "patientConversation" */
  patientConversation: Array<PatientConversation>;
  /** fetch aggregated fields from the table: "patientConversation" */
  patientConversation_aggregate: PatientConversation_Aggregate;
  /** fetch data from the table: "patientConversation" using primary key columns */
  patientConversation_by_pk?: Maybe<PatientConversation>;
  /** fetch data from the table in a streaming manner: "patientConversation" */
  patientConversation_stream: Array<PatientConversation>;
  /** fetch data from the table: "patientFlowsheetData" */
  patientFlowsheetData: Array<PatientFlowsheetData>;
  /** fetch aggregated fields from the table: "patientFlowsheetData" */
  patientFlowsheetData_aggregate: PatientFlowsheetData_Aggregate;
  /** fetch data from the table: "patientFlowsheetData" using primary key columns */
  patientFlowsheetData_by_pk?: Maybe<PatientFlowsheetData>;
  /** fetch data from the table in a streaming manner: "patientFlowsheetData" */
  patientFlowsheetData_stream: Array<PatientFlowsheetData>;
  /** fetch data from the table: "patientFlowsheetRow" */
  patientFlowsheetRow: Array<PatientFlowsheetRow>;
  /** fetch aggregated fields from the table: "patientFlowsheetRow" */
  patientFlowsheetRow_aggregate: PatientFlowsheetRow_Aggregate;
  /** fetch data from the table: "patientFlowsheetRow" using primary key columns */
  patientFlowsheetRow_by_pk?: Maybe<PatientFlowsheetRow>;
  /** fetch data from the table in a streaming manner: "patientFlowsheetRow" */
  patientFlowsheetRow_stream: Array<PatientFlowsheetRow>;
  /** fetch data from the table: "patientInvite" */
  patientInvite: Array<PatientInvite>;
  /** fetch aggregated fields from the table: "patientInvite" */
  patientInvite_aggregate: PatientInvite_Aggregate;
  /** fetch data from the table: "patientInvite" using primary key columns */
  patientInvite_by_pk?: Maybe<PatientInvite>;
  /** fetch data from the table in a streaming manner: "patientInvite" */
  patientInvite_stream: Array<PatientInvite>;
  /** fetch data from the table: "patientMembership" */
  patientMembership: Array<PatientMembership>;
  /** fetch aggregated fields from the table: "patientMembership" */
  patientMembership_aggregate: PatientMembership_Aggregate;
  /** fetch data from the table: "patientMembership" using primary key columns */
  patientMembership_by_pk?: Maybe<PatientMembership>;
  /** fetch data from the table in a streaming manner: "patientMembership" */
  patientMembership_stream: Array<PatientMembership>;
  /** fetch data from the table: "patientSystemNotice" */
  patientSystemNotice: Array<PatientSystemNotice>;
  /** fetch aggregated fields from the table: "patientSystemNotice" */
  patientSystemNotice_aggregate: PatientSystemNotice_Aggregate;
  /** fetch data from the table: "patientSystemNotice" using primary key columns */
  patientSystemNotice_by_pk?: Maybe<PatientSystemNotice>;
  /** fetch data from the table in a streaming manner: "patientSystemNotice" */
  patientSystemNotice_stream: Array<PatientSystemNotice>;
  /** fetch data from the table: "patientTag" */
  patientTag: Array<PatientTag>;
  /** fetch aggregated fields from the table: "patientTag" */
  patientTag_aggregate: PatientTag_Aggregate;
  /** fetch data from the table: "patientTag" using primary key columns */
  patientTag_by_pk?: Maybe<PatientTag>;
  /** fetch data from the table in a streaming manner: "patientTag" */
  patientTag_stream: Array<PatientTag>;
  /** An array relationship */
  patientTags: Array<PatientTags>;
  /** An aggregate relationship */
  patientTags_aggregate: PatientTags_Aggregate;
  /** fetch data from the table: "patientTags" using primary key columns */
  patientTags_by_pk?: Maybe<PatientTags>;
  /** fetch data from the table in a streaming manner: "patientTags" */
  patientTags_stream: Array<PatientTags>;
  /** fetch data from the table: "patientWallet" */
  patientWallet: Array<PatientWallet>;
  /** fetch data from the table: "patientWalletCoupon" */
  patientWalletCoupon: Array<PatientWalletCoupon>;
  /** fetch aggregated fields from the table: "patientWalletCoupon" */
  patientWalletCoupon_aggregate: PatientWalletCoupon_Aggregate;
  /** fetch data from the table: "patientWalletCoupon" using primary key columns */
  patientWalletCoupon_by_pk?: Maybe<PatientWalletCoupon>;
  /** fetch data from the table in a streaming manner: "patientWalletCoupon" */
  patientWalletCoupon_stream: Array<PatientWalletCoupon>;
  /** fetch data from the table: "patientWalletTransaction" */
  patientWalletTransaction: Array<PatientWalletTransaction>;
  /** fetch aggregated fields from the table: "patientWalletTransaction" */
  patientWalletTransaction_aggregate: PatientWalletTransaction_Aggregate;
  /** fetch data from the table: "patientWalletTransaction" using primary key columns */
  patientWalletTransaction_by_pk?: Maybe<PatientWalletTransaction>;
  /** fetch data from the table in a streaming manner: "patientWalletTransaction" */
  patientWalletTransaction_stream: Array<PatientWalletTransaction>;
  /** fetch aggregated fields from the table: "patientWallet" */
  patientWallet_aggregate: PatientWallet_Aggregate;
  /** fetch data from the table: "patientWallet" using primary key columns */
  patientWallet_by_pk?: Maybe<PatientWallet>;
  /** fetch data from the table in a streaming manner: "patientWallet" */
  patientWallet_stream: Array<PatientWallet>;
  /** fetch data from the table: "patientWorkspace" */
  patientWorkspace: Array<PatientWorkspace>;
  /** fetch aggregated fields from the table: "patientWorkspace" */
  patientWorkspace_aggregate: PatientWorkspace_Aggregate;
  /** fetch data from the table: "patientWorkspace" using primary key columns */
  patientWorkspace_by_pk?: Maybe<PatientWorkspace>;
  /** fetch data from the table in a streaming manner: "patientWorkspace" */
  patientWorkspace_stream: Array<PatientWorkspace>;
  /** fetch aggregated fields from the table: "patient" */
  patient_aggregate: Patient_Aggregate;
  /** fetch data from the table: "patient" using primary key columns */
  patient_by_pk?: Maybe<Patient>;
  /** fetch data from the table in a streaming manner: "patient" */
  patient_stream: Array<Patient>;
  /** fetch data from the table: "payment" */
  payment: Array<Payment>;
  /** fetch data from the table: "paymentMethod" */
  paymentMethod: Array<PaymentMethod>;
  /** fetch aggregated fields from the table: "paymentMethod" */
  paymentMethod_aggregate: PaymentMethod_Aggregate;
  /** fetch data from the table: "paymentMethod" using primary key columns */
  paymentMethod_by_pk?: Maybe<PaymentMethod>;
  /** fetch data from the table in a streaming manner: "paymentMethod" */
  paymentMethod_stream: Array<PaymentMethod>;
  /** fetch data from the table: "paymentRelationship" */
  paymentRelationship: Array<PaymentRelationship>;
  /** fetch aggregated fields from the table: "paymentRelationship" */
  paymentRelationship_aggregate: PaymentRelationship_Aggregate;
  /** fetch data from the table: "paymentRelationship" using primary key columns */
  paymentRelationship_by_pk?: Maybe<PaymentRelationship>;
  /** fetch data from the table in a streaming manner: "paymentRelationship" */
  paymentRelationship_stream: Array<PaymentRelationship>;
  /** fetch aggregated fields from the table: "payment" */
  payment_aggregate: Payment_Aggregate;
  /** fetch data from the table: "payment" using primary key columns */
  payment_by_pk?: Maybe<Payment>;
  /** fetch data from the table in a streaming manner: "payment" */
  payment_stream: Array<Payment>;
  /** fetch data from the table: "payrixTerminal" */
  payrixTerminal: Array<PayrixTerminal>;
  /** fetch data from the table: "payrixTerminalTransaction" */
  payrixTerminalTransaction: Array<PayrixTerminalTransaction>;
  /** fetch aggregated fields from the table: "payrixTerminalTransaction" */
  payrixTerminalTransaction_aggregate: PayrixTerminalTransaction_Aggregate;
  /** fetch data from the table: "payrixTerminalTransaction" using primary key columns */
  payrixTerminalTransaction_by_pk?: Maybe<PayrixTerminalTransaction>;
  /** fetch data from the table in a streaming manner: "payrixTerminalTransaction" */
  payrixTerminalTransaction_stream: Array<PayrixTerminalTransaction>;
  /** fetch aggregated fields from the table: "payrixTerminal" */
  payrixTerminal_aggregate: PayrixTerminal_Aggregate;
  /** fetch data from the table: "payrixTerminal" using primary key columns */
  payrixTerminal_by_pk?: Maybe<PayrixTerminal>;
  /** fetch data from the table in a streaming manner: "payrixTerminal" */
  payrixTerminal_stream: Array<PayrixTerminal>;
  /** fetch data from the table: "payrixWebAlert" */
  payrixWebAlert: Array<PayrixWebAlert>;
  /** fetch aggregated fields from the table: "payrixWebAlert" */
  payrixWebAlert_aggregate: PayrixWebAlert_Aggregate;
  /** fetch data from the table: "payrixWebAlert" using primary key columns */
  payrixWebAlert_by_pk?: Maybe<PayrixWebAlert>;
  /** fetch data from the table in a streaming manner: "payrixWebAlert" */
  payrixWebAlert_stream: Array<PayrixWebAlert>;
  /** fetch data from the table: "photo" */
  photo: Array<Photo>;
  /** fetch data from the table: "photoMediaTag" */
  photoMediaTag: Array<PhotoMediaTag>;
  /** fetch aggregated fields from the table: "photoMediaTag" */
  photoMediaTag_aggregate: PhotoMediaTag_Aggregate;
  /** fetch data from the table: "photoMediaTag" using primary key columns */
  photoMediaTag_by_pk?: Maybe<PhotoMediaTag>;
  /** fetch data from the table in a streaming manner: "photoMediaTag" */
  photoMediaTag_stream: Array<PhotoMediaTag>;
  /** fetch aggregated fields from the table: "photo" */
  photo_aggregate: Photo_Aggregate;
  /** fetch data from the table: "photo" using primary key columns */
  photo_by_pk?: Maybe<Photo>;
  /** fetch data from the table in a streaming manner: "photo" */
  photo_stream: Array<Photo>;
  /** fetch data from the table: "pin" */
  pin: Array<Pin>;
  /** fetch aggregated fields from the table: "pin" */
  pin_aggregate: Pin_Aggregate;
  /** fetch data from the table: "pin" using primary key columns */
  pin_by_pk?: Maybe<Pin>;
  /** fetch data from the table in a streaming manner: "pin" */
  pin_stream: Array<Pin>;
  /** fetch data from the table: "postStatus" */
  postStatus: Array<PostStatus>;
  /** fetch aggregated fields from the table: "postStatus" */
  postStatus_aggregate: PostStatus_Aggregate;
  /** fetch data from the table: "postStatus" using primary key columns */
  postStatus_by_pk?: Maybe<PostStatus>;
  /** fetch data from the table in a streaming manner: "postStatus" */
  postStatus_stream: Array<PostStatus>;
  /** fetch data from the table: "prescription" */
  prescription: Array<Prescription>;
  /** fetch data from the table: "prescriptionDetail" */
  prescriptionDetail: Array<PrescriptionDetail>;
  /** fetch aggregated fields from the table: "prescriptionDetail" */
  prescriptionDetail_aggregate: PrescriptionDetail_Aggregate;
  /** fetch data from the table: "prescriptionDetail" using primary key columns */
  prescriptionDetail_by_pk?: Maybe<PrescriptionDetail>;
  /** fetch data from the table in a streaming manner: "prescriptionDetail" */
  prescriptionDetail_stream: Array<PrescriptionDetail>;
  /** fetch aggregated fields from the table: "prescription" */
  prescription_aggregate: Prescription_Aggregate;
  /** fetch data from the table: "prescription" using primary key columns */
  prescription_by_pk?: Maybe<Prescription>;
  /** fetch data from the table in a streaming manner: "prescription" */
  prescription_stream: Array<Prescription>;
  /** fetch data from the table: "promotion" */
  promotion: Array<Promotion>;
  /** fetch data from the table: "promotionCoupons" */
  promotionCoupons: Array<PromotionCoupons>;
  /** fetch aggregated fields from the table: "promotionCoupons" */
  promotionCoupons_aggregate: PromotionCoupons_Aggregate;
  /** fetch data from the table: "promotionCoupons" using primary key columns */
  promotionCoupons_by_pk?: Maybe<PromotionCoupons>;
  /** fetch data from the table in a streaming manner: "promotionCoupons" */
  promotionCoupons_stream: Array<PromotionCoupons>;
  /** An array relationship */
  promotionServices: Array<PromotionServices>;
  /** An aggregate relationship */
  promotionServices_aggregate: PromotionServices_Aggregate;
  /** fetch data from the table: "promotionServices" using primary key columns */
  promotionServices_by_pk?: Maybe<PromotionServices>;
  /** fetch data from the table in a streaming manner: "promotionServices" */
  promotionServices_stream: Array<PromotionServices>;
  /** fetch aggregated fields from the table: "promotion" */
  promotion_aggregate: Promotion_Aggregate;
  /** fetch data from the table: "promotion" using primary key columns */
  promotion_by_pk?: Maybe<Promotion>;
  /** fetch data from the table in a streaming manner: "promotion" */
  promotion_stream: Array<Promotion>;
  /** fetch data from the table: "provider" */
  provider: Array<Provider>;
  /** fetch data from the table: "providerSchedule" */
  providerSchedule: Array<ProviderSchedule>;
  /** fetch aggregated fields from the table: "providerSchedule" */
  providerSchedule_aggregate: ProviderSchedule_Aggregate;
  /** fetch data from the table: "providerSchedule" using primary key columns */
  providerSchedule_by_pk?: Maybe<ProviderSchedule>;
  /** fetch data from the table in a streaming manner: "providerSchedule" */
  providerSchedule_stream: Array<ProviderSchedule>;
  /** fetch aggregated fields from the table: "provider" */
  provider_aggregate: Provider_Aggregate;
  /** fetch data from the table: "provider" using primary key columns */
  provider_by_pk?: Maybe<Provider>;
  /** fetch data from the table in a streaming manner: "provider" */
  provider_stream: Array<Provider>;
  /** fetch data from the table: "purchaseOrder" */
  purchaseOrder: Array<PurchaseOrder>;
  /** fetch aggregated fields from the table: "purchaseOrder" */
  purchaseOrder_aggregate: PurchaseOrder_Aggregate;
  /** fetch data from the table: "purchaseOrder" using primary key columns */
  purchaseOrder_by_pk?: Maybe<PurchaseOrder>;
  /** fetch data from the table in a streaming manner: "purchaseOrder" */
  purchaseOrder_stream: Array<PurchaseOrder>;
  /** fetch data from the table: "recurrenceType" */
  recurrenceType: Array<RecurrenceType>;
  /** fetch aggregated fields from the table: "recurrenceType" */
  recurrenceType_aggregate: RecurrenceType_Aggregate;
  /** fetch data from the table: "recurrenceType" using primary key columns */
  recurrenceType_by_pk?: Maybe<RecurrenceType>;
  /** fetch data from the table in a streaming manner: "recurrenceType" */
  recurrenceType_stream: Array<RecurrenceType>;
  /** fetch data from the table: "refund" */
  refund: Array<Refund>;
  /** fetch aggregated fields from the table: "refund" */
  refund_aggregate: Refund_Aggregate;
  /** fetch data from the table: "refund" using primary key columns */
  refund_by_pk?: Maybe<Refund>;
  /** fetch data from the table in a streaming manner: "refund" */
  refund_stream: Array<Refund>;
  /** fetch data from the table: "resolutionStatus" */
  resolutionStatus: Array<ResolutionStatus>;
  /** fetch aggregated fields from the table: "resolutionStatus" */
  resolutionStatus_aggregate: ResolutionStatus_Aggregate;
  /** fetch data from the table: "resolutionStatus" using primary key columns */
  resolutionStatus_by_pk?: Maybe<ResolutionStatus>;
  /** fetch data from the table in a streaming manner: "resolutionStatus" */
  resolutionStatus_stream: Array<ResolutionStatus>;
  /** fetch data from the table: "rewardProgram" */
  rewardProgram: Array<RewardProgram>;
  /** fetch data from the table: "rewardProgramEnrollment" */
  rewardProgramEnrollment: Array<RewardProgramEnrollment>;
  /** fetch aggregated fields from the table: "rewardProgramEnrollment" */
  rewardProgramEnrollment_aggregate: RewardProgramEnrollment_Aggregate;
  /** fetch data from the table: "rewardProgramEnrollment" using primary key columns */
  rewardProgramEnrollment_by_pk?: Maybe<RewardProgramEnrollment>;
  /** fetch data from the table in a streaming manner: "rewardProgramEnrollment" */
  rewardProgramEnrollment_stream: Array<RewardProgramEnrollment>;
  /** fetch aggregated fields from the table: "rewardProgram" */
  rewardProgram_aggregate: RewardProgram_Aggregate;
  /** fetch data from the table: "rewardProgram" using primary key columns */
  rewardProgram_by_pk?: Maybe<RewardProgram>;
  /** fetch data from the table in a streaming manner: "rewardProgram" */
  rewardProgram_stream: Array<RewardProgram>;
  /** fetch data from the table: "room" */
  room: Array<Room>;
  /** fetch aggregated fields from the table: "room" */
  room_aggregate: Room_Aggregate;
  /** fetch data from the table: "room" using primary key columns */
  room_by_pk?: Maybe<Room>;
  /** fetch data from the table in a streaming manner: "room" */
  room_stream: Array<Room>;
  /** fetch data from the table: "rsReview" */
  rsReview: Array<RsReview>;
  /** fetch aggregated fields from the table: "rsReview" */
  rsReview_aggregate: RsReview_Aggregate;
  /** fetch data from the table: "rsReview" using primary key columns */
  rsReview_by_pk?: Maybe<RsReview>;
  /** fetch data from the table in a streaming manner: "rsReview" */
  rsReview_stream: Array<RsReview>;
  /** execute function "searchConsumableTypes" which returns "consumableType" */
  searchConsumableTypes: Array<ConsumableType>;
  /** execute function "searchConsumableTypes" and query aggregates on result of table type "consumableType" */
  searchConsumableTypes_aggregate: ConsumableType_Aggregate;
  /** execute function "searchPatients" which returns "patient" */
  searchPatients: Array<Patient>;
  /** execute function "searchPatients" and query aggregates on result of table type "patient" */
  searchPatients_aggregate: Patient_Aggregate;
  /** execute function "searchProviderSchedule" which returns "providerSchedule" */
  searchProviderSchedule: Array<ProviderSchedule>;
  /** execute function "searchProviderSchedule" and query aggregates on result of table type "providerSchedule" */
  searchProviderSchedule_aggregate: ProviderSchedule_Aggregate;
  /** execute function "search_note_title" which returns "note" */
  search_note_title: Array<Note>;
  /** execute function "search_note_title" and query aggregates on result of table type "note" */
  search_note_title_aggregate: Note_Aggregate;
  /** execute function "search_patientWorkspace" which returns "patient" */
  search_patientWorkspace: Array<Patient>;
  /** execute function "search_patientWorkspace" and query aggregates on result of table type "patient" */
  search_patientWorkspace_aggregate: Patient_Aggregate;
  /** fetch data from the table: "service" */
  service: Array<Service>;
  /** fetch data from the table: "serviceAddOn" */
  serviceAddOn: Array<ServiceAddOn>;
  /** fetch aggregated fields from the table: "serviceAddOn" */
  serviceAddOn_aggregate: ServiceAddOn_Aggregate;
  /** fetch data from the table: "serviceAddOn" using primary key columns */
  serviceAddOn_by_pk?: Maybe<ServiceAddOn>;
  /** fetch data from the table in a streaming manner: "serviceAddOn" */
  serviceAddOn_stream: Array<ServiceAddOn>;
  /** fetch data from the table: "serviceCategory" */
  serviceCategory: Array<ServiceCategory>;
  /** fetch aggregated fields from the table: "serviceCategory" */
  serviceCategory_aggregate: ServiceCategory_Aggregate;
  /** fetch data from the table: "serviceCategory" using primary key columns */
  serviceCategory_by_pk?: Maybe<ServiceCategory>;
  /** fetch data from the table in a streaming manner: "serviceCategory" */
  serviceCategory_stream: Array<ServiceCategory>;
  /** fetch data from the table: "serviceConsent" */
  serviceConsent: Array<ServiceConsent>;
  /** fetch aggregated fields from the table: "serviceConsent" */
  serviceConsent_aggregate: ServiceConsent_Aggregate;
  /** fetch data from the table: "serviceConsent" using primary key columns */
  serviceConsent_by_pk?: Maybe<ServiceConsent>;
  /** fetch data from the table in a streaming manner: "serviceConsent" */
  serviceConsent_stream: Array<ServiceConsent>;
  /** fetch data from the table: "serviceConsumableType" */
  serviceConsumableType: Array<ServiceConsumableType>;
  /** fetch aggregated fields from the table: "serviceConsumableType" */
  serviceConsumableType_aggregate: ServiceConsumableType_Aggregate;
  /** fetch data from the table: "serviceConsumableType" using primary key columns */
  serviceConsumableType_by_pk?: Maybe<ServiceConsumableType>;
  /** fetch data from the table in a streaming manner: "serviceConsumableType" */
  serviceConsumableType_stream: Array<ServiceConsumableType>;
  /** fetch data from the table: "serviceCoupon" */
  serviceCoupon: Array<ServiceCoupon>;
  /** fetch aggregated fields from the table: "serviceCoupon" */
  serviceCoupon_aggregate: ServiceCoupon_Aggregate;
  /** fetch data from the table: "serviceCoupon" using primary key columns */
  serviceCoupon_by_pk?: Maybe<ServiceCoupon>;
  /** fetch data from the table in a streaming manner: "serviceCoupon" */
  serviceCoupon_stream: Array<ServiceCoupon>;
  /** fetch data from the table: "serviceCustomForm" */
  serviceCustomForm: Array<ServiceCustomForm>;
  /** fetch aggregated fields from the table: "serviceCustomForm" */
  serviceCustomForm_aggregate: ServiceCustomForm_Aggregate;
  /** fetch data from the table: "serviceCustomForm" using primary key columns */
  serviceCustomForm_by_pk?: Maybe<ServiceCustomForm>;
  /** fetch data from the table in a streaming manner: "serviceCustomForm" */
  serviceCustomForm_stream: Array<ServiceCustomForm>;
  /** fetch data from the table: "serviceDeviceType" */
  serviceDeviceType: Array<ServiceDeviceType>;
  /** fetch aggregated fields from the table: "serviceDeviceType" */
  serviceDeviceType_aggregate: ServiceDeviceType_Aggregate;
  /** fetch data from the table: "serviceDeviceType" using primary key columns */
  serviceDeviceType_by_pk?: Maybe<ServiceDeviceType>;
  /** fetch data from the table in a streaming manner: "serviceDeviceType" */
  serviceDeviceType_stream: Array<ServiceDeviceType>;
  /** fetch data from the table: "serviceProvider" */
  serviceProvider: Array<ServiceProvider>;
  /** fetch aggregated fields from the table: "serviceProvider" */
  serviceProvider_aggregate: ServiceProvider_Aggregate;
  /** fetch data from the table: "serviceProvider" using primary key columns */
  serviceProvider_by_pk?: Maybe<ServiceProvider>;
  /** fetch data from the table in a streaming manner: "serviceProvider" */
  serviceProvider_stream: Array<ServiceProvider>;
  /** fetch data from the table: "serviceUnit" */
  serviceUnit: Array<ServiceUnit>;
  /** fetch aggregated fields from the table: "serviceUnit" */
  serviceUnit_aggregate: ServiceUnit_Aggregate;
  /** fetch data from the table: "serviceUnit" using primary key columns */
  serviceUnit_by_pk?: Maybe<ServiceUnit>;
  /** fetch data from the table in a streaming manner: "serviceUnit" */
  serviceUnit_stream: Array<ServiceUnit>;
  /** fetch aggregated fields from the table: "service" */
  service_aggregate: Service_Aggregate;
  /** fetch data from the table: "service" using primary key columns */
  service_by_pk?: Maybe<Service>;
  /** fetch data from the table in a streaming manner: "service" */
  service_stream: Array<Service>;
  /** fetch data from the table: "shrink" */
  shrink: Array<Shrink>;
  /** fetch aggregated fields from the table: "shrink" */
  shrink_aggregate: Shrink_Aggregate;
  /** fetch data from the table: "shrink" using primary key columns */
  shrink_by_pk?: Maybe<Shrink>;
  /** fetch data from the table in a streaming manner: "shrink" */
  shrink_stream: Array<Shrink>;
  /** fetch data from the table: "signature" */
  signature: Array<Signature>;
  /** fetch aggregated fields from the table: "signature" */
  signature_aggregate: Signature_Aggregate;
  /** fetch data from the table: "signature" using primary key columns */
  signature_by_pk?: Maybe<Signature>;
  /** fetch data from the table in a streaming manner: "signature" */
  signature_stream: Array<Signature>;
  /** fetch data from the table: "smartNote" */
  smartNote: Array<SmartNote>;
  /** fetch aggregated fields from the table: "smartNote" */
  smartNote_aggregate: SmartNote_Aggregate;
  /** fetch data from the table: "smartNote" using primary key columns */
  smartNote_by_pk?: Maybe<SmartNote>;
  /** fetch data from the table in a streaming manner: "smartNote" */
  smartNote_stream: Array<SmartNote>;
  /** fetch data from the table: "smsTemplate" */
  smsTemplate: Array<SmsTemplate>;
  /** fetch data from the table: "smsTemplatePatient" */
  smsTemplatePatient: Array<SmsTemplatePatient>;
  /** fetch aggregated fields from the table: "smsTemplatePatient" */
  smsTemplatePatient_aggregate: SmsTemplatePatient_Aggregate;
  /** fetch data from the table: "smsTemplatePatient" using primary key columns */
  smsTemplatePatient_by_pk?: Maybe<SmsTemplatePatient>;
  /** fetch data from the table in a streaming manner: "smsTemplatePatient" */
  smsTemplatePatient_stream: Array<SmsTemplatePatient>;
  /** fetch data from the table: "smsTemplateSendQueue" */
  smsTemplateSendQueue: Array<SmsTemplateSendQueue>;
  /** fetch aggregated fields from the table: "smsTemplateSendQueue" */
  smsTemplateSendQueue_aggregate: SmsTemplateSendQueue_Aggregate;
  /** fetch data from the table: "smsTemplateSendQueue" using primary key columns */
  smsTemplateSendQueue_by_pk?: Maybe<SmsTemplateSendQueue>;
  /** fetch data from the table in a streaming manner: "smsTemplateSendQueue" */
  smsTemplateSendQueue_stream: Array<SmsTemplateSendQueue>;
  /** fetch aggregated fields from the table: "smsTemplate" */
  smsTemplate_aggregate: SmsTemplate_Aggregate;
  /** fetch data from the table: "smsTemplate" using primary key columns */
  smsTemplate_by_pk?: Maybe<SmsTemplate>;
  /** fetch data from the table in a streaming manner: "smsTemplate" */
  smsTemplate_stream: Array<SmsTemplate>;
  /** fetch data from the table: "socialMediaAccount" */
  socialMediaAccount: Array<SocialMediaAccount>;
  /** fetch data from the table: "socialMediaAccountPost" */
  socialMediaAccountPost: Array<SocialMediaAccountPost>;
  /** fetch aggregated fields from the table: "socialMediaAccountPost" */
  socialMediaAccountPost_aggregate: SocialMediaAccountPost_Aggregate;
  /** fetch data from the table: "socialMediaAccountPost" using primary key columns */
  socialMediaAccountPost_by_pk?: Maybe<SocialMediaAccountPost>;
  /** fetch data from the table in a streaming manner: "socialMediaAccountPost" */
  socialMediaAccountPost_stream: Array<SocialMediaAccountPost>;
  /** fetch aggregated fields from the table: "socialMediaAccount" */
  socialMediaAccount_aggregate: SocialMediaAccount_Aggregate;
  /** fetch data from the table: "socialMediaAccount" using primary key columns */
  socialMediaAccount_by_pk?: Maybe<SocialMediaAccount>;
  /** fetch data from the table in a streaming manner: "socialMediaAccount" */
  socialMediaAccount_stream: Array<SocialMediaAccount>;
  /** fetch data from the table: "socialMediaPlatform" */
  socialMediaPlatform: Array<SocialMediaPlatform>;
  /** fetch aggregated fields from the table: "socialMediaPlatform" */
  socialMediaPlatform_aggregate: SocialMediaPlatform_Aggregate;
  /** fetch data from the table: "socialMediaPlatform" using primary key columns */
  socialMediaPlatform_by_pk?: Maybe<SocialMediaPlatform>;
  /** fetch data from the table in a streaming manner: "socialMediaPlatform" */
  socialMediaPlatform_stream: Array<SocialMediaPlatform>;
  /** fetch data from the table: "socialMediaPost" */
  socialMediaPost: Array<SocialMediaPost>;
  /** fetch aggregated fields from the table: "socialMediaPost" */
  socialMediaPost_aggregate: SocialMediaPost_Aggregate;
  /** fetch data from the table: "socialMediaPost" using primary key columns */
  socialMediaPost_by_pk?: Maybe<SocialMediaPost>;
  /** fetch data from the table in a streaming manner: "socialMediaPost" */
  socialMediaPost_stream: Array<SocialMediaPost>;
  /** fetch data from the table: "tag" */
  tag: Array<Tag>;
  /** fetch aggregated fields from the table: "tag" */
  tag_aggregate: Tag_Aggregate;
  /** fetch data from the table: "tag" using primary key columns */
  tag_by_pk?: Maybe<Tag>;
  /** fetch data from the table in a streaming manner: "tag" */
  tag_stream: Array<Tag>;
  /** fetch data from the table: "task" */
  task: Array<Task>;
  /** fetch data from the table: "taskComment" */
  taskComment: Array<TaskComment>;
  /** fetch aggregated fields from the table: "taskComment" */
  taskComment_aggregate: TaskComment_Aggregate;
  /** fetch data from the table: "taskComment" using primary key columns */
  taskComment_by_pk?: Maybe<TaskComment>;
  /** fetch data from the table in a streaming manner: "taskComment" */
  taskComment_stream: Array<TaskComment>;
  /** fetch aggregated fields from the table: "task" */
  task_aggregate: Task_Aggregate;
  /** fetch data from the table: "task" using primary key columns */
  task_by_pk?: Maybe<Task>;
  /** fetch data from the table in a streaming manner: "task" */
  task_stream: Array<Task>;
  /** fetch data from the table: "telehealthMeeting" */
  telehealthMeeting: Array<TelehealthMeeting>;
  /** fetch aggregated fields from the table: "telehealthMeeting" */
  telehealthMeeting_aggregate: TelehealthMeeting_Aggregate;
  /** fetch data from the table: "telehealthMeeting" using primary key columns */
  telehealthMeeting_by_pk?: Maybe<TelehealthMeeting>;
  /** fetch data from the table in a streaming manner: "telehealthMeeting" */
  telehealthMeeting_stream: Array<TelehealthMeeting>;
  /** fetch data from the table: "unusedAppointmentServiceConsumableType" */
  unusedAppointmentServiceConsumableType: Array<UnusedAppointmentServiceConsumableType>;
  /** fetch aggregated fields from the table: "unusedAppointmentServiceConsumableType" */
  unusedAppointmentServiceConsumableType_aggregate: UnusedAppointmentServiceConsumableType_Aggregate;
  /** fetch data from the table: "unusedAppointmentServiceConsumableType" using primary key columns */
  unusedAppointmentServiceConsumableType_by_pk?: Maybe<UnusedAppointmentServiceConsumableType>;
  /** fetch data from the table in a streaming manner: "unusedAppointmentServiceConsumableType" */
  unusedAppointmentServiceConsumableType_stream: Array<UnusedAppointmentServiceConsumableType>;
  /** fetch data from the table: "user" */
  user: Array<User>;
  /** fetch data from the table: "userInvite" */
  userInvite: Array<UserInvite>;
  /** fetch aggregated fields from the table: "userInvite" */
  userInvite_aggregate: UserInvite_Aggregate;
  /** fetch data from the table: "userInvite" using primary key columns */
  userInvite_by_pk?: Maybe<UserInvite>;
  /** fetch data from the table in a streaming manner: "userInvite" */
  userInvite_stream: Array<UserInvite>;
  /** fetch data from the table: "userOrganization" */
  userOrganization: Array<UserOrganization>;
  /** fetch aggregated fields from the table: "userOrganization" */
  userOrganization_aggregate: UserOrganization_Aggregate;
  /** fetch data from the table: "userOrganization" using primary key columns */
  userOrganization_by_pk?: Maybe<UserOrganization>;
  /** fetch data from the table in a streaming manner: "userOrganization" */
  userOrganization_stream: Array<UserOrganization>;
  /** fetch data from the table: "userWorkspace" */
  userWorkspace: Array<UserWorkspace>;
  /** fetch aggregated fields from the table: "userWorkspace" */
  userWorkspace_aggregate: UserWorkspace_Aggregate;
  /** fetch data from the table: "userWorkspace" using primary key columns */
  userWorkspace_by_pk?: Maybe<UserWorkspace>;
  /** fetch data from the table in a streaming manner: "userWorkspace" */
  userWorkspace_stream: Array<UserWorkspace>;
  /** fetch aggregated fields from the table: "user" */
  user_aggregate: User_Aggregate;
  /** fetch data from the table: "user" using primary key columns */
  user_by_pk?: Maybe<User>;
  /** fetch data from the table in a streaming manner: "user" */
  user_stream: Array<User>;
  /** An array relationship */
  vitals: Array<Vitals>;
  /** An aggregate relationship */
  vitals_aggregate: Vitals_Aggregate;
  /** fetch data from the table: "vitals" using primary key columns */
  vitals_by_pk?: Maybe<Vitals>;
  /** fetch data from the table in a streaming manner: "vitals" */
  vitals_stream: Array<Vitals>;
  /** fetch data from the table: "workspace" */
  workspace: Array<Workspace>;
  /** fetch data from the table: "workspaceConfiguration" */
  workspaceConfiguration: Array<WorkspaceConfiguration>;
  /** fetch aggregated fields from the table: "workspaceConfiguration" */
  workspaceConfiguration_aggregate: WorkspaceConfiguration_Aggregate;
  /** fetch data from the table: "workspaceConfiguration" using primary key columns */
  workspaceConfiguration_by_pk?: Maybe<WorkspaceConfiguration>;
  /** fetch data from the table in a streaming manner: "workspaceConfiguration" */
  workspaceConfiguration_stream: Array<WorkspaceConfiguration>;
  /** fetch aggregated fields from the table: "workspace" */
  workspace_aggregate: Workspace_Aggregate;
  /** fetch data from the table: "workspace" using primary key columns */
  workspace_by_pk?: Maybe<Workspace>;
  /** fetch data from the table in a streaming manner: "workspace" */
  workspace_stream: Array<Workspace>;
  /** fetch data from the table: "zdReview" */
  zdReview: Array<ZdReview>;
  /** fetch aggregated fields from the table: "zdReview" */
  zdReview_aggregate: ZdReview_Aggregate;
  /** fetch data from the table: "zdReview" using primary key columns */
  zdReview_by_pk?: Maybe<ZdReview>;
  /** fetch data from the table in a streaming manner: "zdReview" */
  zdReview_stream: Array<ZdReview>;
};


export type Subscription_RootActivityLogArgs = {
  distinct_on?: InputMaybe<Array<ActivityLog_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ActivityLog_Order_By>>;
  where?: InputMaybe<ActivityLog_Bool_Exp>;
};


export type Subscription_RootActivityLog_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ActivityLog_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ActivityLog_Order_By>>;
  where?: InputMaybe<ActivityLog_Bool_Exp>;
};


export type Subscription_RootActivityLog_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootActivityLog_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ActivityLog_Stream_Cursor_Input>>;
  where?: InputMaybe<ActivityLog_Bool_Exp>;
};


export type Subscription_RootAddressArgs = {
  distinct_on?: InputMaybe<Array<Address_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Address_Order_By>>;
  where?: InputMaybe<Address_Bool_Exp>;
};


export type Subscription_RootAddress_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Address_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Address_Order_By>>;
  where?: InputMaybe<Address_Bool_Exp>;
};


export type Subscription_RootAddress_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootAddress_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Address_Stream_Cursor_Input>>;
  where?: InputMaybe<Address_Bool_Exp>;
};


export type Subscription_RootAppointmentArgs = {
  distinct_on?: InputMaybe<Array<Appointment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Appointment_Order_By>>;
  where?: InputMaybe<Appointment_Bool_Exp>;
};


export type Subscription_RootAppointmentConsentArgs = {
  distinct_on?: InputMaybe<Array<AppointmentConsent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AppointmentConsent_Order_By>>;
  where?: InputMaybe<AppointmentConsent_Bool_Exp>;
};


export type Subscription_RootAppointmentConsent_AggregateArgs = {
  distinct_on?: InputMaybe<Array<AppointmentConsent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AppointmentConsent_Order_By>>;
  where?: InputMaybe<AppointmentConsent_Bool_Exp>;
};


export type Subscription_RootAppointmentConsent_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootAppointmentConsent_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<AppointmentConsent_Stream_Cursor_Input>>;
  where?: InputMaybe<AppointmentConsent_Bool_Exp>;
};


export type Subscription_RootAppointmentCustomFormArgs = {
  distinct_on?: InputMaybe<Array<AppointmentCustomForm_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AppointmentCustomForm_Order_By>>;
  where?: InputMaybe<AppointmentCustomForm_Bool_Exp>;
};


export type Subscription_RootAppointmentCustomForm_AggregateArgs = {
  distinct_on?: InputMaybe<Array<AppointmentCustomForm_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AppointmentCustomForm_Order_By>>;
  where?: InputMaybe<AppointmentCustomForm_Bool_Exp>;
};


export type Subscription_RootAppointmentCustomForm_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootAppointmentCustomForm_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<AppointmentCustomForm_Stream_Cursor_Input>>;
  where?: InputMaybe<AppointmentCustomForm_Bool_Exp>;
};


export type Subscription_RootAppointmentServiceArgs = {
  distinct_on?: InputMaybe<Array<AppointmentService_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AppointmentService_Order_By>>;
  where?: InputMaybe<AppointmentService_Bool_Exp>;
};


export type Subscription_RootAppointmentServiceConsumableArgs = {
  distinct_on?: InputMaybe<Array<AppointmentServiceConsumable_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AppointmentServiceConsumable_Order_By>>;
  where?: InputMaybe<AppointmentServiceConsumable_Bool_Exp>;
};


export type Subscription_RootAppointmentServiceConsumable_AggregateArgs = {
  distinct_on?: InputMaybe<Array<AppointmentServiceConsumable_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AppointmentServiceConsumable_Order_By>>;
  where?: InputMaybe<AppointmentServiceConsumable_Bool_Exp>;
};


export type Subscription_RootAppointmentServiceConsumable_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootAppointmentServiceConsumable_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<AppointmentServiceConsumable_Stream_Cursor_Input>>;
  where?: InputMaybe<AppointmentServiceConsumable_Bool_Exp>;
};


export type Subscription_RootAppointmentService_AggregateArgs = {
  distinct_on?: InputMaybe<Array<AppointmentService_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AppointmentService_Order_By>>;
  where?: InputMaybe<AppointmentService_Bool_Exp>;
};


export type Subscription_RootAppointmentService_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootAppointmentService_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<AppointmentService_Stream_Cursor_Input>>;
  where?: InputMaybe<AppointmentService_Bool_Exp>;
};


export type Subscription_RootAppointmentTypeArgs = {
  distinct_on?: InputMaybe<Array<AppointmentType_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AppointmentType_Order_By>>;
  where?: InputMaybe<AppointmentType_Bool_Exp>;
};


export type Subscription_RootAppointmentType_AggregateArgs = {
  distinct_on?: InputMaybe<Array<AppointmentType_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AppointmentType_Order_By>>;
  where?: InputMaybe<AppointmentType_Bool_Exp>;
};


export type Subscription_RootAppointmentType_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootAppointmentType_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<AppointmentType_Stream_Cursor_Input>>;
  where?: InputMaybe<AppointmentType_Bool_Exp>;
};


export type Subscription_RootAppointment_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Appointment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Appointment_Order_By>>;
  where?: InputMaybe<Appointment_Bool_Exp>;
};


export type Subscription_RootAppointment_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootAppointment_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Appointment_Stream_Cursor_Input>>;
  where?: InputMaybe<Appointment_Bool_Exp>;
};


export type Subscription_RootAudienceArgs = {
  distinct_on?: InputMaybe<Array<Audience_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Audience_Order_By>>;
  where?: InputMaybe<Audience_Bool_Exp>;
};


export type Subscription_RootAudienceEmailArgs = {
  distinct_on?: InputMaybe<Array<AudienceEmail_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AudienceEmail_Order_By>>;
  where?: InputMaybe<AudienceEmail_Bool_Exp>;
};


export type Subscription_RootAudienceEmail_AggregateArgs = {
  distinct_on?: InputMaybe<Array<AudienceEmail_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AudienceEmail_Order_By>>;
  where?: InputMaybe<AudienceEmail_Bool_Exp>;
};


export type Subscription_RootAudienceEmail_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootAudienceEmail_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<AudienceEmail_Stream_Cursor_Input>>;
  where?: InputMaybe<AudienceEmail_Bool_Exp>;
};


export type Subscription_RootAudience_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Audience_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Audience_Order_By>>;
  where?: InputMaybe<Audience_Bool_Exp>;
};


export type Subscription_RootAudience_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootAudience_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Audience_Stream_Cursor_Input>>;
  where?: InputMaybe<Audience_Bool_Exp>;
};


export type Subscription_RootAuthProfileArgs = {
  distinct_on?: InputMaybe<Array<AuthProfile_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AuthProfile_Order_By>>;
  where?: InputMaybe<AuthProfile_Bool_Exp>;
};


export type Subscription_RootAuthProfile_AggregateArgs = {
  distinct_on?: InputMaybe<Array<AuthProfile_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AuthProfile_Order_By>>;
  where?: InputMaybe<AuthProfile_Bool_Exp>;
};


export type Subscription_RootAuthProfile_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootAuthProfile_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<AuthProfile_Stream_Cursor_Input>>;
  where?: InputMaybe<AuthProfile_Bool_Exp>;
};


export type Subscription_RootClinicalMessageArgs = {
  distinct_on?: InputMaybe<Array<ClinicalMessage_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ClinicalMessage_Order_By>>;
  where?: InputMaybe<ClinicalMessage_Bool_Exp>;
};


export type Subscription_RootClinicalMessageStatusArgs = {
  distinct_on?: InputMaybe<Array<ClinicalMessageStatus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ClinicalMessageStatus_Order_By>>;
  where?: InputMaybe<ClinicalMessageStatus_Bool_Exp>;
};


export type Subscription_RootClinicalMessageStatus_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ClinicalMessageStatus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ClinicalMessageStatus_Order_By>>;
  where?: InputMaybe<ClinicalMessageStatus_Bool_Exp>;
};


export type Subscription_RootClinicalMessageStatus_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootClinicalMessageStatus_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ClinicalMessageStatus_Stream_Cursor_Input>>;
  where?: InputMaybe<ClinicalMessageStatus_Bool_Exp>;
};


export type Subscription_RootClinicalMessage_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ClinicalMessage_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ClinicalMessage_Order_By>>;
  where?: InputMaybe<ClinicalMessage_Bool_Exp>;
};


export type Subscription_RootClinicalMessage_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootClinicalMessage_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ClinicalMessage_Stream_Cursor_Input>>;
  where?: InputMaybe<ClinicalMessage_Bool_Exp>;
};


export type Subscription_RootCompensationPolicyArgs = {
  distinct_on?: InputMaybe<Array<CompensationPolicy_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CompensationPolicy_Order_By>>;
  where?: InputMaybe<CompensationPolicy_Bool_Exp>;
};


export type Subscription_RootCompensationPolicyCommissionArgs = {
  distinct_on?: InputMaybe<Array<CompensationPolicyCommission_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CompensationPolicyCommission_Order_By>>;
  where?: InputMaybe<CompensationPolicyCommission_Bool_Exp>;
};


export type Subscription_RootCompensationPolicyCommission_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CompensationPolicyCommission_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CompensationPolicyCommission_Order_By>>;
  where?: InputMaybe<CompensationPolicyCommission_Bool_Exp>;
};


export type Subscription_RootCompensationPolicyCommission_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootCompensationPolicyCommission_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<CompensationPolicyCommission_Stream_Cursor_Input>>;
  where?: InputMaybe<CompensationPolicyCommission_Bool_Exp>;
};


export type Subscription_RootCompensationPolicyTierArgs = {
  distinct_on?: InputMaybe<Array<CompensationPolicyTier_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CompensationPolicyTier_Order_By>>;
  where?: InputMaybe<CompensationPolicyTier_Bool_Exp>;
};


export type Subscription_RootCompensationPolicyTierCategoryArgs = {
  distinct_on?: InputMaybe<Array<CompensationPolicyTierCategory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CompensationPolicyTierCategory_Order_By>>;
  where?: InputMaybe<CompensationPolicyTierCategory_Bool_Exp>;
};


export type Subscription_RootCompensationPolicyTierCategory_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CompensationPolicyTierCategory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CompensationPolicyTierCategory_Order_By>>;
  where?: InputMaybe<CompensationPolicyTierCategory_Bool_Exp>;
};


export type Subscription_RootCompensationPolicyTierCategory_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootCompensationPolicyTierCategory_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<CompensationPolicyTierCategory_Stream_Cursor_Input>>;
  where?: InputMaybe<CompensationPolicyTierCategory_Bool_Exp>;
};


export type Subscription_RootCompensationPolicyTierExemptionArgs = {
  distinct_on?: InputMaybe<Array<CompensationPolicyTierExemption_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CompensationPolicyTierExemption_Order_By>>;
  where?: InputMaybe<CompensationPolicyTierExemption_Bool_Exp>;
};


export type Subscription_RootCompensationPolicyTierExemption_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CompensationPolicyTierExemption_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CompensationPolicyTierExemption_Order_By>>;
  where?: InputMaybe<CompensationPolicyTierExemption_Bool_Exp>;
};


export type Subscription_RootCompensationPolicyTierExemption_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootCompensationPolicyTierExemption_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<CompensationPolicyTierExemption_Stream_Cursor_Input>>;
  where?: InputMaybe<CompensationPolicyTierExemption_Bool_Exp>;
};


export type Subscription_RootCompensationPolicyTierInclusionArgs = {
  distinct_on?: InputMaybe<Array<CompensationPolicyTierInclusion_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CompensationPolicyTierInclusion_Order_By>>;
  where?: InputMaybe<CompensationPolicyTierInclusion_Bool_Exp>;
};


export type Subscription_RootCompensationPolicyTierInclusion_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CompensationPolicyTierInclusion_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CompensationPolicyTierInclusion_Order_By>>;
  where?: InputMaybe<CompensationPolicyTierInclusion_Bool_Exp>;
};


export type Subscription_RootCompensationPolicyTierInclusion_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootCompensationPolicyTierInclusion_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<CompensationPolicyTierInclusion_Stream_Cursor_Input>>;
  where?: InputMaybe<CompensationPolicyTierInclusion_Bool_Exp>;
};


export type Subscription_RootCompensationPolicyTier_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CompensationPolicyTier_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CompensationPolicyTier_Order_By>>;
  where?: InputMaybe<CompensationPolicyTier_Bool_Exp>;
};


export type Subscription_RootCompensationPolicyTier_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootCompensationPolicyTier_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<CompensationPolicyTier_Stream_Cursor_Input>>;
  where?: InputMaybe<CompensationPolicyTier_Bool_Exp>;
};


export type Subscription_RootCompensationPolicy_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CompensationPolicy_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CompensationPolicy_Order_By>>;
  where?: InputMaybe<CompensationPolicy_Bool_Exp>;
};


export type Subscription_RootCompensationPolicy_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootCompensationPolicy_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<CompensationPolicy_Stream_Cursor_Input>>;
  where?: InputMaybe<CompensationPolicy_Bool_Exp>;
};


export type Subscription_RootCompensationStrategyArgs = {
  distinct_on?: InputMaybe<Array<CompensationStrategy_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CompensationStrategy_Order_By>>;
  where?: InputMaybe<CompensationStrategy_Bool_Exp>;
};


export type Subscription_RootCompensationStrategyCommissionArgs = {
  distinct_on?: InputMaybe<Array<CompensationStrategyCommission_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CompensationStrategyCommission_Order_By>>;
  where?: InputMaybe<CompensationStrategyCommission_Bool_Exp>;
};


export type Subscription_RootCompensationStrategyCommission_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CompensationStrategyCommission_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CompensationStrategyCommission_Order_By>>;
  where?: InputMaybe<CompensationStrategyCommission_Bool_Exp>;
};


export type Subscription_RootCompensationStrategyCommission_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootCompensationStrategyCommission_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<CompensationStrategyCommission_Stream_Cursor_Input>>;
  where?: InputMaybe<CompensationStrategyCommission_Bool_Exp>;
};


export type Subscription_RootCompensationStrategyTierArgs = {
  distinct_on?: InputMaybe<Array<CompensationStrategyTier_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CompensationStrategyTier_Order_By>>;
  where?: InputMaybe<CompensationStrategyTier_Bool_Exp>;
};


export type Subscription_RootCompensationStrategyTierCategoryArgs = {
  distinct_on?: InputMaybe<Array<CompensationStrategyTierCategory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CompensationStrategyTierCategory_Order_By>>;
  where?: InputMaybe<CompensationStrategyTierCategory_Bool_Exp>;
};


export type Subscription_RootCompensationStrategyTierCategory_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CompensationStrategyTierCategory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CompensationStrategyTierCategory_Order_By>>;
  where?: InputMaybe<CompensationStrategyTierCategory_Bool_Exp>;
};


export type Subscription_RootCompensationStrategyTierCategory_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootCompensationStrategyTierCategory_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<CompensationStrategyTierCategory_Stream_Cursor_Input>>;
  where?: InputMaybe<CompensationStrategyTierCategory_Bool_Exp>;
};


export type Subscription_RootCompensationStrategyTierExemptionArgs = {
  distinct_on?: InputMaybe<Array<CompensationStrategyTierExemption_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CompensationStrategyTierExemption_Order_By>>;
  where?: InputMaybe<CompensationStrategyTierExemption_Bool_Exp>;
};


export type Subscription_RootCompensationStrategyTierExemption_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CompensationStrategyTierExemption_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CompensationStrategyTierExemption_Order_By>>;
  where?: InputMaybe<CompensationStrategyTierExemption_Bool_Exp>;
};


export type Subscription_RootCompensationStrategyTierExemption_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootCompensationStrategyTierExemption_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<CompensationStrategyTierExemption_Stream_Cursor_Input>>;
  where?: InputMaybe<CompensationStrategyTierExemption_Bool_Exp>;
};


export type Subscription_RootCompensationStrategyTierInclusionArgs = {
  distinct_on?: InputMaybe<Array<CompensationStrategyTierInclusion_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CompensationStrategyTierInclusion_Order_By>>;
  where?: InputMaybe<CompensationStrategyTierInclusion_Bool_Exp>;
};


export type Subscription_RootCompensationStrategyTierInclusion_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CompensationStrategyTierInclusion_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CompensationStrategyTierInclusion_Order_By>>;
  where?: InputMaybe<CompensationStrategyTierInclusion_Bool_Exp>;
};


export type Subscription_RootCompensationStrategyTierInclusion_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootCompensationStrategyTierInclusion_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<CompensationStrategyTierInclusion_Stream_Cursor_Input>>;
  where?: InputMaybe<CompensationStrategyTierInclusion_Bool_Exp>;
};


export type Subscription_RootCompensationStrategyTier_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CompensationStrategyTier_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CompensationStrategyTier_Order_By>>;
  where?: InputMaybe<CompensationStrategyTier_Bool_Exp>;
};


export type Subscription_RootCompensationStrategyTier_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootCompensationStrategyTier_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<CompensationStrategyTier_Stream_Cursor_Input>>;
  where?: InputMaybe<CompensationStrategyTier_Bool_Exp>;
};


export type Subscription_RootCompensationStrategy_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CompensationStrategy_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CompensationStrategy_Order_By>>;
  where?: InputMaybe<CompensationStrategy_Bool_Exp>;
};


export type Subscription_RootCompensationStrategy_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootCompensationStrategy_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<CompensationStrategy_Stream_Cursor_Input>>;
  where?: InputMaybe<CompensationStrategy_Bool_Exp>;
};


export type Subscription_RootConsentArgs = {
  distinct_on?: InputMaybe<Array<Consent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Consent_Order_By>>;
  where?: InputMaybe<Consent_Bool_Exp>;
};


export type Subscription_RootConsent_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Consent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Consent_Order_By>>;
  where?: InputMaybe<Consent_Bool_Exp>;
};


export type Subscription_RootConsent_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootConsent_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Consent_Stream_Cursor_Input>>;
  where?: InputMaybe<Consent_Bool_Exp>;
};


export type Subscription_RootConsumableArgs = {
  distinct_on?: InputMaybe<Array<Consumable_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Consumable_Order_By>>;
  where?: InputMaybe<Consumable_Bool_Exp>;
};


export type Subscription_RootConsumableBrandArgs = {
  distinct_on?: InputMaybe<Array<ConsumableBrand_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ConsumableBrand_Order_By>>;
  where?: InputMaybe<ConsumableBrand_Bool_Exp>;
};


export type Subscription_RootConsumableBrand_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ConsumableBrand_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ConsumableBrand_Order_By>>;
  where?: InputMaybe<ConsumableBrand_Bool_Exp>;
};


export type Subscription_RootConsumableBrand_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootConsumableBrand_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ConsumableBrand_Stream_Cursor_Input>>;
  where?: InputMaybe<ConsumableBrand_Bool_Exp>;
};


export type Subscription_RootConsumableSubtypeArgs = {
  distinct_on?: InputMaybe<Array<ConsumableSubtype_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ConsumableSubtype_Order_By>>;
  where?: InputMaybe<ConsumableSubtype_Bool_Exp>;
};


export type Subscription_RootConsumableSubtype_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ConsumableSubtype_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ConsumableSubtype_Order_By>>;
  where?: InputMaybe<ConsumableSubtype_Bool_Exp>;
};


export type Subscription_RootConsumableSubtype_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootConsumableSubtype_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ConsumableSubtype_Stream_Cursor_Input>>;
  where?: InputMaybe<ConsumableSubtype_Bool_Exp>;
};


export type Subscription_RootConsumableTypeArgs = {
  distinct_on?: InputMaybe<Array<ConsumableType_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ConsumableType_Order_By>>;
  where?: InputMaybe<ConsumableType_Bool_Exp>;
};


export type Subscription_RootConsumableTypeConsumableSubtypeArgs = {
  distinct_on?: InputMaybe<Array<ConsumableTypeConsumableSubtype_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ConsumableTypeConsumableSubtype_Order_By>>;
  where?: InputMaybe<ConsumableTypeConsumableSubtype_Bool_Exp>;
};


export type Subscription_RootConsumableTypeConsumableSubtype_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ConsumableTypeConsumableSubtype_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ConsumableTypeConsumableSubtype_Order_By>>;
  where?: InputMaybe<ConsumableTypeConsumableSubtype_Bool_Exp>;
};


export type Subscription_RootConsumableTypeConsumableSubtype_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootConsumableTypeConsumableSubtype_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ConsumableTypeConsumableSubtype_Stream_Cursor_Input>>;
  where?: InputMaybe<ConsumableTypeConsumableSubtype_Bool_Exp>;
};


export type Subscription_RootConsumableType_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ConsumableType_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ConsumableType_Order_By>>;
  where?: InputMaybe<ConsumableType_Bool_Exp>;
};


export type Subscription_RootConsumableType_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootConsumableType_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ConsumableType_Stream_Cursor_Input>>;
  where?: InputMaybe<ConsumableType_Bool_Exp>;
};


export type Subscription_RootConsumable_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Consumable_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Consumable_Order_By>>;
  where?: InputMaybe<Consumable_Bool_Exp>;
};


export type Subscription_RootConsumable_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootConsumable_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Consumable_Stream_Cursor_Input>>;
  where?: InputMaybe<Consumable_Bool_Exp>;
};


export type Subscription_RootCouponArgs = {
  distinct_on?: InputMaybe<Array<Coupon_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Coupon_Order_By>>;
  where?: InputMaybe<Coupon_Bool_Exp>;
};


export type Subscription_RootCouponConfigurationArgs = {
  distinct_on?: InputMaybe<Array<CouponConfiguration_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CouponConfiguration_Order_By>>;
  where?: InputMaybe<CouponConfiguration_Bool_Exp>;
};


export type Subscription_RootCouponConfiguration_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CouponConfiguration_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CouponConfiguration_Order_By>>;
  where?: InputMaybe<CouponConfiguration_Bool_Exp>;
};


export type Subscription_RootCouponConfiguration_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootCouponConfiguration_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<CouponConfiguration_Stream_Cursor_Input>>;
  where?: InputMaybe<CouponConfiguration_Bool_Exp>;
};


export type Subscription_RootCouponScopeArgs = {
  distinct_on?: InputMaybe<Array<CouponScope_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CouponScope_Order_By>>;
  where?: InputMaybe<CouponScope_Bool_Exp>;
};


export type Subscription_RootCouponScope_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CouponScope_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CouponScope_Order_By>>;
  where?: InputMaybe<CouponScope_Bool_Exp>;
};


export type Subscription_RootCouponScope_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootCouponScope_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<CouponScope_Stream_Cursor_Input>>;
  where?: InputMaybe<CouponScope_Bool_Exp>;
};


export type Subscription_RootCouponTypeArgs = {
  distinct_on?: InputMaybe<Array<CouponType_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CouponType_Order_By>>;
  where?: InputMaybe<CouponType_Bool_Exp>;
};


export type Subscription_RootCouponType_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CouponType_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CouponType_Order_By>>;
  where?: InputMaybe<CouponType_Bool_Exp>;
};


export type Subscription_RootCouponType_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootCouponType_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<CouponType_Stream_Cursor_Input>>;
  where?: InputMaybe<CouponType_Bool_Exp>;
};


export type Subscription_RootCoupon_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Coupon_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Coupon_Order_By>>;
  where?: InputMaybe<Coupon_Bool_Exp>;
};


export type Subscription_RootCoupon_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootCoupon_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Coupon_Stream_Cursor_Input>>;
  where?: InputMaybe<Coupon_Bool_Exp>;
};


export type Subscription_RootCustomFormArgs = {
  distinct_on?: InputMaybe<Array<CustomForm_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CustomForm_Order_By>>;
  where?: InputMaybe<CustomForm_Bool_Exp>;
};


export type Subscription_RootCustomFormFieldArgs = {
  distinct_on?: InputMaybe<Array<CustomFormField_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CustomFormField_Order_By>>;
  where?: InputMaybe<CustomFormField_Bool_Exp>;
};


export type Subscription_RootCustomFormFieldCategoryArgs = {
  distinct_on?: InputMaybe<Array<CustomFormFieldCategory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CustomFormFieldCategory_Order_By>>;
  where?: InputMaybe<CustomFormFieldCategory_Bool_Exp>;
};


export type Subscription_RootCustomFormFieldCategory_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CustomFormFieldCategory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CustomFormFieldCategory_Order_By>>;
  where?: InputMaybe<CustomFormFieldCategory_Bool_Exp>;
};


export type Subscription_RootCustomFormFieldCategory_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootCustomFormFieldCategory_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<CustomFormFieldCategory_Stream_Cursor_Input>>;
  where?: InputMaybe<CustomFormFieldCategory_Bool_Exp>;
};


export type Subscription_RootCustomFormFieldResponseArgs = {
  distinct_on?: InputMaybe<Array<CustomFormFieldResponse_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CustomFormFieldResponse_Order_By>>;
  where?: InputMaybe<CustomFormFieldResponse_Bool_Exp>;
};


export type Subscription_RootCustomFormFieldResponse_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CustomFormFieldResponse_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CustomFormFieldResponse_Order_By>>;
  where?: InputMaybe<CustomFormFieldResponse_Bool_Exp>;
};


export type Subscription_RootCustomFormFieldResponse_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootCustomFormFieldResponse_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<CustomFormFieldResponse_Stream_Cursor_Input>>;
  where?: InputMaybe<CustomFormFieldResponse_Bool_Exp>;
};


export type Subscription_RootCustomFormFieldTypeArgs = {
  distinct_on?: InputMaybe<Array<CustomFormFieldType_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CustomFormFieldType_Order_By>>;
  where?: InputMaybe<CustomFormFieldType_Bool_Exp>;
};


export type Subscription_RootCustomFormFieldType_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CustomFormFieldType_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CustomFormFieldType_Order_By>>;
  where?: InputMaybe<CustomFormFieldType_Bool_Exp>;
};


export type Subscription_RootCustomFormFieldType_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootCustomFormFieldType_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<CustomFormFieldType_Stream_Cursor_Input>>;
  where?: InputMaybe<CustomFormFieldType_Bool_Exp>;
};


export type Subscription_RootCustomFormField_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CustomFormField_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CustomFormField_Order_By>>;
  where?: InputMaybe<CustomFormField_Bool_Exp>;
};


export type Subscription_RootCustomFormField_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootCustomFormField_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<CustomFormField_Stream_Cursor_Input>>;
  where?: InputMaybe<CustomFormField_Bool_Exp>;
};


export type Subscription_RootCustomFormOptionArgs = {
  distinct_on?: InputMaybe<Array<CustomFormOption_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CustomFormOption_Order_By>>;
  where?: InputMaybe<CustomFormOption_Bool_Exp>;
};


export type Subscription_RootCustomFormOption_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CustomFormOption_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CustomFormOption_Order_By>>;
  where?: InputMaybe<CustomFormOption_Bool_Exp>;
};


export type Subscription_RootCustomFormOption_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootCustomFormOption_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<CustomFormOption_Stream_Cursor_Input>>;
  where?: InputMaybe<CustomFormOption_Bool_Exp>;
};


export type Subscription_RootCustomFormReplicatedFieldArgs = {
  distinct_on?: InputMaybe<Array<CustomFormReplicatedField_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CustomFormReplicatedField_Order_By>>;
  where?: InputMaybe<CustomFormReplicatedField_Bool_Exp>;
};


export type Subscription_RootCustomFormReplicatedField_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CustomFormReplicatedField_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CustomFormReplicatedField_Order_By>>;
  where?: InputMaybe<CustomFormReplicatedField_Bool_Exp>;
};


export type Subscription_RootCustomFormReplicatedField_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootCustomFormReplicatedField_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<CustomFormReplicatedField_Stream_Cursor_Input>>;
  where?: InputMaybe<CustomFormReplicatedField_Bool_Exp>;
};


export type Subscription_RootCustomFormReplicatedRowArgs = {
  distinct_on?: InputMaybe<Array<CustomFormReplicatedRow_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CustomFormReplicatedRow_Order_By>>;
  where?: InputMaybe<CustomFormReplicatedRow_Bool_Exp>;
};


export type Subscription_RootCustomFormReplicatedRow_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CustomFormReplicatedRow_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CustomFormReplicatedRow_Order_By>>;
  where?: InputMaybe<CustomFormReplicatedRow_Bool_Exp>;
};


export type Subscription_RootCustomFormReplicatedRow_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootCustomFormReplicatedRow_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<CustomFormReplicatedRow_Stream_Cursor_Input>>;
  where?: InputMaybe<CustomFormReplicatedRow_Bool_Exp>;
};


export type Subscription_RootCustomFormResponseArgs = {
  distinct_on?: InputMaybe<Array<CustomFormResponse_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CustomFormResponse_Order_By>>;
  where?: InputMaybe<CustomFormResponse_Bool_Exp>;
};


export type Subscription_RootCustomFormResponse_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CustomFormResponse_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CustomFormResponse_Order_By>>;
  where?: InputMaybe<CustomFormResponse_Bool_Exp>;
};


export type Subscription_RootCustomFormResponse_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootCustomFormResponse_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<CustomFormResponse_Stream_Cursor_Input>>;
  where?: InputMaybe<CustomFormResponse_Bool_Exp>;
};


export type Subscription_RootCustomFormRowArgs = {
  distinct_on?: InputMaybe<Array<CustomFormRow_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CustomFormRow_Order_By>>;
  where?: InputMaybe<CustomFormRow_Bool_Exp>;
};


export type Subscription_RootCustomFormRow_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CustomFormRow_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CustomFormRow_Order_By>>;
  where?: InputMaybe<CustomFormRow_Bool_Exp>;
};


export type Subscription_RootCustomFormRow_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootCustomFormRow_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<CustomFormRow_Stream_Cursor_Input>>;
  where?: InputMaybe<CustomFormRow_Bool_Exp>;
};


export type Subscription_RootCustomForm_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CustomForm_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CustomForm_Order_By>>;
  where?: InputMaybe<CustomForm_Bool_Exp>;
};


export type Subscription_RootCustomForm_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootCustomForm_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<CustomForm_Stream_Cursor_Input>>;
  where?: InputMaybe<CustomForm_Bool_Exp>;
};


export type Subscription_RootCustomQuestionArgs = {
  distinct_on?: InputMaybe<Array<CustomQuestion_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CustomQuestion_Order_By>>;
  where?: InputMaybe<CustomQuestion_Bool_Exp>;
};


export type Subscription_RootCustomQuestionOptionArgs = {
  distinct_on?: InputMaybe<Array<CustomQuestionOption_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CustomQuestionOption_Order_By>>;
  where?: InputMaybe<CustomQuestionOption_Bool_Exp>;
};


export type Subscription_RootCustomQuestionOption_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CustomQuestionOption_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CustomQuestionOption_Order_By>>;
  where?: InputMaybe<CustomQuestionOption_Bool_Exp>;
};


export type Subscription_RootCustomQuestionOption_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootCustomQuestionOption_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<CustomQuestionOption_Stream_Cursor_Input>>;
  where?: InputMaybe<CustomQuestionOption_Bool_Exp>;
};


export type Subscription_RootCustomQuestion_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CustomQuestion_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CustomQuestion_Order_By>>;
  where?: InputMaybe<CustomQuestion_Bool_Exp>;
};


export type Subscription_RootCustomQuestion_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootCustomQuestion_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<CustomQuestion_Stream_Cursor_Input>>;
  where?: InputMaybe<CustomQuestion_Bool_Exp>;
};


export type Subscription_RootDegreeDesignationArgs = {
  distinct_on?: InputMaybe<Array<DegreeDesignation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<DegreeDesignation_Order_By>>;
  where?: InputMaybe<DegreeDesignation_Bool_Exp>;
};


export type Subscription_RootDegreeDesignation_AggregateArgs = {
  distinct_on?: InputMaybe<Array<DegreeDesignation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<DegreeDesignation_Order_By>>;
  where?: InputMaybe<DegreeDesignation_Bool_Exp>;
};


export type Subscription_RootDegreeDesignation_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootDegreeDesignation_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<DegreeDesignation_Stream_Cursor_Input>>;
  where?: InputMaybe<DegreeDesignation_Bool_Exp>;
};


export type Subscription_RootDepositInvoiceLineItemArgs = {
  distinct_on?: InputMaybe<Array<DepositInvoiceLineItem_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<DepositInvoiceLineItem_Order_By>>;
  where?: InputMaybe<DepositInvoiceLineItem_Bool_Exp>;
};


export type Subscription_RootDepositInvoiceLineItem_AggregateArgs = {
  distinct_on?: InputMaybe<Array<DepositInvoiceLineItem_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<DepositInvoiceLineItem_Order_By>>;
  where?: InputMaybe<DepositInvoiceLineItem_Bool_Exp>;
};


export type Subscription_RootDepositInvoiceLineItem_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootDepositInvoiceLineItem_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<DepositInvoiceLineItem_Stream_Cursor_Input>>;
  where?: InputMaybe<DepositInvoiceLineItem_Bool_Exp>;
};


export type Subscription_RootDeviceArgs = {
  distinct_on?: InputMaybe<Array<Device_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Device_Order_By>>;
  where?: InputMaybe<Device_Bool_Exp>;
};


export type Subscription_RootDeviceTypeArgs = {
  distinct_on?: InputMaybe<Array<DeviceType_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<DeviceType_Order_By>>;
  where?: InputMaybe<DeviceType_Bool_Exp>;
};


export type Subscription_RootDeviceType_AggregateArgs = {
  distinct_on?: InputMaybe<Array<DeviceType_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<DeviceType_Order_By>>;
  where?: InputMaybe<DeviceType_Bool_Exp>;
};


export type Subscription_RootDeviceType_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootDeviceType_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<DeviceType_Stream_Cursor_Input>>;
  where?: InputMaybe<DeviceType_Bool_Exp>;
};


export type Subscription_RootDevice_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Device_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Device_Order_By>>;
  where?: InputMaybe<Device_Bool_Exp>;
};


export type Subscription_RootDevice_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootDevice_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Device_Stream_Cursor_Input>>;
  where?: InputMaybe<Device_Bool_Exp>;
};


export type Subscription_RootDiscountInvoiceLineItemArgs = {
  distinct_on?: InputMaybe<Array<DiscountInvoiceLineItem_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<DiscountInvoiceLineItem_Order_By>>;
  where?: InputMaybe<DiscountInvoiceLineItem_Bool_Exp>;
};


export type Subscription_RootDiscountInvoiceLineItem_AggregateArgs = {
  distinct_on?: InputMaybe<Array<DiscountInvoiceLineItem_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<DiscountInvoiceLineItem_Order_By>>;
  where?: InputMaybe<DiscountInvoiceLineItem_Bool_Exp>;
};


export type Subscription_RootDiscountInvoiceLineItem_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootDiscountInvoiceLineItem_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<DiscountInvoiceLineItem_Stream_Cursor_Input>>;
  where?: InputMaybe<DiscountInvoiceLineItem_Bool_Exp>;
};


export type Subscription_RootDocumentArgs = {
  distinct_on?: InputMaybe<Array<Document_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Document_Order_By>>;
  where?: InputMaybe<Document_Bool_Exp>;
};


export type Subscription_RootDocumentTagArgs = {
  distinct_on?: InputMaybe<Array<DocumentTag_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<DocumentTag_Order_By>>;
  where?: InputMaybe<DocumentTag_Bool_Exp>;
};


export type Subscription_RootDocumentTag_AggregateArgs = {
  distinct_on?: InputMaybe<Array<DocumentTag_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<DocumentTag_Order_By>>;
  where?: InputMaybe<DocumentTag_Bool_Exp>;
};


export type Subscription_RootDocumentTag_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootDocumentTag_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<DocumentTag_Stream_Cursor_Input>>;
  where?: InputMaybe<DocumentTag_Bool_Exp>;
};


export type Subscription_RootDocument_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Document_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Document_Order_By>>;
  where?: InputMaybe<Document_Bool_Exp>;
};


export type Subscription_RootDocument_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootDocument_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Document_Stream_Cursor_Input>>;
  where?: InputMaybe<Document_Bool_Exp>;
};


export type Subscription_RootEmailCampaignLogArgs = {
  distinct_on?: InputMaybe<Array<EmailCampaignLog_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EmailCampaignLog_Order_By>>;
  where?: InputMaybe<EmailCampaignLog_Bool_Exp>;
};


export type Subscription_RootEmailCampaignLog_AggregateArgs = {
  distinct_on?: InputMaybe<Array<EmailCampaignLog_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EmailCampaignLog_Order_By>>;
  where?: InputMaybe<EmailCampaignLog_Bool_Exp>;
};


export type Subscription_RootEmailCampaignLog_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootEmailCampaignLog_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<EmailCampaignLog_Stream_Cursor_Input>>;
  where?: InputMaybe<EmailCampaignLog_Bool_Exp>;
};


export type Subscription_RootEmailCampaignMetricsArgs = {
  distinct_on?: InputMaybe<Array<EmailCampaignMetrics_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EmailCampaignMetrics_Order_By>>;
  where?: InputMaybe<EmailCampaignMetrics_Bool_Exp>;
};


export type Subscription_RootEmailCampaignMetrics_AggregateArgs = {
  distinct_on?: InputMaybe<Array<EmailCampaignMetrics_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EmailCampaignMetrics_Order_By>>;
  where?: InputMaybe<EmailCampaignMetrics_Bool_Exp>;
};


export type Subscription_RootEmailCampaignMetrics_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<EmailCampaignMetrics_Stream_Cursor_Input>>;
  where?: InputMaybe<EmailCampaignMetrics_Bool_Exp>;
};


export type Subscription_RootEmailTemplateArgs = {
  distinct_on?: InputMaybe<Array<EmailTemplate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EmailTemplate_Order_By>>;
  where?: InputMaybe<EmailTemplate_Bool_Exp>;
};


export type Subscription_RootEmailTemplatePatientArgs = {
  distinct_on?: InputMaybe<Array<EmailTemplatePatient_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EmailTemplatePatient_Order_By>>;
  where?: InputMaybe<EmailTemplatePatient_Bool_Exp>;
};


export type Subscription_RootEmailTemplatePatient_AggregateArgs = {
  distinct_on?: InputMaybe<Array<EmailTemplatePatient_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EmailTemplatePatient_Order_By>>;
  where?: InputMaybe<EmailTemplatePatient_Bool_Exp>;
};


export type Subscription_RootEmailTemplatePatient_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootEmailTemplatePatient_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<EmailTemplatePatient_Stream_Cursor_Input>>;
  where?: InputMaybe<EmailTemplatePatient_Bool_Exp>;
};


export type Subscription_RootEmailTemplateSendQueueArgs = {
  distinct_on?: InputMaybe<Array<EmailTemplateSendQueue_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EmailTemplateSendQueue_Order_By>>;
  where?: InputMaybe<EmailTemplateSendQueue_Bool_Exp>;
};


export type Subscription_RootEmailTemplateSendQueue_AggregateArgs = {
  distinct_on?: InputMaybe<Array<EmailTemplateSendQueue_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EmailTemplateSendQueue_Order_By>>;
  where?: InputMaybe<EmailTemplateSendQueue_Bool_Exp>;
};


export type Subscription_RootEmailTemplateSendQueue_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootEmailTemplateSendQueue_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<EmailTemplateSendQueue_Stream_Cursor_Input>>;
  where?: InputMaybe<EmailTemplateSendQueue_Bool_Exp>;
};


export type Subscription_RootEmailTemplate_AggregateArgs = {
  distinct_on?: InputMaybe<Array<EmailTemplate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EmailTemplate_Order_By>>;
  where?: InputMaybe<EmailTemplate_Bool_Exp>;
};


export type Subscription_RootEmailTemplate_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootEmailTemplate_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<EmailTemplate_Stream_Cursor_Input>>;
  where?: InputMaybe<EmailTemplate_Bool_Exp>;
};


export type Subscription_RootExternalLocationArgs = {
  distinct_on?: InputMaybe<Array<ExternalLocation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ExternalLocation_Order_By>>;
  where?: InputMaybe<ExternalLocation_Bool_Exp>;
};


export type Subscription_RootExternalLocation_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ExternalLocation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ExternalLocation_Order_By>>;
  where?: InputMaybe<ExternalLocation_Bool_Exp>;
};


export type Subscription_RootExternalLocation_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootExternalLocation_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ExternalLocation_Stream_Cursor_Input>>;
  where?: InputMaybe<ExternalLocation_Bool_Exp>;
};


export type Subscription_RootExternalProviderArgs = {
  distinct_on?: InputMaybe<Array<ExternalProvider_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ExternalProvider_Order_By>>;
  where?: InputMaybe<ExternalProvider_Bool_Exp>;
};


export type Subscription_RootExternalProvider_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ExternalProvider_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ExternalProvider_Order_By>>;
  where?: InputMaybe<ExternalProvider_Bool_Exp>;
};


export type Subscription_RootExternalProvider_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootExternalProvider_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ExternalProvider_Stream_Cursor_Input>>;
  where?: InputMaybe<ExternalProvider_Bool_Exp>;
};


export type Subscription_RootExternalReviewArgs = {
  distinct_on?: InputMaybe<Array<ExternalReview_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ExternalReview_Order_By>>;
  where?: InputMaybe<ExternalReview_Bool_Exp>;
};


export type Subscription_RootExternalReview_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ExternalReview_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ExternalReview_Order_By>>;
  where?: InputMaybe<ExternalReview_Bool_Exp>;
};


export type Subscription_RootExternalReview_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootExternalReview_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ExternalReview_Stream_Cursor_Input>>;
  where?: InputMaybe<ExternalReview_Bool_Exp>;
};


export type Subscription_RootFeeArgs = {
  distinct_on?: InputMaybe<Array<Fee_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Fee_Order_By>>;
  where?: InputMaybe<Fee_Bool_Exp>;
};


export type Subscription_RootFeeInvoiceLineItemArgs = {
  distinct_on?: InputMaybe<Array<FeeInvoiceLineItem_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<FeeInvoiceLineItem_Order_By>>;
  where?: InputMaybe<FeeInvoiceLineItem_Bool_Exp>;
};


export type Subscription_RootFeeInvoiceLineItem_AggregateArgs = {
  distinct_on?: InputMaybe<Array<FeeInvoiceLineItem_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<FeeInvoiceLineItem_Order_By>>;
  where?: InputMaybe<FeeInvoiceLineItem_Bool_Exp>;
};


export type Subscription_RootFeeInvoiceLineItem_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootFeeInvoiceLineItem_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<FeeInvoiceLineItem_Stream_Cursor_Input>>;
  where?: InputMaybe<FeeInvoiceLineItem_Bool_Exp>;
};


export type Subscription_RootFee_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Fee_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Fee_Order_By>>;
  where?: InputMaybe<Fee_Bool_Exp>;
};


export type Subscription_RootFee_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootFee_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Fee_Stream_Cursor_Input>>;
  where?: InputMaybe<Fee_Bool_Exp>;
};


export type Subscription_RootFinixTerminalArgs = {
  distinct_on?: InputMaybe<Array<FinixTerminal_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<FinixTerminal_Order_By>>;
  where?: InputMaybe<FinixTerminal_Bool_Exp>;
};


export type Subscription_RootFinixTerminal_AggregateArgs = {
  distinct_on?: InputMaybe<Array<FinixTerminal_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<FinixTerminal_Order_By>>;
  where?: InputMaybe<FinixTerminal_Bool_Exp>;
};


export type Subscription_RootFinixTerminal_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootFinixTerminal_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<FinixTerminal_Stream_Cursor_Input>>;
  where?: InputMaybe<FinixTerminal_Bool_Exp>;
};


export type Subscription_RootFlowsheetArgs = {
  distinct_on?: InputMaybe<Array<Flowsheet_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Flowsheet_Order_By>>;
  where?: InputMaybe<Flowsheet_Bool_Exp>;
};


export type Subscription_RootFlowsheetParameterArgs = {
  distinct_on?: InputMaybe<Array<FlowsheetParameter_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<FlowsheetParameter_Order_By>>;
  where?: InputMaybe<FlowsheetParameter_Bool_Exp>;
};


export type Subscription_RootFlowsheetParameterFlowsheetArgs = {
  distinct_on?: InputMaybe<Array<FlowsheetParameterFlowsheet_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<FlowsheetParameterFlowsheet_Order_By>>;
  where?: InputMaybe<FlowsheetParameterFlowsheet_Bool_Exp>;
};


export type Subscription_RootFlowsheetParameterFlowsheet_AggregateArgs = {
  distinct_on?: InputMaybe<Array<FlowsheetParameterFlowsheet_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<FlowsheetParameterFlowsheet_Order_By>>;
  where?: InputMaybe<FlowsheetParameterFlowsheet_Bool_Exp>;
};


export type Subscription_RootFlowsheetParameterFlowsheet_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootFlowsheetParameterFlowsheet_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<FlowsheetParameterFlowsheet_Stream_Cursor_Input>>;
  where?: InputMaybe<FlowsheetParameterFlowsheet_Bool_Exp>;
};


export type Subscription_RootFlowsheetParameterGroupArgs = {
  distinct_on?: InputMaybe<Array<FlowsheetParameterGroup_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<FlowsheetParameterGroup_Order_By>>;
  where?: InputMaybe<FlowsheetParameterGroup_Bool_Exp>;
};


export type Subscription_RootFlowsheetParameterGroup_AggregateArgs = {
  distinct_on?: InputMaybe<Array<FlowsheetParameterGroup_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<FlowsheetParameterGroup_Order_By>>;
  where?: InputMaybe<FlowsheetParameterGroup_Bool_Exp>;
};


export type Subscription_RootFlowsheetParameterGroup_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootFlowsheetParameterGroup_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<FlowsheetParameterGroup_Stream_Cursor_Input>>;
  where?: InputMaybe<FlowsheetParameterGroup_Bool_Exp>;
};


export type Subscription_RootFlowsheetParameter_AggregateArgs = {
  distinct_on?: InputMaybe<Array<FlowsheetParameter_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<FlowsheetParameter_Order_By>>;
  where?: InputMaybe<FlowsheetParameter_Bool_Exp>;
};


export type Subscription_RootFlowsheetParameter_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootFlowsheetParameter_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<FlowsheetParameter_Stream_Cursor_Input>>;
  where?: InputMaybe<FlowsheetParameter_Bool_Exp>;
};


export type Subscription_RootFlowsheet_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Flowsheet_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Flowsheet_Order_By>>;
  where?: InputMaybe<Flowsheet_Bool_Exp>;
};


export type Subscription_RootFlowsheet_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootFlowsheet_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Flowsheet_Stream_Cursor_Input>>;
  where?: InputMaybe<Flowsheet_Bool_Exp>;
};


export type Subscription_RootGenericInvoiceLineItemArgs = {
  distinct_on?: InputMaybe<Array<GenericInvoiceLineItem_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GenericInvoiceLineItem_Order_By>>;
  where?: InputMaybe<GenericInvoiceLineItem_Bool_Exp>;
};


export type Subscription_RootGenericInvoiceLineItemDiscountArgs = {
  distinct_on?: InputMaybe<Array<GenericInvoiceLineItemDiscount_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GenericInvoiceLineItemDiscount_Order_By>>;
  where?: InputMaybe<GenericInvoiceLineItemDiscount_Bool_Exp>;
};


export type Subscription_RootGenericInvoiceLineItemDiscount_AggregateArgs = {
  distinct_on?: InputMaybe<Array<GenericInvoiceLineItemDiscount_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GenericInvoiceLineItemDiscount_Order_By>>;
  where?: InputMaybe<GenericInvoiceLineItemDiscount_Bool_Exp>;
};


export type Subscription_RootGenericInvoiceLineItemDiscount_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootGenericInvoiceLineItemDiscount_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<GenericInvoiceLineItemDiscount_Stream_Cursor_Input>>;
  where?: InputMaybe<GenericInvoiceLineItemDiscount_Bool_Exp>;
};


export type Subscription_RootGenericInvoiceLineItem_AggregateArgs = {
  distinct_on?: InputMaybe<Array<GenericInvoiceLineItem_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GenericInvoiceLineItem_Order_By>>;
  where?: InputMaybe<GenericInvoiceLineItem_Bool_Exp>;
};


export type Subscription_RootGenericInvoiceLineItem_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootGenericInvoiceLineItem_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<GenericInvoiceLineItem_Stream_Cursor_Input>>;
  where?: InputMaybe<GenericInvoiceLineItem_Bool_Exp>;
};


export type Subscription_RootGiftCardArgs = {
  distinct_on?: InputMaybe<Array<GiftCard_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GiftCard_Order_By>>;
  where?: InputMaybe<GiftCard_Bool_Exp>;
};


export type Subscription_RootGiftCard_AggregateArgs = {
  distinct_on?: InputMaybe<Array<GiftCard_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GiftCard_Order_By>>;
  where?: InputMaybe<GiftCard_Bool_Exp>;
};


export type Subscription_RootGiftCard_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootGiftCard_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<GiftCard_Stream_Cursor_Input>>;
  where?: InputMaybe<GiftCard_Bool_Exp>;
};


export type Subscription_RootGoogleAccountArgs = {
  distinct_on?: InputMaybe<Array<GoogleAccount_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GoogleAccount_Order_By>>;
  where?: InputMaybe<GoogleAccount_Bool_Exp>;
};


export type Subscription_RootGoogleAccount_AggregateArgs = {
  distinct_on?: InputMaybe<Array<GoogleAccount_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GoogleAccount_Order_By>>;
  where?: InputMaybe<GoogleAccount_Bool_Exp>;
};


export type Subscription_RootGoogleAccount_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootGoogleAccount_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<GoogleAccount_Stream_Cursor_Input>>;
  where?: InputMaybe<GoogleAccount_Bool_Exp>;
};


export type Subscription_RootGoogleLocationArgs = {
  distinct_on?: InputMaybe<Array<GoogleLocation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GoogleLocation_Order_By>>;
  where?: InputMaybe<GoogleLocation_Bool_Exp>;
};


export type Subscription_RootGoogleLocation_AggregateArgs = {
  distinct_on?: InputMaybe<Array<GoogleLocation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GoogleLocation_Order_By>>;
  where?: InputMaybe<GoogleLocation_Bool_Exp>;
};


export type Subscription_RootGoogleLocation_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootGoogleLocation_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<GoogleLocation_Stream_Cursor_Input>>;
  where?: InputMaybe<GoogleLocation_Bool_Exp>;
};


export type Subscription_RootGoogleReviewArgs = {
  distinct_on?: InputMaybe<Array<GoogleReview_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GoogleReview_Order_By>>;
  where?: InputMaybe<GoogleReview_Bool_Exp>;
};


export type Subscription_RootGoogleReview_AggregateArgs = {
  distinct_on?: InputMaybe<Array<GoogleReview_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GoogleReview_Order_By>>;
  where?: InputMaybe<GoogleReview_Bool_Exp>;
};


export type Subscription_RootGoogleReview_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootGoogleReview_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<GoogleReview_Stream_Cursor_Input>>;
  where?: InputMaybe<GoogleReview_Bool_Exp>;
};


export type Subscription_RootImportJobArgs = {
  distinct_on?: InputMaybe<Array<ImportJob_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ImportJob_Order_By>>;
  where?: InputMaybe<ImportJob_Bool_Exp>;
};


export type Subscription_RootImportJob_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ImportJob_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ImportJob_Order_By>>;
  where?: InputMaybe<ImportJob_Bool_Exp>;
};


export type Subscription_RootImportJob_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootImportJob_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ImportJob_Stream_Cursor_Input>>;
  where?: InputMaybe<ImportJob_Bool_Exp>;
};


export type Subscription_RootImportMetadataArgs = {
  distinct_on?: InputMaybe<Array<ImportMetadata_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ImportMetadata_Order_By>>;
  where?: InputMaybe<ImportMetadata_Bool_Exp>;
};


export type Subscription_RootImportMetadata_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ImportMetadata_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ImportMetadata_Order_By>>;
  where?: InputMaybe<ImportMetadata_Bool_Exp>;
};


export type Subscription_RootImportMetadata_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootImportMetadata_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ImportMetadata_Stream_Cursor_Input>>;
  where?: InputMaybe<ImportMetadata_Bool_Exp>;
};


export type Subscription_RootIncompatibleServicesArgs = {
  distinct_on?: InputMaybe<Array<IncompatibleServices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IncompatibleServices_Order_By>>;
  where?: InputMaybe<IncompatibleServices_Bool_Exp>;
};


export type Subscription_RootIncompatibleServices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<IncompatibleServices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<IncompatibleServices_Order_By>>;
  where?: InputMaybe<IncompatibleServices_Bool_Exp>;
};


export type Subscription_RootIncompatibleServices_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootIncompatibleServices_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<IncompatibleServices_Stream_Cursor_Input>>;
  where?: InputMaybe<IncompatibleServices_Bool_Exp>;
};


export type Subscription_RootInternalAppointmentProviderArgs = {
  distinct_on?: InputMaybe<Array<InternalAppointmentProvider_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<InternalAppointmentProvider_Order_By>>;
  where?: InputMaybe<InternalAppointmentProvider_Bool_Exp>;
};


export type Subscription_RootInternalAppointmentProvider_AggregateArgs = {
  distinct_on?: InputMaybe<Array<InternalAppointmentProvider_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<InternalAppointmentProvider_Order_By>>;
  where?: InputMaybe<InternalAppointmentProvider_Bool_Exp>;
};


export type Subscription_RootInternalAppointmentProvider_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootInternalAppointmentProvider_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<InternalAppointmentProvider_Stream_Cursor_Input>>;
  where?: InputMaybe<InternalAppointmentProvider_Bool_Exp>;
};


export type Subscription_RootInventoryTransactionArgs = {
  distinct_on?: InputMaybe<Array<InventoryTransaction_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<InventoryTransaction_Order_By>>;
  where?: InputMaybe<InventoryTransaction_Bool_Exp>;
};


export type Subscription_RootInventoryTransaction_AggregateArgs = {
  distinct_on?: InputMaybe<Array<InventoryTransaction_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<InventoryTransaction_Order_By>>;
  where?: InputMaybe<InventoryTransaction_Bool_Exp>;
};


export type Subscription_RootInventoryTransaction_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootInventoryTransaction_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<InventoryTransaction_Stream_Cursor_Input>>;
  where?: InputMaybe<InventoryTransaction_Bool_Exp>;
};


export type Subscription_RootInvoiceArgs = {
  distinct_on?: InputMaybe<Array<Invoice_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Invoice_Order_By>>;
  where?: InputMaybe<Invoice_Bool_Exp>;
};


export type Subscription_RootInvoiceConfigurationArgs = {
  distinct_on?: InputMaybe<Array<InvoiceConfiguration_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<InvoiceConfiguration_Order_By>>;
  where?: InputMaybe<InvoiceConfiguration_Bool_Exp>;
};


export type Subscription_RootInvoiceConfiguration_AggregateArgs = {
  distinct_on?: InputMaybe<Array<InvoiceConfiguration_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<InvoiceConfiguration_Order_By>>;
  where?: InputMaybe<InvoiceConfiguration_Bool_Exp>;
};


export type Subscription_RootInvoiceConfiguration_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootInvoiceConfiguration_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<InvoiceConfiguration_Stream_Cursor_Input>>;
  where?: InputMaybe<InvoiceConfiguration_Bool_Exp>;
};


export type Subscription_RootInvoiceCouponArgs = {
  distinct_on?: InputMaybe<Array<InvoiceCoupon_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<InvoiceCoupon_Order_By>>;
  where?: InputMaybe<InvoiceCoupon_Bool_Exp>;
};


export type Subscription_RootInvoiceCoupon_AggregateArgs = {
  distinct_on?: InputMaybe<Array<InvoiceCoupon_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<InvoiceCoupon_Order_By>>;
  where?: InputMaybe<InvoiceCoupon_Bool_Exp>;
};


export type Subscription_RootInvoiceCoupon_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootInvoiceCoupon_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<InvoiceCoupon_Stream_Cursor_Input>>;
  where?: InputMaybe<InvoiceCoupon_Bool_Exp>;
};


export type Subscription_RootInvoiceMembershipArgs = {
  distinct_on?: InputMaybe<Array<InvoiceMembership_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<InvoiceMembership_Order_By>>;
  where?: InputMaybe<InvoiceMembership_Bool_Exp>;
};


export type Subscription_RootInvoiceMembership_AggregateArgs = {
  distinct_on?: InputMaybe<Array<InvoiceMembership_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<InvoiceMembership_Order_By>>;
  where?: InputMaybe<InvoiceMembership_Bool_Exp>;
};


export type Subscription_RootInvoiceMembership_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootInvoiceMembership_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<InvoiceMembership_Stream_Cursor_Input>>;
  where?: InputMaybe<InvoiceMembership_Bool_Exp>;
};


export type Subscription_RootInvoicePackageArgs = {
  distinct_on?: InputMaybe<Array<InvoicePackage_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<InvoicePackage_Order_By>>;
  where?: InputMaybe<InvoicePackage_Bool_Exp>;
};


export type Subscription_RootInvoicePackage_AggregateArgs = {
  distinct_on?: InputMaybe<Array<InvoicePackage_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<InvoicePackage_Order_By>>;
  where?: InputMaybe<InvoicePackage_Bool_Exp>;
};


export type Subscription_RootInvoicePackage_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootInvoicePackage_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<InvoicePackage_Stream_Cursor_Input>>;
  where?: InputMaybe<InvoicePackage_Bool_Exp>;
};


export type Subscription_RootInvoiceProductItemArgs = {
  distinct_on?: InputMaybe<Array<InvoiceProductItem_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<InvoiceProductItem_Order_By>>;
  where?: InputMaybe<InvoiceProductItem_Bool_Exp>;
};


export type Subscription_RootInvoiceProductItem_AggregateArgs = {
  distinct_on?: InputMaybe<Array<InvoiceProductItem_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<InvoiceProductItem_Order_By>>;
  where?: InputMaybe<InvoiceProductItem_Bool_Exp>;
};


export type Subscription_RootInvoiceProductItem_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootInvoiceProductItem_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<InvoiceProductItem_Stream_Cursor_Input>>;
  where?: InputMaybe<InvoiceProductItem_Bool_Exp>;
};


export type Subscription_RootInvoiceServiceConsumableArgs = {
  distinct_on?: InputMaybe<Array<InvoiceServiceConsumable_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<InvoiceServiceConsumable_Order_By>>;
  where?: InputMaybe<InvoiceServiceConsumable_Bool_Exp>;
};


export type Subscription_RootInvoiceServiceConsumable_AggregateArgs = {
  distinct_on?: InputMaybe<Array<InvoiceServiceConsumable_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<InvoiceServiceConsumable_Order_By>>;
  where?: InputMaybe<InvoiceServiceConsumable_Bool_Exp>;
};


export type Subscription_RootInvoiceServiceConsumable_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootInvoiceServiceConsumable_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<InvoiceServiceConsumable_Stream_Cursor_Input>>;
  where?: InputMaybe<InvoiceServiceConsumable_Bool_Exp>;
};


export type Subscription_RootInvoiceServiceItemArgs = {
  distinct_on?: InputMaybe<Array<InvoiceServiceItem_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<InvoiceServiceItem_Order_By>>;
  where?: InputMaybe<InvoiceServiceItem_Bool_Exp>;
};


export type Subscription_RootInvoiceServiceItem_AggregateArgs = {
  distinct_on?: InputMaybe<Array<InvoiceServiceItem_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<InvoiceServiceItem_Order_By>>;
  where?: InputMaybe<InvoiceServiceItem_Bool_Exp>;
};


export type Subscription_RootInvoiceServiceItem_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootInvoiceServiceItem_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<InvoiceServiceItem_Stream_Cursor_Input>>;
  where?: InputMaybe<InvoiceServiceItem_Bool_Exp>;
};


export type Subscription_RootInvoiceTemplateArgs = {
  distinct_on?: InputMaybe<Array<InvoiceTemplate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<InvoiceTemplate_Order_By>>;
  where?: InputMaybe<InvoiceTemplate_Bool_Exp>;
};


export type Subscription_RootInvoiceTemplate_AggregateArgs = {
  distinct_on?: InputMaybe<Array<InvoiceTemplate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<InvoiceTemplate_Order_By>>;
  where?: InputMaybe<InvoiceTemplate_Bool_Exp>;
};


export type Subscription_RootInvoiceTemplate_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootInvoiceTemplate_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<InvoiceTemplate_Stream_Cursor_Input>>;
  where?: InputMaybe<InvoiceTemplate_Bool_Exp>;
};


export type Subscription_RootInvoice_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Invoice_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Invoice_Order_By>>;
  where?: InputMaybe<Invoice_Bool_Exp>;
};


export type Subscription_RootInvoice_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootInvoice_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Invoice_Stream_Cursor_Input>>;
  where?: InputMaybe<Invoice_Bool_Exp>;
};


export type Subscription_RootJobArgs = {
  distinct_on?: InputMaybe<Array<Job_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Job_Order_By>>;
  where?: InputMaybe<Job_Bool_Exp>;
};


export type Subscription_RootJobStatusArgs = {
  distinct_on?: InputMaybe<Array<JobStatus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<JobStatus_Order_By>>;
  where?: InputMaybe<JobStatus_Bool_Exp>;
};


export type Subscription_RootJobStatus_AggregateArgs = {
  distinct_on?: InputMaybe<Array<JobStatus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<JobStatus_Order_By>>;
  where?: InputMaybe<JobStatus_Bool_Exp>;
};


export type Subscription_RootJobStatus_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootJobStatus_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<JobStatus_Stream_Cursor_Input>>;
  where?: InputMaybe<JobStatus_Bool_Exp>;
};


export type Subscription_RootJob_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Job_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Job_Order_By>>;
  where?: InputMaybe<Job_Bool_Exp>;
};


export type Subscription_RootJob_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootJob_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Job_Stream_Cursor_Input>>;
  where?: InputMaybe<Job_Bool_Exp>;
};


export type Subscription_RootLabResultArgs = {
  distinct_on?: InputMaybe<Array<LabResult_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<LabResult_Order_By>>;
  where?: InputMaybe<LabResult_Bool_Exp>;
};


export type Subscription_RootLabResult_AggregateArgs = {
  distinct_on?: InputMaybe<Array<LabResult_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<LabResult_Order_By>>;
  where?: InputMaybe<LabResult_Bool_Exp>;
};


export type Subscription_RootLabResult_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootLabResult_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<LabResult_Stream_Cursor_Input>>;
  where?: InputMaybe<LabResult_Bool_Exp>;
};


export type Subscription_RootLeadFormArgs = {
  distinct_on?: InputMaybe<Array<LeadForm_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<LeadForm_Order_By>>;
  where?: InputMaybe<LeadForm_Bool_Exp>;
};


export type Subscription_RootLeadFormEntryArgs = {
  distinct_on?: InputMaybe<Array<LeadFormEntry_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<LeadFormEntry_Order_By>>;
  where?: InputMaybe<LeadFormEntry_Bool_Exp>;
};


export type Subscription_RootLeadFormEntryTagArgs = {
  distinct_on?: InputMaybe<Array<LeadFormEntryTag_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<LeadFormEntryTag_Order_By>>;
  where?: InputMaybe<LeadFormEntryTag_Bool_Exp>;
};


export type Subscription_RootLeadFormEntryTag_AggregateArgs = {
  distinct_on?: InputMaybe<Array<LeadFormEntryTag_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<LeadFormEntryTag_Order_By>>;
  where?: InputMaybe<LeadFormEntryTag_Bool_Exp>;
};


export type Subscription_RootLeadFormEntryTag_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootLeadFormEntryTag_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<LeadFormEntryTag_Stream_Cursor_Input>>;
  where?: InputMaybe<LeadFormEntryTag_Bool_Exp>;
};


export type Subscription_RootLeadFormEntry_AggregateArgs = {
  distinct_on?: InputMaybe<Array<LeadFormEntry_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<LeadFormEntry_Order_By>>;
  where?: InputMaybe<LeadFormEntry_Bool_Exp>;
};


export type Subscription_RootLeadFormEntry_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootLeadFormEntry_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<LeadFormEntry_Stream_Cursor_Input>>;
  where?: InputMaybe<LeadFormEntry_Bool_Exp>;
};


export type Subscription_RootLeadFormFieldArgs = {
  distinct_on?: InputMaybe<Array<LeadFormField_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<LeadFormField_Order_By>>;
  where?: InputMaybe<LeadFormField_Bool_Exp>;
};


export type Subscription_RootLeadFormFieldValueArgs = {
  distinct_on?: InputMaybe<Array<LeadFormFieldValue_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<LeadFormFieldValue_Order_By>>;
  where?: InputMaybe<LeadFormFieldValue_Bool_Exp>;
};


export type Subscription_RootLeadFormFieldValue_AggregateArgs = {
  distinct_on?: InputMaybe<Array<LeadFormFieldValue_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<LeadFormFieldValue_Order_By>>;
  where?: InputMaybe<LeadFormFieldValue_Bool_Exp>;
};


export type Subscription_RootLeadFormFieldValue_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootLeadFormFieldValue_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<LeadFormFieldValue_Stream_Cursor_Input>>;
  where?: InputMaybe<LeadFormFieldValue_Bool_Exp>;
};


export type Subscription_RootLeadFormField_AggregateArgs = {
  distinct_on?: InputMaybe<Array<LeadFormField_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<LeadFormField_Order_By>>;
  where?: InputMaybe<LeadFormField_Bool_Exp>;
};


export type Subscription_RootLeadFormField_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootLeadFormField_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<LeadFormField_Stream_Cursor_Input>>;
  where?: InputMaybe<LeadFormField_Bool_Exp>;
};


export type Subscription_RootLeadFormLinkArgs = {
  distinct_on?: InputMaybe<Array<LeadFormLink_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<LeadFormLink_Order_By>>;
  where?: InputMaybe<LeadFormLink_Bool_Exp>;
};


export type Subscription_RootLeadFormLink_AggregateArgs = {
  distinct_on?: InputMaybe<Array<LeadFormLink_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<LeadFormLink_Order_By>>;
  where?: InputMaybe<LeadFormLink_Bool_Exp>;
};


export type Subscription_RootLeadFormLink_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootLeadFormLink_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<LeadFormLink_Stream_Cursor_Input>>;
  where?: InputMaybe<LeadFormLink_Bool_Exp>;
};


export type Subscription_RootLeadFormStepArgs = {
  distinct_on?: InputMaybe<Array<LeadFormStep_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<LeadFormStep_Order_By>>;
  where?: InputMaybe<LeadFormStep_Bool_Exp>;
};


export type Subscription_RootLeadFormStep_AggregateArgs = {
  distinct_on?: InputMaybe<Array<LeadFormStep_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<LeadFormStep_Order_By>>;
  where?: InputMaybe<LeadFormStep_Bool_Exp>;
};


export type Subscription_RootLeadFormStep_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootLeadFormStep_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<LeadFormStep_Stream_Cursor_Input>>;
  where?: InputMaybe<LeadFormStep_Bool_Exp>;
};


export type Subscription_RootLeadForm_AggregateArgs = {
  distinct_on?: InputMaybe<Array<LeadForm_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<LeadForm_Order_By>>;
  where?: InputMaybe<LeadForm_Bool_Exp>;
};


export type Subscription_RootLeadForm_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootLeadForm_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<LeadForm_Stream_Cursor_Input>>;
  where?: InputMaybe<LeadForm_Bool_Exp>;
};


export type Subscription_RootLeadTagArgs = {
  distinct_on?: InputMaybe<Array<LeadTag_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<LeadTag_Order_By>>;
  where?: InputMaybe<LeadTag_Bool_Exp>;
};


export type Subscription_RootLeadTag_AggregateArgs = {
  distinct_on?: InputMaybe<Array<LeadTag_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<LeadTag_Order_By>>;
  where?: InputMaybe<LeadTag_Bool_Exp>;
};


export type Subscription_RootLeadTag_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootLeadTag_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<LeadTag_Stream_Cursor_Input>>;
  where?: InputMaybe<LeadTag_Bool_Exp>;
};


export type Subscription_RootLeadTrackedEventArgs = {
  distinct_on?: InputMaybe<Array<LeadTrackedEvent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<LeadTrackedEvent_Order_By>>;
  where?: InputMaybe<LeadTrackedEvent_Bool_Exp>;
};


export type Subscription_RootLeadTrackedEvent_AggregateArgs = {
  distinct_on?: InputMaybe<Array<LeadTrackedEvent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<LeadTrackedEvent_Order_By>>;
  where?: InputMaybe<LeadTrackedEvent_Bool_Exp>;
};


export type Subscription_RootLeadTrackedEvent_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootLeadTrackedEvent_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<LeadTrackedEvent_Stream_Cursor_Input>>;
  where?: InputMaybe<LeadTrackedEvent_Bool_Exp>;
};


export type Subscription_RootLocationArgs = {
  distinct_on?: InputMaybe<Array<Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Location_Order_By>>;
  where?: InputMaybe<Location_Bool_Exp>;
};


export type Subscription_RootLocationCouponArgs = {
  distinct_on?: InputMaybe<Array<LocationCoupon_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<LocationCoupon_Order_By>>;
  where?: InputMaybe<LocationCoupon_Bool_Exp>;
};


export type Subscription_RootLocationCoupon_AggregateArgs = {
  distinct_on?: InputMaybe<Array<LocationCoupon_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<LocationCoupon_Order_By>>;
  where?: InputMaybe<LocationCoupon_Bool_Exp>;
};


export type Subscription_RootLocationCoupon_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootLocationCoupon_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<LocationCoupon_Stream_Cursor_Input>>;
  where?: InputMaybe<LocationCoupon_Bool_Exp>;
};


export type Subscription_RootLocationProviderArgs = {
  distinct_on?: InputMaybe<Array<LocationProvider_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<LocationProvider_Order_By>>;
  where?: InputMaybe<LocationProvider_Bool_Exp>;
};


export type Subscription_RootLocationProvider_AggregateArgs = {
  distinct_on?: InputMaybe<Array<LocationProvider_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<LocationProvider_Order_By>>;
  where?: InputMaybe<LocationProvider_Bool_Exp>;
};


export type Subscription_RootLocationProvider_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootLocationProvider_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<LocationProvider_Stream_Cursor_Input>>;
  where?: InputMaybe<LocationProvider_Bool_Exp>;
};


export type Subscription_RootLocationScheduleArgs = {
  distinct_on?: InputMaybe<Array<LocationSchedule_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<LocationSchedule_Order_By>>;
  where?: InputMaybe<LocationSchedule_Bool_Exp>;
};


export type Subscription_RootLocationSchedule_AggregateArgs = {
  distinct_on?: InputMaybe<Array<LocationSchedule_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<LocationSchedule_Order_By>>;
  where?: InputMaybe<LocationSchedule_Bool_Exp>;
};


export type Subscription_RootLocationSchedule_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootLocationSchedule_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<LocationSchedule_Stream_Cursor_Input>>;
  where?: InputMaybe<LocationSchedule_Bool_Exp>;
};


export type Subscription_RootLocationServiceArgs = {
  distinct_on?: InputMaybe<Array<LocationService_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<LocationService_Order_By>>;
  where?: InputMaybe<LocationService_Bool_Exp>;
};


export type Subscription_RootLocationService_AggregateArgs = {
  distinct_on?: InputMaybe<Array<LocationService_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<LocationService_Order_By>>;
  where?: InputMaybe<LocationService_Bool_Exp>;
};


export type Subscription_RootLocationService_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootLocationService_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<LocationService_Stream_Cursor_Input>>;
  where?: InputMaybe<LocationService_Bool_Exp>;
};


export type Subscription_RootLocation_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Location_Order_By>>;
  where?: InputMaybe<Location_Bool_Exp>;
};


export type Subscription_RootLocation_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootLocation_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Location_Stream_Cursor_Input>>;
  where?: InputMaybe<Location_Bool_Exp>;
};


export type Subscription_RootMarketingTagArgs = {
  distinct_on?: InputMaybe<Array<MarketingTag_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MarketingTag_Order_By>>;
  where?: InputMaybe<MarketingTag_Bool_Exp>;
};


export type Subscription_RootMarketingTagEmailArgs = {
  distinct_on?: InputMaybe<Array<MarketingTagEmail_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MarketingTagEmail_Order_By>>;
  where?: InputMaybe<MarketingTagEmail_Bool_Exp>;
};


export type Subscription_RootMarketingTagEmail_AggregateArgs = {
  distinct_on?: InputMaybe<Array<MarketingTagEmail_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MarketingTagEmail_Order_By>>;
  where?: InputMaybe<MarketingTagEmail_Bool_Exp>;
};


export type Subscription_RootMarketingTagEmail_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootMarketingTagEmail_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<MarketingTagEmail_Stream_Cursor_Input>>;
  where?: InputMaybe<MarketingTagEmail_Bool_Exp>;
};


export type Subscription_RootMarketingTagSmsArgs = {
  distinct_on?: InputMaybe<Array<MarketingTagSms_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MarketingTagSms_Order_By>>;
  where?: InputMaybe<MarketingTagSms_Bool_Exp>;
};


export type Subscription_RootMarketingTagSms_AggregateArgs = {
  distinct_on?: InputMaybe<Array<MarketingTagSms_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MarketingTagSms_Order_By>>;
  where?: InputMaybe<MarketingTagSms_Bool_Exp>;
};


export type Subscription_RootMarketingTagSms_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootMarketingTagSms_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<MarketingTagSms_Stream_Cursor_Input>>;
  where?: InputMaybe<MarketingTagSms_Bool_Exp>;
};


export type Subscription_RootMarketingTag_AggregateArgs = {
  distinct_on?: InputMaybe<Array<MarketingTag_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MarketingTag_Order_By>>;
  where?: InputMaybe<MarketingTag_Bool_Exp>;
};


export type Subscription_RootMarketingTag_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootMarketingTag_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<MarketingTag_Stream_Cursor_Input>>;
  where?: InputMaybe<MarketingTag_Bool_Exp>;
};


export type Subscription_RootMediaTagArgs = {
  distinct_on?: InputMaybe<Array<MediaTag_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MediaTag_Order_By>>;
  where?: InputMaybe<MediaTag_Bool_Exp>;
};


export type Subscription_RootMediaTag_AggregateArgs = {
  distinct_on?: InputMaybe<Array<MediaTag_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MediaTag_Order_By>>;
  where?: InputMaybe<MediaTag_Bool_Exp>;
};


export type Subscription_RootMediaTag_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootMediaTag_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<MediaTag_Stream_Cursor_Input>>;
  where?: InputMaybe<MediaTag_Bool_Exp>;
};


export type Subscription_RootMembershipArgs = {
  distinct_on?: InputMaybe<Array<Membership_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Membership_Order_By>>;
  where?: InputMaybe<Membership_Bool_Exp>;
};


export type Subscription_RootMembershipCouponArgs = {
  distinct_on?: InputMaybe<Array<MembershipCoupon_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MembershipCoupon_Order_By>>;
  where?: InputMaybe<MembershipCoupon_Bool_Exp>;
};


export type Subscription_RootMembershipCoupon_AggregateArgs = {
  distinct_on?: InputMaybe<Array<MembershipCoupon_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MembershipCoupon_Order_By>>;
  where?: InputMaybe<MembershipCoupon_Bool_Exp>;
};


export type Subscription_RootMembershipCoupon_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootMembershipCoupon_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<MembershipCoupon_Stream_Cursor_Input>>;
  where?: InputMaybe<MembershipCoupon_Bool_Exp>;
};


export type Subscription_RootMembershipTypeArgs = {
  distinct_on?: InputMaybe<Array<MembershipType_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MembershipType_Order_By>>;
  where?: InputMaybe<MembershipType_Bool_Exp>;
};


export type Subscription_RootMembershipType_AggregateArgs = {
  distinct_on?: InputMaybe<Array<MembershipType_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MembershipType_Order_By>>;
  where?: InputMaybe<MembershipType_Bool_Exp>;
};


export type Subscription_RootMembershipType_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootMembershipType_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<MembershipType_Stream_Cursor_Input>>;
  where?: InputMaybe<MembershipType_Bool_Exp>;
};


export type Subscription_RootMembership_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Membership_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Membership_Order_By>>;
  where?: InputMaybe<Membership_Bool_Exp>;
};


export type Subscription_RootMembership_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootMembership_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Membership_Stream_Cursor_Input>>;
  where?: InputMaybe<Membership_Bool_Exp>;
};


export type Subscription_RootNoteArgs = {
  distinct_on?: InputMaybe<Array<Note_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Note_Order_By>>;
  where?: InputMaybe<Note_Bool_Exp>;
};


export type Subscription_RootNoteSignatureArgs = {
  distinct_on?: InputMaybe<Array<NoteSignature_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<NoteSignature_Order_By>>;
  where?: InputMaybe<NoteSignature_Bool_Exp>;
};


export type Subscription_RootNoteSignature_AggregateArgs = {
  distinct_on?: InputMaybe<Array<NoteSignature_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<NoteSignature_Order_By>>;
  where?: InputMaybe<NoteSignature_Bool_Exp>;
};


export type Subscription_RootNoteSignature_By_PkArgs = {
  noteId: Scalars['uuid'];
  signatureId: Scalars['uuid'];
};


export type Subscription_RootNoteSignature_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<NoteSignature_Stream_Cursor_Input>>;
  where?: InputMaybe<NoteSignature_Bool_Exp>;
};


export type Subscription_RootNote_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Note_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Note_Order_By>>;
  where?: InputMaybe<Note_Bool_Exp>;
};


export type Subscription_RootNote_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootNote_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Note_Stream_Cursor_Input>>;
  where?: InputMaybe<Note_Bool_Exp>;
};


export type Subscription_RootOrganizationArgs = {
  distinct_on?: InputMaybe<Array<Organization_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_Order_By>>;
  where?: InputMaybe<Organization_Bool_Exp>;
};


export type Subscription_RootOrganizationConfigurationArgs = {
  distinct_on?: InputMaybe<Array<OrganizationConfiguration_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<OrganizationConfiguration_Order_By>>;
  where?: InputMaybe<OrganizationConfiguration_Bool_Exp>;
};


export type Subscription_RootOrganizationConfiguration_AggregateArgs = {
  distinct_on?: InputMaybe<Array<OrganizationConfiguration_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<OrganizationConfiguration_Order_By>>;
  where?: InputMaybe<OrganizationConfiguration_Bool_Exp>;
};


export type Subscription_RootOrganizationConfiguration_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootOrganizationConfiguration_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<OrganizationConfiguration_Stream_Cursor_Input>>;
  where?: InputMaybe<OrganizationConfiguration_Bool_Exp>;
};


export type Subscription_RootOrganization_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Organization_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_Order_By>>;
  where?: InputMaybe<Organization_Bool_Exp>;
};


export type Subscription_RootOrganization_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootOrganization_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Organization_Stream_Cursor_Input>>;
  where?: InputMaybe<Organization_Bool_Exp>;
};


export type Subscription_RootPackageArgs = {
  distinct_on?: InputMaybe<Array<Package_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Package_Order_By>>;
  where?: InputMaybe<Package_Bool_Exp>;
};


export type Subscription_RootPackage_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Package_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Package_Order_By>>;
  where?: InputMaybe<Package_Bool_Exp>;
};


export type Subscription_RootPackage_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootPackage_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Package_Stream_Cursor_Input>>;
  where?: InputMaybe<Package_Bool_Exp>;
};


export type Subscription_RootPatientArgs = {
  distinct_on?: InputMaybe<Array<Patient_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Patient_Order_By>>;
  where?: InputMaybe<Patient_Bool_Exp>;
};


export type Subscription_RootPatientAddressArgs = {
  distinct_on?: InputMaybe<Array<PatientAddress_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PatientAddress_Order_By>>;
  where?: InputMaybe<PatientAddress_Bool_Exp>;
};


export type Subscription_RootPatientAddress_AggregateArgs = {
  distinct_on?: InputMaybe<Array<PatientAddress_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PatientAddress_Order_By>>;
  where?: InputMaybe<PatientAddress_Bool_Exp>;
};


export type Subscription_RootPatientAddress_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootPatientAddress_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<PatientAddress_Stream_Cursor_Input>>;
  where?: InputMaybe<PatientAddress_Bool_Exp>;
};


export type Subscription_RootPatientConsentArgs = {
  distinct_on?: InputMaybe<Array<PatientConsent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PatientConsent_Order_By>>;
  where?: InputMaybe<PatientConsent_Bool_Exp>;
};


export type Subscription_RootPatientConsent_AggregateArgs = {
  distinct_on?: InputMaybe<Array<PatientConsent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PatientConsent_Order_By>>;
  where?: InputMaybe<PatientConsent_Bool_Exp>;
};


export type Subscription_RootPatientConsent_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootPatientConsent_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<PatientConsent_Stream_Cursor_Input>>;
  where?: InputMaybe<PatientConsent_Bool_Exp>;
};


export type Subscription_RootPatientConversationArgs = {
  distinct_on?: InputMaybe<Array<PatientConversation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PatientConversation_Order_By>>;
  where?: InputMaybe<PatientConversation_Bool_Exp>;
};


export type Subscription_RootPatientConversation_AggregateArgs = {
  distinct_on?: InputMaybe<Array<PatientConversation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PatientConversation_Order_By>>;
  where?: InputMaybe<PatientConversation_Bool_Exp>;
};


export type Subscription_RootPatientConversation_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootPatientConversation_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<PatientConversation_Stream_Cursor_Input>>;
  where?: InputMaybe<PatientConversation_Bool_Exp>;
};


export type Subscription_RootPatientFlowsheetDataArgs = {
  distinct_on?: InputMaybe<Array<PatientFlowsheetData_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PatientFlowsheetData_Order_By>>;
  where?: InputMaybe<PatientFlowsheetData_Bool_Exp>;
};


export type Subscription_RootPatientFlowsheetData_AggregateArgs = {
  distinct_on?: InputMaybe<Array<PatientFlowsheetData_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PatientFlowsheetData_Order_By>>;
  where?: InputMaybe<PatientFlowsheetData_Bool_Exp>;
};


export type Subscription_RootPatientFlowsheetData_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootPatientFlowsheetData_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<PatientFlowsheetData_Stream_Cursor_Input>>;
  where?: InputMaybe<PatientFlowsheetData_Bool_Exp>;
};


export type Subscription_RootPatientFlowsheetRowArgs = {
  distinct_on?: InputMaybe<Array<PatientFlowsheetRow_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PatientFlowsheetRow_Order_By>>;
  where?: InputMaybe<PatientFlowsheetRow_Bool_Exp>;
};


export type Subscription_RootPatientFlowsheetRow_AggregateArgs = {
  distinct_on?: InputMaybe<Array<PatientFlowsheetRow_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PatientFlowsheetRow_Order_By>>;
  where?: InputMaybe<PatientFlowsheetRow_Bool_Exp>;
};


export type Subscription_RootPatientFlowsheetRow_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootPatientFlowsheetRow_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<PatientFlowsheetRow_Stream_Cursor_Input>>;
  where?: InputMaybe<PatientFlowsheetRow_Bool_Exp>;
};


export type Subscription_RootPatientInviteArgs = {
  distinct_on?: InputMaybe<Array<PatientInvite_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PatientInvite_Order_By>>;
  where?: InputMaybe<PatientInvite_Bool_Exp>;
};


export type Subscription_RootPatientInvite_AggregateArgs = {
  distinct_on?: InputMaybe<Array<PatientInvite_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PatientInvite_Order_By>>;
  where?: InputMaybe<PatientInvite_Bool_Exp>;
};


export type Subscription_RootPatientInvite_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootPatientInvite_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<PatientInvite_Stream_Cursor_Input>>;
  where?: InputMaybe<PatientInvite_Bool_Exp>;
};


export type Subscription_RootPatientMembershipArgs = {
  distinct_on?: InputMaybe<Array<PatientMembership_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PatientMembership_Order_By>>;
  where?: InputMaybe<PatientMembership_Bool_Exp>;
};


export type Subscription_RootPatientMembership_AggregateArgs = {
  distinct_on?: InputMaybe<Array<PatientMembership_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PatientMembership_Order_By>>;
  where?: InputMaybe<PatientMembership_Bool_Exp>;
};


export type Subscription_RootPatientMembership_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootPatientMembership_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<PatientMembership_Stream_Cursor_Input>>;
  where?: InputMaybe<PatientMembership_Bool_Exp>;
};


export type Subscription_RootPatientSystemNoticeArgs = {
  distinct_on?: InputMaybe<Array<PatientSystemNotice_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PatientSystemNotice_Order_By>>;
  where?: InputMaybe<PatientSystemNotice_Bool_Exp>;
};


export type Subscription_RootPatientSystemNotice_AggregateArgs = {
  distinct_on?: InputMaybe<Array<PatientSystemNotice_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PatientSystemNotice_Order_By>>;
  where?: InputMaybe<PatientSystemNotice_Bool_Exp>;
};


export type Subscription_RootPatientSystemNotice_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootPatientSystemNotice_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<PatientSystemNotice_Stream_Cursor_Input>>;
  where?: InputMaybe<PatientSystemNotice_Bool_Exp>;
};


export type Subscription_RootPatientTagArgs = {
  distinct_on?: InputMaybe<Array<PatientTag_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PatientTag_Order_By>>;
  where?: InputMaybe<PatientTag_Bool_Exp>;
};


export type Subscription_RootPatientTag_AggregateArgs = {
  distinct_on?: InputMaybe<Array<PatientTag_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PatientTag_Order_By>>;
  where?: InputMaybe<PatientTag_Bool_Exp>;
};


export type Subscription_RootPatientTag_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootPatientTag_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<PatientTag_Stream_Cursor_Input>>;
  where?: InputMaybe<PatientTag_Bool_Exp>;
};


export type Subscription_RootPatientTagsArgs = {
  distinct_on?: InputMaybe<Array<PatientTags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PatientTags_Order_By>>;
  where?: InputMaybe<PatientTags_Bool_Exp>;
};


export type Subscription_RootPatientTags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<PatientTags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PatientTags_Order_By>>;
  where?: InputMaybe<PatientTags_Bool_Exp>;
};


export type Subscription_RootPatientTags_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootPatientTags_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<PatientTags_Stream_Cursor_Input>>;
  where?: InputMaybe<PatientTags_Bool_Exp>;
};


export type Subscription_RootPatientWalletArgs = {
  distinct_on?: InputMaybe<Array<PatientWallet_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PatientWallet_Order_By>>;
  where?: InputMaybe<PatientWallet_Bool_Exp>;
};


export type Subscription_RootPatientWalletCouponArgs = {
  distinct_on?: InputMaybe<Array<PatientWalletCoupon_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PatientWalletCoupon_Order_By>>;
  where?: InputMaybe<PatientWalletCoupon_Bool_Exp>;
};


export type Subscription_RootPatientWalletCoupon_AggregateArgs = {
  distinct_on?: InputMaybe<Array<PatientWalletCoupon_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PatientWalletCoupon_Order_By>>;
  where?: InputMaybe<PatientWalletCoupon_Bool_Exp>;
};


export type Subscription_RootPatientWalletCoupon_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootPatientWalletCoupon_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<PatientWalletCoupon_Stream_Cursor_Input>>;
  where?: InputMaybe<PatientWalletCoupon_Bool_Exp>;
};


export type Subscription_RootPatientWalletTransactionArgs = {
  distinct_on?: InputMaybe<Array<PatientWalletTransaction_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PatientWalletTransaction_Order_By>>;
  where?: InputMaybe<PatientWalletTransaction_Bool_Exp>;
};


export type Subscription_RootPatientWalletTransaction_AggregateArgs = {
  distinct_on?: InputMaybe<Array<PatientWalletTransaction_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PatientWalletTransaction_Order_By>>;
  where?: InputMaybe<PatientWalletTransaction_Bool_Exp>;
};


export type Subscription_RootPatientWalletTransaction_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootPatientWalletTransaction_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<PatientWalletTransaction_Stream_Cursor_Input>>;
  where?: InputMaybe<PatientWalletTransaction_Bool_Exp>;
};


export type Subscription_RootPatientWallet_AggregateArgs = {
  distinct_on?: InputMaybe<Array<PatientWallet_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PatientWallet_Order_By>>;
  where?: InputMaybe<PatientWallet_Bool_Exp>;
};


export type Subscription_RootPatientWallet_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootPatientWallet_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<PatientWallet_Stream_Cursor_Input>>;
  where?: InputMaybe<PatientWallet_Bool_Exp>;
};


export type Subscription_RootPatientWorkspaceArgs = {
  distinct_on?: InputMaybe<Array<PatientWorkspace_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PatientWorkspace_Order_By>>;
  where?: InputMaybe<PatientWorkspace_Bool_Exp>;
};


export type Subscription_RootPatientWorkspace_AggregateArgs = {
  distinct_on?: InputMaybe<Array<PatientWorkspace_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PatientWorkspace_Order_By>>;
  where?: InputMaybe<PatientWorkspace_Bool_Exp>;
};


export type Subscription_RootPatientWorkspace_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootPatientWorkspace_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<PatientWorkspace_Stream_Cursor_Input>>;
  where?: InputMaybe<PatientWorkspace_Bool_Exp>;
};


export type Subscription_RootPatient_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Patient_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Patient_Order_By>>;
  where?: InputMaybe<Patient_Bool_Exp>;
};


export type Subscription_RootPatient_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootPatient_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Patient_Stream_Cursor_Input>>;
  where?: InputMaybe<Patient_Bool_Exp>;
};


export type Subscription_RootPaymentArgs = {
  distinct_on?: InputMaybe<Array<Payment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Payment_Order_By>>;
  where?: InputMaybe<Payment_Bool_Exp>;
};


export type Subscription_RootPaymentMethodArgs = {
  distinct_on?: InputMaybe<Array<PaymentMethod_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PaymentMethod_Order_By>>;
  where?: InputMaybe<PaymentMethod_Bool_Exp>;
};


export type Subscription_RootPaymentMethod_AggregateArgs = {
  distinct_on?: InputMaybe<Array<PaymentMethod_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PaymentMethod_Order_By>>;
  where?: InputMaybe<PaymentMethod_Bool_Exp>;
};


export type Subscription_RootPaymentMethod_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootPaymentMethod_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<PaymentMethod_Stream_Cursor_Input>>;
  where?: InputMaybe<PaymentMethod_Bool_Exp>;
};


export type Subscription_RootPaymentRelationshipArgs = {
  distinct_on?: InputMaybe<Array<PaymentRelationship_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PaymentRelationship_Order_By>>;
  where?: InputMaybe<PaymentRelationship_Bool_Exp>;
};


export type Subscription_RootPaymentRelationship_AggregateArgs = {
  distinct_on?: InputMaybe<Array<PaymentRelationship_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PaymentRelationship_Order_By>>;
  where?: InputMaybe<PaymentRelationship_Bool_Exp>;
};


export type Subscription_RootPaymentRelationship_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootPaymentRelationship_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<PaymentRelationship_Stream_Cursor_Input>>;
  where?: InputMaybe<PaymentRelationship_Bool_Exp>;
};


export type Subscription_RootPayment_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Payment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Payment_Order_By>>;
  where?: InputMaybe<Payment_Bool_Exp>;
};


export type Subscription_RootPayment_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootPayment_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Payment_Stream_Cursor_Input>>;
  where?: InputMaybe<Payment_Bool_Exp>;
};


export type Subscription_RootPayrixTerminalArgs = {
  distinct_on?: InputMaybe<Array<PayrixTerminal_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PayrixTerminal_Order_By>>;
  where?: InputMaybe<PayrixTerminal_Bool_Exp>;
};


export type Subscription_RootPayrixTerminalTransactionArgs = {
  distinct_on?: InputMaybe<Array<PayrixTerminalTransaction_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PayrixTerminalTransaction_Order_By>>;
  where?: InputMaybe<PayrixTerminalTransaction_Bool_Exp>;
};


export type Subscription_RootPayrixTerminalTransaction_AggregateArgs = {
  distinct_on?: InputMaybe<Array<PayrixTerminalTransaction_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PayrixTerminalTransaction_Order_By>>;
  where?: InputMaybe<PayrixTerminalTransaction_Bool_Exp>;
};


export type Subscription_RootPayrixTerminalTransaction_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootPayrixTerminalTransaction_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<PayrixTerminalTransaction_Stream_Cursor_Input>>;
  where?: InputMaybe<PayrixTerminalTransaction_Bool_Exp>;
};


export type Subscription_RootPayrixTerminal_AggregateArgs = {
  distinct_on?: InputMaybe<Array<PayrixTerminal_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PayrixTerminal_Order_By>>;
  where?: InputMaybe<PayrixTerminal_Bool_Exp>;
};


export type Subscription_RootPayrixTerminal_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootPayrixTerminal_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<PayrixTerminal_Stream_Cursor_Input>>;
  where?: InputMaybe<PayrixTerminal_Bool_Exp>;
};


export type Subscription_RootPayrixWebAlertArgs = {
  distinct_on?: InputMaybe<Array<PayrixWebAlert_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PayrixWebAlert_Order_By>>;
  where?: InputMaybe<PayrixWebAlert_Bool_Exp>;
};


export type Subscription_RootPayrixWebAlert_AggregateArgs = {
  distinct_on?: InputMaybe<Array<PayrixWebAlert_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PayrixWebAlert_Order_By>>;
  where?: InputMaybe<PayrixWebAlert_Bool_Exp>;
};


export type Subscription_RootPayrixWebAlert_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootPayrixWebAlert_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<PayrixWebAlert_Stream_Cursor_Input>>;
  where?: InputMaybe<PayrixWebAlert_Bool_Exp>;
};


export type Subscription_RootPhotoArgs = {
  distinct_on?: InputMaybe<Array<Photo_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Photo_Order_By>>;
  where?: InputMaybe<Photo_Bool_Exp>;
};


export type Subscription_RootPhotoMediaTagArgs = {
  distinct_on?: InputMaybe<Array<PhotoMediaTag_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PhotoMediaTag_Order_By>>;
  where?: InputMaybe<PhotoMediaTag_Bool_Exp>;
};


export type Subscription_RootPhotoMediaTag_AggregateArgs = {
  distinct_on?: InputMaybe<Array<PhotoMediaTag_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PhotoMediaTag_Order_By>>;
  where?: InputMaybe<PhotoMediaTag_Bool_Exp>;
};


export type Subscription_RootPhotoMediaTag_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootPhotoMediaTag_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<PhotoMediaTag_Stream_Cursor_Input>>;
  where?: InputMaybe<PhotoMediaTag_Bool_Exp>;
};


export type Subscription_RootPhoto_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Photo_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Photo_Order_By>>;
  where?: InputMaybe<Photo_Bool_Exp>;
};


export type Subscription_RootPhoto_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootPhoto_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Photo_Stream_Cursor_Input>>;
  where?: InputMaybe<Photo_Bool_Exp>;
};


export type Subscription_RootPinArgs = {
  distinct_on?: InputMaybe<Array<Pin_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Pin_Order_By>>;
  where?: InputMaybe<Pin_Bool_Exp>;
};


export type Subscription_RootPin_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Pin_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Pin_Order_By>>;
  where?: InputMaybe<Pin_Bool_Exp>;
};


export type Subscription_RootPin_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootPin_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Pin_Stream_Cursor_Input>>;
  where?: InputMaybe<Pin_Bool_Exp>;
};


export type Subscription_RootPostStatusArgs = {
  distinct_on?: InputMaybe<Array<PostStatus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PostStatus_Order_By>>;
  where?: InputMaybe<PostStatus_Bool_Exp>;
};


export type Subscription_RootPostStatus_AggregateArgs = {
  distinct_on?: InputMaybe<Array<PostStatus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PostStatus_Order_By>>;
  where?: InputMaybe<PostStatus_Bool_Exp>;
};


export type Subscription_RootPostStatus_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootPostStatus_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<PostStatus_Stream_Cursor_Input>>;
  where?: InputMaybe<PostStatus_Bool_Exp>;
};


export type Subscription_RootPrescriptionArgs = {
  distinct_on?: InputMaybe<Array<Prescription_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Prescription_Order_By>>;
  where?: InputMaybe<Prescription_Bool_Exp>;
};


export type Subscription_RootPrescriptionDetailArgs = {
  distinct_on?: InputMaybe<Array<PrescriptionDetail_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PrescriptionDetail_Order_By>>;
  where?: InputMaybe<PrescriptionDetail_Bool_Exp>;
};


export type Subscription_RootPrescriptionDetail_AggregateArgs = {
  distinct_on?: InputMaybe<Array<PrescriptionDetail_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PrescriptionDetail_Order_By>>;
  where?: InputMaybe<PrescriptionDetail_Bool_Exp>;
};


export type Subscription_RootPrescriptionDetail_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootPrescriptionDetail_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<PrescriptionDetail_Stream_Cursor_Input>>;
  where?: InputMaybe<PrescriptionDetail_Bool_Exp>;
};


export type Subscription_RootPrescription_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Prescription_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Prescription_Order_By>>;
  where?: InputMaybe<Prescription_Bool_Exp>;
};


export type Subscription_RootPrescription_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootPrescription_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Prescription_Stream_Cursor_Input>>;
  where?: InputMaybe<Prescription_Bool_Exp>;
};


export type Subscription_RootPromotionArgs = {
  distinct_on?: InputMaybe<Array<Promotion_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Promotion_Order_By>>;
  where?: InputMaybe<Promotion_Bool_Exp>;
};


export type Subscription_RootPromotionCouponsArgs = {
  distinct_on?: InputMaybe<Array<PromotionCoupons_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PromotionCoupons_Order_By>>;
  where?: InputMaybe<PromotionCoupons_Bool_Exp>;
};


export type Subscription_RootPromotionCoupons_AggregateArgs = {
  distinct_on?: InputMaybe<Array<PromotionCoupons_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PromotionCoupons_Order_By>>;
  where?: InputMaybe<PromotionCoupons_Bool_Exp>;
};


export type Subscription_RootPromotionCoupons_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootPromotionCoupons_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<PromotionCoupons_Stream_Cursor_Input>>;
  where?: InputMaybe<PromotionCoupons_Bool_Exp>;
};


export type Subscription_RootPromotionServicesArgs = {
  distinct_on?: InputMaybe<Array<PromotionServices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PromotionServices_Order_By>>;
  where?: InputMaybe<PromotionServices_Bool_Exp>;
};


export type Subscription_RootPromotionServices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<PromotionServices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PromotionServices_Order_By>>;
  where?: InputMaybe<PromotionServices_Bool_Exp>;
};


export type Subscription_RootPromotionServices_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootPromotionServices_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<PromotionServices_Stream_Cursor_Input>>;
  where?: InputMaybe<PromotionServices_Bool_Exp>;
};


export type Subscription_RootPromotion_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Promotion_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Promotion_Order_By>>;
  where?: InputMaybe<Promotion_Bool_Exp>;
};


export type Subscription_RootPromotion_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootPromotion_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Promotion_Stream_Cursor_Input>>;
  where?: InputMaybe<Promotion_Bool_Exp>;
};


export type Subscription_RootProviderArgs = {
  distinct_on?: InputMaybe<Array<Provider_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Provider_Order_By>>;
  where?: InputMaybe<Provider_Bool_Exp>;
};


export type Subscription_RootProviderScheduleArgs = {
  distinct_on?: InputMaybe<Array<ProviderSchedule_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ProviderSchedule_Order_By>>;
  where?: InputMaybe<ProviderSchedule_Bool_Exp>;
};


export type Subscription_RootProviderSchedule_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ProviderSchedule_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ProviderSchedule_Order_By>>;
  where?: InputMaybe<ProviderSchedule_Bool_Exp>;
};


export type Subscription_RootProviderSchedule_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootProviderSchedule_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ProviderSchedule_Stream_Cursor_Input>>;
  where?: InputMaybe<ProviderSchedule_Bool_Exp>;
};


export type Subscription_RootProvider_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Provider_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Provider_Order_By>>;
  where?: InputMaybe<Provider_Bool_Exp>;
};


export type Subscription_RootProvider_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootProvider_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Provider_Stream_Cursor_Input>>;
  where?: InputMaybe<Provider_Bool_Exp>;
};


export type Subscription_RootPurchaseOrderArgs = {
  distinct_on?: InputMaybe<Array<PurchaseOrder_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PurchaseOrder_Order_By>>;
  where?: InputMaybe<PurchaseOrder_Bool_Exp>;
};


export type Subscription_RootPurchaseOrder_AggregateArgs = {
  distinct_on?: InputMaybe<Array<PurchaseOrder_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PurchaseOrder_Order_By>>;
  where?: InputMaybe<PurchaseOrder_Bool_Exp>;
};


export type Subscription_RootPurchaseOrder_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootPurchaseOrder_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<PurchaseOrder_Stream_Cursor_Input>>;
  where?: InputMaybe<PurchaseOrder_Bool_Exp>;
};


export type Subscription_RootRecurrenceTypeArgs = {
  distinct_on?: InputMaybe<Array<RecurrenceType_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RecurrenceType_Order_By>>;
  where?: InputMaybe<RecurrenceType_Bool_Exp>;
};


export type Subscription_RootRecurrenceType_AggregateArgs = {
  distinct_on?: InputMaybe<Array<RecurrenceType_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RecurrenceType_Order_By>>;
  where?: InputMaybe<RecurrenceType_Bool_Exp>;
};


export type Subscription_RootRecurrenceType_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootRecurrenceType_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<RecurrenceType_Stream_Cursor_Input>>;
  where?: InputMaybe<RecurrenceType_Bool_Exp>;
};


export type Subscription_RootRefundArgs = {
  distinct_on?: InputMaybe<Array<Refund_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Refund_Order_By>>;
  where?: InputMaybe<Refund_Bool_Exp>;
};


export type Subscription_RootRefund_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Refund_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Refund_Order_By>>;
  where?: InputMaybe<Refund_Bool_Exp>;
};


export type Subscription_RootRefund_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootRefund_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Refund_Stream_Cursor_Input>>;
  where?: InputMaybe<Refund_Bool_Exp>;
};


export type Subscription_RootResolutionStatusArgs = {
  distinct_on?: InputMaybe<Array<ResolutionStatus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ResolutionStatus_Order_By>>;
  where?: InputMaybe<ResolutionStatus_Bool_Exp>;
};


export type Subscription_RootResolutionStatus_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ResolutionStatus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ResolutionStatus_Order_By>>;
  where?: InputMaybe<ResolutionStatus_Bool_Exp>;
};


export type Subscription_RootResolutionStatus_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootResolutionStatus_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ResolutionStatus_Stream_Cursor_Input>>;
  where?: InputMaybe<ResolutionStatus_Bool_Exp>;
};


export type Subscription_RootRewardProgramArgs = {
  distinct_on?: InputMaybe<Array<RewardProgram_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RewardProgram_Order_By>>;
  where?: InputMaybe<RewardProgram_Bool_Exp>;
};


export type Subscription_RootRewardProgramEnrollmentArgs = {
  distinct_on?: InputMaybe<Array<RewardProgramEnrollment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RewardProgramEnrollment_Order_By>>;
  where?: InputMaybe<RewardProgramEnrollment_Bool_Exp>;
};


export type Subscription_RootRewardProgramEnrollment_AggregateArgs = {
  distinct_on?: InputMaybe<Array<RewardProgramEnrollment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RewardProgramEnrollment_Order_By>>;
  where?: InputMaybe<RewardProgramEnrollment_Bool_Exp>;
};


export type Subscription_RootRewardProgramEnrollment_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootRewardProgramEnrollment_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<RewardProgramEnrollment_Stream_Cursor_Input>>;
  where?: InputMaybe<RewardProgramEnrollment_Bool_Exp>;
};


export type Subscription_RootRewardProgram_AggregateArgs = {
  distinct_on?: InputMaybe<Array<RewardProgram_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RewardProgram_Order_By>>;
  where?: InputMaybe<RewardProgram_Bool_Exp>;
};


export type Subscription_RootRewardProgram_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootRewardProgram_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<RewardProgram_Stream_Cursor_Input>>;
  where?: InputMaybe<RewardProgram_Bool_Exp>;
};


export type Subscription_RootRoomArgs = {
  distinct_on?: InputMaybe<Array<Room_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Room_Order_By>>;
  where?: InputMaybe<Room_Bool_Exp>;
};


export type Subscription_RootRoom_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Room_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Room_Order_By>>;
  where?: InputMaybe<Room_Bool_Exp>;
};


export type Subscription_RootRoom_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootRoom_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Room_Stream_Cursor_Input>>;
  where?: InputMaybe<Room_Bool_Exp>;
};


export type Subscription_RootRsReviewArgs = {
  distinct_on?: InputMaybe<Array<RsReview_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RsReview_Order_By>>;
  where?: InputMaybe<RsReview_Bool_Exp>;
};


export type Subscription_RootRsReview_AggregateArgs = {
  distinct_on?: InputMaybe<Array<RsReview_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RsReview_Order_By>>;
  where?: InputMaybe<RsReview_Bool_Exp>;
};


export type Subscription_RootRsReview_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootRsReview_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<RsReview_Stream_Cursor_Input>>;
  where?: InputMaybe<RsReview_Bool_Exp>;
};


export type Subscription_RootSearchConsumableTypesArgs = {
  args: SearchConsumableTypes_Args;
  distinct_on?: InputMaybe<Array<ConsumableType_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ConsumableType_Order_By>>;
  where?: InputMaybe<ConsumableType_Bool_Exp>;
};


export type Subscription_RootSearchConsumableTypes_AggregateArgs = {
  args: SearchConsumableTypes_Args;
  distinct_on?: InputMaybe<Array<ConsumableType_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ConsumableType_Order_By>>;
  where?: InputMaybe<ConsumableType_Bool_Exp>;
};


export type Subscription_RootSearchPatientsArgs = {
  args: SearchPatients_Args;
  distinct_on?: InputMaybe<Array<Patient_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Patient_Order_By>>;
  where?: InputMaybe<Patient_Bool_Exp>;
};


export type Subscription_RootSearchPatients_AggregateArgs = {
  args: SearchPatients_Args;
  distinct_on?: InputMaybe<Array<Patient_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Patient_Order_By>>;
  where?: InputMaybe<Patient_Bool_Exp>;
};


export type Subscription_RootSearchProviderScheduleArgs = {
  args: SearchProviderSchedule_Args;
  distinct_on?: InputMaybe<Array<ProviderSchedule_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ProviderSchedule_Order_By>>;
  where?: InputMaybe<ProviderSchedule_Bool_Exp>;
};


export type Subscription_RootSearchProviderSchedule_AggregateArgs = {
  args: SearchProviderSchedule_Args;
  distinct_on?: InputMaybe<Array<ProviderSchedule_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ProviderSchedule_Order_By>>;
  where?: InputMaybe<ProviderSchedule_Bool_Exp>;
};


export type Subscription_RootSearch_Note_TitleArgs = {
  args: Search_Note_Title_Args;
  distinct_on?: InputMaybe<Array<Note_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Note_Order_By>>;
  where?: InputMaybe<Note_Bool_Exp>;
};


export type Subscription_RootSearch_Note_Title_AggregateArgs = {
  args: Search_Note_Title_Args;
  distinct_on?: InputMaybe<Array<Note_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Note_Order_By>>;
  where?: InputMaybe<Note_Bool_Exp>;
};


export type Subscription_RootSearch_PatientWorkspaceArgs = {
  args: Search_PatientWorkspace_Args;
  distinct_on?: InputMaybe<Array<Patient_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Patient_Order_By>>;
  where?: InputMaybe<Patient_Bool_Exp>;
};


export type Subscription_RootSearch_PatientWorkspace_AggregateArgs = {
  args: Search_PatientWorkspace_Args;
  distinct_on?: InputMaybe<Array<Patient_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Patient_Order_By>>;
  where?: InputMaybe<Patient_Bool_Exp>;
};


export type Subscription_RootServiceArgs = {
  distinct_on?: InputMaybe<Array<Service_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Service_Order_By>>;
  where?: InputMaybe<Service_Bool_Exp>;
};


export type Subscription_RootServiceAddOnArgs = {
  distinct_on?: InputMaybe<Array<ServiceAddOn_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ServiceAddOn_Order_By>>;
  where?: InputMaybe<ServiceAddOn_Bool_Exp>;
};


export type Subscription_RootServiceAddOn_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ServiceAddOn_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ServiceAddOn_Order_By>>;
  where?: InputMaybe<ServiceAddOn_Bool_Exp>;
};


export type Subscription_RootServiceAddOn_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootServiceAddOn_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ServiceAddOn_Stream_Cursor_Input>>;
  where?: InputMaybe<ServiceAddOn_Bool_Exp>;
};


export type Subscription_RootServiceCategoryArgs = {
  distinct_on?: InputMaybe<Array<ServiceCategory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ServiceCategory_Order_By>>;
  where?: InputMaybe<ServiceCategory_Bool_Exp>;
};


export type Subscription_RootServiceCategory_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ServiceCategory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ServiceCategory_Order_By>>;
  where?: InputMaybe<ServiceCategory_Bool_Exp>;
};


export type Subscription_RootServiceCategory_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootServiceCategory_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ServiceCategory_Stream_Cursor_Input>>;
  where?: InputMaybe<ServiceCategory_Bool_Exp>;
};


export type Subscription_RootServiceConsentArgs = {
  distinct_on?: InputMaybe<Array<ServiceConsent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ServiceConsent_Order_By>>;
  where?: InputMaybe<ServiceConsent_Bool_Exp>;
};


export type Subscription_RootServiceConsent_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ServiceConsent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ServiceConsent_Order_By>>;
  where?: InputMaybe<ServiceConsent_Bool_Exp>;
};


export type Subscription_RootServiceConsent_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootServiceConsent_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ServiceConsent_Stream_Cursor_Input>>;
  where?: InputMaybe<ServiceConsent_Bool_Exp>;
};


export type Subscription_RootServiceConsumableTypeArgs = {
  distinct_on?: InputMaybe<Array<ServiceConsumableType_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ServiceConsumableType_Order_By>>;
  where?: InputMaybe<ServiceConsumableType_Bool_Exp>;
};


export type Subscription_RootServiceConsumableType_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ServiceConsumableType_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ServiceConsumableType_Order_By>>;
  where?: InputMaybe<ServiceConsumableType_Bool_Exp>;
};


export type Subscription_RootServiceConsumableType_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootServiceConsumableType_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ServiceConsumableType_Stream_Cursor_Input>>;
  where?: InputMaybe<ServiceConsumableType_Bool_Exp>;
};


export type Subscription_RootServiceCouponArgs = {
  distinct_on?: InputMaybe<Array<ServiceCoupon_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ServiceCoupon_Order_By>>;
  where?: InputMaybe<ServiceCoupon_Bool_Exp>;
};


export type Subscription_RootServiceCoupon_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ServiceCoupon_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ServiceCoupon_Order_By>>;
  where?: InputMaybe<ServiceCoupon_Bool_Exp>;
};


export type Subscription_RootServiceCoupon_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootServiceCoupon_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ServiceCoupon_Stream_Cursor_Input>>;
  where?: InputMaybe<ServiceCoupon_Bool_Exp>;
};


export type Subscription_RootServiceCustomFormArgs = {
  distinct_on?: InputMaybe<Array<ServiceCustomForm_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ServiceCustomForm_Order_By>>;
  where?: InputMaybe<ServiceCustomForm_Bool_Exp>;
};


export type Subscription_RootServiceCustomForm_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ServiceCustomForm_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ServiceCustomForm_Order_By>>;
  where?: InputMaybe<ServiceCustomForm_Bool_Exp>;
};


export type Subscription_RootServiceCustomForm_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootServiceCustomForm_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ServiceCustomForm_Stream_Cursor_Input>>;
  where?: InputMaybe<ServiceCustomForm_Bool_Exp>;
};


export type Subscription_RootServiceDeviceTypeArgs = {
  distinct_on?: InputMaybe<Array<ServiceDeviceType_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ServiceDeviceType_Order_By>>;
  where?: InputMaybe<ServiceDeviceType_Bool_Exp>;
};


export type Subscription_RootServiceDeviceType_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ServiceDeviceType_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ServiceDeviceType_Order_By>>;
  where?: InputMaybe<ServiceDeviceType_Bool_Exp>;
};


export type Subscription_RootServiceDeviceType_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootServiceDeviceType_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ServiceDeviceType_Stream_Cursor_Input>>;
  where?: InputMaybe<ServiceDeviceType_Bool_Exp>;
};


export type Subscription_RootServiceProviderArgs = {
  distinct_on?: InputMaybe<Array<ServiceProvider_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ServiceProvider_Order_By>>;
  where?: InputMaybe<ServiceProvider_Bool_Exp>;
};


export type Subscription_RootServiceProvider_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ServiceProvider_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ServiceProvider_Order_By>>;
  where?: InputMaybe<ServiceProvider_Bool_Exp>;
};


export type Subscription_RootServiceProvider_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootServiceProvider_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ServiceProvider_Stream_Cursor_Input>>;
  where?: InputMaybe<ServiceProvider_Bool_Exp>;
};


export type Subscription_RootServiceUnitArgs = {
  distinct_on?: InputMaybe<Array<ServiceUnit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ServiceUnit_Order_By>>;
  where?: InputMaybe<ServiceUnit_Bool_Exp>;
};


export type Subscription_RootServiceUnit_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ServiceUnit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ServiceUnit_Order_By>>;
  where?: InputMaybe<ServiceUnit_Bool_Exp>;
};


export type Subscription_RootServiceUnit_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootServiceUnit_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ServiceUnit_Stream_Cursor_Input>>;
  where?: InputMaybe<ServiceUnit_Bool_Exp>;
};


export type Subscription_RootService_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Service_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Service_Order_By>>;
  where?: InputMaybe<Service_Bool_Exp>;
};


export type Subscription_RootService_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootService_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Service_Stream_Cursor_Input>>;
  where?: InputMaybe<Service_Bool_Exp>;
};


export type Subscription_RootShrinkArgs = {
  distinct_on?: InputMaybe<Array<Shrink_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Shrink_Order_By>>;
  where?: InputMaybe<Shrink_Bool_Exp>;
};


export type Subscription_RootShrink_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Shrink_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Shrink_Order_By>>;
  where?: InputMaybe<Shrink_Bool_Exp>;
};


export type Subscription_RootShrink_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootShrink_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Shrink_Stream_Cursor_Input>>;
  where?: InputMaybe<Shrink_Bool_Exp>;
};


export type Subscription_RootSignatureArgs = {
  distinct_on?: InputMaybe<Array<Signature_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Signature_Order_By>>;
  where?: InputMaybe<Signature_Bool_Exp>;
};


export type Subscription_RootSignature_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Signature_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Signature_Order_By>>;
  where?: InputMaybe<Signature_Bool_Exp>;
};


export type Subscription_RootSignature_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootSignature_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Signature_Stream_Cursor_Input>>;
  where?: InputMaybe<Signature_Bool_Exp>;
};


export type Subscription_RootSmartNoteArgs = {
  distinct_on?: InputMaybe<Array<SmartNote_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SmartNote_Order_By>>;
  where?: InputMaybe<SmartNote_Bool_Exp>;
};


export type Subscription_RootSmartNote_AggregateArgs = {
  distinct_on?: InputMaybe<Array<SmartNote_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SmartNote_Order_By>>;
  where?: InputMaybe<SmartNote_Bool_Exp>;
};


export type Subscription_RootSmartNote_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootSmartNote_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<SmartNote_Stream_Cursor_Input>>;
  where?: InputMaybe<SmartNote_Bool_Exp>;
};


export type Subscription_RootSmsTemplateArgs = {
  distinct_on?: InputMaybe<Array<SmsTemplate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SmsTemplate_Order_By>>;
  where?: InputMaybe<SmsTemplate_Bool_Exp>;
};


export type Subscription_RootSmsTemplatePatientArgs = {
  distinct_on?: InputMaybe<Array<SmsTemplatePatient_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SmsTemplatePatient_Order_By>>;
  where?: InputMaybe<SmsTemplatePatient_Bool_Exp>;
};


export type Subscription_RootSmsTemplatePatient_AggregateArgs = {
  distinct_on?: InputMaybe<Array<SmsTemplatePatient_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SmsTemplatePatient_Order_By>>;
  where?: InputMaybe<SmsTemplatePatient_Bool_Exp>;
};


export type Subscription_RootSmsTemplatePatient_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootSmsTemplatePatient_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<SmsTemplatePatient_Stream_Cursor_Input>>;
  where?: InputMaybe<SmsTemplatePatient_Bool_Exp>;
};


export type Subscription_RootSmsTemplateSendQueueArgs = {
  distinct_on?: InputMaybe<Array<SmsTemplateSendQueue_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SmsTemplateSendQueue_Order_By>>;
  where?: InputMaybe<SmsTemplateSendQueue_Bool_Exp>;
};


export type Subscription_RootSmsTemplateSendQueue_AggregateArgs = {
  distinct_on?: InputMaybe<Array<SmsTemplateSendQueue_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SmsTemplateSendQueue_Order_By>>;
  where?: InputMaybe<SmsTemplateSendQueue_Bool_Exp>;
};


export type Subscription_RootSmsTemplateSendQueue_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootSmsTemplateSendQueue_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<SmsTemplateSendQueue_Stream_Cursor_Input>>;
  where?: InputMaybe<SmsTemplateSendQueue_Bool_Exp>;
};


export type Subscription_RootSmsTemplate_AggregateArgs = {
  distinct_on?: InputMaybe<Array<SmsTemplate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SmsTemplate_Order_By>>;
  where?: InputMaybe<SmsTemplate_Bool_Exp>;
};


export type Subscription_RootSmsTemplate_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootSmsTemplate_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<SmsTemplate_Stream_Cursor_Input>>;
  where?: InputMaybe<SmsTemplate_Bool_Exp>;
};


export type Subscription_RootSocialMediaAccountArgs = {
  distinct_on?: InputMaybe<Array<SocialMediaAccount_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SocialMediaAccount_Order_By>>;
  where?: InputMaybe<SocialMediaAccount_Bool_Exp>;
};


export type Subscription_RootSocialMediaAccountPostArgs = {
  distinct_on?: InputMaybe<Array<SocialMediaAccountPost_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SocialMediaAccountPost_Order_By>>;
  where?: InputMaybe<SocialMediaAccountPost_Bool_Exp>;
};


export type Subscription_RootSocialMediaAccountPost_AggregateArgs = {
  distinct_on?: InputMaybe<Array<SocialMediaAccountPost_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SocialMediaAccountPost_Order_By>>;
  where?: InputMaybe<SocialMediaAccountPost_Bool_Exp>;
};


export type Subscription_RootSocialMediaAccountPost_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootSocialMediaAccountPost_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<SocialMediaAccountPost_Stream_Cursor_Input>>;
  where?: InputMaybe<SocialMediaAccountPost_Bool_Exp>;
};


export type Subscription_RootSocialMediaAccount_AggregateArgs = {
  distinct_on?: InputMaybe<Array<SocialMediaAccount_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SocialMediaAccount_Order_By>>;
  where?: InputMaybe<SocialMediaAccount_Bool_Exp>;
};


export type Subscription_RootSocialMediaAccount_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootSocialMediaAccount_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<SocialMediaAccount_Stream_Cursor_Input>>;
  where?: InputMaybe<SocialMediaAccount_Bool_Exp>;
};


export type Subscription_RootSocialMediaPlatformArgs = {
  distinct_on?: InputMaybe<Array<SocialMediaPlatform_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SocialMediaPlatform_Order_By>>;
  where?: InputMaybe<SocialMediaPlatform_Bool_Exp>;
};


export type Subscription_RootSocialMediaPlatform_AggregateArgs = {
  distinct_on?: InputMaybe<Array<SocialMediaPlatform_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SocialMediaPlatform_Order_By>>;
  where?: InputMaybe<SocialMediaPlatform_Bool_Exp>;
};


export type Subscription_RootSocialMediaPlatform_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootSocialMediaPlatform_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<SocialMediaPlatform_Stream_Cursor_Input>>;
  where?: InputMaybe<SocialMediaPlatform_Bool_Exp>;
};


export type Subscription_RootSocialMediaPostArgs = {
  distinct_on?: InputMaybe<Array<SocialMediaPost_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SocialMediaPost_Order_By>>;
  where?: InputMaybe<SocialMediaPost_Bool_Exp>;
};


export type Subscription_RootSocialMediaPost_AggregateArgs = {
  distinct_on?: InputMaybe<Array<SocialMediaPost_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SocialMediaPost_Order_By>>;
  where?: InputMaybe<SocialMediaPost_Bool_Exp>;
};


export type Subscription_RootSocialMediaPost_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootSocialMediaPost_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<SocialMediaPost_Stream_Cursor_Input>>;
  where?: InputMaybe<SocialMediaPost_Bool_Exp>;
};


export type Subscription_RootTagArgs = {
  distinct_on?: InputMaybe<Array<Tag_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tag_Order_By>>;
  where?: InputMaybe<Tag_Bool_Exp>;
};


export type Subscription_RootTag_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tag_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tag_Order_By>>;
  where?: InputMaybe<Tag_Bool_Exp>;
};


export type Subscription_RootTag_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootTag_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Tag_Stream_Cursor_Input>>;
  where?: InputMaybe<Tag_Bool_Exp>;
};


export type Subscription_RootTaskArgs = {
  distinct_on?: InputMaybe<Array<Task_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_Order_By>>;
  where?: InputMaybe<Task_Bool_Exp>;
};


export type Subscription_RootTaskCommentArgs = {
  distinct_on?: InputMaybe<Array<TaskComment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TaskComment_Order_By>>;
  where?: InputMaybe<TaskComment_Bool_Exp>;
};


export type Subscription_RootTaskComment_AggregateArgs = {
  distinct_on?: InputMaybe<Array<TaskComment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TaskComment_Order_By>>;
  where?: InputMaybe<TaskComment_Bool_Exp>;
};


export type Subscription_RootTaskComment_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootTaskComment_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<TaskComment_Stream_Cursor_Input>>;
  where?: InputMaybe<TaskComment_Bool_Exp>;
};


export type Subscription_RootTask_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Task_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_Order_By>>;
  where?: InputMaybe<Task_Bool_Exp>;
};


export type Subscription_RootTask_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootTask_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Task_Stream_Cursor_Input>>;
  where?: InputMaybe<Task_Bool_Exp>;
};


export type Subscription_RootTelehealthMeetingArgs = {
  distinct_on?: InputMaybe<Array<TelehealthMeeting_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TelehealthMeeting_Order_By>>;
  where?: InputMaybe<TelehealthMeeting_Bool_Exp>;
};


export type Subscription_RootTelehealthMeeting_AggregateArgs = {
  distinct_on?: InputMaybe<Array<TelehealthMeeting_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TelehealthMeeting_Order_By>>;
  where?: InputMaybe<TelehealthMeeting_Bool_Exp>;
};


export type Subscription_RootTelehealthMeeting_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootTelehealthMeeting_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<TelehealthMeeting_Stream_Cursor_Input>>;
  where?: InputMaybe<TelehealthMeeting_Bool_Exp>;
};


export type Subscription_RootUnusedAppointmentServiceConsumableTypeArgs = {
  distinct_on?: InputMaybe<Array<UnusedAppointmentServiceConsumableType_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UnusedAppointmentServiceConsumableType_Order_By>>;
  where?: InputMaybe<UnusedAppointmentServiceConsumableType_Bool_Exp>;
};


export type Subscription_RootUnusedAppointmentServiceConsumableType_AggregateArgs = {
  distinct_on?: InputMaybe<Array<UnusedAppointmentServiceConsumableType_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UnusedAppointmentServiceConsumableType_Order_By>>;
  where?: InputMaybe<UnusedAppointmentServiceConsumableType_Bool_Exp>;
};


export type Subscription_RootUnusedAppointmentServiceConsumableType_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootUnusedAppointmentServiceConsumableType_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<UnusedAppointmentServiceConsumableType_Stream_Cursor_Input>>;
  where?: InputMaybe<UnusedAppointmentServiceConsumableType_Bool_Exp>;
};


export type Subscription_RootUserArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Order_By>>;
  where?: InputMaybe<User_Bool_Exp>;
};


export type Subscription_RootUserInviteArgs = {
  distinct_on?: InputMaybe<Array<UserInvite_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserInvite_Order_By>>;
  where?: InputMaybe<UserInvite_Bool_Exp>;
};


export type Subscription_RootUserInvite_AggregateArgs = {
  distinct_on?: InputMaybe<Array<UserInvite_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserInvite_Order_By>>;
  where?: InputMaybe<UserInvite_Bool_Exp>;
};


export type Subscription_RootUserInvite_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootUserInvite_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<UserInvite_Stream_Cursor_Input>>;
  where?: InputMaybe<UserInvite_Bool_Exp>;
};


export type Subscription_RootUserOrganizationArgs = {
  distinct_on?: InputMaybe<Array<UserOrganization_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserOrganization_Order_By>>;
  where?: InputMaybe<UserOrganization_Bool_Exp>;
};


export type Subscription_RootUserOrganization_AggregateArgs = {
  distinct_on?: InputMaybe<Array<UserOrganization_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserOrganization_Order_By>>;
  where?: InputMaybe<UserOrganization_Bool_Exp>;
};


export type Subscription_RootUserOrganization_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootUserOrganization_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<UserOrganization_Stream_Cursor_Input>>;
  where?: InputMaybe<UserOrganization_Bool_Exp>;
};


export type Subscription_RootUserWorkspaceArgs = {
  distinct_on?: InputMaybe<Array<UserWorkspace_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserWorkspace_Order_By>>;
  where?: InputMaybe<UserWorkspace_Bool_Exp>;
};


export type Subscription_RootUserWorkspace_AggregateArgs = {
  distinct_on?: InputMaybe<Array<UserWorkspace_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserWorkspace_Order_By>>;
  where?: InputMaybe<UserWorkspace_Bool_Exp>;
};


export type Subscription_RootUserWorkspace_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootUserWorkspace_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<UserWorkspace_Stream_Cursor_Input>>;
  where?: InputMaybe<UserWorkspace_Bool_Exp>;
};


export type Subscription_RootUser_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Order_By>>;
  where?: InputMaybe<User_Bool_Exp>;
};


export type Subscription_RootUser_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootUser_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<User_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Bool_Exp>;
};


export type Subscription_RootVitalsArgs = {
  distinct_on?: InputMaybe<Array<Vitals_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vitals_Order_By>>;
  where?: InputMaybe<Vitals_Bool_Exp>;
};


export type Subscription_RootVitals_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Vitals_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vitals_Order_By>>;
  where?: InputMaybe<Vitals_Bool_Exp>;
};


export type Subscription_RootVitals_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootVitals_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Vitals_Stream_Cursor_Input>>;
  where?: InputMaybe<Vitals_Bool_Exp>;
};


export type Subscription_RootWorkspaceArgs = {
  distinct_on?: InputMaybe<Array<Workspace_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Workspace_Order_By>>;
  where?: InputMaybe<Workspace_Bool_Exp>;
};


export type Subscription_RootWorkspaceConfigurationArgs = {
  distinct_on?: InputMaybe<Array<WorkspaceConfiguration_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<WorkspaceConfiguration_Order_By>>;
  where?: InputMaybe<WorkspaceConfiguration_Bool_Exp>;
};


export type Subscription_RootWorkspaceConfiguration_AggregateArgs = {
  distinct_on?: InputMaybe<Array<WorkspaceConfiguration_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<WorkspaceConfiguration_Order_By>>;
  where?: InputMaybe<WorkspaceConfiguration_Bool_Exp>;
};


export type Subscription_RootWorkspaceConfiguration_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootWorkspaceConfiguration_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<WorkspaceConfiguration_Stream_Cursor_Input>>;
  where?: InputMaybe<WorkspaceConfiguration_Bool_Exp>;
};


export type Subscription_RootWorkspace_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Workspace_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Workspace_Order_By>>;
  where?: InputMaybe<Workspace_Bool_Exp>;
};


export type Subscription_RootWorkspace_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootWorkspace_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Workspace_Stream_Cursor_Input>>;
  where?: InputMaybe<Workspace_Bool_Exp>;
};


export type Subscription_RootZdReviewArgs = {
  distinct_on?: InputMaybe<Array<ZdReview_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZdReview_Order_By>>;
  where?: InputMaybe<ZdReview_Bool_Exp>;
};


export type Subscription_RootZdReview_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ZdReview_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZdReview_Order_By>>;
  where?: InputMaybe<ZdReview_Bool_Exp>;
};


export type Subscription_RootZdReview_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootZdReview_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ZdReview_Stream_Cursor_Input>>;
  where?: InputMaybe<ZdReview_Bool_Exp>;
};

/** columns and relationships of "tag" */
export type Tag = {
  __typename?: 'tag';
  color?: Maybe<Scalars['String']>;
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  title: Scalars['String'];
  type: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  workspaceId: Scalars['uuid'];
};

/** aggregated selection of "tag" */
export type Tag_Aggregate = {
  __typename?: 'tag_aggregate';
  aggregate?: Maybe<Tag_Aggregate_Fields>;
  nodes: Array<Tag>;
};

/** aggregate fields of "tag" */
export type Tag_Aggregate_Fields = {
  __typename?: 'tag_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Tag_Max_Fields>;
  min?: Maybe<Tag_Min_Fields>;
};


/** aggregate fields of "tag" */
export type Tag_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Tag_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "tag". All fields are combined with a logical 'AND'. */
export type Tag_Bool_Exp = {
  _and?: InputMaybe<Array<Tag_Bool_Exp>>;
  _not?: InputMaybe<Tag_Bool_Exp>;
  _or?: InputMaybe<Array<Tag_Bool_Exp>>;
  color?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  workspaceId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "tag" */
export enum Tag_Constraint {
  /** unique or primary key constraint on columns "id" */
  TagPkey = 'tag_pkey',
  /** unique or primary key constraint on columns "type", "workspaceId", "title" */
  TagWorkspaceIdTitleTypeKey = 'tag_workspaceId_title_type_key'
}

/** input type for inserting data into table "tag" */
export type Tag_Insert_Input = {
  color?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Tag_Max_Fields = {
  __typename?: 'tag_max_fields';
  color?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Tag_Min_Fields = {
  __typename?: 'tag_min_fields';
  color?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "tag" */
export type Tag_Mutation_Response = {
  __typename?: 'tag_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Tag>;
};

/** input type for inserting object relation for remote table "tag" */
export type Tag_Obj_Rel_Insert_Input = {
  data: Tag_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Tag_On_Conflict>;
};

/** on_conflict condition type for table "tag" */
export type Tag_On_Conflict = {
  constraint: Tag_Constraint;
  update_columns?: Array<Tag_Update_Column>;
  where?: InputMaybe<Tag_Bool_Exp>;
};

/** Ordering options when selecting data from "tag". */
export type Tag_Order_By = {
  color?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: tag */
export type Tag_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "tag" */
export enum Tag_Select_Column {
  /** column name */
  Color = 'color',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Title = 'title',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WorkspaceId = 'workspaceId'
}

/** input type for updating data in table "tag" */
export type Tag_Set_Input = {
  color?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "tag" */
export type Tag_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Tag_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tag_Stream_Cursor_Value_Input = {
  color?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "tag" */
export enum Tag_Update_Column {
  /** column name */
  Color = 'color',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Title = 'title',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WorkspaceId = 'workspaceId'
}

export type Tag_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Tag_Set_Input>;
  /** filter the rows which have to be updated */
  where: Tag_Bool_Exp;
};

/** columns and relationships of "task" */
export type Task = {
  __typename?: 'task';
  /** An object relationship */
  appointment?: Maybe<Appointment>;
  appointmentId?: Maybe<Scalars['uuid']>;
  /** An array relationship */
  comments: Array<TaskComment>;
  /** An aggregate relationship */
  comments_aggregate: TaskComment_Aggregate;
  createdAt: Scalars['timestamptz'];
  description?: Maybe<Scalars['String']>;
  dueDate: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An object relationship */
  patient?: Maybe<Patient>;
  patientId?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  provider?: Maybe<Provider>;
  providerId?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
  updatedAt: Scalars['timestamptz'];
  /** An object relationship */
  user?: Maybe<User>;
  userId?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  workspace: Workspace;
  workspaceId: Scalars['uuid'];
};


/** columns and relationships of "task" */
export type TaskCommentsArgs = {
  distinct_on?: InputMaybe<Array<TaskComment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TaskComment_Order_By>>;
  where?: InputMaybe<TaskComment_Bool_Exp>;
};


/** columns and relationships of "task" */
export type TaskComments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<TaskComment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TaskComment_Order_By>>;
  where?: InputMaybe<TaskComment_Bool_Exp>;
};

/** columns and relationships of "taskComment" */
export type TaskComment = {
  __typename?: 'taskComment';
  /** An object relationship */
  author: User;
  authorId: Scalars['uuid'];
  comment: Scalars['String'];
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An object relationship */
  task: Task;
  taskId: Scalars['uuid'];
  updatedAt: Scalars['timestamptz'];
  /** An object relationship */
  workspace: Workspace;
  workspaceId: Scalars['uuid'];
};

/** aggregated selection of "taskComment" */
export type TaskComment_Aggregate = {
  __typename?: 'taskComment_aggregate';
  aggregate?: Maybe<TaskComment_Aggregate_Fields>;
  nodes: Array<TaskComment>;
};

export type TaskComment_Aggregate_Bool_Exp = {
  count?: InputMaybe<TaskComment_Aggregate_Bool_Exp_Count>;
};

export type TaskComment_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<TaskComment_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<TaskComment_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "taskComment" */
export type TaskComment_Aggregate_Fields = {
  __typename?: 'taskComment_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<TaskComment_Max_Fields>;
  min?: Maybe<TaskComment_Min_Fields>;
};


/** aggregate fields of "taskComment" */
export type TaskComment_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<TaskComment_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "taskComment" */
export type TaskComment_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<TaskComment_Max_Order_By>;
  min?: InputMaybe<TaskComment_Min_Order_By>;
};

/** input type for inserting array relation for remote table "taskComment" */
export type TaskComment_Arr_Rel_Insert_Input = {
  data: Array<TaskComment_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<TaskComment_On_Conflict>;
};

/** Boolean expression to filter rows from the table "taskComment". All fields are combined with a logical 'AND'. */
export type TaskComment_Bool_Exp = {
  _and?: InputMaybe<Array<TaskComment_Bool_Exp>>;
  _not?: InputMaybe<TaskComment_Bool_Exp>;
  _or?: InputMaybe<Array<TaskComment_Bool_Exp>>;
  author?: InputMaybe<User_Bool_Exp>;
  authorId?: InputMaybe<Uuid_Comparison_Exp>;
  comment?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  task?: InputMaybe<Task_Bool_Exp>;
  taskId?: InputMaybe<Uuid_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  workspace?: InputMaybe<Workspace_Bool_Exp>;
  workspaceId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "taskComment" */
export enum TaskComment_Constraint {
  /** unique or primary key constraint on columns "id" */
  TaskCommentPkey = 'taskComment_pkey'
}

/** input type for inserting data into table "taskComment" */
export type TaskComment_Insert_Input = {
  author?: InputMaybe<User_Obj_Rel_Insert_Input>;
  authorId?: InputMaybe<Scalars['uuid']>;
  comment?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  task?: InputMaybe<Task_Obj_Rel_Insert_Input>;
  taskId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspace?: InputMaybe<Workspace_Obj_Rel_Insert_Input>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type TaskComment_Max_Fields = {
  __typename?: 'taskComment_max_fields';
  authorId?: Maybe<Scalars['uuid']>;
  comment?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  taskId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "taskComment" */
export type TaskComment_Max_Order_By = {
  authorId?: InputMaybe<Order_By>;
  comment?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  taskId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type TaskComment_Min_Fields = {
  __typename?: 'taskComment_min_fields';
  authorId?: Maybe<Scalars['uuid']>;
  comment?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  taskId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "taskComment" */
export type TaskComment_Min_Order_By = {
  authorId?: InputMaybe<Order_By>;
  comment?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  taskId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "taskComment" */
export type TaskComment_Mutation_Response = {
  __typename?: 'taskComment_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<TaskComment>;
};

/** on_conflict condition type for table "taskComment" */
export type TaskComment_On_Conflict = {
  constraint: TaskComment_Constraint;
  update_columns?: Array<TaskComment_Update_Column>;
  where?: InputMaybe<TaskComment_Bool_Exp>;
};

/** Ordering options when selecting data from "taskComment". */
export type TaskComment_Order_By = {
  author?: InputMaybe<User_Order_By>;
  authorId?: InputMaybe<Order_By>;
  comment?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  task?: InputMaybe<Task_Order_By>;
  taskId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  workspace?: InputMaybe<Workspace_Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: taskComment */
export type TaskComment_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "taskComment" */
export enum TaskComment_Select_Column {
  /** column name */
  AuthorId = 'authorId',
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  TaskId = 'taskId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WorkspaceId = 'workspaceId'
}

/** input type for updating data in table "taskComment" */
export type TaskComment_Set_Input = {
  authorId?: InputMaybe<Scalars['uuid']>;
  comment?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  taskId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "taskComment" */
export type TaskComment_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: TaskComment_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type TaskComment_Stream_Cursor_Value_Input = {
  authorId?: InputMaybe<Scalars['uuid']>;
  comment?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  taskId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "taskComment" */
export enum TaskComment_Update_Column {
  /** column name */
  AuthorId = 'authorId',
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  TaskId = 'taskId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WorkspaceId = 'workspaceId'
}

export type TaskComment_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<TaskComment_Set_Input>;
  /** filter the rows which have to be updated */
  where: TaskComment_Bool_Exp;
};

/** aggregated selection of "task" */
export type Task_Aggregate = {
  __typename?: 'task_aggregate';
  aggregate?: Maybe<Task_Aggregate_Fields>;
  nodes: Array<Task>;
};

/** aggregate fields of "task" */
export type Task_Aggregate_Fields = {
  __typename?: 'task_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Task_Max_Fields>;
  min?: Maybe<Task_Min_Fields>;
};


/** aggregate fields of "task" */
export type Task_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Task_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "task". All fields are combined with a logical 'AND'. */
export type Task_Bool_Exp = {
  _and?: InputMaybe<Array<Task_Bool_Exp>>;
  _not?: InputMaybe<Task_Bool_Exp>;
  _or?: InputMaybe<Array<Task_Bool_Exp>>;
  appointment?: InputMaybe<Appointment_Bool_Exp>;
  appointmentId?: InputMaybe<Uuid_Comparison_Exp>;
  comments?: InputMaybe<TaskComment_Bool_Exp>;
  comments_aggregate?: InputMaybe<TaskComment_Aggregate_Bool_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  dueDate?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  patient?: InputMaybe<Patient_Bool_Exp>;
  patientId?: InputMaybe<Uuid_Comparison_Exp>;
  provider?: InputMaybe<Provider_Bool_Exp>;
  providerId?: InputMaybe<Uuid_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<User_Bool_Exp>;
  userId?: InputMaybe<Uuid_Comparison_Exp>;
  workspace?: InputMaybe<Workspace_Bool_Exp>;
  workspaceId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "task" */
export enum Task_Constraint {
  /** unique or primary key constraint on columns "id" */
  TaskPkey = 'task_pkey'
}

/** input type for inserting data into table "task" */
export type Task_Insert_Input = {
  appointment?: InputMaybe<Appointment_Obj_Rel_Insert_Input>;
  appointmentId?: InputMaybe<Scalars['uuid']>;
  comments?: InputMaybe<TaskComment_Arr_Rel_Insert_Input>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  dueDate?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  patient?: InputMaybe<Patient_Obj_Rel_Insert_Input>;
  patientId?: InputMaybe<Scalars['uuid']>;
  provider?: InputMaybe<Provider_Obj_Rel_Insert_Input>;
  providerId?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<User_Obj_Rel_Insert_Input>;
  userId?: InputMaybe<Scalars['uuid']>;
  workspace?: InputMaybe<Workspace_Obj_Rel_Insert_Input>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Task_Max_Fields = {
  __typename?: 'task_max_fields';
  appointmentId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  dueDate?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  patientId?: Maybe<Scalars['uuid']>;
  providerId?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['uuid']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Task_Min_Fields = {
  __typename?: 'task_min_fields';
  appointmentId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  dueDate?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  patientId?: Maybe<Scalars['uuid']>;
  providerId?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['uuid']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "task" */
export type Task_Mutation_Response = {
  __typename?: 'task_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Task>;
};

/** input type for inserting object relation for remote table "task" */
export type Task_Obj_Rel_Insert_Input = {
  data: Task_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Task_On_Conflict>;
};

/** on_conflict condition type for table "task" */
export type Task_On_Conflict = {
  constraint: Task_Constraint;
  update_columns?: Array<Task_Update_Column>;
  where?: InputMaybe<Task_Bool_Exp>;
};

/** Ordering options when selecting data from "task". */
export type Task_Order_By = {
  appointment?: InputMaybe<Appointment_Order_By>;
  appointmentId?: InputMaybe<Order_By>;
  comments_aggregate?: InputMaybe<TaskComment_Aggregate_Order_By>;
  createdAt?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  dueDate?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patient?: InputMaybe<Patient_Order_By>;
  patientId?: InputMaybe<Order_By>;
  provider?: InputMaybe<Provider_Order_By>;
  providerId?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  user?: InputMaybe<User_Order_By>;
  userId?: InputMaybe<Order_By>;
  workspace?: InputMaybe<Workspace_Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: task */
export type Task_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "task" */
export enum Task_Select_Column {
  /** column name */
  AppointmentId = 'appointmentId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  DueDate = 'dueDate',
  /** column name */
  Id = 'id',
  /** column name */
  PatientId = 'patientId',
  /** column name */
  ProviderId = 'providerId',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId',
  /** column name */
  WorkspaceId = 'workspaceId'
}

/** input type for updating data in table "task" */
export type Task_Set_Input = {
  appointmentId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  dueDate?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  patientId?: InputMaybe<Scalars['uuid']>;
  providerId?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  userId?: InputMaybe<Scalars['uuid']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "task" */
export type Task_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Task_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Task_Stream_Cursor_Value_Input = {
  appointmentId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  dueDate?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  patientId?: InputMaybe<Scalars['uuid']>;
  providerId?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  userId?: InputMaybe<Scalars['uuid']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "task" */
export enum Task_Update_Column {
  /** column name */
  AppointmentId = 'appointmentId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  DueDate = 'dueDate',
  /** column name */
  Id = 'id',
  /** column name */
  PatientId = 'patientId',
  /** column name */
  ProviderId = 'providerId',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId',
  /** column name */
  WorkspaceId = 'workspaceId'
}

export type Task_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Task_Set_Input>;
  /** filter the rows which have to be updated */
  where: Task_Bool_Exp;
};

/** columns and relationships of "telehealthMeeting" */
export type TelehealthMeeting = {
  __typename?: 'telehealthMeeting';
  /** An object relationship */
  appointment?: Maybe<Appointment>;
  appointmentId?: Maybe<Scalars['uuid']>;
  createdAt: Scalars['timestamptz'];
  endTime: Scalars['timestamptz'];
  hostLink: Scalars['String'];
  id: Scalars['uuid'];
  participantLink: Scalars['String'];
  /** An object relationship */
  patient: Patient;
  patientId: Scalars['uuid'];
  updatedAt: Scalars['timestamptz'];
  whereByMeetingId?: Maybe<Scalars['String']>;
  workspaceId: Scalars['uuid'];
};

/** aggregated selection of "telehealthMeeting" */
export type TelehealthMeeting_Aggregate = {
  __typename?: 'telehealthMeeting_aggregate';
  aggregate?: Maybe<TelehealthMeeting_Aggregate_Fields>;
  nodes: Array<TelehealthMeeting>;
};

/** aggregate fields of "telehealthMeeting" */
export type TelehealthMeeting_Aggregate_Fields = {
  __typename?: 'telehealthMeeting_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<TelehealthMeeting_Max_Fields>;
  min?: Maybe<TelehealthMeeting_Min_Fields>;
};


/** aggregate fields of "telehealthMeeting" */
export type TelehealthMeeting_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<TelehealthMeeting_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "telehealthMeeting". All fields are combined with a logical 'AND'. */
export type TelehealthMeeting_Bool_Exp = {
  _and?: InputMaybe<Array<TelehealthMeeting_Bool_Exp>>;
  _not?: InputMaybe<TelehealthMeeting_Bool_Exp>;
  _or?: InputMaybe<Array<TelehealthMeeting_Bool_Exp>>;
  appointment?: InputMaybe<Appointment_Bool_Exp>;
  appointmentId?: InputMaybe<Uuid_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  endTime?: InputMaybe<Timestamptz_Comparison_Exp>;
  hostLink?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  participantLink?: InputMaybe<String_Comparison_Exp>;
  patient?: InputMaybe<Patient_Bool_Exp>;
  patientId?: InputMaybe<Uuid_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  whereByMeetingId?: InputMaybe<String_Comparison_Exp>;
  workspaceId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "telehealthMeeting" */
export enum TelehealthMeeting_Constraint {
  /** unique or primary key constraint on columns "id" */
  TelehealthMeetingPkey = 'telehealthMeeting_pkey'
}

/** input type for inserting data into table "telehealthMeeting" */
export type TelehealthMeeting_Insert_Input = {
  appointment?: InputMaybe<Appointment_Obj_Rel_Insert_Input>;
  appointmentId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  endTime?: InputMaybe<Scalars['timestamptz']>;
  hostLink?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  participantLink?: InputMaybe<Scalars['String']>;
  patient?: InputMaybe<Patient_Obj_Rel_Insert_Input>;
  patientId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  whereByMeetingId?: InputMaybe<Scalars['String']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type TelehealthMeeting_Max_Fields = {
  __typename?: 'telehealthMeeting_max_fields';
  appointmentId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  endTime?: Maybe<Scalars['timestamptz']>;
  hostLink?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  participantLink?: Maybe<Scalars['String']>;
  patientId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  whereByMeetingId?: Maybe<Scalars['String']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type TelehealthMeeting_Min_Fields = {
  __typename?: 'telehealthMeeting_min_fields';
  appointmentId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  endTime?: Maybe<Scalars['timestamptz']>;
  hostLink?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  participantLink?: Maybe<Scalars['String']>;
  patientId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  whereByMeetingId?: Maybe<Scalars['String']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "telehealthMeeting" */
export type TelehealthMeeting_Mutation_Response = {
  __typename?: 'telehealthMeeting_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<TelehealthMeeting>;
};

/** on_conflict condition type for table "telehealthMeeting" */
export type TelehealthMeeting_On_Conflict = {
  constraint: TelehealthMeeting_Constraint;
  update_columns?: Array<TelehealthMeeting_Update_Column>;
  where?: InputMaybe<TelehealthMeeting_Bool_Exp>;
};

/** Ordering options when selecting data from "telehealthMeeting". */
export type TelehealthMeeting_Order_By = {
  appointment?: InputMaybe<Appointment_Order_By>;
  appointmentId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  endTime?: InputMaybe<Order_By>;
  hostLink?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  participantLink?: InputMaybe<Order_By>;
  patient?: InputMaybe<Patient_Order_By>;
  patientId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  whereByMeetingId?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: telehealthMeeting */
export type TelehealthMeeting_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "telehealthMeeting" */
export enum TelehealthMeeting_Select_Column {
  /** column name */
  AppointmentId = 'appointmentId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  EndTime = 'endTime',
  /** column name */
  HostLink = 'hostLink',
  /** column name */
  Id = 'id',
  /** column name */
  ParticipantLink = 'participantLink',
  /** column name */
  PatientId = 'patientId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WhereByMeetingId = 'whereByMeetingId',
  /** column name */
  WorkspaceId = 'workspaceId'
}

/** input type for updating data in table "telehealthMeeting" */
export type TelehealthMeeting_Set_Input = {
  appointmentId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  endTime?: InputMaybe<Scalars['timestamptz']>;
  hostLink?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  participantLink?: InputMaybe<Scalars['String']>;
  patientId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  whereByMeetingId?: InputMaybe<Scalars['String']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "telehealthMeeting" */
export type TelehealthMeeting_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: TelehealthMeeting_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type TelehealthMeeting_Stream_Cursor_Value_Input = {
  appointmentId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  endTime?: InputMaybe<Scalars['timestamptz']>;
  hostLink?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  participantLink?: InputMaybe<Scalars['String']>;
  patientId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  whereByMeetingId?: InputMaybe<Scalars['String']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "telehealthMeeting" */
export enum TelehealthMeeting_Update_Column {
  /** column name */
  AppointmentId = 'appointmentId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  EndTime = 'endTime',
  /** column name */
  HostLink = 'hostLink',
  /** column name */
  Id = 'id',
  /** column name */
  ParticipantLink = 'participantLink',
  /** column name */
  PatientId = 'patientId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WhereByMeetingId = 'whereByMeetingId',
  /** column name */
  WorkspaceId = 'workspaceId'
}

export type TelehealthMeeting_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<TelehealthMeeting_Set_Input>;
  /** filter the rows which have to be updated */
  where: TelehealthMeeting_Bool_Exp;
};

/** Boolean expression to compare columns of type "timestamp". All fields are combined with logical 'AND'. */
export type Timestamp_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamp']>;
  _gt?: InputMaybe<Scalars['timestamp']>;
  _gte?: InputMaybe<Scalars['timestamp']>;
  _in?: InputMaybe<Array<Scalars['timestamp']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['timestamp']>;
  _lte?: InputMaybe<Scalars['timestamp']>;
  _neq?: InputMaybe<Scalars['timestamp']>;
  _nin?: InputMaybe<Array<Scalars['timestamp']>>;
};

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamptz']>;
  _gt?: InputMaybe<Scalars['timestamptz']>;
  _gte?: InputMaybe<Scalars['timestamptz']>;
  _in?: InputMaybe<Array<Scalars['timestamptz']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['timestamptz']>;
  _lte?: InputMaybe<Scalars['timestamptz']>;
  _neq?: InputMaybe<Scalars['timestamptz']>;
  _nin?: InputMaybe<Array<Scalars['timestamptz']>>;
};

/** Boolean expression to compare columns of type "timetz". All fields are combined with logical 'AND'. */
export type Timetz_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timetz']>;
  _gt?: InputMaybe<Scalars['timetz']>;
  _gte?: InputMaybe<Scalars['timetz']>;
  _in?: InputMaybe<Array<Scalars['timetz']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['timetz']>;
  _lte?: InputMaybe<Scalars['timetz']>;
  _neq?: InputMaybe<Scalars['timetz']>;
  _nin?: InputMaybe<Array<Scalars['timetz']>>;
};

/** Boolean expression to compare columns of type "tstzrange". All fields are combined with logical 'AND'. */
export type Tstzrange_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['tstzrange']>;
  _gt?: InputMaybe<Scalars['tstzrange']>;
  _gte?: InputMaybe<Scalars['tstzrange']>;
  _in?: InputMaybe<Array<Scalars['tstzrange']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['tstzrange']>;
  _lte?: InputMaybe<Scalars['tstzrange']>;
  _neq?: InputMaybe<Scalars['tstzrange']>;
  _nin?: InputMaybe<Array<Scalars['tstzrange']>>;
};

/** Boolean expression to compare columns of type "tsvector". All fields are combined with logical 'AND'. */
export type Tsvector_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['tsvector']>;
  _gt?: InputMaybe<Scalars['tsvector']>;
  _gte?: InputMaybe<Scalars['tsvector']>;
  _in?: InputMaybe<Array<Scalars['tsvector']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['tsvector']>;
  _lte?: InputMaybe<Scalars['tsvector']>;
  _neq?: InputMaybe<Scalars['tsvector']>;
  _nin?: InputMaybe<Array<Scalars['tsvector']>>;
};

/** columns and relationships of "unusedAppointmentServiceConsumableType" */
export type UnusedAppointmentServiceConsumableType = {
  __typename?: 'unusedAppointmentServiceConsumableType';
  appointmentServiceId: Scalars['uuid'];
  /** An object relationship */
  consumableType: ConsumableType;
  consumableTypeId: Scalars['uuid'];
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  updatedAt: Scalars['timestamptz'];
};

/** aggregated selection of "unusedAppointmentServiceConsumableType" */
export type UnusedAppointmentServiceConsumableType_Aggregate = {
  __typename?: 'unusedAppointmentServiceConsumableType_aggregate';
  aggregate?: Maybe<UnusedAppointmentServiceConsumableType_Aggregate_Fields>;
  nodes: Array<UnusedAppointmentServiceConsumableType>;
};

/** aggregate fields of "unusedAppointmentServiceConsumableType" */
export type UnusedAppointmentServiceConsumableType_Aggregate_Fields = {
  __typename?: 'unusedAppointmentServiceConsumableType_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<UnusedAppointmentServiceConsumableType_Max_Fields>;
  min?: Maybe<UnusedAppointmentServiceConsumableType_Min_Fields>;
};


/** aggregate fields of "unusedAppointmentServiceConsumableType" */
export type UnusedAppointmentServiceConsumableType_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<UnusedAppointmentServiceConsumableType_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "unusedAppointmentServiceConsumableType". All fields are combined with a logical 'AND'. */
export type UnusedAppointmentServiceConsumableType_Bool_Exp = {
  _and?: InputMaybe<Array<UnusedAppointmentServiceConsumableType_Bool_Exp>>;
  _not?: InputMaybe<UnusedAppointmentServiceConsumableType_Bool_Exp>;
  _or?: InputMaybe<Array<UnusedAppointmentServiceConsumableType_Bool_Exp>>;
  appointmentServiceId?: InputMaybe<Uuid_Comparison_Exp>;
  consumableType?: InputMaybe<ConsumableType_Bool_Exp>;
  consumableTypeId?: InputMaybe<Uuid_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "unusedAppointmentServiceConsumableType" */
export enum UnusedAppointmentServiceConsumableType_Constraint {
  /** unique or primary key constraint on columns "appointmentServiceId", "consumableTypeId" */
  UnusedAppointmentServiceConsuAppointmentServiceIdConsumabKey = 'unusedAppointmentServiceConsu_appointmentServiceId_consumab_key',
  /** unique or primary key constraint on columns "id" */
  UnusedAppointmentServiceConsumableTypePkey = 'unusedAppointmentServiceConsumableType_pkey'
}

/** input type for inserting data into table "unusedAppointmentServiceConsumableType" */
export type UnusedAppointmentServiceConsumableType_Insert_Input = {
  appointmentServiceId?: InputMaybe<Scalars['uuid']>;
  consumableType?: InputMaybe<ConsumableType_Obj_Rel_Insert_Input>;
  consumableTypeId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type UnusedAppointmentServiceConsumableType_Max_Fields = {
  __typename?: 'unusedAppointmentServiceConsumableType_max_fields';
  appointmentServiceId?: Maybe<Scalars['uuid']>;
  consumableTypeId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type UnusedAppointmentServiceConsumableType_Min_Fields = {
  __typename?: 'unusedAppointmentServiceConsumableType_min_fields';
  appointmentServiceId?: Maybe<Scalars['uuid']>;
  consumableTypeId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "unusedAppointmentServiceConsumableType" */
export type UnusedAppointmentServiceConsumableType_Mutation_Response = {
  __typename?: 'unusedAppointmentServiceConsumableType_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<UnusedAppointmentServiceConsumableType>;
};

/** on_conflict condition type for table "unusedAppointmentServiceConsumableType" */
export type UnusedAppointmentServiceConsumableType_On_Conflict = {
  constraint: UnusedAppointmentServiceConsumableType_Constraint;
  update_columns?: Array<UnusedAppointmentServiceConsumableType_Update_Column>;
  where?: InputMaybe<UnusedAppointmentServiceConsumableType_Bool_Exp>;
};

/** Ordering options when selecting data from "unusedAppointmentServiceConsumableType". */
export type UnusedAppointmentServiceConsumableType_Order_By = {
  appointmentServiceId?: InputMaybe<Order_By>;
  consumableType?: InputMaybe<ConsumableType_Order_By>;
  consumableTypeId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: unusedAppointmentServiceConsumableType */
export type UnusedAppointmentServiceConsumableType_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "unusedAppointmentServiceConsumableType" */
export enum UnusedAppointmentServiceConsumableType_Select_Column {
  /** column name */
  AppointmentServiceId = 'appointmentServiceId',
  /** column name */
  ConsumableTypeId = 'consumableTypeId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "unusedAppointmentServiceConsumableType" */
export type UnusedAppointmentServiceConsumableType_Set_Input = {
  appointmentServiceId?: InputMaybe<Scalars['uuid']>;
  consumableTypeId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "unusedAppointmentServiceConsumableType" */
export type UnusedAppointmentServiceConsumableType_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: UnusedAppointmentServiceConsumableType_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type UnusedAppointmentServiceConsumableType_Stream_Cursor_Value_Input = {
  appointmentServiceId?: InputMaybe<Scalars['uuid']>;
  consumableTypeId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "unusedAppointmentServiceConsumableType" */
export enum UnusedAppointmentServiceConsumableType_Update_Column {
  /** column name */
  AppointmentServiceId = 'appointmentServiceId',
  /** column name */
  ConsumableTypeId = 'consumableTypeId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type UnusedAppointmentServiceConsumableType_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<UnusedAppointmentServiceConsumableType_Set_Input>;
  /** filter the rows which have to be updated */
  where: UnusedAppointmentServiceConsumableType_Bool_Exp;
};

export type UpsertPatientOutput = {
  __typename?: 'upsertPatientOutput';
  patientId: Scalars['String'];
  success: Scalars['Boolean'];
};

/** columns and relationships of "user" */
export type User = {
  __typename?: 'user';
  /** An array relationship */
  activityLogs: Array<ActivityLog>;
  /** An aggregate relationship */
  activityLogs_aggregate: ActivityLog_Aggregate;
  /** An array relationship */
  authProfiles: Array<AuthProfile>;
  /** An aggregate relationship */
  authProfiles_aggregate: AuthProfile_Aggregate;
  createdAt: Scalars['timestamptz'];
  email: Scalars['String'];
  firebaseUid: Scalars['String'];
  firstName: Scalars['String'];
  /** An array relationship */
  googleAccounts: Array<GoogleAccount>;
  /** An aggregate relationship */
  googleAccounts_aggregate: GoogleAccount_Aggregate;
  id: Scalars['uuid'];
  knockUserToken?: Maybe<Scalars['String']>;
  lastName: Scalars['String'];
  /** An array relationship */
  notes: Array<Note>;
  /** An aggregate relationship */
  notes_aggregate: Note_Aggregate;
  phoneNumber?: Maybe<Scalars['String']>;
  profilePicture?: Maybe<Scalars['String']>;
  /** An array relationship */
  providers: Array<Provider>;
  /** An aggregate relationship */
  providers_aggregate: Provider_Aggregate;
  role: Scalars['String'];
  /** An array relationship */
  socialMediaAccounts: Array<SocialMediaAccount>;
  /** An aggregate relationship */
  socialMediaAccounts_aggregate: SocialMediaAccount_Aggregate;
  title?: Maybe<Scalars['String']>;
  twoFactorAuthEnabled?: Maybe<Scalars['Boolean']>;
  updatedAt: Scalars['timestamptz'];
  /** An array relationship */
  userInvites: Array<UserInvite>;
  /** An aggregate relationship */
  userInvites_aggregate: UserInvite_Aggregate;
  /** An object relationship */
  userWorkspace?: Maybe<UserWorkspace>;
  /** An array relationship */
  userWorkspaces: Array<UserWorkspace>;
  /** An aggregate relationship */
  userWorkspaces_aggregate: UserWorkspace_Aggregate;
};


/** columns and relationships of "user" */
export type UserActivityLogsArgs = {
  distinct_on?: InputMaybe<Array<ActivityLog_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ActivityLog_Order_By>>;
  where?: InputMaybe<ActivityLog_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserActivityLogs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ActivityLog_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ActivityLog_Order_By>>;
  where?: InputMaybe<ActivityLog_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserAuthProfilesArgs = {
  distinct_on?: InputMaybe<Array<AuthProfile_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AuthProfile_Order_By>>;
  where?: InputMaybe<AuthProfile_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserAuthProfiles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<AuthProfile_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AuthProfile_Order_By>>;
  where?: InputMaybe<AuthProfile_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserGoogleAccountsArgs = {
  distinct_on?: InputMaybe<Array<GoogleAccount_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GoogleAccount_Order_By>>;
  where?: InputMaybe<GoogleAccount_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserGoogleAccounts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<GoogleAccount_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GoogleAccount_Order_By>>;
  where?: InputMaybe<GoogleAccount_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserNotesArgs = {
  distinct_on?: InputMaybe<Array<Note_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Note_Order_By>>;
  where?: InputMaybe<Note_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserNotes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Note_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Note_Order_By>>;
  where?: InputMaybe<Note_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserProvidersArgs = {
  distinct_on?: InputMaybe<Array<Provider_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Provider_Order_By>>;
  where?: InputMaybe<Provider_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserProviders_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Provider_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Provider_Order_By>>;
  where?: InputMaybe<Provider_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserSocialMediaAccountsArgs = {
  distinct_on?: InputMaybe<Array<SocialMediaAccount_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SocialMediaAccount_Order_By>>;
  where?: InputMaybe<SocialMediaAccount_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserSocialMediaAccounts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<SocialMediaAccount_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SocialMediaAccount_Order_By>>;
  where?: InputMaybe<SocialMediaAccount_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserUserInvitesArgs = {
  distinct_on?: InputMaybe<Array<UserInvite_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserInvite_Order_By>>;
  where?: InputMaybe<UserInvite_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserUserInvites_AggregateArgs = {
  distinct_on?: InputMaybe<Array<UserInvite_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserInvite_Order_By>>;
  where?: InputMaybe<UserInvite_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserUserWorkspacesArgs = {
  distinct_on?: InputMaybe<Array<UserWorkspace_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserWorkspace_Order_By>>;
  where?: InputMaybe<UserWorkspace_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserUserWorkspaces_AggregateArgs = {
  distinct_on?: InputMaybe<Array<UserWorkspace_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserWorkspace_Order_By>>;
  where?: InputMaybe<UserWorkspace_Bool_Exp>;
};

/** columns and relationships of "userInvite" */
export type UserInvite = {
  __typename?: 'userInvite';
  createdAt: Scalars['timestamptz'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['uuid'];
  invitedByUserId: Scalars['uuid'];
  lastName?: Maybe<Scalars['String']>;
  role: Scalars['String'];
  status: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  /** An object relationship */
  user: User;
  /** An object relationship */
  workspace: Workspace;
  workspaceId: Scalars['uuid'];
};

/** aggregated selection of "userInvite" */
export type UserInvite_Aggregate = {
  __typename?: 'userInvite_aggregate';
  aggregate?: Maybe<UserInvite_Aggregate_Fields>;
  nodes: Array<UserInvite>;
};

export type UserInvite_Aggregate_Bool_Exp = {
  count?: InputMaybe<UserInvite_Aggregate_Bool_Exp_Count>;
};

export type UserInvite_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<UserInvite_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<UserInvite_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "userInvite" */
export type UserInvite_Aggregate_Fields = {
  __typename?: 'userInvite_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<UserInvite_Max_Fields>;
  min?: Maybe<UserInvite_Min_Fields>;
};


/** aggregate fields of "userInvite" */
export type UserInvite_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<UserInvite_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "userInvite" */
export type UserInvite_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<UserInvite_Max_Order_By>;
  min?: InputMaybe<UserInvite_Min_Order_By>;
};

/** input type for inserting array relation for remote table "userInvite" */
export type UserInvite_Arr_Rel_Insert_Input = {
  data: Array<UserInvite_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<UserInvite_On_Conflict>;
};

/** Boolean expression to filter rows from the table "userInvite". All fields are combined with a logical 'AND'. */
export type UserInvite_Bool_Exp = {
  _and?: InputMaybe<Array<UserInvite_Bool_Exp>>;
  _not?: InputMaybe<UserInvite_Bool_Exp>;
  _or?: InputMaybe<Array<UserInvite_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  firstName?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  invitedByUserId?: InputMaybe<Uuid_Comparison_Exp>;
  lastName?: InputMaybe<String_Comparison_Exp>;
  role?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<User_Bool_Exp>;
  workspace?: InputMaybe<Workspace_Bool_Exp>;
  workspaceId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "userInvite" */
export enum UserInvite_Constraint {
  /** unique or primary key constraint on columns "workspaceId", "email" */
  UserInviteEmailWorkspaceIdKey = 'userInvite_email_workspaceId_key',
  /** unique or primary key constraint on columns "id" */
  UserInvitePkey = 'userInvite_pkey'
}

/** input type for inserting data into table "userInvite" */
export type UserInvite_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  invitedByUserId?: InputMaybe<Scalars['uuid']>;
  lastName?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<User_Obj_Rel_Insert_Input>;
  workspace?: InputMaybe<Workspace_Obj_Rel_Insert_Input>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type UserInvite_Max_Fields = {
  __typename?: 'userInvite_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  invitedByUserId?: Maybe<Scalars['uuid']>;
  lastName?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "userInvite" */
export type UserInvite_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  firstName?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invitedByUserId?: InputMaybe<Order_By>;
  lastName?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type UserInvite_Min_Fields = {
  __typename?: 'userInvite_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  invitedByUserId?: Maybe<Scalars['uuid']>;
  lastName?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "userInvite" */
export type UserInvite_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  firstName?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invitedByUserId?: InputMaybe<Order_By>;
  lastName?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "userInvite" */
export type UserInvite_Mutation_Response = {
  __typename?: 'userInvite_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<UserInvite>;
};

/** on_conflict condition type for table "userInvite" */
export type UserInvite_On_Conflict = {
  constraint: UserInvite_Constraint;
  update_columns?: Array<UserInvite_Update_Column>;
  where?: InputMaybe<UserInvite_Bool_Exp>;
};

/** Ordering options when selecting data from "userInvite". */
export type UserInvite_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  firstName?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invitedByUserId?: InputMaybe<Order_By>;
  lastName?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  user?: InputMaybe<User_Order_By>;
  workspace?: InputMaybe<Workspace_Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: userInvite */
export type UserInvite_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "userInvite" */
export enum UserInvite_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'firstName',
  /** column name */
  Id = 'id',
  /** column name */
  InvitedByUserId = 'invitedByUserId',
  /** column name */
  LastName = 'lastName',
  /** column name */
  Role = 'role',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WorkspaceId = 'workspaceId'
}

/** input type for updating data in table "userInvite" */
export type UserInvite_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  invitedByUserId?: InputMaybe<Scalars['uuid']>;
  lastName?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "userInvite" */
export type UserInvite_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: UserInvite_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type UserInvite_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  invitedByUserId?: InputMaybe<Scalars['uuid']>;
  lastName?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "userInvite" */
export enum UserInvite_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'firstName',
  /** column name */
  Id = 'id',
  /** column name */
  InvitedByUserId = 'invitedByUserId',
  /** column name */
  LastName = 'lastName',
  /** column name */
  Role = 'role',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WorkspaceId = 'workspaceId'
}

export type UserInvite_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<UserInvite_Set_Input>;
  /** filter the rows which have to be updated */
  where: UserInvite_Bool_Exp;
};

/** columns and relationships of "userOrganization" */
export type UserOrganization = {
  __typename?: 'userOrganization';
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  organizationId: Scalars['uuid'];
  role: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  userId: Scalars['uuid'];
};

/** aggregated selection of "userOrganization" */
export type UserOrganization_Aggregate = {
  __typename?: 'userOrganization_aggregate';
  aggregate?: Maybe<UserOrganization_Aggregate_Fields>;
  nodes: Array<UserOrganization>;
};

/** aggregate fields of "userOrganization" */
export type UserOrganization_Aggregate_Fields = {
  __typename?: 'userOrganization_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<UserOrganization_Max_Fields>;
  min?: Maybe<UserOrganization_Min_Fields>;
};


/** aggregate fields of "userOrganization" */
export type UserOrganization_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<UserOrganization_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "userOrganization". All fields are combined with a logical 'AND'. */
export type UserOrganization_Bool_Exp = {
  _and?: InputMaybe<Array<UserOrganization_Bool_Exp>>;
  _not?: InputMaybe<UserOrganization_Bool_Exp>;
  _or?: InputMaybe<Array<UserOrganization_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  organizationId?: InputMaybe<Uuid_Comparison_Exp>;
  role?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  userId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "userOrganization" */
export enum UserOrganization_Constraint {
  /** unique or primary key constraint on columns "id" */
  UserOrganizationPkey = 'userOrganization_pkey'
}

/** input type for inserting data into table "userOrganization" */
export type UserOrganization_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  organizationId?: InputMaybe<Scalars['uuid']>;
  role?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type UserOrganization_Max_Fields = {
  __typename?: 'userOrganization_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  organizationId?: Maybe<Scalars['uuid']>;
  role?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type UserOrganization_Min_Fields = {
  __typename?: 'userOrganization_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  organizationId?: Maybe<Scalars['uuid']>;
  role?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "userOrganization" */
export type UserOrganization_Mutation_Response = {
  __typename?: 'userOrganization_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<UserOrganization>;
};

/** on_conflict condition type for table "userOrganization" */
export type UserOrganization_On_Conflict = {
  constraint: UserOrganization_Constraint;
  update_columns?: Array<UserOrganization_Update_Column>;
  where?: InputMaybe<UserOrganization_Bool_Exp>;
};

/** Ordering options when selecting data from "userOrganization". */
export type UserOrganization_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  organizationId?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: userOrganization */
export type UserOrganization_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "userOrganization" */
export enum UserOrganization_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  OrganizationId = 'organizationId',
  /** column name */
  Role = 'role',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId'
}

/** input type for updating data in table "userOrganization" */
export type UserOrganization_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  organizationId?: InputMaybe<Scalars['uuid']>;
  role?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "userOrganization" */
export type UserOrganization_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: UserOrganization_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type UserOrganization_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  organizationId?: InputMaybe<Scalars['uuid']>;
  role?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "userOrganization" */
export enum UserOrganization_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  OrganizationId = 'organizationId',
  /** column name */
  Role = 'role',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId'
}

export type UserOrganization_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<UserOrganization_Set_Input>;
  /** filter the rows which have to be updated */
  where: UserOrganization_Bool_Exp;
};

/** columns and relationships of "userWorkspace" */
export type UserWorkspace = {
  __typename?: 'userWorkspace';
  createdAt: Scalars['timestamptz'];
  /** An object relationship */
  defaultLocation?: Maybe<Location>;
  defaultLocationId?: Maybe<Scalars['uuid']>;
  externalCalendarSyncId?: Maybe<Scalars['String']>;
  googleCalendarId?: Maybe<Scalars['String']>;
  healthGorillaId?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  isAppointmentScheduleSynced?: Maybe<Scalars['Boolean']>;
  isArchived?: Maybe<Scalars['Boolean']>;
  isGoogleCalendarSynced?: Maybe<Scalars['Boolean']>;
  isListMobileView: Scalars['Boolean'];
  isManager?: Maybe<Scalars['Boolean']>;
  lockTimezoneToDefaultLocation: Scalars['Boolean'];
  /** An object relationship */
  lockedToLocation?: Maybe<Location>;
  managerPin?: Maybe<Scalars['String']>;
  nylasGrant?: Maybe<Scalars['String']>;
  reportsLockedToLocationId?: Maybe<Scalars['uuid']>;
  role: Scalars['String'];
  sendProviderAppointmentReminder?: Maybe<Scalars['Boolean']>;
  updatedAt: Scalars['timestamptz'];
  /** An object relationship */
  user: User;
  userId: Scalars['uuid'];
  /** An object relationship */
  workspace: Workspace;
  workspaceId: Scalars['uuid'];
};

/** aggregated selection of "userWorkspace" */
export type UserWorkspace_Aggregate = {
  __typename?: 'userWorkspace_aggregate';
  aggregate?: Maybe<UserWorkspace_Aggregate_Fields>;
  nodes: Array<UserWorkspace>;
};

export type UserWorkspace_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<UserWorkspace_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<UserWorkspace_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<UserWorkspace_Aggregate_Bool_Exp_Count>;
};

export type UserWorkspace_Aggregate_Bool_Exp_Bool_And = {
  arguments: UserWorkspace_Select_Column_UserWorkspace_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<UserWorkspace_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type UserWorkspace_Aggregate_Bool_Exp_Bool_Or = {
  arguments: UserWorkspace_Select_Column_UserWorkspace_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<UserWorkspace_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type UserWorkspace_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<UserWorkspace_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<UserWorkspace_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "userWorkspace" */
export type UserWorkspace_Aggregate_Fields = {
  __typename?: 'userWorkspace_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<UserWorkspace_Max_Fields>;
  min?: Maybe<UserWorkspace_Min_Fields>;
};


/** aggregate fields of "userWorkspace" */
export type UserWorkspace_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<UserWorkspace_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "userWorkspace" */
export type UserWorkspace_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<UserWorkspace_Max_Order_By>;
  min?: InputMaybe<UserWorkspace_Min_Order_By>;
};

/** input type for inserting array relation for remote table "userWorkspace" */
export type UserWorkspace_Arr_Rel_Insert_Input = {
  data: Array<UserWorkspace_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<UserWorkspace_On_Conflict>;
};

/** Boolean expression to filter rows from the table "userWorkspace". All fields are combined with a logical 'AND'. */
export type UserWorkspace_Bool_Exp = {
  _and?: InputMaybe<Array<UserWorkspace_Bool_Exp>>;
  _not?: InputMaybe<UserWorkspace_Bool_Exp>;
  _or?: InputMaybe<Array<UserWorkspace_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  defaultLocation?: InputMaybe<Location_Bool_Exp>;
  defaultLocationId?: InputMaybe<Uuid_Comparison_Exp>;
  externalCalendarSyncId?: InputMaybe<String_Comparison_Exp>;
  googleCalendarId?: InputMaybe<String_Comparison_Exp>;
  healthGorillaId?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  isAppointmentScheduleSynced?: InputMaybe<Boolean_Comparison_Exp>;
  isArchived?: InputMaybe<Boolean_Comparison_Exp>;
  isGoogleCalendarSynced?: InputMaybe<Boolean_Comparison_Exp>;
  isListMobileView?: InputMaybe<Boolean_Comparison_Exp>;
  isManager?: InputMaybe<Boolean_Comparison_Exp>;
  lockTimezoneToDefaultLocation?: InputMaybe<Boolean_Comparison_Exp>;
  lockedToLocation?: InputMaybe<Location_Bool_Exp>;
  managerPin?: InputMaybe<String_Comparison_Exp>;
  nylasGrant?: InputMaybe<String_Comparison_Exp>;
  reportsLockedToLocationId?: InputMaybe<Uuid_Comparison_Exp>;
  role?: InputMaybe<String_Comparison_Exp>;
  sendProviderAppointmentReminder?: InputMaybe<Boolean_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<User_Bool_Exp>;
  userId?: InputMaybe<Uuid_Comparison_Exp>;
  workspace?: InputMaybe<Workspace_Bool_Exp>;
  workspaceId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "userWorkspace" */
export enum UserWorkspace_Constraint {
  /** unique or primary key constraint on columns "id" */
  UserWorkspacePkey = 'userWorkspace_pkey',
  /** unique or primary key constraint on columns "userId", "workspaceId" */
  UserWorkspaceUserIdWorkspaceIdKey = 'userWorkspace_userId_workspaceId_key'
}

/** input type for inserting data into table "userWorkspace" */
export type UserWorkspace_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  defaultLocation?: InputMaybe<Location_Obj_Rel_Insert_Input>;
  defaultLocationId?: InputMaybe<Scalars['uuid']>;
  externalCalendarSyncId?: InputMaybe<Scalars['String']>;
  googleCalendarId?: InputMaybe<Scalars['String']>;
  healthGorillaId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  isAppointmentScheduleSynced?: InputMaybe<Scalars['Boolean']>;
  isArchived?: InputMaybe<Scalars['Boolean']>;
  isGoogleCalendarSynced?: InputMaybe<Scalars['Boolean']>;
  isListMobileView?: InputMaybe<Scalars['Boolean']>;
  isManager?: InputMaybe<Scalars['Boolean']>;
  lockTimezoneToDefaultLocation?: InputMaybe<Scalars['Boolean']>;
  lockedToLocation?: InputMaybe<Location_Obj_Rel_Insert_Input>;
  managerPin?: InputMaybe<Scalars['String']>;
  nylasGrant?: InputMaybe<Scalars['String']>;
  reportsLockedToLocationId?: InputMaybe<Scalars['uuid']>;
  role?: InputMaybe<Scalars['String']>;
  sendProviderAppointmentReminder?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<User_Obj_Rel_Insert_Input>;
  userId?: InputMaybe<Scalars['uuid']>;
  workspace?: InputMaybe<Workspace_Obj_Rel_Insert_Input>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type UserWorkspace_Max_Fields = {
  __typename?: 'userWorkspace_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  defaultLocationId?: Maybe<Scalars['uuid']>;
  externalCalendarSyncId?: Maybe<Scalars['String']>;
  googleCalendarId?: Maybe<Scalars['String']>;
  healthGorillaId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  managerPin?: Maybe<Scalars['String']>;
  nylasGrant?: Maybe<Scalars['String']>;
  reportsLockedToLocationId?: Maybe<Scalars['uuid']>;
  role?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['uuid']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "userWorkspace" */
export type UserWorkspace_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  defaultLocationId?: InputMaybe<Order_By>;
  externalCalendarSyncId?: InputMaybe<Order_By>;
  googleCalendarId?: InputMaybe<Order_By>;
  healthGorillaId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  managerPin?: InputMaybe<Order_By>;
  nylasGrant?: InputMaybe<Order_By>;
  reportsLockedToLocationId?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type UserWorkspace_Min_Fields = {
  __typename?: 'userWorkspace_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  defaultLocationId?: Maybe<Scalars['uuid']>;
  externalCalendarSyncId?: Maybe<Scalars['String']>;
  googleCalendarId?: Maybe<Scalars['String']>;
  healthGorillaId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  managerPin?: Maybe<Scalars['String']>;
  nylasGrant?: Maybe<Scalars['String']>;
  reportsLockedToLocationId?: Maybe<Scalars['uuid']>;
  role?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['uuid']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "userWorkspace" */
export type UserWorkspace_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  defaultLocationId?: InputMaybe<Order_By>;
  externalCalendarSyncId?: InputMaybe<Order_By>;
  googleCalendarId?: InputMaybe<Order_By>;
  healthGorillaId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  managerPin?: InputMaybe<Order_By>;
  nylasGrant?: InputMaybe<Order_By>;
  reportsLockedToLocationId?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "userWorkspace" */
export type UserWorkspace_Mutation_Response = {
  __typename?: 'userWorkspace_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<UserWorkspace>;
};

/** input type for inserting object relation for remote table "userWorkspace" */
export type UserWorkspace_Obj_Rel_Insert_Input = {
  data: UserWorkspace_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<UserWorkspace_On_Conflict>;
};

/** on_conflict condition type for table "userWorkspace" */
export type UserWorkspace_On_Conflict = {
  constraint: UserWorkspace_Constraint;
  update_columns?: Array<UserWorkspace_Update_Column>;
  where?: InputMaybe<UserWorkspace_Bool_Exp>;
};

/** Ordering options when selecting data from "userWorkspace". */
export type UserWorkspace_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  defaultLocation?: InputMaybe<Location_Order_By>;
  defaultLocationId?: InputMaybe<Order_By>;
  externalCalendarSyncId?: InputMaybe<Order_By>;
  googleCalendarId?: InputMaybe<Order_By>;
  healthGorillaId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  isAppointmentScheduleSynced?: InputMaybe<Order_By>;
  isArchived?: InputMaybe<Order_By>;
  isGoogleCalendarSynced?: InputMaybe<Order_By>;
  isListMobileView?: InputMaybe<Order_By>;
  isManager?: InputMaybe<Order_By>;
  lockTimezoneToDefaultLocation?: InputMaybe<Order_By>;
  lockedToLocation?: InputMaybe<Location_Order_By>;
  managerPin?: InputMaybe<Order_By>;
  nylasGrant?: InputMaybe<Order_By>;
  reportsLockedToLocationId?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  sendProviderAppointmentReminder?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  user?: InputMaybe<User_Order_By>;
  userId?: InputMaybe<Order_By>;
  workspace?: InputMaybe<Workspace_Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: userWorkspace */
export type UserWorkspace_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "userWorkspace" */
export enum UserWorkspace_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DefaultLocationId = 'defaultLocationId',
  /** column name */
  ExternalCalendarSyncId = 'externalCalendarSyncId',
  /** column name */
  GoogleCalendarId = 'googleCalendarId',
  /** column name */
  HealthGorillaId = 'healthGorillaId',
  /** column name */
  Id = 'id',
  /** column name */
  IsAppointmentScheduleSynced = 'isAppointmentScheduleSynced',
  /** column name */
  IsArchived = 'isArchived',
  /** column name */
  IsGoogleCalendarSynced = 'isGoogleCalendarSynced',
  /** column name */
  IsListMobileView = 'isListMobileView',
  /** column name */
  IsManager = 'isManager',
  /** column name */
  LockTimezoneToDefaultLocation = 'lockTimezoneToDefaultLocation',
  /** column name */
  ManagerPin = 'managerPin',
  /** column name */
  NylasGrant = 'nylasGrant',
  /** column name */
  ReportsLockedToLocationId = 'reportsLockedToLocationId',
  /** column name */
  Role = 'role',
  /** column name */
  SendProviderAppointmentReminder = 'sendProviderAppointmentReminder',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId',
  /** column name */
  WorkspaceId = 'workspaceId'
}

/** select "userWorkspace_aggregate_bool_exp_bool_and_arguments_columns" columns of table "userWorkspace" */
export enum UserWorkspace_Select_Column_UserWorkspace_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsAppointmentScheduleSynced = 'isAppointmentScheduleSynced',
  /** column name */
  IsArchived = 'isArchived',
  /** column name */
  IsGoogleCalendarSynced = 'isGoogleCalendarSynced',
  /** column name */
  IsListMobileView = 'isListMobileView',
  /** column name */
  IsManager = 'isManager',
  /** column name */
  LockTimezoneToDefaultLocation = 'lockTimezoneToDefaultLocation',
  /** column name */
  SendProviderAppointmentReminder = 'sendProviderAppointmentReminder'
}

/** select "userWorkspace_aggregate_bool_exp_bool_or_arguments_columns" columns of table "userWorkspace" */
export enum UserWorkspace_Select_Column_UserWorkspace_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsAppointmentScheduleSynced = 'isAppointmentScheduleSynced',
  /** column name */
  IsArchived = 'isArchived',
  /** column name */
  IsGoogleCalendarSynced = 'isGoogleCalendarSynced',
  /** column name */
  IsListMobileView = 'isListMobileView',
  /** column name */
  IsManager = 'isManager',
  /** column name */
  LockTimezoneToDefaultLocation = 'lockTimezoneToDefaultLocation',
  /** column name */
  SendProviderAppointmentReminder = 'sendProviderAppointmentReminder'
}

/** input type for updating data in table "userWorkspace" */
export type UserWorkspace_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  defaultLocationId?: InputMaybe<Scalars['uuid']>;
  externalCalendarSyncId?: InputMaybe<Scalars['String']>;
  googleCalendarId?: InputMaybe<Scalars['String']>;
  healthGorillaId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  isAppointmentScheduleSynced?: InputMaybe<Scalars['Boolean']>;
  isArchived?: InputMaybe<Scalars['Boolean']>;
  isGoogleCalendarSynced?: InputMaybe<Scalars['Boolean']>;
  isListMobileView?: InputMaybe<Scalars['Boolean']>;
  isManager?: InputMaybe<Scalars['Boolean']>;
  lockTimezoneToDefaultLocation?: InputMaybe<Scalars['Boolean']>;
  managerPin?: InputMaybe<Scalars['String']>;
  nylasGrant?: InputMaybe<Scalars['String']>;
  reportsLockedToLocationId?: InputMaybe<Scalars['uuid']>;
  role?: InputMaybe<Scalars['String']>;
  sendProviderAppointmentReminder?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  userId?: InputMaybe<Scalars['uuid']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "userWorkspace" */
export type UserWorkspace_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: UserWorkspace_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type UserWorkspace_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  defaultLocationId?: InputMaybe<Scalars['uuid']>;
  externalCalendarSyncId?: InputMaybe<Scalars['String']>;
  googleCalendarId?: InputMaybe<Scalars['String']>;
  healthGorillaId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  isAppointmentScheduleSynced?: InputMaybe<Scalars['Boolean']>;
  isArchived?: InputMaybe<Scalars['Boolean']>;
  isGoogleCalendarSynced?: InputMaybe<Scalars['Boolean']>;
  isListMobileView?: InputMaybe<Scalars['Boolean']>;
  isManager?: InputMaybe<Scalars['Boolean']>;
  lockTimezoneToDefaultLocation?: InputMaybe<Scalars['Boolean']>;
  managerPin?: InputMaybe<Scalars['String']>;
  nylasGrant?: InputMaybe<Scalars['String']>;
  reportsLockedToLocationId?: InputMaybe<Scalars['uuid']>;
  role?: InputMaybe<Scalars['String']>;
  sendProviderAppointmentReminder?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  userId?: InputMaybe<Scalars['uuid']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "userWorkspace" */
export enum UserWorkspace_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DefaultLocationId = 'defaultLocationId',
  /** column name */
  ExternalCalendarSyncId = 'externalCalendarSyncId',
  /** column name */
  GoogleCalendarId = 'googleCalendarId',
  /** column name */
  HealthGorillaId = 'healthGorillaId',
  /** column name */
  Id = 'id',
  /** column name */
  IsAppointmentScheduleSynced = 'isAppointmentScheduleSynced',
  /** column name */
  IsArchived = 'isArchived',
  /** column name */
  IsGoogleCalendarSynced = 'isGoogleCalendarSynced',
  /** column name */
  IsListMobileView = 'isListMobileView',
  /** column name */
  IsManager = 'isManager',
  /** column name */
  LockTimezoneToDefaultLocation = 'lockTimezoneToDefaultLocation',
  /** column name */
  ManagerPin = 'managerPin',
  /** column name */
  NylasGrant = 'nylasGrant',
  /** column name */
  ReportsLockedToLocationId = 'reportsLockedToLocationId',
  /** column name */
  Role = 'role',
  /** column name */
  SendProviderAppointmentReminder = 'sendProviderAppointmentReminder',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId',
  /** column name */
  WorkspaceId = 'workspaceId'
}

export type UserWorkspace_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<UserWorkspace_Set_Input>;
  /** filter the rows which have to be updated */
  where: UserWorkspace_Bool_Exp;
};

/** aggregated selection of "user" */
export type User_Aggregate = {
  __typename?: 'user_aggregate';
  aggregate?: Maybe<User_Aggregate_Fields>;
  nodes: Array<User>;
};

/** aggregate fields of "user" */
export type User_Aggregate_Fields = {
  __typename?: 'user_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<User_Max_Fields>;
  min?: Maybe<User_Min_Fields>;
};


/** aggregate fields of "user" */
export type User_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "user". All fields are combined with a logical 'AND'. */
export type User_Bool_Exp = {
  _and?: InputMaybe<Array<User_Bool_Exp>>;
  _not?: InputMaybe<User_Bool_Exp>;
  _or?: InputMaybe<Array<User_Bool_Exp>>;
  activityLogs?: InputMaybe<ActivityLog_Bool_Exp>;
  activityLogs_aggregate?: InputMaybe<ActivityLog_Aggregate_Bool_Exp>;
  authProfiles?: InputMaybe<AuthProfile_Bool_Exp>;
  authProfiles_aggregate?: InputMaybe<AuthProfile_Aggregate_Bool_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  firebaseUid?: InputMaybe<String_Comparison_Exp>;
  firstName?: InputMaybe<String_Comparison_Exp>;
  googleAccounts?: InputMaybe<GoogleAccount_Bool_Exp>;
  googleAccounts_aggregate?: InputMaybe<GoogleAccount_Aggregate_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  lastName?: InputMaybe<String_Comparison_Exp>;
  notes?: InputMaybe<Note_Bool_Exp>;
  notes_aggregate?: InputMaybe<Note_Aggregate_Bool_Exp>;
  phoneNumber?: InputMaybe<String_Comparison_Exp>;
  profilePicture?: InputMaybe<String_Comparison_Exp>;
  providers?: InputMaybe<Provider_Bool_Exp>;
  providers_aggregate?: InputMaybe<Provider_Aggregate_Bool_Exp>;
  role?: InputMaybe<String_Comparison_Exp>;
  socialMediaAccounts?: InputMaybe<SocialMediaAccount_Bool_Exp>;
  socialMediaAccounts_aggregate?: InputMaybe<SocialMediaAccount_Aggregate_Bool_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  twoFactorAuthEnabled?: InputMaybe<Boolean_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  userInvites?: InputMaybe<UserInvite_Bool_Exp>;
  userInvites_aggregate?: InputMaybe<UserInvite_Aggregate_Bool_Exp>;
  userWorkspace?: InputMaybe<UserWorkspace_Bool_Exp>;
  userWorkspaces?: InputMaybe<UserWorkspace_Bool_Exp>;
  userWorkspaces_aggregate?: InputMaybe<UserWorkspace_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "user" */
export enum User_Constraint {
  /** unique or primary key constraint on columns "email" */
  UserEmailKey = 'user_email_key',
  /** unique or primary key constraint on columns "firebaseUid" */
  UserFirebaseUidKey = 'user_firebaseUid_key',
  /** unique or primary key constraint on columns "id" */
  UserPkey = 'user_pkey'
}

/** input type for inserting data into table "user" */
export type User_Insert_Input = {
  activityLogs?: InputMaybe<ActivityLog_Arr_Rel_Insert_Input>;
  authProfiles?: InputMaybe<AuthProfile_Arr_Rel_Insert_Input>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  firebaseUid?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  googleAccounts?: InputMaybe<GoogleAccount_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']>;
  lastName?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Note_Arr_Rel_Insert_Input>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  profilePicture?: InputMaybe<Scalars['String']>;
  providers?: InputMaybe<Provider_Arr_Rel_Insert_Input>;
  role?: InputMaybe<Scalars['String']>;
  socialMediaAccounts?: InputMaybe<SocialMediaAccount_Arr_Rel_Insert_Input>;
  title?: InputMaybe<Scalars['String']>;
  twoFactorAuthEnabled?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  userInvites?: InputMaybe<UserInvite_Arr_Rel_Insert_Input>;
  userWorkspace?: InputMaybe<UserWorkspace_Obj_Rel_Insert_Input>;
  userWorkspaces?: InputMaybe<UserWorkspace_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type User_Max_Fields = {
  __typename?: 'user_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  firebaseUid?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  lastName?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  profilePicture?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type User_Min_Fields = {
  __typename?: 'user_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  firebaseUid?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  lastName?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  profilePicture?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "user" */
export type User_Mutation_Response = {
  __typename?: 'user_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User>;
};

/** input type for inserting object relation for remote table "user" */
export type User_Obj_Rel_Insert_Input = {
  data: User_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<User_On_Conflict>;
};

/** on_conflict condition type for table "user" */
export type User_On_Conflict = {
  constraint: User_Constraint;
  update_columns?: Array<User_Update_Column>;
  where?: InputMaybe<User_Bool_Exp>;
};

/** Ordering options when selecting data from "user". */
export type User_Order_By = {
  activityLogs_aggregate?: InputMaybe<ActivityLog_Aggregate_Order_By>;
  authProfiles_aggregate?: InputMaybe<AuthProfile_Aggregate_Order_By>;
  createdAt?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  firebaseUid?: InputMaybe<Order_By>;
  firstName?: InputMaybe<Order_By>;
  googleAccounts_aggregate?: InputMaybe<GoogleAccount_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  lastName?: InputMaybe<Order_By>;
  notes_aggregate?: InputMaybe<Note_Aggregate_Order_By>;
  phoneNumber?: InputMaybe<Order_By>;
  profilePicture?: InputMaybe<Order_By>;
  providers_aggregate?: InputMaybe<Provider_Aggregate_Order_By>;
  role?: InputMaybe<Order_By>;
  socialMediaAccounts_aggregate?: InputMaybe<SocialMediaAccount_Aggregate_Order_By>;
  title?: InputMaybe<Order_By>;
  twoFactorAuthEnabled?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  userInvites_aggregate?: InputMaybe<UserInvite_Aggregate_Order_By>;
  userWorkspace?: InputMaybe<UserWorkspace_Order_By>;
  userWorkspaces_aggregate?: InputMaybe<UserWorkspace_Aggregate_Order_By>;
};

/** primary key columns input for table: user */
export type User_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "user" */
export enum User_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Email = 'email',
  /** column name */
  FirebaseUid = 'firebaseUid',
  /** column name */
  FirstName = 'firstName',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'lastName',
  /** column name */
  PhoneNumber = 'phoneNumber',
  /** column name */
  ProfilePicture = 'profilePicture',
  /** column name */
  Role = 'role',
  /** column name */
  Title = 'title',
  /** column name */
  TwoFactorAuthEnabled = 'twoFactorAuthEnabled',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "user" */
export type User_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  firebaseUid?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  lastName?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  profilePicture?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  twoFactorAuthEnabled?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "user" */
export type User_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  firebaseUid?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  lastName?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  profilePicture?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  twoFactorAuthEnabled?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "user" */
export enum User_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Email = 'email',
  /** column name */
  FirebaseUid = 'firebaseUid',
  /** column name */
  FirstName = 'firstName',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'lastName',
  /** column name */
  PhoneNumber = 'phoneNumber',
  /** column name */
  ProfilePicture = 'profilePicture',
  /** column name */
  Role = 'role',
  /** column name */
  Title = 'title',
  /** column name */
  TwoFactorAuthEnabled = 'twoFactorAuthEnabled',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type User_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Bool_Exp;
};

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['uuid']>;
  _gt?: InputMaybe<Scalars['uuid']>;
  _gte?: InputMaybe<Scalars['uuid']>;
  _in?: InputMaybe<Array<Scalars['uuid']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['uuid']>;
  _lte?: InputMaybe<Scalars['uuid']>;
  _neq?: InputMaybe<Scalars['uuid']>;
  _nin?: InputMaybe<Array<Scalars['uuid']>>;
};

/** columns and relationships of "vitals" */
export type Vitals = {
  __typename?: 'vitals';
  bloodPressure?: Maybe<Scalars['jsonb']>;
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An object relationship */
  patientWorkspace: PatientWorkspace;
  patientWorkspaceId: Scalars['uuid'];
  pulse: Scalars['Int'];
  spo2?: Maybe<Scalars['numeric']>;
  temperature: Scalars['float8'];
  time?: Maybe<Scalars['timestamptz']>;
  updatedAt: Scalars['timestamptz'];
};


/** columns and relationships of "vitals" */
export type VitalsBloodPressureArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "vitals" */
export type Vitals_Aggregate = {
  __typename?: 'vitals_aggregate';
  aggregate?: Maybe<Vitals_Aggregate_Fields>;
  nodes: Array<Vitals>;
};

export type Vitals_Aggregate_Bool_Exp = {
  avg?: InputMaybe<Vitals_Aggregate_Bool_Exp_Avg>;
  corr?: InputMaybe<Vitals_Aggregate_Bool_Exp_Corr>;
  count?: InputMaybe<Vitals_Aggregate_Bool_Exp_Count>;
  covar_samp?: InputMaybe<Vitals_Aggregate_Bool_Exp_Covar_Samp>;
  max?: InputMaybe<Vitals_Aggregate_Bool_Exp_Max>;
  min?: InputMaybe<Vitals_Aggregate_Bool_Exp_Min>;
  stddev_samp?: InputMaybe<Vitals_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: InputMaybe<Vitals_Aggregate_Bool_Exp_Sum>;
  var_samp?: InputMaybe<Vitals_Aggregate_Bool_Exp_Var_Samp>;
};

export type Vitals_Aggregate_Bool_Exp_Avg = {
  arguments: Vitals_Select_Column_Vitals_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Vitals_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Vitals_Aggregate_Bool_Exp_Corr = {
  arguments: Vitals_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Vitals_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Vitals_Aggregate_Bool_Exp_Corr_Arguments = {
  X: Vitals_Select_Column_Vitals_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: Vitals_Select_Column_Vitals_Aggregate_Bool_Exp_Corr_Arguments_Columns;
};

export type Vitals_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Vitals_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Vitals_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type Vitals_Aggregate_Bool_Exp_Covar_Samp = {
  arguments: Vitals_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Vitals_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Vitals_Aggregate_Bool_Exp_Covar_Samp_Arguments = {
  X: Vitals_Select_Column_Vitals_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  Y: Vitals_Select_Column_Vitals_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
};

export type Vitals_Aggregate_Bool_Exp_Max = {
  arguments: Vitals_Select_Column_Vitals_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Vitals_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Vitals_Aggregate_Bool_Exp_Min = {
  arguments: Vitals_Select_Column_Vitals_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Vitals_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Vitals_Aggregate_Bool_Exp_Stddev_Samp = {
  arguments: Vitals_Select_Column_Vitals_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Vitals_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Vitals_Aggregate_Bool_Exp_Sum = {
  arguments: Vitals_Select_Column_Vitals_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Vitals_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Vitals_Aggregate_Bool_Exp_Var_Samp = {
  arguments: Vitals_Select_Column_Vitals_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Vitals_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

/** aggregate fields of "vitals" */
export type Vitals_Aggregate_Fields = {
  __typename?: 'vitals_aggregate_fields';
  avg?: Maybe<Vitals_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Vitals_Max_Fields>;
  min?: Maybe<Vitals_Min_Fields>;
  stddev?: Maybe<Vitals_Stddev_Fields>;
  stddev_pop?: Maybe<Vitals_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Vitals_Stddev_Samp_Fields>;
  sum?: Maybe<Vitals_Sum_Fields>;
  var_pop?: Maybe<Vitals_Var_Pop_Fields>;
  var_samp?: Maybe<Vitals_Var_Samp_Fields>;
  variance?: Maybe<Vitals_Variance_Fields>;
};


/** aggregate fields of "vitals" */
export type Vitals_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Vitals_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "vitals" */
export type Vitals_Aggregate_Order_By = {
  avg?: InputMaybe<Vitals_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Vitals_Max_Order_By>;
  min?: InputMaybe<Vitals_Min_Order_By>;
  stddev?: InputMaybe<Vitals_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Vitals_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Vitals_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Vitals_Sum_Order_By>;
  var_pop?: InputMaybe<Vitals_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Vitals_Var_Samp_Order_By>;
  variance?: InputMaybe<Vitals_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Vitals_Append_Input = {
  bloodPressure?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "vitals" */
export type Vitals_Arr_Rel_Insert_Input = {
  data: Array<Vitals_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Vitals_On_Conflict>;
};

/** aggregate avg on columns */
export type Vitals_Avg_Fields = {
  __typename?: 'vitals_avg_fields';
  pulse?: Maybe<Scalars['Float']>;
  spo2?: Maybe<Scalars['Float']>;
  temperature?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "vitals" */
export type Vitals_Avg_Order_By = {
  pulse?: InputMaybe<Order_By>;
  spo2?: InputMaybe<Order_By>;
  temperature?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "vitals". All fields are combined with a logical 'AND'. */
export type Vitals_Bool_Exp = {
  _and?: InputMaybe<Array<Vitals_Bool_Exp>>;
  _not?: InputMaybe<Vitals_Bool_Exp>;
  _or?: InputMaybe<Array<Vitals_Bool_Exp>>;
  bloodPressure?: InputMaybe<Jsonb_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  patientWorkspace?: InputMaybe<PatientWorkspace_Bool_Exp>;
  patientWorkspaceId?: InputMaybe<Uuid_Comparison_Exp>;
  pulse?: InputMaybe<Int_Comparison_Exp>;
  spo2?: InputMaybe<Numeric_Comparison_Exp>;
  temperature?: InputMaybe<Float8_Comparison_Exp>;
  time?: InputMaybe<Timestamptz_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "vitals" */
export enum Vitals_Constraint {
  /** unique or primary key constraint on columns "id" */
  VitalsPkey = 'vitals_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Vitals_Delete_At_Path_Input = {
  bloodPressure?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Vitals_Delete_Elem_Input = {
  bloodPressure?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Vitals_Delete_Key_Input = {
  bloodPressure?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "vitals" */
export type Vitals_Inc_Input = {
  pulse?: InputMaybe<Scalars['Int']>;
  spo2?: InputMaybe<Scalars['numeric']>;
  temperature?: InputMaybe<Scalars['float8']>;
};

/** input type for inserting data into table "vitals" */
export type Vitals_Insert_Input = {
  bloodPressure?: InputMaybe<Scalars['jsonb']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  patientWorkspace?: InputMaybe<PatientWorkspace_Obj_Rel_Insert_Input>;
  patientWorkspaceId?: InputMaybe<Scalars['uuid']>;
  pulse?: InputMaybe<Scalars['Int']>;
  spo2?: InputMaybe<Scalars['numeric']>;
  temperature?: InputMaybe<Scalars['float8']>;
  time?: InputMaybe<Scalars['timestamptz']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Vitals_Max_Fields = {
  __typename?: 'vitals_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  patientWorkspaceId?: Maybe<Scalars['uuid']>;
  pulse?: Maybe<Scalars['Int']>;
  spo2?: Maybe<Scalars['numeric']>;
  temperature?: Maybe<Scalars['float8']>;
  time?: Maybe<Scalars['timestamptz']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "vitals" */
export type Vitals_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patientWorkspaceId?: InputMaybe<Order_By>;
  pulse?: InputMaybe<Order_By>;
  spo2?: InputMaybe<Order_By>;
  temperature?: InputMaybe<Order_By>;
  time?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Vitals_Min_Fields = {
  __typename?: 'vitals_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  patientWorkspaceId?: Maybe<Scalars['uuid']>;
  pulse?: Maybe<Scalars['Int']>;
  spo2?: Maybe<Scalars['numeric']>;
  temperature?: Maybe<Scalars['float8']>;
  time?: Maybe<Scalars['timestamptz']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "vitals" */
export type Vitals_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patientWorkspaceId?: InputMaybe<Order_By>;
  pulse?: InputMaybe<Order_By>;
  spo2?: InputMaybe<Order_By>;
  temperature?: InputMaybe<Order_By>;
  time?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "vitals" */
export type Vitals_Mutation_Response = {
  __typename?: 'vitals_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Vitals>;
};

/** on_conflict condition type for table "vitals" */
export type Vitals_On_Conflict = {
  constraint: Vitals_Constraint;
  update_columns?: Array<Vitals_Update_Column>;
  where?: InputMaybe<Vitals_Bool_Exp>;
};

/** Ordering options when selecting data from "vitals". */
export type Vitals_Order_By = {
  bloodPressure?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patientWorkspace?: InputMaybe<PatientWorkspace_Order_By>;
  patientWorkspaceId?: InputMaybe<Order_By>;
  pulse?: InputMaybe<Order_By>;
  spo2?: InputMaybe<Order_By>;
  temperature?: InputMaybe<Order_By>;
  time?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: vitals */
export type Vitals_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Vitals_Prepend_Input = {
  bloodPressure?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "vitals" */
export enum Vitals_Select_Column {
  /** column name */
  BloodPressure = 'bloodPressure',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  PatientWorkspaceId = 'patientWorkspaceId',
  /** column name */
  Pulse = 'pulse',
  /** column name */
  Spo2 = 'spo2',
  /** column name */
  Temperature = 'temperature',
  /** column name */
  Time = 'time',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** select "vitals_aggregate_bool_exp_avg_arguments_columns" columns of table "vitals" */
export enum Vitals_Select_Column_Vitals_Aggregate_Bool_Exp_Avg_Arguments_Columns {
  /** column name */
  Temperature = 'temperature'
}

/** select "vitals_aggregate_bool_exp_corr_arguments_columns" columns of table "vitals" */
export enum Vitals_Select_Column_Vitals_Aggregate_Bool_Exp_Corr_Arguments_Columns {
  /** column name */
  Temperature = 'temperature'
}

/** select "vitals_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "vitals" */
export enum Vitals_Select_Column_Vitals_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns {
  /** column name */
  Temperature = 'temperature'
}

/** select "vitals_aggregate_bool_exp_max_arguments_columns" columns of table "vitals" */
export enum Vitals_Select_Column_Vitals_Aggregate_Bool_Exp_Max_Arguments_Columns {
  /** column name */
  Temperature = 'temperature'
}

/** select "vitals_aggregate_bool_exp_min_arguments_columns" columns of table "vitals" */
export enum Vitals_Select_Column_Vitals_Aggregate_Bool_Exp_Min_Arguments_Columns {
  /** column name */
  Temperature = 'temperature'
}

/** select "vitals_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "vitals" */
export enum Vitals_Select_Column_Vitals_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns {
  /** column name */
  Temperature = 'temperature'
}

/** select "vitals_aggregate_bool_exp_sum_arguments_columns" columns of table "vitals" */
export enum Vitals_Select_Column_Vitals_Aggregate_Bool_Exp_Sum_Arguments_Columns {
  /** column name */
  Temperature = 'temperature'
}

/** select "vitals_aggregate_bool_exp_var_samp_arguments_columns" columns of table "vitals" */
export enum Vitals_Select_Column_Vitals_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns {
  /** column name */
  Temperature = 'temperature'
}

/** input type for updating data in table "vitals" */
export type Vitals_Set_Input = {
  bloodPressure?: InputMaybe<Scalars['jsonb']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  patientWorkspaceId?: InputMaybe<Scalars['uuid']>;
  pulse?: InputMaybe<Scalars['Int']>;
  spo2?: InputMaybe<Scalars['numeric']>;
  temperature?: InputMaybe<Scalars['float8']>;
  time?: InputMaybe<Scalars['timestamptz']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Vitals_Stddev_Fields = {
  __typename?: 'vitals_stddev_fields';
  pulse?: Maybe<Scalars['Float']>;
  spo2?: Maybe<Scalars['Float']>;
  temperature?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "vitals" */
export type Vitals_Stddev_Order_By = {
  pulse?: InputMaybe<Order_By>;
  spo2?: InputMaybe<Order_By>;
  temperature?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Vitals_Stddev_Pop_Fields = {
  __typename?: 'vitals_stddev_pop_fields';
  pulse?: Maybe<Scalars['Float']>;
  spo2?: Maybe<Scalars['Float']>;
  temperature?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "vitals" */
export type Vitals_Stddev_Pop_Order_By = {
  pulse?: InputMaybe<Order_By>;
  spo2?: InputMaybe<Order_By>;
  temperature?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Vitals_Stddev_Samp_Fields = {
  __typename?: 'vitals_stddev_samp_fields';
  pulse?: Maybe<Scalars['Float']>;
  spo2?: Maybe<Scalars['Float']>;
  temperature?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "vitals" */
export type Vitals_Stddev_Samp_Order_By = {
  pulse?: InputMaybe<Order_By>;
  spo2?: InputMaybe<Order_By>;
  temperature?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "vitals" */
export type Vitals_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Vitals_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Vitals_Stream_Cursor_Value_Input = {
  bloodPressure?: InputMaybe<Scalars['jsonb']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  patientWorkspaceId?: InputMaybe<Scalars['uuid']>;
  pulse?: InputMaybe<Scalars['Int']>;
  spo2?: InputMaybe<Scalars['numeric']>;
  temperature?: InputMaybe<Scalars['float8']>;
  time?: InputMaybe<Scalars['timestamptz']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Vitals_Sum_Fields = {
  __typename?: 'vitals_sum_fields';
  pulse?: Maybe<Scalars['Int']>;
  spo2?: Maybe<Scalars['numeric']>;
  temperature?: Maybe<Scalars['float8']>;
};

/** order by sum() on columns of table "vitals" */
export type Vitals_Sum_Order_By = {
  pulse?: InputMaybe<Order_By>;
  spo2?: InputMaybe<Order_By>;
  temperature?: InputMaybe<Order_By>;
};

/** update columns of table "vitals" */
export enum Vitals_Update_Column {
  /** column name */
  BloodPressure = 'bloodPressure',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  PatientWorkspaceId = 'patientWorkspaceId',
  /** column name */
  Pulse = 'pulse',
  /** column name */
  Spo2 = 'spo2',
  /** column name */
  Temperature = 'temperature',
  /** column name */
  Time = 'time',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Vitals_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Vitals_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Vitals_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Vitals_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Vitals_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Vitals_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Vitals_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Vitals_Set_Input>;
  /** filter the rows which have to be updated */
  where: Vitals_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Vitals_Var_Pop_Fields = {
  __typename?: 'vitals_var_pop_fields';
  pulse?: Maybe<Scalars['Float']>;
  spo2?: Maybe<Scalars['Float']>;
  temperature?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "vitals" */
export type Vitals_Var_Pop_Order_By = {
  pulse?: InputMaybe<Order_By>;
  spo2?: InputMaybe<Order_By>;
  temperature?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Vitals_Var_Samp_Fields = {
  __typename?: 'vitals_var_samp_fields';
  pulse?: Maybe<Scalars['Float']>;
  spo2?: Maybe<Scalars['Float']>;
  temperature?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "vitals" */
export type Vitals_Var_Samp_Order_By = {
  pulse?: InputMaybe<Order_By>;
  spo2?: InputMaybe<Order_By>;
  temperature?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Vitals_Variance_Fields = {
  __typename?: 'vitals_variance_fields';
  pulse?: Maybe<Scalars['Float']>;
  spo2?: Maybe<Scalars['Float']>;
  temperature?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "vitals" */
export type Vitals_Variance_Order_By = {
  pulse?: InputMaybe<Order_By>;
  spo2?: InputMaybe<Order_By>;
  temperature?: InputMaybe<Order_By>;
};

/** columns and relationships of "workspace" */
export type Workspace = {
  __typename?: 'workspace';
  /** An array relationship */
  activityLogs: Array<ActivityLog>;
  /** An aggregate relationship */
  activityLogs_aggregate: ActivityLog_Aggregate;
  /** An array relationship */
  authProfiles: Array<AuthProfile>;
  /** An aggregate relationship */
  authProfiles_aggregate: AuthProfile_Aggregate;
  createdAt: Scalars['timestamptz'];
  /** An array relationship */
  deviceTypes: Array<DeviceType>;
  /** An aggregate relationship */
  deviceTypes_aggregate: DeviceType_Aggregate;
  domain?: Maybe<Scalars['String']>;
  eRxEnabled?: Maybe<Scalars['Boolean']>;
  /** An array relationship */
  externalLocations: Array<ExternalLocation>;
  /** An aggregate relationship */
  externalLocations_aggregate: ExternalLocation_Aggregate;
  /** An array relationship */
  externalProviders: Array<ExternalProvider>;
  /** An aggregate relationship */
  externalProviders_aggregate: ExternalProvider_Aggregate;
  /** An array relationship */
  externalReviews: Array<ExternalReview>;
  /** An aggregate relationship */
  externalReviews_aggregate: ExternalReview_Aggregate;
  finixMerchantId?: Maybe<Scalars['String']>;
  flags?: Maybe<Scalars['jsonb']>;
  /** An array relationship */
  googleAccounts: Array<GoogleAccount>;
  /** An aggregate relationship */
  googleAccounts_aggregate: GoogleAccount_Aggregate;
  /** An array relationship */
  googleLocations: Array<GoogleLocation>;
  /** An aggregate relationship */
  googleLocations_aggregate: GoogleLocation_Aggregate;
  id: Scalars['uuid'];
  /** An array relationship */
  leadFormEntries: Array<LeadFormEntry>;
  /** An aggregate relationship */
  leadFormEntries_aggregate: LeadFormEntry_Aggregate;
  /** An array relationship */
  leadFormFields: Array<LeadFormField>;
  /** An aggregate relationship */
  leadFormFields_aggregate: LeadFormField_Aggregate;
  /** An array relationship */
  leadForms: Array<LeadForm>;
  /** An aggregate relationship */
  leadForms_aggregate: LeadForm_Aggregate;
  /** An array relationship */
  locations: Array<Location>;
  /** An aggregate relationship */
  locations_aggregate: Location_Aggregate;
  logo?: Maybe<Scalars['String']>;
  /** An array relationship */
  mediaTags: Array<MediaTag>;
  /** An aggregate relationship */
  mediaTags_aggregate: MediaTag_Aggregate;
  name: Scalars['String'];
  /** An array relationship */
  notes: Array<Note>;
  /** An aggregate relationship */
  notes_aggregate: Note_Aggregate;
  /** An object relationship */
  organization?: Maybe<Organization>;
  organizationId?: Maybe<Scalars['uuid']>;
  /** An array relationship */
  patientWorkspaces: Array<PatientWorkspace>;
  /** An aggregate relationship */
  patientWorkspaces_aggregate: PatientWorkspace_Aggregate;
  payrixMerchantId?: Maybe<Scalars['String']>;
  /** An array relationship */
  providers: Array<Provider>;
  /** An aggregate relationship */
  providers_aggregate: Provider_Aggregate;
  /** An object relationship */
  rewardProgram?: Maybe<RewardProgram>;
  /** An array relationship */
  services: Array<Service>;
  /** An aggregate relationship */
  services_aggregate: Service_Aggregate;
  slug?: Maybe<Scalars['String']>;
  /** An array relationship */
  socialMediaAccounts: Array<SocialMediaAccount>;
  /** An aggregate relationship */
  socialMediaAccounts_aggregate: SocialMediaAccount_Aggregate;
  storefrontTrackingId?: Maybe<Scalars['String']>;
  twilioPhoneNumber?: Maybe<Scalars['String']>;
  updatedAt: Scalars['timestamptz'];
  /** An array relationship */
  userInvites: Array<UserInvite>;
  /** An aggregate relationship */
  userInvites_aggregate: UserInvite_Aggregate;
  /** An object relationship */
  userWorkspace?: Maybe<UserWorkspace>;
  /** An object relationship */
  workspaceConfiguration?: Maybe<WorkspaceConfiguration>;
};


/** columns and relationships of "workspace" */
export type WorkspaceActivityLogsArgs = {
  distinct_on?: InputMaybe<Array<ActivityLog_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ActivityLog_Order_By>>;
  where?: InputMaybe<ActivityLog_Bool_Exp>;
};


/** columns and relationships of "workspace" */
export type WorkspaceActivityLogs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ActivityLog_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ActivityLog_Order_By>>;
  where?: InputMaybe<ActivityLog_Bool_Exp>;
};


/** columns and relationships of "workspace" */
export type WorkspaceAuthProfilesArgs = {
  distinct_on?: InputMaybe<Array<AuthProfile_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AuthProfile_Order_By>>;
  where?: InputMaybe<AuthProfile_Bool_Exp>;
};


/** columns and relationships of "workspace" */
export type WorkspaceAuthProfiles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<AuthProfile_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AuthProfile_Order_By>>;
  where?: InputMaybe<AuthProfile_Bool_Exp>;
};


/** columns and relationships of "workspace" */
export type WorkspaceDeviceTypesArgs = {
  distinct_on?: InputMaybe<Array<DeviceType_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<DeviceType_Order_By>>;
  where?: InputMaybe<DeviceType_Bool_Exp>;
};


/** columns and relationships of "workspace" */
export type WorkspaceDeviceTypes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<DeviceType_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<DeviceType_Order_By>>;
  where?: InputMaybe<DeviceType_Bool_Exp>;
};


/** columns and relationships of "workspace" */
export type WorkspaceExternalLocationsArgs = {
  distinct_on?: InputMaybe<Array<ExternalLocation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ExternalLocation_Order_By>>;
  where?: InputMaybe<ExternalLocation_Bool_Exp>;
};


/** columns and relationships of "workspace" */
export type WorkspaceExternalLocations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ExternalLocation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ExternalLocation_Order_By>>;
  where?: InputMaybe<ExternalLocation_Bool_Exp>;
};


/** columns and relationships of "workspace" */
export type WorkspaceExternalProvidersArgs = {
  distinct_on?: InputMaybe<Array<ExternalProvider_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ExternalProvider_Order_By>>;
  where?: InputMaybe<ExternalProvider_Bool_Exp>;
};


/** columns and relationships of "workspace" */
export type WorkspaceExternalProviders_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ExternalProvider_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ExternalProvider_Order_By>>;
  where?: InputMaybe<ExternalProvider_Bool_Exp>;
};


/** columns and relationships of "workspace" */
export type WorkspaceExternalReviewsArgs = {
  distinct_on?: InputMaybe<Array<ExternalReview_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ExternalReview_Order_By>>;
  where?: InputMaybe<ExternalReview_Bool_Exp>;
};


/** columns and relationships of "workspace" */
export type WorkspaceExternalReviews_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ExternalReview_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ExternalReview_Order_By>>;
  where?: InputMaybe<ExternalReview_Bool_Exp>;
};


/** columns and relationships of "workspace" */
export type WorkspaceFlagsArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "workspace" */
export type WorkspaceGoogleAccountsArgs = {
  distinct_on?: InputMaybe<Array<GoogleAccount_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GoogleAccount_Order_By>>;
  where?: InputMaybe<GoogleAccount_Bool_Exp>;
};


/** columns and relationships of "workspace" */
export type WorkspaceGoogleAccounts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<GoogleAccount_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GoogleAccount_Order_By>>;
  where?: InputMaybe<GoogleAccount_Bool_Exp>;
};


/** columns and relationships of "workspace" */
export type WorkspaceGoogleLocationsArgs = {
  distinct_on?: InputMaybe<Array<GoogleLocation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GoogleLocation_Order_By>>;
  where?: InputMaybe<GoogleLocation_Bool_Exp>;
};


/** columns and relationships of "workspace" */
export type WorkspaceGoogleLocations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<GoogleLocation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GoogleLocation_Order_By>>;
  where?: InputMaybe<GoogleLocation_Bool_Exp>;
};


/** columns and relationships of "workspace" */
export type WorkspaceLeadFormEntriesArgs = {
  distinct_on?: InputMaybe<Array<LeadFormEntry_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<LeadFormEntry_Order_By>>;
  where?: InputMaybe<LeadFormEntry_Bool_Exp>;
};


/** columns and relationships of "workspace" */
export type WorkspaceLeadFormEntries_AggregateArgs = {
  distinct_on?: InputMaybe<Array<LeadFormEntry_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<LeadFormEntry_Order_By>>;
  where?: InputMaybe<LeadFormEntry_Bool_Exp>;
};


/** columns and relationships of "workspace" */
export type WorkspaceLeadFormFieldsArgs = {
  distinct_on?: InputMaybe<Array<LeadFormField_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<LeadFormField_Order_By>>;
  where?: InputMaybe<LeadFormField_Bool_Exp>;
};


/** columns and relationships of "workspace" */
export type WorkspaceLeadFormFields_AggregateArgs = {
  distinct_on?: InputMaybe<Array<LeadFormField_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<LeadFormField_Order_By>>;
  where?: InputMaybe<LeadFormField_Bool_Exp>;
};


/** columns and relationships of "workspace" */
export type WorkspaceLeadFormsArgs = {
  distinct_on?: InputMaybe<Array<LeadForm_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<LeadForm_Order_By>>;
  where?: InputMaybe<LeadForm_Bool_Exp>;
};


/** columns and relationships of "workspace" */
export type WorkspaceLeadForms_AggregateArgs = {
  distinct_on?: InputMaybe<Array<LeadForm_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<LeadForm_Order_By>>;
  where?: InputMaybe<LeadForm_Bool_Exp>;
};


/** columns and relationships of "workspace" */
export type WorkspaceLocationsArgs = {
  distinct_on?: InputMaybe<Array<Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Location_Order_By>>;
  where?: InputMaybe<Location_Bool_Exp>;
};


/** columns and relationships of "workspace" */
export type WorkspaceLocations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Location_Order_By>>;
  where?: InputMaybe<Location_Bool_Exp>;
};


/** columns and relationships of "workspace" */
export type WorkspaceMediaTagsArgs = {
  distinct_on?: InputMaybe<Array<MediaTag_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MediaTag_Order_By>>;
  where?: InputMaybe<MediaTag_Bool_Exp>;
};


/** columns and relationships of "workspace" */
export type WorkspaceMediaTags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<MediaTag_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MediaTag_Order_By>>;
  where?: InputMaybe<MediaTag_Bool_Exp>;
};


/** columns and relationships of "workspace" */
export type WorkspaceNotesArgs = {
  distinct_on?: InputMaybe<Array<Note_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Note_Order_By>>;
  where?: InputMaybe<Note_Bool_Exp>;
};


/** columns and relationships of "workspace" */
export type WorkspaceNotes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Note_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Note_Order_By>>;
  where?: InputMaybe<Note_Bool_Exp>;
};


/** columns and relationships of "workspace" */
export type WorkspacePatientWorkspacesArgs = {
  distinct_on?: InputMaybe<Array<PatientWorkspace_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PatientWorkspace_Order_By>>;
  where?: InputMaybe<PatientWorkspace_Bool_Exp>;
};


/** columns and relationships of "workspace" */
export type WorkspacePatientWorkspaces_AggregateArgs = {
  distinct_on?: InputMaybe<Array<PatientWorkspace_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PatientWorkspace_Order_By>>;
  where?: InputMaybe<PatientWorkspace_Bool_Exp>;
};


/** columns and relationships of "workspace" */
export type WorkspaceProvidersArgs = {
  distinct_on?: InputMaybe<Array<Provider_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Provider_Order_By>>;
  where?: InputMaybe<Provider_Bool_Exp>;
};


/** columns and relationships of "workspace" */
export type WorkspaceProviders_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Provider_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Provider_Order_By>>;
  where?: InputMaybe<Provider_Bool_Exp>;
};


/** columns and relationships of "workspace" */
export type WorkspaceServicesArgs = {
  distinct_on?: InputMaybe<Array<Service_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Service_Order_By>>;
  where?: InputMaybe<Service_Bool_Exp>;
};


/** columns and relationships of "workspace" */
export type WorkspaceServices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Service_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Service_Order_By>>;
  where?: InputMaybe<Service_Bool_Exp>;
};


/** columns and relationships of "workspace" */
export type WorkspaceSocialMediaAccountsArgs = {
  distinct_on?: InputMaybe<Array<SocialMediaAccount_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SocialMediaAccount_Order_By>>;
  where?: InputMaybe<SocialMediaAccount_Bool_Exp>;
};


/** columns and relationships of "workspace" */
export type WorkspaceSocialMediaAccounts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<SocialMediaAccount_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SocialMediaAccount_Order_By>>;
  where?: InputMaybe<SocialMediaAccount_Bool_Exp>;
};


/** columns and relationships of "workspace" */
export type WorkspaceUserInvitesArgs = {
  distinct_on?: InputMaybe<Array<UserInvite_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserInvite_Order_By>>;
  where?: InputMaybe<UserInvite_Bool_Exp>;
};


/** columns and relationships of "workspace" */
export type WorkspaceUserInvites_AggregateArgs = {
  distinct_on?: InputMaybe<Array<UserInvite_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserInvite_Order_By>>;
  where?: InputMaybe<UserInvite_Bool_Exp>;
};

/** columns and relationships of "workspaceConfiguration" */
export type WorkspaceConfiguration = {
  __typename?: 'workspaceConfiguration';
  afterHoursAutoResponderMessage?: Maybe<Scalars['String']>;
  afterHoursCutoffTime?: Maybe<Scalars['String']>;
  appointmentConfirmationMessage?: Maybe<Scalars['String']>;
  appointmentReminders?: Maybe<Scalars['jsonb']>;
  autoCategorizeConversations?: Maybe<Scalars['Boolean']>;
  calendar?: Maybe<Scalars['jsonb']>;
  cancellationSMSMessage?: Maybe<Scalars['String']>;
  ccapiNetworkAddress?: Maybe<Scalars['String']>;
  clinicalNotesExpandedDefault?: Maybe<Scalars['Boolean']>;
  cloudflareCustomHostId?: Maybe<Scalars['String']>;
  completeAppointmentCopy: Scalars['jsonb'];
  createdAt: Scalars['timestamptz'];
  customPermissions?: Maybe<Scalars['jsonb']>;
  defaultToPatientWallet: Scalars['Boolean'];
  enableAfterHoursAutoResponder?: Maybe<Scalars['Boolean']>;
  enableCompleteActionsLinksInAppointmentReminders: Scalars['Boolean'];
  enableLabsDocumentDownload: Scalars['Boolean'];
  giftCardOptions?: Maybe<Scalars['jsonb']>;
  healthGorillaFacilityAdminUser?: Maybe<Scalars['String']>;
  healthGorillaFacilityId?: Maybe<Scalars['String']>;
  healthGorillaFacilitySecret?: Maybe<Scalars['String']>;
  healthGorillaSubscriptions?: Maybe<Scalars['jsonb']>;
  hideCancelledAppointments?: Maybe<Scalars['Boolean']>;
  id: Scalars['uuid'];
  invoiceMessage?: Maybe<Scalars['String']>;
  ipWhitelist?: Maybe<Scalars['jsonb']>;
  ipWhitelistEnabled?: Maybe<Scalars['Boolean']>;
  leadStatusOptions?: Maybe<Scalars['jsonb']>;
  logo?: Maybe<Scalars['String']>;
  noShowActive?: Maybe<Scalars['Boolean']>;
  noShowFee?: Maybe<Scalars['Int']>;
  noShowSMSMessage?: Maybe<Scalars['String']>;
  opportunities?: Maybe<Scalars['jsonb']>;
  patientPortalBookAppointmentBufferAmount: Scalars['Int'];
  patientPortalBookAppointmentBufferUnit: Scalars['String'];
  patientPortalBookableServices: Scalars['jsonb'];
  patientPortalCanBookAppointments: Scalars['Boolean'];
  patientPortalCanCancelAppointments: Scalars['Boolean'];
  patientPortalCancelAppointmentBufferAmount: Scalars['Int'];
  patientPortalCancelAppointmentBufferUnit: Scalars['String'];
  patientPortalInviteCopy: Scalars['jsonb'];
  patientReferralOptions?: Maybe<Scalars['jsonb']>;
  paymentMethods: Scalars['jsonb'];
  providersOnlySeeOwnSchedule?: Maybe<Scalars['Boolean']>;
  remindConfirmedPatients?: Maybe<Scalars['Boolean']>;
  schedulingIntervalInMinutes: Scalars['Int'];
  sendAppointmentConfirmationMessage?: Maybe<Scalars['Boolean']>;
  sendCancellationConfirmationSMS?: Maybe<Scalars['Boolean']>;
  sendClinicalMessages?: Maybe<Scalars['Boolean']>;
  sendEmailReceipts?: Maybe<Scalars['Boolean']>;
  sendNoShowSMS: Scalars['Boolean'];
  smsMarketingCharacterLimit?: Maybe<Scalars['Int']>;
  storefrontConfiguration?: Maybe<Scalars['jsonb']>;
  subscriptionManager: Scalars['String'];
  theme?: Maybe<Scalars['jsonb']>;
  timezone?: Maybe<Scalars['String']>;
  updatedAt: Scalars['timestamptz'];
  /** An object relationship */
  workspace: Workspace;
  workspaceId: Scalars['uuid'];
};


/** columns and relationships of "workspaceConfiguration" */
export type WorkspaceConfigurationAppointmentRemindersArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "workspaceConfiguration" */
export type WorkspaceConfigurationCalendarArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "workspaceConfiguration" */
export type WorkspaceConfigurationCompleteAppointmentCopyArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "workspaceConfiguration" */
export type WorkspaceConfigurationCustomPermissionsArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "workspaceConfiguration" */
export type WorkspaceConfigurationGiftCardOptionsArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "workspaceConfiguration" */
export type WorkspaceConfigurationHealthGorillaSubscriptionsArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "workspaceConfiguration" */
export type WorkspaceConfigurationIpWhitelistArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "workspaceConfiguration" */
export type WorkspaceConfigurationLeadStatusOptionsArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "workspaceConfiguration" */
export type WorkspaceConfigurationOpportunitiesArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "workspaceConfiguration" */
export type WorkspaceConfigurationPatientPortalBookableServicesArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "workspaceConfiguration" */
export type WorkspaceConfigurationPatientPortalInviteCopyArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "workspaceConfiguration" */
export type WorkspaceConfigurationPatientReferralOptionsArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "workspaceConfiguration" */
export type WorkspaceConfigurationPaymentMethodsArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "workspaceConfiguration" */
export type WorkspaceConfigurationStorefrontConfigurationArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "workspaceConfiguration" */
export type WorkspaceConfigurationThemeArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "workspaceConfiguration" */
export type WorkspaceConfiguration_Aggregate = {
  __typename?: 'workspaceConfiguration_aggregate';
  aggregate?: Maybe<WorkspaceConfiguration_Aggregate_Fields>;
  nodes: Array<WorkspaceConfiguration>;
};

/** aggregate fields of "workspaceConfiguration" */
export type WorkspaceConfiguration_Aggregate_Fields = {
  __typename?: 'workspaceConfiguration_aggregate_fields';
  avg?: Maybe<WorkspaceConfiguration_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<WorkspaceConfiguration_Max_Fields>;
  min?: Maybe<WorkspaceConfiguration_Min_Fields>;
  stddev?: Maybe<WorkspaceConfiguration_Stddev_Fields>;
  stddev_pop?: Maybe<WorkspaceConfiguration_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<WorkspaceConfiguration_Stddev_Samp_Fields>;
  sum?: Maybe<WorkspaceConfiguration_Sum_Fields>;
  var_pop?: Maybe<WorkspaceConfiguration_Var_Pop_Fields>;
  var_samp?: Maybe<WorkspaceConfiguration_Var_Samp_Fields>;
  variance?: Maybe<WorkspaceConfiguration_Variance_Fields>;
};


/** aggregate fields of "workspaceConfiguration" */
export type WorkspaceConfiguration_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<WorkspaceConfiguration_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type WorkspaceConfiguration_Append_Input = {
  appointmentReminders?: InputMaybe<Scalars['jsonb']>;
  calendar?: InputMaybe<Scalars['jsonb']>;
  completeAppointmentCopy?: InputMaybe<Scalars['jsonb']>;
  customPermissions?: InputMaybe<Scalars['jsonb']>;
  giftCardOptions?: InputMaybe<Scalars['jsonb']>;
  healthGorillaSubscriptions?: InputMaybe<Scalars['jsonb']>;
  ipWhitelist?: InputMaybe<Scalars['jsonb']>;
  leadStatusOptions?: InputMaybe<Scalars['jsonb']>;
  opportunities?: InputMaybe<Scalars['jsonb']>;
  patientPortalBookableServices?: InputMaybe<Scalars['jsonb']>;
  patientPortalInviteCopy?: InputMaybe<Scalars['jsonb']>;
  patientReferralOptions?: InputMaybe<Scalars['jsonb']>;
  paymentMethods?: InputMaybe<Scalars['jsonb']>;
  storefrontConfiguration?: InputMaybe<Scalars['jsonb']>;
  theme?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type WorkspaceConfiguration_Avg_Fields = {
  __typename?: 'workspaceConfiguration_avg_fields';
  noShowFee?: Maybe<Scalars['Float']>;
  patientPortalBookAppointmentBufferAmount?: Maybe<Scalars['Float']>;
  patientPortalCancelAppointmentBufferAmount?: Maybe<Scalars['Float']>;
  schedulingIntervalInMinutes?: Maybe<Scalars['Float']>;
  smsMarketingCharacterLimit?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "workspaceConfiguration". All fields are combined with a logical 'AND'. */
export type WorkspaceConfiguration_Bool_Exp = {
  _and?: InputMaybe<Array<WorkspaceConfiguration_Bool_Exp>>;
  _not?: InputMaybe<WorkspaceConfiguration_Bool_Exp>;
  _or?: InputMaybe<Array<WorkspaceConfiguration_Bool_Exp>>;
  afterHoursAutoResponderMessage?: InputMaybe<String_Comparison_Exp>;
  afterHoursCutoffTime?: InputMaybe<String_Comparison_Exp>;
  appointmentConfirmationMessage?: InputMaybe<String_Comparison_Exp>;
  appointmentReminders?: InputMaybe<Jsonb_Comparison_Exp>;
  autoCategorizeConversations?: InputMaybe<Boolean_Comparison_Exp>;
  calendar?: InputMaybe<Jsonb_Comparison_Exp>;
  cancellationSMSMessage?: InputMaybe<String_Comparison_Exp>;
  ccapiNetworkAddress?: InputMaybe<String_Comparison_Exp>;
  clinicalNotesExpandedDefault?: InputMaybe<Boolean_Comparison_Exp>;
  cloudflareCustomHostId?: InputMaybe<String_Comparison_Exp>;
  completeAppointmentCopy?: InputMaybe<Jsonb_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  customPermissions?: InputMaybe<Jsonb_Comparison_Exp>;
  defaultToPatientWallet?: InputMaybe<Boolean_Comparison_Exp>;
  enableAfterHoursAutoResponder?: InputMaybe<Boolean_Comparison_Exp>;
  enableCompleteActionsLinksInAppointmentReminders?: InputMaybe<Boolean_Comparison_Exp>;
  enableLabsDocumentDownload?: InputMaybe<Boolean_Comparison_Exp>;
  giftCardOptions?: InputMaybe<Jsonb_Comparison_Exp>;
  healthGorillaFacilityAdminUser?: InputMaybe<String_Comparison_Exp>;
  healthGorillaFacilityId?: InputMaybe<String_Comparison_Exp>;
  healthGorillaFacilitySecret?: InputMaybe<String_Comparison_Exp>;
  healthGorillaSubscriptions?: InputMaybe<Jsonb_Comparison_Exp>;
  hideCancelledAppointments?: InputMaybe<Boolean_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  invoiceMessage?: InputMaybe<String_Comparison_Exp>;
  ipWhitelist?: InputMaybe<Jsonb_Comparison_Exp>;
  ipWhitelistEnabled?: InputMaybe<Boolean_Comparison_Exp>;
  leadStatusOptions?: InputMaybe<Jsonb_Comparison_Exp>;
  logo?: InputMaybe<String_Comparison_Exp>;
  noShowActive?: InputMaybe<Boolean_Comparison_Exp>;
  noShowFee?: InputMaybe<Int_Comparison_Exp>;
  noShowSMSMessage?: InputMaybe<String_Comparison_Exp>;
  opportunities?: InputMaybe<Jsonb_Comparison_Exp>;
  patientPortalBookAppointmentBufferAmount?: InputMaybe<Int_Comparison_Exp>;
  patientPortalBookAppointmentBufferUnit?: InputMaybe<String_Comparison_Exp>;
  patientPortalBookableServices?: InputMaybe<Jsonb_Comparison_Exp>;
  patientPortalCanBookAppointments?: InputMaybe<Boolean_Comparison_Exp>;
  patientPortalCanCancelAppointments?: InputMaybe<Boolean_Comparison_Exp>;
  patientPortalCancelAppointmentBufferAmount?: InputMaybe<Int_Comparison_Exp>;
  patientPortalCancelAppointmentBufferUnit?: InputMaybe<String_Comparison_Exp>;
  patientPortalInviteCopy?: InputMaybe<Jsonb_Comparison_Exp>;
  patientReferralOptions?: InputMaybe<Jsonb_Comparison_Exp>;
  paymentMethods?: InputMaybe<Jsonb_Comparison_Exp>;
  providersOnlySeeOwnSchedule?: InputMaybe<Boolean_Comparison_Exp>;
  remindConfirmedPatients?: InputMaybe<Boolean_Comparison_Exp>;
  schedulingIntervalInMinutes?: InputMaybe<Int_Comparison_Exp>;
  sendAppointmentConfirmationMessage?: InputMaybe<Boolean_Comparison_Exp>;
  sendCancellationConfirmationSMS?: InputMaybe<Boolean_Comparison_Exp>;
  sendClinicalMessages?: InputMaybe<Boolean_Comparison_Exp>;
  sendEmailReceipts?: InputMaybe<Boolean_Comparison_Exp>;
  sendNoShowSMS?: InputMaybe<Boolean_Comparison_Exp>;
  smsMarketingCharacterLimit?: InputMaybe<Int_Comparison_Exp>;
  storefrontConfiguration?: InputMaybe<Jsonb_Comparison_Exp>;
  subscriptionManager?: InputMaybe<String_Comparison_Exp>;
  theme?: InputMaybe<Jsonb_Comparison_Exp>;
  timezone?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  workspace?: InputMaybe<Workspace_Bool_Exp>;
  workspaceId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "workspaceConfiguration" */
export enum WorkspaceConfiguration_Constraint {
  /** unique or primary key constraint on columns "id" */
  WorkspaceConfigurationPkey = 'workspaceConfiguration_pkey',
  /** unique or primary key constraint on columns "workspaceId" */
  WorkspaceConfigurationWorkspaceIdKey = 'workspaceConfiguration_workspaceId_key'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type WorkspaceConfiguration_Delete_At_Path_Input = {
  appointmentReminders?: InputMaybe<Array<Scalars['String']>>;
  calendar?: InputMaybe<Array<Scalars['String']>>;
  completeAppointmentCopy?: InputMaybe<Array<Scalars['String']>>;
  customPermissions?: InputMaybe<Array<Scalars['String']>>;
  giftCardOptions?: InputMaybe<Array<Scalars['String']>>;
  healthGorillaSubscriptions?: InputMaybe<Array<Scalars['String']>>;
  ipWhitelist?: InputMaybe<Array<Scalars['String']>>;
  leadStatusOptions?: InputMaybe<Array<Scalars['String']>>;
  opportunities?: InputMaybe<Array<Scalars['String']>>;
  patientPortalBookableServices?: InputMaybe<Array<Scalars['String']>>;
  patientPortalInviteCopy?: InputMaybe<Array<Scalars['String']>>;
  patientReferralOptions?: InputMaybe<Array<Scalars['String']>>;
  paymentMethods?: InputMaybe<Array<Scalars['String']>>;
  storefrontConfiguration?: InputMaybe<Array<Scalars['String']>>;
  theme?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type WorkspaceConfiguration_Delete_Elem_Input = {
  appointmentReminders?: InputMaybe<Scalars['Int']>;
  calendar?: InputMaybe<Scalars['Int']>;
  completeAppointmentCopy?: InputMaybe<Scalars['Int']>;
  customPermissions?: InputMaybe<Scalars['Int']>;
  giftCardOptions?: InputMaybe<Scalars['Int']>;
  healthGorillaSubscriptions?: InputMaybe<Scalars['Int']>;
  ipWhitelist?: InputMaybe<Scalars['Int']>;
  leadStatusOptions?: InputMaybe<Scalars['Int']>;
  opportunities?: InputMaybe<Scalars['Int']>;
  patientPortalBookableServices?: InputMaybe<Scalars['Int']>;
  patientPortalInviteCopy?: InputMaybe<Scalars['Int']>;
  patientReferralOptions?: InputMaybe<Scalars['Int']>;
  paymentMethods?: InputMaybe<Scalars['Int']>;
  storefrontConfiguration?: InputMaybe<Scalars['Int']>;
  theme?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type WorkspaceConfiguration_Delete_Key_Input = {
  appointmentReminders?: InputMaybe<Scalars['String']>;
  calendar?: InputMaybe<Scalars['String']>;
  completeAppointmentCopy?: InputMaybe<Scalars['String']>;
  customPermissions?: InputMaybe<Scalars['String']>;
  giftCardOptions?: InputMaybe<Scalars['String']>;
  healthGorillaSubscriptions?: InputMaybe<Scalars['String']>;
  ipWhitelist?: InputMaybe<Scalars['String']>;
  leadStatusOptions?: InputMaybe<Scalars['String']>;
  opportunities?: InputMaybe<Scalars['String']>;
  patientPortalBookableServices?: InputMaybe<Scalars['String']>;
  patientPortalInviteCopy?: InputMaybe<Scalars['String']>;
  patientReferralOptions?: InputMaybe<Scalars['String']>;
  paymentMethods?: InputMaybe<Scalars['String']>;
  storefrontConfiguration?: InputMaybe<Scalars['String']>;
  theme?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "workspaceConfiguration" */
export type WorkspaceConfiguration_Inc_Input = {
  noShowFee?: InputMaybe<Scalars['Int']>;
  patientPortalBookAppointmentBufferAmount?: InputMaybe<Scalars['Int']>;
  patientPortalCancelAppointmentBufferAmount?: InputMaybe<Scalars['Int']>;
  schedulingIntervalInMinutes?: InputMaybe<Scalars['Int']>;
  smsMarketingCharacterLimit?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "workspaceConfiguration" */
export type WorkspaceConfiguration_Insert_Input = {
  afterHoursAutoResponderMessage?: InputMaybe<Scalars['String']>;
  afterHoursCutoffTime?: InputMaybe<Scalars['String']>;
  appointmentConfirmationMessage?: InputMaybe<Scalars['String']>;
  appointmentReminders?: InputMaybe<Scalars['jsonb']>;
  autoCategorizeConversations?: InputMaybe<Scalars['Boolean']>;
  calendar?: InputMaybe<Scalars['jsonb']>;
  cancellationSMSMessage?: InputMaybe<Scalars['String']>;
  ccapiNetworkAddress?: InputMaybe<Scalars['String']>;
  clinicalNotesExpandedDefault?: InputMaybe<Scalars['Boolean']>;
  cloudflareCustomHostId?: InputMaybe<Scalars['String']>;
  completeAppointmentCopy?: InputMaybe<Scalars['jsonb']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  customPermissions?: InputMaybe<Scalars['jsonb']>;
  defaultToPatientWallet?: InputMaybe<Scalars['Boolean']>;
  enableAfterHoursAutoResponder?: InputMaybe<Scalars['Boolean']>;
  enableCompleteActionsLinksInAppointmentReminders?: InputMaybe<Scalars['Boolean']>;
  enableLabsDocumentDownload?: InputMaybe<Scalars['Boolean']>;
  giftCardOptions?: InputMaybe<Scalars['jsonb']>;
  healthGorillaFacilityAdminUser?: InputMaybe<Scalars['String']>;
  healthGorillaFacilityId?: InputMaybe<Scalars['String']>;
  healthGorillaFacilitySecret?: InputMaybe<Scalars['String']>;
  healthGorillaSubscriptions?: InputMaybe<Scalars['jsonb']>;
  hideCancelledAppointments?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['uuid']>;
  invoiceMessage?: InputMaybe<Scalars['String']>;
  ipWhitelist?: InputMaybe<Scalars['jsonb']>;
  ipWhitelistEnabled?: InputMaybe<Scalars['Boolean']>;
  leadStatusOptions?: InputMaybe<Scalars['jsonb']>;
  logo?: InputMaybe<Scalars['String']>;
  noShowActive?: InputMaybe<Scalars['Boolean']>;
  noShowFee?: InputMaybe<Scalars['Int']>;
  noShowSMSMessage?: InputMaybe<Scalars['String']>;
  opportunities?: InputMaybe<Scalars['jsonb']>;
  patientPortalBookAppointmentBufferAmount?: InputMaybe<Scalars['Int']>;
  patientPortalBookAppointmentBufferUnit?: InputMaybe<Scalars['String']>;
  patientPortalBookableServices?: InputMaybe<Scalars['jsonb']>;
  patientPortalCanBookAppointments?: InputMaybe<Scalars['Boolean']>;
  patientPortalCanCancelAppointments?: InputMaybe<Scalars['Boolean']>;
  patientPortalCancelAppointmentBufferAmount?: InputMaybe<Scalars['Int']>;
  patientPortalCancelAppointmentBufferUnit?: InputMaybe<Scalars['String']>;
  patientPortalInviteCopy?: InputMaybe<Scalars['jsonb']>;
  patientReferralOptions?: InputMaybe<Scalars['jsonb']>;
  paymentMethods?: InputMaybe<Scalars['jsonb']>;
  providersOnlySeeOwnSchedule?: InputMaybe<Scalars['Boolean']>;
  remindConfirmedPatients?: InputMaybe<Scalars['Boolean']>;
  schedulingIntervalInMinutes?: InputMaybe<Scalars['Int']>;
  sendAppointmentConfirmationMessage?: InputMaybe<Scalars['Boolean']>;
  sendCancellationConfirmationSMS?: InputMaybe<Scalars['Boolean']>;
  sendClinicalMessages?: InputMaybe<Scalars['Boolean']>;
  sendEmailReceipts?: InputMaybe<Scalars['Boolean']>;
  sendNoShowSMS?: InputMaybe<Scalars['Boolean']>;
  smsMarketingCharacterLimit?: InputMaybe<Scalars['Int']>;
  storefrontConfiguration?: InputMaybe<Scalars['jsonb']>;
  subscriptionManager?: InputMaybe<Scalars['String']>;
  theme?: InputMaybe<Scalars['jsonb']>;
  timezone?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspace?: InputMaybe<Workspace_Obj_Rel_Insert_Input>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type WorkspaceConfiguration_Max_Fields = {
  __typename?: 'workspaceConfiguration_max_fields';
  afterHoursAutoResponderMessage?: Maybe<Scalars['String']>;
  afterHoursCutoffTime?: Maybe<Scalars['String']>;
  appointmentConfirmationMessage?: Maybe<Scalars['String']>;
  cancellationSMSMessage?: Maybe<Scalars['String']>;
  ccapiNetworkAddress?: Maybe<Scalars['String']>;
  cloudflareCustomHostId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  healthGorillaFacilityAdminUser?: Maybe<Scalars['String']>;
  healthGorillaFacilityId?: Maybe<Scalars['String']>;
  healthGorillaFacilitySecret?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  invoiceMessage?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  noShowFee?: Maybe<Scalars['Int']>;
  noShowSMSMessage?: Maybe<Scalars['String']>;
  patientPortalBookAppointmentBufferAmount?: Maybe<Scalars['Int']>;
  patientPortalBookAppointmentBufferUnit?: Maybe<Scalars['String']>;
  patientPortalCancelAppointmentBufferAmount?: Maybe<Scalars['Int']>;
  patientPortalCancelAppointmentBufferUnit?: Maybe<Scalars['String']>;
  schedulingIntervalInMinutes?: Maybe<Scalars['Int']>;
  smsMarketingCharacterLimit?: Maybe<Scalars['Int']>;
  subscriptionManager?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type WorkspaceConfiguration_Min_Fields = {
  __typename?: 'workspaceConfiguration_min_fields';
  afterHoursAutoResponderMessage?: Maybe<Scalars['String']>;
  afterHoursCutoffTime?: Maybe<Scalars['String']>;
  appointmentConfirmationMessage?: Maybe<Scalars['String']>;
  cancellationSMSMessage?: Maybe<Scalars['String']>;
  ccapiNetworkAddress?: Maybe<Scalars['String']>;
  cloudflareCustomHostId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  healthGorillaFacilityAdminUser?: Maybe<Scalars['String']>;
  healthGorillaFacilityId?: Maybe<Scalars['String']>;
  healthGorillaFacilitySecret?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  invoiceMessage?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  noShowFee?: Maybe<Scalars['Int']>;
  noShowSMSMessage?: Maybe<Scalars['String']>;
  patientPortalBookAppointmentBufferAmount?: Maybe<Scalars['Int']>;
  patientPortalBookAppointmentBufferUnit?: Maybe<Scalars['String']>;
  patientPortalCancelAppointmentBufferAmount?: Maybe<Scalars['Int']>;
  patientPortalCancelAppointmentBufferUnit?: Maybe<Scalars['String']>;
  schedulingIntervalInMinutes?: Maybe<Scalars['Int']>;
  smsMarketingCharacterLimit?: Maybe<Scalars['Int']>;
  subscriptionManager?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  workspaceId?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "workspaceConfiguration" */
export type WorkspaceConfiguration_Mutation_Response = {
  __typename?: 'workspaceConfiguration_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<WorkspaceConfiguration>;
};

/** input type for inserting object relation for remote table "workspaceConfiguration" */
export type WorkspaceConfiguration_Obj_Rel_Insert_Input = {
  data: WorkspaceConfiguration_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<WorkspaceConfiguration_On_Conflict>;
};

/** on_conflict condition type for table "workspaceConfiguration" */
export type WorkspaceConfiguration_On_Conflict = {
  constraint: WorkspaceConfiguration_Constraint;
  update_columns?: Array<WorkspaceConfiguration_Update_Column>;
  where?: InputMaybe<WorkspaceConfiguration_Bool_Exp>;
};

/** Ordering options when selecting data from "workspaceConfiguration". */
export type WorkspaceConfiguration_Order_By = {
  afterHoursAutoResponderMessage?: InputMaybe<Order_By>;
  afterHoursCutoffTime?: InputMaybe<Order_By>;
  appointmentConfirmationMessage?: InputMaybe<Order_By>;
  appointmentReminders?: InputMaybe<Order_By>;
  autoCategorizeConversations?: InputMaybe<Order_By>;
  calendar?: InputMaybe<Order_By>;
  cancellationSMSMessage?: InputMaybe<Order_By>;
  ccapiNetworkAddress?: InputMaybe<Order_By>;
  clinicalNotesExpandedDefault?: InputMaybe<Order_By>;
  cloudflareCustomHostId?: InputMaybe<Order_By>;
  completeAppointmentCopy?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  customPermissions?: InputMaybe<Order_By>;
  defaultToPatientWallet?: InputMaybe<Order_By>;
  enableAfterHoursAutoResponder?: InputMaybe<Order_By>;
  enableCompleteActionsLinksInAppointmentReminders?: InputMaybe<Order_By>;
  enableLabsDocumentDownload?: InputMaybe<Order_By>;
  giftCardOptions?: InputMaybe<Order_By>;
  healthGorillaFacilityAdminUser?: InputMaybe<Order_By>;
  healthGorillaFacilityId?: InputMaybe<Order_By>;
  healthGorillaFacilitySecret?: InputMaybe<Order_By>;
  healthGorillaSubscriptions?: InputMaybe<Order_By>;
  hideCancelledAppointments?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoiceMessage?: InputMaybe<Order_By>;
  ipWhitelist?: InputMaybe<Order_By>;
  ipWhitelistEnabled?: InputMaybe<Order_By>;
  leadStatusOptions?: InputMaybe<Order_By>;
  logo?: InputMaybe<Order_By>;
  noShowActive?: InputMaybe<Order_By>;
  noShowFee?: InputMaybe<Order_By>;
  noShowSMSMessage?: InputMaybe<Order_By>;
  opportunities?: InputMaybe<Order_By>;
  patientPortalBookAppointmentBufferAmount?: InputMaybe<Order_By>;
  patientPortalBookAppointmentBufferUnit?: InputMaybe<Order_By>;
  patientPortalBookableServices?: InputMaybe<Order_By>;
  patientPortalCanBookAppointments?: InputMaybe<Order_By>;
  patientPortalCanCancelAppointments?: InputMaybe<Order_By>;
  patientPortalCancelAppointmentBufferAmount?: InputMaybe<Order_By>;
  patientPortalCancelAppointmentBufferUnit?: InputMaybe<Order_By>;
  patientPortalInviteCopy?: InputMaybe<Order_By>;
  patientReferralOptions?: InputMaybe<Order_By>;
  paymentMethods?: InputMaybe<Order_By>;
  providersOnlySeeOwnSchedule?: InputMaybe<Order_By>;
  remindConfirmedPatients?: InputMaybe<Order_By>;
  schedulingIntervalInMinutes?: InputMaybe<Order_By>;
  sendAppointmentConfirmationMessage?: InputMaybe<Order_By>;
  sendCancellationConfirmationSMS?: InputMaybe<Order_By>;
  sendClinicalMessages?: InputMaybe<Order_By>;
  sendEmailReceipts?: InputMaybe<Order_By>;
  sendNoShowSMS?: InputMaybe<Order_By>;
  smsMarketingCharacterLimit?: InputMaybe<Order_By>;
  storefrontConfiguration?: InputMaybe<Order_By>;
  subscriptionManager?: InputMaybe<Order_By>;
  theme?: InputMaybe<Order_By>;
  timezone?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  workspace?: InputMaybe<Workspace_Order_By>;
  workspaceId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: workspaceConfiguration */
export type WorkspaceConfiguration_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type WorkspaceConfiguration_Prepend_Input = {
  appointmentReminders?: InputMaybe<Scalars['jsonb']>;
  calendar?: InputMaybe<Scalars['jsonb']>;
  completeAppointmentCopy?: InputMaybe<Scalars['jsonb']>;
  customPermissions?: InputMaybe<Scalars['jsonb']>;
  giftCardOptions?: InputMaybe<Scalars['jsonb']>;
  healthGorillaSubscriptions?: InputMaybe<Scalars['jsonb']>;
  ipWhitelist?: InputMaybe<Scalars['jsonb']>;
  leadStatusOptions?: InputMaybe<Scalars['jsonb']>;
  opportunities?: InputMaybe<Scalars['jsonb']>;
  patientPortalBookableServices?: InputMaybe<Scalars['jsonb']>;
  patientPortalInviteCopy?: InputMaybe<Scalars['jsonb']>;
  patientReferralOptions?: InputMaybe<Scalars['jsonb']>;
  paymentMethods?: InputMaybe<Scalars['jsonb']>;
  storefrontConfiguration?: InputMaybe<Scalars['jsonb']>;
  theme?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "workspaceConfiguration" */
export enum WorkspaceConfiguration_Select_Column {
  /** column name */
  AfterHoursAutoResponderMessage = 'afterHoursAutoResponderMessage',
  /** column name */
  AfterHoursCutoffTime = 'afterHoursCutoffTime',
  /** column name */
  AppointmentConfirmationMessage = 'appointmentConfirmationMessage',
  /** column name */
  AppointmentReminders = 'appointmentReminders',
  /** column name */
  AutoCategorizeConversations = 'autoCategorizeConversations',
  /** column name */
  Calendar = 'calendar',
  /** column name */
  CancellationSmsMessage = 'cancellationSMSMessage',
  /** column name */
  CcapiNetworkAddress = 'ccapiNetworkAddress',
  /** column name */
  ClinicalNotesExpandedDefault = 'clinicalNotesExpandedDefault',
  /** column name */
  CloudflareCustomHostId = 'cloudflareCustomHostId',
  /** column name */
  CompleteAppointmentCopy = 'completeAppointmentCopy',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CustomPermissions = 'customPermissions',
  /** column name */
  DefaultToPatientWallet = 'defaultToPatientWallet',
  /** column name */
  EnableAfterHoursAutoResponder = 'enableAfterHoursAutoResponder',
  /** column name */
  EnableCompleteActionsLinksInAppointmentReminders = 'enableCompleteActionsLinksInAppointmentReminders',
  /** column name */
  EnableLabsDocumentDownload = 'enableLabsDocumentDownload',
  /** column name */
  GiftCardOptions = 'giftCardOptions',
  /** column name */
  HealthGorillaFacilityAdminUser = 'healthGorillaFacilityAdminUser',
  /** column name */
  HealthGorillaFacilityId = 'healthGorillaFacilityId',
  /** column name */
  HealthGorillaFacilitySecret = 'healthGorillaFacilitySecret',
  /** column name */
  HealthGorillaSubscriptions = 'healthGorillaSubscriptions',
  /** column name */
  HideCancelledAppointments = 'hideCancelledAppointments',
  /** column name */
  Id = 'id',
  /** column name */
  InvoiceMessage = 'invoiceMessage',
  /** column name */
  IpWhitelist = 'ipWhitelist',
  /** column name */
  IpWhitelistEnabled = 'ipWhitelistEnabled',
  /** column name */
  LeadStatusOptions = 'leadStatusOptions',
  /** column name */
  Logo = 'logo',
  /** column name */
  NoShowActive = 'noShowActive',
  /** column name */
  NoShowFee = 'noShowFee',
  /** column name */
  NoShowSmsMessage = 'noShowSMSMessage',
  /** column name */
  Opportunities = 'opportunities',
  /** column name */
  PatientPortalBookAppointmentBufferAmount = 'patientPortalBookAppointmentBufferAmount',
  /** column name */
  PatientPortalBookAppointmentBufferUnit = 'patientPortalBookAppointmentBufferUnit',
  /** column name */
  PatientPortalBookableServices = 'patientPortalBookableServices',
  /** column name */
  PatientPortalCanBookAppointments = 'patientPortalCanBookAppointments',
  /** column name */
  PatientPortalCanCancelAppointments = 'patientPortalCanCancelAppointments',
  /** column name */
  PatientPortalCancelAppointmentBufferAmount = 'patientPortalCancelAppointmentBufferAmount',
  /** column name */
  PatientPortalCancelAppointmentBufferUnit = 'patientPortalCancelAppointmentBufferUnit',
  /** column name */
  PatientPortalInviteCopy = 'patientPortalInviteCopy',
  /** column name */
  PatientReferralOptions = 'patientReferralOptions',
  /** column name */
  PaymentMethods = 'paymentMethods',
  /** column name */
  ProvidersOnlySeeOwnSchedule = 'providersOnlySeeOwnSchedule',
  /** column name */
  RemindConfirmedPatients = 'remindConfirmedPatients',
  /** column name */
  SchedulingIntervalInMinutes = 'schedulingIntervalInMinutes',
  /** column name */
  SendAppointmentConfirmationMessage = 'sendAppointmentConfirmationMessage',
  /** column name */
  SendCancellationConfirmationSms = 'sendCancellationConfirmationSMS',
  /** column name */
  SendClinicalMessages = 'sendClinicalMessages',
  /** column name */
  SendEmailReceipts = 'sendEmailReceipts',
  /** column name */
  SendNoShowSms = 'sendNoShowSMS',
  /** column name */
  SmsMarketingCharacterLimit = 'smsMarketingCharacterLimit',
  /** column name */
  StorefrontConfiguration = 'storefrontConfiguration',
  /** column name */
  SubscriptionManager = 'subscriptionManager',
  /** column name */
  Theme = 'theme',
  /** column name */
  Timezone = 'timezone',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WorkspaceId = 'workspaceId'
}

/** input type for updating data in table "workspaceConfiguration" */
export type WorkspaceConfiguration_Set_Input = {
  afterHoursAutoResponderMessage?: InputMaybe<Scalars['String']>;
  afterHoursCutoffTime?: InputMaybe<Scalars['String']>;
  appointmentConfirmationMessage?: InputMaybe<Scalars['String']>;
  appointmentReminders?: InputMaybe<Scalars['jsonb']>;
  autoCategorizeConversations?: InputMaybe<Scalars['Boolean']>;
  calendar?: InputMaybe<Scalars['jsonb']>;
  cancellationSMSMessage?: InputMaybe<Scalars['String']>;
  ccapiNetworkAddress?: InputMaybe<Scalars['String']>;
  clinicalNotesExpandedDefault?: InputMaybe<Scalars['Boolean']>;
  cloudflareCustomHostId?: InputMaybe<Scalars['String']>;
  completeAppointmentCopy?: InputMaybe<Scalars['jsonb']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  customPermissions?: InputMaybe<Scalars['jsonb']>;
  defaultToPatientWallet?: InputMaybe<Scalars['Boolean']>;
  enableAfterHoursAutoResponder?: InputMaybe<Scalars['Boolean']>;
  enableCompleteActionsLinksInAppointmentReminders?: InputMaybe<Scalars['Boolean']>;
  enableLabsDocumentDownload?: InputMaybe<Scalars['Boolean']>;
  giftCardOptions?: InputMaybe<Scalars['jsonb']>;
  healthGorillaFacilityAdminUser?: InputMaybe<Scalars['String']>;
  healthGorillaFacilityId?: InputMaybe<Scalars['String']>;
  healthGorillaFacilitySecret?: InputMaybe<Scalars['String']>;
  healthGorillaSubscriptions?: InputMaybe<Scalars['jsonb']>;
  hideCancelledAppointments?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['uuid']>;
  invoiceMessage?: InputMaybe<Scalars['String']>;
  ipWhitelist?: InputMaybe<Scalars['jsonb']>;
  ipWhitelistEnabled?: InputMaybe<Scalars['Boolean']>;
  leadStatusOptions?: InputMaybe<Scalars['jsonb']>;
  logo?: InputMaybe<Scalars['String']>;
  noShowActive?: InputMaybe<Scalars['Boolean']>;
  noShowFee?: InputMaybe<Scalars['Int']>;
  noShowSMSMessage?: InputMaybe<Scalars['String']>;
  opportunities?: InputMaybe<Scalars['jsonb']>;
  patientPortalBookAppointmentBufferAmount?: InputMaybe<Scalars['Int']>;
  patientPortalBookAppointmentBufferUnit?: InputMaybe<Scalars['String']>;
  patientPortalBookableServices?: InputMaybe<Scalars['jsonb']>;
  patientPortalCanBookAppointments?: InputMaybe<Scalars['Boolean']>;
  patientPortalCanCancelAppointments?: InputMaybe<Scalars['Boolean']>;
  patientPortalCancelAppointmentBufferAmount?: InputMaybe<Scalars['Int']>;
  patientPortalCancelAppointmentBufferUnit?: InputMaybe<Scalars['String']>;
  patientPortalInviteCopy?: InputMaybe<Scalars['jsonb']>;
  patientReferralOptions?: InputMaybe<Scalars['jsonb']>;
  paymentMethods?: InputMaybe<Scalars['jsonb']>;
  providersOnlySeeOwnSchedule?: InputMaybe<Scalars['Boolean']>;
  remindConfirmedPatients?: InputMaybe<Scalars['Boolean']>;
  schedulingIntervalInMinutes?: InputMaybe<Scalars['Int']>;
  sendAppointmentConfirmationMessage?: InputMaybe<Scalars['Boolean']>;
  sendCancellationConfirmationSMS?: InputMaybe<Scalars['Boolean']>;
  sendClinicalMessages?: InputMaybe<Scalars['Boolean']>;
  sendEmailReceipts?: InputMaybe<Scalars['Boolean']>;
  sendNoShowSMS?: InputMaybe<Scalars['Boolean']>;
  smsMarketingCharacterLimit?: InputMaybe<Scalars['Int']>;
  storefrontConfiguration?: InputMaybe<Scalars['jsonb']>;
  subscriptionManager?: InputMaybe<Scalars['String']>;
  theme?: InputMaybe<Scalars['jsonb']>;
  timezone?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type WorkspaceConfiguration_Stddev_Fields = {
  __typename?: 'workspaceConfiguration_stddev_fields';
  noShowFee?: Maybe<Scalars['Float']>;
  patientPortalBookAppointmentBufferAmount?: Maybe<Scalars['Float']>;
  patientPortalCancelAppointmentBufferAmount?: Maybe<Scalars['Float']>;
  schedulingIntervalInMinutes?: Maybe<Scalars['Float']>;
  smsMarketingCharacterLimit?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type WorkspaceConfiguration_Stddev_Pop_Fields = {
  __typename?: 'workspaceConfiguration_stddev_pop_fields';
  noShowFee?: Maybe<Scalars['Float']>;
  patientPortalBookAppointmentBufferAmount?: Maybe<Scalars['Float']>;
  patientPortalCancelAppointmentBufferAmount?: Maybe<Scalars['Float']>;
  schedulingIntervalInMinutes?: Maybe<Scalars['Float']>;
  smsMarketingCharacterLimit?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type WorkspaceConfiguration_Stddev_Samp_Fields = {
  __typename?: 'workspaceConfiguration_stddev_samp_fields';
  noShowFee?: Maybe<Scalars['Float']>;
  patientPortalBookAppointmentBufferAmount?: Maybe<Scalars['Float']>;
  patientPortalCancelAppointmentBufferAmount?: Maybe<Scalars['Float']>;
  schedulingIntervalInMinutes?: Maybe<Scalars['Float']>;
  smsMarketingCharacterLimit?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "workspaceConfiguration" */
export type WorkspaceConfiguration_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: WorkspaceConfiguration_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type WorkspaceConfiguration_Stream_Cursor_Value_Input = {
  afterHoursAutoResponderMessage?: InputMaybe<Scalars['String']>;
  afterHoursCutoffTime?: InputMaybe<Scalars['String']>;
  appointmentConfirmationMessage?: InputMaybe<Scalars['String']>;
  appointmentReminders?: InputMaybe<Scalars['jsonb']>;
  autoCategorizeConversations?: InputMaybe<Scalars['Boolean']>;
  calendar?: InputMaybe<Scalars['jsonb']>;
  cancellationSMSMessage?: InputMaybe<Scalars['String']>;
  ccapiNetworkAddress?: InputMaybe<Scalars['String']>;
  clinicalNotesExpandedDefault?: InputMaybe<Scalars['Boolean']>;
  cloudflareCustomHostId?: InputMaybe<Scalars['String']>;
  completeAppointmentCopy?: InputMaybe<Scalars['jsonb']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  customPermissions?: InputMaybe<Scalars['jsonb']>;
  defaultToPatientWallet?: InputMaybe<Scalars['Boolean']>;
  enableAfterHoursAutoResponder?: InputMaybe<Scalars['Boolean']>;
  enableCompleteActionsLinksInAppointmentReminders?: InputMaybe<Scalars['Boolean']>;
  enableLabsDocumentDownload?: InputMaybe<Scalars['Boolean']>;
  giftCardOptions?: InputMaybe<Scalars['jsonb']>;
  healthGorillaFacilityAdminUser?: InputMaybe<Scalars['String']>;
  healthGorillaFacilityId?: InputMaybe<Scalars['String']>;
  healthGorillaFacilitySecret?: InputMaybe<Scalars['String']>;
  healthGorillaSubscriptions?: InputMaybe<Scalars['jsonb']>;
  hideCancelledAppointments?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['uuid']>;
  invoiceMessage?: InputMaybe<Scalars['String']>;
  ipWhitelist?: InputMaybe<Scalars['jsonb']>;
  ipWhitelistEnabled?: InputMaybe<Scalars['Boolean']>;
  leadStatusOptions?: InputMaybe<Scalars['jsonb']>;
  logo?: InputMaybe<Scalars['String']>;
  noShowActive?: InputMaybe<Scalars['Boolean']>;
  noShowFee?: InputMaybe<Scalars['Int']>;
  noShowSMSMessage?: InputMaybe<Scalars['String']>;
  opportunities?: InputMaybe<Scalars['jsonb']>;
  patientPortalBookAppointmentBufferAmount?: InputMaybe<Scalars['Int']>;
  patientPortalBookAppointmentBufferUnit?: InputMaybe<Scalars['String']>;
  patientPortalBookableServices?: InputMaybe<Scalars['jsonb']>;
  patientPortalCanBookAppointments?: InputMaybe<Scalars['Boolean']>;
  patientPortalCanCancelAppointments?: InputMaybe<Scalars['Boolean']>;
  patientPortalCancelAppointmentBufferAmount?: InputMaybe<Scalars['Int']>;
  patientPortalCancelAppointmentBufferUnit?: InputMaybe<Scalars['String']>;
  patientPortalInviteCopy?: InputMaybe<Scalars['jsonb']>;
  patientReferralOptions?: InputMaybe<Scalars['jsonb']>;
  paymentMethods?: InputMaybe<Scalars['jsonb']>;
  providersOnlySeeOwnSchedule?: InputMaybe<Scalars['Boolean']>;
  remindConfirmedPatients?: InputMaybe<Scalars['Boolean']>;
  schedulingIntervalInMinutes?: InputMaybe<Scalars['Int']>;
  sendAppointmentConfirmationMessage?: InputMaybe<Scalars['Boolean']>;
  sendCancellationConfirmationSMS?: InputMaybe<Scalars['Boolean']>;
  sendClinicalMessages?: InputMaybe<Scalars['Boolean']>;
  sendEmailReceipts?: InputMaybe<Scalars['Boolean']>;
  sendNoShowSMS?: InputMaybe<Scalars['Boolean']>;
  smsMarketingCharacterLimit?: InputMaybe<Scalars['Int']>;
  storefrontConfiguration?: InputMaybe<Scalars['jsonb']>;
  subscriptionManager?: InputMaybe<Scalars['String']>;
  theme?: InputMaybe<Scalars['jsonb']>;
  timezone?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  workspaceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type WorkspaceConfiguration_Sum_Fields = {
  __typename?: 'workspaceConfiguration_sum_fields';
  noShowFee?: Maybe<Scalars['Int']>;
  patientPortalBookAppointmentBufferAmount?: Maybe<Scalars['Int']>;
  patientPortalCancelAppointmentBufferAmount?: Maybe<Scalars['Int']>;
  schedulingIntervalInMinutes?: Maybe<Scalars['Int']>;
  smsMarketingCharacterLimit?: Maybe<Scalars['Int']>;
};

/** update columns of table "workspaceConfiguration" */
export enum WorkspaceConfiguration_Update_Column {
  /** column name */
  AfterHoursAutoResponderMessage = 'afterHoursAutoResponderMessage',
  /** column name */
  AfterHoursCutoffTime = 'afterHoursCutoffTime',
  /** column name */
  AppointmentConfirmationMessage = 'appointmentConfirmationMessage',
  /** column name */
  AppointmentReminders = 'appointmentReminders',
  /** column name */
  AutoCategorizeConversations = 'autoCategorizeConversations',
  /** column name */
  Calendar = 'calendar',
  /** column name */
  CancellationSmsMessage = 'cancellationSMSMessage',
  /** column name */
  CcapiNetworkAddress = 'ccapiNetworkAddress',
  /** column name */
  ClinicalNotesExpandedDefault = 'clinicalNotesExpandedDefault',
  /** column name */
  CloudflareCustomHostId = 'cloudflareCustomHostId',
  /** column name */
  CompleteAppointmentCopy = 'completeAppointmentCopy',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CustomPermissions = 'customPermissions',
  /** column name */
  DefaultToPatientWallet = 'defaultToPatientWallet',
  /** column name */
  EnableAfterHoursAutoResponder = 'enableAfterHoursAutoResponder',
  /** column name */
  EnableCompleteActionsLinksInAppointmentReminders = 'enableCompleteActionsLinksInAppointmentReminders',
  /** column name */
  EnableLabsDocumentDownload = 'enableLabsDocumentDownload',
  /** column name */
  GiftCardOptions = 'giftCardOptions',
  /** column name */
  HealthGorillaFacilityAdminUser = 'healthGorillaFacilityAdminUser',
  /** column name */
  HealthGorillaFacilityId = 'healthGorillaFacilityId',
  /** column name */
  HealthGorillaFacilitySecret = 'healthGorillaFacilitySecret',
  /** column name */
  HealthGorillaSubscriptions = 'healthGorillaSubscriptions',
  /** column name */
  HideCancelledAppointments = 'hideCancelledAppointments',
  /** column name */
  Id = 'id',
  /** column name */
  InvoiceMessage = 'invoiceMessage',
  /** column name */
  IpWhitelist = 'ipWhitelist',
  /** column name */
  IpWhitelistEnabled = 'ipWhitelistEnabled',
  /** column name */
  LeadStatusOptions = 'leadStatusOptions',
  /** column name */
  Logo = 'logo',
  /** column name */
  NoShowActive = 'noShowActive',
  /** column name */
  NoShowFee = 'noShowFee',
  /** column name */
  NoShowSmsMessage = 'noShowSMSMessage',
  /** column name */
  Opportunities = 'opportunities',
  /** column name */
  PatientPortalBookAppointmentBufferAmount = 'patientPortalBookAppointmentBufferAmount',
  /** column name */
  PatientPortalBookAppointmentBufferUnit = 'patientPortalBookAppointmentBufferUnit',
  /** column name */
  PatientPortalBookableServices = 'patientPortalBookableServices',
  /** column name */
  PatientPortalCanBookAppointments = 'patientPortalCanBookAppointments',
  /** column name */
  PatientPortalCanCancelAppointments = 'patientPortalCanCancelAppointments',
  /** column name */
  PatientPortalCancelAppointmentBufferAmount = 'patientPortalCancelAppointmentBufferAmount',
  /** column name */
  PatientPortalCancelAppointmentBufferUnit = 'patientPortalCancelAppointmentBufferUnit',
  /** column name */
  PatientPortalInviteCopy = 'patientPortalInviteCopy',
  /** column name */
  PatientReferralOptions = 'patientReferralOptions',
  /** column name */
  PaymentMethods = 'paymentMethods',
  /** column name */
  ProvidersOnlySeeOwnSchedule = 'providersOnlySeeOwnSchedule',
  /** column name */
  RemindConfirmedPatients = 'remindConfirmedPatients',
  /** column name */
  SchedulingIntervalInMinutes = 'schedulingIntervalInMinutes',
  /** column name */
  SendAppointmentConfirmationMessage = 'sendAppointmentConfirmationMessage',
  /** column name */
  SendCancellationConfirmationSms = 'sendCancellationConfirmationSMS',
  /** column name */
  SendClinicalMessages = 'sendClinicalMessages',
  /** column name */
  SendEmailReceipts = 'sendEmailReceipts',
  /** column name */
  SendNoShowSms = 'sendNoShowSMS',
  /** column name */
  SmsMarketingCharacterLimit = 'smsMarketingCharacterLimit',
  /** column name */
  StorefrontConfiguration = 'storefrontConfiguration',
  /** column name */
  SubscriptionManager = 'subscriptionManager',
  /** column name */
  Theme = 'theme',
  /** column name */
  Timezone = 'timezone',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WorkspaceId = 'workspaceId'
}

export type WorkspaceConfiguration_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<WorkspaceConfiguration_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<WorkspaceConfiguration_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<WorkspaceConfiguration_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<WorkspaceConfiguration_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<WorkspaceConfiguration_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<WorkspaceConfiguration_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<WorkspaceConfiguration_Set_Input>;
  /** filter the rows which have to be updated */
  where: WorkspaceConfiguration_Bool_Exp;
};

/** aggregate var_pop on columns */
export type WorkspaceConfiguration_Var_Pop_Fields = {
  __typename?: 'workspaceConfiguration_var_pop_fields';
  noShowFee?: Maybe<Scalars['Float']>;
  patientPortalBookAppointmentBufferAmount?: Maybe<Scalars['Float']>;
  patientPortalCancelAppointmentBufferAmount?: Maybe<Scalars['Float']>;
  schedulingIntervalInMinutes?: Maybe<Scalars['Float']>;
  smsMarketingCharacterLimit?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type WorkspaceConfiguration_Var_Samp_Fields = {
  __typename?: 'workspaceConfiguration_var_samp_fields';
  noShowFee?: Maybe<Scalars['Float']>;
  patientPortalBookAppointmentBufferAmount?: Maybe<Scalars['Float']>;
  patientPortalCancelAppointmentBufferAmount?: Maybe<Scalars['Float']>;
  schedulingIntervalInMinutes?: Maybe<Scalars['Float']>;
  smsMarketingCharacterLimit?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type WorkspaceConfiguration_Variance_Fields = {
  __typename?: 'workspaceConfiguration_variance_fields';
  noShowFee?: Maybe<Scalars['Float']>;
  patientPortalBookAppointmentBufferAmount?: Maybe<Scalars['Float']>;
  patientPortalCancelAppointmentBufferAmount?: Maybe<Scalars['Float']>;
  schedulingIntervalInMinutes?: Maybe<Scalars['Float']>;
  smsMarketingCharacterLimit?: Maybe<Scalars['Float']>;
};

/** aggregated selection of "workspace" */
export type Workspace_Aggregate = {
  __typename?: 'workspace_aggregate';
  aggregate?: Maybe<Workspace_Aggregate_Fields>;
  nodes: Array<Workspace>;
};

export type Workspace_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Workspace_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Workspace_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Workspace_Aggregate_Bool_Exp_Count>;
};

export type Workspace_Aggregate_Bool_Exp_Bool_And = {
  arguments: Workspace_Select_Column_Workspace_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Workspace_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Workspace_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Workspace_Select_Column_Workspace_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Workspace_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Workspace_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Workspace_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Workspace_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "workspace" */
export type Workspace_Aggregate_Fields = {
  __typename?: 'workspace_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Workspace_Max_Fields>;
  min?: Maybe<Workspace_Min_Fields>;
};


/** aggregate fields of "workspace" */
export type Workspace_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Workspace_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "workspace" */
export type Workspace_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Workspace_Max_Order_By>;
  min?: InputMaybe<Workspace_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Workspace_Append_Input = {
  flags?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "workspace" */
export type Workspace_Arr_Rel_Insert_Input = {
  data: Array<Workspace_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Workspace_On_Conflict>;
};

/** Boolean expression to filter rows from the table "workspace". All fields are combined with a logical 'AND'. */
export type Workspace_Bool_Exp = {
  _and?: InputMaybe<Array<Workspace_Bool_Exp>>;
  _not?: InputMaybe<Workspace_Bool_Exp>;
  _or?: InputMaybe<Array<Workspace_Bool_Exp>>;
  activityLogs?: InputMaybe<ActivityLog_Bool_Exp>;
  activityLogs_aggregate?: InputMaybe<ActivityLog_Aggregate_Bool_Exp>;
  authProfiles?: InputMaybe<AuthProfile_Bool_Exp>;
  authProfiles_aggregate?: InputMaybe<AuthProfile_Aggregate_Bool_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  deviceTypes?: InputMaybe<DeviceType_Bool_Exp>;
  deviceTypes_aggregate?: InputMaybe<DeviceType_Aggregate_Bool_Exp>;
  domain?: InputMaybe<String_Comparison_Exp>;
  eRxEnabled?: InputMaybe<Boolean_Comparison_Exp>;
  externalLocations?: InputMaybe<ExternalLocation_Bool_Exp>;
  externalLocations_aggregate?: InputMaybe<ExternalLocation_Aggregate_Bool_Exp>;
  externalProviders?: InputMaybe<ExternalProvider_Bool_Exp>;
  externalProviders_aggregate?: InputMaybe<ExternalProvider_Aggregate_Bool_Exp>;
  externalReviews?: InputMaybe<ExternalReview_Bool_Exp>;
  externalReviews_aggregate?: InputMaybe<ExternalReview_Aggregate_Bool_Exp>;
  finixMerchantId?: InputMaybe<String_Comparison_Exp>;
  flags?: InputMaybe<Jsonb_Comparison_Exp>;
  googleAccounts?: InputMaybe<GoogleAccount_Bool_Exp>;
  googleAccounts_aggregate?: InputMaybe<GoogleAccount_Aggregate_Bool_Exp>;
  googleLocations?: InputMaybe<GoogleLocation_Bool_Exp>;
  googleLocations_aggregate?: InputMaybe<GoogleLocation_Aggregate_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  leadFormEntries?: InputMaybe<LeadFormEntry_Bool_Exp>;
  leadFormEntries_aggregate?: InputMaybe<LeadFormEntry_Aggregate_Bool_Exp>;
  leadFormFields?: InputMaybe<LeadFormField_Bool_Exp>;
  leadFormFields_aggregate?: InputMaybe<LeadFormField_Aggregate_Bool_Exp>;
  leadForms?: InputMaybe<LeadForm_Bool_Exp>;
  leadForms_aggregate?: InputMaybe<LeadForm_Aggregate_Bool_Exp>;
  locations?: InputMaybe<Location_Bool_Exp>;
  locations_aggregate?: InputMaybe<Location_Aggregate_Bool_Exp>;
  logo?: InputMaybe<String_Comparison_Exp>;
  mediaTags?: InputMaybe<MediaTag_Bool_Exp>;
  mediaTags_aggregate?: InputMaybe<MediaTag_Aggregate_Bool_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  notes?: InputMaybe<Note_Bool_Exp>;
  notes_aggregate?: InputMaybe<Note_Aggregate_Bool_Exp>;
  organization?: InputMaybe<Organization_Bool_Exp>;
  organizationId?: InputMaybe<Uuid_Comparison_Exp>;
  patientWorkspaces?: InputMaybe<PatientWorkspace_Bool_Exp>;
  patientWorkspaces_aggregate?: InputMaybe<PatientWorkspace_Aggregate_Bool_Exp>;
  payrixMerchantId?: InputMaybe<String_Comparison_Exp>;
  providers?: InputMaybe<Provider_Bool_Exp>;
  providers_aggregate?: InputMaybe<Provider_Aggregate_Bool_Exp>;
  rewardProgram?: InputMaybe<RewardProgram_Bool_Exp>;
  services?: InputMaybe<Service_Bool_Exp>;
  services_aggregate?: InputMaybe<Service_Aggregate_Bool_Exp>;
  slug?: InputMaybe<String_Comparison_Exp>;
  socialMediaAccounts?: InputMaybe<SocialMediaAccount_Bool_Exp>;
  socialMediaAccounts_aggregate?: InputMaybe<SocialMediaAccount_Aggregate_Bool_Exp>;
  storefrontTrackingId?: InputMaybe<String_Comparison_Exp>;
  twilioPhoneNumber?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  userInvites?: InputMaybe<UserInvite_Bool_Exp>;
  userInvites_aggregate?: InputMaybe<UserInvite_Aggregate_Bool_Exp>;
  userWorkspace?: InputMaybe<UserWorkspace_Bool_Exp>;
  workspaceConfiguration?: InputMaybe<WorkspaceConfiguration_Bool_Exp>;
};

/** unique or primary key constraints on table "workspace" */
export enum Workspace_Constraint {
  /** unique or primary key constraint on columns "id" */
  WorkspacePkey = 'workspace_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Workspace_Delete_At_Path_Input = {
  flags?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Workspace_Delete_Elem_Input = {
  flags?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Workspace_Delete_Key_Input = {
  flags?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "workspace" */
export type Workspace_Insert_Input = {
  activityLogs?: InputMaybe<ActivityLog_Arr_Rel_Insert_Input>;
  authProfiles?: InputMaybe<AuthProfile_Arr_Rel_Insert_Input>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  deviceTypes?: InputMaybe<DeviceType_Arr_Rel_Insert_Input>;
  domain?: InputMaybe<Scalars['String']>;
  eRxEnabled?: InputMaybe<Scalars['Boolean']>;
  externalLocations?: InputMaybe<ExternalLocation_Arr_Rel_Insert_Input>;
  externalProviders?: InputMaybe<ExternalProvider_Arr_Rel_Insert_Input>;
  externalReviews?: InputMaybe<ExternalReview_Arr_Rel_Insert_Input>;
  finixMerchantId?: InputMaybe<Scalars['String']>;
  flags?: InputMaybe<Scalars['jsonb']>;
  googleAccounts?: InputMaybe<GoogleAccount_Arr_Rel_Insert_Input>;
  googleLocations?: InputMaybe<GoogleLocation_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']>;
  leadFormEntries?: InputMaybe<LeadFormEntry_Arr_Rel_Insert_Input>;
  leadFormFields?: InputMaybe<LeadFormField_Arr_Rel_Insert_Input>;
  leadForms?: InputMaybe<LeadForm_Arr_Rel_Insert_Input>;
  locations?: InputMaybe<Location_Arr_Rel_Insert_Input>;
  logo?: InputMaybe<Scalars['String']>;
  mediaTags?: InputMaybe<MediaTag_Arr_Rel_Insert_Input>;
  name?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Note_Arr_Rel_Insert_Input>;
  organization?: InputMaybe<Organization_Obj_Rel_Insert_Input>;
  organizationId?: InputMaybe<Scalars['uuid']>;
  patientWorkspaces?: InputMaybe<PatientWorkspace_Arr_Rel_Insert_Input>;
  payrixMerchantId?: InputMaybe<Scalars['String']>;
  providers?: InputMaybe<Provider_Arr_Rel_Insert_Input>;
  rewardProgram?: InputMaybe<RewardProgram_Obj_Rel_Insert_Input>;
  services?: InputMaybe<Service_Arr_Rel_Insert_Input>;
  slug?: InputMaybe<Scalars['String']>;
  socialMediaAccounts?: InputMaybe<SocialMediaAccount_Arr_Rel_Insert_Input>;
  storefrontTrackingId?: InputMaybe<Scalars['String']>;
  twilioPhoneNumber?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  userInvites?: InputMaybe<UserInvite_Arr_Rel_Insert_Input>;
  userWorkspace?: InputMaybe<UserWorkspace_Obj_Rel_Insert_Input>;
  workspaceConfiguration?: InputMaybe<WorkspaceConfiguration_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Workspace_Max_Fields = {
  __typename?: 'workspace_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  domain?: Maybe<Scalars['String']>;
  finixMerchantId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  logo?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['uuid']>;
  payrixMerchantId?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  storefrontTrackingId?: Maybe<Scalars['String']>;
  twilioPhoneNumber?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "workspace" */
export type Workspace_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  domain?: InputMaybe<Order_By>;
  finixMerchantId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  logo?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organizationId?: InputMaybe<Order_By>;
  payrixMerchantId?: InputMaybe<Order_By>;
  slug?: InputMaybe<Order_By>;
  storefrontTrackingId?: InputMaybe<Order_By>;
  twilioPhoneNumber?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Workspace_Min_Fields = {
  __typename?: 'workspace_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  domain?: Maybe<Scalars['String']>;
  finixMerchantId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  logo?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['uuid']>;
  payrixMerchantId?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  storefrontTrackingId?: Maybe<Scalars['String']>;
  twilioPhoneNumber?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "workspace" */
export type Workspace_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  domain?: InputMaybe<Order_By>;
  finixMerchantId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  logo?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organizationId?: InputMaybe<Order_By>;
  payrixMerchantId?: InputMaybe<Order_By>;
  slug?: InputMaybe<Order_By>;
  storefrontTrackingId?: InputMaybe<Order_By>;
  twilioPhoneNumber?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "workspace" */
export type Workspace_Mutation_Response = {
  __typename?: 'workspace_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Workspace>;
};

/** input type for inserting object relation for remote table "workspace" */
export type Workspace_Obj_Rel_Insert_Input = {
  data: Workspace_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Workspace_On_Conflict>;
};

/** on_conflict condition type for table "workspace" */
export type Workspace_On_Conflict = {
  constraint: Workspace_Constraint;
  update_columns?: Array<Workspace_Update_Column>;
  where?: InputMaybe<Workspace_Bool_Exp>;
};

/** Ordering options when selecting data from "workspace". */
export type Workspace_Order_By = {
  activityLogs_aggregate?: InputMaybe<ActivityLog_Aggregate_Order_By>;
  authProfiles_aggregate?: InputMaybe<AuthProfile_Aggregate_Order_By>;
  createdAt?: InputMaybe<Order_By>;
  deviceTypes_aggregate?: InputMaybe<DeviceType_Aggregate_Order_By>;
  domain?: InputMaybe<Order_By>;
  eRxEnabled?: InputMaybe<Order_By>;
  externalLocations_aggregate?: InputMaybe<ExternalLocation_Aggregate_Order_By>;
  externalProviders_aggregate?: InputMaybe<ExternalProvider_Aggregate_Order_By>;
  externalReviews_aggregate?: InputMaybe<ExternalReview_Aggregate_Order_By>;
  finixMerchantId?: InputMaybe<Order_By>;
  flags?: InputMaybe<Order_By>;
  googleAccounts_aggregate?: InputMaybe<GoogleAccount_Aggregate_Order_By>;
  googleLocations_aggregate?: InputMaybe<GoogleLocation_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  leadFormEntries_aggregate?: InputMaybe<LeadFormEntry_Aggregate_Order_By>;
  leadFormFields_aggregate?: InputMaybe<LeadFormField_Aggregate_Order_By>;
  leadForms_aggregate?: InputMaybe<LeadForm_Aggregate_Order_By>;
  locations_aggregate?: InputMaybe<Location_Aggregate_Order_By>;
  logo?: InputMaybe<Order_By>;
  mediaTags_aggregate?: InputMaybe<MediaTag_Aggregate_Order_By>;
  name?: InputMaybe<Order_By>;
  notes_aggregate?: InputMaybe<Note_Aggregate_Order_By>;
  organization?: InputMaybe<Organization_Order_By>;
  organizationId?: InputMaybe<Order_By>;
  patientWorkspaces_aggregate?: InputMaybe<PatientWorkspace_Aggregate_Order_By>;
  payrixMerchantId?: InputMaybe<Order_By>;
  providers_aggregate?: InputMaybe<Provider_Aggregate_Order_By>;
  rewardProgram?: InputMaybe<RewardProgram_Order_By>;
  services_aggregate?: InputMaybe<Service_Aggregate_Order_By>;
  slug?: InputMaybe<Order_By>;
  socialMediaAccounts_aggregate?: InputMaybe<SocialMediaAccount_Aggregate_Order_By>;
  storefrontTrackingId?: InputMaybe<Order_By>;
  twilioPhoneNumber?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  userInvites_aggregate?: InputMaybe<UserInvite_Aggregate_Order_By>;
  userWorkspace?: InputMaybe<UserWorkspace_Order_By>;
  workspaceConfiguration?: InputMaybe<WorkspaceConfiguration_Order_By>;
};

/** primary key columns input for table: workspace */
export type Workspace_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Workspace_Prepend_Input = {
  flags?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "workspace" */
export enum Workspace_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Domain = 'domain',
  /** column name */
  ERxEnabled = 'eRxEnabled',
  /** column name */
  FinixMerchantId = 'finixMerchantId',
  /** column name */
  Flags = 'flags',
  /** column name */
  Id = 'id',
  /** column name */
  Logo = 'logo',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationId = 'organizationId',
  /** column name */
  PayrixMerchantId = 'payrixMerchantId',
  /** column name */
  Slug = 'slug',
  /** column name */
  StorefrontTrackingId = 'storefrontTrackingId',
  /** column name */
  TwilioPhoneNumber = 'twilioPhoneNumber',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** select "workspace_aggregate_bool_exp_bool_and_arguments_columns" columns of table "workspace" */
export enum Workspace_Select_Column_Workspace_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  ERxEnabled = 'eRxEnabled'
}

/** select "workspace_aggregate_bool_exp_bool_or_arguments_columns" columns of table "workspace" */
export enum Workspace_Select_Column_Workspace_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  ERxEnabled = 'eRxEnabled'
}

/** input type for updating data in table "workspace" */
export type Workspace_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  domain?: InputMaybe<Scalars['String']>;
  eRxEnabled?: InputMaybe<Scalars['Boolean']>;
  finixMerchantId?: InputMaybe<Scalars['String']>;
  flags?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['uuid']>;
  logo?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['uuid']>;
  payrixMerchantId?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  storefrontTrackingId?: InputMaybe<Scalars['String']>;
  twilioPhoneNumber?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "workspace" */
export type Workspace_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Workspace_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Workspace_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  domain?: InputMaybe<Scalars['String']>;
  eRxEnabled?: InputMaybe<Scalars['Boolean']>;
  finixMerchantId?: InputMaybe<Scalars['String']>;
  flags?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['uuid']>;
  logo?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['uuid']>;
  payrixMerchantId?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  storefrontTrackingId?: InputMaybe<Scalars['String']>;
  twilioPhoneNumber?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "workspace" */
export enum Workspace_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Domain = 'domain',
  /** column name */
  ERxEnabled = 'eRxEnabled',
  /** column name */
  FinixMerchantId = 'finixMerchantId',
  /** column name */
  Flags = 'flags',
  /** column name */
  Id = 'id',
  /** column name */
  Logo = 'logo',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationId = 'organizationId',
  /** column name */
  PayrixMerchantId = 'payrixMerchantId',
  /** column name */
  Slug = 'slug',
  /** column name */
  StorefrontTrackingId = 'storefrontTrackingId',
  /** column name */
  TwilioPhoneNumber = 'twilioPhoneNumber',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Workspace_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Workspace_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Workspace_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Workspace_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Workspace_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Workspace_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Workspace_Set_Input>;
  /** filter the rows which have to be updated */
  where: Workspace_Bool_Exp;
};

/** columns and relationships of "zdReview" */
export type ZdReview = {
  __typename?: 'zdReview';
  bedsideRating: Scalars['Int'];
  comment: Scalars['String'];
  createdAt: Scalars['timestamptz'];
  date: Scalars['String'];
  dateRange?: Maybe<Scalars['String']>;
  externalId: Scalars['String'];
  helpfulCount: Scalars['Int'];
  id: Scalars['uuid'];
  isMailedInReview: Scalars['Boolean'];
  isPartnerReview: Scalars['Boolean'];
  isVirtualVisit: Scalars['Boolean'];
  overallRating: Scalars['Int'];
  patientName: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  waitTimeRating: Scalars['Int'];
};

/** aggregated selection of "zdReview" */
export type ZdReview_Aggregate = {
  __typename?: 'zdReview_aggregate';
  aggregate?: Maybe<ZdReview_Aggregate_Fields>;
  nodes: Array<ZdReview>;
};

/** aggregate fields of "zdReview" */
export type ZdReview_Aggregate_Fields = {
  __typename?: 'zdReview_aggregate_fields';
  avg?: Maybe<ZdReview_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<ZdReview_Max_Fields>;
  min?: Maybe<ZdReview_Min_Fields>;
  stddev?: Maybe<ZdReview_Stddev_Fields>;
  stddev_pop?: Maybe<ZdReview_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<ZdReview_Stddev_Samp_Fields>;
  sum?: Maybe<ZdReview_Sum_Fields>;
  var_pop?: Maybe<ZdReview_Var_Pop_Fields>;
  var_samp?: Maybe<ZdReview_Var_Samp_Fields>;
  variance?: Maybe<ZdReview_Variance_Fields>;
};


/** aggregate fields of "zdReview" */
export type ZdReview_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<ZdReview_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type ZdReview_Avg_Fields = {
  __typename?: 'zdReview_avg_fields';
  bedsideRating?: Maybe<Scalars['Float']>;
  helpfulCount?: Maybe<Scalars['Float']>;
  overallRating?: Maybe<Scalars['Float']>;
  waitTimeRating?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "zdReview". All fields are combined with a logical 'AND'. */
export type ZdReview_Bool_Exp = {
  _and?: InputMaybe<Array<ZdReview_Bool_Exp>>;
  _not?: InputMaybe<ZdReview_Bool_Exp>;
  _or?: InputMaybe<Array<ZdReview_Bool_Exp>>;
  bedsideRating?: InputMaybe<Int_Comparison_Exp>;
  comment?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  date?: InputMaybe<String_Comparison_Exp>;
  dateRange?: InputMaybe<String_Comparison_Exp>;
  externalId?: InputMaybe<String_Comparison_Exp>;
  helpfulCount?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  isMailedInReview?: InputMaybe<Boolean_Comparison_Exp>;
  isPartnerReview?: InputMaybe<Boolean_Comparison_Exp>;
  isVirtualVisit?: InputMaybe<Boolean_Comparison_Exp>;
  overallRating?: InputMaybe<Int_Comparison_Exp>;
  patientName?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  waitTimeRating?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "zdReview" */
export enum ZdReview_Constraint {
  /** unique or primary key constraint on columns "externalId" */
  ZdReviewExternalIdKey = 'zdReview_externalId_key',
  /** unique or primary key constraint on columns "id" */
  ZdReviewPkey = 'zdReview_pkey'
}

/** input type for incrementing numeric columns in table "zdReview" */
export type ZdReview_Inc_Input = {
  bedsideRating?: InputMaybe<Scalars['Int']>;
  helpfulCount?: InputMaybe<Scalars['Int']>;
  overallRating?: InputMaybe<Scalars['Int']>;
  waitTimeRating?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "zdReview" */
export type ZdReview_Insert_Input = {
  bedsideRating?: InputMaybe<Scalars['Int']>;
  comment?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  date?: InputMaybe<Scalars['String']>;
  dateRange?: InputMaybe<Scalars['String']>;
  externalId?: InputMaybe<Scalars['String']>;
  helpfulCount?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  isMailedInReview?: InputMaybe<Scalars['Boolean']>;
  isPartnerReview?: InputMaybe<Scalars['Boolean']>;
  isVirtualVisit?: InputMaybe<Scalars['Boolean']>;
  overallRating?: InputMaybe<Scalars['Int']>;
  patientName?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  waitTimeRating?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type ZdReview_Max_Fields = {
  __typename?: 'zdReview_max_fields';
  bedsideRating?: Maybe<Scalars['Int']>;
  comment?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  date?: Maybe<Scalars['String']>;
  dateRange?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  helpfulCount?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  overallRating?: Maybe<Scalars['Int']>;
  patientName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  waitTimeRating?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type ZdReview_Min_Fields = {
  __typename?: 'zdReview_min_fields';
  bedsideRating?: Maybe<Scalars['Int']>;
  comment?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  date?: Maybe<Scalars['String']>;
  dateRange?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  helpfulCount?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  overallRating?: Maybe<Scalars['Int']>;
  patientName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  waitTimeRating?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "zdReview" */
export type ZdReview_Mutation_Response = {
  __typename?: 'zdReview_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ZdReview>;
};

/** on_conflict condition type for table "zdReview" */
export type ZdReview_On_Conflict = {
  constraint: ZdReview_Constraint;
  update_columns?: Array<ZdReview_Update_Column>;
  where?: InputMaybe<ZdReview_Bool_Exp>;
};

/** Ordering options when selecting data from "zdReview". */
export type ZdReview_Order_By = {
  bedsideRating?: InputMaybe<Order_By>;
  comment?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
  dateRange?: InputMaybe<Order_By>;
  externalId?: InputMaybe<Order_By>;
  helpfulCount?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  isMailedInReview?: InputMaybe<Order_By>;
  isPartnerReview?: InputMaybe<Order_By>;
  isVirtualVisit?: InputMaybe<Order_By>;
  overallRating?: InputMaybe<Order_By>;
  patientName?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  waitTimeRating?: InputMaybe<Order_By>;
};

/** primary key columns input for table: zdReview */
export type ZdReview_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "zdReview" */
export enum ZdReview_Select_Column {
  /** column name */
  BedsideRating = 'bedsideRating',
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Date = 'date',
  /** column name */
  DateRange = 'dateRange',
  /** column name */
  ExternalId = 'externalId',
  /** column name */
  HelpfulCount = 'helpfulCount',
  /** column name */
  Id = 'id',
  /** column name */
  IsMailedInReview = 'isMailedInReview',
  /** column name */
  IsPartnerReview = 'isPartnerReview',
  /** column name */
  IsVirtualVisit = 'isVirtualVisit',
  /** column name */
  OverallRating = 'overallRating',
  /** column name */
  PatientName = 'patientName',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WaitTimeRating = 'waitTimeRating'
}

/** input type for updating data in table "zdReview" */
export type ZdReview_Set_Input = {
  bedsideRating?: InputMaybe<Scalars['Int']>;
  comment?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  date?: InputMaybe<Scalars['String']>;
  dateRange?: InputMaybe<Scalars['String']>;
  externalId?: InputMaybe<Scalars['String']>;
  helpfulCount?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  isMailedInReview?: InputMaybe<Scalars['Boolean']>;
  isPartnerReview?: InputMaybe<Scalars['Boolean']>;
  isVirtualVisit?: InputMaybe<Scalars['Boolean']>;
  overallRating?: InputMaybe<Scalars['Int']>;
  patientName?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  waitTimeRating?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type ZdReview_Stddev_Fields = {
  __typename?: 'zdReview_stddev_fields';
  bedsideRating?: Maybe<Scalars['Float']>;
  helpfulCount?: Maybe<Scalars['Float']>;
  overallRating?: Maybe<Scalars['Float']>;
  waitTimeRating?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type ZdReview_Stddev_Pop_Fields = {
  __typename?: 'zdReview_stddev_pop_fields';
  bedsideRating?: Maybe<Scalars['Float']>;
  helpfulCount?: Maybe<Scalars['Float']>;
  overallRating?: Maybe<Scalars['Float']>;
  waitTimeRating?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type ZdReview_Stddev_Samp_Fields = {
  __typename?: 'zdReview_stddev_samp_fields';
  bedsideRating?: Maybe<Scalars['Float']>;
  helpfulCount?: Maybe<Scalars['Float']>;
  overallRating?: Maybe<Scalars['Float']>;
  waitTimeRating?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "zdReview" */
export type ZdReview_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ZdReview_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ZdReview_Stream_Cursor_Value_Input = {
  bedsideRating?: InputMaybe<Scalars['Int']>;
  comment?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  date?: InputMaybe<Scalars['String']>;
  dateRange?: InputMaybe<Scalars['String']>;
  externalId?: InputMaybe<Scalars['String']>;
  helpfulCount?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  isMailedInReview?: InputMaybe<Scalars['Boolean']>;
  isPartnerReview?: InputMaybe<Scalars['Boolean']>;
  isVirtualVisit?: InputMaybe<Scalars['Boolean']>;
  overallRating?: InputMaybe<Scalars['Int']>;
  patientName?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  waitTimeRating?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type ZdReview_Sum_Fields = {
  __typename?: 'zdReview_sum_fields';
  bedsideRating?: Maybe<Scalars['Int']>;
  helpfulCount?: Maybe<Scalars['Int']>;
  overallRating?: Maybe<Scalars['Int']>;
  waitTimeRating?: Maybe<Scalars['Int']>;
};

/** update columns of table "zdReview" */
export enum ZdReview_Update_Column {
  /** column name */
  BedsideRating = 'bedsideRating',
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Date = 'date',
  /** column name */
  DateRange = 'dateRange',
  /** column name */
  ExternalId = 'externalId',
  /** column name */
  HelpfulCount = 'helpfulCount',
  /** column name */
  Id = 'id',
  /** column name */
  IsMailedInReview = 'isMailedInReview',
  /** column name */
  IsPartnerReview = 'isPartnerReview',
  /** column name */
  IsVirtualVisit = 'isVirtualVisit',
  /** column name */
  OverallRating = 'overallRating',
  /** column name */
  PatientName = 'patientName',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WaitTimeRating = 'waitTimeRating'
}

export type ZdReview_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ZdReview_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ZdReview_Set_Input>;
  /** filter the rows which have to be updated */
  where: ZdReview_Bool_Exp;
};

/** aggregate var_pop on columns */
export type ZdReview_Var_Pop_Fields = {
  __typename?: 'zdReview_var_pop_fields';
  bedsideRating?: Maybe<Scalars['Float']>;
  helpfulCount?: Maybe<Scalars['Float']>;
  overallRating?: Maybe<Scalars['Float']>;
  waitTimeRating?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type ZdReview_Var_Samp_Fields = {
  __typename?: 'zdReview_var_samp_fields';
  bedsideRating?: Maybe<Scalars['Float']>;
  helpfulCount?: Maybe<Scalars['Float']>;
  overallRating?: Maybe<Scalars['Float']>;
  waitTimeRating?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type ZdReview_Variance_Fields = {
  __typename?: 'zdReview_variance_fields';
  bedsideRating?: Maybe<Scalars['Float']>;
  helpfulCount?: Maybe<Scalars['Float']>;
  overallRating?: Maybe<Scalars['Float']>;
  waitTimeRating?: Maybe<Scalars['Float']>;
};
