import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Flex,
  Button,
} from '@chakra-ui/react';
import { useDeleteCustomQuestionMutation } from '@webapp/graphql';
import toast from 'react-hot-toast';
import { DefaultQuestion } from '@webapp/types';

interface DeleteQuestionModalProps {
  isOpen: boolean;
  question?: DefaultQuestion;
  onClose: () => void;
  refetchQuestions: any;
}

export const DeleteQuestionModal = ({
  isOpen,
  question,
  onClose,
  refetchQuestions,
}: DeleteQuestionModalProps): JSX.Element => {
  const [deleteQuestion] = useDeleteCustomQuestionMutation();

  const handleDelete = async () => {
    if (question) {
      deleteQuestion({
        variables: {
          where: {
            id: { _eq: question.id },
          },
        },
        onCompleted: () => {
          refetchQuestions();
          toast.success('Question deleted');
          onClose();
        },
        onError: (error) => {
          toast.error(error.message);
          onClose();
        },
      });
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Delete Question</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          Are you sure you want to delete this question? You can't undo this
          action afterwards.
        </ModalBody>
        <ModalFooter>
          <Flex justifyContent="end">
            <Button onClick={onClose} marginRight="5">
              Cancel
            </Button>
            <Button variant="solid" colorScheme="red" onClick={handleDelete}>
              Delete
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default DeleteQuestionModal;
