import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { LocationFieldsFragmentDoc } from '../../fragments/locationFields.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type InsertLocationMutationVariables = Types.Exact<{
  location: Types.Location_Insert_Input;
}>;


export type InsertLocationMutation = { __typename?: 'mutation_root', insert_location?: { __typename?: 'location_mutation_response', returning: Array<{ __typename?: 'location', id: any, isActive: boolean, name: string, address?: string | null | undefined, phoneNumber?: string | null | undefined, email?: string | null | undefined, maxPatientsPerTimeslot?: number | null | undefined, workspaceId: any, clinicId?: number | null | undefined, addressId?: any | null | undefined, workStartTime?: string | null | undefined, workEndTime?: string | null | undefined, timezone?: string | null | undefined, payrixMerchantId?: string | null | undefined, finixMerchantId?: string | null | undefined, locationSchedules: Array<{ __typename?: 'locationSchedule', id: any, schedule: any, effectiveFrom?: any | null | undefined, createdAt: any, updatedAt: any, locationId: any }>, fullAddress?: { __typename?: 'address', addressLine1: string, addressLine2?: string | null | undefined, city: string, id: any, state: string, zipCode: string } | null | undefined, rooms: Array<{ __typename?: 'room', id: any, name: string }> }> } | null | undefined };


export const InsertLocationDocument = gql`
    mutation insertLocation($location: location_insert_input!) {
  insert_location(objects: [$location]) {
    returning {
      ...LocationFields
    }
  }
}
    ${LocationFieldsFragmentDoc}`;
export type InsertLocationMutationFn = Apollo.MutationFunction<InsertLocationMutation, InsertLocationMutationVariables>;

/**
 * __useInsertLocationMutation__
 *
 * To run a mutation, you first call `useInsertLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertLocationMutation, { data, loading, error }] = useInsertLocationMutation({
 *   variables: {
 *      location: // value for 'location'
 *   },
 * });
 */
export function useInsertLocationMutation(baseOptions?: Apollo.MutationHookOptions<InsertLocationMutation, InsertLocationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertLocationMutation, InsertLocationMutationVariables>(InsertLocationDocument, options);
      }
export type InsertLocationMutationHookResult = ReturnType<typeof useInsertLocationMutation>;
export type InsertLocationMutationResult = Apollo.MutationResult<InsertLocationMutation>;
export type InsertLocationMutationOptions = Apollo.BaseMutationOptions<InsertLocationMutation, InsertLocationMutationVariables>;