import * as Types from '../../generated/graphql';

import { gql } from '@apollo/client';
export type PatientSystemNoticeFieldsFragment = { __typename?: 'patientSystemNotice', id: any, description: string, category: string, subcategory: string, seen: boolean, createdAt: any };

export const PatientSystemNoticeFieldsFragmentDoc = gql`
    fragment PatientSystemNoticeFields on patientSystemNotice {
  id
  description
  category
  subcategory
  seen
  createdAt
}
    `;