import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type DeleteServiceCategoriesMutationVariables = Types.Exact<{
  ids: Array<Types.Scalars['uuid']> | Types.Scalars['uuid'];
}>;


export type DeleteServiceCategoriesMutation = { __typename?: 'mutation_root', delete_serviceCategory?: { __typename?: 'serviceCategory_mutation_response', affected_rows: number } | null | undefined };


export const DeleteServiceCategoriesDocument = gql`
    mutation DeleteServiceCategories($ids: [uuid!]!) {
  delete_serviceCategory(where: {id: {_in: $ids}}) {
    affected_rows
  }
}
    `;
export type DeleteServiceCategoriesMutationFn = Apollo.MutationFunction<DeleteServiceCategoriesMutation, DeleteServiceCategoriesMutationVariables>;

/**
 * __useDeleteServiceCategoriesMutation__
 *
 * To run a mutation, you first call `useDeleteServiceCategoriesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteServiceCategoriesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteServiceCategoriesMutation, { data, loading, error }] = useDeleteServiceCategoriesMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useDeleteServiceCategoriesMutation(baseOptions?: Apollo.MutationHookOptions<DeleteServiceCategoriesMutation, DeleteServiceCategoriesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteServiceCategoriesMutation, DeleteServiceCategoriesMutationVariables>(DeleteServiceCategoriesDocument, options);
      }
export type DeleteServiceCategoriesMutationHookResult = ReturnType<typeof useDeleteServiceCategoriesMutation>;
export type DeleteServiceCategoriesMutationResult = Apollo.MutationResult<DeleteServiceCategoriesMutation>;
export type DeleteServiceCategoriesMutationOptions = Apollo.BaseMutationOptions<DeleteServiceCategoriesMutation, DeleteServiceCategoriesMutationVariables>;