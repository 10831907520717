import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { CustomFormFieldsFragmentDoc } from '../../fragments/customFormFields.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetCustomFormsQueryVariables = Types.Exact<{
  where?: Types.Maybe<Types.CustomForm_Bool_Exp>;
  limit?: Types.Maybe<Types.Scalars['Int']>;
  offset?: Types.Maybe<Types.Scalars['Int']>;
  orderBy?: Types.Maybe<Array<Types.CustomForm_Order_By> | Types.CustomForm_Order_By>;
}>;


export type GetCustomFormsQuery = { __typename?: 'query_root', customForm: Array<{ __typename?: 'customForm', id: any, title: string, isRequired: boolean, allFieldsRequired: boolean, createdAt: any, recurrence?: string | null | undefined, language?: string | null | undefined, surveyJSJSON?: any | null | undefined, workspaceId: any, rows: Array<{ __typename?: 'customFormRow', id: any, index: number, replicable?: boolean | null | undefined, fields: Array<{ __typename?: 'customFormField', id: any, index: number, label: string, body?: string | null | undefined, category?: Types.CustomFormFieldCategory_Enum | null | undefined, type: Types.CustomFormFieldType_Enum, table?: string | null | undefined, attribute?: string | null | undefined, validation?: any | null | undefined, photoId?: any | null | undefined, row: { __typename?: 'customFormRow', id: any, index: number }, options: Array<{ __typename?: 'customFormOption', id: any, value: string }>, photo?: { __typename?: 'photo', file?: { __typename?: 'FilePayload', url: string } | null | undefined } | null | undefined }> }>, serviceCustomForms: Array<{ __typename?: 'serviceCustomForm', id: any, service: { __typename?: 'service', id: any, name: string } }> }> };


export const GetCustomFormsDocument = gql`
    query GetCustomForms($where: customForm_bool_exp, $limit: Int, $offset: Int, $orderBy: [customForm_order_by!]) {
  customForm(where: $where, limit: $limit, offset: $offset, order_by: $orderBy) {
    ...CustomFormFields
  }
}
    ${CustomFormFieldsFragmentDoc}`;

/**
 * __useGetCustomFormsQuery__
 *
 * To run a query within a React component, call `useGetCustomFormsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomFormsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomFormsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetCustomFormsQuery(baseOptions?: Apollo.QueryHookOptions<GetCustomFormsQuery, GetCustomFormsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCustomFormsQuery, GetCustomFormsQueryVariables>(GetCustomFormsDocument, options);
      }
export function useGetCustomFormsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCustomFormsQuery, GetCustomFormsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCustomFormsQuery, GetCustomFormsQueryVariables>(GetCustomFormsDocument, options);
        }
export type GetCustomFormsQueryHookResult = ReturnType<typeof useGetCustomFormsQuery>;
export type GetCustomFormsLazyQueryHookResult = ReturnType<typeof useGetCustomFormsLazyQuery>;
export type GetCustomFormsQueryResult = Apollo.QueryResult<GetCustomFormsQuery, GetCustomFormsQueryVariables>;