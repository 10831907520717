import * as Types from '../../generated/graphql';

import { gql } from '@apollo/client';
import { CouponFieldsFragmentDoc } from './couponFields.fragment.generated';
export type CouponWithConfigurationFragment = { __typename?: 'coupon', id: any, name: string, rule: string, amount: any, type: Types.CouponType_Enum, scope: Types.CouponScope_Enum, validFrom: any, validUntil: any, isActive: boolean, packageId?: any | null | undefined, isSystemGenerated: boolean, configuration?: { __typename?: 'couponConfiguration', maxApplication?: number | null | undefined } | null | undefined, locationCoupons: Array<{ __typename?: 'locationCoupon', location: { __typename?: 'location', id: any, isActive: boolean, name: string, address?: string | null | undefined, phoneNumber?: string | null | undefined, email?: string | null | undefined, maxPatientsPerTimeslot?: number | null | undefined, workspaceId: any, clinicId?: number | null | undefined, addressId?: any | null | undefined, workStartTime?: string | null | undefined, workEndTime?: string | null | undefined, timezone?: string | null | undefined, payrixMerchantId?: string | null | undefined, finixMerchantId?: string | null | undefined, locationSchedules: Array<{ __typename?: 'locationSchedule', id: any, schedule: any, effectiveFrom?: any | null | undefined, createdAt: any, updatedAt: any, locationId: any }>, fullAddress?: { __typename?: 'address', addressLine1: string, addressLine2?: string | null | undefined, city: string, id: any, state: string, zipCode: string } | null | undefined, rooms: Array<{ __typename?: 'room', id: any, name: string }> } }>, serviceCoupons: Array<{ __typename?: 'serviceCoupon', service: { __typename?: 'service', id: any, name: string } }> };

export const CouponWithConfigurationFragmentDoc = gql`
    fragment CouponWithConfiguration on coupon {
  ...CouponFields
  configuration {
    maxApplication
  }
}
    ${CouponFieldsFragmentDoc}`;