import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type UpdateLeadFormEntryMutationVariables = Types.Exact<{
  set?: Types.Maybe<Types.LeadFormEntry_Set_Input>;
  id: Types.Scalars['uuid'];
}>;


export type UpdateLeadFormEntryMutation = { __typename?: 'mutation_root', update_leadFormEntry_by_pk?: { __typename?: 'leadFormEntry', id: any } | null | undefined };


export const UpdateLeadFormEntryDocument = gql`
    mutation UpdateLeadFormEntry($set: leadFormEntry_set_input, $id: uuid!) {
  update_leadFormEntry_by_pk(_set: $set, pk_columns: {id: $id}) {
    id
  }
}
    `;
export type UpdateLeadFormEntryMutationFn = Apollo.MutationFunction<UpdateLeadFormEntryMutation, UpdateLeadFormEntryMutationVariables>;

/**
 * __useUpdateLeadFormEntryMutation__
 *
 * To run a mutation, you first call `useUpdateLeadFormEntryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLeadFormEntryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLeadFormEntryMutation, { data, loading, error }] = useUpdateLeadFormEntryMutation({
 *   variables: {
 *      set: // value for 'set'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateLeadFormEntryMutation(baseOptions?: Apollo.MutationHookOptions<UpdateLeadFormEntryMutation, UpdateLeadFormEntryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateLeadFormEntryMutation, UpdateLeadFormEntryMutationVariables>(UpdateLeadFormEntryDocument, options);
      }
export type UpdateLeadFormEntryMutationHookResult = ReturnType<typeof useUpdateLeadFormEntryMutation>;
export type UpdateLeadFormEntryMutationResult = Apollo.MutationResult<UpdateLeadFormEntryMutation>;
export type UpdateLeadFormEntryMutationOptions = Apollo.BaseMutationOptions<UpdateLeadFormEntryMutation, UpdateLeadFormEntryMutationVariables>;