import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { VitalsFieldsFragmentDoc } from '../../fragments/vitalsFields.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type InsertVitalsMutationVariables = Types.Exact<{
  vitals: Types.Vitals_Insert_Input;
}>;


export type InsertVitalsMutation = { __typename?: 'mutation_root', insert_vitals?: { __typename?: 'vitals_mutation_response', returning: Array<{ __typename?: 'vitals', id: any, temperature: any, pulse: number, bloodPressure?: any | null | undefined, patientWorkspaceId: any, createdAt: any, spo2?: any | null | undefined, updatedAt: any, time?: any | null | undefined }> } | null | undefined };


export const InsertVitalsDocument = gql`
    mutation insertVitals($vitals: vitals_insert_input!) {
  insert_vitals(objects: [$vitals]) {
    returning {
      ...VitalsFields
    }
  }
}
    ${VitalsFieldsFragmentDoc}`;
export type InsertVitalsMutationFn = Apollo.MutationFunction<InsertVitalsMutation, InsertVitalsMutationVariables>;

/**
 * __useInsertVitalsMutation__
 *
 * To run a mutation, you first call `useInsertVitalsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertVitalsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertVitalsMutation, { data, loading, error }] = useInsertVitalsMutation({
 *   variables: {
 *      vitals: // value for 'vitals'
 *   },
 * });
 */
export function useInsertVitalsMutation(baseOptions?: Apollo.MutationHookOptions<InsertVitalsMutation, InsertVitalsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertVitalsMutation, InsertVitalsMutationVariables>(InsertVitalsDocument, options);
      }
export type InsertVitalsMutationHookResult = ReturnType<typeof useInsertVitalsMutation>;
export type InsertVitalsMutationResult = Apollo.MutationResult<InsertVitalsMutation>;
export type InsertVitalsMutationOptions = Apollo.BaseMutationOptions<InsertVitalsMutation, InsertVitalsMutationVariables>;