/* eslint-disable camelcase */
import {
  PatientFlowsheetData_Updates,
  useUpdateManyPatientFlowsheetDataMutation,
} from '@webapp/graphql';
import { FieldValues } from 'react-hook-form';
import toast from 'react-hot-toast';

function useUpdateFlowsheetRowData(): [
  (values: FieldValues) => void,
  { isLoading: boolean }
] {
  const [updateManyPatientFlowsheetData, { loading }] =
    useUpdateManyPatientFlowsheetDataMutation({
      onCompleted: () => {
        toast.success('Row updated successfully');
      },
      onError: (error) => {
        toast.error(error.message);
      },
      refetchQueries: ['PatientFlowsheets'],
    });

  const updateFlowsheetRowData = ({
    flowsheetId,
    recordedAt,
    row,
    ...values
  }: FieldValues) => {
    const flowsheetParameterIds = Object.keys(values);

    const updateInputs: PatientFlowsheetData_Updates[] =
      flowsheetParameterIds.reduce((acc, flowsheetParameterId) => {
        if (values[flowsheetParameterId] !== undefined) {
          return [
            ...acc,
            {
              _set: {
                value: values[flowsheetParameterId],
              },
              where: {
                row: {
                  id: {
                    _eq: row.id,
                  },
                },
                flowsheetParameterId: {
                  _eq: flowsheetParameterId,
                },
              },
            },
          ];
        }

        return acc;
      }, [] as PatientFlowsheetData_Updates[]);

    updateManyPatientFlowsheetData({
      variables: {
        updates: updateInputs,
      },
    });
  };

  return [
    updateFlowsheetRowData,
    {
      isLoading: loading,
    },
  ];
}

export default useUpdateFlowsheetRowData;
