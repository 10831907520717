import {
  Avatar,
  Badge,
  Divider,
  Grid,
  HStack,
  SimpleGrid,
  Stack,
  Text,
} from '@chakra-ui/react';
import { Appointment } from '@webapp/state-models';
import { PatientStatusBadge, PatientIcons } from '@webapp/ui';
import { configuredDayjs as dayjs } from '@webapp/util-time';

export interface AppointmentListRowProps {
  appointment: Appointment;
  onClick: () => void;
  timezone: string;
}

export const AppointmentListRow = ({
  appointment,
  onClick,
  timezone,
}: AppointmentListRowProps) => (
  <Stack onClick={onClick} cursor="pointer" _hover={{ bg: 'gray.50' }}>
    <HStack>
      <Text w={24}>
        {dayjs(appointment.startTime).tz(timezone).format('hh:mm A z')}
      </Text>
      {appointment.status !== 'CANCELED' &&
        appointment.timeDayjs.isBefore(dayjs()) &&
        appointment.notes.length === 0 && (
          <Badge colorScheme={'red'}>Needs note</Badge>
        )}
      <Divider />
    </HStack>
    <SimpleGrid spacing={5} columns={5}>
      <HStack spacing={2} alignItems="flex-start">
        <Avatar
          name={appointment?.provider?.name}
          src={appointment?.provider?.profilePicture || undefined}
        />
        <Stack></Stack>
        <Grid>
          <Text color={'gray.600'}>{appointment.provider?.name}</Text>
          <PatientIcons
            appointment={appointment}
            iconContainerStyles={{
              size: 5,
            }}
          />
        </Grid>
      </HStack>
      <HStack spacing={5} alignItems="flex-start">
        <Avatar
          name={appointment?.patient?.name}
          src={
            appointment?.patient?.attributes?.profilePicture?.thumbnailUrl ||
            appointment?.patient?.attributes?.profilePicture?.url ||
            undefined
          }
        />
        <Stack>
          <Text fontWeight={'medium'} color={'gray.700'}>
            {appointment.patient?.name}
          </Text>
          <PatientStatusBadge
            status={appointment?.patient?.attributes?.status}
          />
        </Stack>
      </HStack>
      <Stack>
        <Text>{appointment?.patient?.attributes?.phoneNumber}</Text>
        <Text>
          DOB:{' '}
          {dayjs(appointment?.patient?.attributes?.dob).format('MM/DD/YYYY')}
        </Text>
      </Stack>
      <Stack>
        {appointment?.services?.map((appointmentService) => (
          <Text>
            {appointmentService?.service?.name} -{' '}
            {appointmentService?.provider?.firstName}{' '}
            {appointmentService?.provider?.lastName}
          </Text>
        ))}
      </Stack>
      <Stack>
        <Text>{appointment.timeDayjs.format('ll')}</Text>
        <Text id={'schedule-list-format-timing'}>
          {dayjs(appointment.startTime).tz(timezone).format('h:mm A')} {' - '}{' '}
          {dayjs(appointment.endTime).tz(timezone).format('h:mm A z')}
        </Text>
      </Stack>
    </SimpleGrid>
    {(appointment?.note || appointment?.patient?.attributes?.profileNote) && (
      <Text as="i">
        {appointment?.note} - {appointment?.patient?.attributes?.profileNote}
      </Text>
    )}
  </Stack>
);

export default AppointmentListRow;
