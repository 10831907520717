import React, { useState, KeyboardEvent } from 'react';
import {
  Box,
  Flex,
  VStack,
  PinInput,
  PinInputField,
  HStack,
  Text,
  Button,
  useToast,
  keyframes,
  usePrefersReducedMotion,
} from '@chakra-ui/react';
import { useVerifyPinMutation } from '@webapp/graphql';
import { useStores } from '@webapp/state-models';
import { observer } from 'mobx-react-lite';
import { useLocation } from 'react-router-dom';

interface GlobalPinLockProps {
  onUnlock: () => void;
}

export const GlobalPinLock: React.FC<GlobalPinLockProps> = ({ onUnlock }) => {
  const [pin, setPin] = useState('');
  const toast = useToast();
  const { ui } = useStores();
  const location = useLocation();
  const [isShaking, setIsShaking] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const prefersReducedMotion = usePrefersReducedMotion();

  const isLocked = ui.globalPinLock;

  const shakeAnimation = keyframes`
    0% { transform: translateX(0); }
    25% { transform: translateX(-10px); }
    50% { transform: translateX(10px); }
    75% { transform: translateX(-10px); }
    100% { transform: translateX(0); }
  `;

  const [verify] = useVerifyPinMutation({
    onCompleted: () => {
      onUnlock?.();
      ui.setGlobalPinLock(false);
      setPin('');
      setErrorMessage('');
      toast({
        title: 'Unlocked',
        status: 'success',
        duration: 2000,
        isClosable: true,
        position: 'top',
      });
    },
    onError: (error) => {
      setIsShaking(true);
      setErrorMessage('Incorrect PIN. Please try again.');
      setTimeout(() => setIsShaking(false), 500);
      toast({
        title: 'Error unlocking, please double check your pin.',
        description: error.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'top',
      });
      setPin('');
    },
  });

  const handleVerify = async (): Promise<void> => {
    await verify({ variables: { value: pin } });
  };

  const shouldShowLock = (): boolean => {
    const path = location.pathname.toLowerCase();
    return !path.includes('consents') && !path.includes('forms');
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>): void => {
    if (event.key === 'Enter' && pin.length === 5) {
      handleVerify();
    }
  };

  if (!isLocked || !shouldShowLock()) return null;

  return (
    <Box
      id="global-pin-lock"
      position="fixed"
      top={0}
      left={0}
      right={0}
      bottom={0}
      bg="rgba(0, 0, 0, 0.7)"
      backdropFilter="blur(5px)"
      zIndex={9000}
    >
      <Flex
        height="100%"
        alignItems="center"
        justifyContent="center"
        animation={
          isShaking && !prefersReducedMotion
            ? `${shakeAnimation} 0.5s ease-in-out`
            : undefined
        }
      >
        <VStack
          bg="white"
          p={8}
          borderRadius="md"
          spacing={6}
          align="center"
          boxShadow="xl"
          w="lg"
          onKeyDown={handleKeyDown}
        >
          <Text fontSize="xl" fontWeight="bold">
            Enter Practice PIN to Unlock
          </Text>
          <Text>
            Please hand tablet back to practice staff member to unlock.
          </Text>
          <HStack>
            <PinInput
              onComplete={setPin}
              value={pin}
              onChange={setPin}
              autoFocus
            >
              <PinInputField />
              <PinInputField />
              <PinInputField />
              <PinInputField />
              <PinInputField />
            </PinInput>
          </HStack>
          {errorMessage && (
            <Text color="red.500" fontSize="sm">
              {errorMessage}
            </Text>
          )}
          <Button
            colorScheme="teal"
            onClick={handleVerify}
            isDisabled={pin.length !== 5}
          >
            Unlock
          </Button>
        </VStack>
      </Flex>
    </Box>
  );
};

export default observer(GlobalPinLock);
