/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable import/order */
import { Button, Flex, Stack, Text } from '@chakra-ui/react';
import {
  useSubscribePatientWalletCouponSubscription,
  useUpdatePatientWalletCouponsMutation,
} from '@webapp/graphql';
import { MinimalPatientWallet } from '@webapp/types';
import { DataTable, HookForm, TruncatedCell } from '@webapp/ui';
import { useEffect, useMemo, useState } from 'react';
import { Column } from 'react-table';
import toast from 'react-hot-toast';
import { useStores } from '@webapp/state-models';
import { ADMIN, OWNER } from '@webapp/constants';
import { CouponQuantity, returnCouponQuantity } from '../util';
import EditablePatientWalletCouponsTable, {
  CouponsFormValues,
} from './editable-patient-wallet-coupons-table';
import { observer } from 'mobx-react-lite';
import { useCustomPermissions } from '@webapp/hooks';

interface PatientWalletCouponsTableProps {
  patientWallet: MinimalPatientWallet;
}

export const PatientWalletCouponsTable = observer(
  ({ patientWallet }: PatientWalletCouponsTableProps): JSX.Element => {
    const { user } = useStores();
    const { hasPermission } = useCustomPermissions();

    const { data: patientWalletCouponData, loading } =
      useSubscribePatientWalletCouponSubscription({
        variables: {
          where: {
            patientWalletId: {
              _eq: patientWallet.id,
            },
            used: {
              _eq: false,
            },
            archived: {
              _eq: false,
            },
          },
        },
      });

    const couponQuantity = useMemo(() => {
      const subData = patientWalletCouponData?.patientWalletCoupon;

      if (subData) {
        return returnCouponQuantity(subData);
      }

      return returnCouponQuantity(patientWallet.patientWalletCoupons);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [patientWalletCouponData]);

    const [isEditing, setIsEditing] = useState(false);

    const toggleEdit = (): void => {
      setIsEditing(!isEditing);
    };

    const [stateCouponQuantity, setStateCouponQuantity] =
      useState(couponQuantity);

    useEffect(() => {
      setStateCouponQuantity(couponQuantity);
    }, [couponQuantity]);

    const [updatePatientWalletCoupons] = useUpdatePatientWalletCouponsMutation({
      onCompleted: (response) => {
        const newCoupons = response.updatePatientWalletCoupons.coupons;
        setStateCouponQuantity(newCoupons);
        setIsEditing(false);
      },
      onError: (error) => {
        toast.error(error.message);
      },
    });

    const handleEdit = ({ coupons: formCoupons }: CouponsFormValues): void => {
      const mapped = formCoupons.map((coupon) => ({
        couponId: coupon.value,
        quantity: coupon.object.quantity,
      }));

      updatePatientWalletCoupons({
        variables: {
          object: {
            patientWalletId: patientWallet.id,
            coupons: mapped,
          },
        },
      });
    };

    const COLUMNS: Column<CouponQuantity>[] = [
      {
        Header: 'Coupon',
        accessor: 'name',
        disableSortBy: true,
        width: 200,

        defaultCanSort: false,
        Cell: ({ row }) => (
          <TruncatedCell maxLength={30} value={row.original.name} />
        ),
      },
      {
        Header: 'Quantity',
        accessor: 'quantity',
        disableSortBy: true,
        defaultCanSort: false,
        width: 70,
      },
    ];

    return (
      <Stack>
        <Text
          color="#525257"
          fontSize="16px"
          fontWeight="bold"
          letterSpacing="0.01em"
          mb={4}
        >
          Available Coupons
        </Text>
        {[OWNER, ADMIN].includes(user?.role as string) &&
          hasPermission('editPatientWallet') && (
            <Flex w="full" justifyContent={'flex-end'}>
              <Button onClick={toggleEdit}>Edit</Button>
            </Flex>
          )}
        {isEditing ? (
          <HookForm
            defaultValues={{
              coupons: stateCouponQuantity.map((coupon) => ({
                label: coupon.name,
                value: coupon.id,
                object: {
                  ...coupon,
                  quantity: coupon.quantity,
                },
              })),
            }}
          >
            <EditablePatientWalletCouponsTable handleEdit={handleEdit} />
          </HookForm>
        ) : (
          <>
            {loading ? (
              <Text>Loading...</Text>
            ) : (
              <DataTable<any>
                customStyles={{
                  borderColor: '#CFEBFF',
                  cell: {
                    color: '#6C6C72',
                  },
                  table: {
                    borderRadius: '8px',
                    minHeight: 'unset',
                  },
                  tableHeader: {
                    background: '#EAFCFF',
                    color: '#525257',
                    textTransform: 'uppercase',
                  },
                }}
                columns={COLUMNS}
                data={stateCouponQuantity}
              />
            )}
          </>
        )}
      </Stack>
    );
  }
);

export default PatientWalletCouponsTable;
