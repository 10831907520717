import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { NoteFieldsFragmentDoc } from '../../fragments/noteFields.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type InsertNoteMutationVariables = Types.Exact<{
  notes: Array<Types.Note_Insert_Input> | Types.Note_Insert_Input;
}>;


export type InsertNoteMutation = { __typename?: 'mutation_root', insert_note?: { __typename?: 'note_mutation_response', returning: Array<{ __typename?: 'note', isLocked?: boolean | null | undefined, createdAt: any, title?: string | null | undefined, isGoodFaithExam?: boolean | null | undefined, id: any, isInternal: boolean, message: string, appointmentId?: any | null | undefined, updatedAt: any, author: { __typename?: 'user', firstName: string, id: any, lastName: string, title?: string | null | undefined, profilePicture?: string | null | undefined }, editedBy?: { __typename?: 'user', firstName: string, id: any, lastName: string, title?: string | null | undefined } | null | undefined, noteSignatures: Array<{ __typename?: 'noteSignature', signature?: { __typename?: 'signature', id: any, filePath: string, provider?: { __typename?: 'provider', firstName: string, id: any, lastName: string, title?: string | null | undefined } | null | undefined, file?: { __typename?: 'FileOnlyURL', url: string } | null | undefined } | null | undefined }>, assignedToProvider?: { __typename?: 'provider', id: any, firstName: string, lastName: string, title?: string | null | undefined } | null | undefined, patient: { __typename?: 'patient', firstName: string, id: any, lastName: string } }> } | null | undefined };


export const InsertNoteDocument = gql`
    mutation InsertNote($notes: [note_insert_input!]!) {
  insert_note(objects: $notes) {
    returning {
      ...NoteFields
    }
  }
}
    ${NoteFieldsFragmentDoc}`;
export type InsertNoteMutationFn = Apollo.MutationFunction<InsertNoteMutation, InsertNoteMutationVariables>;

/**
 * __useInsertNoteMutation__
 *
 * To run a mutation, you first call `useInsertNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertNoteMutation, { data, loading, error }] = useInsertNoteMutation({
 *   variables: {
 *      notes: // value for 'notes'
 *   },
 * });
 */
export function useInsertNoteMutation(baseOptions?: Apollo.MutationHookOptions<InsertNoteMutation, InsertNoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertNoteMutation, InsertNoteMutationVariables>(InsertNoteDocument, options);
      }
export type InsertNoteMutationHookResult = ReturnType<typeof useInsertNoteMutation>;
export type InsertNoteMutationResult = Apollo.MutationResult<InsertNoteMutation>;
export type InsertNoteMutationOptions = Apollo.BaseMutationOptions<InsertNoteMutation, InsertNoteMutationVariables>;