import { HStack, Icon, Tooltip } from '@chakra-ui/react';
import {
  FaCamera,
  FaCheckCircle,
  FaFileSignature,
  FaFileMedical,
} from 'react-icons/fa';
import { MdInventory2 } from 'react-icons/md';
import dayjs from 'dayjs';
import { useFlagsmith } from 'flagsmith-react';

interface AppointmentStatusIconsProps {
  alignment: string;
  appointmentStatus: string;
  consumablesRecorded: boolean;
  hasPhotos: boolean;
  intakeStatus: string;
  notesSigned: boolean;
  startTime: string;
}

export const AppointmentStatusIcons = ({
  alignment = 'right',
  appointmentStatus,
  consumablesRecorded,
  hasPhotos,
  intakeStatus,
  notesSigned,
  startTime,
}: AppointmentStatusIconsProps) => {
  const { hasFeature } = useFlagsmith();
  const currentTime = dayjs();
  const targetTime = dayjs(startTime);
  const isPreAappointment = currentTime.isBefore(targetTime);

  const isConfirmed = appointmentStatus === 'CONFIRMED';
  const intakeColors = {
    NOT_REQUIRED: 'grey',
    COMPLETE: 'green',
    INCOMPLETE: 'red',
  };

  const flexDirection = alignment === 'right' ? 'row-reverse' : 'row';

  return (
    <>
      {hasFeature('appointment:status-icons') && (
        <HStack
          id="icons"
          fontSize="xs"
          my={1}
          flexDirection={flexDirection}
          position="relative"
        >
          {isPreAappointment ? (
            <>
              {isConfirmed && <Icon as={FaCheckCircle} color="green" />}
              <Icon as={FaFileMedical} color={intakeColors[intakeStatus]} />
            </>
          ) : (
            <>
              {hasPhotos && <Icon as={FaCamera} color="green" />}
              {consumablesRecorded && <Icon as={MdInventory2} color="green" />}
              {notesSigned && <Icon as={FaFileSignature} color="green" />}
            </>
          )}
        </HStack>
      )}
    </>
  );
};

export default AppointmentStatusIcons;
