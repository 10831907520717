import {
  Stack,
  Skeleton,
  FormLabel,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
} from '@chakra-ui/react';
import { useState } from 'react';
import { AgGrid, BackButton, FormInputV2 } from '@webapp/ui';
import { usePatientFlowsheets } from '@webapp/hooks';
import { FieldValues } from 'react-hook-form';
import SelectExistingFlowsheetData from './SelectExistingFlowsheetData';
import InsertFlowsheetDataSmartNote from './InsertFlowsheetDataSmartNote';

export interface FlowsheetSmartNoteModalBodyProps {
  patientId: string;
  editorReference: any;
  onClose: () => void;
}
export default function FlowsheetSmartNoteModalBody({
  patientId,
  editorReference,
  onClose,
}: FlowsheetSmartNoteModalBodyProps) {
  const {
    flowsheets,
    insertRow,
    isLoading,
    selectFlowsheet,
    selectedFlowsheet,
    updateData,
    deleteRows,
    insertRowData,
    insertRowLoading,
    getFlowsheets,
  } = usePatientFlowsheets({
    patientId,
  });
  const [tabIndex, setTabIndex] = useState(0);

  function handleNewFlowsheetRow(data: FieldValues) {
    insertRow(data);
    getFlowsheets();
    setTabIndex(0);
  }

  return (
    <Skeleton isLoaded={!isLoading}>
      <Stack>
        <FormLabel htmlFor="flowsheet">Select Flowsheet</FormLabel>
        <FormInputV2
          uncontrolled
          onChange={(flowsheetOption: any) =>
            selectFlowsheet(flowsheetOption.object)
          }
          options={flowsheets?.map((flowsheet) => ({
            label: flowsheet.title,
            object: flowsheet,
            value: flowsheet.id,
          }))}
          name="flowsheet"
          styles={{
            control: {
              maxWidth: '300px',
              minWidth: '300px',
            },
          }}
          type="select"
          value={{
            label: selectedFlowsheet?.title,
            value: selectedFlowsheet?.id,
          }}
        />
        <Tabs onChange={(index) => setTabIndex(index)} index={tabIndex}>
          <TabList>
            <Tab>Select Existing Data</Tab>
            <Tab>Enter New Data</Tab>
          </TabList>

          <TabPanels>
            <TabPanel>
              {selectedFlowsheet && (
                <SelectExistingFlowsheetData
                  key={selectedFlowsheet?.id}
                  flowsheet={selectedFlowsheet}
                  insertRow={insertRow}
                  updateData={updateData}
                  deleteRows={deleteRows}
                  insertRowData={insertRowData}
                  insertRowLoading={insertRowLoading}
                  isLoading={isLoading}
                  editorReference={editorReference}
                  onClose={onClose}
                />
              )}
            </TabPanel>
            <TabPanel>
              {selectedFlowsheet?.gridData?.colDefs && (
                <InsertFlowsheetDataSmartNote
                  colDefs={selectedFlowsheet?.gridData?.colDefs}
                  onSubmit={handleNewFlowsheetRow}
                />
              )}
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Stack>
    </Skeleton>
  );
}
