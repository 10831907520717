import * as Types from '../../generated/graphql';

import { gql } from '@apollo/client';
import { MembershipFieldsFragmentDoc } from './membershipFields.fragment.generated';
import { PaymentFieldsFragmentDoc } from './paymentFields.fragment.generated';
export type PatientMembershipFieldsFragment = { __typename?: 'patientMembership', id: any, status: string, createdAt: any, updatedAt: any, nextPaymentDate?: any | null | undefined, subscriptionEndDate?: any | null | undefined, membership: { __typename?: 'membership', id: any, name: string, type: Types.MembershipType_Enum, price: number, taxRate: any, description?: string | null | undefined, image?: string | null | undefined, availableInStore?: boolean | null | undefined, discount: number, billingPeriodInDays: number, maxBillingCycles?: number | null | undefined, billingDayOfMonth?: number | null | undefined, createdAt: any, updatedAt: any, locationId?: any | null | undefined, workspaceId: any, color: string, hasCoupons: boolean, hasCredits: boolean, credits: number, archived: boolean, location?: { __typename?: 'location', id: any, name: string } | null | undefined, membershipCoupons: Array<{ __typename?: 'membershipCoupon', count: number, coupon: { __typename?: 'coupon', id: any, name: string } }> }, payments: Array<{ __typename?: 'payment', createdAt: any, id: any, workspaceId: any, paymentMethodId?: any | null | undefined, paymentMethodType: string, payrixType?: string | null | undefined, amount: number, invoiceId?: any | null | undefined, status: string, type?: string | null | undefined }> };

export const PatientMembershipFieldsFragmentDoc = gql`
    fragment PatientMembershipFields on patientMembership {
  id
  status
  createdAt
  updatedAt
  nextPaymentDate
  subscriptionEndDate
  membership {
    ...MembershipFields
  }
  payments {
    ...PaymentFields
  }
}
    ${MembershipFieldsFragmentDoc}
${PaymentFieldsFragmentDoc}`;