import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type InsertLeadFormEntryMutationVariables = Types.Exact<{
  object: Types.LeadFormEntry_Insert_Input;
}>;


export type InsertLeadFormEntryMutation = { __typename?: 'mutation_root', insert_leadFormEntry_one?: { __typename?: 'leadFormEntry', patientId: any } | null | undefined };


export const InsertLeadFormEntryDocument = gql`
    mutation insertLeadFormEntry($object: leadFormEntry_insert_input!) {
  insert_leadFormEntry_one(object: $object) {
    patientId
  }
}
    `;
export type InsertLeadFormEntryMutationFn = Apollo.MutationFunction<InsertLeadFormEntryMutation, InsertLeadFormEntryMutationVariables>;

/**
 * __useInsertLeadFormEntryMutation__
 *
 * To run a mutation, you first call `useInsertLeadFormEntryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertLeadFormEntryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertLeadFormEntryMutation, { data, loading, error }] = useInsertLeadFormEntryMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertLeadFormEntryMutation(baseOptions?: Apollo.MutationHookOptions<InsertLeadFormEntryMutation, InsertLeadFormEntryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertLeadFormEntryMutation, InsertLeadFormEntryMutationVariables>(InsertLeadFormEntryDocument, options);
      }
export type InsertLeadFormEntryMutationHookResult = ReturnType<typeof useInsertLeadFormEntryMutation>;
export type InsertLeadFormEntryMutationResult = Apollo.MutationResult<InsertLeadFormEntryMutation>;
export type InsertLeadFormEntryMutationOptions = Apollo.BaseMutationOptions<InsertLeadFormEntryMutation, InsertLeadFormEntryMutationVariables>;