/* eslint-disable prefer-arrow-callback */
/* eslint-disable arrow-body-style */
import { useEffect, useState } from 'react';
import {
  Patient,
  PatientSearchFieldsFragment,
  useSearchPatientWorkspaceLazyQuery,
  SearchPatientWorkspaceQueryResult,
  PatientTableFieldsFragment,
} from '@webapp/graphql';
import Async from 'react-select/async';
import { components, MenuProps } from 'react-select';
import { Button, Box, useBreakpointValue } from '@chakra-ui/react';
import { FaPlus } from 'react-icons/fa';
import { observer } from 'mobx-react-lite';
import { useDebounce } from 'use-debounce';
import { useStores } from '@webapp/state-models';

export interface PatientOption {
  readonly value: string;
  readonly label: string;
  patient: PatientTableFieldsFragment;
}
export interface GroupedOption {
  readonly label: string;
  readonly options: readonly PatientOption[];
}
/* eslint-disable-next-line */
export interface PatientSearchDropdownProps {
  onCreatePatient?: () => void;
  onSelectPatient: (patient: PatientSearchFieldsFragment) => void;
  selectedPatient?: PatientSearchFieldsFragment;
  creatable?: boolean;
  placeholder?: string;
  onClear?: () => void;
}

export const PatientSearchDropdown = observer(
  ({
    onCreatePatient,
    onSelectPatient,
    selectedPatient,
    creatable = true,
    placeholder,
    onClear,
  }: PatientSearchDropdownProps) => {
    const { workspace } = useStores();
    const [searchTerm, setSearchTerm] = useState('');
    const [debouncedSearchTerm] = useDebounce(searchTerm, 300);
    const isMobileOrTablet = useBreakpointValue({
      base: true,
      md: true,
      lg: true,
      xl: false,
    });
    
    const [searchPatient, { loading }] = useSearchPatientWorkspaceLazyQuery({
      fetchPolicy: 'cache-and-network',
    });

    useEffect(() => {
      searchPatient({
        variables: {
          search: `${debouncedSearchTerm}`,
          workspaceId: workspace?.id,
        },
      });
    }, [debouncedSearchTerm, searchPatient]);

    const promiseOptions = async (
      inputValue: string,
      callback: (options: PatientOption[]) => void
    ) =>
      new Promise((resolve) => {
        searchPatient({
          variables: { search: `${inputValue}`, workspaceId: workspace?.id },
        }).then(({ data }: SearchPatientWorkspaceQueryResult) =>
          resolve(
            data?.search_patientWorkspace?.map((patient) => ({
              value: patient.id,
              label: `${patient.attributes.firstName}  ${
                patient.attributes.middleName
                  ? ` ${patient.attributes.middleName.charAt(0)}.`
                  : ''
              } ${patient.attributes.lastName} ${
                patient.attributes?.dob
                  ? `| DOB: ${patient.attributes.dob}`
                  : ''
              }`,
              patient,
            })) as PatientOption[]
          )
        );
      });

    const handleOnChange = (a: any, b: any) => {
      switch (b.action) {
        case 'clear':
          if (onClear) onClear();
          break;
        case 'remove-value':
          if (onClear) onClear();
          break;
        default:
          onSelectPatient(a?.patient as Patient);
          break;
      }
    };

    const Menu = (props: MenuProps<PatientOption, false, GroupedOption>) => (
      <components.Menu<PatientOption, false, GroupedOption> {...props}>
        {props.children}

        {creatable && !isMobileOrTablet && (
          <Button
            data-test-id="create-patient"
            onClick={onCreatePatient}
            w={'full'}
            leftIcon={<FaPlus />}
            variant={'ghost'}
          >
            Create new patient
          </Button>
        )}
      </components.Menu>
    );

    return (
      <Box minW={200}>
        <Async
          data-testid="patient-search-dropdown"
          inputId={'select-patient'}
          isClearable
          defaultValue={
            selectedPatient
              ? {
                  label: `${
                    selectedPatient.attributes?.firstName ??
                    selectedPatient.firstName
                  } ${
                    selectedPatient.attributes?.middleName
                      ? `${selectedPatient.attributes?.middleName[0]}. `
                      : ''
                  }${
                    selectedPatient.attributes?.lastName ??
                    selectedPatient.lastName
                  }`,
                  value: selectedPatient.id,
                  patient: selectedPatient,
                }
              : null
          }
          defaultOptions
          loadOptions={promiseOptions}
          isLoading={loading}
          onInputChange={setSearchTerm}
          onChange={handleOnChange}
          placeholder={placeholder || 'Select a Patient'}
          components={{ Menu }}
        />
      </Box>
    );
  }
);

export default PatientSearchDropdown;
