import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { ExternalReviewFieldsFragmentDoc } from '../../fragments/externalReviewFields.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type SubscribeExternalReviewsSubscriptionVariables = Types.Exact<{
  limit: Types.Scalars['Int'];
  offset?: Types.Maybe<Types.Scalars['Int']>;
  orderBy?: Types.Maybe<Array<Types.ExternalReview_Order_By> | Types.ExternalReview_Order_By>;
  where?: Types.Maybe<Types.ExternalReview_Bool_Exp>;
}>;


export type SubscribeExternalReviewsSubscription = { __typename?: 'subscription_root', externalReview: Array<{ __typename?: 'externalReview', comment: string, externalId: string, externalSource: string, id: any, rating?: number | null | undefined, response?: string | null | undefined, reviewDate: any, reviewerName: string, reviewerPhotoUrl?: string | null | undefined, location?: { __typename?: 'location', id: any, name: string } | null | undefined, provider?: { __typename?: 'provider', firstName: string, id: any, lastName: string, title?: string | null | undefined } | null | undefined }> };


export const SubscribeExternalReviewsDocument = gql`
    subscription SubscribeExternalReviews($limit: Int!, $offset: Int, $orderBy: [externalReview_order_by!], $where: externalReview_bool_exp) {
  externalReview(
    limit: $limit
    offset: $offset
    order_by: $orderBy
    where: $where
  ) {
    ...ExternalReviewFields
  }
}
    ${ExternalReviewFieldsFragmentDoc}`;

/**
 * __useSubscribeExternalReviewsSubscription__
 *
 * To run a query within a React component, call `useSubscribeExternalReviewsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeExternalReviewsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeExternalReviewsSubscription({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      orderBy: // value for 'orderBy'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useSubscribeExternalReviewsSubscription(baseOptions: Apollo.SubscriptionHookOptions<SubscribeExternalReviewsSubscription, SubscribeExternalReviewsSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<SubscribeExternalReviewsSubscription, SubscribeExternalReviewsSubscriptionVariables>(SubscribeExternalReviewsDocument, options);
      }
export type SubscribeExternalReviewsSubscriptionHookResult = ReturnType<typeof useSubscribeExternalReviewsSubscription>;
export type SubscribeExternalReviewsSubscriptionResult = Apollo.SubscriptionResult<SubscribeExternalReviewsSubscription>;