import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { AppointmentTypeFieldsFragmentDoc } from '../../fragments/appointmentTypeFields.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetAppointmentTypesQueryVariables = Types.Exact<{
  where?: Types.Maybe<Types.AppointmentType_Bool_Exp>;
  limit?: Types.Maybe<Types.Scalars['Int']>;
  offset?: Types.Maybe<Types.Scalars['Int']>;
  orderBy?: Types.Maybe<Array<Types.AppointmentType_Order_By> | Types.AppointmentType_Order_By>;
}>;


export type GetAppointmentTypesQuery = { __typename?: 'query_root', appointmentType: Array<{ __typename?: 'appointmentType', id: any, name: string, color?: string | null | undefined, maxPerDay?: number | null | undefined }> };


export const GetAppointmentTypesDocument = gql`
    query GetAppointmentTypes($where: appointmentType_bool_exp, $limit: Int, $offset: Int, $orderBy: [appointmentType_order_by!]) {
  appointmentType(
    where: $where
    limit: $limit
    offset: $offset
    order_by: $orderBy
  ) {
    ...AppointmentTypeFields
  }
}
    ${AppointmentTypeFieldsFragmentDoc}`;

/**
 * __useGetAppointmentTypesQuery__
 *
 * To run a query within a React component, call `useGetAppointmentTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAppointmentTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAppointmentTypesQuery({
 *   variables: {
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetAppointmentTypesQuery(baseOptions?: Apollo.QueryHookOptions<GetAppointmentTypesQuery, GetAppointmentTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAppointmentTypesQuery, GetAppointmentTypesQueryVariables>(GetAppointmentTypesDocument, options);
      }
export function useGetAppointmentTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAppointmentTypesQuery, GetAppointmentTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAppointmentTypesQuery, GetAppointmentTypesQueryVariables>(GetAppointmentTypesDocument, options);
        }
export type GetAppointmentTypesQueryHookResult = ReturnType<typeof useGetAppointmentTypesQuery>;
export type GetAppointmentTypesLazyQueryHookResult = ReturnType<typeof useGetAppointmentTypesLazyQuery>;
export type GetAppointmentTypesQueryResult = Apollo.QueryResult<GetAppointmentTypesQuery, GetAppointmentTypesQueryVariables>;