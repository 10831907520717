import { Box, Spinner, Stack } from '@chakra-ui/react';
import { DEFAULT_LABEL_STYLE } from '@webapp/constants';
import { useGetAppointmentServiceAvailabilityLazyQuery } from '@webapp/graphql';
import dayjs from 'dayjs';
import { groupBy } from 'lodash';
import { useEffect, useState } from 'react';
import { AvailabilityGroups, DatetimeStyles } from '../types';
import Suggestions from './Suggestions';

/* eslint-disable-next-line */
export interface DatetimeSuggestProps {
  customStyles?: DatetimeStyles;
  hasLeadFormLink: boolean;
  locationId: string;
  onTimeChange: (data: any) => void;
  preferredDate: any;
  providerId: string;
  serviceIds: string[];
  startTime: any;
  timezone?: string;
}

const DEFAULT_STYLES: DatetimeStyles = {
  changeSelectionText: {
    color: 'blue.600',
    fontSize: '12px',
    fontWeight: 'bold',
    letterSpacing: '0.03em',
  },
  label: DEFAULT_LABEL_STYLE,
  suggestedTimesHeading: {
    color: 'brand.primary',
    ...DEFAULT_LABEL_STYLE,
    margin: '0 0 8px',
  },
  valueText: {
    color: 'gray.700',
    fontSize: '14px',
    letterSpacing: '0.03em',
  },
};

/* 
    Note: If you are to refactor this component, speak with Pedro first

    Specifically, extracting specific appointment availability logic and transforming to 
    a generic hook based availability handling is possible and if needed in the future
    there are ways to accomplish this without making the component more complex
*/
export function DatetimeSuggest({
  customStyles = {},
  locationId,
  onTimeChange,
  preferredDate,
  providerId,
  serviceIds,
  startTime,
  timezone,
}: DatetimeSuggestProps) {
  const populatedStyles = { ...DEFAULT_STYLES, ...customStyles };

  const [suggestedTimeGroups, setSuggestedTimeGroups] =
    useState<AvailabilityGroups>({});

  const [
    getAppointmentAvailability,
    { data: appointmentAvailability, loading: appointmentAvailabilityLoading },
  ] = useGetAppointmentServiceAvailabilityLazyQuery();

  useEffect(() => {
    if (!appointmentAvailability) return;

    // Leaving this as any on purpose, because its not in it's final shape
    const groupedByDay: any = groupBy(
      appointmentAvailability.getAvailabilityForService,
      'formattedDay'
    );

    Object.keys(groupedByDay).forEach((groupedDay) => {
      groupedByDay[groupedDay] = groupBy(groupedByDay[groupedDay], 'start'); // <- final shape
    });

    setSuggestedTimeGroups(groupedByDay);
  }, [appointmentAvailability]);

  useEffect(() => {
    if (!locationId || !serviceIds || !preferredDate || startTime) return;

    getAppointmentAvailability({
      variables: {
        providerId,
        locationId,
        serviceIds,
        day: dayjs(preferredDate).format('YYYY-MM-DD'),
        numberOfDays: 1,
      },
    });
  }, [
    getAppointmentAvailability,
    providerId,
    serviceIds,
    startTime,
    preferredDate,
    locationId,
  ]);

  return (
    <Stack justifyContent="flex-start">
      <Box>
        {appointmentAvailabilityLoading && <Spinner />}
        {!appointmentAvailabilityLoading && (
          <Suggestions
            customStyles={populatedStyles}
            onTimeChange={onTimeChange}
            preferredDate={preferredDate}
            serviceIds={serviceIds}
            startTime={startTime}
            suggestedTimeGroups={suggestedTimeGroups}
            timezone={timezone}
          />
        )}
      </Box>
    </Stack>
  );
}

export default DatetimeSuggest;
