/* eslint-disable camelcase */
import {
  Service,
  SearchServicesDocument,
  SearchServicesQueryResult,
  GetServicesExternalDocument,
  Service_Bool_Exp,
  GetServicesExternalQueryResult,
} from '@webapp/graphql';
import { ObjectOption } from '@webapp/types';
import { useState } from 'react';
import { groupBy, orderBy } from 'lodash';
import { useApolloClient } from '@apollo/client';

export interface UseLoadServicesExternal {
  called: boolean;
  loading: boolean;
  search: (searchValue: string) => Promise<ObjectOption<Service>[]>;
}

export interface UseLoadServicesExternalProps {
  groupByCategory?: boolean;
  showProducts?: boolean;
  where?: Service_Bool_Exp;
}

export function useLoadServicesExternal({
  groupByCategory,
  showProducts = false,
  where,
}: UseLoadServicesExternalProps = {}): UseLoadServicesExternal {
  const [called, setCalled] = useState(false);

  const [loading, setLoading] = useState(false);
  const client = useApolloClient();

  return {
    called,
    loading,
    search: async (searchValue: string) => {
      setCalled(true);
      setLoading(true);
      const result = await client.query<GetServicesExternalQueryResult['data']>(
        {
          query: GetServicesExternalDocument,
          variables: {
            where: {
              name: { _ilike: `%${searchValue}%` },
              active: { _eq: true },
              ...(showProducts ? {} : { isProduct: { _eq: false } }),
              ...(where ?? {}),
            },
          },
        }
      );
      setLoading(false);

      const services = result.data?.service ?? [];
      const groupedServices = Object.entries(
        groupBy(
          services.map(
            (service): ObjectOption<Service> => ({
              label: service.name,
              value: service.id,
              object: service as Service,
            })
          ),
          'object.serviceCategory.title'
        )
      );

      if (groupByCategory && groupedServices.length > 1) {
        return groupedServices.map(([category, options]) => ({
          label:
            category.toLowerCase() === 'undefined' ? 'Uncategorized' : category,
          options: orderBy(options, 'label'),
        }));
      }
      return orderBy(services, 'label').map(
        (service): ObjectOption<Service> => ({
          label: service.name,
          value: service.id,
          object: service as Service,
        })
      );
    },
  };
}

export default useLoadServicesExternal;
