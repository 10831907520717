import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type InsertNoteSignatureMutationVariables = Types.Exact<{
  noteSignature: Types.NoteSignature_Insert_Input;
}>;


export type InsertNoteSignatureMutation = { __typename?: 'mutation_root', insert_noteSignature_one?: { __typename?: 'noteSignature', signatureId: any, noteId: any } | null | undefined };


export const InsertNoteSignatureDocument = gql`
    mutation InsertNoteSignature($noteSignature: noteSignature_insert_input!) {
  insert_noteSignature_one(object: $noteSignature) {
    signatureId
    noteId
  }
}
    `;
export type InsertNoteSignatureMutationFn = Apollo.MutationFunction<InsertNoteSignatureMutation, InsertNoteSignatureMutationVariables>;

/**
 * __useInsertNoteSignatureMutation__
 *
 * To run a mutation, you first call `useInsertNoteSignatureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertNoteSignatureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertNoteSignatureMutation, { data, loading, error }] = useInsertNoteSignatureMutation({
 *   variables: {
 *      noteSignature: // value for 'noteSignature'
 *   },
 * });
 */
export function useInsertNoteSignatureMutation(baseOptions?: Apollo.MutationHookOptions<InsertNoteSignatureMutation, InsertNoteSignatureMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertNoteSignatureMutation, InsertNoteSignatureMutationVariables>(InsertNoteSignatureDocument, options);
      }
export type InsertNoteSignatureMutationHookResult = ReturnType<typeof useInsertNoteSignatureMutation>;
export type InsertNoteSignatureMutationResult = Apollo.MutationResult<InsertNoteSignatureMutation>;
export type InsertNoteSignatureMutationOptions = Apollo.BaseMutationOptions<InsertNoteSignatureMutation, InsertNoteSignatureMutationVariables>;