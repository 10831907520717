import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { ConsentFieldsFragmentDoc } from '../../fragments/consentFields.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetConsentsQueryVariables = Types.Exact<{
  where?: Types.Maybe<Types.Consent_Bool_Exp>;
  limit?: Types.Maybe<Types.Scalars['Int']>;
  offset?: Types.Maybe<Types.Scalars['Int']>;
  orderBy?: Types.Maybe<Array<Types.Consent_Order_By> | Types.Consent_Order_By>;
}>;


export type GetConsentsQuery = { __typename?: 'query_root', consent: Array<{ __typename?: 'consent', id: any, title: string, description?: string | null | undefined, recurrence: Types.RecurrenceType_Enum, requiredAll: boolean, isPartialMediaConsent: boolean, isFullMediaConsent: boolean, requireProviderSignature?: boolean | null | undefined, requireWitnessSignature?: boolean | null | undefined, createdAt: any, workspaceId: any, surveyJSJson?: any | null | undefined, workspace: { __typename?: 'workspace', name: string }, document?: { __typename?: 'document', id: any, title?: string | null | undefined, filePath: string, documentType: string, documentTags: Array<{ __typename?: 'documentTag', id: any, tag: { __typename?: 'tag', color?: string | null | undefined, id: any, title: string } }>, file?: { __typename?: 'FileOnlyURL', url: string } | null | undefined } | null | undefined, serviceConsents: Array<{ __typename?: 'serviceConsent', id: any, service: { __typename?: 'service', id: any, name: string } }>, patientConsents: Array<{ __typename?: 'patientConsent', id: any, name: string, date: any, providerName?: string | null | undefined, providerDate?: any | null | undefined, witnessName?: string | null | undefined, witnessDate?: any | null | undefined, appointmentId: any, signature?: { __typename?: 'signature', id: any, filePath: string, file?: { __typename?: 'FileOnlyURL', url: string } | null | undefined } | null | undefined, providerSignature?: { __typename?: 'signature', id: any, filePath: string, file?: { __typename?: 'FileOnlyURL', url: string } | null | undefined } | null | undefined, witnessSignature?: { __typename?: 'signature', id: any, filePath: string, file?: { __typename?: 'FileOnlyURL', url: string } | null | undefined } | null | undefined }> }> };


export const GetConsentsDocument = gql`
    query GetConsents($where: consent_bool_exp, $limit: Int, $offset: Int, $orderBy: [consent_order_by!]) {
  consent(where: $where, limit: $limit, offset: $offset, order_by: $orderBy) {
    ...ConsentFields
  }
}
    ${ConsentFieldsFragmentDoc}`;

/**
 * __useGetConsentsQuery__
 *
 * To run a query within a React component, call `useGetConsentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetConsentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetConsentsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetConsentsQuery(baseOptions?: Apollo.QueryHookOptions<GetConsentsQuery, GetConsentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetConsentsQuery, GetConsentsQueryVariables>(GetConsentsDocument, options);
      }
export function useGetConsentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetConsentsQuery, GetConsentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetConsentsQuery, GetConsentsQueryVariables>(GetConsentsDocument, options);
        }
export type GetConsentsQueryHookResult = ReturnType<typeof useGetConsentsQuery>;
export type GetConsentsLazyQueryHookResult = ReturnType<typeof useGetConsentsLazyQuery>;
export type GetConsentsQueryResult = Apollo.QueryResult<GetConsentsQuery, GetConsentsQueryVariables>;