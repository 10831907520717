import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type SvixPortalAccessQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type SvixPortalAccessQuery = { __typename?: 'query_root', svixPortalAccess?: { __typename?: 'SvixPortalAccessOutput', token: string, url: string } | null | undefined };


export const SvixPortalAccessDocument = gql`
    query SvixPortalAccess {
  svixPortalAccess {
    token
    url
  }
}
    `;

/**
 * __useSvixPortalAccessQuery__
 *
 * To run a query within a React component, call `useSvixPortalAccessQuery` and pass it any options that fit your needs.
 * When your component renders, `useSvixPortalAccessQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSvixPortalAccessQuery({
 *   variables: {
 *   },
 * });
 */
export function useSvixPortalAccessQuery(baseOptions?: Apollo.QueryHookOptions<SvixPortalAccessQuery, SvixPortalAccessQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SvixPortalAccessQuery, SvixPortalAccessQueryVariables>(SvixPortalAccessDocument, options);
      }
export function useSvixPortalAccessLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SvixPortalAccessQuery, SvixPortalAccessQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SvixPortalAccessQuery, SvixPortalAccessQueryVariables>(SvixPortalAccessDocument, options);
        }
export type SvixPortalAccessQueryHookResult = ReturnType<typeof useSvixPortalAccessQuery>;
export type SvixPortalAccessLazyQueryHookResult = ReturnType<typeof useSvixPortalAccessLazyQuery>;
export type SvixPortalAccessQueryResult = Apollo.QueryResult<SvixPortalAccessQuery, SvixPortalAccessQueryVariables>;