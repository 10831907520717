import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetGoogleAuthProfileQueryVariables = Types.Exact<{
  userId: Types.Scalars['uuid'];
}>;


export type GetGoogleAuthProfileQuery = { __typename?: 'query_root', authProfile: Array<{ __typename?: 'authProfile', id: any, type: string }> };


export const GetGoogleAuthProfileDocument = gql`
    query GetGoogleAuthProfile($userId: uuid!) {
  authProfile(where: {type: {_eq: "GOOGLE"}, userId: {_eq: $userId}}) {
    id
    type
  }
}
    `;

/**
 * __useGetGoogleAuthProfileQuery__
 *
 * To run a query within a React component, call `useGetGoogleAuthProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGoogleAuthProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGoogleAuthProfileQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetGoogleAuthProfileQuery(baseOptions: Apollo.QueryHookOptions<GetGoogleAuthProfileQuery, GetGoogleAuthProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGoogleAuthProfileQuery, GetGoogleAuthProfileQueryVariables>(GetGoogleAuthProfileDocument, options);
      }
export function useGetGoogleAuthProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGoogleAuthProfileQuery, GetGoogleAuthProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGoogleAuthProfileQuery, GetGoogleAuthProfileQueryVariables>(GetGoogleAuthProfileDocument, options);
        }
export type GetGoogleAuthProfileQueryHookResult = ReturnType<typeof useGetGoogleAuthProfileQuery>;
export type GetGoogleAuthProfileLazyQueryHookResult = ReturnType<typeof useGetGoogleAuthProfileLazyQuery>;
export type GetGoogleAuthProfileQueryResult = Apollo.QueryResult<GetGoogleAuthProfileQuery, GetGoogleAuthProfileQueryVariables>;