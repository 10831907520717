import {
  Button,
  ButtonGroup,
  Flex,
  FormLabel,
  Radio,
  RadioGroup,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import {
  Address,
  PatientAddressFieldsFragment,
  PatientFieldsFragment,
  useListPatientAddressLazyQuery,
} from '@webapp/graphql';
import { ObjectOption } from '@webapp/types';
import { useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { PatientAddressForm } from '@webapp/ui-composites';

function addressToString({
  addressLine1,
  addressLine2,
  city,
  state,
  zipCode,
}: Pick<
  Address,
  'addressLine1' | 'addressLine2' | 'city' | 'state' | 'zipCode'
>) {
  const addressComponents = [];

  if (addressLine2) {
    addressComponents.push(`${addressLine1} ${addressLine2}`);
  } else {
    addressComponents.push(addressLine1);
  }

  addressComponents.push(city);
  addressComponents.push(state);
  addressComponents.push(zipCode);

  return addressComponents.join(', ');
}

function AddressRadioCard({
  addressLine1,
  addressLine2,
  city,
  state,
  zipCode,
}: {
  addressLine1: string;
  addressLine2?: string;
  city: string;
  state: string;
  zipCode: string;
}) {
  return (
    <Flex flexDirection="column" width="100%">
      <Flex
        background="gray.50"
        border="1px solid"
        borderColor="gray.100"
        borderRadius="6px"
        cursor="pointer"
        flexDirection="column"
        padding="12px 24px"
        width="400px"
        _hover={{
          background: 'gray.100',
        }}
      >
        <Text
          color="text.100"
          fontWeight="500"
          textTransform="uppercase"
          width="100%"
        >
          {addressLine1}
        </Text>
        <Text
          color="text.100"
          fontWeight="500"
          textTransform="uppercase"
          width="100%"
        >
          {addressLine2}
        </Text>
        <Text
          color="text.100"
          fontWeight="500"
          textTransform="uppercase"
          width="100%"
        >
          {city}, {state}, {zipCode}
        </Text>
      </Flex>
    </Flex>
  );
}

export function SelectAddressForm({ returnAddressObject = false }) {
  const [canSelect, setCanSelect] = useState<boolean>(false);
  const { isOpen, onClose, onOpen } = useDisclosure();

  const { setValue } = useFormContext();

  const patient = useWatch({ name: 'patient' }) as
    | ObjectOption<PatientFieldsFragment>
    | undefined;

  const [getAddresses, { data, refetch }] = useListPatientAddressLazyQuery();

  const [addressIndex, setAddressIndex] = useState<number | null>(null);

  const [selectedAddress, selectAddress] =
    useState<PatientAddressFieldsFragment | null>(null);

  useEffect(() => {
    const addressOptions = data?.patientAddress ?? [];

    const billingAddressIndex = addressOptions.findIndex(
      (address) => address.type === 'BILLING'
    );

    const defaultAddressIndex = addressOptions.findIndex(
      (address) => address.isDefault
    );

    if (billingAddressIndex > -1) {
      selectAddress(addressOptions[billingAddressIndex]);
      setAddressIndex(billingAddressIndex);

      setValue(
        'address',
        returnAddressObject
          ? addressOptions[billingAddressIndex].address
          : addressToString(addressOptions[billingAddressIndex].address)
      );
    } else if (defaultAddressIndex > -1) {
      selectAddress(addressOptions[defaultAddressIndex]);
      setAddressIndex(defaultAddressIndex);

      setValue(
        'address',
        returnAddressObject
          ? addressOptions?.[billingAddressIndex]?.address
          : addressToString(addressOptions[defaultAddressIndex].address)
      );
    } else if (addressOptions.length) {
      selectAddress(addressOptions[0]);
      setAddressIndex(0);

      setValue(
        'address',
        returnAddressObject
          ? addressOptions[0].address
          : addressToString(addressOptions[0].address)
      );
    } else {
      selectAddress(null);
      setAddressIndex(null);
    }
  }, [data, returnAddressObject, setValue]);

  useEffect(() => {
    if (patient) {
      getAddresses({
        variables: {
          where: {
            patientId: { _eq: patient.object.id },
          },
        },
      });
    }
  }, [getAddresses, patient]);

  function handleSubmit() {
    if (selectedAddress) {
      setValue(
        'address',
        returnAddressObject
          ? selectedAddress.address
          : addressToString(selectedAddress.address)
      );
      setCanSelect(false);
    }
  }

  return (
    <Flex flexDirection="column">
      <FormLabel
        fontSize="14px"
        fontWeight="bold"
        letterSpacing="0.01em"
        margin="0 0 6px 0"
      >
        Billing Address
      </FormLabel>
      {!selectedAddress && !canSelect && (
        <Flex alignItems="center" background="whitesmoke" padding="4px 6px">
          <Button
            alignSelf="end"
            color="gray.400"
            fontSize="12px"
            height="unset"
            margin="0"
            minHeight="unset"
            minWidth="unset"
            onClick={onOpen}
            padding="6px 8px"
            variant="ghost"
            width="max-content"
            _hover={{
              background: 'transparent',
              textDecoration: 'underline',
            }}
          >
            Add a Billing Address
          </Button>
        </Flex>
      )}
      {selectedAddress && !canSelect && (
        <Flex flexDirection="column">
          <Flex
            background="gray.50"
            border="1px solid"
            borderColor="gray.100"
            borderRadius="6px"
            cursor="pointer"
            flexDirection="column"
            padding="12px 24px"
            width="400px"
            _hover={{
              background: 'whitesmoke',
            }}
          >
            <Text
              color="text.100"
              fontWeight="500"
              textTransform="uppercase"
              width="100%"
            >
              {selectedAddress.address.addressLine1}
            </Text>
            <Text
              color="text.100"
              fontWeight="500"
              textTransform="uppercase"
              width="100%"
            >
              {selectedAddress.address.addressLine2}
            </Text>
            <Text
              color="text.100"
              fontWeight="500"
              textTransform="uppercase"
              width="100%"
            >
              {selectedAddress.address.city}, {selectedAddress.address.state},{' '}
              {selectedAddress.address.zipCode}
            </Text>
          </Flex>
          <Button
            color="gray.500"
            fontSize="12px"
            height="max-content"
            margin="8px 0 0"
            minHeight="unset"
            minWidth="unset"
            onClick={() => setCanSelect(true)}
            padding="6px 8px"
            variant="ghost"
            width="max-content"
            _hover={{
              background: 'transparent',
              color: 'text.100',
              textDecoration: 'underline',
            }}
          >
            Change
          </Button>
        </Flex>
      )}
      {canSelect && (
        <Flex flexDirection="column" width="100%">
          <RadioGroup
            onChange={(value) => {
              const addressOptions = data?.patientAddress ?? [];
              const valueInt = parseInt(value, 10);
              setAddressIndex(valueInt);
              selectAddress(addressOptions[valueInt]);
            }}
            value={addressIndex ?? undefined}
          >
            <VStack width="100%">
              {(data?.patientAddress ?? []).map(({ address, id }, index) => {
                const { addressLine1, addressLine2, city, state, zipCode } =
                  address;

                return (
                  <Radio colorScheme="teal" value={index} width="100%">
                    <AddressRadioCard
                      addressLine1={addressLine1}
                      addressLine2={addressLine2 ?? undefined}
                      city={city}
                      state={state}
                      zipCode={zipCode}
                    />
                  </Radio>
                );
              })}
            </VStack>
          </RadioGroup>
          <ButtonGroup justifyContent="end">
            <Button
              alignSelf="end"
              color="gray.400"
              fontSize="12px"
              height="unset"
              margin="8px 0 0"
              minHeight="unset"
              minWidth="unset"
              onClick={onOpen}
              padding="6px 8px"
              variant="ghost"
              width="max-content"
              _hover={{
                background: 'transparent',
                textDecoration: 'underline',
              }}
            >
              Add Address
            </Button>
            <Button
              alignSelf="end"
              colorScheme="teal"
              fontSize="12px"
              height="unset"
              margin="8px 0 0"
              minHeight="unset"
              minWidth="unset"
              onClick={handleSubmit}
              padding="6px 8px"
              width="max-content"
            >
              Select
            </Button>
          </ButtonGroup>
        </Flex>
      )}
      <PatientAddressForm
        isOpen={isOpen}
        onClose={onClose}
        onComplete={refetch}
        patientId={patient?.object.id}
      />
    </Flex>
  );
}

export default SelectAddressForm;
