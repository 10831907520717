import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type ReverseAuthorizationHoldMutationVariables = Types.Exact<{
  paymentId: Types.Scalars['uuid'];
}>;


export type ReverseAuthorizationHoldMutation = { __typename?: 'mutation_root', reverseAuthorizationHold?: { __typename?: 'IdResponse', id: any } | null | undefined };


export const ReverseAuthorizationHoldDocument = gql`
    mutation reverseAuthorizationHold($paymentId: uuid!) {
  reverseAuthorizationHold(paymentId: $paymentId) {
    id
  }
}
    `;
export type ReverseAuthorizationHoldMutationFn = Apollo.MutationFunction<ReverseAuthorizationHoldMutation, ReverseAuthorizationHoldMutationVariables>;

/**
 * __useReverseAuthorizationHoldMutation__
 *
 * To run a mutation, you first call `useReverseAuthorizationHoldMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReverseAuthorizationHoldMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reverseAuthorizationHoldMutation, { data, loading, error }] = useReverseAuthorizationHoldMutation({
 *   variables: {
 *      paymentId: // value for 'paymentId'
 *   },
 * });
 */
export function useReverseAuthorizationHoldMutation(baseOptions?: Apollo.MutationHookOptions<ReverseAuthorizationHoldMutation, ReverseAuthorizationHoldMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReverseAuthorizationHoldMutation, ReverseAuthorizationHoldMutationVariables>(ReverseAuthorizationHoldDocument, options);
      }
export type ReverseAuthorizationHoldMutationHookResult = ReturnType<typeof useReverseAuthorizationHoldMutation>;
export type ReverseAuthorizationHoldMutationResult = Apollo.MutationResult<ReverseAuthorizationHoldMutation>;
export type ReverseAuthorizationHoldMutationOptions = Apollo.BaseMutationOptions<ReverseAuthorizationHoldMutation, ReverseAuthorizationHoldMutationVariables>;