import * as Types from '../../generated/graphql';

import { gql } from '@apollo/client';
export type ConsumableTypeFieldsFullFragment = { __typename?: 'consumableType', id: any, name: string, requiredFields: any, consumables: Array<{ __typename?: 'consumable', id: any, metadata: any, type: { __typename?: 'consumableType', name: string }, brand?: { __typename?: 'consumableBrand', name: string } | null | undefined }> };

export const ConsumableTypeFieldsFullFragmentDoc = gql`
    fragment ConsumableTypeFieldsFull on consumableType {
  id
  name
  requiredFields
  consumables(limit: 50, order_by: {createdAt: desc}) {
    id
    type {
      name
    }
    brand {
      name
    }
    metadata
  }
}
    `;