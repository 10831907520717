import { Stack, Text } from '@chakra-ui/react';
import { toLocalePrice } from '@webapp/util-formatting';

interface PaymentExplanationProps {
  amount: number;
  authOnly?: boolean;
}

export const PaymentExplanation = (
  props: PaymentExplanationProps
): JSX.Element => {
  const { authOnly, amount } = props;

  return (
    <Stack p={20}>
      <Text align={'center'}>
        This appointment requires a {authOnly ? 'hold' : 'deposit'} of{' '}
        {toLocalePrice(amount / 100)} {authOnly && 'to cover no shows'}.
      </Text>
    </Stack>
  );
};

export default PaymentExplanation;
