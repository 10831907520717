import { Instance, SnapshotOut, types } from 'mobx-state-tree';

/**
 * A MediaTagStore model.
 */
// prettier-ignore
export const MediaTagModel = types.model("MediaTag").props({
  id: types.string,
  title: types.string
}).actions((self) => ({
  
}
))
/**
 * The MediaTag instance.
 */
export type MediaTag = Instance<typeof MediaTagModel>;

/**
 * The data of a MediaTag.
 */
export type MediaTagSnapshot = SnapshotOut<typeof MediaTagModel>;
