import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type CreateTelehealthMeetingMutationVariables = Types.Exact<{
  workspaceId: Types.Scalars['uuid'];
  patientId: Types.Scalars['uuid'];
  appointmentId?: Types.Maybe<Types.Scalars['uuid']>;
}>;


export type CreateTelehealthMeetingMutation = { __typename?: 'mutation_root', createTelehealthMeeting?: { __typename?: 'TelehealthMeetingOutput', roomUrl: string, hostRoomUrl: string } | null | undefined };


export const CreateTelehealthMeetingDocument = gql`
    mutation createTelehealthMeeting($workspaceId: uuid!, $patientId: uuid!, $appointmentId: uuid) {
  createTelehealthMeeting(
    workspaceId: $workspaceId
    patientId: $patientId
    appointmentId: $appointmentId
  ) {
    roomUrl
    hostRoomUrl
  }
}
    `;
export type CreateTelehealthMeetingMutationFn = Apollo.MutationFunction<CreateTelehealthMeetingMutation, CreateTelehealthMeetingMutationVariables>;

/**
 * __useCreateTelehealthMeetingMutation__
 *
 * To run a mutation, you first call `useCreateTelehealthMeetingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTelehealthMeetingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTelehealthMeetingMutation, { data, loading, error }] = useCreateTelehealthMeetingMutation({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *      patientId: // value for 'patientId'
 *      appointmentId: // value for 'appointmentId'
 *   },
 * });
 */
export function useCreateTelehealthMeetingMutation(baseOptions?: Apollo.MutationHookOptions<CreateTelehealthMeetingMutation, CreateTelehealthMeetingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTelehealthMeetingMutation, CreateTelehealthMeetingMutationVariables>(CreateTelehealthMeetingDocument, options);
      }
export type CreateTelehealthMeetingMutationHookResult = ReturnType<typeof useCreateTelehealthMeetingMutation>;
export type CreateTelehealthMeetingMutationResult = Apollo.MutationResult<CreateTelehealthMeetingMutation>;
export type CreateTelehealthMeetingMutationOptions = Apollo.BaseMutationOptions<CreateTelehealthMeetingMutation, CreateTelehealthMeetingMutationVariables>;