/* eslint-disable camelcase */
import { useDisclosure } from '@chakra-ui/react';
import { MultiValue } from 'react-select';
import {
  EventcalendarBase,
  MbscCalendarColor,
  MbscCalendarEvent,
  MbscEventcalendarView,
  MbscEventClickEvent,
  MbscEventCreatedEvent,
  MbscEventUpdateEvent,
} from '@mobiscroll/react';
import { DateType } from '@mobiscroll/react/dist/src/core/util/datetime';
import {
  Appointment_Insert_Input,
  LocationFieldsFragment,
  ProviderFieldsFragment,
  useGetInverseAvailabilityMutation,
  useSendAppointmentRescheduleConfirmationMutation,
  useUpdateAppointmentMutation,
} from '@webapp/graphql';
import { ObjectOption } from '@webapp/types';
import { useStores } from '@webapp/state-models';
import { configuredDayjs as dayjs } from '@webapp/util-time';
import { observer } from 'mobx-react-lite';
import { useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import BaseCalendar from './BaseCalendar';

interface MonthWeekCalendarProps {
  filterDate: DateType;
  filterLocations: MultiValue<ObjectOption<LocationFieldsFragment>>;
  filterProviders: MultiValue<ObjectOption<ProviderFieldsFragment>>;
  isListMobileView: boolean;
  onEventClick: (
    event: MbscEventClickEvent,
    instance: EventcalendarBase
  ) => void;
  refetch: () => void;
  selectedView: string;
  setCalendarDate: (date: string) => void;
  view: MbscEventcalendarView;
}

export const MonthWeekCalendar = ({
  filterDate,
  filterLocations,
  filterProviders,
  isListMobileView,
  onEventClick,
  refetch,
  selectedView,
  setCalendarDate,
  view,
}: MonthWeekCalendarProps) => {
  const {
    calendarEventsSnapshot,
    calendarTimeZone,
    setDraftAppointment,
    ui: { setShowCreateAppointmentSidebar },
    workspace,
  } = useStores();

  const [colors, setColors] = useState<MbscCalendarColor[]>([]);

  const lastUpdatedAppointment = useRef<string | number | null | undefined>(
    null
  );

  const {
    isOpen: isAppointmentUpdateConfirmationOpen,
    onClose: onAppointmentUpdateConfirmationClose,
    onOpen: onAppointmentUpdateConfirmationOpen,
  } = useDisclosure();

  const [
    sendAppointmentRescheduleConfirmation,
    { loading: confirmationLoading },
  ] = useSendAppointmentRescheduleConfirmationMutation({
    onCompleted: () => {
      toast.success('Confirmation SMS sent successfully');
      onAppointmentUpdateConfirmationClose();
      lastUpdatedAppointment.current = null;
    },
  });

  const [updateAppointment] = useUpdateAppointmentMutation({
    onCompleted: () => {
      toast.success('Updated appointment');
    },
  });

  const [getInverseAvailability, { data: inverseData }] =
    useGetInverseAvailabilityMutation();

  useEffect(() => {
    if (selectedView === 'week') {
      const locationsFilter = filterLocations
        .map((location) => `'${location.value}'`)
        .join(',');
      const providersFilter = filterProviders
        .map((provider) => `'${provider.value}'`)
        .join(',');
      getInverseAvailability({
        variables: {
          startDate: dayjs(filterDate.toString())
            .startOf('week')
            .format('YYYY-MM-DD'),
          endDate: dayjs(filterDate.toString())
            .endOf('week')
            .format('YYYY-MM-DD'),
          locationsFilter,
          providersFilter,
        },
      });
    }
  }, [selectedView, filterDate, filterLocations, filterProviders]);

  useEffect(() => {
    if (inverseData) {
      const colors =
        inverseData?.getInverseAvailability?.inverseAvailability?.map(
          (inverse) => {
            return { ...inverse, background: '#f5f5f5' };
          }
        ) as MbscCalendarColor[];
      setColors(colors);
    }
  }, [inverseData, selectedView]);

  const calculateStartEndTime = (event: MbscCalendarEvent) => {
    const startTime = event.allDay
      ? dayjs(event.start as string)
          .tz(calendarTimeZone)
          .startOf('day')
          .set('hour', 9)
          .toISOString()
      : dayjs(event.start as string)
          .tz(calendarTimeZone)
          .toISOString();
    const endTime = event.allDay
      ? dayjs(event.start as string)
          .tz(calendarTimeZone)
          .startOf('day')
          .set('hour', 10)
          .toISOString()
      : dayjs(event.end as string)
          .tz(calendarTimeZone)
          .toISOString();
    return { startTime, endTime };
  };

  async function onEventCreated({ event }: MbscEventCreatedEvent) {
    setShowCreateAppointmentSidebar(false);
    const { startTime, endTime } = calculateStartEndTime(event);
    setDraftAppointment({
      id: 'DRAFT',
      workspaceId: workspace?.id,
      type: 'patient_appointment',
      startTime,
      endTime,
    });
    setShowCreateAppointmentSidebar(true);
  }

  async function onEventUpdate({ event }: MbscEventUpdateEvent) {
    if (event.title === 'New event') {
      const { startTime, endTime } = calculateStartEndTime(event);
      setDraftAppointment({
        id: 'DRAFT',
        workspaceId: workspace?.id,
        type: 'patient_appointment',
        startTime,
        endTime,
      });
      return;
    }

    const { start, end, resource } = event;

    const set: Appointment_Insert_Input = {
      timerange: JSON.stringify([start, end]),
    };

    if ((resource as Array<string>).length > 0) {
      const [providerId] = resource as Array<string>;
      set.providerId = providerId === 'No Provider' ? null : providerId;
    }

    await updateAppointment({
      variables: {
        id: event.id,
        shouldUpdateServiceTimes: true,
        set,
      },
    });
    lastUpdatedAppointment.current = event.id;
    onAppointmentUpdateConfirmationOpen();
  }
  const events = calendarEventsSnapshot as MbscCalendarEvent[];
  return (
    <BaseCalendar
      isLoading={confirmationLoading}
      calendarDate={filterDate}
      colors={colors}
      events={events}
      isOpen={isAppointmentUpdateConfirmationOpen}
      onClose={onAppointmentUpdateConfirmationClose}
      onConfirm={() => {
        sendAppointmentRescheduleConfirmation({
          variables: {
            appointmentId: lastUpdatedAppointment.current,
          },
        });
      }}
      onEventClick={onEventClick}
      onEventCreated={onEventCreated}
      onEventUpdate={onEventUpdate}
      selectedView={selectedView}
      setCalendarDate={setCalendarDate}
      view={view}
      isListMobileView={isListMobileView}
    />
  );
};

export default observer(MonthWeekCalendar);
