import { DateTimerangePickerInputProps, FormInputProps } from '@webapp/types';
import { useFormContext } from 'react-hook-form';
import { useEffect, useState } from 'react';
import {
  Box,
  FormLabel,
  Grid,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { ErrorMessage } from '@hookform/error-message';
import dayjs from 'dayjs';
import { DEFAULT_LABEL_STYLE } from './constants';
import './DatePickerFormInput.css';
import TimePicker from './TimePicker';

const TIME_FORMAT = 'hh:mm A';

export function DateTimeRangePickerFormInput({
  id,
  label,
  labelAlign,
  labelStyle = DEFAULT_LABEL_STYLE,
  name,
  rangeStartName,
  rangeEndName,
  type,
}: Omit<FormInputProps, 'datePickerProps'> & DateTimerangePickerInputProps) {
  const {
    isOpen: startTimeIsOpen,
    onToggle: startTimeOnToggle,
    onClose: startTimeOnClose,
  } = useDisclosure();

  const {
    isOpen: endTimeIsOpen,
    onToggle: endTimeOnToggle,
    onClose: endTimeOnClose,
  } = useDisclosure();

  const {
    formState: { errors },
    getValues,
    register,
    setValue,
  } = useFormContext();

  const initialValue = getValues(name);
  const initialStartTime = initialValue?.start ?? '06:00 AM';

  const initialEndTime = initialValue?.end ?? '06:00 PM';

  const [startTime, setStartTime] = useState<string>(initialStartTime);
  const [endTime, setEndTime] = useState<string>(initialEndTime);

  const inputId = id ?? name;

  useEffect(() => {
    register(name);
  });

  useEffect(() => {
    if (rangeStartName) {
      setValue(rangeStartName, startTime);
    }
    if (rangeEndName) {
      setValue(rangeEndName, endTime);
    }
    if (!rangeStartName && !rangeEndName) {
      setValue(name, {
        start: startTime,
        end: endTime,
      });
    }
  }, [name, setValue, startTime, endTime]);

  function handleStartTimeChange({ valueText }: { valueText: string }) {
    setStartTime(valueText);
    startTimeOnClose();
  }

  function handleEndTimeChange({ valueText }: { valueText: string }) {
    setEndTime(valueText);
    endTimeOnClose();
  }

  return (
    <Grid
      alignItems={labelAlign}
      className="form-input-date-picker input-outer"
      gridAutoRows="max-content"
      gridRowGap="6px"
      gridTemplateColumns="1fr"
    >
      {Boolean(label) && (
        <FormLabel
          className="input-label"
          htmlFor={inputId}
          margin="0 0 0 0"
          {...labelStyle}
        >
          {label}
        </FormLabel>
      )}
      <Grid className="input-inner" gridRowGap="8px">
        <Grid
          background="white"
          border="1px solid"
          borderColor="gray.200"
          borderRadius="var(--chakra-radii-md)"
          gridTemplateColumns={'2fr 1fr 2fr'}
        >
          <TimePicker
            onToggle={startTimeOnToggle}
            handleTimeChange={handleStartTimeChange}
            isOpen={startTimeIsOpen}
            onClose={startTimeOnClose}
            time={startTime}
          />
          <Stack
            w={10}
            h={'full'}
            textAlign="center"
            justifyContent={'center'}
            verticalAlign={'center'}
          >
            <Box>{'to'}</Box>
          </Stack>
          <TimePicker
            onToggle={endTimeOnToggle}
            handleTimeChange={handleEndTimeChange}
            isOpen={endTimeIsOpen}
            onClose={endTimeOnClose}
            time={endTime}
          />
        </Grid>
        <ErrorMessage
          errors={errors}
          name={name}
          render={({ message }) => (
            <Text
              className="input-error"
              color="red.500"
              _before={{
                display: 'inline',
                content: '"⚠ "',
              }}
            >
              {message}
            </Text>
          )}
        />
      </Grid>
    </Grid>
  );
}

export default DateTimeRangePickerFormInput;
