import * as Types from '../../generated/graphql';

import { gql } from '@apollo/client';
export type PaymentFieldsFragment = { __typename?: 'payment', createdAt: any, id: any, workspaceId: any, paymentMethodId?: any | null | undefined, paymentMethodType: string, payrixType?: string | null | undefined, amount: number, invoiceId?: any | null | undefined, status: string, type?: string | null | undefined };

export const PaymentFieldsFragmentDoc = gql`
    fragment PaymentFields on payment {
  createdAt
  id
  workspaceId
  paymentMethodId
  paymentMethodType
  payrixType
  amount
  invoiceId
  status
  type
}
    `;