import { HStack, ButtonGroup, Button, Grid } from '@chakra-ui/react';
import { joiResolver } from '@hookform/resolvers/joi';
import { US_STATES } from '@webapp/constants';
import { HookForm, FormInput } from '@webapp/ui';

interface PatientAddressFormValues {
  addressLine1: string;
  addressLine2?: string;
  city: string;

  state: string;
  zipCode: string;
}

export interface WrappedAddressProps {
  onChange: (values: PatientAddressFormValues) => void;
}

export default function WrappedAddress({ onChange }: WrappedAddressProps) {
  function onSubmit(values: PatientAddressFormValues) {
    onChange({
      addressLine1: values.addressLine1,
      addressLine2: values.addressLine2,
      city: values.city,
      state: values.state,
      zipCode: values.zipCode,
    });
  }

  return (
    <HookForm
      shouldSubmitOnChange={true}
      mode="onBlur"
      shouldResetOnDefaultValues={false}
      defaultValues={{
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        zipCode: '',
      }}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, watch }) => (
        <Grid gridRowGap="15px">
          <FormInput name="addressLine1" type="address" />
          <FormInput
            name="addressLine2"
            placeholder="Apartment, Suite, Unit #..."
          />
          <Grid gridTemplateColumns="repeat(3, 1fr)" gridColumnGap="8px">
            <FormInput name="city" placeholder="City" />
            <FormInput
              id="state"
              name="state"
              placeholder=""
              withOptions={US_STATES.map((state) => ({
                label: state.name,
                value: state.abbreviation,
              }))}
              type="select"
            />
            <FormInput name="zipCode" placeholder="Zip Code" />
          </Grid>
        </Grid>
      )}
    </HookForm>
  );
}
