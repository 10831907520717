import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type SaveUrlToPatientPhotosMutationVariables = Types.Exact<{
  url: Types.Scalars['String'];
  patientId: Types.Scalars['uuid'];
  workspaceId: Types.Scalars['uuid'];
}>;


export type SaveUrlToPatientPhotosMutation = { __typename?: 'mutation_root', saveUrlToPatientPhotos?: { __typename?: 'SuccessAnswer', success: boolean } | null | undefined };


export const SaveUrlToPatientPhotosDocument = gql`
    mutation saveUrlToPatientPhotos($url: String!, $patientId: uuid!, $workspaceId: uuid!) {
  saveUrlToPatientPhotos(
    url: $url
    patientId: $patientId
    workspaceId: $workspaceId
  ) {
    success
  }
}
    `;
export type SaveUrlToPatientPhotosMutationFn = Apollo.MutationFunction<SaveUrlToPatientPhotosMutation, SaveUrlToPatientPhotosMutationVariables>;

/**
 * __useSaveUrlToPatientPhotosMutation__
 *
 * To run a mutation, you first call `useSaveUrlToPatientPhotosMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveUrlToPatientPhotosMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveUrlToPatientPhotosMutation, { data, loading, error }] = useSaveUrlToPatientPhotosMutation({
 *   variables: {
 *      url: // value for 'url'
 *      patientId: // value for 'patientId'
 *      workspaceId: // value for 'workspaceId'
 *   },
 * });
 */
export function useSaveUrlToPatientPhotosMutation(baseOptions?: Apollo.MutationHookOptions<SaveUrlToPatientPhotosMutation, SaveUrlToPatientPhotosMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveUrlToPatientPhotosMutation, SaveUrlToPatientPhotosMutationVariables>(SaveUrlToPatientPhotosDocument, options);
      }
export type SaveUrlToPatientPhotosMutationHookResult = ReturnType<typeof useSaveUrlToPatientPhotosMutation>;
export type SaveUrlToPatientPhotosMutationResult = Apollo.MutationResult<SaveUrlToPatientPhotosMutation>;
export type SaveUrlToPatientPhotosMutationOptions = Apollo.BaseMutationOptions<SaveUrlToPatientPhotosMutation, SaveUrlToPatientPhotosMutationVariables>;