/* eslint-disable camelcase */
import { types, getSnapshot } from 'mobx-state-tree';
// /**
//  * An AudienceModel model.
//  */

const Condition = types.model({
  type: types.string,
  target: types.string,
  field: types.string,
  op: types.string,
  val: types.string,
  label: types.string,
});

const Predicate = types.model({
  type: types.string,
  predicates: types.array(Condition),
});

export const AudienceModel = types
  .model('Audience')
  .props({
    // Null means we're creating, otherwise editing
    // Ideally this'd be an identifier, but that seems to override the maybeNull
    id: types.maybeNull(types.string),
    name: types.maybeNull(types.string),
    type: types.optional(types.string, 'active'),
    predicate: types.optional(Predicate, { type: 'AND', predicates: [] }),
    snapshot: types.optional(types.array(types.string), []),
  })
  .views((self) => ({
    hasPredicate: (target: string, field: string, op: string, val: string) => {
      const predStr = `${target}${field}${op}${val}`;
      return !!self.predicate.predicates.find(
        ({ target, field, op, val }) =>
          `${target}${field}${op}${val}` === predStr
      );
    },
    getAST: () => getSnapshot(self.predicate),
  }))
  .actions((self) => ({
    addCondition: (
      target: string,
      field: string,
      op: string,
      val: string,
      label: string
    ) => {
      const cond = Condition.create({
        target,
        field,
        op,
        val,
        type: 'LEAF',
        label,
      });

      self.predicate.predicates.push(cond);
    },
    removeCondition: (condition: any) =>
      self.predicate.predicates.remove(condition),
    setName: (name: string) => {
      self.name = name;
    },
    setType: (type: string) => {
      self.type = type;
    },
  }));
