import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type InsertUnusedAppointmentServiceConsumableTypeMutationVariables = Types.Exact<{
  unusedAppointmentServiceConsumableType: Types.UnusedAppointmentServiceConsumableType_Insert_Input;
}>;


export type InsertUnusedAppointmentServiceConsumableTypeMutation = { __typename?: 'mutation_root', insert_unusedAppointmentServiceConsumableType?: { __typename?: 'unusedAppointmentServiceConsumableType_mutation_response', returning: Array<{ __typename?: 'unusedAppointmentServiceConsumableType', id: any }> } | null | undefined };


export const InsertUnusedAppointmentServiceConsumableTypeDocument = gql`
    mutation InsertUnusedAppointmentServiceConsumableType($unusedAppointmentServiceConsumableType: unusedAppointmentServiceConsumableType_insert_input!) {
  insert_unusedAppointmentServiceConsumableType(
    objects: [$unusedAppointmentServiceConsumableType]
  ) {
    returning {
      id
    }
  }
}
    `;
export type InsertUnusedAppointmentServiceConsumableTypeMutationFn = Apollo.MutationFunction<InsertUnusedAppointmentServiceConsumableTypeMutation, InsertUnusedAppointmentServiceConsumableTypeMutationVariables>;

/**
 * __useInsertUnusedAppointmentServiceConsumableTypeMutation__
 *
 * To run a mutation, you first call `useInsertUnusedAppointmentServiceConsumableTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertUnusedAppointmentServiceConsumableTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertUnusedAppointmentServiceConsumableTypeMutation, { data, loading, error }] = useInsertUnusedAppointmentServiceConsumableTypeMutation({
 *   variables: {
 *      unusedAppointmentServiceConsumableType: // value for 'unusedAppointmentServiceConsumableType'
 *   },
 * });
 */
export function useInsertUnusedAppointmentServiceConsumableTypeMutation(baseOptions?: Apollo.MutationHookOptions<InsertUnusedAppointmentServiceConsumableTypeMutation, InsertUnusedAppointmentServiceConsumableTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertUnusedAppointmentServiceConsumableTypeMutation, InsertUnusedAppointmentServiceConsumableTypeMutationVariables>(InsertUnusedAppointmentServiceConsumableTypeDocument, options);
      }
export type InsertUnusedAppointmentServiceConsumableTypeMutationHookResult = ReturnType<typeof useInsertUnusedAppointmentServiceConsumableTypeMutation>;
export type InsertUnusedAppointmentServiceConsumableTypeMutationResult = Apollo.MutationResult<InsertUnusedAppointmentServiceConsumableTypeMutation>;
export type InsertUnusedAppointmentServiceConsumableTypeMutationOptions = Apollo.BaseMutationOptions<InsertUnusedAppointmentServiceConsumableTypeMutation, InsertUnusedAppointmentServiceConsumableTypeMutationVariables>;