/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable import/order */
/* eslint-disable camelcase */
import {
  Stack,
  Box,
  Button,
  chakra,
  Flex,
  Text,
  Heading,
} from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import { Document, Page, pdfjs } from 'react-pdf';
import { useUploadToS3 } from '@webapp/hooks';
import {
  ConsentFieldsFragment,
  InsertSignatureDocument,
  ListAppointmentsDocument,
  PatientConsentFieldsFragment,
  PatientConsent_Insert_Input,
  StorageType,
  useGetPinAggregateQuery,
  useInsertPatientConsentMutation,
  useUpdatePatientConsentMutation,
} from '@webapp/graphql';
import { useForm, FormProvider, SubmitHandler } from 'react-hook-form';
import { FormInput } from '@webapp/ui';
import { useNavigate, useParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import { useStores } from '@webapp/state-models';
import VerifyPinModal from '../verify-pin-modal';
import './sign-consent.css';
import SignatureGroup from './signatureGroup';
import { isConsentFormFinished } from '@webapp/util-validation';
import {
  loadExistingSignature,
  saveSignatureAndConvertToFile,
} from '../signature-util';
import { useApolloClient } from '@apollo/client';
import { useFlagsmith } from 'flagsmith-react';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

interface SignConsentProps {
  consent: ConsentFieldsFragment;
  patientConsent?: PatientConsentFieldsFragment;
  defaultValues: FormInput;
}

type FormInput = {
  name?: string;
  date?: string;
  providerName?: string;
  providerDate?: string;
  witnessName?: string;
  witnessDate?: string;
};

export const SignConsent = ({
  consent,
  defaultValues,
  patientConsent,
}: SignConsentProps) => {
  const { patientId, appointmentId } = useParams();
  const client = useApolloClient();
  const [loading, setLoading] = useState(false);

  const {
    workspace,
    setOutstandingConsents,
    outstandingForms,
    outstandingConsents,
    ui,
  } = useStores();

  const methods = useForm({
    mode: 'onChange',
    defaultValues,
    reValidateMode: 'onChange',
    criteriaMode: 'firstError',
    shouldFocusError: true,
  });

  const navigate = useNavigate();

  const { handleSubmit } = methods;
  const patientSignatureRef = useRef<SignatureCanvas>(null);
  const providerSignatureRef = useRef<SignatureCanvas>(null);
  const witnessSignatureRef = useRef<SignatureCanvas>(null);

  const [stateNumPages, setStateNumPages] = useState<number | null>(null);
  const [progress, setProgress] = useState(0);
  const [verifyPinModalIsOpen, setVerifyPinModalIsOpen] = useState(false);

  const { data: pinAggregateData } = useGetPinAggregateQuery({
    variables: {
      where: {
        workspaceId: {
          _eq: workspace?.id,
        },
      },
    },
  });
  useEffect(() => {
    document.body.addEventListener(
      'touchstart',
      (e) => {
        if (
          e.target === patientSignatureRef.current ||
          e.target === providerSignatureRef.current ||
          e.target === witnessSignatureRef.current
        ) {
          e.preventDefault();
        }
      },
      false
    );
    document.body.addEventListener(
      'touchend',
      (e) => {
        if (
          e.target === patientSignatureRef.current ||
          e.target === providerSignatureRef.current ||
          e.target === witnessSignatureRef.current
        ) {
          e.preventDefault();
        }
      },
      false
    );
    document.body.addEventListener(
      'touchmove',
      (e) => {
        if (
          e.target === patientSignatureRef.current ||
          e.target === providerSignatureRef.current ||
          e.target === witnessSignatureRef.current
        ) {
          e.preventDefault();
        }
      },
      false
    );
  }, []);
  useEffect(() => {
    if (!patientConsent) return;

    patientSignatureRef.current?.off();

    const signatureURL = patientConsent?.signature?.file?.url;
    const witnessSignatureUrl = patientConsent?.witnessSignature?.file?.url;
    const providerSignatureUrl = patientConsent?.providerSignature?.file?.url;

    if (signatureURL)
      loadExistingSignature(patientSignatureRef.current!, signatureURL);
    if (witnessSignatureUrl) {
      witnessSignatureRef?.current?.off();
      loadExistingSignature(witnessSignatureRef.current!, witnessSignatureUrl);
    }
    if (providerSignatureUrl) {
      providerSignatureRef?.current?.off();
      loadExistingSignature(
        providerSignatureRef.current!,
        providerSignatureUrl
      );
    }
  }, [patientConsent]);

  const [insertPatientConsent] = useInsertPatientConsentMutation();
  const [updatePatientConsent] = useUpdatePatientConsentMutation();

  const { uploadToS3 } = useUploadToS3();

  const getAFileName = (): string => {
    const fileName = `${patientId}-${consent.id}-${new Date().getTime()}.png`;

    return fileName;
  };

  function onDocumentLoadSuccess({ numPages }: { numPages: number }) {
    setStateNumPages(numPages);
  }

  const onClose = (): void => {
    ui.setGlobalPinLock(false);
  };

  const onOpen = (): void => {
    ui.setGlobalPinLock(true);
  };

  const onVerify = (): void => {
    navigate(
      `/patients/${patientId}/appointments/${
        appointmentId || patientConsent?.appointmentId
      }`
    );
  };

  const verifyOrGoNext = (): void => {
    if (pinAggregateData?.pin_aggregate.aggregate?.count) {
      onOpen();
    } else {
      onVerify();
    }
  };

  const refetchQueries = [
    {
      query: ListAppointmentsDocument,
      variables: {
        where: {
          patientId: {
            _eq: patientId,
          },
        },
      },
    },
  ];

  const onSubmit: SubmitHandler<FormInput> = async ({
    name,
    date,
    witnessName,
    witnessDate,
    providerName,
    providerDate,
  }) => {
    try {
      let witnessSignatureFilepath;
      let providerSignatureFilePath;

      setLoading(true);
      const patientFileName = getAFileName();
      const file = await saveSignatureAndConvertToFile(
        patientSignatureRef.current!,
        patientFileName
      );

      const filePath = await uploadToS3({
        fileType: file.type,
        fileContents: file,
        filePath: file.name,
        storageType: StorageType.Document,
        onProgress: setProgress,
      });

      if (patientSignatureRef?.current?.isEmpty()) {
        toast.error('Please sign the document');
        setLoading(false);
        return;
      }

      if (
        consent?.requireWitnessSignature &&
        !witnessSignatureRef.current?.isEmpty()
      ) {
        const witnessFileName = getAFileName();

        const witnessFile = await saveSignatureAndConvertToFile(
          witnessSignatureRef.current!,
          witnessFileName
        );

        witnessSignatureFilepath = await uploadToS3({
          fileType: witnessFile.type,
          fileContents: witnessFile,
          filePath: witnessFile.name,
          storageType: StorageType.Document,
          onProgress: setProgress,
        });
      }
      if (
        consent?.requireProviderSignature &&
        !providerSignatureRef.current?.isEmpty()
      ) {
        const providerFileName = getAFileName();

        const providerFile = await saveSignatureAndConvertToFile(
          providerSignatureRef.current!,
          providerFileName
        );

        providerSignatureFilePath = await uploadToS3({
          fileType: providerFile.type,
          fileContents: providerFile,
          filePath: providerFile.name,
          storageType: StorageType.Document,
          onProgress: setProgress,
        });
      }

      if (!patientConsent) {
        await insertPatientConsent({
          variables: {
            patientConsent: {
              name,
              date,
              consentId: consent.id,
              patientId,
              appointmentId,
              signature: {
                data: {
                  filePath,
                  patientId,
                },
              },
              ...(providerSignatureFilePath
                ? {
                    providerName,
                    providerDate,
                    providerSignature: {
                      data: {
                        filePath: providerSignatureFilePath,
                        patientId,
                      },
                    },
                  }
                : {}),
              ...(witnessSignatureFilepath
                ? {
                    witnessName,
                    witnessDate,
                    witnessSignature: {
                      data: {
                        filePath: witnessSignatureFilepath,
                        patientId,
                      },
                    },
                  }
                : {}),
            },
          },
          refetchQueries,
        });
      } else {
        const updateFields: PatientConsent_Insert_Input = {
          witnessName,
          witnessDate,
          providerName,
          providerDate,
        };
        if (providerSignatureFilePath) {
          const { data: providerSignatureData } = await client.mutate({
            mutation: InsertSignatureDocument,
            variables: {
              signature: {
                filePath: providerSignatureFilePath,
              },
            },
          });
          updateFields.providerSignatureId =
            providerSignatureData?.insert_signature?.returning[0].id;
        }

        if (witnessSignatureFilepath) {
          const { data: witnessSignatureData } = await client.mutate({
            mutation: InsertSignatureDocument,
            variables: {
              signature: {
                filePath: witnessSignatureFilepath,
              },
            },
          });
          updateFields.witnessSignatureId =
            witnessSignatureData?.insert_signature?.returning[0].id;
        }

        await updatePatientConsent({
          variables: {
            id: patientConsent?.id,
            set: updateFields,
          },
        });
      }
      setLoading(false);
      toast.success('Consent submitted successfully');

      if (outstandingConsents.length > 1) {
        const newOutstandingConsentsArray = [
          ...outstandingConsents,
        ] as string[];

        const indexOfCompletedConsent = newOutstandingConsentsArray.indexOf(
          consent.id
        );

        newOutstandingConsentsArray.splice(indexOfCompletedConsent, 1);

        setOutstandingConsents(newOutstandingConsentsArray);

        const nextConsent = newOutstandingConsentsArray[0];

        navigate(
          `/patients/${patientId}/appointments/${appointmentId}/consents/${nextConsent}`
        );
      } else {
        setOutstandingConsents([]);

        if (outstandingForms.length) {
          const nextForm = outstandingForms[0];

          navigate(
            `/patients/${patientId}/appointments/${appointmentId}/forms/${nextForm}`
          );
        } else {
          verifyOrGoNext();
        }
      }
    } catch (e) {
      toast.error((e as Error).message);
    }
  };

  return (
    <Stack
      bg="gray.400"
      justifyContent="center"
      alignItems={'center'}
      maxW={`${window.innerWidth}px`}
    >
      <Box
        zIndex={2}
        bg="white"
        borderBottomWidth={1}
        borderColor="grey.200"
        shadow={'md'}
        w={'full'}
        p={4}
        position="sticky"
        top={0}
        left={0}
      >
        <Heading size="md">Pease read document & sign below 👇</Heading>
      </Box>

      <FormProvider {...methods}>
        <chakra.form onSubmit={handleSubmit(onSubmit)} m={0} p={0}>
          <Stack
            id="document-stack"
            bg="white"
            shadow="md"
            w="full"
            h="full"
            justifyContent={'center'}
            alignItems="center"
          >
            <Box bg="white" width={`${window.innerWidth * 0.8}px`}>
              {/* @ts-ignore-error React 18 */}
              <Document
                file={consent.document?.file?.url}
                onLoadSuccess={onDocumentLoadSuccess}
              >
                {Array.from(new Array(stateNumPages), (el, index) => (
                  // @ts-ignore-error React 18
                  <Page
                    key={`page_${index + 1}`}
                    pageNumber={index + 1}
                    width={window.innerWidth * 0.8}
                    scale={1}
                  />
                ))}
              </Document>
              <Text px={2} pb={4} textAlign="center">
                By signing your name electronically on this document, you are
                agreeing that your electronic signature is the legal equivalent
                of your manual signature. You will receive a copy of this form
                after it has been signed.
              </Text>
              <Stack spacing={4}>
                <SignatureGroup
                  heading="Patient Signature"
                  formInputName="name"
                  formInputDateName="date"
                  consent={patientConsent}
                  signatureRef={patientSignatureRef}
                />
                {consent.requireWitnessSignature && (
                  <SignatureGroup
                    required={false}
                    heading="Witness Signature"
                    formInputName="witnessName"
                    formInputDateName="witnessDate"
                    consent={patientConsent}
                    signatureRef={witnessSignatureRef}
                  />
                )}
                {consent.requireProviderSignature && (
                  <SignatureGroup
                    required={false}
                    heading="Provider Signature"
                    formInputName="providerName"
                    formInputDateName="providerDate"
                    consent={patientConsent}
                    signatureRef={providerSignatureRef}
                  />
                )}
              </Stack>
            </Box>
          </Stack>

          <Flex
            justifyContent="space-between"
            p={4}
            w={'full'}
            alignItems="center"
          >
            <Button onClick={verifyOrGoNext}>Back</Button>
            {(!patientConsent || !isConsentFormFinished(patientConsent)) && (
              <Button colorScheme="teal" type="submit" isLoading={loading}>
                Submit
              </Button>
            )}
          </Flex>
        </chakra.form>
      </FormProvider>
      <VerifyPinModal
        isOpen={verifyPinModalIsOpen}
        onClose={onClose}
        onVerify={onVerify}
      />
    </Stack>
  );
};

export default SignConsent;
