import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type DeviceTypeAggregateQueryVariables = Types.Exact<{
  where?: Types.Maybe<Types.DeviceType_Bool_Exp>;
}>;


export type DeviceTypeAggregateQuery = { __typename?: 'query_root', deviceType_aggregate: { __typename?: 'deviceType_aggregate', aggregate?: { __typename?: 'deviceType_aggregate_fields', count: number } | null | undefined } };


export const DeviceTypeAggregateDocument = gql`
    query deviceTypeAggregate($where: deviceType_bool_exp) {
  deviceType_aggregate(where: $where) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useDeviceTypeAggregateQuery__
 *
 * To run a query within a React component, call `useDeviceTypeAggregateQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeviceTypeAggregateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeviceTypeAggregateQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDeviceTypeAggregateQuery(baseOptions?: Apollo.QueryHookOptions<DeviceTypeAggregateQuery, DeviceTypeAggregateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DeviceTypeAggregateQuery, DeviceTypeAggregateQueryVariables>(DeviceTypeAggregateDocument, options);
      }
export function useDeviceTypeAggregateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DeviceTypeAggregateQuery, DeviceTypeAggregateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DeviceTypeAggregateQuery, DeviceTypeAggregateQueryVariables>(DeviceTypeAggregateDocument, options);
        }
export type DeviceTypeAggregateQueryHookResult = ReturnType<typeof useDeviceTypeAggregateQuery>;
export type DeviceTypeAggregateLazyQueryHookResult = ReturnType<typeof useDeviceTypeAggregateLazyQuery>;
export type DeviceTypeAggregateQueryResult = Apollo.QueryResult<DeviceTypeAggregateQuery, DeviceTypeAggregateQueryVariables>;