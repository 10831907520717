import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type DeleteCustomFormRowMutationVariables = Types.Exact<{
  where: Types.CustomFormRow_Bool_Exp;
}>;


export type DeleteCustomFormRowMutation = { __typename?: 'mutation_root', delete_customFormRow?: { __typename?: 'customFormRow_mutation_response', returning: Array<{ __typename?: 'customFormRow', id: any }> } | null | undefined };


export const DeleteCustomFormRowDocument = gql`
    mutation deleteCustomFormRow($where: customFormRow_bool_exp!) {
  delete_customFormRow(where: $where) {
    returning {
      id
    }
  }
}
    `;
export type DeleteCustomFormRowMutationFn = Apollo.MutationFunction<DeleteCustomFormRowMutation, DeleteCustomFormRowMutationVariables>;

/**
 * __useDeleteCustomFormRowMutation__
 *
 * To run a mutation, you first call `useDeleteCustomFormRowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCustomFormRowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCustomFormRowMutation, { data, loading, error }] = useDeleteCustomFormRowMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDeleteCustomFormRowMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCustomFormRowMutation, DeleteCustomFormRowMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCustomFormRowMutation, DeleteCustomFormRowMutationVariables>(DeleteCustomFormRowDocument, options);
      }
export type DeleteCustomFormRowMutationHookResult = ReturnType<typeof useDeleteCustomFormRowMutation>;
export type DeleteCustomFormRowMutationResult = Apollo.MutationResult<DeleteCustomFormRowMutation>;
export type DeleteCustomFormRowMutationOptions = Apollo.BaseMutationOptions<DeleteCustomFormRowMutation, DeleteCustomFormRowMutationVariables>;