import { Button, Flex, Icon, Stack } from '@chakra-ui/react';
import { HiOutlineMail } from 'react-icons/hi';
import { AppointmentStepProps } from '@webapp/types';
import { useSendUnscheduledClinicalMessageMutation } from '@webapp/graphql';
import toast from 'react-hot-toast';
import { InstructionsTable } from './instructionsTable';

export const InstructionsSection = ({
  appointment,
  mini = false,
}: AppointmentStepProps) => {
  const hasNoAttachments =
    appointment.services.filter(
      (service: any) =>
        service.service.precareDocument || service.service.postcareDocument
    ).length === 0;

  const [sendMessage, { loading }] = useSendUnscheduledClinicalMessageMutation({
    onCompleted: () => {
      toast.success('Instructions sent');
    },
    onError: (e) => {
      toast.error(e.message);
    },
  });
  const sendNow = (docType: 'precare' | 'postcare') => {
    sendMessage({
      variables: {
        appointmentId: appointment.id,
        patientId: appointment.patient.id,
        messageType: docType,
      },
    });
  };

  return (
    <Stack>
      <Flex justifyContent="flex-end" gap="2">
        <Button
          onClick={() => sendNow('precare')}
          variant="outline"
          size="sm"
          leftIcon={<Icon as={HiOutlineMail} />}
          isLoading={loading}
          isDisabled={hasNoAttachments || loading}
          colorScheme="teal"
        >
          Send Pre-Care
        </Button>
        <Button
          onClick={() => sendNow('postcare')}
          variant="outline"
          size="sm"
          leftIcon={<Icon as={HiOutlineMail} />}
          isLoading={loading}
          isDisabled={hasNoAttachments || loading}
          colorScheme="teal"
        >
          Send Post-Care
        </Button>
      </Flex>

      <InstructionsTable appointment={appointment} mini={mini} />
    </Stack>
  );
};

export default InstructionsSection;
