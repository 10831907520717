import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type InsertExternalPaymentMethodMutationVariables = Types.Exact<{
  paymentMethod: Types.PaymentMethod_Insert_Input;
}>;


export type InsertExternalPaymentMethodMutation = { __typename?: 'mutation_root', insert_paymentMethod?: { __typename?: 'paymentMethod_mutation_response', returning: Array<{ __typename?: 'paymentMethod', id: any }> } | null | undefined };


export const InsertExternalPaymentMethodDocument = gql`
    mutation insertExternalPaymentMethod($paymentMethod: paymentMethod_insert_input!) {
  insert_paymentMethod(objects: [$paymentMethod]) {
    returning {
      id
    }
  }
}
    `;
export type InsertExternalPaymentMethodMutationFn = Apollo.MutationFunction<InsertExternalPaymentMethodMutation, InsertExternalPaymentMethodMutationVariables>;

/**
 * __useInsertExternalPaymentMethodMutation__
 *
 * To run a mutation, you first call `useInsertExternalPaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertExternalPaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertExternalPaymentMethodMutation, { data, loading, error }] = useInsertExternalPaymentMethodMutation({
 *   variables: {
 *      paymentMethod: // value for 'paymentMethod'
 *   },
 * });
 */
export function useInsertExternalPaymentMethodMutation(baseOptions?: Apollo.MutationHookOptions<InsertExternalPaymentMethodMutation, InsertExternalPaymentMethodMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertExternalPaymentMethodMutation, InsertExternalPaymentMethodMutationVariables>(InsertExternalPaymentMethodDocument, options);
      }
export type InsertExternalPaymentMethodMutationHookResult = ReturnType<typeof useInsertExternalPaymentMethodMutation>;
export type InsertExternalPaymentMethodMutationResult = Apollo.MutationResult<InsertExternalPaymentMethodMutation>;
export type InsertExternalPaymentMethodMutationOptions = Apollo.BaseMutationOptions<InsertExternalPaymentMethodMutation, InsertExternalPaymentMethodMutationVariables>;