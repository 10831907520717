/* eslint-disable no-param-reassign */
import { useEffect, useState } from 'react';
import { Survey } from 'survey-react-ui';
import { ComponentCollection, Model, setLicenseKey } from 'survey-core';
import { elementsAsComponentConfiguration } from '@webapp/constants';
import { useLoadServiceCategory } from '@webapp/webapp/hooks';
import { useStores } from '@webapp/state-models';
import {
  CustomFormFieldsFragment,
  PatientFieldsFragment,
  StorageType,
  useGetServiceCategoriesLazyQuery,
  useInsertDocumentMutation,
} from '@webapp/graphql';
import { SurveyPDF } from 'survey-pdf';
import { dataURLtoFile } from '@webapp/utils';
import { useUploadToS3 } from '@webapp/hooks';
import dayjs from 'dayjs';

setLicenseKey(
  'MTMxYTY2ODMtNTQxMC00NWIzLTliMGMtZGMyN2RiMGUzMzgyOzE9MjAyNS0wMi0wOCwyPTIwMjUtMDItMDgsND0yMDI1LTAyLTA4'
);
export interface SurveyJSCustomFormProps {
  surveyJSJSON: Record<string, any>;
  data?: Record<string, any>;
  printing?: boolean;
  patient?: PatientFieldsFragment;
  appointmentId?: string;
  form?: CustomFormFieldsFragment;
  onSubmit: (data: Record<string, any>) => void;
  presentationContext: 'private' | 'public';
}

export function SurveyJSCustomForm({
  surveyJSJSON,
  data,
  onSubmit,
  printing = false,
  patient,
  form,
  appointmentId,
  presentationContext,
}: SurveyJSCustomFormProps) {
  const { workspace } = useStores();
  const { uploadToS3 } = useUploadToS3();
  const [progress, setProgress] = useState(0);
  const { search: searchServiceCategories } = useLoadServiceCategory({
    workspaceId: workspace?.id ?? form?.workspaceId,
  });
  const [insertDocument] = useInsertDocumentMutation();
  const [getCategories] = useGetServiceCategoriesLazyQuery();

  async function generateAndSavePDF(data: any) {
    const surveyPdf = new SurveyPDF(surveyJSJSON, {
      fontSize: 12,
      haveCommercialLicense: true,
    });

    surveyPdf.mode = 'display';

    surveyPdf.data = data;

    const file = dataURLtoFile(
      await surveyPdf.raw('dataurlstring'),
      'survey.pdf'
    );

    const filePath = await uploadToS3({
      fileType: file.type,
      fileContents: file,
      filePath: `${patient?.id}-${appointmentId}-${form?.id}-${dayjs().format(
        'MM-DD-YYYY'
      )}`,
      storageType: StorageType.Document,
      randomizeFileName: false,
      onProgress: setProgress,
    });

    const name = `${patient?.attributes.firstName} ${
      patient?.attributes.lastName
    } - ${form?.title}: ${dayjs().format('MM-DD-YYYY')}`;

    await insertDocument({
      variables: {
        document: {
          title: name,
          filePath,
          workspaceId: form?.workspaceId,
          patientId: patient?.id,
          appointmentId,
        },
      },
    });
  }

  const [model] = useState(() => {
    ComponentCollection.Instance.clear();

    elementsAsComponentConfiguration.forEach((element) => {
      if (element.elementsJSON && printing) {
        element.elementsJSON = element.elementsJSON.map((el) => ({
          ...el,
          startWithNewLine: true,
        }));
      }
      if (element.name === 'medication') {
        console.log('element', element);
      }
      ComponentCollection.Instance.add(element);
    });

    const newModel = new Model(surveyJSJSON);
    newModel.storeOthersAsComment = false;

    newModel.setVariable('patientId', patient?.id);
    newModel.setVariable('workspaceId', form?.workspaceId);

    // Hint the image upload component to use a scope to ignore full file
    if (presentationContext === 'public') {
      newModel.setVariable('imageUploadSelectionScope', 'user');
    }

    newModel.onComplete.add((sender) => {
      if (printing) {
        generateAndSavePDF(sender.data);
      } else {
        const workaroundData = JSON.parse(JSON.stringify(sender.data));
        onSubmit(workaroundData);
      }
    });

    // Lazy Load Service Categories
    newModel.onChoicesLazyLoad.add((_, options) => {
      if (options.question.name === 'serviceCategoriesOfInterest') {
        searchServiceCategories('').then((services) => {
          const formattedServices = services.map(({ label, value }) => ({
            text: label,
            value,
          }));
          options.setItems(formattedServices, formattedServices.length);
        });
      }
    });

    // Get display values on subsequent loads
    newModel.onGetChoiceDisplayValue.add((_, options) => {
      if (options.question.name === 'serviceCategoriesOfInterest') {
        getCategories({
          variables: {
            where: {
              id: { _in: options.values },
              workspaceId: { _eq: workspace?.id },
            },
          },
        }).then((result) => {
          options.setItems(
            result.data?.serviceCategory.map(({ title }) => title) ?? []
          );
        });
      }
    });

    return newModel;
  });

  useEffect(() => {
    if (model && data) {
      try {
        model.mergeData(data);
        if (printing) {
          // Use setTimeout to break the call stack
          setTimeout(() => {
            generateAndSavePDF(data).catch((error) => {
              console.error('Error generating PDF:', error);
              // Handle the error appropriately (e.g., show an error message to the user)
            });
          }, 0);
        }
      } catch (error) {
        console.error('Error merging data:', error);
        // Handle the error appropriately (e.g., show an error message to the user)
      }
    }
  }, [data, model, printing]);

  return <Survey model={model} showCompletedPage={false} />;
}
