import {
  Box,
  Collapse,
  useDisclosure,
  Button,
  Flex,
  Icon,
  Text,
} from '@chakra-ui/react';
import { ErrorMessage } from '@hookform/error-message';
import { ObjectOption } from '@webapp/types';
import { DataTable, FormInput } from '@webapp/ui';
import { toLocalePrice } from '@webapp/util-formatting';
import { useLoadPackages } from '@webapp/webapp/hooks';
import { useFormContext, useFieldArray, useWatch } from 'react-hook-form';
import { HiTrash } from 'react-icons/hi';
import { Cell } from 'react-table';
// eslint-disable-next-line import/order
import { RiAddBoxLine } from 'react-icons/ri';
import { useEffect, useState } from 'react';
import { InvoiceFormValues } from './types';

export const PackageForm = (): JSX.Element => {
  const {
    control,
    formState: { errors },
    getValues,
    watch,
  } = useFormContext<InvoiceFormValues>();

  const [toggleDisabled, setToggleDisabled] = useState<boolean>(
    Boolean(getValues('memberships')?.length)
  );

  const { isOpen, onToggle } = useDisclosure();

  const { append, fields, remove } = useFieldArray<
    InvoiceFormValues,
    'packages'
  >({
    control,
    name: 'packages',
  });

  const getValue = (id: string) => {
    const index = fields.findIndex((field) => field.id === id);
    const name = `packages.${index}`;

    return getValues(name as any);
  };

  useEffect(() => {
    const packages = getValues('packages');

    if (!isOpen && packages) {
      onToggle();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const sub = watch((data, options) => {
      if (options.name === 'packages' && data.packages?.length) {
        setToggleDisabled(true);
      } else if (options.name === 'packages') {
        setToggleDisabled(false);
      }
    });

    return () => sub.unsubscribe();
  }, [watch]);

  return (
    <Box my={4}>
      <Button isDisabled={toggleDisabled} onClick={onToggle}>
        Add Package
      </Button>
      <Collapse in={isOpen} animateOpacity>
        <Box width="100%" mt={4}>
          <DataTable
            columns={[
              {
                accessor: 'object',
                disableSortBy: true,
                defaultCanSort: false,
                Header: 'Packages',
                id: 'packages.name',
                Cell: (originalRow: Cell<Record<'id', string>>) => {
                  const index = fields.findIndex(
                    (field) => field.id === originalRow.row.original.id
                  );

                  const name = `packages.${index}`;

                  const currentValue = getValue(originalRow.row.original.id);

                  return (
                    <FormInput
                      type="async-select"
                      shouldDisplayError={false}
                      name={name}
                      placeholder="Add Package"
                      style={{
                        minWidth: '200px',
                      }}
                      selectProps={{
                        defaultOption: currentValue,
                        loadOptions: useLoadPackages,
                        transform: (
                          option: ObjectOption<{
                            id: string;
                            name: string;
                            price: number;
                            quantityToUse: number;
                          }>
                        ) => ({
                          id: originalRow.row.original.id,
                          label: option.label,
                          value: option.value,
                          object: {
                            ...option.object,
                            quantityToUse: 0,
                          },
                        }),
                      }}
                    />
                  );
                },
              },
              {
                accessor: 'label',
                Header: 'Price',
                Cell: (originalRow: Cell<Record<'id', string>>) => {
                  const index = fields.findIndex(
                    (field) => field.id === originalRow.row.original.id
                  );

                  const packages = useWatch({ name: 'packages' });

                  const packageObject = packages[index].object;

                  const { price } = packageObject;

                  return <Text>{toLocalePrice(price / 100)}</Text>;
                },
              },
              {
                accessor: (originalRow: Record<'id', string>) =>
                  'quantityToUse',
                Cell: (originalRow: Cell<Record<'id', string>>) => {
                  const index = fields.findIndex(
                    (field) => field.id === originalRow.row.original.id
                  );

                  return (
                    <FormInput
                      min={0}
                      name={`packages[${index}][object][quantityToUse]`}
                      type="number"
                    />
                  );
                },
                disableSortBy: true,
                defaultCanSort: false,
                Header: 'Quantity To Use',
                id: 'packages.quantityToUse',
              },
              {
                accessor: 'id',
                Cell: (originalRow: Cell<Record<'id', string>>) => {
                  const index = fields.findIndex(
                    (el) => el.id === originalRow.row.original.id
                  );

                  return (
                    <Flex justifyContent="end" width="100%">
                      <Button
                        colorScheme="red"
                        onClick={() => {
                          remove(index);
                        }}
                        variant="ghost"
                      >
                        <Icon as={HiTrash} />
                      </Button>
                    </Flex>
                  );
                },
                disableSortBy: true,
                defaultCanSort: false,
                Header: '',
                id: 'services',
                width: 15,
              },
            ]}
            customStyles={{
              borderColor: '#CFEBFF',
              cell: {
                color: '#6C6C72',
              },
              table: {
                borderRadius: '8px',
                minHeight: 'unset',
              },
              tableHeader: {
                background: '#EAFCFF',
                color: '#525257',
                textTransform: 'uppercase',
              },
            }}
            data={fields}
            emptyStateText="Please Add Packages"
            shouldDisplayPagination={false}
          />
          {errors.packages && (
            <ErrorMessage
              errors={errors}
              name={'packages'}
              render={(error) => (
                <Text
                  className="input-error"
                  color="red.500"
                  margin="15px 0 0"
                  _before={{
                    display: 'inline',
                    content: '"⚠ "',
                  }}
                >
                  {error.message ?? 'Incomplete Packages'}
                </Text>
              )}
            />
          )}
          <Box>
            <Button
              color="teal.500"
              justifyContent="start"
              leftIcon={<Icon as={RiAddBoxLine} color="teal.500" />}
              margin="15px 0 0"
              onClick={() =>
                append({
                  label: '',
                  value: '',
                  object: {
                    id: '',
                    name: '',
                    price: 0,
                    quantityToUse: 0,
                  },
                })
              }
              textAlign="left"
              variant="ghost"
              width="max-content"
            >
              Add New Package
            </Button>
          </Box>
        </Box>
      </Collapse>
    </Box>
  );
};

export default PackageForm;
