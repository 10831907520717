import {
  Box,
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  VStack,
} from '@chakra-ui/react';
import { joiResolver } from '@hookform/resolvers/joi';
import { useCustomUpdateDocumentMutation } from '@webapp/graphql';
import { FormInput, HookForm, FormInputV2 } from '@webapp/ui';
import { SubmitHandler, useFormContext } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useLoadDocumentTags } from '@webapp/hooks';
import UpdateDocumentFormValidationSchema from './UpdateDocumentFormValidationSchema';

interface UpdateDocumentFormValues {
  title: string;
  tags: { value: string; label: string }[];
}

type UpdateDocumentFormProps = {
  defaultValues?: Partial<UpdateDocumentFormValues>;
  documentId: string;
  isModal?: boolean;
  isOpen?: boolean;
  onClose?: () => void;
  onCompleted?: () => void;
  refetchQueries?: string[];
};

function UpdateDocumentFields() {
  const { control } = useFormContext();

  return (
    <VStack spacing={4} align="stretch">
      <FormInput label="Title" name="title" />
      <FormInputV2
        control={control}
        label="Tags"
        name="tags"
        placeholder="Select tags"
        loadOptions={useLoadDocumentTags}
        type="async-multiselect"
      />
    </VStack>
  );
}

export function UpdateDocumentForm({
  defaultValues = {},
  documentId,
  isModal = true,
  isOpen = true,
  onClose = () => undefined,
  onCompleted = () => undefined,
  refetchQueries = [],
}: UpdateDocumentFormProps) {
  const [updateDocument, { loading }] = useCustomUpdateDocumentMutation({
    onCompleted: () => {
      toast.success('Document successfully updated');
      onCompleted();
    },
    onError: (e) => {
      toast.error(e.message);
    },
    refetchQueries,
  });

  const initialValues: UpdateDocumentFormValues = {
    title: '',
    tags: [],
    ...defaultValues,
  };

  const submit: SubmitHandler<UpdateDocumentFormValues> = async ({
    title,
    tags,
  }) => {
    updateDocument({
      variables: {
        set: {
          tags: tags.map((tag) => ({
            tagId: tag.value,
          })),
          title,
        },
        id: documentId,
      },
    });
  };

  return (
    <HookForm
      defaultValues={initialValues}
      logErrors
      onSubmit={submit}
      resolver={joiResolver(UpdateDocumentFormValidationSchema)}
    >
      {({ handleSubmit }) => (
        <Box>
          {isModal ? (
            <Modal isOpen={isOpen} onClose={onClose}>
              <ModalOverlay />
              <ModalContent>
                <ModalCloseButton />
                <ModalHeader>Edit Document</ModalHeader>
                <ModalBody>
                  <UpdateDocumentFields />
                </ModalBody>
                <ModalFooter>
                  <ButtonGroup>
                    <Button disabled={loading} onClick={onClose}>
                      Cancel
                    </Button>
                    <Button
                      colorScheme="blue"
                      isLoading={loading}
                      onClick={handleSubmit(submit)}
                    >
                      Update
                    </Button>
                  </ButtonGroup>
                </ModalFooter>
              </ModalContent>
            </Modal>
          ) : (
            <UpdateDocumentFields />
          )}
        </Box>
      )}
    </HookForm>
  );
}

export default UpdateDocumentForm;
