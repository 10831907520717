import { FormInputProps, mobiScrollDatePickerProps } from '@webapp/types';
import { useFormContext } from 'react-hook-form';
import { memo, useEffect, useState } from 'react';
import {
  Button,
  FormLabel,
  Grid,
  Popover,
  PopoverBody,
  PopoverContent,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { FaCalendar } from 'react-icons/fa';

import { ErrorMessage } from '@hookform/error-message';
import dayjs from 'dayjs';
import { Datepicker } from '@mobiscroll/react';
import { PopoverTrigger } from '../popover-trigger/popover-trigger';
import { DEFAULT_LABEL_STYLE } from './constants';
import './DatePickerFormInput.css';

const DATE_FORMAT = 'YYYY-MM-DD';
const TIME_FORMAT = 'hh:mm A';

export function DatePickerFormInput({
  id,
  label,
  labelAlign,
  labelStyle = DEFAULT_LABEL_STYLE,
  name,
  type,
  colors,
  isEditable,
  onPageChange,
}: FormInputProps & mobiScrollDatePickerProps) {
  const {
    isOpen: dateIsOpen,
    onToggle: dateOnToggle,
    onClose: dateOnClose,
  } = useDisclosure();

  const {
    isOpen: timeIsOpen,
    onToggle: timeOnToggle,
    onClose: timeOnClose,
  } = useDisclosure();

  const {
    formState: { errors },
    getValues,
    register,
    setValue,
  } = useFormContext();

  const initialValue = getValues(name);

  const initialDate = dayjs(getValues(name) ?? new Date());

  const initialTime = initialValue
    ? initialDate.format(TIME_FORMAT)
    : '12:00 AM';

  const [time, setTime] = useState<string>(initialTime);
  const [date, setDate] = useState<string>(initialDate.format());

  const inputId = id ?? name;

  useEffect(() => {
    setDate(getValues(name) ?? initialDate.format());
  }, [getValues(name)]);

  useEffect(() => {
    register(name);
  });

  useEffect(() => {
    const formattedDate = dayjs(date).format('YYYY-MM-DD');

    const formattedDateTime = dayjs(
      `${formattedDate} ${time}`,
      `${DATE_FORMAT} ${TIME_FORMAT}`
    ).format();

    setValue(name, formattedDateTime);
  }, [date, name, setValue, time]);

  function handleDateChange({ value }: { value: Date; valueText: string }) {
    setDate(value.toUTCString());
    dateOnClose();
  }

  function handleTimeChange({ valueText }: { valueText: string }) {
    setTime(valueText);
  }

  return (
    <Grid
      alignItems={labelAlign}
      className="form-input-date-picker input-outer"
      gridAutoRows="max-content"
      gridRowGap="6px"
      gridTemplateColumns="1fr"
    >
      {Boolean(label) && (
        <FormLabel
          className="input-label"
          htmlFor={inputId}
          margin="0 0 0 0"
          sx={{
            color:
              'var(--sjs-font-questiontitle-color, var(--sjs-general-forecolor, var(--foreground, #161616)))',
          }}
          {...labelStyle}
        >
          {label}
        </FormLabel>
      )}
      <Grid className="input-inner" gridRowGap="8px">
        <Grid
          border="1px solid"
          borderColor="gray.200"
          borderRadius="var(--chakra-radii-md)"
          gridTemplateColumns={
            type === 'datetime' ? 'repeat(2, 1fr)' : undefined
          }
          height="40px"
        >
          <Popover
            isOpen={dateIsOpen}
            onClose={dateOnClose}
            placement="bottom-start"
          >
            <PopoverTrigger>
              <Button
                borderRadius="0"
                className="date-time-trigger"
                color="text.100"
                fontSize="14px"
                letterSpacing="0.03em"
                onClick={dateOnToggle}
                justifyContent="space-between"
                variant="ghost"
                disabled={isEditable === false}
                rightIcon={<FaCalendar />}
              >
                {dayjs(date).format('MM/DD/YYYY')}
              </Button>
            </PopoverTrigger>
            <PopoverContent>
              <PopoverBody
                {...(!dateIsOpen && { display: 'none' })}
                padding="0"
              >
                <Datepicker
                  className="date-picker__form_input"
                  controls={['calendar']}
                  {...(colors && { colors })}
                  defaultSelection={getValues(name)}
                  display="inline"
                  onPageChange={onPageChange}
                  onChange={handleDateChange}
                  showOuterDays={false}
                  theme="material"
                  themeVariant="light"
                  touchUi={true}
                />
              </PopoverBody>
            </PopoverContent>
          </Popover>
          {type === 'datetime' && (
            <Popover
              isOpen={timeIsOpen}
              onClose={timeOnClose}
              placement="bottom-start"
            >
              <PopoverTrigger>
                <Button
                  borderRadius="0"
                  className="date-time-trigger"
                  color="text.100"
                  fontSize="14px"
                  letterSpacing="0.03em"
                  onClick={timeOnToggle}
                  justifyContent="start"
                  variant="ghost"
                  disabled={isEditable === false}
                >
                  {time}
                </Button>
              </PopoverTrigger>
              <PopoverContent
                css={{
                  '&::-webkit-scrollbar': {
                    display: 'none',
                  },
                }}
                sx={{
                  scrollbarWidth: 'none',
                }}
              >
                <PopoverBody
                  {...(!timeIsOpen && { display: 'none' })}
                  padding="0"
                  css={{
                    '&::-webkit-scrollbar': {
                      display: 'none',
                    },
                  }}
                  sx={{
                    scrollbarWidth: 'none',
                  }}
                >
                  <Datepicker
                    controls={['timegrid']}
                    defaultSelection={time}
                    display="inline"
                    onChange={handleTimeChange}
                    theme="material"
                    themeVariant="light"
                    disabled={isEditable === false}
                  />
                </PopoverBody>
              </PopoverContent>
            </Popover>
          )}
        </Grid>
        <ErrorMessage
          errors={errors}
          name={name}
          render={({ message }) => (
            <Text
              className="input-error"
              color="red.500"
              _before={{
                display: 'inline',
                content: '"⚠ "',
              }}
            >
              {message}
            </Text>
          )}
        />
      </Grid>
    </Grid>
  );
}

export default memo(DatePickerFormInput);
