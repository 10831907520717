import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { DeviceTypeFieldsFragmentDoc } from '../../fragments/deviceTypeFields.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetDeviceTypeQueryVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
}>;


export type GetDeviceTypeQuery = { __typename?: 'query_root', deviceType_by_pk?: { __typename?: 'deviceType', id: any, value: string } | null | undefined };


export const GetDeviceTypeDocument = gql`
    query GetDeviceType($id: uuid!) {
  deviceType_by_pk(id: $id) {
    ...DeviceTypeFields
  }
}
    ${DeviceTypeFieldsFragmentDoc}`;

/**
 * __useGetDeviceTypeQuery__
 *
 * To run a query within a React component, call `useGetDeviceTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDeviceTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDeviceTypeQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetDeviceTypeQuery(baseOptions: Apollo.QueryHookOptions<GetDeviceTypeQuery, GetDeviceTypeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDeviceTypeQuery, GetDeviceTypeQueryVariables>(GetDeviceTypeDocument, options);
      }
export function useGetDeviceTypeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDeviceTypeQuery, GetDeviceTypeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDeviceTypeQuery, GetDeviceTypeQueryVariables>(GetDeviceTypeDocument, options);
        }
export type GetDeviceTypeQueryHookResult = ReturnType<typeof useGetDeviceTypeQuery>;
export type GetDeviceTypeLazyQueryHookResult = ReturnType<typeof useGetDeviceTypeLazyQuery>;
export type GetDeviceTypeQueryResult = Apollo.QueryResult<GetDeviceTypeQuery, GetDeviceTypeQueryVariables>;