import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type SendAppointmentRescheduleConfirmationMutationVariables = Types.Exact<{
  appointmentId: Types.Scalars['uuid'];
}>;


export type SendAppointmentRescheduleConfirmationMutation = { __typename?: 'mutation_root', sendAppointmentRescheduleConfirmation?: { __typename?: 'SuccessAnswer', success: boolean } | null | undefined };


export const SendAppointmentRescheduleConfirmationDocument = gql`
    mutation SendAppointmentRescheduleConfirmation($appointmentId: uuid!) {
  sendAppointmentRescheduleConfirmation(appointmentId: $appointmentId) {
    success
  }
}
    `;
export type SendAppointmentRescheduleConfirmationMutationFn = Apollo.MutationFunction<SendAppointmentRescheduleConfirmationMutation, SendAppointmentRescheduleConfirmationMutationVariables>;

/**
 * __useSendAppointmentRescheduleConfirmationMutation__
 *
 * To run a mutation, you first call `useSendAppointmentRescheduleConfirmationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendAppointmentRescheduleConfirmationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendAppointmentRescheduleConfirmationMutation, { data, loading, error }] = useSendAppointmentRescheduleConfirmationMutation({
 *   variables: {
 *      appointmentId: // value for 'appointmentId'
 *   },
 * });
 */
export function useSendAppointmentRescheduleConfirmationMutation(baseOptions?: Apollo.MutationHookOptions<SendAppointmentRescheduleConfirmationMutation, SendAppointmentRescheduleConfirmationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendAppointmentRescheduleConfirmationMutation, SendAppointmentRescheduleConfirmationMutationVariables>(SendAppointmentRescheduleConfirmationDocument, options);
      }
export type SendAppointmentRescheduleConfirmationMutationHookResult = ReturnType<typeof useSendAppointmentRescheduleConfirmationMutation>;
export type SendAppointmentRescheduleConfirmationMutationResult = Apollo.MutationResult<SendAppointmentRescheduleConfirmationMutation>;
export type SendAppointmentRescheduleConfirmationMutationOptions = Apollo.BaseMutationOptions<SendAppointmentRescheduleConfirmationMutation, SendAppointmentRescheduleConfirmationMutationVariables>;