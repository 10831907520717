import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { InvoiceTemplateFieldsFragmentDoc } from '../../fragments/invoiceTemplateFields.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetInvoiceTemplateQueryVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
}>;


export type GetInvoiceTemplateQuery = { __typename?: 'query_root', invoiceTemplate_by_pk?: { __typename?: 'invoiceTemplate', id: any, title: string, invoiceValues: any } | null | undefined };


export const GetInvoiceTemplateDocument = gql`
    query GetInvoiceTemplate($id: uuid!) {
  invoiceTemplate_by_pk(id: $id) {
    ...InvoiceTemplateFields
  }
}
    ${InvoiceTemplateFieldsFragmentDoc}`;

/**
 * __useGetInvoiceTemplateQuery__
 *
 * To run a query within a React component, call `useGetInvoiceTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvoiceTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvoiceTemplateQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetInvoiceTemplateQuery(baseOptions: Apollo.QueryHookOptions<GetInvoiceTemplateQuery, GetInvoiceTemplateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInvoiceTemplateQuery, GetInvoiceTemplateQueryVariables>(GetInvoiceTemplateDocument, options);
      }
export function useGetInvoiceTemplateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInvoiceTemplateQuery, GetInvoiceTemplateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInvoiceTemplateQuery, GetInvoiceTemplateQueryVariables>(GetInvoiceTemplateDocument, options);
        }
export type GetInvoiceTemplateQueryHookResult = ReturnType<typeof useGetInvoiceTemplateQuery>;
export type GetInvoiceTemplateLazyQueryHookResult = ReturnType<typeof useGetInvoiceTemplateLazyQuery>;
export type GetInvoiceTemplateQueryResult = Apollo.QueryResult<GetInvoiceTemplateQuery, GetInvoiceTemplateQueryVariables>;