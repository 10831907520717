import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type DeleteFinixTerminalMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
}>;


export type DeleteFinixTerminalMutation = { __typename?: 'mutation_root', delete_finixTerminal_by_pk?: { __typename?: 'finixTerminal', id: any } | null | undefined };


export const DeleteFinixTerminalDocument = gql`
    mutation deleteFinixTerminal($id: uuid!) {
  delete_finixTerminal_by_pk(id: $id) {
    id
  }
}
    `;
export type DeleteFinixTerminalMutationFn = Apollo.MutationFunction<DeleteFinixTerminalMutation, DeleteFinixTerminalMutationVariables>;

/**
 * __useDeleteFinixTerminalMutation__
 *
 * To run a mutation, you first call `useDeleteFinixTerminalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFinixTerminalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFinixTerminalMutation, { data, loading, error }] = useDeleteFinixTerminalMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteFinixTerminalMutation(baseOptions?: Apollo.MutationHookOptions<DeleteFinixTerminalMutation, DeleteFinixTerminalMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteFinixTerminalMutation, DeleteFinixTerminalMutationVariables>(DeleteFinixTerminalDocument, options);
      }
export type DeleteFinixTerminalMutationHookResult = ReturnType<typeof useDeleteFinixTerminalMutation>;
export type DeleteFinixTerminalMutationResult = Apollo.MutationResult<DeleteFinixTerminalMutation>;
export type DeleteFinixTerminalMutationOptions = Apollo.BaseMutationOptions<DeleteFinixTerminalMutation, DeleteFinixTerminalMutationVariables>;