import { StorageType } from '@webapp/graphql';
import { saveSignatureAndConvertToFile } from '@webapp/utils';
import ReactSignatureCanvas from 'react-signature-canvas';
import useUploadToS3 from '../useUploadToS3/useUploadToS3';

interface UploadSignatureParams {
  patientId?: string;
  providerId?: string;
  setProgress?: (progress: number) => void;
  signature: ReactSignatureCanvas;
  userId?: string;
}

export function useUploadSignature() {
  const { uploadToS3 } = useUploadToS3();

  return async ({
    patientId,
    providerId,
    setProgress = () => undefined,
    signature,
    userId,
  }: UploadSignatureParams) => {
    const relevantId = patientId || providerId || userId;

    const fileName = `${relevantId}-${new Date().getTime()}.png`;

    const file = await saveSignatureAndConvertToFile(signature, fileName);

    const filePath = await uploadToS3({
      fileType: file.type,
      fileContents: file,
      filePath: file.name,
      storageType: StorageType.Document,
      onProgress: setProgress,
    });

    return filePath;
  };
}

export default useUploadSignature;
