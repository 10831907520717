/* eslint-disable prefer-arrow-callback */
import { useEffect, useState, useCallback, useRef } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import {
  Button,
  Heading,
  HStack,
  Stack,
  Image,
  Flex,
  Text,
  SimpleGrid,
} from '@chakra-ui/react';
import { observer } from 'mobx-react-lite';
import { LeadForm } from '@webapp/state-models';
import {
  BookExternalAppointmentInput,
  useBookExternalAppointmentMutation,
  useGetLeadFormLinkExternalLazyQuery,
  usePayWithNewMethodMutation,
} from '@webapp/graphql';
import { FormComplete } from '@webapp/ui';
import InnerHTML from 'dangerously-set-html-content';
import { PayForm, PayrixPayfieldsProps } from '@webapp/ui-composites';
import toast from 'react-hot-toast';
import FieldFactory from './fieldFactory';
import ExternalBooking from '../external-booking/external-booking';
import PaymentExplanation from '../payment-explanation';
import LoadingFullScreen from '../../../../../ui-composites/src/lib/loading-full-screen/loading-full-screen';

const DEFAULT_SIDE_BANNER_IMAGE =
  'https://images.unsplash.com/photo-1544717304-a2db4a7b16ee?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=776&q=80';

/* eslint-disable-next-line */
export interface ConfigurableFormProps {
  leadForm?: LeadForm;
  leadFormLinkId?: string | null;
  stepOverride?: number;
  preview?: boolean;
  onStepChange?: (stepIndex: number) => void;
  prefillData?: any;
}

export const ConfigurableForm = observer(function ConfigurableForm({
  leadForm,
  stepOverride,
  preview = false,
  onStepChange,
  leadFormLinkId,
  prefillData,
}: ConfigurableFormProps) {
  const [currentStepIndex, setCurrentStepIndex] = useState<number>(0);
  const containerRef = useRef<HTMLDivElement>(null);
  const [formState, setFormState] = useState(
    leadFormLinkId ? 'initializing' : 'ready'
  );
  const [locationPayrixMerchantId, setLocationPayrixMerchantId] =
    useState<string>();
  const [bookExternalAppointment, { loading: bookingAppointment }] =
    useBookExternalAppointmentMutation();
  const [getLeadFormLink, { data: leadFormLinkData }] =
    useGetLeadFormLinkExternalLazyQuery();
  const [payWithNewMethod] = usePayWithNewMethodMutation({
    onCompleted: () => {
      closePaymentAndNext();
    },
    onError: (err) => {
      console.error(err);
      toast.error('There was an error processing your payment');
    },
  });

  useEffect(() => {
    if (leadFormLinkId) {
      getLeadFormLink({ variables: { id: leadFormLinkId } });
    }
  }, [leadFormLinkId]);

  useEffect(() => {
    if (stepOverride === undefined) return;
    setCurrentStepIndex(stepOverride);
  }, [stepOverride]);

  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  useEffect(() => {
    if (containerRef.current) {
      setDimensions({
        width: containerRef.current.offsetWidth,
        height: containerRef.current.offsetHeight,
      });
    }
  }, []);

  const [showPaymentExplanationStep, setShowPaymentExplanationStep] =
    useState(false);
  const [showPaymentStep, setShowPaymentStep] = useState(false);

  useEffect(() => {
    if (onStepChange && currentStepIndex !== stepOverride)
      onStepChange(currentStepIndex);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStepIndex]);

  const currentStep = leadForm?.sortedSteps[currentStepIndex];

  const methods = useForm<any>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: {},
    criteriaMode: 'firstError',
    shouldFocusError: true,
    shouldUnregister: false,
    shouldUseNativeValidation: false,
    delayError: undefined,
  });

  const {
    handleSubmit,
    register,
    formState: { errors },
    getValues,
    setValue,
  } = methods;

  useEffect(() => {
    if (leadFormLinkData) {
      const bookAppointmentStepIndex = leadForm?.steps.findIndex(
        (s) => s.type === 'book-appointment'
      );

      if (
        bookAppointmentStepIndex !== undefined &&
        bookAppointmentStepIndex !== -1
      ) {
        setValue('patientId', leadFormLinkData?.leadFormLink_by_pk?.patientId);
        setValue(
          'location',
          leadFormLinkData?.leadFormLink_by_pk?.formData?.locationId
        );
        setValue(
          'providerId',
          leadFormLinkData?.leadFormLink_by_pk?.formData?.providerId
        );
        setCurrentStepIndex(bookAppointmentStepIndex);
        setTimeout(() => setFormState('ready'), 500);
      } else {
        setTimeout(() => setFormState('ready'), 500);
      }
    }
  }, [leadFormLinkData]);

  useEffect(() => {
    if (prefillData) {
      Object.keys(prefillData).forEach((key) => {
        const value = prefillData[key];
        if (value !== undefined) {
          setValue(key, value);
        }
      });

      if ((prefillData?.firstName, prefillData?.lastName, prefillData?.email)) {
        if (currentStepIndex < leadForm?.steps?.length - 1) {
          onSubmit(getValues());
        }
      }
    }
  }, [prefillData]);

  useEffect(() => {
    const noShowActive =
      leadForm?.workspace?.workspaceConfiguration?.noShowActive;

    const noShowFee = leadForm?.workspace?.workspaceConfiguration?.noShowFee;

    if (noShowActive && noShowFee) {
      setValue('noShowFee', noShowFee);
    }
  }, [
    leadForm?.workspace?.workspaceConfiguration?.noShowActive,
    leadForm?.workspace?.workspaceConfiguration?.noShowFee,
    setValue,
  ]);

  const { prepayment, noShowFee } = methods.watch();

  const goNextOrClose = (): void => {
    if (!leadForm?.steps) return;

    if (currentStepIndex < leadForm?.steps?.length - 1) {
      setCurrentStepIndex(currentStepIndex + 1);
    } else {
      window.parent.postMessage('close-me', '*');
    }
  };

  async function onSubmit(values: any) {
    if (!leadForm?.steps) return;

    if (showPaymentStep) return;

    if (showPaymentExplanationStep) {
      setShowPaymentStep(true);
      setShowPaymentExplanationStep(false);
      return;
    }

    if (currentStep?.type === 'book-appointment') {
      if (prepayment || noShowFee) {
        setShowPaymentExplanationStep(true);
        return;
      }
      await bookExternalAppointment({
        variables: {
          input: {
            email: values.email,
            firstName: values.firstName,
            lastName: values.lastName,
            patientId: values.patientId,
            phoneNumber: values.phoneNumber,
            locationId: values.location,
            serviceIds: values.serviceIds,
            providerId: values.providerId,
            time: values.dateTime,
            workspaceId: leadForm.workspaceId,
          },
        },
      });
    }

    if (preview) {
      console.log(JSON.stringify(values, null, 2));
    } else {
      const mappedFieldValues = leadForm.allFields
        .map((field) => ({
          leadFormFieldId: field.id,
          value: values[field.apiFieldKey],
          apiFieldKey: field.apiFieldKey,
        }))
        .filter(({ value }) => value !== undefined);

      await leadForm.submitForm({ values, mappedFieldValues });
    }

    goNextOrClose();
  }

  const getBaseValues = useCallback((): Omit<
    BookExternalAppointmentInput,
    | 'billingAddress'
    | 'paymentId'
    | 'noShowFeePaymentMethodId'
    | 'approvedNoShowFeeAmount'
  > => {
    const values = getValues();

    return {
      email: values.email,
      patientId: values.patientId,
      firstName: values.firstName,
      lastName: values.lastName,
      phoneNumber: values.phoneNumber,
      locationId: values.location,
      serviceIds: values.serviceIds,
      providerId: values.providerId,
      time: values.dateTime,
      workspaceId: leadForm?.workspaceId,
    };
  }, [getValues, leadForm?.workspaceId]);

  const closePaymentAndNext = (): void => {
    setShowPaymentStep(false);
    goNextOrClose();
  };

  const payAndBookAppointment: PayrixPayfieldsProps<'token'>['onPayWithNewMethod'] =
    useCallback(
      ({ token }) => {
        const values = getBaseValues();

        payWithNewMethod({
          variables: {
            amount: prepayment ?? noShowFee,
            caseName: prepayment ? 'EXTERNAL_DEPOSIT' : 'EXTERNAL_NO_SHOW_FEE',
            caseContext: {
              ...values,
              billingAddress: token.customer.address1,
            },
            paymentInfo: token,
            payrixMerchantId:
              locationPayrixMerchantId ??
              leadForm?.workspace.payrixMerchantId ??
              '',
            workspaceId: leadForm?.workspaceId ?? '',
          },
        });
      },
      [
        getBaseValues,
        leadForm?.workspace.payrixMerchantId,
        leadForm?.workspaceId,
        locationPayrixMerchantId,
        noShowFee,
        payWithNewMethod,
        prepayment,
      ]
    );

  const renderCurrentStep = useCallback(
    function renderCurrentStep() {
      const authOnly = Boolean(!prepayment && noShowFee);
      const amount = prepayment ?? noShowFee;

      if (showPaymentExplanationStep) {
        return <PaymentExplanation amount={amount} authOnly={authOnly} />;
      }

      if (showPaymentStep) {
        return (
          <PayForm
            amount={amount}
            authenticated={false}
            mode="token"
            type="payrix"
            onPayWithNewMethod={payAndBookAppointment}
            payrixMerchantId={
              locationPayrixMerchantId ??
              leadForm?.workspace.payrixMerchantId ??
              ''
            }
            showPaymentAmount={!authOnly}
            workspaceId={leadForm?.workspaceId ?? ''}
          />
        );
      }

      switch (currentStep?.type) {
        case 'success-page': {
          if (currentStep?.properties?.redirectUrl) {
            if (window.location !== window.parent.location) {
              // in iframe
              window.parent.postMessage(
                `close-me:${currentStep?.properties?.redirectUrl}`,
                '*'
              );
              window.parent.location.href =
                currentStep?.properties?.redirectUrl;
            } else {
              // Not in an iframe
              window.location.href = currentStep?.properties?.redirectUrl;
            }
          }
          return <FormComplete step={currentStep} />;
        }

        case 'book-appointment':
          return (
            <ExternalBooking
              workspaceId={leadForm?.workspaceId as string}
              hasLeadFormLink={!!leadFormLinkId}
              presetServiceIds={
                leadFormLinkData?.leadFormLink_by_pk?.formData?.serviceIds
              }
              onSelectedLocation={(location) => {
                if (location?.payrixMerchantId) {
                  setLocationPayrixMerchantId(location?.payrixMerchantId);
                }
              }}
              lockedToLocationIds={
                leadForm?.lockedToLocations?.map((l) => l.value) ?? []
              }
              lockedToServiceIds={
                leadForm?.lockedToServices?.map((s) => s.value) ?? []
              }
            />
          );
        default:
          return (
            <SimpleGrid minChildWidth={'300px'} spacing={8} pt={8}>
              {currentStep?.sortedFields.map((field) => (
                <FieldFactory
                  key={field.id}
                  field={field}
                  register={register}
                  errors={errors}
                />
              ))}
            </SimpleGrid>
          );
      }
    },
    [
      prepayment,
      noShowFee,
      showPaymentExplanationStep,
      showPaymentStep,
      currentStep,
      payAndBookAppointment,
      locationPayrixMerchantId,
      leadForm?.workspace.payrixMerchantId,
      leadForm?.workspaceId,
      leadForm?.lockedToLocations,
      leadForm?.lockedToServices,
      leadFormLinkId,
      leadFormLinkData?.leadFormLink_by_pk?.formData?.serviceIds,
      register,
      errors,
    ]
  );

  if (formState === 'initializing') return <LoadingFullScreen />;

  return (
    <div>
      {leadForm?.headerCode && <InnerHTML html={leadForm?.headerCode} />}
      <FormProvider {...methods}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          style={{ height: '100%', width: '100%' }}
        >
          <Stack
            ref={containerRef}
            justifyContent={'center'}
            alignItems="center"
            bg="gray.50"
            bgImage={`url(${
              leadForm?.formBackground ||
              'https://images.unsplash.com/photo-1495195129352-aeb325a55b65?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1752&q=80'
            })`}
            h={'100vh'}
            w={'screen'}
            overflowY="scroll"
            sx={{
              '&::-webkit-scrollbar': {
                width: '0px',
                borderRadius: '8px',
                backgroundColor: `rgba(0, 0, 0, 0.05)`,
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: `rgba(0, 0, 0, 0.05)`,
              },
            }}
          >
            <HStack
              rounded={{ base: 'none', lg: 'lg' }}
              shadow={{ base: 'none', lg: 'xl' }}
              bg={'white'}
              w={{ base: 'full', lg: dimensions.width > 800 ? '60%' : '90%' }}
              h={{ base: 'full', lg: 'auto' }}
              marginTop={{ base: '0', lg: '20px' }}
              alignItems="flex-start"
            >
              {leadForm?.layout === 'LEFT_BANNER' && (
                <Flex h={'full'} w={'full'} flex={1}>
                  <Image
                    maxW={300}
                    objectFit={'cover'}
                    src={leadForm?.sideBannerImage || DEFAULT_SIDE_BANNER_IMAGE}
                  />
                </Flex>
              )}
              <Stack
                spacing="4"
                padding={10}
                flex={3}
                flexGrow={1}
                minW={400}
                minH={600}
                maxH="90vh"
                overflowY="scroll"
                sx={{
                  '&::-webkit-scrollbar': {
                    width: '0px',
                    borderRadius: '8px',
                    backgroundColor: `rgba(0, 0, 0, 0.05)`,
                  },
                  '&::-webkit-scrollbar-thumb': {
                    backgroundColor: `rgba(0, 0, 0, 0.05)`,
                  },
                }}
              >
                <Image
                  key={leadForm?.businessLogo}
                  maxW={300}
                  maxH={100}
                  alignSelf={'center'}
                  objectFit={'contain'}
                  mb={0}
                  src={leadForm?.businessLogo || undefined}
                />

                <Heading
                  size="lg"
                  mb={4}
                  fontWeight="extrabold"
                  letterSpacing="tight"
                  textAlign={'center'}
                >
                  {currentStep?.name}
                </Heading>
                <Stack
                  justifyContent={'space-between'}
                  spacing={8}
                  w={'full'}
                  h={'full'}
                >
                  {renderCurrentStep()}

                  <Button
                    disabled={showPaymentStep}
                    type="submit"
                    isLoading={formState === 'submitting' || bookingAppointment}
                    colorScheme="blue"
                    size="lg"
                    fontSize="md"
                    justifySelf={'flex-end'}
                  >
                    {currentStep?.callToAction || 'Continue'}
                  </Button>
                  <Text
                    size="xs"
                    fontSize={'12px'}
                    color="gray.600"
                    textAlign={'center'}
                  >
                    By continuing, you agree to our terms and conditions and to
                    receiving marketing communications.
                  </Text>
                </Stack>
              </Stack>
              {leadForm?.layout === 'RIGHT_BANNER' && (
                <Flex h={'full'} w={'full'} flex={1}>
                  <Image
                    maxW={300}
                    objectFit={'cover'}
                    src={leadForm?.sideBannerImage || DEFAULT_SIDE_BANNER_IMAGE}
                  />
                </Flex>
              )}
            </HStack>
          </Stack>
        </form>
      </FormProvider>
    </div>
  );
});

export default ConfigurableForm;
