import * as Types from '../../generated/graphql';

import { gql } from '@apollo/client';
import { FullConsumableFieldsFragmentDoc } from './fullConsumableFields.fragment.generated';
export type InventoryFieldsFragment = { __typename?: 'InventoryItem', account: any, quantity: number, createdAt: string, updatedAt: string, consumable: { __typename?: 'FullConsumable', id: any, originalQuantity: number, quantity: number, metadata: any, cost: number, isExpirable: boolean, expirationDate?: string | null | undefined, daysBeforeNotification?: number | null | undefined, shouldWarnQuantity: boolean, warningQuantity?: number | null | undefined, archived: boolean, createdAt: string, updatedAt: string, type: { __typename?: 'IdNameRequiredFields', id: any, name: string, requiredFields: any, unitStep: number, unitName: string, defaultNumberOfUnits: number, defaultPricePerUnit: number }, brand: { __typename?: 'IdName', id: any, name: string }, purchaseOrder?: { __typename?: 'IdOrderNumber', id: any, orderNumber: string } | null | undefined }, metadata: { __typename?: 'InventoryItemMetadata', stockAlert: boolean, expiration: { __typename?: 'InventoryMetadataExpiration', status: string, daysUntilExpiration: number } } };

export const InventoryFieldsFragmentDoc = gql`
    fragment InventoryFields on InventoryItem {
  account
  quantity
  consumable {
    ...FullConsumableFields
  }
  metadata {
    stockAlert
    expiration {
      status
      daysUntilExpiration
    }
  }
  createdAt
  updatedAt
}
    ${FullConsumableFieldsFragmentDoc}`;