import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type InsertCustomFormRowMutationVariables = Types.Exact<{
  rows: Array<Types.CustomFormRow_Insert_Input> | Types.CustomFormRow_Insert_Input;
}>;


export type InsertCustomFormRowMutation = { __typename?: 'mutation_root', insert_customFormRow?: { __typename?: 'customFormRow_mutation_response', returning: Array<{ __typename?: 'customFormRow', id: any }> } | null | undefined };


export const InsertCustomFormRowDocument = gql`
    mutation insertCustomFormRow($rows: [customFormRow_insert_input!]!) {
  insert_customFormRow(objects: $rows) {
    returning {
      id
    }
  }
}
    `;
export type InsertCustomFormRowMutationFn = Apollo.MutationFunction<InsertCustomFormRowMutation, InsertCustomFormRowMutationVariables>;

/**
 * __useInsertCustomFormRowMutation__
 *
 * To run a mutation, you first call `useInsertCustomFormRowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertCustomFormRowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertCustomFormRowMutation, { data, loading, error }] = useInsertCustomFormRowMutation({
 *   variables: {
 *      rows: // value for 'rows'
 *   },
 * });
 */
export function useInsertCustomFormRowMutation(baseOptions?: Apollo.MutationHookOptions<InsertCustomFormRowMutation, InsertCustomFormRowMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertCustomFormRowMutation, InsertCustomFormRowMutationVariables>(InsertCustomFormRowDocument, options);
      }
export type InsertCustomFormRowMutationHookResult = ReturnType<typeof useInsertCustomFormRowMutation>;
export type InsertCustomFormRowMutationResult = Apollo.MutationResult<InsertCustomFormRowMutation>;
export type InsertCustomFormRowMutationOptions = Apollo.BaseMutationOptions<InsertCustomFormRowMutation, InsertCustomFormRowMutationVariables>;