import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetEmailCampaignMetricsByIdQueryVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
}>;


export type GetEmailCampaignMetricsByIdQuery = { __typename?: 'query_root', emailTemplate?: { __typename?: 'emailTemplate', id: any, emailName?: string | null | undefined, bounces: number, clicks: number, deliveries: number, opens: number, spamFlags: number, unsubscribes: number, metrics?: { __typename?: 'emailCampaignMetrics', bounces?: any | null | undefined, clicks?: any | null | undefined, deliveries?: any | null | undefined, opens?: any | null | undefined, spamcomplaints?: any | null | undefined, unsubscribes?: any | null | undefined } | null | undefined, emailCampaignLogs: Array<{ __typename?: 'emailCampaignLog', id: any, recipient: string, timestamp: any, type: string, patientWorkspace: { __typename?: 'patientWorkspace', patientId: any, firstName?: string | null | undefined, lastName?: string | null | undefined } }> } | null | undefined };


export const GetEmailCampaignMetricsByIdDocument = gql`
    query getEmailCampaignMetricsById($id: uuid!) {
  emailTemplate: emailTemplate_by_pk(id: $id) {
    id
    emailName
    metrics {
      bounces
      clicks
      deliveries
      opens
      spamcomplaints
      unsubscribes
    }
    bounces
    clicks
    deliveries
    opens
    spamFlags
    unsubscribes
    emailCampaignLogs(order_by: {timestamp: desc}) {
      id
      patientWorkspace {
        patientId
        firstName
        lastName
      }
      recipient
      timestamp
      type
    }
  }
}
    `;

/**
 * __useGetEmailCampaignMetricsByIdQuery__
 *
 * To run a query within a React component, call `useGetEmailCampaignMetricsByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmailCampaignMetricsByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmailCampaignMetricsByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetEmailCampaignMetricsByIdQuery(baseOptions: Apollo.QueryHookOptions<GetEmailCampaignMetricsByIdQuery, GetEmailCampaignMetricsByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEmailCampaignMetricsByIdQuery, GetEmailCampaignMetricsByIdQueryVariables>(GetEmailCampaignMetricsByIdDocument, options);
      }
export function useGetEmailCampaignMetricsByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEmailCampaignMetricsByIdQuery, GetEmailCampaignMetricsByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEmailCampaignMetricsByIdQuery, GetEmailCampaignMetricsByIdQueryVariables>(GetEmailCampaignMetricsByIdDocument, options);
        }
export type GetEmailCampaignMetricsByIdQueryHookResult = ReturnType<typeof useGetEmailCampaignMetricsByIdQuery>;
export type GetEmailCampaignMetricsByIdLazyQueryHookResult = ReturnType<typeof useGetEmailCampaignMetricsByIdLazyQuery>;
export type GetEmailCampaignMetricsByIdQueryResult = Apollo.QueryResult<GetEmailCampaignMetricsByIdQuery, GetEmailCampaignMetricsByIdQueryVariables>;