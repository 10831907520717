import {
  Instance,
  SnapshotOut,
  types,
  flow,
  applySnapshot,
  getSnapshot,
} from 'mobx-state-tree';
import { AnyJsonValue } from '../custom-types/any-json-value';
import { MarketingTagModel } from './marketing-tag';

/**
 * An EmailTemplateModel model.
 */
// prettier-ignore
export const EmailTemplateModel = types.model('EmailTemplateModel').props({
  id: types.maybeNull(types.string),
  design: types.maybeNull(AnyJsonValue),
  fromName: types.maybeNull(types.string),
  fromAddress: types.maybeNull(types.string),
  subjectLine: types.maybeNull(types.string),
  emailName: types.maybeNull(types.string),
  html: types.maybeNull(types.string),
  targetAudienceId: types.maybeNull(types.string),
  omitAudienceId: types.maybeNull(types.string),
  testEmail: types.maybeNull(types.string),
  includesPromotion: types.boolean,
  sendDateTime:types.maybeNull(types.string),
  isActive: types.optional(types.boolean, true),
  allowResend: types.boolean,
  resendWindow: types.maybeNull(types.number),
  description: types.maybeNull(types.string),
  isTemplate: types.optional(types.boolean, false),
  marketingTags: types.optional(types.array(MarketingTagModel), []),
  audienceType: types.string,
  screenshotUrl: types.maybeNull(types.string),
  
  sendType: types.string,
}).actions((self) => ({
    update: flow(function* (set:any) {
    //  const client = GraphqlClient();
    }),
    setId: (id: string) => {
        self.id = id;
    },
    setDesign: (design: any) => {
        self.design = design;
    },
    setDescription: (description: string) => {
        self.description = description;
    },
    setFromName: (name: string) => {
        self.fromName = name;
    },
    setFromAddress: (address: string) => {
        self.fromAddress = address;
    },
    setSubjectLine: (subjectLine: string) => {
        self.subjectLine = subjectLine;
    },
    setEmailName: (emailName: string) => {
        self.emailName = emailName;
    },
    setTargetAudienceId: (audienceId: string) => {
        self.targetAudienceId = audienceId;
    },
    setHtml: (html: string) => {
        self.html = html;
    },
    setTestEmail: (testEmail: string) => {
        self.testEmail = testEmail;
    },
    setOmitAudienceId: (audienceId: string) => {
        self.omitAudienceId = audienceId;
    },
    setIncludesPromotion: (include: boolean) => {
        self.includesPromotion = include;
    },
    setSendDateTime: (sendDate: string) => {
        self.sendDateTime = sendDate;
    },
    setAudienceType: (audienceType: string) => {
        self.audienceType = audienceType;
    },
    setSendType: (sendType: string) => {
        self.sendType = sendType;
    },
    setIsActive: (isActive: boolean) => {
        self.isActive = isActive;
    },
    setIsTemplate: (isTemplate: boolean) => {
        self.isTemplate = isTemplate;
    },
    setAllowResend: (allowResend: boolean) => {
        self.allowResend = allowResend;
    },
    setResendWindow: (resendWindow: number) => {
        self.resendWindow = resendWindow;
    },
    addMarketingTag: (tag: string, id: string) => {
        self.marketingTags.push({title: tag, id});
    },
    removeMarketingTag: (tagId: string) => {
        self.marketingTags.replace(self.marketingTags.filter(({id})=> id !== tagId));
    },
    getMarketingTags: (): any => {
        return getSnapshot(self.marketingTags);
    }
}));

/**
 * The EmailTemplate instance.
 */
export type EmailTemplate = Instance<typeof EmailTemplateModel>;
