import { ApolloError } from '@apollo/client';
import {
  GoogleOnboardingStatusQuery,
  useGoogleOnboardingStatusQuery,
} from '@webapp/graphql';
import React, { createContext, useContext, ReactNode } from 'react';

type GoogleOnboardingStatusContextType = {
  data: GoogleOnboardingStatusQuery;
  loading: boolean;
  error: ApolloError | undefined;
};

const GoogleOnboardingStatusContext = createContext<
  GoogleOnboardingStatusContextType | undefined
>(undefined);

export const GoogleOnboardingStatusProvider: React.FC<{
  children: ReactNode;
}> = ({ children }) => {
  const { data, loading, error } = useGoogleOnboardingStatusQuery();

  if (!data) {
    return <div></div>;
  }

  return (
    <GoogleOnboardingStatusContext.Provider value={{ data, loading, error }}>
      {children}
    </GoogleOnboardingStatusContext.Provider>
  );
};

export const useGoogleOnboardingStatus = () => {
  const context = useContext(GoogleOnboardingStatusContext);
  if (context === undefined) {
    throw new Error(
      'useGoogleOnboardingStatus must be used within a GoogleOnboardingStatusProvider'
    );
  }
  return context;
};
