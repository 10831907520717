import * as Types from '../../generated/graphql';

import { gql } from '@apollo/client';
export type ProviderSearchFieldsFragment = { __typename?: 'provider', id: any, firstName: string, lastName: string, profilePicture?: string | null | undefined, email: string, phoneNumber?: string | null | undefined, address?: string | null | undefined, title?: string | null | undefined, stateLicense?: string | null | undefined, upin?: string | null | undefined, dea?: string | null | undefined, npi?: string | null | undefined };

export const ProviderSearchFieldsFragmentDoc = gql`
    fragment ProviderSearchFields on provider {
  id
  firstName
  lastName
  profilePicture
  email
  phoneNumber
  address
  title
  stateLicense
  upin
  dea
  npi
}
    `;