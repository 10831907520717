import * as Types from '../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type PayWithNewMethodMutationVariables = Types.Exact<{
  amount: Types.Scalars['Int'];
  caseName: Types.Scalars['String'];
  caseContext: Types.Scalars['jsonb'];
  patientId?: Types.Maybe<Types.Scalars['uuid']>;
  paymentInfo: Types.Scalars['jsonb'];
  payrixMerchantId?: Types.Maybe<Types.Scalars['String']>;
  finixMerchantId?: Types.Maybe<Types.Scalars['String']>;
  workspaceId: Types.Scalars['uuid'];
}>;


export type PayWithNewMethodMutation = { __typename?: 'mutation_root', payWithNewMethod: { __typename?: 'SuccessAnswer', success: boolean } };


export const PayWithNewMethodDocument = gql`
    mutation PayWithNewMethod($amount: Int!, $caseName: String!, $caseContext: jsonb!, $patientId: uuid, $paymentInfo: jsonb!, $payrixMerchantId: String, $finixMerchantId: String, $workspaceId: uuid!) {
  payWithNewMethod(
    amount: $amount
    caseName: $caseName
    caseContext: $caseContext
    patientId: $patientId
    paymentInfo: $paymentInfo
    payrixMerchantId: $payrixMerchantId
    workspaceId: $workspaceId
    finixMerchantId: $finixMerchantId
  ) {
    success
  }
}
    `;
export type PayWithNewMethodMutationFn = Apollo.MutationFunction<PayWithNewMethodMutation, PayWithNewMethodMutationVariables>;

/**
 * __usePayWithNewMethodMutation__
 *
 * To run a mutation, you first call `usePayWithNewMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePayWithNewMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [payWithNewMethodMutation, { data, loading, error }] = usePayWithNewMethodMutation({
 *   variables: {
 *      amount: // value for 'amount'
 *      caseName: // value for 'caseName'
 *      caseContext: // value for 'caseContext'
 *      patientId: // value for 'patientId'
 *      paymentInfo: // value for 'paymentInfo'
 *      payrixMerchantId: // value for 'payrixMerchantId'
 *      finixMerchantId: // value for 'finixMerchantId'
 *      workspaceId: // value for 'workspaceId'
 *   },
 * });
 */
export function usePayWithNewMethodMutation(baseOptions?: Apollo.MutationHookOptions<PayWithNewMethodMutation, PayWithNewMethodMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PayWithNewMethodMutation, PayWithNewMethodMutationVariables>(PayWithNewMethodDocument, options);
      }
export type PayWithNewMethodMutationHookResult = ReturnType<typeof usePayWithNewMethodMutation>;
export type PayWithNewMethodMutationResult = Apollo.MutationResult<PayWithNewMethodMutation>;
export type PayWithNewMethodMutationOptions = Apollo.BaseMutationOptions<PayWithNewMethodMutation, PayWithNewMethodMutationVariables>;