/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button, Flex, FormLabel, Stack, Text } from '@chakra-ui/react';
import {
  CouponFieldsFragment,
  SearchServicesQuery,
  useValidateCouponMutation,
  Validity,
  Location,
} from '@webapp/graphql';
import { ObjectOption } from '@webapp/types';
import { FormInput, NotificationType, NotificationWithIcon } from '@webapp/ui';
import { useLoadCoupons } from '@webapp/webapp/hooks';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import {
  CouponMessageType,
  CouponButtonType,
  invalidCouponMessageTypes,
  CouponMessages,
  validCouponMessageTypes,
} from './constants';
import OverrideExpiredCouponModal from './OverrideExpiredCouponModal';

interface ApplyDiscountProps {
  isMembershipCoupon?: boolean;
  couponIds?: string[];
  patientId?: string;
}

interface AppliedCoupon {
  amount: number;
  couponId: string;
  couponName: string;
  expirationOverride?: boolean;
  isMembershipCoupon: boolean;
  packageId?: string;
}

function ApplyDiscount({
  isMembershipCoupon = false,
  couponIds = [],
  patientId,
}: ApplyDiscountProps) {
  const { getValues, setValue, watch } = useFormContext();

  const [localAppliedCoupons, setLocalAppliedCoupons] = useState<
    AppliedCoupon[]
  >([]);

  const loadCoupons = useLoadCoupons({
    couponIds,
    showOnlyActive: !isMembershipCoupon,
    hideSystemGenerated: !isMembershipCoupon,
  });

  const relevantInputName = useMemo(
    () => (isMembershipCoupon ? 'invoiceMembershipCoupons' : 'invoiceCoupons'),
    [isMembershipCoupon]
  );

  const initialAppliedCoupons: AppliedCoupon[] = getValues(
    'appliedCoupons'
  ).filter(
    (appliedCoupon: AppliedCoupon) =>
      appliedCoupon.isMembershipCoupon === isMembershipCoupon
  );

  const [initialValue] = initialAppliedCoupons;

  const services: ObjectOption<SearchServicesQuery['service'][number]>[] =
    useWatch({ name: 'services' });

  const [showOverrideModal, setShowOverrideModal] = useState<boolean>(false);

  const [couponMessage, setCouponMessage] = useState<CouponMessageType | null>(
    initialValue ? CouponMessageType.VALID : null
  );

  const [couponButtonType, setCouponButtonType] =
    useState<CouponButtonType | null>(
      initialValue ? CouponButtonType.REMOVE : null
    );

  const [appliedCouponName, setAppliedCouponName] = useState<string | null>(
    initialValue?.couponName ?? null
  );

  const relevantInputNameValue: ObjectOption<CouponFieldsFragment> =
    watch(relevantInputName);

  useEffect(() => {
    if (initialValue) {
      setLocalAppliedCoupons(initialAppliedCoupons);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (initialValue) {
      return;
    }

    setCouponMessage(null);

    if (!relevantInputNameValue) {
      setCouponButtonType(null);
      return;
    }

    setCouponButtonType(CouponButtonType.APPLY);
  }, [
    relevantInputNameValue,
    initialValue,
    setCouponButtonType,
    setCouponMessage,
  ]);

  const addToAppliedCoupons = useCallback(
    (newCoupon: AppliedCoupon) => {
      if (newCoupon.isMembershipCoupon !== isMembershipCoupon) return;

      setLocalAppliedCoupons([...localAppliedCoupons, newCoupon]);
    },
    [isMembershipCoupon, localAppliedCoupons]
  );

  const setValidAddRemoveButton = useCallback(
    ({
      amount,
      couponId,
      couponName,
      packageId,
      expirationOverride,
    }: {
      amount: number;
      couponId: string;
      packageId?: string;
      couponName: string;
      expirationOverride?: boolean;
    }): void => {
      const newCoupon: AppliedCoupon = {
        amount,
        couponId,
        couponName,
        expirationOverride,
        isMembershipCoupon,
        packageId,
      };

      addToAppliedCoupons(newCoupon);

      setCouponMessage(CouponMessageType.VALID);
      setCouponButtonType(CouponButtonType.REMOVE);
      setAppliedCouponName(couponName);

      setValue('appliedCoupons', [...getValues('appliedCoupons'), newCoupon]);
    },
    [getValues, isMembershipCoupon, setValue, addToAppliedCoupons]
  );

  const onConfirmOverride = useCallback((): void => {
    const coupon: ObjectOption<CouponFieldsFragment> = relevantInputNameValue;

    if (!coupon) {
      setCouponMessage(CouponMessageType.INVALID);
      return;
    }

    if (!services?.length) {
      setCouponMessage(CouponMessageType.SELECT_SERVICES);
      return;
    }

    setValidAddRemoveButton({
      amount: coupon.object.amount,
      couponId: coupon.value as string,
      couponName: coupon.label,
      expirationOverride: true,
      packageId: coupon.object.packageId,
    });
  }, [relevantInputNameValue, services?.length, setValidAddRemoveButton]);

  const removeDiscount = useCallback((): void => {
    const localCopy = [...localAppliedCoupons];

    const lastApplied = localCopy.pop();

    setLocalAppliedCoupons(localCopy);

    setValue(
      'appliedCoupons',
      getValues('appliedCoupons').filter(
        (appliedCoupon: AppliedCoupon) =>
          appliedCoupon.couponId !== lastApplied?.couponId
      )
    );
    setAppliedCouponName(null);
    setCouponMessage(CouponMessageType.REMOVED);
    setCouponButtonType(CouponButtonType.APPLY);
  }, [localAppliedCoupons, setValue, getValues]);

  const appliedCoupons = watch('appliedCoupons');

  /* 
    NOTE: This is important to ensure that when appliedCoupons 
    is modified outside of this component that this component response 
  */
  useEffect(() => {
    if (!appliedCoupons?.length && appliedCouponName) {
      removeDiscount();
    }
  }, [appliedCoupons, appliedCouponName, removeDiscount]);

  const [validateCoupon] = useValidateCouponMutation();

  const validateSelectedCoupon = (): void => {
    const coupon: ObjectOption<CouponFieldsFragment> =
      getValues(relevantInputName);

    if (!coupon) {
      setCouponMessage(CouponMessageType.PLEASE_SELECT);
      return;
    }

    const location: ObjectOption<Pick<Location, 'name' | 'id'>> =
      getValues('location');

    if (!location) {
      setCouponMessage(CouponMessageType.SELECT_LOCATION);
      return;
    }

    if (!services?.length) {
      setCouponMessage(CouponMessageType.SELECT_SERVICES);
      return;
    }

    validateCoupon({
      variables: {
        couponId: coupon.value,
        serviceIds: (services ?? [])
          .filter((service) => service.value)
          .map((service) => service.value),
        locationId: location.value,
        isMembershipCoupon,
        patientId,
      },
      onCompleted: (validateCouponData) => {
        const { validity, couponId } = validateCouponData?.validateCoupon ?? {};

        switch (validity) {
          case Validity.Valid:
            setValidAddRemoveButton({
              amount: coupon.object.amount,
              couponId,
              couponName: coupon.label,
              packageId: coupon.object.packageId,
            });
            break;
          case Validity.Expired:
            setCouponMessage(CouponMessageType.EXPIRED);
            setCouponButtonType(CouponButtonType.OVERRIDE);
            break;
          case Validity.Invalid:
          default:
            setCouponMessage(CouponMessageType.INVALID);
            break;
        }
      },
      onError: () => {
        setCouponMessage(CouponMessageType.INVALID);
      },
    });
  };

  const openOverrideModal = (): void => {
    setShowOverrideModal(true);
  };

  const closeOverrideModal = (): void => {
    setShowOverrideModal(false);
  };

  const couponSelectProps = {
    loadOptions: () => loadCoupons,
    defaultOption: initialValue
      ? {
          label: initialValue.couponName,
          value: initialValue.couponId,
          object: { id: initialValue.couponId, name: initialValue.couponName },
        }
      : null,
    canCreate: false,
  };

  return (
    <Stack spacing={4}>
      <Stack spacing={2}>
        <FormInput
          label={isMembershipCoupon ? 'Wallet Coupons' : 'Discount/Promo Code'}
          name={relevantInputName}
          type="async-select"
          selectProps={couponSelectProps}
        />
        {couponMessage && invalidCouponMessageTypes.includes(couponMessage) && (
          <NotificationWithIcon
            text={CouponMessages[couponMessage]}
            type={NotificationType.WARNING}
          />
        )}
        {couponMessage && validCouponMessageTypes.includes(couponMessage) && (
          <NotificationWithIcon
            text={CouponMessages[couponMessage]}
            type={NotificationType.SUCCESS}
          />
        )}
        <Flex justifyContent="flex-start" w="full" pl={2}>
          {couponButtonType === CouponButtonType.APPLY && (
            <Button
              variant="link"
              colorScheme="teal"
              onClick={validateSelectedCoupon}
            >
              Apply Coupon
            </Button>
          )}
          {couponButtonType === CouponButtonType.OVERRIDE && (
            <Button
              variant="link"
              colorScheme="teal"
              onClick={openOverrideModal}
            >
              Override Expiration
            </Button>
          )}
          {couponButtonType === CouponButtonType.REMOVE && (
            <Button variant="link" colorScheme="teal" onClick={removeDiscount}>
              Remove Discount
            </Button>
          )}
        </Flex>
        <OverrideExpiredCouponModal
          isOpen={showOverrideModal}
          onClose={closeOverrideModal}
          onConfirm={onConfirmOverride}
        />
      </Stack>
      {appliedCouponName && (
        <Stack spacing={2}>
          <FormLabel>Applied Coupon</FormLabel>
          <Text>{appliedCouponName}</Text>
        </Stack>
      )}
    </Stack>
  );
}

export default ApplyDiscount;
