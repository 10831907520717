import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type DeleteEmailMarketingTagMutationVariables = Types.Exact<{
  emailTemplateId: Types.Scalars['uuid'];
  marketingTagId: Types.Scalars['uuid'];
}>;


export type DeleteEmailMarketingTagMutation = { __typename?: 'mutation_root', delete_marketingTagEmail?: { __typename?: 'marketingTagEmail_mutation_response', affected_rows: number } | null | undefined };


export const DeleteEmailMarketingTagDocument = gql`
    mutation deleteEmailMarketingTag($emailTemplateId: uuid!, $marketingTagId: uuid!) {
  delete_marketingTagEmail(
    where: {emailTemplateId: {_eq: $emailTemplateId}, marketingTagId: {_eq: $marketingTagId}}
  ) {
    affected_rows
  }
}
    `;
export type DeleteEmailMarketingTagMutationFn = Apollo.MutationFunction<DeleteEmailMarketingTagMutation, DeleteEmailMarketingTagMutationVariables>;

/**
 * __useDeleteEmailMarketingTagMutation__
 *
 * To run a mutation, you first call `useDeleteEmailMarketingTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEmailMarketingTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEmailMarketingTagMutation, { data, loading, error }] = useDeleteEmailMarketingTagMutation({
 *   variables: {
 *      emailTemplateId: // value for 'emailTemplateId'
 *      marketingTagId: // value for 'marketingTagId'
 *   },
 * });
 */
export function useDeleteEmailMarketingTagMutation(baseOptions?: Apollo.MutationHookOptions<DeleteEmailMarketingTagMutation, DeleteEmailMarketingTagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteEmailMarketingTagMutation, DeleteEmailMarketingTagMutationVariables>(DeleteEmailMarketingTagDocument, options);
      }
export type DeleteEmailMarketingTagMutationHookResult = ReturnType<typeof useDeleteEmailMarketingTagMutation>;
export type DeleteEmailMarketingTagMutationResult = Apollo.MutationResult<DeleteEmailMarketingTagMutation>;
export type DeleteEmailMarketingTagMutationOptions = Apollo.BaseMutationOptions<DeleteEmailMarketingTagMutation, DeleteEmailMarketingTagMutationVariables>;