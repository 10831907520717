import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { SmartNoteFieldsFragmentDoc } from '../../fragments/smartNoteFields.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetSmartNotesQueryVariables = Types.Exact<{
  where?: Types.Maybe<Types.SmartNote_Bool_Exp>;
  limit?: Types.Maybe<Types.Scalars['Int']>;
  offset?: Types.Maybe<Types.Scalars['Int']>;
  orderBy?: Types.Maybe<Array<Types.SmartNote_Order_By> | Types.SmartNote_Order_By>;
}>;


export type GetSmartNotesQuery = { __typename?: 'query_root', smartNote: Array<{ __typename?: 'smartNote', id: any, title: string, callPhrase: string, content: string, createdAt: any, updatedAt: any, workspaceId: any }> };


export const GetSmartNotesDocument = gql`
    query GetSmartNotes($where: smartNote_bool_exp, $limit: Int, $offset: Int, $orderBy: [smartNote_order_by!]) {
  smartNote(where: $where, limit: $limit, offset: $offset, order_by: $orderBy) {
    ...SmartNoteFields
  }
}
    ${SmartNoteFieldsFragmentDoc}`;

/**
 * __useGetSmartNotesQuery__
 *
 * To run a query within a React component, call `useGetSmartNotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSmartNotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSmartNotesQuery({
 *   variables: {
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetSmartNotesQuery(baseOptions?: Apollo.QueryHookOptions<GetSmartNotesQuery, GetSmartNotesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSmartNotesQuery, GetSmartNotesQueryVariables>(GetSmartNotesDocument, options);
      }
export function useGetSmartNotesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSmartNotesQuery, GetSmartNotesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSmartNotesQuery, GetSmartNotesQueryVariables>(GetSmartNotesDocument, options);
        }
export type GetSmartNotesQueryHookResult = ReturnType<typeof useGetSmartNotesQuery>;
export type GetSmartNotesLazyQueryHookResult = ReturnType<typeof useGetSmartNotesLazyQuery>;
export type GetSmartNotesQueryResult = Apollo.QueryResult<GetSmartNotesQuery, GetSmartNotesQueryVariables>;