import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type InsertCompensationPolicyMutationVariables = Types.Exact<{
  object: Types.CompensationPolicy_Insert_Input;
}>;


export type InsertCompensationPolicyMutation = { __typename?: 'mutation_root', insert_compensationPolicy_one?: { __typename?: 'compensationPolicy', id: any } | null | undefined };


export const InsertCompensationPolicyDocument = gql`
    mutation InsertCompensationPolicy($object: compensationPolicy_insert_input!) {
  insert_compensationPolicy_one(object: $object) {
    id
  }
}
    `;
export type InsertCompensationPolicyMutationFn = Apollo.MutationFunction<InsertCompensationPolicyMutation, InsertCompensationPolicyMutationVariables>;

/**
 * __useInsertCompensationPolicyMutation__
 *
 * To run a mutation, you first call `useInsertCompensationPolicyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertCompensationPolicyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertCompensationPolicyMutation, { data, loading, error }] = useInsertCompensationPolicyMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertCompensationPolicyMutation(baseOptions?: Apollo.MutationHookOptions<InsertCompensationPolicyMutation, InsertCompensationPolicyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertCompensationPolicyMutation, InsertCompensationPolicyMutationVariables>(InsertCompensationPolicyDocument, options);
      }
export type InsertCompensationPolicyMutationHookResult = ReturnType<typeof useInsertCompensationPolicyMutation>;
export type InsertCompensationPolicyMutationResult = Apollo.MutationResult<InsertCompensationPolicyMutation>;
export type InsertCompensationPolicyMutationOptions = Apollo.BaseMutationOptions<InsertCompensationPolicyMutation, InsertCompensationPolicyMutationVariables>;