import { Flex, Stack, Button, chakra, Text, HStack } from '@chakra-ui/react';
import { AppointmentFieldsFullFragment } from '@webapp/graphql';
import { useStores } from '@webapp/state-models';
import { StatusBadge } from '@webapp/ui';
import { useNavigate } from 'react-router-dom';
import { FormConsentSection } from './formConsentSection';
import { FormCustomFormSection } from './formCustomFormSection';
import { FormDocumentSection } from './formDocumentSection';
// import { InstructionsTable } from './instructionsTable';
import { InstructionsSection } from './instructionsSection';

export const CollapseSection = chakra(Stack, {
  baseStyle: {
    border: '1px solid #DCDCDC',
    borderRadius: '10px',
    padding: '40px',
  },
});

export const CollapseSectionHeader = chakra(Text, {
  baseStyle: {
    color: '#006070',
    fontSize: '16px',
    fontWeight: 'bold',
    letterSpacing: '0.01em',
    textTransform: 'uppercase',
  },
});

export const PatientDocuments = ({
  appointment,
  mini = false,
}: {
  appointment: AppointmentFieldsFullFragment;
  mini?: boolean;
}): JSX.Element => {
  const { setOutstandingForms, setOutstandingConsents } = useStores();
  const navigate = useNavigate();

  const { resolvedAppointmentCustomForms, resolvedAppointmentConsents } =
    appointment;

  const hasOutstandingForms = resolvedAppointmentCustomForms.hasOutstanding;
  const hasOutstandingConsents = resolvedAppointmentConsents.hasOutstanding;

  const hasIncompleteItems = Boolean(
    hasOutstandingForms || hasOutstandingConsents
  );

  const goCompleteAll = (): void => {
    // shutdown intercom
    try {
      window.Intercom('shutdown');
    } catch (err) {
      // sshh
    }
    if (!hasIncompleteItems) return;

    setOutstandingForms(
      resolvedAppointmentCustomForms.outstanding.map((form) => form.id)
    );

    setOutstandingConsents(
      resolvedAppointmentConsents.outstanding.map((consent) => consent.id)
    );

    const { patientId } = appointment;

    if (hasOutstandingForms) {
      const firstForm = resolvedAppointmentCustomForms.outstanding[0];

      navigate(
        `/patients/${patientId}/appointments/${appointment.id}/forms/${firstForm.id}`
      );
    } else {
      const firstConsent = resolvedAppointmentConsents.outstanding[0];

      navigate(
        `/patients/${patientId}/appointments/${appointment.id}/consents/${firstConsent.id}`
      );
    }
  };

  return (
    <CollapseSection
      {...(hasIncompleteItems && {
        borderColor: 'red.500',
      })}
      padding={{ base: 4, md: mini ? '20px' : '40px' }}
    >
      <Stack>
        <HStack spacing="10px">
          <CollapseSectionHeader>Documents</CollapseSectionHeader>
          {hasIncompleteItems && <StatusBadge status="INCOMPLETE" />}
        </HStack>
        {hasIncompleteItems && (
          <Flex w="full" justifyContent="flex-start" my={6} pl={2}>
            <Button colorScheme="teal" onClick={goCompleteAll}>
              Complete All Outstanding
            </Button>
          </Flex>
        )}
        <FormDocumentSection appointment={appointment} mini={mini} />
        <FormConsentSection appointment={appointment} mini={mini} />
        <FormCustomFormSection appointment={appointment} mini={mini} />
        <InstructionsSection appointment={appointment} mini={mini} />
        {/* <InstructionsTable appointment={appointment} /> */}
      </Stack>
    </CollapseSection>
  );
};

export default PatientDocuments;
