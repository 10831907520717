import { chakra } from '@chakra-ui/react';
import ReactSelect, { StylesConfig } from 'react-select';
import { FormInputProps, SelectOption } from '@webapp/types';
import { capitalize } from 'lodash';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { MONTHS } from '@webapp/constants';
import { configuredDayjs, getLastNYears } from '@webapp/util-time';
import FormInputWrapper from './components/FormInputWrapper';

const SelectStyle: StylesConfig<SelectOption> = {
  control: (base) => ({
    ...base,
    borderColor: '#E2E8F0',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  input: (base) => ({
    ...base,
    borderColor: '#E2E8F0',
  }),
};

function getNameForDisplay({
  displayName,
  name,
}: {
  name: string;
  displayName?: string;
}) {
  if (displayName) {
    return displayName;
  }

  return capitalize(name.split(/(?=[A-Z])+/g).join(' '));
}

function MultiPartDatePickerFormInput(props: FormInputProps) {
  const [day, setDay] = useState<string | null>(null);
  const [month, setMonth] = useState<string | null>(null);
  const [year, setYear] = useState<string | null>(null);
  const { displayName, name, required } = props;
  const { register, setValue } = useFormContext();

  const nameToDisplay = getNameForDisplay({ name, displayName });

  useEffect(() => {
    register(name, {
      required: required ? `${nameToDisplay} is required` : undefined,
    });
  });

  useEffect(() => {
    if (day && month && year) {
      const dayInt = parseInt(day, 10);
      const monthInt = parseInt(month, 10);
      const yearInt = parseInt(year, 10);

      const date = configuredDayjs()
        .set('year', yearInt)
        .set('month', monthInt)
        .set('date', dayInt);

      setValue(name, date.toDate());
    }
  }, [day, month, name, setValue, year]);

  const LIST_OF_DAYS = Array.from(Array(31)).map((_, index) => index + 1);

  const LAST_N_YEARS = getLastNYears(120, { endBuffer: 8 });

  function handleDayChange(newValue: SelectOption | null) {
    setDay(newValue?.value ?? null);
  }

  function handleMonthChange(newValue: SelectOption | null) {
    setMonth(newValue?.value ?? null);
  }

  function handleYearChange(newValue: SelectOption | null) {
    setYear(newValue?.value ?? null);
  }

  return (
    <FormInputWrapper {...props}>
      <chakra.div
        display="grid"
        gridColumnGap="12px"
        gridTemplateColumns="75px 125px 100px"
      >
        <ReactSelect<SelectOption>
          onChange={handleDayChange}
          options={LIST_OF_DAYS.map((dayOption) => ({
            label: `${dayOption}`,
            value: `${dayOption}`,
          }))}
          placeholder="Day"
          styles={SelectStyle}
        />
        <ReactSelect<SelectOption>
          onChange={handleMonthChange}
          options={MONTHS.map((monthOption, index) => ({
            label: monthOption.name,
            value: `${index}`,
          }))}
          placeholder="Month"
          styles={SelectStyle}
        />
        <ReactSelect<SelectOption>
          onChange={handleYearChange}
          placeholder="Year"
          styles={SelectStyle}
          options={LAST_N_YEARS.map((yearOption) => ({
            label: yearOption,
            value: yearOption,
          }))}
        />
      </chakra.div>
    </FormInputWrapper>
  );
}

export default MultiPartDatePickerFormInput;
