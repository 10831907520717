import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import { ConsumableFieldsFragmentDoc } from '../../fragments/consumableFields.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type InsertConsumableMutationVariables = Types.Exact<{
  consumable: Types.Consumable_Insert_Input;
}>;


export type InsertConsumableMutation = { __typename?: 'mutation_root', insert_consumable?: { __typename?: 'consumable_mutation_response', returning: Array<{ __typename?: 'consumable', id: any, cost: number, metadata: any, originalQuantity: any, quantity: any, isExpirable: boolean, expirationDate?: any | null | undefined, daysBeforeNotification?: number | null | undefined, shouldWarnQuantity: boolean, warningQuantity?: number | null | undefined, createdAt: any, updatedAt: any, archived: boolean, type: { __typename?: 'consumableType', id: any, name: string, requiredFields: any, unitStep: any, unitName: string, defaultNumberOfUnits: any, defaultPricePerUnit: number }, brand?: { __typename?: 'consumableBrand', id: any, name: string } | null | undefined, purchaseOrder?: { __typename?: 'purchaseOrder', id: any, orderNumber: string } | null | undefined }> } | null | undefined };


export const InsertConsumableDocument = gql`
    mutation insertConsumable($consumable: consumable_insert_input!) {
  insert_consumable(objects: [$consumable]) {
    returning {
      ...ConsumableFields
    }
  }
}
    ${ConsumableFieldsFragmentDoc}`;
export type InsertConsumableMutationFn = Apollo.MutationFunction<InsertConsumableMutation, InsertConsumableMutationVariables>;

/**
 * __useInsertConsumableMutation__
 *
 * To run a mutation, you first call `useInsertConsumableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertConsumableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertConsumableMutation, { data, loading, error }] = useInsertConsumableMutation({
 *   variables: {
 *      consumable: // value for 'consumable'
 *   },
 * });
 */
export function useInsertConsumableMutation(baseOptions?: Apollo.MutationHookOptions<InsertConsumableMutation, InsertConsumableMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertConsumableMutation, InsertConsumableMutationVariables>(InsertConsumableDocument, options);
      }
export type InsertConsumableMutationHookResult = ReturnType<typeof useInsertConsumableMutation>;
export type InsertConsumableMutationResult = Apollo.MutationResult<InsertConsumableMutation>;
export type InsertConsumableMutationOptions = Apollo.BaseMutationOptions<InsertConsumableMutation, InsertConsumableMutationVariables>;